import { createStyles, makeStyles } from '@material-ui/core'

export const useClassAssignmentGradeStyles = makeStyles(() =>
	createStyles({
		actionsHolder: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginTop: 30,
			'& button': {
				padding: '0 50px'
			}
		}
	})
)

export default useClassAssignmentGradeStyles
