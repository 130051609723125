import { Box } from '@material-ui/core'

import { PagesProps } from '../../../../../../../../@types'
import { StudentAnalyticsInsights } from './components/StudentAnalyticsInsights'
import { StudentAnalyticsProgress } from './components/StudentAnalyticsProgress/StudentAnalyticsProgress'
import { StudentAnalyticsTitle } from './components/StudentAnalyticsTitle'
import { StudentAnalyticsProvider } from './useStudentAnalytics'

export const StudentAnalytics = (props: PagesProps) => {
	return (
		<Box>
			<StudentAnalyticsProvider>
				<StudentAnalyticsTitle {...props} />
				<StudentAnalyticsInsights />
				<StudentAnalyticsProgress />
			</StudentAnalyticsProvider>
		</Box>
	)
}
