import { Box, Paper, PaperProps, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import clsx from 'clsx'
import { DefaultImageIcon, EditIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components'
import { AddToClassButton } from 'src/components/Buttons/AddToClassButton'
import { AddToClassDialogContainer } from 'src/components/Dialogs/AddToClassDialog/AddToClassDialog.container'
import { ModulesDialog } from 'src/components/Dialogs/ModulesDialog/ModulesDialog'
import { StudioSongPlayerDialogContainer } from 'src/components/Dialogs/StudioDialog/StudioSongPlayerDialog.container'
import { VideoDialog } from 'src/components/Dialogs/VideoDialog/VideoDialog'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'
import { Lesson_Plan, Module, Playlist, Song, Tip } from 'src/graphql/autogenerate/schemas'
import useAllClassProductsCardStyles from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/AllClassProductsCard.styles'
import { ProductCardButton } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/productCardButton/ProductCardButton'
import { useAllClassProductsCard } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/useAllClassProductsCard'
import {
	buildImagePath,
	getModuleLessonPlanAggregate,
	getRoles,
	getCatalogItems,
	getPermissionName,
	getTeacherId
} from 'src/utils'
import { ProductTitleEnum } from 'src/utils/enums'

type AllClassProductsCardProps = PaperProps & {
	data: Lesson_Plan | Module | Playlist | Tip | Song
	type: string
	editTooltip?: string
	deleteTooltip?: string
	addToClass?: boolean
	addAssigment?: boolean
}

export const AllClassProductsCard = ({
	data,
	type,
	editTooltip,
	deleteTooltip,
	addAssigment,
	addToClass,
	...props
}: AllClassProductsCardProps) => {
	const styles = useAllClassProductsCardStyles({ imageUrl: buildImagePath(data.image_path) || '' })
	const {
		handleView,
		handleEdit,
		handleOpenDeleteDialog,
		handleCloseDeleteDialog,
		handleDelete,
		deleteDialogOpen,
		deleteLoading,
		deleteError,
		deleteData,
		evictCacheDelete,
		teacherId,
		handleModulesDialogOpen,
		setIsModulesDialogOpen,
		isModulesDialogOpen,
		handleAddToClassDialog,
		addToClassDialogOpen,
		handleCloseAddToClassDialog,
		videoDialogOpen,
		closeVideoDialog,
		video,
		songPlayerOpen,
		closeSongPlayerDialog,
		count,
		getTitle,
		getDescription,
		getId,
		goToCreateFromLesson
	} = useAllClassProductsCard({ data, type })

	return (
		<>
			{type === ProductTitleEnum.LessonPlan ||
			type === ProductTitleEnum.Tip ||
			type === ProductTitleEnum.Song ? (
				<Paper
					{...props}
					className={clsx(styles.containerLessons, props.className)}
					onClick={() => {
						handleView()
					}}>
					<Box display="flex" className={styles.detailsLessons} overflow="hidden">
						<Box className={styles.imageContainerLessons}>
							{data.image_path ? (
								<img src={buildImagePath(data.image_path)} className={styles.image} />
							) : (
								<Box className={styles.defaultContainer}>
									<DefaultImageIcon className={styles.defaultIcon} />
								</Box>
							)}
						</Box>
						<Box className={styles.detailsText}>
							<Typography variant="h6" className={clsx(styles.titleLessons, styles.ellipsisLessons)}>
								{getTitle(data)}
							</Typography>
							<Typography className={styles.ellipsisLessons}>{getDescription(data)}</Typography>
							<Typography className={clsx(styles.genresLessons, styles.ellipsisLessons)}>
								•{/* Todo: {genres.join(', ')} */}
							</Typography>
						</Box>
					</Box>
					<>
						<Box display="flex" flexDirection="column">
							<Typography className={styles.visibilityLessons}>
								{getPermissionName(data.lockable_content?.visibility)}
							</Typography>
						</Box>
						<Box display="flex">
							{type === ProductTitleEnum.LessonPlan && (
								<Tooltip
									title={
										getModuleLessonPlanAggregate(data) === 0
											? 'This lesson is not assigned to any module yet'
											: ''
									}>
									<span>
										<ProductCardButton
											className={styles.editButton}
											onClick={(e) => {
												e.stopPropagation()
												handleModulesDialogOpen()
											}}
											disabled={getModuleLessonPlanAggregate(data) === 0}>
											<InfoIcon />
										</ProductCardButton>
									</span>
								</Tooltip>
							)}

							{teacherId === getTeacherId(data) && (
								<ProductCardButton
									className={styles.editButton}
									tooltipTitle={editTooltip}
									onClick={(e) => {
										e.stopPropagation()
										handleEdit()
									}}
									aria-label="edit">
									<EditIcon />
								</ProductCardButton>
							)}

							{teacherId === getTeacherId(data) && (
								<ProductCardButton
									className={clsx(styles.deleteButton, styles.deleteIcon)}
									tooltipTitle={deleteTooltip}
									onClick={(e) => {
										e.stopPropagation()
										handleOpenDeleteDialog()
									}}
									aria-label="delete">
									<DeleteIcon />
								</ProductCardButton>
							)}

							{addToClass && (
								<AddToClassButton
									tooltipTitle="Add to class"
									onClick={(e) => {
										e.stopPropagation()
										handleAddToClassDialog()
									}}
								/>
							)}

							{addAssigment && (
								<Tooltip title="Add Assignment">
									<BaseButton
										color="primary"
										className={styles.AddToClassButton}
										onClick={goToCreateFromLesson}
										endIcon={<AddIcon />}>
										<Typography>Add Assignment</Typography>
									</BaseButton>
								</Tooltip>
							)}
						</Box>
					</>
				</Paper>
			) : (
				<Paper
					{...props}
					className={styles.paper}
					onClick={() => {
						handleView()
					}}>
					<Box className={styles.container}>
						<Box className={data.image_path ? styles.imageContainer : styles.blank}>
							<Box className={styles.opacity} />
							<Typography variant="h5" className={clsx(styles.ellipsis, styles.title)}>
								{getTitle(data)}
							</Typography>

							<Box
								className={
									type !== ProductTitleEnum.Module && type !== ProductTitleEnum.Playlist
										? styles.noFlag
										: styles.flag
								}>
								<Typography className={clsx(styles.flagText, styles.ellipsisSecondLine)}>
									{type === ProductTitleEnum.Module ? (
										<b>{`${count || 0} Lessons`}</b>
									) : type === ProductTitleEnum.Playlist ? (
										<b>{`${count || 0} Videos`}</b>
									) : (
										''
									)}
								</Typography>
							</Box>
						</Box>
						<Box className={styles.details}>
							<Typography className={clsx(styles.styledEllipsis, styles.description)}>
								{getDescription(data)}
							</Typography>

							<Box className={styles.containerButtons}>
								<Typography className={styles.visibility}>
									{getPermissionName(data.lockable_content?.visibility) || ''}
								</Typography>
								<Box className={styles.buttons}>
									<AddToClassButton
										tooltipTitle="Add to class"
										onClick={(e) => {
											e.stopPropagation()
											handleAddToClassDialog()
										}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Paper>
			)}

			<MutationDialog
				open={deleteDialogOpen}
				onClose={handleCloseDeleteDialog}
				deleteElement={getRoles(type as string)}
				discardButton={{
					onClick: handleCloseDeleteDialog
				}}
				confirmButton={{
					onClick: handleDelete
				}}
				icon="question"
				loading={deleteLoading}
				error={!!deleteError}
				success={!!deleteData}
				onSuccessLabel="Close"
				onSuccessOnClick={() => {
					evictCacheDelete()
				}}
			/>

			<AddToClassDialogContainer
				open={addToClassDialogOpen}
				onClose={handleCloseAddToClassDialog}
				insertId={getId(data) || 0}
				role={getRoles(type as string)}
			/>
			<VideoDialog
				open={videoDialogOpen}
				onClose={closeVideoDialog}
				video={video}
				onDelete={handleOpenDeleteDialog}
				onEdit={handleEdit}
				onAddToClass={handleAddToClassDialog}
				genres={getCatalogItems(video.tip_catalog_item || [])}
				assetUrl={video.asset_path || ''}
				onDiscard={closeVideoDialog}
			/>
			<StudioSongPlayerDialogContainer
				open={songPlayerOpen}
				onClose={closeSongPlayerDialog}
				songId={getId(data)}
				onEdit={handleEdit}
				onDelete={handleOpenDeleteDialog}
			/>
			<ModulesDialog
				lessonPlanId={getId(data)}
				isOpen={isModulesDialogOpen}
				setIsOpen={setIsModulesDialogOpen}
			/>
		</>
	)
}
