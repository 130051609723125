import { Typography, makeStyles, createStyles } from '@material-ui/core'

type SectionHeaderProps = {
	content: string | React.ReactChild
}

const useStyles = makeStyles(() =>
	createStyles({
		header: {
			marginTop: '3em',
			marginBottom: '1em'
		}
	})
)
export const SectionHeader = ({ content }: SectionHeaderProps) => {
	const classes = useStyles()

	return (
		<Typography variant="h6" className={classes.header}>
			<strong>{content}</strong>
		</Typography>
	)
}
