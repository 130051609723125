import { createStyles, makeStyles, Paper, Typography } from '@material-ui/core'
import { ChartData } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChartDataType } from '../../../../../../@types'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: '20px'
		}
	})
)
type Props = {
	title: string
	data?: ChartDataType
}

export const StackedBar = ({ title, data }: Props) => {
	const styles = useStyles()

	return (
		<Paper className={styles.container}>
			<Typography variant="h6">
				<b>{title}</b>
			</Typography>
			<Bar
				data={data as ChartData<'bar', number[], string>}
				options={{
					scales: {
						yAxis: {
							stacked: true,
							beginAtZero: true
						},
						xAxis: {
							stacked: true
						}
					}
				}}
			/>
		</Paper>
	)
}
