import { createStyles, makeStyles } from '@material-ui/core'

export const usePaginatedChartStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			position: 'relative'
		},
		fabButton: {
			background: 'white',
			position: 'absolute'
		},
		btnRight: {
			right: -theme.spacing(6)
		},
		btnLeft: {
			left: -theme.spacing(6)
		}
	})
)

export default usePaginatedChartStyles
