import { Fragment, useEffect } from 'react'

import { Box } from '@material-ui/core'

import { BigBaseButton, InfoDialog, BaseDialog } from '../../../../../../../../../components'
import { GetStudentQuestionaryQuestion } from '../../../../../../../../../graphql/autogenerate/schemas'
import { getCyKey } from '../../../../../../../../../utils/utils'
import { DialogHeader } from '../../../components/DialogHeader'
import { useStudentSignInOptionsStyles } from '../../../styleStudentSignInOptions'
import { useVerificationDialogStyles } from '../styleVerificationDialog'
import { useVerificationDialogContext } from '../useVerificationDialog'

type VerificationDialogProps = {
	question: string
	options: GetStudentQuestionaryQuestion[]
}

export const StudentVerificationDialog = ({ question, options }: VerificationDialogProps) => {
	const { handleClose, isInfoDialogVisibile, setStudentAnswerValue, setStudentCode } =
		useVerificationDialogContext()
	const genericStyles = useStudentSignInOptionsStyles()
	const verificationDialogStyles = useVerificationDialogStyles()

	useEffect(() => {
		return () => {
			setStudentAnswerValue(0)
			setStudentCode('')
		}
	}, [])

	// const handleInfoDialogClose = useCallback(() => {
	// 	setInfoDialogVisibility(false)
	// }, [isInfoDialogVisibile])

	return (
		<Fragment>
			<BaseDialog
				className={genericStyles.baseDialog}
				open
				paperProps={{ className: genericStyles.paper }}
				onClose={handleClose}
				onDiscard={handleClose}
				dividers={false}
				header={
					<DialogHeader
						data-cy={getCyKey(StudentVerificationDialog)}
						title={question}
						style={{ marginBottom: 0 }}
					/>
				}
				isActionsHidden
				body={
					<Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column">
						{options.map((option) => {
							return (
								<BigBaseButton
									key={option.id}
									onClick={() => {
										setStudentAnswerValue(option.id)
									}}
									className={verificationDialogStyles.answerBtn}>
									{option.label}
								</BigBaseButton>
							)
						})}
					</Box>
				}
			/>
			<InfoDialog
				title="Check Your Student Code"
				body="Please check your student code with your teacher"
				icon="!"
				open={isInfoDialogVisibile}
				onClose={handleClose}
				onConfirm={handleClose}
				confirmLabel={'Got it!'}
			/>
		</Fragment>
	)
}
