import { useEffect, useState } from 'react'

import { useGetSongTrackTypesQuery, useGetTrackTypesLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { buildAssetUrl } from 'src/utils'

import { useUploadSongTrackContext } from '../../useUploadSongTrack'

export const useUploadAudioView = () => {
	const { songId } = useWurrlyParams()
	const {
		setAudio,
		setIsUploadTrackStepValid,
		setTrackResourcePath,
		setTrackTypeId,
		formikStepOne: formik,
		setViewTitle,
		setViewDescription
	} = useUploadSongTrackContext()
	const [loadTrackTypes, { data, loading }] = useGetTrackTypesLazyQuery()
	const { data: songData } = useGetSongTrackTypesQuery({
		variables: {
			songId
		}
	})
	const [search, setSearch] = useState('')

	useEffect(() => {
		setViewTitle('Upload Audio Track')
		setViewDescription('')

		return () => {
			setViewTitle('')
			setViewDescription('')
		}
	}, [])

	useEffect(() => {
		const { resource_path, terms, track_type_id } = formik.values

		if (resource_path && terms && Boolean(track_type_id)) {
			setAudio(new Audio(buildAssetUrl(resource_path)))
			setIsUploadTrackStepValid(true)
		} else {
			setIsUploadTrackStepValid(false)
		}
	}, [formik.values])

	useEffect(() => {
		const { resource_path, track_type_id } = formik.values

		setTrackResourcePath(resource_path)
		setTrackTypeId(track_type_id)
	}, [formik.values])

	useEffect(() => {
		loadTrackTypes()
	}, [])

	return {
		formik,
		trackTypes: data?.track_type ?? [],
		loadingTrackTypes: loading,
		setSearch,
		songData,
		search
	}
}
