import React, { useEffect, useState, ChangeEvent } from 'react'

import { Box, Paper } from '@material-ui/core'
import { ActivityBoxEmpty, SelectSort, Select } from 'src/components'
import { AssignmentPaginationList } from 'src/components/AssignmentPaginationList'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'
import { SortOrder } from 'src/utils'

import useStyles from './StudentAssignments.styles'
import { useStudentAssignments } from './useStudentAssignments'

export const StudentAssignments = () => {
	const styles = useStyles()
	const {
		PAGE_ELEMENTS,
		classes,
		loading,
		data,
		selectedClassId,
		setSelectedClassId,
		refetch,
		setSort,
		sort,
		setSortBy
	} = useStudentAssignments()
	const [assignmentsItems, setAssignmentsItems] = useState<Class_Assignment[]>([])
	const [loadingAssignments, setLoadingAssignments] = useState<boolean>(false)

	const handleSelectedClass = (
		e: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		setSelectedClassId(Number(e.target.value))
	}

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		if (selectedSort === SortOrder.DateAsc) {
			setSortBy(SortOrder.Up)
		} else if (selectedSort === SortOrder.DateDesc) {
			setSortBy(SortOrder.Down)
		} else setSortBy(selectedSort)

		setSort(selectedSort)
	}

	useEffect(() => {
		if (classes?.class?.length && setSelectedClassId) {
			setSelectedClassId(classes?.class[0].class_id)
		}
	}, [classes])

	useEffect(() => {
		setLoadingAssignments(loading)
		if (!loading && data && data.class_assignment) {
			setAssignmentsItems(data.class_assignment as unknown as Class_Assignment[])
		}
	}, [data, loading])

	const handlePageChange = (page: number) => {
		setLoadingAssignments(true)
		refetch({ offset: (page - 1) * PAGE_ELEMENTS })
	}

	return (
		<Box>
			<Box className={styles.containerBox}>
				{classes && (
					<Box className={styles.headerBox}>
						<Select
							className={styles.classSelect}
							options={classes?.class?.map((i) => ({ name: i.title, value: i.class_id }))}
							onChange={handleSelectedClass}
							value={selectedClassId}
						/>
						<Box className={styles.dateSelect}>
							<SelectSort cyKey="StudentAssignments" value={sort} onChange={handleSort} dueDate />
						</Box>
					</Box>
				)}
			</Box>
			{assignmentsItems.length > 0 ? (
				<Paper className={styles.noPadding}>
					<AssignmentPaginationList
						rowsPerPage={PAGE_ELEMENTS}
						limit={assignmentsItems.length}
						data={assignmentsItems}
						onPageChange={handlePageChange}
						loading={loadingAssignments}
						totalRows={data?.class_assignment_aggregate.aggregate?.count ?? 0}
					/>
				</Paper>
			) : (
				<ActivityBoxEmpty
					title="No upcoming Assignments."
					description="Start by creating an Assignment for a Class. The upcoming Assignments will appear here."
				/>
			)}
		</Box>
	)
}
