import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { useGetVideoTeachersLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, buildSearchText } from 'src/utils'

export const useVideos = ({ page }: Partial<PagesProps>) => {
	const history = useHistory()

	const [loadFeaturedVideosData, featuredVideosData] = useGetVideoTeachersLazyQuery({})

	const [loadSearchFeaturedVideosData, searchFeaturedVideosData] = useGetVideoTeachersLazyQuery({})

	const onSearch = (search: string) => {
		loadSearchFeaturedVideosData({
			variables: {
				conditions: buildSearchText(search || '', ['title', 'artist.name']),
				offset: 0,
				limit: 5
			}
		})
	}

	const fetchFirstTime = () => {
		return loadFeaturedVideosData({ variables: { limit: 5 } })
	}

	const onSearchEnter = (searchbarText: string) => {
		if (!searchbarText) return

		if (page && page.path === Pages.SearchVideos.path) {
			loadFeaturedVideosData({
				variables: {
					conditions: buildSearchText(searchbarText || '', ['title', 'artist.name']),
					offset: 0
				}
			})

			return
		}

		history.push(buildRouteParameters(Pages.SearchVideos), {
			searchbarText
		})
	}

	return {
		onSearch,
		onSearchEnter,
		searchFeaturedVideosData,
		featuredVideosData,
		fetchFirstTime
	}
}
