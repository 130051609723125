import { Box, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Page } from 'src/@types'
import { MainTitle } from 'src/components'
import { useClassWurrlyContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'
import { buildBreadCrumbs, slugToName } from 'src/utils'

type ClassWurrlyTitleProps = {
	title: string
	subtitle?: string
	onDelete?: () => void
	loadingDelete?: boolean
}

export const ClassWurrlyTitle = ({
	title,
	subtitle,
	onDelete,
	loadingDelete,
	page
}: { page: Page } & ClassWurrlyTitleProps) => {
	const { wurrlyClass } = useClassWurrlyContext()
	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(wurrlyClass?.title || '')
			},
			{
				index: 2,
				overrideName: 'My Wurrly'
			}
		],
		isStudentPage: true,
		params: { classId: wurrlyClass?.class_id || 0 }
	})

	return (
		<MainTitle
			breadcrumbs={breadCrumbs}
			title={
				(title && (
					<Box>
						<Typography variant="h3" style={{ textTransform: 'capitalize' }}>
							<b>{title}</b>&nbsp;
						</Typography>
						<Typography style={{ textTransform: 'capitalize' }}>
							<b>{subtitle}</b>&nbsp;
						</Typography>
					</Box>
				)) ||
				'Class'
			}
			rightActions={
				onDelete
					? [
							{
								style: {
									backgroundColor: '#f44336',
									color: 'white',
									height: 56,
									padding: '0px 30px',
									boxShadow:
										'0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
								},
								startIcon: <DeleteIcon />,
								name: 'Delete',
								onClick: () => {
									onDelete()
								},
								disabled: loadingDelete
							}
					  ]
					: []
			}
		/>
	)
}
