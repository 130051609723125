import { useState, useEffect } from 'react'

import { Box, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { useHistory } from 'react-router'
import { EditIcon } from 'src/assets/icons'
import { ActionButton, BaseButton, Datetime } from 'src/components'
import { RescheduleDialog } from 'src/components/Dialogs/RescheduleDialog/RescheduleDialog'
import { GradeSquare } from 'src/components/Squares/GradeSquare/GradeSquare'
import {
	Class_Assignment,
	Class_Assignment_Status_Enum,
	Submission_State_Enum
} from 'src/graphql/autogenerate/schemas'
import { Pages as TeacherPages } from 'src/routes/teacherPages'
import { buildRouteParameters, dueDateExpired, getDataFromDate } from 'src/utils'

import { getCyKey } from '../../../utils/utils'
import useStyles from './AssignmentCard.styles'
import { GradedProgress } from './components/GradedProgress'
type AssignmentCardProps = React.HTMLAttributes<HTMLDivElement> & {
	classAssignment: Class_Assignment
	totalClassStudents?: number
	showGradeState?: boolean
	viewButton?: boolean
	editButton?: boolean
	showInstructions?: boolean
	grade?: string
	amountGraded?: number
	amountSubmitted?: number
	refetch?: () => void
}

enum GradeStatus {
	Started = 'In Progress',
	NotStarted = 'Not Started',
	Graded = 'Graded'
}

export const AssignmentCard = ({
	classAssignment,
	viewButton = true,
	editButton = true,
	showInstructions = true,
	showGradeState = true,
	grade,
	amountGraded,
	amountSubmitted,
	refetch,
	...props
}: AssignmentCardProps & React.HTMLAttributes<HTMLDivElement>) => {
	const styles = useStyles()
	const history = useHistory()
	const [gradeState, setGradeState] = useState<GradeStatus>(GradeStatus.NotStarted)
	const [gradeTextClass, setGradeTextClass] = useState('')
	const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false)
	const { name: title, instructions, due_date: dueDate, due_time: dueTime } = classAssignment?.assignment
	const { dayNumber, dayName, month, time } = getDataFromDate(dueDate, dueTime)
	const [totalGraded, setTotalGraded] = useState(0)
	const [totalSubmitted, setTotalSubmitted] = useState(0)

	useEffect(() => {
		setTotalGraded(
			amountGraded ??
				classAssignment?.submission?.reduce((acc, val) => {
					if (val.state === Submission_State_Enum.Graded)
						return acc + (val?.submission_students_aggregate?.aggregate?.count || 0)

					return acc
				}, 0)
		)

		setTotalSubmitted(
			amountSubmitted ??
				classAssignment?.submission?.reduce((acc, val) => {
					return acc + (val?.submission_students_aggregate?.aggregate?.count || 0)
				}, 0)
		)
	}, [classAssignment])

	const goToAssignmentDetail = () => {
		history.push(
			buildRouteParameters(TeacherPages.ClassAssignmentDetail, {
				classId: classAssignment.class_id,
				classAssignmentId: classAssignment.class_assignment_id
			})
		)
	}

	const closeRescheduleDialog = () => setRescheduleDialogOpen(false)
	const openRescheduleDialog = () => setRescheduleDialogOpen(true)

	useEffect(() => {
		if (totalSubmitted === 0 && totalGraded === 0) {
			setGradeState(GradeStatus.NotStarted)
			setGradeTextClass(styles.notGraded)
		} else if (
			totalSubmitted === totalGraded &&
			classAssignment.status === Class_Assignment_Status_Enum.Graded
		) {
			setGradeState(GradeStatus.Graded)
			setGradeTextClass(styles.graded)
		} else if (totalGraded > 0) {
			setGradeState(GradeStatus.Started)
			setGradeTextClass(styles.startedGraded)
		} else {
			setGradeState(GradeStatus.NotStarted)
			setGradeTextClass(styles.notGraded)
		}
	}, [totalSubmitted, totalGraded, classAssignment])

	return (
		<ListItem className={clsx(styles.listItem, props?.className)}>
			<ListItemAvatar className={styles.avatar}>
				<GradedProgress val={totalGraded} maxVal={totalSubmitted} totalStudents={totalSubmitted} />
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={
					<Box>
						<Typography
							className={styles.itemTitle}
							variant="h6"
							data-cy={getCyKey(AssignmentCard, 'AssignmentName')}>
							<b>{title}</b>
						</Typography>
						{showInstructions && (
							<Typography noWrap className={styles.itemInstructions}>
								{parse(instructions)}
							</Typography>
						)}
						<Box display="flex" alignItems="center">
							<Datetime
								className={clsx({ [styles.itemDateExpired]: dueDateExpired(dueDate, dueTime) })}
								color="secondary"
								text={`${dayName}, ${dayNumber} ${month} - ${time}`}
							/>
						</Box>
					</Box>
				}
			/>
			<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
				{grade !== undefined && (
					<Box className={styles.grading}>
						<Typography variant="h6" className={styles.gradeLabel}>
							Grade
						</Typography>
						<GradeSquare grade={grade} />
					</Box>
				)}
				{showGradeState && (
					<Typography className={clsx(gradeTextClass, styles.gradeStatus)}>{gradeState}</Typography>
				)}
				{editButton && (
					<Tooltip title="Edit">
						<ActionButton icon={<EditIcon />} onClick={openRescheduleDialog} />
					</Tooltip>
				)}
				{viewButton && (
					<Tooltip title="View">
						<BaseButton
							data-cy={getCyKey(AssignmentCard, 'viewBtn')}
							color="primary"
							endIcon={<ArrowForwardIcon />}
							onClick={goToAssignmentDetail}>
							<Typography>View</Typography>
						</BaseButton>
					</Tooltip>
				)}
			</Box>
			<RescheduleDialog
				open={rescheduleDialogOpen}
				closeDialog={closeRescheduleDialog}
				assignmentDate={dueDate}
				assignmentTime={dueTime}
				refetch={refetch}
				assignmentId={classAssignment?.assignment?.assignment_id}
				classAssignmentId={classAssignment.class_assignment_id}
			/>
		</ListItem>
	)
}
