import { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useVideo, useAudio, useDebounce } from 'react-use'
import { StudioReviewLocationState } from 'src/@types'
import { useReviewAndSubmitContext } from 'src/components/Studio/Hooks/useReviewAndSubmit'
import { useCreateRoyaltyLogMutation } from 'src/graphql/autogenerate/hooks'
import { Screen_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'

import { buildGenericContext } from '../../../context/genericContext'

const useStudioReview = () => {
	const { songId } = useWurrlyParams<typeof StudentPages.StudioReview.params[number]>()
	const location = useLocation()

	const {
		audioUrl,
		setAudioUrl,
		videoUrl,
		setVideoUrl,
		trackId,
		setTrackId,
		isVideoOn,
		setIsVideoOn,
		selectedThumbnail,
		setSelectedThumbnail,
		audioBalance,
		setAudioBalance
	} = useReviewAndSubmitContext()

	const [videoElement, videoState, videoControls, videoRef] = useVideo(<video src={videoUrl} />)
	const [audioElement, audioState, audioControls] = useAudio({ src: audioUrl })
	const [isBlankSong, setIsBlankSong] = useState(false)
	const [mediaPercentage, setMediaPercentage] = useState(0) // 0 - 100

	const [, setTrimSeconds] = useState([0, Number.MAX_SAFE_INTEGER])
	const [trimPercentage, setTrimPercentage] = useState([0, 100])

	const [isApplyingEffects, setIsApplyingEffects] = useState(false)
	const [audioStartTime, setAudioStartTime] = useState<Date>()
	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()
	const [shouldHandleRoyalty, setShouldHandleRoyalty] = useState(false)
	const [isScrubberVisible, setIsScrubberVisible] = useState(true)

	const {
		teacherData: { teacher_id },
		studentData: { student_id }
	} = useLoginContext()

	const toggleVideo = () => {
		setIsVideoOn((prev) => !prev)
	}

	const toggleMedia = () => {
		if (!videoState.paused) {
			pauseMedia()
		} else {
			playMedia()
		}
	}

	const pauseMedia = () => {
		videoControls.pause()
		audioControls.pause()
		setShouldHandleRoyalty(true)
	}
	const playMedia = () => {
		videoControls.play()
		audioControls.play()
		setAudioStartTime(new Date())
	}

	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const playSeconds = (audioPauseTime.getTime() - (audioStartTime ?? audioPauseTime).getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId: trackId ?? 0,
					songId: songId ?? 0,
					teacherId: teacher_id || null,
					studentId: student_id || null,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.VideoStudioReview
				}
			})
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
		setAudioStartTime(undefined)
	}

	const seekMedia = (percentage: number) => {
		const duration = isFinite(videoState.duration) ? videoState.duration : 1
		const newTime = (duration * percentage) / 100
		videoControls.seek(newTime)
		audioControls.seek(newTime)
	}

	const handleTrimValues = (newValues: number[]) => {
		const min = Math.min(...newValues)
		const max = Math.max(...newValues)

		const duration = isFinite(videoState.duration) ? videoState.duration : 1
		const minSeconds = (min * duration) / 100
		const maxSeconds = (max * duration) / 100
		setTrimSeconds([minSeconds, maxSeconds])
		setTrimPercentage([min, max])
	}

	const openScrubber = () => {
		setIsScrubberVisible(true)
		setIsApplyingEffects(false)
	}
	const closeScrubber = () => {
		setIsScrubberVisible(false)
	}
	const openEffects = () => {
		setIsApplyingEffects(true)
		setIsScrubberVisible(false)
	}
	const closeEffects = () => {
		setIsApplyingEffects(false)
	}
	const toggleScrubber = () => {
		if (isScrubberVisible) closeScrubber()
		else openScrubber()
	}
	const toggleEffects = () => {
		if (isApplyingEffects) closeEffects()
		else openEffects()
	}

	// Get params from the location.state
	useEffect(() => {
		if (!location.state) return

		const {
			audioUrl: audioLink,
			videoUrl: videoLink,
			trackId: trackIdState,
			isCameraOn,
			isBlankSong
		} = location.state as StudioReviewLocationState
		if (audioLink && videoLink && typeof trackIdState === 'number') {
			setAudioUrl(audioLink)
			setVideoUrl(videoLink)
			setTrackId(trackIdState)
			setIsVideoOn(isCameraOn)
			setIsBlankSong(!!isBlankSong)
		}
	}, [location])

	// Calculate mediaPercenteage for seeker position
	useEffect(() => {
		const percentage = (videoState.time / videoState.duration) * 100
		setMediaPercentage(percentage)
	}, [videoState])

	// Unstuck the video duration
	useEffect(() => {
		if (!isFinite(videoState.duration) && videoRef.current) {
			// Setting video to max value then back to start so browser parses the whole video and determines its
			// duration, else it shows duration=Infinite until it has played around half of the video
			videoRef.current.currentTime = Number.MAX_SAFE_INTEGER
			setTimeout(() => seekMedia(0.01), 100)
		}
	}, [videoState.duration])

	// Stop both media streams if one ends before the other
	useEffect(() => {
		const videoEndedFirst = videoState.time === videoState.duration && !audioState.paused
		const audioEndedFirst = audioState.time === audioState.duration && !videoState.paused

		if (videoEndedFirst || audioEndedFirst) {
			pauseMedia()
			seekMedia(0)
		}
	}, [videoState.time, audioState.time])

	// Adjust the media's volume based on the balance slider
	useEffect(() => {
		let voice = 1
		let song = 1

		if (audioBalance < 0) {
			song = 1 - (audioBalance * -1) / 100
		} else {
			voice = 1 - audioBalance / 100
		}
		videoControls.volume(voice)
		audioControls.volume(song)
	}, [audioBalance])

	// Debounced to prevent a duplicated royalty log call
	useDebounce(
		() => {
			if (shouldHandleRoyalty) {
				setShouldHandleRoyalty(false)
				handleRoyaltyLog()
			}
		},
		200,
		[shouldHandleRoyalty]
	)

	return {
		isMediaPlaying: !videoState.paused,
		audioUrl,
		videoUrl,
		toggleMedia,
		videoElement,
		videoState,
		audioElement,
		audioState,
		isVideoOn,
		toggleVideo,
		seekMedia,
		mediaPercentage,
		trimPercentage,
		handleTrimValues,
		songId,
		trackId,
		selectedThumbnail,
		setSelectedThumbnail,
		isApplyingEffects,
		setIsApplyingEffects,
		audioBalance,
		setAudioBalance,
		videoRef,
		isScrubberVisible,
		openScrubber,
		closeScrubber,
		openEffects,
		closeEffects,
		toggleEffects,
		toggleScrubber,
		isBlankSong
	}
}

export const [StudioReviewProvider, useStudioReviewContext] = buildGenericContext(useStudioReview)
