import { ChangeEvent, useEffect } from 'react'

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { ListAssignments } from 'src/components/listAssignments/ListAsignments'
import { SeeAllBtnTooltip } from 'src/components/SeeAllTooltip'
import { DateFilterEnum } from 'src/environment'
import { useAssignmentsContext } from 'src/scenes/Student/hooks/useAssignments'
import { AssignmentAccordion } from 'src/scenes/Student/scenes/2-Classes/components/AssignmentAccordion/AssignmentAccordion'

import useStyles from './ClassAssignmentsDetails.styles'

export type ClassAssignmentsDetailsProps = {
	classId: number
}
export const ClassAssignmentsDetails = ({ classId }: ClassAssignmentsDetailsProps) => {
	const styles = useStyles()
	const {
		setSelectedClassId,
		notSubmittedAssignments,
		submittedAssignments,
		loadingNotSubmittedAssignments,
		loadingSubmittedAssignments,
		submittedSort,
		notSubmittedSort,
		seeMoreSubmitted,
		seeMoreNotSubmitted,
		submittedAssignmentsCount,
		notSubmittedAssignmentsCount,
		submittedLimit,
		notSubmittedLimit,
		selectedDateFilter,
		loadingExpiredAssignments,
		expiredAssignmentsCount,
		expiredAssignments,
		expiredLimit,
		expiredSort,
		seeMoreExpired,
		setSelectedDateFilter
	} = useAssignmentsContext()

	useEffect(() => {
		setSelectedClassId(classId)
	}, [classId])

	const handleDateSelected = (
		e: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		setSelectedDateFilter(e.target.value as DateFilterEnum)
	}

	const optionsDateFilter = [
		{ name: 'Next 7 Days', value: DateFilterEnum.nextSevenDays },
		{ name: 'This Month', value: DateFilterEnum.thisMonth },
		{ name: 'Next 3 Months', value: DateFilterEnum.nextThreeMonths },
		{ name: 'Last 6 Months', value: DateFilterEnum.lastSixMonths },
		{ name: 'This Year', value: DateFilterEnum.thisYear },
		{ name: 'All Time', value: DateFilterEnum.allTime }
	]

	return (
		<Box>
			<Box className={styles.container}>
				<Select
					className={styles.dateSelect}
					options={optionsDateFilter}
					onChange={handleDateSelected}
					value={selectedDateFilter}
				/>
			</Box>

			<AssignmentAccordion
				countTitle="To Be Submitted"
				count={notSubmittedAssignmentsCount ?? 0}
				details={
					loadingNotSubmittedAssignments ? (
						<Box className={styles.loaderHolder}>
							<CircularProgress color="secondary" size={50} />
						</Box>
					) : !!notSubmittedAssignmentsCount ? (
						<Box my={4} mb={7}>
							<Box my={4} mb={7}>
								<ListAssignments isInStudentView spacing={1.5} data={notSubmittedAssignments} />
							</Box>
						</Box>
					) : (
						<Box my={4} mb={7}>
							<Typography variant="h6" color="textSecondary">
								This class doesn't have any assignment.
							</Typography>
						</Box>
					)
				}
				sort={notSubmittedSort}
				actions={
					<SeeAllBtnTooltip
						canFetchMore={notSubmittedLimit < (notSubmittedAssignmentsCount || 0)}
						name="submissions"
						onClick={seeMoreNotSubmitted}
					/>
				}
			/>
			<Divider className={styles.divider} />
			<AssignmentAccordion
				countTitle="Submitted"
				count={submittedAssignmentsCount ?? 0}
				details={
					loadingSubmittedAssignments ? (
						<Box className={styles.loaderHolder}>
							<CircularProgress color="secondary" size={50} />
						</Box>
					) : !!submittedAssignmentsCount ? (
						<Box my={4} mb={7}>
							<Box my={4} mb={7}>
								<ListAssignments isInStudentView spacing={1.5} data={submittedAssignments} />
							</Box>
						</Box>
					) : (
						<Box my={4} mb={7}>
							<Typography variant="h6" color="textSecondary">
								You haven’t submitted any Wurrly yet.
							</Typography>
							<Typography color="textSecondary">
								It's easy to submit a Wurrly, go to the assignment and record a Wurrly.
							</Typography>
						</Box>
					)
				}
				sort={submittedSort}
				actions={
					<SeeAllBtnTooltip
						canFetchMore={submittedLimit < (submittedAssignmentsCount || 0)}
						name="submissions"
						onClick={seeMoreSubmitted}
					/>
				}
			/>
			<AssignmentAccordion
				countTitle="Expired"
				count={expiredAssignmentsCount ?? 0}
				details={
					loadingExpiredAssignments ? (
						<Box className={styles.loaderHolder}>
							<CircularProgress color="secondary" size={50} />
						</Box>
					) : !!expiredAssignmentsCount ? (
						<Box my={4} mb={7}>
							<Box my={4} mb={7}>
								<ListAssignments isInStudentView spacing={1.5} data={expiredAssignments} expired />
							</Box>
						</Box>
					) : (
						<Box my={4} mb={7}>
							<Typography variant="h6" color="textSecondary">
								This class doesn't have any assignment.
							</Typography>
						</Box>
					)
				}
				sort={expiredSort}
				actions={
					<SeeAllBtnTooltip
						canFetchMore={expiredLimit < (expiredAssignmentsCount || 0)}
						name="expired"
						onClick={seeMoreExpired}
					/>
				}
			/>
		</Box>
	)
}
