import React from 'react'

import { Badge } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const StyledBadge = styled(Badge)({
	'& .MuiBadge-colorSecondary': {
		borderRadius: '5.25px',
		backgroundColor: '#FFF8E2',
		top: 4,
		right: 4
	}
})

type NotificationBadgeProps = {
	count: number
	children?: React.ReactNode
}
export const NotificationBadge = ({ count, children }: NotificationBadgeProps) => {
	return (
		<StyledBadge color="secondary" badgeContent={<strong>{count}</strong>} invisible={count === 0}>
			{children}
		</StyledBadge>
	)
}
