import React, { Fragment, useState } from 'react'

import { Box, BoxProps, Popover, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'

import { capitalize } from '../../utils'

const useStyles = makeStyles((theme) =>
	createStyles({
		popover: {},
		paper: {
			padding: theme.spacing(2),
			maxWidth: '400px'
		}
	})
)

export type InputHeaderProps = BoxProps & {
	name?: string
	isRequired?: boolean
	popOverLabel?: JSX.Element
	endAdornment?: JSX.Element
	customMargin?: string
}

export const InputHeader = ({
	className,
	name,
	isRequired = false,
	popOverLabel,
	endAdornment,
	customMargin
}: InputHeaderProps) => {
	const styles = useStyles()
	const [anchorEl, setAnchorEl] = useState<EventTarget & SVGSVGElement>()
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined) => {
		setAnchorEl(event?.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(undefined)
	}

	return (
		<Fragment>
			{name && (
				<Box className={className} marginTop={customMargin ? customMargin : '40px'}>
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>
								{capitalize(name)}
								{isRequired ? '*' : ''}
							</b>
						</Typography>
						{popOverLabel && (
							<Box display="flex" alignItems="center">
								<HelpIcon
									aria-owns={
										anchorEl?.id === 'songCatalogType' ? 'mouse-over-popover' : undefined
									}
									aria-haspopup="true"
									id="songCatalogType"
									style={{ marginLeft: '5px' }}
									onMouseEnter={(e) => handlePopoverOpen(e)}
									fontSize="small"
									color="secondary"
								/>
							</Box>
						)}
						{endAdornment && (
							<Box display="flex" alignItems="center">
								{endAdornment}
							</Box>
						)}
					</Box>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						className={styles.popover}
						classes={{
							paper: styles.paper
						}}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						onClose={handlePopoverClose}
						disableRestoreFocus>
						<Typography>{popOverLabel}</Typography>
					</Popover>
				</Box>
			)}
		</Fragment>
	)
}
