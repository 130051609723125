import React from 'react'

import { Box, Typography, Grid } from '@material-ui/core'
import moment from 'moment'
import { Bubble } from 'src/assets/images'
import { BaseDialog } from 'src/components'
import { GetBadgesQuery, GetStudentBadgesQuery } from 'src/graphql/autogenerate/operations'
import {
	StyledBadge,
	StyledFooter,
	StyledGrid
} from 'src/scenes/Student/1-Home/components/BadgeInfo/BadgeInfo.styles'
import { buildImagePath } from 'src/utils'

import useStyles from './BadgeInfo.styles'

type HowToEarnBadgesProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	badgeInfo: studentBadgeType | undefined
}

type studentBadgeType = BadgeType & StudentBadgeType

type BadgeType = GetBadgesQuery['badge'][0]
type StudentBadgeType = GetStudentBadgesQuery['student_badge_resume'][0]

export const BadgeInfo = ({ isOpen, setIsOpen, badgeInfo }: HowToEarnBadgesProps) => {
	const styles = useStyles({ color: badgeInfo?.color ? badgeInfo?.color : '' })
	const closeModal = () => setIsOpen(false)

	return (
		<BaseDialog
			confirmLabel="Got it!"
			onConfirm={closeModal}
			onConfirmProps={{ style: { width: '396px' } }}
			open={isOpen}
			onClose={closeModal}
			dividers={false}
			body={
				<Box>
					<StyledGrid container spacing={1}>
						<Grid item xs={9}>
							<StyledBadge>
								<Box className={styles.imageContainer}>
									<img
										className={styles.badge}
										src={buildImagePath(badgeInfo?.icon_plain)}
										alt={`${badgeInfo?.name} badge`}></img>
									{!!badgeInfo?.total_badges && (
										<Box>
											<img
												src={Bubble}
												alt={`${badgeInfo.name} badge`}
												className={styles.image}
											/>
											<Typography className={styles.badgeCount}>
												{badgeInfo.total_badges}
											</Typography>
										</Box>
									)}
								</Box>
								{badgeInfo?.total_badges && (
									<Typography className={styles.text}>
										<strong>Congratulations!</strong>
									</Typography>
								)}
								<Typography variant="body2">
									{!badgeInfo?.total_badges
										? `Earn this badge and grab ${badgeInfo?.points} extra points by ${badgeInfo?.description}!`
										: `You earned the ${badgeInfo?.name} badge and earned ${badgeInfo?.points} extra points by ${badgeInfo?.description}!`}
								</Typography>
							</StyledBadge>
						</Grid>
					</StyledGrid>
					<StyledFooter>
						{badgeInfo?.total_badges > 0 && (
							<Typography>
								<strong>
									Last awarded {moment(badgeInfo?.last_awarded).format('MM/DD/YYYY')}
								</strong>
							</Typography>
						)}
					</StyledFooter>
				</Box>
			}
			paperProps={{ style: { width: '477px' } }}
			bodyProps={{ style: { justifyContent: 'center' } }}
		/>
	)
}
