import { Box, Typography } from '@material-ui/core'
import { SelectedEventType } from 'src/@types'
import { AccordionWrapper, SelectSort } from 'src/components'
import { SortOrder } from 'src/utils'

type MyFilesAccordionProps = {
	title: string
	count: number
	details: React.ReactNode
	sort?: SortOrder
	handleSort?: (event: SelectedEventType) => void
}
export const MyFilesAccordion = ({ title, count, details, sort, handleSort }: MyFilesAccordionProps) => {
	return (
		<Box marginTop={4}>
			<AccordionWrapper
				defaultExpanded
				summary={
					<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>
								{title} ({count})
							</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />

						{count !== 0 && sort !== undefined && !!handleSort && (
							<SelectSort showRecent value={sort} onChange={handleSort} />
						)}
					</Box>
				}
				details={details}
			/>
		</Box>
	)
}
