import { useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useHistory } from 'react-router-dom'
import { Page, PagesProps } from 'src/@types'

import { InfoDialog, MainTitle } from '../../../../../../components'
import { useScroll } from '../../../../../../hooks/useScroll'
import { Pages } from '../../../../../../routes/teacherPages'
import { buildRouteParameters } from '../../../../../../utils/routes-utils'
import { ClassForm } from './ClassForm'

type Props = PagesProps

export const ClassCreation = ({ page }: Props) => {
	useScroll()
	const history = useHistory()
	const parentPage: Page = Pages[(page.parentName ?? '') as keyof typeof Pages]
	const breadcrumbs = [parentPage, page]

	const [onCancel, setOnCancel] = useState(false)
	const [onSave, setOnSave] = useState(false)

	const [selectedClass, setSelectedClass] = useState<number | null>(0)

	const goBack = () => {
		history.push({ pathname: Pages.Classes.path })
	}

	return (
		<Box>
			<MainTitle
				// data-cy={getCyKey(ClassCreation, 'classCreationForm2')}
				breadcrumbs={breadcrumbs}
				title="Create your class"
				description={
					<Typography style={{ fontSize: 18, maxWidth: 500 }}>
						Please enter the details of your Class below.
					</Typography>
				}
			/>

			<ClassForm
				// data-cy={getCyKey(ClassCreation, 'classCreationForm3')}
				page={page}
				teacherId={1}
				handleCancel={() => setOnCancel(true)}
				handleAfterSave={(classId) => {
					setOnSave(true)
					setSelectedClass(classId)
				}}
			/>

			<InfoDialog
				// data-cy={getCyKey(ClassCreation, 'infoDialog')}
				open={onCancel}
				onClose={() => setOnCancel(false)}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={() => {
					setOnCancel(false)
					goBack()
				}}
				confirmLabel="No, Cancel"
				onConfirm={() => {
					setOnCancel(false)
				}}
				icon="!"
			/>
			<InfoDialog
				open={onSave}
				onClose={() => {}}
				title="Your Class has been Created!"
				body="You have successfully created this class."
				discardLabel="Back to Classes"
				onDiscard={() => {
					setOnSave(false)
					goBack()
				}}
				confirmLabel="View Class"
				confirmProps={{ color: 'secondary' }}
				onConfirm={() => {
					setOnSave(false)
					if (selectedClass)
						history.push(
							buildRouteParameters(Pages.ClassDetail, {
								classId: selectedClass
							})
						)
				}}
				icon={<CheckIcon />}
			/>
		</Box>
	)
}
