import { ReactNode, useState } from 'react'

import { Box, Tab, Tabs, Typography, BoxProps, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { Balance } from 'src/components/Studio/Review/effects/Balance'
import { FrameFilter } from 'src/components/Studio/Review/effects/FrameFilter/FrameFitler'
import { StickerFilter } from 'src/components/Studio/Review/effects/StickerFilter/StickerFilter'
import { MAX_STICKERS } from 'src/utils/constants'

import { ColorFilter } from '../ColorFilter/ColorFilter'
import useStyles from './EffectsTabs.styles'
type EffectTab = {
	title: string
	component: ReactNode
}

type EffectsTabsProps = BoxProps & {
	hideBalance?: boolean
	stickersSelected?: number
	hideTab?: () => void
}

export const EffectsTabs = ({ hideBalance, stickersSelected, hideTab, ...props }: EffectsTabsProps) => {
	const [currentTab, setCurrentTab] = useState(0)

	const styles = useStyles()

	const handleChangeTab = (_: React.ChangeEvent<unknown>, newValue: number) => {
		setCurrentTab(newValue)
	}
	const Effects: EffectTab[] = [
		{
			title: 'Filters',
			component: <ColorFilter />
		},
		{ title: 'Frames', component: <FrameFilter /> },
		{
			title: 'Stickers',
			component: <StickerFilter maxAmountReached={stickersSelected === MAX_STICKERS} />
		},
		...(hideBalance ? [] : [{ title: 'Balance', component: <Balance /> }])
	]

	return (
		<Box {...props} className={clsx(styles.tabsContainer, props.className)}>
			<Tabs className={styles.tabs} value={currentTab} onChange={handleChangeTab}>
				{Effects.map((effect, i) => (
					<Tab
						key={`tab_${i}`}
						label={
							<Typography color={currentTab === i ? 'primary' : undefined}>
								<strong className={styles.tabName}>{effect.title}</strong>
							</Typography>
						}
					/>
				))}
			</Tabs>
			{hideTab && (
				<IconButton className={styles.closeButton} onClick={hideTab}>
					<CloseIcon fontSize="small" />
				</IconButton>
			)}
			<Box className={styles.effectContainer}>{Effects[currentTab].component}</Box>
		</Box>
	)
}
