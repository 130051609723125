import { useState, useEffect } from 'react'

import { useGetLessonByPkForAddAssignmentQuery } from 'src/graphql/autogenerate/hooks'

import { AssignmentFormDataType } from '../AddAssignment/AddAssingment.types'
export const useLessonForAddAssignment = (lessonPlanId: number, classId: number) => {
	const [lesson, setLesson] = useState<AssignmentFormDataType>()
	const { data, loading, error } = useGetLessonByPkForAddAssignmentQuery({
		variables: { lessonPlanId, classId }
	})

	useEffect(() => {
		if (data && !loading && !error) {
			const lessonPlan = data.lesson_plan_by_pk
			const classes = data?.class ?? []
			const songs = lessonPlan?.lesson_plan_song.map((item) => item.song) ?? []
			const videos = lessonPlan?.lesson_plan_tip.map((item) => item.tip) ?? []
			const assets = lessonPlan?.lesson_plan_asset.map((item) => item.asset) ?? []

			const html = lessonPlan?.suggested_assignments?.length
				? lessonPlan?.suggested_assignments
				: lessonPlan?.suggested_assignments_from_articles?.length
				? lessonPlan.suggested_assignments_from_articles[0].content
				: ''
			const div = document.createElement('div')
			div.innerHTML = html
			const instructions = div.textContent || div.innerText || ''

			const lessonData = {
				name: lessonPlan?.title ?? '',
				instructions,
				classes,
				songs,
				videos,
				assets,
				reference_lesson_plan_id: lessonPlan?.lesson_plan_id || null
			}
			setLesson(lessonData as AssignmentFormDataType)
		}
	}, [data, loading, error])

	return { lesson, loading, error }
}
