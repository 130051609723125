import { SyntheticEvent, useEffect, useRef, useState, ReactNode, Dispatch, SetStateAction } from 'react'

import { Button, ButtonProps, Menu, MenuItem, Typography, Tooltip, Input, Box } from '@material-ui/core'
import clsx from 'clsx'
import { stopEventPropagation } from 'src/utils'

import useStyles from './MenuButton.styles'
export type MenuButtonProps = {
	label: React.ReactNode
	inputProps?: {
		menuLabel?: string
		updateText: (text: string) => void
	}
	children:
		| ReactNode
		| ((context: {
				anchor: HTMLElement | null
				setAnchor: Dispatch<SetStateAction<HTMLElement | null>>
		  }) => ReactNode)
} & Omit<ButtonProps, 'children'>

export const MenuButton = ({ label, inputProps, children, key, ...props }: MenuButtonProps) => {
	const styles = useStyles()
	const [text, setText] = useState(label)
	const [isInput, setIsInput] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const inputRef = useRef<HTMLInputElement | null>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		if (!isInput) setAnchorEl(event.currentTarget)
	}

	const handleClose = () => setAnchorEl(null)
	const handleBlur = () => {
		if (isInput) setIsInput(!isInput)
		if (typeof text === 'string') inputProps?.updateText(text)
		handleClose()
	}

	const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setText?.(event?.target?.value.replaceAll(' ', ''))

	const handleEditClick = () => {
		setIsInput(true)
		handleClose()
	}

	const handleEnterPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setIsInput(false)
			handleClose()
			if (typeof text === 'string') inputProps?.updateText(text)
		}
	}

	useEffect(() => {
		if (isInput) inputRef.current?.focus()
	}, [isInput])

	useEffect(() => {
		if (label) setText(label)
	}, [label])

	return (
		<Box key={key} className={styles.buttonHolder}>
			<Button
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				{...props}
				className={clsx(styles.button, props.className)}>
				{!isInput && (
					<Tooltip title={text || ''}>
						<span>
							{typeof text !== 'string' && text}
							{typeof text === 'string' && <Typography className={styles.label}> {text}</Typography>}
						</span>
					</Tooltip>
				)}
				{isInput && (
					<Input
						className={clsx(styles.label, styles.input)}
						onBlur={handleBlur}
						value={text}
						onChange={handleTextChange}
						onKeyDown={handleEnterPressed}
						inputRef={inputRef}
					/>
				)}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={(event: SyntheticEvent) => {
					event.stopPropagation()
					handleClose()
				}}
				PaperProps={{
					onClick: stopEventPropagation
				}}
				role="listbox"
				variant="menu">
				{inputProps?.updateText && (
					<MenuItem onClick={handleEditClick}>{inputProps.menuLabel || 'Edit'}</MenuItem>
				)}
				{typeof children === 'function'
					? children?.({ anchor: anchorEl, setAnchor: setAnchorEl })
					: children}
			</Menu>
		</Box>
	)
}
