import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { useBillingInfo } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useBillingInfo'

import useStyles from './BillingInfo.styles'
export const BillingInfo = () => {
	const { goToViewBillinDetails } = useBillingInfo()

	return (
		<ProfileSection
			title="Billing Info"
			body={<BillingInfoContent></BillingInfoContent>}
			editBtn={{
				label: 'View Billing Details',
				action: goToViewBillinDetails
			}}
		/>
	)
}

const BillingInfoContent = () => {
	const styles = useStyles()
	const {
		subscriptionsAnnualPrice,
		subscriptionsMonthlyPrice,
		teacherLicenseAnnualPrice,
		teacherLicenseMonthlyPrice,
		totalAnnualPrice,
		totalMonthlyPrice
	} = useBillingInfo()

	return (
		<>
			<Box className={styles.container} style={{ marginBottom: '3em' }}>
				<Grid container className={styles.gridContainer}>
					<Grid container>
						<Grid item xs={4}></Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>Monthly</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>Annual</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>
							<Typography className={styles.text}>Teacher License</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>${teacherLicenseMonthlyPrice}</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>${teacherLicenseAnnualPrice}</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={4}>
							<Typography className={styles.text}>Partners & Modules Subscriptions</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>${subscriptionsMonthlyPrice}</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>${subscriptionsAnnualPrice}</Typography>
						</Grid>
					</Grid>
					<Grid container>
						<Divider className={styles.divider} variant="middle" />
					</Grid>
					<Grid container>
						<Grid item xs={4}>
							<Typography className={styles.text}>Total Recurring Payment</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>${totalMonthlyPrice}</Typography>
						</Grid>
						<Grid item xs={4} className={styles.price}>
							<Typography className={styles.text}>${totalAnnualPrice}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
