import { MAX_SONGS } from '../StepTwoForm/utils'

export enum Song_Option {
	Select,
	WholeCatalog
}
export const SongOptions = [
	{ title: 'Select songs', subtitle: `Up to ${MAX_SONGS}`, iconName: 'MusicNote', option: Song_Option.Select },
	{ title: 'All songs', subtitle: 'Whole Catalog', iconName: 'QueueMusic', option: Song_Option.WholeCatalog }
]
