import { useState, useEffect } from 'react'

import MomentUtils from '@date-io/moment'
import { Divider, MenuItem, Box } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment, { Moment } from 'moment'
import { DateRange } from 'src/@types'
import { Select } from 'src/components'
import DateInput from 'src/components/Inputs/DateInput'
import { DateFilterEnum } from 'src/environment'
import { getComparisonDate } from 'src/utils'

import useStyles from './SelectDateRange.styles'
import { CustomDateRangeOption, DateRangeOptions } from './types'
type SelectDateRangeProps = {
	selectedDateRange: DateRange
	setSelectedDateRange: (dateRange: DateRange) => void
	defaultOption?: DateFilterEnum
	maxDate?: Date
}
export const SelectDateRange = ({
	selectedDateRange,
	setSelectedDateRange,
	defaultOption = DateFilterEnum.thisWeek,
	maxDate
}: SelectDateRangeProps) => {
	const [selectedDateOption, setSelectedDateOption] = useState<DateRangeOptions>(defaultOption)
	const styles = useStyles()
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedDateOption(event.target.value as DateRangeOptions)
	}

	const handleFromDateChange = (date: unknown) => {
		setSelectedDateRange({ fromDate: (date as Moment).toDate(), toDate: selectedDateRange.toDate })
	}
	const handleToDateChange = (date: unknown) => {
		setSelectedDateRange({ fromDate: selectedDateRange.fromDate, toDate: (date as Moment).toDate() })
	}

	useEffect(() => {
		if (selectedDateOption !== CustomDateRangeOption.customDateRange) {
			const dateRange = getComparisonDate(defaultOption)
			setSelectedDateRange(dateRange)
		}
	}, [])

	useEffect(() => {
		if (selectedDateOption !== CustomDateRangeOption.customDateRange) {
			const dateRange = getComparisonDate(selectedDateOption)
			setSelectedDateRange(dateRange)
		}
	}, [selectedDateOption])

	return (
		<Box className={styles.container}>
			<Select
				variant="outlined"
				color="secondary"
				value={selectedDateOption}
				onChange={handleChange}
				className={styles.select}>
				<MenuItem value={DateFilterEnum.lastSevenDays}>Last 7 Days</MenuItem>
				<MenuItem value={DateFilterEnum.thisMonth}>This Month</MenuItem>
				<MenuItem value={DateFilterEnum.lastSixMonths}>Last 6 Months</MenuItem>
				<MenuItem value={DateFilterEnum.thisYear}>This Year</MenuItem>
				<MenuItem value={CustomDateRangeOption.customDateRange}>Custom Date Range</MenuItem>
			</Select>

			{selectedDateOption === CustomDateRangeOption.customDateRange && (
				<>
					<Divider orientation="vertical" className={styles.divider} />
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<DateInput
							name="From Date"
							label="From Date"
							value={selectedDateRange.fromDate}
							onChange={handleFromDateChange}
							format="MMM DD, YYYY"
							disableFuture
							disablePast={false}
							className={styles.datePicker}
							maxDate={selectedDateRange.toDate}
						/>
						<DateInput
							name="To Date"
							label="To Date"
							value={selectedDateRange.toDate}
							onChange={handleToDateChange}
							format="MMM DD, YYYY"
							disableFuture
							disablePast={false}
							className={styles.datePicker}
							minDate={selectedDateRange.fromDate}
							maxDate={maxDate || moment(selectedDateRange.fromDate).add(1, 'year').toDate()}
						/>
					</MuiPickersUtilsProvider>
				</>
			)}
		</Box>
	)
}
