import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { MainTitle } from 'src/components'
import { useLoginContext } from 'src/hooks/useLogin'

export const useStyles = makeStyles((theme) =>
	createStyles({
		bgContainer: {
			backgroundColor: '#F4F4F4',
			backgroundSize: 'contain'
		},
		description: {
			overflowWrap: 'normal',
			color: theme.palette.secondary.contrastText,
			opacity: '80%'
		},
		box: {
			paddingTop: '5em',
			paddingBottom: '3em'
		}
	})
)

export const Hero = (props: PagesProps) => {
	const classes = useStyles()

	return (
		<section>
			<div className={classes.bgContainer}>
				<Box p={{ xs: 3, sm: 10, md: 15.5 }} className={classes.box}>
					<MainTitle
						breadcrumbs={[{ ...props.page, name: 'Dashboard' }]}
						title={<WelcomeText />}
						description={<HeroDescription />}
						rightActions={[]}
						titleMargin={1}
					/>
				</Box>
			</div>
		</section>
	)
}

const HeroDescription = () => {
	const classes = useStyles()

	return (
		<Typography variant="body1" className={classes.description}>
			Get a snapshot of everything you need to know.
			<br /> Browse content, engage with students and check insights!
		</Typography>
	)
}

const WelcomeText = () => {
	const { teacherData: teacher } = useLoginContext()

	return (
		<>
			<Typography variant="h3">
				Welcome,
				<strong>{teacher.name_first ?? 'Teacher'}</strong>
			</Typography>
		</>
	)
}
