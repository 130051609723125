import { Chord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/types'

import {
	DIAGRAM_SVG_FILL_COLOR,
	DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT,
	guitarChords as GUITAR_CHORDS
} from './constants'
import { remapChord } from './utils'

const frets = [0, 1, 2, 3, 4, 5]
const fretLabelX = 94
const xPositions = [1, 18, 34.5, 50, 66, 82]
const yPositions = [7.09, 19.5, 35.5, 51.5, 67.5, 83.5]
const fretLabels = [
	'',
	'',
	'',
	'III',
	'',
	'V',
	'',
	'VII',
	'',
	'IX',
	'',
	'',
	'XII',
	'',
	'',
	'XV',
	'',
	'XVII',
	'',
	'XIX',
	'',
	'XXI',
	''
]
const baseTextStyle = { fontFamily: 'Arial', fontSize: '9px' }

type GuitarDiagramHooksType = {
	chord: Chord
	isHighlighted?: boolean
}
export const GuitarDiagram = ({ chord: currentChord, isHighlighted = false }: GuitarDiagramHooksType) => {
	const fillColor = isHighlighted ? DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT : DIAGRAM_SVG_FILL_COLOR

	const renderIndicators = () => {
		const chord = remapChord(currentChord)
		if (!currentChord?.name || !chord) return
		const chordData = GUITAR_CHORDS[chord?.name]

		const elements: JSX.Element[] = []
		chordData?.strings?.forEach((s, index) => {
			const xPos = xPositions[index]

			elements.push(
				<text
					key={'state-' + index}
					x={xPos}
					y="6"
					stroke="none"
					fill={fillColor}
					textAnchor="middle"
					style={baseTextStyle}>
					{s.state === 2 ? 'x' : s.state === 0 ? '' : 'o'}
				</text>
			)
		})

		chordData?.strings?.forEach((s, index) => {
			if (s.state === 0) {
				const fret = s?.position?.fret
				if (!fret) return
				const xPos = xPositions[index]
				const yPos = yPositions[fret - (chordData?.baseFret || 0)]

				elements.push(
					<circle
						key={'position-' + index}
						cx={xPos}
						cy={yPos}
						r="6"
						fill={fillColor}
						stroke={fillColor}
					/>
				)

				if (s?.position?.finger) {
					elements.push(
						<text
							key={'finger-position-' + index}
							x={xPos - 0.2}
							y={yPos + 3.1}
							textAnchor="middle"
							stroke="none"
							fill="#ffffff"
							style={baseTextStyle}>
							{' '}
							{s.position.finger}{' '}
						</text>
					)
				}
			}
		})

		frets?.forEach((f, index) => {
			const fret = f + (chordData?.baseFret || 0)
			const yPos = yPositions[f]

			let fretLabel = fretLabels[fret]

			if (index === 5 && fretLabel === 'V') {
				fretLabel = ''
			}

			if (fretLabel !== '') {
				elements.push(
					<text
						key={'fret-' + index}
						x={fretLabelX}
						y={yPos + 3.1}
						textAnchor="middle"
						stroke="none"
						fill={fillColor}
						style={{
							fontWeight: 'bold',
							fontFamily: 'Arial',
							fontSize: '10px'
						}}>
						{' '}
						{fretLabel}{' '}
					</text>
				)
			}
		})

		return elements
	}

	const renderFretBoard = () => {
		const width = 80.49

		return (
			<g>
				<rect fill="#ffffff" x="1" y="11" width="81.49" height="80.49" />

				<rect fill={fillColor} x="0" y="9" width="83" height="3" />

				<rect fill={fillColor} x="1" y="11" width={width} height="1" />

				<rect fill={fillColor} x="1" y="27" width={width} height="1" />

				<rect fill={fillColor} x="1" y="43" width={width} height="1" />

				<rect fill={fillColor} x="1" y="59" width={width} height="1" />

				<rect fill={fillColor} x="1" y="75" width={width} height="1" />

				<rect fill={fillColor} x="0.49" y="91" width={82} height="1" />

				<rect fill={fillColor} x=".5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="17.5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="34.5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="49.5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="65.5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="81.5" y="11" width="1" height="80" />
			</g>
		)
	}

	const indicatorContent = currentChord != null ? <g style={{ opacity: 1 }}>{renderIndicators()}</g> : null

	const boardContent = renderFretBoard()

	return (
		<div className="chord-diagram-container guitar">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="-7 0 108 100"
				className={isHighlighted ? 'highlight-chord' : ''}>
				{boardContent}
				{indicatorContent}
			</svg>
		</div>
	)
}
