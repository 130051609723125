import { Box, BoxProps, Typography } from '@material-ui/core'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import clsx from 'clsx'

import useStyles from './MessageBox.styles'

type MessageBoxProps = BoxProps & {
	text: string
}
export const MessageBox = ({ text, ...props }: MessageBoxProps) => {
	const styles = useStyles()

	return (
		<Box {...props} className={clsx(styles.messageBox, props.className)}>
			<ChatBubbleIcon className={styles.chatIcon} />
			<Typography className={styles.text}>{text}</Typography>
			{props.children}
		</Box>
	)
}
