import { Box, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import clsx from 'clsx'

import { InputHeader, InputHeaderProps } from './InputHeader'

const useStyles = makeStyles(() => ({
	inputDefault: {
		width: '100%',
		padding: '10px 0'
	}
}))

type Props = { width?: string | number; customMargin?: string; headerLabel?: string } & InputHeaderProps &
	TextFieldProps

export const TextInput = ({
	id,
	name,
	isRequired,
	customMargin,
	headerLabel,
	width = '100%',
	...props
}: Props) => {
	const styles = useStyles()

	return (
		<Box width={width}>
			<InputHeader name={headerLabel ?? name} isRequired={isRequired} customMargin={customMargin} />
			<TextField
				{...props}
				id={id}
				name={name}
				color="secondary"
				variant="outlined"
				className={clsx(styles.inputDefault, props.className)}
			/>
		</Box>
	)
}
