import { useEffect, useState } from 'react'

import { Avatar, Box, Typography } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import moment from 'moment'
import { GradeSquare } from 'src/components/Squares/GradeSquare/GradeSquare'
import { Grading_System } from 'src/graphql/autogenerate/schemas'
import { formatSubmissionDate, dueDateExpired } from 'src/utils/utils'

import useStyles from './SubmissionCard.styles'

type SubmissionCardProps = {
	avatarUrl: string
	submitterName: string
	submissionDate: string
	grade?: string
	message?: string
	dueDateString?: string
	gradingSystem: Pick<Grading_System, 'label' | 'values'> | undefined
	teacherComment: string
}

export const SubmissionCard = ({
	avatarUrl,
	submitterName,
	submissionDate,
	grade,
	message,
	dueDateString,
	gradingSystem,
	teacherComment
}: SubmissionCardProps) => {
	const [submittedLate, setSubmittedLate] = useState(false)
	const [formattedSubmissionDate, setFormattedSubmissionDate] = useState('')
	const styles = useStyles({ lateSubmission: submittedLate })

	useEffect(() => {
		const formattedDate = formatSubmissionDate({ fullDateString: submissionDate })
		setFormattedSubmissionDate(formattedDate)

		if (dueDateString) {
			const dueDate = dueDateString.split('T')[0]
			const dueTime = dueDateString.split('T')[1]
			const lateSubmission = dueDateExpired(dueDate, dueTime, new Date(submissionDate))
			setSubmittedLate(lateSubmission)
		}
	}, [submissionDate, dueDateString])

	return (
		<Box className={styles.container}>
			<Avatar src={avatarUrl} className={styles.avatar} />
			<Box className={styles.content}>
				<Typography variant="h6" className={styles.name} data-testid="submitter-name">
					{submitterName}
				</Typography>
				<Box className={styles.date} data-testid="submission-date">
					<AccessTime fontSize="inherit" className={styles.clockIcon} />
					<Box className={styles.dateContent}>
						<Typography>{formattedSubmissionDate}</Typography>
						{submittedLate && <Typography>({moment(submissionDate).fromNow()})</Typography>}
					</Box>
				</Box>
				{message && (
					<Typography variant="body1" className={styles.message} data-testid="message">
						{message}
					</Typography>
				)}
			</Box>
			<Box className={styles.grading}>
				<Typography variant="h6" className={styles.gradeLabel}>
					{gradingSystem?.label === 'None' ? 'Reviewed' : 'Grade'}
				</Typography>
				<GradeSquare grade={grade} gradingSystem={gradingSystem} teacherComment={teacherComment} />
			</Box>
		</Box>
	)
}
