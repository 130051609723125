import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { Page } from 'src/@types'
import { Privacy_Enum, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { IndieWurrlyPage } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/components/IndieWurrlyPage'
import {
	ClassWurrlyProvider,
	useClassWurrlyContext
} from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'

export const ClassWurrlyContent = ({ page }: { page: Page }) => {
	const params = useWurrlyParams<typeof StudentPages.ClassWurrly.params[number]>()
	const { setClassId, setWurrlyId, student, wurrlyType, wurrlyVisibility } = useClassWurrlyContext()
	const { studentData } = useLoginContext()
	const [showVisibility, setShowVisibility] = useState(false)
	const [showGrade, setShowGrade] = useState(false)
	const [showReact, setShowReact] = useState(false)

	// Set classId and wurrlyId in context
	useEffect(() => {
		if (params.classId && params.wurrlyId) {
			setClassId(params.classId)
			setWurrlyId(params.wurrlyId)
		}
	}, [params.classId, params.wurrlyId])

	useEffect(() => {
		if (studentData && student && wurrlyType && wurrlyVisibility)
			if (
				studentData.student_id === student?.student_id &&
				(wurrlyType === Wurrly_Type_Enum.Indie || wurrlyType === Wurrly_Type_Enum.Challenge)
			) {
				setShowVisibility(true)
				setShowGrade(false)
				setShowReact(false)
			} else if (
				(wurrlyType === Wurrly_Type_Enum.Assignment || wurrlyType === Wurrly_Type_Enum.Challenge) &&
				wurrlyVisibility !== Privacy_Enum.Class
			) {
				setShowVisibility(false)
				setShowGrade(true)
				setShowReact(false)
			} else if (studentData.student_id !== student?.student_id && wurrlyVisibility === Privacy_Enum.Class) {
				setShowVisibility(false)
				setShowGrade(false)
				setShowReact(true)
			} else if (
				studentData.student_id === student?.student_id &&
				wurrlyVisibility === Privacy_Enum.Class &&
				wurrlyType === Wurrly_Type_Enum.Assignment
			) {
				setShowVisibility(false)
				setShowGrade(true)
				setShowReact(true)
			}
	}, [studentData, student, wurrlyType, wurrlyVisibility])

	return (
		<Box>
			<IndieWurrlyPage visibility={showVisibility} grade={showGrade} showReact={showReact} {...page} />
		</Box>
	)
}

export const ClassWurrly = ({ page }: { page: Page }) => {
	return (
		<ClassWurrlyProvider>
			<ClassWurrlyContent page={page} />
		</ClassWurrlyProvider>
	)
}
