import { Avatar, Box, CardHeader, Divider, Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { ScheduleIcon } from 'src/assets/icons'
import { WurrlyPlayer, WurrlyToPlay } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import { useClassWurrlyContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'
import { buildImagePath } from 'src/utils'

import useStyles from './ClassWurrlyDetails.styles'

type ClassWurrlyDetailsProps = {
	footer: JSX.Element
	footerButtons: JSX.Element
	practice?: boolean
}
export const ClassWurrlyDetails = ({ footer, footerButtons, practice }: ClassWurrlyDetailsProps) => {
	const styles = useStyles()
	const { student, wurrly, processingStatus } = useClassWurrlyContext()

	return (
		<>
			<Box className={clsx({ [styles.containerBox]: !practice })}>
				<CardHeader
					className={styles.cardHeader}
					avatar={
						!practice ? (
							<Avatar className={styles.avatar} src={buildImagePath(student?.image_path)} />
						) : null
					}
					title={
						<Typography variant="h6" className={styles.title}>
							<b>
								{student?.first_name} {student?.last_name}
							</b>
						</Typography>
					}
					subheader={
						<Box>
							{practice && (
								<Typography variant="subtitle2" className={styles.practiceSubHeader}>
									Lorem ipsum
								</Typography>
							)}

							<Box display="flex" alignItems="center">
								{!practice && <ScheduleIcon className={styles.icon} />}
								<Typography
									variant="subtitle2"
									className={practice ? styles.subtitle : styles.practiceSubtitle}>
									{moment(wurrly?.created_at).format('ddd, DD MMM HH:mmA')}
								</Typography>
							</Box>
						</Box>
					}
				/>
				<Divider className={styles.divider} />
				<Typography variant="h6" className={styles.trackTitle}>
					{wurrly?.track?.song?.title}
				</Typography>
				<Typography variant="subtitle2" className={clsx(styles.subtitle, styles.artist)}>
					Made Famous By {wurrly?.track?.song?.artist?.name}
				</Typography>
				<WurrlyPlayer wurrly={wurrly as unknown as WurrlyToPlay} processingStatus={processingStatus} />
				{footer}
			</Box>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				className={styles.footerButtons}>
				{footerButtons}
			</Box>
		</>
	)
}
