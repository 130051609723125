import { Box, styled } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { LinkButton } from 'src/components/Buttons/LinkButton'
import { Pages } from 'src/routes/teacherPages'
import { useHomeClasses } from 'src/scenes/Teacher/scenes/1-Home/components/EngageStudents/useHomeClasses'
import { buildRouteParameters } from 'src/utils'

import { SectionHeader } from '../SectionHeader'
import { ClassCarousel } from './ClassCarousel'
import { EmptyStateCards } from './EmptyStateCards'

export const EngageStudents = () => {
	const { classes, setShouldFetchMore } = useHomeClasses()
	const history = useHistory()

	return (
		<section>
			<Row>
				<SectionHeader content="Engage With Your Students" />
				{!!classes.length && (
					<LinkButton
						label="View All"
						onClick={() => history.push(buildRouteParameters(Pages.Classes))}
					/>
				)}
			</Row>

			{classes.length ? (
				<ClassCarousel classes={classes} setShouldFetchMore={setShouldFetchMore} />
			) : (
				<EmptyStateCards />
			)}
		</section>
	)
}

const Row = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between'
})
