import { Dialog, DialogActions, DialogContent } from '@material-ui/core'

import useStyles from './StudioDialog.styles'

type StudioDialogProps = {
	body: React.ReactNode
	hasVideo?: boolean
	bottomActions: React.ReactNode
}
export const StudioDialog = ({ body, bottomActions, hasVideo }: StudioDialogProps) => {
	const styles = useStyles({ hasVideo })

	return (
		<Dialog className={styles.dialog} open>
			<DialogContent className={styles.body}>{body}</DialogContent>
			<DialogActions className={styles.bottomActions}>{bottomActions}</DialogActions>
		</Dialog>
	)
}
