import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useSelectStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.common.white,
			minWidth: '150px',
			'& .MuiOutlinedInput-input': {
				padding: '10px 14px'
			},
			'& .MuiSelect-icon': {
				fill: '#F3AB34'
			},
			'&  .MuiSelect-select:focus': {
				backgroundColor: 'inherit!important'
			}
		}
	})
)

export const optionStyles = {
	root: {
		'&:focus': {
			backgroundColor: 'rgba(243, 171, 52, 0.12)',
			color: '#F3AB34',
			'&:hover': {
				backgroundColor: 'rgba(243, 171, 52, 0.25)'
			}
		},
		'&:hover': {
			backgroundColor: 'rgba(243, 171, 52, 0.25)'
		}
	}
}

export default useSelectStyles
