import { useEffect, useState } from 'react'

import {
	useGetClassNameByPkLazyQuery,
	useGetLessonPlansByClassIdLazyQuery,
	useGetModulesByClassIdLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan, Module } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, buildSearchText } from 'src/utils'
export const useClassLessonsTitle = () => {
	const { classId } = useWurrlyParams()
	const [className, setClassName] = useState('')
	const [selectedLessons, setSelectedLessons] = useState<Lesson_Plan[]>([])
	const [selectedModules, setSelectedModules] = useState<Module[]>([])
	const [showLessonsDialog, setShowLessonsDialog] = useState(false)
	const [showModulesDialog, setShowModulesDialog] = useState(false)
	const [selectProductDialog, setSelectProductDialog] = useState(false)
	const [getSelectedLessons, { data: selectedLessonsData }] = useGetLessonPlansByClassIdLazyQuery()
	const [getSelectedModules, { data: selectedModulesData }] = useGetModulesByClassIdLazyQuery()

	const handleAddLessons = () => {
		setShowLessonsDialog(true)
		handleCancelSelectProductDialog()
	}

	const handleCancelLessons = () => {
		setShowLessonsDialog(false)
	}

	const handleAddModules = () => {
		setShowModulesDialog(true)
		handleCancelSelectProductDialog()
	}

	const handleCancelModules = () => {
		setShowModulesDialog(false)
	}

	const handleOpenSelectProductDialog = () => {
		setSelectProductDialog(true)
	}

	const handleCancelSelectProductDialog = () => {
		setSelectProductDialog(false)
	}

	const breadCrumbs = buildBreadCrumbs({
		page: Pages.ClassLessons,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			},
			{
				index: 2,
				overrideName: 'Lessons'
			}
		],
		params: { classId: Number(classId) }
	})

	useEffect(() => {
		if (selectedLessonsData)
			setSelectedLessons(selectedLessonsData.lesson_plan.map((item) => item) as Lesson_Plan[])
	}, [selectedLessonsData])

	useEffect(() => {
		if (selectedModulesData) setSelectedModules(selectedModulesData.module.map((item) => item) as Module[])
	}, [selectedModulesData])

	const [getClass, { data }] = useGetClassNameByPkLazyQuery()

	useEffect(() => {
		getClass({
			variables: {
				classId: Number(classId)
			}
		})
		getSelectedLessons({
			variables: {
				classId: Number(classId),
				search: buildSearchText('', ['title', 'description'])
			}
		})
		getSelectedModules({
			variables: {
				classId: Number(classId),
				search: buildSearchText('', ['title', 'description'])
			}
		})
	}, [])

	useEffect(() => {
		if (data) {
			setClassName(data.class_by_pk?.title || '')
		}
	}, [data])

	return {
		breadCrumbs,
		handleAddLessons,
		handleCancelLessons,
		handleAddModules,
		handleCancelModules,
		handleOpenSelectProductDialog,
		handleCancelSelectProductDialog,
		showLessonsDialog,
		showModulesDialog,
		selectProductDialog,
		selectedLessons,
		selectedModules,
		setShowLessonsDialog,
		setShowModulesDialog,
		setSelectProductDialog,
		setSelectedLessons,
		setSelectedModules
	}
}
