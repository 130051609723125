import { useState } from 'react'

import { Box, capitalize, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { PagesProps } from 'src/@types'
import { StandardIcon } from 'src/assets/icons'
import { MainTitle, ActionButton, ListLessons } from 'src/components'
import {
	useGetCatalogByPkQuery,
	useGetSequenceTopicByPkQuery,
	useGetSequenceItemByPkQuery
} from 'src/graphql/autogenerate/hooks'
import { Sequence, Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { useScroll, useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, getSequenceItemCatalogItems } from 'src/utils'

import { AddSequenceItemToClass } from '../components/AddSequenceItemToClass'

export const SequenceItemDetail = ({ page }: PagesProps) => {
	useScroll()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const params = useWurrlyParams<typeof Pages.SequenceItemDetail.params[number]>()
	const { catalogId, catalogItemId, sequenceTopicId, sequenceItemId } = params
	const [isAddSequenceToClassOpen, setIsAddSequenceToClassOpen] = useState(false)

	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})
	const catalogName = catalogData?.catalog_by_pk?.name
	const catalogItemName = catalogData?.catalog_by_pk?.catalog_items.find(
		(item) => item.catalog_item_id === catalogItemId
	)?.name

	const { data: sequenceData } = useGetSequenceTopicByPkQuery({
		variables: {
			sequenceTopicId
		}
	})
	const sequenceName = sequenceData?.sequence_topic_by_pk?.title

	const { data: sequenceItemData } = useGetSequenceItemByPkQuery({
		variables: {
			sequenceItemId,
			teacherId
		}
	})
	const sequence = sequenceItemData?.sequence_by_pk as Sequence | undefined
	const lessonPlans = (sequence?.sequence_lesson_plans?.map((item) => item.lesson_plan) || []) as Lesson_Plan[]

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: capitalize(catalogName || '')
			},
			{
				index: 2,
				overrideName: capitalize(catalogItemName || '')
			},
			{
				index: 3,
				overrideName: sequenceName
			},
			{
				index: 4,
				overrideName: sequence?.name
			}
		],
		params
	})

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={`${sequence?.sequence_topic_sequences[0]?.sequence_topic.title ?? ''} (${sequence?.name})`}
				description={sequence?.description ?? ''}
				list={getSequenceItemCatalogItems(sequence)}
				actionButtons={[
					<ActionButton key={0} disabled onClick={() => {}} size="large" icon={<StandardIcon />} />
				]}
				rightActions={[
					{
						style: { height: 56 },
						variant: 'contained',
						color: 'primary',
						className: 'addClass',
						endIcon: <AddIcon />,
						name: 'Add Sequence to Class',
						onClick: (e) => {
							e.stopPropagation()
							setIsAddSequenceToClassOpen(true)
						}
					}
				]}
			/>

			<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>There are {sequence?.sequence_lesson_plans?.length ?? '0'} Lessons in this Sequence</b>
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs>
					<ListLessons teacherId={teacherId} data={lessonPlans} spacing={1.5} sequence={sequence} />
				</Grid>
			</Grid>
			<AddSequenceItemToClass
				isOpen={isAddSequenceToClassOpen}
				setIsOpen={setIsAddSequenceToClassOpen}
				sequence={sequence}
			/>
		</Box>
	)
}
