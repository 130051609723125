import React, { useState } from 'react'

import { Box, Button, CircularProgress, fade, Grid, Typography } from '@material-ui/core'
import { ViewModuleOutlined } from '@material-ui/icons'
import ListIcon from '@material-ui/icons/List'
import { useHistory } from 'react-router-dom'
import { ActionButton, SelectSortOrderBy, WurrlyCard } from 'src/components'
import { queryParamsClassWurrlies } from 'src/components/Grids/WurrliesGrid.types'
import { ListWurrlies } from 'src/components/ListWurrlies'
import { Pages } from 'src/routes/teacherPages'
import { COLORS } from 'src/styles/colors'
import { getCyKey, SortOrder, buildRouteParameters } from 'src/utils'

import { useClassWurrliesContext } from '../useClassWurrlies'

enum wurrlyView {
	Grid,
	List
}

export const ClassWurrliesGrid = (): JSX.Element => {
	const history = useHistory()
	const [view, setView] = useState<wurrlyView>(wurrlyView.Grid)
	const { classId, loading, isFetching, classWurrliesArr, setSort, sort } = useClassWurrliesContext()

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const handleWurrlyCard = (wurrlyId: number) => {
		history.push(
			buildRouteParameters(
				Pages.WurrliesDetails,
				{
					classId,
					wurrlyId
				},
				true,
				{ sort } as queryParamsClassWurrlies
			)
		)
	}

	return (
		<Box marginTop={5}>
			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b>Student Wurrlies </b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<ActionButton
							data-cy={getCyKey(ClassWurrliesGrid, 'btnWurrliesViewGrid')}
							onClick={() => setView(wurrlyView.Grid)}
							style={{
								marginRight: '20px',
								backgroundColor: view === wurrlyView.Grid ? fade(COLORS.yellow, 0.2) : 'white'
							}}
							color={view === wurrlyView.Grid ? 'secondary' : 'default'}
							variant="outlined"
							icon={<ViewModuleOutlined />}
						/>
						<ActionButton
							data-cy={getCyKey(ClassWurrliesGrid, 'btnWurrliesViewList')}
							onClick={() => setView(wurrlyView.List)}
							style={{
								marginRight: '20px',
								backgroundColor: view === wurrlyView.List ? fade(COLORS.yellow, 0.2) : 'white'
							}}
							color={view === wurrlyView.List ? 'secondary' : 'default'}
							variant="outlined"
							icon={<ListIcon />}
						/>
						<SelectSortOrderBy cyKey="Wurrlies" value={sort} onChange={handleSort} />
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				{!loading ? (
					view === wurrlyView.Grid ? (
						classWurrliesArr.map((wurrly) => (
							<Grid key={wurrly.wurrly_id} item xs={4}>
								<WurrlyCard
									type={wurrly?.wurrly_type}
									header={{
										avatar: wurrly.student?.image_path || '',
										subtitle: `${wurrly.student?.full_name}`,
										title: wurrly.challenge?.title ?? wurrly.class.title,
										date: wurrly.created_at
									}}
									cardMedia={wurrly.image_path || ''}
									content={{
										title: wurrly.track?.song?.title,
										subtitle: wurrly.track?.song?.artist?.name || '',
										trackType: wurrly.track?.track_type?.name || '',
										grade: wurrly.submission_wurrly?.submission?.grade || ''
									}}
									onClick={() => handleWurrlyCard(wurrly.wurrly_id)}
								/>
							</Grid>
						))
					) : (
						<Grid item xs>
							<ListWurrlies data={classWurrliesArr} spacing={1.5} onClick={handleWurrlyCard} />
						</Grid>
					)
				) : (
					<Box m={3} textAlign="center">
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
			</Grid>
			{isFetching && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!loading && classWurrliesArr.length > 0 && (
				<Box mt={4} textAlign="center">
					<Button
						onClick={() => {
							window.scrollTo(0, 0)
						}}
						variant="text"
						color="secondary">
						Back to Top
					</Button>
				</Box>
			)}
		</Box>
	)
}
