import { Redirect, Route, RouteProps } from 'react-router-dom'
import { FullScreenLoading } from 'src/components/Loading/FullScreenLoading'
import { Wurrly_Role_Enum } from 'src/graphql/autogenerate/schemas'

import { useLoginContext } from '../hooks/useLogin'

type Props = {
	path: string
	component: <T>(props: T) => JSX.Element
	role?: Wurrly_Role_Enum
	dontValidateAutentication?: boolean
}
const LoadingScreen = () => {
	const { loading } = useLoginContext()

	return loading ? <FullScreenLoading /> : null
}

export const PrivateRoute = ({ component: Component, role, ...rest }: Props & RouteProps) => {
	const { studentData, teacherData, isAuthenticated, hasToken, isLoggedAsTeacher, loading, haveRequestedData } =
		useLoginContext()

	const loggedRole = studentData.student_id
		? Wurrly_Role_Enum.Student
		: teacherData.teacher_id
		? Wurrly_Role_Enum.Teacher
		: null

	const loginRedirectType = isLoggedAsTeacher ? 'teacher' : 'student'

	if (role && role !== loggedRole && (studentData.student_id || teacherData.teacher_id)) {
		return (
			<Route
				{...rest}
				render={(props) => (
					<Redirect exact to={{ pathname: `/${loggedRole}`, state: { from: props.location } }} />
				)}
			/>
		)
	}

	return (
		<Route
			{...rest}
			render={(props) => {
				// show load screen if loading or if the user has token but not has been checked already
				if (loading || (!haveRequestedData && hasToken)) {
					return <LoadingScreen />
				}
				/**
				 * @remarks if the user has token the check will be done inside login context
				 * if the user does not have token the check will be done here
				 */
				if (isAuthenticated === false && !hasToken) {
					return (
						<Redirect
							exact
							to={{
								pathname: `/login/${loginRedirectType}`,
								state: { from: props.location }
							}}
						/>
					)
				}

				return <Component {...props} />
			}}
		/>
	)
}

export const PublicRoute = ({ component: Component, dontValidateAutentication, ...rest }: Props & RouteProps) => {
	const { isAuthenticated, teacherData, studentData, loading } = useLoginContext()

	return (
		<Route
			{...rest}
			render={(props) =>
				loading ? (
					<LoadingScreen />
				) : isAuthenticated === false || dontValidateAutentication ? (
					<Component {...props} />
				) : teacherData.teacher_id ? (
					<Redirect exact to={{ pathname: '/teacher' }} />
				) : studentData.student_id ? (
					<Redirect exact to={{ pathname: '/student' }} />
				) : undefined
			}
		/>
	)
}
