import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { ClassSettingsFormConfig } from './components/ClassSettingsFormConfig'
import { ClassSettingsTitle } from './components/ClassSettingsTitle'
import { ClassSettingsProvider } from './useClassSettings'

export const ClassSettings = (props: PagesProps) => {
	return (
		<Box>
			<ClassSettingsProvider>
				<ClassSettingsTitle {...props} />
				<ClassSettingsFormConfig />
			</ClassSettingsProvider>
		</Box>
	)
}
