import { makeStyles } from '@material-ui/core/styles'

import Placeholder from '../../../../../../../../assets/icons/Placeholder.svg'
export const useStudentCodeDialogStyles = makeStyles({
	boxInput: {
		color: '#F3AB34',
		height: '109px',
		width: '79px',
		outline: 'none',
		borderColor: 'transparent',
		backgroundImage: `url(${Placeholder})`,
		backgroundPositionY: '98%',
		backgroundRepeat: 'no-repeat',
		'&:focus': {
			backgroundColor: 'rgba(243, 171, 52, 0.14)',
			border: '2px solid #F3AB34',
			borderRadius: '4px solid orange'
		}
	}
})
