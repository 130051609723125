import { makeStyles } from '@material-ui/core'

export const useCatalogSongsStyles = makeStyles({
	grid: { marginTop: 40 },
	flex: { flexGrow: 1 },
	results: { textAlign: 'right' },
	title: {
		fontSize: 18
	},
	listItem: {
		minWidth: 'auto'
	},
	form: {
		width: '100%'
	},
	formLabel: {
		flex: 1,
		marginRight: 0,
		textTransform: 'capitalize'
	}
})

export default useCatalogSongsStyles
