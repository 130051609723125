import { useMemo } from 'react'

import { CarouselOfAny } from 'src/components'

import { useGradingContext } from '../../useGrading'
import { GradingCard } from '../GradingCard/GradingCard'

export type GradingCardCarouselProps = {
	gradingValues: string[] | number[]
}

export const GradingCardCarousel = ({ gradingValues }: GradingCardCarouselProps) => {
	const {
		setNextCarouselPosition,
		currentCarouselPosition,
		nextCarouselPosition,
		submissions,
		goToNextCarouselPosition
	} = useGradingContext()

	const handleCarouselClick = (position: number) => {
		if (nextCarouselPosition !== position) return setNextCarouselPosition(position)
		goToNextCarouselPosition()
	}

	const gradingCards = useMemo(
		() =>
			submissions?.map((submission, index) => ({
				element: (
					<GradingCard
						key={submission?.submission_id}
						index={index}
						files={
							submission?.submission_assets?.map((submissionAsset) => submissionAsset.asset) || []
						}
						gradingValues={gradingValues}
					/>
				)
			})),
		[submissions?.length, currentCarouselPosition, nextCarouselPosition, gradingValues]
	)

	return useMemo(
		() => (
			<CarouselOfAny
				slidesPerPage={{ xs: 1, sm: 1 }}
				spacing={1}
				renderItem={(item) => (item.element ? item.element : <div />)}
				data={gradingCards}
				onPrevClick={handleCarouselClick}
				onNextClick={handleCarouselClick}
				goToSlide={currentCarouselPosition}
			/>
		),
		[gradingCards]
	)
}
