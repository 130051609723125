import { createStyles, makeStyles, Theme, Tooltip, withStyles } from '@material-ui/core'

export const useWurrlyCardStyles = makeStyles<Theme, { highlighted?: boolean }>((theme: Theme) =>
	createStyles({
		root: ({ highlighted }) => ({
			border: highlighted ? '3px solid' : 'none',
			borderColor: theme.palette.secondary.main,
			borderRadius: highlighted ? '5px' : 'unset',
			scrollMargin: highlighted ? '100px' : 'unset'
		}),
		avatar: {
			marginRight: theme.spacing(2),
			height: 48,
			width: 48
		},
		header: {
			display: 'flex',
			padding: theme.spacing(3),
			position: 'relative',
			height: 96
		},
		headerAvatar: {},
		headerContainer: {},
		headerTitle: {
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 1.2
		},
		headerSubtitle: {
			fontSize: 14,
			lineHeight: 1
		},
		headerDate: {
			color: theme.palette.grey[500],
			fontSize: 14
		},
		headerSelector: {
			bottom: theme.spacing(1),
			right: theme.spacing(1),
			position: 'absolute'
		},
		headerBadge: {
			alignItems: 'flex-end',
			backgroundColor: '#FFF5D7',
			display: 'flex',
			height: 50,
			justifyContent: 'center',
			position: 'absolute',
			right: theme.spacing(3),
			top: 0,
			width: 45,
			'& img': {
				marginBottom: 7
			},
			'&:before, &:after': {
				bottom: -15,
				content: '""',
				display: 'block',
				height: 15,
				position: 'absolute',
				width: '50%'
			},
			'&:before': {
				borderBottom: '15px solid transparent',
				borderLeft: '22.5px solid #FFF5D7',
				left: 0
			},
			'&:after': {
				borderBottom: '15px solid transparent',
				borderRight: '22.5px solid #FFF5D7',
				right: 0
			}
		},
		cardMedia: {
			height: 195,
			position: 'relative'
		},
		content: {
			display: 'flex',
			padding: theme.spacing(2, 3)
		},
		contentContainer: {
			flexGrow: 1,
			position: 'relative'
		},
		contentAvatar: {
			backgroundColor: theme.palette.grey[300]
		},
		contentTitle: {
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 1.3,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			'& div': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap'
			}
		},
		contentDescription: {
			color: theme.palette.grey[500],
			fontSize: 14,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		},
		contentChatIcon: {
			color: '#8E939E',
			fontSize: 18,
			marginTop: 4,
			alignSelf: 'flex-end'
		},
		actions: {
			padding: theme.spacing(0, 3, 3, 3)
		},
		actionsButton: {
			height: 40
		},
		actionsButtonIcon: { transform: 'rotate(45deg)' },
		cardIcon: {
			height: 25,
			width: 25,
			color: theme.palette.primary.main
		},
		ungradedBadge: {
			background: '#fee2e2',
			color: '#f87171',
			borderRadius: '4px',
			fontWeight: 500,
			display: 'flex',
			alignItems: 'center',
			padding: '8px 16px',
			position: 'absolute',
			right: '20px',
			top: '20px'
		},
		reviewedBadge: {
			background: 'rgba(0, 186, 112, 0.75)',
			color: 'white',
			borderRadius: '4px',
			fontWeight: 500,
			display: 'flex',
			alignItems: 'center',
			padding: '8px 16px',
			position: 'absolute',
			right: '20px',
			top: '20px'
		},
		grade: {
			backgroundColor: 'rgba(0, 186, 112, 0.16)'
		},
		box: {
			backgroundColor: 'white',
			borderRadius: '4px',
			width: 'auto',
			position: 'absolute',
			right: '20px',
			top: '20px'
		}
	})
)

export const LightTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		boxShadow: theme.shadows[1],
		color: theme.palette.text.secondary,
		fontSize: 13,
		padding: '15px 20px'
	},
	arrow: {
		'&:before': {
			backgroundColor: theme.palette.common.white,
			boxShadow: '0 0 1px rgba(0,0,0,0.2)'
		}
	}
}))(Tooltip)

export default useWurrlyCardStyles
