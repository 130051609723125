import { MenuItem, Select as SelectMui, SelectProps as SelectMuiProps } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { capitalize } from 'src/utils'

import { getCyKey } from '../../utils/utils'
import useStyles, { optionStyles } from './Select.styles'

type Options = {
	name: string
	value: unknown
}

type SelectProps = {
	className?: string
	options?: Options[] | undefined
}

export const Option = withStyles(optionStyles)(MenuItem)

export const Select = ({ className, options, value, children, ...restProps }: SelectProps & SelectMuiProps) => {
	const styles = useStyles()

	return (
		<SelectMui
			data-cy={getCyKey(Select, className as string)}
			className={clsx(styles.root, className)}
			color="secondary"
			onClick={(e) => e.stopPropagation()}
			variant="outlined"
			value={value}
			{...restProps}>
			{options
				? options.map(({ name, value: valueOption }, index) => (
						<Option
							data-cy={getCyKey(Select, name)}
							value={valueOption as string}
							selected={value === valueOption}
							key={index}>
							{capitalize(name)}
						</Option>
				  ))
				: children}
		</SelectMui>
	)
}
