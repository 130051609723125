import React from 'react'

import { Box, SvgIcon, Typography } from '@material-ui/core'
import { PersonIcon, AddByCsv } from 'src/assets/icons'
import { Role_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'

import { IconActionOptionType, ListDialog } from '..'

enum AddStudentsOptsEnum {
	upload = 'upload',
	oneByOne = 'classCode'
}
type AddStudentsManualInviteDialogType = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsOneByOneInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsOneByOnePrivateCampInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsByBulkInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsByBulkPrivateCampInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const AddStudentsManualInviteDialog = ({
	isOpen,
	setIsOpen,
	setIsOneByOneInviteOpen,
	setIsOneByOnePrivateCampInviteOpen,
	setIsByBulkInviteOpen,
	setIsByBulkPrivateCampInviteOpen
}: AddStudentsManualInviteDialogType) => {
	const { teacherData: teacher } = useLoginContext()
	const addStudentsOpts: IconActionOptionType[] = [
		{
			id: AddStudentsOptsEnum.upload,
			name: (
				<Box>
					<Typography style={{ fontSize: 24, paddingTop: 12 }}>Bulk Invitation</Typography>
					<Typography style={{ fontSize: 12 }}>Upload a CSV File</Typography>
				</Box>
			),
			icon: <img src={AddByCsv} />,
			cols: 2
		},
		{
			id: AddStudentsOptsEnum.oneByOne,
			name: (
				<Box>
					<Typography style={{ fontSize: 24, paddingTop: 12 }}>One By One</Typography>
					<Typography style={{ fontSize: 12 }}>Invite your students one by one</Typography>
				</Box>
			),
			icon: (
				<SvgIcon>
					<PersonIcon />
				</SvgIcon>
			),
			cols: 2
		}
	]

	return (
		<ListDialog
			open={isOpen}
			cols={2}
			onClose={() => {
				setIsOpen(false)
			}}
			title="Invite Students"
			description="How would you like to invite your Students?"
			discardLabel="Cancel"
			onDiscard={() => {
				setIsOpen(false)
			}}
			onDiscardProps={{
				style: {
					width: '100%'
				}
			}}
			optionSelected={''}
			options={addStudentsOpts}
			handleSelectOption={(opt: string) => {
				switch (opt) {
					case AddStudentsOptsEnum.upload:
						if (teacher.role_name === Role_Name_Enum.School) {
							setIsByBulkInviteOpen(true)
							setIsOpen(false)
						} else {
							setIsByBulkPrivateCampInviteOpen(true)
							setIsOpen(false)
						}
						break
					case AddStudentsOptsEnum.oneByOne:
						if (teacher.role_name === Role_Name_Enum.School) {
							setIsOneByOneInviteOpen(true)
							setIsOpen(false)
						} else {
							setIsOneByOnePrivateCampInviteOpen(true)
							setIsOpen(false)
						}
						break
					default:
						break
				}
				setIsOpen(false)
			}}
			cardHeight={160}
			cardWidth={235}
		/>
	)
}
