import {
	Box,
	Button,
	Card,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	RadioGroup,
	Typography
} from '@material-ui/core'
import { FullBandIcon, GuitarIcon, MusicNote, PianoIcon } from 'src/assets/icons/CustomIcons'
import InputFile from 'src/components/Inputs/InputFile'
import { InputHeader } from 'src/components/Inputs/InputHeader/InputHeader'
import { SelectableOpacity } from 'src/components/Select/SelectableOpacity/SelectableOpacity'
import { FileEnum } from 'src/graphql/autogenerate/schemas'
import { buildAssetUrl, TrackTypeEnum } from 'src/utils'
import { TERMS_URL } from 'src/utils/constants'

import { useUploadSongTrackContext } from '../../useUploadSongTrack'
import useStyles from './UploadAudioView.styles'
import { useUploadAudioView } from './useUploadAudioView'

const TrackTypeIcon = ({ type }: { type: string }) => {
	switch (type) {
		case TrackTypeEnum.Band:
			return <FullBandIcon />
		case TrackTypeEnum.Guitar:
			return <GuitarIcon />
		case TrackTypeEnum.Piano:
			return <PianoIcon />
		case TrackTypeEnum.Vocal:
			return <MusicNote />
		default:
			return null
	}
}

export const UploadAudioView = () => {
	const { setWithChords, setWithLyrics, withChords, withLyrics, setAudioUrl } = useUploadSongTrackContext()
	const { formik, trackTypes, songData } = useUploadAudioView()
	const styles = useStyles()

	return (
		<Box>
			<Box>
				<InputHeader
					name="Upload Your Song"
					isRequired
					endAdornment={
						<Box className={styles.termsContainer}>
							<FormControlLabel
								name="terms"
								checked={formik.values.terms}
								onChange={formik.handleChange}
								control={<Checkbox />}
								label={
									<Typography className={styles.termsText}>
										This song follows WURRLY{' '}
										<a href={TERMS_URL} className={styles.termsLink}>
											Terms of Use
										</a>
										<b> *</b>
									</Typography>
								}
							/>
						</Box>
					}
				/>
				<InputFile.Container
					advise="File Types: m4a, mp3. Max File size: 20mb. Max time: 10 minutes"
					placeholder="Upload an Audio file"
					hasUsedInitialData={false}
					type={FileEnum.Songs}
					handleFileUrl={(key) => {
						formik.setFieldValue('resource_path', key)
						const url = buildAssetUrl(key)
						setAudioUrl(url)
					}}
					isTemporal={false}
				/>
			</Box>

			<Box>
				<InputHeader name="Details" isRequired />
				<FormControl component="fieldset">
					<RadioGroup aria-label="scale id" name="details" className={styles.radios}>
						<Card>
							<FormControlLabel
								className={styles.modeCard}
								value={true}
								control={
									<Checkbox
										checked={withChords}
										onChange={(_, checked) => setWithChords(checked)}
									/>
								}
								label="Chords"
							/>
						</Card>
						<Card>
							<FormControlLabel
								className={styles.modeCard}
								value="lyrics"
								control={
									<Checkbox
										checked={withLyrics}
										onChange={(_, checked) => setWithLyrics(checked)}
									/>
								}
								label="Lyrics"
							/>
						</Card>
					</RadioGroup>
				</FormControl>
			</Box>

			<Box>
				<InputHeader name="Type" isRequired />
				<Grid container spacing={1}>
					{trackTypes.map((type) => (
						<Grid item xs key={type.track_type_id}>
							<SelectableOpacity selected={formik.values.track_type_id === type.track_type_id}>
								<Box>
									<Button
										className={styles.button}
										fullWidth
										disabled={
											!!songData?.song_by_pk?.tracks.find(
												(i) => i.track_type_id === type.track_type_id
											)
										}
										startIcon={<TrackTypeIcon type={type.name} />}
										onClick={() => formik.setFieldValue('track_type_id', type.track_type_id)}>
										{type.name}
									</Button>
								</Box>
							</SelectableOpacity>
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	)
}
