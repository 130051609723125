import { Box, Typography } from '@material-ui/core'

import useStyles from './FormItem.styles'
import { FormItemProps } from './FormItem.types'

const FormItem = ({ title, extraInfo, content }: FormItemProps) => {
	const styles = useStyles()

	return (
		<Box className={styles.formItemContainer}>
			<Box className={styles.titleContainer}>
				<Typography variant="h6">{title}</Typography>
				<Typography color="textSecondary" className={styles.extraInfo}>
					{extraInfo}
				</Typography>
			</Box>
			<Box className={styles.contentContainer}>{content}</Box>
		</Box>
	)
}

export default FormItem
