import { useEffect, useState } from 'react'

import { Submission } from 'src/graphql/autogenerate/schemas'
import {
	orderSubmissionByNameAsc,
	orderSubmissionsByGrade,
	orderSubmissionByNameDesc,
	orderSubmissionByDateAsc
} from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/hooks/utils'
import { SortOrder } from 'src/utils'

export const useSortedSubmissions = (submissions: Submission[], gradingValues: string[] | number[]) => {
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Up)
	const [sortedSubmissions, setSortedSubmissions] = useState<Submission[]>([])

	const getSortedSubmissions = (sortOrder: SortOrder) => {
		switch (sortOrder) {
			case SortOrder.Up:
			default:
				return orderSubmissionByNameAsc([...submissions])
			case SortOrder.Down:
				return orderSubmissionByNameDesc([...submissions])
			case SortOrder.Recent:
				return orderSubmissionByDateAsc([...submissions])
			case SortOrder.Grade:
				return orderSubmissionsByGrade([...submissions], gradingValues)
		}
	}

	useEffect(() => {
		setSortedSubmissions(getSortedSubmissions(sortOrder))
	}, [submissions, sortOrder])

	return { sortedSubmissions, sortOrder, setSortOrder }
}
