import AddIcon from '@material-ui/icons/Add'
import { MainTitle } from 'src/components'
import { AddProductToClassDialog } from 'src/components/Dialogs/AddProductToClassDialog/AddProductToClassDialog'
import { SelectAddProductDialog } from 'src/components/Dialogs/SelectAddProductDialog/SelectAddProductDialog'
import { useClassLessonsTitle } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/components/useClassLessonsTitle'
import { ProductTitleEnum } from 'src/utils'

export const ClassLessonsTitle = () => {
	const {
		breadCrumbs,
		handleAddLessons,
		handleCancelLessons,
		handleAddModules,
		handleCancelModules,
		handleOpenSelectProductDialog,
		handleCancelSelectProductDialog,
		showLessonsDialog,
		showModulesDialog,
		selectProductDialog,
		selectedLessons,
		selectedModules,
		setShowLessonsDialog,
		setShowModulesDialog,
		setSelectProductDialog,
		setSelectedLessons,
		setSelectedModules
	} = useClassLessonsTitle()

	return (
		<>
			<MainTitle
				breadcrumbs={breadCrumbs}
				title="Class Lessons"
				description="These are the Lessons that have been added to this Class."
				rightActions={[
					{
						color: 'secondary',
						endIcon: <AddIcon />,
						name: 'Add to Class',
						onClick: handleOpenSelectProductDialog
					}
				]}
			/>
			<AddProductToClassDialog
				onClose={() => {
					setShowLessonsDialog(false)
					setSelectProductDialog(true)
				}}
				selectedData={selectedLessons}
				setSelected={setSelectedLessons}
				open={showLessonsDialog}
				handleCancel={handleCancelLessons}
				type={ProductTitleEnum.LessonPlan}
			/>

			<AddProductToClassDialog
				onClose={() => {
					setShowModulesDialog(false)
					setSelectProductDialog(true)
				}}
				selectedData={selectedModules}
				setSelected={setSelectedModules}
				open={showModulesDialog}
				handleCancel={handleCancelModules}
				type={ProductTitleEnum.Module}
			/>

			<SelectAddProductDialog
				open={selectProductDialog}
				onClose={() => setSelectProductDialog(false)}
				handleCancel={handleCancelSelectProductDialog}
				actions={[
					{
						name: 'Add Lessons',
						color: 'secondary',
						endIcon: <AddIcon />,
						onClick: handleAddLessons
					},
					{
						name: 'Add Modules',
						color: 'secondary',
						endIcon: <AddIcon />,
						onClick: handleAddModules
					}
				]}
			/>
		</>
	)
}
