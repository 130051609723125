import { useEffect, useState } from 'react'

import moment from 'moment'
import { ChartDataType } from 'src/@types'
import {
	useGetSkillsCoveredBySavedLessonsQuery,
	useGetStandardsCoveredBySavedLessonsQuery,
	useStudentProductivityMonthlyQuery
} from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { COLORS } from 'src/styles/colors'
import { ClassChartType } from 'src/utils'

export const useStudentProductivityData = (classId: number, month: number) => {
	const { data, loading } = useStudentProductivityMonthlyQuery({
		variables: { classId, year: moment().year(), month }
	})
	const [chartData, setChartData] = useState<ChartDataType>({ labels: [], datasets: [] })

	useEffect(() => {
		if (!loading && data && data.student_productivity_monthly) {
			const labels: string[] = []
			const videoViews: number[] = []
			const practiceSessions: number[] = []
			const recordingSessions: number[] = []
			data.student_productivity_monthly.map((item) => {
				// TODO: remove this once the student_productivity_* includes the student_full_name
				labels.push(`${item.student?.first_name || ''} ${item.student?.last_name} || '`)
				videoViews.push(item.video_views)
				practiceSessions.push(item.practice_sessions)
				recordingSessions.push(item.recording_sessions)
			})
			setChartData({
				labels,
				datasets: [
					{
						label: 'Video views',
						data: videoViews,
						backgroundColor: COLORS.blue
					},
					{
						label: 'Practice sessions',
						data: practiceSessions,
						backgroundColor: COLORS.yellow
					},
					{
						label: 'Recording sessions',
						data: recordingSessions,
						backgroundColor: COLORS.purple
					}
				]
			})
		}
	}, [data, loading])

	return { chartData }
}

export const useSkillsCoveredSavedLessonsData = (classId: number) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const { data: standardChartData } = useGetStandardsCoveredBySavedLessonsQuery({
		skip: Number.isNaN(classId),
		variables: {
			classId,
			teacherId
		}
	})

	const { data: skillChartData } = useGetSkillsCoveredBySavedLessonsQuery({
		skip: Number.isNaN(classId),
		variables: { classId }
	})

	const getChartData = (type: ClassChartType) => {
		const classStandardData =
			type === ClassChartType.standards
				? { ...standardChartData?.class_by_pk }
				: { ...skillChartData?.class_by_pk }
		let labels: string[]
		let total = 0
		let data: number[] = []
		if (type === ClassChartType.standards) {
			labels = ['performing', 'creating', 'responding', 'connecting']
			let performing = 0
			let creating = 0
			let responding = 0
			let connecting = 0
			standardChartData?.class_by_pk?.class_lesson_plans.forEach((i) => {
				performing += i?.lesson_plan?.process?.performing?.length || 0
				creating += i?.lesson_plan?.process?.creating?.length || 0
				responding += i?.lesson_plan?.process?.responding?.length || 0
				connecting += i?.lesson_plan?.process?.connecting?.length || 0
			})
			standardChartData?.class_by_pk?.class_modules?.forEach((module) => {
				module?.module?.module_lesson_plan?.forEach((i) => {
					performing += i?.lesson_plan?.process?.performing?.length || 0
					creating += i?.lesson_plan?.process?.creating?.length || 0
					responding += i?.lesson_plan?.process?.responding?.length || 0
					connecting += i?.lesson_plan?.process?.connecting?.length || 0
				})
			})
			total = performing + creating + responding + connecting
			data = [
				(performing / total) * 100,
				(creating / total) * 100,
				(responding / total) * 100,
				(connecting / total) * 100
			]
		} else {
			labels = Object.keys(classStandardData).filter((key) => key !== '__typename' && key !== 'class_id')
			total = labels
				.map(
					(key) =>
						(
							classStandardData[key as keyof typeof classStandardData] as unknown as {
								aggregate: { count: number }
							}
						)?.aggregate?.count
				)
				.reduce((a, b) => a + b, 0)
			data = labels.map(
				(key) =>
					((
						classStandardData[key as keyof typeof classStandardData] as unknown as {
							aggregate: { count: number }
						}
					)?.aggregate?.count /
						total) *
					100
			)
		}

		return {
			labels,
			datasets: [
				{
					data,
					borderColor: COLORS.charts,
					backgroundColor: COLORS.charts,
					borderWidth: 1,
					hoverOffset: 52
				}
			]
		}
	}

	return { getChartData, standardChartData }
}
