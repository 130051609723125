import { createStyles, makeStyles } from '@material-ui/core'

export const useLessonPageStyles = makeStyles(() =>
	createStyles({
		ckEditor: {
			'& .ck.ck-reset_all': {
				display: 'none'
			},
			'& .ck.ck-editor__editable_inline': {
				border: '0px'
			}
		}
	})
)

export default useLessonPageStyles
