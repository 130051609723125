import { useState } from 'react'

import { BoxProps } from '@material-ui/core'
import { SelectClassDialog } from 'src/components'
import { Class } from 'src/graphql/autogenerate/schemas'

import ClassCard from '../ClassCard'
import { FormBox } from '../FormBox/FormBox'

type SelectClassProps = {
	selectedClasses: Class[]
	setSelectedClasses: (value: Class[]) => void
	error?: boolean
	boxProps?: BoxProps
}

export const SelectClass = ({ selectedClasses, setSelectedClasses, error, boxProps }: SelectClassProps) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const openDialog = () => setIsDialogOpen(true)

	const onConfirm = async (item: Class) => {
		setSelectedClasses([item])
	}

	return (
		<>
			<FormBox
				text="Select a class"
				onClick={openDialog}
				withAddIcon={selectedClasses.length ? false : true}
				error={error}
				boxProps={boxProps}
				content={
					selectedClasses.length &&
					selectedClasses.map((item) => (
						<ClassCard
							imgSource={item.image_path ?? ''}
							classTitle={item.title}
							studentsCount={item.class_students_aggregate.aggregate?.count ?? 0}
							key={item.class_id}
						/>
					))
				}
			/>
			<SelectClassDialog
				title="Select a Class"
				description="Please select a Class to add this Assignment to."
				itemClasses={selectedClasses}
				isOpen={isDialogOpen}
				setIsOpen={setIsDialogOpen}
				onConfirm={onConfirm}
			/>
		</>
	)
}
