import React, { Fragment, useState } from 'react'

import { Box, Button, CircularProgress, IconButton, InputBase, Paper, Typography } from '@material-ui/core'
import { fade, useTheme } from '@material-ui/core/styles'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import { ListStudent } from 'src/components'
import { useGetTeacherStudentsByKeywordQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Student } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { buildStudentSearchParams, getCyKey, MAX_SEARCH_ITEMS } from 'src/utils'

import { useStudentsContext } from '../../useStudents'
import { AddStudentToClass } from '../AddStudentToClass'
import { DeleteStudentDialog } from '../DeleteStudentDialog'
import { ReInviteStudentDialog } from '../ReInviteStudentDialog'
import { useStyles } from './styles'

let timer: number
export const StudentSearch = () => {
	const { setSearchText } = useStudentsContext()
	const [focus, setFocus] = useState(false)
	const styles = useStyles(focus)
	const theme = useTheme()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const [text, setText] = useState('')
	const [loading, setLoading] = useState(false)
	const [showClean, setShowClean] = useState(false)
	const [showResults, setShowResults] = useState(false)

	const [studentDelete, setStudentDelete] = useState<Student | undefined>()
	const [studentReInvite, setStudentReInvite] = useState<Student | undefined>()
	const [addToClassStudent, setAddToClassStudent] = useState<Student | undefined>()

	const INITIAL_QUERY_VARIABLES = {
		teacherId,
		limit: MAX_SEARCH_ITEMS,
		offset: 0,
		order: Order_By.Asc
	}

	const inputRef = React.createRef<HTMLInputElement>()

	const { data, refetch } = useGetTeacherStudentsByKeywordQuery({
		variables: INITIAL_QUERY_VARIABLES,
		fetchPolicy: 'no-cache'
	})

	const students = (data?.student || []) as Student[]

	const handleFocus = () => setFocus(true)

	const handleBlur = () => setFocus(false)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoading(true)
		const textEvent = (event.target as HTMLInputElement).value
		setShowClean(!!textEvent)
		setText(textEvent)
		clearTimeout(timer)
		timer = window.setTimeout(() => {
			if (textEvent !== '') {
				setShowResults(true)
				refetch({
					teacherId: teacher.teacher_id,
					conditions: buildStudentSearchParams(textEvent)
				})
				setLoading(false)
			}
		}, 1000)
	}

	const handleSearch = () => {
		setShowResults(false)
		setSearchText(text)
		clearTimeout(timer)
	}

	const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.keyCode === 13 || event.key === 'Enter') {
			event.preventDefault()
			handleSearch()
		}
	}
	const handleClean = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.value = ''
			inputRef.current.focus()
			setText('')
			setSearchText('')
			setShowClean(false)
		}
	}

	return (
		<Box position="relative">
			<Paper component="form" elevation={0} className={focus ? styles.boxInputFocus : styles.boxInput}>
				<IconButton onClick={handleSearch} aria-label="search">
					<SearchIcon />
				</IconButton>
				<InputBase
					data-cy={getCyKey(StudentSearch)}
					value={text}
					inputRef={inputRef}
					onChange={handleChange}
					onKeyDown={handleKeydown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					placeholder={'Find Student By Name, Email or Class'}
					inputProps={{ 'aria-label': 'search song' }}
				/>
				{showClean && (
					<Fragment>
						<Button
							data-cy={getCyKey(StudentSearch, 'Clean')}
							style={{ color: 'rgba(50, 51, 66, 0.5)' }}
							variant="text"
							onClick={handleClean}>
							<HighlightOffIcon />
						</Button>
					</Fragment>
				)}
			</Paper>

			{text && showResults && (
				<Paper
					className={styles.listItems}
					elevation={3}
					style={students.length === 0 ? { padding: 0 } : undefined}>
					<Box overflow="auto" maxHeight={378}>
						{students.length === 0 && (
							<Box
								py={0.5}
								px={1}
								display="flex"
								alignItems="center"
								style={{ backgroundColor: fade(theme.palette.secondary.main, 0.2) }}>
								<IconButton aria-label="search">
									<SearchIcon />
								</IconButton>
								<Box display="flex">
									<Typography>No results found</Typography>
								</Box>
								<div style={{ flexGrow: 1 }} />
								{loading && (
									<IconButton aria-label="search" disabled>
										<CircularProgress color="secondary" size={20} />
									</IconButton>
								)}
							</Box>
						)}
						<ListStudent
							data={students}
							spacing={1.5}
							deleteStudent={setStudentDelete}
							reinviteStudent={setStudentReInvite}
							addToClassStudent={setAddToClassStudent}
							viewButton={false}
						/>
					</Box>
				</Paper>
			)}
			<DeleteStudentDialog
				studentDelete={studentDelete}
				setStudentDelete={setStudentDelete}
				onDone={() => {
					window.location.reload()
				}}
			/>
			<ReInviteStudentDialog studentReinvite={studentReInvite} setStudentReinvite={setStudentReInvite} />
			<AddStudentToClass addToClassStudent={addToClassStudent} setAddToClassStudent={setAddToClassStudent} />
		</Box>
	)
}
