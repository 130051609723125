import {
	DIAGRAM_SVG_FILL_COLOR,
	DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT,
	UKULELE_CHORDS
} from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/constants'
import { Chord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/types'
import { remapChord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/utils'

type UkuleleDiagramType = {
	chord: Chord
	isHighlighted?: boolean
}

const xPositions = [1, 15, 29, 43]
const yPositions = [19, 35, 51, 67]

const textStyle = {
	fontSize: '9px',
	lineHeight: 'normal',
	fontFamily: 'Arial'
}

export const UkuleleDiagram = ({ chord: currentChord, isHighlighted = false }: UkuleleDiagramType) => {
	const fillColor = isHighlighted ? DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT : DIAGRAM_SVG_FILL_COLOR

	const renderIndicators = () => {
		const chord = remapChord(currentChord)
		if (!chord) {
			return <g />
		}

		const chordData = UKULELE_CHORDS[chord?.name]

		if (!chordData) {
			return <g />
		}

		const elements: JSX.Element[] = []

		chordData.strings.forEach((s, index) => {
			const xPos = xPositions[index]

			elements.push(
				<text
					key={'state-' + index}
					x={xPos}
					y="8"
					stroke="none"
					fill={fillColor}
					textAnchor="middle"
					style={textStyle}>
					{s.state === 1 ? 'o' : ''}
				</text>
			)
		})

		chordData.strings.forEach((s, index) => {
			if (s.state === 0) {
				let offset = 0
				if (chordData.baseFret) {
					offset = chordData.baseFret
				}

				const xPos = xPositions[index]
				const yPos = yPositions[(s?.position?.fret || 0) - 1 - offset]

				elements.push(
					<circle
						key={'position-' + index}
						cx={xPos}
						cy={yPos}
						r="4"
						fill={fillColor}
						stroke={fillColor}
					/>
				)
			}
		})

		return elements
	}

	const renderFretBoard = () => {
		return (
			<g>
				<rect fill="#ffffff" x="1" y="11" width="42.49" height="79.49" />

				<rect fill={fillColor} x="0" y="9" width="44" height="3" />

				<rect fill={fillColor} x="1" y="26.5" width="42.49" height="1" />

				<rect fill={fillColor} x="1" y="42.5" width="42.49" height="1" />

				<rect fill={fillColor} x="1" y="58.5" width="42.49" height="1" />

				<rect fill={fillColor} x="1" y="74.5" width="42.49" height="1" />

				<rect fill={fillColor} x=".5" y="90.5" width="42.99" height="1" />

				<rect fill={fillColor} x=".5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="14.5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="28.5" y="11" width="1" height="80" />

				<rect fill={fillColor} x="42.5" y="11" width="1" height="80" />
			</g>
		)
	}

	const boardContent = renderFretBoard()

	return (
		<div className="chord-diagram-container ukulele">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="-6 2 56 100">
				{boardContent}
				<g style={{ opacity: 1 }}>{renderIndicators()}</g>
			</svg>
		</div>
	)
}
