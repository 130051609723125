import { makeStyles, Theme } from '@material-ui/core'

export const useWholeCatalogStyles = makeStyles<Theme, { showTransposed?: boolean }>((theme) => ({
	title: {
		fontWeight: 'bold'
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		padding: theme.spacing(2, 0)
	},
	transposeLabel: {
		fontWeight: 'bold',
		color: ({ showTransposed }) => (showTransposed ? theme.palette.grey[600] : theme.palette.grey[500])
	}
}))

export default useWholeCatalogStyles
