export const stopMediaStreams = () => {
	stopMediaByDocumentVideos()
	stopMediaByMediaDevices()
}

const stopMediaByDocumentVideos = () => {
	const videos = document.querySelectorAll('video')
	for (const video of videos) {
		const mediaStream = video.srcObject
		if (!mediaStream) continue
		const tracks = (mediaStream as MediaStream).getTracks()
		tracks.forEach((track) => track.stop())
	}
}

const stopMediaByMediaDevices = () => {
	navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
		stream.getTracks().forEach((track) => track.stop())
	})
}

export const videoConstraints = {
	aspectRatio: 16 / 9
} as MediaTrackConstraints
