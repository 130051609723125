import { gql } from '@apollo/client'
export const getPresignedUploadUrl = gql`
	query getPresignedUrl($filter: PresignedUrlFilter) {
		presignedUrl(filter: $filter) {
			key
			url
		}
	}
`

export const purgeTemporal = gql`
	mutation purgeS3Temp($filter: PurgeS3TempFilter) {
		purgeS3Temp(filter: $filter) {
			lessonPlan {
				lesson_plan_id
			}
			asset {
				asset_id
			}
			tip {
				tip_id
				resource_path
				image_path
			}
		}
	}
`

export type PresignedUrlType = {
	presignedUrl: {
		key: string
		url: string
	}
}
