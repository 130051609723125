import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { SubscriptionsCardType } from 'src/@types'
import {
	useCancelProductSubscriptionMutation,
	useGetChannelsLazyQuery,
	useGetTeacherSubscriptionsLazyQuery,
	useSearchChannelLazyQuery
} from 'src/graphql/autogenerate/hooks'
import {
	CancelProductSubscriptionMutation,
	GetTeacherSubscriptionsQuery
} from 'src/graphql/autogenerate/operations'
import {
	Channel,
	Order,
	Order_Status_Enum,
	Price_Type_Enum,
	Product_Name_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import {
	MAX_LIST_ITEMS,
	MAX_SEARCH_ITEMS,
	SortOrder,
	buildBreadCrumbs,
	buildRouteParameters,
	buildSearchText,
	createSuscriptionCardData,
	orderBySortOrder
} from 'src/utils'

export const useChannels = () => {
	const history = useHistory()
	const [filter, setFilter] = useState('None')
	const [sort, setSort] = useState(SortOrder.Up)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [channels, setChannels] = useState<Channel[]>([])
	const [channelSuscriptions, setChannelSuscriptions] = useState<SubscriptionsCardType[]>([])
	const [searchbarText, setSearchbarText] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [channelId, setChannelId] = useState<number>()
	const [totalChannels, setTotalChannels] = useState<number>()
	const limit = MAX_SEARCH_ITEMS
	const { teacherData } = useLoginContext()
	const showSubscriptions = history.location.search === '?from=subs'
	const [searchbarData, setSearchbarData] = useState([] as Channel[])
	const [searchTeacherSubscriptionsData, setSearchTeacherSubscriptionsData] = useState<SubscriptionsCardType[]>(
		[]
	)
	const [unSuscribeOpenDialog, setUnSuscribeOpenDialog] = useState(false)
	const [item, setItem] = useState<SubscriptionsCardType>()
	const [onCompleteRes, setOnCompleteRes] = useState<boolean | undefined>(undefined)

	const handleSearchbarTextEnter = (text: string) => {
		setMoreResults(true)
		if (!showSubscriptions) {
			setChannels([])
			getChannels({
				variables: {
					teacher_id: teacherData.teacher_id,
					limit: MAX_LIST_ITEMS,
					order_by: {
						title: orderBySortOrder[sort]
					},
					includeCountChannels: true,
					search: buildSearchText(text, ['title', 'description'])
				}
			})
		} else {
			if (text) setChannelSuscriptions([])
			setFilter('None')
			setSort(SortOrder.Up)
			GetTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Channel,
					order_by: {
						channel: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(
						text,
						['title', 'description', 'short_description'],
						Product_Name_Enum.Channel
					)
				}
			})
		}
	}

	const breadcrumbs = buildBreadCrumbs({
		page: showSubscriptions ? Pages.ChannelsSubs : Pages.Channels
	})
	const [cancelProductSubscription] = useCancelProductSubscriptionMutation()

	const [getChannels, { data: channelData, loading: channelDataLoading, refetch }] = useGetChannelsLazyQuery()
	const [getSearchChannels, { data: searchBarChannels, loading: searchbarChannelsLoading }] =
		useSearchChannelLazyQuery()

	const [
		GetTeacherSubscriptions,
		{ data: suscriptionData, loading: suscriptionDataLoading, refetch: teacherSubscriptionsFefetch }
	] = useGetTeacherSubscriptionsLazyQuery()
	const [GetSearchTeacherSubscriptions, { data: searchSuscriptionData, loading: searchSuscriptionDataLoading }] =
		useGetTeacherSubscriptionsLazyQuery()

	useEffect(() => {
		if (!showSubscriptions) {
			if (searchBarChannels) setSearchbarData(searchBarChannels?.channel as unknown as Channel[])
		} else {
			if (searchSuscriptionData) tranformSuscriptionData(searchSuscriptionData, true)
		}
	}, [searchBarChannels, searchSuscriptionData])

	useEffect(() => {
		setMoreResults(true)
		if (showSubscriptions) {
			setChannelSuscriptions([])
			GetTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Channel,
					order_by: {
						channel: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(
						searchbarText,
						['title', 'description', 'short_description'],
						Product_Name_Enum.Channel
					),
					price_type: filter !== 'None' ? (filter as Price_Type_Enum) : undefined
				}
			})
		} else {
			setChannels([])
			getChannels({
				variables: {
					limit: MAX_LIST_ITEMS,
					teacher_id: teacherData.teacher_id,
					order_by: {
						title: orderBySortOrder[sort]
					},
					includeCountChannels: true,
					search: buildSearchText(searchbarText, ['title', 'description'])
				}
			})
		}
	}, [sort, filter])

	useEffect(() => {
		if (showSubscriptions) {
			setSearchTeacherSubscriptionsData([])
			GetSearchTeacherSubscriptions({
				variables: {
					limit,
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Channel,
					order_by: {
						channel: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(
						searchbarText,
						['title', 'description', 'short_description'],
						Product_Name_Enum.Channel
					)
				}
			})
		} else {
			getSearchChannels({
				variables: {
					teacher_id: teacherData.teacher_id,
					limit,
					searchText: buildSearchText(searchbarText, ['title', 'description', 'short_description']),
					conditions: []
				}
			})
		}
	}, [searchbarText])

	useEffect(() => {
		if (!isFetching || !moreResults) return
		if (showSubscriptions) {
			const currentLength = channelSuscriptions.length
			teacherSubscriptionsFefetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.order.length !== 0 && fetchMoreResult.data?.order.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		} else {
			const currentLength = channels.length
			refetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.channel.length !== 0 && fetchMoreResult.data?.channel.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		if (channelData) {
			setChannels((channel) => [...channel, ...((channelData?.channel ?? []) as unknown as Channel[])])
			setTotalChannels(channelData?.channel_aggregate?.aggregate?.count || 0)
		}
		if (suscriptionData) {
			setTotalChannels(suscriptionData?.order_aggregate.aggregate?.count || 0)
			tranformSuscriptionData(suscriptionData, false)
		}
	}, [channelData, suscriptionData])

	useEffect(() => {
		if (item) {
			setUnSuscribeOpenDialog(true)
		}
	}, [item])
	const tranformSuscriptionData = (data: GetTeacherSubscriptionsQuery, isSearchData: boolean) => {
		data.order.map((item) => {
			const order = item as Order
			if (isSearchData) {
				setSubscriptionSearchData(createSuscriptionCardData(searchTeacherSubscriptionsData, order))
			} else {
				setSubscriptionData(createSuscriptionCardData(channelSuscriptions, order))
			}
		})
	}

	const setSubscriptionSearchData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setSearchTeacherSubscriptionsData((subscription) => [...subscription, data])
		}
	}
	const setSubscriptionData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setChannelSuscriptions((subscription) => [...subscription, data])
		}
	}

	const onSortChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const handleSearchbarTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchbarText(e.target.value)
	}
	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	const onFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const selectedFilter = event.target.value as Price_Type_Enum
		setFilter(selectedFilter)
	}
	const onUnsubscribe = async (stripeSubcriptiptionId: string) => {
		return new Promise<CancelProductSubscriptionMutation>((resolve, reject) => {
			cancelProductSubscription({
				variables: {
					teacherId: teacherData.teacher_id,
					stripeSubcriptiptionId
				},
				onCompleted: (res) => {
					setOnCompleteRes(res?.cancelProductSubscription?.success)
					resolve(res)
				},
				onError: () => {
					reject(new Error('Unsubscribe error'))
				}
			})
		})
	}
	const handleRefetch = (stripeSubcriptiptionId: string) => {
		if (onCompleteRes) {
			const channelSuscriptionsItem = channelSuscriptions.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			const searchTeacherSubscriptionsDataItem = searchTeacherSubscriptionsData.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			if (channelSuscriptionsItem) {
				channelSuscriptionsItem.status = Order_Status_Enum.Canceled
			}
			if (searchTeacherSubscriptionsDataItem) {
				searchTeacherSubscriptionsDataItem.status = Order_Status_Enum.Canceled
			}
		}
	}

	const goToChannel = (channelId: number) => {
		history.push(buildRouteParameters(Pages.ChannelPage, { channelId }))
	}

	return {
		channels,
		setChannels,
		searchbarData,
		searchbarChannelsLoading,
		handleSearchbarTextEnter,
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		isOpen,
		breadcrumbs,
		onSortChange,
		sort,
		filter,
		onFilterChange,
		totalChannels,
		channelId,
		setChannelId,
		goToChannel,
		onUnsubscribe,
		searchSuscriptionDataLoading,
		searchTeacherSubscriptionsData,
		showSubscriptions,
		channelSuscriptions,
		searchbarText,
		isFetching,
		moreResults,
		channelDataLoading,
		suscriptionDataLoading,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	}
}
