import { Box, Typography } from '@material-ui/core'
import { AccordionWrapper } from 'src/components'
import { AssignmentSortOrder } from 'src/utils'

import { getCyKey } from '../../../../../../utils/utils'
import { AssignmentSort } from '../AssignmentSort/AssignmentSort'
import useStyles from './AssignmentAccordion.styles'
type AssignmentAccordionProps = {
	countTitle: string
	count: number
	details: React.ReactNode
	actions?: React.ReactNode
	sort?: AssignmentSortOrder
	handleSort?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => void
}
export const AssignmentAccordion = ({
	countTitle,
	count,
	details,
	sort,
	actions,
	handleSort
}: AssignmentAccordionProps) => {
	const styles = useStyles()

	return (
		<Box data-cy={getCyKey(AssignmentAccordion, countTitle)} className={styles.accordionHolder}>
			<AccordionWrapper
				defaultExpanded
				summary={
					<Box className={styles.title}>
						<Typography variant="h6">
							<b>
								{countTitle} ({count})
							</b>
						</Typography>
						<div className={styles.grow} />
						{sort !== undefined && !!handleSort && (
							<AssignmentSort value={sort} onChange={handleSort} />
						)}
					</Box>
				}
				details={details}
				actions={actions}
			/>
		</Box>
	)
}
