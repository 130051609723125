import { Link, LinkProps, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { disabled: boolean }>((theme) => ({
	link: {
		cursor: ({ disabled }) => (disabled ? '' : 'pointer'),
		pointerEvents: ({ disabled }) => (disabled ? 'none' : undefined),
		color: theme.palette.secondary.dark,
		fontSize: 16,
		fontWeight: 500
	}
}))

export const BaseLink = ({ children, disabled = false, ...ext }: LinkProps & { disabled?: boolean }) => {
	const styles = useStyles({ disabled })

	return (
		<Link className={styles.link} {...ext}>
			{children}
		</Link>
	)
}
