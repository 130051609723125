import { Appcues } from 'appcues-loader'
import { DocumentNode } from 'graphql'
import { StickerElement } from 'src/components/ResizableElement/ResizableElement'
import { SelectedColorFilter } from 'src/components/Studio/Review/effects/ColorFilter/ColorFilter'
import {
	Catalog,
	Class,
	Filter_Frame,
	Grade,
	Order_Status_Enum,
	Price_Type_Enum,
	Role_Name_Enum,
	Wurrly_Type_Enum
} from 'src/graphql/autogenerate/schemas'

import { Billing_Method_Enum, Integration_Name_Enum, Wurrly_Role_Enum } from '../graphql/autogenerate/schemas'
export type TransposeType = -6 | -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6
export type TranspositionType = {
	transposition: number
	lines: {
		start: number
		end: number
		words: [number, string][]
	}[]
}[]

export type Cache<T, U> = {
	data?: T[]
	type?: string
	isFetching?: boolean
	variables: U
	query: DocumentNode
}
export type ProductName<T extends string> = {
	[K in T]: [] | ProductNameAggregate
}

export type ProductNameAggregate = {
	aggregate: { count: number }
}

export interface Product {
	[key: string]: unknown
}
export type SubscriptionsCardType = {
	priceType: Price_Type_Enum // monthly or annual
	price: number
	productId: number
	orderId: number
	title: string
	description: string
	image_path: string
	expiresAt: string
	productType: string
	stripeSubscriptionId: string
	firstLevelAggregate: number
	secondLevelAggregate?: number
	status: Order_Status_Enum
}
export interface LocationState {
	from: {
		pathname: string
	}
}
export type PickRename<T, K extends keyof T, R extends PropertyKey> = Omit<T, K> & { [P in R]: T[K] }

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
	? ElementType
	: never

// eslint-disable-next-line @typescript-eslint/ban-types
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => unknown ? A : never
export type FirstArgument<T> = T extends (arg1: infer U, ...args: unknown[]) => unknown ? U : unknown
export type SecondArgument<T> = T extends (arg1: unknown, arg2: infer U, ...args: unknown[]) => unknown
	? U
	: unknown
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LiteralTypes<T extends string | number | symbol, U = any> = { [K in T]: U }
export type AttributeType<TObj, TProp extends keyof TObj> = TObj[TProp]

export type DatasetType = {
	label?: string
	data: number[]
	backgroundColor?: string | string[]
	borderColor?: string[]
	borderWidth?: number
	weight?: number
	fill?: boolean
	tension?: number
	lineTension?: number
	pointRadius?: number
	pointBorderColor?: string
	pointBorderWidth?: number
	pointBackgroundColor?: string
	pointHoverBackgroundColor?: string
	hoverOffset?: number
}
export type ChartDataType = {
	labels: string[]
	datasets: DatasetType[]
}
export type StudentChartDataType = {
	labels: Date[]
	datasets: DatasetType[]
}

export type BreadcrumbsArrayType = ({ linkTo?: Record<string, unknown> } & Page)[]

export type NumStr = number | string

export type SearchType = {
	id: number
	name: string
	description?: string
	districtId?: string
	schoolsCount?: number
}

export type Page = {
	parentName?: string
	name: string
	path: string
	component: (props: PagesProps) => JSX.Element
	params: readonly string[]
	noPadding?: boolean
	noDrawer?: boolean
	practice?: boolean
	visibility?: boolean
	grade?: boolean
	showReact?: boolean
	onlyDev?: boolean
	noTemplate?: boolean
}

export type PagesProps = {
	teacherId: number
	page: Page
}

export type ChordMapObjectType = {
	[key: number]: {
		chordArray: string[]
		transposition: TransposeType
		notesArray: number[][]
		chordNotesMap: { [key: string]: string }
	}
}

export type QueryCatalogItemsType = {
	catalog_item: {
		catalog: Catalog
		catalog_item_id: number
		image_path: string | null
		icon_path: string | undefined
		name: string
		active: boolean
		order: number
	}[]
}

export interface IName {
	name: string
}

export type PendoVisitorTeacher = {
	id: string
	firstName: string
	lastName: string
	email: string
	ncesId: string
	schoolName: string
	state: string
	district: string
	signUpSource?: Integration_Name_Enum | 'WurrlyEduRefactor-web' | 'WurrlyEduRefactor-mobile'
	integration: Integration_Name_Enum | 'none'
	role: Role_Name_Enum
	licenseStatus: boolean
	membershipLevel: Billing_Method_Enum | 'none'
	planPrice: number
	contractStartDate?: string // Date string in ISO format
	contractEndDate?: string // Date string in ISO format
}
export type PendoAccountTeacher = {
	id: string
	schoolName: string
	ncesId: string
	district: string
	licenseStatus: boolean
	membershipLevel: Billing_Method_Enum | 'none'
	planPrice: number
	contractStartDate?: string // Date string in ISO format
	contractEndDate?: string // Date string in ISO format
}

export type PendoVisitorStudent = {
	id: string
	schoolName: string
	districtName: string
	className: string
	teacherName: string
	gradeLevel: string
	integration: Integration_Name_Enum
	role: Wurrly_Role_Enum
}

export type PendoAccountStudent = {
	id: string
}

export type FilterItemType = {
	name: string
	items: {
		id: number
		label: string
		selected: boolean
	}[]
}

declare global {
	interface Window {
		pendoInitScript: (apiKey: string) => void
		appcues: WurrlyAppcues
	}
}

export type DateRange = {
	fromDate: Date | null
	toDate: Date | null
}

export type AccountActivationToken = {
	email?: string
	userId: number
	role: Wurrly_Role_Enum
}

export declare type ValueType = string | boolean | number
export declare type UserProperties = {
	[key: string]: ValueType
}
export declare type GroupProperties = {
	[key: string]: ValueType
}
export declare type EventProperties = {
	[key: string]: ValueType
}

export interface WurrlyAppcues extends Appcues {
	group: (groupId: string, groupProperties?: GroupProperties) => void
}

export type HasuraComparisonOperators =
	| '_eq'
	| '_neq'
	| '_in'
	| '_nin'
	| '_gt'
	| '_lt'
	| '_gte'
	| '_lte'
	| '_like'
	| '_nlike'
	| '_ilike'
	| '_nilike'
	| '_similar'
	| '_nsimilar'
	| '_regex'
	| '_iregex'
	| '_nregex'
	| '_niregex'

export type ComparisonObject = {
	[dbField: string]: {
		[comparison in HasuraComparisonOperators]: string
	}
}

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export enum InstrumentsGuideType {
	piano = 'piano',
	guitar = 'guitar',
	ukulele = 'ukulele',
	audio = 'audio'
}

export type Chord = {
	name: string
}

export type RecordStudioLocationState = {
	speed: number
	transposition: number
	instrument: string
	classId: number | undefined
	submissionId: number | undefined
	wurrlyType: Wurrly_Type_Enum
	challengeId: number | undefined
}

export type StudioReviewLocationState = {
	audioUrl: string
	videoUrl: string
	trackId: number
	isCameraOn: boolean
	classId: number
	submissionId: number
	stickers: StickerElement[]
	wurrlyType: Wurrly_Type_Enum
	challengeId?: number
	color?: SelectedColorFilter
	frame?: Filter_Frame
	isBlankSong?: boolean
}

export type extractLevelsProps = {
	selected?: number[]
	options?: Grade[]
}

export type SelectedEventType = React.ChangeEvent<{
	name?: string | undefined
	value: unknown
}>

export interface ClassWithCounters extends Class {
	totalVideos: number
	totalLessons: number
}
