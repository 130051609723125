import React from 'react'

import { Typography, Button, PropTypes, ButtonProps, Tooltip } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { DoubleMusicNoteIcon, LessonsIcon, StarIcon, VideoIcon } from 'src/assets/icons/CustomIcons'

const icons = {
	musicNote: DoubleMusicNoteIcon,
	videos: VideoIcon,
	notebook: LessonsIcon,
	add: AddCircleIcon,
	star: StarIcon
}
type IconButtonProps = ButtonProps & {
	text: string
	icon?: keyof typeof icons
	color?: PropTypes.Color
	style?: React.CSSProperties
	tooltipTitle?: string
}

export const IconButton = ({ text, icon, color = 'default', style, tooltipTitle, ...props }: IconButtonProps) => {
	const Icon = icon ? icons[icon] : null

	return (
		<Tooltip title={tooltipTitle || ''}>
			<Button
				variant="contained"
				color={color}
				startIcon={Icon ? <Icon /> : <></>}
				{...props}
				style={{ height: '2.5em', ...style }}>
				<Typography variant="body2">
					<strong>{text}</strong>
				</Typography>
			</Button>
		</Tooltip>
	)
}
