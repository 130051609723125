import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Select } from 'src/components'

import { getCyKey, SortOrder } from '../utils'

type SelectSortProps = {
	cyKey?: string
	value: SortOrder
	showRecent?: boolean
	showDateAdded?: boolean
	showType?: boolean
	notAlphabetical?: boolean
	dueDate?: boolean
	dateSubmitted?: boolean
	grade?: boolean
	leaderboard?: boolean
	onChange?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => void
}

export const SelectSort = ({
	value,
	showRecent,
	onChange,
	cyKey = '',
	showType,
	notAlphabetical = false,
	showDateAdded,
	dueDate,
	dateSubmitted,
	grade,
	leaderboard
}: SelectSortProps) => {
	const options = []

	if (showType) {
		options.push({ name: 'Type', value: SortOrder.Type })
	}
	if (showRecent) {
		options.push({ name: 'Most Recent', value: SortOrder.Recent })
	}
	if (!notAlphabetical) {
		options.push({ name: 'A-Z', value: SortOrder.Up })
		options.push({ name: 'Z-A', value: SortOrder.Down })
	}
	if (showDateAdded) {
		options.push({ name: 'Date Added', value: SortOrder.Recent })
	}
	if (dueDate) {
		options.push({ name: 'Due Date ↑', value: SortOrder.DateAsc })
		options.push({ name: 'Due Date ↓', value: SortOrder.DateDesc })
	}
	if (leaderboard) {
		options.push({ name: 'Higher Score ↑', value: SortOrder.DateAsc })
		options.push({ name: 'Lower Score ↓', value: SortOrder.DateDesc })
	}
	if (dateSubmitted) {
		options.push({ name: 'Date Submitted', value: SortOrder.Recent })
	}
	if (grade) {
		options.push({ name: 'Grade', value: SortOrder.Grade })
	}

	return (
		<Box display="flex" alignItems="center">
			<Typography style={{ marginRight: '10px' }}>Sort By</Typography>
			<Select data-cy={getCyKey(SelectSort, cyKey)} options={options} onChange={onChange} value={value} />
		</Box>
	)
}
