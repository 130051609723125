import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useGetTipDataByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { LIMIT, ProductTitleEnum, buildRouteParameters } from 'src/utils'
export const useVideosWrapper = () => {
	const history = useHistory()
	const { classId } = useWurrlyParams()
	const [count, setCount] = useState(0)
	const [data, setData] = useState<Tip[]>([])

	const type = ProductTitleEnum.Tip

	const handleViewAll = () => {
		history.push(
			buildRouteParameters(Pages.AllClassProducts, {
				classId: Number(classId),
				type: ProductTitleEnum.Tip
			})
		)
	}

	const [getVideos, { data: videosData, loading }] = useGetTipDataByClassIdLazyQuery()

	useEffect(() => {
		getVideos({
			variables: {
				limit: LIMIT,
				classId: Number(classId)
			}
		})
	}, [])
	useEffect(() => {
		if (videosData) {
			setCount(videosData.tip_aggregate.aggregate?.count || 0)
			setData(videosData.tip as Tip[])
		}
	}, [videosData])

	return {
		loading,
		data,
		count,
		handleViewAll,
		type
	}
}
