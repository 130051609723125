import { Fragment } from 'react'

import { Box, Divider, List, makeStyles, Theme } from '@material-ui/core'

import { Student } from '../../../../../../../../graphql/autogenerate/schemas'
import { buildImagePath, concatenate, getCyKey } from '../../../../../../../../utils'
import { ListAddItemStudent } from './ListAddItemStudent'

const useStyles = makeStyles((theme: Theme) => ({
	boxSongs: {
		padding: `0 0 ${(spacing: number) => theme.spacing(spacing)}`,
		'& .MuiBox-root': {
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

type ListItemsProps = {
	spacing?: number
	lineDivider?: boolean
	data: ({ selected: boolean; integrationId?: string } & Student)[]
	onSelect: (item: { selected: boolean } & Student) => void
	hideCheckbox?: boolean
}

export const ListAddItems = ({ spacing = 0, lineDivider, data, onSelect, hideCheckbox }: ListItemsProps) => {
	const classes = useStyles(spacing)

	return (
		<Fragment>
			<List className={classes.boxSongs}>
				{data.map((item, i) => (
					<Box
						data-cy={getCyKey(ListAddItems, 'List')}
						style={{
							cursor: 'pointer',
							backgroundColor: item.selected ? 'rgba(255, 194, 12, 0.1)' : '#fff'
						}}
						key={concatenate([i, item.student_id, item.first_name], '-')}
						overflow="hidden"
						boxShadow={spacing > 0 ? 1 : 0}
						margin="5px"
						borderRadius={spacing > 0 ? 4 : 0}
						onClick={() => {
							onSelect(item)
						}}>
						<ListAddItemStudent
							imageUrl={buildImagePath(item.image_path)}
							name={`${item.first_name} ${item.last_name}`}
							email={item.email || ''}
							selected={item.selected}
							classesName={item?.class_students?.map((cls) => ' ' + cls?.class?.title).toString()}
							hideCheckbox={hideCheckbox}
						/>
						{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
					</Box>
				))}
			</List>
		</Fragment>
	)
}
