import { Fragment, useState } from 'react'

import { Box, Divider, List } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { Catalog, Catalog_Item, Sequence, Sequence_Topic } from '../../../../../graphql/autogenerate/schemas'
import { Pages } from '../../../../../routes/teacherPages'
import { concatenate } from '../../../../../utils'
import { buildRouteParameters } from '../../../../../utils/routes-utils'
import { AddSequenceItemToClass } from '../scenes/Sequences/components/AddSequenceItemToClass'
import { ListItemSequence } from './ListItemSequence'

const useStyles = makeStyles((theme: Theme) => ({
	boxSequence: {
		padding: 0,
		'& .listItem': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

type ListSequencesProps = {
	spacing?: number
	lineDivider?: boolean
	sequenceTopic: Sequence_Topic
	data: Sequence[]
	catalog: Catalog
	catalogItem: Catalog_Item
}

export const ListSequence = ({
	spacing = 0,
	lineDivider,
	sequenceTopic,
	data,
	catalog,
	catalogItem
}: ListSequencesProps) => {
	const history = useHistory()

	const classes = useStyles(spacing)
	const [addToClassDialog, setAddToClassDialog] = useState<Sequence>()
	const [isAddPlaylisToClassOpen, setIsAddPlaylistToClassOpen] = useState(false)

	return (
		<Fragment>
			<List className={classes.boxSequence}>
				{data &&
					data.map((sequence, i) => (
						<Box
							className="listItem"
							style={{
								cursor: 'pointer'
							}}
							key={concatenate([i, sequence.sequence_id, sequence.name], '-')}
							overflow="hidden"
							boxShadow={spacing > 0 ? 1 : 0}
							borderRadius={spacing > 0 ? 4 : 0}
							onClick={() => {
								history.push(
									buildRouteParameters(Pages.SequenceItemDetail, {
										catalogId: catalog.catalog_id,
										catalogItemId: catalogItem.catalog_item_id,
										sequenceTopicId: sequenceTopic.sequence_topic_id,
										sequenceItemId: sequence.sequence_id
									})
								)
							}}>
							<ListItemSequence
								id={sequence.sequence_id}
								imageUrl={sequence.image_path ?? ''}
								title={`${sequence.name}`}
								description={sequence.description}
								genres={`•`}
								footer={`${
									sequence.sequence_lesson_plans_aggregate.aggregate?.count ?? 0
								} Lessons`}
								btnAddClass={{
									onClick: (e) => {
										e.stopPropagation()
										setAddToClassDialog(sequence)
										setIsAddPlaylistToClassOpen(true)
									}
								}}
								onClick={(e) => {
									e.stopPropagation()
									history.push(
										buildRouteParameters(Pages.SequenceItemDetail, {
											catalogId: catalog.catalog_id,
											catalogItemId: catalogItem.catalog_item_id,
											sequenceTopicId: sequenceTopic.sequence_topic_id,
											sequenceItemId: sequence.sequence_id
										})
									)
								}}
							/>
							{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
						</Box>
					))}
			</List>
			<AddSequenceItemToClass
				isOpen={isAddPlaylisToClassOpen}
				setIsOpen={setIsAddPlaylistToClassOpen}
				sequence={addToClassDialog}
			/>
		</Fragment>
	)
}
