import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useDoughnutChartStyles = makeStyles((theme: Theme) =>
	createStyles({
		titleHolder: {
			position: 'relative',
			width: 400,
			textAlign: 'center'
		},
		title: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%,-50%)'
		},
		labelsHolder: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: 5,
			flexWrap: 'wrap'
		},
		labelReferenceHolder: {
			alignItems: 'center',
			margin: theme.spacing(1),
			display: 'flex'
		},
		labelReference: {
			height: '20px',
			width: '20px',
			marginRight: '5px'
		}
	})
)

export default useDoughnutChartStyles
