import { Card, Box, Divider } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { SongPlayer } from 'src/components'
import { AssetForListItem, ListAssetItem } from 'src/components/ListItems/ListAssetItem/ListAssetItem'
import { ListSongItem, SongForListItem } from 'src/components/ListItems/ListSongItem/ListSongItem'
import { ListVideoItem, TipForListItem } from 'src/components/ListItems/ListVideoItem/ListVideoItem'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { VideoPlayer } from 'src/scenes/Teacher/scenes/8-Videos/components'
import { buildImagePath, getCyKey } from 'src/utils/utils'

import useStyles from './AssignmentAttachments.styles'
import { useSongForSongPlayer } from './hooks/useSongForSongPlayer'
import { useVideoForVideoPlayer } from './hooks/useVideoForVideoPlayer'

type AssignmentAttachmentsProps = {
	gradingSystemLabel: string
	songs: SongForListItem[]
	videos: TipForListItem[]
	assets: AssetForListItem[]
}

export const AssignmentAttachments = ({
	gradingSystemLabel,
	songs,
	videos,
	assets
}: AssignmentAttachmentsProps) => {
	const { teacherData: teacher } = useLoginContext()
	const styles = useStyles()

	const {
		setSelectedSong,
		setSelectedSongId,
		selectedSongId,
		viewSongDetails,
		setViewSongDetails,
		handleSongFavorite,
		loadingSongFavorite,
		isSongFavorite
	} = useSongForSongPlayer()

	const { selectedVideo, setSelectedVideo, loadingVideoFavorite, isVideoFavorite, handleFavoriteVideo } =
		useVideoForVideoPlayer()

	const onSongClick = (song: SongForListItem) => {
		setSelectedSong(song)
		setSelectedSongId(song.song_id)
	}
	const onVideoClick = (video: TipForListItem) => {
		setSelectedVideo(video)
	}
	const onAssetClick = (asset: AssetForListItem) => {
		window.open(buildImagePath(asset.resource_path), '_blank')?.focus()
	}

	return (
		<>
			<Card>
				<Box className={styles.section}>
					<Typography variant="h6" className={styles.title}>
						Grading System
					</Typography>
					<Typography
						data-cy={getCyKey(AssignmentAttachments, 'grade')}
						variant="body1"
						className={styles.gradingSystem}>
						{gradingSystemLabel}
					</Typography>
				</Box>

				<Divider />

				<Box className={styles.section}>
					<Typography variant="h6" className={styles.title}>
						Songs
					</Typography>
					<Box>
						{songs.map((song) => (
							<ListSongItem song={song} onClick={onSongClick} key={song.song_id} />
						))}
					</Box>
				</Box>

				<Divider />

				<Box className={styles.section}>
					<Typography variant="h6" className={styles.title}>
						Videos
					</Typography>
					<Box>
						{videos.map((video) => (
							<ListVideoItem tip={video} onClick={onVideoClick} key={video.tip_id} />
						))}
					</Box>
				</Box>

				<Divider />

				<Box className={styles.section}>
					<Typography variant="h6" className={styles.title}>
						Assets
					</Typography>
					<Box>
						{assets.map((asset) => (
							<ListAssetItem asset={asset} onClick={onAssetClick} key={asset.asset_id} />
						))}
					</Box>
				</Box>
			</Card>

			<SongPlayer
				songId={selectedSongId}
				setSongId={setSelectedSongId}
				viewDetails={viewSongDetails}
				setViewDetails={setViewSongDetails}
				loading={loadingSongFavorite}
				isFavorite={isSongFavorite}
				handleFavoriteFn={handleSongFavorite}
				dontAsk={!!teacher?.teacher_setting?.favorites_dont_ask_again_to_delete}
				allTranspositions
			/>
			<VideoPlayer
				video={selectedVideo as Tip}
				setVideo={setSelectedVideo}
				loading={loadingVideoFavorite}
				favoriteVideo={isVideoFavorite}
				handleFavoriteFn={handleFavoriteVideo}
				dontAsk={!!teacher?.teacher_setting?.favorites_dont_ask_again_to_delete}
			/>
		</>
	)
}
