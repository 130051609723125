import { createStyles, makeStyles } from '@material-ui/core'

export const useContactDialogStyles = makeStyles(() =>
	createStyles({
		dialogPaper: {
			minWidth: '30%'
		},
		boldText: {
			fontWeight: 'bold'
		}
	})
)

export default useContactDialogStyles
