import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { Order_By } from 'src/graphql/autogenerate/schemas'
import { SortOrder } from 'src/utils'

import { getCyKey } from '../utils'
type SelectSortProps = {
	cyKey: string
	value: SortOrder | Order_By
	showRecent?: boolean
	onChange?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => void
}

export const SelectSortOrderBy = ({ value, onChange, cyKey }: SelectSortProps) => {
	return (
		<Box display="flex" alignItems="center">
			<Typography style={{ marginRight: '10px' }}>Sort By</Typography>
			<Select
				data-cy={getCyKey(SelectSortOrderBy, cyKey)}
				options={[
					{ name: 'Most Recent', value: SortOrder.Recent },
					{ name: 'A-Z', value: SortOrder.Up },
					{ name: 'Z-A', value: SortOrder.Down }
				]}
				onChange={onChange}
				value={value}
			/>
		</Box>
	)
}
