import { useEffect, useRef, useState } from 'react'

import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import Moveable, {
	MoveableManagerInterface,
	OnDrag,
	OnDragEnd,
	OnResize,
	OnResizeEnd,
	OnRotate,
	OnRotateEnd,
	OnDragStart
} from 'react-moveable'
import { Sticker } from 'src/graphql/autogenerate/schemas'
import { VideoPosition } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'

import useStyles from './ResizableElement.styles'

export type StickerElement = Sticker & {
	renderId: string
	selected: boolean
}

type ResizableElementProps = {
	element: StickerElement
	onDelete: (renderId: string) => void
	updateSticker: (sticker: StickerElement) => void
	onSelect: (sticker: StickerElement) => void
	videoDimensions: VideoPosition
}
export const ResizableElement = ({
	element,
	onDelete,
	updateSticker,
	onSelect,
	videoDimensions
}: ResizableElementProps) => {
	const styles = useStyles()
	const targetRef = useRef<HTMLDivElement>(null)
	const [width, setWidth] = useState(element.width)
	const [height, setHeight] = useState(element.height)
	const [rotation, setRotation] = useState(element.rotation)
	const [positionX, setPositionX] = useState(element.positionX)
	const [positionY, setPositionY] = useState(element.positionY)
	const startedPosition = useRef('')

	const selectSticker = () => setTimeout(() => onSelect(element), 0)

	useEffect(() => {
		updateSticker({
			...element,
			width,
			height,
			rotation,
			positionX,
			positionY
		})
	}, [width, height, rotation, positionX, positionY])

	const DeleteSticker = {
		name: 'dimensionViewable',
		props: {},
		events: {},
		render(moveable: MoveableManagerInterface<unknown, unknown>) {
			const rect = moveable.getRect()

			return (
				<div
					key={'dimension-viewer'}
					className={clsx(
						styles.deleteIcon,
						'moveable-dimension',
						!element.selected ? styles.hiddenDeleteIcon : null
					)}
					style={{
						left: `${rect.width / 2}px`,
						top: `${rect.height + 20}px`
					}}
					onClick={() => onDelete(element.renderId)}>
					<DeleteIcon />
				</div>
			)
		}
	}

	// Handlers

	const onDragStart = (e: OnDragStart) => {
		startedPosition.current = e.target.style.transform
	}

	const onDrag = (e: OnDrag) => {
		e.target.style.transform = e.transform
	}
	const onDragEnd = (e: OnDragEnd) => {
		if (!e.lastEvent) return
		const [x, y] = e.lastEvent.translate
		const { clientX, clientY } = e
		const { maxX, maxY, minX, minY } = videoDimensions

		if (clientX >= minX && clientX <= maxX && clientY >= minY && clientY <= maxY - 45) {
			setPositionX(x)
			setPositionY(y)

			return
		}

		e.target.style.transform = startedPosition.current
	}
	const onRotate = (e: OnRotate) => {
		e.target.style.transform = e.drag.transform
	}
	const onRotateEnd = (e: OnRotateEnd) => {
		if (!e.lastEvent) return
		setRotation(e.lastEvent.beforeRotate)
	}
	const onResize = (e: OnResize) => {
		e.target.style.width = `${e.width}px`
		e.target.style.height = `${e.height}px`
		e.target.style.transform = e.drag.transform
	}
	const onResizeEnd = (e: OnResizeEnd) => {
		if (!e.lastEvent) return
		const width = e.lastEvent.width
		const height = e.lastEvent.height
		setWidth(width)
		setHeight(height)
		if (e?.lastEvent?.drag?.translate?.length) {
			const [x, y] = e.lastEvent.drag.translate
			setPositionX(x)
			setPositionY(y)
		}
	}

	useEffect(() => {
		if (targetRef?.current) {
			const { positionX, positionY, width, height, rotation } = element
			targetRef.current.style.width = `${width}px`
			targetRef.current.style.height = `${height}px`
			targetRef.current.style.transform = `translate(${positionX}px,${positionY}px) rotate(${rotation}deg) scale(1,1)`
		}
	}, [targetRef?.current, element.height, element.width, element.positionX, element.positionY, element.rotation])

	return (
		<div className={clsx(styles.container, !element.selected ? styles.hideControls : null)}>
			<div className={styles.draggableItem} ref={targetRef} onMouseUp={selectSticker}>
				<img src={element.imageUrl} />
			</div>
			<Moveable
				target={targetRef}
				ables={[DeleteSticker]}
				props={{
					dimensionViewable: true
				}}
				draggable={true}
				resizable={true}
				rotatable={true}
				onDragStart={onDragStart}
				onDrag={onDrag}
				onDragEnd={onDragEnd}
				onRotate={onRotate}
				onRotateEnd={onRotateEnd}
				onResize={onResize}
				onResizeEnd={onResizeEnd}
			/>
		</div>
	)
}
