import { useEffect, useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import {
	Category_Standard_Enum,
	Standard,
	Standard_Option,
	Standard_Subcategory
} from 'src/graphql/autogenerate/schemas'

const useSelectData = () => {
	const [hoverItem, setHoverItem] = useState<string | undefined>(undefined)
	const [hoverSubItem, setHoverSubItem] = useState<string | undefined>(undefined)

	const [selectedItems, setSelectedItems] = useState<Record<string, number[]>>({})
	const [selectedCategories, setSelectedCategories] = useState<Category_Standard_Enum[]>([])
	const [options, setOptions] = useState<Standard>({} as Standard)
	const [initialData, setInitialData] = useState<number[]>()
	const [hasUsedInitialData, setHasUsedInitialData] = useState(false)

	const isItemSelected = (item: Standard_Option): boolean => {
		return Object.values(selectedItems).some((category) => category.includes(item.standar_option_id ?? ''))
	}

	/**
	 * Add or remove received item from selectedItems based on its previous state
	 */
	const toggleItem = (item: Standard_Option) => {
		// Item to add/remove should come from the current hoverItem category
		if (hoverItem) {
			const newItems = { ...selectedItems }
			const categoryToModify = newItems[hoverItem]

			if (isItemSelected(item)) {
				// remove
				const index = categoryToModify.findIndex((option) => option === item.standar_option_id)
				if (index !== -1) categoryToModify.splice(index, 1)
				if (categoryToModify.length === 0) delete newItems[hoverItem]
			} else {
				// add
				if (categoryToModify) categoryToModify.push(item.standar_option_id || 0)
				else newItems[hoverItem] = [item.standar_option_id || 0]
			}
			setSelectedItems(newItems)
		}
	}

	const getOptionLabel = (option: Standard_Subcategory): string => {
		let label = option.name
		if (option.name in selectedItems) label += ` (${selectedItems[option.name].length})`

		return label
	}

	const getOptionCategoryLabel = (option: Category_Standard_Enum): string => {
		return option
	}

	const categoryStandards = [
		Category_Standard_Enum.Connecting,
		Category_Standard_Enum.Creating,
		Category_Standard_Enum.Performing,
		Category_Standard_Enum.Responding
	]

	const clearSelection = () => {
		setSelectedItems({})
	}

	useEffect(() => {
		const categories = [] as Category_Standard_Enum[]

		if (categoryStandards.length === 0) return
		if (categoryStandards) {
			for (const item of categoryStandards) {
				if (item in selectedItems) {
					categories.push(item)
				}
			}
		}
		setSelectedCategories(categories)
	}, [selectedItems, options])

	useEffect(() => {
		if (initialData && options && !hasUsedInitialData) {
			const initialSelected = {} as Record<string, number[]>
			for (const subCategory of options.standard_subcategories) {
				const sharedItems = initialData.filter((option) =>
					subCategory.standar_options.some(
						(subCategoryOption) => subCategoryOption.standar_option_id === option
					)
				)
				if (sharedItems.length) initialSelected[subCategory.category_standard] = sharedItems
			}
			setSelectedItems(initialSelected)
			setHasUsedInitialData(true)
		}
	}, [initialData, options])

	return {
		hoverItem,
		hoverSubItem,
		setHoverSubItem,
		setHoverItem,
		selectedItems,
		setSelectedItems,
		isItemSelected,
		toggleItem,
		options,
		setOptions,
		selectedCategories,
		setSelectedCategories,
		getOptionLabel,
		clearSelection,
		setInitialData,
		categoryStandards,
		getOptionCategoryLabel
	}
}

export const [SelectDataProvider, useSelectDataContext] = buildGenericContext(useSelectData)
