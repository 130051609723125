import { useState, useEffect } from 'react'

import { Box, ButtonProps, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import parse from 'html-react-parser'
import moment from 'moment'
import { BaseButton, Datetime } from 'src/components'
import { GradeStar } from 'src/components/GradeStar/GradeStar'
import { StateTypeEnum } from 'src/components/listAssignments/ListItemAssignment.types'
import { formatAMPM, buildImagePath } from 'src/utils'
import { GRADED, START, SUBMITTED } from 'src/utils/constants'

import { getCyKey } from '../../utils/utils'
import useStyles from './ListItemAssignment.styles'

export type ListItemAssignmentProps = {
	imageUrl?: string
	title: string
	instructions: string
	dueDate: string
	dueTime: string
	state: string
	classTitle: string
	genres?: string
	grade?: string
	btnView?: ButtonProps
	expired?: boolean
}

export const ListItemAssignment = ({
	title,
	instructions,
	genres,
	btnView,
	classTitle,
	dueDate,
	state,
	grade,
	dueTime,
	expired = false
}: ListItemAssignmentProps) => {
	const styles = useStyles()
	const [time, setTime] = useState('')

	useEffect(() => {
		// Set Date
		const date = new Date()

		// Set Time
		const [hour, minute] = dueTime.split(':')

		date.setHours(+hour, +minute, 0, 0)
		const time = formatAMPM(date)
		setTime(time)
	}, [])

	return (
		<ListItem className={styles.listItem}>
			<ListItemAvatar className={styles.avatar}>
				<Box className={styles.relative}>
					<GradeStar state={state} grade={grade} />
				</Box>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={
					<div>
						<Typography
							data-cy={getCyKey(ListItemAssignment, 'className')}
							noWrap
							className={styles.classTitle}>
							<b>{classTitle}</b>
						</Typography>
						<Typography
							data-cy={getCyKey(ListItemAssignment, 'assignmentName')}
							className={styles.itemTitle}
							variant="h6">
							<b>{title}</b>
						</Typography>
						<Typography noWrap className={styles.instructions}>
							{parse(instructions)}
						</Typography>
						<Datetime
							className={styles.calender}
							color="secondary"
							text={`${moment(dueDate).format('ddd, DD MMM')} - ${time}`}
						/>
					</div>
				}
				secondary={
					<Typography variant="caption" color="textSecondary">
						<b>{genres}</b>
					</Typography>
				}
			/>
			{!expired && (
				<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
					<div className={styles.divStyle} />

					<img
						data-cy={getCyKey(ListItemAssignment, 'imageState')}
						src={buildImagePath(
							state === StateTypeEnum.Graded
								? GRADED
								: state === StateTypeEnum.Submitted
								? SUBMITTED
								: START
						)}
					/>

					{btnView && (
						<Tooltip title="View">
							<BaseButton
								data-cy={getCyKey(ListItemAssignment, 'viewButton')}
								color="primary"
								className={'addClass'}
								endIcon={<ArrowForwardIcon />}
								{...btnView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
					)}
				</Box>
			)}
		</ListItem>
	)
}
