import { Fragment } from 'react'

import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Fab,
	FormControlLabel,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import TimerIcon from '@material-ui/icons/Timer'
import { EditIcon, FullBandIcon, GuitarIcon, PianoIcon } from 'src/assets/icons'
import { BaseSlider } from 'src/components/BaseSlider'
import { BigBaseButton } from 'src/components/Buttons/BigBaseButton'
import { SelectChord } from 'src/components/SelectChord'
import { Song } from 'src/graphql/autogenerate/schemas'
import { buildImagePath, getSongGenres, SONG_KEYS } from 'src/utils'

import { BaseDialog, BaseDialogProps } from '../BaseDialog/BaseDialog'
import useStyles from './StudioSongPlayerDialog.styles'
import { useStudioSongPlayer } from './useStudioSongPlayerDialog'

export type StudioSongPlayerDialogProps = ReturnType<typeof useStudioSongPlayer> &
	BaseDialogProps & {
		onEdit?: () => void
		onDelete?: () => void
		disableDelete?: boolean
	}

export type StudioSongPlayerDialogInitialState = {
	vocalGuide?: string
	instrument?: string
	speed?: number
	key?: number
}

export const StudioSongPlayerDialog = ({
	loaded,
	music,
	instrument,
	vocalGuide,
	songLoading,
	playing,
	currentTime,
	progress,
	duration,
	speed,
	songKey,
	chords,
	handleSelectInstrument,
	handleControl,
	formatTime,
	handleTimeChange,
	handleActivate,
	handleSpeedChange,
	handleKeyChange,
	onClose,
	onEdit,
	onDelete,
	disableDelete,
	isBlankSong,
	justIncludeVocalGuide,
	...props
}: StudioSongPlayerDialogProps) => {
	const instruments = [
		{
			id: 'Piano',
			name: 'Piano',
			icon: <PianoIcon />
		},
		{
			id: 'Band',
			name: 'Full Band',
			icon: <FullBandIcon />
		},
		{
			id: 'Guitar',
			name: 'Guitar',
			icon: <GuitarIcon />
		}
	]

	const styles = useStyles({ loaded })

	const renderInstrumentList = () => {
		return instruments.map((inst) => (
			<Grid item xs key={inst.id}>
				<Box
					boxShadow={
						!music?.tracks?.some((track) => track?.track_type?.name === inst.id) ||
						(instrument !== inst.id && vocalGuide)
							? 1
							: 0
					}>
					<Button
						className={instrument === inst.id ? styles.buttonMatched : styles.button}
						fullWidth
						disabled={
							!music?.tracks?.some((track) => track?.track_type?.name === inst.id) ||
							(instrument !== inst.id && vocalGuide)
						}
						startIcon={inst.icon}
						variant={instrument === inst.id ? 'outlined' : 'contained'}
						color={instrument === inst.id ? 'secondary' : undefined}
						onClick={() => handleSelectInstrument(inst.id)}>
						{inst?.name}
						{instrument === inst.id && (
							<Box position="absolute" top={8} right={8}>
								<CheckCircleIcon fontSize="small" color="secondary" />
							</Box>
						)}
					</Button>
				</Box>
			</Grid>
		))
	}

	return (
		<BaseDialog
			maxWidth="md"
			fullWidth={true}
			onClose={onClose}
			paperProps={{ className: styles.container }}
			style={isBlankSong ? { height: '50%' } : undefined}
			header={
				<>
					<Box>
						<Typography className={styles.title} component="h1">
							Set Up
						</Typography>
						<Typography className={styles.description}>
							You can tailor the song to suit you, by choosing the accompaniment, speed and key
							below, then click Next to get started!
						</Typography>
					</Box>
					{!songLoading && (
						<Fragment>
							<ListItem className={styles.listItem}>
								<ListItemAvatar>
									<Avatar
										variant="rounded"
										className={styles.avatar}
										src={buildImagePath(music?.image_path || '')}
									/>
								</ListItemAvatar>
								<Box width="100%">
									<ListItemText
										className={styles.listItemText}
										primary={
											<div>
												<Typography variant="h6">
													<b>{music?.title}</b>
												</Typography>
												<Typography className={styles.tipoArtist}>
													<b>{music?.artist?.name}</b>
												</Typography>
											</div>
										}
										secondary={music ? getSongGenres(music as unknown as Song) : ''}
									/>
									{!isBlankSong && (
										<Box className={styles.boxFab}>
											<Fab className={styles.fab} size="medium" onClick={handleControl}>
												{loaded ? (
													playing ? (
														<PauseIcon color="secondary" />
													) : (
														<PlayArrowIcon color="secondary" />
													)
												) : (
													<CircularProgress
														color="secondary"
														className={styles.circularProgress}
													/>
												)}
											</Fab>
											<Box className={styles.boxSongTime} boxShadow={1}>
												<Typography className={styles.tipoSongTime}>
													{formatTime(currentTime)} / {formatTime(duration)}
												</Typography>
												<Box className={styles.boxSlider}>
													<BaseSlider
														value={progress}
														onChange={handleTimeChange}
														aria-labelledby="continuous-slider"
														color="secondary"
													/>
												</Box>
											</Box>
										</Box>
									)}
								</Box>
							</ListItem>
						</Fragment>
					)}
				</>
			}
			body={
				!isBlankSong && (
					<Box className={styles.dialogBody}>
						<Box className={styles.boxBody}>
							<Typography>
								<b>Choose your accompaniment</b>
							</Typography>
							<div style={{ flexGrow: 1 }} />

							<FormControlLabel
								className={styles.vocalGuide}
								control={
									<Checkbox
										checked={vocalGuide || justIncludeVocalGuide}
										onChange={handleActivate}
										name="Activate Vocal Guide"
										color="secondary"
										disabled={
											!music?.tracks?.some((track) => track.track_type.name === 'Vocal') ||
											justIncludeVocalGuide
										}
									/>
								}
								label={
									<Typography className={styles.tipoActivate}>Activate Vocal Guide?</Typography>
								}
							/>
						</Box>
						<Grid container spacing={1}>
							{renderInstrumentList()}
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs>
								<Box mt={2.5} mb={1}>
									<Typography>
										<b>Speed</b>
									</Typography>
								</Box>
								<Box className={styles.boxTimer} boxShadow={1}>
									<TimerIcon fontSize="small" />
									<Box className={styles.boxContainerSlider}>
										<BaseSlider
											value={speed}
											onChange={handleSpeedChange}
											aria-labelledby="discrete-slider"
											color="secondary"
											valueLabelDisplay="off"
											step={0.1}
											marks
											min={-0.3}
											max={0.3}
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item xs>
								<Box mt={2.5} mb={1}>
									<Typography>
										<b>Key</b>
									</Typography>
								</Box>
								<Box className={styles.boxSliderKey} boxShadow={1}>
									<MusicNoteIcon fontSize="small" />
									<Box className={styles.boxContainerSlider}>
										<BaseSlider
											value={songKey}
											onChange={handleKeyChange}
											aria-labelledby="discrete-slider"
											color="secondary"
											valueLabelDisplay="on"
											step={1}
											marks
											min={SONG_KEYS[0]}
											max={SONG_KEYS[SONG_KEYS.length - 1]}
										/>
									</Box>
								</Box>
							</Grid>
						</Grid>
						{chords && !!chords.length && (
							<>
								<Box className={styles.boxChords}>
									<Typography>
										<b>Corresponding Chords</b>
									</Typography>
								</Box>
								<SelectChord
									showGreyButtons
									showSelectedOnly
									selectedChords={chords}
									disabled
									columns={8}
								/>
							</>
						)}
					</Box>
				)
			}
			leftActions={
				<Box>
					<BigBaseButton className={styles.nextBtn} onClick={() => onClose?.({}, 'backdropClick')}>
						Close
					</BigBaseButton>
				</Box>
			}
			rightActions={
				<Box className={styles.boxNextBtn}>
					<IconButton className={styles.actionButton} onClick={onEdit}>
						<EditIcon />
					</IconButton>
					<IconButton
						disabled={Boolean(disableDelete)}
						className={styles.actionButton}
						onClick={onDelete}>
						<DeleteIcon />
					</IconButton>
				</Box>
			}
			{...props}
		/>
	)
}
