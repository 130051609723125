export const COLORS = {
	default: {
		main: '#e0e0e0'
	},
	primary: {
		main: '#221f31',
		light: '#4a465a',
		dark: '#000009',
		contrastText: '#E2E2E4',
		text: '#1D273D'
	},
	secondary: {
		main: '#ffc20c',
		light: '#FFEFD3',
		dark: '#F3AB34',
		contrastText: '#323342'
	},
	error: {
		main: '#f44336',
		light: '#F7CFCA',
		dark: '#d32f2f',
		contrastText: '#E3E1ED'
	},
	success: {
		main: '#00BA70'
	},
	info: {
		main: '#7367A3',
		dark: '#4B446D',
		light: 'rgba(115, 103, 163, 0.16)'
	},
	warning: {
		main: '#D1491F'
	},
	custom: {
		main: '#D1491F'
	},
	white: '#FFFFFF',
	black: '#000000',
	lightgrey: '#CCCED3',
	transparent: 'transparent',
	blue: '#369EB3',
	yellow: '#F3AB34',
	purple: '#B34487',
	charts: ['#FA9A29', '#BBC419', '#1486BB', '#9F6EB2', '#F6E139', '#37296B'],
	orange: '#D1491F',
	appBackground: '#F3F5F8'
}
