import { Box, Typography } from '@material-ui/core'

import useStyles from './StudioHeader.styles'

type StudioHeaderTypes = {
	title: string
	description: string
}
export const StudioHeader = ({ title, description }: StudioHeaderTypes) => {
	const styles = useStyles()

	return (
		<Box>
			<Typography className={styles.title} component="h1">
				{title}
			</Typography>
			<Typography className={styles.description}>{description}</Typography>
		</Box>
	)
}
