import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useVideoUploadStyle = makeStyles(() =>
	createStyles({
		buttonDisabled: {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: 35,
			'& .MuiButton-contained.Mui-disabled': {
				backgroundColor: 'rgba(255, 194, 12, 0.4)'
			}
		},
		terms: {
			fontSize: 18,
			maxWidth: 500
		},
		bigButton: { padding: '6px 85px' },
		containerOptions: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		boxOption: {
			flexBasis: 'calc(50% - 15px)'
		},
		boxTitle: {
			marginBottom: 15
		}
	})
)

export default useVideoUploadStyle
