import { Box, createStyles, fade, makeStyles, Theme, Typography, useTheme } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useHistory } from 'react-router'

import { PagesProps } from '../../../../../../../@types'
import { BigBaseButton, MainTitle } from '../../../../../../../components'
import { BOX_PADDING } from '../../../../../../../styles/constants'
import { buildBreadCrumbs, buildImagePath, slugToName } from '../../../../../../../utils'
import { useClassAnalyticsContext } from '../../../../../hooks/useClassAnalytics'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: fade(theme.palette.common.black, 0.4)
		},
		mainTitle: {
			color: theme.palette.background.paper,
			opacity: '0.7'
		}
	})
)

export const ClassAnalyticsTitle = ({ page }: PagesProps) => {
	const theme = useTheme()
	const history = useHistory()
	const styles = useStyles()

	const { classId, classData } = useClassAnalyticsContext()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.title || '')
			},
			{
				index: 2,
				overrideName: 'Analytics'
			}
		],
		params: {
			classId
		}
	})

	return (
		<Box
			className={styles.root}
			p={BOX_PADDING}
			style={{
				backgroundImage: `${'linear-gradient(0deg, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 100%, 0) 100%)'},url(${buildImagePath(
					classData?.image_path ?? ''
				)})`,
				backgroundColor: fade(theme.palette.primary.dark, 0.8),
				backgroundSize: 'cover'
			}}>
			<MainTitle
				breadcrumbs={breadcrumbs}
				breadcrumbPageStyle={styles.mainTitle}
				title={
					<Typography variant="h3" style={{ color: theme.palette.background.paper }}>
						<b>{classData?.title ?? ''}</b>
					</Typography>
				}
				description={
					<Typography className={styles.mainTitle}>
						Here you can see all the Analytics data for your Class.
					</Typography>
				}
				actionButtons={[
					<BigBaseButton
						key={0}
						style={{
							padding: '0px 30px'
						}}
						color="primary"
						startIcon={<ArrowBack />}
						onClick={() => {
							history.goBack()
						}}>
						<b>Back to Class Details</b>
					</BigBaseButton>
				]}
			/>
		</Box>
	)
}
