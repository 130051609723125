import { useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ClassWithCounters, Page } from 'src/@types'
import { ClassCard } from 'src/components/Cards/ClassCard/ClassCard'

import { Class } from '../graphql/autogenerate/schemas'
import { buildRouteParameters, generateUniqueId } from '../utils'
import { getCyKey } from '../utils/utils'

type ClassListProps = {
	classes: ClassWithCounters[]
	pages: Record<string, Page>
	skipIfOnlyOne?: boolean
}

export const ClassList = ({ classes, pages, skipIfOnlyOne }: ClassListProps): JSX.Element => {
	const history = useHistory()

	const goToClassDetail = (classElement: Class) => {
		const newUrl = buildRouteParameters(pages.ClassDetail, {
			classId: classElement.class_id
		})
		history.push(newUrl)
	}

	useEffect(() => {
		if (skipIfOnlyOne && classes.length === 1) goToClassDetail(classes[0])
	}, [classes])

	return (
		<Grid container spacing={1}>
			<Grid container item xs={12} spacing={3}>
				{classes &&
					classes.map((classElement) => (
						<Grid
							data-cy={getCyKey(ClassList, classElement.title)}
							item
							xs={6}
							key={generateUniqueId()}>
							<ClassCard classElement={classElement} pages={pages} />
						</Grid>
					))}
			</Grid>
		</Grid>
	)
}
