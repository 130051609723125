import { useEffect, useState } from 'react'

import {
	useGetCleverAccountLazyQuery,
	useGetCleverGroupsLazyQuery,
	useGetCleverOAuthUrlQuery,
	useGetCleverStudentsByCourseIdLazyQuery,
	useGetCleverTokenLazyQuery,
	useGetTeacherIntegrationLazyQuery,
	useInsertTeacherIntegrationMutation
} from '../../graphql/autogenerate/hooks'
import { CleverGroup, Integration_Name_Enum } from '../../graphql/autogenerate/schemas'
import { IntegrationAccount, IntegrationStudent } from '../useIntegration'

type CleverIntanceType = {
	setClasses: (classes: CleverGroup[]) => void
	setStudents: (students: IntegrationStudent[]) => void
	setLoadingClasses: (loading: boolean) => void
	setLoadingStudents: (loading: boolean) => void
	teacherId: number
	setTeacherIntegrationId: (id: number) => void
}
export const useCleverInstance = ({
	setClasses,
	setStudents,
	setLoadingClasses,
	setLoadingStudents,
	teacherId,
	setTeacherIntegrationId
}: CleverIntanceType) => {
	const [account, setAccount] = useState<IntegrationAccount>()
	const [isOpen, setIsOpen] = useState(false)
	const [districtId, setDistrictId] = useState('')
	const [
		getCleverToken,
		{ data: cleverTokenData, loading: loadingCleverTokenData, error: cleverTokenDataError }
	] = useGetCleverTokenLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const { data: loginUrl } = useGetCleverOAuthUrlQuery({
		variables: { districtId },
		fetchPolicy: 'no-cache'
	})
	const [getCleverStudents, { data: cleverStudentsData, loading: loadingStudents }] =
		useGetCleverStudentsByCourseIdLazyQuery({ fetchPolicy: 'no-cache' })
	const [getCleverCourses, { data: cleverCoursesData, loading: loadingCourses }] = useGetCleverGroupsLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [getCleverAccount, { data: cleverAcountData, loading: cleverAccountLoading }] =
		useGetCleverAccountLazyQuery({ fetchPolicy: 'no-cache' })
	const [
		teacherIntegrationMutation,
		{ data: teacherIntegrationMutationData, loading: teacherIntegrationMutationLoading }
	] = useInsertTeacherIntegrationMutation({ fetchPolicy: 'no-cache' })
	const [getTeacherIntegration, { data: teacherIntegrationData, loading: loadingTeacherIntegration }] =
		useGetTeacherIntegrationLazyQuery({ fetchPolicy: 'no-cache' })

	const queriesStates = {
		loadingStudents,
		loadingCourses,
		cleverAccountLoading,
		loadingCleverTokenData,
		loadingTeacherIntegration
	}

	const getCleverData = (token: string) => {
		if (token === account?.token && cleverCoursesData?.getCleverGroups) {
			setClasses(cleverCoursesData.getCleverGroups as CleverGroup[])
		} else {
			setAccount({
				...account,
				token
			})
		}
		getCleverCourses({
			variables: {
				token
			}
		})
		getCleverAccount({
			variables: {
				token
			}
		})
	}
	// getting the courses when we get the token from be
	useEffect(() => {
		if (cleverTokenData?.getCleverToken && !loadingCleverTokenData)
			getCleverData(cleverTokenData?.getCleverToken)
	}, [cleverTokenData, loadingCleverTokenData])

	useEffect(() => {
		if (account?.token && !loadingCleverTokenData) getCleverData(account?.token as string)
	}, [account?.token, loadingCleverTokenData])

	useEffect(() => {
		if (cleverCoursesData) {
			setAccount({
				...account,
				courses: cleverCoursesData?.getCleverGroups?.map((i) => ({ name: i?.name, id: i?.id }))
			})
		}
	}, [cleverCoursesData])
	useEffect(() => {
		if (cleverAcountData && !cleverAccountLoading) {
			const teacherEmail = cleverAcountData?.getCleverAccount?.email
			const integrationId = cleverAcountData?.getCleverAccount?.id
			if (teacherId && teacherEmail)
				getTeacherIntegration({
					variables: {
						teacherId,
						integrationName: Integration_Name_Enum.Clever,
						teacherEmail: teacherEmail as string
					}
				})
			if (teacherEmail && integrationId)
				setAccount({
					...account,
					teacherEmail: teacherEmail as string,
					integrationId
				})
		}
	}, [cleverAcountData])

	useEffect(() => {
		if (
			teacherIntegrationData &&
			teacherIntegrationData?.teacher_integration.length === 0 &&
			!loadingTeacherIntegration &&
			teacherId &&
			account?.token &&
			!teacherIntegrationMutationLoading &&
			!teacherIntegrationMutationData &&
			cleverAcountData?.getCleverAccount?.id
		) {
			insertTeacherIntegration()
		} else if (teacherIntegrationData?.teacher_integration[0]?.teacher_integration_id) {
			setTeacherIntegrationId(teacherIntegrationData.teacher_integration[0].teacher_integration_id)
		} else if (
			!teacherIntegrationData?.teacher_integration.length &&
			teacherIntegrationMutationData?.insert_teacher_integration_one?.teacher_integration_id
		) {
			setTeacherIntegrationId(
				teacherIntegrationMutationData?.insert_teacher_integration_one?.teacher_integration_id
			)
		}
	}, [
		teacherIntegrationData,
		loadingTeacherIntegration,
		teacherIntegrationMutationData,
		teacherIntegrationMutationLoading,
		cleverAcountData
	])

	// set students
	useEffect(() => {
		if (cleverStudentsData?.getCleverMembers) {
			setStudents(
				// The clever api return the teacher as a student, so we remove the student that match the teacher email
				cleverStudentsData.getCleverMembers
					.filter((i) => i?.email !== cleverAcountData?.getCleverAccount?.email)
					.map((i) => ({
						email: i?.email || '',
						name: { first: i?.name?.first, last: i?.name?.last },
						integrationId: i?.id
					})) as IntegrationStudent[]
			)
		}
	}, [cleverStudentsData])

	// set classes
	useEffect(() => {
		if (cleverCoursesData?.getCleverGroups) {
			setClasses(cleverCoursesData.getCleverGroups as CleverGroup[])
		} else if (cleverCoursesData?.getCleverGroups === null) {
			setClasses([])
		}
	}, [cleverCoursesData])

	useEffect(() => {
		setLoadingClasses(loadingCourses)
	}, [loadingCourses])

	useEffect(() => {
		setLoadingStudents(loadingStudents)
	}, [loadingStudents])

	// inserting the teacher_integration in db
	const insertTeacherIntegration = () => {
		if (account?.integrationId && teacherId) {
			const { integrationId: integration_id, teacherEmail: email } = account
			teacherIntegrationMutation({
				variables: {
					teacher: {
						teacher_id: teacherId,
						integration_name: Integration_Name_Enum.Clever,
						integration_id,
						email
					}
				},
				update: (cache, { data }) => {
					const integrationCacheUpdate = data?.insert_teacher_integration_one
					if (!integrationCacheUpdate) return
					const identify = cache.identify(integrationCacheUpdate)
					cache.evict({
						id: identify,
						fieldName: 'teacher_integration'
					})
				}
			})
		}
	}
	const openPopup = () => {
		if (isOpen) return

		return new Promise((resolve) => {
			setIsOpen(true)
			const url = loginUrl?.getCleverAuthUrl || ''
			const loginWindow = window.open(url, 'name', 'height=600,width=450')
			if (loginWindow) {
				loginWindow.focus()

				try {
					const oauthInterval = setInterval(() => {
						try {
							if (loginWindow.closed) {
								setIsOpen(false)
								clearInterval(oauthInterval)
							}
							if (!loginWindow.location.href.includes('about:blank')) {
								const codeURL = new URL(loginWindow.location.href)
								const code = codeURL.searchParams.get('code')
								if (!code) return
								getCleverToken({ variables: { code } })
								setClasses([])
								loginWindow.close()
								resolve(true)
							}
						} catch {
							// reject(e)
						}
					}, 200)
				} catch (e) {
					// reject(e)
				}
			}
		})
	}

	const getClassStudents = (classId: string) => {
		setStudents([])
		if (account?.token)
			getCleverStudents({
				variables: {
					token: account.token as string,
					groupId: classId
				}
			})
	}

	const clearToken = () => {
		if (account) setAccount({ ...account, token: null })
	}
	const setToken = (token: string) => {
		if (token) {
			setAccount({ ...account, token })
		}
	}

	const clearAccount = () => {
		setAccount({})
	}

	return {
		openPopup,
		getClassStudents,
		clearToken,
		queriesStates,
		account,
		clearAccount,
		error: cleverTokenDataError,
		setToken,
		setDistrictId
	}
}
