import { makeStyles } from '@material-ui/core'

export const useSongFilterStyles = makeStyles(() => ({
	title: {
		fontSize: 18,
		fontWeight: 'bold'
	},
	listItemIcon: {
		minWidth: 'auto'
	},
	modeFields: {
		width: '100%'
	},
	modeFieldLabel: {
		flex: 1,
		marginRight: 0,
		textTransform: 'capitalize'
	}
}))

export default useSongFilterStyles
