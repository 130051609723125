import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { InfoDialog } from 'src/components'
import { StudentPages } from 'src/routes/studentpages'
import { Pages as TeacherPages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

type RedoDialogProps = {
	isOpen: boolean
	setIsOpen: (state: boolean) => void
	currentSongId: number
	classId: number
	submissionId: number
	isTeacher?: boolean
	isBlankSong?: boolean
}
export const RedoDialog = ({
	isOpen,
	setIsOpen,
	currentSongId,
	classId,
	submissionId,
	isTeacher,
	isBlankSong
}: RedoDialogProps) => {
	const history = useHistory()

	const closeRedoDialog = () => {
		setIsOpen(false)
	}
	const getStudentSetupRoute = () => {
		if (submissionId) {
			return !isBlankSong
				? buildRouteParameters(StudentPages.AssignmentStudentStudio, {
						submissionId,
						classId,
						songId: currentSongId
				  })
				: buildRouteParameters(
						StudentPages.StudentRecord,
						{
							songId: currentSongId
						},
						true,
						{
							isBlankSong: isBlankSong.toString(),
							classId: classId.toString(),
							submissionId: submissionId.toString()
						}
				  )
		}

		return !isBlankSong
			? buildRouteParameters(StudentPages.IndieStudentStudio, { classId, songId: currentSongId })
			: buildRouteParameters(
					StudentPages.StudentRecord,
					{
						songId: currentSongId
					},
					true,
					{
						isBlankSong: isBlankSong.toString(),
						classId: classId.toString()
					}
			  )
	}

	const goToSetup = () => {
		history.push(
			isTeacher
				? !isBlankSong
					? buildRouteParameters(TeacherPages.TeacherStudio, { songId: currentSongId })
					: buildRouteParameters(TeacherPages.TeacherRecord, { songId: currentSongId }, true, {
							isBlankSong: isBlankSong.toString()
					  })
				: getStudentSetupRoute()
		)
	}

	return (
		<InfoDialog
			open={isOpen}
			onClose={closeRedoDialog}
			icon="?"
			title={<strong>Redo this song?</strong>}
			body="Are you sure you want to delete this Song?"
			discardLabel="Cancel"
			onDiscard={closeRedoDialog}
			confirmLabel="Delete"
			confirmProps={{ style: { backgroundColor: '#FF3D00', color: 'white' }, startIcon: <DeleteIcon /> }}
			onConfirm={goToSetup}
		/>
	)
}
