import React, { useState } from 'react'

import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { ViewModuleOutlined } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ListIcon from '@material-ui/icons/List'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import clsx from 'clsx'
import { InfoDialog } from 'src/components'
import { Wurrly, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { SelectWurrliesFilter } from 'src/scenes/Student/1-Home/components/SelectWurrliesFilter'
import { StudentDetailProvider, useStudentDetailContext } from 'src/scenes/Student/1-Home/hooks/useStudentDetail'
import { ClassWurrlyType, SortOrder } from 'src/utils'

import { ActionButton, SelectSortOrderBy } from '..'
import { getWurrlyType } from '../../utils/utils'
import { ActivityBoxEmpty } from '../ActivityBoxEmpty'
import { ListWurrlies } from '../ListWurrlies'
import { WurrlyCard } from '../WurrlyCard/WurrlyCard'
import useStyles from './WurrliesGrid.styles'

enum wurrlyView {
	Grid,
	List
}

type WurrliesGridProps = {
	count?: number
	wurrlies: Wurrly[]
	loading: boolean
	moreResults?: boolean
	sort?: SortProps
	studentId?: number
	title?: string | React.ReactNode
	filter?: FilterProps
	selectable?: boolean
	highlightId?: number
	data?: Record<string, unknown>
	currentTab?: ClassWurrlyType
	onWurrlyClick?: (wurrlyId: number, classId: number) => void
}

type SortProps = {
	sort: SortOrder
	handleSort: (sort: SortOrder) => void
}
type FilterProps = {
	filter: Wurrly_Type_Enum
	handleFilter: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
}

export const WurrliesGrid = (props: WurrliesGridProps) => {
	return (
		<StudentDetailProvider>
			<WurrliesGridContent {...props} />
		</StudentDetailProvider>
	)
}

const WurrliesGridContent = ({
	count,
	wurrlies,
	loading,
	moreResults,
	title,
	sort,
	filter,
	selectable = false,
	highlightId,
	currentTab,
	onWurrlyClick
}: WurrliesGridProps) => {
	const styles = useStyles()
	const { teacherId, selectedCard, setSelectedCard, isErrorOpen, setIsErrorOpen } = useStudentDetailContext()

	const [view, setView] = useState<wurrlyView>(wurrlyView.Grid)

	const handleSortType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		if (sort) {
			const newSort = event.target.value as SortOrder
			sort.handleSort(newSort)
		}
	}

	const handleWurrlyCard = (wurrly: Wurrly) => () => {
		if (selectable) {
			setSelectedCard(wurrly.wurrly_id)
		} else {
			// handle wurrly click
			onWurrlyClick?.(wurrly.wurrly_id, wurrly.class_id)
		}
	}

	const handleListClick = (wurrlyId: number, classId: number) => {
		onWurrlyClick?.(wurrlyId, classId)
	}

	return (
		<Box marginTop={5}>
			{!selectable && (
				<Grid className={styles.grid} container spacing={3}>
					<Grid item xs={12}>
						<Box display="flex" alignItems="center">
							{title && typeof title === 'string' && (
								<Typography variant="h6">
									<b>
										{title} ({count}){' '}
									</b>
								</Typography>
							)}
							{title && typeof title !== 'string' && title}
							<Box className={styles.boxGrow} />
							<ActionButton
								className={
									view === wurrlyView.Grid ? styles.actionButtonColor : styles.actionButton
								}
								onClick={() => setView(wurrlyView.Grid)}
								color={view === wurrlyView.Grid ? 'secondary' : 'default'}
								variant="outlined"
								icon={<ViewModuleOutlined />}
							/>
							<ActionButton
								className={
									view === wurrlyView.List ? styles.actionButtonColor : styles.actionButton
								}
								onClick={() => setView(wurrlyView.List)}
								color={view === wurrlyView.List ? 'secondary' : 'default'}
								variant="outlined"
								icon={<ListIcon />}
							/>
							{sort && (
								<SelectSortOrderBy cyKey="Wurrlies" value={sort.sort} onChange={handleSortType} />
							)}
							{filter && (
								<SelectWurrliesFilter value={filter.filter} onChange={filter.handleFilter} />
							)}
						</Box>
					</Grid>
				</Grid>
			)}
			<Grid container spacing={3}>
				{view === wurrlyView.Grid ? (
					wurrlies.map((wurrly) => {
						const fullName = `${wurrly.student?.first_name} ${wurrly.student?.last_name}`
						const className = wurrly?.class?.title || ''

						return (
							<Grid item xs={4} key={wurrly.wurrly_id}>
								<WurrlyCard
									type={wurrly?.wurrly_type}
									header={{
										title: getWurrlyType(wurrly.wurrly_type),
										subtitle: !!teacherId ? fullName : className || fullName,
										avatar: (!className ? wurrly.student?.image_path : '') as string,
										date: wurrly.created_at,
										privacy: wurrly?.privacy
									}}
									cardMedia={wurrly.image_path || ''}
									content={{
										title: wurrly.track?.song?.title || '',
										subtitle: wurrly.track?.song?.artist?.name ?? '',
										trackType: wurrly.track?.track_type?.name ?? '',
										comment: wurrly?.submission_wurrly?.submission?.teacher_comment || '',
										...(wurrly.submission_wurrly?.submission?.grade && {
											grade: wurrly.submission_wurrly?.submission?.grade
										})
									}}
									onClick={handleWurrlyCard(wurrly)}
									highlighted={wurrly.wurrly_id === highlightId}
									currentTab={currentTab}
								/>

								{selectable && (
									<Box>
										<Box
											className={clsx({
												[styles.boxSelected]: wurrly.wurrly_id === selectedCard
											})}
										/>
										<Box className={styles.boxIcon}>
											{wurrly.wurrly_id === selectedCard ? (
												<CheckCircleIcon fontSize="small" color="secondary" />
											) : (
												<RadioButtonUncheckedOutlinedIcon
													fontSize="small"
													color="disabled"
												/>
											)}
										</Box>
									</Box>
								)}
							</Grid>
						)
					})
				) : (
					<Grid item xs>
						<ListWurrlies onClick={handleListClick} data={wurrlies} spacing={1.5} />
					</Grid>
				)}
			</Grid>
			{loading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!loading && wurrlies.length > 0 && !moreResults && !selectable && (
				<Box mt={4} textAlign="center">
					<Button onClick={() => window.scrollTo(0, 0)} variant="text" color="secondary">
						Back to Top
					</Button>
				</Box>
			)}
			{!loading && !wurrlies.length && (
				<ActivityBoxEmpty className={styles.activity} noBorder title="No wurrlies to show" />
			)}

			<InfoDialog
				open={isErrorOpen}
				onClose={() => {
					setIsErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while setting district to Enterprise"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsErrorOpen(false)
				}}
			/>
		</Box>
	)
}
