import { Box } from '@material-ui/core'
import { NOTES_SIZE } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/constants'
import { GuitarDiagram } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/GuitarDiagram'
import { Chord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/types'
import { UkuleleDiagram } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/UkuleleDiagram'

import { KeyboardDiagram } from '../assets/chords/KeyboardDiagram'
import { InstrumentsGuideType } from '../types'

type NoteProps = {
	chord: Chord
	guide: InstrumentsGuideType
	style: React.CSSProperties
	time: number
}

export const Note = ({ chord, guide, style }: NoteProps) => {
	const noteWidth =
		guide === InstrumentsGuideType.guitar
			? NOTES_SIZE.guitar
			: guide === InstrumentsGuideType.ukulele
			? NOTES_SIZE.ukulele
			: NOTES_SIZE.piano

	return (
		<Box alignItems="center" style={style} display="flex" flexDirection="column">
			<Box style={{ width: noteWidth }}>
				{guide === InstrumentsGuideType.guitar && <GuitarDiagram chord={chord} />}
				{guide === InstrumentsGuideType.ukulele && <UkuleleDiagram chord={chord} />}
				{guide === InstrumentsGuideType.piano && <KeyboardDiagram chord={chord} />}
			</Box>
			{guide !== InstrumentsGuideType.audio && (
				<Box style={{ color: '#FE5935', textAlign: 'left', fontWeight: 'bold' }}>{chord.name}</Box>
			)}
		</Box>
	)
}
