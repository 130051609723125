import { createStyles, makeStyles } from '@material-ui/core'

export const useSelectChordDialogStyles = makeStyles(() =>
	createStyles({
		body: { boxSizing: 'border-box', padding: '12px' },
		container: { display: 'flex', flexDirection: 'column', gap: '4px' },
		grid: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fit, minmax(64px, 1fr))',
			gap: '12px',
			'& > button': { aspectRatio: `${1 / 0.54}` }
		},
		newChord: {
			width: '140px',
			height: '140px',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 0
		}
	})
)

export default useSelectChordDialogStyles
