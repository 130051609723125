import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { WurrliesDetailsFormConfig } from './components/WurrliesDetailsFormConfig'
import { WurrliesDetailsTitle } from './components/WurrliesDetailsTitle'
import { WurrliesDetailsProvider } from './useWurrliesDetails'

export const ClassWurrliesDetails = (props: PagesProps) => {
	return (
		<Box>
			<WurrliesDetailsProvider>
				<WurrliesDetailsTitle {...props} />
				<WurrliesDetailsFormConfig />
			</WurrliesDetailsProvider>
		</Box>
	)
}
