import { useEffect, useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import { useGetWurrliesByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Wurrly, Order_By, Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { SortOrder } from 'src/utils/enums'

const useClassWurrlies = () => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.ClassWurrlies.params[number]>()
	const classId = Number(params.classId)
	const teacherId = teacher.teacher_id
	const [sort, setSort] = useState(SortOrder.Recent)
	const [loading, setLoading] = useState(true)
	const [isFetching, setIsFetching] = useState(false)
	const [classWurrliesCount, setClassWurrliesCount] = useState(0)
	const [classWurrliesArr, setClassWurrliesArr] = useState<Wurrly[]>([])

	const [getWurrlys, { data: classWurrliesData, loading: classWurrliesLoading, refetch }] =
		useGetWurrliesByClassIdLazyQuery()

	const INITIAL_QUERY_VARIABLES = {
		classId,
		orderDate: sort === SortOrder.Recent ? Order_By.Desc : undefined,
		orderTitle: sort === SortOrder.Up ? Order_By.Asc : sort === SortOrder.Down ? Order_By.Desc : undefined,
		privacySettings: [Privacy_Enum.Class, Privacy_Enum.TeacherAndMe]
	}

	useEffect(() => {
		if (classId) {
			getWurrlys({
				variables: {
					...INITIAL_QUERY_VARIABLES
				}
			})
		}
	}, [classId])

	useEffect(() => {
		handleRefetch()
	}, [sort])
	const handleRefetch = () => {
		refetch({
			...INITIAL_QUERY_VARIABLES
		})
	}

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		if (!classWurrliesLoading && classWurrliesData) {
			const wurrlies = (classWurrliesData?.wurrly || []) as Wurrly[]
			const count = classWurrliesData?.wurrly_aggregate.aggregate?.count || 0

			setClassWurrliesArr(wurrlies)
			setClassWurrliesCount(count)
		}
	}, [classWurrliesData, classWurrliesLoading])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [classWurrliesArr])

	return {
		classId,
		teacherId,
		teacher,
		setSort,
		sort,
		classWurrliesArr,
		classWurrliesCount,

		loading,
		isFetching
	}
}

export const [ClassWurrliesProvider, useClassWurrliesContext] = buildGenericContext(useClassWurrlies)
