import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useEffectThumbnailStyles = makeStyles<Theme, { selected?: boolean }>(
	() =>
		createStyles({
			container: {
				display: 'flex',
				flexDirection: 'column',
				textAlign: 'center',
				width: '100px',
				height: '100%',
				cursor: 'pointer'
			},
			name: {
				fontWeight: 'bold',
				textTransform: 'capitalize',
				marginTop: 4,
				fontSize: 12
			},
			selected: {
				boxShadows: '1px 1px 4px 1px red'
			},
			disabled: {
				opacity: 0.5
			},
			selectableImageContainer: {
				position: 'relative',
				borderRadius: 4,
				overflow: 'hidden',
				width: '6.8em',
				height: '4.3em',
				border: ({ selected }) => (selected ? '2px solid #F3AB34' : 'none'),
				'& img': {
					objectFit: 'cover',
					width: '100%',
					height: '100%',
					transform: ({ selected }) => (selected ? 'scale(1.08)' : 'none')
				}
			},
			selectedOverlay: {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				background: 'rgba(243, 171, 52, 0.24)'
			},
			selectedIcon: {
				position: 'absolute',
				top: '.2em',
				right: '.2em'
			}
		}),
	{ index: 1 }
)

export default useEffectThumbnailStyles
