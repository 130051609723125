import { Box, CircularProgress, styled } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { Pages } from 'src/routes/teacherPages'
import { EditProfileInfo } from 'src/scenes/Student/scenes/5-Profile/Components/EditProfileInfo'
import {
	EditProfileProvider,
	useEditProfileContext
} from 'src/scenes/Student/scenes/5-Profile/Components/hooks/useEditProfileData'
import { BOX_PADDING } from 'src/styles/constants'

import { BigBaseButton } from '../../../../components/Buttons/BigBaseButton'
import { useScroll } from '../../../../hooks/useScroll'
import { buildRouteParameters } from '../../../../utils/routes-utils'
import { ProfileTitle } from './Components/ProfileTitle'

const PageContainer = styled(Box)({
	background: '#F3F5F8'
})

export const EditInformation = (props: PagesProps) => {
	useScroll()

	return (
		<EditProfileProvider>
			<PageContainer p={BOX_PADDING}>
				<ProfileTitle {...props} edit />
				<EditProfileInfo />
				<Footer />
			</PageContainer>
		</EditProfileProvider>
	)
}

const FooterButton = styled(BigBaseButton)({
	minWidth: '210px'
})

const Footer = () => {
	const history = useHistory()
	const { isLoading, formik } = useEditProfileContext()

	const goToProfile = () => {
		history.push(buildRouteParameters(Pages.Profile))
	}

	return (
		<Box display="flex" justifyContent="space-between">
			<FooterButton onClick={goToProfile}>Cancel</FooterButton>
			<FooterButton
				onClick={() => {
					formik.submitForm()
				}}
				color="secondary"
				disabled={isLoading}>
				Save {isLoading && <CircularProgress size="1.5em" style={{ marginLeft: '.5em' }} />}
			</FooterButton>
		</Box>
	)
}
