import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { SubscriptionsCardType } from 'src/@types'
import {
	useGetTeacherSubscriptionsLazyQuery,
	useCancelProductSubscriptionMutation
} from 'src/graphql/autogenerate/hooks'
import {
	CancelProductSubscriptionMutation,
	GetTeacherSubscriptionsQuery
} from 'src/graphql/autogenerate/operations'
import { Order, Order_Status_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, createSuscriptionCardData } from 'src/utils'

export const useProfileSubscription = () => {
	const history = useHistory()
	const [getTeacherSubscriptions, { data, loading }] = useGetTeacherSubscriptionsLazyQuery()

	const [cancelProductSubscription] = useCancelProductSubscriptionMutation()
	const [subscription, setSubscription] = useState<SubscriptionsCardType[]>([])
	const { teacherData } = useLoginContext()
	const [unSuscribeOpenDialog, setUnSuscribeOpenDialog] = useState(false)
	const [item, setItem] = useState<SubscriptionsCardType>()
	const [onCompleteRes, setOnCompleteRes] = useState<boolean | undefined>(undefined)

	useEffect(() => {
		if (item) {
			setUnSuscribeOpenDialog(true)
		}
	}, [item])
	const onUnsubscribe = async (stripeSubcriptiptionId: string) => {
		return new Promise<CancelProductSubscriptionMutation>((resolve, reject) => {
			cancelProductSubscription({
				variables: {
					teacherId: teacherData.teacher_id,
					stripeSubcriptiptionId
				},
				onCompleted: (res) => {
					setOnCompleteRes(res?.cancelProductSubscription?.success)
					resolve(res)
				},
				onError: () => {
					reject(new Error('Unsubscribe error'))
				}
			})
		})
	}

	const handleRefetch = (stripeSubcriptiptionId: string) => {
		if (onCompleteRes) {
			const subscriptionItem = subscription.find((i) => i.stripeSubscriptionId === stripeSubcriptiptionId)
			if (subscriptionItem) {
				subscriptionItem.status = Order_Status_Enum.Canceled
			}
		}
	}

	useEffect(() => {
		getTeacherSubscriptions({
			variables: {
				teacherId: teacherData.teacher_id,
				limit: 3
			}
		})
	}, [])

	useEffect(() => {
		if (data) tranformSuscriptionData(data)
	}, [data])

	const setSubscriptionData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setSubscription((subscription) => [...subscription, data])
		}
	}

	const tranformSuscriptionData = (data: GetTeacherSubscriptionsQuery) => {
		data.order.map((item) => {
			const order = item as Order
			setSubscriptionData(createSuscriptionCardData(subscription, order))
		})
	}

	const goToViewAllSubscriptions = () => {
		history.push(buildRouteParameters(Pages.MySubscriptions))
	}

	const goToPartner = (partnerId: number) => {
		history.push(buildRouteParameters(Pages.PartnerPage, { partnerId }))
	}

	const goToChannel = (channelId: number) => {
		history.push(buildRouteParameters(Pages.ChannelPage, { channelId }))
	}

	const goToModule = (moduleId: number) => {
		history.push(buildRouteParameters(Pages.ModuleDetails, { moduleId }))
	}

	const goToPlaylist = (playlistId: number) => {
		history.push(buildRouteParameters(Pages.PlaylistDetails, { playlistId }))
	}

	return {
		goToViewAllSubscriptions,
		goToPartner,
		goToChannel,
		goToModule,
		goToPlaylist,
		data: subscription,
		loading,
		getTeacherSubscriptions,
		onUnsubscribe,
		teacherId: teacherData.teacher_id,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	}
}
