import { useEffect, useState } from 'react'

import { LinearProgress, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'
import { BackgroundContainer, ErrorContainer } from 'src/components/VideoControls/Background/Shared'
import { getAudioWaveform } from 'src/utils'

type WaveformBackgroundProps = {
	videoUrl?: string
	mediaLength?: number
}
type DataType = {
	min: number
	max: number
}

export const WaveformBackground = ({ videoUrl, mediaLength }: WaveformBackgroundProps) => {
	const [waveformData, setWaveformData] = useState<DataType[] | undefined>()
	const [isLoading, setIsLoading] = useState(true)
	const [hasError, setHasError] = useState(false)

	useEffect(() => {
		if (!videoUrl || !mediaLength || !isFinite(mediaLength)) return

		const getWaveform = async () => {
			setIsLoading(true)
			try {
				const waveformDataRes = await getAudioWaveform({ videoSrc: videoUrl, videoLength: mediaLength })
				const data = [] as DataType[]

				for (let i = 0; i < waveformDataRes.min.length; i++) {
					data.push({
						min: waveformDataRes.min[i],
						max: waveformDataRes.max[i]
					})
				}
				setWaveformData(data)
				setHasError(false)
			} catch (error) {
				console.error('Could not get waveform data', error)
				setHasError(true)
			} finally {
				setIsLoading(false)
			}
		}
		getWaveform()
	}, [videoUrl, mediaLength])

	return (
		<BackgroundContainer>
			{(hasError || !videoUrl) && (
				<ErrorContainer>
					<ErrorIcon style={{ margin: '.5em' }} />
					<Typography>Couldn't generate waveform</Typography>
				</ErrorContainer>
			)}
			{!hasError && !isLoading && waveformData && (
				<ResponsiveContainer width="100%" height="80%">
					<AreaChart data={waveformData}>
						<Area type="monotone" dataKey="min" stroke="#cccccc" fill="#cccccc" />
						<Area type="monotone" dataKey="max" stroke="#cccccc" fill="#cccccc" />
					</AreaChart>
				</ResponsiveContainer>
			)}
			{isLoading && <LinearProgress style={{ width: '100%', opacity: 0.5 }} />}
		</BackgroundContainer>
	)
}
