import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const ClassAssignmentFragmentFragmentDoc = gql`
    fragment ClassAssignmentFragment on class_assignment {
  status
  assignment {
    name
    due_date
  }
  submission_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const ClassSettingsFragmentFragmentDoc = gql`
    fragment ClassSettingsFragment on class {
  catalog_item {
    catalog_item_id
    name
  }
  grade {
    grade_id
    name
  }
  skill_level {
    skill_level_id
    name
  }
  grading_system {
    grading_system_id
    name
    label
  }
  maturity_level
}
    `;
export const ClassDetailsReducedFragmentFragmentDoc = gql`
    fragment ClassDetailsReducedFragment on class {
  class_id
  title
  start_date
  end_date
  image_path
  created_at
  maturity_level
  grade_id
}
    `;
export const ClassCountersFragmentFragmentDoc = gql`
    fragment ClassCountersFragment on class {
  class_lesson_plans_aggregate(
    where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
  class_songs_aggregate(
    where: {_and: [{song_id: {_is_null: false}}, {song: {active: {_eq: true}}}]}
  ) {
    aggregate {
      count
    }
  }
  lessonPlanList: class_lesson_plans(
    where: {_and: [{lesson_plan: {lesson_plan_id: {_is_null: false}}}, {lesson_plan: {active: {_eq: true}}}]}
  ) {
    lesson_plan_id
  }
  lessonsInSecuences: class_sequences(
    where: {sequence: {sequence_id: {_is_null: false}}}
  ) {
    sequence {
      sequence_lesson_plans(
        where: {_and: [{lesson_plan: {lesson_plan_id: {_is_null: false}}}, {lesson_plan: {active: {_eq: true}}}]}
      ) {
        lesson_plan_id
      }
    }
  }
  lessonsInModules: class_modules(where: {module: {module_id: {_is_null: false}}}) {
    module {
      module_lesson_plan(
        where: {_and: [{lesson_plan: {lesson_plan_id: {_is_null: false}}}, {lesson_plan: {active: {_eq: true}}}]}
      ) {
        lesson_plan_id
      }
    }
  }
  tipsCount: class_tips(
    where: {_and: [{tip_id: {_is_null: false}}, {tip: {active: {_eq: true}}}]}
  ) {
    tip_id
  }
  playlistCount: class_playlists(
    where: {playlist: {playlist_id: {_is_null: false}}}
  ) {
    playlist {
      tip_playlist(
        where: {_and: [{tip_id: {_is_null: false}}, {tip: {active: {_eq: true}}}]}
      ) {
        tip_id
      }
    }
  }
  class_students_aggregate {
    aggregate {
      count
    }
  }
  class_modules(where: {module: {module_id: {_is_null: false}}}) {
    module {
      module_lesson_plan_aggregate(
        where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
  class_sequences {
    sequence {
      sequence_lesson_plans_aggregate(
        where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
}
    `;
export const ClassTeacherContentFragmentFragmentDoc = gql`
    fragment ClassTeacherContentFragment on class_teacher {
  class_teacher_id
  class {
    ...ClassDetailsReducedFragment
    ...ClassCountersFragment
    grade_id
    grading_system {
      grading_system_id
      label
      name
      values
    }
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}`;
export const LessonPlanReducedFragmentFragmentDoc = gql`
    fragment LessonPlanReducedFragment on lesson_plan {
  lesson_plan_id
  title
  description
  image_path
  inspire
  practice
  process
  record
  reflect
  take_it_further
  suggested_assignments
  differentiations
  materials_needed
}
    `;
export const LessonPlanOnlyCatalogsFragmentFragmentDoc = gql`
    fragment LessonPlanOnlyCatalogsFragment on lesson_plan {
  ...LessonPlanReducedFragment
  lesson_plan_catalog_item {
    catalog_item {
      catalog_item_id
      catalog_id
      name
      catalog {
        catalog_id
        name
      }
    }
  }
}
    ${LessonPlanReducedFragmentFragmentDoc}`;
export const LessonPlanExtendedFragmentFragmentDoc = gql`
    fragment LessonPlanExtendedFragment on lesson_plan {
  ...LessonPlanOnlyCatalogsFragment
  process
  lesson_plan_song(where: {song: {song_id: {_is_null: false}}}) {
    lesson_plan_song_id
    song {
      song_id
      title
      image_path
      artist {
        artist_id
        name
      }
    }
  }
  lesson_plan_asset {
    lesson_plan_asset_id
    asset {
      asset_id
      name
      resource_path
    }
  }
  lesson_plan_tip(where: {tip: {tip_id: {_is_null: false}}}) {
    lesson_plan_tip_id
    tip {
      tip_id
      title
      image_path
    }
  }
  lesson_plan_keyword {
    lesson_plan_keyword_id
    keyword {
      keyword_id
      name
    }
  }
  lesson_plan_grade(order_by: {grade_id: asc}) {
    lesson_plan_grade_id
    grade {
      grade_id
      name
    }
  }
  lesson_plan_musical_skill(order_by: {musical_skill: {name: asc}}) {
    lesson_plan_musical_skill_id
    musical_skill {
      musical_skill_id
      name
    }
  }
  lesson_plan_non_musical_skill(order_by: {non_musical_skill: {name: asc}}) {
    lesson_plan_non_musical_skill_id
    non_musical_skill {
      name
      non_musical_skill_id
    }
  }
  module_lesson_plans_aggregate {
    aggregate {
      count
    }
  }
}
    ${LessonPlanOnlyCatalogsFragmentFragmentDoc}`;
export const ModuleFragmentFragmentDoc = gql`
    fragment ModuleFragment on module {
  module_id
  image_path
  title
  description
  created_at
  video_path
  video_thumbnail_image_path
  header_image_path
  is_purchased_by_teacher
  display_video
  lockable_content {
    visibility
  }
  module_lesson_plan(
    order_by: {order: asc}
    where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
  ) {
    module_lesson_plan_id
    lesson_plan_id
    module_id
  }
  module_lesson_plan_aggregate(
    where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
  module_catalog_items {
    module_catalog_item_id
    catalog_item {
      catalog_item_id
      name
      catalog_id
    }
  }
  class_modules {
    class_module_id
    class {
      title
      class_id
    }
  }
}
    `;
export const TipExtendedFragmentFragmentDoc = gql`
    fragment tipExtendedFragment on tip {
  tip_id
  title
  description
  resource_path
  image_path
  featured
  asset_path
  lockable_content {
    visibility
  }
  is_purchased_by_teacher
  tip_catalog_item {
    tip_catalog_item_id
    catalog_item {
      catalog_item_id
      catalog_id
      name
      catalog {
        catalog_id
        name
      }
    }
  }
  artist {
    name
  }
  class_tips {
    class {
      class_id
    }
  }
}
    `;
export const PlaylistReducedFragmentFragmentDoc = gql`
    fragment playlistReducedFragment on playlist {
  playlist_id
  image_path
  name
  description
  tip_counter
  tip_playlist(where: {tip: {tip_id: {_is_null: false}}}, order_by: {order: asc}) {
    tip_playlist_id
    tip {
      ...tipExtendedFragment
    }
  }
  tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
    aggregate {
      count
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;
export const SequenceFragmentFragmentDoc = gql`
    fragment SequenceFragment on sequence {
  sequence_id
  name
  description
  image_path
  created_at
  sequence_topic_sequences {
    sequence_topic_id
    sequence_topic {
      title
      sequence_topic_catalog_items {
        sequence_topic_catalog_item_id
        catalog_item {
          catalog_item_id
          name
        }
      }
    }
  }
  sequence_lesson_plans(where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}) {
    sequence_lesson_plan_id
    lesson_plan_id
    sequence_id
  }
  sequence_lesson_plans_aggregate(
    where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
  class_sequences {
    class_sequence_id
    class {
      class_id
    }
  }
}
    `;
export const SongExtendedFragmentFragmentDoc = gql`
    fragment SongExtendedFragment on song {
  teacher_id
  resource_path
  song_id
  title
  image_path
  lyrics
  song_maturity_level
  active
  featured
  key
  artist {
    artist_id
    name
  }
  scale {
    scale_id
    name
  }
  tracks {
    track_id
    track_type {
      name
    }
    resource_path
    midis {
      chord_map_object
      chords_Array
      chords_Array_Zero
    }
  }
  song_catalog_item {
    catalog_item {
      catalog_item_id
      name
      catalog_id
      catalog {
        catalog_id
        name
      }
    }
  }
  class_songs {
    class {
      class_id
      title
    }
  }
  recommended
}
    `;
export const SongExtendedFragmentNoTrackFragmentDoc = gql`
    fragment SongExtendedFragmentNoTrack on song {
  song_id
  title
  resource_path
  image_path
  lyrics
  song_maturity_level
  artist {
    name
  }
  scale {
    name
  }
  song_catalog_item {
    catalog_item {
      catalog_item_id
      name
      catalog_id
      catalog {
        catalog_id
        name
      }
    }
  }
  class_songs {
    class {
      class_id
      title
    }
  }
  recommended
}
    `;
export const SongReducedFragmentFragmentDoc = gql`
    fragment songReducedFragment on song {
  teacher_id
  resource_path
  song_id
  title
  image_path
  song_maturity_level
  artist {
    name
  }
  scale {
    name
  }
  song_catalog_item {
    catalog_item {
      catalog_item_id
      name
      catalog_id
      catalog {
        catalog_id
        name
      }
    }
  }
  class_songs {
    class {
      class_id
      title
    }
  }
}
    `;
export const StudentBasicFragmentFragmentDoc = gql`
    fragment StudentBasicFragment on student {
  student_id
  first_name
  middle_name
  last_name
  image_path
  is_active
  email
  avatar_selected
  stage_name
}
    `;
export const StudentReducedFragmentFragmentDoc = gql`
    fragment StudentReducedFragment on student {
  student_id
  first_name
  middle_name
  last_name
  image_path
  is_active
  email
  avatar_selected
  stage_name
  full_name
  district {
    district
  }
  grades {
    grade
  }
}
    `;
export const ClassDetailsExtendedFragmentFragmentDoc = gql`
    fragment ClassDetailsExtendedFragment on class {
  class_id
  title
  start_date
  end_date
  image_path
  created_at
}
    `;
export const StudentExtendedFragmentFragmentDoc = gql`
    fragment StudentExtendedFragment on student {
  student_id
  image_path
  first_name
  last_name
  code
  email
  has_lti_integration
  is_active
  class_students {
    class {
      ...ClassDetailsExtendedFragment
    }
  }
}
    ${ClassDetailsExtendedFragmentFragmentDoc}`;
export const StudentActivityFragmentFragmentDoc = gql`
    fragment StudentActivityFragment on student_activity {
  student_activity_id
  student_activity_type
  created_at
  student_id
  student {
    full_name
    image_path
  }
  description
  url
  past_due
  assignment_id
  assignment {
    name
    class_assignments {
      class_assignment_id
      class_id
    }
  }
}
    `;
export const WurrliesReducedFragmentFragmentDoc = gql`
    fragment wurrliesReducedFragment on wurrly {
  wurrly_id
  privacy
  created_at
  image_path
  resource_path
  final_resource_path
  message
  audio_balance
  audio_url
  is_from_legacy
  is_video_url
}
    `;
export const StudentSettingFragmentFragmentDoc = gql`
    fragment StudentSettingFragment on student_setting {
  dont_ask_again_record
  student_id
}
    `;
export const WurrlyContentFragmentFragmentDoc = gql`
    fragment WurrlyContentFragment on wurrly {
  wurrly_id
  created_at
  image_path
  class_id
  is_video_url
  message
  student {
    first_name
    middle_name
    last_name
    image_path
    student_id
    stage_name
    full_name
  }
  track {
    track_type {
      name
    }
    song {
      title
      artist {
        name
      }
    }
  }
}
    `;
export const CatalogDataFragmentDoc = gql`
    fragment catalogData on catalog {
  name
  catalog_id
  catalog_items(where: {active: {_eq: true}}, order_by: {order: asc}) {
    catalog_item_id
    name
    active
  }
  tip_order
  song_order
  lesson_plan_order
}
    `;
export const LessonCatalogFragmentFragmentDoc = gql`
    fragment lessonCatalogFragment on lesson_plan {
  lesson_plan_id
  title
  description
  image_path
  inspire
  practice
  process
  record
  reflect
  take_it_further
  suggested_assignments
  differentiations
  materials_needed
  is_purchased_by_teacher
  lockable_content {
    visibility
  }
  lesson_plan_catalog_item {
    catalog_item {
      catalog {
        name
      }
      catalog_id
      name
    }
  }
  lesson_plan_song(where: {song: {song_id: {_is_null: false}}}) {
    song {
      title
      image_path
      artist {
        name
      }
    }
  }
  lesson_plan_asset {
    asset {
      name
      resource_path
    }
  }
  lesson_plan_tip(where: {tip: {tip_id: {_is_null: false}}}) {
    tip {
      title
      image_path
    }
  }
  lesson_plan_keyword {
    keyword {
      name
      keyword_id
    }
  }
  lesson_plan_grade {
    grade {
      name
      grade_id
    }
  }
  lesson_plan_musical_skill {
    musical_skill {
      name
      musical_skill_id
    }
  }
  lesson_plan_non_musical_skill {
    non_musical_skill {
      name
      non_musical_skill_id
    }
  }
}
    `;
export const PartnerFragmentFragmentDoc = gql`
    fragment partnerFragment on partner {
  __typename
  partner_id
  title
  description
  image_path
  partner_modules_aggregate {
    aggregate {
      count
    }
    nodes {
      module {
        module_lesson_plan_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export const ChannelFragmentFragmentDoc = gql`
    fragment channelFragment on channel {
  __typename
  channel_id
  title
  description
  image_path
  channel_playlists_aggregate {
    aggregate {
      count
    }
    nodes {
      playlist {
        tip_playlist_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export const ModuleSuscFragmentFragmentDoc = gql`
    fragment moduleSuscFragment on module {
  __typename
  module_id
  title
  description
  image_path
  module_lesson_plan_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const PlaylistFragmentFragmentDoc = gql`
    fragment playlistFragment on playlist {
  __typename
  playlist_id
  name
  description
  image_path
  tip_playlist_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const PlaylistTipsForGenresFragmentFragmentDoc = gql`
    fragment PlaylistTipsForGenresFragment on playlist {
  tip_playlist(where: {tip: {tip_id: {_is_null: false}}}, order_by: {order: asc}) {
    tip_playlist_id
    tip {
      tip_id
      tip_catalog_item {
        tip_catalog_item_id
        catalog_item {
          catalog_item_id
          name
          catalog {
            catalog_id
            name
          }
        }
      }
    }
  }
}
    `;
export const PlaylistTipsForGenresStudentFragmentFragmentDoc = gql`
    fragment PlaylistTipsForGenresStudentFragment on playlist {
  tip_playlist(where: {tip: {tip_id: {_is_null: false}}}) {
    tip_playlist_id
    tip {
      tip_id
      tip_catalog_item {
        tip_catalog_item_id
        catalog_item {
          catalog_item_id
          name
          catalog {
            catalog_id
            name
          }
        }
      }
    }
  }
}
    `;
export const TeacherFavoritesDontAskAgainFragmentFragmentDoc = gql`
    fragment TeacherFavoritesDontAskAgainFragment on teacher_setting {
  teacher_id
  teacher {
    teacher_id
  }
  favorites_dont_ask_again_to_delete
  sequence_lesson_dont_ask_again_add_to_class
  dont_ask_again_record
}
    `;
export const TipFragmentFragmentDoc = gql`
    fragment tipFragment on tip {
  tip_id
  title
  description
  resource_path
  image_path
  featured
  asset_path
  tip_catalog_item {
    tip_catalog_item_id
    catalog_item {
      catalog_item_id
      catalog_id
      name
      catalog {
        catalog_id
        name
      }
    }
  }
  artist {
    name
  }
}
    `;
export const TrackExtendedFragmentFragmentDoc = gql`
    fragment trackExtendedFragment on track {
  track_id
  track_type {
    track_type_id
    name
  }
  song {
    song_id
    title
    artist {
      artist_id
      name
    }
  }
}
    `;
export const WurrlyGridFragmentFragmentDoc = gql`
    fragment wurrlyGridFragment on wurrly {
  wurrly_id
  image_path
  resource_path
  final_resource_path
  privacy
  track {
    ...trackExtendedFragment
  }
  teacher_wurrly_comments {
    teacher_wurrly_comment_id
    comment
  }
  student {
    first_name
    middle_name
    last_name
    image_path
    student_id
  }
}
    ${TrackExtendedFragmentFragmentDoc}`;
export const GetLessonsBySongDocument = gql`
    query GetLessonsBySong($songId: Int, $offset: Int, $limit: Int, $order: order_by = asc) {
  lesson_plan(
    where: {lesson_plan_song: {song_id: {_eq: $songId}, lesson_plan: {lesson_plan_id: {_is_null: false}}}, lockable_content: {visibility: {_neq: for_sale}}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    ...LessonPlanExtendedFragment
    lesson_plan_articles {
      title
      content
      lesson_plan_article_id
    }
    class_lesson_plans {
      class {
        class_id
      }
    }
  }
}
    ${LessonPlanExtendedFragmentFragmentDoc}`;

/**
 * __useGetLessonsBySongQuery__
 *
 * To run a query within a React component, call `useGetLessonsBySongQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsBySongQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsBySongQuery({
 *   variables: {
 *      songId: // value for 'songId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetLessonsBySongQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLessonsBySongQuery, Types.GetLessonsBySongQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonsBySongQuery, Types.GetLessonsBySongQueryVariables>(GetLessonsBySongDocument, options);
      }
export function useGetLessonsBySongLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonsBySongQuery, Types.GetLessonsBySongQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonsBySongQuery, Types.GetLessonsBySongQueryVariables>(GetLessonsBySongDocument, options);
        }
export type GetLessonsBySongQueryHookResult = ReturnType<typeof useGetLessonsBySongQuery>;
export type GetLessonsBySongLazyQueryHookResult = ReturnType<typeof useGetLessonsBySongLazyQuery>;
export type GetLessonsBySongQueryResult = Apollo.QueryResult<Types.GetLessonsBySongQuery, Types.GetLessonsBySongQueryVariables>;
export function refetchGetLessonsBySongQuery(variables?: Types.GetLessonsBySongQueryVariables) {
      return { query: GetLessonsBySongDocument, variables: variables }
    }
export const InsertContactUsWithMailDocument = gql`
    mutation InsertContactUsWithMail($name: String!, $schoolOrDistrict: String!, $message: String!, $email: String!) {
  insertContactUsWithMail(
    filter: {name: $name, schoolOrDistrict: $schoolOrDistrict, message: $message, email: $email}
  )
}
    `;
export type InsertContactUsWithMailMutationFn = Apollo.MutationFunction<Types.InsertContactUsWithMailMutation, Types.InsertContactUsWithMailMutationVariables>;

/**
 * __useInsertContactUsWithMailMutation__
 *
 * To run a mutation, you first call `useInsertContactUsWithMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactUsWithMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactUsWithMailMutation, { data, loading, error }] = useInsertContactUsWithMailMutation({
 *   variables: {
 *      name: // value for 'name'
 *      schoolOrDistrict: // value for 'schoolOrDistrict'
 *      message: // value for 'message'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInsertContactUsWithMailMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertContactUsWithMailMutation, Types.InsertContactUsWithMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertContactUsWithMailMutation, Types.InsertContactUsWithMailMutationVariables>(InsertContactUsWithMailDocument, options);
      }
export type InsertContactUsWithMailMutationHookResult = ReturnType<typeof useInsertContactUsWithMailMutation>;
export type InsertContactUsWithMailMutationResult = Apollo.MutationResult<Types.InsertContactUsWithMailMutation>;
export type InsertContactUsWithMailMutationOptions = Apollo.BaseMutationOptions<Types.InsertContactUsWithMailMutation, Types.InsertContactUsWithMailMutationVariables>;
export const RecoverPasswordDocument = gql`
    mutation RecoverPassword($token: String!, $newPassword: String!) {
  resetPassword(filter: {token: $token, newPassword: $newPassword}) {
    success
  }
}
    `;
export type RecoverPasswordMutationFn = Apollo.MutationFunction<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>;

/**
 * __useRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverPasswordMutation, { data, loading, error }] = useRecoverPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>(RecoverPasswordDocument, options);
      }
export type RecoverPasswordMutationHookResult = ReturnType<typeof useRecoverPasswordMutation>;
export type RecoverPasswordMutationResult = Apollo.MutationResult<Types.RecoverPasswordMutation>;
export type RecoverPasswordMutationOptions = Apollo.BaseMutationOptions<Types.RecoverPasswordMutation, Types.RecoverPasswordMutationVariables>;
export const ActivateAccountDocument = gql`
    mutation ActivateAccount($filter: ActivateAccountFilter) {
  activateAccount(filter: $filter) {
    success
  }
}
    `;
export type ActivateAccountMutationFn = Apollo.MutationFunction<Types.ActivateAccountMutation, Types.ActivateAccountMutationVariables>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useActivateAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateAccountMutation, Types.ActivateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateAccountMutation, Types.ActivateAccountMutationVariables>(ActivateAccountDocument, options);
      }
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<Types.ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<Types.ActivateAccountMutation, Types.ActivateAccountMutationVariables>;
export const ActivateTeacherDocument = gql`
    mutation activateTeacher($filter: ActivateTeacherFilter) {
  activateTeacher(filter: $filter) {
    success
  }
}
    `;
export type ActivateTeacherMutationFn = Apollo.MutationFunction<Types.ActivateTeacherMutation, Types.ActivateTeacherMutationVariables>;

/**
 * __useActivateTeacherMutation__
 *
 * To run a mutation, you first call `useActivateTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTeacherMutation, { data, loading, error }] = useActivateTeacherMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useActivateTeacherMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateTeacherMutation, Types.ActivateTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateTeacherMutation, Types.ActivateTeacherMutationVariables>(ActivateTeacherDocument, options);
      }
export type ActivateTeacherMutationHookResult = ReturnType<typeof useActivateTeacherMutation>;
export type ActivateTeacherMutationResult = Apollo.MutationResult<Types.ActivateTeacherMutation>;
export type ActivateTeacherMutationOptions = Apollo.BaseMutationOptions<Types.ActivateTeacherMutation, Types.ActivateTeacherMutationVariables>;
export const CreateProductSubscriptionDocument = gql`
    mutation createProductSubscription($subscription: createProductSubscriptionSet!) {
  createProductSubscription(set: $subscription) {
    clientSecret
    subscriptionId
  }
}
    `;
export type CreateProductSubscriptionMutationFn = Apollo.MutationFunction<Types.CreateProductSubscriptionMutation, Types.CreateProductSubscriptionMutationVariables>;

/**
 * __useCreateProductSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateProductSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductSubscriptionMutation, { data, loading, error }] = useCreateProductSubscriptionMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useCreateProductSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProductSubscriptionMutation, Types.CreateProductSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProductSubscriptionMutation, Types.CreateProductSubscriptionMutationVariables>(CreateProductSubscriptionDocument, options);
      }
export type CreateProductSubscriptionMutationHookResult = ReturnType<typeof useCreateProductSubscriptionMutation>;
export type CreateProductSubscriptionMutationResult = Apollo.MutationResult<Types.CreateProductSubscriptionMutation>;
export type CreateProductSubscriptionMutationOptions = Apollo.BaseMutationOptions<Types.CreateProductSubscriptionMutation, Types.CreateProductSubscriptionMutationVariables>;
export const CancelProductSubscriptionDocument = gql`
    mutation cancelProductSubscription($teacherId: Int!, $stripeSubcriptiptionId: String!) {
  cancelProductSubscription(
    set: {stripeSubcriptiptionId: $stripeSubcriptiptionId, teacherId: $teacherId}
  ) {
    success
  }
}
    `;
export type CancelProductSubscriptionMutationFn = Apollo.MutationFunction<Types.CancelProductSubscriptionMutation, Types.CancelProductSubscriptionMutationVariables>;

/**
 * __useCancelProductSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelProductSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProductSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProductSubscriptionMutation, { data, loading, error }] = useCancelProductSubscriptionMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      stripeSubcriptiptionId: // value for 'stripeSubcriptiptionId'
 *   },
 * });
 */
export function useCancelProductSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CancelProductSubscriptionMutation, Types.CancelProductSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelProductSubscriptionMutation, Types.CancelProductSubscriptionMutationVariables>(CancelProductSubscriptionDocument, options);
      }
export type CancelProductSubscriptionMutationHookResult = ReturnType<typeof useCancelProductSubscriptionMutation>;
export type CancelProductSubscriptionMutationResult = Apollo.MutationResult<Types.CancelProductSubscriptionMutation>;
export type CancelProductSubscriptionMutationOptions = Apollo.BaseMutationOptions<Types.CancelProductSubscriptionMutation, Types.CancelProductSubscriptionMutationVariables>;
export const SendTeacherActivationEmailDocument = gql`
    query SendTeacherActivationEmail {
  sendTeacherActivationEmail {
    success
  }
}
    `;

/**
 * __useSendTeacherActivationEmailQuery__
 *
 * To run a query within a React component, call `useSendTeacherActivationEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendTeacherActivationEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendTeacherActivationEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useSendTeacherActivationEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.SendTeacherActivationEmailQuery, Types.SendTeacherActivationEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SendTeacherActivationEmailQuery, Types.SendTeacherActivationEmailQueryVariables>(SendTeacherActivationEmailDocument, options);
      }
export function useSendTeacherActivationEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SendTeacherActivationEmailQuery, Types.SendTeacherActivationEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SendTeacherActivationEmailQuery, Types.SendTeacherActivationEmailQueryVariables>(SendTeacherActivationEmailDocument, options);
        }
export type SendTeacherActivationEmailQueryHookResult = ReturnType<typeof useSendTeacherActivationEmailQuery>;
export type SendTeacherActivationEmailLazyQueryHookResult = ReturnType<typeof useSendTeacherActivationEmailLazyQuery>;
export type SendTeacherActivationEmailQueryResult = Apollo.QueryResult<Types.SendTeacherActivationEmailQuery, Types.SendTeacherActivationEmailQueryVariables>;
export function refetchSendTeacherActivationEmailQuery(variables?: Types.SendTeacherActivationEmailQueryVariables) {
      return { query: SendTeacherActivationEmailDocument, variables: variables }
    }
export const SendResetPasswordMailDocument = gql`
    query SendResetPasswordMail($email: String!, $isTeacher: Boolean, $isStudent: Boolean) {
  sendResetPasswordUrl(
    filter: {email: $email, isTeacher: $isTeacher, isStudent: $isStudent}
  ) {
    success
  }
}
    `;

/**
 * __useSendResetPasswordMailQuery__
 *
 * To run a query within a React component, call `useSendResetPasswordMailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendResetPasswordMailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      isTeacher: // value for 'isTeacher'
 *      isStudent: // value for 'isStudent'
 *   },
 * });
 */
export function useSendResetPasswordMailQuery(baseOptions: Apollo.QueryHookOptions<Types.SendResetPasswordMailQuery, Types.SendResetPasswordMailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SendResetPasswordMailQuery, Types.SendResetPasswordMailQueryVariables>(SendResetPasswordMailDocument, options);
      }
export function useSendResetPasswordMailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SendResetPasswordMailQuery, Types.SendResetPasswordMailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SendResetPasswordMailQuery, Types.SendResetPasswordMailQueryVariables>(SendResetPasswordMailDocument, options);
        }
export type SendResetPasswordMailQueryHookResult = ReturnType<typeof useSendResetPasswordMailQuery>;
export type SendResetPasswordMailLazyQueryHookResult = ReturnType<typeof useSendResetPasswordMailLazyQuery>;
export type SendResetPasswordMailQueryResult = Apollo.QueryResult<Types.SendResetPasswordMailQuery, Types.SendResetPasswordMailQueryVariables>;
export function refetchSendResetPasswordMailQuery(variables?: Types.SendResetPasswordMailQueryVariables) {
      return { query: SendResetPasswordMailDocument, variables: variables }
    }
export const ValidatePromotionCodeDocument = gql`
    query ValidatePromotionCode($code: String!) {
  validatePromotionCode(filter: {promotionCode: $code}) {
    isValid
    discount_percent_off
    discount_amount_off
  }
}
    `;

/**
 * __useValidatePromotionCodeQuery__
 *
 * To run a query within a React component, call `useValidatePromotionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePromotionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePromotionCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidatePromotionCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.ValidatePromotionCodeQuery, Types.ValidatePromotionCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ValidatePromotionCodeQuery, Types.ValidatePromotionCodeQueryVariables>(ValidatePromotionCodeDocument, options);
      }
export function useValidatePromotionCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidatePromotionCodeQuery, Types.ValidatePromotionCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ValidatePromotionCodeQuery, Types.ValidatePromotionCodeQueryVariables>(ValidatePromotionCodeDocument, options);
        }
export type ValidatePromotionCodeQueryHookResult = ReturnType<typeof useValidatePromotionCodeQuery>;
export type ValidatePromotionCodeLazyQueryHookResult = ReturnType<typeof useValidatePromotionCodeLazyQuery>;
export type ValidatePromotionCodeQueryResult = Apollo.QueryResult<Types.ValidatePromotionCodeQuery, Types.ValidatePromotionCodeQueryVariables>;
export function refetchValidatePromotionCodeQuery(variables?: Types.ValidatePromotionCodeQueryVariables) {
      return { query: ValidatePromotionCodeDocument, variables: variables }
    }
export const CreateRoyaltyLogDocument = gql`
    mutation CreateRoyaltyLog($trackId: Int!, $songId: Int!, $teacherId: Int, $studentId: Int, $startDate: timestamptz!, $endDate: timestamptz!, $playTime: Float!, $screenType: screen_type_enum!) {
  insert_royalty_log(
    objects: {track_id: $trackId, song_id: $songId, teacher_id: $teacherId, student_id: $studentId, start_date: $startDate, end_date: $endDate, play_time: $playTime, screen_type: $screenType}
  ) {
    affected_rows
  }
}
    `;
export type CreateRoyaltyLogMutationFn = Apollo.MutationFunction<Types.CreateRoyaltyLogMutation, Types.CreateRoyaltyLogMutationVariables>;

/**
 * __useCreateRoyaltyLogMutation__
 *
 * To run a mutation, you first call `useCreateRoyaltyLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoyaltyLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoyaltyLogMutation, { data, loading, error }] = useCreateRoyaltyLogMutation({
 *   variables: {
 *      trackId: // value for 'trackId'
 *      songId: // value for 'songId'
 *      teacherId: // value for 'teacherId'
 *      studentId: // value for 'studentId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      playTime: // value for 'playTime'
 *      screenType: // value for 'screenType'
 *   },
 * });
 */
export function useCreateRoyaltyLogMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateRoyaltyLogMutation, Types.CreateRoyaltyLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateRoyaltyLogMutation, Types.CreateRoyaltyLogMutationVariables>(CreateRoyaltyLogDocument, options);
      }
export type CreateRoyaltyLogMutationHookResult = ReturnType<typeof useCreateRoyaltyLogMutation>;
export type CreateRoyaltyLogMutationResult = Apollo.MutationResult<Types.CreateRoyaltyLogMutation>;
export type CreateRoyaltyLogMutationOptions = Apollo.BaseMutationOptions<Types.CreateRoyaltyLogMutation, Types.CreateRoyaltyLogMutationVariables>;
export const GetScalesDocument = gql`
    query GetScales {
  scale(order_by: {name: desc}) {
    scale_id
    name
  }
}
    `;

/**
 * __useGetScalesQuery__
 *
 * To run a query within a React component, call `useGetScalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScalesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetScalesQuery, Types.GetScalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScalesQuery, Types.GetScalesQueryVariables>(GetScalesDocument, options);
      }
export function useGetScalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScalesQuery, Types.GetScalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScalesQuery, Types.GetScalesQueryVariables>(GetScalesDocument, options);
        }
export type GetScalesQueryHookResult = ReturnType<typeof useGetScalesQuery>;
export type GetScalesLazyQueryHookResult = ReturnType<typeof useGetScalesLazyQuery>;
export type GetScalesQueryResult = Apollo.QueryResult<Types.GetScalesQuery, Types.GetScalesQueryVariables>;
export function refetchGetScalesQuery(variables?: Types.GetScalesQueryVariables) {
      return { query: GetScalesDocument, variables: variables }
    }
export const GetVersionAppDocument = gql`
    subscription getVersionApp {
  app(order_by: {app_id: desc}, limit: 1) {
    version
    created_at
  }
}
    `;

/**
 * __useGetVersionAppSubscription__
 *
 * To run a query within a React component, call `useGetVersionAppSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionAppSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionAppSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetVersionAppSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.GetVersionAppSubscription, Types.GetVersionAppSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetVersionAppSubscription, Types.GetVersionAppSubscriptionVariables>(GetVersionAppDocument, options);
      }
export type GetVersionAppSubscriptionHookResult = ReturnType<typeof useGetVersionAppSubscription>;
export type GetVersionAppSubscriptionResult = Apollo.SubscriptionResult<Types.GetVersionAppSubscription>;
export const GetWurrlyByClassByStudentDocument = gql`
    query GetWurrlyByClassByStudent($classId: Int, $studentId: Int, $limit: Int, $offset: Int, $orderTitle: order_by, $type: wurrly_type_enum, $privacySettings: [privacy_enum!], $orderDate: order_by) {
  wurrly(
    where: {class_id: {_eq: $classId}, student_id_owner: {_eq: $studentId}, wurrly_type: {_eq: $type}, privacy: {_in: $privacySettings}, track: {song: {song_id: {_is_null: false}}}}
    limit: $limit
    offset: $offset
    order_by: {track: {song: {title: $orderTitle}}, created_at: $orderDate}
  ) {
    submission_wurrly {
      state
      submission_id
      submission {
        teacher_comment
        grade
        class_assignment {
          assignment {
            name
          }
        }
      }
    }
    wurrly_id
    created_at
    image_path
    class_id
    wurrly_type
    privacy
    message
    class {
      title
    }
    challenge {
      title
    }
    student {
      first_name
      middle_name
      last_name
      image_path
      stage_name
    }
    track {
      track_type {
        name
      }
      song {
        title
        artist {
          name
        }
      }
    }
  }
  wurrly_aggregate(
    where: {class_id: {_eq: $classId}, student_id_owner: {_eq: $studentId}, wurrly_type: {_eq: $type}, privacy: {_in: $privacySettings}, track: {song: {song_id: {_is_null: false}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetWurrlyByClassByStudentQuery__
 *
 * To run a query within a React component, call `useGetWurrlyByClassByStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrlyByClassByStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrlyByClassByStudentQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderTitle: // value for 'orderTitle'
 *      type: // value for 'type'
 *      privacySettings: // value for 'privacySettings'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetWurrlyByClassByStudentQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetWurrlyByClassByStudentQuery, Types.GetWurrlyByClassByStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrlyByClassByStudentQuery, Types.GetWurrlyByClassByStudentQueryVariables>(GetWurrlyByClassByStudentDocument, options);
      }
export function useGetWurrlyByClassByStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrlyByClassByStudentQuery, Types.GetWurrlyByClassByStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrlyByClassByStudentQuery, Types.GetWurrlyByClassByStudentQueryVariables>(GetWurrlyByClassByStudentDocument, options);
        }
export type GetWurrlyByClassByStudentQueryHookResult = ReturnType<typeof useGetWurrlyByClassByStudentQuery>;
export type GetWurrlyByClassByStudentLazyQueryHookResult = ReturnType<typeof useGetWurrlyByClassByStudentLazyQuery>;
export type GetWurrlyByClassByStudentQueryResult = Apollo.QueryResult<Types.GetWurrlyByClassByStudentQuery, Types.GetWurrlyByClassByStudentQueryVariables>;
export function refetchGetWurrlyByClassByStudentQuery(variables?: Types.GetWurrlyByClassByStudentQueryVariables) {
      return { query: GetWurrlyByClassByStudentDocument, variables: variables }
    }
export const GetWurrlyProcessingStatusDocument = gql`
    subscription GetWurrlyProcessingStatus($wurrlyId: Int!) {
  wurrly_by_pk(wurrly_id: $wurrlyId) {
    processing_status
  }
}
    `;

/**
 * __useGetWurrlyProcessingStatusSubscription__
 *
 * To run a query within a React component, call `useGetWurrlyProcessingStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrlyProcessingStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrlyProcessingStatusSubscription({
 *   variables: {
 *      wurrlyId: // value for 'wurrlyId'
 *   },
 * });
 */
export function useGetWurrlyProcessingStatusSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.GetWurrlyProcessingStatusSubscription, Types.GetWurrlyProcessingStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetWurrlyProcessingStatusSubscription, Types.GetWurrlyProcessingStatusSubscriptionVariables>(GetWurrlyProcessingStatusDocument, options);
      }
export type GetWurrlyProcessingStatusSubscriptionHookResult = ReturnType<typeof useGetWurrlyProcessingStatusSubscription>;
export type GetWurrlyProcessingStatusSubscriptionResult = Apollo.SubscriptionResult<Types.GetWurrlyProcessingStatusSubscription>;
export const GetWurrlyTypesDocument = gql`
    query GetWurrlyTypes {
  wurrly_type {
    name
  }
}
    `;

/**
 * __useGetWurrlyTypesQuery__
 *
 * To run a query within a React component, call `useGetWurrlyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrlyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrlyTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWurrlyTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetWurrlyTypesQuery, Types.GetWurrlyTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrlyTypesQuery, Types.GetWurrlyTypesQueryVariables>(GetWurrlyTypesDocument, options);
      }
export function useGetWurrlyTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrlyTypesQuery, Types.GetWurrlyTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrlyTypesQuery, Types.GetWurrlyTypesQueryVariables>(GetWurrlyTypesDocument, options);
        }
export type GetWurrlyTypesQueryHookResult = ReturnType<typeof useGetWurrlyTypesQuery>;
export type GetWurrlyTypesLazyQueryHookResult = ReturnType<typeof useGetWurrlyTypesLazyQuery>;
export type GetWurrlyTypesQueryResult = Apollo.QueryResult<Types.GetWurrlyTypesQuery, Types.GetWurrlyTypesQueryVariables>;
export function refetchGetWurrlyTypesQuery(variables?: Types.GetWurrlyTypesQueryVariables) {
      return { query: GetWurrlyTypesDocument, variables: variables }
    }
export const GetStudentClassAsignmentsDocument = gql`
    query GetStudentClassAsignments($studentId: Int!, $offset: Int, $limit: Int, $order: order_by = asc) {
  class_assignment(
    where: {class: {class_students: {student_id: {_eq: $studentId}}}}
    offset: $offset
    limit: $limit
    order_by: {assignment: {name: $order}}
  ) {
    ...ClassAssignmentFragment
  }
  class_assignment_aggregate(
    where: {class: {class_students: {student_id: {_eq: $studentId}}}}
  ) {
    aggregate {
      count
    }
  }
  class_student_aggregate(
    where: {class: {class_students: {student_id: {_eq: $studentId}}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${ClassAssignmentFragmentFragmentDoc}`;

/**
 * __useGetStudentClassAsignmentsQuery__
 *
 * To run a query within a React component, call `useGetStudentClassAsignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentClassAsignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentClassAsignmentsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStudentClassAsignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentClassAsignmentsQuery, Types.GetStudentClassAsignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentClassAsignmentsQuery, Types.GetStudentClassAsignmentsQueryVariables>(GetStudentClassAsignmentsDocument, options);
      }
export function useGetStudentClassAsignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentClassAsignmentsQuery, Types.GetStudentClassAsignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentClassAsignmentsQuery, Types.GetStudentClassAsignmentsQueryVariables>(GetStudentClassAsignmentsDocument, options);
        }
export type GetStudentClassAsignmentsQueryHookResult = ReturnType<typeof useGetStudentClassAsignmentsQuery>;
export type GetStudentClassAsignmentsLazyQueryHookResult = ReturnType<typeof useGetStudentClassAsignmentsLazyQuery>;
export type GetStudentClassAsignmentsQueryResult = Apollo.QueryResult<Types.GetStudentClassAsignmentsQuery, Types.GetStudentClassAsignmentsQueryVariables>;
export function refetchGetStudentClassAsignmentsQuery(variables?: Types.GetStudentClassAsignmentsQueryVariables) {
      return { query: GetStudentClassAsignmentsDocument, variables: variables }
    }
export const GetAssignmentBySubmissionIdDocument = gql`
    query GetAssignmentBySubmissionId($submissionId: Int!) {
  submission_by_pk(submission_id: $submissionId) {
    submission_id
    submission_assets {
      asset {
        asset_id
        name
        resource_path
      }
    }
    submission_students_aggregate {
      aggregate {
        count
      }
    }
    class_assignment {
      class_assignment_id
      class_id
      assignment {
        assignment_id
        name
        instructions
        due_time
        due_date
        include_all_songs
        allow_external_files
        assignment_assets {
          asset {
            asset_id
            name
            resource_path
          }
        }
        assignment_tips(where: {tip: {tip_id: {_is_null: false}}}) {
          assignment_tip_id
          tip {
            title
            tip_id
            resource_path
            image_path
            description
            tip_keywords {
              keyword {
                name
              }
            }
            tip_catalog_item {
              catalog_item {
                catalog {
                  name
                  catalog_id
                }
                catalog_id
                catalog_item_id
                name
              }
              tip_catalog_item_id
            }
          }
        }
        assignment_songs(where: {song: {song_id: {_is_null: false}}}) {
          assignment_song_id
          song {
            ...SongExtendedFragment
          }
        }
      }
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetAssignmentBySubmissionIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentBySubmissionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentBySubmissionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentBySubmissionIdQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useGetAssignmentBySubmissionIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssignmentBySubmissionIdQuery, Types.GetAssignmentBySubmissionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssignmentBySubmissionIdQuery, Types.GetAssignmentBySubmissionIdQueryVariables>(GetAssignmentBySubmissionIdDocument, options);
      }
export function useGetAssignmentBySubmissionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssignmentBySubmissionIdQuery, Types.GetAssignmentBySubmissionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssignmentBySubmissionIdQuery, Types.GetAssignmentBySubmissionIdQueryVariables>(GetAssignmentBySubmissionIdDocument, options);
        }
export type GetAssignmentBySubmissionIdQueryHookResult = ReturnType<typeof useGetAssignmentBySubmissionIdQuery>;
export type GetAssignmentBySubmissionIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentBySubmissionIdLazyQuery>;
export type GetAssignmentBySubmissionIdQueryResult = Apollo.QueryResult<Types.GetAssignmentBySubmissionIdQuery, Types.GetAssignmentBySubmissionIdQueryVariables>;
export function refetchGetAssignmentBySubmissionIdQuery(variables?: Types.GetAssignmentBySubmissionIdQueryVariables) {
      return { query: GetAssignmentBySubmissionIdDocument, variables: variables }
    }
export const GetBadgesDocument = gql`
    query getBadges {
  badge(order_by: {badge_id: asc}) {
    icon_awarded
    icon_empty
    icon
    icon_plain
    points
    name
    badge_id
    description
    color
  }
}
    `;

/**
 * __useGetBadgesQuery__
 *
 * To run a query within a React component, call `useGetBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBadgesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBadgesQuery, Types.GetBadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBadgesQuery, Types.GetBadgesQueryVariables>(GetBadgesDocument, options);
      }
export function useGetBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBadgesQuery, Types.GetBadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBadgesQuery, Types.GetBadgesQueryVariables>(GetBadgesDocument, options);
        }
export type GetBadgesQueryHookResult = ReturnType<typeof useGetBadgesQuery>;
export type GetBadgesLazyQueryHookResult = ReturnType<typeof useGetBadgesLazyQuery>;
export type GetBadgesQueryResult = Apollo.QueryResult<Types.GetBadgesQuery, Types.GetBadgesQueryVariables>;
export function refetchGetBadgesQuery(variables?: Types.GetBadgesQueryVariables) {
      return { query: GetBadgesDocument, variables: variables }
    }
export const GetTeacherBadgesDocument = gql`
    query getTeacherBadges {
  badge(order_by: {badge_id: asc}) {
    points
    name
    badge_id
    description
    color
  }
}
    `;

/**
 * __useGetTeacherBadgesQuery__
 *
 * To run a query within a React component, call `useGetTeacherBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeacherBadgesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTeacherBadgesQuery, Types.GetTeacherBadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherBadgesQuery, Types.GetTeacherBadgesQueryVariables>(GetTeacherBadgesDocument, options);
      }
export function useGetTeacherBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherBadgesQuery, Types.GetTeacherBadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherBadgesQuery, Types.GetTeacherBadgesQueryVariables>(GetTeacherBadgesDocument, options);
        }
export type GetTeacherBadgesQueryHookResult = ReturnType<typeof useGetTeacherBadgesQuery>;
export type GetTeacherBadgesLazyQueryHookResult = ReturnType<typeof useGetTeacherBadgesLazyQuery>;
export type GetTeacherBadgesQueryResult = Apollo.QueryResult<Types.GetTeacherBadgesQuery, Types.GetTeacherBadgesQueryVariables>;
export function refetchGetTeacherBadgesQuery(variables?: Types.GetTeacherBadgesQueryVariables) {
      return { query: GetTeacherBadgesDocument, variables: variables }
    }
export const GetBadgesPointsDocument = gql`
    query GetBadgesPoints {
  badge(order_by: {badge_id: asc}) {
    points
  }
}
    `;

/**
 * __useGetBadgesPointsQuery__
 *
 * To run a query within a React component, call `useGetBadgesPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesPointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBadgesPointsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBadgesPointsQuery, Types.GetBadgesPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBadgesPointsQuery, Types.GetBadgesPointsQueryVariables>(GetBadgesPointsDocument, options);
      }
export function useGetBadgesPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBadgesPointsQuery, Types.GetBadgesPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBadgesPointsQuery, Types.GetBadgesPointsQueryVariables>(GetBadgesPointsDocument, options);
        }
export type GetBadgesPointsQueryHookResult = ReturnType<typeof useGetBadgesPointsQuery>;
export type GetBadgesPointsLazyQueryHookResult = ReturnType<typeof useGetBadgesPointsLazyQuery>;
export type GetBadgesPointsQueryResult = Apollo.QueryResult<Types.GetBadgesPointsQuery, Types.GetBadgesPointsQueryVariables>;
export function refetchGetBadgesPointsQuery(variables?: Types.GetBadgesPointsQueryVariables) {
      return { query: GetBadgesPointsDocument, variables: variables }
    }
export const GetStudentBadgesDocument = gql`
    query getStudentBadges($studentId: Int!) {
  student_badge_resume(where: {student_id: {_eq: $studentId}}) {
    badge_id
    total_badges
    badge_total_points
    last_awarded
  }
}
    `;

/**
 * __useGetStudentBadgesQuery__
 *
 * To run a query within a React component, call `useGetStudentBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentBadgesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentBadgesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentBadgesQuery, Types.GetStudentBadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentBadgesQuery, Types.GetStudentBadgesQueryVariables>(GetStudentBadgesDocument, options);
      }
export function useGetStudentBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentBadgesQuery, Types.GetStudentBadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentBadgesQuery, Types.GetStudentBadgesQueryVariables>(GetStudentBadgesDocument, options);
        }
export type GetStudentBadgesQueryHookResult = ReturnType<typeof useGetStudentBadgesQuery>;
export type GetStudentBadgesLazyQueryHookResult = ReturnType<typeof useGetStudentBadgesLazyQuery>;
export type GetStudentBadgesQueryResult = Apollo.QueryResult<Types.GetStudentBadgesQuery, Types.GetStudentBadgesQueryVariables>;
export function refetchGetStudentBadgesQuery(variables?: Types.GetStudentBadgesQueryVariables) {
      return { query: GetStudentBadgesDocument, variables: variables }
    }
export const GetSongCatalogDocument = gql`
    query GetSongCatalog {
  catalog_item(
    where: {active: {_eq: true}, catalog: {song_order: {_is_null: false}}}
    order_by: {catalog: {song_order: asc}, order: asc}
  ) {
    catalog_item_id
    catalog {
      catalog_id
      name
    }
    image_path
    icon_path
    name
    order
    active
  }
}
    `;

/**
 * __useGetSongCatalogQuery__
 *
 * To run a query within a React component, call `useGetSongCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSongCatalogQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSongCatalogQuery, Types.GetSongCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongCatalogQuery, Types.GetSongCatalogQueryVariables>(GetSongCatalogDocument, options);
      }
export function useGetSongCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongCatalogQuery, Types.GetSongCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongCatalogQuery, Types.GetSongCatalogQueryVariables>(GetSongCatalogDocument, options);
        }
export type GetSongCatalogQueryHookResult = ReturnType<typeof useGetSongCatalogQuery>;
export type GetSongCatalogLazyQueryHookResult = ReturnType<typeof useGetSongCatalogLazyQuery>;
export type GetSongCatalogQueryResult = Apollo.QueryResult<Types.GetSongCatalogQuery, Types.GetSongCatalogQueryVariables>;
export function refetchGetSongCatalogQuery(variables?: Types.GetSongCatalogQueryVariables) {
      return { query: GetSongCatalogDocument, variables: variables }
    }
export const ChallengeSeenDocument = gql`
    mutation ChallengeSeen($conditions: [challenge_student_insert_input!]!) {
  insert_challenge_student(objects: $conditions) {
    affected_rows
  }
}
    `;
export type ChallengeSeenMutationFn = Apollo.MutationFunction<Types.ChallengeSeenMutation, Types.ChallengeSeenMutationVariables>;

/**
 * __useChallengeSeenMutation__
 *
 * To run a mutation, you first call `useChallengeSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChallengeSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [challengeSeenMutation, { data, loading, error }] = useChallengeSeenMutation({
 *   variables: {
 *      conditions: // value for 'conditions'
 *   },
 * });
 */
export function useChallengeSeenMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChallengeSeenMutation, Types.ChallengeSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChallengeSeenMutation, Types.ChallengeSeenMutationVariables>(ChallengeSeenDocument, options);
      }
export type ChallengeSeenMutationHookResult = ReturnType<typeof useChallengeSeenMutation>;
export type ChallengeSeenMutationResult = Apollo.MutationResult<Types.ChallengeSeenMutation>;
export type ChallengeSeenMutationOptions = Apollo.BaseMutationOptions<Types.ChallengeSeenMutation, Types.ChallengeSeenMutationVariables>;
export const GetChallengesDocument = gql`
    query GetChallenges($studentId: Int!, $firstTime: Boolean!, $date: String!) {
  getStudentChallenges(
    filter: {student_id: $studentId, firstTime: $firstTime, date: $date}
  ) {
    challenges {
      challenge_id
      title
      image_path
      video_path
      publish_date
      publish_time
      end_date
      end_time
      description
    }
  }
}
    `;

/**
 * __useGetChallengesQuery__
 *
 * To run a query within a React component, call `useGetChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      firstTime: // value for 'firstTime'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetChallengesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengesQuery, Types.GetChallengesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengesQuery, Types.GetChallengesQueryVariables>(GetChallengesDocument, options);
      }
export function useGetChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengesQuery, Types.GetChallengesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengesQuery, Types.GetChallengesQueryVariables>(GetChallengesDocument, options);
        }
export type GetChallengesQueryHookResult = ReturnType<typeof useGetChallengesQuery>;
export type GetChallengesLazyQueryHookResult = ReturnType<typeof useGetChallengesLazyQuery>;
export type GetChallengesQueryResult = Apollo.QueryResult<Types.GetChallengesQuery, Types.GetChallengesQueryVariables>;
export function refetchGetChallengesQuery(variables?: Types.GetChallengesQueryVariables) {
      return { query: GetChallengesDocument, variables: variables }
    }
export const GetFinishedChallengesDocument = gql`
    query GetFinishedChallenges($studentId: Int) {
  wurrly(
    where: {student_id_owner: {_eq: $studentId}, wurrly_type: {_eq: challenge}}
  ) {
    challenge_id
  }
}
    `;

/**
 * __useGetFinishedChallengesQuery__
 *
 * To run a query within a React component, call `useGetFinishedChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinishedChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinishedChallengesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetFinishedChallengesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFinishedChallengesQuery, Types.GetFinishedChallengesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFinishedChallengesQuery, Types.GetFinishedChallengesQueryVariables>(GetFinishedChallengesDocument, options);
      }
export function useGetFinishedChallengesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFinishedChallengesQuery, Types.GetFinishedChallengesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFinishedChallengesQuery, Types.GetFinishedChallengesQueryVariables>(GetFinishedChallengesDocument, options);
        }
export type GetFinishedChallengesQueryHookResult = ReturnType<typeof useGetFinishedChallengesQuery>;
export type GetFinishedChallengesLazyQueryHookResult = ReturnType<typeof useGetFinishedChallengesLazyQuery>;
export type GetFinishedChallengesQueryResult = Apollo.QueryResult<Types.GetFinishedChallengesQuery, Types.GetFinishedChallengesQueryVariables>;
export function refetchGetFinishedChallengesQuery(variables?: Types.GetFinishedChallengesQueryVariables) {
      return { query: GetFinishedChallengesDocument, variables: variables }
    }
export const GetChallengeByIdDocument = gql`
    query GetChallengeById($challengeId: Int!) {
  challenge_by_pk(challenge_id: $challengeId) {
    challenge_id
    image_path
    title
    description
    video_path
    include_all_songs
    challenge_songs {
      song {
        ...SongExtendedFragment
      }
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetChallengeByIdQuery__
 *
 * To run a query within a React component, call `useGetChallengeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeByIdQuery({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useGetChallengeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChallengeByIdQuery, Types.GetChallengeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChallengeByIdQuery, Types.GetChallengeByIdQueryVariables>(GetChallengeByIdDocument, options);
      }
export function useGetChallengeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChallengeByIdQuery, Types.GetChallengeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChallengeByIdQuery, Types.GetChallengeByIdQueryVariables>(GetChallengeByIdDocument, options);
        }
export type GetChallengeByIdQueryHookResult = ReturnType<typeof useGetChallengeByIdQuery>;
export type GetChallengeByIdLazyQueryHookResult = ReturnType<typeof useGetChallengeByIdLazyQuery>;
export type GetChallengeByIdQueryResult = Apollo.QueryResult<Types.GetChallengeByIdQuery, Types.GetChallengeByIdQueryVariables>;
export function refetchGetChallengeByIdQuery(variables?: Types.GetChallengeByIdQueryVariables) {
      return { query: GetChallengeByIdDocument, variables: variables }
    }
export const GetBadgeDataDocument = gql`
    query GetBadgeData($badgeId: Int!) {
  badge_by_pk(badge_id: $badgeId) {
    name
    points
    icon
  }
}
    `;

/**
 * __useGetBadgeDataQuery__
 *
 * To run a query within a React component, call `useGetBadgeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgeDataQuery({
 *   variables: {
 *      badgeId: // value for 'badgeId'
 *   },
 * });
 */
export function useGetBadgeDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBadgeDataQuery, Types.GetBadgeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBadgeDataQuery, Types.GetBadgeDataQueryVariables>(GetBadgeDataDocument, options);
      }
export function useGetBadgeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBadgeDataQuery, Types.GetBadgeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBadgeDataQuery, Types.GetBadgeDataQueryVariables>(GetBadgeDataDocument, options);
        }
export type GetBadgeDataQueryHookResult = ReturnType<typeof useGetBadgeDataQuery>;
export type GetBadgeDataLazyQueryHookResult = ReturnType<typeof useGetBadgeDataLazyQuery>;
export type GetBadgeDataQueryResult = Apollo.QueryResult<Types.GetBadgeDataQuery, Types.GetBadgeDataQueryVariables>;
export function refetchGetBadgeDataQuery(variables?: Types.GetBadgeDataQueryVariables) {
      return { query: GetBadgeDataDocument, variables: variables }
    }
export const GetClassesByStudentDocument = gql`
    query GetClassesByStudent($studentId: Int!) {
  class(
    where: {class_students: {student_id: {_eq: $studentId}}}
    order_by: {created_at: desc_nulls_last}
  ) {
    ...ClassDetailsReducedFragment
    ...ClassCountersFragment
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}`;

/**
 * __useGetClassesByStudentQuery__
 *
 * To run a query within a React component, call `useGetClassesByStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesByStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesByStudentQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetClassesByStudentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassesByStudentQuery, Types.GetClassesByStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassesByStudentQuery, Types.GetClassesByStudentQueryVariables>(GetClassesByStudentDocument, options);
      }
export function useGetClassesByStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassesByStudentQuery, Types.GetClassesByStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassesByStudentQuery, Types.GetClassesByStudentQueryVariables>(GetClassesByStudentDocument, options);
        }
export type GetClassesByStudentQueryHookResult = ReturnType<typeof useGetClassesByStudentQuery>;
export type GetClassesByStudentLazyQueryHookResult = ReturnType<typeof useGetClassesByStudentLazyQuery>;
export type GetClassesByStudentQueryResult = Apollo.QueryResult<Types.GetClassesByStudentQuery, Types.GetClassesByStudentQueryVariables>;
export function refetchGetClassesByStudentQuery(variables?: Types.GetClassesByStudentQueryVariables) {
      return { query: GetClassesByStudentDocument, variables: variables }
    }
export const GetClassMaturityLevelDocument = gql`
    query GetClassMaturityLevel($classId: Int!) {
  class_by_pk(class_id: $classId) {
    maturity_level
  }
}
    `;

/**
 * __useGetClassMaturityLevelQuery__
 *
 * To run a query within a React component, call `useGetClassMaturityLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassMaturityLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassMaturityLevelQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassMaturityLevelQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassMaturityLevelQuery, Types.GetClassMaturityLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassMaturityLevelQuery, Types.GetClassMaturityLevelQueryVariables>(GetClassMaturityLevelDocument, options);
      }
export function useGetClassMaturityLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassMaturityLevelQuery, Types.GetClassMaturityLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassMaturityLevelQuery, Types.GetClassMaturityLevelQueryVariables>(GetClassMaturityLevelDocument, options);
        }
export type GetClassMaturityLevelQueryHookResult = ReturnType<typeof useGetClassMaturityLevelQuery>;
export type GetClassMaturityLevelLazyQueryHookResult = ReturnType<typeof useGetClassMaturityLevelLazyQuery>;
export type GetClassMaturityLevelQueryResult = Apollo.QueryResult<Types.GetClassMaturityLevelQuery, Types.GetClassMaturityLevelQueryVariables>;
export function refetchGetClassMaturityLevelQuery(variables?: Types.GetClassMaturityLevelQueryVariables) {
      return { query: GetClassMaturityLevelDocument, variables: variables }
    }
export const GetClassesByStudentByPkDocument = gql`
    query getClassesByStudentByPk($studentId: Int!) {
  student_by_pk(student_id: $studentId) {
    class_students {
      class_id
      class {
        ...ClassDetailsReducedFragment
      }
    }
    student_id
    cover_image_path
    last_name
    middle_name
    stage_name
    first_name
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}`;

/**
 * __useGetClassesByStudentByPkQuery__
 *
 * To run a query within a React component, call `useGetClassesByStudentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesByStudentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesByStudentByPkQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetClassesByStudentByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassesByStudentByPkQuery, Types.GetClassesByStudentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassesByStudentByPkQuery, Types.GetClassesByStudentByPkQueryVariables>(GetClassesByStudentByPkDocument, options);
      }
export function useGetClassesByStudentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassesByStudentByPkQuery, Types.GetClassesByStudentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassesByStudentByPkQuery, Types.GetClassesByStudentByPkQueryVariables>(GetClassesByStudentByPkDocument, options);
        }
export type GetClassesByStudentByPkQueryHookResult = ReturnType<typeof useGetClassesByStudentByPkQuery>;
export type GetClassesByStudentByPkLazyQueryHookResult = ReturnType<typeof useGetClassesByStudentByPkLazyQuery>;
export type GetClassesByStudentByPkQueryResult = Apollo.QueryResult<Types.GetClassesByStudentByPkQuery, Types.GetClassesByStudentByPkQueryVariables>;
export function refetchGetClassesByStudentByPkQuery(variables?: Types.GetClassesByStudentByPkQueryVariables) {
      return { query: GetClassesByStudentByPkDocument, variables: variables }
    }
export const GetClassDetailsDocument = gql`
    query GetClassDetails($classId: Int!) {
  class_by_pk(class_id: $classId) {
    ...ClassDetailsReducedFragment
    ...ClassCountersFragment
  }
  playlist_tip_aggregate: tip_aggregate(
    where: {_or: [{tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}, {class_tips: {class_id: {_eq: $classId}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}`;

/**
 * __useGetClassDetailsQuery__
 *
 * To run a query within a React component, call `useGetClassDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassDetailsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassDetailsQuery, Types.GetClassDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassDetailsQuery, Types.GetClassDetailsQueryVariables>(GetClassDetailsDocument, options);
      }
export function useGetClassDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassDetailsQuery, Types.GetClassDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassDetailsQuery, Types.GetClassDetailsQueryVariables>(GetClassDetailsDocument, options);
        }
export type GetClassDetailsQueryHookResult = ReturnType<typeof useGetClassDetailsQuery>;
export type GetClassDetailsLazyQueryHookResult = ReturnType<typeof useGetClassDetailsLazyQuery>;
export type GetClassDetailsQueryResult = Apollo.QueryResult<Types.GetClassDetailsQuery, Types.GetClassDetailsQueryVariables>;
export function refetchGetClassDetailsQuery(variables?: Types.GetClassDetailsQueryVariables) {
      return { query: GetClassDetailsDocument, variables: variables }
    }
export const UpdateStudentCoverDocument = gql`
    mutation UpdateStudentCover($student_id: Int!, $cover_image_path: String = "") {
  update_student(
    where: {student_id: {_eq: $student_id}}
    _set: {cover_image_path: $cover_image_path}
  ) {
    affected_rows
    returning {
      cover_image_path
      student_id
    }
  }
}
    `;
export type UpdateStudentCoverMutationFn = Apollo.MutationFunction<Types.UpdateStudentCoverMutation, Types.UpdateStudentCoverMutationVariables>;

/**
 * __useUpdateStudentCoverMutation__
 *
 * To run a mutation, you first call `useUpdateStudentCoverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentCoverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentCoverMutation, { data, loading, error }] = useUpdateStudentCoverMutation({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      cover_image_path: // value for 'cover_image_path'
 *   },
 * });
 */
export function useUpdateStudentCoverMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudentCoverMutation, Types.UpdateStudentCoverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudentCoverMutation, Types.UpdateStudentCoverMutationVariables>(UpdateStudentCoverDocument, options);
      }
export type UpdateStudentCoverMutationHookResult = ReturnType<typeof useUpdateStudentCoverMutation>;
export type UpdateStudentCoverMutationResult = Apollo.MutationResult<Types.UpdateStudentCoverMutation>;
export type UpdateStudentCoverMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudentCoverMutation, Types.UpdateStudentCoverMutationVariables>;
export const GetFilterFramesDocument = gql`
    query GetFilterFrames {
  filter_frame(where: {active: {_eq: true}}) {
    filter_frame_id
    image_path
    frame_name
  }
}
    `;

/**
 * __useGetFilterFramesQuery__
 *
 * To run a query within a React component, call `useGetFilterFramesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterFramesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterFramesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterFramesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFilterFramesQuery, Types.GetFilterFramesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilterFramesQuery, Types.GetFilterFramesQueryVariables>(GetFilterFramesDocument, options);
      }
export function useGetFilterFramesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilterFramesQuery, Types.GetFilterFramesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilterFramesQuery, Types.GetFilterFramesQueryVariables>(GetFilterFramesDocument, options);
        }
export type GetFilterFramesQueryHookResult = ReturnType<typeof useGetFilterFramesQuery>;
export type GetFilterFramesLazyQueryHookResult = ReturnType<typeof useGetFilterFramesLazyQuery>;
export type GetFilterFramesQueryResult = Apollo.QueryResult<Types.GetFilterFramesQuery, Types.GetFilterFramesQueryVariables>;
export function refetchGetFilterFramesQuery(variables?: Types.GetFilterFramesQueryVariables) {
      return { query: GetFilterFramesDocument, variables: variables }
    }
export const GetStickersDocument = gql`
    query GetStickers {
  filter_sticker(where: {active: {_eq: true}}) {
    filter_sticker_id
    image_path
  }
}
    `;

/**
 * __useGetStickersQuery__
 *
 * To run a query within a React component, call `useGetStickersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStickersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStickersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStickersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStickersQuery, Types.GetStickersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStickersQuery, Types.GetStickersQueryVariables>(GetStickersDocument, options);
      }
export function useGetStickersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStickersQuery, Types.GetStickersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStickersQuery, Types.GetStickersQueryVariables>(GetStickersDocument, options);
        }
export type GetStickersQueryHookResult = ReturnType<typeof useGetStickersQuery>;
export type GetStickersLazyQueryHookResult = ReturnType<typeof useGetStickersLazyQuery>;
export type GetStickersQueryResult = Apollo.QueryResult<Types.GetStickersQuery, Types.GetStickersQueryVariables>;
export function refetchGetStickersQuery(variables?: Types.GetStickersQueryVariables) {
      return { query: GetStickersDocument, variables: variables }
    }
export const GetColorFiltersDocument = gql`
    query GetColorFilters {
  filter_color(where: {active: {_eq: true}}, order_by: {order: asc}) {
    filter_color_id
    name
    display_name
    thumbnail_path
  }
}
    `;

/**
 * __useGetColorFiltersQuery__
 *
 * To run a query within a React component, call `useGetColorFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetColorFiltersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetColorFiltersQuery, Types.GetColorFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetColorFiltersQuery, Types.GetColorFiltersQueryVariables>(GetColorFiltersDocument, options);
      }
export function useGetColorFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetColorFiltersQuery, Types.GetColorFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetColorFiltersQuery, Types.GetColorFiltersQueryVariables>(GetColorFiltersDocument, options);
        }
export type GetColorFiltersQueryHookResult = ReturnType<typeof useGetColorFiltersQuery>;
export type GetColorFiltersLazyQueryHookResult = ReturnType<typeof useGetColorFiltersLazyQuery>;
export type GetColorFiltersQueryResult = Apollo.QueryResult<Types.GetColorFiltersQuery, Types.GetColorFiltersQueryVariables>;
export function refetchGetColorFiltersQuery(variables?: Types.GetColorFiltersQueryVariables) {
      return { query: GetColorFiltersDocument, variables: variables }
    }
export const GetFavoriteSongsDocument = gql`
    query getFavoriteSongs($studentId: Int, $limit: Int, $orderTitle: order_by, $orderDate: order_by) {
  student_song_favorite(
    where: {student_id: {_eq: $studentId}, song: {song_id: {_is_null: false}}}
    limit: $limit
    order_by: {song: {title: $orderTitle}, updated_at: $orderDate}
  ) {
    song {
      is_student_favorite(args: {studentid: $studentId})
      ...songReducedFragment
    }
  }
  student_song_favorite_aggregate(
    where: {student_id: {_eq: $studentId}, song: {song_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${SongReducedFragmentFragmentDoc}`;

/**
 * __useGetFavoriteSongsQuery__
 *
 * To run a query within a React component, call `useGetFavoriteSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteSongsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetFavoriteSongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFavoriteSongsQuery, Types.GetFavoriteSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteSongsQuery, Types.GetFavoriteSongsQueryVariables>(GetFavoriteSongsDocument, options);
      }
export function useGetFavoriteSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteSongsQuery, Types.GetFavoriteSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteSongsQuery, Types.GetFavoriteSongsQueryVariables>(GetFavoriteSongsDocument, options);
        }
export type GetFavoriteSongsQueryHookResult = ReturnType<typeof useGetFavoriteSongsQuery>;
export type GetFavoriteSongsLazyQueryHookResult = ReturnType<typeof useGetFavoriteSongsLazyQuery>;
export type GetFavoriteSongsQueryResult = Apollo.QueryResult<Types.GetFavoriteSongsQuery, Types.GetFavoriteSongsQueryVariables>;
export function refetchGetFavoriteSongsQuery(variables?: Types.GetFavoriteSongsQueryVariables) {
      return { query: GetFavoriteSongsDocument, variables: variables }
    }
export const InsertWurrlyReactionDocument = gql`
    mutation InsertWurrlyReaction($reaction_id: Int!, $student_id: Int!, $wurrly_id: Int!) {
  insert_wurrly_reaction(
    objects: {reaction_id: $reaction_id, student_id: $student_id, wurrly_id: $wurrly_id}
  ) {
    affected_rows
  }
}
    `;
export type InsertWurrlyReactionMutationFn = Apollo.MutationFunction<Types.InsertWurrlyReactionMutation, Types.InsertWurrlyReactionMutationVariables>;

/**
 * __useInsertWurrlyReactionMutation__
 *
 * To run a mutation, you first call `useInsertWurrlyReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWurrlyReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWurrlyReactionMutation, { data, loading, error }] = useInsertWurrlyReactionMutation({
 *   variables: {
 *      reaction_id: // value for 'reaction_id'
 *      student_id: // value for 'student_id'
 *      wurrly_id: // value for 'wurrly_id'
 *   },
 * });
 */
export function useInsertWurrlyReactionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertWurrlyReactionMutation, Types.InsertWurrlyReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertWurrlyReactionMutation, Types.InsertWurrlyReactionMutationVariables>(InsertWurrlyReactionDocument, options);
      }
export type InsertWurrlyReactionMutationHookResult = ReturnType<typeof useInsertWurrlyReactionMutation>;
export type InsertWurrlyReactionMutationResult = Apollo.MutationResult<Types.InsertWurrlyReactionMutation>;
export type InsertWurrlyReactionMutationOptions = Apollo.BaseMutationOptions<Types.InsertWurrlyReactionMutation, Types.InsertWurrlyReactionMutationVariables>;
export const DeleteWurrlyReactionDocument = gql`
    mutation DeleteWurrlyReaction($wurrly_id: Int!, $wurrly_reaction_id: Int!) {
  delete_wurrly_reaction(
    where: {wurrly_id: {_eq: $wurrly_id}, wurrly_reaction_id: {_eq: $wurrly_reaction_id}}
  ) {
    affected_rows
    returning {
      reaction_id
      wurrly_id
      wurrly_reaction_id
    }
  }
}
    `;
export type DeleteWurrlyReactionMutationFn = Apollo.MutationFunction<Types.DeleteWurrlyReactionMutation, Types.DeleteWurrlyReactionMutationVariables>;

/**
 * __useDeleteWurrlyReactionMutation__
 *
 * To run a mutation, you first call `useDeleteWurrlyReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWurrlyReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWurrlyReactionMutation, { data, loading, error }] = useDeleteWurrlyReactionMutation({
 *   variables: {
 *      wurrly_id: // value for 'wurrly_id'
 *      wurrly_reaction_id: // value for 'wurrly_reaction_id'
 *   },
 * });
 */
export function useDeleteWurrlyReactionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWurrlyReactionMutation, Types.DeleteWurrlyReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWurrlyReactionMutation, Types.DeleteWurrlyReactionMutationVariables>(DeleteWurrlyReactionDocument, options);
      }
export type DeleteWurrlyReactionMutationHookResult = ReturnType<typeof useDeleteWurrlyReactionMutation>;
export type DeleteWurrlyReactionMutationResult = Apollo.MutationResult<Types.DeleteWurrlyReactionMutation>;
export type DeleteWurrlyReactionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWurrlyReactionMutation, Types.DeleteWurrlyReactionMutationVariables>;
export const UpdateWurrlyReactionByPkDocument = gql`
    mutation UpdateWurrlyReactionByPk($wurrly_reaction_id: Int!, $reaction_id: Int!) {
  update_wurrly_reaction_by_pk(
    pk_columns: {wurrly_reaction_id: $wurrly_reaction_id}
    _set: {reaction_id: $reaction_id}
  ) {
    wurrly_reaction_id
  }
}
    `;
export type UpdateWurrlyReactionByPkMutationFn = Apollo.MutationFunction<Types.UpdateWurrlyReactionByPkMutation, Types.UpdateWurrlyReactionByPkMutationVariables>;

/**
 * __useUpdateWurrlyReactionByPkMutation__
 *
 * To run a mutation, you first call `useUpdateWurrlyReactionByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWurrlyReactionByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWurrlyReactionByPkMutation, { data, loading, error }] = useUpdateWurrlyReactionByPkMutation({
 *   variables: {
 *      wurrly_reaction_id: // value for 'wurrly_reaction_id'
 *      reaction_id: // value for 'reaction_id'
 *   },
 * });
 */
export function useUpdateWurrlyReactionByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWurrlyReactionByPkMutation, Types.UpdateWurrlyReactionByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWurrlyReactionByPkMutation, Types.UpdateWurrlyReactionByPkMutationVariables>(UpdateWurrlyReactionByPkDocument, options);
      }
export type UpdateWurrlyReactionByPkMutationHookResult = ReturnType<typeof useUpdateWurrlyReactionByPkMutation>;
export type UpdateWurrlyReactionByPkMutationResult = Apollo.MutationResult<Types.UpdateWurrlyReactionByPkMutation>;
export type UpdateWurrlyReactionByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWurrlyReactionByPkMutation, Types.UpdateWurrlyReactionByPkMutationVariables>;
export const GetReactionsDocument = gql`
    query GetReactions {
  reaction {
    icon
    name
    reaction_id
  }
}
    `;

/**
 * __useGetReactionsQuery__
 *
 * To run a query within a React component, call `useGetReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReactionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetReactionsQuery, Types.GetReactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetReactionsQuery, Types.GetReactionsQueryVariables>(GetReactionsDocument, options);
      }
export function useGetReactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetReactionsQuery, Types.GetReactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetReactionsQuery, Types.GetReactionsQueryVariables>(GetReactionsDocument, options);
        }
export type GetReactionsQueryHookResult = ReturnType<typeof useGetReactionsQuery>;
export type GetReactionsLazyQueryHookResult = ReturnType<typeof useGetReactionsLazyQuery>;
export type GetReactionsQueryResult = Apollo.QueryResult<Types.GetReactionsQuery, Types.GetReactionsQueryVariables>;
export function refetchGetReactionsQuery(variables?: Types.GetReactionsQueryVariables) {
      return { query: GetReactionsDocument, variables: variables }
    }
export const SetAcceptConcentDocument = gql`
    mutation SetAcceptConcent($filter: AcceptConsentFilter!) {
  acceptConsent(filter: $filter) {
    error
  }
}
    `;
export type SetAcceptConcentMutationFn = Apollo.MutationFunction<Types.SetAcceptConcentMutation, Types.SetAcceptConcentMutationVariables>;

/**
 * __useSetAcceptConcentMutation__
 *
 * To run a mutation, you first call `useSetAcceptConcentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAcceptConcentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAcceptConcentMutation, { data, loading, error }] = useSetAcceptConcentMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSetAcceptConcentMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetAcceptConcentMutation, Types.SetAcceptConcentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetAcceptConcentMutation, Types.SetAcceptConcentMutationVariables>(SetAcceptConcentDocument, options);
      }
export type SetAcceptConcentMutationHookResult = ReturnType<typeof useSetAcceptConcentMutation>;
export type SetAcceptConcentMutationResult = Apollo.MutationResult<Types.SetAcceptConcentMutation>;
export type SetAcceptConcentMutationOptions = Apollo.BaseMutationOptions<Types.SetAcceptConcentMutation, Types.SetAcceptConcentMutationVariables>;
export const GetSongChordsAndLyricsDocument = gql`
    query GetSongChordsAndLyrics($speed: Float!, $trackId: Int!, $transposition: Int!) {
  getSongTrackEvents(
    filter: {speed: $speed, trackId: $trackId, transposition: $transposition}
  ) {
    eventData {
      header {
        formatType
        ticksPerBeat
        trackCount
      }
      infoEvents {
        channel
        deltaTime
        denominator
        frame
        frameRate
        hour
        key
        metronome
        microsecondsPerBeat
        min
        noteNumber
        numerator
        scale
        sec
        subframe
        subtype
        text
        thirtyseconds
        time
        type
        velocity
      }
      msPerTick
      rows {
        deltaTime
        order
        subtype
        text
        time
        type
      }
      totalTime
    }
  }
}
    `;

/**
 * __useGetSongChordsAndLyricsQuery__
 *
 * To run a query within a React component, call `useGetSongChordsAndLyricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongChordsAndLyricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongChordsAndLyricsQuery({
 *   variables: {
 *      speed: // value for 'speed'
 *      trackId: // value for 'trackId'
 *      transposition: // value for 'transposition'
 *   },
 * });
 */
export function useGetSongChordsAndLyricsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongChordsAndLyricsQuery, Types.GetSongChordsAndLyricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongChordsAndLyricsQuery, Types.GetSongChordsAndLyricsQueryVariables>(GetSongChordsAndLyricsDocument, options);
      }
export function useGetSongChordsAndLyricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongChordsAndLyricsQuery, Types.GetSongChordsAndLyricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongChordsAndLyricsQuery, Types.GetSongChordsAndLyricsQueryVariables>(GetSongChordsAndLyricsDocument, options);
        }
export type GetSongChordsAndLyricsQueryHookResult = ReturnType<typeof useGetSongChordsAndLyricsQuery>;
export type GetSongChordsAndLyricsLazyQueryHookResult = ReturnType<typeof useGetSongChordsAndLyricsLazyQuery>;
export type GetSongChordsAndLyricsQueryResult = Apollo.QueryResult<Types.GetSongChordsAndLyricsQuery, Types.GetSongChordsAndLyricsQueryVariables>;
export function refetchGetSongChordsAndLyricsQuery(variables?: Types.GetSongChordsAndLyricsQueryVariables) {
      return { query: GetSongChordsAndLyricsDocument, variables: variables }
    }
export const GetStudentQuestionaryDocument = gql`
    query GetStudentQuestionary($filter: GetStudentQuestionaryFilter!) {
  getStudentQuestionary(filter: $filter) {
    isSchoolTeacher
    questions {
      id
      label
    }
  }
}
    `;

/**
 * __useGetStudentQuestionaryQuery__
 *
 * To run a query within a React component, call `useGetStudentQuestionaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQuestionaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQuestionaryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStudentQuestionaryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentQuestionaryQuery, Types.GetStudentQuestionaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentQuestionaryQuery, Types.GetStudentQuestionaryQueryVariables>(GetStudentQuestionaryDocument, options);
      }
export function useGetStudentQuestionaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentQuestionaryQuery, Types.GetStudentQuestionaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentQuestionaryQuery, Types.GetStudentQuestionaryQueryVariables>(GetStudentQuestionaryDocument, options);
        }
export type GetStudentQuestionaryQueryHookResult = ReturnType<typeof useGetStudentQuestionaryQuery>;
export type GetStudentQuestionaryLazyQueryHookResult = ReturnType<typeof useGetStudentQuestionaryLazyQuery>;
export type GetStudentQuestionaryQueryResult = Apollo.QueryResult<Types.GetStudentQuestionaryQuery, Types.GetStudentQuestionaryQueryVariables>;
export function refetchGetStudentQuestionaryQuery(variables?: Types.GetStudentQuestionaryQueryVariables) {
      return { query: GetStudentQuestionaryDocument, variables: variables }
    }
export const GetLoginStudentByCodeDocument = gql`
    query GetLoginStudentByCode($filter: LoginStudentByCodeFilter!) {
  loginStudentByCode(filter: $filter) {
    token
    isAvatarSelected
  }
}
    `;

/**
 * __useGetLoginStudentByCodeQuery__
 *
 * To run a query within a React component, call `useGetLoginStudentByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginStudentByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginStudentByCodeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLoginStudentByCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLoginStudentByCodeQuery, Types.GetLoginStudentByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLoginStudentByCodeQuery, Types.GetLoginStudentByCodeQueryVariables>(GetLoginStudentByCodeDocument, options);
      }
export function useGetLoginStudentByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLoginStudentByCodeQuery, Types.GetLoginStudentByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLoginStudentByCodeQuery, Types.GetLoginStudentByCodeQueryVariables>(GetLoginStudentByCodeDocument, options);
        }
export type GetLoginStudentByCodeQueryHookResult = ReturnType<typeof useGetLoginStudentByCodeQuery>;
export type GetLoginStudentByCodeLazyQueryHookResult = ReturnType<typeof useGetLoginStudentByCodeLazyQuery>;
export type GetLoginStudentByCodeQueryResult = Apollo.QueryResult<Types.GetLoginStudentByCodeQuery, Types.GetLoginStudentByCodeQueryVariables>;
export function refetchGetLoginStudentByCodeQuery(variables?: Types.GetLoginStudentByCodeQueryVariables) {
      return { query: GetLoginStudentByCodeDocument, variables: variables }
    }
export const StudentIntegrationLoginDocument = gql`
    query StudentIntegrationLogin($integrationId: String!) {
  studentLoginIntegration(filter: {integrationId: $integrationId}) {
    token
  }
}
    `;

/**
 * __useStudentIntegrationLoginQuery__
 *
 * To run a query within a React component, call `useStudentIntegrationLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentIntegrationLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentIntegrationLoginQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useStudentIntegrationLoginQuery(baseOptions: Apollo.QueryHookOptions<Types.StudentIntegrationLoginQuery, Types.StudentIntegrationLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentIntegrationLoginQuery, Types.StudentIntegrationLoginQueryVariables>(StudentIntegrationLoginDocument, options);
      }
export function useStudentIntegrationLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentIntegrationLoginQuery, Types.StudentIntegrationLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentIntegrationLoginQuery, Types.StudentIntegrationLoginQueryVariables>(StudentIntegrationLoginDocument, options);
        }
export type StudentIntegrationLoginQueryHookResult = ReturnType<typeof useStudentIntegrationLoginQuery>;
export type StudentIntegrationLoginLazyQueryHookResult = ReturnType<typeof useStudentIntegrationLoginLazyQuery>;
export type StudentIntegrationLoginQueryResult = Apollo.QueryResult<Types.StudentIntegrationLoginQuery, Types.StudentIntegrationLoginQueryVariables>;
export function refetchStudentIntegrationLoginQuery(variables?: Types.StudentIntegrationLoginQueryVariables) {
      return { query: StudentIntegrationLoginDocument, variables: variables }
    }
export const SetStudentActivationDocument = gql`
    mutation SetStudentActivation($filter: StudentActivationFilter!) {
  studentActivation(filter: $filter) {
    activation
  }
}
    `;
export type SetStudentActivationMutationFn = Apollo.MutationFunction<Types.SetStudentActivationMutation, Types.SetStudentActivationMutationVariables>;

/**
 * __useSetStudentActivationMutation__
 *
 * To run a mutation, you first call `useSetStudentActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStudentActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStudentActivationMutation, { data, loading, error }] = useSetStudentActivationMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSetStudentActivationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetStudentActivationMutation, Types.SetStudentActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetStudentActivationMutation, Types.SetStudentActivationMutationVariables>(SetStudentActivationDocument, options);
      }
export type SetStudentActivationMutationHookResult = ReturnType<typeof useSetStudentActivationMutation>;
export type SetStudentActivationMutationResult = Apollo.MutationResult<Types.SetStudentActivationMutation>;
export type SetStudentActivationMutationOptions = Apollo.BaseMutationOptions<Types.SetStudentActivationMutation, Types.SetStudentActivationMutationVariables>;
export const GetSongForStudioDocument = gql`
    query GetSongForStudio($songId: Int!) {
  song_by_pk(song_id: $songId) {
    ...SongExtendedFragment
    themes: song_catalog_item(
      where: {catalog_item: {catalog: {name: {_eq: themes}}}}
    ) {
      catalog_item {
        name
        catalog_item_id
      }
    }
    genres: song_catalog_item(
      where: {catalog_item: {catalog: {name: {_eq: genres}}}}
    ) {
      catalog_item {
        name
        catalog_item_id
      }
    }
    ages: song_catalog_item(where: {catalog_item: {catalog: {name: {_eq: ages}}}}) {
      catalog_item {
        name
        catalog_item_id
      }
    }
    levels: song_grades(order_by: {grade_id: asc}) {
      grade_id
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetSongForStudioQuery__
 *
 * To run a query within a React component, call `useGetSongForStudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongForStudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongForStudioQuery({
 *   variables: {
 *      songId: // value for 'songId'
 *   },
 * });
 */
export function useGetSongForStudioQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongForStudioQuery, Types.GetSongForStudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongForStudioQuery, Types.GetSongForStudioQueryVariables>(GetSongForStudioDocument, options);
      }
export function useGetSongForStudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongForStudioQuery, Types.GetSongForStudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongForStudioQuery, Types.GetSongForStudioQueryVariables>(GetSongForStudioDocument, options);
        }
export type GetSongForStudioQueryHookResult = ReturnType<typeof useGetSongForStudioQuery>;
export type GetSongForStudioLazyQueryHookResult = ReturnType<typeof useGetSongForStudioLazyQuery>;
export type GetSongForStudioQueryResult = Apollo.QueryResult<Types.GetSongForStudioQuery, Types.GetSongForStudioQueryVariables>;
export function refetchGetSongForStudioQuery(variables?: Types.GetSongForStudioQueryVariables) {
      return { query: GetSongForStudioDocument, variables: variables }
    }
export const GetStudentSongsCatalogDocument = gql`
    query GetStudentSongsCatalog($studentId: Int, $conditions: [song_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int = 5, $order: order_by = asc, $scaleId: Int, $filters: [song_bool_exp!] = [], $chordFilters: [song_bool_exp!] = [], $vocalCondition: song_bool_exp = {}, $chordGuideConfidition: song_bool_exp = {}, $excludeMaturityLevel: [maturity_level_enum!] = []) {
  song(
    where: {song_id: {_is_null: false}, tracks: {midis: {maturity_level: {_nin: $excludeMaturityLevel}}}, scale_id: {_eq: $scaleId}, _or: $conditions, _and: [{_and: $filters}, {_or: $chordFilters}, $vocalCondition, $chordGuideConfidition]}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    recommended
    is_student_favorite(args: {studentid: $studentId})
    tracks(where: {midis: {maturity_level: {_nin: $excludeMaturityLevel}}}) {
      track_id
      track_type {
        name
      }
      resource_path
      midis {
        chord_map_object
        chords_Array
        chords_Array_Zero
      }
    }
    ...SongExtendedFragmentNoTrack
  }
  song_aggregate(
    where: {song_id: {_is_null: false}, tracks: {midis: {maturity_level: {_nin: $excludeMaturityLevel}}}, scale_id: {_eq: $scaleId}, _or: $conditions, _and: [{_and: $filters}, {_or: $chordFilters}, $vocalCondition, $chordGuideConfidition]}
  ) {
    aggregate {
      count
    }
  }
}
    ${SongExtendedFragmentNoTrackFragmentDoc}`;

/**
 * __useGetStudentSongsCatalogQuery__
 *
 * To run a query within a React component, call `useGetStudentSongsCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSongsCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSongsCatalogQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      scaleId: // value for 'scaleId'
 *      filters: // value for 'filters'
 *      chordFilters: // value for 'chordFilters'
 *      vocalCondition: // value for 'vocalCondition'
 *      chordGuideConfidition: // value for 'chordGuideConfidition'
 *      excludeMaturityLevel: // value for 'excludeMaturityLevel'
 *   },
 * });
 */
export function useGetStudentSongsCatalogQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStudentSongsCatalogQuery, Types.GetStudentSongsCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentSongsCatalogQuery, Types.GetStudentSongsCatalogQueryVariables>(GetStudentSongsCatalogDocument, options);
      }
export function useGetStudentSongsCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentSongsCatalogQuery, Types.GetStudentSongsCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentSongsCatalogQuery, Types.GetStudentSongsCatalogQueryVariables>(GetStudentSongsCatalogDocument, options);
        }
export type GetStudentSongsCatalogQueryHookResult = ReturnType<typeof useGetStudentSongsCatalogQuery>;
export type GetStudentSongsCatalogLazyQueryHookResult = ReturnType<typeof useGetStudentSongsCatalogLazyQuery>;
export type GetStudentSongsCatalogQueryResult = Apollo.QueryResult<Types.GetStudentSongsCatalogQuery, Types.GetStudentSongsCatalogQueryVariables>;
export function refetchGetStudentSongsCatalogQuery(variables?: Types.GetStudentSongsCatalogQueryVariables) {
      return { query: GetStudentSongsCatalogDocument, variables: variables }
    }
export const GetSongStudentByPkDocument = gql`
    query GetSongStudentByPk($songId: Int!, $studentId: Int) {
  song(where: {song_id: {_eq: $songId}}) {
    is_student_favorite(args: {studentid: $studentId})
    ...SongExtendedFragment
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetSongStudentByPkQuery__
 *
 * To run a query within a React component, call `useGetSongStudentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongStudentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongStudentByPkQuery({
 *   variables: {
 *      songId: // value for 'songId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetSongStudentByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongStudentByPkQuery, Types.GetSongStudentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongStudentByPkQuery, Types.GetSongStudentByPkQueryVariables>(GetSongStudentByPkDocument, options);
      }
export function useGetSongStudentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongStudentByPkQuery, Types.GetSongStudentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongStudentByPkQuery, Types.GetSongStudentByPkQueryVariables>(GetSongStudentByPkDocument, options);
        }
export type GetSongStudentByPkQueryHookResult = ReturnType<typeof useGetSongStudentByPkQuery>;
export type GetSongStudentByPkLazyQueryHookResult = ReturnType<typeof useGetSongStudentByPkLazyQuery>;
export type GetSongStudentByPkQueryResult = Apollo.QueryResult<Types.GetSongStudentByPkQuery, Types.GetSongStudentByPkQueryVariables>;
export function refetchGetSongStudentByPkQuery(variables?: Types.GetSongStudentByPkQueryVariables) {
      return { query: GetSongStudentByPkDocument, variables: variables }
    }
export const GetStudentSongsByClassDocument = gql`
    query GetStudentSongsByClass($classId: Int, $offset: Int, $order: order_by = asc) {
  class(
    where: {class_id: {_eq: $classId}}
    offset: $offset
    order_by: {title: $order}
  ) {
    class_songs(
      where: {_and: [{song: {song_id: {_is_null: false}}}, {song: {active: {_eq: true}}}]}
    ) {
      song_id
      song {
        ...SongExtendedFragment
      }
    }
    class_songs_aggregate(
      where: {_and: [{song: {song_id: {_is_null: false}}}, {song: {active: {_eq: true}}}]}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetStudentSongsByClassQuery__
 *
 * To run a query within a React component, call `useGetStudentSongsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSongsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSongsByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStudentSongsByClassQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStudentSongsByClassQuery, Types.GetStudentSongsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentSongsByClassQuery, Types.GetStudentSongsByClassQueryVariables>(GetStudentSongsByClassDocument, options);
      }
export function useGetStudentSongsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentSongsByClassQuery, Types.GetStudentSongsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentSongsByClassQuery, Types.GetStudentSongsByClassQueryVariables>(GetStudentSongsByClassDocument, options);
        }
export type GetStudentSongsByClassQueryHookResult = ReturnType<typeof useGetStudentSongsByClassQuery>;
export type GetStudentSongsByClassLazyQueryHookResult = ReturnType<typeof useGetStudentSongsByClassLazyQuery>;
export type GetStudentSongsByClassQueryResult = Apollo.QueryResult<Types.GetStudentSongsByClassQuery, Types.GetStudentSongsByClassQueryVariables>;
export function refetchGetStudentSongsByClassQuery(variables?: Types.GetStudentSongsByClassQueryVariables) {
      return { query: GetStudentSongsByClassDocument, variables: variables }
    }
export const DeleteStudentSongFavoriteDocument = gql`
    mutation deleteStudentSongFavorite($songId: Int!, $studentId: Int) {
  delete_student_song_favorite(
    where: {song_id: {_eq: $songId}, student_id: {_eq: $studentId}}
  ) {
    returning {
      student_id
      song {
        teacher_id
        song_id
        is_student_favorite(args: {studentid: $studentId})
      }
    }
  }
}
    `;
export type DeleteStudentSongFavoriteMutationFn = Apollo.MutationFunction<Types.DeleteStudentSongFavoriteMutation, Types.DeleteStudentSongFavoriteMutationVariables>;

/**
 * __useDeleteStudentSongFavoriteMutation__
 *
 * To run a mutation, you first call `useDeleteStudentSongFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentSongFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentSongFavoriteMutation, { data, loading, error }] = useDeleteStudentSongFavoriteMutation({
 *   variables: {
 *      songId: // value for 'songId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useDeleteStudentSongFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStudentSongFavoriteMutation, Types.DeleteStudentSongFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStudentSongFavoriteMutation, Types.DeleteStudentSongFavoriteMutationVariables>(DeleteStudentSongFavoriteDocument, options);
      }
export type DeleteStudentSongFavoriteMutationHookResult = ReturnType<typeof useDeleteStudentSongFavoriteMutation>;
export type DeleteStudentSongFavoriteMutationResult = Apollo.MutationResult<Types.DeleteStudentSongFavoriteMutation>;
export type DeleteStudentSongFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStudentSongFavoriteMutation, Types.DeleteStudentSongFavoriteMutationVariables>;
export const InsertStudentSongFavoriteDocument = gql`
    mutation insertStudentSongFavorite($songId: Int, $studentId: Int) {
  insert_student_song_favorite(
    objects: {song_id: $songId, student_id: $studentId}
  ) {
    returning {
      student_id
      song {
        teacher_id
        song_id
        is_student_favorite(args: {studentid: $studentId})
      }
    }
  }
}
    `;
export type InsertStudentSongFavoriteMutationFn = Apollo.MutationFunction<Types.InsertStudentSongFavoriteMutation, Types.InsertStudentSongFavoriteMutationVariables>;

/**
 * __useInsertStudentSongFavoriteMutation__
 *
 * To run a mutation, you first call `useInsertStudentSongFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentSongFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentSongFavoriteMutation, { data, loading, error }] = useInsertStudentSongFavoriteMutation({
 *   variables: {
 *      songId: // value for 'songId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useInsertStudentSongFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentSongFavoriteMutation, Types.InsertStudentSongFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentSongFavoriteMutation, Types.InsertStudentSongFavoriteMutationVariables>(InsertStudentSongFavoriteDocument, options);
      }
export type InsertStudentSongFavoriteMutationHookResult = ReturnType<typeof useInsertStudentSongFavoriteMutation>;
export type InsertStudentSongFavoriteMutationResult = Apollo.MutationResult<Types.InsertStudentSongFavoriteMutation>;
export type InsertStudentSongFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentSongFavoriteMutation, Types.InsertStudentSongFavoriteMutationVariables>;
export const UpdateStudentAvatarDocument = gql`
    mutation UpdateStudentAvatar($imagePath: String, $studentId: Int!) {
  update_student_by_pk(
    pk_columns: {student_id: $studentId}
    _set: {avatar_selected: true, image_path: $imagePath}
  ) {
    student_id
  }
}
    `;
export type UpdateStudentAvatarMutationFn = Apollo.MutationFunction<Types.UpdateStudentAvatarMutation, Types.UpdateStudentAvatarMutationVariables>;

/**
 * __useUpdateStudentAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateStudentAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentAvatarMutation, { data, loading, error }] = useUpdateStudentAvatarMutation({
 *   variables: {
 *      imagePath: // value for 'imagePath'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useUpdateStudentAvatarMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudentAvatarMutation, Types.UpdateStudentAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudentAvatarMutation, Types.UpdateStudentAvatarMutationVariables>(UpdateStudentAvatarDocument, options);
      }
export type UpdateStudentAvatarMutationHookResult = ReturnType<typeof useUpdateStudentAvatarMutation>;
export type UpdateStudentAvatarMutationResult = Apollo.MutationResult<Types.UpdateStudentAvatarMutation>;
export type UpdateStudentAvatarMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudentAvatarMutation, Types.UpdateStudentAvatarMutationVariables>;
export const GetStudentByEmailAndPasswordDocument = gql`
    query GetStudentByEmailAndPassword($email: String!, $password: String!) {
  student(where: {email: {_eq: $email}, password: {_eq: $password}}) {
    email
    student_id
    first_name
    last_name
  }
}
    `;

/**
 * __useGetStudentByEmailAndPasswordQuery__
 *
 * To run a query within a React component, call `useGetStudentByEmailAndPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentByEmailAndPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentByEmailAndPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetStudentByEmailAndPasswordQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentByEmailAndPasswordQuery, Types.GetStudentByEmailAndPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentByEmailAndPasswordQuery, Types.GetStudentByEmailAndPasswordQueryVariables>(GetStudentByEmailAndPasswordDocument, options);
      }
export function useGetStudentByEmailAndPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentByEmailAndPasswordQuery, Types.GetStudentByEmailAndPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentByEmailAndPasswordQuery, Types.GetStudentByEmailAndPasswordQueryVariables>(GetStudentByEmailAndPasswordDocument, options);
        }
export type GetStudentByEmailAndPasswordQueryHookResult = ReturnType<typeof useGetStudentByEmailAndPasswordQuery>;
export type GetStudentByEmailAndPasswordLazyQueryHookResult = ReturnType<typeof useGetStudentByEmailAndPasswordLazyQuery>;
export type GetStudentByEmailAndPasswordQueryResult = Apollo.QueryResult<Types.GetStudentByEmailAndPasswordQuery, Types.GetStudentByEmailAndPasswordQueryVariables>;
export function refetchGetStudentByEmailAndPasswordQuery(variables?: Types.GetStudentByEmailAndPasswordQueryVariables) {
      return { query: GetStudentByEmailAndPasswordDocument, variables: variables }
    }
export const GetStudentByPkDocument = gql`
    query GetStudentByPK($studentId: Int!) {
  student_by_pk(student_id: $studentId) {
    student_setting {
      dont_ask_again_record
      favorites_dont_ask_again_to_delete
      student_id
      student_setting_id
    }
    student_integrations {
      integration_name
    }
    ...StudentReducedFragment
  }
}
    ${StudentReducedFragmentFragmentDoc}`;

/**
 * __useGetStudentByPkQuery__
 *
 * To run a query within a React component, call `useGetStudentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentByPkQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentByPkQuery, Types.GetStudentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentByPkQuery, Types.GetStudentByPkQueryVariables>(GetStudentByPkDocument, options);
      }
export function useGetStudentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentByPkQuery, Types.GetStudentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentByPkQuery, Types.GetStudentByPkQueryVariables>(GetStudentByPkDocument, options);
        }
export type GetStudentByPkQueryHookResult = ReturnType<typeof useGetStudentByPkQuery>;
export type GetStudentByPkLazyQueryHookResult = ReturnType<typeof useGetStudentByPkLazyQuery>;
export type GetStudentByPkQueryResult = Apollo.QueryResult<Types.GetStudentByPkQuery, Types.GetStudentByPkQueryVariables>;
export function refetchGetStudentByPkQuery(variables?: Types.GetStudentByPkQueryVariables) {
      return { query: GetStudentByPkDocument, variables: variables }
    }
export const GetAppcuesDataDocument = gql`
    query GetAppcuesData($studentId: Int!) {
  class_student(
    where: {student_id: {_eq: $studentId}}
    order_by: {created_at: asc}
    limit: 1
  ) {
    class {
      title
      grade {
        name
      }
      class_teachers {
        teacher {
          name_first
          name_last
          organization_name
          role_name
          school {
            name
            nces_id
            district {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAppcuesDataQuery__
 *
 * To run a query within a React component, call `useGetAppcuesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppcuesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppcuesDataQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetAppcuesDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAppcuesDataQuery, Types.GetAppcuesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAppcuesDataQuery, Types.GetAppcuesDataQueryVariables>(GetAppcuesDataDocument, options);
      }
export function useGetAppcuesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAppcuesDataQuery, Types.GetAppcuesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAppcuesDataQuery, Types.GetAppcuesDataQueryVariables>(GetAppcuesDataDocument, options);
        }
export type GetAppcuesDataQueryHookResult = ReturnType<typeof useGetAppcuesDataQuery>;
export type GetAppcuesDataLazyQueryHookResult = ReturnType<typeof useGetAppcuesDataLazyQuery>;
export type GetAppcuesDataQueryResult = Apollo.QueryResult<Types.GetAppcuesDataQuery, Types.GetAppcuesDataQueryVariables>;
export function refetchGetAppcuesDataQuery(variables?: Types.GetAppcuesDataQueryVariables) {
      return { query: GetAppcuesDataDocument, variables: variables }
    }
export const GetStudentLoginAttemptFailedEmailDocument = gql`
    query GetStudentLoginAttemptFailedEmail($code: String!) {
  studentLoginAttemptFailedEmail(filter: {code: $code})
}
    `;

/**
 * __useGetStudentLoginAttemptFailedEmailQuery__
 *
 * To run a query within a React component, call `useGetStudentLoginAttemptFailedEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentLoginAttemptFailedEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentLoginAttemptFailedEmailQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetStudentLoginAttemptFailedEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentLoginAttemptFailedEmailQuery, Types.GetStudentLoginAttemptFailedEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentLoginAttemptFailedEmailQuery, Types.GetStudentLoginAttemptFailedEmailQueryVariables>(GetStudentLoginAttemptFailedEmailDocument, options);
      }
export function useGetStudentLoginAttemptFailedEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentLoginAttemptFailedEmailQuery, Types.GetStudentLoginAttemptFailedEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentLoginAttemptFailedEmailQuery, Types.GetStudentLoginAttemptFailedEmailQueryVariables>(GetStudentLoginAttemptFailedEmailDocument, options);
        }
export type GetStudentLoginAttemptFailedEmailQueryHookResult = ReturnType<typeof useGetStudentLoginAttemptFailedEmailQuery>;
export type GetStudentLoginAttemptFailedEmailLazyQueryHookResult = ReturnType<typeof useGetStudentLoginAttemptFailedEmailLazyQuery>;
export type GetStudentLoginAttemptFailedEmailQueryResult = Apollo.QueryResult<Types.GetStudentLoginAttemptFailedEmailQuery, Types.GetStudentLoginAttemptFailedEmailQueryVariables>;
export function refetchGetStudentLoginAttemptFailedEmailQuery(variables?: Types.GetStudentLoginAttemptFailedEmailQueryVariables) {
      return { query: GetStudentLoginAttemptFailedEmailDocument, variables: variables }
    }
export const StudentActivityProgressDocument = gql`
    query StudentActivityProgress($studentId: Int!, $date: timestamptz, $studentActType: student_activity_type_enum = has_been_practicing) {
  student_activity_aggregate(
    where: {student_id: {_eq: $studentId}, created_at: {_gte: $date}, student_activity_type: {_eq: $studentActType}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useStudentActivityProgressQuery__
 *
 * To run a query within a React component, call `useStudentActivityProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentActivityProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentActivityProgressQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      date: // value for 'date'
 *      studentActType: // value for 'studentActType'
 *   },
 * });
 */
export function useStudentActivityProgressQuery(baseOptions: Apollo.QueryHookOptions<Types.StudentActivityProgressQuery, Types.StudentActivityProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentActivityProgressQuery, Types.StudentActivityProgressQueryVariables>(StudentActivityProgressDocument, options);
      }
export function useStudentActivityProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentActivityProgressQuery, Types.StudentActivityProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentActivityProgressQuery, Types.StudentActivityProgressQueryVariables>(StudentActivityProgressDocument, options);
        }
export type StudentActivityProgressQueryHookResult = ReturnType<typeof useStudentActivityProgressQuery>;
export type StudentActivityProgressLazyQueryHookResult = ReturnType<typeof useStudentActivityProgressLazyQuery>;
export type StudentActivityProgressQueryResult = Apollo.QueryResult<Types.StudentActivityProgressQuery, Types.StudentActivityProgressQueryVariables>;
export function refetchStudentActivityProgressQuery(variables?: Types.StudentActivityProgressQueryVariables) {
      return { query: StudentActivityProgressDocument, variables: variables }
    }
export const InsertStudentBadgeEventDocument = gql`
    mutation InsertStudentBadgeEvent($studentId: Int, $eventName: badge_event_name_enum, $songId: Int, $classId: Int, $challengeId: Int) {
  insert_student_badge_event(
    objects: {student_id: $studentId, event_name: $eventName, song_id: $songId, class_id: $classId, challenge_id: $challengeId}
  ) {
    affected_rows
  }
}
    `;
export type InsertStudentBadgeEventMutationFn = Apollo.MutationFunction<Types.InsertStudentBadgeEventMutation, Types.InsertStudentBadgeEventMutationVariables>;

/**
 * __useInsertStudentBadgeEventMutation__
 *
 * To run a mutation, you first call `useInsertStudentBadgeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentBadgeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentBadgeEventMutation, { data, loading, error }] = useInsertStudentBadgeEventMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      eventName: // value for 'eventName'
 *      songId: // value for 'songId'
 *      classId: // value for 'classId'
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useInsertStudentBadgeEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentBadgeEventMutation, Types.InsertStudentBadgeEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentBadgeEventMutation, Types.InsertStudentBadgeEventMutationVariables>(InsertStudentBadgeEventDocument, options);
      }
export type InsertStudentBadgeEventMutationHookResult = ReturnType<typeof useInsertStudentBadgeEventMutation>;
export type InsertStudentBadgeEventMutationResult = Apollo.MutationResult<Types.InsertStudentBadgeEventMutation>;
export type InsertStudentBadgeEventMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentBadgeEventMutation, Types.InsertStudentBadgeEventMutationVariables>;
export const GetPracticeEventDocument = gql`
    query GetPracticeEvent($date: timestamptz, $studentId: Int) {
  student_badge_event_aggregate(
    where: {created_at: {_gte: $date}, student_id: {_eq: $studentId}, event_name: {_eq: practice_goal}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPracticeEventQuery__
 *
 * To run a query within a React component, call `useGetPracticeEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeEventQuery({
 *   variables: {
 *      date: // value for 'date'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetPracticeEventQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPracticeEventQuery, Types.GetPracticeEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPracticeEventQuery, Types.GetPracticeEventQueryVariables>(GetPracticeEventDocument, options);
      }
export function useGetPracticeEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPracticeEventQuery, Types.GetPracticeEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPracticeEventQuery, Types.GetPracticeEventQueryVariables>(GetPracticeEventDocument, options);
        }
export type GetPracticeEventQueryHookResult = ReturnType<typeof useGetPracticeEventQuery>;
export type GetPracticeEventLazyQueryHookResult = ReturnType<typeof useGetPracticeEventLazyQuery>;
export type GetPracticeEventQueryResult = Apollo.QueryResult<Types.GetPracticeEventQuery, Types.GetPracticeEventQueryVariables>;
export function refetchGetPracticeEventQuery(variables?: Types.GetPracticeEventQueryVariables) {
      return { query: GetPracticeEventDocument, variables: variables }
    }
export const GetStudentBannersDocument = gql`
    query GetStudentBanners {
  student_banner {
    student_banner_id
    image_path
  }
}
    `;

/**
 * __useGetStudentBannersQuery__
 *
 * To run a query within a React component, call `useGetStudentBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentBannersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStudentBannersQuery, Types.GetStudentBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentBannersQuery, Types.GetStudentBannersQueryVariables>(GetStudentBannersDocument, options);
      }
export function useGetStudentBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentBannersQuery, Types.GetStudentBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentBannersQuery, Types.GetStudentBannersQueryVariables>(GetStudentBannersDocument, options);
        }
export type GetStudentBannersQueryHookResult = ReturnType<typeof useGetStudentBannersQuery>;
export type GetStudentBannersLazyQueryHookResult = ReturnType<typeof useGetStudentBannersLazyQuery>;
export type GetStudentBannersQueryResult = Apollo.QueryResult<Types.GetStudentBannersQuery, Types.GetStudentBannersQueryVariables>;
export function refetchGetStudentBannersQuery(variables?: Types.GetStudentBannersQueryVariables) {
      return { query: GetStudentBannersDocument, variables: variables }
    }
export const SetGoalDocument = gql`
    mutation SetGoal($studentId: Int!, $weeklyGoal: Int!) {
  insert_student_goal(objects: {student_id: $studentId, weekly_goal: $weeklyGoal}) {
    returning {
      weekly_goal
    }
  }
}
    `;
export type SetGoalMutationFn = Apollo.MutationFunction<Types.SetGoalMutation, Types.SetGoalMutationVariables>;

/**
 * __useSetGoalMutation__
 *
 * To run a mutation, you first call `useSetGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGoalMutation, { data, loading, error }] = useSetGoalMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      weeklyGoal: // value for 'weeklyGoal'
 *   },
 * });
 */
export function useSetGoalMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetGoalMutation, Types.SetGoalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetGoalMutation, Types.SetGoalMutationVariables>(SetGoalDocument, options);
      }
export type SetGoalMutationHookResult = ReturnType<typeof useSetGoalMutation>;
export type SetGoalMutationResult = Apollo.MutationResult<Types.SetGoalMutation>;
export type SetGoalMutationOptions = Apollo.BaseMutationOptions<Types.SetGoalMutation, Types.SetGoalMutationVariables>;
export const StudentGoalDocument = gql`
    query StudentGoal($studentId: Int) {
  student_goal(
    where: {student_id: {_eq: $studentId}}
    order_by: {student_goal_id: desc}
    offset: 0
    limit: 1
  ) {
    weekly_goal
  }
}
    `;

/**
 * __useStudentGoalQuery__
 *
 * To run a query within a React component, call `useStudentGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentGoalQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useStudentGoalQuery(baseOptions?: Apollo.QueryHookOptions<Types.StudentGoalQuery, Types.StudentGoalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentGoalQuery, Types.StudentGoalQueryVariables>(StudentGoalDocument, options);
      }
export function useStudentGoalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentGoalQuery, Types.StudentGoalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentGoalQuery, Types.StudentGoalQueryVariables>(StudentGoalDocument, options);
        }
export type StudentGoalQueryHookResult = ReturnType<typeof useStudentGoalQuery>;
export type StudentGoalLazyQueryHookResult = ReturnType<typeof useStudentGoalLazyQuery>;
export type StudentGoalQueryResult = Apollo.QueryResult<Types.StudentGoalQuery, Types.StudentGoalQueryVariables>;
export function refetchStudentGoalQuery(variables?: Types.StudentGoalQueryVariables) {
      return { query: StudentGoalDocument, variables: variables }
    }
export const InsertStudentDontAskAgainRecordDocument = gql`
    mutation InsertStudentDontAskAgainRecord($studentId: Int!) {
  insert_student_setting(
    objects: {dont_ask_again_record: true, student_id: $studentId}
  ) {
    returning {
      ...StudentSettingFragment
    }
  }
}
    ${StudentSettingFragmentFragmentDoc}`;
export type InsertStudentDontAskAgainRecordMutationFn = Apollo.MutationFunction<Types.InsertStudentDontAskAgainRecordMutation, Types.InsertStudentDontAskAgainRecordMutationVariables>;

/**
 * __useInsertStudentDontAskAgainRecordMutation__
 *
 * To run a mutation, you first call `useInsertStudentDontAskAgainRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentDontAskAgainRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentDontAskAgainRecordMutation, { data, loading, error }] = useInsertStudentDontAskAgainRecordMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useInsertStudentDontAskAgainRecordMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentDontAskAgainRecordMutation, Types.InsertStudentDontAskAgainRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentDontAskAgainRecordMutation, Types.InsertStudentDontAskAgainRecordMutationVariables>(InsertStudentDontAskAgainRecordDocument, options);
      }
export type InsertStudentDontAskAgainRecordMutationHookResult = ReturnType<typeof useInsertStudentDontAskAgainRecordMutation>;
export type InsertStudentDontAskAgainRecordMutationResult = Apollo.MutationResult<Types.InsertStudentDontAskAgainRecordMutation>;
export type InsertStudentDontAskAgainRecordMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentDontAskAgainRecordMutation, Types.InsertStudentDontAskAgainRecordMutationVariables>;
export const UpdateStudentSettingDontAskAgainRecordDocument = gql`
    mutation UpdateStudentSettingDontAskAgainRecord($studentId: Int!) {
  update_student_setting(
    where: {student_id: {_eq: $studentId}}
    _set: {dont_ask_again_record: true}
  ) {
    returning {
      ...StudentSettingFragment
    }
  }
}
    ${StudentSettingFragmentFragmentDoc}`;
export type UpdateStudentSettingDontAskAgainRecordMutationFn = Apollo.MutationFunction<Types.UpdateStudentSettingDontAskAgainRecordMutation, Types.UpdateStudentSettingDontAskAgainRecordMutationVariables>;

/**
 * __useUpdateStudentSettingDontAskAgainRecordMutation__
 *
 * To run a mutation, you first call `useUpdateStudentSettingDontAskAgainRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentSettingDontAskAgainRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentSettingDontAskAgainRecordMutation, { data, loading, error }] = useUpdateStudentSettingDontAskAgainRecordMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useUpdateStudentSettingDontAskAgainRecordMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudentSettingDontAskAgainRecordMutation, Types.UpdateStudentSettingDontAskAgainRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudentSettingDontAskAgainRecordMutation, Types.UpdateStudentSettingDontAskAgainRecordMutationVariables>(UpdateStudentSettingDontAskAgainRecordDocument, options);
      }
export type UpdateStudentSettingDontAskAgainRecordMutationHookResult = ReturnType<typeof useUpdateStudentSettingDontAskAgainRecordMutation>;
export type UpdateStudentSettingDontAskAgainRecordMutationResult = Apollo.MutationResult<Types.UpdateStudentSettingDontAskAgainRecordMutation>;
export type UpdateStudentSettingDontAskAgainRecordMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudentSettingDontAskAgainRecordMutation, Types.UpdateStudentSettingDontAskAgainRecordMutationVariables>;
export const InsertStudentDontAskAgainRemoveFavoriteDocument = gql`
    mutation InsertStudentDontAskAgainRemoveFavorite($studentId: Int!, $dontAsk: Boolean) {
  insert_student_setting(
    objects: {favorites_dont_ask_again_to_delete: $dontAsk, student_id: $studentId}
  ) {
    returning {
      ...StudentSettingFragment
    }
  }
}
    ${StudentSettingFragmentFragmentDoc}`;
export type InsertStudentDontAskAgainRemoveFavoriteMutationFn = Apollo.MutationFunction<Types.InsertStudentDontAskAgainRemoveFavoriteMutation, Types.InsertStudentDontAskAgainRemoveFavoriteMutationVariables>;

/**
 * __useInsertStudentDontAskAgainRemoveFavoriteMutation__
 *
 * To run a mutation, you first call `useInsertStudentDontAskAgainRemoveFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentDontAskAgainRemoveFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentDontAskAgainRemoveFavoriteMutation, { data, loading, error }] = useInsertStudentDontAskAgainRemoveFavoriteMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      dontAsk: // value for 'dontAsk'
 *   },
 * });
 */
export function useInsertStudentDontAskAgainRemoveFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentDontAskAgainRemoveFavoriteMutation, Types.InsertStudentDontAskAgainRemoveFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentDontAskAgainRemoveFavoriteMutation, Types.InsertStudentDontAskAgainRemoveFavoriteMutationVariables>(InsertStudentDontAskAgainRemoveFavoriteDocument, options);
      }
export type InsertStudentDontAskAgainRemoveFavoriteMutationHookResult = ReturnType<typeof useInsertStudentDontAskAgainRemoveFavoriteMutation>;
export type InsertStudentDontAskAgainRemoveFavoriteMutationResult = Apollo.MutationResult<Types.InsertStudentDontAskAgainRemoveFavoriteMutation>;
export type InsertStudentDontAskAgainRemoveFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentDontAskAgainRemoveFavoriteMutation, Types.InsertStudentDontAskAgainRemoveFavoriteMutationVariables>;
export const UpdateStudentSettingDontAskAgainRemoveFavoriteDocument = gql`
    mutation UpdateStudentSettingDontAskAgainRemoveFavorite($studentId: Int!, $dontAsk: Boolean) {
  update_student_setting(
    where: {student_id: {_eq: $studentId}}
    _set: {favorites_dont_ask_again_to_delete: $dontAsk}
  ) {
    returning {
      ...StudentSettingFragment
    }
  }
}
    ${StudentSettingFragmentFragmentDoc}`;
export type UpdateStudentSettingDontAskAgainRemoveFavoriteMutationFn = Apollo.MutationFunction<Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutation, Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutationVariables>;

/**
 * __useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation__
 *
 * To run a mutation, you first call `useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentSettingDontAskAgainRemoveFavoriteMutation, { data, loading, error }] = useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      dontAsk: // value for 'dontAsk'
 *   },
 * });
 */
export function useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutation, Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutation, Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutationVariables>(UpdateStudentSettingDontAskAgainRemoveFavoriteDocument, options);
      }
export type UpdateStudentSettingDontAskAgainRemoveFavoriteMutationHookResult = ReturnType<typeof useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation>;
export type UpdateStudentSettingDontAskAgainRemoveFavoriteMutationResult = Apollo.MutationResult<Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutation>;
export type UpdateStudentSettingDontAskAgainRemoveFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutation, Types.UpdateStudentSettingDontAskAgainRemoveFavoriteMutationVariables>;
export const GetStudentSettingByPkDocument = gql`
    query GetStudentSettingByPk($studentId: Int!) {
  student_setting(where: {student_id: {_eq: $studentId}}) {
    student_id
    dont_ask_again_record
    favorites_dont_ask_again_to_delete
  }
}
    `;

/**
 * __useGetStudentSettingByPkQuery__
 *
 * To run a query within a React component, call `useGetStudentSettingByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSettingByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSettingByPkQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentSettingByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentSettingByPkQuery, Types.GetStudentSettingByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentSettingByPkQuery, Types.GetStudentSettingByPkQueryVariables>(GetStudentSettingByPkDocument, options);
      }
export function useGetStudentSettingByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentSettingByPkQuery, Types.GetStudentSettingByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentSettingByPkQuery, Types.GetStudentSettingByPkQueryVariables>(GetStudentSettingByPkDocument, options);
        }
export type GetStudentSettingByPkQueryHookResult = ReturnType<typeof useGetStudentSettingByPkQuery>;
export type GetStudentSettingByPkLazyQueryHookResult = ReturnType<typeof useGetStudentSettingByPkLazyQuery>;
export type GetStudentSettingByPkQueryResult = Apollo.QueryResult<Types.GetStudentSettingByPkQuery, Types.GetStudentSettingByPkQueryVariables>;
export function refetchGetStudentSettingByPkQuery(variables?: Types.GetStudentSettingByPkQueryVariables) {
      return { query: GetStudentSettingByPkDocument, variables: variables }
    }
export const GetSubmittedAssignmentsDocument = gql`
    query GetSubmittedAssignments($classId: Int!, $studentId: Int!, $limit: Int, $offset: Int, $orderAlph: order_by = asc, $orderDate: order_by, $toDate: date, $fromDate: date) {
  submission(
    where: {submission_students: {student_id: {_eq: $studentId}}, state: {_in: [submitted, graded]}, class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}}
    limit: $limit
    offset: $offset
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    grade
    state
    submission_id
    submission_students_aggregate {
      aggregate {
        count
      }
    }
    class_assignment {
      class_id
      status
      assignment {
        name
        instructions
        due_date
        due_time
      }
    }
    submission_wurrlies {
      wurrly_id
    }
  }
  submission_aggregate(
    where: {submission_students: {student_id: {_eq: $studentId}}, state: {_in: [submitted, graded]}, class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}}
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSubmittedAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetSubmittedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmittedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmittedAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderAlph: // value for 'orderAlph'
 *      orderDate: // value for 'orderDate'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetSubmittedAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSubmittedAssignmentsQuery, Types.GetSubmittedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubmittedAssignmentsQuery, Types.GetSubmittedAssignmentsQueryVariables>(GetSubmittedAssignmentsDocument, options);
      }
export function useGetSubmittedAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubmittedAssignmentsQuery, Types.GetSubmittedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubmittedAssignmentsQuery, Types.GetSubmittedAssignmentsQueryVariables>(GetSubmittedAssignmentsDocument, options);
        }
export type GetSubmittedAssignmentsQueryHookResult = ReturnType<typeof useGetSubmittedAssignmentsQuery>;
export type GetSubmittedAssignmentsLazyQueryHookResult = ReturnType<typeof useGetSubmittedAssignmentsLazyQuery>;
export type GetSubmittedAssignmentsQueryResult = Apollo.QueryResult<Types.GetSubmittedAssignmentsQuery, Types.GetSubmittedAssignmentsQueryVariables>;
export function refetchGetSubmittedAssignmentsQuery(variables?: Types.GetSubmittedAssignmentsQueryVariables) {
      return { query: GetSubmittedAssignmentsDocument, variables: variables }
    }
export const GetNotSubmittedAssignmentsDocument = gql`
    query GetNotSubmittedAssignments($classId: Int!, $studentId: Int!, $limit: Int, $offset: Int, $orderAlph: order_by = asc, $orderDate: order_by, $toDate: date, $fromDate: date) {
  submission(
    where: {submission_students: {student_id: {_eq: $studentId}}, state: {_in: [start, in_progress]}, class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_lte: $toDate, _gte: "now()"}, _and: {due_date: {_gte: $fromDate}}}}}
    limit: $limit
    offset: $offset
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    grade
    state
    submission_id
    submission_students_aggregate {
      aggregate {
        count
      }
    }
    class_assignment {
      status
      assignment {
        name
        instructions
        due_date
        due_time
      }
    }
  }
  submission_aggregate(
    where: {submission_students: {student_id: {_eq: $studentId}}, state: {_in: [start, in_progress]}, class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_lte: $toDate, _gte: "now()"}, _and: {due_date: {_gte: $fromDate}}}}}
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetNotSubmittedAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetNotSubmittedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotSubmittedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotSubmittedAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderAlph: // value for 'orderAlph'
 *      orderDate: // value for 'orderDate'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetNotSubmittedAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNotSubmittedAssignmentsQuery, Types.GetNotSubmittedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNotSubmittedAssignmentsQuery, Types.GetNotSubmittedAssignmentsQueryVariables>(GetNotSubmittedAssignmentsDocument, options);
      }
export function useGetNotSubmittedAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotSubmittedAssignmentsQuery, Types.GetNotSubmittedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNotSubmittedAssignmentsQuery, Types.GetNotSubmittedAssignmentsQueryVariables>(GetNotSubmittedAssignmentsDocument, options);
        }
export type GetNotSubmittedAssignmentsQueryHookResult = ReturnType<typeof useGetNotSubmittedAssignmentsQuery>;
export type GetNotSubmittedAssignmentsLazyQueryHookResult = ReturnType<typeof useGetNotSubmittedAssignmentsLazyQuery>;
export type GetNotSubmittedAssignmentsQueryResult = Apollo.QueryResult<Types.GetNotSubmittedAssignmentsQuery, Types.GetNotSubmittedAssignmentsQueryVariables>;
export function refetchGetNotSubmittedAssignmentsQuery(variables?: Types.GetNotSubmittedAssignmentsQueryVariables) {
      return { query: GetNotSubmittedAssignmentsDocument, variables: variables }
    }
export const GetExpiredAssignmentsDocument = gql`
    query GetExpiredAssignments($classId: Int!, $studentId: Int!, $limit: Int, $offset: Int, $orderAlph: order_by = asc, $orderDate: order_by, $fromDate: date) {
  submission(
    where: {submission_students: {student_id: {_eq: $studentId}}, state: {_in: [start, in_progress]}, class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_lte: "now()"}, _and: {due_date: {_gte: $fromDate}}}}}
    limit: $limit
    offset: $offset
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    grade
    state
    submission_id
    submission_students_aggregate {
      aggregate {
        count
        __typename
      }
      __typename
    }
    class_assignment {
      status
      assignment {
        name
        instructions
        due_date
        due_time
        __typename
      }
      __typename
    }
    __typename
  }
  submission_aggregate(
    where: {submission_students: {student_id: {_eq: $studentId}}, state: {_in: [start, in_progress]}, class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_lte: "now()"}, _and: {due_date: {_gte: $fromDate}}}}}
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    aggregate {
      count
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useGetExpiredAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetExpiredAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpiredAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpiredAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderAlph: // value for 'orderAlph'
 *      orderDate: // value for 'orderDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetExpiredAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExpiredAssignmentsQuery, Types.GetExpiredAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExpiredAssignmentsQuery, Types.GetExpiredAssignmentsQueryVariables>(GetExpiredAssignmentsDocument, options);
      }
export function useGetExpiredAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExpiredAssignmentsQuery, Types.GetExpiredAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExpiredAssignmentsQuery, Types.GetExpiredAssignmentsQueryVariables>(GetExpiredAssignmentsDocument, options);
        }
export type GetExpiredAssignmentsQueryHookResult = ReturnType<typeof useGetExpiredAssignmentsQuery>;
export type GetExpiredAssignmentsLazyQueryHookResult = ReturnType<typeof useGetExpiredAssignmentsLazyQuery>;
export type GetExpiredAssignmentsQueryResult = Apollo.QueryResult<Types.GetExpiredAssignmentsQuery, Types.GetExpiredAssignmentsQueryVariables>;
export function refetchGetExpiredAssignmentsQuery(variables?: Types.GetExpiredAssignmentsQueryVariables) {
      return { query: GetExpiredAssignmentsDocument, variables: variables }
    }
export const GetAssignmentsDocument = gql`
    query GetAssignments($studentId: Int!, $classId: Int, $limit: Int, $offset: Int, $orderAlph: order_by = asc, $orderDate: order_by, $toDate: date, $fromDate: date) {
  submission(
    where: {submission_students: {student_id: {_eq: $studentId}}, class_assignment: {class: {class_id: {_eq: $classId}}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}}
    limit: $limit
    offset: $offset
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    grade
    state
    submission_id
    submission_students_aggregate {
      aggregate {
        count
      }
    }
    class_assignment {
      class_id
      status
      class {
        title
      }
      assignment {
        name
        instructions
        due_date
        due_time
      }
    }
    submission_wurrlies {
      wurrly_id
    }
  }
  submission_aggregate(
    where: {submission_students: {student_id: {_eq: $studentId}}, class_assignment: {class: {class_id: {_eq: $classId}}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}}
    limit: $limit
    offset: $offset
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    aggregate {
      count
    }
  }
  total: submission_aggregate(
    where: {submission_students: {student_id: {_eq: $studentId}}, class_assignment: {class: {class_id: {_eq: $classId}}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}}
    order_by: {class_assignment: {assignment: {name: $orderAlph, due_date: $orderDate}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderAlph: // value for 'orderAlph'
 *      orderDate: // value for 'orderDate'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssignmentsQuery, Types.GetAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssignmentsQuery, Types.GetAssignmentsQueryVariables>(GetAssignmentsDocument, options);
      }
export function useGetAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssignmentsQuery, Types.GetAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssignmentsQuery, Types.GetAssignmentsQueryVariables>(GetAssignmentsDocument, options);
        }
export type GetAssignmentsQueryHookResult = ReturnType<typeof useGetAssignmentsQuery>;
export type GetAssignmentsLazyQueryHookResult = ReturnType<typeof useGetAssignmentsLazyQuery>;
export type GetAssignmentsQueryResult = Apollo.QueryResult<Types.GetAssignmentsQuery, Types.GetAssignmentsQueryVariables>;
export function refetchGetAssignmentsQuery(variables?: Types.GetAssignmentsQueryVariables) {
      return { query: GetAssignmentsDocument, variables: variables }
    }
export const GetTipsByStudentClassDocument = gql`
    query GetTipsByStudentClass($classId: Int!, $orderTitle: order_by = asc, $createdAt: order_by, $limit: Int) {
  tip(
    limit: $limit
    order_by: {title: $orderTitle, created_at: $createdAt}
    where: {_or: [{class_tips: {class_id: {_eq: $classId}}}, {tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}]}
  ) {
    tip_id
    title
    description
    resource_path
    image_path
    featured
    asset_path
    tip_catalog_item {
      tip_catalog_item_id
      catalog_item {
        catalog_item_id
        catalog_id
        name
        catalog {
          catalog_id
          name
        }
      }
    }
  }
  tip_aggregate(
    where: {_or: [{class_tips: {class_id: {_eq: $classId}}}, {tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTipsByStudentClassQuery__
 *
 * To run a query within a React component, call `useGetTipsByStudentClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipsByStudentClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipsByStudentClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      orderTitle: // value for 'orderTitle'
 *      createdAt: // value for 'createdAt'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTipsByStudentClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipsByStudentClassQuery, Types.GetTipsByStudentClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipsByStudentClassQuery, Types.GetTipsByStudentClassQueryVariables>(GetTipsByStudentClassDocument, options);
      }
export function useGetTipsByStudentClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipsByStudentClassQuery, Types.GetTipsByStudentClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipsByStudentClassQuery, Types.GetTipsByStudentClassQueryVariables>(GetTipsByStudentClassDocument, options);
        }
export type GetTipsByStudentClassQueryHookResult = ReturnType<typeof useGetTipsByStudentClassQuery>;
export type GetTipsByStudentClassLazyQueryHookResult = ReturnType<typeof useGetTipsByStudentClassLazyQuery>;
export type GetTipsByStudentClassQueryResult = Apollo.QueryResult<Types.GetTipsByStudentClassQuery, Types.GetTipsByStudentClassQueryVariables>;
export function refetchGetTipsByStudentClassQuery(variables?: Types.GetTipsByStudentClassQueryVariables) {
      return { query: GetTipsByStudentClassDocument, variables: variables }
    }
export const GetImagePathDocument = gql`
    query GetImagePath($resourcePath: String!) {
  imagePath(filter: {resource_path: $resourcePath}) {
    image_path
    resource_path
  }
}
    `;

/**
 * __useGetImagePathQuery__
 *
 * To run a query within a React component, call `useGetImagePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImagePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImagePathQuery({
 *   variables: {
 *      resourcePath: // value for 'resourcePath'
 *   },
 * });
 */
export function useGetImagePathQuery(baseOptions: Apollo.QueryHookOptions<Types.GetImagePathQuery, Types.GetImagePathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetImagePathQuery, Types.GetImagePathQueryVariables>(GetImagePathDocument, options);
      }
export function useGetImagePathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetImagePathQuery, Types.GetImagePathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetImagePathQuery, Types.GetImagePathQueryVariables>(GetImagePathDocument, options);
        }
export type GetImagePathQueryHookResult = ReturnType<typeof useGetImagePathQuery>;
export type GetImagePathLazyQueryHookResult = ReturnType<typeof useGetImagePathLazyQuery>;
export type GetImagePathQueryResult = Apollo.QueryResult<Types.GetImagePathQuery, Types.GetImagePathQueryVariables>;
export function refetchGetImagePathQuery(variables?: Types.GetImagePathQueryVariables) {
      return { query: GetImagePathDocument, variables: variables }
    }
export const UpdateWurrlyMessageAndVisibilityDocument = gql`
    mutation UpdateWurrlyMessageAndVisibility($wurrlyId: Int!, $message: String!, $privacyId: privacy_enum!) {
  update_wurrly_by_pk(
    pk_columns: {wurrly_id: $wurrlyId}
    _set: {message: $message, privacy: $privacyId}
  ) {
    wurrly_id
  }
}
    `;
export type UpdateWurrlyMessageAndVisibilityMutationFn = Apollo.MutationFunction<Types.UpdateWurrlyMessageAndVisibilityMutation, Types.UpdateWurrlyMessageAndVisibilityMutationVariables>;

/**
 * __useUpdateWurrlyMessageAndVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateWurrlyMessageAndVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWurrlyMessageAndVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWurrlyMessageAndVisibilityMutation, { data, loading, error }] = useUpdateWurrlyMessageAndVisibilityMutation({
 *   variables: {
 *      wurrlyId: // value for 'wurrlyId'
 *      message: // value for 'message'
 *      privacyId: // value for 'privacyId'
 *   },
 * });
 */
export function useUpdateWurrlyMessageAndVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWurrlyMessageAndVisibilityMutation, Types.UpdateWurrlyMessageAndVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWurrlyMessageAndVisibilityMutation, Types.UpdateWurrlyMessageAndVisibilityMutationVariables>(UpdateWurrlyMessageAndVisibilityDocument, options);
      }
export type UpdateWurrlyMessageAndVisibilityMutationHookResult = ReturnType<typeof useUpdateWurrlyMessageAndVisibilityMutation>;
export type UpdateWurrlyMessageAndVisibilityMutationResult = Apollo.MutationResult<Types.UpdateWurrlyMessageAndVisibilityMutation>;
export type UpdateWurrlyMessageAndVisibilityMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWurrlyMessageAndVisibilityMutation, Types.UpdateWurrlyMessageAndVisibilityMutationVariables>;
export const CustomCreateWurrlyDocument = gql`
    mutation customCreateWurrly($studentIdOwner: Int!, $studentIdSecondary: Int!, $trackId: Int!, $privacyId: String!, $resourcePath: String!, $imagePath: String!, $classId: Int!, $submission: CustomCreateWurrlySubmissionObject, $message: String = "", $wurrlyType: String!, $audioUrl: String!, $challengeId: Int, $audioBalance: Int = 0, $isMirrored: Boolean = false, $filters: CustomCreateWurrlyFilterOptions) {
  customCreateWurrly(
    set: {studentIdOwner: $studentIdOwner, studentIdSecondary: $studentIdSecondary, trackId: $trackId, privacy: $privacyId, resourcePath: $resourcePath, imagePath: $imagePath, classId: $classId, submission: $submission, message: $message, wurrlyType: $wurrlyType, audioUrl: $audioUrl, audioBalance: $audioBalance, isMirrored: $isMirrored, filters: $filters, challengeId: $challengeId}
  ) {
    wurrlyId
  }
}
    `;
export type CustomCreateWurrlyMutationFn = Apollo.MutationFunction<Types.CustomCreateWurrlyMutation, Types.CustomCreateWurrlyMutationVariables>;

/**
 * __useCustomCreateWurrlyMutation__
 *
 * To run a mutation, you first call `useCustomCreateWurrlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomCreateWurrlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customCreateWurrlyMutation, { data, loading, error }] = useCustomCreateWurrlyMutation({
 *   variables: {
 *      studentIdOwner: // value for 'studentIdOwner'
 *      studentIdSecondary: // value for 'studentIdSecondary'
 *      trackId: // value for 'trackId'
 *      privacyId: // value for 'privacyId'
 *      resourcePath: // value for 'resourcePath'
 *      imagePath: // value for 'imagePath'
 *      classId: // value for 'classId'
 *      submission: // value for 'submission'
 *      message: // value for 'message'
 *      wurrlyType: // value for 'wurrlyType'
 *      audioUrl: // value for 'audioUrl'
 *      challengeId: // value for 'challengeId'
 *      audioBalance: // value for 'audioBalance'
 *      isMirrored: // value for 'isMirrored'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomCreateWurrlyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomCreateWurrlyMutation, Types.CustomCreateWurrlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomCreateWurrlyMutation, Types.CustomCreateWurrlyMutationVariables>(CustomCreateWurrlyDocument, options);
      }
export type CustomCreateWurrlyMutationHookResult = ReturnType<typeof useCustomCreateWurrlyMutation>;
export type CustomCreateWurrlyMutationResult = Apollo.MutationResult<Types.CustomCreateWurrlyMutation>;
export type CustomCreateWurrlyMutationOptions = Apollo.BaseMutationOptions<Types.CustomCreateWurrlyMutation, Types.CustomCreateWurrlyMutationVariables>;
export const GetWurrliesByStudentClassesDocument = gql`
    query GetWurrliesByStudentClasses($studentId: Int!, $privacySettings: [privacy_enum!]!, $limit: Int, $offset: Int, $order: order_by = asc) {
  wurrly(
    where: {class: {class_students: {student_id: {_eq: $studentId}}}, privacy: {_in: $privacySettings}, _and: {student_id_owner: {_neq: $studentId}}}
    limit: $limit
    offset: $offset
    order_by: {track: {song: {title: $order}}}
  ) {
    ...WurrlyContentFragment
  }
  wurrly_aggregate(
    where: {class: {class_students: {student_id: {_eq: $studentId}}}, privacy: {_in: $privacySettings}, _and: {student_id_owner: {_neq: $studentId}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${WurrlyContentFragmentFragmentDoc}`;

/**
 * __useGetWurrliesByStudentClassesQuery__
 *
 * To run a query within a React component, call `useGetWurrliesByStudentClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesByStudentClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesByStudentClassesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      privacySettings: // value for 'privacySettings'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWurrliesByStudentClassesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesByStudentClassesQuery, Types.GetWurrliesByStudentClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesByStudentClassesQuery, Types.GetWurrliesByStudentClassesQueryVariables>(GetWurrliesByStudentClassesDocument, options);
      }
export function useGetWurrliesByStudentClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesByStudentClassesQuery, Types.GetWurrliesByStudentClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesByStudentClassesQuery, Types.GetWurrliesByStudentClassesQueryVariables>(GetWurrliesByStudentClassesDocument, options);
        }
export type GetWurrliesByStudentClassesQueryHookResult = ReturnType<typeof useGetWurrliesByStudentClassesQuery>;
export type GetWurrliesByStudentClassesLazyQueryHookResult = ReturnType<typeof useGetWurrliesByStudentClassesLazyQuery>;
export type GetWurrliesByStudentClassesQueryResult = Apollo.QueryResult<Types.GetWurrliesByStudentClassesQuery, Types.GetWurrliesByStudentClassesQueryVariables>;
export function refetchGetWurrliesByStudentClassesQuery(variables?: Types.GetWurrliesByStudentClassesQueryVariables) {
      return { query: GetWurrliesByStudentClassesDocument, variables: variables }
    }
export const GetWurrliesByClassIdDocument = gql`
    query GetWurrliesByClassId($classId: Int!, $studentId: Int, $limit: Int, $offset: Int, $privacySettings: [privacy_enum!], $orderTitle: order_by, $orderDate: order_by) {
  wurrly(
    where: {class_id: {_eq: $classId}, student_id_owner: {_eq: $studentId}, privacy: {_in: $privacySettings}, _or: [{is_from_legacy: {_eq: true}}, {wurrly_type: {_eq: indie}}, {wurrly_type: {_eq: challenge}}, {wurrly_type: {_eq: assignment}, submission_wurrly: {state: {_eq: submitted}}}]}
    limit: $limit
    offset: $offset
    order_by: {track: {song: {title: $orderTitle}}, created_at: $orderDate}
  ) {
    ...WurrlyContentFragment
    submission_wurrly {
      submission {
        grade
      }
    }
    wurrly_type
    privacy
    class {
      title
    }
    submission_wurrly {
      state
      submission_id
      submission {
        teacher_comment
        grade
        class_assignment {
          assignment {
            name
          }
        }
      }
    }
  }
  wurrly_aggregate(
    where: {class_id: {_eq: $classId}, student_id_owner: {_eq: $studentId}, privacy: {_nin: only_me}}
  ) {
    aggregate {
      count
    }
  }
}
    ${WurrlyContentFragmentFragmentDoc}`;

/**
 * __useGetWurrliesByClassIdQuery__
 *
 * To run a query within a React component, call `useGetWurrliesByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      privacySettings: // value for 'privacySettings'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetWurrliesByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesByClassIdQuery, Types.GetWurrliesByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesByClassIdQuery, Types.GetWurrliesByClassIdQueryVariables>(GetWurrliesByClassIdDocument, options);
      }
export function useGetWurrliesByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesByClassIdQuery, Types.GetWurrliesByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesByClassIdQuery, Types.GetWurrliesByClassIdQueryVariables>(GetWurrliesByClassIdDocument, options);
        }
export type GetWurrliesByClassIdQueryHookResult = ReturnType<typeof useGetWurrliesByClassIdQuery>;
export type GetWurrliesByClassIdLazyQueryHookResult = ReturnType<typeof useGetWurrliesByClassIdLazyQuery>;
export type GetWurrliesByClassIdQueryResult = Apollo.QueryResult<Types.GetWurrliesByClassIdQuery, Types.GetWurrliesByClassIdQueryVariables>;
export function refetchGetWurrliesByClassIdQuery(variables?: Types.GetWurrliesByClassIdQueryVariables) {
      return { query: GetWurrliesByClassIdDocument, variables: variables }
    }
export const GetClassWurrlyDocument = gql`
    query GetClassWurrly($classId: Int!, $wurrlyId: Int!) {
  wurrly(where: {class_id: {_eq: $classId}, wurrly_id: {_eq: $wurrlyId}}) {
    class {
      title
      class_id
    }
    student {
      student_id
      first_name
      last_name
      image_path
      avatar_selected
    }
    track {
      track_id
      song {
        song_id
        title
        artist {
          name
        }
      }
    }
    submission_wurrly {
      state
      submission_id
      submission {
        grade
        state
        teacher_comment
      }
    }
    teacher_wurrly_comments {
      comment
    }
    image_path
    resource_path
    final_resource_path
    created_at
    student_id_owner
    message
    wurrly_type
    wurrly_id
    privacy
    audio_url
    audio_balance
    is_from_legacy
    is_video_url
    wurrly_reactions {
      reaction_id
      student_id
      wurrly_id
      wurrly_reaction_id
    }
  }
}
    `;

/**
 * __useGetClassWurrlyQuery__
 *
 * To run a query within a React component, call `useGetClassWurrlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassWurrlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassWurrlyQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      wurrlyId: // value for 'wurrlyId'
 *   },
 * });
 */
export function useGetClassWurrlyQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassWurrlyQuery, Types.GetClassWurrlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassWurrlyQuery, Types.GetClassWurrlyQueryVariables>(GetClassWurrlyDocument, options);
      }
export function useGetClassWurrlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassWurrlyQuery, Types.GetClassWurrlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassWurrlyQuery, Types.GetClassWurrlyQueryVariables>(GetClassWurrlyDocument, options);
        }
export type GetClassWurrlyQueryHookResult = ReturnType<typeof useGetClassWurrlyQuery>;
export type GetClassWurrlyLazyQueryHookResult = ReturnType<typeof useGetClassWurrlyLazyQuery>;
export type GetClassWurrlyQueryResult = Apollo.QueryResult<Types.GetClassWurrlyQuery, Types.GetClassWurrlyQueryVariables>;
export function refetchGetClassWurrlyQuery(variables?: Types.GetClassWurrlyQueryVariables) {
      return { query: GetClassWurrlyDocument, variables: variables }
    }
export const GetWurrliesByStudentIdDocument = gql`
    query GetWurrliesByStudentId($studentId: Int!, $limit: Int!, $offset: Int, $orderDate: order_by, $orderTitle: order_by, $type: wurrly_type_enum, $classId: Int) {
  wurrly(
    where: {student_id_owner: {_eq: $studentId}, wurrly_type: {_eq: $type}, class_id: {_eq: $classId}}
    limit: $limit
    offset: $offset
    order_by: {student_id_owner: asc, created_at: $orderDate, track: {song: {title: $orderTitle}}}
  ) {
    ...WurrlyContentFragment
    wurrly_type
    privacy
    challenge {
      title
    }
    class {
      title
    }
    submission_wurrly {
      state
      submission_id
      submission {
        teacher_comment
        grade
        class_assignment {
          assignment {
            name
          }
        }
      }
    }
  }
  wurrly_aggregate(where: {student_id_owner: {_eq: $studentId}}) {
    aggregate {
      count
    }
  }
}
    ${WurrlyContentFragmentFragmentDoc}`;

/**
 * __useGetWurrliesByStudentIdQuery__
 *
 * To run a query within a React component, call `useGetWurrliesByStudentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesByStudentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesByStudentIdQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderDate: // value for 'orderDate'
 *      orderTitle: // value for 'orderTitle'
 *      type: // value for 'type'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetWurrliesByStudentIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesByStudentIdQuery, Types.GetWurrliesByStudentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesByStudentIdQuery, Types.GetWurrliesByStudentIdQueryVariables>(GetWurrliesByStudentIdDocument, options);
      }
export function useGetWurrliesByStudentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesByStudentIdQuery, Types.GetWurrliesByStudentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesByStudentIdQuery, Types.GetWurrliesByStudentIdQueryVariables>(GetWurrliesByStudentIdDocument, options);
        }
export type GetWurrliesByStudentIdQueryHookResult = ReturnType<typeof useGetWurrliesByStudentIdQuery>;
export type GetWurrliesByStudentIdLazyQueryHookResult = ReturnType<typeof useGetWurrliesByStudentIdLazyQuery>;
export type GetWurrliesByStudentIdQueryResult = Apollo.QueryResult<Types.GetWurrliesByStudentIdQuery, Types.GetWurrliesByStudentIdQueryVariables>;
export function refetchGetWurrliesByStudentIdQuery(variables?: Types.GetWurrliesByStudentIdQueryVariables) {
      return { query: GetWurrliesByStudentIdDocument, variables: variables }
    }
export const GetDisciplineDocument = gql`
    query GetDiscipline {
  discipline {
    name
    discipline_id
  }
}
    `;

/**
 * __useGetDisciplineQuery__
 *
 * To run a query within a React component, call `useGetDisciplineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisciplineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisciplineQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDisciplineQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDisciplineQuery, Types.GetDisciplineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDisciplineQuery, Types.GetDisciplineQueryVariables>(GetDisciplineDocument, options);
      }
export function useGetDisciplineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDisciplineQuery, Types.GetDisciplineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDisciplineQuery, Types.GetDisciplineQueryVariables>(GetDisciplineDocument, options);
        }
export type GetDisciplineQueryHookResult = ReturnType<typeof useGetDisciplineQuery>;
export type GetDisciplineLazyQueryHookResult = ReturnType<typeof useGetDisciplineLazyQuery>;
export type GetDisciplineQueryResult = Apollo.QueryResult<Types.GetDisciplineQuery, Types.GetDisciplineQueryVariables>;
export function refetchGetDisciplineQuery(variables?: Types.GetDisciplineQueryVariables) {
      return { query: GetDisciplineDocument, variables: variables }
    }
export const GetStandardDocument = gql`
    query GetStandard {
  standard {
    name
    description
    standard_subcategories {
      name
      category_standard
      description
      enduring_understanding
      standar_options {
        standar_option_id
        grade {
          name
          grade_id
        }
        title
        description
      }
    }
  }
}
    `;

/**
 * __useGetStandardQuery__
 *
 * To run a query within a React component, call `useGetStandardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStandardQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStandardQuery, Types.GetStandardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStandardQuery, Types.GetStandardQueryVariables>(GetStandardDocument, options);
      }
export function useGetStandardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStandardQuery, Types.GetStandardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStandardQuery, Types.GetStandardQueryVariables>(GetStandardDocument, options);
        }
export type GetStandardQueryHookResult = ReturnType<typeof useGetStandardQuery>;
export type GetStandardLazyQueryHookResult = ReturnType<typeof useGetStandardLazyQuery>;
export type GetStandardQueryResult = Apollo.QueryResult<Types.GetStandardQuery, Types.GetStandardQueryVariables>;
export function refetchGetStandardQuery(variables?: Types.GetStandardQueryVariables) {
      return { query: GetStandardDocument, variables: variables }
    }
export const GetArtistsDocument = gql`
    query GetArtists {
  artist {
    artist_id
    name
  }
}
    `;

/**
 * __useGetArtistsQuery__
 *
 * To run a query within a React component, call `useGetArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtistsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistsQuery, Types.GetArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsQuery, Types.GetArtistsQueryVariables>(GetArtistsDocument, options);
      }
export function useGetArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsQuery, Types.GetArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsQuery, Types.GetArtistsQueryVariables>(GetArtistsDocument, options);
        }
export type GetArtistsQueryHookResult = ReturnType<typeof useGetArtistsQuery>;
export type GetArtistsLazyQueryHookResult = ReturnType<typeof useGetArtistsLazyQuery>;
export type GetArtistsQueryResult = Apollo.QueryResult<Types.GetArtistsQuery, Types.GetArtistsQueryVariables>;
export function refetchGetArtistsQuery(variables?: Types.GetArtistsQueryVariables) {
      return { query: GetArtistsDocument, variables: variables }
    }
export const CreateAssignmentDocument = gql`
    mutation CreateAssignment($name: String!, $classes: [Int!]!, $instructions: String, $dueDate: String, $dueTime: String, $gradingSystemId: Int!, $songs: [Int!]!, $includeAllSongs: Boolean!, $videos: [Int!]!, $assets: [CustomCreateAssignmentFormAsset!]!, $allowExternalFiles: Boolean, $lessonPlanId: Int) {
  customCreateAssignment(
    set: {name: $name, classes: $classes, instructions: $instructions, dueDate: $dueDate, dueTime: $dueTime, gradingSystemId: $gradingSystemId, songs: $songs, includeAllSongs: $includeAllSongs, videos: $videos, assets: $assets, lessonPlanId: $lessonPlanId, allowExternalFiles: $allowExternalFiles}
  ) {
    assignmentId
    classAssignmentIds
  }
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      classes: // value for 'classes'
 *      instructions: // value for 'instructions'
 *      dueDate: // value for 'dueDate'
 *      dueTime: // value for 'dueTime'
 *      gradingSystemId: // value for 'gradingSystemId'
 *      songs: // value for 'songs'
 *      includeAllSongs: // value for 'includeAllSongs'
 *      videos: // value for 'videos'
 *      assets: // value for 'assets'
 *      allowExternalFiles: // value for 'allowExternalFiles'
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<Types.CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssignmentMutation, Types.CreateAssignmentMutationVariables>;
export const UpdateAssignmentDueDateDocument = gql`
    mutation UpdateAssignmentDueDate($assignmentId: Int!, $dueDate: date!, $dueTime: timetz!) {
  update_assignment(
    _set: {due_date: $dueDate, due_time: $dueTime}
    where: {assignment_id: {_eq: $assignmentId}}
  ) {
    returning {
      assignment_id
    }
  }
}
    `;
export type UpdateAssignmentDueDateMutationFn = Apollo.MutationFunction<Types.UpdateAssignmentDueDateMutation, Types.UpdateAssignmentDueDateMutationVariables>;

/**
 * __useUpdateAssignmentDueDateMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentDueDateMutation, { data, loading, error }] = useUpdateAssignmentDueDateMutation({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      dueDate: // value for 'dueDate'
 *      dueTime: // value for 'dueTime'
 *   },
 * });
 */
export function useUpdateAssignmentDueDateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssignmentDueDateMutation, Types.UpdateAssignmentDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssignmentDueDateMutation, Types.UpdateAssignmentDueDateMutationVariables>(UpdateAssignmentDueDateDocument, options);
      }
export type UpdateAssignmentDueDateMutationHookResult = ReturnType<typeof useUpdateAssignmentDueDateMutation>;
export type UpdateAssignmentDueDateMutationResult = Apollo.MutationResult<Types.UpdateAssignmentDueDateMutation>;
export type UpdateAssignmentDueDateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssignmentDueDateMutation, Types.UpdateAssignmentDueDateMutationVariables>;
export const GetCatalogByPkDocument = gql`
    query GetCatalogByPk($catalogId: Int!) {
  catalog_by_pk(catalog_id: $catalogId) {
    catalog_id
    name
    catalog_items {
      catalog_item_id
      name
      order
    }
    catalog_items_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetCatalogByPkQuery__
 *
 * To run a query within a React component, call `useGetCatalogByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogByPkQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetCatalogByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCatalogByPkQuery, Types.GetCatalogByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCatalogByPkQuery, Types.GetCatalogByPkQueryVariables>(GetCatalogByPkDocument, options);
      }
export function useGetCatalogByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCatalogByPkQuery, Types.GetCatalogByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCatalogByPkQuery, Types.GetCatalogByPkQueryVariables>(GetCatalogByPkDocument, options);
        }
export type GetCatalogByPkQueryHookResult = ReturnType<typeof useGetCatalogByPkQuery>;
export type GetCatalogByPkLazyQueryHookResult = ReturnType<typeof useGetCatalogByPkLazyQuery>;
export type GetCatalogByPkQueryResult = Apollo.QueryResult<Types.GetCatalogByPkQuery, Types.GetCatalogByPkQueryVariables>;
export function refetchGetCatalogByPkQuery(variables?: Types.GetCatalogByPkQueryVariables) {
      return { query: GetCatalogByPkDocument, variables: variables }
    }
export const GetSubjectsDocument = gql`
    query GetSubjects {
  catalog_item(order_by: {order: asc}, where: {catalog: {name: {_eq: subjects}}}) {
    active
    catalog_id
    catalog_item_id
    name
    order
  }
}
    `;

/**
 * __useGetSubjectsQuery__
 *
 * To run a query within a React component, call `useGetSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSubjectsQuery, Types.GetSubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubjectsQuery, Types.GetSubjectsQueryVariables>(GetSubjectsDocument, options);
      }
export function useGetSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubjectsQuery, Types.GetSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubjectsQuery, Types.GetSubjectsQueryVariables>(GetSubjectsDocument, options);
        }
export type GetSubjectsQueryHookResult = ReturnType<typeof useGetSubjectsQuery>;
export type GetSubjectsLazyQueryHookResult = ReturnType<typeof useGetSubjectsLazyQuery>;
export type GetSubjectsQueryResult = Apollo.QueryResult<Types.GetSubjectsQuery, Types.GetSubjectsQueryVariables>;
export function refetchGetSubjectsQuery(variables?: Types.GetSubjectsQueryVariables) {
      return { query: GetSubjectsDocument, variables: variables }
    }
export const GetCatalogItemsDocument = gql`
    query GetCatalogItems($catalogFilter: catalog_bool_exp = {}, $catalogOrder: catalog_order_by = {}) {
  catalog_item(
    where: {active: {_eq: true}, catalog: $catalogFilter}
    order_by: {catalog: $catalogOrder, order: asc}
  ) {
    catalog_item_id
    catalog {
      catalog_id
      name
    }
    image_path
    icon_path
    name
    order
    active
  }
}
    `;

/**
 * __useGetCatalogItemsQuery__
 *
 * To run a query within a React component, call `useGetCatalogItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogItemsQuery({
 *   variables: {
 *      catalogFilter: // value for 'catalogFilter'
 *      catalogOrder: // value for 'catalogOrder'
 *   },
 * });
 */
export function useGetCatalogItemsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCatalogItemsQuery, Types.GetCatalogItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCatalogItemsQuery, Types.GetCatalogItemsQueryVariables>(GetCatalogItemsDocument, options);
      }
export function useGetCatalogItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCatalogItemsQuery, Types.GetCatalogItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCatalogItemsQuery, Types.GetCatalogItemsQueryVariables>(GetCatalogItemsDocument, options);
        }
export type GetCatalogItemsQueryHookResult = ReturnType<typeof useGetCatalogItemsQuery>;
export type GetCatalogItemsLazyQueryHookResult = ReturnType<typeof useGetCatalogItemsLazyQuery>;
export type GetCatalogItemsQueryResult = Apollo.QueryResult<Types.GetCatalogItemsQuery, Types.GetCatalogItemsQueryVariables>;
export function refetchGetCatalogItemsQuery(variables?: Types.GetCatalogItemsQueryVariables) {
      return { query: GetCatalogItemsDocument, variables: variables }
    }
export const GetLessonCatalogFiltersDocument = gql`
    query GetLessonCatalogFilters {
  catalog(
    where: {lesson_plan_order: {_is_null: false}}
    order_by: {lesson_plan_order: asc}
  ) {
    ...catalogData
  }
}
    ${CatalogDataFragmentDoc}`;

/**
 * __useGetLessonCatalogFiltersQuery__
 *
 * To run a query within a React component, call `useGetLessonCatalogFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonCatalogFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonCatalogFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLessonCatalogFiltersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLessonCatalogFiltersQuery, Types.GetLessonCatalogFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonCatalogFiltersQuery, Types.GetLessonCatalogFiltersQueryVariables>(GetLessonCatalogFiltersDocument, options);
      }
export function useGetLessonCatalogFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonCatalogFiltersQuery, Types.GetLessonCatalogFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonCatalogFiltersQuery, Types.GetLessonCatalogFiltersQueryVariables>(GetLessonCatalogFiltersDocument, options);
        }
export type GetLessonCatalogFiltersQueryHookResult = ReturnType<typeof useGetLessonCatalogFiltersQuery>;
export type GetLessonCatalogFiltersLazyQueryHookResult = ReturnType<typeof useGetLessonCatalogFiltersLazyQuery>;
export type GetLessonCatalogFiltersQueryResult = Apollo.QueryResult<Types.GetLessonCatalogFiltersQuery, Types.GetLessonCatalogFiltersQueryVariables>;
export function refetchGetLessonCatalogFiltersQuery(variables?: Types.GetLessonCatalogFiltersQueryVariables) {
      return { query: GetLessonCatalogFiltersDocument, variables: variables }
    }
export const GetVideoCatalogFiltersDocument = gql`
    query GetVideoCatalogFilters {
  catalog(where: {tip_order: {_is_null: false}}, order_by: {tip_order: asc}) {
    ...catalogData
  }
}
    ${CatalogDataFragmentDoc}`;

/**
 * __useGetVideoCatalogFiltersQuery__
 *
 * To run a query within a React component, call `useGetVideoCatalogFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoCatalogFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoCatalogFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideoCatalogFiltersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVideoCatalogFiltersQuery, Types.GetVideoCatalogFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetVideoCatalogFiltersQuery, Types.GetVideoCatalogFiltersQueryVariables>(GetVideoCatalogFiltersDocument, options);
      }
export function useGetVideoCatalogFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVideoCatalogFiltersQuery, Types.GetVideoCatalogFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetVideoCatalogFiltersQuery, Types.GetVideoCatalogFiltersQueryVariables>(GetVideoCatalogFiltersDocument, options);
        }
export type GetVideoCatalogFiltersQueryHookResult = ReturnType<typeof useGetVideoCatalogFiltersQuery>;
export type GetVideoCatalogFiltersLazyQueryHookResult = ReturnType<typeof useGetVideoCatalogFiltersLazyQuery>;
export type GetVideoCatalogFiltersQueryResult = Apollo.QueryResult<Types.GetVideoCatalogFiltersQuery, Types.GetVideoCatalogFiltersQueryVariables>;
export function refetchGetVideoCatalogFiltersQuery(variables?: Types.GetVideoCatalogFiltersQueryVariables) {
      return { query: GetVideoCatalogFiltersDocument, variables: variables }
    }
export const GetSongCatalogFiltersDocument = gql`
    query GetSongCatalogFilters {
  catalog(where: {song_order: {_is_null: false}}, order_by: {song_order: asc}) {
    ...catalogData
  }
}
    ${CatalogDataFragmentDoc}`;

/**
 * __useGetSongCatalogFiltersQuery__
 *
 * To run a query within a React component, call `useGetSongCatalogFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongCatalogFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongCatalogFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSongCatalogFiltersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSongCatalogFiltersQuery, Types.GetSongCatalogFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongCatalogFiltersQuery, Types.GetSongCatalogFiltersQueryVariables>(GetSongCatalogFiltersDocument, options);
      }
export function useGetSongCatalogFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongCatalogFiltersQuery, Types.GetSongCatalogFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongCatalogFiltersQuery, Types.GetSongCatalogFiltersQueryVariables>(GetSongCatalogFiltersDocument, options);
        }
export type GetSongCatalogFiltersQueryHookResult = ReturnType<typeof useGetSongCatalogFiltersQuery>;
export type GetSongCatalogFiltersLazyQueryHookResult = ReturnType<typeof useGetSongCatalogFiltersLazyQuery>;
export type GetSongCatalogFiltersQueryResult = Apollo.QueryResult<Types.GetSongCatalogFiltersQuery, Types.GetSongCatalogFiltersQueryVariables>;
export function refetchGetSongCatalogFiltersQuery(variables?: Types.GetSongCatalogFiltersQueryVariables) {
      return { query: GetSongCatalogFiltersDocument, variables: variables }
    }
export const GetBannerChannelDocument = gql`
    query GetBannerChannel {
  channel(
    where: {active: {_eq: true}, channel_playlists: {}}
    order_by: {order: asc}
  ) {
    title
    channel_id
    banner_description
    banner_image_path
    logo_image_path
  }
}
    `;

/**
 * __useGetBannerChannelQuery__
 *
 * To run a query within a React component, call `useGetBannerChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBannerChannelQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBannerChannelQuery, Types.GetBannerChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBannerChannelQuery, Types.GetBannerChannelQueryVariables>(GetBannerChannelDocument, options);
      }
export function useGetBannerChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBannerChannelQuery, Types.GetBannerChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBannerChannelQuery, Types.GetBannerChannelQueryVariables>(GetBannerChannelDocument, options);
        }
export type GetBannerChannelQueryHookResult = ReturnType<typeof useGetBannerChannelQuery>;
export type GetBannerChannelLazyQueryHookResult = ReturnType<typeof useGetBannerChannelLazyQuery>;
export type GetBannerChannelQueryResult = Apollo.QueryResult<Types.GetBannerChannelQuery, Types.GetBannerChannelQueryVariables>;
export function refetchGetBannerChannelQuery(variables?: Types.GetBannerChannelQueryVariables) {
      return { query: GetBannerChannelDocument, variables: variables }
    }
export const GetChannelPriceDocument = gql`
    query GetChannelPrice($productId: Int!) {
  channel(where: {channel_id: {_eq: $productId}, active: {_eq: true}}) {
    channel_id
    annual_price
    monthly_price
    monthly_price_stripe_price_id
    annual_price_stripe_price_id
    stripe_product_id
    title
  }
}
    `;

/**
 * __useGetChannelPriceQuery__
 *
 * To run a query within a React component, call `useGetChannelPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelPriceQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetChannelPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChannelPriceQuery, Types.GetChannelPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChannelPriceQuery, Types.GetChannelPriceQueryVariables>(GetChannelPriceDocument, options);
      }
export function useGetChannelPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChannelPriceQuery, Types.GetChannelPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChannelPriceQuery, Types.GetChannelPriceQueryVariables>(GetChannelPriceDocument, options);
        }
export type GetChannelPriceQueryHookResult = ReturnType<typeof useGetChannelPriceQuery>;
export type GetChannelPriceLazyQueryHookResult = ReturnType<typeof useGetChannelPriceLazyQuery>;
export type GetChannelPriceQueryResult = Apollo.QueryResult<Types.GetChannelPriceQuery, Types.GetChannelPriceQueryVariables>;
export function refetchGetChannelPriceQuery(variables?: Types.GetChannelPriceQueryVariables) {
      return { query: GetChannelPriceDocument, variables: variables }
    }
export const GetChannelDataDocument = gql`
    query GetChannelData($channelId: Int!) {
  channel(where: {channel_id: {_eq: $channelId}, active: {_eq: true}}) {
    title
    channel_id
    description
    image_path
    video_path
    short_description
    heading_short_description
    heading_description
    get_status_by_teacher
    is_purchased_by_teacher
    video_thumbnail_image_path
    channel_playlists(order_by: {playlist: {order: asc}}) {
      playlist {
        playlist_id
        image_path
        name
        description
        is_purchased_by_teacher
        lockable_content {
          visibility
        }
        tip_playlist {
          tip_id
          playlist_id
          tip {
            tip_catalog_item {
              catalog_item {
                name
                catalog {
                  name
                }
              }
            }
            title
          }
        }
        tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
          aggregate {
            count
          }
        }
        class_playlists {
          class_playlist_id
          class {
            title
            class_id
          }
        }
      }
    }
    lockable_content {
      visibility
    }
  }
}
    `;

/**
 * __useGetChannelDataQuery__
 *
 * To run a query within a React component, call `useGetChannelDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelDataQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChannelDataQuery, Types.GetChannelDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChannelDataQuery, Types.GetChannelDataQueryVariables>(GetChannelDataDocument, options);
      }
export function useGetChannelDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChannelDataQuery, Types.GetChannelDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChannelDataQuery, Types.GetChannelDataQueryVariables>(GetChannelDataDocument, options);
        }
export type GetChannelDataQueryHookResult = ReturnType<typeof useGetChannelDataQuery>;
export type GetChannelDataLazyQueryHookResult = ReturnType<typeof useGetChannelDataLazyQuery>;
export type GetChannelDataQueryResult = Apollo.QueryResult<Types.GetChannelDataQuery, Types.GetChannelDataQueryVariables>;
export function refetchGetChannelDataQuery(variables?: Types.GetChannelDataQueryVariables) {
      return { query: GetChannelDataDocument, variables: variables }
    }
export const GetChannelsDocument = gql`
    query GetChannels($search: [channel_bool_exp!], $limit: Int, $order_by: [channel_order_by!], $offset: Int, $includeCountChannels: Boolean = false, $teacher_id: Int!) {
  channel(
    order_by: $order_by
    limit: $limit
    offset: $offset
    where: {_or: $search, channel_playlists: {}, active: {_eq: true}}
  ) {
    active
    description
    image_path
    title
    video_path
    short_description
    created_at
    channel_id
    channel_playlists {
      playlist {
        name
        tip_playlist_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    get_status_by_teacher
    is_purchased_by_teacher
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
  }
  channel_aggregate(
    where: {_or: $search, channel_playlists: {}, active: {_eq: true}}
  ) @include(if: $includeCountChannels) {
    aggregate {
      count
    }
  }
  channel_playlist_aggregate {
    aggregate {
      count
    }
    nodes {
      playlist {
        tip_playlist_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      includeCountChannels: // value for 'includeCountChannels'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useGetChannelsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetChannelsQuery, Types.GetChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetChannelsQuery, Types.GetChannelsQueryVariables>(GetChannelsDocument, options);
      }
export function useGetChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetChannelsQuery, Types.GetChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetChannelsQuery, Types.GetChannelsQueryVariables>(GetChannelsDocument, options);
        }
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<typeof useGetChannelsLazyQuery>;
export type GetChannelsQueryResult = Apollo.QueryResult<Types.GetChannelsQuery, Types.GetChannelsQueryVariables>;
export function refetchGetChannelsQuery(variables?: Types.GetChannelsQueryVariables) {
      return { query: GetChannelsDocument, variables: variables }
    }
export const SearchChannelDocument = gql`
    query SearchChannel($searchText: [channel_bool_exp!], $limit: Int, $offset: Int, $conditions: [channel_bool_exp!], $order: order_by = asc, $teacher_id: Int!) {
  channel(
    limit: $limit
    offset: $offset
    where: {_or: $searchText, _and: $conditions, channel_playlists: {}, active: {_eq: true}}
    order_by: {title: $order}
  ) {
    active
    description
    image_path
    title
    video_path
    short_description
    created_at
    channel_id
    get_status_by_teacher
    is_purchased_by_teacher
    channel_playlists {
      playlist {
        name
      }
    }
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
  }
}
    `;

/**
 * __useSearchChannelQuery__
 *
 * To run a query within a React component, call `useSearchChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChannelQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      conditions: // value for 'conditions'
 *      order: // value for 'order'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useSearchChannelQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchChannelQuery, Types.SearchChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchChannelQuery, Types.SearchChannelQueryVariables>(SearchChannelDocument, options);
      }
export function useSearchChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchChannelQuery, Types.SearchChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchChannelQuery, Types.SearchChannelQueryVariables>(SearchChannelDocument, options);
        }
export type SearchChannelQueryHookResult = ReturnType<typeof useSearchChannelQuery>;
export type SearchChannelLazyQueryHookResult = ReturnType<typeof useSearchChannelLazyQuery>;
export type SearchChannelQueryResult = Apollo.QueryResult<Types.SearchChannelQuery, Types.SearchChannelQueryVariables>;
export function refetchSearchChannelQuery(variables?: Types.SearchChannelQueryVariables) {
      return { query: SearchChannelDocument, variables: variables }
    }
export const CreateClassDocument = gql`
    mutation CreateClass($objects: [class_insert_input!]!) {
  insert_class(objects: $objects) {
    returning {
      class_id
      class_teachers {
        teacher_id
      }
    }
  }
}
    `;
export type CreateClassMutationFn = Apollo.MutationFunction<Types.CreateClassMutation, Types.CreateClassMutationVariables>;

/**
 * __useCreateClassMutation__
 *
 * To run a mutation, you first call `useCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassMutation, { data, loading, error }] = useCreateClassMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useCreateClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateClassMutation, Types.CreateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateClassMutation, Types.CreateClassMutationVariables>(CreateClassDocument, options);
      }
export type CreateClassMutationHookResult = ReturnType<typeof useCreateClassMutation>;
export type CreateClassMutationResult = Apollo.MutationResult<Types.CreateClassMutation>;
export type CreateClassMutationOptions = Apollo.BaseMutationOptions<Types.CreateClassMutation, Types.CreateClassMutationVariables>;
export const SetClassSettingsDocument = gql`
    mutation SetClassSettings($classId: Int!, $teacherId: Int!, $classData: class_set_input = {}) {
  update_class(
    where: {class_id: {_eq: $classId}, class_teachers: {teacher_id: {_eq: $teacherId}}}
    _set: $classData
  ) {
    affected_rows
    returning {
      ...ClassDetailsReducedFragment
      ...ClassCountersFragment
      ...ClassSettingsFragment
    }
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}
${ClassSettingsFragmentFragmentDoc}`;
export type SetClassSettingsMutationFn = Apollo.MutationFunction<Types.SetClassSettingsMutation, Types.SetClassSettingsMutationVariables>;

/**
 * __useSetClassSettingsMutation__
 *
 * To run a mutation, you first call `useSetClassSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClassSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClassSettingsMutation, { data, loading, error }] = useSetClassSettingsMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *      classData: // value for 'classData'
 *   },
 * });
 */
export function useSetClassSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetClassSettingsMutation, Types.SetClassSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetClassSettingsMutation, Types.SetClassSettingsMutationVariables>(SetClassSettingsDocument, options);
      }
export type SetClassSettingsMutationHookResult = ReturnType<typeof useSetClassSettingsMutation>;
export type SetClassSettingsMutationResult = Apollo.MutationResult<Types.SetClassSettingsMutation>;
export type SetClassSettingsMutationOptions = Apollo.BaseMutationOptions<Types.SetClassSettingsMutation, Types.SetClassSettingsMutationVariables>;
export const DeleteClassDocument = gql`
    mutation DeleteClass($classId: Int!) {
  delete_class(where: {class_id: {_eq: $classId}}) {
    affected_rows
    returning {
      class_id
    }
  }
}
    `;
export type DeleteClassMutationFn = Apollo.MutationFunction<Types.DeleteClassMutation, Types.DeleteClassMutationVariables>;

/**
 * __useDeleteClassMutation__
 *
 * To run a mutation, you first call `useDeleteClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassMutation, { data, loading, error }] = useDeleteClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useDeleteClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteClassMutation, Types.DeleteClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteClassMutation, Types.DeleteClassMutationVariables>(DeleteClassDocument, options);
      }
export type DeleteClassMutationHookResult = ReturnType<typeof useDeleteClassMutation>;
export type DeleteClassMutationResult = Apollo.MutationResult<Types.DeleteClassMutation>;
export type DeleteClassMutationOptions = Apollo.BaseMutationOptions<Types.DeleteClassMutation, Types.DeleteClassMutationVariables>;
export const GetClassTeacherByPkDocument = gql`
    query GetClassTeacherByPk($teacherId: Int!, $classId: Int!) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    class_teachers(where: {class_id: {_eq: $classId}}) {
      class_teacher_id
      class {
        ...ClassDetailsReducedFragment
        ...ClassCountersFragment
      }
    }
  }
  total_lessons: lesson_plan_aggregate(
    where: {class_lesson_plans: {class_id: {_eq: $classId}}}
  ) {
    aggregate {
      count
    }
  }
  playlist_tip_aggregate: tip_aggregate(
    where: {_and: [{_or: [{tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}, {class_tips: {class_id: {_eq: $classId}}}]}, {active: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}`;

/**
 * __useGetClassTeacherByPkQuery__
 *
 * To run a query within a React component, call `useGetClassTeacherByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTeacherByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTeacherByPkQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassTeacherByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassTeacherByPkQuery, Types.GetClassTeacherByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassTeacherByPkQuery, Types.GetClassTeacherByPkQueryVariables>(GetClassTeacherByPkDocument, options);
      }
export function useGetClassTeacherByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassTeacherByPkQuery, Types.GetClassTeacherByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassTeacherByPkQuery, Types.GetClassTeacherByPkQueryVariables>(GetClassTeacherByPkDocument, options);
        }
export type GetClassTeacherByPkQueryHookResult = ReturnType<typeof useGetClassTeacherByPkQuery>;
export type GetClassTeacherByPkLazyQueryHookResult = ReturnType<typeof useGetClassTeacherByPkLazyQuery>;
export type GetClassTeacherByPkQueryResult = Apollo.QueryResult<Types.GetClassTeacherByPkQuery, Types.GetClassTeacherByPkQueryVariables>;
export function refetchGetClassTeacherByPkQuery(variables?: Types.GetClassTeacherByPkQueryVariables) {
      return { query: GetClassTeacherByPkDocument, variables: variables }
    }
export const GetClassByPkDocument = gql`
    query GetClassByPk($classId: Int!) {
  class_by_pk(class_id: $classId) {
    class_id
    title
    start_date
    end_date
    image_path
    maturity_level
    catalog_item_id
    grading_system_id
    grade_id
    total_lessons_in_class
    catalog_item {
      catalog_item_id
      name
    }
    class_teachers {
      teacher {
        full_name
        teacher_id
        image_path
        created_at
      }
    }
    ...ClassCountersFragment
  }
}
    ${ClassCountersFragmentFragmentDoc}`;

/**
 * __useGetClassByPkQuery__
 *
 * To run a query within a React component, call `useGetClassByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassByPkQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassByPkQuery, Types.GetClassByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassByPkQuery, Types.GetClassByPkQueryVariables>(GetClassByPkDocument, options);
      }
export function useGetClassByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassByPkQuery, Types.GetClassByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassByPkQuery, Types.GetClassByPkQueryVariables>(GetClassByPkDocument, options);
        }
export type GetClassByPkQueryHookResult = ReturnType<typeof useGetClassByPkQuery>;
export type GetClassByPkLazyQueryHookResult = ReturnType<typeof useGetClassByPkLazyQuery>;
export type GetClassByPkQueryResult = Apollo.QueryResult<Types.GetClassByPkQuery, Types.GetClassByPkQueryVariables>;
export function refetchGetClassByPkQuery(variables?: Types.GetClassByPkQueryVariables) {
      return { query: GetClassByPkDocument, variables: variables }
    }
export const GetClassPointsByBadgeEventDocument = gql`
    query GetClassPointsByBadgeEvent($classId: Int, $offset: Int, $limit: Int) {
  student_badge(
    where: {awarded_badge_events: {student_badge_event: {class_id: {_eq: $classId}}}}
    offset: $offset
    order_by: {created_at: desc}
    limit: $limit
  ) {
    student_badge_id
    badge {
      points
      name
    }
    student {
      image_path
      student_id
      full_name
      student_badge_events {
        challenge {
          title
        }
      }
    }
  }
  student_badge_aggregate(
    where: {awarded_badge_events: {student_badge_event: {class_id: {_eq: $classId}}}}
  ) {
    aggregate {
      count(columns: student_badge_id)
    }
  }
}
    `;

/**
 * __useGetClassPointsByBadgeEventQuery__
 *
 * To run a query within a React component, call `useGetClassPointsByBadgeEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassPointsByBadgeEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassPointsByBadgeEventQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetClassPointsByBadgeEventQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetClassPointsByBadgeEventQuery, Types.GetClassPointsByBadgeEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassPointsByBadgeEventQuery, Types.GetClassPointsByBadgeEventQueryVariables>(GetClassPointsByBadgeEventDocument, options);
      }
export function useGetClassPointsByBadgeEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassPointsByBadgeEventQuery, Types.GetClassPointsByBadgeEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassPointsByBadgeEventQuery, Types.GetClassPointsByBadgeEventQueryVariables>(GetClassPointsByBadgeEventDocument, options);
        }
export type GetClassPointsByBadgeEventQueryHookResult = ReturnType<typeof useGetClassPointsByBadgeEventQuery>;
export type GetClassPointsByBadgeEventLazyQueryHookResult = ReturnType<typeof useGetClassPointsByBadgeEventLazyQuery>;
export type GetClassPointsByBadgeEventQueryResult = Apollo.QueryResult<Types.GetClassPointsByBadgeEventQuery, Types.GetClassPointsByBadgeEventQueryVariables>;
export function refetchGetClassPointsByBadgeEventQuery(variables?: Types.GetClassPointsByBadgeEventQueryVariables) {
      return { query: GetClassPointsByBadgeEventDocument, variables: variables }
    }
export const GetStudentTotalPointsByClassDocument = gql`
    query GetStudentTotalPointsByClass($classId: Int, $offset: Int, $limit: Int) {
  student(
    where: {student_badge_events: {class_id: {_eq: $classId}}}
    offset: $offset
    limit: $limit
  ) {
    full_name
    image_path
    student_id
    student_badges(
      where: {awarded_badge_events: {student_badge_event: {class_id: {_eq: $classId}}}}
    ) {
      badge {
        points
        badge_id
      }
    }
  }
  student_aggregate(where: {student_badge_events: {class_id: {_eq: $classId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetStudentTotalPointsByClassQuery__
 *
 * To run a query within a React component, call `useGetStudentTotalPointsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentTotalPointsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentTotalPointsByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStudentTotalPointsByClassQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStudentTotalPointsByClassQuery, Types.GetStudentTotalPointsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentTotalPointsByClassQuery, Types.GetStudentTotalPointsByClassQueryVariables>(GetStudentTotalPointsByClassDocument, options);
      }
export function useGetStudentTotalPointsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentTotalPointsByClassQuery, Types.GetStudentTotalPointsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentTotalPointsByClassQuery, Types.GetStudentTotalPointsByClassQueryVariables>(GetStudentTotalPointsByClassDocument, options);
        }
export type GetStudentTotalPointsByClassQueryHookResult = ReturnType<typeof useGetStudentTotalPointsByClassQuery>;
export type GetStudentTotalPointsByClassLazyQueryHookResult = ReturnType<typeof useGetStudentTotalPointsByClassLazyQuery>;
export type GetStudentTotalPointsByClassQueryResult = Apollo.QueryResult<Types.GetStudentTotalPointsByClassQuery, Types.GetStudentTotalPointsByClassQueryVariables>;
export function refetchGetStudentTotalPointsByClassQuery(variables?: Types.GetStudentTotalPointsByClassQueryVariables) {
      return { query: GetStudentTotalPointsByClassDocument, variables: variables }
    }
export const GetClassBadgesDocument = gql`
    query GetClassBadges($fromDate: timestamptz!, $toDate: timestamptz!, $classId: Int) {
  student_badge(
    where: {_and: {created_at: {_gte: $fromDate, _lte: $toDate}, awarded_badge_events: {student_badge_event: {class_id: {_eq: $classId}}}}}
    order_by: {created_at: asc}
  ) {
    badge {
      color
      name
      points
      badge_id
    }
    student_badge_id
    created_at
  }
}
    `;

/**
 * __useGetClassBadgesQuery__
 *
 * To run a query within a React component, call `useGetClassBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassBadgesQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassBadgesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassBadgesQuery, Types.GetClassBadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassBadgesQuery, Types.GetClassBadgesQueryVariables>(GetClassBadgesDocument, options);
      }
export function useGetClassBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassBadgesQuery, Types.GetClassBadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassBadgesQuery, Types.GetClassBadgesQueryVariables>(GetClassBadgesDocument, options);
        }
export type GetClassBadgesQueryHookResult = ReturnType<typeof useGetClassBadgesQuery>;
export type GetClassBadgesLazyQueryHookResult = ReturnType<typeof useGetClassBadgesLazyQuery>;
export type GetClassBadgesQueryResult = Apollo.QueryResult<Types.GetClassBadgesQuery, Types.GetClassBadgesQueryVariables>;
export function refetchGetClassBadgesQuery(variables?: Types.GetClassBadgesQueryVariables) {
      return { query: GetClassBadgesDocument, variables: variables }
    }
export const GetClassesDocument = gql`
    query GetClasses($teacherId: Int!, $rightNow: timestamptz, $getActiveClasses: Boolean = true, $limit: Int, $search: [class_bool_exp!] = [{}]) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    active: class_teachers(
      order_by: {class: {created_at: desc_nulls_last}}
      where: {class: {_and: [{start_date: {_lte: $rightNow}}, {end_date: {_gte: $rightNow}}], _or: $search}}
      limit: $limit
    ) @include(if: $getActiveClasses) {
      ...ClassTeacherContentFragment
    }
    inactive: class_teachers(
      order_by: {class: {created_at: desc_nulls_last}}
      where: {class: {_or: [{start_date: {_gt: $rightNow}}, {end_date: {_lt: $rightNow}}]}}
      limit: $limit
    ) @skip(if: $getActiveClasses) {
      ...ClassTeacherContentFragment
    }
    active_aggregate: class_teachers_aggregate(
      where: {class: {_and: [{start_date: {_lte: $rightNow}}, {end_date: {_gte: $rightNow}}]}}
    ) @include(if: $getActiveClasses) {
      aggregate {
        count
      }
    }
    inactive_aggregate: class_teachers_aggregate(
      where: {class: {_or: [{start_date: {_gt: $rightNow}}, {end_date: {_lt: $rightNow}}]}}
    ) @skip(if: $getActiveClasses) {
      aggregate {
        count
      }
    }
  }
}
    ${ClassTeacherContentFragmentFragmentDoc}`;

/**
 * __useGetClassesQuery__
 *
 * To run a query within a React component, call `useGetClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      rightNow: // value for 'rightNow'
 *      getActiveClasses: // value for 'getActiveClasses'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetClassesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassesQuery, Types.GetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassesQuery, Types.GetClassesQueryVariables>(GetClassesDocument, options);
      }
export function useGetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassesQuery, Types.GetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassesQuery, Types.GetClassesQueryVariables>(GetClassesDocument, options);
        }
export type GetClassesQueryHookResult = ReturnType<typeof useGetClassesQuery>;
export type GetClassesLazyQueryHookResult = ReturnType<typeof useGetClassesLazyQuery>;
export type GetClassesQueryResult = Apollo.QueryResult<Types.GetClassesQuery, Types.GetClassesQueryVariables>;
export function refetchGetClassesQuery(variables?: Types.GetClassesQueryVariables) {
      return { query: GetClassesDocument, variables: variables }
    }
export const GetClassesDialogDocument = gql`
    query GetClassesDialog($offset: Int, $searchTexts: [class_bool_exp!], $userId: Int, $tipId: Int, $playlistId: Int, $lessonPlanId: Int, $sequenceId: Int, $moduleId: Int, $songId: Int, $limit: Int, $orderBy: [class_order_by!]) {
  class(
    where: {_or: $searchTexts}
    offset: $offset
    limit: $limit
    order_by: $orderBy
  ) {
    image_path
    class_id
    title
    maturity_level
    total_lessons_in_class
    grade_id
    class_students(where: {student_id: {_eq: $userId}}) {
      student_id
    }
    class_teachers(where: {teacher_id: {_eq: $userId}}) {
      teacher_id
      teacher {
        teacher_id
        name_first
        name_last
      }
    }
    class_tips(where: {tip_id: {_eq: $tipId}}) {
      tip_id
    }
    class_playlists(where: {playlist_id: {_eq: $playlistId}}) {
      playlist_id
    }
    class_lesson_plans(where: {lesson_plan_id: {_eq: $lessonPlanId}}) {
      lesson_plan_id
    }
    class_sequences(where: {sequence_id: {_eq: $sequenceId}}) {
      sequence_id
    }
    class_songs(where: {song_id: {_eq: $songId}}) {
      song_id
    }
    class_modules(where: {module_id: {_eq: $moduleId}}) {
      module_id
    }
    class_students_aggregate {
      aggregate {
        count
      }
    }
    tipsCount: class_tips {
      tip_id
    }
    playlistCount: class_playlists {
      playlist {
        tip_playlist {
          tip_id
        }
      }
    }
    class_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
    class_songs_aggregate {
      aggregate {
        count
      }
    }
    class_modules_aggregate {
      aggregate {
        count
      }
    }
  }
  class_aggregate(where: {_or: $searchTexts}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetClassesDialogQuery__
 *
 * To run a query within a React component, call `useGetClassesDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesDialogQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      searchTexts: // value for 'searchTexts'
 *      userId: // value for 'userId'
 *      tipId: // value for 'tipId'
 *      playlistId: // value for 'playlistId'
 *      lessonPlanId: // value for 'lessonPlanId'
 *      sequenceId: // value for 'sequenceId'
 *      moduleId: // value for 'moduleId'
 *      songId: // value for 'songId'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetClassesDialogQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetClassesDialogQuery, Types.GetClassesDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassesDialogQuery, Types.GetClassesDialogQueryVariables>(GetClassesDialogDocument, options);
      }
export function useGetClassesDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassesDialogQuery, Types.GetClassesDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassesDialogQuery, Types.GetClassesDialogQueryVariables>(GetClassesDialogDocument, options);
        }
export type GetClassesDialogQueryHookResult = ReturnType<typeof useGetClassesDialogQuery>;
export type GetClassesDialogLazyQueryHookResult = ReturnType<typeof useGetClassesDialogLazyQuery>;
export type GetClassesDialogQueryResult = Apollo.QueryResult<Types.GetClassesDialogQuery, Types.GetClassesDialogQueryVariables>;
export function refetchGetClassesDialogQuery(variables?: Types.GetClassesDialogQueryVariables) {
      return { query: GetClassesDialogDocument, variables: variables }
    }
export const GetStudentClassesDocument = gql`
    query GetStudentClasses($teacherId: Int!, $studentId: Int!) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    class_teachers(
      order_by: {class: {title: asc}}
      where: {class: {class_students: {student_id: {_eq: $studentId}}}}
    ) {
      class {
        ...ClassDetailsReducedFragment
        ...ClassCountersFragment
      }
    }
    class_teachers_aggregate(
      where: {class: {class_students: {student_id: {_eq: $studentId}}}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}`;

/**
 * __useGetStudentClassesQuery__
 *
 * To run a query within a React component, call `useGetStudentClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentClassesQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentClassesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentClassesQuery, Types.GetStudentClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentClassesQuery, Types.GetStudentClassesQueryVariables>(GetStudentClassesDocument, options);
      }
export function useGetStudentClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentClassesQuery, Types.GetStudentClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentClassesQuery, Types.GetStudentClassesQueryVariables>(GetStudentClassesDocument, options);
        }
export type GetStudentClassesQueryHookResult = ReturnType<typeof useGetStudentClassesQuery>;
export type GetStudentClassesLazyQueryHookResult = ReturnType<typeof useGetStudentClassesLazyQuery>;
export type GetStudentClassesQueryResult = Apollo.QueryResult<Types.GetStudentClassesQuery, Types.GetStudentClassesQueryVariables>;
export function refetchGetStudentClassesQuery(variables?: Types.GetStudentClassesQueryVariables) {
      return { query: GetStudentClassesDocument, variables: variables }
    }
export const GetStudentsByClassDocument = gql`
    query GetStudentsByClass($teacherId: Int!, $classId: Int!, $sortOrder: order_by = asc, $limit: Int, $offset: Int) {
  student(
    where: {class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}, class_id: {_eq: $classId}}}}}
    order_by: {first_name: $sortOrder}
    limit: $limit
    offset: $offset
  ) {
    student_id
    first_name
    last_name
    is_active
    email
    image_path
    code
  }
  student_aggregate(
    where: {class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}, class_id: {_eq: $classId}}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetStudentsByClassQuery__
 *
 * To run a query within a React component, call `useGetStudentsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByClassQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *      sortOrder: // value for 'sortOrder'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetStudentsByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentsByClassQuery, Types.GetStudentsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentsByClassQuery, Types.GetStudentsByClassQueryVariables>(GetStudentsByClassDocument, options);
      }
export function useGetStudentsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentsByClassQuery, Types.GetStudentsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentsByClassQuery, Types.GetStudentsByClassQueryVariables>(GetStudentsByClassDocument, options);
        }
export type GetStudentsByClassQueryHookResult = ReturnType<typeof useGetStudentsByClassQuery>;
export type GetStudentsByClassLazyQueryHookResult = ReturnType<typeof useGetStudentsByClassLazyQuery>;
export type GetStudentsByClassQueryResult = Apollo.QueryResult<Types.GetStudentsByClassQuery, Types.GetStudentsByClassQueryVariables>;
export function refetchGetStudentsByClassQuery(variables?: Types.GetStudentsByClassQueryVariables) {
      return { query: GetStudentsByClassDocument, variables: variables }
    }
export const GetClassSettingsDocument = gql`
    query GetClassSettings($classId: Int!, $teacherId: Int!) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    class_teachers(where: {class_id: {_eq: $classId}}) {
      class_teacher_id
      class {
        ...ClassDetailsReducedFragment
        ...ClassCountersFragment
        ...ClassSettingsFragment
      }
    }
  }
}
    ${ClassDetailsReducedFragmentFragmentDoc}
${ClassCountersFragmentFragmentDoc}
${ClassSettingsFragmentFragmentDoc}`;

/**
 * __useGetClassSettingsQuery__
 *
 * To run a query within a React component, call `useGetClassSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassSettingsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetClassSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassSettingsQuery, Types.GetClassSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassSettingsQuery, Types.GetClassSettingsQueryVariables>(GetClassSettingsDocument, options);
      }
export function useGetClassSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassSettingsQuery, Types.GetClassSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassSettingsQuery, Types.GetClassSettingsQueryVariables>(GetClassSettingsDocument, options);
        }
export type GetClassSettingsQueryHookResult = ReturnType<typeof useGetClassSettingsQuery>;
export type GetClassSettingsLazyQueryHookResult = ReturnType<typeof useGetClassSettingsLazyQuery>;
export type GetClassSettingsQueryResult = Apollo.QueryResult<Types.GetClassSettingsQuery, Types.GetClassSettingsQueryVariables>;
export function refetchGetClassSettingsQuery(variables?: Types.GetClassSettingsQueryVariables) {
      return { query: GetClassSettingsDocument, variables: variables }
    }
export const GetClassLessonPlansDocument = gql`
    query GetClassLessonPlans($teacherId: Int!, $classId: Int!, $limit: Int, $orderTitle: order_by = asc, $orderDate: order_by) {
  lesson_plan(
    where: {_and: [{active: {_eq: true}}, {_or: [{class_lesson_plans: {class_id: {_eq: $classId}}}, {_and: [{module_lesson_plans: {module: {active: {_eq: true}}}}, {module_lesson_plans: {module: {class_modules: {class_id: {_eq: $classId}}}}}]}, {sequence_lesson_plan: {sequence: {class_sequences: {class_id: {_eq: $classId}}}}}]}]}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    assignment_created(args: {classid: $classId})
    assignments(where: {class_assignments: {class_id: {_eq: $classId}}}) {
      class_assignments {
        class_assignment_id
      }
    }
    class_lesson_plans {
      class_lesson_plan_id
      class {
        class_id
        title
      }
    }
    module_lesson_plans(
      where: {module: {class_modules: {class_id: {_eq: $classId}}}}
    ) {
      module {
        title
      }
    }
    sequence_lesson_plan(
      where: {sequence: {class_sequences: {class_id: {_eq: $classId}}}}
    ) {
      sequence {
        name
      }
    }
    process
    module_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
    ...LessonPlanOnlyCatalogsFragment
  }
  lesson_plan_aggregate(
    where: {_and: [{active: {_eq: true}}, {_or: [{class_lesson_plans: {class_id: {_eq: $classId}}}, {_and: [{module_lesson_plans: {module: {active: {_eq: true}}}}, {module_lesson_plans: {module: {class_modules: {class_id: {_eq: $classId}}}}}]}, {sequence_lesson_plan: {sequence: {class_sequences: {class_id: {_eq: $classId}}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${LessonPlanOnlyCatalogsFragmentFragmentDoc}`;

/**
 * __useGetClassLessonPlansQuery__
 *
 * To run a query within a React component, call `useGetClassLessonPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassLessonPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassLessonPlansQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetClassLessonPlansQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassLessonPlansQuery, Types.GetClassLessonPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassLessonPlansQuery, Types.GetClassLessonPlansQueryVariables>(GetClassLessonPlansDocument, options);
      }
export function useGetClassLessonPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassLessonPlansQuery, Types.GetClassLessonPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassLessonPlansQuery, Types.GetClassLessonPlansQueryVariables>(GetClassLessonPlansDocument, options);
        }
export type GetClassLessonPlansQueryHookResult = ReturnType<typeof useGetClassLessonPlansQuery>;
export type GetClassLessonPlansLazyQueryHookResult = ReturnType<typeof useGetClassLessonPlansLazyQuery>;
export type GetClassLessonPlansQueryResult = Apollo.QueryResult<Types.GetClassLessonPlansQuery, Types.GetClassLessonPlansQueryVariables>;
export function refetchGetClassLessonPlansQuery(variables?: Types.GetClassLessonPlansQueryVariables) {
      return { query: GetClassLessonPlansDocument, variables: variables }
    }
export const GetClassModulesDocument = gql`
    query GetClassModules($classId: Int!, $limit: Int, $orderTitle: order_by = asc, $orderDate: order_by) {
  class_by_pk(class_id: $classId) {
    class_id
    class_modules(
      limit: $limit
      order_by: {module: {title: $orderTitle, created_at: $orderDate}}
      where: {module: {active: {_eq: true}, module_id: {_is_null: false}}}
    ) {
      class_module_id
      module {
        is_purchased_by_teacher
        ...ModuleFragment
        lockable_content {
          visibility
        }
        module_lesson_plan(
          order_by: {order: asc}
          where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
        ) {
          lesson_plan {
            module_lesson_plans_aggregate {
              aggregate {
                count
              }
            }
            assignment_created(args: {classid: $classId})
          }
        }
      }
    }
    class_modules_aggregate(
      where: {module: {active: {_eq: true}, module_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${ModuleFragmentFragmentDoc}`;

/**
 * __useGetClassModulesQuery__
 *
 * To run a query within a React component, call `useGetClassModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassModulesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetClassModulesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassModulesQuery, Types.GetClassModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassModulesQuery, Types.GetClassModulesQueryVariables>(GetClassModulesDocument, options);
      }
export function useGetClassModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassModulesQuery, Types.GetClassModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassModulesQuery, Types.GetClassModulesQueryVariables>(GetClassModulesDocument, options);
        }
export type GetClassModulesQueryHookResult = ReturnType<typeof useGetClassModulesQuery>;
export type GetClassModulesLazyQueryHookResult = ReturnType<typeof useGetClassModulesLazyQuery>;
export type GetClassModulesQueryResult = Apollo.QueryResult<Types.GetClassModulesQuery, Types.GetClassModulesQueryVariables>;
export function refetchGetClassModulesQuery(variables?: Types.GetClassModulesQueryVariables) {
      return { query: GetClassModulesDocument, variables: variables }
    }
export const GetTipsByClassDocument = gql`
    query GetTipsByClass($classId: Int!, $teacherId: Int!, $orderTitle: order_by = asc, $createdAt: order_by) {
  tip(
    where: {_and: [{_or: [{_and: [{tip_playlists: {playlist: {active: {_eq: true}}}}, {tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}]}, {class_tips: {class_id: {_eq: $classId}}}]}, {active: {_eq: true}}]}
    order_by: {title: $orderTitle, created_at: $createdAt}
  ) {
    ...tipExtendedFragment
    tip_playlists(
      where: {_and: [{playlist: {class_playlists: {class_id: {_eq: $classId}}}}, {playlist: {active: {_eq: true}}}]}
    ) {
      playlist {
        name
      }
    }
    is_favorite(args: {teacherid: $teacherId})
  }
  tip_aggregate(
    where: {_and: [{_or: [{_and: [{tip_playlists: {playlist: {active: {_eq: true}}}}, {tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}]}, {class_tips: {class_id: {_eq: $classId}}}]}, {active: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetTipsByClassQuery__
 *
 * To run a query within a React component, call `useGetTipsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipsByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *      orderTitle: // value for 'orderTitle'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useGetTipsByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipsByClassQuery, Types.GetTipsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipsByClassQuery, Types.GetTipsByClassQueryVariables>(GetTipsByClassDocument, options);
      }
export function useGetTipsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipsByClassQuery, Types.GetTipsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipsByClassQuery, Types.GetTipsByClassQueryVariables>(GetTipsByClassDocument, options);
        }
export type GetTipsByClassQueryHookResult = ReturnType<typeof useGetTipsByClassQuery>;
export type GetTipsByClassLazyQueryHookResult = ReturnType<typeof useGetTipsByClassLazyQuery>;
export type GetTipsByClassQueryResult = Apollo.QueryResult<Types.GetTipsByClassQuery, Types.GetTipsByClassQueryVariables>;
export function refetchGetTipsByClassQuery(variables?: Types.GetTipsByClassQueryVariables) {
      return { query: GetTipsByClassDocument, variables: variables }
    }
export const GetTipPlayListsByClassDocument = gql`
    query GetTipPlayListsByClass($classId: Int!, $orderName: order_by = asc, $limit: Int) {
  class(where: {class_id: {_eq: $classId}}) {
    class_id
    class_playlists(
      limit: $limit
      where: {_and: [{playlist: {active: {_eq: true}}}, {playlist: {playlist_id: {_is_null: false}}}]}
    ) {
      class_playlist_id
      playlist {
        playlist_id
        image_path
        name
        description
        tip_counter
        ...PlaylistTipsForGenresStudentFragment
        tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
          aggregate {
            count
          }
        }
      }
    }
    class_playlists_aggregate(
      where: {_and: [{playlist: {active: {_eq: true}}}, {playlist: {playlist_id: {_is_null: false}}}]}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${PlaylistTipsForGenresStudentFragmentFragmentDoc}`;

/**
 * __useGetTipPlayListsByClassQuery__
 *
 * To run a query within a React component, call `useGetTipPlayListsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipPlayListsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipPlayListsByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      orderName: // value for 'orderName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTipPlayListsByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipPlayListsByClassQuery, Types.GetTipPlayListsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipPlayListsByClassQuery, Types.GetTipPlayListsByClassQueryVariables>(GetTipPlayListsByClassDocument, options);
      }
export function useGetTipPlayListsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipPlayListsByClassQuery, Types.GetTipPlayListsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipPlayListsByClassQuery, Types.GetTipPlayListsByClassQueryVariables>(GetTipPlayListsByClassDocument, options);
        }
export type GetTipPlayListsByClassQueryHookResult = ReturnType<typeof useGetTipPlayListsByClassQuery>;
export type GetTipPlayListsByClassLazyQueryHookResult = ReturnType<typeof useGetTipPlayListsByClassLazyQuery>;
export type GetTipPlayListsByClassQueryResult = Apollo.QueryResult<Types.GetTipPlayListsByClassQuery, Types.GetTipPlayListsByClassQueryVariables>;
export function refetchGetTipPlayListsByClassQuery(variables?: Types.GetTipPlayListsByClassQueryVariables) {
      return { query: GetTipPlayListsByClassDocument, variables: variables }
    }
export const GetClassSequenceDocument = gql`
    query GetClassSequence($classId: Int!, $limit: Int, $orderTitle: order_by = asc, $orderDate: order_by) {
  class_by_pk(class_id: $classId) {
    class_id
    class_sequences(
      limit: $limit
      order_by: {sequence: {name: $orderTitle, created_at: $orderDate}}
    ) {
      class_sequence_id
      sequence {
        ...SequenceFragment
        sequence_lesson_plans(where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}) {
          lesson_plan {
            assignment_created(args: {classid: $classId})
            module_lesson_plans_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
    class_sequences_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${SequenceFragmentFragmentDoc}`;

/**
 * __useGetClassSequenceQuery__
 *
 * To run a query within a React component, call `useGetClassSequenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassSequenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassSequenceQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetClassSequenceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassSequenceQuery, Types.GetClassSequenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassSequenceQuery, Types.GetClassSequenceQueryVariables>(GetClassSequenceDocument, options);
      }
export function useGetClassSequenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassSequenceQuery, Types.GetClassSequenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassSequenceQuery, Types.GetClassSequenceQueryVariables>(GetClassSequenceDocument, options);
        }
export type GetClassSequenceQueryHookResult = ReturnType<typeof useGetClassSequenceQuery>;
export type GetClassSequenceLazyQueryHookResult = ReturnType<typeof useGetClassSequenceLazyQuery>;
export type GetClassSequenceQueryResult = Apollo.QueryResult<Types.GetClassSequenceQuery, Types.GetClassSequenceQueryVariables>;
export function refetchGetClassSequenceQuery(variables?: Types.GetClassSequenceQueryVariables) {
      return { query: GetClassSequenceDocument, variables: variables }
    }
export const GetPlaylistDetailsByClassDocument = gql`
    query GetPlaylistDetailsByClass($teacherId: Int!, $classId: Int!, $playlistId: Int!) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    class_teachers(where: {class_id: {_eq: $classId}}) {
      class_teacher_id
      class {
        class_id
        class_playlists(
          order_by: {playlist: {name: asc}}
          where: {playlist_id: {_eq: $playlistId}}
        ) {
          class_playlist_id
          playlist {
            ...playlistReducedFragment
          }
        }
        class_playlists_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    ${PlaylistReducedFragmentFragmentDoc}`;

/**
 * __useGetPlaylistDetailsByClassQuery__
 *
 * To run a query within a React component, call `useGetPlaylistDetailsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistDetailsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistDetailsByClassQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useGetPlaylistDetailsByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPlaylistDetailsByClassQuery, Types.GetPlaylistDetailsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPlaylistDetailsByClassQuery, Types.GetPlaylistDetailsByClassQueryVariables>(GetPlaylistDetailsByClassDocument, options);
      }
export function useGetPlaylistDetailsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlaylistDetailsByClassQuery, Types.GetPlaylistDetailsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPlaylistDetailsByClassQuery, Types.GetPlaylistDetailsByClassQueryVariables>(GetPlaylistDetailsByClassDocument, options);
        }
export type GetPlaylistDetailsByClassQueryHookResult = ReturnType<typeof useGetPlaylistDetailsByClassQuery>;
export type GetPlaylistDetailsByClassLazyQueryHookResult = ReturnType<typeof useGetPlaylistDetailsByClassLazyQuery>;
export type GetPlaylistDetailsByClassQueryResult = Apollo.QueryResult<Types.GetPlaylistDetailsByClassQuery, Types.GetPlaylistDetailsByClassQueryVariables>;
export function refetchGetPlaylistDetailsByClassQuery(variables?: Types.GetPlaylistDetailsByClassQueryVariables) {
      return { query: GetPlaylistDetailsByClassDocument, variables: variables }
    }
export const GetSongsByClassDocument = gql`
    query GetSongsByClass($classId: Int!, $teacherId: Int!, $orderTitle: order_by = asc, $createdAt: order_by) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    class_teachers(where: {class_id: {_eq: $classId}}) {
      class_teacher_id
      class {
        class_id
        class_songs(
          where: {_and: [{song: {song_id: {_is_null: false}}}, {song: {active: {_eq: true}}}]}
          order_by: {song: {title: $orderTitle, created_at: $createdAt}}
        ) {
          class_song_id
          song {
            ...songReducedFragment
            is_favorite(args: {teacherid: $teacherId})
          }
        }
        class_songs_aggregate(
          where: {_and: [{song: {song_id: {_is_null: false}}}, {song: {active: {_eq: true}}}]}
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    ${SongReducedFragmentFragmentDoc}`;

/**
 * __useGetSongsByClassQuery__
 *
 * To run a query within a React component, call `useGetSongsByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongsByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongsByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *      orderTitle: // value for 'orderTitle'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useGetSongsByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongsByClassQuery, Types.GetSongsByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongsByClassQuery, Types.GetSongsByClassQueryVariables>(GetSongsByClassDocument, options);
      }
export function useGetSongsByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongsByClassQuery, Types.GetSongsByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongsByClassQuery, Types.GetSongsByClassQueryVariables>(GetSongsByClassDocument, options);
        }
export type GetSongsByClassQueryHookResult = ReturnType<typeof useGetSongsByClassQuery>;
export type GetSongsByClassLazyQueryHookResult = ReturnType<typeof useGetSongsByClassLazyQuery>;
export type GetSongsByClassQueryResult = Apollo.QueryResult<Types.GetSongsByClassQuery, Types.GetSongsByClassQueryVariables>;
export function refetchGetSongsByClassQuery(variables?: Types.GetSongsByClassQueryVariables) {
      return { query: GetSongsByClassDocument, variables: variables }
    }
export const GetWurrliesByClassDocument = gql`
    query GetWurrliesByClass($classId: Int!, $teacherId: Int!, $limit: Int, $offset: Int, $orderTitle: order_by, $orderDate: order_by) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    class_teachers(where: {class_id: {_eq: $classId}}) {
      class_teacher_id
      class {
        class_id
        wurrlies(
          where: {class_id: {_eq: $classId}}
          limit: $limit
          offset: $offset
          order_by: {created_at: $orderDate, track: {song: {title: $orderTitle}}}
        ) {
          ...wurrliesReducedFragment
          student {
            ...StudentBasicFragment
          }
          track {
            ...trackExtendedFragment
          }
          teacher_wurrly_comments(where: {teacher_id: {_eq: $teacherId}}) {
            teacher_id
            teacher_wurrly_comment_id
            comment
          }
        }
        wurrlies_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    ${WurrliesReducedFragmentFragmentDoc}
${StudentBasicFragmentFragmentDoc}
${TrackExtendedFragmentFragmentDoc}`;

/**
 * __useGetWurrliesByClassQuery__
 *
 * To run a query within a React component, call `useGetWurrliesByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetWurrliesByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesByClassQuery, Types.GetWurrliesByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesByClassQuery, Types.GetWurrliesByClassQueryVariables>(GetWurrliesByClassDocument, options);
      }
export function useGetWurrliesByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesByClassQuery, Types.GetWurrliesByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesByClassQuery, Types.GetWurrliesByClassQueryVariables>(GetWurrliesByClassDocument, options);
        }
export type GetWurrliesByClassQueryHookResult = ReturnType<typeof useGetWurrliesByClassQuery>;
export type GetWurrliesByClassLazyQueryHookResult = ReturnType<typeof useGetWurrliesByClassLazyQuery>;
export type GetWurrliesByClassQueryResult = Apollo.QueryResult<Types.GetWurrliesByClassQuery, Types.GetWurrliesByClassQueryVariables>;
export function refetchGetWurrliesByClassQuery(variables?: Types.GetWurrliesByClassQueryVariables) {
      return { query: GetWurrliesByClassDocument, variables: variables }
    }
export const GetStandardsCoveredBySavedLessonsDocument = gql`
    query GetStandardsCoveredBySavedLessons($classId: Int!, $teacherId: Int) {
  class_by_pk(class_id: $classId) {
    class_id
    class_lesson_plans(where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}) {
      lesson_plan {
        title
        process
        is_favorite(args: {teacherid: $teacherId})
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        lesson_plan_id
        title
        description
        image_path
        inspire
        practice
        process
        record
        reflect
        take_it_further
        suggested_assignments
        differentiations
        materials_needed
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        lesson_plan_catalog_item {
          catalog_item {
            catalog {
              name
            }
            catalog_id
            name
          }
        }
      }
    }
    class_modules(
      where: {module: {module_id: {_is_null: false}, module_lesson_plan: {lesson_plan: {lesson_plan_id: {_is_null: false}}}}}
    ) {
      module {
        module_lesson_plan {
          lesson_plan {
            process
            module_lesson_plans_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetStandardsCoveredBySavedLessonsQuery__
 *
 * To run a query within a React component, call `useGetStandardsCoveredBySavedLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardsCoveredBySavedLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardsCoveredBySavedLessonsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetStandardsCoveredBySavedLessonsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStandardsCoveredBySavedLessonsQuery, Types.GetStandardsCoveredBySavedLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStandardsCoveredBySavedLessonsQuery, Types.GetStandardsCoveredBySavedLessonsQueryVariables>(GetStandardsCoveredBySavedLessonsDocument, options);
      }
export function useGetStandardsCoveredBySavedLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStandardsCoveredBySavedLessonsQuery, Types.GetStandardsCoveredBySavedLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStandardsCoveredBySavedLessonsQuery, Types.GetStandardsCoveredBySavedLessonsQueryVariables>(GetStandardsCoveredBySavedLessonsDocument, options);
        }
export type GetStandardsCoveredBySavedLessonsQueryHookResult = ReturnType<typeof useGetStandardsCoveredBySavedLessonsQuery>;
export type GetStandardsCoveredBySavedLessonsLazyQueryHookResult = ReturnType<typeof useGetStandardsCoveredBySavedLessonsLazyQuery>;
export type GetStandardsCoveredBySavedLessonsQueryResult = Apollo.QueryResult<Types.GetStandardsCoveredBySavedLessonsQuery, Types.GetStandardsCoveredBySavedLessonsQueryVariables>;
export function refetchGetStandardsCoveredBySavedLessonsQuery(variables?: Types.GetStandardsCoveredBySavedLessonsQueryVariables) {
      return { query: GetStandardsCoveredBySavedLessonsDocument, variables: variables }
    }
export const GetSkillsCoveredBySavedLessonsDocument = gql`
    query GetSkillsCoveredBySavedLessons($classId: Int!) {
  class_by_pk(class_id: $classId) {
    class_id
    social_awareness: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: social_awareness}}}}}
    ) {
      aggregate {
        count
      }
    }
    self_awareness: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: self_awareness}}}}}
    ) {
      aggregate {
        count
      }
    }
    self_management: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: self_management}}}}}
    ) {
      aggregate {
        count
      }
    }
    responsible_decision_making: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: responsible_decision_making}}}}}
    ) {
      aggregate {
        count
      }
    }
    relationship_skills: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: relationship_skills}}}}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetSkillsCoveredBySavedLessonsQuery__
 *
 * To run a query within a React component, call `useGetSkillsCoveredBySavedLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillsCoveredBySavedLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillsCoveredBySavedLessonsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetSkillsCoveredBySavedLessonsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSkillsCoveredBySavedLessonsQuery, Types.GetSkillsCoveredBySavedLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSkillsCoveredBySavedLessonsQuery, Types.GetSkillsCoveredBySavedLessonsQueryVariables>(GetSkillsCoveredBySavedLessonsDocument, options);
      }
export function useGetSkillsCoveredBySavedLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSkillsCoveredBySavedLessonsQuery, Types.GetSkillsCoveredBySavedLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSkillsCoveredBySavedLessonsQuery, Types.GetSkillsCoveredBySavedLessonsQueryVariables>(GetSkillsCoveredBySavedLessonsDocument, options);
        }
export type GetSkillsCoveredBySavedLessonsQueryHookResult = ReturnType<typeof useGetSkillsCoveredBySavedLessonsQuery>;
export type GetSkillsCoveredBySavedLessonsLazyQueryHookResult = ReturnType<typeof useGetSkillsCoveredBySavedLessonsLazyQuery>;
export type GetSkillsCoveredBySavedLessonsQueryResult = Apollo.QueryResult<Types.GetSkillsCoveredBySavedLessonsQuery, Types.GetSkillsCoveredBySavedLessonsQueryVariables>;
export function refetchGetSkillsCoveredBySavedLessonsQuery(variables?: Types.GetSkillsCoveredBySavedLessonsQueryVariables) {
      return { query: GetSkillsCoveredBySavedLessonsDocument, variables: variables }
    }
export const GetClassesNamesDocument = gql`
    query GetClassesNames($teacherId: Int!) {
  class(where: {class_teachers: {teacher_id: {_eq: $teacherId}}}) {
    title
    class_id
  }
}
    `;

/**
 * __useGetClassesNamesQuery__
 *
 * To run a query within a React component, call `useGetClassesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesNamesQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetClassesNamesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassesNamesQuery, Types.GetClassesNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassesNamesQuery, Types.GetClassesNamesQueryVariables>(GetClassesNamesDocument, options);
      }
export function useGetClassesNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassesNamesQuery, Types.GetClassesNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassesNamesQuery, Types.GetClassesNamesQueryVariables>(GetClassesNamesDocument, options);
        }
export type GetClassesNamesQueryHookResult = ReturnType<typeof useGetClassesNamesQuery>;
export type GetClassesNamesLazyQueryHookResult = ReturnType<typeof useGetClassesNamesLazyQuery>;
export type GetClassesNamesQueryResult = Apollo.QueryResult<Types.GetClassesNamesQuery, Types.GetClassesNamesQueryVariables>;
export function refetchGetClassesNamesQuery(variables?: Types.GetClassesNamesQueryVariables) {
      return { query: GetClassesNamesDocument, variables: variables }
    }
export const GetClassNameByPkDocument = gql`
    query GetClassNameByPk($classId: Int!) {
  class_by_pk(class_id: $classId) {
    title
    class_id
  }
}
    `;

/**
 * __useGetClassNameByPkQuery__
 *
 * To run a query within a React component, call `useGetClassNameByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassNameByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassNameByPkQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassNameByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassNameByPkQuery, Types.GetClassNameByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassNameByPkQuery, Types.GetClassNameByPkQueryVariables>(GetClassNameByPkDocument, options);
      }
export function useGetClassNameByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassNameByPkQuery, Types.GetClassNameByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassNameByPkQuery, Types.GetClassNameByPkQueryVariables>(GetClassNameByPkDocument, options);
        }
export type GetClassNameByPkQueryHookResult = ReturnType<typeof useGetClassNameByPkQuery>;
export type GetClassNameByPkLazyQueryHookResult = ReturnType<typeof useGetClassNameByPkLazyQuery>;
export type GetClassNameByPkQueryResult = Apollo.QueryResult<Types.GetClassNameByPkQuery, Types.GetClassNameByPkQueryVariables>;
export function refetchGetClassNameByPkQuery(variables?: Types.GetClassNameByPkQueryVariables) {
      return { query: GetClassNameByPkDocument, variables: variables }
    }
export const GetNonMusicalSkillsLessonsCoveredByClassIdDocument = gql`
    query GetNonMusicalSkillsLessonsCoveredByClassId($classId: Int!, $teacherId: Int!) {
  class_by_pk(class_id: $classId) {
    class_id
    social_awareness: class_lesson_plans(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: social_awareness}}}}}
    ) {
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        lesson_plan_non_musical_skill(
          where: {non_musical_skill: {type: {_eq: social_awareness}}}
        ) {
          non_musical_skill {
            type
            name
          }
        }
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        ...LessonPlanReducedFragment
      }
    }
    self_awareness: class_lesson_plans(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: self_awareness}}}}}
    ) {
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        lesson_plan_non_musical_skill(
          where: {non_musical_skill: {type: {_eq: self_awareness}}}
        ) {
          non_musical_skill {
            type
            name
          }
        }
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        ...LessonPlanReducedFragment
      }
    }
    self_management: class_lesson_plans(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: self_management}}}}}
    ) {
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        lesson_plan_non_musical_skill(
          where: {non_musical_skill: {type: {_eq: self_management}}}
        ) {
          non_musical_skill {
            type
            name
          }
        }
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        ...LessonPlanReducedFragment
      }
    }
    responsible_decision_making: class_lesson_plans(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: responsible_decision_making}}}}}
    ) {
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        lesson_plan_non_musical_skill(
          where: {non_musical_skill: {type: {_eq: responsible_decision_making}}}
        ) {
          non_musical_skill {
            type
            name
          }
        }
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        ...LessonPlanReducedFragment
      }
    }
    relationship_skills: class_lesson_plans(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: relationship_skills}}}}}
    ) {
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        lesson_plan_non_musical_skill(
          where: {non_musical_skill: {type: {_eq: relationship_skills}}}
        ) {
          non_musical_skill {
            type
            name
          }
        }
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        ...LessonPlanReducedFragment
      }
    }
  }
}
    ${LessonPlanReducedFragmentFragmentDoc}`;

/**
 * __useGetNonMusicalSkillsLessonsCoveredByClassIdQuery__
 *
 * To run a query within a React component, call `useGetNonMusicalSkillsLessonsCoveredByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonMusicalSkillsLessonsCoveredByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonMusicalSkillsLessonsCoveredByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetNonMusicalSkillsLessonsCoveredByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNonMusicalSkillsLessonsCoveredByClassIdQuery, Types.GetNonMusicalSkillsLessonsCoveredByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNonMusicalSkillsLessonsCoveredByClassIdQuery, Types.GetNonMusicalSkillsLessonsCoveredByClassIdQueryVariables>(GetNonMusicalSkillsLessonsCoveredByClassIdDocument, options);
      }
export function useGetNonMusicalSkillsLessonsCoveredByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNonMusicalSkillsLessonsCoveredByClassIdQuery, Types.GetNonMusicalSkillsLessonsCoveredByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNonMusicalSkillsLessonsCoveredByClassIdQuery, Types.GetNonMusicalSkillsLessonsCoveredByClassIdQueryVariables>(GetNonMusicalSkillsLessonsCoveredByClassIdDocument, options);
        }
export type GetNonMusicalSkillsLessonsCoveredByClassIdQueryHookResult = ReturnType<typeof useGetNonMusicalSkillsLessonsCoveredByClassIdQuery>;
export type GetNonMusicalSkillsLessonsCoveredByClassIdLazyQueryHookResult = ReturnType<typeof useGetNonMusicalSkillsLessonsCoveredByClassIdLazyQuery>;
export type GetNonMusicalSkillsLessonsCoveredByClassIdQueryResult = Apollo.QueryResult<Types.GetNonMusicalSkillsLessonsCoveredByClassIdQuery, Types.GetNonMusicalSkillsLessonsCoveredByClassIdQueryVariables>;
export function refetchGetNonMusicalSkillsLessonsCoveredByClassIdQuery(variables?: Types.GetNonMusicalSkillsLessonsCoveredByClassIdQueryVariables) {
      return { query: GetNonMusicalSkillsLessonsCoveredByClassIdDocument, variables: variables }
    }
export const GetClassImagesDocument = gql`
    query getClassImages {
  class_images {
    id: class_image_id
    imagePath: image_path
  }
}
    `;

/**
 * __useGetClassImagesQuery__
 *
 * To run a query within a React component, call `useGetClassImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassImagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetClassImagesQuery, Types.GetClassImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassImagesQuery, Types.GetClassImagesQueryVariables>(GetClassImagesDocument, options);
      }
export function useGetClassImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassImagesQuery, Types.GetClassImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassImagesQuery, Types.GetClassImagesQueryVariables>(GetClassImagesDocument, options);
        }
export type GetClassImagesQueryHookResult = ReturnType<typeof useGetClassImagesQuery>;
export type GetClassImagesLazyQueryHookResult = ReturnType<typeof useGetClassImagesLazyQuery>;
export type GetClassImagesQueryResult = Apollo.QueryResult<Types.GetClassImagesQuery, Types.GetClassImagesQueryVariables>;
export function refetchGetClassImagesQuery(variables?: Types.GetClassImagesQueryVariables) {
      return { query: GetClassImagesDocument, variables: variables }
    }
export const GetClassNameDocument = gql`
    query GetClassName($classId: Int!) {
  class_by_pk(class_id: $classId) {
    class_id
    title
  }
}
    `;

/**
 * __useGetClassNameQuery__
 *
 * To run a query within a React component, call `useGetClassNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassNameQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassNameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassNameQuery, Types.GetClassNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassNameQuery, Types.GetClassNameQueryVariables>(GetClassNameDocument, options);
      }
export function useGetClassNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassNameQuery, Types.GetClassNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassNameQuery, Types.GetClassNameQueryVariables>(GetClassNameDocument, options);
        }
export type GetClassNameQueryHookResult = ReturnType<typeof useGetClassNameQuery>;
export type GetClassNameLazyQueryHookResult = ReturnType<typeof useGetClassNameLazyQuery>;
export type GetClassNameQueryResult = Apollo.QueryResult<Types.GetClassNameQuery, Types.GetClassNameQueryVariables>;
export function refetchGetClassNameQuery(variables?: Types.GetClassNameQueryVariables) {
      return { query: GetClassNameDocument, variables: variables }
    }
export const UpdateClassAssignmentStatusDocument = gql`
    mutation UpdateClassAssignmentStatus($classAssignmentId: Int!, $status: class_assignment_status_enum!) {
  update_class_assignment_by_pk(
    pk_columns: {class_assignment_id: $classAssignmentId}
    _set: {status: $status}
  ) {
    class_assignment_id
  }
}
    `;
export type UpdateClassAssignmentStatusMutationFn = Apollo.MutationFunction<Types.UpdateClassAssignmentStatusMutation, Types.UpdateClassAssignmentStatusMutationVariables>;

/**
 * __useUpdateClassAssignmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateClassAssignmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassAssignmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassAssignmentStatusMutation, { data, loading, error }] = useUpdateClassAssignmentStatusMutation({
 *   variables: {
 *      classAssignmentId: // value for 'classAssignmentId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateClassAssignmentStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClassAssignmentStatusMutation, Types.UpdateClassAssignmentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateClassAssignmentStatusMutation, Types.UpdateClassAssignmentStatusMutationVariables>(UpdateClassAssignmentStatusDocument, options);
      }
export type UpdateClassAssignmentStatusMutationHookResult = ReturnType<typeof useUpdateClassAssignmentStatusMutation>;
export type UpdateClassAssignmentStatusMutationResult = Apollo.MutationResult<Types.UpdateClassAssignmentStatusMutation>;
export type UpdateClassAssignmentStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClassAssignmentStatusMutation, Types.UpdateClassAssignmentStatusMutationVariables>;
export const GetClassAssignmentsExtendedDocument = gql`
    query GetClassAssignmentsExtended($classId: Int, $offset: Int, $limit: Int, $order: order_by = asc) {
  class_assignment(
    where: {class_id: {_eq: $classId}}
    offset: $offset
    limit: $limit
    order_by: {assignment: {name: $order}}
  ) {
    ...ClassAssignmentFragment
    status
    assignment {
      assignment_id
      name
      instructions
      due_date
      due_time
    }
    submission {
      state
      grade
      submission_id
      submission_students_aggregate {
        aggregate {
          count
        }
      }
    }
    submission_aggregate {
      aggregate {
        count
      }
    }
  }
  class_assignment_aggregate(where: {class_id: {_eq: $classId}}) {
    aggregate {
      count
    }
  }
  class_student_aggregate(where: {class_id: {_eq: $classId}}) {
    aggregate {
      count
    }
  }
}
    ${ClassAssignmentFragmentFragmentDoc}`;

/**
 * __useGetClassAssignmentsExtendedQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsExtendedQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetClassAssignmentsExtendedQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetClassAssignmentsExtendedQuery, Types.GetClassAssignmentsExtendedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentsExtendedQuery, Types.GetClassAssignmentsExtendedQueryVariables>(GetClassAssignmentsExtendedDocument, options);
      }
export function useGetClassAssignmentsExtendedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentsExtendedQuery, Types.GetClassAssignmentsExtendedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentsExtendedQuery, Types.GetClassAssignmentsExtendedQueryVariables>(GetClassAssignmentsExtendedDocument, options);
        }
export type GetClassAssignmentsExtendedQueryHookResult = ReturnType<typeof useGetClassAssignmentsExtendedQuery>;
export type GetClassAssignmentsExtendedLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentsExtendedLazyQuery>;
export type GetClassAssignmentsExtendedQueryResult = Apollo.QueryResult<Types.GetClassAssignmentsExtendedQuery, Types.GetClassAssignmentsExtendedQueryVariables>;
export function refetchGetClassAssignmentsExtendedQuery(variables?: Types.GetClassAssignmentsExtendedQueryVariables) {
      return { query: GetClassAssignmentsExtendedDocument, variables: variables }
    }
export const GetClassAssignmentsCountDocument = gql`
    query GetClassAssignmentsCount($classId: Int!, $studentId: Int!) {
  submission_aggregate(
    where: {_and: [{class_assignment: {class_id: {_eq: $classId}, assignment: {due_date: {_gte: "now()"}}}}, {submission_students: {student_id: {_eq: $studentId}}}, {state: {_in: [start, in_progress]}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetClassAssignmentsCountQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsCountQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetClassAssignmentsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassAssignmentsCountQuery, Types.GetClassAssignmentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentsCountQuery, Types.GetClassAssignmentsCountQueryVariables>(GetClassAssignmentsCountDocument, options);
      }
export function useGetClassAssignmentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentsCountQuery, Types.GetClassAssignmentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentsCountQuery, Types.GetClassAssignmentsCountQueryVariables>(GetClassAssignmentsCountDocument, options);
        }
export type GetClassAssignmentsCountQueryHookResult = ReturnType<typeof useGetClassAssignmentsCountQuery>;
export type GetClassAssignmentsCountLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentsCountLazyQuery>;
export type GetClassAssignmentsCountQueryResult = Apollo.QueryResult<Types.GetClassAssignmentsCountQuery, Types.GetClassAssignmentsCountQueryVariables>;
export function refetchGetClassAssignmentsCountQuery(variables?: Types.GetClassAssignmentsCountQueryVariables) {
      return { query: GetClassAssignmentsCountDocument, variables: variables }
    }
export const GetClassAssignmentsLastWeekCountDocument = gql`
    query GetClassAssignmentsLastWeekCount($classId: Int!, $date: timestamptz!) {
  completedLastWeekAssignments: class_assignment_aggregate(
    where: {class_id: {_eq: $classId}, status: {_eq: graded}, updated_at: {_lte: $date}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetClassAssignmentsLastWeekCountQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsLastWeekCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsLastWeekCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsLastWeekCountQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetClassAssignmentsLastWeekCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassAssignmentsLastWeekCountQuery, Types.GetClassAssignmentsLastWeekCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentsLastWeekCountQuery, Types.GetClassAssignmentsLastWeekCountQueryVariables>(GetClassAssignmentsLastWeekCountDocument, options);
      }
export function useGetClassAssignmentsLastWeekCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentsLastWeekCountQuery, Types.GetClassAssignmentsLastWeekCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentsLastWeekCountQuery, Types.GetClassAssignmentsLastWeekCountQueryVariables>(GetClassAssignmentsLastWeekCountDocument, options);
        }
export type GetClassAssignmentsLastWeekCountQueryHookResult = ReturnType<typeof useGetClassAssignmentsLastWeekCountQuery>;
export type GetClassAssignmentsLastWeekCountLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentsLastWeekCountLazyQuery>;
export type GetClassAssignmentsLastWeekCountQueryResult = Apollo.QueryResult<Types.GetClassAssignmentsLastWeekCountQuery, Types.GetClassAssignmentsLastWeekCountQueryVariables>;
export function refetchGetClassAssignmentsLastWeekCountQuery(variables?: Types.GetClassAssignmentsLastWeekCountQueryVariables) {
      return { query: GetClassAssignmentsLastWeekCountDocument, variables: variables }
    }
export const GetStudentClassAssignmentsDocument = gql`
    query GetStudentClassAssignments($classId: Int!, $studentId: Int!, $fromDate: timestamptz!, $toDate: timestamptz, $comparisonToDate: timestamptz, $comparisonFromDate: timestamptz) {
  class(where: {class_id: {_eq: $classId}}) {
    total: class_assignments_aggregate(
      where: {_and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}]}
    ) {
      aggregate {
        count
      }
    }
    submmited: class_assignments(
      where: {_and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}, {submission: {state: {_in: [graded, submitted]}, submission_students: {student_id: {_eq: $studentId}}}}]}
    ) {
      assignment_id
    }
    submmitedComparison: class_assignments(
      where: {_and: [{created_at: {_gte: $comparisonFromDate}}, {created_at: {_lte: $comparisonToDate}}, {submission: {state: {_in: [graded, submitted]}, submission_students: {student_id: {_eq: $studentId}}}}]}
    ) {
      assignment_id
    }
  }
}
    `;

/**
 * __useGetStudentClassAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetStudentClassAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentClassAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentClassAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      comparisonToDate: // value for 'comparisonToDate'
 *      comparisonFromDate: // value for 'comparisonFromDate'
 *   },
 * });
 */
export function useGetStudentClassAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentClassAssignmentsQuery, Types.GetStudentClassAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentClassAssignmentsQuery, Types.GetStudentClassAssignmentsQueryVariables>(GetStudentClassAssignmentsDocument, options);
      }
export function useGetStudentClassAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentClassAssignmentsQuery, Types.GetStudentClassAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentClassAssignmentsQuery, Types.GetStudentClassAssignmentsQueryVariables>(GetStudentClassAssignmentsDocument, options);
        }
export type GetStudentClassAssignmentsQueryHookResult = ReturnType<typeof useGetStudentClassAssignmentsQuery>;
export type GetStudentClassAssignmentsLazyQueryHookResult = ReturnType<typeof useGetStudentClassAssignmentsLazyQuery>;
export type GetStudentClassAssignmentsQueryResult = Apollo.QueryResult<Types.GetStudentClassAssignmentsQuery, Types.GetStudentClassAssignmentsQueryVariables>;
export function refetchGetStudentClassAssignmentsQuery(variables?: Types.GetStudentClassAssignmentsQueryVariables) {
      return { query: GetStudentClassAssignmentsDocument, variables: variables }
    }
export const GetClassAssignmentsDocument = gql`
    query GetClassAssignments($classId: Int!, $fromDate: timestamptz!, $toDate: timestamptz, $comparisonToDate: timestamptz, $comparisonFromDate: timestamptz) {
  class(where: {class_id: {_eq: $classId}}) {
    total: class_assignments_aggregate(
      where: {_and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}]}
    ) {
      aggregate {
        count
      }
    }
    submmited: class_assignments_aggregate(
      where: {_and: [{updated_at: {_gte: $fromDate}}, {updated_at: {_lte: $toDate}}, {status: {_eq: graded}}]}
    ) {
      aggregate {
        count
      }
    }
    submmitedComparison: class_assignments_aggregate(
      where: {_and: [{updated_at: {_gte: $comparisonFromDate}}, {updated_at: {_lte: $comparisonToDate}}, {status: {_eq: graded}}]}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetClassAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      comparisonToDate: // value for 'comparisonToDate'
 *      comparisonFromDate: // value for 'comparisonFromDate'
 *   },
 * });
 */
export function useGetClassAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassAssignmentsQuery, Types.GetClassAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentsQuery, Types.GetClassAssignmentsQueryVariables>(GetClassAssignmentsDocument, options);
      }
export function useGetClassAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentsQuery, Types.GetClassAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentsQuery, Types.GetClassAssignmentsQueryVariables>(GetClassAssignmentsDocument, options);
        }
export type GetClassAssignmentsQueryHookResult = ReturnType<typeof useGetClassAssignmentsQuery>;
export type GetClassAssignmentsLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentsLazyQuery>;
export type GetClassAssignmentsQueryResult = Apollo.QueryResult<Types.GetClassAssignmentsQuery, Types.GetClassAssignmentsQueryVariables>;
export function refetchGetClassAssignmentsQuery(variables?: Types.GetClassAssignmentsQueryVariables) {
      return { query: GetClassAssignmentsDocument, variables: variables }
    }
export const GetNotGradedAssignmentsDocument = gql`
    query GetNotGradedAssignments($classId: Int!, $offset: Int, $limit: Int, $toDate: date, $fromDate: date, $excludedSubmissionPrivacy: privacy_enum = only_me, $order_by: [class_assignment_order_by!]) {
  class_assignment(
    where: {_or: [{submission: {state: {_in: [start, submitted, in_progress]}}}, {_not: {submission: {}}}], _and: [{class_id: {_eq: $classId}}, {assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}], submission: {submission_wurrlies: {wurrly: {privacy: {_neq: only_me}}}}}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    class_assignment_id
    class_id
    status
    assignment {
      assignment_id
      name
      instructions
      due_date
      due_time
    }
    submission {
      state
      grade
      submission_id
      submission_students_aggregate {
        aggregate {
          count
        }
      }
    }
    submission_aggregate {
      aggregate {
        count
      }
    }
  }
  class_assignment_aggregate(
    where: {_or: [{submission: {state: {_in: [start, submitted, in_progress]}}}, {_not: {submission: {}}}], _and: [{class_id: {_eq: $classId}}, {assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}], submission: {submission_wurrlies: {wurrly: {privacy: {_neq: only_me}}}}}
    order_by: $order_by
  ) {
    aggregate {
      count
    }
  }
  class_by_pk(class_id: $classId) {
    title
    class_students_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetNotGradedAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetNotGradedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotGradedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotGradedAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *      excludedSubmissionPrivacy: // value for 'excludedSubmissionPrivacy'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNotGradedAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNotGradedAssignmentsQuery, Types.GetNotGradedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNotGradedAssignmentsQuery, Types.GetNotGradedAssignmentsQueryVariables>(GetNotGradedAssignmentsDocument, options);
      }
export function useGetNotGradedAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotGradedAssignmentsQuery, Types.GetNotGradedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNotGradedAssignmentsQuery, Types.GetNotGradedAssignmentsQueryVariables>(GetNotGradedAssignmentsDocument, options);
        }
export type GetNotGradedAssignmentsQueryHookResult = ReturnType<typeof useGetNotGradedAssignmentsQuery>;
export type GetNotGradedAssignmentsLazyQueryHookResult = ReturnType<typeof useGetNotGradedAssignmentsLazyQuery>;
export type GetNotGradedAssignmentsQueryResult = Apollo.QueryResult<Types.GetNotGradedAssignmentsQuery, Types.GetNotGradedAssignmentsQueryVariables>;
export function refetchGetNotGradedAssignmentsQuery(variables?: Types.GetNotGradedAssignmentsQueryVariables) {
      return { query: GetNotGradedAssignmentsDocument, variables: variables }
    }
export const GetGradedAssignmentsDocument = gql`
    query GetGradedAssignments($classId: Int!, $offset: Int, $limit: Int, $order_by: [class_assignment_order_by!], $toDate: date, $fromDate: date) {
  class_assignment(
    where: {submission: {}, _not: {submission: {_and: [{state: {_neq: graded}}]}}, class_id: {_eq: $classId}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    class_assignment_id
    class_id
    status
    assignment {
      assignment_id
      name
      instructions
      due_date
      due_time
    }
    submission {
      state
      grade
      submission_id
      submission_students_aggregate {
        aggregate {
          count
        }
      }
    }
    submission_aggregate {
      aggregate {
        count
      }
    }
  }
  class_assignment_aggregate(
    where: {submission: {}, _not: {submission: {_and: [{state: {_neq: graded}}]}}, class_id: {_eq: $classId}, assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}
  ) {
    aggregate {
      count
    }
  }
  class_by_pk(class_id: $classId) {
    title
    class_students_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetGradedAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetGradedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradedAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetGradedAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGradedAssignmentsQuery, Types.GetGradedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGradedAssignmentsQuery, Types.GetGradedAssignmentsQueryVariables>(GetGradedAssignmentsDocument, options);
      }
export function useGetGradedAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGradedAssignmentsQuery, Types.GetGradedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGradedAssignmentsQuery, Types.GetGradedAssignmentsQueryVariables>(GetGradedAssignmentsDocument, options);
        }
export type GetGradedAssignmentsQueryHookResult = ReturnType<typeof useGetGradedAssignmentsQuery>;
export type GetGradedAssignmentsLazyQueryHookResult = ReturnType<typeof useGetGradedAssignmentsLazyQuery>;
export type GetGradedAssignmentsQueryResult = Apollo.QueryResult<Types.GetGradedAssignmentsQuery, Types.GetGradedAssignmentsQueryVariables>;
export function refetchGetGradedAssignmentsQuery(variables?: Types.GetGradedAssignmentsQueryVariables) {
      return { query: GetGradedAssignmentsDocument, variables: variables }
    }
export const GetClassAssignmentDocument = gql`
    query GetClassAssignment($classAssignmentId: Int!) {
  class_assignment_by_pk(class_assignment_id: $classAssignmentId) {
    class_assignment_id
    status
    assignment {
      assignment_id
      due_date
      due_time
      name
      instructions
      grading_system {
        values
        label
      }
    }
    class {
      title
      class_id
    }
  }
}
    `;

/**
 * __useGetClassAssignmentQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentQuery({
 *   variables: {
 *      classAssignmentId: // value for 'classAssignmentId'
 *   },
 * });
 */
export function useGetClassAssignmentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassAssignmentQuery, Types.GetClassAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentQuery, Types.GetClassAssignmentQueryVariables>(GetClassAssignmentDocument, options);
      }
export function useGetClassAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentQuery, Types.GetClassAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentQuery, Types.GetClassAssignmentQueryVariables>(GetClassAssignmentDocument, options);
        }
export type GetClassAssignmentQueryHookResult = ReturnType<typeof useGetClassAssignmentQuery>;
export type GetClassAssignmentLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentLazyQuery>;
export type GetClassAssignmentQueryResult = Apollo.QueryResult<Types.GetClassAssignmentQuery, Types.GetClassAssignmentQueryVariables>;
export function refetchGetClassAssignmentQuery(variables?: Types.GetClassAssignmentQueryVariables) {
      return { query: GetClassAssignmentDocument, variables: variables }
    }
export const GetClassAssignmentByPkDocument = gql`
    query GetClassAssignmentByPk($classAssignmentId: Int!) {
  class_assignment_by_pk(class_assignment_id: $classAssignmentId) {
    submission {
      state
      grade
      submission_id
    }
  }
}
    `;

/**
 * __useGetClassAssignmentByPkQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentByPkQuery({
 *   variables: {
 *      classAssignmentId: // value for 'classAssignmentId'
 *   },
 * });
 */
export function useGetClassAssignmentByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassAssignmentByPkQuery, Types.GetClassAssignmentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentByPkQuery, Types.GetClassAssignmentByPkQueryVariables>(GetClassAssignmentByPkDocument, options);
      }
export function useGetClassAssignmentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentByPkQuery, Types.GetClassAssignmentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentByPkQuery, Types.GetClassAssignmentByPkQueryVariables>(GetClassAssignmentByPkDocument, options);
        }
export type GetClassAssignmentByPkQueryHookResult = ReturnType<typeof useGetClassAssignmentByPkQuery>;
export type GetClassAssignmentByPkLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentByPkLazyQuery>;
export type GetClassAssignmentByPkQueryResult = Apollo.QueryResult<Types.GetClassAssignmentByPkQuery, Types.GetClassAssignmentByPkQueryVariables>;
export function refetchGetClassAssignmentByPkQuery(variables?: Types.GetClassAssignmentByPkQueryVariables) {
      return { query: GetClassAssignmentByPkDocument, variables: variables }
    }
export const GetAssignmentAttachmentsByClassAssignmentIdDocument = gql`
    query GetAssignmentAttachmentsByClassAssignmentId($classAssignmentId: Int!, $teacherId: Int) {
  class_assignment_by_pk(class_assignment_id: $classAssignmentId) {
    class_assignment_id
    assignment {
      assignment_id
      assignment_songs(where: {song: {song_id: {_is_null: false}}}) {
        song {
          song_id
          title
          image_path
          is_favorite(args: {teacherid: $teacherId})
          artist {
            name
          }
        }
      }
      assignment_tips(where: {tip: {tip_id: {_is_null: false}}}) {
        tip {
          tip_id
          title
          description
          image_path
          resource_path
          is_favorite(args: {teacherid: $teacherId})
          tip_catalog_item {
            catalog_item {
              catalog {
                name
              }
            }
          }
        }
      }
      assignment_assets {
        asset {
          asset_id
          name
          resource_path
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentAttachmentsByClassAssignmentIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentAttachmentsByClassAssignmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentAttachmentsByClassAssignmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentAttachmentsByClassAssignmentIdQuery({
 *   variables: {
 *      classAssignmentId: // value for 'classAssignmentId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetAssignmentAttachmentsByClassAssignmentIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssignmentAttachmentsByClassAssignmentIdQuery, Types.GetAssignmentAttachmentsByClassAssignmentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssignmentAttachmentsByClassAssignmentIdQuery, Types.GetAssignmentAttachmentsByClassAssignmentIdQueryVariables>(GetAssignmentAttachmentsByClassAssignmentIdDocument, options);
      }
export function useGetAssignmentAttachmentsByClassAssignmentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssignmentAttachmentsByClassAssignmentIdQuery, Types.GetAssignmentAttachmentsByClassAssignmentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssignmentAttachmentsByClassAssignmentIdQuery, Types.GetAssignmentAttachmentsByClassAssignmentIdQueryVariables>(GetAssignmentAttachmentsByClassAssignmentIdDocument, options);
        }
export type GetAssignmentAttachmentsByClassAssignmentIdQueryHookResult = ReturnType<typeof useGetAssignmentAttachmentsByClassAssignmentIdQuery>;
export type GetAssignmentAttachmentsByClassAssignmentIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentAttachmentsByClassAssignmentIdLazyQuery>;
export type GetAssignmentAttachmentsByClassAssignmentIdQueryResult = Apollo.QueryResult<Types.GetAssignmentAttachmentsByClassAssignmentIdQuery, Types.GetAssignmentAttachmentsByClassAssignmentIdQueryVariables>;
export function refetchGetAssignmentAttachmentsByClassAssignmentIdQuery(variables?: Types.GetAssignmentAttachmentsByClassAssignmentIdQueryVariables) {
      return { query: GetAssignmentAttachmentsByClassAssignmentIdDocument, variables: variables }
    }
export const GetSubmissionsByClassAssignmentIdDocument = gql`
    query GetSubmissionsByClassAssignmentId($classAssignmentId: Int!, $limit: Int, $sortByName: order_by, $sortByDate: order_by, $submissionStates: [submission_state_enum!]) {
  submission(
    where: {_and: [{class_assignment_id: {_eq: $classAssignmentId}}, {state: {_in: $submissionStates}}]}
    limit: $limit
    order_by: [{name: $sortByName}, {date_submitted: $sortByDate}]
  ) {
    grade
    submission_id
    date_submitted
    student_comment
    teacher_comment
    name
    state
    submission_assets {
      asset {
        asset_id
        name
        resource_path
      }
    }
    submission_wurrlies(
      where: {wurrly: {_or: [{is_from_legacy: {_eq: true}}, {submission_wurrly: {state: {_eq: submitted}}}]}}
    ) {
      wurrly {
        final_resource_path
        resource_path
        image_path
        audio_url
        is_from_legacy
        message
        processing_status
        is_video_url
        wurrly_type
        student {
          first_name
          last_name
          student_id
          image_path
        }
      }
    }
  }
  submission_aggregate(
    where: {_and: [{class_assignment_id: {_eq: $classAssignmentId}}, {state: {_in: $submissionStates}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSubmissionsByClassAssignmentIdQuery__
 *
 * To run a query within a React component, call `useGetSubmissionsByClassAssignmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionsByClassAssignmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionsByClassAssignmentIdQuery({
 *   variables: {
 *      classAssignmentId: // value for 'classAssignmentId'
 *      limit: // value for 'limit'
 *      sortByName: // value for 'sortByName'
 *      sortByDate: // value for 'sortByDate'
 *      submissionStates: // value for 'submissionStates'
 *   },
 * });
 */
export function useGetSubmissionsByClassAssignmentIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSubmissionsByClassAssignmentIdQuery, Types.GetSubmissionsByClassAssignmentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubmissionsByClassAssignmentIdQuery, Types.GetSubmissionsByClassAssignmentIdQueryVariables>(GetSubmissionsByClassAssignmentIdDocument, options);
      }
export function useGetSubmissionsByClassAssignmentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubmissionsByClassAssignmentIdQuery, Types.GetSubmissionsByClassAssignmentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubmissionsByClassAssignmentIdQuery, Types.GetSubmissionsByClassAssignmentIdQueryVariables>(GetSubmissionsByClassAssignmentIdDocument, options);
        }
export type GetSubmissionsByClassAssignmentIdQueryHookResult = ReturnType<typeof useGetSubmissionsByClassAssignmentIdQuery>;
export type GetSubmissionsByClassAssignmentIdLazyQueryHookResult = ReturnType<typeof useGetSubmissionsByClassAssignmentIdLazyQuery>;
export type GetSubmissionsByClassAssignmentIdQueryResult = Apollo.QueryResult<Types.GetSubmissionsByClassAssignmentIdQuery, Types.GetSubmissionsByClassAssignmentIdQueryVariables>;
export function refetchGetSubmissionsByClassAssignmentIdQuery(variables?: Types.GetSubmissionsByClassAssignmentIdQueryVariables) {
      return { query: GetSubmissionsByClassAssignmentIdDocument, variables: variables }
    }
export const GetStudentCompletedAssignmentsDocument = gql`
    query GetStudentCompletedAssignments($classId: Int!, $studentId: Int!, $fromDate: timestamptz!, $toDate: timestamptz!, $orderAlph: order_by = asc, $orderDate: order_by) {
  class_assignment(
    where: {class_id: {_eq: $classId}, submission: {_and: [{state: {_in: [graded, submitted]}, submission_students: {student_id: {_eq: $studentId}}}]}, _and: [{created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}}]}
    order_by: {assignment: {name: $orderAlph, due_date: $orderDate}}
  ) {
    assignment {
      assignment_id
      name
      instructions
      due_date
      due_time
      grading_system {
        values
      }
    }
    totalSubmitted: submission_aggregate(
      where: {state: {_in: [graded, submitted]}, _and: [{date_submitted: {_gte: $fromDate}}, {date_submitted: {_lte: $toDate}}]}
    ) {
      aggregate {
        count
      }
    }
    totalGraded: submission_aggregate(
      where: {state: {_eq: graded}, _and: [{date_submitted: {_gte: $fromDate}}, {date_submitted: {_lte: $toDate}}]}
    ) {
      aggregate {
        count
      }
    }
    submission(
      where: {submission_students: {student_id: {_eq: $studentId}}, _and: [{date_submitted: {_gte: $fromDate}}, {date_submitted: {_lte: $toDate}}]}
    ) {
      date_submitted
      grade
    }
  }
}
    `;

/**
 * __useGetStudentCompletedAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetStudentCompletedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentCompletedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentCompletedAssignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      orderAlph: // value for 'orderAlph'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetStudentCompletedAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentCompletedAssignmentsQuery, Types.GetStudentCompletedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentCompletedAssignmentsQuery, Types.GetStudentCompletedAssignmentsQueryVariables>(GetStudentCompletedAssignmentsDocument, options);
      }
export function useGetStudentCompletedAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentCompletedAssignmentsQuery, Types.GetStudentCompletedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentCompletedAssignmentsQuery, Types.GetStudentCompletedAssignmentsQueryVariables>(GetStudentCompletedAssignmentsDocument, options);
        }
export type GetStudentCompletedAssignmentsQueryHookResult = ReturnType<typeof useGetStudentCompletedAssignmentsQuery>;
export type GetStudentCompletedAssignmentsLazyQueryHookResult = ReturnType<typeof useGetStudentCompletedAssignmentsLazyQuery>;
export type GetStudentCompletedAssignmentsQueryResult = Apollo.QueryResult<Types.GetStudentCompletedAssignmentsQuery, Types.GetStudentCompletedAssignmentsQueryVariables>;
export function refetchGetStudentCompletedAssignmentsQuery(variables?: Types.GetStudentCompletedAssignmentsQueryVariables) {
      return { query: GetStudentCompletedAssignmentsDocument, variables: variables }
    }
export const GetClassAssignmentAnalyticsDocument = gql`
    query GetClassAssignmentAnalytics($classId: Int!, $fromDate: timestamptz!, $toDate: timestamptz, $comparisonToDate: timestamptz, $comparisonFromDate: timestamptz) {
  total: class_assignment_aggregate(where: {class_id: {_eq: $classId}}) {
    aggregate {
      count
    }
  }
  submitted: class_assignment_aggregate(
    where: {_and: [{class_id: {_eq: $classId}, created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}, submission: {state: {_in: [graded, submitted]}}}]}
  ) {
    aggregate {
      count
    }
  }
  comparisonSubmitted: class_assignment_aggregate(
    where: {_and: [{class_id: {_eq: $classId}, created_at: {_gte: $comparisonFromDate}}, {created_at: {_lte: $comparisonToDate}, submission: {state: {_in: [graded, submitted]}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetClassAssignmentAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetClassAssignmentAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassAssignmentAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassAssignmentAnalyticsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      comparisonToDate: // value for 'comparisonToDate'
 *      comparisonFromDate: // value for 'comparisonFromDate'
 *   },
 * });
 */
export function useGetClassAssignmentAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassAssignmentAnalyticsQuery, Types.GetClassAssignmentAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassAssignmentAnalyticsQuery, Types.GetClassAssignmentAnalyticsQueryVariables>(GetClassAssignmentAnalyticsDocument, options);
      }
export function useGetClassAssignmentAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassAssignmentAnalyticsQuery, Types.GetClassAssignmentAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassAssignmentAnalyticsQuery, Types.GetClassAssignmentAnalyticsQueryVariables>(GetClassAssignmentAnalyticsDocument, options);
        }
export type GetClassAssignmentAnalyticsQueryHookResult = ReturnType<typeof useGetClassAssignmentAnalyticsQuery>;
export type GetClassAssignmentAnalyticsLazyQueryHookResult = ReturnType<typeof useGetClassAssignmentAnalyticsLazyQuery>;
export type GetClassAssignmentAnalyticsQueryResult = Apollo.QueryResult<Types.GetClassAssignmentAnalyticsQuery, Types.GetClassAssignmentAnalyticsQueryVariables>;
export function refetchGetClassAssignmentAnalyticsQuery(variables?: Types.GetClassAssignmentAnalyticsQueryVariables) {
      return { query: GetClassAssignmentAnalyticsDocument, variables: variables }
    }
export const GetCompletedAsignmentsDocument = gql`
    query GetCompletedAsignments($classId: Int!, $offset: Int, $limit: Int, $orderAlph: order_by = asc, $orderDate: order_by, $toDate: timestamptz, $fromDate: timestamptz) {
  class_assignment(
    where: {_and: [{class_id: {_eq: $classId}, created_at: {_gte: $fromDate}}, {created_at: {_lte: $toDate}, _not: {submission: {state: {_nin: [graded, submitted]}}}}], submission: {}}
    offset: $offset
    limit: $limit
    order_by: {assignment: {name: $orderAlph, due_date: $orderDate}}
  ) {
    class_assignment_id
    class_id
    status
    assignment {
      assignment_id
      name
      instructions
      due_date
      due_time
    }
    submission {
      state
      grade
      submission_id
      submission_students_aggregate {
        aggregate {
          count
        }
      }
    }
    submission_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetCompletedAsignmentsQuery__
 *
 * To run a query within a React component, call `useGetCompletedAsignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedAsignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedAsignmentsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderAlph: // value for 'orderAlph'
 *      orderDate: // value for 'orderDate'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetCompletedAsignmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompletedAsignmentsQuery, Types.GetCompletedAsignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompletedAsignmentsQuery, Types.GetCompletedAsignmentsQueryVariables>(GetCompletedAsignmentsDocument, options);
      }
export function useGetCompletedAsignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompletedAsignmentsQuery, Types.GetCompletedAsignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompletedAsignmentsQuery, Types.GetCompletedAsignmentsQueryVariables>(GetCompletedAsignmentsDocument, options);
        }
export type GetCompletedAsignmentsQueryHookResult = ReturnType<typeof useGetCompletedAsignmentsQuery>;
export type GetCompletedAsignmentsLazyQueryHookResult = ReturnType<typeof useGetCompletedAsignmentsLazyQuery>;
export type GetCompletedAsignmentsQueryResult = Apollo.QueryResult<Types.GetCompletedAsignmentsQuery, Types.GetCompletedAsignmentsQueryVariables>;
export function refetchGetCompletedAsignmentsQuery(variables?: Types.GetCompletedAsignmentsQueryVariables) {
      return { query: GetCompletedAsignmentsDocument, variables: variables }
    }
export const GetAssignmentsWithNoSubmissionsDocument = gql`
    query GetAssignmentsWithNoSubmissions($classId: Int!, $offset: Int, $limit: Int, $toDate: date, $fromDate: date, $excludedSubmissionPrivacy: privacy_enum = only_me, $order_by: [class_assignment_order_by!]) {
  class_assignment(
    where: {_and: [{_not: {submission: {state: {_in: [graded, in_progress, submitted]}}}}, {class_id: {_eq: $classId}}, {assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}]}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    class_assignment_id
    class_id
    status
    assignment {
      assignment_id
      name
      instructions
      due_date
      due_time
    }
    submission {
      state
      grade
      submission_id
      submission_students_aggregate {
        aggregate {
          count
        }
      }
    }
    submission_aggregate {
      aggregate {
        count
      }
    }
  }
  class_assignment_aggregate(
    where: {_and: [{_not: {submission: {state: {_in: [graded, in_progress, submitted]}}}}, {class_id: {_eq: $classId}}, {assignment: {due_date: {_lte: $toDate}, _and: {due_date: {_gte: $fromDate}}}}]}
    offset: $offset
    order_by: $order_by
  ) {
    aggregate {
      count
    }
  }
  class_by_pk(class_id: $classId) {
    title
    class_students_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentsWithNoSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsWithNoSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsWithNoSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsWithNoSubmissionsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *      excludedSubmissionPrivacy: // value for 'excludedSubmissionPrivacy'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetAssignmentsWithNoSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssignmentsWithNoSubmissionsQuery, Types.GetAssignmentsWithNoSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssignmentsWithNoSubmissionsQuery, Types.GetAssignmentsWithNoSubmissionsQueryVariables>(GetAssignmentsWithNoSubmissionsDocument, options);
      }
export function useGetAssignmentsWithNoSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssignmentsWithNoSubmissionsQuery, Types.GetAssignmentsWithNoSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssignmentsWithNoSubmissionsQuery, Types.GetAssignmentsWithNoSubmissionsQueryVariables>(GetAssignmentsWithNoSubmissionsDocument, options);
        }
export type GetAssignmentsWithNoSubmissionsQueryHookResult = ReturnType<typeof useGetAssignmentsWithNoSubmissionsQuery>;
export type GetAssignmentsWithNoSubmissionsLazyQueryHookResult = ReturnType<typeof useGetAssignmentsWithNoSubmissionsLazyQuery>;
export type GetAssignmentsWithNoSubmissionsQueryResult = Apollo.QueryResult<Types.GetAssignmentsWithNoSubmissionsQuery, Types.GetAssignmentsWithNoSubmissionsQueryVariables>;
export function refetchGetAssignmentsWithNoSubmissionsQuery(variables?: Types.GetAssignmentsWithNoSubmissionsQueryVariables) {
      return { query: GetAssignmentsWithNoSubmissionsDocument, variables: variables }
    }
export const InsertLessonToClassDocument = gql`
    mutation InsertLessonToClass($classId: Int!, $lessonPlanId: Int!) {
  insert_class_lesson_plan_one(
    object: {class_id: $classId, lesson_plan_id: $lessonPlanId}
  ) {
    class_lesson_plan_id
    lesson_plan_id
    class_id
    class {
      class_id
      class_lesson_plans {
        lesson_plan {
          lesson_plan_id
        }
      }
      class_lesson_plans_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;
export type InsertLessonToClassMutationFn = Apollo.MutationFunction<Types.InsertLessonToClassMutation, Types.InsertLessonToClassMutationVariables>;

/**
 * __useInsertLessonToClassMutation__
 *
 * To run a mutation, you first call `useInsertLessonToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLessonToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLessonToClassMutation, { data, loading, error }] = useInsertLessonToClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useInsertLessonToClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLessonToClassMutation, Types.InsertLessonToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLessonToClassMutation, Types.InsertLessonToClassMutationVariables>(InsertLessonToClassDocument, options);
      }
export type InsertLessonToClassMutationHookResult = ReturnType<typeof useInsertLessonToClassMutation>;
export type InsertLessonToClassMutationResult = Apollo.MutationResult<Types.InsertLessonToClassMutation>;
export type InsertLessonToClassMutationOptions = Apollo.BaseMutationOptions<Types.InsertLessonToClassMutation, Types.InsertLessonToClassMutationVariables>;
export const InsertLessonToClassByLessonPkDocument = gql`
    mutation InsertLessonToClassByLessonPK($classId: Int!, $lessonPlanId: Int!) {
  insert_class_lesson_plan_one(
    object: {class_id: $classId, lesson_plan_id: $lessonPlanId}
  ) {
    lesson_plan {
      lesson_plan_id
      class_lesson_plans {
        class_id
        class_lesson_plan_id
        lesson_plan {
          lesson_plan_id
        }
      }
      class_lesson_plans_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;
export type InsertLessonToClassByLessonPkMutationFn = Apollo.MutationFunction<Types.InsertLessonToClassByLessonPkMutation, Types.InsertLessonToClassByLessonPkMutationVariables>;

/**
 * __useInsertLessonToClassByLessonPkMutation__
 *
 * To run a mutation, you first call `useInsertLessonToClassByLessonPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLessonToClassByLessonPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLessonToClassByLessonPkMutation, { data, loading, error }] = useInsertLessonToClassByLessonPkMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useInsertLessonToClassByLessonPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLessonToClassByLessonPkMutation, Types.InsertLessonToClassByLessonPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLessonToClassByLessonPkMutation, Types.InsertLessonToClassByLessonPkMutationVariables>(InsertLessonToClassByLessonPkDocument, options);
      }
export type InsertLessonToClassByLessonPkMutationHookResult = ReturnType<typeof useInsertLessonToClassByLessonPkMutation>;
export type InsertLessonToClassByLessonPkMutationResult = Apollo.MutationResult<Types.InsertLessonToClassByLessonPkMutation>;
export type InsertLessonToClassByLessonPkMutationOptions = Apollo.BaseMutationOptions<Types.InsertLessonToClassByLessonPkMutation, Types.InsertLessonToClassByLessonPkMutationVariables>;
export const InsertLessonToClassesByLessonPkDocument = gql`
    mutation InsertLessonToClassesByLessonPK($classes: [class_lesson_plan_insert_input!]!) {
  insert_class_lesson_plan(objects: $classes) {
    affected_rows
    returning {
      lesson_plan {
        lesson_plan_id
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
      }
    }
  }
}
    `;
export type InsertLessonToClassesByLessonPkMutationFn = Apollo.MutationFunction<Types.InsertLessonToClassesByLessonPkMutation, Types.InsertLessonToClassesByLessonPkMutationVariables>;

/**
 * __useInsertLessonToClassesByLessonPkMutation__
 *
 * To run a mutation, you first call `useInsertLessonToClassesByLessonPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLessonToClassesByLessonPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLessonToClassesByLessonPkMutation, { data, loading, error }] = useInsertLessonToClassesByLessonPkMutation({
 *   variables: {
 *      classes: // value for 'classes'
 *   },
 * });
 */
export function useInsertLessonToClassesByLessonPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLessonToClassesByLessonPkMutation, Types.InsertLessonToClassesByLessonPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLessonToClassesByLessonPkMutation, Types.InsertLessonToClassesByLessonPkMutationVariables>(InsertLessonToClassesByLessonPkDocument, options);
      }
export type InsertLessonToClassesByLessonPkMutationHookResult = ReturnType<typeof useInsertLessonToClassesByLessonPkMutation>;
export type InsertLessonToClassesByLessonPkMutationResult = Apollo.MutationResult<Types.InsertLessonToClassesByLessonPkMutation>;
export type InsertLessonToClassesByLessonPkMutationOptions = Apollo.BaseMutationOptions<Types.InsertLessonToClassesByLessonPkMutation, Types.InsertLessonToClassesByLessonPkMutationVariables>;
export const DeleteLessonFromClassDocument = gql`
    mutation DeleteLessonFromClass($lessonPlanId: Int!, $classId: Int!) {
  delete_class_lesson_plan(
    where: {class_id: {_eq: $classId}, lesson_plan_id: {_eq: $lessonPlanId}}
  ) {
    affected_rows
    returning {
      class_lesson_plan_id
      lesson_plan_id
      class_id
      class {
        class_id
        class_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type DeleteLessonFromClassMutationFn = Apollo.MutationFunction<Types.DeleteLessonFromClassMutation, Types.DeleteLessonFromClassMutationVariables>;

/**
 * __useDeleteLessonFromClassMutation__
 *
 * To run a mutation, you first call `useDeleteLessonFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonFromClassMutation, { data, loading, error }] = useDeleteLessonFromClassMutation({
 *   variables: {
 *      lessonPlanId: // value for 'lessonPlanId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useDeleteLessonFromClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLessonFromClassMutation, Types.DeleteLessonFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLessonFromClassMutation, Types.DeleteLessonFromClassMutationVariables>(DeleteLessonFromClassDocument, options);
      }
export type DeleteLessonFromClassMutationHookResult = ReturnType<typeof useDeleteLessonFromClassMutation>;
export type DeleteLessonFromClassMutationResult = Apollo.MutationResult<Types.DeleteLessonFromClassMutation>;
export type DeleteLessonFromClassMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLessonFromClassMutation, Types.DeleteLessonFromClassMutationVariables>;
export const DeleteLessonDocument = gql`
    mutation DeleteLesson($lessonPlanId: Int!, $teacherId: Int!) {
  delete_lesson_plan(
    where: {lesson_plan_id: {_eq: $lessonPlanId}, teacher_id: {_eq: $teacherId}, featured: {_eq: false}}
  ) {
    affected_rows
    returning {
      lesson_plan_id
    }
  }
}
    `;
export type DeleteLessonMutationFn = Apollo.MutationFunction<Types.DeleteLessonMutation, Types.DeleteLessonMutationVariables>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      lessonPlanId: // value for 'lessonPlanId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useDeleteLessonMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLessonMutation, Types.DeleteLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLessonMutation, Types.DeleteLessonMutationVariables>(DeleteLessonDocument, options);
      }
export type DeleteLessonMutationHookResult = ReturnType<typeof useDeleteLessonMutation>;
export type DeleteLessonMutationResult = Apollo.MutationResult<Types.DeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLessonMutation, Types.DeleteLessonMutationVariables>;
export const InsertClassLessonDocument = gql`
    mutation InsertClassLesson($classLesson: [class_lesson_plan_insert_input!]!) {
  insert_class_lesson_plan(objects: $classLesson) {
    affected_rows
    returning {
      class_id
      class_lesson_plan_id
    }
  }
}
    `;
export type InsertClassLessonMutationFn = Apollo.MutationFunction<Types.InsertClassLessonMutation, Types.InsertClassLessonMutationVariables>;

/**
 * __useInsertClassLessonMutation__
 *
 * To run a mutation, you first call `useInsertClassLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClassLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClassLessonMutation, { data, loading, error }] = useInsertClassLessonMutation({
 *   variables: {
 *      classLesson: // value for 'classLesson'
 *   },
 * });
 */
export function useInsertClassLessonMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertClassLessonMutation, Types.InsertClassLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertClassLessonMutation, Types.InsertClassLessonMutationVariables>(InsertClassLessonDocument, options);
      }
export type InsertClassLessonMutationHookResult = ReturnType<typeof useInsertClassLessonMutation>;
export type InsertClassLessonMutationResult = Apollo.MutationResult<Types.InsertClassLessonMutation>;
export type InsertClassLessonMutationOptions = Apollo.BaseMutationOptions<Types.InsertClassLessonMutation, Types.InsertClassLessonMutationVariables>;
export const DeleteClassModuleDocument = gql`
    mutation DeleteClassModule($moduleId: Int!, $classId: Int!) {
  delete_class_module(
    where: {class_id: {_eq: $classId}, module_id: {_eq: $moduleId}}
  ) {
    affected_rows
    returning {
      class {
        class_id
        class_modules(order_by: {module: {title: asc}}) {
          class_module_id
          module {
            module_id
          }
        }
        class_modules_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type DeleteClassModuleMutationFn = Apollo.MutationFunction<Types.DeleteClassModuleMutation, Types.DeleteClassModuleMutationVariables>;

/**
 * __useDeleteClassModuleMutation__
 *
 * To run a mutation, you first call `useDeleteClassModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassModuleMutation, { data, loading, error }] = useDeleteClassModuleMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useDeleteClassModuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteClassModuleMutation, Types.DeleteClassModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteClassModuleMutation, Types.DeleteClassModuleMutationVariables>(DeleteClassModuleDocument, options);
      }
export type DeleteClassModuleMutationHookResult = ReturnType<typeof useDeleteClassModuleMutation>;
export type DeleteClassModuleMutationResult = Apollo.MutationResult<Types.DeleteClassModuleMutation>;
export type DeleteClassModuleMutationOptions = Apollo.BaseMutationOptions<Types.DeleteClassModuleMutation, Types.DeleteClassModuleMutationVariables>;
export const InsertClassModuleDocument = gql`
    mutation InsertClassModule($classModule: [class_module_insert_input!]!) {
  insert_class_module(objects: $classModule) {
    affected_rows
    returning {
      class_id
      class_module_id
    }
  }
}
    `;
export type InsertClassModuleMutationFn = Apollo.MutationFunction<Types.InsertClassModuleMutation, Types.InsertClassModuleMutationVariables>;

/**
 * __useInsertClassModuleMutation__
 *
 * To run a mutation, you first call `useInsertClassModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClassModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClassModuleMutation, { data, loading, error }] = useInsertClassModuleMutation({
 *   variables: {
 *      classModule: // value for 'classModule'
 *   },
 * });
 */
export function useInsertClassModuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertClassModuleMutation, Types.InsertClassModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertClassModuleMutation, Types.InsertClassModuleMutationVariables>(InsertClassModuleDocument, options);
      }
export type InsertClassModuleMutationHookResult = ReturnType<typeof useInsertClassModuleMutation>;
export type InsertClassModuleMutationResult = Apollo.MutationResult<Types.InsertClassModuleMutation>;
export type InsertClassModuleMutationOptions = Apollo.BaseMutationOptions<Types.InsertClassModuleMutation, Types.InsertClassModuleMutationVariables>;
export const DeletePlaylistFromClassDocument = gql`
    mutation DeletePlaylistFromClass($classId: Int!, $playlistId: Int!, $orderName: order_by = asc, $limit: Int) {
  delete_class_playlist(
    where: {class_id: {_eq: $classId}, playlist_id: {_eq: $playlistId}}
  ) {
    affected_rows
    returning {
      class {
        class_id
        class_playlists(limit: $limit, order_by: {playlist: {name: $orderName}}) {
          class_playlist_id
          playlist {
            ...playlistReducedFragment
          }
        }
        class_playlists_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    ${PlaylistReducedFragmentFragmentDoc}`;
export type DeletePlaylistFromClassMutationFn = Apollo.MutationFunction<Types.DeletePlaylistFromClassMutation, Types.DeletePlaylistFromClassMutationVariables>;

/**
 * __useDeletePlaylistFromClassMutation__
 *
 * To run a mutation, you first call `useDeletePlaylistFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaylistFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaylistFromClassMutation, { data, loading, error }] = useDeletePlaylistFromClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      playlistId: // value for 'playlistId'
 *      orderName: // value for 'orderName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDeletePlaylistFromClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePlaylistFromClassMutation, Types.DeletePlaylistFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePlaylistFromClassMutation, Types.DeletePlaylistFromClassMutationVariables>(DeletePlaylistFromClassDocument, options);
      }
export type DeletePlaylistFromClassMutationHookResult = ReturnType<typeof useDeletePlaylistFromClassMutation>;
export type DeletePlaylistFromClassMutationResult = Apollo.MutationResult<Types.DeletePlaylistFromClassMutation>;
export type DeletePlaylistFromClassMutationOptions = Apollo.BaseMutationOptions<Types.DeletePlaylistFromClassMutation, Types.DeletePlaylistFromClassMutationVariables>;
export const SetInsertPlaylistToClassesDocument = gql`
    mutation SetInsertPlaylistToClasses($classes: [class_playlist_insert_input!]!) {
  insert_class_playlist(objects: $classes) {
    returning {
      class_playlist_id
      class {
        class_id
      }
      playlist {
        playlist_id
      }
    }
  }
}
    `;
export type SetInsertPlaylistToClassesMutationFn = Apollo.MutationFunction<Types.SetInsertPlaylistToClassesMutation, Types.SetInsertPlaylistToClassesMutationVariables>;

/**
 * __useSetInsertPlaylistToClassesMutation__
 *
 * To run a mutation, you first call `useSetInsertPlaylistToClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInsertPlaylistToClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInsertPlaylistToClassesMutation, { data, loading, error }] = useSetInsertPlaylistToClassesMutation({
 *   variables: {
 *      classes: // value for 'classes'
 *   },
 * });
 */
export function useSetInsertPlaylistToClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetInsertPlaylistToClassesMutation, Types.SetInsertPlaylistToClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetInsertPlaylistToClassesMutation, Types.SetInsertPlaylistToClassesMutationVariables>(SetInsertPlaylistToClassesDocument, options);
      }
export type SetInsertPlaylistToClassesMutationHookResult = ReturnType<typeof useSetInsertPlaylistToClassesMutation>;
export type SetInsertPlaylistToClassesMutationResult = Apollo.MutationResult<Types.SetInsertPlaylistToClassesMutation>;
export type SetInsertPlaylistToClassesMutationOptions = Apollo.BaseMutationOptions<Types.SetInsertPlaylistToClassesMutation, Types.SetInsertPlaylistToClassesMutationVariables>;
export const InsertClassPlaylistDocument = gql`
    mutation InsertClassPlaylist($classPlaylists: [class_playlist_insert_input!]!) {
  insert_class_playlist(objects: $classPlaylists) {
    affected_rows
    returning {
      class_id
      playlist_id
      class_playlist_id
    }
  }
}
    `;
export type InsertClassPlaylistMutationFn = Apollo.MutationFunction<Types.InsertClassPlaylistMutation, Types.InsertClassPlaylistMutationVariables>;

/**
 * __useInsertClassPlaylistMutation__
 *
 * To run a mutation, you first call `useInsertClassPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClassPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClassPlaylistMutation, { data, loading, error }] = useInsertClassPlaylistMutation({
 *   variables: {
 *      classPlaylists: // value for 'classPlaylists'
 *   },
 * });
 */
export function useInsertClassPlaylistMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertClassPlaylistMutation, Types.InsertClassPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertClassPlaylistMutation, Types.InsertClassPlaylistMutationVariables>(InsertClassPlaylistDocument, options);
      }
export type InsertClassPlaylistMutationHookResult = ReturnType<typeof useInsertClassPlaylistMutation>;
export type InsertClassPlaylistMutationResult = Apollo.MutationResult<Types.InsertClassPlaylistMutation>;
export type InsertClassPlaylistMutationOptions = Apollo.BaseMutationOptions<Types.InsertClassPlaylistMutation, Types.InsertClassPlaylistMutationVariables>;
export const DeleteClassSequenceDocument = gql`
    mutation DeleteClassSequence($sequenceItemId: Int!, $classId: Int!) {
  delete_class_sequence(
    where: {class_id: {_eq: $classId}, sequence_id: {_eq: $sequenceItemId}}
  ) {
    affected_rows
    returning {
      class {
        class_id
        class_sequences(order_by: {sequence: {name: asc}}) {
          class_sequence_id
          sequence {
            sequence_id
          }
        }
        class_sequences_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type DeleteClassSequenceMutationFn = Apollo.MutationFunction<Types.DeleteClassSequenceMutation, Types.DeleteClassSequenceMutationVariables>;

/**
 * __useDeleteClassSequenceMutation__
 *
 * To run a mutation, you first call `useDeleteClassSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassSequenceMutation, { data, loading, error }] = useDeleteClassSequenceMutation({
 *   variables: {
 *      sequenceItemId: // value for 'sequenceItemId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useDeleteClassSequenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteClassSequenceMutation, Types.DeleteClassSequenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteClassSequenceMutation, Types.DeleteClassSequenceMutationVariables>(DeleteClassSequenceDocument, options);
      }
export type DeleteClassSequenceMutationHookResult = ReturnType<typeof useDeleteClassSequenceMutation>;
export type DeleteClassSequenceMutationResult = Apollo.MutationResult<Types.DeleteClassSequenceMutation>;
export type DeleteClassSequenceMutationOptions = Apollo.BaseMutationOptions<Types.DeleteClassSequenceMutation, Types.DeleteClassSequenceMutationVariables>;
export const SetInsertSequenceToClassesDocument = gql`
    mutation SetInsertSequenceToClasses($classes: [class_sequence_insert_input!]!) {
  insert_class_sequence(objects: $classes) {
    returning {
      class_sequence_id
      class {
        class_id
      }
      sequence {
        sequence_id
      }
    }
  }
}
    `;
export type SetInsertSequenceToClassesMutationFn = Apollo.MutationFunction<Types.SetInsertSequenceToClassesMutation, Types.SetInsertSequenceToClassesMutationVariables>;

/**
 * __useSetInsertSequenceToClassesMutation__
 *
 * To run a mutation, you first call `useSetInsertSequenceToClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInsertSequenceToClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInsertSequenceToClassesMutation, { data, loading, error }] = useSetInsertSequenceToClassesMutation({
 *   variables: {
 *      classes: // value for 'classes'
 *   },
 * });
 */
export function useSetInsertSequenceToClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetInsertSequenceToClassesMutation, Types.SetInsertSequenceToClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetInsertSequenceToClassesMutation, Types.SetInsertSequenceToClassesMutationVariables>(SetInsertSequenceToClassesDocument, options);
      }
export type SetInsertSequenceToClassesMutationHookResult = ReturnType<typeof useSetInsertSequenceToClassesMutation>;
export type SetInsertSequenceToClassesMutationResult = Apollo.MutationResult<Types.SetInsertSequenceToClassesMutation>;
export type SetInsertSequenceToClassesMutationOptions = Apollo.BaseMutationOptions<Types.SetInsertSequenceToClassesMutation, Types.SetInsertSequenceToClassesMutationVariables>;
export const InsertClassSequenceDocument = gql`
    mutation InsertClassSequence($classSequence: [class_sequence_insert_input!]!) {
  insert_class_sequence(objects: $classSequence) {
    affected_rows
    returning {
      class_id
      class_sequence_id
    }
  }
}
    `;
export type InsertClassSequenceMutationFn = Apollo.MutationFunction<Types.InsertClassSequenceMutation, Types.InsertClassSequenceMutationVariables>;

/**
 * __useInsertClassSequenceMutation__
 *
 * To run a mutation, you first call `useInsertClassSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClassSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClassSequenceMutation, { data, loading, error }] = useInsertClassSequenceMutation({
 *   variables: {
 *      classSequence: // value for 'classSequence'
 *   },
 * });
 */
export function useInsertClassSequenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertClassSequenceMutation, Types.InsertClassSequenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertClassSequenceMutation, Types.InsertClassSequenceMutationVariables>(InsertClassSequenceDocument, options);
      }
export type InsertClassSequenceMutationHookResult = ReturnType<typeof useInsertClassSequenceMutation>;
export type InsertClassSequenceMutationResult = Apollo.MutationResult<Types.InsertClassSequenceMutation>;
export type InsertClassSequenceMutationOptions = Apollo.BaseMutationOptions<Types.InsertClassSequenceMutation, Types.InsertClassSequenceMutationVariables>;
export const InsertSongToClassDocument = gql`
    mutation InsertSongToClass($classId: Int!, $songId: Int!, $limit: Int, $orderTitle: order_by = asc, $createdAt: order_by, $teacherId: Int!) {
  insert_class_song(objects: {class_id: $classId, song_id: $songId}) {
    affected_rows
    returning {
      class {
        class_id
        class_songs(
          limit: $limit
          order_by: {song: {created_at: $createdAt, title: $orderTitle}}
        ) {
          class_song_id
          song {
            ...songReducedFragment
            is_favorite(args: {teacherid: $teacherId})
          }
        }
        class_songs_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    ${SongReducedFragmentFragmentDoc}`;
export type InsertSongToClassMutationFn = Apollo.MutationFunction<Types.InsertSongToClassMutation, Types.InsertSongToClassMutationVariables>;

/**
 * __useInsertSongToClassMutation__
 *
 * To run a mutation, you first call `useInsertSongToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSongToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSongToClassMutation, { data, loading, error }] = useInsertSongToClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      songId: // value for 'songId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      createdAt: // value for 'createdAt'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useInsertSongToClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertSongToClassMutation, Types.InsertSongToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertSongToClassMutation, Types.InsertSongToClassMutationVariables>(InsertSongToClassDocument, options);
      }
export type InsertSongToClassMutationHookResult = ReturnType<typeof useInsertSongToClassMutation>;
export type InsertSongToClassMutationResult = Apollo.MutationResult<Types.InsertSongToClassMutation>;
export type InsertSongToClassMutationOptions = Apollo.BaseMutationOptions<Types.InsertSongToClassMutation, Types.InsertSongToClassMutationVariables>;
export const DeleteSongFromClassDocument = gql`
    mutation DeleteSongFromClass($classId: Int!, $songId: Int!, $limit: Int, $orderTitle: order_by! = asc, $createdAt: order_by) {
  delete_class_song(where: {class_id: {_eq: $classId}, song_id: {_eq: $songId}}) {
    affected_rows
    returning {
      class {
        class_id
        class_songs(
          limit: $limit
          order_by: {song: {created_at: $createdAt, title: $orderTitle}}
        ) {
          class_song_id
          song {
            song_id
          }
        }
        class_songs_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type DeleteSongFromClassMutationFn = Apollo.MutationFunction<Types.DeleteSongFromClassMutation, Types.DeleteSongFromClassMutationVariables>;

/**
 * __useDeleteSongFromClassMutation__
 *
 * To run a mutation, you first call `useDeleteSongFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSongFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSongFromClassMutation, { data, loading, error }] = useDeleteSongFromClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      songId: // value for 'songId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useDeleteSongFromClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSongFromClassMutation, Types.DeleteSongFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSongFromClassMutation, Types.DeleteSongFromClassMutationVariables>(DeleteSongFromClassDocument, options);
      }
export type DeleteSongFromClassMutationHookResult = ReturnType<typeof useDeleteSongFromClassMutation>;
export type DeleteSongFromClassMutationResult = Apollo.MutationResult<Types.DeleteSongFromClassMutation>;
export type DeleteSongFromClassMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSongFromClassMutation, Types.DeleteSongFromClassMutationVariables>;
export const InsertClassSongsDocument = gql`
    mutation InsertClassSongs($classObject: [class_song_insert_input!]!) {
  insert_class_song(objects: $classObject) {
    affected_rows
    returning {
      class_id
      song_id
    }
  }
}
    `;
export type InsertClassSongsMutationFn = Apollo.MutationFunction<Types.InsertClassSongsMutation, Types.InsertClassSongsMutationVariables>;

/**
 * __useInsertClassSongsMutation__
 *
 * To run a mutation, you first call `useInsertClassSongsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClassSongsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClassSongsMutation, { data, loading, error }] = useInsertClassSongsMutation({
 *   variables: {
 *      classObject: // value for 'classObject'
 *   },
 * });
 */
export function useInsertClassSongsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertClassSongsMutation, Types.InsertClassSongsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertClassSongsMutation, Types.InsertClassSongsMutationVariables>(InsertClassSongsDocument, options);
      }
export type InsertClassSongsMutationHookResult = ReturnType<typeof useInsertClassSongsMutation>;
export type InsertClassSongsMutationResult = Apollo.MutationResult<Types.InsertClassSongsMutation>;
export type InsertClassSongsMutationOptions = Apollo.BaseMutationOptions<Types.InsertClassSongsMutation, Types.InsertClassSongsMutationVariables>;
export const DeleteStudentByIdDocument = gql`
    mutation DeleteStudentByID($studentId: Int!, $classId: Int!) {
  delete_class_student(
    where: {student_id: {_eq: $studentId}, class_id: {_eq: $classId}}
  ) {
    affected_rows
    returning {
      class {
        class_id
      }
    }
  }
  delete_submission(
    where: {submission_students: {student_id: {_eq: $studentId}}, class_assignment: {class_id: {_eq: $classId}}}
  ) {
    affected_rows
    returning {
      class_assignment {
        class_assignment_id
      }
    }
  }
}
    `;
export type DeleteStudentByIdMutationFn = Apollo.MutationFunction<Types.DeleteStudentByIdMutation, Types.DeleteStudentByIdMutationVariables>;

/**
 * __useDeleteStudentByIdMutation__
 *
 * To run a mutation, you first call `useDeleteStudentByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentByIdMutation, { data, loading, error }] = useDeleteStudentByIdMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useDeleteStudentByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStudentByIdMutation, Types.DeleteStudentByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStudentByIdMutation, Types.DeleteStudentByIdMutationVariables>(DeleteStudentByIdDocument, options);
      }
export type DeleteStudentByIdMutationHookResult = ReturnType<typeof useDeleteStudentByIdMutation>;
export type DeleteStudentByIdMutationResult = Apollo.MutationResult<Types.DeleteStudentByIdMutation>;
export type DeleteStudentByIdMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStudentByIdMutation, Types.DeleteStudentByIdMutationVariables>;
export const InsertStudentsDocument = gql`
    mutation InsertStudents($students: [class_student_insert_input!]!) {
  insert_class_student(objects: $students) {
    affected_rows
    returning {
      class_student_id
      student {
        student_id
        class_students {
          class_student_id
        }
      }
      class {
        class_id
      }
    }
  }
}
    `;
export type InsertStudentsMutationFn = Apollo.MutationFunction<Types.InsertStudentsMutation, Types.InsertStudentsMutationVariables>;

/**
 * __useInsertStudentsMutation__
 *
 * To run a mutation, you first call `useInsertStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentsMutation, { data, loading, error }] = useInsertStudentsMutation({
 *   variables: {
 *      students: // value for 'students'
 *   },
 * });
 */
export function useInsertStudentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentsMutation, Types.InsertStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentsMutation, Types.InsertStudentsMutationVariables>(InsertStudentsDocument, options);
      }
export type InsertStudentsMutationHookResult = ReturnType<typeof useInsertStudentsMutation>;
export type InsertStudentsMutationResult = Apollo.MutationResult<Types.InsertStudentsMutation>;
export type InsertStudentsMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentsMutation, Types.InsertStudentsMutationVariables>;
export const InsertIntegrationStudentsToClassDocument = gql`
    mutation InsertIntegrationStudentsToClass($data: StudentInsertClassData!) {
  insertIntegrationStudentsToClass(set: {data: $data}) {
    error
    success
  }
}
    `;
export type InsertIntegrationStudentsToClassMutationFn = Apollo.MutationFunction<Types.InsertIntegrationStudentsToClassMutation, Types.InsertIntegrationStudentsToClassMutationVariables>;

/**
 * __useInsertIntegrationStudentsToClassMutation__
 *
 * To run a mutation, you first call `useInsertIntegrationStudentsToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertIntegrationStudentsToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertIntegrationStudentsToClassMutation, { data, loading, error }] = useInsertIntegrationStudentsToClassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertIntegrationStudentsToClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertIntegrationStudentsToClassMutation, Types.InsertIntegrationStudentsToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertIntegrationStudentsToClassMutation, Types.InsertIntegrationStudentsToClassMutationVariables>(InsertIntegrationStudentsToClassDocument, options);
      }
export type InsertIntegrationStudentsToClassMutationHookResult = ReturnType<typeof useInsertIntegrationStudentsToClassMutation>;
export type InsertIntegrationStudentsToClassMutationResult = Apollo.MutationResult<Types.InsertIntegrationStudentsToClassMutation>;
export type InsertIntegrationStudentsToClassMutationOptions = Apollo.BaseMutationOptions<Types.InsertIntegrationStudentsToClassMutation, Types.InsertIntegrationStudentsToClassMutationVariables>;
export const InsertStudentToClassesDocument = gql`
    mutation InsertStudentToClasses($classObject: [class_student_insert_input!]!) {
  insert_class_student(objects: $classObject) {
    affected_rows
    returning {
      class_id
      student_id
    }
  }
}
    `;
export type InsertStudentToClassesMutationFn = Apollo.MutationFunction<Types.InsertStudentToClassesMutation, Types.InsertStudentToClassesMutationVariables>;

/**
 * __useInsertStudentToClassesMutation__
 *
 * To run a mutation, you first call `useInsertStudentToClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentToClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentToClassesMutation, { data, loading, error }] = useInsertStudentToClassesMutation({
 *   variables: {
 *      classObject: // value for 'classObject'
 *   },
 * });
 */
export function useInsertStudentToClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentToClassesMutation, Types.InsertStudentToClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentToClassesMutation, Types.InsertStudentToClassesMutationVariables>(InsertStudentToClassesDocument, options);
      }
export type InsertStudentToClassesMutationHookResult = ReturnType<typeof useInsertStudentToClassesMutation>;
export type InsertStudentToClassesMutationResult = Apollo.MutationResult<Types.InsertStudentToClassesMutation>;
export type InsertStudentToClassesMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentToClassesMutation, Types.InsertStudentToClassesMutationVariables>;
export const CheckStudentsEmailsInClassDocument = gql`
    query CheckStudentsEmailsInClass($classId: Int!, $studentsEmails: [String!]) {
  class_student(
    where: {class_id: {_eq: $classId}, student: {email: {_in: $studentsEmails}}}
  ) {
    class {
      class_id
    }
    student {
      email
    }
  }
}
    `;

/**
 * __useCheckStudentsEmailsInClassQuery__
 *
 * To run a query within a React component, call `useCheckStudentsEmailsInClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStudentsEmailsInClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStudentsEmailsInClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentsEmails: // value for 'studentsEmails'
 *   },
 * });
 */
export function useCheckStudentsEmailsInClassQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckStudentsEmailsInClassQuery, Types.CheckStudentsEmailsInClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckStudentsEmailsInClassQuery, Types.CheckStudentsEmailsInClassQueryVariables>(CheckStudentsEmailsInClassDocument, options);
      }
export function useCheckStudentsEmailsInClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckStudentsEmailsInClassQuery, Types.CheckStudentsEmailsInClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckStudentsEmailsInClassQuery, Types.CheckStudentsEmailsInClassQueryVariables>(CheckStudentsEmailsInClassDocument, options);
        }
export type CheckStudentsEmailsInClassQueryHookResult = ReturnType<typeof useCheckStudentsEmailsInClassQuery>;
export type CheckStudentsEmailsInClassLazyQueryHookResult = ReturnType<typeof useCheckStudentsEmailsInClassLazyQuery>;
export type CheckStudentsEmailsInClassQueryResult = Apollo.QueryResult<Types.CheckStudentsEmailsInClassQuery, Types.CheckStudentsEmailsInClassQueryVariables>;
export function refetchCheckStudentsEmailsInClassQuery(variables?: Types.CheckStudentsEmailsInClassQueryVariables) {
      return { query: CheckStudentsEmailsInClassDocument, variables: variables }
    }
export const DeleteTipFromClassDocument = gql`
    mutation DeleteTipFromClass($classId: Int!, $tipId: Int!, $limit: Int, $orderTitle: order_by! = asc, $created_at: order_by) {
  delete_class_tip(where: {class_id: {_eq: $classId}, tip_id: {_eq: $tipId}}) {
    affected_rows
    returning {
      class {
        class_id
        class_tips(
          limit: $limit
          order_by: {tip: {title: $orderTitle, created_at: $created_at}}
        ) {
          class_tip_id
          tip {
            tip_id
          }
        }
        class_tips_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;
export type DeleteTipFromClassMutationFn = Apollo.MutationFunction<Types.DeleteTipFromClassMutation, Types.DeleteTipFromClassMutationVariables>;

/**
 * __useDeleteTipFromClassMutation__
 *
 * To run a mutation, you first call `useDeleteTipFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTipFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTipFromClassMutation, { data, loading, error }] = useDeleteTipFromClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      tipId: // value for 'tipId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      created_at: // value for 'created_at'
 *   },
 * });
 */
export function useDeleteTipFromClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTipFromClassMutation, Types.DeleteTipFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTipFromClassMutation, Types.DeleteTipFromClassMutationVariables>(DeleteTipFromClassDocument, options);
      }
export type DeleteTipFromClassMutationHookResult = ReturnType<typeof useDeleteTipFromClassMutation>;
export type DeleteTipFromClassMutationResult = Apollo.MutationResult<Types.DeleteTipFromClassMutation>;
export type DeleteTipFromClassMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTipFromClassMutation, Types.DeleteTipFromClassMutationVariables>;
export const InsertTipToClassDocument = gql`
    mutation InsertTipToClass($classId: Int!, $tipId: Int!, $limit: Int, $orderTitle: order_by = asc, $created_at: order_by, $teacherId: Int!) {
  insert_class_tip(objects: {class_id: $classId, tip_id: $tipId}) {
    affected_rows
    returning {
      class {
        class_id
        class_tips(
          limit: $limit
          order_by: {tip: {title: $orderTitle, created_at: $created_at}}
        ) {
          class_tip_id
          tip {
            ...tipExtendedFragment
            is_favorite(args: {teacherid: $teacherId})
          }
        }
        class_tips_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;
export type InsertTipToClassMutationFn = Apollo.MutationFunction<Types.InsertTipToClassMutation, Types.InsertTipToClassMutationVariables>;

/**
 * __useInsertTipToClassMutation__
 *
 * To run a mutation, you first call `useInsertTipToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTipToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTipToClassMutation, { data, loading, error }] = useInsertTipToClassMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      tipId: // value for 'tipId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      created_at: // value for 'created_at'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useInsertTipToClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTipToClassMutation, Types.InsertTipToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTipToClassMutation, Types.InsertTipToClassMutationVariables>(InsertTipToClassDocument, options);
      }
export type InsertTipToClassMutationHookResult = ReturnType<typeof useInsertTipToClassMutation>;
export type InsertTipToClassMutationResult = Apollo.MutationResult<Types.InsertTipToClassMutation>;
export type InsertTipToClassMutationOptions = Apollo.BaseMutationOptions<Types.InsertTipToClassMutation, Types.InsertTipToClassMutationVariables>;
export const InsertTipsToClassDocument = gql`
    mutation InsertTipsToClass($tips: [class_tip_insert_input!]!) {
  insert_class_tip(objects: $tips) {
    affected_rows
    returning {
      class_tip_id
      tip {
        tip_id
      }
      class {
        class_id
      }
    }
  }
}
    `;
export type InsertTipsToClassMutationFn = Apollo.MutationFunction<Types.InsertTipsToClassMutation, Types.InsertTipsToClassMutationVariables>;

/**
 * __useInsertTipsToClassMutation__
 *
 * To run a mutation, you first call `useInsertTipsToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTipsToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTipsToClassMutation, { data, loading, error }] = useInsertTipsToClassMutation({
 *   variables: {
 *      tips: // value for 'tips'
 *   },
 * });
 */
export function useInsertTipsToClassMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTipsToClassMutation, Types.InsertTipsToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTipsToClassMutation, Types.InsertTipsToClassMutationVariables>(InsertTipsToClassDocument, options);
      }
export type InsertTipsToClassMutationHookResult = ReturnType<typeof useInsertTipsToClassMutation>;
export type InsertTipsToClassMutationResult = Apollo.MutationResult<Types.InsertTipsToClassMutation>;
export type InsertTipsToClassMutationOptions = Apollo.BaseMutationOptions<Types.InsertTipsToClassMutation, Types.InsertTipsToClassMutationVariables>;
export const InsertTipsToClassesDocument = gql`
    mutation InsertTipsToClasses($classObject: [class_tip_insert_input!]!) {
  insert_class_tip(objects: $classObject) {
    affected_rows
    returning {
      class_id
      tip_id
    }
  }
}
    `;
export type InsertTipsToClassesMutationFn = Apollo.MutationFunction<Types.InsertTipsToClassesMutation, Types.InsertTipsToClassesMutationVariables>;

/**
 * __useInsertTipsToClassesMutation__
 *
 * To run a mutation, you first call `useInsertTipsToClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTipsToClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTipsToClassesMutation, { data, loading, error }] = useInsertTipsToClassesMutation({
 *   variables: {
 *      classObject: // value for 'classObject'
 *   },
 * });
 */
export function useInsertTipsToClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTipsToClassesMutation, Types.InsertTipsToClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTipsToClassesMutation, Types.InsertTipsToClassesMutationVariables>(InsertTipsToClassesDocument, options);
      }
export type InsertTipsToClassesMutationHookResult = ReturnType<typeof useInsertTipsToClassesMutation>;
export type InsertTipsToClassesMutationResult = Apollo.MutationResult<Types.InsertTipsToClassesMutation>;
export type InsertTipsToClassesMutationOptions = Apollo.BaseMutationOptions<Types.InsertTipsToClassesMutation, Types.InsertTipsToClassesMutationVariables>;
export const GetInProgressCleverRosterLogDocument = gql`
    subscription GetInProgressCleverRosterLog($teacherId: Int!) {
  clever_roster_log(
    where: {teacher_id: {_eq: $teacherId}, status: {_eq: in_progress}}
  ) {
    status
  }
}
    `;

/**
 * __useGetInProgressCleverRosterLogSubscription__
 *
 * To run a query within a React component, call `useGetInProgressCleverRosterLogSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInProgressCleverRosterLogSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInProgressCleverRosterLogSubscription({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetInProgressCleverRosterLogSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.GetInProgressCleverRosterLogSubscription, Types.GetInProgressCleverRosterLogSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetInProgressCleverRosterLogSubscription, Types.GetInProgressCleverRosterLogSubscriptionVariables>(GetInProgressCleverRosterLogDocument, options);
      }
export type GetInProgressCleverRosterLogSubscriptionHookResult = ReturnType<typeof useGetInProgressCleverRosterLogSubscription>;
export type GetInProgressCleverRosterLogSubscriptionResult = Apollo.SubscriptionResult<Types.GetInProgressCleverRosterLogSubscription>;
export const CreateCampTeacherContactDocument = gql`
    mutation CreateCampTeacherContact($campName: String!, $phoneNumber: String!, $message: String = "", $firstName: String!, $lastName: String!, $email: String!) {
  insert_contact_us_camp_teacher(
    objects: {camp_name: $campName, phone_number: $phoneNumber, message: $message, first_name: $firstName, last_name: $lastName, email: $email}
  ) {
    affected_rows
  }
}
    `;
export type CreateCampTeacherContactMutationFn = Apollo.MutationFunction<Types.CreateCampTeacherContactMutation, Types.CreateCampTeacherContactMutationVariables>;

/**
 * __useCreateCampTeacherContactMutation__
 *
 * To run a mutation, you first call `useCreateCampTeacherContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampTeacherContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampTeacherContactMutation, { data, loading, error }] = useCreateCampTeacherContactMutation({
 *   variables: {
 *      campName: // value for 'campName'
 *      phoneNumber: // value for 'phoneNumber'
 *      message: // value for 'message'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateCampTeacherContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCampTeacherContactMutation, Types.CreateCampTeacherContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCampTeacherContactMutation, Types.CreateCampTeacherContactMutationVariables>(CreateCampTeacherContactDocument, options);
      }
export type CreateCampTeacherContactMutationHookResult = ReturnType<typeof useCreateCampTeacherContactMutation>;
export type CreateCampTeacherContactMutationResult = Apollo.MutationResult<Types.CreateCampTeacherContactMutation>;
export type CreateCampTeacherContactMutationOptions = Apollo.BaseMutationOptions<Types.CreateCampTeacherContactMutation, Types.CreateCampTeacherContactMutationVariables>;
export const GetMasterListNamesDocument = gql`
    query GetMasterListNames($search: [crossclear_masterlist_bool_exp!]!, $limit: Int) {
  crossclear_masterlist(where: {_or: $search}, distinct_on: [name], limit: $limit) {
    name
    masterlist_id
    cross_id
  }
}
    `;

/**
 * __useGetMasterListNamesQuery__
 *
 * To run a query within a React component, call `useGetMasterListNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterListNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterListNamesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMasterListNamesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMasterListNamesQuery, Types.GetMasterListNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMasterListNamesQuery, Types.GetMasterListNamesQueryVariables>(GetMasterListNamesDocument, options);
      }
export function useGetMasterListNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMasterListNamesQuery, Types.GetMasterListNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMasterListNamesQuery, Types.GetMasterListNamesQueryVariables>(GetMasterListNamesDocument, options);
        }
export type GetMasterListNamesQueryHookResult = ReturnType<typeof useGetMasterListNamesQuery>;
export type GetMasterListNamesLazyQueryHookResult = ReturnType<typeof useGetMasterListNamesLazyQuery>;
export type GetMasterListNamesQueryResult = Apollo.QueryResult<Types.GetMasterListNamesQuery, Types.GetMasterListNamesQueryVariables>;
export function refetchGetMasterListNamesQuery(variables?: Types.GetMasterListNamesQueryVariables) {
      return { query: GetMasterListNamesDocument, variables: variables }
    }
export const GetDistrictsDocument = gql`
    query GetDistricts($search: String!, $limit: Int!) {
  district(where: {name: {_ilike: $search}}, limit: $limit, order_by: {name: asc}) {
    name
    district_id
    city
    state
    schools_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetDistrictsQuery__
 *
 * To run a query within a React component, call `useGetDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetDistrictsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDistrictsQuery, Types.GetDistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDistrictsQuery, Types.GetDistrictsQueryVariables>(GetDistrictsDocument, options);
      }
export function useGetDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDistrictsQuery, Types.GetDistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDistrictsQuery, Types.GetDistrictsQueryVariables>(GetDistrictsDocument, options);
        }
export type GetDistrictsQueryHookResult = ReturnType<typeof useGetDistrictsQuery>;
export type GetDistrictsLazyQueryHookResult = ReturnType<typeof useGetDistrictsLazyQuery>;
export type GetDistrictsQueryResult = Apollo.QueryResult<Types.GetDistrictsQuery, Types.GetDistrictsQueryVariables>;
export function refetchGetDistrictsQuery(variables?: Types.GetDistrictsQueryVariables) {
      return { query: GetDistrictsDocument, variables: variables }
    }
export const GetValidCodeDocument = gql`
    query GetValidCode {
  getValidCode
}
    `;

/**
 * __useGetValidCodeQuery__
 *
 * To run a query within a React component, call `useGetValidCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetValidCodeQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetValidCodeQuery, Types.GetValidCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetValidCodeQuery, Types.GetValidCodeQueryVariables>(GetValidCodeDocument, options);
      }
export function useGetValidCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetValidCodeQuery, Types.GetValidCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetValidCodeQuery, Types.GetValidCodeQueryVariables>(GetValidCodeDocument, options);
        }
export type GetValidCodeQueryHookResult = ReturnType<typeof useGetValidCodeQuery>;
export type GetValidCodeLazyQueryHookResult = ReturnType<typeof useGetValidCodeLazyQuery>;
export type GetValidCodeQueryResult = Apollo.QueryResult<Types.GetValidCodeQuery, Types.GetValidCodeQueryVariables>;
export function refetchGetValidCodeQuery(variables?: Types.GetValidCodeQueryVariables) {
      return { query: GetValidCodeDocument, variables: variables }
    }
export const GetValidCodesDocument = gql`
    query getValidCodes($filter: Int!) {
  getValidCodes(filter: $filter)
}
    `;

/**
 * __useGetValidCodesQuery__
 *
 * To run a query within a React component, call `useGetValidCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidCodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetValidCodesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetValidCodesQuery, Types.GetValidCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetValidCodesQuery, Types.GetValidCodesQueryVariables>(GetValidCodesDocument, options);
      }
export function useGetValidCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetValidCodesQuery, Types.GetValidCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetValidCodesQuery, Types.GetValidCodesQueryVariables>(GetValidCodesDocument, options);
        }
export type GetValidCodesQueryHookResult = ReturnType<typeof useGetValidCodesQuery>;
export type GetValidCodesLazyQueryHookResult = ReturnType<typeof useGetValidCodesLazyQuery>;
export type GetValidCodesQueryResult = Apollo.QueryResult<Types.GetValidCodesQuery, Types.GetValidCodesQueryVariables>;
export function refetchGetValidCodesQuery(variables?: Types.GetValidCodesQueryVariables) {
      return { query: GetValidCodesDocument, variables: variables }
    }
export const GetGradesDocument = gql`
    query GetGrades {
  grade(order_by: {grade_id: asc}) {
    grade_id
    name
  }
}
    `;

/**
 * __useGetGradesQuery__
 *
 * To run a query within a React component, call `useGetGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGradesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetGradesQuery, Types.GetGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGradesQuery, Types.GetGradesQueryVariables>(GetGradesDocument, options);
      }
export function useGetGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGradesQuery, Types.GetGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGradesQuery, Types.GetGradesQueryVariables>(GetGradesDocument, options);
        }
export type GetGradesQueryHookResult = ReturnType<typeof useGetGradesQuery>;
export type GetGradesLazyQueryHookResult = ReturnType<typeof useGetGradesLazyQuery>;
export type GetGradesQueryResult = Apollo.QueryResult<Types.GetGradesQuery, Types.GetGradesQueryVariables>;
export function refetchGetGradesQuery(variables?: Types.GetGradesQueryVariables) {
      return { query: GetGradesDocument, variables: variables }
    }
export const GetDefaultGradingSystemsDocument = gql`
    query GetDefaultGradingSystems {
  grading_system(order_by: {grading_system_id: asc}) {
    grading_system_id
    name
    label
  }
}
    `;

/**
 * __useGetDefaultGradingSystemsQuery__
 *
 * To run a query within a React component, call `useGetDefaultGradingSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultGradingSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultGradingSystemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultGradingSystemsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDefaultGradingSystemsQuery, Types.GetDefaultGradingSystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDefaultGradingSystemsQuery, Types.GetDefaultGradingSystemsQueryVariables>(GetDefaultGradingSystemsDocument, options);
      }
export function useGetDefaultGradingSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDefaultGradingSystemsQuery, Types.GetDefaultGradingSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDefaultGradingSystemsQuery, Types.GetDefaultGradingSystemsQueryVariables>(GetDefaultGradingSystemsDocument, options);
        }
export type GetDefaultGradingSystemsQueryHookResult = ReturnType<typeof useGetDefaultGradingSystemsQuery>;
export type GetDefaultGradingSystemsLazyQueryHookResult = ReturnType<typeof useGetDefaultGradingSystemsLazyQuery>;
export type GetDefaultGradingSystemsQueryResult = Apollo.QueryResult<Types.GetDefaultGradingSystemsQuery, Types.GetDefaultGradingSystemsQueryVariables>;
export function refetchGetDefaultGradingSystemsQuery(variables?: Types.GetDefaultGradingSystemsQueryVariables) {
      return { query: GetDefaultGradingSystemsDocument, variables: variables }
    }
export const UpdateLessonDocument = gql`
    mutation UpdateLesson($keywordIdsToDelete: [Int!] = [], $levelIdsToDelete: [Int!] = [], $catalogItemIdsToDelete: [Int!] = [], $songIdsToDelete: [Int!] = [], $musicalSkillIdsToDelete: [Int!] = [], $nonMusicalSkillIdsToDelete: [Int!] = [], $tipIdsToDelete: [Int!] = [], $assetsIdsToDelete: [Int!] = [], $articles: [lesson_plan_article_insert_input!]! = [], $lessonPlanId: Int!, $teacherId: Int!, $availableId: Int = 1, $title: String = "", $description: String = "", $imagePath: String = "", $inspire: String = "", $practice: String = "", $record: String = "", $reflect: String = "", $takeItFurther: String = "", $materials: String = "", $differentiations: String = "", $suggestedAssignments: String = "", $discipline: [lesson_plan_discipline_insert_input!]!, $process: [lesson_plan_standard_insert_input!]!, $lessonKeywords: lesson_plan_keyword_arr_rel_insert_input = null, $levels: lesson_plan_grade_arr_rel_insert_input = null, $lessonCatalogItems: lesson_plan_catalog_item_arr_rel_insert_input = null, $songs: lesson_plan_song_arr_rel_insert_input = null, $musicalSkill: lesson_plan_musical_skill_arr_rel_insert_input = null, $nonMusicalSkill: lesson_plan_non_musical_skill_arr_rel_insert_input = null, $tips: lesson_plan_tip_arr_rel_insert_input = null, $assets: lesson_plan_asset_arr_rel_insert_input = null) {
  delete_lesson_plan_keyword(
    where: {keyword_id: {_in: $keywordIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_grade(
    where: {grade_id: {_in: $levelIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_catalog_item(
    where: {catalog_item_id: {_in: $catalogItemIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_song(
    where: {song_id: {_in: $songIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_musical_skill(
    where: {musical_skill_id: {_in: $musicalSkillIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_non_musical_skill(
    where: {non_musical_skill_id: {_in: $nonMusicalSkillIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_tip(
    where: {tip_id: {_in: $tipIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_lesson_plan_asset(
    where: {asset_id: {_in: $assetsIdsToDelete}, _and: {lesson_plan_id: {_eq: $lessonPlanId}}}
  ) {
    affected_rows
  }
  delete_asset(where: {asset_id: {_in: $assetsIdsToDelete}}) {
    affected_rows
  }
  delete_lesson_plan_article(where: {lesson_plan_id: {_eq: $lessonPlanId}}) {
    returning {
      lesson_plan_id
      lesson_plan_article_id
    }
  }
  delete_lesson_plan_discipline(where: {lesson_plan_id: {_eq: $lessonPlanId}}) {
    returning {
      discipline_id
    }
  }
  delete_lesson_plan_standard(where: {lesson_plan_id: {_eq: $lessonPlanId}}) {
    returning {
      standar_option_id
    }
  }
  insert_lesson_plan_article(objects: $articles) {
    returning {
      lesson_plan_id
      lesson_plan_article_id
      title
      content
    }
  }
  insert_lesson_plan_discipline(objects: $discipline) {
    returning {
      discipline_id
    }
  }
  insert_lesson_plan_standard(objects: $process) {
    returning {
      standar_option_id
    }
  }
  insert_lesson_plan_one(
    object: {lesson_plan_id: $lessonPlanId, teacher_id: $teacherId, available_id: $availableId, title: $title, description: $description, image_path: $imagePath, inspire: $inspire, practice: $practice, record: $record, reflect: $reflect, take_it_further: $takeItFurther, materials_needed: $materials, differentiations: $differentiations, suggested_assignments: $suggestedAssignments, lesson_plan_keyword: $lessonKeywords, lesson_plan_grade: $levels, lesson_plan_catalog_item: $lessonCatalogItems, lesson_plan_song: $songs, lesson_plan_musical_skill: $musicalSkill, lesson_plan_non_musical_skill: $nonMusicalSkill, lesson_plan_tip: $tips, lesson_plan_asset: $assets}
    on_conflict: {constraint: lesson_plan_pkey, update_columns: [teacher_id, available_id, title, description, image_path, inspire, practice, record, reflect, take_it_further, materials_needed, differentiations, suggested_assignments, discipline, process]}
  ) {
    title
  }
}
    `;
export type UpdateLessonMutationFn = Apollo.MutationFunction<Types.UpdateLessonMutation, Types.UpdateLessonMutationVariables>;

/**
 * __useUpdateLessonMutation__
 *
 * To run a mutation, you first call `useUpdateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonMutation, { data, loading, error }] = useUpdateLessonMutation({
 *   variables: {
 *      keywordIdsToDelete: // value for 'keywordIdsToDelete'
 *      levelIdsToDelete: // value for 'levelIdsToDelete'
 *      catalogItemIdsToDelete: // value for 'catalogItemIdsToDelete'
 *      songIdsToDelete: // value for 'songIdsToDelete'
 *      musicalSkillIdsToDelete: // value for 'musicalSkillIdsToDelete'
 *      nonMusicalSkillIdsToDelete: // value for 'nonMusicalSkillIdsToDelete'
 *      tipIdsToDelete: // value for 'tipIdsToDelete'
 *      assetsIdsToDelete: // value for 'assetsIdsToDelete'
 *      articles: // value for 'articles'
 *      lessonPlanId: // value for 'lessonPlanId'
 *      teacherId: // value for 'teacherId'
 *      availableId: // value for 'availableId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      imagePath: // value for 'imagePath'
 *      inspire: // value for 'inspire'
 *      practice: // value for 'practice'
 *      record: // value for 'record'
 *      reflect: // value for 'reflect'
 *      takeItFurther: // value for 'takeItFurther'
 *      materials: // value for 'materials'
 *      differentiations: // value for 'differentiations'
 *      suggestedAssignments: // value for 'suggestedAssignments'
 *      discipline: // value for 'discipline'
 *      process: // value for 'process'
 *      lessonKeywords: // value for 'lessonKeywords'
 *      levels: // value for 'levels'
 *      lessonCatalogItems: // value for 'lessonCatalogItems'
 *      songs: // value for 'songs'
 *      musicalSkill: // value for 'musicalSkill'
 *      nonMusicalSkill: // value for 'nonMusicalSkill'
 *      tips: // value for 'tips'
 *      assets: // value for 'assets'
 *   },
 * });
 */
export function useUpdateLessonMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLessonMutation, Types.UpdateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLessonMutation, Types.UpdateLessonMutationVariables>(UpdateLessonDocument, options);
      }
export type UpdateLessonMutationHookResult = ReturnType<typeof useUpdateLessonMutation>;
export type UpdateLessonMutationResult = Apollo.MutationResult<Types.UpdateLessonMutation>;
export type UpdateLessonMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLessonMutation, Types.UpdateLessonMutationVariables>;
export const AddLessonDocument = gql`
    mutation AddLesson($data: lesson_plan_insert_input!) {
  insert_lesson_plan_one(object: $data) {
    lesson_plan_id
    title
  }
}
    `;
export type AddLessonMutationFn = Apollo.MutationFunction<Types.AddLessonMutation, Types.AddLessonMutationVariables>;

/**
 * __useAddLessonMutation__
 *
 * To run a mutation, you first call `useAddLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLessonMutation, { data, loading, error }] = useAddLessonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddLessonMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddLessonMutation, Types.AddLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddLessonMutation, Types.AddLessonMutationVariables>(AddLessonDocument, options);
      }
export type AddLessonMutationHookResult = ReturnType<typeof useAddLessonMutation>;
export type AddLessonMutationResult = Apollo.MutationResult<Types.AddLessonMutation>;
export type AddLessonMutationOptions = Apollo.BaseMutationOptions<Types.AddLessonMutation, Types.AddLessonMutationVariables>;
export const UpdateClassLessonsDocument = gql`
    mutation UpdateClassLessons($class_id: Int!, $lessonPlansIds: [class_lesson_plan_insert_input!]!) {
  delete_class_lesson_plan(where: {class_id: {_eq: $class_id}}) {
    affected_rows
  }
  insert_class_lesson_plan(objects: $lessonPlansIds) {
    affected_rows
  }
}
    `;
export type UpdateClassLessonsMutationFn = Apollo.MutationFunction<Types.UpdateClassLessonsMutation, Types.UpdateClassLessonsMutationVariables>;

/**
 * __useUpdateClassLessonsMutation__
 *
 * To run a mutation, you first call `useUpdateClassLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassLessonsMutation, { data, loading, error }] = useUpdateClassLessonsMutation({
 *   variables: {
 *      class_id: // value for 'class_id'
 *      lessonPlansIds: // value for 'lessonPlansIds'
 *   },
 * });
 */
export function useUpdateClassLessonsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClassLessonsMutation, Types.UpdateClassLessonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateClassLessonsMutation, Types.UpdateClassLessonsMutationVariables>(UpdateClassLessonsDocument, options);
      }
export type UpdateClassLessonsMutationHookResult = ReturnType<typeof useUpdateClassLessonsMutation>;
export type UpdateClassLessonsMutationResult = Apollo.MutationResult<Types.UpdateClassLessonsMutation>;
export type UpdateClassLessonsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClassLessonsMutation, Types.UpdateClassLessonsMutationVariables>;
export const DeleteLessonByPkDocument = gql`
    mutation DeleteLessonByPk($lessonId: Int!) {
  delete_lesson_plan_by_pk(lesson_plan_id: $lessonId) {
    lesson_plan_id
  }
}
    `;
export type DeleteLessonByPkMutationFn = Apollo.MutationFunction<Types.DeleteLessonByPkMutation, Types.DeleteLessonByPkMutationVariables>;

/**
 * __useDeleteLessonByPkMutation__
 *
 * To run a mutation, you first call `useDeleteLessonByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonByPkMutation, { data, loading, error }] = useDeleteLessonByPkMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useDeleteLessonByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLessonByPkMutation, Types.DeleteLessonByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLessonByPkMutation, Types.DeleteLessonByPkMutationVariables>(DeleteLessonByPkDocument, options);
      }
export type DeleteLessonByPkMutationHookResult = ReturnType<typeof useDeleteLessonByPkMutation>;
export type DeleteLessonByPkMutationResult = Apollo.MutationResult<Types.DeleteLessonByPkMutation>;
export type DeleteLessonByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLessonByPkMutation, Types.DeleteLessonByPkMutationVariables>;
export const GetLessonsByPkDocument = gql`
    query GetLessonsByPK($teacherId: Int!, $lessonPlanId: Int!) {
  lesson_plan_by_pk(lesson_plan_id: $lessonPlanId) {
    is_favorite(args: {teacherid: $teacherId})
    featured
    teacher_id
    lesson_plan_articles(order_by: {order: asc}) {
      title
      content
      lesson_plan_article_id
      order
    }
    class_lesson_plans {
      class_lesson_plan_id
      class {
        class_id
        title
      }
    }
    ...LessonPlanExtendedFragment
    lesson_plan_standards {
      standar_option_id
      lesson_plan_id
      standard_option {
        title
        standar_option_id
      }
    }
    lesson_plan_disciplines {
      lesson_plan_id
      discipline_id
      discipline {
        discipline_id
        name
      }
    }
    lesson_plan_song(where: {song: {song_id: {_is_null: false}}}) {
      song {
        song_id
        title
        image_path
        artist {
          artist_id
          name
        }
        is_favorite(args: {teacherid: $teacherId})
      }
    }
    module_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
    lesson_plan_tip(where: {tip: {tip_id: {_is_null: false}}}) {
      lesson_plan_tip_id
      tip {
        tip_id
        title
        description
        image_path
        resource_path
        is_favorite(args: {teacherid: $teacherId})
        tip_catalog_item {
          catalog_item {
            name
            catalog {
              name
            }
          }
        }
      }
    }
  }
}
    ${LessonPlanExtendedFragmentFragmentDoc}`;

/**
 * __useGetLessonsByPkQuery__
 *
 * To run a query within a React component, call `useGetLessonsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsByPkQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useGetLessonsByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonsByPkQuery, Types.GetLessonsByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonsByPkQuery, Types.GetLessonsByPkQueryVariables>(GetLessonsByPkDocument, options);
      }
export function useGetLessonsByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonsByPkQuery, Types.GetLessonsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonsByPkQuery, Types.GetLessonsByPkQueryVariables>(GetLessonsByPkDocument, options);
        }
export type GetLessonsByPkQueryHookResult = ReturnType<typeof useGetLessonsByPkQuery>;
export type GetLessonsByPkLazyQueryHookResult = ReturnType<typeof useGetLessonsByPkLazyQuery>;
export type GetLessonsByPkQueryResult = Apollo.QueryResult<Types.GetLessonsByPkQuery, Types.GetLessonsByPkQueryVariables>;
export function refetchGetLessonsByPkQuery(variables?: Types.GetLessonsByPkQueryVariables) {
      return { query: GetLessonsByPkDocument, variables: variables }
    }
export const GetFeaturedLessonsDocument = gql`
    query GetFeaturedLessons($teacherId: Int, $offset: Int, $limit: Int, $order: order_by = asc) {
  lesson_plan(
    where: {featured: {_eq: true}, lesson_plan_id: {_is_null: false}, lockable_content: {visibility: {_neq: for_sale}}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    class_lesson_plans {
      class_lesson_plan_id
      class {
        class_id
        title
      }
    }
    ...LessonPlanExtendedFragment
  }
}
    ${LessonPlanExtendedFragmentFragmentDoc}`;

/**
 * __useGetFeaturedLessonsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedLessonsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetFeaturedLessonsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFeaturedLessonsQuery, Types.GetFeaturedLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeaturedLessonsQuery, Types.GetFeaturedLessonsQueryVariables>(GetFeaturedLessonsDocument, options);
      }
export function useGetFeaturedLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeaturedLessonsQuery, Types.GetFeaturedLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeaturedLessonsQuery, Types.GetFeaturedLessonsQueryVariables>(GetFeaturedLessonsDocument, options);
        }
export type GetFeaturedLessonsQueryHookResult = ReturnType<typeof useGetFeaturedLessonsQuery>;
export type GetFeaturedLessonsLazyQueryHookResult = ReturnType<typeof useGetFeaturedLessonsLazyQuery>;
export type GetFeaturedLessonsQueryResult = Apollo.QueryResult<Types.GetFeaturedLessonsQuery, Types.GetFeaturedLessonsQueryVariables>;
export function refetchGetFeaturedLessonsQuery(variables?: Types.GetFeaturedLessonsQueryVariables) {
      return { query: GetFeaturedLessonsDocument, variables: variables }
    }
export const GetLessonsDocument = gql`
    query GetLessons($teacherId: Int, $offset: Int, $limit: Int, $order_by: [lesson_plan_order_by!]) {
  lesson_plan(
    where: {teacher_id: {_eq: $teacherId}, lesson_plan_id: {_is_null: false}, lockable_content: {visibility: {_neq: for_sale}}}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    is_favorite(args: {teacherid: $teacherId})
    class_lesson_plans {
      class_lesson_plan_id
      class {
        class_id
        title
      }
    }
    ...LessonPlanExtendedFragment
  }
  lesson_plan_aggregate(
    where: {teacher_id: {_eq: $teacherId}, lesson_plan_id: {_is_null: false}, lockable_content: {visibility: {_neq: for_sale}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${LessonPlanExtendedFragmentFragmentDoc}`;

/**
 * __useGetLessonsQuery__
 *
 * To run a query within a React component, call `useGetLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetLessonsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLessonsQuery, Types.GetLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonsQuery, Types.GetLessonsQueryVariables>(GetLessonsDocument, options);
      }
export function useGetLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonsQuery, Types.GetLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonsQuery, Types.GetLessonsQueryVariables>(GetLessonsDocument, options);
        }
export type GetLessonsQueryHookResult = ReturnType<typeof useGetLessonsQuery>;
export type GetLessonsLazyQueryHookResult = ReturnType<typeof useGetLessonsLazyQuery>;
export type GetLessonsQueryResult = Apollo.QueryResult<Types.GetLessonsQuery, Types.GetLessonsQueryVariables>;
export function refetchGetLessonsQuery(variables?: Types.GetLessonsQueryVariables) {
      return { query: GetLessonsDocument, variables: variables }
    }
export const GetLessonByPkForEditDocument = gql`
    query GetLessonByPKForEdit($lessonPlanId: Int!) {
  lesson_plan_by_pk(lesson_plan_id: $lessonPlanId) {
    teacher_id
    available_id
    title
    description
    image_path
    inspire
    practice
    record
    reflect
    take_it_further
    materials_needed
    differentiations
    suggested_assignments
    lesson_plan_standards {
      standar_option_id
      lesson_plan_id
      standard_option {
        title
        standar_option_id
      }
    }
    lesson_plan_disciplines {
      lesson_plan_id
      discipline_id
      discipline {
        discipline_id
        name
      }
    }
    articles: lesson_plan_articles(order_by: {order: asc}) {
      title
      content
      lesson_plan_article_id
      order
    }
    keywords: lesson_plan_keyword {
      keyword_id
    }
    catalog_items: lesson_plan_catalog_item {
      catalog_item_id
    }
    levels: lesson_plan_grade(order_by: {grade_id: asc}) {
      grade_id
    }
    musical_skills: lesson_plan_musical_skill {
      musical_skill_id
    }
    non_musical_skills: lesson_plan_non_musical_skill {
      non_musical_skill_id
    }
    songs: lesson_plan_song(where: {song: {song_id: {_is_null: false}}}) {
      song_id
    }
    videos: lesson_plan_tip(where: {tip: {tip_id: {_is_null: false}}}) {
      tip_id
    }
    assets: lesson_plan_asset {
      asset {
        asset_id
        name
        resource_path
      }
    }
  }
}
    `;

/**
 * __useGetLessonByPkForEditQuery__
 *
 * To run a query within a React component, call `useGetLessonByPkForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonByPkForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonByPkForEditQuery({
 *   variables: {
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useGetLessonByPkForEditQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonByPkForEditQuery, Types.GetLessonByPkForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonByPkForEditQuery, Types.GetLessonByPkForEditQueryVariables>(GetLessonByPkForEditDocument, options);
      }
export function useGetLessonByPkForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonByPkForEditQuery, Types.GetLessonByPkForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonByPkForEditQuery, Types.GetLessonByPkForEditQueryVariables>(GetLessonByPkForEditDocument, options);
        }
export type GetLessonByPkForEditQueryHookResult = ReturnType<typeof useGetLessonByPkForEditQuery>;
export type GetLessonByPkForEditLazyQueryHookResult = ReturnType<typeof useGetLessonByPkForEditLazyQuery>;
export type GetLessonByPkForEditQueryResult = Apollo.QueryResult<Types.GetLessonByPkForEditQuery, Types.GetLessonByPkForEditQueryVariables>;
export function refetchGetLessonByPkForEditQuery(variables?: Types.GetLessonByPkForEditQueryVariables) {
      return { query: GetLessonByPkForEditDocument, variables: variables }
    }
export const GetLessonByPkForAddAssignmentDocument = gql`
    query GetLessonByPKForAddAssignment($lessonPlanId: Int!, $classId: Int!) {
  lesson_plan_by_pk(lesson_plan_id: $lessonPlanId) {
    lesson_plan_id
    title
    suggested_assignments
    suggested_assignments_from_articles: lesson_plan_articles(
      where: {title: {_ilike: "Suggested Assignment"}}
    ) {
      title
      content
    }
    lesson_plan_song(where: {song: {song_id: {_is_null: false}}}) {
      song {
        song_id
        title
        image_path
        artist {
          artist_id
          name
        }
        song_catalog_item {
          catalog_item {
            name
          }
        }
      }
    }
    lesson_plan_tip(where: {tip: {tip_id: {_is_null: false}}}) {
      tip {
        tip_id
        title
        description
        image_path
        tip_catalog_item {
          catalog_item {
            name
            catalog {
              name
            }
          }
        }
      }
    }
    lesson_plan_asset {
      asset {
        name
        resource_path
      }
    }
  }
  class(where: {class_id: {_eq: $classId}}) {
    class_id
    title
    image_path
    class_students_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetLessonByPkForAddAssignmentQuery__
 *
 * To run a query within a React component, call `useGetLessonByPkForAddAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonByPkForAddAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonByPkForAddAssignmentQuery({
 *   variables: {
 *      lessonPlanId: // value for 'lessonPlanId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetLessonByPkForAddAssignmentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonByPkForAddAssignmentQuery, Types.GetLessonByPkForAddAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonByPkForAddAssignmentQuery, Types.GetLessonByPkForAddAssignmentQueryVariables>(GetLessonByPkForAddAssignmentDocument, options);
      }
export function useGetLessonByPkForAddAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonByPkForAddAssignmentQuery, Types.GetLessonByPkForAddAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonByPkForAddAssignmentQuery, Types.GetLessonByPkForAddAssignmentQueryVariables>(GetLessonByPkForAddAssignmentDocument, options);
        }
export type GetLessonByPkForAddAssignmentQueryHookResult = ReturnType<typeof useGetLessonByPkForAddAssignmentQuery>;
export type GetLessonByPkForAddAssignmentLazyQueryHookResult = ReturnType<typeof useGetLessonByPkForAddAssignmentLazyQuery>;
export type GetLessonByPkForAddAssignmentQueryResult = Apollo.QueryResult<Types.GetLessonByPkForAddAssignmentQuery, Types.GetLessonByPkForAddAssignmentQueryVariables>;
export function refetchGetLessonByPkForAddAssignmentQuery(variables?: Types.GetLessonByPkForAddAssignmentQueryVariables) {
      return { query: GetLessonByPkForAddAssignmentDocument, variables: variables }
    }
export const GetLessonsCatalogDocument = gql`
    query GetLessonsCatalog($teacherId: Int, $conditions: [lesson_plan_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc, $featured: Boolean_comparison_exp!, $catalogId: Int, $filters: [lesson_plan_bool_exp!] = [{}]) {
  lesson_plan(
    where: {_and: [{_and: $filters}, {featured: $featured}, {_or: $conditions}, {lesson_plan_id: {_is_null: false}}, {active: {_eq: true}}]}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    class_lesson_plans {
      class_lesson_plan_id
      class {
        class_id
        title
      }
    }
    ...lessonCatalogFragment
    module_lesson_plans_aggregate(
      where: {module: {lockable_content: {visibility: {_in: [public, for_sale]}}}}
    ) {
      aggregate {
        count
      }
    }
  }
  lesson_plan_aggregate(
    where: {_and: [{_and: $filters}, {featured: $featured}, {_or: $conditions}, {lesson_plan_id: {_is_null: false}}, {active: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${LessonCatalogFragmentFragmentDoc}`;

/**
 * __useGetLessonsCatalogQuery__
 *
 * To run a query within a React component, call `useGetLessonsCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsCatalogQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      featured: // value for 'featured'
 *      catalogId: // value for 'catalogId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetLessonsCatalogQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonsCatalogQuery, Types.GetLessonsCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonsCatalogQuery, Types.GetLessonsCatalogQueryVariables>(GetLessonsCatalogDocument, options);
      }
export function useGetLessonsCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonsCatalogQuery, Types.GetLessonsCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonsCatalogQuery, Types.GetLessonsCatalogQueryVariables>(GetLessonsCatalogDocument, options);
        }
export type GetLessonsCatalogQueryHookResult = ReturnType<typeof useGetLessonsCatalogQuery>;
export type GetLessonsCatalogLazyQueryHookResult = ReturnType<typeof useGetLessonsCatalogLazyQuery>;
export type GetLessonsCatalogQueryResult = Apollo.QueryResult<Types.GetLessonsCatalogQuery, Types.GetLessonsCatalogQueryVariables>;
export function refetchGetLessonsCatalogQuery(variables?: Types.GetLessonsCatalogQueryVariables) {
      return { query: GetLessonsCatalogDocument, variables: variables }
    }
export const GetLessonPlanByClassIdDocument = gql`
    query GetLessonPlanByClassId($classId: Int!, $limit: Int, $offset: Int, $order_by: order_by) {
  lesson_plan(
    where: {class_lesson_plans: {class_id: {_eq: $classId}, lesson_plan: {lesson_plan_id: {_is_null: false}}}}
    order_by: {title: $order_by}
    limit: $limit
    offset: $offset
  ) {
    assignment_created(args: {classid: $classId})
    assignments(where: {class_assignments: {class_id: {_eq: $classId}}}) {
      class_assignments {
        class_assignment_id
      }
    }
    ...LessonPlanExtendedFragment
  }
  lesson_plan_aggregate(
    where: {class_lesson_plans: {class_id: {_eq: $classId}, lesson_plan: {lesson_plan_id: {_is_null: false}}}}
  ) {
    aggregate {
      count
    }
  }
}
    ${LessonPlanExtendedFragmentFragmentDoc}`;

/**
 * __useGetLessonPlanByClassIdQuery__
 *
 * To run a query within a React component, call `useGetLessonPlanByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonPlanByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonPlanByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetLessonPlanByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonPlanByClassIdQuery, Types.GetLessonPlanByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonPlanByClassIdQuery, Types.GetLessonPlanByClassIdQueryVariables>(GetLessonPlanByClassIdDocument, options);
      }
export function useGetLessonPlanByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonPlanByClassIdQuery, Types.GetLessonPlanByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonPlanByClassIdQuery, Types.GetLessonPlanByClassIdQueryVariables>(GetLessonPlanByClassIdDocument, options);
        }
export type GetLessonPlanByClassIdQueryHookResult = ReturnType<typeof useGetLessonPlanByClassIdQuery>;
export type GetLessonPlanByClassIdLazyQueryHookResult = ReturnType<typeof useGetLessonPlanByClassIdLazyQuery>;
export type GetLessonPlanByClassIdQueryResult = Apollo.QueryResult<Types.GetLessonPlanByClassIdQuery, Types.GetLessonPlanByClassIdQueryVariables>;
export function refetchGetLessonPlanByClassIdQuery(variables?: Types.GetLessonPlanByClassIdQueryVariables) {
      return { query: GetLessonPlanByClassIdDocument, variables: variables }
    }
export const GetLessonPdfDocument = gql`
    query GetLessonPDF($lessonId: Int!) {
  lessonPlanPDF(filter: {lesson_plan_id: $lessonId}) {
    path
  }
}
    `;

/**
 * __useGetLessonPdfQuery__
 *
 * To run a query within a React component, call `useGetLessonPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonPdfQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGetLessonPdfQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonPdfQuery, Types.GetLessonPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonPdfQuery, Types.GetLessonPdfQueryVariables>(GetLessonPdfDocument, options);
      }
export function useGetLessonPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonPdfQuery, Types.GetLessonPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonPdfQuery, Types.GetLessonPdfQueryVariables>(GetLessonPdfDocument, options);
        }
export type GetLessonPdfQueryHookResult = ReturnType<typeof useGetLessonPdfQuery>;
export type GetLessonPdfLazyQueryHookResult = ReturnType<typeof useGetLessonPdfLazyQuery>;
export type GetLessonPdfQueryResult = Apollo.QueryResult<Types.GetLessonPdfQuery, Types.GetLessonPdfQueryVariables>;
export function refetchGetLessonPdfQuery(variables?: Types.GetLessonPdfQueryVariables) {
      return { query: GetLessonPdfDocument, variables: variables }
    }
export const GetClassLessonsByClassIdDocument = gql`
    query GetClassLessonsByClassId($limit: Int, $order: order_by = asc, $classId: Int!) {
  lesson_plan(
    limit: $limit
    where: {class_lesson_plans: {class_id: {_eq: $classId}}}
    order_by: {title: $order}
  ) {
    teacher_id
    lesson_plan_id
    image_path
    description
    title
    featured
    active
    module_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
    lesson_plan_catalog_item {
      catalog_item {
        name
      }
    }
    lockable_content {
      visibility
    }
  }
  lesson_plan_aggregate(where: {class_lesson_plans: {class_id: {_eq: $classId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetClassLessonsByClassIdQuery__
 *
 * To run a query within a React component, call `useGetClassLessonsByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassLessonsByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassLessonsByClassIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetClassLessonsByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassLessonsByClassIdQuery, Types.GetClassLessonsByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassLessonsByClassIdQuery, Types.GetClassLessonsByClassIdQueryVariables>(GetClassLessonsByClassIdDocument, options);
      }
export function useGetClassLessonsByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassLessonsByClassIdQuery, Types.GetClassLessonsByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassLessonsByClassIdQuery, Types.GetClassLessonsByClassIdQueryVariables>(GetClassLessonsByClassIdDocument, options);
        }
export type GetClassLessonsByClassIdQueryHookResult = ReturnType<typeof useGetClassLessonsByClassIdQuery>;
export type GetClassLessonsByClassIdLazyQueryHookResult = ReturnType<typeof useGetClassLessonsByClassIdLazyQuery>;
export type GetClassLessonsByClassIdQueryResult = Apollo.QueryResult<Types.GetClassLessonsByClassIdQuery, Types.GetClassLessonsByClassIdQueryVariables>;
export function refetchGetClassLessonsByClassIdQuery(variables?: Types.GetClassLessonsByClassIdQueryVariables) {
      return { query: GetClassLessonsByClassIdDocument, variables: variables }
    }
export const GetAllLessonPlansDocument = gql`
    query GetAllLessonPlans($search: [lesson_plan_bool_exp!], $offset: Int, $limit: Int) {
  lesson_plan(where: {_or: $search}, offset: $offset, limit: $limit) {
    lesson_plan_id
    teacher_id
    title
    description
    image_path
    lockable_content {
      visibility
    }
    module_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
    lesson_plan_catalog_item {
      catalog_item {
        name
        catalog {
          name
        }
      }
    }
  }
  lesson_plan_aggregate(where: {_or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllLessonPlansQuery__
 *
 * To run a query within a React component, call `useGetAllLessonPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLessonPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLessonPlansQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllLessonPlansQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllLessonPlansQuery, Types.GetAllLessonPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllLessonPlansQuery, Types.GetAllLessonPlansQueryVariables>(GetAllLessonPlansDocument, options);
      }
export function useGetAllLessonPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllLessonPlansQuery, Types.GetAllLessonPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllLessonPlansQuery, Types.GetAllLessonPlansQueryVariables>(GetAllLessonPlansDocument, options);
        }
export type GetAllLessonPlansQueryHookResult = ReturnType<typeof useGetAllLessonPlansQuery>;
export type GetAllLessonPlansLazyQueryHookResult = ReturnType<typeof useGetAllLessonPlansLazyQuery>;
export type GetAllLessonPlansQueryResult = Apollo.QueryResult<Types.GetAllLessonPlansQuery, Types.GetAllLessonPlansQueryVariables>;
export function refetchGetAllLessonPlansQuery(variables?: Types.GetAllLessonPlansQueryVariables) {
      return { query: GetAllLessonPlansDocument, variables: variables }
    }
export const GetLessonPlansByClassIdDocument = gql`
    query GetLessonPlansByClassId($classId: Int!, $search: [lesson_plan_bool_exp!], $limit: Int, $offset: Int) {
  lesson_plan(
    where: {class_lesson_plans: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
    offset: $offset
  ) {
    lesson_plan_id
    teacher_id
    title
    active
    featured
    description
    image_path
    lockable_content {
      visibility
    }
    lesson_plan_catalog_item {
      catalog_item {
        name
        catalog {
          name
        }
      }
    }
    module_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
  }
  lesson_plan_aggregate(
    where: {class_lesson_plans: {class_id: {_eq: $classId}}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetLessonPlansByClassIdQuery__
 *
 * To run a query within a React component, call `useGetLessonPlansByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonPlansByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonPlansByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetLessonPlansByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLessonPlansByClassIdQuery, Types.GetLessonPlansByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLessonPlansByClassIdQuery, Types.GetLessonPlansByClassIdQueryVariables>(GetLessonPlansByClassIdDocument, options);
      }
export function useGetLessonPlansByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLessonPlansByClassIdQuery, Types.GetLessonPlansByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLessonPlansByClassIdQuery, Types.GetLessonPlansByClassIdQueryVariables>(GetLessonPlansByClassIdDocument, options);
        }
export type GetLessonPlansByClassIdQueryHookResult = ReturnType<typeof useGetLessonPlansByClassIdQuery>;
export type GetLessonPlansByClassIdLazyQueryHookResult = ReturnType<typeof useGetLessonPlansByClassIdLazyQuery>;
export type GetLessonPlansByClassIdQueryResult = Apollo.QueryResult<Types.GetLessonPlansByClassIdQuery, Types.GetLessonPlansByClassIdQueryVariables>;
export function refetchGetLessonPlansByClassIdQuery(variables?: Types.GetLessonPlansByClassIdQueryVariables) {
      return { query: GetLessonPlansByClassIdDocument, variables: variables }
    }
export const GetSearchLessonPlansByClassIdDocument = gql`
    query GetSearchLessonPlansByClassId($classId: Int!, $search: [lesson_plan_bool_exp!], $limit: Int) {
  lesson_plan(
    where: {class_lesson_plans: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
  ) {
    lesson_plan_id
    teacher_id
    title
    active
    featured
    description
    image_path
    lockable_content {
      visibility
    }
    lesson_plan_catalog_item {
      catalog_item {
        name
        catalog {
          name
        }
      }
    }
    module_lesson_plans_aggregate {
      aggregate {
        count
      }
    }
  }
  lesson_plan_aggregate(
    where: {class_lesson_plans: {class_id: {_eq: $classId}}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSearchLessonPlansByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSearchLessonPlansByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchLessonPlansByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchLessonPlansByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSearchLessonPlansByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSearchLessonPlansByClassIdQuery, Types.GetSearchLessonPlansByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchLessonPlansByClassIdQuery, Types.GetSearchLessonPlansByClassIdQueryVariables>(GetSearchLessonPlansByClassIdDocument, options);
      }
export function useGetSearchLessonPlansByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchLessonPlansByClassIdQuery, Types.GetSearchLessonPlansByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchLessonPlansByClassIdQuery, Types.GetSearchLessonPlansByClassIdQueryVariables>(GetSearchLessonPlansByClassIdDocument, options);
        }
export type GetSearchLessonPlansByClassIdQueryHookResult = ReturnType<typeof useGetSearchLessonPlansByClassIdQuery>;
export type GetSearchLessonPlansByClassIdLazyQueryHookResult = ReturnType<typeof useGetSearchLessonPlansByClassIdLazyQuery>;
export type GetSearchLessonPlansByClassIdQueryResult = Apollo.QueryResult<Types.GetSearchLessonPlansByClassIdQuery, Types.GetSearchLessonPlansByClassIdQueryVariables>;
export function refetchGetSearchLessonPlansByClassIdQuery(variables?: Types.GetSearchLessonPlansByClassIdQueryVariables) {
      return { query: GetSearchLessonPlansByClassIdDocument, variables: variables }
    }
export const GetTeacherStandardsDocument = gql`
    query GetTeacherStandards($teacherId: Int) {
  class_lesson_plan(
    where: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}, lesson_plan: {lesson_plan_id: {_is_null: false}}}
  ) {
    lesson_plan {
      process
    }
  }
  class_module(
    where: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}, module: {module_lesson_plan: {lesson_plan: {lesson_plan_id: {_is_null: false}}}}}
  ) {
    module {
      module_lesson_plan {
        lesson_plan {
          process
        }
      }
    }
  }
}
    `;

/**
 * __useGetTeacherStandardsQuery__
 *
 * To run a query within a React component, call `useGetTeacherStandardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherStandardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherStandardsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherStandardsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTeacherStandardsQuery, Types.GetTeacherStandardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherStandardsQuery, Types.GetTeacherStandardsQueryVariables>(GetTeacherStandardsDocument, options);
      }
export function useGetTeacherStandardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherStandardsQuery, Types.GetTeacherStandardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherStandardsQuery, Types.GetTeacherStandardsQueryVariables>(GetTeacherStandardsDocument, options);
        }
export type GetTeacherStandardsQueryHookResult = ReturnType<typeof useGetTeacherStandardsQuery>;
export type GetTeacherStandardsLazyQueryHookResult = ReturnType<typeof useGetTeacherStandardsLazyQuery>;
export type GetTeacherStandardsQueryResult = Apollo.QueryResult<Types.GetTeacherStandardsQuery, Types.GetTeacherStandardsQueryVariables>;
export function refetchGetTeacherStandardsQuery(variables?: Types.GetTeacherStandardsQueryVariables) {
      return { query: GetTeacherStandardsDocument, variables: variables }
    }
export const GetTeacherSkillsDocument = gql`
    query GetTeacherSkills($teacherId: Int) {
  class(where: {class_teachers: {teacher_id: {_eq: $teacherId}}}) {
    social_awareness: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: social_awareness}}}}}
    ) {
      aggregate {
        count
      }
    }
    self_awareness: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: self_awareness}}}}}
    ) {
      aggregate {
        count
      }
    }
    self_management: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: self_management}}}}}
    ) {
      aggregate {
        count
      }
    }
    responsible_decision_making: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: responsible_decision_making}}}}}
    ) {
      aggregate {
        count
      }
    }
    relationship_skills: class_lesson_plans_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}, lesson_plan_non_musical_skill: {non_musical_skill: {type: {_eq: relationship_skills}}}}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetTeacherSkillsQuery__
 *
 * To run a query within a React component, call `useGetTeacherSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherSkillsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherSkillsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTeacherSkillsQuery, Types.GetTeacherSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherSkillsQuery, Types.GetTeacherSkillsQueryVariables>(GetTeacherSkillsDocument, options);
      }
export function useGetTeacherSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherSkillsQuery, Types.GetTeacherSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherSkillsQuery, Types.GetTeacherSkillsQueryVariables>(GetTeacherSkillsDocument, options);
        }
export type GetTeacherSkillsQueryHookResult = ReturnType<typeof useGetTeacherSkillsQuery>;
export type GetTeacherSkillsLazyQueryHookResult = ReturnType<typeof useGetTeacherSkillsLazyQuery>;
export type GetTeacherSkillsQueryResult = Apollo.QueryResult<Types.GetTeacherSkillsQuery, Types.GetTeacherSkillsQueryVariables>;
export function refetchGetTeacherSkillsQuery(variables?: Types.GetTeacherSkillsQueryVariables) {
      return { query: GetTeacherSkillsDocument, variables: variables }
    }
export const LoginTeacherDocument = gql`
    query LoginTeacher($email: String!, $password: String!) {
  login(filter: {email: $email, password: $password, isTeacher: true}) {
    token
  }
}
    `;

/**
 * __useLoginTeacherQuery__
 *
 * To run a query within a React component, call `useLoginTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginTeacherQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginTeacherQuery(baseOptions: Apollo.QueryHookOptions<Types.LoginTeacherQuery, Types.LoginTeacherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoginTeacherQuery, Types.LoginTeacherQueryVariables>(LoginTeacherDocument, options);
      }
export function useLoginTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoginTeacherQuery, Types.LoginTeacherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoginTeacherQuery, Types.LoginTeacherQueryVariables>(LoginTeacherDocument, options);
        }
export type LoginTeacherQueryHookResult = ReturnType<typeof useLoginTeacherQuery>;
export type LoginTeacherLazyQueryHookResult = ReturnType<typeof useLoginTeacherLazyQuery>;
export type LoginTeacherQueryResult = Apollo.QueryResult<Types.LoginTeacherQuery, Types.LoginTeacherQueryVariables>;
export function refetchLoginTeacherQuery(variables?: Types.LoginTeacherQueryVariables) {
      return { query: LoginTeacherDocument, variables: variables }
    }
export const GetMembershipTypesDocument = gql`
    query GetMembershipTypes {
  membership_type(order_by: {name: asc}) {
    membership_type_id
    name
    billing_method
    price
    icon_name
    stripe_price_id
  }
}
    `;

/**
 * __useGetMembershipTypesQuery__
 *
 * To run a query within a React component, call `useGetMembershipTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembershipTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMembershipTypesQuery, Types.GetMembershipTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMembershipTypesQuery, Types.GetMembershipTypesQueryVariables>(GetMembershipTypesDocument, options);
      }
export function useGetMembershipTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMembershipTypesQuery, Types.GetMembershipTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMembershipTypesQuery, Types.GetMembershipTypesQueryVariables>(GetMembershipTypesDocument, options);
        }
export type GetMembershipTypesQueryHookResult = ReturnType<typeof useGetMembershipTypesQuery>;
export type GetMembershipTypesLazyQueryHookResult = ReturnType<typeof useGetMembershipTypesLazyQuery>;
export type GetMembershipTypesQueryResult = Apollo.QueryResult<Types.GetMembershipTypesQuery, Types.GetMembershipTypesQueryVariables>;
export function refetchGetMembershipTypesQuery(variables?: Types.GetMembershipTypesQueryVariables) {
      return { query: GetMembershipTypesDocument, variables: variables }
    }
export const SetInsertModuleToClassesDocument = gql`
    mutation SetInsertModuleToClasses($classes: [class_module_insert_input!]!) {
  insert_class_module(objects: $classes) {
    returning {
      class_module_id
      class {
        class_id
      }
      module {
        module_id
      }
    }
  }
}
    `;
export type SetInsertModuleToClassesMutationFn = Apollo.MutationFunction<Types.SetInsertModuleToClassesMutation, Types.SetInsertModuleToClassesMutationVariables>;

/**
 * __useSetInsertModuleToClassesMutation__
 *
 * To run a mutation, you first call `useSetInsertModuleToClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInsertModuleToClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInsertModuleToClassesMutation, { data, loading, error }] = useSetInsertModuleToClassesMutation({
 *   variables: {
 *      classes: // value for 'classes'
 *   },
 * });
 */
export function useSetInsertModuleToClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetInsertModuleToClassesMutation, Types.SetInsertModuleToClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetInsertModuleToClassesMutation, Types.SetInsertModuleToClassesMutationVariables>(SetInsertModuleToClassesDocument, options);
      }
export type SetInsertModuleToClassesMutationHookResult = ReturnType<typeof useSetInsertModuleToClassesMutation>;
export type SetInsertModuleToClassesMutationResult = Apollo.MutationResult<Types.SetInsertModuleToClassesMutation>;
export type SetInsertModuleToClassesMutationOptions = Apollo.BaseMutationOptions<Types.SetInsertModuleToClassesMutation, Types.SetInsertModuleToClassesMutationVariables>;
export const UpdateClassModulesDocument = gql`
    mutation UpdateClassModules($class_id: Int!, $moduleIds: [class_module_insert_input!]!) {
  delete_class_module(where: {class_id: {_eq: $class_id}}) {
    affected_rows
  }
  insert_class_module(objects: $moduleIds) {
    affected_rows
  }
}
    `;
export type UpdateClassModulesMutationFn = Apollo.MutationFunction<Types.UpdateClassModulesMutation, Types.UpdateClassModulesMutationVariables>;

/**
 * __useUpdateClassModulesMutation__
 *
 * To run a mutation, you first call `useUpdateClassModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassModulesMutation, { data, loading, error }] = useUpdateClassModulesMutation({
 *   variables: {
 *      class_id: // value for 'class_id'
 *      moduleIds: // value for 'moduleIds'
 *   },
 * });
 */
export function useUpdateClassModulesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClassModulesMutation, Types.UpdateClassModulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateClassModulesMutation, Types.UpdateClassModulesMutationVariables>(UpdateClassModulesDocument, options);
      }
export type UpdateClassModulesMutationHookResult = ReturnType<typeof useUpdateClassModulesMutation>;
export type UpdateClassModulesMutationResult = Apollo.MutationResult<Types.UpdateClassModulesMutation>;
export type UpdateClassModulesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClassModulesMutation, Types.UpdateClassModulesMutationVariables>;
export const GetModuleByPkDocument = gql`
    query GetModuleByPk($moduleId: Int!, $teacherId: Int!, $classId: Int) {
  module(where: {module_id: {_eq: $moduleId}, active: {_eq: true}}) {
    ...ModuleFragment
    module_lesson_plan(
      order_by: {order: asc}
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      module_lesson_plan_id
      lesson_plan_id
      module_id
      lesson_plan {
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
        is_favorite(args: {teacherid: $teacherId})
        is_purchased_by_teacher
        assignment_created(args: {classid: $classId})
        assignments(where: {class_assignments: {class_id: {_eq: $classId}}}) {
          class_assignments {
            class_assignment_id
          }
        }
        lockable_content {
          visibility
        }
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        ...LessonPlanOnlyCatalogsFragment
      }
    }
  }
}
    ${ModuleFragmentFragmentDoc}
${LessonPlanOnlyCatalogsFragmentFragmentDoc}`;

/**
 * __useGetModuleByPkQuery__
 *
 * To run a query within a React component, call `useGetModuleByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleByPkQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetModuleByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModuleByPkQuery, Types.GetModuleByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModuleByPkQuery, Types.GetModuleByPkQueryVariables>(GetModuleByPkDocument, options);
      }
export function useGetModuleByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModuleByPkQuery, Types.GetModuleByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModuleByPkQuery, Types.GetModuleByPkQueryVariables>(GetModuleByPkDocument, options);
        }
export type GetModuleByPkQueryHookResult = ReturnType<typeof useGetModuleByPkQuery>;
export type GetModuleByPkLazyQueryHookResult = ReturnType<typeof useGetModuleByPkLazyQuery>;
export type GetModuleByPkQueryResult = Apollo.QueryResult<Types.GetModuleByPkQuery, Types.GetModuleByPkQueryVariables>;
export function refetchGetModuleByPkQuery(variables?: Types.GetModuleByPkQueryVariables) {
      return { query: GetModuleByPkDocument, variables: variables }
    }
export const GetModulePriceDocument = gql`
    query GetModulePrice($productId: Int!) {
  module(where: {module_id: {_eq: $productId}, active: {_eq: true}}) {
    module_id
    annual_price
    monthly_price
    monthly_price_stripe_price_id
    annual_price_stripe_price_id
    stripe_product_id
    title
  }
}
    `;

/**
 * __useGetModulePriceQuery__
 *
 * To run a query within a React component, call `useGetModulePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulePriceQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetModulePriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModulePriceQuery, Types.GetModulePriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModulePriceQuery, Types.GetModulePriceQueryVariables>(GetModulePriceDocument, options);
      }
export function useGetModulePriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModulePriceQuery, Types.GetModulePriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModulePriceQuery, Types.GetModulePriceQueryVariables>(GetModulePriceDocument, options);
        }
export type GetModulePriceQueryHookResult = ReturnType<typeof useGetModulePriceQuery>;
export type GetModulePriceLazyQueryHookResult = ReturnType<typeof useGetModulePriceLazyQuery>;
export type GetModulePriceQueryResult = Apollo.QueryResult<Types.GetModulePriceQuery, Types.GetModulePriceQueryVariables>;
export function refetchGetModulePriceQuery(variables?: Types.GetModulePriceQueryVariables) {
      return { query: GetModulePriceDocument, variables: variables }
    }
export const GetModulesDocument = gql`
    query GetModules($search: [module_bool_exp!], $offset: Int, $limit: Int, $order_by: [module_order_by!], $teacher_id: Int!) {
  module(
    where: {_and: [{_or: $search}, {active: {_eq: true}}, {module_id: {_is_null: false}}, {module_lesson_plan: {}}, {module_lesson_plan: {lesson_plan: {lockable_content: {visibility: {_in: [public, for_sale]}}}}}]}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    module_id
    image_path
    title
    description
    is_purchased_by_teacher
    get_status_by_teacher
    module_lesson_plan {
      lesson_plan_id
      module_id
      lesson_plan {
        lesson_plan_catalog_item {
          catalog_item {
            catalog_item_id
            name
            catalog {
              catalog_id
              name
            }
          }
        }
      }
    }
    module_lesson_plan_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
    class_modules {
      class_module_id
      class {
        title
        class_id
      }
    }
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
  }
  module_aggregate(
    where: {_and: [{_or: $search}, {active: {_eq: true}}, {module_id: {_is_null: false}}, {module_lesson_plan: {}}, {module_lesson_plan: {lesson_plan: {lockable_content: {visibility: {_in: [public, for_sale]}}}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useGetModulesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModulesQuery, Types.GetModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModulesQuery, Types.GetModulesQueryVariables>(GetModulesDocument, options);
      }
export function useGetModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModulesQuery, Types.GetModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModulesQuery, Types.GetModulesQueryVariables>(GetModulesDocument, options);
        }
export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>;
export type GetModulesQueryResult = Apollo.QueryResult<Types.GetModulesQuery, Types.GetModulesQueryVariables>;
export function refetchGetModulesQuery(variables?: Types.GetModulesQueryVariables) {
      return { query: GetModulesDocument, variables: variables }
    }
export const GetModuleByLessonPlanIdDocument = gql`
    query GetModuleByLessonPlanId($lessonPlanId: Int!) {
  module(where: {module_lesson_plan: {lesson_plan_id: {_eq: $lessonPlanId}}}) {
    module_id
    image_path
    title
    description
    is_purchased_by_teacher
    lockable_content {
      visibility
    }
    module_lesson_plan_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
    class_modules {
      class_module_id
      class {
        title
        class_id
      }
    }
  }
}
    `;

/**
 * __useGetModuleByLessonPlanIdQuery__
 *
 * To run a query within a React component, call `useGetModuleByLessonPlanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleByLessonPlanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleByLessonPlanIdQuery({
 *   variables: {
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useGetModuleByLessonPlanIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModuleByLessonPlanIdQuery, Types.GetModuleByLessonPlanIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModuleByLessonPlanIdQuery, Types.GetModuleByLessonPlanIdQueryVariables>(GetModuleByLessonPlanIdDocument, options);
      }
export function useGetModuleByLessonPlanIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModuleByLessonPlanIdQuery, Types.GetModuleByLessonPlanIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModuleByLessonPlanIdQuery, Types.GetModuleByLessonPlanIdQueryVariables>(GetModuleByLessonPlanIdDocument, options);
        }
export type GetModuleByLessonPlanIdQueryHookResult = ReturnType<typeof useGetModuleByLessonPlanIdQuery>;
export type GetModuleByLessonPlanIdLazyQueryHookResult = ReturnType<typeof useGetModuleByLessonPlanIdLazyQuery>;
export type GetModuleByLessonPlanIdQueryResult = Apollo.QueryResult<Types.GetModuleByLessonPlanIdQuery, Types.GetModuleByLessonPlanIdQueryVariables>;
export function refetchGetModuleByLessonPlanIdQuery(variables?: Types.GetModuleByLessonPlanIdQueryVariables) {
      return { query: GetModuleByLessonPlanIdDocument, variables: variables }
    }
export const GetModuleAggregateDocument = gql`
    query GetModuleAggregate($lessonPlanId: Int!) {
  module_lesson_plan_aggregate(
    where: {lesson_plan_id: {_eq: $lessonPlanId}, module: {module_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetModuleAggregateQuery__
 *
 * To run a query within a React component, call `useGetModuleAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleAggregateQuery({
 *   variables: {
 *      lessonPlanId: // value for 'lessonPlanId'
 *   },
 * });
 */
export function useGetModuleAggregateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModuleAggregateQuery, Types.GetModuleAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModuleAggregateQuery, Types.GetModuleAggregateQueryVariables>(GetModuleAggregateDocument, options);
      }
export function useGetModuleAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModuleAggregateQuery, Types.GetModuleAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModuleAggregateQuery, Types.GetModuleAggregateQueryVariables>(GetModuleAggregateDocument, options);
        }
export type GetModuleAggregateQueryHookResult = ReturnType<typeof useGetModuleAggregateQuery>;
export type GetModuleAggregateLazyQueryHookResult = ReturnType<typeof useGetModuleAggregateLazyQuery>;
export type GetModuleAggregateQueryResult = Apollo.QueryResult<Types.GetModuleAggregateQuery, Types.GetModuleAggregateQueryVariables>;
export function refetchGetModuleAggregateQuery(variables?: Types.GetModuleAggregateQueryVariables) {
      return { query: GetModuleAggregateDocument, variables: variables }
    }
export const GetModulesLessonsPageDocument = gql`
    query getModulesLessonsPage($search: String = "%%", $offset: Int, $limit: Int, $order: order_by = asc) {
  module(
    where: {_and: [{_or: [{title: {_ilike: $search}}, {description: {_ilike: $search}}]}, {active: {_eq: true}}, {module_id: {_is_null: false}}, {module_lesson_plan: {}}]}
    offset: $offset
    limit: $limit
    order_by: {order: $order}
  ) {
    module_id
    image_path
    title
    order
    description
    module_lesson_plan(where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}) {
      lesson_plan_id
      module_id
    }
  }
}
    `;

/**
 * __useGetModulesLessonsPageQuery__
 *
 * To run a query within a React component, call `useGetModulesLessonsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesLessonsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesLessonsPageQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetModulesLessonsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetModulesLessonsPageQuery, Types.GetModulesLessonsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModulesLessonsPageQuery, Types.GetModulesLessonsPageQueryVariables>(GetModulesLessonsPageDocument, options);
      }
export function useGetModulesLessonsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModulesLessonsPageQuery, Types.GetModulesLessonsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModulesLessonsPageQuery, Types.GetModulesLessonsPageQueryVariables>(GetModulesLessonsPageDocument, options);
        }
export type GetModulesLessonsPageQueryHookResult = ReturnType<typeof useGetModulesLessonsPageQuery>;
export type GetModulesLessonsPageLazyQueryHookResult = ReturnType<typeof useGetModulesLessonsPageLazyQuery>;
export type GetModulesLessonsPageQueryResult = Apollo.QueryResult<Types.GetModulesLessonsPageQuery, Types.GetModulesLessonsPageQueryVariables>;
export function refetchGetModulesLessonsPageQuery(variables?: Types.GetModulesLessonsPageQueryVariables) {
      return { query: GetModulesLessonsPageDocument, variables: variables }
    }
export const GetModulesDataByClassIdDocument = gql`
    query GetModulesDataByClassId($classId: Int!, $limit: Int!) {
  module(where: {class_modules: {class_id: {_eq: $classId}}}, limit: $limit) {
    module_id
    image_path
    title
    description
    active
    module_lesson_plan {
      lesson_plan_id
      module_id
      order
      lesson_plan {
        lesson_plan_catalog_item {
          catalog_item {
            name
          }
        }
      }
    }
    lockable_content {
      lockable_content_id
      visibility
    }
    module_lesson_plan_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
  module_aggregate(where: {class_modules: {class_id: {_eq: $classId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetModulesDataByClassIdQuery__
 *
 * To run a query within a React component, call `useGetModulesDataByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesDataByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesDataByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetModulesDataByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModulesDataByClassIdQuery, Types.GetModulesDataByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModulesDataByClassIdQuery, Types.GetModulesDataByClassIdQueryVariables>(GetModulesDataByClassIdDocument, options);
      }
export function useGetModulesDataByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModulesDataByClassIdQuery, Types.GetModulesDataByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModulesDataByClassIdQuery, Types.GetModulesDataByClassIdQueryVariables>(GetModulesDataByClassIdDocument, options);
        }
export type GetModulesDataByClassIdQueryHookResult = ReturnType<typeof useGetModulesDataByClassIdQuery>;
export type GetModulesDataByClassIdLazyQueryHookResult = ReturnType<typeof useGetModulesDataByClassIdLazyQuery>;
export type GetModulesDataByClassIdQueryResult = Apollo.QueryResult<Types.GetModulesDataByClassIdQuery, Types.GetModulesDataByClassIdQueryVariables>;
export function refetchGetModulesDataByClassIdQuery(variables?: Types.GetModulesDataByClassIdQueryVariables) {
      return { query: GetModulesDataByClassIdDocument, variables: variables }
    }
export const GetAllModulesDialogDocument = gql`
    query GetAllModulesDialog($search: [module_bool_exp!], $offset: Int, $limit: Int) {
  module(where: {_or: $search}, offset: $offset, limit: $limit) {
    module_id
    image_path
    title
    lockable_content_id
    description
    active
    lockable_content {
      lockable_content_id
      visibility
    }
    module_lesson_plan_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
  module_aggregate(where: {_or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllModulesDialogQuery__
 *
 * To run a query within a React component, call `useGetAllModulesDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModulesDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllModulesDialogQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllModulesDialogQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllModulesDialogQuery, Types.GetAllModulesDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllModulesDialogQuery, Types.GetAllModulesDialogQueryVariables>(GetAllModulesDialogDocument, options);
      }
export function useGetAllModulesDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllModulesDialogQuery, Types.GetAllModulesDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllModulesDialogQuery, Types.GetAllModulesDialogQueryVariables>(GetAllModulesDialogDocument, options);
        }
export type GetAllModulesDialogQueryHookResult = ReturnType<typeof useGetAllModulesDialogQuery>;
export type GetAllModulesDialogLazyQueryHookResult = ReturnType<typeof useGetAllModulesDialogLazyQuery>;
export type GetAllModulesDialogQueryResult = Apollo.QueryResult<Types.GetAllModulesDialogQuery, Types.GetAllModulesDialogQueryVariables>;
export function refetchGetAllModulesDialogQuery(variables?: Types.GetAllModulesDialogQueryVariables) {
      return { query: GetAllModulesDialogDocument, variables: variables }
    }
export const GetModulesByClassIdDocument = gql`
    query GetModulesByClassId($classId: Int!, $search: [module_bool_exp!], $limit: Int, $offset: Int) {
  module(
    where: {class_modules: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
    offset: $offset
  ) {
    module_id
    image_path
    title
    lockable_content_id
    description
    active
    lockable_content {
      lockable_content_id
      visibility
    }
    module_lesson_plan_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
  module_aggregate(
    where: {class_modules: {class_id: {_eq: $classId}}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetModulesByClassIdQuery__
 *
 * To run a query within a React component, call `useGetModulesByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetModulesByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetModulesByClassIdQuery, Types.GetModulesByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetModulesByClassIdQuery, Types.GetModulesByClassIdQueryVariables>(GetModulesByClassIdDocument, options);
      }
export function useGetModulesByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetModulesByClassIdQuery, Types.GetModulesByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetModulesByClassIdQuery, Types.GetModulesByClassIdQueryVariables>(GetModulesByClassIdDocument, options);
        }
export type GetModulesByClassIdQueryHookResult = ReturnType<typeof useGetModulesByClassIdQuery>;
export type GetModulesByClassIdLazyQueryHookResult = ReturnType<typeof useGetModulesByClassIdLazyQuery>;
export type GetModulesByClassIdQueryResult = Apollo.QueryResult<Types.GetModulesByClassIdQuery, Types.GetModulesByClassIdQueryVariables>;
export function refetchGetModulesByClassIdQuery(variables?: Types.GetModulesByClassIdQueryVariables) {
      return { query: GetModulesByClassIdDocument, variables: variables }
    }
export const GetSearchModulesByClassIdDocument = gql`
    query GetSearchModulesByClassId($classId: Int!, $search: [module_bool_exp!], $limit: Int) {
  module(
    where: {class_modules: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
  ) {
    module_id
    image_path
    title
    lockable_content_id
    description
    active
    lockable_content {
      lockable_content_id
      visibility
    }
    module_lesson_plan_aggregate(
      where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
  module_aggregate(
    where: {class_modules: {class_id: {_eq: $classId}}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSearchModulesByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSearchModulesByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchModulesByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchModulesByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSearchModulesByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSearchModulesByClassIdQuery, Types.GetSearchModulesByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchModulesByClassIdQuery, Types.GetSearchModulesByClassIdQueryVariables>(GetSearchModulesByClassIdDocument, options);
      }
export function useGetSearchModulesByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchModulesByClassIdQuery, Types.GetSearchModulesByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchModulesByClassIdQuery, Types.GetSearchModulesByClassIdQueryVariables>(GetSearchModulesByClassIdDocument, options);
        }
export type GetSearchModulesByClassIdQueryHookResult = ReturnType<typeof useGetSearchModulesByClassIdQuery>;
export type GetSearchModulesByClassIdLazyQueryHookResult = ReturnType<typeof useGetSearchModulesByClassIdLazyQuery>;
export type GetSearchModulesByClassIdQueryResult = Apollo.QueryResult<Types.GetSearchModulesByClassIdQuery, Types.GetSearchModulesByClassIdQueryVariables>;
export function refetchGetSearchModulesByClassIdQuery(variables?: Types.GetSearchModulesByClassIdQueryVariables) {
      return { query: GetSearchModulesByClassIdDocument, variables: variables }
    }
export const GetOrdersDocument = gql`
    query GetOrders($offset: Int, $limit: Int, $teacherId: Int!) {
  order(
    where: {teacher_id: {_eq: $teacherId}}
    limit: $limit
    offset: $offset
    order_by: {created_at: desc}
  ) {
    order_id
    total
    created_at
    channel {
      __typename
      title
    }
    partner {
      __typename
      title
    }
    playlist {
      __typename
      name
    }
    module {
      __typename
      title
    }
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrdersQuery, Types.GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrdersQuery, Types.GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrdersQuery, Types.GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrdersQuery, Types.GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<Types.GetOrdersQuery, Types.GetOrdersQueryVariables>;
export function refetchGetOrdersQuery(variables?: Types.GetOrdersQueryVariables) {
      return { query: GetOrdersDocument, variables: variables }
    }
export const GetTeacherSubscriptionsDocument = gql`
    query GetTeacherSubscriptions($search: [order_bool_exp!] = [{}], $teacherId: Int!, $limit: Int, $offset: Int, $productName: product_name_enum, $order_by: [order_order_by!] = {created_at: desc}, $price_type: price_type_enum) {
  order(
    where: {teacher_id: {_eq: $teacherId}, product_name: {_eq: $productName}, expires_at: {_gte: "now()"}, price_type: {_eq: $price_type}, _or: $search}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    total
    order_id
    price_type
    expires_at
    status
    stripe_subscription_id
    channel {
      ...channelFragment
    }
    partner {
      ...partnerFragment
    }
    playlist {
      ...playlistFragment
    }
    module {
      ...moduleSuscFragment
    }
  }
  order_aggregate(
    where: {teacher_id: {_eq: $teacherId}, product_name: {_eq: $productName}, price_type: {_eq: $price_type}, expires_at: {_gte: "now()"}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    ${ChannelFragmentFragmentDoc}
${PartnerFragmentFragmentDoc}
${PlaylistFragmentFragmentDoc}
${ModuleSuscFragmentFragmentDoc}`;

/**
 * __useGetTeacherSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetTeacherSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherSubscriptionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      teacherId: // value for 'teacherId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      productName: // value for 'productName'
 *      order_by: // value for 'order_by'
 *      price_type: // value for 'price_type'
 *   },
 * });
 */
export function useGetTeacherSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherSubscriptionsQuery, Types.GetTeacherSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherSubscriptionsQuery, Types.GetTeacherSubscriptionsQueryVariables>(GetTeacherSubscriptionsDocument, options);
      }
export function useGetTeacherSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherSubscriptionsQuery, Types.GetTeacherSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherSubscriptionsQuery, Types.GetTeacherSubscriptionsQueryVariables>(GetTeacherSubscriptionsDocument, options);
        }
export type GetTeacherSubscriptionsQueryHookResult = ReturnType<typeof useGetTeacherSubscriptionsQuery>;
export type GetTeacherSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetTeacherSubscriptionsLazyQuery>;
export type GetTeacherSubscriptionsQueryResult = Apollo.QueryResult<Types.GetTeacherSubscriptionsQuery, Types.GetTeacherSubscriptionsQueryVariables>;
export function refetchGetTeacherSubscriptionsQuery(variables?: Types.GetTeacherSubscriptionsQueryVariables) {
      return { query: GetTeacherSubscriptionsDocument, variables: variables }
    }
export const GetTotalSubscriptionsDocument = gql`
    query GetTotalSubscriptions($teacherId: Int!, $startDate: timestamptz!, $endDate: timestamptz!) {
  Annual_price: order_aggregate(
    where: {teacher_id: {_eq: $teacherId}, price_type: {_eq: annual}, created_at: {_gte: $startDate, _lte: $endDate}}
    order_by: {created_at: desc}
  ) {
    aggregate {
      sum {
        total
      }
    }
  }
  Monthly_price: order_aggregate(
    where: {teacher_id: {_eq: $teacherId}, price_type: {_eq: monthly}, created_at: {_gte: $startDate, _lte: $endDate}}
    order_by: {created_at: desc}
  ) {
    aggregate {
      sum {
        total
      }
    }
  }
}
    `;

/**
 * __useGetTotalSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetTotalSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalSubscriptionsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetTotalSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTotalSubscriptionsQuery, Types.GetTotalSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTotalSubscriptionsQuery, Types.GetTotalSubscriptionsQueryVariables>(GetTotalSubscriptionsDocument, options);
      }
export function useGetTotalSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTotalSubscriptionsQuery, Types.GetTotalSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTotalSubscriptionsQuery, Types.GetTotalSubscriptionsQueryVariables>(GetTotalSubscriptionsDocument, options);
        }
export type GetTotalSubscriptionsQueryHookResult = ReturnType<typeof useGetTotalSubscriptionsQuery>;
export type GetTotalSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetTotalSubscriptionsLazyQuery>;
export type GetTotalSubscriptionsQueryResult = Apollo.QueryResult<Types.GetTotalSubscriptionsQuery, Types.GetTotalSubscriptionsQueryVariables>;
export function refetchGetTotalSubscriptionsQuery(variables?: Types.GetTotalSubscriptionsQueryVariables) {
      return { query: GetTotalSubscriptionsDocument, variables: variables }
    }
export const GetAllPartnerDocument = gql`
    query GetAllPartner {
  partner(
    where: {_and: [{active: {_eq: true}}, {_or: [{partner_modules: {}}, {partner_playlists: {}}]}]}
    order_by: {order: asc}
  ) {
    title
    partner_id
    banner_description
    banner_image_path
    logo_image_path
  }
}
    `;

/**
 * __useGetAllPartnerQuery__
 *
 * To run a query within a React component, call `useGetAllPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPartnerQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllPartnerQuery, Types.GetAllPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllPartnerQuery, Types.GetAllPartnerQueryVariables>(GetAllPartnerDocument, options);
      }
export function useGetAllPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllPartnerQuery, Types.GetAllPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllPartnerQuery, Types.GetAllPartnerQueryVariables>(GetAllPartnerDocument, options);
        }
export type GetAllPartnerQueryHookResult = ReturnType<typeof useGetAllPartnerQuery>;
export type GetAllPartnerLazyQueryHookResult = ReturnType<typeof useGetAllPartnerLazyQuery>;
export type GetAllPartnerQueryResult = Apollo.QueryResult<Types.GetAllPartnerQuery, Types.GetAllPartnerQueryVariables>;
export function refetchGetAllPartnerQuery(variables?: Types.GetAllPartnerQueryVariables) {
      return { query: GetAllPartnerDocument, variables: variables }
    }
export const GetAllFeaturedPartnerDocument = gql`
    query GetAllFeaturedPartner {
  partner(where: {active: {_eq: true}, feature: {_eq: true}, partner_modules: {}}) {
    title
    partner_id
    description
    image_path
  }
}
    `;

/**
 * __useGetAllFeaturedPartnerQuery__
 *
 * To run a query within a React component, call `useGetAllFeaturedPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeaturedPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFeaturedPartnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFeaturedPartnerQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllFeaturedPartnerQuery, Types.GetAllFeaturedPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllFeaturedPartnerQuery, Types.GetAllFeaturedPartnerQueryVariables>(GetAllFeaturedPartnerDocument, options);
      }
export function useGetAllFeaturedPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllFeaturedPartnerQuery, Types.GetAllFeaturedPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllFeaturedPartnerQuery, Types.GetAllFeaturedPartnerQueryVariables>(GetAllFeaturedPartnerDocument, options);
        }
export type GetAllFeaturedPartnerQueryHookResult = ReturnType<typeof useGetAllFeaturedPartnerQuery>;
export type GetAllFeaturedPartnerLazyQueryHookResult = ReturnType<typeof useGetAllFeaturedPartnerLazyQuery>;
export type GetAllFeaturedPartnerQueryResult = Apollo.QueryResult<Types.GetAllFeaturedPartnerQuery, Types.GetAllFeaturedPartnerQueryVariables>;
export function refetchGetAllFeaturedPartnerQuery(variables?: Types.GetAllFeaturedPartnerQueryVariables) {
      return { query: GetAllFeaturedPartnerDocument, variables: variables }
    }
export const GetPartnerPriceDocument = gql`
    query GetPartnerPrice($productId: Int!) {
  partner(where: {partner_id: {_eq: $productId}, active: {_eq: true}}) {
    partner_id
    annual_price
    monthly_price
    monthly_price_stripe_price_id
    annual_price_stripe_price_id
    stripe_product_id
    title
  }
}
    `;

/**
 * __useGetPartnerPriceQuery__
 *
 * To run a query within a React component, call `useGetPartnerPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerPriceQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetPartnerPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPartnerPriceQuery, Types.GetPartnerPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnerPriceQuery, Types.GetPartnerPriceQueryVariables>(GetPartnerPriceDocument, options);
      }
export function useGetPartnerPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerPriceQuery, Types.GetPartnerPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnerPriceQuery, Types.GetPartnerPriceQueryVariables>(GetPartnerPriceDocument, options);
        }
export type GetPartnerPriceQueryHookResult = ReturnType<typeof useGetPartnerPriceQuery>;
export type GetPartnerPriceLazyQueryHookResult = ReturnType<typeof useGetPartnerPriceLazyQuery>;
export type GetPartnerPriceQueryResult = Apollo.QueryResult<Types.GetPartnerPriceQuery, Types.GetPartnerPriceQueryVariables>;
export function refetchGetPartnerPriceQuery(variables?: Types.GetPartnerPriceQueryVariables) {
      return { query: GetPartnerPriceDocument, variables: variables }
    }
export const GetPartnerDataDocument = gql`
    query GetPartnerData($partnerId: Int!) {
  partner(where: {partner_id: {_eq: $partnerId}, active: {_eq: true}}) {
    title
    partner_id
    description
    image_path
    video_path
    short_description
    heading_short_description
    heading_description
    get_status_by_teacher
    is_purchased_by_teacher
    video_thumbnail_image_path
    partner_playlists {
      playlist {
        playlist_id
        image_path
        name
        description
        tip_playlist {
          tip_id
          playlist_id
          tip {
            ...tipExtendedFragment
          }
        }
        tip_playlist_aggregate(where: {playlist: {playlist_id: {_is_null: false}}}) {
          aggregate {
            count
          }
        }
        lockable_content {
          visibility
        }
        class_playlists {
          class_playlist_id
          class {
            title
            class_id
          }
        }
        order_details {
          order_id
        }
      }
    }
    partner_modules {
      module {
        module_id
        image_path
        title
        description
        is_purchased_by_teacher
        lockable_content {
          visibility
        }
        module_lesson_plan {
          lesson_plan_id
          module_id
        }
        module_lesson_plan_aggregate(
          where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
        ) {
          aggregate {
            count
          }
        }
        class_modules {
          class_module_id
          class {
            title
            class_id
          }
        }
      }
    }
    lockable_content {
      visibility
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetPartnerDataQuery__
 *
 * To run a query within a React component, call `useGetPartnerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerDataQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPartnerDataQuery, Types.GetPartnerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnerDataQuery, Types.GetPartnerDataQueryVariables>(GetPartnerDataDocument, options);
      }
export function useGetPartnerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerDataQuery, Types.GetPartnerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnerDataQuery, Types.GetPartnerDataQueryVariables>(GetPartnerDataDocument, options);
        }
export type GetPartnerDataQueryHookResult = ReturnType<typeof useGetPartnerDataQuery>;
export type GetPartnerDataLazyQueryHookResult = ReturnType<typeof useGetPartnerDataLazyQuery>;
export type GetPartnerDataQueryResult = Apollo.QueryResult<Types.GetPartnerDataQuery, Types.GetPartnerDataQueryVariables>;
export function refetchGetPartnerDataQuery(variables?: Types.GetPartnerDataQueryVariables) {
      return { query: GetPartnerDataDocument, variables: variables }
    }
export const GetPartnersDocument = gql`
    query GetPartners($search: [partner_bool_exp!], $limit: Int, $order_by: [partner_order_by!], $offset: Int, $active: Boolean_comparison_exp = {}, $includeCountPartners: Boolean = false, $teacher_id: Int!) {
  partner(
    order_by: $order_by
    limit: $limit
    offset: $offset
    where: {_and: [{_or: $search}, {_or: [{partner_modules: {}}, {partner_playlists: {}}]}, {active: {_eq: true}}]}
  ) {
    active
    description
    image_path
    title
    video_path
    short_description
    created_at
    feature
    partner_id
    partner_modules {
      module {
        title
        module_lesson_plan_aggregate(
          where: {lesson_plan: {lockable_content: {visibility: {_nin: restricted}}}}
        ) {
          aggregate {
            count(columns: lesson_plan_id)
          }
        }
      }
    }
    get_status_by_teacher
    is_purchased_by_teacher
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
  }
  partner_aggregate(
    where: {_and: [{_or: $search}, {_or: [{partner_modules: {}}, {partner_playlists: {}}]}, {active: {_eq: true}}]}
  ) @include(if: $includeCountPartners) {
    aggregate {
      count
    }
  }
  partner_module_aggregate {
    aggregate {
      count
    }
    nodes {
      module {
        module_lesson_plan_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      active: // value for 'active'
 *      includeCountPartners: // value for 'includeCountPartners'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useGetPartnersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPartnersQuery, Types.GetPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnersQuery, Types.GetPartnersQueryVariables>(GetPartnersDocument, options);
      }
export function useGetPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnersQuery, Types.GetPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnersQuery, Types.GetPartnersQueryVariables>(GetPartnersDocument, options);
        }
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersQueryResult = Apollo.QueryResult<Types.GetPartnersQuery, Types.GetPartnersQueryVariables>;
export function refetchGetPartnersQuery(variables?: Types.GetPartnersQueryVariables) {
      return { query: GetPartnersDocument, variables: variables }
    }
export const SearchPartnerDocument = gql`
    query SearchPartner($searchText: [partner_bool_exp!], $limit: Int, $offset: Int, $featured: Boolean, $conditions: [partner_bool_exp!], $order: order_by = asc, $teacher_id: Int!) {
  partner(
    limit: $limit
    offset: $offset
    where: {_and: [{_or: $searchText}, {_and: $conditions}, {_or: [{partner_modules: {}}, {partner_playlists: {}}]}, {active: {_eq: true}}, {feature: {_eq: $featured}}]}
    order_by: {title: $order}
  ) {
    active
    description
    get_status_by_teacher
    is_purchased_by_teacher
    image_path
    title
    video_path
    short_description
    created_at
    feature
    partner_id
    partner_modules {
      module {
        title
      }
    }
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
  }
}
    `;

/**
 * __useSearchPartnerQuery__
 *
 * To run a query within a React component, call `useSearchPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPartnerQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      featured: // value for 'featured'
 *      conditions: // value for 'conditions'
 *      order: // value for 'order'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useSearchPartnerQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchPartnerQuery, Types.SearchPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchPartnerQuery, Types.SearchPartnerQueryVariables>(SearchPartnerDocument, options);
      }
export function useSearchPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchPartnerQuery, Types.SearchPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchPartnerQuery, Types.SearchPartnerQueryVariables>(SearchPartnerDocument, options);
        }
export type SearchPartnerQueryHookResult = ReturnType<typeof useSearchPartnerQuery>;
export type SearchPartnerLazyQueryHookResult = ReturnType<typeof useSearchPartnerLazyQuery>;
export type SearchPartnerQueryResult = Apollo.QueryResult<Types.SearchPartnerQuery, Types.SearchPartnerQueryVariables>;
export function refetchSearchPartnerQuery(variables?: Types.SearchPartnerQueryVariables) {
      return { query: SearchPartnerDocument, variables: variables }
    }
export const GetPartnerTitleDocument = gql`
    query GetPartnerTitle($partner_id: Int!) {
  partner_by_pk(partner_id: $partner_id) {
    title
  }
}
    `;

/**
 * __useGetPartnerTitleQuery__
 *
 * To run a query within a React component, call `useGetPartnerTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerTitleQuery({
 *   variables: {
 *      partner_id: // value for 'partner_id'
 *   },
 * });
 */
export function useGetPartnerTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPartnerTitleQuery, Types.GetPartnerTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnerTitleQuery, Types.GetPartnerTitleQueryVariables>(GetPartnerTitleDocument, options);
      }
export function useGetPartnerTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerTitleQuery, Types.GetPartnerTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnerTitleQuery, Types.GetPartnerTitleQueryVariables>(GetPartnerTitleDocument, options);
        }
export type GetPartnerTitleQueryHookResult = ReturnType<typeof useGetPartnerTitleQuery>;
export type GetPartnerTitleLazyQueryHookResult = ReturnType<typeof useGetPartnerTitleLazyQuery>;
export type GetPartnerTitleQueryResult = Apollo.QueryResult<Types.GetPartnerTitleQuery, Types.GetPartnerTitleQueryVariables>;
export function refetchGetPartnerTitleQuery(variables?: Types.GetPartnerTitleQueryVariables) {
      return { query: GetPartnerTitleDocument, variables: variables }
    }
export const GetPartnerSubscriptionExpirationDateDocument = gql`
    query getPartnerSubscriptionExpirationDate($partnerId: Int!) {
  order(where: {partner_id: {_eq: $partnerId}}) {
    expires_at
    status
  }
}
    `;

/**
 * __useGetPartnerSubscriptionExpirationDateQuery__
 *
 * To run a query within a React component, call `useGetPartnerSubscriptionExpirationDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerSubscriptionExpirationDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerSubscriptionExpirationDateQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useGetPartnerSubscriptionExpirationDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPartnerSubscriptionExpirationDateQuery, Types.GetPartnerSubscriptionExpirationDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPartnerSubscriptionExpirationDateQuery, Types.GetPartnerSubscriptionExpirationDateQueryVariables>(GetPartnerSubscriptionExpirationDateDocument, options);
      }
export function useGetPartnerSubscriptionExpirationDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPartnerSubscriptionExpirationDateQuery, Types.GetPartnerSubscriptionExpirationDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPartnerSubscriptionExpirationDateQuery, Types.GetPartnerSubscriptionExpirationDateQueryVariables>(GetPartnerSubscriptionExpirationDateDocument, options);
        }
export type GetPartnerSubscriptionExpirationDateQueryHookResult = ReturnType<typeof useGetPartnerSubscriptionExpirationDateQuery>;
export type GetPartnerSubscriptionExpirationDateLazyQueryHookResult = ReturnType<typeof useGetPartnerSubscriptionExpirationDateLazyQuery>;
export type GetPartnerSubscriptionExpirationDateQueryResult = Apollo.QueryResult<Types.GetPartnerSubscriptionExpirationDateQuery, Types.GetPartnerSubscriptionExpirationDateQueryVariables>;
export function refetchGetPartnerSubscriptionExpirationDateQuery(variables?: Types.GetPartnerSubscriptionExpirationDateQueryVariables) {
      return { query: GetPartnerSubscriptionExpirationDateDocument, variables: variables }
    }
export const GetPlaylistsDocument = gql`
    query GetPlaylists($search: [playlist_bool_exp!] = [{name: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order_by: [playlist_order_by!], $teacher_id: Int!) {
  playlist(
    where: {_or: $search, tip_playlist: {}, active: {_eq: true}}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    playlist_id
    image_path
    name
    description
    tip_counter
    class_playlists {
      class {
        title
        class_id
      }
    }
    tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
      aggregate {
        count
      }
    }
    ...PlaylistTipsForGenresFragment
    get_status_by_teacher
    is_purchased_by_teacher
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
    lockable_content_id
  }
  playlist_aggregate(where: {_or: $search, tip_playlist: {}, active: {_eq: true}}) {
    aggregate {
      count
    }
  }
}
    ${PlaylistTipsForGenresFragmentFragmentDoc}`;

/**
 * __useGetPlaylistsQuery__
 *
 * To run a query within a React component, call `useGetPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useGetPlaylistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPlaylistsQuery, Types.GetPlaylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPlaylistsQuery, Types.GetPlaylistsQueryVariables>(GetPlaylistsDocument, options);
      }
export function useGetPlaylistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlaylistsQuery, Types.GetPlaylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPlaylistsQuery, Types.GetPlaylistsQueryVariables>(GetPlaylistsDocument, options);
        }
export type GetPlaylistsQueryHookResult = ReturnType<typeof useGetPlaylistsQuery>;
export type GetPlaylistsLazyQueryHookResult = ReturnType<typeof useGetPlaylistsLazyQuery>;
export type GetPlaylistsQueryResult = Apollo.QueryResult<Types.GetPlaylistsQuery, Types.GetPlaylistsQueryVariables>;
export function refetchGetPlaylistsQuery(variables?: Types.GetPlaylistsQueryVariables) {
      return { query: GetPlaylistsDocument, variables: variables }
    }
export const SearchPlaylistDocument = gql`
    query SearchPlaylist($searchText: [playlist_bool_exp!], $limit: Int, $offset: Int, $order_by: [playlist_order_by!], $teacher_id: Int!) {
  playlist(
    limit: $limit
    offset: $offset
    where: {_or: $searchText, tip_playlist: {}, active: {_eq: true}}
    order_by: $order_by
  ) {
    playlist_id
    image_path
    name
    description
    tip_counter
    class_playlists {
      class {
        title
        class_id
      }
    }
    tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
      aggregate {
        count
      }
    }
    ...PlaylistTipsForGenresFragment
    get_status_by_teacher
    is_purchased_by_teacher
    lockable_content {
      visibility
      schools(where: {school: {teachers: {teacher_id: {_eq: $teacher_id}}}}) {
        lockable_content_id
        school_id
        school {
          title: name
        }
      }
      districts(
        where: {district: {schools: {teachers: {teacher_id: {_eq: $teacher_id}}}}}
      ) {
        lockable_content_id
        district_id
        district {
          title: name
        }
      }
    }
    lockable_content_id
  }
}
    ${PlaylistTipsForGenresFragmentFragmentDoc}`;

/**
 * __useSearchPlaylistQuery__
 *
 * To run a query within a React component, call `useSearchPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlaylistQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      teacher_id: // value for 'teacher_id'
 *   },
 * });
 */
export function useSearchPlaylistQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchPlaylistQuery, Types.SearchPlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchPlaylistQuery, Types.SearchPlaylistQueryVariables>(SearchPlaylistDocument, options);
      }
export function useSearchPlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchPlaylistQuery, Types.SearchPlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchPlaylistQuery, Types.SearchPlaylistQueryVariables>(SearchPlaylistDocument, options);
        }
export type SearchPlaylistQueryHookResult = ReturnType<typeof useSearchPlaylistQuery>;
export type SearchPlaylistLazyQueryHookResult = ReturnType<typeof useSearchPlaylistLazyQuery>;
export type SearchPlaylistQueryResult = Apollo.QueryResult<Types.SearchPlaylistQuery, Types.SearchPlaylistQueryVariables>;
export function refetchSearchPlaylistQuery(variables?: Types.SearchPlaylistQueryVariables) {
      return { query: SearchPlaylistDocument, variables: variables }
    }
export const GetPlaylistDocument = gql`
    query GetPlaylist($playlistId: Int!) {
  playlist(where: {playlist_id: {_eq: $playlistId}, active: {_eq: true}}) {
    playlist_id
    image_path
    name
    description
    get_status_by_teacher
    is_purchased_by_teacher
    display_video
    header_image_path
    video_path
    video_thumbnail_image_path
    lockable_content {
      visibility
    }
    lockable_content_id
    tip_playlist(where: {tip: {tip_id: {_is_null: false}}}, order_by: {order: asc}) {
      tip_id
      playlist_id
      tip {
        ...tipExtendedFragment
      }
    }
    class_playlists {
      class {
        title
        class_id
      }
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetPlaylistQuery__
 *
 * To run a query within a React component, call `useGetPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useGetPlaylistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPlaylistQuery, Types.GetPlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPlaylistQuery, Types.GetPlaylistQueryVariables>(GetPlaylistDocument, options);
      }
export function useGetPlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlaylistQuery, Types.GetPlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPlaylistQuery, Types.GetPlaylistQueryVariables>(GetPlaylistDocument, options);
        }
export type GetPlaylistQueryHookResult = ReturnType<typeof useGetPlaylistQuery>;
export type GetPlaylistLazyQueryHookResult = ReturnType<typeof useGetPlaylistLazyQuery>;
export type GetPlaylistQueryResult = Apollo.QueryResult<Types.GetPlaylistQuery, Types.GetPlaylistQueryVariables>;
export function refetchGetPlaylistQuery(variables?: Types.GetPlaylistQueryVariables) {
      return { query: GetPlaylistDocument, variables: variables }
    }
export const GetPlaylistPriceDocument = gql`
    query GetPlaylistPrice($productId: Int!) {
  playlist(where: {playlist_id: {_eq: $productId}, active: {_eq: true}}) {
    playlist_id
    annual_price
    monthly_price
    monthly_price_stripe_price_id
    annual_price_stripe_price_id
    stripe_product_id
    name
  }
}
    `;

/**
 * __useGetPlaylistPriceQuery__
 *
 * To run a query within a React component, call `useGetPlaylistPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistPriceQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetPlaylistPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPlaylistPriceQuery, Types.GetPlaylistPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPlaylistPriceQuery, Types.GetPlaylistPriceQueryVariables>(GetPlaylistPriceDocument, options);
      }
export function useGetPlaylistPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlaylistPriceQuery, Types.GetPlaylistPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPlaylistPriceQuery, Types.GetPlaylistPriceQueryVariables>(GetPlaylistPriceDocument, options);
        }
export type GetPlaylistPriceQueryHookResult = ReturnType<typeof useGetPlaylistPriceQuery>;
export type GetPlaylistPriceLazyQueryHookResult = ReturnType<typeof useGetPlaylistPriceLazyQuery>;
export type GetPlaylistPriceQueryResult = Apollo.QueryResult<Types.GetPlaylistPriceQuery, Types.GetPlaylistPriceQueryVariables>;
export function refetchGetPlaylistPriceQuery(variables?: Types.GetPlaylistPriceQueryVariables) {
      return { query: GetPlaylistPriceDocument, variables: variables }
    }
export const GetAllPlaylistDocument = gql`
    query GetAllPlaylist($limit: Int, $search: [playlist_bool_exp!], $order_by: [playlist_order_by!], $offset: Int) {
  playlist(
    limit: $limit
    offset: $offset
    where: {_or: $search}
    order_by: {order: asc}
  ) {
    playlist_id
    name
    description
    image_path
    active
    featured
    order
    order_details {
      order_id
    }
    tip_playlist {
      tip {
        tip_catalog_item {
          catalog_item {
            name
            catalog {
              name
            }
          }
        }
      }
    }
    tip_playlist_aggregate {
      aggregate {
        count
      }
    }
    lockable_content {
      visibility
    }
  }
  playlist_aggregate(where: {_or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllPlaylistQuery__
 *
 * To run a query within a React component, call `useGetAllPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPlaylistQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllPlaylistQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllPlaylistQuery, Types.GetAllPlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllPlaylistQuery, Types.GetAllPlaylistQueryVariables>(GetAllPlaylistDocument, options);
      }
export function useGetAllPlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllPlaylistQuery, Types.GetAllPlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllPlaylistQuery, Types.GetAllPlaylistQueryVariables>(GetAllPlaylistDocument, options);
        }
export type GetAllPlaylistQueryHookResult = ReturnType<typeof useGetAllPlaylistQuery>;
export type GetAllPlaylistLazyQueryHookResult = ReturnType<typeof useGetAllPlaylistLazyQuery>;
export type GetAllPlaylistQueryResult = Apollo.QueryResult<Types.GetAllPlaylistQuery, Types.GetAllPlaylistQueryVariables>;
export function refetchGetAllPlaylistQuery(variables?: Types.GetAllPlaylistQueryVariables) {
      return { query: GetAllPlaylistDocument, variables: variables }
    }
export const GetPlaylistByClassIdDocument = gql`
    query GetPlaylistByClassId($classId: Int!, $search: [playlist_bool_exp!], $limit: Int, $offset: Int) {
  playlist(
    where: {class_playlists: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
    offset: $offset
  ) {
    playlist_id
    image_path
    name
    lockable_content_id
    description
    active
    lockable_content {
      lockable_content_id
      visibility
    }
    tip_playlist_aggregate {
      aggregate {
        count
      }
    }
  }
  playlist_aggregate(
    where: {class_playlists: {class_id: {_eq: $classId}}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPlaylistByClassIdQuery__
 *
 * To run a query within a React component, call `useGetPlaylistByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPlaylistByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPlaylistByClassIdQuery, Types.GetPlaylistByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPlaylistByClassIdQuery, Types.GetPlaylistByClassIdQueryVariables>(GetPlaylistByClassIdDocument, options);
      }
export function useGetPlaylistByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlaylistByClassIdQuery, Types.GetPlaylistByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPlaylistByClassIdQuery, Types.GetPlaylistByClassIdQueryVariables>(GetPlaylistByClassIdDocument, options);
        }
export type GetPlaylistByClassIdQueryHookResult = ReturnType<typeof useGetPlaylistByClassIdQuery>;
export type GetPlaylistByClassIdLazyQueryHookResult = ReturnType<typeof useGetPlaylistByClassIdLazyQuery>;
export type GetPlaylistByClassIdQueryResult = Apollo.QueryResult<Types.GetPlaylistByClassIdQuery, Types.GetPlaylistByClassIdQueryVariables>;
export function refetchGetPlaylistByClassIdQuery(variables?: Types.GetPlaylistByClassIdQueryVariables) {
      return { query: GetPlaylistByClassIdDocument, variables: variables }
    }
export const GetSearchPlaylistByClassIdDocument = gql`
    query GetSearchPlaylistByClassId($classId: Int!, $search: [playlist_bool_exp!], $limit: Int) {
  playlist(
    where: {class_playlists: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
  ) {
    playlist_id
    image_path
    name
    lockable_content_id
    description
    active
    lockable_content {
      lockable_content_id
      visibility
    }
    tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
      aggregate {
        count
      }
    }
  }
  playlist_aggregate(
    where: {class_playlists: {class_id: {_eq: $classId}}, _or: $search}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSearchPlaylistByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSearchPlaylistByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchPlaylistByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchPlaylistByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSearchPlaylistByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSearchPlaylistByClassIdQuery, Types.GetSearchPlaylistByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchPlaylistByClassIdQuery, Types.GetSearchPlaylistByClassIdQueryVariables>(GetSearchPlaylistByClassIdDocument, options);
      }
export function useGetSearchPlaylistByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchPlaylistByClassIdQuery, Types.GetSearchPlaylistByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchPlaylistByClassIdQuery, Types.GetSearchPlaylistByClassIdQueryVariables>(GetSearchPlaylistByClassIdDocument, options);
        }
export type GetSearchPlaylistByClassIdQueryHookResult = ReturnType<typeof useGetSearchPlaylistByClassIdQuery>;
export type GetSearchPlaylistByClassIdLazyQueryHookResult = ReturnType<typeof useGetSearchPlaylistByClassIdLazyQuery>;
export type GetSearchPlaylistByClassIdQueryResult = Apollo.QueryResult<Types.GetSearchPlaylistByClassIdQuery, Types.GetSearchPlaylistByClassIdQueryVariables>;
export function refetchGetSearchPlaylistByClassIdQuery(variables?: Types.GetSearchPlaylistByClassIdQueryVariables) {
      return { query: GetSearchPlaylistByClassIdDocument, variables: variables }
    }
export const GetPlaylistDataByClassIdDocument = gql`
    query GetPlaylistDataByClassId($classId: Int!, $limit: Int!) {
  playlist(where: {class_playlists: {class_id: {_eq: $classId}}}, limit: $limit) {
    playlist_id
    image_path
    name
    lockable_content_id
    description
    active
    lockable_content {
      lockable_content_id
      visibility
    }
    tip_playlist_aggregate(where: {tip: {tip_id: {_is_null: false}}}) {
      aggregate {
        count
      }
    }
  }
  playlist_aggregate(where: {class_playlists: {class_id: {_eq: $classId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPlaylistDataByClassIdQuery__
 *
 * To run a query within a React component, call `useGetPlaylistDataByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistDataByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistDataByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPlaylistDataByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPlaylistDataByClassIdQuery, Types.GetPlaylistDataByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPlaylistDataByClassIdQuery, Types.GetPlaylistDataByClassIdQueryVariables>(GetPlaylistDataByClassIdDocument, options);
      }
export function useGetPlaylistDataByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPlaylistDataByClassIdQuery, Types.GetPlaylistDataByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPlaylistDataByClassIdQuery, Types.GetPlaylistDataByClassIdQueryVariables>(GetPlaylistDataByClassIdDocument, options);
        }
export type GetPlaylistDataByClassIdQueryHookResult = ReturnType<typeof useGetPlaylistDataByClassIdQuery>;
export type GetPlaylistDataByClassIdLazyQueryHookResult = ReturnType<typeof useGetPlaylistDataByClassIdLazyQuery>;
export type GetPlaylistDataByClassIdQueryResult = Apollo.QueryResult<Types.GetPlaylistDataByClassIdQuery, Types.GetPlaylistDataByClassIdQueryVariables>;
export function refetchGetPlaylistDataByClassIdQuery(variables?: Types.GetPlaylistDataByClassIdQueryVariables) {
      return { query: GetPlaylistDataByClassIdDocument, variables: variables }
    }
export const SendMailDocument = gql`
    mutation SendMail($filter: SendMailFilter) {
  sendMail(filter: $filter) {
    result
  }
}
    `;
export type SendMailMutationFn = Apollo.MutationFunction<Types.SendMailMutation, Types.SendMailMutationVariables>;

/**
 * __useSendMailMutation__
 *
 * To run a mutation, you first call `useSendMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMailMutation, { data, loading, error }] = useSendMailMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSendMailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendMailMutation, Types.SendMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendMailMutation, Types.SendMailMutationVariables>(SendMailDocument, options);
      }
export type SendMailMutationHookResult = ReturnType<typeof useSendMailMutation>;
export type SendMailMutationResult = Apollo.MutationResult<Types.SendMailMutation>;
export type SendMailMutationOptions = Apollo.BaseMutationOptions<Types.SendMailMutation, Types.SendMailMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($membershipId: Int!, $teacherId: Int!, $promotionCode: String) {
  createSubscription(
    filter: {membershipId: $membershipId, teacherId: $teacherId, promotionCode: $promotionCode}
  ) {
    clientSecret
    subscriptionId
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<Types.CreateSubscriptionMutation, Types.CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      membershipId: // value for 'membershipId'
 *      teacherId: // value for 'teacherId'
 *      promotionCode: // value for 'promotionCode'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSubscriptionMutation, Types.CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSubscriptionMutation, Types.CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<Types.CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<Types.CreateSubscriptionMutation, Types.CreateSubscriptionMutationVariables>;
export const SignupTeacherDocument = gql`
    mutation SignupTeacher($email: String!, $password: String!, $role: String!, $address: String, $organizationName: String, $schoolId: Int, $nameFirst: String!, $nameLast: String!) {
  teacherSignup(
    filter: {address: $address, email: $email, organizationName: $organizationName, password: $password, role: $role, schoolId: $schoolId, nameFirst: $nameFirst, nameLast: $nameLast}
  ) {
    token
  }
}
    `;
export type SignupTeacherMutationFn = Apollo.MutationFunction<Types.SignupTeacherMutation, Types.SignupTeacherMutationVariables>;

/**
 * __useSignupTeacherMutation__
 *
 * To run a mutation, you first call `useSignupTeacherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupTeacherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupTeacherMutation, { data, loading, error }] = useSignupTeacherMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      address: // value for 'address'
 *      organizationName: // value for 'organizationName'
 *      schoolId: // value for 'schoolId'
 *      nameFirst: // value for 'nameFirst'
 *      nameLast: // value for 'nameLast'
 *   },
 * });
 */
export function useSignupTeacherMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignupTeacherMutation, Types.SignupTeacherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignupTeacherMutation, Types.SignupTeacherMutationVariables>(SignupTeacherDocument, options);
      }
export type SignupTeacherMutationHookResult = ReturnType<typeof useSignupTeacherMutation>;
export type SignupTeacherMutationResult = Apollo.MutationResult<Types.SignupTeacherMutation>;
export type SignupTeacherMutationOptions = Apollo.BaseMutationOptions<Types.SignupTeacherMutation, Types.SignupTeacherMutationVariables>;
export const AddStudentsSendEmailsDocument = gql`
    mutation AddStudentsSendEmails($filter: StudentFromCSVFilter!) {
  addStudentsOver13(filter: $filter) {
    msg
  }
}
    `;
export type AddStudentsSendEmailsMutationFn = Apollo.MutationFunction<Types.AddStudentsSendEmailsMutation, Types.AddStudentsSendEmailsMutationVariables>;

/**
 * __useAddStudentsSendEmailsMutation__
 *
 * To run a mutation, you first call `useAddStudentsSendEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentsSendEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentsSendEmailsMutation, { data, loading, error }] = useAddStudentsSendEmailsMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAddStudentsSendEmailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddStudentsSendEmailsMutation, Types.AddStudentsSendEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddStudentsSendEmailsMutation, Types.AddStudentsSendEmailsMutationVariables>(AddStudentsSendEmailsDocument, options);
      }
export type AddStudentsSendEmailsMutationHookResult = ReturnType<typeof useAddStudentsSendEmailsMutation>;
export type AddStudentsSendEmailsMutationResult = Apollo.MutationResult<Types.AddStudentsSendEmailsMutation>;
export type AddStudentsSendEmailsMutationOptions = Apollo.BaseMutationOptions<Types.AddStudentsSendEmailsMutation, Types.AddStudentsSendEmailsMutationVariables>;
export const AddStudentsSendParentConsentEmailsDocument = gql`
    mutation AddStudentsSendParentConsentEmails($set: AddStudentsUnder12Set!) {
  addStudentsUnder12(set: $set) {
    code
    parentEmail
  }
}
    `;
export type AddStudentsSendParentConsentEmailsMutationFn = Apollo.MutationFunction<Types.AddStudentsSendParentConsentEmailsMutation, Types.AddStudentsSendParentConsentEmailsMutationVariables>;

/**
 * __useAddStudentsSendParentConsentEmailsMutation__
 *
 * To run a mutation, you first call `useAddStudentsSendParentConsentEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentsSendParentConsentEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentsSendParentConsentEmailsMutation, { data, loading, error }] = useAddStudentsSendParentConsentEmailsMutation({
 *   variables: {
 *      set: // value for 'set'
 *   },
 * });
 */
export function useAddStudentsSendParentConsentEmailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddStudentsSendParentConsentEmailsMutation, Types.AddStudentsSendParentConsentEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddStudentsSendParentConsentEmailsMutation, Types.AddStudentsSendParentConsentEmailsMutationVariables>(AddStudentsSendParentConsentEmailsDocument, options);
      }
export type AddStudentsSendParentConsentEmailsMutationHookResult = ReturnType<typeof useAddStudentsSendParentConsentEmailsMutation>;
export type AddStudentsSendParentConsentEmailsMutationResult = Apollo.MutationResult<Types.AddStudentsSendParentConsentEmailsMutation>;
export type AddStudentsSendParentConsentEmailsMutationOptions = Apollo.BaseMutationOptions<Types.AddStudentsSendParentConsentEmailsMutation, Types.AddStudentsSendParentConsentEmailsMutationVariables>;
export const GetSendStudentRemainderEmailDocument = gql`
    query GetSendStudentRemainderEmail($filter: SendReinviteStudentsEmailFilter!) {
  sendReinviteStudentsEmail(filter: $filter)
}
    `;

/**
 * __useGetSendStudentRemainderEmailQuery__
 *
 * To run a query within a React component, call `useGetSendStudentRemainderEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSendStudentRemainderEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSendStudentRemainderEmailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSendStudentRemainderEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSendStudentRemainderEmailQuery, Types.GetSendStudentRemainderEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSendStudentRemainderEmailQuery, Types.GetSendStudentRemainderEmailQueryVariables>(GetSendStudentRemainderEmailDocument, options);
      }
export function useGetSendStudentRemainderEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSendStudentRemainderEmailQuery, Types.GetSendStudentRemainderEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSendStudentRemainderEmailQuery, Types.GetSendStudentRemainderEmailQueryVariables>(GetSendStudentRemainderEmailDocument, options);
        }
export type GetSendStudentRemainderEmailQueryHookResult = ReturnType<typeof useGetSendStudentRemainderEmailQuery>;
export type GetSendStudentRemainderEmailLazyQueryHookResult = ReturnType<typeof useGetSendStudentRemainderEmailLazyQuery>;
export type GetSendStudentRemainderEmailQueryResult = Apollo.QueryResult<Types.GetSendStudentRemainderEmailQuery, Types.GetSendStudentRemainderEmailQueryVariables>;
export function refetchGetSendStudentRemainderEmailQuery(variables?: Types.GetSendStudentRemainderEmailQueryVariables) {
      return { query: GetSendStudentRemainderEmailDocument, variables: variables }
    }
export const UpdateTeacherInformationDocument = gql`
    mutation UpdateTeacherInformation($teacherId: Int!, $firstName: String!, $lastName: String!, $currentPassword: String!, $newPassword: String) {
  editTeacherInformation(
    filter: {teacherId: $teacherId, firstName: $firstName, lastName: $lastName, currentPassword: $currentPassword, newPassword: $newPassword}
  )
}
    `;
export type UpdateTeacherInformationMutationFn = Apollo.MutationFunction<Types.UpdateTeacherInformationMutation, Types.UpdateTeacherInformationMutationVariables>;

/**
 * __useUpdateTeacherInformationMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherInformationMutation, { data, loading, error }] = useUpdateTeacherInformationMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdateTeacherInformationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeacherInformationMutation, Types.UpdateTeacherInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeacherInformationMutation, Types.UpdateTeacherInformationMutationVariables>(UpdateTeacherInformationDocument, options);
      }
export type UpdateTeacherInformationMutationHookResult = ReturnType<typeof useUpdateTeacherInformationMutation>;
export type UpdateTeacherInformationMutationResult = Apollo.MutationResult<Types.UpdateTeacherInformationMutation>;
export type UpdateTeacherInformationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeacherInformationMutation, Types.UpdateTeacherInformationMutationVariables>;
export const SignUpTeacherIntegrationDocument = gql`
    mutation SignUpTeacherIntegration($token: String!, $role: String!, $schoolId: Int, $organizationName: String, $address: String) {
  teacherSignupIntegration(
    filter: {token: $token, role: $role, address: $address, organizationName: $organizationName, schoolId: $schoolId}
  ) {
    token
  }
}
    `;
export type SignUpTeacherIntegrationMutationFn = Apollo.MutationFunction<Types.SignUpTeacherIntegrationMutation, Types.SignUpTeacherIntegrationMutationVariables>;

/**
 * __useSignUpTeacherIntegrationMutation__
 *
 * To run a mutation, you first call `useSignUpTeacherIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpTeacherIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpTeacherIntegrationMutation, { data, loading, error }] = useSignUpTeacherIntegrationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      role: // value for 'role'
 *      schoolId: // value for 'schoolId'
 *      organizationName: // value for 'organizationName'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSignUpTeacherIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpTeacherIntegrationMutation, Types.SignUpTeacherIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpTeacherIntegrationMutation, Types.SignUpTeacherIntegrationMutationVariables>(SignUpTeacherIntegrationDocument, options);
      }
export type SignUpTeacherIntegrationMutationHookResult = ReturnType<typeof useSignUpTeacherIntegrationMutation>;
export type SignUpTeacherIntegrationMutationResult = Apollo.MutationResult<Types.SignUpTeacherIntegrationMutation>;
export type SignUpTeacherIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.SignUpTeacherIntegrationMutation, Types.SignUpTeacherIntegrationMutationVariables>;
export const CompleteTeacherIntegrationDocument = gql`
    mutation CompleteTeacherIntegration($data: CompleteTeacherIntegrationSet!) {
  completeTeacherIntegration(set: $data) {
    token
  }
}
    `;
export type CompleteTeacherIntegrationMutationFn = Apollo.MutationFunction<Types.CompleteTeacherIntegrationMutation, Types.CompleteTeacherIntegrationMutationVariables>;

/**
 * __useCompleteTeacherIntegrationMutation__
 *
 * To run a mutation, you first call `useCompleteTeacherIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTeacherIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTeacherIntegrationMutation, { data, loading, error }] = useCompleteTeacherIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompleteTeacherIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteTeacherIntegrationMutation, Types.CompleteTeacherIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteTeacherIntegrationMutation, Types.CompleteTeacherIntegrationMutationVariables>(CompleteTeacherIntegrationDocument, options);
      }
export type CompleteTeacherIntegrationMutationHookResult = ReturnType<typeof useCompleteTeacherIntegrationMutation>;
export type CompleteTeacherIntegrationMutationResult = Apollo.MutationResult<Types.CompleteTeacherIntegrationMutation>;
export type CompleteTeacherIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.CompleteTeacherIntegrationMutation, Types.CompleteTeacherIntegrationMutationVariables>;
export const GetCleverDistrictsDocument = gql`
    query GetCleverDistricts {
  getCleverDistricts {
    id
    name
  }
}
    `;

/**
 * __useGetCleverDistrictsQuery__
 *
 * To run a query within a React component, call `useGetCleverDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverDistrictsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCleverDistrictsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCleverDistrictsQuery, Types.GetCleverDistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCleverDistrictsQuery, Types.GetCleverDistrictsQueryVariables>(GetCleverDistrictsDocument, options);
      }
export function useGetCleverDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCleverDistrictsQuery, Types.GetCleverDistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCleverDistrictsQuery, Types.GetCleverDistrictsQueryVariables>(GetCleverDistrictsDocument, options);
        }
export type GetCleverDistrictsQueryHookResult = ReturnType<typeof useGetCleverDistrictsQuery>;
export type GetCleverDistrictsLazyQueryHookResult = ReturnType<typeof useGetCleverDistrictsLazyQuery>;
export type GetCleverDistrictsQueryResult = Apollo.QueryResult<Types.GetCleverDistrictsQuery, Types.GetCleverDistrictsQueryVariables>;
export function refetchGetCleverDistrictsQuery(variables?: Types.GetCleverDistrictsQueryVariables) {
      return { query: GetCleverDistrictsDocument, variables: variables }
    }
export const TeacherEmailExistsDocument = gql`
    query TeacherEmailExists($email: String!) {
  teacherEmailExists(filter: {email: $email})
}
    `;

/**
 * __useTeacherEmailExistsQuery__
 *
 * To run a query within a React component, call `useTeacherEmailExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherEmailExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherEmailExistsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useTeacherEmailExistsQuery(baseOptions: Apollo.QueryHookOptions<Types.TeacherEmailExistsQuery, Types.TeacherEmailExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TeacherEmailExistsQuery, Types.TeacherEmailExistsQueryVariables>(TeacherEmailExistsDocument, options);
      }
export function useTeacherEmailExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TeacherEmailExistsQuery, Types.TeacherEmailExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TeacherEmailExistsQuery, Types.TeacherEmailExistsQueryVariables>(TeacherEmailExistsDocument, options);
        }
export type TeacherEmailExistsQueryHookResult = ReturnType<typeof useTeacherEmailExistsQuery>;
export type TeacherEmailExistsLazyQueryHookResult = ReturnType<typeof useTeacherEmailExistsLazyQuery>;
export type TeacherEmailExistsQueryResult = Apollo.QueryResult<Types.TeacherEmailExistsQuery, Types.TeacherEmailExistsQueryVariables>;
export function refetchTeacherEmailExistsQuery(variables?: Types.TeacherEmailExistsQueryVariables) {
      return { query: TeacherEmailExistsDocument, variables: variables }
    }
export const LoginIntegrationDocument = gql`
    query LoginIntegration($email: String!, $integrationId: String!, $isGoogle: Boolean, $isClever: Boolean, $isMicrosoft: Boolean, $accessToken: String!) {
  loginIntegration(
    filter: {email: $email, integrationId: $integrationId, accessToken: $accessToken, isGoogle: $isGoogle, isClever: $isClever, isMicrosoft: $isMicrosoft}
  ) {
    token
    cleverSignup
  }
}
    `;

/**
 * __useLoginIntegrationQuery__
 *
 * To run a query within a React component, call `useLoginIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginIntegrationQuery({
 *   variables: {
 *      email: // value for 'email'
 *      integrationId: // value for 'integrationId'
 *      isGoogle: // value for 'isGoogle'
 *      isClever: // value for 'isClever'
 *      isMicrosoft: // value for 'isMicrosoft'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useLoginIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.LoginIntegrationQuery, Types.LoginIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoginIntegrationQuery, Types.LoginIntegrationQueryVariables>(LoginIntegrationDocument, options);
      }
export function useLoginIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoginIntegrationQuery, Types.LoginIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoginIntegrationQuery, Types.LoginIntegrationQueryVariables>(LoginIntegrationDocument, options);
        }
export type LoginIntegrationQueryHookResult = ReturnType<typeof useLoginIntegrationQuery>;
export type LoginIntegrationLazyQueryHookResult = ReturnType<typeof useLoginIntegrationLazyQuery>;
export type LoginIntegrationQueryResult = Apollo.QueryResult<Types.LoginIntegrationQuery, Types.LoginIntegrationQueryVariables>;
export function refetchLoginIntegrationQuery(variables?: Types.LoginIntegrationQueryVariables) {
      return { query: LoginIntegrationDocument, variables: variables }
    }
export const GetStudentQRsPdfDocument = gql`
    query getStudentQRsPDF($filter: AddStudentsByBulkQRsFilter!) {
  addStudentsByBulkQRs(filter: $filter) {
    path
    html
  }
}
    `;

/**
 * __useGetStudentQRsPdfQuery__
 *
 * To run a query within a React component, call `useGetStudentQRsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQRsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQRsPdfQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStudentQRsPdfQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentQRsPdfQuery, Types.GetStudentQRsPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentQRsPdfQuery, Types.GetStudentQRsPdfQueryVariables>(GetStudentQRsPdfDocument, options);
      }
export function useGetStudentQRsPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentQRsPdfQuery, Types.GetStudentQRsPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentQRsPdfQuery, Types.GetStudentQRsPdfQueryVariables>(GetStudentQRsPdfDocument, options);
        }
export type GetStudentQRsPdfQueryHookResult = ReturnType<typeof useGetStudentQRsPdfQuery>;
export type GetStudentQRsPdfLazyQueryHookResult = ReturnType<typeof useGetStudentQRsPdfLazyQuery>;
export type GetStudentQRsPdfQueryResult = Apollo.QueryResult<Types.GetStudentQRsPdfQuery, Types.GetStudentQRsPdfQueryVariables>;
export function refetchGetStudentQRsPdfQuery(variables?: Types.GetStudentQRsPdfQueryVariables) {
      return { query: GetStudentQRsPdfDocument, variables: variables }
    }
export const GetSignupIntegrationTokenDocument = gql`
    query GetSignupIntegrationToken($email: String!, $integrationId: String!, $isGoogle: Boolean, $isClever: Boolean, $isMicrosoft: Boolean, $accessToken: String!) {
  getSignupIntegrationToken(
    filter: {email: $email, integrationId: $integrationId, accessToken: $accessToken, isGoogle: $isGoogle, isClever: $isClever, isMicrosoft: $isMicrosoft}
  ) {
    token
  }
}
    `;

/**
 * __useGetSignupIntegrationTokenQuery__
 *
 * To run a query within a React component, call `useGetSignupIntegrationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignupIntegrationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignupIntegrationTokenQuery({
 *   variables: {
 *      email: // value for 'email'
 *      integrationId: // value for 'integrationId'
 *      isGoogle: // value for 'isGoogle'
 *      isClever: // value for 'isClever'
 *      isMicrosoft: // value for 'isMicrosoft'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useGetSignupIntegrationTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSignupIntegrationTokenQuery, Types.GetSignupIntegrationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSignupIntegrationTokenQuery, Types.GetSignupIntegrationTokenQueryVariables>(GetSignupIntegrationTokenDocument, options);
      }
export function useGetSignupIntegrationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSignupIntegrationTokenQuery, Types.GetSignupIntegrationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSignupIntegrationTokenQuery, Types.GetSignupIntegrationTokenQueryVariables>(GetSignupIntegrationTokenDocument, options);
        }
export type GetSignupIntegrationTokenQueryHookResult = ReturnType<typeof useGetSignupIntegrationTokenQuery>;
export type GetSignupIntegrationTokenLazyQueryHookResult = ReturnType<typeof useGetSignupIntegrationTokenLazyQuery>;
export type GetSignupIntegrationTokenQueryResult = Apollo.QueryResult<Types.GetSignupIntegrationTokenQuery, Types.GetSignupIntegrationTokenQueryVariables>;
export function refetchGetSignupIntegrationTokenQuery(variables?: Types.GetSignupIntegrationTokenQueryVariables) {
      return { query: GetSignupIntegrationTokenDocument, variables: variables }
    }
export const GetLinkMetaDataDocument = gql`
    query GetLinkMetaData($filter: LinkMetadataFilter!) {
  linkMetaData(filter: $filter) {
    url
    title
    description
    images
  }
}
    `;

/**
 * __useGetLinkMetaDataQuery__
 *
 * To run a query within a React component, call `useGetLinkMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkMetaDataQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLinkMetaDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLinkMetaDataQuery, Types.GetLinkMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLinkMetaDataQuery, Types.GetLinkMetaDataQueryVariables>(GetLinkMetaDataDocument, options);
      }
export function useGetLinkMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLinkMetaDataQuery, Types.GetLinkMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLinkMetaDataQuery, Types.GetLinkMetaDataQueryVariables>(GetLinkMetaDataDocument, options);
        }
export type GetLinkMetaDataQueryHookResult = ReturnType<typeof useGetLinkMetaDataQuery>;
export type GetLinkMetaDataLazyQueryHookResult = ReturnType<typeof useGetLinkMetaDataLazyQuery>;
export type GetLinkMetaDataQueryResult = Apollo.QueryResult<Types.GetLinkMetaDataQuery, Types.GetLinkMetaDataQueryVariables>;
export function refetchGetLinkMetaDataQuery(variables?: Types.GetLinkMetaDataQueryVariables) {
      return { query: GetLinkMetaDataDocument, variables: variables }
    }
export const LoginStudentDocument = gql`
    query LoginStudent($email: String!, $password: String!) {
  login(filter: {email: $email, password: $password, isStudent: true}) {
    token
    avatarSelected
  }
}
    `;

/**
 * __useLoginStudentQuery__
 *
 * To run a query within a React component, call `useLoginStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginStudentQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginStudentQuery(baseOptions: Apollo.QueryHookOptions<Types.LoginStudentQuery, Types.LoginStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoginStudentQuery, Types.LoginStudentQueryVariables>(LoginStudentDocument, options);
      }
export function useLoginStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoginStudentQuery, Types.LoginStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoginStudentQuery, Types.LoginStudentQueryVariables>(LoginStudentDocument, options);
        }
export type LoginStudentQueryHookResult = ReturnType<typeof useLoginStudentQuery>;
export type LoginStudentLazyQueryHookResult = ReturnType<typeof useLoginStudentLazyQuery>;
export type LoginStudentQueryResult = Apollo.QueryResult<Types.LoginStudentQuery, Types.LoginStudentQueryVariables>;
export function refetchLoginStudentQuery(variables?: Types.LoginStudentQueryVariables) {
      return { query: LoginStudentDocument, variables: variables }
    }
export const GetDownloadStudentQRsPdfDocument = gql`
    query GetDownloadStudentQRsPDF($filter: DownloadQRCodeFilter!) {
  downloadQRCode(filter: $filter) {
    path
    html
  }
}
    `;

/**
 * __useGetDownloadStudentQRsPdfQuery__
 *
 * To run a query within a React component, call `useGetDownloadStudentQRsPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadStudentQRsPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadStudentQRsPdfQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDownloadStudentQRsPdfQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDownloadStudentQRsPdfQuery, Types.GetDownloadStudentQRsPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDownloadStudentQRsPdfQuery, Types.GetDownloadStudentQRsPdfQueryVariables>(GetDownloadStudentQRsPdfDocument, options);
      }
export function useGetDownloadStudentQRsPdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDownloadStudentQRsPdfQuery, Types.GetDownloadStudentQRsPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDownloadStudentQRsPdfQuery, Types.GetDownloadStudentQRsPdfQueryVariables>(GetDownloadStudentQRsPdfDocument, options);
        }
export type GetDownloadStudentQRsPdfQueryHookResult = ReturnType<typeof useGetDownloadStudentQRsPdfQuery>;
export type GetDownloadStudentQRsPdfLazyQueryHookResult = ReturnType<typeof useGetDownloadStudentQRsPdfLazyQuery>;
export type GetDownloadStudentQRsPdfQueryResult = Apollo.QueryResult<Types.GetDownloadStudentQRsPdfQuery, Types.GetDownloadStudentQRsPdfQueryVariables>;
export function refetchGetDownloadStudentQRsPdfQuery(variables?: Types.GetDownloadStudentQRsPdfQueryVariables) {
      return { query: GetDownloadStudentQRsPdfDocument, variables: variables }
    }
export const LoginOrSignUpTeacherIntegrationDocument = gql`
    query LoginOrSignUpTeacherIntegration($accesToken: String!, $email: String!, $integrationId: String!, $integrationName: String!) {
  loginOrSignupTeacherIntegration(
    filter: {accessToken: $accesToken, email: $email, integrationId: $integrationId, integrationName: $integrationName}
  ) {
    isSignup
    teacherId
    schoolId
    token
  }
}
    `;

/**
 * __useLoginOrSignUpTeacherIntegrationQuery__
 *
 * To run a query within a React component, call `useLoginOrSignUpTeacherIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginOrSignUpTeacherIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginOrSignUpTeacherIntegrationQuery({
 *   variables: {
 *      accesToken: // value for 'accesToken'
 *      email: // value for 'email'
 *      integrationId: // value for 'integrationId'
 *      integrationName: // value for 'integrationName'
 *   },
 * });
 */
export function useLoginOrSignUpTeacherIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.LoginOrSignUpTeacherIntegrationQuery, Types.LoginOrSignUpTeacherIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LoginOrSignUpTeacherIntegrationQuery, Types.LoginOrSignUpTeacherIntegrationQueryVariables>(LoginOrSignUpTeacherIntegrationDocument, options);
      }
export function useLoginOrSignUpTeacherIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LoginOrSignUpTeacherIntegrationQuery, Types.LoginOrSignUpTeacherIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LoginOrSignUpTeacherIntegrationQuery, Types.LoginOrSignUpTeacherIntegrationQueryVariables>(LoginOrSignUpTeacherIntegrationDocument, options);
        }
export type LoginOrSignUpTeacherIntegrationQueryHookResult = ReturnType<typeof useLoginOrSignUpTeacherIntegrationQuery>;
export type LoginOrSignUpTeacherIntegrationLazyQueryHookResult = ReturnType<typeof useLoginOrSignUpTeacherIntegrationLazyQuery>;
export type LoginOrSignUpTeacherIntegrationQueryResult = Apollo.QueryResult<Types.LoginOrSignUpTeacherIntegrationQuery, Types.LoginOrSignUpTeacherIntegrationQueryVariables>;
export function refetchLoginOrSignUpTeacherIntegrationQuery(variables?: Types.LoginOrSignUpTeacherIntegrationQueryVariables) {
      return { query: LoginOrSignUpTeacherIntegrationDocument, variables: variables }
    }
export const RoastCanvasClassesDocument = gql`
    mutation RoastCanvasClasses($ids: [Int!]!, $idToken: String!) {
  rosterCanvasClasses(set: {classesIds: $ids, accessToken: $idToken}) {
    success
    message
  }
}
    `;
export type RoastCanvasClassesMutationFn = Apollo.MutationFunction<Types.RoastCanvasClassesMutation, Types.RoastCanvasClassesMutationVariables>;

/**
 * __useRoastCanvasClassesMutation__
 *
 * To run a mutation, you first call `useRoastCanvasClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoastCanvasClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roastCanvasClassesMutation, { data, loading, error }] = useRoastCanvasClassesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useRoastCanvasClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RoastCanvasClassesMutation, Types.RoastCanvasClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RoastCanvasClassesMutation, Types.RoastCanvasClassesMutationVariables>(RoastCanvasClassesDocument, options);
      }
export type RoastCanvasClassesMutationHookResult = ReturnType<typeof useRoastCanvasClassesMutation>;
export type RoastCanvasClassesMutationResult = Apollo.MutationResult<Types.RoastCanvasClassesMutation>;
export type RoastCanvasClassesMutationOptions = Apollo.BaseMutationOptions<Types.RoastCanvasClassesMutation, Types.RoastCanvasClassesMutationVariables>;
export const RoastClassLinkClassesDocument = gql`
    mutation RoastClassLinkClasses($classesIds: [String!]!) {
  rosterClassLinkClasses(set: {classesIds: $classesIds}) {
    success
    message
  }
}
    `;
export type RoastClassLinkClassesMutationFn = Apollo.MutationFunction<Types.RoastClassLinkClassesMutation, Types.RoastClassLinkClassesMutationVariables>;

/**
 * __useRoastClassLinkClassesMutation__
 *
 * To run a mutation, you first call `useRoastClassLinkClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoastClassLinkClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roastClassLinkClassesMutation, { data, loading, error }] = useRoastClassLinkClassesMutation({
 *   variables: {
 *      classesIds: // value for 'classesIds'
 *   },
 * });
 */
export function useRoastClassLinkClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RoastClassLinkClassesMutation, Types.RoastClassLinkClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RoastClassLinkClassesMutation, Types.RoastClassLinkClassesMutationVariables>(RoastClassLinkClassesDocument, options);
      }
export type RoastClassLinkClassesMutationHookResult = ReturnType<typeof useRoastClassLinkClassesMutation>;
export type RoastClassLinkClassesMutationResult = Apollo.MutationResult<Types.RoastClassLinkClassesMutation>;
export type RoastClassLinkClassesMutationOptions = Apollo.BaseMutationOptions<Types.RoastClassLinkClassesMutation, Types.RoastClassLinkClassesMutationVariables>;
export const GetClasslinkStudentsDocument = gql`
    query GetClasslinkStudents($filter: GetClassLinkStudentsFilter!) {
  getClassLinkStudents(filter: $filter) {
    sourcedId
    firstName
    lastName
  }
}
    `;

/**
 * __useGetClasslinkStudentsQuery__
 *
 * To run a query within a React component, call `useGetClasslinkStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClasslinkStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClasslinkStudentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetClasslinkStudentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClasslinkStudentsQuery, Types.GetClasslinkStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClasslinkStudentsQuery, Types.GetClasslinkStudentsQueryVariables>(GetClasslinkStudentsDocument, options);
      }
export function useGetClasslinkStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClasslinkStudentsQuery, Types.GetClasslinkStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClasslinkStudentsQuery, Types.GetClasslinkStudentsQueryVariables>(GetClasslinkStudentsDocument, options);
        }
export type GetClasslinkStudentsQueryHookResult = ReturnType<typeof useGetClasslinkStudentsQuery>;
export type GetClasslinkStudentsLazyQueryHookResult = ReturnType<typeof useGetClasslinkStudentsLazyQuery>;
export type GetClasslinkStudentsQueryResult = Apollo.QueryResult<Types.GetClasslinkStudentsQuery, Types.GetClasslinkStudentsQueryVariables>;
export function refetchGetClasslinkStudentsQuery(variables?: Types.GetClasslinkStudentsQueryVariables) {
      return { query: GetClasslinkStudentsDocument, variables: variables }
    }
export const RoastCleverClassesDocument = gql`
    mutation RoastCleverClasses($classesIds: [String!]!, $accessToken: String!, $teacherId: Int!) {
  roastCleverClasses(
    filter: {classesIds: $classesIds, accessToken: $accessToken, teacherId: $teacherId}
  ) {
    error
  }
}
    `;
export type RoastCleverClassesMutationFn = Apollo.MutationFunction<Types.RoastCleverClassesMutation, Types.RoastCleverClassesMutationVariables>;

/**
 * __useRoastCleverClassesMutation__
 *
 * To run a mutation, you first call `useRoastCleverClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoastCleverClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roastCleverClassesMutation, { data, loading, error }] = useRoastCleverClassesMutation({
 *   variables: {
 *      classesIds: // value for 'classesIds'
 *      accessToken: // value for 'accessToken'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useRoastCleverClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RoastCleverClassesMutation, Types.RoastCleverClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RoastCleverClassesMutation, Types.RoastCleverClassesMutationVariables>(RoastCleverClassesDocument, options);
      }
export type RoastCleverClassesMutationHookResult = ReturnType<typeof useRoastCleverClassesMutation>;
export type RoastCleverClassesMutationResult = Apollo.MutationResult<Types.RoastCleverClassesMutation>;
export type RoastCleverClassesMutationOptions = Apollo.BaseMutationOptions<Types.RoastCleverClassesMutation, Types.RoastCleverClassesMutationVariables>;
export const GetSchoolsByDistrictDocument = gql`
    query GetSchoolsByDistrict($districtId: Int!, $search: [school_bool_exp!], $limit: Int!) {
  school(
    where: {district_id: {_eq: $districtId}, _or: $search}
    limit: $limit
    order_by: {name: asc}
  ) {
    name
    district_id
    school_id
    address_line_1
  }
}
    `;

/**
 * __useGetSchoolsByDistrictQuery__
 *
 * To run a query within a React component, call `useGetSchoolsByDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsByDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsByDistrictQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSchoolsByDistrictQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSchoolsByDistrictQuery, Types.GetSchoolsByDistrictQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSchoolsByDistrictQuery, Types.GetSchoolsByDistrictQueryVariables>(GetSchoolsByDistrictDocument, options);
      }
export function useGetSchoolsByDistrictLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSchoolsByDistrictQuery, Types.GetSchoolsByDistrictQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSchoolsByDistrictQuery, Types.GetSchoolsByDistrictQueryVariables>(GetSchoolsByDistrictDocument, options);
        }
export type GetSchoolsByDistrictQueryHookResult = ReturnType<typeof useGetSchoolsByDistrictQuery>;
export type GetSchoolsByDistrictLazyQueryHookResult = ReturnType<typeof useGetSchoolsByDistrictLazyQuery>;
export type GetSchoolsByDistrictQueryResult = Apollo.QueryResult<Types.GetSchoolsByDistrictQuery, Types.GetSchoolsByDistrictQueryVariables>;
export function refetchGetSchoolsByDistrictQuery(variables?: Types.GetSchoolsByDistrictQueryVariables) {
      return { query: GetSchoolsByDistrictDocument, variables: variables }
    }
export const GetSchoolByCleverIdDocument = gql`
    query GetSchoolByCleverId($cleverId: String!) {
  school(where: {clever_id: {_eq: $cleverId}}) {
    name
    district_id
    school_id
  }
}
    `;

/**
 * __useGetSchoolByCleverIdQuery__
 *
 * To run a query within a React component, call `useGetSchoolByCleverIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolByCleverIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolByCleverIdQuery({
 *   variables: {
 *      cleverId: // value for 'cleverId'
 *   },
 * });
 */
export function useGetSchoolByCleverIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSchoolByCleverIdQuery, Types.GetSchoolByCleverIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSchoolByCleverIdQuery, Types.GetSchoolByCleverIdQueryVariables>(GetSchoolByCleverIdDocument, options);
      }
export function useGetSchoolByCleverIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSchoolByCleverIdQuery, Types.GetSchoolByCleverIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSchoolByCleverIdQuery, Types.GetSchoolByCleverIdQueryVariables>(GetSchoolByCleverIdDocument, options);
        }
export type GetSchoolByCleverIdQueryHookResult = ReturnType<typeof useGetSchoolByCleverIdQuery>;
export type GetSchoolByCleverIdLazyQueryHookResult = ReturnType<typeof useGetSchoolByCleverIdLazyQuery>;
export type GetSchoolByCleverIdQueryResult = Apollo.QueryResult<Types.GetSchoolByCleverIdQuery, Types.GetSchoolByCleverIdQueryVariables>;
export function refetchGetSchoolByCleverIdQuery(variables?: Types.GetSchoolByCleverIdQueryVariables) {
      return { query: GetSchoolByCleverIdDocument, variables: variables }
    }
export const UpdateClassSequencesDocument = gql`
    mutation updateClassSequences($classId: Int, $objects: [class_sequence_insert_input!]!) {
  delete_class_sequence(where: {class_id: {_eq: $classId}}) {
    affected_rows
  }
  insert_class_sequence(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateClassSequencesMutationFn = Apollo.MutationFunction<Types.UpdateClassSequencesMutation, Types.UpdateClassSequencesMutationVariables>;

/**
 * __useUpdateClassSequencesMutation__
 *
 * To run a mutation, you first call `useUpdateClassSequencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassSequencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassSequencesMutation, { data, loading, error }] = useUpdateClassSequencesMutation({
 *   variables: {
 *      classId: // value for 'classId'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateClassSequencesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClassSequencesMutation, Types.UpdateClassSequencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateClassSequencesMutation, Types.UpdateClassSequencesMutationVariables>(UpdateClassSequencesDocument, options);
      }
export type UpdateClassSequencesMutationHookResult = ReturnType<typeof useUpdateClassSequencesMutation>;
export type UpdateClassSequencesMutationResult = Apollo.MutationResult<Types.UpdateClassSequencesMutation>;
export type UpdateClassSequencesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClassSequencesMutation, Types.UpdateClassSequencesMutationVariables>;
export const GetSequenceItemByPkDocument = gql`
    query GetSequenceItemByPk($sequenceItemId: Int!, $teacherId: Int!, $classId: Int) {
  sequence_by_pk(sequence_id: $sequenceItemId) {
    ...SequenceFragment
    sequence_lesson_plans(where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}) {
      sequence_lesson_plan_id
      lesson_plan_id
      sequence_id
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        assignment_created(args: {classid: $classId})
        assignments(where: {class_assignments: {class_id: {_eq: $classId}}}) {
          class_assignments {
            class_assignment_id
          }
        }
        ...LessonPlanOnlyCatalogsFragment
        module_lesson_plans_aggregate {
          aggregate {
            count
          }
        }
        class_lesson_plans {
          class_lesson_plan_id
          class {
            class_id
            title
          }
        }
      }
    }
  }
}
    ${SequenceFragmentFragmentDoc}
${LessonPlanOnlyCatalogsFragmentFragmentDoc}`;

/**
 * __useGetSequenceItemByPkQuery__
 *
 * To run a query within a React component, call `useGetSequenceItemByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceItemByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceItemByPkQuery({
 *   variables: {
 *      sequenceItemId: // value for 'sequenceItemId'
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetSequenceItemByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSequenceItemByPkQuery, Types.GetSequenceItemByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSequenceItemByPkQuery, Types.GetSequenceItemByPkQueryVariables>(GetSequenceItemByPkDocument, options);
      }
export function useGetSequenceItemByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSequenceItemByPkQuery, Types.GetSequenceItemByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSequenceItemByPkQuery, Types.GetSequenceItemByPkQueryVariables>(GetSequenceItemByPkDocument, options);
        }
export type GetSequenceItemByPkQueryHookResult = ReturnType<typeof useGetSequenceItemByPkQuery>;
export type GetSequenceItemByPkLazyQueryHookResult = ReturnType<typeof useGetSequenceItemByPkLazyQuery>;
export type GetSequenceItemByPkQueryResult = Apollo.QueryResult<Types.GetSequenceItemByPkQuery, Types.GetSequenceItemByPkQueryVariables>;
export function refetchGetSequenceItemByPkQuery(variables?: Types.GetSequenceItemByPkQueryVariables) {
      return { query: GetSequenceItemByPkDocument, variables: variables }
    }
export const GetSequenceDocument = gql`
    query GetSequence($classId: Int, $conditions: [sequence_bool_exp!] = [{name: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc) {
  sequence(
    where: {_or: $conditions}
    offset: $offset
    limit: $limit
    order_by: {name: $order}
  ) {
    ...SequenceFragment
  }
  sequence_aggregate {
    aggregate {
      count
    }
  }
}
    ${SequenceFragmentFragmentDoc}`;

/**
 * __useGetSequenceQuery__
 *
 * To run a query within a React component, call `useGetSequenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSequenceQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSequenceQuery, Types.GetSequenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSequenceQuery, Types.GetSequenceQueryVariables>(GetSequenceDocument, options);
      }
export function useGetSequenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSequenceQuery, Types.GetSequenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSequenceQuery, Types.GetSequenceQueryVariables>(GetSequenceDocument, options);
        }
export type GetSequenceQueryHookResult = ReturnType<typeof useGetSequenceQuery>;
export type GetSequenceLazyQueryHookResult = ReturnType<typeof useGetSequenceLazyQuery>;
export type GetSequenceQueryResult = Apollo.QueryResult<Types.GetSequenceQuery, Types.GetSequenceQueryVariables>;
export function refetchGetSequenceQuery(variables?: Types.GetSequenceQueryVariables) {
      return { query: GetSequenceDocument, variables: variables }
    }
export const GetSequenceTopicByPkDocument = gql`
    query GetSequenceTopicByPk($sequenceTopicId: Int!) {
  sequence_topic_by_pk(sequence_topic_id: $sequenceTopicId) {
    sequence_topic_id
    title
    description
    image_path
    sequence_topic_sequences {
      sequence {
        sequence_id
        name
      }
    }
    sequence_topic_sequences_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetSequenceTopicByPkQuery__
 *
 * To run a query within a React component, call `useGetSequenceTopicByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceTopicByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceTopicByPkQuery({
 *   variables: {
 *      sequenceTopicId: // value for 'sequenceTopicId'
 *   },
 * });
 */
export function useGetSequenceTopicByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSequenceTopicByPkQuery, Types.GetSequenceTopicByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSequenceTopicByPkQuery, Types.GetSequenceTopicByPkQueryVariables>(GetSequenceTopicByPkDocument, options);
      }
export function useGetSequenceTopicByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSequenceTopicByPkQuery, Types.GetSequenceTopicByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSequenceTopicByPkQuery, Types.GetSequenceTopicByPkQueryVariables>(GetSequenceTopicByPkDocument, options);
        }
export type GetSequenceTopicByPkQueryHookResult = ReturnType<typeof useGetSequenceTopicByPkQuery>;
export type GetSequenceTopicByPkLazyQueryHookResult = ReturnType<typeof useGetSequenceTopicByPkLazyQuery>;
export type GetSequenceTopicByPkQueryResult = Apollo.QueryResult<Types.GetSequenceTopicByPkQuery, Types.GetSequenceTopicByPkQueryVariables>;
export function refetchGetSequenceTopicByPkQuery(variables?: Types.GetSequenceTopicByPkQueryVariables) {
      return { query: GetSequenceTopicByPkDocument, variables: variables }
    }
export const GetSequenceTopicsDocument = gql`
    query GetSequenceTopics($sequenceTopicId: Int!, $search: String = "%%", $offset: Int, $limit: Int, $order: order_by = asc) {
  sequence_topic_by_pk(sequence_topic_id: $sequenceTopicId) {
    sequence_topic_id
    image_path
    title
    description
    sequence_topic_sequences(
      where: {_and: [{_or: [{sequence: {name: {_ilike: $search}}}, {sequence: {description: {_ilike: $search}}}]}, {sequence: {sequence_lesson_plans: {}}}]}
      offset: $offset
      limit: $limit
      order_by: {sequence_topic_sequence_id: $order}
    ) {
      sequence {
        image_path
        name
        description
        sequence_id
        sequence_lesson_plans_aggregate(
          where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
        ) {
          aggregate {
            count
          }
        }
        class_sequences {
          class_sequence_id
          class {
            class_id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSequenceTopicsQuery__
 *
 * To run a query within a React component, call `useGetSequenceTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceTopicsQuery({
 *   variables: {
 *      sequenceTopicId: // value for 'sequenceTopicId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSequenceTopicsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSequenceTopicsQuery, Types.GetSequenceTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSequenceTopicsQuery, Types.GetSequenceTopicsQueryVariables>(GetSequenceTopicsDocument, options);
      }
export function useGetSequenceTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSequenceTopicsQuery, Types.GetSequenceTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSequenceTopicsQuery, Types.GetSequenceTopicsQueryVariables>(GetSequenceTopicsDocument, options);
        }
export type GetSequenceTopicsQueryHookResult = ReturnType<typeof useGetSequenceTopicsQuery>;
export type GetSequenceTopicsLazyQueryHookResult = ReturnType<typeof useGetSequenceTopicsLazyQuery>;
export type GetSequenceTopicsQueryResult = Apollo.QueryResult<Types.GetSequenceTopicsQuery, Types.GetSequenceTopicsQueryVariables>;
export function refetchGetSequenceTopicsQuery(variables?: Types.GetSequenceTopicsQueryVariables) {
      return { query: GetSequenceTopicsDocument, variables: variables }
    }
export const GetSequenceTopicsByCatalogItemDocument = gql`
    query GetSequenceTopicsByCatalogItem($catalogItemId: Int) {
  sequence_topic(
    where: {_and: [{sequence_topic_catalog_items: {catalog_item_id: {_eq: $catalogItemId}}}, {sequence_topic_sequences: {}}]}
  ) {
    sequence_topic_id
    image_path
    title
    description
    sequence_topic_sequences {
      sequence {
        name
        sequence_lesson_plans {
          lesson_plan {
            lesson_plan_id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSequenceTopicsByCatalogItemQuery__
 *
 * To run a query within a React component, call `useGetSequenceTopicsByCatalogItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceTopicsByCatalogItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceTopicsByCatalogItemQuery({
 *   variables: {
 *      catalogItemId: // value for 'catalogItemId'
 *   },
 * });
 */
export function useGetSequenceTopicsByCatalogItemQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSequenceTopicsByCatalogItemQuery, Types.GetSequenceTopicsByCatalogItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSequenceTopicsByCatalogItemQuery, Types.GetSequenceTopicsByCatalogItemQueryVariables>(GetSequenceTopicsByCatalogItemDocument, options);
      }
export function useGetSequenceTopicsByCatalogItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSequenceTopicsByCatalogItemQuery, Types.GetSequenceTopicsByCatalogItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSequenceTopicsByCatalogItemQuery, Types.GetSequenceTopicsByCatalogItemQueryVariables>(GetSequenceTopicsByCatalogItemDocument, options);
        }
export type GetSequenceTopicsByCatalogItemQueryHookResult = ReturnType<typeof useGetSequenceTopicsByCatalogItemQuery>;
export type GetSequenceTopicsByCatalogItemLazyQueryHookResult = ReturnType<typeof useGetSequenceTopicsByCatalogItemLazyQuery>;
export type GetSequenceTopicsByCatalogItemQueryResult = Apollo.QueryResult<Types.GetSequenceTopicsByCatalogItemQuery, Types.GetSequenceTopicsByCatalogItemQueryVariables>;
export function refetchGetSequenceTopicsByCatalogItemQuery(variables?: Types.GetSequenceTopicsByCatalogItemQueryVariables) {
      return { query: GetSequenceTopicsByCatalogItemDocument, variables: variables }
    }
export const GetSequenceTopicsListDocument = gql`
    query GetSequenceTopicsList($search: String = "%%", $offset: Int, $limit: Int, $order: order_by = asc) {
  sequence_topic(offset: $offset, limit: $limit) {
    sequence_topic_id
    image_path
    title
    description
    sequence_topic_sequences {
      sequence {
        image_path
        name
        description
        sequence_id
        sequence_lesson_plans_aggregate(
          where: {lesson_plan: {lesson_plan_id: {_is_null: false}}}
        ) {
          aggregate {
            count
          }
        }
        class_sequences {
          class_sequence_id
          class {
            class_id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSequenceTopicsListQuery__
 *
 * To run a query within a React component, call `useGetSequenceTopicsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSequenceTopicsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSequenceTopicsListQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSequenceTopicsListQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSequenceTopicsListQuery, Types.GetSequenceTopicsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSequenceTopicsListQuery, Types.GetSequenceTopicsListQueryVariables>(GetSequenceTopicsListDocument, options);
      }
export function useGetSequenceTopicsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSequenceTopicsListQuery, Types.GetSequenceTopicsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSequenceTopicsListQuery, Types.GetSequenceTopicsListQueryVariables>(GetSequenceTopicsListDocument, options);
        }
export type GetSequenceTopicsListQueryHookResult = ReturnType<typeof useGetSequenceTopicsListQuery>;
export type GetSequenceTopicsListLazyQueryHookResult = ReturnType<typeof useGetSequenceTopicsListLazyQuery>;
export type GetSequenceTopicsListQueryResult = Apollo.QueryResult<Types.GetSequenceTopicsListQuery, Types.GetSequenceTopicsListQueryVariables>;
export function refetchGetSequenceTopicsListQuery(variables?: Types.GetSequenceTopicsListQueryVariables) {
      return { query: GetSequenceTopicsListDocument, variables: variables }
    }
export const GetSkillLevelsDocument = gql`
    query GetSkillLevels {
  skill_level(order_by: {skill_level_id: asc}) {
    skill_level_id
    name
  }
}
    `;

/**
 * __useGetSkillLevelsQuery__
 *
 * To run a query within a React component, call `useGetSkillLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSkillLevelsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSkillLevelsQuery, Types.GetSkillLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSkillLevelsQuery, Types.GetSkillLevelsQueryVariables>(GetSkillLevelsDocument, options);
      }
export function useGetSkillLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSkillLevelsQuery, Types.GetSkillLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSkillLevelsQuery, Types.GetSkillLevelsQueryVariables>(GetSkillLevelsDocument, options);
        }
export type GetSkillLevelsQueryHookResult = ReturnType<typeof useGetSkillLevelsQuery>;
export type GetSkillLevelsLazyQueryHookResult = ReturnType<typeof useGetSkillLevelsLazyQuery>;
export type GetSkillLevelsQueryResult = Apollo.QueryResult<Types.GetSkillLevelsQuery, Types.GetSkillLevelsQueryVariables>;
export function refetchGetSkillLevelsQuery(variables?: Types.GetSkillLevelsQueryVariables) {
      return { query: GetSkillLevelsDocument, variables: variables }
    }
export const InsertSongDocument = gql`
    mutation InsertSong($title: String!, $img: String!, $key: String!, $featured: Boolean!, $active: Boolean!, $scale_id: Int!, $artist_id: Int!, $teacher_id: Int!, $levels: song_grade_arr_rel_insert_input = null, $songCatalogItems: song_catalog_item_arr_rel_insert_input = null) {
  insert_song_one(
    object: {title: $title, image_path: $img, key: $key, featured: $featured, active: $active, scale_id: $scale_id, artist_id: $artist_id, teacher_id: $teacher_id, lockable_content: {data: {visibility: private, table: "song"}}, song_grades: $levels, song_catalog_item: $songCatalogItems}
  ) {
    song_id
  }
}
    `;
export type InsertSongMutationFn = Apollo.MutationFunction<Types.InsertSongMutation, Types.InsertSongMutationVariables>;

/**
 * __useInsertSongMutation__
 *
 * To run a mutation, you first call `useInsertSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSongMutation, { data, loading, error }] = useInsertSongMutation({
 *   variables: {
 *      title: // value for 'title'
 *      img: // value for 'img'
 *      key: // value for 'key'
 *      featured: // value for 'featured'
 *      active: // value for 'active'
 *      scale_id: // value for 'scale_id'
 *      artist_id: // value for 'artist_id'
 *      teacher_id: // value for 'teacher_id'
 *      levels: // value for 'levels'
 *      songCatalogItems: // value for 'songCatalogItems'
 *   },
 * });
 */
export function useInsertSongMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertSongMutation, Types.InsertSongMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertSongMutation, Types.InsertSongMutationVariables>(InsertSongDocument, options);
      }
export type InsertSongMutationHookResult = ReturnType<typeof useInsertSongMutation>;
export type InsertSongMutationResult = Apollo.MutationResult<Types.InsertSongMutation>;
export type InsertSongMutationOptions = Apollo.BaseMutationOptions<Types.InsertSongMutation, Types.InsertSongMutationVariables>;
export const DeleteSongByPkDocument = gql`
    mutation DeleteSongByPk($songId: Int!) {
  delete_song_by_pk(song_id: $songId) {
    song_id
  }
}
    `;
export type DeleteSongByPkMutationFn = Apollo.MutationFunction<Types.DeleteSongByPkMutation, Types.DeleteSongByPkMutationVariables>;

/**
 * __useDeleteSongByPkMutation__
 *
 * To run a mutation, you first call `useDeleteSongByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSongByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSongByPkMutation, { data, loading, error }] = useDeleteSongByPkMutation({
 *   variables: {
 *      songId: // value for 'songId'
 *   },
 * });
 */
export function useDeleteSongByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSongByPkMutation, Types.DeleteSongByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSongByPkMutation, Types.DeleteSongByPkMutationVariables>(DeleteSongByPkDocument, options);
      }
export type DeleteSongByPkMutationHookResult = ReturnType<typeof useDeleteSongByPkMutation>;
export type DeleteSongByPkMutationResult = Apollo.MutationResult<Types.DeleteSongByPkMutation>;
export type DeleteSongByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSongByPkMutation, Types.DeleteSongByPkMutationVariables>;
export const UpdateSongByPkDocument = gql`
    mutation UpdateSongByPk($songId: Int!, $values: song_set_input, $song_catalog_item: [song_catalog_item_insert_input!]!, $levels: [song_grade_insert_input!]!) {
  update_song_by_pk(pk_columns: {song_id: $songId}, _set: $values) {
    song_id
  }
  delete_song_catalog_item(where: {song_id: {_eq: $songId}}) {
    affected_rows
  }
  delete_song_grade(where: {song_id: {_eq: $songId}}) {
    affected_rows
  }
  insert_song_catalog_item(objects: $song_catalog_item) {
    affected_rows
  }
  insert_song_grade(objects: $levels) {
    affected_rows
  }
}
    `;
export type UpdateSongByPkMutationFn = Apollo.MutationFunction<Types.UpdateSongByPkMutation, Types.UpdateSongByPkMutationVariables>;

/**
 * __useUpdateSongByPkMutation__
 *
 * To run a mutation, you first call `useUpdateSongByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSongByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSongByPkMutation, { data, loading, error }] = useUpdateSongByPkMutation({
 *   variables: {
 *      songId: // value for 'songId'
 *      values: // value for 'values'
 *      song_catalog_item: // value for 'song_catalog_item'
 *      levels: // value for 'levels'
 *   },
 * });
 */
export function useUpdateSongByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSongByPkMutation, Types.UpdateSongByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSongByPkMutation, Types.UpdateSongByPkMutationVariables>(UpdateSongByPkDocument, options);
      }
export type UpdateSongByPkMutationHookResult = ReturnType<typeof useUpdateSongByPkMutation>;
export type UpdateSongByPkMutationResult = Apollo.MutationResult<Types.UpdateSongByPkMutation>;
export type UpdateSongByPkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSongByPkMutation, Types.UpdateSongByPkMutationVariables>;
export const UpdateClassSongDocument = gql`
    mutation UpdateClassSong($class_id: Int!, $songIds: [class_song_insert_input!]!) {
  delete_class_song(where: {class_id: {_eq: $class_id}}) {
    affected_rows
  }
  insert_class_song(objects: $songIds) {
    affected_rows
  }
}
    `;
export type UpdateClassSongMutationFn = Apollo.MutationFunction<Types.UpdateClassSongMutation, Types.UpdateClassSongMutationVariables>;

/**
 * __useUpdateClassSongMutation__
 *
 * To run a mutation, you first call `useUpdateClassSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassSongMutation, { data, loading, error }] = useUpdateClassSongMutation({
 *   variables: {
 *      class_id: // value for 'class_id'
 *      songIds: // value for 'songIds'
 *   },
 * });
 */
export function useUpdateClassSongMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClassSongMutation, Types.UpdateClassSongMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateClassSongMutation, Types.UpdateClassSongMutationVariables>(UpdateClassSongDocument, options);
      }
export type UpdateClassSongMutationHookResult = ReturnType<typeof useUpdateClassSongMutation>;
export type UpdateClassSongMutationResult = Apollo.MutationResult<Types.UpdateClassSongMutation>;
export type UpdateClassSongMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClassSongMutation, Types.UpdateClassSongMutationVariables>;
export const GetSongByPkDocument = gql`
    query GetSongByPk($songId: Int!, $teacherId: Int) {
  song(where: {song_id: {_eq: $songId}}) {
    ...SongExtendedFragment
    is_favorite(args: {teacherid: $teacherId})
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetSongByPkQuery__
 *
 * To run a query within a React component, call `useGetSongByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongByPkQuery({
 *   variables: {
 *      songId: // value for 'songId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetSongByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongByPkQuery, Types.GetSongByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongByPkQuery, Types.GetSongByPkQueryVariables>(GetSongByPkDocument, options);
      }
export function useGetSongByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongByPkQuery, Types.GetSongByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongByPkQuery, Types.GetSongByPkQueryVariables>(GetSongByPkDocument, options);
        }
export type GetSongByPkQueryHookResult = ReturnType<typeof useGetSongByPkQuery>;
export type GetSongByPkLazyQueryHookResult = ReturnType<typeof useGetSongByPkLazyQuery>;
export type GetSongByPkQueryResult = Apollo.QueryResult<Types.GetSongByPkQuery, Types.GetSongByPkQueryVariables>;
export function refetchGetSongByPkQuery(variables?: Types.GetSongByPkQueryVariables) {
      return { query: GetSongByPkDocument, variables: variables }
    }
export const GetSongsByPkDocument = gql`
    query GetSongsByPk($songIds: [Int!]!) {
  song(where: {song_id: {_in: $songIds}}) {
    ...songReducedFragment
  }
}
    ${SongReducedFragmentFragmentDoc}`;

/**
 * __useGetSongsByPkQuery__
 *
 * To run a query within a React component, call `useGetSongsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongsByPkQuery({
 *   variables: {
 *      songIds: // value for 'songIds'
 *   },
 * });
 */
export function useGetSongsByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongsByPkQuery, Types.GetSongsByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongsByPkQuery, Types.GetSongsByPkQueryVariables>(GetSongsByPkDocument, options);
      }
export function useGetSongsByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongsByPkQuery, Types.GetSongsByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongsByPkQuery, Types.GetSongsByPkQueryVariables>(GetSongsByPkDocument, options);
        }
export type GetSongsByPkQueryHookResult = ReturnType<typeof useGetSongsByPkQuery>;
export type GetSongsByPkLazyQueryHookResult = ReturnType<typeof useGetSongsByPkLazyQuery>;
export type GetSongsByPkQueryResult = Apollo.QueryResult<Types.GetSongsByPkQuery, Types.GetSongsByPkQueryVariables>;
export function refetchGetSongsByPkQuery(variables?: Types.GetSongsByPkQueryVariables) {
      return { query: GetSongsByPkDocument, variables: variables }
    }
export const GetSongsDocument = gql`
    query GetSongs($teacherId: Int, $conditions: [song_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int = 5, $order: order_by = asc, $scaleId: Int, $featured: Boolean = null, $filters: [song_bool_exp!] = [], $chordFilters: [song_bool_exp!] = [], $vocalCondition: song_bool_exp = {}, $chordGuideConfidition: song_bool_exp = {}, $featuredCondition: song_bool_exp = {}, $notFeaturedCondition: song_bool_exp = {}) {
  song(
    where: {song_id: {_is_null: false}, featured: {_eq: $featured}, scale_id: {_eq: $scaleId}, _or: $conditions, _and: [{_and: $filters}, {_or: $chordFilters}, $vocalCondition, $chordGuideConfidition, $featuredCondition, $notFeaturedCondition, {active: {_eq: true}}]}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    featured
    is_favorite(args: {teacherid: $teacherId})
    ...SongExtendedFragment
  }
  song_aggregate(
    where: {song_id: {_is_null: false}, featured: {_eq: $featured}, scale_id: {_eq: $scaleId}, _or: $conditions, _and: [{_and: $filters}, {_or: $chordFilters}, $vocalCondition, $chordGuideConfidition, $featuredCondition, $notFeaturedCondition, {active: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetSongsQuery__
 *
 * To run a query within a React component, call `useGetSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      scaleId: // value for 'scaleId'
 *      featured: // value for 'featured'
 *      filters: // value for 'filters'
 *      chordFilters: // value for 'chordFilters'
 *      vocalCondition: // value for 'vocalCondition'
 *      chordGuideConfidition: // value for 'chordGuideConfidition'
 *      featuredCondition: // value for 'featuredCondition'
 *      notFeaturedCondition: // value for 'notFeaturedCondition'
 *   },
 * });
 */
export function useGetSongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSongsQuery, Types.GetSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongsQuery, Types.GetSongsQueryVariables>(GetSongsDocument, options);
      }
export function useGetSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongsQuery, Types.GetSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongsQuery, Types.GetSongsQueryVariables>(GetSongsDocument, options);
        }
export type GetSongsQueryHookResult = ReturnType<typeof useGetSongsQuery>;
export type GetSongsLazyQueryHookResult = ReturnType<typeof useGetSongsLazyQuery>;
export type GetSongsQueryResult = Apollo.QueryResult<Types.GetSongsQuery, Types.GetSongsQueryVariables>;
export function refetchGetSongsQuery(variables?: Types.GetSongsQueryVariables) {
      return { query: GetSongsDocument, variables: variables }
    }
export const GetRecommendedSongsDocument = gql`
    query GetRecommendedSongs($teacherId: Int, $offset: Int, $limit: Int, $order: order_by = asc, $orderDate: order_by) {
  song(
    where: {recommended: {_eq: true}, song_id: {_is_null: false}, teacher_id: {_eq: $teacherId}}
    offset: $offset
    limit: $limit
    order_by: {title: $order, created_at: $orderDate}
  ) {
    recommended
    is_favorite(args: {teacherid: $teacherId})
    ...songReducedFragment
  }
  song_aggregate(where: {recommended: {_eq: true}, song_id: {_is_null: false}}) {
    aggregate {
      count
    }
  }
}
    ${SongReducedFragmentFragmentDoc}`;

/**
 * __useGetRecommendedSongsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedSongsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetRecommendedSongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetRecommendedSongsQuery, Types.GetRecommendedSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecommendedSongsQuery, Types.GetRecommendedSongsQueryVariables>(GetRecommendedSongsDocument, options);
      }
export function useGetRecommendedSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecommendedSongsQuery, Types.GetRecommendedSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecommendedSongsQuery, Types.GetRecommendedSongsQueryVariables>(GetRecommendedSongsDocument, options);
        }
export type GetRecommendedSongsQueryHookResult = ReturnType<typeof useGetRecommendedSongsQuery>;
export type GetRecommendedSongsLazyQueryHookResult = ReturnType<typeof useGetRecommendedSongsLazyQuery>;
export type GetRecommendedSongsQueryResult = Apollo.QueryResult<Types.GetRecommendedSongsQuery, Types.GetRecommendedSongsQueryVariables>;
export function refetchGetRecommendedSongsQuery(variables?: Types.GetRecommendedSongsQueryVariables) {
      return { query: GetRecommendedSongsDocument, variables: variables }
    }
export const GetUploadedSongsDocument = gql`
    query GetUploadedSongs($teacherId: Int, $offset: Int, $limit: Int, $order_by: [song_order_by!]) {
  song(
    where: {song_id: {_is_null: false}, teacher_id: {_eq: $teacherId}}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    recommended
    is_favorite(args: {teacherid: $teacherId})
    ...songReducedFragment
  }
  song_aggregate(
    where: {song_id: {_is_null: false}, teacher_id: {_eq: $teacherId}}
  ) {
    aggregate {
      count
    }
  }
}
    ${SongReducedFragmentFragmentDoc}`;

/**
 * __useGetUploadedSongsQuery__
 *
 * To run a query within a React component, call `useGetUploadedSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadedSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadedSongsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetUploadedSongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUploadedSongsQuery, Types.GetUploadedSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUploadedSongsQuery, Types.GetUploadedSongsQueryVariables>(GetUploadedSongsDocument, options);
      }
export function useGetUploadedSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUploadedSongsQuery, Types.GetUploadedSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUploadedSongsQuery, Types.GetUploadedSongsQueryVariables>(GetUploadedSongsDocument, options);
        }
export type GetUploadedSongsQueryHookResult = ReturnType<typeof useGetUploadedSongsQuery>;
export type GetUploadedSongsLazyQueryHookResult = ReturnType<typeof useGetUploadedSongsLazyQuery>;
export type GetUploadedSongsQueryResult = Apollo.QueryResult<Types.GetUploadedSongsQuery, Types.GetUploadedSongsQueryVariables>;
export function refetchGetUploadedSongsQuery(variables?: Types.GetUploadedSongsQueryVariables) {
      return { query: GetUploadedSongsDocument, variables: variables }
    }
export const SearchMySongsDocument = gql`
    query SearchMySongs($teacherId: Int, $conditions: [song_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc) {
  song(
    where: {_or: $conditions, teacher_id: {_eq: $teacherId}, song_id: {_is_null: false}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...SongExtendedFragment
  }
  song_aggregate(where: {song_id: {_is_null: false}}) {
    aggregate {
      count
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useSearchMySongsQuery__
 *
 * To run a query within a React component, call `useSearchMySongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMySongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMySongsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchMySongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchMySongsQuery, Types.SearchMySongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchMySongsQuery, Types.SearchMySongsQueryVariables>(SearchMySongsDocument, options);
      }
export function useSearchMySongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchMySongsQuery, Types.SearchMySongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchMySongsQuery, Types.SearchMySongsQueryVariables>(SearchMySongsDocument, options);
        }
export type SearchMySongsQueryHookResult = ReturnType<typeof useSearchMySongsQuery>;
export type SearchMySongsLazyQueryHookResult = ReturnType<typeof useSearchMySongsLazyQuery>;
export type SearchMySongsQueryResult = Apollo.QueryResult<Types.SearchMySongsQuery, Types.SearchMySongsQueryVariables>;
export function refetchSearchMySongsQuery(variables?: Types.SearchMySongsQueryVariables) {
      return { query: SearchMySongsDocument, variables: variables }
    }
export const GetSongTrackTypesDocument = gql`
    query GetSongTrackTypes($songId: Int!) {
  song_by_pk(song_id: $songId) {
    tracks {
      track_type_id
    }
  }
}
    `;

/**
 * __useGetSongTrackTypesQuery__
 *
 * To run a query within a React component, call `useGetSongTrackTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongTrackTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongTrackTypesQuery({
 *   variables: {
 *      songId: // value for 'songId'
 *   },
 * });
 */
export function useGetSongTrackTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongTrackTypesQuery, Types.GetSongTrackTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongTrackTypesQuery, Types.GetSongTrackTypesQueryVariables>(GetSongTrackTypesDocument, options);
      }
export function useGetSongTrackTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongTrackTypesQuery, Types.GetSongTrackTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongTrackTypesQuery, Types.GetSongTrackTypesQueryVariables>(GetSongTrackTypesDocument, options);
        }
export type GetSongTrackTypesQueryHookResult = ReturnType<typeof useGetSongTrackTypesQuery>;
export type GetSongTrackTypesLazyQueryHookResult = ReturnType<typeof useGetSongTrackTypesLazyQuery>;
export type GetSongTrackTypesQueryResult = Apollo.QueryResult<Types.GetSongTrackTypesQuery, Types.GetSongTrackTypesQueryVariables>;
export function refetchGetSongTrackTypesQuery(variables?: Types.GetSongTrackTypesQueryVariables) {
      return { query: GetSongTrackTypesDocument, variables: variables }
    }
export const GetFeaturedSongsDocument = gql`
    query getFeaturedSongs($conditions: [song_bool_exp!] = [{title: {_ilike: "%%"}}], $teacherId: Int, $offset: Int, $limit: Int, $order: order_by = asc, $orderDate: order_by, $chordFilters: [song_bool_exp!] = [{}]) {
  song(
    where: {_or: $conditions, featured: {_eq: true}, song_id: {_is_null: false}, teacher_id: {_eq: $teacherId}, _and: [{_or: $chordFilters}], active: {_eq: true}}
    offset: $offset
    limit: $limit
    order_by: {title: $order, created_at: $orderDate}
  ) {
    recommended
    is_favorite(args: {teacherid: $teacherId})
    featured
    ...SongExtendedFragment
  }
  song_aggregate(
    where: {active: {_eq: true}, featured: {_eq: true}, song_id: {_is_null: false}, _and: [{_or: $chordFilters}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${SongExtendedFragmentFragmentDoc}`;

/**
 * __useGetFeaturedSongsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedSongsQuery({
 *   variables: {
 *      conditions: // value for 'conditions'
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      orderDate: // value for 'orderDate'
 *      chordFilters: // value for 'chordFilters'
 *   },
 * });
 */
export function useGetFeaturedSongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFeaturedSongsQuery, Types.GetFeaturedSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeaturedSongsQuery, Types.GetFeaturedSongsQueryVariables>(GetFeaturedSongsDocument, options);
      }
export function useGetFeaturedSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeaturedSongsQuery, Types.GetFeaturedSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeaturedSongsQuery, Types.GetFeaturedSongsQueryVariables>(GetFeaturedSongsDocument, options);
        }
export type GetFeaturedSongsQueryHookResult = ReturnType<typeof useGetFeaturedSongsQuery>;
export type GetFeaturedSongsLazyQueryHookResult = ReturnType<typeof useGetFeaturedSongsLazyQuery>;
export type GetFeaturedSongsQueryResult = Apollo.QueryResult<Types.GetFeaturedSongsQuery, Types.GetFeaturedSongsQueryVariables>;
export function refetchGetFeaturedSongsQuery(variables?: Types.GetFeaturedSongsQueryVariables) {
      return { query: GetFeaturedSongsDocument, variables: variables }
    }
export const GetSongByClassIdDocument = gql`
    query GetSongByClassId($classId: Int!, $search: [song_bool_exp!], $limit: Int, $offset: Int) {
  song(
    where: {class_songs: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
    offset: $offset
  ) {
    song_id
    image_path
    teacher_id
    resource_path
    title
    lockable_content_id
    active
    lockable_content {
      lockable_content_id
      visibility
    }
  }
  song_aggregate(where: {class_songs: {class_id: {_eq: $classId}}, _or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSongByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSongByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSongByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongByClassIdQuery, Types.GetSongByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongByClassIdQuery, Types.GetSongByClassIdQueryVariables>(GetSongByClassIdDocument, options);
      }
export function useGetSongByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongByClassIdQuery, Types.GetSongByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongByClassIdQuery, Types.GetSongByClassIdQueryVariables>(GetSongByClassIdDocument, options);
        }
export type GetSongByClassIdQueryHookResult = ReturnType<typeof useGetSongByClassIdQuery>;
export type GetSongByClassIdLazyQueryHookResult = ReturnType<typeof useGetSongByClassIdLazyQuery>;
export type GetSongByClassIdQueryResult = Apollo.QueryResult<Types.GetSongByClassIdQuery, Types.GetSongByClassIdQueryVariables>;
export function refetchGetSongByClassIdQuery(variables?: Types.GetSongByClassIdQueryVariables) {
      return { query: GetSongByClassIdDocument, variables: variables }
    }
export const GetSearchSongByClassIdDocument = gql`
    query GetSearchSongByClassId($classId: Int!, $search: [song_bool_exp!], $limit: Int) {
  song(
    where: {class_songs: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
  ) {
    song_id
    image_path
    teacher_id
    resource_path
    title
    lockable_content_id
    active
    lockable_content {
      lockable_content_id
      visibility
    }
  }
  song_aggregate(where: {class_songs: {class_id: {_eq: $classId}}, _or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSearchSongByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSearchSongByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchSongByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchSongByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSearchSongByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSearchSongByClassIdQuery, Types.GetSearchSongByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchSongByClassIdQuery, Types.GetSearchSongByClassIdQueryVariables>(GetSearchSongByClassIdDocument, options);
      }
export function useGetSearchSongByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchSongByClassIdQuery, Types.GetSearchSongByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchSongByClassIdQuery, Types.GetSearchSongByClassIdQueryVariables>(GetSearchSongByClassIdDocument, options);
        }
export type GetSearchSongByClassIdQueryHookResult = ReturnType<typeof useGetSearchSongByClassIdQuery>;
export type GetSearchSongByClassIdLazyQueryHookResult = ReturnType<typeof useGetSearchSongByClassIdLazyQuery>;
export type GetSearchSongByClassIdQueryResult = Apollo.QueryResult<Types.GetSearchSongByClassIdQuery, Types.GetSearchSongByClassIdQueryVariables>;
export function refetchGetSearchSongByClassIdQuery(variables?: Types.GetSearchSongByClassIdQueryVariables) {
      return { query: GetSearchSongByClassIdDocument, variables: variables }
    }
export const GetAllSongsDocument = gql`
    query GetAllSongs($search: [song_bool_exp!], $offset: Int, $limit: Int) {
  song_aggregate(where: {_or: $search}) {
    aggregate {
      count
    }
  }
  song(where: {_or: $search}, offset: $offset, limit: $limit) {
    song_id
    teacher_id
    resource_path
    title
    image_path
    lockable_content {
      visibility
    }
  }
}
    `;

/**
 * __useGetAllSongsQuery__
 *
 * To run a query within a React component, call `useGetAllSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSongsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllSongsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllSongsQuery, Types.GetAllSongsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllSongsQuery, Types.GetAllSongsQueryVariables>(GetAllSongsDocument, options);
      }
export function useGetAllSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllSongsQuery, Types.GetAllSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllSongsQuery, Types.GetAllSongsQueryVariables>(GetAllSongsDocument, options);
        }
export type GetAllSongsQueryHookResult = ReturnType<typeof useGetAllSongsQuery>;
export type GetAllSongsLazyQueryHookResult = ReturnType<typeof useGetAllSongsLazyQuery>;
export type GetAllSongsQueryResult = Apollo.QueryResult<Types.GetAllSongsQuery, Types.GetAllSongsQueryVariables>;
export function refetchGetAllSongsQuery(variables?: Types.GetAllSongsQueryVariables) {
      return { query: GetAllSongsDocument, variables: variables }
    }
export const GetSongDataByClassIdDocument = gql`
    query GetSongDataByClassId($classId: Int!, $limit: Int!) {
  song(where: {class_songs: {class_id: {_eq: $classId}}}, limit: $limit) {
    song_id
    image_path
    resource_path
    teacher_id
    title
    lockable_content_id
    active
    lockable_content {
      lockable_content_id
      visibility
    }
  }
  song_aggregate(where: {class_songs: {class_id: {_eq: $classId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSongDataByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSongDataByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSongDataByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSongDataByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSongDataByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSongDataByClassIdQuery, Types.GetSongDataByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSongDataByClassIdQuery, Types.GetSongDataByClassIdQueryVariables>(GetSongDataByClassIdDocument, options);
      }
export function useGetSongDataByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSongDataByClassIdQuery, Types.GetSongDataByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSongDataByClassIdQuery, Types.GetSongDataByClassIdQueryVariables>(GetSongDataByClassIdDocument, options);
        }
export type GetSongDataByClassIdQueryHookResult = ReturnType<typeof useGetSongDataByClassIdQuery>;
export type GetSongDataByClassIdLazyQueryHookResult = ReturnType<typeof useGetSongDataByClassIdLazyQuery>;
export type GetSongDataByClassIdQueryResult = Apollo.QueryResult<Types.GetSongDataByClassIdQuery, Types.GetSongDataByClassIdQueryVariables>;
export function refetchGetSongDataByClassIdQuery(variables?: Types.GetSongDataByClassIdQueryVariables) {
      return { query: GetSongDataByClassIdDocument, variables: variables }
    }
export const InsertTeacherSongFavoriteDocument = gql`
    mutation insertTeacherSongFavorite($teacherId: Int, $songId: Int) {
  insert_teacher_song_favorite(
    objects: {song_id: $songId, teacher_id: $teacherId}
  ) {
    returning {
      teacher_id
      song {
        teacher_id
        song_id
        is_favorite(args: {teacherid: $teacherId})
      }
    }
  }
}
    `;
export type InsertTeacherSongFavoriteMutationFn = Apollo.MutationFunction<Types.InsertTeacherSongFavoriteMutation, Types.InsertTeacherSongFavoriteMutationVariables>;

/**
 * __useInsertTeacherSongFavoriteMutation__
 *
 * To run a mutation, you first call `useInsertTeacherSongFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeacherSongFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeacherSongFavoriteMutation, { data, loading, error }] = useInsertTeacherSongFavoriteMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      songId: // value for 'songId'
 *   },
 * });
 */
export function useInsertTeacherSongFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTeacherSongFavoriteMutation, Types.InsertTeacherSongFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTeacherSongFavoriteMutation, Types.InsertTeacherSongFavoriteMutationVariables>(InsertTeacherSongFavoriteDocument, options);
      }
export type InsertTeacherSongFavoriteMutationHookResult = ReturnType<typeof useInsertTeacherSongFavoriteMutation>;
export type InsertTeacherSongFavoriteMutationResult = Apollo.MutationResult<Types.InsertTeacherSongFavoriteMutation>;
export type InsertTeacherSongFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.InsertTeacherSongFavoriteMutation, Types.InsertTeacherSongFavoriteMutationVariables>;
export const DeleteTeacherSongFavoriteDocument = gql`
    mutation deleteTeacherSongFavorite($teacherId: Int, $songId: Int!) {
  delete_teacher_song_favorite(
    where: {song_id: {_eq: $songId}, teacher_id: {_eq: $teacherId}}
  ) {
    returning {
      teacher_id
      song {
        teacher_id
        song_id
        is_favorite(args: {teacherid: $teacherId})
      }
    }
  }
}
    `;
export type DeleteTeacherSongFavoriteMutationFn = Apollo.MutationFunction<Types.DeleteTeacherSongFavoriteMutation, Types.DeleteTeacherSongFavoriteMutationVariables>;

/**
 * __useDeleteTeacherSongFavoriteMutation__
 *
 * To run a mutation, you first call `useDeleteTeacherSongFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeacherSongFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeacherSongFavoriteMutation, { data, loading, error }] = useDeleteTeacherSongFavoriteMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      songId: // value for 'songId'
 *   },
 * });
 */
export function useDeleteTeacherSongFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTeacherSongFavoriteMutation, Types.DeleteTeacherSongFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTeacherSongFavoriteMutation, Types.DeleteTeacherSongFavoriteMutationVariables>(DeleteTeacherSongFavoriteDocument, options);
      }
export type DeleteTeacherSongFavoriteMutationHookResult = ReturnType<typeof useDeleteTeacherSongFavoriteMutation>;
export type DeleteTeacherSongFavoriteMutationResult = Apollo.MutationResult<Types.DeleteTeacherSongFavoriteMutation>;
export type DeleteTeacherSongFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTeacherSongFavoriteMutation, Types.DeleteTeacherSongFavoriteMutationVariables>;
export const InsertStudentsBulkDocument = gql`
    mutation InsertStudentsBulk($students: [student_insert_input!]!) {
  insert_student(objects: $students) {
    affected_rows
    returning {
      student_id
    }
  }
}
    `;
export type InsertStudentsBulkMutationFn = Apollo.MutationFunction<Types.InsertStudentsBulkMutation, Types.InsertStudentsBulkMutationVariables>;

/**
 * __useInsertStudentsBulkMutation__
 *
 * To run a mutation, you first call `useInsertStudentsBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStudentsBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStudentsBulkMutation, { data, loading, error }] = useInsertStudentsBulkMutation({
 *   variables: {
 *      students: // value for 'students'
 *   },
 * });
 */
export function useInsertStudentsBulkMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertStudentsBulkMutation, Types.InsertStudentsBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertStudentsBulkMutation, Types.InsertStudentsBulkMutationVariables>(InsertStudentsBulkDocument, options);
      }
export type InsertStudentsBulkMutationHookResult = ReturnType<typeof useInsertStudentsBulkMutation>;
export type InsertStudentsBulkMutationResult = Apollo.MutationResult<Types.InsertStudentsBulkMutation>;
export type InsertStudentsBulkMutationOptions = Apollo.BaseMutationOptions<Types.InsertStudentsBulkMutation, Types.InsertStudentsBulkMutationVariables>;
export const RemoveStudentFromTeacherClassesDocument = gql`
    mutation RemoveStudentFromTeacherClasses($studentId: Int!, $teacherId: Int!) {
  delete_class_student(
    where: {_and: [{class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}, {student_id: {_eq: $studentId}}]}
  ) {
    affected_rows
  }
}
    `;
export type RemoveStudentFromTeacherClassesMutationFn = Apollo.MutationFunction<Types.RemoveStudentFromTeacherClassesMutation, Types.RemoveStudentFromTeacherClassesMutationVariables>;

/**
 * __useRemoveStudentFromTeacherClassesMutation__
 *
 * To run a mutation, you first call `useRemoveStudentFromTeacherClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentFromTeacherClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentFromTeacherClassesMutation, { data, loading, error }] = useRemoveStudentFromTeacherClassesMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useRemoveStudentFromTeacherClassesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveStudentFromTeacherClassesMutation, Types.RemoveStudentFromTeacherClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveStudentFromTeacherClassesMutation, Types.RemoveStudentFromTeacherClassesMutationVariables>(RemoveStudentFromTeacherClassesDocument, options);
      }
export type RemoveStudentFromTeacherClassesMutationHookResult = ReturnType<typeof useRemoveStudentFromTeacherClassesMutation>;
export type RemoveStudentFromTeacherClassesMutationResult = Apollo.MutationResult<Types.RemoveStudentFromTeacherClassesMutation>;
export type RemoveStudentFromTeacherClassesMutationOptions = Apollo.BaseMutationOptions<Types.RemoveStudentFromTeacherClassesMutation, Types.RemoveStudentFromTeacherClassesMutationVariables>;
export const UpdateStudentAvatarAndStageNameDocument = gql`
    mutation UpdateStudentAvatarAndStageName($imagePath: String, $stageName: String!, $studentId: Int!) {
  update_student_by_pk(
    pk_columns: {student_id: $studentId}
    _set: {avatar_selected: true, image_path: $imagePath, stage_name: $stageName}
  ) {
    student_id
  }
}
    `;
export type UpdateStudentAvatarAndStageNameMutationFn = Apollo.MutationFunction<Types.UpdateStudentAvatarAndStageNameMutation, Types.UpdateStudentAvatarAndStageNameMutationVariables>;

/**
 * __useUpdateStudentAvatarAndStageNameMutation__
 *
 * To run a mutation, you first call `useUpdateStudentAvatarAndStageNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentAvatarAndStageNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentAvatarAndStageNameMutation, { data, loading, error }] = useUpdateStudentAvatarAndStageNameMutation({
 *   variables: {
 *      imagePath: // value for 'imagePath'
 *      stageName: // value for 'stageName'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useUpdateStudentAvatarAndStageNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudentAvatarAndStageNameMutation, Types.UpdateStudentAvatarAndStageNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudentAvatarAndStageNameMutation, Types.UpdateStudentAvatarAndStageNameMutationVariables>(UpdateStudentAvatarAndStageNameDocument, options);
      }
export type UpdateStudentAvatarAndStageNameMutationHookResult = ReturnType<typeof useUpdateStudentAvatarAndStageNameMutation>;
export type UpdateStudentAvatarAndStageNameMutationResult = Apollo.MutationResult<Types.UpdateStudentAvatarAndStageNameMutation>;
export type UpdateStudentAvatarAndStageNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudentAvatarAndStageNameMutation, Types.UpdateStudentAvatarAndStageNameMutationVariables>;
export const SetStudentEmailDocument = gql`
    mutation SetStudentEmail($code: String!, $email: String!) {
  update_student(where: {code: {_eq: $code}}, _set: {email: $email}) {
    returning {
      code
      student_id
    }
  }
}
    `;
export type SetStudentEmailMutationFn = Apollo.MutationFunction<Types.SetStudentEmailMutation, Types.SetStudentEmailMutationVariables>;

/**
 * __useSetStudentEmailMutation__
 *
 * To run a mutation, you first call `useSetStudentEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStudentEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStudentEmailMutation, { data, loading, error }] = useSetStudentEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSetStudentEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetStudentEmailMutation, Types.SetStudentEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetStudentEmailMutation, Types.SetStudentEmailMutationVariables>(SetStudentEmailDocument, options);
      }
export type SetStudentEmailMutationHookResult = ReturnType<typeof useSetStudentEmailMutation>;
export type SetStudentEmailMutationResult = Apollo.MutationResult<Types.SetStudentEmailMutation>;
export type SetStudentEmailMutationOptions = Apollo.BaseMutationOptions<Types.SetStudentEmailMutation, Types.SetStudentEmailMutationVariables>;
export const UpdateStudentInformationDocument = gql`
    mutation UpdateStudentInformation($studentId: Int, $lastName: String = "", $firstName: String = "", $stageName: String = "") {
  update_student(
    where: {student_id: {_eq: $studentId}}
    _set: {last_name: $lastName, first_name: $firstName, stage_name: $stageName}
  ) {
    returning {
      first_name
      last_name
      stage_name
    }
  }
}
    `;
export type UpdateStudentInformationMutationFn = Apollo.MutationFunction<Types.UpdateStudentInformationMutation, Types.UpdateStudentInformationMutationVariables>;

/**
 * __useUpdateStudentInformationMutation__
 *
 * To run a mutation, you first call `useUpdateStudentInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentInformationMutation, { data, loading, error }] = useUpdateStudentInformationMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      lastName: // value for 'lastName'
 *      firstName: // value for 'firstName'
 *      stageName: // value for 'stageName'
 *   },
 * });
 */
export function useUpdateStudentInformationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStudentInformationMutation, Types.UpdateStudentInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStudentInformationMutation, Types.UpdateStudentInformationMutationVariables>(UpdateStudentInformationDocument, options);
      }
export type UpdateStudentInformationMutationHookResult = ReturnType<typeof useUpdateStudentInformationMutation>;
export type UpdateStudentInformationMutationResult = Apollo.MutationResult<Types.UpdateStudentInformationMutation>;
export type UpdateStudentInformationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStudentInformationMutation, Types.UpdateStudentInformationMutationVariables>;
export const StudentByPkDocument = gql`
    query StudentByPK($student_id: Int!) {
  student_by_pk(student_id: $student_id) {
    ...StudentBasicFragment
  }
  student_aggregate {
    aggregate {
      count
    }
  }
}
    ${StudentBasicFragmentFragmentDoc}`;

/**
 * __useStudentByPkQuery__
 *
 * To run a query within a React component, call `useStudentByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentByPkQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *   },
 * });
 */
export function useStudentByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.StudentByPkQuery, Types.StudentByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentByPkQuery, Types.StudentByPkQueryVariables>(StudentByPkDocument, options);
      }
export function useStudentByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentByPkQuery, Types.StudentByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentByPkQuery, Types.StudentByPkQueryVariables>(StudentByPkDocument, options);
        }
export type StudentByPkQueryHookResult = ReturnType<typeof useStudentByPkQuery>;
export type StudentByPkLazyQueryHookResult = ReturnType<typeof useStudentByPkLazyQuery>;
export type StudentByPkQueryResult = Apollo.QueryResult<Types.StudentByPkQuery, Types.StudentByPkQueryVariables>;
export function refetchStudentByPkQuery(variables?: Types.StudentByPkQueryVariables) {
      return { query: StudentByPkDocument, variables: variables }
    }
export const GetStudentByPkExtendedDocument = gql`
    query GetStudentByPKExtended($teacherId: Int, $studentId: Int!) {
  student_by_pk(student_id: $studentId) {
    student_id
    image_path
    first_name
    last_name
    code
    email
    has_lti_integration
    is_active
    class_students(
      order_by: {class: {title: asc}}
      where: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}
    ) {
      class {
        ...ClassDetailsExtendedFragment
      }
    }
  }
}
    ${ClassDetailsExtendedFragmentFragmentDoc}`;

/**
 * __useGetStudentByPkExtendedQuery__
 *
 * To run a query within a React component, call `useGetStudentByPkExtendedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentByPkExtendedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentByPkExtendedQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentByPkExtendedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentByPkExtendedQuery, Types.GetStudentByPkExtendedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentByPkExtendedQuery, Types.GetStudentByPkExtendedQueryVariables>(GetStudentByPkExtendedDocument, options);
      }
export function useGetStudentByPkExtendedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentByPkExtendedQuery, Types.GetStudentByPkExtendedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentByPkExtendedQuery, Types.GetStudentByPkExtendedQueryVariables>(GetStudentByPkExtendedDocument, options);
        }
export type GetStudentByPkExtendedQueryHookResult = ReturnType<typeof useGetStudentByPkExtendedQuery>;
export type GetStudentByPkExtendedLazyQueryHookResult = ReturnType<typeof useGetStudentByPkExtendedLazyQuery>;
export type GetStudentByPkExtendedQueryResult = Apollo.QueryResult<Types.GetStudentByPkExtendedQuery, Types.GetStudentByPkExtendedQueryVariables>;
export function refetchGetStudentByPkExtendedQuery(variables?: Types.GetStudentByPkExtendedQueryVariables) {
      return { query: GetStudentByPkExtendedDocument, variables: variables }
    }
export const GetStudentsByKeywordDocument = gql`
    query GetStudentsByKeyword($teacherId: Int!, $conditions: [student_bool_exp!] = [{first_name: {_like: "%%"}}], $classId: Int!, $first_name: order_by = asc) {
  student(
    order_by: {first_name: $first_name}
    where: {_and: [{class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}}, {_not: {class_students: {class_id: {_eq: $classId}}}}, {_or: $conditions}]}
  ) {
    student_id
    first_name
    last_name
    is_active
    email
    image_path
    class_students {
      class {
        title
        class_id
      }
    }
  }
  student_aggregate(
    where: {_and: [{class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}}, {_not: {class_students: {class_id: {_eq: $classId}}}}, {_or: $conditions}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetStudentsByKeywordQuery__
 *
 * To run a query within a React component, call `useGetStudentsByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByKeywordQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      conditions: // value for 'conditions'
 *      classId: // value for 'classId'
 *      first_name: // value for 'first_name'
 *   },
 * });
 */
export function useGetStudentsByKeywordQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentsByKeywordQuery, Types.GetStudentsByKeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentsByKeywordQuery, Types.GetStudentsByKeywordQueryVariables>(GetStudentsByKeywordDocument, options);
      }
export function useGetStudentsByKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentsByKeywordQuery, Types.GetStudentsByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentsByKeywordQuery, Types.GetStudentsByKeywordQueryVariables>(GetStudentsByKeywordDocument, options);
        }
export type GetStudentsByKeywordQueryHookResult = ReturnType<typeof useGetStudentsByKeywordQuery>;
export type GetStudentsByKeywordLazyQueryHookResult = ReturnType<typeof useGetStudentsByKeywordLazyQuery>;
export type GetStudentsByKeywordQueryResult = Apollo.QueryResult<Types.GetStudentsByKeywordQuery, Types.GetStudentsByKeywordQueryVariables>;
export function refetchGetStudentsByKeywordQuery(variables?: Types.GetStudentsByKeywordQueryVariables) {
      return { query: GetStudentsByKeywordDocument, variables: variables }
    }
export const GetStudentProductivityByPkDocument = gql`
    query GetStudentProductivityByPK($studentId: Int!, $year: Int!, $minMonth: Int, $maxMonth: Int, $minDay: Int, $maxDay: Int, $classId: Int) {
  student_by_pk(student_id: $studentId) {
    student_id
    first_name
    last_name
    student_productivity_yearly(
      where: {year: {_eq: $year}, class_id: {_eq: $classId}}
    ) {
      student_productivity_yearly_id
      year
      video_views
      practice_sessions
      recording_sessions
    }
    student_productivity_monthly(
      where: {year: {_eq: $year}, month: {_gte: $minMonth, _lte: $maxMonth}, class_id: {_eq: $classId}}
    ) {
      student_productivity_monthly_id
      month
      practice_sessions
      recording_sessions
      video_views
    }
    student_productivity_daily(
      where: {year: {_eq: $year}, month: {_gte: $minMonth, _lte: $maxMonth}, day: {_gt: $minDay, _lte: $maxDay}, class_id: {_eq: $classId}}
    ) {
      student_productivity_daily_id
      day
      practice_sessions
      recording_sessions
      video_views
    }
  }
}
    `;

/**
 * __useGetStudentProductivityByPkQuery__
 *
 * To run a query within a React component, call `useGetStudentProductivityByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentProductivityByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentProductivityByPkQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      year: // value for 'year'
 *      minMonth: // value for 'minMonth'
 *      maxMonth: // value for 'maxMonth'
 *      minDay: // value for 'minDay'
 *      maxDay: // value for 'maxDay'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useGetStudentProductivityByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentProductivityByPkQuery, Types.GetStudentProductivityByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentProductivityByPkQuery, Types.GetStudentProductivityByPkQueryVariables>(GetStudentProductivityByPkDocument, options);
      }
export function useGetStudentProductivityByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentProductivityByPkQuery, Types.GetStudentProductivityByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentProductivityByPkQuery, Types.GetStudentProductivityByPkQueryVariables>(GetStudentProductivityByPkDocument, options);
        }
export type GetStudentProductivityByPkQueryHookResult = ReturnType<typeof useGetStudentProductivityByPkQuery>;
export type GetStudentProductivityByPkLazyQueryHookResult = ReturnType<typeof useGetStudentProductivityByPkLazyQuery>;
export type GetStudentProductivityByPkQueryResult = Apollo.QueryResult<Types.GetStudentProductivityByPkQuery, Types.GetStudentProductivityByPkQueryVariables>;
export function refetchGetStudentProductivityByPkQuery(variables?: Types.GetStudentProductivityByPkQueryVariables) {
      return { query: GetStudentProductivityByPkDocument, variables: variables }
    }
export const GetStudentsByEmailsDocument = gql`
    query GetStudentsByEmails($studentsEmails: [String!]) {
  student(where: {email: {_in: $studentsEmails}}) {
    student_id
    email
  }
}
    `;

/**
 * __useGetStudentsByEmailsQuery__
 *
 * To run a query within a React component, call `useGetStudentsByEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByEmailsQuery({
 *   variables: {
 *      studentsEmails: // value for 'studentsEmails'
 *   },
 * });
 */
export function useGetStudentsByEmailsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetStudentsByEmailsQuery, Types.GetStudentsByEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentsByEmailsQuery, Types.GetStudentsByEmailsQueryVariables>(GetStudentsByEmailsDocument, options);
      }
export function useGetStudentsByEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentsByEmailsQuery, Types.GetStudentsByEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentsByEmailsQuery, Types.GetStudentsByEmailsQueryVariables>(GetStudentsByEmailsDocument, options);
        }
export type GetStudentsByEmailsQueryHookResult = ReturnType<typeof useGetStudentsByEmailsQuery>;
export type GetStudentsByEmailsLazyQueryHookResult = ReturnType<typeof useGetStudentsByEmailsLazyQuery>;
export type GetStudentsByEmailsQueryResult = Apollo.QueryResult<Types.GetStudentsByEmailsQuery, Types.GetStudentsByEmailsQueryVariables>;
export function refetchGetStudentsByEmailsQuery(variables?: Types.GetStudentsByEmailsQueryVariables) {
      return { query: GetStudentsByEmailsDocument, variables: variables }
    }
export const GetClassStudentActivitiesDocument = gql`
    query GetClassStudentActivities($classId: Int, $offset: Int, $limit: Int, $order: [student_activity_order_by!] = {created_at: asc}) {
  student_activity(
    where: {class_id: {_eq: $classId}}
    offset: $offset
    limit: $limit
    order_by: $order
  ) {
    ...StudentActivityFragment
  }
  student_activity_aggregate(where: {class_id: {_eq: $classId}}) {
    aggregate {
      count
    }
  }
}
    ${StudentActivityFragmentFragmentDoc}`;

/**
 * __useGetClassStudentActivitiesQuery__
 *
 * To run a query within a React component, call `useGetClassStudentActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentActivitiesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetClassStudentActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetClassStudentActivitiesQuery, Types.GetClassStudentActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassStudentActivitiesQuery, Types.GetClassStudentActivitiesQueryVariables>(GetClassStudentActivitiesDocument, options);
      }
export function useGetClassStudentActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassStudentActivitiesQuery, Types.GetClassStudentActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassStudentActivitiesQuery, Types.GetClassStudentActivitiesQueryVariables>(GetClassStudentActivitiesDocument, options);
        }
export type GetClassStudentActivitiesQueryHookResult = ReturnType<typeof useGetClassStudentActivitiesQuery>;
export type GetClassStudentActivitiesLazyQueryHookResult = ReturnType<typeof useGetClassStudentActivitiesLazyQuery>;
export type GetClassStudentActivitiesQueryResult = Apollo.QueryResult<Types.GetClassStudentActivitiesQuery, Types.GetClassStudentActivitiesQueryVariables>;
export function refetchGetClassStudentActivitiesQuery(variables?: Types.GetClassStudentActivitiesQueryVariables) {
      return { query: GetClassStudentActivitiesDocument, variables: variables }
    }
export const GetStudentActivitiesByStudentByClassDocument = gql`
    query GetStudentActivitiesByStudentByClass($classId: Int, $offset: Int, $limit: Int, $order: [student_activity_order_by!] = {created_at: asc}, $studentId: Int!) {
  student_activity(
    where: {class_id: {_eq: $classId}, student_id: {_eq: $studentId}}
    offset: $offset
    limit: $limit
    order_by: $order
  ) {
    ...StudentActivityFragment
  }
  student_activity_aggregate(
    where: {class_id: {_eq: $classId}, student_id: {_eq: $studentId}}
  ) {
    aggregate {
      count
    }
  }
}
    ${StudentActivityFragmentFragmentDoc}`;

/**
 * __useGetStudentActivitiesByStudentByClassQuery__
 *
 * To run a query within a React component, call `useGetStudentActivitiesByStudentByClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentActivitiesByStudentByClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentActivitiesByStudentByClassQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentActivitiesByStudentByClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentActivitiesByStudentByClassQuery, Types.GetStudentActivitiesByStudentByClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentActivitiesByStudentByClassQuery, Types.GetStudentActivitiesByStudentByClassQueryVariables>(GetStudentActivitiesByStudentByClassDocument, options);
      }
export function useGetStudentActivitiesByStudentByClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentActivitiesByStudentByClassQuery, Types.GetStudentActivitiesByStudentByClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentActivitiesByStudentByClassQuery, Types.GetStudentActivitiesByStudentByClassQueryVariables>(GetStudentActivitiesByStudentByClassDocument, options);
        }
export type GetStudentActivitiesByStudentByClassQueryHookResult = ReturnType<typeof useGetStudentActivitiesByStudentByClassQuery>;
export type GetStudentActivitiesByStudentByClassLazyQueryHookResult = ReturnType<typeof useGetStudentActivitiesByStudentByClassLazyQuery>;
export type GetStudentActivitiesByStudentByClassQueryResult = Apollo.QueryResult<Types.GetStudentActivitiesByStudentByClassQuery, Types.GetStudentActivitiesByStudentByClassQueryVariables>;
export function refetchGetStudentActivitiesByStudentByClassQuery(variables?: Types.GetStudentActivitiesByStudentByClassQueryVariables) {
      return { query: GetStudentActivitiesByStudentByClassDocument, variables: variables }
    }
export const GetGoogleOAuthUrlDocument = gql`
    query GetGoogleOAuthUrl {
  getGoogleAuthUrl
}
    `;

/**
 * __useGetGoogleOAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetGoogleOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleOAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleOAuthUrlQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetGoogleOAuthUrlQuery, Types.GetGoogleOAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGoogleOAuthUrlQuery, Types.GetGoogleOAuthUrlQueryVariables>(GetGoogleOAuthUrlDocument, options);
      }
export function useGetGoogleOAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGoogleOAuthUrlQuery, Types.GetGoogleOAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGoogleOAuthUrlQuery, Types.GetGoogleOAuthUrlQueryVariables>(GetGoogleOAuthUrlDocument, options);
        }
export type GetGoogleOAuthUrlQueryHookResult = ReturnType<typeof useGetGoogleOAuthUrlQuery>;
export type GetGoogleOAuthUrlLazyQueryHookResult = ReturnType<typeof useGetGoogleOAuthUrlLazyQuery>;
export type GetGoogleOAuthUrlQueryResult = Apollo.QueryResult<Types.GetGoogleOAuthUrlQuery, Types.GetGoogleOAuthUrlQueryVariables>;
export function refetchGetGoogleOAuthUrlQuery(variables?: Types.GetGoogleOAuthUrlQueryVariables) {
      return { query: GetGoogleOAuthUrlDocument, variables: variables }
    }
export const GetGoogeOAuthTokenDocument = gql`
    query GetGoogeOAuthToken($code: String!) {
  getGoogleToken(filter: {code: $code}) {
    access_token
    refresh_token
    scope
    token_type
    id_token
    expiry_date
  }
}
    `;

/**
 * __useGetGoogeOAuthTokenQuery__
 *
 * To run a query within a React component, call `useGetGoogeOAuthTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogeOAuthTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogeOAuthTokenQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetGoogeOAuthTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGoogeOAuthTokenQuery, Types.GetGoogeOAuthTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGoogeOAuthTokenQuery, Types.GetGoogeOAuthTokenQueryVariables>(GetGoogeOAuthTokenDocument, options);
      }
export function useGetGoogeOAuthTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGoogeOAuthTokenQuery, Types.GetGoogeOAuthTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGoogeOAuthTokenQuery, Types.GetGoogeOAuthTokenQueryVariables>(GetGoogeOAuthTokenDocument, options);
        }
export type GetGoogeOAuthTokenQueryHookResult = ReturnType<typeof useGetGoogeOAuthTokenQuery>;
export type GetGoogeOAuthTokenLazyQueryHookResult = ReturnType<typeof useGetGoogeOAuthTokenLazyQuery>;
export type GetGoogeOAuthTokenQueryResult = Apollo.QueryResult<Types.GetGoogeOAuthTokenQuery, Types.GetGoogeOAuthTokenQueryVariables>;
export function refetchGetGoogeOAuthTokenQuery(variables?: Types.GetGoogeOAuthTokenQueryVariables) {
      return { query: GetGoogeOAuthTokenDocument, variables: variables }
    }
export const GetGoogleAccountDocument = gql`
    query GetGoogleAccount($credentials: GoogleOAuthTokenInput!) {
  getGoogleAccount(filter: {credentials: $credentials}) {
    integrationId
    teacherEmail
  }
}
    `;

/**
 * __useGetGoogleAccountQuery__
 *
 * To run a query within a React component, call `useGetGoogleAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAccountQuery({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useGetGoogleAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGoogleAccountQuery, Types.GetGoogleAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGoogleAccountQuery, Types.GetGoogleAccountQueryVariables>(GetGoogleAccountDocument, options);
      }
export function useGetGoogleAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGoogleAccountQuery, Types.GetGoogleAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGoogleAccountQuery, Types.GetGoogleAccountQueryVariables>(GetGoogleAccountDocument, options);
        }
export type GetGoogleAccountQueryHookResult = ReturnType<typeof useGetGoogleAccountQuery>;
export type GetGoogleAccountLazyQueryHookResult = ReturnType<typeof useGetGoogleAccountLazyQuery>;
export type GetGoogleAccountQueryResult = Apollo.QueryResult<Types.GetGoogleAccountQuery, Types.GetGoogleAccountQueryVariables>;
export function refetchGetGoogleAccountQuery(variables?: Types.GetGoogleAccountQueryVariables) {
      return { query: GetGoogleAccountDocument, variables: variables }
    }
export const GetGoogleGroupsDocument = gql`
    query GetGoogleGroups($credentials: GoogleOAuthTokenInput!) {
  getGoogleGroups(filter: {credentials: $credentials}) {
    id
    name
  }
}
    `;

/**
 * __useGetGoogleGroupsQuery__
 *
 * To run a query within a React component, call `useGetGoogleGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleGroupsQuery({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useGetGoogleGroupsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGoogleGroupsQuery, Types.GetGoogleGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGoogleGroupsQuery, Types.GetGoogleGroupsQueryVariables>(GetGoogleGroupsDocument, options);
      }
export function useGetGoogleGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGoogleGroupsQuery, Types.GetGoogleGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGoogleGroupsQuery, Types.GetGoogleGroupsQueryVariables>(GetGoogleGroupsDocument, options);
        }
export type GetGoogleGroupsQueryHookResult = ReturnType<typeof useGetGoogleGroupsQuery>;
export type GetGoogleGroupsLazyQueryHookResult = ReturnType<typeof useGetGoogleGroupsLazyQuery>;
export type GetGoogleGroupsQueryResult = Apollo.QueryResult<Types.GetGoogleGroupsQuery, Types.GetGoogleGroupsQueryVariables>;
export function refetchGetGoogleGroupsQuery(variables?: Types.GetGoogleGroupsQueryVariables) {
      return { query: GetGoogleGroupsDocument, variables: variables }
    }
export const GetGoogleStudentsByCourseIdDocument = gql`
    query GetGoogleStudentsByCourseId($credentials: GoogleOAuthTokenInput!, $groupId: String!) {
  getGoogleMembers(filter: {credentials: $credentials, groupId: $groupId}) {
    id
    emailAddress
    name {
      familyName
      fullName
      givenName
    }
  }
}
    `;

/**
 * __useGetGoogleStudentsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetGoogleStudentsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleStudentsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleStudentsByCourseIdQuery({
 *   variables: {
 *      credentials: // value for 'credentials'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGoogleStudentsByCourseIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGoogleStudentsByCourseIdQuery, Types.GetGoogleStudentsByCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGoogleStudentsByCourseIdQuery, Types.GetGoogleStudentsByCourseIdQueryVariables>(GetGoogleStudentsByCourseIdDocument, options);
      }
export function useGetGoogleStudentsByCourseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGoogleStudentsByCourseIdQuery, Types.GetGoogleStudentsByCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGoogleStudentsByCourseIdQuery, Types.GetGoogleStudentsByCourseIdQueryVariables>(GetGoogleStudentsByCourseIdDocument, options);
        }
export type GetGoogleStudentsByCourseIdQueryHookResult = ReturnType<typeof useGetGoogleStudentsByCourseIdQuery>;
export type GetGoogleStudentsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetGoogleStudentsByCourseIdLazyQuery>;
export type GetGoogleStudentsByCourseIdQueryResult = Apollo.QueryResult<Types.GetGoogleStudentsByCourseIdQuery, Types.GetGoogleStudentsByCourseIdQueryVariables>;
export function refetchGetGoogleStudentsByCourseIdQuery(variables?: Types.GetGoogleStudentsByCourseIdQueryVariables) {
      return { query: GetGoogleStudentsByCourseIdDocument, variables: variables }
    }
export const GetMsoAuthUrlDocument = gql`
    query GetMSOAuthUrl {
  getMsAuthUrl
}
    `;

/**
 * __useGetMsoAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetMsoAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsoAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsoAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMsoAuthUrlQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMsoAuthUrlQuery, Types.GetMsoAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMsoAuthUrlQuery, Types.GetMsoAuthUrlQueryVariables>(GetMsoAuthUrlDocument, options);
      }
export function useGetMsoAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMsoAuthUrlQuery, Types.GetMsoAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMsoAuthUrlQuery, Types.GetMsoAuthUrlQueryVariables>(GetMsoAuthUrlDocument, options);
        }
export type GetMsoAuthUrlQueryHookResult = ReturnType<typeof useGetMsoAuthUrlQuery>;
export type GetMsoAuthUrlLazyQueryHookResult = ReturnType<typeof useGetMsoAuthUrlLazyQuery>;
export type GetMsoAuthUrlQueryResult = Apollo.QueryResult<Types.GetMsoAuthUrlQuery, Types.GetMsoAuthUrlQueryVariables>;
export function refetchGetMsoAuthUrlQuery(variables?: Types.GetMsoAuthUrlQueryVariables) {
      return { query: GetMsoAuthUrlDocument, variables: variables }
    }
export const GetMsTokenDocument = gql`
    query GetMSToken($code: String!) {
  getMsToken(filter: {code: $code})
}
    `;

/**
 * __useGetMsTokenQuery__
 *
 * To run a query within a React component, call `useGetMsTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsTokenQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetMsTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMsTokenQuery, Types.GetMsTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMsTokenQuery, Types.GetMsTokenQueryVariables>(GetMsTokenDocument, options);
      }
export function useGetMsTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMsTokenQuery, Types.GetMsTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMsTokenQuery, Types.GetMsTokenQueryVariables>(GetMsTokenDocument, options);
        }
export type GetMsTokenQueryHookResult = ReturnType<typeof useGetMsTokenQuery>;
export type GetMsTokenLazyQueryHookResult = ReturnType<typeof useGetMsTokenLazyQuery>;
export type GetMsTokenQueryResult = Apollo.QueryResult<Types.GetMsTokenQuery, Types.GetMsTokenQueryVariables>;
export function refetchGetMsTokenQuery(variables?: Types.GetMsTokenQueryVariables) {
      return { query: GetMsTokenDocument, variables: variables }
    }
export const GetMsAccountDocument = gql`
    query GetMSAccount($token: String!) {
  getMsAccount(filter: {accessToken: $token}) {
    id
    mail
  }
}
    `;

/**
 * __useGetMsAccountQuery__
 *
 * To run a query within a React component, call `useGetMsAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsAccountQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetMsAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMsAccountQuery, Types.GetMsAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMsAccountQuery, Types.GetMsAccountQueryVariables>(GetMsAccountDocument, options);
      }
export function useGetMsAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMsAccountQuery, Types.GetMsAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMsAccountQuery, Types.GetMsAccountQueryVariables>(GetMsAccountDocument, options);
        }
export type GetMsAccountQueryHookResult = ReturnType<typeof useGetMsAccountQuery>;
export type GetMsAccountLazyQueryHookResult = ReturnType<typeof useGetMsAccountLazyQuery>;
export type GetMsAccountQueryResult = Apollo.QueryResult<Types.GetMsAccountQuery, Types.GetMsAccountQueryVariables>;
export function refetchGetMsAccountQuery(variables?: Types.GetMsAccountQueryVariables) {
      return { query: GetMsAccountDocument, variables: variables }
    }
export const GetMsGroupsDocument = gql`
    query GetMSGroups($token: String!) {
  getMsGroups(filter: {accessToken: $token}) {
    id
    displayName
    description
  }
}
    `;

/**
 * __useGetMsGroupsQuery__
 *
 * To run a query within a React component, call `useGetMsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsGroupsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetMsGroupsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMsGroupsQuery, Types.GetMsGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMsGroupsQuery, Types.GetMsGroupsQueryVariables>(GetMsGroupsDocument, options);
      }
export function useGetMsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMsGroupsQuery, Types.GetMsGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMsGroupsQuery, Types.GetMsGroupsQueryVariables>(GetMsGroupsDocument, options);
        }
export type GetMsGroupsQueryHookResult = ReturnType<typeof useGetMsGroupsQuery>;
export type GetMsGroupsLazyQueryHookResult = ReturnType<typeof useGetMsGroupsLazyQuery>;
export type GetMsGroupsQueryResult = Apollo.QueryResult<Types.GetMsGroupsQuery, Types.GetMsGroupsQueryVariables>;
export function refetchGetMsGroupsQuery(variables?: Types.GetMsGroupsQueryVariables) {
      return { query: GetMsGroupsDocument, variables: variables }
    }
export const GetMsStudentsByCourseIdDocument = gql`
    query GetMSStudentsByCourseId($token: String!, $groupId: String!) {
  getMsMembers(filter: {accessToken: $token, groupId: $groupId}) {
    id
    givenName
    surname
    mail
  }
}
    `;

/**
 * __useGetMsStudentsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetMsStudentsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsStudentsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsStudentsByCourseIdQuery({
 *   variables: {
 *      token: // value for 'token'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetMsStudentsByCourseIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMsStudentsByCourseIdQuery, Types.GetMsStudentsByCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMsStudentsByCourseIdQuery, Types.GetMsStudentsByCourseIdQueryVariables>(GetMsStudentsByCourseIdDocument, options);
      }
export function useGetMsStudentsByCourseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMsStudentsByCourseIdQuery, Types.GetMsStudentsByCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMsStudentsByCourseIdQuery, Types.GetMsStudentsByCourseIdQueryVariables>(GetMsStudentsByCourseIdDocument, options);
        }
export type GetMsStudentsByCourseIdQueryHookResult = ReturnType<typeof useGetMsStudentsByCourseIdQuery>;
export type GetMsStudentsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetMsStudentsByCourseIdLazyQuery>;
export type GetMsStudentsByCourseIdQueryResult = Apollo.QueryResult<Types.GetMsStudentsByCourseIdQuery, Types.GetMsStudentsByCourseIdQueryVariables>;
export function refetchGetMsStudentsByCourseIdQuery(variables?: Types.GetMsStudentsByCourseIdQueryVariables) {
      return { query: GetMsStudentsByCourseIdDocument, variables: variables }
    }
export const GetCleverOAuthUrlDocument = gql`
    query GetCleverOAuthUrl($districtId: String!) {
  getCleverAuthUrl(filter: {districtId: $districtId})
}
    `;

/**
 * __useGetCleverOAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetCleverOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverOAuthUrlQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useGetCleverOAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCleverOAuthUrlQuery, Types.GetCleverOAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCleverOAuthUrlQuery, Types.GetCleverOAuthUrlQueryVariables>(GetCleverOAuthUrlDocument, options);
      }
export function useGetCleverOAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCleverOAuthUrlQuery, Types.GetCleverOAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCleverOAuthUrlQuery, Types.GetCleverOAuthUrlQueryVariables>(GetCleverOAuthUrlDocument, options);
        }
export type GetCleverOAuthUrlQueryHookResult = ReturnType<typeof useGetCleverOAuthUrlQuery>;
export type GetCleverOAuthUrlLazyQueryHookResult = ReturnType<typeof useGetCleverOAuthUrlLazyQuery>;
export type GetCleverOAuthUrlQueryResult = Apollo.QueryResult<Types.GetCleverOAuthUrlQuery, Types.GetCleverOAuthUrlQueryVariables>;
export function refetchGetCleverOAuthUrlQuery(variables?: Types.GetCleverOAuthUrlQueryVariables) {
      return { query: GetCleverOAuthUrlDocument, variables: variables }
    }
export const GetCleverTokenDocument = gql`
    query GetCleverToken($code: String!) {
  getCleverToken(filter: {code: $code})
}
    `;

/**
 * __useGetCleverTokenQuery__
 *
 * To run a query within a React component, call `useGetCleverTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverTokenQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCleverTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCleverTokenQuery, Types.GetCleverTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCleverTokenQuery, Types.GetCleverTokenQueryVariables>(GetCleverTokenDocument, options);
      }
export function useGetCleverTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCleverTokenQuery, Types.GetCleverTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCleverTokenQuery, Types.GetCleverTokenQueryVariables>(GetCleverTokenDocument, options);
        }
export type GetCleverTokenQueryHookResult = ReturnType<typeof useGetCleverTokenQuery>;
export type GetCleverTokenLazyQueryHookResult = ReturnType<typeof useGetCleverTokenLazyQuery>;
export type GetCleverTokenQueryResult = Apollo.QueryResult<Types.GetCleverTokenQuery, Types.GetCleverTokenQueryVariables>;
export function refetchGetCleverTokenQuery(variables?: Types.GetCleverTokenQueryVariables) {
      return { query: GetCleverTokenDocument, variables: variables }
    }
export const GetCleverAccountDocument = gql`
    query GetCleverAccount($token: String!) {
  getCleverAccount(filter: {accessToken: $token}) {
    id
    email
    isTeacher
  }
}
    `;

/**
 * __useGetCleverAccountQuery__
 *
 * To run a query within a React component, call `useGetCleverAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverAccountQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetCleverAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCleverAccountQuery, Types.GetCleverAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCleverAccountQuery, Types.GetCleverAccountQueryVariables>(GetCleverAccountDocument, options);
      }
export function useGetCleverAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCleverAccountQuery, Types.GetCleverAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCleverAccountQuery, Types.GetCleverAccountQueryVariables>(GetCleverAccountDocument, options);
        }
export type GetCleverAccountQueryHookResult = ReturnType<typeof useGetCleverAccountQuery>;
export type GetCleverAccountLazyQueryHookResult = ReturnType<typeof useGetCleverAccountLazyQuery>;
export type GetCleverAccountQueryResult = Apollo.QueryResult<Types.GetCleverAccountQuery, Types.GetCleverAccountQueryVariables>;
export function refetchGetCleverAccountQuery(variables?: Types.GetCleverAccountQueryVariables) {
      return { query: GetCleverAccountDocument, variables: variables }
    }
export const GetCleverGroupsDocument = gql`
    query GetCleverGroups($token: String!) {
  getCleverGroups(filter: {accessToken: $token}) {
    id
    name
    school
    students
  }
}
    `;

/**
 * __useGetCleverGroupsQuery__
 *
 * To run a query within a React component, call `useGetCleverGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverGroupsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetCleverGroupsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCleverGroupsQuery, Types.GetCleverGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCleverGroupsQuery, Types.GetCleverGroupsQueryVariables>(GetCleverGroupsDocument, options);
      }
export function useGetCleverGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCleverGroupsQuery, Types.GetCleverGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCleverGroupsQuery, Types.GetCleverGroupsQueryVariables>(GetCleverGroupsDocument, options);
        }
export type GetCleverGroupsQueryHookResult = ReturnType<typeof useGetCleverGroupsQuery>;
export type GetCleverGroupsLazyQueryHookResult = ReturnType<typeof useGetCleverGroupsLazyQuery>;
export type GetCleverGroupsQueryResult = Apollo.QueryResult<Types.GetCleverGroupsQuery, Types.GetCleverGroupsQueryVariables>;
export function refetchGetCleverGroupsQuery(variables?: Types.GetCleverGroupsQueryVariables) {
      return { query: GetCleverGroupsDocument, variables: variables }
    }
export const GetCleverStudentsByCourseIdDocument = gql`
    query GetCleverStudentsByCourseId($token: String!, $groupId: String!) {
  getCleverMembers(filter: {accessToken: $token, groupId: $groupId}) {
    id
    name {
      first
      last
    }
    email
  }
}
    `;

/**
 * __useGetCleverStudentsByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetCleverStudentsByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCleverStudentsByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCleverStudentsByCourseIdQuery({
 *   variables: {
 *      token: // value for 'token'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetCleverStudentsByCourseIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCleverStudentsByCourseIdQuery, Types.GetCleverStudentsByCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCleverStudentsByCourseIdQuery, Types.GetCleverStudentsByCourseIdQueryVariables>(GetCleverStudentsByCourseIdDocument, options);
      }
export function useGetCleverStudentsByCourseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCleverStudentsByCourseIdQuery, Types.GetCleverStudentsByCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCleverStudentsByCourseIdQuery, Types.GetCleverStudentsByCourseIdQueryVariables>(GetCleverStudentsByCourseIdDocument, options);
        }
export type GetCleverStudentsByCourseIdQueryHookResult = ReturnType<typeof useGetCleverStudentsByCourseIdQuery>;
export type GetCleverStudentsByCourseIdLazyQueryHookResult = ReturnType<typeof useGetCleverStudentsByCourseIdLazyQuery>;
export type GetCleverStudentsByCourseIdQueryResult = Apollo.QueryResult<Types.GetCleverStudentsByCourseIdQuery, Types.GetCleverStudentsByCourseIdQueryVariables>;
export function refetchGetCleverStudentsByCourseIdQuery(variables?: Types.GetCleverStudentsByCourseIdQueryVariables) {
      return { query: GetCleverStudentsByCourseIdDocument, variables: variables }
    }
export const GetStudentIntegrationDocument = gql`
    query GetStudentIntegration($studentId: Int!) {
  student_integration(where: {student_id: {_eq: $studentId}}) {
    integration_name
  }
}
    `;

/**
 * __useGetStudentIntegrationQuery__
 *
 * To run a query within a React component, call `useGetStudentIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentIntegrationQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useGetStudentIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentIntegrationQuery, Types.GetStudentIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentIntegrationQuery, Types.GetStudentIntegrationQueryVariables>(GetStudentIntegrationDocument, options);
      }
export function useGetStudentIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentIntegrationQuery, Types.GetStudentIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentIntegrationQuery, Types.GetStudentIntegrationQueryVariables>(GetStudentIntegrationDocument, options);
        }
export type GetStudentIntegrationQueryHookResult = ReturnType<typeof useGetStudentIntegrationQuery>;
export type GetStudentIntegrationLazyQueryHookResult = ReturnType<typeof useGetStudentIntegrationLazyQuery>;
export type GetStudentIntegrationQueryResult = Apollo.QueryResult<Types.GetStudentIntegrationQuery, Types.GetStudentIntegrationQueryVariables>;
export function refetchGetStudentIntegrationQuery(variables?: Types.GetStudentIntegrationQueryVariables) {
      return { query: GetStudentIntegrationDocument, variables: variables }
    }
export const GetClassLinkAuthUrlDocument = gql`
    query GetClassLinkAuthUrl {
  getClassLinkAuthUrl
}
    `;

/**
 * __useGetClassLinkAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetClassLinkAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassLinkAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassLinkAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassLinkAuthUrlQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetClassLinkAuthUrlQuery, Types.GetClassLinkAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassLinkAuthUrlQuery, Types.GetClassLinkAuthUrlQueryVariables>(GetClassLinkAuthUrlDocument, options);
      }
export function useGetClassLinkAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassLinkAuthUrlQuery, Types.GetClassLinkAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassLinkAuthUrlQuery, Types.GetClassLinkAuthUrlQueryVariables>(GetClassLinkAuthUrlDocument, options);
        }
export type GetClassLinkAuthUrlQueryHookResult = ReturnType<typeof useGetClassLinkAuthUrlQuery>;
export type GetClassLinkAuthUrlLazyQueryHookResult = ReturnType<typeof useGetClassLinkAuthUrlLazyQuery>;
export type GetClassLinkAuthUrlQueryResult = Apollo.QueryResult<Types.GetClassLinkAuthUrlQuery, Types.GetClassLinkAuthUrlQueryVariables>;
export function refetchGetClassLinkAuthUrlQuery(variables?: Types.GetClassLinkAuthUrlQueryVariables) {
      return { query: GetClassLinkAuthUrlDocument, variables: variables }
    }
export const GetClassLinkUserDocument = gql`
    query GetClassLinkUser($code: String!) {
  getClassLinkUser(filter: {code: $code}) {
    displayName
    email
    firstName
    lastName
    sourcedId
    isTeacher
    classes {
      id
      name
      totalStudents
    }
  }
}
    `;

/**
 * __useGetClassLinkUserQuery__
 *
 * To run a query within a React component, call `useGetClassLinkUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassLinkUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassLinkUserQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetClassLinkUserQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassLinkUserQuery, Types.GetClassLinkUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassLinkUserQuery, Types.GetClassLinkUserQueryVariables>(GetClassLinkUserDocument, options);
      }
export function useGetClassLinkUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassLinkUserQuery, Types.GetClassLinkUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassLinkUserQuery, Types.GetClassLinkUserQueryVariables>(GetClassLinkUserDocument, options);
        }
export type GetClassLinkUserQueryHookResult = ReturnType<typeof useGetClassLinkUserQuery>;
export type GetClassLinkUserLazyQueryHookResult = ReturnType<typeof useGetClassLinkUserLazyQuery>;
export type GetClassLinkUserQueryResult = Apollo.QueryResult<Types.GetClassLinkUserQuery, Types.GetClassLinkUserQueryVariables>;
export function refetchGetClassLinkUserQuery(variables?: Types.GetClassLinkUserQueryVariables) {
      return { query: GetClassLinkUserDocument, variables: variables }
    }
export const GetCanvasAuthUrlDocument = gql`
    query GetCanvasAuthUrl {
  getCanvasAuthUrl
}
    `;

/**
 * __useGetCanvasAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetCanvasAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanvasAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanvasAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCanvasAuthUrlQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCanvasAuthUrlQuery, Types.GetCanvasAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCanvasAuthUrlQuery, Types.GetCanvasAuthUrlQueryVariables>(GetCanvasAuthUrlDocument, options);
      }
export function useGetCanvasAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCanvasAuthUrlQuery, Types.GetCanvasAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCanvasAuthUrlQuery, Types.GetCanvasAuthUrlQueryVariables>(GetCanvasAuthUrlDocument, options);
        }
export type GetCanvasAuthUrlQueryHookResult = ReturnType<typeof useGetCanvasAuthUrlQuery>;
export type GetCanvasAuthUrlLazyQueryHookResult = ReturnType<typeof useGetCanvasAuthUrlLazyQuery>;
export type GetCanvasAuthUrlQueryResult = Apollo.QueryResult<Types.GetCanvasAuthUrlQuery, Types.GetCanvasAuthUrlQueryVariables>;
export function refetchGetCanvasAuthUrlQuery(variables?: Types.GetCanvasAuthUrlQueryVariables) {
      return { query: GetCanvasAuthUrlDocument, variables: variables }
    }
export const GetCanvasUserDocument = gql`
    query GetCanvasUser($code: String!) {
  getCanvasUser(filter: {code: $code}) {
    displayName
    email
    firstName
    lastName
    sourcedId
    isTeacher
    classes {
      id
      name
      totalStudents
    }
    access_token
  }
}
    `;

/**
 * __useGetCanvasUserQuery__
 *
 * To run a query within a React component, call `useGetCanvasUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanvasUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanvasUserQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCanvasUserQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCanvasUserQuery, Types.GetCanvasUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCanvasUserQuery, Types.GetCanvasUserQueryVariables>(GetCanvasUserDocument, options);
      }
export function useGetCanvasUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCanvasUserQuery, Types.GetCanvasUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCanvasUserQuery, Types.GetCanvasUserQueryVariables>(GetCanvasUserDocument, options);
        }
export type GetCanvasUserQueryHookResult = ReturnType<typeof useGetCanvasUserQuery>;
export type GetCanvasUserLazyQueryHookResult = ReturnType<typeof useGetCanvasUserLazyQuery>;
export type GetCanvasUserQueryResult = Apollo.QueryResult<Types.GetCanvasUserQuery, Types.GetCanvasUserQueryVariables>;
export function refetchGetCanvasUserQuery(variables?: Types.GetCanvasUserQueryVariables) {
      return { query: GetCanvasUserDocument, variables: variables }
    }
export const UpdateProductivityDocument = gql`
    mutation UpdateProductivity($day: Int!, $month: Int!, $year: Int!, $studentId: Int!, $classId: Int!, $practiceSessions: Int, $recordingSessions: Int, $videoViews: Int) {
  insert_student_productivity_daily(
    objects: {day: $day, month: $month, year: $year, student_id: $studentId, class_id: $classId, video_views: 0, practice_sessions: 0, recording_sessions: 0}
    on_conflict: {constraint: student_productivity_daily_student_id_class_id_day_month_year_k, update_columns: []}
  ) {
    affected_rows
  }
  update_student_productivity_daily(
    _inc: {practice_sessions: $practiceSessions, recording_sessions: $recordingSessions, video_views: $videoViews}
    where: {day: {_eq: $day}, month: {_eq: $month}, student_id: {_eq: $studentId}, year: {_eq: $year}, class_id: {_eq: $classId}}
  ) {
    returning {
      practice_sessions
      recording_sessions
      video_views
    }
  }
  insert_student_productivity_monthly(
    objects: {month: $month, year: $year, student_id: $studentId, class_id: $classId, video_views: 0, practice_sessions: 0, recording_sessions: 0}
    on_conflict: {constraint: student_productivity_monthly_class_id_student_id_month_year_key, update_columns: []}
  ) {
    affected_rows
  }
  update_student_productivity_monthly(
    _inc: {practice_sessions: $practiceSessions, recording_sessions: $recordingSessions, video_views: $videoViews}
    where: {month: {_eq: $month}, student_id: {_eq: $studentId}, year: {_eq: $year}, class_id: {_eq: $classId}}
  ) {
    returning {
      practice_sessions
      recording_sessions
      video_views
    }
  }
  insert_student_productivity_yearly(
    objects: {year: $year, student_id: $studentId, class_id: $classId, video_views: 0, practice_sessions: 0, recording_sessions: 0}
    on_conflict: {constraint: student_productivity_yearly_student_id_class_id_year_key, update_columns: []}
  ) {
    affected_rows
  }
  update_student_productivity_yearly(
    _inc: {practice_sessions: $practiceSessions, recording_sessions: $recordingSessions, video_views: $videoViews}
    where: {student_id: {_eq: $studentId}, year: {_eq: $year}, class_id: {_eq: $classId}}
  ) {
    returning {
      practice_sessions
      recording_sessions
      video_views
    }
  }
}
    `;
export type UpdateProductivityMutationFn = Apollo.MutationFunction<Types.UpdateProductivityMutation, Types.UpdateProductivityMutationVariables>;

/**
 * __useUpdateProductivityMutation__
 *
 * To run a mutation, you first call `useUpdateProductivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductivityMutation, { data, loading, error }] = useUpdateProductivityMutation({
 *   variables: {
 *      day: // value for 'day'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      studentId: // value for 'studentId'
 *      classId: // value for 'classId'
 *      practiceSessions: // value for 'practiceSessions'
 *      recordingSessions: // value for 'recordingSessions'
 *      videoViews: // value for 'videoViews'
 *   },
 * });
 */
export function useUpdateProductivityMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProductivityMutation, Types.UpdateProductivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProductivityMutation, Types.UpdateProductivityMutationVariables>(UpdateProductivityDocument, options);
      }
export type UpdateProductivityMutationHookResult = ReturnType<typeof useUpdateProductivityMutation>;
export type UpdateProductivityMutationResult = Apollo.MutationResult<Types.UpdateProductivityMutation>;
export type UpdateProductivityMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProductivityMutation, Types.UpdateProductivityMutationVariables>;
export const StudentProductivityMonthlyDocument = gql`
    query StudentProductivityMonthly($year: Int!, $month: Int!, $classId: Int!) {
  student_productivity_monthly(
    where: {year: {_eq: $year}, class_id: {_eq: $classId}, month: {_eq: $month}}
  ) {
    practice_sessions
    recording_sessions
    video_views
    student {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useStudentProductivityMonthlyQuery__
 *
 * To run a query within a React component, call `useStudentProductivityMonthlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentProductivityMonthlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentProductivityMonthlyQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useStudentProductivityMonthlyQuery(baseOptions: Apollo.QueryHookOptions<Types.StudentProductivityMonthlyQuery, Types.StudentProductivityMonthlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentProductivityMonthlyQuery, Types.StudentProductivityMonthlyQueryVariables>(StudentProductivityMonthlyDocument, options);
      }
export function useStudentProductivityMonthlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentProductivityMonthlyQuery, Types.StudentProductivityMonthlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentProductivityMonthlyQuery, Types.StudentProductivityMonthlyQueryVariables>(StudentProductivityMonthlyDocument, options);
        }
export type StudentProductivityMonthlyQueryHookResult = ReturnType<typeof useStudentProductivityMonthlyQuery>;
export type StudentProductivityMonthlyLazyQueryHookResult = ReturnType<typeof useStudentProductivityMonthlyLazyQuery>;
export type StudentProductivityMonthlyQueryResult = Apollo.QueryResult<Types.StudentProductivityMonthlyQuery, Types.StudentProductivityMonthlyQueryVariables>;
export function refetchStudentProductivityMonthlyQuery(variables?: Types.StudentProductivityMonthlyQueryVariables) {
      return { query: StudentProductivityMonthlyDocument, variables: variables }
    }
export const StudentProductivityDayRangeDocument = gql`
    query StudentProductivityDayRange($classId: Int!) {
  student_productivity_daily(where: {class_id: {_eq: $classId}}) {
    practice_sessions
    recording_sessions
    video_views
    student {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useStudentProductivityDayRangeQuery__
 *
 * To run a query within a React component, call `useStudentProductivityDayRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentProductivityDayRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentProductivityDayRangeQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useStudentProductivityDayRangeQuery(baseOptions: Apollo.QueryHookOptions<Types.StudentProductivityDayRangeQuery, Types.StudentProductivityDayRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentProductivityDayRangeQuery, Types.StudentProductivityDayRangeQueryVariables>(StudentProductivityDayRangeDocument, options);
      }
export function useStudentProductivityDayRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentProductivityDayRangeQuery, Types.StudentProductivityDayRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentProductivityDayRangeQuery, Types.StudentProductivityDayRangeQueryVariables>(StudentProductivityDayRangeDocument, options);
        }
export type StudentProductivityDayRangeQueryHookResult = ReturnType<typeof useStudentProductivityDayRangeQuery>;
export type StudentProductivityDayRangeLazyQueryHookResult = ReturnType<typeof useStudentProductivityDayRangeLazyQuery>;
export type StudentProductivityDayRangeQueryResult = Apollo.QueryResult<Types.StudentProductivityDayRangeQuery, Types.StudentProductivityDayRangeQueryVariables>;
export function refetchStudentProductivityDayRangeQuery(variables?: Types.StudentProductivityDayRangeQueryVariables) {
      return { query: StudentProductivityDayRangeDocument, variables: variables }
    }
export const StudentProductivityTimeRangeDocument = gql`
    query StudentProductivityTimeRange($where: student_productivity_daily_bool_exp) {
  student_productivity_daily(where: $where) {
    practice_sessions
    recording_sessions
    video_views
    student {
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useStudentProductivityTimeRangeQuery__
 *
 * To run a query within a React component, call `useStudentProductivityTimeRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentProductivityTimeRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentProductivityTimeRangeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentProductivityTimeRangeQuery(baseOptions?: Apollo.QueryHookOptions<Types.StudentProductivityTimeRangeQuery, Types.StudentProductivityTimeRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StudentProductivityTimeRangeQuery, Types.StudentProductivityTimeRangeQueryVariables>(StudentProductivityTimeRangeDocument, options);
      }
export function useStudentProductivityTimeRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StudentProductivityTimeRangeQuery, Types.StudentProductivityTimeRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StudentProductivityTimeRangeQuery, Types.StudentProductivityTimeRangeQueryVariables>(StudentProductivityTimeRangeDocument, options);
        }
export type StudentProductivityTimeRangeQueryHookResult = ReturnType<typeof useStudentProductivityTimeRangeQuery>;
export type StudentProductivityTimeRangeLazyQueryHookResult = ReturnType<typeof useStudentProductivityTimeRangeLazyQuery>;
export type StudentProductivityTimeRangeQueryResult = Apollo.QueryResult<Types.StudentProductivityTimeRangeQuery, Types.StudentProductivityTimeRangeQueryVariables>;
export function refetchStudentProductivityTimeRangeQuery(variables?: Types.StudentProductivityTimeRangeQueryVariables) {
      return { query: StudentProductivityTimeRangeDocument, variables: variables }
    }
export const UpdateSubmissionsDocument = gql`
    mutation UpdateSubmissions($submissions: [submission_insert_input!]!) {
  insert_submission(
    objects: $submissions
    on_conflict: {constraint: class_assignment_student_pkey, update_columns: [grade, teacher_comment, state]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateSubmissionsMutationFn = Apollo.MutationFunction<Types.UpdateSubmissionsMutation, Types.UpdateSubmissionsMutationVariables>;

/**
 * __useUpdateSubmissionsMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionsMutation, { data, loading, error }] = useUpdateSubmissionsMutation({
 *   variables: {
 *      submissions: // value for 'submissions'
 *   },
 * });
 */
export function useUpdateSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSubmissionsMutation, Types.UpdateSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSubmissionsMutation, Types.UpdateSubmissionsMutationVariables>(UpdateSubmissionsDocument, options);
      }
export type UpdateSubmissionsMutationHookResult = ReturnType<typeof useUpdateSubmissionsMutation>;
export type UpdateSubmissionsMutationResult = Apollo.MutationResult<Types.UpdateSubmissionsMutation>;
export type UpdateSubmissionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSubmissionsMutation, Types.UpdateSubmissionsMutationVariables>;
export const EditSubmissionDocument = gql`
    mutation EditSubmission($submissionId: Int!, $update: submission_set_input!) {
  update_submission_by_pk(
    pk_columns: {submission_id: $submissionId}
    _set: $update
  ) {
    submission_id
  }
}
    `;
export type EditSubmissionMutationFn = Apollo.MutationFunction<Types.EditSubmissionMutation, Types.EditSubmissionMutationVariables>;

/**
 * __useEditSubmissionMutation__
 *
 * To run a mutation, you first call `useEditSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSubmissionMutation, { data, loading, error }] = useEditSubmissionMutation({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useEditSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditSubmissionMutation, Types.EditSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditSubmissionMutation, Types.EditSubmissionMutationVariables>(EditSubmissionDocument, options);
      }
export type EditSubmissionMutationHookResult = ReturnType<typeof useEditSubmissionMutation>;
export type EditSubmissionMutationResult = Apollo.MutationResult<Types.EditSubmissionMutation>;
export type EditSubmissionMutationOptions = Apollo.BaseMutationOptions<Types.EditSubmissionMutation, Types.EditSubmissionMutationVariables>;
export const AddSubmissionAssetDocument = gql`
    mutation addSubmissionAsset($newAsset: submission_asset_insert_input!) {
  insert_submission_asset_one(object: $newAsset) {
    asset_id
    submission_id
    submission_asset_id
  }
}
    `;
export type AddSubmissionAssetMutationFn = Apollo.MutationFunction<Types.AddSubmissionAssetMutation, Types.AddSubmissionAssetMutationVariables>;

/**
 * __useAddSubmissionAssetMutation__
 *
 * To run a mutation, you first call `useAddSubmissionAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubmissionAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubmissionAssetMutation, { data, loading, error }] = useAddSubmissionAssetMutation({
 *   variables: {
 *      newAsset: // value for 'newAsset'
 *   },
 * });
 */
export function useAddSubmissionAssetMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSubmissionAssetMutation, Types.AddSubmissionAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSubmissionAssetMutation, Types.AddSubmissionAssetMutationVariables>(AddSubmissionAssetDocument, options);
      }
export type AddSubmissionAssetMutationHookResult = ReturnType<typeof useAddSubmissionAssetMutation>;
export type AddSubmissionAssetMutationResult = Apollo.MutationResult<Types.AddSubmissionAssetMutation>;
export type AddSubmissionAssetMutationOptions = Apollo.BaseMutationOptions<Types.AddSubmissionAssetMutation, Types.AddSubmissionAssetMutationVariables>;
export const DeleteSubmissionAssetsDocument = gql`
    mutation deleteSubmissionAssets($asset_id: Int!) {
  delete_asset_by_pk(asset_id: $asset_id) {
    asset_id
  }
}
    `;
export type DeleteSubmissionAssetsMutationFn = Apollo.MutationFunction<Types.DeleteSubmissionAssetsMutation, Types.DeleteSubmissionAssetsMutationVariables>;

/**
 * __useDeleteSubmissionAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteSubmissionAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubmissionAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubmissionAssetsMutation, { data, loading, error }] = useDeleteSubmissionAssetsMutation({
 *   variables: {
 *      asset_id: // value for 'asset_id'
 *   },
 * });
 */
export function useDeleteSubmissionAssetsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSubmissionAssetsMutation, Types.DeleteSubmissionAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSubmissionAssetsMutation, Types.DeleteSubmissionAssetsMutationVariables>(DeleteSubmissionAssetsDocument, options);
      }
export type DeleteSubmissionAssetsMutationHookResult = ReturnType<typeof useDeleteSubmissionAssetsMutation>;
export type DeleteSubmissionAssetsMutationResult = Apollo.MutationResult<Types.DeleteSubmissionAssetsMutation>;
export type DeleteSubmissionAssetsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSubmissionAssetsMutation, Types.DeleteSubmissionAssetsMutationVariables>;
export const GetStudentClassSubmissionDocument = gql`
    query GetStudentClassSubmission($studentId: Int!, $classId: Int, $offset: Int, $limit: Int, $orderBy: order_by = asc) {
  submission(
    where: {submission_students: {student_id: {_eq: $studentId}}, class_assignment: {class_id: {_eq: $classId}}}
    limit: $limit
    offset: $offset
    order_by: {class_assignment: {assignment: {name: $orderBy}}}
  ) {
    grade
    date_submitted
    class_assignment {
      assignment {
        name
        due_date
        due_time
      }
    }
  }
  submission_aggregate(
    where: {submission_students: {student_id: {_eq: $studentId}}, class_assignment: {class_id: {_eq: $classId}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetStudentClassSubmissionQuery__
 *
 * To run a query within a React component, call `useGetStudentClassSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentClassSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentClassSubmissionQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      classId: // value for 'classId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetStudentClassSubmissionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentClassSubmissionQuery, Types.GetStudentClassSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentClassSubmissionQuery, Types.GetStudentClassSubmissionQueryVariables>(GetStudentClassSubmissionDocument, options);
      }
export function useGetStudentClassSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentClassSubmissionQuery, Types.GetStudentClassSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentClassSubmissionQuery, Types.GetStudentClassSubmissionQueryVariables>(GetStudentClassSubmissionDocument, options);
        }
export type GetStudentClassSubmissionQueryHookResult = ReturnType<typeof useGetStudentClassSubmissionQuery>;
export type GetStudentClassSubmissionLazyQueryHookResult = ReturnType<typeof useGetStudentClassSubmissionLazyQuery>;
export type GetStudentClassSubmissionQueryResult = Apollo.QueryResult<Types.GetStudentClassSubmissionQuery, Types.GetStudentClassSubmissionQueryVariables>;
export function refetchGetStudentClassSubmissionQuery(variables?: Types.GetStudentClassSubmissionQueryVariables) {
      return { query: GetStudentClassSubmissionDocument, variables: variables }
    }
export const GetNotSubmittedSubmissionsCountDocument = gql`
    query GetNotSubmittedSubmissionsCount($classAssignmentId: Int!) {
  submission_aggregate(
    where: {class_assignment_id: {_eq: $classAssignmentId}, state: {_in: [in_progress, start]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetNotSubmittedSubmissionsCountQuery__
 *
 * To run a query within a React component, call `useGetNotSubmittedSubmissionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotSubmittedSubmissionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotSubmittedSubmissionsCountQuery({
 *   variables: {
 *      classAssignmentId: // value for 'classAssignmentId'
 *   },
 * });
 */
export function useGetNotSubmittedSubmissionsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNotSubmittedSubmissionsCountQuery, Types.GetNotSubmittedSubmissionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNotSubmittedSubmissionsCountQuery, Types.GetNotSubmittedSubmissionsCountQueryVariables>(GetNotSubmittedSubmissionsCountDocument, options);
      }
export function useGetNotSubmittedSubmissionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotSubmittedSubmissionsCountQuery, Types.GetNotSubmittedSubmissionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNotSubmittedSubmissionsCountQuery, Types.GetNotSubmittedSubmissionsCountQueryVariables>(GetNotSubmittedSubmissionsCountDocument, options);
        }
export type GetNotSubmittedSubmissionsCountQueryHookResult = ReturnType<typeof useGetNotSubmittedSubmissionsCountQuery>;
export type GetNotSubmittedSubmissionsCountLazyQueryHookResult = ReturnType<typeof useGetNotSubmittedSubmissionsCountLazyQuery>;
export type GetNotSubmittedSubmissionsCountQueryResult = Apollo.QueryResult<Types.GetNotSubmittedSubmissionsCountQuery, Types.GetNotSubmittedSubmissionsCountQueryVariables>;
export function refetchGetNotSubmittedSubmissionsCountQuery(variables?: Types.GetNotSubmittedSubmissionsCountQueryVariables) {
      return { query: GetNotSubmittedSubmissionsCountDocument, variables: variables }
    }
export const GetSubscriptionDocument = gql`
    query GetSubscription($teacherId: Int!) {
  getSubscription(filter: {teacherId: $teacherId}) {
    hasSubscription
    periodStart
    periodEnd
    price
    billingMethod
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>;
export function refetchGetSubscriptionQuery(variables?: Types.GetSubscriptionQueryVariables) {
      return { query: GetSubscriptionDocument, variables: variables }
    }
export const UpdateTeacherAvatarDocument = gql`
    mutation UpdateTeacherAvatar($imagePath: String, $teacherId: Int!) {
  update_teacher_by_pk(
    pk_columns: {teacher_id: $teacherId}
    _set: {image_path: $imagePath}
  ) {
    teacher_id
  }
}
    `;
export type UpdateTeacherAvatarMutationFn = Apollo.MutationFunction<Types.UpdateTeacherAvatarMutation, Types.UpdateTeacherAvatarMutationVariables>;

/**
 * __useUpdateTeacherAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherAvatarMutation, { data, loading, error }] = useUpdateTeacherAvatarMutation({
 *   variables: {
 *      imagePath: // value for 'imagePath'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useUpdateTeacherAvatarMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeacherAvatarMutation, Types.UpdateTeacherAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeacherAvatarMutation, Types.UpdateTeacherAvatarMutationVariables>(UpdateTeacherAvatarDocument, options);
      }
export type UpdateTeacherAvatarMutationHookResult = ReturnType<typeof useUpdateTeacherAvatarMutation>;
export type UpdateTeacherAvatarMutationResult = Apollo.MutationResult<Types.UpdateTeacherAvatarMutation>;
export type UpdateTeacherAvatarMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeacherAvatarMutation, Types.UpdateTeacherAvatarMutationVariables>;
export const GetTeacherDocument = gql`
    query GetTeacher($teacherId: Int!) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    is_enterprise
    is_school_enterprise
    is_district_enterprise
    name_first
    name_last
    role_name
    full_name
    organization_name
    image_path
    email
    is_active
    school {
      school_id
      name
      nces_id
      district {
        district_id
        name
      }
    }
    teacher_setting {
      sequence_lesson_dont_ask_again_add_to_class
      favorites_dont_ask_again_to_delete
      teacher_id
      teacher_setting_id
    }
    teacher_integrations(order_by: {teacher_integration_id: desc}) {
      integration_name
      is_sso
    }
  }
}
    `;

/**
 * __useGetTeacherQuery__
 *
 * To run a query within a React component, call `useGetTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherQuery, Types.GetTeacherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherQuery, Types.GetTeacherQueryVariables>(GetTeacherDocument, options);
      }
export function useGetTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherQuery, Types.GetTeacherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherQuery, Types.GetTeacherQueryVariables>(GetTeacherDocument, options);
        }
export type GetTeacherQueryHookResult = ReturnType<typeof useGetTeacherQuery>;
export type GetTeacherLazyQueryHookResult = ReturnType<typeof useGetTeacherLazyQuery>;
export type GetTeacherQueryResult = Apollo.QueryResult<Types.GetTeacherQuery, Types.GetTeacherQueryVariables>;
export function refetchGetTeacherQuery(variables?: Types.GetTeacherQueryVariables) {
      return { query: GetTeacherDocument, variables: variables }
    }
export const GetTeacherFavLessonPlansDocument = gql`
    query GetTeacherFavLessonPlans($teacherId: Int!, $notInClassId: Int = -1, $limit: Int, $orderTitle: order_by, $orderDate: order_by) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    is_enterprise
    teacher_lesson_plan_favorites(
      where: {_and: [{_not: {lesson_plan: {class_lesson_plans: {class_id: {_eq: $notInClassId}}}}}, {_not: {lesson_plan: {module_lesson_plans: {module: {class_modules: {class_id: {_eq: $notInClassId}}}}}}}, {_not: {lesson_plan: {sequence_lesson_plan: {sequence: {class_sequences: {class_id: {_eq: $notInClassId}}}}}}}, {lesson_plan: {active: {_eq: true}}}, {lesson_plan: {lesson_plan_id: {_is_null: false}}}]}
      limit: $limit
      order_by: {lesson_plan: {title: $orderTitle, created_at: $orderDate}}
    ) {
      teacher_lesson_plan_favorite_id
      lesson_plan {
        is_favorite(args: {teacherid: $teacherId})
        ...LessonPlanExtendedFragment
      }
    }
    teacher_lesson_plan_favorites_aggregate(
      where: {_and: [{_not: {lesson_plan: {class_lesson_plans: {class_id: {_eq: $notInClassId}}}}}, {_not: {lesson_plan: {module_lesson_plans: {module: {class_modules: {class_id: {_eq: $notInClassId}}}}}}}, {_not: {lesson_plan: {sequence_lesson_plan: {sequence: {class_sequences: {class_id: {_eq: $notInClassId}}}}}}}, {lesson_plan: {active: {_eq: true}}}, {lesson_plan: {lesson_plan_id: {_is_null: false}}}]}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${LessonPlanExtendedFragmentFragmentDoc}`;

/**
 * __useGetTeacherFavLessonPlansQuery__
 *
 * To run a query within a React component, call `useGetTeacherFavLessonPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherFavLessonPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherFavLessonPlansQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      notInClassId: // value for 'notInClassId'
 *      limit: // value for 'limit'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetTeacherFavLessonPlansQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherFavLessonPlansQuery, Types.GetTeacherFavLessonPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherFavLessonPlansQuery, Types.GetTeacherFavLessonPlansQueryVariables>(GetTeacherFavLessonPlansDocument, options);
      }
export function useGetTeacherFavLessonPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherFavLessonPlansQuery, Types.GetTeacherFavLessonPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherFavLessonPlansQuery, Types.GetTeacherFavLessonPlansQueryVariables>(GetTeacherFavLessonPlansDocument, options);
        }
export type GetTeacherFavLessonPlansQueryHookResult = ReturnType<typeof useGetTeacherFavLessonPlansQuery>;
export type GetTeacherFavLessonPlansLazyQueryHookResult = ReturnType<typeof useGetTeacherFavLessonPlansLazyQuery>;
export type GetTeacherFavLessonPlansQueryResult = Apollo.QueryResult<Types.GetTeacherFavLessonPlansQuery, Types.GetTeacherFavLessonPlansQueryVariables>;
export function refetchGetTeacherFavLessonPlansQuery(variables?: Types.GetTeacherFavLessonPlansQueryVariables) {
      return { query: GetTeacherFavLessonPlansDocument, variables: variables }
    }
export const GetTeacherStudentsByKeywordDocument = gql`
    query GetTeacherStudentsByKeyword($offset: Int, $limit: Int, $conditions: [student_bool_exp!] = [{first_name: {_like: "%%"}}], $teacherId: Int!, $order: order_by = asc) {
  student(
    limit: $limit
    offset: $offset
    order_by: {first_name: $order}
    where: {_and: [{class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}}, {_or: $conditions}]}
  ) {
    student_id
    clever_id
    first_name
    last_name
    email
    image_path
    is_active
    has_lti_integration
    code
    class_students {
      class {
        title
        class_id
      }
    }
  }
  student_aggregate(
    where: {_and: [{class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}}, {_or: $conditions}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTeacherStudentsByKeywordQuery__
 *
 * To run a query within a React component, call `useGetTeacherStudentsByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherStudentsByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherStudentsByKeywordQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      conditions: // value for 'conditions'
 *      teacherId: // value for 'teacherId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetTeacherStudentsByKeywordQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherStudentsByKeywordQuery, Types.GetTeacherStudentsByKeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherStudentsByKeywordQuery, Types.GetTeacherStudentsByKeywordQueryVariables>(GetTeacherStudentsByKeywordDocument, options);
      }
export function useGetTeacherStudentsByKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherStudentsByKeywordQuery, Types.GetTeacherStudentsByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherStudentsByKeywordQuery, Types.GetTeacherStudentsByKeywordQueryVariables>(GetTeacherStudentsByKeywordDocument, options);
        }
export type GetTeacherStudentsByKeywordQueryHookResult = ReturnType<typeof useGetTeacherStudentsByKeywordQuery>;
export type GetTeacherStudentsByKeywordLazyQueryHookResult = ReturnType<typeof useGetTeacherStudentsByKeywordLazyQuery>;
export type GetTeacherStudentsByKeywordQueryResult = Apollo.QueryResult<Types.GetTeacherStudentsByKeywordQuery, Types.GetTeacherStudentsByKeywordQueryVariables>;
export function refetchGetTeacherStudentsByKeywordQuery(variables?: Types.GetTeacherStudentsByKeywordQueryVariables) {
      return { query: GetTeacherStudentsByKeywordDocument, variables: variables }
    }
export const GetFavoriteVideosNotInClassDocument = gql`
    query GetFavoriteVideosNotInClass($teacherId: Int!, $classId: Int!, $limit: Int) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    teacher_tip_favorites(
      where: {_and: [{_not: {tip: {class_tips: {class_id: {_eq: $classId}}}}}, {tip: {tip_id: {_is_null: false}}}, {tip: {active: {_eq: true}}}, {_not: {tip: {tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}}}]}
      order_by: {tip: {title: asc}}
      limit: $limit
    ) {
      teacher_tip_favorite_id
      tip {
        ...tipExtendedFragment
        is_favorite(args: {teacherid: $teacherId})
      }
    }
    teacher_tip_favorites_aggregate(
      where: {_and: [{_not: {tip: {class_tips: {class_id: {_eq: $classId}}}}}, {tip: {tip_id: {_is_null: false}}}, {tip: {active: {_eq: true}}}, {_not: {tip: {tip_playlists: {playlist: {class_playlists: {class_id: {_eq: $classId}}}}}}}]}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetFavoriteVideosNotInClassQuery__
 *
 * To run a query within a React component, call `useGetFavoriteVideosNotInClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteVideosNotInClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteVideosNotInClassQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFavoriteVideosNotInClassQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteVideosNotInClassQuery, Types.GetFavoriteVideosNotInClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteVideosNotInClassQuery, Types.GetFavoriteVideosNotInClassQueryVariables>(GetFavoriteVideosNotInClassDocument, options);
      }
export function useGetFavoriteVideosNotInClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteVideosNotInClassQuery, Types.GetFavoriteVideosNotInClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteVideosNotInClassQuery, Types.GetFavoriteVideosNotInClassQueryVariables>(GetFavoriteVideosNotInClassDocument, options);
        }
export type GetFavoriteVideosNotInClassQueryHookResult = ReturnType<typeof useGetFavoriteVideosNotInClassQuery>;
export type GetFavoriteVideosNotInClassLazyQueryHookResult = ReturnType<typeof useGetFavoriteVideosNotInClassLazyQuery>;
export type GetFavoriteVideosNotInClassQueryResult = Apollo.QueryResult<Types.GetFavoriteVideosNotInClassQuery, Types.GetFavoriteVideosNotInClassQueryVariables>;
export function refetchGetFavoriteVideosNotInClassQuery(variables?: Types.GetFavoriteVideosNotInClassQueryVariables) {
      return { query: GetFavoriteVideosNotInClassDocument, variables: variables }
    }
export const GetFavoriteSongsNotInClassByTeacherDocument = gql`
    query GetFavoriteSongsNotInClassByTeacher($teacherId: Int!, $classId: Int!, $limit: Int) {
  teacher_by_pk(teacher_id: $teacherId) {
    teacher_id
    teacher_song_favorites(
      limit: $limit
      order_by: {song: {title: asc}}
      where: {_not: {song: {class_songs: {class_id: {_eq: $classId}}}}, song: {song_id: {_is_null: false}}}
    ) {
      teacher_song_favorite_id
      song {
        ...songReducedFragment
        is_favorite(args: {teacherid: $teacherId})
      }
    }
    teacher_song_favorites_aggregate(
      where: {_not: {song: {class_songs: {class_id: {_eq: $classId}}}}, song: {song_id: {_is_null: false}}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    ${SongReducedFragmentFragmentDoc}`;

/**
 * __useGetFavoriteSongsNotInClassByTeacherQuery__
 *
 * To run a query within a React component, call `useGetFavoriteSongsNotInClassByTeacherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteSongsNotInClassByTeacherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteSongsNotInClassByTeacherQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFavoriteSongsNotInClassByTeacherQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteSongsNotInClassByTeacherQuery, Types.GetFavoriteSongsNotInClassByTeacherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteSongsNotInClassByTeacherQuery, Types.GetFavoriteSongsNotInClassByTeacherQueryVariables>(GetFavoriteSongsNotInClassByTeacherDocument, options);
      }
export function useGetFavoriteSongsNotInClassByTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteSongsNotInClassByTeacherQuery, Types.GetFavoriteSongsNotInClassByTeacherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteSongsNotInClassByTeacherQuery, Types.GetFavoriteSongsNotInClassByTeacherQueryVariables>(GetFavoriteSongsNotInClassByTeacherDocument, options);
        }
export type GetFavoriteSongsNotInClassByTeacherQueryHookResult = ReturnType<typeof useGetFavoriteSongsNotInClassByTeacherQuery>;
export type GetFavoriteSongsNotInClassByTeacherLazyQueryHookResult = ReturnType<typeof useGetFavoriteSongsNotInClassByTeacherLazyQuery>;
export type GetFavoriteSongsNotInClassByTeacherQueryResult = Apollo.QueryResult<Types.GetFavoriteSongsNotInClassByTeacherQuery, Types.GetFavoriteSongsNotInClassByTeacherQueryVariables>;
export function refetchGetFavoriteSongsNotInClassByTeacherQuery(variables?: Types.GetFavoriteSongsNotInClassByTeacherQueryVariables) {
      return { query: GetFavoriteSongsNotInClassByTeacherDocument, variables: variables }
    }
export const GetTeacherByUsernameAndPasswordDocument = gql`
    query GetTeacherByUsernameAndPassword($email: String!, $password: String!) {
  teacher(where: {email: {_eq: $email}, password: {_eq: $password}}) {
    teacher_id
    name_first
    name_last
    is_enterprise
    role_name
    teacher_setting {
      favorites_dont_ask_again_to_delete
      teacher_id
      teacher_setting_id
    }
  }
}
    `;

/**
 * __useGetTeacherByUsernameAndPasswordQuery__
 *
 * To run a query within a React component, call `useGetTeacherByUsernameAndPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherByUsernameAndPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherByUsernameAndPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetTeacherByUsernameAndPasswordQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherByUsernameAndPasswordQuery, Types.GetTeacherByUsernameAndPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherByUsernameAndPasswordQuery, Types.GetTeacherByUsernameAndPasswordQueryVariables>(GetTeacherByUsernameAndPasswordDocument, options);
      }
export function useGetTeacherByUsernameAndPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherByUsernameAndPasswordQuery, Types.GetTeacherByUsernameAndPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherByUsernameAndPasswordQuery, Types.GetTeacherByUsernameAndPasswordQueryVariables>(GetTeacherByUsernameAndPasswordDocument, options);
        }
export type GetTeacherByUsernameAndPasswordQueryHookResult = ReturnType<typeof useGetTeacherByUsernameAndPasswordQuery>;
export type GetTeacherByUsernameAndPasswordLazyQueryHookResult = ReturnType<typeof useGetTeacherByUsernameAndPasswordLazyQuery>;
export type GetTeacherByUsernameAndPasswordQueryResult = Apollo.QueryResult<Types.GetTeacherByUsernameAndPasswordQuery, Types.GetTeacherByUsernameAndPasswordQueryVariables>;
export function refetchGetTeacherByUsernameAndPasswordQuery(variables?: Types.GetTeacherByUsernameAndPasswordQueryVariables) {
      return { query: GetTeacherByUsernameAndPasswordDocument, variables: variables }
    }
export const GetPresignedUrlDocument = gql`
    query GetPresignedUrl($filter: PresignedUrlFilter) {
  presignedUrl(filter: $filter) {
    key
    url
  }
}
    `;

/**
 * __useGetPresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPresignedUrlQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
      }
export function useGetPresignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>(GetPresignedUrlDocument, options);
        }
export type GetPresignedUrlQueryHookResult = ReturnType<typeof useGetPresignedUrlQuery>;
export type GetPresignedUrlLazyQueryHookResult = ReturnType<typeof useGetPresignedUrlLazyQuery>;
export type GetPresignedUrlQueryResult = Apollo.QueryResult<Types.GetPresignedUrlQuery, Types.GetPresignedUrlQueryVariables>;
export function refetchGetPresignedUrlQuery(variables?: Types.GetPresignedUrlQueryVariables) {
      return { query: GetPresignedUrlDocument, variables: variables }
    }
export const InsertTeacherIntegrationDocument = gql`
    mutation InsertTeacherIntegration($teacher: teacher_integration_insert_input!) {
  insert_teacher_integration_one(object: $teacher) {
    teacher_integration_id
    email
    integration_name
    teacher_id
    integration_id
  }
}
    `;
export type InsertTeacherIntegrationMutationFn = Apollo.MutationFunction<Types.InsertTeacherIntegrationMutation, Types.InsertTeacherIntegrationMutationVariables>;

/**
 * __useInsertTeacherIntegrationMutation__
 *
 * To run a mutation, you first call `useInsertTeacherIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeacherIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeacherIntegrationMutation, { data, loading, error }] = useInsertTeacherIntegrationMutation({
 *   variables: {
 *      teacher: // value for 'teacher'
 *   },
 * });
 */
export function useInsertTeacherIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTeacherIntegrationMutation, Types.InsertTeacherIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTeacherIntegrationMutation, Types.InsertTeacherIntegrationMutationVariables>(InsertTeacherIntegrationDocument, options);
      }
export type InsertTeacherIntegrationMutationHookResult = ReturnType<typeof useInsertTeacherIntegrationMutation>;
export type InsertTeacherIntegrationMutationResult = Apollo.MutationResult<Types.InsertTeacherIntegrationMutation>;
export type InsertTeacherIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.InsertTeacherIntegrationMutation, Types.InsertTeacherIntegrationMutationVariables>;
export const GetTeacherIntegrationDocument = gql`
    query GetTeacherIntegration($teacherId: Int!, $integrationName: integration_name_enum, $teacherEmail: String!) {
  teacher_integration(
    where: {teacher_id: {_eq: $teacherId}, integration_name: {_eq: $integrationName}, email: {_eq: $teacherEmail}}
  ) {
    teacher_integration_id
    email
    teacher_id
  }
}
    `;

/**
 * __useGetTeacherIntegrationQuery__
 *
 * To run a query within a React component, call `useGetTeacherIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherIntegrationQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      integrationName: // value for 'integrationName'
 *      teacherEmail: // value for 'teacherEmail'
 *   },
 * });
 */
export function useGetTeacherIntegrationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherIntegrationQuery, Types.GetTeacherIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherIntegrationQuery, Types.GetTeacherIntegrationQueryVariables>(GetTeacherIntegrationDocument, options);
      }
export function useGetTeacherIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherIntegrationQuery, Types.GetTeacherIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherIntegrationQuery, Types.GetTeacherIntegrationQueryVariables>(GetTeacherIntegrationDocument, options);
        }
export type GetTeacherIntegrationQueryHookResult = ReturnType<typeof useGetTeacherIntegrationQuery>;
export type GetTeacherIntegrationLazyQueryHookResult = ReturnType<typeof useGetTeacherIntegrationLazyQuery>;
export type GetTeacherIntegrationQueryResult = Apollo.QueryResult<Types.GetTeacherIntegrationQuery, Types.GetTeacherIntegrationQueryVariables>;
export function refetchGetTeacherIntegrationQuery(variables?: Types.GetTeacherIntegrationQueryVariables) {
      return { query: GetTeacherIntegrationDocument, variables: variables }
    }
export const InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesDocument = gql`
    mutation InsertTeacherSettingDontAskAgainDeleteLessonFromFavorites($teacherId: Int!, $dontAsk: Boolean!) {
  insert_teacher_setting(
    objects: {favorites_dont_ask_again_to_delete: $dontAsk, teacher_id: $teacherId}
  ) {
    returning {
      ...TeacherFavoritesDontAskAgainFragment
    }
  }
}
    ${TeacherFavoritesDontAskAgainFragmentFragmentDoc}`;
export type InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationFn = Apollo.MutationFunction<Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>;

/**
 * __useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation__
 *
 * To run a mutation, you first call `useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, { data, loading, error }] = useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      dontAsk: // value for 'dontAsk'
 *   },
 * });
 */
export function useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>(InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesDocument, options);
      }
export type InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationHookResult = ReturnType<typeof useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation>;
export type InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationResult = Apollo.MutationResult<Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation>;
export type InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationOptions = Apollo.BaseMutationOptions<Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.InsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>;
export const UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesDocument = gql`
    mutation UpdateTeacherSettingDontAskAgainDeleteLessonFromFavorites($teacherId: Int!, $dontAsk: Boolean!) {
  update_teacher_setting(
    where: {teacher_id: {_eq: $teacherId}}
    _set: {favorites_dont_ask_again_to_delete: $dontAsk}
  ) {
    returning {
      ...TeacherFavoritesDontAskAgainFragment
    }
  }
}
    ${TeacherFavoritesDontAskAgainFragmentFragmentDoc}`;
export type UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationFn = Apollo.MutationFunction<Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>;

/**
 * __useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, { data, loading, error }] = useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      dontAsk: // value for 'dontAsk'
 *   },
 * });
 */
export function useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>(UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesDocument, options);
      }
export type UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationHookResult = ReturnType<typeof useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation>;
export type UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationResult = Apollo.MutationResult<Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation>;
export type UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation, Types.UpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutationVariables>;
export const SetUpdateSequenceLessonDontAskAgainDocument = gql`
    mutation SetUpdateSequenceLessonDontAskAgain($teacherId: Int!, $dontAsk: Boolean!) {
  update_teacher_setting(
    where: {teacher_id: {_eq: $teacherId}}
    _set: {sequence_lesson_dont_ask_again_add_to_class: $dontAsk}
  ) {
    returning {
      ...TeacherFavoritesDontAskAgainFragment
    }
  }
}
    ${TeacherFavoritesDontAskAgainFragmentFragmentDoc}`;
export type SetUpdateSequenceLessonDontAskAgainMutationFn = Apollo.MutationFunction<Types.SetUpdateSequenceLessonDontAskAgainMutation, Types.SetUpdateSequenceLessonDontAskAgainMutationVariables>;

/**
 * __useSetUpdateSequenceLessonDontAskAgainMutation__
 *
 * To run a mutation, you first call `useSetUpdateSequenceLessonDontAskAgainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUpdateSequenceLessonDontAskAgainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUpdateSequenceLessonDontAskAgainMutation, { data, loading, error }] = useSetUpdateSequenceLessonDontAskAgainMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      dontAsk: // value for 'dontAsk'
 *   },
 * });
 */
export function useSetUpdateSequenceLessonDontAskAgainMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetUpdateSequenceLessonDontAskAgainMutation, Types.SetUpdateSequenceLessonDontAskAgainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetUpdateSequenceLessonDontAskAgainMutation, Types.SetUpdateSequenceLessonDontAskAgainMutationVariables>(SetUpdateSequenceLessonDontAskAgainDocument, options);
      }
export type SetUpdateSequenceLessonDontAskAgainMutationHookResult = ReturnType<typeof useSetUpdateSequenceLessonDontAskAgainMutation>;
export type SetUpdateSequenceLessonDontAskAgainMutationResult = Apollo.MutationResult<Types.SetUpdateSequenceLessonDontAskAgainMutation>;
export type SetUpdateSequenceLessonDontAskAgainMutationOptions = Apollo.BaseMutationOptions<Types.SetUpdateSequenceLessonDontAskAgainMutation, Types.SetUpdateSequenceLessonDontAskAgainMutationVariables>;
export const UpdateTeacherSettingDontAskAgainRecordDocument = gql`
    mutation UpdateTeacherSettingDontAskAgainRecord($teacherId: Int!) {
  update_teacher_setting(
    where: {teacher_id: {_eq: $teacherId}}
    _set: {dont_ask_again_record: true}
  ) {
    returning {
      ...TeacherFavoritesDontAskAgainFragment
    }
  }
}
    ${TeacherFavoritesDontAskAgainFragmentFragmentDoc}`;
export type UpdateTeacherSettingDontAskAgainRecordMutationFn = Apollo.MutationFunction<Types.UpdateTeacherSettingDontAskAgainRecordMutation, Types.UpdateTeacherSettingDontAskAgainRecordMutationVariables>;

/**
 * __useUpdateTeacherSettingDontAskAgainRecordMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherSettingDontAskAgainRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherSettingDontAskAgainRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherSettingDontAskAgainRecordMutation, { data, loading, error }] = useUpdateTeacherSettingDontAskAgainRecordMutation({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useUpdateTeacherSettingDontAskAgainRecordMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTeacherSettingDontAskAgainRecordMutation, Types.UpdateTeacherSettingDontAskAgainRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTeacherSettingDontAskAgainRecordMutation, Types.UpdateTeacherSettingDontAskAgainRecordMutationVariables>(UpdateTeacherSettingDontAskAgainRecordDocument, options);
      }
export type UpdateTeacherSettingDontAskAgainRecordMutationHookResult = ReturnType<typeof useUpdateTeacherSettingDontAskAgainRecordMutation>;
export type UpdateTeacherSettingDontAskAgainRecordMutationResult = Apollo.MutationResult<Types.UpdateTeacherSettingDontAskAgainRecordMutation>;
export type UpdateTeacherSettingDontAskAgainRecordMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTeacherSettingDontAskAgainRecordMutation, Types.UpdateTeacherSettingDontAskAgainRecordMutationVariables>;
export const GetTeacherSettingByPkDocument = gql`
    query GetTeacherSettingByPk($teacherId: Int!) {
  teacher_setting(where: {teacher_id: {_eq: $teacherId}}) {
    ...TeacherFavoritesDontAskAgainFragment
  }
}
    ${TeacherFavoritesDontAskAgainFragmentFragmentDoc}`;

/**
 * __useGetTeacherSettingByPkQuery__
 *
 * To run a query within a React component, call `useGetTeacherSettingByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherSettingByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherSettingByPkQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherSettingByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherSettingByPkQuery, Types.GetTeacherSettingByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherSettingByPkQuery, Types.GetTeacherSettingByPkQueryVariables>(GetTeacherSettingByPkDocument, options);
      }
export function useGetTeacherSettingByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherSettingByPkQuery, Types.GetTeacherSettingByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherSettingByPkQuery, Types.GetTeacherSettingByPkQueryVariables>(GetTeacherSettingByPkDocument, options);
        }
export type GetTeacherSettingByPkQueryHookResult = ReturnType<typeof useGetTeacherSettingByPkQuery>;
export type GetTeacherSettingByPkLazyQueryHookResult = ReturnType<typeof useGetTeacherSettingByPkLazyQuery>;
export type GetTeacherSettingByPkQueryResult = Apollo.QueryResult<Types.GetTeacherSettingByPkQuery, Types.GetTeacherSettingByPkQueryVariables>;
export function refetchGetTeacherSettingByPkQuery(variables?: Types.GetTeacherSettingByPkQueryVariables) {
      return { query: GetTeacherSettingByPkDocument, variables: variables }
    }
export const GetTeacherLicenseDocument = gql`
    query GetTeacherLicense($teacherId: Int!) {
  teacher_subscription(
    where: {cancelled_at: {_is_null: true}, teacher_id: {_eq: $teacherId}}
  ) {
    subscription_id
    membership_type {
      billing_method
      price
    }
  }
}
    `;

/**
 * __useGetTeacherLicenseQuery__
 *
 * To run a query within a React component, call `useGetTeacherLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeacherLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeacherLicenseQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetTeacherLicenseQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTeacherLicenseQuery, Types.GetTeacherLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTeacherLicenseQuery, Types.GetTeacherLicenseQueryVariables>(GetTeacherLicenseDocument, options);
      }
export function useGetTeacherLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTeacherLicenseQuery, Types.GetTeacherLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTeacherLicenseQuery, Types.GetTeacherLicenseQueryVariables>(GetTeacherLicenseDocument, options);
        }
export type GetTeacherLicenseQueryHookResult = ReturnType<typeof useGetTeacherLicenseQuery>;
export type GetTeacherLicenseLazyQueryHookResult = ReturnType<typeof useGetTeacherLicenseLazyQuery>;
export type GetTeacherLicenseQueryResult = Apollo.QueryResult<Types.GetTeacherLicenseQuery, Types.GetTeacherLicenseQueryVariables>;
export function refetchGetTeacherLicenseQuery(variables?: Types.GetTeacherLicenseQueryVariables) {
      return { query: GetTeacherLicenseDocument, variables: variables }
    }
export const TestDocument = gql`
    query Test {
  song {
    song_id
  }
}
    `;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQuery(baseOptions?: Apollo.QueryHookOptions<Types.TestQuery, Types.TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestQuery, Types.TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestQuery, Types.TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestQuery, Types.TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestQueryResult = Apollo.QueryResult<Types.TestQuery, Types.TestQueryVariables>;
export function refetchTestQuery(variables?: Types.TestQueryVariables) {
      return { query: TestDocument, variables: variables }
    }
export const EditVideoDocument = gql`
    mutation EditVideo($tipId: Int, $data: tip_set_input!, $tip_keywords: [tip_keyword_insert_input!]!, $tip_catalog_item: [tip_catalog_item_insert_input!]!) {
  update_tip(where: {tip_id: {_eq: $tipId}}, _set: $data) {
    affected_rows
    returning {
      tip_id
    }
  }
  delete_tip_keyword(where: {tip_id: {_eq: $tipId}}) {
    affected_rows
  }
  delete_tip_catalog_item(where: {tip_id: {_eq: $tipId}}) {
    affected_rows
  }
  insert_tip_keyword(objects: $tip_keywords) {
    affected_rows
  }
  insert_tip_catalog_item(objects: $tip_catalog_item) {
    affected_rows
  }
}
    `;
export type EditVideoMutationFn = Apollo.MutationFunction<Types.EditVideoMutation, Types.EditVideoMutationVariables>;

/**
 * __useEditVideoMutation__
 *
 * To run a mutation, you first call `useEditVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVideoMutation, { data, loading, error }] = useEditVideoMutation({
 *   variables: {
 *      tipId: // value for 'tipId'
 *      data: // value for 'data'
 *      tip_keywords: // value for 'tip_keywords'
 *      tip_catalog_item: // value for 'tip_catalog_item'
 *   },
 * });
 */
export function useEditVideoMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditVideoMutation, Types.EditVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditVideoMutation, Types.EditVideoMutationVariables>(EditVideoDocument, options);
      }
export type EditVideoMutationHookResult = ReturnType<typeof useEditVideoMutation>;
export type EditVideoMutationResult = Apollo.MutationResult<Types.EditVideoMutation>;
export type EditVideoMutationOptions = Apollo.BaseMutationOptions<Types.EditVideoMutation, Types.EditVideoMutationVariables>;
export const DeleteVideoDocument = gql`
    mutation DeleteVideo($tipId: Int) {
  delete_tip(where: {tip_id: {_eq: $tipId}}) {
    affected_rows
  }
}
    `;
export type DeleteVideoMutationFn = Apollo.MutationFunction<Types.DeleteVideoMutation, Types.DeleteVideoMutationVariables>;

/**
 * __useDeleteVideoMutation__
 *
 * To run a mutation, you first call `useDeleteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoMutation, { data, loading, error }] = useDeleteVideoMutation({
 *   variables: {
 *      tipId: // value for 'tipId'
 *   },
 * });
 */
export function useDeleteVideoMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteVideoMutation, Types.DeleteVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteVideoMutation, Types.DeleteVideoMutationVariables>(DeleteVideoDocument, options);
      }
export type DeleteVideoMutationHookResult = ReturnType<typeof useDeleteVideoMutation>;
export type DeleteVideoMutationResult = Apollo.MutationResult<Types.DeleteVideoMutation>;
export type DeleteVideoMutationOptions = Apollo.BaseMutationOptions<Types.DeleteVideoMutation, Types.DeleteVideoMutationVariables>;
export const DeleteVideoByPkDocument = gql`
    mutation DeleteVideoByPk($tipId: Int!) {
  delete_tip_by_pk(tip_id: $tipId) {
    tip_id
  }
}
    `;
export type DeleteVideoByPkMutationFn = Apollo.MutationFunction<Types.DeleteVideoByPkMutation, Types.DeleteVideoByPkMutationVariables>;

/**
 * __useDeleteVideoByPkMutation__
 *
 * To run a mutation, you first call `useDeleteVideoByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoByPkMutation, { data, loading, error }] = useDeleteVideoByPkMutation({
 *   variables: {
 *      tipId: // value for 'tipId'
 *   },
 * });
 */
export function useDeleteVideoByPkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteVideoByPkMutation, Types.DeleteVideoByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteVideoByPkMutation, Types.DeleteVideoByPkMutationVariables>(DeleteVideoByPkDocument, options);
      }
export type DeleteVideoByPkMutationHookResult = ReturnType<typeof useDeleteVideoByPkMutation>;
export type DeleteVideoByPkMutationResult = Apollo.MutationResult<Types.DeleteVideoByPkMutation>;
export type DeleteVideoByPkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteVideoByPkMutation, Types.DeleteVideoByPkMutationVariables>;
export const AddVideoDocument = gql`
    mutation AddVideo($data: tip_insert_input!) {
  insert_tip_one(object: $data) {
    tip_id
    title
    teacher_id
  }
}
    `;
export type AddVideoMutationFn = Apollo.MutationFunction<Types.AddVideoMutation, Types.AddVideoMutationVariables>;

/**
 * __useAddVideoMutation__
 *
 * To run a mutation, you first call `useAddVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVideoMutation, { data, loading, error }] = useAddVideoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddVideoMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddVideoMutation, Types.AddVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddVideoMutation, Types.AddVideoMutationVariables>(AddVideoDocument, options);
      }
export type AddVideoMutationHookResult = ReturnType<typeof useAddVideoMutation>;
export type AddVideoMutationResult = Apollo.MutationResult<Types.AddVideoMutation>;
export type AddVideoMutationOptions = Apollo.BaseMutationOptions<Types.AddVideoMutation, Types.AddVideoMutationVariables>;
export const UpdateClassVideosDocument = gql`
    mutation UpdateClassVideos($class_id: Int!, $tipIds: [class_tip_insert_input!]!) {
  delete_class_tip(where: {class_id: {_eq: $class_id}}) {
    affected_rows
  }
  insert_class_tip(objects: $tipIds) {
    affected_rows
  }
}
    `;
export type UpdateClassVideosMutationFn = Apollo.MutationFunction<Types.UpdateClassVideosMutation, Types.UpdateClassVideosMutationVariables>;

/**
 * __useUpdateClassVideosMutation__
 *
 * To run a mutation, you first call `useUpdateClassVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassVideosMutation, { data, loading, error }] = useUpdateClassVideosMutation({
 *   variables: {
 *      class_id: // value for 'class_id'
 *      tipIds: // value for 'tipIds'
 *   },
 * });
 */
export function useUpdateClassVideosMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateClassVideosMutation, Types.UpdateClassVideosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateClassVideosMutation, Types.UpdateClassVideosMutationVariables>(UpdateClassVideosDocument, options);
      }
export type UpdateClassVideosMutationHookResult = ReturnType<typeof useUpdateClassVideosMutation>;
export type UpdateClassVideosMutationResult = Apollo.MutationResult<Types.UpdateClassVideosMutation>;
export type UpdateClassVideosMutationOptions = Apollo.BaseMutationOptions<Types.UpdateClassVideosMutation, Types.UpdateClassVideosMutationVariables>;
export const GetSearchVideosDocument = gql`
    query getSearchVideos($teacherId: Int, $searchConditions: [tip_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc) {
  tip(
    where: {_or: $searchConditions, tip_id: {_is_null: false}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...tipExtendedFragment
  }
  tip_aggregate(where: {_or: $searchConditions, tip_id: {_is_null: false}}) {
    aggregate {
      count
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetSearchVideosQuery__
 *
 * To run a query within a React component, call `useGetSearchVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchVideosQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      searchConditions: // value for 'searchConditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSearchVideosQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSearchVideosQuery, Types.GetSearchVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchVideosQuery, Types.GetSearchVideosQueryVariables>(GetSearchVideosDocument, options);
      }
export function useGetSearchVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchVideosQuery, Types.GetSearchVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchVideosQuery, Types.GetSearchVideosQueryVariables>(GetSearchVideosDocument, options);
        }
export type GetSearchVideosQueryHookResult = ReturnType<typeof useGetSearchVideosQuery>;
export type GetSearchVideosLazyQueryHookResult = ReturnType<typeof useGetSearchVideosLazyQuery>;
export type GetSearchVideosQueryResult = Apollo.QueryResult<Types.GetSearchVideosQuery, Types.GetSearchVideosQueryVariables>;
export function refetchGetSearchVideosQuery(variables?: Types.GetSearchVideosQueryVariables) {
      return { query: GetSearchVideosDocument, variables: variables }
    }
export const GetSearchVideosFeaturedDocument = gql`
    query getSearchVideosFeatured($teacherId: Int, $searchConditions: [tip_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc) {
  tip(
    where: {featured: {_eq: true}, _or: $searchConditions, tip_id: {_is_null: false}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...tipExtendedFragment
  }
  tip_aggregate(
    where: {featured: {_eq: true}, _or: $searchConditions, tip_id: {_is_null: false}}
  ) {
    aggregate {
      count
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetSearchVideosFeaturedQuery__
 *
 * To run a query within a React component, call `useGetSearchVideosFeaturedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchVideosFeaturedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchVideosFeaturedQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      searchConditions: // value for 'searchConditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSearchVideosFeaturedQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSearchVideosFeaturedQuery, Types.GetSearchVideosFeaturedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchVideosFeaturedQuery, Types.GetSearchVideosFeaturedQueryVariables>(GetSearchVideosFeaturedDocument, options);
      }
export function useGetSearchVideosFeaturedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchVideosFeaturedQuery, Types.GetSearchVideosFeaturedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchVideosFeaturedQuery, Types.GetSearchVideosFeaturedQueryVariables>(GetSearchVideosFeaturedDocument, options);
        }
export type GetSearchVideosFeaturedQueryHookResult = ReturnType<typeof useGetSearchVideosFeaturedQuery>;
export type GetSearchVideosFeaturedLazyQueryHookResult = ReturnType<typeof useGetSearchVideosFeaturedLazyQuery>;
export type GetSearchVideosFeaturedQueryResult = Apollo.QueryResult<Types.GetSearchVideosFeaturedQuery, Types.GetSearchVideosFeaturedQueryVariables>;
export function refetchGetSearchVideosFeaturedQuery(variables?: Types.GetSearchVideosFeaturedQueryVariables) {
      return { query: GetSearchVideosFeaturedDocument, variables: variables }
    }
export const SearchMyVideosDocument = gql`
    query SearchMyVideos($teacherId: Int, $conditions: [tip_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc) {
  tip(
    where: {_or: $conditions, teacher_id: {_eq: $teacherId}, tip_id: {_is_null: false}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...tipExtendedFragment
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useSearchMyVideosQuery__
 *
 * To run a query within a React component, call `useSearchMyVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMyVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMyVideosQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchMyVideosQuery(baseOptions?: Apollo.QueryHookOptions<Types.SearchMyVideosQuery, Types.SearchMyVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchMyVideosQuery, Types.SearchMyVideosQueryVariables>(SearchMyVideosDocument, options);
      }
export function useSearchMyVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchMyVideosQuery, Types.SearchMyVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchMyVideosQuery, Types.SearchMyVideosQueryVariables>(SearchMyVideosDocument, options);
        }
export type SearchMyVideosQueryHookResult = ReturnType<typeof useSearchMyVideosQuery>;
export type SearchMyVideosLazyQueryHookResult = ReturnType<typeof useSearchMyVideosLazyQuery>;
export type SearchMyVideosQueryResult = Apollo.QueryResult<Types.SearchMyVideosQuery, Types.SearchMyVideosQueryVariables>;
export function refetchSearchMyVideosQuery(variables?: Types.SearchMyVideosQueryVariables) {
      return { query: SearchMyVideosDocument, variables: variables }
    }
export const GetFeaturedTipsDocument = gql`
    query GetFeaturedTips($teacherId: Int, $offset: Int, $limit: Int, $order: order_by = asc) {
  tip(
    where: {featured: {_eq: true}, tip_id: {_is_null: false}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...tipExtendedFragment
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetFeaturedTipsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedTipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedTipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedTipsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetFeaturedTipsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFeaturedTipsQuery, Types.GetFeaturedTipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeaturedTipsQuery, Types.GetFeaturedTipsQueryVariables>(GetFeaturedTipsDocument, options);
      }
export function useGetFeaturedTipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeaturedTipsQuery, Types.GetFeaturedTipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeaturedTipsQuery, Types.GetFeaturedTipsQueryVariables>(GetFeaturedTipsDocument, options);
        }
export type GetFeaturedTipsQueryHookResult = ReturnType<typeof useGetFeaturedTipsQuery>;
export type GetFeaturedTipsLazyQueryHookResult = ReturnType<typeof useGetFeaturedTipsLazyQuery>;
export type GetFeaturedTipsQueryResult = Apollo.QueryResult<Types.GetFeaturedTipsQuery, Types.GetFeaturedTipsQueryVariables>;
export function refetchGetFeaturedTipsQuery(variables?: Types.GetFeaturedTipsQueryVariables) {
      return { query: GetFeaturedTipsDocument, variables: variables }
    }
export const GetFeaturedVideosDocument = gql`
    query GetFeaturedVideos($conditions: [tip_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc) {
  tip(
    where: {_or: $conditions, tip_id: {_is_null: false}, active: {_eq: true}, featured: {_eq: true}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    ...tipExtendedFragment
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetFeaturedVideosQuery__
 *
 * To run a query within a React component, call `useGetFeaturedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedVideosQuery({
 *   variables: {
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetFeaturedVideosQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFeaturedVideosQuery, Types.GetFeaturedVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeaturedVideosQuery, Types.GetFeaturedVideosQueryVariables>(GetFeaturedVideosDocument, options);
      }
export function useGetFeaturedVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeaturedVideosQuery, Types.GetFeaturedVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeaturedVideosQuery, Types.GetFeaturedVideosQueryVariables>(GetFeaturedVideosDocument, options);
        }
export type GetFeaturedVideosQueryHookResult = ReturnType<typeof useGetFeaturedVideosQuery>;
export type GetFeaturedVideosLazyQueryHookResult = ReturnType<typeof useGetFeaturedVideosLazyQuery>;
export type GetFeaturedVideosQueryResult = Apollo.QueryResult<Types.GetFeaturedVideosQuery, Types.GetFeaturedVideosQueryVariables>;
export function refetchGetFeaturedVideosQuery(variables?: Types.GetFeaturedVideosQueryVariables) {
      return { query: GetFeaturedVideosDocument, variables: variables }
    }
export const GetVideoTeachersDocument = gql`
    query GetVideoTeachers($conditions: [tip_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc, $teacherId: Int) {
  tip(
    where: {_or: $conditions, tip_id: {_is_null: false}, active: {_eq: true}}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...tipExtendedFragment
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetVideoTeachersQuery__
 *
 * To run a query within a React component, call `useGetVideoTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoTeachersQuery({
 *   variables: {
 *      conditions: // value for 'conditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetVideoTeachersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVideoTeachersQuery, Types.GetVideoTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetVideoTeachersQuery, Types.GetVideoTeachersQueryVariables>(GetVideoTeachersDocument, options);
      }
export function useGetVideoTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVideoTeachersQuery, Types.GetVideoTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetVideoTeachersQuery, Types.GetVideoTeachersQueryVariables>(GetVideoTeachersDocument, options);
        }
export type GetVideoTeachersQueryHookResult = ReturnType<typeof useGetVideoTeachersQuery>;
export type GetVideoTeachersLazyQueryHookResult = ReturnType<typeof useGetVideoTeachersLazyQuery>;
export type GetVideoTeachersQueryResult = Apollo.QueryResult<Types.GetVideoTeachersQuery, Types.GetVideoTeachersQueryVariables>;
export function refetchGetVideoTeachersQuery(variables?: Types.GetVideoTeachersQueryVariables) {
      return { query: GetVideoTeachersDocument, variables: variables }
    }
export const GetTipsDocument = gql`
    query GetTips($teacherId: Int!, $offset: Int, $limit: Int, $order_by: [tip_order_by!]) {
  tip(
    where: {teacher_id: {_eq: $teacherId}, tip_id: {_is_null: false}}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    ...tipExtendedFragment
  }
  tip_aggregate(where: {teacher_id: {_eq: $teacherId}, tip_id: {_is_null: false}}) {
    aggregate {
      count
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetTipsQuery__
 *
 * To run a query within a React component, call `useGetTipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipsQuery({
 *   variables: {
 *      teacherId: // value for 'teacherId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetTipsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipsQuery, Types.GetTipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipsQuery, Types.GetTipsQueryVariables>(GetTipsDocument, options);
      }
export function useGetTipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipsQuery, Types.GetTipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipsQuery, Types.GetTipsQueryVariables>(GetTipsDocument, options);
        }
export type GetTipsQueryHookResult = ReturnType<typeof useGetTipsQuery>;
export type GetTipsLazyQueryHookResult = ReturnType<typeof useGetTipsLazyQuery>;
export type GetTipsQueryResult = Apollo.QueryResult<Types.GetTipsQuery, Types.GetTipsQueryVariables>;
export function refetchGetTipsQuery(variables?: Types.GetTipsQueryVariables) {
      return { query: GetTipsDocument, variables: variables }
    }
export const GetTipsByIdsDocument = gql`
    query GetTipsByIds($tipIds: [Int!]!) {
  tip(where: {tip_id: {_in: $tipIds}}) {
    ...tipFragment
  }
}
    ${TipFragmentFragmentDoc}`;

/**
 * __useGetTipsByIdsQuery__
 *
 * To run a query within a React component, call `useGetTipsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipsByIdsQuery({
 *   variables: {
 *      tipIds: // value for 'tipIds'
 *   },
 * });
 */
export function useGetTipsByIdsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipsByIdsQuery, Types.GetTipsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipsByIdsQuery, Types.GetTipsByIdsQueryVariables>(GetTipsByIdsDocument, options);
      }
export function useGetTipsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipsByIdsQuery, Types.GetTipsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipsByIdsQuery, Types.GetTipsByIdsQueryVariables>(GetTipsByIdsDocument, options);
        }
export type GetTipsByIdsQueryHookResult = ReturnType<typeof useGetTipsByIdsQuery>;
export type GetTipsByIdsLazyQueryHookResult = ReturnType<typeof useGetTipsByIdsLazyQuery>;
export type GetTipsByIdsQueryResult = Apollo.QueryResult<Types.GetTipsByIdsQuery, Types.GetTipsByIdsQueryVariables>;
export function refetchGetTipsByIdsQuery(variables?: Types.GetTipsByIdsQueryVariables) {
      return { query: GetTipsByIdsDocument, variables: variables }
    }
export const GetTipsByIdForEditDocument = gql`
    query GetTipsByIdForEdit($tipId: Int) {
  tip(where: {tip_id: {_eq: $tipId}}) {
    title
    description
    image_path
    resource_path
    asset_path
    tip_keywords {
      keyword {
        name
        keyword_id
      }
    }
    class_tips {
      class {
        class_id
      }
    }
    subjects: tip_catalog_item(
      where: {catalog_item: {catalog: {name: {_eq: subjects}}}}
    ) {
      catalog_item {
        name
        catalog_item_id
      }
    }
    instruments: tip_catalog_item(
      where: {catalog_item: {catalog: {name: {_eq: instruments}}}}
    ) {
      catalog_item {
        name
        catalog_item_id
      }
    }
    ages: tip_catalog_item(where: {catalog_item: {catalog: {name: {_eq: ages}}}}) {
      catalog_item {
        name
        catalog_item_id
      }
    }
  }
}
    `;

/**
 * __useGetTipsByIdForEditQuery__
 *
 * To run a query within a React component, call `useGetTipsByIdForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipsByIdForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipsByIdForEditQuery({
 *   variables: {
 *      tipId: // value for 'tipId'
 *   },
 * });
 */
export function useGetTipsByIdForEditQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTipsByIdForEditQuery, Types.GetTipsByIdForEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipsByIdForEditQuery, Types.GetTipsByIdForEditQueryVariables>(GetTipsByIdForEditDocument, options);
      }
export function useGetTipsByIdForEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipsByIdForEditQuery, Types.GetTipsByIdForEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipsByIdForEditQuery, Types.GetTipsByIdForEditQueryVariables>(GetTipsByIdForEditDocument, options);
        }
export type GetTipsByIdForEditQueryHookResult = ReturnType<typeof useGetTipsByIdForEditQuery>;
export type GetTipsByIdForEditLazyQueryHookResult = ReturnType<typeof useGetTipsByIdForEditLazyQuery>;
export type GetTipsByIdForEditQueryResult = Apollo.QueryResult<Types.GetTipsByIdForEditQuery, Types.GetTipsByIdForEditQueryVariables>;
export function refetchGetTipsByIdForEditQuery(variables?: Types.GetTipsByIdForEditQueryVariables) {
      return { query: GetTipsByIdForEditDocument, variables: variables }
    }
export const GetVideosInCatalogIdsDocument = gql`
    query GetVideosInCatalogIds($searchConditions: [tip_bool_exp!] = [{title: {_ilike: "%%"}}], $offset: Int, $limit: Int, $order: order_by = asc, $featured: Boolean_comparison_exp, $conditions: [tip_bool_exp!], $teacherId: Int) {
  tip(
    where: {_and: [{featured: $featured}, {_and: $conditions}, {_or: $searchConditions}, {tip_id: {_is_null: false}}, {active: {_eq: true}}]}
    offset: $offset
    limit: $limit
    order_by: {title: $order}
  ) {
    is_favorite(args: {teacherid: $teacherId})
    ...tipExtendedFragment
  }
  tip_aggregate(
    where: {_and: [{featured: $featured}, {_and: $conditions}, {_or: $searchConditions}, {tip_id: {_is_null: false}}, {active: {_eq: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${TipExtendedFragmentFragmentDoc}`;

/**
 * __useGetVideosInCatalogIdsQuery__
 *
 * To run a query within a React component, call `useGetVideosInCatalogIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosInCatalogIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosInCatalogIdsQuery({
 *   variables: {
 *      searchConditions: // value for 'searchConditions'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      featured: // value for 'featured'
 *      conditions: // value for 'conditions'
 *      teacherId: // value for 'teacherId'
 *   },
 * });
 */
export function useGetVideosInCatalogIdsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetVideosInCatalogIdsQuery, Types.GetVideosInCatalogIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetVideosInCatalogIdsQuery, Types.GetVideosInCatalogIdsQueryVariables>(GetVideosInCatalogIdsDocument, options);
      }
export function useGetVideosInCatalogIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVideosInCatalogIdsQuery, Types.GetVideosInCatalogIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetVideosInCatalogIdsQuery, Types.GetVideosInCatalogIdsQueryVariables>(GetVideosInCatalogIdsDocument, options);
        }
export type GetVideosInCatalogIdsQueryHookResult = ReturnType<typeof useGetVideosInCatalogIdsQuery>;
export type GetVideosInCatalogIdsLazyQueryHookResult = ReturnType<typeof useGetVideosInCatalogIdsLazyQuery>;
export type GetVideosInCatalogIdsQueryResult = Apollo.QueryResult<Types.GetVideosInCatalogIdsQuery, Types.GetVideosInCatalogIdsQueryVariables>;
export function refetchGetVideosInCatalogIdsQuery(variables?: Types.GetVideosInCatalogIdsQueryVariables) {
      return { query: GetVideosInCatalogIdsDocument, variables: variables }
    }
export const GetTipByClassIdDocument = gql`
    query GetTipByClassId($classId: Int!, $search: [tip_bool_exp!], $limit: Int, $offset: Int) {
  tip(
    where: {class_tips: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
    offset: $offset
  ) {
    tip_id
    image_path
    teacher_id
    title
    lockable_content_id
    description
    featured
    active
    resource_path
    tip_catalog_item {
      tip_catalog_item_id
      catalog_item {
        catalog_item_id
        catalog_id
        name
        catalog {
          catalog_id
          name
        }
      }
    }
    lockable_content {
      lockable_content_id
      visibility
    }
  }
  tip_aggregate(where: {class_tips: {class_id: {_eq: $classId}}, _or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTipByClassIdQuery__
 *
 * To run a query within a React component, call `useGetTipByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTipByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipByClassIdQuery, Types.GetTipByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipByClassIdQuery, Types.GetTipByClassIdQueryVariables>(GetTipByClassIdDocument, options);
      }
export function useGetTipByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipByClassIdQuery, Types.GetTipByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipByClassIdQuery, Types.GetTipByClassIdQueryVariables>(GetTipByClassIdDocument, options);
        }
export type GetTipByClassIdQueryHookResult = ReturnType<typeof useGetTipByClassIdQuery>;
export type GetTipByClassIdLazyQueryHookResult = ReturnType<typeof useGetTipByClassIdLazyQuery>;
export type GetTipByClassIdQueryResult = Apollo.QueryResult<Types.GetTipByClassIdQuery, Types.GetTipByClassIdQueryVariables>;
export function refetchGetTipByClassIdQuery(variables?: Types.GetTipByClassIdQueryVariables) {
      return { query: GetTipByClassIdDocument, variables: variables }
    }
export const GetSearchTipByClassIdDocument = gql`
    query GetSearchTipByClassId($classId: Int!, $search: [tip_bool_exp!], $limit: Int) {
  tip(
    where: {class_tips: {class_id: {_eq: $classId}}, _or: $search}
    limit: $limit
  ) {
    tip_id
    image_path
    teacher_id
    title
    lockable_content_id
    description
    featured
    active
    resource_path
    tip_catalog_item {
      tip_catalog_item_id
      catalog_item {
        catalog_item_id
        catalog_id
        name
        catalog {
          catalog_id
          name
        }
      }
    }
    lockable_content {
      lockable_content_id
      visibility
    }
  }
  tip_aggregate(where: {class_tips: {class_id: {_eq: $classId}}, _or: $search}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetSearchTipByClassIdQuery__
 *
 * To run a query within a React component, call `useGetSearchTipByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchTipByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchTipByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSearchTipByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSearchTipByClassIdQuery, Types.GetSearchTipByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchTipByClassIdQuery, Types.GetSearchTipByClassIdQueryVariables>(GetSearchTipByClassIdDocument, options);
      }
export function useGetSearchTipByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchTipByClassIdQuery, Types.GetSearchTipByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchTipByClassIdQuery, Types.GetSearchTipByClassIdQueryVariables>(GetSearchTipByClassIdDocument, options);
        }
export type GetSearchTipByClassIdQueryHookResult = ReturnType<typeof useGetSearchTipByClassIdQuery>;
export type GetSearchTipByClassIdLazyQueryHookResult = ReturnType<typeof useGetSearchTipByClassIdLazyQuery>;
export type GetSearchTipByClassIdQueryResult = Apollo.QueryResult<Types.GetSearchTipByClassIdQuery, Types.GetSearchTipByClassIdQueryVariables>;
export function refetchGetSearchTipByClassIdQuery(variables?: Types.GetSearchTipByClassIdQueryVariables) {
      return { query: GetSearchTipByClassIdDocument, variables: variables }
    }
export const GetTipDataByClassIdDocument = gql`
    query GetTipDataByClassId($classId: Int!, $limit: Int!) {
  tip(where: {class_tips: {class_id: {_eq: $classId}}}, limit: $limit) {
    tip_id
    image_path
    teacher_id
    title
    resource_path
    lockable_content_id
    description
    featured
    active
    tip_catalog_item {
      tip_catalog_item_id
      catalog_item {
        catalog_item_id
        catalog_id
        name
        catalog {
          catalog_id
          name
        }
      }
    }
    lockable_content {
      lockable_content_id
      visibility
    }
  }
  tip_aggregate(where: {class_tips: {class_id: {_eq: $classId}}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTipDataByClassIdQuery__
 *
 * To run a query within a React component, call `useGetTipDataByClassIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTipDataByClassIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTipDataByClassIdQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTipDataByClassIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTipDataByClassIdQuery, Types.GetTipDataByClassIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTipDataByClassIdQuery, Types.GetTipDataByClassIdQueryVariables>(GetTipDataByClassIdDocument, options);
      }
export function useGetTipDataByClassIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTipDataByClassIdQuery, Types.GetTipDataByClassIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTipDataByClassIdQuery, Types.GetTipDataByClassIdQueryVariables>(GetTipDataByClassIdDocument, options);
        }
export type GetTipDataByClassIdQueryHookResult = ReturnType<typeof useGetTipDataByClassIdQuery>;
export type GetTipDataByClassIdLazyQueryHookResult = ReturnType<typeof useGetTipDataByClassIdLazyQuery>;
export type GetTipDataByClassIdQueryResult = Apollo.QueryResult<Types.GetTipDataByClassIdQuery, Types.GetTipDataByClassIdQueryVariables>;
export function refetchGetTipDataByClassIdQuery(variables?: Types.GetTipDataByClassIdQueryVariables) {
      return { query: GetTipDataByClassIdDocument, variables: variables }
    }
export const GetAllTipsDocument = gql`
    query GetAllTips($search: [tip_bool_exp!], $offset: Int, $limit: Int) {
  tip_aggregate(where: {_or: $search}) {
    aggregate {
      count
    }
  }
  tip(where: {_or: $search}, offset: $offset, limit: $limit) {
    tip_id
    teacher_id
    title
    description
    image_path
    active
    featured
    lockable_content {
      visibility
    }
  }
}
    `;

/**
 * __useGetAllTipsQuery__
 *
 * To run a query within a React component, call `useGetAllTipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTipsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllTipsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllTipsQuery, Types.GetAllTipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllTipsQuery, Types.GetAllTipsQueryVariables>(GetAllTipsDocument, options);
      }
export function useGetAllTipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllTipsQuery, Types.GetAllTipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllTipsQuery, Types.GetAllTipsQueryVariables>(GetAllTipsDocument, options);
        }
export type GetAllTipsQueryHookResult = ReturnType<typeof useGetAllTipsQuery>;
export type GetAllTipsLazyQueryHookResult = ReturnType<typeof useGetAllTipsLazyQuery>;
export type GetAllTipsQueryResult = Apollo.QueryResult<Types.GetAllTipsQuery, Types.GetAllTipsQueryVariables>;
export function refetchGetAllTipsQuery(variables?: Types.GetAllTipsQueryVariables) {
      return { query: GetAllTipsDocument, variables: variables }
    }
export const InsertTrackDocument = gql`
    mutation InsertTrack($object: track_insert_input!) {
  insert_track_one(object: $object) {
    track_id
  }
}
    `;
export type InsertTrackMutationFn = Apollo.MutationFunction<Types.InsertTrackMutation, Types.InsertTrackMutationVariables>;

/**
 * __useInsertTrackMutation__
 *
 * To run a mutation, you first call `useInsertTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTrackMutation, { data, loading, error }] = useInsertTrackMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTrackMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertTrackMutation, Types.InsertTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertTrackMutation, Types.InsertTrackMutationVariables>(InsertTrackDocument, options);
      }
export type InsertTrackMutationHookResult = ReturnType<typeof useInsertTrackMutation>;
export type InsertTrackMutationResult = Apollo.MutationResult<Types.InsertTrackMutation>;
export type InsertTrackMutationOptions = Apollo.BaseMutationOptions<Types.InsertTrackMutation, Types.InsertTrackMutationVariables>;
export const CustomInsertTrackDocument = gql`
    mutation CustomInsertTrack($filter: UploadTrackFilter!) {
  customTrackUpload(filter: $filter)
}
    `;
export type CustomInsertTrackMutationFn = Apollo.MutationFunction<Types.CustomInsertTrackMutation, Types.CustomInsertTrackMutationVariables>;

/**
 * __useCustomInsertTrackMutation__
 *
 * To run a mutation, you first call `useCustomInsertTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomInsertTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customInsertTrackMutation, { data, loading, error }] = useCustomInsertTrackMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomInsertTrackMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomInsertTrackMutation, Types.CustomInsertTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomInsertTrackMutation, Types.CustomInsertTrackMutationVariables>(CustomInsertTrackDocument, options);
      }
export type CustomInsertTrackMutationHookResult = ReturnType<typeof useCustomInsertTrackMutation>;
export type CustomInsertTrackMutationResult = Apollo.MutationResult<Types.CustomInsertTrackMutation>;
export type CustomInsertTrackMutationOptions = Apollo.BaseMutationOptions<Types.CustomInsertTrackMutation, Types.CustomInsertTrackMutationVariables>;
export const DeleteTrackDocument = gql`
    mutation DeleteTrack($trackId: Int!) {
  delete_track_by_pk(track_id: $trackId) {
    track_id
  }
}
    `;
export type DeleteTrackMutationFn = Apollo.MutationFunction<Types.DeleteTrackMutation, Types.DeleteTrackMutationVariables>;

/**
 * __useDeleteTrackMutation__
 *
 * To run a mutation, you first call `useDeleteTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrackMutation, { data, loading, error }] = useDeleteTrackMutation({
 *   variables: {
 *      trackId: // value for 'trackId'
 *   },
 * });
 */
export function useDeleteTrackMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteTrackMutation, Types.DeleteTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteTrackMutation, Types.DeleteTrackMutationVariables>(DeleteTrackDocument, options);
      }
export type DeleteTrackMutationHookResult = ReturnType<typeof useDeleteTrackMutation>;
export type DeleteTrackMutationResult = Apollo.MutationResult<Types.DeleteTrackMutation>;
export type DeleteTrackMutationOptions = Apollo.BaseMutationOptions<Types.DeleteTrackMutation, Types.DeleteTrackMutationVariables>;
export const GetTrackTypesDocument = gql`
    query GetTrackTypes($where: track_type_bool_exp) {
  track_type(where: $where) {
    name
    track_type_id
  }
}
    `;

/**
 * __useGetTrackTypesQuery__
 *
 * To run a query within a React component, call `useGetTrackTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTrackTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTrackTypesQuery, Types.GetTrackTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTrackTypesQuery, Types.GetTrackTypesQueryVariables>(GetTrackTypesDocument, options);
      }
export function useGetTrackTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTrackTypesQuery, Types.GetTrackTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTrackTypesQuery, Types.GetTrackTypesQueryVariables>(GetTrackTypesDocument, options);
        }
export type GetTrackTypesQueryHookResult = ReturnType<typeof useGetTrackTypesQuery>;
export type GetTrackTypesLazyQueryHookResult = ReturnType<typeof useGetTrackTypesLazyQuery>;
export type GetTrackTypesQueryResult = Apollo.QueryResult<Types.GetTrackTypesQuery, Types.GetTrackTypesQueryVariables>;
export function refetchGetTrackTypesQuery(variables?: Types.GetTrackTypesQueryVariables) {
      return { query: GetTrackTypesDocument, variables: variables }
    }
export const ValidateStudentsInClassDocument = gql`
    query ValidateStudentsInClass($students: StudentsInClass!) {
  validateStudentsInClass(filter: {studentsInClass: $students}) {
    studentsAlreadyInClass {
      first_name
      last_name
      email
      code
    }
    studentsNewInClass {
      first_name
      last_name
      email
      code
    }
    error
  }
}
    `;

/**
 * __useValidateStudentsInClassQuery__
 *
 * To run a query within a React component, call `useValidateStudentsInClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateStudentsInClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateStudentsInClassQuery({
 *   variables: {
 *      students: // value for 'students'
 *   },
 * });
 */
export function useValidateStudentsInClassQuery(baseOptions: Apollo.QueryHookOptions<Types.ValidateStudentsInClassQuery, Types.ValidateStudentsInClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ValidateStudentsInClassQuery, Types.ValidateStudentsInClassQueryVariables>(ValidateStudentsInClassDocument, options);
      }
export function useValidateStudentsInClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ValidateStudentsInClassQuery, Types.ValidateStudentsInClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ValidateStudentsInClassQuery, Types.ValidateStudentsInClassQueryVariables>(ValidateStudentsInClassDocument, options);
        }
export type ValidateStudentsInClassQueryHookResult = ReturnType<typeof useValidateStudentsInClassQuery>;
export type ValidateStudentsInClassLazyQueryHookResult = ReturnType<typeof useValidateStudentsInClassLazyQuery>;
export type ValidateStudentsInClassQueryResult = Apollo.QueryResult<Types.ValidateStudentsInClassQuery, Types.ValidateStudentsInClassQueryVariables>;
export function refetchValidateStudentsInClassQuery(variables?: Types.ValidateStudentsInClassQueryVariables) {
      return { query: ValidateStudentsInClassDocument, variables: variables }
    }
export const DeleteWurrlyDocument = gql`
    mutation DeleteWurrly($wurrlyId: Int!) {
  delete_wurrly(where: {wurrly_id: {_eq: $wurrlyId}}) {
    affected_rows
    returning {
      class {
        class_id
        wurrlies {
          wurrly_id
        }
      }
    }
  }
}
    `;
export type DeleteWurrlyMutationFn = Apollo.MutationFunction<Types.DeleteWurrlyMutation, Types.DeleteWurrlyMutationVariables>;

/**
 * __useDeleteWurrlyMutation__
 *
 * To run a mutation, you first call `useDeleteWurrlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWurrlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWurrlyMutation, { data, loading, error }] = useDeleteWurrlyMutation({
 *   variables: {
 *      wurrlyId: // value for 'wurrlyId'
 *   },
 * });
 */
export function useDeleteWurrlyMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteWurrlyMutation, Types.DeleteWurrlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteWurrlyMutation, Types.DeleteWurrlyMutationVariables>(DeleteWurrlyDocument, options);
      }
export type DeleteWurrlyMutationHookResult = ReturnType<typeof useDeleteWurrlyMutation>;
export type DeleteWurrlyMutationResult = Apollo.MutationResult<Types.DeleteWurrlyMutation>;
export type DeleteWurrlyMutationOptions = Apollo.BaseMutationOptions<Types.DeleteWurrlyMutation, Types.DeleteWurrlyMutationVariables>;
export const UpdateWurrlyInsertCommentDocument = gql`
    mutation UpdateWurrlyInsertComment($wurrlyId: Int!, $privacyId: privacy_enum!, $teacherWurrlyComment: teacher_wurrly_comment_insert_input!) {
  update_wurrly(where: {wurrly_id: {_eq: $wurrlyId}}, _set: {privacy: $privacyId}) {
    affected_rows
    returning {
      class {
        class_id
      }
    }
  }
  insert_teacher_wurrly_comment_one(object: $teacherWurrlyComment) {
    wurrly {
      class {
        class_id
      }
    }
  }
}
    `;
export type UpdateWurrlyInsertCommentMutationFn = Apollo.MutationFunction<Types.UpdateWurrlyInsertCommentMutation, Types.UpdateWurrlyInsertCommentMutationVariables>;

/**
 * __useUpdateWurrlyInsertCommentMutation__
 *
 * To run a mutation, you first call `useUpdateWurrlyInsertCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWurrlyInsertCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWurrlyInsertCommentMutation, { data, loading, error }] = useUpdateWurrlyInsertCommentMutation({
 *   variables: {
 *      wurrlyId: // value for 'wurrlyId'
 *      privacyId: // value for 'privacyId'
 *      teacherWurrlyComment: // value for 'teacherWurrlyComment'
 *   },
 * });
 */
export function useUpdateWurrlyInsertCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWurrlyInsertCommentMutation, Types.UpdateWurrlyInsertCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWurrlyInsertCommentMutation, Types.UpdateWurrlyInsertCommentMutationVariables>(UpdateWurrlyInsertCommentDocument, options);
      }
export type UpdateWurrlyInsertCommentMutationHookResult = ReturnType<typeof useUpdateWurrlyInsertCommentMutation>;
export type UpdateWurrlyInsertCommentMutationResult = Apollo.MutationResult<Types.UpdateWurrlyInsertCommentMutation>;
export type UpdateWurrlyInsertCommentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWurrlyInsertCommentMutation, Types.UpdateWurrlyInsertCommentMutationVariables>;
export const UpdateWurrlyUpdateCommentDocument = gql`
    mutation UpdateWurrlyUpdateComment($wurrlyId: Int!, $privacyId: privacy_enum!, $submissionId: Int!, $teacherComment: String) {
  update_wurrly(where: {wurrly_id: {_eq: $wurrlyId}}, _set: {privacy: $privacyId}) {
    affected_rows
    returning {
      class {
        class_id
      }
    }
  }
  update_teacher_wurrly_comment(
    where: {wurrly_id: {_eq: $wurrlyId}}
    _set: {comment: $teacherComment}
  ) {
    affected_rows
    returning {
      wurrly {
        class {
          class_id
        }
      }
    }
  }
  update_submission_by_pk(
    pk_columns: {submission_id: $submissionId}
    _set: {teacher_comment: $teacherComment}
  ) {
    submission_id
  }
}
    `;
export type UpdateWurrlyUpdateCommentMutationFn = Apollo.MutationFunction<Types.UpdateWurrlyUpdateCommentMutation, Types.UpdateWurrlyUpdateCommentMutationVariables>;

/**
 * __useUpdateWurrlyUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateWurrlyUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWurrlyUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWurrlyUpdateCommentMutation, { data, loading, error }] = useUpdateWurrlyUpdateCommentMutation({
 *   variables: {
 *      wurrlyId: // value for 'wurrlyId'
 *      privacyId: // value for 'privacyId'
 *      submissionId: // value for 'submissionId'
 *      teacherComment: // value for 'teacherComment'
 *   },
 * });
 */
export function useUpdateWurrlyUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWurrlyUpdateCommentMutation, Types.UpdateWurrlyUpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWurrlyUpdateCommentMutation, Types.UpdateWurrlyUpdateCommentMutationVariables>(UpdateWurrlyUpdateCommentDocument, options);
      }
export type UpdateWurrlyUpdateCommentMutationHookResult = ReturnType<typeof useUpdateWurrlyUpdateCommentMutation>;
export type UpdateWurrlyUpdateCommentMutationResult = Apollo.MutationResult<Types.UpdateWurrlyUpdateCommentMutation>;
export type UpdateWurrlyUpdateCommentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWurrlyUpdateCommentMutation, Types.UpdateWurrlyUpdateCommentMutationVariables>;
export const UpdateWurrlyPrivacyDocument = gql`
    mutation UpdateWurrlyPrivacy($wurrlyId: Int!, $privacyId: privacy_enum!) {
  update_wurrly(where: {wurrly_id: {_eq: $wurrlyId}}, _set: {privacy: $privacyId}) {
    affected_rows
    returning {
      wurrly_id
    }
  }
}
    `;
export type UpdateWurrlyPrivacyMutationFn = Apollo.MutationFunction<Types.UpdateWurrlyPrivacyMutation, Types.UpdateWurrlyPrivacyMutationVariables>;

/**
 * __useUpdateWurrlyPrivacyMutation__
 *
 * To run a mutation, you first call `useUpdateWurrlyPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWurrlyPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWurrlyPrivacyMutation, { data, loading, error }] = useUpdateWurrlyPrivacyMutation({
 *   variables: {
 *      wurrlyId: // value for 'wurrlyId'
 *      privacyId: // value for 'privacyId'
 *   },
 * });
 */
export function useUpdateWurrlyPrivacyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateWurrlyPrivacyMutation, Types.UpdateWurrlyPrivacyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateWurrlyPrivacyMutation, Types.UpdateWurrlyPrivacyMutationVariables>(UpdateWurrlyPrivacyDocument, options);
      }
export type UpdateWurrlyPrivacyMutationHookResult = ReturnType<typeof useUpdateWurrlyPrivacyMutation>;
export type UpdateWurrlyPrivacyMutationResult = Apollo.MutationResult<Types.UpdateWurrlyPrivacyMutation>;
export type UpdateWurrlyPrivacyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateWurrlyPrivacyMutation, Types.UpdateWurrlyPrivacyMutationVariables>;
export const GetWurrliesDocument = gql`
    query GetWurrlies($classId: Int!, $teacherId: Int, $orderTitle: order_by, $orderDate: order_by, $limit: Int) {
  wurrly(
    where: {class_id: {_eq: $classId}, _or: [{wurrly_type: {_eq: indie}}, {wurrly_type: {_eq: challenge}}, {wurrly_type: {_eq: assignment}, submission_wurrly: {state: {_eq: submitted}}}, {is_from_legacy: {_eq: true}}]}
    limit: $limit
    order_by: {track: {song: {title: $orderTitle}}, student_id_owner: asc, created_at: $orderDate}
  ) {
    ...WurrlyContentFragment
    wurrly_id
    created_at
    image_path
    resource_path
    final_resource_path
    is_from_legacy
    image_path
    is_video_url
    wurrly_type
    privacy
    challenge {
      title
    }
    class {
      title
    }
    student {
      first_name
      middle_name
      last_name
      image_path
      ...StudentBasicFragment
    }
    track {
      ...trackExtendedFragment
    }
    teacher_wurrly_comments(where: {teacher_id: {_eq: $teacherId}}) {
      teacher_id
      teacher_wurrly_comment_id
      comment
    }
    submission_wurrly {
      state
      submission_id
      submission {
        teacher_comment
        grade
      }
    }
  }
  wurrly_aggregate(
    where: {class_id: {_eq: $classId}, _or: [{is_from_legacy: {_eq: true}}, {submission_wurrly: {state: {_eq: submitted}}}]}
    order_by: {track: {song: {title: asc}}, student_id_owner: asc}
  ) {
    aggregate {
      count
    }
  }
}
    ${WurrlyContentFragmentFragmentDoc}
${StudentBasicFragmentFragmentDoc}
${TrackExtendedFragmentFragmentDoc}`;

/**
 * __useGetWurrliesQuery__
 *
 * To run a query within a React component, call `useGetWurrliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      teacherId: // value for 'teacherId'
 *      orderTitle: // value for 'orderTitle'
 *      orderDate: // value for 'orderDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetWurrliesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesQuery, Types.GetWurrliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesQuery, Types.GetWurrliesQueryVariables>(GetWurrliesDocument, options);
      }
export function useGetWurrliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesQuery, Types.GetWurrliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesQuery, Types.GetWurrliesQueryVariables>(GetWurrliesDocument, options);
        }
export type GetWurrliesQueryHookResult = ReturnType<typeof useGetWurrliesQuery>;
export type GetWurrliesLazyQueryHookResult = ReturnType<typeof useGetWurrliesLazyQuery>;
export type GetWurrliesQueryResult = Apollo.QueryResult<Types.GetWurrliesQuery, Types.GetWurrliesQueryVariables>;
export function refetchGetWurrliesQuery(variables?: Types.GetWurrliesQueryVariables) {
      return { query: GetWurrliesDocument, variables: variables }
    }
export const GetWurrliesCountDocument = gql`
    query GetWurrliesCount($classId: Int!, $date: timestamptz!) {
  recordingSessions: wurrly_aggregate(where: {class_id: {_eq: $classId}}) {
    aggregate {
      count
    }
  }
  recordingSessionsLastWeek: wurrly_aggregate(
    where: {class_id: {_eq: $classId}, created_at: {_lte: $date}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetWurrliesCountQuery__
 *
 * To run a query within a React component, call `useGetWurrliesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesCountQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetWurrliesCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesCountQuery, Types.GetWurrliesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesCountQuery, Types.GetWurrliesCountQueryVariables>(GetWurrliesCountDocument, options);
      }
export function useGetWurrliesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesCountQuery, Types.GetWurrliesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesCountQuery, Types.GetWurrliesCountQueryVariables>(GetWurrliesCountDocument, options);
        }
export type GetWurrliesCountQueryHookResult = ReturnType<typeof useGetWurrliesCountQuery>;
export type GetWurrliesCountLazyQueryHookResult = ReturnType<typeof useGetWurrliesCountLazyQuery>;
export type GetWurrliesCountQueryResult = Apollo.QueryResult<Types.GetWurrliesCountQuery, Types.GetWurrliesCountQueryVariables>;
export function refetchGetWurrliesCountQuery(variables?: Types.GetWurrliesCountQueryVariables) {
      return { query: GetWurrliesCountDocument, variables: variables }
    }
export const GetWurrliesCountSinceLastWeekByStudentIdDocument = gql`
    query GetWurrliesCountSinceLastWeekByStudentId($studentId: Int!, $classId: Int!, $W1From: timestamptz!, $W1To: timestamptz!, $W2From: timestamptz!, $W2To: timestamptz!) {
  recordingSessions: wurrly_aggregate(
    where: {_and: [{student_id_owner: {_eq: $studentId}}, {updated_at: {_gte: $W2From}}, {updated_at: {_lte: $W2To}}, {class_id: {_eq: $classId}}]}
  ) {
    aggregate {
      count
    }
  }
  recordingSessionsLastWeek: wurrly_aggregate(
    where: {_and: [{student_id_owner: {_eq: $studentId}}, {updated_at: {_gte: $W1From}}, {updated_at: {_lte: $W1To}}, {class_id: {_eq: $classId}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetWurrliesCountSinceLastWeekByStudentIdQuery__
 *
 * To run a query within a React component, call `useGetWurrliesCountSinceLastWeekByStudentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWurrliesCountSinceLastWeekByStudentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWurrliesCountSinceLastWeekByStudentIdQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      classId: // value for 'classId'
 *      W1From: // value for 'W1From'
 *      W1To: // value for 'W1To'
 *      W2From: // value for 'W2From'
 *      W2To: // value for 'W2To'
 *   },
 * });
 */
export function useGetWurrliesCountSinceLastWeekByStudentIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetWurrliesCountSinceLastWeekByStudentIdQuery, Types.GetWurrliesCountSinceLastWeekByStudentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetWurrliesCountSinceLastWeekByStudentIdQuery, Types.GetWurrliesCountSinceLastWeekByStudentIdQueryVariables>(GetWurrliesCountSinceLastWeekByStudentIdDocument, options);
      }
export function useGetWurrliesCountSinceLastWeekByStudentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWurrliesCountSinceLastWeekByStudentIdQuery, Types.GetWurrliesCountSinceLastWeekByStudentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetWurrliesCountSinceLastWeekByStudentIdQuery, Types.GetWurrliesCountSinceLastWeekByStudentIdQueryVariables>(GetWurrliesCountSinceLastWeekByStudentIdDocument, options);
        }
export type GetWurrliesCountSinceLastWeekByStudentIdQueryHookResult = ReturnType<typeof useGetWurrliesCountSinceLastWeekByStudentIdQuery>;
export type GetWurrliesCountSinceLastWeekByStudentIdLazyQueryHookResult = ReturnType<typeof useGetWurrliesCountSinceLastWeekByStudentIdLazyQuery>;
export type GetWurrliesCountSinceLastWeekByStudentIdQueryResult = Apollo.QueryResult<Types.GetWurrliesCountSinceLastWeekByStudentIdQuery, Types.GetWurrliesCountSinceLastWeekByStudentIdQueryVariables>;
export function refetchGetWurrliesCountSinceLastWeekByStudentIdQuery(variables?: Types.GetWurrliesCountSinceLastWeekByStudentIdQueryVariables) {
      return { query: GetWurrliesCountSinceLastWeekByStudentIdDocument, variables: variables }
    }
export const GetStudentWurrliesDocument = gql`
    query GetStudentWurrlies($studentId: Int!, $teacherId: Int, $classId: Int, $orderTitle: order_by, $privacySettings: [privacy_enum!] = class, $orderDate: order_by) {
  student_by_pk(student_id: $studentId) {
    wurrly(
      where: {class_id: {_eq: $classId}, student: {class_students: {class: {class_teachers: {teacher_id: {_eq: $teacherId}}}}}, privacy: {_in: $privacySettings}, _or: [{is_from_legacy: {_eq: true}}, {is_from_legacy: {_eq: false}}, {submission_wurrly: {state: {_eq: submitted}}}]}
      order_by: {track: {song: {title: $orderTitle}}, created_at: $orderDate}
    ) {
      ...wurrlyGridFragment
      audio_balance
      audio_url
      is_from_legacy
    }
  }
}
    ${WurrlyGridFragmentFragmentDoc}`;

/**
 * __useGetStudentWurrliesQuery__
 *
 * To run a query within a React component, call `useGetStudentWurrliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentWurrliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentWurrliesQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      teacherId: // value for 'teacherId'
 *      classId: // value for 'classId'
 *      orderTitle: // value for 'orderTitle'
 *      privacySettings: // value for 'privacySettings'
 *      orderDate: // value for 'orderDate'
 *   },
 * });
 */
export function useGetStudentWurrliesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStudentWurrliesQuery, Types.GetStudentWurrliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStudentWurrliesQuery, Types.GetStudentWurrliesQueryVariables>(GetStudentWurrliesDocument, options);
      }
export function useGetStudentWurrliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStudentWurrliesQuery, Types.GetStudentWurrliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStudentWurrliesQuery, Types.GetStudentWurrliesQueryVariables>(GetStudentWurrliesDocument, options);
        }
export type GetStudentWurrliesQueryHookResult = ReturnType<typeof useGetStudentWurrliesQuery>;
export type GetStudentWurrliesLazyQueryHookResult = ReturnType<typeof useGetStudentWurrliesLazyQuery>;
export type GetStudentWurrliesQueryResult = Apollo.QueryResult<Types.GetStudentWurrliesQuery, Types.GetStudentWurrliesQueryVariables>;
export function refetchGetStudentWurrliesQuery(variables?: Types.GetStudentWurrliesQueryVariables) {
      return { query: GetStudentWurrliesDocument, variables: variables }
    }
export const GetClassWurrliesCountDocument = gql`
    query GetClassWurrliesCount($classId: Int!, $comparisonFromDate: timestamptz!, $comparisonToDate: timestamptz!, $fromDate: timestamptz!, $toDate: timestamptz!) {
  recordingSessions: wurrly_aggregate(
    where: {_and: [{class_id: {_eq: $classId}}, {updated_at: {_gte: $fromDate}}, {updated_at: {_lte: $toDate}}]}
  ) {
    aggregate {
      count
    }
  }
  comparisonRecordingSessions: wurrly_aggregate(
    where: {_and: [{class_id: {_eq: $classId}}, {updated_at: {_gte: $comparisonFromDate}}, {updated_at: {_lte: $comparisonToDate}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetClassWurrliesCountQuery__
 *
 * To run a query within a React component, call `useGetClassWurrliesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassWurrliesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassWurrliesCountQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      comparisonFromDate: // value for 'comparisonFromDate'
 *      comparisonToDate: // value for 'comparisonToDate'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetClassWurrliesCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetClassWurrliesCountQuery, Types.GetClassWurrliesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetClassWurrliesCountQuery, Types.GetClassWurrliesCountQueryVariables>(GetClassWurrliesCountDocument, options);
      }
export function useGetClassWurrliesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetClassWurrliesCountQuery, Types.GetClassWurrliesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetClassWurrliesCountQuery, Types.GetClassWurrliesCountQueryVariables>(GetClassWurrliesCountDocument, options);
        }
export type GetClassWurrliesCountQueryHookResult = ReturnType<typeof useGetClassWurrliesCountQuery>;
export type GetClassWurrliesCountLazyQueryHookResult = ReturnType<typeof useGetClassWurrliesCountLazyQuery>;
export type GetClassWurrliesCountQueryResult = Apollo.QueryResult<Types.GetClassWurrliesCountQuery, Types.GetClassWurrliesCountQueryVariables>;
export function refetchGetClassWurrliesCountQuery(variables?: Types.GetClassWurrliesCountQueryVariables) {
      return { query: GetClassWurrliesCountDocument, variables: variables }
    }