import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react'

import { Box, BoxProps, Divider } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import clsx from 'clsx'
import { FixedSizeList } from 'react-window'
import { createScrollStopListener } from 'src/hooks/createScrollStopListener'

import { TimeSlide } from '../TimeSlide/TimeSlide'
import { Waveform, WaveformProps } from '../Waveform/Waveform'
import useStyles from './TimeSlider.styles'

type Row = {
	/** Icon to render at the start of the row */
	icon: React.ReactNode
	/** Content to render in the middle of the row */
	content: {
		/** Component of the content */
		component: React.ReactNode
		/** Time in ms where the component should render */
		time: number
	}[]
	/** Handler to add an item on click, receiving time in ms */
	handleAdd?: (time: number) => void
}

type TimeSliderProps = {
	rows: Row[]
	/** Initial left space of the slider */
	sliderLeftOffset: number
	/** Width in pixels that represents a milisecond */
	pxPerMs?: number
	/** Song duration in miliseconds */
	songDuration: number
	/** Show time every X miliseconds */
	showTimeEvery?: number
	/** Rows container props */
	rowsContainerProps?: BoxProps
	/** Height of each row */
	rowHeight?: number
	/** Waveform file src and duration */
	waveformProps?: WaveformProps
	/**
	 * State setter to update the slider ref.
	 * The upper component should have a state of HTMLDivElement
	 * and to move it alongside with the song it should update the scrollLeft property like so:
	 * const [sliderElement, setSliderElement] = useState<HTMLDivElement | undefined>(undefined)
	 * useEffect(() => {
	 * sliderElement.scrollLeft = currentSongTime * pxPerMs
	 * },[currentSongTime])
	 */
	setSliderElement?: Dispatch<SetStateAction<HTMLDivElement | undefined>>
	onScrollToTime?: (time: number) => void
	time?: number
}

export const TimeSlider = ({
	rows,
	sliderLeftOffset = 100,
	pxPerMs = 0.2,
	showTimeEvery = 200,
	rowHeight = 100,
	songDuration,
	rowsContainerProps,
	waveformProps,
	onScrollToTime,
	setSliderElement,
	time = 0
}: TimeSliderProps) => {
	const styles = useStyles({ leftOffset: sliderLeftOffset, rows: rows.length, rowHeight })
	const rowsHolderRef = useRef<HTMLDivElement>(null)
	const sliderRef = useRef<FixedSizeList>(null)
	const [isScrollingAttemptStarted, setIsScrollingAttemptStarted] = useState(false)

	const scrollContainer = (offset: number) => {
		if (rowsHolderRef.current) {
			rowsHolderRef.current.scrollLeft = offset
		}
	}

	const scrollSlider = (offset: number) => {
		if (sliderRef.current) {
			sliderRef.current.scrollTo(offset)
		}
	}

	useEffect(() => {
		const offset = time * pxPerMs * 1000
		if (!isScrollingAttemptStarted) {
			scrollContainer(offset)
			scrollSlider(offset)
		}
	}, [time, isScrollingAttemptStarted])

	useEffect(() => {
		if (rowsHolderRef.current) {
			const destroyContainerListener = createScrollStopListener(rowsHolderRef.current, (event) => {
				if (isScrollingAttemptStarted) {
					const target = event.target as HTMLElement
					const containerScrollLeft = target.scrollLeft
					const positionClicked = containerScrollLeft - sliderLeftOffset
					const timeReached = (positionClicked / pxPerMs + 1000) / 1000

					if (onScrollToTime) {
						onScrollToTime(timeReached)
					}
				}
			})

			return () => destroyContainerListener()
		}

		return () => {}
	}, [isScrollingAttemptStarted])

	useEffect(() => {
		if (rowsHolderRef.current) {
			setSliderElement?.(rowsHolderRef.current)
		}
	}, [rowsHolderRef.current])

	const handleRowClick = (e: React.MouseEvent<HTMLElement>, handleAdd: ((time: number) => void) | undefined) => {
		if (!rowsHolderRef.current || !handleAdd) return

		e.stopPropagation()
		// X (from left) position of the container in the screen
		const { x: containerXOffset } = rowsHolderRef.current.getBoundingClientRect()
		// How much has been scrolled from the left
		const containerScrollLeft = rowsHolderRef.current.scrollLeft
		// X (from left) position of the clicked element in the screen (not including scrolling)
		const clickLeftOffset = e.clientX - containerXOffset
		// X position of the clicked element
		const positionClicked = containerScrollLeft + clickLeftOffset - sliderLeftOffset
		// Time in ms of the clicked element
		const timeClicked = positionClicked / pxPerMs
		if (timeClicked >= 0) handleAdd(timeClicked)
	}

	return (
		<Box position="relative" className={styles.container}>
			<Box className={styles.timeIndicator} />
			<Box>
				<Box className={styles.waveformIcon}>
					{waveformProps && (
						<Box display="flex" className={styles.timeIcon}>
							<AccessTime />
						</Box>
					)}
					{rows.map(({ icon }, index) => (
						<Box key={index} className={clsx(styles.iconRow, styles.icon)}>
							{icon}
						</Box>
					))}
				</Box>
			</Box>
			<Box className={styles.scrollingContainer}>
				<Box display="flex" className={styles.time}>
					<TimeSlide
						ref={sliderRef}
						miliseconds={songDuration}
						pxPerMs={pxPerMs}
						sliderLeftOffset={sliderLeftOffset}
						showTimeEvery={showTimeEvery}
						onScroll={scrollContainer}
					/>
				</Box>
				<Box
					className={styles.rowsContainer}
					{...rowsContainerProps}
					{...{ ref: rowsHolderRef }}
					onMouseOver={() => setIsScrollingAttemptStarted(true)}
					onMouseLeave={() => setIsScrollingAttemptStarted(false)}
					onScroll={(event) => {
						scrollSlider((event.target as HTMLElement).scrollLeft)
					}}>
					{waveformProps && (
						<Box
							display="flex"
							className={clsx(styles.time, styles.waveform)}
							width={(waveformProps?.mediaLength || 0) * 1000 * pxPerMs}>
							<Waveform {...waveformProps} />
						</Box>
					)}
					<Box display="flex">
						<Box
							className={styles.rowsInnerContainer}
							minWidth={sliderLeftOffset + songDuration * pxPerMs}>
							{rows.map(({ content, handleAdd }, index) => (
								<Fragment key={index}>
									<Box
										className={clsx(styles.row, styles.content, styles.contentRow)}
										onClick={(e) => handleRowClick(e, handleAdd)}>
										{content.map((item, index) => (
											<Box
												key={index}
												className={styles.contentItem}
												left={sliderLeftOffset + item.time * pxPerMs}>
												{item.component}
											</Box>
										))}
									</Box>
									<Divider className={styles.divider} />
								</Fragment>
							))}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

// Usage example:
// This example will be removed in the future, but it's useful to have it here for now

/*

	enum SliderItemTypeEnum {
		Lyric,
		Chord,
		Break
	}

	const [lyrics, setLyrics] = useState(
		Array(100)
			.fill('word')
			.map((_, index) => ({ time: index * 600, label: 'Word' + index, id: index }))
	)
	const [chords, setChords] = useState(
		Array(50)
			.fill('word')
			.map((_, index) => ({ time: index * 600, label: 'C' + index, id: index }))
	)
	const [breaks, setBreaks] = useState(
		Array(20)
			.fill('word')
			.map((_, index) => ({ time: index * 2000, label: 'Break', id: index }))
	)
	const handleTextChange = (text: string, lyricId: number) => {
		const newLyrics = [...lyrics]
		const currentLyric = newLyrics.find((i) => i.id === lyricId)
		if (currentLyric) currentLyric.label = text
		setLyrics(newLyrics)
	}
	const handlePositionChange = (xPos: number, lyricId: number, lyricTime: number, type: SliderItemTypeEnum) => {
		if (type === SliderItemTypeEnum.Lyric) {
			const newLyrics = [...lyrics]
			const currentLyric = newLyrics.find((i) => i.id === lyricId)
			if (currentLyric) currentLyric.time = lyricTime + xPos / 0.2
			setLyrics(newLyrics)
		}
		if (type === SliderItemTypeEnum.Chord) {
			const newChords = [...chords]
			const currentChord = newChords.find((i) => i.id === lyricId)
			if (currentChord) currentChord.time = lyricTime + xPos / 0.2
			setChords(newChords)
		}
		if (type === SliderItemTypeEnum.Break) {
			const newBreaks = [...breaks]
			const currentBreak = newBreaks.find((i) => i.id === lyricId)
			if (currentBreak) currentBreak.time = lyricTime + xPos / 0.2
			setBreaks(newBreaks)
		}
	}

	const removeItem = (itemId: number, type: SliderItemTypeEnum) => {
		if (type === SliderItemTypeEnum.Lyric) {
			const copy = [...lyrics.filter((i) => i.id !== itemId)]
			setLyrics(copy)
		}
		if (type === SliderItemTypeEnum.Chord) {
			const copy = [...chords.filter((i) => i.id !== itemId)]
			setChords(copy)
		}
		if (type === SliderItemTypeEnum.Break) {
			const copy = [...breaks.filter((i) => i.id !== itemId)]
			setBreaks(copy)
		}
	}

	const addItem = (time: number, type: SliderItemTypeEnum) => {
		if (type === SliderItemTypeEnum.Lyric)
			setLyrics([...lyrics, { time, label: 'Word' + lyrics.length, id: lyrics.length }])
		if (type === SliderItemTypeEnum.Chord)
			setChords([...chords, { time, label: 'C' + chords.length, id: chords.length }])
		if (type === SliderItemTypeEnum.Break) setBreaks([...breaks, { time, label: 'Break', id: breaks.length }])
	}

	const rowHeight = 100
	// In ms
	// In px
	const sliderLeftOffset = 200
	const pxPerMs = 0.2
	const songSrc = 'https://wurrly-refactor-assets-dev.wurrlyedu.com/wurrlies/EW/av/nq/25/JbA0EBgAzL.wav'
	// Duration of the song in seconds, using 31 as the test wav file has a duratio of 93 seconds
	const songDurationSeconds = 93
	const songDuration = songDurationSeconds * 1000

	const [sliderElement, setSliderElement] = useState<HTMLDivElement | undefined>(undefined)

	const slider = (
		<TimeSlider
			songDuration={songDuration}
			sliderLeftOffset={sliderLeftOffset}
			pxPerMs={pxPerMs}
			rowHeight={rowHeight}
			setSliderElement={setSliderElement}
			waveformProps={{
				fileSrc: songSrc,
				mediaLength: songDurationSeconds + 1
			}}
			rows={[
				{
					icon: <LibraryMusicIcon style={{ width: 30, height: 30 }} />,
					handleAdd: (time: number) => addItem(time, SliderItemTypeEnum.Lyric),
					content: lyrics.map((lyric) => {
						return {
							time: lyric.time,
							component: (
								<DraggableMenuButton
									key={lyric.id}
									label={lyric.label}
									inputProps={{
										updateText: (text: string) => handleTextChange(text, lyric.id)
									}}
									onPositionUpdated={(xPos: number) =>
										handlePositionChange(xPos, lyric.id, lyric.time, SliderItemTypeEnum.Lyric)
									}
									draggableProps={{
										bounds: {
											right: songDuration * pxPerMs - lyric.time * pxPerMs - 70,
											left: -lyric.time * pxPerMs
										}
									}}
									resetPositionOnEnd>
									<MenuItem onClick={() => removeItem(lyric.id, SliderItemTypeEnum.Lyric)}>
										Delete Lyric
									</MenuItem>
								</DraggableMenuButton>
							)
						}
					})
				},
				{
					icon: <QueueMusicIcon style={{ width: 30, height: 30 }} />,
					handleAdd: (time: number) => addItem(time, SliderItemTypeEnum.Chord),
					content: chords.map((i) => ({
						time: i.time,
						component: (
							<DraggableMenuButton
								key={i.id}
								label={i.label}
								onPositionUpdated={(xPos: number) =>
									handlePositionChange(xPos, i.id, i.time, SliderItemTypeEnum.Chord)
								}
								draggableProps={{
									bounds: {
										right: songDuration * pxPerMs - i.time * pxPerMs - 70,
										left: -i.time * pxPerMs
									}
								}}
								resetPositionOnEnd>
								<MenuItem
									onClick={() => {
										// TODO: Implement chord edit logic
									}}>
									Edit Chord
								</MenuItem>
								<MenuItem onClick={() => removeItem(i.id, SliderItemTypeEnum.Chord)}>
									Delete Chord
								</MenuItem>
							</DraggableMenuButton>
						)
					}))
				},
				{
					icon: <LineBreakIcon style={{ width: 30, height: 30 }} />,
					handleAdd: (time: number) => addItem(time, SliderItemTypeEnum.Break),
					content: breaks.map((i) => ({
						time: i.time,
						component: (
							<DraggableMenuButton
								key={i.id}
								label={<LineBreakIcon width={15} height={15} />}
								onPositionUpdated={(xPos: number) =>
									handlePositionChange(xPos, i.id, i.time, SliderItemTypeEnum.Break)
								}
								draggableProps={{
									bounds: {
										right: songDuration * pxPerMs - i.time * pxPerMs - 70,
										left: -i.time * pxPerMs
									}
								}}
								resetPositionOnEnd>
								<MenuItem onClick={() => removeItem(i.id, SliderItemTypeEnum.Break)}>
									Delete Break
								</MenuItem>
							</DraggableMenuButton>
						)
					}))
				}
			]}
		/>
	)
*/
