import { makeStyles } from '@material-ui/core/styles'

export const useInstrumentNotesPanelStyles = makeStyles(() => ({
	slider: {
		position: 'absolute',
		top: '-10px !important',
		height: 270,
		width: 4,
		'& .MuiSlider-thumb::after': {
			width: 2,
			height: '270px',
			background: '#F2BE20',
			borderRadius: '2px',
			transform: 'matrix(1, 0, 0, -1, 0, 0)',
			top: '0px',
			left: 3,
			right: '0px',
			bottom: '0px'
		},
		'& .MuiSlider-track': {
			backgroundColor: 'transparent'
		},
		'& .MuiSlider-rail': {
			backgroundColor: 'transparent'
		},
		'& .Mui-disabled': {
			color: '#F2BE20',
			borderRadius: 2,
			height: 15
		}
	},
	lyric: {
		position: 'absolute',
		fontFamily: 'Roboto',
		textTransform: 'uppercase',
		fontSize: '15px',
		fontWeight: 'bolder',
		lineHeight: '18px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '8px 10px',
		height: '42px',
		boxShadow:
			'0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
		borderRadius: '4px'
	},
	wrapper: {
		transition: 'ease 1s',
		height: '100%'
	},
	container: {
		position: 'absolute',
		left: '50%',
		bottom: '40px',
		transform: 'translate(-50%,0)',
		overflow: 'hidden',
		width: '90%',
		height: '270px',
		background: '#FFFFFF',
		border: '1px solid #DDDDDD',
		opacity: 0.9,
		zIndex: 10
	}
}))
