import React, { useState } from 'react'

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { queryFavoriteLessons, queryFavoriteSongs, queryFavoriteTips } from 'src/scenes/Teacher/queries'

import { PagesProps } from '../../../../@types'
import { DoubleMusicNoteIcon, LessonsIcon, VideoIcon } from '../../../../assets/icons/CustomIcons'
import {
	AccordionWrapper,
	ImageButton,
	ListLessons,
	ListSongs,
	ListVideos,
	MainTitle,
	SelectSort
} from '../../../../components'
import { SeeAllBtnTooltip } from '../../../../components/SeeAllTooltip'
import { Lesson_Plan, Song, Tip } from '../../../../graphql/autogenerate/schemas'
import { Pages } from '../../../../routes/teacherPages'
import { SortOrder, TypeEnum } from '../../../../utils'
import {
	DEFAULT_CLASS_LESSON_IMG,
	DEFAULT_CLASS_SONG_IMG,
	DEFAULT_CLASS_VIDEOS_IMG,
	MAX_FAVOURITE_ITEMS
} from '../../../../utils/constants'
import { useGetFavorites } from './hooks'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btn: {
			pointerEvents: 'none',
			backgroundColor: theme.palette.common.white,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		},
		banner: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '14/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		}
	})
)

export const MyFavorites = ({ page, teacherId }: PagesProps) => {
	const classes = useStyles()
	const [sortSongs, setSortSongs] = useState(SortOrder.Up)
	const [sortTips, setSortTips] = useState(SortOrder.Up)
	const [sortLessons, setSortLessons] = useState(SortOrder.Up)

	const [limitSongs, setLimitSongs] = useState(MAX_FAVOURITE_ITEMS)
	const [limitTips, setLimitTips] = useState(MAX_FAVOURITE_ITEMS)
	const [limitLessons, setLimitLessons] = useState(MAX_FAVOURITE_ITEMS)

	const [songs, loadingSongs, totalSongs] = useGetFavorites<Song>(
		queryFavoriteSongs,
		teacherId,
		limitSongs,
		sortSongs,
		TypeEnum.Song
	)

	const [tips, loadingTips, totalTips] = useGetFavorites<Tip>(
		queryFavoriteTips,
		teacherId,
		limitTips,
		sortTips,
		TypeEnum.Video
	)

	const [lessons, loadingLessons, totalLessons] = useGetFavorites<Lesson_Plan>(
		queryFavoriteLessons,
		teacherId,
		limitLessons,
		sortLessons,
		TypeEnum.Lesson
	)

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		setSort: React.Dispatch<React.SetStateAction<SortOrder>>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const addMoreLimit = (limit: number, setLimit: React.Dispatch<React.SetStateAction<number>>) => {
		setLimit(limit + MAX_FAVOURITE_ITEMS)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Here are the Songs, Videos & Lessons that you've Favorited."
			/>

			{loadingSongs || loadingTips || loadingLessons ? (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			) : songs.length > 0 || tips.length > 0 || lessons.length > 0 ? (
				<Box pt={3}>
					{songs.length > 0 && (
						<AccordionWrapper
							defaultExpanded
							summary={
								<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
									<Typography variant="h6">
										<b>Song Favorites ({songs.filter(Boolean).length})</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />

									<SelectSort
										cyKey="Songs"
										showRecent
										value={sortSongs}
										onChange={(e) => handleSort(e, setSortSongs)}
									/>
								</Box>
							}
							details={
								songs && (
									<ListSongs
										teacherId={teacherId}
										allTranspositions
										lessonsButton
										spacing={1.5}
										data={songs.filter(Boolean)}
									/>
								)
							}
							actions={
								songs.length < totalSongs && (
									<SeeAllBtnTooltip
										canFetchMore={songs.length < totalSongs}
										name="songs"
										onClick={() => addMoreLimit(limitSongs, setLimitSongs)}
									/>
								)
							}
						/>
					)}

					{tips.length > 0 && (
						<AccordionWrapper
							defaultExpanded
							summary={
								<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
									<Typography variant="h6">
										<b>Video Favorites ({tips.filter(Boolean).length})</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />

									<SelectSort
										cyKey="Tips"
										showRecent
										value={sortTips}
										onChange={(e) => handleSort(e, setSortTips)}
									/>
								</Box>
							}
							details={
								tips && (
									<ListVideos teacherId={teacherId} spacing={1.5} data={tips.filter(Boolean)} />
								)
							}
							actions={
								tips.length < totalTips && (
									<SeeAllBtnTooltip
										canFetchMore={tips.length < totalTips}
										name="videos"
										onClick={() => addMoreLimit(limitTips, setLimitTips)}
									/>
								)
							}
						/>
					)}

					{lessons.length > 0 && (
						<AccordionWrapper
							defaultExpanded
							summary={
								<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
									<Typography variant="h6">
										<b>Lesson Favorites ({lessons.filter(Boolean).length})</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />

									<SelectSort
										cyKey="Lessons"
										showRecent
										value={sortLessons}
										onChange={(e) => handleSort(e, setSortLessons)}
									/>
								</Box>
							}
							details={
								lessons && (
									<ListLessons
										teacherId={teacherId}
										spacing={1.5}
										data={lessons.filter(Boolean)}
									/>
								)
							}
							actions={
								lessons.length < totalLessons && (
									<SeeAllBtnTooltip
										canFetchMore={lessons.length < totalLessons}
										name="lessons"
										onClick={() => addMoreLimit(limitLessons, setLimitLessons)}
									/>
								)
							}
						/>
					)}
				</Box>
			) : (
				<Box>
					<Divider />
					<Box my={4} textAlign="center">
						<Typography variant="h6" color="textSecondary">
							You haven't Favorited any content yet.
						</Typography>
						<Typography color="textSecondary">
							It's easy to find your next Favorited by exploring the content below.
						</Typography>
					</Box>
					<Divider />
				</Box>
			)}

			{(lessons?.length <= 0 || songs?.length <= 0 || tips?.length <= 0) && (
				<Box pt={3} pb={2}>
					<Typography variant="h6">
						<b>Explore Content</b>
					</Typography>
				</Box>
			)}
			<Grid container spacing={3}>
				{lessons?.length <= 0 && (
					<Grid item xs={12} sm={12} md={6}>
						<ImageButton
							className={classes.banner}
							titleHeight={{ xs: 232 }}
							backgroundImageUrl={DEFAULT_CLASS_LESSON_IMG}
							title={<Typography style={{ fontSize: 28 }}>Find Lessons</Typography>}
							titlePosition="initial"
							element={
								<Button className={classes.btn} startIcon={<LessonsIcon />}>
									{Pages.Lessons.name}
								</Button>
							}
							elementPosition={{ top: 'auto', right: 16, left: 'auto', bottom: 16 }}
							linkTo={Pages.Lessons.path}
						/>
					</Grid>
				)}
				{songs?.length <= 0 && (
					<Grid item xs={12} sm={12} md={6}>
						<ImageButton
							className={classes.banner}
							titleHeight={{ xs: 232 }}
							backgroundImageUrl={DEFAULT_CLASS_SONG_IMG}
							// backgroundImageUrl={} TODO: put img instead color
							title={<Typography style={{ fontSize: 28 }}>Discover Music</Typography>}
							titlePosition="initial"
							element={
								<Button className={classes.btn} startIcon={<DoubleMusicNoteIcon />}>
									{Pages.MusicCatalog.name}
								</Button>
							}
							elementPosition={{ top: 'auto', right: 16, left: 'auto', bottom: 16 }}
							linkTo={Pages.MusicCatalog.path}
						/>
					</Grid>
				)}
				{tips?.length <= 0 && (
					<Grid item xs={12} sm={12} md={6}>
						<ImageButton
							className={classes.banner}
							backgroundImageUrl={DEFAULT_CLASS_VIDEOS_IMG}
							titleHeight={{ xs: 232 }}
							// backgroundImageUrl={} TODO: put img instead color
							title={<Typography style={{ fontSize: 28 }}>Browse Videos</Typography>}
							titlePosition="initial"
							element={
								<Button className={classes.btn} startIcon={<VideoIcon />}>
									{Pages.Videos.name}
								</Button>
							}
							elementPosition={{ top: 'auto', right: 16, left: 'auto', bottom: 16 }}
							linkTo={Pages.Videos.path}
						/>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}
