import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AddToClassDialog, InfoDialog } from 'src/components'
import { useSetInsertPlaylistToClassesMutation } from 'src/graphql/autogenerate/hooks'
import { Class, Class_Playlist_Insert_Input, Playlist } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

type AddPlaylistToClassProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	playlist?: Playlist
}
export const AddPlaylistToClass = ({ playlist, isOpen, setIsOpen }: AddPlaylistToClassProps) => {
	const history = useHistory()
	const [isErrorOpen, setIsErrorOpen] = useState(false)
	const [playlistId, setPlaylistId] = useState<number | undefined>()
	const [itemClasses, setItemClasses] = useState<Class[]>([])
	const [insertPlaylistToClasses] = useSetInsertPlaylistToClassesMutation()
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		return () => {
			setPlaylistId(undefined)
			setIsOpen(false)
			setIsErrorOpen(false)
			setItemClasses([])
		}
	}, [])

	useEffect(() => {
		if (!playlistId) setIsOpen(false)
	}, [playlistId])

	useEffect(() => {
		if (isOpen && playlist) {
			setPlaylistId(playlist.playlist_id)
			setItemClasses(playlist.class_playlists?.map((item) => item.class) || [])
		} else if (isOpen && !playlist) {
			setIsErrorOpen(true)
		}
	}, [isOpen])

	return (
		<Box>
			<InfoDialog
				open={isErrorOpen}
				onClose={() => {
					setIsErrorOpen(false)
					setIsOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsErrorOpen(false)
					setIsOpen(false)
				}}
			/>

			<AddToClassDialog
				isOpen={!!playlistId}
				setIsOpen={setPlaylistId}
				setSearchText={setSearchText}
				searchText={searchText}
				itemClasses={itemClasses}
				itemName="Playlist"
				title="Save Playlist to Class"
				description="This will save the Playlist so that you can view and teach right from your Class page. This content will be visible to the students that are in the Class."
				onConfirm={async (selectedClasses) => {
					const array = [...selectedClasses]
					const classes = array.map((cls) => {
						return {
							playlist_id: playlistId,
							class_id: cls.class_id
						} as Class_Playlist_Insert_Input
					})
					if (classes && classes.length) {
						await insertPlaylistToClasses({
							variables: { classes },
							update: (cache, { data }) => {
								const classesToUpdateInCache = data?.insert_class_playlist?.returning
								if (!classesToUpdateInCache) return

								let identify
								classesToUpdateInCache.forEach((cls) => {
									identify = cache.identify(cls.class)

									cache.evict({
										id: identify,
										fieldName: 'class_playlists'
									})
									cache.evict({
										id: identify,
										fieldName: 'class_playlists_aggregate'
									})
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'playlist'
								})
							}
						})
					}
				}}
				onSuccess={{ discardLabel: 'Return to Playlist', confirmLabel: 'View Classes' }}
				onDone={() => history.push(buildRouteParameters(Pages.Classes))}
			/>
		</Box>
	)
}
