import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { getCssFilter } from 'src/components/Studio/Preview/ColorFilters'
import { Filter_Color_Name_Enum } from 'src/graphql/autogenerate/schemas'

type UseVideoPreviewStylesProps = {
	selectedColorFilter?: Filter_Color_Name_Enum
	isMirrored?: boolean
}
export const useVideoPreviewStyles = makeStyles<Theme, UseVideoPreviewStylesProps>(() =>
	createStyles({
		videoPlayer: {
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'relative',
			backgroundColor: 'white'
		},
		video: {
			width: '100%',
			height: '100%',
			objectFit: 'fill',
			filter: ({ selectedColorFilter }) => getCssFilter(selectedColorFilter),
			transform: ({ isMirrored }) => (isMirrored ? 'scaleX(-1)' : 'none')
		},
		frame: {
			position: 'absolute',
			top: '0px',
			left: '0px',
			width: '100%',
			height: '100%',
			zIndex: 1,
			'&.MuiButton-root': {
				width: '67px ',
				height: '57px '
			},
			'&.MuiButton-root.selected': {
				backgroundColor: '#FFEFD3'
			}
		}
	})
)

export default useVideoPreviewStyles
