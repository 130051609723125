import { Fragment, useState } from 'react'

import { Box, CircularProgress, FilledInput, InputAdornment, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { useFormik } from 'formik'
import { noop } from 'lodash'
import { PromotionCodeInput } from 'src/components/Inputs/PromotionCodeInput/PromotionCodeInput'
import { useCreateProductSubscriptionMutation } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { usePriceDiscount } from 'src/hooks/usePriceDiscount'
import * as yup from 'yup'

import { Price_Type_Enum, Product_Name_Enum } from '../../graphql/autogenerate/schemas'
import { masterStyles } from '../../styles/master-styles.deprecated'
import { getPriceInDollars } from '../../utils'
import { CardInput } from '../Inputs/CardInput'
import { TextInput } from '../Inputs/TextInput'
import { BaseDialog, BaseDialogProps } from './BaseDialog/BaseDialog'
import { InfoDialog } from './InfoDialog'

export type SelectPeriodPaymentType = {
	priceType: Price_Type_Enum // monthly or annual
	price: number // product price
	productId: number
	productName: Product_Name_Enum // module or partner
	stripePriceId: string
	stripeProductId: string
	productNameDisplay: string
}
type Props = {
	selectedProduct: SelectPeriodPaymentType | undefined
	onSuccess: () => void
	close: () => void
	table: string
}

const validationSchema = yup.object({
	cardHolder: yup.string().required('Name is required').ensure(),
	promotion_code: yup.string().ensure().nullable(),
	promotion_code_is_valid: yup.boolean().when('promotion_code', (promotion_code, schema) => {
		if (!promotion_code) return schema

		return schema.equals([true], 'Promotion code is invalid')
	})
})

export const PaymentDialogForProduct = ({
	selectedProduct,
	onSuccess,
	close,
	table,
	...ext
}: Props & Omit<BaseDialogProps, 'onClose'>) => {
	// const history = useHistory()
	const stripe = useStripe()
	const elements = useElements()
	const { teacherData: teacher } = useLoginContext()
	const [cardComplete, setCardComplete] = useState(false)
	const [error, setError] = useState<string>('')
	const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)

	const [createProductSubscription] = useCreateProductSubscriptionMutation()

	const formik = useFormik({
		initialValues: {
			cardHolder: teacher?.name_first + ' ' + teacher?.name_last,
			promotion_code: '',
			promotion_code_is_valid: undefined,
			discount_total: 0
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async (variables, { resetForm }) => {
			try {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				const clientSecret = await getClientSecret()
				if (!stripe || !elements) return
				if (!cardComplete) return
				if (!clientSecret) return

				// reset error
				setError('')

				const cardElement = elements.getElement(CardNumberElement) as StripeCardNumberElement
				const payload = await stripe.confirmCardPayment(clientSecret, {
					payment_method: {
						card: cardElement,
						billing_details: {
							name: variables.cardHolder
						}
					}
				})

				if (payload.error) {
					setError(`Payment failed ${payload.error.message}`)
				} else {
					// reseting the form
					resetForm()
					close()
					setIsSuccessPopupOpen(true)
				}
			} catch (error) {
				console.error({ error })
				if (error instanceof Error) setError(error?.message)
			}
		}
	})

	const { handleDiscountAmount, handleDiscountPercentage, totalPrice } = usePriceDiscount(selectedProduct?.price)

	const getClientSecret = async () => {
		if (!selectedProduct) return null
		let clientSecret: string | null = null

		try {
			const res = await createProductSubscription({
				variables: {
					subscription: {
						teacherId: teacher?.teacher_id,
						productId: selectedProduct?.productId,
						productName: selectedProduct?.productName,
						stripePriceId: selectedProduct?.stripePriceId,
						stripeProductId: selectedProduct?.stripeProductId,
						promotionCode: formik.values.promotion_code || null
					}
				}
			})

			clientSecret = res.data?.createProductSubscription?.clientSecret || null

			if (!clientSecret) {
				setError('Something went wrong. Please try again later.')

				return null
			}

			return clientSecret
		} catch (error) {
			console.error({ error })
			if (error instanceof Error) setError(error?.message)

			return null
		}
	}

	const handleSuccessPopupClose = () => {
		setIsSuccessPopupOpen(false)
		window.location.reload()
	}
	const handleSuccessPopupConfirm = () => {
		setIsSuccessPopupOpen(false)
		onSuccess()
		window.location.reload()
	}

	// TODO: redirect depending on current page
	// const handleGoToProduct = () => {
	// 	setIsSuccessPopupOpen(false)

	// 	if (selectedProduct?.productName === Product_Name_Enum.Module)
	// 		history.push(buildRouteParameters(Pages.ModuleDetails, { moduleId: selectedProduct?.productId }))
	// 	else if (selectedProduct?.productName === Product_Name_Enum.Partner)
	// 		history.push(buildRouteParameters(Pages.PartnerPage, { partnerId: selectedProduct?.productId }))
	// }

	return (
		<Fragment>
			<BaseDialog
				dividers={false}
				onClose={noop}
				header={
					<Fragment>
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Confirm Your Payment Details
						</Typography>
						<Typography align="center" variant="body2" style={{ padding: 14, fontWeight: 'normal' }}>
							Please confirm your payment details in order to add this {selectedProduct?.productName}
						</Typography>
					</Fragment>
				}
				confirmLabel={
					formik.isSubmitting ? <CircularProgress variant="indeterminate" size={20} /> : 'Confirm'
				}
				onConfirm={() => {
					formik.submitForm()
				}}
				onConfirmProps={{
					fullWidth: true
				}}
				isConfirmDisabled={!formik.isValid || formik.isSubmitting || !cardComplete}
				discardLabel="Go Back"
				onDiscardProps={{
					disabled: formik.isSubmitting,
					fullWidth: true
				}}
				body={
					<Box padding="0px 10px">
						<form style={masterStyles.formContainerNoPadding} onSubmit={formik.handleSubmit}>
							<TextInput
								name="Cardholder Name"
								isRequired
								value={formik.values.cardHolder}
								onChange={(e) => formik.setFieldValue('cardHolder', e.target.value)}
								error={formik.touched.cardHolder && Boolean(formik.errors.cardHolder)}
								helperText={formik.touched.cardHolder && formik.errors.cardHolder}
								placeholder="Enter Name"
							/>
							<CardInput setCardComplete={setCardComplete} />
							<PromotionCodeInput
								isValid={formik.values.promotion_code_is_valid}
								onChangeDiscountAmount={handleDiscountAmount}
								onChangeDiscountPercentage={handleDiscountPercentage}
								onChangeValid={formik.setFieldValue}
								onChange={formik.setFieldValue}
								onFocus={() => formik.setFieldTouched('promotion_code', true)}
								value={formik.values.promotion_code}
								helperText={formik.values.promotion_code && formik.errors.promotion_code_is_valid}
								error={
									formik.touched.promotion_code && Boolean(formik.errors.promotion_code_is_valid)
								}
							/>
							<FilledInput
								inputProps={{
									style: {
										padding: '18.5px'
									}
								}}
								fullWidth
								disabled
								value={`${selectedProduct?.productNameDisplay}`}
								disableUnderline={true}
								endAdornment={
									<InputAdornment position="end">
										${getPriceInDollars(totalPrice)} {selectedProduct?.priceType}
									</InputAdornment>
								}
							/>
						</form>

						{!!error && <ErrorMessage error={error} />}
					</Box>
				}
				{...ext}
			/>
			<InfoDialog
				open={isSuccessPopupOpen}
				onClose={handleSuccessPopupClose}
				title={
					<Typography align="center" style={{ fontWeight: 'bold', fontSize: 32 }}>
						{table} Purchased {'\n'}
						Successfully!
					</Typography>
				}
				body="You can now add this resource to your classes. You can change your payment details from your Settings at any time."
				// discardLabel={`Go to ${table}`}
				// onDiscard={handleGoToProduct}
				confirmLabel="Confirm"
				confirmProps={{ color: 'secondary' }}
				onConfirm={handleSuccessPopupConfirm}
				icon={<Check />}
			/>
		</Fragment>
	)
}

const ErrorMessage = (props: { error: string }) => {
	return (
		<Box style={{ display: 'flex', flexDirection: 'row' }}>
			<CloseIcon color="error" fontSize="small" style={{ marginRight: 5 }} />
			<Typography color="error" style={{ fontSize: 14 }}>
				{props.error}
			</Typography>
		</Box>
	)
}
