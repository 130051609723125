import { useState } from 'react'

import { Box, createStyles, fade, makeStyles, Paper, Theme, Typography, useTheme } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { Pen } from 'src/assets/icons'
import { MainTitle } from 'src/components'
import { buildBreadCrumbs, buildImagePath } from 'src/utils'

import { useStudentClassDetailsContext } from '../hooks/useClassDetails'
import { EditWallpaper } from './EditWallpaper'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainTitle: {
			color: '#2E2642',
			opacity: '0.7',
			padding: '0px 29px'
		},
		banner: {
			color: theme.palette.text.secondary,
			padding: '172px 29px',
			height: 357,
			width: 329
		},
		itemContainer: {
			'&& :hover': {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.text.primary
			},
			cursor: 'pointer'
		}
	})
)
export const HomeTitle = ({ page }: PagesProps) => {
	const styles = useStyles()
	const theme = useTheme()
	const [isEditWallpaperOpen, setIsEditWallpaperOpen] = useState(false)

	const { stageName, cover } = useStudentClassDetailsContext()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1
			}
		]
	})

	return (
		<Box
			p={{ xs: 3, sm: 10, md: 15.5 }}
			style={{
				backgroundImage: `URL(${buildImagePath(cover)})`,
				backgroundColor: fade(theme.palette.primary.dark, 0.8),
				backgroundSize: 'cover',
				width: '100%',
				height: '452px'
			}}>
			<Box position="absolute" marginTop={-16}>
				<Paper className={styles.banner}>
					<Box
						display="flex"
						flexDirection="column"
						textAlign="left"
						style={{
							fontWeight: 500,
							fontSize: '56.92px',
							color: 'black'
						}}>
						{`Welcome,`}
						<Box
							maxWidth={'329px'}
							style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
							stageName ?? 'student'
						}`}</Box>
					</Box>
				</Paper>
			</Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				breadcrumbPageStyle={styles.mainTitle}
				title={<Typography variant="h3" style={{ color: theme.palette.background.paper }}></Typography>}
				rightActions={[
					{
						variant: 'contained',
						color: 'default',
						startIcon: <img src={Pen} />,
						onClick: () => {
							setIsEditWallpaperOpen(true)
						},
						style: { marginTop: '-17em' }
					}
				]}
			/>
			<EditWallpaper isOpen={isEditWallpaperOpen} setIsOpen={setIsEditWallpaperOpen} />
		</Box>
	)
}
