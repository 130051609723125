import { createStyles, makeStyles } from '@material-ui/core'

export const useSubmissionsHeaderStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: theme.spacing(2)
		}
	})
)

export default useSubmissionsHeaderStyles
