import React from 'react'

import { Typography, Button, PropTypes } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { DoubleMusicNoteIcon, LessonsIcon, VideoIcon } from '../../../../../assets/icons/CustomIcons'

const icons = {
	musicNote: DoubleMusicNoteIcon,
	videos: VideoIcon,
	notebook: LessonsIcon,
	add: AddCircleIcon
}
type IconButtonProps = {
	text: string
	icon?: keyof typeof icons
	color?: PropTypes.Color
	style?: React.CSSProperties
}

export const IconButton = ({ text, icon, color = 'default', style }: IconButtonProps) => {
	const Icon = icon ? icons[icon] : null

	return (
		<Button
			variant="contained"
			color={color}
			startIcon={Icon ? <Icon /> : <></>}
			style={{ height: '2.5em', ...style }}>
			<Typography variant="body2">
				<strong>{text}</strong>
			</Typography>
		</Button>
	)
}
