import { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { PagesProps } from 'src/@types'
import { DeleteItemDialog, MainTitle } from 'src/components'
import { useDeleteWurrlyMutation, useGetClassTeacherByPkQuery } from 'src/graphql/autogenerate/hooks'
import { buildBreadCrumbs, slugToName } from 'src/utils'

import { useWurrliesDetailsContext } from '../useWurrliesDetails'

export const WurrliesDetailsTitle = ({ page }: PagesProps) => {
	const { teacherId, classId, wurrlyDetails, classWurrliesDetailsLoading } = useWurrliesDetailsContext()
	const { data: classData } = useGetClassTeacherByPkQuery({ variables: { teacherId, classId } })

	const [wurrlyDetailIdToDelete, setWurrlyDetailIdToDelete] = useState<number>()
	const [deleteWurrly] = useDeleteWurrlyMutation()

	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
			},
			{
				index: 2,
				overrideName: 'Wurrlies'
			},
			{
				index: 3,
				overrideName: 'Review'
			}
		],
		params: {
			classId
		}
	})

	return (
		<Box>
			{classWurrliesDetailsLoading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!classWurrliesDetailsLoading && (
				<MainTitle
					breadcrumbs={breadCrumbs}
					title="Class Wurrly Review"
					description="These are the details of the Wurrlies that your Students have submitted to this Class."
					rightActions={[
						{
							style: {
								backgroundColor: '#f44336',
								color: 'white',
								height: 56,
								padding: '0px 30px'
							},
							startIcon: <DeleteIcon />,
							name: 'Delete',
							onClick: () => {
								if (wurrlyDetails) {
									setWurrlyDetailIdToDelete(wurrlyDetails.wurrly_id)
								}
							}
						}
					]}
				/>
			)}
			<DeleteItemDialog
				isOpen={!!wurrlyDetailIdToDelete}
				setIsOpen={setWurrlyDetailIdToDelete}
				onConfirm={async () => {
					if (wurrlyDetailIdToDelete) {
						await deleteWurrly({
							variables: {
								wurrlyId: wurrlyDetailIdToDelete
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_wurrly?.returning[0].class
								if (!classToUpdateInCache) return
								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'wurrlies'
								})
							}
						})
					}
				}}
				itemName="Wurrly"
			/>
		</Box>
	)
}
