import { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { PagesProps } from 'src/@types'
import { DeleteItemDialog } from 'src/components'

import { MainTitle } from '../../../../../../../components/MainTitle'
import { useDeleteClassMutation } from '../../../../../../../graphql/autogenerate/hooks'
import { Pages } from '../../../../../../../routes/teacherPages'
import { buildBreadCrumbs, buildRouteParameters, slugToName } from '../../../../../../../utils'
import { useClassSettingsContext } from '../useClassSettings'

export const ClassSettingsTitle = ({ page }: PagesProps) => {
	const { classId, history, classSettingsData, classSettingsLoading } = useClassSettingsContext()

	const [classIdToDelete, setClassIdToDelete] = useState<number>()
	const [deleteClass] = useDeleteClassMutation()

	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(
					classSettingsData?.teacher_by_pk?.class_teachers[0]?.class
						? classSettingsData.teacher_by_pk?.class_teachers[0].class.title
						: ''
				)
			},
			{
				index: 2,
				overrideName: 'Settings'
			}
		],
		params: {
			classId
		}
	})

	return (
		<Box>
			{classSettingsLoading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!classSettingsLoading && (
				<MainTitle
					breadcrumbs={breadCrumbs}
					title="Class Settings"
					description="View and change any of your Class details below."
					rightActions={[
						{
							style: {
								backgroundColor: '#f44336',
								color: 'white',
								height: 56,
								padding: '0px 30px'
							},
							startIcon: <DeleteIcon />,
							name: 'Delete Class',
							onClick: () => setClassIdToDelete(classId)
						}
					]}
				/>
			)}
			<DeleteItemDialog
				itemName="Class"
				isOpen={!!classIdToDelete}
				setIsOpen={setClassIdToDelete}
				onConfirm={async () => {
					await deleteClass({
						variables: { classId: Number(classIdToDelete) },
						update: (cache, { data }) => {
							const classToUpdateInCache = data?.delete_class?.returning[0]
							if (!classToUpdateInCache) return
							const identify = cache.identify(classToUpdateInCache)
							cache.evict({
								id: identify
							})
						}
					})
				}}
				onDone={() => history.push(buildRouteParameters(Pages.Classes))}
			/>
		</Box>
	)
}
