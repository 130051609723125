import { Box, Divider, Typography } from '@material-ui/core'

import useStyles from './HeaderList.styles'
export const HeaderList = () => {
	const styles = useStyles()

	return (
		<Box className={styles.container}>
			<Box className={styles.containerTitle}>
				<Typography style={{ flex: 1 }} className={styles.title}>
					Date
				</Typography>
				<Typography style={{ flex: 3 }} className={styles.title}>
					Name
				</Typography>
				<Typography style={{ flex: 1 }} className={styles.title}>
					Type
				</Typography>
				<Typography style={{ flex: 1 }} className={styles.title}>
					USD
				</Typography>
			</Box>
			<Divider className={styles.divider} variant="middle" component="li" />
		</Box>
	)
}
