import { Box, Typography, BoxProps, TypographyProps } from '@material-ui/core'

type TitleProps = BoxProps & {
	title: string
	titleProps?: TypographyProps
}

export const Title = ({ title, titleProps, ...props }: TitleProps) => {
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" width="100%" {...props}>
			<Typography variant="h5" {...titleProps}>
				{title}
			</Typography>
		</Box>
	)
}
