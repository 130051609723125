import React from 'react'

import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AssignmentReturned from '@material-ui/icons/AssignmentReturned'
import moment from 'moment'
import { BigBaseButton, FavoriteButton, BaseDialog, BaseLink } from 'src/components'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useUpdateProductivityMutation } from 'src/graphql/autogenerate/hooks'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { getVideoGenres, getCyKey, buildImagePath } from 'src/utils'

const useStyles = makeStyles({
	dialog: {
		'& .MuiDialog-paper': {
			width: '100%'
		},
		'& .MuiDialogContent-root': {
			overflow: 'initial'
		}
	}
})

type VideoPlayerProps = {
	video?: Tip
	classId?: number
	setVideo: (video: Tip | undefined) => void
	onAddtoClass?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	loading: boolean
	favoriteVideo: boolean
	handleFavoriteFn: (isFavorite: boolean, noDialog: boolean) => void
	dontAsk: boolean
}

export const VideoPlayer = ({
	video,
	classId,
	setVideo,
	onAddtoClass,
	loading,
	favoriteVideo,
	handleFavoriteFn,
	dontAsk
}: VideoPlayerProps) => {
	const styles = useStyles()
	const { studentData: student } = useLoginContext()
	const handleClose = () => setVideo(undefined)
	const handleFavorite = () => handleFavoriteFn(favoriteVideo, dontAsk)

	const studentId = student.student_id

	const [upsertProductivity] = useUpdateProductivityMutation()

	const handleOnPlay = () => {
		if (!studentId || !classId) return
		upsertProductivity({
			variables: {
				studentId,
				classId,
				day: Number(moment().format('DD')),
				month: Number(moment().format('MM')),
				year: Number(moment().format('YYYY')),
				videoViews: 1,
				practiceSessions: 0,
				recordingSessions: 0
			}
		})
	}

	return (
		<BaseDialog
			data-cy={getCyKey(VideoPlayer)}
			maxWidth="md"
			className={styles.dialog}
			onClose={handleClose}
			open={!!video}
			dividers={false}
			header={
				<Box>
					<Typography variant="h5">{video?.title}</Typography>
					<Typography variant="body1">{video?.description}</Typography>
					<Typography variant="subtitle1" color="textSecondary">
						{video && getVideoGenres(video)}
					</Typography>
				</Box>
			}
			body={
				<Box>
					<ReactCustomVideoPlayer
						width="575px"
						onPlay={handleOnPlay}
						style={{ margin: 'auto' }}
						light={video?.image_path}
						url={video?.resource_path || ''}
					/>
				</Box>
			}
			rightActions={
				<Box justifyContent="flex-end">
					{video?.asset_path && (
						<BaseLink target="_blank" href={buildImagePath(video?.asset_path)} download>
							<BigBaseButton variant="contained">
								<Tooltip title="Download Learning Resource">
									<AssignmentReturned />
								</Tooltip>
							</BigBaseButton>
						</BaseLink>
					)}
					<FavoriteButton isFavorite={favoriteVideo} onClick={handleFavorite} loading={loading} />
					{onAddtoClass && (
						<BigBaseButton
							variant="contained"
							onClick={onAddtoClass}
							color="primary"
							endIcon={<AddIcon />}>
							<Typography variant="caption">Add to Class</Typography>
						</BigBaseButton>
					)}
				</Box>
			}
			onDiscard={handleClose}
			discardLabel="Close"
		/>
	)
}
