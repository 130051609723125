import { useEffect, useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { NotesIcon } from 'src/assets/icons'
import { FixedBackground, InfoDialog } from 'src/components'
import {
	useGetCleverAccountLazyQuery,
	useGetCleverTokenLazyQuery,
	useGetSignupIntegrationTokenLazyQuery,
	useLoginIntegrationLazyQuery,
	useStudentIntegrationLoginLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useIntegrationContext } from 'src/hooks/useIntegration'
import { useLoginContext } from 'src/hooks/useLogin'
import { LoginPages } from 'src/routes/loginPages'
import { INTEGRATION_LOGIN_ERROR_RENDER } from 'src/utils'

export type CleverSignupState = {
	signupIntegrationCleverToken: string
}

export const CleverLogin = () => {
	const history = useHistory()
	const { cleverInstance } = useIntegrationContext()
	const [code, setCode] = useState('')
	const [token, setToken] = useState('')
	const [errorMessage, setErrorMessage] = useState('An error occured while trying to sing in.')
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const { loginSignIn, setIntegrationName } = useLoginContext()
	const [getCleverToken, { data: tokenData, error: tokenError, loading: tokenLoading }] =
		useGetCleverTokenLazyQuery({
			fetchPolicy: 'no-cache'
		})
	const [
		getCleverAccount,
		{ data: cleverAccountData, error: cleverAccountError, loading: cleverAccountLoading }
	] = useGetCleverAccountLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [
		loginIntegrationQuery,
		{ data: loginIntegrationData, loading: loginIntegrationLoading, error: loginIntegrationError }
	] = useLoginIntegrationLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [
		studentIntegrationLogin,
		{ data: studentIntegrationLoginData, error: studentIntegrationError, loading: studentIntegrationLoading }
	] = useStudentIntegrationLoginLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [
		getSignupIntegrationToken,
		{ data: signupTokenData, error: signupTokenError, loading: signupTokenLoading }
	] = useGetSignupIntegrationTokenLazyQuery({ fetchPolicy: 'no-cache' })

	// Get code
	useEffect(() => {
		const codeURL = new URL(window.location.href)
		const code = codeURL.searchParams.get('code')
		if (code) setCode(code)
	}, [])

	// Get token from code
	useEffect(() => {
		if (code) getCleverToken({ variables: { code } })
	}, [code])

	// Set token state
	useEffect(() => {
		if (tokenData?.getCleverToken) {
			setToken(tokenData?.getCleverToken)
			cleverInstance?.setToken(tokenData?.getCleverToken)
		}
	}, [tokenData])

	// Get clever account from token
	useEffect(() => {
		if (token)
			getCleverAccount({
				variables: {
					token
				}
			})
	}, [token])

	// Get Teacher login from clever account
	useEffect(() => {
		if (cleverAccountLoading) return
		if (!cleverAccountData?.getCleverAccount) return
		const integrationId = cleverAccountData?.getCleverAccount?.id
		const email = cleverAccountData?.getCleverAccount?.email
		const isTeacher = cleverAccountData?.getCleverAccount?.isTeacher
		if (integrationId && email && isTeacher) {
			loginIntegrationQuery({
				variables: { email, integrationId, accessToken: token, isClever: true }
			})
		}
		if (integrationId && !isTeacher) studentIntegrationLogin({ variables: { integrationId } })
	}, [cleverAccountData?.getCleverAccount?.id, cleverAccountLoading])

	// Set session token
	useEffect(() => {
		const token =
			loginIntegrationData?.loginIntegration?.token ||
			studentIntegrationLoginData?.studentLoginIntegration?.token
		if (token) loginSignIn(token)
		if (loginIntegrationData?.loginIntegration?.cleverSignup) setIntegrationName(Integration_Name_Enum.Clever)
		if (
			!token &&
			!loginIntegrationLoading &&
			!studentIntegrationLoading &&
			(loginIntegrationData || studentIntegrationLoginData)
		) {
			setErrorMessage('No wurrly account associated to this clever account')
			setTimeout(() => {
				setIsOnErrorOpen(true)
			}, 500)
		}
	}, [loginIntegrationData, studentIntegrationLoginData, loginIntegrationLoading, studentIntegrationLoading])

	// Refirect to signup with the signupIntegrationToken as state var
	useEffect(() => {
		if (signupTokenLoading) return
		if (signupTokenData?.getSignupIntegrationToken?.token) {
			history.push({
				pathname: LoginPages.Teacher.path,
				state: {
					token: signupTokenData?.getSignupIntegrationToken?.token,
					integrationName: Integration_Name_Enum.Clever
				}
			})
		}
	}, [signupTokenData, signupTokenLoading])

	// Open popup on error
	useEffect(() => {
		if (loginIntegrationLoading || tokenLoading || cleverAccountLoading || studentIntegrationLoading) return
		if (
			tokenError ||
			cleverAccountError ||
			loginIntegrationError ||
			studentIntegrationError ||
			signupTokenError
		) {
			if (
				loginIntegrationError?.message === INTEGRATION_LOGIN_ERROR_RENDER &&
				cleverAccountData?.getCleverAccount?.email &&
				cleverAccountData?.getCleverAccount?.id &&
				tokenData?.getCleverToken
			) {
				getSignupIntegrationToken({
					variables: {
						email: cleverAccountData?.getCleverAccount?.email,
						integrationId: cleverAccountData?.getCleverAccount?.id,
						isClever: true,
						accessToken: tokenData?.getCleverToken
					}
				})
			} else {
				setErrorMessage('An error occured while trying to sing in.')
				setTimeout(() => {
					setIsOnErrorOpen(true)
				}, 500)
			}
		}
	}, [
		tokenError,
		tokenLoading,
		cleverAccountError,
		cleverAccountLoading,
		loginIntegrationError,
		loginIntegrationLoading,
		studentIntegrationError,
		studentIntegrationLoading,
		signupTokenError
	])

	return (
		<FixedBackground>
			<img
				src={NotesIcon}
				style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
			/>
			<Box style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
				<CircularProgress color="secondary" size={40} />
			</Box>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {}}
				icon="x"
				title="Something went wrong"
				body={errorMessage}
				confirmLabel=""
				onConfirm={() => {}}
				confirmProps={{ style: { fontWeight: 'bold' } }}
			/>
		</FixedBackground>
	)
}
