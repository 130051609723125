import { makeStyles } from '@material-ui/core'

export const useAddAssignmentsStyles = makeStyles(() => ({
	titleDescription: {
		fontSize: 18,
		maxWidth: 500
	},
	bottomButton: {
		minWidth: '14em',
		padding: '.5em 3em'
	},
	bottomButtonsBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	stepContainer: {
		margin: '3em 0'
	},
	spinner: {
		marginLeft: '1em'
	}
}))

export default useAddAssignmentsStyles
