import React, { CSSProperties, useEffect, useState } from 'react'

import { Box, Button, Checkbox, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import ClassLinkIcon from 'src/assets/icons/LoginIntegrations/ClassLinkIcon.svg'
import { BaseDialog, BaseLink, InfoDialog } from 'src/components'
import { Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { ClassLinkTeacherData } from 'src/hooks/integrations/useClassLinkInstance'
import { useIntegrationContext } from 'src/hooks/useIntegration'
import { useLoginContext } from 'src/hooks/useLogin'
import { IntegrationButtonActions, SignUpData } from 'src/scenes/Login/enums'

import { useLoginStyles } from '../styles'
import useStyles from './IntegrationButtonClassLink.styles'

type IntegrationButtonProps = {
	className?: string
	customLabel?: string
	style?: CSSProperties
	disabled?: boolean
	loading: boolean
	action: IntegrationButtonActions
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
	setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>
	onButtonClick?: () => void
}

const LABEL = 'ClassLink'
const ICON = ClassLinkIcon
const integrationType = Integration_Name_Enum.ClassLink
export const IntegrationButtonClassLink = ({
	className,
	customLabel,
	style,
	disabled = false,
	loading: inheritedLoading,
	setLoading: setInheritedLoading,
	action,
	setSignUpData,
	onButtonClick
}: IntegrationButtonProps) => {
	const classes = useLoginStyles({})
	const styles = useStyles({ inheritedLoading })
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [signupTermsDialogOpen, setSignupTermsDialogOpen] = useState(false)
	const [isTermsOfServiceChecked, setIsTermsOfServiceChecked] = useState(false)
	const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const { loginSignIn, setIntegrationName } = useLoginContext()
	const {
		classLinkInstance: {
			// after this function (openPopup) is called, useClassLinkInstance will do the work
			// and will return loginOrSignUpData or loginOrSignUpError
			openPopup: openClassLinkPopup,
			account: classLinkAccount,
			loading: classLinkLoading,
			data: loginOrSignupData,
			error: loginOrSignUpError
		}
	} = useIntegrationContext()

	// set loading if classLink is getting user data or if loginOrSignUp is loading
	useEffect(() => {
		setInheritedLoading(classLinkLoading)
	}, [classLinkLoading])

	// After loginOrSignUp data has been received
	useEffect(() => {
		if (loginOrSignUpError) checkLoginOrSignUpError()
		else if (loginOrSignupData) handleLoginOrSignUp()
	}, [loginOrSignupData, loginOrSignUpError])

	// handle login or SignUp error
	const checkLoginOrSignUpError = () => {
		setErrorMessage('An error occured with this integration')

		setTimeout(() => {
			setErrorDialogOpen(true)
		}, 500)
	}

	// handle login or SignUp success
	const handleLoginOrSignUp = () => {
		if (!loginOrSignupData || !classLinkAccount) return

		const { isTeacher } = classLinkAccount
		const token = loginOrSignupData.token
		if (isTeacher) {
			const { isSignup, teacherId, token, schoolId } = loginOrSignupData as ClassLinkTeacherData

			// start signup process
			if (!schoolId && isSignup && setSignUpData) {
				// signup will redirect to school section
				setIntegrationName(integrationType)
				setSignUpData((prev) => ({
					...prev,
					token,
					integrationId: integrationType,
					teacherId
				}))

				return
			}
		}

		// login for teacher or student
		// this will redirect to home
		loginSignIn(token)
	}

	const handleOnclickButton = () => {
		if (onButtonClick) onButtonClick()
		if (action === IntegrationButtonActions.Login) {
			openClassLinkPopup()
		} else {
			setSignupTermsDialogOpen(true)
		}
	}

	return (
		<>
			<Button
				className={clsx(styles.button, className)}
				disabled={disabled || inheritedLoading}
				onClick={handleOnclickButton}
				style={style}>
				<img src={ICON} className={styles.integrationIcon} alt={customLabel || LABEL} />
				{!inheritedLoading && <b className={styles.label}>{customLabel || LABEL}</b>}
				{inheritedLoading && <CircularProgress color="secondary" className={styles.loading} />}
			</Button>
			<InfoDialog
				open={errorDialogOpen}
				onClose={() => {
					setErrorDialogOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body={errorMessage}
				confirmLabel="Close"
				onConfirm={() => {
					setErrorDialogOpen(false)
				}}
			/>
			<BaseDialog
				open={signupTermsDialogOpen}
				onClose={() => setSignupTermsDialogOpen(false)}
				dividers={false}
				header={
					<>
						<Typography variant="h5" align="center" className={styles.header}>
							<b>To Sign Up you must accept the Terms of Service and Privacy Policy</b>
						</Typography>
					</>
				}
				body={
					<>
						<Box mt={1} display="flex" flexWrap="nowrap" alignItems="center">
							<Checkbox
								className={classes.checkbox}
								onClick={() => setIsTermsOfServiceChecked(!isTermsOfServiceChecked)}
								checked={isTermsOfServiceChecked}
							/>
							<Typography variant="caption" className={classes.checkboxText}>
								I Have read and accept the WURRLY{' '}
								<BaseLink href="https://www.wurrlyedu.com/terms">
									<Typography variant="caption">Terms of Service.</Typography>
								</BaseLink>
							</Typography>{' '}
						</Box>
						<Box>
							<Checkbox
								className={classes.checkbox}
								onClick={() => setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)}
								checked={isPrivacyPolicyChecked}
							/>
							<Typography variant="caption" className={classes.checkboxText}>
								I Have read, underatand and accept the WURRLY{' '}
								<BaseLink href="https://www.wurrlyedu.com/privacy">
									<Typography variant="caption">Privacy Policy.</Typography>
								</BaseLink>
							</Typography>{' '}
						</Box>
					</>
				}
				confirmLabel="Sign Up"
				discardLabel="close"
				onDiscard={() => setSignupTermsDialogOpen(false)}
				isConfirmDisabled={!isPrivacyPolicyChecked || !isTermsOfServiceChecked}
				onConfirm={() => {
					setSignupTermsDialogOpen(false)
					openClassLinkPopup()
				}}
			/>
		</>
	)
}
