import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const usePaymentsListStyles = makeStyles<Theme>(() =>
	createStyles({
		list: {
			paddingBottom: '5%'
		},
		title: {
			marginBottom: '20px'
		}
	})
)

export default usePaymentsListStyles
