import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { getCyKey } from '../../../../../../utils'
import { ClassStudentsGrid } from './components/ClassStudentsGrid'
import { ClassStudentsTitle } from './components/ClassStudentsTitle'
import { ClassStudentsProvider } from './useClassStudents'

export const ClassStudents = (props: PagesProps) => {
	return (
		<Box data-cy={getCyKey(ClassStudents, 'classStudentsBox')}>
			<ClassStudentsProvider>
				<ClassStudentsTitle {...props} />
				<ClassStudentsGrid />
			</ClassStudentsProvider>
		</Box>
	)
}
