import { useState, useEffect } from 'react'

import { NotesIcon } from 'src/assets/icons/CustomIcons'
import { FixedBackground } from 'src/components'
import { useSearchQuery } from 'src/hooks'

import { AccountActivationDialog } from './components/ActivateAccountDialog/AccountActivationDialog'
import useStyles from './TokenAccountActivation.styles'

export const TokenAccountActivation = () => {
	const styles = useStyles()
	const [token, setToken] = useState<string>()

	const queryParams = useSearchQuery()

	useEffect(() => {
		const tokenFromUrl = queryParams.get('token')
		if (tokenFromUrl && typeof tokenFromUrl === 'string') {
			setToken(tokenFromUrl)
		}
	}, [queryParams])

	return (
		<FixedBackground>
			<NotesIcon className={styles.notesIcon} />
			<AccountActivationDialog token={token} />
		</FixedBackground>
	)
}
