import React from 'react'

import { PagesProps } from 'src/@types'
import { useGetCatalogByPkQuery } from 'src/graphql/autogenerate/hooks'
import { Catalog_Item } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { CatalogVideosBody } from 'src/scenes/Teacher/scenes/8-Videos/components/CatalogVideosBody/CatalogVideosBody'
import { capitalize } from 'src/utils'
import { buildBreadCrumbs } from 'src/utils/utils'

export const CatalogItemVideos = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const params = useWurrlyParams<typeof Pages.CatalogItemVideos.params[number]>()

	const { catalogId, catalogItemId } = params
	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})

	const catalogName = catalogData?.catalog_by_pk?.name
	const catalogItem = catalogData?.catalog_by_pk?.catalog_items.find(
		(item) => item.catalog_item_id === catalogItemId
	) as Catalog_Item | undefined

	// TODO: fix breadcrumbs logic here

	const title = catalogItem?.name || 'Videos'

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: capitalize(catalogName)
			},
			{
				index: 2,
				overrideName: title
			}
		],

		params
	})

	return (
		<CatalogVideosBody
			title={title}
			page={page}
			catalogId={catalogId}
			catalogItemId={catalogItemId}
			teacherId={teacherId}
			breadcrumbs={breadcrumbs}
		/>
	)
}
