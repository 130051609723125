import { makeStyles } from '@material-ui/core'

export const useStudioSongPlayerStyles = makeStyles((theme) => ({
	dialogBody: {
		width: 'calc(100% - 8px)'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '800px',
		backgroundColor: 'white',
		padding: '40px',
		borderRadius: '8px',
		height: '85%',
		overflowY: 'auto',
		[theme.breakpoints.down('md')]: {
			height: '100%'
		},
		'& .MuiDialogContent-dividers': {
			border: 'none'
		}
	},
	body: {
		padding: '10px 0 20px 5px',
		marginBottom: 10
	},
	listItem: {
		padding: '8px 0',
		alignItems: 'flex-start'
	},
	listItemText: { margin: 0 },
	tipoArtist: { fontSize: 14 },
	boxFab: {
		alignItems: 'center',
		display: 'flex',
		marginTop: theme.spacing(2)
	},
	fab: ({ loaded }: { loaded: boolean }) => ({
		backgroundColor: '#fff',
		minWidth: 48,
		pointerEvents: loaded ? 'initial' : 'none'
	}),
	circularProgress: {
		width: 25,
		height: 25
	},
	boxSongTime: {
		alignItems: 'center',
		borderRadius: 4,
		display: 'flex',
		marginLeft: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		width: '100%'
	},
	boxSlider: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: theme.spacing(2),
		width: '100%'
	},
	boxBody: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		paddingTop: '15px',
		paddingBottom: '10px'
	},
	tipoActivate: { fontSize: 14 },
	tipoSongTime: {
		minWidth: 85,
		fontSize: 14
	},
	avatar: {
		width: 140,
		height: 140,
		marginRight: 16
	},
	boxTimer: {
		alignItems: 'center',
		borderRadius: 4,
		display: 'flex',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		width: '100%'
	},
	boxContainerSlider: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: theme.spacing(2),
		width: '100%'
	},
	boxSliderKey: {
		alignItems: 'center',
		borderRadius: 4,
		display: 'flex',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		width: '100%'
	},
	boxChords: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3),
		paddingTop: 20
	},
	vocalGuide: {
		display: 'flex',
		justifyContent: 'center'
	},
	boxNextBtn: {
		gap: '12px',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	nextBtn: {
		width: '210px'
	},
	actionButton: {
		height: '56px',
		width: '56px'
	},
	button: {
		height: 72,
		position: 'relative',
		color: 'inherit',
		borderWidth: undefined,
		backgroundColor: '#fff'
	},
	buttonMatched: {
		height: 72,
		position: 'relative',
		color: '#111',
		borderWidth: 2,
		backgroundColor: 'rgba(255, 194, 12, 0.1)'
	},
	title: {
		fontSize: '30px',
		lineHeight: '1em'
	},
	description: {
		lineHeight: '1.2em',
		margin: '5px 0px 25px 0px'
	}
}))

export default useStudioSongPlayerStyles
