import { makeStyles } from '@material-ui/core'
import { DEFAULT_MUSIC_BG } from 'src/utils/constants'

export const useClassCardStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		height: '4em'
	},
	image: {
		borderRadius: '10px',
		height: '4em',
		width: '4em',
		marginRight: '1em'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	title: {
		lineHeight: 1.2
	},
	studentsCount: {
		color: 'rgb(160, 160, 160)',
		lineHeight: 1.2
	},
	// default image has different dimensions so we need to enforce the image size through css
	defaultImage: {
		marginRight: '1em',
		height: '4em',
		width: '4em',
		borderRadius: '10px',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url('${DEFAULT_MUSIC_BG}')`
	}
}))

export default useClassCardStyles
