import { Box, MenuItem, Select as SelectMui, SelectProps as SelectMuiProps, Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './Select.styles'

export type Options<T = unknown> = {
	name: string
	value: T
}

export type OnChangeEventType = React.ChangeEvent<{
	name?: string
	value: unknown
}>
export type OnChangeEvent = (event: OnChangeEventType, child: React.ReactNode) => void

export type SelectProps = {
	className?: string
	options?: Options[] | undefined
	label?: string
	value: unknown
	children?: React.ReactNode
	onChange: OnChangeEvent
	selectProps?: SelectMuiProps
}

export const Select = ({ className, options, value, children, onChange, label, selectProps }: SelectProps) => {
	const styles = useStyles()

	return (
		<Box display="flex" alignItems="center" className={className}>
			{label && <Typography className={styles.label}>{label}</Typography>}
			<SelectMui
				color="secondary"
				onClick={(e) => e.stopPropagation()}
				variant="outlined"
				value={value}
				{...selectProps}
				onChange={onChange}
				className={clsx(styles.root, selectProps?.className)}>
				{options
					? options.map(({ name, value: valueOption }, index) => (
							<MenuItem
								value={valueOption as string}
								selected={value === valueOption}
								key={index}
								className={styles.option}>
								{name}
							</MenuItem>
					  ))
					: children}
			</SelectMui>
		</Box>
	)
}
