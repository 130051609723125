import { Box, CircularProgress, makeStyles } from '@material-ui/core'

import { FixedBackground } from '../FixedBackground'

const useStyles = makeStyles(() => ({
	loadingWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		width: '100%'
	}
}))
export const FullScreenLoading = () => {
	const styles = useStyles()

	return (
		<FixedBackground>
			<Box className={styles.loadingWrapper}>
				<CircularProgress
					color="secondary"
					size={40}
					style={{
						justifyContent: 'center'
					}}
				/>
			</Box>
		</FixedBackground>
	)
}
