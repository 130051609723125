import { alpha, styled } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'

export const StyledAlert = styled(Alert)(({ theme }) => ({
	padding: '0px 6px',
	fontSize: '12px',
	color: theme.palette.info.main,
	backgroundColor: alpha(theme.palette.info.main, 0.2),
	maxHeight: '34px',
	minWidth: '100px'
}))

export const StyledTypography = styled(Typography)({
	marginRight: '5px'
})
