import { SetStateAction, useEffect, useState, Dispatch } from 'react'

import { useGetStudentSongsByClassLazyQuery } from 'src/graphql/autogenerate/hooks'
import { MAX_LIST_ITEMS, SortOrder } from 'src/utils'

export const useGetSongs = (
	order: SortOrder = SortOrder.Up,
	classId?: number
): [typeof querySongs, boolean, boolean, boolean, number, Dispatch<SetStateAction<boolean>>] => {
	const [, setLimit] = useState(MAX_LIST_ITEMS)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [songs, setSongs] = useState<typeof querySongs>([])
	const [count, setCount] = useState(0)

	const [getClassSongs, { data: classSongs, loading, fetchMore: fetchMoreSongs }] =
		useGetStudentSongsByClassLazyQuery({
			variables: {
				classId
			}
		})
	const querySongs = classSongs?.class[0].class_songs || []

	useEffect(() => {
		if (classSongs && classSongs?.class[0].class_songs) {
			setSongs(classSongs.class[0].class_songs)
			setCount(classSongs.class[0].class_songs_aggregate.aggregate?.count ?? 0)
		}
	}, [classSongs])

	useEffect(() => {
		getClassSongs()
	}, [order])

	useEffect(() => {
		if (!loading) setIsFetching(false)
	}, [loading])

	useEffect(() => {
		const totalSongsCount = classSongs?.class[0].class_songs_aggregate.aggregate?.count ?? 0
		const fetchedSongsCount = songs.length
		setMoreResults(fetchedSongsCount < totalSongsCount)
	}, [classSongs?.class[0].class_songs_aggregate.aggregate?.count, songs])

	useEffect(() => {
		if (!isFetching) return
		if (!loading && fetchMoreSongs) {
			const currentLength = songs.length
			fetchMoreSongs({
				variables: {
					offset: currentLength
				}
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.class[0].class_songs.length !== 0) {
					setLimit(currentLength + (fetchMoreResult.data?.class[0].class_songs.length || 0))
					setMoreResults(true)
				} else {
					setMoreResults(false)
					setIsFetching(false)
				}
			})
		}
	}, [isFetching])

	return [songs, isFetching, moreResults, loading, count, setIsFetching]
}
