import { ReactChild, ReactFragment, ReactPortal } from 'react'

import { Link, Tooltip, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import { DownloadIcon2 } from 'src/assets/icons'

const StyledTooltip = withStyles((theme) => ({
	tooltip: {
		color: theme.palette.primary.main,
		maxWidth: '400px',
		margin: '0 .5em',
		backgroundColor: 'white',
		border: `1px solid ${theme.palette.primary.contrastText}`,
		boxShadow:
			'0px 60px 94px rgba(0, 0, 0, 0.0140573), 0px 25.0666px 39.271px rgba(0, 0, 0, 0.0201946), 0px 13.4018px 20.9961px rgba(0, 0, 0, 0.025), 0px 7.51293px 11.7703px rgba(0, 0, 0, 0.0298054), 0px 3.99006px 6.2511px rgba(0, 0, 0, 0.0359427), 0px 1.66035px 2.60122px rgba(0, 0, 0, 0.05)',
		fontSize: '16px',
		padding: '20px'
	},
	arrow: {
		color: 'white',
		'&::before': {
			border: `1px solid ${theme.palette.primary.contrastText}`
		}
	}
}))(Tooltip)

type CustomTooltipProps = {
	content: ReactChild | ReactFragment | ReactPortal
}

export const CustomTooltip = ({ content }: CustomTooltipProps) => {
	return (
		<StyledTooltip title={content} placement="left-start" interactive arrow>
			<HelpIcon
				aria-haspopup="true"
				id="take_it_further"
				style={{ marginLeft: '5px', alignSelf: 'center' }}
				fontSize="small"
				color="secondary"
			/>
		</StyledTooltip>
	)
}

export const stepTwoTooltipContent = {
	inspire: (
		<Typography>
			We believe the best learning starts with inspiration as it engages you students and makes them
			interested and open to learning! You can do this by sharing content (song, video or image etc.) or an
			experience, that initiates a conversation!
		</Typography>
	),
	practice: (
		<Typography>
			Practicing a new skill through fun, interactive activities is the best way to keep students engaged as
			they build their muscle memory. It also allows for a growth mindset, where the result is not as
			important as the journey, and challenges or failures can be viewed as opportunities to improve their
			learning and skills.
		</Typography>
	),
	record: (
		<Typography>
			Recording allows students to create an artifact that demonstrates mastery over the subject matter. It
			also mimics performance (leading to greater management skills for performance anxiety), and allows for
			preservation of work so students can track their development.
		</Typography>
	),
	reflect: (
		<Typography>
			Reflection allows for greater self awareness as the experience of doing something is very different
			from the experience of watching yourself do something. It also leads to deeper learning as the same
			lesson is now being learned from multiple perspectives. Ask your students some questions that guide
			their reflection in a positive way.
		</Typography>
	)
}

export const stepThreeTooltipContent = {
	takeItFurther: (
		<Typography>
			If this lesson was to be repeated, what aspects could be changed, and how could it be altered to be
			more challenging?
		</Typography>
	),
	materialsNeeded: <Typography>What materials will the teacher and students need for this lesson?</Typography>,
	differentiation: (
		<Typography>
			How could the class be offered under different circumstances, such as with differently abled students,
			variations for repeating the class, or teaching without access to a smart device?
		</Typography>
	),
	suggestedAssignments: (
		<Typography>
			Instructions to the student for an assignment that accompanies this lesson. This will be shared with
			the students if an assignment is created, so should be written in first person to the student.
		</Typography>
	)
}

export const stepFourTooltipContent = {
	creating: (
		<Typography>
			Conceiving and developing new artistic ideas and work.
			<br />
			<br />
			<Link color="secondary" target="_blank" href="https://selarts.org/wp-content/uploads/Creating.pdf">
				<img alt="DownloadIcon2" src={DownloadIcon2} style={{ cursor: 'pointer' }} /> Download the Create
				SEL Framework PDF
			</Link>
		</Typography>
	),
	performing: (
		<Typography>
			- Performing (dance, music, theatre): Realizing artistic ideas and work through interpretation and
			presentation.
			<br />
			<br />
			- Presenting (visual arts): Interpreting and sharing artistic work.
			<br />
			<br />
			- Producing (media arts): Realizing and presenting artistic ideas and work.
			<br />
			<br />
			<Link color="secondary" target="_blank" href="https://selarts.org/wp-content/uploads/Perform.pdf">
				<img alt="DownloadIcon2" src={DownloadIcon2} style={{ cursor: 'pointer' }} /> Download the
				Perform/Present/Produce SEL Framework PDF
			</Link>
		</Typography>
	),
	responding: (
		<Typography>
			Understanding and evaluating how the arts convey meaning.
			<br />
			<br />
			<Link color="secondary" target="_blank" href="https://selarts.org/wp-content/uploads/Respond.pdf">
				<img alt="DownloadIcon2" src={DownloadIcon2} style={{ cursor: 'pointer' }} /> Download the Respond
				SEL Framework PDF
			</Link>
		</Typography>
	),
	connecting: (
		<Typography>
			Relating artistic ideas and work with personal meaning and external context.
			<br />
			<br />
			<Link color="secondary" target="_blank" href="https://selarts.org/wp-content/uploads/Connect.pdf">
				<img alt="DownloadIcon2" src={DownloadIcon2} style={{ cursor: 'pointer' }} /> Download the Connect
				SEL Framework PDF
			</Link>
		</Typography>
	)
}
