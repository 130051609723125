import React, { useEffect, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Radio,
	RadioGroup,
	Switch,
	Typography,
	useTheme
} from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { useHistory, useLocation } from 'react-router-dom'
import { FilterItemType, PagesProps } from 'src/@types'
import { ListSongs, MainTitle, Search, SelectSort, SelectChord } from 'src/components'
import {
	useGetCatalogByPkQuery,
	useGetClassMaturityLevelQuery,
	useGetScalesQuery
} from 'src/graphql/autogenerate/hooks'
import { Catalog_Item, Song } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useScroll } from 'src/hooks/useScroll'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { StudentPages } from 'src/routes/studentpages'
import { useClassIdParam } from 'src/scenes/Student/scenes/3-MusicCatalog/useClassIdParam'
import { QueryCatalogs, QueryCatalogsType } from 'src/scenes/Teacher/queries'
import { capitalize, getCyKey, TypeEnum, SortOrder } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'
import { buildBreadCrumbs, fetchOnScrollDown } from 'src/utils/utils'

import { LocationState } from '../../types'
import { FilterNameEnum } from '../enums'
import { useGetSongs } from './hooks'

export const CatalogSongs = ({ page }: PagesProps) => {
	const history = useHistory()
	const location = useLocation()
	const theme = useTheme()
	const state = location.state as LocationState
	const stateChords = (state?.chords as string[]) || []
	const search = (state?.search as string) || ''
	const { studentData } = useLoginContext()
	const studentId = studentData.student_id

	const params = useWurrlyParams<typeof StudentPages.CatalogItemSongs.params[number]>()
	const { catalogId, catalogItemId } = params
	const { classId, gotParamFromState } = useClassIdParam()
	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})
	const catalogName = catalogData?.catalog_by_pk?.name
	const catalogItem = catalogData?.catalog_by_pk?.catalog_items.find(
		(item) => item.catalog_item_id === catalogItemId
	) as Catalog_Item | undefined

	// const [checked, setChecked] = useState<string[]>([])
	const [scale, setScale] = useState(0)
	const [filters, setFilters] = useState<FilterItemType[]>([])
	const [sort, setSort] = useState(SortOrder.Up)
	const [selectedChords, setSelectedChords] = useState<string[]>(stateChords || [])
	const [selectedFilters, setSelectedFilters] = useState<number[]>([])
	const [songsFiltered, setSongsFiltered] = useState<Song[]>([])
	const [transpose, setTranspose] = useState(false)
	// const [isRecomended, setIsRecomended] = useState<boolean>()
	const [vocalGuide, setVocalGuide] = useState(false)
	const [chordGuide, setChordGuide] = useState(!!selectedChords?.length)
	const isChords = page.name === StudentPages.BrowseByChords.name
	const secondaryFilters = useQuery<QueryCatalogsType>(QueryCatalogs(TypeEnum.Song))
	const { data: classMaturityLevel } = useGetClassMaturityLevelQuery({ variables: { classId } })

	const [songs, isFetching, moreResults, loading, count, setIsFetching] = useGetSongs(
		studentId,
		catalogItemId,
		search,
		sort,
		selectedChords,
		transpose,
		scale,
		vocalGuide,
		chordGuide,
		selectedFilters,
		classMaturityLevel?.class_by_pk?.maturity_level
	)
	const { data: scales } = useGetScalesQuery()
	const scrollRef = useRef(null)
	const IsAnyFilterSelected = !!selectedFilters.length || chordGuide || vocalGuide
	useEffect(() => {
		if (scrollRef?.current)
			window.addEventListener('scroll', () => {
				const htmlElement = scrollRef?.current as unknown as HTMLElement
				fetchOnScrollDown(isFetching, setIsFetching, htmlElement)
			})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [scrollRef])
	// TODO: fix breadcrumbs logic here
	const title =
		catalogItem?.name ||
		(!catalogId && search && 'Song Search Results') ||
		(isChords && 'Browse By Chords') ||
		(catalogId && capitalize(catalogName)) ||
		page.name
	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: catalogItemId
			? [
					{
						index: 1,
						overrideName: capitalize(catalogName)
					},
					{
						index: 2,
						overrideName: title
					}
			  ]
			: [
					{
						index: 1,
						overrideName: title
					}
			  ],
		params: { ...params, classId },
		isStudentPage: true
	})

	useScroll()

	//  get secondary filters
	useEffect(() => {
		if (!secondaryFilters.loading && secondaryFilters.data) {
			const { catalog } = secondaryFilters.data

			const tmpcatalog = catalog.map((itemCatalog) => ({
				name: capitalize(itemCatalog.name),
				items: itemCatalog.catalog_items.map((el) => ({
					id: el.catalog_item_id,
					label: el.name,
					selected: false
				}))
			}))
			setFilters(tmpcatalog)
		}
	}, [secondaryFilters])

	useEffect(() => {
		if (!songs.length && !songsFiltered.length) return
		setSongsFiltered(songs as Song[])
	}, [songs])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const handleCheck = (indexFilter: number, indexItem: number) => () => {
		const cpy = [...filters]
		const filter = cpy[indexFilter]
		const item = filter.items[indexItem]

		item.selected = !item.selected
		if (item.selected) {
			setSelectedFilters([...selectedFilters, item.id])
		} else {
			const auxFilters = [...selectedFilters] // make a separate copy of the array
			const index = auxFilters.indexOf(item.id)
			if (index !== -1) {
				auxFilters.splice(index, 1)
				setSelectedFilters(auxFilters)
			}
		}
		setFilters(cpy)
		// setSongsFiltered(filterSongsByChordAndFilters(catalogSongs, selectedChords, cpy, transpose))
	}

	const handleScale = (event: React.ChangeEvent<HTMLInputElement>) => {
		setScale(+(event.target as HTMLInputElement).value)
	}

	const handleChord = (chords: string[]) => {
		setSelectedChords(chords)
	}

	const handleTranspose = () => {
		setTranspose((prev) => !prev)
	}

	const overrideOnClick = (classId: number, songId: number, isBlankSong: boolean) => {
		if (!isBlankSong) {
			history.push(
				buildRouteParameters(StudentPages.IndieStudentStudio, {
					songId,
					classId
				})
			)
		} else {
			history.push(
				buildRouteParameters(
					StudentPages.StudentRecord,
					{
						songId
					},
					true,
					{
						isBlankSong: isBlankSong.toString(),
						classId: classId.toString()
					}
				)
			)
		}
	}

	const handleSearch = (search: string) => {
		// TODO: ask if when the user search, it should be redirected to search page or it should be refreshed
		if (!gotParamFromState) {
			history.push(buildRouteParameters(StudentPages.SearchSongs, { classId }), { search })
		} else {
			history.push(buildRouteParameters(StudentPages.SearchSongsDrawer), { search, classId })
		}
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={title}
				description="Get inspired by the songs you love! Find a Song and get to the studio to practice and record, or Favourite it for later!."
				locationState={{ classId }}
			/>

			<Search
				teacherId={0}
				studentId={studentId}
				type={TypeEnum.Song}
				onChange={handleSearch}
				textSearch={search}
				classId={classId}
				overrideSongClick={overrideOnClick}
			/>

			{!search && (
				<Box>
					<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Browse by Chords</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<FormControlLabel
							control={
								<Switch
									data-cy={getCyKey(CatalogSongs, 'ShowTransposedResults')}
									checked={transpose}
									onChange={handleTranspose}
									name="checkedA"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							}
							label={
								<Typography
									style={{
										fontWeight: 'bold',
										color: !transpose ? theme.palette.grey[500] : theme.palette.grey[600]
									}}>
									Show Transposed Results
								</Typography>
							}
						/>
					</Box>
					<SelectChord selectedChords={selectedChords} onChange={handleChord} />
				</Box>
			)}

			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12} md={9}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b>Songs</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						{count !== 0 && <SelectSort cyKey="Songs" value={sort} onChange={handleSort} />}
					</Box>
				</Grid>
				<Grid item xs={12} md>
					<Typography style={{ textAlign: 'right' }} variant="h5" color="textSecondary">
						{count} Results
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					{isFetching || !loading ? (
						<ListSongs
							ref={scrollRef}
							teacherId={0}
							selectedChords={selectedChords}
							allTranspositions={transpose}
							studioButton
							spacing={1.5}
							data={songsFiltered}
							classId={classId}
							overrideOnClick={overrideOnClick}
						/>
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{isFetching && moreResults && (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{!loading && songsFiltered.length > 0 && !moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)}
					{!loading && songsFiltered.length <= 0 && (
						<div>
							<Divider />
							<Box my={4}>
								<Typography variant="h6" color="textSecondary">
									{search && !IsAnyFilterSelected
										? `Your search for "${search}" did not return any results.`
										: 'No results found'}
								</Typography>
								<Typography color="textSecondary">
									{search && !IsAnyFilterSelected
										? `Remember it's easy to upload your own by clicking the button on the top right!`
										: 'Your filter selection did not return any results'}
								</Typography>
								<Typography color="textSecondary">
									{IsAnyFilterSelected &&
										'Try removing a filter or upload own by clicking the button top right'}
								</Typography>
							</Box>
							<Divider />
						</div>
					)}
				</Grid>

				<Grid item xs={12} md>
					<Paper>
						<Box py={2} px={3}>
							<Typography
								variant="h6"
								style={{
									fontSize: 18
								}}>
								<b>Filters</b>
							</Typography>
						</Box>
						<Divider />
						<Box py={1} px={3}>
							<List key={`filter-guide`} dense>
								<Typography
									variant="h6"
									style={{
										fontSize: 18
									}}>
									<b>Guides</b>
								</Typography>
								<ListItem button disableGutters onClick={() => setVocalGuide(!vocalGuide)}>
									<ListItemIcon
										style={{
											minWidth: 'auto'
										}}>
										<Checkbox
											data-cy={getCyKey(CatalogSongs, 'WithVocalGuide')}
											edge="start"
											checked={vocalGuide}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'With Vocal Guide'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="vocalGuide" primary={'With Vocal Guide'} />
								</ListItem>
								<ListItem
									button
									disableGutters
									onClick={() => {
										if (selectedChords.length === 0) {
											setChordGuide(!chordGuide)
										}
									}}>
									<ListItemIcon
										style={{
											minWidth: 'auto'
										}}>
										<Checkbox
											data-cy={getCyKey(CatalogSongs, 'WithChordGuide')}
											edge="start"
											checked={selectedChords.length > 0 || chordGuide}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'With Chord Guide'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="chordGuide" primary={'With Chord Guide'} />
								</ListItem>
							</List>
							{filters.map(
								(filter, indexFilter) =>
									filter?.name?.toLowerCase() !== catalogName?.toLowerCase() && (
										<List key={`filter-${indexFilter}`} dense>
											<Typography
												variant="h6"
												style={{
													fontSize: 18
												}}>
												<b>{filter.name}</b>
											</Typography>
											{filter.items.map((item, indexItem) => (
												<ListItem
													key={item.id}
													button
													disableGutters
													onClick={handleCheck(indexFilter, indexItem)}>
													<ListItemIcon
														style={{
															minWidth: 'auto'
														}}>
														<Checkbox
															data-cy={getCyKey(CatalogSongs, item.label)}
															edge="start"
															checked={item.selected}
															checkedIcon={<CheckBoxOutlinedIcon />}
															tabIndex={-1}
															disableRipple
															inputProps={{
																'aria-labelledby': item.label
															}}
														/>
													</ListItemIcon>
													<ListItemText id={item.id.toString()} primary={item.label} />
												</ListItem>
											))}
										</List>
									)
							)}
							<FormControl
								component="fieldset"
								style={{
									width: '100%'
								}}>
								<Typography
									variant="h6"
									style={{
										fontSize: 18
									}}>
									<b>{FilterNameEnum.Modes}</b>
								</Typography>
								<RadioGroup aria-label="select" name="select" value={scale} onChange={handleScale}>
									{scales?.scale?.map((item) => (
										<ListItem key={item?.scale_id} dense button disableGutters>
											<FormControlLabel
												data-cy={getCyKey(CatalogSongs) + '-radioButton-' + item.name}
												style={{
													flex: 1,
													marginRight: 0,
													textTransform: 'capitalize'
												}}
												value={item?.scale_id}
												control={<Radio disableRipple />}
												label={item?.name}
											/>
										</ListItem>
									))}
								</RadioGroup>
							</FormControl>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}
