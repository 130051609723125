import { useEffect, useState } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import jwt, { JwtPayload } from 'jsonwebtoken'
import { noop } from 'lodash'
import { useHistory } from 'react-router-dom'
import { AccountActivationToken } from 'src/@types'
import { WurrlyYellowLogo } from 'src/assets/icons'
import { BaseDialog, InfoDialog } from 'src/components'
import { useActivateAccountMutation, useLoginTeacherLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { LoginPages } from 'src/routes/loginPages'

import { ActivateAccountFormValidationSchema, isActivateTokenPayloadValid } from '../../utils'
import { ActivateAccountInputs } from '../ActivateAccountInputs/ActivateAccountInputs'
import { InvalidTokenMessage } from '../InvalidTokenMessage/InvalidTokenMessage'
import useStyles from './AccountActivationDialog.styles'

type AccountActivationDialogProps = {
	token?: string
}
export const AccountActivationDialog = ({ token }: AccountActivationDialogProps) => {
	const styles = useStyles()
	const history = useHistory()

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			passwordConfirm: '',
			acceptedTermsOfService: false,
			acceptedPrivacyPolicy: false,
			isTokenValid: false,
			tokenHasExpired: false
		},
		validateOnMount: true,
		onSubmit: () => handleActivate(),
		validationSchema: ActivateAccountFormValidationSchema
	})

	const [errorMessage, setErrorMessage] = useState<string>()
	const [activationSucces, setActivationSucces] = useState(false)
	const [signInError, setSignInError] = useState(false)
	const [activateAccount, { loading: isActivateAccountLoading }] = useActivateAccountMutation()

	const [loginQuery, { data: loginData, error: loginError }] = useLoginTeacherLazyQuery()
	const { loginSignIn } = useLoginContext()

	const passwordsMatch = formik.values.password === formik.values.passwordConfirm

	const extractTokenPayload = () => {
		if (!token) return

		try {
			const decodedToken = jwt.decode(token) as AccountActivationToken & JwtPayload
			const timestampInSeconds = Math.floor(Date.now() / 1000)
			const isExpired = (decodedToken?.exp ?? 0) < timestampInSeconds

			if (isExpired) {
				formik.setFieldValue('tokenHasExpired', true)
				throw new Error('Token has expired')
			} else if (!isActivateTokenPayloadValid(decodedToken)) {
				throw new Error('Token does not contain the required fields')
			}
			formik.setFieldValue('email', decodedToken?.email ?? '')
			formik.setFieldValue('isTokenValid', true)
		} catch (error) {
			console.error('Could not decode JWT', error)
			formik.setFieldValue('isTokenValid', false)
		}
	}

	const handleActivate = async () => {
		if (!token) return

		try {
			const activateAccountResult = await activateAccount({
				variables: {
					filter: {
						token,
						password: formik.values.password,
						passwordConfirm: formik.values.passwordConfirm
					}
				}
			})
			if (!activateAccountResult.data?.activateAccount?.success) {
				throw new Error('Something went wrong. Please try again.')
			}
			setActivationSucces(true)
		} catch (error) {
			console.error('Could not activate account:', error)
			setErrorMessage(String(error))
		}
	}

	const login = async () => {
		if (!activationSucces) return
		loginQuery({
			variables: { email: formik.values.email, password: formik.values.password }
		})
	}

	const handleLoginResult = () => {
		try {
			const loginToken = loginData?.login?.token

			if (loginError) throw new Error(loginError.message)
			if (loginToken) loginSignIn(loginToken)
		} catch (error) {
			console.error('Could not login:', error)
			setSignInError(true)
			setActivationSucces(false)
		}
	}

	const closeErrorMessage = () => {
		setErrorMessage(undefined)
		formik.setFieldValue('password', '')
		formik.setFieldValue('passwordConfirm', '')
	}

	const goToLogin = () => history.push(LoginPages.Teacher.path)

	useEffect(() => extractTokenPayload(), [token])
	useEffect(() => handleLoginResult(), [loginData, loginError])
	useEffect(() => {
		login()
	}, [activationSucces])

	return (
		<>
			<BaseDialog
				open
				transparentBackdrop
				dividers={false}
				onClose={noop}
				className={styles.base}
				PaperProps={{ className: styles.paper }}
				header={
					<Box display="flex" flexDirection="column" alignItems="center">
						<WurrlyYellowLogo className={styles.wurrlyIcon} />
						<Typography className={styles.title}>
							<strong>Let's Activate You Admin Account!</strong>
						</Typography>
					</Box>
				}
				body={
					<>
						{formik.values.isTokenValid && !formik.values.tokenHasExpired && (
							<ActivateAccountInputs
								email={formik.values.email}
								password={formik.values.password}
								isPasswordValid={!formik.errors.password}
								passwordConfirm={formik.values.passwordConfirm}
								passwordsMatch={passwordsMatch}
								acceptedTermsOfService={formik.values.acceptedTermsOfService}
								acceptedPrivacyPolicy={formik.values.acceptedPrivacyPolicy}
								handleFormChange={formik.handleChange}
								setFieldValue={formik.setFieldValue}
							/>
						)}
						{!formik.values.isTokenValid && (
							<InvalidTokenMessage
								expired={formik.values.tokenHasExpired}
								hasToken={!!token?.length}
							/>
						)}
					</>
				}
				confirmLabel="Activate"
				onConfirm={handleActivate}
				onConfirmProps={{ className: styles.confirmButton }}
				isConfirmDisabled={isActivateAccountLoading || !passwordsMatch || !formik.isValid}
				isConfirmLoading={isActivateAccountLoading}
			/>
			<InfoDialog
				icon="error"
				open={!!errorMessage}
				title="Could not activate account"
				body={<Typography>{errorMessage}</Typography>}
				discardLabel="Ok"
				onDiscard={closeErrorMessage}
			/>
			<InfoDialog
				icon="success"
				open={activationSucces}
				title="Your account has been Activated!"
				body={
					<Typography>
						Please wait while we sign you in. <CircularProgress size={18} />
					</Typography>
				}
			/>
			<InfoDialog
				icon="warning"
				open={signInError}
				title="Could not sign in!"
				body={
					<Typography>
						Your account has been activated, but we could not sign you in automatically. Please try
						signin in manualy.
					</Typography>
				}
				discardLabel="Go to Login"
				onDiscard={goToLogin}
			/>
		</>
	)
}
