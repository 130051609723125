import { createStyles, makeStyles } from '@material-ui/core'

export const useSelectRoleDialogStyles = makeStyles(() =>
	createStyles({
		baseDialogPaper: {
			width: '100%',
			maxWidth: '800px',
			'&.MuiDialog-paper': {
				paddingBottom: 0
			}
		},
		title: { paddingBottom: '10px' },
		rolesSection: { paddingBottom: '40px' },
		roleContainer: { width: '30%' },
		roleImgContainer: { margin: '20px 0' },
		roleImg: { height: '80px' },
		roleButton: { margin: '0 auto', paddingLeft: '40px', paddingRight: '40px' }
	})
)

export default useSelectRoleDialogStyles
