import { ChangeEvent } from 'react'

import { useQuery } from '@apollo/client'
import {
	Box,
	FormControl,
	RadioGroup,
	Card,
	FormControlLabel,
	Radio,
	Button,
	Typography,
	TextField
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { Autocomplete } from '@material-ui/lab'
import { BigBaseButton, MainTitle } from 'src/components'
import { DebouncedAutocomplete } from 'src/components/Inputs/DebouncedAutocomplete/DebouncedAutocomplete'
import InputFile from 'src/components/Inputs/InputFile'
import { InputHeader } from 'src/components/Inputs/InputHeader/InputHeader'
import { TextInput } from 'src/components/Inputs/TextInput/TextInput'
import { InfoDialog } from 'src/components/MutationDialog/InfoDialog'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'
import { Select } from 'src/components/Select/Select'
import { Artist, FileEnum, Grade } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { QueryCatalogItemsByCatalogId, QueryCatalogsByCatalogId } from 'src/scenes/Teacher/queries'
import { AutoCompleteStyle } from 'src/scenes/Teacher/scenes/17-Songs/scenes/UploadSong/components/CustomAutoComplete'
import { buildBreadCrumbs, CHORDS, sortGradeLevels, TypeEnum } from 'src/utils'

import { SongTrackCard } from '../UploadSong/components/SongTrackCard/SongTrackCard'
import useStyles from './UpdateSong.styles'
import { useUpdateSong } from './useUpdateSong'
type ArtistSelectOptionType = Pick<Artist, 'name' | 'artist_id'>

export const UpdateSong = () => {
	const styles = useStyles()
	const {
		formik,
		artists,
		scales,
		updateError,
		updateData,
		songData,
		songLoading,
		deleteSong,
		deleteLoading,
		deleteData,
		deleteError,
		showDeleteDialog,
		toggleShowDeleteDialog,
		goToSongs,
		goToUploadTrack,
		setTrackToDelete,
		trackToDelete,
		handleDeleteTrack,
		shouldShowDeleteSongError,
		shouldShowUpdateSongError,
		trackWasDeleted,
		deleteTrackHasError,
		clearTrackDeletionStatus,
		gradesCatalog
	} = useUpdateSong()

	const breadcrumbs = buildBreadCrumbs({
		page: Pages.SongUpload
	})

	const classes = useStyles()
	const CATALOG_THEMES_ID = 3
	const CATALOG_GENRES_ID = 1
	const CATALOG_AGES_ID = 4

	const SONG_AGES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Song, CATALOG_AGES_ID)
	)
	const SONG_THEMES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Song, CATALOG_THEMES_ID)
	)

	const SONG_GENRES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Song, CATALOG_GENRES_ID)
	)

	const handleSelectedLevels = (_event: ChangeEvent<unknown>, levels: Grade[]) => {
		sortGradeLevels(levels)
		formik.setFieldValue('levels', levels)
	}

	return (
		<>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title="Enter your Song's lyrics"
				description="Please enter the lyrics of the song you've uploaded. Simply input your lyrics line by line &amp; the app will handle the rest"
				rightActions={[
					{
						name: 'Delete',
						onClick: toggleShowDeleteDialog,
						className: styles.deleteButton,
						disabled: songLoading || deleteLoading || !songData?.song_by_pk
					}
				]}
			/>
			<Box className={styles.form}>
				<TextInput
					label="Song title"
					required
					placeholder="Slowly Getting There"
					name="title"
					value={formik.values.title}
					onFocus={() => formik.setFieldTouched('title')}
					error={Boolean(formik.errors.title) && formik.touched.title}
					helperText={formik.touched.title ? formik.errors.title : undefined}
					onChange={formik.handleChange}
				/>

				<InputHeader name="Artist" />
				<DebouncedAutocomplete<ArtistSelectOptionType | undefined, false, true, true>
					options={artists}
					getOptionLabel={(option) => option?.name ?? ''}
					inputMode="search"
					onChange={(_, value) => {
						formik.setFieldValue('artist_id', (value as ArtistSelectOptionType)?.artist_id as number)
					}}
					value={{
						name: artists.find((item) => item.artist_id === formik.values.artist_id)?.name ?? '',
						artist_id: formik.values.artist_id
					}}
					inputProps={{
						placeholder: 'Search for Artists'
					}}
				/>
				<InputHeader name="Album art" />
				<InputFile.Container
					advise={'Accepted Format: JPG, PNG. Max Size: 200mb'}
					placeholder="Upload an Image"
					type={FileEnum.Images}
					handleFileUrl={(key) => {
						formik.setFieldValue('image_path', key)
					}}
					isTemporal={false}
					onDelete={() => formik.setFieldValue('image_path', '')}
					initialFile={
						formik.values.image_path !== ''
							? {
									resource_path: formik.values.image_path,
									name: formik.values.image_path
							  }
							: undefined
					}
				/>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>
							<b>Themes</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-theme',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-themes-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={SONG_THEMES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.themes}
						onChange={(_, value) => {
							formik.setFieldValue('themes', value)
						}}
						closeIcon={<CloseIcon data-cy="themes-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								className={classes.whiteBg}
								{...params}
								data-cy="upload-song-themes"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Genres</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-genre',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-genres-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={SONG_GENRES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.genres}
						onChange={(_, value) => {
							formik.setFieldValue('genres', value)
						}}
						closeIcon={<CloseIcon data-cy="genres-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								className={classes.whiteBg}
								{...params}
								data-cy="upload-song-genres"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>
							<b>Ages</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-ages',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-ages-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={SONG_AGES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.ages}
						onChange={(_, value) => {
							formik.setFieldValue('ages', value)
						}}
						closeIcon={<CloseIcon data-cy="ages-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								className={classes.whiteBg}
								{...params}
								data-cy="upload-video-ages"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Grade Level*</Typography>
					</Box>
					<Autocomplete
						className={classes.whiteBg}
						ChipProps={{
							'data-cy': 'selected-level',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-levels-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						multiple
						disableCloseOnSelect
						autoHighlight
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
						options={gradesCatalog.data?.grade || []}
						value={formik.values.levels}
						onChange={handleSelectedLevels}
						closeIcon={<CloseIcon data-cy="levels-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								onFocus={() => formik.setFieldTouched('levels')}
								error={!!formik.errors.levels && !!formik.touched.levels}
								helperText={
									!!formik.errors.levels && formik.touched.levels ? formik.errors.levels : ''
								}
								{...params}
								data-cy="upload-lesson-levels"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box className={styles.keyAndScaleSection}>
					<Box>
						<InputHeader name="Song Key" />
						<Select
							options={CHORDS.map((value) => ({ name: value, value }))}
							value={formik.values.key}
							onChange={(event) => {
								formik.setFieldValue('key', event.target.value)
							}}
						/>
					</Box>
					<Box>
						<InputHeader name="Mode" />

						<FormControl component="fieldset">
							<RadioGroup
								aria-label="scale id"
								name="scale_id"
								onChange={(event) => formik.setFieldValue('scale_id', Number(event.target.value))}
								value={formik.values.scale_id}
								className={styles.radios}>
								{scales.map((scale) => (
									<Card key={scale.scale_id}>
										<FormControlLabel
											className={styles.modeCard}
											value={scale.scale_id}
											control={<Radio />}
											label={scale.name}
										/>
									</Card>
								))}
							</RadioGroup>
						</FormControl>
					</Box>
				</Box>

				<InputHeader name="Tracks Upload" />

				<Box className={styles.trackList}>
					{songData?.song_by_pk?.tracks?.map((track) => (
						<SongTrackCard
							title={track.resource_path}
							key={track.track_id}
							onDelete={() => setTrackToDelete(track.track_id)}
						/>
					))}
					{(songData?.song_by_pk?.tracks.length ?? 0) >= 1 && (
						<Button variant="outlined" startIcon={<Add />} onClick={goToUploadTrack}>
							Add Another Track
						</Button>
					)}
				</Box>

				{songData?.song_by_pk?.tracks?.length === 0 && (
					<Box>
						<Button className={styles.addTracksButton} onClick={goToUploadTrack}>
							<Add />
							<span>Upload a Track</span>
						</Button>
					</Box>
				)}

				<Box className={styles.buttonsSection}>
					<BigBaseButton className={styles.button} onClick={goToSongs}>
						Cancel
					</BigBaseButton>
					<BigBaseButton
						color="secondary"
						className={styles.button}
						disabled={formik.isSubmitting || !formik.isValid || !songData?.song_by_pk?.song_id}
						onClick={formik.submitForm}>
						Save
					</BigBaseButton>
				</Box>
			</Box>
			<MutationDialog
				open={false}
				error={!!updateError || shouldShowUpdateSongError}
				success={!!updateData?.update_song_by_pk}
				icon="question"
				successProps={{
					title: `Song Updated!`,
					body: `You have successfully updated a Song`,
					confirmButton: {
						onClick: goToSongs,
						label: 'View Songs'
					}
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while updating the Song.`,
					icon: 'error'
				}}
			/>
			<MutationDialog
				open={false}
				error={!!deleteError || shouldShowDeleteSongError}
				success={!!deleteData?.delete_song_by_pk}
				icon="question"
				successProps={{
					title: `Song Deleted!`,
					body: `You have successfully deleted a Song`,
					confirmButton: {
						onClick: goToSongs,
						label: 'View Songs'
					}
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while deleting the Song.`,
					icon: 'error'
				}}
			/>
			<InfoDialog
				open={showDeleteDialog}
				onClose={toggleShowDeleteDialog}
				title="Delete this Song?"
				body="You will lose all the content that you've added"
				discardButton={{
					label: 'Cancel',
					onClick: toggleShowDeleteDialog
				}}
				confirmButton={{
					label: 'Delete Song',
					className: styles.deleteButton,
					onClick: deleteSong,
					startIcon: <DeleteIcon />
				}}
				icon="warning"
			/>
			<InfoDialog
				open={Boolean(trackToDelete)}
				onClose={() => setTrackToDelete(undefined)}
				title="Delete this Track?"
				body="You will lose all the content that you've added"
				discardButton={{
					label: 'Cancel',
					onClick: () => setTrackToDelete(undefined)
				}}
				confirmButton={{
					label: 'Delete Track',
					className: styles.deleteButton,
					onClick: handleDeleteTrack,
					startIcon: <DeleteIcon />
				}}
				icon="warning"
			/>

			<MutationDialog
				open={false}
				error={deleteTrackHasError}
				success={trackWasDeleted}
				icon="exclamation"
				successProps={{
					title: `Track Deleted!`,
					body: `You have successfully deleted a Track`,
					confirmButton: {
						onClick: clearTrackDeletionStatus,
						label: 'Confirm'
					}
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while deleting the Track.`,
					icon: 'error',
					confirmButton: {
						onClick: clearTrackDeletionStatus,
						label: 'Confirm'
					}
				}}
			/>
		</>
	)
}
