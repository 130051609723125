import { useEffect, useState } from 'react'

import { Box, ButtonProps, Paper, PaperProps, Tooltip, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import clsx from 'clsx'
import { CartIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { StatusProductButton } from 'src/components/Buttons/StatusProductButton/StatusProductButton'
import { Maybe, Partner, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'

import useStyles from './PartnerCard.styles'

export type PartnerCardProps = PaperProps & {
	partner: Partner
	onDelete?: () => void
	onEdit?: () => void
	editTooltip?: string
	deleteTooltip?: string
	onActiveChange?: (active: boolean) => void
	onView?: () => void
	btnSuscription?: ButtonProps
}

export const PartnerCard = ({ partner: partner, onClick, onView, btnSuscription, ...props }: PartnerCardProps) => {
	const styles = useStyles({ imageUrl: buildImagePath(partner.image_path) || '' })
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [status, setStatus] = useState('')
	const [isDistrict, setIsDistrict] = useState(true)

	useEffect(() => {
		if (partner) {
			setVisibility(partner.lockable_content.visibility)
			setSubscription(partner.is_purchased_by_teacher)
			setStatus(partner.get_status_by_teacher as string)
			setIsDistrict(partner?.lockable_content?.districts?.length > 0)
		}
	}, [partner])
	const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target !== e.currentTarget || !onClick) return
		onClick(e)
	}

	const partnerModules = partner.partner_modules.length
	let partnerLessons = 0
	partner?.partner_modules.map(
		(module) => (partnerLessons += module?.module?.module_lesson_plan_aggregate?.aggregate?.count ?? 0)
	)

	return (
		<Paper {...props} onClick={handleOnClick}>
			<Box className={styles.container}>
				<Box className={partner.image_path ? styles.imageContainer : styles.blank} onClick={onClick}>
					<Box className={styles.opacity} />
					<Typography variant="h5" className={clsx(styles.ellipsis, styles.title)}>
						{partner.title}
					</Typography>

					<Box className={styles.flag}>
						<Typography className={clsx(styles.flagText, styles.ellipsisSecondLine)}>
							<b>{`${partnerModules} Modules | ${partnerLessons || 0} Lessons`}</b>
						</Typography>
					</Box>
				</Box>

				<Box className={styles.details}>
					<Typography className={clsx(styles.ellipsis, styles.description)} onClick={onClick}>
						{partner.description}
					</Typography>
					<Typography className={clsx(styles.genres, styles.ellipsis)} onClick={onClick}>
						• {partner.short_description}
					</Typography>
					{visibility === Visibility_Enum.ForSale && (
						<StatusProductButton
							isDistrict={isDistrict}
							isSubscription={subscription as boolean}
							status={status}
						/>
					)}
					<Box className={styles.buttons}>
						<Tooltip title="View">
							<BaseButton
								color="primary"
								className={styles.viewButton}
								endIcon={<ArrowForwardIcon />}
								onClick={onView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
						{visibility === Visibility_Enum.ForSale && !subscription && (
							<BaseButton
								className={styles.subscription}
								startIcon={<CartIcon style={{ color: '#F3AB34' }} />}
								{...btnSuscription}>
								<Typography className={styles.subscribe}>Subscribe to Partner</Typography>
							</BaseButton>
						)}
					</Box>
				</Box>
			</Box>
		</Paper>
	)
}
