import { useState } from 'react'

import { SongForListItem } from 'src/components/ListItems/ListSongItem/ListSongItem'
import {
	useDeleteTeacherSongFavoriteMutation,
	useInsertTeacherSongFavoriteMutation
} from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'

export const useSongForSongPlayer = () => {
	const { teacherData: teacher } = useLoginContext()

	const [selectedSong, setSelectedSong] = useState<SongForListItem>()
	const [selectedSongId, setSelectedSongId] = useState<number>()
	const [viewSongDetails, setViewSongDetails] = useState<boolean>(true)
	const [addFavoriteSong, { loading: loadingAddSong }] = useInsertTeacherSongFavoriteMutation()
	const [deleteFavoriteSong, { loading: loadingDelSong }] = useDeleteTeacherSongFavoriteMutation()

	const handleSongFavorite = (songIsFavorite: boolean) => {
		if (songIsFavorite) removeSongFavorite()
		else addSongFavorite()
	}
	const removeSongFavorite = async () => {
		try {
			await deleteFavoriteSong({
				variables: { teacherId: teacher.teacher_id, songId: selectedSongId ?? 0 },
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_song_favorite'
					})
				}
			})
			setSelectedSong((prev) => {
				if (!prev) return undefined

				return { ...prev, is_favorite: false }
			})
		} catch (error) {
			console.error('Could not remove favorite song')
		}
	}
	const addSongFavorite = async () => {
		try {
			await addFavoriteSong({
				variables: { teacherId: teacher.teacher_id, songId: selectedSongId },
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_song_favorite'
					})
				}
			})
			setSelectedSong((prev) => {
				if (!prev) return undefined

				return { ...prev, is_favorite: true }
			})
		} catch (error) {
			console.error('Could not favorite song')
		}
	}

	return {
		selectedSong,
		setSelectedSong,
		selectedSongId,
		setSelectedSongId,
		viewSongDetails,
		setViewSongDetails,
		handleSongFavorite,
		loadingSongFavorite: loadingAddSong || loadingDelSong,
		isSongFavorite: !!selectedSong?.is_favorite
	}
}
