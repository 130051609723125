import { Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps, QueryCatalogItemsType } from 'src/@types'
import { Catalogs, MainTitle, Search, SelectChord } from 'src/components'
import { useGetSongCatalogQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { useScroll } from 'src/hooks/useScroll'
import { StudentPages } from 'src/routes/studentpages'
import { useClassIdParam } from 'src/scenes/Student/scenes/3-MusicCatalog/useClassIdParam'
import { TypeEnum } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'

export const MusicCatalog = ({ page }: PagesProps) => {
	useScroll()
	const history = useHistory()
	const { classId, gotParamFromState } = useClassIdParam()
	const catalogs = useGetSongCatalogQuery()
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const handleChord = (chords: string[]) => {
		if (!gotParamFromState) {
			history.push(buildRouteParameters(StudentPages.BrowseByChords, { classId }), {
				chords
			})
		} else {
			history.push(buildRouteParameters(StudentPages.BrowseByChordsDrawer), {
				chords,
				classId
			})
		}
	}

	const handleSearch = (search: string) => {
		if (!gotParamFromState) {
			history.push(buildRouteParameters(StudentPages.SearchSongs, { classId }), {
				search
			})
		} else {
			history.push(buildRouteParameters(StudentPages.SearchSongsDrawer), {
				search,
				classId
			})
		}
	}

	const overrideSongClick = (classId: number, songId: number, isBlankSong: boolean) => {
		if (!isBlankSong) {
			window
				.open(
					buildRouteParameters(StudentPages.IndieStudentStudio, {
						songId,
						classId
					}),
					'_blank'
				)
				?.focus()
		} else {
			window
				.open(
					buildRouteParameters(
						StudentPages.StudentRecord,
						{
							songId
						},
						true,
						{
							isBlankSong: isBlankSong.toString(),
							classId: classId.toString()
						}
					),
					'_blank'
				)
				?.focus()
		}
	}

	return (
		<div>
			<MainTitle
				breadcrumbs={[page]}
				title="Music Catalog"
				description="Get inspired by the songs you love! Find a Song and get to the studio to practice and record, or Favourite it for later!."
			/>

			<Search
				teacherId={0}
				type={TypeEnum.Song}
				onChange={handleSearch}
				studentId={studentId}
				overrideSongClick={overrideSongClick}
				classId={classId}
			/>

			<Box mb={2}>
				<Typography variant="h6">
					<b>Browse by Chords</b>
				</Typography>
			</Box>
			<SelectChord onChange={handleChord} />

			{!catalogs.loading && catalogs.data && (
				<Catalogs
					isTeacher={false}
					parentPage={'song'}
					data={catalogs.data as QueryCatalogItemsType}
					classId={classId}
					sendParamAsState
				/>
			)}
		</div>
	)
}
