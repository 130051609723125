import { PagesProps } from 'src/@types'
import { useGetCatalogByPkQuery } from 'src/graphql/autogenerate/hooks'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { LessonsCatalogBody } from 'src/scenes/Teacher/scenes/6-Lessons/components/LessonsCatalogBody'
import { buildBreadCrumbs } from 'src/utils'
export const SearchLessons = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const params = useWurrlyParams<typeof Pages.CatalogItemLessons.params[number]>()
	const { catalogId, catalogItemId } = params

	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})

	const catalogName = catalogData?.catalog_by_pk?.name

	const title = 'Lesson Search Results'

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: title || ''
			}
		],
		params
	})

	return (
		<LessonsCatalogBody
			page={page}
			breadcrumbs={breadcrumbs}
			catalogId={catalogId}
			catalogItemId={catalogItemId}
			teacherId={teacherId}
			title={title}
			catalogName={catalogName}
		/>
	)
}
