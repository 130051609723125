import { Box, Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { SelectSort } from 'src/components'
import { SortOrder } from 'src/utils/enums'

import useStyles from './SongsTitle.styles'

type SongsTitleProps = {
	sort: SortOrder
	handleSort: (sort: SortOrder) => void
	count: number
	isLoading?: boolean
}
export const SongsTitle = ({ sort, handleSort, count, isLoading }: SongsTitleProps) => {
	const styles = useStyles()
	const handleSortEvent = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		handleSort(event.target.value as SortOrder)
	}

	return (
		<Grid className={styles.container} container spacing={3}>
			<Grid item xs={12} md={9}>
				<Box className={styles.title}>
					<Typography variant="h6">
						<b>Songs</b>
					</Typography>
					<div className={styles.separator} />
					{!!count && <SelectSort cyKey="Songs" value={sort} onChange={handleSortEvent} />}
				</Box>
			</Grid>
			<Grid item xs={12} md>
				<Typography className={styles.results} variant="h5" color="textSecondary">
					{!isLoading ? count : <Skeleton className={styles.loadingResult}>{count}</Skeleton>} Results
				</Typography>
			</Grid>
		</Grid>
	)
}
