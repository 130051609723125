import { useEffect, useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'

import useStyles from './GradedProgress.styles'

export const GradedProgress = ({
	val,
	maxVal,
	totalStudents
}: {
	val: number
	maxVal: number
	totalStudents: number
}) => {
	const styles = useStyles()
	const [color, setColor] = useState('')
	const [lightColor, setLightColor] = useState('')
	const percentage = val === 0 && maxVal === 0 ? 0 : (val / maxVal) * 100
	useEffect(() => {
		if (val === maxVal && val !== 0) {
			setColor(styles.graded)
			setLightColor(styles.lightGraded)
		} else if (val < maxVal && val > 0) {
			setColor(styles.startedGraded)
			setLightColor(styles.lightStartedGraded)
		} else {
			setColor(styles.notGraded)
			setLightColor(styles.lightNotGraded)
		}
	}, [val, maxVal])

	return (
		<Box className={lightColor + ' ' + styles.container}>
			<CircularProgress variant="determinate" value={percentage} size={50} thickness={2} className={color} />
			<Box className={styles.assignmentNumber}>
				<Box className={color + ' ' + styles.upperNumber}>{val}</Box>
				<Box className={color + ' ' + styles.lowerNumber}>{totalStudents}</Box>
			</Box>
		</Box>
	)
}
