import { ChangeEvent } from 'react'

import { Slider, styled } from '@material-ui/core'

export type ScrubberProps = {
	currentValue: number
	setValue: (newValue: number) => void
}
export const Scrubber = ({ currentValue, setValue }: ScrubberProps) => {
	const handleChange = (_event: ChangeEvent<Record<string, unknown>>, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			setValue(newValue)
		}
	}

	return <StyledSlider value={currentValue} onChange={handleChange} track={false} />
}

const StyledSlider = styled(Slider)({
	position: 'absolute',
	width: '100%',
	zIndex: 2,
	height: '100%',
	padding: 0,
	'& .MuiSlider-rail': {
		opacity: 0
	},
	'& .MuiSlider-thumb': {
		margin: 0,
		height: '100%',
		width: '3px',
		borderRadius: 0,
		backgroundColor: '#1D273D'
	}
})
