import { createStyles, makeStyles } from '@material-ui/core'

export const useLricsStyles = makeStyles(() =>
	createStyles({
		lyrics: ({ xPos, yPos, lyricFontSize }: { xPos: number; yPos: number; lyricFontSize: number }) => ({
			position: 'absolute',
			padding: '2px 5px',
			bottom: yPos,
			left: xPos,
			fontSize: lyricFontSize
		})
	})
)

export default useLricsStyles
