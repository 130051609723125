import React, { useEffect, useState } from 'react'

import { Box, Button, CircularProgress, List, makeStyles, Theme, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useHistory, useParams } from 'react-router-dom'
import { LiteralTypes, PagesProps } from 'src/@types'
import { AccordionWrapper, DeleteItemDialog, MainTitle, SelectSort } from 'src/components'
import {
	useDeletePlaylistFromClassMutation,
	useGetClassTeacherByPkQuery,
	useGetTipPlayListsByClassQuery
} from 'src/graphql/autogenerate/hooks'
import { Order_By, Playlist } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { ListItemPlaylist } from 'src/scenes/Teacher/scenes/8-Videos/components'
import {
	buildRouteParameters,
	concatenate,
	SortOrder,
	getPlaylistGenres,
	buildBreadCrumbs,
	getCyKey,
	slugToName
} from 'src/utils'

const useStyles = makeStyles((theme: Theme) => ({
	boxPlaylist: {
		padding: 0,
		'& .listItem': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

export const ClassVideosViewAllPlatlists = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const history = useHistory()
	const styles = useStyles(1.5)
	const params = useParams<LiteralTypes<typeof Pages.ClassVideosViewAllPlaylist.params[number]>>()
	const classId = Number(params.classId)

	const [videosPlaylistIdToDelete, setVideosPlaylistIdToDelete] = useState<number>()
	const [deletePlaylistFromClass] = useDeletePlaylistFromClassMutation()
	const [videosPlaylistArr, setVideosPlaylistArr] = useState<Playlist[]>([])
	const [videosPlaylistCount, setVideosPlaylistCount] = useState(0)
	const [videosPlaylistLimit, setVideosPlaylistLimit] = useState<number | undefined>(5)
	const [videosPlaylistSort, SetVideosPlaylistSort] = useState(SortOrder.Up)
	const { data: classData } = useGetClassTeacherByPkQuery({ variables: { teacherId, classId } })
	const {
		data: videosPlayListData,
		loading: videosPlayListLoading,
		refetch: videosPlayListRefect
	} = useGetTipPlayListsByClassQuery({
		variables: {
			classId,
			limit: videosPlaylistLimit
		}
	})
	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
			},
			{
				index: 2,
				overrideName: 'Videos'
			},
			{
				index: 3,
				overrideName: 'Playlists'
			}
		],
		params
	})

	useEffect(() => {
		videosPlayListRefect({
			classId,
			limit: videosPlaylistLimit,
			orderName:
				videosPlaylistSort !== SortOrder.Recent
					? videosPlaylistSort !== SortOrder.Down
						? Order_By.Asc
						: Order_By.Desc
					: undefined
			// created_at: videosSavedSort === SortOrder.Recent ? Order_By.Asc : undefined
		})
	}, [videosPlaylistLimit, videosPlaylistSort])

	const seeAllVideosPlaylist = () => {
		setVideosPlaylistLimit(undefined)
	}

	const handleSort = (
		e: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		SetVideosPlaylistSort(e.target.value as SortOrder)
	}

	useEffect(() => {
		if (videosPlayListData && videosPlayListData?.class) {
			const playlistArr =
				(videosPlayListData?.class?.[0]?.class_playlists?.map((playlist) => {
					return { ...playlist.playlist }
				}) as Playlist[]) || []
			setVideosPlaylistArr(playlistArr)
			const count = videosPlayListData.class?.[0]?.class_playlists_aggregate.aggregate?.count || 0
			setVideosPlaylistCount(count)
		}
	}, [videosPlayListData])

	return (
		<Box pt={3}>
			{videosPlayListLoading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!videosPlayListLoading && (
				<Box>
					<MainTitle
						breadcrumbs={breadcrumbs}
						title={'Class Playlists'}
						description={'These are the Playlists that have been added to this Class.'}
						rightActions={[
							{
								variant: 'contained',
								color: 'secondary',
								startIcon: <AddIcon />,
								name: 'Add Playlist',
								onClick: () => {
									history.push(Pages.Videos.path)
								}
							}
						]}
					/>
					<AccordionWrapper
						defaultExpanded
						summary={
							<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
								<Typography variant="h6">
									<b>Saved Playlists ({videosPlaylistCount})</b>
								</Typography>
								<div style={{ flexGrow: 1 }} />

								<SelectSort
									cyKey={getCyKey(ClassVideosViewAllPlatlists)}
									value={videosPlaylistSort}
									onChange={handleSort}
								/>
							</Box>
						}
						details={
							<List className={styles.boxPlaylist}>
								{videosPlaylistCount > 0 &&
									videosPlaylistArr.map((playlist, i) => (
										<Box key={playlist.playlist_id} className="listItem">
											<ListItemPlaylist
												key={concatenate([i, playlist.playlist_id, playlist.name], '-')}
												id={playlist.playlist_id}
												imageUrl={playlist.image_path ?? ''}
												title={playlist.name}
												description={playlist.description}
												genres={getPlaylistGenres(playlist)}
												totalvideos={playlist.tip_playlist_aggregate.aggregate?.count ?? 0}
												onClick={(e) => {
													e.stopPropagation()
													history.push(
														buildRouteParameters(
															Pages.ClassVideosPlaylistDetails, // Route with paramters
															{
																classId,
																playlistId: playlist.playlist_id
															}
														)
													)
												}}
												btnView={{
													onClick: (e) => {
														e.stopPropagation()
														history.push(
															buildRouteParameters(
																Pages.ClassVideosPlaylistDetails, // Route with paramters
																{
																	classId,
																	playlistId: playlist.playlist_id
																}
															)
														)
													}
												}}
												btnRemove={{
													onClick: (e) => {
														e.stopPropagation()
														setVideosPlaylistIdToDelete(playlist.playlist_id)
													}
												}}
											/>
										</Box>
									))}
							</List>
						}
						actions={
							<Button
								onClick={() => seeAllVideosPlaylist()}
								disabled={videosPlaylistArr.length === videosPlaylistCount}>
								<Typography color="textSecondary">
									<b>See All</b>
								</Typography>
							</Button>
						}
					/>
				</Box>
			)}
			<DeleteItemDialog
				itemName="Playlist"
				isOpen={!!videosPlaylistIdToDelete}
				setIsOpen={setVideosPlaylistIdToDelete}
				onConfirm={async () => {
					if (videosPlaylistIdToDelete) {
						await deletePlaylistFromClass({
							variables: {
								classId,
								playlistId: videosPlaylistIdToDelete,
								orderName:
									videosPlaylistSort !== SortOrder.Recent
										? videosPlaylistSort !== SortOrder.Down
											? Order_By.Asc
											: Order_By.Desc
										: undefined,
								limit: videosPlaylistLimit
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_class_playlist?.returning[0].class
								if (!classToUpdateInCache) return
								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'class_playlists'
								})
							}
						})
					}
				}}
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Playlist from your Class?',
						body: "You'll be able to re-add it from the Videos page at any time.",
						buttonLabel: 'Delete Playlist'
					},
					confirmation: {
						body: 'You have successfully deleted this Playlist from your Class.'
					}
				}}
			/>
		</Box>
	)
}
