import { buildGenericContext } from 'src/context/genericContext'
import { useSetStudentActivationMutation } from 'src/graphql/autogenerate/hooks'
import { useSearchQuery } from 'src/hooks'

const useStudentAccountActivation = () => {
	const query = useSearchQuery()
	const studentEmail = query.get('studentEmail')?.replace(' ', '+')
	const code = query.get('code')

	const [
		setStudentActivationMutation,
		{ data: setStudentActivationData, loading: setStudentActivationLoading, error: setStudentActivationError }
	] = useSetStudentActivationMutation()

	return {
		code,
		studentEmail,
		setStudentActivationMutation,
		setStudentActivationData,
		setStudentActivationLoading,
		setStudentActivationError
	}
}

export const [StudentAccountActivationProvider, useStudentAccountActivationContext] =
	buildGenericContext(useStudentAccountActivation)
