import { Class } from 'src/graphql/autogenerate/schemas'
import * as yup from 'yup'

export const MAX_NAME_LENGHT = 100
export const MAX_INSTRUCTIONS_LENGTH = 1000
export const stepOneValidationSchema = yup.object({
	classes: yup.array().required(),
	name: yup.string().required().max(MAX_NAME_LENGHT),
	instructionsLen: yup.number().required().min(1).max(MAX_INSTRUCTIONS_LENGTH),
	due_date: yup.date().required(),
	due_time: yup.date().required(),
	grading_system: yup.number().required()
})

export const stepOneInitialValues = {
	classes: [] as Class[],
	name: '',
	instructions: '',
	due_date: undefined as Date | undefined,
	due_time: undefined as Date | undefined,
	grading_system: 1 as number | undefined,
	instructionsLen: 0
}
