import { useEffect, useState } from 'react'

import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { useGetDisciplineLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Discipline } from 'src/graphql/autogenerate/schemas'
import useDisciplineStyles from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload/Discipline.styles'

type DisciplineType = {
	changeData: (field: string, value: unknown, shouldValidate?: boolean) => void
	lesson_plan_id: number
	disciplines: Discipline[]
}

type SelectedDisciplineType = {
	lesson_plan_id: number
	discipline_id: number
}
export const DisciplineForm = ({ changeData, lesson_plan_id, disciplines }: DisciplineType) => {
	const classes = useDisciplineStyles()
	const [getDiscipline, { data }] = useGetDisciplineLazyQuery()
	const [discipline, setDiscipline] = useState<Discipline[]>([])
	const [selectedDiscipline, setSelectedDiscipline] = useState<SelectedDisciplineType[]>([])

	const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		const disciplineName = Number(event.target.value)
		const isChecked = event.target.checked

		if (isChecked) {
			setSelectedDiscipline((prevSelected: SelectedDisciplineType[]) => [
				...prevSelected,
				{
					lesson_plan_id,
					discipline_id: discipline.filter((d) => d.discipline_id === disciplineName)[0].discipline_id
				}
			])
		} else {
			setSelectedDiscipline((prevSelected: SelectedDisciplineType[]) =>
				prevSelected.filter((d) => d.discipline_id !== disciplineName)
			)
		}
	}

	const handleChecked = (discipline_id: number) => {
		return selectedDiscipline.some((d) => d.discipline_id === discipline_id)
	}
	useEffect(() => {
		if (data?.discipline) {
			setDiscipline(data.discipline as Discipline[])
		}
	}, [data])

	useEffect(() => {
		if (discipline.length === 0 || disciplines.length === 0) return
		setSelectedDiscipline(
			disciplines.map((d) => {
				return {
					lesson_plan_id,
					discipline_id: discipline.filter((x) => x.discipline_id === d.discipline_id)[0].discipline_id
				}
			})
		)
	}, [discipline])

	useEffect(() => {
		changeData('discipline', selectedDiscipline)
	}, [selectedDiscipline])

	useEffect(() => {
		getDiscipline()
	}, [])

	return (
		<Box marginTop="40px">
			<Box display="flex" justifyContent="space-between">
				<Typography className={classes.fieldTitle}>Discipline*</Typography>
			</Box>
			<Grid container spacing={3}>
				{discipline?.map((discipline, index) => {
					return (
						<Grid key={index} item className={classes.checkboxGridItem}>
							<Box className={classes.checkboxInput}>
								<FormControlLabel
									control={
										<Checkbox
											checked={handleChecked(discipline.discipline_id)}
											onChange={handleCheckbox}
											value={discipline.discipline_id}
										/>
									}
									label={discipline.name}
								/>
							</Box>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
}
