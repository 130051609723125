import React, { useEffect, useState } from 'react'

import { useLoginContext } from 'src/hooks/useLogin'

import { DeleteItemDialog } from '../../../../../components'
import { useRemoveStudentFromTeacherClassesMutation } from '../../../../../graphql/autogenerate/hooks'
import { Student } from '../../../../../graphql/autogenerate/schemas'

type Props = {
	setStudentDelete: React.Dispatch<React.SetStateAction<Student | undefined>>
	studentDelete: Student | undefined
	onDone?: () => void
}

export const DeleteStudentDialog = ({ studentDelete, setStudentDelete, onDone }: Props) => {
	const [idToDelete, setIdToDelete] = useState<number | undefined>() // Set the Candidate Id to be eliminated
	const [deleteStudent] = useRemoveStudentFromTeacherClassesMutation()
	const { teacherData } = useLoginContext()

	useEffect(() => {
		if (studentDelete) {
			setIdToDelete(studentDelete.student_id)
			setStudentDelete(undefined)
		}
	}, [studentDelete])

	const dialogMessages = {
		mainMessages: {
			title: 'Remove this Student from your Platform?',
			body: 'This will delete their account, which removes them from all of the Classes they are in and deletes any work they have created. Are you sure?',
			buttonLabel: 'Remove Student'
		},
		confirmation: {
			title: 'Student Removed!',
			body: 'You have successfully removed this Student.'
		}
	}

	return (
		<DeleteItemDialog
			isOpen={!!idToDelete}
			setIsOpen={setIdToDelete}
			onConfirm={async () => {
				if (idToDelete) {
					await deleteStudent({
						variables: {
							studentId: idToDelete,
							teacherId: teacherData.teacher_id
						}
					})
				}
			}}
			onDone={() => {
				if (onDone) onDone()
			}}
			optionalMessages={dialogMessages}
		/>
	)
}
