import { useEffect, useState } from 'react'

import { useGetOrdersLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Channel, Module, Partner, Playlist } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { MAX_LIST_ITEMS, buildBreadCrumbs } from 'src/utils'

export type BillingItem = {
	orderId: number
	date: string
	price: number
	name: string
	type: string
}
export const useBillingDetails = () => {
	const [orders, setOrders] = useState<BillingItem[]>([])
	const [getOrders, { loading: queryLoading, data, fetchMore, refetch }] = useGetOrdersLazyQuery()
	const { teacherData } = useLoginContext()
	const [isFetching, setIsFetching] = useState(false)
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [moreResults, setMoreResults] = useState(true)

	const createItem = (
		orderId: number,
		date: string,
		price: number,
		details:
			| ({ __typename: 'channel' } & Pick<Channel, 'title'>)
			| ({ __typename: 'partner' } & Pick<Partner, 'title'>)
			| ({ __typename: 'playlist' } & Pick<Playlist, 'name'>)
			| ({ __typename: 'module' } & Pick<Module, 'title'>)
	) => {
		if (orders.find((order) => order.orderId === orderId)) return

		const item: BillingItem = {
			orderId,
			date,
			price,
			name: details.__typename === 'playlist' ? details.name : details.title,
			type: details.__typename
		}
		setOrders((orders) => [...orders, item])
	}

	useEffect(() => {
		setIsFetching(false)
	}, [orders])

	useEffect(() => {
		getOrders({
			variables: {
				teacherId: teacherData.teacher_id,
				limit
			}
		})
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		if (!isFetching) return
		if (!queryLoading && fetchMore) {
			const currentLength = orders.length
			fetchMore({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				const fetchMoreResultLength = fetchMoreResult.data?.order?.length || 0
				if (fetchMoreResultLength !== 0) {
					const newLimit = currentLength + fetchMoreResultLength
					setLimit(newLimit)
					refetch({ limit: newLimit })
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])
	useEffect(() => {
		if (data) {
			data.order.map((order) => {
				if (order.channel != null) {
					createItem(order.order_id, order.created_at, order.total, order.channel)
				} else if (order.partner != null) {
					createItem(order.order_id, order.created_at, order.total, order.partner)
				} else if (order.playlist != null) {
					createItem(order.order_id, order.created_at, order.total, order.playlist)
				} else if (order.module != null) {
					createItem(order.order_id, order.created_at, order.total, order.module)
				}
			})
		}
	}, [data])

	const breadcrumbs = buildBreadCrumbs({
		page: Pages.BillingDetails
	})

	return {
		breadcrumbs,
		orders,
		queryLoading,
		isFetching,
		moreResults
	}
}
