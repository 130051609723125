import { BaseQueryOptions } from '@apollo/client'
import { loadStripe } from '@stripe/stripe-js'
import { AddStudentsUnder12TableHeader } from 'src/components'
import { AddStudentsTableHeader } from 'src/components/Tables/AddStudentsEnhancedTableHeader'
import { DateFilterEnum, environment } from 'src/environment'
import { Privacy_Enum, Role_Name_Enum } from 'src/graphql/autogenerate/schemas'

/**
 * @description Add constants with all letters CAPITALIZED and with underscore
 */

export const optionsDateFilter = [
	{ name: 'Next 7 Days', value: DateFilterEnum.nextSevenDays },
	{ name: 'This Month', value: DateFilterEnum.thisMonth },
	{ name: 'Next 3 Months', value: DateFilterEnum.nextThreeMonths },
	{ name: 'Last 6 Months', value: DateFilterEnum.lastSixMonths },
	{ name: 'This Year', value: DateFilterEnum.thisYear },
	{ name: 'All Time', value: DateFilterEnum.allTime }
]
export const BASE_URL = environment.s3Bucket
export const AWS_ASSETS_URL = '//wurrlyedu-cdn1.wurrly.co/images/source/'
export const THUMBNAIL_URL = `${BASE_URL}videos/thumbnails`
export const AWS_SONG_URL = environment.assetProcessor
export const SEE_MORE_RESULTS_NAME = 'Start typing to see more...'

export const LIMIT = 5
export const BLANK_SONG_TITLE = 'blank track'
export const MAX_SEARCH_ITEMS = 10
export const MAX_LIST_ITEMS = 10
export const MAX_MY_FILES_ITEMS = 5
export const MAX_FAVOURITE_ITEMS = 5

export const ASSIGNMENT_PER_VIEW = 5

export const MAX_DASHBOARD_QUERY_ITEMS = 5

export const MAX_SUBSCRIPTIONS_DISPLAY = 4

/* BEGIN IMAGE SECTION */
export const AWS_S3_RESOURCES = `${BASE_URL}resources`
export const DEFAULT_MUSIC_BG = `${AWS_S3_RESOURCES}/defaultMusicBg.jpg`
export const DEFAULT_CLASS_IMG = `${AWS_S3_RESOURCES}/defaultClassImage.png`
export const DEFAULT_CLASS_LESSON_IMG = `${AWS_S3_RESOURCES}/Class_Lessons_Image.svg`
export const DEFAULT_CLASS_VIDEOS_IMG = `${AWS_S3_RESOURCES}/Class_Videos_Image.svg`
export const DEFAULT_CLASS_SONG_IMG = `${AWS_S3_RESOURCES}/Class_Music_Catalog.svg`
export const Like_Icon = `${AWS_S3_RESOURCES}/LikeIcon.jpg`

export const FRAMES = `${AWS_S3_RESOURCES}/FRAMES/`

const ASSIGNMENTS_RESOURCES = `${AWS_S3_RESOURCES}/Assignments`

export const YELLOW_STAR = `${ASSIGNMENTS_RESOURCES}/YellowStar.jpg`
export const GREEN_STAR = `${ASSIGNMENTS_RESOURCES}/GreenGradeStar.jpg`
export const PURPLE_STAR = `${ASSIGNMENTS_RESOURCES}/PurpleStar.jpg`
export const GRADED = `${ASSIGNMENTS_RESOURCES}/Graded.jpg`
export const SUBMITTED = `${ASSIGNMENTS_RESOURCES}/Submitted.jpg`
export const START = `${ASSIGNMENTS_RESOURCES}/Start.jpg`

export const DEFAULT_CLASS_TIP_IMG = `${AWS_S3_RESOURCES}/Class_Videos.png`
export const DEFAULT_CLASS_STUDENT_IMG = `${AWS_S3_RESOURCES}/Class_Students.png`

const TEACHER_DASH_RESOURCES = `${AWS_S3_RESOURCES}/Teacher_Dashboard`

export const TEACHER_DASH_HERO = `${TEACHER_DASH_RESOURCES}/Teacher_Dashboard_Hero.png`
export const LIBRARY_IMG = `${TEACHER_DASH_RESOURCES}/Library.png`
export const CAMERA_IMG = `${TEACHER_DASH_RESOURCES}/Camera.png`
export const CLASSROOM_IMG = `${TEACHER_DASH_RESOURCES}/Classroom.png`

export const STAGE_IMG = `${TEACHER_DASH_RESOURCES}/Stage.png`
export const DESKTOP_IMG = `${TEACHER_DASH_RESOURCES}/Desktop.png`

const RECORD_STUDIO_RESOURCES = `${AWS_S3_RESOURCES}/RecordStudio`
export const DEFAULT_THUMBNAILS = [1, 2, 3, 4].map(
	(i) => `${RECORD_STUDIO_RESOURCES}/Student_video_cover_art_${i}.png`
)
export const DEFAULT_AUDIO_THUMBNAILS = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(
	(i) => `${RECORD_STUDIO_RESOURCES}/default_audio_thumbnail_${i}.jpg`
)
/* END IMAGE SECTION */

export const CHORDS = [
	'Am',
	'A',
	'Bbm',
	'Bb',
	'Bm',
	'B',
	'Cm',
	'C',
	'C#m',
	'Db',
	'Dm',
	'D',
	'Ebm',
	'E',
	'Fm',
	'F',
	'F#m',
	'F#',
	'Gm',
	'G',
	'G#m',
	'G#'
]

export const DAYS = [1, 2, 3, 4, 5, 6, 7]

export const BREADCRUMBS_LENGTH = 30

export const SUBJECT_FILTERS = ['General Music', 'Jazz', 'Modern Band', 'Vocal']

export const AGES_FILTERS = ['Middle School', 'Early Childhood', 'Elementary School', 'High School']

export const STRIPE_PROMISE = loadStripe(environment.stripePK)

export const DEFAULT_CARD_HEIGHT = 140

export const WAIT_TIME = 5000

export const ADD_STUDENTS_HEADER: AddStudentsTableHeader[] = [
	{ id: 'studentName', label: 'Student Name' },
	{ id: 'studentEmail', label: 'Student Email' },
	{ id: 'qrCode', label: 'QR Code' },
	{ id: 'studentCode', label: 'Student Code' }
]
export const STUDENTS_UNDER_12_HEADER: AddStudentsUnder12TableHeader[] = [
	{ id: 'studentName', label: 'Student Name' },
	{ id: 'parentEmail', label: 'Parent Email' }
]

export const Modes = ['Major', 'Minor', 'Chromatic']
export const MAX_SEARCH_SONGS = 4
export const SONG_KEYS = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6]

export const LOGIN_TEST_CREDENTIALS = {
	teacher: {
		private: {
			email: 'testmail@mailinator.com',
			password: 'Password123$'
		},
		school: {
			email: 'paula.davila+School@wearecapicua.com',
			password: 'Hola123!'
		},
		createClass: {
			email: 'paula.davila+createClass@wearecapicua.com',
			password: 'Hola123!'
		},
		classLessons: {
			email: 'classlessons@mailinator.com',
			password: 'Password123$'
		},
		classWithWurrlies: {
			email: 'paula.davila+classWurrlies@wearecapicua.com',
			password: 'Hola123!'
		},
		teacherCamp: {
			email: 'paula.davila+camp@wearecapicua.com',
			password: 'Password123$'
		},
		assignmentTestFlow: {
			email: 'paula.davila+smokeUserTeacher@wearecapicua.com',
			password: 'Hola123!'
		}
	},
	student: {
		byEmail: {
			email: 'paula.davila+studentAutomation@wearecapicua.com',
			password: 'Hola123!'
		},
		byCode: {
			code: '0kqB0R',
			school: '21st Century Charter School Of Gary',
			teacher: 'Teacher Teacher'
		}
	}
}

export const getPreviousMonday = () => {
	const date = new Date()
	const day = date.getDay()
	const prevMonday = new Date()
	if (date.getDay() === 0) {
		prevMonday.setDate(date.getDate() - 7)
	} else {
		prevMonday.setDate(date.getDate() - (day - 1))
	}

	const formated = prevMonday.toLocaleDateString('en-US')

	return formated
}

export const INTEGRATION_LOGIN_ERROR_RENDER = 'You must create an account with this email first.'

export const INTEGRATION_SIGNUP_ERROR_RENDER = 'You already have an account with this email.'

export const APP_PRIVACY_TERMS = 'https://www.wurrlyedu.com/privacy'

export const PRIVACY_ARRAY: { name: Privacy_Enum }[] = Object.entries(Privacy_Enum).map((e) => ({ name: e[1] }))
export const PRIVACY_LABELS: Record<Privacy_Enum, string> = {
	[Privacy_Enum.Class]: 'Class',
	[Privacy_Enum.OnlyMe]: 'Only Me',
	[Privacy_Enum.TeacherAndMe]: 'Teacher & Me'
}

export const MAX_AMOUNT_STUDENT_WRONG_LOGIN_ANSWER = 1

export const AVATARS = [
	'resources/Avatars/avatar-0.svg',
	'resources/Avatars/avatar-1.svg',
	'resources/Avatars/avatar-2.svg',
	'resources/Avatars/avatar-3.svg',
	'resources/Avatars/avatar-4.svg',
	'resources/Avatars/avatar-5.svg',
	'resources/Avatars/avatar-6.svg',
	'resources/Avatars/avatar-7.svg',
	'resources/Avatars/avatar-8.svg',
	'resources/Avatars/avatar-9.svg',
	'resources/Avatars/avatar-10.svg',
	'resources/Avatars/avatar-11.svg',
	'resources/Avatars/avatar-12.svg',
	'resources/Avatars/avatar-13.svg'
]

export const GOALSICONS = [
	'resources/GoalIcons/Background.svg',
	'resources/GoalIcons/Confetti.svg',
	'resources/GoalIcons/DartBoard.svg'
]
export const LEVELS = [
	'PK',
	'K',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'I',
	'II',
	'III',
	'S',
	'N',
	'In'
]

export const TEACHING_ENVS = {
	school: 'School Teacher',
	camp: 'Camp Teacher',
	private: 'Private Teacher'
} as Record<`${Role_Name_Enum}`, string>

export const RECORD_PREVIEW_THUMBNAIL = {
	width: 135,
	height: 86
}

export const RECORD_SELECT_THUMBNAIL = {
	width: 212,
	height: 155
}

export const PROFILE_AVATAR_SIZE = '8em'

export const MAX_STICKERS = 10

export const DIVA_FILTER_BORDER = `${AWS_S3_RESOURCES}/Filters/Diva.png`

export const NO_CACHE: Pick<BaseQueryOptions, 'fetchPolicy'> = { fetchPolicy: 'no-cache' }

export const ROOT_CHORDS = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B']

export const CHORD_QUALITIES = [
	'M',
	'm',
	'6',
	'Maj7',
	'm7',
	'7',
	'5',
	'sus4',
	'm7b5',
	'dim',
	'7sus4',
	'7alt',
	'm6',
	'sus2',
	'Maj9',
	'9',
	'm9',
	'#9',
	'b9',
	'Maj11',
	'11',
	'Maj#11',
	'M/Maj7',
	'm11',
	'#11',
	'+',
	'+7'
]

export const DEFAULT_AUDIO_SPEED_STEPS = 0.1
export const MAX_AUDIO_SPEED_SLOW = -0.2
export const MAX_AUDIO_SPEED_FAST = 0.2

// The left position of the slider it self
export const INITIAL_X_OFFSET = 100

// The position of the chord in case there is no overlap
export const CHORD_MAIN_Y_POS = 100

// The position of the chord in case it overlaps with the previous chord
export const CHORD_ASC_Y_POS = 40

// The position of the lyric in case there is no overlap
export const LYRIC_MAIN_Y_POS = 120

// The position of the lyric in case there is no overlap and is on audio
export const LYRIC_MAIN_Y_POS_AUDIO = 170

// The position of the lyric in case it overlaps with the previous lyric and is not audio
export const LYRIC_DESC_Y_POS = 90

// The position of the lyric in case it overlaps with the previous lyric and is audio
export const LYRIC_DESC_Y_POS_AUDIO = 140

// The position of the lyric in case it overlaps with the anteultimate lyric and is not audio
export const LYRIC_SECOND_DESC_Y_POS = 60

// The position of the lyric in case it overlaps with the anteultimate lyric and is audio
export const LYRIC_SECOND_DESC_Y_POS_AUDIO = 110

export const MILISECONDS_TO_MINUTES = 60000

export const TERMS_URL = 'https://www.wurrlyedu.com/terms'

export const URL_CONTACT_US = 'https://www.wurrly.com/cta/contact-us'

export const DEFAULT_THUMBNAIL_NAME = 'default-thumbnail.svg'

export const YOUTUBE_COM = 'youtube.com'
export const YOUTUBE_BE = 'youtu.be'

export const BAMBOO_VIDEO_COM = 'bamboo-video.com'
