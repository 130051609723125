import React, { useEffect, useState, Fragment } from 'react'

import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Fab,
	FormControlLabel,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import TimerIcon from '@material-ui/icons/Timer'
import noop from 'lodash/fp/noop'
import { matchPath } from 'react-router'
import { useDebounce } from 'react-use'
import { ChordMapObjectType, RecordStudioLocationState } from 'src/@types'
import { FullBandIcon, GuitarIcon, PianoIcon } from 'src/assets/icons/CustomIcons'
import { BaseDialog, BaseSlider, SelectChord, BigBaseButton } from 'src/components'
import { useCreateRoyaltyLogMutation } from 'src/graphql/autogenerate/hooks'
import { Screen_Type_Enum, Song, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useSearchQuery } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { Pages as TeacherPages } from 'src/routes/teacherPages'
import { RouteNotFound } from 'src/scenes/Errors/404.container'
import {
	buildImagePath,
	getCyKey,
	getSongGenres,
	SONG_KEYS,
	buildRouteParameters,
	getAudioUrl,
	TrackTypeEnum
} from 'src/utils'

import { StudioHeader } from './StudioHeader'
import useStyles from './StudioSongPlayer.styles'
import { useStudioSetupContext } from './useStudioSetup'

type StudioSongPlayerProps = {
	selectedChords?: string[]
	isTeacher?: boolean
}

export type StudioSongPlayerInitialState = {
	vocalGuide?: string
	instrument?: string
	speed?: number
	key?: number
}

export const StudioSongPlayer = ({ selectedChords, isTeacher }: StudioSongPlayerProps) => {
	const instruments = [
		{
			id: TrackTypeEnum.Piano,
			name: 'Piano',
			icon: <PianoIcon />
		},
		{
			id: TrackTypeEnum.Band,
			name: 'Full Band',
			icon: <FullBandIcon />
		},
		{
			id: TrackTypeEnum.Guitar,
			name: 'Guitar',
			icon: <GuitarIcon />
		}
	]
	const [audio, setAudio] = useState(new Audio(''))
	const [loaded, setLoaded] = useState(false)
	const [playing, setPlaying] = useState(false)
	const [progress, setProgress] = useState(0)
	const [currentTime, setCurrentTime] = useState(0)
	const [duration, setDuration] = useState(0)
	const [usedParams, setUsedParams] = useState(false)
	const [currentTrackId, setCurrentTrackId] = useState<number>()
	const [audioStartTime, setAudioStartTime] = useState<Date>()
	const [shouldHandleRoyalty, setShouldHandleRoyalty] = useState(false)
	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()
	const [justVocalGuide, setJustVocalGuide] = useState(false)
	const {
		setChords,
		chords,
		vocalGuide,
		setVocalGuide,
		instrument,
		setInstrument,
		speed,
		setSpeed,
		key,
		setKey,
		history,
		songId,
		getSong,
		songLoading,
		music,
		classId,
		submissionId,
		challengeId,
		loadingMusic
	} = useStudioSetupContext()
	const {
		teacherData: { teacher_id },
		studentData: { student_id }
	} = useLoginContext()
	const styles = useStyles({ loaded })
	const queryParams = useSearchQuery()

	useEffect(() => {
		if (!usedParams) {
			const initialSpeed = queryParams.get('speed')
			const initialKey = queryParams.get('key')
			const initialInstrument = queryParams.get('instrument')
			const initialVocalGuide = queryParams.get('vocalGuide')

			if (initialSpeed) setSpeed(Number(initialSpeed))
			if (initialKey) setKey(Number(initialKey))
			if (initialInstrument) setInstrument(initialInstrument)
			if (initialVocalGuide) setVocalGuide(initialVocalGuide === 'true')
		}
	}, [queryParams])

	useEffect(() => {
		return () => audio.pause()
	}, [audio])

	useEffect(() => {
		if (songId) {
			getSong()
		}
	}, [songId])

	useEffect(() => {
		if (!songLoading && music) {
			let transposition = 0
			let instrumentSelected = ''
			const justIncludeVocalGuide =
				music.tracks.length === 1 && music.tracks[0].track_type.name === TrackTypeEnum.Vocal

			setJustVocalGuide(justIncludeVocalGuide)
			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum.Band)) {
				instrumentSelected = TrackTypeEnum.Band
			}
			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum.Guitar)) {
				instrumentSelected = TrackTypeEnum.Guitar
			}
			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum.Piano)) {
				instrumentSelected = TrackTypeEnum.Piano
			}
			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum?.Vocal)) {
				instrumentSelected = TrackTypeEnum?.Band
			}

			setInstrument(instrumentSelected)

			const track = music.tracks.find(
				(track) =>
					track.track_type.name === instrumentSelected || track.track_type.name === TrackTypeEnum?.Vocal
			)

			if (selectedChords && selectedChords.length > 0) {
				const chord_map = Object.values(track?.midis[0]?.chord_map_object as ChordMapObjectType).find(
					(chordMap) => {
						return (
							new Set([...chordMap.chordArray, ...selectedChords]).size ===
							chordMap.chordArray.length
						)
					}
				)
				transposition = chord_map ? chord_map.transposition : 0
				setKey(transposition)
			} else {
				setChords(track?.midis?.flatMap((midi) => midi.chords_Array_Zero?.split(',') || []))
			}
			setAudio(new Audio(getAudioUrl(0.0, transposition, instrumentSelected, music)))
		}
	}, [music, songLoading, selectedChords])

	useEffect(() => {
		setLoaded(false)
		if (playing) {
			audio.pause()
		}

		if (music) {
			audio.src = getAudioUrl(
				speed,
				key,
				vocalGuide || justVocalGuide ? TrackTypeEnum.Vocal : instrument,
				music
			)
			if (playing) {
				audio.playbackRate = speed + 1
				audio.currentTime = currentTime
				audio.play()
			}
			const track = music.tracks.find(
				(track) => track.track_type.name === instrument || track.track_type.name === TrackTypeEnum?.Vocal
			)
			if (track) {
				setChords(((track?.midis[0]?.chord_map_object || {}) as ChordMapObjectType)[key]?.chordArray || [])
				setCurrentTrackId(track?.track_id)
			}
		}
	}, [key, speed, instrument, vocalGuide, music])

	const handleLoadedData = () => {
		setLoaded(true)
		setDuration(audio.duration)
	}
	const handleAudioTimeUpdate = () => {
		setProgress((audio.currentTime * 100) / audio.duration) // percent
		setCurrentTime(audio.currentTime) // seconds
	}
	const handleAudioEnded = () => {
		setPlaying(false)
		setShouldHandleRoyalty(true)
	}

	useEffect(() => {
		audio.addEventListener('loadeddata', handleLoadedData)
		audio.addEventListener('timeupdate', handleAudioTimeUpdate)
		audio.addEventListener('ended', handleAudioEnded)

		return () => {
			audio.removeEventListener('loadeddata', handleLoadedData)
			audio.removeEventListener('timeupdate', handleAudioTimeUpdate)
			audio.removeEventListener('ended', handleAudioEnded)
		}
	}, [audio])

	const renderInstrumentList = () => {
		return instruments.map((inst) => (
			<Grid item xs key={inst.id}>
				<Box
					boxShadow={
						!music?.tracks.some((track) => track.track_type.name === inst.id) ||
						(instrument !== inst.id && vocalGuide)
							? 1
							: 0
					}>
					<Button
						data-cy={getCyKey(StudioSongPlayer, inst.name)}
						style={{
							height: 72,
							color: instrument === inst.id ? '#111' : 'inherit', // negro
							position: 'relative',
							borderWidth: instrument === inst.id ? 2 : undefined,
							backgroundColor: instrument === inst.id ? 'rgba(255, 194, 12, 0.1)' : '#fff'
						}}
						fullWidth
						disabled={
							!music?.tracks.some((track) => track.track_type.name === inst.id) ||
							(instrument !== inst.id && vocalGuide)
						}
						startIcon={inst.icon}
						variant={instrument === inst.id ? 'outlined' : 'contained'}
						color={instrument === inst.id ? 'secondary' : undefined}
						onClick={() => handleSelectInstrument(inst.id)}>
						{inst.name}
						{instrument === inst.id && (
							<Box position="absolute" top={8} right={8}>
								<CheckCircleIcon fontSize="small" color="secondary" />
							</Box>
						)}
					</Button>
				</Box>
			</Grid>
		))
	}

	const formatTime = (seconds: number) => {
		const seg = Math.floor(seconds)
		const min = Math.floor(seg / 60)
		const hor = Math.floor(min / 60)

		const twoDigits = (num: number) => `0${num}`.slice(-2)

		return `${hor > 0 ? `${twoDigits(hor)}:` : ''}${twoDigits(min % 60)}:${twoDigits(seg % 60)}`
	}

	const handleTimeChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		const val = ((value as number) * duration) / 100
		audio.currentTime = +val.toFixed(4)
	}

	const handleControl = () => {
		if (playing) {
			audio.pause()
			setPlaying(false)
			handleRoyaltyLog()
		} else {
			audio.playbackRate = speed + 1
			audio.play()
			setPlaying(true)
			setAudioStartTime(new Date())
		}
	}

	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const playSeconds = (audioPauseTime.getTime() - (audioStartTime ?? audioPauseTime).getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId: currentTrackId ?? 0,
					songId: songId ?? 0,
					teacherId: teacher_id || null,
					studentId: student_id || null,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.VideoStudioSetup
				}
			})
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
		setAudioStartTime(undefined)
	}

	useDebounce(
		() => {
			if (shouldHandleRoyalty) {
				setShouldHandleRoyalty(false)
				handleRoyaltyLog()
			}
		},
		200,
		[shouldHandleRoyalty]
	)

	const handleActivate = () => {
		if (!vocalGuide) {
			setInstrument('Band')
		}
		setVocalGuide(!vocalGuide)
		setUsedParams(true)
	}

	const handleSelectInstrument = (inst: string) => {
		if (!vocalGuide) {
			setInstrument(inst)
			setUsedParams(true)
		}
	}
	const handleSpeedChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		setSpeed(value as number)
		setUsedParams(true)
	}

	const handleKeyChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		setKey(value as number)
		setUsedParams(true)
	}

	const getWurrlyType = () => {
		const currentPath = history.location.pathname
		switch (true) {
			case !!matchPath(currentPath, StudentPages.AssignmentStudentStudio):
				return Wurrly_Type_Enum.Assignment
			case !!matchPath(currentPath, StudentPages.IndieStudentStudio):
				return Wurrly_Type_Enum.Indie
			case !!matchPath(currentPath, StudentPages.ChallengeStudentStudio):
				return Wurrly_Type_Enum.Challenge
			case !!matchPath(currentPath, TeacherPages.TeacherStudio):
			default:
				return Wurrly_Type_Enum.Indie
		}
	}

	const handleClick = () => {
		history.push({
			pathname: buildRouteParameters(isTeacher ? TeacherPages.TeacherRecord : StudentPages.StudentRecord, {
				songId
			}),
			state: {
				speed,
				transposition: key,
				instrument: vocalGuide ? 'Vocal' : instrument,
				classId,
				submissionId,
				challengeId,
				wurrlyType: getWurrlyType()
			} as RecordStudioLocationState
		})
	}

	const leaveStudio = () => {
		history.push(buildRouteParameters(isTeacher ? TeacherPages.Home : StudentPages.Home))
	}

	return !loadingMusic && !music ? (
		<RouteNotFound />
	) : (
		<BaseDialog
			maxWidth="md"
			data-cy={getCyKey(StudioSongPlayer)}
			fullWidth={true}
			onClose={noop}
			open
			paperProps={{ className: styles.container }}
			backdropStyles={{ opacity: '0 !important' }}
			header={
				<>
					<StudioHeader
						title="Set Up"
						description="You can tailor the song to suit you, by choosing the accompaniment, speed and key below, then click Next to get started!"
					/>
					{!songLoading && (
						<Fragment>
							<ListItem className={styles.listItem}>
								<ListItemAvatar>
									<Avatar
										variant="rounded"
										className={styles.avatar}
										src={buildImagePath(music?.image_path || '')}
									/>
								</ListItemAvatar>
								<Box width="100%">
									<ListItemText
										className={styles.listItemText}
										primary={
											<div>
												<Typography variant="h6">
													<b>{music?.title}</b>
												</Typography>
												<Typography className={styles.tipoArtist}>
													<b>{music?.artist.name}</b>
												</Typography>
											</div>
										}
										secondary={music ? getSongGenres(music as unknown as Song) : ''}
									/>
									<Box className={styles.boxFab}>
										<Fab
											data-cy={getCyKey(StudioSongPlayer, 'Fab')}
											className={styles.fab}
											size="medium"
											onClick={handleControl}>
											{loaded ? (
												playing ? (
													<PauseIcon
														data-cy={getCyKey(StudioSongPlayer, 'PauseIcon')}
														color="secondary"
													/>
												) : (
													<PlayArrowIcon
														data-cy={getCyKey(StudioSongPlayer, 'PlayArrowIcon')}
														color="secondary"
													/>
												)
											) : (
												<CircularProgress
													color="secondary"
													className={styles.circularProgress}
												/>
											)}
										</Fab>
										<Box className={styles.boxSongTime} boxShadow={1}>
											<Typography
												className={styles.tipoSongTime}
												data-cy={getCyKey(StudioSongPlayer, 'SongTimes')}>
												{formatTime(currentTime)} / {formatTime(duration)}
											</Typography>
											<Box className={styles.boxSlider}>
												<BaseSlider
													data-cy={getCyKey(StudioSongPlayer, 'SongSlider')}
													value={progress}
													onChange={handleTimeChange}
													aria-labelledby="continuous-slider"
													color="secondary"
												/>
											</Box>
										</Box>
									</Box>
								</Box>
							</ListItem>
						</Fragment>
					)}
				</>
			}
			body={
				<Box style={{ width: 'calc(100% - 8px)' }}>
					<Box className={styles.boxBody}>
						<Typography>
							<b>Choose your accompaniment</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />

						<FormControlLabel
							className={styles.vocalGuide}
							control={
								<Checkbox
									data-cy={getCyKey(StudioSongPlayer, `ActivateVocalGuide`)}
									checked={vocalGuide || justVocalGuide}
									onChange={handleActivate}
									name="Activate Vocal Guide"
									color="secondary"
									disabled={
										!music?.tracks.some((track) => track.track_type.name === 'Vocal') ||
										justVocalGuide
									}
								/>
							}
							label={<Typography className={styles.tipoActivate}>Activate Vocal Guide?</Typography>}
						/>
					</Box>
					<Grid container spacing={1}>
						{renderInstrumentList()}
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs>
							<Box mt={2.5} mb={1}>
								<Typography>
									<b>Speed</b>
								</Typography>
							</Box>
							<Box className={styles.boxTimer} boxShadow={1}>
								<TimerIcon fontSize="small" />
								<Box className={styles.boxContainerSlider}>
									<BaseSlider
										data-cy={getCyKey(StudioSongPlayer, 'SpeedSlider')}
										value={speed}
										onChange={handleSpeedChange}
										aria-labelledby="discrete-slider"
										color="secondary"
										valueLabelDisplay="off"
										step={0.1}
										marks
										min={-0.3}
										max={0.3}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item xs>
							<Box mt={2.5} mb={1}>
								<Typography>
									<b>Key</b>
								</Typography>
							</Box>
							<Box className={styles.boxSliderKey} boxShadow={1}>
								<MusicNoteIcon fontSize="small" />
								<Box className={styles.boxContainerSlider}>
									<BaseSlider
										data-cy={getCyKey(StudioSongPlayer, 'Slider')}
										value={key}
										onChange={handleKeyChange}
										aria-labelledby="discrete-slider"
										color="secondary"
										valueLabelDisplay="on"
										step={1}
										marks
										min={SONG_KEYS[0]}
										max={SONG_KEYS[SONG_KEYS.length - 1]}
									/>
								</Box>
							</Box>
						</Grid>
					</Grid>
					{chords && !!chords.length && (
						<>
							<Box className={styles.boxChords}>
								<Typography>
									<b>Corresponding Chords</b>
								</Typography>
							</Box>
							<SelectChord
								showGreyButtons
								showSelectedOnly
								selectedChords={chords}
								disabled
								columns={8}
							/>
						</>
					)}
				</Box>
			}
			rightActions={
				<Box className={styles.boxNextBtn}>
					<BigBaseButton
						className={styles.nextBtn}
						data-cy={getCyKey(StudioSongPlayer, 'Corresponding Chords')}
						variant="contained"
						onClick={handleClick}
						color="secondary">
						Next
					</BigBaseButton>
				</Box>
			}
			discardLabel="Close Studio"
			onDiscard={leaveStudio}
		/>
	)
}
