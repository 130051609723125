import { useEffect, useState } from 'react'

import moment from 'moment'
import { SubscriptionsCardType } from 'src/@types'

export const useSubscriptionsCard = (data: SubscriptionsCardType) => {
	const [expiringDate, setExpiringDate] = useState('')
	const [isSubscribed, setIsSubscribed] = useState(false)

	useEffect(() => {
		setIsSubscribed(data.status === 'active')
	}, [data.status])
	useEffect(() => {
		if (data.expiresAt) {
			const expiringDate = data.expiresAt.toString()
			const expiringDateObj = new Date(expiringDate)
			const currentDateObj = new Date()
			const remainingDays = Math.floor(
				(expiringDateObj.getTime() - currentDateObj.getTime()) / (1000 * 60 * 60 * 24)
			)
			if (remainingDays <= 0) {
				setExpiringDate(`Expired on ${moment(expiringDateObj).format('MMM D, YYYY')}`)
			}
			if (remainingDays === 1) {
				setExpiringDate(`Expires tomorrow`)
			} else if (remainingDays <= 6 && remainingDays > 1) {
				setExpiringDate(`${remainingDays} days left`)
			} else {
				setExpiringDate(`${moment(expiringDateObj).format('MMMM D, YYYY')}`)
			}
		}
	}, [data.expiresAt])

	return {
		expiringDate,
		isSubscribed
	}
}
