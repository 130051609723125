import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { AssignmentSortOrder } from 'src/utils'

import useStyles from './AssignmentSort.styles'
type AssignmentSortProps = {
	value: AssignmentSortOrder
	onChange?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => void
	dateSort?: boolean
}

export const AssignmentSort = ({ value, onChange, dateSort = true }: AssignmentSortProps) => {
	const styles = useStyles()

	const options = [
		{ name: 'A-Z', value: AssignmentSortOrder.AlphAsc },
		{ name: 'Z-A', value: AssignmentSortOrder.AlphDesc }
	]

	if (dateSort) {
		options.push({ name: 'Due Date ↑', value: AssignmentSortOrder.DateAsc })
		options.push({ name: 'Due Date ↓', value: AssignmentSortOrder.DateDesc })
	}

	return (
		<Box display="flex" alignItems="center">
			<Typography className={styles.label}>Sort By</Typography>
			<Select options={options} onChange={onChange} value={value} />
		</Box>
	)
}
