import { gql } from '@apollo/client'
import {
	Catalog,
	Catalog_Item,
	Grade,
	Keyword,
	Musical_Skill,
	Non_Musical_Skill,
	Teacher_Lesson_Plan_Favorite,
	Teacher_Song_Favorite,
	Teacher_Tip_Favorite
} from 'src/graphql/autogenerate/schemas'
import { lessonFragment, songReducedFragment, tipFragment } from 'src/scenes/Teacher/fragments'
import { TypeEnum } from 'src/utils'

export const QueryCatalogItems = (type: TypeEnum) => gql`
	query getCatalogItems{
		catalog_item(
			where: {
				active: {_eq: true},
				catalog: {${type}_order: {_is_null: false}}
			},
			order_by: {
				catalog: {${type}_order: asc},
				order: asc
			})
		{
			catalog_item_id
			catalog {
				catalog_id
				name
			}
			image_path
			icon_path
			name
			order
			active
		}
	}
`
export const QueryCatalogs = (type: TypeEnum) => gql`
	query {
		catalog(where: { ${type}_order: {_is_null: false}}, order_by: {${type}_order: asc}) {
			name
			catalog_items(where: {active: {_eq: true}}, order_by: { order: asc} ) {
				catalog_item_id
				name
				active
			}
			${type}_order
		}
	}
`

export const QueryCatalogsByCatalogId = (type: TypeEnum, id: number) => gql`
	query {
		catalog_item(
			where: {
				catalog_id: {_eq: ${id}},
				catalog: {${type}_order: {_is_null: false}},
				active: {_eq: true}
			},
			order_by: {
				catalog: {${type}_order: asc},
				order: asc
			})
		{
			catalog_id
			catalog_item_id
			name
		}
	}
`

export const QueryKeyword = (type: TypeEnum) => gql`
	query {
		keyword(
			where: {
				${type}_order: {_is_null: false}
			},
			order_by: {
				${type}_order: asc
			})
		{
			name
			keyword_id
		}
	}

`

export const mutationInsertFavorite = (type: TypeEnum) => gql`
	mutation insertFavorite($teacherId: Int, $itemId: Int) {
		insert_teacher_${type}_favorite(objects: { ${type}_id: $itemId, teacher_id: $teacherId }) {
			returning {
				teacher_id
				${type}{
					teacher_id
					${type}_id
					is_favorite(args: {teacherid: $teacherId})
				}
			}
		}
	}
`

export const mutationDeleteFavorite = (type: TypeEnum) => gql`
	mutation deleteFavorite($teacherId: Int!, $itemId: Int!) {
		delete_teacher_${type}_favorite(where: {${type}_id: {_eq: $itemId}, teacher_id: {_eq: $teacherId}}) {
			returning {
				teacher_id
				${type}{
					teacher_id
					${type}_id
					is_favorite(args: {teacherid: $teacherId})
				}
			}
		}
	}
`

export const queryGrade = gql`
	query {
		grade(order_by: { grade_id: asc }) {
			grade_id
			name
		}
	}
`
export const queryMusicalSkill = gql`
	query {
		musical_skill(order_by: { name: asc }) {
			musical_skill_id
			name
		}
	}
`

export const queryNonMusicalSkill = gql`
	query {
		non_musical_skill(order_by: { name: asc }) {
			non_musical_skill_id
			name
		}
	}
`

export const queryFavoriteSongs = gql`
	query getFavoriteSongs($teacherId: Int, $limit: Int, $orderTitle: order_by, $orderDate: order_by) {
		teacher_song_favorite(
			where: { teacher_id: { _eq: $teacherId }, song: { song_id: { _is_null: false } } }
			limit: $limit
			order_by: { song: { title: $orderTitle }, updated_at: $orderDate }
		) {
			song {
				is_favorite(args: { teacherid: $teacherId })
				...songReducedFragment
			}
		}
		teacher_song_favorite_aggregate(
			where: { teacher_id: { _eq: $teacherId }, song: { song_id: { _is_null: false } } }
		) {
			aggregate {
				count
			}
		}
	}
	${songReducedFragment}
`

export const queryFavoriteTips = gql`
	query getFavoriteTips($teacherId: Int, $limit: Int, $orderTitle: order_by, $orderDate: order_by) {
		teacher_tip_favorite(
			where: { teacher_id: { _eq: $teacherId }, tip: { tip_id: { _is_null: false } } }
			limit: $limit
			order_by: { tip: { title: $orderTitle }, updated_at: $orderDate }
		) {
			tip {
				is_favorite(args: { teacherid: $teacherId })
				...tipFragment
			}
		}
		teacher_tip_favorite_aggregate(
			where: { teacher_id: { _eq: $teacherId }, tip: { tip_id: { _is_null: false } } }
		) {
			aggregate {
				count
			}
		}
	}
	${tipFragment}
`

export const queryFavoriteLessons = gql`
	query getFavoriteLessons($teacherId: Int, $limit: Int, $orderTitle: order_by, $orderDate: order_by) {
		teacher_lesson_plan_favorite(
			where: { teacher_id: { _eq: $teacherId }, lesson_plan: { lesson_plan_id: { _is_null: false } } }
			limit: $limit
			order_by: { lesson_plan: { title: $orderTitle }, updated_at: $orderDate }
		) {
			lesson_plan {
				is_favorite(args: { teacherid: $teacherId })
				class_lesson_plans {
					class_lesson_plan_id
					class {
						class_id
						title
					}
				}
				...lessonFragment
			}
		}
		teacher_lesson_plan_favorite_aggregate(
			where: { teacher_id: { _eq: $teacherId }, lesson_plan: { lesson_plan_id: { _is_null: false } } }
		) {
			aggregate {
				count
			}
		}
	}
	${lessonFragment}
`

export type QueryFavoriteSongsType = {
	teacher_song_favorite: Teacher_Song_Favorite[]
}

export type QueryFavoriteTipsType = {
	teacher_tip_favorite: Teacher_Tip_Favorite[]
}

export type QueryFavoriteLessonsType = {
	teacher_lesson_plan_favorite: Teacher_Lesson_Plan_Favorite[]
}

export type QueryNonMusicalSkillType = {
	non_musical_skill: Non_Musical_Skill[]
}

export type QueryMusicalSkillType = {
	musical_skill: Musical_Skill[]
}

export type QueryGradeType = {
	grade: Grade[]
}

export type QueryKeywordType = {
	keyword: Keyword[]
}

export type QueryCatalogsType = {
	catalog: Catalog[]
}

export type QueryCatalogItemsByCatalogId = {
	catalog_item: Catalog_Item[]
}
