import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useListItemAssignmentStyles = makeStyles((theme: Theme) =>
	createStyles({
		toolbar: {
			gap: '20px'
		},
		classSelect: {
			flexGrow: 1
		},
		listItem: {
			minHeight: 100,
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		date: {
			display: 'flex',
			alignItems: 'center',
			marginTop: '10px',
			color: theme.palette.grey[400]
		},
		avatar: {
			marginTop: 7,
			marginBottom: 6,
			width: '70px'
		},
		itemTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word',
			fontSize: 18
		},
		avatarIcon: {
			height: '60px',
			width: '60px'
		},
		classTitle: { fontSize: 12 },
		divStyle: {
			flexGrow: 1,
			display: 'flex',
			alignItems: 'space-between',
			justifyContent: 'center'
		},
		instructions: {
			WebkitLineClamp: 1,
			textOverflow: 'ellipsis',
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word',
			fontSize: 14,
			padding: '5px 0',
			whiteSpace: 'pre-wrap'
		},
		calender: {
			marginTop: 15
		},
		gradeValue: {
			color: '#00BA70',
			textTransform: 'capitalize',
			position: 'absolute',
			top: '52%',
			left: '50%',
			transform: 'translate3D(-50%,-50%,0)',
			width: '45px',
			overflowWrap: 'break-word',
			textAlign: 'center',
			lineHeight: '1',
			fontSize: '14px'
		},
		activityEmpty: {
			backgroundColor: theme.palette.common.white,
			padding: '3em'
		},
		relative: { position: 'relative', height: '60px', width: '60px' }
	})
)

export default useListItemAssignmentStyles
