import { Box, Grid } from '@material-ui/core'
import { ImageActionCard } from 'src/components'
import { CanvasThumbnail } from 'src/components/CanvasThumbnail/CanvasThumbnail'
import { OverlayFilter } from 'src/components/Studio/Preview/ColorFilters'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { buildFrameUrl, DEFAULT_THUMBNAILS } from 'src/utils'

export const SelectWurrlyThumbnail = () => {
	const {
		selectedThumbnail,
		setSelectedThumbnail,
		videoThumbnails,
		canvasDimensions,
		selectedFrameFilter,
		updatedStickers,
		selectedColorFilter,
		processedThumbnails,
		addProcessedThumbnail
	} = useVideoSubmitContext()

	const handleSelect = (isDefaultThumbnail: boolean, index: number) => {
		setSelectedThumbnail({ default: isDefaultThumbnail, index })
	}

	return (
		<Box>
			<OverlayFilter filter={selectedColorFilter?.name} generatingThumbnails />
			<div>
				{videoThumbnails.map((thumbnail, index) => (
					<CanvasThumbnail
						key={thumbnail}
						imageUrl={thumbnail}
						canvasWidth={canvasDimensions.width}
						canvasHeight={canvasDimensions.height}
						frameUrl={
							selectedFrameFilter?.image_path
								? buildFrameUrl(selectedFrameFilter?.image_path)
								: undefined
						}
						stickers={updatedStickers}
						colorFilter={selectedColorFilter?.name}
						onFilterApplied={(url: string) => addProcessedThumbnail(url, index)}
					/>
				))}
			</div>
			<Grid container spacing={2}>
				{processedThumbnails.map((imgSrc, i) => (
					<Grid item xs={3} key={`generated_thumbnail_${i}`}>
						<ImageActionCard
							option={{ id: `generated_thumbnail_${i}`, imagePath: imgSrc }}
							isSelected={!selectedThumbnail?.default && selectedThumbnail?.index === i}
							handleClick={() => handleSelect(false, i)}
						/>
					</Grid>
				))}
				{DEFAULT_THUMBNAILS.map((imgSrc, i) => (
					<Grid item xs={3} key={`generated_thumbnail_${i}`}>
						<ImageActionCard
							option={{ id: `default_thumbnail_${i}`, imagePath: imgSrc }}
							isSelected={selectedThumbnail?.default && selectedThumbnail?.index === i}
							handleClick={() => handleSelect(true, i)}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	)
}
