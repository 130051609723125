import { Box, Button, ButtonProps, Tooltip, Typography } from '@material-ui/core'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import useSelectAddProductDialog from 'src/components/Dialogs/SelectAddProductDialog/useSelectAddProductDialog.styles'

import { BaseDialog, BaseDialogProps } from '../BaseDialog/BaseDialog'
type SelectProductDialogProps = BaseDialogProps & {
	actions: ({
		name?: string
		tooltipText?: string
	} & ButtonProps)[]
	handleCancel?: () => void
}
export const SelectAddProductDialog = ({ actions, handleCancel, ...props }: SelectProductDialogProps) => {
	const styles = useSelectAddProductDialog()

	return (
		<BaseDialog
			dividers={false}
			maxWidth="md"
			{...props}
			header={
				<Box display="flex" flexDirection="column" alignItems="center">
					<Typography className={styles.title}>
						<strong>Add to Class</strong>
					</Typography>
				</Box>
			}
			body={
				<Box className={styles.container}>
					{actions.map((btn, index) => {
						const actionsBtn = (
							<Button
								key={index + '-button-' + btn.name}
								className={styles.btn}
								variant={btn.variant}
								style={{
									...btn.style
								}}
								color={btn.color}
								startIcon={btn.startIcon ?? undefined}
								endIcon={btn.endIcon}
								onClick={btn.onClick}
								disabled={btn.disabled}>
								{btn.name}
							</Button>
						)

						const buttonToDisplay = btn.tooltipText ? (
							<Tooltip key={index + '-tooltip-' + btn.name} title={btn.tooltipText as string}>
								<Box>{actionsBtn}</Box>
							</Tooltip>
						) : (
							actionsBtn
						)

						return buttonToDisplay
					})}
				</Box>
			}
			footer={
				props.footer || (
					<Box className={styles.footer}>
						<BaseButton onClick={handleCancel} size="large">
							Close
						</BaseButton>
					</Box>
				)
			}
		/>
	)
}
