import { ChangeEvent, useEffect, useState } from 'react'

import { Box, Card, CircularProgress, Fab, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { BaseSlider } from 'src/components'
import { useCreateRoyaltyLogMutation } from 'src/graphql/autogenerate/hooks'
import { Screen_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { secondsToTimeString } from 'src/utils'

import useStyles from './MiniSongPlayer.styles'
type MiniSongPlayerProps = {
	audioSrc: string
	songId: number
	trackId: number
	setSongPlaying?: React.Dispatch<React.SetStateAction<number>>
	songPlaying?: number
}

export const MiniSongPlayer = ({
	audioSrc,
	songId,
	trackId,
	songPlaying,
	setSongPlaying
}: MiniSongPlayerProps) => {
	const {
		studentData: { student_id }
	} = useLoginContext()
	const styles = useStyles()
	const [progress, setProgress] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	const [songError, setSongError] = useState(false)
	const [audio, setAudio] = useState(new Audio(''))
	const [currentTime, setCurrentTime] = useState(0)
	const [isPlaying, setIsPlaying] = useState(false)
	const [audioStartTime, setAudioStartTime] = useState<Date>()
	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()

	const playAudio = () => {
		audio.play()
		setAudioStartTime(new Date())
	}
	const pauseAudio = () => {
		audio.pause()
		handleRoyaltyLog()
	}
	const toggleAudio = (setSongPlaying: React.Dispatch<React.SetStateAction<number>> | undefined) => {
		if (setSongPlaying) setSongPlaying(songId)
		if (isPlaying) pauseAudio()
		else playAudio()
		setIsPlaying((prev) => !prev)
	}
	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const playSeconds = (audioPauseTime.getTime() - (audioStartTime ?? audioPauseTime).getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId,
					songId,
					teacherId: null,
					studentId: student_id,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.AssignmentDetail
				}
			})
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
		setAudioStartTime(undefined)
	}

	const MultimediaIcon = () => {
		if (songError) return <ErrorIcon color="secondary" />
		if (isLoading) return <CircularProgress color="secondary" />
		if (isPlaying) return <PauseIcon color="secondary" />

		return <PlayArrowIcon color="secondary" />
	}
	const handleLoadedData = () => setIsLoading(false)
	const handleSongError = () => setSongError(true)
	const handleTimeUpdate = () => {
		const time = audio.currentTime
		const prog = (time / audio.duration) * 100
		setCurrentTime(time)
		setProgress(prog)
	}
	const handleTimeSeek = (_e: ChangeEvent<unknown>, value: number | number[]) => {
		if (typeof value === 'number') audio.currentTime = (value * audio.duration) / 100
	}

	useEffect(() => {
		setAudio(new Audio(audioSrc))
	}, [audioSrc])

	useEffect(() => {
		audio.addEventListener('loadeddata', handleLoadedData)
		audio.addEventListener('error', handleSongError)
		audio.addEventListener('timeupdate', handleTimeUpdate)
		audio.addEventListener('ended', pauseAudio)

		return () => {
			audio.removeEventListener('loadeddata', handleLoadedData)
			audio.removeEventListener('error', handleSongError)
			audio.removeEventListener('timeupdate', handleTimeUpdate)
			audio.removeEventListener('ended', pauseAudio)
		}
	}, [audio])

	useEffect(() => {
		if (isPlaying && songPlaying !== songId) toggleAudio(undefined)
	}, [songPlaying])

	return (
		<Box className={styles.container}>
			<Fab
				className={styles.playPauseButton}
				size="medium"
				onClick={() => toggleAudio(setSongPlaying)}
				disabled={songError}>
				<MultimediaIcon />
			</Fab>
			<Card className={styles.progressContainer}>
				<Typography className={styles.songTime}>
					{secondsToTimeString(currentTime)} / {secondsToTimeString(audio.duration)}
				</Typography>
				<BaseSlider
					className={styles.progress}
					value={progress}
					color="secondary"
					onChange={handleTimeSeek}
					disabled={songError}
				/>
			</Card>
		</Box>
	)
}
