import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useWaveformStyles = makeStyles((theme: Theme) =>
	createStyles({
		backgroundContainer: {
			position: 'relative',
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center'
		},
		errorContainer: {
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			color: theme.palette.error.main
		},
		errorIcon: {
			margin: '.5em'
		},
		loader: { width: '100%', opacity: 0.5 }
	})
)

export default useWaveformStyles
