import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { ClassSongsSaved } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/components/ClassSongsSaved'
import { ClassSongsTitle } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/components/ClassSongsTitle'
import { ClassSongsProvider } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/useClassSongs'

export const ClassSongs = (props: PagesProps) => {
	return (
		<Box>
			<ClassSongsProvider>
				<ClassSongsTitle {...props} />
				<ClassSongsSaved />
			</ClassSongsProvider>
		</Box>
	)
}
