import { useEffect, useState } from 'react'

import { Box, CircularProgress, Typography, Link } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useHistory } from 'react-router-dom'
import { Page, PagesProps } from 'src/@types'
import { BigBaseButton, InfoDialog, MainTitle, Stepper } from 'src/components'
import { Lesson_Plan_Article_Insert_Input } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { useUpdateLessonMutation } from '../../../../../../graphql/autogenerate/hooks'
import { LessonFormDataType } from '../LessonUpload'
import { StepFiveForm } from '../LessonUpload/StepFiveForm'
import { StepFourForm } from '../LessonUpload/StepFourForm'
import { StepOneForm } from '../LessonUpload/StepOneForm'
import { StepThreeForm } from '../LessonUpload/StepThreeForm'
import { StepTwoForm } from '../LessonUpload/StepTwoForm'
import { useStyles as useSharedStyles } from '../LessonUpload/styles'
import { useInitialLessonData } from './useInitialLessonData'

const stepUsedInitialDataInitialValue = {
	1: false,
	2: false,
	3: false,
	4: false,
	5: false
}

export const LessonEdit = ({ page }: PagesProps) => {
	const params = useWurrlyParams<typeof Pages.ClassLessonDetail.params[number]>()
	const sharedClasses = useSharedStyles()
	const { teacherData } = useLoginContext()
	const history = useHistory()

	const lessonPlanId = Number(params.lessonPlanId)
	const parentPage: Page = Pages[(page.parentName ?? '') as keyof typeof Pages]
	const breadcrumbs = [parentPage, page]

	const [lessonData, setLessonData] = useState<LessonFormDataType>({})
	const [currentStep, setCurrentStep] = useState(1)
	const [onCancel, setOnCancel] = useState(false)
	const [stepOneIsValid, setStepOneIsValid] = useState(false)
	const [stepFourIsValid, setStepFourIsValid] = useState(false)
	const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true)
	const [uploadSuccess, setUploadSuccess] = useState(false)
	const [uploadError, setUploadError] = useState(false)
	const [assetsIdsToDelete, setAssetsIdsToDelete] = useState<number[]>([])
	const [stepsUsedInitialData, setStepsUsedInitialData] = useState<Record<number, boolean>>(
		stepUsedInitialDataInitialValue
	)
	const { initialLessonData, loading, getRelationsToAdd, getRelationsToDelete } = useInitialLessonData({
		lessonPlanId
	})
	const [updateLesson, { loading: updateLessonLoading }] = useUpdateLessonMutation()

	const addAssetIdToRemove = (assetId: number) => {
		if (!assetsIdsToDelete.includes(assetId)) setAssetsIdsToDelete((prev) => [...prev, assetId])
	}
	const saveData = (stepData: LessonFormDataType) => {
		setLessonData({ ...lessonData, ...stepData })
	}

	const saveLesson = async () => {
		const mutationVariables = buildMutationParams()
		try {
			await updateLesson({ variables: mutationVariables })
			setUploadSuccess(true)
		} catch (error) {
			console.error('Error updating lesson', error)
			setUploadError(true)
		}
	}

	const buildMutationParams = () => {
		const {
			title,
			description,
			image_path,
			inspire,
			practice,
			record,
			reflect,
			takeItFurther,
			materials,
			differentiations,
			suggestedAssignments
		} = lessonData

		const assets = {
			data: lessonData.assets?.map((asset) => ({ asset: { data: asset } })) ?? []
		}
		const articles = [
			...(lessonData.stepTwoArticles?.map<Lesson_Plan_Article_Insert_Input>((article) => ({
				title: article.title || '',
				content: article.content || '',
				lesson_plan_id: lessonPlanId,
				order: article.order
			})) || []),
			...(lessonData.stepThreeArticles?.map<Lesson_Plan_Article_Insert_Input>((article) => ({
				title: article.title || '',
				content: article.content || '',
				lesson_plan_id: lessonPlanId,
				order: article.order
			})) || [])
		]

		return {
			teacherId: teacherData.teacher_id,
			lessonPlanId,
			title,
			description,
			imagePath: image_path,
			inspire,
			practice,
			record,
			reflect,
			takeItFurther,
			materials,
			differentiations,
			suggestedAssignments,
			discipline:
				lessonData.discipline?.map((discipline) => ({
					discipline_id: discipline.discipline_id,
					lesson_plan_id: lessonPlanId
				})) || [],
			process:
				lessonData.process?.map((obj) => ({
					standar_option_id: obj,
					lesson_plan_id: lessonPlanId
				})) || [],

			assets,
			...getRelationsToAdd(lessonData),
			...getRelationsToDelete(lessonData),
			assetsIdsToDelete,
			articles
		}
	}

	const handleNextBtn = () => {
		if (currentStep < steps.length) {
			setCurrentStep(currentStep + 1)
			window.scrollTo(0, 0)
		} else {
			saveLesson()
		}
	}

	const handleStepUsedInitialData = (step: number) => {
		if (step in stepsUsedInitialData) {
			setStepsUsedInitialData((prev) => ({ ...prev, [step]: true }))
		}
	}

	const goBackToLesson = () => {
		history.push(
			buildRouteParameters(Pages.LessonDetail, {
				lessonPlanId
			})
		)
	}

	useEffect(() => {
		let isValid = true
		if ((currentStep === 1 && !stepOneIsValid) || (currentStep === 4 && !stepFourIsValid)) {
			isValid = false
		}
		setIsNextBtnDisabled(!isValid)
	}, [currentStep, stepOneIsValid, stepFourIsValid])

	const steps = [
		{
			step: 1,
			stepName: 'Lesson Details',
			component: (
				<StepOneForm
					lessonData={lessonData}
					saveData={saveData}
					setStepIsValid={setStepOneIsValid}
					handleInitialData={{
						initialData: initialLessonData,
						stepHasFilledInitialData: stepsUsedInitialData[1],
						setInitialDataUsed: () => handleStepUsedInitialData(1)
					}}
				/>
			)
		},
		{
			step: 2,
			stepName: `Main Lesson Sections`,
			component: (
				<StepTwoForm
					lessonData={lessonData}
					saveData={saveData}
					handleInitialData={{
						initialData: initialLessonData,
						stepHasFilledInitialData: stepsUsedInitialData[2],
						setInitialDataUsed: () => handleStepUsedInitialData(2)
					}}
				/>
			)
		},
		{
			step: 3,
			stepName: `Secondary Lesson Sections`,
			component: (
				<StepThreeForm
					lessonData={lessonData}
					saveData={saveData}
					handleInitialData={{
						initialData: initialLessonData,
						stepHasFilledInitialData: stepsUsedInitialData[3],
						setInitialDataUsed: () => handleStepUsedInitialData(3)
					}}
				/>
			)
		},
		{
			step: 4,
			stepName: `Core Standards`,
			component: (
				<StepFourForm
					lessonData={lessonData}
					saveData={saveData}
					setStepIsValid={setStepFourIsValid}
					handleInitialData={{
						initialData: initialLessonData,
						stepHasFilledInitialData: stepsUsedInitialData[4],
						setInitialDataUsed: () => handleStepUsedInitialData(4)
					}}
					lesson_plan_id={lessonPlanId}
				/>
			)
		},
		{
			step: 5,
			stepName: `Add Content`,
			component: (
				<StepFiveForm
					lessonData={lessonData}
					saveData={saveData}
					handleInitialData={{
						initialData: initialLessonData,
						stepHasFilledInitialData: stepsUsedInitialData[5],
						setInitialDataUsed: () => handleStepUsedInitialData(5)
					}}
					onAssetDelete={addAssetIdToRemove}
				/>
			)
		}
	]

	return (
		<Box>
			<MainTitle breadcrumbs={breadcrumbs} title="Edit Your Lesson" />
			<Stepper
				stepDescription={steps.find((step) => step.step === currentStep)?.stepName ?? ''}
				currentStep={currentStep}
				totalSteps={steps.length}
				stepBoxProps={{ mt: 6 }}
				stepDescriptionClassName={sharedClasses.fieldTitle}
			/>

			{loading && <CircularProgress color="secondary" size={30} />}
			{!loading && (steps[currentStep - 1]?.component ?? null)}

			<Box
				className={sharedClasses.buttonDisabled}
				marginTop="35px"
				display="flex"
				justifyContent="space-between">
				<Box>
					<BigBaseButton
						style={{ padding: '6px 40px' }}
						variant="contained"
						color="default"
						onClick={() => setOnCancel(true)}>
						<Typography variant="caption">Cancel</Typography>
					</BigBaseButton>
					{currentStep !== 1 && (
						<BigBaseButton
							style={{ marginLeft: '10px', padding: '6px 40px' }}
							variant="contained"
							color="default"
							onClick={() => setCurrentStep(currentStep - 1)}>
							<Typography variant="caption">Back</Typography>
						</BigBaseButton>
					)}
				</Box>

				<BigBaseButton
					style={{ padding: '6px 50px' }}
					color="secondary"
					onClick={handleNextBtn}
					disabled={isNextBtnDisabled || updateLessonLoading}>
					<Typography variant="caption">{currentStep === steps.length ? 'Save' : 'Next'}</Typography>
					{updateLessonLoading && <CircularProgress size="1.5em" style={{ marginLeft: '.5em' }} />}
				</BigBaseButton>
			</Box>
			<InfoDialog
				open={uploadSuccess}
				icon={<CheckIcon />}
				title="Lesson Saved!"
				body="Your Lesson was saved successfully!"
				discardLabel="Return to Lesson"
				onDiscard={goBackToLesson}
				onClose={goBackToLesson}
			/>
			<InfoDialog
				open={onCancel}
				onClose={() => setOnCancel(false)}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={() => {
					setOnCancel(false)
					history.push(
						buildRouteParameters(Pages.LessonDetail, {
							lessonPlanId
						})
					)
				}}
				confirmLabel="No, Continue Editing"
				onConfirm={() => {
					setOnCancel(false)
				}}
				icon="!"
			/>

			<InfoDialog
				open={uploadError}
				onClose={() => setUploadError(false)}
				title="Couldn't update lesson"
				body={
					<Typography>
						Please try again. <br />
						If the problem persists, contact support:{' '}
						<Link color="secondary" target="_blank" href="mailto:support@wurrlyedu.com">
							support@wurrlyedu.com
						</Link>
					</Typography>
				}
				discardLabel="Close"
				onDiscard={() => {
					setUploadError(false)
				}}
				icon="!"
			/>
		</Box>
	)
}
