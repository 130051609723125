import { createStyles, makeStyles } from '@material-ui/core'

export const useUploadExternalfileStep = makeStyles(() =>
	createStyles({
		fieldTitle: {
			fontSize: '1.3em',
			fontWeight: 500,
			marginBottom: '.8em',
			'& b, strong': {
				fontWeight: 500
			}
		}
	})
)

export default useUploadExternalfileStep
