import { createStyles, makeStyles, Theme } from '@material-ui/core'

type UseStudioStylesProps = {
	hasVideo?: boolean
}
export const useStudioDialogStyles = makeStyles<Theme, UseStudioStylesProps>(() =>
	createStyles({
		dialog: {
			'& .MuiDialog-paperWidthSm': {
				maxWidth: 'unset',
				borderRadius: '8px'
			},
			'& div.MuiBackdrop-root': {
				backgroundColor: 'transparent'
			}
		},
		body: {
			maxWidth: 'unset',
			height: '100%',
			padding: 0,
			borderStyle: 'none',
			overflowY: ({ hasVideo }) => (hasVideo ? 'unset' : 'scroll'),
			'&.MuiDialogContent-root:first-child': {
				padding: 0
			}
		},
		bottomActions: {
			padding: '0 2em',
			height: '8.5em',
			borderTop: '1px solid #1D273D1A',
			justifyContent: 'space-between',
			width: '100vw',
			maxWidth: '122vh'
		}
	})
)

export default useStudioDialogStyles
