import { ChartOptions } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { TypedChartComponent } from 'react-chartjs-2/dist/types'
import { ChartDataType } from 'src/@types'
import { PaginatedChart } from 'src/components/Charts/PaginatedChart/PaginatedChart'

import { ChartPaginationProps } from '../PaginatedChart/PaginatedChart'
import { DEFAULT_STACKED_BAR_OPTIONS } from './utils'

type StackedBarProps = ChartPaginationProps & {
	data: ChartDataType
	options?: ChartOptions<'bar'>
	paginated?: boolean
}

export const StackedBar = ({ data, options = {}, paginated, itemsPerPage, stepSize }: StackedBarProps) => {
	const mergedOptions = { ...DEFAULT_STACKED_BAR_OPTIONS, ...options }

	return paginated ? (
		<PaginatedChart
			ChartComponent={Bar as TypedChartComponent}
			chartProps={{ type: 'bar', options: mergedOptions, data }}
			itemsPerPage={itemsPerPage}
			stepSize={stepSize}
		/>
	) : (
		<Bar data={data} options={mergedOptions} />
	)
}
