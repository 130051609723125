import React from 'react'

import { Box, Typography, useTheme, styled } from '@material-ui/core'

type Props = {
	title?: string
	description?: string
	children?: React.ReactChild
	style?: React.CSSProperties
	noBorder?: boolean
}

const StyledBox = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	padding: '7.5em',
	justifyContent: 'center',
	justifyItems: 'center',
	textAlign: 'center',
	borderRadius: '6px',
	height: '500px'
})

export const ActivityBoxEmpty = ({
	title,
	description,
	style,
	noBorder,
	className,
	children
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
	const theme = useTheme()

	return (
		<StyledBox
			className={className}
			style={{ color: theme.palette.grey[500], ...style }}
			border={noBorder ? '' : '1px dashed #8F8F8F'}>
			{title && (
				<Typography style={{ marginBottom: 2 }} variant="h6">
					{title}
				</Typography>
			)}
			{description && <Typography variant="body2">{description}</Typography>}
			{children}
		</StyledBox>
	)
}
