import React, { useEffect, useState } from 'react'

import {
	Avatar,
	Box,
	ButtonProps,
	Checkbox,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { EditIcon, LessonsSmallIcon, MusicNoteV2SmallIcon, StandardIcon, StarSmallIcon } from 'src/assets/icons'
import { ActionButton } from 'src/components/Buttons/ActionButton'
import { AssignmentAdded } from 'src/components/Buttons/AssignmentAdded/AssignmentAdded'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { SmallBaseButton } from 'src/components/Buttons/SmallBaseButton'
import { Lesson_Plan, Module, Playlist, Tip, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { ProductTitleEnum } from 'src/utils'

import useStyles from './ListItemMedia.styles'

export type ListItemMediaProps = {
	imageUrl: string
	title: string
	author: string
	genres: string
	draggable?: boolean
	selected?: boolean
	onSelected?: (checked: boolean) => void
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	btnData?: ButtonProps
	btnFavorite?: { selected?: boolean } & ButtonProps
	btnLyric?: ButtonProps
	btnAddClass?: ButtonProps
	btnRemove?: ButtonProps
	removeTooltip?: string
	btnEdit?: ButtonProps
	btnStandard?: ButtonProps
	btnView?: ButtonProps
	btnAddAssignment?: ButtonProps
	btnAssignmentAdded?: ButtonProps
	btnStudio?: ButtonProps
	isVideo?: boolean
	changedPrivacy?: boolean
	selectedPrivacy?: Visibility_Enum
	data?: Lesson_Plan | Module | Playlist | Tip
	errorText?: string
	handleError?: (value: boolean) => void
	handleErrorText?: (value: string) => void
	selectableDisabled?: boolean
	elementsName?: ProductTitleEnum.Tip | ProductTitleEnum.Module
	btnModules?: ButtonProps
	moduleCount?: number
	setSelectedItems?: (items: Lesson_Plan[] | Module[]) => void
	selectedItems?: Lesson_Plan[] | Module[]
	showDelete?: boolean
	textTooltipCard?: string
	type?: ProductTitleEnum
	onRemove?: () => void
}

export const ListItemMedia = ({
	imageUrl,
	title,
	author,
	genres,
	btnData,
	btnFavorite,
	btnLyric,
	btnAddClass,
	btnStandard,
	btnRemove,
	btnEdit,
	btnView,
	btnAddAssignment,
	btnAssignmentAdded,
	btnStudio,
	draggable = false,
	isVideo = false,
	selected = false,
	onSelected,
	changedPrivacy,
	selectedPrivacy,
	selectableDisabled,
	data,
	handleError,
	handleErrorText,
	elementsName = ProductTitleEnum.Tip,
	moduleCount,
	btnModules,
	selectedItems,
	setSelectedItems,
	showDelete = false,
	type,
	textTooltipCard = '',
	onRemove,
	removeTooltip
}: ListItemMediaProps) => {
	const styles = useStyles()

	const [changeStyles, setChangeStyles] = useState(false)
	const [dataPrivacy, setDataPrivacy] = useState(data?.lockable_content?.visibility)

	useEffect(() => {
		setDataPrivacy(data?.lockable_content?.visibility)
	}, [data])

	useEffect(() => {
		// WHEN THE SELECTED VISIBILITY IS ADMIN ONLY, TEACHER WONT HAVE ACCESS TO MODULE

		if (
			handleError &&
			handleErrorText &&
			changedPrivacy &&
			(selectedPrivacy === Visibility_Enum.AdminOnly ||
				(dataPrivacy &&
					selectedPrivacy === Visibility_Enum.ForSale &&
					[Visibility_Enum.Public, Visibility_Enum.ForSale].includes(dataPrivacy)))
		) {
			setChangeStyles(false)
			handleError(false)
			handleErrorText('')
		}
		// WHEN THE SELECTED VISIBILITY IS PUBLIC, THE TEACHER WILL ONLY HAVE ACCESS TO PUBLIC LESSONS
		if (
			handleError &&
			handleErrorText &&
			selectedPrivacy === Visibility_Enum.Public &&
			dataPrivacy !== Visibility_Enum.Public
		) {
			setChangeStyles(true)
			handleError(true)
			handleErrorText(`Teachers won't be able to access non public ${elementsName}`)
		}
		// WHEN THE SELECTED VISIBILITY IS RESTRICTED, THE TEACHER WILL ONLY HAVE ACCESS TO PUBLIC OR RESTRICTED LESSONS,
		// ALL THOSE LESSONS WHICH ARE PRIVATE OR FOR SALE WONT BE ACCESSIBLE UNLESS GIVEN ACCESS
		if (
			handleError &&
			handleErrorText &&
			dataPrivacy &&
			selectedPrivacy === Visibility_Enum.Restricted &&
			![Visibility_Enum.Public, Visibility_Enum.Restricted].includes(dataPrivacy)
		) {
			setChangeStyles(true)
			handleError(true)
			handleErrorText(`Teachers won't be able to access these ${elementsName} unless given access`)
		}
		// WHEN THE SELECTED VISIBILITY IS FOR SALE, THE TEACHER WILL ONLY HAVE ACCESS TO PUBLIC LESSONS,
		// ALL THOSE LESSONS WHICH ARE NOT PUBLIC OR ARE FOR SALE WONT BE ACCESSIBLE UNLESS GIVEN ACCESS
		if (
			handleError &&
			handleErrorText &&
			changedPrivacy &&
			dataPrivacy &&
			selectedPrivacy === Visibility_Enum.ForSale &&
			![Visibility_Enum.Public, Visibility_Enum.ForSale].includes(dataPrivacy)
		) {
			setChangeStyles(true)
			handleError(true)
			handleErrorText(`Teachers won't be able to access non public ${elementsName}`)
		}
	}, [changedPrivacy, selectedPrivacy])

	const handleRemove = () => {
		if (onRemove) {
			onRemove()

			return
		}
		// remove should be defined in the parent component
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				{
					if (selectedItems && setSelectedItems) {
						const arr = (selectedItems as Lesson_Plan[])?.filter(
							(el) => el.lesson_plan_id !== (data as Lesson_Plan)?.lesson_plan_id
						)
						setSelectedItems(arr)
					}
				}
				break
			case ProductTitleEnum.Module: {
				{
					if (selectedItems && setSelectedItems) {
						const arr = (selectedItems as Module[])?.filter(
							(el) => el.module_id !== (data as Module)?.module_id
						)
						setSelectedItems(arr)
					}
				}
				break
			}
		}
	}

	return (
		<Tooltip title={textTooltipCard}>
			<ListItem
				className={!changeStyles ? styles.listItem : styles.listChangedPrivacyItems}
				draggable={draggable}>
				<ListItemAvatar className={!isVideo ? styles.avatar : styles.avatarVideo}>
					<>
						<Avatar variant="rounded" src={imageUrl} />
						<Box className={styles.thumbnail}>
							{isVideo && <PlayArrowIcon className={styles.playIcon} />}
						</Box>
					</>
				</ListItemAvatar>
				<ListItemText
					disableTypography
					className={styles.text}
					primary={
						<div>
							<Typography className={styles.itemTitle} variant="h6" style={{ fontSize: 18 }}>
								<b>{title}</b>
							</Typography>
							<Typography noWrap style={{ fontSize: 14 }}>
								<b>{author}</b>
							</Typography>
						</div>
					}
					secondary={
						<Typography variant="caption" color="textSecondary">
							<b>{genres}</b>
						</Typography>
					}
				/>
				{(showDelete || onRemove) && (
					<Box style={{ display: 'flex', alignSelf: 'center' }}>
						<IconButton className={styles.iconButton} onClick={handleRemove}>
							<Clear />
						</IconButton>
					</Box>
				)}
				<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
					<div style={{ flexGrow: 1 }} />
					{btnModules && (
						<Tooltip title={moduleCount === 0 ? 'This lesson is not assigned to any module yet' : ''}>
							<span>
								<ActionButton {...btnModules} size="small" icon={<InfoIcon />}></ActionButton>
							</span>
						</Tooltip>
					)}

					{btnEdit && (
						<Tooltip title="Edit">
							<ActionButton
								{...btnEdit}
								size="small"
								disabled={btnEdit.disabled}
								icon={<EditIcon />}
							/>
						</Tooltip>
					)}
					{btnFavorite && (
						<Tooltip title="Favorite">
							<ActionButton
								{...btnFavorite}
								size="small"
								selected={btnFavorite.selected}
								disabled={btnFavorite.disabled}
								icon={<StarSmallIcon />}
							/>
						</Tooltip>
					)}
					{btnData && (
						<Tooltip title="Datas">
							<ActionButton
								{...btnData}
								size="small"
								disabled={btnData.disabled}
								icon={<LessonsSmallIcon />}
							/>
						</Tooltip>
					)}
					{btnLyric && (
						<Tooltip title="Lyrics">
							<ActionButton
								{...btnLyric}
								size="small"
								disabled={btnLyric.disabled}
								icon={<MusicNoteV2SmallIcon />}
							/>
						</Tooltip>
					)}
					{btnStandard && (
						<Tooltip title="Standard">
							<ActionButton
								{...btnStandard}
								size="small"
								disabled={btnStandard.disabled}
								icon={<StandardIcon />}
							/>
						</Tooltip>
					)}
					{btnRemove && (
						<Tooltip title={removeTooltip || 'Remove'}>
							<Box className={styles.enableToolTip}>
								<ActionButton {...btnRemove} size="small" icon={<DeleteIcon fontSize="small" />} />
							</Box>
						</Tooltip>
					)}
					{btnAddClass && (
						<Tooltip title="Add to class">
							<SmallBaseButton
								color="primary"
								className={'addClass'}
								endIcon={<AddIcon />}
								{...btnAddClass}>
								<Typography>Add to class</Typography>
							</SmallBaseButton>
						</Tooltip>
					)}
					{btnStudio && (
						<Tooltip title="Go to Studio">
							<SmallBaseButton color="primary" className={'addClass'} {...btnStudio}>
								<Typography>Studio</Typography>
							</SmallBaseButton>
						</Tooltip>
					)}
					{btnView && (
						<Tooltip title="View">
							<BaseButton
								color="primary"
								className={'addClass'}
								endIcon={<ArrowForwardIcon />}
								{...btnView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
					)}
					{btnAddAssignment && (
						<Tooltip title="Add Assignment">
							<BaseButton
								color="primary"
								className={'addClass'}
								endIcon={<AddIcon />}
								{...btnAddAssignment}>
								<Typography>Add Assignment</Typography>
							</BaseButton>
						</Tooltip>
					)}
					{onSelected !== undefined && (
						<Checkbox
							disabled={selectableDisabled}
							checked={selected}
							onChange={(event) => onSelected(event.target.checked)}
						/>
					)}

					{btnAssignmentAdded && <AssignmentAdded {...btnAssignmentAdded} />}
				</Box>
			</ListItem>
		</Tooltip>
	)
}
