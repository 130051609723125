import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { HeadphonesIcon } from '../../../assets/icons'
import { InfoDialog } from '../../Dialogs/InfoDialog'
import { useStudioRecordContext } from './useStudioRecord'

export const HeadphonesConnectivityDialog = () => {
	const { setShowHeadphonesConnectivity, showHeadphonesConnectivity } = useStudioRecordContext()
	const history = useHistory()

	const toggleModalVisibility = () => {
		setShowHeadphonesConnectivity(() => !showHeadphonesConnectivity)
	}

	const goBack = () => {
		history.goBack()
	}

	const renderTitle = () => (
		<>
			<Typography component={'span'} style={{ fontSize: '32px' }}>
				Please Use
			</Typography>
			<br />
			<Typography component={'span'} style={{ fontSize: '32px', lineHeight: '25px' }}>
				Headphones
			</Typography>
		</>
	)

	return (
		<InfoDialog
			title={renderTitle()}
			open
			icon={<HeadphonesIcon />}
			confirmLabel="Got it"
			onClose={toggleModalVisibility}
			onConfirm={toggleModalVisibility}
			discardLabel="Take me back"
			onDiscard={goBack}
			body={<> The sounds quality is greatly improved with headphones.</>}
		/>
	)
}
