import { MainTitle } from 'src/components'
import { PaymentsList } from 'src/scenes/Teacher/scenes/BillingDetails/components/PaymentsList'
import { useBillingDetails } from 'src/scenes/Teacher/scenes/BillingDetails/useBillingDetails'

export const BillingDetails = () => {
	const { breadcrumbs, orders, queryLoading, isFetching, moreResults } = useBillingDetails()

	return (
		<>
			<MainTitle breadcrumbs={breadcrumbs} title="Billing Details" />
			<PaymentsList data={orders} isFetching={isFetching} moreResults={moreResults} loading={queryLoading} />
		</>
	)
}
