export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  _int8: any;
  bigint: any;
  bytea: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
};


export type AcceptConsentData = {
  __typename?: 'AcceptConsentData';
  error?: Maybe<Scalars['String']>;
};

export type AcceptConsentFilter = {
  code: Scalars['String'];
  parentEmail: Scalars['String'];
};

export type ActivateAccountData = {
  __typename?: 'ActivateAccountData';
  success?: Maybe<Scalars['Boolean']>;
};

export type ActivateAccountFilter = {
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
  token: Scalars['String'];
};

export type ActivateTeacherData = {
  __typename?: 'ActivateTeacherData';
  success?: Maybe<Scalars['Boolean']>;
};

export type ActivateTeacherFilter = {
  token: Scalars['String'];
};

export type AddStudentsByBulkQRsData = {
  __typename?: 'AddStudentsByBulkQRsData';
  html?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type AddStudentsByBulkQRsFilter = {
  classId: Scalars['Int'];
  students: Array<StudentFromCsv>;
};

export type AddStudentsUnder12Data = {
  __typename?: 'AddStudentsUnder12Data';
  code: Scalars['String'];
  parentEmail: Scalars['String'];
};

export type AddStudentsUnder12Set = {
  classId: Scalars['Int'];
  students: Array<StudentsUnder12Data>;
  teacherId: Scalars['Int'];
};

export type AdminLoginData = {
  __typename?: 'AdminLoginData';
  token?: Maybe<Scalars['String']>;
};

export type AdminLoginFilter = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ApplyStickerFilter = {
  stickers?: Maybe<Array<Sticker>>;
  videoDisplayHeight: Scalars['Int'];
  videoDisplayWidth: Scalars['Int'];
  videoUrl: Scalars['String'];
};

export type ApplyStickerResponse = {
  __typename?: 'ApplyStickerResponse';
  videoUrl?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CancelSubscriptionData = {
  __typename?: 'CancelSubscriptionData';
  success: Scalars['Boolean'];
};

export type CancelSubscriptionFilter = {
  teacherId: Scalars['Int'];
};

export type CanvasGroup = {
  __typename?: 'CanvasGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  totalStudents: Scalars['Int'];
};

export type ChordsSetType = {
  chord: Scalars['String'];
  time: Scalars['Float'];
};

export type ClassLinkGroup = {
  __typename?: 'ClassLinkGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  totalStudents: Scalars['Int'];
};

export type CleverAccount = {
  __typename?: 'CleverAccount';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isTeacher?: Maybe<Scalars['Boolean']>;
  name?: Maybe<CleverAccountName>;
};

export type CleverAccountName = {
  __typename?: 'CleverAccountName';
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
};

export type CleverGroup = {
  __typename?: 'CleverGroup';
  course?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_modified?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  section_number?: Maybe<Scalars['String']>;
  sis_id?: Maybe<Scalars['String']>;
  students?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  teacher?: Maybe<Scalars['String']>;
  teachers?: Maybe<Array<Maybe<Scalars['String']>>>;
  term_id?: Maybe<Scalars['String']>;
};

export type CleverSchoolData = {
  __typename?: 'CleverSchoolData';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ColorFilterInput = {
  filterColorId: Scalars['Int'];
  filterColorName: Scalars['String'];
};

export type CompleteTeacherIntegrationData = {
  __typename?: 'CompleteTeacherIntegrationData';
  token?: Maybe<Scalars['String']>;
};

export type CompleteTeacherIntegrationSet = {
  address?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  schoolId?: Maybe<Scalars['Int']>;
  teacherId: Scalars['Int'];
};

export type CreateSubscriptionData = {
  __typename?: 'CreateSubscriptionData';
  clientSecret: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type CreateSubscriptionFilter = {
  membershipId: Scalars['Int'];
  promotionCode?: Maybe<Scalars['String']>;
  teacherId: Scalars['Int'];
};

export type CustomCreateAssignmentData = {
  __typename?: 'CustomCreateAssignmentData';
  assignmentId?: Maybe<Scalars['Int']>;
  classAssignmentIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CustomCreateAssignmentFilter = {
  allowExternalFiles?: Maybe<Scalars['Boolean']>;
  assets: Array<CustomCreateAssignmentFormAsset>;
  classes: Array<Scalars['Int']>;
  dueDate?: Maybe<Scalars['String']>;
  dueTime?: Maybe<Scalars['String']>;
  gradingSystemId: Scalars['Int'];
  includeAllSongs: Scalars['Boolean'];
  instructions?: Maybe<Scalars['String']>;
  lessonPlanId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  songs: Array<Scalars['Int']>;
  videos: Array<Scalars['Int']>;
};

export type CustomCreateAssignmentFormAsset = {
  name: Scalars['String'];
  resourcePath: Scalars['String'];
};

export type CustomCreateWurrlyData = {
  __typename?: 'CustomCreateWurrlyData';
  isSent: Scalars['Boolean'];
  wurrlyId?: Maybe<Scalars['Int']>;
};

export type CustomCreateWurrlyFilter = {
  audioBalance?: Maybe<Scalars['Int']>;
  audioUrl: Scalars['String'];
  challengeId?: Maybe<Scalars['Int']>;
  classId: Scalars['Int'];
  filters?: Maybe<CustomCreateWurrlyFilterOptions>;
  imagePath: Scalars['String'];
  isMirrored?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  privacy: Scalars['String'];
  resourcePath: Scalars['String'];
  studentIdOwner: Scalars['Int'];
  studentIdSecondary: Scalars['Int'];
  submission?: Maybe<CustomCreateWurrlySubmissionObject>;
  trackId: Scalars['Int'];
  wurrlyId?: Maybe<Scalars['Int']>;
  wurrlyType: Scalars['String'];
};

export type CustomCreateWurrlyFilterOptions = {
  color?: Maybe<ColorFilterInput>;
  frames?: Maybe<FramesFilter>;
  stickers?: Maybe<ApplyStickerFilter>;
};

export type CustomCreateWurrlySubmissionObject = {
  submissionId: Scalars['Int'];
  submissionWurrlyState: Scalars['String'];
};

export type DistrictAddOrUpdateChannelsAndPlaylistsSet = {
  districtId: Scalars['Int'];
  lockableContentChannels?: Maybe<Array<Scalars['Int']>>;
  lockableContentPlaylists?: Maybe<Array<Scalars['Int']>>;
};

export type DistrictAddOrUpdatePartnersAndModulesSet = {
  districtId: Scalars['Int'];
  lockableContentModules?: Maybe<Array<Scalars['Int']>>;
  lockableContentPartners?: Maybe<Array<Scalars['Int']>>;
  lockableContentPlaylists?: Maybe<Array<Scalars['Int']>>;
};

export type DownloadQrCodeData = {
  __typename?: 'DownloadQRCodeData';
  html?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type DownloadQrCodeFilter = {
  students: Array<StudentFromCsv>;
};

export type DynamicTemplateData = {
  studentMail: Scalars['String'];
  teacherName: Scalars['String'];
  to: Scalars['String'];
};

export type EditTeacherInfoFilter = {
  currentPassword: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  newPassword?: Maybe<Scalars['String']>;
  teacherId: Scalars['Int'];
};

export type EventDataType = {
  __typename?: 'EventDataType';
  header: MidiHeaderType;
  infoEvents: Array<MidiTrackType>;
  msPerTick: Scalars['Float'];
  rows: Array<EventRowType>;
  totalTime: Scalars['Float'];
};

export type EventRowType = {
  __typename?: 'EventRowType';
  deltaTime: Scalars['Float'];
  order: Scalars['Int'];
  subtype: Scalars['String'];
  text: Scalars['String'];
  time: Scalars['Int'];
  type: Scalars['String'];
};

export enum FileEnum {
  Assets = 'assets',
  Images = 'images',
  Lessons = 'lessons',
  Resources = 'resources',
  Songs = 'songs',
  Tips = 'tips',
  Wurrlies = 'wurrlies'
}

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: Maybe<Scalars['Float']>;
  _gt?: Maybe<Scalars['Float']>;
  _gte?: Maybe<Scalars['Float']>;
  _in?: Maybe<Array<Scalars['Float']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Float']>;
  _lte?: Maybe<Scalars['Float']>;
  _neq?: Maybe<Scalars['Float']>;
  _nin?: Maybe<Array<Scalars['Float']>>;
};

export type FramesData = {
  __typename?: 'FramesData';
  videoURL?: Maybe<Scalars['String']>;
};

export type FramesFilter = {
  filterFrameId?: Maybe<Scalars['Int']>;
  frameEnum?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
};

export type GetCanvasAuthUrlFilter = {
  overridedDomain: Scalars['String'];
};

export type GetCanvasUserData = {
  __typename?: 'GetCanvasUserData';
  access_token: Scalars['String'];
  classes: Array<CanvasGroup>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isTeacher: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  sourcedId: Scalars['String'];
};

export type GetCanvasUserFilter = {
  code: Scalars['String'];
};

export type GetClassLinkAuthUrlFilter = {
  overridedDomain: Scalars['String'];
};

export type GetClassLinkGroupsData = {
  __typename?: 'GetClassLinkGroupsData';
  name: Scalars['String'];
  sourcedId: Scalars['String'];
  totalStudents: Scalars['Int'];
};

export type GetClassLinkGroupsFilter = {
  teacherSourcedId: Scalars['String'];
};

export type GetClassLinkStudentsData = {
  __typename?: 'GetClassLinkStudentsData';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sourcedId: Scalars['String'];
};

export type GetClassLinkStudentsFilter = {
  classSourcedId: Scalars['String'];
};

export type GetClassLinkUserData = {
  __typename?: 'GetClassLinkUserData';
  classes: Array<ClassLinkGroup>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isTeacher: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  sourcedId: Scalars['String'];
};

export type GetClassLinkUserFilter = {
  code: Scalars['String'];
};

export type GetCleverAccountFilter = {
  accessToken: Scalars['String'];
};

export type GetCleverAuthUrlFilter = {
  districtId?: Maybe<Scalars['String']>;
  isSSO?: Maybe<Scalars['Boolean']>;
};

export type GetCleverDistrictsData = {
  __typename?: 'GetCleverDistrictsData';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<Maybe<CleverSchoolData>>>;
};

export type GetCleverGroupsFilter = {
  accessToken: Scalars['String'];
};

export type GetCleverMembersFilter = {
  accessToken: Scalars['String'];
  groupId: Scalars['String'];
};

export type GetCleverTokenFilter = {
  code?: Maybe<Scalars['String']>;
};

export type GetGoogleAccountFilter = {
  credentials: GoogleOAuthTokenInput;
};

export type GetGoogleAuthUrlFilter = {
  isSSO?: Maybe<Scalars['Boolean']>;
};

export type GetGoogleGroupsFilter = {
  credentials: GoogleOAuthTokenInput;
};

export type GetGoogleMembersFilter = {
  credentials: GoogleOAuthTokenInput;
  groupId: Scalars['String'];
};

export type GetGoogleTokenFilter = {
  code?: Maybe<Scalars['String']>;
};

export type GetMsAccountFilter = {
  accessToken: Scalars['String'];
};

export type GetMsAuthUrlFilter = {
  isSSO?: Maybe<Scalars['Boolean']>;
};

export type GetMsGroupsFilter = {
  accessToken: Scalars['String'];
};

export type GetMsMembersFilter = {
  accessToken: Scalars['String'];
  groupId: Scalars['String'];
};

export type GetMsTokenFilter = {
  code?: Maybe<Scalars['String']>;
};

export type GetSongTrackEventsFilter = {
  speed: Scalars['Float'];
  trackId: Scalars['Int'];
  transposition: Scalars['Int'];
};

export type GetSongTrackEventsResponse = {
  __typename?: 'GetSongTrackEventsResponse';
  eventData?: Maybe<EventDataType>;
};

export type GetStudentChallenge = {
  __typename?: 'GetStudentChallenge';
  challenge_id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  publish_date?: Maybe<Scalars['String']>;
  publish_time?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video_path?: Maybe<Scalars['String']>;
};

export type GetStudentChallengesData = {
  __typename?: 'GetStudentChallengesData';
  challenges: Array<GetStudentChallenge>;
};

export type GetStudentChallengesFilter = {
  date: Scalars['String'];
  firstTime: Scalars['Boolean'];
  student_id: Scalars['Int'];
};

export type GetStudentQuestionaryFilter = {
  code: Scalars['String'];
};

export type GetStudentQuestionaryQuestion = {
  __typename?: 'GetStudentQuestionaryQuestion';
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type GetStudentQuestionaryResponse = {
  __typename?: 'GetStudentQuestionaryResponse';
  isSchoolTeacher?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Maybe<GetStudentQuestionaryQuestion>>>;
};

export type GetSubscriptionData = {
  __typename?: 'GetSubscriptionData';
  billingMethod?: Maybe<Scalars['String']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  periodEnd?: Maybe<Scalars['timestamptz']>;
  periodStart?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Float']>;
};

export type GetSubscriptionFilter = {
  teacherId: Scalars['Int'];
};

export type GoogleAccount = {
  __typename?: 'GoogleAccount';
  courses?: Maybe<Array<Maybe<GoogleGroup>>>;
  integrationId?: Maybe<Scalars['String']>;
  loginUrl?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Maybe<GoogleStudent>>>;
  teacherEmail?: Maybe<Scalars['String']>;
  token?: Maybe<GoogleOAuthToken>;
};

export type GoogleGroup = {
  __typename?: 'GoogleGroup';
  alternateLink?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  courseState?: Maybe<Scalars['String']>;
  creationTime?: Maybe<Scalars['String']>;
  descriptionHeading?: Maybe<Scalars['String']>;
  enrollmentCode?: Maybe<Scalars['String']>;
  guardiansEnabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  teacherFolder?: Maybe<GoogleTeacherFolder>;
  teacherGroupEmail?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['String']>;
};

export type GoogleOAuthToken = {
  __typename?: 'GoogleOAuthToken';
  access_token: Scalars['String'];
  expiry_date?: Maybe<Scalars['String']>;
  id_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
  token_type?: Maybe<Scalars['String']>;
};

export type GoogleOAuthTokenInput = {
  access_token: Scalars['String'];
  expiry_date?: Maybe<Scalars['String']>;
  id_token?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
  token_type?: Maybe<Scalars['String']>;
};

export type GoogleStudent = {
  __typename?: 'GoogleStudent';
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<GoogleStudentName>;
};

export type GoogleStudentName = {
  __typename?: 'GoogleStudentName';
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

export type GoogleTeacherFolder = {
  __typename?: 'GoogleTeacherFolder';
  alternateLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ImpersonateTeacherData = {
  __typename?: 'ImpersonateTeacherData';
  token: Scalars['String'];
};

export type ImpersonateTeacherFilter = {
  teacherId: Scalars['Int'];
};

export type InsertContactUsWithMailFilter = {
  email?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
  schoolOrDistrict: Scalars['String'];
};

export type InsertIntegrationStudentsToClassData = {
  __typename?: 'InsertIntegrationStudentsToClassData';
  error: Scalars['String'];
  success: Scalars['Boolean'];
};

export type InsertIntegrationStudentsToClassSet = {
  data: StudentInsertClassData;
};

export enum InstancesEnum {
  Asset = 'asset',
  LessonPlan = 'lesson_plan',
  Song = 'song',
  Tip = 'tip'
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type InviteAdminData = {
  __typename?: 'InviteAdminData';
  adminId?: Maybe<Scalars['Int']>;
  emailInvitationSent?: Maybe<Scalars['Boolean']>;
};

export type InviteAdminFilter = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type InviteTeacherData = {
  __typename?: 'InviteTeacherData';
  emailInvitationSent?: Maybe<Scalars['Boolean']>;
  teacherId?: Maybe<Scalars['Int']>;
};

export type InviteTeacherFilter = {
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  schoolId?: Maybe<Scalars['Int']>;
};

export type LessonPlanPdfData = {
  __typename?: 'LessonPlanPDFData';
  html?: Maybe<Scalars['String']>;
  path: Scalars['String'];
};

export type LessonPlanPdfFilter = {
  lesson_plan_id: Scalars['Int'];
};

export type LinkMetadataFilter = {
  link: Scalars['String'];
};

export type ListSubscriptions = {
  __typename?: 'ListSubscriptions';
  subscriptions?: Maybe<Array<Maybe<StripeSubscriptionPlan>>>;
};

export type LoginData = {
  __typename?: 'LoginData';
  avatarSelected?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type LoginFilter = {
  email: Scalars['String'];
  isStudent?: Maybe<Scalars['Boolean']>;
  isTeacher?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
};

export type LoginIntegrationData = {
  __typename?: 'LoginIntegrationData';
  cleverSignup: Scalars['Boolean'];
  token: Scalars['String'];
};

export type LoginIntegrationFilter = {
  accessToken: Scalars['String'];
  email: Scalars['String'];
  integrationId: Scalars['String'];
  isClever?: Maybe<Scalars['Boolean']>;
  isGoogle?: Maybe<Scalars['Boolean']>;
  isMicrosoft?: Maybe<Scalars['Boolean']>;
};

export type LoginOrSignupTeacherIntegrationData = {
  __typename?: 'LoginOrSignupTeacherIntegrationData';
  isSignup: Scalars['Boolean'];
  schoolId?: Maybe<Scalars['Int']>;
  teacherId: Scalars['Int'];
  token: Scalars['String'];
};

export type LoginOrSignupTeacherIntegrationFilter = {
  accessToken: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  integrationId: Scalars['String'];
  integrationName: Scalars['String'];
};

export type LoginStudentByCodeFilter = {
  code: Scalars['String'];
  id: Scalars['Int'];
};

export type LoginStudentByCodeResponse = {
  __typename?: 'LoginStudentByCodeResponse';
  isAvatarSelected?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type LyricsSetType = {
  text: Scalars['String'];
  time: Scalars['Float'];
};

export type MetaData = {
  __typename?: 'MetaData';
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MidiHeaderType = {
  __typename?: 'MidiHeaderType';
  formatType: Scalars['Int'];
  ticksPerBeat: Scalars['Int'];
  trackCount: Scalars['Int'];
};

export type MidiToJsonData = {
  __typename?: 'MidiToJsonData';
  success: Scalars['Boolean'];
};

export type MidiToJsonFilter = {
  songId?: Maybe<Scalars['Int']>;
  songIdEnd?: Maybe<Scalars['Int']>;
  songIdStart?: Maybe<Scalars['Int']>;
};

export type MidiTrackType = {
  __typename?: 'MidiTrackType';
  channel?: Maybe<Scalars['Int']>;
  deltaTime: Scalars['Float'];
  denominator?: Maybe<Scalars['Int']>;
  frame?: Maybe<Scalars['Int']>;
  frameRate?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  metronome?: Maybe<Scalars['Int']>;
  microsecondsPerBeat?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  noteNumber?: Maybe<Scalars['Int']>;
  numerator?: Maybe<Scalars['Int']>;
  scale?: Maybe<Scalars['Int']>;
  sec?: Maybe<Scalars['Int']>;
  subframe?: Maybe<Scalars['Int']>;
  subtype: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  thirtyseconds?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  velocity?: Maybe<Scalars['Int']>;
};

export type MsAccount = {
  __typename?: 'MsAccount';
  displayName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type MsGroup = {
  __typename?: 'MsGroup';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PresignedUrlFilter = {
  conType: Scalars['String'];
  ext: Scalars['String'];
  isTemporal: Scalars['Boolean'];
  type: FileEnum;
};

export type PurgeS3TempFilter = {
  instanceType: InstancesEnum;
  rowId: Scalars['Int'];
};

export type ResetPasswordData = {
  __typename?: 'ResetPasswordData';
  success: Scalars['Boolean'];
};

export type ResetPasswordFilter = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type Response = {
  __typename?: 'Response';
  msg?: Maybe<Scalars['String']>;
};

export type RoastCleverClassesData = {
  __typename?: 'RoastCleverClassesData';
  error?: Maybe<Scalars['String']>;
};

export type RoastCleverClassesFilter = {
  accessToken: Scalars['String'];
  classesIds: Array<Scalars['String']>;
  teacherId: Scalars['Int'];
};

export type RosterCanvasClassesData = {
  __typename?: 'RosterCanvasClassesData';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RosterCanvasClassesSet = {
  accessToken: Scalars['String'];
  classesIds: Array<Scalars['Int']>;
};

export type RosterClassLinkClassesData = {
  __typename?: 'RosterClassLinkClassesData';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RosterClassLinkClassesSet = {
  classesIds: Array<Scalars['String']>;
};

export type SchoolAddOrUpdateChannelsAndPlaylistsSet = {
  lockableContentChannels?: Maybe<Array<Scalars['Int']>>;
  lockableContentPlaylists?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
};

export type SchoolAddOrUpdatePartnersAndModulesSet = {
  lockableContentModules?: Maybe<Array<Scalars['Int']>>;
  lockableContentPartners?: Maybe<Array<Scalars['Int']>>;
  lockableContentPlaylists?: Maybe<Array<Scalars['Int']>>;
  schoolId: Scalars['Int'];
};

export type SendInvitationData = {
  __typename?: 'SendInvitationData';
  emailSent?: Maybe<Scalars['Boolean']>;
};

export type SendInvitationFilter = {
  adminId?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  teacherId?: Maybe<Scalars['Int']>;
};

export type SendMailFilter = {
  dynamicTemplateData?: Maybe<Scalars['String']>;
  templateId: Scalars['String'];
  to: Scalars['String'];
};

export type SendMailResult = {
  __typename?: 'SendMailResult';
  result?: Maybe<Scalars['String']>;
};

export type SendMultipleMailFilter = {
  dynamicTemplateData: Array<DynamicTemplateData>;
  templateId: Scalars['String'];
};

export type SendReinviteStudentsEmailFilter = {
  student: StudentFromCsv;
};

export type SendResetPasswordUrlData = {
  __typename?: 'SendResetPasswordUrlData';
  success: Scalars['Boolean'];
};

export type SendResetPasswordUrlFilter = {
  email: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isStudent?: Maybe<Scalars['Boolean']>;
  isTeacher?: Maybe<Scalars['Boolean']>;
};

export type SignupTeacherIntegrationData = {
  __typename?: 'SignupTeacherIntegrationData';
  token?: Maybe<Scalars['String']>;
};

export type SignupTeacherIntegrationFilter = {
  address?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  schoolId?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
};

export type SignupTeacherIntegrationGetTokenData = {
  __typename?: 'SignupTeacherIntegrationGetTokenData';
  token?: Maybe<Scalars['String']>;
};

export type SignupTeacherIntegrationGetTokenFilter = {
  accessToken: Scalars['String'];
  email: Scalars['String'];
  integrationId: Scalars['String'];
  isClever?: Maybe<Scalars['Boolean']>;
  isGoogle?: Maybe<Scalars['Boolean']>;
  isMicrosoft?: Maybe<Scalars['Boolean']>;
};

export type Sticker = {
  height: Scalars['Int'];
  imageUrl: Scalars['String'];
  positionX: Scalars['Int'];
  positionY: Scalars['Int'];
  rotation: Scalars['Int'];
  stickerId: Scalars['Int'];
  width: Scalars['Int'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionPlan = {
  __typename?: 'StripeSubscriptionPlan';
  billingMethod?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stripePriceId?: Maybe<Scalars['String']>;
};

export type StudentActivationFilter = {
  code: Scalars['String'];
  password: Scalars['String'];
  studentEmail: Scalars['String'];
};

export type StudentActivationResponse = {
  __typename?: 'StudentActivationResponse';
  activation?: Maybe<Scalars['Boolean']>;
};

export type StudentData = {
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type StudentDataOutput = {
  __typename?: 'StudentDataOutput';
  code: Scalars['String'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type StudentFromCsv = {
  code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  qrCode?: Maybe<Scalars['String']>;
};

export type StudentFromCsvFilter = {
  classId: Scalars['Int'];
  students: Array<StudentFromCsv>;
  teacherName: Scalars['String'];
};

export type StudentInsertClassData = {
  classId: Scalars['Int'];
  integrationName: Scalars['String'];
  students: Array<StudentInsertData>;
};

export type StudentInsertData = {
  code: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  integration_id: Scalars['String'];
  last_name: Scalars['String'];
};

export type StudentLoginAttemptFailedEmailFilter = {
  code: Scalars['String'];
};

export type StudentLoginIntegrationData = {
  __typename?: 'StudentLoginIntegrationData';
  token: Scalars['String'];
};

export type StudentLoginIntegrationFilter = {
  integrationId: Scalars['String'];
};

export type StudentsInClass = {
  classId: Scalars['Int'];
  students: Array<StudentData>;
};

export type StudentsUnder12Data = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  parentEmail: Scalars['String'];
};

export type TeacherSignupData = {
  __typename?: 'TeacherSignupData';
  token?: Maybe<Scalars['String']>;
};

export type TeacherSignupFilter = {
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  nameFirst: Scalars['String'];
  nameLast: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: Scalars['String'];
  schoolId?: Maybe<Scalars['Int']>;
};

export type TipInputFilter = {
  resource_path: Scalars['String'];
};

export type UpdateTrackFilter = {
  breaks: Array<Scalars['Float']>;
  chords: Array<ChordsSetType>;
  lyrics: Array<Array<LyricsSetType>>;
  masterlist_id?: Maybe<Scalars['Int']>;
  microsecondsPerBeat?: Maybe<Scalars['Int']>;
  resourcePath: Scalars['String'];
  songId: Scalars['Int'];
  trackId: Scalars['Int'];
  trackTypeId: Scalars['Int'];
};

export type UploadTrackFilter = {
  breaks: Array<Scalars['Float']>;
  chords: Array<ChordsSetType>;
  lyrics: Array<Array<LyricsSetType>>;
  masterlist_id?: Maybe<Scalars['Int']>;
  microsecondsPerBeat?: Maybe<Scalars['Int']>;
  resourcePath: Scalars['String'];
  songId: Scalars['Int'];
  trackTypeId: Scalars['Int'];
};

export type ValidateEmailFilter = {
  email: Scalars['String'];
};

export type ValidateStudentsInClassData = {
  __typename?: 'ValidateStudentsInClassData';
  error: Scalars['String'];
  studentsAlreadyInClass: Array<Maybe<StudentDataOutput>>;
  studentsNewInClass: Array<Maybe<StudentDataOutput>>;
  success: Scalars['Boolean'];
};

export type ValidateStudentsInClassFilter = {
  studentsInClass: StudentsInClass;
};


/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: Maybe<Scalars['_int4']>;
  _gt?: Maybe<Scalars['_int4']>;
  _gte?: Maybe<Scalars['_int4']>;
  _in?: Maybe<Array<Scalars['_int4']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_int4']>;
  _lte?: Maybe<Scalars['_int4']>;
  _neq?: Maybe<Scalars['_int4']>;
  _nin?: Maybe<Array<Scalars['_int4']>>;
};


/** columns and relationships of "account_activation" */
export type Account_Activation = {
  __typename?: 'account_activation';
  account_activation_id: Scalars['Int'];
  /** An object relationship */
  admin?: Maybe<Admin>;
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled: Scalars['Boolean'];
  /** An object relationship */
  invited_by_admin?: Maybe<Admin>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  teacher_id?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  used: Scalars['Boolean'];
};

/** aggregated selection of "account_activation" */
export type Account_Activation_Aggregate = {
  __typename?: 'account_activation_aggregate';
  aggregate?: Maybe<Account_Activation_Aggregate_Fields>;
  nodes: Array<Account_Activation>;
};

/** aggregate fields of "account_activation" */
export type Account_Activation_Aggregate_Fields = {
  __typename?: 'account_activation_aggregate_fields';
  avg?: Maybe<Account_Activation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Activation_Max_Fields>;
  min?: Maybe<Account_Activation_Min_Fields>;
  stddev?: Maybe<Account_Activation_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Activation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Activation_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Activation_Sum_Fields>;
  var_pop?: Maybe<Account_Activation_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Activation_Var_Samp_Fields>;
  variance?: Maybe<Account_Activation_Variance_Fields>;
};


/** aggregate fields of "account_activation" */
export type Account_Activation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Activation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Activation_Avg_Fields = {
  __typename?: 'account_activation_avg_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account_activation". All fields are combined with a logical 'AND'. */
export type Account_Activation_Bool_Exp = {
  _and?: Maybe<Array<Account_Activation_Bool_Exp>>;
  _not?: Maybe<Account_Activation_Bool_Exp>;
  _or?: Maybe<Array<Account_Activation_Bool_Exp>>;
  account_activation_id?: Maybe<Int_Comparison_Exp>;
  admin?: Maybe<Admin_Bool_Exp>;
  admin_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  invited_by_admin?: Maybe<Admin_Bool_Exp>;
  invited_by_admin_id?: Maybe<Int_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  used?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_activation" */
export enum Account_Activation_Constraint {
  /** unique or primary key constraint */
  AccountActivationPkey = 'account_activation_pkey'
}

/** input type for incrementing numeric columns in table "account_activation" */
export type Account_Activation_Inc_Input = {
  account_activation_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_activation" */
export type Account_Activation_Insert_Input = {
  account_activation_id?: Maybe<Scalars['Int']>;
  admin?: Maybe<Admin_Obj_Rel_Insert_Input>;
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  invited_by_admin?: Maybe<Admin_Obj_Rel_Insert_Input>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Account_Activation_Max_Fields = {
  __typename?: 'account_activation_max_fields';
  account_activation_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Account_Activation_Min_Fields = {
  __typename?: 'account_activation_min_fields';
  account_activation_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "account_activation" */
export type Account_Activation_Mutation_Response = {
  __typename?: 'account_activation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Activation>;
};

/** on_conflict condition type for table "account_activation" */
export type Account_Activation_On_Conflict = {
  constraint: Account_Activation_Constraint;
  update_columns?: Array<Account_Activation_Update_Column>;
  where?: Maybe<Account_Activation_Bool_Exp>;
};

/** Ordering options when selecting data from "account_activation". */
export type Account_Activation_Order_By = {
  account_activation_id?: Maybe<Order_By>;
  admin?: Maybe<Admin_Order_By>;
  admin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  invited_by_admin?: Maybe<Admin_Order_By>;
  invited_by_admin_id?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used?: Maybe<Order_By>;
};

/** primary key columns input for table: account_activation */
export type Account_Activation_Pk_Columns_Input = {
  account_activation_id: Scalars['Int'];
};

/** select columns of table "account_activation" */
export enum Account_Activation_Select_Column {
  /** column name */
  AccountActivationId = 'account_activation_id',
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  InvitedByAdminId = 'invited_by_admin_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "account_activation" */
export type Account_Activation_Set_Input = {
  account_activation_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Account_Activation_Stddev_Fields = {
  __typename?: 'account_activation_stddev_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Activation_Stddev_Pop_Fields = {
  __typename?: 'account_activation_stddev_pop_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Activation_Stddev_Samp_Fields = {
  __typename?: 'account_activation_stddev_samp_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Account_Activation_Sum_Fields = {
  __typename?: 'account_activation_sum_fields';
  account_activation_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  invited_by_admin_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "account_activation" */
export enum Account_Activation_Update_Column {
  /** column name */
  AccountActivationId = 'account_activation_id',
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  InvitedByAdminId = 'invited_by_admin_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used'
}

/** aggregate var_pop on columns */
export type Account_Activation_Var_Pop_Fields = {
  __typename?: 'account_activation_var_pop_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Activation_Var_Samp_Fields = {
  __typename?: 'account_activation_var_samp_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Activation_Variance_Fields = {
  __typename?: 'account_activation_variance_fields';
  account_activation_id?: Maybe<Scalars['Float']>;
  admin_id?: Maybe<Scalars['Float']>;
  invited_by_admin_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "admin" */
export type Admin = {
  __typename?: 'admin';
  admin_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  wurrly_role: Wurrly_Role_Enum;
};

/** aggregated selection of "admin" */
export type Admin_Aggregate = {
  __typename?: 'admin_aggregate';
  aggregate?: Maybe<Admin_Aggregate_Fields>;
  nodes: Array<Admin>;
};

/** aggregate fields of "admin" */
export type Admin_Aggregate_Fields = {
  __typename?: 'admin_aggregate_fields';
  avg?: Maybe<Admin_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_Max_Fields>;
  min?: Maybe<Admin_Min_Fields>;
  stddev?: Maybe<Admin_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_Sum_Fields>;
  var_pop?: Maybe<Admin_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_Var_Samp_Fields>;
  variance?: Maybe<Admin_Variance_Fields>;
};


/** aggregate fields of "admin" */
export type Admin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_Avg_Fields = {
  __typename?: 'admin_avg_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "admin". All fields are combined with a logical 'AND'. */
export type Admin_Bool_Exp = {
  _and?: Maybe<Array<Admin_Bool_Exp>>;
  _not?: Maybe<Admin_Bool_Exp>;
  _or?: Maybe<Array<Admin_Bool_Exp>>;
  admin_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wurrly_role?: Maybe<Wurrly_Role_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin" */
export enum Admin_Constraint {
  /** unique or primary key constraint */
  AdminEmailKey = 'admin_email_key',
  /** unique or primary key constraint */
  AdminPkey = 'admin_pkey'
}

/** input type for incrementing numeric columns in table "admin" */
export type Admin_Inc_Input = {
  admin_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "admin" */
export type Admin_Insert_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
};

/** aggregate max on columns */
export type Admin_Max_Fields = {
  __typename?: 'admin_max_fields';
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Admin_Min_Fields = {
  __typename?: 'admin_min_fields';
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "admin" */
export type Admin_Mutation_Response = {
  __typename?: 'admin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin>;
};

/** input type for inserting object relation for remote table "admin" */
export type Admin_Obj_Rel_Insert_Input = {
  data: Admin_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Admin_On_Conflict>;
};

/** on_conflict condition type for table "admin" */
export type Admin_On_Conflict = {
  constraint: Admin_Constraint;
  update_columns?: Array<Admin_Update_Column>;
  where?: Maybe<Admin_Bool_Exp>;
};

/** Ordering options when selecting data from "admin". */
export type Admin_Order_By = {
  admin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_role?: Maybe<Order_By>;
};

/** primary key columns input for table: admin */
export type Admin_Pk_Columns_Input = {
  admin_id: Scalars['Int'];
};

/** select columns of table "admin" */
export enum Admin_Select_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyRole = 'wurrly_role'
}

/** input type for updating data in table "admin" */
export type Admin_Set_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
};

/** aggregate stddev on columns */
export type Admin_Stddev_Fields = {
  __typename?: 'admin_stddev_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_Stddev_Pop_Fields = {
  __typename?: 'admin_stddev_pop_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_Stddev_Samp_Fields = {
  __typename?: 'admin_stddev_samp_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_Sum_Fields = {
  __typename?: 'admin_sum_fields';
  admin_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "admin" */
export enum Admin_Update_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyRole = 'wurrly_role'
}

/** aggregate var_pop on columns */
export type Admin_Var_Pop_Fields = {
  __typename?: 'admin_var_pop_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_Var_Samp_Fields = {
  __typename?: 'admin_var_samp_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_Variance_Fields = {
  __typename?: 'admin_variance_fields';
  admin_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "app" */
export type App = {
  __typename?: 'app';
  app_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  version: Scalars['String'];
};

/** aggregated selection of "app" */
export type App_Aggregate = {
  __typename?: 'app_aggregate';
  aggregate?: Maybe<App_Aggregate_Fields>;
  nodes: Array<App>;
};

/** aggregate fields of "app" */
export type App_Aggregate_Fields = {
  __typename?: 'app_aggregate_fields';
  avg?: Maybe<App_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<App_Max_Fields>;
  min?: Maybe<App_Min_Fields>;
  stddev?: Maybe<App_Stddev_Fields>;
  stddev_pop?: Maybe<App_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Stddev_Samp_Fields>;
  sum?: Maybe<App_Sum_Fields>;
  var_pop?: Maybe<App_Var_Pop_Fields>;
  var_samp?: Maybe<App_Var_Samp_Fields>;
  variance?: Maybe<App_Variance_Fields>;
};


/** aggregate fields of "app" */
export type App_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type App_Avg_Fields = {
  __typename?: 'app_avg_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "app". All fields are combined with a logical 'AND'. */
export type App_Bool_Exp = {
  _and?: Maybe<Array<App_Bool_Exp>>;
  _not?: Maybe<App_Bool_Exp>;
  _or?: Maybe<Array<App_Bool_Exp>>;
  app_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app" */
export enum App_Constraint {
  /** unique or primary key constraint */
  AppPkey = 'app_pkey',
  /** unique or primary key constraint */
  AppVersionKey = 'app_version_key'
}

/** input type for incrementing numeric columns in table "app" */
export type App_Inc_Input = {
  app_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "app" */
export type App_Insert_Input = {
  app_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Max_Fields = {
  __typename?: 'app_max_fields';
  app_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type App_Min_Fields = {
  __typename?: 'app_min_fields';
  app_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "app" */
export type App_Mutation_Response = {
  __typename?: 'app_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App>;
};

/** on_conflict condition type for table "app" */
export type App_On_Conflict = {
  constraint: App_Constraint;
  update_columns?: Array<App_Update_Column>;
  where?: Maybe<App_Bool_Exp>;
};

/** Ordering options when selecting data from "app". */
export type App_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: app */
export type App_Pk_Columns_Input = {
  app_id: Scalars['Int'];
};

/** select columns of table "app" */
export enum App_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "app" */
export type App_Set_Input = {
  app_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type App_Stddev_Fields = {
  __typename?: 'app_stddev_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type App_Stddev_Pop_Fields = {
  __typename?: 'app_stddev_pop_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type App_Stddev_Samp_Fields = {
  __typename?: 'app_stddev_samp_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type App_Sum_Fields = {
  __typename?: 'app_sum_fields';
  app_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "app" */
export enum App_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type App_Var_Pop_Fields = {
  __typename?: 'app_var_pop_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type App_Var_Samp_Fields = {
  __typename?: 'app_var_samp_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type App_Variance_Fields = {
  __typename?: 'app_variance_fields';
  app_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "artist" */
export type Artist = {
  __typename?: 'artist';
  artist_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  last_update: Scalars['timestamptz'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "artist" */
export type ArtistDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "artist" */
export type Artist_Aggregate = {
  __typename?: 'artist_aggregate';
  aggregate?: Maybe<Artist_Aggregate_Fields>;
  nodes: Array<Artist>;
};

/** aggregate fields of "artist" */
export type Artist_Aggregate_Fields = {
  __typename?: 'artist_aggregate_fields';
  avg?: Maybe<Artist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Max_Fields>;
  min?: Maybe<Artist_Min_Fields>;
  stddev?: Maybe<Artist_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Sum_Fields>;
  var_pop?: Maybe<Artist_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Var_Samp_Fields>;
  variance?: Maybe<Artist_Variance_Fields>;
};


/** aggregate fields of "artist" */
export type Artist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Artist_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Artist_Avg_Fields = {
  __typename?: 'artist_avg_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "artist". All fields are combined with a logical 'AND'. */
export type Artist_Bool_Exp = {
  _and?: Maybe<Array<Artist_Bool_Exp>>;
  _not?: Maybe<Artist_Bool_Exp>;
  _or?: Maybe<Array<Artist_Bool_Exp>>;
  artist_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "artist" */
export enum Artist_Constraint {
  /** unique or primary key constraint */
  ArtistPkey = 'artist_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Artist_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Artist_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Artist_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "artist" */
export type Artist_Inc_Input = {
  artist_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "artist" */
export type Artist_Insert_Input = {
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Max_Fields = {
  __typename?: 'artist_max_fields';
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artist_Min_Fields = {
  __typename?: 'artist_min_fields';
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "artist" */
export type Artist_Mutation_Response = {
  __typename?: 'artist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist>;
};

/** input type for inserting object relation for remote table "artist" */
export type Artist_Obj_Rel_Insert_Input = {
  data: Artist_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artist_On_Conflict>;
};

/** on_conflict condition type for table "artist" */
export type Artist_On_Conflict = {
  constraint: Artist_Constraint;
  update_columns?: Array<Artist_Update_Column>;
  where?: Maybe<Artist_Bool_Exp>;
};

/** Ordering options when selecting data from "artist". */
export type Artist_Order_By = {
  artist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: artist */
export type Artist_Pk_Columns_Input = {
  artist_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Artist_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "artist" */
export enum Artist_Select_Column {
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "artist" */
export type Artist_Set_Input = {
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Stddev_Fields = {
  __typename?: 'artist_stddev_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Stddev_Pop_Fields = {
  __typename?: 'artist_stddev_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Stddev_Samp_Fields = {
  __typename?: 'artist_stddev_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Sum_Fields = {
  __typename?: 'artist_sum_fields';
  artist_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "artist" */
export enum Artist_Update_Column {
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Var_Pop_Fields = {
  __typename?: 'artist_var_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Var_Samp_Fields = {
  __typename?: 'artist_var_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Variance_Fields = {
  __typename?: 'artist_variance_fields';
  artist_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "asset" */
export type Asset = {
  __typename?: 'asset';
  asset_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  lesson_plan_assets: Array<Lesson_Plan_Asset>;
  /** An aggregate relationship */
  lesson_plan_assets_aggregate: Lesson_Plan_Asset_Aggregate;
  name: Scalars['String'];
  resource_path: Scalars['String'];
  /** An array relationship */
  submission: Array<Submission_Asset>;
  /** An aggregate relationship */
  submission_aggregate: Submission_Asset_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "asset" */
export type AssetLesson_Plan_AssetsArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


/** columns and relationships of "asset" */
export type AssetLesson_Plan_Assets_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


/** columns and relationships of "asset" */
export type AssetSubmissionArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


/** columns and relationships of "asset" */
export type AssetSubmission_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};

export type AssetT = {
  __typename?: 'assetT';
  asset_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "asset" */
export type Asset_Aggregate = {
  __typename?: 'asset_aggregate';
  aggregate?: Maybe<Asset_Aggregate_Fields>;
  nodes: Array<Asset>;
};

/** aggregate fields of "asset" */
export type Asset_Aggregate_Fields = {
  __typename?: 'asset_aggregate_fields';
  avg?: Maybe<Asset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Asset_Max_Fields>;
  min?: Maybe<Asset_Min_Fields>;
  stddev?: Maybe<Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Asset_Sum_Fields>;
  var_pop?: Maybe<Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Asset_Var_Samp_Fields>;
  variance?: Maybe<Asset_Variance_Fields>;
};


/** aggregate fields of "asset" */
export type Asset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Asset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Asset_Avg_Fields = {
  __typename?: 'asset_avg_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "asset". All fields are combined with a logical 'AND'. */
export type Asset_Bool_Exp = {
  _and?: Maybe<Array<Asset_Bool_Exp>>;
  _not?: Maybe<Asset_Bool_Exp>;
  _or?: Maybe<Array<Asset_Bool_Exp>>;
  asset_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan_assets?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  resource_path?: Maybe<String_Comparison_Exp>;
  submission?: Maybe<Submission_Asset_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "asset" */
export enum Asset_Constraint {
  /** unique or primary key constraint */
  AssetPkey = 'asset_pkey'
}

/** input type for incrementing numeric columns in table "asset" */
export type Asset_Inc_Input = {
  asset_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "asset" */
export type Asset_Insert_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_assets?: Maybe<Lesson_Plan_Asset_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  submission?: Maybe<Submission_Asset_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Asset_Max_Fields = {
  __typename?: 'asset_max_fields';
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Asset_Min_Fields = {
  __typename?: 'asset_min_fields';
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "asset" */
export type Asset_Mutation_Response = {
  __typename?: 'asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Asset>;
};

/** input type for inserting object relation for remote table "asset" */
export type Asset_Obj_Rel_Insert_Input = {
  data: Asset_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Asset_On_Conflict>;
};

/** on_conflict condition type for table "asset" */
export type Asset_On_Conflict = {
  constraint: Asset_Constraint;
  update_columns?: Array<Asset_Update_Column>;
  where?: Maybe<Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "asset". */
export type Asset_Order_By = {
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_assets_aggregate?: Maybe<Lesson_Plan_Asset_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  submission_aggregate?: Maybe<Submission_Asset_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: asset */
export type Asset_Pk_Columns_Input = {
  asset_id: Scalars['Int'];
};

/** select columns of table "asset" */
export enum Asset_Select_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset" */
export type Asset_Set_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Asset_Stddev_Fields = {
  __typename?: 'asset_stddev_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Asset_Stddev_Pop_Fields = {
  __typename?: 'asset_stddev_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Asset_Stddev_Samp_Fields = {
  __typename?: 'asset_stddev_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Asset_Sum_Fields = {
  __typename?: 'asset_sum_fields';
  asset_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "asset" */
export enum Asset_Update_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Asset_Var_Pop_Fields = {
  __typename?: 'asset_var_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Asset_Var_Samp_Fields = {
  __typename?: 'asset_var_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Asset_Variance_Fields = {
  __typename?: 'asset_variance_fields';
  asset_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "assignment" */
export type Assignment = {
  __typename?: 'assignment';
  allow_external_files: Scalars['Boolean'];
  /** An array relationship */
  assignment_assets: Array<Assignment_Asset>;
  /** An aggregate relationship */
  assignment_assets_aggregate: Assignment_Asset_Aggregate;
  assignment_id: Scalars['Int'];
  /** An array relationship */
  assignment_songs: Array<Assignment_Song>;
  /** An aggregate relationship */
  assignment_songs_aggregate: Assignment_Song_Aggregate;
  /** An array relationship */
  assignment_tips: Array<Assignment_Tip>;
  /** An aggregate relationship */
  assignment_tips_aggregate: Assignment_Tip_Aggregate;
  /** An array relationship */
  class_assignments: Array<Class_Assignment>;
  /** An aggregate relationship */
  class_assignments_aggregate: Class_Assignment_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date: Scalars['date'];
  due_time?: Maybe<Scalars['timetz']>;
  /** An object relationship */
  grading_system: Grading_System;
  grading_system_id: Scalars['Int'];
  include_all_songs: Scalars['Boolean'];
  instructions: Scalars['String'];
  name: Scalars['String'];
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "assignment" */
export type AssignmentAssignment_AssetsArgs = {
  distinct_on?: Maybe<Array<Assignment_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Asset_Order_By>>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentAssignment_Assets_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Asset_Order_By>>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentAssignment_SongsArgs = {
  distinct_on?: Maybe<Array<Assignment_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Song_Order_By>>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentAssignment_Songs_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Song_Order_By>>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentAssignment_TipsArgs = {
  distinct_on?: Maybe<Array<Assignment_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Tip_Order_By>>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentAssignment_Tips_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Tip_Order_By>>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentClass_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentClass_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};

/** aggregated selection of "assignment" */
export type Assignment_Aggregate = {
  __typename?: 'assignment_aggregate';
  aggregate?: Maybe<Assignment_Aggregate_Fields>;
  nodes: Array<Assignment>;
};

/** aggregate fields of "assignment" */
export type Assignment_Aggregate_Fields = {
  __typename?: 'assignment_aggregate_fields';
  avg?: Maybe<Assignment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignment_Max_Fields>;
  min?: Maybe<Assignment_Min_Fields>;
  stddev?: Maybe<Assignment_Stddev_Fields>;
  stddev_pop?: Maybe<Assignment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignment_Stddev_Samp_Fields>;
  sum?: Maybe<Assignment_Sum_Fields>;
  var_pop?: Maybe<Assignment_Var_Pop_Fields>;
  var_samp?: Maybe<Assignment_Var_Samp_Fields>;
  variance?: Maybe<Assignment_Variance_Fields>;
};


/** aggregate fields of "assignment" */
export type Assignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assignment" */
export type Assignment_Aggregate_Order_By = {
  avg?: Maybe<Assignment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Assignment_Max_Order_By>;
  min?: Maybe<Assignment_Min_Order_By>;
  stddev?: Maybe<Assignment_Stddev_Order_By>;
  stddev_pop?: Maybe<Assignment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Assignment_Stddev_Samp_Order_By>;
  sum?: Maybe<Assignment_Sum_Order_By>;
  var_pop?: Maybe<Assignment_Var_Pop_Order_By>;
  var_samp?: Maybe<Assignment_Var_Samp_Order_By>;
  variance?: Maybe<Assignment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assignment" */
export type Assignment_Arr_Rel_Insert_Input = {
  data: Array<Assignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_On_Conflict>;
};

/** columns and relationships of "assignment_asset" */
export type Assignment_Asset = {
  __typename?: 'assignment_asset';
  /** An object relationship */
  asset: Asset;
  asset_id: Scalars['Int'];
  /** An object relationship */
  assignment: Assignment;
  assignment_asset_id: Scalars['Int'];
  assignment_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "assignment_asset" */
export type Assignment_Asset_Aggregate = {
  __typename?: 'assignment_asset_aggregate';
  aggregate?: Maybe<Assignment_Asset_Aggregate_Fields>;
  nodes: Array<Assignment_Asset>;
};

/** aggregate fields of "assignment_asset" */
export type Assignment_Asset_Aggregate_Fields = {
  __typename?: 'assignment_asset_aggregate_fields';
  avg?: Maybe<Assignment_Asset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignment_Asset_Max_Fields>;
  min?: Maybe<Assignment_Asset_Min_Fields>;
  stddev?: Maybe<Assignment_Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Assignment_Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignment_Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Assignment_Asset_Sum_Fields>;
  var_pop?: Maybe<Assignment_Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Assignment_Asset_Var_Samp_Fields>;
  variance?: Maybe<Assignment_Asset_Variance_Fields>;
};


/** aggregate fields of "assignment_asset" */
export type Assignment_Asset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_Asset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assignment_asset" */
export type Assignment_Asset_Aggregate_Order_By = {
  avg?: Maybe<Assignment_Asset_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Assignment_Asset_Max_Order_By>;
  min?: Maybe<Assignment_Asset_Min_Order_By>;
  stddev?: Maybe<Assignment_Asset_Stddev_Order_By>;
  stddev_pop?: Maybe<Assignment_Asset_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Assignment_Asset_Stddev_Samp_Order_By>;
  sum?: Maybe<Assignment_Asset_Sum_Order_By>;
  var_pop?: Maybe<Assignment_Asset_Var_Pop_Order_By>;
  var_samp?: Maybe<Assignment_Asset_Var_Samp_Order_By>;
  variance?: Maybe<Assignment_Asset_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assignment_asset" */
export type Assignment_Asset_Arr_Rel_Insert_Input = {
  data: Array<Assignment_Asset_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_Asset_On_Conflict>;
};

/** aggregate avg on columns */
export type Assignment_Asset_Avg_Fields = {
  __typename?: 'assignment_asset_avg_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assignment_asset" */
export type Assignment_Asset_Avg_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assignment_asset". All fields are combined with a logical 'AND'. */
export type Assignment_Asset_Bool_Exp = {
  _and?: Maybe<Array<Assignment_Asset_Bool_Exp>>;
  _not?: Maybe<Assignment_Asset_Bool_Exp>;
  _or?: Maybe<Array<Assignment_Asset_Bool_Exp>>;
  asset?: Maybe<Asset_Bool_Exp>;
  asset_id?: Maybe<Int_Comparison_Exp>;
  assignment?: Maybe<Assignment_Bool_Exp>;
  assignment_asset_id?: Maybe<Int_Comparison_Exp>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment_asset" */
export enum Assignment_Asset_Constraint {
  /** unique or primary key constraint */
  AssignmentAssetPkey = 'assignment_asset_pkey'
}

/** input type for incrementing numeric columns in table "assignment_asset" */
export type Assignment_Asset_Inc_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  assignment_asset_id?: Maybe<Scalars['Int']>;
  assignment_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assignment_asset" */
export type Assignment_Asset_Insert_Input = {
  asset?: Maybe<Asset_Obj_Rel_Insert_Input>;
  asset_id?: Maybe<Scalars['Int']>;
  assignment?: Maybe<Assignment_Obj_Rel_Insert_Input>;
  assignment_asset_id?: Maybe<Scalars['Int']>;
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assignment_Asset_Max_Fields = {
  __typename?: 'assignment_asset_max_fields';
  asset_id?: Maybe<Scalars['Int']>;
  assignment_asset_id?: Maybe<Scalars['Int']>;
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assignment_asset" */
export type Assignment_Asset_Max_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assignment_Asset_Min_Fields = {
  __typename?: 'assignment_asset_min_fields';
  asset_id?: Maybe<Scalars['Int']>;
  assignment_asset_id?: Maybe<Scalars['Int']>;
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assignment_asset" */
export type Assignment_Asset_Min_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "assignment_asset" */
export type Assignment_Asset_Mutation_Response = {
  __typename?: 'assignment_asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment_Asset>;
};

/** on_conflict condition type for table "assignment_asset" */
export type Assignment_Asset_On_Conflict = {
  constraint: Assignment_Asset_Constraint;
  update_columns?: Array<Assignment_Asset_Update_Column>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment_asset". */
export type Assignment_Asset_Order_By = {
  asset?: Maybe<Asset_Order_By>;
  asset_id?: Maybe<Order_By>;
  assignment?: Maybe<Assignment_Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment_asset */
export type Assignment_Asset_Pk_Columns_Input = {
  assignment_asset_id: Scalars['Int'];
};

/** select columns of table "assignment_asset" */
export enum Assignment_Asset_Select_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  AssignmentAssetId = 'assignment_asset_id',
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assignment_asset" */
export type Assignment_Asset_Set_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  assignment_asset_id?: Maybe<Scalars['Int']>;
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Assignment_Asset_Stddev_Fields = {
  __typename?: 'assignment_asset_stddev_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assignment_asset" */
export type Assignment_Asset_Stddev_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assignment_Asset_Stddev_Pop_Fields = {
  __typename?: 'assignment_asset_stddev_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assignment_asset" */
export type Assignment_Asset_Stddev_Pop_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assignment_Asset_Stddev_Samp_Fields = {
  __typename?: 'assignment_asset_stddev_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assignment_asset" */
export type Assignment_Asset_Stddev_Samp_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Assignment_Asset_Sum_Fields = {
  __typename?: 'assignment_asset_sum_fields';
  asset_id?: Maybe<Scalars['Int']>;
  assignment_asset_id?: Maybe<Scalars['Int']>;
  assignment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assignment_asset" */
export type Assignment_Asset_Sum_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** update columns of table "assignment_asset" */
export enum Assignment_Asset_Update_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  AssignmentAssetId = 'assignment_asset_id',
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Assignment_Asset_Var_Pop_Fields = {
  __typename?: 'assignment_asset_var_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assignment_asset" */
export type Assignment_Asset_Var_Pop_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assignment_Asset_Var_Samp_Fields = {
  __typename?: 'assignment_asset_var_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assignment_asset" */
export type Assignment_Asset_Var_Samp_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Assignment_Asset_Variance_Fields = {
  __typename?: 'assignment_asset_variance_fields';
  asset_id?: Maybe<Scalars['Float']>;
  assignment_asset_id?: Maybe<Scalars['Float']>;
  assignment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assignment_asset" */
export type Assignment_Asset_Variance_Order_By = {
  asset_id?: Maybe<Order_By>;
  assignment_asset_id?: Maybe<Order_By>;
  assignment_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Assignment_Avg_Fields = {
  __typename?: 'assignment_avg_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assignment" */
export type Assignment_Avg_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assignment". All fields are combined with a logical 'AND'. */
export type Assignment_Bool_Exp = {
  _and?: Maybe<Array<Assignment_Bool_Exp>>;
  _not?: Maybe<Assignment_Bool_Exp>;
  _or?: Maybe<Array<Assignment_Bool_Exp>>;
  allow_external_files?: Maybe<Boolean_Comparison_Exp>;
  assignment_assets?: Maybe<Assignment_Asset_Bool_Exp>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  assignment_songs?: Maybe<Assignment_Song_Bool_Exp>;
  assignment_tips?: Maybe<Assignment_Tip_Bool_Exp>;
  class_assignments?: Maybe<Class_Assignment_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_date?: Maybe<Date_Comparison_Exp>;
  due_time?: Maybe<Timetz_Comparison_Exp>;
  grading_system?: Maybe<Grading_System_Bool_Exp>;
  grading_system_id?: Maybe<Int_Comparison_Exp>;
  include_all_songs?: Maybe<Boolean_Comparison_Exp>;
  instructions?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  reference_lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment" */
export enum Assignment_Constraint {
  /** unique or primary key constraint */
  AssignmentPkey = 'assignment_pkey'
}

export type Assignment_Created_Lesson_Plan_Args = {
  classid?: Maybe<Scalars['Int']>;
};

/** input type for incrementing numeric columns in table "assignment" */
export type Assignment_Inc_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assignment" */
export type Assignment_Insert_Input = {
  allow_external_files?: Maybe<Scalars['Boolean']>;
  assignment_assets?: Maybe<Assignment_Asset_Arr_Rel_Insert_Input>;
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_songs?: Maybe<Assignment_Song_Arr_Rel_Insert_Input>;
  assignment_tips?: Maybe<Assignment_Tip_Arr_Rel_Insert_Input>;
  class_assignments?: Maybe<Class_Assignment_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  due_time?: Maybe<Scalars['timetz']>;
  grading_system?: Maybe<Grading_System_Obj_Rel_Insert_Input>;
  grading_system_id?: Maybe<Scalars['Int']>;
  include_all_songs?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "assignment_lesson_plan" */
export type Assignment_Lesson_Plan = {
  __typename?: 'assignment_lesson_plan';
  assignment_id: Scalars['Int'];
  assignment_lesson_plan_id: Scalars['Int'];
  lesson_plan_id: Scalars['Int'];
};

/** aggregated selection of "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Aggregate = {
  __typename?: 'assignment_lesson_plan_aggregate';
  aggregate?: Maybe<Assignment_Lesson_Plan_Aggregate_Fields>;
  nodes: Array<Assignment_Lesson_Plan>;
};

/** aggregate fields of "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Aggregate_Fields = {
  __typename?: 'assignment_lesson_plan_aggregate_fields';
  avg?: Maybe<Assignment_Lesson_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignment_Lesson_Plan_Max_Fields>;
  min?: Maybe<Assignment_Lesson_Plan_Min_Fields>;
  stddev?: Maybe<Assignment_Lesson_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Assignment_Lesson_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignment_Lesson_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Assignment_Lesson_Plan_Sum_Fields>;
  var_pop?: Maybe<Assignment_Lesson_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Assignment_Lesson_Plan_Var_Samp_Fields>;
  variance?: Maybe<Assignment_Lesson_Plan_Variance_Fields>;
};


/** aggregate fields of "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_Lesson_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Assignment_Lesson_Plan_Avg_Fields = {
  __typename?: 'assignment_lesson_plan_avg_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "assignment_lesson_plan". All fields are combined with a logical 'AND'. */
export type Assignment_Lesson_Plan_Bool_Exp = {
  _and?: Maybe<Array<Assignment_Lesson_Plan_Bool_Exp>>;
  _not?: Maybe<Assignment_Lesson_Plan_Bool_Exp>;
  _or?: Maybe<Array<Assignment_Lesson_Plan_Bool_Exp>>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  assignment_lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment_lesson_plan" */
export enum Assignment_Lesson_Plan_Constraint {
  /** unique or primary key constraint */
  AssignmentLessonPlanPkey = 'assignment_lesson_plan_pkey'
}

/** input type for incrementing numeric columns in table "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Inc_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Insert_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Assignment_Lesson_Plan_Max_Fields = {
  __typename?: 'assignment_lesson_plan_max_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Assignment_Lesson_Plan_Min_Fields = {
  __typename?: 'assignment_lesson_plan_min_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Mutation_Response = {
  __typename?: 'assignment_lesson_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment_Lesson_Plan>;
};

/** on_conflict condition type for table "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_On_Conflict = {
  constraint: Assignment_Lesson_Plan_Constraint;
  update_columns?: Array<Assignment_Lesson_Plan_Update_Column>;
  where?: Maybe<Assignment_Lesson_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment_lesson_plan". */
export type Assignment_Lesson_Plan_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment_lesson_plan */
export type Assignment_Lesson_Plan_Pk_Columns_Input = {
  assignment_lesson_plan_id: Scalars['Int'];
};

/** select columns of table "assignment_lesson_plan" */
export enum Assignment_Lesson_Plan_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  AssignmentLessonPlanId = 'assignment_lesson_plan_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id'
}

/** input type for updating data in table "assignment_lesson_plan" */
export type Assignment_Lesson_Plan_Set_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Assignment_Lesson_Plan_Stddev_Fields = {
  __typename?: 'assignment_lesson_plan_stddev_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Assignment_Lesson_Plan_Stddev_Pop_Fields = {
  __typename?: 'assignment_lesson_plan_stddev_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Assignment_Lesson_Plan_Stddev_Samp_Fields = {
  __typename?: 'assignment_lesson_plan_stddev_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Assignment_Lesson_Plan_Sum_Fields = {
  __typename?: 'assignment_lesson_plan_sum_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "assignment_lesson_plan" */
export enum Assignment_Lesson_Plan_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  AssignmentLessonPlanId = 'assignment_lesson_plan_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id'
}

/** aggregate var_pop on columns */
export type Assignment_Lesson_Plan_Var_Pop_Fields = {
  __typename?: 'assignment_lesson_plan_var_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Assignment_Lesson_Plan_Var_Samp_Fields = {
  __typename?: 'assignment_lesson_plan_var_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Assignment_Lesson_Plan_Variance_Fields = {
  __typename?: 'assignment_lesson_plan_variance_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Assignment_Max_Fields = {
  __typename?: 'assignment_max_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  due_time?: Maybe<Scalars['timetz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assignment" */
export type Assignment_Max_Order_By = {
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  due_time?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  instructions?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assignment_Min_Fields = {
  __typename?: 'assignment_min_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  due_time?: Maybe<Scalars['timetz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assignment" */
export type Assignment_Min_Order_By = {
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  due_time?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  instructions?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "assignment" */
export type Assignment_Mutation_Response = {
  __typename?: 'assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment>;
};

/** input type for inserting object relation for remote table "assignment" */
export type Assignment_Obj_Rel_Insert_Input = {
  data: Assignment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_On_Conflict>;
};

/** on_conflict condition type for table "assignment" */
export type Assignment_On_Conflict = {
  constraint: Assignment_Constraint;
  update_columns?: Array<Assignment_Update_Column>;
  where?: Maybe<Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment". */
export type Assignment_Order_By = {
  allow_external_files?: Maybe<Order_By>;
  assignment_assets_aggregate?: Maybe<Assignment_Asset_Aggregate_Order_By>;
  assignment_id?: Maybe<Order_By>;
  assignment_songs_aggregate?: Maybe<Assignment_Song_Aggregate_Order_By>;
  assignment_tips_aggregate?: Maybe<Assignment_Tip_Aggregate_Order_By>;
  class_assignments_aggregate?: Maybe<Class_Assignment_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  due_time?: Maybe<Order_By>;
  grading_system?: Maybe<Grading_System_Order_By>;
  grading_system_id?: Maybe<Order_By>;
  include_all_songs?: Maybe<Order_By>;
  instructions?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment */
export type Assignment_Pk_Columns_Input = {
  assignment_id: Scalars['Int'];
};

/** select columns of table "assignment" */
export enum Assignment_Select_Column {
  /** column name */
  AllowExternalFiles = 'allow_external_files',
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  DueTime = 'due_time',
  /** column name */
  GradingSystemId = 'grading_system_id',
  /** column name */
  IncludeAllSongs = 'include_all_songs',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  ReferenceLessonPlanId = 'reference_lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assignment" */
export type Assignment_Set_Input = {
  allow_external_files?: Maybe<Scalars['Boolean']>;
  assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  due_date?: Maybe<Scalars['date']>;
  due_time?: Maybe<Scalars['timetz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  include_all_songs?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "assignment_song" */
export type Assignment_Song = {
  __typename?: 'assignment_song';
  /** An object relationship */
  assignment: Assignment;
  assignment_id: Scalars['Int'];
  assignment_song_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "assignment_song" */
export type Assignment_Song_Aggregate = {
  __typename?: 'assignment_song_aggregate';
  aggregate?: Maybe<Assignment_Song_Aggregate_Fields>;
  nodes: Array<Assignment_Song>;
};

/** aggregate fields of "assignment_song" */
export type Assignment_Song_Aggregate_Fields = {
  __typename?: 'assignment_song_aggregate_fields';
  avg?: Maybe<Assignment_Song_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignment_Song_Max_Fields>;
  min?: Maybe<Assignment_Song_Min_Fields>;
  stddev?: Maybe<Assignment_Song_Stddev_Fields>;
  stddev_pop?: Maybe<Assignment_Song_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignment_Song_Stddev_Samp_Fields>;
  sum?: Maybe<Assignment_Song_Sum_Fields>;
  var_pop?: Maybe<Assignment_Song_Var_Pop_Fields>;
  var_samp?: Maybe<Assignment_Song_Var_Samp_Fields>;
  variance?: Maybe<Assignment_Song_Variance_Fields>;
};


/** aggregate fields of "assignment_song" */
export type Assignment_Song_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_Song_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assignment_song" */
export type Assignment_Song_Aggregate_Order_By = {
  avg?: Maybe<Assignment_Song_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Assignment_Song_Max_Order_By>;
  min?: Maybe<Assignment_Song_Min_Order_By>;
  stddev?: Maybe<Assignment_Song_Stddev_Order_By>;
  stddev_pop?: Maybe<Assignment_Song_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Assignment_Song_Stddev_Samp_Order_By>;
  sum?: Maybe<Assignment_Song_Sum_Order_By>;
  var_pop?: Maybe<Assignment_Song_Var_Pop_Order_By>;
  var_samp?: Maybe<Assignment_Song_Var_Samp_Order_By>;
  variance?: Maybe<Assignment_Song_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assignment_song" */
export type Assignment_Song_Arr_Rel_Insert_Input = {
  data: Array<Assignment_Song_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_Song_On_Conflict>;
};

/** aggregate avg on columns */
export type Assignment_Song_Avg_Fields = {
  __typename?: 'assignment_song_avg_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assignment_song" */
export type Assignment_Song_Avg_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assignment_song". All fields are combined with a logical 'AND'. */
export type Assignment_Song_Bool_Exp = {
  _and?: Maybe<Array<Assignment_Song_Bool_Exp>>;
  _not?: Maybe<Assignment_Song_Bool_Exp>;
  _or?: Maybe<Array<Assignment_Song_Bool_Exp>>;
  assignment?: Maybe<Assignment_Bool_Exp>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  assignment_song_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment_song" */
export enum Assignment_Song_Constraint {
  /** unique or primary key constraint */
  AssignmentSongPkey = 'assignment_song_pkey'
}

/** input type for incrementing numeric columns in table "assignment_song" */
export type Assignment_Song_Inc_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assignment_song" */
export type Assignment_Song_Insert_Input = {
  assignment?: Maybe<Assignment_Obj_Rel_Insert_Input>;
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assignment_Song_Max_Fields = {
  __typename?: 'assignment_song_max_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assignment_song" */
export type Assignment_Song_Max_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assignment_Song_Min_Fields = {
  __typename?: 'assignment_song_min_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assignment_song" */
export type Assignment_Song_Min_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "assignment_song" */
export type Assignment_Song_Mutation_Response = {
  __typename?: 'assignment_song_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment_Song>;
};

/** on_conflict condition type for table "assignment_song" */
export type Assignment_Song_On_Conflict = {
  constraint: Assignment_Song_Constraint;
  update_columns?: Array<Assignment_Song_Update_Column>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment_song". */
export type Assignment_Song_Order_By = {
  assignment?: Maybe<Assignment_Order_By>;
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment_song */
export type Assignment_Song_Pk_Columns_Input = {
  assignment_song_id: Scalars['Int'];
};

/** select columns of table "assignment_song" */
export enum Assignment_Song_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  AssignmentSongId = 'assignment_song_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assignment_song" */
export type Assignment_Song_Set_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Assignment_Song_Stddev_Fields = {
  __typename?: 'assignment_song_stddev_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assignment_song" */
export type Assignment_Song_Stddev_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assignment_Song_Stddev_Pop_Fields = {
  __typename?: 'assignment_song_stddev_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assignment_song" */
export type Assignment_Song_Stddev_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assignment_Song_Stddev_Samp_Fields = {
  __typename?: 'assignment_song_stddev_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assignment_song" */
export type Assignment_Song_Stddev_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Assignment_Song_Sum_Fields = {
  __typename?: 'assignment_song_sum_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assignment_song" */
export type Assignment_Song_Sum_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** update columns of table "assignment_song" */
export enum Assignment_Song_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  AssignmentSongId = 'assignment_song_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Assignment_Song_Var_Pop_Fields = {
  __typename?: 'assignment_song_var_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assignment_song" */
export type Assignment_Song_Var_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assignment_Song_Var_Samp_Fields = {
  __typename?: 'assignment_song_var_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assignment_song" */
export type Assignment_Song_Var_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Assignment_Song_Variance_Fields = {
  __typename?: 'assignment_song_variance_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assignment_song" */
export type Assignment_Song_Variance_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Assignment_Stddev_Fields = {
  __typename?: 'assignment_stddev_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assignment" */
export type Assignment_Stddev_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assignment_Stddev_Pop_Fields = {
  __typename?: 'assignment_stddev_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assignment" */
export type Assignment_Stddev_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assignment_Stddev_Samp_Fields = {
  __typename?: 'assignment_stddev_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assignment" */
export type Assignment_Stddev_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Assignment_Sum_Fields = {
  __typename?: 'assignment_sum_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  reference_lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assignment" */
export type Assignment_Sum_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** columns and relationships of "assignment_tip" */
export type Assignment_Tip = {
  __typename?: 'assignment_tip';
  /** An object relationship */
  assignment: Assignment;
  assignment_id: Scalars['Int'];
  assignment_tip_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "assignment_tip" */
export type Assignment_Tip_Aggregate = {
  __typename?: 'assignment_tip_aggregate';
  aggregate?: Maybe<Assignment_Tip_Aggregate_Fields>;
  nodes: Array<Assignment_Tip>;
};

/** aggregate fields of "assignment_tip" */
export type Assignment_Tip_Aggregate_Fields = {
  __typename?: 'assignment_tip_aggregate_fields';
  avg?: Maybe<Assignment_Tip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignment_Tip_Max_Fields>;
  min?: Maybe<Assignment_Tip_Min_Fields>;
  stddev?: Maybe<Assignment_Tip_Stddev_Fields>;
  stddev_pop?: Maybe<Assignment_Tip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignment_Tip_Stddev_Samp_Fields>;
  sum?: Maybe<Assignment_Tip_Sum_Fields>;
  var_pop?: Maybe<Assignment_Tip_Var_Pop_Fields>;
  var_samp?: Maybe<Assignment_Tip_Var_Samp_Fields>;
  variance?: Maybe<Assignment_Tip_Variance_Fields>;
};


/** aggregate fields of "assignment_tip" */
export type Assignment_Tip_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_Tip_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assignment_tip" */
export type Assignment_Tip_Aggregate_Order_By = {
  avg?: Maybe<Assignment_Tip_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Assignment_Tip_Max_Order_By>;
  min?: Maybe<Assignment_Tip_Min_Order_By>;
  stddev?: Maybe<Assignment_Tip_Stddev_Order_By>;
  stddev_pop?: Maybe<Assignment_Tip_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Assignment_Tip_Stddev_Samp_Order_By>;
  sum?: Maybe<Assignment_Tip_Sum_Order_By>;
  var_pop?: Maybe<Assignment_Tip_Var_Pop_Order_By>;
  var_samp?: Maybe<Assignment_Tip_Var_Samp_Order_By>;
  variance?: Maybe<Assignment_Tip_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assignment_tip" */
export type Assignment_Tip_Arr_Rel_Insert_Input = {
  data: Array<Assignment_Tip_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_Tip_On_Conflict>;
};

/** aggregate avg on columns */
export type Assignment_Tip_Avg_Fields = {
  __typename?: 'assignment_tip_avg_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assignment_tip" */
export type Assignment_Tip_Avg_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assignment_tip". All fields are combined with a logical 'AND'. */
export type Assignment_Tip_Bool_Exp = {
  _and?: Maybe<Array<Assignment_Tip_Bool_Exp>>;
  _not?: Maybe<Assignment_Tip_Bool_Exp>;
  _or?: Maybe<Array<Assignment_Tip_Bool_Exp>>;
  assignment?: Maybe<Assignment_Bool_Exp>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  assignment_tip_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment_tip" */
export enum Assignment_Tip_Constraint {
  /** unique or primary key constraint */
  AssignmentTipPkey = 'assignment_tip_pkey'
}

/** input type for incrementing numeric columns in table "assignment_tip" */
export type Assignment_Tip_Inc_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "assignment_tip" */
export type Assignment_Tip_Insert_Input = {
  assignment?: Maybe<Assignment_Obj_Rel_Insert_Input>;
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assignment_Tip_Max_Fields = {
  __typename?: 'assignment_tip_max_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assignment_tip" */
export type Assignment_Tip_Max_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assignment_Tip_Min_Fields = {
  __typename?: 'assignment_tip_min_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assignment_tip" */
export type Assignment_Tip_Min_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "assignment_tip" */
export type Assignment_Tip_Mutation_Response = {
  __typename?: 'assignment_tip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment_Tip>;
};

/** on_conflict condition type for table "assignment_tip" */
export type Assignment_Tip_On_Conflict = {
  constraint: Assignment_Tip_Constraint;
  update_columns?: Array<Assignment_Tip_Update_Column>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment_tip". */
export type Assignment_Tip_Order_By = {
  assignment?: Maybe<Assignment_Order_By>;
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment_tip */
export type Assignment_Tip_Pk_Columns_Input = {
  assignment_tip_id: Scalars['Int'];
};

/** select columns of table "assignment_tip" */
export enum Assignment_Tip_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  AssignmentTipId = 'assignment_tip_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assignment_tip" */
export type Assignment_Tip_Set_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Assignment_Tip_Stddev_Fields = {
  __typename?: 'assignment_tip_stddev_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assignment_tip" */
export type Assignment_Tip_Stddev_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assignment_Tip_Stddev_Pop_Fields = {
  __typename?: 'assignment_tip_stddev_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assignment_tip" */
export type Assignment_Tip_Stddev_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assignment_Tip_Stddev_Samp_Fields = {
  __typename?: 'assignment_tip_stddev_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assignment_tip" */
export type Assignment_Tip_Stddev_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Assignment_Tip_Sum_Fields = {
  __typename?: 'assignment_tip_sum_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  assignment_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "assignment_tip" */
export type Assignment_Tip_Sum_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "assignment_tip" */
export enum Assignment_Tip_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  AssignmentTipId = 'assignment_tip_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Assignment_Tip_Var_Pop_Fields = {
  __typename?: 'assignment_tip_var_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assignment_tip" */
export type Assignment_Tip_Var_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assignment_Tip_Var_Samp_Fields = {
  __typename?: 'assignment_tip_var_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assignment_tip" */
export type Assignment_Tip_Var_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Assignment_Tip_Variance_Fields = {
  __typename?: 'assignment_tip_variance_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  assignment_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assignment_tip" */
export type Assignment_Tip_Variance_Order_By = {
  assignment_id?: Maybe<Order_By>;
  assignment_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "assignment" */
export enum Assignment_Update_Column {
  /** column name */
  AllowExternalFiles = 'allow_external_files',
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  DueTime = 'due_time',
  /** column name */
  GradingSystemId = 'grading_system_id',
  /** column name */
  IncludeAllSongs = 'include_all_songs',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  ReferenceLessonPlanId = 'reference_lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Assignment_Var_Pop_Fields = {
  __typename?: 'assignment_var_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assignment" */
export type Assignment_Var_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assignment_Var_Samp_Fields = {
  __typename?: 'assignment_var_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assignment" */
export type Assignment_Var_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Assignment_Variance_Fields = {
  __typename?: 'assignment_variance_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  reference_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assignment" */
export type Assignment_Variance_Order_By = {
  assignment_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  reference_lesson_plan_id?: Maybe<Order_By>;
};

/** columns and relationships of "available" */
export type Available = {
  __typename?: 'available';
  /** An object relationship */
  availableLessonPlan?: Maybe<Lesson_Plan>;
  available_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "available" */
export type Available_Aggregate = {
  __typename?: 'available_aggregate';
  aggregate?: Maybe<Available_Aggregate_Fields>;
  nodes: Array<Available>;
};

/** aggregate fields of "available" */
export type Available_Aggregate_Fields = {
  __typename?: 'available_aggregate_fields';
  avg?: Maybe<Available_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Available_Max_Fields>;
  min?: Maybe<Available_Min_Fields>;
  stddev?: Maybe<Available_Stddev_Fields>;
  stddev_pop?: Maybe<Available_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Available_Stddev_Samp_Fields>;
  sum?: Maybe<Available_Sum_Fields>;
  var_pop?: Maybe<Available_Var_Pop_Fields>;
  var_samp?: Maybe<Available_Var_Samp_Fields>;
  variance?: Maybe<Available_Variance_Fields>;
};


/** aggregate fields of "available" */
export type Available_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Available_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Available_Avg_Fields = {
  __typename?: 'available_avg_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "available". All fields are combined with a logical 'AND'. */
export type Available_Bool_Exp = {
  _and?: Maybe<Array<Available_Bool_Exp>>;
  _not?: Maybe<Available_Bool_Exp>;
  _or?: Maybe<Array<Available_Bool_Exp>>;
  availableLessonPlan?: Maybe<Lesson_Plan_Bool_Exp>;
  available_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "available" */
export enum Available_Constraint {
  /** unique or primary key constraint */
  AvailablePkey = 'available_pkey'
}

/** input type for incrementing numeric columns in table "available" */
export type Available_Inc_Input = {
  available_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "available" */
export type Available_Insert_Input = {
  availableLessonPlan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Available_Max_Fields = {
  __typename?: 'available_max_fields';
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Available_Min_Fields = {
  __typename?: 'available_min_fields';
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "available" */
export type Available_Mutation_Response = {
  __typename?: 'available_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Available>;
};

/** on_conflict condition type for table "available" */
export type Available_On_Conflict = {
  constraint: Available_Constraint;
  update_columns?: Array<Available_Update_Column>;
  where?: Maybe<Available_Bool_Exp>;
};

/** Ordering options when selecting data from "available". */
export type Available_Order_By = {
  availableLessonPlan?: Maybe<Lesson_Plan_Order_By>;
  available_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: available */
export type Available_Pk_Columns_Input = {
  available_id: Scalars['Int'];
};

/** select columns of table "available" */
export enum Available_Select_Column {
  /** column name */
  AvailableId = 'available_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "available" */
export type Available_Set_Input = {
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Available_Stddev_Fields = {
  __typename?: 'available_stddev_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Available_Stddev_Pop_Fields = {
  __typename?: 'available_stddev_pop_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Available_Stddev_Samp_Fields = {
  __typename?: 'available_stddev_samp_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Available_Sum_Fields = {
  __typename?: 'available_sum_fields';
  available_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "available" */
export enum Available_Update_Column {
  /** column name */
  AvailableId = 'available_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Available_Var_Pop_Fields = {
  __typename?: 'available_var_pop_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Available_Var_Samp_Fields = {
  __typename?: 'available_var_samp_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Available_Variance_Fields = {
  __typename?: 'available_variance_fields';
  available_id?: Maybe<Scalars['Float']>;
};

/** this table relates badge_id and the events that awards that badge */
export type Awarded_Badge_Event = {
  __typename?: 'awarded_badge_event';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  student_badge: Student_Badge;
  /** An object relationship */
  student_badge_event: Student_Badge_Event;
  student_badge_event_id: Scalars['Int'];
  student_badge_id: Scalars['Int'];
};

/** aggregated selection of "awarded_badge_event" */
export type Awarded_Badge_Event_Aggregate = {
  __typename?: 'awarded_badge_event_aggregate';
  aggregate?: Maybe<Awarded_Badge_Event_Aggregate_Fields>;
  nodes: Array<Awarded_Badge_Event>;
};

/** aggregate fields of "awarded_badge_event" */
export type Awarded_Badge_Event_Aggregate_Fields = {
  __typename?: 'awarded_badge_event_aggregate_fields';
  avg?: Maybe<Awarded_Badge_Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Awarded_Badge_Event_Max_Fields>;
  min?: Maybe<Awarded_Badge_Event_Min_Fields>;
  stddev?: Maybe<Awarded_Badge_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Awarded_Badge_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Awarded_Badge_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Awarded_Badge_Event_Sum_Fields>;
  var_pop?: Maybe<Awarded_Badge_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Awarded_Badge_Event_Var_Samp_Fields>;
  variance?: Maybe<Awarded_Badge_Event_Variance_Fields>;
};


/** aggregate fields of "awarded_badge_event" */
export type Awarded_Badge_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "awarded_badge_event" */
export type Awarded_Badge_Event_Aggregate_Order_By = {
  avg?: Maybe<Awarded_Badge_Event_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Awarded_Badge_Event_Max_Order_By>;
  min?: Maybe<Awarded_Badge_Event_Min_Order_By>;
  stddev?: Maybe<Awarded_Badge_Event_Stddev_Order_By>;
  stddev_pop?: Maybe<Awarded_Badge_Event_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Awarded_Badge_Event_Stddev_Samp_Order_By>;
  sum?: Maybe<Awarded_Badge_Event_Sum_Order_By>;
  var_pop?: Maybe<Awarded_Badge_Event_Var_Pop_Order_By>;
  var_samp?: Maybe<Awarded_Badge_Event_Var_Samp_Order_By>;
  variance?: Maybe<Awarded_Badge_Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "awarded_badge_event" */
export type Awarded_Badge_Event_Arr_Rel_Insert_Input = {
  data: Array<Awarded_Badge_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Awarded_Badge_Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Awarded_Badge_Event_Avg_Fields = {
  __typename?: 'awarded_badge_event_avg_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Avg_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "awarded_badge_event". All fields are combined with a logical 'AND'. */
export type Awarded_Badge_Event_Bool_Exp = {
  _and?: Maybe<Array<Awarded_Badge_Event_Bool_Exp>>;
  _not?: Maybe<Awarded_Badge_Event_Bool_Exp>;
  _or?: Maybe<Array<Awarded_Badge_Event_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  student_badge?: Maybe<Student_Badge_Bool_Exp>;
  student_badge_event?: Maybe<Student_Badge_Event_Bool_Exp>;
  student_badge_event_id?: Maybe<Int_Comparison_Exp>;
  student_badge_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "awarded_badge_event" */
export enum Awarded_Badge_Event_Constraint {
  /** unique or primary key constraint */
  AwardedBadgeEventPkey = 'awarded_badge_event_pkey'
}

/** input type for incrementing numeric columns in table "awarded_badge_event" */
export type Awarded_Badge_Event_Inc_Input = {
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "awarded_badge_event" */
export type Awarded_Badge_Event_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge?: Maybe<Student_Badge_Obj_Rel_Insert_Input>;
  student_badge_event?: Maybe<Student_Badge_Event_Obj_Rel_Insert_Input>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Awarded_Badge_Event_Max_Fields = {
  __typename?: 'awarded_badge_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Awarded_Badge_Event_Min_Fields = {
  __typename?: 'awarded_badge_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "awarded_badge_event" */
export type Awarded_Badge_Event_Mutation_Response = {
  __typename?: 'awarded_badge_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Awarded_Badge_Event>;
};

/** on_conflict condition type for table "awarded_badge_event" */
export type Awarded_Badge_Event_On_Conflict = {
  constraint: Awarded_Badge_Event_Constraint;
  update_columns?: Array<Awarded_Badge_Event_Update_Column>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "awarded_badge_event". */
export type Awarded_Badge_Event_Order_By = {
  created_at?: Maybe<Order_By>;
  student_badge?: Maybe<Student_Badge_Order_By>;
  student_badge_event?: Maybe<Student_Badge_Event_Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** primary key columns input for table: awarded_badge_event */
export type Awarded_Badge_Event_Pk_Columns_Input = {
  student_badge_event_id: Scalars['Int'];
  student_badge_id: Scalars['Int'];
};

/** select columns of table "awarded_badge_event" */
export enum Awarded_Badge_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentBadgeEventId = 'student_badge_event_id',
  /** column name */
  StudentBadgeId = 'student_badge_id'
}

/** input type for updating data in table "awarded_badge_event" */
export type Awarded_Badge_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Awarded_Badge_Event_Stddev_Fields = {
  __typename?: 'awarded_badge_event_stddev_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Stddev_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Awarded_Badge_Event_Stddev_Pop_Fields = {
  __typename?: 'awarded_badge_event_stddev_pop_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Stddev_Pop_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Awarded_Badge_Event_Stddev_Samp_Fields = {
  __typename?: 'awarded_badge_event_stddev_samp_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Stddev_Samp_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Awarded_Badge_Event_Sum_Fields = {
  __typename?: 'awarded_badge_event_sum_fields';
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Sum_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** update columns of table "awarded_badge_event" */
export enum Awarded_Badge_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentBadgeEventId = 'student_badge_event_id',
  /** column name */
  StudentBadgeId = 'student_badge_id'
}

/** aggregate var_pop on columns */
export type Awarded_Badge_Event_Var_Pop_Fields = {
  __typename?: 'awarded_badge_event_var_pop_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Var_Pop_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Awarded_Badge_Event_Var_Samp_Fields = {
  __typename?: 'awarded_badge_event_var_samp_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Var_Samp_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Awarded_Badge_Event_Variance_Fields = {
  __typename?: 'awarded_badge_event_variance_fields';
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "awarded_badge_event" */
export type Awarded_Badge_Event_Variance_Order_By = {
  student_badge_event_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
};

/** columns and relationships of "badge" */
export type Badge = {
  __typename?: 'badge';
  badge_id: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  icon: Scalars['String'];
  icon_awarded?: Maybe<Scalars['String']>;
  icon_empty?: Maybe<Scalars['String']>;
  icon_plain?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  points: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "badge" */
export type Badge_Aggregate = {
  __typename?: 'badge_aggregate';
  aggregate?: Maybe<Badge_Aggregate_Fields>;
  nodes: Array<Badge>;
};

/** aggregate fields of "badge" */
export type Badge_Aggregate_Fields = {
  __typename?: 'badge_aggregate_fields';
  avg?: Maybe<Badge_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Badge_Max_Fields>;
  min?: Maybe<Badge_Min_Fields>;
  stddev?: Maybe<Badge_Stddev_Fields>;
  stddev_pop?: Maybe<Badge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Badge_Stddev_Samp_Fields>;
  sum?: Maybe<Badge_Sum_Fields>;
  var_pop?: Maybe<Badge_Var_Pop_Fields>;
  var_samp?: Maybe<Badge_Var_Samp_Fields>;
  variance?: Maybe<Badge_Variance_Fields>;
};


/** aggregate fields of "badge" */
export type Badge_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Badge_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Badge_Avg_Fields = {
  __typename?: 'badge_avg_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "badge". All fields are combined with a logical 'AND'. */
export type Badge_Bool_Exp = {
  _and?: Maybe<Array<Badge_Bool_Exp>>;
  _not?: Maybe<Badge_Bool_Exp>;
  _or?: Maybe<Array<Badge_Bool_Exp>>;
  badge_id?: Maybe<Int_Comparison_Exp>;
  color?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  icon_awarded?: Maybe<String_Comparison_Exp>;
  icon_empty?: Maybe<String_Comparison_Exp>;
  icon_plain?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  points?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "badge" */
export enum Badge_Constraint {
  /** unique or primary key constraint */
  BadgePkey = 'badge_pkey'
}

/** columns and relationships of "badge_event" */
export type Badge_Event = {
  __typename?: 'badge_event';
  badge_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  event_name: Badge_Event_Name_Enum;
  required_times_to_be_awarded: Scalars['Int'];
};

/** aggregated selection of "badge_event" */
export type Badge_Event_Aggregate = {
  __typename?: 'badge_event_aggregate';
  aggregate?: Maybe<Badge_Event_Aggregate_Fields>;
  nodes: Array<Badge_Event>;
};

/** aggregate fields of "badge_event" */
export type Badge_Event_Aggregate_Fields = {
  __typename?: 'badge_event_aggregate_fields';
  avg?: Maybe<Badge_Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Badge_Event_Max_Fields>;
  min?: Maybe<Badge_Event_Min_Fields>;
  stddev?: Maybe<Badge_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Badge_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Badge_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Badge_Event_Sum_Fields>;
  var_pop?: Maybe<Badge_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Badge_Event_Var_Samp_Fields>;
  variance?: Maybe<Badge_Event_Variance_Fields>;
};


/** aggregate fields of "badge_event" */
export type Badge_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Badge_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Badge_Event_Avg_Fields = {
  __typename?: 'badge_event_avg_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "badge_event". All fields are combined with a logical 'AND'. */
export type Badge_Event_Bool_Exp = {
  _and?: Maybe<Array<Badge_Event_Bool_Exp>>;
  _not?: Maybe<Badge_Event_Bool_Exp>;
  _or?: Maybe<Array<Badge_Event_Bool_Exp>>;
  badge_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  event_name?: Maybe<Badge_Event_Name_Enum_Comparison_Exp>;
  required_times_to_be_awarded?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "badge_event" */
export enum Badge_Event_Constraint {
  /** unique or primary key constraint */
  BadgeEventPkey = 'badge_event_pkey'
}

/** input type for incrementing numeric columns in table "badge_event" */
export type Badge_Event_Inc_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  required_times_to_be_awarded?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "badge_event" */
export type Badge_Event_Insert_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event_name?: Maybe<Badge_Event_Name_Enum>;
  required_times_to_be_awarded?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Badge_Event_Max_Fields = {
  __typename?: 'badge_event_max_fields';
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  required_times_to_be_awarded?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Badge_Event_Min_Fields = {
  __typename?: 'badge_event_min_fields';
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  required_times_to_be_awarded?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "badge_event" */
export type Badge_Event_Mutation_Response = {
  __typename?: 'badge_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Badge_Event>;
};

/** columns and relationships of "badge_event_name" */
export type Badge_Event_Name = {
  __typename?: 'badge_event_name';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "badge_event_name" */
export type Badge_Event_Name_Aggregate = {
  __typename?: 'badge_event_name_aggregate';
  aggregate?: Maybe<Badge_Event_Name_Aggregate_Fields>;
  nodes: Array<Badge_Event_Name>;
};

/** aggregate fields of "badge_event_name" */
export type Badge_Event_Name_Aggregate_Fields = {
  __typename?: 'badge_event_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Badge_Event_Name_Max_Fields>;
  min?: Maybe<Badge_Event_Name_Min_Fields>;
};


/** aggregate fields of "badge_event_name" */
export type Badge_Event_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Badge_Event_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "badge_event_name". All fields are combined with a logical 'AND'. */
export type Badge_Event_Name_Bool_Exp = {
  _and?: Maybe<Array<Badge_Event_Name_Bool_Exp>>;
  _not?: Maybe<Badge_Event_Name_Bool_Exp>;
  _or?: Maybe<Array<Badge_Event_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "badge_event_name" */
export enum Badge_Event_Name_Constraint {
  /** unique or primary key constraint */
  BagdeEventNamePkey = 'bagde_event_name_pkey'
}

export enum Badge_Event_Name_Enum {
  /** Completed Weekly Challenge */
  ChallengeCompletion = 'challenge_completion',
  /** Student Login */
  Login = 'login',
  /** Complete Practice Goal */
  PracticeGoal = 'practice_goal',
  /** Successfully Saved a Recording */
  SavedRecording = 'saved_recording',
  /** Streamed a Song */
  Streaming = 'streaming',
  /** Unsaved Practice Sessions */
  UnsavedPracticeSessions = 'unsaved_practice_sessions'
}

/** Boolean expression to compare columns of type "badge_event_name_enum". All fields are combined with logical 'AND'. */
export type Badge_Event_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Badge_Event_Name_Enum>;
  _in?: Maybe<Array<Badge_Event_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Badge_Event_Name_Enum>;
  _nin?: Maybe<Array<Badge_Event_Name_Enum>>;
};

/** input type for inserting data into table "badge_event_name" */
export type Badge_Event_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Badge_Event_Name_Max_Fields = {
  __typename?: 'badge_event_name_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Badge_Event_Name_Min_Fields = {
  __typename?: 'badge_event_name_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "badge_event_name" */
export type Badge_Event_Name_Mutation_Response = {
  __typename?: 'badge_event_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Badge_Event_Name>;
};

/** input type for inserting object relation for remote table "badge_event_name" */
export type Badge_Event_Name_Obj_Rel_Insert_Input = {
  data: Badge_Event_Name_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Badge_Event_Name_On_Conflict>;
};

/** on_conflict condition type for table "badge_event_name" */
export type Badge_Event_Name_On_Conflict = {
  constraint: Badge_Event_Name_Constraint;
  update_columns?: Array<Badge_Event_Name_Update_Column>;
  where?: Maybe<Badge_Event_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "badge_event_name". */
export type Badge_Event_Name_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: badge_event_name */
export type Badge_Event_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "badge_event_name" */
export enum Badge_Event_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "badge_event_name" */
export type Badge_Event_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "badge_event_name" */
export enum Badge_Event_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** on_conflict condition type for table "badge_event" */
export type Badge_Event_On_Conflict = {
  constraint: Badge_Event_Constraint;
  update_columns?: Array<Badge_Event_Update_Column>;
  where?: Maybe<Badge_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "badge_event". */
export type Badge_Event_Order_By = {
  badge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  event_name?: Maybe<Order_By>;
  required_times_to_be_awarded?: Maybe<Order_By>;
};

/** primary key columns input for table: badge_event */
export type Badge_Event_Pk_Columns_Input = {
  badge_id: Scalars['Int'];
  event_name: Badge_Event_Name_Enum;
};

/** select columns of table "badge_event" */
export enum Badge_Event_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventName = 'event_name',
  /** column name */
  RequiredTimesToBeAwarded = 'required_times_to_be_awarded'
}

/** input type for updating data in table "badge_event" */
export type Badge_Event_Set_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event_name?: Maybe<Badge_Event_Name_Enum>;
  required_times_to_be_awarded?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Badge_Event_Stddev_Fields = {
  __typename?: 'badge_event_stddev_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Badge_Event_Stddev_Pop_Fields = {
  __typename?: 'badge_event_stddev_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Badge_Event_Stddev_Samp_Fields = {
  __typename?: 'badge_event_stddev_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Badge_Event_Sum_Fields = {
  __typename?: 'badge_event_sum_fields';
  badge_id?: Maybe<Scalars['Int']>;
  required_times_to_be_awarded?: Maybe<Scalars['Int']>;
};

/** update columns of table "badge_event" */
export enum Badge_Event_Update_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventName = 'event_name',
  /** column name */
  RequiredTimesToBeAwarded = 'required_times_to_be_awarded'
}

/** aggregate var_pop on columns */
export type Badge_Event_Var_Pop_Fields = {
  __typename?: 'badge_event_var_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Badge_Event_Var_Samp_Fields = {
  __typename?: 'badge_event_var_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Badge_Event_Variance_Fields = {
  __typename?: 'badge_event_variance_fields';
  badge_id?: Maybe<Scalars['Float']>;
  required_times_to_be_awarded?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "badge" */
export type Badge_Inc_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "badge" */
export type Badge_Insert_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_awarded?: Maybe<Scalars['String']>;
  icon_empty?: Maybe<Scalars['String']>;
  icon_plain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Badge_Max_Fields = {
  __typename?: 'badge_max_fields';
  badge_id?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_awarded?: Maybe<Scalars['String']>;
  icon_empty?: Maybe<Scalars['String']>;
  icon_plain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Badge_Min_Fields = {
  __typename?: 'badge_min_fields';
  badge_id?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_awarded?: Maybe<Scalars['String']>;
  icon_empty?: Maybe<Scalars['String']>;
  icon_plain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "badge" */
export type Badge_Mutation_Response = {
  __typename?: 'badge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Badge>;
};

/** input type for inserting object relation for remote table "badge" */
export type Badge_Obj_Rel_Insert_Input = {
  data: Badge_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Badge_On_Conflict>;
};

/** on_conflict condition type for table "badge" */
export type Badge_On_Conflict = {
  constraint: Badge_Constraint;
  update_columns?: Array<Badge_Update_Column>;
  where?: Maybe<Badge_Bool_Exp>;
};

/** Ordering options when selecting data from "badge". */
export type Badge_Order_By = {
  badge_id?: Maybe<Order_By>;
  color?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  icon_awarded?: Maybe<Order_By>;
  icon_empty?: Maybe<Order_By>;
  icon_plain?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: badge */
export type Badge_Pk_Columns_Input = {
  badge_id: Scalars['Int'];
};

/** select columns of table "badge" */
export enum Badge_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  IconAwarded = 'icon_awarded',
  /** column name */
  IconEmpty = 'icon_empty',
  /** column name */
  IconPlain = 'icon_plain',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "badge" */
export type Badge_Set_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  icon_awarded?: Maybe<Scalars['String']>;
  icon_empty?: Maybe<Scalars['String']>;
  icon_plain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Badge_Stddev_Fields = {
  __typename?: 'badge_stddev_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Badge_Stddev_Pop_Fields = {
  __typename?: 'badge_stddev_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Badge_Stddev_Samp_Fields = {
  __typename?: 'badge_stddev_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Badge_Sum_Fields = {
  __typename?: 'badge_sum_fields';
  badge_id?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

/** update columns of table "badge" */
export enum Badge_Update_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Icon = 'icon',
  /** column name */
  IconAwarded = 'icon_awarded',
  /** column name */
  IconEmpty = 'icon_empty',
  /** column name */
  IconPlain = 'icon_plain',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Badge_Var_Pop_Fields = {
  __typename?: 'badge_var_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Badge_Var_Samp_Fields = {
  __typename?: 'badge_var_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Badge_Variance_Fields = {
  __typename?: 'badge_variance_fields';
  badge_id?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** user billing tracking history */
export type Billing_History = {
  __typename?: 'billing_history';
  amount: Scalars['Int'];
  billing_history_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  status: Billing_Status_Enum;
  stripe_billing_reason?: Maybe<Scalars['String']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_paymente_intent_id?: Maybe<Scalars['String']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_promotion_code?: Maybe<Scalars['String']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "billing_history" */
export type Billing_History_Aggregate = {
  __typename?: 'billing_history_aggregate';
  aggregate?: Maybe<Billing_History_Aggregate_Fields>;
  nodes: Array<Billing_History>;
};

/** aggregate fields of "billing_history" */
export type Billing_History_Aggregate_Fields = {
  __typename?: 'billing_history_aggregate_fields';
  avg?: Maybe<Billing_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_History_Max_Fields>;
  min?: Maybe<Billing_History_Min_Fields>;
  stddev?: Maybe<Billing_History_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_History_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_History_Sum_Fields>;
  var_pop?: Maybe<Billing_History_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_History_Var_Samp_Fields>;
  variance?: Maybe<Billing_History_Variance_Fields>;
};


/** aggregate fields of "billing_history" */
export type Billing_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Billing_History_Avg_Fields = {
  __typename?: 'billing_history_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "billing_history". All fields are combined with a logical 'AND'. */
export type Billing_History_Bool_Exp = {
  _and?: Maybe<Array<Billing_History_Bool_Exp>>;
  _not?: Maybe<Billing_History_Bool_Exp>;
  _or?: Maybe<Array<Billing_History_Bool_Exp>>;
  amount?: Maybe<Int_Comparison_Exp>;
  billing_history_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Billing_Status_Enum_Comparison_Exp>;
  stripe_billing_reason?: Maybe<String_Comparison_Exp>;
  stripe_charge_id?: Maybe<String_Comparison_Exp>;
  stripe_coupon_name?: Maybe<String_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  stripe_discount_amount?: Maybe<Int_Comparison_Exp>;
  stripe_invoice_id?: Maybe<String_Comparison_Exp>;
  stripe_paymente_intent_id?: Maybe<String_Comparison_Exp>;
  stripe_price_id?: Maybe<String_Comparison_Exp>;
  stripe_promotion_code?: Maybe<String_Comparison_Exp>;
  stripe_promotion_code_id?: Maybe<String_Comparison_Exp>;
  stripe_subscription_id?: Maybe<String_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing_history" */
export enum Billing_History_Constraint {
  /** unique or primary key constraint */
  BillingHistoryPkey = 'billing_history_pkey'
}

/** input type for incrementing numeric columns in table "billing_history" */
export type Billing_History_Inc_Input = {
  amount?: Maybe<Scalars['Int']>;
  billing_history_id?: Maybe<Scalars['Int']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "billing_history" */
export type Billing_History_Insert_Input = {
  amount?: Maybe<Scalars['Int']>;
  billing_history_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Billing_Status_Enum>;
  stripe_billing_reason?: Maybe<Scalars['String']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_paymente_intent_id?: Maybe<Scalars['String']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_promotion_code?: Maybe<Scalars['String']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_History_Max_Fields = {
  __typename?: 'billing_history_max_fields';
  amount?: Maybe<Scalars['Int']>;
  billing_history_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  stripe_billing_reason?: Maybe<Scalars['String']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_paymente_intent_id?: Maybe<Scalars['String']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_promotion_code?: Maybe<Scalars['String']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Billing_History_Min_Fields = {
  __typename?: 'billing_history_min_fields';
  amount?: Maybe<Scalars['Int']>;
  billing_history_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  stripe_billing_reason?: Maybe<Scalars['String']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_paymente_intent_id?: Maybe<Scalars['String']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_promotion_code?: Maybe<Scalars['String']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "billing_history" */
export type Billing_History_Mutation_Response = {
  __typename?: 'billing_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_History>;
};

/** on_conflict condition type for table "billing_history" */
export type Billing_History_On_Conflict = {
  constraint: Billing_History_Constraint;
  update_columns?: Array<Billing_History_Update_Column>;
  where?: Maybe<Billing_History_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_history". */
export type Billing_History_Order_By = {
  amount?: Maybe<Order_By>;
  billing_history_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripe_billing_reason?: Maybe<Order_By>;
  stripe_charge_id?: Maybe<Order_By>;
  stripe_coupon_name?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  stripe_invoice_id?: Maybe<Order_By>;
  stripe_paymente_intent_id?: Maybe<Order_By>;
  stripe_price_id?: Maybe<Order_By>;
  stripe_promotion_code?: Maybe<Order_By>;
  stripe_promotion_code_id?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: billing_history */
export type Billing_History_Pk_Columns_Input = {
  billing_history_id: Scalars['Int'];
};

/** select columns of table "billing_history" */
export enum Billing_History_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BillingHistoryId = 'billing_history_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status',
  /** column name */
  StripeBillingReason = 'stripe_billing_reason',
  /** column name */
  StripeChargeId = 'stripe_charge_id',
  /** column name */
  StripeCouponName = 'stripe_coupon_name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  StripeDiscountAmount = 'stripe_discount_amount',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  StripePaymenteIntentId = 'stripe_paymente_intent_id',
  /** column name */
  StripePriceId = 'stripe_price_id',
  /** column name */
  StripePromotionCode = 'stripe_promotion_code',
  /** column name */
  StripePromotionCodeId = 'stripe_promotion_code_id',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "billing_history" */
export type Billing_History_Set_Input = {
  amount?: Maybe<Scalars['Int']>;
  billing_history_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Billing_Status_Enum>;
  stripe_billing_reason?: Maybe<Scalars['String']>;
  stripe_charge_id?: Maybe<Scalars['String']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_paymente_intent_id?: Maybe<Scalars['String']>;
  stripe_price_id?: Maybe<Scalars['String']>;
  stripe_promotion_code?: Maybe<Scalars['String']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_History_Stddev_Fields = {
  __typename?: 'billing_history_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Billing_History_Stddev_Pop_Fields = {
  __typename?: 'billing_history_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Billing_History_Stddev_Samp_Fields = {
  __typename?: 'billing_history_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Billing_History_Sum_Fields = {
  __typename?: 'billing_history_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  billing_history_id?: Maybe<Scalars['Int']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "billing_history" */
export enum Billing_History_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BillingHistoryId = 'billing_history_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status',
  /** column name */
  StripeBillingReason = 'stripe_billing_reason',
  /** column name */
  StripeChargeId = 'stripe_charge_id',
  /** column name */
  StripeCouponName = 'stripe_coupon_name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  StripeDiscountAmount = 'stripe_discount_amount',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  StripePaymenteIntentId = 'stripe_paymente_intent_id',
  /** column name */
  StripePriceId = 'stripe_price_id',
  /** column name */
  StripePromotionCode = 'stripe_promotion_code',
  /** column name */
  StripePromotionCodeId = 'stripe_promotion_code_id',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_History_Var_Pop_Fields = {
  __typename?: 'billing_history_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Billing_History_Var_Samp_Fields = {
  __typename?: 'billing_history_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Billing_History_Variance_Fields = {
  __typename?: 'billing_history_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  billing_history_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "billing_method" */
export type Billing_Method = {
  __typename?: 'billing_method';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "billing_method" */
export type Billing_Method_Aggregate = {
  __typename?: 'billing_method_aggregate';
  aggregate?: Maybe<Billing_Method_Aggregate_Fields>;
  nodes: Array<Billing_Method>;
};

/** aggregate fields of "billing_method" */
export type Billing_Method_Aggregate_Fields = {
  __typename?: 'billing_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Billing_Method_Max_Fields>;
  min?: Maybe<Billing_Method_Min_Fields>;
};


/** aggregate fields of "billing_method" */
export type Billing_Method_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Method_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "billing_method". All fields are combined with a logical 'AND'. */
export type Billing_Method_Bool_Exp = {
  _and?: Maybe<Array<Billing_Method_Bool_Exp>>;
  _not?: Maybe<Billing_Method_Bool_Exp>;
  _or?: Maybe<Array<Billing_Method_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing_method" */
export enum Billing_Method_Constraint {
  /** unique or primary key constraint */
  BillingMethodPkey = 'billing_method_pkey'
}

export enum Billing_Method_Enum {
  /** annual payment */
  Annual = 'annual',
  /** monthly payment */
  Monthly = 'monthly'
}

/** Boolean expression to compare columns of type "billing_method_enum". All fields are combined with logical 'AND'. */
export type Billing_Method_Enum_Comparison_Exp = {
  _eq?: Maybe<Billing_Method_Enum>;
  _in?: Maybe<Array<Billing_Method_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Billing_Method_Enum>;
  _nin?: Maybe<Array<Billing_Method_Enum>>;
};

/** input type for inserting data into table "billing_method" */
export type Billing_Method_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Billing_Method_Max_Fields = {
  __typename?: 'billing_method_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Billing_Method_Min_Fields = {
  __typename?: 'billing_method_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "billing_method" */
export type Billing_Method_Mutation_Response = {
  __typename?: 'billing_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Method>;
};

/** on_conflict condition type for table "billing_method" */
export type Billing_Method_On_Conflict = {
  constraint: Billing_Method_Constraint;
  update_columns?: Array<Billing_Method_Update_Column>;
  where?: Maybe<Billing_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_method". */
export type Billing_Method_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: billing_method */
export type Billing_Method_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "billing_method" */
export enum Billing_Method_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "billing_method" */
export type Billing_Method_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "billing_method" */
export enum Billing_Method_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "billing_status" */
export type Billing_Status = {
  __typename?: 'billing_status';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "billing_status" */
export type Billing_Status_Aggregate = {
  __typename?: 'billing_status_aggregate';
  aggregate?: Maybe<Billing_Status_Aggregate_Fields>;
  nodes: Array<Billing_Status>;
};

/** aggregate fields of "billing_status" */
export type Billing_Status_Aggregate_Fields = {
  __typename?: 'billing_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Billing_Status_Max_Fields>;
  min?: Maybe<Billing_Status_Min_Fields>;
};


/** aggregate fields of "billing_status" */
export type Billing_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "billing_status". All fields are combined with a logical 'AND'. */
export type Billing_Status_Bool_Exp = {
  _and?: Maybe<Array<Billing_Status_Bool_Exp>>;
  _not?: Maybe<Billing_Status_Bool_Exp>;
  _or?: Maybe<Array<Billing_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing_status" */
export enum Billing_Status_Constraint {
  /** unique or primary key constraint */
  BillingStatusPkey = 'billing_status_pkey'
}

export enum Billing_Status_Enum {
  /** failed invoice */
  Failure = 'failure',
  /** successful invoice */
  Success = 'success'
}

/** Boolean expression to compare columns of type "billing_status_enum". All fields are combined with logical 'AND'. */
export type Billing_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Billing_Status_Enum>;
  _in?: Maybe<Array<Billing_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Billing_Status_Enum>;
  _nin?: Maybe<Array<Billing_Status_Enum>>;
};

/** input type for inserting data into table "billing_status" */
export type Billing_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Billing_Status_Max_Fields = {
  __typename?: 'billing_status_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Billing_Status_Min_Fields = {
  __typename?: 'billing_status_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "billing_status" */
export type Billing_Status_Mutation_Response = {
  __typename?: 'billing_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Status>;
};

/** on_conflict condition type for table "billing_status" */
export type Billing_Status_On_Conflict = {
  constraint: Billing_Status_Constraint;
  update_columns?: Array<Billing_Status_Update_Column>;
  where?: Maybe<Billing_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_status". */
export type Billing_Status_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: billing_status */
export type Billing_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "billing_status" */
export enum Billing_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "billing_status" */
export type Billing_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "billing_status" */
export enum Billing_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}


/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: Maybe<Scalars['bytea']>;
  _gt?: Maybe<Scalars['bytea']>;
  _gte?: Maybe<Scalars['bytea']>;
  _in?: Maybe<Array<Scalars['bytea']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bytea']>;
  _lte?: Maybe<Scalars['bytea']>;
  _neq?: Maybe<Scalars['bytea']>;
  _nin?: Maybe<Array<Scalars['bytea']>>;
};

export type CancelProductSubscriptionData = {
  __typename?: 'cancelProductSubscriptionData';
  success: Scalars['Boolean'];
};

export type CancelProductSubscriptionSet = {
  stripeSubcriptiptionId: Scalars['String'];
  teacherId: Scalars['Int'];
};

/** columns and relationships of "catalog" */
export type Catalog = {
  __typename?: 'catalog';
  catalog_id: Scalars['Int'];
  /** An array relationship */
  catalog_items: Array<Catalog_Item>;
  /** An aggregate relationship */
  catalog_items_aggregate: Catalog_Item_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name: Catalog_Name_Enum;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "catalog" */
export type CatalogCatalog_ItemsArgs = {
  distinct_on?: Maybe<Array<Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Item_Order_By>>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "catalog" */
export type CatalogCatalog_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Item_Order_By>>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};

/** aggregated selection of "catalog" */
export type Catalog_Aggregate = {
  __typename?: 'catalog_aggregate';
  aggregate?: Maybe<Catalog_Aggregate_Fields>;
  nodes: Array<Catalog>;
};

/** aggregate fields of "catalog" */
export type Catalog_Aggregate_Fields = {
  __typename?: 'catalog_aggregate_fields';
  avg?: Maybe<Catalog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Catalog_Max_Fields>;
  min?: Maybe<Catalog_Min_Fields>;
  stddev?: Maybe<Catalog_Stddev_Fields>;
  stddev_pop?: Maybe<Catalog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Catalog_Stddev_Samp_Fields>;
  sum?: Maybe<Catalog_Sum_Fields>;
  var_pop?: Maybe<Catalog_Var_Pop_Fields>;
  var_samp?: Maybe<Catalog_Var_Samp_Fields>;
  variance?: Maybe<Catalog_Variance_Fields>;
};


/** aggregate fields of "catalog" */
export type Catalog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Catalog_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Catalog_Avg_Fields = {
  __typename?: 'catalog_avg_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "catalog". All fields are combined with a logical 'AND'. */
export type Catalog_Bool_Exp = {
  _and?: Maybe<Array<Catalog_Bool_Exp>>;
  _not?: Maybe<Catalog_Bool_Exp>;
  _or?: Maybe<Array<Catalog_Bool_Exp>>;
  catalog_id?: Maybe<Int_Comparison_Exp>;
  catalog_items?: Maybe<Catalog_Item_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan_order?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<Catalog_Name_Enum_Comparison_Exp>;
  song_order?: Maybe<Int_Comparison_Exp>;
  tip_order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "catalog" */
export enum Catalog_Constraint {
  /** unique or primary key constraint */
  CatalogPkey = 'catalog_pkey'
}

/** input type for incrementing numeric columns in table "catalog" */
export type Catalog_Inc_Input = {
  catalog_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "catalog" */
export type Catalog_Insert_Input = {
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_items?: Maybe<Catalog_Item_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Catalog_Name_Enum>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "catalog_item" */
export type Catalog_Item = {
  __typename?: 'catalog_item';
  active: Scalars['Boolean'];
  /** An object relationship */
  catalog: Catalog;
  catalog_id: Scalars['Int'];
  catalog_item_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "catalog_item" */
export type Catalog_Item_Aggregate = {
  __typename?: 'catalog_item_aggregate';
  aggregate?: Maybe<Catalog_Item_Aggregate_Fields>;
  nodes: Array<Catalog_Item>;
};

/** aggregate fields of "catalog_item" */
export type Catalog_Item_Aggregate_Fields = {
  __typename?: 'catalog_item_aggregate_fields';
  avg?: Maybe<Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Catalog_Item_Max_Fields>;
  min?: Maybe<Catalog_Item_Min_Fields>;
  stddev?: Maybe<Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "catalog_item" */
export type Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "catalog_item" */
export type Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Catalog_Item_Max_Order_By>;
  min?: Maybe<Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "catalog_item" */
export type Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Catalog_Item_Avg_Fields = {
  __typename?: 'catalog_item_avg_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "catalog_item" */
export type Catalog_Item_Avg_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "catalog_item". All fields are combined with a logical 'AND'. */
export type Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Catalog_Item_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  catalog?: Maybe<Catalog_Bool_Exp>;
  catalog_id?: Maybe<Int_Comparison_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  icon_path?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "catalog_item" */
export enum Catalog_Item_Constraint {
  /** unique or primary key constraint */
  CatalogItemsPkey = 'catalog_items_pkey'
}

/** input type for incrementing numeric columns in table "catalog_item" */
export type Catalog_Item_Inc_Input = {
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "catalog_item" */
export type Catalog_Item_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  catalog?: Maybe<Catalog_Obj_Rel_Insert_Input>;
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Catalog_Item_Max_Fields = {
  __typename?: 'catalog_item_max_fields';
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "catalog_item" */
export type Catalog_Item_Max_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  icon_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Catalog_Item_Min_Fields = {
  __typename?: 'catalog_item_min_fields';
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "catalog_item" */
export type Catalog_Item_Min_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  icon_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "catalog_item" */
export type Catalog_Item_Mutation_Response = {
  __typename?: 'catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalog_Item>;
};

/** input type for inserting object relation for remote table "catalog_item" */
export type Catalog_Item_Obj_Rel_Insert_Input = {
  data: Catalog_Item_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Catalog_Item_On_Conflict>;
};

/** on_conflict condition type for table "catalog_item" */
export type Catalog_Item_On_Conflict = {
  constraint: Catalog_Item_Constraint;
  update_columns?: Array<Catalog_Item_Update_Column>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "catalog_item". */
export type Catalog_Item_Order_By = {
  active?: Maybe<Order_By>;
  catalog?: Maybe<Catalog_Order_By>;
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  icon_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: catalog_item */
export type Catalog_Item_Pk_Columns_Input = {
  catalog_item_id: Scalars['Int'];
};

/** select columns of table "catalog_item" */
export enum Catalog_Item_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IconPath = 'icon_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "catalog_item" */
export type Catalog_Item_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Catalog_Item_Stddev_Fields = {
  __typename?: 'catalog_item_stddev_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "catalog_item" */
export type Catalog_Item_Stddev_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'catalog_item_stddev_pop_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "catalog_item" */
export type Catalog_Item_Stddev_Pop_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'catalog_item_stddev_samp_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "catalog_item" */
export type Catalog_Item_Stddev_Samp_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Catalog_Item_Sum_Fields = {
  __typename?: 'catalog_item_sum_fields';
  catalog_id?: Maybe<Scalars['Int']>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "catalog_item" */
export type Catalog_Item_Sum_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "catalog_item" */
export enum Catalog_Item_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IconPath = 'icon_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Catalog_Item_Var_Pop_Fields = {
  __typename?: 'catalog_item_var_pop_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "catalog_item" */
export type Catalog_Item_Var_Pop_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Catalog_Item_Var_Samp_Fields = {
  __typename?: 'catalog_item_var_samp_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "catalog_item" */
export type Catalog_Item_Var_Samp_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Catalog_Item_Variance_Fields = {
  __typename?: 'catalog_item_variance_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  catalog_item_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "catalog_item" */
export type Catalog_Item_Variance_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Catalog_Max_Fields = {
  __typename?: 'catalog_max_fields';
  catalog_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Catalog_Min_Fields = {
  __typename?: 'catalog_min_fields';
  catalog_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "catalog" */
export type Catalog_Mutation_Response = {
  __typename?: 'catalog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalog>;
};

/** columns and relationships of "catalog_name" */
export type Catalog_Name = {
  __typename?: 'catalog_name';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "catalog_name" */
export type Catalog_Name_Aggregate = {
  __typename?: 'catalog_name_aggregate';
  aggregate?: Maybe<Catalog_Name_Aggregate_Fields>;
  nodes: Array<Catalog_Name>;
};

/** aggregate fields of "catalog_name" */
export type Catalog_Name_Aggregate_Fields = {
  __typename?: 'catalog_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Catalog_Name_Max_Fields>;
  min?: Maybe<Catalog_Name_Min_Fields>;
};


/** aggregate fields of "catalog_name" */
export type Catalog_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Catalog_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "catalog_name". All fields are combined with a logical 'AND'. */
export type Catalog_Name_Bool_Exp = {
  _and?: Maybe<Array<Catalog_Name_Bool_Exp>>;
  _not?: Maybe<Catalog_Name_Bool_Exp>;
  _or?: Maybe<Array<Catalog_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "catalog_name" */
export enum Catalog_Name_Constraint {
  /** unique or primary key constraint */
  CataloEnumPkey = 'catalo_enum_pkey'
}

export enum Catalog_Name_Enum {
  Ages = 'ages',
  Genres = 'genres',
  Instruments = 'instruments',
  Subjects = 'subjects',
  Themes = 'themes'
}

/** Boolean expression to compare columns of type "catalog_name_enum". All fields are combined with logical 'AND'. */
export type Catalog_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Catalog_Name_Enum>;
  _in?: Maybe<Array<Catalog_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Catalog_Name_Enum>;
  _nin?: Maybe<Array<Catalog_Name_Enum>>;
};

/** input type for inserting data into table "catalog_name" */
export type Catalog_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Catalog_Name_Max_Fields = {
  __typename?: 'catalog_name_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Catalog_Name_Min_Fields = {
  __typename?: 'catalog_name_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "catalog_name" */
export type Catalog_Name_Mutation_Response = {
  __typename?: 'catalog_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Catalog_Name>;
};

/** on_conflict condition type for table "catalog_name" */
export type Catalog_Name_On_Conflict = {
  constraint: Catalog_Name_Constraint;
  update_columns?: Array<Catalog_Name_Update_Column>;
  where?: Maybe<Catalog_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "catalog_name". */
export type Catalog_Name_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: catalog_name */
export type Catalog_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "catalog_name" */
export enum Catalog_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "catalog_name" */
export type Catalog_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "catalog_name" */
export enum Catalog_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for inserting object relation for remote table "catalog" */
export type Catalog_Obj_Rel_Insert_Input = {
  data: Catalog_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Catalog_On_Conflict>;
};

/** on_conflict condition type for table "catalog" */
export type Catalog_On_Conflict = {
  constraint: Catalog_Constraint;
  update_columns?: Array<Catalog_Update_Column>;
  where?: Maybe<Catalog_Bool_Exp>;
};

/** Ordering options when selecting data from "catalog". */
export type Catalog_Order_By = {
  catalog_id?: Maybe<Order_By>;
  catalog_items_aggregate?: Maybe<Catalog_Item_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_order?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  song_order?: Maybe<Order_By>;
  tip_order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: catalog */
export type Catalog_Pk_Columns_Input = {
  catalog_id: Scalars['Int'];
};

/** select columns of table "catalog" */
export enum Catalog_Select_Column {
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanOrder = 'lesson_plan_order',
  /** column name */
  Name = 'name',
  /** column name */
  SongOrder = 'song_order',
  /** column name */
  TipOrder = 'tip_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "catalog" */
export type Catalog_Set_Input = {
  catalog_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Catalog_Name_Enum>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Catalog_Stddev_Fields = {
  __typename?: 'catalog_stddev_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Catalog_Stddev_Pop_Fields = {
  __typename?: 'catalog_stddev_pop_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Catalog_Stddev_Samp_Fields = {
  __typename?: 'catalog_stddev_samp_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Catalog_Sum_Fields = {
  __typename?: 'catalog_sum_fields';
  catalog_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Int']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Int']>;
};

/** update columns of table "catalog" */
export enum Catalog_Update_Column {
  /** column name */
  CatalogId = 'catalog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanOrder = 'lesson_plan_order',
  /** column name */
  Name = 'name',
  /** column name */
  SongOrder = 'song_order',
  /** column name */
  TipOrder = 'tip_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Catalog_Var_Pop_Fields = {
  __typename?: 'catalog_var_pop_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Catalog_Var_Samp_Fields = {
  __typename?: 'catalog_var_samp_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Catalog_Variance_Fields = {
  __typename?: 'catalog_variance_fields';
  catalog_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in song catalogs */
  song_order?: Maybe<Scalars['Float']>;
  /** If not null, it will be displayed in tip catalogs */
  tip_order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "category_standard" */
export type Category_Standard = {
  __typename?: 'category_standard';
  description: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  standard_subcategories: Array<Standard_Subcategory>;
  /** An aggregate relationship */
  standard_subcategories_aggregate: Standard_Subcategory_Aggregate;
};


/** columns and relationships of "category_standard" */
export type Category_StandardStandard_SubcategoriesArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};


/** columns and relationships of "category_standard" */
export type Category_StandardStandard_Subcategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};

/** aggregated selection of "category_standard" */
export type Category_Standard_Aggregate = {
  __typename?: 'category_standard_aggregate';
  aggregate?: Maybe<Category_Standard_Aggregate_Fields>;
  nodes: Array<Category_Standard>;
};

/** aggregate fields of "category_standard" */
export type Category_Standard_Aggregate_Fields = {
  __typename?: 'category_standard_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Standard_Max_Fields>;
  min?: Maybe<Category_Standard_Min_Fields>;
};


/** aggregate fields of "category_standard" */
export type Category_Standard_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Standard_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "category_standard". All fields are combined with a logical 'AND'. */
export type Category_Standard_Bool_Exp = {
  _and?: Maybe<Array<Category_Standard_Bool_Exp>>;
  _not?: Maybe<Category_Standard_Bool_Exp>;
  _or?: Maybe<Array<Category_Standard_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  standard_subcategories?: Maybe<Standard_Subcategory_Bool_Exp>;
};

/** unique or primary key constraints on table "category_standard" */
export enum Category_Standard_Constraint {
  /** unique or primary key constraint */
  CategoryStandardPkey = 'category_standard_pkey'
}

export enum Category_Standard_Enum {
  /** Connecting */
  Connecting = 'Connecting',
  /** Creating */
  Creating = 'Creating',
  /** Performing */
  Performing = 'Performing',
  /** Responding */
  Responding = 'Responding'
}

/** Boolean expression to compare columns of type "category_standard_enum". All fields are combined with logical 'AND'. */
export type Category_Standard_Enum_Comparison_Exp = {
  _eq?: Maybe<Category_Standard_Enum>;
  _in?: Maybe<Array<Category_Standard_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Category_Standard_Enum>;
  _nin?: Maybe<Array<Category_Standard_Enum>>;
};

/** input type for inserting data into table "category_standard" */
export type Category_Standard_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standard_subcategories?: Maybe<Standard_Subcategory_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Category_Standard_Max_Fields = {
  __typename?: 'category_standard_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Standard_Min_Fields = {
  __typename?: 'category_standard_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "category_standard" */
export type Category_Standard_Mutation_Response = {
  __typename?: 'category_standard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Standard>;
};

/** input type for inserting object relation for remote table "category_standard" */
export type Category_Standard_Obj_Rel_Insert_Input = {
  data: Category_Standard_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Category_Standard_On_Conflict>;
};

/** on_conflict condition type for table "category_standard" */
export type Category_Standard_On_Conflict = {
  constraint: Category_Standard_Constraint;
  update_columns?: Array<Category_Standard_Update_Column>;
  where?: Maybe<Category_Standard_Bool_Exp>;
};

/** Ordering options when selecting data from "category_standard". */
export type Category_Standard_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  standard_subcategories_aggregate?: Maybe<Standard_Subcategory_Aggregate_Order_By>;
};

/** primary key columns input for table: category_standard */
export type Category_Standard_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "category_standard" */
export enum Category_Standard_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "category_standard" */
export type Category_Standard_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "category_standard" */
export enum Category_Standard_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "challenge" */
export type Challenge = {
  __typename?: 'challenge';
  active: Scalars['Boolean'];
  /** An array relationship */
  challenge_catalog_items: Array<Challenge_Catalog_Item>;
  /** An aggregate relationship */
  challenge_catalog_items_aggregate: Challenge_Catalog_Item_Aggregate;
  /** An array relationship */
  challenge_districts: Array<Challenge_District>;
  /** An aggregate relationship */
  challenge_districts_aggregate: Challenge_District_Aggregate;
  /** An array relationship */
  challenge_grades: Array<Challenge_Grade>;
  /** An aggregate relationship */
  challenge_grades_aggregate: Challenge_Grade_Aggregate;
  challenge_id: Scalars['Int'];
  /** An array relationship */
  challenge_songs: Array<Challenge_Song>;
  /** An aggregate relationship */
  challenge_songs_aggregate: Challenge_Song_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  /** A computed field, executes function "challenge_end_date" */
  end_date_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timetz']>;
  image_path?: Maybe<Scalars['String']>;
  include_all_songs: Scalars['Boolean'];
  publish_date?: Maybe<Scalars['date']>;
  publish_time?: Maybe<Scalars['timetz']>;
  /** An array relationship */
  seen: Array<Challenge_Student>;
  /** An aggregate relationship */
  seen_aggregate: Challenge_Student_Aggregate;
  /** A computed field, executes function "challenge_start_date" */
  start_date_time?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  video_path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_Catalog_ItemsArgs = {
  distinct_on?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Catalog_Item_Order_By>>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_Catalog_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Catalog_Item_Order_By>>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_DistrictsArgs = {
  distinct_on?: Maybe<Array<Challenge_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_District_Order_By>>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_Districts_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_District_Order_By>>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_GradesArgs = {
  distinct_on?: Maybe<Array<Challenge_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Grade_Order_By>>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_Grades_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Grade_Order_By>>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_SongsArgs = {
  distinct_on?: Maybe<Array<Challenge_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Song_Order_By>>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeChallenge_Songs_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Song_Order_By>>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeSeenArgs = {
  distinct_on?: Maybe<Array<Challenge_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Student_Order_By>>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};


/** columns and relationships of "challenge" */
export type ChallengeSeen_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Student_Order_By>>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};

/** aggregated selection of "challenge" */
export type Challenge_Aggregate = {
  __typename?: 'challenge_aggregate';
  aggregate?: Maybe<Challenge_Aggregate_Fields>;
  nodes: Array<Challenge>;
};

/** aggregate fields of "challenge" */
export type Challenge_Aggregate_Fields = {
  __typename?: 'challenge_aggregate_fields';
  avg?: Maybe<Challenge_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Challenge_Max_Fields>;
  min?: Maybe<Challenge_Min_Fields>;
  stddev?: Maybe<Challenge_Stddev_Fields>;
  stddev_pop?: Maybe<Challenge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Challenge_Stddev_Samp_Fields>;
  sum?: Maybe<Challenge_Sum_Fields>;
  var_pop?: Maybe<Challenge_Var_Pop_Fields>;
  var_samp?: Maybe<Challenge_Var_Samp_Fields>;
  variance?: Maybe<Challenge_Variance_Fields>;
};


/** aggregate fields of "challenge" */
export type Challenge_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Challenge_Avg_Fields = {
  __typename?: 'challenge_avg_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "challenge". All fields are combined with a logical 'AND'. */
export type Challenge_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Bool_Exp>>;
  _not?: Maybe<Challenge_Bool_Exp>;
  _or?: Maybe<Array<Challenge_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  challenge_catalog_items?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
  challenge_districts?: Maybe<Challenge_District_Bool_Exp>;
  challenge_grades?: Maybe<Challenge_Grade_Bool_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  challenge_songs?: Maybe<Challenge_Song_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  end_date_time?: Maybe<String_Comparison_Exp>;
  end_time?: Maybe<Timetz_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  include_all_songs?: Maybe<Boolean_Comparison_Exp>;
  publish_date?: Maybe<Date_Comparison_Exp>;
  publish_time?: Maybe<Timetz_Comparison_Exp>;
  seen?: Maybe<Challenge_Student_Bool_Exp>;
  start_date_time?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_path?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "challenge_catalog_item" */
export type Challenge_Catalog_Item = {
  __typename?: 'challenge_catalog_item';
  /** An object relationship */
  catalog_item: Catalog_Item;
  catalog_item_id: Scalars['Int'];
  /** An object relationship */
  challenge: Challenge;
  challenge_catalog_item_id: Scalars['Int'];
  challenge_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
};

/** aggregated selection of "challenge_catalog_item" */
export type Challenge_Catalog_Item_Aggregate = {
  __typename?: 'challenge_catalog_item_aggregate';
  aggregate?: Maybe<Challenge_Catalog_Item_Aggregate_Fields>;
  nodes: Array<Challenge_Catalog_Item>;
};

/** aggregate fields of "challenge_catalog_item" */
export type Challenge_Catalog_Item_Aggregate_Fields = {
  __typename?: 'challenge_catalog_item_aggregate_fields';
  avg?: Maybe<Challenge_Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Challenge_Catalog_Item_Max_Fields>;
  min?: Maybe<Challenge_Catalog_Item_Min_Fields>;
  stddev?: Maybe<Challenge_Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Challenge_Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Challenge_Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Challenge_Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Challenge_Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Challenge_Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Challenge_Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "challenge_catalog_item" */
export type Challenge_Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Challenge_Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Challenge_Catalog_Item_Max_Order_By>;
  min?: Maybe<Challenge_Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Challenge_Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Challenge_Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Challenge_Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Challenge_Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Challenge_Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Challenge_Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Challenge_Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Challenge_Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Challenge_Catalog_Item_Avg_Fields = {
  __typename?: 'challenge_catalog_item_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "challenge_catalog_item". All fields are combined with a logical 'AND'. */
export type Challenge_Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Challenge_Catalog_Item_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  challenge?: Maybe<Challenge_Bool_Exp>;
  challenge_catalog_item_id?: Maybe<Int_Comparison_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "challenge_catalog_item" */
export enum Challenge_Catalog_Item_Constraint {
  /** unique or primary key constraint */
  ChallengeCatalogItemPkey = 'challenge_catalog_item_pkey'
}

/** input type for incrementing numeric columns in table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>;
  challenge_catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Challenge_Catalog_Item_Max_Fields = {
  __typename?: 'challenge_catalog_item_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Challenge_Catalog_Item_Min_Fields = {
  __typename?: 'challenge_catalog_item_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Mutation_Response = {
  __typename?: 'challenge_catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Catalog_Item>;
};

/** on_conflict condition type for table "challenge_catalog_item" */
export type Challenge_Catalog_Item_On_Conflict = {
  constraint: Challenge_Catalog_Item_Constraint;
  update_columns?: Array<Challenge_Catalog_Item_Update_Column>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "challenge_catalog_item". */
export type Challenge_Catalog_Item_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  challenge?: Maybe<Challenge_Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
};

/** primary key columns input for table: challenge_catalog_item */
export type Challenge_Catalog_Item_Pk_Columns_Input = {
  challenge_catalog_item_id: Scalars['Int'];
};

/** select columns of table "challenge_catalog_item" */
export enum Challenge_Catalog_Item_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  ChallengeCatalogItemId = 'challenge_catalog_item_id',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at'
}

/** input type for updating data in table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Challenge_Catalog_Item_Stddev_Fields = {
  __typename?: 'challenge_catalog_item_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Challenge_Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'challenge_catalog_item_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Challenge_Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'challenge_catalog_item_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Challenge_Catalog_Item_Sum_Fields = {
  __typename?: 'challenge_catalog_item_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_catalog_item_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** update columns of table "challenge_catalog_item" */
export enum Challenge_Catalog_Item_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  ChallengeCatalogItemId = 'challenge_catalog_item_id',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at'
}

/** aggregate var_pop on columns */
export type Challenge_Catalog_Item_Var_Pop_Fields = {
  __typename?: 'challenge_catalog_item_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Challenge_Catalog_Item_Var_Samp_Fields = {
  __typename?: 'challenge_catalog_item_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Challenge_Catalog_Item_Variance_Fields = {
  __typename?: 'challenge_catalog_item_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_catalog_item_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "challenge_catalog_item" */
export type Challenge_Catalog_Item_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  challenge_catalog_item_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "challenge" */
export enum Challenge_Constraint {
  /** unique or primary key constraint */
  ChallengePkey = 'challenge_pkey'
}

/** columns and relationships of "challenge_district" */
export type Challenge_District = {
  __typename?: 'challenge_district';
  /** An object relationship */
  challenge: Challenge;
  challenge_district_id: Scalars['Int'];
  challenge_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  district: District;
  district_id: Scalars['Int'];
};

/** aggregated selection of "challenge_district" */
export type Challenge_District_Aggregate = {
  __typename?: 'challenge_district_aggregate';
  aggregate?: Maybe<Challenge_District_Aggregate_Fields>;
  nodes: Array<Challenge_District>;
};

/** aggregate fields of "challenge_district" */
export type Challenge_District_Aggregate_Fields = {
  __typename?: 'challenge_district_aggregate_fields';
  avg?: Maybe<Challenge_District_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Challenge_District_Max_Fields>;
  min?: Maybe<Challenge_District_Min_Fields>;
  stddev?: Maybe<Challenge_District_Stddev_Fields>;
  stddev_pop?: Maybe<Challenge_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Challenge_District_Stddev_Samp_Fields>;
  sum?: Maybe<Challenge_District_Sum_Fields>;
  var_pop?: Maybe<Challenge_District_Var_Pop_Fields>;
  var_samp?: Maybe<Challenge_District_Var_Samp_Fields>;
  variance?: Maybe<Challenge_District_Variance_Fields>;
};


/** aggregate fields of "challenge_district" */
export type Challenge_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_District_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "challenge_district" */
export type Challenge_District_Aggregate_Order_By = {
  avg?: Maybe<Challenge_District_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Challenge_District_Max_Order_By>;
  min?: Maybe<Challenge_District_Min_Order_By>;
  stddev?: Maybe<Challenge_District_Stddev_Order_By>;
  stddev_pop?: Maybe<Challenge_District_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Challenge_District_Stddev_Samp_Order_By>;
  sum?: Maybe<Challenge_District_Sum_Order_By>;
  var_pop?: Maybe<Challenge_District_Var_Pop_Order_By>;
  var_samp?: Maybe<Challenge_District_Var_Samp_Order_By>;
  variance?: Maybe<Challenge_District_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "challenge_district" */
export type Challenge_District_Arr_Rel_Insert_Input = {
  data: Array<Challenge_District_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Challenge_District_On_Conflict>;
};

/** aggregate avg on columns */
export type Challenge_District_Avg_Fields = {
  __typename?: 'challenge_district_avg_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "challenge_district" */
export type Challenge_District_Avg_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "challenge_district". All fields are combined with a logical 'AND'. */
export type Challenge_District_Bool_Exp = {
  _and?: Maybe<Array<Challenge_District_Bool_Exp>>;
  _not?: Maybe<Challenge_District_Bool_Exp>;
  _or?: Maybe<Array<Challenge_District_Bool_Exp>>;
  challenge?: Maybe<Challenge_Bool_Exp>;
  challenge_district_id?: Maybe<Int_Comparison_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  district?: Maybe<District_Bool_Exp>;
  district_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "challenge_district" */
export enum Challenge_District_Constraint {
  /** unique or primary key constraint */
  ChallengeDistrictChallengeIdDistrictIdKey = 'challenge_district_challenge_id_district_id_key',
  /** unique or primary key constraint */
  ChallengeDistrictPkey = 'challenge_district_pkey'
}

/** input type for incrementing numeric columns in table "challenge_district" */
export type Challenge_District_Inc_Input = {
  challenge_district_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "challenge_district" */
export type Challenge_District_Insert_Input = {
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>;
  challenge_district_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<District_Obj_Rel_Insert_Input>;
  district_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Challenge_District_Max_Fields = {
  __typename?: 'challenge_district_max_fields';
  challenge_district_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "challenge_district" */
export type Challenge_District_Max_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Challenge_District_Min_Fields = {
  __typename?: 'challenge_district_min_fields';
  challenge_district_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "challenge_district" */
export type Challenge_District_Min_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "challenge_district" */
export type Challenge_District_Mutation_Response = {
  __typename?: 'challenge_district_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_District>;
};

/** on_conflict condition type for table "challenge_district" */
export type Challenge_District_On_Conflict = {
  constraint: Challenge_District_Constraint;
  update_columns?: Array<Challenge_District_Update_Column>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};

/** Ordering options when selecting data from "challenge_district". */
export type Challenge_District_Order_By = {
  challenge?: Maybe<Challenge_Order_By>;
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district?: Maybe<District_Order_By>;
  district_id?: Maybe<Order_By>;
};

/** primary key columns input for table: challenge_district */
export type Challenge_District_Pk_Columns_Input = {
  challenge_district_id: Scalars['Int'];
};

/** select columns of table "challenge_district" */
export enum Challenge_District_Select_Column {
  /** column name */
  ChallengeDistrictId = 'challenge_district_id',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id'
}

/** input type for updating data in table "challenge_district" */
export type Challenge_District_Set_Input = {
  challenge_district_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Challenge_District_Stddev_Fields = {
  __typename?: 'challenge_district_stddev_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "challenge_district" */
export type Challenge_District_Stddev_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Challenge_District_Stddev_Pop_Fields = {
  __typename?: 'challenge_district_stddev_pop_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "challenge_district" */
export type Challenge_District_Stddev_Pop_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Challenge_District_Stddev_Samp_Fields = {
  __typename?: 'challenge_district_stddev_samp_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "challenge_district" */
export type Challenge_District_Stddev_Samp_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Challenge_District_Sum_Fields = {
  __typename?: 'challenge_district_sum_fields';
  challenge_district_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "challenge_district" */
export type Challenge_District_Sum_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** update columns of table "challenge_district" */
export enum Challenge_District_Update_Column {
  /** column name */
  ChallengeDistrictId = 'challenge_district_id',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id'
}

/** aggregate var_pop on columns */
export type Challenge_District_Var_Pop_Fields = {
  __typename?: 'challenge_district_var_pop_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "challenge_district" */
export type Challenge_District_Var_Pop_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Challenge_District_Var_Samp_Fields = {
  __typename?: 'challenge_district_var_samp_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "challenge_district" */
export type Challenge_District_Var_Samp_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Challenge_District_Variance_Fields = {
  __typename?: 'challenge_district_variance_fields';
  challenge_district_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "challenge_district" */
export type Challenge_District_Variance_Order_By = {
  challenge_district_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
};

/** columns and relationships of "challenge_grade" */
export type Challenge_Grade = {
  __typename?: 'challenge_grade';
  /** An object relationship */
  challenge: Challenge;
  challenge_grade_id: Scalars['Int'];
  challenge_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  grade: Grade;
  grade_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "challenge_grade" */
export type Challenge_Grade_Aggregate = {
  __typename?: 'challenge_grade_aggregate';
  aggregate?: Maybe<Challenge_Grade_Aggregate_Fields>;
  nodes: Array<Challenge_Grade>;
};

/** aggregate fields of "challenge_grade" */
export type Challenge_Grade_Aggregate_Fields = {
  __typename?: 'challenge_grade_aggregate_fields';
  avg?: Maybe<Challenge_Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Challenge_Grade_Max_Fields>;
  min?: Maybe<Challenge_Grade_Min_Fields>;
  stddev?: Maybe<Challenge_Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Challenge_Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Challenge_Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Challenge_Grade_Sum_Fields>;
  var_pop?: Maybe<Challenge_Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Challenge_Grade_Var_Samp_Fields>;
  variance?: Maybe<Challenge_Grade_Variance_Fields>;
};


/** aggregate fields of "challenge_grade" */
export type Challenge_Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "challenge_grade" */
export type Challenge_Grade_Aggregate_Order_By = {
  avg?: Maybe<Challenge_Grade_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Challenge_Grade_Max_Order_By>;
  min?: Maybe<Challenge_Grade_Min_Order_By>;
  stddev?: Maybe<Challenge_Grade_Stddev_Order_By>;
  stddev_pop?: Maybe<Challenge_Grade_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Challenge_Grade_Stddev_Samp_Order_By>;
  sum?: Maybe<Challenge_Grade_Sum_Order_By>;
  var_pop?: Maybe<Challenge_Grade_Var_Pop_Order_By>;
  var_samp?: Maybe<Challenge_Grade_Var_Samp_Order_By>;
  variance?: Maybe<Challenge_Grade_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "challenge_grade" */
export type Challenge_Grade_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Grade_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Challenge_Grade_On_Conflict>;
};

/** aggregate avg on columns */
export type Challenge_Grade_Avg_Fields = {
  __typename?: 'challenge_grade_avg_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "challenge_grade" */
export type Challenge_Grade_Avg_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "challenge_grade". All fields are combined with a logical 'AND'. */
export type Challenge_Grade_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Grade_Bool_Exp>>;
  _not?: Maybe<Challenge_Grade_Bool_Exp>;
  _or?: Maybe<Array<Challenge_Grade_Bool_Exp>>;
  challenge?: Maybe<Challenge_Bool_Exp>;
  challenge_grade_id?: Maybe<Int_Comparison_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grade?: Maybe<Grade_Bool_Exp>;
  grade_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "challenge_grade" */
export enum Challenge_Grade_Constraint {
  /** unique or primary key constraint */
  ChallengeGradePkey = 'challenge_grade_pkey'
}

/** input type for incrementing numeric columns in table "challenge_grade" */
export type Challenge_Grade_Inc_Input = {
  challenge_grade_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  grade_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "challenge_grade" */
export type Challenge_Grade_Insert_Input = {
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>;
  challenge_grade_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grade?: Maybe<Grade_Obj_Rel_Insert_Input>;
  grade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Challenge_Grade_Max_Fields = {
  __typename?: 'challenge_grade_max_fields';
  challenge_grade_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "challenge_grade" */
export type Challenge_Grade_Max_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Challenge_Grade_Min_Fields = {
  __typename?: 'challenge_grade_min_fields';
  challenge_grade_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "challenge_grade" */
export type Challenge_Grade_Min_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "challenge_grade" */
export type Challenge_Grade_Mutation_Response = {
  __typename?: 'challenge_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Grade>;
};

/** on_conflict condition type for table "challenge_grade" */
export type Challenge_Grade_On_Conflict = {
  constraint: Challenge_Grade_Constraint;
  update_columns?: Array<Challenge_Grade_Update_Column>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "challenge_grade". */
export type Challenge_Grade_Order_By = {
  challenge?: Maybe<Challenge_Order_By>;
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  grade?: Maybe<Grade_Order_By>;
  grade_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: challenge_grade */
export type Challenge_Grade_Pk_Columns_Input = {
  challenge_grade_id: Scalars['Int'];
};

/** select columns of table "challenge_grade" */
export enum Challenge_Grade_Select_Column {
  /** column name */
  ChallengeGradeId = 'challenge_grade_id',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "challenge_grade" */
export type Challenge_Grade_Set_Input = {
  challenge_grade_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Challenge_Grade_Stddev_Fields = {
  __typename?: 'challenge_grade_stddev_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "challenge_grade" */
export type Challenge_Grade_Stddev_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Challenge_Grade_Stddev_Pop_Fields = {
  __typename?: 'challenge_grade_stddev_pop_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "challenge_grade" */
export type Challenge_Grade_Stddev_Pop_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Challenge_Grade_Stddev_Samp_Fields = {
  __typename?: 'challenge_grade_stddev_samp_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "challenge_grade" */
export type Challenge_Grade_Stddev_Samp_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Challenge_Grade_Sum_Fields = {
  __typename?: 'challenge_grade_sum_fields';
  challenge_grade_id?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  grade_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "challenge_grade" */
export type Challenge_Grade_Sum_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** update columns of table "challenge_grade" */
export enum Challenge_Grade_Update_Column {
  /** column name */
  ChallengeGradeId = 'challenge_grade_id',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Challenge_Grade_Var_Pop_Fields = {
  __typename?: 'challenge_grade_var_pop_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "challenge_grade" */
export type Challenge_Grade_Var_Pop_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Challenge_Grade_Var_Samp_Fields = {
  __typename?: 'challenge_grade_var_samp_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "challenge_grade" */
export type Challenge_Grade_Var_Samp_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Challenge_Grade_Variance_Fields = {
  __typename?: 'challenge_grade_variance_fields';
  challenge_grade_id?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "challenge_grade" */
export type Challenge_Grade_Variance_Order_By = {
  challenge_grade_id?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "challenge" */
export type Challenge_Inc_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "challenge" */
export type Challenge_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  challenge_catalog_items?: Maybe<Challenge_Catalog_Item_Arr_Rel_Insert_Input>;
  challenge_districts?: Maybe<Challenge_District_Arr_Rel_Insert_Input>;
  challenge_grades?: Maybe<Challenge_Grade_Arr_Rel_Insert_Input>;
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_songs?: Maybe<Challenge_Song_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  image_path?: Maybe<Scalars['String']>;
  include_all_songs?: Maybe<Scalars['Boolean']>;
  publish_date?: Maybe<Scalars['date']>;
  publish_time?: Maybe<Scalars['timetz']>;
  seen?: Maybe<Challenge_Student_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Challenge_Max_Fields = {
  __typename?: 'challenge_max_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  image_path?: Maybe<Scalars['String']>;
  publish_date?: Maybe<Scalars['date']>;
  publish_time?: Maybe<Scalars['timetz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Challenge_Min_Fields = {
  __typename?: 'challenge_min_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  image_path?: Maybe<Scalars['String']>;
  publish_date?: Maybe<Scalars['date']>;
  publish_time?: Maybe<Scalars['timetz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "challenge" */
export type Challenge_Mutation_Response = {
  __typename?: 'challenge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Challenge>;
};

/** input type for inserting object relation for remote table "challenge" */
export type Challenge_Obj_Rel_Insert_Input = {
  data: Challenge_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Challenge_On_Conflict>;
};

/** on_conflict condition type for table "challenge" */
export type Challenge_On_Conflict = {
  constraint: Challenge_Constraint;
  update_columns?: Array<Challenge_Update_Column>;
  where?: Maybe<Challenge_Bool_Exp>;
};

/** Ordering options when selecting data from "challenge". */
export type Challenge_Order_By = {
  active?: Maybe<Order_By>;
  challenge_catalog_items_aggregate?: Maybe<Challenge_Catalog_Item_Aggregate_Order_By>;
  challenge_districts_aggregate?: Maybe<Challenge_District_Aggregate_Order_By>;
  challenge_grades_aggregate?: Maybe<Challenge_Grade_Aggregate_Order_By>;
  challenge_id?: Maybe<Order_By>;
  challenge_songs_aggregate?: Maybe<Challenge_Song_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  end_date_time?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  include_all_songs?: Maybe<Order_By>;
  publish_date?: Maybe<Order_By>;
  publish_time?: Maybe<Order_By>;
  seen_aggregate?: Maybe<Challenge_Student_Aggregate_Order_By>;
  start_date_time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_path?: Maybe<Order_By>;
};

/** primary key columns input for table: challenge */
export type Challenge_Pk_Columns_Input = {
  challenge_id: Scalars['Int'];
};

/** select columns of table "challenge" */
export enum Challenge_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IncludeAllSongs = 'include_all_songs',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  PublishTime = 'publish_time',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path'
}

/** input type for updating data in table "challenge" */
export type Challenge_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  challenge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  end_time?: Maybe<Scalars['timetz']>;
  image_path?: Maybe<Scalars['String']>;
  include_all_songs?: Maybe<Scalars['Boolean']>;
  publish_date?: Maybe<Scalars['date']>;
  publish_time?: Maybe<Scalars['timetz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "challenge_song" */
export type Challenge_Song = {
  __typename?: 'challenge_song';
  /** An object relationship */
  challenge: Challenge;
  challenge_id: Scalars['Int'];
  challenge_song_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
};

/** aggregated selection of "challenge_song" */
export type Challenge_Song_Aggregate = {
  __typename?: 'challenge_song_aggregate';
  aggregate?: Maybe<Challenge_Song_Aggregate_Fields>;
  nodes: Array<Challenge_Song>;
};

/** aggregate fields of "challenge_song" */
export type Challenge_Song_Aggregate_Fields = {
  __typename?: 'challenge_song_aggregate_fields';
  avg?: Maybe<Challenge_Song_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Challenge_Song_Max_Fields>;
  min?: Maybe<Challenge_Song_Min_Fields>;
  stddev?: Maybe<Challenge_Song_Stddev_Fields>;
  stddev_pop?: Maybe<Challenge_Song_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Challenge_Song_Stddev_Samp_Fields>;
  sum?: Maybe<Challenge_Song_Sum_Fields>;
  var_pop?: Maybe<Challenge_Song_Var_Pop_Fields>;
  var_samp?: Maybe<Challenge_Song_Var_Samp_Fields>;
  variance?: Maybe<Challenge_Song_Variance_Fields>;
};


/** aggregate fields of "challenge_song" */
export type Challenge_Song_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Song_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "challenge_song" */
export type Challenge_Song_Aggregate_Order_By = {
  avg?: Maybe<Challenge_Song_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Challenge_Song_Max_Order_By>;
  min?: Maybe<Challenge_Song_Min_Order_By>;
  stddev?: Maybe<Challenge_Song_Stddev_Order_By>;
  stddev_pop?: Maybe<Challenge_Song_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Challenge_Song_Stddev_Samp_Order_By>;
  sum?: Maybe<Challenge_Song_Sum_Order_By>;
  var_pop?: Maybe<Challenge_Song_Var_Pop_Order_By>;
  var_samp?: Maybe<Challenge_Song_Var_Samp_Order_By>;
  variance?: Maybe<Challenge_Song_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "challenge_song" */
export type Challenge_Song_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Song_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Challenge_Song_On_Conflict>;
};

/** aggregate avg on columns */
export type Challenge_Song_Avg_Fields = {
  __typename?: 'challenge_song_avg_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "challenge_song" */
export type Challenge_Song_Avg_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "challenge_song". All fields are combined with a logical 'AND'. */
export type Challenge_Song_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Song_Bool_Exp>>;
  _not?: Maybe<Challenge_Song_Bool_Exp>;
  _or?: Maybe<Array<Challenge_Song_Bool_Exp>>;
  challenge?: Maybe<Challenge_Bool_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  challenge_song_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "challenge_song" */
export enum Challenge_Song_Constraint {
  /** unique or primary key constraint */
  ChallengeSongPkey = 'challenge_song_pkey'
}

/** input type for incrementing numeric columns in table "challenge_song" */
export type Challenge_Song_Inc_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "challenge_song" */
export type Challenge_Song_Insert_Input = {
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>;
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Challenge_Song_Max_Fields = {
  __typename?: 'challenge_song_max_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "challenge_song" */
export type Challenge_Song_Max_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Challenge_Song_Min_Fields = {
  __typename?: 'challenge_song_min_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "challenge_song" */
export type Challenge_Song_Min_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "challenge_song" */
export type Challenge_Song_Mutation_Response = {
  __typename?: 'challenge_song_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Song>;
};

/** on_conflict condition type for table "challenge_song" */
export type Challenge_Song_On_Conflict = {
  constraint: Challenge_Song_Constraint;
  update_columns?: Array<Challenge_Song_Update_Column>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};

/** Ordering options when selecting data from "challenge_song". */
export type Challenge_Song_Order_By = {
  challenge?: Maybe<Challenge_Order_By>;
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
};

/** primary key columns input for table: challenge_song */
export type Challenge_Song_Pk_Columns_Input = {
  challenge_song_id: Scalars['Int'];
};

/** select columns of table "challenge_song" */
export enum Challenge_Song_Select_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ChallengeSongId = 'challenge_song_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id'
}

/** input type for updating data in table "challenge_song" */
export type Challenge_Song_Set_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Challenge_Song_Stddev_Fields = {
  __typename?: 'challenge_song_stddev_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "challenge_song" */
export type Challenge_Song_Stddev_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Challenge_Song_Stddev_Pop_Fields = {
  __typename?: 'challenge_song_stddev_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "challenge_song" */
export type Challenge_Song_Stddev_Pop_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Challenge_Song_Stddev_Samp_Fields = {
  __typename?: 'challenge_song_stddev_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "challenge_song" */
export type Challenge_Song_Stddev_Samp_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Challenge_Song_Sum_Fields = {
  __typename?: 'challenge_song_sum_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "challenge_song" */
export type Challenge_Song_Sum_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** update columns of table "challenge_song" */
export enum Challenge_Song_Update_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ChallengeSongId = 'challenge_song_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id'
}

/** aggregate var_pop on columns */
export type Challenge_Song_Var_Pop_Fields = {
  __typename?: 'challenge_song_var_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "challenge_song" */
export type Challenge_Song_Var_Pop_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Challenge_Song_Var_Samp_Fields = {
  __typename?: 'challenge_song_var_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "challenge_song" */
export type Challenge_Song_Var_Samp_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Challenge_Song_Variance_Fields = {
  __typename?: 'challenge_song_variance_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "challenge_song" */
export type Challenge_Song_Variance_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Challenge_Stddev_Fields = {
  __typename?: 'challenge_stddev_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Challenge_Stddev_Pop_Fields = {
  __typename?: 'challenge_stddev_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Challenge_Stddev_Samp_Fields = {
  __typename?: 'challenge_stddev_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "challenge_student" */
export type Challenge_Student = {
  __typename?: 'challenge_student';
  challenge_id: Scalars['Int'];
  challenge_student_id: Scalars['Int'];
  seen: Scalars['Boolean'];
  student_id: Scalars['Int'];
};

/** aggregated selection of "challenge_student" */
export type Challenge_Student_Aggregate = {
  __typename?: 'challenge_student_aggregate';
  aggregate?: Maybe<Challenge_Student_Aggregate_Fields>;
  nodes: Array<Challenge_Student>;
};

/** aggregate fields of "challenge_student" */
export type Challenge_Student_Aggregate_Fields = {
  __typename?: 'challenge_student_aggregate_fields';
  avg?: Maybe<Challenge_Student_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Challenge_Student_Max_Fields>;
  min?: Maybe<Challenge_Student_Min_Fields>;
  stddev?: Maybe<Challenge_Student_Stddev_Fields>;
  stddev_pop?: Maybe<Challenge_Student_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Challenge_Student_Stddev_Samp_Fields>;
  sum?: Maybe<Challenge_Student_Sum_Fields>;
  var_pop?: Maybe<Challenge_Student_Var_Pop_Fields>;
  var_samp?: Maybe<Challenge_Student_Var_Samp_Fields>;
  variance?: Maybe<Challenge_Student_Variance_Fields>;
};


/** aggregate fields of "challenge_student" */
export type Challenge_Student_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Challenge_Student_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "challenge_student" */
export type Challenge_Student_Aggregate_Order_By = {
  avg?: Maybe<Challenge_Student_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Challenge_Student_Max_Order_By>;
  min?: Maybe<Challenge_Student_Min_Order_By>;
  stddev?: Maybe<Challenge_Student_Stddev_Order_By>;
  stddev_pop?: Maybe<Challenge_Student_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Challenge_Student_Stddev_Samp_Order_By>;
  sum?: Maybe<Challenge_Student_Sum_Order_By>;
  var_pop?: Maybe<Challenge_Student_Var_Pop_Order_By>;
  var_samp?: Maybe<Challenge_Student_Var_Samp_Order_By>;
  variance?: Maybe<Challenge_Student_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "challenge_student" */
export type Challenge_Student_Arr_Rel_Insert_Input = {
  data: Array<Challenge_Student_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Challenge_Student_On_Conflict>;
};

/** aggregate avg on columns */
export type Challenge_Student_Avg_Fields = {
  __typename?: 'challenge_student_avg_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "challenge_student" */
export type Challenge_Student_Avg_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "challenge_student". All fields are combined with a logical 'AND'. */
export type Challenge_Student_Bool_Exp = {
  _and?: Maybe<Array<Challenge_Student_Bool_Exp>>;
  _not?: Maybe<Challenge_Student_Bool_Exp>;
  _or?: Maybe<Array<Challenge_Student_Bool_Exp>>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  challenge_student_id?: Maybe<Int_Comparison_Exp>;
  seen?: Maybe<Boolean_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "challenge_student" */
export enum Challenge_Student_Constraint {
  /** unique or primary key constraint */
  ChallengeStudentPkey = 'challenge_student_pkey',
  /** unique or primary key constraint */
  ChallengeStudentStudentIdChallengeIdKey = 'challenge_student_student_id_challenge_id_key'
}

/** input type for incrementing numeric columns in table "challenge_student" */
export type Challenge_Student_Inc_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_student_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "challenge_student" */
export type Challenge_Student_Insert_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_student_id?: Maybe<Scalars['Int']>;
  seen?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Challenge_Student_Max_Fields = {
  __typename?: 'challenge_student_max_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_student_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "challenge_student" */
export type Challenge_Student_Max_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Challenge_Student_Min_Fields = {
  __typename?: 'challenge_student_min_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_student_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "challenge_student" */
export type Challenge_Student_Min_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "challenge_student" */
export type Challenge_Student_Mutation_Response = {
  __typename?: 'challenge_student_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Challenge_Student>;
};

/** on_conflict condition type for table "challenge_student" */
export type Challenge_Student_On_Conflict = {
  constraint: Challenge_Student_Constraint;
  update_columns?: Array<Challenge_Student_Update_Column>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};

/** Ordering options when selecting data from "challenge_student". */
export type Challenge_Student_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  seen?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** primary key columns input for table: challenge_student */
export type Challenge_Student_Pk_Columns_Input = {
  challenge_student_id: Scalars['Int'];
};

/** select columns of table "challenge_student" */
export enum Challenge_Student_Select_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ChallengeStudentId = 'challenge_student_id',
  /** column name */
  Seen = 'seen',
  /** column name */
  StudentId = 'student_id'
}

/** input type for updating data in table "challenge_student" */
export type Challenge_Student_Set_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_student_id?: Maybe<Scalars['Int']>;
  seen?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Challenge_Student_Stddev_Fields = {
  __typename?: 'challenge_student_stddev_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "challenge_student" */
export type Challenge_Student_Stddev_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Challenge_Student_Stddev_Pop_Fields = {
  __typename?: 'challenge_student_stddev_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "challenge_student" */
export type Challenge_Student_Stddev_Pop_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Challenge_Student_Stddev_Samp_Fields = {
  __typename?: 'challenge_student_stddev_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "challenge_student" */
export type Challenge_Student_Stddev_Samp_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Challenge_Student_Sum_Fields = {
  __typename?: 'challenge_student_sum_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  challenge_student_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "challenge_student" */
export type Challenge_Student_Sum_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** update columns of table "challenge_student" */
export enum Challenge_Student_Update_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ChallengeStudentId = 'challenge_student_id',
  /** column name */
  Seen = 'seen',
  /** column name */
  StudentId = 'student_id'
}

/** aggregate var_pop on columns */
export type Challenge_Student_Var_Pop_Fields = {
  __typename?: 'challenge_student_var_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "challenge_student" */
export type Challenge_Student_Var_Pop_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Challenge_Student_Var_Samp_Fields = {
  __typename?: 'challenge_student_var_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "challenge_student" */
export type Challenge_Student_Var_Samp_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Challenge_Student_Variance_Fields = {
  __typename?: 'challenge_student_variance_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  challenge_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "challenge_student" */
export type Challenge_Student_Variance_Order_By = {
  challenge_id?: Maybe<Order_By>;
  challenge_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Challenge_Sum_Fields = {
  __typename?: 'challenge_sum_fields';
  challenge_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "challenge" */
export enum Challenge_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IncludeAllSongs = 'include_all_songs',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  PublishTime = 'publish_time',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path'
}

/** aggregate var_pop on columns */
export type Challenge_Var_Pop_Fields = {
  __typename?: 'challenge_var_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Challenge_Var_Samp_Fields = {
  __typename?: 'challenge_var_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Challenge_Variance_Fields = {
  __typename?: 'challenge_variance_fields';
  challenge_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "channel" */
export type Channel = {
  __typename?: 'channel';
  active: Scalars['Boolean'];
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  channel_id: Scalars['Int'];
  /** An array relationship */
  channel_playlists: Array<Channel_Playlist>;
  /** An aggregate relationship */
  channel_playlists_aggregate: Channel_Playlist_Aggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_channel_status_by_teacher" */
  get_status_by_teacher?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** A computed field, executes function "is_channel_purchased_by_teacher" */
  is_purchased_by_teacher?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lockable_content: Lockable_Content;
  lockable_content_id: Scalars['Int'];
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order: Scalars['String'];
  /** An array relationship */
  order_details: Array<Order>;
  /** An aggregate relationship */
  order_details_aggregate: Order_Aggregate;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "channel" */
export type ChannelChannel_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


/** columns and relationships of "channel" */
export type ChannelChannel_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


/** columns and relationships of "channel" */
export type ChannelOrder_DetailsArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "channel" */
export type ChannelOrder_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};

/** aggregated selection of "channel" */
export type Channel_Aggregate = {
  __typename?: 'channel_aggregate';
  aggregate?: Maybe<Channel_Aggregate_Fields>;
  nodes: Array<Channel>;
};

/** aggregate fields of "channel" */
export type Channel_Aggregate_Fields = {
  __typename?: 'channel_aggregate_fields';
  avg?: Maybe<Channel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Channel_Max_Fields>;
  min?: Maybe<Channel_Min_Fields>;
  stddev?: Maybe<Channel_Stddev_Fields>;
  stddev_pop?: Maybe<Channel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Channel_Stddev_Samp_Fields>;
  sum?: Maybe<Channel_Sum_Fields>;
  var_pop?: Maybe<Channel_Var_Pop_Fields>;
  var_samp?: Maybe<Channel_Var_Samp_Fields>;
  variance?: Maybe<Channel_Variance_Fields>;
};


/** aggregate fields of "channel" */
export type Channel_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Channel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Channel_Avg_Fields = {
  __typename?: 'channel_avg_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "channel". All fields are combined with a logical 'AND'. */
export type Channel_Bool_Exp = {
  _and?: Maybe<Array<Channel_Bool_Exp>>;
  _not?: Maybe<Channel_Bool_Exp>;
  _or?: Maybe<Array<Channel_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  annual_price?: Maybe<Int_Comparison_Exp>;
  annual_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  banner_description?: Maybe<String_Comparison_Exp>;
  banner_image_path?: Maybe<String_Comparison_Exp>;
  channel_id?: Maybe<Int_Comparison_Exp>;
  channel_playlists?: Maybe<Channel_Playlist_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  district_price?: Maybe<Int_Comparison_Exp>;
  district_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  get_status_by_teacher?: Maybe<String_Comparison_Exp>;
  heading_description?: Maybe<String_Comparison_Exp>;
  heading_short_description?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_purchased_by_teacher?: Maybe<Boolean_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  logo_image_path?: Maybe<String_Comparison_Exp>;
  monthly_price?: Maybe<Int_Comparison_Exp>;
  monthly_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  order?: Maybe<String_Comparison_Exp>;
  order_details?: Maybe<Order_Bool_Exp>;
  school_price?: Maybe<Int_Comparison_Exp>;
  school_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  short_description?: Maybe<String_Comparison_Exp>;
  stripe_product_id?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_path?: Maybe<String_Comparison_Exp>;
  video_thumbnail_image_path?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel" */
export enum Channel_Constraint {
  /** unique or primary key constraint */
  ChannelAnnualPriceStripePriceIdKey = 'channel_annual_price_stripe_price_id_key',
  /** unique or primary key constraint */
  ChannelDistrictPriceStripePriceIdKey = 'channel_district_price_stripe_price_id_key',
  /** unique or primary key constraint */
  ChannelLockableContentIdKey = 'channel_lockable_content_id_key',
  /** unique or primary key constraint */
  ChannelMonthlyPriceStripePriceIdKey = 'channel_monthly_price_stripe_price_id_key',
  /** unique or primary key constraint */
  ChannelPkey = 'channel_pkey',
  /** unique or primary key constraint */
  ChannelSchoolPriceStripePriceIdKey = 'channel_school_price_stripe_price_id_key',
  /** unique or primary key constraint */
  ChannelStripeProductIdKey = 'channel_stripe_product_id_key'
}

/** input type for incrementing numeric columns in table "channel" */
export type Channel_Inc_Input = {
  annual_price?: Maybe<Scalars['Int']>;
  channel_id?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "channel" */
export type Channel_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlists?: Maybe<Channel_Playlist_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  order_details?: Maybe<Order_Arr_Rel_Insert_Input>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Channel_Max_Fields = {
  __typename?: 'channel_max_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Channel_Min_Fields = {
  __typename?: 'channel_min_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "channel" */
export type Channel_Mutation_Response = {
  __typename?: 'channel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel>;
};

/** input type for inserting object relation for remote table "channel" */
export type Channel_Obj_Rel_Insert_Input = {
  data: Channel_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Channel_On_Conflict>;
};

/** on_conflict condition type for table "channel" */
export type Channel_On_Conflict = {
  constraint: Channel_Constraint;
  update_columns?: Array<Channel_Update_Column>;
  where?: Maybe<Channel_Bool_Exp>;
};

/** Ordering options when selecting data from "channel". */
export type Channel_Order_By = {
  active?: Maybe<Order_By>;
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  banner_description?: Maybe<Order_By>;
  banner_image_path?: Maybe<Order_By>;
  channel_id?: Maybe<Order_By>;
  channel_playlists_aggregate?: Maybe<Channel_Playlist_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  get_status_by_teacher?: Maybe<Order_By>;
  heading_description?: Maybe<Order_By>;
  heading_short_description?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_purchased_by_teacher?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  logo_image_path?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_details_aggregate?: Maybe<Order_Aggregate_Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  short_description?: Maybe<Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_path?: Maybe<Order_By>;
  video_thumbnail_image_path?: Maybe<Order_By>;
};

/** primary key columns input for table: channel */
export type Channel_Pk_Columns_Input = {
  channel_id: Scalars['Int'];
};

/** columns and relationships of "channel_playlist" */
export type Channel_Playlist = {
  __typename?: 'channel_playlist';
  /** An object relationship */
  channel: Channel;
  channel_id: Scalars['Int'];
  channel_playlist_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  playlist: Playlist;
  playlist_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "channel_playlist" */
export type Channel_Playlist_Aggregate = {
  __typename?: 'channel_playlist_aggregate';
  aggregate?: Maybe<Channel_Playlist_Aggregate_Fields>;
  nodes: Array<Channel_Playlist>;
};

/** aggregate fields of "channel_playlist" */
export type Channel_Playlist_Aggregate_Fields = {
  __typename?: 'channel_playlist_aggregate_fields';
  avg?: Maybe<Channel_Playlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Channel_Playlist_Max_Fields>;
  min?: Maybe<Channel_Playlist_Min_Fields>;
  stddev?: Maybe<Channel_Playlist_Stddev_Fields>;
  stddev_pop?: Maybe<Channel_Playlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Channel_Playlist_Stddev_Samp_Fields>;
  sum?: Maybe<Channel_Playlist_Sum_Fields>;
  var_pop?: Maybe<Channel_Playlist_Var_Pop_Fields>;
  var_samp?: Maybe<Channel_Playlist_Var_Samp_Fields>;
  variance?: Maybe<Channel_Playlist_Variance_Fields>;
};


/** aggregate fields of "channel_playlist" */
export type Channel_Playlist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Channel_Playlist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "channel_playlist" */
export type Channel_Playlist_Aggregate_Order_By = {
  avg?: Maybe<Channel_Playlist_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Channel_Playlist_Max_Order_By>;
  min?: Maybe<Channel_Playlist_Min_Order_By>;
  stddev?: Maybe<Channel_Playlist_Stddev_Order_By>;
  stddev_pop?: Maybe<Channel_Playlist_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Channel_Playlist_Stddev_Samp_Order_By>;
  sum?: Maybe<Channel_Playlist_Sum_Order_By>;
  var_pop?: Maybe<Channel_Playlist_Var_Pop_Order_By>;
  var_samp?: Maybe<Channel_Playlist_Var_Samp_Order_By>;
  variance?: Maybe<Channel_Playlist_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "channel_playlist" */
export type Channel_Playlist_Arr_Rel_Insert_Input = {
  data: Array<Channel_Playlist_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Channel_Playlist_On_Conflict>;
};

/** aggregate avg on columns */
export type Channel_Playlist_Avg_Fields = {
  __typename?: 'channel_playlist_avg_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "channel_playlist" */
export type Channel_Playlist_Avg_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "channel_playlist". All fields are combined with a logical 'AND'. */
export type Channel_Playlist_Bool_Exp = {
  _and?: Maybe<Array<Channel_Playlist_Bool_Exp>>;
  _not?: Maybe<Channel_Playlist_Bool_Exp>;
  _or?: Maybe<Array<Channel_Playlist_Bool_Exp>>;
  channel?: Maybe<Channel_Bool_Exp>;
  channel_id?: Maybe<Int_Comparison_Exp>;
  channel_playlist_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  playlist?: Maybe<Playlist_Bool_Exp>;
  playlist_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_playlist" */
export enum Channel_Playlist_Constraint {
  /** unique or primary key constraint */
  ChannelPlaylistChannelIdPlaylistIdKey = 'channel_playlist_channel_id_playlist_id_key',
  /** unique or primary key constraint */
  ChannelPlaylistPkey = 'channel_playlist_pkey'
}

/** input type for incrementing numeric columns in table "channel_playlist" */
export type Channel_Playlist_Inc_Input = {
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "channel_playlist" */
export type Channel_Playlist_Insert_Input = {
  channel?: Maybe<Channel_Obj_Rel_Insert_Input>;
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist?: Maybe<Playlist_Obj_Rel_Insert_Input>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Channel_Playlist_Max_Fields = {
  __typename?: 'channel_playlist_max_fields';
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "channel_playlist" */
export type Channel_Playlist_Max_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Playlist_Min_Fields = {
  __typename?: 'channel_playlist_min_fields';
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "channel_playlist" */
export type Channel_Playlist_Min_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "channel_playlist" */
export type Channel_Playlist_Mutation_Response = {
  __typename?: 'channel_playlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Playlist>;
};

/** on_conflict condition type for table "channel_playlist" */
export type Channel_Playlist_On_Conflict = {
  constraint: Channel_Playlist_Constraint;
  update_columns?: Array<Channel_Playlist_Update_Column>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_playlist". */
export type Channel_Playlist_Order_By = {
  channel?: Maybe<Channel_Order_By>;
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  playlist?: Maybe<Playlist_Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: channel_playlist */
export type Channel_Playlist_Pk_Columns_Input = {
  channel_playlist_id: Scalars['Int'];
};

/** select columns of table "channel_playlist" */
export enum Channel_Playlist_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChannelPlaylistId = 'channel_playlist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "channel_playlist" */
export type Channel_Playlist_Set_Input = {
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Channel_Playlist_Stddev_Fields = {
  __typename?: 'channel_playlist_stddev_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "channel_playlist" */
export type Channel_Playlist_Stddev_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Channel_Playlist_Stddev_Pop_Fields = {
  __typename?: 'channel_playlist_stddev_pop_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "channel_playlist" */
export type Channel_Playlist_Stddev_Pop_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Channel_Playlist_Stddev_Samp_Fields = {
  __typename?: 'channel_playlist_stddev_samp_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "channel_playlist" */
export type Channel_Playlist_Stddev_Samp_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Channel_Playlist_Sum_Fields = {
  __typename?: 'channel_playlist_sum_fields';
  channel_id?: Maybe<Scalars['Int']>;
  channel_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "channel_playlist" */
export type Channel_Playlist_Sum_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** update columns of table "channel_playlist" */
export enum Channel_Playlist_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChannelPlaylistId = 'channel_playlist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Channel_Playlist_Var_Pop_Fields = {
  __typename?: 'channel_playlist_var_pop_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "channel_playlist" */
export type Channel_Playlist_Var_Pop_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Channel_Playlist_Var_Samp_Fields = {
  __typename?: 'channel_playlist_var_samp_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "channel_playlist" */
export type Channel_Playlist_Var_Samp_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Channel_Playlist_Variance_Fields = {
  __typename?: 'channel_playlist_variance_fields';
  channel_id?: Maybe<Scalars['Float']>;
  channel_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "channel_playlist" */
export type Channel_Playlist_Variance_Order_By = {
  channel_id?: Maybe<Order_By>;
  channel_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** select columns of table "channel" */
export enum Channel_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  BannerDescription = 'banner_description',
  /** column name */
  BannerImagePath = 'banner_image_path',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  HeadingDescription = 'heading_description',
  /** column name */
  HeadingShortDescription = 'heading_short_description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  LogoImagePath = 'logo_image_path',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Order = 'order',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** input type for updating data in table "channel" */
export type Channel_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Channel_Stddev_Fields = {
  __typename?: 'channel_stddev_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Channel_Stddev_Pop_Fields = {
  __typename?: 'channel_stddev_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Channel_Stddev_Samp_Fields = {
  __typename?: 'channel_stddev_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Channel_Sum_Fields = {
  __typename?: 'channel_sum_fields';
  annual_price?: Maybe<Scalars['Int']>;
  channel_id?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "channel" */
export enum Channel_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  BannerDescription = 'banner_description',
  /** column name */
  BannerImagePath = 'banner_image_path',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  HeadingDescription = 'heading_description',
  /** column name */
  HeadingShortDescription = 'heading_short_description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  LogoImagePath = 'logo_image_path',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Order = 'order',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** aggregate var_pop on columns */
export type Channel_Var_Pop_Fields = {
  __typename?: 'channel_var_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Channel_Var_Samp_Fields = {
  __typename?: 'channel_var_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Channel_Variance_Fields = {
  __typename?: 'channel_variance_fields';
  annual_price?: Maybe<Scalars['Float']>;
  channel_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "class" */
export type Class = {
  __typename?: 'class';
  /** An object relationship */
  catalog_item?: Maybe<Catalog_Item>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  class_assignments: Array<Class_Assignment>;
  /** An aggregate relationship */
  class_assignments_aggregate: Class_Assignment_Aggregate;
  class_id: Scalars['Int'];
  /** An array relationship */
  class_lesson_plans: Array<Class_Lesson_Plan>;
  /** An aggregate relationship */
  class_lesson_plans_aggregate: Class_Lesson_Plan_Aggregate;
  /** An array relationship */
  class_modules: Array<Class_Module>;
  /** An aggregate relationship */
  class_modules_aggregate: Class_Module_Aggregate;
  /** An array relationship */
  class_playlists: Array<Class_Playlist>;
  /** An aggregate relationship */
  class_playlists_aggregate: Class_Playlist_Aggregate;
  /** An array relationship */
  class_sequences: Array<Class_Sequence>;
  /** An aggregate relationship */
  class_sequences_aggregate: Class_Sequence_Aggregate;
  /** An array relationship */
  class_songs: Array<Class_Song>;
  /** An aggregate relationship */
  class_songs_aggregate: Class_Song_Aggregate;
  /** An array relationship */
  class_students: Array<Class_Student>;
  /** An aggregate relationship */
  class_students_aggregate: Class_Student_Aggregate;
  /** An array relationship */
  class_teachers: Array<Class_Teacher>;
  /** An aggregate relationship */
  class_teachers_aggregate: Class_Teacher_Aggregate;
  /** An array relationship */
  class_tips: Array<Class_Tip>;
  /** An aggregate relationship */
  class_tips_aggregate: Class_Tip_Aggregate;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  grade?: Maybe<Grade>;
  grade_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  grading_system?: Maybe<Grading_System>;
  grading_system_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  isExternal: Scalars['Boolean'];
  maturity_level: Maturity_Level_Enum;
  /** An object relationship */
  school?: Maybe<School>;
  school_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  skill_level?: Maybe<Skill_Level>;
  skill_level_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  /** A computed field, executes function "total_lessons_in_class" */
  total_lessons_in_class?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wurrlies: Array<Wurrly>;
  /** An aggregate relationship */
  wurrlies_aggregate: Wurrly_Aggregate;
};


/** columns and relationships of "class" */
export type ClassClass_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Lesson_PlansArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Lesson_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_ModulesArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Modules_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_SequencesArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_SongsArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Songs_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_StudentsArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Students_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_TeachersArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Teachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_TipsArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassClass_Tips_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassWurrliesArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassWurrlies_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};

/** aggregated selection of "class" */
export type Class_Aggregate = {
  __typename?: 'class_aggregate';
  aggregate?: Maybe<Class_Aggregate_Fields>;
  nodes: Array<Class>;
};

/** aggregate fields of "class" */
export type Class_Aggregate_Fields = {
  __typename?: 'class_aggregate_fields';
  avg?: Maybe<Class_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Max_Fields>;
  min?: Maybe<Class_Min_Fields>;
  stddev?: Maybe<Class_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Sum_Fields>;
  var_pop?: Maybe<Class_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Var_Samp_Fields>;
  variance?: Maybe<Class_Variance_Fields>;
};


/** aggregate fields of "class" */
export type Class_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class" */
export type Class_Aggregate_Order_By = {
  avg?: Maybe<Class_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Max_Order_By>;
  min?: Maybe<Class_Min_Order_By>;
  stddev?: Maybe<Class_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Sum_Order_By>;
  var_pop?: Maybe<Class_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Var_Samp_Order_By>;
  variance?: Maybe<Class_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class" */
export type Class_Arr_Rel_Insert_Input = {
  data: Array<Class_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_On_Conflict>;
};

/** columns and relationships of "class_assignment" */
export type Class_Assignment = {
  __typename?: 'class_assignment';
  /** An object relationship */
  assignment: Assignment;
  assignment_id: Scalars['Int'];
  /** An object relationship */
  class: Class;
  class_assignment_id: Scalars['Int'];
  class_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Once the teacher finishes grading all the students of the class, this changes to GRADED. DRAFT status means that the assignment is not published. */
  status: Class_Assignment_Status_Enum;
  /** An array relationship */
  submission: Array<Submission>;
  /** An aggregate relationship */
  submission_aggregate: Submission_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "class_assignment" */
export type Class_AssignmentSubmissionArgs = {
  distinct_on?: Maybe<Array<Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Order_By>>;
  where?: Maybe<Submission_Bool_Exp>;
};


/** columns and relationships of "class_assignment" */
export type Class_AssignmentSubmission_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Order_By>>;
  where?: Maybe<Submission_Bool_Exp>;
};

/** aggregated selection of "class_assignment" */
export type Class_Assignment_Aggregate = {
  __typename?: 'class_assignment_aggregate';
  aggregate?: Maybe<Class_Assignment_Aggregate_Fields>;
  nodes: Array<Class_Assignment>;
};

/** aggregate fields of "class_assignment" */
export type Class_Assignment_Aggregate_Fields = {
  __typename?: 'class_assignment_aggregate_fields';
  avg?: Maybe<Class_Assignment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Assignment_Max_Fields>;
  min?: Maybe<Class_Assignment_Min_Fields>;
  stddev?: Maybe<Class_Assignment_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Assignment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Assignment_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Assignment_Sum_Fields>;
  var_pop?: Maybe<Class_Assignment_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Assignment_Var_Samp_Fields>;
  variance?: Maybe<Class_Assignment_Variance_Fields>;
};


/** aggregate fields of "class_assignment" */
export type Class_Assignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_assignment" */
export type Class_Assignment_Aggregate_Order_By = {
  avg?: Maybe<Class_Assignment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Assignment_Max_Order_By>;
  min?: Maybe<Class_Assignment_Min_Order_By>;
  stddev?: Maybe<Class_Assignment_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Assignment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Assignment_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Assignment_Sum_Order_By>;
  var_pop?: Maybe<Class_Assignment_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Assignment_Var_Samp_Order_By>;
  variance?: Maybe<Class_Assignment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_assignment" */
export type Class_Assignment_Arr_Rel_Insert_Input = {
  data: Array<Class_Assignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Assignment_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Assignment_Avg_Fields = {
  __typename?: 'class_assignment_avg_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_assignment" */
export type Class_Assignment_Avg_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_assignment". All fields are combined with a logical 'AND'. */
export type Class_Assignment_Bool_Exp = {
  _and?: Maybe<Array<Class_Assignment_Bool_Exp>>;
  _not?: Maybe<Class_Assignment_Bool_Exp>;
  _or?: Maybe<Array<Class_Assignment_Bool_Exp>>;
  assignment?: Maybe<Assignment_Bool_Exp>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  class?: Maybe<Class_Bool_Exp>;
  class_assignment_id?: Maybe<Int_Comparison_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Class_Assignment_Status_Enum_Comparison_Exp>;
  submission?: Maybe<Submission_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_assignment" */
export enum Class_Assignment_Constraint {
  /** unique or primary key constraint */
  ClassAssignmentClassIdAssignmentIdKey = 'class_assignment_class_id_assignment_id_key',
  /** unique or primary key constraint */
  ClassroomAssignmentPkey = 'classroom_assignment_pkey'
}

/** input type for incrementing numeric columns in table "class_assignment" */
export type Class_Assignment_Inc_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_assignment" */
export type Class_Assignment_Insert_Input = {
  assignment?: Maybe<Assignment_Obj_Rel_Insert_Input>;
  assignment_id?: Maybe<Scalars['Int']>;
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Once the teacher finishes grading all the students of the class, this changes to GRADED. DRAFT status means that the assignment is not published. */
  status?: Maybe<Class_Assignment_Status_Enum>;
  submission?: Maybe<Submission_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Assignment_Max_Fields = {
  __typename?: 'class_assignment_max_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_assignment" */
export type Class_Assignment_Max_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Assignment_Min_Fields = {
  __typename?: 'class_assignment_min_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_assignment" */
export type Class_Assignment_Min_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_assignment" */
export type Class_Assignment_Mutation_Response = {
  __typename?: 'class_assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Assignment>;
};

/** input type for inserting object relation for remote table "class_assignment" */
export type Class_Assignment_Obj_Rel_Insert_Input = {
  data: Class_Assignment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Class_Assignment_On_Conflict>;
};

/** on_conflict condition type for table "class_assignment" */
export type Class_Assignment_On_Conflict = {
  constraint: Class_Assignment_Constraint;
  update_columns?: Array<Class_Assignment_Update_Column>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "class_assignment". */
export type Class_Assignment_Order_By = {
  assignment?: Maybe<Assignment_Order_By>;
  assignment_id?: Maybe<Order_By>;
  class?: Maybe<Class_Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  submission_aggregate?: Maybe<Submission_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_assignment */
export type Class_Assignment_Pk_Columns_Input = {
  class_assignment_id: Scalars['Int'];
};

/** select columns of table "class_assignment" */
export enum Class_Assignment_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  ClassAssignmentId = 'class_assignment_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_assignment" */
export type Class_Assignment_Set_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Once the teacher finishes grading all the students of the class, this changes to GRADED. DRAFT status means that the assignment is not published. */
  status?: Maybe<Class_Assignment_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "class_assignment_status" */
export type Class_Assignment_Status = {
  __typename?: 'class_assignment_status';
  name: Scalars['String'];
};

/** aggregated selection of "class_assignment_status" */
export type Class_Assignment_Status_Aggregate = {
  __typename?: 'class_assignment_status_aggregate';
  aggregate?: Maybe<Class_Assignment_Status_Aggregate_Fields>;
  nodes: Array<Class_Assignment_Status>;
};

/** aggregate fields of "class_assignment_status" */
export type Class_Assignment_Status_Aggregate_Fields = {
  __typename?: 'class_assignment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Assignment_Status_Max_Fields>;
  min?: Maybe<Class_Assignment_Status_Min_Fields>;
};


/** aggregate fields of "class_assignment_status" */
export type Class_Assignment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Assignment_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_assignment_status". All fields are combined with a logical 'AND'. */
export type Class_Assignment_Status_Bool_Exp = {
  _and?: Maybe<Array<Class_Assignment_Status_Bool_Exp>>;
  _not?: Maybe<Class_Assignment_Status_Bool_Exp>;
  _or?: Maybe<Array<Class_Assignment_Status_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_assignment_status" */
export enum Class_Assignment_Status_Constraint {
  /** unique or primary key constraint */
  ClassAssignmentStatusPkey = 'class_assignment_status_pkey'
}

export enum Class_Assignment_Status_Enum {
  Created = 'created',
  Graded = 'graded',
  InProgress = 'in_progress'
}

/** Boolean expression to compare columns of type "class_assignment_status_enum". All fields are combined with logical 'AND'. */
export type Class_Assignment_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Class_Assignment_Status_Enum>;
  _in?: Maybe<Array<Class_Assignment_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Class_Assignment_Status_Enum>;
  _nin?: Maybe<Array<Class_Assignment_Status_Enum>>;
};

/** input type for inserting data into table "class_assignment_status" */
export type Class_Assignment_Status_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Assignment_Status_Max_Fields = {
  __typename?: 'class_assignment_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Class_Assignment_Status_Min_Fields = {
  __typename?: 'class_assignment_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_assignment_status" */
export type Class_Assignment_Status_Mutation_Response = {
  __typename?: 'class_assignment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Assignment_Status>;
};

/** on_conflict condition type for table "class_assignment_status" */
export type Class_Assignment_Status_On_Conflict = {
  constraint: Class_Assignment_Status_Constraint;
  update_columns?: Array<Class_Assignment_Status_Update_Column>;
  where?: Maybe<Class_Assignment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "class_assignment_status". */
export type Class_Assignment_Status_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: class_assignment_status */
export type Class_Assignment_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "class_assignment_status" */
export enum Class_Assignment_Status_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "class_assignment_status" */
export type Class_Assignment_Status_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "class_assignment_status" */
export enum Class_Assignment_Status_Update_Column {
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type Class_Assignment_Stddev_Fields = {
  __typename?: 'class_assignment_stddev_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_assignment" */
export type Class_Assignment_Stddev_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Assignment_Stddev_Pop_Fields = {
  __typename?: 'class_assignment_stddev_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_assignment" */
export type Class_Assignment_Stddev_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Assignment_Stddev_Samp_Fields = {
  __typename?: 'class_assignment_stddev_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_assignment" */
export type Class_Assignment_Stddev_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Assignment_Sum_Fields = {
  __typename?: 'class_assignment_sum_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_assignment" */
export type Class_Assignment_Sum_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** update columns of table "class_assignment" */
export enum Class_Assignment_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  ClassAssignmentId = 'class_assignment_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Assignment_Var_Pop_Fields = {
  __typename?: 'class_assignment_var_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_assignment" */
export type Class_Assignment_Var_Pop_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Assignment_Var_Samp_Fields = {
  __typename?: 'class_assignment_var_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_assignment" */
export type Class_Assignment_Var_Samp_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Assignment_Variance_Fields = {
  __typename?: 'class_assignment_variance_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_assignment" */
export type Class_Assignment_Variance_Order_By = {
  assignment_id?: Maybe<Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Class_Avg_Fields = {
  __typename?: 'class_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class" */
export type Class_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class". All fields are combined with a logical 'AND'. */
export type Class_Bool_Exp = {
  _and?: Maybe<Array<Class_Bool_Exp>>;
  _not?: Maybe<Class_Bool_Exp>;
  _or?: Maybe<Array<Class_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  class_assignments?: Maybe<Class_Assignment_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_lesson_plans?: Maybe<Class_Lesson_Plan_Bool_Exp>;
  class_modules?: Maybe<Class_Module_Bool_Exp>;
  class_playlists?: Maybe<Class_Playlist_Bool_Exp>;
  class_sequences?: Maybe<Class_Sequence_Bool_Exp>;
  class_songs?: Maybe<Class_Song_Bool_Exp>;
  class_students?: Maybe<Class_Student_Bool_Exp>;
  class_teachers?: Maybe<Class_Teacher_Bool_Exp>;
  class_tips?: Maybe<Class_Tip_Bool_Exp>;
  clever_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_date?: Maybe<Timestamptz_Comparison_Exp>;
  grade?: Maybe<Grade_Bool_Exp>;
  grade_id?: Maybe<Int_Comparison_Exp>;
  grading_system?: Maybe<Grading_System_Bool_Exp>;
  grading_system_id?: Maybe<Int_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  integration_id?: Maybe<String_Comparison_Exp>;
  integration_name?: Maybe<Integration_Name_Enum_Comparison_Exp>;
  isExternal?: Maybe<Boolean_Comparison_Exp>;
  maturity_level?: Maybe<Maturity_Level_Enum_Comparison_Exp>;
  school?: Maybe<School_Bool_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
  skill_level?: Maybe<Skill_Level_Bool_Exp>;
  skill_level_id?: Maybe<Int_Comparison_Exp>;
  start_date?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  total_lessons_in_class?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wurrlies?: Maybe<Wurrly_Bool_Exp>;
};

/** unique or primary key constraints on table "class" */
export enum Class_Constraint {
  /** unique or primary key constraint */
  ClassroomPkey = 'classroom_pkey'
}

/** columns and relationships of "class_images" */
export type Class_Images = {
  __typename?: 'class_images';
  class_image_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  image_path: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "class_images" */
export type Class_Images_Aggregate = {
  __typename?: 'class_images_aggregate';
  aggregate?: Maybe<Class_Images_Aggregate_Fields>;
  nodes: Array<Class_Images>;
};

/** aggregate fields of "class_images" */
export type Class_Images_Aggregate_Fields = {
  __typename?: 'class_images_aggregate_fields';
  avg?: Maybe<Class_Images_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Images_Max_Fields>;
  min?: Maybe<Class_Images_Min_Fields>;
  stddev?: Maybe<Class_Images_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Images_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Images_Sum_Fields>;
  var_pop?: Maybe<Class_Images_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Images_Var_Samp_Fields>;
  variance?: Maybe<Class_Images_Variance_Fields>;
};


/** aggregate fields of "class_images" */
export type Class_Images_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Images_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Class_Images_Avg_Fields = {
  __typename?: 'class_images_avg_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "class_images". All fields are combined with a logical 'AND'. */
export type Class_Images_Bool_Exp = {
  _and?: Maybe<Array<Class_Images_Bool_Exp>>;
  _not?: Maybe<Class_Images_Bool_Exp>;
  _or?: Maybe<Array<Class_Images_Bool_Exp>>;
  class_image_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_images" */
export enum Class_Images_Constraint {
  /** unique or primary key constraint */
  ClassImagesPkey = 'class_images_pkey'
}

/** input type for incrementing numeric columns in table "class_images" */
export type Class_Images_Inc_Input = {
  class_image_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_images" */
export type Class_Images_Insert_Input = {
  class_image_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  image_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Images_Max_Fields = {
  __typename?: 'class_images_max_fields';
  class_image_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  image_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Class_Images_Min_Fields = {
  __typename?: 'class_images_min_fields';
  class_image_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  image_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "class_images" */
export type Class_Images_Mutation_Response = {
  __typename?: 'class_images_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Images>;
};

/** on_conflict condition type for table "class_images" */
export type Class_Images_On_Conflict = {
  constraint: Class_Images_Constraint;
  update_columns?: Array<Class_Images_Update_Column>;
  where?: Maybe<Class_Images_Bool_Exp>;
};

/** Ordering options when selecting data from "class_images". */
export type Class_Images_Order_By = {
  class_image_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_images */
export type Class_Images_Pk_Columns_Input = {
  class_image_id: Scalars['Int'];
};

/** select columns of table "class_images" */
export enum Class_Images_Select_Column {
  /** column name */
  ClassImageId = 'class_image_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_images" */
export type Class_Images_Set_Input = {
  class_image_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  image_path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Images_Stddev_Fields = {
  __typename?: 'class_images_stddev_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Class_Images_Stddev_Pop_Fields = {
  __typename?: 'class_images_stddev_pop_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Class_Images_Stddev_Samp_Fields = {
  __typename?: 'class_images_stddev_samp_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Class_Images_Sum_Fields = {
  __typename?: 'class_images_sum_fields';
  class_image_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "class_images" */
export enum Class_Images_Update_Column {
  /** column name */
  ClassImageId = 'class_image_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Images_Var_Pop_Fields = {
  __typename?: 'class_images_var_pop_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Class_Images_Var_Samp_Fields = {
  __typename?: 'class_images_var_samp_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Class_Images_Variance_Fields = {
  __typename?: 'class_images_variance_fields';
  class_image_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "class" */
export type Class_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  grade_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class" */
export type Class_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  class_assignments?: Maybe<Class_Assignment_Arr_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plans?: Maybe<Class_Lesson_Plan_Arr_Rel_Insert_Input>;
  class_modules?: Maybe<Class_Module_Arr_Rel_Insert_Input>;
  class_playlists?: Maybe<Class_Playlist_Arr_Rel_Insert_Input>;
  class_sequences?: Maybe<Class_Sequence_Arr_Rel_Insert_Input>;
  class_songs?: Maybe<Class_Song_Arr_Rel_Insert_Input>;
  class_students?: Maybe<Class_Student_Arr_Rel_Insert_Input>;
  class_teachers?: Maybe<Class_Teacher_Arr_Rel_Insert_Input>;
  class_tips?: Maybe<Class_Tip_Arr_Rel_Insert_Input>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  grade?: Maybe<Grade_Obj_Rel_Insert_Input>;
  grade_id?: Maybe<Scalars['Int']>;
  grading_system?: Maybe<Grading_System_Obj_Rel_Insert_Input>;
  grading_system_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  isExternal?: Maybe<Scalars['Boolean']>;
  maturity_level?: Maybe<Maturity_Level_Enum>;
  school?: Maybe<School_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars['Int']>;
  skill_level?: Maybe<Skill_Level_Obj_Rel_Insert_Input>;
  skill_level_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrlies?: Maybe<Wurrly_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "class_lesson_plan" */
export type Class_Lesson_Plan = {
  __typename?: 'class_lesson_plan';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_lesson_plan_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "class_lesson_plan" */
export type Class_Lesson_Plan_Aggregate = {
  __typename?: 'class_lesson_plan_aggregate';
  aggregate?: Maybe<Class_Lesson_Plan_Aggregate_Fields>;
  nodes: Array<Class_Lesson_Plan>;
};

/** aggregate fields of "class_lesson_plan" */
export type Class_Lesson_Plan_Aggregate_Fields = {
  __typename?: 'class_lesson_plan_aggregate_fields';
  avg?: Maybe<Class_Lesson_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Lesson_Plan_Max_Fields>;
  min?: Maybe<Class_Lesson_Plan_Min_Fields>;
  stddev?: Maybe<Class_Lesson_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Lesson_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Lesson_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Lesson_Plan_Sum_Fields>;
  var_pop?: Maybe<Class_Lesson_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Lesson_Plan_Var_Samp_Fields>;
  variance?: Maybe<Class_Lesson_Plan_Variance_Fields>;
};


/** aggregate fields of "class_lesson_plan" */
export type Class_Lesson_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_lesson_plan" */
export type Class_Lesson_Plan_Aggregate_Order_By = {
  avg?: Maybe<Class_Lesson_Plan_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Lesson_Plan_Max_Order_By>;
  min?: Maybe<Class_Lesson_Plan_Min_Order_By>;
  stddev?: Maybe<Class_Lesson_Plan_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Lesson_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Lesson_Plan_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Lesson_Plan_Sum_Order_By>;
  var_pop?: Maybe<Class_Lesson_Plan_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Lesson_Plan_Var_Samp_Order_By>;
  variance?: Maybe<Class_Lesson_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_lesson_plan" */
export type Class_Lesson_Plan_Arr_Rel_Insert_Input = {
  data: Array<Class_Lesson_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Lesson_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Lesson_Plan_Avg_Fields = {
  __typename?: 'class_lesson_plan_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_lesson_plan". All fields are combined with a logical 'AND'. */
export type Class_Lesson_Plan_Bool_Exp = {
  _and?: Maybe<Array<Class_Lesson_Plan_Bool_Exp>>;
  _not?: Maybe<Class_Lesson_Plan_Bool_Exp>;
  _or?: Maybe<Array<Class_Lesson_Plan_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_lesson_plan" */
export enum Class_Lesson_Plan_Constraint {
  /** unique or primary key constraint */
  ClassLessonPlanLessonPlanIdClassIdKey = 'class_lesson_plan_lesson_plan_id_class_id_key',
  /** unique or primary key constraint */
  ClassLessonPlanPkey = 'class_lesson_plan_pkey'
}

/** input type for incrementing numeric columns in table "class_lesson_plan" */
export type Class_Lesson_Plan_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_lesson_plan" */
export type Class_Lesson_Plan_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plan_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Lesson_Plan_Max_Fields = {
  __typename?: 'class_lesson_plan_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plan_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Lesson_Plan_Min_Fields = {
  __typename?: 'class_lesson_plan_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plan_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_lesson_plan" */
export type Class_Lesson_Plan_Mutation_Response = {
  __typename?: 'class_lesson_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Lesson_Plan>;
};

/** on_conflict condition type for table "class_lesson_plan" */
export type Class_Lesson_Plan_On_Conflict = {
  constraint: Class_Lesson_Plan_Constraint;
  update_columns?: Array<Class_Lesson_Plan_Update_Column>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "class_lesson_plan". */
export type Class_Lesson_Plan_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_lesson_plan */
export type Class_Lesson_Plan_Pk_Columns_Input = {
  class_lesson_plan_id: Scalars['Int'];
};

/** select columns of table "class_lesson_plan" */
export enum Class_Lesson_Plan_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassLessonPlanId = 'class_lesson_plan_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_lesson_plan" */
export type Class_Lesson_Plan_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plan_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Lesson_Plan_Stddev_Fields = {
  __typename?: 'class_lesson_plan_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Lesson_Plan_Stddev_Pop_Fields = {
  __typename?: 'class_lesson_plan_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Lesson_Plan_Stddev_Samp_Fields = {
  __typename?: 'class_lesson_plan_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Lesson_Plan_Sum_Fields = {
  __typename?: 'class_lesson_plan_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "class_lesson_plan" */
export enum Class_Lesson_Plan_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassLessonPlanId = 'class_lesson_plan_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Lesson_Plan_Var_Pop_Fields = {
  __typename?: 'class_lesson_plan_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Lesson_Plan_Var_Samp_Fields = {
  __typename?: 'class_lesson_plan_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Lesson_Plan_Variance_Fields = {
  __typename?: 'class_lesson_plan_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_lesson_plan" */
export type Class_Lesson_Plan_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Class_Max_Fields = {
  __typename?: 'class_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class" */
export type Class_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Min_Fields = {
  __typename?: 'class_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class" */
export type Class_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "class_module" */
export type Class_Module = {
  __typename?: 'class_module';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_module_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  module: Module;
  module_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "class_module" */
export type Class_Module_Aggregate = {
  __typename?: 'class_module_aggregate';
  aggregate?: Maybe<Class_Module_Aggregate_Fields>;
  nodes: Array<Class_Module>;
};

/** aggregate fields of "class_module" */
export type Class_Module_Aggregate_Fields = {
  __typename?: 'class_module_aggregate_fields';
  avg?: Maybe<Class_Module_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Module_Max_Fields>;
  min?: Maybe<Class_Module_Min_Fields>;
  stddev?: Maybe<Class_Module_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Module_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Module_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Module_Sum_Fields>;
  var_pop?: Maybe<Class_Module_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Module_Var_Samp_Fields>;
  variance?: Maybe<Class_Module_Variance_Fields>;
};


/** aggregate fields of "class_module" */
export type Class_Module_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Module_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_module" */
export type Class_Module_Aggregate_Order_By = {
  avg?: Maybe<Class_Module_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Module_Max_Order_By>;
  min?: Maybe<Class_Module_Min_Order_By>;
  stddev?: Maybe<Class_Module_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Module_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Module_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Module_Sum_Order_By>;
  var_pop?: Maybe<Class_Module_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Module_Var_Samp_Order_By>;
  variance?: Maybe<Class_Module_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_module" */
export type Class_Module_Arr_Rel_Insert_Input = {
  data: Array<Class_Module_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Module_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Module_Avg_Fields = {
  __typename?: 'class_module_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_module" */
export type Class_Module_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_module". All fields are combined with a logical 'AND'. */
export type Class_Module_Bool_Exp = {
  _and?: Maybe<Array<Class_Module_Bool_Exp>>;
  _not?: Maybe<Class_Module_Bool_Exp>;
  _or?: Maybe<Array<Class_Module_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_module_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_module" */
export enum Class_Module_Constraint {
  /** unique or primary key constraint */
  ClassModuleClassIdModuleIdKey = 'class_module_class_id_module_id_key',
  /** unique or primary key constraint */
  ClassroomModulePkey = 'classroom_module_pkey'
}

/** input type for incrementing numeric columns in table "class_module" */
export type Class_Module_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_module_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_module" */
export type Class_Module_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_module_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Module_Max_Fields = {
  __typename?: 'class_module_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_module_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_module" */
export type Class_Module_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Module_Min_Fields = {
  __typename?: 'class_module_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_module_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_module" */
export type Class_Module_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_module" */
export type Class_Module_Mutation_Response = {
  __typename?: 'class_module_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Module>;
};

/** on_conflict condition type for table "class_module" */
export type Class_Module_On_Conflict = {
  constraint: Class_Module_Constraint;
  update_columns?: Array<Class_Module_Update_Column>;
  where?: Maybe<Class_Module_Bool_Exp>;
};

/** Ordering options when selecting data from "class_module". */
export type Class_Module_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_module */
export type Class_Module_Pk_Columns_Input = {
  class_module_id: Scalars['Int'];
};

/** select columns of table "class_module" */
export enum Class_Module_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassModuleId = 'class_module_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_module" */
export type Class_Module_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_module_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Module_Stddev_Fields = {
  __typename?: 'class_module_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_module" */
export type Class_Module_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Module_Stddev_Pop_Fields = {
  __typename?: 'class_module_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_module" */
export type Class_Module_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Module_Stddev_Samp_Fields = {
  __typename?: 'class_module_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_module" */
export type Class_Module_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Module_Sum_Fields = {
  __typename?: 'class_module_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_module_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_module" */
export type Class_Module_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** update columns of table "class_module" */
export enum Class_Module_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassModuleId = 'class_module_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Module_Var_Pop_Fields = {
  __typename?: 'class_module_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_module" */
export type Class_Module_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Module_Var_Samp_Fields = {
  __typename?: 'class_module_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_module" */
export type Class_Module_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Module_Variance_Fields = {
  __typename?: 'class_module_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_module_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_module" */
export type Class_Module_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_module_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "class" */
export type Class_Mutation_Response = {
  __typename?: 'class_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class>;
};

/** input type for inserting object relation for remote table "class" */
export type Class_Obj_Rel_Insert_Input = {
  data: Class_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Class_On_Conflict>;
};

/** on_conflict condition type for table "class" */
export type Class_On_Conflict = {
  constraint: Class_Constraint;
  update_columns?: Array<Class_Update_Column>;
  where?: Maybe<Class_Bool_Exp>;
};

/** Ordering options when selecting data from "class". */
export type Class_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  class_assignments_aggregate?: Maybe<Class_Assignment_Aggregate_Order_By>;
  class_id?: Maybe<Order_By>;
  class_lesson_plans_aggregate?: Maybe<Class_Lesson_Plan_Aggregate_Order_By>;
  class_modules_aggregate?: Maybe<Class_Module_Aggregate_Order_By>;
  class_playlists_aggregate?: Maybe<Class_Playlist_Aggregate_Order_By>;
  class_sequences_aggregate?: Maybe<Class_Sequence_Aggregate_Order_By>;
  class_songs_aggregate?: Maybe<Class_Song_Aggregate_Order_By>;
  class_students_aggregate?: Maybe<Class_Student_Aggregate_Order_By>;
  class_teachers_aggregate?: Maybe<Class_Teacher_Aggregate_Order_By>;
  class_tips_aggregate?: Maybe<Class_Tip_Aggregate_Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  grade?: Maybe<Grade_Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system?: Maybe<Grading_System_Order_By>;
  grading_system_id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  integration_name?: Maybe<Order_By>;
  isExternal?: Maybe<Order_By>;
  maturity_level?: Maybe<Order_By>;
  school?: Maybe<School_Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level?: Maybe<Skill_Level_Order_By>;
  skill_level_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_lessons_in_class?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrlies_aggregate?: Maybe<Wurrly_Aggregate_Order_By>;
};

/** primary key columns input for table: class */
export type Class_Pk_Columns_Input = {
  class_id: Scalars['Int'];
};

/** columns and relationships of "class_playlist" */
export type Class_Playlist = {
  __typename?: 'class_playlist';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_playlist_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  playlist: Playlist;
  playlist_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "class_playlist" */
export type Class_Playlist_Aggregate = {
  __typename?: 'class_playlist_aggregate';
  aggregate?: Maybe<Class_Playlist_Aggregate_Fields>;
  nodes: Array<Class_Playlist>;
};

/** aggregate fields of "class_playlist" */
export type Class_Playlist_Aggregate_Fields = {
  __typename?: 'class_playlist_aggregate_fields';
  avg?: Maybe<Class_Playlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Playlist_Max_Fields>;
  min?: Maybe<Class_Playlist_Min_Fields>;
  stddev?: Maybe<Class_Playlist_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Playlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Playlist_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Playlist_Sum_Fields>;
  var_pop?: Maybe<Class_Playlist_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Playlist_Var_Samp_Fields>;
  variance?: Maybe<Class_Playlist_Variance_Fields>;
};


/** aggregate fields of "class_playlist" */
export type Class_Playlist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Playlist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_playlist" */
export type Class_Playlist_Aggregate_Order_By = {
  avg?: Maybe<Class_Playlist_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Playlist_Max_Order_By>;
  min?: Maybe<Class_Playlist_Min_Order_By>;
  stddev?: Maybe<Class_Playlist_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Playlist_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Playlist_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Playlist_Sum_Order_By>;
  var_pop?: Maybe<Class_Playlist_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Playlist_Var_Samp_Order_By>;
  variance?: Maybe<Class_Playlist_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_playlist" */
export type Class_Playlist_Arr_Rel_Insert_Input = {
  data: Array<Class_Playlist_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Playlist_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Playlist_Avg_Fields = {
  __typename?: 'class_playlist_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_playlist" */
export type Class_Playlist_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_playlist". All fields are combined with a logical 'AND'. */
export type Class_Playlist_Bool_Exp = {
  _and?: Maybe<Array<Class_Playlist_Bool_Exp>>;
  _not?: Maybe<Class_Playlist_Bool_Exp>;
  _or?: Maybe<Array<Class_Playlist_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_playlist_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  playlist?: Maybe<Playlist_Bool_Exp>;
  playlist_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_playlist" */
export enum Class_Playlist_Constraint {
  /** unique or primary key constraint */
  ClassPlaylistClassroomIdPlaylistIdKey = 'class_playlist_classroom_id_playlist_id_key',
  /** unique or primary key constraint */
  ClassroomPlaylistPkey = 'classroom_playlist_pkey'
}

/** input type for incrementing numeric columns in table "class_playlist" */
export type Class_Playlist_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_playlist" */
export type Class_Playlist_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist?: Maybe<Playlist_Obj_Rel_Insert_Input>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Playlist_Max_Fields = {
  __typename?: 'class_playlist_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_playlist" */
export type Class_Playlist_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Playlist_Min_Fields = {
  __typename?: 'class_playlist_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_playlist" */
export type Class_Playlist_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_playlist" */
export type Class_Playlist_Mutation_Response = {
  __typename?: 'class_playlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Playlist>;
};

/** on_conflict condition type for table "class_playlist" */
export type Class_Playlist_On_Conflict = {
  constraint: Class_Playlist_Constraint;
  update_columns?: Array<Class_Playlist_Update_Column>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};

/** Ordering options when selecting data from "class_playlist". */
export type Class_Playlist_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  playlist?: Maybe<Playlist_Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_playlist */
export type Class_Playlist_Pk_Columns_Input = {
  class_playlist_id: Scalars['Int'];
};

/** select columns of table "class_playlist" */
export enum Class_Playlist_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassPlaylistId = 'class_playlist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_playlist" */
export type Class_Playlist_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_playlist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Playlist_Stddev_Fields = {
  __typename?: 'class_playlist_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_playlist" */
export type Class_Playlist_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Playlist_Stddev_Pop_Fields = {
  __typename?: 'class_playlist_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_playlist" */
export type Class_Playlist_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Playlist_Stddev_Samp_Fields = {
  __typename?: 'class_playlist_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_playlist" */
export type Class_Playlist_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Playlist_Sum_Fields = {
  __typename?: 'class_playlist_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_playlist" */
export type Class_Playlist_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** update columns of table "class_playlist" */
export enum Class_Playlist_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassPlaylistId = 'class_playlist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Playlist_Var_Pop_Fields = {
  __typename?: 'class_playlist_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_playlist" */
export type Class_Playlist_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Playlist_Var_Samp_Fields = {
  __typename?: 'class_playlist_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_playlist" */
export type Class_Playlist_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Playlist_Variance_Fields = {
  __typename?: 'class_playlist_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_playlist" */
export type Class_Playlist_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** select columns of table "class" */
export enum Class_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  GradingSystemId = 'grading_system_id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsExternal = 'isExternal',
  /** column name */
  MaturityLevel = 'maturity_level',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "class_sequence" */
export type Class_Sequence = {
  __typename?: 'class_sequence';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_sequence_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  sequence: Sequence;
  sequence_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "class_sequence" */
export type Class_Sequence_Aggregate = {
  __typename?: 'class_sequence_aggregate';
  aggregate?: Maybe<Class_Sequence_Aggregate_Fields>;
  nodes: Array<Class_Sequence>;
};

/** aggregate fields of "class_sequence" */
export type Class_Sequence_Aggregate_Fields = {
  __typename?: 'class_sequence_aggregate_fields';
  avg?: Maybe<Class_Sequence_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Sequence_Max_Fields>;
  min?: Maybe<Class_Sequence_Min_Fields>;
  stddev?: Maybe<Class_Sequence_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Sequence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Sequence_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Sequence_Sum_Fields>;
  var_pop?: Maybe<Class_Sequence_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Sequence_Var_Samp_Fields>;
  variance?: Maybe<Class_Sequence_Variance_Fields>;
};


/** aggregate fields of "class_sequence" */
export type Class_Sequence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Sequence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_sequence" */
export type Class_Sequence_Aggregate_Order_By = {
  avg?: Maybe<Class_Sequence_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Sequence_Max_Order_By>;
  min?: Maybe<Class_Sequence_Min_Order_By>;
  stddev?: Maybe<Class_Sequence_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Sequence_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Sequence_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Sequence_Sum_Order_By>;
  var_pop?: Maybe<Class_Sequence_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Sequence_Var_Samp_Order_By>;
  variance?: Maybe<Class_Sequence_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_sequence" */
export type Class_Sequence_Arr_Rel_Insert_Input = {
  data: Array<Class_Sequence_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Sequence_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Sequence_Avg_Fields = {
  __typename?: 'class_sequence_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_sequence" */
export type Class_Sequence_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_sequence". All fields are combined with a logical 'AND'. */
export type Class_Sequence_Bool_Exp = {
  _and?: Maybe<Array<Class_Sequence_Bool_Exp>>;
  _not?: Maybe<Class_Sequence_Bool_Exp>;
  _or?: Maybe<Array<Class_Sequence_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_sequence_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  sequence?: Maybe<Sequence_Bool_Exp>;
  sequence_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_sequence" */
export enum Class_Sequence_Constraint {
  /** unique or primary key constraint */
  ClassSequenceClassIdSequenceIdKey = 'class_sequence_class_id_sequence_id_key',
  /** unique or primary key constraint */
  ClassroomSequencePkey = 'classroom_sequence_pkey'
}

/** input type for incrementing numeric columns in table "class_sequence" */
export type Class_Sequence_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_sequence_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_sequence" */
export type Class_Sequence_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_sequence_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence?: Maybe<Sequence_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Sequence_Max_Fields = {
  __typename?: 'class_sequence_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_sequence_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_sequence" */
export type Class_Sequence_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Sequence_Min_Fields = {
  __typename?: 'class_sequence_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_sequence_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_sequence" */
export type Class_Sequence_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_sequence" */
export type Class_Sequence_Mutation_Response = {
  __typename?: 'class_sequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Sequence>;
};

/** on_conflict condition type for table "class_sequence" */
export type Class_Sequence_On_Conflict = {
  constraint: Class_Sequence_Constraint;
  update_columns?: Array<Class_Sequence_Update_Column>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};

/** Ordering options when selecting data from "class_sequence". */
export type Class_Sequence_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sequence?: Maybe<Sequence_Order_By>;
  sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_sequence */
export type Class_Sequence_Pk_Columns_Input = {
  class_sequence_id: Scalars['Int'];
};

/** select columns of table "class_sequence" */
export enum Class_Sequence_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassSequenceId = 'class_sequence_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_sequence" */
export type Class_Sequence_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_sequence_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Sequence_Stddev_Fields = {
  __typename?: 'class_sequence_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_sequence" */
export type Class_Sequence_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Sequence_Stddev_Pop_Fields = {
  __typename?: 'class_sequence_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_sequence" */
export type Class_Sequence_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Sequence_Stddev_Samp_Fields = {
  __typename?: 'class_sequence_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_sequence" */
export type Class_Sequence_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Sequence_Sum_Fields = {
  __typename?: 'class_sequence_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_sequence_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_sequence" */
export type Class_Sequence_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** update columns of table "class_sequence" */
export enum Class_Sequence_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassSequenceId = 'class_sequence_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Sequence_Var_Pop_Fields = {
  __typename?: 'class_sequence_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_sequence" */
export type Class_Sequence_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Sequence_Var_Samp_Fields = {
  __typename?: 'class_sequence_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_sequence" */
export type Class_Sequence_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Sequence_Variance_Fields = {
  __typename?: 'class_sequence_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_sequence_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_sequence" */
export type Class_Sequence_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_sequence_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
};

/** input type for updating data in table "class" */
export type Class_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  isExternal?: Maybe<Scalars['Boolean']>;
  maturity_level?: Maybe<Maturity_Level_Enum>;
  school_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "class_song" */
export type Class_Song = {
  __typename?: 'class_song';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_song_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "class_song" */
export type Class_Song_Aggregate = {
  __typename?: 'class_song_aggregate';
  aggregate?: Maybe<Class_Song_Aggregate_Fields>;
  nodes: Array<Class_Song>;
};

/** aggregate fields of "class_song" */
export type Class_Song_Aggregate_Fields = {
  __typename?: 'class_song_aggregate_fields';
  avg?: Maybe<Class_Song_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Song_Max_Fields>;
  min?: Maybe<Class_Song_Min_Fields>;
  stddev?: Maybe<Class_Song_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Song_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Song_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Song_Sum_Fields>;
  var_pop?: Maybe<Class_Song_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Song_Var_Samp_Fields>;
  variance?: Maybe<Class_Song_Variance_Fields>;
};


/** aggregate fields of "class_song" */
export type Class_Song_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Song_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_song" */
export type Class_Song_Aggregate_Order_By = {
  avg?: Maybe<Class_Song_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Song_Max_Order_By>;
  min?: Maybe<Class_Song_Min_Order_By>;
  stddev?: Maybe<Class_Song_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Song_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Song_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Song_Sum_Order_By>;
  var_pop?: Maybe<Class_Song_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Song_Var_Samp_Order_By>;
  variance?: Maybe<Class_Song_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_song" */
export type Class_Song_Arr_Rel_Insert_Input = {
  data: Array<Class_Song_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Song_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Song_Avg_Fields = {
  __typename?: 'class_song_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_song" */
export type Class_Song_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_song". All fields are combined with a logical 'AND'. */
export type Class_Song_Bool_Exp = {
  _and?: Maybe<Array<Class_Song_Bool_Exp>>;
  _not?: Maybe<Class_Song_Bool_Exp>;
  _or?: Maybe<Array<Class_Song_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_song_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_song" */
export enum Class_Song_Constraint {
  /** unique or primary key constraint */
  ClassSongClassIdSongIdKey = 'class_song_class_id_song_id_key',
  /** unique or primary key constraint */
  ClassSongPkey = 'class_song_pkey'
}

/** input type for incrementing numeric columns in table "class_song" */
export type Class_Song_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_song" */
export type Class_Song_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Song_Max_Fields = {
  __typename?: 'class_song_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_song" */
export type Class_Song_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Song_Min_Fields = {
  __typename?: 'class_song_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_song" */
export type Class_Song_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_song" */
export type Class_Song_Mutation_Response = {
  __typename?: 'class_song_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Song>;
};

/** on_conflict condition type for table "class_song" */
export type Class_Song_On_Conflict = {
  constraint: Class_Song_Constraint;
  update_columns?: Array<Class_Song_Update_Column>;
  where?: Maybe<Class_Song_Bool_Exp>;
};

/** Ordering options when selecting data from "class_song". */
export type Class_Song_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_song */
export type Class_Song_Pk_Columns_Input = {
  class_song_id: Scalars['Int'];
};

/** select columns of table "class_song" */
export enum Class_Song_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassSongId = 'class_song_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_song" */
export type Class_Song_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_song_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Song_Stddev_Fields = {
  __typename?: 'class_song_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_song" */
export type Class_Song_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Song_Stddev_Pop_Fields = {
  __typename?: 'class_song_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_song" */
export type Class_Song_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Song_Stddev_Samp_Fields = {
  __typename?: 'class_song_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_song" */
export type Class_Song_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Song_Sum_Fields = {
  __typename?: 'class_song_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_song" */
export type Class_Song_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** update columns of table "class_song" */
export enum Class_Song_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassSongId = 'class_song_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Song_Var_Pop_Fields = {
  __typename?: 'class_song_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_song" */
export type Class_Song_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Song_Var_Samp_Fields = {
  __typename?: 'class_song_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_song" */
export type Class_Song_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Song_Variance_Fields = {
  __typename?: 'class_song_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_song" */
export type Class_Song_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Class_Stddev_Fields = {
  __typename?: 'class_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class" */
export type Class_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Stddev_Pop_Fields = {
  __typename?: 'class_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class" */
export type Class_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Stddev_Samp_Fields = {
  __typename?: 'class_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class" */
export type Class_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** columns and relationships of "class_student" */
export type Class_Student = {
  __typename?: 'class_student';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_student_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  student: Student;
  student_id: Scalars['Int'];
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "class_student_activities" */
export type Class_Student_Activities = {
  __typename?: 'class_student_activities';
  class_id: Scalars['Int'];
  class_student_activities_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  image_path?: Maybe<Scalars['String']>;
  prefix: Scalars['String'];
  resource_path?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "class_student_activities" */
export type Class_Student_Activities_Aggregate = {
  __typename?: 'class_student_activities_aggregate';
  aggregate?: Maybe<Class_Student_Activities_Aggregate_Fields>;
  nodes: Array<Class_Student_Activities>;
};

/** aggregate fields of "class_student_activities" */
export type Class_Student_Activities_Aggregate_Fields = {
  __typename?: 'class_student_activities_aggregate_fields';
  avg?: Maybe<Class_Student_Activities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Student_Activities_Max_Fields>;
  min?: Maybe<Class_Student_Activities_Min_Fields>;
  stddev?: Maybe<Class_Student_Activities_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Student_Activities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Student_Activities_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Student_Activities_Sum_Fields>;
  var_pop?: Maybe<Class_Student_Activities_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Student_Activities_Var_Samp_Fields>;
  variance?: Maybe<Class_Student_Activities_Variance_Fields>;
};


/** aggregate fields of "class_student_activities" */
export type Class_Student_Activities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Student_Activities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Class_Student_Activities_Avg_Fields = {
  __typename?: 'class_student_activities_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "class_student_activities". All fields are combined with a logical 'AND'. */
export type Class_Student_Activities_Bool_Exp = {
  _and?: Maybe<Array<Class_Student_Activities_Bool_Exp>>;
  _not?: Maybe<Class_Student_Activities_Bool_Exp>;
  _or?: Maybe<Array<Class_Student_Activities_Bool_Exp>>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_student_activities_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  prefix?: Maybe<String_Comparison_Exp>;
  resource_path?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_student_activities" */
export enum Class_Student_Activities_Constraint {
  /** unique or primary key constraint */
  ClassStudentActivitiesPkey = 'class_student_activities_pkey'
}

/** input type for incrementing numeric columns in table "class_student_activities" */
export type Class_Student_Activities_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_student_activities_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_student_activities" */
export type Class_Student_Activities_Insert_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_student_activities_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Student_Activities_Max_Fields = {
  __typename?: 'class_student_activities_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_student_activities_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Class_Student_Activities_Min_Fields = {
  __typename?: 'class_student_activities_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_student_activities_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "class_student_activities" */
export type Class_Student_Activities_Mutation_Response = {
  __typename?: 'class_student_activities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Student_Activities>;
};

/** on_conflict condition type for table "class_student_activities" */
export type Class_Student_Activities_On_Conflict = {
  constraint: Class_Student_Activities_Constraint;
  update_columns?: Array<Class_Student_Activities_Update_Column>;
  where?: Maybe<Class_Student_Activities_Bool_Exp>;
};

/** Ordering options when selecting data from "class_student_activities". */
export type Class_Student_Activities_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_activities_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  prefix?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_student_activities */
export type Class_Student_Activities_Pk_Columns_Input = {
  class_student_activities_id: Scalars['Int'];
};

/** select columns of table "class_student_activities" */
export enum Class_Student_Activities_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassStudentActivitiesId = 'class_student_activities_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_student_activities" */
export type Class_Student_Activities_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_student_activities_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Student_Activities_Stddev_Fields = {
  __typename?: 'class_student_activities_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Class_Student_Activities_Stddev_Pop_Fields = {
  __typename?: 'class_student_activities_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Class_Student_Activities_Stddev_Samp_Fields = {
  __typename?: 'class_student_activities_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Class_Student_Activities_Sum_Fields = {
  __typename?: 'class_student_activities_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_student_activities_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "class_student_activities" */
export enum Class_Student_Activities_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassStudentActivitiesId = 'class_student_activities_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Student_Activities_Var_Pop_Fields = {
  __typename?: 'class_student_activities_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Class_Student_Activities_Var_Samp_Fields = {
  __typename?: 'class_student_activities_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Class_Student_Activities_Variance_Fields = {
  __typename?: 'class_student_activities_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_activities_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "class_student" */
export type Class_Student_Aggregate = {
  __typename?: 'class_student_aggregate';
  aggregate?: Maybe<Class_Student_Aggregate_Fields>;
  nodes: Array<Class_Student>;
};

/** aggregate fields of "class_student" */
export type Class_Student_Aggregate_Fields = {
  __typename?: 'class_student_aggregate_fields';
  avg?: Maybe<Class_Student_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Student_Max_Fields>;
  min?: Maybe<Class_Student_Min_Fields>;
  stddev?: Maybe<Class_Student_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Student_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Student_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Student_Sum_Fields>;
  var_pop?: Maybe<Class_Student_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Student_Var_Samp_Fields>;
  variance?: Maybe<Class_Student_Variance_Fields>;
};


/** aggregate fields of "class_student" */
export type Class_Student_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Student_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_student" */
export type Class_Student_Aggregate_Order_By = {
  avg?: Maybe<Class_Student_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Student_Max_Order_By>;
  min?: Maybe<Class_Student_Min_Order_By>;
  stddev?: Maybe<Class_Student_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Student_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Student_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Student_Sum_Order_By>;
  var_pop?: Maybe<Class_Student_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Student_Var_Samp_Order_By>;
  variance?: Maybe<Class_Student_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_student" */
export type Class_Student_Arr_Rel_Insert_Input = {
  data: Array<Class_Student_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Student_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Student_Avg_Fields = {
  __typename?: 'class_student_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_student" */
export type Class_Student_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_student". All fields are combined with a logical 'AND'. */
export type Class_Student_Bool_Exp = {
  _and?: Maybe<Array<Class_Student_Bool_Exp>>;
  _not?: Maybe<Class_Student_Bool_Exp>;
  _or?: Maybe<Array<Class_Student_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_student_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  teacher_integration_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_student" */
export enum Class_Student_Constraint {
  /** unique or primary key constraint */
  ClassStudentClassIdStudentIdKey = 'class_student_class_id_student_id_key',
  /** unique or primary key constraint */
  ClassStudentPkey = 'class_student_pkey'
}

/** input type for incrementing numeric columns in table "class_student" */
export type Class_Student_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_student_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_student" */
export type Class_Student_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_student_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Student_Max_Fields = {
  __typename?: 'class_student_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_student_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_student" */
export type Class_Student_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Student_Min_Fields = {
  __typename?: 'class_student_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_student_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_student" */
export type Class_Student_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_student" */
export type Class_Student_Mutation_Response = {
  __typename?: 'class_student_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Student>;
};

/** on_conflict condition type for table "class_student" */
export type Class_Student_On_Conflict = {
  constraint: Class_Student_Constraint;
  update_columns?: Array<Class_Student_Update_Column>;
  where?: Maybe<Class_Student_Bool_Exp>;
};

/** Ordering options when selecting data from "class_student". */
export type Class_Student_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_student */
export type Class_Student_Pk_Columns_Input = {
  class_student_id: Scalars['Int'];
};

/** select columns of table "class_student" */
export enum Class_Student_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassStudentId = 'class_student_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherIntegrationId = 'teacher_integration_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_student" */
export type Class_Student_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_student_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Student_Stddev_Fields = {
  __typename?: 'class_student_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_student" */
export type Class_Student_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Student_Stddev_Pop_Fields = {
  __typename?: 'class_student_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_student" */
export type Class_Student_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Student_Stddev_Samp_Fields = {
  __typename?: 'class_student_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_student" */
export type Class_Student_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Student_Sum_Fields = {
  __typename?: 'class_student_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_student_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_student" */
export type Class_Student_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** update columns of table "class_student" */
export enum Class_Student_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassStudentId = 'class_student_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherIntegrationId = 'teacher_integration_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Student_Var_Pop_Fields = {
  __typename?: 'class_student_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_student" */
export type Class_Student_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Student_Var_Samp_Fields = {
  __typename?: 'class_student_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_student" */
export type Class_Student_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Student_Variance_Fields = {
  __typename?: 'class_student_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_student_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_student" */
export type Class_Student_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_student_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Sum_Fields = {
  __typename?: 'class_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  grade_id?: Maybe<Scalars['Int']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class" */
export type Class_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** columns and relationships of "class_teacher" */
export type Class_Teacher = {
  __typename?: 'class_teacher';
  active: Scalars['Boolean'];
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_teacher_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  teacher: Teacher;
  teacher_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "class_teacher" */
export type Class_Teacher_Aggregate = {
  __typename?: 'class_teacher_aggregate';
  aggregate?: Maybe<Class_Teacher_Aggregate_Fields>;
  nodes: Array<Class_Teacher>;
};

/** aggregate fields of "class_teacher" */
export type Class_Teacher_Aggregate_Fields = {
  __typename?: 'class_teacher_aggregate_fields';
  avg?: Maybe<Class_Teacher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Teacher_Max_Fields>;
  min?: Maybe<Class_Teacher_Min_Fields>;
  stddev?: Maybe<Class_Teacher_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Teacher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Teacher_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Teacher_Sum_Fields>;
  var_pop?: Maybe<Class_Teacher_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Teacher_Var_Samp_Fields>;
  variance?: Maybe<Class_Teacher_Variance_Fields>;
};


/** aggregate fields of "class_teacher" */
export type Class_Teacher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Teacher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_teacher" */
export type Class_Teacher_Aggregate_Order_By = {
  avg?: Maybe<Class_Teacher_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Teacher_Max_Order_By>;
  min?: Maybe<Class_Teacher_Min_Order_By>;
  stddev?: Maybe<Class_Teacher_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Teacher_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Teacher_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Teacher_Sum_Order_By>;
  var_pop?: Maybe<Class_Teacher_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Teacher_Var_Samp_Order_By>;
  variance?: Maybe<Class_Teacher_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_teacher" */
export type Class_Teacher_Arr_Rel_Insert_Input = {
  data: Array<Class_Teacher_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Teacher_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Teacher_Avg_Fields = {
  __typename?: 'class_teacher_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_teacher" */
export type Class_Teacher_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_teacher". All fields are combined with a logical 'AND'. */
export type Class_Teacher_Bool_Exp = {
  _and?: Maybe<Array<Class_Teacher_Bool_Exp>>;
  _not?: Maybe<Class_Teacher_Bool_Exp>;
  _or?: Maybe<Array<Class_Teacher_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_teacher_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_teacher" */
export enum Class_Teacher_Constraint {
  /** unique or primary key constraint */
  ClassTeacherClassIdTeacherIdKey = 'class_teacher_class_id_teacher_id_key',
  /** unique or primary key constraint */
  ClassTeacherPkey = 'class_teacher_pkey'
}

/** input type for incrementing numeric columns in table "class_teacher" */
export type Class_Teacher_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_teacher" */
export type Class_Teacher_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Teacher_Max_Fields = {
  __typename?: 'class_teacher_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_teacher" */
export type Class_Teacher_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Teacher_Min_Fields = {
  __typename?: 'class_teacher_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_teacher" */
export type Class_Teacher_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_teacher" */
export type Class_Teacher_Mutation_Response = {
  __typename?: 'class_teacher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Teacher>;
};

/** on_conflict condition type for table "class_teacher" */
export type Class_Teacher_On_Conflict = {
  constraint: Class_Teacher_Constraint;
  update_columns?: Array<Class_Teacher_Update_Column>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};

/** Ordering options when selecting data from "class_teacher". */
export type Class_Teacher_Order_By = {
  active?: Maybe<Order_By>;
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_teacher */
export type Class_Teacher_Pk_Columns_Input = {
  class_teacher_id: Scalars['Int'];
};

/** select columns of table "class_teacher" */
export enum Class_Teacher_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassTeacherId = 'class_teacher_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_teacher" */
export type Class_Teacher_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  class_id?: Maybe<Scalars['Int']>;
  class_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Teacher_Stddev_Fields = {
  __typename?: 'class_teacher_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_teacher" */
export type Class_Teacher_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Teacher_Stddev_Pop_Fields = {
  __typename?: 'class_teacher_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_teacher" */
export type Class_Teacher_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Teacher_Stddev_Samp_Fields = {
  __typename?: 'class_teacher_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_teacher" */
export type Class_Teacher_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Teacher_Sum_Fields = {
  __typename?: 'class_teacher_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_teacher" */
export type Class_Teacher_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** update columns of table "class_teacher" */
export enum Class_Teacher_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassTeacherId = 'class_teacher_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Teacher_Var_Pop_Fields = {
  __typename?: 'class_teacher_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_teacher" */
export type Class_Teacher_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Teacher_Var_Samp_Fields = {
  __typename?: 'class_teacher_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_teacher" */
export type Class_Teacher_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Teacher_Variance_Fields = {
  __typename?: 'class_teacher_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_teacher" */
export type Class_Teacher_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** columns and relationships of "class_tip" */
export type Class_Tip = {
  __typename?: 'class_tip';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  class_tip_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "class_tip" */
export type Class_Tip_Aggregate = {
  __typename?: 'class_tip_aggregate';
  aggregate?: Maybe<Class_Tip_Aggregate_Fields>;
  nodes: Array<Class_Tip>;
};

/** aggregate fields of "class_tip" */
export type Class_Tip_Aggregate_Fields = {
  __typename?: 'class_tip_aggregate_fields';
  avg?: Maybe<Class_Tip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Tip_Max_Fields>;
  min?: Maybe<Class_Tip_Min_Fields>;
  stddev?: Maybe<Class_Tip_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Tip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Tip_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Tip_Sum_Fields>;
  var_pop?: Maybe<Class_Tip_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Tip_Var_Samp_Fields>;
  variance?: Maybe<Class_Tip_Variance_Fields>;
};


/** aggregate fields of "class_tip" */
export type Class_Tip_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Tip_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_tip" */
export type Class_Tip_Aggregate_Order_By = {
  avg?: Maybe<Class_Tip_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Tip_Max_Order_By>;
  min?: Maybe<Class_Tip_Min_Order_By>;
  stddev?: Maybe<Class_Tip_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Tip_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Tip_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Tip_Sum_Order_By>;
  var_pop?: Maybe<Class_Tip_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Tip_Var_Samp_Order_By>;
  variance?: Maybe<Class_Tip_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_tip" */
export type Class_Tip_Arr_Rel_Insert_Input = {
  data: Array<Class_Tip_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Tip_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Tip_Avg_Fields = {
  __typename?: 'class_tip_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_tip" */
export type Class_Tip_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_tip". All fields are combined with a logical 'AND'. */
export type Class_Tip_Bool_Exp = {
  _and?: Maybe<Array<Class_Tip_Bool_Exp>>;
  _not?: Maybe<Class_Tip_Bool_Exp>;
  _or?: Maybe<Array<Class_Tip_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  class_tip_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_tip" */
export enum Class_Tip_Constraint {
  /** unique or primary key constraint */
  ClassTipClassIdTipIdKey = 'class_tip_class_id_tip_id_key',
  /** unique or primary key constraint */
  ClassroomTipPkey = 'classroom_tip_pkey'
}

/** input type for incrementing numeric columns in table "class_tip" */
export type Class_Tip_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_tip" */
export type Class_Tip_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  class_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Tip_Max_Fields = {
  __typename?: 'class_tip_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_tip" */
export type Class_Tip_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Tip_Min_Fields = {
  __typename?: 'class_tip_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_tip" */
export type Class_Tip_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_tip" */
export type Class_Tip_Mutation_Response = {
  __typename?: 'class_tip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Tip>;
};

/** on_conflict condition type for table "class_tip" */
export type Class_Tip_On_Conflict = {
  constraint: Class_Tip_Constraint;
  update_columns?: Array<Class_Tip_Update_Column>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};

/** Ordering options when selecting data from "class_tip". */
export type Class_Tip_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_tip */
export type Class_Tip_Pk_Columns_Input = {
  class_tip_id: Scalars['Int'];
};

/** select columns of table "class_tip" */
export enum Class_Tip_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassTipId = 'class_tip_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_tip" */
export type Class_Tip_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  class_tip_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Tip_Stddev_Fields = {
  __typename?: 'class_tip_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_tip" */
export type Class_Tip_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Tip_Stddev_Pop_Fields = {
  __typename?: 'class_tip_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_tip" */
export type Class_Tip_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Tip_Stddev_Samp_Fields = {
  __typename?: 'class_tip_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_tip" */
export type Class_Tip_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Tip_Sum_Fields = {
  __typename?: 'class_tip_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  class_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_tip" */
export type Class_Tip_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "class_tip" */
export enum Class_Tip_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  ClassTipId = 'class_tip_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Tip_Var_Pop_Fields = {
  __typename?: 'class_tip_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_tip" */
export type Class_Tip_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Tip_Var_Samp_Fields = {
  __typename?: 'class_tip_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_tip" */
export type Class_Tip_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Tip_Variance_Fields = {
  __typename?: 'class_tip_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  class_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_tip" */
export type Class_Tip_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  class_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "class" */
export enum Class_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  GradingSystemId = 'grading_system_id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsExternal = 'isExternal',
  /** column name */
  MaturityLevel = 'maturity_level',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Var_Pop_Fields = {
  __typename?: 'class_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class" */
export type Class_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Var_Samp_Fields = {
  __typename?: 'class_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class" */
export type Class_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Variance_Fields = {
  __typename?: 'class_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  grade_id?: Maybe<Scalars['Float']>;
  grading_system_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class" */
export type Class_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** columns and relationships of "clever_roster_log" */
export type Clever_Roster_Log = {
  __typename?: 'clever_roster_log';
  clever_roster_log_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  details: Scalars['jsonb'];
  status: Clever_Roster_Status_Enum;
  teacher_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "clever_roster_log" */
export type Clever_Roster_LogDetailsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "clever_roster_log" */
export type Clever_Roster_Log_Aggregate = {
  __typename?: 'clever_roster_log_aggregate';
  aggregate?: Maybe<Clever_Roster_Log_Aggregate_Fields>;
  nodes: Array<Clever_Roster_Log>;
};

/** aggregate fields of "clever_roster_log" */
export type Clever_Roster_Log_Aggregate_Fields = {
  __typename?: 'clever_roster_log_aggregate_fields';
  avg?: Maybe<Clever_Roster_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Clever_Roster_Log_Max_Fields>;
  min?: Maybe<Clever_Roster_Log_Min_Fields>;
  stddev?: Maybe<Clever_Roster_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Clever_Roster_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clever_Roster_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Clever_Roster_Log_Sum_Fields>;
  var_pop?: Maybe<Clever_Roster_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Clever_Roster_Log_Var_Samp_Fields>;
  variance?: Maybe<Clever_Roster_Log_Variance_Fields>;
};


/** aggregate fields of "clever_roster_log" */
export type Clever_Roster_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Clever_Roster_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clever_Roster_Log_Append_Input = {
  details?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Clever_Roster_Log_Avg_Fields = {
  __typename?: 'clever_roster_log_avg_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clever_roster_log". All fields are combined with a logical 'AND'. */
export type Clever_Roster_Log_Bool_Exp = {
  _and?: Maybe<Array<Clever_Roster_Log_Bool_Exp>>;
  _not?: Maybe<Clever_Roster_Log_Bool_Exp>;
  _or?: Maybe<Array<Clever_Roster_Log_Bool_Exp>>;
  clever_roster_log_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  details?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Clever_Roster_Status_Enum_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clever_roster_log" */
export enum Clever_Roster_Log_Constraint {
  /** unique or primary key constraint */
  CleverRosterLogPkey = 'clever_roster_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clever_Roster_Log_Delete_At_Path_Input = {
  details?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clever_Roster_Log_Delete_Elem_Input = {
  details?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clever_Roster_Log_Delete_Key_Input = {
  details?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "clever_roster_log" */
export type Clever_Roster_Log_Inc_Input = {
  clever_roster_log_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "clever_roster_log" */
export type Clever_Roster_Log_Insert_Input = {
  clever_roster_log_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Clever_Roster_Status_Enum>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Clever_Roster_Log_Max_Fields = {
  __typename?: 'clever_roster_log_max_fields';
  clever_roster_log_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Clever_Roster_Log_Min_Fields = {
  __typename?: 'clever_roster_log_min_fields';
  clever_roster_log_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "clever_roster_log" */
export type Clever_Roster_Log_Mutation_Response = {
  __typename?: 'clever_roster_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clever_Roster_Log>;
};

/** on_conflict condition type for table "clever_roster_log" */
export type Clever_Roster_Log_On_Conflict = {
  constraint: Clever_Roster_Log_Constraint;
  update_columns?: Array<Clever_Roster_Log_Update_Column>;
  where?: Maybe<Clever_Roster_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "clever_roster_log". */
export type Clever_Roster_Log_Order_By = {
  clever_roster_log_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: clever_roster_log */
export type Clever_Roster_Log_Pk_Columns_Input = {
  clever_roster_log_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clever_Roster_Log_Prepend_Input = {
  details?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "clever_roster_log" */
export enum Clever_Roster_Log_Select_Column {
  /** column name */
  CleverRosterLogId = 'clever_roster_log_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Status = 'status',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "clever_roster_log" */
export type Clever_Roster_Log_Set_Input = {
  clever_roster_log_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Clever_Roster_Status_Enum>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Clever_Roster_Log_Stddev_Fields = {
  __typename?: 'clever_roster_log_stddev_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Clever_Roster_Log_Stddev_Pop_Fields = {
  __typename?: 'clever_roster_log_stddev_pop_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Clever_Roster_Log_Stddev_Samp_Fields = {
  __typename?: 'clever_roster_log_stddev_samp_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Clever_Roster_Log_Sum_Fields = {
  __typename?: 'clever_roster_log_sum_fields';
  clever_roster_log_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "clever_roster_log" */
export enum Clever_Roster_Log_Update_Column {
  /** column name */
  CleverRosterLogId = 'clever_roster_log_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Status = 'status',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Clever_Roster_Log_Var_Pop_Fields = {
  __typename?: 'clever_roster_log_var_pop_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Clever_Roster_Log_Var_Samp_Fields = {
  __typename?: 'clever_roster_log_var_samp_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Clever_Roster_Log_Variance_Fields = {
  __typename?: 'clever_roster_log_variance_fields';
  clever_roster_log_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "clever_roster_status" */
export type Clever_Roster_Status = {
  __typename?: 'clever_roster_status';
  name: Scalars['String'];
};

/** aggregated selection of "clever_roster_status" */
export type Clever_Roster_Status_Aggregate = {
  __typename?: 'clever_roster_status_aggregate';
  aggregate?: Maybe<Clever_Roster_Status_Aggregate_Fields>;
  nodes: Array<Clever_Roster_Status>;
};

/** aggregate fields of "clever_roster_status" */
export type Clever_Roster_Status_Aggregate_Fields = {
  __typename?: 'clever_roster_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clever_Roster_Status_Max_Fields>;
  min?: Maybe<Clever_Roster_Status_Min_Fields>;
};


/** aggregate fields of "clever_roster_status" */
export type Clever_Roster_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Clever_Roster_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "clever_roster_status". All fields are combined with a logical 'AND'. */
export type Clever_Roster_Status_Bool_Exp = {
  _and?: Maybe<Array<Clever_Roster_Status_Bool_Exp>>;
  _not?: Maybe<Clever_Roster_Status_Bool_Exp>;
  _or?: Maybe<Array<Clever_Roster_Status_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clever_roster_status" */
export enum Clever_Roster_Status_Constraint {
  /** unique or primary key constraint */
  CleverRosterStatusPkey = 'clever_roster_status_pkey'
}

export enum Clever_Roster_Status_Enum {
  Done = 'done',
  Error = 'error',
  InProgress = 'in_progress'
}

/** Boolean expression to compare columns of type "clever_roster_status_enum". All fields are combined with logical 'AND'. */
export type Clever_Roster_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Clever_Roster_Status_Enum>;
  _in?: Maybe<Array<Clever_Roster_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Clever_Roster_Status_Enum>;
  _nin?: Maybe<Array<Clever_Roster_Status_Enum>>;
};

/** input type for inserting data into table "clever_roster_status" */
export type Clever_Roster_Status_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clever_Roster_Status_Max_Fields = {
  __typename?: 'clever_roster_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Clever_Roster_Status_Min_Fields = {
  __typename?: 'clever_roster_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "clever_roster_status" */
export type Clever_Roster_Status_Mutation_Response = {
  __typename?: 'clever_roster_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clever_Roster_Status>;
};

/** on_conflict condition type for table "clever_roster_status" */
export type Clever_Roster_Status_On_Conflict = {
  constraint: Clever_Roster_Status_Constraint;
  update_columns?: Array<Clever_Roster_Status_Update_Column>;
  where?: Maybe<Clever_Roster_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "clever_roster_status". */
export type Clever_Roster_Status_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: clever_roster_status */
export type Clever_Roster_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "clever_roster_status" */
export enum Clever_Roster_Status_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "clever_roster_status" */
export type Clever_Roster_Status_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "clever_roster_status" */
export enum Clever_Roster_Status_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "contact_us" */
export type Contact_Us = {
  __typename?: 'contact_us';
  contact_us_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  message: Scalars['String'];
  name: Scalars['String'];
  school_or_district: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "contact_us" */
export type Contact_Us_Aggregate = {
  __typename?: 'contact_us_aggregate';
  aggregate?: Maybe<Contact_Us_Aggregate_Fields>;
  nodes: Array<Contact_Us>;
};

/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_Fields = {
  __typename?: 'contact_us_aggregate_fields';
  avg?: Maybe<Contact_Us_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Us_Max_Fields>;
  min?: Maybe<Contact_Us_Min_Fields>;
  stddev?: Maybe<Contact_Us_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Us_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Us_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Us_Sum_Fields>;
  var_pop?: Maybe<Contact_Us_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Us_Var_Samp_Fields>;
  variance?: Maybe<Contact_Us_Variance_Fields>;
};


/** aggregate fields of "contact_us" */
export type Contact_Us_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Us_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contact_Us_Avg_Fields = {
  __typename?: 'contact_us_avg_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contact_us". All fields are combined with a logical 'AND'. */
export type Contact_Us_Bool_Exp = {
  _and?: Maybe<Array<Contact_Us_Bool_Exp>>;
  _not?: Maybe<Contact_Us_Bool_Exp>;
  _or?: Maybe<Array<Contact_Us_Bool_Exp>>;
  contact_us_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  school_or_district?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher = {
  __typename?: 'contact_us_camp_teacher';
  camp_name: Scalars['String'];
  contact_us_camp_teacher_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  phone_number: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Aggregate = {
  __typename?: 'contact_us_camp_teacher_aggregate';
  aggregate?: Maybe<Contact_Us_Camp_Teacher_Aggregate_Fields>;
  nodes: Array<Contact_Us_Camp_Teacher>;
};

/** aggregate fields of "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Aggregate_Fields = {
  __typename?: 'contact_us_camp_teacher_aggregate_fields';
  avg?: Maybe<Contact_Us_Camp_Teacher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Us_Camp_Teacher_Max_Fields>;
  min?: Maybe<Contact_Us_Camp_Teacher_Min_Fields>;
  stddev?: Maybe<Contact_Us_Camp_Teacher_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Us_Camp_Teacher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Us_Camp_Teacher_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Us_Camp_Teacher_Sum_Fields>;
  var_pop?: Maybe<Contact_Us_Camp_Teacher_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Us_Camp_Teacher_Var_Samp_Fields>;
  variance?: Maybe<Contact_Us_Camp_Teacher_Variance_Fields>;
};


/** aggregate fields of "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Us_Camp_Teacher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contact_Us_Camp_Teacher_Avg_Fields = {
  __typename?: 'contact_us_camp_teacher_avg_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contact_us_camp_teacher". All fields are combined with a logical 'AND'. */
export type Contact_Us_Camp_Teacher_Bool_Exp = {
  _and?: Maybe<Array<Contact_Us_Camp_Teacher_Bool_Exp>>;
  _not?: Maybe<Contact_Us_Camp_Teacher_Bool_Exp>;
  _or?: Maybe<Array<Contact_Us_Camp_Teacher_Bool_Exp>>;
  camp_name?: Maybe<String_Comparison_Exp>;
  contact_us_camp_teacher_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_us_camp_teacher" */
export enum Contact_Us_Camp_Teacher_Constraint {
  /** unique or primary key constraint */
  ContactUsCampTeacherPkey = 'contact_us_camp_teacher_pkey'
}

/** input type for incrementing numeric columns in table "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Inc_Input = {
  contact_us_camp_teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Insert_Input = {
  camp_name?: Maybe<Scalars['String']>;
  contact_us_camp_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contact_Us_Camp_Teacher_Max_Fields = {
  __typename?: 'contact_us_camp_teacher_max_fields';
  camp_name?: Maybe<Scalars['String']>;
  contact_us_camp_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Contact_Us_Camp_Teacher_Min_Fields = {
  __typename?: 'contact_us_camp_teacher_min_fields';
  camp_name?: Maybe<Scalars['String']>;
  contact_us_camp_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Mutation_Response = {
  __typename?: 'contact_us_camp_teacher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Us_Camp_Teacher>;
};

/** on_conflict condition type for table "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_On_Conflict = {
  constraint: Contact_Us_Camp_Teacher_Constraint;
  update_columns?: Array<Contact_Us_Camp_Teacher_Update_Column>;
  where?: Maybe<Contact_Us_Camp_Teacher_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_us_camp_teacher". */
export type Contact_Us_Camp_Teacher_Order_By = {
  camp_name?: Maybe<Order_By>;
  contact_us_camp_teacher_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_us_camp_teacher */
export type Contact_Us_Camp_Teacher_Pk_Columns_Input = {
  contact_us_camp_teacher_id: Scalars['Int'];
};

/** select columns of table "contact_us_camp_teacher" */
export enum Contact_Us_Camp_Teacher_Select_Column {
  /** column name */
  CampName = 'camp_name',
  /** column name */
  ContactUsCampTeacherId = 'contact_us_camp_teacher_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Message = 'message',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact_us_camp_teacher" */
export type Contact_Us_Camp_Teacher_Set_Input = {
  camp_name?: Maybe<Scalars['String']>;
  contact_us_camp_teacher_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contact_Us_Camp_Teacher_Stddev_Fields = {
  __typename?: 'contact_us_camp_teacher_stddev_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Us_Camp_Teacher_Stddev_Pop_Fields = {
  __typename?: 'contact_us_camp_teacher_stddev_pop_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Us_Camp_Teacher_Stddev_Samp_Fields = {
  __typename?: 'contact_us_camp_teacher_stddev_samp_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Contact_Us_Camp_Teacher_Sum_Fields = {
  __typename?: 'contact_us_camp_teacher_sum_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "contact_us_camp_teacher" */
export enum Contact_Us_Camp_Teacher_Update_Column {
  /** column name */
  CampName = 'camp_name',
  /** column name */
  ContactUsCampTeacherId = 'contact_us_camp_teacher_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Message = 'message',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Contact_Us_Camp_Teacher_Var_Pop_Fields = {
  __typename?: 'contact_us_camp_teacher_var_pop_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Us_Camp_Teacher_Var_Samp_Fields = {
  __typename?: 'contact_us_camp_teacher_var_samp_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Us_Camp_Teacher_Variance_Fields = {
  __typename?: 'contact_us_camp_teacher_variance_fields';
  contact_us_camp_teacher_id?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "contact_us" */
export enum Contact_Us_Constraint {
  /** unique or primary key constraint */
  ContactUsPkey = 'contact_us_pkey'
}

/** input type for incrementing numeric columns in table "contact_us" */
export type Contact_Us_Inc_Input = {
  contact_us_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_us" */
export type Contact_Us_Insert_Input = {
  contact_us_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  school_or_district?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contact_Us_Max_Fields = {
  __typename?: 'contact_us_max_fields';
  contact_us_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  school_or_district?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Contact_Us_Min_Fields = {
  __typename?: 'contact_us_min_fields';
  contact_us_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  school_or_district?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contact_us" */
export type Contact_Us_Mutation_Response = {
  __typename?: 'contact_us_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Us>;
};

/** on_conflict condition type for table "contact_us" */
export type Contact_Us_On_Conflict = {
  constraint: Contact_Us_Constraint;
  update_columns?: Array<Contact_Us_Update_Column>;
  where?: Maybe<Contact_Us_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_us". */
export type Contact_Us_Order_By = {
  contact_us_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  school_or_district?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: contact_us */
export type Contact_Us_Pk_Columns_Input = {
  contact_us_id: Scalars['Int'];
};

/** select columns of table "contact_us" */
export enum Contact_Us_Select_Column {
  /** column name */
  ContactUsId = 'contact_us_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  SchoolOrDistrict = 'school_or_district',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact_us" */
export type Contact_Us_Set_Input = {
  contact_us_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  school_or_district?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contact_Us_Stddev_Fields = {
  __typename?: 'contact_us_stddev_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Us_Stddev_Pop_Fields = {
  __typename?: 'contact_us_stddev_pop_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Us_Stddev_Samp_Fields = {
  __typename?: 'contact_us_stddev_samp_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Contact_Us_Sum_Fields = {
  __typename?: 'contact_us_sum_fields';
  contact_us_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "contact_us" */
export enum Contact_Us_Update_Column {
  /** column name */
  ContactUsId = 'contact_us_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  SchoolOrDistrict = 'school_or_district',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Contact_Us_Var_Pop_Fields = {
  __typename?: 'contact_us_var_pop_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Us_Var_Samp_Fields = {
  __typename?: 'contact_us_var_samp_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Us_Variance_Fields = {
  __typename?: 'contact_us_variance_fields';
  contact_us_id?: Maybe<Scalars['Float']>;
};

export type CreateProductSubscriptionData = {
  __typename?: 'createProductSubscriptionData';
  clientSecret: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type CreateProductSubscriptionSet = {
  productId: Scalars['Int'];
  productName: Scalars['String'];
  promotionCode?: Maybe<Scalars['String']>;
  stripePriceId: Scalars['String'];
  stripeProductId: Scalars['String'];
  teacherId: Scalars['Int'];
};

/** Song master list obtained from crossclear */
export type Crossclear_Masterlist = {
  __typename?: 'crossclear_masterlist';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  cross_id: Scalars['String'];
  cross_ids: Scalars['jsonb'];
  in_style_of: Scalars['String'];
  masterlist_id: Scalars['Int'];
  mix_type: Scalars['String'];
  name: Scalars['String'];
  recording_type: Scalars['String'];
  studio_mix_group_ids: Scalars['jsonb'];
  studio_recording_ids: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  year?: Maybe<Scalars['numeric']>;
};


/** Song master list obtained from crossclear */
export type Crossclear_MasterlistCross_IdsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Song master list obtained from crossclear */
export type Crossclear_MasterlistStudio_Mix_Group_IdsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Song master list obtained from crossclear */
export type Crossclear_MasterlistStudio_Recording_IdsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "crossclear.masterlist" */
export type Crossclear_Masterlist_Aggregate = {
  __typename?: 'crossclear_masterlist_aggregate';
  aggregate?: Maybe<Crossclear_Masterlist_Aggregate_Fields>;
  nodes: Array<Crossclear_Masterlist>;
};

/** aggregate fields of "crossclear.masterlist" */
export type Crossclear_Masterlist_Aggregate_Fields = {
  __typename?: 'crossclear_masterlist_aggregate_fields';
  avg?: Maybe<Crossclear_Masterlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Crossclear_Masterlist_Max_Fields>;
  min?: Maybe<Crossclear_Masterlist_Min_Fields>;
  stddev?: Maybe<Crossclear_Masterlist_Stddev_Fields>;
  stddev_pop?: Maybe<Crossclear_Masterlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Crossclear_Masterlist_Stddev_Samp_Fields>;
  sum?: Maybe<Crossclear_Masterlist_Sum_Fields>;
  var_pop?: Maybe<Crossclear_Masterlist_Var_Pop_Fields>;
  var_samp?: Maybe<Crossclear_Masterlist_Var_Samp_Fields>;
  variance?: Maybe<Crossclear_Masterlist_Variance_Fields>;
};


/** aggregate fields of "crossclear.masterlist" */
export type Crossclear_Masterlist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Crossclear_Masterlist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Crossclear_Masterlist_Append_Input = {
  cross_ids?: Maybe<Scalars['jsonb']>;
  studio_mix_group_ids?: Maybe<Scalars['jsonb']>;
  studio_recording_ids?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Crossclear_Masterlist_Avg_Fields = {
  __typename?: 'crossclear_masterlist_avg_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "crossclear.masterlist". All fields are combined with a logical 'AND'. */
export type Crossclear_Masterlist_Bool_Exp = {
  _and?: Maybe<Array<Crossclear_Masterlist_Bool_Exp>>;
  _not?: Maybe<Crossclear_Masterlist_Bool_Exp>;
  _or?: Maybe<Array<Crossclear_Masterlist_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cross_id?: Maybe<String_Comparison_Exp>;
  cross_ids?: Maybe<Jsonb_Comparison_Exp>;
  in_style_of?: Maybe<String_Comparison_Exp>;
  masterlist_id?: Maybe<Int_Comparison_Exp>;
  mix_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  recording_type?: Maybe<String_Comparison_Exp>;
  studio_mix_group_ids?: Maybe<Jsonb_Comparison_Exp>;
  studio_recording_ids?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  year?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "crossclear.masterlist" */
export enum Crossclear_Masterlist_Constraint {
  /** unique or primary key constraint */
  MasterlistCrossIdKey = 'masterlist_cross_id_key',
  /** unique or primary key constraint */
  MasterlistPkey = 'masterlist_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Crossclear_Masterlist_Delete_At_Path_Input = {
  cross_ids?: Maybe<Array<Scalars['String']>>;
  studio_mix_group_ids?: Maybe<Array<Scalars['String']>>;
  studio_recording_ids?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Crossclear_Masterlist_Delete_Elem_Input = {
  cross_ids?: Maybe<Scalars['Int']>;
  studio_mix_group_ids?: Maybe<Scalars['Int']>;
  studio_recording_ids?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Crossclear_Masterlist_Delete_Key_Input = {
  cross_ids?: Maybe<Scalars['String']>;
  studio_mix_group_ids?: Maybe<Scalars['String']>;
  studio_recording_ids?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "crossclear.masterlist" */
export type Crossclear_Masterlist_Inc_Input = {
  masterlist_id?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "crossclear.masterlist" */
export type Crossclear_Masterlist_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cross_id?: Maybe<Scalars['String']>;
  cross_ids?: Maybe<Scalars['jsonb']>;
  in_style_of?: Maybe<Scalars['String']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  mix_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recording_type?: Maybe<Scalars['String']>;
  studio_mix_group_ids?: Maybe<Scalars['jsonb']>;
  studio_recording_ids?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Crossclear_Masterlist_Max_Fields = {
  __typename?: 'crossclear_masterlist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cross_id?: Maybe<Scalars['String']>;
  in_style_of?: Maybe<Scalars['String']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  mix_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recording_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Crossclear_Masterlist_Min_Fields = {
  __typename?: 'crossclear_masterlist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cross_id?: Maybe<Scalars['String']>;
  in_style_of?: Maybe<Scalars['String']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  mix_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recording_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "crossclear.masterlist" */
export type Crossclear_Masterlist_Mutation_Response = {
  __typename?: 'crossclear_masterlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Crossclear_Masterlist>;
};

/** on_conflict condition type for table "crossclear.masterlist" */
export type Crossclear_Masterlist_On_Conflict = {
  constraint: Crossclear_Masterlist_Constraint;
  update_columns?: Array<Crossclear_Masterlist_Update_Column>;
  where?: Maybe<Crossclear_Masterlist_Bool_Exp>;
};

/** Ordering options when selecting data from "crossclear.masterlist". */
export type Crossclear_Masterlist_Order_By = {
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cross_id?: Maybe<Order_By>;
  cross_ids?: Maybe<Order_By>;
  in_style_of?: Maybe<Order_By>;
  masterlist_id?: Maybe<Order_By>;
  mix_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  recording_type?: Maybe<Order_By>;
  studio_mix_group_ids?: Maybe<Order_By>;
  studio_recording_ids?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: crossclear_masterlist */
export type Crossclear_Masterlist_Pk_Columns_Input = {
  masterlist_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Crossclear_Masterlist_Prepend_Input = {
  cross_ids?: Maybe<Scalars['jsonb']>;
  studio_mix_group_ids?: Maybe<Scalars['jsonb']>;
  studio_recording_ids?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "crossclear.masterlist" */
export enum Crossclear_Masterlist_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CrossId = 'cross_id',
  /** column name */
  CrossIds = 'cross_ids',
  /** column name */
  InStyleOf = 'in_style_of',
  /** column name */
  MasterlistId = 'masterlist_id',
  /** column name */
  MixType = 'mix_type',
  /** column name */
  Name = 'name',
  /** column name */
  RecordingType = 'recording_type',
  /** column name */
  StudioMixGroupIds = 'studio_mix_group_ids',
  /** column name */
  StudioRecordingIds = 'studio_recording_ids',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "crossclear.masterlist" */
export type Crossclear_Masterlist_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cross_id?: Maybe<Scalars['String']>;
  cross_ids?: Maybe<Scalars['jsonb']>;
  in_style_of?: Maybe<Scalars['String']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  mix_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recording_type?: Maybe<Scalars['String']>;
  studio_mix_group_ids?: Maybe<Scalars['jsonb']>;
  studio_recording_ids?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Crossclear_Masterlist_Stddev_Fields = {
  __typename?: 'crossclear_masterlist_stddev_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Crossclear_Masterlist_Stddev_Pop_Fields = {
  __typename?: 'crossclear_masterlist_stddev_pop_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Crossclear_Masterlist_Stddev_Samp_Fields = {
  __typename?: 'crossclear_masterlist_stddev_samp_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Crossclear_Masterlist_Sum_Fields = {
  __typename?: 'crossclear_masterlist_sum_fields';
  masterlist_id?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['numeric']>;
};

/** update columns of table "crossclear.masterlist" */
export enum Crossclear_Masterlist_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CrossId = 'cross_id',
  /** column name */
  CrossIds = 'cross_ids',
  /** column name */
  InStyleOf = 'in_style_of',
  /** column name */
  MasterlistId = 'masterlist_id',
  /** column name */
  MixType = 'mix_type',
  /** column name */
  Name = 'name',
  /** column name */
  RecordingType = 'recording_type',
  /** column name */
  StudioMixGroupIds = 'studio_mix_group_ids',
  /** column name */
  StudioRecordingIds = 'studio_recording_ids',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Crossclear_Masterlist_Var_Pop_Fields = {
  __typename?: 'crossclear_masterlist_var_pop_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Crossclear_Masterlist_Var_Samp_Fields = {
  __typename?: 'crossclear_masterlist_var_samp_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Crossclear_Masterlist_Variance_Fields = {
  __typename?: 'crossclear_masterlist_variance_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "discipline" */
export type Discipline = {
  __typename?: 'discipline';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  discipline_id: Scalars['Int'];
  /** An array relationship */
  lesson_plan_disciplines: Array<Lesson_Plan_Discipline>;
  /** An aggregate relationship */
  lesson_plan_disciplines_aggregate: Lesson_Plan_Discipline_Aggregate;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "discipline" */
export type DisciplineLesson_Plan_DisciplinesArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


/** columns and relationships of "discipline" */
export type DisciplineLesson_Plan_Disciplines_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};

/** aggregated selection of "discipline" */
export type Discipline_Aggregate = {
  __typename?: 'discipline_aggregate';
  aggregate?: Maybe<Discipline_Aggregate_Fields>;
  nodes: Array<Discipline>;
};

/** aggregate fields of "discipline" */
export type Discipline_Aggregate_Fields = {
  __typename?: 'discipline_aggregate_fields';
  avg?: Maybe<Discipline_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Discipline_Max_Fields>;
  min?: Maybe<Discipline_Min_Fields>;
  stddev?: Maybe<Discipline_Stddev_Fields>;
  stddev_pop?: Maybe<Discipline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discipline_Stddev_Samp_Fields>;
  sum?: Maybe<Discipline_Sum_Fields>;
  var_pop?: Maybe<Discipline_Var_Pop_Fields>;
  var_samp?: Maybe<Discipline_Var_Samp_Fields>;
  variance?: Maybe<Discipline_Variance_Fields>;
};


/** aggregate fields of "discipline" */
export type Discipline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discipline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Discipline_Avg_Fields = {
  __typename?: 'discipline_avg_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "discipline". All fields are combined with a logical 'AND'. */
export type Discipline_Bool_Exp = {
  _and?: Maybe<Array<Discipline_Bool_Exp>>;
  _not?: Maybe<Discipline_Bool_Exp>;
  _or?: Maybe<Array<Discipline_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  discipline_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_disciplines?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "discipline" */
export enum Discipline_Constraint {
  /** unique or primary key constraint */
  DisciplineNameKey = 'discipline_name_key',
  /** unique or primary key constraint */
  DisciplinePkey = 'discipline_pkey'
}

/** input type for incrementing numeric columns in table "discipline" */
export type Discipline_Inc_Input = {
  discipline_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "discipline" */
export type Discipline_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_disciplines?: Maybe<Lesson_Plan_Discipline_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Discipline_Max_Fields = {
  __typename?: 'discipline_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discipline_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Discipline_Min_Fields = {
  __typename?: 'discipline_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discipline_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "discipline" */
export type Discipline_Mutation_Response = {
  __typename?: 'discipline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Discipline>;
};

/** input type for inserting object relation for remote table "discipline" */
export type Discipline_Obj_Rel_Insert_Input = {
  data: Discipline_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Discipline_On_Conflict>;
};

/** on_conflict condition type for table "discipline" */
export type Discipline_On_Conflict = {
  constraint: Discipline_Constraint;
  update_columns?: Array<Discipline_Update_Column>;
  where?: Maybe<Discipline_Bool_Exp>;
};

/** Ordering options when selecting data from "discipline". */
export type Discipline_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  discipline_id?: Maybe<Order_By>;
  lesson_plan_disciplines_aggregate?: Maybe<Lesson_Plan_Discipline_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: discipline */
export type Discipline_Pk_Columns_Input = {
  discipline_id: Scalars['Int'];
};

/** select columns of table "discipline" */
export enum Discipline_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisciplineId = 'discipline_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "discipline" */
export type Discipline_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  discipline_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Discipline_Stddev_Fields = {
  __typename?: 'discipline_stddev_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Discipline_Stddev_Pop_Fields = {
  __typename?: 'discipline_stddev_pop_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Discipline_Stddev_Samp_Fields = {
  __typename?: 'discipline_stddev_samp_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Discipline_Sum_Fields = {
  __typename?: 'discipline_sum_fields';
  discipline_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "discipline" */
export enum Discipline_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisciplineId = 'discipline_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Discipline_Var_Pop_Fields = {
  __typename?: 'discipline_var_pop_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Discipline_Var_Samp_Fields = {
  __typename?: 'discipline_var_samp_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Discipline_Variance_Fields = {
  __typename?: 'discipline_variance_fields';
  discipline_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "district" */
export type District = {
  __typename?: 'district';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id: Scalars['Int'];
  is_enterprise: Scalars['Boolean'];
  last_sync?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  lockable_content_districts: Array<Lockable_Content_District>;
  /** An aggregate relationship */
  lockable_content_districts_aggregate: Lockable_Content_District_Aggregate;
  mdr_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nces_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  schools: Array<School>;
  /** An aggregate relationship */
  schools_aggregate: School_Aggregate;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "district" */
export type DistrictLockable_Content_DistrictsArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


/** columns and relationships of "district" */
export type DistrictLockable_Content_Districts_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


/** columns and relationships of "district" */
export type DistrictSchoolsArgs = {
  distinct_on?: Maybe<Array<School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Order_By>>;
  where?: Maybe<School_Bool_Exp>;
};


/** columns and relationships of "district" */
export type DistrictSchools_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Order_By>>;
  where?: Maybe<School_Bool_Exp>;
};

/** columns and relationships of "district_admin" */
export type District_Admin = {
  __typename?: 'district_admin';
  clever_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  district_admin_id: Scalars['Int'];
  district_id: Scalars['Int'];
  email: Scalars['String'];
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  name_middle: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['numeric'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "district_admin" */
export type District_Admin_Aggregate = {
  __typename?: 'district_admin_aggregate';
  aggregate?: Maybe<District_Admin_Aggregate_Fields>;
  nodes: Array<District_Admin>;
};

/** aggregate fields of "district_admin" */
export type District_Admin_Aggregate_Fields = {
  __typename?: 'district_admin_aggregate_fields';
  avg?: Maybe<District_Admin_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<District_Admin_Max_Fields>;
  min?: Maybe<District_Admin_Min_Fields>;
  stddev?: Maybe<District_Admin_Stddev_Fields>;
  stddev_pop?: Maybe<District_Admin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<District_Admin_Stddev_Samp_Fields>;
  sum?: Maybe<District_Admin_Sum_Fields>;
  var_pop?: Maybe<District_Admin_Var_Pop_Fields>;
  var_samp?: Maybe<District_Admin_Var_Samp_Fields>;
  variance?: Maybe<District_Admin_Variance_Fields>;
};


/** aggregate fields of "district_admin" */
export type District_Admin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<District_Admin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type District_Admin_Avg_Fields = {
  __typename?: 'district_admin_avg_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "district_admin". All fields are combined with a logical 'AND'. */
export type District_Admin_Bool_Exp = {
  _and?: Maybe<Array<District_Admin_Bool_Exp>>;
  _not?: Maybe<District_Admin_Bool_Exp>;
  _or?: Maybe<Array<District_Admin_Bool_Exp>>;
  clever_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  district_admin_id?: Maybe<Int_Comparison_Exp>;
  district_id?: Maybe<Int_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  name_first?: Maybe<String_Comparison_Exp>;
  name_last?: Maybe<String_Comparison_Exp>;
  name_middle?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "district_admin" */
export enum District_Admin_Constraint {
  /** unique or primary key constraint */
  DistrictAdminCleverIdKey = 'district_admin_clever_id_key',
  /** unique or primary key constraint */
  DistrictAdminDistrictIdKey = 'district_admin_district_id_key',
  /** unique or primary key constraint */
  DistrictAdminPkey = 'district_admin_pkey'
}

/** input type for incrementing numeric columns in table "district_admin" */
export type District_Admin_Inc_Input = {
  district_admin_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "district_admin" */
export type District_Admin_Insert_Input = {
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_admin_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type District_Admin_Max_Fields = {
  __typename?: 'district_admin_max_fields';
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_admin_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type District_Admin_Min_Fields = {
  __typename?: 'district_admin_min_fields';
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_admin_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "district_admin" */
export type District_Admin_Mutation_Response = {
  __typename?: 'district_admin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<District_Admin>;
};

/** on_conflict condition type for table "district_admin" */
export type District_Admin_On_Conflict = {
  constraint: District_Admin_Constraint;
  update_columns?: Array<District_Admin_Update_Column>;
  where?: Maybe<District_Admin_Bool_Exp>;
};

/** Ordering options when selecting data from "district_admin". */
export type District_Admin_Order_By = {
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district_admin_id?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: district_admin */
export type District_Admin_Pk_Columns_Input = {
  district_admin_id: Scalars['Int'];
};

/** select columns of table "district_admin" */
export enum District_Admin_Select_Column {
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictAdminId = 'district_admin_id',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  Email = 'email',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "district_admin" */
export type District_Admin_Set_Input = {
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_admin_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type District_Admin_Stddev_Fields = {
  __typename?: 'district_admin_stddev_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type District_Admin_Stddev_Pop_Fields = {
  __typename?: 'district_admin_stddev_pop_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type District_Admin_Stddev_Samp_Fields = {
  __typename?: 'district_admin_stddev_samp_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type District_Admin_Sum_Fields = {
  __typename?: 'district_admin_sum_fields';
  district_admin_id?: Maybe<Scalars['Int']>;
  district_id?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['numeric']>;
};

/** update columns of table "district_admin" */
export enum District_Admin_Update_Column {
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictAdminId = 'district_admin_id',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  Email = 'email',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type District_Admin_Var_Pop_Fields = {
  __typename?: 'district_admin_var_pop_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type District_Admin_Var_Samp_Fields = {
  __typename?: 'district_admin_var_samp_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type District_Admin_Variance_Fields = {
  __typename?: 'district_admin_variance_fields';
  district_admin_id?: Maybe<Scalars['Float']>;
  district_id?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "district" */
export type District_Aggregate = {
  __typename?: 'district_aggregate';
  aggregate?: Maybe<District_Aggregate_Fields>;
  nodes: Array<District>;
};

/** aggregate fields of "district" */
export type District_Aggregate_Fields = {
  __typename?: 'district_aggregate_fields';
  avg?: Maybe<District_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<District_Max_Fields>;
  min?: Maybe<District_Min_Fields>;
  stddev?: Maybe<District_Stddev_Fields>;
  stddev_pop?: Maybe<District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<District_Stddev_Samp_Fields>;
  sum?: Maybe<District_Sum_Fields>;
  var_pop?: Maybe<District_Var_Pop_Fields>;
  var_samp?: Maybe<District_Var_Samp_Fields>;
  variance?: Maybe<District_Variance_Fields>;
};


/** aggregate fields of "district" */
export type District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<District_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type District_Avg_Fields = {
  __typename?: 'district_avg_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "district". All fields are combined with a logical 'AND'. */
export type District_Bool_Exp = {
  _and?: Maybe<Array<District_Bool_Exp>>;
  _not?: Maybe<District_Bool_Exp>;
  _or?: Maybe<Array<District_Bool_Exp>>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  clever_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  district_id?: Maybe<Int_Comparison_Exp>;
  is_enterprise?: Maybe<Boolean_Comparison_Exp>;
  last_sync?: Maybe<Timestamptz_Comparison_Exp>;
  lockable_content_districts?: Maybe<Lockable_Content_District_Bool_Exp>;
  mdr_id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  nces_id?: Maybe<String_Comparison_Exp>;
  schools?: Maybe<School_Bool_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "district" */
export enum District_Constraint {
  /** unique or primary key constraint */
  DistrictCleverIdKey = 'district_clever_id_key',
  /** unique or primary key constraint */
  DistrictMdrIdKey = 'district_mdr_id_key',
  /** unique or primary key constraint */
  DistrictNcesIdKey = 'district_nces_id_key',
  /** unique or primary key constraint */
  DistrictPkey = 'district_pkey'
}

/** input type for incrementing numeric columns in table "district" */
export type District_Inc_Input = {
  district_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "district" */
export type District_Insert_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  is_enterprise?: Maybe<Scalars['Boolean']>;
  last_sync?: Maybe<Scalars['timestamptz']>;
  lockable_content_districts?: Maybe<Lockable_Content_District_Arr_Rel_Insert_Input>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  schools?: Maybe<School_Arr_Rel_Insert_Input>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type District_Max_Fields = {
  __typename?: 'district_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  last_sync?: Maybe<Scalars['timestamptz']>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type District_Min_Fields = {
  __typename?: 'district_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  last_sync?: Maybe<Scalars['timestamptz']>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "district" */
export type District_Mutation_Response = {
  __typename?: 'district_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<District>;
};

/** input type for inserting object relation for remote table "district" */
export type District_Obj_Rel_Insert_Input = {
  data: District_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<District_On_Conflict>;
};

/** on_conflict condition type for table "district" */
export type District_On_Conflict = {
  constraint: District_Constraint;
  update_columns?: Array<District_Update_Column>;
  where?: Maybe<District_Bool_Exp>;
};

/** Ordering options when selecting data from "district". */
export type District_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  is_enterprise?: Maybe<Order_By>;
  last_sync?: Maybe<Order_By>;
  lockable_content_districts_aggregate?: Maybe<Lockable_Content_District_Aggregate_Order_By>;
  mdr_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nces_id?: Maybe<Order_By>;
  schools_aggregate?: Maybe<School_Aggregate_Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: district */
export type District_Pk_Columns_Input = {
  district_id: Scalars['Int'];
};

/** select columns of table "district" */
export enum District_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  IsEnterprise = 'is_enterprise',
  /** column name */
  LastSync = 'last_sync',
  /** column name */
  MdrId = 'mdr_id',
  /** column name */
  Name = 'name',
  /** column name */
  NcesId = 'nces_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "district" */
export type District_Set_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  is_enterprise?: Maybe<Scalars['Boolean']>;
  last_sync?: Maybe<Scalars['timestamptz']>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type District_Stddev_Fields = {
  __typename?: 'district_stddev_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type District_Stddev_Pop_Fields = {
  __typename?: 'district_stddev_pop_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type District_Stddev_Samp_Fields = {
  __typename?: 'district_stddev_samp_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type District_Sum_Fields = {
  __typename?: 'district_sum_fields';
  district_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "district" */
export enum District_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  IsEnterprise = 'is_enterprise',
  /** column name */
  LastSync = 'last_sync',
  /** column name */
  MdrId = 'mdr_id',
  /** column name */
  Name = 'name',
  /** column name */
  NcesId = 'nces_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type District_Var_Pop_Fields = {
  __typename?: 'district_var_pop_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type District_Var_Samp_Fields = {
  __typename?: 'district_var_samp_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type District_Variance_Fields = {
  __typename?: 'district_variance_fields';
  district_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "filter_color" */
export type Filter_Color = {
  __typename?: 'filter_color';
  active: Scalars['Boolean'];
  display_name?: Maybe<Scalars['String']>;
  filter_color_id: Scalars['Int'];
  name: Filter_Color_Name_Enum;
  order: Scalars['Int'];
  thumbnail_path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "filter_color" */
export type Filter_Color_Aggregate = {
  __typename?: 'filter_color_aggregate';
  aggregate?: Maybe<Filter_Color_Aggregate_Fields>;
  nodes: Array<Filter_Color>;
};

/** aggregate fields of "filter_color" */
export type Filter_Color_Aggregate_Fields = {
  __typename?: 'filter_color_aggregate_fields';
  avg?: Maybe<Filter_Color_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filter_Color_Max_Fields>;
  min?: Maybe<Filter_Color_Min_Fields>;
  stddev?: Maybe<Filter_Color_Stddev_Fields>;
  stddev_pop?: Maybe<Filter_Color_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filter_Color_Stddev_Samp_Fields>;
  sum?: Maybe<Filter_Color_Sum_Fields>;
  var_pop?: Maybe<Filter_Color_Var_Pop_Fields>;
  var_samp?: Maybe<Filter_Color_Var_Samp_Fields>;
  variance?: Maybe<Filter_Color_Variance_Fields>;
};


/** aggregate fields of "filter_color" */
export type Filter_Color_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Color_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Filter_Color_Avg_Fields = {
  __typename?: 'filter_color_avg_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "filter_color". All fields are combined with a logical 'AND'. */
export type Filter_Color_Bool_Exp = {
  _and?: Maybe<Array<Filter_Color_Bool_Exp>>;
  _not?: Maybe<Filter_Color_Bool_Exp>;
  _or?: Maybe<Array<Filter_Color_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  filter_color_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<Filter_Color_Name_Enum_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  thumbnail_path?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_color" */
export enum Filter_Color_Constraint {
  /** unique or primary key constraint */
  FilterColorPkey = 'filter_color_pkey'
}

/** input type for incrementing numeric columns in table "filter_color" */
export type Filter_Color_Inc_Input = {
  filter_color_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "filter_color" */
export type Filter_Color_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  display_name?: Maybe<Scalars['String']>;
  filter_color_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Filter_Color_Name_Enum>;
  order?: Maybe<Scalars['Int']>;
  thumbnail_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Color_Max_Fields = {
  __typename?: 'filter_color_max_fields';
  display_name?: Maybe<Scalars['String']>;
  filter_color_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  thumbnail_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Color_Min_Fields = {
  __typename?: 'filter_color_min_fields';
  display_name?: Maybe<Scalars['String']>;
  filter_color_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  thumbnail_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_color" */
export type Filter_Color_Mutation_Response = {
  __typename?: 'filter_color_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Color>;
};

/** columns and relationships of "filter_color_name" */
export type Filter_Color_Name = {
  __typename?: 'filter_color_name';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "filter_color_name" */
export type Filter_Color_Name_Aggregate = {
  __typename?: 'filter_color_name_aggregate';
  aggregate?: Maybe<Filter_Color_Name_Aggregate_Fields>;
  nodes: Array<Filter_Color_Name>;
};

/** aggregate fields of "filter_color_name" */
export type Filter_Color_Name_Aggregate_Fields = {
  __typename?: 'filter_color_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Filter_Color_Name_Max_Fields>;
  min?: Maybe<Filter_Color_Name_Min_Fields>;
};


/** aggregate fields of "filter_color_name" */
export type Filter_Color_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Color_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "filter_color_name". All fields are combined with a logical 'AND'. */
export type Filter_Color_Name_Bool_Exp = {
  _and?: Maybe<Array<Filter_Color_Name_Bool_Exp>>;
  _not?: Maybe<Filter_Color_Name_Bool_Exp>;
  _or?: Maybe<Array<Filter_Color_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_color_name" */
export enum Filter_Color_Name_Constraint {
  /** unique or primary key constraint */
  FilterColorNamePkey = 'filter_color_name_pkey'
}

export enum Filter_Color_Name_Enum {
  /** Black and White */
  BlackAndWhite = 'black_and_white',
  /** Diva */
  Diva = 'diva',
  /** Sepia */
  Sepia = 'sepia',
  /** Vintage */
  Vintage = 'vintage',
  /** Warm */
  Warm = 'warm'
}

/** Boolean expression to compare columns of type "filter_color_name_enum". All fields are combined with logical 'AND'. */
export type Filter_Color_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Filter_Color_Name_Enum>;
  _in?: Maybe<Array<Filter_Color_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Filter_Color_Name_Enum>;
  _nin?: Maybe<Array<Filter_Color_Name_Enum>>;
};

/** input type for inserting data into table "filter_color_name" */
export type Filter_Color_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Color_Name_Max_Fields = {
  __typename?: 'filter_color_name_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Color_Name_Min_Fields = {
  __typename?: 'filter_color_name_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_color_name" */
export type Filter_Color_Name_Mutation_Response = {
  __typename?: 'filter_color_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Color_Name>;
};

/** on_conflict condition type for table "filter_color_name" */
export type Filter_Color_Name_On_Conflict = {
  constraint: Filter_Color_Name_Constraint;
  update_columns?: Array<Filter_Color_Name_Update_Column>;
  where?: Maybe<Filter_Color_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_color_name". */
export type Filter_Color_Name_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: filter_color_name */
export type Filter_Color_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "filter_color_name" */
export enum Filter_Color_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "filter_color_name" */
export type Filter_Color_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "filter_color_name" */
export enum Filter_Color_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for inserting object relation for remote table "filter_color" */
export type Filter_Color_Obj_Rel_Insert_Input = {
  data: Filter_Color_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Filter_Color_On_Conflict>;
};

/** on_conflict condition type for table "filter_color" */
export type Filter_Color_On_Conflict = {
  constraint: Filter_Color_Constraint;
  update_columns?: Array<Filter_Color_Update_Column>;
  where?: Maybe<Filter_Color_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_color". */
export type Filter_Color_Order_By = {
  active?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  filter_color_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  thumbnail_path?: Maybe<Order_By>;
};

/** primary key columns input for table: filter_color */
export type Filter_Color_Pk_Columns_Input = {
  filter_color_id: Scalars['Int'];
};

/** select columns of table "filter_color" */
export enum Filter_Color_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FilterColorId = 'filter_color_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ThumbnailPath = 'thumbnail_path'
}

/** input type for updating data in table "filter_color" */
export type Filter_Color_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  display_name?: Maybe<Scalars['String']>;
  filter_color_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Filter_Color_Name_Enum>;
  order?: Maybe<Scalars['Int']>;
  thumbnail_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Filter_Color_Stddev_Fields = {
  __typename?: 'filter_color_stddev_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Filter_Color_Stddev_Pop_Fields = {
  __typename?: 'filter_color_stddev_pop_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Filter_Color_Stddev_Samp_Fields = {
  __typename?: 'filter_color_stddev_samp_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Filter_Color_Sum_Fields = {
  __typename?: 'filter_color_sum_fields';
  filter_color_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "filter_color" */
export enum Filter_Color_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  FilterColorId = 'filter_color_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ThumbnailPath = 'thumbnail_path'
}

/** aggregate var_pop on columns */
export type Filter_Color_Var_Pop_Fields = {
  __typename?: 'filter_color_var_pop_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Filter_Color_Var_Samp_Fields = {
  __typename?: 'filter_color_var_samp_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Filter_Color_Variance_Fields = {
  __typename?: 'filter_color_variance_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "filter_frame" */
export type Filter_Frame = {
  __typename?: 'filter_frame';
  active: Scalars['Boolean'];
  filter_frame_id: Scalars['Int'];
  frame_name?: Maybe<Scalars['String']>;
  image_path: Scalars['String'];
};

/** aggregated selection of "filter_frame" */
export type Filter_Frame_Aggregate = {
  __typename?: 'filter_frame_aggregate';
  aggregate?: Maybe<Filter_Frame_Aggregate_Fields>;
  nodes: Array<Filter_Frame>;
};

/** aggregate fields of "filter_frame" */
export type Filter_Frame_Aggregate_Fields = {
  __typename?: 'filter_frame_aggregate_fields';
  avg?: Maybe<Filter_Frame_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filter_Frame_Max_Fields>;
  min?: Maybe<Filter_Frame_Min_Fields>;
  stddev?: Maybe<Filter_Frame_Stddev_Fields>;
  stddev_pop?: Maybe<Filter_Frame_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filter_Frame_Stddev_Samp_Fields>;
  sum?: Maybe<Filter_Frame_Sum_Fields>;
  var_pop?: Maybe<Filter_Frame_Var_Pop_Fields>;
  var_samp?: Maybe<Filter_Frame_Var_Samp_Fields>;
  variance?: Maybe<Filter_Frame_Variance_Fields>;
};


/** aggregate fields of "filter_frame" */
export type Filter_Frame_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Frame_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Filter_Frame_Avg_Fields = {
  __typename?: 'filter_frame_avg_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "filter_frame". All fields are combined with a logical 'AND'. */
export type Filter_Frame_Bool_Exp = {
  _and?: Maybe<Array<Filter_Frame_Bool_Exp>>;
  _not?: Maybe<Filter_Frame_Bool_Exp>;
  _or?: Maybe<Array<Filter_Frame_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  filter_frame_id?: Maybe<Int_Comparison_Exp>;
  frame_name?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_frame" */
export enum Filter_Frame_Constraint {
  /** unique or primary key constraint */
  FilterFrameFilterFrameIdKey = 'filter_frame_filter_frame_id_key'
}

/** input type for incrementing numeric columns in table "filter_frame" */
export type Filter_Frame_Inc_Input = {
  filter_frame_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "filter_frame" */
export type Filter_Frame_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  frame_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Frame_Max_Fields = {
  __typename?: 'filter_frame_max_fields';
  filter_frame_id?: Maybe<Scalars['Int']>;
  frame_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Frame_Min_Fields = {
  __typename?: 'filter_frame_min_fields';
  filter_frame_id?: Maybe<Scalars['Int']>;
  frame_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_frame" */
export type Filter_Frame_Mutation_Response = {
  __typename?: 'filter_frame_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Frame>;
};

/** input type for inserting object relation for remote table "filter_frame" */
export type Filter_Frame_Obj_Rel_Insert_Input = {
  data: Filter_Frame_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Filter_Frame_On_Conflict>;
};

/** on_conflict condition type for table "filter_frame" */
export type Filter_Frame_On_Conflict = {
  constraint: Filter_Frame_Constraint;
  update_columns?: Array<Filter_Frame_Update_Column>;
  where?: Maybe<Filter_Frame_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_frame". */
export type Filter_Frame_Order_By = {
  active?: Maybe<Order_By>;
  filter_frame_id?: Maybe<Order_By>;
  frame_name?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
};

/** select columns of table "filter_frame" */
export enum Filter_Frame_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  FilterFrameId = 'filter_frame_id',
  /** column name */
  FrameName = 'frame_name',
  /** column name */
  ImagePath = 'image_path'
}

/** input type for updating data in table "filter_frame" */
export type Filter_Frame_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  frame_name?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Filter_Frame_Stddev_Fields = {
  __typename?: 'filter_frame_stddev_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Filter_Frame_Stddev_Pop_Fields = {
  __typename?: 'filter_frame_stddev_pop_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Filter_Frame_Stddev_Samp_Fields = {
  __typename?: 'filter_frame_stddev_samp_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Filter_Frame_Sum_Fields = {
  __typename?: 'filter_frame_sum_fields';
  filter_frame_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "filter_frame" */
export enum Filter_Frame_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  FilterFrameId = 'filter_frame_id',
  /** column name */
  FrameName = 'frame_name',
  /** column name */
  ImagePath = 'image_path'
}

/** aggregate var_pop on columns */
export type Filter_Frame_Var_Pop_Fields = {
  __typename?: 'filter_frame_var_pop_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Filter_Frame_Var_Samp_Fields = {
  __typename?: 'filter_frame_var_samp_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Filter_Frame_Variance_Fields = {
  __typename?: 'filter_frame_variance_fields';
  filter_frame_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "filter_sticker" */
export type Filter_Sticker = {
  __typename?: 'filter_sticker';
  active: Scalars['Boolean'];
  filter_sticker_id: Scalars['Int'];
  image_path: Scalars['String'];
};

/** aggregated selection of "filter_sticker" */
export type Filter_Sticker_Aggregate = {
  __typename?: 'filter_sticker_aggregate';
  aggregate?: Maybe<Filter_Sticker_Aggregate_Fields>;
  nodes: Array<Filter_Sticker>;
};

/** aggregate fields of "filter_sticker" */
export type Filter_Sticker_Aggregate_Fields = {
  __typename?: 'filter_sticker_aggregate_fields';
  avg?: Maybe<Filter_Sticker_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Filter_Sticker_Max_Fields>;
  min?: Maybe<Filter_Sticker_Min_Fields>;
  stddev?: Maybe<Filter_Sticker_Stddev_Fields>;
  stddev_pop?: Maybe<Filter_Sticker_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Filter_Sticker_Stddev_Samp_Fields>;
  sum?: Maybe<Filter_Sticker_Sum_Fields>;
  var_pop?: Maybe<Filter_Sticker_Var_Pop_Fields>;
  var_samp?: Maybe<Filter_Sticker_Var_Samp_Fields>;
  variance?: Maybe<Filter_Sticker_Variance_Fields>;
};


/** aggregate fields of "filter_sticker" */
export type Filter_Sticker_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Filter_Sticker_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Filter_Sticker_Avg_Fields = {
  __typename?: 'filter_sticker_avg_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "filter_sticker". All fields are combined with a logical 'AND'. */
export type Filter_Sticker_Bool_Exp = {
  _and?: Maybe<Array<Filter_Sticker_Bool_Exp>>;
  _not?: Maybe<Filter_Sticker_Bool_Exp>;
  _or?: Maybe<Array<Filter_Sticker_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  filter_sticker_id?: Maybe<Int_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filter_sticker" */
export enum Filter_Sticker_Constraint {
  /** unique or primary key constraint */
  FilterStickerPkey = 'filter_sticker_pkey'
}

/** input type for incrementing numeric columns in table "filter_sticker" */
export type Filter_Sticker_Inc_Input = {
  filter_sticker_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "filter_sticker" */
export type Filter_Sticker_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  filter_sticker_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Filter_Sticker_Max_Fields = {
  __typename?: 'filter_sticker_max_fields';
  filter_sticker_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Filter_Sticker_Min_Fields = {
  __typename?: 'filter_sticker_min_fields';
  filter_sticker_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "filter_sticker" */
export type Filter_Sticker_Mutation_Response = {
  __typename?: 'filter_sticker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Filter_Sticker>;
};

/** input type for inserting object relation for remote table "filter_sticker" */
export type Filter_Sticker_Obj_Rel_Insert_Input = {
  data: Filter_Sticker_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Filter_Sticker_On_Conflict>;
};

/** on_conflict condition type for table "filter_sticker" */
export type Filter_Sticker_On_Conflict = {
  constraint: Filter_Sticker_Constraint;
  update_columns?: Array<Filter_Sticker_Update_Column>;
  where?: Maybe<Filter_Sticker_Bool_Exp>;
};

/** Ordering options when selecting data from "filter_sticker". */
export type Filter_Sticker_Order_By = {
  active?: Maybe<Order_By>;
  filter_sticker_id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
};

/** primary key columns input for table: filter_sticker */
export type Filter_Sticker_Pk_Columns_Input = {
  filter_sticker_id: Scalars['Int'];
};

/** select columns of table "filter_sticker" */
export enum Filter_Sticker_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  FilterStickerId = 'filter_sticker_id',
  /** column name */
  ImagePath = 'image_path'
}

/** input type for updating data in table "filter_sticker" */
export type Filter_Sticker_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  filter_sticker_id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Filter_Sticker_Stddev_Fields = {
  __typename?: 'filter_sticker_stddev_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Filter_Sticker_Stddev_Pop_Fields = {
  __typename?: 'filter_sticker_stddev_pop_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Filter_Sticker_Stddev_Samp_Fields = {
  __typename?: 'filter_sticker_stddev_samp_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Filter_Sticker_Sum_Fields = {
  __typename?: 'filter_sticker_sum_fields';
  filter_sticker_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "filter_sticker" */
export enum Filter_Sticker_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  FilterStickerId = 'filter_sticker_id',
  /** column name */
  ImagePath = 'image_path'
}

/** aggregate var_pop on columns */
export type Filter_Sticker_Var_Pop_Fields = {
  __typename?: 'filter_sticker_var_pop_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Filter_Sticker_Var_Samp_Fields = {
  __typename?: 'filter_sticker_var_samp_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Filter_Sticker_Variance_Fields = {
  __typename?: 'filter_sticker_variance_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "grade" */
export type Grade = {
  __typename?: 'grade';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  standar_options: Array<Standard_Option>;
  /** An aggregate relationship */
  standar_options_aggregate: Standard_Option_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "grade" */
export type GradeStandar_OptionsArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};


/** columns and relationships of "grade" */
export type GradeStandar_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};

/** aggregated selection of "grade" */
export type Grade_Aggregate = {
  __typename?: 'grade_aggregate';
  aggregate?: Maybe<Grade_Aggregate_Fields>;
  nodes: Array<Grade>;
};

/** aggregate fields of "grade" */
export type Grade_Aggregate_Fields = {
  __typename?: 'grade_aggregate_fields';
  avg?: Maybe<Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Grade_Max_Fields>;
  min?: Maybe<Grade_Min_Fields>;
  stddev?: Maybe<Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Grade_Sum_Fields>;
  var_pop?: Maybe<Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Grade_Var_Samp_Fields>;
  variance?: Maybe<Grade_Variance_Fields>;
};


/** aggregate fields of "grade" */
export type Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Grade_Avg_Fields = {
  __typename?: 'grade_avg_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "grade". All fields are combined with a logical 'AND'. */
export type Grade_Bool_Exp = {
  _and?: Maybe<Array<Grade_Bool_Exp>>;
  _not?: Maybe<Grade_Bool_Exp>;
  _or?: Maybe<Array<Grade_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grade_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  standar_options?: Maybe<Standard_Option_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "grade" */
export enum Grade_Constraint {
  /** unique or primary key constraint */
  GradePkey1 = 'grade_pkey1'
}

/** input type for incrementing numeric columns in table "grade" */
export type Grade_Inc_Input = {
  grade_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "grade" */
export type Grade_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  standar_options?: Maybe<Standard_Option_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Grade_Max_Fields = {
  __typename?: 'grade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Grade_Min_Fields = {
  __typename?: 'grade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "grade" */
export type Grade_Mutation_Response = {
  __typename?: 'grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Grade>;
};

/** input type for inserting object relation for remote table "grade" */
export type Grade_Obj_Rel_Insert_Input = {
  data: Grade_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Grade_On_Conflict>;
};

/** on_conflict condition type for table "grade" */
export type Grade_On_Conflict = {
  constraint: Grade_Constraint;
  update_columns?: Array<Grade_Update_Column>;
  where?: Maybe<Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "grade". */
export type Grade_Order_By = {
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  standar_options_aggregate?: Maybe<Standard_Option_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: grade */
export type Grade_Pk_Columns_Input = {
  grade_id: Scalars['Int'];
};

/** select columns of table "grade" */
export enum Grade_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "grade" */
export type Grade_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Grade_Stddev_Fields = {
  __typename?: 'grade_stddev_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Grade_Stddev_Pop_Fields = {
  __typename?: 'grade_stddev_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Grade_Stddev_Samp_Fields = {
  __typename?: 'grade_stddev_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Grade_Sum_Fields = {
  __typename?: 'grade_sum_fields';
  grade_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "grade" */
export enum Grade_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Grade_Var_Pop_Fields = {
  __typename?: 'grade_var_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Grade_Var_Samp_Fields = {
  __typename?: 'grade_var_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Grade_Variance_Fields = {
  __typename?: 'grade_variance_fields';
  grade_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "grading_system" */
export type Grading_System = {
  __typename?: 'grading_system';
  created_at?: Maybe<Scalars['timestamptz']>;
  grading_system_id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  name: Grading_System_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  values?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "grading_system" */
export type Grading_SystemValuesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "grading_system" */
export type Grading_System_Aggregate = {
  __typename?: 'grading_system_aggregate';
  aggregate?: Maybe<Grading_System_Aggregate_Fields>;
  nodes: Array<Grading_System>;
};

/** aggregate fields of "grading_system" */
export type Grading_System_Aggregate_Fields = {
  __typename?: 'grading_system_aggregate_fields';
  avg?: Maybe<Grading_System_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Grading_System_Max_Fields>;
  min?: Maybe<Grading_System_Min_Fields>;
  stddev?: Maybe<Grading_System_Stddev_Fields>;
  stddev_pop?: Maybe<Grading_System_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Grading_System_Stddev_Samp_Fields>;
  sum?: Maybe<Grading_System_Sum_Fields>;
  var_pop?: Maybe<Grading_System_Var_Pop_Fields>;
  var_samp?: Maybe<Grading_System_Var_Samp_Fields>;
  variance?: Maybe<Grading_System_Variance_Fields>;
};


/** aggregate fields of "grading_system" */
export type Grading_System_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Grading_System_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Grading_System_Append_Input = {
  values?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Grading_System_Avg_Fields = {
  __typename?: 'grading_system_avg_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "grading_system". All fields are combined with a logical 'AND'. */
export type Grading_System_Bool_Exp = {
  _and?: Maybe<Array<Grading_System_Bool_Exp>>;
  _not?: Maybe<Grading_System_Bool_Exp>;
  _or?: Maybe<Array<Grading_System_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grading_system_id?: Maybe<Int_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  name?: Maybe<Grading_System_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  values?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "grading_system" */
export enum Grading_System_Constraint {
  /** unique or primary key constraint */
  GradingSystemIdPkey = 'grading_system_id_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Grading_System_Delete_At_Path_Input = {
  values?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Grading_System_Delete_Elem_Input = {
  values?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Grading_System_Delete_Key_Input = {
  values?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "grading_system" */
export type Grading_System_Inc_Input = {
  grading_system_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "grading_system" */
export type Grading_System_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Grading_System_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  values?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Grading_System_Max_Fields = {
  __typename?: 'grading_system_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Grading_System_Min_Fields = {
  __typename?: 'grading_system_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "grading_system" */
export type Grading_System_Mutation_Response = {
  __typename?: 'grading_system_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Grading_System>;
};

/** input type for inserting object relation for remote table "grading_system" */
export type Grading_System_Obj_Rel_Insert_Input = {
  data: Grading_System_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Grading_System_On_Conflict>;
};

/** on_conflict condition type for table "grading_system" */
export type Grading_System_On_Conflict = {
  constraint: Grading_System_Constraint;
  update_columns?: Array<Grading_System_Update_Column>;
  where?: Maybe<Grading_System_Bool_Exp>;
};

/** Ordering options when selecting data from "grading_system". */
export type Grading_System_Order_By = {
  created_at?: Maybe<Order_By>;
  grading_system_id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  values?: Maybe<Order_By>;
};

/** primary key columns input for table: grading_system */
export type Grading_System_Pk_Columns_Input = {
  grading_system_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Grading_System_Prepend_Input = {
  values?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "grading_system" */
export enum Grading_System_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradingSystemId = 'grading_system_id',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Values = 'values'
}

/** input type for updating data in table "grading_system" */
export type Grading_System_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grading_system_id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Grading_System_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  values?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Grading_System_Stddev_Fields = {
  __typename?: 'grading_system_stddev_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Grading_System_Stddev_Pop_Fields = {
  __typename?: 'grading_system_stddev_pop_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Grading_System_Stddev_Samp_Fields = {
  __typename?: 'grading_system_stddev_samp_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Grading_System_Sum_Fields = {
  __typename?: 'grading_system_sum_fields';
  grading_system_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "grading_system_type" */
export type Grading_System_Type = {
  __typename?: 'grading_system_type';
  name: Scalars['String'];
};

/** aggregated selection of "grading_system_type" */
export type Grading_System_Type_Aggregate = {
  __typename?: 'grading_system_type_aggregate';
  aggregate?: Maybe<Grading_System_Type_Aggregate_Fields>;
  nodes: Array<Grading_System_Type>;
};

/** aggregate fields of "grading_system_type" */
export type Grading_System_Type_Aggregate_Fields = {
  __typename?: 'grading_system_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Grading_System_Type_Max_Fields>;
  min?: Maybe<Grading_System_Type_Min_Fields>;
};


/** aggregate fields of "grading_system_type" */
export type Grading_System_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Grading_System_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "grading_system_type". All fields are combined with a logical 'AND'. */
export type Grading_System_Type_Bool_Exp = {
  _and?: Maybe<Array<Grading_System_Type_Bool_Exp>>;
  _not?: Maybe<Grading_System_Type_Bool_Exp>;
  _or?: Maybe<Array<Grading_System_Type_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "grading_system_type" */
export enum Grading_System_Type_Constraint {
  /** unique or primary key constraint */
  GradingSystemTypePkey = 'grading_system_type_pkey'
}

export enum Grading_System_Type_Enum {
  Checks = 'checks',
  EightPoint = 'eightPoint',
  Esnu = 'esnu',
  Letter = 'letter',
  None = 'none',
  PassFail = 'passFail',
  Percentage = 'percentage',
  SimpleLetter = 'simpleLetter',
  SixPoint = 'sixPoint',
  Stars = 'stars',
  TenPoint = 'tenPoint'
}

/** Boolean expression to compare columns of type "grading_system_type_enum". All fields are combined with logical 'AND'. */
export type Grading_System_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Grading_System_Type_Enum>;
  _in?: Maybe<Array<Grading_System_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Grading_System_Type_Enum>;
  _nin?: Maybe<Array<Grading_System_Type_Enum>>;
};

/** input type for inserting data into table "grading_system_type" */
export type Grading_System_Type_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Grading_System_Type_Max_Fields = {
  __typename?: 'grading_system_type_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Grading_System_Type_Min_Fields = {
  __typename?: 'grading_system_type_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "grading_system_type" */
export type Grading_System_Type_Mutation_Response = {
  __typename?: 'grading_system_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Grading_System_Type>;
};

/** on_conflict condition type for table "grading_system_type" */
export type Grading_System_Type_On_Conflict = {
  constraint: Grading_System_Type_Constraint;
  update_columns?: Array<Grading_System_Type_Update_Column>;
  where?: Maybe<Grading_System_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "grading_system_type". */
export type Grading_System_Type_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: grading_system_type */
export type Grading_System_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "grading_system_type" */
export enum Grading_System_Type_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "grading_system_type" */
export type Grading_System_Type_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "grading_system_type" */
export enum Grading_System_Type_Update_Column {
  /** column name */
  Name = 'name'
}

/** update columns of table "grading_system" */
export enum Grading_System_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradingSystemId = 'grading_system_id',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Values = 'values'
}

/** aggregate var_pop on columns */
export type Grading_System_Var_Pop_Fields = {
  __typename?: 'grading_system_var_pop_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Grading_System_Var_Samp_Fields = {
  __typename?: 'grading_system_var_samp_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Grading_System_Variance_Fields = {
  __typename?: 'grading_system_variance_fields';
  grading_system_id?: Maybe<Scalars['Float']>;
};

export type ImagePathData = {
  __typename?: 'imagePathData';
  image_path?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "integration_name" */
export type Integration_Name = {
  __typename?: 'integration_name';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "integration_name" */
export type Integration_Name_Aggregate = {
  __typename?: 'integration_name_aggregate';
  aggregate?: Maybe<Integration_Name_Aggregate_Fields>;
  nodes: Array<Integration_Name>;
};

/** aggregate fields of "integration_name" */
export type Integration_Name_Aggregate_Fields = {
  __typename?: 'integration_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Integration_Name_Max_Fields>;
  min?: Maybe<Integration_Name_Min_Fields>;
};


/** aggregate fields of "integration_name" */
export type Integration_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Integration_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "integration_name". All fields are combined with a logical 'AND'. */
export type Integration_Name_Bool_Exp = {
  _and?: Maybe<Array<Integration_Name_Bool_Exp>>;
  _not?: Maybe<Integration_Name_Bool_Exp>;
  _or?: Maybe<Array<Integration_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_name" */
export enum Integration_Name_Constraint {
  /** unique or primary key constraint */
  TeacherIntegrationNamePkey = 'teacher_integration_name_pkey'
}

export enum Integration_Name_Enum {
  Canvas = 'canvas',
  ClassLink = 'classLink',
  Clever = 'clever',
  Google = 'google',
  MicrosoftTeams = 'microsoft_teams'
}

/** Boolean expression to compare columns of type "integration_name_enum". All fields are combined with logical 'AND'. */
export type Integration_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Integration_Name_Enum>;
  _in?: Maybe<Array<Integration_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Integration_Name_Enum>;
  _nin?: Maybe<Array<Integration_Name_Enum>>;
};

/** input type for inserting data into table "integration_name" */
export type Integration_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Integration_Name_Max_Fields = {
  __typename?: 'integration_name_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Integration_Name_Min_Fields = {
  __typename?: 'integration_name_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "integration_name" */
export type Integration_Name_Mutation_Response = {
  __typename?: 'integration_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Name>;
};

/** on_conflict condition type for table "integration_name" */
export type Integration_Name_On_Conflict = {
  constraint: Integration_Name_Constraint;
  update_columns?: Array<Integration_Name_Update_Column>;
  where?: Maybe<Integration_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_name". */
export type Integration_Name_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: integration_name */
export type Integration_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "integration_name" */
export enum Integration_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "integration_name" */
export type Integration_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "integration_name" */
export enum Integration_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "invitation_status" */
export type Invitation_Status = {
  __typename?: 'invitation_status';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "invitation_status" */
export type Invitation_Status_Aggregate = {
  __typename?: 'invitation_status_aggregate';
  aggregate?: Maybe<Invitation_Status_Aggregate_Fields>;
  nodes: Array<Invitation_Status>;
};

/** aggregate fields of "invitation_status" */
export type Invitation_Status_Aggregate_Fields = {
  __typename?: 'invitation_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invitation_Status_Max_Fields>;
  min?: Maybe<Invitation_Status_Min_Fields>;
};


/** aggregate fields of "invitation_status" */
export type Invitation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Invitation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "invitation_status". All fields are combined with a logical 'AND'. */
export type Invitation_Status_Bool_Exp = {
  _and?: Maybe<Array<Invitation_Status_Bool_Exp>>;
  _not?: Maybe<Invitation_Status_Bool_Exp>;
  _or?: Maybe<Array<Invitation_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invitation_status" */
export enum Invitation_Status_Constraint {
  /** unique or primary key constraint */
  InvitationStatusPkey = 'invitation_status_pkey'
}

export enum Invitation_Status_Enum {
  Approved = 'approved',
  Created = 'created',
  Denied = 'denied'
}

/** Boolean expression to compare columns of type "invitation_status_enum". All fields are combined with logical 'AND'. */
export type Invitation_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Invitation_Status_Enum>;
  _in?: Maybe<Array<Invitation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Invitation_Status_Enum>;
  _nin?: Maybe<Array<Invitation_Status_Enum>>;
};

/** input type for inserting data into table "invitation_status" */
export type Invitation_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Invitation_Status_Max_Fields = {
  __typename?: 'invitation_status_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invitation_Status_Min_Fields = {
  __typename?: 'invitation_status_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invitation_status" */
export type Invitation_Status_Mutation_Response = {
  __typename?: 'invitation_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitation_Status>;
};

/** on_conflict condition type for table "invitation_status" */
export type Invitation_Status_On_Conflict = {
  constraint: Invitation_Status_Constraint;
  update_columns?: Array<Invitation_Status_Update_Column>;
  where?: Maybe<Invitation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "invitation_status". */
export type Invitation_Status_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: invitation_status */
export type Invitation_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "invitation_status" */
export enum Invitation_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "invitation_status" */
export type Invitation_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "invitation_status" */
export enum Invitation_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Is_Favorite_Lesson_Plan_Args = {
  teacherid?: Maybe<Scalars['Int']>;
};

export type Is_Favorite_Song_Args = {
  teacherid?: Maybe<Scalars['Int']>;
};

export type Is_Favorite_Tip_Args = {
  teacherid?: Maybe<Scalars['Int']>;
};

export type Is_Student_Favorite_Lesson_Plan_Args = {
  studentid?: Maybe<Scalars['Int']>;
};

export type Is_Student_Favorite_Song_Args = {
  studentid?: Maybe<Scalars['Int']>;
};

export type Is_Student_Favorite_Tip_Args = {
  studentid?: Maybe<Scalars['Int']>;
};

export type Is_Teacher_Favorite_Lesson_Plan_Args = {
  teacherid?: Maybe<Scalars['Int']>;
};

export type Is_Teacher_Favorite_Song_Args = {
  teacherid?: Maybe<Scalars['Int']>;
};

export type Is_Teacher_Favorite_Tip_Args = {
  teacherid?: Maybe<Scalars['Int']>;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "keyword" */
export type Keyword = {
  __typename?: 'keyword';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id: Scalars['Int'];
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "keyword" */
export type Keyword_Aggregate = {
  __typename?: 'keyword_aggregate';
  aggregate?: Maybe<Keyword_Aggregate_Fields>;
  nodes: Array<Keyword>;
};

/** aggregate fields of "keyword" */
export type Keyword_Aggregate_Fields = {
  __typename?: 'keyword_aggregate_fields';
  avg?: Maybe<Keyword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Keyword_Max_Fields>;
  min?: Maybe<Keyword_Min_Fields>;
  stddev?: Maybe<Keyword_Stddev_Fields>;
  stddev_pop?: Maybe<Keyword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Keyword_Stddev_Samp_Fields>;
  sum?: Maybe<Keyword_Sum_Fields>;
  var_pop?: Maybe<Keyword_Var_Pop_Fields>;
  var_samp?: Maybe<Keyword_Var_Samp_Fields>;
  variance?: Maybe<Keyword_Variance_Fields>;
};


/** aggregate fields of "keyword" */
export type Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Keyword_Avg_Fields = {
  __typename?: 'keyword_avg_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "keyword". All fields are combined with a logical 'AND'. */
export type Keyword_Bool_Exp = {
  _and?: Maybe<Array<Keyword_Bool_Exp>>;
  _not?: Maybe<Keyword_Bool_Exp>;
  _or?: Maybe<Array<Keyword_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  keyword_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_order?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  song_order?: Maybe<Int_Comparison_Exp>;
  tip_order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "keyword" */
export enum Keyword_Constraint {
  /** unique or primary key constraint */
  KeywordPkey = 'keyword_pkey'
}

/** input type for incrementing numeric columns in table "keyword" */
export type Keyword_Inc_Input = {
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "keyword" */
export type Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Keyword_Max_Fields = {
  __typename?: 'keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Keyword_Min_Fields = {
  __typename?: 'keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "keyword" */
export type Keyword_Mutation_Response = {
  __typename?: 'keyword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Keyword>;
};

/** input type for inserting object relation for remote table "keyword" */
export type Keyword_Obj_Rel_Insert_Input = {
  data: Keyword_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Keyword_On_Conflict>;
};

/** on_conflict condition type for table "keyword" */
export type Keyword_On_Conflict = {
  constraint: Keyword_Constraint;
  update_columns?: Array<Keyword_Update_Column>;
  where?: Maybe<Keyword_Bool_Exp>;
};

/** Ordering options when selecting data from "keyword". */
export type Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  lesson_plan_order?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  song_order?: Maybe<Order_By>;
  tip_order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: keyword */
export type Keyword_Pk_Columns_Input = {
  keyword_id: Scalars['Int'];
};

/** select columns of table "keyword" */
export enum Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  LessonPlanOrder = 'lesson_plan_order',
  /** column name */
  Name = 'name',
  /** column name */
  SongOrder = 'song_order',
  /** column name */
  TipOrder = 'tip_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "keyword" */
export type Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Keyword_Stddev_Fields = {
  __typename?: 'keyword_stddev_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Keyword_Stddev_Pop_Fields = {
  __typename?: 'keyword_stddev_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Keyword_Stddev_Samp_Fields = {
  __typename?: 'keyword_stddev_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Keyword_Sum_Fields = {
  __typename?: 'keyword_sum_fields';
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_order?: Maybe<Scalars['Int']>;
  song_order?: Maybe<Scalars['Int']>;
  tip_order?: Maybe<Scalars['Int']>;
};

/** update columns of table "keyword" */
export enum Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  LessonPlanOrder = 'lesson_plan_order',
  /** column name */
  Name = 'name',
  /** column name */
  SongOrder = 'song_order',
  /** column name */
  TipOrder = 'tip_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Keyword_Var_Pop_Fields = {
  __typename?: 'keyword_var_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Keyword_Var_Samp_Fields = {
  __typename?: 'keyword_var_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Keyword_Variance_Fields = {
  __typename?: 'keyword_variance_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_order?: Maybe<Scalars['Float']>;
  song_order?: Maybe<Scalars['Float']>;
  tip_order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "label_control" */
export type Label_Control = {
  __typename?: 'label_control';
  control: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  label_control_id: Scalars['Int'];
  label_id: Scalars['Int'];
  notes: Scalars['String'];
  track_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "label_control" */
export type Label_Control_Aggregate = {
  __typename?: 'label_control_aggregate';
  aggregate?: Maybe<Label_Control_Aggregate_Fields>;
  nodes: Array<Label_Control>;
};

/** aggregate fields of "label_control" */
export type Label_Control_Aggregate_Fields = {
  __typename?: 'label_control_aggregate_fields';
  avg?: Maybe<Label_Control_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Label_Control_Max_Fields>;
  min?: Maybe<Label_Control_Min_Fields>;
  stddev?: Maybe<Label_Control_Stddev_Fields>;
  stddev_pop?: Maybe<Label_Control_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Label_Control_Stddev_Samp_Fields>;
  sum?: Maybe<Label_Control_Sum_Fields>;
  var_pop?: Maybe<Label_Control_Var_Pop_Fields>;
  var_samp?: Maybe<Label_Control_Var_Samp_Fields>;
  variance?: Maybe<Label_Control_Variance_Fields>;
};


/** aggregate fields of "label_control" */
export type Label_Control_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Label_Control_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Label_Control_Avg_Fields = {
  __typename?: 'label_control_avg_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "label_control". All fields are combined with a logical 'AND'. */
export type Label_Control_Bool_Exp = {
  _and?: Maybe<Array<Label_Control_Bool_Exp>>;
  _not?: Maybe<Label_Control_Bool_Exp>;
  _or?: Maybe<Array<Label_Control_Bool_Exp>>;
  control?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  label_control_id?: Maybe<Int_Comparison_Exp>;
  label_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  track_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "label_control" */
export enum Label_Control_Constraint {
  /** unique or primary key constraint */
  LabelControlPkey = 'label_control_pkey'
}

/** input type for incrementing numeric columns in table "label_control" */
export type Label_Control_Inc_Input = {
  label_control_id?: Maybe<Scalars['Int']>;
  label_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "label_control" */
export type Label_Control_Insert_Input = {
  control?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  label_control_id?: Maybe<Scalars['Int']>;
  label_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Label_Control_Max_Fields = {
  __typename?: 'label_control_max_fields';
  control?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  label_control_id?: Maybe<Scalars['Int']>;
  label_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Label_Control_Min_Fields = {
  __typename?: 'label_control_min_fields';
  control?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  label_control_id?: Maybe<Scalars['Int']>;
  label_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "label_control" */
export type Label_Control_Mutation_Response = {
  __typename?: 'label_control_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Label_Control>;
};

/** on_conflict condition type for table "label_control" */
export type Label_Control_On_Conflict = {
  constraint: Label_Control_Constraint;
  update_columns?: Array<Label_Control_Update_Column>;
  where?: Maybe<Label_Control_Bool_Exp>;
};

/** Ordering options when selecting data from "label_control". */
export type Label_Control_Order_By = {
  control?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  label_control_id?: Maybe<Order_By>;
  label_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: label_control */
export type Label_Control_Pk_Columns_Input = {
  label_control_id: Scalars['Int'];
};

/** select columns of table "label_control" */
export enum Label_Control_Select_Column {
  /** column name */
  Control = 'control',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LabelControlId = 'label_control_id',
  /** column name */
  LabelId = 'label_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "label_control" */
export type Label_Control_Set_Input = {
  control?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  label_control_id?: Maybe<Scalars['Int']>;
  label_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Label_Control_Stddev_Fields = {
  __typename?: 'label_control_stddev_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Label_Control_Stddev_Pop_Fields = {
  __typename?: 'label_control_stddev_pop_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Label_Control_Stddev_Samp_Fields = {
  __typename?: 'label_control_stddev_samp_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Label_Control_Sum_Fields = {
  __typename?: 'label_control_sum_fields';
  label_control_id?: Maybe<Scalars['Int']>;
  label_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "label_control" */
export enum Label_Control_Update_Column {
  /** column name */
  Control = 'control',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LabelControlId = 'label_control_id',
  /** column name */
  LabelId = 'label_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Label_Control_Var_Pop_Fields = {
  __typename?: 'label_control_var_pop_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Label_Control_Var_Samp_Fields = {
  __typename?: 'label_control_var_samp_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Label_Control_Variance_Fields = {
  __typename?: 'label_control_variance_fields';
  label_control_id?: Maybe<Scalars['Float']>;
  label_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "labels" */
export type Labels = {
  __typename?: 'labels';
  created_at?: Maybe<Scalars['timestamptz']>;
  labels_id: Scalars['Int'];
  last_update: Scalars['timestamptz'];
  name: Scalars['String'];
  notes: Scalars['String'];
  primary_contact_email: Scalars['String'];
  primary_contact_name: Scalars['String'];
  publisher_city: Scalars['String'];
  publisher_state: Scalars['String'];
  publisher_street: Scalars['String'];
  publisher_street2: Scalars['String'];
  publisher_zip: Scalars['Int'];
  secondary_contact_email: Scalars['String'];
  secondary_contact_name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "labels" */
export type Labels_Aggregate = {
  __typename?: 'labels_aggregate';
  aggregate?: Maybe<Labels_Aggregate_Fields>;
  nodes: Array<Labels>;
};

/** aggregate fields of "labels" */
export type Labels_Aggregate_Fields = {
  __typename?: 'labels_aggregate_fields';
  avg?: Maybe<Labels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Labels_Max_Fields>;
  min?: Maybe<Labels_Min_Fields>;
  stddev?: Maybe<Labels_Stddev_Fields>;
  stddev_pop?: Maybe<Labels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Labels_Stddev_Samp_Fields>;
  sum?: Maybe<Labels_Sum_Fields>;
  var_pop?: Maybe<Labels_Var_Pop_Fields>;
  var_samp?: Maybe<Labels_Var_Samp_Fields>;
  variance?: Maybe<Labels_Variance_Fields>;
};


/** aggregate fields of "labels" */
export type Labels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Labels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Labels_Avg_Fields = {
  __typename?: 'labels_avg_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "labels". All fields are combined with a logical 'AND'. */
export type Labels_Bool_Exp = {
  _and?: Maybe<Array<Labels_Bool_Exp>>;
  _not?: Maybe<Labels_Bool_Exp>;
  _or?: Maybe<Array<Labels_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  labels_id?: Maybe<Int_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  primary_contact_email?: Maybe<String_Comparison_Exp>;
  primary_contact_name?: Maybe<String_Comparison_Exp>;
  publisher_city?: Maybe<String_Comparison_Exp>;
  publisher_state?: Maybe<String_Comparison_Exp>;
  publisher_street?: Maybe<String_Comparison_Exp>;
  publisher_street2?: Maybe<String_Comparison_Exp>;
  publisher_zip?: Maybe<Int_Comparison_Exp>;
  secondary_contact_email?: Maybe<String_Comparison_Exp>;
  secondary_contact_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "labels" */
export enum Labels_Constraint {
  /** unique or primary key constraint */
  LabelsPkey = 'labels_pkey'
}

/** input type for incrementing numeric columns in table "labels" */
export type Labels_Inc_Input = {
  labels_id?: Maybe<Scalars['Int']>;
  publisher_zip?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "labels" */
export type Labels_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  labels_id?: Maybe<Scalars['Int']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Labels_Max_Fields = {
  __typename?: 'labels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  labels_id?: Maybe<Scalars['Int']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Labels_Min_Fields = {
  __typename?: 'labels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  labels_id?: Maybe<Scalars['Int']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "labels" */
export type Labels_Mutation_Response = {
  __typename?: 'labels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Labels>;
};

/** on_conflict condition type for table "labels" */
export type Labels_On_Conflict = {
  constraint: Labels_Constraint;
  update_columns?: Array<Labels_Update_Column>;
  where?: Maybe<Labels_Bool_Exp>;
};

/** Ordering options when selecting data from "labels". */
export type Labels_Order_By = {
  created_at?: Maybe<Order_By>;
  labels_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  primary_contact_email?: Maybe<Order_By>;
  primary_contact_name?: Maybe<Order_By>;
  publisher_city?: Maybe<Order_By>;
  publisher_state?: Maybe<Order_By>;
  publisher_street?: Maybe<Order_By>;
  publisher_street2?: Maybe<Order_By>;
  publisher_zip?: Maybe<Order_By>;
  secondary_contact_email?: Maybe<Order_By>;
  secondary_contact_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: labels */
export type Labels_Pk_Columns_Input = {
  labels_id: Scalars['Int'];
};

/** select columns of table "labels" */
export enum Labels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LabelsId = 'labels_id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PrimaryContactEmail = 'primary_contact_email',
  /** column name */
  PrimaryContactName = 'primary_contact_name',
  /** column name */
  PublisherCity = 'publisher_city',
  /** column name */
  PublisherState = 'publisher_state',
  /** column name */
  PublisherStreet = 'publisher_street',
  /** column name */
  PublisherStreet2 = 'publisher_street2',
  /** column name */
  PublisherZip = 'publisher_zip',
  /** column name */
  SecondaryContactEmail = 'secondary_contact_email',
  /** column name */
  SecondaryContactName = 'secondary_contact_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "labels" */
export type Labels_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  labels_id?: Maybe<Scalars['Int']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Labels_Stddev_Fields = {
  __typename?: 'labels_stddev_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Labels_Stddev_Pop_Fields = {
  __typename?: 'labels_stddev_pop_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Labels_Stddev_Samp_Fields = {
  __typename?: 'labels_stddev_samp_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Labels_Sum_Fields = {
  __typename?: 'labels_sum_fields';
  labels_id?: Maybe<Scalars['Int']>;
  publisher_zip?: Maybe<Scalars['Int']>;
};

/** update columns of table "labels" */
export enum Labels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LabelsId = 'labels_id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PrimaryContactEmail = 'primary_contact_email',
  /** column name */
  PrimaryContactName = 'primary_contact_name',
  /** column name */
  PublisherCity = 'publisher_city',
  /** column name */
  PublisherState = 'publisher_state',
  /** column name */
  PublisherStreet = 'publisher_street',
  /** column name */
  PublisherStreet2 = 'publisher_street2',
  /** column name */
  PublisherZip = 'publisher_zip',
  /** column name */
  SecondaryContactEmail = 'secondary_contact_email',
  /** column name */
  SecondaryContactName = 'secondary_contact_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Labels_Var_Pop_Fields = {
  __typename?: 'labels_var_pop_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Labels_Var_Samp_Fields = {
  __typename?: 'labels_var_samp_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Labels_Variance_Fields = {
  __typename?: 'labels_variance_fields';
  labels_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

export type LessonPlanAttributes = {
  __typename?: 'lessonPlanAttributes';
  asset?: Maybe<Array<Maybe<AssetT>>>;
  lessonPlan?: Maybe<LessonPlanT>;
  tip?: Maybe<TipT>;
};

export type LessonPlanT = {
  __typename?: 'lessonPlanT';
  image_path?: Maybe<Scalars['String']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "lesson_plan" */
export type Lesson_Plan = {
  __typename?: 'lesson_plan';
  active: Scalars['Boolean'];
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "assignment_created" */
  assignment_created?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  assignments: Array<Assignment>;
  /** An aggregate relationship */
  assignments_aggregate: Assignment_Aggregate;
  available_id: Scalars['Int'];
  /** An array relationship */
  class_lesson_plans: Array<Class_Lesson_Plan>;
  /** An aggregate relationship */
  class_lesson_plans_aggregate: Class_Lesson_Plan_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  differentiations?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['jsonb']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  image_path: Scalars['String'];
  inspire?: Maybe<Scalars['String']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  is_favorite?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_lesson_plan_purchased_by_teacher" */
  is_purchased_by_teacher?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "student_liked_lesson_plan" */
  is_student_favorite?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "teacher_liked_lesson_plan" */
  is_teacher_favorite?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  lesson_plan_articles: Array<Lesson_Plan_Article>;
  /** An aggregate relationship */
  lesson_plan_articles_aggregate: Lesson_Plan_Article_Aggregate;
  /** An array relationship */
  lesson_plan_asset: Array<Lesson_Plan_Asset>;
  /** An aggregate relationship */
  lesson_plan_asset_aggregate: Lesson_Plan_Asset_Aggregate;
  /** An array relationship */
  lesson_plan_catalog_item: Array<Lesson_Plan_Catalog_Item>;
  /** An aggregate relationship */
  lesson_plan_catalog_item_aggregate: Lesson_Plan_Catalog_Item_Aggregate;
  /** An array relationship */
  lesson_plan_disciplines: Array<Lesson_Plan_Discipline>;
  /** An aggregate relationship */
  lesson_plan_disciplines_aggregate: Lesson_Plan_Discipline_Aggregate;
  /** An array relationship */
  lesson_plan_grade: Array<Lesson_Plan_Grade>;
  /** An aggregate relationship */
  lesson_plan_grade_aggregate: Lesson_Plan_Grade_Aggregate;
  lesson_plan_id: Scalars['Int'];
  /** An array relationship */
  lesson_plan_keyword: Array<Lesson_Plan_Keyword>;
  /** An aggregate relationship */
  lesson_plan_keyword_aggregate: Lesson_Plan_Keyword_Aggregate;
  /** An array relationship */
  lesson_plan_musical_skill: Array<Lesson_Plan_Musical_Skill>;
  /** An aggregate relationship */
  lesson_plan_musical_skill_aggregate: Lesson_Plan_Musical_Skill_Aggregate;
  /** An array relationship */
  lesson_plan_non_musical_skill: Array<Lesson_Plan_Non_Musical_Skill>;
  /** An aggregate relationship */
  lesson_plan_non_musical_skill_aggregate: Lesson_Plan_Non_Musical_Skill_Aggregate;
  /** An array relationship */
  lesson_plan_skill_level: Array<Lesson_Plan_Skill_Level>;
  /** An aggregate relationship */
  lesson_plan_skill_level_aggregate: Lesson_Plan_Skill_Level_Aggregate;
  /** An array relationship */
  lesson_plan_song: Array<Lesson_Plan_Song>;
  /** An aggregate relationship */
  lesson_plan_song_aggregate: Lesson_Plan_Song_Aggregate;
  /** An array relationship */
  lesson_plan_standards: Array<Lesson_Plan_Standard>;
  /** An aggregate relationship */
  lesson_plan_standards_aggregate: Lesson_Plan_Standard_Aggregate;
  /** An array relationship */
  lesson_plan_tip: Array<Lesson_Plan_Tip>;
  /** An aggregate relationship */
  lesson_plan_tip_aggregate: Lesson_Plan_Tip_Aggregate;
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  materials_needed?: Maybe<Scalars['String']>;
  /** An array relationship */
  module_lesson_plans: Array<Module_Lesson_Plan>;
  /** An aggregate relationship */
  module_lesson_plans_aggregate: Module_Lesson_Plan_Aggregate;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['jsonb']>;
  record?: Maybe<Scalars['String']>;
  reflect?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  sequence_lesson_plan: Array<Sequence_Lesson_Plan>;
  /** An aggregate relationship */
  sequence_lesson_plan_aggregate: Sequence_Lesson_Plan_Aggregate;
  stripe_product_id?: Maybe<Scalars['String']>;
  suggested_assignments?: Maybe<Scalars['String']>;
  take_it_further?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  teacher_lesson_plan_favorites: Array<Teacher_Lesson_Plan_Favorite>;
  /** An aggregate relationship */
  teacher_lesson_plan_favorites_aggregate: Teacher_Lesson_Plan_Favorite_Aggregate;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanAssignment_CreatedArgs = {
  args: Assignment_Created_Lesson_Plan_Args;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanAssignmentsArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanAssignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanClass_Lesson_PlansArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanClass_Lesson_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanDisciplineArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanIs_FavoriteArgs = {
  args: Is_Favorite_Lesson_Plan_Args;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanIs_Student_FavoriteArgs = {
  args: Is_Student_Favorite_Lesson_Plan_Args;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanIs_Teacher_FavoriteArgs = {
  args: Is_Teacher_Favorite_Lesson_Plan_Args;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_ArticlesArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Article_Order_By>>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Articles_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Article_Order_By>>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_AssetArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Catalog_Item_Order_By>>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Catalog_Item_Order_By>>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_DisciplinesArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Disciplines_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_GradeArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Grade_Order_By>>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Grade_Order_By>>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_KeywordArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Keyword_Order_By>>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Keyword_Order_By>>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Non_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Non_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Skill_LevelArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Skill_Level_Order_By>>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Skill_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Skill_Level_Order_By>>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_SongArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Song_Order_By>>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Song_Order_By>>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_StandardsArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Standard_Order_By>>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Standards_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Standard_Order_By>>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_TipArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Tip_Order_By>>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanLesson_Plan_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Tip_Order_By>>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanModule_Lesson_PlansArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanModule_Lesson_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanProcessArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanSequence_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanSequence_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanTeacher_Lesson_Plan_FavoritesArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


/** columns and relationships of "lesson_plan" */
export type Lesson_PlanTeacher_Lesson_Plan_Favorites_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};

/** aggregated selection of "lesson_plan" */
export type Lesson_Plan_Aggregate = {
  __typename?: 'lesson_plan_aggregate';
  aggregate?: Maybe<Lesson_Plan_Aggregate_Fields>;
  nodes: Array<Lesson_Plan>;
};

/** aggregate fields of "lesson_plan" */
export type Lesson_Plan_Aggregate_Fields = {
  __typename?: 'lesson_plan_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Max_Fields>;
  min?: Maybe<Lesson_Plan_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Variance_Fields>;
};


/** aggregate fields of "lesson_plan" */
export type Lesson_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan" */
export type Lesson_Plan_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lesson_Plan_Append_Input = {
  discipline?: Maybe<Scalars['jsonb']>;
  process?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "lesson_plan" */
export type Lesson_Plan_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_On_Conflict>;
};

/** lesson plan descriptions for display to clients */
export type Lesson_Plan_Article = {
  __typename?: 'lesson_plan_article';
  content: Scalars['String'];
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_article_id: Scalars['Int'];
  lesson_plan_id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

/** aggregated selection of "lesson_plan_article" */
export type Lesson_Plan_Article_Aggregate = {
  __typename?: 'lesson_plan_article_aggregate';
  aggregate?: Maybe<Lesson_Plan_Article_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Article>;
};

/** aggregate fields of "lesson_plan_article" */
export type Lesson_Plan_Article_Aggregate_Fields = {
  __typename?: 'lesson_plan_article_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Article_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Article_Max_Fields>;
  min?: Maybe<Lesson_Plan_Article_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Article_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Article_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Article_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Article_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Article_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Article_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Article_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_article" */
export type Lesson_Plan_Article_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_article" */
export type Lesson_Plan_Article_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Article_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Article_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Article_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Article_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Article_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Article_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Article_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Article_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Article_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Article_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_article" */
export type Lesson_Plan_Article_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Article_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Article_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Article_Avg_Fields = {
  __typename?: 'lesson_plan_article_avg_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Avg_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_article". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Article_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Article_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Article_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Article_Bool_Exp>>;
  content?: Maybe<String_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_article_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_article" */
export enum Lesson_Plan_Article_Constraint {
  /** unique or primary key constraint */
  LessonPlanArticlePkey = 'lesson_plan_article_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_article" */
export type Lesson_Plan_Article_Inc_Input = {
  lesson_plan_article_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_article" */
export type Lesson_Plan_Article_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_article_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Article_Max_Fields = {
  __typename?: 'lesson_plan_article_max_fields';
  content?: Maybe<Scalars['String']>;
  lesson_plan_article_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Max_Order_By = {
  content?: Maybe<Order_By>;
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Article_Min_Fields = {
  __typename?: 'lesson_plan_article_min_fields';
  content?: Maybe<Scalars['String']>;
  lesson_plan_article_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Min_Order_By = {
  content?: Maybe<Order_By>;
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_article" */
export type Lesson_Plan_Article_Mutation_Response = {
  __typename?: 'lesson_plan_article_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Article>;
};

/** on_conflict condition type for table "lesson_plan_article" */
export type Lesson_Plan_Article_On_Conflict = {
  constraint: Lesson_Plan_Article_Constraint;
  update_columns?: Array<Lesson_Plan_Article_Update_Column>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_article". */
export type Lesson_Plan_Article_Order_By = {
  content?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_article */
export type Lesson_Plan_Article_Pk_Columns_Input = {
  lesson_plan_article_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_article" */
export enum Lesson_Plan_Article_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  LessonPlanArticleId = 'lesson_plan_article_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "lesson_plan_article" */
export type Lesson_Plan_Article_Set_Input = {
  content?: Maybe<Scalars['String']>;
  lesson_plan_article_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Article_Stddev_Fields = {
  __typename?: 'lesson_plan_article_stddev_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Stddev_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Article_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_article_stddev_pop_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Stddev_Pop_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Article_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_article_stddev_samp_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Stddev_Samp_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Article_Sum_Fields = {
  __typename?: 'lesson_plan_article_sum_fields';
  lesson_plan_article_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Sum_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_article" */
export enum Lesson_Plan_Article_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  LessonPlanArticleId = 'lesson_plan_article_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Article_Var_Pop_Fields = {
  __typename?: 'lesson_plan_article_var_pop_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Var_Pop_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Article_Var_Samp_Fields = {
  __typename?: 'lesson_plan_article_var_samp_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Var_Samp_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Article_Variance_Fields = {
  __typename?: 'lesson_plan_article_variance_fields';
  lesson_plan_article_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_article" */
export type Lesson_Plan_Article_Variance_Order_By = {
  lesson_plan_article_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** columns and relationships of "lesson_plan_asset" */
export type Lesson_Plan_Asset = {
  __typename?: 'lesson_plan_asset';
  /** An object relationship */
  asset: Asset;
  asset_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_asset_id: Scalars['Int'];
  lesson_plan_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_asset" */
export type Lesson_Plan_Asset_Aggregate = {
  __typename?: 'lesson_plan_asset_aggregate';
  aggregate?: Maybe<Lesson_Plan_Asset_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Asset>;
};

/** aggregate fields of "lesson_plan_asset" */
export type Lesson_Plan_Asset_Aggregate_Fields = {
  __typename?: 'lesson_plan_asset_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Asset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Asset_Max_Fields>;
  min?: Maybe<Lesson_Plan_Asset_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Asset_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Asset_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Asset_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_asset" */
export type Lesson_Plan_Asset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Asset_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Asset_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Asset_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Asset_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Asset_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Asset_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Asset_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Asset_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Asset_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Asset_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Asset_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Asset_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Asset_Avg_Fields = {
  __typename?: 'lesson_plan_asset_avg_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Avg_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_asset". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Asset_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Asset_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Asset_Bool_Exp>>;
  asset?: Maybe<Asset_Bool_Exp>;
  asset_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_asset_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_asset" */
export enum Lesson_Plan_Asset_Constraint {
  /** unique or primary key constraint */
  LessonPlanAssetLessonPlanIdAssetIdKey = 'lesson_plan_asset_lesson_plan_id_asset_id_key',
  /** unique or primary key constraint */
  LessonPlanAssetPkey = 'lesson_plan_asset_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Inc_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Insert_Input = {
  asset?: Maybe<Asset_Obj_Rel_Insert_Input>;
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Asset_Max_Fields = {
  __typename?: 'lesson_plan_asset_max_fields';
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Max_Order_By = {
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Asset_Min_Fields = {
  __typename?: 'lesson_plan_asset_min_fields';
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Min_Order_By = {
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Mutation_Response = {
  __typename?: 'lesson_plan_asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Asset>;
};

/** on_conflict condition type for table "lesson_plan_asset" */
export type Lesson_Plan_Asset_On_Conflict = {
  constraint: Lesson_Plan_Asset_Constraint;
  update_columns?: Array<Lesson_Plan_Asset_Update_Column>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_asset". */
export type Lesson_Plan_Asset_Order_By = {
  asset?: Maybe<Asset_Order_By>;
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_asset */
export type Lesson_Plan_Asset_Pk_Columns_Input = {
  lesson_plan_asset_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_asset" */
export enum Lesson_Plan_Asset_Select_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanAssetId = 'lesson_plan_asset_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Set_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Asset_Stddev_Fields = {
  __typename?: 'lesson_plan_asset_stddev_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Stddev_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Asset_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_asset_stddev_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Stddev_Pop_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Asset_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_asset_stddev_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Stddev_Samp_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Asset_Sum_Fields = {
  __typename?: 'lesson_plan_asset_sum_fields';
  asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_asset_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Sum_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_asset" */
export enum Lesson_Plan_Asset_Update_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanAssetId = 'lesson_plan_asset_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Asset_Var_Pop_Fields = {
  __typename?: 'lesson_plan_asset_var_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Var_Pop_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Asset_Var_Samp_Fields = {
  __typename?: 'lesson_plan_asset_var_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Var_Samp_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Asset_Variance_Fields = {
  __typename?: 'lesson_plan_asset_variance_fields';
  asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_asset_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_asset" */
export type Lesson_Plan_Asset_Variance_Order_By = {
  asset_id?: Maybe<Order_By>;
  lesson_plan_asset_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Avg_Fields = {
  __typename?: 'lesson_plan_avg_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan" */
export type Lesson_Plan_Avg_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  annual_price?: Maybe<Int_Comparison_Exp>;
  annual_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  assignments?: Maybe<Assignment_Bool_Exp>;
  available_id?: Maybe<Int_Comparison_Exp>;
  class_lesson_plans?: Maybe<Class_Lesson_Plan_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  differentiations?: Maybe<String_Comparison_Exp>;
  discipline?: Maybe<Jsonb_Comparison_Exp>;
  district_price?: Maybe<Int_Comparison_Exp>;
  district_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  featured?: Maybe<Boolean_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  inspire?: Maybe<String_Comparison_Exp>;
  is_draft?: Maybe<Boolean_Comparison_Exp>;
  is_purchased_by_teacher?: Maybe<Boolean_Comparison_Exp>;
  lesson_plan_articles?: Maybe<Lesson_Plan_Article_Bool_Exp>;
  lesson_plan_asset?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
  lesson_plan_catalog_item?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
  lesson_plan_disciplines?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
  lesson_plan_grade?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_keyword?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
  lesson_plan_musical_skill?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
  lesson_plan_non_musical_skill?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
  lesson_plan_skill_level?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
  lesson_plan_song?: Maybe<Lesson_Plan_Song_Bool_Exp>;
  lesson_plan_standards?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
  lesson_plan_tip?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  materials_needed?: Maybe<String_Comparison_Exp>;
  module_lesson_plans?: Maybe<Module_Lesson_Plan_Bool_Exp>;
  monthly_price?: Maybe<Int_Comparison_Exp>;
  monthly_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<String_Comparison_Exp>;
  process?: Maybe<Jsonb_Comparison_Exp>;
  record?: Maybe<String_Comparison_Exp>;
  reflect?: Maybe<String_Comparison_Exp>;
  school_price?: Maybe<Int_Comparison_Exp>;
  school_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  sequence_lesson_plan?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
  stripe_product_id?: Maybe<String_Comparison_Exp>;
  suggested_assignments?: Maybe<String_Comparison_Exp>;
  take_it_further?: Maybe<String_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_lesson_plan_favorites?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item = {
  __typename?: 'lesson_plan_catalog_item';
  /** An object relationship */
  catalog_item: Catalog_Item;
  catalog_item_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_catalog_item_id: Scalars['Int'];
  lesson_plan_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Aggregate = {
  __typename?: 'lesson_plan_catalog_item_aggregate';
  aggregate?: Maybe<Lesson_Plan_Catalog_Item_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Catalog_Item>;
};

/** aggregate fields of "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Aggregate_Fields = {
  __typename?: 'lesson_plan_catalog_item_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Catalog_Item_Max_Fields>;
  min?: Maybe<Lesson_Plan_Catalog_Item_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Catalog_Item_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Catalog_Item_Avg_Fields = {
  __typename?: 'lesson_plan_catalog_item_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_catalog_item". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Catalog_Item_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_catalog_item_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_catalog_item" */
export enum Lesson_Plan_Catalog_Item_Constraint {
  /** unique or primary key constraint */
  LessonPlanCatalogItemLessonPlanIdCatalogItemIdKey = 'lesson_plan_catalog_item_lesson_plan_id_catalog_item_id_key',
  /** unique or primary key constraint */
  LessonPlanCatalogItemPkey = 'lesson_plan_catalog_item_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Catalog_Item_Max_Fields = {
  __typename?: 'lesson_plan_catalog_item_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Catalog_Item_Min_Fields = {
  __typename?: 'lesson_plan_catalog_item_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Mutation_Response = {
  __typename?: 'lesson_plan_catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Catalog_Item>;
};

/** on_conflict condition type for table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_On_Conflict = {
  constraint: Lesson_Plan_Catalog_Item_Constraint;
  update_columns?: Array<Lesson_Plan_Catalog_Item_Update_Column>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_catalog_item". */
export type Lesson_Plan_Catalog_Item_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_catalog_item */
export type Lesson_Plan_Catalog_Item_Pk_Columns_Input = {
  lesson_plan_catalog_item_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_catalog_item" */
export enum Lesson_Plan_Catalog_Item_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanCatalogItemId = 'lesson_plan_catalog_item_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Catalog_Item_Stddev_Fields = {
  __typename?: 'lesson_plan_catalog_item_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_catalog_item_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_catalog_item_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Catalog_Item_Sum_Fields = {
  __typename?: 'lesson_plan_catalog_item_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_catalog_item" */
export enum Lesson_Plan_Catalog_Item_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanCatalogItemId = 'lesson_plan_catalog_item_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Catalog_Item_Var_Pop_Fields = {
  __typename?: 'lesson_plan_catalog_item_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Catalog_Item_Var_Samp_Fields = {
  __typename?: 'lesson_plan_catalog_item_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Catalog_Item_Variance_Fields = {
  __typename?: 'lesson_plan_catalog_item_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_catalog_item_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_catalog_item" */
export type Lesson_Plan_Catalog_Item_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  lesson_plan_catalog_item_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "lesson_plan" */
export enum Lesson_Plan_Constraint {
  /** unique or primary key constraint */
  LessonPlanAnnualPriceStripePriceIdKey = 'lesson_plan_annual_price_stripe_price_id_key',
  /** unique or primary key constraint */
  LessonPlanLockableContentIdKey = 'lesson_plan_lockable_content_id_key',
  /** unique or primary key constraint */
  LessonPlanMonthlyPriceStripePriceIdKey = 'lesson_plan_monthly_price_stripe_price_id_key',
  /** unique or primary key constraint */
  LessonPlanPkey = 'lesson_plan_pkey',
  /** unique or primary key constraint */
  LessonPlanStripeProductIdKey = 'lesson_plan_stripe_product_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lesson_Plan_Delete_At_Path_Input = {
  discipline?: Maybe<Array<Scalars['String']>>;
  process?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lesson_Plan_Delete_Elem_Input = {
  discipline?: Maybe<Scalars['Int']>;
  process?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lesson_Plan_Delete_Key_Input = {
  discipline?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['String']>;
};

/** columns and relationships of "lesson_plan_discipline" */
export type Lesson_Plan_Discipline = {
  __typename?: 'lesson_plan_discipline';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  discipline: Discipline;
  discipline_id: Scalars['Int'];
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_discipline_id: Scalars['Int'];
  lesson_plan_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Aggregate = {
  __typename?: 'lesson_plan_discipline_aggregate';
  aggregate?: Maybe<Lesson_Plan_Discipline_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Discipline>;
};

/** aggregate fields of "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Aggregate_Fields = {
  __typename?: 'lesson_plan_discipline_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Discipline_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Discipline_Max_Fields>;
  min?: Maybe<Lesson_Plan_Discipline_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Discipline_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Discipline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Discipline_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Discipline_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Discipline_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Discipline_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Discipline_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Discipline_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Discipline_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Discipline_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Discipline_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Discipline_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Discipline_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Discipline_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Discipline_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Discipline_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Discipline_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Discipline_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Discipline_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Discipline_Avg_Fields = {
  __typename?: 'lesson_plan_discipline_avg_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Avg_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_discipline". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Discipline_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Discipline_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Discipline_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  discipline?: Maybe<Discipline_Bool_Exp>;
  discipline_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_discipline_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_discipline" */
export enum Lesson_Plan_Discipline_Constraint {
  /** unique or primary key constraint */
  LessonPlanDisciplineLessonPlanIdDisciplineIdKey = 'lesson_plan_discipline_lesson_plan_id_discipline_id_key',
  /** unique or primary key constraint */
  LessonPlanDisciplinePkey = 'lesson_plan_discipline_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Inc_Input = {
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  discipline?: Maybe<Discipline_Obj_Rel_Insert_Input>;
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Discipline_Max_Fields = {
  __typename?: 'lesson_plan_discipline_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Discipline_Min_Fields = {
  __typename?: 'lesson_plan_discipline_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Mutation_Response = {
  __typename?: 'lesson_plan_discipline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Discipline>;
};

/** on_conflict condition type for table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_On_Conflict = {
  constraint: Lesson_Plan_Discipline_Constraint;
  update_columns?: Array<Lesson_Plan_Discipline_Update_Column>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_discipline". */
export type Lesson_Plan_Discipline_Order_By = {
  created_at?: Maybe<Order_By>;
  discipline?: Maybe<Discipline_Order_By>;
  discipline_id?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_discipline */
export type Lesson_Plan_Discipline_Pk_Columns_Input = {
  lesson_plan_discipline_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_discipline" */
export enum Lesson_Plan_Discipline_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisciplineId = 'discipline_id',
  /** column name */
  LessonPlanDisciplineId = 'lesson_plan_discipline_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Discipline_Stddev_Fields = {
  __typename?: 'lesson_plan_discipline_stddev_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Stddev_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Discipline_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_discipline_stddev_pop_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Stddev_Pop_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Discipline_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_discipline_stddev_samp_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Stddev_Samp_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Discipline_Sum_Fields = {
  __typename?: 'lesson_plan_discipline_sum_fields';
  discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Sum_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_discipline" */
export enum Lesson_Plan_Discipline_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisciplineId = 'discipline_id',
  /** column name */
  LessonPlanDisciplineId = 'lesson_plan_discipline_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Discipline_Var_Pop_Fields = {
  __typename?: 'lesson_plan_discipline_var_pop_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Var_Pop_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Discipline_Var_Samp_Fields = {
  __typename?: 'lesson_plan_discipline_var_samp_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Var_Samp_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Discipline_Variance_Fields = {
  __typename?: 'lesson_plan_discipline_variance_fields';
  discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_discipline_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_discipline" */
export type Lesson_Plan_Discipline_Variance_Order_By = {
  discipline_id?: Maybe<Order_By>;
  lesson_plan_discipline_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** columns and relationships of "lesson_plan_grade" */
export type Lesson_Plan_Grade = {
  __typename?: 'lesson_plan_grade';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  grade: Grade;
  grade_id: Scalars['Int'];
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_grade_id: Scalars['Int'];
  lesson_plan_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_grade" */
export type Lesson_Plan_Grade_Aggregate = {
  __typename?: 'lesson_plan_grade_aggregate';
  aggregate?: Maybe<Lesson_Plan_Grade_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Grade>;
};

/** aggregate fields of "lesson_plan_grade" */
export type Lesson_Plan_Grade_Aggregate_Fields = {
  __typename?: 'lesson_plan_grade_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Grade_Max_Fields>;
  min?: Maybe<Lesson_Plan_Grade_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Grade_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Grade_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Grade_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_grade" */
export type Lesson_Plan_Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Grade_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Grade_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Grade_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Grade_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Grade_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Grade_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Grade_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Grade_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Grade_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Grade_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Grade_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Grade_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Grade_Avg_Fields = {
  __typename?: 'lesson_plan_grade_avg_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Avg_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_grade". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Grade_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Grade_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Grade_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grade?: Maybe<Grade_Bool_Exp>;
  grade_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_grade_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_grade" */
export enum Lesson_Plan_Grade_Constraint {
  /** unique or primary key constraint */
  LessonPlanGradeLessonPlanIdGradeIdKey = 'lesson_plan_grade_lesson_plan_id_grade_id_key',
  /** unique or primary key constraint */
  LessonPlanGradePkey = 'lesson_plan_grade_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Inc_Input = {
  grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade?: Maybe<Grade_Obj_Rel_Insert_Input>;
  grade_id?: Maybe<Scalars['Int']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Grade_Max_Fields = {
  __typename?: 'lesson_plan_grade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Grade_Min_Fields = {
  __typename?: 'lesson_plan_grade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Mutation_Response = {
  __typename?: 'lesson_plan_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Grade>;
};

/** on_conflict condition type for table "lesson_plan_grade" */
export type Lesson_Plan_Grade_On_Conflict = {
  constraint: Lesson_Plan_Grade_Constraint;
  update_columns?: Array<Lesson_Plan_Grade_Update_Column>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_grade". */
export type Lesson_Plan_Grade_Order_By = {
  created_at?: Maybe<Order_By>;
  grade?: Maybe<Grade_Order_By>;
  grade_id?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_grade */
export type Lesson_Plan_Grade_Pk_Columns_Input = {
  lesson_plan_grade_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_grade" */
export enum Lesson_Plan_Grade_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  LessonPlanGradeId = 'lesson_plan_grade_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Grade_Stddev_Fields = {
  __typename?: 'lesson_plan_grade_stddev_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Stddev_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Grade_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_grade_stddev_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Stddev_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Grade_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_grade_stddev_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Stddev_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Grade_Sum_Fields = {
  __typename?: 'lesson_plan_grade_sum_fields';
  grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_grade_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Sum_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_grade" */
export enum Lesson_Plan_Grade_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  LessonPlanGradeId = 'lesson_plan_grade_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Grade_Var_Pop_Fields = {
  __typename?: 'lesson_plan_grade_var_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Var_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Grade_Var_Samp_Fields = {
  __typename?: 'lesson_plan_grade_var_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Var_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Grade_Variance_Fields = {
  __typename?: 'lesson_plan_grade_variance_fields';
  grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_grade_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_grade" */
export type Lesson_Plan_Grade_Variance_Order_By = {
  grade_id?: Maybe<Order_By>;
  lesson_plan_grade_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "lesson_plan" */
export type Lesson_Plan_Inc_Input = {
  annual_price?: Maybe<Scalars['Int']>;
  available_id?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan" */
export type Lesson_Plan_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  assignments?: Maybe<Assignment_Arr_Rel_Insert_Input>;
  available_id?: Maybe<Scalars['Int']>;
  class_lesson_plans?: Maybe<Class_Lesson_Plan_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  differentiations?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['jsonb']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  inspire?: Maybe<Scalars['String']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  lesson_plan_articles?: Maybe<Lesson_Plan_Article_Arr_Rel_Insert_Input>;
  lesson_plan_asset?: Maybe<Lesson_Plan_Asset_Arr_Rel_Insert_Input>;
  lesson_plan_catalog_item?: Maybe<Lesson_Plan_Catalog_Item_Arr_Rel_Insert_Input>;
  lesson_plan_disciplines?: Maybe<Lesson_Plan_Discipline_Arr_Rel_Insert_Input>;
  lesson_plan_grade?: Maybe<Lesson_Plan_Grade_Arr_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword?: Maybe<Lesson_Plan_Keyword_Arr_Rel_Insert_Input>;
  lesson_plan_musical_skill?: Maybe<Lesson_Plan_Musical_Skill_Arr_Rel_Insert_Input>;
  lesson_plan_non_musical_skill?: Maybe<Lesson_Plan_Non_Musical_Skill_Arr_Rel_Insert_Input>;
  lesson_plan_skill_level?: Maybe<Lesson_Plan_Skill_Level_Arr_Rel_Insert_Input>;
  lesson_plan_song?: Maybe<Lesson_Plan_Song_Arr_Rel_Insert_Input>;
  lesson_plan_standards?: Maybe<Lesson_Plan_Standard_Arr_Rel_Insert_Input>;
  lesson_plan_tip?: Maybe<Lesson_Plan_Tip_Arr_Rel_Insert_Input>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  materials_needed?: Maybe<Scalars['String']>;
  module_lesson_plans?: Maybe<Module_Lesson_Plan_Arr_Rel_Insert_Input>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['jsonb']>;
  record?: Maybe<Scalars['String']>;
  reflect?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  sequence_lesson_plan?: Maybe<Sequence_Lesson_Plan_Arr_Rel_Insert_Input>;
  stripe_product_id?: Maybe<Scalars['String']>;
  suggested_assignments?: Maybe<Scalars['String']>;
  take_it_further?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorites?: Maybe<Teacher_Lesson_Plan_Favorite_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "lesson_plan_keyword" */
export type Lesson_Plan_Keyword = {
  __typename?: 'lesson_plan_keyword';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  keyword: Keyword;
  keyword_id: Scalars['Int'];
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  lesson_plan_keyword_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Aggregate = {
  __typename?: 'lesson_plan_keyword_aggregate';
  aggregate?: Maybe<Lesson_Plan_Keyword_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Keyword>;
};

/** aggregate fields of "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Aggregate_Fields = {
  __typename?: 'lesson_plan_keyword_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Keyword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Keyword_Max_Fields>;
  min?: Maybe<Lesson_Plan_Keyword_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Keyword_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Keyword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Keyword_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Keyword_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Keyword_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Keyword_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Keyword_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Keyword_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Keyword_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Keyword_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Keyword_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Keyword_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Keyword_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Keyword_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Keyword_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Keyword_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Keyword_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Keyword_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Keyword_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Keyword_Avg_Fields = {
  __typename?: 'lesson_plan_keyword_avg_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Avg_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_keyword". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Keyword_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Keyword_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Keyword_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  keyword?: Maybe<Keyword_Bool_Exp>;
  keyword_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_keyword_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_keyword" */
export enum Lesson_Plan_Keyword_Constraint {
  /** unique or primary key constraint */
  LessonPlanKeywordLessonPlanIdKeywordIdKey = 'lesson_plan_keyword_lesson_plan_id_keyword_id_key',
  /** unique or primary key constraint */
  LessonPlanKeywordPkey = 'lesson_plan_keyword_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Inc_Input = {
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword?: Maybe<Keyword_Obj_Rel_Insert_Input>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Keyword_Max_Fields = {
  __typename?: 'lesson_plan_keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Keyword_Min_Fields = {
  __typename?: 'lesson_plan_keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Mutation_Response = {
  __typename?: 'lesson_plan_keyword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Keyword>;
};

/** on_conflict condition type for table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_On_Conflict = {
  constraint: Lesson_Plan_Keyword_Constraint;
  update_columns?: Array<Lesson_Plan_Keyword_Update_Column>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_keyword". */
export type Lesson_Plan_Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword?: Maybe<Keyword_Order_By>;
  keyword_id?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_keyword */
export type Lesson_Plan_Keyword_Pk_Columns_Input = {
  lesson_plan_keyword_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_keyword" */
export enum Lesson_Plan_Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanKeywordId = 'lesson_plan_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Keyword_Stddev_Fields = {
  __typename?: 'lesson_plan_keyword_stddev_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Stddev_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Keyword_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_keyword_stddev_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Stddev_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Keyword_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_keyword_stddev_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Stddev_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Keyword_Sum_Fields = {
  __typename?: 'lesson_plan_keyword_sum_fields';
  keyword_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Sum_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_keyword" */
export enum Lesson_Plan_Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanKeywordId = 'lesson_plan_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Keyword_Var_Pop_Fields = {
  __typename?: 'lesson_plan_keyword_var_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Var_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Keyword_Var_Samp_Fields = {
  __typename?: 'lesson_plan_keyword_var_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Var_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Keyword_Variance_Fields = {
  __typename?: 'lesson_plan_keyword_variance_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_keyword" */
export type Lesson_Plan_Keyword_Variance_Order_By = {
  keyword_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Lesson_Plan_Max_Fields = {
  __typename?: 'lesson_plan_max_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  differentiations?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  inspire?: Maybe<Scalars['String']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  materials_needed?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Scalars['String']>;
  record?: Maybe<Scalars['String']>;
  reflect?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  suggested_assignments?: Maybe<Scalars['String']>;
  take_it_further?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan" */
export type Lesson_Plan_Max_Order_By = {
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  differentiations?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  inspire?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  materials_needed?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  practice?: Maybe<Order_By>;
  record?: Maybe<Order_By>;
  reflect?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  suggested_assignments?: Maybe<Order_By>;
  take_it_further?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Min_Fields = {
  __typename?: 'lesson_plan_min_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  differentiations?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  inspire?: Maybe<Scalars['String']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  materials_needed?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Scalars['String']>;
  record?: Maybe<Scalars['String']>;
  reflect?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  suggested_assignments?: Maybe<Scalars['String']>;
  take_it_further?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan" */
export type Lesson_Plan_Min_Order_By = {
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  differentiations?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  inspire?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  materials_needed?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  practice?: Maybe<Order_By>;
  record?: Maybe<Order_By>;
  reflect?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  suggested_assignments?: Maybe<Order_By>;
  take_it_further?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill = {
  __typename?: 'lesson_plan_musical_skill';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  lesson_plan_musical_skill_id: Scalars['Int'];
  /** An object relationship */
  musical_skill: Musical_Skill;
  musical_skill_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Aggregate = {
  __typename?: 'lesson_plan_musical_skill_aggregate';
  aggregate?: Maybe<Lesson_Plan_Musical_Skill_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Musical_Skill>;
};

/** aggregate fields of "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Aggregate_Fields = {
  __typename?: 'lesson_plan_musical_skill_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Musical_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Musical_Skill_Max_Fields>;
  min?: Maybe<Lesson_Plan_Musical_Skill_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Musical_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Musical_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Musical_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Musical_Skill_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Musical_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Musical_Skill_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Musical_Skill_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Musical_Skill_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Musical_Skill_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Musical_Skill_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Musical_Skill_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Musical_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Musical_Skill_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Musical_Skill_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Musical_Skill_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Musical_Skill_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Musical_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Musical_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Musical_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Musical_Skill_Avg_Fields = {
  __typename?: 'lesson_plan_musical_skill_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_musical_skill". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Musical_Skill_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Musical_Skill_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Musical_Skill_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  musical_skill?: Maybe<Musical_Skill_Bool_Exp>;
  musical_skill_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_musical_skill" */
export enum Lesson_Plan_Musical_Skill_Constraint {
  /** unique or primary key constraint */
  LessonPlanMusicalSkillLessonPlanIdMusicalSkillIdKey = 'lesson_plan_musical_skill_lesson_plan_id_musical_skill_id_key',
  /** unique or primary key constraint */
  LessonPlanMusicalSkillPkey = 'lesson_plan_musical_skill_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Int']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Int']>;
  musical_skill?: Maybe<Musical_Skill_Obj_Rel_Insert_Input>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Musical_Skill_Max_Fields = {
  __typename?: 'lesson_plan_musical_skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Int']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Musical_Skill_Min_Fields = {
  __typename?: 'lesson_plan_musical_skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Int']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Mutation_Response = {
  __typename?: 'lesson_plan_musical_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Musical_Skill>;
};

/** on_conflict condition type for table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_On_Conflict = {
  constraint: Lesson_Plan_Musical_Skill_Constraint;
  update_columns?: Array<Lesson_Plan_Musical_Skill_Update_Column>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_musical_skill". */
export type Lesson_Plan_Musical_Skill_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill?: Maybe<Musical_Skill_Order_By>;
  musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_musical_skill */
export type Lesson_Plan_Musical_Skill_Pk_Columns_Input = {
  lesson_plan_musical_skill_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_musical_skill" */
export enum Lesson_Plan_Musical_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanMusicalSkillId = 'lesson_plan_musical_skill_id',
  /** column name */
  MusicalSkillId = 'musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Int']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Musical_Skill_Stddev_Fields = {
  __typename?: 'lesson_plan_musical_skill_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Musical_Skill_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_musical_skill_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Musical_Skill_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_musical_skill_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Musical_Skill_Sum_Fields = {
  __typename?: 'lesson_plan_musical_skill_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Int']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_musical_skill" */
export enum Lesson_Plan_Musical_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanMusicalSkillId = 'lesson_plan_musical_skill_id',
  /** column name */
  MusicalSkillId = 'musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Musical_Skill_Var_Pop_Fields = {
  __typename?: 'lesson_plan_musical_skill_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Musical_Skill_Var_Samp_Fields = {
  __typename?: 'lesson_plan_musical_skill_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Musical_Skill_Variance_Fields = {
  __typename?: 'lesson_plan_musical_skill_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_musical_skill_id?: Maybe<Scalars['Float']>;
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_musical_skill" */
export type Lesson_Plan_Musical_Skill_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_musical_skill_id?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan" */
export type Lesson_Plan_Mutation_Response = {
  __typename?: 'lesson_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan>;
};

/** columns and relationships of "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill = {
  __typename?: 'lesson_plan_non_musical_skill';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  lesson_plan_non_musical_skill_id: Scalars['Int'];
  /** An object relationship */
  non_musical_skill: Non_Musical_Skill;
  non_musical_skill_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Aggregate = {
  __typename?: 'lesson_plan_non_musical_skill_aggregate';
  aggregate?: Maybe<Lesson_Plan_Non_Musical_Skill_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Non_Musical_Skill>;
};

/** aggregate fields of "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Aggregate_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Non_Musical_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Non_Musical_Skill_Max_Fields>;
  min?: Maybe<Lesson_Plan_Non_Musical_Skill_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Non_Musical_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Non_Musical_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Non_Musical_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Non_Musical_Skill_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Non_Musical_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Non_Musical_Skill_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Non_Musical_Skill_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Non_Musical_Skill_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Non_Musical_Skill_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Non_Musical_Skill_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Non_Musical_Skill_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Non_Musical_Skill_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Non_Musical_Skill_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Non_Musical_Skill_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Non_Musical_Skill_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Non_Musical_Skill_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Non_Musical_Skill_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Non_Musical_Skill_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Non_Musical_Skill_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Non_Musical_Skill_Avg_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_non_musical_skill". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Non_Musical_Skill_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_non_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  non_musical_skill?: Maybe<Non_Musical_Skill_Bool_Exp>;
  non_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_non_musical_skill" */
export enum Lesson_Plan_Non_Musical_Skill_Constraint {
  /** unique or primary key constraint */
  LessonPlanNonMusicalSkillLessonPlanIdNonMusicalSkill = 'lesson_plan_non_musical_skill_lesson_plan_id_non_musical_skill_',
  /** unique or primary key constraint */
  LessonPlanNonMusicalSkillPkey = 'lesson_plan_non_musical_skill_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Int']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Int']>;
  non_musical_skill?: Maybe<Non_Musical_Skill_Obj_Rel_Insert_Input>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Non_Musical_Skill_Max_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Int']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Non_Musical_Skill_Min_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Int']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Mutation_Response = {
  __typename?: 'lesson_plan_non_musical_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Non_Musical_Skill>;
};

/** on_conflict condition type for table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_On_Conflict = {
  constraint: Lesson_Plan_Non_Musical_Skill_Constraint;
  update_columns?: Array<Lesson_Plan_Non_Musical_Skill_Update_Column>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_non_musical_skill". */
export type Lesson_Plan_Non_Musical_Skill_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill?: Maybe<Non_Musical_Skill_Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_non_musical_skill */
export type Lesson_Plan_Non_Musical_Skill_Pk_Columns_Input = {
  lesson_plan_non_musical_skill_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_non_musical_skill" */
export enum Lesson_Plan_Non_Musical_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanNonMusicalSkillId = 'lesson_plan_non_musical_skill_id',
  /** column name */
  NonMusicalSkillId = 'non_musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Int']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Non_Musical_Skill_Stddev_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Non_Musical_Skill_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Non_Musical_Skill_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Non_Musical_Skill_Sum_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Int']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_non_musical_skill" */
export enum Lesson_Plan_Non_Musical_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanNonMusicalSkillId = 'lesson_plan_non_musical_skill_id',
  /** column name */
  NonMusicalSkillId = 'non_musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Non_Musical_Skill_Var_Pop_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Non_Musical_Skill_Var_Samp_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Non_Musical_Skill_Variance_Fields = {
  __typename?: 'lesson_plan_non_musical_skill_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_non_musical_skill_id?: Maybe<Scalars['Float']>;
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_non_musical_skill" */
export type Lesson_Plan_Non_Musical_Skill_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_non_musical_skill_id?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "lesson_plan" */
export type Lesson_Plan_Obj_Rel_Insert_Input = {
  data: Lesson_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_On_Conflict>;
};

/** on_conflict condition type for table "lesson_plan" */
export type Lesson_Plan_On_Conflict = {
  constraint: Lesson_Plan_Constraint;
  update_columns?: Array<Lesson_Plan_Update_Column>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan". */
export type Lesson_Plan_Order_By = {
  active?: Maybe<Order_By>;
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  assignments_aggregate?: Maybe<Assignment_Aggregate_Order_By>;
  available_id?: Maybe<Order_By>;
  class_lesson_plans_aggregate?: Maybe<Class_Lesson_Plan_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  differentiations?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  featured?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  inspire?: Maybe<Order_By>;
  is_draft?: Maybe<Order_By>;
  is_purchased_by_teacher?: Maybe<Order_By>;
  lesson_plan_articles_aggregate?: Maybe<Lesson_Plan_Article_Aggregate_Order_By>;
  lesson_plan_asset_aggregate?: Maybe<Lesson_Plan_Asset_Aggregate_Order_By>;
  lesson_plan_catalog_item_aggregate?: Maybe<Lesson_Plan_Catalog_Item_Aggregate_Order_By>;
  lesson_plan_disciplines_aggregate?: Maybe<Lesson_Plan_Discipline_Aggregate_Order_By>;
  lesson_plan_grade_aggregate?: Maybe<Lesson_Plan_Grade_Aggregate_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_keyword_aggregate?: Maybe<Lesson_Plan_Keyword_Aggregate_Order_By>;
  lesson_plan_musical_skill_aggregate?: Maybe<Lesson_Plan_Musical_Skill_Aggregate_Order_By>;
  lesson_plan_non_musical_skill_aggregate?: Maybe<Lesson_Plan_Non_Musical_Skill_Aggregate_Order_By>;
  lesson_plan_skill_level_aggregate?: Maybe<Lesson_Plan_Skill_Level_Aggregate_Order_By>;
  lesson_plan_song_aggregate?: Maybe<Lesson_Plan_Song_Aggregate_Order_By>;
  lesson_plan_standards_aggregate?: Maybe<Lesson_Plan_Standard_Aggregate_Order_By>;
  lesson_plan_tip_aggregate?: Maybe<Lesson_Plan_Tip_Aggregate_Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  materials_needed?: Maybe<Order_By>;
  module_lesson_plans_aggregate?: Maybe<Module_Lesson_Plan_Aggregate_Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  practice?: Maybe<Order_By>;
  process?: Maybe<Order_By>;
  record?: Maybe<Order_By>;
  reflect?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  sequence_lesson_plan_aggregate?: Maybe<Sequence_Lesson_Plan_Aggregate_Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  suggested_assignments?: Maybe<Order_By>;
  take_it_further?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorites_aggregate?: Maybe<Teacher_Lesson_Plan_Favorite_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan */
export type Lesson_Plan_Pk_Columns_Input = {
  lesson_plan_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lesson_Plan_Prepend_Input = {
  discipline?: Maybe<Scalars['jsonb']>;
  process?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "lesson_plan" */
export enum Lesson_Plan_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  AvailableId = 'available_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Differentiations = 'differentiations',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Inspire = 'inspire',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  MaterialsNeeded = 'materials_needed',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Practice = 'practice',
  /** column name */
  Process = 'process',
  /** column name */
  Record = 'record',
  /** column name */
  Reflect = 'reflect',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  SuggestedAssignments = 'suggested_assignments',
  /** column name */
  TakeItFurther = 'take_it_further',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan" */
export type Lesson_Plan_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  differentiations?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['jsonb']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  inspire?: Maybe<Scalars['String']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  materials_needed?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['jsonb']>;
  record?: Maybe<Scalars['String']>;
  reflect?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  suggested_assignments?: Maybe<Scalars['String']>;
  take_it_further?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level = {
  __typename?: 'lesson_plan_skill_level';
  created_at: Scalars['timestamptz'];
  lesson_plan_id: Scalars['Int'];
  lesson_plan_skill_level_id: Scalars['Int'];
  skill_level_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Aggregate = {
  __typename?: 'lesson_plan_skill_level_aggregate';
  aggregate?: Maybe<Lesson_Plan_Skill_Level_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Skill_Level>;
};

/** aggregate fields of "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Aggregate_Fields = {
  __typename?: 'lesson_plan_skill_level_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Skill_Level_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Skill_Level_Max_Fields>;
  min?: Maybe<Lesson_Plan_Skill_Level_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Skill_Level_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Skill_Level_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Skill_Level_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Skill_Level_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Skill_Level_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Skill_Level_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Skill_Level_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Skill_Level_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Skill_Level_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Skill_Level_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Skill_Level_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Skill_Level_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Skill_Level_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Skill_Level_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Skill_Level_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Skill_Level_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Skill_Level_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Skill_Level_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Skill_Level_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Skill_Level_Avg_Fields = {
  __typename?: 'lesson_plan_skill_level_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_skill_level". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Skill_Level_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Skill_Level_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Skill_Level_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_skill_level_id?: Maybe<Int_Comparison_Exp>;
  skill_level_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_skill_level" */
export enum Lesson_Plan_Skill_Level_Constraint {
  /** unique or primary key constraint */
  LessonPlanSkillLevelLessonPlanIdSkillLevelIdKey = 'lesson_plan_skill_level_lesson_plan_id_skill_level_id_key',
  /** unique or primary key constraint */
  LessonPlanSkillLevelPkey = 'lesson_plan_skill_level_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Skill_Level_Max_Fields = {
  __typename?: 'lesson_plan_skill_level_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Skill_Level_Min_Fields = {
  __typename?: 'lesson_plan_skill_level_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Mutation_Response = {
  __typename?: 'lesson_plan_skill_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Skill_Level>;
};

/** on_conflict condition type for table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_On_Conflict = {
  constraint: Lesson_Plan_Skill_Level_Constraint;
  update_columns?: Array<Lesson_Plan_Skill_Level_Update_Column>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_skill_level". */
export type Lesson_Plan_Skill_Level_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_skill_level */
export type Lesson_Plan_Skill_Level_Pk_Columns_Input = {
  lesson_plan_skill_level_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_skill_level" */
export enum Lesson_Plan_Skill_Level_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanSkillLevelId = 'lesson_plan_skill_level_id',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Skill_Level_Stddev_Fields = {
  __typename?: 'lesson_plan_skill_level_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Skill_Level_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_skill_level_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Skill_Level_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_skill_level_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Skill_Level_Sum_Fields = {
  __typename?: 'lesson_plan_skill_level_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_skill_level" */
export enum Lesson_Plan_Skill_Level_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanSkillLevelId = 'lesson_plan_skill_level_id',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Skill_Level_Var_Pop_Fields = {
  __typename?: 'lesson_plan_skill_level_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Skill_Level_Var_Samp_Fields = {
  __typename?: 'lesson_plan_skill_level_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Skill_Level_Variance_Fields = {
  __typename?: 'lesson_plan_skill_level_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_skill_level_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_skill_level" */
export type Lesson_Plan_Skill_Level_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_skill_level_id?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
};

/** columns and relationships of "lesson_plan_song" */
export type Lesson_Plan_Song = {
  __typename?: 'lesson_plan_song';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  lesson_plan_song_id: Scalars['Int'];
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_song" */
export type Lesson_Plan_Song_Aggregate = {
  __typename?: 'lesson_plan_song_aggregate';
  aggregate?: Maybe<Lesson_Plan_Song_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Song>;
};

/** aggregate fields of "lesson_plan_song" */
export type Lesson_Plan_Song_Aggregate_Fields = {
  __typename?: 'lesson_plan_song_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Song_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Song_Max_Fields>;
  min?: Maybe<Lesson_Plan_Song_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Song_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Song_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Song_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Song_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Song_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Song_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Song_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_song" */
export type Lesson_Plan_Song_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_song" */
export type Lesson_Plan_Song_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Song_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Song_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Song_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Song_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Song_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Song_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Song_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Song_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Song_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Song_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_song" */
export type Lesson_Plan_Song_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Song_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Song_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Song_Avg_Fields = {
  __typename?: 'lesson_plan_song_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_song". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Song_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Song_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Song_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Song_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_song_id?: Maybe<Int_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_song" */
export enum Lesson_Plan_Song_Constraint {
  /** unique or primary key constraint */
  LessonPlanSongLessonPlanIdSongIdKey = 'lesson_plan_song_lesson_plan_id_song_id_key',
  /** unique or primary key constraint */
  LessonPlanSongPkey1 = 'lesson_plan_song_pkey1'
}

/** input type for incrementing numeric columns in table "lesson_plan_song" */
export type Lesson_Plan_Song_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_song" */
export type Lesson_Plan_Song_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_song_id?: Maybe<Scalars['Int']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Song_Max_Fields = {
  __typename?: 'lesson_plan_song_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Song_Min_Fields = {
  __typename?: 'lesson_plan_song_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_song" */
export type Lesson_Plan_Song_Mutation_Response = {
  __typename?: 'lesson_plan_song_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Song>;
};

/** on_conflict condition type for table "lesson_plan_song" */
export type Lesson_Plan_Song_On_Conflict = {
  constraint: Lesson_Plan_Song_Constraint;
  update_columns?: Array<Lesson_Plan_Song_Update_Column>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_song". */
export type Lesson_Plan_Song_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_song */
export type Lesson_Plan_Song_Pk_Columns_Input = {
  lesson_plan_song_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_song" */
export enum Lesson_Plan_Song_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanSongId = 'lesson_plan_song_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_song" */
export type Lesson_Plan_Song_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Song_Stddev_Fields = {
  __typename?: 'lesson_plan_song_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Song_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_song_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Song_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_song_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Song_Sum_Fields = {
  __typename?: 'lesson_plan_song_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_song_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_song" */
export enum Lesson_Plan_Song_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanSongId = 'lesson_plan_song_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Song_Var_Pop_Fields = {
  __typename?: 'lesson_plan_song_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Song_Var_Samp_Fields = {
  __typename?: 'lesson_plan_song_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Song_Variance_Fields = {
  __typename?: 'lesson_plan_song_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_song_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_song" */
export type Lesson_Plan_Song_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_song_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** columns and relationships of "lesson_plan_standard" */
export type Lesson_Plan_Standard = {
  __typename?: 'lesson_plan_standard';
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  lesson_plan_standar_id: Scalars['Int'];
  standar_option_id: Scalars['Int'];
  /** An object relationship */
  standard_option: Standard_Option;
};

/** aggregated selection of "lesson_plan_standard" */
export type Lesson_Plan_Standard_Aggregate = {
  __typename?: 'lesson_plan_standard_aggregate';
  aggregate?: Maybe<Lesson_Plan_Standard_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Standard>;
};

/** aggregate fields of "lesson_plan_standard" */
export type Lesson_Plan_Standard_Aggregate_Fields = {
  __typename?: 'lesson_plan_standard_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Standard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Standard_Max_Fields>;
  min?: Maybe<Lesson_Plan_Standard_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Standard_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Standard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Standard_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Standard_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Standard_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Standard_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Standard_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_standard" */
export type Lesson_Plan_Standard_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Standard_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Standard_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Standard_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Standard_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Standard_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Standard_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Standard_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Standard_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Standard_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Standard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Standard_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Standard_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Standard_Avg_Fields = {
  __typename?: 'lesson_plan_standard_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_standard". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Standard_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Standard_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Standard_Bool_Exp>>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_standar_id?: Maybe<Int_Comparison_Exp>;
  standar_option_id?: Maybe<Int_Comparison_Exp>;
  standard_option?: Maybe<Standard_Option_Bool_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_standard" */
export enum Lesson_Plan_Standard_Constraint {
  /** unique or primary key constraint */
  LessonPlanStandarPkey = 'lesson_plan_standar_pkey'
}

/** input type for incrementing numeric columns in table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_standar_id?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Insert_Input = {
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_standar_id?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standard_option?: Maybe<Standard_Option_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lesson_Plan_Standard_Max_Fields = {
  __typename?: 'lesson_plan_standard_max_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_standar_id?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Max_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Standard_Min_Fields = {
  __typename?: 'lesson_plan_standard_min_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_standar_id?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Min_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Mutation_Response = {
  __typename?: 'lesson_plan_standard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Standard>;
};

/** on_conflict condition type for table "lesson_plan_standard" */
export type Lesson_Plan_Standard_On_Conflict = {
  constraint: Lesson_Plan_Standard_Constraint;
  update_columns?: Array<Lesson_Plan_Standard_Update_Column>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_standard". */
export type Lesson_Plan_Standard_Order_By = {
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standard_option?: Maybe<Standard_Option_Order_By>;
};

/** primary key columns input for table: lesson_plan_standard */
export type Lesson_Plan_Standard_Pk_Columns_Input = {
  lesson_plan_standar_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_standard" */
export enum Lesson_Plan_Standard_Select_Column {
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanStandarId = 'lesson_plan_standar_id',
  /** column name */
  StandarOptionId = 'standar_option_id'
}

/** input type for updating data in table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Set_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_standar_id?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Standard_Stddev_Fields = {
  __typename?: 'lesson_plan_standard_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Standard_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_standard_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Standard_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_standard_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Standard_Sum_Fields = {
  __typename?: 'lesson_plan_standard_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_standar_id?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_standard" */
export enum Lesson_Plan_Standard_Update_Column {
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanStandarId = 'lesson_plan_standar_id',
  /** column name */
  StandarOptionId = 'standar_option_id'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Standard_Var_Pop_Fields = {
  __typename?: 'lesson_plan_standard_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Standard_Var_Samp_Fields = {
  __typename?: 'lesson_plan_standard_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Standard_Variance_Fields = {
  __typename?: 'lesson_plan_standard_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_standar_id?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_standard" */
export type Lesson_Plan_Standard_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_standar_id?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Stddev_Fields = {
  __typename?: 'lesson_plan_stddev_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan" */
export type Lesson_Plan_Stddev_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_stddev_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan" */
export type Lesson_Plan_Stddev_Pop_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_stddev_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan" */
export type Lesson_Plan_Stddev_Samp_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Sum_Fields = {
  __typename?: 'lesson_plan_sum_fields';
  annual_price?: Maybe<Scalars['Int']>;
  available_id?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan" */
export type Lesson_Plan_Sum_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** columns and relationships of "lesson_plan_tip" */
export type Lesson_Plan_Tip = {
  __typename?: 'lesson_plan_tip';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  lesson_plan_tip_id: Scalars['Int'];
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_plan_tip" */
export type Lesson_Plan_Tip_Aggregate = {
  __typename?: 'lesson_plan_tip_aggregate';
  aggregate?: Maybe<Lesson_Plan_Tip_Aggregate_Fields>;
  nodes: Array<Lesson_Plan_Tip>;
};

/** aggregate fields of "lesson_plan_tip" */
export type Lesson_Plan_Tip_Aggregate_Fields = {
  __typename?: 'lesson_plan_tip_aggregate_fields';
  avg?: Maybe<Lesson_Plan_Tip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lesson_Plan_Tip_Max_Fields>;
  min?: Maybe<Lesson_Plan_Tip_Min_Fields>;
  stddev?: Maybe<Lesson_Plan_Tip_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Plan_Tip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Plan_Tip_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Plan_Tip_Sum_Fields>;
  var_pop?: Maybe<Lesson_Plan_Tip_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Plan_Tip_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Plan_Tip_Variance_Fields>;
};


/** aggregate fields of "lesson_plan_tip" */
export type Lesson_Plan_Tip_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Aggregate_Order_By = {
  avg?: Maybe<Lesson_Plan_Tip_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lesson_Plan_Tip_Max_Order_By>;
  min?: Maybe<Lesson_Plan_Tip_Min_Order_By>;
  stddev?: Maybe<Lesson_Plan_Tip_Stddev_Order_By>;
  stddev_pop?: Maybe<Lesson_Plan_Tip_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lesson_Plan_Tip_Stddev_Samp_Order_By>;
  sum?: Maybe<Lesson_Plan_Tip_Sum_Order_By>;
  var_pop?: Maybe<Lesson_Plan_Tip_Var_Pop_Order_By>;
  var_samp?: Maybe<Lesson_Plan_Tip_Var_Samp_Order_By>;
  variance?: Maybe<Lesson_Plan_Tip_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Plan_Tip_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lesson_Plan_Tip_On_Conflict>;
};

/** aggregate avg on columns */
export type Lesson_Plan_Tip_Avg_Fields = {
  __typename?: 'lesson_plan_tip_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_plan_tip". All fields are combined with a logical 'AND'. */
export type Lesson_Plan_Tip_Bool_Exp = {
  _and?: Maybe<Array<Lesson_Plan_Tip_Bool_Exp>>;
  _not?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
  _or?: Maybe<Array<Lesson_Plan_Tip_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  lesson_plan_tip_id?: Maybe<Int_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lesson_plan_tip" */
export enum Lesson_Plan_Tip_Constraint {
  /** unique or primary key constraint */
  LessonPlanTipLessonPlanIdTipIdKey = 'lesson_plan_tip_lesson_plan_id_tip_id_key',
  /** unique or primary key constraint */
  LessonPlanTipPkey1 = 'lesson_plan_tip_pkey1'
}

/** input type for incrementing numeric columns in table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_tip_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lesson_Plan_Tip_Max_Fields = {
  __typename?: 'lesson_plan_tip_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Plan_Tip_Min_Fields = {
  __typename?: 'lesson_plan_tip_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Mutation_Response = {
  __typename?: 'lesson_plan_tip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lesson_Plan_Tip>;
};

/** on_conflict condition type for table "lesson_plan_tip" */
export type Lesson_Plan_Tip_On_Conflict = {
  constraint: Lesson_Plan_Tip_Constraint;
  update_columns?: Array<Lesson_Plan_Tip_Update_Column>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};

/** Ordering options when selecting data from "lesson_plan_tip". */
export type Lesson_Plan_Tip_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lesson_plan_tip */
export type Lesson_Plan_Tip_Pk_Columns_Input = {
  lesson_plan_tip_id: Scalars['Int'];
};

/** select columns of table "lesson_plan_tip" */
export enum Lesson_Plan_Tip_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanTipId = 'lesson_plan_tip_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Lesson_Plan_Tip_Stddev_Fields = {
  __typename?: 'lesson_plan_tip_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Plan_Tip_Stddev_Pop_Fields = {
  __typename?: 'lesson_plan_tip_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Plan_Tip_Stddev_Samp_Fields = {
  __typename?: 'lesson_plan_tip_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Plan_Tip_Sum_Fields = {
  __typename?: 'lesson_plan_tip_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  lesson_plan_tip_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan_tip" */
export enum Lesson_Plan_Tip_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LessonPlanTipId = 'lesson_plan_tip_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Tip_Var_Pop_Fields = {
  __typename?: 'lesson_plan_tip_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Tip_Var_Samp_Fields = {
  __typename?: 'lesson_plan_tip_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Tip_Variance_Fields = {
  __typename?: 'lesson_plan_tip_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lesson_plan_tip_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan_tip" */
export type Lesson_Plan_Tip_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  lesson_plan_tip_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "lesson_plan" */
export enum Lesson_Plan_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  AvailableId = 'available_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Differentiations = 'differentiations',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Inspire = 'inspire',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  MaterialsNeeded = 'materials_needed',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Practice = 'practice',
  /** column name */
  Process = 'process',
  /** column name */
  Record = 'record',
  /** column name */
  Reflect = 'reflect',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  SuggestedAssignments = 'suggested_assignments',
  /** column name */
  TakeItFurther = 'take_it_further',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Lesson_Plan_Var_Pop_Fields = {
  __typename?: 'lesson_plan_var_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_plan" */
export type Lesson_Plan_Var_Pop_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Plan_Var_Samp_Fields = {
  __typename?: 'lesson_plan_var_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_plan" */
export type Lesson_Plan_Var_Samp_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Plan_Variance_Fields = {
  __typename?: 'lesson_plan_variance_fields';
  annual_price?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_plan" */
export type Lesson_Plan_Variance_Order_By = {
  annual_price?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_Content = {
  __typename?: 'lockable_content';
  /** An object relationship */
  channel?: Maybe<Channel>;
  /** An array relationship */
  classes: Array<Lockable_Content_Class>;
  /** An aggregate relationship */
  classes_aggregate: Lockable_Content_Class_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  districts: Array<Lockable_Content_District>;
  /** An aggregate relationship */
  districts_aggregate: Lockable_Content_District_Aggregate;
  /** An object relationship */
  lesson_plan?: Maybe<Lesson_Plan>;
  lockable_content_id: Scalars['Int'];
  /** An object relationship */
  module?: Maybe<Module>;
  /** An object relationship */
  partner?: Maybe<Partner>;
  /** An object relationship */
  playlist?: Maybe<Playlist>;
  /** An array relationship */
  schools: Array<Lockable_Content_School>;
  /** An aggregate relationship */
  schools_aggregate: Lockable_Content_School_Aggregate;
  /** An object relationship */
  sequence?: Maybe<Sequence>;
  /** An object relationship */
  sequence_topic?: Maybe<Sequence_Topic>;
  /** An object relationship */
  song?: Maybe<Song>;
  table: Scalars['String'];
  /** An array relationship */
  teachers: Array<Lockable_Content_Teacher>;
  /** An aggregate relationship */
  teachers_aggregate: Lockable_Content_Teacher_Aggregate;
  /** An object relationship */
  tip?: Maybe<Tip>;
  updated_at: Scalars['timestamptz'];
  visibility: Visibility_Enum;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentClassesArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Class_Order_By>>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentClasses_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Class_Order_By>>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentDistrictsArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentDistricts_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentSchoolsArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentSchools_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentTeachersArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Teacher_Order_By>>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};


/** This table will be related to any wurrly resource to define the level of access */
export type Lockable_ContentTeachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Teacher_Order_By>>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};

/** aggregated selection of "lockable_content" */
export type Lockable_Content_Aggregate = {
  __typename?: 'lockable_content_aggregate';
  aggregate?: Maybe<Lockable_Content_Aggregate_Fields>;
  nodes: Array<Lockable_Content>;
};

/** aggregate fields of "lockable_content" */
export type Lockable_Content_Aggregate_Fields = {
  __typename?: 'lockable_content_aggregate_fields';
  avg?: Maybe<Lockable_Content_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lockable_Content_Max_Fields>;
  min?: Maybe<Lockable_Content_Min_Fields>;
  stddev?: Maybe<Lockable_Content_Stddev_Fields>;
  stddev_pop?: Maybe<Lockable_Content_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lockable_Content_Stddev_Samp_Fields>;
  sum?: Maybe<Lockable_Content_Sum_Fields>;
  var_pop?: Maybe<Lockable_Content_Var_Pop_Fields>;
  var_samp?: Maybe<Lockable_Content_Var_Samp_Fields>;
  variance?: Maybe<Lockable_Content_Variance_Fields>;
};


/** aggregate fields of "lockable_content" */
export type Lockable_Content_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lockable_Content_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Lockable_Content_Avg_Fields = {
  __typename?: 'lockable_content_avg_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "lockable_content". All fields are combined with a logical 'AND'. */
export type Lockable_Content_Bool_Exp = {
  _and?: Maybe<Array<Lockable_Content_Bool_Exp>>;
  _not?: Maybe<Lockable_Content_Bool_Exp>;
  _or?: Maybe<Array<Lockable_Content_Bool_Exp>>;
  channel?: Maybe<Channel_Bool_Exp>;
  classes?: Maybe<Lockable_Content_Class_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  districts?: Maybe<Lockable_Content_District_Bool_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  partner?: Maybe<Partner_Bool_Exp>;
  playlist?: Maybe<Playlist_Bool_Exp>;
  schools?: Maybe<Lockable_Content_School_Bool_Exp>;
  sequence?: Maybe<Sequence_Bool_Exp>;
  sequence_topic?: Maybe<Sequence_Topic_Bool_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  table?: Maybe<String_Comparison_Exp>;
  teachers?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  visibility?: Maybe<Visibility_Enum_Comparison_Exp>;
};

/** columns and relationships of "lockable_content_class" */
export type Lockable_Content_Class = {
  __typename?: 'lockable_content_class';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  lockable_content_id: Scalars['Int'];
};

/** aggregated selection of "lockable_content_class" */
export type Lockable_Content_Class_Aggregate = {
  __typename?: 'lockable_content_class_aggregate';
  aggregate?: Maybe<Lockable_Content_Class_Aggregate_Fields>;
  nodes: Array<Lockable_Content_Class>;
};

/** aggregate fields of "lockable_content_class" */
export type Lockable_Content_Class_Aggregate_Fields = {
  __typename?: 'lockable_content_class_aggregate_fields';
  avg?: Maybe<Lockable_Content_Class_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lockable_Content_Class_Max_Fields>;
  min?: Maybe<Lockable_Content_Class_Min_Fields>;
  stddev?: Maybe<Lockable_Content_Class_Stddev_Fields>;
  stddev_pop?: Maybe<Lockable_Content_Class_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lockable_Content_Class_Stddev_Samp_Fields>;
  sum?: Maybe<Lockable_Content_Class_Sum_Fields>;
  var_pop?: Maybe<Lockable_Content_Class_Var_Pop_Fields>;
  var_samp?: Maybe<Lockable_Content_Class_Var_Samp_Fields>;
  variance?: Maybe<Lockable_Content_Class_Variance_Fields>;
};


/** aggregate fields of "lockable_content_class" */
export type Lockable_Content_Class_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lockable_content_class" */
export type Lockable_Content_Class_Aggregate_Order_By = {
  avg?: Maybe<Lockable_Content_Class_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lockable_Content_Class_Max_Order_By>;
  min?: Maybe<Lockable_Content_Class_Min_Order_By>;
  stddev?: Maybe<Lockable_Content_Class_Stddev_Order_By>;
  stddev_pop?: Maybe<Lockable_Content_Class_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lockable_Content_Class_Stddev_Samp_Order_By>;
  sum?: Maybe<Lockable_Content_Class_Sum_Order_By>;
  var_pop?: Maybe<Lockable_Content_Class_Var_Pop_Order_By>;
  var_samp?: Maybe<Lockable_Content_Class_Var_Samp_Order_By>;
  variance?: Maybe<Lockable_Content_Class_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lockable_content_class" */
export type Lockable_Content_Class_Arr_Rel_Insert_Input = {
  data: Array<Lockable_Content_Class_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lockable_Content_Class_On_Conflict>;
};

/** aggregate avg on columns */
export type Lockable_Content_Class_Avg_Fields = {
  __typename?: 'lockable_content_class_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lockable_content_class". All fields are combined with a logical 'AND'. */
export type Lockable_Content_Class_Bool_Exp = {
  _and?: Maybe<Array<Lockable_Content_Class_Bool_Exp>>;
  _not?: Maybe<Lockable_Content_Class_Bool_Exp>;
  _or?: Maybe<Array<Lockable_Content_Class_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lockable_content_class" */
export enum Lockable_Content_Class_Constraint {
  /** unique or primary key constraint */
  LockableContentClassPkey = 'lockable_content_class_pkey'
}

/** input type for incrementing numeric columns in table "lockable_content_class" */
export type Lockable_Content_Class_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lockable_content_class" */
export type Lockable_Content_Class_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lockable_Content_Class_Max_Fields = {
  __typename?: 'lockable_content_class_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lockable_Content_Class_Min_Fields = {
  __typename?: 'lockable_content_class_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lockable_content_class" */
export type Lockable_Content_Class_Mutation_Response = {
  __typename?: 'lockable_content_class_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lockable_Content_Class>;
};

/** on_conflict condition type for table "lockable_content_class" */
export type Lockable_Content_Class_On_Conflict = {
  constraint: Lockable_Content_Class_Constraint;
  update_columns?: Array<Lockable_Content_Class_Update_Column>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};

/** Ordering options when selecting data from "lockable_content_class". */
export type Lockable_Content_Class_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** primary key columns input for table: lockable_content_class */
export type Lockable_Content_Class_Pk_Columns_Input = {
  class_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};

/** select columns of table "lockable_content_class" */
export enum Lockable_Content_Class_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id'
}

/** input type for updating data in table "lockable_content_class" */
export type Lockable_Content_Class_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lockable_Content_Class_Stddev_Fields = {
  __typename?: 'lockable_content_class_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lockable_Content_Class_Stddev_Pop_Fields = {
  __typename?: 'lockable_content_class_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lockable_Content_Class_Stddev_Samp_Fields = {
  __typename?: 'lockable_content_class_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lockable_Content_Class_Sum_Fields = {
  __typename?: 'lockable_content_class_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** update columns of table "lockable_content_class" */
export enum Lockable_Content_Class_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id'
}

/** aggregate var_pop on columns */
export type Lockable_Content_Class_Var_Pop_Fields = {
  __typename?: 'lockable_content_class_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lockable_Content_Class_Var_Samp_Fields = {
  __typename?: 'lockable_content_class_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lockable_Content_Class_Variance_Fields = {
  __typename?: 'lockable_content_class_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lockable_content_class" */
export type Lockable_Content_Class_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "lockable_content" */
export enum Lockable_Content_Constraint {
  /** unique or primary key constraint */
  LockabelContentPkey = 'lockabel_content_pkey'
}

/** columns and relationships of "lockable_content_district" */
export type Lockable_Content_District = {
  __typename?: 'lockable_content_district';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  district: District;
  district_id: Scalars['Int'];
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id: Scalars['Int'];
  /** An object relationship */
  module?: Maybe<Module>;
};

/** aggregated selection of "lockable_content_district" */
export type Lockable_Content_District_Aggregate = {
  __typename?: 'lockable_content_district_aggregate';
  aggregate?: Maybe<Lockable_Content_District_Aggregate_Fields>;
  nodes: Array<Lockable_Content_District>;
};

/** aggregate fields of "lockable_content_district" */
export type Lockable_Content_District_Aggregate_Fields = {
  __typename?: 'lockable_content_district_aggregate_fields';
  avg?: Maybe<Lockable_Content_District_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lockable_Content_District_Max_Fields>;
  min?: Maybe<Lockable_Content_District_Min_Fields>;
  stddev?: Maybe<Lockable_Content_District_Stddev_Fields>;
  stddev_pop?: Maybe<Lockable_Content_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lockable_Content_District_Stddev_Samp_Fields>;
  sum?: Maybe<Lockable_Content_District_Sum_Fields>;
  var_pop?: Maybe<Lockable_Content_District_Var_Pop_Fields>;
  var_samp?: Maybe<Lockable_Content_District_Var_Samp_Fields>;
  variance?: Maybe<Lockable_Content_District_Variance_Fields>;
};


/** aggregate fields of "lockable_content_district" */
export type Lockable_Content_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lockable_content_district" */
export type Lockable_Content_District_Aggregate_Order_By = {
  avg?: Maybe<Lockable_Content_District_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lockable_Content_District_Max_Order_By>;
  min?: Maybe<Lockable_Content_District_Min_Order_By>;
  stddev?: Maybe<Lockable_Content_District_Stddev_Order_By>;
  stddev_pop?: Maybe<Lockable_Content_District_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lockable_Content_District_Stddev_Samp_Order_By>;
  sum?: Maybe<Lockable_Content_District_Sum_Order_By>;
  var_pop?: Maybe<Lockable_Content_District_Var_Pop_Order_By>;
  var_samp?: Maybe<Lockable_Content_District_Var_Samp_Order_By>;
  variance?: Maybe<Lockable_Content_District_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lockable_content_district" */
export type Lockable_Content_District_Arr_Rel_Insert_Input = {
  data: Array<Lockable_Content_District_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lockable_Content_District_On_Conflict>;
};

/** aggregate avg on columns */
export type Lockable_Content_District_Avg_Fields = {
  __typename?: 'lockable_content_district_avg_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Avg_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lockable_content_district". All fields are combined with a logical 'AND'. */
export type Lockable_Content_District_Bool_Exp = {
  _and?: Maybe<Array<Lockable_Content_District_Bool_Exp>>;
  _not?: Maybe<Lockable_Content_District_Bool_Exp>;
  _or?: Maybe<Array<Lockable_Content_District_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  district?: Maybe<District_Bool_Exp>;
  district_id?: Maybe<Int_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
};

/** unique or primary key constraints on table "lockable_content_district" */
export enum Lockable_Content_District_Constraint {
  /** unique or primary key constraint */
  LockableContentDistrictPkey = 'lockable_content_district_pkey'
}

/** input type for incrementing numeric columns in table "lockable_content_district" */
export type Lockable_Content_District_Inc_Input = {
  district_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lockable_content_district" */
export type Lockable_Content_District_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<District_Obj_Rel_Insert_Input>;
  district_id?: Maybe<Scalars['Int']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lockable_Content_District_Max_Fields = {
  __typename?: 'lockable_content_district_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lockable_Content_District_Min_Fields = {
  __typename?: 'lockable_content_district_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lockable_content_district" */
export type Lockable_Content_District_Mutation_Response = {
  __typename?: 'lockable_content_district_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lockable_Content_District>;
};

/** on_conflict condition type for table "lockable_content_district" */
export type Lockable_Content_District_On_Conflict = {
  constraint: Lockable_Content_District_Constraint;
  update_columns?: Array<Lockable_Content_District_Update_Column>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};

/** Ordering options when selecting data from "lockable_content_district". */
export type Lockable_Content_District_Order_By = {
  created_at?: Maybe<Order_By>;
  district?: Maybe<District_Order_By>;
  district_id?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
};

/** primary key columns input for table: lockable_content_district */
export type Lockable_Content_District_Pk_Columns_Input = {
  district_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};

/** select columns of table "lockable_content_district" */
export enum Lockable_Content_District_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  LockableContentId = 'lockable_content_id'
}

/** input type for updating data in table "lockable_content_district" */
export type Lockable_Content_District_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lockable_Content_District_Stddev_Fields = {
  __typename?: 'lockable_content_district_stddev_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Stddev_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lockable_Content_District_Stddev_Pop_Fields = {
  __typename?: 'lockable_content_district_stddev_pop_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Stddev_Pop_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lockable_Content_District_Stddev_Samp_Fields = {
  __typename?: 'lockable_content_district_stddev_samp_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Stddev_Samp_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lockable_Content_District_Sum_Fields = {
  __typename?: 'lockable_content_district_sum_fields';
  district_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Sum_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** update columns of table "lockable_content_district" */
export enum Lockable_Content_District_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  LockableContentId = 'lockable_content_id'
}

/** aggregate var_pop on columns */
export type Lockable_Content_District_Var_Pop_Fields = {
  __typename?: 'lockable_content_district_var_pop_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Var_Pop_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lockable_Content_District_Var_Samp_Fields = {
  __typename?: 'lockable_content_district_var_samp_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Var_Samp_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lockable_Content_District_Variance_Fields = {
  __typename?: 'lockable_content_district_variance_fields';
  district_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lockable_content_district" */
export type Lockable_Content_District_Variance_Order_By = {
  district_id?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "lockable_content" */
export type Lockable_Content_Inc_Input = {
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lockable_content" */
export type Lockable_Content_Insert_Input = {
  channel?: Maybe<Channel_Obj_Rel_Insert_Input>;
  classes?: Maybe<Lockable_Content_Class_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  districts?: Maybe<Lockable_Content_District_Arr_Rel_Insert_Input>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  partner?: Maybe<Partner_Obj_Rel_Insert_Input>;
  playlist?: Maybe<Playlist_Obj_Rel_Insert_Input>;
  schools?: Maybe<Lockable_Content_School_Arr_Rel_Insert_Input>;
  sequence?: Maybe<Sequence_Obj_Rel_Insert_Input>;
  sequence_topic?: Maybe<Sequence_Topic_Obj_Rel_Insert_Input>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  table?: Maybe<Scalars['String']>;
  teachers?: Maybe<Lockable_Content_Teacher_Arr_Rel_Insert_Input>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visibility?: Maybe<Visibility_Enum>;
};

/** aggregate max on columns */
export type Lockable_Content_Max_Fields = {
  __typename?: 'lockable_content_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  table?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Lockable_Content_Min_Fields = {
  __typename?: 'lockable_content_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  table?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "lockable_content" */
export type Lockable_Content_Mutation_Response = {
  __typename?: 'lockable_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lockable_Content>;
};

/** input type for inserting object relation for remote table "lockable_content" */
export type Lockable_Content_Obj_Rel_Insert_Input = {
  data: Lockable_Content_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Lockable_Content_On_Conflict>;
};

/** on_conflict condition type for table "lockable_content" */
export type Lockable_Content_On_Conflict = {
  constraint: Lockable_Content_Constraint;
  update_columns?: Array<Lockable_Content_Update_Column>;
  where?: Maybe<Lockable_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "lockable_content". */
export type Lockable_Content_Order_By = {
  channel?: Maybe<Channel_Order_By>;
  classes_aggregate?: Maybe<Lockable_Content_Class_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  districts_aggregate?: Maybe<Lockable_Content_District_Aggregate_Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  partner?: Maybe<Partner_Order_By>;
  playlist?: Maybe<Playlist_Order_By>;
  schools_aggregate?: Maybe<Lockable_Content_School_Aggregate_Order_By>;
  sequence?: Maybe<Sequence_Order_By>;
  sequence_topic?: Maybe<Sequence_Topic_Order_By>;
  song?: Maybe<Song_Order_By>;
  table?: Maybe<Order_By>;
  teachers_aggregate?: Maybe<Lockable_Content_Teacher_Aggregate_Order_By>;
  tip?: Maybe<Tip_Order_By>;
  updated_at?: Maybe<Order_By>;
  visibility?: Maybe<Order_By>;
};

/** primary key columns input for table: lockable_content */
export type Lockable_Content_Pk_Columns_Input = {
  lockable_content_id: Scalars['Int'];
};

/** columns and relationships of "lockable_content_school" */
export type Lockable_Content_School = {
  __typename?: 'lockable_content_school';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id: Scalars['Int'];
  /** An object relationship */
  module?: Maybe<Module>;
  /** An object relationship */
  school: School;
  school_id: Scalars['Int'];
};

/** aggregated selection of "lockable_content_school" */
export type Lockable_Content_School_Aggregate = {
  __typename?: 'lockable_content_school_aggregate';
  aggregate?: Maybe<Lockable_Content_School_Aggregate_Fields>;
  nodes: Array<Lockable_Content_School>;
};

/** aggregate fields of "lockable_content_school" */
export type Lockable_Content_School_Aggregate_Fields = {
  __typename?: 'lockable_content_school_aggregate_fields';
  avg?: Maybe<Lockable_Content_School_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lockable_Content_School_Max_Fields>;
  min?: Maybe<Lockable_Content_School_Min_Fields>;
  stddev?: Maybe<Lockable_Content_School_Stddev_Fields>;
  stddev_pop?: Maybe<Lockable_Content_School_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lockable_Content_School_Stddev_Samp_Fields>;
  sum?: Maybe<Lockable_Content_School_Sum_Fields>;
  var_pop?: Maybe<Lockable_Content_School_Var_Pop_Fields>;
  var_samp?: Maybe<Lockable_Content_School_Var_Samp_Fields>;
  variance?: Maybe<Lockable_Content_School_Variance_Fields>;
};


/** aggregate fields of "lockable_content_school" */
export type Lockable_Content_School_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lockable_content_school" */
export type Lockable_Content_School_Aggregate_Order_By = {
  avg?: Maybe<Lockable_Content_School_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lockable_Content_School_Max_Order_By>;
  min?: Maybe<Lockable_Content_School_Min_Order_By>;
  stddev?: Maybe<Lockable_Content_School_Stddev_Order_By>;
  stddev_pop?: Maybe<Lockable_Content_School_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lockable_Content_School_Stddev_Samp_Order_By>;
  sum?: Maybe<Lockable_Content_School_Sum_Order_By>;
  var_pop?: Maybe<Lockable_Content_School_Var_Pop_Order_By>;
  var_samp?: Maybe<Lockable_Content_School_Var_Samp_Order_By>;
  variance?: Maybe<Lockable_Content_School_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lockable_content_school" */
export type Lockable_Content_School_Arr_Rel_Insert_Input = {
  data: Array<Lockable_Content_School_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lockable_Content_School_On_Conflict>;
};

/** aggregate avg on columns */
export type Lockable_Content_School_Avg_Fields = {
  __typename?: 'lockable_content_school_avg_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Avg_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lockable_content_school". All fields are combined with a logical 'AND'. */
export type Lockable_Content_School_Bool_Exp = {
  _and?: Maybe<Array<Lockable_Content_School_Bool_Exp>>;
  _not?: Maybe<Lockable_Content_School_Bool_Exp>;
  _or?: Maybe<Array<Lockable_Content_School_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  school?: Maybe<School_Bool_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lockable_content_school" */
export enum Lockable_Content_School_Constraint {
  /** unique or primary key constraint */
  LockableContentSchoolPkey = 'lockable_content_school_pkey'
}

/** input type for incrementing numeric columns in table "lockable_content_school" */
export type Lockable_Content_School_Inc_Input = {
  lockable_content_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lockable_content_school" */
export type Lockable_Content_School_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  school?: Maybe<School_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lockable_Content_School_Max_Fields = {
  __typename?: 'lockable_content_school_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lockable_Content_School_Min_Fields = {
  __typename?: 'lockable_content_school_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lockable_content_school" */
export type Lockable_Content_School_Mutation_Response = {
  __typename?: 'lockable_content_school_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lockable_Content_School>;
};

/** on_conflict condition type for table "lockable_content_school" */
export type Lockable_Content_School_On_Conflict = {
  constraint: Lockable_Content_School_Constraint;
  update_columns?: Array<Lockable_Content_School_Update_Column>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};

/** Ordering options when selecting data from "lockable_content_school". */
export type Lockable_Content_School_Order_By = {
  created_at?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  school?: Maybe<School_Order_By>;
  school_id?: Maybe<Order_By>;
};

/** primary key columns input for table: lockable_content_school */
export type Lockable_Content_School_Pk_Columns_Input = {
  lockable_content_id: Scalars['Int'];
  school_id: Scalars['Int'];
};

/** select columns of table "lockable_content_school" */
export enum Lockable_Content_School_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  SchoolId = 'school_id'
}

/** input type for updating data in table "lockable_content_school" */
export type Lockable_Content_School_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lockable_Content_School_Stddev_Fields = {
  __typename?: 'lockable_content_school_stddev_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Stddev_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lockable_Content_School_Stddev_Pop_Fields = {
  __typename?: 'lockable_content_school_stddev_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Stddev_Pop_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lockable_Content_School_Stddev_Samp_Fields = {
  __typename?: 'lockable_content_school_stddev_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Stddev_Samp_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lockable_Content_School_Sum_Fields = {
  __typename?: 'lockable_content_school_sum_fields';
  lockable_content_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Sum_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** update columns of table "lockable_content_school" */
export enum Lockable_Content_School_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  SchoolId = 'school_id'
}

/** aggregate var_pop on columns */
export type Lockable_Content_School_Var_Pop_Fields = {
  __typename?: 'lockable_content_school_var_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Var_Pop_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lockable_Content_School_Var_Samp_Fields = {
  __typename?: 'lockable_content_school_var_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Var_Samp_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lockable_Content_School_Variance_Fields = {
  __typename?: 'lockable_content_school_variance_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lockable_content_school" */
export type Lockable_Content_School_Variance_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** select columns of table "lockable_content" */
export enum Lockable_Content_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  Table = 'table',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visibility = 'visibility'
}

/** input type for updating data in table "lockable_content" */
export type Lockable_Content_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  table?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visibility?: Maybe<Visibility_Enum>;
};

/** aggregate stddev on columns */
export type Lockable_Content_Stddev_Fields = {
  __typename?: 'lockable_content_stddev_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Lockable_Content_Stddev_Pop_Fields = {
  __typename?: 'lockable_content_stddev_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Lockable_Content_Stddev_Samp_Fields = {
  __typename?: 'lockable_content_stddev_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Lockable_Content_Sum_Fields = {
  __typename?: 'lockable_content_sum_fields';
  lockable_content_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "lockable_content_teacher" */
export type Lockable_Content_Teacher = {
  __typename?: 'lockable_content_teacher';
  created_at: Scalars['timestamptz'];
  lockable_content_id: Scalars['Int'];
  /** An object relationship */
  teacher: Teacher;
  teacher_id: Scalars['Int'];
};

/** aggregated selection of "lockable_content_teacher" */
export type Lockable_Content_Teacher_Aggregate = {
  __typename?: 'lockable_content_teacher_aggregate';
  aggregate?: Maybe<Lockable_Content_Teacher_Aggregate_Fields>;
  nodes: Array<Lockable_Content_Teacher>;
};

/** aggregate fields of "lockable_content_teacher" */
export type Lockable_Content_Teacher_Aggregate_Fields = {
  __typename?: 'lockable_content_teacher_aggregate_fields';
  avg?: Maybe<Lockable_Content_Teacher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lockable_Content_Teacher_Max_Fields>;
  min?: Maybe<Lockable_Content_Teacher_Min_Fields>;
  stddev?: Maybe<Lockable_Content_Teacher_Stddev_Fields>;
  stddev_pop?: Maybe<Lockable_Content_Teacher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lockable_Content_Teacher_Stddev_Samp_Fields>;
  sum?: Maybe<Lockable_Content_Teacher_Sum_Fields>;
  var_pop?: Maybe<Lockable_Content_Teacher_Var_Pop_Fields>;
  var_samp?: Maybe<Lockable_Content_Teacher_Var_Samp_Fields>;
  variance?: Maybe<Lockable_Content_Teacher_Variance_Fields>;
};


/** aggregate fields of "lockable_content_teacher" */
export type Lockable_Content_Teacher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Aggregate_Order_By = {
  avg?: Maybe<Lockable_Content_Teacher_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Lockable_Content_Teacher_Max_Order_By>;
  min?: Maybe<Lockable_Content_Teacher_Min_Order_By>;
  stddev?: Maybe<Lockable_Content_Teacher_Stddev_Order_By>;
  stddev_pop?: Maybe<Lockable_Content_Teacher_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Lockable_Content_Teacher_Stddev_Samp_Order_By>;
  sum?: Maybe<Lockable_Content_Teacher_Sum_Order_By>;
  var_pop?: Maybe<Lockable_Content_Teacher_Var_Pop_Order_By>;
  var_samp?: Maybe<Lockable_Content_Teacher_Var_Samp_Order_By>;
  variance?: Maybe<Lockable_Content_Teacher_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Arr_Rel_Insert_Input = {
  data: Array<Lockable_Content_Teacher_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Lockable_Content_Teacher_On_Conflict>;
};

/** aggregate avg on columns */
export type Lockable_Content_Teacher_Avg_Fields = {
  __typename?: 'lockable_content_teacher_avg_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Avg_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lockable_content_teacher". All fields are combined with a logical 'AND'. */
export type Lockable_Content_Teacher_Bool_Exp = {
  _and?: Maybe<Array<Lockable_Content_Teacher_Bool_Exp>>;
  _not?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
  _or?: Maybe<Array<Lockable_Content_Teacher_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lockable_content_teacher" */
export enum Lockable_Content_Teacher_Constraint {
  /** unique or primary key constraint */
  LockableContentTeacherPkey = 'lockable_content_teacher_pkey'
}

/** input type for incrementing numeric columns in table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Inc_Input = {
  lockable_content_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Lockable_Content_Teacher_Max_Fields = {
  __typename?: 'lockable_content_teacher_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Lockable_Content_Teacher_Min_Fields = {
  __typename?: 'lockable_content_teacher_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Mutation_Response = {
  __typename?: 'lockable_content_teacher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lockable_Content_Teacher>;
};

/** on_conflict condition type for table "lockable_content_teacher" */
export type Lockable_Content_Teacher_On_Conflict = {
  constraint: Lockable_Content_Teacher_Constraint;
  update_columns?: Array<Lockable_Content_Teacher_Update_Column>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};

/** Ordering options when selecting data from "lockable_content_teacher". */
export type Lockable_Content_Teacher_Order_By = {
  created_at?: Maybe<Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** primary key columns input for table: lockable_content_teacher */
export type Lockable_Content_Teacher_Pk_Columns_Input = {
  lockable_content_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
};

/** select columns of table "lockable_content_teacher" */
export enum Lockable_Content_Teacher_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** input type for updating data in table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Lockable_Content_Teacher_Stddev_Fields = {
  __typename?: 'lockable_content_teacher_stddev_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Stddev_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lockable_Content_Teacher_Stddev_Pop_Fields = {
  __typename?: 'lockable_content_teacher_stddev_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Stddev_Pop_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lockable_Content_Teacher_Stddev_Samp_Fields = {
  __typename?: 'lockable_content_teacher_stddev_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Stddev_Samp_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Lockable_Content_Teacher_Sum_Fields = {
  __typename?: 'lockable_content_teacher_sum_fields';
  lockable_content_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Sum_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** update columns of table "lockable_content_teacher" */
export enum Lockable_Content_Teacher_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  TeacherId = 'teacher_id'
}

/** aggregate var_pop on columns */
export type Lockable_Content_Teacher_Var_Pop_Fields = {
  __typename?: 'lockable_content_teacher_var_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Var_Pop_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lockable_Content_Teacher_Var_Samp_Fields = {
  __typename?: 'lockable_content_teacher_var_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Var_Samp_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Lockable_Content_Teacher_Variance_Fields = {
  __typename?: 'lockable_content_teacher_variance_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lockable_content_teacher" */
export type Lockable_Content_Teacher_Variance_Order_By = {
  lockable_content_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** update columns of table "lockable_content" */
export enum Lockable_Content_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  Table = 'table',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visibility = 'visibility'
}

/** aggregate var_pop on columns */
export type Lockable_Content_Var_Pop_Fields = {
  __typename?: 'lockable_content_var_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Lockable_Content_Var_Samp_Fields = {
  __typename?: 'lockable_content_var_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Lockable_Content_Variance_Fields = {
  __typename?: 'lockable_content_variance_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "maturity_level" */
export type Maturity_Level = {
  __typename?: 'maturity_level';
  name: Scalars['String'];
};

/** aggregated selection of "maturity_level" */
export type Maturity_Level_Aggregate = {
  __typename?: 'maturity_level_aggregate';
  aggregate?: Maybe<Maturity_Level_Aggregate_Fields>;
  nodes: Array<Maturity_Level>;
};

/** aggregate fields of "maturity_level" */
export type Maturity_Level_Aggregate_Fields = {
  __typename?: 'maturity_level_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Maturity_Level_Max_Fields>;
  min?: Maybe<Maturity_Level_Min_Fields>;
};


/** aggregate fields of "maturity_level" */
export type Maturity_Level_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Maturity_Level_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "maturity_level". All fields are combined with a logical 'AND'. */
export type Maturity_Level_Bool_Exp = {
  _and?: Maybe<Array<Maturity_Level_Bool_Exp>>;
  _not?: Maybe<Maturity_Level_Bool_Exp>;
  _or?: Maybe<Array<Maturity_Level_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "maturity_level" */
export enum Maturity_Level_Constraint {
  /** unique or primary key constraint */
  MaurityLevelPkey = 'maurity_level_pkey'
}

export enum Maturity_Level_Enum {
  General = 'general',
  Mature = 'mature'
}

/** Boolean expression to compare columns of type "maturity_level_enum". All fields are combined with logical 'AND'. */
export type Maturity_Level_Enum_Comparison_Exp = {
  _eq?: Maybe<Maturity_Level_Enum>;
  _in?: Maybe<Array<Maturity_Level_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Maturity_Level_Enum>;
  _nin?: Maybe<Array<Maturity_Level_Enum>>;
};

/** input type for inserting data into table "maturity_level" */
export type Maturity_Level_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Maturity_Level_Max_Fields = {
  __typename?: 'maturity_level_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Maturity_Level_Min_Fields = {
  __typename?: 'maturity_level_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "maturity_level" */
export type Maturity_Level_Mutation_Response = {
  __typename?: 'maturity_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Maturity_Level>;
};

/** on_conflict condition type for table "maturity_level" */
export type Maturity_Level_On_Conflict = {
  constraint: Maturity_Level_Constraint;
  update_columns?: Array<Maturity_Level_Update_Column>;
  where?: Maybe<Maturity_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "maturity_level". */
export type Maturity_Level_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: maturity_level */
export type Maturity_Level_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "maturity_level" */
export enum Maturity_Level_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "maturity_level" */
export type Maturity_Level_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "maturity_level" */
export enum Maturity_Level_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "membership_type" */
export type Membership_Type = {
  __typename?: 'membership_type';
  billing_method: Billing_Method_Enum;
  icon_name: Scalars['String'];
  membership_type_id: Scalars['Int'];
  name: Scalars['String'];
  /** Price in cents */
  price: Scalars['numeric'];
  /** Get this value from Stripe product "Teacher Packages" */
  stripe_price_id: Scalars['String'];
};

/** aggregated selection of "membership_type" */
export type Membership_Type_Aggregate = {
  __typename?: 'membership_type_aggregate';
  aggregate?: Maybe<Membership_Type_Aggregate_Fields>;
  nodes: Array<Membership_Type>;
};

/** aggregate fields of "membership_type" */
export type Membership_Type_Aggregate_Fields = {
  __typename?: 'membership_type_aggregate_fields';
  avg?: Maybe<Membership_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Membership_Type_Max_Fields>;
  min?: Maybe<Membership_Type_Min_Fields>;
  stddev?: Maybe<Membership_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Membership_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Membership_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Membership_Type_Sum_Fields>;
  var_pop?: Maybe<Membership_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Membership_Type_Var_Samp_Fields>;
  variance?: Maybe<Membership_Type_Variance_Fields>;
};


/** aggregate fields of "membership_type" */
export type Membership_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Membership_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Membership_Type_Avg_Fields = {
  __typename?: 'membership_type_avg_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "membership_type". All fields are combined with a logical 'AND'. */
export type Membership_Type_Bool_Exp = {
  _and?: Maybe<Array<Membership_Type_Bool_Exp>>;
  _not?: Maybe<Membership_Type_Bool_Exp>;
  _or?: Maybe<Array<Membership_Type_Bool_Exp>>;
  billing_method?: Maybe<Billing_Method_Enum_Comparison_Exp>;
  icon_name?: Maybe<String_Comparison_Exp>;
  membership_type_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  stripe_price_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "membership_type" */
export enum Membership_Type_Constraint {
  /** unique or primary key constraint */
  MembershipTypePkey = 'membership_type_pkey'
}

/** input type for incrementing numeric columns in table "membership_type" */
export type Membership_Type_Inc_Input = {
  membership_type_id?: Maybe<Scalars['Int']>;
  /** Price in cents */
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "membership_type" */
export type Membership_Type_Insert_Input = {
  billing_method?: Maybe<Billing_Method_Enum>;
  icon_name?: Maybe<Scalars['String']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** Price in cents */
  price?: Maybe<Scalars['numeric']>;
  /** Get this value from Stripe product "Teacher Packages" */
  stripe_price_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Membership_Type_Max_Fields = {
  __typename?: 'membership_type_max_fields';
  icon_name?: Maybe<Scalars['String']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** Price in cents */
  price?: Maybe<Scalars['numeric']>;
  /** Get this value from Stripe product "Teacher Packages" */
  stripe_price_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Membership_Type_Min_Fields = {
  __typename?: 'membership_type_min_fields';
  icon_name?: Maybe<Scalars['String']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** Price in cents */
  price?: Maybe<Scalars['numeric']>;
  /** Get this value from Stripe product "Teacher Packages" */
  stripe_price_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "membership_type" */
export type Membership_Type_Mutation_Response = {
  __typename?: 'membership_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Membership_Type>;
};

/** input type for inserting object relation for remote table "membership_type" */
export type Membership_Type_Obj_Rel_Insert_Input = {
  data: Membership_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Membership_Type_On_Conflict>;
};

/** on_conflict condition type for table "membership_type" */
export type Membership_Type_On_Conflict = {
  constraint: Membership_Type_Constraint;
  update_columns?: Array<Membership_Type_Update_Column>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "membership_type". */
export type Membership_Type_Order_By = {
  billing_method?: Maybe<Order_By>;
  icon_name?: Maybe<Order_By>;
  membership_type_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  stripe_price_id?: Maybe<Order_By>;
};

/** primary key columns input for table: membership_type */
export type Membership_Type_Pk_Columns_Input = {
  membership_type_id: Scalars['Int'];
};

/** select columns of table "membership_type" */
export enum Membership_Type_Select_Column {
  /** column name */
  BillingMethod = 'billing_method',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  MembershipTypeId = 'membership_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  StripePriceId = 'stripe_price_id'
}

/** input type for updating data in table "membership_type" */
export type Membership_Type_Set_Input = {
  billing_method?: Maybe<Billing_Method_Enum>;
  icon_name?: Maybe<Scalars['String']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** Price in cents */
  price?: Maybe<Scalars['numeric']>;
  /** Get this value from Stripe product "Teacher Packages" */
  stripe_price_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Membership_Type_Stddev_Fields = {
  __typename?: 'membership_type_stddev_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Membership_Type_Stddev_Pop_Fields = {
  __typename?: 'membership_type_stddev_pop_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Membership_Type_Stddev_Samp_Fields = {
  __typename?: 'membership_type_stddev_samp_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Membership_Type_Sum_Fields = {
  __typename?: 'membership_type_sum_fields';
  membership_type_id?: Maybe<Scalars['Int']>;
  /** Price in cents */
  price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "membership_type" */
export enum Membership_Type_Update_Column {
  /** column name */
  BillingMethod = 'billing_method',
  /** column name */
  IconName = 'icon_name',
  /** column name */
  MembershipTypeId = 'membership_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  StripePriceId = 'stripe_price_id'
}

/** aggregate var_pop on columns */
export type Membership_Type_Var_Pop_Fields = {
  __typename?: 'membership_type_var_pop_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Membership_Type_Var_Samp_Fields = {
  __typename?: 'membership_type_var_samp_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Membership_Type_Variance_Fields = {
  __typename?: 'membership_type_variance_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  /** Price in cents */
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "midi" */
export type Midi = {
  __typename?: 'midi';
  admin_content_json?: Maybe<Scalars['jsonb']>;
  chord_map?: Maybe<Scalars['jsonb']>;
  chord_map_object?: Maybe<Scalars['jsonb']>;
  chords_Array?: Maybe<Scalars['String']>;
  chords_Array_Five?: Maybe<Scalars['String']>;
  chords_Array_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_Five?: Maybe<Scalars['String']>;
  chords_Array_Neg_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_One?: Maybe<Scalars['String']>;
  chords_Array_Neg_Six?: Maybe<Scalars['String']>;
  chords_Array_Neg_Three?: Maybe<Scalars['String']>;
  chords_Array_Neg_Two?: Maybe<Scalars['String']>;
  chords_Array_One?: Maybe<Scalars['String']>;
  chords_Array_Six?: Maybe<Scalars['String']>;
  chords_Array_Three?: Maybe<Scalars['String']>;
  chords_Array_Two?: Maybe<Scalars['String']>;
  chords_Array_Zero?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['bytea']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update: Scalars['timestamptz'];
  maturity_level: Maturity_Level_Enum;
  midi_id: Scalars['Int'];
  /** An object relationship */
  track?: Maybe<Track>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "midi" */
export type MidiAdmin_Content_JsonArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "midi" */
export type MidiChord_MapArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "midi" */
export type MidiChord_Map_ObjectArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "midi" */
export type MidiContent_JsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "midi" */
export type Midi_Aggregate = {
  __typename?: 'midi_aggregate';
  aggregate?: Maybe<Midi_Aggregate_Fields>;
  nodes: Array<Midi>;
};

/** aggregate fields of "midi" */
export type Midi_Aggregate_Fields = {
  __typename?: 'midi_aggregate_fields';
  avg?: Maybe<Midi_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Midi_Max_Fields>;
  min?: Maybe<Midi_Min_Fields>;
  stddev?: Maybe<Midi_Stddev_Fields>;
  stddev_pop?: Maybe<Midi_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Midi_Stddev_Samp_Fields>;
  sum?: Maybe<Midi_Sum_Fields>;
  var_pop?: Maybe<Midi_Var_Pop_Fields>;
  var_samp?: Maybe<Midi_Var_Samp_Fields>;
  variance?: Maybe<Midi_Variance_Fields>;
};


/** aggregate fields of "midi" */
export type Midi_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Midi_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "midi" */
export type Midi_Aggregate_Order_By = {
  avg?: Maybe<Midi_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Midi_Max_Order_By>;
  min?: Maybe<Midi_Min_Order_By>;
  stddev?: Maybe<Midi_Stddev_Order_By>;
  stddev_pop?: Maybe<Midi_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Midi_Stddev_Samp_Order_By>;
  sum?: Maybe<Midi_Sum_Order_By>;
  var_pop?: Maybe<Midi_Var_Pop_Order_By>;
  var_samp?: Maybe<Midi_Var_Samp_Order_By>;
  variance?: Maybe<Midi_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Midi_Append_Input = {
  admin_content_json?: Maybe<Scalars['jsonb']>;
  chord_map?: Maybe<Scalars['jsonb']>;
  chord_map_object?: Maybe<Scalars['jsonb']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "midi" */
export type Midi_Arr_Rel_Insert_Input = {
  data: Array<Midi_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Midi_On_Conflict>;
};

/** aggregate avg on columns */
export type Midi_Avg_Fields = {
  __typename?: 'midi_avg_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "midi" */
export type Midi_Avg_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "midi". All fields are combined with a logical 'AND'. */
export type Midi_Bool_Exp = {
  _and?: Maybe<Array<Midi_Bool_Exp>>;
  _not?: Maybe<Midi_Bool_Exp>;
  _or?: Maybe<Array<Midi_Bool_Exp>>;
  admin_content_json?: Maybe<Jsonb_Comparison_Exp>;
  chord_map?: Maybe<Jsonb_Comparison_Exp>;
  chord_map_object?: Maybe<Jsonb_Comparison_Exp>;
  chords_Array?: Maybe<String_Comparison_Exp>;
  chords_Array_Five?: Maybe<String_Comparison_Exp>;
  chords_Array_Four?: Maybe<String_Comparison_Exp>;
  chords_Array_Neg_Five?: Maybe<String_Comparison_Exp>;
  chords_Array_Neg_Four?: Maybe<String_Comparison_Exp>;
  chords_Array_Neg_One?: Maybe<String_Comparison_Exp>;
  chords_Array_Neg_Six?: Maybe<String_Comparison_Exp>;
  chords_Array_Neg_Three?: Maybe<String_Comparison_Exp>;
  chords_Array_Neg_Two?: Maybe<String_Comparison_Exp>;
  chords_Array_One?: Maybe<String_Comparison_Exp>;
  chords_Array_Six?: Maybe<String_Comparison_Exp>;
  chords_Array_Three?: Maybe<String_Comparison_Exp>;
  chords_Array_Two?: Maybe<String_Comparison_Exp>;
  chords_Array_Zero?: Maybe<String_Comparison_Exp>;
  content?: Maybe<Bytea_Comparison_Exp>;
  content_json?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  maturity_level?: Maybe<Maturity_Level_Enum_Comparison_Exp>;
  midi_id?: Maybe<Int_Comparison_Exp>;
  track?: Maybe<Track_Bool_Exp>;
  track_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "midi" */
export enum Midi_Constraint {
  /** unique or primary key constraint */
  MidiPkey = 'midi_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Midi_Delete_At_Path_Input = {
  admin_content_json?: Maybe<Array<Scalars['String']>>;
  chord_map?: Maybe<Array<Scalars['String']>>;
  chord_map_object?: Maybe<Array<Scalars['String']>>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Midi_Delete_Elem_Input = {
  admin_content_json?: Maybe<Scalars['Int']>;
  chord_map?: Maybe<Scalars['Int']>;
  chord_map_object?: Maybe<Scalars['Int']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Midi_Delete_Key_Input = {
  admin_content_json?: Maybe<Scalars['String']>;
  chord_map?: Maybe<Scalars['String']>;
  chord_map_object?: Maybe<Scalars['String']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "midi" */
export type Midi_Inc_Input = {
  midi_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "midi" */
export type Midi_Insert_Input = {
  admin_content_json?: Maybe<Scalars['jsonb']>;
  chord_map?: Maybe<Scalars['jsonb']>;
  chord_map_object?: Maybe<Scalars['jsonb']>;
  chords_Array?: Maybe<Scalars['String']>;
  chords_Array_Five?: Maybe<Scalars['String']>;
  chords_Array_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_Five?: Maybe<Scalars['String']>;
  chords_Array_Neg_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_One?: Maybe<Scalars['String']>;
  chords_Array_Neg_Six?: Maybe<Scalars['String']>;
  chords_Array_Neg_Three?: Maybe<Scalars['String']>;
  chords_Array_Neg_Two?: Maybe<Scalars['String']>;
  chords_Array_One?: Maybe<Scalars['String']>;
  chords_Array_Six?: Maybe<Scalars['String']>;
  chords_Array_Three?: Maybe<Scalars['String']>;
  chords_Array_Two?: Maybe<Scalars['String']>;
  chords_Array_Zero?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['bytea']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  maturity_level?: Maybe<Maturity_Level_Enum>;
  midi_id?: Maybe<Scalars['Int']>;
  track?: Maybe<Track_Obj_Rel_Insert_Input>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Midi_Max_Fields = {
  __typename?: 'midi_max_fields';
  chords_Array?: Maybe<Scalars['String']>;
  chords_Array_Five?: Maybe<Scalars['String']>;
  chords_Array_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_Five?: Maybe<Scalars['String']>;
  chords_Array_Neg_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_One?: Maybe<Scalars['String']>;
  chords_Array_Neg_Six?: Maybe<Scalars['String']>;
  chords_Array_Neg_Three?: Maybe<Scalars['String']>;
  chords_Array_Neg_Two?: Maybe<Scalars['String']>;
  chords_Array_One?: Maybe<Scalars['String']>;
  chords_Array_Six?: Maybe<Scalars['String']>;
  chords_Array_Three?: Maybe<Scalars['String']>;
  chords_Array_Two?: Maybe<Scalars['String']>;
  chords_Array_Zero?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  midi_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "midi" */
export type Midi_Max_Order_By = {
  chords_Array?: Maybe<Order_By>;
  chords_Array_Five?: Maybe<Order_By>;
  chords_Array_Four?: Maybe<Order_By>;
  chords_Array_Neg_Five?: Maybe<Order_By>;
  chords_Array_Neg_Four?: Maybe<Order_By>;
  chords_Array_Neg_One?: Maybe<Order_By>;
  chords_Array_Neg_Six?: Maybe<Order_By>;
  chords_Array_Neg_Three?: Maybe<Order_By>;
  chords_Array_Neg_Two?: Maybe<Order_By>;
  chords_Array_One?: Maybe<Order_By>;
  chords_Array_Six?: Maybe<Order_By>;
  chords_Array_Three?: Maybe<Order_By>;
  chords_Array_Two?: Maybe<Order_By>;
  chords_Array_Zero?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Midi_Min_Fields = {
  __typename?: 'midi_min_fields';
  chords_Array?: Maybe<Scalars['String']>;
  chords_Array_Five?: Maybe<Scalars['String']>;
  chords_Array_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_Five?: Maybe<Scalars['String']>;
  chords_Array_Neg_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_One?: Maybe<Scalars['String']>;
  chords_Array_Neg_Six?: Maybe<Scalars['String']>;
  chords_Array_Neg_Three?: Maybe<Scalars['String']>;
  chords_Array_Neg_Two?: Maybe<Scalars['String']>;
  chords_Array_One?: Maybe<Scalars['String']>;
  chords_Array_Six?: Maybe<Scalars['String']>;
  chords_Array_Three?: Maybe<Scalars['String']>;
  chords_Array_Two?: Maybe<Scalars['String']>;
  chords_Array_Zero?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  midi_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "midi" */
export type Midi_Min_Order_By = {
  chords_Array?: Maybe<Order_By>;
  chords_Array_Five?: Maybe<Order_By>;
  chords_Array_Four?: Maybe<Order_By>;
  chords_Array_Neg_Five?: Maybe<Order_By>;
  chords_Array_Neg_Four?: Maybe<Order_By>;
  chords_Array_Neg_One?: Maybe<Order_By>;
  chords_Array_Neg_Six?: Maybe<Order_By>;
  chords_Array_Neg_Three?: Maybe<Order_By>;
  chords_Array_Neg_Two?: Maybe<Order_By>;
  chords_Array_One?: Maybe<Order_By>;
  chords_Array_Six?: Maybe<Order_By>;
  chords_Array_Three?: Maybe<Order_By>;
  chords_Array_Two?: Maybe<Order_By>;
  chords_Array_Zero?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "midi" */
export type Midi_Mutation_Response = {
  __typename?: 'midi_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Midi>;
};

/** on_conflict condition type for table "midi" */
export type Midi_On_Conflict = {
  constraint: Midi_Constraint;
  update_columns?: Array<Midi_Update_Column>;
  where?: Maybe<Midi_Bool_Exp>;
};

/** Ordering options when selecting data from "midi". */
export type Midi_Order_By = {
  admin_content_json?: Maybe<Order_By>;
  chord_map?: Maybe<Order_By>;
  chord_map_object?: Maybe<Order_By>;
  chords_Array?: Maybe<Order_By>;
  chords_Array_Five?: Maybe<Order_By>;
  chords_Array_Four?: Maybe<Order_By>;
  chords_Array_Neg_Five?: Maybe<Order_By>;
  chords_Array_Neg_Four?: Maybe<Order_By>;
  chords_Array_Neg_One?: Maybe<Order_By>;
  chords_Array_Neg_Six?: Maybe<Order_By>;
  chords_Array_Neg_Three?: Maybe<Order_By>;
  chords_Array_Neg_Two?: Maybe<Order_By>;
  chords_Array_One?: Maybe<Order_By>;
  chords_Array_Six?: Maybe<Order_By>;
  chords_Array_Three?: Maybe<Order_By>;
  chords_Array_Two?: Maybe<Order_By>;
  chords_Array_Zero?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  content_json?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  maturity_level?: Maybe<Order_By>;
  midi_id?: Maybe<Order_By>;
  track?: Maybe<Track_Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: midi */
export type Midi_Pk_Columns_Input = {
  midi_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Midi_Prepend_Input = {
  admin_content_json?: Maybe<Scalars['jsonb']>;
  chord_map?: Maybe<Scalars['jsonb']>;
  chord_map_object?: Maybe<Scalars['jsonb']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "midi" */
export enum Midi_Select_Column {
  /** column name */
  AdminContentJson = 'admin_content_json',
  /** column name */
  ChordMap = 'chord_map',
  /** column name */
  ChordMapObject = 'chord_map_object',
  /** column name */
  ChordsArray = 'chords_Array',
  /** column name */
  ChordsArrayFive = 'chords_Array_Five',
  /** column name */
  ChordsArrayFour = 'chords_Array_Four',
  /** column name */
  ChordsArrayNegFive = 'chords_Array_Neg_Five',
  /** column name */
  ChordsArrayNegFour = 'chords_Array_Neg_Four',
  /** column name */
  ChordsArrayNegOne = 'chords_Array_Neg_One',
  /** column name */
  ChordsArrayNegSix = 'chords_Array_Neg_Six',
  /** column name */
  ChordsArrayNegThree = 'chords_Array_Neg_Three',
  /** column name */
  ChordsArrayNegTwo = 'chords_Array_Neg_Two',
  /** column name */
  ChordsArrayOne = 'chords_Array_One',
  /** column name */
  ChordsArraySix = 'chords_Array_Six',
  /** column name */
  ChordsArrayThree = 'chords_Array_Three',
  /** column name */
  ChordsArrayTwo = 'chords_Array_Two',
  /** column name */
  ChordsArrayZero = 'chords_Array_Zero',
  /** column name */
  Content = 'content',
  /** column name */
  ContentJson = 'content_json',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  MaturityLevel = 'maturity_level',
  /** column name */
  MidiId = 'midi_id',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "midi" */
export type Midi_Set_Input = {
  admin_content_json?: Maybe<Scalars['jsonb']>;
  chord_map?: Maybe<Scalars['jsonb']>;
  chord_map_object?: Maybe<Scalars['jsonb']>;
  chords_Array?: Maybe<Scalars['String']>;
  chords_Array_Five?: Maybe<Scalars['String']>;
  chords_Array_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_Five?: Maybe<Scalars['String']>;
  chords_Array_Neg_Four?: Maybe<Scalars['String']>;
  chords_Array_Neg_One?: Maybe<Scalars['String']>;
  chords_Array_Neg_Six?: Maybe<Scalars['String']>;
  chords_Array_Neg_Three?: Maybe<Scalars['String']>;
  chords_Array_Neg_Two?: Maybe<Scalars['String']>;
  chords_Array_One?: Maybe<Scalars['String']>;
  chords_Array_Six?: Maybe<Scalars['String']>;
  chords_Array_Three?: Maybe<Scalars['String']>;
  chords_Array_Two?: Maybe<Scalars['String']>;
  chords_Array_Zero?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['bytea']>;
  /** Also known as midiData, is the conversion from content using java lambda to convert it to json */
  content_json?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  maturity_level?: Maybe<Maturity_Level_Enum>;
  midi_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Midi_Stddev_Fields = {
  __typename?: 'midi_stddev_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "midi" */
export type Midi_Stddev_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Midi_Stddev_Pop_Fields = {
  __typename?: 'midi_stddev_pop_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "midi" */
export type Midi_Stddev_Pop_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Midi_Stddev_Samp_Fields = {
  __typename?: 'midi_stddev_samp_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "midi" */
export type Midi_Stddev_Samp_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Midi_Sum_Fields = {
  __typename?: 'midi_sum_fields';
  midi_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "midi" */
export type Midi_Sum_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** update columns of table "midi" */
export enum Midi_Update_Column {
  /** column name */
  AdminContentJson = 'admin_content_json',
  /** column name */
  ChordMap = 'chord_map',
  /** column name */
  ChordMapObject = 'chord_map_object',
  /** column name */
  ChordsArray = 'chords_Array',
  /** column name */
  ChordsArrayFive = 'chords_Array_Five',
  /** column name */
  ChordsArrayFour = 'chords_Array_Four',
  /** column name */
  ChordsArrayNegFive = 'chords_Array_Neg_Five',
  /** column name */
  ChordsArrayNegFour = 'chords_Array_Neg_Four',
  /** column name */
  ChordsArrayNegOne = 'chords_Array_Neg_One',
  /** column name */
  ChordsArrayNegSix = 'chords_Array_Neg_Six',
  /** column name */
  ChordsArrayNegThree = 'chords_Array_Neg_Three',
  /** column name */
  ChordsArrayNegTwo = 'chords_Array_Neg_Two',
  /** column name */
  ChordsArrayOne = 'chords_Array_One',
  /** column name */
  ChordsArraySix = 'chords_Array_Six',
  /** column name */
  ChordsArrayThree = 'chords_Array_Three',
  /** column name */
  ChordsArrayTwo = 'chords_Array_Two',
  /** column name */
  ChordsArrayZero = 'chords_Array_Zero',
  /** column name */
  Content = 'content',
  /** column name */
  ContentJson = 'content_json',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  MaturityLevel = 'maturity_level',
  /** column name */
  MidiId = 'midi_id',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Midi_Var_Pop_Fields = {
  __typename?: 'midi_var_pop_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "midi" */
export type Midi_Var_Pop_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Midi_Var_Samp_Fields = {
  __typename?: 'midi_var_samp_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "midi" */
export type Midi_Var_Samp_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Midi_Variance_Fields = {
  __typename?: 'midi_variance_fields';
  midi_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "midi" */
export type Midi_Variance_Order_By = {
  midi_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** columns and relationships of "module" */
export type Module = {
  __typename?: 'module';
  about: Scalars['String'];
  active: Scalars['Boolean'];
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  class_modules: Array<Class_Module>;
  /** An aggregate relationship */
  class_modules_aggregate: Class_Module_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  display_video: Scalars['Boolean'];
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "get_module_status_by_teacher" */
  get_status_by_teacher?: Maybe<Scalars['String']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** A computed field, executes function "is_purchased_by_teacher" */
  is_purchased_by_teacher?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  module_catalog_items: Array<Module_Catalog_Item>;
  /** An aggregate relationship */
  module_catalog_items_aggregate: Module_Catalog_Item_Aggregate;
  module_id: Scalars['Int'];
  /** An array relationship */
  module_lesson_plan: Array<Module_Lesson_Plan>;
  /** An aggregate relationship */
  module_lesson_plan_aggregate: Module_Lesson_Plan_Aggregate;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order: Scalars['String'];
  /** An array relationship */
  order_details: Array<Order>;
  /** An aggregate relationship */
  order_details_aggregate: Order_Aggregate;
  /** An array relationship */
  partner_modules: Array<Partner_Module>;
  /** An aggregate relationship */
  partner_modules_aggregate: Partner_Module_Aggregate;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "module" */
export type ModuleClass_ModulesArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleClass_Modules_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModule_Catalog_ItemsArgs = {
  distinct_on?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Catalog_Item_Order_By>>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModule_Catalog_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Catalog_Item_Order_By>>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModule_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleModule_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleOrder_DetailsArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModuleOrder_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModulePartner_ModulesArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


/** columns and relationships of "module" */
export type ModulePartner_Modules_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};

/** aggregated selection of "module" */
export type Module_Aggregate = {
  __typename?: 'module_aggregate';
  aggregate?: Maybe<Module_Aggregate_Fields>;
  nodes: Array<Module>;
};

/** aggregate fields of "module" */
export type Module_Aggregate_Fields = {
  __typename?: 'module_aggregate_fields';
  avg?: Maybe<Module_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Module_Max_Fields>;
  min?: Maybe<Module_Min_Fields>;
  stddev?: Maybe<Module_Stddev_Fields>;
  stddev_pop?: Maybe<Module_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Module_Stddev_Samp_Fields>;
  sum?: Maybe<Module_Sum_Fields>;
  var_pop?: Maybe<Module_Var_Pop_Fields>;
  var_samp?: Maybe<Module_Var_Samp_Fields>;
  variance?: Maybe<Module_Variance_Fields>;
};


/** aggregate fields of "module" */
export type Module_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Module_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Module_Avg_Fields = {
  __typename?: 'module_avg_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "module". All fields are combined with a logical 'AND'. */
export type Module_Bool_Exp = {
  _and?: Maybe<Array<Module_Bool_Exp>>;
  _not?: Maybe<Module_Bool_Exp>;
  _or?: Maybe<Array<Module_Bool_Exp>>;
  about?: Maybe<String_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  annual_price?: Maybe<Int_Comparison_Exp>;
  annual_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  class_modules?: Maybe<Class_Module_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  display_video?: Maybe<Boolean_Comparison_Exp>;
  district_price?: Maybe<Int_Comparison_Exp>;
  district_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  feature?: Maybe<Boolean_Comparison_Exp>;
  get_status_by_teacher?: Maybe<String_Comparison_Exp>;
  header_image_path?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_purchased_by_teacher?: Maybe<Boolean_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  module_catalog_items?: Maybe<Module_Catalog_Item_Bool_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  module_lesson_plan?: Maybe<Module_Lesson_Plan_Bool_Exp>;
  monthly_price?: Maybe<Int_Comparison_Exp>;
  monthly_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  order?: Maybe<String_Comparison_Exp>;
  order_details?: Maybe<Order_Bool_Exp>;
  partner_modules?: Maybe<Partner_Module_Bool_Exp>;
  school_price?: Maybe<Int_Comparison_Exp>;
  school_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  stripe_product_id?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_path?: Maybe<String_Comparison_Exp>;
  video_thumbnail_image_path?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "module_catalog_item" */
export type Module_Catalog_Item = {
  __typename?: 'module_catalog_item';
  /** An object relationship */
  catalog_item: Catalog_Item;
  catalog_item_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  module: Module;
  module_catalog_item_id: Scalars['Int'];
  module_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "module_catalog_item" */
export type Module_Catalog_Item_Aggregate = {
  __typename?: 'module_catalog_item_aggregate';
  aggregate?: Maybe<Module_Catalog_Item_Aggregate_Fields>;
  nodes: Array<Module_Catalog_Item>;
};

/** aggregate fields of "module_catalog_item" */
export type Module_Catalog_Item_Aggregate_Fields = {
  __typename?: 'module_catalog_item_aggregate_fields';
  avg?: Maybe<Module_Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Module_Catalog_Item_Max_Fields>;
  min?: Maybe<Module_Catalog_Item_Min_Fields>;
  stddev?: Maybe<Module_Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Module_Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Module_Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Module_Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Module_Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Module_Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Module_Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "module_catalog_item" */
export type Module_Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "module_catalog_item" */
export type Module_Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Module_Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Module_Catalog_Item_Max_Order_By>;
  min?: Maybe<Module_Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Module_Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Module_Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Module_Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Module_Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Module_Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Module_Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Module_Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "module_catalog_item" */
export type Module_Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Module_Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Module_Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Module_Catalog_Item_Avg_Fields = {
  __typename?: 'module_catalog_item_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "module_catalog_item". All fields are combined with a logical 'AND'. */
export type Module_Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Module_Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Module_Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Module_Catalog_Item_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  module_catalog_item_id?: Maybe<Int_Comparison_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "module_catalog_item" */
export enum Module_Catalog_Item_Constraint {
  /** unique or primary key constraint */
  ModuleCatalogItemModuleIdCatalogItemIdKey = 'module_catalog_item_module_id_catalog_item_id_key',
  /** unique or primary key constraint */
  ModuleCatalogItemPkey = 'module_catalog_item_pkey'
}

/** input type for incrementing numeric columns in table "module_catalog_item" */
export type Module_Catalog_Item_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  module_catalog_item_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "module_catalog_item" */
export type Module_Catalog_Item_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  module_catalog_item_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Module_Catalog_Item_Max_Fields = {
  __typename?: 'module_catalog_item_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module_catalog_item_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Module_Catalog_Item_Min_Fields = {
  __typename?: 'module_catalog_item_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module_catalog_item_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "module_catalog_item" */
export type Module_Catalog_Item_Mutation_Response = {
  __typename?: 'module_catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Module_Catalog_Item>;
};

/** on_conflict condition type for table "module_catalog_item" */
export type Module_Catalog_Item_On_Conflict = {
  constraint: Module_Catalog_Item_Constraint;
  update_columns?: Array<Module_Catalog_Item_Update_Column>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "module_catalog_item". */
export type Module_Catalog_Item_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: module_catalog_item */
export type Module_Catalog_Item_Pk_Columns_Input = {
  module_catalog_item_id: Scalars['Int'];
};

/** select columns of table "module_catalog_item" */
export enum Module_Catalog_Item_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleCatalogItemId = 'module_catalog_item_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "module_catalog_item" */
export type Module_Catalog_Item_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  module_catalog_item_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Module_Catalog_Item_Stddev_Fields = {
  __typename?: 'module_catalog_item_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Module_Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'module_catalog_item_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Module_Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'module_catalog_item_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Module_Catalog_Item_Sum_Fields = {
  __typename?: 'module_catalog_item_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  module_catalog_item_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** update columns of table "module_catalog_item" */
export enum Module_Catalog_Item_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleCatalogItemId = 'module_catalog_item_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Module_Catalog_Item_Var_Pop_Fields = {
  __typename?: 'module_catalog_item_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Module_Catalog_Item_Var_Samp_Fields = {
  __typename?: 'module_catalog_item_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Module_Catalog_Item_Variance_Fields = {
  __typename?: 'module_catalog_item_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  module_catalog_item_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "module_catalog_item" */
export type Module_Catalog_Item_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  module_catalog_item_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "module" */
export enum Module_Constraint {
  /** unique or primary key constraint */
  ModuleAnnualPriceStripePriceIdKey = 'module_annual_price_stripe_price_id_key',
  /** unique or primary key constraint */
  ModuleLockableContentIdKey = 'module_lockable_content_id_key',
  /** unique or primary key constraint */
  ModuleMonthlyPriceStripePriceIdKey = 'module_monthly_price_stripe_price_id_key',
  /** unique or primary key constraint */
  ModulePkey = 'module_pkey',
  /** unique or primary key constraint */
  ModuleStripeProductIdKey = 'module_stripe_product_id_key'
}

/** columns and relationships of "module_grade" */
export type Module_Grade = {
  __typename?: 'module_grade';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id: Scalars['Int'];
  module_grade_id: Scalars['Int'];
  module_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "module_grade" */
export type Module_Grade_Aggregate = {
  __typename?: 'module_grade_aggregate';
  aggregate?: Maybe<Module_Grade_Aggregate_Fields>;
  nodes: Array<Module_Grade>;
};

/** aggregate fields of "module_grade" */
export type Module_Grade_Aggregate_Fields = {
  __typename?: 'module_grade_aggregate_fields';
  avg?: Maybe<Module_Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Module_Grade_Max_Fields>;
  min?: Maybe<Module_Grade_Min_Fields>;
  stddev?: Maybe<Module_Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Module_Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Module_Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Module_Grade_Sum_Fields>;
  var_pop?: Maybe<Module_Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Module_Grade_Var_Samp_Fields>;
  variance?: Maybe<Module_Grade_Variance_Fields>;
};


/** aggregate fields of "module_grade" */
export type Module_Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Module_Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Module_Grade_Avg_Fields = {
  __typename?: 'module_grade_avg_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "module_grade". All fields are combined with a logical 'AND'. */
export type Module_Grade_Bool_Exp = {
  _and?: Maybe<Array<Module_Grade_Bool_Exp>>;
  _not?: Maybe<Module_Grade_Bool_Exp>;
  _or?: Maybe<Array<Module_Grade_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grade_id?: Maybe<Int_Comparison_Exp>;
  module_grade_id?: Maybe<Int_Comparison_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "module_grade" */
export enum Module_Grade_Constraint {
  /** unique or primary key constraint */
  ModuleGradePkey = 'module_grade_pkey'
}

/** input type for incrementing numeric columns in table "module_grade" */
export type Module_Grade_Inc_Input = {
  grade_id?: Maybe<Scalars['Int']>;
  module_grade_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "module_grade" */
export type Module_Grade_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  module_grade_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Module_Grade_Max_Fields = {
  __typename?: 'module_grade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  module_grade_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Module_Grade_Min_Fields = {
  __typename?: 'module_grade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  module_grade_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "module_grade" */
export type Module_Grade_Mutation_Response = {
  __typename?: 'module_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Module_Grade>;
};

/** on_conflict condition type for table "module_grade" */
export type Module_Grade_On_Conflict = {
  constraint: Module_Grade_Constraint;
  update_columns?: Array<Module_Grade_Update_Column>;
  where?: Maybe<Module_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "module_grade". */
export type Module_Grade_Order_By = {
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  module_grade_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: module_grade */
export type Module_Grade_Pk_Columns_Input = {
  module_grade_id: Scalars['Int'];
};

/** select columns of table "module_grade" */
export enum Module_Grade_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  ModuleGradeId = 'module_grade_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "module_grade" */
export type Module_Grade_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  module_grade_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Module_Grade_Stddev_Fields = {
  __typename?: 'module_grade_stddev_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Module_Grade_Stddev_Pop_Fields = {
  __typename?: 'module_grade_stddev_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Module_Grade_Stddev_Samp_Fields = {
  __typename?: 'module_grade_stddev_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Module_Grade_Sum_Fields = {
  __typename?: 'module_grade_sum_fields';
  grade_id?: Maybe<Scalars['Int']>;
  module_grade_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "module_grade" */
export enum Module_Grade_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  ModuleGradeId = 'module_grade_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Module_Grade_Var_Pop_Fields = {
  __typename?: 'module_grade_var_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Module_Grade_Var_Samp_Fields = {
  __typename?: 'module_grade_var_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Module_Grade_Variance_Fields = {
  __typename?: 'module_grade_variance_fields';
  grade_id?: Maybe<Scalars['Float']>;
  module_grade_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "module" */
export type Module_Inc_Input = {
  annual_price?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "module" */
export type Module_Insert_Input = {
  about?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  class_modules?: Maybe<Class_Module_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_video?: Maybe<Scalars['Boolean']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module_catalog_items?: Maybe<Module_Catalog_Item_Arr_Rel_Insert_Input>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan?: Maybe<Module_Lesson_Plan_Arr_Rel_Insert_Input>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  order_details?: Maybe<Order_Arr_Rel_Insert_Input>;
  partner_modules?: Maybe<Partner_Module_Arr_Rel_Insert_Input>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "module_lesson_plan" */
export type Module_Lesson_Plan = {
  __typename?: 'module_lesson_plan';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  /** An object relationship */
  module: Module;
  module_id: Scalars['Int'];
  module_lesson_plan_id: Scalars['Int'];
  order?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "module_lesson_plan" */
export type Module_Lesson_Plan_Aggregate = {
  __typename?: 'module_lesson_plan_aggregate';
  aggregate?: Maybe<Module_Lesson_Plan_Aggregate_Fields>;
  nodes: Array<Module_Lesson_Plan>;
};

/** aggregate fields of "module_lesson_plan" */
export type Module_Lesson_Plan_Aggregate_Fields = {
  __typename?: 'module_lesson_plan_aggregate_fields';
  avg?: Maybe<Module_Lesson_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Module_Lesson_Plan_Max_Fields>;
  min?: Maybe<Module_Lesson_Plan_Min_Fields>;
  stddev?: Maybe<Module_Lesson_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Module_Lesson_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Module_Lesson_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Module_Lesson_Plan_Sum_Fields>;
  var_pop?: Maybe<Module_Lesson_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Module_Lesson_Plan_Var_Samp_Fields>;
  variance?: Maybe<Module_Lesson_Plan_Variance_Fields>;
};


/** aggregate fields of "module_lesson_plan" */
export type Module_Lesson_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "module_lesson_plan" */
export type Module_Lesson_Plan_Aggregate_Order_By = {
  avg?: Maybe<Module_Lesson_Plan_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Module_Lesson_Plan_Max_Order_By>;
  min?: Maybe<Module_Lesson_Plan_Min_Order_By>;
  stddev?: Maybe<Module_Lesson_Plan_Stddev_Order_By>;
  stddev_pop?: Maybe<Module_Lesson_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Module_Lesson_Plan_Stddev_Samp_Order_By>;
  sum?: Maybe<Module_Lesson_Plan_Sum_Order_By>;
  var_pop?: Maybe<Module_Lesson_Plan_Var_Pop_Order_By>;
  var_samp?: Maybe<Module_Lesson_Plan_Var_Samp_Order_By>;
  variance?: Maybe<Module_Lesson_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "module_lesson_plan" */
export type Module_Lesson_Plan_Arr_Rel_Insert_Input = {
  data: Array<Module_Lesson_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Module_Lesson_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Module_Lesson_Plan_Avg_Fields = {
  __typename?: 'module_lesson_plan_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "module_lesson_plan". All fields are combined with a logical 'AND'. */
export type Module_Lesson_Plan_Bool_Exp = {
  _and?: Maybe<Array<Module_Lesson_Plan_Bool_Exp>>;
  _not?: Maybe<Module_Lesson_Plan_Bool_Exp>;
  _or?: Maybe<Array<Module_Lesson_Plan_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  module_lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "module_lesson_plan" */
export enum Module_Lesson_Plan_Constraint {
  /** unique or primary key constraint */
  LessonPlanModuleLessonPlanIdModuleIdKey = 'lesson_plan_module_lesson_plan_id_module_id_key',
  /** unique or primary key constraint */
  ModuleLessonPlanPkey = 'module_lesson_plan_pkey'
}

/** input type for incrementing numeric columns in table "module_lesson_plan" */
export type Module_Lesson_Plan_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "module_lesson_plan" */
export type Module_Lesson_Plan_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Module_Lesson_Plan_Max_Fields = {
  __typename?: 'module_lesson_plan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Module_Lesson_Plan_Min_Fields = {
  __typename?: 'module_lesson_plan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "module_lesson_plan" */
export type Module_Lesson_Plan_Mutation_Response = {
  __typename?: 'module_lesson_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Module_Lesson_Plan>;
};

/** on_conflict condition type for table "module_lesson_plan" */
export type Module_Lesson_Plan_On_Conflict = {
  constraint: Module_Lesson_Plan_Constraint;
  update_columns?: Array<Module_Lesson_Plan_Update_Column>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "module_lesson_plan". */
export type Module_Lesson_Plan_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: module_lesson_plan */
export type Module_Lesson_Plan_Pk_Columns_Input = {
  module_lesson_plan_id: Scalars['Int'];
};

/** select columns of table "module_lesson_plan" */
export enum Module_Lesson_Plan_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  ModuleLessonPlanId = 'module_lesson_plan_id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "module_lesson_plan" */
export type Module_Lesson_Plan_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Module_Lesson_Plan_Stddev_Fields = {
  __typename?: 'module_lesson_plan_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Module_Lesson_Plan_Stddev_Pop_Fields = {
  __typename?: 'module_lesson_plan_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Module_Lesson_Plan_Stddev_Samp_Fields = {
  __typename?: 'module_lesson_plan_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Module_Lesson_Plan_Sum_Fields = {
  __typename?: 'module_lesson_plan_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  module_lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "module_lesson_plan" */
export enum Module_Lesson_Plan_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  ModuleLessonPlanId = 'module_lesson_plan_id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Module_Lesson_Plan_Var_Pop_Fields = {
  __typename?: 'module_lesson_plan_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Module_Lesson_Plan_Var_Samp_Fields = {
  __typename?: 'module_lesson_plan_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Module_Lesson_Plan_Variance_Fields = {
  __typename?: 'module_lesson_plan_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  module_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "module_lesson_plan" */
export type Module_Lesson_Plan_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Module_Max_Fields = {
  __typename?: 'module_max_fields';
  about?: Maybe<Scalars['String']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Module_Min_Fields = {
  __typename?: 'module_min_fields';
  about?: Maybe<Scalars['String']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "module" */
export type Module_Mutation_Response = {
  __typename?: 'module_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Module>;
};

/** input type for inserting object relation for remote table "module" */
export type Module_Obj_Rel_Insert_Input = {
  data: Module_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Module_On_Conflict>;
};

/** on_conflict condition type for table "module" */
export type Module_On_Conflict = {
  constraint: Module_Constraint;
  update_columns?: Array<Module_Update_Column>;
  where?: Maybe<Module_Bool_Exp>;
};

/** Ordering options when selecting data from "module". */
export type Module_Order_By = {
  about?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  class_modules_aggregate?: Maybe<Class_Module_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display_video?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  get_status_by_teacher?: Maybe<Order_By>;
  header_image_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_purchased_by_teacher?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  module_catalog_items_aggregate?: Maybe<Module_Catalog_Item_Aggregate_Order_By>;
  module_id?: Maybe<Order_By>;
  module_lesson_plan_aggregate?: Maybe<Module_Lesson_Plan_Aggregate_Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_details_aggregate?: Maybe<Order_Aggregate_Order_By>;
  partner_modules_aggregate?: Maybe<Partner_Module_Aggregate_Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_path?: Maybe<Order_By>;
  video_thumbnail_image_path?: Maybe<Order_By>;
};

/** primary key columns input for table: module */
export type Module_Pk_Columns_Input = {
  module_id: Scalars['Int'];
};

/** select columns of table "module" */
export enum Module_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayVideo = 'display_video',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  HeaderImagePath = 'header_image_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Order = 'order',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** input type for updating data in table "module" */
export type Module_Set_Input = {
  about?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_video?: Maybe<Scalars['Boolean']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Module_Stddev_Fields = {
  __typename?: 'module_stddev_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Module_Stddev_Pop_Fields = {
  __typename?: 'module_stddev_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Module_Stddev_Samp_Fields = {
  __typename?: 'module_stddev_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Module_Sum_Fields = {
  __typename?: 'module_sum_fields';
  annual_price?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "module" */
export enum Module_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayVideo = 'display_video',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  HeaderImagePath = 'header_image_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Order = 'order',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** aggregate var_pop on columns */
export type Module_Var_Pop_Fields = {
  __typename?: 'module_var_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Module_Var_Samp_Fields = {
  __typename?: 'module_var_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Module_Variance_Fields = {
  __typename?: 'module_variance_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "musical_skill" */
export type Musical_Skill = {
  __typename?: 'musical_skill';
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id: Scalars['Int'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "musical_skill" */
export type Musical_Skill_Aggregate = {
  __typename?: 'musical_skill_aggregate';
  aggregate?: Maybe<Musical_Skill_Aggregate_Fields>;
  nodes: Array<Musical_Skill>;
};

/** aggregate fields of "musical_skill" */
export type Musical_Skill_Aggregate_Fields = {
  __typename?: 'musical_skill_aggregate_fields';
  avg?: Maybe<Musical_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Musical_Skill_Max_Fields>;
  min?: Maybe<Musical_Skill_Min_Fields>;
  stddev?: Maybe<Musical_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Musical_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Musical_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Musical_Skill_Sum_Fields>;
  var_pop?: Maybe<Musical_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Musical_Skill_Var_Samp_Fields>;
  variance?: Maybe<Musical_Skill_Variance_Fields>;
};


/** aggregate fields of "musical_skill" */
export type Musical_Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Musical_Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Musical_Skill_Avg_Fields = {
  __typename?: 'musical_skill_avg_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "musical_skill". All fields are combined with a logical 'AND'. */
export type Musical_Skill_Bool_Exp = {
  _and?: Maybe<Array<Musical_Skill_Bool_Exp>>;
  _not?: Maybe<Musical_Skill_Bool_Exp>;
  _or?: Maybe<Array<Musical_Skill_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  musical_skill_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "musical_skill" */
export enum Musical_Skill_Constraint {
  /** unique or primary key constraint */
  MusicalSkillPkey1 = 'musical_skill_pkey1'
}

/** input type for incrementing numeric columns in table "musical_skill" */
export type Musical_Skill_Inc_Input = {
  musical_skill_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "musical_skill" */
export type Musical_Skill_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Musical_Skill_Max_Fields = {
  __typename?: 'musical_skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Musical_Skill_Min_Fields = {
  __typename?: 'musical_skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "musical_skill" */
export type Musical_Skill_Mutation_Response = {
  __typename?: 'musical_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Musical_Skill>;
};

/** input type for inserting object relation for remote table "musical_skill" */
export type Musical_Skill_Obj_Rel_Insert_Input = {
  data: Musical_Skill_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Musical_Skill_On_Conflict>;
};

/** on_conflict condition type for table "musical_skill" */
export type Musical_Skill_On_Conflict = {
  constraint: Musical_Skill_Constraint;
  update_columns?: Array<Musical_Skill_Update_Column>;
  where?: Maybe<Musical_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "musical_skill". */
export type Musical_Skill_Order_By = {
  created_at?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: musical_skill */
export type Musical_Skill_Pk_Columns_Input = {
  musical_skill_id: Scalars['Int'];
};

/** select columns of table "musical_skill" */
export enum Musical_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MusicalSkillId = 'musical_skill_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "musical_skill" */
export type Musical_Skill_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Musical_Skill_Stddev_Fields = {
  __typename?: 'musical_skill_stddev_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Musical_Skill_Stddev_Pop_Fields = {
  __typename?: 'musical_skill_stddev_pop_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Musical_Skill_Stddev_Samp_Fields = {
  __typename?: 'musical_skill_stddev_samp_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Musical_Skill_Sum_Fields = {
  __typename?: 'musical_skill_sum_fields';
  musical_skill_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "musical_skill" */
export enum Musical_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MusicalSkillId = 'musical_skill_id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Musical_Skill_Var_Pop_Fields = {
  __typename?: 'musical_skill_var_pop_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Musical_Skill_Var_Samp_Fields = {
  __typename?: 'musical_skill_var_samp_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Musical_Skill_Variance_Fields = {
  __typename?: 'musical_skill_variance_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  acceptConsent?: Maybe<AcceptConsentData>;
  activateAccount?: Maybe<ActivateAccountData>;
  activateTeacher?: Maybe<ActivateTeacherData>;
  addStudentsOver13?: Maybe<Response>;
  addStudentsUnder12?: Maybe<Array<Maybe<AddStudentsUnder12Data>>>;
  cancelProductSubscription?: Maybe<CancelProductSubscriptionData>;
  cancelSubscription?: Maybe<CancelSubscriptionData>;
  completeTeacherIntegration?: Maybe<CompleteTeacherIntegrationData>;
  createProductSubscription?: Maybe<CreateProductSubscriptionData>;
  createSubscription?: Maybe<CreateSubscriptionData>;
  customCreateAssignment?: Maybe<CustomCreateAssignmentData>;
  customCreateWurrly?: Maybe<CustomCreateWurrlyData>;
  customTrackUpdate: Scalars['Int'];
  customTrackUpload: Scalars['Int'];
  /** delete data from the table: "account_activation" */
  delete_account_activation?: Maybe<Account_Activation_Mutation_Response>;
  /** delete single row from the table: "account_activation" */
  delete_account_activation_by_pk?: Maybe<Account_Activation>;
  /** delete data from the table: "admin" */
  delete_admin?: Maybe<Admin_Mutation_Response>;
  /** delete single row from the table: "admin" */
  delete_admin_by_pk?: Maybe<Admin>;
  /** delete data from the table: "app" */
  delete_app?: Maybe<App_Mutation_Response>;
  /** delete single row from the table: "app" */
  delete_app_by_pk?: Maybe<App>;
  /** delete data from the table: "artist" */
  delete_artist?: Maybe<Artist_Mutation_Response>;
  /** delete single row from the table: "artist" */
  delete_artist_by_pk?: Maybe<Artist>;
  /** delete data from the table: "asset" */
  delete_asset?: Maybe<Asset_Mutation_Response>;
  /** delete single row from the table: "asset" */
  delete_asset_by_pk?: Maybe<Asset>;
  /** delete data from the table: "assignment" */
  delete_assignment?: Maybe<Assignment_Mutation_Response>;
  /** delete data from the table: "assignment_asset" */
  delete_assignment_asset?: Maybe<Assignment_Asset_Mutation_Response>;
  /** delete single row from the table: "assignment_asset" */
  delete_assignment_asset_by_pk?: Maybe<Assignment_Asset>;
  /** delete single row from the table: "assignment" */
  delete_assignment_by_pk?: Maybe<Assignment>;
  /** delete data from the table: "assignment_lesson_plan" */
  delete_assignment_lesson_plan?: Maybe<Assignment_Lesson_Plan_Mutation_Response>;
  /** delete single row from the table: "assignment_lesson_plan" */
  delete_assignment_lesson_plan_by_pk?: Maybe<Assignment_Lesson_Plan>;
  /** delete data from the table: "assignment_song" */
  delete_assignment_song?: Maybe<Assignment_Song_Mutation_Response>;
  /** delete single row from the table: "assignment_song" */
  delete_assignment_song_by_pk?: Maybe<Assignment_Song>;
  /** delete data from the table: "assignment_tip" */
  delete_assignment_tip?: Maybe<Assignment_Tip_Mutation_Response>;
  /** delete single row from the table: "assignment_tip" */
  delete_assignment_tip_by_pk?: Maybe<Assignment_Tip>;
  /** delete data from the table: "available" */
  delete_available?: Maybe<Available_Mutation_Response>;
  /** delete single row from the table: "available" */
  delete_available_by_pk?: Maybe<Available>;
  /** delete data from the table: "awarded_badge_event" */
  delete_awarded_badge_event?: Maybe<Awarded_Badge_Event_Mutation_Response>;
  /** delete single row from the table: "awarded_badge_event" */
  delete_awarded_badge_event_by_pk?: Maybe<Awarded_Badge_Event>;
  /** delete data from the table: "badge" */
  delete_badge?: Maybe<Badge_Mutation_Response>;
  /** delete single row from the table: "badge" */
  delete_badge_by_pk?: Maybe<Badge>;
  /** delete data from the table: "badge_event" */
  delete_badge_event?: Maybe<Badge_Event_Mutation_Response>;
  /** delete single row from the table: "badge_event" */
  delete_badge_event_by_pk?: Maybe<Badge_Event>;
  /** delete data from the table: "badge_event_name" */
  delete_badge_event_name?: Maybe<Badge_Event_Name_Mutation_Response>;
  /** delete single row from the table: "badge_event_name" */
  delete_badge_event_name_by_pk?: Maybe<Badge_Event_Name>;
  /** delete data from the table: "billing_history" */
  delete_billing_history?: Maybe<Billing_History_Mutation_Response>;
  /** delete single row from the table: "billing_history" */
  delete_billing_history_by_pk?: Maybe<Billing_History>;
  /** delete data from the table: "billing_method" */
  delete_billing_method?: Maybe<Billing_Method_Mutation_Response>;
  /** delete single row from the table: "billing_method" */
  delete_billing_method_by_pk?: Maybe<Billing_Method>;
  /** delete data from the table: "billing_status" */
  delete_billing_status?: Maybe<Billing_Status_Mutation_Response>;
  /** delete single row from the table: "billing_status" */
  delete_billing_status_by_pk?: Maybe<Billing_Status>;
  /** delete data from the table: "catalog" */
  delete_catalog?: Maybe<Catalog_Mutation_Response>;
  /** delete single row from the table: "catalog" */
  delete_catalog_by_pk?: Maybe<Catalog>;
  /** delete data from the table: "catalog_item" */
  delete_catalog_item?: Maybe<Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "catalog_item" */
  delete_catalog_item_by_pk?: Maybe<Catalog_Item>;
  /** delete data from the table: "catalog_name" */
  delete_catalog_name?: Maybe<Catalog_Name_Mutation_Response>;
  /** delete single row from the table: "catalog_name" */
  delete_catalog_name_by_pk?: Maybe<Catalog_Name>;
  /** delete data from the table: "category_standard" */
  delete_category_standard?: Maybe<Category_Standard_Mutation_Response>;
  /** delete single row from the table: "category_standard" */
  delete_category_standard_by_pk?: Maybe<Category_Standard>;
  /** delete data from the table: "challenge" */
  delete_challenge?: Maybe<Challenge_Mutation_Response>;
  /** delete single row from the table: "challenge" */
  delete_challenge_by_pk?: Maybe<Challenge>;
  /** delete data from the table: "challenge_catalog_item" */
  delete_challenge_catalog_item?: Maybe<Challenge_Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "challenge_catalog_item" */
  delete_challenge_catalog_item_by_pk?: Maybe<Challenge_Catalog_Item>;
  /** delete data from the table: "challenge_district" */
  delete_challenge_district?: Maybe<Challenge_District_Mutation_Response>;
  /** delete single row from the table: "challenge_district" */
  delete_challenge_district_by_pk?: Maybe<Challenge_District>;
  /** delete data from the table: "challenge_grade" */
  delete_challenge_grade?: Maybe<Challenge_Grade_Mutation_Response>;
  /** delete single row from the table: "challenge_grade" */
  delete_challenge_grade_by_pk?: Maybe<Challenge_Grade>;
  /** delete data from the table: "challenge_song" */
  delete_challenge_song?: Maybe<Challenge_Song_Mutation_Response>;
  /** delete single row from the table: "challenge_song" */
  delete_challenge_song_by_pk?: Maybe<Challenge_Song>;
  /** delete data from the table: "challenge_student" */
  delete_challenge_student?: Maybe<Challenge_Student_Mutation_Response>;
  /** delete single row from the table: "challenge_student" */
  delete_challenge_student_by_pk?: Maybe<Challenge_Student>;
  /** delete data from the table: "channel" */
  delete_channel?: Maybe<Channel_Mutation_Response>;
  /** delete single row from the table: "channel" */
  delete_channel_by_pk?: Maybe<Channel>;
  /** delete data from the table: "channel_playlist" */
  delete_channel_playlist?: Maybe<Channel_Playlist_Mutation_Response>;
  /** delete single row from the table: "channel_playlist" */
  delete_channel_playlist_by_pk?: Maybe<Channel_Playlist>;
  /** delete data from the table: "class" */
  delete_class?: Maybe<Class_Mutation_Response>;
  /** delete data from the table: "class_assignment" */
  delete_class_assignment?: Maybe<Class_Assignment_Mutation_Response>;
  /** delete single row from the table: "class_assignment" */
  delete_class_assignment_by_pk?: Maybe<Class_Assignment>;
  /** delete data from the table: "class_assignment_status" */
  delete_class_assignment_status?: Maybe<Class_Assignment_Status_Mutation_Response>;
  /** delete single row from the table: "class_assignment_status" */
  delete_class_assignment_status_by_pk?: Maybe<Class_Assignment_Status>;
  /** delete single row from the table: "class" */
  delete_class_by_pk?: Maybe<Class>;
  /** delete data from the table: "class_images" */
  delete_class_images?: Maybe<Class_Images_Mutation_Response>;
  /** delete single row from the table: "class_images" */
  delete_class_images_by_pk?: Maybe<Class_Images>;
  /** delete data from the table: "class_lesson_plan" */
  delete_class_lesson_plan?: Maybe<Class_Lesson_Plan_Mutation_Response>;
  /** delete single row from the table: "class_lesson_plan" */
  delete_class_lesson_plan_by_pk?: Maybe<Class_Lesson_Plan>;
  /** delete data from the table: "class_module" */
  delete_class_module?: Maybe<Class_Module_Mutation_Response>;
  /** delete single row from the table: "class_module" */
  delete_class_module_by_pk?: Maybe<Class_Module>;
  /** delete data from the table: "class_playlist" */
  delete_class_playlist?: Maybe<Class_Playlist_Mutation_Response>;
  /** delete single row from the table: "class_playlist" */
  delete_class_playlist_by_pk?: Maybe<Class_Playlist>;
  /** delete data from the table: "class_sequence" */
  delete_class_sequence?: Maybe<Class_Sequence_Mutation_Response>;
  /** delete single row from the table: "class_sequence" */
  delete_class_sequence_by_pk?: Maybe<Class_Sequence>;
  /** delete data from the table: "class_song" */
  delete_class_song?: Maybe<Class_Song_Mutation_Response>;
  /** delete single row from the table: "class_song" */
  delete_class_song_by_pk?: Maybe<Class_Song>;
  /** delete data from the table: "class_student" */
  delete_class_student?: Maybe<Class_Student_Mutation_Response>;
  /** delete data from the table: "class_student_activities" */
  delete_class_student_activities?: Maybe<Class_Student_Activities_Mutation_Response>;
  /** delete single row from the table: "class_student_activities" */
  delete_class_student_activities_by_pk?: Maybe<Class_Student_Activities>;
  /** delete single row from the table: "class_student" */
  delete_class_student_by_pk?: Maybe<Class_Student>;
  /** delete data from the table: "class_teacher" */
  delete_class_teacher?: Maybe<Class_Teacher_Mutation_Response>;
  /** delete single row from the table: "class_teacher" */
  delete_class_teacher_by_pk?: Maybe<Class_Teacher>;
  /** delete data from the table: "class_tip" */
  delete_class_tip?: Maybe<Class_Tip_Mutation_Response>;
  /** delete single row from the table: "class_tip" */
  delete_class_tip_by_pk?: Maybe<Class_Tip>;
  /** delete data from the table: "clever_roster_log" */
  delete_clever_roster_log?: Maybe<Clever_Roster_Log_Mutation_Response>;
  /** delete single row from the table: "clever_roster_log" */
  delete_clever_roster_log_by_pk?: Maybe<Clever_Roster_Log>;
  /** delete data from the table: "clever_roster_status" */
  delete_clever_roster_status?: Maybe<Clever_Roster_Status_Mutation_Response>;
  /** delete single row from the table: "clever_roster_status" */
  delete_clever_roster_status_by_pk?: Maybe<Clever_Roster_Status>;
  /** delete data from the table: "contact_us" */
  delete_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** delete single row from the table: "contact_us" */
  delete_contact_us_by_pk?: Maybe<Contact_Us>;
  /** delete data from the table: "contact_us_camp_teacher" */
  delete_contact_us_camp_teacher?: Maybe<Contact_Us_Camp_Teacher_Mutation_Response>;
  /** delete single row from the table: "contact_us_camp_teacher" */
  delete_contact_us_camp_teacher_by_pk?: Maybe<Contact_Us_Camp_Teacher>;
  /** delete data from the table: "crossclear.masterlist" */
  delete_crossclear_masterlist?: Maybe<Crossclear_Masterlist_Mutation_Response>;
  /** delete single row from the table: "crossclear.masterlist" */
  delete_crossclear_masterlist_by_pk?: Maybe<Crossclear_Masterlist>;
  /** delete data from the table: "discipline" */
  delete_discipline?: Maybe<Discipline_Mutation_Response>;
  /** delete single row from the table: "discipline" */
  delete_discipline_by_pk?: Maybe<Discipline>;
  /** delete data from the table: "district" */
  delete_district?: Maybe<District_Mutation_Response>;
  /** delete data from the table: "district_admin" */
  delete_district_admin?: Maybe<District_Admin_Mutation_Response>;
  /** delete single row from the table: "district_admin" */
  delete_district_admin_by_pk?: Maybe<District_Admin>;
  /** delete single row from the table: "district" */
  delete_district_by_pk?: Maybe<District>;
  /** delete data from the table: "filter_color" */
  delete_filter_color?: Maybe<Filter_Color_Mutation_Response>;
  /** delete single row from the table: "filter_color" */
  delete_filter_color_by_pk?: Maybe<Filter_Color>;
  /** delete data from the table: "filter_color_name" */
  delete_filter_color_name?: Maybe<Filter_Color_Name_Mutation_Response>;
  /** delete single row from the table: "filter_color_name" */
  delete_filter_color_name_by_pk?: Maybe<Filter_Color_Name>;
  /** delete data from the table: "filter_frame" */
  delete_filter_frame?: Maybe<Filter_Frame_Mutation_Response>;
  /** delete data from the table: "filter_sticker" */
  delete_filter_sticker?: Maybe<Filter_Sticker_Mutation_Response>;
  /** delete single row from the table: "filter_sticker" */
  delete_filter_sticker_by_pk?: Maybe<Filter_Sticker>;
  /** delete data from the table: "grade" */
  delete_grade?: Maybe<Grade_Mutation_Response>;
  /** delete single row from the table: "grade" */
  delete_grade_by_pk?: Maybe<Grade>;
  /** delete data from the table: "grading_system" */
  delete_grading_system?: Maybe<Grading_System_Mutation_Response>;
  /** delete single row from the table: "grading_system" */
  delete_grading_system_by_pk?: Maybe<Grading_System>;
  /** delete data from the table: "grading_system_type" */
  delete_grading_system_type?: Maybe<Grading_System_Type_Mutation_Response>;
  /** delete single row from the table: "grading_system_type" */
  delete_grading_system_type_by_pk?: Maybe<Grading_System_Type>;
  /** delete data from the table: "integration_name" */
  delete_integration_name?: Maybe<Integration_Name_Mutation_Response>;
  /** delete single row from the table: "integration_name" */
  delete_integration_name_by_pk?: Maybe<Integration_Name>;
  /** delete data from the table: "invitation_status" */
  delete_invitation_status?: Maybe<Invitation_Status_Mutation_Response>;
  /** delete single row from the table: "invitation_status" */
  delete_invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** delete data from the table: "keyword" */
  delete_keyword?: Maybe<Keyword_Mutation_Response>;
  /** delete single row from the table: "keyword" */
  delete_keyword_by_pk?: Maybe<Keyword>;
  /** delete data from the table: "label_control" */
  delete_label_control?: Maybe<Label_Control_Mutation_Response>;
  /** delete single row from the table: "label_control" */
  delete_label_control_by_pk?: Maybe<Label_Control>;
  /** delete data from the table: "labels" */
  delete_labels?: Maybe<Labels_Mutation_Response>;
  /** delete single row from the table: "labels" */
  delete_labels_by_pk?: Maybe<Labels>;
  /** delete data from the table: "lesson_plan" */
  delete_lesson_plan?: Maybe<Lesson_Plan_Mutation_Response>;
  /** delete data from the table: "lesson_plan_article" */
  delete_lesson_plan_article?: Maybe<Lesson_Plan_Article_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_article" */
  delete_lesson_plan_article_by_pk?: Maybe<Lesson_Plan_Article>;
  /** delete data from the table: "lesson_plan_asset" */
  delete_lesson_plan_asset?: Maybe<Lesson_Plan_Asset_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_asset" */
  delete_lesson_plan_asset_by_pk?: Maybe<Lesson_Plan_Asset>;
  /** delete single row from the table: "lesson_plan" */
  delete_lesson_plan_by_pk?: Maybe<Lesson_Plan>;
  /** delete data from the table: "lesson_plan_catalog_item" */
  delete_lesson_plan_catalog_item?: Maybe<Lesson_Plan_Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_catalog_item" */
  delete_lesson_plan_catalog_item_by_pk?: Maybe<Lesson_Plan_Catalog_Item>;
  /** delete data from the table: "lesson_plan_discipline" */
  delete_lesson_plan_discipline?: Maybe<Lesson_Plan_Discipline_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_discipline" */
  delete_lesson_plan_discipline_by_pk?: Maybe<Lesson_Plan_Discipline>;
  /** delete data from the table: "lesson_plan_grade" */
  delete_lesson_plan_grade?: Maybe<Lesson_Plan_Grade_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_grade" */
  delete_lesson_plan_grade_by_pk?: Maybe<Lesson_Plan_Grade>;
  /** delete data from the table: "lesson_plan_keyword" */
  delete_lesson_plan_keyword?: Maybe<Lesson_Plan_Keyword_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_keyword" */
  delete_lesson_plan_keyword_by_pk?: Maybe<Lesson_Plan_Keyword>;
  /** delete data from the table: "lesson_plan_musical_skill" */
  delete_lesson_plan_musical_skill?: Maybe<Lesson_Plan_Musical_Skill_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_musical_skill" */
  delete_lesson_plan_musical_skill_by_pk?: Maybe<Lesson_Plan_Musical_Skill>;
  /** delete data from the table: "lesson_plan_non_musical_skill" */
  delete_lesson_plan_non_musical_skill?: Maybe<Lesson_Plan_Non_Musical_Skill_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_non_musical_skill" */
  delete_lesson_plan_non_musical_skill_by_pk?: Maybe<Lesson_Plan_Non_Musical_Skill>;
  /** delete data from the table: "lesson_plan_skill_level" */
  delete_lesson_plan_skill_level?: Maybe<Lesson_Plan_Skill_Level_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_skill_level" */
  delete_lesson_plan_skill_level_by_pk?: Maybe<Lesson_Plan_Skill_Level>;
  /** delete data from the table: "lesson_plan_song" */
  delete_lesson_plan_song?: Maybe<Lesson_Plan_Song_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_song" */
  delete_lesson_plan_song_by_pk?: Maybe<Lesson_Plan_Song>;
  /** delete data from the table: "lesson_plan_standard" */
  delete_lesson_plan_standard?: Maybe<Lesson_Plan_Standard_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_standard" */
  delete_lesson_plan_standard_by_pk?: Maybe<Lesson_Plan_Standard>;
  /** delete data from the table: "lesson_plan_tip" */
  delete_lesson_plan_tip?: Maybe<Lesson_Plan_Tip_Mutation_Response>;
  /** delete single row from the table: "lesson_plan_tip" */
  delete_lesson_plan_tip_by_pk?: Maybe<Lesson_Plan_Tip>;
  /** delete data from the table: "lockable_content" */
  delete_lockable_content?: Maybe<Lockable_Content_Mutation_Response>;
  /** delete single row from the table: "lockable_content" */
  delete_lockable_content_by_pk?: Maybe<Lockable_Content>;
  /** delete data from the table: "lockable_content_class" */
  delete_lockable_content_class?: Maybe<Lockable_Content_Class_Mutation_Response>;
  /** delete single row from the table: "lockable_content_class" */
  delete_lockable_content_class_by_pk?: Maybe<Lockable_Content_Class>;
  /** delete data from the table: "lockable_content_district" */
  delete_lockable_content_district?: Maybe<Lockable_Content_District_Mutation_Response>;
  /** delete single row from the table: "lockable_content_district" */
  delete_lockable_content_district_by_pk?: Maybe<Lockable_Content_District>;
  /** delete data from the table: "lockable_content_school" */
  delete_lockable_content_school?: Maybe<Lockable_Content_School_Mutation_Response>;
  /** delete single row from the table: "lockable_content_school" */
  delete_lockable_content_school_by_pk?: Maybe<Lockable_Content_School>;
  /** delete data from the table: "lockable_content_teacher" */
  delete_lockable_content_teacher?: Maybe<Lockable_Content_Teacher_Mutation_Response>;
  /** delete single row from the table: "lockable_content_teacher" */
  delete_lockable_content_teacher_by_pk?: Maybe<Lockable_Content_Teacher>;
  /** delete data from the table: "maturity_level" */
  delete_maturity_level?: Maybe<Maturity_Level_Mutation_Response>;
  /** delete single row from the table: "maturity_level" */
  delete_maturity_level_by_pk?: Maybe<Maturity_Level>;
  /** delete data from the table: "membership_type" */
  delete_membership_type?: Maybe<Membership_Type_Mutation_Response>;
  /** delete single row from the table: "membership_type" */
  delete_membership_type_by_pk?: Maybe<Membership_Type>;
  /** delete data from the table: "midi" */
  delete_midi?: Maybe<Midi_Mutation_Response>;
  /** delete single row from the table: "midi" */
  delete_midi_by_pk?: Maybe<Midi>;
  /** delete data from the table: "module" */
  delete_module?: Maybe<Module_Mutation_Response>;
  /** delete single row from the table: "module" */
  delete_module_by_pk?: Maybe<Module>;
  /** delete data from the table: "module_catalog_item" */
  delete_module_catalog_item?: Maybe<Module_Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "module_catalog_item" */
  delete_module_catalog_item_by_pk?: Maybe<Module_Catalog_Item>;
  /** delete data from the table: "module_grade" */
  delete_module_grade?: Maybe<Module_Grade_Mutation_Response>;
  /** delete single row from the table: "module_grade" */
  delete_module_grade_by_pk?: Maybe<Module_Grade>;
  /** delete data from the table: "module_lesson_plan" */
  delete_module_lesson_plan?: Maybe<Module_Lesson_Plan_Mutation_Response>;
  /** delete single row from the table: "module_lesson_plan" */
  delete_module_lesson_plan_by_pk?: Maybe<Module_Lesson_Plan>;
  /** delete data from the table: "musical_skill" */
  delete_musical_skill?: Maybe<Musical_Skill_Mutation_Response>;
  /** delete single row from the table: "musical_skill" */
  delete_musical_skill_by_pk?: Maybe<Musical_Skill>;
  /** delete data from the table: "non_musical_skill" */
  delete_non_musical_skill?: Maybe<Non_Musical_Skill_Mutation_Response>;
  /** delete single row from the table: "non_musical_skill" */
  delete_non_musical_skill_by_pk?: Maybe<Non_Musical_Skill>;
  /** delete data from the table: "non_musical_skill_type" */
  delete_non_musical_skill_type?: Maybe<Non_Musical_Skill_Type_Mutation_Response>;
  /** delete single row from the table: "non_musical_skill_type" */
  delete_non_musical_skill_type_by_pk?: Maybe<Non_Musical_Skill_Type>;
  /** delete data from the table: "order" */
  delete_order?: Maybe<Order_Mutation_Response>;
  /** delete single row from the table: "order" */
  delete_order_by_pk?: Maybe<Order>;
  /** delete data from the table: "order_status" */
  delete_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** delete single row from the table: "order_status" */
  delete_order_status_by_pk?: Maybe<Order_Status>;
  /** delete data from the table: "parent_invitation" */
  delete_parent_invitation?: Maybe<Parent_Invitation_Mutation_Response>;
  /** delete single row from the table: "parent_invitation" */
  delete_parent_invitation_by_pk?: Maybe<Parent_Invitation>;
  /** delete data from the table: "partner" */
  delete_partner?: Maybe<Partner_Mutation_Response>;
  /** delete single row from the table: "partner" */
  delete_partner_by_pk?: Maybe<Partner>;
  /** delete data from the table: "partner_module" */
  delete_partner_module?: Maybe<Partner_Module_Mutation_Response>;
  /** delete single row from the table: "partner_module" */
  delete_partner_module_by_pk?: Maybe<Partner_Module>;
  /** delete data from the table: "partner_playlist" */
  delete_partner_playlist?: Maybe<Partner_Playlist_Mutation_Response>;
  /** delete single row from the table: "partner_playlist" */
  delete_partner_playlist_by_pk?: Maybe<Partner_Playlist>;
  /** delete data from the table: "playlist" */
  delete_playlist?: Maybe<Playlist_Mutation_Response>;
  /** delete single row from the table: "playlist" */
  delete_playlist_by_pk?: Maybe<Playlist>;
  /** delete data from the table: "price_type" */
  delete_price_type?: Maybe<Price_Type_Mutation_Response>;
  /** delete single row from the table: "price_type" */
  delete_price_type_by_pk?: Maybe<Price_Type>;
  /** delete data from the table: "privacy" */
  delete_privacy?: Maybe<Privacy_Mutation_Response>;
  /** delete single row from the table: "privacy" */
  delete_privacy_by_pk?: Maybe<Privacy>;
  /** delete data from the table: "product_name" */
  delete_product_name?: Maybe<Product_Name_Mutation_Response>;
  /** delete single row from the table: "product_name" */
  delete_product_name_by_pk?: Maybe<Product_Name>;
  /** delete data from the table: "publisher" */
  delete_publisher?: Maybe<Publisher_Mutation_Response>;
  /** delete single row from the table: "publisher" */
  delete_publisher_by_pk?: Maybe<Publisher>;
  /** delete data from the table: "publisher_control" */
  delete_publisher_control?: Maybe<Publisher_Control_Mutation_Response>;
  /** delete single row from the table: "publisher_control" */
  delete_publisher_control_by_pk?: Maybe<Publisher_Control>;
  /** delete data from the table: "reaction" */
  delete_reaction?: Maybe<Reaction_Mutation_Response>;
  /** delete single row from the table: "reaction" */
  delete_reaction_by_pk?: Maybe<Reaction>;
  /** delete data from the table: "reset_password" */
  delete_reset_password?: Maybe<Reset_Password_Mutation_Response>;
  /** delete single row from the table: "reset_password" */
  delete_reset_password_by_pk?: Maybe<Reset_Password>;
  /** delete data from the table: "role_name" */
  delete_role_name?: Maybe<Role_Name_Mutation_Response>;
  /** delete single row from the table: "role_name" */
  delete_role_name_by_pk?: Maybe<Role_Name>;
  /** delete data from the table: "royalty_log" */
  delete_royalty_log?: Maybe<Royalty_Log_Mutation_Response>;
  /** delete single row from the table: "royalty_log" */
  delete_royalty_log_by_pk?: Maybe<Royalty_Log>;
  /** delete data from the table: "scale" */
  delete_scale?: Maybe<Scale_Mutation_Response>;
  /** delete single row from the table: "scale" */
  delete_scale_by_pk?: Maybe<Scale>;
  /** delete data from the table: "school" */
  delete_school?: Maybe<School_Mutation_Response>;
  /** delete data from the table: "school_admin" */
  delete_school_admin?: Maybe<School_Admin_Mutation_Response>;
  /** delete single row from the table: "school_admin" */
  delete_school_admin_by_pk?: Maybe<School_Admin>;
  /** delete single row from the table: "school" */
  delete_school_by_pk?: Maybe<School>;
  /** delete data from the table: "school_teacher" */
  delete_school_teacher?: Maybe<School_Teacher_Mutation_Response>;
  /** delete single row from the table: "school_teacher" */
  delete_school_teacher_by_pk?: Maybe<School_Teacher>;
  /** delete data from the table: "screen_type" */
  delete_screen_type?: Maybe<Screen_Type_Mutation_Response>;
  /** delete single row from the table: "screen_type" */
  delete_screen_type_by_pk?: Maybe<Screen_Type>;
  /** delete data from the table: "sequence" */
  delete_sequence?: Maybe<Sequence_Mutation_Response>;
  /** delete single row from the table: "sequence" */
  delete_sequence_by_pk?: Maybe<Sequence>;
  /** delete data from the table: "sequence_keyword" */
  delete_sequence_keyword?: Maybe<Sequence_Keyword_Mutation_Response>;
  /** delete single row from the table: "sequence_keyword" */
  delete_sequence_keyword_by_pk?: Maybe<Sequence_Keyword>;
  /** delete data from the table: "sequence_lesson_plan" */
  delete_sequence_lesson_plan?: Maybe<Sequence_Lesson_Plan_Mutation_Response>;
  /** delete single row from the table: "sequence_lesson_plan" */
  delete_sequence_lesson_plan_by_pk?: Maybe<Sequence_Lesson_Plan>;
  /** delete data from the table: "sequence_topic" */
  delete_sequence_topic?: Maybe<Sequence_Topic_Mutation_Response>;
  /** delete single row from the table: "sequence_topic" */
  delete_sequence_topic_by_pk?: Maybe<Sequence_Topic>;
  /** delete data from the table: "sequence_topic_catalog_item" */
  delete_sequence_topic_catalog_item?: Maybe<Sequence_Topic_Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "sequence_topic_catalog_item" */
  delete_sequence_topic_catalog_item_by_pk?: Maybe<Sequence_Topic_Catalog_Item>;
  /** delete data from the table: "sequence_topic_keyword" */
  delete_sequence_topic_keyword?: Maybe<Sequence_Topic_Keyword_Mutation_Response>;
  /** delete single row from the table: "sequence_topic_keyword" */
  delete_sequence_topic_keyword_by_pk?: Maybe<Sequence_Topic_Keyword>;
  /** delete data from the table: "sequence_topic_sequence" */
  delete_sequence_topic_sequence?: Maybe<Sequence_Topic_Sequence_Mutation_Response>;
  /** delete single row from the table: "sequence_topic_sequence" */
  delete_sequence_topic_sequence_by_pk?: Maybe<Sequence_Topic_Sequence>;
  /** delete data from the table: "session" */
  delete_session?: Maybe<Session_Mutation_Response>;
  /** delete single row from the table: "session" */
  delete_session_by_pk?: Maybe<Session>;
  /** delete data from the table: "skill_level" */
  delete_skill_level?: Maybe<Skill_Level_Mutation_Response>;
  /** delete single row from the table: "skill_level" */
  delete_skill_level_by_pk?: Maybe<Skill_Level>;
  /** delete data from the table: "song" */
  delete_song?: Maybe<Song_Mutation_Response>;
  /** delete single row from the table: "song" */
  delete_song_by_pk?: Maybe<Song>;
  /** delete data from the table: "song_catalog_item" */
  delete_song_catalog_item?: Maybe<Song_Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "song_catalog_item" */
  delete_song_catalog_item_by_pk?: Maybe<Song_Catalog_Item>;
  /** delete data from the table: "song_grade" */
  delete_song_grade?: Maybe<Song_Grade_Mutation_Response>;
  /** delete single row from the table: "song_grade" */
  delete_song_grade_by_pk?: Maybe<Song_Grade>;
  /** delete data from the table: "song_keyword" */
  delete_song_keyword?: Maybe<Song_Keyword_Mutation_Response>;
  /** delete single row from the table: "song_keyword" */
  delete_song_keyword_by_pk?: Maybe<Song_Keyword>;
  /** delete data from the table: "standard" */
  delete_standard?: Maybe<Standard_Mutation_Response>;
  /** delete single row from the table: "standard" */
  delete_standard_by_pk?: Maybe<Standard>;
  /** delete data from the table: "standard_option" */
  delete_standard_option?: Maybe<Standard_Option_Mutation_Response>;
  /** delete single row from the table: "standard_option" */
  delete_standard_option_by_pk?: Maybe<Standard_Option>;
  /** delete data from the table: "standard_subcategory" */
  delete_standard_subcategory?: Maybe<Standard_Subcategory_Mutation_Response>;
  /** delete single row from the table: "standard_subcategory" */
  delete_standard_subcategory_by_pk?: Maybe<Standard_Subcategory>;
  /** delete data from the table: "student" */
  delete_student?: Maybe<Student_Mutation_Response>;
  /** delete data from the table: "student_activity" */
  delete_student_activity?: Maybe<Student_Activity_Mutation_Response>;
  /** delete single row from the table: "student_activity" */
  delete_student_activity_by_pk?: Maybe<Student_Activity>;
  /** delete data from the table: "student_activity_type" */
  delete_student_activity_type?: Maybe<Student_Activity_Type_Mutation_Response>;
  /** delete single row from the table: "student_activity_type" */
  delete_student_activity_type_by_pk?: Maybe<Student_Activity_Type>;
  /** delete data from the table: "student_badge" */
  delete_student_badge?: Maybe<Student_Badge_Mutation_Response>;
  /** delete single row from the table: "student_badge" */
  delete_student_badge_by_pk?: Maybe<Student_Badge>;
  /** delete data from the table: "student_badge_event" */
  delete_student_badge_event?: Maybe<Student_Badge_Event_Mutation_Response>;
  /** delete single row from the table: "student_badge_event" */
  delete_student_badge_event_by_pk?: Maybe<Student_Badge_Event>;
  /** delete data from the table: "student_banner" */
  delete_student_banner?: Maybe<Student_Banner_Mutation_Response>;
  /** delete single row from the table: "student_banner" */
  delete_student_banner_by_pk?: Maybe<Student_Banner>;
  /** delete single row from the table: "student" */
  delete_student_by_pk?: Maybe<Student>;
  /** delete data from the table: "student_goal" */
  delete_student_goal?: Maybe<Student_Goal_Mutation_Response>;
  /** delete single row from the table: "student_goal" */
  delete_student_goal_by_pk?: Maybe<Student_Goal>;
  /** delete data from the table: "student_integration" */
  delete_student_integration?: Maybe<Student_Integration_Mutation_Response>;
  /** delete single row from the table: "student_integration" */
  delete_student_integration_by_pk?: Maybe<Student_Integration>;
  /** delete data from the table: "student_lesson_plan_favorite" */
  delete_student_lesson_plan_favorite?: Maybe<Student_Lesson_Plan_Favorite_Mutation_Response>;
  /** delete single row from the table: "student_lesson_plan_favorite" */
  delete_student_lesson_plan_favorite_by_pk?: Maybe<Student_Lesson_Plan_Favorite>;
  /** delete data from the table: "student_productivity_daily" */
  delete_student_productivity_daily?: Maybe<Student_Productivity_Daily_Mutation_Response>;
  /** delete single row from the table: "student_productivity_daily" */
  delete_student_productivity_daily_by_pk?: Maybe<Student_Productivity_Daily>;
  /** delete data from the table: "student_productivity_monthly" */
  delete_student_productivity_monthly?: Maybe<Student_Productivity_Monthly_Mutation_Response>;
  /** delete single row from the table: "student_productivity_monthly" */
  delete_student_productivity_monthly_by_pk?: Maybe<Student_Productivity_Monthly>;
  /** delete data from the table: "student_productivity_yearly" */
  delete_student_productivity_yearly?: Maybe<Student_Productivity_Yearly_Mutation_Response>;
  /** delete single row from the table: "student_productivity_yearly" */
  delete_student_productivity_yearly_by_pk?: Maybe<Student_Productivity_Yearly>;
  /** delete data from the table: "student_setting" */
  delete_student_setting?: Maybe<Student_Setting_Mutation_Response>;
  /** delete single row from the table: "student_setting" */
  delete_student_setting_by_pk?: Maybe<Student_Setting>;
  /** delete data from the table: "student_song_favorite" */
  delete_student_song_favorite?: Maybe<Student_Song_Favorite_Mutation_Response>;
  /** delete single row from the table: "student_song_favorite" */
  delete_student_song_favorite_by_pk?: Maybe<Student_Song_Favorite>;
  /** delete data from the table: "student_tip_favorite" */
  delete_student_tip_favorite?: Maybe<Student_Tip_Favorite_Mutation_Response>;
  /** delete single row from the table: "student_tip_favorite" */
  delete_student_tip_favorite_by_pk?: Maybe<Student_Tip_Favorite>;
  /** delete data from the table: "submission" */
  delete_submission?: Maybe<Submission_Mutation_Response>;
  /** delete data from the table: "submission_asset" */
  delete_submission_asset?: Maybe<Submission_Asset_Mutation_Response>;
  /** delete single row from the table: "submission_asset" */
  delete_submission_asset_by_pk?: Maybe<Submission_Asset>;
  /** delete single row from the table: "submission" */
  delete_submission_by_pk?: Maybe<Submission>;
  /** delete data from the table: "submission_state" */
  delete_submission_state?: Maybe<Submission_State_Mutation_Response>;
  /** delete single row from the table: "submission_state" */
  delete_submission_state_by_pk?: Maybe<Submission_State>;
  /** delete data from the table: "submission_student" */
  delete_submission_student?: Maybe<Submission_Student_Mutation_Response>;
  /** delete single row from the table: "submission_student" */
  delete_submission_student_by_pk?: Maybe<Submission_Student>;
  /** delete data from the table: "submission_wurrly" */
  delete_submission_wurrly?: Maybe<Submission_Wurrly_Mutation_Response>;
  /** delete single row from the table: "submission_wurrly" */
  delete_submission_wurrly_by_pk?: Maybe<Submission_Wurrly>;
  /** delete data from the table: "submission_wurrly_state" */
  delete_submission_wurrly_state?: Maybe<Submission_Wurrly_State_Mutation_Response>;
  /** delete single row from the table: "submission_wurrly_state" */
  delete_submission_wurrly_state_by_pk?: Maybe<Submission_Wurrly_State>;
  /** delete data from the table: "teacher" */
  delete_teacher?: Maybe<Teacher_Mutation_Response>;
  /** delete single row from the table: "teacher" */
  delete_teacher_by_pk?: Maybe<Teacher>;
  /** delete data from the table: "teacher_integration" */
  delete_teacher_integration?: Maybe<Teacher_Integration_Mutation_Response>;
  /** delete single row from the table: "teacher_integration" */
  delete_teacher_integration_by_pk?: Maybe<Teacher_Integration>;
  /** delete data from the table: "teacher_lesson_plan_favorite" */
  delete_teacher_lesson_plan_favorite?: Maybe<Teacher_Lesson_Plan_Favorite_Mutation_Response>;
  /** delete single row from the table: "teacher_lesson_plan_favorite" */
  delete_teacher_lesson_plan_favorite_by_pk?: Maybe<Teacher_Lesson_Plan_Favorite>;
  /** delete data from the table: "teacher_setting" */
  delete_teacher_setting?: Maybe<Teacher_Setting_Mutation_Response>;
  /** delete single row from the table: "teacher_setting" */
  delete_teacher_setting_by_pk?: Maybe<Teacher_Setting>;
  /** delete data from the table: "teacher_song_favorite" */
  delete_teacher_song_favorite?: Maybe<Teacher_Song_Favorite_Mutation_Response>;
  /** delete single row from the table: "teacher_song_favorite" */
  delete_teacher_song_favorite_by_pk?: Maybe<Teacher_Song_Favorite>;
  /** delete data from the table: "teacher_subscription" */
  delete_teacher_subscription?: Maybe<Teacher_Subscription_Mutation_Response>;
  /** delete single row from the table: "teacher_subscription" */
  delete_teacher_subscription_by_pk?: Maybe<Teacher_Subscription>;
  /** delete data from the table: "teacher_tip_favorite" */
  delete_teacher_tip_favorite?: Maybe<Teacher_Tip_Favorite_Mutation_Response>;
  /** delete single row from the table: "teacher_tip_favorite" */
  delete_teacher_tip_favorite_by_pk?: Maybe<Teacher_Tip_Favorite>;
  /** delete data from the table: "teacher_wurrly_comment" */
  delete_teacher_wurrly_comment?: Maybe<Teacher_Wurrly_Comment_Mutation_Response>;
  /** delete single row from the table: "teacher_wurrly_comment" */
  delete_teacher_wurrly_comment_by_pk?: Maybe<Teacher_Wurrly_Comment>;
  /** delete data from the table: "tip" */
  delete_tip?: Maybe<Tip_Mutation_Response>;
  /** delete data from the table: "tip_age" */
  delete_tip_age?: Maybe<Tip_Age_Mutation_Response>;
  /** delete single row from the table: "tip_age" */
  delete_tip_age_by_pk?: Maybe<Tip_Age>;
  /** delete single row from the table: "tip" */
  delete_tip_by_pk?: Maybe<Tip>;
  /** delete data from the table: "tip_catalog_item" */
  delete_tip_catalog_item?: Maybe<Tip_Catalog_Item_Mutation_Response>;
  /** delete single row from the table: "tip_catalog_item" */
  delete_tip_catalog_item_by_pk?: Maybe<Tip_Catalog_Item>;
  /** delete data from the table: "tip_grade" */
  delete_tip_grade?: Maybe<Tip_Grade_Mutation_Response>;
  /** delete single row from the table: "tip_grade" */
  delete_tip_grade_by_pk?: Maybe<Tip_Grade>;
  /** delete data from the table: "tip_keyword" */
  delete_tip_keyword?: Maybe<Tip_Keyword_Mutation_Response>;
  /** delete single row from the table: "tip_keyword" */
  delete_tip_keyword_by_pk?: Maybe<Tip_Keyword>;
  /** delete data from the table: "tip_musical_skill" */
  delete_tip_musical_skill?: Maybe<Tip_Musical_Skill_Mutation_Response>;
  /** delete single row from the table: "tip_musical_skill" */
  delete_tip_musical_skill_by_pk?: Maybe<Tip_Musical_Skill>;
  /** delete data from the table: "tip_non_musical_skill" */
  delete_tip_non_musical_skill?: Maybe<Tip_Non_Musical_Skill_Mutation_Response>;
  /** delete single row from the table: "tip_non_musical_skill" */
  delete_tip_non_musical_skill_by_pk?: Maybe<Tip_Non_Musical_Skill>;
  /** delete data from the table: "tip_playlist" */
  delete_tip_playlist?: Maybe<Tip_Playlist_Mutation_Response>;
  /** delete single row from the table: "tip_playlist" */
  delete_tip_playlist_by_pk?: Maybe<Tip_Playlist>;
  /** delete data from the table: "track" */
  delete_track?: Maybe<Track_Mutation_Response>;
  /** delete single row from the table: "track" */
  delete_track_by_pk?: Maybe<Track>;
  /** delete data from the table: "track_type" */
  delete_track_type?: Maybe<Track_Type_Mutation_Response>;
  /** delete single row from the table: "track_type" */
  delete_track_type_by_pk?: Maybe<Track_Type>;
  /** delete data from the table: "transpose_index" */
  delete_transpose_index?: Maybe<Transpose_Index_Mutation_Response>;
  /** delete single row from the table: "transpose_index" */
  delete_transpose_index_by_pk?: Maybe<Transpose_Index>;
  /** delete data from the table: "utils.most_attended_classes" */
  delete_utils_most_attended_classes?: Maybe<Utils_Most_Attended_Classes_Mutation_Response>;
  /** delete data from the table: "utils.most_attended_subjects" */
  delete_utils_most_attended_subjects?: Maybe<Utils_Most_Attended_Subjects_Mutation_Response>;
  /** delete data from the table: "utils.teacher_subscriptions_per_month" */
  delete_utils_teacher_subscriptions_per_month?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Mutation_Response>;
  /** delete data from the table: "utils.users_per_month" */
  delete_utils_users_per_month?: Maybe<Utils_Users_Per_Month_Mutation_Response>;
  /** delete data from the table: "visibility" */
  delete_visibility?: Maybe<Visibility_Mutation_Response>;
  /** delete single row from the table: "visibility" */
  delete_visibility_by_pk?: Maybe<Visibility>;
  /** delete data from the table: "wurrly" */
  delete_wurrly?: Maybe<Wurrly_Mutation_Response>;
  /** delete single row from the table: "wurrly" */
  delete_wurrly_by_pk?: Maybe<Wurrly>;
  /** delete data from the table: "wurrly_filter" */
  delete_wurrly_filter?: Maybe<Wurrly_Filter_Mutation_Response>;
  /** delete single row from the table: "wurrly_filter" */
  delete_wurrly_filter_by_pk?: Maybe<Wurrly_Filter>;
  /** delete data from the table: "wurrly_filter_sticker" */
  delete_wurrly_filter_sticker?: Maybe<Wurrly_Filter_Sticker_Mutation_Response>;
  /** delete data from the table: "wurrly_processing_status" */
  delete_wurrly_processing_status?: Maybe<Wurrly_Processing_Status_Mutation_Response>;
  /** delete single row from the table: "wurrly_processing_status" */
  delete_wurrly_processing_status_by_pk?: Maybe<Wurrly_Processing_Status>;
  /** delete data from the table: "wurrly_reaction" */
  delete_wurrly_reaction?: Maybe<Wurrly_Reaction_Mutation_Response>;
  /** delete single row from the table: "wurrly_reaction" */
  delete_wurrly_reaction_by_pk?: Maybe<Wurrly_Reaction>;
  /** delete data from the table: "wurrly_role" */
  delete_wurrly_role?: Maybe<Wurrly_Role_Mutation_Response>;
  /** delete single row from the table: "wurrly_role" */
  delete_wurrly_role_by_pk?: Maybe<Wurrly_Role>;
  /** delete data from the table: "wurrly_type" */
  delete_wurrly_type?: Maybe<Wurrly_Type_Mutation_Response>;
  /** delete single row from the table: "wurrly_type" */
  delete_wurrly_type_by_pk?: Maybe<Wurrly_Type>;
  districtAddOrUpdateChannelsAndPlaylists?: Maybe<Scalars['Boolean']>;
  districtAddOrUpdatePartnersAndModules?: Maybe<Scalars['Boolean']>;
  editTeacherInformation?: Maybe<Scalars['Boolean']>;
  insertContactUsWithMail?: Maybe<Scalars['Boolean']>;
  insertIntegrationStudentsToClass: InsertIntegrationStudentsToClassData;
  /** insert data into the table: "account_activation" */
  insert_account_activation?: Maybe<Account_Activation_Mutation_Response>;
  /** insert a single row into the table: "account_activation" */
  insert_account_activation_one?: Maybe<Account_Activation>;
  /** insert data into the table: "admin" */
  insert_admin?: Maybe<Admin_Mutation_Response>;
  /** insert a single row into the table: "admin" */
  insert_admin_one?: Maybe<Admin>;
  /** insert data into the table: "app" */
  insert_app?: Maybe<App_Mutation_Response>;
  /** insert a single row into the table: "app" */
  insert_app_one?: Maybe<App>;
  /** insert data into the table: "artist" */
  insert_artist?: Maybe<Artist_Mutation_Response>;
  /** insert a single row into the table: "artist" */
  insert_artist_one?: Maybe<Artist>;
  /** insert data into the table: "asset" */
  insert_asset?: Maybe<Asset_Mutation_Response>;
  /** insert a single row into the table: "asset" */
  insert_asset_one?: Maybe<Asset>;
  /** insert data into the table: "assignment" */
  insert_assignment?: Maybe<Assignment_Mutation_Response>;
  /** insert data into the table: "assignment_asset" */
  insert_assignment_asset?: Maybe<Assignment_Asset_Mutation_Response>;
  /** insert a single row into the table: "assignment_asset" */
  insert_assignment_asset_one?: Maybe<Assignment_Asset>;
  /** insert data into the table: "assignment_lesson_plan" */
  insert_assignment_lesson_plan?: Maybe<Assignment_Lesson_Plan_Mutation_Response>;
  /** insert a single row into the table: "assignment_lesson_plan" */
  insert_assignment_lesson_plan_one?: Maybe<Assignment_Lesson_Plan>;
  /** insert a single row into the table: "assignment" */
  insert_assignment_one?: Maybe<Assignment>;
  /** insert data into the table: "assignment_song" */
  insert_assignment_song?: Maybe<Assignment_Song_Mutation_Response>;
  /** insert a single row into the table: "assignment_song" */
  insert_assignment_song_one?: Maybe<Assignment_Song>;
  /** insert data into the table: "assignment_tip" */
  insert_assignment_tip?: Maybe<Assignment_Tip_Mutation_Response>;
  /** insert a single row into the table: "assignment_tip" */
  insert_assignment_tip_one?: Maybe<Assignment_Tip>;
  /** insert data into the table: "available" */
  insert_available?: Maybe<Available_Mutation_Response>;
  /** insert a single row into the table: "available" */
  insert_available_one?: Maybe<Available>;
  /** insert data into the table: "awarded_badge_event" */
  insert_awarded_badge_event?: Maybe<Awarded_Badge_Event_Mutation_Response>;
  /** insert a single row into the table: "awarded_badge_event" */
  insert_awarded_badge_event_one?: Maybe<Awarded_Badge_Event>;
  /** insert data into the table: "badge" */
  insert_badge?: Maybe<Badge_Mutation_Response>;
  /** insert data into the table: "badge_event" */
  insert_badge_event?: Maybe<Badge_Event_Mutation_Response>;
  /** insert data into the table: "badge_event_name" */
  insert_badge_event_name?: Maybe<Badge_Event_Name_Mutation_Response>;
  /** insert a single row into the table: "badge_event_name" */
  insert_badge_event_name_one?: Maybe<Badge_Event_Name>;
  /** insert a single row into the table: "badge_event" */
  insert_badge_event_one?: Maybe<Badge_Event>;
  /** insert a single row into the table: "badge" */
  insert_badge_one?: Maybe<Badge>;
  /** insert data into the table: "billing_history" */
  insert_billing_history?: Maybe<Billing_History_Mutation_Response>;
  /** insert a single row into the table: "billing_history" */
  insert_billing_history_one?: Maybe<Billing_History>;
  /** insert data into the table: "billing_method" */
  insert_billing_method?: Maybe<Billing_Method_Mutation_Response>;
  /** insert a single row into the table: "billing_method" */
  insert_billing_method_one?: Maybe<Billing_Method>;
  /** insert data into the table: "billing_status" */
  insert_billing_status?: Maybe<Billing_Status_Mutation_Response>;
  /** insert a single row into the table: "billing_status" */
  insert_billing_status_one?: Maybe<Billing_Status>;
  /** insert data into the table: "catalog" */
  insert_catalog?: Maybe<Catalog_Mutation_Response>;
  /** insert data into the table: "catalog_item" */
  insert_catalog_item?: Maybe<Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "catalog_item" */
  insert_catalog_item_one?: Maybe<Catalog_Item>;
  /** insert data into the table: "catalog_name" */
  insert_catalog_name?: Maybe<Catalog_Name_Mutation_Response>;
  /** insert a single row into the table: "catalog_name" */
  insert_catalog_name_one?: Maybe<Catalog_Name>;
  /** insert a single row into the table: "catalog" */
  insert_catalog_one?: Maybe<Catalog>;
  /** insert data into the table: "category_standard" */
  insert_category_standard?: Maybe<Category_Standard_Mutation_Response>;
  /** insert a single row into the table: "category_standard" */
  insert_category_standard_one?: Maybe<Category_Standard>;
  /** insert data into the table: "challenge" */
  insert_challenge?: Maybe<Challenge_Mutation_Response>;
  /** insert data into the table: "challenge_catalog_item" */
  insert_challenge_catalog_item?: Maybe<Challenge_Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "challenge_catalog_item" */
  insert_challenge_catalog_item_one?: Maybe<Challenge_Catalog_Item>;
  /** insert data into the table: "challenge_district" */
  insert_challenge_district?: Maybe<Challenge_District_Mutation_Response>;
  /** insert a single row into the table: "challenge_district" */
  insert_challenge_district_one?: Maybe<Challenge_District>;
  /** insert data into the table: "challenge_grade" */
  insert_challenge_grade?: Maybe<Challenge_Grade_Mutation_Response>;
  /** insert a single row into the table: "challenge_grade" */
  insert_challenge_grade_one?: Maybe<Challenge_Grade>;
  /** insert a single row into the table: "challenge" */
  insert_challenge_one?: Maybe<Challenge>;
  /** insert data into the table: "challenge_song" */
  insert_challenge_song?: Maybe<Challenge_Song_Mutation_Response>;
  /** insert a single row into the table: "challenge_song" */
  insert_challenge_song_one?: Maybe<Challenge_Song>;
  /** insert data into the table: "challenge_student" */
  insert_challenge_student?: Maybe<Challenge_Student_Mutation_Response>;
  /** insert a single row into the table: "challenge_student" */
  insert_challenge_student_one?: Maybe<Challenge_Student>;
  /** insert data into the table: "channel" */
  insert_channel?: Maybe<Channel_Mutation_Response>;
  /** insert a single row into the table: "channel" */
  insert_channel_one?: Maybe<Channel>;
  /** insert data into the table: "channel_playlist" */
  insert_channel_playlist?: Maybe<Channel_Playlist_Mutation_Response>;
  /** insert a single row into the table: "channel_playlist" */
  insert_channel_playlist_one?: Maybe<Channel_Playlist>;
  /** insert data into the table: "class" */
  insert_class?: Maybe<Class_Mutation_Response>;
  /** insert data into the table: "class_assignment" */
  insert_class_assignment?: Maybe<Class_Assignment_Mutation_Response>;
  /** insert a single row into the table: "class_assignment" */
  insert_class_assignment_one?: Maybe<Class_Assignment>;
  /** insert data into the table: "class_assignment_status" */
  insert_class_assignment_status?: Maybe<Class_Assignment_Status_Mutation_Response>;
  /** insert a single row into the table: "class_assignment_status" */
  insert_class_assignment_status_one?: Maybe<Class_Assignment_Status>;
  /** insert data into the table: "class_images" */
  insert_class_images?: Maybe<Class_Images_Mutation_Response>;
  /** insert a single row into the table: "class_images" */
  insert_class_images_one?: Maybe<Class_Images>;
  /** insert data into the table: "class_lesson_plan" */
  insert_class_lesson_plan?: Maybe<Class_Lesson_Plan_Mutation_Response>;
  /** insert a single row into the table: "class_lesson_plan" */
  insert_class_lesson_plan_one?: Maybe<Class_Lesson_Plan>;
  /** insert data into the table: "class_module" */
  insert_class_module?: Maybe<Class_Module_Mutation_Response>;
  /** insert a single row into the table: "class_module" */
  insert_class_module_one?: Maybe<Class_Module>;
  /** insert a single row into the table: "class" */
  insert_class_one?: Maybe<Class>;
  /** insert data into the table: "class_playlist" */
  insert_class_playlist?: Maybe<Class_Playlist_Mutation_Response>;
  /** insert a single row into the table: "class_playlist" */
  insert_class_playlist_one?: Maybe<Class_Playlist>;
  /** insert data into the table: "class_sequence" */
  insert_class_sequence?: Maybe<Class_Sequence_Mutation_Response>;
  /** insert a single row into the table: "class_sequence" */
  insert_class_sequence_one?: Maybe<Class_Sequence>;
  /** insert data into the table: "class_song" */
  insert_class_song?: Maybe<Class_Song_Mutation_Response>;
  /** insert a single row into the table: "class_song" */
  insert_class_song_one?: Maybe<Class_Song>;
  /** insert data into the table: "class_student" */
  insert_class_student?: Maybe<Class_Student_Mutation_Response>;
  /** insert data into the table: "class_student_activities" */
  insert_class_student_activities?: Maybe<Class_Student_Activities_Mutation_Response>;
  /** insert a single row into the table: "class_student_activities" */
  insert_class_student_activities_one?: Maybe<Class_Student_Activities>;
  /** insert a single row into the table: "class_student" */
  insert_class_student_one?: Maybe<Class_Student>;
  /** insert data into the table: "class_teacher" */
  insert_class_teacher?: Maybe<Class_Teacher_Mutation_Response>;
  /** insert a single row into the table: "class_teacher" */
  insert_class_teacher_one?: Maybe<Class_Teacher>;
  /** insert data into the table: "class_tip" */
  insert_class_tip?: Maybe<Class_Tip_Mutation_Response>;
  /** insert a single row into the table: "class_tip" */
  insert_class_tip_one?: Maybe<Class_Tip>;
  /** insert data into the table: "clever_roster_log" */
  insert_clever_roster_log?: Maybe<Clever_Roster_Log_Mutation_Response>;
  /** insert a single row into the table: "clever_roster_log" */
  insert_clever_roster_log_one?: Maybe<Clever_Roster_Log>;
  /** insert data into the table: "clever_roster_status" */
  insert_clever_roster_status?: Maybe<Clever_Roster_Status_Mutation_Response>;
  /** insert a single row into the table: "clever_roster_status" */
  insert_clever_roster_status_one?: Maybe<Clever_Roster_Status>;
  /** insert data into the table: "contact_us" */
  insert_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** insert data into the table: "contact_us_camp_teacher" */
  insert_contact_us_camp_teacher?: Maybe<Contact_Us_Camp_Teacher_Mutation_Response>;
  /** insert a single row into the table: "contact_us_camp_teacher" */
  insert_contact_us_camp_teacher_one?: Maybe<Contact_Us_Camp_Teacher>;
  /** insert a single row into the table: "contact_us" */
  insert_contact_us_one?: Maybe<Contact_Us>;
  /** insert data into the table: "crossclear.masterlist" */
  insert_crossclear_masterlist?: Maybe<Crossclear_Masterlist_Mutation_Response>;
  /** insert a single row into the table: "crossclear.masterlist" */
  insert_crossclear_masterlist_one?: Maybe<Crossclear_Masterlist>;
  /** insert data into the table: "discipline" */
  insert_discipline?: Maybe<Discipline_Mutation_Response>;
  /** insert a single row into the table: "discipline" */
  insert_discipline_one?: Maybe<Discipline>;
  /** insert data into the table: "district" */
  insert_district?: Maybe<District_Mutation_Response>;
  /** insert data into the table: "district_admin" */
  insert_district_admin?: Maybe<District_Admin_Mutation_Response>;
  /** insert a single row into the table: "district_admin" */
  insert_district_admin_one?: Maybe<District_Admin>;
  /** insert a single row into the table: "district" */
  insert_district_one?: Maybe<District>;
  /** insert data into the table: "filter_color" */
  insert_filter_color?: Maybe<Filter_Color_Mutation_Response>;
  /** insert data into the table: "filter_color_name" */
  insert_filter_color_name?: Maybe<Filter_Color_Name_Mutation_Response>;
  /** insert a single row into the table: "filter_color_name" */
  insert_filter_color_name_one?: Maybe<Filter_Color_Name>;
  /** insert a single row into the table: "filter_color" */
  insert_filter_color_one?: Maybe<Filter_Color>;
  /** insert data into the table: "filter_frame" */
  insert_filter_frame?: Maybe<Filter_Frame_Mutation_Response>;
  /** insert a single row into the table: "filter_frame" */
  insert_filter_frame_one?: Maybe<Filter_Frame>;
  /** insert data into the table: "filter_sticker" */
  insert_filter_sticker?: Maybe<Filter_Sticker_Mutation_Response>;
  /** insert a single row into the table: "filter_sticker" */
  insert_filter_sticker_one?: Maybe<Filter_Sticker>;
  /** insert data into the table: "grade" */
  insert_grade?: Maybe<Grade_Mutation_Response>;
  /** insert a single row into the table: "grade" */
  insert_grade_one?: Maybe<Grade>;
  /** insert data into the table: "grading_system" */
  insert_grading_system?: Maybe<Grading_System_Mutation_Response>;
  /** insert a single row into the table: "grading_system" */
  insert_grading_system_one?: Maybe<Grading_System>;
  /** insert data into the table: "grading_system_type" */
  insert_grading_system_type?: Maybe<Grading_System_Type_Mutation_Response>;
  /** insert a single row into the table: "grading_system_type" */
  insert_grading_system_type_one?: Maybe<Grading_System_Type>;
  /** insert data into the table: "integration_name" */
  insert_integration_name?: Maybe<Integration_Name_Mutation_Response>;
  /** insert a single row into the table: "integration_name" */
  insert_integration_name_one?: Maybe<Integration_Name>;
  /** insert data into the table: "invitation_status" */
  insert_invitation_status?: Maybe<Invitation_Status_Mutation_Response>;
  /** insert a single row into the table: "invitation_status" */
  insert_invitation_status_one?: Maybe<Invitation_Status>;
  /** insert data into the table: "keyword" */
  insert_keyword?: Maybe<Keyword_Mutation_Response>;
  /** insert a single row into the table: "keyword" */
  insert_keyword_one?: Maybe<Keyword>;
  /** insert data into the table: "label_control" */
  insert_label_control?: Maybe<Label_Control_Mutation_Response>;
  /** insert a single row into the table: "label_control" */
  insert_label_control_one?: Maybe<Label_Control>;
  /** insert data into the table: "labels" */
  insert_labels?: Maybe<Labels_Mutation_Response>;
  /** insert a single row into the table: "labels" */
  insert_labels_one?: Maybe<Labels>;
  /** insert data into the table: "lesson_plan" */
  insert_lesson_plan?: Maybe<Lesson_Plan_Mutation_Response>;
  /** insert data into the table: "lesson_plan_article" */
  insert_lesson_plan_article?: Maybe<Lesson_Plan_Article_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_article" */
  insert_lesson_plan_article_one?: Maybe<Lesson_Plan_Article>;
  /** insert data into the table: "lesson_plan_asset" */
  insert_lesson_plan_asset?: Maybe<Lesson_Plan_Asset_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_asset" */
  insert_lesson_plan_asset_one?: Maybe<Lesson_Plan_Asset>;
  /** insert data into the table: "lesson_plan_catalog_item" */
  insert_lesson_plan_catalog_item?: Maybe<Lesson_Plan_Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_catalog_item" */
  insert_lesson_plan_catalog_item_one?: Maybe<Lesson_Plan_Catalog_Item>;
  /** insert data into the table: "lesson_plan_discipline" */
  insert_lesson_plan_discipline?: Maybe<Lesson_Plan_Discipline_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_discipline" */
  insert_lesson_plan_discipline_one?: Maybe<Lesson_Plan_Discipline>;
  /** insert data into the table: "lesson_plan_grade" */
  insert_lesson_plan_grade?: Maybe<Lesson_Plan_Grade_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_grade" */
  insert_lesson_plan_grade_one?: Maybe<Lesson_Plan_Grade>;
  /** insert data into the table: "lesson_plan_keyword" */
  insert_lesson_plan_keyword?: Maybe<Lesson_Plan_Keyword_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_keyword" */
  insert_lesson_plan_keyword_one?: Maybe<Lesson_Plan_Keyword>;
  /** insert data into the table: "lesson_plan_musical_skill" */
  insert_lesson_plan_musical_skill?: Maybe<Lesson_Plan_Musical_Skill_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_musical_skill" */
  insert_lesson_plan_musical_skill_one?: Maybe<Lesson_Plan_Musical_Skill>;
  /** insert data into the table: "lesson_plan_non_musical_skill" */
  insert_lesson_plan_non_musical_skill?: Maybe<Lesson_Plan_Non_Musical_Skill_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_non_musical_skill" */
  insert_lesson_plan_non_musical_skill_one?: Maybe<Lesson_Plan_Non_Musical_Skill>;
  /** insert a single row into the table: "lesson_plan" */
  insert_lesson_plan_one?: Maybe<Lesson_Plan>;
  /** insert data into the table: "lesson_plan_skill_level" */
  insert_lesson_plan_skill_level?: Maybe<Lesson_Plan_Skill_Level_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_skill_level" */
  insert_lesson_plan_skill_level_one?: Maybe<Lesson_Plan_Skill_Level>;
  /** insert data into the table: "lesson_plan_song" */
  insert_lesson_plan_song?: Maybe<Lesson_Plan_Song_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_song" */
  insert_lesson_plan_song_one?: Maybe<Lesson_Plan_Song>;
  /** insert data into the table: "lesson_plan_standard" */
  insert_lesson_plan_standard?: Maybe<Lesson_Plan_Standard_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_standard" */
  insert_lesson_plan_standard_one?: Maybe<Lesson_Plan_Standard>;
  /** insert data into the table: "lesson_plan_tip" */
  insert_lesson_plan_tip?: Maybe<Lesson_Plan_Tip_Mutation_Response>;
  /** insert a single row into the table: "lesson_plan_tip" */
  insert_lesson_plan_tip_one?: Maybe<Lesson_Plan_Tip>;
  /** insert data into the table: "lockable_content" */
  insert_lockable_content?: Maybe<Lockable_Content_Mutation_Response>;
  /** insert data into the table: "lockable_content_class" */
  insert_lockable_content_class?: Maybe<Lockable_Content_Class_Mutation_Response>;
  /** insert a single row into the table: "lockable_content_class" */
  insert_lockable_content_class_one?: Maybe<Lockable_Content_Class>;
  /** insert data into the table: "lockable_content_district" */
  insert_lockable_content_district?: Maybe<Lockable_Content_District_Mutation_Response>;
  /** insert a single row into the table: "lockable_content_district" */
  insert_lockable_content_district_one?: Maybe<Lockable_Content_District>;
  /** insert a single row into the table: "lockable_content" */
  insert_lockable_content_one?: Maybe<Lockable_Content>;
  /** insert data into the table: "lockable_content_school" */
  insert_lockable_content_school?: Maybe<Lockable_Content_School_Mutation_Response>;
  /** insert a single row into the table: "lockable_content_school" */
  insert_lockable_content_school_one?: Maybe<Lockable_Content_School>;
  /** insert data into the table: "lockable_content_teacher" */
  insert_lockable_content_teacher?: Maybe<Lockable_Content_Teacher_Mutation_Response>;
  /** insert a single row into the table: "lockable_content_teacher" */
  insert_lockable_content_teacher_one?: Maybe<Lockable_Content_Teacher>;
  /** insert data into the table: "maturity_level" */
  insert_maturity_level?: Maybe<Maturity_Level_Mutation_Response>;
  /** insert a single row into the table: "maturity_level" */
  insert_maturity_level_one?: Maybe<Maturity_Level>;
  /** insert data into the table: "membership_type" */
  insert_membership_type?: Maybe<Membership_Type_Mutation_Response>;
  /** insert a single row into the table: "membership_type" */
  insert_membership_type_one?: Maybe<Membership_Type>;
  /** insert data into the table: "midi" */
  insert_midi?: Maybe<Midi_Mutation_Response>;
  /** insert a single row into the table: "midi" */
  insert_midi_one?: Maybe<Midi>;
  /** insert data into the table: "module" */
  insert_module?: Maybe<Module_Mutation_Response>;
  /** insert data into the table: "module_catalog_item" */
  insert_module_catalog_item?: Maybe<Module_Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "module_catalog_item" */
  insert_module_catalog_item_one?: Maybe<Module_Catalog_Item>;
  /** insert data into the table: "module_grade" */
  insert_module_grade?: Maybe<Module_Grade_Mutation_Response>;
  /** insert a single row into the table: "module_grade" */
  insert_module_grade_one?: Maybe<Module_Grade>;
  /** insert data into the table: "module_lesson_plan" */
  insert_module_lesson_plan?: Maybe<Module_Lesson_Plan_Mutation_Response>;
  /** insert a single row into the table: "module_lesson_plan" */
  insert_module_lesson_plan_one?: Maybe<Module_Lesson_Plan>;
  /** insert a single row into the table: "module" */
  insert_module_one?: Maybe<Module>;
  /** insert data into the table: "musical_skill" */
  insert_musical_skill?: Maybe<Musical_Skill_Mutation_Response>;
  /** insert a single row into the table: "musical_skill" */
  insert_musical_skill_one?: Maybe<Musical_Skill>;
  /** insert data into the table: "non_musical_skill" */
  insert_non_musical_skill?: Maybe<Non_Musical_Skill_Mutation_Response>;
  /** insert a single row into the table: "non_musical_skill" */
  insert_non_musical_skill_one?: Maybe<Non_Musical_Skill>;
  /** insert data into the table: "non_musical_skill_type" */
  insert_non_musical_skill_type?: Maybe<Non_Musical_Skill_Type_Mutation_Response>;
  /** insert a single row into the table: "non_musical_skill_type" */
  insert_non_musical_skill_type_one?: Maybe<Non_Musical_Skill_Type>;
  /** insert data into the table: "order" */
  insert_order?: Maybe<Order_Mutation_Response>;
  /** insert a single row into the table: "order" */
  insert_order_one?: Maybe<Order>;
  /** insert data into the table: "order_status" */
  insert_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** insert a single row into the table: "order_status" */
  insert_order_status_one?: Maybe<Order_Status>;
  /** insert data into the table: "parent_invitation" */
  insert_parent_invitation?: Maybe<Parent_Invitation_Mutation_Response>;
  /** insert a single row into the table: "parent_invitation" */
  insert_parent_invitation_one?: Maybe<Parent_Invitation>;
  /** insert data into the table: "partner" */
  insert_partner?: Maybe<Partner_Mutation_Response>;
  /** insert data into the table: "partner_module" */
  insert_partner_module?: Maybe<Partner_Module_Mutation_Response>;
  /** insert a single row into the table: "partner_module" */
  insert_partner_module_one?: Maybe<Partner_Module>;
  /** insert a single row into the table: "partner" */
  insert_partner_one?: Maybe<Partner>;
  /** insert data into the table: "partner_playlist" */
  insert_partner_playlist?: Maybe<Partner_Playlist_Mutation_Response>;
  /** insert a single row into the table: "partner_playlist" */
  insert_partner_playlist_one?: Maybe<Partner_Playlist>;
  /** insert data into the table: "playlist" */
  insert_playlist?: Maybe<Playlist_Mutation_Response>;
  /** insert a single row into the table: "playlist" */
  insert_playlist_one?: Maybe<Playlist>;
  /** insert data into the table: "price_type" */
  insert_price_type?: Maybe<Price_Type_Mutation_Response>;
  /** insert a single row into the table: "price_type" */
  insert_price_type_one?: Maybe<Price_Type>;
  /** insert data into the table: "privacy" */
  insert_privacy?: Maybe<Privacy_Mutation_Response>;
  /** insert a single row into the table: "privacy" */
  insert_privacy_one?: Maybe<Privacy>;
  /** insert data into the table: "product_name" */
  insert_product_name?: Maybe<Product_Name_Mutation_Response>;
  /** insert a single row into the table: "product_name" */
  insert_product_name_one?: Maybe<Product_Name>;
  /** insert data into the table: "publisher" */
  insert_publisher?: Maybe<Publisher_Mutation_Response>;
  /** insert data into the table: "publisher_control" */
  insert_publisher_control?: Maybe<Publisher_Control_Mutation_Response>;
  /** insert a single row into the table: "publisher_control" */
  insert_publisher_control_one?: Maybe<Publisher_Control>;
  /** insert a single row into the table: "publisher" */
  insert_publisher_one?: Maybe<Publisher>;
  /** insert data into the table: "reaction" */
  insert_reaction?: Maybe<Reaction_Mutation_Response>;
  /** insert a single row into the table: "reaction" */
  insert_reaction_one?: Maybe<Reaction>;
  /** insert data into the table: "reset_password" */
  insert_reset_password?: Maybe<Reset_Password_Mutation_Response>;
  /** insert a single row into the table: "reset_password" */
  insert_reset_password_one?: Maybe<Reset_Password>;
  /** insert data into the table: "role_name" */
  insert_role_name?: Maybe<Role_Name_Mutation_Response>;
  /** insert a single row into the table: "role_name" */
  insert_role_name_one?: Maybe<Role_Name>;
  /** insert data into the table: "royalty_log" */
  insert_royalty_log?: Maybe<Royalty_Log_Mutation_Response>;
  /** insert a single row into the table: "royalty_log" */
  insert_royalty_log_one?: Maybe<Royalty_Log>;
  /** insert data into the table: "scale" */
  insert_scale?: Maybe<Scale_Mutation_Response>;
  /** insert a single row into the table: "scale" */
  insert_scale_one?: Maybe<Scale>;
  /** insert data into the table: "school" */
  insert_school?: Maybe<School_Mutation_Response>;
  /** insert data into the table: "school_admin" */
  insert_school_admin?: Maybe<School_Admin_Mutation_Response>;
  /** insert a single row into the table: "school_admin" */
  insert_school_admin_one?: Maybe<School_Admin>;
  /** insert a single row into the table: "school" */
  insert_school_one?: Maybe<School>;
  /** insert data into the table: "school_teacher" */
  insert_school_teacher?: Maybe<School_Teacher_Mutation_Response>;
  /** insert a single row into the table: "school_teacher" */
  insert_school_teacher_one?: Maybe<School_Teacher>;
  /** insert data into the table: "screen_type" */
  insert_screen_type?: Maybe<Screen_Type_Mutation_Response>;
  /** insert a single row into the table: "screen_type" */
  insert_screen_type_one?: Maybe<Screen_Type>;
  /** insert data into the table: "sequence" */
  insert_sequence?: Maybe<Sequence_Mutation_Response>;
  /** insert data into the table: "sequence_keyword" */
  insert_sequence_keyword?: Maybe<Sequence_Keyword_Mutation_Response>;
  /** insert a single row into the table: "sequence_keyword" */
  insert_sequence_keyword_one?: Maybe<Sequence_Keyword>;
  /** insert data into the table: "sequence_lesson_plan" */
  insert_sequence_lesson_plan?: Maybe<Sequence_Lesson_Plan_Mutation_Response>;
  /** insert a single row into the table: "sequence_lesson_plan" */
  insert_sequence_lesson_plan_one?: Maybe<Sequence_Lesson_Plan>;
  /** insert a single row into the table: "sequence" */
  insert_sequence_one?: Maybe<Sequence>;
  /** insert data into the table: "sequence_topic" */
  insert_sequence_topic?: Maybe<Sequence_Topic_Mutation_Response>;
  /** insert data into the table: "sequence_topic_catalog_item" */
  insert_sequence_topic_catalog_item?: Maybe<Sequence_Topic_Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "sequence_topic_catalog_item" */
  insert_sequence_topic_catalog_item_one?: Maybe<Sequence_Topic_Catalog_Item>;
  /** insert data into the table: "sequence_topic_keyword" */
  insert_sequence_topic_keyword?: Maybe<Sequence_Topic_Keyword_Mutation_Response>;
  /** insert a single row into the table: "sequence_topic_keyword" */
  insert_sequence_topic_keyword_one?: Maybe<Sequence_Topic_Keyword>;
  /** insert a single row into the table: "sequence_topic" */
  insert_sequence_topic_one?: Maybe<Sequence_Topic>;
  /** insert data into the table: "sequence_topic_sequence" */
  insert_sequence_topic_sequence?: Maybe<Sequence_Topic_Sequence_Mutation_Response>;
  /** insert a single row into the table: "sequence_topic_sequence" */
  insert_sequence_topic_sequence_one?: Maybe<Sequence_Topic_Sequence>;
  /** insert data into the table: "session" */
  insert_session?: Maybe<Session_Mutation_Response>;
  /** insert a single row into the table: "session" */
  insert_session_one?: Maybe<Session>;
  /** insert data into the table: "skill_level" */
  insert_skill_level?: Maybe<Skill_Level_Mutation_Response>;
  /** insert a single row into the table: "skill_level" */
  insert_skill_level_one?: Maybe<Skill_Level>;
  /** insert data into the table: "song" */
  insert_song?: Maybe<Song_Mutation_Response>;
  /** insert data into the table: "song_catalog_item" */
  insert_song_catalog_item?: Maybe<Song_Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "song_catalog_item" */
  insert_song_catalog_item_one?: Maybe<Song_Catalog_Item>;
  /** insert data into the table: "song_grade" */
  insert_song_grade?: Maybe<Song_Grade_Mutation_Response>;
  /** insert a single row into the table: "song_grade" */
  insert_song_grade_one?: Maybe<Song_Grade>;
  /** insert data into the table: "song_keyword" */
  insert_song_keyword?: Maybe<Song_Keyword_Mutation_Response>;
  /** insert a single row into the table: "song_keyword" */
  insert_song_keyword_one?: Maybe<Song_Keyword>;
  /** insert a single row into the table: "song" */
  insert_song_one?: Maybe<Song>;
  /** insert data into the table: "standard" */
  insert_standard?: Maybe<Standard_Mutation_Response>;
  /** insert a single row into the table: "standard" */
  insert_standard_one?: Maybe<Standard>;
  /** insert data into the table: "standard_option" */
  insert_standard_option?: Maybe<Standard_Option_Mutation_Response>;
  /** insert a single row into the table: "standard_option" */
  insert_standard_option_one?: Maybe<Standard_Option>;
  /** insert data into the table: "standard_subcategory" */
  insert_standard_subcategory?: Maybe<Standard_Subcategory_Mutation_Response>;
  /** insert a single row into the table: "standard_subcategory" */
  insert_standard_subcategory_one?: Maybe<Standard_Subcategory>;
  /** insert data into the table: "student" */
  insert_student?: Maybe<Student_Mutation_Response>;
  /** insert data into the table: "student_activity" */
  insert_student_activity?: Maybe<Student_Activity_Mutation_Response>;
  /** insert a single row into the table: "student_activity" */
  insert_student_activity_one?: Maybe<Student_Activity>;
  /** insert data into the table: "student_activity_type" */
  insert_student_activity_type?: Maybe<Student_Activity_Type_Mutation_Response>;
  /** insert a single row into the table: "student_activity_type" */
  insert_student_activity_type_one?: Maybe<Student_Activity_Type>;
  /** insert data into the table: "student_badge" */
  insert_student_badge?: Maybe<Student_Badge_Mutation_Response>;
  /** insert data into the table: "student_badge_event" */
  insert_student_badge_event?: Maybe<Student_Badge_Event_Mutation_Response>;
  /** insert a single row into the table: "student_badge_event" */
  insert_student_badge_event_one?: Maybe<Student_Badge_Event>;
  /** insert a single row into the table: "student_badge" */
  insert_student_badge_one?: Maybe<Student_Badge>;
  /** insert data into the table: "student_banner" */
  insert_student_banner?: Maybe<Student_Banner_Mutation_Response>;
  /** insert a single row into the table: "student_banner" */
  insert_student_banner_one?: Maybe<Student_Banner>;
  /** insert data into the table: "student_goal" */
  insert_student_goal?: Maybe<Student_Goal_Mutation_Response>;
  /** insert a single row into the table: "student_goal" */
  insert_student_goal_one?: Maybe<Student_Goal>;
  /** insert data into the table: "student_integration" */
  insert_student_integration?: Maybe<Student_Integration_Mutation_Response>;
  /** insert a single row into the table: "student_integration" */
  insert_student_integration_one?: Maybe<Student_Integration>;
  /** insert data into the table: "student_lesson_plan_favorite" */
  insert_student_lesson_plan_favorite?: Maybe<Student_Lesson_Plan_Favorite_Mutation_Response>;
  /** insert a single row into the table: "student_lesson_plan_favorite" */
  insert_student_lesson_plan_favorite_one?: Maybe<Student_Lesson_Plan_Favorite>;
  /** insert a single row into the table: "student" */
  insert_student_one?: Maybe<Student>;
  /** insert data into the table: "student_productivity_daily" */
  insert_student_productivity_daily?: Maybe<Student_Productivity_Daily_Mutation_Response>;
  /** insert a single row into the table: "student_productivity_daily" */
  insert_student_productivity_daily_one?: Maybe<Student_Productivity_Daily>;
  /** insert data into the table: "student_productivity_monthly" */
  insert_student_productivity_monthly?: Maybe<Student_Productivity_Monthly_Mutation_Response>;
  /** insert a single row into the table: "student_productivity_monthly" */
  insert_student_productivity_monthly_one?: Maybe<Student_Productivity_Monthly>;
  /** insert data into the table: "student_productivity_yearly" */
  insert_student_productivity_yearly?: Maybe<Student_Productivity_Yearly_Mutation_Response>;
  /** insert a single row into the table: "student_productivity_yearly" */
  insert_student_productivity_yearly_one?: Maybe<Student_Productivity_Yearly>;
  /** insert data into the table: "student_setting" */
  insert_student_setting?: Maybe<Student_Setting_Mutation_Response>;
  /** insert a single row into the table: "student_setting" */
  insert_student_setting_one?: Maybe<Student_Setting>;
  /** insert data into the table: "student_song_favorite" */
  insert_student_song_favorite?: Maybe<Student_Song_Favorite_Mutation_Response>;
  /** insert a single row into the table: "student_song_favorite" */
  insert_student_song_favorite_one?: Maybe<Student_Song_Favorite>;
  /** insert data into the table: "student_tip_favorite" */
  insert_student_tip_favorite?: Maybe<Student_Tip_Favorite_Mutation_Response>;
  /** insert a single row into the table: "student_tip_favorite" */
  insert_student_tip_favorite_one?: Maybe<Student_Tip_Favorite>;
  /** insert data into the table: "submission" */
  insert_submission?: Maybe<Submission_Mutation_Response>;
  /** insert data into the table: "submission_asset" */
  insert_submission_asset?: Maybe<Submission_Asset_Mutation_Response>;
  /** insert a single row into the table: "submission_asset" */
  insert_submission_asset_one?: Maybe<Submission_Asset>;
  /** insert a single row into the table: "submission" */
  insert_submission_one?: Maybe<Submission>;
  /** insert data into the table: "submission_state" */
  insert_submission_state?: Maybe<Submission_State_Mutation_Response>;
  /** insert a single row into the table: "submission_state" */
  insert_submission_state_one?: Maybe<Submission_State>;
  /** insert data into the table: "submission_student" */
  insert_submission_student?: Maybe<Submission_Student_Mutation_Response>;
  /** insert a single row into the table: "submission_student" */
  insert_submission_student_one?: Maybe<Submission_Student>;
  /** insert data into the table: "submission_wurrly" */
  insert_submission_wurrly?: Maybe<Submission_Wurrly_Mutation_Response>;
  /** insert a single row into the table: "submission_wurrly" */
  insert_submission_wurrly_one?: Maybe<Submission_Wurrly>;
  /** insert data into the table: "submission_wurrly_state" */
  insert_submission_wurrly_state?: Maybe<Submission_Wurrly_State_Mutation_Response>;
  /** insert a single row into the table: "submission_wurrly_state" */
  insert_submission_wurrly_state_one?: Maybe<Submission_Wurrly_State>;
  /** insert data into the table: "teacher" */
  insert_teacher?: Maybe<Teacher_Mutation_Response>;
  /** insert data into the table: "teacher_integration" */
  insert_teacher_integration?: Maybe<Teacher_Integration_Mutation_Response>;
  /** insert a single row into the table: "teacher_integration" */
  insert_teacher_integration_one?: Maybe<Teacher_Integration>;
  /** insert data into the table: "teacher_lesson_plan_favorite" */
  insert_teacher_lesson_plan_favorite?: Maybe<Teacher_Lesson_Plan_Favorite_Mutation_Response>;
  /** insert a single row into the table: "teacher_lesson_plan_favorite" */
  insert_teacher_lesson_plan_favorite_one?: Maybe<Teacher_Lesson_Plan_Favorite>;
  /** insert a single row into the table: "teacher" */
  insert_teacher_one?: Maybe<Teacher>;
  /** insert data into the table: "teacher_setting" */
  insert_teacher_setting?: Maybe<Teacher_Setting_Mutation_Response>;
  /** insert a single row into the table: "teacher_setting" */
  insert_teacher_setting_one?: Maybe<Teacher_Setting>;
  /** insert data into the table: "teacher_song_favorite" */
  insert_teacher_song_favorite?: Maybe<Teacher_Song_Favorite_Mutation_Response>;
  /** insert a single row into the table: "teacher_song_favorite" */
  insert_teacher_song_favorite_one?: Maybe<Teacher_Song_Favorite>;
  /** insert data into the table: "teacher_subscription" */
  insert_teacher_subscription?: Maybe<Teacher_Subscription_Mutation_Response>;
  /** insert a single row into the table: "teacher_subscription" */
  insert_teacher_subscription_one?: Maybe<Teacher_Subscription>;
  /** insert data into the table: "teacher_tip_favorite" */
  insert_teacher_tip_favorite?: Maybe<Teacher_Tip_Favorite_Mutation_Response>;
  /** insert a single row into the table: "teacher_tip_favorite" */
  insert_teacher_tip_favorite_one?: Maybe<Teacher_Tip_Favorite>;
  /** insert data into the table: "teacher_wurrly_comment" */
  insert_teacher_wurrly_comment?: Maybe<Teacher_Wurrly_Comment_Mutation_Response>;
  /** insert a single row into the table: "teacher_wurrly_comment" */
  insert_teacher_wurrly_comment_one?: Maybe<Teacher_Wurrly_Comment>;
  /** insert data into the table: "tip" */
  insert_tip?: Maybe<Tip_Mutation_Response>;
  /** insert data into the table: "tip_age" */
  insert_tip_age?: Maybe<Tip_Age_Mutation_Response>;
  /** insert a single row into the table: "tip_age" */
  insert_tip_age_one?: Maybe<Tip_Age>;
  /** insert data into the table: "tip_catalog_item" */
  insert_tip_catalog_item?: Maybe<Tip_Catalog_Item_Mutation_Response>;
  /** insert a single row into the table: "tip_catalog_item" */
  insert_tip_catalog_item_one?: Maybe<Tip_Catalog_Item>;
  /** insert data into the table: "tip_grade" */
  insert_tip_grade?: Maybe<Tip_Grade_Mutation_Response>;
  /** insert a single row into the table: "tip_grade" */
  insert_tip_grade_one?: Maybe<Tip_Grade>;
  /** insert data into the table: "tip_keyword" */
  insert_tip_keyword?: Maybe<Tip_Keyword_Mutation_Response>;
  /** insert a single row into the table: "tip_keyword" */
  insert_tip_keyword_one?: Maybe<Tip_Keyword>;
  /** insert data into the table: "tip_musical_skill" */
  insert_tip_musical_skill?: Maybe<Tip_Musical_Skill_Mutation_Response>;
  /** insert a single row into the table: "tip_musical_skill" */
  insert_tip_musical_skill_one?: Maybe<Tip_Musical_Skill>;
  /** insert data into the table: "tip_non_musical_skill" */
  insert_tip_non_musical_skill?: Maybe<Tip_Non_Musical_Skill_Mutation_Response>;
  /** insert a single row into the table: "tip_non_musical_skill" */
  insert_tip_non_musical_skill_one?: Maybe<Tip_Non_Musical_Skill>;
  /** insert a single row into the table: "tip" */
  insert_tip_one?: Maybe<Tip>;
  /** insert data into the table: "tip_playlist" */
  insert_tip_playlist?: Maybe<Tip_Playlist_Mutation_Response>;
  /** insert a single row into the table: "tip_playlist" */
  insert_tip_playlist_one?: Maybe<Tip_Playlist>;
  /** insert data into the table: "track" */
  insert_track?: Maybe<Track_Mutation_Response>;
  /** insert a single row into the table: "track" */
  insert_track_one?: Maybe<Track>;
  /** insert data into the table: "track_type" */
  insert_track_type?: Maybe<Track_Type_Mutation_Response>;
  /** insert a single row into the table: "track_type" */
  insert_track_type_one?: Maybe<Track_Type>;
  /** insert data into the table: "transpose_index" */
  insert_transpose_index?: Maybe<Transpose_Index_Mutation_Response>;
  /** insert a single row into the table: "transpose_index" */
  insert_transpose_index_one?: Maybe<Transpose_Index>;
  /** insert data into the table: "utils.most_attended_classes" */
  insert_utils_most_attended_classes?: Maybe<Utils_Most_Attended_Classes_Mutation_Response>;
  /** insert a single row into the table: "utils.most_attended_classes" */
  insert_utils_most_attended_classes_one?: Maybe<Utils_Most_Attended_Classes>;
  /** insert data into the table: "utils.most_attended_subjects" */
  insert_utils_most_attended_subjects?: Maybe<Utils_Most_Attended_Subjects_Mutation_Response>;
  /** insert a single row into the table: "utils.most_attended_subjects" */
  insert_utils_most_attended_subjects_one?: Maybe<Utils_Most_Attended_Subjects>;
  /** insert data into the table: "utils.teacher_subscriptions_per_month" */
  insert_utils_teacher_subscriptions_per_month?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Mutation_Response>;
  /** insert a single row into the table: "utils.teacher_subscriptions_per_month" */
  insert_utils_teacher_subscriptions_per_month_one?: Maybe<Utils_Teacher_Subscriptions_Per_Month>;
  /** insert data into the table: "utils.users_per_month" */
  insert_utils_users_per_month?: Maybe<Utils_Users_Per_Month_Mutation_Response>;
  /** insert a single row into the table: "utils.users_per_month" */
  insert_utils_users_per_month_one?: Maybe<Utils_Users_Per_Month>;
  /** insert data into the table: "visibility" */
  insert_visibility?: Maybe<Visibility_Mutation_Response>;
  /** insert a single row into the table: "visibility" */
  insert_visibility_one?: Maybe<Visibility>;
  /** insert data into the table: "wurrly" */
  insert_wurrly?: Maybe<Wurrly_Mutation_Response>;
  /** insert data into the table: "wurrly_filter" */
  insert_wurrly_filter?: Maybe<Wurrly_Filter_Mutation_Response>;
  /** insert a single row into the table: "wurrly_filter" */
  insert_wurrly_filter_one?: Maybe<Wurrly_Filter>;
  /** insert data into the table: "wurrly_filter_sticker" */
  insert_wurrly_filter_sticker?: Maybe<Wurrly_Filter_Sticker_Mutation_Response>;
  /** insert a single row into the table: "wurrly_filter_sticker" */
  insert_wurrly_filter_sticker_one?: Maybe<Wurrly_Filter_Sticker>;
  /** insert a single row into the table: "wurrly" */
  insert_wurrly_one?: Maybe<Wurrly>;
  /** insert data into the table: "wurrly_processing_status" */
  insert_wurrly_processing_status?: Maybe<Wurrly_Processing_Status_Mutation_Response>;
  /** insert a single row into the table: "wurrly_processing_status" */
  insert_wurrly_processing_status_one?: Maybe<Wurrly_Processing_Status>;
  /** insert data into the table: "wurrly_reaction" */
  insert_wurrly_reaction?: Maybe<Wurrly_Reaction_Mutation_Response>;
  /** insert a single row into the table: "wurrly_reaction" */
  insert_wurrly_reaction_one?: Maybe<Wurrly_Reaction>;
  /** insert data into the table: "wurrly_role" */
  insert_wurrly_role?: Maybe<Wurrly_Role_Mutation_Response>;
  /** insert a single row into the table: "wurrly_role" */
  insert_wurrly_role_one?: Maybe<Wurrly_Role>;
  /** insert data into the table: "wurrly_type" */
  insert_wurrly_type?: Maybe<Wurrly_Type_Mutation_Response>;
  /** insert a single row into the table: "wurrly_type" */
  insert_wurrly_type_one?: Maybe<Wurrly_Type>;
  inviteAdmin?: Maybe<InviteAdminData>;
  inviteTeacher?: Maybe<InviteTeacherData>;
  midiToJson?: Maybe<MidiToJsonData>;
  purgeS3Temp?: Maybe<LessonPlanAttributes>;
  resetPassword?: Maybe<ResetPasswordData>;
  roastCleverClasses?: Maybe<RoastCleverClassesData>;
  rosterCanvasClasses: RosterCanvasClassesData;
  rosterClassLinkClasses: RosterClassLinkClassesData;
  schoolAddOrUpdateChannelsAndPlaylists?: Maybe<Scalars['Boolean']>;
  schoolAddOrUpdatePartnersAndModules?: Maybe<Scalars['Boolean']>;
  sendInvitation?: Maybe<SendInvitationData>;
  sendMail?: Maybe<SendMailResult>;
  sendMultipleMail?: Maybe<SendMailResult>;
  studentActivation?: Maybe<StudentActivationResponse>;
  syncPrices?: Maybe<Scalars['Boolean']>;
  teacherSignup?: Maybe<TeacherSignupData>;
  teacherSignupIntegration?: Maybe<SignupTeacherIntegrationData>;
  /** update data of the table: "account_activation" */
  update_account_activation?: Maybe<Account_Activation_Mutation_Response>;
  /** update single row of the table: "account_activation" */
  update_account_activation_by_pk?: Maybe<Account_Activation>;
  /** update data of the table: "admin" */
  update_admin?: Maybe<Admin_Mutation_Response>;
  /** update single row of the table: "admin" */
  update_admin_by_pk?: Maybe<Admin>;
  /** update data of the table: "app" */
  update_app?: Maybe<App_Mutation_Response>;
  /** update single row of the table: "app" */
  update_app_by_pk?: Maybe<App>;
  /** update data of the table: "artist" */
  update_artist?: Maybe<Artist_Mutation_Response>;
  /** update single row of the table: "artist" */
  update_artist_by_pk?: Maybe<Artist>;
  /** update data of the table: "asset" */
  update_asset?: Maybe<Asset_Mutation_Response>;
  /** update single row of the table: "asset" */
  update_asset_by_pk?: Maybe<Asset>;
  /** update data of the table: "assignment" */
  update_assignment?: Maybe<Assignment_Mutation_Response>;
  /** update data of the table: "assignment_asset" */
  update_assignment_asset?: Maybe<Assignment_Asset_Mutation_Response>;
  /** update single row of the table: "assignment_asset" */
  update_assignment_asset_by_pk?: Maybe<Assignment_Asset>;
  /** update single row of the table: "assignment" */
  update_assignment_by_pk?: Maybe<Assignment>;
  /** update data of the table: "assignment_lesson_plan" */
  update_assignment_lesson_plan?: Maybe<Assignment_Lesson_Plan_Mutation_Response>;
  /** update single row of the table: "assignment_lesson_plan" */
  update_assignment_lesson_plan_by_pk?: Maybe<Assignment_Lesson_Plan>;
  /** update data of the table: "assignment_song" */
  update_assignment_song?: Maybe<Assignment_Song_Mutation_Response>;
  /** update single row of the table: "assignment_song" */
  update_assignment_song_by_pk?: Maybe<Assignment_Song>;
  /** update data of the table: "assignment_tip" */
  update_assignment_tip?: Maybe<Assignment_Tip_Mutation_Response>;
  /** update single row of the table: "assignment_tip" */
  update_assignment_tip_by_pk?: Maybe<Assignment_Tip>;
  /** update data of the table: "available" */
  update_available?: Maybe<Available_Mutation_Response>;
  /** update single row of the table: "available" */
  update_available_by_pk?: Maybe<Available>;
  /** update data of the table: "awarded_badge_event" */
  update_awarded_badge_event?: Maybe<Awarded_Badge_Event_Mutation_Response>;
  /** update single row of the table: "awarded_badge_event" */
  update_awarded_badge_event_by_pk?: Maybe<Awarded_Badge_Event>;
  /** update data of the table: "badge" */
  update_badge?: Maybe<Badge_Mutation_Response>;
  /** update single row of the table: "badge" */
  update_badge_by_pk?: Maybe<Badge>;
  /** update data of the table: "badge_event" */
  update_badge_event?: Maybe<Badge_Event_Mutation_Response>;
  /** update single row of the table: "badge_event" */
  update_badge_event_by_pk?: Maybe<Badge_Event>;
  /** update data of the table: "badge_event_name" */
  update_badge_event_name?: Maybe<Badge_Event_Name_Mutation_Response>;
  /** update single row of the table: "badge_event_name" */
  update_badge_event_name_by_pk?: Maybe<Badge_Event_Name>;
  /** update data of the table: "billing_history" */
  update_billing_history?: Maybe<Billing_History_Mutation_Response>;
  /** update single row of the table: "billing_history" */
  update_billing_history_by_pk?: Maybe<Billing_History>;
  /** update data of the table: "billing_method" */
  update_billing_method?: Maybe<Billing_Method_Mutation_Response>;
  /** update single row of the table: "billing_method" */
  update_billing_method_by_pk?: Maybe<Billing_Method>;
  /** update data of the table: "billing_status" */
  update_billing_status?: Maybe<Billing_Status_Mutation_Response>;
  /** update single row of the table: "billing_status" */
  update_billing_status_by_pk?: Maybe<Billing_Status>;
  /** update data of the table: "catalog" */
  update_catalog?: Maybe<Catalog_Mutation_Response>;
  /** update single row of the table: "catalog" */
  update_catalog_by_pk?: Maybe<Catalog>;
  /** update data of the table: "catalog_item" */
  update_catalog_item?: Maybe<Catalog_Item_Mutation_Response>;
  /** update single row of the table: "catalog_item" */
  update_catalog_item_by_pk?: Maybe<Catalog_Item>;
  /** update data of the table: "catalog_name" */
  update_catalog_name?: Maybe<Catalog_Name_Mutation_Response>;
  /** update single row of the table: "catalog_name" */
  update_catalog_name_by_pk?: Maybe<Catalog_Name>;
  /** update data of the table: "category_standard" */
  update_category_standard?: Maybe<Category_Standard_Mutation_Response>;
  /** update single row of the table: "category_standard" */
  update_category_standard_by_pk?: Maybe<Category_Standard>;
  /** update data of the table: "challenge" */
  update_challenge?: Maybe<Challenge_Mutation_Response>;
  /** update single row of the table: "challenge" */
  update_challenge_by_pk?: Maybe<Challenge>;
  /** update data of the table: "challenge_catalog_item" */
  update_challenge_catalog_item?: Maybe<Challenge_Catalog_Item_Mutation_Response>;
  /** update single row of the table: "challenge_catalog_item" */
  update_challenge_catalog_item_by_pk?: Maybe<Challenge_Catalog_Item>;
  /** update data of the table: "challenge_district" */
  update_challenge_district?: Maybe<Challenge_District_Mutation_Response>;
  /** update single row of the table: "challenge_district" */
  update_challenge_district_by_pk?: Maybe<Challenge_District>;
  /** update data of the table: "challenge_grade" */
  update_challenge_grade?: Maybe<Challenge_Grade_Mutation_Response>;
  /** update single row of the table: "challenge_grade" */
  update_challenge_grade_by_pk?: Maybe<Challenge_Grade>;
  /** update data of the table: "challenge_song" */
  update_challenge_song?: Maybe<Challenge_Song_Mutation_Response>;
  /** update single row of the table: "challenge_song" */
  update_challenge_song_by_pk?: Maybe<Challenge_Song>;
  /** update data of the table: "challenge_student" */
  update_challenge_student?: Maybe<Challenge_Student_Mutation_Response>;
  /** update single row of the table: "challenge_student" */
  update_challenge_student_by_pk?: Maybe<Challenge_Student>;
  /** update data of the table: "channel" */
  update_channel?: Maybe<Channel_Mutation_Response>;
  /** update single row of the table: "channel" */
  update_channel_by_pk?: Maybe<Channel>;
  /** update data of the table: "channel_playlist" */
  update_channel_playlist?: Maybe<Channel_Playlist_Mutation_Response>;
  /** update single row of the table: "channel_playlist" */
  update_channel_playlist_by_pk?: Maybe<Channel_Playlist>;
  /** update data of the table: "class" */
  update_class?: Maybe<Class_Mutation_Response>;
  /** update data of the table: "class_assignment" */
  update_class_assignment?: Maybe<Class_Assignment_Mutation_Response>;
  /** update single row of the table: "class_assignment" */
  update_class_assignment_by_pk?: Maybe<Class_Assignment>;
  /** update data of the table: "class_assignment_status" */
  update_class_assignment_status?: Maybe<Class_Assignment_Status_Mutation_Response>;
  /** update single row of the table: "class_assignment_status" */
  update_class_assignment_status_by_pk?: Maybe<Class_Assignment_Status>;
  /** update single row of the table: "class" */
  update_class_by_pk?: Maybe<Class>;
  /** update data of the table: "class_images" */
  update_class_images?: Maybe<Class_Images_Mutation_Response>;
  /** update single row of the table: "class_images" */
  update_class_images_by_pk?: Maybe<Class_Images>;
  /** update data of the table: "class_lesson_plan" */
  update_class_lesson_plan?: Maybe<Class_Lesson_Plan_Mutation_Response>;
  /** update single row of the table: "class_lesson_plan" */
  update_class_lesson_plan_by_pk?: Maybe<Class_Lesson_Plan>;
  /** update data of the table: "class_module" */
  update_class_module?: Maybe<Class_Module_Mutation_Response>;
  /** update single row of the table: "class_module" */
  update_class_module_by_pk?: Maybe<Class_Module>;
  /** update data of the table: "class_playlist" */
  update_class_playlist?: Maybe<Class_Playlist_Mutation_Response>;
  /** update single row of the table: "class_playlist" */
  update_class_playlist_by_pk?: Maybe<Class_Playlist>;
  /** update data of the table: "class_sequence" */
  update_class_sequence?: Maybe<Class_Sequence_Mutation_Response>;
  /** update single row of the table: "class_sequence" */
  update_class_sequence_by_pk?: Maybe<Class_Sequence>;
  /** update data of the table: "class_song" */
  update_class_song?: Maybe<Class_Song_Mutation_Response>;
  /** update single row of the table: "class_song" */
  update_class_song_by_pk?: Maybe<Class_Song>;
  /** update data of the table: "class_student" */
  update_class_student?: Maybe<Class_Student_Mutation_Response>;
  /** update data of the table: "class_student_activities" */
  update_class_student_activities?: Maybe<Class_Student_Activities_Mutation_Response>;
  /** update single row of the table: "class_student_activities" */
  update_class_student_activities_by_pk?: Maybe<Class_Student_Activities>;
  /** update single row of the table: "class_student" */
  update_class_student_by_pk?: Maybe<Class_Student>;
  /** update data of the table: "class_teacher" */
  update_class_teacher?: Maybe<Class_Teacher_Mutation_Response>;
  /** update single row of the table: "class_teacher" */
  update_class_teacher_by_pk?: Maybe<Class_Teacher>;
  /** update data of the table: "class_tip" */
  update_class_tip?: Maybe<Class_Tip_Mutation_Response>;
  /** update single row of the table: "class_tip" */
  update_class_tip_by_pk?: Maybe<Class_Tip>;
  /** update data of the table: "clever_roster_log" */
  update_clever_roster_log?: Maybe<Clever_Roster_Log_Mutation_Response>;
  /** update single row of the table: "clever_roster_log" */
  update_clever_roster_log_by_pk?: Maybe<Clever_Roster_Log>;
  /** update data of the table: "clever_roster_status" */
  update_clever_roster_status?: Maybe<Clever_Roster_Status_Mutation_Response>;
  /** update single row of the table: "clever_roster_status" */
  update_clever_roster_status_by_pk?: Maybe<Clever_Roster_Status>;
  /** update data of the table: "contact_us" */
  update_contact_us?: Maybe<Contact_Us_Mutation_Response>;
  /** update single row of the table: "contact_us" */
  update_contact_us_by_pk?: Maybe<Contact_Us>;
  /** update data of the table: "contact_us_camp_teacher" */
  update_contact_us_camp_teacher?: Maybe<Contact_Us_Camp_Teacher_Mutation_Response>;
  /** update single row of the table: "contact_us_camp_teacher" */
  update_contact_us_camp_teacher_by_pk?: Maybe<Contact_Us_Camp_Teacher>;
  /** update data of the table: "crossclear.masterlist" */
  update_crossclear_masterlist?: Maybe<Crossclear_Masterlist_Mutation_Response>;
  /** update single row of the table: "crossclear.masterlist" */
  update_crossclear_masterlist_by_pk?: Maybe<Crossclear_Masterlist>;
  /** update data of the table: "discipline" */
  update_discipline?: Maybe<Discipline_Mutation_Response>;
  /** update single row of the table: "discipline" */
  update_discipline_by_pk?: Maybe<Discipline>;
  /** update data of the table: "district" */
  update_district?: Maybe<District_Mutation_Response>;
  /** update data of the table: "district_admin" */
  update_district_admin?: Maybe<District_Admin_Mutation_Response>;
  /** update single row of the table: "district_admin" */
  update_district_admin_by_pk?: Maybe<District_Admin>;
  /** update single row of the table: "district" */
  update_district_by_pk?: Maybe<District>;
  /** update data of the table: "filter_color" */
  update_filter_color?: Maybe<Filter_Color_Mutation_Response>;
  /** update single row of the table: "filter_color" */
  update_filter_color_by_pk?: Maybe<Filter_Color>;
  /** update data of the table: "filter_color_name" */
  update_filter_color_name?: Maybe<Filter_Color_Name_Mutation_Response>;
  /** update single row of the table: "filter_color_name" */
  update_filter_color_name_by_pk?: Maybe<Filter_Color_Name>;
  /** update data of the table: "filter_frame" */
  update_filter_frame?: Maybe<Filter_Frame_Mutation_Response>;
  /** update data of the table: "filter_sticker" */
  update_filter_sticker?: Maybe<Filter_Sticker_Mutation_Response>;
  /** update single row of the table: "filter_sticker" */
  update_filter_sticker_by_pk?: Maybe<Filter_Sticker>;
  /** update data of the table: "grade" */
  update_grade?: Maybe<Grade_Mutation_Response>;
  /** update single row of the table: "grade" */
  update_grade_by_pk?: Maybe<Grade>;
  /** update data of the table: "grading_system" */
  update_grading_system?: Maybe<Grading_System_Mutation_Response>;
  /** update single row of the table: "grading_system" */
  update_grading_system_by_pk?: Maybe<Grading_System>;
  /** update data of the table: "grading_system_type" */
  update_grading_system_type?: Maybe<Grading_System_Type_Mutation_Response>;
  /** update single row of the table: "grading_system_type" */
  update_grading_system_type_by_pk?: Maybe<Grading_System_Type>;
  /** update data of the table: "integration_name" */
  update_integration_name?: Maybe<Integration_Name_Mutation_Response>;
  /** update single row of the table: "integration_name" */
  update_integration_name_by_pk?: Maybe<Integration_Name>;
  /** update data of the table: "invitation_status" */
  update_invitation_status?: Maybe<Invitation_Status_Mutation_Response>;
  /** update single row of the table: "invitation_status" */
  update_invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** update data of the table: "keyword" */
  update_keyword?: Maybe<Keyword_Mutation_Response>;
  /** update single row of the table: "keyword" */
  update_keyword_by_pk?: Maybe<Keyword>;
  /** update data of the table: "label_control" */
  update_label_control?: Maybe<Label_Control_Mutation_Response>;
  /** update single row of the table: "label_control" */
  update_label_control_by_pk?: Maybe<Label_Control>;
  /** update data of the table: "labels" */
  update_labels?: Maybe<Labels_Mutation_Response>;
  /** update single row of the table: "labels" */
  update_labels_by_pk?: Maybe<Labels>;
  /** update data of the table: "lesson_plan" */
  update_lesson_plan?: Maybe<Lesson_Plan_Mutation_Response>;
  /** update data of the table: "lesson_plan_article" */
  update_lesson_plan_article?: Maybe<Lesson_Plan_Article_Mutation_Response>;
  /** update single row of the table: "lesson_plan_article" */
  update_lesson_plan_article_by_pk?: Maybe<Lesson_Plan_Article>;
  /** update data of the table: "lesson_plan_asset" */
  update_lesson_plan_asset?: Maybe<Lesson_Plan_Asset_Mutation_Response>;
  /** update single row of the table: "lesson_plan_asset" */
  update_lesson_plan_asset_by_pk?: Maybe<Lesson_Plan_Asset>;
  /** update single row of the table: "lesson_plan" */
  update_lesson_plan_by_pk?: Maybe<Lesson_Plan>;
  /** update data of the table: "lesson_plan_catalog_item" */
  update_lesson_plan_catalog_item?: Maybe<Lesson_Plan_Catalog_Item_Mutation_Response>;
  /** update single row of the table: "lesson_plan_catalog_item" */
  update_lesson_plan_catalog_item_by_pk?: Maybe<Lesson_Plan_Catalog_Item>;
  /** update data of the table: "lesson_plan_discipline" */
  update_lesson_plan_discipline?: Maybe<Lesson_Plan_Discipline_Mutation_Response>;
  /** update single row of the table: "lesson_plan_discipline" */
  update_lesson_plan_discipline_by_pk?: Maybe<Lesson_Plan_Discipline>;
  /** update data of the table: "lesson_plan_grade" */
  update_lesson_plan_grade?: Maybe<Lesson_Plan_Grade_Mutation_Response>;
  /** update single row of the table: "lesson_plan_grade" */
  update_lesson_plan_grade_by_pk?: Maybe<Lesson_Plan_Grade>;
  /** update data of the table: "lesson_plan_keyword" */
  update_lesson_plan_keyword?: Maybe<Lesson_Plan_Keyword_Mutation_Response>;
  /** update single row of the table: "lesson_plan_keyword" */
  update_lesson_plan_keyword_by_pk?: Maybe<Lesson_Plan_Keyword>;
  /** update data of the table: "lesson_plan_musical_skill" */
  update_lesson_plan_musical_skill?: Maybe<Lesson_Plan_Musical_Skill_Mutation_Response>;
  /** update single row of the table: "lesson_plan_musical_skill" */
  update_lesson_plan_musical_skill_by_pk?: Maybe<Lesson_Plan_Musical_Skill>;
  /** update data of the table: "lesson_plan_non_musical_skill" */
  update_lesson_plan_non_musical_skill?: Maybe<Lesson_Plan_Non_Musical_Skill_Mutation_Response>;
  /** update single row of the table: "lesson_plan_non_musical_skill" */
  update_lesson_plan_non_musical_skill_by_pk?: Maybe<Lesson_Plan_Non_Musical_Skill>;
  /** update data of the table: "lesson_plan_skill_level" */
  update_lesson_plan_skill_level?: Maybe<Lesson_Plan_Skill_Level_Mutation_Response>;
  /** update single row of the table: "lesson_plan_skill_level" */
  update_lesson_plan_skill_level_by_pk?: Maybe<Lesson_Plan_Skill_Level>;
  /** update data of the table: "lesson_plan_song" */
  update_lesson_plan_song?: Maybe<Lesson_Plan_Song_Mutation_Response>;
  /** update single row of the table: "lesson_plan_song" */
  update_lesson_plan_song_by_pk?: Maybe<Lesson_Plan_Song>;
  /** update data of the table: "lesson_plan_standard" */
  update_lesson_plan_standard?: Maybe<Lesson_Plan_Standard_Mutation_Response>;
  /** update single row of the table: "lesson_plan_standard" */
  update_lesson_plan_standard_by_pk?: Maybe<Lesson_Plan_Standard>;
  /** update data of the table: "lesson_plan_tip" */
  update_lesson_plan_tip?: Maybe<Lesson_Plan_Tip_Mutation_Response>;
  /** update single row of the table: "lesson_plan_tip" */
  update_lesson_plan_tip_by_pk?: Maybe<Lesson_Plan_Tip>;
  /** update data of the table: "lockable_content" */
  update_lockable_content?: Maybe<Lockable_Content_Mutation_Response>;
  /** update single row of the table: "lockable_content" */
  update_lockable_content_by_pk?: Maybe<Lockable_Content>;
  /** update data of the table: "lockable_content_class" */
  update_lockable_content_class?: Maybe<Lockable_Content_Class_Mutation_Response>;
  /** update single row of the table: "lockable_content_class" */
  update_lockable_content_class_by_pk?: Maybe<Lockable_Content_Class>;
  /** update data of the table: "lockable_content_district" */
  update_lockable_content_district?: Maybe<Lockable_Content_District_Mutation_Response>;
  /** update single row of the table: "lockable_content_district" */
  update_lockable_content_district_by_pk?: Maybe<Lockable_Content_District>;
  /** update data of the table: "lockable_content_school" */
  update_lockable_content_school?: Maybe<Lockable_Content_School_Mutation_Response>;
  /** update single row of the table: "lockable_content_school" */
  update_lockable_content_school_by_pk?: Maybe<Lockable_Content_School>;
  /** update data of the table: "lockable_content_teacher" */
  update_lockable_content_teacher?: Maybe<Lockable_Content_Teacher_Mutation_Response>;
  /** update single row of the table: "lockable_content_teacher" */
  update_lockable_content_teacher_by_pk?: Maybe<Lockable_Content_Teacher>;
  /** update data of the table: "maturity_level" */
  update_maturity_level?: Maybe<Maturity_Level_Mutation_Response>;
  /** update single row of the table: "maturity_level" */
  update_maturity_level_by_pk?: Maybe<Maturity_Level>;
  /** update data of the table: "membership_type" */
  update_membership_type?: Maybe<Membership_Type_Mutation_Response>;
  /** update single row of the table: "membership_type" */
  update_membership_type_by_pk?: Maybe<Membership_Type>;
  /** update data of the table: "midi" */
  update_midi?: Maybe<Midi_Mutation_Response>;
  /** update single row of the table: "midi" */
  update_midi_by_pk?: Maybe<Midi>;
  /** update data of the table: "module" */
  update_module?: Maybe<Module_Mutation_Response>;
  /** update single row of the table: "module" */
  update_module_by_pk?: Maybe<Module>;
  /** update data of the table: "module_catalog_item" */
  update_module_catalog_item?: Maybe<Module_Catalog_Item_Mutation_Response>;
  /** update single row of the table: "module_catalog_item" */
  update_module_catalog_item_by_pk?: Maybe<Module_Catalog_Item>;
  /** update data of the table: "module_grade" */
  update_module_grade?: Maybe<Module_Grade_Mutation_Response>;
  /** update single row of the table: "module_grade" */
  update_module_grade_by_pk?: Maybe<Module_Grade>;
  /** update data of the table: "module_lesson_plan" */
  update_module_lesson_plan?: Maybe<Module_Lesson_Plan_Mutation_Response>;
  /** update single row of the table: "module_lesson_plan" */
  update_module_lesson_plan_by_pk?: Maybe<Module_Lesson_Plan>;
  /** update data of the table: "musical_skill" */
  update_musical_skill?: Maybe<Musical_Skill_Mutation_Response>;
  /** update single row of the table: "musical_skill" */
  update_musical_skill_by_pk?: Maybe<Musical_Skill>;
  /** update data of the table: "non_musical_skill" */
  update_non_musical_skill?: Maybe<Non_Musical_Skill_Mutation_Response>;
  /** update single row of the table: "non_musical_skill" */
  update_non_musical_skill_by_pk?: Maybe<Non_Musical_Skill>;
  /** update data of the table: "non_musical_skill_type" */
  update_non_musical_skill_type?: Maybe<Non_Musical_Skill_Type_Mutation_Response>;
  /** update single row of the table: "non_musical_skill_type" */
  update_non_musical_skill_type_by_pk?: Maybe<Non_Musical_Skill_Type>;
  /** update data of the table: "order" */
  update_order?: Maybe<Order_Mutation_Response>;
  /** update single row of the table: "order" */
  update_order_by_pk?: Maybe<Order>;
  /** update data of the table: "order_status" */
  update_order_status?: Maybe<Order_Status_Mutation_Response>;
  /** update single row of the table: "order_status" */
  update_order_status_by_pk?: Maybe<Order_Status>;
  /** update data of the table: "parent_invitation" */
  update_parent_invitation?: Maybe<Parent_Invitation_Mutation_Response>;
  /** update single row of the table: "parent_invitation" */
  update_parent_invitation_by_pk?: Maybe<Parent_Invitation>;
  /** update data of the table: "partner" */
  update_partner?: Maybe<Partner_Mutation_Response>;
  /** update single row of the table: "partner" */
  update_partner_by_pk?: Maybe<Partner>;
  /** update data of the table: "partner_module" */
  update_partner_module?: Maybe<Partner_Module_Mutation_Response>;
  /** update single row of the table: "partner_module" */
  update_partner_module_by_pk?: Maybe<Partner_Module>;
  /** update data of the table: "partner_playlist" */
  update_partner_playlist?: Maybe<Partner_Playlist_Mutation_Response>;
  /** update single row of the table: "partner_playlist" */
  update_partner_playlist_by_pk?: Maybe<Partner_Playlist>;
  /** update data of the table: "playlist" */
  update_playlist?: Maybe<Playlist_Mutation_Response>;
  /** update single row of the table: "playlist" */
  update_playlist_by_pk?: Maybe<Playlist>;
  /** update data of the table: "price_type" */
  update_price_type?: Maybe<Price_Type_Mutation_Response>;
  /** update single row of the table: "price_type" */
  update_price_type_by_pk?: Maybe<Price_Type>;
  /** update data of the table: "privacy" */
  update_privacy?: Maybe<Privacy_Mutation_Response>;
  /** update single row of the table: "privacy" */
  update_privacy_by_pk?: Maybe<Privacy>;
  /** update data of the table: "product_name" */
  update_product_name?: Maybe<Product_Name_Mutation_Response>;
  /** update single row of the table: "product_name" */
  update_product_name_by_pk?: Maybe<Product_Name>;
  /** update data of the table: "publisher" */
  update_publisher?: Maybe<Publisher_Mutation_Response>;
  /** update single row of the table: "publisher" */
  update_publisher_by_pk?: Maybe<Publisher>;
  /** update data of the table: "publisher_control" */
  update_publisher_control?: Maybe<Publisher_Control_Mutation_Response>;
  /** update single row of the table: "publisher_control" */
  update_publisher_control_by_pk?: Maybe<Publisher_Control>;
  /** update data of the table: "reaction" */
  update_reaction?: Maybe<Reaction_Mutation_Response>;
  /** update single row of the table: "reaction" */
  update_reaction_by_pk?: Maybe<Reaction>;
  /** update data of the table: "reset_password" */
  update_reset_password?: Maybe<Reset_Password_Mutation_Response>;
  /** update single row of the table: "reset_password" */
  update_reset_password_by_pk?: Maybe<Reset_Password>;
  /** update data of the table: "role_name" */
  update_role_name?: Maybe<Role_Name_Mutation_Response>;
  /** update single row of the table: "role_name" */
  update_role_name_by_pk?: Maybe<Role_Name>;
  /** update data of the table: "royalty_log" */
  update_royalty_log?: Maybe<Royalty_Log_Mutation_Response>;
  /** update single row of the table: "royalty_log" */
  update_royalty_log_by_pk?: Maybe<Royalty_Log>;
  /** update data of the table: "scale" */
  update_scale?: Maybe<Scale_Mutation_Response>;
  /** update single row of the table: "scale" */
  update_scale_by_pk?: Maybe<Scale>;
  /** update data of the table: "school" */
  update_school?: Maybe<School_Mutation_Response>;
  /** update data of the table: "school_admin" */
  update_school_admin?: Maybe<School_Admin_Mutation_Response>;
  /** update single row of the table: "school_admin" */
  update_school_admin_by_pk?: Maybe<School_Admin>;
  /** update single row of the table: "school" */
  update_school_by_pk?: Maybe<School>;
  /** update data of the table: "school_teacher" */
  update_school_teacher?: Maybe<School_Teacher_Mutation_Response>;
  /** update single row of the table: "school_teacher" */
  update_school_teacher_by_pk?: Maybe<School_Teacher>;
  /** update data of the table: "screen_type" */
  update_screen_type?: Maybe<Screen_Type_Mutation_Response>;
  /** update single row of the table: "screen_type" */
  update_screen_type_by_pk?: Maybe<Screen_Type>;
  /** update data of the table: "sequence" */
  update_sequence?: Maybe<Sequence_Mutation_Response>;
  /** update single row of the table: "sequence" */
  update_sequence_by_pk?: Maybe<Sequence>;
  /** update data of the table: "sequence_keyword" */
  update_sequence_keyword?: Maybe<Sequence_Keyword_Mutation_Response>;
  /** update single row of the table: "sequence_keyword" */
  update_sequence_keyword_by_pk?: Maybe<Sequence_Keyword>;
  /** update data of the table: "sequence_lesson_plan" */
  update_sequence_lesson_plan?: Maybe<Sequence_Lesson_Plan_Mutation_Response>;
  /** update single row of the table: "sequence_lesson_plan" */
  update_sequence_lesson_plan_by_pk?: Maybe<Sequence_Lesson_Plan>;
  /** update data of the table: "sequence_topic" */
  update_sequence_topic?: Maybe<Sequence_Topic_Mutation_Response>;
  /** update single row of the table: "sequence_topic" */
  update_sequence_topic_by_pk?: Maybe<Sequence_Topic>;
  /** update data of the table: "sequence_topic_catalog_item" */
  update_sequence_topic_catalog_item?: Maybe<Sequence_Topic_Catalog_Item_Mutation_Response>;
  /** update single row of the table: "sequence_topic_catalog_item" */
  update_sequence_topic_catalog_item_by_pk?: Maybe<Sequence_Topic_Catalog_Item>;
  /** update data of the table: "sequence_topic_keyword" */
  update_sequence_topic_keyword?: Maybe<Sequence_Topic_Keyword_Mutation_Response>;
  /** update single row of the table: "sequence_topic_keyword" */
  update_sequence_topic_keyword_by_pk?: Maybe<Sequence_Topic_Keyword>;
  /** update data of the table: "sequence_topic_sequence" */
  update_sequence_topic_sequence?: Maybe<Sequence_Topic_Sequence_Mutation_Response>;
  /** update single row of the table: "sequence_topic_sequence" */
  update_sequence_topic_sequence_by_pk?: Maybe<Sequence_Topic_Sequence>;
  /** update data of the table: "session" */
  update_session?: Maybe<Session_Mutation_Response>;
  /** update single row of the table: "session" */
  update_session_by_pk?: Maybe<Session>;
  /** update data of the table: "skill_level" */
  update_skill_level?: Maybe<Skill_Level_Mutation_Response>;
  /** update single row of the table: "skill_level" */
  update_skill_level_by_pk?: Maybe<Skill_Level>;
  /** update data of the table: "song" */
  update_song?: Maybe<Song_Mutation_Response>;
  /** update single row of the table: "song" */
  update_song_by_pk?: Maybe<Song>;
  /** update data of the table: "song_catalog_item" */
  update_song_catalog_item?: Maybe<Song_Catalog_Item_Mutation_Response>;
  /** update single row of the table: "song_catalog_item" */
  update_song_catalog_item_by_pk?: Maybe<Song_Catalog_Item>;
  /** update data of the table: "song_grade" */
  update_song_grade?: Maybe<Song_Grade_Mutation_Response>;
  /** update single row of the table: "song_grade" */
  update_song_grade_by_pk?: Maybe<Song_Grade>;
  /** update data of the table: "song_keyword" */
  update_song_keyword?: Maybe<Song_Keyword_Mutation_Response>;
  /** update single row of the table: "song_keyword" */
  update_song_keyword_by_pk?: Maybe<Song_Keyword>;
  /** update data of the table: "standard" */
  update_standard?: Maybe<Standard_Mutation_Response>;
  /** update single row of the table: "standard" */
  update_standard_by_pk?: Maybe<Standard>;
  /** update data of the table: "standard_option" */
  update_standard_option?: Maybe<Standard_Option_Mutation_Response>;
  /** update single row of the table: "standard_option" */
  update_standard_option_by_pk?: Maybe<Standard_Option>;
  /** update data of the table: "standard_subcategory" */
  update_standard_subcategory?: Maybe<Standard_Subcategory_Mutation_Response>;
  /** update single row of the table: "standard_subcategory" */
  update_standard_subcategory_by_pk?: Maybe<Standard_Subcategory>;
  /** update data of the table: "student" */
  update_student?: Maybe<Student_Mutation_Response>;
  /** update data of the table: "student_activity" */
  update_student_activity?: Maybe<Student_Activity_Mutation_Response>;
  /** update single row of the table: "student_activity" */
  update_student_activity_by_pk?: Maybe<Student_Activity>;
  /** update data of the table: "student_activity_type" */
  update_student_activity_type?: Maybe<Student_Activity_Type_Mutation_Response>;
  /** update single row of the table: "student_activity_type" */
  update_student_activity_type_by_pk?: Maybe<Student_Activity_Type>;
  /** update data of the table: "student_badge" */
  update_student_badge?: Maybe<Student_Badge_Mutation_Response>;
  /** update single row of the table: "student_badge" */
  update_student_badge_by_pk?: Maybe<Student_Badge>;
  /** update data of the table: "student_badge_event" */
  update_student_badge_event?: Maybe<Student_Badge_Event_Mutation_Response>;
  /** update single row of the table: "student_badge_event" */
  update_student_badge_event_by_pk?: Maybe<Student_Badge_Event>;
  /** update data of the table: "student_banner" */
  update_student_banner?: Maybe<Student_Banner_Mutation_Response>;
  /** update single row of the table: "student_banner" */
  update_student_banner_by_pk?: Maybe<Student_Banner>;
  /** update single row of the table: "student" */
  update_student_by_pk?: Maybe<Student>;
  /** update data of the table: "student_goal" */
  update_student_goal?: Maybe<Student_Goal_Mutation_Response>;
  /** update single row of the table: "student_goal" */
  update_student_goal_by_pk?: Maybe<Student_Goal>;
  /** update data of the table: "student_integration" */
  update_student_integration?: Maybe<Student_Integration_Mutation_Response>;
  /** update single row of the table: "student_integration" */
  update_student_integration_by_pk?: Maybe<Student_Integration>;
  /** update data of the table: "student_lesson_plan_favorite" */
  update_student_lesson_plan_favorite?: Maybe<Student_Lesson_Plan_Favorite_Mutation_Response>;
  /** update single row of the table: "student_lesson_plan_favorite" */
  update_student_lesson_plan_favorite_by_pk?: Maybe<Student_Lesson_Plan_Favorite>;
  /** update data of the table: "student_productivity_daily" */
  update_student_productivity_daily?: Maybe<Student_Productivity_Daily_Mutation_Response>;
  /** update single row of the table: "student_productivity_daily" */
  update_student_productivity_daily_by_pk?: Maybe<Student_Productivity_Daily>;
  /** update data of the table: "student_productivity_monthly" */
  update_student_productivity_monthly?: Maybe<Student_Productivity_Monthly_Mutation_Response>;
  /** update single row of the table: "student_productivity_monthly" */
  update_student_productivity_monthly_by_pk?: Maybe<Student_Productivity_Monthly>;
  /** update data of the table: "student_productivity_yearly" */
  update_student_productivity_yearly?: Maybe<Student_Productivity_Yearly_Mutation_Response>;
  /** update single row of the table: "student_productivity_yearly" */
  update_student_productivity_yearly_by_pk?: Maybe<Student_Productivity_Yearly>;
  /** update data of the table: "student_setting" */
  update_student_setting?: Maybe<Student_Setting_Mutation_Response>;
  /** update single row of the table: "student_setting" */
  update_student_setting_by_pk?: Maybe<Student_Setting>;
  /** update data of the table: "student_song_favorite" */
  update_student_song_favorite?: Maybe<Student_Song_Favorite_Mutation_Response>;
  /** update single row of the table: "student_song_favorite" */
  update_student_song_favorite_by_pk?: Maybe<Student_Song_Favorite>;
  /** update data of the table: "student_tip_favorite" */
  update_student_tip_favorite?: Maybe<Student_Tip_Favorite_Mutation_Response>;
  /** update single row of the table: "student_tip_favorite" */
  update_student_tip_favorite_by_pk?: Maybe<Student_Tip_Favorite>;
  /** update data of the table: "submission" */
  update_submission?: Maybe<Submission_Mutation_Response>;
  /** update data of the table: "submission_asset" */
  update_submission_asset?: Maybe<Submission_Asset_Mutation_Response>;
  /** update single row of the table: "submission_asset" */
  update_submission_asset_by_pk?: Maybe<Submission_Asset>;
  /** update single row of the table: "submission" */
  update_submission_by_pk?: Maybe<Submission>;
  /** update data of the table: "submission_state" */
  update_submission_state?: Maybe<Submission_State_Mutation_Response>;
  /** update single row of the table: "submission_state" */
  update_submission_state_by_pk?: Maybe<Submission_State>;
  /** update data of the table: "submission_student" */
  update_submission_student?: Maybe<Submission_Student_Mutation_Response>;
  /** update single row of the table: "submission_student" */
  update_submission_student_by_pk?: Maybe<Submission_Student>;
  /** update data of the table: "submission_wurrly" */
  update_submission_wurrly?: Maybe<Submission_Wurrly_Mutation_Response>;
  /** update single row of the table: "submission_wurrly" */
  update_submission_wurrly_by_pk?: Maybe<Submission_Wurrly>;
  /** update data of the table: "submission_wurrly_state" */
  update_submission_wurrly_state?: Maybe<Submission_Wurrly_State_Mutation_Response>;
  /** update single row of the table: "submission_wurrly_state" */
  update_submission_wurrly_state_by_pk?: Maybe<Submission_Wurrly_State>;
  /** update data of the table: "teacher" */
  update_teacher?: Maybe<Teacher_Mutation_Response>;
  /** update single row of the table: "teacher" */
  update_teacher_by_pk?: Maybe<Teacher>;
  /** update data of the table: "teacher_integration" */
  update_teacher_integration?: Maybe<Teacher_Integration_Mutation_Response>;
  /** update single row of the table: "teacher_integration" */
  update_teacher_integration_by_pk?: Maybe<Teacher_Integration>;
  /** update data of the table: "teacher_lesson_plan_favorite" */
  update_teacher_lesson_plan_favorite?: Maybe<Teacher_Lesson_Plan_Favorite_Mutation_Response>;
  /** update single row of the table: "teacher_lesson_plan_favorite" */
  update_teacher_lesson_plan_favorite_by_pk?: Maybe<Teacher_Lesson_Plan_Favorite>;
  /** update data of the table: "teacher_setting" */
  update_teacher_setting?: Maybe<Teacher_Setting_Mutation_Response>;
  /** update single row of the table: "teacher_setting" */
  update_teacher_setting_by_pk?: Maybe<Teacher_Setting>;
  /** update data of the table: "teacher_song_favorite" */
  update_teacher_song_favorite?: Maybe<Teacher_Song_Favorite_Mutation_Response>;
  /** update single row of the table: "teacher_song_favorite" */
  update_teacher_song_favorite_by_pk?: Maybe<Teacher_Song_Favorite>;
  /** update data of the table: "teacher_subscription" */
  update_teacher_subscription?: Maybe<Teacher_Subscription_Mutation_Response>;
  /** update single row of the table: "teacher_subscription" */
  update_teacher_subscription_by_pk?: Maybe<Teacher_Subscription>;
  /** update data of the table: "teacher_tip_favorite" */
  update_teacher_tip_favorite?: Maybe<Teacher_Tip_Favorite_Mutation_Response>;
  /** update single row of the table: "teacher_tip_favorite" */
  update_teacher_tip_favorite_by_pk?: Maybe<Teacher_Tip_Favorite>;
  /** update data of the table: "teacher_wurrly_comment" */
  update_teacher_wurrly_comment?: Maybe<Teacher_Wurrly_Comment_Mutation_Response>;
  /** update single row of the table: "teacher_wurrly_comment" */
  update_teacher_wurrly_comment_by_pk?: Maybe<Teacher_Wurrly_Comment>;
  /** update data of the table: "tip" */
  update_tip?: Maybe<Tip_Mutation_Response>;
  /** update data of the table: "tip_age" */
  update_tip_age?: Maybe<Tip_Age_Mutation_Response>;
  /** update single row of the table: "tip_age" */
  update_tip_age_by_pk?: Maybe<Tip_Age>;
  /** update single row of the table: "tip" */
  update_tip_by_pk?: Maybe<Tip>;
  /** update data of the table: "tip_catalog_item" */
  update_tip_catalog_item?: Maybe<Tip_Catalog_Item_Mutation_Response>;
  /** update single row of the table: "tip_catalog_item" */
  update_tip_catalog_item_by_pk?: Maybe<Tip_Catalog_Item>;
  /** update data of the table: "tip_grade" */
  update_tip_grade?: Maybe<Tip_Grade_Mutation_Response>;
  /** update single row of the table: "tip_grade" */
  update_tip_grade_by_pk?: Maybe<Tip_Grade>;
  /** update data of the table: "tip_keyword" */
  update_tip_keyword?: Maybe<Tip_Keyword_Mutation_Response>;
  /** update single row of the table: "tip_keyword" */
  update_tip_keyword_by_pk?: Maybe<Tip_Keyword>;
  /** update data of the table: "tip_musical_skill" */
  update_tip_musical_skill?: Maybe<Tip_Musical_Skill_Mutation_Response>;
  /** update single row of the table: "tip_musical_skill" */
  update_tip_musical_skill_by_pk?: Maybe<Tip_Musical_Skill>;
  /** update data of the table: "tip_non_musical_skill" */
  update_tip_non_musical_skill?: Maybe<Tip_Non_Musical_Skill_Mutation_Response>;
  /** update single row of the table: "tip_non_musical_skill" */
  update_tip_non_musical_skill_by_pk?: Maybe<Tip_Non_Musical_Skill>;
  /** update data of the table: "tip_playlist" */
  update_tip_playlist?: Maybe<Tip_Playlist_Mutation_Response>;
  /** update single row of the table: "tip_playlist" */
  update_tip_playlist_by_pk?: Maybe<Tip_Playlist>;
  /** update data of the table: "track" */
  update_track?: Maybe<Track_Mutation_Response>;
  /** update single row of the table: "track" */
  update_track_by_pk?: Maybe<Track>;
  /** update data of the table: "track_type" */
  update_track_type?: Maybe<Track_Type_Mutation_Response>;
  /** update single row of the table: "track_type" */
  update_track_type_by_pk?: Maybe<Track_Type>;
  /** update data of the table: "transpose_index" */
  update_transpose_index?: Maybe<Transpose_Index_Mutation_Response>;
  /** update single row of the table: "transpose_index" */
  update_transpose_index_by_pk?: Maybe<Transpose_Index>;
  /** update data of the table: "utils.most_attended_classes" */
  update_utils_most_attended_classes?: Maybe<Utils_Most_Attended_Classes_Mutation_Response>;
  /** update data of the table: "utils.most_attended_subjects" */
  update_utils_most_attended_subjects?: Maybe<Utils_Most_Attended_Subjects_Mutation_Response>;
  /** update data of the table: "utils.teacher_subscriptions_per_month" */
  update_utils_teacher_subscriptions_per_month?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Mutation_Response>;
  /** update data of the table: "utils.users_per_month" */
  update_utils_users_per_month?: Maybe<Utils_Users_Per_Month_Mutation_Response>;
  /** update data of the table: "visibility" */
  update_visibility?: Maybe<Visibility_Mutation_Response>;
  /** update single row of the table: "visibility" */
  update_visibility_by_pk?: Maybe<Visibility>;
  /** update data of the table: "wurrly" */
  update_wurrly?: Maybe<Wurrly_Mutation_Response>;
  /** update single row of the table: "wurrly" */
  update_wurrly_by_pk?: Maybe<Wurrly>;
  /** update data of the table: "wurrly_filter" */
  update_wurrly_filter?: Maybe<Wurrly_Filter_Mutation_Response>;
  /** update single row of the table: "wurrly_filter" */
  update_wurrly_filter_by_pk?: Maybe<Wurrly_Filter>;
  /** update data of the table: "wurrly_filter_sticker" */
  update_wurrly_filter_sticker?: Maybe<Wurrly_Filter_Sticker_Mutation_Response>;
  /** update data of the table: "wurrly_processing_status" */
  update_wurrly_processing_status?: Maybe<Wurrly_Processing_Status_Mutation_Response>;
  /** update single row of the table: "wurrly_processing_status" */
  update_wurrly_processing_status_by_pk?: Maybe<Wurrly_Processing_Status>;
  /** update data of the table: "wurrly_reaction" */
  update_wurrly_reaction?: Maybe<Wurrly_Reaction_Mutation_Response>;
  /** update single row of the table: "wurrly_reaction" */
  update_wurrly_reaction_by_pk?: Maybe<Wurrly_Reaction>;
  /** update data of the table: "wurrly_role" */
  update_wurrly_role?: Maybe<Wurrly_Role_Mutation_Response>;
  /** update single row of the table: "wurrly_role" */
  update_wurrly_role_by_pk?: Maybe<Wurrly_Role>;
  /** update data of the table: "wurrly_type" */
  update_wurrly_type?: Maybe<Wurrly_Type_Mutation_Response>;
  /** update single row of the table: "wurrly_type" */
  update_wurrly_type_by_pk?: Maybe<Wurrly_Type>;
  /** execute VOLATILE function "utils.update_district_lockable_content_modules_and_lessons_function" which returns "lockable_content_district" */
  utils_update_district_lockable_content_modules_and_lessons_function: Array<Lockable_Content_District>;
  /** execute VOLATILE function "utils.update_school_lockable_content_modules_and_lessons_function" which returns "lockable_content_school" */
  utils_update_school_lockable_content_modules_and_lessons_function: Array<Lockable_Content_School>;
};


/** mutation root */
export type Mutation_RootAcceptConsentArgs = {
  filter?: Maybe<AcceptConsentFilter>;
};


/** mutation root */
export type Mutation_RootActivateAccountArgs = {
  filter?: Maybe<ActivateAccountFilter>;
};


/** mutation root */
export type Mutation_RootActivateTeacherArgs = {
  filter?: Maybe<ActivateTeacherFilter>;
};


/** mutation root */
export type Mutation_RootAddStudentsOver13Args = {
  filter: StudentFromCsvFilter;
};


/** mutation root */
export type Mutation_RootAddStudentsUnder12Args = {
  set?: Maybe<AddStudentsUnder12Set>;
};


/** mutation root */
export type Mutation_RootCancelProductSubscriptionArgs = {
  set?: Maybe<CancelProductSubscriptionSet>;
};


/** mutation root */
export type Mutation_RootCancelSubscriptionArgs = {
  filter?: Maybe<CancelSubscriptionFilter>;
};


/** mutation root */
export type Mutation_RootCompleteTeacherIntegrationArgs = {
  set?: Maybe<CompleteTeacherIntegrationSet>;
};


/** mutation root */
export type Mutation_RootCreateProductSubscriptionArgs = {
  set?: Maybe<CreateProductSubscriptionSet>;
};


/** mutation root */
export type Mutation_RootCreateSubscriptionArgs = {
  filter?: Maybe<CreateSubscriptionFilter>;
};


/** mutation root */
export type Mutation_RootCustomCreateAssignmentArgs = {
  set?: Maybe<CustomCreateAssignmentFilter>;
};


/** mutation root */
export type Mutation_RootCustomCreateWurrlyArgs = {
  set?: Maybe<CustomCreateWurrlyFilter>;
};


/** mutation root */
export type Mutation_RootCustomTrackUpdateArgs = {
  filter?: Maybe<UpdateTrackFilter>;
};


/** mutation root */
export type Mutation_RootCustomTrackUploadArgs = {
  filter?: Maybe<UploadTrackFilter>;
};


/** mutation root */
export type Mutation_RootDelete_Account_ActivationArgs = {
  where: Account_Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Activation_By_PkArgs = {
  account_activation_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AdminArgs = {
  where: Admin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_By_PkArgs = {
  admin_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AppArgs = {
  where: App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_By_PkArgs = {
  app_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ArtistArgs = {
  where: Artist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_By_PkArgs = {
  artist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AssetArgs = {
  where: Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Asset_By_PkArgs = {
  asset_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AssignmentArgs = {
  where: Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_AssetArgs = {
  where: Assignment_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_Asset_By_PkArgs = {
  assignment_asset_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Assignment_By_PkArgs = {
  assignment_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Assignment_Lesson_PlanArgs = {
  where: Assignment_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_Lesson_Plan_By_PkArgs = {
  assignment_lesson_plan_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Assignment_SongArgs = {
  where: Assignment_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_Song_By_PkArgs = {
  assignment_song_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Assignment_TipArgs = {
  where: Assignment_Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_Tip_By_PkArgs = {
  assignment_tip_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AvailableArgs = {
  where: Available_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Available_By_PkArgs = {
  available_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Awarded_Badge_EventArgs = {
  where: Awarded_Badge_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Awarded_Badge_Event_By_PkArgs = {
  student_badge_event_id: Scalars['Int'];
  student_badge_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BadgeArgs = {
  where: Badge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badge_By_PkArgs = {
  badge_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Badge_EventArgs = {
  where: Badge_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badge_Event_By_PkArgs = {
  badge_id: Scalars['Int'];
  event_name: Badge_Event_Name_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Badge_Event_NameArgs = {
  where: Badge_Event_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Badge_Event_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_HistoryArgs = {
  where: Billing_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_History_By_PkArgs = {
  billing_history_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_MethodArgs = {
  where: Billing_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Method_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_StatusArgs = {
  where: Billing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CatalogArgs = {
  where: Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Catalog_By_PkArgs = {
  catalog_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Catalog_ItemArgs = {
  where: Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Catalog_Item_By_PkArgs = {
  catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Catalog_NameArgs = {
  where: Catalog_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Catalog_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Category_StandardArgs = {
  where: Category_Standard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Standard_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ChallengeArgs = {
  where: Challenge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Challenge_By_PkArgs = {
  challenge_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Challenge_Catalog_ItemArgs = {
  where: Challenge_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Challenge_Catalog_Item_By_PkArgs = {
  challenge_catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Challenge_DistrictArgs = {
  where: Challenge_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Challenge_District_By_PkArgs = {
  challenge_district_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Challenge_GradeArgs = {
  where: Challenge_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Challenge_Grade_By_PkArgs = {
  challenge_grade_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Challenge_SongArgs = {
  where: Challenge_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Challenge_Song_By_PkArgs = {
  challenge_song_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Challenge_StudentArgs = {
  where: Challenge_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Challenge_Student_By_PkArgs = {
  challenge_student_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ChannelArgs = {
  where: Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_By_PkArgs = {
  channel_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Channel_PlaylistArgs = {
  where: Channel_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_Playlist_By_PkArgs = {
  channel_playlist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ClassArgs = {
  where: Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_AssignmentArgs = {
  where: Class_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Assignment_By_PkArgs = {
  class_assignment_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Assignment_StatusArgs = {
  where: Class_Assignment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Assignment_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Class_By_PkArgs = {
  class_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_ImagesArgs = {
  where: Class_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Images_By_PkArgs = {
  class_image_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Lesson_PlanArgs = {
  where: Class_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Lesson_Plan_By_PkArgs = {
  class_lesson_plan_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_ModuleArgs = {
  where: Class_Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Module_By_PkArgs = {
  class_module_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_PlaylistArgs = {
  where: Class_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Playlist_By_PkArgs = {
  class_playlist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_SequenceArgs = {
  where: Class_Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Sequence_By_PkArgs = {
  class_sequence_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_SongArgs = {
  where: Class_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Song_By_PkArgs = {
  class_song_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_StudentArgs = {
  where: Class_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Student_ActivitiesArgs = {
  where: Class_Student_Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Student_Activities_By_PkArgs = {
  class_student_activities_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Student_By_PkArgs = {
  class_student_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_TeacherArgs = {
  where: Class_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Teacher_By_PkArgs = {
  class_teacher_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_TipArgs = {
  where: Class_Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Tip_By_PkArgs = {
  class_tip_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Clever_Roster_LogArgs = {
  where: Clever_Roster_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clever_Roster_Log_By_PkArgs = {
  clever_roster_log_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Clever_Roster_StatusArgs = {
  where: Clever_Roster_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clever_Roster_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_UsArgs = {
  where: Contact_Us_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Us_By_PkArgs = {
  contact_us_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_Us_Camp_TeacherArgs = {
  where: Contact_Us_Camp_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Us_Camp_Teacher_By_PkArgs = {
  contact_us_camp_teacher_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Crossclear_MasterlistArgs = {
  where: Crossclear_Masterlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Crossclear_Masterlist_By_PkArgs = {
  masterlist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DisciplineArgs = {
  where: Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discipline_By_PkArgs = {
  discipline_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DistrictArgs = {
  where: District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_District_AdminArgs = {
  where: District_Admin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_District_Admin_By_PkArgs = {
  district_admin_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_District_By_PkArgs = {
  district_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Filter_ColorArgs = {
  where: Filter_Color_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_Color_By_PkArgs = {
  filter_color_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Filter_Color_NameArgs = {
  where: Filter_Color_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_Color_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Filter_FrameArgs = {
  where: Filter_Frame_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_StickerArgs = {
  where: Filter_Sticker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filter_Sticker_By_PkArgs = {
  filter_sticker_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GradeArgs = {
  where: Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Grade_By_PkArgs = {
  grade_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Grading_SystemArgs = {
  where: Grading_System_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Grading_System_By_PkArgs = {
  grading_system_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Grading_System_TypeArgs = {
  where: Grading_System_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Grading_System_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Integration_NameArgs = {
  where: Integration_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Integration_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Invitation_StatusArgs = {
  where: Invitation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invitation_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_KeywordArgs = {
  where: Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Keyword_By_PkArgs = {
  keyword_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Label_ControlArgs = {
  where: Label_Control_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Label_Control_By_PkArgs = {
  label_control_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LabelsArgs = {
  where: Labels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Labels_By_PkArgs = {
  labels_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_PlanArgs = {
  where: Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_ArticleArgs = {
  where: Lesson_Plan_Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Article_By_PkArgs = {
  lesson_plan_article_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_AssetArgs = {
  where: Lesson_Plan_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Asset_By_PkArgs = {
  lesson_plan_asset_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_By_PkArgs = {
  lesson_plan_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Catalog_ItemArgs = {
  where: Lesson_Plan_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Catalog_Item_By_PkArgs = {
  lesson_plan_catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_DisciplineArgs = {
  where: Lesson_Plan_Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Discipline_By_PkArgs = {
  lesson_plan_discipline_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_GradeArgs = {
  where: Lesson_Plan_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Grade_By_PkArgs = {
  lesson_plan_grade_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_KeywordArgs = {
  where: Lesson_Plan_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Keyword_By_PkArgs = {
  lesson_plan_keyword_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Musical_SkillArgs = {
  where: Lesson_Plan_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Musical_Skill_By_PkArgs = {
  lesson_plan_musical_skill_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Non_Musical_SkillArgs = {
  where: Lesson_Plan_Non_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Non_Musical_Skill_By_PkArgs = {
  lesson_plan_non_musical_skill_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Skill_LevelArgs = {
  where: Lesson_Plan_Skill_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Skill_Level_By_PkArgs = {
  lesson_plan_skill_level_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_SongArgs = {
  where: Lesson_Plan_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Song_By_PkArgs = {
  lesson_plan_song_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_StandardArgs = {
  where: Lesson_Plan_Standard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Standard_By_PkArgs = {
  lesson_plan_standar_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_TipArgs = {
  where: Lesson_Plan_Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lesson_Plan_Tip_By_PkArgs = {
  lesson_plan_tip_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lockable_ContentArgs = {
  where: Lockable_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_ClassArgs = {
  where: Lockable_Content_Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_Class_By_PkArgs = {
  class_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_DistrictArgs = {
  where: Lockable_Content_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_District_By_PkArgs = {
  district_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_SchoolArgs = {
  where: Lockable_Content_School_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_School_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_TeacherArgs = {
  where: Lockable_Content_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lockable_Content_Teacher_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Maturity_LevelArgs = {
  where: Maturity_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Maturity_Level_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Membership_TypeArgs = {
  where: Membership_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Membership_Type_By_PkArgs = {
  membership_type_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MidiArgs = {
  where: Midi_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Midi_By_PkArgs = {
  midi_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ModuleArgs = {
  where: Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Module_By_PkArgs = {
  module_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Module_Catalog_ItemArgs = {
  where: Module_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Module_Catalog_Item_By_PkArgs = {
  module_catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Module_GradeArgs = {
  where: Module_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Module_Grade_By_PkArgs = {
  module_grade_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Module_Lesson_PlanArgs = {
  where: Module_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Module_Lesson_Plan_By_PkArgs = {
  module_lesson_plan_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Musical_SkillArgs = {
  where: Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Musical_Skill_By_PkArgs = {
  musical_skill_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Non_Musical_SkillArgs = {
  where: Non_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Non_Musical_Skill_By_PkArgs = {
  non_musical_skill_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Non_Musical_Skill_TypeArgs = {
  where: Non_Musical_Skill_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Non_Musical_Skill_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_OrderArgs = {
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_By_PkArgs = {
  order_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Order_StatusArgs = {
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Parent_InvitationArgs = {
  where: Parent_Invitation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Parent_Invitation_By_PkArgs = {
  parent_invitation_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PartnerArgs = {
  where: Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_By_PkArgs = {
  partner_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_ModuleArgs = {
  where: Partner_Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Module_By_PkArgs = {
  partner_module_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Partner_PlaylistArgs = {
  where: Partner_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Partner_Playlist_By_PkArgs = {
  partner_playlist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PlaylistArgs = {
  where: Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Playlist_By_PkArgs = {
  playlist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Price_TypeArgs = {
  where: Price_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Price_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PrivacyArgs = {
  where: Privacy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Privacy_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Product_NameArgs = {
  where: Product_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PublisherArgs = {
  where: Publisher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Publisher_By_PkArgs = {
  publisher_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Publisher_ControlArgs = {
  where: Publisher_Control_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Publisher_Control_By_PkArgs = {
  publisher_control_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReactionArgs = {
  where: Reaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reaction_By_PkArgs = {
  reaction_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Reset_PasswordArgs = {
  where: Reset_Password_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reset_Password_By_PkArgs = {
  reset_password_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Role_NameArgs = {
  where: Role_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Name_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Royalty_LogArgs = {
  where: Royalty_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Royalty_Log_By_PkArgs = {
  royalty_log_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ScaleArgs = {
  where: Scale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scale_By_PkArgs = {
  scale_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SchoolArgs = {
  where: School_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_School_AdminArgs = {
  where: School_Admin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_School_Admin_By_PkArgs = {
  school_admin_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_School_By_PkArgs = {
  school_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_School_TeacherArgs = {
  where: School_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_School_Teacher_By_PkArgs = {
  school_teacher_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Screen_TypeArgs = {
  where: Screen_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Screen_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SequenceArgs = {
  where: Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_By_PkArgs = {
  sequence_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_KeywordArgs = {
  where: Sequence_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Keyword_By_PkArgs = {
  sequence_keyword_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Lesson_PlanArgs = {
  where: Sequence_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Lesson_Plan_By_PkArgs = {
  sequence_lesson_plan_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_TopicArgs = {
  where: Sequence_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_By_PkArgs = {
  sequence_topic_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_Catalog_ItemArgs = {
  where: Sequence_Topic_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_Catalog_Item_By_PkArgs = {
  sequence_topic_catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_KeywordArgs = {
  where: Sequence_Topic_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_Keyword_By_PkArgs = {
  sequence_topic_keyword_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_SequenceArgs = {
  where: Sequence_Topic_Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sequence_Topic_Sequence_By_PkArgs = {
  sequence_topic_sequence_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_By_PkArgs = {
  session_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Skill_LevelArgs = {
  where: Skill_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Skill_Level_By_PkArgs = {
  skill_level_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SongArgs = {
  where: Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Song_By_PkArgs = {
  song_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Song_Catalog_ItemArgs = {
  where: Song_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Song_Catalog_Item_By_PkArgs = {
  song_catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Song_GradeArgs = {
  where: Song_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Song_Grade_By_PkArgs = {
  song_grade_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Song_KeywordArgs = {
  where: Song_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Song_Keyword_By_PkArgs = {
  song_keyword_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StandardArgs = {
  where: Standard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Standard_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Standard_OptionArgs = {
  where: Standard_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Standard_Option_By_PkArgs = {
  standar_option_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Standard_SubcategoryArgs = {
  where: Standard_Subcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Standard_Subcategory_By_PkArgs = {
  standar_subcategory_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_StudentArgs = {
  where: Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_ActivityArgs = {
  where: Student_Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Activity_By_PkArgs = {
  student_activity_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Activity_TypeArgs = {
  where: Student_Activity_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Activity_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Student_BadgeArgs = {
  where: Student_Badge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Badge_By_PkArgs = {
  student_badge_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Badge_EventArgs = {
  where: Student_Badge_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Badge_Event_By_PkArgs = {
  student_badge_event_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_BannerArgs = {
  where: Student_Banner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Banner_By_PkArgs = {
  student_banner_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_By_PkArgs = {
  student_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_GoalArgs = {
  where: Student_Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Goal_By_PkArgs = {
  student_goal_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_IntegrationArgs = {
  where: Student_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Integration_By_PkArgs = {
  student_integration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Lesson_Plan_FavoriteArgs = {
  where: Student_Lesson_Plan_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Lesson_Plan_Favorite_By_PkArgs = {
  student_lesson_plan_favorite_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Productivity_DailyArgs = {
  where: Student_Productivity_Daily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Productivity_Daily_By_PkArgs = {
  student_productivity_daily_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Productivity_MonthlyArgs = {
  where: Student_Productivity_Monthly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Productivity_Monthly_By_PkArgs = {
  student_productivity_monthly_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Productivity_YearlyArgs = {
  where: Student_Productivity_Yearly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Productivity_Yearly_By_PkArgs = {
  student_productivity_yearly_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_SettingArgs = {
  where: Student_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Setting_By_PkArgs = {
  student_setting_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Song_FavoriteArgs = {
  where: Student_Song_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Song_Favorite_By_PkArgs = {
  student_song_favorite_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Student_Tip_FavoriteArgs = {
  where: Student_Tip_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Student_Tip_Favorite_By_PkArgs = {
  student_tip_favorite_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubmissionArgs = {
  where: Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_AssetArgs = {
  where: Submission_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_Asset_By_PkArgs = {
  submission_asset_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Submission_By_PkArgs = {
  submission_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Submission_StateArgs = {
  where: Submission_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_State_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Submission_StudentArgs = {
  where: Submission_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_Student_By_PkArgs = {
  submission_student_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Submission_WurrlyArgs = {
  where: Submission_Wurrly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_Wurrly_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Submission_Wurrly_StateArgs = {
  where: Submission_Wurrly_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Submission_Wurrly_State_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TeacherArgs = {
  where: Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_By_PkArgs = {
  teacher_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_IntegrationArgs = {
  where: Teacher_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Integration_By_PkArgs = {
  teacher_integration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Lesson_Plan_FavoriteArgs = {
  where: Teacher_Lesson_Plan_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Lesson_Plan_Favorite_By_PkArgs = {
  teacher_lesson_plan_favorite_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_SettingArgs = {
  where: Teacher_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Setting_By_PkArgs = {
  teacher_setting_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Song_FavoriteArgs = {
  where: Teacher_Song_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Song_Favorite_By_PkArgs = {
  teacher_song_favorite_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_SubscriptionArgs = {
  where: Teacher_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Subscription_By_PkArgs = {
  subscription_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Tip_FavoriteArgs = {
  where: Teacher_Tip_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Tip_Favorite_By_PkArgs = {
  teacher_tip_favorite_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Wurrly_CommentArgs = {
  where: Teacher_Wurrly_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Wurrly_Comment_By_PkArgs = {
  teacher_wurrly_comment_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TipArgs = {
  where: Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_AgeArgs = {
  where: Tip_Age_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Age_By_PkArgs = {
  tip_age_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_By_PkArgs = {
  tip_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_Catalog_ItemArgs = {
  where: Tip_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Catalog_Item_By_PkArgs = {
  tip_catalog_item_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_GradeArgs = {
  where: Tip_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Grade_By_PkArgs = {
  tip_grade_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_KeywordArgs = {
  where: Tip_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Keyword_By_PkArgs = {
  tip_keyword_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_Musical_SkillArgs = {
  where: Tip_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Musical_Skill_By_PkArgs = {
  tip_musical_skill_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_Non_Musical_SkillArgs = {
  where: Tip_Non_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Non_Musical_Skill_By_PkArgs = {
  tip_non_musical_skill_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_PlaylistArgs = {
  where: Tip_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Playlist_By_PkArgs = {
  tip_playlist_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TrackArgs = {
  where: Track_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Track_By_PkArgs = {
  track_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Track_TypeArgs = {
  where: Track_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Track_Type_By_PkArgs = {
  track_type_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Transpose_IndexArgs = {
  where: Transpose_Index_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transpose_Index_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Utils_Most_Attended_ClassesArgs = {
  where: Utils_Most_Attended_Classes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Utils_Most_Attended_SubjectsArgs = {
  where: Utils_Most_Attended_Subjects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Utils_Teacher_Subscriptions_Per_MonthArgs = {
  where: Utils_Teacher_Subscriptions_Per_Month_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Utils_Users_Per_MonthArgs = {
  where: Utils_Users_Per_Month_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VisibilityArgs = {
  where: Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Visibility_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_WurrlyArgs = {
  where: Wurrly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_FilterArgs = {
  where: Wurrly_Filter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Filter_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Filter_StickerArgs = {
  where: Wurrly_Filter_Sticker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Processing_StatusArgs = {
  where: Wurrly_Processing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Processing_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_ReactionArgs = {
  where: Wurrly_Reaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Reaction_By_PkArgs = {
  wurrly_reaction_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_RoleArgs = {
  where: Wurrly_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Role_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_TypeArgs = {
  where: Wurrly_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wurrly_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDistrictAddOrUpdateChannelsAndPlaylistsArgs = {
  set?: Maybe<DistrictAddOrUpdateChannelsAndPlaylistsSet>;
};


/** mutation root */
export type Mutation_RootDistrictAddOrUpdatePartnersAndModulesArgs = {
  set?: Maybe<DistrictAddOrUpdatePartnersAndModulesSet>;
};


/** mutation root */
export type Mutation_RootEditTeacherInformationArgs = {
  filter?: Maybe<EditTeacherInfoFilter>;
};


/** mutation root */
export type Mutation_RootInsertContactUsWithMailArgs = {
  filter?: Maybe<InsertContactUsWithMailFilter>;
};


/** mutation root */
export type Mutation_RootInsertIntegrationStudentsToClassArgs = {
  set: InsertIntegrationStudentsToClassSet;
};


/** mutation root */
export type Mutation_RootInsert_Account_ActivationArgs = {
  objects: Array<Account_Activation_Insert_Input>;
  on_conflict?: Maybe<Account_Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Activation_OneArgs = {
  object: Account_Activation_Insert_Input;
  on_conflict?: Maybe<Account_Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AdminArgs = {
  objects: Array<Admin_Insert_Input>;
  on_conflict?: Maybe<Admin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_OneArgs = {
  object: Admin_Insert_Input;
  on_conflict?: Maybe<Admin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppArgs = {
  objects: Array<App_Insert_Input>;
  on_conflict?: Maybe<App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_OneArgs = {
  object: App_Insert_Input;
  on_conflict?: Maybe<App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ArtistArgs = {
  objects: Array<Artist_Insert_Input>;
  on_conflict?: Maybe<Artist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_OneArgs = {
  object: Artist_Insert_Input;
  on_conflict?: Maybe<Artist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AssetArgs = {
  objects: Array<Asset_Insert_Input>;
  on_conflict?: Maybe<Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asset_OneArgs = {
  object: Asset_Insert_Input;
  on_conflict?: Maybe<Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AssignmentArgs = {
  objects: Array<Assignment_Insert_Input>;
  on_conflict?: Maybe<Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_AssetArgs = {
  objects: Array<Assignment_Asset_Insert_Input>;
  on_conflict?: Maybe<Assignment_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_Asset_OneArgs = {
  object: Assignment_Asset_Insert_Input;
  on_conflict?: Maybe<Assignment_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_Lesson_PlanArgs = {
  objects: Array<Assignment_Lesson_Plan_Insert_Input>;
  on_conflict?: Maybe<Assignment_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_Lesson_Plan_OneArgs = {
  object: Assignment_Lesson_Plan_Insert_Input;
  on_conflict?: Maybe<Assignment_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_OneArgs = {
  object: Assignment_Insert_Input;
  on_conflict?: Maybe<Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_SongArgs = {
  objects: Array<Assignment_Song_Insert_Input>;
  on_conflict?: Maybe<Assignment_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_Song_OneArgs = {
  object: Assignment_Song_Insert_Input;
  on_conflict?: Maybe<Assignment_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_TipArgs = {
  objects: Array<Assignment_Tip_Insert_Input>;
  on_conflict?: Maybe<Assignment_Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_Tip_OneArgs = {
  object: Assignment_Tip_Insert_Input;
  on_conflict?: Maybe<Assignment_Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AvailableArgs = {
  objects: Array<Available_Insert_Input>;
  on_conflict?: Maybe<Available_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Available_OneArgs = {
  object: Available_Insert_Input;
  on_conflict?: Maybe<Available_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Awarded_Badge_EventArgs = {
  objects: Array<Awarded_Badge_Event_Insert_Input>;
  on_conflict?: Maybe<Awarded_Badge_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Awarded_Badge_Event_OneArgs = {
  object: Awarded_Badge_Event_Insert_Input;
  on_conflict?: Maybe<Awarded_Badge_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BadgeArgs = {
  objects: Array<Badge_Insert_Input>;
  on_conflict?: Maybe<Badge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_EventArgs = {
  objects: Array<Badge_Event_Insert_Input>;
  on_conflict?: Maybe<Badge_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Event_NameArgs = {
  objects: Array<Badge_Event_Name_Insert_Input>;
  on_conflict?: Maybe<Badge_Event_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Event_Name_OneArgs = {
  object: Badge_Event_Name_Insert_Input;
  on_conflict?: Maybe<Badge_Event_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_Event_OneArgs = {
  object: Badge_Event_Insert_Input;
  on_conflict?: Maybe<Badge_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Badge_OneArgs = {
  object: Badge_Insert_Input;
  on_conflict?: Maybe<Badge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_HistoryArgs = {
  objects: Array<Billing_History_Insert_Input>;
  on_conflict?: Maybe<Billing_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_History_OneArgs = {
  object: Billing_History_Insert_Input;
  on_conflict?: Maybe<Billing_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_MethodArgs = {
  objects: Array<Billing_Method_Insert_Input>;
  on_conflict?: Maybe<Billing_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Method_OneArgs = {
  object: Billing_Method_Insert_Input;
  on_conflict?: Maybe<Billing_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_StatusArgs = {
  objects: Array<Billing_Status_Insert_Input>;
  on_conflict?: Maybe<Billing_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Status_OneArgs = {
  object: Billing_Status_Insert_Input;
  on_conflict?: Maybe<Billing_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CatalogArgs = {
  objects: Array<Catalog_Insert_Input>;
  on_conflict?: Maybe<Catalog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_ItemArgs = {
  objects: Array<Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_Item_OneArgs = {
  object: Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_NameArgs = {
  objects: Array<Catalog_Name_Insert_Input>;
  on_conflict?: Maybe<Catalog_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_Name_OneArgs = {
  object: Catalog_Name_Insert_Input;
  on_conflict?: Maybe<Catalog_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Catalog_OneArgs = {
  object: Catalog_Insert_Input;
  on_conflict?: Maybe<Catalog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_StandardArgs = {
  objects: Array<Category_Standard_Insert_Input>;
  on_conflict?: Maybe<Category_Standard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Standard_OneArgs = {
  object: Category_Standard_Insert_Input;
  on_conflict?: Maybe<Category_Standard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChallengeArgs = {
  objects: Array<Challenge_Insert_Input>;
  on_conflict?: Maybe<Challenge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_Catalog_ItemArgs = {
  objects: Array<Challenge_Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Challenge_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_Catalog_Item_OneArgs = {
  object: Challenge_Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Challenge_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_DistrictArgs = {
  objects: Array<Challenge_District_Insert_Input>;
  on_conflict?: Maybe<Challenge_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_District_OneArgs = {
  object: Challenge_District_Insert_Input;
  on_conflict?: Maybe<Challenge_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_GradeArgs = {
  objects: Array<Challenge_Grade_Insert_Input>;
  on_conflict?: Maybe<Challenge_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_Grade_OneArgs = {
  object: Challenge_Grade_Insert_Input;
  on_conflict?: Maybe<Challenge_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_OneArgs = {
  object: Challenge_Insert_Input;
  on_conflict?: Maybe<Challenge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_SongArgs = {
  objects: Array<Challenge_Song_Insert_Input>;
  on_conflict?: Maybe<Challenge_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_Song_OneArgs = {
  object: Challenge_Song_Insert_Input;
  on_conflict?: Maybe<Challenge_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_StudentArgs = {
  objects: Array<Challenge_Student_Insert_Input>;
  on_conflict?: Maybe<Challenge_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Challenge_Student_OneArgs = {
  object: Challenge_Student_Insert_Input;
  on_conflict?: Maybe<Challenge_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChannelArgs = {
  objects: Array<Channel_Insert_Input>;
  on_conflict?: Maybe<Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_OneArgs = {
  object: Channel_Insert_Input;
  on_conflict?: Maybe<Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_PlaylistArgs = {
  objects: Array<Channel_Playlist_Insert_Input>;
  on_conflict?: Maybe<Channel_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_Playlist_OneArgs = {
  object: Channel_Playlist_Insert_Input;
  on_conflict?: Maybe<Channel_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClassArgs = {
  objects: Array<Class_Insert_Input>;
  on_conflict?: Maybe<Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_AssignmentArgs = {
  objects: Array<Class_Assignment_Insert_Input>;
  on_conflict?: Maybe<Class_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Assignment_OneArgs = {
  object: Class_Assignment_Insert_Input;
  on_conflict?: Maybe<Class_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Assignment_StatusArgs = {
  objects: Array<Class_Assignment_Status_Insert_Input>;
  on_conflict?: Maybe<Class_Assignment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Assignment_Status_OneArgs = {
  object: Class_Assignment_Status_Insert_Input;
  on_conflict?: Maybe<Class_Assignment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_ImagesArgs = {
  objects: Array<Class_Images_Insert_Input>;
  on_conflict?: Maybe<Class_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Images_OneArgs = {
  object: Class_Images_Insert_Input;
  on_conflict?: Maybe<Class_Images_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Lesson_PlanArgs = {
  objects: Array<Class_Lesson_Plan_Insert_Input>;
  on_conflict?: Maybe<Class_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Lesson_Plan_OneArgs = {
  object: Class_Lesson_Plan_Insert_Input;
  on_conflict?: Maybe<Class_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_ModuleArgs = {
  objects: Array<Class_Module_Insert_Input>;
  on_conflict?: Maybe<Class_Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Module_OneArgs = {
  object: Class_Module_Insert_Input;
  on_conflict?: Maybe<Class_Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_OneArgs = {
  object: Class_Insert_Input;
  on_conflict?: Maybe<Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_PlaylistArgs = {
  objects: Array<Class_Playlist_Insert_Input>;
  on_conflict?: Maybe<Class_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Playlist_OneArgs = {
  object: Class_Playlist_Insert_Input;
  on_conflict?: Maybe<Class_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_SequenceArgs = {
  objects: Array<Class_Sequence_Insert_Input>;
  on_conflict?: Maybe<Class_Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Sequence_OneArgs = {
  object: Class_Sequence_Insert_Input;
  on_conflict?: Maybe<Class_Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_SongArgs = {
  objects: Array<Class_Song_Insert_Input>;
  on_conflict?: Maybe<Class_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Song_OneArgs = {
  object: Class_Song_Insert_Input;
  on_conflict?: Maybe<Class_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_StudentArgs = {
  objects: Array<Class_Student_Insert_Input>;
  on_conflict?: Maybe<Class_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Student_ActivitiesArgs = {
  objects: Array<Class_Student_Activities_Insert_Input>;
  on_conflict?: Maybe<Class_Student_Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Student_Activities_OneArgs = {
  object: Class_Student_Activities_Insert_Input;
  on_conflict?: Maybe<Class_Student_Activities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Student_OneArgs = {
  object: Class_Student_Insert_Input;
  on_conflict?: Maybe<Class_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_TeacherArgs = {
  objects: Array<Class_Teacher_Insert_Input>;
  on_conflict?: Maybe<Class_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Teacher_OneArgs = {
  object: Class_Teacher_Insert_Input;
  on_conflict?: Maybe<Class_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_TipArgs = {
  objects: Array<Class_Tip_Insert_Input>;
  on_conflict?: Maybe<Class_Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Tip_OneArgs = {
  object: Class_Tip_Insert_Input;
  on_conflict?: Maybe<Class_Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clever_Roster_LogArgs = {
  objects: Array<Clever_Roster_Log_Insert_Input>;
  on_conflict?: Maybe<Clever_Roster_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clever_Roster_Log_OneArgs = {
  object: Clever_Roster_Log_Insert_Input;
  on_conflict?: Maybe<Clever_Roster_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clever_Roster_StatusArgs = {
  objects: Array<Clever_Roster_Status_Insert_Input>;
  on_conflict?: Maybe<Clever_Roster_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clever_Roster_Status_OneArgs = {
  object: Clever_Roster_Status_Insert_Input;
  on_conflict?: Maybe<Clever_Roster_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_UsArgs = {
  objects: Array<Contact_Us_Insert_Input>;
  on_conflict?: Maybe<Contact_Us_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Us_Camp_TeacherArgs = {
  objects: Array<Contact_Us_Camp_Teacher_Insert_Input>;
  on_conflict?: Maybe<Contact_Us_Camp_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Us_Camp_Teacher_OneArgs = {
  object: Contact_Us_Camp_Teacher_Insert_Input;
  on_conflict?: Maybe<Contact_Us_Camp_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Us_OneArgs = {
  object: Contact_Us_Insert_Input;
  on_conflict?: Maybe<Contact_Us_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Crossclear_MasterlistArgs = {
  objects: Array<Crossclear_Masterlist_Insert_Input>;
  on_conflict?: Maybe<Crossclear_Masterlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Crossclear_Masterlist_OneArgs = {
  object: Crossclear_Masterlist_Insert_Input;
  on_conflict?: Maybe<Crossclear_Masterlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DisciplineArgs = {
  objects: Array<Discipline_Insert_Input>;
  on_conflict?: Maybe<Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discipline_OneArgs = {
  object: Discipline_Insert_Input;
  on_conflict?: Maybe<Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DistrictArgs = {
  objects: Array<District_Insert_Input>;
  on_conflict?: Maybe<District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_District_AdminArgs = {
  objects: Array<District_Admin_Insert_Input>;
  on_conflict?: Maybe<District_Admin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_District_Admin_OneArgs = {
  object: District_Admin_Insert_Input;
  on_conflict?: Maybe<District_Admin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_District_OneArgs = {
  object: District_Insert_Input;
  on_conflict?: Maybe<District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_ColorArgs = {
  objects: Array<Filter_Color_Insert_Input>;
  on_conflict?: Maybe<Filter_Color_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Color_NameArgs = {
  objects: Array<Filter_Color_Name_Insert_Input>;
  on_conflict?: Maybe<Filter_Color_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Color_Name_OneArgs = {
  object: Filter_Color_Name_Insert_Input;
  on_conflict?: Maybe<Filter_Color_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Color_OneArgs = {
  object: Filter_Color_Insert_Input;
  on_conflict?: Maybe<Filter_Color_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_FrameArgs = {
  objects: Array<Filter_Frame_Insert_Input>;
  on_conflict?: Maybe<Filter_Frame_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Frame_OneArgs = {
  object: Filter_Frame_Insert_Input;
  on_conflict?: Maybe<Filter_Frame_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_StickerArgs = {
  objects: Array<Filter_Sticker_Insert_Input>;
  on_conflict?: Maybe<Filter_Sticker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filter_Sticker_OneArgs = {
  object: Filter_Sticker_Insert_Input;
  on_conflict?: Maybe<Filter_Sticker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GradeArgs = {
  objects: Array<Grade_Insert_Input>;
  on_conflict?: Maybe<Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Grade_OneArgs = {
  object: Grade_Insert_Input;
  on_conflict?: Maybe<Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Grading_SystemArgs = {
  objects: Array<Grading_System_Insert_Input>;
  on_conflict?: Maybe<Grading_System_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Grading_System_OneArgs = {
  object: Grading_System_Insert_Input;
  on_conflict?: Maybe<Grading_System_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Grading_System_TypeArgs = {
  objects: Array<Grading_System_Type_Insert_Input>;
  on_conflict?: Maybe<Grading_System_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Grading_System_Type_OneArgs = {
  object: Grading_System_Type_Insert_Input;
  on_conflict?: Maybe<Grading_System_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_NameArgs = {
  objects: Array<Integration_Name_Insert_Input>;
  on_conflict?: Maybe<Integration_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Integration_Name_OneArgs = {
  object: Integration_Name_Insert_Input;
  on_conflict?: Maybe<Integration_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invitation_StatusArgs = {
  objects: Array<Invitation_Status_Insert_Input>;
  on_conflict?: Maybe<Invitation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invitation_Status_OneArgs = {
  object: Invitation_Status_Insert_Input;
  on_conflict?: Maybe<Invitation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_KeywordArgs = {
  objects: Array<Keyword_Insert_Input>;
  on_conflict?: Maybe<Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Keyword_OneArgs = {
  object: Keyword_Insert_Input;
  on_conflict?: Maybe<Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Label_ControlArgs = {
  objects: Array<Label_Control_Insert_Input>;
  on_conflict?: Maybe<Label_Control_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Label_Control_OneArgs = {
  object: Label_Control_Insert_Input;
  on_conflict?: Maybe<Label_Control_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LabelsArgs = {
  objects: Array<Labels_Insert_Input>;
  on_conflict?: Maybe<Labels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Labels_OneArgs = {
  object: Labels_Insert_Input;
  on_conflict?: Maybe<Labels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_PlanArgs = {
  objects: Array<Lesson_Plan_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_ArticleArgs = {
  objects: Array<Lesson_Plan_Article_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Article_OneArgs = {
  object: Lesson_Plan_Article_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_AssetArgs = {
  objects: Array<Lesson_Plan_Asset_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Asset_OneArgs = {
  object: Lesson_Plan_Asset_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Catalog_ItemArgs = {
  objects: Array<Lesson_Plan_Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Catalog_Item_OneArgs = {
  object: Lesson_Plan_Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_DisciplineArgs = {
  objects: Array<Lesson_Plan_Discipline_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Discipline_OneArgs = {
  object: Lesson_Plan_Discipline_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_GradeArgs = {
  objects: Array<Lesson_Plan_Grade_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Grade_OneArgs = {
  object: Lesson_Plan_Grade_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_KeywordArgs = {
  objects: Array<Lesson_Plan_Keyword_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Keyword_OneArgs = {
  object: Lesson_Plan_Keyword_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Musical_SkillArgs = {
  objects: Array<Lesson_Plan_Musical_Skill_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Musical_Skill_OneArgs = {
  object: Lesson_Plan_Musical_Skill_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Non_Musical_SkillArgs = {
  objects: Array<Lesson_Plan_Non_Musical_Skill_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Non_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Non_Musical_Skill_OneArgs = {
  object: Lesson_Plan_Non_Musical_Skill_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Non_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_OneArgs = {
  object: Lesson_Plan_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Skill_LevelArgs = {
  objects: Array<Lesson_Plan_Skill_Level_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Skill_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Skill_Level_OneArgs = {
  object: Lesson_Plan_Skill_Level_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Skill_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_SongArgs = {
  objects: Array<Lesson_Plan_Song_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Song_OneArgs = {
  object: Lesson_Plan_Song_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_StandardArgs = {
  objects: Array<Lesson_Plan_Standard_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Standard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Standard_OneArgs = {
  object: Lesson_Plan_Standard_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Standard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_TipArgs = {
  objects: Array<Lesson_Plan_Tip_Insert_Input>;
  on_conflict?: Maybe<Lesson_Plan_Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Plan_Tip_OneArgs = {
  object: Lesson_Plan_Tip_Insert_Input;
  on_conflict?: Maybe<Lesson_Plan_Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_ContentArgs = {
  objects: Array<Lockable_Content_Insert_Input>;
  on_conflict?: Maybe<Lockable_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_ClassArgs = {
  objects: Array<Lockable_Content_Class_Insert_Input>;
  on_conflict?: Maybe<Lockable_Content_Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_Class_OneArgs = {
  object: Lockable_Content_Class_Insert_Input;
  on_conflict?: Maybe<Lockable_Content_Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_DistrictArgs = {
  objects: Array<Lockable_Content_District_Insert_Input>;
  on_conflict?: Maybe<Lockable_Content_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_District_OneArgs = {
  object: Lockable_Content_District_Insert_Input;
  on_conflict?: Maybe<Lockable_Content_District_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_OneArgs = {
  object: Lockable_Content_Insert_Input;
  on_conflict?: Maybe<Lockable_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_SchoolArgs = {
  objects: Array<Lockable_Content_School_Insert_Input>;
  on_conflict?: Maybe<Lockable_Content_School_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_School_OneArgs = {
  object: Lockable_Content_School_Insert_Input;
  on_conflict?: Maybe<Lockable_Content_School_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_TeacherArgs = {
  objects: Array<Lockable_Content_Teacher_Insert_Input>;
  on_conflict?: Maybe<Lockable_Content_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lockable_Content_Teacher_OneArgs = {
  object: Lockable_Content_Teacher_Insert_Input;
  on_conflict?: Maybe<Lockable_Content_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Maturity_LevelArgs = {
  objects: Array<Maturity_Level_Insert_Input>;
  on_conflict?: Maybe<Maturity_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Maturity_Level_OneArgs = {
  object: Maturity_Level_Insert_Input;
  on_conflict?: Maybe<Maturity_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_TypeArgs = {
  objects: Array<Membership_Type_Insert_Input>;
  on_conflict?: Maybe<Membership_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Membership_Type_OneArgs = {
  object: Membership_Type_Insert_Input;
  on_conflict?: Maybe<Membership_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MidiArgs = {
  objects: Array<Midi_Insert_Input>;
  on_conflict?: Maybe<Midi_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Midi_OneArgs = {
  object: Midi_Insert_Input;
  on_conflict?: Maybe<Midi_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModuleArgs = {
  objects: Array<Module_Insert_Input>;
  on_conflict?: Maybe<Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Catalog_ItemArgs = {
  objects: Array<Module_Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Module_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Catalog_Item_OneArgs = {
  object: Module_Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Module_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_GradeArgs = {
  objects: Array<Module_Grade_Insert_Input>;
  on_conflict?: Maybe<Module_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Grade_OneArgs = {
  object: Module_Grade_Insert_Input;
  on_conflict?: Maybe<Module_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Lesson_PlanArgs = {
  objects: Array<Module_Lesson_Plan_Insert_Input>;
  on_conflict?: Maybe<Module_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_Lesson_Plan_OneArgs = {
  object: Module_Lesson_Plan_Insert_Input;
  on_conflict?: Maybe<Module_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Module_OneArgs = {
  object: Module_Insert_Input;
  on_conflict?: Maybe<Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Musical_SkillArgs = {
  objects: Array<Musical_Skill_Insert_Input>;
  on_conflict?: Maybe<Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Musical_Skill_OneArgs = {
  object: Musical_Skill_Insert_Input;
  on_conflict?: Maybe<Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Non_Musical_SkillArgs = {
  objects: Array<Non_Musical_Skill_Insert_Input>;
  on_conflict?: Maybe<Non_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Non_Musical_Skill_OneArgs = {
  object: Non_Musical_Skill_Insert_Input;
  on_conflict?: Maybe<Non_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Non_Musical_Skill_TypeArgs = {
  objects: Array<Non_Musical_Skill_Type_Insert_Input>;
  on_conflict?: Maybe<Non_Musical_Skill_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Non_Musical_Skill_Type_OneArgs = {
  object: Non_Musical_Skill_Type_Insert_Input;
  on_conflict?: Maybe<Non_Musical_Skill_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrderArgs = {
  objects: Array<Order_Insert_Input>;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_OneArgs = {
  object: Order_Insert_Input;
  on_conflict?: Maybe<Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_StatusArgs = {
  objects: Array<Order_Status_Insert_Input>;
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Status_OneArgs = {
  object: Order_Status_Insert_Input;
  on_conflict?: Maybe<Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parent_InvitationArgs = {
  objects: Array<Parent_Invitation_Insert_Input>;
  on_conflict?: Maybe<Parent_Invitation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Parent_Invitation_OneArgs = {
  object: Parent_Invitation_Insert_Input;
  on_conflict?: Maybe<Parent_Invitation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PartnerArgs = {
  objects: Array<Partner_Insert_Input>;
  on_conflict?: Maybe<Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_ModuleArgs = {
  objects: Array<Partner_Module_Insert_Input>;
  on_conflict?: Maybe<Partner_Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Module_OneArgs = {
  object: Partner_Module_Insert_Input;
  on_conflict?: Maybe<Partner_Module_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_OneArgs = {
  object: Partner_Insert_Input;
  on_conflict?: Maybe<Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_PlaylistArgs = {
  objects: Array<Partner_Playlist_Insert_Input>;
  on_conflict?: Maybe<Partner_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Partner_Playlist_OneArgs = {
  object: Partner_Playlist_Insert_Input;
  on_conflict?: Maybe<Partner_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PlaylistArgs = {
  objects: Array<Playlist_Insert_Input>;
  on_conflict?: Maybe<Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Playlist_OneArgs = {
  object: Playlist_Insert_Input;
  on_conflict?: Maybe<Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_TypeArgs = {
  objects: Array<Price_Type_Insert_Input>;
  on_conflict?: Maybe<Price_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Type_OneArgs = {
  object: Price_Type_Insert_Input;
  on_conflict?: Maybe<Price_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrivacyArgs = {
  objects: Array<Privacy_Insert_Input>;
  on_conflict?: Maybe<Privacy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Privacy_OneArgs = {
  object: Privacy_Insert_Input;
  on_conflict?: Maybe<Privacy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_NameArgs = {
  objects: Array<Product_Name_Insert_Input>;
  on_conflict?: Maybe<Product_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Name_OneArgs = {
  object: Product_Name_Insert_Input;
  on_conflict?: Maybe<Product_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PublisherArgs = {
  objects: Array<Publisher_Insert_Input>;
  on_conflict?: Maybe<Publisher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Publisher_ControlArgs = {
  objects: Array<Publisher_Control_Insert_Input>;
  on_conflict?: Maybe<Publisher_Control_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Publisher_Control_OneArgs = {
  object: Publisher_Control_Insert_Input;
  on_conflict?: Maybe<Publisher_Control_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Publisher_OneArgs = {
  object: Publisher_Insert_Input;
  on_conflict?: Maybe<Publisher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReactionArgs = {
  objects: Array<Reaction_Insert_Input>;
  on_conflict?: Maybe<Reaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reaction_OneArgs = {
  object: Reaction_Insert_Input;
  on_conflict?: Maybe<Reaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reset_PasswordArgs = {
  objects: Array<Reset_Password_Insert_Input>;
  on_conflict?: Maybe<Reset_Password_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reset_Password_OneArgs = {
  object: Reset_Password_Insert_Input;
  on_conflict?: Maybe<Reset_Password_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_NameArgs = {
  objects: Array<Role_Name_Insert_Input>;
  on_conflict?: Maybe<Role_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Name_OneArgs = {
  object: Role_Name_Insert_Input;
  on_conflict?: Maybe<Role_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Royalty_LogArgs = {
  objects: Array<Royalty_Log_Insert_Input>;
  on_conflict?: Maybe<Royalty_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Royalty_Log_OneArgs = {
  object: Royalty_Log_Insert_Input;
  on_conflict?: Maybe<Royalty_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScaleArgs = {
  objects: Array<Scale_Insert_Input>;
  on_conflict?: Maybe<Scale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scale_OneArgs = {
  object: Scale_Insert_Input;
  on_conflict?: Maybe<Scale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SchoolArgs = {
  objects: Array<School_Insert_Input>;
  on_conflict?: Maybe<School_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_AdminArgs = {
  objects: Array<School_Admin_Insert_Input>;
  on_conflict?: Maybe<School_Admin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_Admin_OneArgs = {
  object: School_Admin_Insert_Input;
  on_conflict?: Maybe<School_Admin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_OneArgs = {
  object: School_Insert_Input;
  on_conflict?: Maybe<School_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_TeacherArgs = {
  objects: Array<School_Teacher_Insert_Input>;
  on_conflict?: Maybe<School_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_School_Teacher_OneArgs = {
  object: School_Teacher_Insert_Input;
  on_conflict?: Maybe<School_Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screen_TypeArgs = {
  objects: Array<Screen_Type_Insert_Input>;
  on_conflict?: Maybe<Screen_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screen_Type_OneArgs = {
  object: Screen_Type_Insert_Input;
  on_conflict?: Maybe<Screen_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SequenceArgs = {
  objects: Array<Sequence_Insert_Input>;
  on_conflict?: Maybe<Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_KeywordArgs = {
  objects: Array<Sequence_Keyword_Insert_Input>;
  on_conflict?: Maybe<Sequence_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Keyword_OneArgs = {
  object: Sequence_Keyword_Insert_Input;
  on_conflict?: Maybe<Sequence_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Lesson_PlanArgs = {
  objects: Array<Sequence_Lesson_Plan_Insert_Input>;
  on_conflict?: Maybe<Sequence_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Lesson_Plan_OneArgs = {
  object: Sequence_Lesson_Plan_Insert_Input;
  on_conflict?: Maybe<Sequence_Lesson_Plan_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_OneArgs = {
  object: Sequence_Insert_Input;
  on_conflict?: Maybe<Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_TopicArgs = {
  objects: Array<Sequence_Topic_Insert_Input>;
  on_conflict?: Maybe<Sequence_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_Catalog_ItemArgs = {
  objects: Array<Sequence_Topic_Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Sequence_Topic_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_Catalog_Item_OneArgs = {
  object: Sequence_Topic_Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Sequence_Topic_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_KeywordArgs = {
  objects: Array<Sequence_Topic_Keyword_Insert_Input>;
  on_conflict?: Maybe<Sequence_Topic_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_Keyword_OneArgs = {
  object: Sequence_Topic_Keyword_Insert_Input;
  on_conflict?: Maybe<Sequence_Topic_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_OneArgs = {
  object: Sequence_Topic_Insert_Input;
  on_conflict?: Maybe<Sequence_Topic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_SequenceArgs = {
  objects: Array<Sequence_Topic_Sequence_Insert_Input>;
  on_conflict?: Maybe<Sequence_Topic_Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sequence_Topic_Sequence_OneArgs = {
  object: Sequence_Topic_Sequence_Insert_Input;
  on_conflict?: Maybe<Sequence_Topic_Sequence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skill_LevelArgs = {
  objects: Array<Skill_Level_Insert_Input>;
  on_conflict?: Maybe<Skill_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Skill_Level_OneArgs = {
  object: Skill_Level_Insert_Input;
  on_conflict?: Maybe<Skill_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SongArgs = {
  objects: Array<Song_Insert_Input>;
  on_conflict?: Maybe<Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_Catalog_ItemArgs = {
  objects: Array<Song_Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Song_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_Catalog_Item_OneArgs = {
  object: Song_Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Song_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_GradeArgs = {
  objects: Array<Song_Grade_Insert_Input>;
  on_conflict?: Maybe<Song_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_Grade_OneArgs = {
  object: Song_Grade_Insert_Input;
  on_conflict?: Maybe<Song_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_KeywordArgs = {
  objects: Array<Song_Keyword_Insert_Input>;
  on_conflict?: Maybe<Song_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_Keyword_OneArgs = {
  object: Song_Keyword_Insert_Input;
  on_conflict?: Maybe<Song_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Song_OneArgs = {
  object: Song_Insert_Input;
  on_conflict?: Maybe<Song_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StandardArgs = {
  objects: Array<Standard_Insert_Input>;
  on_conflict?: Maybe<Standard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_OneArgs = {
  object: Standard_Insert_Input;
  on_conflict?: Maybe<Standard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_OptionArgs = {
  objects: Array<Standard_Option_Insert_Input>;
  on_conflict?: Maybe<Standard_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_Option_OneArgs = {
  object: Standard_Option_Insert_Input;
  on_conflict?: Maybe<Standard_Option_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_SubcategoryArgs = {
  objects: Array<Standard_Subcategory_Insert_Input>;
  on_conflict?: Maybe<Standard_Subcategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_Subcategory_OneArgs = {
  object: Standard_Subcategory_Insert_Input;
  on_conflict?: Maybe<Standard_Subcategory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StudentArgs = {
  objects: Array<Student_Insert_Input>;
  on_conflict?: Maybe<Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_ActivityArgs = {
  objects: Array<Student_Activity_Insert_Input>;
  on_conflict?: Maybe<Student_Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Activity_OneArgs = {
  object: Student_Activity_Insert_Input;
  on_conflict?: Maybe<Student_Activity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Activity_TypeArgs = {
  objects: Array<Student_Activity_Type_Insert_Input>;
  on_conflict?: Maybe<Student_Activity_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Activity_Type_OneArgs = {
  object: Student_Activity_Type_Insert_Input;
  on_conflict?: Maybe<Student_Activity_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_BadgeArgs = {
  objects: Array<Student_Badge_Insert_Input>;
  on_conflict?: Maybe<Student_Badge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Badge_EventArgs = {
  objects: Array<Student_Badge_Event_Insert_Input>;
  on_conflict?: Maybe<Student_Badge_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Badge_Event_OneArgs = {
  object: Student_Badge_Event_Insert_Input;
  on_conflict?: Maybe<Student_Badge_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Badge_OneArgs = {
  object: Student_Badge_Insert_Input;
  on_conflict?: Maybe<Student_Badge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_BannerArgs = {
  objects: Array<Student_Banner_Insert_Input>;
  on_conflict?: Maybe<Student_Banner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Banner_OneArgs = {
  object: Student_Banner_Insert_Input;
  on_conflict?: Maybe<Student_Banner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_GoalArgs = {
  objects: Array<Student_Goal_Insert_Input>;
  on_conflict?: Maybe<Student_Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Goal_OneArgs = {
  object: Student_Goal_Insert_Input;
  on_conflict?: Maybe<Student_Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_IntegrationArgs = {
  objects: Array<Student_Integration_Insert_Input>;
  on_conflict?: Maybe<Student_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Integration_OneArgs = {
  object: Student_Integration_Insert_Input;
  on_conflict?: Maybe<Student_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Lesson_Plan_FavoriteArgs = {
  objects: Array<Student_Lesson_Plan_Favorite_Insert_Input>;
  on_conflict?: Maybe<Student_Lesson_Plan_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Lesson_Plan_Favorite_OneArgs = {
  object: Student_Lesson_Plan_Favorite_Insert_Input;
  on_conflict?: Maybe<Student_Lesson_Plan_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_OneArgs = {
  object: Student_Insert_Input;
  on_conflict?: Maybe<Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Productivity_DailyArgs = {
  objects: Array<Student_Productivity_Daily_Insert_Input>;
  on_conflict?: Maybe<Student_Productivity_Daily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Productivity_Daily_OneArgs = {
  object: Student_Productivity_Daily_Insert_Input;
  on_conflict?: Maybe<Student_Productivity_Daily_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Productivity_MonthlyArgs = {
  objects: Array<Student_Productivity_Monthly_Insert_Input>;
  on_conflict?: Maybe<Student_Productivity_Monthly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Productivity_Monthly_OneArgs = {
  object: Student_Productivity_Monthly_Insert_Input;
  on_conflict?: Maybe<Student_Productivity_Monthly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Productivity_YearlyArgs = {
  objects: Array<Student_Productivity_Yearly_Insert_Input>;
  on_conflict?: Maybe<Student_Productivity_Yearly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Productivity_Yearly_OneArgs = {
  object: Student_Productivity_Yearly_Insert_Input;
  on_conflict?: Maybe<Student_Productivity_Yearly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_SettingArgs = {
  objects: Array<Student_Setting_Insert_Input>;
  on_conflict?: Maybe<Student_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Setting_OneArgs = {
  object: Student_Setting_Insert_Input;
  on_conflict?: Maybe<Student_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Song_FavoriteArgs = {
  objects: Array<Student_Song_Favorite_Insert_Input>;
  on_conflict?: Maybe<Student_Song_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Song_Favorite_OneArgs = {
  object: Student_Song_Favorite_Insert_Input;
  on_conflict?: Maybe<Student_Song_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Tip_FavoriteArgs = {
  objects: Array<Student_Tip_Favorite_Insert_Input>;
  on_conflict?: Maybe<Student_Tip_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Student_Tip_Favorite_OneArgs = {
  object: Student_Tip_Favorite_Insert_Input;
  on_conflict?: Maybe<Student_Tip_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubmissionArgs = {
  objects: Array<Submission_Insert_Input>;
  on_conflict?: Maybe<Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_AssetArgs = {
  objects: Array<Submission_Asset_Insert_Input>;
  on_conflict?: Maybe<Submission_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_Asset_OneArgs = {
  object: Submission_Asset_Insert_Input;
  on_conflict?: Maybe<Submission_Asset_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_OneArgs = {
  object: Submission_Insert_Input;
  on_conflict?: Maybe<Submission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_StateArgs = {
  objects: Array<Submission_State_Insert_Input>;
  on_conflict?: Maybe<Submission_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_State_OneArgs = {
  object: Submission_State_Insert_Input;
  on_conflict?: Maybe<Submission_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_StudentArgs = {
  objects: Array<Submission_Student_Insert_Input>;
  on_conflict?: Maybe<Submission_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_Student_OneArgs = {
  object: Submission_Student_Insert_Input;
  on_conflict?: Maybe<Submission_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_WurrlyArgs = {
  objects: Array<Submission_Wurrly_Insert_Input>;
  on_conflict?: Maybe<Submission_Wurrly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_Wurrly_OneArgs = {
  object: Submission_Wurrly_Insert_Input;
  on_conflict?: Maybe<Submission_Wurrly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_Wurrly_StateArgs = {
  objects: Array<Submission_Wurrly_State_Insert_Input>;
  on_conflict?: Maybe<Submission_Wurrly_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Submission_Wurrly_State_OneArgs = {
  object: Submission_Wurrly_State_Insert_Input;
  on_conflict?: Maybe<Submission_Wurrly_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeacherArgs = {
  objects: Array<Teacher_Insert_Input>;
  on_conflict?: Maybe<Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_IntegrationArgs = {
  objects: Array<Teacher_Integration_Insert_Input>;
  on_conflict?: Maybe<Teacher_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Integration_OneArgs = {
  object: Teacher_Integration_Insert_Input;
  on_conflict?: Maybe<Teacher_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Lesson_Plan_FavoriteArgs = {
  objects: Array<Teacher_Lesson_Plan_Favorite_Insert_Input>;
  on_conflict?: Maybe<Teacher_Lesson_Plan_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Lesson_Plan_Favorite_OneArgs = {
  object: Teacher_Lesson_Plan_Favorite_Insert_Input;
  on_conflict?: Maybe<Teacher_Lesson_Plan_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_OneArgs = {
  object: Teacher_Insert_Input;
  on_conflict?: Maybe<Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_SettingArgs = {
  objects: Array<Teacher_Setting_Insert_Input>;
  on_conflict?: Maybe<Teacher_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Setting_OneArgs = {
  object: Teacher_Setting_Insert_Input;
  on_conflict?: Maybe<Teacher_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Song_FavoriteArgs = {
  objects: Array<Teacher_Song_Favorite_Insert_Input>;
  on_conflict?: Maybe<Teacher_Song_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Song_Favorite_OneArgs = {
  object: Teacher_Song_Favorite_Insert_Input;
  on_conflict?: Maybe<Teacher_Song_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_SubscriptionArgs = {
  objects: Array<Teacher_Subscription_Insert_Input>;
  on_conflict?: Maybe<Teacher_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Subscription_OneArgs = {
  object: Teacher_Subscription_Insert_Input;
  on_conflict?: Maybe<Teacher_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Tip_FavoriteArgs = {
  objects: Array<Teacher_Tip_Favorite_Insert_Input>;
  on_conflict?: Maybe<Teacher_Tip_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Tip_Favorite_OneArgs = {
  object: Teacher_Tip_Favorite_Insert_Input;
  on_conflict?: Maybe<Teacher_Tip_Favorite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Wurrly_CommentArgs = {
  objects: Array<Teacher_Wurrly_Comment_Insert_Input>;
  on_conflict?: Maybe<Teacher_Wurrly_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Wurrly_Comment_OneArgs = {
  object: Teacher_Wurrly_Comment_Insert_Input;
  on_conflict?: Maybe<Teacher_Wurrly_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TipArgs = {
  objects: Array<Tip_Insert_Input>;
  on_conflict?: Maybe<Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_AgeArgs = {
  objects: Array<Tip_Age_Insert_Input>;
  on_conflict?: Maybe<Tip_Age_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Age_OneArgs = {
  object: Tip_Age_Insert_Input;
  on_conflict?: Maybe<Tip_Age_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Catalog_ItemArgs = {
  objects: Array<Tip_Catalog_Item_Insert_Input>;
  on_conflict?: Maybe<Tip_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Catalog_Item_OneArgs = {
  object: Tip_Catalog_Item_Insert_Input;
  on_conflict?: Maybe<Tip_Catalog_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_GradeArgs = {
  objects: Array<Tip_Grade_Insert_Input>;
  on_conflict?: Maybe<Tip_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Grade_OneArgs = {
  object: Tip_Grade_Insert_Input;
  on_conflict?: Maybe<Tip_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_KeywordArgs = {
  objects: Array<Tip_Keyword_Insert_Input>;
  on_conflict?: Maybe<Tip_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Keyword_OneArgs = {
  object: Tip_Keyword_Insert_Input;
  on_conflict?: Maybe<Tip_Keyword_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Musical_SkillArgs = {
  objects: Array<Tip_Musical_Skill_Insert_Input>;
  on_conflict?: Maybe<Tip_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Musical_Skill_OneArgs = {
  object: Tip_Musical_Skill_Insert_Input;
  on_conflict?: Maybe<Tip_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Non_Musical_SkillArgs = {
  objects: Array<Tip_Non_Musical_Skill_Insert_Input>;
  on_conflict?: Maybe<Tip_Non_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Non_Musical_Skill_OneArgs = {
  object: Tip_Non_Musical_Skill_Insert_Input;
  on_conflict?: Maybe<Tip_Non_Musical_Skill_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_OneArgs = {
  object: Tip_Insert_Input;
  on_conflict?: Maybe<Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_PlaylistArgs = {
  objects: Array<Tip_Playlist_Insert_Input>;
  on_conflict?: Maybe<Tip_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Playlist_OneArgs = {
  object: Tip_Playlist_Insert_Input;
  on_conflict?: Maybe<Tip_Playlist_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TrackArgs = {
  objects: Array<Track_Insert_Input>;
  on_conflict?: Maybe<Track_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Track_OneArgs = {
  object: Track_Insert_Input;
  on_conflict?: Maybe<Track_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Track_TypeArgs = {
  objects: Array<Track_Type_Insert_Input>;
  on_conflict?: Maybe<Track_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Track_Type_OneArgs = {
  object: Track_Type_Insert_Input;
  on_conflict?: Maybe<Track_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transpose_IndexArgs = {
  objects: Array<Transpose_Index_Insert_Input>;
  on_conflict?: Maybe<Transpose_Index_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transpose_Index_OneArgs = {
  object: Transpose_Index_Insert_Input;
  on_conflict?: Maybe<Transpose_Index_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Most_Attended_ClassesArgs = {
  objects: Array<Utils_Most_Attended_Classes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Most_Attended_Classes_OneArgs = {
  object: Utils_Most_Attended_Classes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Most_Attended_SubjectsArgs = {
  objects: Array<Utils_Most_Attended_Subjects_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Most_Attended_Subjects_OneArgs = {
  object: Utils_Most_Attended_Subjects_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Teacher_Subscriptions_Per_MonthArgs = {
  objects: Array<Utils_Teacher_Subscriptions_Per_Month_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Teacher_Subscriptions_Per_Month_OneArgs = {
  object: Utils_Teacher_Subscriptions_Per_Month_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Users_Per_MonthArgs = {
  objects: Array<Utils_Users_Per_Month_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Utils_Users_Per_Month_OneArgs = {
  object: Utils_Users_Per_Month_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VisibilityArgs = {
  objects: Array<Visibility_Insert_Input>;
  on_conflict?: Maybe<Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Visibility_OneArgs = {
  object: Visibility_Insert_Input;
  on_conflict?: Maybe<Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WurrlyArgs = {
  objects: Array<Wurrly_Insert_Input>;
  on_conflict?: Maybe<Wurrly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_FilterArgs = {
  objects: Array<Wurrly_Filter_Insert_Input>;
  on_conflict?: Maybe<Wurrly_Filter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Filter_OneArgs = {
  object: Wurrly_Filter_Insert_Input;
  on_conflict?: Maybe<Wurrly_Filter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Filter_StickerArgs = {
  objects: Array<Wurrly_Filter_Sticker_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Filter_Sticker_OneArgs = {
  object: Wurrly_Filter_Sticker_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_OneArgs = {
  object: Wurrly_Insert_Input;
  on_conflict?: Maybe<Wurrly_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Processing_StatusArgs = {
  objects: Array<Wurrly_Processing_Status_Insert_Input>;
  on_conflict?: Maybe<Wurrly_Processing_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Processing_Status_OneArgs = {
  object: Wurrly_Processing_Status_Insert_Input;
  on_conflict?: Maybe<Wurrly_Processing_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_ReactionArgs = {
  objects: Array<Wurrly_Reaction_Insert_Input>;
  on_conflict?: Maybe<Wurrly_Reaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Reaction_OneArgs = {
  object: Wurrly_Reaction_Insert_Input;
  on_conflict?: Maybe<Wurrly_Reaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_RoleArgs = {
  objects: Array<Wurrly_Role_Insert_Input>;
  on_conflict?: Maybe<Wurrly_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Role_OneArgs = {
  object: Wurrly_Role_Insert_Input;
  on_conflict?: Maybe<Wurrly_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_TypeArgs = {
  objects: Array<Wurrly_Type_Insert_Input>;
  on_conflict?: Maybe<Wurrly_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wurrly_Type_OneArgs = {
  object: Wurrly_Type_Insert_Input;
  on_conflict?: Maybe<Wurrly_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInviteAdminArgs = {
  filter: InviteAdminFilter;
};


/** mutation root */
export type Mutation_RootInviteTeacherArgs = {
  filter: InviteTeacherFilter;
};


/** mutation root */
export type Mutation_RootMidiToJsonArgs = {
  filter?: Maybe<MidiToJsonFilter>;
};


/** mutation root */
export type Mutation_RootPurgeS3TempArgs = {
  filter?: Maybe<PurgeS3TempFilter>;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  filter?: Maybe<ResetPasswordFilter>;
};


/** mutation root */
export type Mutation_RootRoastCleverClassesArgs = {
  filter?: Maybe<RoastCleverClassesFilter>;
};


/** mutation root */
export type Mutation_RootRosterCanvasClassesArgs = {
  set: RosterCanvasClassesSet;
};


/** mutation root */
export type Mutation_RootRosterClassLinkClassesArgs = {
  set: RosterClassLinkClassesSet;
};


/** mutation root */
export type Mutation_RootSchoolAddOrUpdateChannelsAndPlaylistsArgs = {
  set?: Maybe<SchoolAddOrUpdateChannelsAndPlaylistsSet>;
};


/** mutation root */
export type Mutation_RootSchoolAddOrUpdatePartnersAndModulesArgs = {
  set?: Maybe<SchoolAddOrUpdatePartnersAndModulesSet>;
};


/** mutation root */
export type Mutation_RootSendInvitationArgs = {
  filter: SendInvitationFilter;
};


/** mutation root */
export type Mutation_RootSendMailArgs = {
  filter?: Maybe<SendMailFilter>;
};


/** mutation root */
export type Mutation_RootSendMultipleMailArgs = {
  filter: SendMultipleMailFilter;
};


/** mutation root */
export type Mutation_RootStudentActivationArgs = {
  filter: StudentActivationFilter;
};


/** mutation root */
export type Mutation_RootSyncPricesArgs = {
  set?: Maybe<SyncPricesSet>;
};


/** mutation root */
export type Mutation_RootTeacherSignupArgs = {
  filter?: Maybe<TeacherSignupFilter>;
};


/** mutation root */
export type Mutation_RootTeacherSignupIntegrationArgs = {
  filter?: Maybe<SignupTeacherIntegrationFilter>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ActivationArgs = {
  _inc?: Maybe<Account_Activation_Inc_Input>;
  _set?: Maybe<Account_Activation_Set_Input>;
  where: Account_Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Activation_By_PkArgs = {
  _inc?: Maybe<Account_Activation_Inc_Input>;
  _set?: Maybe<Account_Activation_Set_Input>;
  pk_columns: Account_Activation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AdminArgs = {
  _inc?: Maybe<Admin_Inc_Input>;
  _set?: Maybe<Admin_Set_Input>;
  where: Admin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_By_PkArgs = {
  _inc?: Maybe<Admin_Inc_Input>;
  _set?: Maybe<Admin_Set_Input>;
  pk_columns: Admin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppArgs = {
  _inc?: Maybe<App_Inc_Input>;
  _set?: Maybe<App_Set_Input>;
  where: App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_By_PkArgs = {
  _inc?: Maybe<App_Inc_Input>;
  _set?: Maybe<App_Set_Input>;
  pk_columns: App_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ArtistArgs = {
  _append?: Maybe<Artist_Append_Input>;
  _delete_at_path?: Maybe<Artist_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artist_Delete_Elem_Input>;
  _delete_key?: Maybe<Artist_Delete_Key_Input>;
  _inc?: Maybe<Artist_Inc_Input>;
  _prepend?: Maybe<Artist_Prepend_Input>;
  _set?: Maybe<Artist_Set_Input>;
  where: Artist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_By_PkArgs = {
  _append?: Maybe<Artist_Append_Input>;
  _delete_at_path?: Maybe<Artist_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artist_Delete_Elem_Input>;
  _delete_key?: Maybe<Artist_Delete_Key_Input>;
  _inc?: Maybe<Artist_Inc_Input>;
  _prepend?: Maybe<Artist_Prepend_Input>;
  _set?: Maybe<Artist_Set_Input>;
  pk_columns: Artist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AssetArgs = {
  _inc?: Maybe<Asset_Inc_Input>;
  _set?: Maybe<Asset_Set_Input>;
  where: Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Asset_By_PkArgs = {
  _inc?: Maybe<Asset_Inc_Input>;
  _set?: Maybe<Asset_Set_Input>;
  pk_columns: Asset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AssignmentArgs = {
  _inc?: Maybe<Assignment_Inc_Input>;
  _set?: Maybe<Assignment_Set_Input>;
  where: Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_AssetArgs = {
  _inc?: Maybe<Assignment_Asset_Inc_Input>;
  _set?: Maybe<Assignment_Asset_Set_Input>;
  where: Assignment_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_Asset_By_PkArgs = {
  _inc?: Maybe<Assignment_Asset_Inc_Input>;
  _set?: Maybe<Assignment_Asset_Set_Input>;
  pk_columns: Assignment_Asset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_By_PkArgs = {
  _inc?: Maybe<Assignment_Inc_Input>;
  _set?: Maybe<Assignment_Set_Input>;
  pk_columns: Assignment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_Lesson_PlanArgs = {
  _inc?: Maybe<Assignment_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Assignment_Lesson_Plan_Set_Input>;
  where: Assignment_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_Lesson_Plan_By_PkArgs = {
  _inc?: Maybe<Assignment_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Assignment_Lesson_Plan_Set_Input>;
  pk_columns: Assignment_Lesson_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_SongArgs = {
  _inc?: Maybe<Assignment_Song_Inc_Input>;
  _set?: Maybe<Assignment_Song_Set_Input>;
  where: Assignment_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_Song_By_PkArgs = {
  _inc?: Maybe<Assignment_Song_Inc_Input>;
  _set?: Maybe<Assignment_Song_Set_Input>;
  pk_columns: Assignment_Song_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_TipArgs = {
  _inc?: Maybe<Assignment_Tip_Inc_Input>;
  _set?: Maybe<Assignment_Tip_Set_Input>;
  where: Assignment_Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_Tip_By_PkArgs = {
  _inc?: Maybe<Assignment_Tip_Inc_Input>;
  _set?: Maybe<Assignment_Tip_Set_Input>;
  pk_columns: Assignment_Tip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AvailableArgs = {
  _inc?: Maybe<Available_Inc_Input>;
  _set?: Maybe<Available_Set_Input>;
  where: Available_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Available_By_PkArgs = {
  _inc?: Maybe<Available_Inc_Input>;
  _set?: Maybe<Available_Set_Input>;
  pk_columns: Available_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Awarded_Badge_EventArgs = {
  _inc?: Maybe<Awarded_Badge_Event_Inc_Input>;
  _set?: Maybe<Awarded_Badge_Event_Set_Input>;
  where: Awarded_Badge_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Awarded_Badge_Event_By_PkArgs = {
  _inc?: Maybe<Awarded_Badge_Event_Inc_Input>;
  _set?: Maybe<Awarded_Badge_Event_Set_Input>;
  pk_columns: Awarded_Badge_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BadgeArgs = {
  _inc?: Maybe<Badge_Inc_Input>;
  _set?: Maybe<Badge_Set_Input>;
  where: Badge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_By_PkArgs = {
  _inc?: Maybe<Badge_Inc_Input>;
  _set?: Maybe<Badge_Set_Input>;
  pk_columns: Badge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_EventArgs = {
  _inc?: Maybe<Badge_Event_Inc_Input>;
  _set?: Maybe<Badge_Event_Set_Input>;
  where: Badge_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Event_By_PkArgs = {
  _inc?: Maybe<Badge_Event_Inc_Input>;
  _set?: Maybe<Badge_Event_Set_Input>;
  pk_columns: Badge_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Event_NameArgs = {
  _set?: Maybe<Badge_Event_Name_Set_Input>;
  where: Badge_Event_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Badge_Event_Name_By_PkArgs = {
  _set?: Maybe<Badge_Event_Name_Set_Input>;
  pk_columns: Badge_Event_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_HistoryArgs = {
  _inc?: Maybe<Billing_History_Inc_Input>;
  _set?: Maybe<Billing_History_Set_Input>;
  where: Billing_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_History_By_PkArgs = {
  _inc?: Maybe<Billing_History_Inc_Input>;
  _set?: Maybe<Billing_History_Set_Input>;
  pk_columns: Billing_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_MethodArgs = {
  _set?: Maybe<Billing_Method_Set_Input>;
  where: Billing_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Method_By_PkArgs = {
  _set?: Maybe<Billing_Method_Set_Input>;
  pk_columns: Billing_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_StatusArgs = {
  _set?: Maybe<Billing_Status_Set_Input>;
  where: Billing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Status_By_PkArgs = {
  _set?: Maybe<Billing_Status_Set_Input>;
  pk_columns: Billing_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CatalogArgs = {
  _inc?: Maybe<Catalog_Inc_Input>;
  _set?: Maybe<Catalog_Set_Input>;
  where: Catalog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_By_PkArgs = {
  _inc?: Maybe<Catalog_Inc_Input>;
  _set?: Maybe<Catalog_Set_Input>;
  pk_columns: Catalog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_ItemArgs = {
  _inc?: Maybe<Catalog_Item_Inc_Input>;
  _set?: Maybe<Catalog_Item_Set_Input>;
  where: Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Catalog_Item_Inc_Input>;
  _set?: Maybe<Catalog_Item_Set_Input>;
  pk_columns: Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_NameArgs = {
  _set?: Maybe<Catalog_Name_Set_Input>;
  where: Catalog_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Catalog_Name_By_PkArgs = {
  _set?: Maybe<Catalog_Name_Set_Input>;
  pk_columns: Catalog_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_StandardArgs = {
  _set?: Maybe<Category_Standard_Set_Input>;
  where: Category_Standard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Standard_By_PkArgs = {
  _set?: Maybe<Category_Standard_Set_Input>;
  pk_columns: Category_Standard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ChallengeArgs = {
  _inc?: Maybe<Challenge_Inc_Input>;
  _set?: Maybe<Challenge_Set_Input>;
  where: Challenge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_By_PkArgs = {
  _inc?: Maybe<Challenge_Inc_Input>;
  _set?: Maybe<Challenge_Set_Input>;
  pk_columns: Challenge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_Catalog_ItemArgs = {
  _inc?: Maybe<Challenge_Catalog_Item_Inc_Input>;
  _set?: Maybe<Challenge_Catalog_Item_Set_Input>;
  where: Challenge_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Challenge_Catalog_Item_Inc_Input>;
  _set?: Maybe<Challenge_Catalog_Item_Set_Input>;
  pk_columns: Challenge_Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_DistrictArgs = {
  _inc?: Maybe<Challenge_District_Inc_Input>;
  _set?: Maybe<Challenge_District_Set_Input>;
  where: Challenge_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_District_By_PkArgs = {
  _inc?: Maybe<Challenge_District_Inc_Input>;
  _set?: Maybe<Challenge_District_Set_Input>;
  pk_columns: Challenge_District_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_GradeArgs = {
  _inc?: Maybe<Challenge_Grade_Inc_Input>;
  _set?: Maybe<Challenge_Grade_Set_Input>;
  where: Challenge_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_Grade_By_PkArgs = {
  _inc?: Maybe<Challenge_Grade_Inc_Input>;
  _set?: Maybe<Challenge_Grade_Set_Input>;
  pk_columns: Challenge_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_SongArgs = {
  _inc?: Maybe<Challenge_Song_Inc_Input>;
  _set?: Maybe<Challenge_Song_Set_Input>;
  where: Challenge_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_Song_By_PkArgs = {
  _inc?: Maybe<Challenge_Song_Inc_Input>;
  _set?: Maybe<Challenge_Song_Set_Input>;
  pk_columns: Challenge_Song_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_StudentArgs = {
  _inc?: Maybe<Challenge_Student_Inc_Input>;
  _set?: Maybe<Challenge_Student_Set_Input>;
  where: Challenge_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Challenge_Student_By_PkArgs = {
  _inc?: Maybe<Challenge_Student_Inc_Input>;
  _set?: Maybe<Challenge_Student_Set_Input>;
  pk_columns: Challenge_Student_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ChannelArgs = {
  _inc?: Maybe<Channel_Inc_Input>;
  _set?: Maybe<Channel_Set_Input>;
  where: Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_By_PkArgs = {
  _inc?: Maybe<Channel_Inc_Input>;
  _set?: Maybe<Channel_Set_Input>;
  pk_columns: Channel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_PlaylistArgs = {
  _inc?: Maybe<Channel_Playlist_Inc_Input>;
  _set?: Maybe<Channel_Playlist_Set_Input>;
  where: Channel_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Playlist_By_PkArgs = {
  _inc?: Maybe<Channel_Playlist_Inc_Input>;
  _set?: Maybe<Channel_Playlist_Set_Input>;
  pk_columns: Channel_Playlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClassArgs = {
  _inc?: Maybe<Class_Inc_Input>;
  _set?: Maybe<Class_Set_Input>;
  where: Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_AssignmentArgs = {
  _inc?: Maybe<Class_Assignment_Inc_Input>;
  _set?: Maybe<Class_Assignment_Set_Input>;
  where: Class_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Assignment_By_PkArgs = {
  _inc?: Maybe<Class_Assignment_Inc_Input>;
  _set?: Maybe<Class_Assignment_Set_Input>;
  pk_columns: Class_Assignment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Assignment_StatusArgs = {
  _set?: Maybe<Class_Assignment_Status_Set_Input>;
  where: Class_Assignment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Assignment_Status_By_PkArgs = {
  _set?: Maybe<Class_Assignment_Status_Set_Input>;
  pk_columns: Class_Assignment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_By_PkArgs = {
  _inc?: Maybe<Class_Inc_Input>;
  _set?: Maybe<Class_Set_Input>;
  pk_columns: Class_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_ImagesArgs = {
  _inc?: Maybe<Class_Images_Inc_Input>;
  _set?: Maybe<Class_Images_Set_Input>;
  where: Class_Images_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Images_By_PkArgs = {
  _inc?: Maybe<Class_Images_Inc_Input>;
  _set?: Maybe<Class_Images_Set_Input>;
  pk_columns: Class_Images_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Lesson_PlanArgs = {
  _inc?: Maybe<Class_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Class_Lesson_Plan_Set_Input>;
  where: Class_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Lesson_Plan_By_PkArgs = {
  _inc?: Maybe<Class_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Class_Lesson_Plan_Set_Input>;
  pk_columns: Class_Lesson_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_ModuleArgs = {
  _inc?: Maybe<Class_Module_Inc_Input>;
  _set?: Maybe<Class_Module_Set_Input>;
  where: Class_Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Module_By_PkArgs = {
  _inc?: Maybe<Class_Module_Inc_Input>;
  _set?: Maybe<Class_Module_Set_Input>;
  pk_columns: Class_Module_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_PlaylistArgs = {
  _inc?: Maybe<Class_Playlist_Inc_Input>;
  _set?: Maybe<Class_Playlist_Set_Input>;
  where: Class_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Playlist_By_PkArgs = {
  _inc?: Maybe<Class_Playlist_Inc_Input>;
  _set?: Maybe<Class_Playlist_Set_Input>;
  pk_columns: Class_Playlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_SequenceArgs = {
  _inc?: Maybe<Class_Sequence_Inc_Input>;
  _set?: Maybe<Class_Sequence_Set_Input>;
  where: Class_Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Sequence_By_PkArgs = {
  _inc?: Maybe<Class_Sequence_Inc_Input>;
  _set?: Maybe<Class_Sequence_Set_Input>;
  pk_columns: Class_Sequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_SongArgs = {
  _inc?: Maybe<Class_Song_Inc_Input>;
  _set?: Maybe<Class_Song_Set_Input>;
  where: Class_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Song_By_PkArgs = {
  _inc?: Maybe<Class_Song_Inc_Input>;
  _set?: Maybe<Class_Song_Set_Input>;
  pk_columns: Class_Song_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_StudentArgs = {
  _inc?: Maybe<Class_Student_Inc_Input>;
  _set?: Maybe<Class_Student_Set_Input>;
  where: Class_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Student_ActivitiesArgs = {
  _inc?: Maybe<Class_Student_Activities_Inc_Input>;
  _set?: Maybe<Class_Student_Activities_Set_Input>;
  where: Class_Student_Activities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Student_Activities_By_PkArgs = {
  _inc?: Maybe<Class_Student_Activities_Inc_Input>;
  _set?: Maybe<Class_Student_Activities_Set_Input>;
  pk_columns: Class_Student_Activities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Student_By_PkArgs = {
  _inc?: Maybe<Class_Student_Inc_Input>;
  _set?: Maybe<Class_Student_Set_Input>;
  pk_columns: Class_Student_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_TeacherArgs = {
  _inc?: Maybe<Class_Teacher_Inc_Input>;
  _set?: Maybe<Class_Teacher_Set_Input>;
  where: Class_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Teacher_By_PkArgs = {
  _inc?: Maybe<Class_Teacher_Inc_Input>;
  _set?: Maybe<Class_Teacher_Set_Input>;
  pk_columns: Class_Teacher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_TipArgs = {
  _inc?: Maybe<Class_Tip_Inc_Input>;
  _set?: Maybe<Class_Tip_Set_Input>;
  where: Class_Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Tip_By_PkArgs = {
  _inc?: Maybe<Class_Tip_Inc_Input>;
  _set?: Maybe<Class_Tip_Set_Input>;
  pk_columns: Class_Tip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clever_Roster_LogArgs = {
  _append?: Maybe<Clever_Roster_Log_Append_Input>;
  _delete_at_path?: Maybe<Clever_Roster_Log_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Clever_Roster_Log_Delete_Elem_Input>;
  _delete_key?: Maybe<Clever_Roster_Log_Delete_Key_Input>;
  _inc?: Maybe<Clever_Roster_Log_Inc_Input>;
  _prepend?: Maybe<Clever_Roster_Log_Prepend_Input>;
  _set?: Maybe<Clever_Roster_Log_Set_Input>;
  where: Clever_Roster_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clever_Roster_Log_By_PkArgs = {
  _append?: Maybe<Clever_Roster_Log_Append_Input>;
  _delete_at_path?: Maybe<Clever_Roster_Log_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Clever_Roster_Log_Delete_Elem_Input>;
  _delete_key?: Maybe<Clever_Roster_Log_Delete_Key_Input>;
  _inc?: Maybe<Clever_Roster_Log_Inc_Input>;
  _prepend?: Maybe<Clever_Roster_Log_Prepend_Input>;
  _set?: Maybe<Clever_Roster_Log_Set_Input>;
  pk_columns: Clever_Roster_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clever_Roster_StatusArgs = {
  _set?: Maybe<Clever_Roster_Status_Set_Input>;
  where: Clever_Roster_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clever_Roster_Status_By_PkArgs = {
  _set?: Maybe<Clever_Roster_Status_Set_Input>;
  pk_columns: Clever_Roster_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_UsArgs = {
  _inc?: Maybe<Contact_Us_Inc_Input>;
  _set?: Maybe<Contact_Us_Set_Input>;
  where: Contact_Us_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_By_PkArgs = {
  _inc?: Maybe<Contact_Us_Inc_Input>;
  _set?: Maybe<Contact_Us_Set_Input>;
  pk_columns: Contact_Us_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_Camp_TeacherArgs = {
  _inc?: Maybe<Contact_Us_Camp_Teacher_Inc_Input>;
  _set?: Maybe<Contact_Us_Camp_Teacher_Set_Input>;
  where: Contact_Us_Camp_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Us_Camp_Teacher_By_PkArgs = {
  _inc?: Maybe<Contact_Us_Camp_Teacher_Inc_Input>;
  _set?: Maybe<Contact_Us_Camp_Teacher_Set_Input>;
  pk_columns: Contact_Us_Camp_Teacher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Crossclear_MasterlistArgs = {
  _append?: Maybe<Crossclear_Masterlist_Append_Input>;
  _delete_at_path?: Maybe<Crossclear_Masterlist_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Crossclear_Masterlist_Delete_Elem_Input>;
  _delete_key?: Maybe<Crossclear_Masterlist_Delete_Key_Input>;
  _inc?: Maybe<Crossclear_Masterlist_Inc_Input>;
  _prepend?: Maybe<Crossclear_Masterlist_Prepend_Input>;
  _set?: Maybe<Crossclear_Masterlist_Set_Input>;
  where: Crossclear_Masterlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Crossclear_Masterlist_By_PkArgs = {
  _append?: Maybe<Crossclear_Masterlist_Append_Input>;
  _delete_at_path?: Maybe<Crossclear_Masterlist_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Crossclear_Masterlist_Delete_Elem_Input>;
  _delete_key?: Maybe<Crossclear_Masterlist_Delete_Key_Input>;
  _inc?: Maybe<Crossclear_Masterlist_Inc_Input>;
  _prepend?: Maybe<Crossclear_Masterlist_Prepend_Input>;
  _set?: Maybe<Crossclear_Masterlist_Set_Input>;
  pk_columns: Crossclear_Masterlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DisciplineArgs = {
  _inc?: Maybe<Discipline_Inc_Input>;
  _set?: Maybe<Discipline_Set_Input>;
  where: Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discipline_By_PkArgs = {
  _inc?: Maybe<Discipline_Inc_Input>;
  _set?: Maybe<Discipline_Set_Input>;
  pk_columns: Discipline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DistrictArgs = {
  _inc?: Maybe<District_Inc_Input>;
  _set?: Maybe<District_Set_Input>;
  where: District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_District_AdminArgs = {
  _inc?: Maybe<District_Admin_Inc_Input>;
  _set?: Maybe<District_Admin_Set_Input>;
  where: District_Admin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_District_Admin_By_PkArgs = {
  _inc?: Maybe<District_Admin_Inc_Input>;
  _set?: Maybe<District_Admin_Set_Input>;
  pk_columns: District_Admin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_District_By_PkArgs = {
  _inc?: Maybe<District_Inc_Input>;
  _set?: Maybe<District_Set_Input>;
  pk_columns: District_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_ColorArgs = {
  _inc?: Maybe<Filter_Color_Inc_Input>;
  _set?: Maybe<Filter_Color_Set_Input>;
  where: Filter_Color_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Color_By_PkArgs = {
  _inc?: Maybe<Filter_Color_Inc_Input>;
  _set?: Maybe<Filter_Color_Set_Input>;
  pk_columns: Filter_Color_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Color_NameArgs = {
  _set?: Maybe<Filter_Color_Name_Set_Input>;
  where: Filter_Color_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Color_Name_By_PkArgs = {
  _set?: Maybe<Filter_Color_Name_Set_Input>;
  pk_columns: Filter_Color_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_FrameArgs = {
  _inc?: Maybe<Filter_Frame_Inc_Input>;
  _set?: Maybe<Filter_Frame_Set_Input>;
  where: Filter_Frame_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_StickerArgs = {
  _inc?: Maybe<Filter_Sticker_Inc_Input>;
  _set?: Maybe<Filter_Sticker_Set_Input>;
  where: Filter_Sticker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filter_Sticker_By_PkArgs = {
  _inc?: Maybe<Filter_Sticker_Inc_Input>;
  _set?: Maybe<Filter_Sticker_Set_Input>;
  pk_columns: Filter_Sticker_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GradeArgs = {
  _inc?: Maybe<Grade_Inc_Input>;
  _set?: Maybe<Grade_Set_Input>;
  where: Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Grade_By_PkArgs = {
  _inc?: Maybe<Grade_Inc_Input>;
  _set?: Maybe<Grade_Set_Input>;
  pk_columns: Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Grading_SystemArgs = {
  _append?: Maybe<Grading_System_Append_Input>;
  _delete_at_path?: Maybe<Grading_System_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Grading_System_Delete_Elem_Input>;
  _delete_key?: Maybe<Grading_System_Delete_Key_Input>;
  _inc?: Maybe<Grading_System_Inc_Input>;
  _prepend?: Maybe<Grading_System_Prepend_Input>;
  _set?: Maybe<Grading_System_Set_Input>;
  where: Grading_System_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Grading_System_By_PkArgs = {
  _append?: Maybe<Grading_System_Append_Input>;
  _delete_at_path?: Maybe<Grading_System_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Grading_System_Delete_Elem_Input>;
  _delete_key?: Maybe<Grading_System_Delete_Key_Input>;
  _inc?: Maybe<Grading_System_Inc_Input>;
  _prepend?: Maybe<Grading_System_Prepend_Input>;
  _set?: Maybe<Grading_System_Set_Input>;
  pk_columns: Grading_System_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Grading_System_TypeArgs = {
  _set?: Maybe<Grading_System_Type_Set_Input>;
  where: Grading_System_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Grading_System_Type_By_PkArgs = {
  _set?: Maybe<Grading_System_Type_Set_Input>;
  pk_columns: Grading_System_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_NameArgs = {
  _set?: Maybe<Integration_Name_Set_Input>;
  where: Integration_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Integration_Name_By_PkArgs = {
  _set?: Maybe<Integration_Name_Set_Input>;
  pk_columns: Integration_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invitation_StatusArgs = {
  _set?: Maybe<Invitation_Status_Set_Input>;
  where: Invitation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invitation_Status_By_PkArgs = {
  _set?: Maybe<Invitation_Status_Set_Input>;
  pk_columns: Invitation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_KeywordArgs = {
  _inc?: Maybe<Keyword_Inc_Input>;
  _set?: Maybe<Keyword_Set_Input>;
  where: Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Keyword_By_PkArgs = {
  _inc?: Maybe<Keyword_Inc_Input>;
  _set?: Maybe<Keyword_Set_Input>;
  pk_columns: Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Label_ControlArgs = {
  _inc?: Maybe<Label_Control_Inc_Input>;
  _set?: Maybe<Label_Control_Set_Input>;
  where: Label_Control_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Label_Control_By_PkArgs = {
  _inc?: Maybe<Label_Control_Inc_Input>;
  _set?: Maybe<Label_Control_Set_Input>;
  pk_columns: Label_Control_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LabelsArgs = {
  _inc?: Maybe<Labels_Inc_Input>;
  _set?: Maybe<Labels_Set_Input>;
  where: Labels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Labels_By_PkArgs = {
  _inc?: Maybe<Labels_Inc_Input>;
  _set?: Maybe<Labels_Set_Input>;
  pk_columns: Labels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_PlanArgs = {
  _append?: Maybe<Lesson_Plan_Append_Input>;
  _delete_at_path?: Maybe<Lesson_Plan_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Lesson_Plan_Delete_Elem_Input>;
  _delete_key?: Maybe<Lesson_Plan_Delete_Key_Input>;
  _inc?: Maybe<Lesson_Plan_Inc_Input>;
  _prepend?: Maybe<Lesson_Plan_Prepend_Input>;
  _set?: Maybe<Lesson_Plan_Set_Input>;
  where: Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_ArticleArgs = {
  _inc?: Maybe<Lesson_Plan_Article_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Article_Set_Input>;
  where: Lesson_Plan_Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Article_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Article_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Article_Set_Input>;
  pk_columns: Lesson_Plan_Article_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_AssetArgs = {
  _inc?: Maybe<Lesson_Plan_Asset_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Asset_Set_Input>;
  where: Lesson_Plan_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Asset_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Asset_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Asset_Set_Input>;
  pk_columns: Lesson_Plan_Asset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_By_PkArgs = {
  _append?: Maybe<Lesson_Plan_Append_Input>;
  _delete_at_path?: Maybe<Lesson_Plan_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Lesson_Plan_Delete_Elem_Input>;
  _delete_key?: Maybe<Lesson_Plan_Delete_Key_Input>;
  _inc?: Maybe<Lesson_Plan_Inc_Input>;
  _prepend?: Maybe<Lesson_Plan_Prepend_Input>;
  _set?: Maybe<Lesson_Plan_Set_Input>;
  pk_columns: Lesson_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Catalog_ItemArgs = {
  _inc?: Maybe<Lesson_Plan_Catalog_Item_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Catalog_Item_Set_Input>;
  where: Lesson_Plan_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Catalog_Item_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Catalog_Item_Set_Input>;
  pk_columns: Lesson_Plan_Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_DisciplineArgs = {
  _inc?: Maybe<Lesson_Plan_Discipline_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Discipline_Set_Input>;
  where: Lesson_Plan_Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Discipline_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Discipline_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Discipline_Set_Input>;
  pk_columns: Lesson_Plan_Discipline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_GradeArgs = {
  _inc?: Maybe<Lesson_Plan_Grade_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Grade_Set_Input>;
  where: Lesson_Plan_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Grade_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Grade_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Grade_Set_Input>;
  pk_columns: Lesson_Plan_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_KeywordArgs = {
  _inc?: Maybe<Lesson_Plan_Keyword_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Keyword_Set_Input>;
  where: Lesson_Plan_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Keyword_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Keyword_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Keyword_Set_Input>;
  pk_columns: Lesson_Plan_Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Musical_SkillArgs = {
  _inc?: Maybe<Lesson_Plan_Musical_Skill_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Musical_Skill_Set_Input>;
  where: Lesson_Plan_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Musical_Skill_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Musical_Skill_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Musical_Skill_Set_Input>;
  pk_columns: Lesson_Plan_Musical_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Non_Musical_SkillArgs = {
  _inc?: Maybe<Lesson_Plan_Non_Musical_Skill_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Non_Musical_Skill_Set_Input>;
  where: Lesson_Plan_Non_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Non_Musical_Skill_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Non_Musical_Skill_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Non_Musical_Skill_Set_Input>;
  pk_columns: Lesson_Plan_Non_Musical_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Skill_LevelArgs = {
  _inc?: Maybe<Lesson_Plan_Skill_Level_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Skill_Level_Set_Input>;
  where: Lesson_Plan_Skill_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Skill_Level_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Skill_Level_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Skill_Level_Set_Input>;
  pk_columns: Lesson_Plan_Skill_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_SongArgs = {
  _inc?: Maybe<Lesson_Plan_Song_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Song_Set_Input>;
  where: Lesson_Plan_Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Song_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Song_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Song_Set_Input>;
  pk_columns: Lesson_Plan_Song_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_StandardArgs = {
  _inc?: Maybe<Lesson_Plan_Standard_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Standard_Set_Input>;
  where: Lesson_Plan_Standard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Standard_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Standard_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Standard_Set_Input>;
  pk_columns: Lesson_Plan_Standard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_TipArgs = {
  _inc?: Maybe<Lesson_Plan_Tip_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Tip_Set_Input>;
  where: Lesson_Plan_Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lesson_Plan_Tip_By_PkArgs = {
  _inc?: Maybe<Lesson_Plan_Tip_Inc_Input>;
  _set?: Maybe<Lesson_Plan_Tip_Set_Input>;
  pk_columns: Lesson_Plan_Tip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_ContentArgs = {
  _inc?: Maybe<Lockable_Content_Inc_Input>;
  _set?: Maybe<Lockable_Content_Set_Input>;
  where: Lockable_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_By_PkArgs = {
  _inc?: Maybe<Lockable_Content_Inc_Input>;
  _set?: Maybe<Lockable_Content_Set_Input>;
  pk_columns: Lockable_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_ClassArgs = {
  _inc?: Maybe<Lockable_Content_Class_Inc_Input>;
  _set?: Maybe<Lockable_Content_Class_Set_Input>;
  where: Lockable_Content_Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_Class_By_PkArgs = {
  _inc?: Maybe<Lockable_Content_Class_Inc_Input>;
  _set?: Maybe<Lockable_Content_Class_Set_Input>;
  pk_columns: Lockable_Content_Class_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_DistrictArgs = {
  _inc?: Maybe<Lockable_Content_District_Inc_Input>;
  _set?: Maybe<Lockable_Content_District_Set_Input>;
  where: Lockable_Content_District_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_District_By_PkArgs = {
  _inc?: Maybe<Lockable_Content_District_Inc_Input>;
  _set?: Maybe<Lockable_Content_District_Set_Input>;
  pk_columns: Lockable_Content_District_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_SchoolArgs = {
  _inc?: Maybe<Lockable_Content_School_Inc_Input>;
  _set?: Maybe<Lockable_Content_School_Set_Input>;
  where: Lockable_Content_School_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_School_By_PkArgs = {
  _inc?: Maybe<Lockable_Content_School_Inc_Input>;
  _set?: Maybe<Lockable_Content_School_Set_Input>;
  pk_columns: Lockable_Content_School_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_TeacherArgs = {
  _inc?: Maybe<Lockable_Content_Teacher_Inc_Input>;
  _set?: Maybe<Lockable_Content_Teacher_Set_Input>;
  where: Lockable_Content_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lockable_Content_Teacher_By_PkArgs = {
  _inc?: Maybe<Lockable_Content_Teacher_Inc_Input>;
  _set?: Maybe<Lockable_Content_Teacher_Set_Input>;
  pk_columns: Lockable_Content_Teacher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Maturity_LevelArgs = {
  _set?: Maybe<Maturity_Level_Set_Input>;
  where: Maturity_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Maturity_Level_By_PkArgs = {
  _set?: Maybe<Maturity_Level_Set_Input>;
  pk_columns: Maturity_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_TypeArgs = {
  _inc?: Maybe<Membership_Type_Inc_Input>;
  _set?: Maybe<Membership_Type_Set_Input>;
  where: Membership_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Membership_Type_By_PkArgs = {
  _inc?: Maybe<Membership_Type_Inc_Input>;
  _set?: Maybe<Membership_Type_Set_Input>;
  pk_columns: Membership_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MidiArgs = {
  _append?: Maybe<Midi_Append_Input>;
  _delete_at_path?: Maybe<Midi_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Midi_Delete_Elem_Input>;
  _delete_key?: Maybe<Midi_Delete_Key_Input>;
  _inc?: Maybe<Midi_Inc_Input>;
  _prepend?: Maybe<Midi_Prepend_Input>;
  _set?: Maybe<Midi_Set_Input>;
  where: Midi_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Midi_By_PkArgs = {
  _append?: Maybe<Midi_Append_Input>;
  _delete_at_path?: Maybe<Midi_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Midi_Delete_Elem_Input>;
  _delete_key?: Maybe<Midi_Delete_Key_Input>;
  _inc?: Maybe<Midi_Inc_Input>;
  _prepend?: Maybe<Midi_Prepend_Input>;
  _set?: Maybe<Midi_Set_Input>;
  pk_columns: Midi_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ModuleArgs = {
  _inc?: Maybe<Module_Inc_Input>;
  _set?: Maybe<Module_Set_Input>;
  where: Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Module_By_PkArgs = {
  _inc?: Maybe<Module_Inc_Input>;
  _set?: Maybe<Module_Set_Input>;
  pk_columns: Module_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Catalog_ItemArgs = {
  _inc?: Maybe<Module_Catalog_Item_Inc_Input>;
  _set?: Maybe<Module_Catalog_Item_Set_Input>;
  where: Module_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Module_Catalog_Item_Inc_Input>;
  _set?: Maybe<Module_Catalog_Item_Set_Input>;
  pk_columns: Module_Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Module_GradeArgs = {
  _inc?: Maybe<Module_Grade_Inc_Input>;
  _set?: Maybe<Module_Grade_Set_Input>;
  where: Module_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Grade_By_PkArgs = {
  _inc?: Maybe<Module_Grade_Inc_Input>;
  _set?: Maybe<Module_Grade_Set_Input>;
  pk_columns: Module_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Lesson_PlanArgs = {
  _inc?: Maybe<Module_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Module_Lesson_Plan_Set_Input>;
  where: Module_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Module_Lesson_Plan_By_PkArgs = {
  _inc?: Maybe<Module_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Module_Lesson_Plan_Set_Input>;
  pk_columns: Module_Lesson_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Musical_SkillArgs = {
  _inc?: Maybe<Musical_Skill_Inc_Input>;
  _set?: Maybe<Musical_Skill_Set_Input>;
  where: Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Musical_Skill_By_PkArgs = {
  _inc?: Maybe<Musical_Skill_Inc_Input>;
  _set?: Maybe<Musical_Skill_Set_Input>;
  pk_columns: Musical_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Musical_SkillArgs = {
  _inc?: Maybe<Non_Musical_Skill_Inc_Input>;
  _set?: Maybe<Non_Musical_Skill_Set_Input>;
  where: Non_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Musical_Skill_By_PkArgs = {
  _inc?: Maybe<Non_Musical_Skill_Inc_Input>;
  _set?: Maybe<Non_Musical_Skill_Set_Input>;
  pk_columns: Non_Musical_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Musical_Skill_TypeArgs = {
  _set?: Maybe<Non_Musical_Skill_Type_Set_Input>;
  where: Non_Musical_Skill_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Non_Musical_Skill_Type_By_PkArgs = {
  _set?: Maybe<Non_Musical_Skill_Type_Set_Input>;
  pk_columns: Non_Musical_Skill_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrderArgs = {
  _append?: Maybe<Order_Append_Input>;
  _delete_at_path?: Maybe<Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Delete_Key_Input>;
  _inc?: Maybe<Order_Inc_Input>;
  _prepend?: Maybe<Order_Prepend_Input>;
  _set?: Maybe<Order_Set_Input>;
  where: Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_By_PkArgs = {
  _append?: Maybe<Order_Append_Input>;
  _delete_at_path?: Maybe<Order_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Order_Delete_Elem_Input>;
  _delete_key?: Maybe<Order_Delete_Key_Input>;
  _inc?: Maybe<Order_Inc_Input>;
  _prepend?: Maybe<Order_Prepend_Input>;
  _set?: Maybe<Order_Set_Input>;
  pk_columns: Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_StatusArgs = {
  _set?: Maybe<Order_Status_Set_Input>;
  where: Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Status_By_PkArgs = {
  _set?: Maybe<Order_Status_Set_Input>;
  pk_columns: Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_InvitationArgs = {
  _inc?: Maybe<Parent_Invitation_Inc_Input>;
  _set?: Maybe<Parent_Invitation_Set_Input>;
  where: Parent_Invitation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Parent_Invitation_By_PkArgs = {
  _inc?: Maybe<Parent_Invitation_Inc_Input>;
  _set?: Maybe<Parent_Invitation_Set_Input>;
  pk_columns: Parent_Invitation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PartnerArgs = {
  _inc?: Maybe<Partner_Inc_Input>;
  _set?: Maybe<Partner_Set_Input>;
  where: Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_By_PkArgs = {
  _inc?: Maybe<Partner_Inc_Input>;
  _set?: Maybe<Partner_Set_Input>;
  pk_columns: Partner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_ModuleArgs = {
  _inc?: Maybe<Partner_Module_Inc_Input>;
  _set?: Maybe<Partner_Module_Set_Input>;
  where: Partner_Module_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Module_By_PkArgs = {
  _inc?: Maybe<Partner_Module_Inc_Input>;
  _set?: Maybe<Partner_Module_Set_Input>;
  pk_columns: Partner_Module_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_PlaylistArgs = {
  _inc?: Maybe<Partner_Playlist_Inc_Input>;
  _set?: Maybe<Partner_Playlist_Set_Input>;
  where: Partner_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Partner_Playlist_By_PkArgs = {
  _inc?: Maybe<Partner_Playlist_Inc_Input>;
  _set?: Maybe<Partner_Playlist_Set_Input>;
  pk_columns: Partner_Playlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PlaylistArgs = {
  _inc?: Maybe<Playlist_Inc_Input>;
  _set?: Maybe<Playlist_Set_Input>;
  where: Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Playlist_By_PkArgs = {
  _inc?: Maybe<Playlist_Inc_Input>;
  _set?: Maybe<Playlist_Set_Input>;
  pk_columns: Playlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Price_TypeArgs = {
  _set?: Maybe<Price_Type_Set_Input>;
  where: Price_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Type_By_PkArgs = {
  _set?: Maybe<Price_Type_Set_Input>;
  pk_columns: Price_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrivacyArgs = {
  _set?: Maybe<Privacy_Set_Input>;
  where: Privacy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Privacy_By_PkArgs = {
  _set?: Maybe<Privacy_Set_Input>;
  pk_columns: Privacy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_NameArgs = {
  _set?: Maybe<Product_Name_Set_Input>;
  where: Product_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Name_By_PkArgs = {
  _set?: Maybe<Product_Name_Set_Input>;
  pk_columns: Product_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PublisherArgs = {
  _inc?: Maybe<Publisher_Inc_Input>;
  _set?: Maybe<Publisher_Set_Input>;
  where: Publisher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Publisher_By_PkArgs = {
  _inc?: Maybe<Publisher_Inc_Input>;
  _set?: Maybe<Publisher_Set_Input>;
  pk_columns: Publisher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Publisher_ControlArgs = {
  _inc?: Maybe<Publisher_Control_Inc_Input>;
  _set?: Maybe<Publisher_Control_Set_Input>;
  where: Publisher_Control_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Publisher_Control_By_PkArgs = {
  _inc?: Maybe<Publisher_Control_Inc_Input>;
  _set?: Maybe<Publisher_Control_Set_Input>;
  pk_columns: Publisher_Control_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReactionArgs = {
  _inc?: Maybe<Reaction_Inc_Input>;
  _set?: Maybe<Reaction_Set_Input>;
  where: Reaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reaction_By_PkArgs = {
  _inc?: Maybe<Reaction_Inc_Input>;
  _set?: Maybe<Reaction_Set_Input>;
  pk_columns: Reaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reset_PasswordArgs = {
  _inc?: Maybe<Reset_Password_Inc_Input>;
  _set?: Maybe<Reset_Password_Set_Input>;
  where: Reset_Password_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reset_Password_By_PkArgs = {
  _inc?: Maybe<Reset_Password_Inc_Input>;
  _set?: Maybe<Reset_Password_Set_Input>;
  pk_columns: Reset_Password_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_NameArgs = {
  _set?: Maybe<Role_Name_Set_Input>;
  where: Role_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Name_By_PkArgs = {
  _set?: Maybe<Role_Name_Set_Input>;
  pk_columns: Role_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Royalty_LogArgs = {
  _inc?: Maybe<Royalty_Log_Inc_Input>;
  _set?: Maybe<Royalty_Log_Set_Input>;
  where: Royalty_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Royalty_Log_By_PkArgs = {
  _inc?: Maybe<Royalty_Log_Inc_Input>;
  _set?: Maybe<Royalty_Log_Set_Input>;
  pk_columns: Royalty_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ScaleArgs = {
  _inc?: Maybe<Scale_Inc_Input>;
  _set?: Maybe<Scale_Set_Input>;
  where: Scale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scale_By_PkArgs = {
  _inc?: Maybe<Scale_Inc_Input>;
  _set?: Maybe<Scale_Set_Input>;
  pk_columns: Scale_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SchoolArgs = {
  _inc?: Maybe<School_Inc_Input>;
  _set?: Maybe<School_Set_Input>;
  where: School_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_School_AdminArgs = {
  _inc?: Maybe<School_Admin_Inc_Input>;
  _set?: Maybe<School_Admin_Set_Input>;
  where: School_Admin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_School_Admin_By_PkArgs = {
  _inc?: Maybe<School_Admin_Inc_Input>;
  _set?: Maybe<School_Admin_Set_Input>;
  pk_columns: School_Admin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_School_By_PkArgs = {
  _inc?: Maybe<School_Inc_Input>;
  _set?: Maybe<School_Set_Input>;
  pk_columns: School_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_School_TeacherArgs = {
  _inc?: Maybe<School_Teacher_Inc_Input>;
  _set?: Maybe<School_Teacher_Set_Input>;
  where: School_Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_School_Teacher_By_PkArgs = {
  _inc?: Maybe<School_Teacher_Inc_Input>;
  _set?: Maybe<School_Teacher_Set_Input>;
  pk_columns: School_Teacher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Screen_TypeArgs = {
  _set?: Maybe<Screen_Type_Set_Input>;
  where: Screen_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Screen_Type_By_PkArgs = {
  _set?: Maybe<Screen_Type_Set_Input>;
  pk_columns: Screen_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SequenceArgs = {
  _inc?: Maybe<Sequence_Inc_Input>;
  _set?: Maybe<Sequence_Set_Input>;
  where: Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_By_PkArgs = {
  _inc?: Maybe<Sequence_Inc_Input>;
  _set?: Maybe<Sequence_Set_Input>;
  pk_columns: Sequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_KeywordArgs = {
  _inc?: Maybe<Sequence_Keyword_Inc_Input>;
  _set?: Maybe<Sequence_Keyword_Set_Input>;
  where: Sequence_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Keyword_By_PkArgs = {
  _inc?: Maybe<Sequence_Keyword_Inc_Input>;
  _set?: Maybe<Sequence_Keyword_Set_Input>;
  pk_columns: Sequence_Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Lesson_PlanArgs = {
  _inc?: Maybe<Sequence_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Sequence_Lesson_Plan_Set_Input>;
  where: Sequence_Lesson_Plan_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Lesson_Plan_By_PkArgs = {
  _inc?: Maybe<Sequence_Lesson_Plan_Inc_Input>;
  _set?: Maybe<Sequence_Lesson_Plan_Set_Input>;
  pk_columns: Sequence_Lesson_Plan_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_TopicArgs = {
  _inc?: Maybe<Sequence_Topic_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Set_Input>;
  where: Sequence_Topic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_By_PkArgs = {
  _inc?: Maybe<Sequence_Topic_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Set_Input>;
  pk_columns: Sequence_Topic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_Catalog_ItemArgs = {
  _inc?: Maybe<Sequence_Topic_Catalog_Item_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Catalog_Item_Set_Input>;
  where: Sequence_Topic_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Sequence_Topic_Catalog_Item_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Catalog_Item_Set_Input>;
  pk_columns: Sequence_Topic_Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_KeywordArgs = {
  _inc?: Maybe<Sequence_Topic_Keyword_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Keyword_Set_Input>;
  where: Sequence_Topic_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_Keyword_By_PkArgs = {
  _inc?: Maybe<Sequence_Topic_Keyword_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Keyword_Set_Input>;
  pk_columns: Sequence_Topic_Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_SequenceArgs = {
  _inc?: Maybe<Sequence_Topic_Sequence_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Sequence_Set_Input>;
  where: Sequence_Topic_Sequence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sequence_Topic_Sequence_By_PkArgs = {
  _inc?: Maybe<Sequence_Topic_Sequence_Inc_Input>;
  _set?: Maybe<Sequence_Topic_Sequence_Set_Input>;
  pk_columns: Sequence_Topic_Sequence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SessionArgs = {
  _inc?: Maybe<Session_Inc_Input>;
  _set?: Maybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_By_PkArgs = {
  _inc?: Maybe<Session_Inc_Input>;
  _set?: Maybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_LevelArgs = {
  _inc?: Maybe<Skill_Level_Inc_Input>;
  _set?: Maybe<Skill_Level_Set_Input>;
  where: Skill_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Skill_Level_By_PkArgs = {
  _inc?: Maybe<Skill_Level_Inc_Input>;
  _set?: Maybe<Skill_Level_Set_Input>;
  pk_columns: Skill_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SongArgs = {
  _append?: Maybe<Song_Append_Input>;
  _delete_at_path?: Maybe<Song_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Song_Delete_Elem_Input>;
  _delete_key?: Maybe<Song_Delete_Key_Input>;
  _inc?: Maybe<Song_Inc_Input>;
  _prepend?: Maybe<Song_Prepend_Input>;
  _set?: Maybe<Song_Set_Input>;
  where: Song_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Song_By_PkArgs = {
  _append?: Maybe<Song_Append_Input>;
  _delete_at_path?: Maybe<Song_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Song_Delete_Elem_Input>;
  _delete_key?: Maybe<Song_Delete_Key_Input>;
  _inc?: Maybe<Song_Inc_Input>;
  _prepend?: Maybe<Song_Prepend_Input>;
  _set?: Maybe<Song_Set_Input>;
  pk_columns: Song_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Song_Catalog_ItemArgs = {
  _inc?: Maybe<Song_Catalog_Item_Inc_Input>;
  _set?: Maybe<Song_Catalog_Item_Set_Input>;
  where: Song_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Song_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Song_Catalog_Item_Inc_Input>;
  _set?: Maybe<Song_Catalog_Item_Set_Input>;
  pk_columns: Song_Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Song_GradeArgs = {
  _inc?: Maybe<Song_Grade_Inc_Input>;
  _set?: Maybe<Song_Grade_Set_Input>;
  where: Song_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Song_Grade_By_PkArgs = {
  _inc?: Maybe<Song_Grade_Inc_Input>;
  _set?: Maybe<Song_Grade_Set_Input>;
  pk_columns: Song_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Song_KeywordArgs = {
  _inc?: Maybe<Song_Keyword_Inc_Input>;
  _set?: Maybe<Song_Keyword_Set_Input>;
  where: Song_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Song_Keyword_By_PkArgs = {
  _inc?: Maybe<Song_Keyword_Inc_Input>;
  _set?: Maybe<Song_Keyword_Set_Input>;
  pk_columns: Song_Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StandardArgs = {
  _set?: Maybe<Standard_Set_Input>;
  where: Standard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_By_PkArgs = {
  _set?: Maybe<Standard_Set_Input>;
  pk_columns: Standard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_OptionArgs = {
  _inc?: Maybe<Standard_Option_Inc_Input>;
  _set?: Maybe<Standard_Option_Set_Input>;
  where: Standard_Option_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_Option_By_PkArgs = {
  _inc?: Maybe<Standard_Option_Inc_Input>;
  _set?: Maybe<Standard_Option_Set_Input>;
  pk_columns: Standard_Option_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_SubcategoryArgs = {
  _inc?: Maybe<Standard_Subcategory_Inc_Input>;
  _set?: Maybe<Standard_Subcategory_Set_Input>;
  where: Standard_Subcategory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_Subcategory_By_PkArgs = {
  _inc?: Maybe<Standard_Subcategory_Inc_Input>;
  _set?: Maybe<Standard_Subcategory_Set_Input>;
  pk_columns: Standard_Subcategory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StudentArgs = {
  _inc?: Maybe<Student_Inc_Input>;
  _set?: Maybe<Student_Set_Input>;
  where: Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_ActivityArgs = {
  _inc?: Maybe<Student_Activity_Inc_Input>;
  _set?: Maybe<Student_Activity_Set_Input>;
  where: Student_Activity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Activity_By_PkArgs = {
  _inc?: Maybe<Student_Activity_Inc_Input>;
  _set?: Maybe<Student_Activity_Set_Input>;
  pk_columns: Student_Activity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Activity_TypeArgs = {
  _set?: Maybe<Student_Activity_Type_Set_Input>;
  where: Student_Activity_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Activity_Type_By_PkArgs = {
  _set?: Maybe<Student_Activity_Type_Set_Input>;
  pk_columns: Student_Activity_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_BadgeArgs = {
  _inc?: Maybe<Student_Badge_Inc_Input>;
  _set?: Maybe<Student_Badge_Set_Input>;
  where: Student_Badge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Badge_By_PkArgs = {
  _inc?: Maybe<Student_Badge_Inc_Input>;
  _set?: Maybe<Student_Badge_Set_Input>;
  pk_columns: Student_Badge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Badge_EventArgs = {
  _inc?: Maybe<Student_Badge_Event_Inc_Input>;
  _set?: Maybe<Student_Badge_Event_Set_Input>;
  where: Student_Badge_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Badge_Event_By_PkArgs = {
  _inc?: Maybe<Student_Badge_Event_Inc_Input>;
  _set?: Maybe<Student_Badge_Event_Set_Input>;
  pk_columns: Student_Badge_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_BannerArgs = {
  _inc?: Maybe<Student_Banner_Inc_Input>;
  _set?: Maybe<Student_Banner_Set_Input>;
  where: Student_Banner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Banner_By_PkArgs = {
  _inc?: Maybe<Student_Banner_Inc_Input>;
  _set?: Maybe<Student_Banner_Set_Input>;
  pk_columns: Student_Banner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_By_PkArgs = {
  _inc?: Maybe<Student_Inc_Input>;
  _set?: Maybe<Student_Set_Input>;
  pk_columns: Student_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_GoalArgs = {
  _inc?: Maybe<Student_Goal_Inc_Input>;
  _set?: Maybe<Student_Goal_Set_Input>;
  where: Student_Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Goal_By_PkArgs = {
  _inc?: Maybe<Student_Goal_Inc_Input>;
  _set?: Maybe<Student_Goal_Set_Input>;
  pk_columns: Student_Goal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_IntegrationArgs = {
  _inc?: Maybe<Student_Integration_Inc_Input>;
  _set?: Maybe<Student_Integration_Set_Input>;
  where: Student_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Integration_By_PkArgs = {
  _inc?: Maybe<Student_Integration_Inc_Input>;
  _set?: Maybe<Student_Integration_Set_Input>;
  pk_columns: Student_Integration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Lesson_Plan_FavoriteArgs = {
  _inc?: Maybe<Student_Lesson_Plan_Favorite_Inc_Input>;
  _set?: Maybe<Student_Lesson_Plan_Favorite_Set_Input>;
  where: Student_Lesson_Plan_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Lesson_Plan_Favorite_By_PkArgs = {
  _inc?: Maybe<Student_Lesson_Plan_Favorite_Inc_Input>;
  _set?: Maybe<Student_Lesson_Plan_Favorite_Set_Input>;
  pk_columns: Student_Lesson_Plan_Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Productivity_DailyArgs = {
  _inc?: Maybe<Student_Productivity_Daily_Inc_Input>;
  _set?: Maybe<Student_Productivity_Daily_Set_Input>;
  where: Student_Productivity_Daily_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Productivity_Daily_By_PkArgs = {
  _inc?: Maybe<Student_Productivity_Daily_Inc_Input>;
  _set?: Maybe<Student_Productivity_Daily_Set_Input>;
  pk_columns: Student_Productivity_Daily_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Productivity_MonthlyArgs = {
  _inc?: Maybe<Student_Productivity_Monthly_Inc_Input>;
  _set?: Maybe<Student_Productivity_Monthly_Set_Input>;
  where: Student_Productivity_Monthly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Productivity_Monthly_By_PkArgs = {
  _inc?: Maybe<Student_Productivity_Monthly_Inc_Input>;
  _set?: Maybe<Student_Productivity_Monthly_Set_Input>;
  pk_columns: Student_Productivity_Monthly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Productivity_YearlyArgs = {
  _inc?: Maybe<Student_Productivity_Yearly_Inc_Input>;
  _set?: Maybe<Student_Productivity_Yearly_Set_Input>;
  where: Student_Productivity_Yearly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Productivity_Yearly_By_PkArgs = {
  _inc?: Maybe<Student_Productivity_Yearly_Inc_Input>;
  _set?: Maybe<Student_Productivity_Yearly_Set_Input>;
  pk_columns: Student_Productivity_Yearly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_SettingArgs = {
  _inc?: Maybe<Student_Setting_Inc_Input>;
  _set?: Maybe<Student_Setting_Set_Input>;
  where: Student_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Setting_By_PkArgs = {
  _inc?: Maybe<Student_Setting_Inc_Input>;
  _set?: Maybe<Student_Setting_Set_Input>;
  pk_columns: Student_Setting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Song_FavoriteArgs = {
  _inc?: Maybe<Student_Song_Favorite_Inc_Input>;
  _set?: Maybe<Student_Song_Favorite_Set_Input>;
  where: Student_Song_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Song_Favorite_By_PkArgs = {
  _inc?: Maybe<Student_Song_Favorite_Inc_Input>;
  _set?: Maybe<Student_Song_Favorite_Set_Input>;
  pk_columns: Student_Song_Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Tip_FavoriteArgs = {
  _inc?: Maybe<Student_Tip_Favorite_Inc_Input>;
  _set?: Maybe<Student_Tip_Favorite_Set_Input>;
  where: Student_Tip_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Student_Tip_Favorite_By_PkArgs = {
  _inc?: Maybe<Student_Tip_Favorite_Inc_Input>;
  _set?: Maybe<Student_Tip_Favorite_Set_Input>;
  pk_columns: Student_Tip_Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SubmissionArgs = {
  _inc?: Maybe<Submission_Inc_Input>;
  _set?: Maybe<Submission_Set_Input>;
  where: Submission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_AssetArgs = {
  _inc?: Maybe<Submission_Asset_Inc_Input>;
  _set?: Maybe<Submission_Asset_Set_Input>;
  where: Submission_Asset_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_Asset_By_PkArgs = {
  _inc?: Maybe<Submission_Asset_Inc_Input>;
  _set?: Maybe<Submission_Asset_Set_Input>;
  pk_columns: Submission_Asset_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_By_PkArgs = {
  _inc?: Maybe<Submission_Inc_Input>;
  _set?: Maybe<Submission_Set_Input>;
  pk_columns: Submission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_StateArgs = {
  _set?: Maybe<Submission_State_Set_Input>;
  where: Submission_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_State_By_PkArgs = {
  _set?: Maybe<Submission_State_Set_Input>;
  pk_columns: Submission_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_StudentArgs = {
  _inc?: Maybe<Submission_Student_Inc_Input>;
  _set?: Maybe<Submission_Student_Set_Input>;
  where: Submission_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_Student_By_PkArgs = {
  _inc?: Maybe<Submission_Student_Inc_Input>;
  _set?: Maybe<Submission_Student_Set_Input>;
  pk_columns: Submission_Student_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_WurrlyArgs = {
  _inc?: Maybe<Submission_Wurrly_Inc_Input>;
  _set?: Maybe<Submission_Wurrly_Set_Input>;
  where: Submission_Wurrly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_Wurrly_By_PkArgs = {
  _inc?: Maybe<Submission_Wurrly_Inc_Input>;
  _set?: Maybe<Submission_Wurrly_Set_Input>;
  pk_columns: Submission_Wurrly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_Wurrly_StateArgs = {
  _set?: Maybe<Submission_Wurrly_State_Set_Input>;
  where: Submission_Wurrly_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Submission_Wurrly_State_By_PkArgs = {
  _set?: Maybe<Submission_Wurrly_State_Set_Input>;
  pk_columns: Submission_Wurrly_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeacherArgs = {
  _append?: Maybe<Teacher_Append_Input>;
  _delete_at_path?: Maybe<Teacher_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Teacher_Delete_Elem_Input>;
  _delete_key?: Maybe<Teacher_Delete_Key_Input>;
  _inc?: Maybe<Teacher_Inc_Input>;
  _prepend?: Maybe<Teacher_Prepend_Input>;
  _set?: Maybe<Teacher_Set_Input>;
  where: Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_By_PkArgs = {
  _append?: Maybe<Teacher_Append_Input>;
  _delete_at_path?: Maybe<Teacher_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Teacher_Delete_Elem_Input>;
  _delete_key?: Maybe<Teacher_Delete_Key_Input>;
  _inc?: Maybe<Teacher_Inc_Input>;
  _prepend?: Maybe<Teacher_Prepend_Input>;
  _set?: Maybe<Teacher_Set_Input>;
  pk_columns: Teacher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_IntegrationArgs = {
  _inc?: Maybe<Teacher_Integration_Inc_Input>;
  _set?: Maybe<Teacher_Integration_Set_Input>;
  where: Teacher_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Integration_By_PkArgs = {
  _inc?: Maybe<Teacher_Integration_Inc_Input>;
  _set?: Maybe<Teacher_Integration_Set_Input>;
  pk_columns: Teacher_Integration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Lesson_Plan_FavoriteArgs = {
  _inc?: Maybe<Teacher_Lesson_Plan_Favorite_Inc_Input>;
  _set?: Maybe<Teacher_Lesson_Plan_Favorite_Set_Input>;
  where: Teacher_Lesson_Plan_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Lesson_Plan_Favorite_By_PkArgs = {
  _inc?: Maybe<Teacher_Lesson_Plan_Favorite_Inc_Input>;
  _set?: Maybe<Teacher_Lesson_Plan_Favorite_Set_Input>;
  pk_columns: Teacher_Lesson_Plan_Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_SettingArgs = {
  _inc?: Maybe<Teacher_Setting_Inc_Input>;
  _set?: Maybe<Teacher_Setting_Set_Input>;
  where: Teacher_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Setting_By_PkArgs = {
  _inc?: Maybe<Teacher_Setting_Inc_Input>;
  _set?: Maybe<Teacher_Setting_Set_Input>;
  pk_columns: Teacher_Setting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Song_FavoriteArgs = {
  _inc?: Maybe<Teacher_Song_Favorite_Inc_Input>;
  _set?: Maybe<Teacher_Song_Favorite_Set_Input>;
  where: Teacher_Song_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Song_Favorite_By_PkArgs = {
  _inc?: Maybe<Teacher_Song_Favorite_Inc_Input>;
  _set?: Maybe<Teacher_Song_Favorite_Set_Input>;
  pk_columns: Teacher_Song_Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_SubscriptionArgs = {
  _inc?: Maybe<Teacher_Subscription_Inc_Input>;
  _set?: Maybe<Teacher_Subscription_Set_Input>;
  where: Teacher_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Subscription_By_PkArgs = {
  _inc?: Maybe<Teacher_Subscription_Inc_Input>;
  _set?: Maybe<Teacher_Subscription_Set_Input>;
  pk_columns: Teacher_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Tip_FavoriteArgs = {
  _inc?: Maybe<Teacher_Tip_Favorite_Inc_Input>;
  _set?: Maybe<Teacher_Tip_Favorite_Set_Input>;
  where: Teacher_Tip_Favorite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Tip_Favorite_By_PkArgs = {
  _inc?: Maybe<Teacher_Tip_Favorite_Inc_Input>;
  _set?: Maybe<Teacher_Tip_Favorite_Set_Input>;
  pk_columns: Teacher_Tip_Favorite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Wurrly_CommentArgs = {
  _inc?: Maybe<Teacher_Wurrly_Comment_Inc_Input>;
  _set?: Maybe<Teacher_Wurrly_Comment_Set_Input>;
  where: Teacher_Wurrly_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Wurrly_Comment_By_PkArgs = {
  _inc?: Maybe<Teacher_Wurrly_Comment_Inc_Input>;
  _set?: Maybe<Teacher_Wurrly_Comment_Set_Input>;
  pk_columns: Teacher_Wurrly_Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TipArgs = {
  _inc?: Maybe<Tip_Inc_Input>;
  _set?: Maybe<Tip_Set_Input>;
  where: Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_AgeArgs = {
  _inc?: Maybe<Tip_Age_Inc_Input>;
  _set?: Maybe<Tip_Age_Set_Input>;
  where: Tip_Age_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Age_By_PkArgs = {
  _inc?: Maybe<Tip_Age_Inc_Input>;
  _set?: Maybe<Tip_Age_Set_Input>;
  pk_columns: Tip_Age_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_By_PkArgs = {
  _inc?: Maybe<Tip_Inc_Input>;
  _set?: Maybe<Tip_Set_Input>;
  pk_columns: Tip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Catalog_ItemArgs = {
  _inc?: Maybe<Tip_Catalog_Item_Inc_Input>;
  _set?: Maybe<Tip_Catalog_Item_Set_Input>;
  where: Tip_Catalog_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Catalog_Item_By_PkArgs = {
  _inc?: Maybe<Tip_Catalog_Item_Inc_Input>;
  _set?: Maybe<Tip_Catalog_Item_Set_Input>;
  pk_columns: Tip_Catalog_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_GradeArgs = {
  _inc?: Maybe<Tip_Grade_Inc_Input>;
  _set?: Maybe<Tip_Grade_Set_Input>;
  where: Tip_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Grade_By_PkArgs = {
  _inc?: Maybe<Tip_Grade_Inc_Input>;
  _set?: Maybe<Tip_Grade_Set_Input>;
  pk_columns: Tip_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_KeywordArgs = {
  _inc?: Maybe<Tip_Keyword_Inc_Input>;
  _set?: Maybe<Tip_Keyword_Set_Input>;
  where: Tip_Keyword_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Keyword_By_PkArgs = {
  _inc?: Maybe<Tip_Keyword_Inc_Input>;
  _set?: Maybe<Tip_Keyword_Set_Input>;
  pk_columns: Tip_Keyword_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Musical_SkillArgs = {
  _inc?: Maybe<Tip_Musical_Skill_Inc_Input>;
  _set?: Maybe<Tip_Musical_Skill_Set_Input>;
  where: Tip_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Musical_Skill_By_PkArgs = {
  _inc?: Maybe<Tip_Musical_Skill_Inc_Input>;
  _set?: Maybe<Tip_Musical_Skill_Set_Input>;
  pk_columns: Tip_Musical_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Non_Musical_SkillArgs = {
  _inc?: Maybe<Tip_Non_Musical_Skill_Inc_Input>;
  _set?: Maybe<Tip_Non_Musical_Skill_Set_Input>;
  where: Tip_Non_Musical_Skill_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Non_Musical_Skill_By_PkArgs = {
  _inc?: Maybe<Tip_Non_Musical_Skill_Inc_Input>;
  _set?: Maybe<Tip_Non_Musical_Skill_Set_Input>;
  pk_columns: Tip_Non_Musical_Skill_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_PlaylistArgs = {
  _inc?: Maybe<Tip_Playlist_Inc_Input>;
  _set?: Maybe<Tip_Playlist_Set_Input>;
  where: Tip_Playlist_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Playlist_By_PkArgs = {
  _inc?: Maybe<Tip_Playlist_Inc_Input>;
  _set?: Maybe<Tip_Playlist_Set_Input>;
  pk_columns: Tip_Playlist_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TrackArgs = {
  _inc?: Maybe<Track_Inc_Input>;
  _set?: Maybe<Track_Set_Input>;
  where: Track_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Track_By_PkArgs = {
  _inc?: Maybe<Track_Inc_Input>;
  _set?: Maybe<Track_Set_Input>;
  pk_columns: Track_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Track_TypeArgs = {
  _inc?: Maybe<Track_Type_Inc_Input>;
  _set?: Maybe<Track_Type_Set_Input>;
  where: Track_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Track_Type_By_PkArgs = {
  _inc?: Maybe<Track_Type_Inc_Input>;
  _set?: Maybe<Track_Type_Set_Input>;
  pk_columns: Track_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transpose_IndexArgs = {
  _set?: Maybe<Transpose_Index_Set_Input>;
  where: Transpose_Index_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transpose_Index_By_PkArgs = {
  _set?: Maybe<Transpose_Index_Set_Input>;
  pk_columns: Transpose_Index_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Utils_Most_Attended_ClassesArgs = {
  _inc?: Maybe<Utils_Most_Attended_Classes_Inc_Input>;
  _set?: Maybe<Utils_Most_Attended_Classes_Set_Input>;
  where: Utils_Most_Attended_Classes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Utils_Most_Attended_SubjectsArgs = {
  _inc?: Maybe<Utils_Most_Attended_Subjects_Inc_Input>;
  _set?: Maybe<Utils_Most_Attended_Subjects_Set_Input>;
  where: Utils_Most_Attended_Subjects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Utils_Teacher_Subscriptions_Per_MonthArgs = {
  _inc?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Inc_Input>;
  _set?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Set_Input>;
  where: Utils_Teacher_Subscriptions_Per_Month_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Utils_Users_Per_MonthArgs = {
  _inc?: Maybe<Utils_Users_Per_Month_Inc_Input>;
  _set?: Maybe<Utils_Users_Per_Month_Set_Input>;
  where: Utils_Users_Per_Month_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VisibilityArgs = {
  _set?: Maybe<Visibility_Set_Input>;
  where: Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Visibility_By_PkArgs = {
  _set?: Maybe<Visibility_Set_Input>;
  pk_columns: Visibility_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WurrlyArgs = {
  _inc?: Maybe<Wurrly_Inc_Input>;
  _set?: Maybe<Wurrly_Set_Input>;
  where: Wurrly_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_By_PkArgs = {
  _inc?: Maybe<Wurrly_Inc_Input>;
  _set?: Maybe<Wurrly_Set_Input>;
  pk_columns: Wurrly_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_FilterArgs = {
  _inc?: Maybe<Wurrly_Filter_Inc_Input>;
  _set?: Maybe<Wurrly_Filter_Set_Input>;
  where: Wurrly_Filter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Filter_By_PkArgs = {
  _inc?: Maybe<Wurrly_Filter_Inc_Input>;
  _set?: Maybe<Wurrly_Filter_Set_Input>;
  pk_columns: Wurrly_Filter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Filter_StickerArgs = {
  _inc?: Maybe<Wurrly_Filter_Sticker_Inc_Input>;
  _set?: Maybe<Wurrly_Filter_Sticker_Set_Input>;
  where: Wurrly_Filter_Sticker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Processing_StatusArgs = {
  _set?: Maybe<Wurrly_Processing_Status_Set_Input>;
  where: Wurrly_Processing_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Processing_Status_By_PkArgs = {
  _set?: Maybe<Wurrly_Processing_Status_Set_Input>;
  pk_columns: Wurrly_Processing_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_ReactionArgs = {
  _inc?: Maybe<Wurrly_Reaction_Inc_Input>;
  _set?: Maybe<Wurrly_Reaction_Set_Input>;
  where: Wurrly_Reaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Reaction_By_PkArgs = {
  _inc?: Maybe<Wurrly_Reaction_Inc_Input>;
  _set?: Maybe<Wurrly_Reaction_Set_Input>;
  pk_columns: Wurrly_Reaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_RoleArgs = {
  _set?: Maybe<Wurrly_Role_Set_Input>;
  where: Wurrly_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Role_By_PkArgs = {
  _set?: Maybe<Wurrly_Role_Set_Input>;
  pk_columns: Wurrly_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_TypeArgs = {
  _set?: Maybe<Wurrly_Type_Set_Input>;
  where: Wurrly_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wurrly_Type_By_PkArgs = {
  _set?: Maybe<Wurrly_Type_Set_Input>;
  pk_columns: Wurrly_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUtils_Update_District_Lockable_Content_Modules_And_Lessons_FunctionArgs = {
  args: Utils_Update_District_Lockable_Content_Modules_And_Lessons_Function_Args;
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootUtils_Update_School_Lockable_Content_Modules_And_Lessons_FunctionArgs = {
  args: Utils_Update_School_Lockable_Content_Modules_And_Lessons_Function_Args;
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};

/** columns and relationships of "non_musical_skill" */
export type Non_Musical_Skill = {
  __typename?: 'non_musical_skill';
  created_at?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  non_musical_skill_id: Scalars['Int'];
  type?: Maybe<Non_Musical_Skill_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "non_musical_skill" */
export type Non_Musical_Skill_Aggregate = {
  __typename?: 'non_musical_skill_aggregate';
  aggregate?: Maybe<Non_Musical_Skill_Aggregate_Fields>;
  nodes: Array<Non_Musical_Skill>;
};

/** aggregate fields of "non_musical_skill" */
export type Non_Musical_Skill_Aggregate_Fields = {
  __typename?: 'non_musical_skill_aggregate_fields';
  avg?: Maybe<Non_Musical_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Non_Musical_Skill_Max_Fields>;
  min?: Maybe<Non_Musical_Skill_Min_Fields>;
  stddev?: Maybe<Non_Musical_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Non_Musical_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Non_Musical_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Non_Musical_Skill_Sum_Fields>;
  var_pop?: Maybe<Non_Musical_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Non_Musical_Skill_Var_Samp_Fields>;
  variance?: Maybe<Non_Musical_Skill_Variance_Fields>;
};


/** aggregate fields of "non_musical_skill" */
export type Non_Musical_Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Non_Musical_Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Non_Musical_Skill_Avg_Fields = {
  __typename?: 'non_musical_skill_avg_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "non_musical_skill". All fields are combined with a logical 'AND'. */
export type Non_Musical_Skill_Bool_Exp = {
  _and?: Maybe<Array<Non_Musical_Skill_Bool_Exp>>;
  _not?: Maybe<Non_Musical_Skill_Bool_Exp>;
  _or?: Maybe<Array<Non_Musical_Skill_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  non_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<Non_Musical_Skill_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "non_musical_skill" */
export enum Non_Musical_Skill_Constraint {
  /** unique or primary key constraint */
  NonMusicalSkillPkey1 = 'non_musical_skill_pkey1'
}

/** input type for incrementing numeric columns in table "non_musical_skill" */
export type Non_Musical_Skill_Inc_Input = {
  non_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "non_musical_skill" */
export type Non_Musical_Skill_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Non_Musical_Skill_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Non_Musical_Skill_Max_Fields = {
  __typename?: 'non_musical_skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Non_Musical_Skill_Min_Fields = {
  __typename?: 'non_musical_skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "non_musical_skill" */
export type Non_Musical_Skill_Mutation_Response = {
  __typename?: 'non_musical_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Non_Musical_Skill>;
};

/** input type for inserting object relation for remote table "non_musical_skill" */
export type Non_Musical_Skill_Obj_Rel_Insert_Input = {
  data: Non_Musical_Skill_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Non_Musical_Skill_On_Conflict>;
};

/** on_conflict condition type for table "non_musical_skill" */
export type Non_Musical_Skill_On_Conflict = {
  constraint: Non_Musical_Skill_Constraint;
  update_columns?: Array<Non_Musical_Skill_Update_Column>;
  where?: Maybe<Non_Musical_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "non_musical_skill". */
export type Non_Musical_Skill_Order_By = {
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: non_musical_skill */
export type Non_Musical_Skill_Pk_Columns_Input = {
  non_musical_skill_id: Scalars['Int'];
};

/** select columns of table "non_musical_skill" */
export enum Non_Musical_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  NonMusicalSkillId = 'non_musical_skill_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "non_musical_skill" */
export type Non_Musical_Skill_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Non_Musical_Skill_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Non_Musical_Skill_Stddev_Fields = {
  __typename?: 'non_musical_skill_stddev_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Non_Musical_Skill_Stddev_Pop_Fields = {
  __typename?: 'non_musical_skill_stddev_pop_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Non_Musical_Skill_Stddev_Samp_Fields = {
  __typename?: 'non_musical_skill_stddev_samp_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Non_Musical_Skill_Sum_Fields = {
  __typename?: 'non_musical_skill_sum_fields';
  non_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "non_musical_skill_type" */
export type Non_Musical_Skill_Type = {
  __typename?: 'non_musical_skill_type';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "non_musical_skill_type" */
export type Non_Musical_Skill_Type_Aggregate = {
  __typename?: 'non_musical_skill_type_aggregate';
  aggregate?: Maybe<Non_Musical_Skill_Type_Aggregate_Fields>;
  nodes: Array<Non_Musical_Skill_Type>;
};

/** aggregate fields of "non_musical_skill_type" */
export type Non_Musical_Skill_Type_Aggregate_Fields = {
  __typename?: 'non_musical_skill_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Non_Musical_Skill_Type_Max_Fields>;
  min?: Maybe<Non_Musical_Skill_Type_Min_Fields>;
};


/** aggregate fields of "non_musical_skill_type" */
export type Non_Musical_Skill_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Non_Musical_Skill_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "non_musical_skill_type". All fields are combined with a logical 'AND'. */
export type Non_Musical_Skill_Type_Bool_Exp = {
  _and?: Maybe<Array<Non_Musical_Skill_Type_Bool_Exp>>;
  _not?: Maybe<Non_Musical_Skill_Type_Bool_Exp>;
  _or?: Maybe<Array<Non_Musical_Skill_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "non_musical_skill_type" */
export enum Non_Musical_Skill_Type_Constraint {
  /** unique or primary key constraint */
  NonMusicalSkillTypePkey = 'non_musical_skill_type_pkey'
}

export enum Non_Musical_Skill_Type_Enum {
  /** relationship skills */
  RelationshipSkills = 'relationship_skills',
  /** responsible deicision-making */
  ResponsibleDecisionMaking = 'responsible_decision_making',
  /** self-awareness */
  SelfAwareness = 'self_awareness',
  /** self-management */
  SelfManagement = 'self_management',
  /** social awareness */
  SocialAwareness = 'social_awareness'
}

/** Boolean expression to compare columns of type "non_musical_skill_type_enum". All fields are combined with logical 'AND'. */
export type Non_Musical_Skill_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Non_Musical_Skill_Type_Enum>;
  _in?: Maybe<Array<Non_Musical_Skill_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Non_Musical_Skill_Type_Enum>;
  _nin?: Maybe<Array<Non_Musical_Skill_Type_Enum>>;
};

/** input type for inserting data into table "non_musical_skill_type" */
export type Non_Musical_Skill_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Non_Musical_Skill_Type_Max_Fields = {
  __typename?: 'non_musical_skill_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Non_Musical_Skill_Type_Min_Fields = {
  __typename?: 'non_musical_skill_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "non_musical_skill_type" */
export type Non_Musical_Skill_Type_Mutation_Response = {
  __typename?: 'non_musical_skill_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Non_Musical_Skill_Type>;
};

/** on_conflict condition type for table "non_musical_skill_type" */
export type Non_Musical_Skill_Type_On_Conflict = {
  constraint: Non_Musical_Skill_Type_Constraint;
  update_columns?: Array<Non_Musical_Skill_Type_Update_Column>;
  where?: Maybe<Non_Musical_Skill_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "non_musical_skill_type". */
export type Non_Musical_Skill_Type_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: non_musical_skill_type */
export type Non_Musical_Skill_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "non_musical_skill_type" */
export enum Non_Musical_Skill_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "non_musical_skill_type" */
export type Non_Musical_Skill_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "non_musical_skill_type" */
export enum Non_Musical_Skill_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** update columns of table "non_musical_skill" */
export enum Non_Musical_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  NonMusicalSkillId = 'non_musical_skill_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Non_Musical_Skill_Var_Pop_Fields = {
  __typename?: 'non_musical_skill_var_pop_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Non_Musical_Skill_Var_Samp_Fields = {
  __typename?: 'non_musical_skill_var_samp_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Non_Musical_Skill_Variance_Fields = {
  __typename?: 'non_musical_skill_variance_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** Items bought by the customer */
export type Order = {
  __typename?: 'order';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  channel?: Maybe<Channel>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  lesson_plan_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  module?: Maybe<Module>;
  module_id?: Maybe<Scalars['Int']>;
  order_id: Scalars['Int'];
  /** An object relationship */
  partner?: Maybe<Partner>;
  partner_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  playlist?: Maybe<Playlist>;
  playlist_id?: Maybe<Scalars['Int']>;
  price_type: Price_Type_Enum;
  product_name: Product_Name_Enum;
  product_stamp?: Maybe<Scalars['jsonb']>;
  status: Order_Status_Enum;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id: Scalars['String'];
  teacher_id: Scalars['Int'];
  total: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** Items bought by the customer */
export type OrderProduct_StampArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order" */
export type Order_Aggregate = {
  __typename?: 'order_aggregate';
  aggregate?: Maybe<Order_Aggregate_Fields>;
  nodes: Array<Order>;
};

/** aggregate fields of "order" */
export type Order_Aggregate_Fields = {
  __typename?: 'order_aggregate_fields';
  avg?: Maybe<Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Order_Max_Fields>;
  min?: Maybe<Order_Min_Fields>;
  stddev?: Maybe<Order_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Sum_Fields>;
  var_pop?: Maybe<Order_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Var_Samp_Fields>;
  variance?: Maybe<Order_Variance_Fields>;
};


/** aggregate fields of "order" */
export type Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  avg?: Maybe<Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Max_Order_By>;
  min?: Maybe<Order_Min_Order_By>;
  stddev?: Maybe<Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Sum_Order_By>;
  var_pop?: Maybe<Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Var_Samp_Order_By>;
  variance?: Maybe<Order_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Order_Append_Input = {
  product_stamp?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  data: Array<Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Avg_Fields = {
  __typename?: 'order_avg_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: Maybe<Array<Order_Bool_Exp>>;
  _not?: Maybe<Order_Bool_Exp>;
  _or?: Maybe<Array<Order_Bool_Exp>>;
  canceled_at?: Maybe<Timestamptz_Comparison_Exp>;
  channel?: Maybe<Channel_Bool_Exp>;
  channel_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  order_id?: Maybe<Int_Comparison_Exp>;
  partner?: Maybe<Partner_Bool_Exp>;
  partner_id?: Maybe<Int_Comparison_Exp>;
  playlist?: Maybe<Playlist_Bool_Exp>;
  playlist_id?: Maybe<Int_Comparison_Exp>;
  price_type?: Maybe<Price_Type_Enum_Comparison_Exp>;
  product_name?: Maybe<Product_Name_Enum_Comparison_Exp>;
  product_stamp?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Order_Status_Enum_Comparison_Exp>;
  stripe_coupon_name?: Maybe<String_Comparison_Exp>;
  stripe_discount_amount?: Maybe<Int_Comparison_Exp>;
  stripe_promotion_code_id?: Maybe<String_Comparison_Exp>;
  stripe_subscription_id?: Maybe<String_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  total?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint */
  OrderDetailsPkey = 'order_details_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Order_Delete_At_Path_Input = {
  product_stamp?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Order_Delete_Elem_Input = {
  product_stamp?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Order_Delete_Key_Input = {
  product_stamp?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  channel_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  canceled_at?: Maybe<Scalars['timestamptz']>;
  channel?: Maybe<Channel_Obj_Rel_Insert_Input>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  module_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  partner?: Maybe<Partner_Obj_Rel_Insert_Input>;
  partner_id?: Maybe<Scalars['Int']>;
  playlist?: Maybe<Playlist_Obj_Rel_Insert_Input>;
  playlist_id?: Maybe<Scalars['Int']>;
  price_type?: Maybe<Price_Type_Enum>;
  product_name?: Maybe<Product_Name_Enum>;
  product_stamp?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Order_Status_Enum>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Order_Max_Fields = {
  __typename?: 'order_max_fields';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  canceled_at?: Maybe<Order_By>;
  channel_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_coupon_name?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  stripe_promotion_code_id?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Min_Fields = {
  __typename?: 'order_min_fields';
  canceled_at?: Maybe<Scalars['timestamptz']>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  canceled_at?: Maybe<Order_By>;
  channel_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_coupon_name?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  stripe_promotion_code_id?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "order" */
export type Order_Mutation_Response = {
  __typename?: 'order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** on_conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns?: Array<Order_Update_Column>;
  where?: Maybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  canceled_at?: Maybe<Order_By>;
  channel?: Maybe<Channel_Order_By>;
  channel_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner?: Maybe<Partner_Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist?: Maybe<Playlist_Order_By>;
  playlist_id?: Maybe<Order_By>;
  price_type?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  product_stamp?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripe_coupon_name?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  stripe_promotion_code_id?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  order_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Order_Prepend_Input = {
  product_stamp?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  PriceType = 'price_type',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  ProductStamp = 'product_stamp',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCouponName = 'stripe_coupon_name',
  /** column name */
  StripeDiscountAmount = 'stripe_discount_amount',
  /** column name */
  StripePromotionCodeId = 'stripe_promotion_code_id',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  canceled_at?: Maybe<Scalars['timestamptz']>;
  channel_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  price_type?: Maybe<Price_Type_Enum>;
  product_name?: Maybe<Product_Name_Enum>;
  product_stamp?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Order_Status_Enum>;
  stripe_coupon_name?: Maybe<Scalars['String']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  stripe_promotion_code_id?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "order_status" */
export type Order_Status = {
  __typename?: 'order_status';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "order_status" */
export type Order_Status_Aggregate = {
  __typename?: 'order_status_aggregate';
  aggregate?: Maybe<Order_Status_Aggregate_Fields>;
  nodes: Array<Order_Status>;
};

/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_Fields = {
  __typename?: 'order_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Order_Status_Max_Fields>;
  min?: Maybe<Order_Status_Min_Fields>;
};


/** aggregate fields of "order_status" */
export type Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_status". All fields are combined with a logical 'AND'. */
export type Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Order_Status_Bool_Exp>>;
  _not?: Maybe<Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Order_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_status" */
export enum Order_Status_Constraint {
  /** unique or primary key constraint */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Order_Status_Enum {
  /** Active Subscription */
  Active = 'active',
  /** Canceled Subscription */
  Canceled = 'canceled',
  /** Overdue Subscription */
  Overdue = 'overdue'
}

/** Boolean expression to compare columns of type "order_status_enum". All fields are combined with logical 'AND'. */
export type Order_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Order_Status_Enum>;
  _in?: Maybe<Array<Order_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Order_Status_Enum>;
  _nin?: Maybe<Array<Order_Status_Enum>>;
};

/** input type for inserting data into table "order_status" */
export type Order_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Status_Max_Fields = {
  __typename?: 'order_status_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Order_Status_Min_Fields = {
  __typename?: 'order_status_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_status" */
export type Order_Status_Mutation_Response = {
  __typename?: 'order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order_Status>;
};

/** on_conflict condition type for table "order_status" */
export type Order_Status_On_Conflict = {
  constraint: Order_Status_Constraint;
  update_columns?: Array<Order_Status_Update_Column>;
  where?: Maybe<Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "order_status". */
export type Order_Status_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: order_status */
export type Order_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "order_status" */
export enum Order_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "order_status" */
export type Order_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "order_status" */
export enum Order_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type Order_Stddev_Fields = {
  __typename?: 'order_stddev_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Stddev_Pop_Fields = {
  __typename?: 'order_stddev_pop_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Stddev_Samp_Fields = {
  __typename?: 'order_stddev_samp_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Sum_Fields = {
  __typename?: 'order_sum_fields';
  channel_id?: Maybe<Scalars['Int']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  module_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  stripe_discount_amount?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  PriceType = 'price_type',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  ProductStamp = 'product_stamp',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCouponName = 'stripe_coupon_name',
  /** column name */
  StripeDiscountAmount = 'stripe_discount_amount',
  /** column name */
  StripePromotionCodeId = 'stripe_promotion_code_id',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Order_Var_Pop_Fields = {
  __typename?: 'order_var_pop_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Var_Samp_Fields = {
  __typename?: 'order_var_samp_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Variance_Fields = {
  __typename?: 'order_variance_fields';
  channel_id?: Maybe<Scalars['Float']>;
  lesson_plan_id?: Maybe<Scalars['Float']>;
  module_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  stripe_discount_amount?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  channel_id?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  stripe_discount_amount?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** columns and relationships of "parent_invitation" */
export type Parent_Invitation = {
  __typename?: 'parent_invitation';
  class_id: Scalars['Int'];
  code: Scalars['String'];
  invitation_status: Invitation_Status_Enum;
  parent_email: Scalars['String'];
  parent_first_name?: Maybe<Scalars['String']>;
  parent_invitation_id: Scalars['Int'];
  parent_last_name?: Maybe<Scalars['String']>;
  student_first_name?: Maybe<Scalars['String']>;
  student_last_name?: Maybe<Scalars['String']>;
  teacher_id: Scalars['Int'];
};

/** aggregated selection of "parent_invitation" */
export type Parent_Invitation_Aggregate = {
  __typename?: 'parent_invitation_aggregate';
  aggregate?: Maybe<Parent_Invitation_Aggregate_Fields>;
  nodes: Array<Parent_Invitation>;
};

/** aggregate fields of "parent_invitation" */
export type Parent_Invitation_Aggregate_Fields = {
  __typename?: 'parent_invitation_aggregate_fields';
  avg?: Maybe<Parent_Invitation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parent_Invitation_Max_Fields>;
  min?: Maybe<Parent_Invitation_Min_Fields>;
  stddev?: Maybe<Parent_Invitation_Stddev_Fields>;
  stddev_pop?: Maybe<Parent_Invitation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parent_Invitation_Stddev_Samp_Fields>;
  sum?: Maybe<Parent_Invitation_Sum_Fields>;
  var_pop?: Maybe<Parent_Invitation_Var_Pop_Fields>;
  var_samp?: Maybe<Parent_Invitation_Var_Samp_Fields>;
  variance?: Maybe<Parent_Invitation_Variance_Fields>;
};


/** aggregate fields of "parent_invitation" */
export type Parent_Invitation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Parent_Invitation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Parent_Invitation_Avg_Fields = {
  __typename?: 'parent_invitation_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "parent_invitation". All fields are combined with a logical 'AND'. */
export type Parent_Invitation_Bool_Exp = {
  _and?: Maybe<Array<Parent_Invitation_Bool_Exp>>;
  _not?: Maybe<Parent_Invitation_Bool_Exp>;
  _or?: Maybe<Array<Parent_Invitation_Bool_Exp>>;
  class_id?: Maybe<Int_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  invitation_status?: Maybe<Invitation_Status_Enum_Comparison_Exp>;
  parent_email?: Maybe<String_Comparison_Exp>;
  parent_first_name?: Maybe<String_Comparison_Exp>;
  parent_invitation_id?: Maybe<Int_Comparison_Exp>;
  parent_last_name?: Maybe<String_Comparison_Exp>;
  student_first_name?: Maybe<String_Comparison_Exp>;
  student_last_name?: Maybe<String_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "parent_invitation" */
export enum Parent_Invitation_Constraint {
  /** unique or primary key constraint */
  ParentInvitationCodeKey = 'parent_invitation_code_key',
  /** unique or primary key constraint */
  ParentInvitationPkey = 'parent_invitation_pkey'
}

/** input type for incrementing numeric columns in table "parent_invitation" */
export type Parent_Invitation_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  parent_invitation_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "parent_invitation" */
export type Parent_Invitation_Insert_Input = {
  class_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Invitation_Status_Enum>;
  parent_email?: Maybe<Scalars['String']>;
  parent_first_name?: Maybe<Scalars['String']>;
  parent_invitation_id?: Maybe<Scalars['Int']>;
  parent_last_name?: Maybe<Scalars['String']>;
  student_first_name?: Maybe<Scalars['String']>;
  student_last_name?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Parent_Invitation_Max_Fields = {
  __typename?: 'parent_invitation_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  parent_email?: Maybe<Scalars['String']>;
  parent_first_name?: Maybe<Scalars['String']>;
  parent_invitation_id?: Maybe<Scalars['Int']>;
  parent_last_name?: Maybe<Scalars['String']>;
  student_first_name?: Maybe<Scalars['String']>;
  student_last_name?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Parent_Invitation_Min_Fields = {
  __typename?: 'parent_invitation_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  parent_email?: Maybe<Scalars['String']>;
  parent_first_name?: Maybe<Scalars['String']>;
  parent_invitation_id?: Maybe<Scalars['Int']>;
  parent_last_name?: Maybe<Scalars['String']>;
  student_first_name?: Maybe<Scalars['String']>;
  student_last_name?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "parent_invitation" */
export type Parent_Invitation_Mutation_Response = {
  __typename?: 'parent_invitation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parent_Invitation>;
};

/** on_conflict condition type for table "parent_invitation" */
export type Parent_Invitation_On_Conflict = {
  constraint: Parent_Invitation_Constraint;
  update_columns?: Array<Parent_Invitation_Update_Column>;
  where?: Maybe<Parent_Invitation_Bool_Exp>;
};

/** Ordering options when selecting data from "parent_invitation". */
export type Parent_Invitation_Order_By = {
  class_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  invitation_status?: Maybe<Order_By>;
  parent_email?: Maybe<Order_By>;
  parent_first_name?: Maybe<Order_By>;
  parent_invitation_id?: Maybe<Order_By>;
  parent_last_name?: Maybe<Order_By>;
  student_first_name?: Maybe<Order_By>;
  student_last_name?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** primary key columns input for table: parent_invitation */
export type Parent_Invitation_Pk_Columns_Input = {
  parent_invitation_id: Scalars['Int'];
};

/** select columns of table "parent_invitation" */
export enum Parent_Invitation_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Code = 'code',
  /** column name */
  InvitationStatus = 'invitation_status',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentFirstName = 'parent_first_name',
  /** column name */
  ParentInvitationId = 'parent_invitation_id',
  /** column name */
  ParentLastName = 'parent_last_name',
  /** column name */
  StudentFirstName = 'student_first_name',
  /** column name */
  StudentLastName = 'student_last_name',
  /** column name */
  TeacherId = 'teacher_id'
}

/** input type for updating data in table "parent_invitation" */
export type Parent_Invitation_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  invitation_status?: Maybe<Invitation_Status_Enum>;
  parent_email?: Maybe<Scalars['String']>;
  parent_first_name?: Maybe<Scalars['String']>;
  parent_invitation_id?: Maybe<Scalars['Int']>;
  parent_last_name?: Maybe<Scalars['String']>;
  student_first_name?: Maybe<Scalars['String']>;
  student_last_name?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Parent_Invitation_Stddev_Fields = {
  __typename?: 'parent_invitation_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Parent_Invitation_Stddev_Pop_Fields = {
  __typename?: 'parent_invitation_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Parent_Invitation_Stddev_Samp_Fields = {
  __typename?: 'parent_invitation_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Parent_Invitation_Sum_Fields = {
  __typename?: 'parent_invitation_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  parent_invitation_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "parent_invitation" */
export enum Parent_Invitation_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Code = 'code',
  /** column name */
  InvitationStatus = 'invitation_status',
  /** column name */
  ParentEmail = 'parent_email',
  /** column name */
  ParentFirstName = 'parent_first_name',
  /** column name */
  ParentInvitationId = 'parent_invitation_id',
  /** column name */
  ParentLastName = 'parent_last_name',
  /** column name */
  StudentFirstName = 'student_first_name',
  /** column name */
  StudentLastName = 'student_last_name',
  /** column name */
  TeacherId = 'teacher_id'
}

/** aggregate var_pop on columns */
export type Parent_Invitation_Var_Pop_Fields = {
  __typename?: 'parent_invitation_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Parent_Invitation_Var_Samp_Fields = {
  __typename?: 'parent_invitation_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Parent_Invitation_Variance_Fields = {
  __typename?: 'parent_invitation_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  parent_invitation_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** collection of modules */
export type Partner = {
  __typename?: 'partner';
  active: Scalars['Boolean'];
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "get_partner_status_by_teacher" */
  get_status_by_teacher?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** A computed field, executes function "is_partner_purchased_by_teacher" */
  is_purchased_by_teacher?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lockable_content: Lockable_Content;
  lockable_content_id: Scalars['Int'];
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order: Scalars['String'];
  /** An array relationship */
  order_details: Array<Order>;
  /** An aggregate relationship */
  order_details_aggregate: Order_Aggregate;
  partner_id: Scalars['Int'];
  /** An object relationship */
  partner_module?: Maybe<Partner_Module>;
  /** An array relationship */
  partner_modules: Array<Partner_Module>;
  /** An aggregate relationship */
  partner_modules_aggregate: Partner_Module_Aggregate;
  /** An array relationship */
  partner_playlists: Array<Partner_Playlist>;
  /** An aggregate relationship */
  partner_playlists_aggregate: Partner_Playlist_Aggregate;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  video_path: Scalars['String'];
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};


/** collection of modules */
export type PartnerOrder_DetailsArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** collection of modules */
export type PartnerOrder_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** collection of modules */
export type PartnerPartner_ModulesArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


/** collection of modules */
export type PartnerPartner_Modules_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


/** collection of modules */
export type PartnerPartner_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


/** collection of modules */
export type PartnerPartner_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};

/** aggregated selection of "partner" */
export type Partner_Aggregate = {
  __typename?: 'partner_aggregate';
  aggregate?: Maybe<Partner_Aggregate_Fields>;
  nodes: Array<Partner>;
};

/** aggregate fields of "partner" */
export type Partner_Aggregate_Fields = {
  __typename?: 'partner_aggregate_fields';
  avg?: Maybe<Partner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Max_Fields>;
  min?: Maybe<Partner_Min_Fields>;
  stddev?: Maybe<Partner_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Sum_Fields>;
  var_pop?: Maybe<Partner_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Var_Samp_Fields>;
  variance?: Maybe<Partner_Variance_Fields>;
};


/** aggregate fields of "partner" */
export type Partner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Partner_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Partner_Avg_Fields = {
  __typename?: 'partner_avg_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "partner". All fields are combined with a logical 'AND'. */
export type Partner_Bool_Exp = {
  _and?: Maybe<Array<Partner_Bool_Exp>>;
  _not?: Maybe<Partner_Bool_Exp>;
  _or?: Maybe<Array<Partner_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  annual_price?: Maybe<Int_Comparison_Exp>;
  annual_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  banner_description?: Maybe<String_Comparison_Exp>;
  banner_image_path?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  district_price?: Maybe<Int_Comparison_Exp>;
  district_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  feature?: Maybe<Boolean_Comparison_Exp>;
  get_status_by_teacher?: Maybe<String_Comparison_Exp>;
  heading_description?: Maybe<String_Comparison_Exp>;
  heading_short_description?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_purchased_by_teacher?: Maybe<Boolean_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  logo_image_path?: Maybe<String_Comparison_Exp>;
  monthly_price?: Maybe<Int_Comparison_Exp>;
  monthly_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  order?: Maybe<String_Comparison_Exp>;
  order_details?: Maybe<Order_Bool_Exp>;
  partner_id?: Maybe<Int_Comparison_Exp>;
  partner_module?: Maybe<Partner_Module_Bool_Exp>;
  partner_modules?: Maybe<Partner_Module_Bool_Exp>;
  partner_playlists?: Maybe<Partner_Playlist_Bool_Exp>;
  school_price?: Maybe<Int_Comparison_Exp>;
  school_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  short_description?: Maybe<String_Comparison_Exp>;
  stripe_product_id?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_path?: Maybe<String_Comparison_Exp>;
  video_thumbnail_image_path?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner" */
export enum Partner_Constraint {
  /** unique or primary key constraint */
  PartnerAnnualPriceStripePriceIdKey = 'partner_annual_price_stripe_price_id_key',
  /** unique or primary key constraint */
  PartnerLockableContentIdKey = 'partner_lockable_content_id_key',
  /** unique or primary key constraint */
  PartnerMonthlyPriceStripePriceIdKey = 'partner_monthly_price_stripe_price_id_key',
  /** unique or primary key constraint */
  PartnerPkey = 'partner_pkey',
  /** unique or primary key constraint */
  PartnerStripeProductIdKey = 'partner_stripe_product_id_key'
}

/** input type for incrementing numeric columns in table "partner" */
export type Partner_Inc_Input = {
  annual_price?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "partner" */
export type Partner_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  order_details?: Maybe<Order_Arr_Rel_Insert_Input>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module?: Maybe<Partner_Module_Obj_Rel_Insert_Input>;
  partner_modules?: Maybe<Partner_Module_Arr_Rel_Insert_Input>;
  partner_playlists?: Maybe<Partner_Playlist_Arr_Rel_Insert_Input>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Partner_Max_Fields = {
  __typename?: 'partner_max_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  partner_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Partner_Min_Fields = {
  __typename?: 'partner_min_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  partner_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "partner_module" */
export type Partner_Module = {
  __typename?: 'partner_module';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  module: Module;
  module_id: Scalars['Int'];
  /** An object relationship */
  partner: Partner;
  partner_id: Scalars['Int'];
  partner_module_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "partner_module" */
export type Partner_Module_Aggregate = {
  __typename?: 'partner_module_aggregate';
  aggregate?: Maybe<Partner_Module_Aggregate_Fields>;
  nodes: Array<Partner_Module>;
};

/** aggregate fields of "partner_module" */
export type Partner_Module_Aggregate_Fields = {
  __typename?: 'partner_module_aggregate_fields';
  avg?: Maybe<Partner_Module_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Module_Max_Fields>;
  min?: Maybe<Partner_Module_Min_Fields>;
  stddev?: Maybe<Partner_Module_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Module_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Module_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Module_Sum_Fields>;
  var_pop?: Maybe<Partner_Module_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Module_Var_Samp_Fields>;
  variance?: Maybe<Partner_Module_Variance_Fields>;
};


/** aggregate fields of "partner_module" */
export type Partner_Module_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Partner_Module_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "partner_module" */
export type Partner_Module_Aggregate_Order_By = {
  avg?: Maybe<Partner_Module_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Partner_Module_Max_Order_By>;
  min?: Maybe<Partner_Module_Min_Order_By>;
  stddev?: Maybe<Partner_Module_Stddev_Order_By>;
  stddev_pop?: Maybe<Partner_Module_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Partner_Module_Stddev_Samp_Order_By>;
  sum?: Maybe<Partner_Module_Sum_Order_By>;
  var_pop?: Maybe<Partner_Module_Var_Pop_Order_By>;
  var_samp?: Maybe<Partner_Module_Var_Samp_Order_By>;
  variance?: Maybe<Partner_Module_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "partner_module" */
export type Partner_Module_Arr_Rel_Insert_Input = {
  data: Array<Partner_Module_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Partner_Module_On_Conflict>;
};

/** aggregate avg on columns */
export type Partner_Module_Avg_Fields = {
  __typename?: 'partner_module_avg_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "partner_module" */
export type Partner_Module_Avg_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "partner_module". All fields are combined with a logical 'AND'. */
export type Partner_Module_Bool_Exp = {
  _and?: Maybe<Array<Partner_Module_Bool_Exp>>;
  _not?: Maybe<Partner_Module_Bool_Exp>;
  _or?: Maybe<Array<Partner_Module_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  module?: Maybe<Module_Bool_Exp>;
  module_id?: Maybe<Int_Comparison_Exp>;
  partner?: Maybe<Partner_Bool_Exp>;
  partner_id?: Maybe<Int_Comparison_Exp>;
  partner_module_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner_module" */
export enum Partner_Module_Constraint {
  /** unique or primary key constraint */
  PartnerModulePartnerIdModuleIdKey = 'partner_module_partner_id_module_id_key',
  /** unique or primary key constraint */
  PartnerModulePkey = 'partner_module_pkey'
}

/** input type for incrementing numeric columns in table "partner_module" */
export type Partner_Module_Inc_Input = {
  module_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "partner_module" */
export type Partner_Module_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  module?: Maybe<Module_Obj_Rel_Insert_Input>;
  module_id?: Maybe<Scalars['Int']>;
  partner?: Maybe<Partner_Obj_Rel_Insert_Input>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Partner_Module_Max_Fields = {
  __typename?: 'partner_module_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  module_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "partner_module" */
export type Partner_Module_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Partner_Module_Min_Fields = {
  __typename?: 'partner_module_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  module_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "partner_module" */
export type Partner_Module_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "partner_module" */
export type Partner_Module_Mutation_Response = {
  __typename?: 'partner_module_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Module>;
};

/** input type for inserting object relation for remote table "partner_module" */
export type Partner_Module_Obj_Rel_Insert_Input = {
  data: Partner_Module_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Partner_Module_On_Conflict>;
};

/** on_conflict condition type for table "partner_module" */
export type Partner_Module_On_Conflict = {
  constraint: Partner_Module_Constraint;
  update_columns?: Array<Partner_Module_Update_Column>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_module". */
export type Partner_Module_Order_By = {
  created_at?: Maybe<Order_By>;
  module?: Maybe<Module_Order_By>;
  module_id?: Maybe<Order_By>;
  partner?: Maybe<Partner_Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: partner_module */
export type Partner_Module_Pk_Columns_Input = {
  partner_module_id: Scalars['Int'];
};

/** select columns of table "partner_module" */
export enum Partner_Module_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PartnerModuleId = 'partner_module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "partner_module" */
export type Partner_Module_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  module_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Partner_Module_Stddev_Fields = {
  __typename?: 'partner_module_stddev_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "partner_module" */
export type Partner_Module_Stddev_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Partner_Module_Stddev_Pop_Fields = {
  __typename?: 'partner_module_stddev_pop_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "partner_module" */
export type Partner_Module_Stddev_Pop_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Partner_Module_Stddev_Samp_Fields = {
  __typename?: 'partner_module_stddev_samp_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "partner_module" */
export type Partner_Module_Stddev_Samp_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Partner_Module_Sum_Fields = {
  __typename?: 'partner_module_sum_fields';
  module_id?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_module_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "partner_module" */
export type Partner_Module_Sum_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** update columns of table "partner_module" */
export enum Partner_Module_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PartnerModuleId = 'partner_module_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Partner_Module_Var_Pop_Fields = {
  __typename?: 'partner_module_var_pop_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "partner_module" */
export type Partner_Module_Var_Pop_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Partner_Module_Var_Samp_Fields = {
  __typename?: 'partner_module_var_samp_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "partner_module" */
export type Partner_Module_Var_Samp_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Partner_Module_Variance_Fields = {
  __typename?: 'partner_module_variance_fields';
  module_id?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  partner_module_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "partner_module" */
export type Partner_Module_Variance_Order_By = {
  module_id?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "partner" */
export type Partner_Mutation_Response = {
  __typename?: 'partner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner>;
};

/** input type for inserting object relation for remote table "partner" */
export type Partner_Obj_Rel_Insert_Input = {
  data: Partner_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Partner_On_Conflict>;
};

/** on_conflict condition type for table "partner" */
export type Partner_On_Conflict = {
  constraint: Partner_Constraint;
  update_columns?: Array<Partner_Update_Column>;
  where?: Maybe<Partner_Bool_Exp>;
};

/** Ordering options when selecting data from "partner". */
export type Partner_Order_By = {
  active?: Maybe<Order_By>;
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  banner_description?: Maybe<Order_By>;
  banner_image_path?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  get_status_by_teacher?: Maybe<Order_By>;
  heading_description?: Maybe<Order_By>;
  heading_short_description?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_purchased_by_teacher?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  logo_image_path?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_details_aggregate?: Maybe<Order_Aggregate_Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_module?: Maybe<Partner_Module_Order_By>;
  partner_modules_aggregate?: Maybe<Partner_Module_Aggregate_Order_By>;
  partner_playlists_aggregate?: Maybe<Partner_Playlist_Aggregate_Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  short_description?: Maybe<Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  video_path?: Maybe<Order_By>;
  video_thumbnail_image_path?: Maybe<Order_By>;
};

/** primary key columns input for table: partner */
export type Partner_Pk_Columns_Input = {
  partner_id: Scalars['Int'];
};

/** columns and relationships of "partner_playlist" */
export type Partner_Playlist = {
  __typename?: 'partner_playlist';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  partner: Partner;
  partner_id: Scalars['Int'];
  partner_playlist_id: Scalars['Int'];
  /** An object relationship */
  playlist: Playlist;
  playlist_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "partner_playlist" */
export type Partner_Playlist_Aggregate = {
  __typename?: 'partner_playlist_aggregate';
  aggregate?: Maybe<Partner_Playlist_Aggregate_Fields>;
  nodes: Array<Partner_Playlist>;
};

/** aggregate fields of "partner_playlist" */
export type Partner_Playlist_Aggregate_Fields = {
  __typename?: 'partner_playlist_aggregate_fields';
  avg?: Maybe<Partner_Playlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Partner_Playlist_Max_Fields>;
  min?: Maybe<Partner_Playlist_Min_Fields>;
  stddev?: Maybe<Partner_Playlist_Stddev_Fields>;
  stddev_pop?: Maybe<Partner_Playlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Partner_Playlist_Stddev_Samp_Fields>;
  sum?: Maybe<Partner_Playlist_Sum_Fields>;
  var_pop?: Maybe<Partner_Playlist_Var_Pop_Fields>;
  var_samp?: Maybe<Partner_Playlist_Var_Samp_Fields>;
  variance?: Maybe<Partner_Playlist_Variance_Fields>;
};


/** aggregate fields of "partner_playlist" */
export type Partner_Playlist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Partner_Playlist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "partner_playlist" */
export type Partner_Playlist_Aggregate_Order_By = {
  avg?: Maybe<Partner_Playlist_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Partner_Playlist_Max_Order_By>;
  min?: Maybe<Partner_Playlist_Min_Order_By>;
  stddev?: Maybe<Partner_Playlist_Stddev_Order_By>;
  stddev_pop?: Maybe<Partner_Playlist_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Partner_Playlist_Stddev_Samp_Order_By>;
  sum?: Maybe<Partner_Playlist_Sum_Order_By>;
  var_pop?: Maybe<Partner_Playlist_Var_Pop_Order_By>;
  var_samp?: Maybe<Partner_Playlist_Var_Samp_Order_By>;
  variance?: Maybe<Partner_Playlist_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "partner_playlist" */
export type Partner_Playlist_Arr_Rel_Insert_Input = {
  data: Array<Partner_Playlist_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Partner_Playlist_On_Conflict>;
};

/** aggregate avg on columns */
export type Partner_Playlist_Avg_Fields = {
  __typename?: 'partner_playlist_avg_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "partner_playlist" */
export type Partner_Playlist_Avg_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "partner_playlist". All fields are combined with a logical 'AND'. */
export type Partner_Playlist_Bool_Exp = {
  _and?: Maybe<Array<Partner_Playlist_Bool_Exp>>;
  _not?: Maybe<Partner_Playlist_Bool_Exp>;
  _or?: Maybe<Array<Partner_Playlist_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  partner?: Maybe<Partner_Bool_Exp>;
  partner_id?: Maybe<Int_Comparison_Exp>;
  partner_playlist_id?: Maybe<Int_Comparison_Exp>;
  playlist?: Maybe<Playlist_Bool_Exp>;
  playlist_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner_playlist" */
export enum Partner_Playlist_Constraint {
  /** unique or primary key constraint */
  PartnerPlaylistPartnerIdPlaylistIdKey = 'partner_playlist_partner_id_playlist_id_key',
  /** unique or primary key constraint */
  PartnerPlaylistPkey = 'partner_playlist_pkey'
}

/** input type for incrementing numeric columns in table "partner_playlist" */
export type Partner_Playlist_Inc_Input = {
  partner_id?: Maybe<Scalars['Int']>;
  partner_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "partner_playlist" */
export type Partner_Playlist_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  partner?: Maybe<Partner_Obj_Rel_Insert_Input>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_playlist_id?: Maybe<Scalars['Int']>;
  playlist?: Maybe<Playlist_Obj_Rel_Insert_Input>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Partner_Playlist_Max_Fields = {
  __typename?: 'partner_playlist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "partner_playlist" */
export type Partner_Playlist_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Partner_Playlist_Min_Fields = {
  __typename?: 'partner_playlist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "partner_playlist" */
export type Partner_Playlist_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "partner_playlist" */
export type Partner_Playlist_Mutation_Response = {
  __typename?: 'partner_playlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Playlist>;
};

/** on_conflict condition type for table "partner_playlist" */
export type Partner_Playlist_On_Conflict = {
  constraint: Partner_Playlist_Constraint;
  update_columns?: Array<Partner_Playlist_Update_Column>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_playlist". */
export type Partner_Playlist_Order_By = {
  created_at?: Maybe<Order_By>;
  partner?: Maybe<Partner_Order_By>;
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist?: Maybe<Playlist_Order_By>;
  playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: partner_playlist */
export type Partner_Playlist_Pk_Columns_Input = {
  partner_playlist_id: Scalars['Int'];
};

/** select columns of table "partner_playlist" */
export enum Partner_Playlist_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PartnerPlaylistId = 'partner_playlist_id',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "partner_playlist" */
export type Partner_Playlist_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  partner_id?: Maybe<Scalars['Int']>;
  partner_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Partner_Playlist_Stddev_Fields = {
  __typename?: 'partner_playlist_stddev_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "partner_playlist" */
export type Partner_Playlist_Stddev_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Partner_Playlist_Stddev_Pop_Fields = {
  __typename?: 'partner_playlist_stddev_pop_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "partner_playlist" */
export type Partner_Playlist_Stddev_Pop_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Partner_Playlist_Stddev_Samp_Fields = {
  __typename?: 'partner_playlist_stddev_samp_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "partner_playlist" */
export type Partner_Playlist_Stddev_Samp_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Partner_Playlist_Sum_Fields = {
  __typename?: 'partner_playlist_sum_fields';
  partner_id?: Maybe<Scalars['Int']>;
  partner_playlist_id?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "partner_playlist" */
export type Partner_Playlist_Sum_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** update columns of table "partner_playlist" */
export enum Partner_Playlist_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  PartnerPlaylistId = 'partner_playlist_id',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Partner_Playlist_Var_Pop_Fields = {
  __typename?: 'partner_playlist_var_pop_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "partner_playlist" */
export type Partner_Playlist_Var_Pop_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Partner_Playlist_Var_Samp_Fields = {
  __typename?: 'partner_playlist_var_samp_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "partner_playlist" */
export type Partner_Playlist_Var_Samp_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Partner_Playlist_Variance_Fields = {
  __typename?: 'partner_playlist_variance_fields';
  partner_id?: Maybe<Scalars['Float']>;
  partner_playlist_id?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "partner_playlist" */
export type Partner_Playlist_Variance_Order_By = {
  partner_id?: Maybe<Order_By>;
  partner_playlist_id?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
};

/** select columns of table "partner" */
export enum Partner_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  BannerDescription = 'banner_description',
  /** column name */
  BannerImagePath = 'banner_image_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  HeadingDescription = 'heading_description',
  /** column name */
  HeadingShortDescription = 'heading_short_description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  LogoImagePath = 'logo_image_path',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Order = 'order',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** input type for updating data in table "partner" */
export type Partner_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  banner_description?: Maybe<Scalars['String']>;
  banner_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['Boolean']>;
  heading_description?: Maybe<Scalars['String']>;
  heading_short_description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  logo_image_path?: Maybe<Scalars['String']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  partner_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Partner_Stddev_Fields = {
  __typename?: 'partner_stddev_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Partner_Stddev_Pop_Fields = {
  __typename?: 'partner_stddev_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Partner_Stddev_Samp_Fields = {
  __typename?: 'partner_stddev_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Partner_Sum_Fields = {
  __typename?: 'partner_sum_fields';
  annual_price?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  partner_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
};

/** update columns of table "partner" */
export enum Partner_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  BannerDescription = 'banner_description',
  /** column name */
  BannerImagePath = 'banner_image_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Feature = 'feature',
  /** column name */
  HeadingDescription = 'heading_description',
  /** column name */
  HeadingShortDescription = 'heading_short_description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  LogoImagePath = 'logo_image_path',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Order = 'order',
  /** column name */
  PartnerId = 'partner_id',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** aggregate var_pop on columns */
export type Partner_Var_Pop_Fields = {
  __typename?: 'partner_var_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Partner_Var_Samp_Fields = {
  __typename?: 'partner_var_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Partner_Variance_Fields = {
  __typename?: 'partner_variance_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  partner_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "playlist" */
export type Playlist = {
  __typename?: 'playlist';
  active: Scalars['Boolean'];
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  channel_playlists: Array<Channel_Playlist>;
  /** An aggregate relationship */
  channel_playlists_aggregate: Channel_Playlist_Aggregate;
  /** An array relationship */
  class_playlists: Array<Class_Playlist>;
  /** An aggregate relationship */
  class_playlists_aggregate: Class_Playlist_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  display_video?: Maybe<Scalars['Boolean']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  /** A computed field, executes function "get_playlist_status_by_teacher" */
  get_status_by_teacher?: Maybe<Scalars['String']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** A computed field, executes function "is_playlist_plan_purchased_by_teacher" */
  is_purchased_by_teacher?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['String'];
  /** An array relationship */
  order_details: Array<Order>;
  /** An aggregate relationship */
  order_details_aggregate: Order_Aggregate;
  /** An array relationship */
  partner_playlists: Array<Partner_Playlist>;
  /** An aggregate relationship */
  partner_playlists_aggregate: Partner_Playlist_Aggregate;
  playlist_id: Scalars['Int'];
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  tip_counter: Scalars['Int'];
  /** An array relationship */
  tip_playlist: Array<Tip_Playlist>;
  /** An aggregate relationship */
  tip_playlist_aggregate: Tip_Playlist_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "playlist" */
export type PlaylistChannel_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistChannel_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistClass_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistClass_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistOrder_DetailsArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistOrder_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistPartner_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistPartner_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistTip_PlaylistArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};


/** columns and relationships of "playlist" */
export type PlaylistTip_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};

/** aggregated selection of "playlist" */
export type Playlist_Aggregate = {
  __typename?: 'playlist_aggregate';
  aggregate?: Maybe<Playlist_Aggregate_Fields>;
  nodes: Array<Playlist>;
};

/** aggregate fields of "playlist" */
export type Playlist_Aggregate_Fields = {
  __typename?: 'playlist_aggregate_fields';
  avg?: Maybe<Playlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Playlist_Max_Fields>;
  min?: Maybe<Playlist_Min_Fields>;
  stddev?: Maybe<Playlist_Stddev_Fields>;
  stddev_pop?: Maybe<Playlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Playlist_Stddev_Samp_Fields>;
  sum?: Maybe<Playlist_Sum_Fields>;
  var_pop?: Maybe<Playlist_Var_Pop_Fields>;
  var_samp?: Maybe<Playlist_Var_Samp_Fields>;
  variance?: Maybe<Playlist_Variance_Fields>;
};


/** aggregate fields of "playlist" */
export type Playlist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Playlist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Playlist_Avg_Fields = {
  __typename?: 'playlist_avg_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "playlist". All fields are combined with a logical 'AND'. */
export type Playlist_Bool_Exp = {
  _and?: Maybe<Array<Playlist_Bool_Exp>>;
  _not?: Maybe<Playlist_Bool_Exp>;
  _or?: Maybe<Array<Playlist_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  annual_price?: Maybe<Int_Comparison_Exp>;
  annual_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  channel_playlists?: Maybe<Channel_Playlist_Bool_Exp>;
  class_playlists?: Maybe<Class_Playlist_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  display_video?: Maybe<Boolean_Comparison_Exp>;
  district_price?: Maybe<Int_Comparison_Exp>;
  district_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  featured?: Maybe<Boolean_Comparison_Exp>;
  get_status_by_teacher?: Maybe<String_Comparison_Exp>;
  header_image_path?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_purchased_by_teacher?: Maybe<Boolean_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  monthly_price?: Maybe<Int_Comparison_Exp>;
  monthly_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<String_Comparison_Exp>;
  order_details?: Maybe<Order_Bool_Exp>;
  partner_playlists?: Maybe<Partner_Playlist_Bool_Exp>;
  playlist_id?: Maybe<Int_Comparison_Exp>;
  school_price?: Maybe<Int_Comparison_Exp>;
  school_price_stripe_price_id?: Maybe<String_Comparison_Exp>;
  stripe_product_id?: Maybe<String_Comparison_Exp>;
  tip_counter?: Maybe<Int_Comparison_Exp>;
  tip_playlist?: Maybe<Tip_Playlist_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  video_path?: Maybe<String_Comparison_Exp>;
  video_thumbnail_image_path?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "playlist" */
export enum Playlist_Constraint {
  /** unique or primary key constraint */
  PlaylistAnnualPriceStripePriceIdKey = 'playlist_annual_price_stripe_price_id_key',
  /** unique or primary key constraint */
  PlaylistDistrictPriceStripePriceIdKey = 'playlist_district_price_stripe_price_id_key',
  /** unique or primary key constraint */
  PlaylistLockableContentIdKey = 'playlist_lockable_content_id_key',
  /** unique or primary key constraint */
  PlaylistMonthlyPriceStripePriceIdKey = 'playlist_monthly_price_stripe_price_id_key',
  /** unique or primary key constraint */
  PlaylistPkey = 'playlist_pkey',
  /** unique or primary key constraint */
  PlaylistSchoolPriceStripePriceIdKey = 'playlist_school_price_stripe_price_id_key'
}

/** input type for incrementing numeric columns in table "playlist" */
export type Playlist_Inc_Input = {
  annual_price?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  tip_counter?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "playlist" */
export type Playlist_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  channel_playlists?: Maybe<Channel_Playlist_Arr_Rel_Insert_Input>;
  class_playlists?: Maybe<Class_Playlist_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_video?: Maybe<Scalars['Boolean']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  order_details?: Maybe<Order_Arr_Rel_Insert_Input>;
  partner_playlists?: Maybe<Partner_Playlist_Arr_Rel_Insert_Input>;
  playlist_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  tip_counter?: Maybe<Scalars['Int']>;
  tip_playlist?: Maybe<Tip_Playlist_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Playlist_Max_Fields = {
  __typename?: 'playlist_max_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  playlist_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  tip_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Playlist_Min_Fields = {
  __typename?: 'playlist_min_fields';
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  playlist_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  tip_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "playlist" */
export type Playlist_Mutation_Response = {
  __typename?: 'playlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Playlist>;
};

/** input type for inserting object relation for remote table "playlist" */
export type Playlist_Obj_Rel_Insert_Input = {
  data: Playlist_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Playlist_On_Conflict>;
};

/** on_conflict condition type for table "playlist" */
export type Playlist_On_Conflict = {
  constraint: Playlist_Constraint;
  update_columns?: Array<Playlist_Update_Column>;
  where?: Maybe<Playlist_Bool_Exp>;
};

/** Ordering options when selecting data from "playlist". */
export type Playlist_Order_By = {
  active?: Maybe<Order_By>;
  annual_price?: Maybe<Order_By>;
  annual_price_stripe_price_id?: Maybe<Order_By>;
  channel_playlists_aggregate?: Maybe<Channel_Playlist_Aggregate_Order_By>;
  class_playlists_aggregate?: Maybe<Class_Playlist_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display_video?: Maybe<Order_By>;
  district_price?: Maybe<Order_By>;
  district_price_stripe_price_id?: Maybe<Order_By>;
  featured?: Maybe<Order_By>;
  get_status_by_teacher?: Maybe<Order_By>;
  header_image_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_purchased_by_teacher?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  monthly_price?: Maybe<Order_By>;
  monthly_price_stripe_price_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_details_aggregate?: Maybe<Order_Aggregate_Order_By>;
  partner_playlists_aggregate?: Maybe<Partner_Playlist_Aggregate_Order_By>;
  playlist_id?: Maybe<Order_By>;
  school_price?: Maybe<Order_By>;
  school_price_stripe_price_id?: Maybe<Order_By>;
  stripe_product_id?: Maybe<Order_By>;
  tip_counter?: Maybe<Order_By>;
  tip_playlist_aggregate?: Maybe<Tip_Playlist_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  video_path?: Maybe<Order_By>;
  video_thumbnail_image_path?: Maybe<Order_By>;
};

/** primary key columns input for table: playlist */
export type Playlist_Pk_Columns_Input = {
  playlist_id: Scalars['Int'];
};

/** select columns of table "playlist" */
export enum Playlist_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayVideo = 'display_video',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Featured = 'featured',
  /** column name */
  HeaderImagePath = 'header_image_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  TipCounter = 'tip_counter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** input type for updating data in table "playlist" */
export type Playlist_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  annual_price?: Maybe<Scalars['Int']>;
  annual_price_stripe_price_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_video?: Maybe<Scalars['Boolean']>;
  district_price?: Maybe<Scalars['Int']>;
  district_price_stripe_price_id?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  header_image_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  monthly_price_stripe_price_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  playlist_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  school_price_stripe_price_id?: Maybe<Scalars['String']>;
  stripe_product_id?: Maybe<Scalars['String']>;
  tip_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video_path?: Maybe<Scalars['String']>;
  video_thumbnail_image_path?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Playlist_Stddev_Fields = {
  __typename?: 'playlist_stddev_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Playlist_Stddev_Pop_Fields = {
  __typename?: 'playlist_stddev_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Playlist_Stddev_Samp_Fields = {
  __typename?: 'playlist_stddev_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Playlist_Sum_Fields = {
  __typename?: 'playlist_sum_fields';
  annual_price?: Maybe<Scalars['Int']>;
  district_price?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  monthly_price?: Maybe<Scalars['Int']>;
  playlist_id?: Maybe<Scalars['Int']>;
  school_price?: Maybe<Scalars['Int']>;
  tip_counter?: Maybe<Scalars['Int']>;
};

/** update columns of table "playlist" */
export enum Playlist_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AnnualPrice = 'annual_price',
  /** column name */
  AnnualPriceStripePriceId = 'annual_price_stripe_price_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayVideo = 'display_video',
  /** column name */
  DistrictPrice = 'district_price',
  /** column name */
  DistrictPriceStripePriceId = 'district_price_stripe_price_id',
  /** column name */
  Featured = 'featured',
  /** column name */
  HeaderImagePath = 'header_image_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  MonthlyPrice = 'monthly_price',
  /** column name */
  MonthlyPriceStripePriceId = 'monthly_price_stripe_price_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  SchoolPrice = 'school_price',
  /** column name */
  SchoolPriceStripePriceId = 'school_price_stripe_price_id',
  /** column name */
  StripeProductId = 'stripe_product_id',
  /** column name */
  TipCounter = 'tip_counter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoPath = 'video_path',
  /** column name */
  VideoThumbnailImagePath = 'video_thumbnail_image_path'
}

/** aggregate var_pop on columns */
export type Playlist_Var_Pop_Fields = {
  __typename?: 'playlist_var_pop_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Playlist_Var_Samp_Fields = {
  __typename?: 'playlist_var_samp_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Playlist_Variance_Fields = {
  __typename?: 'playlist_variance_fields';
  annual_price?: Maybe<Scalars['Float']>;
  district_price?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  monthly_price?: Maybe<Scalars['Float']>;
  playlist_id?: Maybe<Scalars['Float']>;
  school_price?: Maybe<Scalars['Float']>;
  tip_counter?: Maybe<Scalars['Float']>;
};

export type PresignedUrlData = {
  __typename?: 'presignedUrlData';
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "price_type" */
export type Price_Type = {
  __typename?: 'price_type';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "price_type" */
export type Price_Type_Aggregate = {
  __typename?: 'price_type_aggregate';
  aggregate?: Maybe<Price_Type_Aggregate_Fields>;
  nodes: Array<Price_Type>;
};

/** aggregate fields of "price_type" */
export type Price_Type_Aggregate_Fields = {
  __typename?: 'price_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Price_Type_Max_Fields>;
  min?: Maybe<Price_Type_Min_Fields>;
};


/** aggregate fields of "price_type" */
export type Price_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Price_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "price_type". All fields are combined with a logical 'AND'. */
export type Price_Type_Bool_Exp = {
  _and?: Maybe<Array<Price_Type_Bool_Exp>>;
  _not?: Maybe<Price_Type_Bool_Exp>;
  _or?: Maybe<Array<Price_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "price_type" */
export enum Price_Type_Constraint {
  /** unique or primary key constraint */
  PriceTypePkey = 'price_type_pkey'
}

export enum Price_Type_Enum {
  Annual = 'annual',
  District = 'district',
  Monthly = 'monthly',
  School = 'school'
}

/** Boolean expression to compare columns of type "price_type_enum". All fields are combined with logical 'AND'. */
export type Price_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Price_Type_Enum>;
  _in?: Maybe<Array<Price_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Price_Type_Enum>;
  _nin?: Maybe<Array<Price_Type_Enum>>;
};

/** input type for inserting data into table "price_type" */
export type Price_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Price_Type_Max_Fields = {
  __typename?: 'price_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Price_Type_Min_Fields = {
  __typename?: 'price_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "price_type" */
export type Price_Type_Mutation_Response = {
  __typename?: 'price_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Price_Type>;
};

/** on_conflict condition type for table "price_type" */
export type Price_Type_On_Conflict = {
  constraint: Price_Type_Constraint;
  update_columns?: Array<Price_Type_Update_Column>;
  where?: Maybe<Price_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "price_type". */
export type Price_Type_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: price_type */
export type Price_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "price_type" */
export enum Price_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "price_type" */
export type Price_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "price_type" */
export enum Price_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "privacy" */
export type Privacy = {
  __typename?: 'privacy';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "privacy" */
export type Privacy_Aggregate = {
  __typename?: 'privacy_aggregate';
  aggregate?: Maybe<Privacy_Aggregate_Fields>;
  nodes: Array<Privacy>;
};

/** aggregate fields of "privacy" */
export type Privacy_Aggregate_Fields = {
  __typename?: 'privacy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Privacy_Max_Fields>;
  min?: Maybe<Privacy_Min_Fields>;
};


/** aggregate fields of "privacy" */
export type Privacy_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Privacy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "privacy". All fields are combined with a logical 'AND'. */
export type Privacy_Bool_Exp = {
  _and?: Maybe<Array<Privacy_Bool_Exp>>;
  _not?: Maybe<Privacy_Bool_Exp>;
  _or?: Maybe<Array<Privacy_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "privacy" */
export enum Privacy_Constraint {
  /** unique or primary key constraint */
  PrivacyPkey = 'privacy_pkey'
}

export enum Privacy_Enum {
  /** Class Teacher And Me */
  Class = 'class',
  /** Only Me */
  OnlyMe = 'only_me',
  /** Teacher And Me */
  TeacherAndMe = 'teacher_and_me'
}

/** Boolean expression to compare columns of type "privacy_enum". All fields are combined with logical 'AND'. */
export type Privacy_Enum_Comparison_Exp = {
  _eq?: Maybe<Privacy_Enum>;
  _in?: Maybe<Array<Privacy_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Privacy_Enum>;
  _nin?: Maybe<Array<Privacy_Enum>>;
};

/** input type for inserting data into table "privacy" */
export type Privacy_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Privacy_Max_Fields = {
  __typename?: 'privacy_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Privacy_Min_Fields = {
  __typename?: 'privacy_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "privacy" */
export type Privacy_Mutation_Response = {
  __typename?: 'privacy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Privacy>;
};

/** on_conflict condition type for table "privacy" */
export type Privacy_On_Conflict = {
  constraint: Privacy_Constraint;
  update_columns?: Array<Privacy_Update_Column>;
  where?: Maybe<Privacy_Bool_Exp>;
};

/** Ordering options when selecting data from "privacy". */
export type Privacy_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: privacy */
export type Privacy_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "privacy" */
export enum Privacy_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "privacy" */
export type Privacy_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "privacy" */
export enum Privacy_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** Corresponding names of tables that are products */
export type Product_Name = {
  __typename?: 'product_name';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "product_name" */
export type Product_Name_Aggregate = {
  __typename?: 'product_name_aggregate';
  aggregate?: Maybe<Product_Name_Aggregate_Fields>;
  nodes: Array<Product_Name>;
};

/** aggregate fields of "product_name" */
export type Product_Name_Aggregate_Fields = {
  __typename?: 'product_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Name_Max_Fields>;
  min?: Maybe<Product_Name_Min_Fields>;
};


/** aggregate fields of "product_name" */
export type Product_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product_name". All fields are combined with a logical 'AND'. */
export type Product_Name_Bool_Exp = {
  _and?: Maybe<Array<Product_Name_Bool_Exp>>;
  _not?: Maybe<Product_Name_Bool_Exp>;
  _or?: Maybe<Array<Product_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_name" */
export enum Product_Name_Constraint {
  /** unique or primary key constraint */
  ProductNamePkey = 'product_name_pkey'
}

export enum Product_Name_Enum {
  Channel = 'channel',
  LessonPlan = 'lesson_plan',
  Module = 'module',
  Partner = 'partner',
  Playlist = 'playlist'
}

/** Boolean expression to compare columns of type "product_name_enum". All fields are combined with logical 'AND'. */
export type Product_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Product_Name_Enum>;
  _in?: Maybe<Array<Product_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Product_Name_Enum>;
  _nin?: Maybe<Array<Product_Name_Enum>>;
};

/** input type for inserting data into table "product_name" */
export type Product_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Name_Max_Fields = {
  __typename?: 'product_name_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Name_Min_Fields = {
  __typename?: 'product_name_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "product_name" */
export type Product_Name_Mutation_Response = {
  __typename?: 'product_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Name>;
};

/** on_conflict condition type for table "product_name" */
export type Product_Name_On_Conflict = {
  constraint: Product_Name_Constraint;
  update_columns?: Array<Product_Name_Update_Column>;
  where?: Maybe<Product_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "product_name". */
export type Product_Name_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: product_name */
export type Product_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "product_name" */
export enum Product_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "product_name" */
export type Product_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "product_name" */
export enum Product_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "publisher" */
export type Publisher = {
  __typename?: 'publisher';
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update: Scalars['timestamptz'];
  name: Scalars['String'];
  notes: Scalars['String'];
  primary_contact_email: Scalars['String'];
  primary_contact_name: Scalars['String'];
  publisher_city: Scalars['String'];
  publisher_id: Scalars['Int'];
  publisher_state: Scalars['String'];
  publisher_street: Scalars['String'];
  publisher_street2: Scalars['String'];
  publisher_zip: Scalars['Int'];
  secondary_contact_email: Scalars['String'];
  secondary_contact_name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "publisher" */
export type Publisher_Aggregate = {
  __typename?: 'publisher_aggregate';
  aggregate?: Maybe<Publisher_Aggregate_Fields>;
  nodes: Array<Publisher>;
};

/** aggregate fields of "publisher" */
export type Publisher_Aggregate_Fields = {
  __typename?: 'publisher_aggregate_fields';
  avg?: Maybe<Publisher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Publisher_Max_Fields>;
  min?: Maybe<Publisher_Min_Fields>;
  stddev?: Maybe<Publisher_Stddev_Fields>;
  stddev_pop?: Maybe<Publisher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Publisher_Stddev_Samp_Fields>;
  sum?: Maybe<Publisher_Sum_Fields>;
  var_pop?: Maybe<Publisher_Var_Pop_Fields>;
  var_samp?: Maybe<Publisher_Var_Samp_Fields>;
  variance?: Maybe<Publisher_Variance_Fields>;
};


/** aggregate fields of "publisher" */
export type Publisher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Publisher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Publisher_Avg_Fields = {
  __typename?: 'publisher_avg_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "publisher". All fields are combined with a logical 'AND'. */
export type Publisher_Bool_Exp = {
  _and?: Maybe<Array<Publisher_Bool_Exp>>;
  _not?: Maybe<Publisher_Bool_Exp>;
  _or?: Maybe<Array<Publisher_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  primary_contact_email?: Maybe<String_Comparison_Exp>;
  primary_contact_name?: Maybe<String_Comparison_Exp>;
  publisher_city?: Maybe<String_Comparison_Exp>;
  publisher_id?: Maybe<Int_Comparison_Exp>;
  publisher_state?: Maybe<String_Comparison_Exp>;
  publisher_street?: Maybe<String_Comparison_Exp>;
  publisher_street2?: Maybe<String_Comparison_Exp>;
  publisher_zip?: Maybe<Int_Comparison_Exp>;
  secondary_contact_email?: Maybe<String_Comparison_Exp>;
  secondary_contact_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "publisher" */
export enum Publisher_Constraint {
  /** unique or primary key constraint */
  PublisherPkey = 'publisher_pkey'
}

/** columns and relationships of "publisher_control" */
export type Publisher_Control = {
  __typename?: 'publisher_control';
  control: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  notes: Scalars['String'];
  publisher_control_id: Scalars['Int'];
  publisher_id: Scalars['Int'];
  song_id: Scalars['Int'];
  updated_at: Scalars['timestamp'];
  user_update: Scalars['Int'];
};

/** aggregated selection of "publisher_control" */
export type Publisher_Control_Aggregate = {
  __typename?: 'publisher_control_aggregate';
  aggregate?: Maybe<Publisher_Control_Aggregate_Fields>;
  nodes: Array<Publisher_Control>;
};

/** aggregate fields of "publisher_control" */
export type Publisher_Control_Aggregate_Fields = {
  __typename?: 'publisher_control_aggregate_fields';
  avg?: Maybe<Publisher_Control_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Publisher_Control_Max_Fields>;
  min?: Maybe<Publisher_Control_Min_Fields>;
  stddev?: Maybe<Publisher_Control_Stddev_Fields>;
  stddev_pop?: Maybe<Publisher_Control_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Publisher_Control_Stddev_Samp_Fields>;
  sum?: Maybe<Publisher_Control_Sum_Fields>;
  var_pop?: Maybe<Publisher_Control_Var_Pop_Fields>;
  var_samp?: Maybe<Publisher_Control_Var_Samp_Fields>;
  variance?: Maybe<Publisher_Control_Variance_Fields>;
};


/** aggregate fields of "publisher_control" */
export type Publisher_Control_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Publisher_Control_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Publisher_Control_Avg_Fields = {
  __typename?: 'publisher_control_avg_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "publisher_control". All fields are combined with a logical 'AND'. */
export type Publisher_Control_Bool_Exp = {
  _and?: Maybe<Array<Publisher_Control_Bool_Exp>>;
  _not?: Maybe<Publisher_Control_Bool_Exp>;
  _or?: Maybe<Array<Publisher_Control_Bool_Exp>>;
  control?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  publisher_control_id?: Maybe<Int_Comparison_Exp>;
  publisher_id?: Maybe<Int_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  user_update?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "publisher_control" */
export enum Publisher_Control_Constraint {
  /** unique or primary key constraint */
  PublisherControlPkey = 'publisher_control_pkey'
}

/** input type for incrementing numeric columns in table "publisher_control" */
export type Publisher_Control_Inc_Input = {
  control?: Maybe<Scalars['Int']>;
  publisher_control_id?: Maybe<Scalars['Int']>;
  publisher_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  user_update?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "publisher_control" */
export type Publisher_Control_Insert_Input = {
  control?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  notes?: Maybe<Scalars['String']>;
  publisher_control_id?: Maybe<Scalars['Int']>;
  publisher_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_update?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Publisher_Control_Max_Fields = {
  __typename?: 'publisher_control_max_fields';
  control?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  notes?: Maybe<Scalars['String']>;
  publisher_control_id?: Maybe<Scalars['Int']>;
  publisher_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_update?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Publisher_Control_Min_Fields = {
  __typename?: 'publisher_control_min_fields';
  control?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  notes?: Maybe<Scalars['String']>;
  publisher_control_id?: Maybe<Scalars['Int']>;
  publisher_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_update?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "publisher_control" */
export type Publisher_Control_Mutation_Response = {
  __typename?: 'publisher_control_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Publisher_Control>;
};

/** input type for inserting object relation for remote table "publisher_control" */
export type Publisher_Control_Obj_Rel_Insert_Input = {
  data: Publisher_Control_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Publisher_Control_On_Conflict>;
};

/** on_conflict condition type for table "publisher_control" */
export type Publisher_Control_On_Conflict = {
  constraint: Publisher_Control_Constraint;
  update_columns?: Array<Publisher_Control_Update_Column>;
  where?: Maybe<Publisher_Control_Bool_Exp>;
};

/** Ordering options when selecting data from "publisher_control". */
export type Publisher_Control_Order_By = {
  control?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  publisher_control_id?: Maybe<Order_By>;
  publisher_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_update?: Maybe<Order_By>;
};

/** primary key columns input for table: publisher_control */
export type Publisher_Control_Pk_Columns_Input = {
  publisher_control_id: Scalars['Int'];
};

/** select columns of table "publisher_control" */
export enum Publisher_Control_Select_Column {
  /** column name */
  Control = 'control',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Notes = 'notes',
  /** column name */
  PublisherControlId = 'publisher_control_id',
  /** column name */
  PublisherId = 'publisher_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserUpdate = 'user_update'
}

/** input type for updating data in table "publisher_control" */
export type Publisher_Control_Set_Input = {
  control?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  notes?: Maybe<Scalars['String']>;
  publisher_control_id?: Maybe<Scalars['Int']>;
  publisher_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_update?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Publisher_Control_Stddev_Fields = {
  __typename?: 'publisher_control_stddev_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Publisher_Control_Stddev_Pop_Fields = {
  __typename?: 'publisher_control_stddev_pop_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Publisher_Control_Stddev_Samp_Fields = {
  __typename?: 'publisher_control_stddev_samp_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Publisher_Control_Sum_Fields = {
  __typename?: 'publisher_control_sum_fields';
  control?: Maybe<Scalars['Int']>;
  publisher_control_id?: Maybe<Scalars['Int']>;
  publisher_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  user_update?: Maybe<Scalars['Int']>;
};

/** update columns of table "publisher_control" */
export enum Publisher_Control_Update_Column {
  /** column name */
  Control = 'control',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Notes = 'notes',
  /** column name */
  PublisherControlId = 'publisher_control_id',
  /** column name */
  PublisherId = 'publisher_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserUpdate = 'user_update'
}

/** aggregate var_pop on columns */
export type Publisher_Control_Var_Pop_Fields = {
  __typename?: 'publisher_control_var_pop_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Publisher_Control_Var_Samp_Fields = {
  __typename?: 'publisher_control_var_samp_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Publisher_Control_Variance_Fields = {
  __typename?: 'publisher_control_variance_fields';
  control?: Maybe<Scalars['Float']>;
  publisher_control_id?: Maybe<Scalars['Float']>;
  publisher_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  user_update?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "publisher" */
export type Publisher_Inc_Input = {
  publisher_id?: Maybe<Scalars['Int']>;
  publisher_zip?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "publisher" */
export type Publisher_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['Int']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Publisher_Max_Fields = {
  __typename?: 'publisher_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['Int']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Publisher_Min_Fields = {
  __typename?: 'publisher_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['Int']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "publisher" */
export type Publisher_Mutation_Response = {
  __typename?: 'publisher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Publisher>;
};

/** on_conflict condition type for table "publisher" */
export type Publisher_On_Conflict = {
  constraint: Publisher_Constraint;
  update_columns?: Array<Publisher_Update_Column>;
  where?: Maybe<Publisher_Bool_Exp>;
};

/** Ordering options when selecting data from "publisher". */
export type Publisher_Order_By = {
  created_at?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  primary_contact_email?: Maybe<Order_By>;
  primary_contact_name?: Maybe<Order_By>;
  publisher_city?: Maybe<Order_By>;
  publisher_id?: Maybe<Order_By>;
  publisher_state?: Maybe<Order_By>;
  publisher_street?: Maybe<Order_By>;
  publisher_street2?: Maybe<Order_By>;
  publisher_zip?: Maybe<Order_By>;
  secondary_contact_email?: Maybe<Order_By>;
  secondary_contact_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: publisher */
export type Publisher_Pk_Columns_Input = {
  publisher_id: Scalars['Int'];
};

/** select columns of table "publisher" */
export enum Publisher_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PrimaryContactEmail = 'primary_contact_email',
  /** column name */
  PrimaryContactName = 'primary_contact_name',
  /** column name */
  PublisherCity = 'publisher_city',
  /** column name */
  PublisherId = 'publisher_id',
  /** column name */
  PublisherState = 'publisher_state',
  /** column name */
  PublisherStreet = 'publisher_street',
  /** column name */
  PublisherStreet2 = 'publisher_street2',
  /** column name */
  PublisherZip = 'publisher_zip',
  /** column name */
  SecondaryContactEmail = 'secondary_contact_email',
  /** column name */
  SecondaryContactName = 'secondary_contact_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "publisher" */
export type Publisher_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  primary_contact_email?: Maybe<Scalars['String']>;
  primary_contact_name?: Maybe<Scalars['String']>;
  publisher_city?: Maybe<Scalars['String']>;
  publisher_id?: Maybe<Scalars['Int']>;
  publisher_state?: Maybe<Scalars['String']>;
  publisher_street?: Maybe<Scalars['String']>;
  publisher_street2?: Maybe<Scalars['String']>;
  publisher_zip?: Maybe<Scalars['Int']>;
  secondary_contact_email?: Maybe<Scalars['String']>;
  secondary_contact_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Publisher_Stddev_Fields = {
  __typename?: 'publisher_stddev_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Publisher_Stddev_Pop_Fields = {
  __typename?: 'publisher_stddev_pop_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Publisher_Stddev_Samp_Fields = {
  __typename?: 'publisher_stddev_samp_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Publisher_Sum_Fields = {
  __typename?: 'publisher_sum_fields';
  publisher_id?: Maybe<Scalars['Int']>;
  publisher_zip?: Maybe<Scalars['Int']>;
};

/** update columns of table "publisher" */
export enum Publisher_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PrimaryContactEmail = 'primary_contact_email',
  /** column name */
  PrimaryContactName = 'primary_contact_name',
  /** column name */
  PublisherCity = 'publisher_city',
  /** column name */
  PublisherId = 'publisher_id',
  /** column name */
  PublisherState = 'publisher_state',
  /** column name */
  PublisherStreet = 'publisher_street',
  /** column name */
  PublisherStreet2 = 'publisher_street2',
  /** column name */
  PublisherZip = 'publisher_zip',
  /** column name */
  SecondaryContactEmail = 'secondary_contact_email',
  /** column name */
  SecondaryContactName = 'secondary_contact_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Publisher_Var_Pop_Fields = {
  __typename?: 'publisher_var_pop_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Publisher_Var_Samp_Fields = {
  __typename?: 'publisher_var_samp_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Publisher_Variance_Fields = {
  __typename?: 'publisher_variance_fields';
  publisher_id?: Maybe<Scalars['Float']>;
  publisher_zip?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "account_activation" */
  account_activation: Array<Account_Activation>;
  /** fetch aggregated fields from the table: "account_activation" */
  account_activation_aggregate: Account_Activation_Aggregate;
  /** fetch data from the table: "account_activation" using primary key columns */
  account_activation_by_pk?: Maybe<Account_Activation>;
  addStudentsByBulkQRs?: Maybe<AddStudentsByBulkQRsData>;
  /** fetch data from the table: "admin" */
  admin: Array<Admin>;
  adminLogin?: Maybe<AdminLoginData>;
  /** fetch aggregated fields from the table: "admin" */
  admin_aggregate: Admin_Aggregate;
  /** fetch data from the table: "admin" using primary key columns */
  admin_by_pk?: Maybe<Admin>;
  /** fetch data from the table: "app" */
  app: Array<App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App>;
  applySticker?: Maybe<ApplyStickerResponse>;
  /** fetch data from the table: "artist" */
  artist: Array<Artist>;
  /** fetch aggregated fields from the table: "artist" */
  artist_aggregate: Artist_Aggregate;
  /** fetch data from the table: "artist" using primary key columns */
  artist_by_pk?: Maybe<Artist>;
  /** fetch data from the table: "asset" */
  asset: Array<Asset>;
  /** fetch aggregated fields from the table: "asset" */
  asset_aggregate: Asset_Aggregate;
  /** fetch data from the table: "asset" using primary key columns */
  asset_by_pk?: Maybe<Asset>;
  /** fetch data from the table: "assignment" */
  assignment: Array<Assignment>;
  /** fetch aggregated fields from the table: "assignment" */
  assignment_aggregate: Assignment_Aggregate;
  /** fetch data from the table: "assignment_asset" */
  assignment_asset: Array<Assignment_Asset>;
  /** fetch aggregated fields from the table: "assignment_asset" */
  assignment_asset_aggregate: Assignment_Asset_Aggregate;
  /** fetch data from the table: "assignment_asset" using primary key columns */
  assignment_asset_by_pk?: Maybe<Assignment_Asset>;
  /** fetch data from the table: "assignment" using primary key columns */
  assignment_by_pk?: Maybe<Assignment>;
  /** fetch data from the table: "assignment_lesson_plan" */
  assignment_lesson_plan: Array<Assignment_Lesson_Plan>;
  /** fetch aggregated fields from the table: "assignment_lesson_plan" */
  assignment_lesson_plan_aggregate: Assignment_Lesson_Plan_Aggregate;
  /** fetch data from the table: "assignment_lesson_plan" using primary key columns */
  assignment_lesson_plan_by_pk?: Maybe<Assignment_Lesson_Plan>;
  /** fetch data from the table: "assignment_song" */
  assignment_song: Array<Assignment_Song>;
  /** fetch aggregated fields from the table: "assignment_song" */
  assignment_song_aggregate: Assignment_Song_Aggregate;
  /** fetch data from the table: "assignment_song" using primary key columns */
  assignment_song_by_pk?: Maybe<Assignment_Song>;
  /** fetch data from the table: "assignment_tip" */
  assignment_tip: Array<Assignment_Tip>;
  /** fetch aggregated fields from the table: "assignment_tip" */
  assignment_tip_aggregate: Assignment_Tip_Aggregate;
  /** fetch data from the table: "assignment_tip" using primary key columns */
  assignment_tip_by_pk?: Maybe<Assignment_Tip>;
  /** fetch data from the table: "available" */
  available: Array<Available>;
  /** fetch aggregated fields from the table: "available" */
  available_aggregate: Available_Aggregate;
  /** fetch data from the table: "available" using primary key columns */
  available_by_pk?: Maybe<Available>;
  /** fetch data from the table: "awarded_badge_event" */
  awarded_badge_event: Array<Awarded_Badge_Event>;
  /** fetch aggregated fields from the table: "awarded_badge_event" */
  awarded_badge_event_aggregate: Awarded_Badge_Event_Aggregate;
  /** fetch data from the table: "awarded_badge_event" using primary key columns */
  awarded_badge_event_by_pk?: Maybe<Awarded_Badge_Event>;
  /** fetch data from the table: "badge" */
  badge: Array<Badge>;
  /** fetch aggregated fields from the table: "badge" */
  badge_aggregate: Badge_Aggregate;
  /** fetch data from the table: "badge" using primary key columns */
  badge_by_pk?: Maybe<Badge>;
  /** fetch data from the table: "badge_event" */
  badge_event: Array<Badge_Event>;
  /** fetch aggregated fields from the table: "badge_event" */
  badge_event_aggregate: Badge_Event_Aggregate;
  /** fetch data from the table: "badge_event" using primary key columns */
  badge_event_by_pk?: Maybe<Badge_Event>;
  /** fetch data from the table: "badge_event_name" */
  badge_event_name: Array<Badge_Event_Name>;
  /** fetch aggregated fields from the table: "badge_event_name" */
  badge_event_name_aggregate: Badge_Event_Name_Aggregate;
  /** fetch data from the table: "badge_event_name" using primary key columns */
  badge_event_name_by_pk?: Maybe<Badge_Event_Name>;
  /** fetch data from the table: "billing_history" */
  billing_history: Array<Billing_History>;
  /** fetch aggregated fields from the table: "billing_history" */
  billing_history_aggregate: Billing_History_Aggregate;
  /** fetch data from the table: "billing_history" using primary key columns */
  billing_history_by_pk?: Maybe<Billing_History>;
  /** fetch data from the table: "billing_method" */
  billing_method: Array<Billing_Method>;
  /** fetch aggregated fields from the table: "billing_method" */
  billing_method_aggregate: Billing_Method_Aggregate;
  /** fetch data from the table: "billing_method" using primary key columns */
  billing_method_by_pk?: Maybe<Billing_Method>;
  /** fetch data from the table: "billing_status" */
  billing_status: Array<Billing_Status>;
  /** fetch aggregated fields from the table: "billing_status" */
  billing_status_aggregate: Billing_Status_Aggregate;
  /** fetch data from the table: "billing_status" using primary key columns */
  billing_status_by_pk?: Maybe<Billing_Status>;
  /** fetch data from the table: "catalog" */
  catalog: Array<Catalog>;
  /** fetch aggregated fields from the table: "catalog" */
  catalog_aggregate: Catalog_Aggregate;
  /** fetch data from the table: "catalog" using primary key columns */
  catalog_by_pk?: Maybe<Catalog>;
  /** fetch data from the table: "catalog_item" */
  catalog_item: Array<Catalog_Item>;
  /** fetch aggregated fields from the table: "catalog_item" */
  catalog_item_aggregate: Catalog_Item_Aggregate;
  /** fetch data from the table: "catalog_item" using primary key columns */
  catalog_item_by_pk?: Maybe<Catalog_Item>;
  /** fetch data from the table: "catalog_name" */
  catalog_name: Array<Catalog_Name>;
  /** fetch aggregated fields from the table: "catalog_name" */
  catalog_name_aggregate: Catalog_Name_Aggregate;
  /** fetch data from the table: "catalog_name" using primary key columns */
  catalog_name_by_pk?: Maybe<Catalog_Name>;
  /** fetch data from the table: "category_standard" */
  category_standard: Array<Category_Standard>;
  /** fetch aggregated fields from the table: "category_standard" */
  category_standard_aggregate: Category_Standard_Aggregate;
  /** fetch data from the table: "category_standard" using primary key columns */
  category_standard_by_pk?: Maybe<Category_Standard>;
  /** fetch data from the table: "challenge" */
  challenge: Array<Challenge>;
  /** fetch aggregated fields from the table: "challenge" */
  challenge_aggregate: Challenge_Aggregate;
  /** fetch data from the table: "challenge" using primary key columns */
  challenge_by_pk?: Maybe<Challenge>;
  /** fetch data from the table: "challenge_catalog_item" */
  challenge_catalog_item: Array<Challenge_Catalog_Item>;
  /** fetch aggregated fields from the table: "challenge_catalog_item" */
  challenge_catalog_item_aggregate: Challenge_Catalog_Item_Aggregate;
  /** fetch data from the table: "challenge_catalog_item" using primary key columns */
  challenge_catalog_item_by_pk?: Maybe<Challenge_Catalog_Item>;
  /** fetch data from the table: "challenge_district" */
  challenge_district: Array<Challenge_District>;
  /** fetch aggregated fields from the table: "challenge_district" */
  challenge_district_aggregate: Challenge_District_Aggregate;
  /** fetch data from the table: "challenge_district" using primary key columns */
  challenge_district_by_pk?: Maybe<Challenge_District>;
  /** fetch data from the table: "challenge_grade" */
  challenge_grade: Array<Challenge_Grade>;
  /** fetch aggregated fields from the table: "challenge_grade" */
  challenge_grade_aggregate: Challenge_Grade_Aggregate;
  /** fetch data from the table: "challenge_grade" using primary key columns */
  challenge_grade_by_pk?: Maybe<Challenge_Grade>;
  /** fetch data from the table: "challenge_song" */
  challenge_song: Array<Challenge_Song>;
  /** fetch aggregated fields from the table: "challenge_song" */
  challenge_song_aggregate: Challenge_Song_Aggregate;
  /** fetch data from the table: "challenge_song" using primary key columns */
  challenge_song_by_pk?: Maybe<Challenge_Song>;
  /** fetch data from the table: "challenge_student" */
  challenge_student: Array<Challenge_Student>;
  /** fetch aggregated fields from the table: "challenge_student" */
  challenge_student_aggregate: Challenge_Student_Aggregate;
  /** fetch data from the table: "challenge_student" using primary key columns */
  challenge_student_by_pk?: Maybe<Challenge_Student>;
  /** fetch data from the table: "channel" */
  channel: Array<Channel>;
  /** fetch aggregated fields from the table: "channel" */
  channel_aggregate: Channel_Aggregate;
  /** fetch data from the table: "channel" using primary key columns */
  channel_by_pk?: Maybe<Channel>;
  /** fetch data from the table: "channel_playlist" */
  channel_playlist: Array<Channel_Playlist>;
  /** fetch aggregated fields from the table: "channel_playlist" */
  channel_playlist_aggregate: Channel_Playlist_Aggregate;
  /** fetch data from the table: "channel_playlist" using primary key columns */
  channel_playlist_by_pk?: Maybe<Channel_Playlist>;
  /** fetch data from the table: "class" */
  class: Array<Class>;
  /** fetch aggregated fields from the table: "class" */
  class_aggregate: Class_Aggregate;
  /** fetch data from the table: "class_assignment" */
  class_assignment: Array<Class_Assignment>;
  /** fetch aggregated fields from the table: "class_assignment" */
  class_assignment_aggregate: Class_Assignment_Aggregate;
  /** fetch data from the table: "class_assignment" using primary key columns */
  class_assignment_by_pk?: Maybe<Class_Assignment>;
  /** fetch data from the table: "class_assignment_status" */
  class_assignment_status: Array<Class_Assignment_Status>;
  /** fetch aggregated fields from the table: "class_assignment_status" */
  class_assignment_status_aggregate: Class_Assignment_Status_Aggregate;
  /** fetch data from the table: "class_assignment_status" using primary key columns */
  class_assignment_status_by_pk?: Maybe<Class_Assignment_Status>;
  /** fetch data from the table: "class" using primary key columns */
  class_by_pk?: Maybe<Class>;
  /** fetch data from the table: "class_images" */
  class_images: Array<Class_Images>;
  /** fetch aggregated fields from the table: "class_images" */
  class_images_aggregate: Class_Images_Aggregate;
  /** fetch data from the table: "class_images" using primary key columns */
  class_images_by_pk?: Maybe<Class_Images>;
  /** fetch data from the table: "class_lesson_plan" */
  class_lesson_plan: Array<Class_Lesson_Plan>;
  /** fetch aggregated fields from the table: "class_lesson_plan" */
  class_lesson_plan_aggregate: Class_Lesson_Plan_Aggregate;
  /** fetch data from the table: "class_lesson_plan" using primary key columns */
  class_lesson_plan_by_pk?: Maybe<Class_Lesson_Plan>;
  /** fetch data from the table: "class_module" */
  class_module: Array<Class_Module>;
  /** fetch aggregated fields from the table: "class_module" */
  class_module_aggregate: Class_Module_Aggregate;
  /** fetch data from the table: "class_module" using primary key columns */
  class_module_by_pk?: Maybe<Class_Module>;
  /** fetch data from the table: "class_playlist" */
  class_playlist: Array<Class_Playlist>;
  /** fetch aggregated fields from the table: "class_playlist" */
  class_playlist_aggregate: Class_Playlist_Aggregate;
  /** fetch data from the table: "class_playlist" using primary key columns */
  class_playlist_by_pk?: Maybe<Class_Playlist>;
  /** fetch data from the table: "class_sequence" */
  class_sequence: Array<Class_Sequence>;
  /** fetch aggregated fields from the table: "class_sequence" */
  class_sequence_aggregate: Class_Sequence_Aggregate;
  /** fetch data from the table: "class_sequence" using primary key columns */
  class_sequence_by_pk?: Maybe<Class_Sequence>;
  /** fetch data from the table: "class_song" */
  class_song: Array<Class_Song>;
  /** fetch aggregated fields from the table: "class_song" */
  class_song_aggregate: Class_Song_Aggregate;
  /** fetch data from the table: "class_song" using primary key columns */
  class_song_by_pk?: Maybe<Class_Song>;
  /** fetch data from the table: "class_student" */
  class_student: Array<Class_Student>;
  /** fetch data from the table: "class_student_activities" */
  class_student_activities: Array<Class_Student_Activities>;
  /** fetch aggregated fields from the table: "class_student_activities" */
  class_student_activities_aggregate: Class_Student_Activities_Aggregate;
  /** fetch data from the table: "class_student_activities" using primary key columns */
  class_student_activities_by_pk?: Maybe<Class_Student_Activities>;
  /** fetch aggregated fields from the table: "class_student" */
  class_student_aggregate: Class_Student_Aggregate;
  /** fetch data from the table: "class_student" using primary key columns */
  class_student_by_pk?: Maybe<Class_Student>;
  /** fetch data from the table: "class_teacher" */
  class_teacher: Array<Class_Teacher>;
  /** fetch aggregated fields from the table: "class_teacher" */
  class_teacher_aggregate: Class_Teacher_Aggregate;
  /** fetch data from the table: "class_teacher" using primary key columns */
  class_teacher_by_pk?: Maybe<Class_Teacher>;
  /** fetch data from the table: "class_tip" */
  class_tip: Array<Class_Tip>;
  /** fetch aggregated fields from the table: "class_tip" */
  class_tip_aggregate: Class_Tip_Aggregate;
  /** fetch data from the table: "class_tip" using primary key columns */
  class_tip_by_pk?: Maybe<Class_Tip>;
  /** fetch data from the table: "clever_roster_log" */
  clever_roster_log: Array<Clever_Roster_Log>;
  /** fetch aggregated fields from the table: "clever_roster_log" */
  clever_roster_log_aggregate: Clever_Roster_Log_Aggregate;
  /** fetch data from the table: "clever_roster_log" using primary key columns */
  clever_roster_log_by_pk?: Maybe<Clever_Roster_Log>;
  /** fetch data from the table: "clever_roster_status" */
  clever_roster_status: Array<Clever_Roster_Status>;
  /** fetch aggregated fields from the table: "clever_roster_status" */
  clever_roster_status_aggregate: Clever_Roster_Status_Aggregate;
  /** fetch data from the table: "clever_roster_status" using primary key columns */
  clever_roster_status_by_pk?: Maybe<Clever_Roster_Status>;
  /** fetch data from the table: "contact_us" */
  contact_us: Array<Contact_Us>;
  /** fetch aggregated fields from the table: "contact_us" */
  contact_us_aggregate: Contact_Us_Aggregate;
  /** fetch data from the table: "contact_us" using primary key columns */
  contact_us_by_pk?: Maybe<Contact_Us>;
  /** fetch data from the table: "contact_us_camp_teacher" */
  contact_us_camp_teacher: Array<Contact_Us_Camp_Teacher>;
  /** fetch aggregated fields from the table: "contact_us_camp_teacher" */
  contact_us_camp_teacher_aggregate: Contact_Us_Camp_Teacher_Aggregate;
  /** fetch data from the table: "contact_us_camp_teacher" using primary key columns */
  contact_us_camp_teacher_by_pk?: Maybe<Contact_Us_Camp_Teacher>;
  /** fetch data from the table: "crossclear.masterlist" */
  crossclear_masterlist: Array<Crossclear_Masterlist>;
  /** fetch aggregated fields from the table: "crossclear.masterlist" */
  crossclear_masterlist_aggregate: Crossclear_Masterlist_Aggregate;
  /** fetch data from the table: "crossclear.masterlist" using primary key columns */
  crossclear_masterlist_by_pk?: Maybe<Crossclear_Masterlist>;
  /** fetch data from the table: "discipline" */
  discipline: Array<Discipline>;
  /** fetch aggregated fields from the table: "discipline" */
  discipline_aggregate: Discipline_Aggregate;
  /** fetch data from the table: "discipline" using primary key columns */
  discipline_by_pk?: Maybe<Discipline>;
  /** fetch data from the table: "district" */
  district: Array<District>;
  /** fetch data from the table: "district_admin" */
  district_admin: Array<District_Admin>;
  /** fetch aggregated fields from the table: "district_admin" */
  district_admin_aggregate: District_Admin_Aggregate;
  /** fetch data from the table: "district_admin" using primary key columns */
  district_admin_by_pk?: Maybe<District_Admin>;
  /** fetch aggregated fields from the table: "district" */
  district_aggregate: District_Aggregate;
  /** fetch data from the table: "district" using primary key columns */
  district_by_pk?: Maybe<District>;
  downloadQRCode?: Maybe<DownloadQrCodeData>;
  /** fetch data from the table: "filter_color" */
  filter_color: Array<Filter_Color>;
  /** fetch aggregated fields from the table: "filter_color" */
  filter_color_aggregate: Filter_Color_Aggregate;
  /** fetch data from the table: "filter_color" using primary key columns */
  filter_color_by_pk?: Maybe<Filter_Color>;
  /** fetch data from the table: "filter_color_name" */
  filter_color_name: Array<Filter_Color_Name>;
  /** fetch aggregated fields from the table: "filter_color_name" */
  filter_color_name_aggregate: Filter_Color_Name_Aggregate;
  /** fetch data from the table: "filter_color_name" using primary key columns */
  filter_color_name_by_pk?: Maybe<Filter_Color_Name>;
  /** fetch data from the table: "filter_frame" */
  filter_frame: Array<Filter_Frame>;
  /** fetch aggregated fields from the table: "filter_frame" */
  filter_frame_aggregate: Filter_Frame_Aggregate;
  /** fetch data from the table: "filter_sticker" */
  filter_sticker: Array<Filter_Sticker>;
  /** fetch aggregated fields from the table: "filter_sticker" */
  filter_sticker_aggregate: Filter_Sticker_Aggregate;
  /** fetch data from the table: "filter_sticker" using primary key columns */
  filter_sticker_by_pk?: Maybe<Filter_Sticker>;
  filtersPing?: Maybe<Scalars['String']>;
  frames?: Maybe<FramesData>;
  getCanvasAuthUrl?: Maybe<Scalars['String']>;
  getCanvasUser: GetCanvasUserData;
  getClassLinkAuthUrl?: Maybe<Scalars['String']>;
  getClassLinkGroups: Array<GetClassLinkGroupsData>;
  getClassLinkStudents: Array<GetClassLinkStudentsData>;
  getClassLinkUser: GetClassLinkUserData;
  getCleverAccount?: Maybe<CleverAccount>;
  getCleverAuthUrl?: Maybe<Scalars['String']>;
  getCleverDistricts?: Maybe<Array<Maybe<GetCleverDistrictsData>>>;
  getCleverGroups?: Maybe<Array<Maybe<CleverGroup>>>;
  getCleverMembers?: Maybe<Array<Maybe<CleverAccount>>>;
  getCleverToken?: Maybe<Scalars['String']>;
  getGoogleAccount?: Maybe<GoogleAccount>;
  getGoogleAuthUrl?: Maybe<Scalars['String']>;
  getGoogleGroups?: Maybe<Array<Maybe<GoogleGroup>>>;
  getGoogleMembers?: Maybe<Array<Maybe<GoogleStudent>>>;
  getGoogleToken?: Maybe<GoogleOAuthToken>;
  getMsAccount?: Maybe<MsAccount>;
  getMsAuthUrl?: Maybe<Scalars['String']>;
  getMsGroups?: Maybe<Array<Maybe<MsGroup>>>;
  getMsMembers?: Maybe<Array<Maybe<MsAccount>>>;
  getMsToken?: Maybe<Scalars['String']>;
  getSignupIntegrationToken?: Maybe<SignupTeacherIntegrationGetTokenData>;
  getSongTrackEvents?: Maybe<GetSongTrackEventsResponse>;
  getStudentChallenges: GetStudentChallengesData;
  getStudentQuestionary?: Maybe<GetStudentQuestionaryResponse>;
  getSubscription?: Maybe<GetSubscriptionData>;
  getValidCode: Scalars['String'];
  getValidCodes: Array<Scalars['String']>;
  /** fetch data from the table: "grade" */
  grade: Array<Grade>;
  /** fetch aggregated fields from the table: "grade" */
  grade_aggregate: Grade_Aggregate;
  /** fetch data from the table: "grade" using primary key columns */
  grade_by_pk?: Maybe<Grade>;
  /** fetch data from the table: "grading_system" */
  grading_system: Array<Grading_System>;
  /** fetch aggregated fields from the table: "grading_system" */
  grading_system_aggregate: Grading_System_Aggregate;
  /** fetch data from the table: "grading_system" using primary key columns */
  grading_system_by_pk?: Maybe<Grading_System>;
  /** fetch data from the table: "grading_system_type" */
  grading_system_type: Array<Grading_System_Type>;
  /** fetch aggregated fields from the table: "grading_system_type" */
  grading_system_type_aggregate: Grading_System_Type_Aggregate;
  /** fetch data from the table: "grading_system_type" using primary key columns */
  grading_system_type_by_pk?: Maybe<Grading_System_Type>;
  imagePath?: Maybe<ImagePathData>;
  impersonateTeacher?: Maybe<ImpersonateTeacherData>;
  /** fetch data from the table: "integration_name" */
  integration_name: Array<Integration_Name>;
  /** fetch aggregated fields from the table: "integration_name" */
  integration_name_aggregate: Integration_Name_Aggregate;
  /** fetch data from the table: "integration_name" using primary key columns */
  integration_name_by_pk?: Maybe<Integration_Name>;
  /** fetch data from the table: "invitation_status" */
  invitation_status: Array<Invitation_Status>;
  /** fetch aggregated fields from the table: "invitation_status" */
  invitation_status_aggregate: Invitation_Status_Aggregate;
  /** fetch data from the table: "invitation_status" using primary key columns */
  invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** fetch data from the table: "keyword" */
  keyword: Array<Keyword>;
  /** fetch aggregated fields from the table: "keyword" */
  keyword_aggregate: Keyword_Aggregate;
  /** fetch data from the table: "keyword" using primary key columns */
  keyword_by_pk?: Maybe<Keyword>;
  /** fetch data from the table: "label_control" */
  label_control: Array<Label_Control>;
  /** fetch aggregated fields from the table: "label_control" */
  label_control_aggregate: Label_Control_Aggregate;
  /** fetch data from the table: "label_control" using primary key columns */
  label_control_by_pk?: Maybe<Label_Control>;
  /** fetch data from the table: "labels" */
  labels: Array<Labels>;
  /** fetch aggregated fields from the table: "labels" */
  labels_aggregate: Labels_Aggregate;
  /** fetch data from the table: "labels" using primary key columns */
  labels_by_pk?: Maybe<Labels>;
  lessonPlanPDF?: Maybe<LessonPlanPdfData>;
  /** fetch data from the table: "lesson_plan" */
  lesson_plan: Array<Lesson_Plan>;
  /** fetch aggregated fields from the table: "lesson_plan" */
  lesson_plan_aggregate: Lesson_Plan_Aggregate;
  /** fetch data from the table: "lesson_plan_article" */
  lesson_plan_article: Array<Lesson_Plan_Article>;
  /** fetch aggregated fields from the table: "lesson_plan_article" */
  lesson_plan_article_aggregate: Lesson_Plan_Article_Aggregate;
  /** fetch data from the table: "lesson_plan_article" using primary key columns */
  lesson_plan_article_by_pk?: Maybe<Lesson_Plan_Article>;
  /** An array relationship */
  lesson_plan_asset: Array<Lesson_Plan_Asset>;
  /** An aggregate relationship */
  lesson_plan_asset_aggregate: Lesson_Plan_Asset_Aggregate;
  /** fetch data from the table: "lesson_plan_asset" using primary key columns */
  lesson_plan_asset_by_pk?: Maybe<Lesson_Plan_Asset>;
  /** fetch data from the table: "lesson_plan" using primary key columns */
  lesson_plan_by_pk?: Maybe<Lesson_Plan>;
  /** An array relationship */
  lesson_plan_catalog_item: Array<Lesson_Plan_Catalog_Item>;
  /** An aggregate relationship */
  lesson_plan_catalog_item_aggregate: Lesson_Plan_Catalog_Item_Aggregate;
  /** fetch data from the table: "lesson_plan_catalog_item" using primary key columns */
  lesson_plan_catalog_item_by_pk?: Maybe<Lesson_Plan_Catalog_Item>;
  /** fetch data from the table: "lesson_plan_discipline" */
  lesson_plan_discipline: Array<Lesson_Plan_Discipline>;
  /** fetch aggregated fields from the table: "lesson_plan_discipline" */
  lesson_plan_discipline_aggregate: Lesson_Plan_Discipline_Aggregate;
  /** fetch data from the table: "lesson_plan_discipline" using primary key columns */
  lesson_plan_discipline_by_pk?: Maybe<Lesson_Plan_Discipline>;
  /** An array relationship */
  lesson_plan_grade: Array<Lesson_Plan_Grade>;
  /** An aggregate relationship */
  lesson_plan_grade_aggregate: Lesson_Plan_Grade_Aggregate;
  /** fetch data from the table: "lesson_plan_grade" using primary key columns */
  lesson_plan_grade_by_pk?: Maybe<Lesson_Plan_Grade>;
  /** An array relationship */
  lesson_plan_keyword: Array<Lesson_Plan_Keyword>;
  /** An aggregate relationship */
  lesson_plan_keyword_aggregate: Lesson_Plan_Keyword_Aggregate;
  /** fetch data from the table: "lesson_plan_keyword" using primary key columns */
  lesson_plan_keyword_by_pk?: Maybe<Lesson_Plan_Keyword>;
  /** An array relationship */
  lesson_plan_musical_skill: Array<Lesson_Plan_Musical_Skill>;
  /** An aggregate relationship */
  lesson_plan_musical_skill_aggregate: Lesson_Plan_Musical_Skill_Aggregate;
  /** fetch data from the table: "lesson_plan_musical_skill" using primary key columns */
  lesson_plan_musical_skill_by_pk?: Maybe<Lesson_Plan_Musical_Skill>;
  /** An array relationship */
  lesson_plan_non_musical_skill: Array<Lesson_Plan_Non_Musical_Skill>;
  /** An aggregate relationship */
  lesson_plan_non_musical_skill_aggregate: Lesson_Plan_Non_Musical_Skill_Aggregate;
  /** fetch data from the table: "lesson_plan_non_musical_skill" using primary key columns */
  lesson_plan_non_musical_skill_by_pk?: Maybe<Lesson_Plan_Non_Musical_Skill>;
  /** An array relationship */
  lesson_plan_skill_level: Array<Lesson_Plan_Skill_Level>;
  /** An aggregate relationship */
  lesson_plan_skill_level_aggregate: Lesson_Plan_Skill_Level_Aggregate;
  /** fetch data from the table: "lesson_plan_skill_level" using primary key columns */
  lesson_plan_skill_level_by_pk?: Maybe<Lesson_Plan_Skill_Level>;
  /** An array relationship */
  lesson_plan_song: Array<Lesson_Plan_Song>;
  /** An aggregate relationship */
  lesson_plan_song_aggregate: Lesson_Plan_Song_Aggregate;
  /** fetch data from the table: "lesson_plan_song" using primary key columns */
  lesson_plan_song_by_pk?: Maybe<Lesson_Plan_Song>;
  /** fetch data from the table: "lesson_plan_standard" */
  lesson_plan_standard: Array<Lesson_Plan_Standard>;
  /** fetch aggregated fields from the table: "lesson_plan_standard" */
  lesson_plan_standard_aggregate: Lesson_Plan_Standard_Aggregate;
  /** fetch data from the table: "lesson_plan_standard" using primary key columns */
  lesson_plan_standard_by_pk?: Maybe<Lesson_Plan_Standard>;
  /** An array relationship */
  lesson_plan_tip: Array<Lesson_Plan_Tip>;
  /** An aggregate relationship */
  lesson_plan_tip_aggregate: Lesson_Plan_Tip_Aggregate;
  /** fetch data from the table: "lesson_plan_tip" using primary key columns */
  lesson_plan_tip_by_pk?: Maybe<Lesson_Plan_Tip>;
  linkMetaData?: Maybe<MetaData>;
  listSubscriptions?: Maybe<ListSubscriptions>;
  /** fetch data from the table: "lockable_content" */
  lockable_content: Array<Lockable_Content>;
  /** fetch aggregated fields from the table: "lockable_content" */
  lockable_content_aggregate: Lockable_Content_Aggregate;
  /** fetch data from the table: "lockable_content" using primary key columns */
  lockable_content_by_pk?: Maybe<Lockable_Content>;
  /** fetch data from the table: "lockable_content_class" */
  lockable_content_class: Array<Lockable_Content_Class>;
  /** fetch aggregated fields from the table: "lockable_content_class" */
  lockable_content_class_aggregate: Lockable_Content_Class_Aggregate;
  /** fetch data from the table: "lockable_content_class" using primary key columns */
  lockable_content_class_by_pk?: Maybe<Lockable_Content_Class>;
  /** fetch data from the table: "lockable_content_district" */
  lockable_content_district: Array<Lockable_Content_District>;
  /** fetch aggregated fields from the table: "lockable_content_district" */
  lockable_content_district_aggregate: Lockable_Content_District_Aggregate;
  /** fetch data from the table: "lockable_content_district" using primary key columns */
  lockable_content_district_by_pk?: Maybe<Lockable_Content_District>;
  /** fetch data from the table: "lockable_content_school" */
  lockable_content_school: Array<Lockable_Content_School>;
  /** fetch aggregated fields from the table: "lockable_content_school" */
  lockable_content_school_aggregate: Lockable_Content_School_Aggregate;
  /** fetch data from the table: "lockable_content_school" using primary key columns */
  lockable_content_school_by_pk?: Maybe<Lockable_Content_School>;
  /** fetch data from the table: "lockable_content_teacher" */
  lockable_content_teacher: Array<Lockable_Content_Teacher>;
  /** fetch aggregated fields from the table: "lockable_content_teacher" */
  lockable_content_teacher_aggregate: Lockable_Content_Teacher_Aggregate;
  /** fetch data from the table: "lockable_content_teacher" using primary key columns */
  lockable_content_teacher_by_pk?: Maybe<Lockable_Content_Teacher>;
  login?: Maybe<LoginData>;
  loginIntegration?: Maybe<LoginIntegrationData>;
  loginOrSignupTeacherIntegration?: Maybe<LoginOrSignupTeacherIntegrationData>;
  loginStudentByCode?: Maybe<LoginStudentByCodeResponse>;
  /** fetch data from the table: "maturity_level" */
  maturity_level: Array<Maturity_Level>;
  /** fetch aggregated fields from the table: "maturity_level" */
  maturity_level_aggregate: Maturity_Level_Aggregate;
  /** fetch data from the table: "maturity_level" using primary key columns */
  maturity_level_by_pk?: Maybe<Maturity_Level>;
  /** fetch data from the table: "membership_type" */
  membership_type: Array<Membership_Type>;
  /** fetch aggregated fields from the table: "membership_type" */
  membership_type_aggregate: Membership_Type_Aggregate;
  /** fetch data from the table: "membership_type" using primary key columns */
  membership_type_by_pk?: Maybe<Membership_Type>;
  /** fetch data from the table: "midi" */
  midi: Array<Midi>;
  /** fetch aggregated fields from the table: "midi" */
  midi_aggregate: Midi_Aggregate;
  /** fetch data from the table: "midi" using primary key columns */
  midi_by_pk?: Maybe<Midi>;
  /** fetch data from the table: "module" */
  module: Array<Module>;
  /** fetch aggregated fields from the table: "module" */
  module_aggregate: Module_Aggregate;
  /** fetch data from the table: "module" using primary key columns */
  module_by_pk?: Maybe<Module>;
  /** fetch data from the table: "module_catalog_item" */
  module_catalog_item: Array<Module_Catalog_Item>;
  /** fetch aggregated fields from the table: "module_catalog_item" */
  module_catalog_item_aggregate: Module_Catalog_Item_Aggregate;
  /** fetch data from the table: "module_catalog_item" using primary key columns */
  module_catalog_item_by_pk?: Maybe<Module_Catalog_Item>;
  /** fetch data from the table: "module_grade" */
  module_grade: Array<Module_Grade>;
  /** fetch aggregated fields from the table: "module_grade" */
  module_grade_aggregate: Module_Grade_Aggregate;
  /** fetch data from the table: "module_grade" using primary key columns */
  module_grade_by_pk?: Maybe<Module_Grade>;
  /** An array relationship */
  module_lesson_plan: Array<Module_Lesson_Plan>;
  /** An aggregate relationship */
  module_lesson_plan_aggregate: Module_Lesson_Plan_Aggregate;
  /** fetch data from the table: "module_lesson_plan" using primary key columns */
  module_lesson_plan_by_pk?: Maybe<Module_Lesson_Plan>;
  /** fetch data from the table: "musical_skill" */
  musical_skill: Array<Musical_Skill>;
  /** fetch aggregated fields from the table: "musical_skill" */
  musical_skill_aggregate: Musical_Skill_Aggregate;
  /** fetch data from the table: "musical_skill" using primary key columns */
  musical_skill_by_pk?: Maybe<Musical_Skill>;
  /** fetch data from the table: "non_musical_skill" */
  non_musical_skill: Array<Non_Musical_Skill>;
  /** fetch aggregated fields from the table: "non_musical_skill" */
  non_musical_skill_aggregate: Non_Musical_Skill_Aggregate;
  /** fetch data from the table: "non_musical_skill" using primary key columns */
  non_musical_skill_by_pk?: Maybe<Non_Musical_Skill>;
  /** fetch data from the table: "non_musical_skill_type" */
  non_musical_skill_type: Array<Non_Musical_Skill_Type>;
  /** fetch aggregated fields from the table: "non_musical_skill_type" */
  non_musical_skill_type_aggregate: Non_Musical_Skill_Type_Aggregate;
  /** fetch data from the table: "non_musical_skill_type" using primary key columns */
  non_musical_skill_type_by_pk?: Maybe<Non_Musical_Skill_Type>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table: "parent_invitation" */
  parent_invitation: Array<Parent_Invitation>;
  /** fetch aggregated fields from the table: "parent_invitation" */
  parent_invitation_aggregate: Parent_Invitation_Aggregate;
  /** fetch data from the table: "parent_invitation" using primary key columns */
  parent_invitation_by_pk?: Maybe<Parent_Invitation>;
  /** fetch data from the table: "partner" */
  partner: Array<Partner>;
  /** fetch aggregated fields from the table: "partner" */
  partner_aggregate: Partner_Aggregate;
  /** fetch data from the table: "partner" using primary key columns */
  partner_by_pk?: Maybe<Partner>;
  /** fetch data from the table: "partner_module" */
  partner_module: Array<Partner_Module>;
  /** fetch aggregated fields from the table: "partner_module" */
  partner_module_aggregate: Partner_Module_Aggregate;
  /** fetch data from the table: "partner_module" using primary key columns */
  partner_module_by_pk?: Maybe<Partner_Module>;
  /** fetch data from the table: "partner_playlist" */
  partner_playlist: Array<Partner_Playlist>;
  /** fetch aggregated fields from the table: "partner_playlist" */
  partner_playlist_aggregate: Partner_Playlist_Aggregate;
  /** fetch data from the table: "partner_playlist" using primary key columns */
  partner_playlist_by_pk?: Maybe<Partner_Playlist>;
  ping?: Maybe<Scalars['String']>;
  /** fetch data from the table: "playlist" */
  playlist: Array<Playlist>;
  /** fetch aggregated fields from the table: "playlist" */
  playlist_aggregate: Playlist_Aggregate;
  /** fetch data from the table: "playlist" using primary key columns */
  playlist_by_pk?: Maybe<Playlist>;
  presignedUrl?: Maybe<PresignedUrlData>;
  /** fetch data from the table: "price_type" */
  price_type: Array<Price_Type>;
  /** fetch aggregated fields from the table: "price_type" */
  price_type_aggregate: Price_Type_Aggregate;
  /** fetch data from the table: "price_type" using primary key columns */
  price_type_by_pk?: Maybe<Price_Type>;
  /** fetch data from the table: "privacy" */
  privacy: Array<Privacy>;
  /** fetch aggregated fields from the table: "privacy" */
  privacy_aggregate: Privacy_Aggregate;
  /** fetch data from the table: "privacy" using primary key columns */
  privacy_by_pk?: Maybe<Privacy>;
  /** fetch data from the table: "product_name" */
  product_name: Array<Product_Name>;
  /** fetch aggregated fields from the table: "product_name" */
  product_name_aggregate: Product_Name_Aggregate;
  /** fetch data from the table: "product_name" using primary key columns */
  product_name_by_pk?: Maybe<Product_Name>;
  /** fetch data from the table: "publisher" */
  publisher: Array<Publisher>;
  /** fetch aggregated fields from the table: "publisher" */
  publisher_aggregate: Publisher_Aggregate;
  /** fetch data from the table: "publisher" using primary key columns */
  publisher_by_pk?: Maybe<Publisher>;
  /** fetch data from the table: "publisher_control" */
  publisher_control: Array<Publisher_Control>;
  /** fetch aggregated fields from the table: "publisher_control" */
  publisher_control_aggregate: Publisher_Control_Aggregate;
  /** fetch data from the table: "publisher_control" using primary key columns */
  publisher_control_by_pk?: Maybe<Publisher_Control>;
  /** fetch data from the table: "reaction" */
  reaction: Array<Reaction>;
  /** fetch aggregated fields from the table: "reaction" */
  reaction_aggregate: Reaction_Aggregate;
  /** fetch data from the table: "reaction" using primary key columns */
  reaction_by_pk?: Maybe<Reaction>;
  /** fetch data from the table: "reset_password" */
  reset_password: Array<Reset_Password>;
  /** fetch aggregated fields from the table: "reset_password" */
  reset_password_aggregate: Reset_Password_Aggregate;
  /** fetch data from the table: "reset_password" using primary key columns */
  reset_password_by_pk?: Maybe<Reset_Password>;
  /** fetch data from the table: "role_name" */
  role_name: Array<Role_Name>;
  /** fetch aggregated fields from the table: "role_name" */
  role_name_aggregate: Role_Name_Aggregate;
  /** fetch data from the table: "role_name" using primary key columns */
  role_name_by_pk?: Maybe<Role_Name>;
  /** fetch data from the table: "royalty_log" */
  royalty_log: Array<Royalty_Log>;
  /** fetch aggregated fields from the table: "royalty_log" */
  royalty_log_aggregate: Royalty_Log_Aggregate;
  /** fetch data from the table: "royalty_log" using primary key columns */
  royalty_log_by_pk?: Maybe<Royalty_Log>;
  /** fetch data from the table: "scale" */
  scale: Array<Scale>;
  /** fetch aggregated fields from the table: "scale" */
  scale_aggregate: Scale_Aggregate;
  /** fetch data from the table: "scale" using primary key columns */
  scale_by_pk?: Maybe<Scale>;
  /** fetch data from the table: "school" */
  school: Array<School>;
  /** fetch data from the table: "school_admin" */
  school_admin: Array<School_Admin>;
  /** fetch aggregated fields from the table: "school_admin" */
  school_admin_aggregate: School_Admin_Aggregate;
  /** fetch data from the table: "school_admin" using primary key columns */
  school_admin_by_pk?: Maybe<School_Admin>;
  /** fetch aggregated fields from the table: "school" */
  school_aggregate: School_Aggregate;
  /** fetch data from the table: "school" using primary key columns */
  school_by_pk?: Maybe<School>;
  /** fetch data from the table: "school_teacher" */
  school_teacher: Array<School_Teacher>;
  /** fetch aggregated fields from the table: "school_teacher" */
  school_teacher_aggregate: School_Teacher_Aggregate;
  /** fetch data from the table: "school_teacher" using primary key columns */
  school_teacher_by_pk?: Maybe<School_Teacher>;
  /** fetch data from the table: "screen_type" */
  screen_type: Array<Screen_Type>;
  /** fetch aggregated fields from the table: "screen_type" */
  screen_type_aggregate: Screen_Type_Aggregate;
  /** fetch data from the table: "screen_type" using primary key columns */
  screen_type_by_pk?: Maybe<Screen_Type>;
  sendReinviteStudentsEmail?: Maybe<Scalars['Boolean']>;
  sendResetPasswordUrl?: Maybe<SendResetPasswordUrlData>;
  sendTeacherActivationEmail?: Maybe<SendTeacherActivationEmailData>;
  /** fetch data from the table: "sequence" */
  sequence: Array<Sequence>;
  /** fetch aggregated fields from the table: "sequence" */
  sequence_aggregate: Sequence_Aggregate;
  /** fetch data from the table: "sequence" using primary key columns */
  sequence_by_pk?: Maybe<Sequence>;
  /** fetch data from the table: "sequence_keyword" */
  sequence_keyword: Array<Sequence_Keyword>;
  /** fetch aggregated fields from the table: "sequence_keyword" */
  sequence_keyword_aggregate: Sequence_Keyword_Aggregate;
  /** fetch data from the table: "sequence_keyword" using primary key columns */
  sequence_keyword_by_pk?: Maybe<Sequence_Keyword>;
  /** An array relationship */
  sequence_lesson_plan: Array<Sequence_Lesson_Plan>;
  /** An aggregate relationship */
  sequence_lesson_plan_aggregate: Sequence_Lesson_Plan_Aggregate;
  /** fetch data from the table: "sequence_lesson_plan" using primary key columns */
  sequence_lesson_plan_by_pk?: Maybe<Sequence_Lesson_Plan>;
  /** fetch data from the table: "sequence_topic" */
  sequence_topic: Array<Sequence_Topic>;
  /** fetch aggregated fields from the table: "sequence_topic" */
  sequence_topic_aggregate: Sequence_Topic_Aggregate;
  /** fetch data from the table: "sequence_topic" using primary key columns */
  sequence_topic_by_pk?: Maybe<Sequence_Topic>;
  /** fetch data from the table: "sequence_topic_catalog_item" */
  sequence_topic_catalog_item: Array<Sequence_Topic_Catalog_Item>;
  /** fetch aggregated fields from the table: "sequence_topic_catalog_item" */
  sequence_topic_catalog_item_aggregate: Sequence_Topic_Catalog_Item_Aggregate;
  /** fetch data from the table: "sequence_topic_catalog_item" using primary key columns */
  sequence_topic_catalog_item_by_pk?: Maybe<Sequence_Topic_Catalog_Item>;
  /** fetch data from the table: "sequence_topic_keyword" */
  sequence_topic_keyword: Array<Sequence_Topic_Keyword>;
  /** fetch aggregated fields from the table: "sequence_topic_keyword" */
  sequence_topic_keyword_aggregate: Sequence_Topic_Keyword_Aggregate;
  /** fetch data from the table: "sequence_topic_keyword" using primary key columns */
  sequence_topic_keyword_by_pk?: Maybe<Sequence_Topic_Keyword>;
  /** fetch data from the table: "sequence_topic_sequence" */
  sequence_topic_sequence: Array<Sequence_Topic_Sequence>;
  /** fetch aggregated fields from the table: "sequence_topic_sequence" */
  sequence_topic_sequence_aggregate: Sequence_Topic_Sequence_Aggregate;
  /** fetch data from the table: "sequence_topic_sequence" using primary key columns */
  sequence_topic_sequence_by_pk?: Maybe<Sequence_Topic_Sequence>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "skill_level" */
  skill_level: Array<Skill_Level>;
  /** fetch aggregated fields from the table: "skill_level" */
  skill_level_aggregate: Skill_Level_Aggregate;
  /** fetch data from the table: "skill_level" using primary key columns */
  skill_level_by_pk?: Maybe<Skill_Level>;
  /** fetch data from the table: "song" */
  song: Array<Song>;
  /** fetch aggregated fields from the table: "song" */
  song_aggregate: Song_Aggregate;
  /** fetch data from the table: "song" using primary key columns */
  song_by_pk?: Maybe<Song>;
  /** An array relationship */
  song_catalog_item: Array<Song_Catalog_Item>;
  /** An aggregate relationship */
  song_catalog_item_aggregate: Song_Catalog_Item_Aggregate;
  /** fetch data from the table: "song_catalog_item" using primary key columns */
  song_catalog_item_by_pk?: Maybe<Song_Catalog_Item>;
  /** fetch data from the table: "song_grade" */
  song_grade: Array<Song_Grade>;
  /** fetch aggregated fields from the table: "song_grade" */
  song_grade_aggregate: Song_Grade_Aggregate;
  /** fetch data from the table: "song_grade" using primary key columns */
  song_grade_by_pk?: Maybe<Song_Grade>;
  /** fetch data from the table: "song_keyword" */
  song_keyword: Array<Song_Keyword>;
  /** fetch aggregated fields from the table: "song_keyword" */
  song_keyword_aggregate: Song_Keyword_Aggregate;
  /** fetch data from the table: "song_keyword" using primary key columns */
  song_keyword_by_pk?: Maybe<Song_Keyword>;
  /** fetch data from the table: "standard" */
  standard: Array<Standard>;
  /** fetch aggregated fields from the table: "standard" */
  standard_aggregate: Standard_Aggregate;
  /** fetch data from the table: "standard" using primary key columns */
  standard_by_pk?: Maybe<Standard>;
  /** fetch data from the table: "standard_option" */
  standard_option: Array<Standard_Option>;
  /** fetch aggregated fields from the table: "standard_option" */
  standard_option_aggregate: Standard_Option_Aggregate;
  /** fetch data from the table: "standard_option" using primary key columns */
  standard_option_by_pk?: Maybe<Standard_Option>;
  /** fetch data from the table: "standard_subcategory" */
  standard_subcategory: Array<Standard_Subcategory>;
  /** fetch aggregated fields from the table: "standard_subcategory" */
  standard_subcategory_aggregate: Standard_Subcategory_Aggregate;
  /** fetch data from the table: "standard_subcategory" using primary key columns */
  standard_subcategory_by_pk?: Maybe<Standard_Subcategory>;
  /** fetch data from the table: "student" */
  student: Array<Student>;
  studentEmailExists?: Maybe<Scalars['Boolean']>;
  studentLoginAttemptFailedEmail?: Maybe<Scalars['Boolean']>;
  studentLoginIntegration?: Maybe<StudentLoginIntegrationData>;
  /** fetch data from the table: "student_activity" */
  student_activity: Array<Student_Activity>;
  /** fetch aggregated fields from the table: "student_activity" */
  student_activity_aggregate: Student_Activity_Aggregate;
  /** fetch data from the table: "student_activity" using primary key columns */
  student_activity_by_pk?: Maybe<Student_Activity>;
  /** fetch data from the table: "student_activity_type" */
  student_activity_type: Array<Student_Activity_Type>;
  /** fetch aggregated fields from the table: "student_activity_type" */
  student_activity_type_aggregate: Student_Activity_Type_Aggregate;
  /** fetch data from the table: "student_activity_type" using primary key columns */
  student_activity_type_by_pk?: Maybe<Student_Activity_Type>;
  /** fetch aggregated fields from the table: "student" */
  student_aggregate: Student_Aggregate;
  /** fetch data from the table: "student_badge" */
  student_badge: Array<Student_Badge>;
  /** fetch aggregated fields from the table: "student_badge" */
  student_badge_aggregate: Student_Badge_Aggregate;
  /** fetch data from the table: "student_badge" using primary key columns */
  student_badge_by_pk?: Maybe<Student_Badge>;
  /** fetch data from the table: "student_badge_event" */
  student_badge_event: Array<Student_Badge_Event>;
  /** fetch aggregated fields from the table: "student_badge_event" */
  student_badge_event_aggregate: Student_Badge_Event_Aggregate;
  /** fetch data from the table: "student_badge_event" using primary key columns */
  student_badge_event_by_pk?: Maybe<Student_Badge_Event>;
  /** fetch data from the table: "student_badge_resume" */
  student_badge_resume: Array<Student_Badge_Resume>;
  /** fetch aggregated fields from the table: "student_badge_resume" */
  student_badge_resume_aggregate: Student_Badge_Resume_Aggregate;
  /** fetch data from the table: "student_banner" */
  student_banner: Array<Student_Banner>;
  /** fetch aggregated fields from the table: "student_banner" */
  student_banner_aggregate: Student_Banner_Aggregate;
  /** fetch data from the table: "student_banner" using primary key columns */
  student_banner_by_pk?: Maybe<Student_Banner>;
  /** fetch data from the table: "student" using primary key columns */
  student_by_pk?: Maybe<Student>;
  /** fetch data from the table: "student_district" */
  student_district: Array<Student_District>;
  /** fetch aggregated fields from the table: "student_district" */
  student_district_aggregate: Student_District_Aggregate;
  /** fetch data from the table: "student_goal" */
  student_goal: Array<Student_Goal>;
  /** fetch aggregated fields from the table: "student_goal" */
  student_goal_aggregate: Student_Goal_Aggregate;
  /** fetch data from the table: "student_goal" using primary key columns */
  student_goal_by_pk?: Maybe<Student_Goal>;
  /** fetch data from the table: "student_grade" */
  student_grade: Array<Student_Grade>;
  /** fetch aggregated fields from the table: "student_grade" */
  student_grade_aggregate: Student_Grade_Aggregate;
  /** fetch data from the table: "student_integration" */
  student_integration: Array<Student_Integration>;
  /** fetch aggregated fields from the table: "student_integration" */
  student_integration_aggregate: Student_Integration_Aggregate;
  /** fetch data from the table: "student_integration" using primary key columns */
  student_integration_by_pk?: Maybe<Student_Integration>;
  /** fetch data from the table: "student_lesson_plan_favorite" */
  student_lesson_plan_favorite: Array<Student_Lesson_Plan_Favorite>;
  /** fetch aggregated fields from the table: "student_lesson_plan_favorite" */
  student_lesson_plan_favorite_aggregate: Student_Lesson_Plan_Favorite_Aggregate;
  /** fetch data from the table: "student_lesson_plan_favorite" using primary key columns */
  student_lesson_plan_favorite_by_pk?: Maybe<Student_Lesson_Plan_Favorite>;
  /** An array relationship */
  student_productivity_daily: Array<Student_Productivity_Daily>;
  /** An aggregate relationship */
  student_productivity_daily_aggregate: Student_Productivity_Daily_Aggregate;
  /** fetch data from the table: "student_productivity_daily" using primary key columns */
  student_productivity_daily_by_pk?: Maybe<Student_Productivity_Daily>;
  /** An array relationship */
  student_productivity_monthly: Array<Student_Productivity_Monthly>;
  /** An aggregate relationship */
  student_productivity_monthly_aggregate: Student_Productivity_Monthly_Aggregate;
  /** fetch data from the table: "student_productivity_monthly" using primary key columns */
  student_productivity_monthly_by_pk?: Maybe<Student_Productivity_Monthly>;
  /** An array relationship */
  student_productivity_yearly: Array<Student_Productivity_Yearly>;
  /** An aggregate relationship */
  student_productivity_yearly_aggregate: Student_Productivity_Yearly_Aggregate;
  /** fetch data from the table: "student_productivity_yearly" using primary key columns */
  student_productivity_yearly_by_pk?: Maybe<Student_Productivity_Yearly>;
  /** fetch data from the table: "student_setting" */
  student_setting: Array<Student_Setting>;
  /** fetch aggregated fields from the table: "student_setting" */
  student_setting_aggregate: Student_Setting_Aggregate;
  /** fetch data from the table: "student_setting" using primary key columns */
  student_setting_by_pk?: Maybe<Student_Setting>;
  /** fetch data from the table: "student_song_favorite" */
  student_song_favorite: Array<Student_Song_Favorite>;
  /** fetch aggregated fields from the table: "student_song_favorite" */
  student_song_favorite_aggregate: Student_Song_Favorite_Aggregate;
  /** fetch data from the table: "student_song_favorite" using primary key columns */
  student_song_favorite_by_pk?: Maybe<Student_Song_Favorite>;
  /** fetch data from the table: "student_tip_favorite" */
  student_tip_favorite: Array<Student_Tip_Favorite>;
  /** fetch aggregated fields from the table: "student_tip_favorite" */
  student_tip_favorite_aggregate: Student_Tip_Favorite_Aggregate;
  /** fetch data from the table: "student_tip_favorite" using primary key columns */
  student_tip_favorite_by_pk?: Maybe<Student_Tip_Favorite>;
  /** An array relationship */
  submission: Array<Submission>;
  /** An aggregate relationship */
  submission_aggregate: Submission_Aggregate;
  /** fetch data from the table: "submission_asset" */
  submission_asset: Array<Submission_Asset>;
  /** fetch aggregated fields from the table: "submission_asset" */
  submission_asset_aggregate: Submission_Asset_Aggregate;
  /** fetch data from the table: "submission_asset" using primary key columns */
  submission_asset_by_pk?: Maybe<Submission_Asset>;
  /** fetch data from the table: "submission" using primary key columns */
  submission_by_pk?: Maybe<Submission>;
  /** fetch data from the table: "submission_state" */
  submission_state: Array<Submission_State>;
  /** fetch aggregated fields from the table: "submission_state" */
  submission_state_aggregate: Submission_State_Aggregate;
  /** fetch data from the table: "submission_state" using primary key columns */
  submission_state_by_pk?: Maybe<Submission_State>;
  /** fetch data from the table: "submission_student" */
  submission_student: Array<Submission_Student>;
  /** fetch aggregated fields from the table: "submission_student" */
  submission_student_aggregate: Submission_Student_Aggregate;
  /** fetch data from the table: "submission_student" using primary key columns */
  submission_student_by_pk?: Maybe<Submission_Student>;
  /** fetch data from the table: "submission_wurrly" */
  submission_wurrly: Array<Submission_Wurrly>;
  /** fetch aggregated fields from the table: "submission_wurrly" */
  submission_wurrly_aggregate: Submission_Wurrly_Aggregate;
  /** fetch data from the table: "submission_wurrly" using primary key columns */
  submission_wurrly_by_pk?: Maybe<Submission_Wurrly>;
  /** fetch data from the table: "submission_wurrly_state" */
  submission_wurrly_state: Array<Submission_Wurrly_State>;
  /** fetch aggregated fields from the table: "submission_wurrly_state" */
  submission_wurrly_state_aggregate: Submission_Wurrly_State_Aggregate;
  /** fetch data from the table: "submission_wurrly_state" using primary key columns */
  submission_wurrly_state_by_pk?: Maybe<Submission_Wurrly_State>;
  /** fetch data from the table: "teacher" */
  teacher: Array<Teacher>;
  teacherEmailExists?: Maybe<Scalars['Boolean']>;
  /** fetch aggregated fields from the table: "teacher" */
  teacher_aggregate: Teacher_Aggregate;
  /** fetch data from the table: "teacher" using primary key columns */
  teacher_by_pk?: Maybe<Teacher>;
  /** fetch data from the table: "teacher_integration" */
  teacher_integration: Array<Teacher_Integration>;
  /** fetch aggregated fields from the table: "teacher_integration" */
  teacher_integration_aggregate: Teacher_Integration_Aggregate;
  /** fetch data from the table: "teacher_integration" using primary key columns */
  teacher_integration_by_pk?: Maybe<Teacher_Integration>;
  /** fetch data from the table: "teacher_lesson_plan_favorite" */
  teacher_lesson_plan_favorite: Array<Teacher_Lesson_Plan_Favorite>;
  /** fetch aggregated fields from the table: "teacher_lesson_plan_favorite" */
  teacher_lesson_plan_favorite_aggregate: Teacher_Lesson_Plan_Favorite_Aggregate;
  /** fetch data from the table: "teacher_lesson_plan_favorite" using primary key columns */
  teacher_lesson_plan_favorite_by_pk?: Maybe<Teacher_Lesson_Plan_Favorite>;
  /** fetch data from the table: "teacher_setting" */
  teacher_setting: Array<Teacher_Setting>;
  /** fetch aggregated fields from the table: "teacher_setting" */
  teacher_setting_aggregate: Teacher_Setting_Aggregate;
  /** fetch data from the table: "teacher_setting" using primary key columns */
  teacher_setting_by_pk?: Maybe<Teacher_Setting>;
  /** fetch data from the table: "teacher_song_favorite" */
  teacher_song_favorite: Array<Teacher_Song_Favorite>;
  /** fetch aggregated fields from the table: "teacher_song_favorite" */
  teacher_song_favorite_aggregate: Teacher_Song_Favorite_Aggregate;
  /** fetch data from the table: "teacher_song_favorite" using primary key columns */
  teacher_song_favorite_by_pk?: Maybe<Teacher_Song_Favorite>;
  /** fetch data from the table: "teacher_subscription" */
  teacher_subscription: Array<Teacher_Subscription>;
  /** fetch aggregated fields from the table: "teacher_subscription" */
  teacher_subscription_aggregate: Teacher_Subscription_Aggregate;
  /** fetch data from the table: "teacher_subscription" using primary key columns */
  teacher_subscription_by_pk?: Maybe<Teacher_Subscription>;
  /** fetch data from the table: "teacher_tip_favorite" */
  teacher_tip_favorite: Array<Teacher_Tip_Favorite>;
  /** fetch aggregated fields from the table: "teacher_tip_favorite" */
  teacher_tip_favorite_aggregate: Teacher_Tip_Favorite_Aggregate;
  /** fetch data from the table: "teacher_tip_favorite" using primary key columns */
  teacher_tip_favorite_by_pk?: Maybe<Teacher_Tip_Favorite>;
  /** fetch data from the table: "teacher_wurrly_comment" */
  teacher_wurrly_comment: Array<Teacher_Wurrly_Comment>;
  /** fetch aggregated fields from the table: "teacher_wurrly_comment" */
  teacher_wurrly_comment_aggregate: Teacher_Wurrly_Comment_Aggregate;
  /** fetch data from the table: "teacher_wurrly_comment" using primary key columns */
  teacher_wurrly_comment_by_pk?: Maybe<Teacher_Wurrly_Comment>;
  /** fetch data from the table: "tip" */
  tip: Array<Tip>;
  /** fetch data from the table: "tip_age" */
  tip_age: Array<Tip_Age>;
  /** fetch aggregated fields from the table: "tip_age" */
  tip_age_aggregate: Tip_Age_Aggregate;
  /** fetch data from the table: "tip_age" using primary key columns */
  tip_age_by_pk?: Maybe<Tip_Age>;
  /** fetch aggregated fields from the table: "tip" */
  tip_aggregate: Tip_Aggregate;
  /** fetch data from the table: "tip" using primary key columns */
  tip_by_pk?: Maybe<Tip>;
  /** An array relationship */
  tip_catalog_item: Array<Tip_Catalog_Item>;
  /** An aggregate relationship */
  tip_catalog_item_aggregate: Tip_Catalog_Item_Aggregate;
  /** fetch data from the table: "tip_catalog_item" using primary key columns */
  tip_catalog_item_by_pk?: Maybe<Tip_Catalog_Item>;
  /** fetch data from the table: "tip_grade" */
  tip_grade: Array<Tip_Grade>;
  /** fetch aggregated fields from the table: "tip_grade" */
  tip_grade_aggregate: Tip_Grade_Aggregate;
  /** fetch data from the table: "tip_grade" using primary key columns */
  tip_grade_by_pk?: Maybe<Tip_Grade>;
  /** fetch data from the table: "tip_keyword" */
  tip_keyword: Array<Tip_Keyword>;
  /** fetch aggregated fields from the table: "tip_keyword" */
  tip_keyword_aggregate: Tip_Keyword_Aggregate;
  /** fetch data from the table: "tip_keyword" using primary key columns */
  tip_keyword_by_pk?: Maybe<Tip_Keyword>;
  /** fetch data from the table: "tip_musical_skill" */
  tip_musical_skill: Array<Tip_Musical_Skill>;
  /** fetch aggregated fields from the table: "tip_musical_skill" */
  tip_musical_skill_aggregate: Tip_Musical_Skill_Aggregate;
  /** fetch data from the table: "tip_musical_skill" using primary key columns */
  tip_musical_skill_by_pk?: Maybe<Tip_Musical_Skill>;
  /** fetch data from the table: "tip_non_musical_skill" */
  tip_non_musical_skill: Array<Tip_Non_Musical_Skill>;
  /** fetch aggregated fields from the table: "tip_non_musical_skill" */
  tip_non_musical_skill_aggregate: Tip_Non_Musical_Skill_Aggregate;
  /** fetch data from the table: "tip_non_musical_skill" using primary key columns */
  tip_non_musical_skill_by_pk?: Maybe<Tip_Non_Musical_Skill>;
  /** An array relationship */
  tip_playlist: Array<Tip_Playlist>;
  /** An aggregate relationship */
  tip_playlist_aggregate: Tip_Playlist_Aggregate;
  /** fetch data from the table: "tip_playlist" using primary key columns */
  tip_playlist_by_pk?: Maybe<Tip_Playlist>;
  /** fetch data from the table: "track" */
  track: Array<Track>;
  /** fetch aggregated fields from the table: "track" */
  track_aggregate: Track_Aggregate;
  /** fetch data from the table: "track" using primary key columns */
  track_by_pk?: Maybe<Track>;
  /** fetch data from the table: "track_type" */
  track_type: Array<Track_Type>;
  /** fetch aggregated fields from the table: "track_type" */
  track_type_aggregate: Track_Type_Aggregate;
  /** fetch data from the table: "track_type" using primary key columns */
  track_type_by_pk?: Maybe<Track_Type>;
  /** fetch data from the table: "transpose_index" */
  transpose_index: Array<Transpose_Index>;
  /** fetch aggregated fields from the table: "transpose_index" */
  transpose_index_aggregate: Transpose_Index_Aggregate;
  /** fetch data from the table: "transpose_index" using primary key columns */
  transpose_index_by_pk?: Maybe<Transpose_Index>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "utils.avg_lessons" */
  utils_avg_lessons: Array<Utils_Avg_Lessons>;
  /** fetch aggregated fields from the table: "utils.avg_lessons" */
  utils_avg_lessons_aggregate: Utils_Avg_Lessons_Aggregate;
  /** fetch data from the table: "utils.most_attended_classes" */
  utils_most_attended_classes: Array<Utils_Most_Attended_Classes>;
  /** fetch aggregated fields from the table: "utils.most_attended_classes" */
  utils_most_attended_classes_aggregate: Utils_Most_Attended_Classes_Aggregate;
  /** fetch data from the table: "utils.most_attended_subjects" */
  utils_most_attended_subjects: Array<Utils_Most_Attended_Subjects>;
  /** fetch aggregated fields from the table: "utils.most_attended_subjects" */
  utils_most_attended_subjects_aggregate: Utils_Most_Attended_Subjects_Aggregate;
  /** execute function "utils.search_last_classes" which returns "class" */
  utils_search_last_classes: Array<Class>;
  /** execute function "utils.search_last_classes" and query aggregates on result of table type "class" */
  utils_search_last_classes_aggregate: Class_Aggregate;
  /** execute function "utils.search_most_attended_classes" which returns "utils.most_attended_classes" */
  utils_search_most_attended_classes: Array<Utils_Most_Attended_Classes>;
  /** execute function "utils.search_most_attended_classes" and query aggregates on result of table type "utils.most_attended_classes" */
  utils_search_most_attended_classes_aggregate: Utils_Most_Attended_Classes_Aggregate;
  /** execute function "utils.search_most_attended_subjects" which returns "utils.most_attended_subjects" */
  utils_search_most_attended_subjects: Array<Utils_Most_Attended_Subjects>;
  /** execute function "utils.search_most_attended_subjects" and query aggregates on result of table type "utils.most_attended_subjects" */
  utils_search_most_attended_subjects_aggregate: Utils_Most_Attended_Subjects_Aggregate;
  /** execute function "utils.search_new_users" which returns "utils.users_per_month" */
  utils_search_new_users: Array<Utils_Users_Per_Month>;
  /** execute function "utils.search_new_users" and query aggregates on result of table type "utils.users_per_month" */
  utils_search_new_users_aggregate: Utils_Users_Per_Month_Aggregate;
  /** execute function "utils.search_teacher_subscriptions" which returns "utils.teacher_subscriptions_per_month" */
  utils_search_teacher_subscriptions: Array<Utils_Teacher_Subscriptions_Per_Month>;
  /** execute function "utils.search_teacher_subscriptions" and query aggregates on result of table type "utils.teacher_subscriptions_per_month" */
  utils_search_teacher_subscriptions_aggregate: Utils_Teacher_Subscriptions_Per_Month_Aggregate;
  /** fetch data from the table: "utils.teacher_subscriptions_per_month" */
  utils_teacher_subscriptions_per_month: Array<Utils_Teacher_Subscriptions_Per_Month>;
  /** fetch aggregated fields from the table: "utils.teacher_subscriptions_per_month" */
  utils_teacher_subscriptions_per_month_aggregate: Utils_Teacher_Subscriptions_Per_Month_Aggregate;
  /** fetch data from the table: "utils.users_per_month" */
  utils_users_per_month: Array<Utils_Users_Per_Month>;
  /** fetch aggregated fields from the table: "utils.users_per_month" */
  utils_users_per_month_aggregate: Utils_Users_Per_Month_Aggregate;
  validatePromotionCode?: Maybe<ValidatePromotionCodeResponse>;
  validateStudentsInClass: ValidateStudentsInClassData;
  /** fetch data from the table: "visibility" */
  visibility: Array<Visibility>;
  /** fetch aggregated fields from the table: "visibility" */
  visibility_aggregate: Visibility_Aggregate;
  /** fetch data from the table: "visibility" using primary key columns */
  visibility_by_pk?: Maybe<Visibility>;
  /** An array relationship */
  wurrly: Array<Wurrly>;
  /** An aggregate relationship */
  wurrly_aggregate: Wurrly_Aggregate;
  /** fetch data from the table: "wurrly" using primary key columns */
  wurrly_by_pk?: Maybe<Wurrly>;
  /** fetch data from the table: "wurrly_filter" */
  wurrly_filter: Array<Wurrly_Filter>;
  /** fetch aggregated fields from the table: "wurrly_filter" */
  wurrly_filter_aggregate: Wurrly_Filter_Aggregate;
  /** fetch data from the table: "wurrly_filter" using primary key columns */
  wurrly_filter_by_pk?: Maybe<Wurrly_Filter>;
  /** fetch data from the table: "wurrly_filter_sticker" */
  wurrly_filter_sticker: Array<Wurrly_Filter_Sticker>;
  /** fetch aggregated fields from the table: "wurrly_filter_sticker" */
  wurrly_filter_sticker_aggregate: Wurrly_Filter_Sticker_Aggregate;
  /** fetch data from the table: "wurrly_processing_status" */
  wurrly_processing_status: Array<Wurrly_Processing_Status>;
  /** fetch aggregated fields from the table: "wurrly_processing_status" */
  wurrly_processing_status_aggregate: Wurrly_Processing_Status_Aggregate;
  /** fetch data from the table: "wurrly_processing_status" using primary key columns */
  wurrly_processing_status_by_pk?: Maybe<Wurrly_Processing_Status>;
  /** fetch data from the table: "wurrly_reaction" */
  wurrly_reaction: Array<Wurrly_Reaction>;
  /** fetch aggregated fields from the table: "wurrly_reaction" */
  wurrly_reaction_aggregate: Wurrly_Reaction_Aggregate;
  /** fetch data from the table: "wurrly_reaction" using primary key columns */
  wurrly_reaction_by_pk?: Maybe<Wurrly_Reaction>;
  /** fetch data from the table: "wurrly_role" */
  wurrly_role: Array<Wurrly_Role>;
  /** fetch aggregated fields from the table: "wurrly_role" */
  wurrly_role_aggregate: Wurrly_Role_Aggregate;
  /** fetch data from the table: "wurrly_role" using primary key columns */
  wurrly_role_by_pk?: Maybe<Wurrly_Role>;
  /** fetch data from the table: "wurrly_type" */
  wurrly_type: Array<Wurrly_Type>;
  /** fetch aggregated fields from the table: "wurrly_type" */
  wurrly_type_aggregate: Wurrly_Type_Aggregate;
  /** fetch data from the table: "wurrly_type" using primary key columns */
  wurrly_type_by_pk?: Maybe<Wurrly_Type>;
};


export type Query_RootAccount_ActivationArgs = {
  distinct_on?: Maybe<Array<Account_Activation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Activation_Order_By>>;
  where?: Maybe<Account_Activation_Bool_Exp>;
};


export type Query_RootAccount_Activation_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Activation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Activation_Order_By>>;
  where?: Maybe<Account_Activation_Bool_Exp>;
};


export type Query_RootAccount_Activation_By_PkArgs = {
  account_activation_id: Scalars['Int'];
};


export type Query_RootAddStudentsByBulkQRsArgs = {
  filter: AddStudentsByBulkQRsFilter;
};


export type Query_RootAdminArgs = {
  distinct_on?: Maybe<Array<Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Order_By>>;
  where?: Maybe<Admin_Bool_Exp>;
};


export type Query_RootAdminLoginArgs = {
  filter?: Maybe<AdminLoginFilter>;
};


export type Query_RootAdmin_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Order_By>>;
  where?: Maybe<Admin_Bool_Exp>;
};


export type Query_RootAdmin_By_PkArgs = {
  admin_id: Scalars['Int'];
};


export type Query_RootAppArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


export type Query_RootApp_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


export type Query_RootApp_By_PkArgs = {
  app_id: Scalars['Int'];
};


export type Query_RootApplyStickerArgs = {
  filter: ApplyStickerFilter;
};


export type Query_RootArtistArgs = {
  distinct_on?: Maybe<Array<Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Order_By>>;
  where?: Maybe<Artist_Bool_Exp>;
};


export type Query_RootArtist_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Order_By>>;
  where?: Maybe<Artist_Bool_Exp>;
};


export type Query_RootArtist_By_PkArgs = {
  artist_id: Scalars['Int'];
};


export type Query_RootAssetArgs = {
  distinct_on?: Maybe<Array<Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Order_By>>;
  where?: Maybe<Asset_Bool_Exp>;
};


export type Query_RootAsset_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Order_By>>;
  where?: Maybe<Asset_Bool_Exp>;
};


export type Query_RootAsset_By_PkArgs = {
  asset_id: Scalars['Int'];
};


export type Query_RootAssignmentArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Query_RootAssignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Query_RootAssignment_AssetArgs = {
  distinct_on?: Maybe<Array<Assignment_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Asset_Order_By>>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};


export type Query_RootAssignment_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Asset_Order_By>>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};


export type Query_RootAssignment_Asset_By_PkArgs = {
  assignment_asset_id: Scalars['Int'];
};


export type Query_RootAssignment_By_PkArgs = {
  assignment_id: Scalars['Int'];
};


export type Query_RootAssignment_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Assignment_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Lesson_Plan_Order_By>>;
  where?: Maybe<Assignment_Lesson_Plan_Bool_Exp>;
};


export type Query_RootAssignment_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Lesson_Plan_Order_By>>;
  where?: Maybe<Assignment_Lesson_Plan_Bool_Exp>;
};


export type Query_RootAssignment_Lesson_Plan_By_PkArgs = {
  assignment_lesson_plan_id: Scalars['Int'];
};


export type Query_RootAssignment_SongArgs = {
  distinct_on?: Maybe<Array<Assignment_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Song_Order_By>>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};


export type Query_RootAssignment_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Song_Order_By>>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};


export type Query_RootAssignment_Song_By_PkArgs = {
  assignment_song_id: Scalars['Int'];
};


export type Query_RootAssignment_TipArgs = {
  distinct_on?: Maybe<Array<Assignment_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Tip_Order_By>>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};


export type Query_RootAssignment_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Tip_Order_By>>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};


export type Query_RootAssignment_Tip_By_PkArgs = {
  assignment_tip_id: Scalars['Int'];
};


export type Query_RootAvailableArgs = {
  distinct_on?: Maybe<Array<Available_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Order_By>>;
  where?: Maybe<Available_Bool_Exp>;
};


export type Query_RootAvailable_AggregateArgs = {
  distinct_on?: Maybe<Array<Available_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Order_By>>;
  where?: Maybe<Available_Bool_Exp>;
};


export type Query_RootAvailable_By_PkArgs = {
  available_id: Scalars['Int'];
};


export type Query_RootAwarded_Badge_EventArgs = {
  distinct_on?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Awarded_Badge_Event_Order_By>>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};


export type Query_RootAwarded_Badge_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Awarded_Badge_Event_Order_By>>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};


export type Query_RootAwarded_Badge_Event_By_PkArgs = {
  student_badge_event_id: Scalars['Int'];
  student_badge_id: Scalars['Int'];
};


export type Query_RootBadgeArgs = {
  distinct_on?: Maybe<Array<Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Order_By>>;
  where?: Maybe<Badge_Bool_Exp>;
};


export type Query_RootBadge_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Order_By>>;
  where?: Maybe<Badge_Bool_Exp>;
};


export type Query_RootBadge_By_PkArgs = {
  badge_id: Scalars['Int'];
};


export type Query_RootBadge_EventArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Order_By>>;
  where?: Maybe<Badge_Event_Bool_Exp>;
};


export type Query_RootBadge_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Order_By>>;
  where?: Maybe<Badge_Event_Bool_Exp>;
};


export type Query_RootBadge_Event_By_PkArgs = {
  badge_id: Scalars['Int'];
  event_name: Badge_Event_Name_Enum;
};


export type Query_RootBadge_Event_NameArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Name_Order_By>>;
  where?: Maybe<Badge_Event_Name_Bool_Exp>;
};


export type Query_RootBadge_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Name_Order_By>>;
  where?: Maybe<Badge_Event_Name_Bool_Exp>;
};


export type Query_RootBadge_Event_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootBilling_HistoryArgs = {
  distinct_on?: Maybe<Array<Billing_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_History_Order_By>>;
  where?: Maybe<Billing_History_Bool_Exp>;
};


export type Query_RootBilling_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_History_Order_By>>;
  where?: Maybe<Billing_History_Bool_Exp>;
};


export type Query_RootBilling_History_By_PkArgs = {
  billing_history_id: Scalars['Int'];
};


export type Query_RootBilling_MethodArgs = {
  distinct_on?: Maybe<Array<Billing_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Method_Order_By>>;
  where?: Maybe<Billing_Method_Bool_Exp>;
};


export type Query_RootBilling_Method_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Method_Order_By>>;
  where?: Maybe<Billing_Method_Bool_Exp>;
};


export type Query_RootBilling_Method_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootBilling_StatusArgs = {
  distinct_on?: Maybe<Array<Billing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Status_Order_By>>;
  where?: Maybe<Billing_Status_Bool_Exp>;
};


export type Query_RootBilling_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Status_Order_By>>;
  where?: Maybe<Billing_Status_Bool_Exp>;
};


export type Query_RootBilling_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootCatalogArgs = {
  distinct_on?: Maybe<Array<Catalog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Order_By>>;
  where?: Maybe<Catalog_Bool_Exp>;
};


export type Query_RootCatalog_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Order_By>>;
  where?: Maybe<Catalog_Bool_Exp>;
};


export type Query_RootCatalog_By_PkArgs = {
  catalog_id: Scalars['Int'];
};


export type Query_RootCatalog_ItemArgs = {
  distinct_on?: Maybe<Array<Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Item_Order_By>>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};


export type Query_RootCatalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Item_Order_By>>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};


export type Query_RootCatalog_Item_By_PkArgs = {
  catalog_item_id: Scalars['Int'];
};


export type Query_RootCatalog_NameArgs = {
  distinct_on?: Maybe<Array<Catalog_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Name_Order_By>>;
  where?: Maybe<Catalog_Name_Bool_Exp>;
};


export type Query_RootCatalog_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Name_Order_By>>;
  where?: Maybe<Catalog_Name_Bool_Exp>;
};


export type Query_RootCatalog_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootCategory_StandardArgs = {
  distinct_on?: Maybe<Array<Category_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Standard_Order_By>>;
  where?: Maybe<Category_Standard_Bool_Exp>;
};


export type Query_RootCategory_Standard_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Standard_Order_By>>;
  where?: Maybe<Category_Standard_Bool_Exp>;
};


export type Query_RootCategory_Standard_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootChallengeArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Order_By>>;
  where?: Maybe<Challenge_Bool_Exp>;
};


export type Query_RootChallenge_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Order_By>>;
  where?: Maybe<Challenge_Bool_Exp>;
};


export type Query_RootChallenge_By_PkArgs = {
  challenge_id: Scalars['Int'];
};


export type Query_RootChallenge_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Catalog_Item_Order_By>>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};


export type Query_RootChallenge_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Catalog_Item_Order_By>>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};


export type Query_RootChallenge_Catalog_Item_By_PkArgs = {
  challenge_catalog_item_id: Scalars['Int'];
};


export type Query_RootChallenge_DistrictArgs = {
  distinct_on?: Maybe<Array<Challenge_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_District_Order_By>>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};


export type Query_RootChallenge_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_District_Order_By>>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};


export type Query_RootChallenge_District_By_PkArgs = {
  challenge_district_id: Scalars['Int'];
};


export type Query_RootChallenge_GradeArgs = {
  distinct_on?: Maybe<Array<Challenge_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Grade_Order_By>>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};


export type Query_RootChallenge_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Grade_Order_By>>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};


export type Query_RootChallenge_Grade_By_PkArgs = {
  challenge_grade_id: Scalars['Int'];
};


export type Query_RootChallenge_SongArgs = {
  distinct_on?: Maybe<Array<Challenge_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Song_Order_By>>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};


export type Query_RootChallenge_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Song_Order_By>>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};


export type Query_RootChallenge_Song_By_PkArgs = {
  challenge_song_id: Scalars['Int'];
};


export type Query_RootChallenge_StudentArgs = {
  distinct_on?: Maybe<Array<Challenge_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Student_Order_By>>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};


export type Query_RootChallenge_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Student_Order_By>>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};


export type Query_RootChallenge_Student_By_PkArgs = {
  challenge_student_id: Scalars['Int'];
};


export type Query_RootChannelArgs = {
  distinct_on?: Maybe<Array<Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Order_By>>;
  where?: Maybe<Channel_Bool_Exp>;
};


export type Query_RootChannel_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Order_By>>;
  where?: Maybe<Channel_Bool_Exp>;
};


export type Query_RootChannel_By_PkArgs = {
  channel_id: Scalars['Int'];
};


export type Query_RootChannel_PlaylistArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


export type Query_RootChannel_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


export type Query_RootChannel_Playlist_By_PkArgs = {
  channel_playlist_id: Scalars['Int'];
};


export type Query_RootClassArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Query_RootClass_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Query_RootClass_AssignmentArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


export type Query_RootClass_Assignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


export type Query_RootClass_Assignment_By_PkArgs = {
  class_assignment_id: Scalars['Int'];
};


export type Query_RootClass_Assignment_StatusArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Status_Order_By>>;
  where?: Maybe<Class_Assignment_Status_Bool_Exp>;
};


export type Query_RootClass_Assignment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Status_Order_By>>;
  where?: Maybe<Class_Assignment_Status_Bool_Exp>;
};


export type Query_RootClass_Assignment_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootClass_By_PkArgs = {
  class_id: Scalars['Int'];
};


export type Query_RootClass_ImagesArgs = {
  distinct_on?: Maybe<Array<Class_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Images_Order_By>>;
  where?: Maybe<Class_Images_Bool_Exp>;
};


export type Query_RootClass_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Images_Order_By>>;
  where?: Maybe<Class_Images_Bool_Exp>;
};


export type Query_RootClass_Images_By_PkArgs = {
  class_image_id: Scalars['Int'];
};


export type Query_RootClass_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


export type Query_RootClass_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


export type Query_RootClass_Lesson_Plan_By_PkArgs = {
  class_lesson_plan_id: Scalars['Int'];
};


export type Query_RootClass_ModuleArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


export type Query_RootClass_Module_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


export type Query_RootClass_Module_By_PkArgs = {
  class_module_id: Scalars['Int'];
};


export type Query_RootClass_PlaylistArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


export type Query_RootClass_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


export type Query_RootClass_Playlist_By_PkArgs = {
  class_playlist_id: Scalars['Int'];
};


export type Query_RootClass_SequenceArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


export type Query_RootClass_Sequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


export type Query_RootClass_Sequence_By_PkArgs = {
  class_sequence_id: Scalars['Int'];
};


export type Query_RootClass_SongArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


export type Query_RootClass_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


export type Query_RootClass_Song_By_PkArgs = {
  class_song_id: Scalars['Int'];
};


export type Query_RootClass_StudentArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


export type Query_RootClass_Student_ActivitiesArgs = {
  distinct_on?: Maybe<Array<Class_Student_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Activities_Order_By>>;
  where?: Maybe<Class_Student_Activities_Bool_Exp>;
};


export type Query_RootClass_Student_Activities_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Student_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Activities_Order_By>>;
  where?: Maybe<Class_Student_Activities_Bool_Exp>;
};


export type Query_RootClass_Student_Activities_By_PkArgs = {
  class_student_activities_id: Scalars['Int'];
};


export type Query_RootClass_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


export type Query_RootClass_Student_By_PkArgs = {
  class_student_id: Scalars['Int'];
};


export type Query_RootClass_TeacherArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


export type Query_RootClass_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


export type Query_RootClass_Teacher_By_PkArgs = {
  class_teacher_id: Scalars['Int'];
};


export type Query_RootClass_TipArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


export type Query_RootClass_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


export type Query_RootClass_Tip_By_PkArgs = {
  class_tip_id: Scalars['Int'];
};


export type Query_RootClever_Roster_LogArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Log_Order_By>>;
  where?: Maybe<Clever_Roster_Log_Bool_Exp>;
};


export type Query_RootClever_Roster_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Log_Order_By>>;
  where?: Maybe<Clever_Roster_Log_Bool_Exp>;
};


export type Query_RootClever_Roster_Log_By_PkArgs = {
  clever_roster_log_id: Scalars['Int'];
};


export type Query_RootClever_Roster_StatusArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Status_Order_By>>;
  where?: Maybe<Clever_Roster_Status_Bool_Exp>;
};


export type Query_RootClever_Roster_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Status_Order_By>>;
  where?: Maybe<Clever_Roster_Status_Bool_Exp>;
};


export type Query_RootClever_Roster_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootContact_UsArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Order_By>>;
  where?: Maybe<Contact_Us_Bool_Exp>;
};


export type Query_RootContact_Us_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Order_By>>;
  where?: Maybe<Contact_Us_Bool_Exp>;
};


export type Query_RootContact_Us_By_PkArgs = {
  contact_us_id: Scalars['Int'];
};


export type Query_RootContact_Us_Camp_TeacherArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Camp_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Camp_Teacher_Order_By>>;
  where?: Maybe<Contact_Us_Camp_Teacher_Bool_Exp>;
};


export type Query_RootContact_Us_Camp_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Camp_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Camp_Teacher_Order_By>>;
  where?: Maybe<Contact_Us_Camp_Teacher_Bool_Exp>;
};


export type Query_RootContact_Us_Camp_Teacher_By_PkArgs = {
  contact_us_camp_teacher_id: Scalars['Int'];
};


export type Query_RootCrossclear_MasterlistArgs = {
  distinct_on?: Maybe<Array<Crossclear_Masterlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crossclear_Masterlist_Order_By>>;
  where?: Maybe<Crossclear_Masterlist_Bool_Exp>;
};


export type Query_RootCrossclear_Masterlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Crossclear_Masterlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crossclear_Masterlist_Order_By>>;
  where?: Maybe<Crossclear_Masterlist_Bool_Exp>;
};


export type Query_RootCrossclear_Masterlist_By_PkArgs = {
  masterlist_id: Scalars['Int'];
};


export type Query_RootDisciplineArgs = {
  distinct_on?: Maybe<Array<Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discipline_Order_By>>;
  where?: Maybe<Discipline_Bool_Exp>;
};


export type Query_RootDiscipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discipline_Order_By>>;
  where?: Maybe<Discipline_Bool_Exp>;
};


export type Query_RootDiscipline_By_PkArgs = {
  discipline_id: Scalars['Int'];
};


export type Query_RootDistrictArgs = {
  distinct_on?: Maybe<Array<District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Order_By>>;
  where?: Maybe<District_Bool_Exp>;
};


export type Query_RootDistrict_AdminArgs = {
  distinct_on?: Maybe<Array<District_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Admin_Order_By>>;
  where?: Maybe<District_Admin_Bool_Exp>;
};


export type Query_RootDistrict_Admin_AggregateArgs = {
  distinct_on?: Maybe<Array<District_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Admin_Order_By>>;
  where?: Maybe<District_Admin_Bool_Exp>;
};


export type Query_RootDistrict_Admin_By_PkArgs = {
  district_admin_id: Scalars['Int'];
};


export type Query_RootDistrict_AggregateArgs = {
  distinct_on?: Maybe<Array<District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Order_By>>;
  where?: Maybe<District_Bool_Exp>;
};


export type Query_RootDistrict_By_PkArgs = {
  district_id: Scalars['Int'];
};


export type Query_RootDownloadQrCodeArgs = {
  filter: DownloadQrCodeFilter;
};


export type Query_RootFilter_ColorArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Order_By>>;
  where?: Maybe<Filter_Color_Bool_Exp>;
};


export type Query_RootFilter_Color_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Order_By>>;
  where?: Maybe<Filter_Color_Bool_Exp>;
};


export type Query_RootFilter_Color_By_PkArgs = {
  filter_color_id: Scalars['Int'];
};


export type Query_RootFilter_Color_NameArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Name_Order_By>>;
  where?: Maybe<Filter_Color_Name_Bool_Exp>;
};


export type Query_RootFilter_Color_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Name_Order_By>>;
  where?: Maybe<Filter_Color_Name_Bool_Exp>;
};


export type Query_RootFilter_Color_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootFilter_FrameArgs = {
  distinct_on?: Maybe<Array<Filter_Frame_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Frame_Order_By>>;
  where?: Maybe<Filter_Frame_Bool_Exp>;
};


export type Query_RootFilter_Frame_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Frame_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Frame_Order_By>>;
  where?: Maybe<Filter_Frame_Bool_Exp>;
};


export type Query_RootFilter_StickerArgs = {
  distinct_on?: Maybe<Array<Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Sticker_Order_By>>;
  where?: Maybe<Filter_Sticker_Bool_Exp>;
};


export type Query_RootFilter_Sticker_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Sticker_Order_By>>;
  where?: Maybe<Filter_Sticker_Bool_Exp>;
};


export type Query_RootFilter_Sticker_By_PkArgs = {
  filter_sticker_id: Scalars['Int'];
};


export type Query_RootFramesArgs = {
  filter: FramesFilter;
};


export type Query_RootGetCanvasAuthUrlArgs = {
  filter?: Maybe<GetCanvasAuthUrlFilter>;
};


export type Query_RootGetCanvasUserArgs = {
  filter: GetCanvasUserFilter;
};


export type Query_RootGetClassLinkAuthUrlArgs = {
  filter?: Maybe<GetClassLinkAuthUrlFilter>;
};


export type Query_RootGetClassLinkGroupsArgs = {
  filter: GetClassLinkGroupsFilter;
};


export type Query_RootGetClassLinkStudentsArgs = {
  filter: GetClassLinkStudentsFilter;
};


export type Query_RootGetClassLinkUserArgs = {
  filter: GetClassLinkUserFilter;
};


export type Query_RootGetCleverAccountArgs = {
  filter?: Maybe<GetCleverAccountFilter>;
};


export type Query_RootGetCleverAuthUrlArgs = {
  filter?: Maybe<GetCleverAuthUrlFilter>;
};


export type Query_RootGetCleverGroupsArgs = {
  filter?: Maybe<GetCleverGroupsFilter>;
};


export type Query_RootGetCleverMembersArgs = {
  filter?: Maybe<GetCleverMembersFilter>;
};


export type Query_RootGetCleverTokenArgs = {
  filter?: Maybe<GetCleverTokenFilter>;
};


export type Query_RootGetGoogleAccountArgs = {
  filter?: Maybe<GetGoogleAccountFilter>;
};


export type Query_RootGetGoogleAuthUrlArgs = {
  filter?: Maybe<GetGoogleAuthUrlFilter>;
};


export type Query_RootGetGoogleGroupsArgs = {
  filter?: Maybe<GetGoogleGroupsFilter>;
};


export type Query_RootGetGoogleMembersArgs = {
  filter?: Maybe<GetGoogleMembersFilter>;
};


export type Query_RootGetGoogleTokenArgs = {
  filter?: Maybe<GetGoogleTokenFilter>;
};


export type Query_RootGetMsAccountArgs = {
  filter?: Maybe<GetMsAccountFilter>;
};


export type Query_RootGetMsAuthUrlArgs = {
  filter?: Maybe<GetMsAuthUrlFilter>;
};


export type Query_RootGetMsGroupsArgs = {
  filter?: Maybe<GetMsGroupsFilter>;
};


export type Query_RootGetMsMembersArgs = {
  filter?: Maybe<GetMsMembersFilter>;
};


export type Query_RootGetMsTokenArgs = {
  filter?: Maybe<GetMsTokenFilter>;
};


export type Query_RootGetSignupIntegrationTokenArgs = {
  filter?: Maybe<SignupTeacherIntegrationGetTokenFilter>;
};


export type Query_RootGetSongTrackEventsArgs = {
  filter: GetSongTrackEventsFilter;
};


export type Query_RootGetStudentChallengesArgs = {
  filter: GetStudentChallengesFilter;
};


export type Query_RootGetStudentQuestionaryArgs = {
  filter: GetStudentQuestionaryFilter;
};


export type Query_RootGetSubscriptionArgs = {
  filter?: Maybe<GetSubscriptionFilter>;
};


export type Query_RootGetValidCodesArgs = {
  filter?: Maybe<Scalars['Int']>;
};


export type Query_RootGradeArgs = {
  distinct_on?: Maybe<Array<Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grade_Order_By>>;
  where?: Maybe<Grade_Bool_Exp>;
};


export type Query_RootGrade_AggregateArgs = {
  distinct_on?: Maybe<Array<Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grade_Order_By>>;
  where?: Maybe<Grade_Bool_Exp>;
};


export type Query_RootGrade_By_PkArgs = {
  grade_id: Scalars['Int'];
};


export type Query_RootGrading_SystemArgs = {
  distinct_on?: Maybe<Array<Grading_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Order_By>>;
  where?: Maybe<Grading_System_Bool_Exp>;
};


export type Query_RootGrading_System_AggregateArgs = {
  distinct_on?: Maybe<Array<Grading_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Order_By>>;
  where?: Maybe<Grading_System_Bool_Exp>;
};


export type Query_RootGrading_System_By_PkArgs = {
  grading_system_id: Scalars['Int'];
};


export type Query_RootGrading_System_TypeArgs = {
  distinct_on?: Maybe<Array<Grading_System_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Type_Order_By>>;
  where?: Maybe<Grading_System_Type_Bool_Exp>;
};


export type Query_RootGrading_System_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Grading_System_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Type_Order_By>>;
  where?: Maybe<Grading_System_Type_Bool_Exp>;
};


export type Query_RootGrading_System_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootImagePathArgs = {
  filter: TipInputFilter;
};


export type Query_RootImpersonateTeacherArgs = {
  filter: ImpersonateTeacherFilter;
};


export type Query_RootIntegration_NameArgs = {
  distinct_on?: Maybe<Array<Integration_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Name_Order_By>>;
  where?: Maybe<Integration_Name_Bool_Exp>;
};


export type Query_RootIntegration_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Name_Order_By>>;
  where?: Maybe<Integration_Name_Bool_Exp>;
};


export type Query_RootIntegration_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootInvitation_StatusArgs = {
  distinct_on?: Maybe<Array<Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitation_Status_Order_By>>;
  where?: Maybe<Invitation_Status_Bool_Exp>;
};


export type Query_RootInvitation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitation_Status_Order_By>>;
  where?: Maybe<Invitation_Status_Bool_Exp>;
};


export type Query_RootInvitation_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootKeywordArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


export type Query_RootKeyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


export type Query_RootKeyword_By_PkArgs = {
  keyword_id: Scalars['Int'];
};


export type Query_RootLabel_ControlArgs = {
  distinct_on?: Maybe<Array<Label_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Label_Control_Order_By>>;
  where?: Maybe<Label_Control_Bool_Exp>;
};


export type Query_RootLabel_Control_AggregateArgs = {
  distinct_on?: Maybe<Array<Label_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Label_Control_Order_By>>;
  where?: Maybe<Label_Control_Bool_Exp>;
};


export type Query_RootLabel_Control_By_PkArgs = {
  label_control_id: Scalars['Int'];
};


export type Query_RootLabelsArgs = {
  distinct_on?: Maybe<Array<Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Labels_Order_By>>;
  where?: Maybe<Labels_Bool_Exp>;
};


export type Query_RootLabels_AggregateArgs = {
  distinct_on?: Maybe<Array<Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Labels_Order_By>>;
  where?: Maybe<Labels_Bool_Exp>;
};


export type Query_RootLabels_By_PkArgs = {
  labels_id: Scalars['Int'];
};


export type Query_RootLessonPlanPdfArgs = {
  filter?: Maybe<LessonPlanPdfFilter>;
};


export type Query_RootLesson_PlanArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Order_By>>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};


export type Query_RootLesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Order_By>>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};


export type Query_RootLesson_Plan_ArticleArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Article_Order_By>>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};


export type Query_RootLesson_Plan_Article_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Article_Order_By>>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};


export type Query_RootLesson_Plan_Article_By_PkArgs = {
  lesson_plan_article_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_AssetArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


export type Query_RootLesson_Plan_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


export type Query_RootLesson_Plan_Asset_By_PkArgs = {
  lesson_plan_asset_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_By_PkArgs = {
  lesson_plan_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Catalog_Item_Order_By>>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};


export type Query_RootLesson_Plan_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Catalog_Item_Order_By>>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};


export type Query_RootLesson_Plan_Catalog_Item_By_PkArgs = {
  lesson_plan_catalog_item_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_DisciplineArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


export type Query_RootLesson_Plan_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


export type Query_RootLesson_Plan_Discipline_By_PkArgs = {
  lesson_plan_discipline_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_GradeArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Grade_Order_By>>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};


export type Query_RootLesson_Plan_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Grade_Order_By>>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};


export type Query_RootLesson_Plan_Grade_By_PkArgs = {
  lesson_plan_grade_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_KeywordArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Keyword_Order_By>>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};


export type Query_RootLesson_Plan_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Keyword_Order_By>>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};


export type Query_RootLesson_Plan_Keyword_By_PkArgs = {
  lesson_plan_keyword_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};


export type Query_RootLesson_Plan_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};


export type Query_RootLesson_Plan_Musical_Skill_By_PkArgs = {
  lesson_plan_musical_skill_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_Non_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};


export type Query_RootLesson_Plan_Non_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};


export type Query_RootLesson_Plan_Non_Musical_Skill_By_PkArgs = {
  lesson_plan_non_musical_skill_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_Skill_LevelArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Skill_Level_Order_By>>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};


export type Query_RootLesson_Plan_Skill_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Skill_Level_Order_By>>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};


export type Query_RootLesson_Plan_Skill_Level_By_PkArgs = {
  lesson_plan_skill_level_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_SongArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Song_Order_By>>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};


export type Query_RootLesson_Plan_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Song_Order_By>>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};


export type Query_RootLesson_Plan_Song_By_PkArgs = {
  lesson_plan_song_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_StandardArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Standard_Order_By>>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};


export type Query_RootLesson_Plan_Standard_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Standard_Order_By>>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};


export type Query_RootLesson_Plan_Standard_By_PkArgs = {
  lesson_plan_standar_id: Scalars['Int'];
};


export type Query_RootLesson_Plan_TipArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Tip_Order_By>>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};


export type Query_RootLesson_Plan_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Tip_Order_By>>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};


export type Query_RootLesson_Plan_Tip_By_PkArgs = {
  lesson_plan_tip_id: Scalars['Int'];
};


export type Query_RootLinkMetaDataArgs = {
  filter?: Maybe<LinkMetadataFilter>;
};


export type Query_RootLockable_ContentArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Order_By>>;
  where?: Maybe<Lockable_Content_Bool_Exp>;
};


export type Query_RootLockable_Content_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Order_By>>;
  where?: Maybe<Lockable_Content_Bool_Exp>;
};


export type Query_RootLockable_Content_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
};


export type Query_RootLockable_Content_ClassArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Class_Order_By>>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};


export type Query_RootLockable_Content_Class_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Class_Order_By>>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};


export type Query_RootLockable_Content_Class_By_PkArgs = {
  class_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};


export type Query_RootLockable_Content_DistrictArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


export type Query_RootLockable_Content_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


export type Query_RootLockable_Content_District_By_PkArgs = {
  district_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};


export type Query_RootLockable_Content_SchoolArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


export type Query_RootLockable_Content_School_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


export type Query_RootLockable_Content_School_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type Query_RootLockable_Content_TeacherArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Teacher_Order_By>>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};


export type Query_RootLockable_Content_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Teacher_Order_By>>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};


export type Query_RootLockable_Content_Teacher_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
};


export type Query_RootLoginArgs = {
  filter?: Maybe<LoginFilter>;
};


export type Query_RootLoginIntegrationArgs = {
  filter?: Maybe<LoginIntegrationFilter>;
};


export type Query_RootLoginOrSignupTeacherIntegrationArgs = {
  filter?: Maybe<LoginOrSignupTeacherIntegrationFilter>;
};


export type Query_RootLoginStudentByCodeArgs = {
  filter: LoginStudentByCodeFilter;
};


export type Query_RootMaturity_LevelArgs = {
  distinct_on?: Maybe<Array<Maturity_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Maturity_Level_Order_By>>;
  where?: Maybe<Maturity_Level_Bool_Exp>;
};


export type Query_RootMaturity_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Maturity_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Maturity_Level_Order_By>>;
  where?: Maybe<Maturity_Level_Bool_Exp>;
};


export type Query_RootMaturity_Level_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootMembership_TypeArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Query_RootMembership_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Query_RootMembership_Type_By_PkArgs = {
  membership_type_id: Scalars['Int'];
};


export type Query_RootMidiArgs = {
  distinct_on?: Maybe<Array<Midi_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Midi_Order_By>>;
  where?: Maybe<Midi_Bool_Exp>;
};


export type Query_RootMidi_AggregateArgs = {
  distinct_on?: Maybe<Array<Midi_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Midi_Order_By>>;
  where?: Maybe<Midi_Bool_Exp>;
};


export type Query_RootMidi_By_PkArgs = {
  midi_id: Scalars['Int'];
};


export type Query_RootModuleArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Query_RootModule_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Query_RootModule_By_PkArgs = {
  module_id: Scalars['Int'];
};


export type Query_RootModule_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Catalog_Item_Order_By>>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};


export type Query_RootModule_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Catalog_Item_Order_By>>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};


export type Query_RootModule_Catalog_Item_By_PkArgs = {
  module_catalog_item_id: Scalars['Int'];
};


export type Query_RootModule_GradeArgs = {
  distinct_on?: Maybe<Array<Module_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Grade_Order_By>>;
  where?: Maybe<Module_Grade_Bool_Exp>;
};


export type Query_RootModule_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Grade_Order_By>>;
  where?: Maybe<Module_Grade_Bool_Exp>;
};


export type Query_RootModule_Grade_By_PkArgs = {
  module_grade_id: Scalars['Int'];
};


export type Query_RootModule_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


export type Query_RootModule_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


export type Query_RootModule_Lesson_Plan_By_PkArgs = {
  module_lesson_plan_id: Scalars['Int'];
};


export type Query_RootMusical_SkillArgs = {
  distinct_on?: Maybe<Array<Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Musical_Skill_Order_By>>;
  where?: Maybe<Musical_Skill_Bool_Exp>;
};


export type Query_RootMusical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Musical_Skill_Order_By>>;
  where?: Maybe<Musical_Skill_Bool_Exp>;
};


export type Query_RootMusical_Skill_By_PkArgs = {
  musical_skill_id: Scalars['Int'];
};


export type Query_RootNon_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Bool_Exp>;
};


export type Query_RootNon_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Bool_Exp>;
};


export type Query_RootNon_Musical_Skill_By_PkArgs = {
  non_musical_skill_id: Scalars['Int'];
};


export type Query_RootNon_Musical_Skill_TypeArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Type_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Type_Bool_Exp>;
};


export type Query_RootNon_Musical_Skill_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Type_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Type_Bool_Exp>;
};


export type Query_RootNon_Musical_Skill_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootOrderArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Query_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Query_RootOrder_By_PkArgs = {
  order_id: Scalars['Int'];
};


export type Query_RootOrder_StatusArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Query_RootOrder_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootParent_InvitationArgs = {
  distinct_on?: Maybe<Array<Parent_Invitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Parent_Invitation_Order_By>>;
  where?: Maybe<Parent_Invitation_Bool_Exp>;
};


export type Query_RootParent_Invitation_AggregateArgs = {
  distinct_on?: Maybe<Array<Parent_Invitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Parent_Invitation_Order_By>>;
  where?: Maybe<Parent_Invitation_Bool_Exp>;
};


export type Query_RootParent_Invitation_By_PkArgs = {
  parent_invitation_id: Scalars['Int'];
};


export type Query_RootPartnerArgs = {
  distinct_on?: Maybe<Array<Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Order_By>>;
  where?: Maybe<Partner_Bool_Exp>;
};


export type Query_RootPartner_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Order_By>>;
  where?: Maybe<Partner_Bool_Exp>;
};


export type Query_RootPartner_By_PkArgs = {
  partner_id: Scalars['Int'];
};


export type Query_RootPartner_ModuleArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


export type Query_RootPartner_Module_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


export type Query_RootPartner_Module_By_PkArgs = {
  partner_module_id: Scalars['Int'];
};


export type Query_RootPartner_PlaylistArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


export type Query_RootPartner_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


export type Query_RootPartner_Playlist_By_PkArgs = {
  partner_playlist_id: Scalars['Int'];
};


export type Query_RootPlaylistArgs = {
  distinct_on?: Maybe<Array<Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Playlist_Order_By>>;
  where?: Maybe<Playlist_Bool_Exp>;
};


export type Query_RootPlaylist_AggregateArgs = {
  distinct_on?: Maybe<Array<Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Playlist_Order_By>>;
  where?: Maybe<Playlist_Bool_Exp>;
};


export type Query_RootPlaylist_By_PkArgs = {
  playlist_id: Scalars['Int'];
};


export type Query_RootPresignedUrlArgs = {
  filter?: Maybe<PresignedUrlFilter>;
};


export type Query_RootPrice_TypeArgs = {
  distinct_on?: Maybe<Array<Price_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Type_Order_By>>;
  where?: Maybe<Price_Type_Bool_Exp>;
};


export type Query_RootPrice_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Type_Order_By>>;
  where?: Maybe<Price_Type_Bool_Exp>;
};


export type Query_RootPrice_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootPrivacyArgs = {
  distinct_on?: Maybe<Array<Privacy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Privacy_Order_By>>;
  where?: Maybe<Privacy_Bool_Exp>;
};


export type Query_RootPrivacy_AggregateArgs = {
  distinct_on?: Maybe<Array<Privacy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Privacy_Order_By>>;
  where?: Maybe<Privacy_Bool_Exp>;
};


export type Query_RootPrivacy_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootProduct_NameArgs = {
  distinct_on?: Maybe<Array<Product_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Name_Order_By>>;
  where?: Maybe<Product_Name_Bool_Exp>;
};


export type Query_RootProduct_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Name_Order_By>>;
  where?: Maybe<Product_Name_Bool_Exp>;
};


export type Query_RootProduct_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootPublisherArgs = {
  distinct_on?: Maybe<Array<Publisher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Order_By>>;
  where?: Maybe<Publisher_Bool_Exp>;
};


export type Query_RootPublisher_AggregateArgs = {
  distinct_on?: Maybe<Array<Publisher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Order_By>>;
  where?: Maybe<Publisher_Bool_Exp>;
};


export type Query_RootPublisher_By_PkArgs = {
  publisher_id: Scalars['Int'];
};


export type Query_RootPublisher_ControlArgs = {
  distinct_on?: Maybe<Array<Publisher_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Control_Order_By>>;
  where?: Maybe<Publisher_Control_Bool_Exp>;
};


export type Query_RootPublisher_Control_AggregateArgs = {
  distinct_on?: Maybe<Array<Publisher_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Control_Order_By>>;
  where?: Maybe<Publisher_Control_Bool_Exp>;
};


export type Query_RootPublisher_Control_By_PkArgs = {
  publisher_control_id: Scalars['Int'];
};


export type Query_RootReactionArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


export type Query_RootReaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


export type Query_RootReaction_By_PkArgs = {
  reaction_id: Scalars['Int'];
};


export type Query_RootReset_PasswordArgs = {
  distinct_on?: Maybe<Array<Reset_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reset_Password_Order_By>>;
  where?: Maybe<Reset_Password_Bool_Exp>;
};


export type Query_RootReset_Password_AggregateArgs = {
  distinct_on?: Maybe<Array<Reset_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reset_Password_Order_By>>;
  where?: Maybe<Reset_Password_Bool_Exp>;
};


export type Query_RootReset_Password_By_PkArgs = {
  reset_password_id: Scalars['Int'];
};


export type Query_RootRole_NameArgs = {
  distinct_on?: Maybe<Array<Role_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Name_Order_By>>;
  where?: Maybe<Role_Name_Bool_Exp>;
};


export type Query_RootRole_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Name_Order_By>>;
  where?: Maybe<Role_Name_Bool_Exp>;
};


export type Query_RootRole_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootRoyalty_LogArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


export type Query_RootRoyalty_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


export type Query_RootRoyalty_Log_By_PkArgs = {
  royalty_log_id: Scalars['Int'];
};


export type Query_RootScaleArgs = {
  distinct_on?: Maybe<Array<Scale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scale_Order_By>>;
  where?: Maybe<Scale_Bool_Exp>;
};


export type Query_RootScale_AggregateArgs = {
  distinct_on?: Maybe<Array<Scale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scale_Order_By>>;
  where?: Maybe<Scale_Bool_Exp>;
};


export type Query_RootScale_By_PkArgs = {
  scale_id: Scalars['Int'];
};


export type Query_RootSchoolArgs = {
  distinct_on?: Maybe<Array<School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Order_By>>;
  where?: Maybe<School_Bool_Exp>;
};


export type Query_RootSchool_AdminArgs = {
  distinct_on?: Maybe<Array<School_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Admin_Order_By>>;
  where?: Maybe<School_Admin_Bool_Exp>;
};


export type Query_RootSchool_Admin_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Admin_Order_By>>;
  where?: Maybe<School_Admin_Bool_Exp>;
};


export type Query_RootSchool_Admin_By_PkArgs = {
  school_admin_id: Scalars['Int'];
};


export type Query_RootSchool_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Order_By>>;
  where?: Maybe<School_Bool_Exp>;
};


export type Query_RootSchool_By_PkArgs = {
  school_id: Scalars['Int'];
};


export type Query_RootSchool_TeacherArgs = {
  distinct_on?: Maybe<Array<School_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Teacher_Order_By>>;
  where?: Maybe<School_Teacher_Bool_Exp>;
};


export type Query_RootSchool_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Teacher_Order_By>>;
  where?: Maybe<School_Teacher_Bool_Exp>;
};


export type Query_RootSchool_Teacher_By_PkArgs = {
  school_teacher_id: Scalars['Int'];
};


export type Query_RootScreen_TypeArgs = {
  distinct_on?: Maybe<Array<Screen_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Screen_Type_Order_By>>;
  where?: Maybe<Screen_Type_Bool_Exp>;
};


export type Query_RootScreen_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Screen_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Screen_Type_Order_By>>;
  where?: Maybe<Screen_Type_Bool_Exp>;
};


export type Query_RootScreen_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootSendReinviteStudentsEmailArgs = {
  filter: SendReinviteStudentsEmailFilter;
};


export type Query_RootSendResetPasswordUrlArgs = {
  filter?: Maybe<SendResetPasswordUrlFilter>;
};


export type Query_RootSequenceArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Query_RootSequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Query_RootSequence_By_PkArgs = {
  sequence_id: Scalars['Int'];
};


export type Query_RootSequence_KeywordArgs = {
  distinct_on?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Keyword_Order_By>>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};


export type Query_RootSequence_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Keyword_Order_By>>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};


export type Query_RootSequence_Keyword_By_PkArgs = {
  sequence_keyword_id: Scalars['Int'];
};


export type Query_RootSequence_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


export type Query_RootSequence_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


export type Query_RootSequence_Lesson_Plan_By_PkArgs = {
  sequence_lesson_plan_id: Scalars['Int'];
};


export type Query_RootSequence_TopicArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Order_By>>;
  where?: Maybe<Sequence_Topic_Bool_Exp>;
};


export type Query_RootSequence_Topic_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Order_By>>;
  where?: Maybe<Sequence_Topic_Bool_Exp>;
};


export type Query_RootSequence_Topic_By_PkArgs = {
  sequence_topic_id: Scalars['Int'];
};


export type Query_RootSequence_Topic_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Catalog_Item_Order_By>>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};


export type Query_RootSequence_Topic_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Catalog_Item_Order_By>>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};


export type Query_RootSequence_Topic_Catalog_Item_By_PkArgs = {
  sequence_topic_catalog_item_id: Scalars['Int'];
};


export type Query_RootSequence_Topic_KeywordArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Keyword_Order_By>>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};


export type Query_RootSequence_Topic_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Keyword_Order_By>>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};


export type Query_RootSequence_Topic_Keyword_By_PkArgs = {
  sequence_topic_keyword_id: Scalars['Int'];
};


export type Query_RootSequence_Topic_SequenceArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};


export type Query_RootSequence_Topic_Sequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};


export type Query_RootSequence_Topic_Sequence_By_PkArgs = {
  sequence_topic_sequence_id: Scalars['Int'];
};


export type Query_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_By_PkArgs = {
  session_id: Scalars['Int'];
};


export type Query_RootSkill_LevelArgs = {
  distinct_on?: Maybe<Array<Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Level_Order_By>>;
  where?: Maybe<Skill_Level_Bool_Exp>;
};


export type Query_RootSkill_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Level_Order_By>>;
  where?: Maybe<Skill_Level_Bool_Exp>;
};


export type Query_RootSkill_Level_By_PkArgs = {
  skill_level_id: Scalars['Int'];
};


export type Query_RootSongArgs = {
  distinct_on?: Maybe<Array<Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Order_By>>;
  where?: Maybe<Song_Bool_Exp>;
};


export type Query_RootSong_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Order_By>>;
  where?: Maybe<Song_Bool_Exp>;
};


export type Query_RootSong_By_PkArgs = {
  song_id: Scalars['Int'];
};


export type Query_RootSong_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Catalog_Item_Order_By>>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};


export type Query_RootSong_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Catalog_Item_Order_By>>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};


export type Query_RootSong_Catalog_Item_By_PkArgs = {
  song_catalog_item_id: Scalars['Int'];
};


export type Query_RootSong_GradeArgs = {
  distinct_on?: Maybe<Array<Song_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Grade_Order_By>>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};


export type Query_RootSong_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Grade_Order_By>>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};


export type Query_RootSong_Grade_By_PkArgs = {
  song_grade_id: Scalars['Int'];
};


export type Query_RootSong_KeywordArgs = {
  distinct_on?: Maybe<Array<Song_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Keyword_Order_By>>;
  where?: Maybe<Song_Keyword_Bool_Exp>;
};


export type Query_RootSong_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Keyword_Order_By>>;
  where?: Maybe<Song_Keyword_Bool_Exp>;
};


export type Query_RootSong_Keyword_By_PkArgs = {
  song_keyword_id: Scalars['Int'];
};


export type Query_RootStandardArgs = {
  distinct_on?: Maybe<Array<Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Order_By>>;
  where?: Maybe<Standard_Bool_Exp>;
};


export type Query_RootStandard_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Order_By>>;
  where?: Maybe<Standard_Bool_Exp>;
};


export type Query_RootStandard_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootStandard_OptionArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};


export type Query_RootStandard_Option_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};


export type Query_RootStandard_Option_By_PkArgs = {
  standar_option_id: Scalars['Int'];
};


export type Query_RootStandard_SubcategoryArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};


export type Query_RootStandard_Subcategory_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};


export type Query_RootStandard_Subcategory_By_PkArgs = {
  standar_subcategory_id: Scalars['Int'];
};


export type Query_RootStudentArgs = {
  distinct_on?: Maybe<Array<Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Order_By>>;
  where?: Maybe<Student_Bool_Exp>;
};


export type Query_RootStudentEmailExistsArgs = {
  filter?: Maybe<ValidateEmailFilter>;
};


export type Query_RootStudentLoginAttemptFailedEmailArgs = {
  filter: StudentLoginAttemptFailedEmailFilter;
};


export type Query_RootStudentLoginIntegrationArgs = {
  filter?: Maybe<StudentLoginIntegrationFilter>;
};


export type Query_RootStudent_ActivityArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Order_By>>;
  where?: Maybe<Student_Activity_Bool_Exp>;
};


export type Query_RootStudent_Activity_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Order_By>>;
  where?: Maybe<Student_Activity_Bool_Exp>;
};


export type Query_RootStudent_Activity_By_PkArgs = {
  student_activity_id: Scalars['Int'];
};


export type Query_RootStudent_Activity_TypeArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Type_Order_By>>;
  where?: Maybe<Student_Activity_Type_Bool_Exp>;
};


export type Query_RootStudent_Activity_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Type_Order_By>>;
  where?: Maybe<Student_Activity_Type_Bool_Exp>;
};


export type Query_RootStudent_Activity_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootStudent_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Order_By>>;
  where?: Maybe<Student_Bool_Exp>;
};


export type Query_RootStudent_BadgeArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Order_By>>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};


export type Query_RootStudent_Badge_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Order_By>>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};


export type Query_RootStudent_Badge_By_PkArgs = {
  student_badge_id: Scalars['Int'];
};


export type Query_RootStudent_Badge_EventArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Event_Order_By>>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};


export type Query_RootStudent_Badge_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Event_Order_By>>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};


export type Query_RootStudent_Badge_Event_By_PkArgs = {
  student_badge_event_id: Scalars['Int'];
};


export type Query_RootStudent_Badge_ResumeArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Resume_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Resume_Order_By>>;
  where?: Maybe<Student_Badge_Resume_Bool_Exp>;
};


export type Query_RootStudent_Badge_Resume_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Resume_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Resume_Order_By>>;
  where?: Maybe<Student_Badge_Resume_Bool_Exp>;
};


export type Query_RootStudent_BannerArgs = {
  distinct_on?: Maybe<Array<Student_Banner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Banner_Order_By>>;
  where?: Maybe<Student_Banner_Bool_Exp>;
};


export type Query_RootStudent_Banner_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Banner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Banner_Order_By>>;
  where?: Maybe<Student_Banner_Bool_Exp>;
};


export type Query_RootStudent_Banner_By_PkArgs = {
  student_banner_id: Scalars['Int'];
};


export type Query_RootStudent_By_PkArgs = {
  student_id: Scalars['Int'];
};


export type Query_RootStudent_DistrictArgs = {
  distinct_on?: Maybe<Array<Student_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_District_Order_By>>;
  where?: Maybe<Student_District_Bool_Exp>;
};


export type Query_RootStudent_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_District_Order_By>>;
  where?: Maybe<Student_District_Bool_Exp>;
};


export type Query_RootStudent_GoalArgs = {
  distinct_on?: Maybe<Array<Student_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Goal_Order_By>>;
  where?: Maybe<Student_Goal_Bool_Exp>;
};


export type Query_RootStudent_Goal_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Goal_Order_By>>;
  where?: Maybe<Student_Goal_Bool_Exp>;
};


export type Query_RootStudent_Goal_By_PkArgs = {
  student_goal_id: Scalars['Int'];
};


export type Query_RootStudent_GradeArgs = {
  distinct_on?: Maybe<Array<Student_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Grade_Order_By>>;
  where?: Maybe<Student_Grade_Bool_Exp>;
};


export type Query_RootStudent_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Grade_Order_By>>;
  where?: Maybe<Student_Grade_Bool_Exp>;
};


export type Query_RootStudent_IntegrationArgs = {
  distinct_on?: Maybe<Array<Student_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Integration_Order_By>>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};


export type Query_RootStudent_Integration_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Integration_Order_By>>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};


export type Query_RootStudent_Integration_By_PkArgs = {
  student_integration_id: Scalars['Int'];
};


export type Query_RootStudent_Lesson_Plan_FavoriteArgs = {
  distinct_on?: Maybe<Array<Student_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Student_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Query_RootStudent_Lesson_Plan_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Student_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Query_RootStudent_Lesson_Plan_Favorite_By_PkArgs = {
  student_lesson_plan_favorite_id: Scalars['Int'];
};


export type Query_RootStudent_Productivity_DailyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Daily_Order_By>>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};


export type Query_RootStudent_Productivity_Daily_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Daily_Order_By>>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};


export type Query_RootStudent_Productivity_Daily_By_PkArgs = {
  student_productivity_daily_id: Scalars['Int'];
};


export type Query_RootStudent_Productivity_MonthlyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Monthly_Order_By>>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};


export type Query_RootStudent_Productivity_Monthly_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Monthly_Order_By>>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};


export type Query_RootStudent_Productivity_Monthly_By_PkArgs = {
  student_productivity_monthly_id: Scalars['Int'];
};


export type Query_RootStudent_Productivity_YearlyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Yearly_Order_By>>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};


export type Query_RootStudent_Productivity_Yearly_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Yearly_Order_By>>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};


export type Query_RootStudent_Productivity_Yearly_By_PkArgs = {
  student_productivity_yearly_id: Scalars['Int'];
};


export type Query_RootStudent_SettingArgs = {
  distinct_on?: Maybe<Array<Student_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Setting_Order_By>>;
  where?: Maybe<Student_Setting_Bool_Exp>;
};


export type Query_RootStudent_Setting_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Setting_Order_By>>;
  where?: Maybe<Student_Setting_Bool_Exp>;
};


export type Query_RootStudent_Setting_By_PkArgs = {
  student_setting_id: Scalars['Int'];
};


export type Query_RootStudent_Song_FavoriteArgs = {
  distinct_on?: Maybe<Array<Student_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Song_Favorite_Order_By>>;
  where?: Maybe<Student_Song_Favorite_Bool_Exp>;
};


export type Query_RootStudent_Song_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Song_Favorite_Order_By>>;
  where?: Maybe<Student_Song_Favorite_Bool_Exp>;
};


export type Query_RootStudent_Song_Favorite_By_PkArgs = {
  student_song_favorite_id: Scalars['Int'];
};


export type Query_RootStudent_Tip_FavoriteArgs = {
  distinct_on?: Maybe<Array<Student_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Tip_Favorite_Order_By>>;
  where?: Maybe<Student_Tip_Favorite_Bool_Exp>;
};


export type Query_RootStudent_Tip_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Tip_Favorite_Order_By>>;
  where?: Maybe<Student_Tip_Favorite_Bool_Exp>;
};


export type Query_RootStudent_Tip_Favorite_By_PkArgs = {
  student_tip_favorite_id: Scalars['Int'];
};


export type Query_RootSubmissionArgs = {
  distinct_on?: Maybe<Array<Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Order_By>>;
  where?: Maybe<Submission_Bool_Exp>;
};


export type Query_RootSubmission_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Order_By>>;
  where?: Maybe<Submission_Bool_Exp>;
};


export type Query_RootSubmission_AssetArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


export type Query_RootSubmission_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


export type Query_RootSubmission_Asset_By_PkArgs = {
  submission_asset_id: Scalars['Int'];
};


export type Query_RootSubmission_By_PkArgs = {
  submission_id: Scalars['Int'];
};


export type Query_RootSubmission_StateArgs = {
  distinct_on?: Maybe<Array<Submission_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_State_Order_By>>;
  where?: Maybe<Submission_State_Bool_Exp>;
};


export type Query_RootSubmission_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_State_Order_By>>;
  where?: Maybe<Submission_State_Bool_Exp>;
};


export type Query_RootSubmission_State_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootSubmission_StudentArgs = {
  distinct_on?: Maybe<Array<Submission_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Student_Order_By>>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};


export type Query_RootSubmission_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Student_Order_By>>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};


export type Query_RootSubmission_Student_By_PkArgs = {
  submission_student_id: Scalars['Int'];
};


export type Query_RootSubmission_WurrlyArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_Order_By>>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};


export type Query_RootSubmission_Wurrly_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_Order_By>>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};


export type Query_RootSubmission_Wurrly_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


export type Query_RootSubmission_Wurrly_StateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_State_Order_By>>;
  where?: Maybe<Submission_Wurrly_State_Bool_Exp>;
};


export type Query_RootSubmission_Wurrly_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_State_Order_By>>;
  where?: Maybe<Submission_Wurrly_State_Bool_Exp>;
};


export type Query_RootSubmission_Wurrly_State_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootTeacherArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Query_RootTeacherEmailExistsArgs = {
  filter?: Maybe<ValidateEmailFilter>;
};


export type Query_RootTeacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Query_RootTeacher_By_PkArgs = {
  teacher_id: Scalars['Int'];
};


export type Query_RootTeacher_IntegrationArgs = {
  distinct_on?: Maybe<Array<Teacher_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Integration_Order_By>>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};


export type Query_RootTeacher_Integration_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Integration_Order_By>>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};


export type Query_RootTeacher_Integration_By_PkArgs = {
  teacher_integration_id: Scalars['Int'];
};


export type Query_RootTeacher_Lesson_Plan_FavoriteArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Query_RootTeacher_Lesson_Plan_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Query_RootTeacher_Lesson_Plan_Favorite_By_PkArgs = {
  teacher_lesson_plan_favorite_id: Scalars['Int'];
};


export type Query_RootTeacher_SettingArgs = {
  distinct_on?: Maybe<Array<Teacher_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Setting_Order_By>>;
  where?: Maybe<Teacher_Setting_Bool_Exp>;
};


export type Query_RootTeacher_Setting_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Setting_Order_By>>;
  where?: Maybe<Teacher_Setting_Bool_Exp>;
};


export type Query_RootTeacher_Setting_By_PkArgs = {
  teacher_setting_id: Scalars['Int'];
};


export type Query_RootTeacher_Song_FavoriteArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


export type Query_RootTeacher_Song_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


export type Query_RootTeacher_Song_Favorite_By_PkArgs = {
  teacher_song_favorite_id: Scalars['Int'];
};


export type Query_RootTeacher_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Teacher_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Subscription_Order_By>>;
  where?: Maybe<Teacher_Subscription_Bool_Exp>;
};


export type Query_RootTeacher_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Subscription_Order_By>>;
  where?: Maybe<Teacher_Subscription_Bool_Exp>;
};


export type Query_RootTeacher_Subscription_By_PkArgs = {
  subscription_id: Scalars['Int'];
};


export type Query_RootTeacher_Tip_FavoriteArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


export type Query_RootTeacher_Tip_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


export type Query_RootTeacher_Tip_Favorite_By_PkArgs = {
  teacher_tip_favorite_id: Scalars['Int'];
};


export type Query_RootTeacher_Wurrly_CommentArgs = {
  distinct_on?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Wurrly_Comment_Order_By>>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};


export type Query_RootTeacher_Wurrly_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Wurrly_Comment_Order_By>>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};


export type Query_RootTeacher_Wurrly_Comment_By_PkArgs = {
  teacher_wurrly_comment_id: Scalars['Int'];
};


export type Query_RootTipArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Query_RootTip_AgeArgs = {
  distinct_on?: Maybe<Array<Tip_Age_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Age_Order_By>>;
  where?: Maybe<Tip_Age_Bool_Exp>;
};


export type Query_RootTip_Age_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Age_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Age_Order_By>>;
  where?: Maybe<Tip_Age_Bool_Exp>;
};


export type Query_RootTip_Age_By_PkArgs = {
  tip_age_id: Scalars['Int'];
};


export type Query_RootTip_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Query_RootTip_By_PkArgs = {
  tip_id: Scalars['Int'];
};


export type Query_RootTip_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Catalog_Item_Order_By>>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};


export type Query_RootTip_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Catalog_Item_Order_By>>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};


export type Query_RootTip_Catalog_Item_By_PkArgs = {
  tip_catalog_item_id: Scalars['Int'];
};


export type Query_RootTip_GradeArgs = {
  distinct_on?: Maybe<Array<Tip_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Grade_Order_By>>;
  where?: Maybe<Tip_Grade_Bool_Exp>;
};


export type Query_RootTip_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Grade_Order_By>>;
  where?: Maybe<Tip_Grade_Bool_Exp>;
};


export type Query_RootTip_Grade_By_PkArgs = {
  tip_grade_id: Scalars['Int'];
};


export type Query_RootTip_KeywordArgs = {
  distinct_on?: Maybe<Array<Tip_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Keyword_Order_By>>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};


export type Query_RootTip_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Keyword_Order_By>>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};


export type Query_RootTip_Keyword_By_PkArgs = {
  tip_keyword_id: Scalars['Int'];
};


export type Query_RootTip_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Tip_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Musical_Skill_Bool_Exp>;
};


export type Query_RootTip_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Musical_Skill_Bool_Exp>;
};


export type Query_RootTip_Musical_Skill_By_PkArgs = {
  tip_musical_skill_id: Scalars['Int'];
};


export type Query_RootTip_Non_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Tip_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Non_Musical_Skill_Bool_Exp>;
};


export type Query_RootTip_Non_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Non_Musical_Skill_Bool_Exp>;
};


export type Query_RootTip_Non_Musical_Skill_By_PkArgs = {
  tip_non_musical_skill_id: Scalars['Int'];
};


export type Query_RootTip_PlaylistArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};


export type Query_RootTip_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};


export type Query_RootTip_Playlist_By_PkArgs = {
  tip_playlist_id: Scalars['Int'];
};


export type Query_RootTrackArgs = {
  distinct_on?: Maybe<Array<Track_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Order_By>>;
  where?: Maybe<Track_Bool_Exp>;
};


export type Query_RootTrack_AggregateArgs = {
  distinct_on?: Maybe<Array<Track_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Order_By>>;
  where?: Maybe<Track_Bool_Exp>;
};


export type Query_RootTrack_By_PkArgs = {
  track_id: Scalars['Int'];
};


export type Query_RootTrack_TypeArgs = {
  distinct_on?: Maybe<Array<Track_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Type_Order_By>>;
  where?: Maybe<Track_Type_Bool_Exp>;
};


export type Query_RootTrack_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Track_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Type_Order_By>>;
  where?: Maybe<Track_Type_Bool_Exp>;
};


export type Query_RootTrack_Type_By_PkArgs = {
  track_type_id: Scalars['Int'];
};


export type Query_RootTranspose_IndexArgs = {
  distinct_on?: Maybe<Array<Transpose_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transpose_Index_Order_By>>;
  where?: Maybe<Transpose_Index_Bool_Exp>;
};


export type Query_RootTranspose_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Transpose_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transpose_Index_Order_By>>;
  where?: Maybe<Transpose_Index_Bool_Exp>;
};


export type Query_RootTranspose_Index_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUtils_Avg_LessonsArgs = {
  distinct_on?: Maybe<Array<Utils_Avg_Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Avg_Lessons_Order_By>>;
  where?: Maybe<Utils_Avg_Lessons_Bool_Exp>;
};


export type Query_RootUtils_Avg_Lessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Avg_Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Avg_Lessons_Order_By>>;
  where?: Maybe<Utils_Avg_Lessons_Bool_Exp>;
};


export type Query_RootUtils_Most_Attended_ClassesArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Query_RootUtils_Most_Attended_Classes_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Query_RootUtils_Most_Attended_SubjectsArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Query_RootUtils_Most_Attended_Subjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Query_RootUtils_Search_Last_ClassesArgs = {
  args: Utils_Search_Last_Classes_Args;
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Query_RootUtils_Search_Last_Classes_AggregateArgs = {
  args: Utils_Search_Last_Classes_Args;
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Query_RootUtils_Search_Most_Attended_ClassesArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Query_RootUtils_Search_Most_Attended_Classes_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Query_RootUtils_Search_Most_Attended_SubjectsArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Query_RootUtils_Search_Most_Attended_Subjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Query_RootUtils_Search_New_UsersArgs = {
  args: Utils_Search_New_Users_Args;
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Search_New_Users_AggregateArgs = {
  args: Utils_Search_New_Users_Args;
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Search_Teacher_SubscriptionsArgs = {
  args: Utils_Search_Teacher_Subscriptions_Args;
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Search_Teacher_Subscriptions_AggregateArgs = {
  args: Utils_Search_Teacher_Subscriptions_Args;
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Teacher_Subscriptions_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Teacher_Subscriptions_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Users_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Query_RootUtils_Users_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Query_RootValidatePromotionCodeArgs = {
  filter?: Maybe<ValidatePromotionCodeFilter>;
};


export type Query_RootValidateStudentsInClassArgs = {
  filter: ValidateStudentsInClassFilter;
};


export type Query_RootVisibilityArgs = {
  distinct_on?: Maybe<Array<Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visibility_Order_By>>;
  where?: Maybe<Visibility_Bool_Exp>;
};


export type Query_RootVisibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visibility_Order_By>>;
  where?: Maybe<Visibility_Bool_Exp>;
};


export type Query_RootVisibility_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootWurrlyArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};


export type Query_RootWurrly_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};


export type Query_RootWurrly_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


export type Query_RootWurrly_FilterArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Order_By>>;
  where?: Maybe<Wurrly_Filter_Bool_Exp>;
};


export type Query_RootWurrly_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Order_By>>;
  where?: Maybe<Wurrly_Filter_Bool_Exp>;
};


export type Query_RootWurrly_Filter_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


export type Query_RootWurrly_Filter_StickerArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Sticker_Order_By>>;
  where?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
};


export type Query_RootWurrly_Filter_Sticker_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Sticker_Order_By>>;
  where?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
};


export type Query_RootWurrly_Processing_StatusArgs = {
  distinct_on?: Maybe<Array<Wurrly_Processing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Processing_Status_Order_By>>;
  where?: Maybe<Wurrly_Processing_Status_Bool_Exp>;
};


export type Query_RootWurrly_Processing_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Processing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Processing_Status_Order_By>>;
  where?: Maybe<Wurrly_Processing_Status_Bool_Exp>;
};


export type Query_RootWurrly_Processing_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootWurrly_ReactionArgs = {
  distinct_on?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Reaction_Order_By>>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};


export type Query_RootWurrly_Reaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Reaction_Order_By>>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};


export type Query_RootWurrly_Reaction_By_PkArgs = {
  wurrly_reaction_id: Scalars['Int'];
};


export type Query_RootWurrly_RoleArgs = {
  distinct_on?: Maybe<Array<Wurrly_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Role_Order_By>>;
  where?: Maybe<Wurrly_Role_Bool_Exp>;
};


export type Query_RootWurrly_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Role_Order_By>>;
  where?: Maybe<Wurrly_Role_Bool_Exp>;
};


export type Query_RootWurrly_Role_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootWurrly_TypeArgs = {
  distinct_on?: Maybe<Array<Wurrly_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Type_Order_By>>;
  where?: Maybe<Wurrly_Type_Bool_Exp>;
};


export type Query_RootWurrly_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Type_Order_By>>;
  where?: Maybe<Wurrly_Type_Bool_Exp>;
};


export type Query_RootWurrly_Type_By_PkArgs = {
  name: Scalars['String'];
};

/** columns and relationships of "reaction" */
export type Reaction = {
  __typename?: 'reaction';
  created_at: Scalars['timestamptz'];
  icon: Scalars['String'];
  name: Scalars['String'];
  reaction_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "reaction" */
export type Reaction_Aggregate = {
  __typename?: 'reaction_aggregate';
  aggregate?: Maybe<Reaction_Aggregate_Fields>;
  nodes: Array<Reaction>;
};

/** aggregate fields of "reaction" */
export type Reaction_Aggregate_Fields = {
  __typename?: 'reaction_aggregate_fields';
  avg?: Maybe<Reaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reaction_Max_Fields>;
  min?: Maybe<Reaction_Min_Fields>;
  stddev?: Maybe<Reaction_Stddev_Fields>;
  stddev_pop?: Maybe<Reaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reaction_Stddev_Samp_Fields>;
  sum?: Maybe<Reaction_Sum_Fields>;
  var_pop?: Maybe<Reaction_Var_Pop_Fields>;
  var_samp?: Maybe<Reaction_Var_Samp_Fields>;
  variance?: Maybe<Reaction_Variance_Fields>;
};


/** aggregate fields of "reaction" */
export type Reaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Reaction_Avg_Fields = {
  __typename?: 'reaction_avg_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "reaction". All fields are combined with a logical 'AND'. */
export type Reaction_Bool_Exp = {
  _and?: Maybe<Array<Reaction_Bool_Exp>>;
  _not?: Maybe<Reaction_Bool_Exp>;
  _or?: Maybe<Array<Reaction_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  reaction_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reaction" */
export enum Reaction_Constraint {
  /** unique or primary key constraint */
  ReactionPkey = 'reaction_pkey'
}

/** input type for incrementing numeric columns in table "reaction" */
export type Reaction_Inc_Input = {
  reaction_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "reaction" */
export type Reaction_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reaction_Max_Fields = {
  __typename?: 'reaction_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Reaction_Min_Fields = {
  __typename?: 'reaction_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "reaction" */
export type Reaction_Mutation_Response = {
  __typename?: 'reaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reaction>;
};

/** on_conflict condition type for table "reaction" */
export type Reaction_On_Conflict = {
  constraint: Reaction_Constraint;
  update_columns?: Array<Reaction_Update_Column>;
  where?: Maybe<Reaction_Bool_Exp>;
};

/** Ordering options when selecting data from "reaction". */
export type Reaction_Order_By = {
  created_at?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reaction_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: reaction */
export type Reaction_Pk_Columns_Input = {
  reaction_id: Scalars['Int'];
};

/** select columns of table "reaction" */
export enum Reaction_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Name = 'name',
  /** column name */
  ReactionId = 'reaction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reaction" */
export type Reaction_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  icon?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reaction_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Reaction_Stddev_Fields = {
  __typename?: 'reaction_stddev_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Reaction_Stddev_Pop_Fields = {
  __typename?: 'reaction_stddev_pop_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Reaction_Stddev_Samp_Fields = {
  __typename?: 'reaction_stddev_samp_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Reaction_Sum_Fields = {
  __typename?: 'reaction_sum_fields';
  reaction_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "reaction" */
export enum Reaction_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Icon = 'icon',
  /** column name */
  Name = 'name',
  /** column name */
  ReactionId = 'reaction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Reaction_Var_Pop_Fields = {
  __typename?: 'reaction_var_pop_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Reaction_Var_Samp_Fields = {
  __typename?: 'reaction_var_samp_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Reaction_Variance_Fields = {
  __typename?: 'reaction_variance_fields';
  reaction_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "reset_password" */
export type Reset_Password = {
  __typename?: 'reset_password';
  admin_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  reset_password_id: Scalars['Int'];
  /** An object relationship */
  student?: Maybe<Student>;
  student_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  teacher_id?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  used: Scalars['Boolean'];
};

/** aggregated selection of "reset_password" */
export type Reset_Password_Aggregate = {
  __typename?: 'reset_password_aggregate';
  aggregate?: Maybe<Reset_Password_Aggregate_Fields>;
  nodes: Array<Reset_Password>;
};

/** aggregate fields of "reset_password" */
export type Reset_Password_Aggregate_Fields = {
  __typename?: 'reset_password_aggregate_fields';
  avg?: Maybe<Reset_Password_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reset_Password_Max_Fields>;
  min?: Maybe<Reset_Password_Min_Fields>;
  stddev?: Maybe<Reset_Password_Stddev_Fields>;
  stddev_pop?: Maybe<Reset_Password_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reset_Password_Stddev_Samp_Fields>;
  sum?: Maybe<Reset_Password_Sum_Fields>;
  var_pop?: Maybe<Reset_Password_Var_Pop_Fields>;
  var_samp?: Maybe<Reset_Password_Var_Samp_Fields>;
  variance?: Maybe<Reset_Password_Variance_Fields>;
};


/** aggregate fields of "reset_password" */
export type Reset_Password_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reset_Password_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Reset_Password_Avg_Fields = {
  __typename?: 'reset_password_avg_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "reset_password". All fields are combined with a logical 'AND'. */
export type Reset_Password_Bool_Exp = {
  _and?: Maybe<Array<Reset_Password_Bool_Exp>>;
  _not?: Maybe<Reset_Password_Bool_Exp>;
  _or?: Maybe<Array<Reset_Password_Bool_Exp>>;
  admin_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  reset_password_id?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  used?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "reset_password" */
export enum Reset_Password_Constraint {
  /** unique or primary key constraint */
  ResetPasswordPkey = 'reset_password_pkey',
  /** unique or primary key constraint */
  ResetPasswordTokenKey = 'reset_password_token_key'
}

/** input type for incrementing numeric columns in table "reset_password" */
export type Reset_Password_Inc_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  reset_password_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "reset_password" */
export type Reset_Password_Insert_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  reset_password_id?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Reset_Password_Max_Fields = {
  __typename?: 'reset_password_max_fields';
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  reset_password_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Reset_Password_Min_Fields = {
  __typename?: 'reset_password_min_fields';
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  reset_password_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "reset_password" */
export type Reset_Password_Mutation_Response = {
  __typename?: 'reset_password_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reset_Password>;
};

/** on_conflict condition type for table "reset_password" */
export type Reset_Password_On_Conflict = {
  constraint: Reset_Password_Constraint;
  update_columns?: Array<Reset_Password_Update_Column>;
  where?: Maybe<Reset_Password_Bool_Exp>;
};

/** Ordering options when selecting data from "reset_password". */
export type Reset_Password_Order_By = {
  admin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  reset_password_id?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used?: Maybe<Order_By>;
};

/** primary key columns input for table: reset_password */
export type Reset_Password_Pk_Columns_Input = {
  reset_password_id: Scalars['Int'];
};

/** select columns of table "reset_password" */
export enum Reset_Password_Select_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ResetPasswordId = 'reset_password_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "reset_password" */
export type Reset_Password_Set_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  reset_password_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Reset_Password_Stddev_Fields = {
  __typename?: 'reset_password_stddev_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Reset_Password_Stddev_Pop_Fields = {
  __typename?: 'reset_password_stddev_pop_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Reset_Password_Stddev_Samp_Fields = {
  __typename?: 'reset_password_stddev_samp_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Reset_Password_Sum_Fields = {
  __typename?: 'reset_password_sum_fields';
  admin_id?: Maybe<Scalars['Int']>;
  reset_password_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "reset_password" */
export enum Reset_Password_Update_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ResetPasswordId = 'reset_password_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Used = 'used'
}

/** aggregate var_pop on columns */
export type Reset_Password_Var_Pop_Fields = {
  __typename?: 'reset_password_var_pop_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Reset_Password_Var_Samp_Fields = {
  __typename?: 'reset_password_var_samp_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Reset_Password_Variance_Fields = {
  __typename?: 'reset_password_variance_fields';
  admin_id?: Maybe<Scalars['Float']>;
  reset_password_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "role_name" */
export type Role_Name = {
  __typename?: 'role_name';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "role_name" */
export type Role_Name_Aggregate = {
  __typename?: 'role_name_aggregate';
  aggregate?: Maybe<Role_Name_Aggregate_Fields>;
  nodes: Array<Role_Name>;
};

/** aggregate fields of "role_name" */
export type Role_Name_Aggregate_Fields = {
  __typename?: 'role_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Name_Max_Fields>;
  min?: Maybe<Role_Name_Min_Fields>;
};


/** aggregate fields of "role_name" */
export type Role_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role_name". All fields are combined with a logical 'AND'. */
export type Role_Name_Bool_Exp = {
  _and?: Maybe<Array<Role_Name_Bool_Exp>>;
  _not?: Maybe<Role_Name_Bool_Exp>;
  _or?: Maybe<Array<Role_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_name" */
export enum Role_Name_Constraint {
  /** unique or primary key constraint */
  TeacherRoleNamePkey = 'teacher_role_name_pkey'
}

export enum Role_Name_Enum {
  /** Camp Teacher */
  Camp = 'camp',
  /** Private Teacher */
  Private = 'private',
  /** School Teacher */
  School = 'school'
}

/** Boolean expression to compare columns of type "role_name_enum". All fields are combined with logical 'AND'. */
export type Role_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Role_Name_Enum>;
  _in?: Maybe<Array<Role_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Role_Name_Enum>;
  _nin?: Maybe<Array<Role_Name_Enum>>;
};

/** input type for inserting data into table "role_name" */
export type Role_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Role_Name_Max_Fields = {
  __typename?: 'role_name_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Name_Min_Fields = {
  __typename?: 'role_name_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role_name" */
export type Role_Name_Mutation_Response = {
  __typename?: 'role_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Name>;
};

/** on_conflict condition type for table "role_name" */
export type Role_Name_On_Conflict = {
  constraint: Role_Name_Constraint;
  update_columns?: Array<Role_Name_Update_Column>;
  where?: Maybe<Role_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "role_name". */
export type Role_Name_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: role_name */
export type Role_Name_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "role_name" */
export enum Role_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "role_name" */
export type Role_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "role_name" */
export enum Role_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "royalty_log" */
export type Royalty_Log = {
  __typename?: 'royalty_log';
  end_date: Scalars['timestamptz'];
  play_time: Scalars['Float'];
  royalty_log_id: Scalars['Int'];
  screen_type?: Maybe<Screen_Type_Enum>;
  song_id: Scalars['Int'];
  start_date: Scalars['timestamptz'];
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id: Scalars['Int'];
};

/** aggregated selection of "royalty_log" */
export type Royalty_Log_Aggregate = {
  __typename?: 'royalty_log_aggregate';
  aggregate?: Maybe<Royalty_Log_Aggregate_Fields>;
  nodes: Array<Royalty_Log>;
};

/** aggregate fields of "royalty_log" */
export type Royalty_Log_Aggregate_Fields = {
  __typename?: 'royalty_log_aggregate_fields';
  avg?: Maybe<Royalty_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Royalty_Log_Max_Fields>;
  min?: Maybe<Royalty_Log_Min_Fields>;
  stddev?: Maybe<Royalty_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Royalty_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Royalty_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Royalty_Log_Sum_Fields>;
  var_pop?: Maybe<Royalty_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Royalty_Log_Var_Samp_Fields>;
  variance?: Maybe<Royalty_Log_Variance_Fields>;
};


/** aggregate fields of "royalty_log" */
export type Royalty_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Royalty_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "royalty_log" */
export type Royalty_Log_Aggregate_Order_By = {
  avg?: Maybe<Royalty_Log_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Royalty_Log_Max_Order_By>;
  min?: Maybe<Royalty_Log_Min_Order_By>;
  stddev?: Maybe<Royalty_Log_Stddev_Order_By>;
  stddev_pop?: Maybe<Royalty_Log_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Royalty_Log_Stddev_Samp_Order_By>;
  sum?: Maybe<Royalty_Log_Sum_Order_By>;
  var_pop?: Maybe<Royalty_Log_Var_Pop_Order_By>;
  var_samp?: Maybe<Royalty_Log_Var_Samp_Order_By>;
  variance?: Maybe<Royalty_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "royalty_log" */
export type Royalty_Log_Arr_Rel_Insert_Input = {
  data: Array<Royalty_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Royalty_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Royalty_Log_Avg_Fields = {
  __typename?: 'royalty_log_avg_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "royalty_log" */
export type Royalty_Log_Avg_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "royalty_log". All fields are combined with a logical 'AND'. */
export type Royalty_Log_Bool_Exp = {
  _and?: Maybe<Array<Royalty_Log_Bool_Exp>>;
  _not?: Maybe<Royalty_Log_Bool_Exp>;
  _or?: Maybe<Array<Royalty_Log_Bool_Exp>>;
  end_date?: Maybe<Timestamptz_Comparison_Exp>;
  play_time?: Maybe<Float_Comparison_Exp>;
  royalty_log_id?: Maybe<Int_Comparison_Exp>;
  screen_type?: Maybe<Screen_Type_Enum_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  start_date?: Maybe<Timestamptz_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  track_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "royalty_log" */
export enum Royalty_Log_Constraint {
  /** unique or primary key constraint */
  RoyaltyLogPkey = 'royalty_log_pkey'
}

/** input type for incrementing numeric columns in table "royalty_log" */
export type Royalty_Log_Inc_Input = {
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "royalty_log" */
export type Royalty_Log_Insert_Input = {
  end_date?: Maybe<Scalars['timestamptz']>;
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Int']>;
  screen_type?: Maybe<Screen_Type_Enum>;
  song_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Royalty_Log_Max_Fields = {
  __typename?: 'royalty_log_max_fields';
  end_date?: Maybe<Scalars['timestamptz']>;
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "royalty_log" */
export type Royalty_Log_Max_Order_By = {
  end_date?: Maybe<Order_By>;
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Royalty_Log_Min_Fields = {
  __typename?: 'royalty_log_min_fields';
  end_date?: Maybe<Scalars['timestamptz']>;
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "royalty_log" */
export type Royalty_Log_Min_Order_By = {
  end_date?: Maybe<Order_By>;
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "royalty_log" */
export type Royalty_Log_Mutation_Response = {
  __typename?: 'royalty_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Royalty_Log>;
};

/** on_conflict condition type for table "royalty_log" */
export type Royalty_Log_On_Conflict = {
  constraint: Royalty_Log_Constraint;
  update_columns?: Array<Royalty_Log_Update_Column>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "royalty_log". */
export type Royalty_Log_Order_By = {
  end_date?: Maybe<Order_By>;
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  screen_type?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** primary key columns input for table: royalty_log */
export type Royalty_Log_Pk_Columns_Input = {
  royalty_log_id: Scalars['Int'];
};

/** select columns of table "royalty_log" */
export enum Royalty_Log_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  PlayTime = 'play_time',
  /** column name */
  RoyaltyLogId = 'royalty_log_id',
  /** column name */
  ScreenType = 'screen_type',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TrackId = 'track_id'
}

/** input type for updating data in table "royalty_log" */
export type Royalty_Log_Set_Input = {
  end_date?: Maybe<Scalars['timestamptz']>;
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Int']>;
  screen_type?: Maybe<Screen_Type_Enum>;
  song_id?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Royalty_Log_Stddev_Fields = {
  __typename?: 'royalty_log_stddev_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "royalty_log" */
export type Royalty_Log_Stddev_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Royalty_Log_Stddev_Pop_Fields = {
  __typename?: 'royalty_log_stddev_pop_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "royalty_log" */
export type Royalty_Log_Stddev_Pop_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Royalty_Log_Stddev_Samp_Fields = {
  __typename?: 'royalty_log_stddev_samp_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "royalty_log" */
export type Royalty_Log_Stddev_Samp_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Royalty_Log_Sum_Fields = {
  __typename?: 'royalty_log_sum_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "royalty_log" */
export type Royalty_Log_Sum_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** update columns of table "royalty_log" */
export enum Royalty_Log_Update_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  PlayTime = 'play_time',
  /** column name */
  RoyaltyLogId = 'royalty_log_id',
  /** column name */
  ScreenType = 'screen_type',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TrackId = 'track_id'
}

/** aggregate var_pop on columns */
export type Royalty_Log_Var_Pop_Fields = {
  __typename?: 'royalty_log_var_pop_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "royalty_log" */
export type Royalty_Log_Var_Pop_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Royalty_Log_Var_Samp_Fields = {
  __typename?: 'royalty_log_var_samp_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "royalty_log" */
export type Royalty_Log_Var_Samp_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Royalty_Log_Variance_Fields = {
  __typename?: 'royalty_log_variance_fields';
  play_time?: Maybe<Scalars['Float']>;
  royalty_log_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "royalty_log" */
export type Royalty_Log_Variance_Order_By = {
  play_time?: Maybe<Order_By>;
  royalty_log_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
};

/** columns and relationships of "scale" */
export type Scale = {
  __typename?: 'scale';
  created_at?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  scale_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "scale" */
export type Scale_Aggregate = {
  __typename?: 'scale_aggregate';
  aggregate?: Maybe<Scale_Aggregate_Fields>;
  nodes: Array<Scale>;
};

/** aggregate fields of "scale" */
export type Scale_Aggregate_Fields = {
  __typename?: 'scale_aggregate_fields';
  avg?: Maybe<Scale_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Scale_Max_Fields>;
  min?: Maybe<Scale_Min_Fields>;
  stddev?: Maybe<Scale_Stddev_Fields>;
  stddev_pop?: Maybe<Scale_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scale_Stddev_Samp_Fields>;
  sum?: Maybe<Scale_Sum_Fields>;
  var_pop?: Maybe<Scale_Var_Pop_Fields>;
  var_samp?: Maybe<Scale_Var_Samp_Fields>;
  variance?: Maybe<Scale_Variance_Fields>;
};


/** aggregate fields of "scale" */
export type Scale_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Scale_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Scale_Avg_Fields = {
  __typename?: 'scale_avg_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "scale". All fields are combined with a logical 'AND'. */
export type Scale_Bool_Exp = {
  _and?: Maybe<Array<Scale_Bool_Exp>>;
  _not?: Maybe<Scale_Bool_Exp>;
  _or?: Maybe<Array<Scale_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  scale_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "scale" */
export enum Scale_Constraint {
  /** unique or primary key constraint */
  ScalePkey = 'scale_pkey'
}

/** input type for incrementing numeric columns in table "scale" */
export type Scale_Inc_Input = {
  scale_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "scale" */
export type Scale_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Scale_Max_Fields = {
  __typename?: 'scale_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Scale_Min_Fields = {
  __typename?: 'scale_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "scale" */
export type Scale_Mutation_Response = {
  __typename?: 'scale_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Scale>;
};

/** input type for inserting object relation for remote table "scale" */
export type Scale_Obj_Rel_Insert_Input = {
  data: Scale_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Scale_On_Conflict>;
};

/** on_conflict condition type for table "scale" */
export type Scale_On_Conflict = {
  constraint: Scale_Constraint;
  update_columns?: Array<Scale_Update_Column>;
  where?: Maybe<Scale_Bool_Exp>;
};

/** Ordering options when selecting data from "scale". */
export type Scale_Order_By = {
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  scale_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: scale */
export type Scale_Pk_Columns_Input = {
  scale_id: Scalars['Int'];
};

/** select columns of table "scale" */
export enum Scale_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  ScaleId = 'scale_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "scale" */
export type Scale_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Scale_Stddev_Fields = {
  __typename?: 'scale_stddev_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Scale_Stddev_Pop_Fields = {
  __typename?: 'scale_stddev_pop_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Scale_Stddev_Samp_Fields = {
  __typename?: 'scale_stddev_samp_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Scale_Sum_Fields = {
  __typename?: 'scale_sum_fields';
  scale_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "scale" */
export enum Scale_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  ScaleId = 'scale_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Scale_Var_Pop_Fields = {
  __typename?: 'scale_var_pop_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Scale_Var_Samp_Fields = {
  __typename?: 'scale_var_samp_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Scale_Variance_Fields = {
  __typename?: 'scale_variance_fields';
  scale_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "school" */
export type School = {
  __typename?: 'school';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  classes: Array<Class>;
  /** An aggregate relationship */
  classes_aggregate: Class_Aggregate;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  district?: Maybe<District>;
  district_id?: Maybe<Scalars['Int']>;
  is_enterprise: Scalars['Boolean'];
  /** An array relationship */
  lockable_content_schools: Array<Lockable_Content_School>;
  /** An aggregate relationship */
  lockable_content_schools_aggregate: Lockable_Content_School_Aggregate;
  mdr_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nces_id?: Maybe<Scalars['String']>;
  school_id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  /** An array relationship */
  students: Array<Student>;
  /** An aggregate relationship */
  students_aggregate: Student_Aggregate;
  /** An array relationship */
  teachers: Array<Teacher>;
  /** An aggregate relationship */
  teachers_aggregate: Teacher_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "school" */
export type SchoolClassesArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolClasses_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolLockable_Content_SchoolsArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolLockable_Content_Schools_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolStudentsArgs = {
  distinct_on?: Maybe<Array<Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Order_By>>;
  where?: Maybe<Student_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolStudents_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Order_By>>;
  where?: Maybe<Student_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolTeachersArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


/** columns and relationships of "school" */
export type SchoolTeachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};

/** columns and relationships of "school_admin" */
export type School_Admin = {
  __typename?: 'school_admin';
  clever_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  department: Scalars['String'];
  email: Scalars['String'];
  name_first: Scalars['String'];
  name_last: Scalars['String'];
  name_middle: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['numeric'];
  school_admin_id: Scalars['Int'];
  school_id: Scalars['Int'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "school_admin" */
export type School_Admin_Aggregate = {
  __typename?: 'school_admin_aggregate';
  aggregate?: Maybe<School_Admin_Aggregate_Fields>;
  nodes: Array<School_Admin>;
};

/** aggregate fields of "school_admin" */
export type School_Admin_Aggregate_Fields = {
  __typename?: 'school_admin_aggregate_fields';
  avg?: Maybe<School_Admin_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<School_Admin_Max_Fields>;
  min?: Maybe<School_Admin_Min_Fields>;
  stddev?: Maybe<School_Admin_Stddev_Fields>;
  stddev_pop?: Maybe<School_Admin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<School_Admin_Stddev_Samp_Fields>;
  sum?: Maybe<School_Admin_Sum_Fields>;
  var_pop?: Maybe<School_Admin_Var_Pop_Fields>;
  var_samp?: Maybe<School_Admin_Var_Samp_Fields>;
  variance?: Maybe<School_Admin_Variance_Fields>;
};


/** aggregate fields of "school_admin" */
export type School_Admin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<School_Admin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type School_Admin_Avg_Fields = {
  __typename?: 'school_admin_avg_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "school_admin". All fields are combined with a logical 'AND'. */
export type School_Admin_Bool_Exp = {
  _and?: Maybe<Array<School_Admin_Bool_Exp>>;
  _not?: Maybe<School_Admin_Bool_Exp>;
  _or?: Maybe<Array<School_Admin_Bool_Exp>>;
  clever_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  department?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  name_first?: Maybe<String_Comparison_Exp>;
  name_last?: Maybe<String_Comparison_Exp>;
  name_middle?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<Numeric_Comparison_Exp>;
  school_admin_id?: Maybe<Int_Comparison_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "school_admin" */
export enum School_Admin_Constraint {
  /** unique or primary key constraint */
  SchoolAdminCleverIdKey = 'school_admin_clever_id_key',
  /** unique or primary key constraint */
  SchoolAdminPkey = 'school_admin_pkey',
  /** unique or primary key constraint */
  SchoolAdminSchoolIdKey = 'school_admin_school_id_key'
}

/** input type for incrementing numeric columns in table "school_admin" */
export type School_Admin_Inc_Input = {
  phone?: Maybe<Scalars['numeric']>;
  school_admin_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "school_admin" */
export type School_Admin_Insert_Input = {
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  school_admin_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type School_Admin_Max_Fields = {
  __typename?: 'school_admin_max_fields';
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  school_admin_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type School_Admin_Min_Fields = {
  __typename?: 'school_admin_min_fields';
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  school_admin_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "school_admin" */
export type School_Admin_Mutation_Response = {
  __typename?: 'school_admin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<School_Admin>;
};

/** on_conflict condition type for table "school_admin" */
export type School_Admin_On_Conflict = {
  constraint: School_Admin_Constraint;
  update_columns?: Array<School_Admin_Update_Column>;
  where?: Maybe<School_Admin_Bool_Exp>;
};

/** Ordering options when selecting data from "school_admin". */
export type School_Admin_Order_By = {
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  department?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  school_admin_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: school_admin */
export type School_Admin_Pk_Columns_Input = {
  school_admin_id: Scalars['Int'];
};

/** select columns of table "school_admin" */
export enum School_Admin_Select_Column {
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Department = 'department',
  /** column name */
  Email = 'email',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  SchoolAdminId = 'school_admin_id',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "school_admin" */
export type School_Admin_Set_Input = {
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  school_admin_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type School_Admin_Stddev_Fields = {
  __typename?: 'school_admin_stddev_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type School_Admin_Stddev_Pop_Fields = {
  __typename?: 'school_admin_stddev_pop_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type School_Admin_Stddev_Samp_Fields = {
  __typename?: 'school_admin_stddev_samp_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type School_Admin_Sum_Fields = {
  __typename?: 'school_admin_sum_fields';
  phone?: Maybe<Scalars['numeric']>;
  school_admin_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "school_admin" */
export enum School_Admin_Update_Column {
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Department = 'department',
  /** column name */
  Email = 'email',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  SchoolAdminId = 'school_admin_id',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type School_Admin_Var_Pop_Fields = {
  __typename?: 'school_admin_var_pop_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type School_Admin_Var_Samp_Fields = {
  __typename?: 'school_admin_var_samp_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type School_Admin_Variance_Fields = {
  __typename?: 'school_admin_variance_fields';
  phone?: Maybe<Scalars['Float']>;
  school_admin_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "school" */
export type School_Aggregate = {
  __typename?: 'school_aggregate';
  aggregate?: Maybe<School_Aggregate_Fields>;
  nodes: Array<School>;
};

/** aggregate fields of "school" */
export type School_Aggregate_Fields = {
  __typename?: 'school_aggregate_fields';
  avg?: Maybe<School_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<School_Max_Fields>;
  min?: Maybe<School_Min_Fields>;
  stddev?: Maybe<School_Stddev_Fields>;
  stddev_pop?: Maybe<School_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<School_Stddev_Samp_Fields>;
  sum?: Maybe<School_Sum_Fields>;
  var_pop?: Maybe<School_Var_Pop_Fields>;
  var_samp?: Maybe<School_Var_Samp_Fields>;
  variance?: Maybe<School_Variance_Fields>;
};


/** aggregate fields of "school" */
export type School_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<School_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "school" */
export type School_Aggregate_Order_By = {
  avg?: Maybe<School_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<School_Max_Order_By>;
  min?: Maybe<School_Min_Order_By>;
  stddev?: Maybe<School_Stddev_Order_By>;
  stddev_pop?: Maybe<School_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<School_Stddev_Samp_Order_By>;
  sum?: Maybe<School_Sum_Order_By>;
  var_pop?: Maybe<School_Var_Pop_Order_By>;
  var_samp?: Maybe<School_Var_Samp_Order_By>;
  variance?: Maybe<School_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "school" */
export type School_Arr_Rel_Insert_Input = {
  data: Array<School_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<School_On_Conflict>;
};

/** aggregate avg on columns */
export type School_Avg_Fields = {
  __typename?: 'school_avg_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "school" */
export type School_Avg_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "school". All fields are combined with a logical 'AND'. */
export type School_Bool_Exp = {
  _and?: Maybe<Array<School_Bool_Exp>>;
  _not?: Maybe<School_Bool_Exp>;
  _or?: Maybe<Array<School_Bool_Exp>>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  classes?: Maybe<Class_Bool_Exp>;
  clever_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  district?: Maybe<District_Bool_Exp>;
  district_id?: Maybe<Int_Comparison_Exp>;
  is_enterprise?: Maybe<Boolean_Comparison_Exp>;
  lockable_content_schools?: Maybe<Lockable_Content_School_Bool_Exp>;
  mdr_id?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  nces_id?: Maybe<String_Comparison_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  students?: Maybe<Student_Bool_Exp>;
  teachers?: Maybe<Teacher_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "school" */
export enum School_Constraint {
  /** unique or primary key constraint */
  SchoolCleverIdKey = 'school_clever_id_key',
  /** unique or primary key constraint */
  SchoolMdrIdKey = 'school_mdr_id_key',
  /** unique or primary key constraint */
  SchoolNcesIdKey = 'school_nces_id_key',
  /** unique or primary key constraint */
  SchoolPkey = 'school_pkey'
}

/** input type for incrementing numeric columns in table "school" */
export type School_Inc_Input = {
  district_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "school" */
export type School_Insert_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  classes?: Maybe<Class_Arr_Rel_Insert_Input>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<District_Obj_Rel_Insert_Input>;
  district_id?: Maybe<Scalars['Int']>;
  is_enterprise?: Maybe<Scalars['Boolean']>;
  lockable_content_schools?: Maybe<Lockable_Content_School_Arr_Rel_Insert_Input>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  students?: Maybe<Student_Arr_Rel_Insert_Input>;
  teachers?: Maybe<Teacher_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type School_Max_Fields = {
  __typename?: 'school_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "school" */
export type School_Max_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  mdr_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nces_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type School_Min_Fields = {
  __typename?: 'school_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "school" */
export type School_Min_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district_id?: Maybe<Order_By>;
  mdr_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nces_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "school" */
export type School_Mutation_Response = {
  __typename?: 'school_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<School>;
};

/** input type for inserting object relation for remote table "school" */
export type School_Obj_Rel_Insert_Input = {
  data: School_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<School_On_Conflict>;
};

/** on_conflict condition type for table "school" */
export type School_On_Conflict = {
  constraint: School_Constraint;
  update_columns?: Array<School_Update_Column>;
  where?: Maybe<School_Bool_Exp>;
};

/** Ordering options when selecting data from "school". */
export type School_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  classes_aggregate?: Maybe<Class_Aggregate_Order_By>;
  clever_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district?: Maybe<District_Order_By>;
  district_id?: Maybe<Order_By>;
  is_enterprise?: Maybe<Order_By>;
  lockable_content_schools_aggregate?: Maybe<Lockable_Content_School_Aggregate_Order_By>;
  mdr_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nces_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  students_aggregate?: Maybe<Student_Aggregate_Order_By>;
  teachers_aggregate?: Maybe<Teacher_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: school */
export type School_Pk_Columns_Input = {
  school_id: Scalars['Int'];
};

/** select columns of table "school" */
export enum School_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  IsEnterprise = 'is_enterprise',
  /** column name */
  MdrId = 'mdr_id',
  /** column name */
  Name = 'name',
  /** column name */
  NcesId = 'nces_id',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "school" */
export type School_Set_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clever_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district_id?: Maybe<Scalars['Int']>;
  is_enterprise?: Maybe<Scalars['Boolean']>;
  mdr_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nces_id?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type School_Stddev_Fields = {
  __typename?: 'school_stddev_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "school" */
export type School_Stddev_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type School_Stddev_Pop_Fields = {
  __typename?: 'school_stddev_pop_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "school" */
export type School_Stddev_Pop_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type School_Stddev_Samp_Fields = {
  __typename?: 'school_stddev_samp_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "school" */
export type School_Stddev_Samp_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type School_Sum_Fields = {
  __typename?: 'school_sum_fields';
  district_id?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "school" */
export type School_Sum_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** columns and relationships of "school_teacher" */
export type School_Teacher = {
  __typename?: 'school_teacher';
  created_at?: Maybe<Scalars['timestamptz']>;
  school_id: Scalars['Int'];
  school_teacher_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "school_teacher" */
export type School_Teacher_Aggregate = {
  __typename?: 'school_teacher_aggregate';
  aggregate?: Maybe<School_Teacher_Aggregate_Fields>;
  nodes: Array<School_Teacher>;
};

/** aggregate fields of "school_teacher" */
export type School_Teacher_Aggregate_Fields = {
  __typename?: 'school_teacher_aggregate_fields';
  avg?: Maybe<School_Teacher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<School_Teacher_Max_Fields>;
  min?: Maybe<School_Teacher_Min_Fields>;
  stddev?: Maybe<School_Teacher_Stddev_Fields>;
  stddev_pop?: Maybe<School_Teacher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<School_Teacher_Stddev_Samp_Fields>;
  sum?: Maybe<School_Teacher_Sum_Fields>;
  var_pop?: Maybe<School_Teacher_Var_Pop_Fields>;
  var_samp?: Maybe<School_Teacher_Var_Samp_Fields>;
  variance?: Maybe<School_Teacher_Variance_Fields>;
};


/** aggregate fields of "school_teacher" */
export type School_Teacher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<School_Teacher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type School_Teacher_Avg_Fields = {
  __typename?: 'school_teacher_avg_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "school_teacher". All fields are combined with a logical 'AND'. */
export type School_Teacher_Bool_Exp = {
  _and?: Maybe<Array<School_Teacher_Bool_Exp>>;
  _not?: Maybe<School_Teacher_Bool_Exp>;
  _or?: Maybe<Array<School_Teacher_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
  school_teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "school_teacher" */
export enum School_Teacher_Constraint {
  /** unique or primary key constraint */
  SchoolTeacherPkey = 'school_teacher_pkey',
  /** unique or primary key constraint */
  SchoolTeacherSchoolIdKey = 'school_teacher_school_id_key',
  /** unique or primary key constraint */
  SchoolTeacherTeacherIdKey = 'school_teacher_teacher_id_key'
}

/** input type for incrementing numeric columns in table "school_teacher" */
export type School_Teacher_Inc_Input = {
  school_id?: Maybe<Scalars['Int']>;
  school_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "school_teacher" */
export type School_Teacher_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  school_id?: Maybe<Scalars['Int']>;
  school_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type School_Teacher_Max_Fields = {
  __typename?: 'school_teacher_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  school_id?: Maybe<Scalars['Int']>;
  school_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type School_Teacher_Min_Fields = {
  __typename?: 'school_teacher_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  school_id?: Maybe<Scalars['Int']>;
  school_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "school_teacher" */
export type School_Teacher_Mutation_Response = {
  __typename?: 'school_teacher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<School_Teacher>;
};

/** on_conflict condition type for table "school_teacher" */
export type School_Teacher_On_Conflict = {
  constraint: School_Teacher_Constraint;
  update_columns?: Array<School_Teacher_Update_Column>;
  where?: Maybe<School_Teacher_Bool_Exp>;
};

/** Ordering options when selecting data from "school_teacher". */
export type School_Teacher_Order_By = {
  created_at?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  school_teacher_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: school_teacher */
export type School_Teacher_Pk_Columns_Input = {
  school_teacher_id: Scalars['Int'];
};

/** select columns of table "school_teacher" */
export enum School_Teacher_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  SchoolTeacherId = 'school_teacher_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "school_teacher" */
export type School_Teacher_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  school_id?: Maybe<Scalars['Int']>;
  school_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type School_Teacher_Stddev_Fields = {
  __typename?: 'school_teacher_stddev_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type School_Teacher_Stddev_Pop_Fields = {
  __typename?: 'school_teacher_stddev_pop_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type School_Teacher_Stddev_Samp_Fields = {
  __typename?: 'school_teacher_stddev_samp_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type School_Teacher_Sum_Fields = {
  __typename?: 'school_teacher_sum_fields';
  school_id?: Maybe<Scalars['Int']>;
  school_teacher_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "school_teacher" */
export enum School_Teacher_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  SchoolTeacherId = 'school_teacher_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type School_Teacher_Var_Pop_Fields = {
  __typename?: 'school_teacher_var_pop_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type School_Teacher_Var_Samp_Fields = {
  __typename?: 'school_teacher_var_samp_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type School_Teacher_Variance_Fields = {
  __typename?: 'school_teacher_variance_fields';
  school_id?: Maybe<Scalars['Float']>;
  school_teacher_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** update columns of table "school" */
export enum School_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistrictId = 'district_id',
  /** column name */
  IsEnterprise = 'is_enterprise',
  /** column name */
  MdrId = 'mdr_id',
  /** column name */
  Name = 'name',
  /** column name */
  NcesId = 'nces_id',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type School_Var_Pop_Fields = {
  __typename?: 'school_var_pop_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "school" */
export type School_Var_Pop_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type School_Var_Samp_Fields = {
  __typename?: 'school_var_samp_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "school" */
export type School_Var_Samp_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type School_Variance_Fields = {
  __typename?: 'school_variance_fields';
  district_id?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "school" */
export type School_Variance_Order_By = {
  district_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** columns and relationships of "screen_type" */
export type Screen_Type = {
  __typename?: 'screen_type';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "screen_type" */
export type Screen_Type_Aggregate = {
  __typename?: 'screen_type_aggregate';
  aggregate?: Maybe<Screen_Type_Aggregate_Fields>;
  nodes: Array<Screen_Type>;
};

/** aggregate fields of "screen_type" */
export type Screen_Type_Aggregate_Fields = {
  __typename?: 'screen_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Screen_Type_Max_Fields>;
  min?: Maybe<Screen_Type_Min_Fields>;
};


/** aggregate fields of "screen_type" */
export type Screen_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Screen_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "screen_type". All fields are combined with a logical 'AND'. */
export type Screen_Type_Bool_Exp = {
  _and?: Maybe<Array<Screen_Type_Bool_Exp>>;
  _not?: Maybe<Screen_Type_Bool_Exp>;
  _or?: Maybe<Array<Screen_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "screen_type" */
export enum Screen_Type_Constraint {
  /** unique or primary key constraint */
  ScreenTypePkey = 'screen_type_pkey'
}

export enum Screen_Type_Enum {
  /** Assignment Detail */
  AssignmentDetail = 'assignment_detail',
  /** Song Player */
  SongPlayer = 'song_player',
  /** Video Studio - Record */
  VideoStudioRecord = 'video_studio_record',
  /** Video Studio - Review */
  VideoStudioReview = 'video_studio_review',
  /** Video Studio - Setup */
  VideoStudioSetup = 'video_studio_setup',
  /** Wurrly Detail */
  WurrlyDetail = 'wurrly_detail'
}

/** Boolean expression to compare columns of type "screen_type_enum". All fields are combined with logical 'AND'. */
export type Screen_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Screen_Type_Enum>;
  _in?: Maybe<Array<Screen_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Screen_Type_Enum>;
  _nin?: Maybe<Array<Screen_Type_Enum>>;
};

/** input type for inserting data into table "screen_type" */
export type Screen_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Screen_Type_Max_Fields = {
  __typename?: 'screen_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Screen_Type_Min_Fields = {
  __typename?: 'screen_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "screen_type" */
export type Screen_Type_Mutation_Response = {
  __typename?: 'screen_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Screen_Type>;
};

/** on_conflict condition type for table "screen_type" */
export type Screen_Type_On_Conflict = {
  constraint: Screen_Type_Constraint;
  update_columns?: Array<Screen_Type_Update_Column>;
  where?: Maybe<Screen_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "screen_type". */
export type Screen_Type_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: screen_type */
export type Screen_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "screen_type" */
export enum Screen_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "screen_type" */
export type Screen_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "screen_type" */
export enum Screen_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type SendTeacherActivationEmailData = {
  __typename?: 'sendTeacherActivationEmailData';
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "sequence" */
export type Sequence = {
  __typename?: 'sequence';
  active: Scalars['Boolean'];
  /** An array relationship */
  class_sequences: Array<Class_Sequence>;
  /** An aggregate relationship */
  class_sequences_aggregate: Class_Sequence_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  image_path: Scalars['String'];
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  sequence_id: Scalars['Int'];
  /** An array relationship */
  sequence_keywords: Array<Sequence_Keyword>;
  /** An aggregate relationship */
  sequence_keywords_aggregate: Sequence_Keyword_Aggregate;
  /** An array relationship */
  sequence_lesson_plans: Array<Sequence_Lesson_Plan>;
  /** An aggregate relationship */
  sequence_lesson_plans_aggregate: Sequence_Lesson_Plan_Aggregate;
  /** An array relationship */
  sequence_topic_sequences: Array<Sequence_Topic_Sequence>;
  /** An aggregate relationship */
  sequence_topic_sequences_aggregate: Sequence_Topic_Sequence_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "sequence" */
export type SequenceClass_SequencesArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceClass_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_KeywordsArgs = {
  distinct_on?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Keyword_Order_By>>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_Keywords_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Keyword_Order_By>>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_Lesson_PlansArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_Lesson_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_Topic_SequencesArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};


/** columns and relationships of "sequence" */
export type SequenceSequence_Topic_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};

/** aggregated selection of "sequence" */
export type Sequence_Aggregate = {
  __typename?: 'sequence_aggregate';
  aggregate?: Maybe<Sequence_Aggregate_Fields>;
  nodes: Array<Sequence>;
};

/** aggregate fields of "sequence" */
export type Sequence_Aggregate_Fields = {
  __typename?: 'sequence_aggregate_fields';
  avg?: Maybe<Sequence_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Max_Fields>;
  min?: Maybe<Sequence_Min_Fields>;
  stddev?: Maybe<Sequence_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Sum_Fields>;
  var_pop?: Maybe<Sequence_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Variance_Fields>;
};


/** aggregate fields of "sequence" */
export type Sequence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sequence_Avg_Fields = {
  __typename?: 'sequence_avg_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sequence". All fields are combined with a logical 'AND'. */
export type Sequence_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Bool_Exp>>;
  _not?: Maybe<Sequence_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  class_sequences?: Maybe<Class_Sequence_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  sequence_id?: Maybe<Int_Comparison_Exp>;
  sequence_keywords?: Maybe<Sequence_Keyword_Bool_Exp>;
  sequence_lesson_plans?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
  sequence_topic_sequences?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence" */
export enum Sequence_Constraint {
  /** unique or primary key constraint */
  SequenceItemPkey = 'sequence_item_pkey',
  /** unique or primary key constraint */
  SequenceLockableContentIdKey = 'sequence_lockable_content_id_key'
}

/** input type for incrementing numeric columns in table "sequence" */
export type Sequence_Inc_Input = {
  lockable_content_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence" */
export type Sequence_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  class_sequences?: Maybe<Class_Sequence_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keywords?: Maybe<Sequence_Keyword_Arr_Rel_Insert_Input>;
  sequence_lesson_plans?: Maybe<Sequence_Lesson_Plan_Arr_Rel_Insert_Input>;
  sequence_topic_sequences?: Maybe<Sequence_Topic_Sequence_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "sequence_keyword" */
export type Sequence_Keyword = {
  __typename?: 'sequence_keyword';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  keyword: Keyword;
  keyword_id: Scalars['Int'];
  /** An object relationship */
  sequence: Sequence;
  sequence_id: Scalars['Int'];
  sequence_keyword_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sequence_keyword" */
export type Sequence_Keyword_Aggregate = {
  __typename?: 'sequence_keyword_aggregate';
  aggregate?: Maybe<Sequence_Keyword_Aggregate_Fields>;
  nodes: Array<Sequence_Keyword>;
};

/** aggregate fields of "sequence_keyword" */
export type Sequence_Keyword_Aggregate_Fields = {
  __typename?: 'sequence_keyword_aggregate_fields';
  avg?: Maybe<Sequence_Keyword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Keyword_Max_Fields>;
  min?: Maybe<Sequence_Keyword_Min_Fields>;
  stddev?: Maybe<Sequence_Keyword_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Keyword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Keyword_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Keyword_Sum_Fields>;
  var_pop?: Maybe<Sequence_Keyword_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Keyword_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Keyword_Variance_Fields>;
};


/** aggregate fields of "sequence_keyword" */
export type Sequence_Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence_keyword" */
export type Sequence_Keyword_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Keyword_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Keyword_Max_Order_By>;
  min?: Maybe<Sequence_Keyword_Min_Order_By>;
  stddev?: Maybe<Sequence_Keyword_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Keyword_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Keyword_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Keyword_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Keyword_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Keyword_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Keyword_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence_keyword" */
export type Sequence_Keyword_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Keyword_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Keyword_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Keyword_Avg_Fields = {
  __typename?: 'sequence_keyword_avg_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Avg_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence_keyword". All fields are combined with a logical 'AND'. */
export type Sequence_Keyword_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Keyword_Bool_Exp>>;
  _not?: Maybe<Sequence_Keyword_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Keyword_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  keyword?: Maybe<Keyword_Bool_Exp>;
  keyword_id?: Maybe<Int_Comparison_Exp>;
  sequence?: Maybe<Sequence_Bool_Exp>;
  sequence_id?: Maybe<Int_Comparison_Exp>;
  sequence_keyword_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_keyword" */
export enum Sequence_Keyword_Constraint {
  /** unique or primary key constraint */
  SequenceKeywordPkey = 'sequence_keyword_pkey',
  /** unique or primary key constraint */
  SequenceKeywordSequenceIdKeywordIdKey = 'sequence_keyword_sequence_id_keyword_id_key'
}

/** input type for incrementing numeric columns in table "sequence_keyword" */
export type Sequence_Keyword_Inc_Input = {
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keyword_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_keyword" */
export type Sequence_Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword?: Maybe<Keyword_Obj_Rel_Insert_Input>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Sequence_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Keyword_Max_Fields = {
  __typename?: 'sequence_keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Keyword_Min_Fields = {
  __typename?: 'sequence_keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence_keyword" */
export type Sequence_Keyword_Mutation_Response = {
  __typename?: 'sequence_keyword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Keyword>;
};

/** on_conflict condition type for table "sequence_keyword" */
export type Sequence_Keyword_On_Conflict = {
  constraint: Sequence_Keyword_Constraint;
  update_columns?: Array<Sequence_Keyword_Update_Column>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_keyword". */
export type Sequence_Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword?: Maybe<Keyword_Order_By>;
  keyword_id?: Maybe<Order_By>;
  sequence?: Maybe<Sequence_Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_keyword */
export type Sequence_Keyword_Pk_Columns_Input = {
  sequence_keyword_id: Scalars['Int'];
};

/** select columns of table "sequence_keyword" */
export enum Sequence_Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  SequenceKeywordId = 'sequence_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequence_keyword" */
export type Sequence_Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Keyword_Stddev_Fields = {
  __typename?: 'sequence_keyword_stddev_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Stddev_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Keyword_Stddev_Pop_Fields = {
  __typename?: 'sequence_keyword_stddev_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Stddev_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Keyword_Stddev_Samp_Fields = {
  __typename?: 'sequence_keyword_stddev_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Stddev_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Sequence_Keyword_Sum_Fields = {
  __typename?: 'sequence_keyword_sum_fields';
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_keyword_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Sum_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** update columns of table "sequence_keyword" */
export enum Sequence_Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  SequenceKeywordId = 'sequence_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Keyword_Var_Pop_Fields = {
  __typename?: 'sequence_keyword_var_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Var_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Keyword_Var_Samp_Fields = {
  __typename?: 'sequence_keyword_var_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Var_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Keyword_Variance_Fields = {
  __typename?: 'sequence_keyword_variance_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence_keyword" */
export type Sequence_Keyword_Variance_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keyword_id?: Maybe<Order_By>;
};

/** columns and relationships of "sequence_lesson_plan" */
export type Sequence_Lesson_Plan = {
  __typename?: 'sequence_lesson_plan';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  /** An object relationship */
  sequence: Sequence;
  sequence_id: Scalars['Int'];
  sequence_lesson_plan_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Aggregate = {
  __typename?: 'sequence_lesson_plan_aggregate';
  aggregate?: Maybe<Sequence_Lesson_Plan_Aggregate_Fields>;
  nodes: Array<Sequence_Lesson_Plan>;
};

/** aggregate fields of "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Aggregate_Fields = {
  __typename?: 'sequence_lesson_plan_aggregate_fields';
  avg?: Maybe<Sequence_Lesson_Plan_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Lesson_Plan_Max_Fields>;
  min?: Maybe<Sequence_Lesson_Plan_Min_Fields>;
  stddev?: Maybe<Sequence_Lesson_Plan_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Lesson_Plan_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Lesson_Plan_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Lesson_Plan_Sum_Fields>;
  var_pop?: Maybe<Sequence_Lesson_Plan_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Lesson_Plan_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Lesson_Plan_Variance_Fields>;
};


/** aggregate fields of "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Lesson_Plan_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Lesson_Plan_Max_Order_By>;
  min?: Maybe<Sequence_Lesson_Plan_Min_Order_By>;
  stddev?: Maybe<Sequence_Lesson_Plan_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Lesson_Plan_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Lesson_Plan_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Lesson_Plan_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Lesson_Plan_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Lesson_Plan_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Lesson_Plan_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Lesson_Plan_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Lesson_Plan_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Lesson_Plan_Avg_Fields = {
  __typename?: 'sequence_lesson_plan_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence_lesson_plan". All fields are combined with a logical 'AND'. */
export type Sequence_Lesson_Plan_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Lesson_Plan_Bool_Exp>>;
  _not?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Lesson_Plan_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  sequence?: Maybe<Sequence_Bool_Exp>;
  sequence_id?: Maybe<Int_Comparison_Exp>;
  sequence_lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_lesson_plan" */
export enum Sequence_Lesson_Plan_Constraint {
  /** unique or primary key constraint */
  LessonPlanSequenceItemSequenceItemIdLessonPlanIdKey = 'lesson_plan_sequence_item_sequence_item_id_lesson_plan_id_key',
  /** unique or primary key constraint */
  SequenceLessonPlanPkey = 'sequence_lesson_plan_pkey'
}

/** input type for incrementing numeric columns in table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Sequence_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Lesson_Plan_Max_Fields = {
  __typename?: 'sequence_lesson_plan_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Lesson_Plan_Min_Fields = {
  __typename?: 'sequence_lesson_plan_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Mutation_Response = {
  __typename?: 'sequence_lesson_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Lesson_Plan>;
};

/** on_conflict condition type for table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_On_Conflict = {
  constraint: Sequence_Lesson_Plan_Constraint;
  update_columns?: Array<Sequence_Lesson_Plan_Update_Column>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_lesson_plan". */
export type Sequence_Lesson_Plan_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  sequence?: Maybe<Sequence_Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_lesson_plan */
export type Sequence_Lesson_Plan_Pk_Columns_Input = {
  sequence_lesson_plan_id: Scalars['Int'];
};

/** select columns of table "sequence_lesson_plan" */
export enum Sequence_Lesson_Plan_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  SequenceLessonPlanId = 'sequence_lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Lesson_Plan_Stddev_Fields = {
  __typename?: 'sequence_lesson_plan_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Lesson_Plan_Stddev_Pop_Fields = {
  __typename?: 'sequence_lesson_plan_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Lesson_Plan_Stddev_Samp_Fields = {
  __typename?: 'sequence_lesson_plan_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Sequence_Lesson_Plan_Sum_Fields = {
  __typename?: 'sequence_lesson_plan_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** update columns of table "sequence_lesson_plan" */
export enum Sequence_Lesson_Plan_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  SequenceLessonPlanId = 'sequence_lesson_plan_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Lesson_Plan_Var_Pop_Fields = {
  __typename?: 'sequence_lesson_plan_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Lesson_Plan_Var_Samp_Fields = {
  __typename?: 'sequence_lesson_plan_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Lesson_Plan_Variance_Fields = {
  __typename?: 'sequence_lesson_plan_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_lesson_plan_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence_lesson_plan" */
export type Sequence_Lesson_Plan_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_lesson_plan_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Sequence_Max_Fields = {
  __typename?: 'sequence_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sequence_Min_Fields = {
  __typename?: 'sequence_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sequence" */
export type Sequence_Mutation_Response = {
  __typename?: 'sequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence>;
};

/** input type for inserting object relation for remote table "sequence" */
export type Sequence_Obj_Rel_Insert_Input = {
  data: Sequence_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_On_Conflict>;
};

/** on_conflict condition type for table "sequence" */
export type Sequence_On_Conflict = {
  constraint: Sequence_Constraint;
  update_columns?: Array<Sequence_Update_Column>;
  where?: Maybe<Sequence_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence". */
export type Sequence_Order_By = {
  active?: Maybe<Order_By>;
  class_sequences_aggregate?: Maybe<Class_Sequence_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_keywords_aggregate?: Maybe<Sequence_Keyword_Aggregate_Order_By>;
  sequence_lesson_plans_aggregate?: Maybe<Sequence_Lesson_Plan_Aggregate_Order_By>;
  sequence_topic_sequences_aggregate?: Maybe<Sequence_Topic_Sequence_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence */
export type Sequence_Pk_Columns_Input = {
  sequence_id: Scalars['Int'];
};

/** select columns of table "sequence" */
export enum Sequence_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequence" */
export type Sequence_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Stddev_Fields = {
  __typename?: 'sequence_stddev_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Stddev_Pop_Fields = {
  __typename?: 'sequence_stddev_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Stddev_Samp_Fields = {
  __typename?: 'sequence_stddev_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Sequence_Sum_Fields = {
  __typename?: 'sequence_sum_fields';
  lockable_content_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  sequence_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "sequence_topic" */
export type Sequence_Topic = {
  __typename?: 'sequence_topic';
  about: Scalars['String'];
  active: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  featured: Scalars['Boolean'];
  image_path?: Maybe<Scalars['String']>;
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  sequence_topic_catalog_items: Array<Sequence_Topic_Catalog_Item>;
  /** An aggregate relationship */
  sequence_topic_catalog_items_aggregate: Sequence_Topic_Catalog_Item_Aggregate;
  sequence_topic_id: Scalars['Int'];
  /** An array relationship */
  sequence_topic_keywords: Array<Sequence_Topic_Keyword>;
  /** An aggregate relationship */
  sequence_topic_keywords_aggregate: Sequence_Topic_Keyword_Aggregate;
  /** An array relationship */
  sequence_topic_sequences: Array<Sequence_Topic_Sequence>;
  /** An aggregate relationship */
  sequence_topic_sequences_aggregate: Sequence_Topic_Sequence_Aggregate;
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "sequence_topic" */
export type Sequence_TopicSequence_Topic_Catalog_ItemsArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Catalog_Item_Order_By>>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "sequence_topic" */
export type Sequence_TopicSequence_Topic_Catalog_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Catalog_Item_Order_By>>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "sequence_topic" */
export type Sequence_TopicSequence_Topic_KeywordsArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Keyword_Order_By>>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};


/** columns and relationships of "sequence_topic" */
export type Sequence_TopicSequence_Topic_Keywords_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Keyword_Order_By>>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};


/** columns and relationships of "sequence_topic" */
export type Sequence_TopicSequence_Topic_SequencesArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};


/** columns and relationships of "sequence_topic" */
export type Sequence_TopicSequence_Topic_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};

/** aggregated selection of "sequence_topic" */
export type Sequence_Topic_Aggregate = {
  __typename?: 'sequence_topic_aggregate';
  aggregate?: Maybe<Sequence_Topic_Aggregate_Fields>;
  nodes: Array<Sequence_Topic>;
};

/** aggregate fields of "sequence_topic" */
export type Sequence_Topic_Aggregate_Fields = {
  __typename?: 'sequence_topic_aggregate_fields';
  avg?: Maybe<Sequence_Topic_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Topic_Max_Fields>;
  min?: Maybe<Sequence_Topic_Min_Fields>;
  stddev?: Maybe<Sequence_Topic_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Topic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Topic_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Topic_Sum_Fields>;
  var_pop?: Maybe<Sequence_Topic_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Topic_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Topic_Variance_Fields>;
};


/** aggregate fields of "sequence_topic" */
export type Sequence_Topic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Topic_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sequence_Topic_Avg_Fields = {
  __typename?: 'sequence_topic_avg_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sequence_topic". All fields are combined with a logical 'AND'. */
export type Sequence_Topic_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Topic_Bool_Exp>>;
  _not?: Maybe<Sequence_Topic_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Topic_Bool_Exp>>;
  about?: Maybe<String_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  featured?: Maybe<Boolean_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic_catalog_items?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
  sequence_topic_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic_keywords?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
  sequence_topic_sequences?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item = {
  __typename?: 'sequence_topic_catalog_item';
  /** An object relationship */
  catalog_item: Catalog_Item;
  catalog_item_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  sequence_topic?: Maybe<Sequence_Topic>;
  sequence_topic_catalog_item_id: Scalars['Int'];
  sequence_topic_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Aggregate = {
  __typename?: 'sequence_topic_catalog_item_aggregate';
  aggregate?: Maybe<Sequence_Topic_Catalog_Item_Aggregate_Fields>;
  nodes: Array<Sequence_Topic_Catalog_Item>;
};

/** aggregate fields of "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Aggregate_Fields = {
  __typename?: 'sequence_topic_catalog_item_aggregate_fields';
  avg?: Maybe<Sequence_Topic_Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Topic_Catalog_Item_Max_Fields>;
  min?: Maybe<Sequence_Topic_Catalog_Item_Min_Fields>;
  stddev?: Maybe<Sequence_Topic_Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Topic_Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Topic_Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Topic_Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Sequence_Topic_Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Topic_Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Topic_Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Topic_Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Topic_Catalog_Item_Max_Order_By>;
  min?: Maybe<Sequence_Topic_Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Sequence_Topic_Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Topic_Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Topic_Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Topic_Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Topic_Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Topic_Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Topic_Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Topic_Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Topic_Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Topic_Catalog_Item_Avg_Fields = {
  __typename?: 'sequence_topic_catalog_item_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence_topic_catalog_item". All fields are combined with a logical 'AND'. */
export type Sequence_Topic_Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Topic_Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Topic_Catalog_Item_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  sequence_topic?: Maybe<Sequence_Topic_Bool_Exp>;
  sequence_topic_catalog_item_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_topic_catalog_item" */
export enum Sequence_Topic_Catalog_Item_Constraint {
  /** unique or primary key constraint */
  SequenceCatalogItemPkey = 'sequence_catalog_item_pkey'
}

/** input type for incrementing numeric columns in table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_topic?: Maybe<Sequence_Topic_Obj_Rel_Insert_Input>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Topic_Catalog_Item_Max_Fields = {
  __typename?: 'sequence_topic_catalog_item_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Topic_Catalog_Item_Min_Fields = {
  __typename?: 'sequence_topic_catalog_item_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Mutation_Response = {
  __typename?: 'sequence_topic_catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Topic_Catalog_Item>;
};

/** on_conflict condition type for table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_On_Conflict = {
  constraint: Sequence_Topic_Catalog_Item_Constraint;
  update_columns?: Array<Sequence_Topic_Catalog_Item_Update_Column>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_topic_catalog_item". */
export type Sequence_Topic_Catalog_Item_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sequence_topic?: Maybe<Sequence_Topic_Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_topic_catalog_item */
export type Sequence_Topic_Catalog_Item_Pk_Columns_Input = {
  sequence_topic_catalog_item_id: Scalars['Int'];
};

/** select columns of table "sequence_topic_catalog_item" */
export enum Sequence_Topic_Catalog_Item_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SequenceTopicCatalogItemId = 'sequence_topic_catalog_item_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Topic_Catalog_Item_Stddev_Fields = {
  __typename?: 'sequence_topic_catalog_item_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Topic_Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'sequence_topic_catalog_item_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Topic_Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'sequence_topic_catalog_item_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Sequence_Topic_Catalog_Item_Sum_Fields = {
  __typename?: 'sequence_topic_catalog_item_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** update columns of table "sequence_topic_catalog_item" */
export enum Sequence_Topic_Catalog_Item_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SequenceTopicCatalogItemId = 'sequence_topic_catalog_item_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Topic_Catalog_Item_Var_Pop_Fields = {
  __typename?: 'sequence_topic_catalog_item_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Topic_Catalog_Item_Var_Samp_Fields = {
  __typename?: 'sequence_topic_catalog_item_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Topic_Catalog_Item_Variance_Fields = {
  __typename?: 'sequence_topic_catalog_item_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_catalog_item_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence_topic_catalog_item" */
export type Sequence_Topic_Catalog_Item_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  sequence_topic_catalog_item_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "sequence_topic" */
export enum Sequence_Topic_Constraint {
  /** unique or primary key constraint */
  SequencePkey = 'sequence_pkey',
  /** unique or primary key constraint */
  SequenceTopicLockableContentIdKey = 'sequence_topic_lockable_content_id_key'
}

/** input type for incrementing numeric columns in table "sequence_topic" */
export type Sequence_Topic_Inc_Input = {
  lockable_content_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_topic" */
export type Sequence_Topic_Insert_Input = {
  about?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  sequence_topic_catalog_items?: Maybe<Sequence_Topic_Catalog_Item_Arr_Rel_Insert_Input>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keywords?: Maybe<Sequence_Topic_Keyword_Arr_Rel_Insert_Input>;
  sequence_topic_sequences?: Maybe<Sequence_Topic_Sequence_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "sequence_topic_keyword" */
export type Sequence_Topic_Keyword = {
  __typename?: 'sequence_topic_keyword';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  keyword: Keyword;
  keyword_id: Scalars['Int'];
  /** An object relationship */
  sequence_topic: Sequence_Topic;
  sequence_topic_id: Scalars['Int'];
  sequence_topic_keyword_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Aggregate = {
  __typename?: 'sequence_topic_keyword_aggregate';
  aggregate?: Maybe<Sequence_Topic_Keyword_Aggregate_Fields>;
  nodes: Array<Sequence_Topic_Keyword>;
};

/** aggregate fields of "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Aggregate_Fields = {
  __typename?: 'sequence_topic_keyword_aggregate_fields';
  avg?: Maybe<Sequence_Topic_Keyword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Topic_Keyword_Max_Fields>;
  min?: Maybe<Sequence_Topic_Keyword_Min_Fields>;
  stddev?: Maybe<Sequence_Topic_Keyword_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Topic_Keyword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Topic_Keyword_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Topic_Keyword_Sum_Fields>;
  var_pop?: Maybe<Sequence_Topic_Keyword_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Topic_Keyword_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Topic_Keyword_Variance_Fields>;
};


/** aggregate fields of "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Topic_Keyword_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Topic_Keyword_Max_Order_By>;
  min?: Maybe<Sequence_Topic_Keyword_Min_Order_By>;
  stddev?: Maybe<Sequence_Topic_Keyword_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Topic_Keyword_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Topic_Keyword_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Topic_Keyword_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Topic_Keyword_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Topic_Keyword_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Topic_Keyword_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Topic_Keyword_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Topic_Keyword_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Topic_Keyword_Avg_Fields = {
  __typename?: 'sequence_topic_keyword_avg_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Avg_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence_topic_keyword". All fields are combined with a logical 'AND'. */
export type Sequence_Topic_Keyword_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Topic_Keyword_Bool_Exp>>;
  _not?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Topic_Keyword_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  keyword?: Maybe<Keyword_Bool_Exp>;
  keyword_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic?: Maybe<Sequence_Topic_Bool_Exp>;
  sequence_topic_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic_keyword_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_topic_keyword" */
export enum Sequence_Topic_Keyword_Constraint {
  /** unique or primary key constraint */
  SequenceTopicKeywordPkey = 'sequence_topic_keyword_pkey'
}

/** input type for incrementing numeric columns in table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Inc_Input = {
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword?: Maybe<Keyword_Obj_Rel_Insert_Input>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_topic?: Maybe<Sequence_Topic_Obj_Rel_Insert_Input>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Topic_Keyword_Max_Fields = {
  __typename?: 'sequence_topic_keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Topic_Keyword_Min_Fields = {
  __typename?: 'sequence_topic_keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Mutation_Response = {
  __typename?: 'sequence_topic_keyword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Topic_Keyword>;
};

/** on_conflict condition type for table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_On_Conflict = {
  constraint: Sequence_Topic_Keyword_Constraint;
  update_columns?: Array<Sequence_Topic_Keyword_Update_Column>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_topic_keyword". */
export type Sequence_Topic_Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword?: Maybe<Keyword_Order_By>;
  keyword_id?: Maybe<Order_By>;
  sequence_topic?: Maybe<Sequence_Topic_Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_topic_keyword */
export type Sequence_Topic_Keyword_Pk_Columns_Input = {
  sequence_topic_keyword_id: Scalars['Int'];
};

/** select columns of table "sequence_topic_keyword" */
export enum Sequence_Topic_Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  SequenceTopicKeywordId = 'sequence_topic_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Topic_Keyword_Stddev_Fields = {
  __typename?: 'sequence_topic_keyword_stddev_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Stddev_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Topic_Keyword_Stddev_Pop_Fields = {
  __typename?: 'sequence_topic_keyword_stddev_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Stddev_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Topic_Keyword_Stddev_Samp_Fields = {
  __typename?: 'sequence_topic_keyword_stddev_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Stddev_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Sequence_Topic_Keyword_Sum_Fields = {
  __typename?: 'sequence_topic_keyword_sum_fields';
  keyword_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Sum_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** update columns of table "sequence_topic_keyword" */
export enum Sequence_Topic_Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  SequenceTopicKeywordId = 'sequence_topic_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Topic_Keyword_Var_Pop_Fields = {
  __typename?: 'sequence_topic_keyword_var_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Var_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Topic_Keyword_Var_Samp_Fields = {
  __typename?: 'sequence_topic_keyword_var_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Var_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Topic_Keyword_Variance_Fields = {
  __typename?: 'sequence_topic_keyword_variance_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence_topic_keyword" */
export type Sequence_Topic_Keyword_Variance_Order_By = {
  keyword_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keyword_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Sequence_Topic_Max_Fields = {
  __typename?: 'sequence_topic_max_fields';
  about?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sequence_Topic_Min_Fields = {
  __typename?: 'sequence_topic_min_fields';
  about?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sequence_topic" */
export type Sequence_Topic_Mutation_Response = {
  __typename?: 'sequence_topic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Topic>;
};

/** input type for inserting object relation for remote table "sequence_topic" */
export type Sequence_Topic_Obj_Rel_Insert_Input = {
  data: Sequence_Topic_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Topic_On_Conflict>;
};

/** on_conflict condition type for table "sequence_topic" */
export type Sequence_Topic_On_Conflict = {
  constraint: Sequence_Topic_Constraint;
  update_columns?: Array<Sequence_Topic_Update_Column>;
  where?: Maybe<Sequence_Topic_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_topic". */
export type Sequence_Topic_Order_By = {
  about?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  featured?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  sequence_topic_catalog_items_aggregate?: Maybe<Sequence_Topic_Catalog_Item_Aggregate_Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_keywords_aggregate?: Maybe<Sequence_Topic_Keyword_Aggregate_Order_By>;
  sequence_topic_sequences_aggregate?: Maybe<Sequence_Topic_Sequence_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_topic */
export type Sequence_Topic_Pk_Columns_Input = {
  sequence_topic_id: Scalars['Int'];
};

/** select columns of table "sequence_topic" */
export enum Sequence_Topic_Select_Column {
  /** column name */
  About = 'about',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** many sequences with many sequence topics */
export type Sequence_Topic_Sequence = {
  __typename?: 'sequence_topic_sequence';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  sequence: Sequence;
  sequence_id: Scalars['Int'];
  /** An object relationship */
  sequence_topic: Sequence_Topic;
  sequence_topic_id: Scalars['Int'];
  sequence_topic_sequence_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Aggregate = {
  __typename?: 'sequence_topic_sequence_aggregate';
  aggregate?: Maybe<Sequence_Topic_Sequence_Aggregate_Fields>;
  nodes: Array<Sequence_Topic_Sequence>;
};

/** aggregate fields of "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Aggregate_Fields = {
  __typename?: 'sequence_topic_sequence_aggregate_fields';
  avg?: Maybe<Sequence_Topic_Sequence_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sequence_Topic_Sequence_Max_Fields>;
  min?: Maybe<Sequence_Topic_Sequence_Min_Fields>;
  stddev?: Maybe<Sequence_Topic_Sequence_Stddev_Fields>;
  stddev_pop?: Maybe<Sequence_Topic_Sequence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sequence_Topic_Sequence_Stddev_Samp_Fields>;
  sum?: Maybe<Sequence_Topic_Sequence_Sum_Fields>;
  var_pop?: Maybe<Sequence_Topic_Sequence_Var_Pop_Fields>;
  var_samp?: Maybe<Sequence_Topic_Sequence_Var_Samp_Fields>;
  variance?: Maybe<Sequence_Topic_Sequence_Variance_Fields>;
};


/** aggregate fields of "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Aggregate_Order_By = {
  avg?: Maybe<Sequence_Topic_Sequence_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Sequence_Topic_Sequence_Max_Order_By>;
  min?: Maybe<Sequence_Topic_Sequence_Min_Order_By>;
  stddev?: Maybe<Sequence_Topic_Sequence_Stddev_Order_By>;
  stddev_pop?: Maybe<Sequence_Topic_Sequence_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Sequence_Topic_Sequence_Stddev_Samp_Order_By>;
  sum?: Maybe<Sequence_Topic_Sequence_Sum_Order_By>;
  var_pop?: Maybe<Sequence_Topic_Sequence_Var_Pop_Order_By>;
  var_samp?: Maybe<Sequence_Topic_Sequence_Var_Samp_Order_By>;
  variance?: Maybe<Sequence_Topic_Sequence_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Arr_Rel_Insert_Input = {
  data: Array<Sequence_Topic_Sequence_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Sequence_Topic_Sequence_On_Conflict>;
};

/** aggregate avg on columns */
export type Sequence_Topic_Sequence_Avg_Fields = {
  __typename?: 'sequence_topic_sequence_avg_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Avg_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sequence_topic_sequence". All fields are combined with a logical 'AND'. */
export type Sequence_Topic_Sequence_Bool_Exp = {
  _and?: Maybe<Array<Sequence_Topic_Sequence_Bool_Exp>>;
  _not?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
  _or?: Maybe<Array<Sequence_Topic_Sequence_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  sequence?: Maybe<Sequence_Bool_Exp>;
  sequence_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic?: Maybe<Sequence_Topic_Bool_Exp>;
  sequence_topic_id?: Maybe<Int_Comparison_Exp>;
  sequence_topic_sequence_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sequence_topic_sequence" */
export enum Sequence_Topic_Sequence_Constraint {
  /** unique or primary key constraint */
  SequenceTopicSequencePkey = 'sequence_topic_sequence_pkey',
  /** unique or primary key constraint */
  SequenceTopicSequenceSequenceTopicIdSequenceIdKey = 'sequence_topic_sequence_sequence_topic_id_sequence_id_key'
}

/** input type for incrementing numeric columns in table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Inc_Input = {
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence?: Maybe<Sequence_Obj_Rel_Insert_Input>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_topic?: Maybe<Sequence_Topic_Obj_Rel_Insert_Input>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sequence_Topic_Sequence_Max_Fields = {
  __typename?: 'sequence_topic_sequence_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sequence_Topic_Sequence_Min_Fields = {
  __typename?: 'sequence_topic_sequence_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Mutation_Response = {
  __typename?: 'sequence_topic_sequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sequence_Topic_Sequence>;
};

/** on_conflict condition type for table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_On_Conflict = {
  constraint: Sequence_Topic_Sequence_Constraint;
  update_columns?: Array<Sequence_Topic_Sequence_Update_Column>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};

/** Ordering options when selecting data from "sequence_topic_sequence". */
export type Sequence_Topic_Sequence_Order_By = {
  created_at?: Maybe<Order_By>;
  sequence?: Maybe<Sequence_Order_By>;
  sequence_id?: Maybe<Order_By>;
  sequence_topic?: Maybe<Sequence_Topic_Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: sequence_topic_sequence */
export type Sequence_Topic_Sequence_Pk_Columns_Input = {
  sequence_topic_sequence_id: Scalars['Int'];
};

/** select columns of table "sequence_topic_sequence" */
export enum Sequence_Topic_Sequence_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  SequenceTopicSequenceId = 'sequence_topic_sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Topic_Sequence_Stddev_Fields = {
  __typename?: 'sequence_topic_sequence_stddev_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Stddev_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Topic_Sequence_Stddev_Pop_Fields = {
  __typename?: 'sequence_topic_sequence_stddev_pop_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Stddev_Pop_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Topic_Sequence_Stddev_Samp_Fields = {
  __typename?: 'sequence_topic_sequence_stddev_samp_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Stddev_Samp_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Sequence_Topic_Sequence_Sum_Fields = {
  __typename?: 'sequence_topic_sequence_sum_fields';
  sequence_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Sum_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** update columns of table "sequence_topic_sequence" */
export enum Sequence_Topic_Sequence_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  SequenceTopicSequenceId = 'sequence_topic_sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Topic_Sequence_Var_Pop_Fields = {
  __typename?: 'sequence_topic_sequence_var_pop_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Var_Pop_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sequence_Topic_Sequence_Var_Samp_Fields = {
  __typename?: 'sequence_topic_sequence_var_samp_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Var_Samp_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Sequence_Topic_Sequence_Variance_Fields = {
  __typename?: 'sequence_topic_sequence_variance_fields';
  sequence_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
  sequence_topic_sequence_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sequence_topic_sequence" */
export type Sequence_Topic_Sequence_Variance_Order_By = {
  sequence_id?: Maybe<Order_By>;
  sequence_topic_id?: Maybe<Order_By>;
  sequence_topic_sequence_id?: Maybe<Order_By>;
};

/** input type for updating data in table "sequence_topic" */
export type Sequence_Topic_Set_Input = {
  about?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sequence_Topic_Stddev_Fields = {
  __typename?: 'sequence_topic_stddev_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sequence_Topic_Stddev_Pop_Fields = {
  __typename?: 'sequence_topic_stddev_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sequence_Topic_Stddev_Samp_Fields = {
  __typename?: 'sequence_topic_stddev_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Sequence_Topic_Sum_Fields = {
  __typename?: 'sequence_topic_sum_fields';
  lockable_content_id?: Maybe<Scalars['Int']>;
  sequence_topic_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "sequence_topic" */
export enum Sequence_Topic_Update_Column {
  /** column name */
  About = 'about',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  SequenceTopicId = 'sequence_topic_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Topic_Var_Pop_Fields = {
  __typename?: 'sequence_topic_var_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sequence_Topic_Var_Samp_Fields = {
  __typename?: 'sequence_topic_var_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sequence_Topic_Variance_Fields = {
  __typename?: 'sequence_topic_variance_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  sequence_topic_id?: Maybe<Scalars['Float']>;
};

/** update columns of table "sequence" */
export enum Sequence_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  SequenceId = 'sequence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Sequence_Var_Pop_Fields = {
  __typename?: 'sequence_var_pop_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sequence_Var_Samp_Fields = {
  __typename?: 'sequence_var_samp_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sequence_Variance_Fields = {
  __typename?: 'sequence_variance_fields';
  lockable_content_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  sequence_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "session" */
export type Session = {
  __typename?: 'session';
  admin_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['date'];
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id: Scalars['Int'];
  student_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  teacher_id?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

/** aggregated selection of "session" */
export type Session_Aggregate = {
  __typename?: 'session_aggregate';
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

/** aggregate fields of "session" */
export type Session_Aggregate_Fields = {
  __typename?: 'session_aggregate_fields';
  avg?: Maybe<Session_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
  stddev?: Maybe<Session_Stddev_Fields>;
  stddev_pop?: Maybe<Session_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Session_Stddev_Samp_Fields>;
  sum?: Maybe<Session_Sum_Fields>;
  var_pop?: Maybe<Session_Var_Pop_Fields>;
  var_samp?: Maybe<Session_Var_Samp_Fields>;
  variance?: Maybe<Session_Variance_Fields>;
};


/** aggregate fields of "session" */
export type Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Session_Avg_Fields = {
  __typename?: 'session_avg_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "session". All fields are combined with a logical 'AND'. */
export type Session_Bool_Exp = {
  _and?: Maybe<Array<Session_Bool_Exp>>;
  _not?: Maybe<Session_Bool_Exp>;
  _or?: Maybe<Array<Session_Bool_Exp>>;
  admin_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Date_Comparison_Exp>;
  impersonate_id?: Maybe<Int_Comparison_Exp>;
  session_id?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "session" */
export enum Session_Constraint {
  /** unique or primary key constraint */
  LoginAuthPkey = 'login_auth_pkey'
}

/** input type for incrementing numeric columns in table "session" */
export type Session_Inc_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "session" */
export type Session_Insert_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['date']>;
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Session_Max_Fields = {
  __typename?: 'session_max_fields';
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['date']>;
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Session_Min_Fields = {
  __typename?: 'session_min_fields';
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['date']>;
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "session" */
export type Session_Mutation_Response = {
  __typename?: 'session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session>;
};

/** on_conflict condition type for table "session" */
export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns?: Array<Session_Update_Column>;
  where?: Maybe<Session_Bool_Exp>;
};

/** Ordering options when selecting data from "session". */
export type Session_Order_By = {
  admin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  impersonate_id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: session */
export type Session_Pk_Columns_Input = {
  session_id: Scalars['Int'];
};

/** select columns of table "session" */
export enum Session_Select_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ImpersonateId = 'impersonate_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "session" */
export type Session_Set_Input = {
  admin_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['date']>;
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Session_Stddev_Fields = {
  __typename?: 'session_stddev_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Session_Stddev_Pop_Fields = {
  __typename?: 'session_stddev_pop_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Session_Stddev_Samp_Fields = {
  __typename?: 'session_stddev_samp_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Session_Sum_Fields = {
  __typename?: 'session_sum_fields';
  admin_id?: Maybe<Scalars['Int']>;
  impersonate_id?: Maybe<Scalars['Int']>;
  session_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "session" */
export enum Session_Update_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ImpersonateId = 'impersonate_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Session_Var_Pop_Fields = {
  __typename?: 'session_var_pop_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Session_Var_Samp_Fields = {
  __typename?: 'session_var_samp_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Session_Variance_Fields = {
  __typename?: 'session_variance_fields';
  admin_id?: Maybe<Scalars['Float']>;
  impersonate_id?: Maybe<Scalars['Float']>;
  session_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "skill_level" */
export type Skill_Level = {
  __typename?: 'skill_level';
  created_at?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  skill_level_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "skill_level" */
export type Skill_Level_Aggregate = {
  __typename?: 'skill_level_aggregate';
  aggregate?: Maybe<Skill_Level_Aggregate_Fields>;
  nodes: Array<Skill_Level>;
};

/** aggregate fields of "skill_level" */
export type Skill_Level_Aggregate_Fields = {
  __typename?: 'skill_level_aggregate_fields';
  avg?: Maybe<Skill_Level_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Skill_Level_Max_Fields>;
  min?: Maybe<Skill_Level_Min_Fields>;
  stddev?: Maybe<Skill_Level_Stddev_Fields>;
  stddev_pop?: Maybe<Skill_Level_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Skill_Level_Stddev_Samp_Fields>;
  sum?: Maybe<Skill_Level_Sum_Fields>;
  var_pop?: Maybe<Skill_Level_Var_Pop_Fields>;
  var_samp?: Maybe<Skill_Level_Var_Samp_Fields>;
  variance?: Maybe<Skill_Level_Variance_Fields>;
};


/** aggregate fields of "skill_level" */
export type Skill_Level_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Skill_Level_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Skill_Level_Avg_Fields = {
  __typename?: 'skill_level_avg_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "skill_level". All fields are combined with a logical 'AND'. */
export type Skill_Level_Bool_Exp = {
  _and?: Maybe<Array<Skill_Level_Bool_Exp>>;
  _not?: Maybe<Skill_Level_Bool_Exp>;
  _or?: Maybe<Array<Skill_Level_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  skill_level_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "skill_level" */
export enum Skill_Level_Constraint {
  /** unique or primary key constraint */
  SkillLevelPkey = 'skill_level_pkey'
}

/** input type for incrementing numeric columns in table "skill_level" */
export type Skill_Level_Inc_Input = {
  skill_level_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "skill_level" */
export type Skill_Level_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Skill_Level_Max_Fields = {
  __typename?: 'skill_level_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Skill_Level_Min_Fields = {
  __typename?: 'skill_level_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "skill_level" */
export type Skill_Level_Mutation_Response = {
  __typename?: 'skill_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Skill_Level>;
};

/** input type for inserting object relation for remote table "skill_level" */
export type Skill_Level_Obj_Rel_Insert_Input = {
  data: Skill_Level_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Skill_Level_On_Conflict>;
};

/** on_conflict condition type for table "skill_level" */
export type Skill_Level_On_Conflict = {
  constraint: Skill_Level_Constraint;
  update_columns?: Array<Skill_Level_Update_Column>;
  where?: Maybe<Skill_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "skill_level". */
export type Skill_Level_Order_By = {
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  skill_level_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: skill_level */
export type Skill_Level_Pk_Columns_Input = {
  skill_level_id: Scalars['Int'];
};

/** select columns of table "skill_level" */
export enum Skill_Level_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "skill_level" */
export type Skill_Level_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Skill_Level_Stddev_Fields = {
  __typename?: 'skill_level_stddev_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Skill_Level_Stddev_Pop_Fields = {
  __typename?: 'skill_level_stddev_pop_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Skill_Level_Stddev_Samp_Fields = {
  __typename?: 'skill_level_stddev_samp_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Skill_Level_Sum_Fields = {
  __typename?: 'skill_level_sum_fields';
  skill_level_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "skill_level" */
export enum Skill_Level_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Skill_Level_Var_Pop_Fields = {
  __typename?: 'skill_level_var_pop_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Skill_Level_Var_Samp_Fields = {
  __typename?: 'skill_level_var_samp_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Skill_Level_Variance_Fields = {
  __typename?: 'skill_level_variance_fields';
  skill_level_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "song" */
export type Song = {
  __typename?: 'song';
  /** Used to show/hide the song on student and teacher side */
  active: Scalars['Boolean'];
  /** An object relationship */
  artist: Artist;
  artist_id: Scalars['Int'];
  /** An array relationship */
  class_songs: Array<Class_Song>;
  /** An aggregate relationship */
  class_songs_aggregate: Class_Song_Aggregate;
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  /** Used to show recommended on Teacher and Student side */
  featured: Scalars['Boolean'];
  image_path: Scalars['String'];
  is_favorite?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "student_liked_song" */
  is_student_favorite?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "teacher_liked_song" */
  is_teacher_favorite?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  last_update: Scalars['timestamptz'];
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  lyrics?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  publisherControl?: Maybe<Publisher_Control>;
  recommended?: Maybe<Scalars['Boolean']>;
  resource_path?: Maybe<Scalars['String']>;
  /** An array relationship */
  royalty_logs: Array<Royalty_Log>;
  /** An aggregate relationship */
  royalty_logs_aggregate: Royalty_Log_Aggregate;
  /** An object relationship */
  scale?: Maybe<Scale>;
  scale_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  song_catalog_item: Array<Song_Catalog_Item>;
  /** An aggregate relationship */
  song_catalog_item_aggregate: Song_Catalog_Item_Aggregate;
  /** An array relationship */
  song_grades: Array<Song_Grade>;
  /** An aggregate relationship */
  song_grades_aggregate: Song_Grade_Aggregate;
  song_id: Scalars['Int'];
  /** A computed field, executes function "song_maturity_level" */
  song_maturity_level?: Maybe<Scalars['String']>;
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  teacher_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  teacher_song_favorites: Array<Teacher_Song_Favorite>;
  /** An aggregate relationship */
  teacher_song_favorites_aggregate: Teacher_Song_Favorite_Aggregate;
  title: Scalars['String'];
  /** An array relationship */
  tracks: Array<Track>;
  /** An aggregate relationship */
  tracks_aggregate: Track_Aggregate;
  writers?: Maybe<Scalars['String']>;
};


/** columns and relationships of "song" */
export type SongClass_SongsArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongClass_Songs_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "song" */
export type SongIs_FavoriteArgs = {
  args: Is_Favorite_Song_Args;
};


/** columns and relationships of "song" */
export type SongIs_Student_FavoriteArgs = {
  args: Is_Student_Favorite_Song_Args;
};


/** columns and relationships of "song" */
export type SongIs_Teacher_FavoriteArgs = {
  args: Is_Teacher_Favorite_Song_Args;
};


/** columns and relationships of "song" */
export type SongLyricsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "song" */
export type SongRoyalty_LogsArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongRoyalty_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongSong_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Catalog_Item_Order_By>>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongSong_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Catalog_Item_Order_By>>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongSong_GradesArgs = {
  distinct_on?: Maybe<Array<Song_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Grade_Order_By>>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongSong_Grades_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Grade_Order_By>>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongTeacher_Song_FavoritesArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongTeacher_Song_Favorites_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongTracksArgs = {
  distinct_on?: Maybe<Array<Track_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Order_By>>;
  where?: Maybe<Track_Bool_Exp>;
};


/** columns and relationships of "song" */
export type SongTracks_AggregateArgs = {
  distinct_on?: Maybe<Array<Track_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Order_By>>;
  where?: Maybe<Track_Bool_Exp>;
};

/** aggregated selection of "song" */
export type Song_Aggregate = {
  __typename?: 'song_aggregate';
  aggregate?: Maybe<Song_Aggregate_Fields>;
  nodes: Array<Song>;
};

/** aggregate fields of "song" */
export type Song_Aggregate_Fields = {
  __typename?: 'song_aggregate_fields';
  avg?: Maybe<Song_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Song_Max_Fields>;
  min?: Maybe<Song_Min_Fields>;
  stddev?: Maybe<Song_Stddev_Fields>;
  stddev_pop?: Maybe<Song_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Song_Stddev_Samp_Fields>;
  sum?: Maybe<Song_Sum_Fields>;
  var_pop?: Maybe<Song_Var_Pop_Fields>;
  var_samp?: Maybe<Song_Var_Samp_Fields>;
  variance?: Maybe<Song_Variance_Fields>;
};


/** aggregate fields of "song" */
export type Song_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Song_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Song_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
  lyrics?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Song_Avg_Fields = {
  __typename?: 'song_avg_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "song". All fields are combined with a logical 'AND'. */
export type Song_Bool_Exp = {
  _and?: Maybe<Array<Song_Bool_Exp>>;
  _not?: Maybe<Song_Bool_Exp>;
  _or?: Maybe<Array<Song_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  artist?: Maybe<Artist_Bool_Exp>;
  artist_id?: Maybe<Int_Comparison_Exp>;
  class_songs?: Maybe<Class_Song_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  featured?: Maybe<Boolean_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  lyrics?: Maybe<Jsonb_Comparison_Exp>;
  publisherControl?: Maybe<Publisher_Control_Bool_Exp>;
  recommended?: Maybe<Boolean_Comparison_Exp>;
  resource_path?: Maybe<String_Comparison_Exp>;
  royalty_logs?: Maybe<Royalty_Log_Bool_Exp>;
  scale?: Maybe<Scale_Bool_Exp>;
  scale_id?: Maybe<Int_Comparison_Exp>;
  song_catalog_item?: Maybe<Song_Catalog_Item_Bool_Exp>;
  song_grades?: Maybe<Song_Grade_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  song_maturity_level?: Maybe<String_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_song_favorites?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  tracks?: Maybe<Track_Bool_Exp>;
  writers?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "song_catalog_item" */
export type Song_Catalog_Item = {
  __typename?: 'song_catalog_item';
  /** An object relationship */
  catalog_item: Catalog_Item;
  catalog_item_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  song: Song;
  song_catalog_item_id: Scalars['Int'];
  song_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "song_catalog_item" */
export type Song_Catalog_Item_Aggregate = {
  __typename?: 'song_catalog_item_aggregate';
  aggregate?: Maybe<Song_Catalog_Item_Aggregate_Fields>;
  nodes: Array<Song_Catalog_Item>;
};

/** aggregate fields of "song_catalog_item" */
export type Song_Catalog_Item_Aggregate_Fields = {
  __typename?: 'song_catalog_item_aggregate_fields';
  avg?: Maybe<Song_Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Song_Catalog_Item_Max_Fields>;
  min?: Maybe<Song_Catalog_Item_Min_Fields>;
  stddev?: Maybe<Song_Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Song_Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Song_Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Song_Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Song_Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Song_Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Song_Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "song_catalog_item" */
export type Song_Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "song_catalog_item" */
export type Song_Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Song_Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Song_Catalog_Item_Max_Order_By>;
  min?: Maybe<Song_Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Song_Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Song_Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Song_Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Song_Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Song_Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Song_Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Song_Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "song_catalog_item" */
export type Song_Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Song_Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Song_Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Song_Catalog_Item_Avg_Fields = {
  __typename?: 'song_catalog_item_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "song_catalog_item". All fields are combined with a logical 'AND'. */
export type Song_Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Song_Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Song_Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Song_Catalog_Item_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_catalog_item_id?: Maybe<Int_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "song_catalog_item" */
export enum Song_Catalog_Item_Constraint {
  /** unique or primary key constraint */
  SongCatalogItemPkey = 'song_catalog_item_pkey',
  /** unique or primary key constraint */
  SongCatalogItemSongIdCatalogItemIdKey = 'song_catalog_item_song_id_catalog_item_id_key'
}

/** input type for incrementing numeric columns in table "song_catalog_item" */
export type Song_Catalog_Item_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  song_catalog_item_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "song_catalog_item" */
export type Song_Catalog_Item_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_catalog_item_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Song_Catalog_Item_Max_Fields = {
  __typename?: 'song_catalog_item_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_catalog_item_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Song_Catalog_Item_Min_Fields = {
  __typename?: 'song_catalog_item_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_catalog_item_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "song_catalog_item" */
export type Song_Catalog_Item_Mutation_Response = {
  __typename?: 'song_catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Song_Catalog_Item>;
};

/** on_conflict condition type for table "song_catalog_item" */
export type Song_Catalog_Item_On_Conflict = {
  constraint: Song_Catalog_Item_Constraint;
  update_columns?: Array<Song_Catalog_Item_Update_Column>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "song_catalog_item". */
export type Song_Catalog_Item_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: song_catalog_item */
export type Song_Catalog_Item_Pk_Columns_Input = {
  song_catalog_item_id: Scalars['Int'];
};

/** select columns of table "song_catalog_item" */
export enum Song_Catalog_Item_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongCatalogItemId = 'song_catalog_item_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "song_catalog_item" */
export type Song_Catalog_Item_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_catalog_item_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Song_Catalog_Item_Stddev_Fields = {
  __typename?: 'song_catalog_item_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Song_Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'song_catalog_item_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Song_Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'song_catalog_item_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Song_Catalog_Item_Sum_Fields = {
  __typename?: 'song_catalog_item_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  song_catalog_item_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** update columns of table "song_catalog_item" */
export enum Song_Catalog_Item_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongCatalogItemId = 'song_catalog_item_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Song_Catalog_Item_Var_Pop_Fields = {
  __typename?: 'song_catalog_item_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Song_Catalog_Item_Var_Samp_Fields = {
  __typename?: 'song_catalog_item_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Song_Catalog_Item_Variance_Fields = {
  __typename?: 'song_catalog_item_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  song_catalog_item_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "song_catalog_item" */
export type Song_Catalog_Item_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  song_catalog_item_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "song" */
export enum Song_Constraint {
  /** unique or primary key constraint */
  SongLockableContentIdKey = 'song_lockable_content_id_key',
  /** unique or primary key constraint */
  SongPkey = 'song_pkey',
  /** unique or primary key constraint */
  SongSongIdKey = 'song_song_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Song_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
  lyrics?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Song_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
  lyrics?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Song_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
};

/** columns and relationships of "song_grade" */
export type Song_Grade = {
  __typename?: 'song_grade';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  grade: Grade;
  grade_id: Scalars['Int'];
  /** An object relationship */
  song: Song;
  song_grade_id: Scalars['Int'];
  song_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "song_grade" */
export type Song_Grade_Aggregate = {
  __typename?: 'song_grade_aggregate';
  aggregate?: Maybe<Song_Grade_Aggregate_Fields>;
  nodes: Array<Song_Grade>;
};

/** aggregate fields of "song_grade" */
export type Song_Grade_Aggregate_Fields = {
  __typename?: 'song_grade_aggregate_fields';
  avg?: Maybe<Song_Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Song_Grade_Max_Fields>;
  min?: Maybe<Song_Grade_Min_Fields>;
  stddev?: Maybe<Song_Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Song_Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Song_Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Song_Grade_Sum_Fields>;
  var_pop?: Maybe<Song_Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Song_Grade_Var_Samp_Fields>;
  variance?: Maybe<Song_Grade_Variance_Fields>;
};


/** aggregate fields of "song_grade" */
export type Song_Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Song_Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "song_grade" */
export type Song_Grade_Aggregate_Order_By = {
  avg?: Maybe<Song_Grade_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Song_Grade_Max_Order_By>;
  min?: Maybe<Song_Grade_Min_Order_By>;
  stddev?: Maybe<Song_Grade_Stddev_Order_By>;
  stddev_pop?: Maybe<Song_Grade_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Song_Grade_Stddev_Samp_Order_By>;
  sum?: Maybe<Song_Grade_Sum_Order_By>;
  var_pop?: Maybe<Song_Grade_Var_Pop_Order_By>;
  var_samp?: Maybe<Song_Grade_Var_Samp_Order_By>;
  variance?: Maybe<Song_Grade_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "song_grade" */
export type Song_Grade_Arr_Rel_Insert_Input = {
  data: Array<Song_Grade_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Song_Grade_On_Conflict>;
};

/** aggregate avg on columns */
export type Song_Grade_Avg_Fields = {
  __typename?: 'song_grade_avg_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "song_grade" */
export type Song_Grade_Avg_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "song_grade". All fields are combined with a logical 'AND'. */
export type Song_Grade_Bool_Exp = {
  _and?: Maybe<Array<Song_Grade_Bool_Exp>>;
  _not?: Maybe<Song_Grade_Bool_Exp>;
  _or?: Maybe<Array<Song_Grade_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grade?: Maybe<Grade_Bool_Exp>;
  grade_id?: Maybe<Int_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_grade_id?: Maybe<Int_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "song_grade" */
export enum Song_Grade_Constraint {
  /** unique or primary key constraint */
  SongGradePkey = 'song_grade_pkey',
  /** unique or primary key constraint */
  SongGradeSongIdGradeIdKey = 'song_grade_song_id_grade_id_key'
}

/** input type for incrementing numeric columns in table "song_grade" */
export type Song_Grade_Inc_Input = {
  grade_id?: Maybe<Scalars['Int']>;
  song_grade_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "song_grade" */
export type Song_Grade_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade?: Maybe<Grade_Obj_Rel_Insert_Input>;
  grade_id?: Maybe<Scalars['Int']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_grade_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Song_Grade_Max_Fields = {
  __typename?: 'song_grade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  song_grade_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "song_grade" */
export type Song_Grade_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Song_Grade_Min_Fields = {
  __typename?: 'song_grade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  song_grade_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "song_grade" */
export type Song_Grade_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "song_grade" */
export type Song_Grade_Mutation_Response = {
  __typename?: 'song_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Song_Grade>;
};

/** on_conflict condition type for table "song_grade" */
export type Song_Grade_On_Conflict = {
  constraint: Song_Grade_Constraint;
  update_columns?: Array<Song_Grade_Update_Column>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "song_grade". */
export type Song_Grade_Order_By = {
  created_at?: Maybe<Order_By>;
  grade?: Maybe<Grade_Order_By>;
  grade_id?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: song_grade */
export type Song_Grade_Pk_Columns_Input = {
  song_grade_id: Scalars['Int'];
};

/** select columns of table "song_grade" */
export enum Song_Grade_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  SongGradeId = 'song_grade_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "song_grade" */
export type Song_Grade_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grade_id?: Maybe<Scalars['Int']>;
  song_grade_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Song_Grade_Stddev_Fields = {
  __typename?: 'song_grade_stddev_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "song_grade" */
export type Song_Grade_Stddev_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Song_Grade_Stddev_Pop_Fields = {
  __typename?: 'song_grade_stddev_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "song_grade" */
export type Song_Grade_Stddev_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Song_Grade_Stddev_Samp_Fields = {
  __typename?: 'song_grade_stddev_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "song_grade" */
export type Song_Grade_Stddev_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Song_Grade_Sum_Fields = {
  __typename?: 'song_grade_sum_fields';
  grade_id?: Maybe<Scalars['Int']>;
  song_grade_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "song_grade" */
export type Song_Grade_Sum_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** update columns of table "song_grade" */
export enum Song_Grade_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradeId = 'grade_id',
  /** column name */
  SongGradeId = 'song_grade_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Song_Grade_Var_Pop_Fields = {
  __typename?: 'song_grade_var_pop_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "song_grade" */
export type Song_Grade_Var_Pop_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Song_Grade_Var_Samp_Fields = {
  __typename?: 'song_grade_var_samp_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "song_grade" */
export type Song_Grade_Var_Samp_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Song_Grade_Variance_Fields = {
  __typename?: 'song_grade_variance_fields';
  grade_id?: Maybe<Scalars['Float']>;
  song_grade_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "song_grade" */
export type Song_Grade_Variance_Order_By = {
  grade_id?: Maybe<Order_By>;
  song_grade_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "song" */
export type Song_Inc_Input = {
  artist_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  scale_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "song" */
export type Song_Insert_Input = {
  /** Used to show/hide the song on student and teacher side */
  active?: Maybe<Scalars['Boolean']>;
  artist?: Maybe<Artist_Obj_Rel_Insert_Input>;
  artist_id?: Maybe<Scalars['Int']>;
  class_songs?: Maybe<Class_Song_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  /** Used to show recommended on Teacher and Student side */
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  lyrics?: Maybe<Scalars['jsonb']>;
  publisherControl?: Maybe<Publisher_Control_Obj_Rel_Insert_Input>;
  recommended?: Maybe<Scalars['Boolean']>;
  resource_path?: Maybe<Scalars['String']>;
  royalty_logs?: Maybe<Royalty_Log_Arr_Rel_Insert_Input>;
  scale?: Maybe<Scale_Obj_Rel_Insert_Input>;
  scale_id?: Maybe<Scalars['Int']>;
  song_catalog_item?: Maybe<Song_Catalog_Item_Arr_Rel_Insert_Input>;
  song_grades?: Maybe<Song_Grade_Arr_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorites?: Maybe<Teacher_Song_Favorite_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  tracks?: Maybe<Track_Arr_Rel_Insert_Input>;
  writers?: Maybe<Scalars['String']>;
};

/** columns and relationships of "song_keyword" */
export type Song_Keyword = {
  __typename?: 'song_keyword';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id: Scalars['Int'];
  song_id: Scalars['Int'];
  song_keyword_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "song_keyword" */
export type Song_Keyword_Aggregate = {
  __typename?: 'song_keyword_aggregate';
  aggregate?: Maybe<Song_Keyword_Aggregate_Fields>;
  nodes: Array<Song_Keyword>;
};

/** aggregate fields of "song_keyword" */
export type Song_Keyword_Aggregate_Fields = {
  __typename?: 'song_keyword_aggregate_fields';
  avg?: Maybe<Song_Keyword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Song_Keyword_Max_Fields>;
  min?: Maybe<Song_Keyword_Min_Fields>;
  stddev?: Maybe<Song_Keyword_Stddev_Fields>;
  stddev_pop?: Maybe<Song_Keyword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Song_Keyword_Stddev_Samp_Fields>;
  sum?: Maybe<Song_Keyword_Sum_Fields>;
  var_pop?: Maybe<Song_Keyword_Var_Pop_Fields>;
  var_samp?: Maybe<Song_Keyword_Var_Samp_Fields>;
  variance?: Maybe<Song_Keyword_Variance_Fields>;
};


/** aggregate fields of "song_keyword" */
export type Song_Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Song_Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Song_Keyword_Avg_Fields = {
  __typename?: 'song_keyword_avg_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "song_keyword". All fields are combined with a logical 'AND'. */
export type Song_Keyword_Bool_Exp = {
  _and?: Maybe<Array<Song_Keyword_Bool_Exp>>;
  _not?: Maybe<Song_Keyword_Bool_Exp>;
  _or?: Maybe<Array<Song_Keyword_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  keyword_id?: Maybe<Int_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  song_keyword_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "song_keyword" */
export enum Song_Keyword_Constraint {
  /** unique or primary key constraint */
  SongKeywordPkey = 'song_keyword_pkey',
  /** unique or primary key constraint */
  SongKeywordSongIdKeywordIdKey = 'song_keyword_song_id_keyword_id_key'
}

/** input type for incrementing numeric columns in table "song_keyword" */
export type Song_Keyword_Inc_Input = {
  keyword_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  song_keyword_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "song_keyword" */
export type Song_Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  song_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Song_Keyword_Max_Fields = {
  __typename?: 'song_keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  song_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Song_Keyword_Min_Fields = {
  __typename?: 'song_keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  song_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "song_keyword" */
export type Song_Keyword_Mutation_Response = {
  __typename?: 'song_keyword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Song_Keyword>;
};

/** on_conflict condition type for table "song_keyword" */
export type Song_Keyword_On_Conflict = {
  constraint: Song_Keyword_Constraint;
  update_columns?: Array<Song_Keyword_Update_Column>;
  where?: Maybe<Song_Keyword_Bool_Exp>;
};

/** Ordering options when selecting data from "song_keyword". */
export type Song_Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  song_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: song_keyword */
export type Song_Keyword_Pk_Columns_Input = {
  song_keyword_id: Scalars['Int'];
};

/** select columns of table "song_keyword" */
export enum Song_Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  SongKeywordId = 'song_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "song_keyword" */
export type Song_Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  song_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Song_Keyword_Stddev_Fields = {
  __typename?: 'song_keyword_stddev_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Song_Keyword_Stddev_Pop_Fields = {
  __typename?: 'song_keyword_stddev_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Song_Keyword_Stddev_Samp_Fields = {
  __typename?: 'song_keyword_stddev_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Song_Keyword_Sum_Fields = {
  __typename?: 'song_keyword_sum_fields';
  keyword_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  song_keyword_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "song_keyword" */
export enum Song_Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  SongKeywordId = 'song_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Song_Keyword_Var_Pop_Fields = {
  __typename?: 'song_keyword_var_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Song_Keyword_Var_Samp_Fields = {
  __typename?: 'song_keyword_var_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Song_Keyword_Variance_Fields = {
  __typename?: 'song_keyword_variance_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  song_keyword_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Song_Max_Fields = {
  __typename?: 'song_max_fields';
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  image_path?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  writers?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Song_Min_Fields = {
  __typename?: 'song_min_fields';
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  image_path?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  writers?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "song" */
export type Song_Mutation_Response = {
  __typename?: 'song_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Song>;
};

/** input type for inserting object relation for remote table "song" */
export type Song_Obj_Rel_Insert_Input = {
  data: Song_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Song_On_Conflict>;
};

/** on_conflict condition type for table "song" */
export type Song_On_Conflict = {
  constraint: Song_Constraint;
  update_columns?: Array<Song_Update_Column>;
  where?: Maybe<Song_Bool_Exp>;
};

/** Ordering options when selecting data from "song". */
export type Song_Order_By = {
  active?: Maybe<Order_By>;
  artist?: Maybe<Artist_Order_By>;
  artist_id?: Maybe<Order_By>;
  class_songs_aggregate?: Maybe<Class_Song_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  featured?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  lyrics?: Maybe<Order_By>;
  publisherControl?: Maybe<Publisher_Control_Order_By>;
  recommended?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  royalty_logs_aggregate?: Maybe<Royalty_Log_Aggregate_Order_By>;
  scale?: Maybe<Scale_Order_By>;
  scale_id?: Maybe<Order_By>;
  song_catalog_item_aggregate?: Maybe<Song_Catalog_Item_Aggregate_Order_By>;
  song_grades_aggregate?: Maybe<Song_Grade_Aggregate_Order_By>;
  song_id?: Maybe<Order_By>;
  song_maturity_level?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorites_aggregate?: Maybe<Teacher_Song_Favorite_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  tracks_aggregate?: Maybe<Track_Aggregate_Order_By>;
  writers?: Maybe<Order_By>;
};

/** primary key columns input for table: song */
export type Song_Pk_Columns_Input = {
  song_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Song_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
  lyrics?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "song" */
export enum Song_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Key = 'key',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  Lyrics = 'lyrics',
  /** column name */
  Recommended = 'recommended',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  ScaleId = 'scale_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  Writers = 'writers'
}

/** input type for updating data in table "song" */
export type Song_Set_Input = {
  /** Used to show/hide the song on student and teacher side */
  active?: Maybe<Scalars['Boolean']>;
  artist_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  /** Used to show recommended on Teacher and Student side */
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  lyrics?: Maybe<Scalars['jsonb']>;
  recommended?: Maybe<Scalars['Boolean']>;
  resource_path?: Maybe<Scalars['String']>;
  scale_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  writers?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Song_Stddev_Fields = {
  __typename?: 'song_stddev_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Song_Stddev_Pop_Fields = {
  __typename?: 'song_stddev_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Song_Stddev_Samp_Fields = {
  __typename?: 'song_stddev_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Song_Sum_Fields = {
  __typename?: 'song_sum_fields';
  artist_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  scale_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "song" */
export enum Song_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  Key = 'key',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  Lyrics = 'lyrics',
  /** column name */
  Recommended = 'recommended',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  ScaleId = 'scale_id',
  /** column name */
  SongId = 'song_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  Writers = 'writers'
}

/** aggregate var_pop on columns */
export type Song_Var_Pop_Fields = {
  __typename?: 'song_var_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Song_Var_Samp_Fields = {
  __typename?: 'song_var_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Song_Variance_Fields = {
  __typename?: 'song_variance_fields';
  artist_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  scale_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "standard" */
export type Standard = {
  __typename?: 'standard';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  standard_subcategories: Array<Standard_Subcategory>;
  /** An aggregate relationship */
  standard_subcategories_aggregate: Standard_Subcategory_Aggregate;
};


/** columns and relationships of "standard" */
export type StandardStandard_SubcategoriesArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};


/** columns and relationships of "standard" */
export type StandardStandard_Subcategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};

/** aggregated selection of "standard" */
export type Standard_Aggregate = {
  __typename?: 'standard_aggregate';
  aggregate?: Maybe<Standard_Aggregate_Fields>;
  nodes: Array<Standard>;
};

/** aggregate fields of "standard" */
export type Standard_Aggregate_Fields = {
  __typename?: 'standard_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Standard_Max_Fields>;
  min?: Maybe<Standard_Min_Fields>;
};


/** aggregate fields of "standard" */
export type Standard_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Standard_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "standard". All fields are combined with a logical 'AND'. */
export type Standard_Bool_Exp = {
  _and?: Maybe<Array<Standard_Bool_Exp>>;
  _not?: Maybe<Standard_Bool_Exp>;
  _or?: Maybe<Array<Standard_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  standard_subcategories?: Maybe<Standard_Subcategory_Bool_Exp>;
};

/** unique or primary key constraints on table "standard" */
export enum Standard_Constraint {
  /** unique or primary key constraint */
  StandardPkey = 'standard_pkey'
}

export enum Standard_Enum {
  /** Arts Media and Entertainment Industry Skills */
  Ameis = 'AMEIS',
  /** CASEL Framework */
  Cf = 'CF',
  /** National Core Arts Standards */
  Ncas = 'NCAS'
}

/** Boolean expression to compare columns of type "standard_enum". All fields are combined with logical 'AND'. */
export type Standard_Enum_Comparison_Exp = {
  _eq?: Maybe<Standard_Enum>;
  _in?: Maybe<Array<Standard_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Standard_Enum>;
  _nin?: Maybe<Array<Standard_Enum>>;
};

/** input type for inserting data into table "standard" */
export type Standard_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standard_subcategories?: Maybe<Standard_Subcategory_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Standard_Max_Fields = {
  __typename?: 'standard_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Standard_Min_Fields = {
  __typename?: 'standard_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "standard" */
export type Standard_Mutation_Response = {
  __typename?: 'standard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Standard>;
};

/** input type for inserting object relation for remote table "standard" */
export type Standard_Obj_Rel_Insert_Input = {
  data: Standard_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Standard_On_Conflict>;
};

/** on_conflict condition type for table "standard" */
export type Standard_On_Conflict = {
  constraint: Standard_Constraint;
  update_columns?: Array<Standard_Update_Column>;
  where?: Maybe<Standard_Bool_Exp>;
};

/** columns and relationships of "standard_option" */
export type Standard_Option = {
  __typename?: 'standard_option';
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  grade?: Maybe<Grade>;
  level?: Maybe<Scalars['Int']>;
  standar_option_id: Scalars['Int'];
  /** An object relationship */
  standar_subcategory: Standard_Subcategory;
  standar_subcategory_id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "standard_option" */
export type Standard_Option_Aggregate = {
  __typename?: 'standard_option_aggregate';
  aggregate?: Maybe<Standard_Option_Aggregate_Fields>;
  nodes: Array<Standard_Option>;
};

/** aggregate fields of "standard_option" */
export type Standard_Option_Aggregate_Fields = {
  __typename?: 'standard_option_aggregate_fields';
  avg?: Maybe<Standard_Option_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Standard_Option_Max_Fields>;
  min?: Maybe<Standard_Option_Min_Fields>;
  stddev?: Maybe<Standard_Option_Stddev_Fields>;
  stddev_pop?: Maybe<Standard_Option_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Standard_Option_Stddev_Samp_Fields>;
  sum?: Maybe<Standard_Option_Sum_Fields>;
  var_pop?: Maybe<Standard_Option_Var_Pop_Fields>;
  var_samp?: Maybe<Standard_Option_Var_Samp_Fields>;
  variance?: Maybe<Standard_Option_Variance_Fields>;
};


/** aggregate fields of "standard_option" */
export type Standard_Option_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Standard_Option_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "standard_option" */
export type Standard_Option_Aggregate_Order_By = {
  avg?: Maybe<Standard_Option_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Standard_Option_Max_Order_By>;
  min?: Maybe<Standard_Option_Min_Order_By>;
  stddev?: Maybe<Standard_Option_Stddev_Order_By>;
  stddev_pop?: Maybe<Standard_Option_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Standard_Option_Stddev_Samp_Order_By>;
  sum?: Maybe<Standard_Option_Sum_Order_By>;
  var_pop?: Maybe<Standard_Option_Var_Pop_Order_By>;
  var_samp?: Maybe<Standard_Option_Var_Samp_Order_By>;
  variance?: Maybe<Standard_Option_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "standard_option" */
export type Standard_Option_Arr_Rel_Insert_Input = {
  data: Array<Standard_Option_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Standard_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Standard_Option_Avg_Fields = {
  __typename?: 'standard_option_avg_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "standard_option" */
export type Standard_Option_Avg_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "standard_option". All fields are combined with a logical 'AND'. */
export type Standard_Option_Bool_Exp = {
  _and?: Maybe<Array<Standard_Option_Bool_Exp>>;
  _not?: Maybe<Standard_Option_Bool_Exp>;
  _or?: Maybe<Array<Standard_Option_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  grade?: Maybe<Grade_Bool_Exp>;
  level?: Maybe<Int_Comparison_Exp>;
  standar_option_id?: Maybe<Int_Comparison_Exp>;
  standar_subcategory?: Maybe<Standard_Subcategory_Bool_Exp>;
  standar_subcategory_id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "standard_option" */
export enum Standard_Option_Constraint {
  /** unique or primary key constraint */
  StandarOptionPkey = 'standar_option_pkey'
}

/** input type for incrementing numeric columns in table "standard_option" */
export type Standard_Option_Inc_Input = {
  level?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "standard_option" */
export type Standard_Option_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade_Obj_Rel_Insert_Input>;
  level?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standar_subcategory?: Maybe<Standard_Subcategory_Obj_Rel_Insert_Input>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Standard_Option_Max_Fields = {
  __typename?: 'standard_option_max_fields';
  description?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "standard_option" */
export type Standard_Option_Max_Order_By = {
  description?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Standard_Option_Min_Fields = {
  __typename?: 'standard_option_min_fields';
  description?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "standard_option" */
export type Standard_Option_Min_Order_By = {
  description?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "standard_option" */
export type Standard_Option_Mutation_Response = {
  __typename?: 'standard_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Standard_Option>;
};

/** input type for inserting object relation for remote table "standard_option" */
export type Standard_Option_Obj_Rel_Insert_Input = {
  data: Standard_Option_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Standard_Option_On_Conflict>;
};

/** on_conflict condition type for table "standard_option" */
export type Standard_Option_On_Conflict = {
  constraint: Standard_Option_Constraint;
  update_columns?: Array<Standard_Option_Update_Column>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "standard_option". */
export type Standard_Option_Order_By = {
  description?: Maybe<Order_By>;
  grade?: Maybe<Grade_Order_By>;
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory?: Maybe<Standard_Subcategory_Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: standard_option */
export type Standard_Option_Pk_Columns_Input = {
  standar_option_id: Scalars['Int'];
};

/** select columns of table "standard_option" */
export enum Standard_Option_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Level = 'level',
  /** column name */
  StandarOptionId = 'standar_option_id',
  /** column name */
  StandarSubcategoryId = 'standar_subcategory_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "standard_option" */
export type Standard_Option_Set_Input = {
  description?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Standard_Option_Stddev_Fields = {
  __typename?: 'standard_option_stddev_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "standard_option" */
export type Standard_Option_Stddev_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Standard_Option_Stddev_Pop_Fields = {
  __typename?: 'standard_option_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "standard_option" */
export type Standard_Option_Stddev_Pop_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Standard_Option_Stddev_Samp_Fields = {
  __typename?: 'standard_option_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "standard_option" */
export type Standard_Option_Stddev_Samp_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Standard_Option_Sum_Fields = {
  __typename?: 'standard_option_sum_fields';
  level?: Maybe<Scalars['Int']>;
  standar_option_id?: Maybe<Scalars['Int']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "standard_option" */
export type Standard_Option_Sum_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** update columns of table "standard_option" */
export enum Standard_Option_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Level = 'level',
  /** column name */
  StandarOptionId = 'standar_option_id',
  /** column name */
  StandarSubcategoryId = 'standar_subcategory_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Standard_Option_Var_Pop_Fields = {
  __typename?: 'standard_option_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "standard_option" */
export type Standard_Option_Var_Pop_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Standard_Option_Var_Samp_Fields = {
  __typename?: 'standard_option_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "standard_option" */
export type Standard_Option_Var_Samp_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Standard_Option_Variance_Fields = {
  __typename?: 'standard_option_variance_fields';
  level?: Maybe<Scalars['Float']>;
  standar_option_id?: Maybe<Scalars['Float']>;
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "standard_option" */
export type Standard_Option_Variance_Order_By = {
  level?: Maybe<Order_By>;
  standar_option_id?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "standard". */
export type Standard_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  standard_subcategories_aggregate?: Maybe<Standard_Subcategory_Aggregate_Order_By>;
};

/** primary key columns input for table: standard */
export type Standard_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "standard" */
export enum Standard_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "standard" */
export type Standard_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "standard_subcategory" */
export type Standard_Subcategory = {
  __typename?: 'standard_subcategory';
  category_standard: Category_Standard_Enum;
  /** An object relationship */
  category_standards: Category_Standard;
  description?: Maybe<Scalars['String']>;
  enduring_understanding?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  standar_options: Array<Standard_Option>;
  /** An aggregate relationship */
  standar_options_aggregate: Standard_Option_Aggregate;
  standar_subcategory_id: Scalars['Int'];
  standard: Standard_Enum;
  /** An object relationship */
  standards: Standard;
};


/** columns and relationships of "standard_subcategory" */
export type Standard_SubcategoryStandar_OptionsArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};


/** columns and relationships of "standard_subcategory" */
export type Standard_SubcategoryStandar_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};

/** aggregated selection of "standard_subcategory" */
export type Standard_Subcategory_Aggregate = {
  __typename?: 'standard_subcategory_aggregate';
  aggregate?: Maybe<Standard_Subcategory_Aggregate_Fields>;
  nodes: Array<Standard_Subcategory>;
};

/** aggregate fields of "standard_subcategory" */
export type Standard_Subcategory_Aggregate_Fields = {
  __typename?: 'standard_subcategory_aggregate_fields';
  avg?: Maybe<Standard_Subcategory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Standard_Subcategory_Max_Fields>;
  min?: Maybe<Standard_Subcategory_Min_Fields>;
  stddev?: Maybe<Standard_Subcategory_Stddev_Fields>;
  stddev_pop?: Maybe<Standard_Subcategory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Standard_Subcategory_Stddev_Samp_Fields>;
  sum?: Maybe<Standard_Subcategory_Sum_Fields>;
  var_pop?: Maybe<Standard_Subcategory_Var_Pop_Fields>;
  var_samp?: Maybe<Standard_Subcategory_Var_Samp_Fields>;
  variance?: Maybe<Standard_Subcategory_Variance_Fields>;
};


/** aggregate fields of "standard_subcategory" */
export type Standard_Subcategory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "standard_subcategory" */
export type Standard_Subcategory_Aggregate_Order_By = {
  avg?: Maybe<Standard_Subcategory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Standard_Subcategory_Max_Order_By>;
  min?: Maybe<Standard_Subcategory_Min_Order_By>;
  stddev?: Maybe<Standard_Subcategory_Stddev_Order_By>;
  stddev_pop?: Maybe<Standard_Subcategory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Standard_Subcategory_Stddev_Samp_Order_By>;
  sum?: Maybe<Standard_Subcategory_Sum_Order_By>;
  var_pop?: Maybe<Standard_Subcategory_Var_Pop_Order_By>;
  var_samp?: Maybe<Standard_Subcategory_Var_Samp_Order_By>;
  variance?: Maybe<Standard_Subcategory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "standard_subcategory" */
export type Standard_Subcategory_Arr_Rel_Insert_Input = {
  data: Array<Standard_Subcategory_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Standard_Subcategory_On_Conflict>;
};

/** aggregate avg on columns */
export type Standard_Subcategory_Avg_Fields = {
  __typename?: 'standard_subcategory_avg_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Avg_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "standard_subcategory". All fields are combined with a logical 'AND'. */
export type Standard_Subcategory_Bool_Exp = {
  _and?: Maybe<Array<Standard_Subcategory_Bool_Exp>>;
  _not?: Maybe<Standard_Subcategory_Bool_Exp>;
  _or?: Maybe<Array<Standard_Subcategory_Bool_Exp>>;
  category_standard?: Maybe<Category_Standard_Enum_Comparison_Exp>;
  category_standards?: Maybe<Category_Standard_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  enduring_understanding?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  standar_options?: Maybe<Standard_Option_Bool_Exp>;
  standar_subcategory_id?: Maybe<Int_Comparison_Exp>;
  standard?: Maybe<Standard_Enum_Comparison_Exp>;
  standards?: Maybe<Standard_Bool_Exp>;
};

/** unique or primary key constraints on table "standard_subcategory" */
export enum Standard_Subcategory_Constraint {
  /** unique or primary key constraint */
  StandarSubcategoryPkey = 'standar_subcategory_pkey'
}

/** input type for incrementing numeric columns in table "standard_subcategory" */
export type Standard_Subcategory_Inc_Input = {
  standar_subcategory_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "standard_subcategory" */
export type Standard_Subcategory_Insert_Input = {
  category_standard?: Maybe<Category_Standard_Enum>;
  category_standards?: Maybe<Category_Standard_Obj_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  enduring_understanding?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standar_options?: Maybe<Standard_Option_Arr_Rel_Insert_Input>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
  standard?: Maybe<Standard_Enum>;
  standards?: Maybe<Standard_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Standard_Subcategory_Max_Fields = {
  __typename?: 'standard_subcategory_max_fields';
  description?: Maybe<Scalars['String']>;
  enduring_understanding?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Max_Order_By = {
  description?: Maybe<Order_By>;
  enduring_understanding?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Standard_Subcategory_Min_Fields = {
  __typename?: 'standard_subcategory_min_fields';
  description?: Maybe<Scalars['String']>;
  enduring_understanding?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Min_Order_By = {
  description?: Maybe<Order_By>;
  enduring_understanding?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "standard_subcategory" */
export type Standard_Subcategory_Mutation_Response = {
  __typename?: 'standard_subcategory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Standard_Subcategory>;
};

/** input type for inserting object relation for remote table "standard_subcategory" */
export type Standard_Subcategory_Obj_Rel_Insert_Input = {
  data: Standard_Subcategory_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Standard_Subcategory_On_Conflict>;
};

/** on_conflict condition type for table "standard_subcategory" */
export type Standard_Subcategory_On_Conflict = {
  constraint: Standard_Subcategory_Constraint;
  update_columns?: Array<Standard_Subcategory_Update_Column>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};

/** Ordering options when selecting data from "standard_subcategory". */
export type Standard_Subcategory_Order_By = {
  category_standard?: Maybe<Order_By>;
  category_standards?: Maybe<Category_Standard_Order_By>;
  description?: Maybe<Order_By>;
  enduring_understanding?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  standar_options_aggregate?: Maybe<Standard_Option_Aggregate_Order_By>;
  standar_subcategory_id?: Maybe<Order_By>;
  standard?: Maybe<Order_By>;
  standards?: Maybe<Standard_Order_By>;
};

/** primary key columns input for table: standard_subcategory */
export type Standard_Subcategory_Pk_Columns_Input = {
  standar_subcategory_id: Scalars['Int'];
};

/** select columns of table "standard_subcategory" */
export enum Standard_Subcategory_Select_Column {
  /** column name */
  CategoryStandard = 'category_standard',
  /** column name */
  Description = 'description',
  /** column name */
  EnduringUnderstanding = 'enduring_understanding',
  /** column name */
  Name = 'name',
  /** column name */
  StandarSubcategoryId = 'standar_subcategory_id',
  /** column name */
  Standard = 'standard'
}

/** input type for updating data in table "standard_subcategory" */
export type Standard_Subcategory_Set_Input = {
  category_standard?: Maybe<Category_Standard_Enum>;
  description?: Maybe<Scalars['String']>;
  enduring_understanding?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  standar_subcategory_id?: Maybe<Scalars['Int']>;
  standard?: Maybe<Standard_Enum>;
};

/** aggregate stddev on columns */
export type Standard_Subcategory_Stddev_Fields = {
  __typename?: 'standard_subcategory_stddev_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Stddev_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Standard_Subcategory_Stddev_Pop_Fields = {
  __typename?: 'standard_subcategory_stddev_pop_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Stddev_Pop_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Standard_Subcategory_Stddev_Samp_Fields = {
  __typename?: 'standard_subcategory_stddev_samp_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Stddev_Samp_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Standard_Subcategory_Sum_Fields = {
  __typename?: 'standard_subcategory_sum_fields';
  standar_subcategory_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Sum_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** update columns of table "standard_subcategory" */
export enum Standard_Subcategory_Update_Column {
  /** column name */
  CategoryStandard = 'category_standard',
  /** column name */
  Description = 'description',
  /** column name */
  EnduringUnderstanding = 'enduring_understanding',
  /** column name */
  Name = 'name',
  /** column name */
  StandarSubcategoryId = 'standar_subcategory_id',
  /** column name */
  Standard = 'standard'
}

/** aggregate var_pop on columns */
export type Standard_Subcategory_Var_Pop_Fields = {
  __typename?: 'standard_subcategory_var_pop_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Var_Pop_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Standard_Subcategory_Var_Samp_Fields = {
  __typename?: 'standard_subcategory_var_samp_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Var_Samp_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Standard_Subcategory_Variance_Fields = {
  __typename?: 'standard_subcategory_variance_fields';
  standar_subcategory_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "standard_subcategory" */
export type Standard_Subcategory_Variance_Order_By = {
  standar_subcategory_id?: Maybe<Order_By>;
};

/** update columns of table "standard" */
export enum Standard_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "student" */
export type Student = {
  __typename?: 'student';
  activate_mail_sent: Scalars['Boolean'];
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  avatar_selected: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  class_students: Array<Class_Student>;
  /** An aggregate relationship */
  class_students_aggregate: Class_Student_Aggregate;
  /** deprecated */
  clever_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cover_image_path: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  district?: Maybe<Student_District>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  /** A computed field, executes function "student_full_name" */
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  /** An object relationship */
  grades?: Maybe<Student_Grade>;
  graduation_year?: Maybe<Scalars['Int']>;
  has_lti_integration?: Maybe<Scalars['Boolean']>;
  home_language?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_id?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_name?: Maybe<Integration_Name_Enum>;
  is_active: Scalars['Boolean'];
  last_name: Scalars['String'];
  middle_name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  race?: Maybe<Scalars['String']>;
  /** An object relationship */
  school?: Maybe<School>;
  school_id?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** An array relationship */
  student_badge_events: Array<Student_Badge_Event>;
  /** An aggregate relationship */
  student_badge_events_aggregate: Student_Badge_Event_Aggregate;
  /** An object relationship */
  student_badge_summary?: Maybe<Student_Badge_Resume>;
  /** An array relationship */
  student_badges: Array<Student_Badge>;
  /** An aggregate relationship */
  student_badges_aggregate: Student_Badge_Aggregate;
  student_id: Scalars['Int'];
  /** An array relationship */
  student_integrations: Array<Student_Integration>;
  /** An aggregate relationship */
  student_integrations_aggregate: Student_Integration_Aggregate;
  /** An array relationship */
  student_productivity_daily: Array<Student_Productivity_Daily>;
  /** An aggregate relationship */
  student_productivity_daily_aggregate: Student_Productivity_Daily_Aggregate;
  /** An array relationship */
  student_productivity_monthly: Array<Student_Productivity_Monthly>;
  /** An aggregate relationship */
  student_productivity_monthly_aggregate: Student_Productivity_Monthly_Aggregate;
  /** An array relationship */
  student_productivity_yearly: Array<Student_Productivity_Yearly>;
  /** An aggregate relationship */
  student_productivity_yearly_aggregate: Student_Productivity_Yearly_Aggregate;
  /** An object relationship */
  student_setting?: Maybe<Student_Setting>;
  unweighted_gpa?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  weighted_gpa?: Maybe<Scalars['String']>;
  /** An array relationship */
  wurrly: Array<Wurrly>;
  /** An aggregate relationship */
  wurrly_aggregate: Wurrly_Aggregate;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
  zip?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "student" */
export type StudentClass_StudentsArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentClass_Students_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Badge_EventsArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Event_Order_By>>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Badge_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Event_Order_By>>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_BadgesArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Order_By>>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Badges_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Order_By>>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_IntegrationsArgs = {
  distinct_on?: Maybe<Array<Student_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Integration_Order_By>>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Integrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Integration_Order_By>>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Productivity_DailyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Daily_Order_By>>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Productivity_Daily_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Daily_Order_By>>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Productivity_MonthlyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Monthly_Order_By>>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Productivity_Monthly_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Monthly_Order_By>>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Productivity_YearlyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Yearly_Order_By>>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentStudent_Productivity_Yearly_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Yearly_Order_By>>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentWurrlyArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};


/** columns and relationships of "student" */
export type StudentWurrly_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};

/** columns and relationships of "student_activity" */
export type Student_Activity = {
  __typename?: 'student_activity';
  /** An object relationship */
  assignment?: Maybe<Assignment>;
  assignment_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  past_due: Scalars['Boolean'];
  song_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  student: Student;
  student_activity_id: Scalars['Int'];
  student_activity_type: Student_Activity_Type_Enum;
  student_id: Scalars['Int'];
  tip_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** this is the URL at which the description should point to */
  url?: Maybe<Scalars['String']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "student_activity" */
export type Student_Activity_Aggregate = {
  __typename?: 'student_activity_aggregate';
  aggregate?: Maybe<Student_Activity_Aggregate_Fields>;
  nodes: Array<Student_Activity>;
};

/** aggregate fields of "student_activity" */
export type Student_Activity_Aggregate_Fields = {
  __typename?: 'student_activity_aggregate_fields';
  avg?: Maybe<Student_Activity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Activity_Max_Fields>;
  min?: Maybe<Student_Activity_Min_Fields>;
  stddev?: Maybe<Student_Activity_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Activity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Activity_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Activity_Sum_Fields>;
  var_pop?: Maybe<Student_Activity_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Activity_Var_Samp_Fields>;
  variance?: Maybe<Student_Activity_Variance_Fields>;
};


/** aggregate fields of "student_activity" */
export type Student_Activity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Activity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Activity_Avg_Fields = {
  __typename?: 'student_activity_avg_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_activity". All fields are combined with a logical 'AND'. */
export type Student_Activity_Bool_Exp = {
  _and?: Maybe<Array<Student_Activity_Bool_Exp>>;
  _not?: Maybe<Student_Activity_Bool_Exp>;
  _or?: Maybe<Array<Student_Activity_Bool_Exp>>;
  assignment?: Maybe<Assignment_Bool_Exp>;
  assignment_id?: Maybe<Int_Comparison_Exp>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  past_due?: Maybe<Boolean_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_activity_id?: Maybe<Int_Comparison_Exp>;
  student_activity_type?: Maybe<Student_Activity_Type_Enum_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_activity" */
export enum Student_Activity_Constraint {
  /** unique or primary key constraint */
  StudentActivityPkey = 'student_activity_pkey'
}

/** input type for incrementing numeric columns in table "student_activity" */
export type Student_Activity_Inc_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  student_activity_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_activity" */
export type Student_Activity_Insert_Input = {
  assignment?: Maybe<Assignment_Obj_Rel_Insert_Input>;
  assignment_id?: Maybe<Scalars['Int']>;
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  past_due?: Maybe<Scalars['Boolean']>;
  song_id?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_activity_id?: Maybe<Scalars['Int']>;
  student_activity_type?: Maybe<Student_Activity_Type_Enum>;
  student_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this is the URL at which the description should point to */
  url?: Maybe<Scalars['String']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Activity_Max_Fields = {
  __typename?: 'student_activity_max_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  song_id?: Maybe<Scalars['Int']>;
  student_activity_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this is the URL at which the description should point to */
  url?: Maybe<Scalars['String']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Student_Activity_Min_Fields = {
  __typename?: 'student_activity_min_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  song_id?: Maybe<Scalars['Int']>;
  student_activity_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this is the URL at which the description should point to */
  url?: Maybe<Scalars['String']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "student_activity" */
export type Student_Activity_Mutation_Response = {
  __typename?: 'student_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Activity>;
};

/** on_conflict condition type for table "student_activity" */
export type Student_Activity_On_Conflict = {
  constraint: Student_Activity_Constraint;
  update_columns?: Array<Student_Activity_Update_Column>;
  where?: Maybe<Student_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "student_activity". */
export type Student_Activity_Order_By = {
  assignment?: Maybe<Assignment_Order_By>;
  assignment_id?: Maybe<Order_By>;
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  past_due?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_activity_id?: Maybe<Order_By>;
  student_activity_type?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_activity */
export type Student_Activity_Pk_Columns_Input = {
  student_activity_id: Scalars['Int'];
};

/** select columns of table "student_activity" */
export enum Student_Activity_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  PastDue = 'past_due',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StudentActivityId = 'student_activity_id',
  /** column name */
  StudentActivityType = 'student_activity_type',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** input type for updating data in table "student_activity" */
export type Student_Activity_Set_Input = {
  assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  past_due?: Maybe<Scalars['Boolean']>;
  song_id?: Maybe<Scalars['Int']>;
  student_activity_id?: Maybe<Scalars['Int']>;
  student_activity_type?: Maybe<Student_Activity_Type_Enum>;
  student_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** this is the URL at which the description should point to */
  url?: Maybe<Scalars['String']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Activity_Stddev_Fields = {
  __typename?: 'student_activity_stddev_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Activity_Stddev_Pop_Fields = {
  __typename?: 'student_activity_stddev_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Activity_Stddev_Samp_Fields = {
  __typename?: 'student_activity_stddev_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Activity_Sum_Fields = {
  __typename?: 'student_activity_sum_fields';
  assignment_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  student_activity_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "student_activity_type" */
export type Student_Activity_Type = {
  __typename?: 'student_activity_type';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "student_activity_type" */
export type Student_Activity_Type_Aggregate = {
  __typename?: 'student_activity_type_aggregate';
  aggregate?: Maybe<Student_Activity_Type_Aggregate_Fields>;
  nodes: Array<Student_Activity_Type>;
};

/** aggregate fields of "student_activity_type" */
export type Student_Activity_Type_Aggregate_Fields = {
  __typename?: 'student_activity_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Student_Activity_Type_Max_Fields>;
  min?: Maybe<Student_Activity_Type_Min_Fields>;
};


/** aggregate fields of "student_activity_type" */
export type Student_Activity_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Activity_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "student_activity_type". All fields are combined with a logical 'AND'. */
export type Student_Activity_Type_Bool_Exp = {
  _and?: Maybe<Array<Student_Activity_Type_Bool_Exp>>;
  _not?: Maybe<Student_Activity_Type_Bool_Exp>;
  _or?: Maybe<Array<Student_Activity_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_activity_type" */
export enum Student_Activity_Type_Constraint {
  /** unique or primary key constraint */
  StudentActivityTypePkey = 'student_activity_type_pkey'
}

export enum Student_Activity_Type_Enum {
  /** if outside of assignment */
  HasBeenPracticing = 'has_been_practicing',
  HasCompletedTheirAssignment = 'has_completed_their_assignment',
  HasCompletedTheirGoal = 'has_completed_their_goal',
  /** collect daily late submissions to group together thereafter */
  HasHandedInTheAssignment = 'has_handed_in_the_assignment',
  /** if outside of assignment */
  HasRecorded = 'has_recorded',
  /** if outside of assignment */
  HasWatchedVideo = 'has_watched_video'
}

/** Boolean expression to compare columns of type "student_activity_type_enum". All fields are combined with logical 'AND'. */
export type Student_Activity_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Student_Activity_Type_Enum>;
  _in?: Maybe<Array<Student_Activity_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Student_Activity_Type_Enum>;
  _nin?: Maybe<Array<Student_Activity_Type_Enum>>;
};

/** input type for inserting data into table "student_activity_type" */
export type Student_Activity_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Student_Activity_Type_Max_Fields = {
  __typename?: 'student_activity_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Student_Activity_Type_Min_Fields = {
  __typename?: 'student_activity_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "student_activity_type" */
export type Student_Activity_Type_Mutation_Response = {
  __typename?: 'student_activity_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Activity_Type>;
};

/** on_conflict condition type for table "student_activity_type" */
export type Student_Activity_Type_On_Conflict = {
  constraint: Student_Activity_Type_Constraint;
  update_columns?: Array<Student_Activity_Type_Update_Column>;
  where?: Maybe<Student_Activity_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "student_activity_type". */
export type Student_Activity_Type_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: student_activity_type */
export type Student_Activity_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "student_activity_type" */
export enum Student_Activity_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "student_activity_type" */
export type Student_Activity_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "student_activity_type" */
export enum Student_Activity_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** update columns of table "student_activity" */
export enum Student_Activity_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  PastDue = 'past_due',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StudentActivityId = 'student_activity_id',
  /** column name */
  StudentActivityType = 'student_activity_type',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** aggregate var_pop on columns */
export type Student_Activity_Var_Pop_Fields = {
  __typename?: 'student_activity_var_pop_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Activity_Var_Samp_Fields = {
  __typename?: 'student_activity_var_samp_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Activity_Variance_Fields = {
  __typename?: 'student_activity_variance_fields';
  assignment_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_activity_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "student" */
export type Student_Aggregate = {
  __typename?: 'student_aggregate';
  aggregate?: Maybe<Student_Aggregate_Fields>;
  nodes: Array<Student>;
};

/** aggregate fields of "student" */
export type Student_Aggregate_Fields = {
  __typename?: 'student_aggregate_fields';
  avg?: Maybe<Student_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Max_Fields>;
  min?: Maybe<Student_Min_Fields>;
  stddev?: Maybe<Student_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Sum_Fields>;
  var_pop?: Maybe<Student_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Var_Samp_Fields>;
  variance?: Maybe<Student_Variance_Fields>;
};


/** aggregate fields of "student" */
export type Student_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student" */
export type Student_Aggregate_Order_By = {
  avg?: Maybe<Student_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Max_Order_By>;
  min?: Maybe<Student_Min_Order_By>;
  stddev?: Maybe<Student_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Sum_Order_By>;
  var_pop?: Maybe<Student_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Var_Samp_Order_By>;
  variance?: Maybe<Student_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student" */
export type Student_Arr_Rel_Insert_Input = {
  data: Array<Student_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Avg_Fields = {
  __typename?: 'student_avg_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student" */
export type Student_Avg_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** columns and relationships of "student_badge" */
export type Student_Badge = {
  __typename?: 'student_badge';
  /** An array relationship */
  awarded_badge_events: Array<Awarded_Badge_Event>;
  /** An aggregate relationship */
  awarded_badge_events_aggregate: Awarded_Badge_Event_Aggregate;
  /** An object relationship */
  badge: Badge;
  badge_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  student: Student;
  student_badge_id: Scalars['Int'];
  student_id: Scalars['Int'];
};


/** columns and relationships of "student_badge" */
export type Student_BadgeAwarded_Badge_EventsArgs = {
  distinct_on?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Awarded_Badge_Event_Order_By>>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};


/** columns and relationships of "student_badge" */
export type Student_BadgeAwarded_Badge_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Awarded_Badge_Event_Order_By>>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};

/** aggregated selection of "student_badge" */
export type Student_Badge_Aggregate = {
  __typename?: 'student_badge_aggregate';
  aggregate?: Maybe<Student_Badge_Aggregate_Fields>;
  nodes: Array<Student_Badge>;
};

/** aggregate fields of "student_badge" */
export type Student_Badge_Aggregate_Fields = {
  __typename?: 'student_badge_aggregate_fields';
  avg?: Maybe<Student_Badge_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Badge_Max_Fields>;
  min?: Maybe<Student_Badge_Min_Fields>;
  stddev?: Maybe<Student_Badge_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Badge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Badge_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Badge_Sum_Fields>;
  var_pop?: Maybe<Student_Badge_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Badge_Var_Samp_Fields>;
  variance?: Maybe<Student_Badge_Variance_Fields>;
};


/** aggregate fields of "student_badge" */
export type Student_Badge_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Badge_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_badge" */
export type Student_Badge_Aggregate_Order_By = {
  avg?: Maybe<Student_Badge_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Badge_Max_Order_By>;
  min?: Maybe<Student_Badge_Min_Order_By>;
  stddev?: Maybe<Student_Badge_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Badge_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Badge_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Badge_Sum_Order_By>;
  var_pop?: Maybe<Student_Badge_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Badge_Var_Samp_Order_By>;
  variance?: Maybe<Student_Badge_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_badge" */
export type Student_Badge_Arr_Rel_Insert_Input = {
  data: Array<Student_Badge_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_Badge_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Badge_Avg_Fields = {
  __typename?: 'student_badge_avg_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_badge" */
export type Student_Badge_Avg_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_badge". All fields are combined with a logical 'AND'. */
export type Student_Badge_Bool_Exp = {
  _and?: Maybe<Array<Student_Badge_Bool_Exp>>;
  _not?: Maybe<Student_Badge_Bool_Exp>;
  _or?: Maybe<Array<Student_Badge_Bool_Exp>>;
  awarded_badge_events?: Maybe<Awarded_Badge_Event_Bool_Exp>;
  badge?: Maybe<Badge_Bool_Exp>;
  badge_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_badge_id?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_badge" */
export enum Student_Badge_Constraint {
  /** unique or primary key constraint */
  StudentBadgePkey = 'student_badge_pkey'
}

/** columns and relationships of "student_badge_event" */
export type Student_Badge_Event = {
  __typename?: 'student_badge_event';
  /** An object relationship */
  badge_event_name: Badge_Event_Name;
  /** An object relationship */
  challenge?: Maybe<Challenge>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  /** A computed field, executes function "student_badge_event_date" */
  date?: Maybe<Scalars['String']>;
  event_name: Badge_Event_Name_Enum;
  song_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  student: Student;
  student_badge_event_id: Scalars['Int'];
  student_id: Scalars['Int'];
  used: Scalars['Boolean'];
};

/** aggregated selection of "student_badge_event" */
export type Student_Badge_Event_Aggregate = {
  __typename?: 'student_badge_event_aggregate';
  aggregate?: Maybe<Student_Badge_Event_Aggregate_Fields>;
  nodes: Array<Student_Badge_Event>;
};

/** aggregate fields of "student_badge_event" */
export type Student_Badge_Event_Aggregate_Fields = {
  __typename?: 'student_badge_event_aggregate_fields';
  avg?: Maybe<Student_Badge_Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Badge_Event_Max_Fields>;
  min?: Maybe<Student_Badge_Event_Min_Fields>;
  stddev?: Maybe<Student_Badge_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Badge_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Badge_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Badge_Event_Sum_Fields>;
  var_pop?: Maybe<Student_Badge_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Badge_Event_Var_Samp_Fields>;
  variance?: Maybe<Student_Badge_Event_Variance_Fields>;
};


/** aggregate fields of "student_badge_event" */
export type Student_Badge_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_badge_event" */
export type Student_Badge_Event_Aggregate_Order_By = {
  avg?: Maybe<Student_Badge_Event_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Badge_Event_Max_Order_By>;
  min?: Maybe<Student_Badge_Event_Min_Order_By>;
  stddev?: Maybe<Student_Badge_Event_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Badge_Event_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Badge_Event_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Badge_Event_Sum_Order_By>;
  var_pop?: Maybe<Student_Badge_Event_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Badge_Event_Var_Samp_Order_By>;
  variance?: Maybe<Student_Badge_Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_badge_event" */
export type Student_Badge_Event_Arr_Rel_Insert_Input = {
  data: Array<Student_Badge_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_Badge_Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Badge_Event_Avg_Fields = {
  __typename?: 'student_badge_event_avg_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_badge_event" */
export type Student_Badge_Event_Avg_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_badge_event". All fields are combined with a logical 'AND'. */
export type Student_Badge_Event_Bool_Exp = {
  _and?: Maybe<Array<Student_Badge_Event_Bool_Exp>>;
  _not?: Maybe<Student_Badge_Event_Bool_Exp>;
  _or?: Maybe<Array<Student_Badge_Event_Bool_Exp>>;
  badge_event_name?: Maybe<Badge_Event_Name_Bool_Exp>;
  challenge?: Maybe<Challenge_Bool_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date?: Maybe<String_Comparison_Exp>;
  event_name?: Maybe<Badge_Event_Name_Enum_Comparison_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_badge_event_id?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  used?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_badge_event" */
export enum Student_Badge_Event_Constraint {
  /** unique or primary key constraint */
  StudentBadgeEventPkey = 'student_badge_event_pkey'
}

/** input type for incrementing numeric columns in table "student_badge_event" */
export type Student_Badge_Event_Inc_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_badge_event" */
export type Student_Badge_Event_Insert_Input = {
  badge_event_name?: Maybe<Badge_Event_Name_Obj_Rel_Insert_Input>;
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Badge_Event_Name_Enum>;
  song_id?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Student_Badge_Event_Max_Fields = {
  __typename?: 'student_badge_event_max_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student_badge_event" */
export type Student_Badge_Event_Max_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Badge_Event_Min_Fields = {
  __typename?: 'student_badge_event_min_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student_badge_event" */
export type Student_Badge_Event_Min_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_badge_event" */
export type Student_Badge_Event_Mutation_Response = {
  __typename?: 'student_badge_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Badge_Event>;
};

/** input type for inserting object relation for remote table "student_badge_event" */
export type Student_Badge_Event_Obj_Rel_Insert_Input = {
  data: Student_Badge_Event_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Student_Badge_Event_On_Conflict>;
};

/** on_conflict condition type for table "student_badge_event" */
export type Student_Badge_Event_On_Conflict = {
  constraint: Student_Badge_Event_Constraint;
  update_columns?: Array<Student_Badge_Event_Update_Column>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "student_badge_event". */
export type Student_Badge_Event_Order_By = {
  badge_event_name?: Maybe<Badge_Event_Name_Order_By>;
  challenge?: Maybe<Challenge_Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  event_name?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  used?: Maybe<Order_By>;
};

/** primary key columns input for table: student_badge_event */
export type Student_Badge_Event_Pk_Columns_Input = {
  student_badge_event_id: Scalars['Int'];
};

/** select columns of table "student_badge_event" */
export enum Student_Badge_Event_Select_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventName = 'event_name',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StudentBadgeEventId = 'student_badge_event_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  Used = 'used'
}

/** input type for updating data in table "student_badge_event" */
export type Student_Badge_Event_Set_Input = {
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_name?: Maybe<Badge_Event_Name_Enum>;
  song_id?: Maybe<Scalars['Int']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Student_Badge_Event_Stddev_Fields = {
  __typename?: 'student_badge_event_stddev_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_badge_event" */
export type Student_Badge_Event_Stddev_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Badge_Event_Stddev_Pop_Fields = {
  __typename?: 'student_badge_event_stddev_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_badge_event" */
export type Student_Badge_Event_Stddev_Pop_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Badge_Event_Stddev_Samp_Fields = {
  __typename?: 'student_badge_event_stddev_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_badge_event" */
export type Student_Badge_Event_Stddev_Samp_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Badge_Event_Sum_Fields = {
  __typename?: 'student_badge_event_sum_fields';
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  student_badge_event_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_badge_event" */
export type Student_Badge_Event_Sum_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** update columns of table "student_badge_event" */
export enum Student_Badge_Event_Update_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventName = 'event_name',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StudentBadgeEventId = 'student_badge_event_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  Used = 'used'
}

/** aggregate var_pop on columns */
export type Student_Badge_Event_Var_Pop_Fields = {
  __typename?: 'student_badge_event_var_pop_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_badge_event" */
export type Student_Badge_Event_Var_Pop_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Badge_Event_Var_Samp_Fields = {
  __typename?: 'student_badge_event_var_samp_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_badge_event" */
export type Student_Badge_Event_Var_Samp_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Badge_Event_Variance_Fields = {
  __typename?: 'student_badge_event_variance_fields';
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  student_badge_event_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_badge_event" */
export type Student_Badge_Event_Variance_Order_By = {
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  student_badge_event_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "student_badge" */
export type Student_Badge_Inc_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_badge" */
export type Student_Badge_Insert_Input = {
  awarded_badge_events?: Maybe<Awarded_Badge_Event_Arr_Rel_Insert_Input>;
  badge?: Maybe<Badge_Obj_Rel_Insert_Input>;
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_badge_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Badge_Max_Fields = {
  __typename?: 'student_badge_max_fields';
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student_badge" */
export type Student_Badge_Max_Order_By = {
  badge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Badge_Min_Fields = {
  __typename?: 'student_badge_min_fields';
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student_badge" */
export type Student_Badge_Min_Order_By = {
  badge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_badge" */
export type Student_Badge_Mutation_Response = {
  __typename?: 'student_badge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Badge>;
};

/** input type for inserting object relation for remote table "student_badge" */
export type Student_Badge_Obj_Rel_Insert_Input = {
  data: Student_Badge_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Student_Badge_On_Conflict>;
};

/** on_conflict condition type for table "student_badge" */
export type Student_Badge_On_Conflict = {
  constraint: Student_Badge_Constraint;
  update_columns?: Array<Student_Badge_Update_Column>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};

/** Ordering options when selecting data from "student_badge". */
export type Student_Badge_Order_By = {
  awarded_badge_events_aggregate?: Maybe<Awarded_Badge_Event_Aggregate_Order_By>;
  badge?: Maybe<Badge_Order_By>;
  badge_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_badge */
export type Student_Badge_Pk_Columns_Input = {
  student_badge_id: Scalars['Int'];
};

/** columns and relationships of "student_badge_resume" */
export type Student_Badge_Resume = {
  __typename?: 'student_badge_resume';
  badge_id?: Maybe<Scalars['Int']>;
  badge_total_points?: Maybe<Scalars['bigint']>;
  last_awarded?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  total_badges?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "student_badge_resume" */
export type Student_Badge_Resume_Aggregate = {
  __typename?: 'student_badge_resume_aggregate';
  aggregate?: Maybe<Student_Badge_Resume_Aggregate_Fields>;
  nodes: Array<Student_Badge_Resume>;
};

/** aggregate fields of "student_badge_resume" */
export type Student_Badge_Resume_Aggregate_Fields = {
  __typename?: 'student_badge_resume_aggregate_fields';
  avg?: Maybe<Student_Badge_Resume_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Badge_Resume_Max_Fields>;
  min?: Maybe<Student_Badge_Resume_Min_Fields>;
  stddev?: Maybe<Student_Badge_Resume_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Badge_Resume_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Badge_Resume_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Badge_Resume_Sum_Fields>;
  var_pop?: Maybe<Student_Badge_Resume_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Badge_Resume_Var_Samp_Fields>;
  variance?: Maybe<Student_Badge_Resume_Variance_Fields>;
};


/** aggregate fields of "student_badge_resume" */
export type Student_Badge_Resume_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Badge_Resume_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Badge_Resume_Avg_Fields = {
  __typename?: 'student_badge_resume_avg_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_badge_resume". All fields are combined with a logical 'AND'. */
export type Student_Badge_Resume_Bool_Exp = {
  _and?: Maybe<Array<Student_Badge_Resume_Bool_Exp>>;
  _not?: Maybe<Student_Badge_Resume_Bool_Exp>;
  _or?: Maybe<Array<Student_Badge_Resume_Bool_Exp>>;
  badge_id?: Maybe<Int_Comparison_Exp>;
  badge_total_points?: Maybe<Bigint_Comparison_Exp>;
  last_awarded?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  points?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  total_badges?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "student_badge_resume" */
export type Student_Badge_Resume_Insert_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  badge_total_points?: Maybe<Scalars['bigint']>;
  last_awarded?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  total_badges?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Student_Badge_Resume_Max_Fields = {
  __typename?: 'student_badge_resume_max_fields';
  badge_id?: Maybe<Scalars['Int']>;
  badge_total_points?: Maybe<Scalars['bigint']>;
  last_awarded?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  total_badges?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Student_Badge_Resume_Min_Fields = {
  __typename?: 'student_badge_resume_min_fields';
  badge_id?: Maybe<Scalars['Int']>;
  badge_total_points?: Maybe<Scalars['bigint']>;
  last_awarded?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  total_badges?: Maybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "student_badge_resume" */
export type Student_Badge_Resume_Obj_Rel_Insert_Input = {
  data: Student_Badge_Resume_Insert_Input;
};

/** Ordering options when selecting data from "student_badge_resume". */
export type Student_Badge_Resume_Order_By = {
  badge_id?: Maybe<Order_By>;
  badge_total_points?: Maybe<Order_By>;
  last_awarded?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  total_badges?: Maybe<Order_By>;
};

/** select columns of table "student_badge_resume" */
export enum Student_Badge_Resume_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  BadgeTotalPoints = 'badge_total_points',
  /** column name */
  LastAwarded = 'last_awarded',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  TotalBadges = 'total_badges'
}

/** aggregate stddev on columns */
export type Student_Badge_Resume_Stddev_Fields = {
  __typename?: 'student_badge_resume_stddev_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Badge_Resume_Stddev_Pop_Fields = {
  __typename?: 'student_badge_resume_stddev_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Badge_Resume_Stddev_Samp_Fields = {
  __typename?: 'student_badge_resume_stddev_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Badge_Resume_Sum_Fields = {
  __typename?: 'student_badge_resume_sum_fields';
  badge_id?: Maybe<Scalars['Int']>;
  badge_total_points?: Maybe<Scalars['bigint']>;
  points?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  total_badges?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Student_Badge_Resume_Var_Pop_Fields = {
  __typename?: 'student_badge_resume_var_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Badge_Resume_Var_Samp_Fields = {
  __typename?: 'student_badge_resume_var_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Badge_Resume_Variance_Fields = {
  __typename?: 'student_badge_resume_variance_fields';
  badge_id?: Maybe<Scalars['Float']>;
  badge_total_points?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  total_badges?: Maybe<Scalars['Float']>;
};

/** select columns of table "student_badge" */
export enum Student_Badge_Select_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentBadgeId = 'student_badge_id',
  /** column name */
  StudentId = 'student_id'
}

/** input type for updating data in table "student_badge" */
export type Student_Badge_Set_Input = {
  badge_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  student_badge_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Badge_Stddev_Fields = {
  __typename?: 'student_badge_stddev_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_badge" */
export type Student_Badge_Stddev_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Badge_Stddev_Pop_Fields = {
  __typename?: 'student_badge_stddev_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_badge" */
export type Student_Badge_Stddev_Pop_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Badge_Stddev_Samp_Fields = {
  __typename?: 'student_badge_stddev_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_badge" */
export type Student_Badge_Stddev_Samp_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Badge_Sum_Fields = {
  __typename?: 'student_badge_sum_fields';
  badge_id?: Maybe<Scalars['Int']>;
  student_badge_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_badge" */
export type Student_Badge_Sum_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** update columns of table "student_badge" */
export enum Student_Badge_Update_Column {
  /** column name */
  BadgeId = 'badge_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentBadgeId = 'student_badge_id',
  /** column name */
  StudentId = 'student_id'
}

/** aggregate var_pop on columns */
export type Student_Badge_Var_Pop_Fields = {
  __typename?: 'student_badge_var_pop_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_badge" */
export type Student_Badge_Var_Pop_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Badge_Var_Samp_Fields = {
  __typename?: 'student_badge_var_samp_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_badge" */
export type Student_Badge_Var_Samp_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Badge_Variance_Fields = {
  __typename?: 'student_badge_variance_fields';
  badge_id?: Maybe<Scalars['Float']>;
  student_badge_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_badge" */
export type Student_Badge_Variance_Order_By = {
  badge_id?: Maybe<Order_By>;
  student_badge_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** columns and relationships of "student_banner" */
export type Student_Banner = {
  __typename?: 'student_banner';
  image_path: Scalars['String'];
  student_banner_id: Scalars['Int'];
};

/** aggregated selection of "student_banner" */
export type Student_Banner_Aggregate = {
  __typename?: 'student_banner_aggregate';
  aggregate?: Maybe<Student_Banner_Aggregate_Fields>;
  nodes: Array<Student_Banner>;
};

/** aggregate fields of "student_banner" */
export type Student_Banner_Aggregate_Fields = {
  __typename?: 'student_banner_aggregate_fields';
  avg?: Maybe<Student_Banner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Banner_Max_Fields>;
  min?: Maybe<Student_Banner_Min_Fields>;
  stddev?: Maybe<Student_Banner_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Banner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Banner_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Banner_Sum_Fields>;
  var_pop?: Maybe<Student_Banner_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Banner_Var_Samp_Fields>;
  variance?: Maybe<Student_Banner_Variance_Fields>;
};


/** aggregate fields of "student_banner" */
export type Student_Banner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Banner_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Banner_Avg_Fields = {
  __typename?: 'student_banner_avg_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_banner". All fields are combined with a logical 'AND'. */
export type Student_Banner_Bool_Exp = {
  _and?: Maybe<Array<Student_Banner_Bool_Exp>>;
  _not?: Maybe<Student_Banner_Bool_Exp>;
  _or?: Maybe<Array<Student_Banner_Bool_Exp>>;
  image_path?: Maybe<String_Comparison_Exp>;
  student_banner_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_banner" */
export enum Student_Banner_Constraint {
  /** unique or primary key constraint */
  StudentBannerImagePathKey = 'student_banner_image_path_key',
  /** unique or primary key constraint */
  StudentBannerPkey = 'student_banner_pkey'
}

/** input type for incrementing numeric columns in table "student_banner" */
export type Student_Banner_Inc_Input = {
  student_banner_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_banner" */
export type Student_Banner_Insert_Input = {
  image_path?: Maybe<Scalars['String']>;
  student_banner_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Banner_Max_Fields = {
  __typename?: 'student_banner_max_fields';
  image_path?: Maybe<Scalars['String']>;
  student_banner_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Student_Banner_Min_Fields = {
  __typename?: 'student_banner_min_fields';
  image_path?: Maybe<Scalars['String']>;
  student_banner_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "student_banner" */
export type Student_Banner_Mutation_Response = {
  __typename?: 'student_banner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Banner>;
};

/** on_conflict condition type for table "student_banner" */
export type Student_Banner_On_Conflict = {
  constraint: Student_Banner_Constraint;
  update_columns?: Array<Student_Banner_Update_Column>;
  where?: Maybe<Student_Banner_Bool_Exp>;
};

/** Ordering options when selecting data from "student_banner". */
export type Student_Banner_Order_By = {
  image_path?: Maybe<Order_By>;
  student_banner_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_banner */
export type Student_Banner_Pk_Columns_Input = {
  student_banner_id: Scalars['Int'];
};

/** select columns of table "student_banner" */
export enum Student_Banner_Select_Column {
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  StudentBannerId = 'student_banner_id'
}

/** input type for updating data in table "student_banner" */
export type Student_Banner_Set_Input = {
  image_path?: Maybe<Scalars['String']>;
  student_banner_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Banner_Stddev_Fields = {
  __typename?: 'student_banner_stddev_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Banner_Stddev_Pop_Fields = {
  __typename?: 'student_banner_stddev_pop_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Banner_Stddev_Samp_Fields = {
  __typename?: 'student_banner_stddev_samp_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Banner_Sum_Fields = {
  __typename?: 'student_banner_sum_fields';
  student_banner_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "student_banner" */
export enum Student_Banner_Update_Column {
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  StudentBannerId = 'student_banner_id'
}

/** aggregate var_pop on columns */
export type Student_Banner_Var_Pop_Fields = {
  __typename?: 'student_banner_var_pop_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Banner_Var_Samp_Fields = {
  __typename?: 'student_banner_var_samp_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Banner_Variance_Fields = {
  __typename?: 'student_banner_variance_fields';
  student_banner_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student". All fields are combined with a logical 'AND'. */
export type Student_Bool_Exp = {
  _and?: Maybe<Array<Student_Bool_Exp>>;
  _not?: Maybe<Student_Bool_Exp>;
  _or?: Maybe<Array<Student_Bool_Exp>>;
  activate_mail_sent?: Maybe<Boolean_Comparison_Exp>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  avatar_selected?: Maybe<Boolean_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  class_students?: Maybe<Class_Student_Bool_Exp>;
  clever_id?: Maybe<String_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  cover_image_path?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  district?: Maybe<Student_District_Bool_Exp>;
  dob?: Maybe<Date_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  grade?: Maybe<String_Comparison_Exp>;
  grades?: Maybe<Student_Grade_Bool_Exp>;
  graduation_year?: Maybe<Int_Comparison_Exp>;
  has_lti_integration?: Maybe<Boolean_Comparison_Exp>;
  home_language?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  integration_id?: Maybe<String_Comparison_Exp>;
  integration_name?: Maybe<Integration_Name_Enum_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  middle_name?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  race?: Maybe<String_Comparison_Exp>;
  school?: Maybe<School_Bool_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
  stage_name?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  student_badge_events?: Maybe<Student_Badge_Event_Bool_Exp>;
  student_badge_summary?: Maybe<Student_Badge_Resume_Bool_Exp>;
  student_badges?: Maybe<Student_Badge_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_integrations?: Maybe<Student_Integration_Bool_Exp>;
  student_productivity_daily?: Maybe<Student_Productivity_Daily_Bool_Exp>;
  student_productivity_monthly?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
  student_productivity_yearly?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
  student_setting?: Maybe<Student_Setting_Bool_Exp>;
  unweighted_gpa?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  weighted_gpa?: Maybe<String_Comparison_Exp>;
  wurrly?: Maybe<Wurrly_Bool_Exp>;
  wurrly_role?: Maybe<Wurrly_Role_Enum_Comparison_Exp>;
  zip?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student" */
export enum Student_Constraint {
  /** unique or primary key constraint */
  StudentCleverIdKey = 'student_clever_id_key',
  /** unique or primary key constraint */
  StudentCodeKey = 'student_code_key',
  /** unique or primary key constraint */
  StudentPkey = 'student_pkey'
}

/** columns and relationships of "student_district" */
export type Student_District = {
  __typename?: 'student_district';
  district?: Maybe<Scalars['_int4']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "student_district" */
export type Student_District_Aggregate = {
  __typename?: 'student_district_aggregate';
  aggregate?: Maybe<Student_District_Aggregate_Fields>;
  nodes: Array<Student_District>;
};

/** aggregate fields of "student_district" */
export type Student_District_Aggregate_Fields = {
  __typename?: 'student_district_aggregate_fields';
  avg?: Maybe<Student_District_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_District_Max_Fields>;
  min?: Maybe<Student_District_Min_Fields>;
  stddev?: Maybe<Student_District_Stddev_Fields>;
  stddev_pop?: Maybe<Student_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_District_Stddev_Samp_Fields>;
  sum?: Maybe<Student_District_Sum_Fields>;
  var_pop?: Maybe<Student_District_Var_Pop_Fields>;
  var_samp?: Maybe<Student_District_Var_Samp_Fields>;
  variance?: Maybe<Student_District_Variance_Fields>;
};


/** aggregate fields of "student_district" */
export type Student_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_District_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_District_Avg_Fields = {
  __typename?: 'student_district_avg_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_district". All fields are combined with a logical 'AND'. */
export type Student_District_Bool_Exp = {
  _and?: Maybe<Array<Student_District_Bool_Exp>>;
  _not?: Maybe<Student_District_Bool_Exp>;
  _or?: Maybe<Array<Student_District_Bool_Exp>>;
  district?: Maybe<_Int4_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "student_district" */
export type Student_District_Insert_Input = {
  district?: Maybe<Scalars['_int4']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_District_Max_Fields = {
  __typename?: 'student_district_max_fields';
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Student_District_Min_Fields = {
  __typename?: 'student_district_min_fields';
  student_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "student_district" */
export type Student_District_Obj_Rel_Insert_Input = {
  data: Student_District_Insert_Input;
};

/** Ordering options when selecting data from "student_district". */
export type Student_District_Order_By = {
  district?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** select columns of table "student_district" */
export enum Student_District_Select_Column {
  /** column name */
  District = 'district',
  /** column name */
  StudentId = 'student_id'
}

/** aggregate stddev on columns */
export type Student_District_Stddev_Fields = {
  __typename?: 'student_district_stddev_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_District_Stddev_Pop_Fields = {
  __typename?: 'student_district_stddev_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_District_Stddev_Samp_Fields = {
  __typename?: 'student_district_stddev_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_District_Sum_Fields = {
  __typename?: 'student_district_sum_fields';
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Student_District_Var_Pop_Fields = {
  __typename?: 'student_district_var_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_District_Var_Samp_Fields = {
  __typename?: 'student_district_var_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_District_Variance_Fields = {
  __typename?: 'student_district_variance_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "student_goal" */
export type Student_Goal = {
  __typename?: 'student_goal';
  start_date: Scalars['date'];
  student_goal_id: Scalars['Int'];
  student_id: Scalars['Int'];
  weekly_goal: Scalars['Int'];
};

/** aggregated selection of "student_goal" */
export type Student_Goal_Aggregate = {
  __typename?: 'student_goal_aggregate';
  aggregate?: Maybe<Student_Goal_Aggregate_Fields>;
  nodes: Array<Student_Goal>;
};

/** aggregate fields of "student_goal" */
export type Student_Goal_Aggregate_Fields = {
  __typename?: 'student_goal_aggregate_fields';
  avg?: Maybe<Student_Goal_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Goal_Max_Fields>;
  min?: Maybe<Student_Goal_Min_Fields>;
  stddev?: Maybe<Student_Goal_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Goal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Goal_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Goal_Sum_Fields>;
  var_pop?: Maybe<Student_Goal_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Goal_Var_Samp_Fields>;
  variance?: Maybe<Student_Goal_Variance_Fields>;
};


/** aggregate fields of "student_goal" */
export type Student_Goal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Goal_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Goal_Avg_Fields = {
  __typename?: 'student_goal_avg_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_goal". All fields are combined with a logical 'AND'. */
export type Student_Goal_Bool_Exp = {
  _and?: Maybe<Array<Student_Goal_Bool_Exp>>;
  _not?: Maybe<Student_Goal_Bool_Exp>;
  _or?: Maybe<Array<Student_Goal_Bool_Exp>>;
  start_date?: Maybe<Date_Comparison_Exp>;
  student_goal_id?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  weekly_goal?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_goal" */
export enum Student_Goal_Constraint {
  /** unique or primary key constraint */
  StudentGoalPkey = 'student_goal_pkey'
}

/** input type for incrementing numeric columns in table "student_goal" */
export type Student_Goal_Inc_Input = {
  student_goal_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  weekly_goal?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_goal" */
export type Student_Goal_Insert_Input = {
  start_date?: Maybe<Scalars['date']>;
  student_goal_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  weekly_goal?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Goal_Max_Fields = {
  __typename?: 'student_goal_max_fields';
  start_date?: Maybe<Scalars['date']>;
  student_goal_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  weekly_goal?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Student_Goal_Min_Fields = {
  __typename?: 'student_goal_min_fields';
  start_date?: Maybe<Scalars['date']>;
  student_goal_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  weekly_goal?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "student_goal" */
export type Student_Goal_Mutation_Response = {
  __typename?: 'student_goal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Goal>;
};

/** on_conflict condition type for table "student_goal" */
export type Student_Goal_On_Conflict = {
  constraint: Student_Goal_Constraint;
  update_columns?: Array<Student_Goal_Update_Column>;
  where?: Maybe<Student_Goal_Bool_Exp>;
};

/** Ordering options when selecting data from "student_goal". */
export type Student_Goal_Order_By = {
  start_date?: Maybe<Order_By>;
  student_goal_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  weekly_goal?: Maybe<Order_By>;
};

/** primary key columns input for table: student_goal */
export type Student_Goal_Pk_Columns_Input = {
  student_goal_id: Scalars['Int'];
};

/** select columns of table "student_goal" */
export enum Student_Goal_Select_Column {
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StudentGoalId = 'student_goal_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  WeeklyGoal = 'weekly_goal'
}

/** input type for updating data in table "student_goal" */
export type Student_Goal_Set_Input = {
  start_date?: Maybe<Scalars['date']>;
  student_goal_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  weekly_goal?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Goal_Stddev_Fields = {
  __typename?: 'student_goal_stddev_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Goal_Stddev_Pop_Fields = {
  __typename?: 'student_goal_stddev_pop_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Goal_Stddev_Samp_Fields = {
  __typename?: 'student_goal_stddev_samp_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Goal_Sum_Fields = {
  __typename?: 'student_goal_sum_fields';
  student_goal_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  weekly_goal?: Maybe<Scalars['Int']>;
};

/** update columns of table "student_goal" */
export enum Student_Goal_Update_Column {
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StudentGoalId = 'student_goal_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  WeeklyGoal = 'weekly_goal'
}

/** aggregate var_pop on columns */
export type Student_Goal_Var_Pop_Fields = {
  __typename?: 'student_goal_var_pop_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Goal_Var_Samp_Fields = {
  __typename?: 'student_goal_var_samp_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Goal_Variance_Fields = {
  __typename?: 'student_goal_variance_fields';
  student_goal_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  weekly_goal?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "student_grade" */
export type Student_Grade = {
  __typename?: 'student_grade';
  grade?: Maybe<Scalars['_int4']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "student_grade" */
export type Student_Grade_Aggregate = {
  __typename?: 'student_grade_aggregate';
  aggregate?: Maybe<Student_Grade_Aggregate_Fields>;
  nodes: Array<Student_Grade>;
};

/** aggregate fields of "student_grade" */
export type Student_Grade_Aggregate_Fields = {
  __typename?: 'student_grade_aggregate_fields';
  avg?: Maybe<Student_Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Grade_Max_Fields>;
  min?: Maybe<Student_Grade_Min_Fields>;
  stddev?: Maybe<Student_Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Grade_Sum_Fields>;
  var_pop?: Maybe<Student_Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Grade_Var_Samp_Fields>;
  variance?: Maybe<Student_Grade_Variance_Fields>;
};


/** aggregate fields of "student_grade" */
export type Student_Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Grade_Avg_Fields = {
  __typename?: 'student_grade_avg_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_grade". All fields are combined with a logical 'AND'. */
export type Student_Grade_Bool_Exp = {
  _and?: Maybe<Array<Student_Grade_Bool_Exp>>;
  _not?: Maybe<Student_Grade_Bool_Exp>;
  _or?: Maybe<Array<Student_Grade_Bool_Exp>>;
  grade?: Maybe<_Int4_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "student_grade" */
export type Student_Grade_Insert_Input = {
  grade?: Maybe<Scalars['_int4']>;
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Grade_Max_Fields = {
  __typename?: 'student_grade_max_fields';
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Student_Grade_Min_Fields = {
  __typename?: 'student_grade_min_fields';
  student_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "student_grade" */
export type Student_Grade_Obj_Rel_Insert_Input = {
  data: Student_Grade_Insert_Input;
};

/** Ordering options when selecting data from "student_grade". */
export type Student_Grade_Order_By = {
  grade?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
};

/** select columns of table "student_grade" */
export enum Student_Grade_Select_Column {
  /** column name */
  Grade = 'grade',
  /** column name */
  StudentId = 'student_id'
}

/** aggregate stddev on columns */
export type Student_Grade_Stddev_Fields = {
  __typename?: 'student_grade_stddev_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Grade_Stddev_Pop_Fields = {
  __typename?: 'student_grade_stddev_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Grade_Stddev_Samp_Fields = {
  __typename?: 'student_grade_stddev_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Grade_Sum_Fields = {
  __typename?: 'student_grade_sum_fields';
  student_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Student_Grade_Var_Pop_Fields = {
  __typename?: 'student_grade_var_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Grade_Var_Samp_Fields = {
  __typename?: 'student_grade_var_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Grade_Variance_Fields = {
  __typename?: 'student_grade_variance_fields';
  student_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "student" */
export type Student_Inc_Input = {
  graduation_year?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student" */
export type Student_Insert_Input = {
  activate_mail_sent?: Maybe<Scalars['Boolean']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  avatar_selected?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  class_students?: Maybe<Class_Student_Arr_Rel_Insert_Input>;
  /** deprecated */
  clever_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cover_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Student_District_Obj_Rel_Insert_Input>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  grades?: Maybe<Student_Grade_Obj_Rel_Insert_Input>;
  graduation_year?: Maybe<Scalars['Int']>;
  has_lti_integration?: Maybe<Scalars['Boolean']>;
  home_language?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_id?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_name?: Maybe<Integration_Name_Enum>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  school?: Maybe<School_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  student_badge_events?: Maybe<Student_Badge_Event_Arr_Rel_Insert_Input>;
  student_badge_summary?: Maybe<Student_Badge_Resume_Obj_Rel_Insert_Input>;
  student_badges?: Maybe<Student_Badge_Arr_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  student_integrations?: Maybe<Student_Integration_Arr_Rel_Insert_Input>;
  student_productivity_daily?: Maybe<Student_Productivity_Daily_Arr_Rel_Insert_Input>;
  student_productivity_monthly?: Maybe<Student_Productivity_Monthly_Arr_Rel_Insert_Input>;
  student_productivity_yearly?: Maybe<Student_Productivity_Yearly_Arr_Rel_Insert_Input>;
  student_setting?: Maybe<Student_Setting_Obj_Rel_Insert_Input>;
  unweighted_gpa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighted_gpa?: Maybe<Scalars['String']>;
  wurrly?: Maybe<Wurrly_Arr_Rel_Insert_Input>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
  zip?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "student_integration" */
export type Student_Integration = {
  __typename?: 'student_integration';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  integration_id: Scalars['String'];
  integration_name: Integration_Name_Enum;
  is_sso: Scalars['Boolean'];
  student_id: Scalars['Int'];
  student_integration_id: Scalars['Int'];
};

/** aggregated selection of "student_integration" */
export type Student_Integration_Aggregate = {
  __typename?: 'student_integration_aggregate';
  aggregate?: Maybe<Student_Integration_Aggregate_Fields>;
  nodes: Array<Student_Integration>;
};

/** aggregate fields of "student_integration" */
export type Student_Integration_Aggregate_Fields = {
  __typename?: 'student_integration_aggregate_fields';
  avg?: Maybe<Student_Integration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Integration_Max_Fields>;
  min?: Maybe<Student_Integration_Min_Fields>;
  stddev?: Maybe<Student_Integration_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Integration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Integration_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Integration_Sum_Fields>;
  var_pop?: Maybe<Student_Integration_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Integration_Var_Samp_Fields>;
  variance?: Maybe<Student_Integration_Variance_Fields>;
};


/** aggregate fields of "student_integration" */
export type Student_Integration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Integration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_integration" */
export type Student_Integration_Aggregate_Order_By = {
  avg?: Maybe<Student_Integration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Integration_Max_Order_By>;
  min?: Maybe<Student_Integration_Min_Order_By>;
  stddev?: Maybe<Student_Integration_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Integration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Integration_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Integration_Sum_Order_By>;
  var_pop?: Maybe<Student_Integration_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Integration_Var_Samp_Order_By>;
  variance?: Maybe<Student_Integration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_integration" */
export type Student_Integration_Arr_Rel_Insert_Input = {
  data: Array<Student_Integration_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_Integration_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Integration_Avg_Fields = {
  __typename?: 'student_integration_avg_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_integration" */
export type Student_Integration_Avg_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_integration". All fields are combined with a logical 'AND'. */
export type Student_Integration_Bool_Exp = {
  _and?: Maybe<Array<Student_Integration_Bool_Exp>>;
  _not?: Maybe<Student_Integration_Bool_Exp>;
  _or?: Maybe<Array<Student_Integration_Bool_Exp>>;
  classLink_tenant_id?: Maybe<Int_Comparison_Exp>;
  classLink_token?: Maybe<String_Comparison_Exp>;
  integration_id?: Maybe<String_Comparison_Exp>;
  integration_name?: Maybe<Integration_Name_Enum_Comparison_Exp>;
  is_sso?: Maybe<Boolean_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_integration_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_integration" */
export enum Student_Integration_Constraint {
  /** unique or primary key constraint */
  StudentIntegrationPkey = 'student_integration_pkey',
  /** unique or primary key constraint */
  StudentIntegrationStudentIdIntegrationIdKey = 'student_integration_student_id_integration_id_key'
}

/** input type for incrementing numeric columns in table "student_integration" */
export type Student_Integration_Inc_Input = {
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_integration_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_integration" */
export type Student_Integration_Insert_Input = {
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  is_sso?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  student_integration_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Integration_Max_Fields = {
  __typename?: 'student_integration_max_fields';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  student_integration_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student_integration" */
export type Student_Integration_Max_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  classLink_token?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Integration_Min_Fields = {
  __typename?: 'student_integration_min_fields';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  student_integration_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student_integration" */
export type Student_Integration_Min_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  classLink_token?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_integration" */
export type Student_Integration_Mutation_Response = {
  __typename?: 'student_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Integration>;
};

/** on_conflict condition type for table "student_integration" */
export type Student_Integration_On_Conflict = {
  constraint: Student_Integration_Constraint;
  update_columns?: Array<Student_Integration_Update_Column>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "student_integration". */
export type Student_Integration_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  classLink_token?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  integration_name?: Maybe<Order_By>;
  is_sso?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_integration */
export type Student_Integration_Pk_Columns_Input = {
  student_integration_id: Scalars['Int'];
};

/** select columns of table "student_integration" */
export enum Student_Integration_Select_Column {
  /** column name */
  ClassLinkTenantId = 'classLink_tenant_id',
  /** column name */
  ClassLinkToken = 'classLink_token',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsSso = 'is_sso',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentIntegrationId = 'student_integration_id'
}

/** input type for updating data in table "student_integration" */
export type Student_Integration_Set_Input = {
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  is_sso?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  student_integration_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Integration_Stddev_Fields = {
  __typename?: 'student_integration_stddev_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_integration" */
export type Student_Integration_Stddev_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Integration_Stddev_Pop_Fields = {
  __typename?: 'student_integration_stddev_pop_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_integration" */
export type Student_Integration_Stddev_Pop_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Integration_Stddev_Samp_Fields = {
  __typename?: 'student_integration_stddev_samp_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_integration" */
export type Student_Integration_Stddev_Samp_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Integration_Sum_Fields = {
  __typename?: 'student_integration_sum_fields';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_integration_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_integration" */
export type Student_Integration_Sum_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** update columns of table "student_integration" */
export enum Student_Integration_Update_Column {
  /** column name */
  ClassLinkTenantId = 'classLink_tenant_id',
  /** column name */
  ClassLinkToken = 'classLink_token',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsSso = 'is_sso',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentIntegrationId = 'student_integration_id'
}

/** aggregate var_pop on columns */
export type Student_Integration_Var_Pop_Fields = {
  __typename?: 'student_integration_var_pop_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_integration" */
export type Student_Integration_Var_Pop_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Integration_Var_Samp_Fields = {
  __typename?: 'student_integration_var_samp_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_integration" */
export type Student_Integration_Var_Samp_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Integration_Variance_Fields = {
  __typename?: 'student_integration_variance_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_integration_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_integration" */
export type Student_Integration_Variance_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_integration_id?: Maybe<Order_By>;
};

/** columns and relationships of "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite = {
  __typename?: 'student_lesson_plan_favorite';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  lesson_plan?: Maybe<Lesson_Plan>;
  lesson_plan_id: Scalars['Int'];
  student_id: Scalars['Int'];
  student_lesson_plan_favorite_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Aggregate = {
  __typename?: 'student_lesson_plan_favorite_aggregate';
  aggregate?: Maybe<Student_Lesson_Plan_Favorite_Aggregate_Fields>;
  nodes: Array<Student_Lesson_Plan_Favorite>;
};

/** aggregate fields of "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Aggregate_Fields = {
  __typename?: 'student_lesson_plan_favorite_aggregate_fields';
  avg?: Maybe<Student_Lesson_Plan_Favorite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Lesson_Plan_Favorite_Max_Fields>;
  min?: Maybe<Student_Lesson_Plan_Favorite_Min_Fields>;
  stddev?: Maybe<Student_Lesson_Plan_Favorite_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Lesson_Plan_Favorite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Lesson_Plan_Favorite_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Lesson_Plan_Favorite_Sum_Fields>;
  var_pop?: Maybe<Student_Lesson_Plan_Favorite_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Lesson_Plan_Favorite_Var_Samp_Fields>;
  variance?: Maybe<Student_Lesson_Plan_Favorite_Variance_Fields>;
};


/** aggregate fields of "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Lesson_Plan_Favorite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Lesson_Plan_Favorite_Avg_Fields = {
  __typename?: 'student_lesson_plan_favorite_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_lesson_plan_favorite". All fields are combined with a logical 'AND'. */
export type Student_Lesson_Plan_Favorite_Bool_Exp = {
  _and?: Maybe<Array<Student_Lesson_Plan_Favorite_Bool_Exp>>;
  _not?: Maybe<Student_Lesson_Plan_Favorite_Bool_Exp>;
  _or?: Maybe<Array<Student_Lesson_Plan_Favorite_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_lesson_plan_favorite_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_lesson_plan_favorite" */
export enum Student_Lesson_Plan_Favorite_Constraint {
  /** unique or primary key constraint */
  StudentLessonPlanFavoritePkey = 'student_lesson_plan_favorite_pkey'
}

/** input type for incrementing numeric columns in table "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Student_Lesson_Plan_Favorite_Max_Fields = {
  __typename?: 'student_lesson_plan_favorite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Student_Lesson_Plan_Favorite_Min_Fields = {
  __typename?: 'student_lesson_plan_favorite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Mutation_Response = {
  __typename?: 'student_lesson_plan_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Lesson_Plan_Favorite>;
};

/** on_conflict condition type for table "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_On_Conflict = {
  constraint: Student_Lesson_Plan_Favorite_Constraint;
  update_columns?: Array<Student_Lesson_Plan_Favorite_Update_Column>;
  where?: Maybe<Student_Lesson_Plan_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "student_lesson_plan_favorite". */
export type Student_Lesson_Plan_Favorite_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_lesson_plan_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: student_lesson_plan_favorite */
export type Student_Lesson_Plan_Favorite_Pk_Columns_Input = {
  student_lesson_plan_favorite_id: Scalars['Int'];
};

/** select columns of table "student_lesson_plan_favorite" */
export enum Student_Lesson_Plan_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentLessonPlanFavoriteId = 'student_lesson_plan_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "student_lesson_plan_favorite" */
export type Student_Lesson_Plan_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Student_Lesson_Plan_Favorite_Stddev_Fields = {
  __typename?: 'student_lesson_plan_favorite_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Lesson_Plan_Favorite_Stddev_Pop_Fields = {
  __typename?: 'student_lesson_plan_favorite_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Lesson_Plan_Favorite_Stddev_Samp_Fields = {
  __typename?: 'student_lesson_plan_favorite_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Lesson_Plan_Favorite_Sum_Fields = {
  __typename?: 'student_lesson_plan_favorite_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "student_lesson_plan_favorite" */
export enum Student_Lesson_Plan_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentLessonPlanFavoriteId = 'student_lesson_plan_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Student_Lesson_Plan_Favorite_Var_Pop_Fields = {
  __typename?: 'student_lesson_plan_favorite_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Lesson_Plan_Favorite_Var_Samp_Fields = {
  __typename?: 'student_lesson_plan_favorite_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Lesson_Plan_Favorite_Variance_Fields = {
  __typename?: 'student_lesson_plan_favorite_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Student_Max_Fields = {
  __typename?: 'student_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** deprecated */
  clever_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cover_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  graduation_year?: Maybe<Scalars['Int']>;
  home_language?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  unweighted_gpa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighted_gpa?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student" */
export type Student_Max_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  /** deprecated */
  clever_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  cover_image_path?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  graduation_year?: Maybe<Order_By>;
  home_language?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  /** deprecated */
  integration_id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  race?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  stage_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  unweighted_gpa?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weighted_gpa?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Min_Fields = {
  __typename?: 'student_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** deprecated */
  clever_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cover_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  graduation_year?: Maybe<Scalars['Int']>;
  home_language?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  unweighted_gpa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighted_gpa?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student" */
export type Student_Min_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  /** deprecated */
  clever_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  cover_image_path?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  graduation_year?: Maybe<Order_By>;
  home_language?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  /** deprecated */
  integration_id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  race?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  stage_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  unweighted_gpa?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weighted_gpa?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "student" */
export type Student_Mutation_Response = {
  __typename?: 'student_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student>;
};

/** input type for inserting object relation for remote table "student" */
export type Student_Obj_Rel_Insert_Input = {
  data: Student_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Student_On_Conflict>;
};

/** on_conflict condition type for table "student" */
export type Student_On_Conflict = {
  constraint: Student_Constraint;
  update_columns?: Array<Student_Update_Column>;
  where?: Maybe<Student_Bool_Exp>;
};

/** Ordering options when selecting data from "student". */
export type Student_Order_By = {
  activate_mail_sent?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  avatar_selected?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  class_students_aggregate?: Maybe<Class_Student_Aggregate_Order_By>;
  clever_id?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  cover_image_path?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  district?: Maybe<Student_District_Order_By>;
  dob?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  grades?: Maybe<Student_Grade_Order_By>;
  graduation_year?: Maybe<Order_By>;
  has_lti_integration?: Maybe<Order_By>;
  home_language?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  integration_name?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  middle_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  race?: Maybe<Order_By>;
  school?: Maybe<School_Order_By>;
  school_id?: Maybe<Order_By>;
  stage_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  student_badge_events_aggregate?: Maybe<Student_Badge_Event_Aggregate_Order_By>;
  student_badge_summary?: Maybe<Student_Badge_Resume_Order_By>;
  student_badges_aggregate?: Maybe<Student_Badge_Aggregate_Order_By>;
  student_id?: Maybe<Order_By>;
  student_integrations_aggregate?: Maybe<Student_Integration_Aggregate_Order_By>;
  student_productivity_daily_aggregate?: Maybe<Student_Productivity_Daily_Aggregate_Order_By>;
  student_productivity_monthly_aggregate?: Maybe<Student_Productivity_Monthly_Aggregate_Order_By>;
  student_productivity_yearly_aggregate?: Maybe<Student_Productivity_Yearly_Aggregate_Order_By>;
  student_setting?: Maybe<Student_Setting_Order_By>;
  unweighted_gpa?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weighted_gpa?: Maybe<Order_By>;
  wurrly_aggregate?: Maybe<Wurrly_Aggregate_Order_By>;
  wurrly_role?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: student */
export type Student_Pk_Columns_Input = {
  student_id: Scalars['Int'];
};

/** columns and relationships of "student_productivity_daily" */
export type Student_Productivity_Daily = {
  __typename?: 'student_productivity_daily';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  day: Scalars['Int'];
  month: Scalars['Int'];
  practice_sessions: Scalars['Int'];
  recording_sessions: Scalars['Int'];
  /** An object relationship */
  student: Student;
  student_id: Scalars['Int'];
  student_productivity_daily_id: Scalars['Int'];
  video_views: Scalars['Int'];
  year: Scalars['Int'];
};

/** aggregated selection of "student_productivity_daily" */
export type Student_Productivity_Daily_Aggregate = {
  __typename?: 'student_productivity_daily_aggregate';
  aggregate?: Maybe<Student_Productivity_Daily_Aggregate_Fields>;
  nodes: Array<Student_Productivity_Daily>;
};

/** aggregate fields of "student_productivity_daily" */
export type Student_Productivity_Daily_Aggregate_Fields = {
  __typename?: 'student_productivity_daily_aggregate_fields';
  avg?: Maybe<Student_Productivity_Daily_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Productivity_Daily_Max_Fields>;
  min?: Maybe<Student_Productivity_Daily_Min_Fields>;
  stddev?: Maybe<Student_Productivity_Daily_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Productivity_Daily_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Productivity_Daily_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Productivity_Daily_Sum_Fields>;
  var_pop?: Maybe<Student_Productivity_Daily_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Productivity_Daily_Var_Samp_Fields>;
  variance?: Maybe<Student_Productivity_Daily_Variance_Fields>;
};


/** aggregate fields of "student_productivity_daily" */
export type Student_Productivity_Daily_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_productivity_daily" */
export type Student_Productivity_Daily_Aggregate_Order_By = {
  avg?: Maybe<Student_Productivity_Daily_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Productivity_Daily_Max_Order_By>;
  min?: Maybe<Student_Productivity_Daily_Min_Order_By>;
  stddev?: Maybe<Student_Productivity_Daily_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Productivity_Daily_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Productivity_Daily_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Productivity_Daily_Sum_Order_By>;
  var_pop?: Maybe<Student_Productivity_Daily_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Productivity_Daily_Var_Samp_Order_By>;
  variance?: Maybe<Student_Productivity_Daily_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_productivity_daily" */
export type Student_Productivity_Daily_Arr_Rel_Insert_Input = {
  data: Array<Student_Productivity_Daily_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_Productivity_Daily_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Productivity_Daily_Avg_Fields = {
  __typename?: 'student_productivity_daily_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_productivity_daily". All fields are combined with a logical 'AND'. */
export type Student_Productivity_Daily_Bool_Exp = {
  _and?: Maybe<Array<Student_Productivity_Daily_Bool_Exp>>;
  _not?: Maybe<Student_Productivity_Daily_Bool_Exp>;
  _or?: Maybe<Array<Student_Productivity_Daily_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  day?: Maybe<Int_Comparison_Exp>;
  month?: Maybe<Int_Comparison_Exp>;
  practice_sessions?: Maybe<Int_Comparison_Exp>;
  recording_sessions?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_productivity_daily_id?: Maybe<Int_Comparison_Exp>;
  video_views?: Maybe<Int_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_productivity_daily" */
export enum Student_Productivity_Daily_Constraint {
  /** unique or primary key constraint */
  StudentProductivityDailyPkey = 'student_productivity_daily_pkey',
  /** unique or primary key constraint */
  StudentProductivityDailyStudentIdClassIdDayMonthYearK = 'student_productivity_daily_student_id_class_id_day_month_year_k'
}

/** input type for incrementing numeric columns in table "student_productivity_daily" */
export type Student_Productivity_Daily_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_daily_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_productivity_daily" */
export type Student_Productivity_Daily_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_daily_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Productivity_Daily_Max_Fields = {
  __typename?: 'student_productivity_daily_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_daily_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Productivity_Daily_Min_Fields = {
  __typename?: 'student_productivity_daily_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_daily_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_productivity_daily" */
export type Student_Productivity_Daily_Mutation_Response = {
  __typename?: 'student_productivity_daily_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Productivity_Daily>;
};

/** on_conflict condition type for table "student_productivity_daily" */
export type Student_Productivity_Daily_On_Conflict = {
  constraint: Student_Productivity_Daily_Constraint;
  update_columns?: Array<Student_Productivity_Daily_Update_Column>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};

/** Ordering options when selecting data from "student_productivity_daily". */
export type Student_Productivity_Daily_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: student_productivity_daily */
export type Student_Productivity_Daily_Pk_Columns_Input = {
  student_productivity_daily_id: Scalars['Int'];
};

/** select columns of table "student_productivity_daily" */
export enum Student_Productivity_Daily_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Day = 'day',
  /** column name */
  Month = 'month',
  /** column name */
  PracticeSessions = 'practice_sessions',
  /** column name */
  RecordingSessions = 'recording_sessions',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentProductivityDailyId = 'student_productivity_daily_id',
  /** column name */
  VideoViews = 'video_views',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "student_productivity_daily" */
export type Student_Productivity_Daily_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_daily_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Productivity_Daily_Stddev_Fields = {
  __typename?: 'student_productivity_daily_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Productivity_Daily_Stddev_Pop_Fields = {
  __typename?: 'student_productivity_daily_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Productivity_Daily_Stddev_Samp_Fields = {
  __typename?: 'student_productivity_daily_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Productivity_Daily_Sum_Fields = {
  __typename?: 'student_productivity_daily_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_daily_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "student_productivity_daily" */
export enum Student_Productivity_Daily_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Day = 'day',
  /** column name */
  Month = 'month',
  /** column name */
  PracticeSessions = 'practice_sessions',
  /** column name */
  RecordingSessions = 'recording_sessions',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentProductivityDailyId = 'student_productivity_daily_id',
  /** column name */
  VideoViews = 'video_views',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Student_Productivity_Daily_Var_Pop_Fields = {
  __typename?: 'student_productivity_daily_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Productivity_Daily_Var_Samp_Fields = {
  __typename?: 'student_productivity_daily_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Productivity_Daily_Variance_Fields = {
  __typename?: 'student_productivity_daily_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  day?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_daily_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_productivity_daily" */
export type Student_Productivity_Daily_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_daily_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "student_productivity_monthly" */
export type Student_Productivity_Monthly = {
  __typename?: 'student_productivity_monthly';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  month: Scalars['Int'];
  practice_sessions: Scalars['Int'];
  recording_sessions: Scalars['Int'];
  /** An object relationship */
  student: Student;
  student_id: Scalars['Int'];
  student_productivity_monthly_id: Scalars['Int'];
  video_views: Scalars['Int'];
  year: Scalars['Int'];
};

/** aggregated selection of "student_productivity_monthly" */
export type Student_Productivity_Monthly_Aggregate = {
  __typename?: 'student_productivity_monthly_aggregate';
  aggregate?: Maybe<Student_Productivity_Monthly_Aggregate_Fields>;
  nodes: Array<Student_Productivity_Monthly>;
};

/** aggregate fields of "student_productivity_monthly" */
export type Student_Productivity_Monthly_Aggregate_Fields = {
  __typename?: 'student_productivity_monthly_aggregate_fields';
  avg?: Maybe<Student_Productivity_Monthly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Productivity_Monthly_Max_Fields>;
  min?: Maybe<Student_Productivity_Monthly_Min_Fields>;
  stddev?: Maybe<Student_Productivity_Monthly_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Productivity_Monthly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Productivity_Monthly_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Productivity_Monthly_Sum_Fields>;
  var_pop?: Maybe<Student_Productivity_Monthly_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Productivity_Monthly_Var_Samp_Fields>;
  variance?: Maybe<Student_Productivity_Monthly_Variance_Fields>;
};


/** aggregate fields of "student_productivity_monthly" */
export type Student_Productivity_Monthly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Aggregate_Order_By = {
  avg?: Maybe<Student_Productivity_Monthly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Productivity_Monthly_Max_Order_By>;
  min?: Maybe<Student_Productivity_Monthly_Min_Order_By>;
  stddev?: Maybe<Student_Productivity_Monthly_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Productivity_Monthly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Productivity_Monthly_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Productivity_Monthly_Sum_Order_By>;
  var_pop?: Maybe<Student_Productivity_Monthly_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Productivity_Monthly_Var_Samp_Order_By>;
  variance?: Maybe<Student_Productivity_Monthly_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Arr_Rel_Insert_Input = {
  data: Array<Student_Productivity_Monthly_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_Productivity_Monthly_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Productivity_Monthly_Avg_Fields = {
  __typename?: 'student_productivity_monthly_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_productivity_monthly". All fields are combined with a logical 'AND'. */
export type Student_Productivity_Monthly_Bool_Exp = {
  _and?: Maybe<Array<Student_Productivity_Monthly_Bool_Exp>>;
  _not?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
  _or?: Maybe<Array<Student_Productivity_Monthly_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  month?: Maybe<Int_Comparison_Exp>;
  practice_sessions?: Maybe<Int_Comparison_Exp>;
  recording_sessions?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_productivity_monthly_id?: Maybe<Int_Comparison_Exp>;
  video_views?: Maybe<Int_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_productivity_monthly" */
export enum Student_Productivity_Monthly_Constraint {
  /** unique or primary key constraint */
  StudentProductivityMonthlyClassIdStudentIdMonthYearKey = 'student_productivity_monthly_class_id_student_id_month_year_key',
  /** unique or primary key constraint */
  StudentProductivityMonthlyPkey = 'student_productivity_monthly_pkey'
}

/** input type for incrementing numeric columns in table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_monthly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_monthly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Productivity_Monthly_Max_Fields = {
  __typename?: 'student_productivity_monthly_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_monthly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Productivity_Monthly_Min_Fields = {
  __typename?: 'student_productivity_monthly_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_monthly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Mutation_Response = {
  __typename?: 'student_productivity_monthly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Productivity_Monthly>;
};

/** on_conflict condition type for table "student_productivity_monthly" */
export type Student_Productivity_Monthly_On_Conflict = {
  constraint: Student_Productivity_Monthly_Constraint;
  update_columns?: Array<Student_Productivity_Monthly_Update_Column>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};

/** Ordering options when selecting data from "student_productivity_monthly". */
export type Student_Productivity_Monthly_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: student_productivity_monthly */
export type Student_Productivity_Monthly_Pk_Columns_Input = {
  student_productivity_monthly_id: Scalars['Int'];
};

/** select columns of table "student_productivity_monthly" */
export enum Student_Productivity_Monthly_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Month = 'month',
  /** column name */
  PracticeSessions = 'practice_sessions',
  /** column name */
  RecordingSessions = 'recording_sessions',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentProductivityMonthlyId = 'student_productivity_monthly_id',
  /** column name */
  VideoViews = 'video_views',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_monthly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Productivity_Monthly_Stddev_Fields = {
  __typename?: 'student_productivity_monthly_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Productivity_Monthly_Stddev_Pop_Fields = {
  __typename?: 'student_productivity_monthly_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Productivity_Monthly_Stddev_Samp_Fields = {
  __typename?: 'student_productivity_monthly_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Productivity_Monthly_Sum_Fields = {
  __typename?: 'student_productivity_monthly_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_monthly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "student_productivity_monthly" */
export enum Student_Productivity_Monthly_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Month = 'month',
  /** column name */
  PracticeSessions = 'practice_sessions',
  /** column name */
  RecordingSessions = 'recording_sessions',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentProductivityMonthlyId = 'student_productivity_monthly_id',
  /** column name */
  VideoViews = 'video_views',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Student_Productivity_Monthly_Var_Pop_Fields = {
  __typename?: 'student_productivity_monthly_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Productivity_Monthly_Var_Samp_Fields = {
  __typename?: 'student_productivity_monthly_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Productivity_Monthly_Variance_Fields = {
  __typename?: 'student_productivity_monthly_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_monthly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_productivity_monthly" */
export type Student_Productivity_Monthly_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  month?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_monthly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "student_productivity_yearly" */
export type Student_Productivity_Yearly = {
  __typename?: 'student_productivity_yearly';
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  practice_sessions: Scalars['Int'];
  recording_sessions: Scalars['Int'];
  /** An object relationship */
  student: Student;
  student_id: Scalars['Int'];
  student_productivity_yearly_id: Scalars['Int'];
  video_views: Scalars['Int'];
  year: Scalars['Int'];
};

/** aggregated selection of "student_productivity_yearly" */
export type Student_Productivity_Yearly_Aggregate = {
  __typename?: 'student_productivity_yearly_aggregate';
  aggregate?: Maybe<Student_Productivity_Yearly_Aggregate_Fields>;
  nodes: Array<Student_Productivity_Yearly>;
};

/** aggregate fields of "student_productivity_yearly" */
export type Student_Productivity_Yearly_Aggregate_Fields = {
  __typename?: 'student_productivity_yearly_aggregate_fields';
  avg?: Maybe<Student_Productivity_Yearly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Productivity_Yearly_Max_Fields>;
  min?: Maybe<Student_Productivity_Yearly_Min_Fields>;
  stddev?: Maybe<Student_Productivity_Yearly_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Productivity_Yearly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Productivity_Yearly_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Productivity_Yearly_Sum_Fields>;
  var_pop?: Maybe<Student_Productivity_Yearly_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Productivity_Yearly_Var_Samp_Fields>;
  variance?: Maybe<Student_Productivity_Yearly_Variance_Fields>;
};


/** aggregate fields of "student_productivity_yearly" */
export type Student_Productivity_Yearly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Aggregate_Order_By = {
  avg?: Maybe<Student_Productivity_Yearly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Student_Productivity_Yearly_Max_Order_By>;
  min?: Maybe<Student_Productivity_Yearly_Min_Order_By>;
  stddev?: Maybe<Student_Productivity_Yearly_Stddev_Order_By>;
  stddev_pop?: Maybe<Student_Productivity_Yearly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Student_Productivity_Yearly_Stddev_Samp_Order_By>;
  sum?: Maybe<Student_Productivity_Yearly_Sum_Order_By>;
  var_pop?: Maybe<Student_Productivity_Yearly_Var_Pop_Order_By>;
  var_samp?: Maybe<Student_Productivity_Yearly_Var_Samp_Order_By>;
  variance?: Maybe<Student_Productivity_Yearly_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Arr_Rel_Insert_Input = {
  data: Array<Student_Productivity_Yearly_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Student_Productivity_Yearly_On_Conflict>;
};

/** aggregate avg on columns */
export type Student_Productivity_Yearly_Avg_Fields = {
  __typename?: 'student_productivity_yearly_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Avg_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "student_productivity_yearly". All fields are combined with a logical 'AND'. */
export type Student_Productivity_Yearly_Bool_Exp = {
  _and?: Maybe<Array<Student_Productivity_Yearly_Bool_Exp>>;
  _not?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
  _or?: Maybe<Array<Student_Productivity_Yearly_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  practice_sessions?: Maybe<Int_Comparison_Exp>;
  recording_sessions?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_productivity_yearly_id?: Maybe<Int_Comparison_Exp>;
  video_views?: Maybe<Int_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_productivity_yearly" */
export enum Student_Productivity_Yearly_Constraint {
  /** unique or primary key constraint */
  StudentProductivityPkey = 'student_productivity_pkey',
  /** unique or primary key constraint */
  StudentProductivityYearlyStudentIdClassIdYearKey = 'student_productivity_yearly_student_id_class_id_year_key'
}

/** input type for incrementing numeric columns in table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_yearly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_yearly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Productivity_Yearly_Max_Fields = {
  __typename?: 'student_productivity_yearly_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_yearly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Student_Productivity_Yearly_Min_Fields = {
  __typename?: 'student_productivity_yearly_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_yearly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Mutation_Response = {
  __typename?: 'student_productivity_yearly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Productivity_Yearly>;
};

/** on_conflict condition type for table "student_productivity_yearly" */
export type Student_Productivity_Yearly_On_Conflict = {
  constraint: Student_Productivity_Yearly_Constraint;
  update_columns?: Array<Student_Productivity_Yearly_Update_Column>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};

/** Ordering options when selecting data from "student_productivity_yearly". */
export type Student_Productivity_Yearly_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: student_productivity_yearly */
export type Student_Productivity_Yearly_Pk_Columns_Input = {
  student_productivity_yearly_id: Scalars['Int'];
};

/** select columns of table "student_productivity_yearly" */
export enum Student_Productivity_Yearly_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  PracticeSessions = 'practice_sessions',
  /** column name */
  RecordingSessions = 'recording_sessions',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentProductivityYearlyId = 'student_productivity_yearly_id',
  /** column name */
  VideoViews = 'video_views',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_yearly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Productivity_Yearly_Stddev_Fields = {
  __typename?: 'student_productivity_yearly_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Stddev_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Productivity_Yearly_Stddev_Pop_Fields = {
  __typename?: 'student_productivity_yearly_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Stddev_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Productivity_Yearly_Stddev_Samp_Fields = {
  __typename?: 'student_productivity_yearly_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Stddev_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Productivity_Yearly_Sum_Fields = {
  __typename?: 'student_productivity_yearly_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  practice_sessions?: Maybe<Scalars['Int']>;
  recording_sessions?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_productivity_yearly_id?: Maybe<Scalars['Int']>;
  video_views?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Sum_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "student_productivity_yearly" */
export enum Student_Productivity_Yearly_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  PracticeSessions = 'practice_sessions',
  /** column name */
  RecordingSessions = 'recording_sessions',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentProductivityYearlyId = 'student_productivity_yearly_id',
  /** column name */
  VideoViews = 'video_views',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Student_Productivity_Yearly_Var_Pop_Fields = {
  __typename?: 'student_productivity_yearly_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Var_Pop_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Productivity_Yearly_Var_Samp_Fields = {
  __typename?: 'student_productivity_yearly_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Var_Samp_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Productivity_Yearly_Variance_Fields = {
  __typename?: 'student_productivity_yearly_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  practice_sessions?: Maybe<Scalars['Float']>;
  recording_sessions?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_productivity_yearly_id?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student_productivity_yearly" */
export type Student_Productivity_Yearly_Variance_Order_By = {
  class_id?: Maybe<Order_By>;
  practice_sessions?: Maybe<Order_By>;
  recording_sessions?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_productivity_yearly_id?: Maybe<Order_By>;
  video_views?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** select columns of table "student" */
export enum Student_Select_Column {
  /** column name */
  ActivateMailSent = 'activate_mail_sent',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AvatarSelected = 'avatar_selected',
  /** column name */
  City = 'city',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  Code = 'code',
  /** column name */
  CoverImagePath = 'cover_image_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Grade = 'grade',
  /** column name */
  GraduationYear = 'graduation_year',
  /** column name */
  HasLtiIntegration = 'has_lti_integration',
  /** column name */
  HomeLanguage = 'home_language',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  Password = 'password',
  /** column name */
  Race = 'race',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  StageName = 'stage_name',
  /** column name */
  State = 'state',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  UnweightedGpa = 'unweighted_gpa',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightedGpa = 'weighted_gpa',
  /** column name */
  WurrlyRole = 'wurrly_role',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "student" */
export type Student_Set_Input = {
  activate_mail_sent?: Maybe<Scalars['Boolean']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  avatar_selected?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  /** deprecated */
  clever_id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cover_image_path?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  graduation_year?: Maybe<Scalars['Int']>;
  has_lti_integration?: Maybe<Scalars['Boolean']>;
  home_language?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_id?: Maybe<Scalars['String']>;
  /** deprecated */
  integration_name?: Maybe<Integration_Name_Enum>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  school_id?: Maybe<Scalars['Int']>;
  stage_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  student_id?: Maybe<Scalars['Int']>;
  unweighted_gpa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weighted_gpa?: Maybe<Scalars['String']>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
  zip?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "student_setting" */
export type Student_Setting = {
  __typename?: 'student_setting';
  dont_ask_again_record?: Maybe<Scalars['Boolean']>;
  favorites_dont_ask_again_to_delete?: Maybe<Scalars['Boolean']>;
  student_id: Scalars['Int'];
  student_setting_id: Scalars['Int'];
};

/** aggregated selection of "student_setting" */
export type Student_Setting_Aggregate = {
  __typename?: 'student_setting_aggregate';
  aggregate?: Maybe<Student_Setting_Aggregate_Fields>;
  nodes: Array<Student_Setting>;
};

/** aggregate fields of "student_setting" */
export type Student_Setting_Aggregate_Fields = {
  __typename?: 'student_setting_aggregate_fields';
  avg?: Maybe<Student_Setting_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Setting_Max_Fields>;
  min?: Maybe<Student_Setting_Min_Fields>;
  stddev?: Maybe<Student_Setting_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Setting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Setting_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Setting_Sum_Fields>;
  var_pop?: Maybe<Student_Setting_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Setting_Var_Samp_Fields>;
  variance?: Maybe<Student_Setting_Variance_Fields>;
};


/** aggregate fields of "student_setting" */
export type Student_Setting_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Setting_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Setting_Avg_Fields = {
  __typename?: 'student_setting_avg_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_setting". All fields are combined with a logical 'AND'. */
export type Student_Setting_Bool_Exp = {
  _and?: Maybe<Array<Student_Setting_Bool_Exp>>;
  _not?: Maybe<Student_Setting_Bool_Exp>;
  _or?: Maybe<Array<Student_Setting_Bool_Exp>>;
  dont_ask_again_record?: Maybe<Boolean_Comparison_Exp>;
  favorites_dont_ask_again_to_delete?: Maybe<Boolean_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_setting_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_setting" */
export enum Student_Setting_Constraint {
  /** unique or primary key constraint */
  StudentSettingPkey = 'student_setting_pkey',
  /** unique or primary key constraint */
  StudentSettingStudentIdKey = 'student_setting_student_id_key'
}

/** input type for incrementing numeric columns in table "student_setting" */
export type Student_Setting_Inc_Input = {
  student_id?: Maybe<Scalars['Int']>;
  student_setting_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_setting" */
export type Student_Setting_Insert_Input = {
  dont_ask_again_record?: Maybe<Scalars['Boolean']>;
  favorites_dont_ask_again_to_delete?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  student_setting_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Student_Setting_Max_Fields = {
  __typename?: 'student_setting_max_fields';
  student_id?: Maybe<Scalars['Int']>;
  student_setting_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Student_Setting_Min_Fields = {
  __typename?: 'student_setting_min_fields';
  student_id?: Maybe<Scalars['Int']>;
  student_setting_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "student_setting" */
export type Student_Setting_Mutation_Response = {
  __typename?: 'student_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Setting>;
};

/** input type for inserting object relation for remote table "student_setting" */
export type Student_Setting_Obj_Rel_Insert_Input = {
  data: Student_Setting_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Student_Setting_On_Conflict>;
};

/** on_conflict condition type for table "student_setting" */
export type Student_Setting_On_Conflict = {
  constraint: Student_Setting_Constraint;
  update_columns?: Array<Student_Setting_Update_Column>;
  where?: Maybe<Student_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "student_setting". */
export type Student_Setting_Order_By = {
  dont_ask_again_record?: Maybe<Order_By>;
  favorites_dont_ask_again_to_delete?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_setting_id?: Maybe<Order_By>;
};

/** primary key columns input for table: student_setting */
export type Student_Setting_Pk_Columns_Input = {
  student_setting_id: Scalars['Int'];
};

/** select columns of table "student_setting" */
export enum Student_Setting_Select_Column {
  /** column name */
  DontAskAgainRecord = 'dont_ask_again_record',
  /** column name */
  FavoritesDontAskAgainToDelete = 'favorites_dont_ask_again_to_delete',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentSettingId = 'student_setting_id'
}

/** input type for updating data in table "student_setting" */
export type Student_Setting_Set_Input = {
  dont_ask_again_record?: Maybe<Scalars['Boolean']>;
  favorites_dont_ask_again_to_delete?: Maybe<Scalars['Boolean']>;
  student_id?: Maybe<Scalars['Int']>;
  student_setting_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Student_Setting_Stddev_Fields = {
  __typename?: 'student_setting_stddev_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Setting_Stddev_Pop_Fields = {
  __typename?: 'student_setting_stddev_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Setting_Stddev_Samp_Fields = {
  __typename?: 'student_setting_stddev_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Setting_Sum_Fields = {
  __typename?: 'student_setting_sum_fields';
  student_id?: Maybe<Scalars['Int']>;
  student_setting_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "student_setting" */
export enum Student_Setting_Update_Column {
  /** column name */
  DontAskAgainRecord = 'dont_ask_again_record',
  /** column name */
  FavoritesDontAskAgainToDelete = 'favorites_dont_ask_again_to_delete',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentSettingId = 'student_setting_id'
}

/** aggregate var_pop on columns */
export type Student_Setting_Var_Pop_Fields = {
  __typename?: 'student_setting_var_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Setting_Var_Samp_Fields = {
  __typename?: 'student_setting_var_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Setting_Variance_Fields = {
  __typename?: 'student_setting_variance_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_setting_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "student_song_favorite" */
export type Student_Song_Favorite = {
  __typename?: 'student_song_favorite';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
  student_id: Scalars['Int'];
  student_song_favorite_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "student_song_favorite" */
export type Student_Song_Favorite_Aggregate = {
  __typename?: 'student_song_favorite_aggregate';
  aggregate?: Maybe<Student_Song_Favorite_Aggregate_Fields>;
  nodes: Array<Student_Song_Favorite>;
};

/** aggregate fields of "student_song_favorite" */
export type Student_Song_Favorite_Aggregate_Fields = {
  __typename?: 'student_song_favorite_aggregate_fields';
  avg?: Maybe<Student_Song_Favorite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Song_Favorite_Max_Fields>;
  min?: Maybe<Student_Song_Favorite_Min_Fields>;
  stddev?: Maybe<Student_Song_Favorite_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Song_Favorite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Song_Favorite_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Song_Favorite_Sum_Fields>;
  var_pop?: Maybe<Student_Song_Favorite_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Song_Favorite_Var_Samp_Fields>;
  variance?: Maybe<Student_Song_Favorite_Variance_Fields>;
};


/** aggregate fields of "student_song_favorite" */
export type Student_Song_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Song_Favorite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Song_Favorite_Avg_Fields = {
  __typename?: 'student_song_favorite_avg_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_song_favorite". All fields are combined with a logical 'AND'. */
export type Student_Song_Favorite_Bool_Exp = {
  _and?: Maybe<Array<Student_Song_Favorite_Bool_Exp>>;
  _not?: Maybe<Student_Song_Favorite_Bool_Exp>;
  _or?: Maybe<Array<Student_Song_Favorite_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_song_favorite_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_song_favorite" */
export enum Student_Song_Favorite_Constraint {
  /** unique or primary key constraint */
  StudentSongFavoritePkey = 'student_song_favorite_pkey'
}

/** input type for incrementing numeric columns in table "student_song_favorite" */
export type Student_Song_Favorite_Inc_Input = {
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_song_favorite_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_song_favorite" */
export type Student_Song_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Student_Song_Favorite_Max_Fields = {
  __typename?: 'student_song_favorite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Student_Song_Favorite_Min_Fields = {
  __typename?: 'student_song_favorite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "student_song_favorite" */
export type Student_Song_Favorite_Mutation_Response = {
  __typename?: 'student_song_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Song_Favorite>;
};

/** on_conflict condition type for table "student_song_favorite" */
export type Student_Song_Favorite_On_Conflict = {
  constraint: Student_Song_Favorite_Constraint;
  update_columns?: Array<Student_Song_Favorite_Update_Column>;
  where?: Maybe<Student_Song_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "student_song_favorite". */
export type Student_Song_Favorite_Order_By = {
  created_at?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_song_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: student_song_favorite */
export type Student_Song_Favorite_Pk_Columns_Input = {
  student_song_favorite_id: Scalars['Int'];
};

/** select columns of table "student_song_favorite" */
export enum Student_Song_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentSongFavoriteId = 'student_song_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "student_song_favorite" */
export type Student_Song_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Student_Song_Favorite_Stddev_Fields = {
  __typename?: 'student_song_favorite_stddev_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Song_Favorite_Stddev_Pop_Fields = {
  __typename?: 'student_song_favorite_stddev_pop_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Song_Favorite_Stddev_Samp_Fields = {
  __typename?: 'student_song_favorite_stddev_samp_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Song_Favorite_Sum_Fields = {
  __typename?: 'student_song_favorite_sum_fields';
  song_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  student_song_favorite_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "student_song_favorite" */
export enum Student_Song_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentSongFavoriteId = 'student_song_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Student_Song_Favorite_Var_Pop_Fields = {
  __typename?: 'student_song_favorite_var_pop_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Song_Favorite_Var_Samp_Fields = {
  __typename?: 'student_song_favorite_var_samp_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Song_Favorite_Variance_Fields = {
  __typename?: 'student_song_favorite_variance_fields';
  song_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  student_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Student_Stddev_Fields = {
  __typename?: 'student_stddev_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "student" */
export type Student_Stddev_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Student_Stddev_Pop_Fields = {
  __typename?: 'student_stddev_pop_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "student" */
export type Student_Stddev_Pop_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Student_Stddev_Samp_Fields = {
  __typename?: 'student_stddev_samp_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "student" */
export type Student_Stddev_Samp_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Student_Sum_Fields = {
  __typename?: 'student_sum_fields';
  graduation_year?: Maybe<Scalars['Int']>;
  school_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "student" */
export type Student_Sum_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** columns and relationships of "student_tip_favorite" */
export type Student_Tip_Favorite = {
  __typename?: 'student_tip_favorite';
  created_at: Scalars['timestamptz'];
  student_id: Scalars['Int'];
  student_tip_favorite_id: Scalars['Int'];
  /** An object relationship */
  tip?: Maybe<Tip>;
  tip_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "student_tip_favorite" */
export type Student_Tip_Favorite_Aggregate = {
  __typename?: 'student_tip_favorite_aggregate';
  aggregate?: Maybe<Student_Tip_Favorite_Aggregate_Fields>;
  nodes: Array<Student_Tip_Favorite>;
};

/** aggregate fields of "student_tip_favorite" */
export type Student_Tip_Favorite_Aggregate_Fields = {
  __typename?: 'student_tip_favorite_aggregate_fields';
  avg?: Maybe<Student_Tip_Favorite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Student_Tip_Favorite_Max_Fields>;
  min?: Maybe<Student_Tip_Favorite_Min_Fields>;
  stddev?: Maybe<Student_Tip_Favorite_Stddev_Fields>;
  stddev_pop?: Maybe<Student_Tip_Favorite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Student_Tip_Favorite_Stddev_Samp_Fields>;
  sum?: Maybe<Student_Tip_Favorite_Sum_Fields>;
  var_pop?: Maybe<Student_Tip_Favorite_Var_Pop_Fields>;
  var_samp?: Maybe<Student_Tip_Favorite_Var_Samp_Fields>;
  variance?: Maybe<Student_Tip_Favorite_Variance_Fields>;
};


/** aggregate fields of "student_tip_favorite" */
export type Student_Tip_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Student_Tip_Favorite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Student_Tip_Favorite_Avg_Fields = {
  __typename?: 'student_tip_favorite_avg_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "student_tip_favorite". All fields are combined with a logical 'AND'. */
export type Student_Tip_Favorite_Bool_Exp = {
  _and?: Maybe<Array<Student_Tip_Favorite_Bool_Exp>>;
  _not?: Maybe<Student_Tip_Favorite_Bool_Exp>;
  _or?: Maybe<Array<Student_Tip_Favorite_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  student_tip_favorite_id?: Maybe<Int_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "student_tip_favorite" */
export enum Student_Tip_Favorite_Constraint {
  /** unique or primary key constraint */
  StudentTipFavoritePkey = 'student_tip_favorite_pkey'
}

/** input type for incrementing numeric columns in table "student_tip_favorite" */
export type Student_Tip_Favorite_Inc_Input = {
  student_id?: Maybe<Scalars['Int']>;
  student_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "student_tip_favorite" */
export type Student_Tip_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  student_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Student_Tip_Favorite_Max_Fields = {
  __typename?: 'student_tip_favorite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  student_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Student_Tip_Favorite_Min_Fields = {
  __typename?: 'student_tip_favorite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  student_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "student_tip_favorite" */
export type Student_Tip_Favorite_Mutation_Response = {
  __typename?: 'student_tip_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Student_Tip_Favorite>;
};

/** on_conflict condition type for table "student_tip_favorite" */
export type Student_Tip_Favorite_On_Conflict = {
  constraint: Student_Tip_Favorite_Constraint;
  update_columns?: Array<Student_Tip_Favorite_Update_Column>;
  where?: Maybe<Student_Tip_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "student_tip_favorite". */
export type Student_Tip_Favorite_Order_By = {
  created_at?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  student_tip_favorite_id?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: student_tip_favorite */
export type Student_Tip_Favorite_Pk_Columns_Input = {
  student_tip_favorite_id: Scalars['Int'];
};

/** select columns of table "student_tip_favorite" */
export enum Student_Tip_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentTipFavoriteId = 'student_tip_favorite_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "student_tip_favorite" */
export type Student_Tip_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  student_id?: Maybe<Scalars['Int']>;
  student_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Student_Tip_Favorite_Stddev_Fields = {
  __typename?: 'student_tip_favorite_stddev_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Student_Tip_Favorite_Stddev_Pop_Fields = {
  __typename?: 'student_tip_favorite_stddev_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Student_Tip_Favorite_Stddev_Samp_Fields = {
  __typename?: 'student_tip_favorite_stddev_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Student_Tip_Favorite_Sum_Fields = {
  __typename?: 'student_tip_favorite_sum_fields';
  student_id?: Maybe<Scalars['Int']>;
  student_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "student_tip_favorite" */
export enum Student_Tip_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  StudentTipFavoriteId = 'student_tip_favorite_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Student_Tip_Favorite_Var_Pop_Fields = {
  __typename?: 'student_tip_favorite_var_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Student_Tip_Favorite_Var_Samp_Fields = {
  __typename?: 'student_tip_favorite_var_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Student_Tip_Favorite_Variance_Fields = {
  __typename?: 'student_tip_favorite_variance_fields';
  student_id?: Maybe<Scalars['Float']>;
  student_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** update columns of table "student" */
export enum Student_Update_Column {
  /** column name */
  ActivateMailSent = 'activate_mail_sent',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AvatarSelected = 'avatar_selected',
  /** column name */
  City = 'city',
  /** column name */
  CleverId = 'clever_id',
  /** column name */
  Code = 'code',
  /** column name */
  CoverImagePath = 'cover_image_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Grade = 'grade',
  /** column name */
  GraduationYear = 'graduation_year',
  /** column name */
  HasLtiIntegration = 'has_lti_integration',
  /** column name */
  HomeLanguage = 'home_language',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  Password = 'password',
  /** column name */
  Race = 'race',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  StageName = 'stage_name',
  /** column name */
  State = 'state',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  UnweightedGpa = 'unweighted_gpa',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightedGpa = 'weighted_gpa',
  /** column name */
  WurrlyRole = 'wurrly_role',
  /** column name */
  Zip = 'zip'
}

/** aggregate var_pop on columns */
export type Student_Var_Pop_Fields = {
  __typename?: 'student_var_pop_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "student" */
export type Student_Var_Pop_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Student_Var_Samp_Fields = {
  __typename?: 'student_var_samp_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "student" */
export type Student_Var_Samp_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Student_Variance_Fields = {
  __typename?: 'student_variance_fields';
  graduation_year?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "student" */
export type Student_Variance_Order_By = {
  graduation_year?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** columns and relationships of "submission" */
export type Submission = {
  __typename?: 'submission';
  active: Scalars['Boolean'];
  /** An object relationship */
  class_assignment?: Maybe<Class_Assignment>;
  class_assignment_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Scalars['timestamptz']>;
  external_file?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Submission_State_Enum>;
  student_comment?: Maybe<Scalars['String']>;
  /** An array relationship */
  submission_assets: Array<Submission_Asset>;
  /** An aggregate relationship */
  submission_assets_aggregate: Submission_Asset_Aggregate;
  submission_id: Scalars['Int'];
  /** An array relationship */
  submission_students: Array<Submission_Student>;
  /** An aggregate relationship */
  submission_students_aggregate: Submission_Student_Aggregate;
  /** An array relationship */
  submission_wurrlies: Array<Submission_Wurrly>;
  /** An aggregate relationship */
  submission_wurrlies_aggregate: Submission_Wurrly_Aggregate;
  teacher_comment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_AssetsArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_Assets_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_StudentsArgs = {
  distinct_on?: Maybe<Array<Submission_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Student_Order_By>>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_Students_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Student_Order_By>>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_WurrliesArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_Order_By>>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};


/** columns and relationships of "submission" */
export type SubmissionSubmission_Wurrlies_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_Order_By>>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};

/** aggregated selection of "submission" */
export type Submission_Aggregate = {
  __typename?: 'submission_aggregate';
  aggregate?: Maybe<Submission_Aggregate_Fields>;
  nodes: Array<Submission>;
};

/** aggregate fields of "submission" */
export type Submission_Aggregate_Fields = {
  __typename?: 'submission_aggregate_fields';
  avg?: Maybe<Submission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Submission_Max_Fields>;
  min?: Maybe<Submission_Min_Fields>;
  stddev?: Maybe<Submission_Stddev_Fields>;
  stddev_pop?: Maybe<Submission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Submission_Stddev_Samp_Fields>;
  sum?: Maybe<Submission_Sum_Fields>;
  var_pop?: Maybe<Submission_Var_Pop_Fields>;
  var_samp?: Maybe<Submission_Var_Samp_Fields>;
  variance?: Maybe<Submission_Variance_Fields>;
};


/** aggregate fields of "submission" */
export type Submission_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submission_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "submission" */
export type Submission_Aggregate_Order_By = {
  avg?: Maybe<Submission_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Submission_Max_Order_By>;
  min?: Maybe<Submission_Min_Order_By>;
  stddev?: Maybe<Submission_Stddev_Order_By>;
  stddev_pop?: Maybe<Submission_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Submission_Stddev_Samp_Order_By>;
  sum?: Maybe<Submission_Sum_Order_By>;
  var_pop?: Maybe<Submission_Var_Pop_Order_By>;
  var_samp?: Maybe<Submission_Var_Samp_Order_By>;
  variance?: Maybe<Submission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "submission" */
export type Submission_Arr_Rel_Insert_Input = {
  data: Array<Submission_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Submission_On_Conflict>;
};

/** relations with assets */
export type Submission_Asset = {
  __typename?: 'submission_asset';
  /** An object relationship */
  asset: Asset;
  asset_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  submission: Submission;
  submission_asset_id: Scalars['Int'];
  submission_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "submission_asset" */
export type Submission_Asset_Aggregate = {
  __typename?: 'submission_asset_aggregate';
  aggregate?: Maybe<Submission_Asset_Aggregate_Fields>;
  nodes: Array<Submission_Asset>;
};

/** aggregate fields of "submission_asset" */
export type Submission_Asset_Aggregate_Fields = {
  __typename?: 'submission_asset_aggregate_fields';
  avg?: Maybe<Submission_Asset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Submission_Asset_Max_Fields>;
  min?: Maybe<Submission_Asset_Min_Fields>;
  stddev?: Maybe<Submission_Asset_Stddev_Fields>;
  stddev_pop?: Maybe<Submission_Asset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Submission_Asset_Stddev_Samp_Fields>;
  sum?: Maybe<Submission_Asset_Sum_Fields>;
  var_pop?: Maybe<Submission_Asset_Var_Pop_Fields>;
  var_samp?: Maybe<Submission_Asset_Var_Samp_Fields>;
  variance?: Maybe<Submission_Asset_Variance_Fields>;
};


/** aggregate fields of "submission_asset" */
export type Submission_Asset_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submission_Asset_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "submission_asset" */
export type Submission_Asset_Aggregate_Order_By = {
  avg?: Maybe<Submission_Asset_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Submission_Asset_Max_Order_By>;
  min?: Maybe<Submission_Asset_Min_Order_By>;
  stddev?: Maybe<Submission_Asset_Stddev_Order_By>;
  stddev_pop?: Maybe<Submission_Asset_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Submission_Asset_Stddev_Samp_Order_By>;
  sum?: Maybe<Submission_Asset_Sum_Order_By>;
  var_pop?: Maybe<Submission_Asset_Var_Pop_Order_By>;
  var_samp?: Maybe<Submission_Asset_Var_Samp_Order_By>;
  variance?: Maybe<Submission_Asset_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "submission_asset" */
export type Submission_Asset_Arr_Rel_Insert_Input = {
  data: Array<Submission_Asset_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Submission_Asset_On_Conflict>;
};

/** aggregate avg on columns */
export type Submission_Asset_Avg_Fields = {
  __typename?: 'submission_asset_avg_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "submission_asset" */
export type Submission_Asset_Avg_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "submission_asset". All fields are combined with a logical 'AND'. */
export type Submission_Asset_Bool_Exp = {
  _and?: Maybe<Array<Submission_Asset_Bool_Exp>>;
  _not?: Maybe<Submission_Asset_Bool_Exp>;
  _or?: Maybe<Array<Submission_Asset_Bool_Exp>>;
  asset?: Maybe<Asset_Bool_Exp>;
  asset_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  submission?: Maybe<Submission_Bool_Exp>;
  submission_asset_id?: Maybe<Int_Comparison_Exp>;
  submission_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "submission_asset" */
export enum Submission_Asset_Constraint {
  /** unique or primary key constraint */
  SubmissionAssetPkey = 'submission_asset_pkey',
  /** unique or primary key constraint */
  SubmissionAssetSubmissionIdAssetIdKey = 'submission_asset_submission_id_asset_id_key'
}

/** input type for incrementing numeric columns in table "submission_asset" */
export type Submission_Asset_Inc_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  submission_asset_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "submission_asset" */
export type Submission_Asset_Insert_Input = {
  asset?: Maybe<Asset_Obj_Rel_Insert_Input>;
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  submission?: Maybe<Submission_Obj_Rel_Insert_Input>;
  submission_asset_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Submission_Asset_Max_Fields = {
  __typename?: 'submission_asset_max_fields';
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  submission_asset_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "submission_asset" */
export type Submission_Asset_Max_Order_By = {
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Submission_Asset_Min_Fields = {
  __typename?: 'submission_asset_min_fields';
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  submission_asset_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "submission_asset" */
export type Submission_Asset_Min_Order_By = {
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "submission_asset" */
export type Submission_Asset_Mutation_Response = {
  __typename?: 'submission_asset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission_Asset>;
};

/** on_conflict condition type for table "submission_asset" */
export type Submission_Asset_On_Conflict = {
  constraint: Submission_Asset_Constraint;
  update_columns?: Array<Submission_Asset_Update_Column>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};

/** Ordering options when selecting data from "submission_asset". */
export type Submission_Asset_Order_By = {
  asset?: Maybe<Asset_Order_By>;
  asset_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  submission?: Maybe<Submission_Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: submission_asset */
export type Submission_Asset_Pk_Columns_Input = {
  submission_asset_id: Scalars['Int'];
};

/** select columns of table "submission_asset" */
export enum Submission_Asset_Select_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SubmissionAssetId = 'submission_asset_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "submission_asset" */
export type Submission_Asset_Set_Input = {
  asset_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  submission_asset_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Submission_Asset_Stddev_Fields = {
  __typename?: 'submission_asset_stddev_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "submission_asset" */
export type Submission_Asset_Stddev_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Submission_Asset_Stddev_Pop_Fields = {
  __typename?: 'submission_asset_stddev_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "submission_asset" */
export type Submission_Asset_Stddev_Pop_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Submission_Asset_Stddev_Samp_Fields = {
  __typename?: 'submission_asset_stddev_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "submission_asset" */
export type Submission_Asset_Stddev_Samp_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Submission_Asset_Sum_Fields = {
  __typename?: 'submission_asset_sum_fields';
  asset_id?: Maybe<Scalars['Int']>;
  submission_asset_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "submission_asset" */
export type Submission_Asset_Sum_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** update columns of table "submission_asset" */
export enum Submission_Asset_Update_Column {
  /** column name */
  AssetId = 'asset_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SubmissionAssetId = 'submission_asset_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Submission_Asset_Var_Pop_Fields = {
  __typename?: 'submission_asset_var_pop_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "submission_asset" */
export type Submission_Asset_Var_Pop_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Submission_Asset_Var_Samp_Fields = {
  __typename?: 'submission_asset_var_samp_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "submission_asset" */
export type Submission_Asset_Var_Samp_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Submission_Asset_Variance_Fields = {
  __typename?: 'submission_asset_variance_fields';
  asset_id?: Maybe<Scalars['Float']>;
  submission_asset_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "submission_asset" */
export type Submission_Asset_Variance_Order_By = {
  asset_id?: Maybe<Order_By>;
  submission_asset_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Submission_Avg_Fields = {
  __typename?: 'submission_avg_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "submission" */
export type Submission_Avg_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "submission". All fields are combined with a logical 'AND'. */
export type Submission_Bool_Exp = {
  _and?: Maybe<Array<Submission_Bool_Exp>>;
  _not?: Maybe<Submission_Bool_Exp>;
  _or?: Maybe<Array<Submission_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  class_assignment?: Maybe<Class_Assignment_Bool_Exp>;
  class_assignment_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_submitted?: Maybe<Timestamptz_Comparison_Exp>;
  external_file?: Maybe<String_Comparison_Exp>;
  grade?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Submission_State_Enum_Comparison_Exp>;
  student_comment?: Maybe<String_Comparison_Exp>;
  submission_assets?: Maybe<Submission_Asset_Bool_Exp>;
  submission_id?: Maybe<Int_Comparison_Exp>;
  submission_students?: Maybe<Submission_Student_Bool_Exp>;
  submission_wurrlies?: Maybe<Submission_Wurrly_Bool_Exp>;
  teacher_comment?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "submission" */
export enum Submission_Constraint {
  /** unique or primary key constraint */
  ClassAssignmentStudentPkey = 'class_assignment_student_pkey'
}

/** input type for incrementing numeric columns in table "submission" */
export type Submission_Inc_Input = {
  class_assignment_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "submission" */
export type Submission_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  class_assignment?: Maybe<Class_Assignment_Obj_Rel_Insert_Input>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Scalars['timestamptz']>;
  external_file?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Submission_State_Enum>;
  student_comment?: Maybe<Scalars['String']>;
  submission_assets?: Maybe<Submission_Asset_Arr_Rel_Insert_Input>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_students?: Maybe<Submission_Student_Arr_Rel_Insert_Input>;
  submission_wurrlies?: Maybe<Submission_Wurrly_Arr_Rel_Insert_Input>;
  teacher_comment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Submission_Max_Fields = {
  __typename?: 'submission_max_fields';
  class_assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Scalars['timestamptz']>;
  external_file?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Scalars['String']>;
  student_comment?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['Int']>;
  teacher_comment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "submission" */
export type Submission_Max_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Order_By>;
  external_file?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Order_By>;
  student_comment?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  teacher_comment?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Submission_Min_Fields = {
  __typename?: 'submission_min_fields';
  class_assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Scalars['timestamptz']>;
  external_file?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Scalars['String']>;
  student_comment?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['Int']>;
  teacher_comment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "submission" */
export type Submission_Min_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Order_By>;
  external_file?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Order_By>;
  student_comment?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  teacher_comment?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "submission" */
export type Submission_Mutation_Response = {
  __typename?: 'submission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission>;
};

/** input type for inserting object relation for remote table "submission" */
export type Submission_Obj_Rel_Insert_Input = {
  data: Submission_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Submission_On_Conflict>;
};

/** on_conflict condition type for table "submission" */
export type Submission_On_Conflict = {
  constraint: Submission_Constraint;
  update_columns?: Array<Submission_Update_Column>;
  where?: Maybe<Submission_Bool_Exp>;
};

/** Ordering options when selecting data from "submission". */
export type Submission_Order_By = {
  active?: Maybe<Order_By>;
  class_assignment?: Maybe<Class_Assignment_Order_By>;
  class_assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_submitted?: Maybe<Order_By>;
  external_file?: Maybe<Order_By>;
  grade?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  student_comment?: Maybe<Order_By>;
  submission_assets_aggregate?: Maybe<Submission_Asset_Aggregate_Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_students_aggregate?: Maybe<Submission_Student_Aggregate_Order_By>;
  submission_wurrlies_aggregate?: Maybe<Submission_Wurrly_Aggregate_Order_By>;
  teacher_comment?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: submission */
export type Submission_Pk_Columns_Input = {
  submission_id: Scalars['Int'];
};

/** select columns of table "submission" */
export enum Submission_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ClassAssignmentId = 'class_assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSubmitted = 'date_submitted',
  /** column name */
  ExternalFile = 'external_file',
  /** column name */
  Grade = 'grade',
  /** column name */
  Name = 'name',
  /** column name */
  State = 'state',
  /** column name */
  StudentComment = 'student_comment',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  TeacherComment = 'teacher_comment',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "submission" */
export type Submission_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  class_assignment_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** date in which the student uploaded this submission */
  date_submitted?: Maybe<Scalars['timestamptz']>;
  external_file?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  /** This should be populated with the student name or by the teacher. */
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Submission_State_Enum>;
  student_comment?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['Int']>;
  teacher_comment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "submission_state" */
export type Submission_State = {
  __typename?: 'submission_state';
  name: Scalars['String'];
};

/** aggregated selection of "submission_state" */
export type Submission_State_Aggregate = {
  __typename?: 'submission_state_aggregate';
  aggregate?: Maybe<Submission_State_Aggregate_Fields>;
  nodes: Array<Submission_State>;
};

/** aggregate fields of "submission_state" */
export type Submission_State_Aggregate_Fields = {
  __typename?: 'submission_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Submission_State_Max_Fields>;
  min?: Maybe<Submission_State_Min_Fields>;
};


/** aggregate fields of "submission_state" */
export type Submission_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submission_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "submission_state". All fields are combined with a logical 'AND'. */
export type Submission_State_Bool_Exp = {
  _and?: Maybe<Array<Submission_State_Bool_Exp>>;
  _not?: Maybe<Submission_State_Bool_Exp>;
  _or?: Maybe<Array<Submission_State_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "submission_state" */
export enum Submission_State_Constraint {
  /** unique or primary key constraint */
  SubmissionStatePkey = 'submission_state_pkey'
}

export enum Submission_State_Enum {
  Graded = 'graded',
  InProgress = 'in_progress',
  Start = 'start',
  Submitted = 'submitted'
}

/** Boolean expression to compare columns of type "submission_state_enum". All fields are combined with logical 'AND'. */
export type Submission_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Submission_State_Enum>;
  _in?: Maybe<Array<Submission_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Submission_State_Enum>;
  _nin?: Maybe<Array<Submission_State_Enum>>;
};

/** input type for inserting data into table "submission_state" */
export type Submission_State_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Submission_State_Max_Fields = {
  __typename?: 'submission_state_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Submission_State_Min_Fields = {
  __typename?: 'submission_state_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "submission_state" */
export type Submission_State_Mutation_Response = {
  __typename?: 'submission_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission_State>;
};

/** on_conflict condition type for table "submission_state" */
export type Submission_State_On_Conflict = {
  constraint: Submission_State_Constraint;
  update_columns?: Array<Submission_State_Update_Column>;
  where?: Maybe<Submission_State_Bool_Exp>;
};

/** Ordering options when selecting data from "submission_state". */
export type Submission_State_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: submission_state */
export type Submission_State_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "submission_state" */
export enum Submission_State_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "submission_state" */
export type Submission_State_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "submission_state" */
export enum Submission_State_Update_Column {
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type Submission_Stddev_Fields = {
  __typename?: 'submission_stddev_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "submission" */
export type Submission_Stddev_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Submission_Stddev_Pop_Fields = {
  __typename?: 'submission_stddev_pop_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "submission" */
export type Submission_Stddev_Pop_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Submission_Stddev_Samp_Fields = {
  __typename?: 'submission_stddev_samp_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "submission" */
export type Submission_Stddev_Samp_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** columns and relationships of "submission_student" */
export type Submission_Student = {
  __typename?: 'submission_student';
  student_id: Scalars['Int'];
  submission_id: Scalars['Int'];
  submission_student_id: Scalars['Int'];
};

/** aggregated selection of "submission_student" */
export type Submission_Student_Aggregate = {
  __typename?: 'submission_student_aggregate';
  aggregate?: Maybe<Submission_Student_Aggregate_Fields>;
  nodes: Array<Submission_Student>;
};

/** aggregate fields of "submission_student" */
export type Submission_Student_Aggregate_Fields = {
  __typename?: 'submission_student_aggregate_fields';
  avg?: Maybe<Submission_Student_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Submission_Student_Max_Fields>;
  min?: Maybe<Submission_Student_Min_Fields>;
  stddev?: Maybe<Submission_Student_Stddev_Fields>;
  stddev_pop?: Maybe<Submission_Student_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Submission_Student_Stddev_Samp_Fields>;
  sum?: Maybe<Submission_Student_Sum_Fields>;
  var_pop?: Maybe<Submission_Student_Var_Pop_Fields>;
  var_samp?: Maybe<Submission_Student_Var_Samp_Fields>;
  variance?: Maybe<Submission_Student_Variance_Fields>;
};


/** aggregate fields of "submission_student" */
export type Submission_Student_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submission_Student_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "submission_student" */
export type Submission_Student_Aggregate_Order_By = {
  avg?: Maybe<Submission_Student_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Submission_Student_Max_Order_By>;
  min?: Maybe<Submission_Student_Min_Order_By>;
  stddev?: Maybe<Submission_Student_Stddev_Order_By>;
  stddev_pop?: Maybe<Submission_Student_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Submission_Student_Stddev_Samp_Order_By>;
  sum?: Maybe<Submission_Student_Sum_Order_By>;
  var_pop?: Maybe<Submission_Student_Var_Pop_Order_By>;
  var_samp?: Maybe<Submission_Student_Var_Samp_Order_By>;
  variance?: Maybe<Submission_Student_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "submission_student" */
export type Submission_Student_Arr_Rel_Insert_Input = {
  data: Array<Submission_Student_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Submission_Student_On_Conflict>;
};

/** aggregate avg on columns */
export type Submission_Student_Avg_Fields = {
  __typename?: 'submission_student_avg_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "submission_student" */
export type Submission_Student_Avg_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "submission_student". All fields are combined with a logical 'AND'. */
export type Submission_Student_Bool_Exp = {
  _and?: Maybe<Array<Submission_Student_Bool_Exp>>;
  _not?: Maybe<Submission_Student_Bool_Exp>;
  _or?: Maybe<Array<Submission_Student_Bool_Exp>>;
  student_id?: Maybe<Int_Comparison_Exp>;
  submission_id?: Maybe<Int_Comparison_Exp>;
  submission_student_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "submission_student" */
export enum Submission_Student_Constraint {
  /** unique or primary key constraint */
  SubmissionStudentPkey = 'submission_student_pkey'
}

/** input type for incrementing numeric columns in table "submission_student" */
export type Submission_Student_Inc_Input = {
  student_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_student_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "submission_student" */
export type Submission_Student_Insert_Input = {
  student_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_student_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Submission_Student_Max_Fields = {
  __typename?: 'submission_student_max_fields';
  student_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_student_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "submission_student" */
export type Submission_Student_Max_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Submission_Student_Min_Fields = {
  __typename?: 'submission_student_min_fields';
  student_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_student_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "submission_student" */
export type Submission_Student_Min_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "submission_student" */
export type Submission_Student_Mutation_Response = {
  __typename?: 'submission_student_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission_Student>;
};

/** on_conflict condition type for table "submission_student" */
export type Submission_Student_On_Conflict = {
  constraint: Submission_Student_Constraint;
  update_columns?: Array<Submission_Student_Update_Column>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};

/** Ordering options when selecting data from "submission_student". */
export type Submission_Student_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** primary key columns input for table: submission_student */
export type Submission_Student_Pk_Columns_Input = {
  submission_student_id: Scalars['Int'];
};

/** select columns of table "submission_student" */
export enum Submission_Student_Select_Column {
  /** column name */
  StudentId = 'student_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionStudentId = 'submission_student_id'
}

/** input type for updating data in table "submission_student" */
export type Submission_Student_Set_Input = {
  student_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_student_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Submission_Student_Stddev_Fields = {
  __typename?: 'submission_student_stddev_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "submission_student" */
export type Submission_Student_Stddev_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Submission_Student_Stddev_Pop_Fields = {
  __typename?: 'submission_student_stddev_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "submission_student" */
export type Submission_Student_Stddev_Pop_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Submission_Student_Stddev_Samp_Fields = {
  __typename?: 'submission_student_stddev_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "submission_student" */
export type Submission_Student_Stddev_Samp_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Submission_Student_Sum_Fields = {
  __typename?: 'submission_student_sum_fields';
  student_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_student_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "submission_student" */
export type Submission_Student_Sum_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** update columns of table "submission_student" */
export enum Submission_Student_Update_Column {
  /** column name */
  StudentId = 'student_id',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionStudentId = 'submission_student_id'
}

/** aggregate var_pop on columns */
export type Submission_Student_Var_Pop_Fields = {
  __typename?: 'submission_student_var_pop_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "submission_student" */
export type Submission_Student_Var_Pop_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Submission_Student_Var_Samp_Fields = {
  __typename?: 'submission_student_var_samp_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "submission_student" */
export type Submission_Student_Var_Samp_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Submission_Student_Variance_Fields = {
  __typename?: 'submission_student_variance_fields';
  student_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
  submission_student_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "submission_student" */
export type Submission_Student_Variance_Order_By = {
  student_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_student_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Submission_Sum_Fields = {
  __typename?: 'submission_sum_fields';
  class_assignment_id?: Maybe<Scalars['Int']>;
  submission_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "submission" */
export type Submission_Sum_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** update columns of table "submission" */
export enum Submission_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ClassAssignmentId = 'class_assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateSubmitted = 'date_submitted',
  /** column name */
  ExternalFile = 'external_file',
  /** column name */
  Grade = 'grade',
  /** column name */
  Name = 'name',
  /** column name */
  State = 'state',
  /** column name */
  StudentComment = 'student_comment',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  TeacherComment = 'teacher_comment',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Submission_Var_Pop_Fields = {
  __typename?: 'submission_var_pop_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "submission" */
export type Submission_Var_Pop_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Submission_Var_Samp_Fields = {
  __typename?: 'submission_var_samp_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "submission" */
export type Submission_Var_Samp_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Submission_Variance_Fields = {
  __typename?: 'submission_variance_fields';
  class_assignment_id?: Maybe<Scalars['Float']>;
  submission_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "submission" */
export type Submission_Variance_Order_By = {
  class_assignment_id?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
};

/** columns and relationships of "submission_wurrly" */
export type Submission_Wurrly = {
  __typename?: 'submission_wurrly';
  state: Submission_Wurrly_State_Enum;
  /** An object relationship */
  submission?: Maybe<Submission>;
  submission_id: Scalars['Int'];
  /** An object relationship */
  submission_wurrly_state: Submission_Wurrly_State;
  /** An object relationship */
  wurrly?: Maybe<Wurrly>;
  wurrly_id: Scalars['Int'];
};

/** aggregated selection of "submission_wurrly" */
export type Submission_Wurrly_Aggregate = {
  __typename?: 'submission_wurrly_aggregate';
  aggregate?: Maybe<Submission_Wurrly_Aggregate_Fields>;
  nodes: Array<Submission_Wurrly>;
};

/** aggregate fields of "submission_wurrly" */
export type Submission_Wurrly_Aggregate_Fields = {
  __typename?: 'submission_wurrly_aggregate_fields';
  avg?: Maybe<Submission_Wurrly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Submission_Wurrly_Max_Fields>;
  min?: Maybe<Submission_Wurrly_Min_Fields>;
  stddev?: Maybe<Submission_Wurrly_Stddev_Fields>;
  stddev_pop?: Maybe<Submission_Wurrly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Submission_Wurrly_Stddev_Samp_Fields>;
  sum?: Maybe<Submission_Wurrly_Sum_Fields>;
  var_pop?: Maybe<Submission_Wurrly_Var_Pop_Fields>;
  var_samp?: Maybe<Submission_Wurrly_Var_Samp_Fields>;
  variance?: Maybe<Submission_Wurrly_Variance_Fields>;
};


/** aggregate fields of "submission_wurrly" */
export type Submission_Wurrly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "submission_wurrly" */
export type Submission_Wurrly_Aggregate_Order_By = {
  avg?: Maybe<Submission_Wurrly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Submission_Wurrly_Max_Order_By>;
  min?: Maybe<Submission_Wurrly_Min_Order_By>;
  stddev?: Maybe<Submission_Wurrly_Stddev_Order_By>;
  stddev_pop?: Maybe<Submission_Wurrly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Submission_Wurrly_Stddev_Samp_Order_By>;
  sum?: Maybe<Submission_Wurrly_Sum_Order_By>;
  var_pop?: Maybe<Submission_Wurrly_Var_Pop_Order_By>;
  var_samp?: Maybe<Submission_Wurrly_Var_Samp_Order_By>;
  variance?: Maybe<Submission_Wurrly_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "submission_wurrly" */
export type Submission_Wurrly_Arr_Rel_Insert_Input = {
  data: Array<Submission_Wurrly_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Submission_Wurrly_On_Conflict>;
};

/** aggregate avg on columns */
export type Submission_Wurrly_Avg_Fields = {
  __typename?: 'submission_wurrly_avg_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Avg_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "submission_wurrly". All fields are combined with a logical 'AND'. */
export type Submission_Wurrly_Bool_Exp = {
  _and?: Maybe<Array<Submission_Wurrly_Bool_Exp>>;
  _not?: Maybe<Submission_Wurrly_Bool_Exp>;
  _or?: Maybe<Array<Submission_Wurrly_Bool_Exp>>;
  state?: Maybe<Submission_Wurrly_State_Enum_Comparison_Exp>;
  submission?: Maybe<Submission_Bool_Exp>;
  submission_id?: Maybe<Int_Comparison_Exp>;
  submission_wurrly_state?: Maybe<Submission_Wurrly_State_Bool_Exp>;
  wurrly?: Maybe<Wurrly_Bool_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "submission_wurrly" */
export enum Submission_Wurrly_Constraint {
  /** unique or primary key constraint */
  SubmissionWurrlyPkey = 'submission_wurrly_pkey'
}

/** input type for incrementing numeric columns in table "submission_wurrly" */
export type Submission_Wurrly_Inc_Input = {
  submission_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "submission_wurrly" */
export type Submission_Wurrly_Insert_Input = {
  state?: Maybe<Submission_Wurrly_State_Enum>;
  submission?: Maybe<Submission_Obj_Rel_Insert_Input>;
  submission_id?: Maybe<Scalars['Int']>;
  submission_wurrly_state?: Maybe<Submission_Wurrly_State_Obj_Rel_Insert_Input>;
  wurrly?: Maybe<Wurrly_Obj_Rel_Insert_Input>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Submission_Wurrly_Max_Fields = {
  __typename?: 'submission_wurrly_max_fields';
  submission_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Max_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Submission_Wurrly_Min_Fields = {
  __typename?: 'submission_wurrly_min_fields';
  submission_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Min_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "submission_wurrly" */
export type Submission_Wurrly_Mutation_Response = {
  __typename?: 'submission_wurrly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission_Wurrly>;
};

/** input type for inserting object relation for remote table "submission_wurrly" */
export type Submission_Wurrly_Obj_Rel_Insert_Input = {
  data: Submission_Wurrly_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Submission_Wurrly_On_Conflict>;
};

/** on_conflict condition type for table "submission_wurrly" */
export type Submission_Wurrly_On_Conflict = {
  constraint: Submission_Wurrly_Constraint;
  update_columns?: Array<Submission_Wurrly_Update_Column>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};

/** Ordering options when selecting data from "submission_wurrly". */
export type Submission_Wurrly_Order_By = {
  state?: Maybe<Order_By>;
  submission?: Maybe<Submission_Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_wurrly_state?: Maybe<Submission_Wurrly_State_Order_By>;
  wurrly?: Maybe<Wurrly_Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** primary key columns input for table: submission_wurrly */
export type Submission_Wurrly_Pk_Columns_Input = {
  wurrly_id: Scalars['Int'];
};

/** select columns of table "submission_wurrly" */
export enum Submission_Wurrly_Select_Column {
  /** column name */
  State = 'state',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** input type for updating data in table "submission_wurrly" */
export type Submission_Wurrly_Set_Input = {
  state?: Maybe<Submission_Wurrly_State_Enum>;
  submission_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "submission_wurrly_state" */
export type Submission_Wurrly_State = {
  __typename?: 'submission_wurrly_state';
  name: Scalars['String'];
};

/** aggregated selection of "submission_wurrly_state" */
export type Submission_Wurrly_State_Aggregate = {
  __typename?: 'submission_wurrly_state_aggregate';
  aggregate?: Maybe<Submission_Wurrly_State_Aggregate_Fields>;
  nodes: Array<Submission_Wurrly_State>;
};

/** aggregate fields of "submission_wurrly_state" */
export type Submission_Wurrly_State_Aggregate_Fields = {
  __typename?: 'submission_wurrly_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Submission_Wurrly_State_Max_Fields>;
  min?: Maybe<Submission_Wurrly_State_Min_Fields>;
};


/** aggregate fields of "submission_wurrly_state" */
export type Submission_Wurrly_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Submission_Wurrly_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "submission_wurrly_state". All fields are combined with a logical 'AND'. */
export type Submission_Wurrly_State_Bool_Exp = {
  _and?: Maybe<Array<Submission_Wurrly_State_Bool_Exp>>;
  _not?: Maybe<Submission_Wurrly_State_Bool_Exp>;
  _or?: Maybe<Array<Submission_Wurrly_State_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "submission_wurrly_state" */
export enum Submission_Wurrly_State_Constraint {
  /** unique or primary key constraint */
  SubmissionWurrlyStatePkey = 'submission_wurrly_state_pkey'
}

export enum Submission_Wurrly_State_Enum {
  Draft = 'draft',
  Submitted = 'submitted'
}

/** Boolean expression to compare columns of type "submission_wurrly_state_enum". All fields are combined with logical 'AND'. */
export type Submission_Wurrly_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Submission_Wurrly_State_Enum>;
  _in?: Maybe<Array<Submission_Wurrly_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Submission_Wurrly_State_Enum>;
  _nin?: Maybe<Array<Submission_Wurrly_State_Enum>>;
};

/** input type for inserting data into table "submission_wurrly_state" */
export type Submission_Wurrly_State_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Submission_Wurrly_State_Max_Fields = {
  __typename?: 'submission_wurrly_state_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Submission_Wurrly_State_Min_Fields = {
  __typename?: 'submission_wurrly_state_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "submission_wurrly_state" */
export type Submission_Wurrly_State_Mutation_Response = {
  __typename?: 'submission_wurrly_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Submission_Wurrly_State>;
};

/** input type for inserting object relation for remote table "submission_wurrly_state" */
export type Submission_Wurrly_State_Obj_Rel_Insert_Input = {
  data: Submission_Wurrly_State_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Submission_Wurrly_State_On_Conflict>;
};

/** on_conflict condition type for table "submission_wurrly_state" */
export type Submission_Wurrly_State_On_Conflict = {
  constraint: Submission_Wurrly_State_Constraint;
  update_columns?: Array<Submission_Wurrly_State_Update_Column>;
  where?: Maybe<Submission_Wurrly_State_Bool_Exp>;
};

/** Ordering options when selecting data from "submission_wurrly_state". */
export type Submission_Wurrly_State_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: submission_wurrly_state */
export type Submission_Wurrly_State_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "submission_wurrly_state" */
export enum Submission_Wurrly_State_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "submission_wurrly_state" */
export type Submission_Wurrly_State_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "submission_wurrly_state" */
export enum Submission_Wurrly_State_Update_Column {
  /** column name */
  Name = 'name'
}

/** aggregate stddev on columns */
export type Submission_Wurrly_Stddev_Fields = {
  __typename?: 'submission_wurrly_stddev_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Stddev_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Submission_Wurrly_Stddev_Pop_Fields = {
  __typename?: 'submission_wurrly_stddev_pop_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Stddev_Pop_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Submission_Wurrly_Stddev_Samp_Fields = {
  __typename?: 'submission_wurrly_stddev_samp_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Stddev_Samp_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Submission_Wurrly_Sum_Fields = {
  __typename?: 'submission_wurrly_sum_fields';
  submission_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Sum_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** update columns of table "submission_wurrly" */
export enum Submission_Wurrly_Update_Column {
  /** column name */
  State = 'state',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** aggregate var_pop on columns */
export type Submission_Wurrly_Var_Pop_Fields = {
  __typename?: 'submission_wurrly_var_pop_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Var_Pop_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Submission_Wurrly_Var_Samp_Fields = {
  __typename?: 'submission_wurrly_var_samp_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Var_Samp_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Submission_Wurrly_Variance_Fields = {
  __typename?: 'submission_wurrly_variance_fields';
  submission_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "submission_wurrly" */
export type Submission_Wurrly_Variance_Order_By = {
  submission_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account_activation" */
  account_activation: Array<Account_Activation>;
  /** fetch aggregated fields from the table: "account_activation" */
  account_activation_aggregate: Account_Activation_Aggregate;
  /** fetch data from the table: "account_activation" using primary key columns */
  account_activation_by_pk?: Maybe<Account_Activation>;
  /** fetch data from the table: "admin" */
  admin: Array<Admin>;
  /** fetch aggregated fields from the table: "admin" */
  admin_aggregate: Admin_Aggregate;
  /** fetch data from the table: "admin" using primary key columns */
  admin_by_pk?: Maybe<Admin>;
  /** fetch data from the table: "app" */
  app: Array<App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App>;
  /** fetch data from the table: "artist" */
  artist: Array<Artist>;
  /** fetch aggregated fields from the table: "artist" */
  artist_aggregate: Artist_Aggregate;
  /** fetch data from the table: "artist" using primary key columns */
  artist_by_pk?: Maybe<Artist>;
  /** fetch data from the table: "asset" */
  asset: Array<Asset>;
  /** fetch aggregated fields from the table: "asset" */
  asset_aggregate: Asset_Aggregate;
  /** fetch data from the table: "asset" using primary key columns */
  asset_by_pk?: Maybe<Asset>;
  /** fetch data from the table: "assignment" */
  assignment: Array<Assignment>;
  /** fetch aggregated fields from the table: "assignment" */
  assignment_aggregate: Assignment_Aggregate;
  /** fetch data from the table: "assignment_asset" */
  assignment_asset: Array<Assignment_Asset>;
  /** fetch aggregated fields from the table: "assignment_asset" */
  assignment_asset_aggregate: Assignment_Asset_Aggregate;
  /** fetch data from the table: "assignment_asset" using primary key columns */
  assignment_asset_by_pk?: Maybe<Assignment_Asset>;
  /** fetch data from the table: "assignment" using primary key columns */
  assignment_by_pk?: Maybe<Assignment>;
  /** fetch data from the table: "assignment_lesson_plan" */
  assignment_lesson_plan: Array<Assignment_Lesson_Plan>;
  /** fetch aggregated fields from the table: "assignment_lesson_plan" */
  assignment_lesson_plan_aggregate: Assignment_Lesson_Plan_Aggregate;
  /** fetch data from the table: "assignment_lesson_plan" using primary key columns */
  assignment_lesson_plan_by_pk?: Maybe<Assignment_Lesson_Plan>;
  /** fetch data from the table: "assignment_song" */
  assignment_song: Array<Assignment_Song>;
  /** fetch aggregated fields from the table: "assignment_song" */
  assignment_song_aggregate: Assignment_Song_Aggregate;
  /** fetch data from the table: "assignment_song" using primary key columns */
  assignment_song_by_pk?: Maybe<Assignment_Song>;
  /** fetch data from the table: "assignment_tip" */
  assignment_tip: Array<Assignment_Tip>;
  /** fetch aggregated fields from the table: "assignment_tip" */
  assignment_tip_aggregate: Assignment_Tip_Aggregate;
  /** fetch data from the table: "assignment_tip" using primary key columns */
  assignment_tip_by_pk?: Maybe<Assignment_Tip>;
  /** fetch data from the table: "available" */
  available: Array<Available>;
  /** fetch aggregated fields from the table: "available" */
  available_aggregate: Available_Aggregate;
  /** fetch data from the table: "available" using primary key columns */
  available_by_pk?: Maybe<Available>;
  /** fetch data from the table: "awarded_badge_event" */
  awarded_badge_event: Array<Awarded_Badge_Event>;
  /** fetch aggregated fields from the table: "awarded_badge_event" */
  awarded_badge_event_aggregate: Awarded_Badge_Event_Aggregate;
  /** fetch data from the table: "awarded_badge_event" using primary key columns */
  awarded_badge_event_by_pk?: Maybe<Awarded_Badge_Event>;
  /** fetch data from the table: "badge" */
  badge: Array<Badge>;
  /** fetch aggregated fields from the table: "badge" */
  badge_aggregate: Badge_Aggregate;
  /** fetch data from the table: "badge" using primary key columns */
  badge_by_pk?: Maybe<Badge>;
  /** fetch data from the table: "badge_event" */
  badge_event: Array<Badge_Event>;
  /** fetch aggregated fields from the table: "badge_event" */
  badge_event_aggregate: Badge_Event_Aggregate;
  /** fetch data from the table: "badge_event" using primary key columns */
  badge_event_by_pk?: Maybe<Badge_Event>;
  /** fetch data from the table: "badge_event_name" */
  badge_event_name: Array<Badge_Event_Name>;
  /** fetch aggregated fields from the table: "badge_event_name" */
  badge_event_name_aggregate: Badge_Event_Name_Aggregate;
  /** fetch data from the table: "badge_event_name" using primary key columns */
  badge_event_name_by_pk?: Maybe<Badge_Event_Name>;
  /** fetch data from the table: "billing_history" */
  billing_history: Array<Billing_History>;
  /** fetch aggregated fields from the table: "billing_history" */
  billing_history_aggregate: Billing_History_Aggregate;
  /** fetch data from the table: "billing_history" using primary key columns */
  billing_history_by_pk?: Maybe<Billing_History>;
  /** fetch data from the table: "billing_method" */
  billing_method: Array<Billing_Method>;
  /** fetch aggregated fields from the table: "billing_method" */
  billing_method_aggregate: Billing_Method_Aggregate;
  /** fetch data from the table: "billing_method" using primary key columns */
  billing_method_by_pk?: Maybe<Billing_Method>;
  /** fetch data from the table: "billing_status" */
  billing_status: Array<Billing_Status>;
  /** fetch aggregated fields from the table: "billing_status" */
  billing_status_aggregate: Billing_Status_Aggregate;
  /** fetch data from the table: "billing_status" using primary key columns */
  billing_status_by_pk?: Maybe<Billing_Status>;
  /** fetch data from the table: "catalog" */
  catalog: Array<Catalog>;
  /** fetch aggregated fields from the table: "catalog" */
  catalog_aggregate: Catalog_Aggregate;
  /** fetch data from the table: "catalog" using primary key columns */
  catalog_by_pk?: Maybe<Catalog>;
  /** fetch data from the table: "catalog_item" */
  catalog_item: Array<Catalog_Item>;
  /** fetch aggregated fields from the table: "catalog_item" */
  catalog_item_aggregate: Catalog_Item_Aggregate;
  /** fetch data from the table: "catalog_item" using primary key columns */
  catalog_item_by_pk?: Maybe<Catalog_Item>;
  /** fetch data from the table: "catalog_name" */
  catalog_name: Array<Catalog_Name>;
  /** fetch aggregated fields from the table: "catalog_name" */
  catalog_name_aggregate: Catalog_Name_Aggregate;
  /** fetch data from the table: "catalog_name" using primary key columns */
  catalog_name_by_pk?: Maybe<Catalog_Name>;
  /** fetch data from the table: "category_standard" */
  category_standard: Array<Category_Standard>;
  /** fetch aggregated fields from the table: "category_standard" */
  category_standard_aggregate: Category_Standard_Aggregate;
  /** fetch data from the table: "category_standard" using primary key columns */
  category_standard_by_pk?: Maybe<Category_Standard>;
  /** fetch data from the table: "challenge" */
  challenge: Array<Challenge>;
  /** fetch aggregated fields from the table: "challenge" */
  challenge_aggregate: Challenge_Aggregate;
  /** fetch data from the table: "challenge" using primary key columns */
  challenge_by_pk?: Maybe<Challenge>;
  /** fetch data from the table: "challenge_catalog_item" */
  challenge_catalog_item: Array<Challenge_Catalog_Item>;
  /** fetch aggregated fields from the table: "challenge_catalog_item" */
  challenge_catalog_item_aggregate: Challenge_Catalog_Item_Aggregate;
  /** fetch data from the table: "challenge_catalog_item" using primary key columns */
  challenge_catalog_item_by_pk?: Maybe<Challenge_Catalog_Item>;
  /** fetch data from the table: "challenge_district" */
  challenge_district: Array<Challenge_District>;
  /** fetch aggregated fields from the table: "challenge_district" */
  challenge_district_aggregate: Challenge_District_Aggregate;
  /** fetch data from the table: "challenge_district" using primary key columns */
  challenge_district_by_pk?: Maybe<Challenge_District>;
  /** fetch data from the table: "challenge_grade" */
  challenge_grade: Array<Challenge_Grade>;
  /** fetch aggregated fields from the table: "challenge_grade" */
  challenge_grade_aggregate: Challenge_Grade_Aggregate;
  /** fetch data from the table: "challenge_grade" using primary key columns */
  challenge_grade_by_pk?: Maybe<Challenge_Grade>;
  /** fetch data from the table: "challenge_song" */
  challenge_song: Array<Challenge_Song>;
  /** fetch aggregated fields from the table: "challenge_song" */
  challenge_song_aggregate: Challenge_Song_Aggregate;
  /** fetch data from the table: "challenge_song" using primary key columns */
  challenge_song_by_pk?: Maybe<Challenge_Song>;
  /** fetch data from the table: "challenge_student" */
  challenge_student: Array<Challenge_Student>;
  /** fetch aggregated fields from the table: "challenge_student" */
  challenge_student_aggregate: Challenge_Student_Aggregate;
  /** fetch data from the table: "challenge_student" using primary key columns */
  challenge_student_by_pk?: Maybe<Challenge_Student>;
  /** fetch data from the table: "channel" */
  channel: Array<Channel>;
  /** fetch aggregated fields from the table: "channel" */
  channel_aggregate: Channel_Aggregate;
  /** fetch data from the table: "channel" using primary key columns */
  channel_by_pk?: Maybe<Channel>;
  /** fetch data from the table: "channel_playlist" */
  channel_playlist: Array<Channel_Playlist>;
  /** fetch aggregated fields from the table: "channel_playlist" */
  channel_playlist_aggregate: Channel_Playlist_Aggregate;
  /** fetch data from the table: "channel_playlist" using primary key columns */
  channel_playlist_by_pk?: Maybe<Channel_Playlist>;
  /** fetch data from the table: "class" */
  class: Array<Class>;
  /** fetch aggregated fields from the table: "class" */
  class_aggregate: Class_Aggregate;
  /** fetch data from the table: "class_assignment" */
  class_assignment: Array<Class_Assignment>;
  /** fetch aggregated fields from the table: "class_assignment" */
  class_assignment_aggregate: Class_Assignment_Aggregate;
  /** fetch data from the table: "class_assignment" using primary key columns */
  class_assignment_by_pk?: Maybe<Class_Assignment>;
  /** fetch data from the table: "class_assignment_status" */
  class_assignment_status: Array<Class_Assignment_Status>;
  /** fetch aggregated fields from the table: "class_assignment_status" */
  class_assignment_status_aggregate: Class_Assignment_Status_Aggregate;
  /** fetch data from the table: "class_assignment_status" using primary key columns */
  class_assignment_status_by_pk?: Maybe<Class_Assignment_Status>;
  /** fetch data from the table: "class" using primary key columns */
  class_by_pk?: Maybe<Class>;
  /** fetch data from the table: "class_images" */
  class_images: Array<Class_Images>;
  /** fetch aggregated fields from the table: "class_images" */
  class_images_aggregate: Class_Images_Aggregate;
  /** fetch data from the table: "class_images" using primary key columns */
  class_images_by_pk?: Maybe<Class_Images>;
  /** fetch data from the table: "class_lesson_plan" */
  class_lesson_plan: Array<Class_Lesson_Plan>;
  /** fetch aggregated fields from the table: "class_lesson_plan" */
  class_lesson_plan_aggregate: Class_Lesson_Plan_Aggregate;
  /** fetch data from the table: "class_lesson_plan" using primary key columns */
  class_lesson_plan_by_pk?: Maybe<Class_Lesson_Plan>;
  /** fetch data from the table: "class_module" */
  class_module: Array<Class_Module>;
  /** fetch aggregated fields from the table: "class_module" */
  class_module_aggregate: Class_Module_Aggregate;
  /** fetch data from the table: "class_module" using primary key columns */
  class_module_by_pk?: Maybe<Class_Module>;
  /** fetch data from the table: "class_playlist" */
  class_playlist: Array<Class_Playlist>;
  /** fetch aggregated fields from the table: "class_playlist" */
  class_playlist_aggregate: Class_Playlist_Aggregate;
  /** fetch data from the table: "class_playlist" using primary key columns */
  class_playlist_by_pk?: Maybe<Class_Playlist>;
  /** fetch data from the table: "class_sequence" */
  class_sequence: Array<Class_Sequence>;
  /** fetch aggregated fields from the table: "class_sequence" */
  class_sequence_aggregate: Class_Sequence_Aggregate;
  /** fetch data from the table: "class_sequence" using primary key columns */
  class_sequence_by_pk?: Maybe<Class_Sequence>;
  /** fetch data from the table: "class_song" */
  class_song: Array<Class_Song>;
  /** fetch aggregated fields from the table: "class_song" */
  class_song_aggregate: Class_Song_Aggregate;
  /** fetch data from the table: "class_song" using primary key columns */
  class_song_by_pk?: Maybe<Class_Song>;
  /** fetch data from the table: "class_student" */
  class_student: Array<Class_Student>;
  /** fetch data from the table: "class_student_activities" */
  class_student_activities: Array<Class_Student_Activities>;
  /** fetch aggregated fields from the table: "class_student_activities" */
  class_student_activities_aggregate: Class_Student_Activities_Aggregate;
  /** fetch data from the table: "class_student_activities" using primary key columns */
  class_student_activities_by_pk?: Maybe<Class_Student_Activities>;
  /** fetch aggregated fields from the table: "class_student" */
  class_student_aggregate: Class_Student_Aggregate;
  /** fetch data from the table: "class_student" using primary key columns */
  class_student_by_pk?: Maybe<Class_Student>;
  /** fetch data from the table: "class_teacher" */
  class_teacher: Array<Class_Teacher>;
  /** fetch aggregated fields from the table: "class_teacher" */
  class_teacher_aggregate: Class_Teacher_Aggregate;
  /** fetch data from the table: "class_teacher" using primary key columns */
  class_teacher_by_pk?: Maybe<Class_Teacher>;
  /** fetch data from the table: "class_tip" */
  class_tip: Array<Class_Tip>;
  /** fetch aggregated fields from the table: "class_tip" */
  class_tip_aggregate: Class_Tip_Aggregate;
  /** fetch data from the table: "class_tip" using primary key columns */
  class_tip_by_pk?: Maybe<Class_Tip>;
  /** fetch data from the table: "clever_roster_log" */
  clever_roster_log: Array<Clever_Roster_Log>;
  /** fetch aggregated fields from the table: "clever_roster_log" */
  clever_roster_log_aggregate: Clever_Roster_Log_Aggregate;
  /** fetch data from the table: "clever_roster_log" using primary key columns */
  clever_roster_log_by_pk?: Maybe<Clever_Roster_Log>;
  /** fetch data from the table: "clever_roster_status" */
  clever_roster_status: Array<Clever_Roster_Status>;
  /** fetch aggregated fields from the table: "clever_roster_status" */
  clever_roster_status_aggregate: Clever_Roster_Status_Aggregate;
  /** fetch data from the table: "clever_roster_status" using primary key columns */
  clever_roster_status_by_pk?: Maybe<Clever_Roster_Status>;
  /** fetch data from the table: "contact_us" */
  contact_us: Array<Contact_Us>;
  /** fetch aggregated fields from the table: "contact_us" */
  contact_us_aggregate: Contact_Us_Aggregate;
  /** fetch data from the table: "contact_us" using primary key columns */
  contact_us_by_pk?: Maybe<Contact_Us>;
  /** fetch data from the table: "contact_us_camp_teacher" */
  contact_us_camp_teacher: Array<Contact_Us_Camp_Teacher>;
  /** fetch aggregated fields from the table: "contact_us_camp_teacher" */
  contact_us_camp_teacher_aggregate: Contact_Us_Camp_Teacher_Aggregate;
  /** fetch data from the table: "contact_us_camp_teacher" using primary key columns */
  contact_us_camp_teacher_by_pk?: Maybe<Contact_Us_Camp_Teacher>;
  /** fetch data from the table: "crossclear.masterlist" */
  crossclear_masterlist: Array<Crossclear_Masterlist>;
  /** fetch aggregated fields from the table: "crossclear.masterlist" */
  crossclear_masterlist_aggregate: Crossclear_Masterlist_Aggregate;
  /** fetch data from the table: "crossclear.masterlist" using primary key columns */
  crossclear_masterlist_by_pk?: Maybe<Crossclear_Masterlist>;
  /** fetch data from the table: "discipline" */
  discipline: Array<Discipline>;
  /** fetch aggregated fields from the table: "discipline" */
  discipline_aggregate: Discipline_Aggregate;
  /** fetch data from the table: "discipline" using primary key columns */
  discipline_by_pk?: Maybe<Discipline>;
  /** fetch data from the table: "district" */
  district: Array<District>;
  /** fetch data from the table: "district_admin" */
  district_admin: Array<District_Admin>;
  /** fetch aggregated fields from the table: "district_admin" */
  district_admin_aggregate: District_Admin_Aggregate;
  /** fetch data from the table: "district_admin" using primary key columns */
  district_admin_by_pk?: Maybe<District_Admin>;
  /** fetch aggregated fields from the table: "district" */
  district_aggregate: District_Aggregate;
  /** fetch data from the table: "district" using primary key columns */
  district_by_pk?: Maybe<District>;
  /** fetch data from the table: "filter_color" */
  filter_color: Array<Filter_Color>;
  /** fetch aggregated fields from the table: "filter_color" */
  filter_color_aggregate: Filter_Color_Aggregate;
  /** fetch data from the table: "filter_color" using primary key columns */
  filter_color_by_pk?: Maybe<Filter_Color>;
  /** fetch data from the table: "filter_color_name" */
  filter_color_name: Array<Filter_Color_Name>;
  /** fetch aggregated fields from the table: "filter_color_name" */
  filter_color_name_aggregate: Filter_Color_Name_Aggregate;
  /** fetch data from the table: "filter_color_name" using primary key columns */
  filter_color_name_by_pk?: Maybe<Filter_Color_Name>;
  /** fetch data from the table: "filter_frame" */
  filter_frame: Array<Filter_Frame>;
  /** fetch aggregated fields from the table: "filter_frame" */
  filter_frame_aggregate: Filter_Frame_Aggregate;
  /** fetch data from the table: "filter_sticker" */
  filter_sticker: Array<Filter_Sticker>;
  /** fetch aggregated fields from the table: "filter_sticker" */
  filter_sticker_aggregate: Filter_Sticker_Aggregate;
  /** fetch data from the table: "filter_sticker" using primary key columns */
  filter_sticker_by_pk?: Maybe<Filter_Sticker>;
  /** fetch data from the table: "grade" */
  grade: Array<Grade>;
  /** fetch aggregated fields from the table: "grade" */
  grade_aggregate: Grade_Aggregate;
  /** fetch data from the table: "grade" using primary key columns */
  grade_by_pk?: Maybe<Grade>;
  /** fetch data from the table: "grading_system" */
  grading_system: Array<Grading_System>;
  /** fetch aggregated fields from the table: "grading_system" */
  grading_system_aggregate: Grading_System_Aggregate;
  /** fetch data from the table: "grading_system" using primary key columns */
  grading_system_by_pk?: Maybe<Grading_System>;
  /** fetch data from the table: "grading_system_type" */
  grading_system_type: Array<Grading_System_Type>;
  /** fetch aggregated fields from the table: "grading_system_type" */
  grading_system_type_aggregate: Grading_System_Type_Aggregate;
  /** fetch data from the table: "grading_system_type" using primary key columns */
  grading_system_type_by_pk?: Maybe<Grading_System_Type>;
  /** fetch data from the table: "integration_name" */
  integration_name: Array<Integration_Name>;
  /** fetch aggregated fields from the table: "integration_name" */
  integration_name_aggregate: Integration_Name_Aggregate;
  /** fetch data from the table: "integration_name" using primary key columns */
  integration_name_by_pk?: Maybe<Integration_Name>;
  /** fetch data from the table: "invitation_status" */
  invitation_status: Array<Invitation_Status>;
  /** fetch aggregated fields from the table: "invitation_status" */
  invitation_status_aggregate: Invitation_Status_Aggregate;
  /** fetch data from the table: "invitation_status" using primary key columns */
  invitation_status_by_pk?: Maybe<Invitation_Status>;
  /** fetch data from the table: "keyword" */
  keyword: Array<Keyword>;
  /** fetch aggregated fields from the table: "keyword" */
  keyword_aggregate: Keyword_Aggregate;
  /** fetch data from the table: "keyword" using primary key columns */
  keyword_by_pk?: Maybe<Keyword>;
  /** fetch data from the table: "label_control" */
  label_control: Array<Label_Control>;
  /** fetch aggregated fields from the table: "label_control" */
  label_control_aggregate: Label_Control_Aggregate;
  /** fetch data from the table: "label_control" using primary key columns */
  label_control_by_pk?: Maybe<Label_Control>;
  /** fetch data from the table: "labels" */
  labels: Array<Labels>;
  /** fetch aggregated fields from the table: "labels" */
  labels_aggregate: Labels_Aggregate;
  /** fetch data from the table: "labels" using primary key columns */
  labels_by_pk?: Maybe<Labels>;
  /** fetch data from the table: "lesson_plan" */
  lesson_plan: Array<Lesson_Plan>;
  /** fetch aggregated fields from the table: "lesson_plan" */
  lesson_plan_aggregate: Lesson_Plan_Aggregate;
  /** fetch data from the table: "lesson_plan_article" */
  lesson_plan_article: Array<Lesson_Plan_Article>;
  /** fetch aggregated fields from the table: "lesson_plan_article" */
  lesson_plan_article_aggregate: Lesson_Plan_Article_Aggregate;
  /** fetch data from the table: "lesson_plan_article" using primary key columns */
  lesson_plan_article_by_pk?: Maybe<Lesson_Plan_Article>;
  /** An array relationship */
  lesson_plan_asset: Array<Lesson_Plan_Asset>;
  /** An aggregate relationship */
  lesson_plan_asset_aggregate: Lesson_Plan_Asset_Aggregate;
  /** fetch data from the table: "lesson_plan_asset" using primary key columns */
  lesson_plan_asset_by_pk?: Maybe<Lesson_Plan_Asset>;
  /** fetch data from the table: "lesson_plan" using primary key columns */
  lesson_plan_by_pk?: Maybe<Lesson_Plan>;
  /** An array relationship */
  lesson_plan_catalog_item: Array<Lesson_Plan_Catalog_Item>;
  /** An aggregate relationship */
  lesson_plan_catalog_item_aggregate: Lesson_Plan_Catalog_Item_Aggregate;
  /** fetch data from the table: "lesson_plan_catalog_item" using primary key columns */
  lesson_plan_catalog_item_by_pk?: Maybe<Lesson_Plan_Catalog_Item>;
  /** fetch data from the table: "lesson_plan_discipline" */
  lesson_plan_discipline: Array<Lesson_Plan_Discipline>;
  /** fetch aggregated fields from the table: "lesson_plan_discipline" */
  lesson_plan_discipline_aggregate: Lesson_Plan_Discipline_Aggregate;
  /** fetch data from the table: "lesson_plan_discipline" using primary key columns */
  lesson_plan_discipline_by_pk?: Maybe<Lesson_Plan_Discipline>;
  /** An array relationship */
  lesson_plan_grade: Array<Lesson_Plan_Grade>;
  /** An aggregate relationship */
  lesson_plan_grade_aggregate: Lesson_Plan_Grade_Aggregate;
  /** fetch data from the table: "lesson_plan_grade" using primary key columns */
  lesson_plan_grade_by_pk?: Maybe<Lesson_Plan_Grade>;
  /** An array relationship */
  lesson_plan_keyword: Array<Lesson_Plan_Keyword>;
  /** An aggregate relationship */
  lesson_plan_keyword_aggregate: Lesson_Plan_Keyword_Aggregate;
  /** fetch data from the table: "lesson_plan_keyword" using primary key columns */
  lesson_plan_keyword_by_pk?: Maybe<Lesson_Plan_Keyword>;
  /** An array relationship */
  lesson_plan_musical_skill: Array<Lesson_Plan_Musical_Skill>;
  /** An aggregate relationship */
  lesson_plan_musical_skill_aggregate: Lesson_Plan_Musical_Skill_Aggregate;
  /** fetch data from the table: "lesson_plan_musical_skill" using primary key columns */
  lesson_plan_musical_skill_by_pk?: Maybe<Lesson_Plan_Musical_Skill>;
  /** An array relationship */
  lesson_plan_non_musical_skill: Array<Lesson_Plan_Non_Musical_Skill>;
  /** An aggregate relationship */
  lesson_plan_non_musical_skill_aggregate: Lesson_Plan_Non_Musical_Skill_Aggregate;
  /** fetch data from the table: "lesson_plan_non_musical_skill" using primary key columns */
  lesson_plan_non_musical_skill_by_pk?: Maybe<Lesson_Plan_Non_Musical_Skill>;
  /** An array relationship */
  lesson_plan_skill_level: Array<Lesson_Plan_Skill_Level>;
  /** An aggregate relationship */
  lesson_plan_skill_level_aggregate: Lesson_Plan_Skill_Level_Aggregate;
  /** fetch data from the table: "lesson_plan_skill_level" using primary key columns */
  lesson_plan_skill_level_by_pk?: Maybe<Lesson_Plan_Skill_Level>;
  /** An array relationship */
  lesson_plan_song: Array<Lesson_Plan_Song>;
  /** An aggregate relationship */
  lesson_plan_song_aggregate: Lesson_Plan_Song_Aggregate;
  /** fetch data from the table: "lesson_plan_song" using primary key columns */
  lesson_plan_song_by_pk?: Maybe<Lesson_Plan_Song>;
  /** fetch data from the table: "lesson_plan_standard" */
  lesson_plan_standard: Array<Lesson_Plan_Standard>;
  /** fetch aggregated fields from the table: "lesson_plan_standard" */
  lesson_plan_standard_aggregate: Lesson_Plan_Standard_Aggregate;
  /** fetch data from the table: "lesson_plan_standard" using primary key columns */
  lesson_plan_standard_by_pk?: Maybe<Lesson_Plan_Standard>;
  /** An array relationship */
  lesson_plan_tip: Array<Lesson_Plan_Tip>;
  /** An aggregate relationship */
  lesson_plan_tip_aggregate: Lesson_Plan_Tip_Aggregate;
  /** fetch data from the table: "lesson_plan_tip" using primary key columns */
  lesson_plan_tip_by_pk?: Maybe<Lesson_Plan_Tip>;
  /** fetch data from the table: "lockable_content" */
  lockable_content: Array<Lockable_Content>;
  /** fetch aggregated fields from the table: "lockable_content" */
  lockable_content_aggregate: Lockable_Content_Aggregate;
  /** fetch data from the table: "lockable_content" using primary key columns */
  lockable_content_by_pk?: Maybe<Lockable_Content>;
  /** fetch data from the table: "lockable_content_class" */
  lockable_content_class: Array<Lockable_Content_Class>;
  /** fetch aggregated fields from the table: "lockable_content_class" */
  lockable_content_class_aggregate: Lockable_Content_Class_Aggregate;
  /** fetch data from the table: "lockable_content_class" using primary key columns */
  lockable_content_class_by_pk?: Maybe<Lockable_Content_Class>;
  /** fetch data from the table: "lockable_content_district" */
  lockable_content_district: Array<Lockable_Content_District>;
  /** fetch aggregated fields from the table: "lockable_content_district" */
  lockable_content_district_aggregate: Lockable_Content_District_Aggregate;
  /** fetch data from the table: "lockable_content_district" using primary key columns */
  lockable_content_district_by_pk?: Maybe<Lockable_Content_District>;
  /** fetch data from the table: "lockable_content_school" */
  lockable_content_school: Array<Lockable_Content_School>;
  /** fetch aggregated fields from the table: "lockable_content_school" */
  lockable_content_school_aggregate: Lockable_Content_School_Aggregate;
  /** fetch data from the table: "lockable_content_school" using primary key columns */
  lockable_content_school_by_pk?: Maybe<Lockable_Content_School>;
  /** fetch data from the table: "lockable_content_teacher" */
  lockable_content_teacher: Array<Lockable_Content_Teacher>;
  /** fetch aggregated fields from the table: "lockable_content_teacher" */
  lockable_content_teacher_aggregate: Lockable_Content_Teacher_Aggregate;
  /** fetch data from the table: "lockable_content_teacher" using primary key columns */
  lockable_content_teacher_by_pk?: Maybe<Lockable_Content_Teacher>;
  /** fetch data from the table: "maturity_level" */
  maturity_level: Array<Maturity_Level>;
  /** fetch aggregated fields from the table: "maturity_level" */
  maturity_level_aggregate: Maturity_Level_Aggregate;
  /** fetch data from the table: "maturity_level" using primary key columns */
  maturity_level_by_pk?: Maybe<Maturity_Level>;
  /** fetch data from the table: "membership_type" */
  membership_type: Array<Membership_Type>;
  /** fetch aggregated fields from the table: "membership_type" */
  membership_type_aggregate: Membership_Type_Aggregate;
  /** fetch data from the table: "membership_type" using primary key columns */
  membership_type_by_pk?: Maybe<Membership_Type>;
  /** fetch data from the table: "midi" */
  midi: Array<Midi>;
  /** fetch aggregated fields from the table: "midi" */
  midi_aggregate: Midi_Aggregate;
  /** fetch data from the table: "midi" using primary key columns */
  midi_by_pk?: Maybe<Midi>;
  /** fetch data from the table: "module" */
  module: Array<Module>;
  /** fetch aggregated fields from the table: "module" */
  module_aggregate: Module_Aggregate;
  /** fetch data from the table: "module" using primary key columns */
  module_by_pk?: Maybe<Module>;
  /** fetch data from the table: "module_catalog_item" */
  module_catalog_item: Array<Module_Catalog_Item>;
  /** fetch aggregated fields from the table: "module_catalog_item" */
  module_catalog_item_aggregate: Module_Catalog_Item_Aggregate;
  /** fetch data from the table: "module_catalog_item" using primary key columns */
  module_catalog_item_by_pk?: Maybe<Module_Catalog_Item>;
  /** fetch data from the table: "module_grade" */
  module_grade: Array<Module_Grade>;
  /** fetch aggregated fields from the table: "module_grade" */
  module_grade_aggregate: Module_Grade_Aggregate;
  /** fetch data from the table: "module_grade" using primary key columns */
  module_grade_by_pk?: Maybe<Module_Grade>;
  /** An array relationship */
  module_lesson_plan: Array<Module_Lesson_Plan>;
  /** An aggregate relationship */
  module_lesson_plan_aggregate: Module_Lesson_Plan_Aggregate;
  /** fetch data from the table: "module_lesson_plan" using primary key columns */
  module_lesson_plan_by_pk?: Maybe<Module_Lesson_Plan>;
  /** fetch data from the table: "musical_skill" */
  musical_skill: Array<Musical_Skill>;
  /** fetch aggregated fields from the table: "musical_skill" */
  musical_skill_aggregate: Musical_Skill_Aggregate;
  /** fetch data from the table: "musical_skill" using primary key columns */
  musical_skill_by_pk?: Maybe<Musical_Skill>;
  /** fetch data from the table: "non_musical_skill" */
  non_musical_skill: Array<Non_Musical_Skill>;
  /** fetch aggregated fields from the table: "non_musical_skill" */
  non_musical_skill_aggregate: Non_Musical_Skill_Aggregate;
  /** fetch data from the table: "non_musical_skill" using primary key columns */
  non_musical_skill_by_pk?: Maybe<Non_Musical_Skill>;
  /** fetch data from the table: "non_musical_skill_type" */
  non_musical_skill_type: Array<Non_Musical_Skill_Type>;
  /** fetch aggregated fields from the table: "non_musical_skill_type" */
  non_musical_skill_type_aggregate: Non_Musical_Skill_Type_Aggregate;
  /** fetch data from the table: "non_musical_skill_type" using primary key columns */
  non_musical_skill_type_by_pk?: Maybe<Non_Musical_Skill_Type>;
  /** fetch data from the table: "order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "order" */
  order_aggregate: Order_Aggregate;
  /** fetch data from the table: "order" using primary key columns */
  order_by_pk?: Maybe<Order>;
  /** fetch data from the table: "order_status" */
  order_status: Array<Order_Status>;
  /** fetch aggregated fields from the table: "order_status" */
  order_status_aggregate: Order_Status_Aggregate;
  /** fetch data from the table: "order_status" using primary key columns */
  order_status_by_pk?: Maybe<Order_Status>;
  /** fetch data from the table: "parent_invitation" */
  parent_invitation: Array<Parent_Invitation>;
  /** fetch aggregated fields from the table: "parent_invitation" */
  parent_invitation_aggregate: Parent_Invitation_Aggregate;
  /** fetch data from the table: "parent_invitation" using primary key columns */
  parent_invitation_by_pk?: Maybe<Parent_Invitation>;
  /** fetch data from the table: "partner" */
  partner: Array<Partner>;
  /** fetch aggregated fields from the table: "partner" */
  partner_aggregate: Partner_Aggregate;
  /** fetch data from the table: "partner" using primary key columns */
  partner_by_pk?: Maybe<Partner>;
  /** fetch data from the table: "partner_module" */
  partner_module: Array<Partner_Module>;
  /** fetch aggregated fields from the table: "partner_module" */
  partner_module_aggregate: Partner_Module_Aggregate;
  /** fetch data from the table: "partner_module" using primary key columns */
  partner_module_by_pk?: Maybe<Partner_Module>;
  /** fetch data from the table: "partner_playlist" */
  partner_playlist: Array<Partner_Playlist>;
  /** fetch aggregated fields from the table: "partner_playlist" */
  partner_playlist_aggregate: Partner_Playlist_Aggregate;
  /** fetch data from the table: "partner_playlist" using primary key columns */
  partner_playlist_by_pk?: Maybe<Partner_Playlist>;
  /** fetch data from the table: "playlist" */
  playlist: Array<Playlist>;
  /** fetch aggregated fields from the table: "playlist" */
  playlist_aggregate: Playlist_Aggregate;
  /** fetch data from the table: "playlist" using primary key columns */
  playlist_by_pk?: Maybe<Playlist>;
  /** fetch data from the table: "price_type" */
  price_type: Array<Price_Type>;
  /** fetch aggregated fields from the table: "price_type" */
  price_type_aggregate: Price_Type_Aggregate;
  /** fetch data from the table: "price_type" using primary key columns */
  price_type_by_pk?: Maybe<Price_Type>;
  /** fetch data from the table: "privacy" */
  privacy: Array<Privacy>;
  /** fetch aggregated fields from the table: "privacy" */
  privacy_aggregate: Privacy_Aggregate;
  /** fetch data from the table: "privacy" using primary key columns */
  privacy_by_pk?: Maybe<Privacy>;
  /** fetch data from the table: "product_name" */
  product_name: Array<Product_Name>;
  /** fetch aggregated fields from the table: "product_name" */
  product_name_aggregate: Product_Name_Aggregate;
  /** fetch data from the table: "product_name" using primary key columns */
  product_name_by_pk?: Maybe<Product_Name>;
  /** fetch data from the table: "publisher" */
  publisher: Array<Publisher>;
  /** fetch aggregated fields from the table: "publisher" */
  publisher_aggregate: Publisher_Aggregate;
  /** fetch data from the table: "publisher" using primary key columns */
  publisher_by_pk?: Maybe<Publisher>;
  /** fetch data from the table: "publisher_control" */
  publisher_control: Array<Publisher_Control>;
  /** fetch aggregated fields from the table: "publisher_control" */
  publisher_control_aggregate: Publisher_Control_Aggregate;
  /** fetch data from the table: "publisher_control" using primary key columns */
  publisher_control_by_pk?: Maybe<Publisher_Control>;
  /** fetch data from the table: "reaction" */
  reaction: Array<Reaction>;
  /** fetch aggregated fields from the table: "reaction" */
  reaction_aggregate: Reaction_Aggregate;
  /** fetch data from the table: "reaction" using primary key columns */
  reaction_by_pk?: Maybe<Reaction>;
  /** fetch data from the table: "reset_password" */
  reset_password: Array<Reset_Password>;
  /** fetch aggregated fields from the table: "reset_password" */
  reset_password_aggregate: Reset_Password_Aggregate;
  /** fetch data from the table: "reset_password" using primary key columns */
  reset_password_by_pk?: Maybe<Reset_Password>;
  /** fetch data from the table: "role_name" */
  role_name: Array<Role_Name>;
  /** fetch aggregated fields from the table: "role_name" */
  role_name_aggregate: Role_Name_Aggregate;
  /** fetch data from the table: "role_name" using primary key columns */
  role_name_by_pk?: Maybe<Role_Name>;
  /** fetch data from the table: "royalty_log" */
  royalty_log: Array<Royalty_Log>;
  /** fetch aggregated fields from the table: "royalty_log" */
  royalty_log_aggregate: Royalty_Log_Aggregate;
  /** fetch data from the table: "royalty_log" using primary key columns */
  royalty_log_by_pk?: Maybe<Royalty_Log>;
  /** fetch data from the table: "scale" */
  scale: Array<Scale>;
  /** fetch aggregated fields from the table: "scale" */
  scale_aggregate: Scale_Aggregate;
  /** fetch data from the table: "scale" using primary key columns */
  scale_by_pk?: Maybe<Scale>;
  /** fetch data from the table: "school" */
  school: Array<School>;
  /** fetch data from the table: "school_admin" */
  school_admin: Array<School_Admin>;
  /** fetch aggregated fields from the table: "school_admin" */
  school_admin_aggregate: School_Admin_Aggregate;
  /** fetch data from the table: "school_admin" using primary key columns */
  school_admin_by_pk?: Maybe<School_Admin>;
  /** fetch aggregated fields from the table: "school" */
  school_aggregate: School_Aggregate;
  /** fetch data from the table: "school" using primary key columns */
  school_by_pk?: Maybe<School>;
  /** fetch data from the table: "school_teacher" */
  school_teacher: Array<School_Teacher>;
  /** fetch aggregated fields from the table: "school_teacher" */
  school_teacher_aggregate: School_Teacher_Aggregate;
  /** fetch data from the table: "school_teacher" using primary key columns */
  school_teacher_by_pk?: Maybe<School_Teacher>;
  /** fetch data from the table: "screen_type" */
  screen_type: Array<Screen_Type>;
  /** fetch aggregated fields from the table: "screen_type" */
  screen_type_aggregate: Screen_Type_Aggregate;
  /** fetch data from the table: "screen_type" using primary key columns */
  screen_type_by_pk?: Maybe<Screen_Type>;
  /** fetch data from the table: "sequence" */
  sequence: Array<Sequence>;
  /** fetch aggregated fields from the table: "sequence" */
  sequence_aggregate: Sequence_Aggregate;
  /** fetch data from the table: "sequence" using primary key columns */
  sequence_by_pk?: Maybe<Sequence>;
  /** fetch data from the table: "sequence_keyword" */
  sequence_keyword: Array<Sequence_Keyword>;
  /** fetch aggregated fields from the table: "sequence_keyword" */
  sequence_keyword_aggregate: Sequence_Keyword_Aggregate;
  /** fetch data from the table: "sequence_keyword" using primary key columns */
  sequence_keyword_by_pk?: Maybe<Sequence_Keyword>;
  /** An array relationship */
  sequence_lesson_plan: Array<Sequence_Lesson_Plan>;
  /** An aggregate relationship */
  sequence_lesson_plan_aggregate: Sequence_Lesson_Plan_Aggregate;
  /** fetch data from the table: "sequence_lesson_plan" using primary key columns */
  sequence_lesson_plan_by_pk?: Maybe<Sequence_Lesson_Plan>;
  /** fetch data from the table: "sequence_topic" */
  sequence_topic: Array<Sequence_Topic>;
  /** fetch aggregated fields from the table: "sequence_topic" */
  sequence_topic_aggregate: Sequence_Topic_Aggregate;
  /** fetch data from the table: "sequence_topic" using primary key columns */
  sequence_topic_by_pk?: Maybe<Sequence_Topic>;
  /** fetch data from the table: "sequence_topic_catalog_item" */
  sequence_topic_catalog_item: Array<Sequence_Topic_Catalog_Item>;
  /** fetch aggregated fields from the table: "sequence_topic_catalog_item" */
  sequence_topic_catalog_item_aggregate: Sequence_Topic_Catalog_Item_Aggregate;
  /** fetch data from the table: "sequence_topic_catalog_item" using primary key columns */
  sequence_topic_catalog_item_by_pk?: Maybe<Sequence_Topic_Catalog_Item>;
  /** fetch data from the table: "sequence_topic_keyword" */
  sequence_topic_keyword: Array<Sequence_Topic_Keyword>;
  /** fetch aggregated fields from the table: "sequence_topic_keyword" */
  sequence_topic_keyword_aggregate: Sequence_Topic_Keyword_Aggregate;
  /** fetch data from the table: "sequence_topic_keyword" using primary key columns */
  sequence_topic_keyword_by_pk?: Maybe<Sequence_Topic_Keyword>;
  /** fetch data from the table: "sequence_topic_sequence" */
  sequence_topic_sequence: Array<Sequence_Topic_Sequence>;
  /** fetch aggregated fields from the table: "sequence_topic_sequence" */
  sequence_topic_sequence_aggregate: Sequence_Topic_Sequence_Aggregate;
  /** fetch data from the table: "sequence_topic_sequence" using primary key columns */
  sequence_topic_sequence_by_pk?: Maybe<Sequence_Topic_Sequence>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** fetch aggregated fields from the table: "session" */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "skill_level" */
  skill_level: Array<Skill_Level>;
  /** fetch aggregated fields from the table: "skill_level" */
  skill_level_aggregate: Skill_Level_Aggregate;
  /** fetch data from the table: "skill_level" using primary key columns */
  skill_level_by_pk?: Maybe<Skill_Level>;
  /** fetch data from the table: "song" */
  song: Array<Song>;
  /** fetch aggregated fields from the table: "song" */
  song_aggregate: Song_Aggregate;
  /** fetch data from the table: "song" using primary key columns */
  song_by_pk?: Maybe<Song>;
  /** An array relationship */
  song_catalog_item: Array<Song_Catalog_Item>;
  /** An aggregate relationship */
  song_catalog_item_aggregate: Song_Catalog_Item_Aggregate;
  /** fetch data from the table: "song_catalog_item" using primary key columns */
  song_catalog_item_by_pk?: Maybe<Song_Catalog_Item>;
  /** fetch data from the table: "song_grade" */
  song_grade: Array<Song_Grade>;
  /** fetch aggregated fields from the table: "song_grade" */
  song_grade_aggregate: Song_Grade_Aggregate;
  /** fetch data from the table: "song_grade" using primary key columns */
  song_grade_by_pk?: Maybe<Song_Grade>;
  /** fetch data from the table: "song_keyword" */
  song_keyword: Array<Song_Keyword>;
  /** fetch aggregated fields from the table: "song_keyword" */
  song_keyword_aggregate: Song_Keyword_Aggregate;
  /** fetch data from the table: "song_keyword" using primary key columns */
  song_keyword_by_pk?: Maybe<Song_Keyword>;
  /** fetch data from the table: "standard" */
  standard: Array<Standard>;
  /** fetch aggregated fields from the table: "standard" */
  standard_aggregate: Standard_Aggregate;
  /** fetch data from the table: "standard" using primary key columns */
  standard_by_pk?: Maybe<Standard>;
  /** fetch data from the table: "standard_option" */
  standard_option: Array<Standard_Option>;
  /** fetch aggregated fields from the table: "standard_option" */
  standard_option_aggregate: Standard_Option_Aggregate;
  /** fetch data from the table: "standard_option" using primary key columns */
  standard_option_by_pk?: Maybe<Standard_Option>;
  /** fetch data from the table: "standard_subcategory" */
  standard_subcategory: Array<Standard_Subcategory>;
  /** fetch aggregated fields from the table: "standard_subcategory" */
  standard_subcategory_aggregate: Standard_Subcategory_Aggregate;
  /** fetch data from the table: "standard_subcategory" using primary key columns */
  standard_subcategory_by_pk?: Maybe<Standard_Subcategory>;
  /** fetch data from the table: "student" */
  student: Array<Student>;
  /** fetch data from the table: "student_activity" */
  student_activity: Array<Student_Activity>;
  /** fetch aggregated fields from the table: "student_activity" */
  student_activity_aggregate: Student_Activity_Aggregate;
  /** fetch data from the table: "student_activity" using primary key columns */
  student_activity_by_pk?: Maybe<Student_Activity>;
  /** fetch data from the table: "student_activity_type" */
  student_activity_type: Array<Student_Activity_Type>;
  /** fetch aggregated fields from the table: "student_activity_type" */
  student_activity_type_aggregate: Student_Activity_Type_Aggregate;
  /** fetch data from the table: "student_activity_type" using primary key columns */
  student_activity_type_by_pk?: Maybe<Student_Activity_Type>;
  /** fetch aggregated fields from the table: "student" */
  student_aggregate: Student_Aggregate;
  /** fetch data from the table: "student_badge" */
  student_badge: Array<Student_Badge>;
  /** fetch aggregated fields from the table: "student_badge" */
  student_badge_aggregate: Student_Badge_Aggregate;
  /** fetch data from the table: "student_badge" using primary key columns */
  student_badge_by_pk?: Maybe<Student_Badge>;
  /** fetch data from the table: "student_badge_event" */
  student_badge_event: Array<Student_Badge_Event>;
  /** fetch aggregated fields from the table: "student_badge_event" */
  student_badge_event_aggregate: Student_Badge_Event_Aggregate;
  /** fetch data from the table: "student_badge_event" using primary key columns */
  student_badge_event_by_pk?: Maybe<Student_Badge_Event>;
  /** fetch data from the table: "student_badge_resume" */
  student_badge_resume: Array<Student_Badge_Resume>;
  /** fetch aggregated fields from the table: "student_badge_resume" */
  student_badge_resume_aggregate: Student_Badge_Resume_Aggregate;
  /** fetch data from the table: "student_banner" */
  student_banner: Array<Student_Banner>;
  /** fetch aggregated fields from the table: "student_banner" */
  student_banner_aggregate: Student_Banner_Aggregate;
  /** fetch data from the table: "student_banner" using primary key columns */
  student_banner_by_pk?: Maybe<Student_Banner>;
  /** fetch data from the table: "student" using primary key columns */
  student_by_pk?: Maybe<Student>;
  /** fetch data from the table: "student_district" */
  student_district: Array<Student_District>;
  /** fetch aggregated fields from the table: "student_district" */
  student_district_aggregate: Student_District_Aggregate;
  /** fetch data from the table: "student_goal" */
  student_goal: Array<Student_Goal>;
  /** fetch aggregated fields from the table: "student_goal" */
  student_goal_aggregate: Student_Goal_Aggregate;
  /** fetch data from the table: "student_goal" using primary key columns */
  student_goal_by_pk?: Maybe<Student_Goal>;
  /** fetch data from the table: "student_grade" */
  student_grade: Array<Student_Grade>;
  /** fetch aggregated fields from the table: "student_grade" */
  student_grade_aggregate: Student_Grade_Aggregate;
  /** fetch data from the table: "student_integration" */
  student_integration: Array<Student_Integration>;
  /** fetch aggregated fields from the table: "student_integration" */
  student_integration_aggregate: Student_Integration_Aggregate;
  /** fetch data from the table: "student_integration" using primary key columns */
  student_integration_by_pk?: Maybe<Student_Integration>;
  /** fetch data from the table: "student_lesson_plan_favorite" */
  student_lesson_plan_favorite: Array<Student_Lesson_Plan_Favorite>;
  /** fetch aggregated fields from the table: "student_lesson_plan_favorite" */
  student_lesson_plan_favorite_aggregate: Student_Lesson_Plan_Favorite_Aggregate;
  /** fetch data from the table: "student_lesson_plan_favorite" using primary key columns */
  student_lesson_plan_favorite_by_pk?: Maybe<Student_Lesson_Plan_Favorite>;
  /** An array relationship */
  student_productivity_daily: Array<Student_Productivity_Daily>;
  /** An aggregate relationship */
  student_productivity_daily_aggregate: Student_Productivity_Daily_Aggregate;
  /** fetch data from the table: "student_productivity_daily" using primary key columns */
  student_productivity_daily_by_pk?: Maybe<Student_Productivity_Daily>;
  /** An array relationship */
  student_productivity_monthly: Array<Student_Productivity_Monthly>;
  /** An aggregate relationship */
  student_productivity_monthly_aggregate: Student_Productivity_Monthly_Aggregate;
  /** fetch data from the table: "student_productivity_monthly" using primary key columns */
  student_productivity_monthly_by_pk?: Maybe<Student_Productivity_Monthly>;
  /** An array relationship */
  student_productivity_yearly: Array<Student_Productivity_Yearly>;
  /** An aggregate relationship */
  student_productivity_yearly_aggregate: Student_Productivity_Yearly_Aggregate;
  /** fetch data from the table: "student_productivity_yearly" using primary key columns */
  student_productivity_yearly_by_pk?: Maybe<Student_Productivity_Yearly>;
  /** fetch data from the table: "student_setting" */
  student_setting: Array<Student_Setting>;
  /** fetch aggregated fields from the table: "student_setting" */
  student_setting_aggregate: Student_Setting_Aggregate;
  /** fetch data from the table: "student_setting" using primary key columns */
  student_setting_by_pk?: Maybe<Student_Setting>;
  /** fetch data from the table: "student_song_favorite" */
  student_song_favorite: Array<Student_Song_Favorite>;
  /** fetch aggregated fields from the table: "student_song_favorite" */
  student_song_favorite_aggregate: Student_Song_Favorite_Aggregate;
  /** fetch data from the table: "student_song_favorite" using primary key columns */
  student_song_favorite_by_pk?: Maybe<Student_Song_Favorite>;
  /** fetch data from the table: "student_tip_favorite" */
  student_tip_favorite: Array<Student_Tip_Favorite>;
  /** fetch aggregated fields from the table: "student_tip_favorite" */
  student_tip_favorite_aggregate: Student_Tip_Favorite_Aggregate;
  /** fetch data from the table: "student_tip_favorite" using primary key columns */
  student_tip_favorite_by_pk?: Maybe<Student_Tip_Favorite>;
  /** An array relationship */
  submission: Array<Submission>;
  /** An aggregate relationship */
  submission_aggregate: Submission_Aggregate;
  /** fetch data from the table: "submission_asset" */
  submission_asset: Array<Submission_Asset>;
  /** fetch aggregated fields from the table: "submission_asset" */
  submission_asset_aggregate: Submission_Asset_Aggregate;
  /** fetch data from the table: "submission_asset" using primary key columns */
  submission_asset_by_pk?: Maybe<Submission_Asset>;
  /** fetch data from the table: "submission" using primary key columns */
  submission_by_pk?: Maybe<Submission>;
  /** fetch data from the table: "submission_state" */
  submission_state: Array<Submission_State>;
  /** fetch aggregated fields from the table: "submission_state" */
  submission_state_aggregate: Submission_State_Aggregate;
  /** fetch data from the table: "submission_state" using primary key columns */
  submission_state_by_pk?: Maybe<Submission_State>;
  /** fetch data from the table: "submission_student" */
  submission_student: Array<Submission_Student>;
  /** fetch aggregated fields from the table: "submission_student" */
  submission_student_aggregate: Submission_Student_Aggregate;
  /** fetch data from the table: "submission_student" using primary key columns */
  submission_student_by_pk?: Maybe<Submission_Student>;
  /** fetch data from the table: "submission_wurrly" */
  submission_wurrly: Array<Submission_Wurrly>;
  /** fetch aggregated fields from the table: "submission_wurrly" */
  submission_wurrly_aggregate: Submission_Wurrly_Aggregate;
  /** fetch data from the table: "submission_wurrly" using primary key columns */
  submission_wurrly_by_pk?: Maybe<Submission_Wurrly>;
  /** fetch data from the table: "submission_wurrly_state" */
  submission_wurrly_state: Array<Submission_Wurrly_State>;
  /** fetch aggregated fields from the table: "submission_wurrly_state" */
  submission_wurrly_state_aggregate: Submission_Wurrly_State_Aggregate;
  /** fetch data from the table: "submission_wurrly_state" using primary key columns */
  submission_wurrly_state_by_pk?: Maybe<Submission_Wurrly_State>;
  /** fetch data from the table: "teacher" */
  teacher: Array<Teacher>;
  /** fetch aggregated fields from the table: "teacher" */
  teacher_aggregate: Teacher_Aggregate;
  /** fetch data from the table: "teacher" using primary key columns */
  teacher_by_pk?: Maybe<Teacher>;
  /** fetch data from the table: "teacher_integration" */
  teacher_integration: Array<Teacher_Integration>;
  /** fetch aggregated fields from the table: "teacher_integration" */
  teacher_integration_aggregate: Teacher_Integration_Aggregate;
  /** fetch data from the table: "teacher_integration" using primary key columns */
  teacher_integration_by_pk?: Maybe<Teacher_Integration>;
  /** fetch data from the table: "teacher_lesson_plan_favorite" */
  teacher_lesson_plan_favorite: Array<Teacher_Lesson_Plan_Favorite>;
  /** fetch aggregated fields from the table: "teacher_lesson_plan_favorite" */
  teacher_lesson_plan_favorite_aggregate: Teacher_Lesson_Plan_Favorite_Aggregate;
  /** fetch data from the table: "teacher_lesson_plan_favorite" using primary key columns */
  teacher_lesson_plan_favorite_by_pk?: Maybe<Teacher_Lesson_Plan_Favorite>;
  /** fetch data from the table: "teacher_setting" */
  teacher_setting: Array<Teacher_Setting>;
  /** fetch aggregated fields from the table: "teacher_setting" */
  teacher_setting_aggregate: Teacher_Setting_Aggregate;
  /** fetch data from the table: "teacher_setting" using primary key columns */
  teacher_setting_by_pk?: Maybe<Teacher_Setting>;
  /** fetch data from the table: "teacher_song_favorite" */
  teacher_song_favorite: Array<Teacher_Song_Favorite>;
  /** fetch aggregated fields from the table: "teacher_song_favorite" */
  teacher_song_favorite_aggregate: Teacher_Song_Favorite_Aggregate;
  /** fetch data from the table: "teacher_song_favorite" using primary key columns */
  teacher_song_favorite_by_pk?: Maybe<Teacher_Song_Favorite>;
  /** fetch data from the table: "teacher_subscription" */
  teacher_subscription: Array<Teacher_Subscription>;
  /** fetch aggregated fields from the table: "teacher_subscription" */
  teacher_subscription_aggregate: Teacher_Subscription_Aggregate;
  /** fetch data from the table: "teacher_subscription" using primary key columns */
  teacher_subscription_by_pk?: Maybe<Teacher_Subscription>;
  /** fetch data from the table: "teacher_tip_favorite" */
  teacher_tip_favorite: Array<Teacher_Tip_Favorite>;
  /** fetch aggregated fields from the table: "teacher_tip_favorite" */
  teacher_tip_favorite_aggregate: Teacher_Tip_Favorite_Aggregate;
  /** fetch data from the table: "teacher_tip_favorite" using primary key columns */
  teacher_tip_favorite_by_pk?: Maybe<Teacher_Tip_Favorite>;
  /** fetch data from the table: "teacher_wurrly_comment" */
  teacher_wurrly_comment: Array<Teacher_Wurrly_Comment>;
  /** fetch aggregated fields from the table: "teacher_wurrly_comment" */
  teacher_wurrly_comment_aggregate: Teacher_Wurrly_Comment_Aggregate;
  /** fetch data from the table: "teacher_wurrly_comment" using primary key columns */
  teacher_wurrly_comment_by_pk?: Maybe<Teacher_Wurrly_Comment>;
  /** fetch data from the table: "tip" */
  tip: Array<Tip>;
  /** fetch data from the table: "tip_age" */
  tip_age: Array<Tip_Age>;
  /** fetch aggregated fields from the table: "tip_age" */
  tip_age_aggregate: Tip_Age_Aggregate;
  /** fetch data from the table: "tip_age" using primary key columns */
  tip_age_by_pk?: Maybe<Tip_Age>;
  /** fetch aggregated fields from the table: "tip" */
  tip_aggregate: Tip_Aggregate;
  /** fetch data from the table: "tip" using primary key columns */
  tip_by_pk?: Maybe<Tip>;
  /** An array relationship */
  tip_catalog_item: Array<Tip_Catalog_Item>;
  /** An aggregate relationship */
  tip_catalog_item_aggregate: Tip_Catalog_Item_Aggregate;
  /** fetch data from the table: "tip_catalog_item" using primary key columns */
  tip_catalog_item_by_pk?: Maybe<Tip_Catalog_Item>;
  /** fetch data from the table: "tip_grade" */
  tip_grade: Array<Tip_Grade>;
  /** fetch aggregated fields from the table: "tip_grade" */
  tip_grade_aggregate: Tip_Grade_Aggregate;
  /** fetch data from the table: "tip_grade" using primary key columns */
  tip_grade_by_pk?: Maybe<Tip_Grade>;
  /** fetch data from the table: "tip_keyword" */
  tip_keyword: Array<Tip_Keyword>;
  /** fetch aggregated fields from the table: "tip_keyword" */
  tip_keyword_aggregate: Tip_Keyword_Aggregate;
  /** fetch data from the table: "tip_keyword" using primary key columns */
  tip_keyword_by_pk?: Maybe<Tip_Keyword>;
  /** fetch data from the table: "tip_musical_skill" */
  tip_musical_skill: Array<Tip_Musical_Skill>;
  /** fetch aggregated fields from the table: "tip_musical_skill" */
  tip_musical_skill_aggregate: Tip_Musical_Skill_Aggregate;
  /** fetch data from the table: "tip_musical_skill" using primary key columns */
  tip_musical_skill_by_pk?: Maybe<Tip_Musical_Skill>;
  /** fetch data from the table: "tip_non_musical_skill" */
  tip_non_musical_skill: Array<Tip_Non_Musical_Skill>;
  /** fetch aggregated fields from the table: "tip_non_musical_skill" */
  tip_non_musical_skill_aggregate: Tip_Non_Musical_Skill_Aggregate;
  /** fetch data from the table: "tip_non_musical_skill" using primary key columns */
  tip_non_musical_skill_by_pk?: Maybe<Tip_Non_Musical_Skill>;
  /** An array relationship */
  tip_playlist: Array<Tip_Playlist>;
  /** An aggregate relationship */
  tip_playlist_aggregate: Tip_Playlist_Aggregate;
  /** fetch data from the table: "tip_playlist" using primary key columns */
  tip_playlist_by_pk?: Maybe<Tip_Playlist>;
  /** fetch data from the table: "track" */
  track: Array<Track>;
  /** fetch aggregated fields from the table: "track" */
  track_aggregate: Track_Aggregate;
  /** fetch data from the table: "track" using primary key columns */
  track_by_pk?: Maybe<Track>;
  /** fetch data from the table: "track_type" */
  track_type: Array<Track_Type>;
  /** fetch aggregated fields from the table: "track_type" */
  track_type_aggregate: Track_Type_Aggregate;
  /** fetch data from the table: "track_type" using primary key columns */
  track_type_by_pk?: Maybe<Track_Type>;
  /** fetch data from the table: "transpose_index" */
  transpose_index: Array<Transpose_Index>;
  /** fetch aggregated fields from the table: "transpose_index" */
  transpose_index_aggregate: Transpose_Index_Aggregate;
  /** fetch data from the table: "transpose_index" using primary key columns */
  transpose_index_by_pk?: Maybe<Transpose_Index>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "utils.avg_lessons" */
  utils_avg_lessons: Array<Utils_Avg_Lessons>;
  /** fetch aggregated fields from the table: "utils.avg_lessons" */
  utils_avg_lessons_aggregate: Utils_Avg_Lessons_Aggregate;
  /** fetch data from the table: "utils.most_attended_classes" */
  utils_most_attended_classes: Array<Utils_Most_Attended_Classes>;
  /** fetch aggregated fields from the table: "utils.most_attended_classes" */
  utils_most_attended_classes_aggregate: Utils_Most_Attended_Classes_Aggregate;
  /** fetch data from the table: "utils.most_attended_subjects" */
  utils_most_attended_subjects: Array<Utils_Most_Attended_Subjects>;
  /** fetch aggregated fields from the table: "utils.most_attended_subjects" */
  utils_most_attended_subjects_aggregate: Utils_Most_Attended_Subjects_Aggregate;
  /** execute function "utils.search_last_classes" which returns "class" */
  utils_search_last_classes: Array<Class>;
  /** execute function "utils.search_last_classes" and query aggregates on result of table type "class" */
  utils_search_last_classes_aggregate: Class_Aggregate;
  /** execute function "utils.search_most_attended_classes" which returns "utils.most_attended_classes" */
  utils_search_most_attended_classes: Array<Utils_Most_Attended_Classes>;
  /** execute function "utils.search_most_attended_classes" and query aggregates on result of table type "utils.most_attended_classes" */
  utils_search_most_attended_classes_aggregate: Utils_Most_Attended_Classes_Aggregate;
  /** execute function "utils.search_most_attended_subjects" which returns "utils.most_attended_subjects" */
  utils_search_most_attended_subjects: Array<Utils_Most_Attended_Subjects>;
  /** execute function "utils.search_most_attended_subjects" and query aggregates on result of table type "utils.most_attended_subjects" */
  utils_search_most_attended_subjects_aggregate: Utils_Most_Attended_Subjects_Aggregate;
  /** execute function "utils.search_new_users" which returns "utils.users_per_month" */
  utils_search_new_users: Array<Utils_Users_Per_Month>;
  /** execute function "utils.search_new_users" and query aggregates on result of table type "utils.users_per_month" */
  utils_search_new_users_aggregate: Utils_Users_Per_Month_Aggregate;
  /** execute function "utils.search_teacher_subscriptions" which returns "utils.teacher_subscriptions_per_month" */
  utils_search_teacher_subscriptions: Array<Utils_Teacher_Subscriptions_Per_Month>;
  /** execute function "utils.search_teacher_subscriptions" and query aggregates on result of table type "utils.teacher_subscriptions_per_month" */
  utils_search_teacher_subscriptions_aggregate: Utils_Teacher_Subscriptions_Per_Month_Aggregate;
  /** fetch data from the table: "utils.teacher_subscriptions_per_month" */
  utils_teacher_subscriptions_per_month: Array<Utils_Teacher_Subscriptions_Per_Month>;
  /** fetch aggregated fields from the table: "utils.teacher_subscriptions_per_month" */
  utils_teacher_subscriptions_per_month_aggregate: Utils_Teacher_Subscriptions_Per_Month_Aggregate;
  /** fetch data from the table: "utils.users_per_month" */
  utils_users_per_month: Array<Utils_Users_Per_Month>;
  /** fetch aggregated fields from the table: "utils.users_per_month" */
  utils_users_per_month_aggregate: Utils_Users_Per_Month_Aggregate;
  /** fetch data from the table: "visibility" */
  visibility: Array<Visibility>;
  /** fetch aggregated fields from the table: "visibility" */
  visibility_aggregate: Visibility_Aggregate;
  /** fetch data from the table: "visibility" using primary key columns */
  visibility_by_pk?: Maybe<Visibility>;
  /** An array relationship */
  wurrly: Array<Wurrly>;
  /** An aggregate relationship */
  wurrly_aggregate: Wurrly_Aggregate;
  /** fetch data from the table: "wurrly" using primary key columns */
  wurrly_by_pk?: Maybe<Wurrly>;
  /** fetch data from the table: "wurrly_filter" */
  wurrly_filter: Array<Wurrly_Filter>;
  /** fetch aggregated fields from the table: "wurrly_filter" */
  wurrly_filter_aggregate: Wurrly_Filter_Aggregate;
  /** fetch data from the table: "wurrly_filter" using primary key columns */
  wurrly_filter_by_pk?: Maybe<Wurrly_Filter>;
  /** fetch data from the table: "wurrly_filter_sticker" */
  wurrly_filter_sticker: Array<Wurrly_Filter_Sticker>;
  /** fetch aggregated fields from the table: "wurrly_filter_sticker" */
  wurrly_filter_sticker_aggregate: Wurrly_Filter_Sticker_Aggregate;
  /** fetch data from the table: "wurrly_processing_status" */
  wurrly_processing_status: Array<Wurrly_Processing_Status>;
  /** fetch aggregated fields from the table: "wurrly_processing_status" */
  wurrly_processing_status_aggregate: Wurrly_Processing_Status_Aggregate;
  /** fetch data from the table: "wurrly_processing_status" using primary key columns */
  wurrly_processing_status_by_pk?: Maybe<Wurrly_Processing_Status>;
  /** fetch data from the table: "wurrly_reaction" */
  wurrly_reaction: Array<Wurrly_Reaction>;
  /** fetch aggregated fields from the table: "wurrly_reaction" */
  wurrly_reaction_aggregate: Wurrly_Reaction_Aggregate;
  /** fetch data from the table: "wurrly_reaction" using primary key columns */
  wurrly_reaction_by_pk?: Maybe<Wurrly_Reaction>;
  /** fetch data from the table: "wurrly_role" */
  wurrly_role: Array<Wurrly_Role>;
  /** fetch aggregated fields from the table: "wurrly_role" */
  wurrly_role_aggregate: Wurrly_Role_Aggregate;
  /** fetch data from the table: "wurrly_role" using primary key columns */
  wurrly_role_by_pk?: Maybe<Wurrly_Role>;
  /** fetch data from the table: "wurrly_type" */
  wurrly_type: Array<Wurrly_Type>;
  /** fetch aggregated fields from the table: "wurrly_type" */
  wurrly_type_aggregate: Wurrly_Type_Aggregate;
  /** fetch data from the table: "wurrly_type" using primary key columns */
  wurrly_type_by_pk?: Maybe<Wurrly_Type>;
};


export type Subscription_RootAccount_ActivationArgs = {
  distinct_on?: Maybe<Array<Account_Activation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Activation_Order_By>>;
  where?: Maybe<Account_Activation_Bool_Exp>;
};


export type Subscription_RootAccount_Activation_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Activation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Activation_Order_By>>;
  where?: Maybe<Account_Activation_Bool_Exp>;
};


export type Subscription_RootAccount_Activation_By_PkArgs = {
  account_activation_id: Scalars['Int'];
};


export type Subscription_RootAdminArgs = {
  distinct_on?: Maybe<Array<Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Order_By>>;
  where?: Maybe<Admin_Bool_Exp>;
};


export type Subscription_RootAdmin_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Order_By>>;
  where?: Maybe<Admin_Bool_Exp>;
};


export type Subscription_RootAdmin_By_PkArgs = {
  admin_id: Scalars['Int'];
};


export type Subscription_RootAppArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


export type Subscription_RootApp_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Order_By>>;
  where?: Maybe<App_Bool_Exp>;
};


export type Subscription_RootApp_By_PkArgs = {
  app_id: Scalars['Int'];
};


export type Subscription_RootArtistArgs = {
  distinct_on?: Maybe<Array<Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Order_By>>;
  where?: Maybe<Artist_Bool_Exp>;
};


export type Subscription_RootArtist_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Order_By>>;
  where?: Maybe<Artist_Bool_Exp>;
};


export type Subscription_RootArtist_By_PkArgs = {
  artist_id: Scalars['Int'];
};


export type Subscription_RootAssetArgs = {
  distinct_on?: Maybe<Array<Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Order_By>>;
  where?: Maybe<Asset_Bool_Exp>;
};


export type Subscription_RootAsset_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Order_By>>;
  where?: Maybe<Asset_Bool_Exp>;
};


export type Subscription_RootAsset_By_PkArgs = {
  asset_id: Scalars['Int'];
};


export type Subscription_RootAssignmentArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Subscription_RootAssignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Subscription_RootAssignment_AssetArgs = {
  distinct_on?: Maybe<Array<Assignment_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Asset_Order_By>>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};


export type Subscription_RootAssignment_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Asset_Order_By>>;
  where?: Maybe<Assignment_Asset_Bool_Exp>;
};


export type Subscription_RootAssignment_Asset_By_PkArgs = {
  assignment_asset_id: Scalars['Int'];
};


export type Subscription_RootAssignment_By_PkArgs = {
  assignment_id: Scalars['Int'];
};


export type Subscription_RootAssignment_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Assignment_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Lesson_Plan_Order_By>>;
  where?: Maybe<Assignment_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootAssignment_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Lesson_Plan_Order_By>>;
  where?: Maybe<Assignment_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootAssignment_Lesson_Plan_By_PkArgs = {
  assignment_lesson_plan_id: Scalars['Int'];
};


export type Subscription_RootAssignment_SongArgs = {
  distinct_on?: Maybe<Array<Assignment_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Song_Order_By>>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};


export type Subscription_RootAssignment_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Song_Order_By>>;
  where?: Maybe<Assignment_Song_Bool_Exp>;
};


export type Subscription_RootAssignment_Song_By_PkArgs = {
  assignment_song_id: Scalars['Int'];
};


export type Subscription_RootAssignment_TipArgs = {
  distinct_on?: Maybe<Array<Assignment_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Tip_Order_By>>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};


export type Subscription_RootAssignment_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Tip_Order_By>>;
  where?: Maybe<Assignment_Tip_Bool_Exp>;
};


export type Subscription_RootAssignment_Tip_By_PkArgs = {
  assignment_tip_id: Scalars['Int'];
};


export type Subscription_RootAvailableArgs = {
  distinct_on?: Maybe<Array<Available_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Order_By>>;
  where?: Maybe<Available_Bool_Exp>;
};


export type Subscription_RootAvailable_AggregateArgs = {
  distinct_on?: Maybe<Array<Available_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Order_By>>;
  where?: Maybe<Available_Bool_Exp>;
};


export type Subscription_RootAvailable_By_PkArgs = {
  available_id: Scalars['Int'];
};


export type Subscription_RootAwarded_Badge_EventArgs = {
  distinct_on?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Awarded_Badge_Event_Order_By>>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};


export type Subscription_RootAwarded_Badge_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Awarded_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Awarded_Badge_Event_Order_By>>;
  where?: Maybe<Awarded_Badge_Event_Bool_Exp>;
};


export type Subscription_RootAwarded_Badge_Event_By_PkArgs = {
  student_badge_event_id: Scalars['Int'];
  student_badge_id: Scalars['Int'];
};


export type Subscription_RootBadgeArgs = {
  distinct_on?: Maybe<Array<Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Order_By>>;
  where?: Maybe<Badge_Bool_Exp>;
};


export type Subscription_RootBadge_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Order_By>>;
  where?: Maybe<Badge_Bool_Exp>;
};


export type Subscription_RootBadge_By_PkArgs = {
  badge_id: Scalars['Int'];
};


export type Subscription_RootBadge_EventArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Order_By>>;
  where?: Maybe<Badge_Event_Bool_Exp>;
};


export type Subscription_RootBadge_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Order_By>>;
  where?: Maybe<Badge_Event_Bool_Exp>;
};


export type Subscription_RootBadge_Event_By_PkArgs = {
  badge_id: Scalars['Int'];
  event_name: Badge_Event_Name_Enum;
};


export type Subscription_RootBadge_Event_NameArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Name_Order_By>>;
  where?: Maybe<Badge_Event_Name_Bool_Exp>;
};


export type Subscription_RootBadge_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Badge_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Badge_Event_Name_Order_By>>;
  where?: Maybe<Badge_Event_Name_Bool_Exp>;
};


export type Subscription_RootBadge_Event_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootBilling_HistoryArgs = {
  distinct_on?: Maybe<Array<Billing_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_History_Order_By>>;
  where?: Maybe<Billing_History_Bool_Exp>;
};


export type Subscription_RootBilling_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_History_Order_By>>;
  where?: Maybe<Billing_History_Bool_Exp>;
};


export type Subscription_RootBilling_History_By_PkArgs = {
  billing_history_id: Scalars['Int'];
};


export type Subscription_RootBilling_MethodArgs = {
  distinct_on?: Maybe<Array<Billing_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Method_Order_By>>;
  where?: Maybe<Billing_Method_Bool_Exp>;
};


export type Subscription_RootBilling_Method_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Method_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Method_Order_By>>;
  where?: Maybe<Billing_Method_Bool_Exp>;
};


export type Subscription_RootBilling_Method_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootBilling_StatusArgs = {
  distinct_on?: Maybe<Array<Billing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Status_Order_By>>;
  where?: Maybe<Billing_Status_Bool_Exp>;
};


export type Subscription_RootBilling_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Status_Order_By>>;
  where?: Maybe<Billing_Status_Bool_Exp>;
};


export type Subscription_RootBilling_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootCatalogArgs = {
  distinct_on?: Maybe<Array<Catalog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Order_By>>;
  where?: Maybe<Catalog_Bool_Exp>;
};


export type Subscription_RootCatalog_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Order_By>>;
  where?: Maybe<Catalog_Bool_Exp>;
};


export type Subscription_RootCatalog_By_PkArgs = {
  catalog_id: Scalars['Int'];
};


export type Subscription_RootCatalog_ItemArgs = {
  distinct_on?: Maybe<Array<Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Item_Order_By>>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};


export type Subscription_RootCatalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Item_Order_By>>;
  where?: Maybe<Catalog_Item_Bool_Exp>;
};


export type Subscription_RootCatalog_Item_By_PkArgs = {
  catalog_item_id: Scalars['Int'];
};


export type Subscription_RootCatalog_NameArgs = {
  distinct_on?: Maybe<Array<Catalog_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Name_Order_By>>;
  where?: Maybe<Catalog_Name_Bool_Exp>;
};


export type Subscription_RootCatalog_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Catalog_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Catalog_Name_Order_By>>;
  where?: Maybe<Catalog_Name_Bool_Exp>;
};


export type Subscription_RootCatalog_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootCategory_StandardArgs = {
  distinct_on?: Maybe<Array<Category_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Standard_Order_By>>;
  where?: Maybe<Category_Standard_Bool_Exp>;
};


export type Subscription_RootCategory_Standard_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Standard_Order_By>>;
  where?: Maybe<Category_Standard_Bool_Exp>;
};


export type Subscription_RootCategory_Standard_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootChallengeArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Order_By>>;
  where?: Maybe<Challenge_Bool_Exp>;
};


export type Subscription_RootChallenge_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Order_By>>;
  where?: Maybe<Challenge_Bool_Exp>;
};


export type Subscription_RootChallenge_By_PkArgs = {
  challenge_id: Scalars['Int'];
};


export type Subscription_RootChallenge_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Catalog_Item_Order_By>>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootChallenge_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Catalog_Item_Order_By>>;
  where?: Maybe<Challenge_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootChallenge_Catalog_Item_By_PkArgs = {
  challenge_catalog_item_id: Scalars['Int'];
};


export type Subscription_RootChallenge_DistrictArgs = {
  distinct_on?: Maybe<Array<Challenge_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_District_Order_By>>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};


export type Subscription_RootChallenge_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_District_Order_By>>;
  where?: Maybe<Challenge_District_Bool_Exp>;
};


export type Subscription_RootChallenge_District_By_PkArgs = {
  challenge_district_id: Scalars['Int'];
};


export type Subscription_RootChallenge_GradeArgs = {
  distinct_on?: Maybe<Array<Challenge_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Grade_Order_By>>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};


export type Subscription_RootChallenge_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Grade_Order_By>>;
  where?: Maybe<Challenge_Grade_Bool_Exp>;
};


export type Subscription_RootChallenge_Grade_By_PkArgs = {
  challenge_grade_id: Scalars['Int'];
};


export type Subscription_RootChallenge_SongArgs = {
  distinct_on?: Maybe<Array<Challenge_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Song_Order_By>>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};


export type Subscription_RootChallenge_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Song_Order_By>>;
  where?: Maybe<Challenge_Song_Bool_Exp>;
};


export type Subscription_RootChallenge_Song_By_PkArgs = {
  challenge_song_id: Scalars['Int'];
};


export type Subscription_RootChallenge_StudentArgs = {
  distinct_on?: Maybe<Array<Challenge_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Student_Order_By>>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};


export type Subscription_RootChallenge_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Challenge_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Challenge_Student_Order_By>>;
  where?: Maybe<Challenge_Student_Bool_Exp>;
};


export type Subscription_RootChallenge_Student_By_PkArgs = {
  challenge_student_id: Scalars['Int'];
};


export type Subscription_RootChannelArgs = {
  distinct_on?: Maybe<Array<Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Order_By>>;
  where?: Maybe<Channel_Bool_Exp>;
};


export type Subscription_RootChannel_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Order_By>>;
  where?: Maybe<Channel_Bool_Exp>;
};


export type Subscription_RootChannel_By_PkArgs = {
  channel_id: Scalars['Int'];
};


export type Subscription_RootChannel_PlaylistArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


export type Subscription_RootChannel_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Channel_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Channel_Playlist_Order_By>>;
  where?: Maybe<Channel_Playlist_Bool_Exp>;
};


export type Subscription_RootChannel_Playlist_By_PkArgs = {
  channel_playlist_id: Scalars['Int'];
};


export type Subscription_RootClassArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Subscription_RootClass_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Subscription_RootClass_AssignmentArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


export type Subscription_RootClass_Assignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Order_By>>;
  where?: Maybe<Class_Assignment_Bool_Exp>;
};


export type Subscription_RootClass_Assignment_By_PkArgs = {
  class_assignment_id: Scalars['Int'];
};


export type Subscription_RootClass_Assignment_StatusArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Status_Order_By>>;
  where?: Maybe<Class_Assignment_Status_Bool_Exp>;
};


export type Subscription_RootClass_Assignment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Assignment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Assignment_Status_Order_By>>;
  where?: Maybe<Class_Assignment_Status_Bool_Exp>;
};


export type Subscription_RootClass_Assignment_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootClass_By_PkArgs = {
  class_id: Scalars['Int'];
};


export type Subscription_RootClass_ImagesArgs = {
  distinct_on?: Maybe<Array<Class_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Images_Order_By>>;
  where?: Maybe<Class_Images_Bool_Exp>;
};


export type Subscription_RootClass_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Images_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Images_Order_By>>;
  where?: Maybe<Class_Images_Bool_Exp>;
};


export type Subscription_RootClass_Images_By_PkArgs = {
  class_image_id: Scalars['Int'];
};


export type Subscription_RootClass_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootClass_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Lesson_Plan_Order_By>>;
  where?: Maybe<Class_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootClass_Lesson_Plan_By_PkArgs = {
  class_lesson_plan_id: Scalars['Int'];
};


export type Subscription_RootClass_ModuleArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


export type Subscription_RootClass_Module_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Module_Order_By>>;
  where?: Maybe<Class_Module_Bool_Exp>;
};


export type Subscription_RootClass_Module_By_PkArgs = {
  class_module_id: Scalars['Int'];
};


export type Subscription_RootClass_PlaylistArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


export type Subscription_RootClass_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Playlist_Order_By>>;
  where?: Maybe<Class_Playlist_Bool_Exp>;
};


export type Subscription_RootClass_Playlist_By_PkArgs = {
  class_playlist_id: Scalars['Int'];
};


export type Subscription_RootClass_SequenceArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


export type Subscription_RootClass_Sequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Sequence_Order_By>>;
  where?: Maybe<Class_Sequence_Bool_Exp>;
};


export type Subscription_RootClass_Sequence_By_PkArgs = {
  class_sequence_id: Scalars['Int'];
};


export type Subscription_RootClass_SongArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


export type Subscription_RootClass_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Song_Order_By>>;
  where?: Maybe<Class_Song_Bool_Exp>;
};


export type Subscription_RootClass_Song_By_PkArgs = {
  class_song_id: Scalars['Int'];
};


export type Subscription_RootClass_StudentArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


export type Subscription_RootClass_Student_ActivitiesArgs = {
  distinct_on?: Maybe<Array<Class_Student_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Activities_Order_By>>;
  where?: Maybe<Class_Student_Activities_Bool_Exp>;
};


export type Subscription_RootClass_Student_Activities_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Student_Activities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Activities_Order_By>>;
  where?: Maybe<Class_Student_Activities_Bool_Exp>;
};


export type Subscription_RootClass_Student_Activities_By_PkArgs = {
  class_student_activities_id: Scalars['Int'];
};


export type Subscription_RootClass_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Student_Order_By>>;
  where?: Maybe<Class_Student_Bool_Exp>;
};


export type Subscription_RootClass_Student_By_PkArgs = {
  class_student_id: Scalars['Int'];
};


export type Subscription_RootClass_TeacherArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


export type Subscription_RootClass_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


export type Subscription_RootClass_Teacher_By_PkArgs = {
  class_teacher_id: Scalars['Int'];
};


export type Subscription_RootClass_TipArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


export type Subscription_RootClass_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


export type Subscription_RootClass_Tip_By_PkArgs = {
  class_tip_id: Scalars['Int'];
};


export type Subscription_RootClever_Roster_LogArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Log_Order_By>>;
  where?: Maybe<Clever_Roster_Log_Bool_Exp>;
};


export type Subscription_RootClever_Roster_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Log_Order_By>>;
  where?: Maybe<Clever_Roster_Log_Bool_Exp>;
};


export type Subscription_RootClever_Roster_Log_By_PkArgs = {
  clever_roster_log_id: Scalars['Int'];
};


export type Subscription_RootClever_Roster_StatusArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Status_Order_By>>;
  where?: Maybe<Clever_Roster_Status_Bool_Exp>;
};


export type Subscription_RootClever_Roster_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Clever_Roster_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Clever_Roster_Status_Order_By>>;
  where?: Maybe<Clever_Roster_Status_Bool_Exp>;
};


export type Subscription_RootClever_Roster_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootContact_UsArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Order_By>>;
  where?: Maybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContact_Us_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Order_By>>;
  where?: Maybe<Contact_Us_Bool_Exp>;
};


export type Subscription_RootContact_Us_By_PkArgs = {
  contact_us_id: Scalars['Int'];
};


export type Subscription_RootContact_Us_Camp_TeacherArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Camp_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Camp_Teacher_Order_By>>;
  where?: Maybe<Contact_Us_Camp_Teacher_Bool_Exp>;
};


export type Subscription_RootContact_Us_Camp_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Us_Camp_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Us_Camp_Teacher_Order_By>>;
  where?: Maybe<Contact_Us_Camp_Teacher_Bool_Exp>;
};


export type Subscription_RootContact_Us_Camp_Teacher_By_PkArgs = {
  contact_us_camp_teacher_id: Scalars['Int'];
};


export type Subscription_RootCrossclear_MasterlistArgs = {
  distinct_on?: Maybe<Array<Crossclear_Masterlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crossclear_Masterlist_Order_By>>;
  where?: Maybe<Crossclear_Masterlist_Bool_Exp>;
};


export type Subscription_RootCrossclear_Masterlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Crossclear_Masterlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crossclear_Masterlist_Order_By>>;
  where?: Maybe<Crossclear_Masterlist_Bool_Exp>;
};


export type Subscription_RootCrossclear_Masterlist_By_PkArgs = {
  masterlist_id: Scalars['Int'];
};


export type Subscription_RootDisciplineArgs = {
  distinct_on?: Maybe<Array<Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discipline_Order_By>>;
  where?: Maybe<Discipline_Bool_Exp>;
};


export type Subscription_RootDiscipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discipline_Order_By>>;
  where?: Maybe<Discipline_Bool_Exp>;
};


export type Subscription_RootDiscipline_By_PkArgs = {
  discipline_id: Scalars['Int'];
};


export type Subscription_RootDistrictArgs = {
  distinct_on?: Maybe<Array<District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Order_By>>;
  where?: Maybe<District_Bool_Exp>;
};


export type Subscription_RootDistrict_AdminArgs = {
  distinct_on?: Maybe<Array<District_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Admin_Order_By>>;
  where?: Maybe<District_Admin_Bool_Exp>;
};


export type Subscription_RootDistrict_Admin_AggregateArgs = {
  distinct_on?: Maybe<Array<District_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Admin_Order_By>>;
  where?: Maybe<District_Admin_Bool_Exp>;
};


export type Subscription_RootDistrict_Admin_By_PkArgs = {
  district_admin_id: Scalars['Int'];
};


export type Subscription_RootDistrict_AggregateArgs = {
  distinct_on?: Maybe<Array<District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<District_Order_By>>;
  where?: Maybe<District_Bool_Exp>;
};


export type Subscription_RootDistrict_By_PkArgs = {
  district_id: Scalars['Int'];
};


export type Subscription_RootFilter_ColorArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Order_By>>;
  where?: Maybe<Filter_Color_Bool_Exp>;
};


export type Subscription_RootFilter_Color_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Order_By>>;
  where?: Maybe<Filter_Color_Bool_Exp>;
};


export type Subscription_RootFilter_Color_By_PkArgs = {
  filter_color_id: Scalars['Int'];
};


export type Subscription_RootFilter_Color_NameArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Name_Order_By>>;
  where?: Maybe<Filter_Color_Name_Bool_Exp>;
};


export type Subscription_RootFilter_Color_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Color_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Color_Name_Order_By>>;
  where?: Maybe<Filter_Color_Name_Bool_Exp>;
};


export type Subscription_RootFilter_Color_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootFilter_FrameArgs = {
  distinct_on?: Maybe<Array<Filter_Frame_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Frame_Order_By>>;
  where?: Maybe<Filter_Frame_Bool_Exp>;
};


export type Subscription_RootFilter_Frame_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Frame_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Frame_Order_By>>;
  where?: Maybe<Filter_Frame_Bool_Exp>;
};


export type Subscription_RootFilter_StickerArgs = {
  distinct_on?: Maybe<Array<Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Sticker_Order_By>>;
  where?: Maybe<Filter_Sticker_Bool_Exp>;
};


export type Subscription_RootFilter_Sticker_AggregateArgs = {
  distinct_on?: Maybe<Array<Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Filter_Sticker_Order_By>>;
  where?: Maybe<Filter_Sticker_Bool_Exp>;
};


export type Subscription_RootFilter_Sticker_By_PkArgs = {
  filter_sticker_id: Scalars['Int'];
};


export type Subscription_RootGradeArgs = {
  distinct_on?: Maybe<Array<Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grade_Order_By>>;
  where?: Maybe<Grade_Bool_Exp>;
};


export type Subscription_RootGrade_AggregateArgs = {
  distinct_on?: Maybe<Array<Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grade_Order_By>>;
  where?: Maybe<Grade_Bool_Exp>;
};


export type Subscription_RootGrade_By_PkArgs = {
  grade_id: Scalars['Int'];
};


export type Subscription_RootGrading_SystemArgs = {
  distinct_on?: Maybe<Array<Grading_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Order_By>>;
  where?: Maybe<Grading_System_Bool_Exp>;
};


export type Subscription_RootGrading_System_AggregateArgs = {
  distinct_on?: Maybe<Array<Grading_System_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Order_By>>;
  where?: Maybe<Grading_System_Bool_Exp>;
};


export type Subscription_RootGrading_System_By_PkArgs = {
  grading_system_id: Scalars['Int'];
};


export type Subscription_RootGrading_System_TypeArgs = {
  distinct_on?: Maybe<Array<Grading_System_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Type_Order_By>>;
  where?: Maybe<Grading_System_Type_Bool_Exp>;
};


export type Subscription_RootGrading_System_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Grading_System_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Grading_System_Type_Order_By>>;
  where?: Maybe<Grading_System_Type_Bool_Exp>;
};


export type Subscription_RootGrading_System_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootIntegration_NameArgs = {
  distinct_on?: Maybe<Array<Integration_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Name_Order_By>>;
  where?: Maybe<Integration_Name_Bool_Exp>;
};


export type Subscription_RootIntegration_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Integration_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Integration_Name_Order_By>>;
  where?: Maybe<Integration_Name_Bool_Exp>;
};


export type Subscription_RootIntegration_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootInvitation_StatusArgs = {
  distinct_on?: Maybe<Array<Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitation_Status_Order_By>>;
  where?: Maybe<Invitation_Status_Bool_Exp>;
};


export type Subscription_RootInvitation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Invitation_Status_Order_By>>;
  where?: Maybe<Invitation_Status_Bool_Exp>;
};


export type Subscription_RootInvitation_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootKeywordArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


export type Subscription_RootKeyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Keyword_Order_By>>;
  where?: Maybe<Keyword_Bool_Exp>;
};


export type Subscription_RootKeyword_By_PkArgs = {
  keyword_id: Scalars['Int'];
};


export type Subscription_RootLabel_ControlArgs = {
  distinct_on?: Maybe<Array<Label_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Label_Control_Order_By>>;
  where?: Maybe<Label_Control_Bool_Exp>;
};


export type Subscription_RootLabel_Control_AggregateArgs = {
  distinct_on?: Maybe<Array<Label_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Label_Control_Order_By>>;
  where?: Maybe<Label_Control_Bool_Exp>;
};


export type Subscription_RootLabel_Control_By_PkArgs = {
  label_control_id: Scalars['Int'];
};


export type Subscription_RootLabelsArgs = {
  distinct_on?: Maybe<Array<Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Labels_Order_By>>;
  where?: Maybe<Labels_Bool_Exp>;
};


export type Subscription_RootLabels_AggregateArgs = {
  distinct_on?: Maybe<Array<Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Labels_Order_By>>;
  where?: Maybe<Labels_Bool_Exp>;
};


export type Subscription_RootLabels_By_PkArgs = {
  labels_id: Scalars['Int'];
};


export type Subscription_RootLesson_PlanArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Order_By>>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Order_By>>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_ArticleArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Article_Order_By>>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Article_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Article_Order_By>>;
  where?: Maybe<Lesson_Plan_Article_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Article_By_PkArgs = {
  lesson_plan_article_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_AssetArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Asset_Order_By>>;
  where?: Maybe<Lesson_Plan_Asset_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Asset_By_PkArgs = {
  lesson_plan_asset_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_By_PkArgs = {
  lesson_plan_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Catalog_Item_Order_By>>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Catalog_Item_Order_By>>;
  where?: Maybe<Lesson_Plan_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Catalog_Item_By_PkArgs = {
  lesson_plan_catalog_item_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_DisciplineArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Discipline_Order_By>>;
  where?: Maybe<Lesson_Plan_Discipline_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Discipline_By_PkArgs = {
  lesson_plan_discipline_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_GradeArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Grade_Order_By>>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Grade_Order_By>>;
  where?: Maybe<Lesson_Plan_Grade_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Grade_By_PkArgs = {
  lesson_plan_grade_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_KeywordArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Keyword_Order_By>>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Keyword_Order_By>>;
  where?: Maybe<Lesson_Plan_Keyword_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Keyword_By_PkArgs = {
  lesson_plan_keyword_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Musical_Skill_By_PkArgs = {
  lesson_plan_musical_skill_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_Non_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Non_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Lesson_Plan_Non_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Non_Musical_Skill_By_PkArgs = {
  lesson_plan_non_musical_skill_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_Skill_LevelArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Skill_Level_Order_By>>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Skill_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Skill_Level_Order_By>>;
  where?: Maybe<Lesson_Plan_Skill_Level_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Skill_Level_By_PkArgs = {
  lesson_plan_skill_level_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_SongArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Song_Order_By>>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Song_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Song_Order_By>>;
  where?: Maybe<Lesson_Plan_Song_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Song_By_PkArgs = {
  lesson_plan_song_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_StandardArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Standard_Order_By>>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Standard_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Standard_Order_By>>;
  where?: Maybe<Lesson_Plan_Standard_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Standard_By_PkArgs = {
  lesson_plan_standar_id: Scalars['Int'];
};


export type Subscription_RootLesson_Plan_TipArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Tip_Order_By>>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Tip_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Tip_Order_By>>;
  where?: Maybe<Lesson_Plan_Tip_Bool_Exp>;
};


export type Subscription_RootLesson_Plan_Tip_By_PkArgs = {
  lesson_plan_tip_id: Scalars['Int'];
};


export type Subscription_RootLockable_ContentArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Order_By>>;
  where?: Maybe<Lockable_Content_Bool_Exp>;
};


export type Subscription_RootLockable_Content_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Order_By>>;
  where?: Maybe<Lockable_Content_Bool_Exp>;
};


export type Subscription_RootLockable_Content_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
};


export type Subscription_RootLockable_Content_ClassArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Class_Order_By>>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};


export type Subscription_RootLockable_Content_Class_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Class_Order_By>>;
  where?: Maybe<Lockable_Content_Class_Bool_Exp>;
};


export type Subscription_RootLockable_Content_Class_By_PkArgs = {
  class_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};


export type Subscription_RootLockable_Content_DistrictArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


export type Subscription_RootLockable_Content_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_District_Order_By>>;
  where?: Maybe<Lockable_Content_District_Bool_Exp>;
};


export type Subscription_RootLockable_Content_District_By_PkArgs = {
  district_id: Scalars['Int'];
  lockable_content_id: Scalars['Int'];
};


export type Subscription_RootLockable_Content_SchoolArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


export type Subscription_RootLockable_Content_School_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_School_Order_By>>;
  where?: Maybe<Lockable_Content_School_Bool_Exp>;
};


export type Subscription_RootLockable_Content_School_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
  school_id: Scalars['Int'];
};


export type Subscription_RootLockable_Content_TeacherArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Teacher_Order_By>>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};


export type Subscription_RootLockable_Content_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Lockable_Content_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lockable_Content_Teacher_Order_By>>;
  where?: Maybe<Lockable_Content_Teacher_Bool_Exp>;
};


export type Subscription_RootLockable_Content_Teacher_By_PkArgs = {
  lockable_content_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
};


export type Subscription_RootMaturity_LevelArgs = {
  distinct_on?: Maybe<Array<Maturity_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Maturity_Level_Order_By>>;
  where?: Maybe<Maturity_Level_Bool_Exp>;
};


export type Subscription_RootMaturity_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Maturity_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Maturity_Level_Order_By>>;
  where?: Maybe<Maturity_Level_Bool_Exp>;
};


export type Subscription_RootMaturity_Level_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootMembership_TypeArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Subscription_RootMembership_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Membership_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Membership_Type_Order_By>>;
  where?: Maybe<Membership_Type_Bool_Exp>;
};


export type Subscription_RootMembership_Type_By_PkArgs = {
  membership_type_id: Scalars['Int'];
};


export type Subscription_RootMidiArgs = {
  distinct_on?: Maybe<Array<Midi_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Midi_Order_By>>;
  where?: Maybe<Midi_Bool_Exp>;
};


export type Subscription_RootMidi_AggregateArgs = {
  distinct_on?: Maybe<Array<Midi_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Midi_Order_By>>;
  where?: Maybe<Midi_Bool_Exp>;
};


export type Subscription_RootMidi_By_PkArgs = {
  midi_id: Scalars['Int'];
};


export type Subscription_RootModuleArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Subscription_RootModule_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Order_By>>;
  where?: Maybe<Module_Bool_Exp>;
};


export type Subscription_RootModule_By_PkArgs = {
  module_id: Scalars['Int'];
};


export type Subscription_RootModule_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Catalog_Item_Order_By>>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootModule_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Catalog_Item_Order_By>>;
  where?: Maybe<Module_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootModule_Catalog_Item_By_PkArgs = {
  module_catalog_item_id: Scalars['Int'];
};


export type Subscription_RootModule_GradeArgs = {
  distinct_on?: Maybe<Array<Module_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Grade_Order_By>>;
  where?: Maybe<Module_Grade_Bool_Exp>;
};


export type Subscription_RootModule_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Grade_Order_By>>;
  where?: Maybe<Module_Grade_Bool_Exp>;
};


export type Subscription_RootModule_Grade_By_PkArgs = {
  module_grade_id: Scalars['Int'];
};


export type Subscription_RootModule_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootModule_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Module_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Module_Lesson_Plan_Order_By>>;
  where?: Maybe<Module_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootModule_Lesson_Plan_By_PkArgs = {
  module_lesson_plan_id: Scalars['Int'];
};


export type Subscription_RootMusical_SkillArgs = {
  distinct_on?: Maybe<Array<Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Musical_Skill_Order_By>>;
  where?: Maybe<Musical_Skill_Bool_Exp>;
};


export type Subscription_RootMusical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Musical_Skill_Order_By>>;
  where?: Maybe<Musical_Skill_Bool_Exp>;
};


export type Subscription_RootMusical_Skill_By_PkArgs = {
  musical_skill_id: Scalars['Int'];
};


export type Subscription_RootNon_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootNon_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootNon_Musical_Skill_By_PkArgs = {
  non_musical_skill_id: Scalars['Int'];
};


export type Subscription_RootNon_Musical_Skill_TypeArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Type_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Type_Bool_Exp>;
};


export type Subscription_RootNon_Musical_Skill_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Non_Musical_Skill_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Non_Musical_Skill_Type_Order_By>>;
  where?: Maybe<Non_Musical_Skill_Type_Bool_Exp>;
};


export type Subscription_RootNon_Musical_Skill_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootOrderArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Order_By>>;
  where?: Maybe<Order_Bool_Exp>;
};


export type Subscription_RootOrder_By_PkArgs = {
  order_id: Scalars['Int'];
};


export type Subscription_RootOrder_StatusArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Status_Order_By>>;
  where?: Maybe<Order_Status_Bool_Exp>;
};


export type Subscription_RootOrder_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootParent_InvitationArgs = {
  distinct_on?: Maybe<Array<Parent_Invitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Parent_Invitation_Order_By>>;
  where?: Maybe<Parent_Invitation_Bool_Exp>;
};


export type Subscription_RootParent_Invitation_AggregateArgs = {
  distinct_on?: Maybe<Array<Parent_Invitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Parent_Invitation_Order_By>>;
  where?: Maybe<Parent_Invitation_Bool_Exp>;
};


export type Subscription_RootParent_Invitation_By_PkArgs = {
  parent_invitation_id: Scalars['Int'];
};


export type Subscription_RootPartnerArgs = {
  distinct_on?: Maybe<Array<Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Order_By>>;
  where?: Maybe<Partner_Bool_Exp>;
};


export type Subscription_RootPartner_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Order_By>>;
  where?: Maybe<Partner_Bool_Exp>;
};


export type Subscription_RootPartner_By_PkArgs = {
  partner_id: Scalars['Int'];
};


export type Subscription_RootPartner_ModuleArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


export type Subscription_RootPartner_Module_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Module_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Module_Order_By>>;
  where?: Maybe<Partner_Module_Bool_Exp>;
};


export type Subscription_RootPartner_Module_By_PkArgs = {
  partner_module_id: Scalars['Int'];
};


export type Subscription_RootPartner_PlaylistArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


export type Subscription_RootPartner_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Partner_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Partner_Playlist_Order_By>>;
  where?: Maybe<Partner_Playlist_Bool_Exp>;
};


export type Subscription_RootPartner_Playlist_By_PkArgs = {
  partner_playlist_id: Scalars['Int'];
};


export type Subscription_RootPlaylistArgs = {
  distinct_on?: Maybe<Array<Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Playlist_Order_By>>;
  where?: Maybe<Playlist_Bool_Exp>;
};


export type Subscription_RootPlaylist_AggregateArgs = {
  distinct_on?: Maybe<Array<Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Playlist_Order_By>>;
  where?: Maybe<Playlist_Bool_Exp>;
};


export type Subscription_RootPlaylist_By_PkArgs = {
  playlist_id: Scalars['Int'];
};


export type Subscription_RootPrice_TypeArgs = {
  distinct_on?: Maybe<Array<Price_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Type_Order_By>>;
  where?: Maybe<Price_Type_Bool_Exp>;
};


export type Subscription_RootPrice_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Type_Order_By>>;
  where?: Maybe<Price_Type_Bool_Exp>;
};


export type Subscription_RootPrice_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootPrivacyArgs = {
  distinct_on?: Maybe<Array<Privacy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Privacy_Order_By>>;
  where?: Maybe<Privacy_Bool_Exp>;
};


export type Subscription_RootPrivacy_AggregateArgs = {
  distinct_on?: Maybe<Array<Privacy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Privacy_Order_By>>;
  where?: Maybe<Privacy_Bool_Exp>;
};


export type Subscription_RootPrivacy_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootProduct_NameArgs = {
  distinct_on?: Maybe<Array<Product_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Name_Order_By>>;
  where?: Maybe<Product_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Name_Order_By>>;
  where?: Maybe<Product_Name_Bool_Exp>;
};


export type Subscription_RootProduct_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootPublisherArgs = {
  distinct_on?: Maybe<Array<Publisher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Order_By>>;
  where?: Maybe<Publisher_Bool_Exp>;
};


export type Subscription_RootPublisher_AggregateArgs = {
  distinct_on?: Maybe<Array<Publisher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Order_By>>;
  where?: Maybe<Publisher_Bool_Exp>;
};


export type Subscription_RootPublisher_By_PkArgs = {
  publisher_id: Scalars['Int'];
};


export type Subscription_RootPublisher_ControlArgs = {
  distinct_on?: Maybe<Array<Publisher_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Control_Order_By>>;
  where?: Maybe<Publisher_Control_Bool_Exp>;
};


export type Subscription_RootPublisher_Control_AggregateArgs = {
  distinct_on?: Maybe<Array<Publisher_Control_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Publisher_Control_Order_By>>;
  where?: Maybe<Publisher_Control_Bool_Exp>;
};


export type Subscription_RootPublisher_Control_By_PkArgs = {
  publisher_control_id: Scalars['Int'];
};


export type Subscription_RootReactionArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


export type Subscription_RootReaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reaction_Order_By>>;
  where?: Maybe<Reaction_Bool_Exp>;
};


export type Subscription_RootReaction_By_PkArgs = {
  reaction_id: Scalars['Int'];
};


export type Subscription_RootReset_PasswordArgs = {
  distinct_on?: Maybe<Array<Reset_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reset_Password_Order_By>>;
  where?: Maybe<Reset_Password_Bool_Exp>;
};


export type Subscription_RootReset_Password_AggregateArgs = {
  distinct_on?: Maybe<Array<Reset_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reset_Password_Order_By>>;
  where?: Maybe<Reset_Password_Bool_Exp>;
};


export type Subscription_RootReset_Password_By_PkArgs = {
  reset_password_id: Scalars['Int'];
};


export type Subscription_RootRole_NameArgs = {
  distinct_on?: Maybe<Array<Role_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Name_Order_By>>;
  where?: Maybe<Role_Name_Bool_Exp>;
};


export type Subscription_RootRole_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Name_Order_By>>;
  where?: Maybe<Role_Name_Bool_Exp>;
};


export type Subscription_RootRole_Name_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootRoyalty_LogArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


export type Subscription_RootRoyalty_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


export type Subscription_RootRoyalty_Log_By_PkArgs = {
  royalty_log_id: Scalars['Int'];
};


export type Subscription_RootScaleArgs = {
  distinct_on?: Maybe<Array<Scale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scale_Order_By>>;
  where?: Maybe<Scale_Bool_Exp>;
};


export type Subscription_RootScale_AggregateArgs = {
  distinct_on?: Maybe<Array<Scale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scale_Order_By>>;
  where?: Maybe<Scale_Bool_Exp>;
};


export type Subscription_RootScale_By_PkArgs = {
  scale_id: Scalars['Int'];
};


export type Subscription_RootSchoolArgs = {
  distinct_on?: Maybe<Array<School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Order_By>>;
  where?: Maybe<School_Bool_Exp>;
};


export type Subscription_RootSchool_AdminArgs = {
  distinct_on?: Maybe<Array<School_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Admin_Order_By>>;
  where?: Maybe<School_Admin_Bool_Exp>;
};


export type Subscription_RootSchool_Admin_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Admin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Admin_Order_By>>;
  where?: Maybe<School_Admin_Bool_Exp>;
};


export type Subscription_RootSchool_Admin_By_PkArgs = {
  school_admin_id: Scalars['Int'];
};


export type Subscription_RootSchool_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Order_By>>;
  where?: Maybe<School_Bool_Exp>;
};


export type Subscription_RootSchool_By_PkArgs = {
  school_id: Scalars['Int'];
};


export type Subscription_RootSchool_TeacherArgs = {
  distinct_on?: Maybe<Array<School_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Teacher_Order_By>>;
  where?: Maybe<School_Teacher_Bool_Exp>;
};


export type Subscription_RootSchool_Teacher_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<School_Teacher_Order_By>>;
  where?: Maybe<School_Teacher_Bool_Exp>;
};


export type Subscription_RootSchool_Teacher_By_PkArgs = {
  school_teacher_id: Scalars['Int'];
};


export type Subscription_RootScreen_TypeArgs = {
  distinct_on?: Maybe<Array<Screen_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Screen_Type_Order_By>>;
  where?: Maybe<Screen_Type_Bool_Exp>;
};


export type Subscription_RootScreen_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Screen_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Screen_Type_Order_By>>;
  where?: Maybe<Screen_Type_Bool_Exp>;
};


export type Subscription_RootScreen_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootSequenceArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Order_By>>;
  where?: Maybe<Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_By_PkArgs = {
  sequence_id: Scalars['Int'];
};


export type Subscription_RootSequence_KeywordArgs = {
  distinct_on?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Keyword_Order_By>>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};


export type Subscription_RootSequence_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Keyword_Order_By>>;
  where?: Maybe<Sequence_Keyword_Bool_Exp>;
};


export type Subscription_RootSequence_Keyword_By_PkArgs = {
  sequence_keyword_id: Scalars['Int'];
};


export type Subscription_RootSequence_Lesson_PlanArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootSequence_Lesson_Plan_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Lesson_Plan_Order_By>>;
  where?: Maybe<Sequence_Lesson_Plan_Bool_Exp>;
};


export type Subscription_RootSequence_Lesson_Plan_By_PkArgs = {
  sequence_lesson_plan_id: Scalars['Int'];
};


export type Subscription_RootSequence_TopicArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Order_By>>;
  where?: Maybe<Sequence_Topic_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Order_By>>;
  where?: Maybe<Sequence_Topic_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_By_PkArgs = {
  sequence_topic_id: Scalars['Int'];
};


export type Subscription_RootSequence_Topic_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Catalog_Item_Order_By>>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Catalog_Item_Order_By>>;
  where?: Maybe<Sequence_Topic_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_Catalog_Item_By_PkArgs = {
  sequence_topic_catalog_item_id: Scalars['Int'];
};


export type Subscription_RootSequence_Topic_KeywordArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Keyword_Order_By>>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Keyword_Order_By>>;
  where?: Maybe<Sequence_Topic_Keyword_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_Keyword_By_PkArgs = {
  sequence_topic_keyword_id: Scalars['Int'];
};


export type Subscription_RootSequence_Topic_SequenceArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_Sequence_AggregateArgs = {
  distinct_on?: Maybe<Array<Sequence_Topic_Sequence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sequence_Topic_Sequence_Order_By>>;
  where?: Maybe<Sequence_Topic_Sequence_Bool_Exp>;
};


export type Subscription_RootSequence_Topic_Sequence_By_PkArgs = {
  sequence_topic_sequence_id: Scalars['Int'];
};


export type Subscription_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_By_PkArgs = {
  session_id: Scalars['Int'];
};


export type Subscription_RootSkill_LevelArgs = {
  distinct_on?: Maybe<Array<Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Level_Order_By>>;
  where?: Maybe<Skill_Level_Bool_Exp>;
};


export type Subscription_RootSkill_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Skill_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Skill_Level_Order_By>>;
  where?: Maybe<Skill_Level_Bool_Exp>;
};


export type Subscription_RootSkill_Level_By_PkArgs = {
  skill_level_id: Scalars['Int'];
};


export type Subscription_RootSongArgs = {
  distinct_on?: Maybe<Array<Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Order_By>>;
  where?: Maybe<Song_Bool_Exp>;
};


export type Subscription_RootSong_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Order_By>>;
  where?: Maybe<Song_Bool_Exp>;
};


export type Subscription_RootSong_By_PkArgs = {
  song_id: Scalars['Int'];
};


export type Subscription_RootSong_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Catalog_Item_Order_By>>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootSong_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Catalog_Item_Order_By>>;
  where?: Maybe<Song_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootSong_Catalog_Item_By_PkArgs = {
  song_catalog_item_id: Scalars['Int'];
};


export type Subscription_RootSong_GradeArgs = {
  distinct_on?: Maybe<Array<Song_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Grade_Order_By>>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};


export type Subscription_RootSong_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Grade_Order_By>>;
  where?: Maybe<Song_Grade_Bool_Exp>;
};


export type Subscription_RootSong_Grade_By_PkArgs = {
  song_grade_id: Scalars['Int'];
};


export type Subscription_RootSong_KeywordArgs = {
  distinct_on?: Maybe<Array<Song_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Keyword_Order_By>>;
  where?: Maybe<Song_Keyword_Bool_Exp>;
};


export type Subscription_RootSong_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Song_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Song_Keyword_Order_By>>;
  where?: Maybe<Song_Keyword_Bool_Exp>;
};


export type Subscription_RootSong_Keyword_By_PkArgs = {
  song_keyword_id: Scalars['Int'];
};


export type Subscription_RootStandardArgs = {
  distinct_on?: Maybe<Array<Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Order_By>>;
  where?: Maybe<Standard_Bool_Exp>;
};


export type Subscription_RootStandard_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Order_By>>;
  where?: Maybe<Standard_Bool_Exp>;
};


export type Subscription_RootStandard_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootStandard_OptionArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};


export type Subscription_RootStandard_Option_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Option_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Option_Order_By>>;
  where?: Maybe<Standard_Option_Bool_Exp>;
};


export type Subscription_RootStandard_Option_By_PkArgs = {
  standar_option_id: Scalars['Int'];
};


export type Subscription_RootStandard_SubcategoryArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};


export type Subscription_RootStandard_Subcategory_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Subcategory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Subcategory_Order_By>>;
  where?: Maybe<Standard_Subcategory_Bool_Exp>;
};


export type Subscription_RootStandard_Subcategory_By_PkArgs = {
  standar_subcategory_id: Scalars['Int'];
};


export type Subscription_RootStudentArgs = {
  distinct_on?: Maybe<Array<Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Order_By>>;
  where?: Maybe<Student_Bool_Exp>;
};


export type Subscription_RootStudent_ActivityArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Order_By>>;
  where?: Maybe<Student_Activity_Bool_Exp>;
};


export type Subscription_RootStudent_Activity_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Order_By>>;
  where?: Maybe<Student_Activity_Bool_Exp>;
};


export type Subscription_RootStudent_Activity_By_PkArgs = {
  student_activity_id: Scalars['Int'];
};


export type Subscription_RootStudent_Activity_TypeArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Type_Order_By>>;
  where?: Maybe<Student_Activity_Type_Bool_Exp>;
};


export type Subscription_RootStudent_Activity_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Activity_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Activity_Type_Order_By>>;
  where?: Maybe<Student_Activity_Type_Bool_Exp>;
};


export type Subscription_RootStudent_Activity_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootStudent_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Order_By>>;
  where?: Maybe<Student_Bool_Exp>;
};


export type Subscription_RootStudent_BadgeArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Order_By>>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};


export type Subscription_RootStudent_Badge_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Order_By>>;
  where?: Maybe<Student_Badge_Bool_Exp>;
};


export type Subscription_RootStudent_Badge_By_PkArgs = {
  student_badge_id: Scalars['Int'];
};


export type Subscription_RootStudent_Badge_EventArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Event_Order_By>>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};


export type Subscription_RootStudent_Badge_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Event_Order_By>>;
  where?: Maybe<Student_Badge_Event_Bool_Exp>;
};


export type Subscription_RootStudent_Badge_Event_By_PkArgs = {
  student_badge_event_id: Scalars['Int'];
};


export type Subscription_RootStudent_Badge_ResumeArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Resume_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Resume_Order_By>>;
  where?: Maybe<Student_Badge_Resume_Bool_Exp>;
};


export type Subscription_RootStudent_Badge_Resume_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Badge_Resume_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Badge_Resume_Order_By>>;
  where?: Maybe<Student_Badge_Resume_Bool_Exp>;
};


export type Subscription_RootStudent_BannerArgs = {
  distinct_on?: Maybe<Array<Student_Banner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Banner_Order_By>>;
  where?: Maybe<Student_Banner_Bool_Exp>;
};


export type Subscription_RootStudent_Banner_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Banner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Banner_Order_By>>;
  where?: Maybe<Student_Banner_Bool_Exp>;
};


export type Subscription_RootStudent_Banner_By_PkArgs = {
  student_banner_id: Scalars['Int'];
};


export type Subscription_RootStudent_By_PkArgs = {
  student_id: Scalars['Int'];
};


export type Subscription_RootStudent_DistrictArgs = {
  distinct_on?: Maybe<Array<Student_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_District_Order_By>>;
  where?: Maybe<Student_District_Bool_Exp>;
};


export type Subscription_RootStudent_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_District_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_District_Order_By>>;
  where?: Maybe<Student_District_Bool_Exp>;
};


export type Subscription_RootStudent_GoalArgs = {
  distinct_on?: Maybe<Array<Student_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Goal_Order_By>>;
  where?: Maybe<Student_Goal_Bool_Exp>;
};


export type Subscription_RootStudent_Goal_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Goal_Order_By>>;
  where?: Maybe<Student_Goal_Bool_Exp>;
};


export type Subscription_RootStudent_Goal_By_PkArgs = {
  student_goal_id: Scalars['Int'];
};


export type Subscription_RootStudent_GradeArgs = {
  distinct_on?: Maybe<Array<Student_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Grade_Order_By>>;
  where?: Maybe<Student_Grade_Bool_Exp>;
};


export type Subscription_RootStudent_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Grade_Order_By>>;
  where?: Maybe<Student_Grade_Bool_Exp>;
};


export type Subscription_RootStudent_IntegrationArgs = {
  distinct_on?: Maybe<Array<Student_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Integration_Order_By>>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};


export type Subscription_RootStudent_Integration_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Integration_Order_By>>;
  where?: Maybe<Student_Integration_Bool_Exp>;
};


export type Subscription_RootStudent_Integration_By_PkArgs = {
  student_integration_id: Scalars['Int'];
};


export type Subscription_RootStudent_Lesson_Plan_FavoriteArgs = {
  distinct_on?: Maybe<Array<Student_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Student_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Subscription_RootStudent_Lesson_Plan_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Student_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Subscription_RootStudent_Lesson_Plan_Favorite_By_PkArgs = {
  student_lesson_plan_favorite_id: Scalars['Int'];
};


export type Subscription_RootStudent_Productivity_DailyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Daily_Order_By>>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};


export type Subscription_RootStudent_Productivity_Daily_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Daily_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Daily_Order_By>>;
  where?: Maybe<Student_Productivity_Daily_Bool_Exp>;
};


export type Subscription_RootStudent_Productivity_Daily_By_PkArgs = {
  student_productivity_daily_id: Scalars['Int'];
};


export type Subscription_RootStudent_Productivity_MonthlyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Monthly_Order_By>>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};


export type Subscription_RootStudent_Productivity_Monthly_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Monthly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Monthly_Order_By>>;
  where?: Maybe<Student_Productivity_Monthly_Bool_Exp>;
};


export type Subscription_RootStudent_Productivity_Monthly_By_PkArgs = {
  student_productivity_monthly_id: Scalars['Int'];
};


export type Subscription_RootStudent_Productivity_YearlyArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Yearly_Order_By>>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};


export type Subscription_RootStudent_Productivity_Yearly_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Productivity_Yearly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Productivity_Yearly_Order_By>>;
  where?: Maybe<Student_Productivity_Yearly_Bool_Exp>;
};


export type Subscription_RootStudent_Productivity_Yearly_By_PkArgs = {
  student_productivity_yearly_id: Scalars['Int'];
};


export type Subscription_RootStudent_SettingArgs = {
  distinct_on?: Maybe<Array<Student_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Setting_Order_By>>;
  where?: Maybe<Student_Setting_Bool_Exp>;
};


export type Subscription_RootStudent_Setting_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Setting_Order_By>>;
  where?: Maybe<Student_Setting_Bool_Exp>;
};


export type Subscription_RootStudent_Setting_By_PkArgs = {
  student_setting_id: Scalars['Int'];
};


export type Subscription_RootStudent_Song_FavoriteArgs = {
  distinct_on?: Maybe<Array<Student_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Song_Favorite_Order_By>>;
  where?: Maybe<Student_Song_Favorite_Bool_Exp>;
};


export type Subscription_RootStudent_Song_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Song_Favorite_Order_By>>;
  where?: Maybe<Student_Song_Favorite_Bool_Exp>;
};


export type Subscription_RootStudent_Song_Favorite_By_PkArgs = {
  student_song_favorite_id: Scalars['Int'];
};


export type Subscription_RootStudent_Tip_FavoriteArgs = {
  distinct_on?: Maybe<Array<Student_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Tip_Favorite_Order_By>>;
  where?: Maybe<Student_Tip_Favorite_Bool_Exp>;
};


export type Subscription_RootStudent_Tip_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Student_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Student_Tip_Favorite_Order_By>>;
  where?: Maybe<Student_Tip_Favorite_Bool_Exp>;
};


export type Subscription_RootStudent_Tip_Favorite_By_PkArgs = {
  student_tip_favorite_id: Scalars['Int'];
};


export type Subscription_RootSubmissionArgs = {
  distinct_on?: Maybe<Array<Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Order_By>>;
  where?: Maybe<Submission_Bool_Exp>;
};


export type Subscription_RootSubmission_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Order_By>>;
  where?: Maybe<Submission_Bool_Exp>;
};


export type Subscription_RootSubmission_AssetArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


export type Subscription_RootSubmission_Asset_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Asset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Asset_Order_By>>;
  where?: Maybe<Submission_Asset_Bool_Exp>;
};


export type Subscription_RootSubmission_Asset_By_PkArgs = {
  submission_asset_id: Scalars['Int'];
};


export type Subscription_RootSubmission_By_PkArgs = {
  submission_id: Scalars['Int'];
};


export type Subscription_RootSubmission_StateArgs = {
  distinct_on?: Maybe<Array<Submission_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_State_Order_By>>;
  where?: Maybe<Submission_State_Bool_Exp>;
};


export type Subscription_RootSubmission_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_State_Order_By>>;
  where?: Maybe<Submission_State_Bool_Exp>;
};


export type Subscription_RootSubmission_State_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootSubmission_StudentArgs = {
  distinct_on?: Maybe<Array<Submission_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Student_Order_By>>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};


export type Subscription_RootSubmission_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Student_Order_By>>;
  where?: Maybe<Submission_Student_Bool_Exp>;
};


export type Subscription_RootSubmission_Student_By_PkArgs = {
  submission_student_id: Scalars['Int'];
};


export type Subscription_RootSubmission_WurrlyArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_Order_By>>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};


export type Subscription_RootSubmission_Wurrly_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_Order_By>>;
  where?: Maybe<Submission_Wurrly_Bool_Exp>;
};


export type Subscription_RootSubmission_Wurrly_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


export type Subscription_RootSubmission_Wurrly_StateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_State_Order_By>>;
  where?: Maybe<Submission_Wurrly_State_Bool_Exp>;
};


export type Subscription_RootSubmission_Wurrly_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Submission_Wurrly_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Submission_Wurrly_State_Order_By>>;
  where?: Maybe<Submission_Wurrly_State_Bool_Exp>;
};


export type Subscription_RootSubmission_Wurrly_State_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootTeacherArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Subscription_RootTeacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Subscription_RootTeacher_By_PkArgs = {
  teacher_id: Scalars['Int'];
};


export type Subscription_RootTeacher_IntegrationArgs = {
  distinct_on?: Maybe<Array<Teacher_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Integration_Order_By>>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};


export type Subscription_RootTeacher_Integration_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Integration_Order_By>>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};


export type Subscription_RootTeacher_Integration_By_PkArgs = {
  teacher_integration_id: Scalars['Int'];
};


export type Subscription_RootTeacher_Lesson_Plan_FavoriteArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Subscription_RootTeacher_Lesson_Plan_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


export type Subscription_RootTeacher_Lesson_Plan_Favorite_By_PkArgs = {
  teacher_lesson_plan_favorite_id: Scalars['Int'];
};


export type Subscription_RootTeacher_SettingArgs = {
  distinct_on?: Maybe<Array<Teacher_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Setting_Order_By>>;
  where?: Maybe<Teacher_Setting_Bool_Exp>;
};


export type Subscription_RootTeacher_Setting_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Setting_Order_By>>;
  where?: Maybe<Teacher_Setting_Bool_Exp>;
};


export type Subscription_RootTeacher_Setting_By_PkArgs = {
  teacher_setting_id: Scalars['Int'];
};


export type Subscription_RootTeacher_Song_FavoriteArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


export type Subscription_RootTeacher_Song_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


export type Subscription_RootTeacher_Song_Favorite_By_PkArgs = {
  teacher_song_favorite_id: Scalars['Int'];
};


export type Subscription_RootTeacher_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Teacher_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Subscription_Order_By>>;
  where?: Maybe<Teacher_Subscription_Bool_Exp>;
};


export type Subscription_RootTeacher_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Subscription_Order_By>>;
  where?: Maybe<Teacher_Subscription_Bool_Exp>;
};


export type Subscription_RootTeacher_Subscription_By_PkArgs = {
  subscription_id: Scalars['Int'];
};


export type Subscription_RootTeacher_Tip_FavoriteArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


export type Subscription_RootTeacher_Tip_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


export type Subscription_RootTeacher_Tip_Favorite_By_PkArgs = {
  teacher_tip_favorite_id: Scalars['Int'];
};


export type Subscription_RootTeacher_Wurrly_CommentArgs = {
  distinct_on?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Wurrly_Comment_Order_By>>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};


export type Subscription_RootTeacher_Wurrly_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Wurrly_Comment_Order_By>>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};


export type Subscription_RootTeacher_Wurrly_Comment_By_PkArgs = {
  teacher_wurrly_comment_id: Scalars['Int'];
};


export type Subscription_RootTipArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Subscription_RootTip_AgeArgs = {
  distinct_on?: Maybe<Array<Tip_Age_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Age_Order_By>>;
  where?: Maybe<Tip_Age_Bool_Exp>;
};


export type Subscription_RootTip_Age_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Age_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Age_Order_By>>;
  where?: Maybe<Tip_Age_Bool_Exp>;
};


export type Subscription_RootTip_Age_By_PkArgs = {
  tip_age_id: Scalars['Int'];
};


export type Subscription_RootTip_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Subscription_RootTip_By_PkArgs = {
  tip_id: Scalars['Int'];
};


export type Subscription_RootTip_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Catalog_Item_Order_By>>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootTip_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Catalog_Item_Order_By>>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};


export type Subscription_RootTip_Catalog_Item_By_PkArgs = {
  tip_catalog_item_id: Scalars['Int'];
};


export type Subscription_RootTip_GradeArgs = {
  distinct_on?: Maybe<Array<Tip_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Grade_Order_By>>;
  where?: Maybe<Tip_Grade_Bool_Exp>;
};


export type Subscription_RootTip_Grade_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Grade_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Grade_Order_By>>;
  where?: Maybe<Tip_Grade_Bool_Exp>;
};


export type Subscription_RootTip_Grade_By_PkArgs = {
  tip_grade_id: Scalars['Int'];
};


export type Subscription_RootTip_KeywordArgs = {
  distinct_on?: Maybe<Array<Tip_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Keyword_Order_By>>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};


export type Subscription_RootTip_Keyword_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Keyword_Order_By>>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};


export type Subscription_RootTip_Keyword_By_PkArgs = {
  tip_keyword_id: Scalars['Int'];
};


export type Subscription_RootTip_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Tip_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootTip_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootTip_Musical_Skill_By_PkArgs = {
  tip_musical_skill_id: Scalars['Int'];
};


export type Subscription_RootTip_Non_Musical_SkillArgs = {
  distinct_on?: Maybe<Array<Tip_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Non_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootTip_Non_Musical_Skill_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Non_Musical_Skill_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Non_Musical_Skill_Order_By>>;
  where?: Maybe<Tip_Non_Musical_Skill_Bool_Exp>;
};


export type Subscription_RootTip_Non_Musical_Skill_By_PkArgs = {
  tip_non_musical_skill_id: Scalars['Int'];
};


export type Subscription_RootTip_PlaylistArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};


export type Subscription_RootTip_Playlist_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};


export type Subscription_RootTip_Playlist_By_PkArgs = {
  tip_playlist_id: Scalars['Int'];
};


export type Subscription_RootTrackArgs = {
  distinct_on?: Maybe<Array<Track_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Order_By>>;
  where?: Maybe<Track_Bool_Exp>;
};


export type Subscription_RootTrack_AggregateArgs = {
  distinct_on?: Maybe<Array<Track_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Order_By>>;
  where?: Maybe<Track_Bool_Exp>;
};


export type Subscription_RootTrack_By_PkArgs = {
  track_id: Scalars['Int'];
};


export type Subscription_RootTrack_TypeArgs = {
  distinct_on?: Maybe<Array<Track_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Type_Order_By>>;
  where?: Maybe<Track_Type_Bool_Exp>;
};


export type Subscription_RootTrack_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Track_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Track_Type_Order_By>>;
  where?: Maybe<Track_Type_Bool_Exp>;
};


export type Subscription_RootTrack_Type_By_PkArgs = {
  track_type_id: Scalars['Int'];
};


export type Subscription_RootTranspose_IndexArgs = {
  distinct_on?: Maybe<Array<Transpose_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transpose_Index_Order_By>>;
  where?: Maybe<Transpose_Index_Bool_Exp>;
};


export type Subscription_RootTranspose_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Transpose_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transpose_Index_Order_By>>;
  where?: Maybe<Transpose_Index_Bool_Exp>;
};


export type Subscription_RootTranspose_Index_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUtils_Avg_LessonsArgs = {
  distinct_on?: Maybe<Array<Utils_Avg_Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Avg_Lessons_Order_By>>;
  where?: Maybe<Utils_Avg_Lessons_Bool_Exp>;
};


export type Subscription_RootUtils_Avg_Lessons_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Avg_Lessons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Avg_Lessons_Order_By>>;
  where?: Maybe<Utils_Avg_Lessons_Bool_Exp>;
};


export type Subscription_RootUtils_Most_Attended_ClassesArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Subscription_RootUtils_Most_Attended_Classes_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Subscription_RootUtils_Most_Attended_SubjectsArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Subscription_RootUtils_Most_Attended_Subjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Last_ClassesArgs = {
  args: Utils_Search_Last_Classes_Args;
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Last_Classes_AggregateArgs = {
  args: Utils_Search_Last_Classes_Args;
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Most_Attended_ClassesArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Most_Attended_Classes_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Classes_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Most_Attended_SubjectsArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Most_Attended_Subjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Most_Attended_Subjects_Order_By>>;
  where?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
};


export type Subscription_RootUtils_Search_New_UsersArgs = {
  args: Utils_Search_New_Users_Args;
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Search_New_Users_AggregateArgs = {
  args: Utils_Search_New_Users_Args;
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Teacher_SubscriptionsArgs = {
  args: Utils_Search_Teacher_Subscriptions_Args;
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Search_Teacher_Subscriptions_AggregateArgs = {
  args: Utils_Search_Teacher_Subscriptions_Args;
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Teacher_Subscriptions_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Teacher_Subscriptions_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Order_By>>;
  where?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Users_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Subscription_RootUtils_Users_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Utils_Users_Per_Month_Order_By>>;
  where?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
};


export type Subscription_RootVisibilityArgs = {
  distinct_on?: Maybe<Array<Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visibility_Order_By>>;
  where?: Maybe<Visibility_Bool_Exp>;
};


export type Subscription_RootVisibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Visibility_Order_By>>;
  where?: Maybe<Visibility_Bool_Exp>;
};


export type Subscription_RootVisibility_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootWurrlyArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};


export type Subscription_RootWurrly_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Order_By>>;
  where?: Maybe<Wurrly_Bool_Exp>;
};


export type Subscription_RootWurrly_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


export type Subscription_RootWurrly_FilterArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Order_By>>;
  where?: Maybe<Wurrly_Filter_Bool_Exp>;
};


export type Subscription_RootWurrly_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Order_By>>;
  where?: Maybe<Wurrly_Filter_Bool_Exp>;
};


export type Subscription_RootWurrly_Filter_By_PkArgs = {
  wurrly_id: Scalars['Int'];
};


export type Subscription_RootWurrly_Filter_StickerArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Sticker_Order_By>>;
  where?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
};


export type Subscription_RootWurrly_Filter_Sticker_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Sticker_Order_By>>;
  where?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
};


export type Subscription_RootWurrly_Processing_StatusArgs = {
  distinct_on?: Maybe<Array<Wurrly_Processing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Processing_Status_Order_By>>;
  where?: Maybe<Wurrly_Processing_Status_Bool_Exp>;
};


export type Subscription_RootWurrly_Processing_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Processing_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Processing_Status_Order_By>>;
  where?: Maybe<Wurrly_Processing_Status_Bool_Exp>;
};


export type Subscription_RootWurrly_Processing_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootWurrly_ReactionArgs = {
  distinct_on?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Reaction_Order_By>>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};


export type Subscription_RootWurrly_Reaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Reaction_Order_By>>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};


export type Subscription_RootWurrly_Reaction_By_PkArgs = {
  wurrly_reaction_id: Scalars['Int'];
};


export type Subscription_RootWurrly_RoleArgs = {
  distinct_on?: Maybe<Array<Wurrly_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Role_Order_By>>;
  where?: Maybe<Wurrly_Role_Bool_Exp>;
};


export type Subscription_RootWurrly_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Role_Order_By>>;
  where?: Maybe<Wurrly_Role_Bool_Exp>;
};


export type Subscription_RootWurrly_Role_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootWurrly_TypeArgs = {
  distinct_on?: Maybe<Array<Wurrly_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Type_Order_By>>;
  where?: Maybe<Wurrly_Type_Bool_Exp>;
};


export type Subscription_RootWurrly_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Type_Order_By>>;
  where?: Maybe<Wurrly_Type_Bool_Exp>;
};


export type Subscription_RootWurrly_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type SyncPricesSet = {
  productId: Scalars['Int'];
  productName: Scalars['String'];
};

/** columns and relationships of "teacher" */
export type Teacher = {
  __typename?: 'teacher';
  address?: Maybe<Scalars['String']>;
  /** An array relationship */
  class_teachers: Array<Class_Teacher>;
  /** An aggregate relationship */
  class_teachers_aggregate: Class_Teacher_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** A computed field, executes function "teacher_full_name" */
  full_name?: Maybe<Scalars['String']>;
  google_address?: Maybe<Scalars['jsonb']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "teacher_is_district_enterprise" */
  is_district_enterprise?: Maybe<Scalars['Boolean']>;
  /** It is not a free user, and it has paid */
  is_enterprise: Scalars['Boolean'];
  /** A computed field, executes function "teacher_is_school_enterprise" */
  is_school_enterprise?: Maybe<Scalars['Boolean']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['numeric']>;
  role_name: Role_Name_Enum;
  /** An object relationship */
  school?: Maybe<School>;
  school_id?: Maybe<Scalars['Int']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  teacher_id: Scalars['Int'];
  /** An array relationship */
  teacher_integrations: Array<Teacher_Integration>;
  /** An aggregate relationship */
  teacher_integrations_aggregate: Teacher_Integration_Aggregate;
  /** An array relationship */
  teacher_lesson_plan_favorites: Array<Teacher_Lesson_Plan_Favorite>;
  /** An aggregate relationship */
  teacher_lesson_plan_favorites_aggregate: Teacher_Lesson_Plan_Favorite_Aggregate;
  /** An array relationship */
  teacher_lesson_plans: Array<Lesson_Plan>;
  /** An aggregate relationship */
  teacher_lesson_plans_aggregate: Lesson_Plan_Aggregate;
  /** An object relationship */
  teacher_setting?: Maybe<Teacher_Setting>;
  /** An array relationship */
  teacher_song_favorites: Array<Teacher_Song_Favorite>;
  /** An aggregate relationship */
  teacher_song_favorites_aggregate: Teacher_Song_Favorite_Aggregate;
  /** An array relationship */
  teacher_tip_favorites: Array<Teacher_Tip_Favorite>;
  /** An aggregate relationship */
  teacher_tip_favorites_aggregate: Teacher_Tip_Favorite_Aggregate;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
};


/** columns and relationships of "teacher" */
export type TeacherClass_TeachersArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherClass_Teachers_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Teacher_Order_By>>;
  where?: Maybe<Class_Teacher_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherGoogle_AddressArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_IntegrationsArgs = {
  distinct_on?: Maybe<Array<Teacher_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Integration_Order_By>>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Integrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Integration_Order_By>>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Lesson_Plan_FavoritesArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Lesson_Plan_Favorites_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Order_By>>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Lesson_PlansArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Order_By>>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Lesson_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Lesson_Plan_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lesson_Plan_Order_By>>;
  where?: Maybe<Lesson_Plan_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Song_FavoritesArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Song_Favorites_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Song_Favorite_Order_By>>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Tip_FavoritesArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


/** columns and relationships of "teacher" */
export type TeacherTeacher_Tip_Favorites_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};

/** aggregated selection of "teacher" */
export type Teacher_Aggregate = {
  __typename?: 'teacher_aggregate';
  aggregate?: Maybe<Teacher_Aggregate_Fields>;
  nodes: Array<Teacher>;
};

/** aggregate fields of "teacher" */
export type Teacher_Aggregate_Fields = {
  __typename?: 'teacher_aggregate_fields';
  avg?: Maybe<Teacher_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Max_Fields>;
  min?: Maybe<Teacher_Min_Fields>;
  stddev?: Maybe<Teacher_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Sum_Fields>;
  var_pop?: Maybe<Teacher_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Variance_Fields>;
};


/** aggregate fields of "teacher" */
export type Teacher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher" */
export type Teacher_Aggregate_Order_By = {
  avg?: Maybe<Teacher_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Max_Order_By>;
  min?: Maybe<Teacher_Min_Order_By>;
  stddev?: Maybe<Teacher_Stddev_Order_By>;
  stddev_pop?: Maybe<Teacher_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Teacher_Stddev_Samp_Order_By>;
  sum?: Maybe<Teacher_Sum_Order_By>;
  var_pop?: Maybe<Teacher_Var_Pop_Order_By>;
  var_samp?: Maybe<Teacher_Var_Samp_Order_By>;
  variance?: Maybe<Teacher_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Teacher_Append_Input = {
  google_address?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "teacher" */
export type Teacher_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_On_Conflict>;
};

/** aggregate avg on columns */
export type Teacher_Avg_Fields = {
  __typename?: 'teacher_avg_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "teacher" */
export type Teacher_Avg_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teacher". All fields are combined with a logical 'AND'. */
export type Teacher_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Bool_Exp>>;
  _not?: Maybe<Teacher_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  class_teachers?: Maybe<Class_Teacher_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  google_address?: Maybe<Jsonb_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  is_district_enterprise?: Maybe<Boolean_Comparison_Exp>;
  is_enterprise?: Maybe<Boolean_Comparison_Exp>;
  is_school_enterprise?: Maybe<Boolean_Comparison_Exp>;
  name_first?: Maybe<String_Comparison_Exp>;
  name_last?: Maybe<String_Comparison_Exp>;
  name_middle?: Maybe<String_Comparison_Exp>;
  organization_name?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<Numeric_Comparison_Exp>;
  role_name?: Maybe<Role_Name_Enum_Comparison_Exp>;
  school?: Maybe<School_Bool_Exp>;
  school_id?: Maybe<Int_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_integrations?: Maybe<Teacher_Integration_Bool_Exp>;
  teacher_lesson_plan_favorites?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
  teacher_lesson_plans?: Maybe<Lesson_Plan_Bool_Exp>;
  teacher_setting?: Maybe<Teacher_Setting_Bool_Exp>;
  teacher_song_favorites?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
  teacher_tip_favorites?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wurrly_role?: Maybe<Wurrly_Role_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher" */
export enum Teacher_Constraint {
  /** unique or primary key constraint */
  TeacherEmailKey = 'teacher_email_key',
  /** unique or primary key constraint */
  TeacherPkey = 'teacher_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Teacher_Delete_At_Path_Input = {
  google_address?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Teacher_Delete_Elem_Input = {
  google_address?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Teacher_Delete_Key_Input = {
  google_address?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "teacher" */
export type Teacher_Inc_Input = {
  phone?: Maybe<Scalars['numeric']>;
  school_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher" */
export type Teacher_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  class_teachers?: Maybe<Class_Teacher_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  google_address?: Maybe<Scalars['jsonb']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  /** It is not a free user, and it has paid */
  is_enterprise?: Maybe<Scalars['Boolean']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  role_name?: Maybe<Role_Name_Enum>;
  school?: Maybe<School_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars['Int']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integrations?: Maybe<Teacher_Integration_Arr_Rel_Insert_Input>;
  teacher_lesson_plan_favorites?: Maybe<Teacher_Lesson_Plan_Favorite_Arr_Rel_Insert_Input>;
  teacher_lesson_plans?: Maybe<Lesson_Plan_Arr_Rel_Insert_Input>;
  teacher_setting?: Maybe<Teacher_Setting_Obj_Rel_Insert_Input>;
  teacher_song_favorites?: Maybe<Teacher_Song_Favorite_Arr_Rel_Insert_Input>;
  teacher_tip_favorites?: Maybe<Teacher_Tip_Favorite_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
};

/** columns and relationships of "teacher_integration" */
export type Teacher_Integration = {
  __typename?: 'teacher_integration';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  integration_id: Scalars['String'];
  integration_name: Integration_Name_Enum;
  is_sso: Scalars['Boolean'];
  teacher_id: Scalars['Int'];
  teacher_integration_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "teacher_integration" */
export type Teacher_Integration_Aggregate = {
  __typename?: 'teacher_integration_aggregate';
  aggregate?: Maybe<Teacher_Integration_Aggregate_Fields>;
  nodes: Array<Teacher_Integration>;
};

/** aggregate fields of "teacher_integration" */
export type Teacher_Integration_Aggregate_Fields = {
  __typename?: 'teacher_integration_aggregate_fields';
  avg?: Maybe<Teacher_Integration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Integration_Max_Fields>;
  min?: Maybe<Teacher_Integration_Min_Fields>;
  stddev?: Maybe<Teacher_Integration_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Integration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Integration_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Integration_Sum_Fields>;
  var_pop?: Maybe<Teacher_Integration_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Integration_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Integration_Variance_Fields>;
};


/** aggregate fields of "teacher_integration" */
export type Teacher_Integration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Integration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_integration" */
export type Teacher_Integration_Aggregate_Order_By = {
  avg?: Maybe<Teacher_Integration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Integration_Max_Order_By>;
  min?: Maybe<Teacher_Integration_Min_Order_By>;
  stddev?: Maybe<Teacher_Integration_Stddev_Order_By>;
  stddev_pop?: Maybe<Teacher_Integration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Teacher_Integration_Stddev_Samp_Order_By>;
  sum?: Maybe<Teacher_Integration_Sum_Order_By>;
  var_pop?: Maybe<Teacher_Integration_Var_Pop_Order_By>;
  var_samp?: Maybe<Teacher_Integration_Var_Samp_Order_By>;
  variance?: Maybe<Teacher_Integration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_integration" */
export type Teacher_Integration_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Integration_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_Integration_On_Conflict>;
};

/** aggregate avg on columns */
export type Teacher_Integration_Avg_Fields = {
  __typename?: 'teacher_integration_avg_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "teacher_integration" */
export type Teacher_Integration_Avg_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teacher_integration". All fields are combined with a logical 'AND'. */
export type Teacher_Integration_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Integration_Bool_Exp>>;
  _not?: Maybe<Teacher_Integration_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Integration_Bool_Exp>>;
  classLink_tenant_id?: Maybe<Int_Comparison_Exp>;
  classLink_token?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  integration_id?: Maybe<String_Comparison_Exp>;
  integration_name?: Maybe<Integration_Name_Enum_Comparison_Exp>;
  is_sso?: Maybe<Boolean_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_integration_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_integration" */
export enum Teacher_Integration_Constraint {
  /** unique or primary key constraint */
  TeacherIntegrationPkey = 'teacher_integration_pkey',
  /** unique or primary key constraint */
  TeacherIntegrationTeacherIdIntegrationNameIntegrationId = 'teacher_integration_teacher_id_integration_name_integration_id_'
}

/** input type for incrementing numeric columns in table "teacher_integration" */
export type Teacher_Integration_Inc_Input = {
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_integration" */
export type Teacher_Integration_Insert_Input = {
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  is_sso?: Maybe<Scalars['Boolean']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teacher_Integration_Max_Fields = {
  __typename?: 'teacher_integration_max_fields';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teacher_integration" */
export type Teacher_Integration_Max_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  classLink_token?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Integration_Min_Fields = {
  __typename?: 'teacher_integration_min_fields';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teacher_integration" */
export type Teacher_Integration_Min_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  classLink_token?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_integration" */
export type Teacher_Integration_Mutation_Response = {
  __typename?: 'teacher_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Integration>;
};

/** on_conflict condition type for table "teacher_integration" */
export type Teacher_Integration_On_Conflict = {
  constraint: Teacher_Integration_Constraint;
  update_columns?: Array<Teacher_Integration_Update_Column>;
  where?: Maybe<Teacher_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_integration". */
export type Teacher_Integration_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  classLink_token?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  integration_id?: Maybe<Order_By>;
  integration_name?: Maybe<Order_By>;
  is_sso?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_integration */
export type Teacher_Integration_Pk_Columns_Input = {
  teacher_integration_id: Scalars['Int'];
};

/** select columns of table "teacher_integration" */
export enum Teacher_Integration_Select_Column {
  /** column name */
  ClassLinkTenantId = 'classLink_tenant_id',
  /** column name */
  ClassLinkToken = 'classLink_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsSso = 'is_sso',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherIntegrationId = 'teacher_integration_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teacher_integration" */
export type Teacher_Integration_Set_Input = {
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  classLink_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  integration_id?: Maybe<Scalars['String']>;
  integration_name?: Maybe<Integration_Name_Enum>;
  is_sso?: Maybe<Scalars['Boolean']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Teacher_Integration_Stddev_Fields = {
  __typename?: 'teacher_integration_stddev_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "teacher_integration" */
export type Teacher_Integration_Stddev_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Integration_Stddev_Pop_Fields = {
  __typename?: 'teacher_integration_stddev_pop_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "teacher_integration" */
export type Teacher_Integration_Stddev_Pop_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Integration_Stddev_Samp_Fields = {
  __typename?: 'teacher_integration_stddev_samp_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "teacher_integration" */
export type Teacher_Integration_Stddev_Samp_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Teacher_Integration_Sum_Fields = {
  __typename?: 'teacher_integration_sum_fields';
  classLink_tenant_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_integration_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "teacher_integration" */
export type Teacher_Integration_Sum_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** update columns of table "teacher_integration" */
export enum Teacher_Integration_Update_Column {
  /** column name */
  ClassLinkTenantId = 'classLink_tenant_id',
  /** column name */
  ClassLinkToken = 'classLink_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  IntegrationName = 'integration_name',
  /** column name */
  IsSso = 'is_sso',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherIntegrationId = 'teacher_integration_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Teacher_Integration_Var_Pop_Fields = {
  __typename?: 'teacher_integration_var_pop_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "teacher_integration" */
export type Teacher_Integration_Var_Pop_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teacher_Integration_Var_Samp_Fields = {
  __typename?: 'teacher_integration_var_samp_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "teacher_integration" */
export type Teacher_Integration_Var_Samp_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Teacher_Integration_Variance_Fields = {
  __typename?: 'teacher_integration_variance_fields';
  classLink_tenant_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_integration_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "teacher_integration" */
export type Teacher_Integration_Variance_Order_By = {
  classLink_tenant_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integration_id?: Maybe<Order_By>;
};

/** columns and relationships of "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite = {
  __typename?: 'teacher_lesson_plan_favorite';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lesson_plan: Lesson_Plan;
  lesson_plan_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
  teacher_lesson_plan_favorite_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Aggregate = {
  __typename?: 'teacher_lesson_plan_favorite_aggregate';
  aggregate?: Maybe<Teacher_Lesson_Plan_Favorite_Aggregate_Fields>;
  nodes: Array<Teacher_Lesson_Plan_Favorite>;
};

/** aggregate fields of "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Aggregate_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_aggregate_fields';
  avg?: Maybe<Teacher_Lesson_Plan_Favorite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Lesson_Plan_Favorite_Max_Fields>;
  min?: Maybe<Teacher_Lesson_Plan_Favorite_Min_Fields>;
  stddev?: Maybe<Teacher_Lesson_Plan_Favorite_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Lesson_Plan_Favorite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Lesson_Plan_Favorite_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Lesson_Plan_Favorite_Sum_Fields>;
  var_pop?: Maybe<Teacher_Lesson_Plan_Favorite_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Lesson_Plan_Favorite_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Lesson_Plan_Favorite_Variance_Fields>;
};


/** aggregate fields of "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Aggregate_Order_By = {
  avg?: Maybe<Teacher_Lesson_Plan_Favorite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Lesson_Plan_Favorite_Max_Order_By>;
  min?: Maybe<Teacher_Lesson_Plan_Favorite_Min_Order_By>;
  stddev?: Maybe<Teacher_Lesson_Plan_Favorite_Stddev_Order_By>;
  stddev_pop?: Maybe<Teacher_Lesson_Plan_Favorite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Teacher_Lesson_Plan_Favorite_Stddev_Samp_Order_By>;
  sum?: Maybe<Teacher_Lesson_Plan_Favorite_Sum_Order_By>;
  var_pop?: Maybe<Teacher_Lesson_Plan_Favorite_Var_Pop_Order_By>;
  var_samp?: Maybe<Teacher_Lesson_Plan_Favorite_Var_Samp_Order_By>;
  variance?: Maybe<Teacher_Lesson_Plan_Favorite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Lesson_Plan_Favorite_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_Lesson_Plan_Favorite_On_Conflict>;
};

/** aggregate avg on columns */
export type Teacher_Lesson_Plan_Favorite_Avg_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_avg_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Avg_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teacher_lesson_plan_favorite". All fields are combined with a logical 'AND'. */
export type Teacher_Lesson_Plan_Favorite_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Bool_Exp>>;
  _not?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Lesson_Plan_Favorite_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  lesson_plan?: Maybe<Lesson_Plan_Bool_Exp>;
  lesson_plan_id?: Maybe<Int_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_lesson_plan_favorite_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_lesson_plan_favorite" */
export enum Teacher_Lesson_Plan_Favorite_Constraint {
  /** unique or primary key constraint */
  TeacherLessonPlanFavoritePkey = 'teacher_lesson_plan_favorite_pkey',
  /** unique or primary key constraint */
  TeacherLessonPlanFavoriteTeacherIdLessonPlanIdKey = 'teacher_lesson_plan_favorite_teacher_id_lesson_plan_id_key'
}

/** input type for incrementing numeric columns in table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Inc_Input = {
  lesson_plan_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan?: Maybe<Lesson_Plan_Obj_Rel_Insert_Input>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teacher_Lesson_Plan_Favorite_Max_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Lesson_Plan_Favorite_Min_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Mutation_Response = {
  __typename?: 'teacher_lesson_plan_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Lesson_Plan_Favorite>;
};

/** on_conflict condition type for table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_On_Conflict = {
  constraint: Teacher_Lesson_Plan_Favorite_Constraint;
  update_columns?: Array<Teacher_Lesson_Plan_Favorite_Update_Column>;
  where?: Maybe<Teacher_Lesson_Plan_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_lesson_plan_favorite". */
export type Teacher_Lesson_Plan_Favorite_Order_By = {
  created_at?: Maybe<Order_By>;
  lesson_plan?: Maybe<Lesson_Plan_Order_By>;
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_lesson_plan_favorite */
export type Teacher_Lesson_Plan_Favorite_Pk_Columns_Input = {
  teacher_lesson_plan_favorite_id: Scalars['Int'];
};

/** select columns of table "teacher_lesson_plan_favorite" */
export enum Teacher_Lesson_Plan_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherLessonPlanFavoriteId = 'teacher_lesson_plan_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  lesson_plan_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Teacher_Lesson_Plan_Favorite_Stddev_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_stddev_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Stddev_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Lesson_Plan_Favorite_Stddev_Pop_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_stddev_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Stddev_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Lesson_Plan_Favorite_Stddev_Samp_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_stddev_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Stddev_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Teacher_Lesson_Plan_Favorite_Sum_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_sum_fields';
  lesson_plan_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Sum_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** update columns of table "teacher_lesson_plan_favorite" */
export enum Teacher_Lesson_Plan_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  LessonPlanId = 'lesson_plan_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherLessonPlanFavoriteId = 'teacher_lesson_plan_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Teacher_Lesson_Plan_Favorite_Var_Pop_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_var_pop_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Var_Pop_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teacher_Lesson_Plan_Favorite_Var_Samp_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_var_samp_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Var_Samp_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Teacher_Lesson_Plan_Favorite_Variance_Fields = {
  __typename?: 'teacher_lesson_plan_favorite_variance_fields';
  lesson_plan_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_lesson_plan_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "teacher_lesson_plan_favorite" */
export type Teacher_Lesson_Plan_Favorite_Variance_Order_By = {
  lesson_plan_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_lesson_plan_favorite_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Teacher_Max_Fields = {
  __typename?: 'teacher_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  school_id?: Maybe<Scalars['Int']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teacher" */
export type Teacher_Max_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  organization_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Min_Fields = {
  __typename?: 'teacher_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  school_id?: Maybe<Scalars['Int']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teacher" */
export type Teacher_Min_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  organization_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher" */
export type Teacher_Mutation_Response = {
  __typename?: 'teacher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher>;
};

/** input type for inserting object relation for remote table "teacher" */
export type Teacher_Obj_Rel_Insert_Input = {
  data: Teacher_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_On_Conflict>;
};

/** on_conflict condition type for table "teacher" */
export type Teacher_On_Conflict = {
  constraint: Teacher_Constraint;
  update_columns?: Array<Teacher_Update_Column>;
  where?: Maybe<Teacher_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher". */
export type Teacher_Order_By = {
  address?: Maybe<Order_By>;
  class_teachers_aggregate?: Maybe<Class_Teacher_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  google_address?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  is_district_enterprise?: Maybe<Order_By>;
  is_enterprise?: Maybe<Order_By>;
  is_school_enterprise?: Maybe<Order_By>;
  name_first?: Maybe<Order_By>;
  name_last?: Maybe<Order_By>;
  name_middle?: Maybe<Order_By>;
  organization_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  role_name?: Maybe<Order_By>;
  school?: Maybe<School_Order_By>;
  school_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_integrations_aggregate?: Maybe<Teacher_Integration_Aggregate_Order_By>;
  teacher_lesson_plan_favorites_aggregate?: Maybe<Teacher_Lesson_Plan_Favorite_Aggregate_Order_By>;
  teacher_lesson_plans_aggregate?: Maybe<Lesson_Plan_Aggregate_Order_By>;
  teacher_setting?: Maybe<Teacher_Setting_Order_By>;
  teacher_song_favorites_aggregate?: Maybe<Teacher_Song_Favorite_Aggregate_Order_By>;
  teacher_tip_favorites_aggregate?: Maybe<Teacher_Tip_Favorite_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_role?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher */
export type Teacher_Pk_Columns_Input = {
  teacher_id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Teacher_Prepend_Input = {
  google_address?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "teacher" */
export enum Teacher_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleAddress = 'google_address',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsEnterprise = 'is_enterprise',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyRole = 'wurrly_role'
}

/** input type for updating data in table "teacher" */
export type Teacher_Set_Input = {
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  google_address?: Maybe<Scalars['jsonb']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  /** It is not a free user, and it has paid */
  is_enterprise?: Maybe<Scalars['Boolean']>;
  name_first?: Maybe<Scalars['String']>;
  name_last?: Maybe<Scalars['String']>;
  name_middle?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['numeric']>;
  role_name?: Maybe<Role_Name_Enum>;
  school_id?: Maybe<Scalars['Int']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  teacher_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_role?: Maybe<Wurrly_Role_Enum>;
};

/** columns and relationships of "teacher_setting" */
export type Teacher_Setting = {
  __typename?: 'teacher_setting';
  dont_ask_again_record: Scalars['Boolean'];
  /** if true, deleting a favorite won't prop a popup to ask if this action is correct */
  favorites_dont_ask_again_to_delete: Scalars['Boolean'];
  /** If true, adding a single lesson to a class won't prompt a pop-up asking the user to add a single lesson to a class. */
  sequence_lesson_dont_ask_again_add_to_class: Scalars['Boolean'];
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id: Scalars['Int'];
};

/** aggregated selection of "teacher_setting" */
export type Teacher_Setting_Aggregate = {
  __typename?: 'teacher_setting_aggregate';
  aggregate?: Maybe<Teacher_Setting_Aggregate_Fields>;
  nodes: Array<Teacher_Setting>;
};

/** aggregate fields of "teacher_setting" */
export type Teacher_Setting_Aggregate_Fields = {
  __typename?: 'teacher_setting_aggregate_fields';
  avg?: Maybe<Teacher_Setting_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Setting_Max_Fields>;
  min?: Maybe<Teacher_Setting_Min_Fields>;
  stddev?: Maybe<Teacher_Setting_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Setting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Setting_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Setting_Sum_Fields>;
  var_pop?: Maybe<Teacher_Setting_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Setting_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Setting_Variance_Fields>;
};


/** aggregate fields of "teacher_setting" */
export type Teacher_Setting_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Setting_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Teacher_Setting_Avg_Fields = {
  __typename?: 'teacher_setting_avg_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "teacher_setting". All fields are combined with a logical 'AND'. */
export type Teacher_Setting_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Setting_Bool_Exp>>;
  _not?: Maybe<Teacher_Setting_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Setting_Bool_Exp>>;
  dont_ask_again_record?: Maybe<Boolean_Comparison_Exp>;
  favorites_dont_ask_again_to_delete?: Maybe<Boolean_Comparison_Exp>;
  sequence_lesson_dont_ask_again_add_to_class?: Maybe<Boolean_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_setting_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_setting" */
export enum Teacher_Setting_Constraint {
  /** unique or primary key constraint */
  SettingPkey = 'setting_pkey',
  /** unique or primary key constraint */
  TeacherSettingTeacherIdKey = 'teacher_setting_teacher_id_key'
}

/** input type for incrementing numeric columns in table "teacher_setting" */
export type Teacher_Setting_Inc_Input = {
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_setting" */
export type Teacher_Setting_Insert_Input = {
  dont_ask_again_record?: Maybe<Scalars['Boolean']>;
  /** if true, deleting a favorite won't prop a popup to ask if this action is correct */
  favorites_dont_ask_again_to_delete?: Maybe<Scalars['Boolean']>;
  /** If true, adding a single lesson to a class won't prompt a pop-up asking the user to add a single lesson to a class. */
  sequence_lesson_dont_ask_again_add_to_class?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Teacher_Setting_Max_Fields = {
  __typename?: 'teacher_setting_max_fields';
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Teacher_Setting_Min_Fields = {
  __typename?: 'teacher_setting_min_fields';
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "teacher_setting" */
export type Teacher_Setting_Mutation_Response = {
  __typename?: 'teacher_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Setting>;
};

/** input type for inserting object relation for remote table "teacher_setting" */
export type Teacher_Setting_Obj_Rel_Insert_Input = {
  data: Teacher_Setting_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_Setting_On_Conflict>;
};

/** on_conflict condition type for table "teacher_setting" */
export type Teacher_Setting_On_Conflict = {
  constraint: Teacher_Setting_Constraint;
  update_columns?: Array<Teacher_Setting_Update_Column>;
  where?: Maybe<Teacher_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_setting". */
export type Teacher_Setting_Order_By = {
  dont_ask_again_record?: Maybe<Order_By>;
  favorites_dont_ask_again_to_delete?: Maybe<Order_By>;
  sequence_lesson_dont_ask_again_add_to_class?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_setting_id?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_setting */
export type Teacher_Setting_Pk_Columns_Input = {
  teacher_setting_id: Scalars['Int'];
};

/** select columns of table "teacher_setting" */
export enum Teacher_Setting_Select_Column {
  /** column name */
  DontAskAgainRecord = 'dont_ask_again_record',
  /** column name */
  FavoritesDontAskAgainToDelete = 'favorites_dont_ask_again_to_delete',
  /** column name */
  SequenceLessonDontAskAgainAddToClass = 'sequence_lesson_dont_ask_again_add_to_class',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherSettingId = 'teacher_setting_id'
}

/** input type for updating data in table "teacher_setting" */
export type Teacher_Setting_Set_Input = {
  dont_ask_again_record?: Maybe<Scalars['Boolean']>;
  /** if true, deleting a favorite won't prop a popup to ask if this action is correct */
  favorites_dont_ask_again_to_delete?: Maybe<Scalars['Boolean']>;
  /** If true, adding a single lesson to a class won't prompt a pop-up asking the user to add a single lesson to a class. */
  sequence_lesson_dont_ask_again_add_to_class?: Maybe<Scalars['Boolean']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Teacher_Setting_Stddev_Fields = {
  __typename?: 'teacher_setting_stddev_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Setting_Stddev_Pop_Fields = {
  __typename?: 'teacher_setting_stddev_pop_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Setting_Stddev_Samp_Fields = {
  __typename?: 'teacher_setting_stddev_samp_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Teacher_Setting_Sum_Fields = {
  __typename?: 'teacher_setting_sum_fields';
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_setting_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "teacher_setting" */
export enum Teacher_Setting_Update_Column {
  /** column name */
  DontAskAgainRecord = 'dont_ask_again_record',
  /** column name */
  FavoritesDontAskAgainToDelete = 'favorites_dont_ask_again_to_delete',
  /** column name */
  SequenceLessonDontAskAgainAddToClass = 'sequence_lesson_dont_ask_again_add_to_class',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherSettingId = 'teacher_setting_id'
}

/** aggregate var_pop on columns */
export type Teacher_Setting_Var_Pop_Fields = {
  __typename?: 'teacher_setting_var_pop_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Teacher_Setting_Var_Samp_Fields = {
  __typename?: 'teacher_setting_var_samp_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Teacher_Setting_Variance_Fields = {
  __typename?: 'teacher_setting_variance_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_setting_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "teacher_song_favorite" */
export type Teacher_Song_Favorite = {
  __typename?: 'teacher_song_favorite';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
  teacher_song_favorite_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "teacher_song_favorite" */
export type Teacher_Song_Favorite_Aggregate = {
  __typename?: 'teacher_song_favorite_aggregate';
  aggregate?: Maybe<Teacher_Song_Favorite_Aggregate_Fields>;
  nodes: Array<Teacher_Song_Favorite>;
};

/** aggregate fields of "teacher_song_favorite" */
export type Teacher_Song_Favorite_Aggregate_Fields = {
  __typename?: 'teacher_song_favorite_aggregate_fields';
  avg?: Maybe<Teacher_Song_Favorite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Song_Favorite_Max_Fields>;
  min?: Maybe<Teacher_Song_Favorite_Min_Fields>;
  stddev?: Maybe<Teacher_Song_Favorite_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Song_Favorite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Song_Favorite_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Song_Favorite_Sum_Fields>;
  var_pop?: Maybe<Teacher_Song_Favorite_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Song_Favorite_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Song_Favorite_Variance_Fields>;
};


/** aggregate fields of "teacher_song_favorite" */
export type Teacher_Song_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Song_Favorite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Aggregate_Order_By = {
  avg?: Maybe<Teacher_Song_Favorite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Song_Favorite_Max_Order_By>;
  min?: Maybe<Teacher_Song_Favorite_Min_Order_By>;
  stddev?: Maybe<Teacher_Song_Favorite_Stddev_Order_By>;
  stddev_pop?: Maybe<Teacher_Song_Favorite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Teacher_Song_Favorite_Stddev_Samp_Order_By>;
  sum?: Maybe<Teacher_Song_Favorite_Sum_Order_By>;
  var_pop?: Maybe<Teacher_Song_Favorite_Var_Pop_Order_By>;
  var_samp?: Maybe<Teacher_Song_Favorite_Var_Samp_Order_By>;
  variance?: Maybe<Teacher_Song_Favorite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Song_Favorite_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_Song_Favorite_On_Conflict>;
};

/** aggregate avg on columns */
export type Teacher_Song_Favorite_Avg_Fields = {
  __typename?: 'teacher_song_favorite_avg_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Avg_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teacher_song_favorite". All fields are combined with a logical 'AND'. */
export type Teacher_Song_Favorite_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Song_Favorite_Bool_Exp>>;
  _not?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Song_Favorite_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_song_favorite_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_song_favorite" */
export enum Teacher_Song_Favorite_Constraint {
  /** unique or primary key constraint */
  TeacherSongFavoritePkey = 'teacher_song_favorite_pkey',
  /** unique or primary key constraint */
  TeacherSongFavoriteTeacherIdSongIdKey = 'teacher_song_favorite_teacher_id_song_id_key'
}

/** input type for incrementing numeric columns in table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Inc_Input = {
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorite_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teacher_Song_Favorite_Max_Fields = {
  __typename?: 'teacher_song_favorite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Song_Favorite_Min_Fields = {
  __typename?: 'teacher_song_favorite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Mutation_Response = {
  __typename?: 'teacher_song_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Song_Favorite>;
};

/** on_conflict condition type for table "teacher_song_favorite" */
export type Teacher_Song_Favorite_On_Conflict = {
  constraint: Teacher_Song_Favorite_Constraint;
  update_columns?: Array<Teacher_Song_Favorite_Update_Column>;
  where?: Maybe<Teacher_Song_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_song_favorite". */
export type Teacher_Song_Favorite_Order_By = {
  created_at?: Maybe<Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_song_favorite */
export type Teacher_Song_Favorite_Pk_Columns_Input = {
  teacher_song_favorite_id: Scalars['Int'];
};

/** select columns of table "teacher_song_favorite" */
export enum Teacher_Song_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherSongFavoriteId = 'teacher_song_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorite_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Teacher_Song_Favorite_Stddev_Fields = {
  __typename?: 'teacher_song_favorite_stddev_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Stddev_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Song_Favorite_Stddev_Pop_Fields = {
  __typename?: 'teacher_song_favorite_stddev_pop_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Stddev_Pop_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Song_Favorite_Stddev_Samp_Fields = {
  __typename?: 'teacher_song_favorite_stddev_samp_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Stddev_Samp_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Teacher_Song_Favorite_Sum_Fields = {
  __typename?: 'teacher_song_favorite_sum_fields';
  song_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_song_favorite_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Sum_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** update columns of table "teacher_song_favorite" */
export enum Teacher_Song_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SongId = 'song_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherSongFavoriteId = 'teacher_song_favorite_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Teacher_Song_Favorite_Var_Pop_Fields = {
  __typename?: 'teacher_song_favorite_var_pop_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Var_Pop_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teacher_Song_Favorite_Var_Samp_Fields = {
  __typename?: 'teacher_song_favorite_var_samp_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Var_Samp_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Teacher_Song_Favorite_Variance_Fields = {
  __typename?: 'teacher_song_favorite_variance_fields';
  song_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_song_favorite_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "teacher_song_favorite" */
export type Teacher_Song_Favorite_Variance_Order_By = {
  song_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_song_favorite_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Teacher_Stddev_Fields = {
  __typename?: 'teacher_stddev_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "teacher" */
export type Teacher_Stddev_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Stddev_Pop_Fields = {
  __typename?: 'teacher_stddev_pop_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "teacher" */
export type Teacher_Stddev_Pop_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Stddev_Samp_Fields = {
  __typename?: 'teacher_stddev_samp_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "teacher" */
export type Teacher_Stddev_Samp_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** columns and relationships of "teacher_subscription" */
export type Teacher_Subscription = {
  __typename?: 'teacher_subscription';
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  membership_type: Membership_Type;
  membership_type_id: Scalars['Int'];
  subscription_id: Scalars['Int'];
  teacher_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "teacher_subscription" */
export type Teacher_Subscription_Aggregate = {
  __typename?: 'teacher_subscription_aggregate';
  aggregate?: Maybe<Teacher_Subscription_Aggregate_Fields>;
  nodes: Array<Teacher_Subscription>;
};

/** aggregate fields of "teacher_subscription" */
export type Teacher_Subscription_Aggregate_Fields = {
  __typename?: 'teacher_subscription_aggregate_fields';
  avg?: Maybe<Teacher_Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Subscription_Max_Fields>;
  min?: Maybe<Teacher_Subscription_Min_Fields>;
  stddev?: Maybe<Teacher_Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Subscription_Sum_Fields>;
  var_pop?: Maybe<Teacher_Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Subscription_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Subscription_Variance_Fields>;
};


/** aggregate fields of "teacher_subscription" */
export type Teacher_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Subscription_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Teacher_Subscription_Avg_Fields = {
  __typename?: 'teacher_subscription_avg_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "teacher_subscription". All fields are combined with a logical 'AND'. */
export type Teacher_Subscription_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Subscription_Bool_Exp>>;
  _not?: Maybe<Teacher_Subscription_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Subscription_Bool_Exp>>;
  cancelled_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  membership_type?: Maybe<Membership_Type_Bool_Exp>;
  membership_type_id?: Maybe<Int_Comparison_Exp>;
  subscription_id?: Maybe<Int_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_subscription" */
export enum Teacher_Subscription_Constraint {
  /** unique or primary key constraint */
  SubscriptionPkey = 'subscription_pkey'
}

/** input type for incrementing numeric columns in table "teacher_subscription" */
export type Teacher_Subscription_Inc_Input = {
  membership_type_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_subscription" */
export type Teacher_Subscription_Insert_Input = {
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  membership_type?: Maybe<Membership_Type_Obj_Rel_Insert_Input>;
  membership_type_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teacher_Subscription_Max_Fields = {
  __typename?: 'teacher_subscription_max_fields';
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Teacher_Subscription_Min_Fields = {
  __typename?: 'teacher_subscription_min_fields';
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "teacher_subscription" */
export type Teacher_Subscription_Mutation_Response = {
  __typename?: 'teacher_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Subscription>;
};

/** on_conflict condition type for table "teacher_subscription" */
export type Teacher_Subscription_On_Conflict = {
  constraint: Teacher_Subscription_Constraint;
  update_columns?: Array<Teacher_Subscription_Update_Column>;
  where?: Maybe<Teacher_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_subscription". */
export type Teacher_Subscription_Order_By = {
  cancelled_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  membership_type?: Maybe<Membership_Type_Order_By>;
  membership_type_id?: Maybe<Order_By>;
  subscription_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_subscription */
export type Teacher_Subscription_Pk_Columns_Input = {
  subscription_id: Scalars['Int'];
};

/** select columns of table "teacher_subscription" */
export enum Teacher_Subscription_Select_Column {
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MembershipTypeId = 'membership_type_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teacher_subscription" */
export type Teacher_Subscription_Set_Input = {
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  membership_type_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Teacher_Subscription_Stddev_Fields = {
  __typename?: 'teacher_subscription_stddev_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Subscription_Stddev_Pop_Fields = {
  __typename?: 'teacher_subscription_stddev_pop_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Subscription_Stddev_Samp_Fields = {
  __typename?: 'teacher_subscription_stddev_samp_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Teacher_Subscription_Sum_Fields = {
  __typename?: 'teacher_subscription_sum_fields';
  membership_type_id?: Maybe<Scalars['Int']>;
  subscription_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "teacher_subscription" */
export enum Teacher_Subscription_Update_Column {
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MembershipTypeId = 'membership_type_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Teacher_Subscription_Var_Pop_Fields = {
  __typename?: 'teacher_subscription_var_pop_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Teacher_Subscription_Var_Samp_Fields = {
  __typename?: 'teacher_subscription_var_samp_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Teacher_Subscription_Variance_Fields = {
  __typename?: 'teacher_subscription_variance_fields';
  membership_type_id?: Maybe<Scalars['Float']>;
  subscription_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Teacher_Sum_Fields = {
  __typename?: 'teacher_sum_fields';
  phone?: Maybe<Scalars['numeric']>;
  school_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "teacher" */
export type Teacher_Sum_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** columns and relationships of "teacher_tip_favorite" */
export type Teacher_Tip_Favorite = {
  __typename?: 'teacher_tip_favorite';
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id: Scalars['Int'];
  teacher_tip_favorite_id: Scalars['Int'];
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Aggregate = {
  __typename?: 'teacher_tip_favorite_aggregate';
  aggregate?: Maybe<Teacher_Tip_Favorite_Aggregate_Fields>;
  nodes: Array<Teacher_Tip_Favorite>;
};

/** aggregate fields of "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Aggregate_Fields = {
  __typename?: 'teacher_tip_favorite_aggregate_fields';
  avg?: Maybe<Teacher_Tip_Favorite_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Tip_Favorite_Max_Fields>;
  min?: Maybe<Teacher_Tip_Favorite_Min_Fields>;
  stddev?: Maybe<Teacher_Tip_Favorite_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Tip_Favorite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Tip_Favorite_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Tip_Favorite_Sum_Fields>;
  var_pop?: Maybe<Teacher_Tip_Favorite_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Tip_Favorite_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Tip_Favorite_Variance_Fields>;
};


/** aggregate fields of "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Aggregate_Order_By = {
  avg?: Maybe<Teacher_Tip_Favorite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Tip_Favorite_Max_Order_By>;
  min?: Maybe<Teacher_Tip_Favorite_Min_Order_By>;
  stddev?: Maybe<Teacher_Tip_Favorite_Stddev_Order_By>;
  stddev_pop?: Maybe<Teacher_Tip_Favorite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Teacher_Tip_Favorite_Stddev_Samp_Order_By>;
  sum?: Maybe<Teacher_Tip_Favorite_Sum_Order_By>;
  var_pop?: Maybe<Teacher_Tip_Favorite_Var_Pop_Order_By>;
  var_samp?: Maybe<Teacher_Tip_Favorite_Var_Samp_Order_By>;
  variance?: Maybe<Teacher_Tip_Favorite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Tip_Favorite_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_Tip_Favorite_On_Conflict>;
};

/** aggregate avg on columns */
export type Teacher_Tip_Favorite_Avg_Fields = {
  __typename?: 'teacher_tip_favorite_avg_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Avg_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teacher_tip_favorite". All fields are combined with a logical 'AND'. */
export type Teacher_Tip_Favorite_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Tip_Favorite_Bool_Exp>>;
  _not?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Tip_Favorite_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_tip_favorite_id?: Maybe<Int_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_tip_favorite" */
export enum Teacher_Tip_Favorite_Constraint {
  /** unique or primary key constraint */
  TeacherTipFavoritePkey = 'teacher_tip_favorite_pkey',
  /** unique or primary key constraint */
  TeacherTipFavoriteTipIdTeacherIdKey = 'teacher_tip_favorite_tip_id_teacher_id_key'
}

/** input type for incrementing numeric columns in table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Inc_Input = {
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teacher_Tip_Favorite_Max_Fields = {
  __typename?: 'teacher_tip_favorite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Tip_Favorite_Min_Fields = {
  __typename?: 'teacher_tip_favorite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Mutation_Response = {
  __typename?: 'teacher_tip_favorite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Tip_Favorite>;
};

/** on_conflict condition type for table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_On_Conflict = {
  constraint: Teacher_Tip_Favorite_Constraint;
  update_columns?: Array<Teacher_Tip_Favorite_Update_Column>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_tip_favorite". */
export type Teacher_Tip_Favorite_Order_By = {
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_tip_favorite */
export type Teacher_Tip_Favorite_Pk_Columns_Input = {
  teacher_tip_favorite_id: Scalars['Int'];
};

/** select columns of table "teacher_tip_favorite" */
export enum Teacher_Tip_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherTipFavoriteId = 'teacher_tip_favorite_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Teacher_Tip_Favorite_Stddev_Fields = {
  __typename?: 'teacher_tip_favorite_stddev_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Stddev_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Tip_Favorite_Stddev_Pop_Fields = {
  __typename?: 'teacher_tip_favorite_stddev_pop_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Stddev_Pop_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Tip_Favorite_Stddev_Samp_Fields = {
  __typename?: 'teacher_tip_favorite_stddev_samp_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Stddev_Samp_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Teacher_Tip_Favorite_Sum_Fields = {
  __typename?: 'teacher_tip_favorite_sum_fields';
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Sum_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "teacher_tip_favorite" */
export enum Teacher_Tip_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherTipFavoriteId = 'teacher_tip_favorite_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Teacher_Tip_Favorite_Var_Pop_Fields = {
  __typename?: 'teacher_tip_favorite_var_pop_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Var_Pop_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teacher_Tip_Favorite_Var_Samp_Fields = {
  __typename?: 'teacher_tip_favorite_var_samp_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Var_Samp_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Teacher_Tip_Favorite_Variance_Fields = {
  __typename?: 'teacher_tip_favorite_variance_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_tip_favorite_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "teacher_tip_favorite" */
export type Teacher_Tip_Favorite_Variance_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorite_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "teacher" */
export enum Teacher_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  GoogleAddress = 'google_address',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsEnterprise = 'is_enterprise',
  /** column name */
  NameFirst = 'name_first',
  /** column name */
  NameLast = 'name_last',
  /** column name */
  NameMiddle = 'name_middle',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  SchoolId = 'school_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyRole = 'wurrly_role'
}

/** aggregate var_pop on columns */
export type Teacher_Var_Pop_Fields = {
  __typename?: 'teacher_var_pop_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "teacher" */
export type Teacher_Var_Pop_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teacher_Var_Samp_Fields = {
  __typename?: 'teacher_var_samp_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "teacher" */
export type Teacher_Var_Samp_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Teacher_Variance_Fields = {
  __typename?: 'teacher_variance_fields';
  phone?: Maybe<Scalars['Float']>;
  school_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "teacher" */
export type Teacher_Variance_Order_By = {
  phone?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
};

/** columns and relationships of "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment = {
  __typename?: 'teacher_wurrly_comment';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  teacher: Teacher;
  teacher_id: Scalars['Int'];
  teacher_wurrly_comment_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  wurrly: Wurrly;
  wurrly_id: Scalars['Int'];
};

/** aggregated selection of "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Aggregate = {
  __typename?: 'teacher_wurrly_comment_aggregate';
  aggregate?: Maybe<Teacher_Wurrly_Comment_Aggregate_Fields>;
  nodes: Array<Teacher_Wurrly_Comment>;
};

/** aggregate fields of "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Aggregate_Fields = {
  __typename?: 'teacher_wurrly_comment_aggregate_fields';
  avg?: Maybe<Teacher_Wurrly_Comment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Wurrly_Comment_Max_Fields>;
  min?: Maybe<Teacher_Wurrly_Comment_Min_Fields>;
  stddev?: Maybe<Teacher_Wurrly_Comment_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Wurrly_Comment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Wurrly_Comment_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Wurrly_Comment_Sum_Fields>;
  var_pop?: Maybe<Teacher_Wurrly_Comment_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Wurrly_Comment_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Wurrly_Comment_Variance_Fields>;
};


/** aggregate fields of "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Aggregate_Order_By = {
  avg?: Maybe<Teacher_Wurrly_Comment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Teacher_Wurrly_Comment_Max_Order_By>;
  min?: Maybe<Teacher_Wurrly_Comment_Min_Order_By>;
  stddev?: Maybe<Teacher_Wurrly_Comment_Stddev_Order_By>;
  stddev_pop?: Maybe<Teacher_Wurrly_Comment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Teacher_Wurrly_Comment_Stddev_Samp_Order_By>;
  sum?: Maybe<Teacher_Wurrly_Comment_Sum_Order_By>;
  var_pop?: Maybe<Teacher_Wurrly_Comment_Var_Pop_Order_By>;
  var_samp?: Maybe<Teacher_Wurrly_Comment_Var_Samp_Order_By>;
  variance?: Maybe<Teacher_Wurrly_Comment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Arr_Rel_Insert_Input = {
  data: Array<Teacher_Wurrly_Comment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Teacher_Wurrly_Comment_On_Conflict>;
};

/** aggregate avg on columns */
export type Teacher_Wurrly_Comment_Avg_Fields = {
  __typename?: 'teacher_wurrly_comment_avg_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Avg_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teacher_wurrly_comment". All fields are combined with a logical 'AND'. */
export type Teacher_Wurrly_Comment_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Wurrly_Comment_Bool_Exp>>;
  _not?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Wurrly_Comment_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_wurrly_comment_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wurrly?: Maybe<Wurrly_Bool_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_wurrly_comment" */
export enum Teacher_Wurrly_Comment_Constraint {
  /** unique or primary key constraint */
  TeacherWurrlyCommentPkey = 'teacher_wurrly_comment_pkey'
}

/** input type for incrementing numeric columns in table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Inc_Input = {
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly?: Maybe<Wurrly_Obj_Rel_Insert_Input>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Teacher_Wurrly_Comment_Max_Fields = {
  __typename?: 'teacher_wurrly_comment_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Max_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Teacher_Wurrly_Comment_Min_Fields = {
  __typename?: 'teacher_wurrly_comment_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Min_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Mutation_Response = {
  __typename?: 'teacher_wurrly_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Wurrly_Comment>;
};

/** on_conflict condition type for table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_On_Conflict = {
  constraint: Teacher_Wurrly_Comment_Constraint;
  update_columns?: Array<Teacher_Wurrly_Comment_Update_Column>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_wurrly_comment". */
export type Teacher_Wurrly_Comment_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly?: Maybe<Wurrly_Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_wurrly_comment */
export type Teacher_Wurrly_Comment_Pk_Columns_Input = {
  teacher_wurrly_comment_id: Scalars['Int'];
};

/** select columns of table "teacher_wurrly_comment" */
export enum Teacher_Wurrly_Comment_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherWurrlyCommentId = 'teacher_wurrly_comment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** input type for updating data in table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Teacher_Wurrly_Comment_Stddev_Fields = {
  __typename?: 'teacher_wurrly_comment_stddev_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Stddev_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Wurrly_Comment_Stddev_Pop_Fields = {
  __typename?: 'teacher_wurrly_comment_stddev_pop_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Stddev_Pop_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Wurrly_Comment_Stddev_Samp_Fields = {
  __typename?: 'teacher_wurrly_comment_stddev_samp_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Stddev_Samp_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Teacher_Wurrly_Comment_Sum_Fields = {
  __typename?: 'teacher_wurrly_comment_sum_fields';
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Sum_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** update columns of table "teacher_wurrly_comment" */
export enum Teacher_Wurrly_Comment_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TeacherWurrlyCommentId = 'teacher_wurrly_comment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** aggregate var_pop on columns */
export type Teacher_Wurrly_Comment_Var_Pop_Fields = {
  __typename?: 'teacher_wurrly_comment_var_pop_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Var_Pop_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teacher_Wurrly_Comment_Var_Samp_Fields = {
  __typename?: 'teacher_wurrly_comment_var_samp_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Var_Samp_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Teacher_Wurrly_Comment_Variance_Fields = {
  __typename?: 'teacher_wurrly_comment_variance_fields';
  teacher_id?: Maybe<Scalars['Float']>;
  teacher_wurrly_comment_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "teacher_wurrly_comment" */
export type Teacher_Wurrly_Comment_Variance_Order_By = {
  teacher_id?: Maybe<Order_By>;
  teacher_wurrly_comment_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timetz']>;
  _gt?: Maybe<Scalars['timetz']>;
  _gte?: Maybe<Scalars['timetz']>;
  _in?: Maybe<Array<Scalars['timetz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timetz']>;
  _lte?: Maybe<Scalars['timetz']>;
  _neq?: Maybe<Scalars['timetz']>;
  _nin?: Maybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "tip" */
export type Tip = {
  __typename?: 'tip';
  active: Scalars['Boolean'];
  /** An object relationship */
  artist?: Maybe<Artist>;
  artist_id?: Maybe<Scalars['Int']>;
  asset_path?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  class_tips: Array<Class_Tip>;
  /** An aggregate relationship */
  class_tips_aggregate: Class_Tip_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured: Scalars['Boolean'];
  image_path: Scalars['String'];
  is_favorite?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "is_tip_purchased_by_teacher" */
  is_purchased_by_teacher?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "student_liked_video" */
  is_student_favorite?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "teacher_liked_video" */
  is_teacher_favorite?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  lockable_content?: Maybe<Lockable_Content>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path: Scalars['String'];
  /** An object relationship */
  skill_level?: Maybe<Skill_Level>;
  skill_level_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  teacher_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  teacher_tip_favorites: Array<Teacher_Tip_Favorite>;
  /** An aggregate relationship */
  teacher_tip_favorites_aggregate: Teacher_Tip_Favorite_Aggregate;
  /** An array relationship */
  tip_catalog_item: Array<Tip_Catalog_Item>;
  /** An aggregate relationship */
  tip_catalog_item_aggregate: Tip_Catalog_Item_Aggregate;
  tip_id: Scalars['Int'];
  /** An array relationship */
  tip_keywords: Array<Tip_Keyword>;
  /** An aggregate relationship */
  tip_keywords_aggregate: Tip_Keyword_Aggregate;
  /** An array relationship */
  tip_playlists: Array<Tip_Playlist>;
  /** An aggregate relationship */
  tip_playlists_aggregate: Tip_Playlist_Aggregate;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tip" */
export type TipClass_TipsArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipClass_Tips_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Tip_Order_By>>;
  where?: Maybe<Class_Tip_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipIs_FavoriteArgs = {
  args: Is_Favorite_Tip_Args;
};


/** columns and relationships of "tip" */
export type TipIs_Student_FavoriteArgs = {
  args: Is_Student_Favorite_Tip_Args;
};


/** columns and relationships of "tip" */
export type TipIs_Teacher_FavoriteArgs = {
  args: Is_Teacher_Favorite_Tip_Args;
};


/** columns and relationships of "tip" */
export type TipTeacher_Tip_FavoritesArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTeacher_Tip_Favorites_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Tip_Favorite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Tip_Favorite_Order_By>>;
  where?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTip_Catalog_ItemArgs = {
  distinct_on?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Catalog_Item_Order_By>>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTip_Catalog_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Catalog_Item_Order_By>>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTip_KeywordsArgs = {
  distinct_on?: Maybe<Array<Tip_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Keyword_Order_By>>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTip_Keywords_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Keyword_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Keyword_Order_By>>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTip_PlaylistsArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipTip_Playlists_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Playlist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Playlist_Order_By>>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};

export type TipT = {
  __typename?: 'tipT';
  image_path?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "tip_age" */
export type Tip_Age = {
  __typename?: 'tip_age';
  ages_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_age_id: Scalars['Int'];
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_age" */
export type Tip_Age_Aggregate = {
  __typename?: 'tip_age_aggregate';
  aggregate?: Maybe<Tip_Age_Aggregate_Fields>;
  nodes: Array<Tip_Age>;
};

/** aggregate fields of "tip_age" */
export type Tip_Age_Aggregate_Fields = {
  __typename?: 'tip_age_aggregate_fields';
  avg?: Maybe<Tip_Age_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Age_Max_Fields>;
  min?: Maybe<Tip_Age_Min_Fields>;
  stddev?: Maybe<Tip_Age_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Age_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Age_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Age_Sum_Fields>;
  var_pop?: Maybe<Tip_Age_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Age_Var_Samp_Fields>;
  variance?: Maybe<Tip_Age_Variance_Fields>;
};


/** aggregate fields of "tip_age" */
export type Tip_Age_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Age_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tip_Age_Avg_Fields = {
  __typename?: 'tip_age_avg_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tip_age". All fields are combined with a logical 'AND'. */
export type Tip_Age_Bool_Exp = {
  _and?: Maybe<Array<Tip_Age_Bool_Exp>>;
  _not?: Maybe<Tip_Age_Bool_Exp>;
  _or?: Maybe<Array<Tip_Age_Bool_Exp>>;
  ages_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  tip_age_id?: Maybe<Int_Comparison_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_age" */
export enum Tip_Age_Constraint {
  /** unique or primary key constraint */
  TipAgesPkey = 'tip_ages_pkey',
  /** unique or primary key constraint */
  TipAgesTipIdAgesIdKey = 'tip_ages_tip_id_ages_id_key'
}

/** input type for incrementing numeric columns in table "tip_age" */
export type Tip_Age_Inc_Input = {
  ages_id?: Maybe<Scalars['Int']>;
  tip_age_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_age" */
export type Tip_Age_Insert_Input = {
  ages_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_age_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Age_Max_Fields = {
  __typename?: 'tip_age_max_fields';
  ages_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_age_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tip_Age_Min_Fields = {
  __typename?: 'tip_age_min_fields';
  ages_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_age_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tip_age" */
export type Tip_Age_Mutation_Response = {
  __typename?: 'tip_age_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Age>;
};

/** on_conflict condition type for table "tip_age" */
export type Tip_Age_On_Conflict = {
  constraint: Tip_Age_Constraint;
  update_columns?: Array<Tip_Age_Update_Column>;
  where?: Maybe<Tip_Age_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_age". */
export type Tip_Age_Order_By = {
  ages_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_age_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_age */
export type Tip_Age_Pk_Columns_Input = {
  tip_age_id: Scalars['Int'];
};

/** select columns of table "tip_age" */
export enum Tip_Age_Select_Column {
  /** column name */
  AgesId = 'ages_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipAgeId = 'tip_age_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_age" */
export type Tip_Age_Set_Input = {
  ages_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_age_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Age_Stddev_Fields = {
  __typename?: 'tip_age_stddev_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tip_Age_Stddev_Pop_Fields = {
  __typename?: 'tip_age_stddev_pop_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tip_Age_Stddev_Samp_Fields = {
  __typename?: 'tip_age_stddev_samp_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tip_Age_Sum_Fields = {
  __typename?: 'tip_age_sum_fields';
  ages_id?: Maybe<Scalars['Int']>;
  tip_age_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tip_age" */
export enum Tip_Age_Update_Column {
  /** column name */
  AgesId = 'ages_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipAgeId = 'tip_age_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Age_Var_Pop_Fields = {
  __typename?: 'tip_age_var_pop_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tip_Age_Var_Samp_Fields = {
  __typename?: 'tip_age_var_samp_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tip_Age_Variance_Fields = {
  __typename?: 'tip_age_variance_fields';
  ages_id?: Maybe<Scalars['Float']>;
  tip_age_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "tip" */
export type Tip_Aggregate = {
  __typename?: 'tip_aggregate';
  aggregate?: Maybe<Tip_Aggregate_Fields>;
  nodes: Array<Tip>;
};

/** aggregate fields of "tip" */
export type Tip_Aggregate_Fields = {
  __typename?: 'tip_aggregate_fields';
  avg?: Maybe<Tip_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Max_Fields>;
  min?: Maybe<Tip_Min_Fields>;
  stddev?: Maybe<Tip_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Sum_Fields>;
  var_pop?: Maybe<Tip_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Var_Samp_Fields>;
  variance?: Maybe<Tip_Variance_Fields>;
};


/** aggregate fields of "tip" */
export type Tip_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tip_Avg_Fields = {
  __typename?: 'tip_avg_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tip". All fields are combined with a logical 'AND'. */
export type Tip_Bool_Exp = {
  _and?: Maybe<Array<Tip_Bool_Exp>>;
  _not?: Maybe<Tip_Bool_Exp>;
  _or?: Maybe<Array<Tip_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  artist?: Maybe<Artist_Bool_Exp>;
  artist_id?: Maybe<Int_Comparison_Exp>;
  asset_path?: Maybe<String_Comparison_Exp>;
  available_id?: Maybe<Int_Comparison_Exp>;
  class_tips?: Maybe<Class_Tip_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  featured?: Maybe<Boolean_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_purchased_by_teacher?: Maybe<Boolean_Comparison_Exp>;
  lockable_content?: Maybe<Lockable_Content_Bool_Exp>;
  lockable_content_id?: Maybe<Int_Comparison_Exp>;
  resource_path?: Maybe<String_Comparison_Exp>;
  skill_level?: Maybe<Skill_Level_Bool_Exp>;
  skill_level_id?: Maybe<Int_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Int_Comparison_Exp>;
  teacher_tip_favorites?: Maybe<Teacher_Tip_Favorite_Bool_Exp>;
  tip_catalog_item?: Maybe<Tip_Catalog_Item_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  tip_keywords?: Maybe<Tip_Keyword_Bool_Exp>;
  tip_playlists?: Maybe<Tip_Playlist_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "tip_catalog_item" */
export type Tip_Catalog_Item = {
  __typename?: 'tip_catalog_item';
  /** An object relationship */
  catalog_item: Catalog_Item;
  catalog_item_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  tip: Tip;
  tip_catalog_item_id: Scalars['Int'];
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_catalog_item" */
export type Tip_Catalog_Item_Aggregate = {
  __typename?: 'tip_catalog_item_aggregate';
  aggregate?: Maybe<Tip_Catalog_Item_Aggregate_Fields>;
  nodes: Array<Tip_Catalog_Item>;
};

/** aggregate fields of "tip_catalog_item" */
export type Tip_Catalog_Item_Aggregate_Fields = {
  __typename?: 'tip_catalog_item_aggregate_fields';
  avg?: Maybe<Tip_Catalog_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Catalog_Item_Max_Fields>;
  min?: Maybe<Tip_Catalog_Item_Min_Fields>;
  stddev?: Maybe<Tip_Catalog_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Catalog_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Catalog_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Catalog_Item_Sum_Fields>;
  var_pop?: Maybe<Tip_Catalog_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Catalog_Item_Var_Samp_Fields>;
  variance?: Maybe<Tip_Catalog_Item_Variance_Fields>;
};


/** aggregate fields of "tip_catalog_item" */
export type Tip_Catalog_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Catalog_Item_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tip_catalog_item" */
export type Tip_Catalog_Item_Aggregate_Order_By = {
  avg?: Maybe<Tip_Catalog_Item_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tip_Catalog_Item_Max_Order_By>;
  min?: Maybe<Tip_Catalog_Item_Min_Order_By>;
  stddev?: Maybe<Tip_Catalog_Item_Stddev_Order_By>;
  stddev_pop?: Maybe<Tip_Catalog_Item_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tip_Catalog_Item_Stddev_Samp_Order_By>;
  sum?: Maybe<Tip_Catalog_Item_Sum_Order_By>;
  var_pop?: Maybe<Tip_Catalog_Item_Var_Pop_Order_By>;
  var_samp?: Maybe<Tip_Catalog_Item_Var_Samp_Order_By>;
  variance?: Maybe<Tip_Catalog_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tip_catalog_item" */
export type Tip_Catalog_Item_Arr_Rel_Insert_Input = {
  data: Array<Tip_Catalog_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tip_Catalog_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Tip_Catalog_Item_Avg_Fields = {
  __typename?: 'tip_catalog_item_avg_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Avg_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tip_catalog_item". All fields are combined with a logical 'AND'. */
export type Tip_Catalog_Item_Bool_Exp = {
  _and?: Maybe<Array<Tip_Catalog_Item_Bool_Exp>>;
  _not?: Maybe<Tip_Catalog_Item_Bool_Exp>;
  _or?: Maybe<Array<Tip_Catalog_Item_Bool_Exp>>;
  catalog_item?: Maybe<Catalog_Item_Bool_Exp>;
  catalog_item_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_catalog_item_id?: Maybe<Int_Comparison_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_catalog_item" */
export enum Tip_Catalog_Item_Constraint {
  /** unique or primary key constraint */
  TipCatalogItemPkey = 'tip_catalog_item_pkey',
  /** unique or primary key constraint */
  TipCatalogItemTipIdCatalogItemIdKey = 'tip_catalog_item_tip_id_catalog_item_id_key'
}

/** input type for incrementing numeric columns in table "tip_catalog_item" */
export type Tip_Catalog_Item_Inc_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  tip_catalog_item_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_catalog_item" */
export type Tip_Catalog_Item_Insert_Input = {
  catalog_item?: Maybe<Catalog_Item_Obj_Rel_Insert_Input>;
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_catalog_item_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Catalog_Item_Max_Fields = {
  __typename?: 'tip_catalog_item_max_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_catalog_item_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Max_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tip_Catalog_Item_Min_Fields = {
  __typename?: 'tip_catalog_item_min_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_catalog_item_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Min_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tip_catalog_item" */
export type Tip_Catalog_Item_Mutation_Response = {
  __typename?: 'tip_catalog_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Catalog_Item>;
};

/** on_conflict condition type for table "tip_catalog_item" */
export type Tip_Catalog_Item_On_Conflict = {
  constraint: Tip_Catalog_Item_Constraint;
  update_columns?: Array<Tip_Catalog_Item_Update_Column>;
  where?: Maybe<Tip_Catalog_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_catalog_item". */
export type Tip_Catalog_Item_Order_By = {
  catalog_item?: Maybe<Catalog_Item_Order_By>;
  catalog_item_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_catalog_item */
export type Tip_Catalog_Item_Pk_Columns_Input = {
  tip_catalog_item_id: Scalars['Int'];
};

/** select columns of table "tip_catalog_item" */
export enum Tip_Catalog_Item_Select_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipCatalogItemId = 'tip_catalog_item_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_catalog_item" */
export type Tip_Catalog_Item_Set_Input = {
  catalog_item_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tip_catalog_item_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Catalog_Item_Stddev_Fields = {
  __typename?: 'tip_catalog_item_stddev_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Stddev_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tip_Catalog_Item_Stddev_Pop_Fields = {
  __typename?: 'tip_catalog_item_stddev_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Stddev_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tip_Catalog_Item_Stddev_Samp_Fields = {
  __typename?: 'tip_catalog_item_stddev_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Stddev_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tip_Catalog_Item_Sum_Fields = {
  __typename?: 'tip_catalog_item_sum_fields';
  catalog_item_id?: Maybe<Scalars['Int']>;
  tip_catalog_item_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Sum_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** update columns of table "tip_catalog_item" */
export enum Tip_Catalog_Item_Update_Column {
  /** column name */
  CatalogItemId = 'catalog_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TipCatalogItemId = 'tip_catalog_item_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Catalog_Item_Var_Pop_Fields = {
  __typename?: 'tip_catalog_item_var_pop_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Var_Pop_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tip_Catalog_Item_Var_Samp_Fields = {
  __typename?: 'tip_catalog_item_var_samp_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Var_Samp_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tip_Catalog_Item_Variance_Fields = {
  __typename?: 'tip_catalog_item_variance_fields';
  catalog_item_id?: Maybe<Scalars['Float']>;
  tip_catalog_item_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tip_catalog_item" */
export type Tip_Catalog_Item_Variance_Order_By = {
  catalog_item_id?: Maybe<Order_By>;
  tip_catalog_item_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "tip" */
export enum Tip_Constraint {
  /** unique or primary key constraint */
  TipLockableContentIdKey = 'tip_lockable_content_id_key',
  /** unique or primary key constraint */
  TipPkey = 'tip_pkey'
}

/** columns and relationships of "tip_grade" */
export type Tip_Grade = {
  __typename?: 'tip_grade';
  created_at?: Maybe<Scalars['timestamptz']>;
  grades_id: Scalars['Int'];
  tip_grade_id: Scalars['Int'];
  tip_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_grade" */
export type Tip_Grade_Aggregate = {
  __typename?: 'tip_grade_aggregate';
  aggregate?: Maybe<Tip_Grade_Aggregate_Fields>;
  nodes: Array<Tip_Grade>;
};

/** aggregate fields of "tip_grade" */
export type Tip_Grade_Aggregate_Fields = {
  __typename?: 'tip_grade_aggregate_fields';
  avg?: Maybe<Tip_Grade_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Grade_Max_Fields>;
  min?: Maybe<Tip_Grade_Min_Fields>;
  stddev?: Maybe<Tip_Grade_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Grade_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Grade_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Grade_Sum_Fields>;
  var_pop?: Maybe<Tip_Grade_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Grade_Var_Samp_Fields>;
  variance?: Maybe<Tip_Grade_Variance_Fields>;
};


/** aggregate fields of "tip_grade" */
export type Tip_Grade_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Grade_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tip_Grade_Avg_Fields = {
  __typename?: 'tip_grade_avg_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tip_grade". All fields are combined with a logical 'AND'. */
export type Tip_Grade_Bool_Exp = {
  _and?: Maybe<Array<Tip_Grade_Bool_Exp>>;
  _not?: Maybe<Tip_Grade_Bool_Exp>;
  _or?: Maybe<Array<Tip_Grade_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  grades_id?: Maybe<Int_Comparison_Exp>;
  tip_grade_id?: Maybe<Int_Comparison_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_grade" */
export enum Tip_Grade_Constraint {
  /** unique or primary key constraint */
  GradeTipPkey = 'grade_tip_pkey',
  /** unique or primary key constraint */
  TipGradesGradesIdTipIdKey = 'tip_grades_grades_id_tip_id_key'
}

/** input type for incrementing numeric columns in table "tip_grade" */
export type Tip_Grade_Inc_Input = {
  grades_id?: Maybe<Scalars['Int']>;
  tip_grade_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_grade" */
export type Tip_Grade_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grades_id?: Maybe<Scalars['Int']>;
  tip_grade_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Grade_Max_Fields = {
  __typename?: 'tip_grade_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grades_id?: Maybe<Scalars['Int']>;
  tip_grade_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tip_Grade_Min_Fields = {
  __typename?: 'tip_grade_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  grades_id?: Maybe<Scalars['Int']>;
  tip_grade_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tip_grade" */
export type Tip_Grade_Mutation_Response = {
  __typename?: 'tip_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Grade>;
};

/** on_conflict condition type for table "tip_grade" */
export type Tip_Grade_On_Conflict = {
  constraint: Tip_Grade_Constraint;
  update_columns?: Array<Tip_Grade_Update_Column>;
  where?: Maybe<Tip_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_grade". */
export type Tip_Grade_Order_By = {
  created_at?: Maybe<Order_By>;
  grades_id?: Maybe<Order_By>;
  tip_grade_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_grade */
export type Tip_Grade_Pk_Columns_Input = {
  tip_grade_id: Scalars['Int'];
};

/** select columns of table "tip_grade" */
export enum Tip_Grade_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradesId = 'grades_id',
  /** column name */
  TipGradeId = 'tip_grade_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_grade" */
export type Tip_Grade_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  grades_id?: Maybe<Scalars['Int']>;
  tip_grade_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Grade_Stddev_Fields = {
  __typename?: 'tip_grade_stddev_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tip_Grade_Stddev_Pop_Fields = {
  __typename?: 'tip_grade_stddev_pop_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tip_Grade_Stddev_Samp_Fields = {
  __typename?: 'tip_grade_stddev_samp_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tip_Grade_Sum_Fields = {
  __typename?: 'tip_grade_sum_fields';
  grades_id?: Maybe<Scalars['Int']>;
  tip_grade_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tip_grade" */
export enum Tip_Grade_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GradesId = 'grades_id',
  /** column name */
  TipGradeId = 'tip_grade_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Grade_Var_Pop_Fields = {
  __typename?: 'tip_grade_var_pop_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tip_Grade_Var_Samp_Fields = {
  __typename?: 'tip_grade_var_samp_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tip_Grade_Variance_Fields = {
  __typename?: 'tip_grade_variance_fields';
  grades_id?: Maybe<Scalars['Float']>;
  tip_grade_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "tip" */
export type Tip_Inc_Input = {
  artist_id?: Maybe<Scalars['Int']>;
  available_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip" */
export type Tip_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  artist?: Maybe<Artist_Obj_Rel_Insert_Input>;
  artist_id?: Maybe<Scalars['Int']>;
  asset_path?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  class_tips?: Maybe<Class_Tip_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content?: Maybe<Lockable_Content_Obj_Rel_Insert_Input>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
  skill_level?: Maybe<Skill_Level_Obj_Rel_Insert_Input>;
  skill_level_id?: Maybe<Scalars['Int']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['Int']>;
  teacher_tip_favorites?: Maybe<Teacher_Tip_Favorite_Arr_Rel_Insert_Input>;
  tip_catalog_item?: Maybe<Tip_Catalog_Item_Arr_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keywords?: Maybe<Tip_Keyword_Arr_Rel_Insert_Input>;
  tip_playlists?: Maybe<Tip_Playlist_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "tip_keyword" */
export type Tip_Keyword = {
  __typename?: 'tip_keyword';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  keyword: Keyword;
  keyword_id: Scalars['Int'];
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['Int'];
  tip_keyword_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_keyword" */
export type Tip_Keyword_Aggregate = {
  __typename?: 'tip_keyword_aggregate';
  aggregate?: Maybe<Tip_Keyword_Aggregate_Fields>;
  nodes: Array<Tip_Keyword>;
};

/** aggregate fields of "tip_keyword" */
export type Tip_Keyword_Aggregate_Fields = {
  __typename?: 'tip_keyword_aggregate_fields';
  avg?: Maybe<Tip_Keyword_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Keyword_Max_Fields>;
  min?: Maybe<Tip_Keyword_Min_Fields>;
  stddev?: Maybe<Tip_Keyword_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Keyword_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Keyword_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Keyword_Sum_Fields>;
  var_pop?: Maybe<Tip_Keyword_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Keyword_Var_Samp_Fields>;
  variance?: Maybe<Tip_Keyword_Variance_Fields>;
};


/** aggregate fields of "tip_keyword" */
export type Tip_Keyword_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Keyword_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tip_keyword" */
export type Tip_Keyword_Aggregate_Order_By = {
  avg?: Maybe<Tip_Keyword_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tip_Keyword_Max_Order_By>;
  min?: Maybe<Tip_Keyword_Min_Order_By>;
  stddev?: Maybe<Tip_Keyword_Stddev_Order_By>;
  stddev_pop?: Maybe<Tip_Keyword_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tip_Keyword_Stddev_Samp_Order_By>;
  sum?: Maybe<Tip_Keyword_Sum_Order_By>;
  var_pop?: Maybe<Tip_Keyword_Var_Pop_Order_By>;
  var_samp?: Maybe<Tip_Keyword_Var_Samp_Order_By>;
  variance?: Maybe<Tip_Keyword_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tip_keyword" */
export type Tip_Keyword_Arr_Rel_Insert_Input = {
  data: Array<Tip_Keyword_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tip_Keyword_On_Conflict>;
};

/** aggregate avg on columns */
export type Tip_Keyword_Avg_Fields = {
  __typename?: 'tip_keyword_avg_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tip_keyword" */
export type Tip_Keyword_Avg_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tip_keyword". All fields are combined with a logical 'AND'. */
export type Tip_Keyword_Bool_Exp = {
  _and?: Maybe<Array<Tip_Keyword_Bool_Exp>>;
  _not?: Maybe<Tip_Keyword_Bool_Exp>;
  _or?: Maybe<Array<Tip_Keyword_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  keyword?: Maybe<Keyword_Bool_Exp>;
  keyword_id?: Maybe<Int_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  tip_keyword_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_keyword" */
export enum Tip_Keyword_Constraint {
  /** unique or primary key constraint */
  TipKeywordPkey = 'tip_keyword_pkey',
  /** unique or primary key constraint */
  TipKeywordTipIdKeywordIdKey = 'tip_keyword_tip_id_keyword_id_key'
}

/** input type for incrementing numeric columns in table "tip_keyword" */
export type Tip_Keyword_Inc_Input = {
  keyword_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keyword_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_keyword" */
export type Tip_Keyword_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword?: Maybe<Keyword_Obj_Rel_Insert_Input>;
  keyword_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Keyword_Max_Fields = {
  __typename?: 'tip_keyword_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tip_keyword" */
export type Tip_Keyword_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tip_Keyword_Min_Fields = {
  __typename?: 'tip_keyword_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tip_keyword" */
export type Tip_Keyword_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tip_keyword" */
export type Tip_Keyword_Mutation_Response = {
  __typename?: 'tip_keyword_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Keyword>;
};

/** on_conflict condition type for table "tip_keyword" */
export type Tip_Keyword_On_Conflict = {
  constraint: Tip_Keyword_Constraint;
  update_columns?: Array<Tip_Keyword_Update_Column>;
  where?: Maybe<Tip_Keyword_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_keyword". */
export type Tip_Keyword_Order_By = {
  created_at?: Maybe<Order_By>;
  keyword?: Maybe<Keyword_Order_By>;
  keyword_id?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_keyword */
export type Tip_Keyword_Pk_Columns_Input = {
  tip_keyword_id: Scalars['Int'];
};

/** select columns of table "tip_keyword" */
export enum Tip_Keyword_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipKeywordId = 'tip_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_keyword" */
export type Tip_Keyword_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  keyword_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keyword_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Keyword_Stddev_Fields = {
  __typename?: 'tip_keyword_stddev_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tip_keyword" */
export type Tip_Keyword_Stddev_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tip_Keyword_Stddev_Pop_Fields = {
  __typename?: 'tip_keyword_stddev_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tip_keyword" */
export type Tip_Keyword_Stddev_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tip_Keyword_Stddev_Samp_Fields = {
  __typename?: 'tip_keyword_stddev_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tip_keyword" */
export type Tip_Keyword_Stddev_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tip_Keyword_Sum_Fields = {
  __typename?: 'tip_keyword_sum_fields';
  keyword_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_keyword_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tip_keyword" */
export type Tip_Keyword_Sum_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** update columns of table "tip_keyword" */
export enum Tip_Keyword_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  KeywordId = 'keyword_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipKeywordId = 'tip_keyword_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Keyword_Var_Pop_Fields = {
  __typename?: 'tip_keyword_var_pop_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tip_keyword" */
export type Tip_Keyword_Var_Pop_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tip_Keyword_Var_Samp_Fields = {
  __typename?: 'tip_keyword_var_samp_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tip_keyword" */
export type Tip_Keyword_Var_Samp_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tip_Keyword_Variance_Fields = {
  __typename?: 'tip_keyword_variance_fields';
  keyword_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_keyword_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tip_keyword" */
export type Tip_Keyword_Variance_Order_By = {
  keyword_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keyword_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Tip_Max_Fields = {
  __typename?: 'tip_max_fields';
  artist_id?: Maybe<Scalars['Int']>;
  asset_path?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tip_Min_Fields = {
  __typename?: 'tip_min_fields';
  artist_id?: Maybe<Scalars['Int']>;
  asset_path?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "tip_musical_skill" */
export type Tip_Musical_Skill = {
  __typename?: 'tip_musical_skill';
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id: Scalars['Int'];
  tip_id: Scalars['Int'];
  tip_musical_skill_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_musical_skill" */
export type Tip_Musical_Skill_Aggregate = {
  __typename?: 'tip_musical_skill_aggregate';
  aggregate?: Maybe<Tip_Musical_Skill_Aggregate_Fields>;
  nodes: Array<Tip_Musical_Skill>;
};

/** aggregate fields of "tip_musical_skill" */
export type Tip_Musical_Skill_Aggregate_Fields = {
  __typename?: 'tip_musical_skill_aggregate_fields';
  avg?: Maybe<Tip_Musical_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Musical_Skill_Max_Fields>;
  min?: Maybe<Tip_Musical_Skill_Min_Fields>;
  stddev?: Maybe<Tip_Musical_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Musical_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Musical_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Musical_Skill_Sum_Fields>;
  var_pop?: Maybe<Tip_Musical_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Musical_Skill_Var_Samp_Fields>;
  variance?: Maybe<Tip_Musical_Skill_Variance_Fields>;
};


/** aggregate fields of "tip_musical_skill" */
export type Tip_Musical_Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Musical_Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tip_Musical_Skill_Avg_Fields = {
  __typename?: 'tip_musical_skill_avg_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tip_musical_skill". All fields are combined with a logical 'AND'. */
export type Tip_Musical_Skill_Bool_Exp = {
  _and?: Maybe<Array<Tip_Musical_Skill_Bool_Exp>>;
  _not?: Maybe<Tip_Musical_Skill_Bool_Exp>;
  _or?: Maybe<Array<Tip_Musical_Skill_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  musical_skill_id?: Maybe<Int_Comparison_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  tip_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_musical_skill" */
export enum Tip_Musical_Skill_Constraint {
  /** unique or primary key constraint */
  MusicalSkillTipPkey = 'musical_skill_tip_pkey'
}

/** input type for incrementing numeric columns in table "tip_musical_skill" */
export type Tip_Musical_Skill_Inc_Input = {
  musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_musical_skill" */
export type Tip_Musical_Skill_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Musical_Skill_Max_Fields = {
  __typename?: 'tip_musical_skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tip_Musical_Skill_Min_Fields = {
  __typename?: 'tip_musical_skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tip_musical_skill" */
export type Tip_Musical_Skill_Mutation_Response = {
  __typename?: 'tip_musical_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Musical_Skill>;
};

/** on_conflict condition type for table "tip_musical_skill" */
export type Tip_Musical_Skill_On_Conflict = {
  constraint: Tip_Musical_Skill_Constraint;
  update_columns?: Array<Tip_Musical_Skill_Update_Column>;
  where?: Maybe<Tip_Musical_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_musical_skill". */
export type Tip_Musical_Skill_Order_By = {
  created_at?: Maybe<Order_By>;
  musical_skill_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_musical_skill */
export type Tip_Musical_Skill_Pk_Columns_Input = {
  tip_musical_skill_id: Scalars['Int'];
};

/** select columns of table "tip_musical_skill" */
export enum Tip_Musical_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MusicalSkillId = 'musical_skill_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipMusicalSkillId = 'tip_musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_musical_skill" */
export type Tip_Musical_Skill_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Musical_Skill_Stddev_Fields = {
  __typename?: 'tip_musical_skill_stddev_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tip_Musical_Skill_Stddev_Pop_Fields = {
  __typename?: 'tip_musical_skill_stddev_pop_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tip_Musical_Skill_Stddev_Samp_Fields = {
  __typename?: 'tip_musical_skill_stddev_samp_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tip_Musical_Skill_Sum_Fields = {
  __typename?: 'tip_musical_skill_sum_fields';
  musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tip_musical_skill" */
export enum Tip_Musical_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MusicalSkillId = 'musical_skill_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipMusicalSkillId = 'tip_musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Musical_Skill_Var_Pop_Fields = {
  __typename?: 'tip_musical_skill_var_pop_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tip_Musical_Skill_Var_Samp_Fields = {
  __typename?: 'tip_musical_skill_var_samp_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tip_Musical_Skill_Variance_Fields = {
  __typename?: 'tip_musical_skill_variance_fields';
  musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "tip" */
export type Tip_Mutation_Response = {
  __typename?: 'tip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip>;
};

/** columns and relationships of "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill = {
  __typename?: 'tip_non_musical_skill';
  created_at?: Maybe<Scalars['timestamptz']>;
  non_musical_skill_id: Scalars['Int'];
  tip_id: Scalars['Int'];
  tip_non_musical_skill_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Aggregate = {
  __typename?: 'tip_non_musical_skill_aggregate';
  aggregate?: Maybe<Tip_Non_Musical_Skill_Aggregate_Fields>;
  nodes: Array<Tip_Non_Musical_Skill>;
};

/** aggregate fields of "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Aggregate_Fields = {
  __typename?: 'tip_non_musical_skill_aggregate_fields';
  avg?: Maybe<Tip_Non_Musical_Skill_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Non_Musical_Skill_Max_Fields>;
  min?: Maybe<Tip_Non_Musical_Skill_Min_Fields>;
  stddev?: Maybe<Tip_Non_Musical_Skill_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Non_Musical_Skill_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Non_Musical_Skill_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Non_Musical_Skill_Sum_Fields>;
  var_pop?: Maybe<Tip_Non_Musical_Skill_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Non_Musical_Skill_Var_Samp_Fields>;
  variance?: Maybe<Tip_Non_Musical_Skill_Variance_Fields>;
};


/** aggregate fields of "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Non_Musical_Skill_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tip_Non_Musical_Skill_Avg_Fields = {
  __typename?: 'tip_non_musical_skill_avg_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tip_non_musical_skill". All fields are combined with a logical 'AND'. */
export type Tip_Non_Musical_Skill_Bool_Exp = {
  _and?: Maybe<Array<Tip_Non_Musical_Skill_Bool_Exp>>;
  _not?: Maybe<Tip_Non_Musical_Skill_Bool_Exp>;
  _or?: Maybe<Array<Tip_Non_Musical_Skill_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  non_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  tip_non_musical_skill_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_non_musical_skill" */
export enum Tip_Non_Musical_Skill_Constraint {
  /** unique or primary key constraint */
  NonMusicalSkillTipPkey = 'non_musical_skill_tip_pkey',
  /** unique or primary key constraint */
  TipNonMusicalSkillTipIdNonMusicalSkillIdKey = 'tip_non_musical_skill_tip_id_non_musical_skill_id_key'
}

/** input type for incrementing numeric columns in table "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Inc_Input = {
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Non_Musical_Skill_Max_Fields = {
  __typename?: 'tip_non_musical_skill_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tip_Non_Musical_Skill_Min_Fields = {
  __typename?: 'tip_non_musical_skill_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Mutation_Response = {
  __typename?: 'tip_non_musical_skill_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Non_Musical_Skill>;
};

/** on_conflict condition type for table "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_On_Conflict = {
  constraint: Tip_Non_Musical_Skill_Constraint;
  update_columns?: Array<Tip_Non_Musical_Skill_Update_Column>;
  where?: Maybe<Tip_Non_Musical_Skill_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_non_musical_skill". */
export type Tip_Non_Musical_Skill_Order_By = {
  created_at?: Maybe<Order_By>;
  non_musical_skill_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_non_musical_skill_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_non_musical_skill */
export type Tip_Non_Musical_Skill_Pk_Columns_Input = {
  tip_non_musical_skill_id: Scalars['Int'];
};

/** select columns of table "tip_non_musical_skill" */
export enum Tip_Non_Musical_Skill_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NonMusicalSkillId = 'non_musical_skill_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipNonMusicalSkillId = 'tip_non_musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_non_musical_skill" */
export type Tip_Non_Musical_Skill_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Non_Musical_Skill_Stddev_Fields = {
  __typename?: 'tip_non_musical_skill_stddev_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tip_Non_Musical_Skill_Stddev_Pop_Fields = {
  __typename?: 'tip_non_musical_skill_stddev_pop_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tip_Non_Musical_Skill_Stddev_Samp_Fields = {
  __typename?: 'tip_non_musical_skill_stddev_samp_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tip_Non_Musical_Skill_Sum_Fields = {
  __typename?: 'tip_non_musical_skill_sum_fields';
  non_musical_skill_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tip_non_musical_skill" */
export enum Tip_Non_Musical_Skill_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NonMusicalSkillId = 'non_musical_skill_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipNonMusicalSkillId = 'tip_non_musical_skill_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Non_Musical_Skill_Var_Pop_Fields = {
  __typename?: 'tip_non_musical_skill_var_pop_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tip_Non_Musical_Skill_Var_Samp_Fields = {
  __typename?: 'tip_non_musical_skill_var_samp_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tip_Non_Musical_Skill_Variance_Fields = {
  __typename?: 'tip_non_musical_skill_variance_fields';
  non_musical_skill_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_non_musical_skill_id?: Maybe<Scalars['Float']>;
};

/** input type for inserting object relation for remote table "tip" */
export type Tip_Obj_Rel_Insert_Input = {
  data: Tip_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tip_On_Conflict>;
};

/** on_conflict condition type for table "tip" */
export type Tip_On_Conflict = {
  constraint: Tip_Constraint;
  update_columns?: Array<Tip_Update_Column>;
  where?: Maybe<Tip_Bool_Exp>;
};

/** Ordering options when selecting data from "tip". */
export type Tip_Order_By = {
  active?: Maybe<Order_By>;
  artist?: Maybe<Artist_Order_By>;
  artist_id?: Maybe<Order_By>;
  asset_path?: Maybe<Order_By>;
  available_id?: Maybe<Order_By>;
  class_tips_aggregate?: Maybe<Class_Tip_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  featured?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_purchased_by_teacher?: Maybe<Order_By>;
  lockable_content?: Maybe<Lockable_Content_Order_By>;
  lockable_content_id?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  skill_level?: Maybe<Skill_Level_Order_By>;
  skill_level_id?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  teacher_tip_favorites_aggregate?: Maybe<Teacher_Tip_Favorite_Aggregate_Order_By>;
  tip_catalog_item_aggregate?: Maybe<Tip_Catalog_Item_Aggregate_Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_keywords_aggregate?: Maybe<Tip_Keyword_Aggregate_Order_By>;
  tip_playlists_aggregate?: Maybe<Tip_Playlist_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip */
export type Tip_Pk_Columns_Input = {
  tip_id: Scalars['Int'];
};

/** columns and relationships of "tip_playlist" */
export type Tip_Playlist = {
  __typename?: 'tip_playlist';
  created_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['String']>;
  /** An object relationship */
  playlist: Playlist;
  playlist_id: Scalars['Int'];
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['Int'];
  tip_playlist_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "tip_playlist" */
export type Tip_Playlist_Aggregate = {
  __typename?: 'tip_playlist_aggregate';
  aggregate?: Maybe<Tip_Playlist_Aggregate_Fields>;
  nodes: Array<Tip_Playlist>;
};

/** aggregate fields of "tip_playlist" */
export type Tip_Playlist_Aggregate_Fields = {
  __typename?: 'tip_playlist_aggregate_fields';
  avg?: Maybe<Tip_Playlist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Playlist_Max_Fields>;
  min?: Maybe<Tip_Playlist_Min_Fields>;
  stddev?: Maybe<Tip_Playlist_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Playlist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Playlist_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Playlist_Sum_Fields>;
  var_pop?: Maybe<Tip_Playlist_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Playlist_Var_Samp_Fields>;
  variance?: Maybe<Tip_Playlist_Variance_Fields>;
};


/** aggregate fields of "tip_playlist" */
export type Tip_Playlist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Playlist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tip_playlist" */
export type Tip_Playlist_Aggregate_Order_By = {
  avg?: Maybe<Tip_Playlist_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tip_Playlist_Max_Order_By>;
  min?: Maybe<Tip_Playlist_Min_Order_By>;
  stddev?: Maybe<Tip_Playlist_Stddev_Order_By>;
  stddev_pop?: Maybe<Tip_Playlist_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tip_Playlist_Stddev_Samp_Order_By>;
  sum?: Maybe<Tip_Playlist_Sum_Order_By>;
  var_pop?: Maybe<Tip_Playlist_Var_Pop_Order_By>;
  var_samp?: Maybe<Tip_Playlist_Var_Samp_Order_By>;
  variance?: Maybe<Tip_Playlist_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tip_playlist" */
export type Tip_Playlist_Arr_Rel_Insert_Input = {
  data: Array<Tip_Playlist_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tip_Playlist_On_Conflict>;
};

/** aggregate avg on columns */
export type Tip_Playlist_Avg_Fields = {
  __typename?: 'tip_playlist_avg_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tip_playlist" */
export type Tip_Playlist_Avg_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tip_playlist". All fields are combined with a logical 'AND'. */
export type Tip_Playlist_Bool_Exp = {
  _and?: Maybe<Array<Tip_Playlist_Bool_Exp>>;
  _not?: Maybe<Tip_Playlist_Bool_Exp>;
  _or?: Maybe<Array<Tip_Playlist_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  order?: Maybe<String_Comparison_Exp>;
  playlist?: Maybe<Playlist_Bool_Exp>;
  playlist_id?: Maybe<Int_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Int_Comparison_Exp>;
  tip_playlist_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_playlist" */
export enum Tip_Playlist_Constraint {
  /** unique or primary key constraint */
  TipPlaylistPkey = 'tip_playlist_pkey',
  /** unique or primary key constraint */
  TipPlaylistTipIdPlaylistIdKey = 'tip_playlist_tip_id_playlist_id_key'
}

/** input type for incrementing numeric columns in table "tip_playlist" */
export type Tip_Playlist_Inc_Input = {
  playlist_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_playlist_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tip_playlist" */
export type Tip_Playlist_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['String']>;
  playlist?: Maybe<Playlist_Obj_Rel_Insert_Input>;
  playlist_id?: Maybe<Scalars['Int']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Playlist_Max_Fields = {
  __typename?: 'tip_playlist_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['String']>;
  playlist_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tip_playlist" */
export type Tip_Playlist_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tip_Playlist_Min_Fields = {
  __typename?: 'tip_playlist_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['String']>;
  playlist_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tip_playlist" */
export type Tip_Playlist_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tip_playlist" */
export type Tip_Playlist_Mutation_Response = {
  __typename?: 'tip_playlist_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Playlist>;
};

/** on_conflict condition type for table "tip_playlist" */
export type Tip_Playlist_On_Conflict = {
  constraint: Tip_Playlist_Constraint;
  update_columns?: Array<Tip_Playlist_Update_Column>;
  where?: Maybe<Tip_Playlist_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_playlist". */
export type Tip_Playlist_Order_By = {
  created_at?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  playlist?: Maybe<Playlist_Order_By>;
  playlist_id?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_playlist */
export type Tip_Playlist_Pk_Columns_Input = {
  tip_playlist_id: Scalars['Int'];
};

/** select columns of table "tip_playlist" */
export enum Tip_Playlist_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Order = 'order',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipPlaylistId = 'tip_playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip_playlist" */
export type Tip_Playlist_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  order?: Maybe<Scalars['String']>;
  playlist_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_playlist_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Playlist_Stddev_Fields = {
  __typename?: 'tip_playlist_stddev_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tip_playlist" */
export type Tip_Playlist_Stddev_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tip_Playlist_Stddev_Pop_Fields = {
  __typename?: 'tip_playlist_stddev_pop_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tip_playlist" */
export type Tip_Playlist_Stddev_Pop_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tip_Playlist_Stddev_Samp_Fields = {
  __typename?: 'tip_playlist_stddev_samp_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tip_playlist" */
export type Tip_Playlist_Stddev_Samp_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tip_Playlist_Sum_Fields = {
  __typename?: 'tip_playlist_sum_fields';
  playlist_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  tip_playlist_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tip_playlist" */
export type Tip_Playlist_Sum_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** update columns of table "tip_playlist" */
export enum Tip_Playlist_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Order = 'order',
  /** column name */
  PlaylistId = 'playlist_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  TipPlaylistId = 'tip_playlist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Playlist_Var_Pop_Fields = {
  __typename?: 'tip_playlist_var_pop_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tip_playlist" */
export type Tip_Playlist_Var_Pop_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tip_Playlist_Var_Samp_Fields = {
  __typename?: 'tip_playlist_var_samp_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tip_playlist" */
export type Tip_Playlist_Var_Samp_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tip_Playlist_Variance_Fields = {
  __typename?: 'tip_playlist_variance_fields';
  playlist_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
  tip_playlist_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tip_playlist" */
export type Tip_Playlist_Variance_Order_By = {
  playlist_id?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
  tip_playlist_id?: Maybe<Order_By>;
};

/** select columns of table "tip" */
export enum Tip_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  AssetPath = 'asset_path',
  /** column name */
  AvailableId = 'available_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip" */
export type Tip_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  artist_id?: Maybe<Scalars['Int']>;
  asset_path?: Maybe<Scalars['String']>;
  available_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  image_path?: Maybe<Scalars['String']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  resource_path?: Maybe<Scalars['String']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tip_Stddev_Fields = {
  __typename?: 'tip_stddev_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tip_Stddev_Pop_Fields = {
  __typename?: 'tip_stddev_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tip_Stddev_Samp_Fields = {
  __typename?: 'tip_stddev_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tip_Sum_Fields = {
  __typename?: 'tip_sum_fields';
  artist_id?: Maybe<Scalars['Int']>;
  available_id?: Maybe<Scalars['Int']>;
  lockable_content_id?: Maybe<Scalars['Int']>;
  skill_level_id?: Maybe<Scalars['Int']>;
  teacher_id?: Maybe<Scalars['Int']>;
  tip_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "tip" */
export enum Tip_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  AssetPath = 'asset_path',
  /** column name */
  AvailableId = 'available_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Featured = 'featured',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  LockableContentId = 'lockable_content_id',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  SkillLevelId = 'skill_level_id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  TipId = 'tip_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tip_Var_Pop_Fields = {
  __typename?: 'tip_var_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tip_Var_Samp_Fields = {
  __typename?: 'tip_var_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tip_Variance_Fields = {
  __typename?: 'tip_variance_fields';
  artist_id?: Maybe<Scalars['Float']>;
  available_id?: Maybe<Scalars['Float']>;
  lockable_content_id?: Maybe<Scalars['Float']>;
  skill_level_id?: Maybe<Scalars['Float']>;
  teacher_id?: Maybe<Scalars['Float']>;
  tip_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "track" */
export type Track = {
  __typename?: 'track';
  created_at?: Maybe<Scalars['timestamptz']>;
  isrc_id?: Maybe<Scalars['String']>;
  last_update: Scalars['timestamptz'];
  masterlist_id?: Maybe<Scalars['Int']>;
  midi?: Maybe<Scalars['bytea']>;
  midi_path?: Maybe<Scalars['String']>;
  /** An array relationship */
  midis: Array<Midi>;
  /** An aggregate relationship */
  midis_aggregate: Midi_Aggregate;
  notes?: Maybe<Scalars['String']>;
  resource_path: Scalars['String'];
  /** An array relationship */
  royalty_logs: Array<Royalty_Log>;
  /** An aggregate relationship */
  royalty_logs_aggregate: Royalty_Log_Aggregate;
  /** An object relationship */
  song: Song;
  song_id: Scalars['Int'];
  track_id: Scalars['Int'];
  /** An object relationship */
  track_type: Track_Type;
  track_type_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "track" */
export type TrackMidisArgs = {
  distinct_on?: Maybe<Array<Midi_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Midi_Order_By>>;
  where?: Maybe<Midi_Bool_Exp>;
};


/** columns and relationships of "track" */
export type TrackMidis_AggregateArgs = {
  distinct_on?: Maybe<Array<Midi_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Midi_Order_By>>;
  where?: Maybe<Midi_Bool_Exp>;
};


/** columns and relationships of "track" */
export type TrackRoyalty_LogsArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};


/** columns and relationships of "track" */
export type TrackRoyalty_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Royalty_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Royalty_Log_Order_By>>;
  where?: Maybe<Royalty_Log_Bool_Exp>;
};

/** aggregated selection of "track" */
export type Track_Aggregate = {
  __typename?: 'track_aggregate';
  aggregate?: Maybe<Track_Aggregate_Fields>;
  nodes: Array<Track>;
};

/** aggregate fields of "track" */
export type Track_Aggregate_Fields = {
  __typename?: 'track_aggregate_fields';
  avg?: Maybe<Track_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Track_Max_Fields>;
  min?: Maybe<Track_Min_Fields>;
  stddev?: Maybe<Track_Stddev_Fields>;
  stddev_pop?: Maybe<Track_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Track_Stddev_Samp_Fields>;
  sum?: Maybe<Track_Sum_Fields>;
  var_pop?: Maybe<Track_Var_Pop_Fields>;
  var_samp?: Maybe<Track_Var_Samp_Fields>;
  variance?: Maybe<Track_Variance_Fields>;
};


/** aggregate fields of "track" */
export type Track_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Track_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "track" */
export type Track_Aggregate_Order_By = {
  avg?: Maybe<Track_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Track_Max_Order_By>;
  min?: Maybe<Track_Min_Order_By>;
  stddev?: Maybe<Track_Stddev_Order_By>;
  stddev_pop?: Maybe<Track_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Track_Stddev_Samp_Order_By>;
  sum?: Maybe<Track_Sum_Order_By>;
  var_pop?: Maybe<Track_Var_Pop_Order_By>;
  var_samp?: Maybe<Track_Var_Samp_Order_By>;
  variance?: Maybe<Track_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "track" */
export type Track_Arr_Rel_Insert_Input = {
  data: Array<Track_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Track_On_Conflict>;
};

/** aggregate avg on columns */
export type Track_Avg_Fields = {
  __typename?: 'track_avg_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "track" */
export type Track_Avg_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "track". All fields are combined with a logical 'AND'. */
export type Track_Bool_Exp = {
  _and?: Maybe<Array<Track_Bool_Exp>>;
  _not?: Maybe<Track_Bool_Exp>;
  _or?: Maybe<Array<Track_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  isrc_id?: Maybe<String_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  masterlist_id?: Maybe<Int_Comparison_Exp>;
  midi?: Maybe<Bytea_Comparison_Exp>;
  midi_path?: Maybe<String_Comparison_Exp>;
  midis?: Maybe<Midi_Bool_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  resource_path?: Maybe<String_Comparison_Exp>;
  royalty_logs?: Maybe<Royalty_Log_Bool_Exp>;
  song?: Maybe<Song_Bool_Exp>;
  song_id?: Maybe<Int_Comparison_Exp>;
  track_id?: Maybe<Int_Comparison_Exp>;
  track_type?: Maybe<Track_Type_Bool_Exp>;
  track_type_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "track" */
export enum Track_Constraint {
  /** unique or primary key constraint */
  TrackPkey = 'track_pkey'
}

/** input type for incrementing numeric columns in table "track" */
export type Track_Inc_Input = {
  masterlist_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  track_type_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "track" */
export type Track_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  isrc_id?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  midi?: Maybe<Scalars['bytea']>;
  midi_path?: Maybe<Scalars['String']>;
  midis?: Maybe<Midi_Arr_Rel_Insert_Input>;
  notes?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  royalty_logs?: Maybe<Royalty_Log_Arr_Rel_Insert_Input>;
  song?: Maybe<Song_Obj_Rel_Insert_Input>;
  song_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  track_type?: Maybe<Track_Type_Obj_Rel_Insert_Input>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Track_Max_Fields = {
  __typename?: 'track_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  isrc_id?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  midi_path?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  song_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "track" */
export type Track_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  isrc_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  masterlist_id?: Maybe<Order_By>;
  midi_path?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Track_Min_Fields = {
  __typename?: 'track_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  isrc_id?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  midi_path?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  song_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "track" */
export type Track_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  isrc_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  masterlist_id?: Maybe<Order_By>;
  midi_path?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "track" */
export type Track_Mutation_Response = {
  __typename?: 'track_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Track>;
};

/** input type for inserting object relation for remote table "track" */
export type Track_Obj_Rel_Insert_Input = {
  data: Track_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Track_On_Conflict>;
};

/** on_conflict condition type for table "track" */
export type Track_On_Conflict = {
  constraint: Track_Constraint;
  update_columns?: Array<Track_Update_Column>;
  where?: Maybe<Track_Bool_Exp>;
};

/** Ordering options when selecting data from "track". */
export type Track_Order_By = {
  created_at?: Maybe<Order_By>;
  isrc_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  masterlist_id?: Maybe<Order_By>;
  midi?: Maybe<Order_By>;
  midi_path?: Maybe<Order_By>;
  midis_aggregate?: Maybe<Midi_Aggregate_Order_By>;
  notes?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  royalty_logs_aggregate?: Maybe<Royalty_Log_Aggregate_Order_By>;
  song?: Maybe<Song_Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type?: Maybe<Track_Type_Order_By>;
  track_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: track */
export type Track_Pk_Columns_Input = {
  track_id: Scalars['Int'];
};

/** select columns of table "track" */
export enum Track_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsrcId = 'isrc_id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  MasterlistId = 'masterlist_id',
  /** column name */
  Midi = 'midi',
  /** column name */
  MidiPath = 'midi_path',
  /** column name */
  Notes = 'notes',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  SongId = 'song_id',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  TrackTypeId = 'track_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "track" */
export type Track_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  isrc_id?: Maybe<Scalars['String']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  masterlist_id?: Maybe<Scalars['Int']>;
  midi?: Maybe<Scalars['bytea']>;
  midi_path?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  song_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Track_Stddev_Fields = {
  __typename?: 'track_stddev_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "track" */
export type Track_Stddev_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Track_Stddev_Pop_Fields = {
  __typename?: 'track_stddev_pop_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "track" */
export type Track_Stddev_Pop_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Track_Stddev_Samp_Fields = {
  __typename?: 'track_stddev_samp_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "track" */
export type Track_Stddev_Samp_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Track_Sum_Fields = {
  __typename?: 'track_sum_fields';
  masterlist_id?: Maybe<Scalars['Int']>;
  song_id?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  track_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "track" */
export type Track_Sum_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "track_type" */
export type Track_Type = {
  __typename?: 'track_type';
  created_at?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  track_type_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "track_type" */
export type Track_Type_Aggregate = {
  __typename?: 'track_type_aggregate';
  aggregate?: Maybe<Track_Type_Aggregate_Fields>;
  nodes: Array<Track_Type>;
};

/** aggregate fields of "track_type" */
export type Track_Type_Aggregate_Fields = {
  __typename?: 'track_type_aggregate_fields';
  avg?: Maybe<Track_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Track_Type_Max_Fields>;
  min?: Maybe<Track_Type_Min_Fields>;
  stddev?: Maybe<Track_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Track_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Track_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Track_Type_Sum_Fields>;
  var_pop?: Maybe<Track_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Track_Type_Var_Samp_Fields>;
  variance?: Maybe<Track_Type_Variance_Fields>;
};


/** aggregate fields of "track_type" */
export type Track_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Track_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Track_Type_Avg_Fields = {
  __typename?: 'track_type_avg_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "track_type". All fields are combined with a logical 'AND'. */
export type Track_Type_Bool_Exp = {
  _and?: Maybe<Array<Track_Type_Bool_Exp>>;
  _not?: Maybe<Track_Type_Bool_Exp>;
  _or?: Maybe<Array<Track_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  track_type_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "track_type" */
export enum Track_Type_Constraint {
  /** unique or primary key constraint */
  TrackTypePkey = 'track_type_pkey'
}

/** input type for incrementing numeric columns in table "track_type" */
export type Track_Type_Inc_Input = {
  track_type_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "track_type" */
export type Track_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Track_Type_Max_Fields = {
  __typename?: 'track_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Track_Type_Min_Fields = {
  __typename?: 'track_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "track_type" */
export type Track_Type_Mutation_Response = {
  __typename?: 'track_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Track_Type>;
};

/** input type for inserting object relation for remote table "track_type" */
export type Track_Type_Obj_Rel_Insert_Input = {
  data: Track_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Track_Type_On_Conflict>;
};

/** on_conflict condition type for table "track_type" */
export type Track_Type_On_Conflict = {
  constraint: Track_Type_Constraint;
  update_columns?: Array<Track_Type_Update_Column>;
  where?: Maybe<Track_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "track_type". */
export type Track_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: track_type */
export type Track_Type_Pk_Columns_Input = {
  track_type_id: Scalars['Int'];
};

/** select columns of table "track_type" */
export enum Track_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  TrackTypeId = 'track_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "track_type" */
export type Track_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  track_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Track_Type_Stddev_Fields = {
  __typename?: 'track_type_stddev_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Track_Type_Stddev_Pop_Fields = {
  __typename?: 'track_type_stddev_pop_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Track_Type_Stddev_Samp_Fields = {
  __typename?: 'track_type_stddev_samp_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Track_Type_Sum_Fields = {
  __typename?: 'track_type_sum_fields';
  track_type_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "track_type" */
export enum Track_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  TrackTypeId = 'track_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Track_Type_Var_Pop_Fields = {
  __typename?: 'track_type_var_pop_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Track_Type_Var_Samp_Fields = {
  __typename?: 'track_type_var_samp_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Track_Type_Variance_Fields = {
  __typename?: 'track_type_variance_fields';
  track_type_id?: Maybe<Scalars['Float']>;
};

/** update columns of table "track" */
export enum Track_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsrcId = 'isrc_id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  MasterlistId = 'masterlist_id',
  /** column name */
  Midi = 'midi',
  /** column name */
  MidiPath = 'midi_path',
  /** column name */
  Notes = 'notes',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  SongId = 'song_id',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  TrackTypeId = 'track_type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Track_Var_Pop_Fields = {
  __typename?: 'track_var_pop_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "track" */
export type Track_Var_Pop_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Track_Var_Samp_Fields = {
  __typename?: 'track_var_samp_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "track" */
export type Track_Var_Samp_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Track_Variance_Fields = {
  __typename?: 'track_variance_fields';
  masterlist_id?: Maybe<Scalars['Float']>;
  song_id?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  track_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "track" */
export type Track_Variance_Order_By = {
  masterlist_id?: Maybe<Order_By>;
  song_id?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  track_type_id?: Maybe<Order_By>;
};

/** The possible values for midi to transpose */
export type Transpose_Index = {
  __typename?: 'transpose_index';
  description?: Maybe<Scalars['String']>;
  /** also known as pitch */
  name: Scalars['String'];
};

/** aggregated selection of "transpose_index" */
export type Transpose_Index_Aggregate = {
  __typename?: 'transpose_index_aggregate';
  aggregate?: Maybe<Transpose_Index_Aggregate_Fields>;
  nodes: Array<Transpose_Index>;
};

/** aggregate fields of "transpose_index" */
export type Transpose_Index_Aggregate_Fields = {
  __typename?: 'transpose_index_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Transpose_Index_Max_Fields>;
  min?: Maybe<Transpose_Index_Min_Fields>;
};


/** aggregate fields of "transpose_index" */
export type Transpose_Index_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transpose_Index_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "transpose_index". All fields are combined with a logical 'AND'. */
export type Transpose_Index_Bool_Exp = {
  _and?: Maybe<Array<Transpose_Index_Bool_Exp>>;
  _not?: Maybe<Transpose_Index_Bool_Exp>;
  _or?: Maybe<Array<Transpose_Index_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transpose_index" */
export enum Transpose_Index_Constraint {
  /** unique or primary key constraint */
  TransposeIndexPkey = 'transpose_index_pkey'
}

/** input type for inserting data into table "transpose_index" */
export type Transpose_Index_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  /** also known as pitch */
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Transpose_Index_Max_Fields = {
  __typename?: 'transpose_index_max_fields';
  description?: Maybe<Scalars['String']>;
  /** also known as pitch */
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transpose_Index_Min_Fields = {
  __typename?: 'transpose_index_min_fields';
  description?: Maybe<Scalars['String']>;
  /** also known as pitch */
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transpose_index" */
export type Transpose_Index_Mutation_Response = {
  __typename?: 'transpose_index_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transpose_Index>;
};

/** on_conflict condition type for table "transpose_index" */
export type Transpose_Index_On_Conflict = {
  constraint: Transpose_Index_Constraint;
  update_columns?: Array<Transpose_Index_Update_Column>;
  where?: Maybe<Transpose_Index_Bool_Exp>;
};

/** Ordering options when selecting data from "transpose_index". */
export type Transpose_Index_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: transpose_index */
export type Transpose_Index_Pk_Columns_Input = {
  /** also known as pitch */
  name: Scalars['String'];
};

/** select columns of table "transpose_index" */
export enum Transpose_Index_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "transpose_index" */
export type Transpose_Index_Set_Input = {
  description?: Maybe<Scalars['String']>;
  /** also known as pitch */
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "transpose_index" */
export enum Transpose_Index_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  role_name?: Maybe<Scalars['String']>;
  wurrly_role?: Maybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  role_name?: Maybe<String_Comparison_Exp>;
  wurrly_role?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role_name?: Maybe<Scalars['String']>;
  wurrly_role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_path?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role_name?: Maybe<Scalars['String']>;
  wurrly_role?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  role_name?: Maybe<Order_By>;
  wurrly_role?: Maybe<Order_By>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  WurrlyRole = 'wurrly_role'
}

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "utils.avg_lessons" */
export type Utils_Avg_Lessons = {
  __typename?: 'utils_avg_lessons';
  avg_lessons?: Maybe<Scalars['Int']>;
  avg_modules?: Maybe<Scalars['Int']>;
  avg_sequences?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "utils.avg_lessons" */
export type Utils_Avg_Lessons_Aggregate = {
  __typename?: 'utils_avg_lessons_aggregate';
  aggregate?: Maybe<Utils_Avg_Lessons_Aggregate_Fields>;
  nodes: Array<Utils_Avg_Lessons>;
};

/** aggregate fields of "utils.avg_lessons" */
export type Utils_Avg_Lessons_Aggregate_Fields = {
  __typename?: 'utils_avg_lessons_aggregate_fields';
  avg?: Maybe<Utils_Avg_Lessons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Utils_Avg_Lessons_Max_Fields>;
  min?: Maybe<Utils_Avg_Lessons_Min_Fields>;
  stddev?: Maybe<Utils_Avg_Lessons_Stddev_Fields>;
  stddev_pop?: Maybe<Utils_Avg_Lessons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utils_Avg_Lessons_Stddev_Samp_Fields>;
  sum?: Maybe<Utils_Avg_Lessons_Sum_Fields>;
  var_pop?: Maybe<Utils_Avg_Lessons_Var_Pop_Fields>;
  var_samp?: Maybe<Utils_Avg_Lessons_Var_Samp_Fields>;
  variance?: Maybe<Utils_Avg_Lessons_Variance_Fields>;
};


/** aggregate fields of "utils.avg_lessons" */
export type Utils_Avg_Lessons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Utils_Avg_Lessons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Utils_Avg_Lessons_Avg_Fields = {
  __typename?: 'utils_avg_lessons_avg_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "utils.avg_lessons". All fields are combined with a logical 'AND'. */
export type Utils_Avg_Lessons_Bool_Exp = {
  _and?: Maybe<Array<Utils_Avg_Lessons_Bool_Exp>>;
  _not?: Maybe<Utils_Avg_Lessons_Bool_Exp>;
  _or?: Maybe<Array<Utils_Avg_Lessons_Bool_Exp>>;
  avg_lessons?: Maybe<Int_Comparison_Exp>;
  avg_modules?: Maybe<Int_Comparison_Exp>;
  avg_sequences?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Utils_Avg_Lessons_Max_Fields = {
  __typename?: 'utils_avg_lessons_max_fields';
  avg_lessons?: Maybe<Scalars['Int']>;
  avg_modules?: Maybe<Scalars['Int']>;
  avg_sequences?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Utils_Avg_Lessons_Min_Fields = {
  __typename?: 'utils_avg_lessons_min_fields';
  avg_lessons?: Maybe<Scalars['Int']>;
  avg_modules?: Maybe<Scalars['Int']>;
  avg_sequences?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "utils.avg_lessons". */
export type Utils_Avg_Lessons_Order_By = {
  avg_lessons?: Maybe<Order_By>;
  avg_modules?: Maybe<Order_By>;
  avg_sequences?: Maybe<Order_By>;
};

/** select columns of table "utils.avg_lessons" */
export enum Utils_Avg_Lessons_Select_Column {
  /** column name */
  AvgLessons = 'avg_lessons',
  /** column name */
  AvgModules = 'avg_modules',
  /** column name */
  AvgSequences = 'avg_sequences'
}

/** aggregate stddev on columns */
export type Utils_Avg_Lessons_Stddev_Fields = {
  __typename?: 'utils_avg_lessons_stddev_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Utils_Avg_Lessons_Stddev_Pop_Fields = {
  __typename?: 'utils_avg_lessons_stddev_pop_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Utils_Avg_Lessons_Stddev_Samp_Fields = {
  __typename?: 'utils_avg_lessons_stddev_samp_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Utils_Avg_Lessons_Sum_Fields = {
  __typename?: 'utils_avg_lessons_sum_fields';
  avg_lessons?: Maybe<Scalars['Int']>;
  avg_modules?: Maybe<Scalars['Int']>;
  avg_sequences?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Utils_Avg_Lessons_Var_Pop_Fields = {
  __typename?: 'utils_avg_lessons_var_pop_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Utils_Avg_Lessons_Var_Samp_Fields = {
  __typename?: 'utils_avg_lessons_var_samp_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Utils_Avg_Lessons_Variance_Fields = {
  __typename?: 'utils_avg_lessons_variance_fields';
  avg_lessons?: Maybe<Scalars['Float']>;
  avg_modules?: Maybe<Scalars['Float']>;
  avg_sequences?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes = {
  __typename?: 'utils_most_attended_classes';
  class_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Aggregate = {
  __typename?: 'utils_most_attended_classes_aggregate';
  aggregate?: Maybe<Utils_Most_Attended_Classes_Aggregate_Fields>;
  nodes: Array<Utils_Most_Attended_Classes>;
};

/** aggregate fields of "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Aggregate_Fields = {
  __typename?: 'utils_most_attended_classes_aggregate_fields';
  avg?: Maybe<Utils_Most_Attended_Classes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Utils_Most_Attended_Classes_Max_Fields>;
  min?: Maybe<Utils_Most_Attended_Classes_Min_Fields>;
  stddev?: Maybe<Utils_Most_Attended_Classes_Stddev_Fields>;
  stddev_pop?: Maybe<Utils_Most_Attended_Classes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utils_Most_Attended_Classes_Stddev_Samp_Fields>;
  sum?: Maybe<Utils_Most_Attended_Classes_Sum_Fields>;
  var_pop?: Maybe<Utils_Most_Attended_Classes_Var_Pop_Fields>;
  var_samp?: Maybe<Utils_Most_Attended_Classes_Var_Samp_Fields>;
  variance?: Maybe<Utils_Most_Attended_Classes_Variance_Fields>;
};


/** aggregate fields of "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Utils_Most_Attended_Classes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Utils_Most_Attended_Classes_Avg_Fields = {
  __typename?: 'utils_most_attended_classes_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "utils.most_attended_classes". All fields are combined with a logical 'AND'. */
export type Utils_Most_Attended_Classes_Bool_Exp = {
  _and?: Maybe<Array<Utils_Most_Attended_Classes_Bool_Exp>>;
  _not?: Maybe<Utils_Most_Attended_Classes_Bool_Exp>;
  _or?: Maybe<Array<Utils_Most_Attended_Classes_Bool_Exp>>;
  class_id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  total?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Insert_Input = {
  class_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Utils_Most_Attended_Classes_Max_Fields = {
  __typename?: 'utils_most_attended_classes_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Utils_Most_Attended_Classes_Min_Fields = {
  __typename?: 'utils_most_attended_classes_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Mutation_Response = {
  __typename?: 'utils_most_attended_classes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Utils_Most_Attended_Classes>;
};

/** Ordering options when selecting data from "utils.most_attended_classes". */
export type Utils_Most_Attended_Classes_Order_By = {
  class_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** select columns of table "utils.most_attended_classes" */
export enum Utils_Most_Attended_Classes_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Title = 'title',
  /** column name */
  Total = 'total'
}

/** input type for updating data in table "utils.most_attended_classes" */
export type Utils_Most_Attended_Classes_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Utils_Most_Attended_Classes_Stddev_Fields = {
  __typename?: 'utils_most_attended_classes_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Utils_Most_Attended_Classes_Stddev_Pop_Fields = {
  __typename?: 'utils_most_attended_classes_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Utils_Most_Attended_Classes_Stddev_Samp_Fields = {
  __typename?: 'utils_most_attended_classes_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Utils_Most_Attended_Classes_Sum_Fields = {
  __typename?: 'utils_most_attended_classes_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Utils_Most_Attended_Classes_Var_Pop_Fields = {
  __typename?: 'utils_most_attended_classes_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Utils_Most_Attended_Classes_Var_Samp_Fields = {
  __typename?: 'utils_most_attended_classes_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Utils_Most_Attended_Classes_Variance_Fields = {
  __typename?: 'utils_most_attended_classes_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects = {
  __typename?: 'utils_most_attended_subjects';
  class_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Aggregate = {
  __typename?: 'utils_most_attended_subjects_aggregate';
  aggregate?: Maybe<Utils_Most_Attended_Subjects_Aggregate_Fields>;
  nodes: Array<Utils_Most_Attended_Subjects>;
};

/** aggregate fields of "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Aggregate_Fields = {
  __typename?: 'utils_most_attended_subjects_aggregate_fields';
  avg?: Maybe<Utils_Most_Attended_Subjects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Utils_Most_Attended_Subjects_Max_Fields>;
  min?: Maybe<Utils_Most_Attended_Subjects_Min_Fields>;
  stddev?: Maybe<Utils_Most_Attended_Subjects_Stddev_Fields>;
  stddev_pop?: Maybe<Utils_Most_Attended_Subjects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utils_Most_Attended_Subjects_Stddev_Samp_Fields>;
  sum?: Maybe<Utils_Most_Attended_Subjects_Sum_Fields>;
  var_pop?: Maybe<Utils_Most_Attended_Subjects_Var_Pop_Fields>;
  var_samp?: Maybe<Utils_Most_Attended_Subjects_Var_Samp_Fields>;
  variance?: Maybe<Utils_Most_Attended_Subjects_Variance_Fields>;
};


/** aggregate fields of "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Utils_Most_Attended_Subjects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Utils_Most_Attended_Subjects_Avg_Fields = {
  __typename?: 'utils_most_attended_subjects_avg_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "utils.most_attended_subjects". All fields are combined with a logical 'AND'. */
export type Utils_Most_Attended_Subjects_Bool_Exp = {
  _and?: Maybe<Array<Utils_Most_Attended_Subjects_Bool_Exp>>;
  _not?: Maybe<Utils_Most_Attended_Subjects_Bool_Exp>;
  _or?: Maybe<Array<Utils_Most_Attended_Subjects_Bool_Exp>>;
  class_id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  total?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Inc_Input = {
  class_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Insert_Input = {
  class_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Utils_Most_Attended_Subjects_Max_Fields = {
  __typename?: 'utils_most_attended_subjects_max_fields';
  class_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Utils_Most_Attended_Subjects_Min_Fields = {
  __typename?: 'utils_most_attended_subjects_min_fields';
  class_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Mutation_Response = {
  __typename?: 'utils_most_attended_subjects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Utils_Most_Attended_Subjects>;
};

/** Ordering options when selecting data from "utils.most_attended_subjects". */
export type Utils_Most_Attended_Subjects_Order_By = {
  class_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  total?: Maybe<Order_By>;
};

/** select columns of table "utils.most_attended_subjects" */
export enum Utils_Most_Attended_Subjects_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Name = 'name',
  /** column name */
  Total = 'total'
}

/** input type for updating data in table "utils.most_attended_subjects" */
export type Utils_Most_Attended_Subjects_Set_Input = {
  class_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Utils_Most_Attended_Subjects_Stddev_Fields = {
  __typename?: 'utils_most_attended_subjects_stddev_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Utils_Most_Attended_Subjects_Stddev_Pop_Fields = {
  __typename?: 'utils_most_attended_subjects_stddev_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Utils_Most_Attended_Subjects_Stddev_Samp_Fields = {
  __typename?: 'utils_most_attended_subjects_stddev_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Utils_Most_Attended_Subjects_Sum_Fields = {
  __typename?: 'utils_most_attended_subjects_sum_fields';
  class_id?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Utils_Most_Attended_Subjects_Var_Pop_Fields = {
  __typename?: 'utils_most_attended_subjects_var_pop_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Utils_Most_Attended_Subjects_Var_Samp_Fields = {
  __typename?: 'utils_most_attended_subjects_var_samp_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Utils_Most_Attended_Subjects_Variance_Fields = {
  __typename?: 'utils_most_attended_subjects_variance_fields';
  class_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type Utils_Search_Last_Classes_Args = {
  initial_date?: Maybe<Scalars['timestamp']>;
};

export type Utils_Search_New_Users_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  initial_date?: Maybe<Scalars['timestamp']>;
};

export type Utils_Search_Teacher_Subscriptions_Args = {
  end_date?: Maybe<Scalars['timestamp']>;
  initial_date?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month = {
  __typename?: 'utils_teacher_subscriptions_per_month';
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

export type Utils_Teacher_Subscriptions_Per_Month_Aggregate = {
  __typename?: 'utils_teacher_subscriptions_per_month_aggregate';
  aggregate?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Aggregate_Fields>;
  nodes: Array<Utils_Teacher_Subscriptions_Per_Month>;
};

/** aggregate fields of "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month_Aggregate_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_aggregate_fields';
  avg?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Max_Fields>;
  min?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Min_Fields>;
  stddev?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Sum_Fields>;
  var_pop?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Var_Samp_Fields>;
  variance?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Variance_Fields>;
};


/** aggregate fields of "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Avg_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_avg_fields';
  users?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "utils.teacher_subscriptions_per_month". All fields are combined with a logical 'AND'. */
export type Utils_Teacher_Subscriptions_Per_Month_Bool_Exp = {
  _and?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>>;
  _not?: Maybe<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>;
  _or?: Maybe<Array<Utils_Teacher_Subscriptions_Per_Month_Bool_Exp>>;
  month?: Maybe<Timestamp_Comparison_Exp>;
  users?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month_Inc_Input = {
  users?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month_Insert_Input = {
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Max_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_max_fields';
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Min_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_min_fields';
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month_Mutation_Response = {
  __typename?: 'utils_teacher_subscriptions_per_month_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Utils_Teacher_Subscriptions_Per_Month>;
};

/** Ordering options when selecting data from "utils.teacher_subscriptions_per_month". */
export type Utils_Teacher_Subscriptions_Per_Month_Order_By = {
  month?: Maybe<Order_By>;
  users?: Maybe<Order_By>;
};

/** select columns of table "utils.teacher_subscriptions_per_month" */
export enum Utils_Teacher_Subscriptions_Per_Month_Select_Column {
  /** column name */
  Month = 'month',
  /** column name */
  Users = 'users'
}

/** input type for updating data in table "utils.teacher_subscriptions_per_month" */
export type Utils_Teacher_Subscriptions_Per_Month_Set_Input = {
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Stddev_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_stddev_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Stddev_Pop_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_stddev_pop_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Stddev_Samp_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_stddev_samp_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Sum_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_sum_fields';
  users?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Var_Pop_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_var_pop_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Var_Samp_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_var_samp_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Utils_Teacher_Subscriptions_Per_Month_Variance_Fields = {
  __typename?: 'utils_teacher_subscriptions_per_month_variance_fields';
  users?: Maybe<Scalars['Float']>;
};

export type Utils_Update_District_Lockable_Content_Modules_And_Lessons_Function_Args = {
  _district_id?: Maybe<Scalars['Int']>;
  _module_ids?: Maybe<Scalars['_int8']>;
};

export type Utils_Update_School_Lockable_Content_Modules_And_Lessons_Function_Args = {
  _module_ids?: Maybe<Scalars['_int8']>;
  _school_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "utils.users_per_month" */
export type Utils_Users_Per_Month = {
  __typename?: 'utils_users_per_month';
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

export type Utils_Users_Per_Month_Aggregate = {
  __typename?: 'utils_users_per_month_aggregate';
  aggregate?: Maybe<Utils_Users_Per_Month_Aggregate_Fields>;
  nodes: Array<Utils_Users_Per_Month>;
};

/** aggregate fields of "utils.users_per_month" */
export type Utils_Users_Per_Month_Aggregate_Fields = {
  __typename?: 'utils_users_per_month_aggregate_fields';
  avg?: Maybe<Utils_Users_Per_Month_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Utils_Users_Per_Month_Max_Fields>;
  min?: Maybe<Utils_Users_Per_Month_Min_Fields>;
  stddev?: Maybe<Utils_Users_Per_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Utils_Users_Per_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Utils_Users_Per_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Utils_Users_Per_Month_Sum_Fields>;
  var_pop?: Maybe<Utils_Users_Per_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Utils_Users_Per_Month_Var_Samp_Fields>;
  variance?: Maybe<Utils_Users_Per_Month_Variance_Fields>;
};


/** aggregate fields of "utils.users_per_month" */
export type Utils_Users_Per_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Utils_Users_Per_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Utils_Users_Per_Month_Avg_Fields = {
  __typename?: 'utils_users_per_month_avg_fields';
  users?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "utils.users_per_month". All fields are combined with a logical 'AND'. */
export type Utils_Users_Per_Month_Bool_Exp = {
  _and?: Maybe<Array<Utils_Users_Per_Month_Bool_Exp>>;
  _not?: Maybe<Utils_Users_Per_Month_Bool_Exp>;
  _or?: Maybe<Array<Utils_Users_Per_Month_Bool_Exp>>;
  month?: Maybe<Timestamp_Comparison_Exp>;
  users?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "utils.users_per_month" */
export type Utils_Users_Per_Month_Inc_Input = {
  users?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "utils.users_per_month" */
export type Utils_Users_Per_Month_Insert_Input = {
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Utils_Users_Per_Month_Max_Fields = {
  __typename?: 'utils_users_per_month_max_fields';
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Utils_Users_Per_Month_Min_Fields = {
  __typename?: 'utils_users_per_month_min_fields';
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "utils.users_per_month" */
export type Utils_Users_Per_Month_Mutation_Response = {
  __typename?: 'utils_users_per_month_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Utils_Users_Per_Month>;
};

/** Ordering options when selecting data from "utils.users_per_month". */
export type Utils_Users_Per_Month_Order_By = {
  month?: Maybe<Order_By>;
  users?: Maybe<Order_By>;
};

/** select columns of table "utils.users_per_month" */
export enum Utils_Users_Per_Month_Select_Column {
  /** column name */
  Month = 'month',
  /** column name */
  Users = 'users'
}

/** input type for updating data in table "utils.users_per_month" */
export type Utils_Users_Per_Month_Set_Input = {
  month?: Maybe<Scalars['timestamp']>;
  users?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Utils_Users_Per_Month_Stddev_Fields = {
  __typename?: 'utils_users_per_month_stddev_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Utils_Users_Per_Month_Stddev_Pop_Fields = {
  __typename?: 'utils_users_per_month_stddev_pop_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Utils_Users_Per_Month_Stddev_Samp_Fields = {
  __typename?: 'utils_users_per_month_stddev_samp_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Utils_Users_Per_Month_Sum_Fields = {
  __typename?: 'utils_users_per_month_sum_fields';
  users?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Utils_Users_Per_Month_Var_Pop_Fields = {
  __typename?: 'utils_users_per_month_var_pop_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Utils_Users_Per_Month_Var_Samp_Fields = {
  __typename?: 'utils_users_per_month_var_samp_fields';
  users?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Utils_Users_Per_Month_Variance_Fields = {
  __typename?: 'utils_users_per_month_variance_fields';
  users?: Maybe<Scalars['Float']>;
};

export type ValidatePromotionCodeFilter = {
  promotionCode: Scalars['String'];
};

export type ValidatePromotionCodeResponse = {
  __typename?: 'validatePromotionCodeResponse';
  discount_amount_off?: Maybe<Scalars['Int']>;
  discount_percent_off?: Maybe<Scalars['Int']>;
  isValid: Scalars['Boolean'];
};

/** columns and relationships of "visibility" */
export type Visibility = {
  __typename?: 'visibility';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "visibility" */
export type Visibility_Aggregate = {
  __typename?: 'visibility_aggregate';
  aggregate?: Maybe<Visibility_Aggregate_Fields>;
  nodes: Array<Visibility>;
};

/** aggregate fields of "visibility" */
export type Visibility_Aggregate_Fields = {
  __typename?: 'visibility_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Visibility_Max_Fields>;
  min?: Maybe<Visibility_Min_Fields>;
};


/** aggregate fields of "visibility" */
export type Visibility_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Visibility_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "visibility". All fields are combined with a logical 'AND'. */
export type Visibility_Bool_Exp = {
  _and?: Maybe<Array<Visibility_Bool_Exp>>;
  _not?: Maybe<Visibility_Bool_Exp>;
  _or?: Maybe<Array<Visibility_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "visibility" */
export enum Visibility_Constraint {
  /** unique or primary key constraint */
  VisibilityPkey = 'visibility_pkey'
}

export enum Visibility_Enum {
  /** Admin Only */
  AdminOnly = 'admin_only',
  /** This product is listed for sale */
  ForSale = 'for_sale',
  /** Private data access */
  Private = 'private',
  /** Public data access */
  Public = 'public',
  /** Restricted to certain teacher id, class id, school id or district id */
  Restricted = 'restricted'
}

/** Boolean expression to compare columns of type "visibility_enum". All fields are combined with logical 'AND'. */
export type Visibility_Enum_Comparison_Exp = {
  _eq?: Maybe<Visibility_Enum>;
  _in?: Maybe<Array<Visibility_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Visibility_Enum>;
  _nin?: Maybe<Array<Visibility_Enum>>;
};

/** input type for inserting data into table "visibility" */
export type Visibility_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Visibility_Max_Fields = {
  __typename?: 'visibility_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Visibility_Min_Fields = {
  __typename?: 'visibility_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "visibility" */
export type Visibility_Mutation_Response = {
  __typename?: 'visibility_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Visibility>;
};

/** on_conflict condition type for table "visibility" */
export type Visibility_On_Conflict = {
  constraint: Visibility_Constraint;
  update_columns?: Array<Visibility_Update_Column>;
  where?: Maybe<Visibility_Bool_Exp>;
};

/** Ordering options when selecting data from "visibility". */
export type Visibility_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: visibility */
export type Visibility_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "visibility" */
export enum Visibility_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "visibility" */
export type Visibility_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "visibility" */
export enum Visibility_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "wurrly" */
export type Wurrly = {
  __typename?: 'wurrly';
  audio_balance: Scalars['Int'];
  audio_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  challenge?: Maybe<Challenge>;
  challenge_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  class: Class;
  class_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  filters?: Maybe<Wurrly_Filter>;
  final_resource_path?: Maybe<Scalars['String']>;
  image_path: Scalars['String'];
  is_from_legacy: Scalars['Boolean'];
  is_mirrored: Scalars['Boolean'];
  /** A computed field, executes function "is_video_url" */
  is_video_url?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  privacy: Privacy_Enum;
  processing_status: Wurrly_Processing_Status_Enum;
  resource_path: Scalars['String'];
  /** An object relationship */
  student: Student;
  student_id_owner: Scalars['Int'];
  student_id_secondary?: Maybe<Scalars['Int']>;
  /** An object relationship */
  submission_wurrly?: Maybe<Submission_Wurrly>;
  /** An array relationship */
  teacher_wurrly_comments: Array<Teacher_Wurrly_Comment>;
  /** An aggregate relationship */
  teacher_wurrly_comments_aggregate: Teacher_Wurrly_Comment_Aggregate;
  /** An object relationship */
  track: Track;
  track_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id: Scalars['Int'];
  /** An array relationship */
  wurrly_reactions: Array<Wurrly_Reaction>;
  /** An aggregate relationship */
  wurrly_reactions_aggregate: Wurrly_Reaction_Aggregate;
  wurrly_type: Wurrly_Type_Enum;
};


/** columns and relationships of "wurrly" */
export type WurrlyTeacher_Wurrly_CommentsArgs = {
  distinct_on?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Wurrly_Comment_Order_By>>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};


/** columns and relationships of "wurrly" */
export type WurrlyTeacher_Wurrly_Comments_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Wurrly_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Wurrly_Comment_Order_By>>;
  where?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
};


/** columns and relationships of "wurrly" */
export type WurrlyWurrly_ReactionsArgs = {
  distinct_on?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Reaction_Order_By>>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};


/** columns and relationships of "wurrly" */
export type WurrlyWurrly_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Reaction_Order_By>>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};

/** aggregated selection of "wurrly" */
export type Wurrly_Aggregate = {
  __typename?: 'wurrly_aggregate';
  aggregate?: Maybe<Wurrly_Aggregate_Fields>;
  nodes: Array<Wurrly>;
};

/** aggregate fields of "wurrly" */
export type Wurrly_Aggregate_Fields = {
  __typename?: 'wurrly_aggregate_fields';
  avg?: Maybe<Wurrly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Max_Fields>;
  min?: Maybe<Wurrly_Min_Fields>;
  stddev?: Maybe<Wurrly_Stddev_Fields>;
  stddev_pop?: Maybe<Wurrly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wurrly_Stddev_Samp_Fields>;
  sum?: Maybe<Wurrly_Sum_Fields>;
  var_pop?: Maybe<Wurrly_Var_Pop_Fields>;
  var_samp?: Maybe<Wurrly_Var_Samp_Fields>;
  variance?: Maybe<Wurrly_Variance_Fields>;
};


/** aggregate fields of "wurrly" */
export type Wurrly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wurrly" */
export type Wurrly_Aggregate_Order_By = {
  avg?: Maybe<Wurrly_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wurrly_Max_Order_By>;
  min?: Maybe<Wurrly_Min_Order_By>;
  stddev?: Maybe<Wurrly_Stddev_Order_By>;
  stddev_pop?: Maybe<Wurrly_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wurrly_Stddev_Samp_Order_By>;
  sum?: Maybe<Wurrly_Sum_Order_By>;
  var_pop?: Maybe<Wurrly_Var_Pop_Order_By>;
  var_samp?: Maybe<Wurrly_Var_Samp_Order_By>;
  variance?: Maybe<Wurrly_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wurrly" */
export type Wurrly_Arr_Rel_Insert_Input = {
  data: Array<Wurrly_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wurrly_On_Conflict>;
};

/** aggregate avg on columns */
export type Wurrly_Avg_Fields = {
  __typename?: 'wurrly_avg_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wurrly" */
export type Wurrly_Avg_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wurrly". All fields are combined with a logical 'AND'. */
export type Wurrly_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Bool_Exp>>;
  _not?: Maybe<Wurrly_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Bool_Exp>>;
  audio_balance?: Maybe<Int_Comparison_Exp>;
  audio_url?: Maybe<String_Comparison_Exp>;
  challenge?: Maybe<Challenge_Bool_Exp>;
  challenge_id?: Maybe<Int_Comparison_Exp>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  filters?: Maybe<Wurrly_Filter_Bool_Exp>;
  final_resource_path?: Maybe<String_Comparison_Exp>;
  image_path?: Maybe<String_Comparison_Exp>;
  is_from_legacy?: Maybe<Boolean_Comparison_Exp>;
  is_mirrored?: Maybe<Boolean_Comparison_Exp>;
  is_video_url?: Maybe<Boolean_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  privacy?: Maybe<Privacy_Enum_Comparison_Exp>;
  processing_status?: Maybe<Wurrly_Processing_Status_Enum_Comparison_Exp>;
  resource_path?: Maybe<String_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id_owner?: Maybe<Int_Comparison_Exp>;
  student_id_secondary?: Maybe<Int_Comparison_Exp>;
  submission_wurrly?: Maybe<Submission_Wurrly_Bool_Exp>;
  teacher_wurrly_comments?: Maybe<Teacher_Wurrly_Comment_Bool_Exp>;
  track?: Maybe<Track_Bool_Exp>;
  track_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
  wurrly_reactions?: Maybe<Wurrly_Reaction_Bool_Exp>;
  wurrly_type?: Maybe<Wurrly_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "wurrly" */
export enum Wurrly_Constraint {
  /** unique or primary key constraint */
  WurrlyPkey = 'wurrly_pkey'
}

/** columns and relationships of "wurrly_filter" */
export type Wurrly_Filter = {
  __typename?: 'wurrly_filter';
  /** An object relationship */
  color?: Maybe<Filter_Color>;
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  frame?: Maybe<Filter_Frame>;
  /** An array relationship */
  stickers: Array<Wurrly_Filter_Sticker>;
  /** An aggregate relationship */
  stickers_aggregate: Wurrly_Filter_Sticker_Aggregate;
  wurrly_id: Scalars['Int'];
};


/** columns and relationships of "wurrly_filter" */
export type Wurrly_FilterStickersArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Sticker_Order_By>>;
  where?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
};


/** columns and relationships of "wurrly_filter" */
export type Wurrly_FilterStickers_AggregateArgs = {
  distinct_on?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wurrly_Filter_Sticker_Order_By>>;
  where?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
};

/** aggregated selection of "wurrly_filter" */
export type Wurrly_Filter_Aggregate = {
  __typename?: 'wurrly_filter_aggregate';
  aggregate?: Maybe<Wurrly_Filter_Aggregate_Fields>;
  nodes: Array<Wurrly_Filter>;
};

/** aggregate fields of "wurrly_filter" */
export type Wurrly_Filter_Aggregate_Fields = {
  __typename?: 'wurrly_filter_aggregate_fields';
  avg?: Maybe<Wurrly_Filter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Filter_Max_Fields>;
  min?: Maybe<Wurrly_Filter_Min_Fields>;
  stddev?: Maybe<Wurrly_Filter_Stddev_Fields>;
  stddev_pop?: Maybe<Wurrly_Filter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wurrly_Filter_Stddev_Samp_Fields>;
  sum?: Maybe<Wurrly_Filter_Sum_Fields>;
  var_pop?: Maybe<Wurrly_Filter_Var_Pop_Fields>;
  var_samp?: Maybe<Wurrly_Filter_Var_Samp_Fields>;
  variance?: Maybe<Wurrly_Filter_Variance_Fields>;
};


/** aggregate fields of "wurrly_filter" */
export type Wurrly_Filter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Filter_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wurrly_Filter_Avg_Fields = {
  __typename?: 'wurrly_filter_avg_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wurrly_filter". All fields are combined with a logical 'AND'. */
export type Wurrly_Filter_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Filter_Bool_Exp>>;
  _not?: Maybe<Wurrly_Filter_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Filter_Bool_Exp>>;
  color?: Maybe<Filter_Color_Bool_Exp>;
  filter_color_id?: Maybe<Int_Comparison_Exp>;
  filter_frame_id?: Maybe<Int_Comparison_Exp>;
  frame?: Maybe<Filter_Frame_Bool_Exp>;
  stickers?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "wurrly_filter" */
export enum Wurrly_Filter_Constraint {
  /** unique or primary key constraint */
  WurrlyFilterPkey = 'wurrly_filter_pkey'
}

/** input type for incrementing numeric columns in table "wurrly_filter" */
export type Wurrly_Filter_Inc_Input = {
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wurrly_filter" */
export type Wurrly_Filter_Insert_Input = {
  color?: Maybe<Filter_Color_Obj_Rel_Insert_Input>;
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  frame?: Maybe<Filter_Frame_Obj_Rel_Insert_Input>;
  stickers?: Maybe<Wurrly_Filter_Sticker_Arr_Rel_Insert_Input>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Wurrly_Filter_Max_Fields = {
  __typename?: 'wurrly_filter_max_fields';
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Wurrly_Filter_Min_Fields = {
  __typename?: 'wurrly_filter_min_fields';
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "wurrly_filter" */
export type Wurrly_Filter_Mutation_Response = {
  __typename?: 'wurrly_filter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly_Filter>;
};

/** input type for inserting object relation for remote table "wurrly_filter" */
export type Wurrly_Filter_Obj_Rel_Insert_Input = {
  data: Wurrly_Filter_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Wurrly_Filter_On_Conflict>;
};

/** on_conflict condition type for table "wurrly_filter" */
export type Wurrly_Filter_On_Conflict = {
  constraint: Wurrly_Filter_Constraint;
  update_columns?: Array<Wurrly_Filter_Update_Column>;
  where?: Maybe<Wurrly_Filter_Bool_Exp>;
};

/** Ordering options when selecting data from "wurrly_filter". */
export type Wurrly_Filter_Order_By = {
  color?: Maybe<Filter_Color_Order_By>;
  filter_color_id?: Maybe<Order_By>;
  filter_frame_id?: Maybe<Order_By>;
  frame?: Maybe<Filter_Frame_Order_By>;
  stickers_aggregate?: Maybe<Wurrly_Filter_Sticker_Aggregate_Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** primary key columns input for table: wurrly_filter */
export type Wurrly_Filter_Pk_Columns_Input = {
  wurrly_id: Scalars['Int'];
};

/** select columns of table "wurrly_filter" */
export enum Wurrly_Filter_Select_Column {
  /** column name */
  FilterColorId = 'filter_color_id',
  /** column name */
  FilterFrameId = 'filter_frame_id',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** input type for updating data in table "wurrly_filter" */
export type Wurrly_Filter_Set_Input = {
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Wurrly_Filter_Stddev_Fields = {
  __typename?: 'wurrly_filter_stddev_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wurrly_Filter_Stddev_Pop_Fields = {
  __typename?: 'wurrly_filter_stddev_pop_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wurrly_Filter_Stddev_Samp_Fields = {
  __typename?: 'wurrly_filter_stddev_samp_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker = {
  __typename?: 'wurrly_filter_sticker';
  /** An object relationship */
  filter_sticker: Filter_Sticker;
  filter_sticker_id: Scalars['Int'];
  wurrly_id: Scalars['Int'];
};

/** aggregated selection of "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Aggregate = {
  __typename?: 'wurrly_filter_sticker_aggregate';
  aggregate?: Maybe<Wurrly_Filter_Sticker_Aggregate_Fields>;
  nodes: Array<Wurrly_Filter_Sticker>;
};

/** aggregate fields of "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Aggregate_Fields = {
  __typename?: 'wurrly_filter_sticker_aggregate_fields';
  avg?: Maybe<Wurrly_Filter_Sticker_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Filter_Sticker_Max_Fields>;
  min?: Maybe<Wurrly_Filter_Sticker_Min_Fields>;
  stddev?: Maybe<Wurrly_Filter_Sticker_Stddev_Fields>;
  stddev_pop?: Maybe<Wurrly_Filter_Sticker_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wurrly_Filter_Sticker_Stddev_Samp_Fields>;
  sum?: Maybe<Wurrly_Filter_Sticker_Sum_Fields>;
  var_pop?: Maybe<Wurrly_Filter_Sticker_Var_Pop_Fields>;
  var_samp?: Maybe<Wurrly_Filter_Sticker_Var_Samp_Fields>;
  variance?: Maybe<Wurrly_Filter_Sticker_Variance_Fields>;
};


/** aggregate fields of "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Filter_Sticker_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Aggregate_Order_By = {
  avg?: Maybe<Wurrly_Filter_Sticker_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wurrly_Filter_Sticker_Max_Order_By>;
  min?: Maybe<Wurrly_Filter_Sticker_Min_Order_By>;
  stddev?: Maybe<Wurrly_Filter_Sticker_Stddev_Order_By>;
  stddev_pop?: Maybe<Wurrly_Filter_Sticker_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wurrly_Filter_Sticker_Stddev_Samp_Order_By>;
  sum?: Maybe<Wurrly_Filter_Sticker_Sum_Order_By>;
  var_pop?: Maybe<Wurrly_Filter_Sticker_Var_Pop_Order_By>;
  var_samp?: Maybe<Wurrly_Filter_Sticker_Var_Samp_Order_By>;
  variance?: Maybe<Wurrly_Filter_Sticker_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Arr_Rel_Insert_Input = {
  data: Array<Wurrly_Filter_Sticker_Insert_Input>;
};

/** aggregate avg on columns */
export type Wurrly_Filter_Sticker_Avg_Fields = {
  __typename?: 'wurrly_filter_sticker_avg_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Avg_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wurrly_filter_sticker". All fields are combined with a logical 'AND'. */
export type Wurrly_Filter_Sticker_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Filter_Sticker_Bool_Exp>>;
  _not?: Maybe<Wurrly_Filter_Sticker_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Filter_Sticker_Bool_Exp>>;
  filter_sticker?: Maybe<Filter_Sticker_Bool_Exp>;
  filter_sticker_id?: Maybe<Int_Comparison_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Inc_Input = {
  filter_sticker_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Insert_Input = {
  filter_sticker?: Maybe<Filter_Sticker_Obj_Rel_Insert_Input>;
  filter_sticker_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Wurrly_Filter_Sticker_Max_Fields = {
  __typename?: 'wurrly_filter_sticker_max_fields';
  filter_sticker_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Max_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wurrly_Filter_Sticker_Min_Fields = {
  __typename?: 'wurrly_filter_sticker_min_fields';
  filter_sticker_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Min_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Mutation_Response = {
  __typename?: 'wurrly_filter_sticker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly_Filter_Sticker>;
};

/** Ordering options when selecting data from "wurrly_filter_sticker". */
export type Wurrly_Filter_Sticker_Order_By = {
  filter_sticker?: Maybe<Filter_Sticker_Order_By>;
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** select columns of table "wurrly_filter_sticker" */
export enum Wurrly_Filter_Sticker_Select_Column {
  /** column name */
  FilterStickerId = 'filter_sticker_id',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** input type for updating data in table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Set_Input = {
  filter_sticker_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Wurrly_Filter_Sticker_Stddev_Fields = {
  __typename?: 'wurrly_filter_sticker_stddev_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Stddev_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wurrly_Filter_Sticker_Stddev_Pop_Fields = {
  __typename?: 'wurrly_filter_sticker_stddev_pop_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Stddev_Pop_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wurrly_Filter_Sticker_Stddev_Samp_Fields = {
  __typename?: 'wurrly_filter_sticker_stddev_samp_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Stddev_Samp_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wurrly_Filter_Sticker_Sum_Fields = {
  __typename?: 'wurrly_filter_sticker_sum_fields';
  filter_sticker_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Sum_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Wurrly_Filter_Sticker_Var_Pop_Fields = {
  __typename?: 'wurrly_filter_sticker_var_pop_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Var_Pop_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wurrly_Filter_Sticker_Var_Samp_Fields = {
  __typename?: 'wurrly_filter_sticker_var_samp_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Var_Samp_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wurrly_Filter_Sticker_Variance_Fields = {
  __typename?: 'wurrly_filter_sticker_variance_fields';
  filter_sticker_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wurrly_filter_sticker" */
export type Wurrly_Filter_Sticker_Variance_Order_By = {
  filter_sticker_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wurrly_Filter_Sum_Fields = {
  __typename?: 'wurrly_filter_sum_fields';
  filter_color_id?: Maybe<Scalars['Int']>;
  filter_frame_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "wurrly_filter" */
export enum Wurrly_Filter_Update_Column {
  /** column name */
  FilterColorId = 'filter_color_id',
  /** column name */
  FilterFrameId = 'filter_frame_id',
  /** column name */
  WurrlyId = 'wurrly_id'
}

/** aggregate var_pop on columns */
export type Wurrly_Filter_Var_Pop_Fields = {
  __typename?: 'wurrly_filter_var_pop_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wurrly_Filter_Var_Samp_Fields = {
  __typename?: 'wurrly_filter_var_samp_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wurrly_Filter_Variance_Fields = {
  __typename?: 'wurrly_filter_variance_fields';
  filter_color_id?: Maybe<Scalars['Float']>;
  filter_frame_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "wurrly" */
export type Wurrly_Inc_Input = {
  audio_balance?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  student_id_owner?: Maybe<Scalars['Int']>;
  student_id_secondary?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wurrly" */
export type Wurrly_Insert_Input = {
  audio_balance?: Maybe<Scalars['Int']>;
  audio_url?: Maybe<Scalars['String']>;
  challenge?: Maybe<Challenge_Obj_Rel_Insert_Input>;
  challenge_id?: Maybe<Scalars['Int']>;
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  filters?: Maybe<Wurrly_Filter_Obj_Rel_Insert_Input>;
  final_resource_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  is_from_legacy?: Maybe<Scalars['Boolean']>;
  is_mirrored?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  privacy?: Maybe<Privacy_Enum>;
  processing_status?: Maybe<Wurrly_Processing_Status_Enum>;
  resource_path?: Maybe<Scalars['String']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id_owner?: Maybe<Scalars['Int']>;
  student_id_secondary?: Maybe<Scalars['Int']>;
  submission_wurrly?: Maybe<Submission_Wurrly_Obj_Rel_Insert_Input>;
  teacher_wurrly_comments?: Maybe<Teacher_Wurrly_Comment_Arr_Rel_Insert_Input>;
  track?: Maybe<Track_Obj_Rel_Insert_Input>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reactions?: Maybe<Wurrly_Reaction_Arr_Rel_Insert_Input>;
  wurrly_type?: Maybe<Wurrly_Type_Enum>;
};

/** aggregate max on columns */
export type Wurrly_Max_Fields = {
  __typename?: 'wurrly_max_fields';
  audio_balance?: Maybe<Scalars['Int']>;
  audio_url?: Maybe<Scalars['String']>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  final_resource_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  student_id_owner?: Maybe<Scalars['Int']>;
  student_id_secondary?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "wurrly" */
export type Wurrly_Max_Order_By = {
  audio_balance?: Maybe<Order_By>;
  audio_url?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  final_resource_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wurrly_Min_Fields = {
  __typename?: 'wurrly_min_fields';
  audio_balance?: Maybe<Scalars['Int']>;
  audio_url?: Maybe<Scalars['String']>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  final_resource_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  resource_path?: Maybe<Scalars['String']>;
  student_id_owner?: Maybe<Scalars['Int']>;
  student_id_secondary?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "wurrly" */
export type Wurrly_Min_Order_By = {
  audio_balance?: Maybe<Order_By>;
  audio_url?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  final_resource_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wurrly" */
export type Wurrly_Mutation_Response = {
  __typename?: 'wurrly_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly>;
};

/** input type for inserting object relation for remote table "wurrly" */
export type Wurrly_Obj_Rel_Insert_Input = {
  data: Wurrly_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Wurrly_On_Conflict>;
};

/** on_conflict condition type for table "wurrly" */
export type Wurrly_On_Conflict = {
  constraint: Wurrly_Constraint;
  update_columns?: Array<Wurrly_Update_Column>;
  where?: Maybe<Wurrly_Bool_Exp>;
};

/** Ordering options when selecting data from "wurrly". */
export type Wurrly_Order_By = {
  audio_balance?: Maybe<Order_By>;
  audio_url?: Maybe<Order_By>;
  challenge?: Maybe<Challenge_Order_By>;
  challenge_id?: Maybe<Order_By>;
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  filters?: Maybe<Wurrly_Filter_Order_By>;
  final_resource_path?: Maybe<Order_By>;
  image_path?: Maybe<Order_By>;
  is_from_legacy?: Maybe<Order_By>;
  is_mirrored?: Maybe<Order_By>;
  is_video_url?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  privacy?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  resource_path?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  submission_wurrly?: Maybe<Submission_Wurrly_Order_By>;
  teacher_wurrly_comments_aggregate?: Maybe<Teacher_Wurrly_Comment_Aggregate_Order_By>;
  track?: Maybe<Track_Order_By>;
  track_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reactions_aggregate?: Maybe<Wurrly_Reaction_Aggregate_Order_By>;
  wurrly_type?: Maybe<Order_By>;
};

/** primary key columns input for table: wurrly */
export type Wurrly_Pk_Columns_Input = {
  wurrly_id: Scalars['Int'];
};

/** columns and relationships of "wurrly_processing_status" */
export type Wurrly_Processing_Status = {
  __typename?: 'wurrly_processing_status';
  name: Scalars['String'];
};

/** aggregated selection of "wurrly_processing_status" */
export type Wurrly_Processing_Status_Aggregate = {
  __typename?: 'wurrly_processing_status_aggregate';
  aggregate?: Maybe<Wurrly_Processing_Status_Aggregate_Fields>;
  nodes: Array<Wurrly_Processing_Status>;
};

/** aggregate fields of "wurrly_processing_status" */
export type Wurrly_Processing_Status_Aggregate_Fields = {
  __typename?: 'wurrly_processing_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Processing_Status_Max_Fields>;
  min?: Maybe<Wurrly_Processing_Status_Min_Fields>;
};


/** aggregate fields of "wurrly_processing_status" */
export type Wurrly_Processing_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Processing_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wurrly_processing_status". All fields are combined with a logical 'AND'. */
export type Wurrly_Processing_Status_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Processing_Status_Bool_Exp>>;
  _not?: Maybe<Wurrly_Processing_Status_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Processing_Status_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "wurrly_processing_status" */
export enum Wurrly_Processing_Status_Constraint {
  /** unique or primary key constraint */
  WurrlyProcessingStausPkey = 'wurrly_processing_staus_pkey'
}

export enum Wurrly_Processing_Status_Enum {
  Done = 'done',
  Error = 'error',
  InProgress = 'in_progress',
  NotStarted = 'not_started'
}

/** Boolean expression to compare columns of type "wurrly_processing_status_enum". All fields are combined with logical 'AND'. */
export type Wurrly_Processing_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Wurrly_Processing_Status_Enum>;
  _in?: Maybe<Array<Wurrly_Processing_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wurrly_Processing_Status_Enum>;
  _nin?: Maybe<Array<Wurrly_Processing_Status_Enum>>;
};

/** input type for inserting data into table "wurrly_processing_status" */
export type Wurrly_Processing_Status_Insert_Input = {
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wurrly_Processing_Status_Max_Fields = {
  __typename?: 'wurrly_processing_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wurrly_Processing_Status_Min_Fields = {
  __typename?: 'wurrly_processing_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wurrly_processing_status" */
export type Wurrly_Processing_Status_Mutation_Response = {
  __typename?: 'wurrly_processing_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly_Processing_Status>;
};

/** on_conflict condition type for table "wurrly_processing_status" */
export type Wurrly_Processing_Status_On_Conflict = {
  constraint: Wurrly_Processing_Status_Constraint;
  update_columns?: Array<Wurrly_Processing_Status_Update_Column>;
  where?: Maybe<Wurrly_Processing_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "wurrly_processing_status". */
export type Wurrly_Processing_Status_Order_By = {
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: wurrly_processing_status */
export type Wurrly_Processing_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "wurrly_processing_status" */
export enum Wurrly_Processing_Status_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "wurrly_processing_status" */
export type Wurrly_Processing_Status_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "wurrly_processing_status" */
export enum Wurrly_Processing_Status_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "wurrly_reaction" */
export type Wurrly_Reaction = {
  __typename?: 'wurrly_reaction';
  reaction_id: Scalars['Int'];
  /** An object relationship */
  student: Student;
  student_id: Scalars['Int'];
  /** An object relationship */
  wurrly: Wurrly;
  wurrly_id: Scalars['Int'];
  wurrly_reaction_id: Scalars['Int'];
};

/** aggregated selection of "wurrly_reaction" */
export type Wurrly_Reaction_Aggregate = {
  __typename?: 'wurrly_reaction_aggregate';
  aggregate?: Maybe<Wurrly_Reaction_Aggregate_Fields>;
  nodes: Array<Wurrly_Reaction>;
};

/** aggregate fields of "wurrly_reaction" */
export type Wurrly_Reaction_Aggregate_Fields = {
  __typename?: 'wurrly_reaction_aggregate_fields';
  avg?: Maybe<Wurrly_Reaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Reaction_Max_Fields>;
  min?: Maybe<Wurrly_Reaction_Min_Fields>;
  stddev?: Maybe<Wurrly_Reaction_Stddev_Fields>;
  stddev_pop?: Maybe<Wurrly_Reaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wurrly_Reaction_Stddev_Samp_Fields>;
  sum?: Maybe<Wurrly_Reaction_Sum_Fields>;
  var_pop?: Maybe<Wurrly_Reaction_Var_Pop_Fields>;
  var_samp?: Maybe<Wurrly_Reaction_Var_Samp_Fields>;
  variance?: Maybe<Wurrly_Reaction_Variance_Fields>;
};


/** aggregate fields of "wurrly_reaction" */
export type Wurrly_Reaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Reaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wurrly_reaction" */
export type Wurrly_Reaction_Aggregate_Order_By = {
  avg?: Maybe<Wurrly_Reaction_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wurrly_Reaction_Max_Order_By>;
  min?: Maybe<Wurrly_Reaction_Min_Order_By>;
  stddev?: Maybe<Wurrly_Reaction_Stddev_Order_By>;
  stddev_pop?: Maybe<Wurrly_Reaction_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wurrly_Reaction_Stddev_Samp_Order_By>;
  sum?: Maybe<Wurrly_Reaction_Sum_Order_By>;
  var_pop?: Maybe<Wurrly_Reaction_Var_Pop_Order_By>;
  var_samp?: Maybe<Wurrly_Reaction_Var_Samp_Order_By>;
  variance?: Maybe<Wurrly_Reaction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wurrly_reaction" */
export type Wurrly_Reaction_Arr_Rel_Insert_Input = {
  data: Array<Wurrly_Reaction_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wurrly_Reaction_On_Conflict>;
};

/** aggregate avg on columns */
export type Wurrly_Reaction_Avg_Fields = {
  __typename?: 'wurrly_reaction_avg_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Avg_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wurrly_reaction". All fields are combined with a logical 'AND'. */
export type Wurrly_Reaction_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Reaction_Bool_Exp>>;
  _not?: Maybe<Wurrly_Reaction_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Reaction_Bool_Exp>>;
  reaction_id?: Maybe<Int_Comparison_Exp>;
  student?: Maybe<Student_Bool_Exp>;
  student_id?: Maybe<Int_Comparison_Exp>;
  wurrly?: Maybe<Wurrly_Bool_Exp>;
  wurrly_id?: Maybe<Int_Comparison_Exp>;
  wurrly_reaction_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "wurrly_reaction" */
export enum Wurrly_Reaction_Constraint {
  /** unique or primary key constraint */
  WurrlyReactionPkey = 'wurrly_reaction_pkey'
}

/** input type for incrementing numeric columns in table "wurrly_reaction" */
export type Wurrly_Reaction_Inc_Input = {
  reaction_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reaction_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wurrly_reaction" */
export type Wurrly_Reaction_Insert_Input = {
  reaction_id?: Maybe<Scalars['Int']>;
  student?: Maybe<Student_Obj_Rel_Insert_Input>;
  student_id?: Maybe<Scalars['Int']>;
  wurrly?: Maybe<Wurrly_Obj_Rel_Insert_Input>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reaction_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Wurrly_Reaction_Max_Fields = {
  __typename?: 'wurrly_reaction_max_fields';
  reaction_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reaction_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Max_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wurrly_Reaction_Min_Fields = {
  __typename?: 'wurrly_reaction_min_fields';
  reaction_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reaction_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Min_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wurrly_reaction" */
export type Wurrly_Reaction_Mutation_Response = {
  __typename?: 'wurrly_reaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly_Reaction>;
};

/** on_conflict condition type for table "wurrly_reaction" */
export type Wurrly_Reaction_On_Conflict = {
  constraint: Wurrly_Reaction_Constraint;
  update_columns?: Array<Wurrly_Reaction_Update_Column>;
  where?: Maybe<Wurrly_Reaction_Bool_Exp>;
};

/** Ordering options when selecting data from "wurrly_reaction". */
export type Wurrly_Reaction_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student?: Maybe<Student_Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly?: Maybe<Wurrly_Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** primary key columns input for table: wurrly_reaction */
export type Wurrly_Reaction_Pk_Columns_Input = {
  wurrly_reaction_id: Scalars['Int'];
};

/** select columns of table "wurrly_reaction" */
export enum Wurrly_Reaction_Select_Column {
  /** column name */
  ReactionId = 'reaction_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  WurrlyId = 'wurrly_id',
  /** column name */
  WurrlyReactionId = 'wurrly_reaction_id'
}

/** input type for updating data in table "wurrly_reaction" */
export type Wurrly_Reaction_Set_Input = {
  reaction_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reaction_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Wurrly_Reaction_Stddev_Fields = {
  __typename?: 'wurrly_reaction_stddev_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Stddev_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wurrly_Reaction_Stddev_Pop_Fields = {
  __typename?: 'wurrly_reaction_stddev_pop_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Stddev_Pop_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wurrly_Reaction_Stddev_Samp_Fields = {
  __typename?: 'wurrly_reaction_stddev_samp_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Stddev_Samp_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wurrly_Reaction_Sum_Fields = {
  __typename?: 'wurrly_reaction_sum_fields';
  reaction_id?: Maybe<Scalars['Int']>;
  student_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_reaction_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Sum_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** update columns of table "wurrly_reaction" */
export enum Wurrly_Reaction_Update_Column {
  /** column name */
  ReactionId = 'reaction_id',
  /** column name */
  StudentId = 'student_id',
  /** column name */
  WurrlyId = 'wurrly_id',
  /** column name */
  WurrlyReactionId = 'wurrly_reaction_id'
}

/** aggregate var_pop on columns */
export type Wurrly_Reaction_Var_Pop_Fields = {
  __typename?: 'wurrly_reaction_var_pop_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Var_Pop_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wurrly_Reaction_Var_Samp_Fields = {
  __typename?: 'wurrly_reaction_var_samp_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Var_Samp_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wurrly_Reaction_Variance_Fields = {
  __typename?: 'wurrly_reaction_variance_fields';
  reaction_id?: Maybe<Scalars['Float']>;
  student_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
  wurrly_reaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wurrly_reaction" */
export type Wurrly_Reaction_Variance_Order_By = {
  reaction_id?: Maybe<Order_By>;
  student_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
  wurrly_reaction_id?: Maybe<Order_By>;
};

/** columns and relationships of "wurrly_role" */
export type Wurrly_Role = {
  __typename?: 'wurrly_role';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "wurrly_role" */
export type Wurrly_Role_Aggregate = {
  __typename?: 'wurrly_role_aggregate';
  aggregate?: Maybe<Wurrly_Role_Aggregate_Fields>;
  nodes: Array<Wurrly_Role>;
};

/** aggregate fields of "wurrly_role" */
export type Wurrly_Role_Aggregate_Fields = {
  __typename?: 'wurrly_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Role_Max_Fields>;
  min?: Maybe<Wurrly_Role_Min_Fields>;
};


/** aggregate fields of "wurrly_role" */
export type Wurrly_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wurrly_role". All fields are combined with a logical 'AND'. */
export type Wurrly_Role_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Role_Bool_Exp>>;
  _not?: Maybe<Wurrly_Role_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Role_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "wurrly_role" */
export enum Wurrly_Role_Constraint {
  /** unique or primary key constraint */
  HasuraRolesPkey = 'hasura_roles_pkey'
}

export enum Wurrly_Role_Enum {
  /** admin */
  Admin = 'admin',
  /** public */
  Public = 'public',
  /** student */
  Student = 'student',
  /** teacher */
  Teacher = 'teacher'
}

/** Boolean expression to compare columns of type "wurrly_role_enum". All fields are combined with logical 'AND'. */
export type Wurrly_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Wurrly_Role_Enum>;
  _in?: Maybe<Array<Wurrly_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wurrly_Role_Enum>;
  _nin?: Maybe<Array<Wurrly_Role_Enum>>;
};

/** input type for inserting data into table "wurrly_role" */
export type Wurrly_Role_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wurrly_Role_Max_Fields = {
  __typename?: 'wurrly_role_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wurrly_Role_Min_Fields = {
  __typename?: 'wurrly_role_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wurrly_role" */
export type Wurrly_Role_Mutation_Response = {
  __typename?: 'wurrly_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly_Role>;
};

/** on_conflict condition type for table "wurrly_role" */
export type Wurrly_Role_On_Conflict = {
  constraint: Wurrly_Role_Constraint;
  update_columns?: Array<Wurrly_Role_Update_Column>;
  where?: Maybe<Wurrly_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "wurrly_role". */
export type Wurrly_Role_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: wurrly_role */
export type Wurrly_Role_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "wurrly_role" */
export enum Wurrly_Role_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "wurrly_role" */
export type Wurrly_Role_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "wurrly_role" */
export enum Wurrly_Role_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** select columns of table "wurrly" */
export enum Wurrly_Select_Column {
  /** column name */
  AudioBalance = 'audio_balance',
  /** column name */
  AudioUrl = 'audio_url',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinalResourcePath = 'final_resource_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsFromLegacy = 'is_from_legacy',
  /** column name */
  IsMirrored = 'is_mirrored',
  /** column name */
  Message = 'message',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  StudentIdOwner = 'student_id_owner',
  /** column name */
  StudentIdSecondary = 'student_id_secondary',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyId = 'wurrly_id',
  /** column name */
  WurrlyType = 'wurrly_type'
}

/** input type for updating data in table "wurrly" */
export type Wurrly_Set_Input = {
  audio_balance?: Maybe<Scalars['Int']>;
  audio_url?: Maybe<Scalars['String']>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  final_resource_path?: Maybe<Scalars['String']>;
  image_path?: Maybe<Scalars['String']>;
  is_from_legacy?: Maybe<Scalars['Boolean']>;
  is_mirrored?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  privacy?: Maybe<Privacy_Enum>;
  processing_status?: Maybe<Wurrly_Processing_Status_Enum>;
  resource_path?: Maybe<Scalars['String']>;
  student_id_owner?: Maybe<Scalars['Int']>;
  student_id_secondary?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wurrly_id?: Maybe<Scalars['Int']>;
  wurrly_type?: Maybe<Wurrly_Type_Enum>;
};

/** aggregate stddev on columns */
export type Wurrly_Stddev_Fields = {
  __typename?: 'wurrly_stddev_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wurrly" */
export type Wurrly_Stddev_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wurrly_Stddev_Pop_Fields = {
  __typename?: 'wurrly_stddev_pop_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wurrly" */
export type Wurrly_Stddev_Pop_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wurrly_Stddev_Samp_Fields = {
  __typename?: 'wurrly_stddev_samp_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wurrly" */
export type Wurrly_Stddev_Samp_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wurrly_Sum_Fields = {
  __typename?: 'wurrly_sum_fields';
  audio_balance?: Maybe<Scalars['Int']>;
  challenge_id?: Maybe<Scalars['Int']>;
  class_id?: Maybe<Scalars['Int']>;
  student_id_owner?: Maybe<Scalars['Int']>;
  student_id_secondary?: Maybe<Scalars['Int']>;
  track_id?: Maybe<Scalars['Int']>;
  wurrly_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "wurrly" */
export type Wurrly_Sum_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** columns and relationships of "wurrly_type" */
export type Wurrly_Type = {
  __typename?: 'wurrly_type';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "wurrly_type" */
export type Wurrly_Type_Aggregate = {
  __typename?: 'wurrly_type_aggregate';
  aggregate?: Maybe<Wurrly_Type_Aggregate_Fields>;
  nodes: Array<Wurrly_Type>;
};

/** aggregate fields of "wurrly_type" */
export type Wurrly_Type_Aggregate_Fields = {
  __typename?: 'wurrly_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wurrly_Type_Max_Fields>;
  min?: Maybe<Wurrly_Type_Min_Fields>;
};


/** aggregate fields of "wurrly_type" */
export type Wurrly_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wurrly_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "wurrly_type". All fields are combined with a logical 'AND'. */
export type Wurrly_Type_Bool_Exp = {
  _and?: Maybe<Array<Wurrly_Type_Bool_Exp>>;
  _not?: Maybe<Wurrly_Type_Bool_Exp>;
  _or?: Maybe<Array<Wurrly_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "wurrly_type" */
export enum Wurrly_Type_Constraint {
  /** unique or primary key constraint */
  WurrlyTypePkey = 'wurrly_type_pkey'
}

export enum Wurrly_Type_Enum {
  Assignment = 'assignment',
  Challenge = 'challenge',
  Indie = 'indie'
}

/** Boolean expression to compare columns of type "wurrly_type_enum". All fields are combined with logical 'AND'. */
export type Wurrly_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Wurrly_Type_Enum>;
  _in?: Maybe<Array<Wurrly_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Wurrly_Type_Enum>;
  _nin?: Maybe<Array<Wurrly_Type_Enum>>;
};

/** input type for inserting data into table "wurrly_type" */
export type Wurrly_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wurrly_Type_Max_Fields = {
  __typename?: 'wurrly_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wurrly_Type_Min_Fields = {
  __typename?: 'wurrly_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wurrly_type" */
export type Wurrly_Type_Mutation_Response = {
  __typename?: 'wurrly_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wurrly_Type>;
};

/** on_conflict condition type for table "wurrly_type" */
export type Wurrly_Type_On_Conflict = {
  constraint: Wurrly_Type_Constraint;
  update_columns?: Array<Wurrly_Type_Update_Column>;
  where?: Maybe<Wurrly_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "wurrly_type". */
export type Wurrly_Type_Order_By = {
  description?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: wurrly_type */
export type Wurrly_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "wurrly_type" */
export enum Wurrly_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "wurrly_type" */
export type Wurrly_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "wurrly_type" */
export enum Wurrly_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** update columns of table "wurrly" */
export enum Wurrly_Update_Column {
  /** column name */
  AudioBalance = 'audio_balance',
  /** column name */
  AudioUrl = 'audio_url',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinalResourcePath = 'final_resource_path',
  /** column name */
  ImagePath = 'image_path',
  /** column name */
  IsFromLegacy = 'is_from_legacy',
  /** column name */
  IsMirrored = 'is_mirrored',
  /** column name */
  Message = 'message',
  /** column name */
  Privacy = 'privacy',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  ResourcePath = 'resource_path',
  /** column name */
  StudentIdOwner = 'student_id_owner',
  /** column name */
  StudentIdSecondary = 'student_id_secondary',
  /** column name */
  TrackId = 'track_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WurrlyId = 'wurrly_id',
  /** column name */
  WurrlyType = 'wurrly_type'
}

/** aggregate var_pop on columns */
export type Wurrly_Var_Pop_Fields = {
  __typename?: 'wurrly_var_pop_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wurrly" */
export type Wurrly_Var_Pop_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wurrly_Var_Samp_Fields = {
  __typename?: 'wurrly_var_samp_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wurrly" */
export type Wurrly_Var_Samp_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wurrly_Variance_Fields = {
  __typename?: 'wurrly_variance_fields';
  audio_balance?: Maybe<Scalars['Float']>;
  challenge_id?: Maybe<Scalars['Float']>;
  class_id?: Maybe<Scalars['Float']>;
  student_id_owner?: Maybe<Scalars['Float']>;
  student_id_secondary?: Maybe<Scalars['Float']>;
  track_id?: Maybe<Scalars['Float']>;
  wurrly_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wurrly" */
export type Wurrly_Variance_Order_By = {
  audio_balance?: Maybe<Order_By>;
  challenge_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  student_id_owner?: Maybe<Order_By>;
  student_id_secondary?: Maybe<Order_By>;
  track_id?: Maybe<Order_By>;
  wurrly_id?: Maybe<Order_By>;
};
