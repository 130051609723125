import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentTilesStyles = makeStyles(() =>
	createStyles({
		imageButtonWrapper: {
			height: '100%',
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			}
		},
		assignmentTile: { height: 200 },
		container: {
			aspectRatio: '23/9',
			'& .MuiGrid-root': {
				margin: 0
			}
		},
		bigImage: {
			height: '100%',
			aspectRatio: '11/14',
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			}
		},
		image: {
			height: '100%',
			aspectRatio: '16/10',
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			},
			'& .main': {
				height: '100%'
			}
		},
		bottomImage: {
			height: '100%',
			aspectRatio: '23/6',
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			},
			'& .main': {
				height: '100%'
			}
		},
		fullheight: {
			height: '100%'
		}
	})
)

export default useAssignmentTilesStyles
