import { createStyles, fade, makeStyles } from '@material-ui/core'

export const useViewAssignmentsStyles = makeStyles((theme) =>
	createStyles({
		listBox: {
			padding: 0,
			margin: 0,
			'& .MuiAutocomplete-option:not(last-child)': {
				padding: '15px'
			},
			'& .MuiAutocomplete-option[aria-selected="true"]': {
				color: theme.palette.secondary.main,
				backgroundColor: 'rgba(255, 194, 12, 0.3)'
			},
			'& .MuiAutocomplete-option[data-focus="true"]': {
				color: theme.palette.secondary.main,
				backgroundColor: 'rgba(255, 194, 12, 0.1)'
			}
		},
		buttonOption: {
			height: '100%',
			position: 'relative',
			backgroundSize: 'cover',
			backgroundPosition: 'center 40%',
			backgroundRepeat: 'no-repeat',
			'& .MuiButton-iconSizeLarge > *:first-child': {
				fontSize: '50px'
			}
		},
		buttonDisabled: {
			marginTop: '35px',
			display: 'flex',
			justifyContent: 'space-between',
			'& .MuiButton-contained.Mui-disabled': {
				backgroundColor: 'rgba(255, 194, 12, 0.4)'
			}
		},
		selectInput: {
			width: '100%'
		},
		inputDefault: {
			padding: '10px 0',
			width: '100%'
		},
		inputSuccess: {
			padding: '10px 0',
			width: '100%',
			'& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.success.dark
			}
		},
		inputError: {
			padding: '10px 0',
			width: '100%',
			'& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.error.dark
			}
		},
		inputImage: {
			padding: '4px 14px 4px 4px',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			border: '1px solid rgba(0, 0, 0, .23)',
			borderRadius: '4px',
			'&:hover': {
				border: '1px solid rgba(0, 0, 0)',
				borderRadius: '4px'
			}
		},
		inputImageFile: {
			padding: '4px 14px 4px 4px',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			border: '1px solid rgba(0, 0, 0, .23)',
			borderRadius: '4px',
			'&:hover': {
				border: '1px solid rgba(0, 0, 0)',
				borderRadius: '4px'
			}
		},
		labelImageFile: {
			marginLeft: theme.spacing(1),
			flex: 1,
			alignItems: 'center'
		},
		labelImage: {
			padding: '10px 0px',
			marginLeft: theme.spacing(1),
			flex: 1,
			alignItems: 'center',
			color: theme.palette.text.disabled
		},
		textDefault: {
			color: theme.palette.text.secondary
		},
		textSuccess: {
			color: theme.palette.success.dark
		},
		textError: {
			color: theme.palette.error.dark
		},
		chip: {
			color: theme.palette.background.default,
			backgroundColor: theme.palette.text.secondary,
			borderRadius: '4px',
			marginRight: '5px'
		},
		closeChipIcon: {
			color: fade(theme.palette.background.default, 0.6),
			height: '26px',
			width: '18px',
			marginLeft: '3px',
			'& .MuiChip-deleteIcon:hover': {
				color: theme.palette.background.default
			}
		},
		thumbnail: {
			'& .MuiGridListTile-tile': {
				borderRadius: '5px'
			}
		},
		popover: {
			pointerEvents: 'none'
		},
		paper: {
			padding: theme.spacing(1),
			maxWidth: '400px'
		},
		checkboxInput: {
			backgroundColor: theme.palette.background.paper,
			border: '1px solid rgba(0, 0, 0, .23)',
			padding: '5px 20px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		checkboxGridItem: {
			flexGrow: 1
		},
		pickInput: {
			padding: '4px 14px 4px 4px',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			border: '1px solid rgba(0, 0, 0, .23)',
			borderRadius: '4px',
			backgroundColor: theme.palette.background.paper,
			cursor: 'pointer'
		},
		pickInputMultiple: {
			padding: '4px 14px 4px 4px',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			border: '1px solid rgba(0, 0, 0, .23)',
			backgroundColor: theme.palette.background.paper,
			cursor: 'pointer'
		},
		endAdornment: {
			display: 'none'
		},
		fieldTitle: {
			fontSize: '1.3em',
			fontWeight: 500,
			marginBottom: '.8em',
			'& b, strong': {
				fontWeight: 500
			}
		},
		textGray: {
			opacity: 0.5,
			fontSize: '1.1em',
			lineHeight: '1.6em'
		},
		instructionsTitle: { fontSize: 18, maxWidth: 500, marginTop: '30px' },
		instructions: { fontSize: 14, maxWidth: 500, marginTop: '10px' },
		stepper: { position: 'absolute', top: '150px', right: '100px', width: '250px' },
		standardButton: { padding: '6px 40px' },
		backButton: { marginLeft: '10px', padding: '6px 40px' }
	})
)

export default useViewAssignmentsStyles
