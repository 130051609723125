import { useEffect, useState } from 'react'

import { useDebounce } from 'react-use'
import {
	useGetClassLinkUserLazyQuery,
	useGetClassLinkAuthUrlQuery,
	useLoginOrSignUpTeacherIntegrationLazyQuery,
	useStudentIntegrationLoginLazyQuery,
	useGetClasslinkStudentsLazyQuery
	// useGetClasslinkStudentsLazyQuery
} from 'src/graphql/autogenerate/hooks'
import {
	ClassLinkGroup,
	GetClassLinkUserData,
	Integration_Name_Enum,
	LoginOrSignupTeacherIntegrationData,
	StudentLoginIntegrationData
} from 'src/graphql/autogenerate/schemas'
import { IntegrationStudent } from 'src/hooks/useIntegration'
import { NO_CACHE } from 'src/utils'
import { getErrorMessage } from 'src/utils/errorHandler'

type ClassLinkIntanceType = {
	setClasses: (classes: ClassLinkGroup[]) => void
	setStudents: (students: IntegrationStudent[]) => void
}
export type ClassLinkTeacherData = Omit<LoginOrSignupTeacherIntegrationData, '__typename'>
export type ClassLinkStudentData = Omit<StudentLoginIntegrationData, '__typename'>
export const useClassLinkInstance = ({ setClasses, setStudents }: ClassLinkIntanceType) => {
	const [account, setAccount] = useState<Pick<GetClassLinkUserData, 'email' | 'sourcedId' | 'isTeacher'>>()
	const [code, setCode] = useState<string>()
	const [isOpen, setIsOpen] = useState(false)
	const [error, setError] = useState('')
	const [starterAction, setStarterAction] = useState(false)
	const [data, setData] = useState<ClassLinkTeacherData | ClassLinkStudentData>()
	const { data: loginUrl } = useGetClassLinkAuthUrlQuery(NO_CACHE)
	const [
		getClassLinkUser,
		{ data: classLinkUserData, loading: classLinkUserLoading, error: classLinkUserError }
	] = useGetClassLinkUserLazyQuery(NO_CACHE)
	const [
		loginOrSignUpTeacher,
		{ data: loginOrSignUpTeacherData, loading: loginOrSignUpTeacherLoading, error: loginOrSignUpTeacherError }
	] = useLoginOrSignUpTeacherIntegrationLazyQuery(NO_CACHE)
	const [loginStudent, { data: loginStudentData, loading: loginStudentLoading, error: loginStudentError }] =
		useStudentIntegrationLoginLazyQuery(NO_CACHE)
	const [loadStudents, { data: dataStudents }] = useGetClasslinkStudentsLazyQuery()

	useDebounce(
		() => {
			if (code) {
				setStarterAction(true)
				getClassLinkUser({ variables: { code } })
			}
		},
		500,
		[code]
	)

	useEffect(() => {
		const students = (dataStudents?.getClassLinkStudents ?? []).map(({ firstName, lastName, sourcedId }) => ({
			name: {
				first: firstName,
				last: lastName
			},
			email: '',
			integrationId: sourcedId
		}))

		setStudents(students)
	}, [dataStudents])

	const runLoadStudents = (sourcedId: string) => {
		loadStudents({
			variables: {
				filter: {
					classSourcedId: sourcedId
				}
			}
		})
	}

	useEffect(() => {
		if (classLinkUserLoading) return
		if (classLinkUserError) return setErrorWrapper(classLinkUserError)
		if (classLinkUserData?.getClassLinkUser && starterAction) {
			const response = classLinkUserData?.getClassLinkUser
			const { email, sourcedId, classes, isTeacher } = response

			if (sourcedId) {
				setAccount({
					...account,
					email,
					sourcedId,
					isTeacher
				})
				setClasses(classes)
				setStarterAction(false)
			}
		}
	}, [classLinkUserData, starterAction])

	useEffect(() => {
		const isValidIntegration = Boolean(account && account?.sourcedId)
		if (isValidIntegration) handleValidIntegration()
	}, [account])

	useEffect(() => {
		if (loginOrSignUpTeacherError) setErrorWrapper(loginOrSignUpTeacherError)
		else if (loginOrSignUpTeacherData) handleLoginOrSignUpTeacherResponse()
	}, [loginOrSignUpTeacherData, loginOrSignUpTeacherError])

	useEffect(() => {
		if (loginStudentError) setErrorWrapper(loginStudentError)
		else if (loginStudentData) handleLoginStudentResponse()
	}, [loginStudentData, loginStudentError])

	const handleValidIntegration = () => {
		const { sourcedId, email, isTeacher } = account || {}
		// types already checked before
		if (isTeacher && email && sourcedId)
			loginOrSignUpTeacher({
				variables: {
					accesToken: '', // unused in classLink
					email,
					integrationName: Integration_Name_Enum.ClassLink,
					integrationId: sourcedId
				}
			})
		else if (!isTeacher && sourcedId) loginStudent({ variables: { integrationId: sourcedId } })
	}

	const handleLoginOrSignUpTeacherResponse = () => {
		if (!loginOrSignUpTeacherData?.loginOrSignupTeacherIntegration) return

		const { isSignup, teacherId, token, schoolId } = loginOrSignUpTeacherData?.loginOrSignupTeacherIntegration
		setData({
			isSignup,
			teacherId,
			token,
			schoolId
		})
	}

	const handleLoginStudentResponse = () => {
		const token = loginStudentData?.studentLoginIntegration?.token
		if (!token) return setErrorWrapper('Something went wrong, please try again')

		setData({ token } as ClassLinkStudentData)
	}

	const setErrorWrapper = (message: unknown) => {
		const formatted = getErrorMessage(message)
		console.error({ error: formatted })
		setError(formatted)
	}

	const openPopup = () => {
		const url = loginUrl?.getClassLinkAuthUrl || ''
		if (isOpen || !url) return

		return new Promise((resolve) => {
			setIsOpen(true)
			const loginWindow = window.open(url, 'name', 'height=600,width=450')

			if (loginWindow) {
				loginWindow.focus()

				try {
					const oauthInterval = setInterval(() => {
						try {
							if (loginWindow.closed) {
								setIsOpen(false)
								clearInterval(oauthInterval)
							}
							if (loginWindow.location.href && !loginWindow.location.href.includes('about:blank')) {
								const codeURL = new URL(loginWindow.location.href)
								const code = codeURL.searchParams.get('code')
								if (!code) return
								setCode(code)

								loginWindow.close()
								resolve(true)
							}
						} catch (err) {
							console.error({ err })
						}
					}, 200)
				} catch (err) {
					console.error({ err })
				}
			}
		})
	}

	return {
		// starter actions
		openPopup,
		setCode,
		runLoadStudents,

		// data
		account,
		loading: classLinkUserLoading || loginOrSignUpTeacherLoading || loginStudentLoading,
		error,
		data
	}
}
