import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useUploadAudioViewStyles = makeStyles((theme: Theme) =>
	createStyles({
		termsContainer: {
			display: 'flex'
		},
		termsText: {
			userSelect: 'none'
		},
		termsLink: {
			color: theme.palette.secondary.main,
			textDecoration: 'none'
		},
		radios: { display: 'flex', flexDirection: 'row', gap: '12px' },
		modeCard: { padding: '8px 16px' },
		button: {
			height: 72,
			position: 'relative',
			color: 'inherit',
			borderWidth: undefined,
			backgroundColor: '#fff'
		},
		musicNote: {
			fontSize: '46px'
		},
		selectedTrackCheckmark: {
			position: 'absolute',
			top: '5px',
			right: '5px'
		}
	})
)

export default useUploadAudioViewStyles
