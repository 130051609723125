import { Fragment } from 'react'

import { Box, Typography } from '@material-ui/core'
import { BaseLink, CarouselOfAny, WurrlyCard } from 'src/components'
import { Wurrly } from 'src/graphql/autogenerate/schemas'

type WurrlyCarouselProps = {
	title: string
	wurrlies: Wurrly[]
	viewAllLink: string
	onWurrlyClick: (wurrlyId: number) => void
}

/**
 * @remarks Teacher
 * this component is used just on teacher side
 */

export const WurrlyCarousel = ({ title, wurrlies, viewAllLink, onWurrlyClick }: WurrlyCarouselProps) => {
	if (wurrlies.length === 0) {
		return null
	}

	return (
		<Fragment>
			<Box display="flex" justifyContent="space-between" marginTop={5} alignItems="center" marginBottom={2}>
				<Typography variant="h6">
					<b>{title}</b>
				</Typography>
				<Typography variant="h6">
					<BaseLink data-cy={'ViewAllLink'} href={viewAllLink}>
						{' '}
						View All
					</BaseLink>
				</Typography>
			</Box>
			<CarouselOfAny
				slidesPerPage={{ xs: 2, sm: 3 }}
				spacing={3}
				useCarouselOnSingle={true}
				data={wurrlies.map((wurrly, key) => ({
					element: (
						<WurrlyCard
							key={key}
							type={wurrly.wurrly_type}
							header={{
								avatar: wurrly.student?.image_path || '',
								title: `${wurrly.student?.first_name} ${wurrly.student?.last_name}`,
								date: wurrly.created_at
							}}
							cardMedia={wurrly.image_path || ''}
							content={{
								title: wurrly.track?.song?.title || '',
								subtitle: wurrly.track?.song?.artist?.name || '',
								trackType: wurrly.track?.track_type?.name || '',
								grade: wurrly.submission_wurrly?.submission?.grade || '',
								comment: wurrly?.submission_wurrly?.submission?.teacher_comment || ''
							}}
							onClick={() => onWurrlyClick(wurrly.wurrly_id)}
						/>
					),
					linkTo: {}
				}))}
				renderItem={(item) => (item.element ? item.element : <div />)}
			/>
		</Fragment>
	)
}
