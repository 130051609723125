import { useEffect, useMemo, useState } from 'react'

import { Box, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import { ChartDataType } from 'src/@types'
import { StandardItem } from 'src/components/SkillCoveredBySavedLessons/StandardItem'
import { GetStandardsCoveredBySavedLessonsQuery } from 'src/graphql/autogenerate/operations'
import { Lesson_Plan, Non_Musical_Skill_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useInsightsContext } from 'src/scenes/Teacher/scenes/1-Home/components/Insights/hooks/useInsights'
import {
	Connecting,
	Creating,
	musicStandard,
	MusicStandars,
	Performing,
	Responding
} from 'src/utils/MusicStandard'

import { BigBaseButton } from '..'
import { DoughnutChart } from './Doughnut/DoughnutChart'
import { SkillItem } from './SkillItem'

type SkillCoveredBySavedLessonsProps = {
	chartStandardsData: ChartDataType
	chartSkillsData: ChartDataType
	standardChartData: GetStandardsCoveredBySavedLessonsQuery | undefined
}

type Process = {
	[key: string]: string[]
}

export const SkillCoveredBySavedLessons = ({
	chartStandardsData,
	chartSkillsData,
	standardChartData
}: SkillCoveredBySavedLessonsProps) => {
	const { teacherData: teacher } = useLoginContext()
	const { chartAcordeonData, percentage, classLessons } = useInsightsContext()
	const [moduleObject, setModuleObject] = useState<Lesson_Plan>()
	const [renderedAccordeons, setRenderedAccordeons] = useState<React.ReactNode>()

	useEffect(() => {
		const lessons: Lesson_Plan[] = []
		standardChartData?.class_by_pk?.class_modules?.forEach((module) =>
			module.module?.module_lesson_plan?.forEach((item) => {
				if (item.lesson_plan) lessons.push(item.lesson_plan as Lesson_Plan)
			})
		)

		standardChartData?.class_by_pk?.class_lesson_plans.forEach((item) => {
			if (item.lesson_plan) lessons.push(item.lesson_plan as Lesson_Plan)
		})

		const process = {
			creating: [],
			connecting: [],
			performing: [],
			responding: []
		} as Process

		lessons.forEach((lesson) => {
			const processes = lesson.process as Process
			Object.keys(processes).forEach((processKey) => {
				if (!processes[processKey]?.length) return
				processes[processKey]?.forEach((processItem: string) => {
					if (!process[processKey].includes(processItem)) process[processKey].push(processItem)
				})
			})
		})

		const moduleLesson = { process }
		setModuleObject(moduleLesson as Lesson_Plan)
	}, [standardChartData])

	const process = moduleObject?.process

	useEffect(() => {
		const rendered = acordeons()
		setRenderedAccordeons(rendered)
	}, [process, chartAcordeonData])

	const acordeons = () => {
		const hasProcess =
			!!process?.connecting.length ||
			!!process?.creating.length ||
			!!process?.performing.length ||
			!!process?.responding.length
		if (!hasProcess) return null
		const acordeons = Object.keys(process).map((key) => {
			const data: musicStandard | null =
				key === MusicStandars.Creating && key === chartAcordeonData
					? Creating
					: key === MusicStandars.Connecting && key === chartAcordeonData
					? Connecting
					: key === MusicStandars.Performing && key === chartAcordeonData
					? Performing
					: key === MusicStandars.Responding && key === chartAcordeonData
					? Responding
					: null

			const optionsTitles = process[key]
			// If standard doesn't have any item, don't show it.
			if (!data || !optionsTitles || !optionsTitles?.length) return null
			const standards: { [key: string]: { title: string; description: string; lessons: Lesson_Plan[] }[] } =
				{}
			data.subCategories.forEach((subCategory) => {
				for (const optionTitle of optionsTitles) {
					const option = subCategory.options.find((i) => i.title === optionTitle)
					const lessons: Lesson_Plan[] = []
					standardChartData?.class_by_pk?.class_lesson_plans?.forEach((i) => {
						if (i?.lesson_plan?.process[key]?.includes(optionTitle))
							lessons.push(i?.lesson_plan as Lesson_Plan)
					})

					if (option) {
						if (standards[subCategory.name]) {
							standards[subCategory.name].push({
								title: option.title,
								description: option.description,
								lessons
							})
						} else {
							standards[subCategory.name] = [
								{ title: option.title, description: option.description, lessons }
							]
						}
					}
				}
			})
			const descriptions = Object.keys(standards).map((standardName) => (
				<StandardItem
					key={standardName}
					standardName={standardName}
					standards={standards}
					teacherId={teacher.teacher_id}
				/>
			))

			return descriptions
		})

		return acordeons
	}

	const skillsAccordions = useMemo(() => {
		if (
			!chartAcordeonData ||
			!classLessons?.class_by_pk ||
			!Object.values(Non_Musical_Skill_Type_Enum).includes(
				chartAcordeonData as unknown as Non_Musical_Skill_Type_Enum
			)
		)
			return
		const lessons = classLessons?.class_by_pk[
			chartAcordeonData as unknown as Non_Musical_Skill_Type_Enum
		].filter((i) => i.lesson_plan.lesson_plan_non_musical_skill.length)
		const accordionObject: { [key: string]: Lesson_Plan[] } = {}
		lessons.forEach((lesson) => {
			lesson.lesson_plan.lesson_plan_non_musical_skill.forEach((nonMusicalSkill) => {
				const name = nonMusicalSkill.non_musical_skill.name
				const duplicatedLessonPlan = accordionObject[name]?.find(
					(lessonPlan) => lessonPlan.lesson_plan_id === lesson.lesson_plan.lesson_plan_id
				)
				if (accordionObject[name] && !duplicatedLessonPlan) {
					accordionObject[name].push(lesson.lesson_plan as Lesson_Plan)
				} else if (!accordionObject[name]) {
					accordionObject[name] = [lesson.lesson_plan as Lesson_Plan]
				}
			})
		})
		const skillsAccordions = Object.keys(accordionObject).map((skillName) => (
			<SkillItem
				teacherId={teacher.teacher_id}
				lessons={accordionObject[skillName]}
				skillName={skillName}
				key={skillName}
			/>
		))

		return skillsAccordions
	}, [classLessons, chartAcordeonData])

	return (
		<Box marginBottom={2}>
			<Grid xs={12} item>
				<Paper style={{ padding: '20px' }}>
					<Box display="flex" justifyContent="space-between" alignItems="flex-end" marginBottom={2}>
						<Box>
							<Typography style={{ marginBottom: '10px' }} variant="h6">
								Skills Covered by Saved Lessons
							</Typography>
							<Typography variant="body2">
								Start by generating the report to check insights
							</Typography>
						</Box>
						<Tooltip title="Coming soon">
							<BigBaseButton
								style={{
									width: '220px'
								}}
								color="secondary"
								onClick={() => {}}>
								<b>Generate Report</b>
							</BigBaseButton>
						</Tooltip>
					</Box>
					<Grid container>
						<Grid xs={6} item>
							<DoughnutChart title="NATIONAL CORE ARTS ANCHOR STANDARDS" data={chartStandardsData} />
						</Grid>
						<Grid xs={6} item>
							<DoughnutChart title="SOCIAL & EMOTIONAL LEARNING SKILLS" data={chartSkillsData} />
						</Grid>
					</Grid>
					{chartAcordeonData && (
						<Typography>
							<b>
								{chartAcordeonData}
								{' - '}
								{percentage}
							</b>
						</Typography>
					)}
					<Box>{renderedAccordeons}</Box>
					<Box>{skillsAccordions}</Box>
				</Paper>
			</Grid>
		</Box>
	)
}
