import { Fragment } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { BaseDialog } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import { useModulesDialog } from 'src/components/Dialogs/ModulesDialog/useModulesDialog'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { Module, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { AddModuleToClass } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Modules/components/AddModuleToClass'
import { ModulesCardContainer } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Modules/components/ModulesContainer'

type ModulesDialogProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	lessonPlanId: number
}

export const ModulesDialog = ({ isOpen, setIsOpen, lessonPlanId }: ModulesDialogProps) => {
	const {
		modules,
		loading,
		setAddToClassModule,
		setIsAddModuleToClassOpen,
		isAddModuleToClassOpen,
		addToClassModule,
		handleOpenProductDialog,
		handleCloseProductDialog,
		setModuleId,
		moduleId,
		isOpenProductDialog
	} = useModulesDialog(isOpen, lessonPlanId)

	return (
		<>
			<AddModuleToClass
				isOpen={isAddModuleToClassOpen}
				setIsOpen={setIsAddModuleToClassOpen}
				module={addToClassModule}
			/>
			{isOpenProductDialog && moduleId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={moduleId}
					productName={Product_Name_Enum.Module}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<BaseDialog
				bodyProps={{
					style: loading
						? {
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
						  }
						: {
								marginTop: '20px'
						  }
				}}
				paperProps={{
					style: {
						maxWidth: '100%',
						width: '50%',
						height: '80%',
						maxHeight: '100%'
					}
				}}
				dividers={false}
				onClose={() => {
					setIsOpen(false)
				}}
				open={isOpen}
				header={
					!loading && (
						<Fragment>
							<Typography variant="h4" align="center">
								{'Modules where this Lesson can be found'}
							</Typography>
						</Fragment>
					)
				}
				body={
					<Box padding="6px 10px">
						{!loading ? (
							modules.map((module) => (
								<Box marginBottom={'15px'} marginTop={'15px'} key={module.module_id}>
									<ModulesCardContainer
										module={module as Module}
										key={module.module_id}
										elevation={1.5}
										btnAddClass={{
											onClick: (e) => {
												e.stopPropagation()
												setAddToClassModule(module as Module)
												setIsAddModuleToClassOpen(true)
											}
										}}
										btnSuscription={{
											onClick: (e) => {
												e.stopPropagation()
												setModuleId(module.module_id)
												handleOpenProductDialog()
											}
										}}
									/>
								</Box>
							))
						) : (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
					</Box>
				}
				onConfirm={() => {}}
				onConfirmProps={{ style: { width: '214px' } }}
				onDiscard={() => {
					setIsOpen(false)
				}}
				onDiscardProps={{ style: { width: '214px' } }}
				discardLabel="Close"
			/>
		</>
	)
}
