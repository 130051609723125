import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { GradeSquareProps } from 'src/components/Squares/GradeSquare/GradeSquare'

export const useGradeSquareStyles = makeStyles<Theme, GradeSquareProps>((theme) =>
	createStyles({
		box: {
			display: 'flex',
			minWidth: theme.spacing(5),
			minHeight: theme.spacing(5),
			borderRadius: '4px',
			justifyContent: 'center',
			alignItems: 'center',

			backgroundColor: ({ grade, gradingSystem, teacherComment }) => {
				if (grade === 'pass') return 'rgba(0, 186, 112, 0.16)'
				if (grade === 'fail') return 'rgba(255, 106, 58, 0.16)'

				return grade || (gradingSystem?.label === 'None' && teacherComment)
					? 'rgba(0, 186, 112, 0.16)'
					: theme.palette.error.contrastText
			}
		},
		grade: {
			color: ({ grade }) => {
				if (grade === 'pass') return '#000'
				if (grade === 'fail') return '#000'

				return grade ? theme.palette.success.main : 'initial'
			},
			textTransform: 'uppercase',
			width: '45px',
			overflowWrap: 'break-word',
			textAlign: 'center',
			lineHeight: '1',
			fontSize: '14px'
		},
		boxESNU: {
			display: 'flex',
			minWidth: theme.spacing(5),
			minHeight: theme.spacing(5),
			borderRadius: '4px',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: ({ grade }) => {
				switch (grade) {
					case 'excellent':
						return '#4B446D'
					case 'satisfactory':
						return '#B7D8CB'
					case 'needs improvement':
						return '#F3AB34'
					case 'unsatisfactory':
						return '#FF6A3A'
					default:
						return 'rgb(227, 225, 237)'
				}
			}
		},
		gradeESNU: {
			color: ({ grade }) => {
				switch (grade) {
					case 'excellent':
						return '#FFF'
					case 'satisfactory':
						return '#B7D8CB'
					case 'needs improvement':
						return '#000'
					case 'unsatisfactory':
						return '#FFF'
					default:
						return '#000'
				}
			},
			textTransform: 'uppercase',
			width: '45px',
			overflowWrap: 'break-word',
			textAlign: 'center',
			lineHeight: '1',
			fontSize: '14px',
			fontWeight: 'bold'
		}
	})
)

export default useGradeSquareStyles
