import { useMemo } from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { QueryCatalogItemsType } from 'src/@types'

import { CarouselOfAny, ImageButton, ImageButtonProps } from '.'
import { Catalog, Catalog_Item } from '../graphql/autogenerate/schemas'
import { StudentPages } from '../routes/studentpages'
import { Pages } from '../routes/teacherPages'
import { capitalize } from '../utils'
import { DEFAULT_MUSIC_BG } from '../utils/constants'
import { buildRouteParameters } from '../utils/routes-utils'
import { generateUniqueId, getCyKey } from '../utils/utils'

type CatalogsProps = {
	parentPage: 'lessonPlan' | 'song' | 'video'
	data: QueryCatalogItemsType
	isTeacher?: boolean
	classId?: number
	sendParamAsState?: boolean
	overrideCatalogOnClick?: (catalog: Catalog) => void
}

type CustomCatalog = Omit<Catalog, 'catalog_items'> & {
	catalog_items: (Catalog_Item & { linkTo: ReturnType<typeof handleLinkTo> })[]
}

const useStyles = makeStyles(() => ({
	smallBanners: {
		'& .MuiButtonBase-root': {
			backgroundSize: 'cover',
			aspectRatio: '16/10',
			backgroundRepeat: 'no-repeat',
			'& .main': {
				width: '100%',
				height: '100%'
			}
		}
	}
}))
export const Catalogs = ({
	parentPage,
	isTeacher = true,
	data,
	classId,
	sendParamAsState = false,
	overrideCatalogOnClick
}: CatalogsProps) => {
	const styles = useStyles()
	const { catalog_item: catalogItems } = data
	const songCatalogPages = isTeacher ? Pages : StudentPages

	const catalogs = useMemo(() => {
		const listCatalogs: CustomCatalog[] = []
		catalogItems.forEach((item) => {
			const linkTo = handleLinkTo(
				isTeacher,
				sendParamAsState,
				parentPage,
				item.catalog.catalog_id,
				item.catalog_item_id,
				classId
			)
			const found = listCatalogs.find((c) => c.catalog_id === item.catalog.catalog_id)
			if (!found) {
				listCatalogs.push({
					...item.catalog,
					catalog_items: [
						{
							...item,
							catalog_id: item.catalog.catalog_id,
							linkTo
						}
					]
				})
			} else {
				found.catalog_items.push({ ...item, catalog_id: item.catalog.catalog_id, linkTo })
			}
		})

		return listCatalogs
	}, [classId])

	return (
		<div>
			{catalogs.map(({ name: catalogName, catalog_id: catalogId, catalog_items }) => {
				return (
					<div key={generateUniqueId()}>
						<Box mt={6} mb={2}>
							<Typography variant="h6">
								<b>{capitalize(catalogName)}</b>
							</Typography>
						</Box>
						<CarouselOfAny
							slidesPerPage={{ xs: 2, sm: 3, md: 4 }}
							spacing={3}
							data={[
								...catalog_items.map((item) => ({
									id: item.catalog_item_id,
									backgroundImageUrl: item.image_path || DEFAULT_MUSIC_BG,
									title: (
										<Typography
											data-cy={getCyKey(Catalogs, item.name)}
											style={{
												maxWidth: '90px',
												textAlign: 'center'
											}}>
											<b>{item.name}</b>
										</Typography>
									),
									icon: item.icon_path || '',
									linkTo: item.linkTo,
									catalog: item.catalog
								})),
								{
									backgroundColor: '#221f31',
									title: 'All ' + catalogName,
									element: <SearchIcon />,
									linkTo: buildRouteParameters(
										(parentPage === 'lessonPlan' && Pages.CatalogLessons) ||
											(parentPage === 'song' && songCatalogPages.CatalogSongs) ||
											Pages.CatalogVideos,
										{
											catalogId
										}
									)
								}
							]}
							renderItem={(item: ImageButtonProps & { catalog?: Catalog }) => {
								const buttonOnClick = () => {
									if (overrideCatalogOnClick && item.catalog)
										overrideCatalogOnClick(item.catalog)
								}

								return (
									<ImageButton
										className={styles.smallBanners}
										centerTitle
										backgroundColor={item.backgroundColor}
										backgroundImageUrl={item.backgroundImageUrl}
										title={item.title}
										icon={item.icon}
										element={item.element}
										linkTo={!overrideCatalogOnClick ? item.linkTo : undefined}
										onClick={buttonOnClick}
									/>
								)
							}}
						/>
					</div>
				)
			})}
		</div>
	)
}

const handleLinkTo = (
	isTeacher: boolean,
	sendParamAsState: boolean,
	parentPage: string,
	catalogId: number,
	catalogItemId: number,
	classId?: number
) => {
	const songCatalogPages = isTeacher ? Pages : StudentPages

	if (!sendParamAsState) {
		if (!catalogId || !catalogItemId) return

		return {
			pathname: buildRouteParameters(
				(parentPage === 'lessonPlan' && Pages.CatalogItemLessons) ||
					(parentPage === 'song' && songCatalogPages.CatalogItemSongs) ||
					Pages.CatalogItemVideos,
				isTeacher
					? {
							catalogId,
							catalogItemId
					  }
					: {
							catalogId,
							catalogItemId,
							classId: classId ?? 0
					  }
			)
		}
	} else {
		if (!catalogId || !catalogItemId) return

		return {
			pathname: buildRouteParameters(songCatalogPages.CatalogItemSongsDrawer, {
				catalogId,
				catalogItemId
			}),
			state: { classId }
		}
	}
}
