import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useHeaderListStyles = makeStyles<Theme>(() =>
	createStyles({
		container: {
			margin: '30px 40px 0 40px'
		},
		containerTitle: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		title: {
			fontWeight: 500,
			fontSize: '17px',
			lineHeight: '20px'
		},
		divider: {
			height: '2px',
			backgroundColor: '#FFC20C',
			width: '100%',
			margin: '15px 0'
		}
	})
)

export default useHeaderListStyles
