import { useEffect, useMemo, useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import { DateFilterEnum } from 'src/environment'
import {
	useGetClassesByStudentQuery,
	useGetExpiredAssignmentsLazyQuery,
	useGetNotSubmittedAssignmentsLazyQuery,
	useGetSubmittedAssignmentsLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Order_By, Submission } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { ASSIGNMENT_PER_VIEW, AssignmentSortOrder, getComparisonDate } from 'src/utils'

const useAssignments = () => {
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const { data: classes } = useGetClassesByStudentQuery({ variables: { studentId }, skip: !studentId })
	const [selectedClassId, setSelectedClassId] = useState(0)
	const [selectedClassName, setSelectedClassName] = useState('')
	const [selectedDateFilter, setSelectedDateFilter] = useState(DateFilterEnum.allTime)
	const [submittedSort, setSubmittedSort] = useState(AssignmentSortOrder.AlphAsc)
	const [notSubmittedSort, setNotSubmittedSort] = useState(AssignmentSortOrder.AlphAsc)
	const [submittedLimit, setSubmittedLimit] = useState(ASSIGNMENT_PER_VIEW)
	const [notSubmittedLimit, setNotSubmittedLimit] = useState(ASSIGNMENT_PER_VIEW)
	const [notSubmittedAssignments, setNotSubmittedAssignments] = useState<Submission[]>([])
	const [submittedAssignments, setSubmittedAssignments] = useState<Submission[]>([])
	const [expiredAssignments, setExpiredAssignments] = useState<Submission[]>([])
	const [expiredSort, setExpiredSort] = useState(AssignmentSortOrder.AlphAsc)
	const [expiredLimit, setExpiredLimit] = useState(ASSIGNMENT_PER_VIEW)
	const { fromDate, toDate } = useMemo(() => getComparisonDate(selectedDateFilter), [selectedDateFilter])

	const [
		getNotSubmited,
		{
			data: notSubmittedAssignmentsData,
			loading: loadingNotSubmittedAssignments,
			refetch: fetchMoreNotSubmitted
		}
	] = useGetNotSubmittedAssignmentsLazyQuery({
		variables: {
			classId: selectedClassId,
			studentId,
			fromDate,
			toDate,
			limit: notSubmittedLimit,
			orderAlph:
				notSubmittedSort === AssignmentSortOrder.AlphAsc
					? Order_By.Asc
					: notSubmittedSort === AssignmentSortOrder.AlphDesc
					? Order_By.Desc
					: undefined,
			orderDate:
				notSubmittedSort === AssignmentSortOrder.DateAsc
					? Order_By.Asc
					: notSubmittedSort === AssignmentSortOrder.DateDesc
					? Order_By.Desc
					: undefined
		}
	})

	const [
		getExpiredd,
		{ data: expiredAssignmentsData, loading: loadingExpiredAssignments, refetch: fetchMoreExpired }
	] = useGetExpiredAssignmentsLazyQuery({
		variables: {
			classId: selectedClassId,
			studentId,
			fromDate,
			limit: expiredLimit,
			orderAlph:
				expiredSort === AssignmentSortOrder.AlphAsc
					? Order_By.Asc
					: expiredSort === AssignmentSortOrder.AlphDesc
					? Order_By.Desc
					: undefined,
			orderDate:
				expiredSort === AssignmentSortOrder.DateAsc
					? Order_By.Asc
					: expiredSort === AssignmentSortOrder.DateDesc
					? Order_By.Desc
					: undefined
		}
	})

	const [
		getSubmited,
		{ data: submittedAssignmentsData, loading: loadingSubmittedAssignments, refetch: fetchMoreSubmitted }
	] = useGetSubmittedAssignmentsLazyQuery({
		variables: {
			classId: selectedClassId,
			studentId,
			fromDate,
			toDate,
			limit: submittedLimit,
			orderAlph:
				submittedSort === AssignmentSortOrder.AlphAsc
					? Order_By.Asc
					: submittedSort === AssignmentSortOrder.AlphDesc
					? Order_By.Desc
					: undefined,
			orderDate:
				submittedSort === AssignmentSortOrder.DateAsc
					? Order_By.Asc
					: submittedSort === AssignmentSortOrder.DateDesc
					? Order_By.Desc
					: undefined
		}
	})

	const submittedAssignmentsCount = submittedAssignmentsData?.submission_aggregate.aggregate?.count
	const notSubmittedAssignmentsCount = notSubmittedAssignmentsData?.submission_aggregate.aggregate?.count
	const expiredAssignmentsCount = expiredAssignmentsData?.submission_aggregate.aggregate?.count

	useEffect(() => {
		getSubmited()
		getNotSubmited()
		getExpiredd()
	}, [])

	useEffect(() => {
		setNotSubmittedAssignments([])
		setSubmittedAssignments([])
		setExpiredAssignments([])
		setSubmittedLimit(ASSIGNMENT_PER_VIEW)
		setExpiredLimit(ASSIGNMENT_PER_VIEW)
		setNotSubmittedLimit(ASSIGNMENT_PER_VIEW)
	}, [selectedDateFilter, selectedClassId, submittedSort, notSubmittedSort])

	useEffect(() => {
		if (!classes) return
		const selectedClass = classes.class.find((i) => i.class_id === selectedClassId)
		if (selectedClass?.title) setSelectedClassName(selectedClass.title)
	}, [classes, selectedClassId])

	const seeMoreSubmitted = () => {
		fetchMoreSubmitted({
			limit: ASSIGNMENT_PER_VIEW,
			offset: submittedAssignments?.length
		})
	}

	useEffect(() => {
		if (submittedAssignments) {
			setSubmittedAssignments((submittedAssignments) =>
				[
					...submittedAssignments,
					...((submittedAssignmentsData?.submission ?? []) as Submission[])
				].filter(
					(item, index, array) =>
						array.findIndex((i) => i.submission_id === item.submission_id) === index
				)
			)
		}
		if (notSubmittedAssignments) {
			setNotSubmittedAssignments((notSubmittedAssignments) =>
				[
					...notSubmittedAssignments,
					...((notSubmittedAssignmentsData?.submission ?? []) as Submission[])
				].filter(
					(item, index, array) =>
						array.findIndex((i) => i.submission_id === item.submission_id) === index
				)
			)
		}
		if (expiredAssignmentsData) {
			setExpiredAssignments((expiredAssignments) =>
				[...expiredAssignments, ...((expiredAssignmentsData?.submission ?? []) as Submission[])].filter(
					(item, index, array) =>
						array.findIndex((i) => i.submission_id === item.submission_id) === index
				)
			)
		}
	}, [submittedAssignmentsData, notSubmittedAssignmentsData, expiredAssignmentsData])

	const seeMoreNotSubmitted = () => {
		fetchMoreNotSubmitted({
			limit: ASSIGNMENT_PER_VIEW,
			offset: notSubmittedAssignments?.length
		})
	}

	const seeMoreExpired = () => {
		fetchMoreExpired({
			limit: ASSIGNMENT_PER_VIEW,
			offset: expiredAssignments?.length
		})
	}

	const clearLimits = () => {
		setSubmittedLimit(ASSIGNMENT_PER_VIEW)
		setNotSubmittedLimit(ASSIGNMENT_PER_VIEW)
		setExpiredLimit(ASSIGNMENT_PER_VIEW)
	}

	return {
		selectedClassId,
		setSelectedClassId,
		selectedDateFilter,
		seeMoreExpired,
		setSelectedDateFilter,
		submittedSort,
		setSubmittedSort,
		expiredSort,
		setExpiredSort,
		notSubmittedSort,
		setNotSubmittedSort,
		submittedAssignments,
		notSubmittedAssignments,
		loadingNotSubmittedAssignments,
		loadingExpiredAssignments,
		loadingSubmittedAssignments,
		seeMoreSubmitted,
		seeMoreNotSubmitted,
		clearLimits,
		classes,
		submittedAssignmentsCount,
		notSubmittedAssignmentsCount,
		expiredAssignmentsCount,
		expiredLimit,
		submittedLimit,
		expiredAssignments,
		notSubmittedLimit,
		selectedClassName
	}
}

export const [AssignmentsProvider, useAssignmentsContext] = buildGenericContext(useAssignments)
