import { Fragment } from 'react'

import { Box, Button, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'

import useStyles from './ImageActionCard.styles'
import { ImageActionCardType } from './ImageActionCard.types'

export * from './ImageActionCard.types'

export const ImageActionCard = ({
	option: { id, title, name, imagePath, secondLink },
	isSelected = false,
	handleClick,
	cardHeight = 140,
	cardWidth,
	buttonProps
}: ImageActionCardType) => {
	const styles = useStyles({ imagePath, cardHeight, cardWidth, isSelected })

	return (
		<Fragment>
			{title && (
				<Typography variant="subtitle1">
					<b>{title}</b>
				</Typography>
			)}
			<Button
				data-cy={`button-${id}`}
				disableRipple={!!secondLink}
				size="large"
				color={isSelected ? 'secondary' : undefined}
				fullWidth={!cardWidth}
				onClick={handleClick}
				variant={isSelected ? 'outlined' : 'contained'}
				{...buttonProps}
				className={clsx(styles.buttonOption, buttonProps?.className)}>
				{isSelected && (
					<Box position="absolute" top={8} right={8}>
						<CheckCircleIcon fontSize="small" color="secondary" />
					</Box>
				)}
				{name && (
					<div>
						<Typography variant="subtitle1">
							<b>{name}</b>
						</Typography>
					</div>
				)}
				{secondLink && (
					<Box position="absolute" bottom={8}>
						{secondLink}
					</Box>
				)}
			</Button>
		</Fragment>
	)
}
