import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStudentAssignmentsStyles = makeStyles((theme: Theme) =>
	createStyles({
		date: {
			display: 'flex',
			alignItems: 'center',
			marginTop: 10,
			'& svg': {
				marginRight: '10px',
				fontSize: '23px'
			}
		},
		greenDate: {
			color: theme.palette.success.main
		},
		redDate: {
			color: '#FF3D00'
		},
		grade: {
			marginRight: 10,
			minWidth: 55,
			height: 55
		}
	})
)

export default useStudentAssignmentsStyles
