import { useMemo } from 'react'

import { CarouselOfAny } from 'src/components'
import { useGetFilterFramesQuery } from 'src/graphql/autogenerate/hooks'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { buildFrameUrl } from 'src/utils'

import { EffectThumbnail } from '../EffectThumbnail/EffectThumbnail'

export const FrameFilter = () => {
	const { setSelectedFrameFilter, selectedFrameFilter } = useVideoSubmitContext()

	const { data } = useGetFilterFramesQuery()
	const effects = data?.filter_frame || []

	const onFilterSelect = (frame: { imageUrl: string; id: number }) => {
		if (selectedFrameFilter?.filter_frame_id === frame.id) {
			setSelectedFrameFilter(undefined)
		} else {
			setSelectedFrameFilter({ active: true, image_path: frame.imageUrl, filter_frame_id: frame.id })
		}
	}

	const carousel = useMemo(
		() => (
			<CarouselOfAny
				slidesPerPage={{ sm: 2, md: 3, xl: 4 }}
				spacing={1}
				centerSlide
				arrowType="secondary"
				data={[
					...(effects?.map((effect) => ({
						element: (
							<EffectThumbnail
								key={effect.filter_frame_id}
								name={effect.frame_name || `${effect.image_path}`}
								imageUrl={buildFrameUrl(effect.image_path)}
								selectable
								selected={selectedFrameFilter?.filter_frame_id === effect.filter_frame_id}
								onClick={() =>
									onFilterSelect({ imageUrl: effect.image_path, id: effect.filter_frame_id })
								}
							/>
						),
						linkTo: {}
					})) || [])
				]}
				renderItem={(item) => (item?.element ? item?.element : <div></div>)}
			/>
		),
		[effects.length, selectedFrameFilter]
	)

	return carousel
}
