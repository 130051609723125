import { CSSProperties, useEffect, useState } from 'react'

import { Avatar, styled } from '@material-ui/core'
import { Teacher, Student } from 'src/graphql/autogenerate/schemas'

const StyledAvatar = styled(Avatar)({
	fontWeight: 'bold',
	background: 'linear-gradient(225deg, #F4C247 0%, #D43F48 100%)'
})

type UserAvatarProps = {
	user:
		| Pick<Teacher, '__typename' | 'name_first' | 'name_last' | 'image_path'>
		| Pick<Student, '__typename' | 'first_name' | 'last_name' | 'image_path'>
	style?: CSSProperties
}

export const UserAvatar = ({ user, style }: UserAvatarProps) => {
	const [userInitials, setUserInitials] = useState('')

	useEffect(() => {
		let initials = ''
		if (user.__typename === 'teacher') {
			initials += user.name_first?.[0]?.toUpperCase() ?? ''
			initials += user.name_last?.[0]?.toUpperCase() ?? ''
		} else if (user.__typename === 'student') {
			initials += user.first_name?.[0]?.toUpperCase() ?? ''
			initials += user.last_name?.[0]?.toUpperCase() ?? ''
		}
		setUserInitials(initials)
	}, [user])

	return (
		<StyledAvatar src={user.image_path ?? undefined} style={style}>
			<svg viewBox="0 0 100 100">
				<text x="21" y="64" fontSize="38" fill="white" opacity="87%">
					{userInitials}
				</text>
			</svg>
		</StyledAvatar>
	)
}
