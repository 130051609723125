import { useState } from 'react'

import { Box, CircularProgress, IconButton, TextField, Typography, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import { Autocomplete } from '@material-ui/lab'
import { SearchType } from 'src/@types'
import { getCyKey, limitString, SEE_MORE_RESULTS_NAME } from 'src/utils'

import { CircledCross } from '../../../assets/icons'
import { useStyles } from '../../Teacher/scenes/6-Lessons/scenes/LessonUpload/styles'
import { useLoginStyles } from '../styles'

type InputListProps = {
	items: SearchType[]
	selectedValue?: SearchType
	searchValue: string
	setSearchValue: (name: string) => void
	setSelectedValue: (value?: SearchType) => void
	placeholder?: string
	disabled?: boolean
	totalAmount?: number
	loadingData?: boolean
}

export const SignUpAutocomplete = ({
	items,
	setSearchValue,
	searchValue,
	selectedValue,
	setSelectedValue,
	placeholder,
	disabled = false,
	totalAmount,
	loadingData,
	...props
}: InputListProps) => {
	const classes = useStyles()
	const theme = useTheme()
	const [loading, setLoading] = useState(false)
	const [timer, setTimer] = useState(0)
	const loginClasses = useLoginStyles({ searchValue, loading })

	return (
		<Autocomplete
			data-cy={getCyKey(SignUpAutocomplete, 'autoComplete')}
			{...props}
			value={selectedValue}
			autoComplete={true}
			autoHighlight
			disabled={disabled}
			classes={{ endAdornment: classes.endAdornment, noOptions: loginClasses.noOptions }}
			ListboxProps={{
				className: [classes.listBox, loginClasses.listBox].join(' ')
			}}
			filterOptions={(x) => {
				if (totalAmount && totalAmount > x.length)
					return [...x, { name: SEE_MORE_RESULTS_NAME, description: '', id: 1000 }]

				return x
			}}
			getOptionDisabled={(option) => option.name === SEE_MORE_RESULTS_NAME}
			style={{ maxHeight: '50px' }}
			noOptionsText={
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					style={{
						color:
							loading || loadingData
								? theme.palette.common.black
								: !searchValue
								? theme.palette.secondary.main
								: theme.palette.warning.main,
						width: '100%'
					}}>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						{loading ? null : !searchValue ? <InfoIcon /> : <img src={CircledCross} />}
						<Typography style={{ paddingLeft: '15px' }}>
							{loading || loadingData
								? 'Loading'
								: !searchValue && items.length
								? 'Type something'
								: !items.length
								? 'No Items'
								: 'Not Found'}
							{(loading || loadingData) && <CircularProgress color="secondary" size={20} />}
						</Typography>
					</Box>
					{!loading && !searchValue && <CloseIcon style={{ cursor: 'pointer' }} />}
				</Box>
			}
			options={items}
			getOptionLabel={(el) =>
				el.name +
				(el.description ? ` (${limitString(el.description, { limit: 60 - el.name.length })})` : '')
			}
			onChange={(_, val) => {
				// on select value
				if (val?.id === selectedValue?.id) return
				setSelectedValue(val ? { ...val } : undefined)
			}}
			onInputChange={(_, search) => {
				// on type a value
				if (search === searchValue) return
				setLoading(true)
				clearTimeout(timer)
				setTimer(
					window.setTimeout(() => {
						setSearchValue(search || '')
						setLoading(false)
					}, 1000)
				)
			}}
			renderInput={(params) => (
				<Box position="relative">
					<TextField {...params} variant="outlined" color="secondary" placeholder={placeholder || ''} />
					{loading && (
						<IconButton aria-label="search" disabled style={{ position: 'absolute', right: 10 }}>
							<CircularProgress color="secondary" size={20} />
						</IconButton>
					)}
				</Box>
			)}
		/>
	)
}
