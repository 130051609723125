import { PagesProps } from 'src/@types'
import { useGetCatalogByPkQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { CatalogVideosBody } from 'src/scenes/Teacher/scenes/8-Videos/components/CatalogVideosBody/CatalogVideosBody'
import { capitalize } from 'src/utils'
import { buildBreadCrumbs } from 'src/utils/utils'

export const CatalogVideos = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const params = useWurrlyParams<typeof Pages.CatalogItemVideos.params[number]>()

	const { catalogId } = params
	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})

	const catalogName = catalogData?.catalog_by_pk?.name

	// TODO: fix breadcrumbs logic here

	const title = catalogData?.catalog_by_pk?.name || 'Videos'

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: capitalize(catalogName)
			}
		],

		params
	})

	return (
		<CatalogVideosBody
			page={page}
			title={title}
			catalogId={catalogId}
			teacherId={teacherId}
			breadcrumbs={breadcrumbs}
		/>
	)
}
