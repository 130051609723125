import { Box, CircularProgress, Typography } from '@material-ui/core'
import { AccordionWrapper } from 'src/components/AccordionWrapper'
import { LinkButton } from 'src/components/Buttons/LinkButton'
import { useLessonsWrapper } from 'src/components/Lessons/useLessonsWrapper'
import { AllClassProductsCard } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/AllClassProductsCard'

export const LessonsWrapper = () => {
	const { data, loading, count, handleViewAll, type } = useLessonsWrapper()

	return (
		<Box>
			<Box>
				{loading && (
					<Box m={3} pt={3} textAlign="center">
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}

				{!loading && (
					<Box pt={3}>
						<AccordionWrapper
							defaultExpanded
							summary={
								<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
									<Typography variant="h6">
										<b>Lessons ({count})</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />
									<LinkButton label="View All" onClick={() => handleViewAll()} />
								</Box>
							}
							details={data.map((item, index) => (
								<AllClassProductsCard
									addAssigment={true}
									addToClass={false}
									key={`AllClassProductsCard-${type}-${index}`}
									data={item}
									type={type}
								/>
							))}
							actions={null}
						/>
					</Box>
				)}
			</Box>
		</Box>
	)
}
