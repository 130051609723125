import { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { noop } from 'lodash'
import moment from 'moment'
import { PagesProps } from 'src/@types'
import { CartIcon } from 'src/assets/icons/CustomIcons'
import { ListVideos, MainTitle } from 'src/components'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useGetPlaylistQuery } from 'src/graphql/autogenerate/hooks'
import { Maybe, Playlist, Product_Name_Enum, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { useProfileSubscription } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useProfileSubscription'
import { buildBreadCrumbs, buildImagePath, slugToName } from 'src/utils'

import { AddPlaylistToClass } from '../components/AddPlaylistToClass'

export const PlaylistDetails = ({ page, teacherId }: PagesProps) => {
	const params = useWurrlyParams<typeof Pages.PlaylistDetails.params[number]>()
	const playlistId = params.playlistId
	const [playlist, setPlaylist] = useState<Playlist>()
	const [isAddPlaylisToClassOpen, setIsAddPlaylistToClassOpen] = useState(false)
	const { data: getPlaylistData, loading: getPlaylistLoading } = useGetPlaylistQuery({
		variables: {
			playlistId
		}
	})

	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [isOpen, setIsOpen] = useState(false)

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: 'Playlist'
			},
			{
				index: 2,
				overrideName: slugToName(getPlaylistData?.playlist[0]?.name || '')
			}
		],
		params: {
			playlistId
		}
	})

	// scroll to top
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if (!getPlaylistLoading && getPlaylistData && getPlaylistData.playlist[0]) {
			setPlaylist(getPlaylistData.playlist[0] as Playlist)
			setSubscription(getPlaylistData.playlist[0]?.is_purchased_by_teacher)
			setVisibility(getPlaylistData.playlist[0]?.lockable_content?.visibility)
		}
	}, [getPlaylistData, getPlaylistLoading])

	const { data, loading: loadingSubscriptions } = useProfileSubscription()
	const [expiresAt, setExpiresAt] = useState<string>()
	const [showExpiresAt, setShowExpiresAt] = useState<boolean>(false)

	useEffect(() => {
		if (data && !loadingSubscriptions) {
			const url = window.location.href

			const matchingElement = data.find((element) => {
				return url.includes(`${element.productType}/${element.productId}`)
			})
			const currentDate = moment()
			const targetDate = moment(matchingElement?.expiresAt)

			const duration = moment.duration(targetDate.diff(currentDate))
			const days = duration.asDays()
			setExpiresAt(days.toFixed(0))
			setShowExpiresAt(!matchingElement || duration.asDays() < 0 ? false : true)
		}
	}, [data])

	const AssetToDisplay = playlist?.display_video
		? playlist?.video_path && (
				<ReactCustomVideoPlayer
					url={playlist.video_path}
					light={playlist.video_thumbnail_image_path || ''}
					width={465}
					height={265}
				/>
		  )
		: playlist?.header_image_path && (
				<img width={465} height={265} src={buildImagePath(playlist?.header_image_path) ?? ''} />
		  )

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadCrumbs}
				title={playlist?.name ?? page.name}
				description={playlist?.description ?? ''}
				assetsDisplay={AssetToDisplay || <></>}
				rightActions={[
					visibility !== Visibility_Enum.ForSale ||
					(subscription && visibility === Visibility_Enum.ForSale)
						? {
								variant: 'contained',
								color: 'primary',
								className: 'addClass',
								endIcon: <AddIcon />,
								name: 'Add Playlist to Class',
								onClick: (e) => {
									e.stopPropagation()
									setIsAddPlaylistToClassOpen(true)
								}
						  }
						: {
								variant: 'contained',
								startIcon: <CartIcon style={{ color: '#221F31' }} />,
								onClick: handleOpenProductDialog,
								name: 'SUBSCRIBE TO PLAYLIST',
								style: {
									minWidth: '172px',
									background: 'linear-gradient(256.66deg, #FFC20C 21.57%, #EDA42B 61.82%)'
								}
						  }
				]}
			/>
			{showExpiresAt && (
				<Typography
					variant="overline"
					style={{
						fontSize: '14px'
					}}>
					Subscription expires on:{' '}
					<b
						style={{
							color: '#FFC20C'
						}}>
						{expiresAt} days
					</b>
				</Typography>
			)}
			<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>There are {playlist?.tip_playlist?.length ?? '0'} Videos in this Playlist</b>
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs>
					{!getPlaylistLoading ? (
						<ListVideos
							teacherId={teacherId}
							data={playlist?.tip_playlist?.map((item) => item.tip) ?? []}
							spacing={1.5}
						/>
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
				</Grid>
			</Grid>
			<AddPlaylistToClass
				isOpen={isAddPlaylisToClassOpen}
				setIsOpen={setIsAddPlaylistToClassOpen}
				playlist={playlist}
			/>
			{isOpen && playlistId && (
				<SelectProductDialog
					onSuccess={noop}
					productName={Product_Name_Enum.Playlist}
					productId={playlistId}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
		</Box>
	)
}
