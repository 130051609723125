import { Box, CircularProgress } from '@material-ui/core'

import { PagesProps } from '../../../../@types'
import { ClassList } from '../../../../components/ClassList'
import { StudentPages } from '../../../../routes/studentpages'
import { ClassTitle } from './components/ClassTitle'
import { useClasses } from './useClasses'

export const Classes = (props: PagesProps) => {
	const { loading, classes } = useClasses()

	return (
		<Box>
			<ClassTitle {...props} />
			<ClassList classes={classes} pages={StudentPages} skipIfOnlyOne />
			{loading && <CircularProgress color="secondary" size={30} />}
		</Box>
	)
}
