import React, { useState } from 'react'

import { Box, Grid, styled, Typography } from '@material-ui/core'
import { useSetGoalMutation } from 'src/graphql/autogenerate/hooks'
import { GOALSICONS, buildImagePath, DAYS } from 'src/utils'

import { BaseDialog } from '../../../../components'

type HowToEarnBadgesProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	studentId: number
}

const StyledBadge = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	padding: '.5em',
	textAlign: 'center',
	justifyContent: 'center',
	'& img': {
		width: '140px',
		height: '140px',
		marginLeft: 'auto',
		marginRight: 'auto'
	}
})

export const SetGoal = ({ isOpen, setIsOpen, studentId }: HowToEarnBadgesProps) => {
	const [daysSelected, setDaysSelected] = useState(0)
	const [isSuccessOpen, setIsSuccessOpen] = useState(false)
	const [SetGoalMutation] = useSetGoalMutation()

	const closeModal = () => {
		setIsOpen(false)
		setIsSuccessOpen(false)
	}

	const handleSetGoalMutation = async () => {
		try {
			await SetGoalMutation({
				variables: { studentId, weeklyGoal: daysSelected },
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'student_goal'
					})
				}
			})
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<Box>
			<BaseDialog
				confirmLabel="Set Goal"
				onConfirm={() => {
					setIsOpen(false)
					setIsSuccessOpen(true)
					handleSetGoalMutation()
				}}
				onConfirmProps={{ style: { width: '141px' } }}
				typographyProps={{ style: { fontWeight: 'bold' } }}
				open={isOpen}
				onClose={closeModal}
				onDiscard={closeModal}
				discardLabel="Cancel"
				dividers={false}
				body={<GoalBody daysSelected={daysSelected} setDaysSelected={setDaysSelected} />}
				paperProps={{ style: { width: '506px', height: '654px' } }}
				bodyProps={{ style: { justifyContent: 'center', display: 'flex' } }}
			/>
			<BaseDialog
				confirmLabel="Got it!"
				onConfirm={closeModal}
				onConfirmProps={{
					style: {
						margin: '0 auto',
						width: '220px',
						padding: '0px'
					}
				}}
				typographyProps={{ style: { fontWeight: 'bold' } }}
				open={isSuccessOpen}
				onClose={closeModal}
				dividers={false}
				body={<GreatJob />}
				paperProps={{ style: { width: '447px', height: '456px' } }}
				bodyProps={{ style: { justifyContent: 'center', display: 'flex' } }}
			/>
		</Box>
	)
}

const StyledGrid = styled(Grid)({
	width: '100%',
	height: '100%',
	justifyContent: 'space-between',
	alignItems: 'center'
})

type GoalBodyProps = {
	daysSelected: number
	setDaysSelected: React.Dispatch<React.SetStateAction<number>>
}

const GoalBody = ({ daysSelected, setDaysSelected }: GoalBodyProps) => {
	const BACKGROUND = GOALSICONS[0]
	const DARTBOARD = GOALSICONS[2]

	return (
		<Box>
			<StyledBadge>
				<Box style={{ position: 'relative', width: '140px', margin: 'auto' }}>
					<img
						src={buildImagePath(BACKGROUND)}
						alt={`BACKGROUND`}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
						}}></img>
					<img
						src={buildImagePath(DARTBOARD)}
						alt={`BACKGROUND`}
						style={{
							position: 'absolute',
							margin: '33px',
							width: '74px',
							height: '74px',
							top: '0',
							right: '0'
						}}></img>
				</Box>
				<Typography
					style={{
						display: 'flex',
						fontSize: '32px',
						marginTop: '12px',
						marginBottom: '12px',
						justifyContent: 'center'
					}}>
					<strong>The more you practice, the quicker you improve!</strong>
				</Typography>
				<Typography variant="body1">
					Set yourself some goals for healthy habits, and we'll reward you when you reach them!
				</Typography>
				<Typography variant="body1" style={{ marginTop: '30px' }}>
					How many times per week would you like to practice?
				</Typography>
				<Box style={{ height: '117px' }}>
					<StyledGrid container>
						{DAYS.map((days) => (
							<Grid key={days} item xs={12} sm={1}>
								<Box
									onClick={() => {
										setDaysSelected(days)
									}}
									style={{ height: '39px', width: '39px', padding: '0px', cursor: 'pointer' }}>
									<Typography
										style={{
											display: 'flex',
											border: '2px solid #FFC20C',
											borderRadius: '39px',
											height: '39px',
											width: '39px',
											alignItems: 'center',
											justifyContent: 'center',
											color: '#FFC20C',
											backgroundColor: days === daysSelected ? '#FFF3CE' : ''
										}}>
										{days}
									</Typography>
								</Box>
							</Grid>
						))}
					</StyledGrid>
				</Box>
			</StyledBadge>
		</Box>
	)
}

const GreatJob = () => {
	const BACKGROUND = GOALSICONS[0]
	const CONFETTI = GOALSICONS[1]

	return (
		<Box>
			<StyledBadge>
				<Box style={{ position: 'relative', width: '140px', margin: 'auto' }}>
					<img
						src={buildImagePath(BACKGROUND)}
						alt={`BACKGROUND`}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
						}}></img>
					<img
						src={buildImagePath(CONFETTI)}
						alt={`BACKGROUND`}
						style={{
							position: 'absolute',
							margin: '33px',
							width: '74px',
							height: '74px',
							top: '0',
							right: '0'
						}}></img>
				</Box>
				<Typography
					style={{
						display: 'flex',
						fontSize: '32px',
						marginTop: '12px',
						marginBottom: '12px',
						justifyContent: 'center'
					}}>
					<strong>Great job!</strong>
				</Typography>
				<Typography variant="body1">
					You have set your weekly Goal. We'll reward you when you reach them!
				</Typography>
			</StyledBadge>
		</Box>
	)
}
