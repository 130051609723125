import { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { PagesProps } from 'src/@types'
import { DeleteItemDialog, MainTitle } from 'src/components'
import { useDeleteWurrlyMutation } from 'src/graphql/autogenerate/hooks'
import { buildBreadCrumbs, slugToName } from 'src/utils'

import { useClassStudentWurrliesDetailsContext } from '../useClassStudentWurrliesDetails'

export const ClassStudentWurrliesDetailsTitle = ({ page }: PagesProps) => {
	const { studentId, wurrlyDetails, wurrlyDetailsLoading, refetch } = useClassStudentWurrliesDetailsContext()
	const [wurrlyDetailIdToDelete, setWurrlyDetailIdToDelete] = useState<number>()
	const [deleteWurrly] = useDeleteWurrlyMutation()

	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(
					`${wurrlyDetails?.student?.first_name ?? ''} ${wurrlyDetails?.student?.last_name ?? ''}`
				)
			},
			{
				index: 2,
				overrideName: 'Wurrlies'
			},
			{
				index: 3,
				overrideName: 'Review'
			}
		],
		params: {
			studentId
		}
	})

	return (
		<Box>
			{wurrlyDetailsLoading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!wurrlyDetailsLoading && (
				<MainTitle
					breadcrumbs={breadCrumbs}
					title="Student Wurrly Review"
					description={`These are the details of the Wurrlies that
					${wurrlyDetails?.student?.first_name || ''}
					${' '}
					${wurrlyDetails?.student?.last_name || ''} has submitted`}
					rightActions={[
						{
							style: {
								backgroundColor: '#f44336',
								color: 'white',
								height: 56,
								padding: '0px 30px'
							},
							startIcon: <DeleteIcon />,
							name: 'Delete',
							onClick: () => {
								if (wurrlyDetails) {
									setWurrlyDetailIdToDelete(wurrlyDetails?.wurrly_id)
								}
							}
						}
					]}
				/>
			)}
			<DeleteItemDialog
				isOpen={!!wurrlyDetailIdToDelete}
				setIsOpen={setWurrlyDetailIdToDelete}
				onConfirm={async () => {
					if (wurrlyDetailIdToDelete && refetch) {
						await deleteWurrly({
							variables: {
								wurrlyId: wurrlyDetailIdToDelete
							}
						})
						refetch()
					}
				}}
				itemName="Wurrly"
			/>
		</Box>
	)
}
