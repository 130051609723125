import { Fragment, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Box, CircularProgress, Divider, List } from '@material-ui/core'
import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import { isEmpty, noop } from 'lodash'
import { useHistory } from 'react-router'
import { cache } from 'src/apollo/state'
import {
	GetFavoriteVideosNotInClassDocument,
	useDeleteVideoByPkMutation,
	useInsertTipsToClassMutation
} from 'src/graphql/autogenerate/hooks'
import { Class, Class_Tip_Insert_Input, Tip, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { mutationDeleteFavorite, mutationInsertFavorite } from 'src/scenes/Teacher/queries'
import { LessonsSong } from 'src/scenes/Teacher/scenes/7-MusicCatalog/components'
import { VideoPlayer } from 'src/scenes/Teacher/scenes/8-Videos/components/VideoPlayer'
import {
	TypeEnum,
	buildImagePath,
	buildRouteParameters,
	buildVideoPath,
	concatenate,
	createYoutubeThumbnailLink,
	getCyKey,
	getVideoGenres,
	isFromYoutube
} from 'src/utils'

import { AddToClassDialog, DeleteItemDialog } from '.'
import { RemoveFavoriteDialog } from './'
import { ListItemSong } from './ListItemSong'

const useStyles = makeStyles((theme: Theme) => ({
	boxVideos: {
		padding: 0,
		'& .itemBox': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	},
	boxLoading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.common.black, 0.1)
	}
}))

type ListVideosProps = {
	teacherId: number
	classId?: number
	spacing?: number
	lineDivider?: boolean
	data: Tip[]
	dataCy?: string
	viewButton?: boolean
	editButton?: boolean
	copyLinkButton?: boolean
	removeButton?: boolean
	overrideAddToClass?: (TipId: number) => Promise<void>
	overrideDelete?: (TipId: number) => void
	returnItemAlone?: boolean
	listItem?: Tip
	listItemNumber?: number
	disableRemoveFromPlaylist?: boolean
}

export const ListVideos = ({
	teacherId,
	classId,
	spacing = 0,
	lineDivider,
	data,
	dataCy,
	viewButton,
	editButton,
	copyLinkButton,
	removeButton,
	overrideDelete,
	overrideAddToClass,
	returnItemAlone,
	listItem,
	listItemNumber,
	disableRemoveFromPlaylist
}: ListVideosProps) => {
	const classes = useStyles(spacing)
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()

	const [video, setVideo] = useState<Tip>()
	const [lessonSelectedVideoId, setLessonSelectedVideoId] = useState<number>()
	const [addToClassVideo, setAddToClassVideo] = useState<number>()
	const [removeVideoFn, setRemoveVideoFn] = useState<() => Promise<void>>()
	const [loading, setLoading] = useState(false)
	const [dontAsk, setDontAsk] = useState(false)
	const [favoriteVideo, setFavoriteVideo] = useState(false)
	const [btnCopyTooltip, setBtnCopyTooltip] = useState('Copy Link')
	const [videoClasses, setVideoClasses] = useState<Class[]>([])
	const [searchText, setSearchText] = useState('')
	const [handleFavoriteFn, setHandleFavoriteFn] = useState<(isFavorite: boolean, noDialog: boolean) => void>(
		() => {}
	)
	const [deleteVideoId, setDeleteVideoId] = useState<number | undefined>()

	const [deleteVideo] = useDeleteVideoByPkMutation()

	const openTooltip = () => setBtnCopyTooltip('Copied!')

	const resetTooltip = () => setTimeout(() => setBtnCopyTooltip('Copy Link'), 100)

	const onCopyCode = (videoPath: string) => {
		navigator.clipboard.writeText(buildVideoPath(videoPath ?? ''))
		openTooltip()
	}

	const [insertTips] = useInsertTipsToClassMutation()

	useEffect(() => {
		if (!addToClassVideo) {
			setVideoClasses([])
		}
	}, [addToClassVideo])

	const ItemVideo = (props: { video: Tip }): JSX.Element => {
		const { video } = props
		const hasPaid = video?.is_purchased_by_teacher || false
		const isForSale = video?.lockable_content?.visibility === Visibility_Enum.ForSale
		const videoVisibility = video?.lockable_content?.visibility

		const [addFavoriteVideo, { loading: loadingAdd, error: errorAdd }] = useMutation(
			mutationInsertFavorite(TypeEnum.Video)
		)
		const [deleteFavoriteVideo, { loading: loadingDel, error: errorDel }] = useMutation(
			mutationDeleteFavorite(TypeEnum.Video)
		)
		const [isFavorite, setIsFavorite] = useState(!!video.is_favorite) // !!video.is_favorite

		const handleEdit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation()
			history.push(
				buildRouteParameters(Pages.EditVideo, {
					tipId: video.tip_id
				})
			)
		}

		const removeFavorite = async () => {
			setRemoveVideoFn(undefined) // close Remove Favorited Dialog

			setLoading(true)
			await deleteFavoriteVideo({
				variables: { teacherId, itemId: video.tip_id },
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_songs_favorites'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_tip_favorite'
					})
				},
				refetchQueries: [GetFavoriteVideosNotInClassDocument]
			})
			setLoading(false)

			if (!errorDel && !loadingDel) {
				setIsFavorite(false)
				setFavoriteVideo(false)
			}
		}

		const addFavorite = async () => {
			setLoading(true)
			await addFavoriteVideo({
				variables: { teacherId, itemId: video.tip_id },
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_songs_favorites'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_tip_favorite'
					})
				},
				refetchQueries: [GetFavoriteVideosNotInClassDocument]
			})
			setLoading(false)

			if (!errorAdd && !loadingAdd) {
				setIsFavorite(true)
				setFavoriteVideo(true)
			}
		}

		const handleFavorite = (isFavorite: boolean) => {
			if (isFavorite) {
				if (teacher?.teacher_setting?.favorites_dont_ask_again_to_delete) removeFavorite()
				else setRemoveVideoFn(() => removeFavorite)
			} else addFavorite()
		}

		const openVideoPlayer = () => {
			setHandleFavoriteFn(() => handleFavorite)
			setFavoriteVideo(isFavorite)
			setVideo(video)
		}

		if (!video) return <Fragment></Fragment>

		const playlistName = video?.tip_playlists?.[0]?.playlist?.name
		const tooltipText =
			disableRemoveFromPlaylist && playlistName
				? `This video belongs to "${playlistName}" playlist, and cannot be deleted individually`
				: undefined
		const imageUrl = useMemo(() => {
			return isFromYoutube(video.resource_path)
				? createYoutubeThumbnailLink(video.resource_path)
				: buildImagePath(video.image_path) || ''
		}, [video])

		return (
			<Box position="relative">
				{(loadingAdd || loadingDel) && (
					<Box className={classes.boxLoading}>
						<CircularProgress color="secondary" size={20} />
					</Box>
				)}
				<Box
					data-cy={getCyKey(ListVideos)}
					style={
						isForSale && !hasPaid
							? {
									cursor: 'default'
							  }
							: {
									cursor: 'pointer'
							  }
					}
					onClick={isForSale && !hasPaid ? noop : openVideoPlayer}>
					<ListItemSong
						isVideo
						imageUrl={imageUrl}
						title={video.title}
						author={video.artist?.name ?? ''}
						genres={`• ${getVideoGenres(video)}`}
						resetTooltip={resetTooltip}
						btnCopyTooltip={btnCopyTooltip}
						visibility={videoVisibility}
						isPurchased={hasPaid}
						btnFavorite={{
							disabled: isForSale && !hasPaid,
							selected: isFavorite,
							onClick: (e) => {
								e.stopPropagation()
								handleFavorite(isFavorite)
							}
						}}
						btnEdit={
							editButton
								? {
										disabled: isForSale,

										onClick: handleEdit
								  }
								: undefined
						}
						btnCopyLink={
							copyLinkButton
								? {
										disabled: isForSale && !hasPaid,

										onClick: (e) => {
											e.stopPropagation()
											onCopyCode(video.resource_path)
										}
								  }
								: undefined
						}
						btnRemove={
							removeButton
								? {
										disabled: isForSale || Boolean(tooltipText),
										tooltipText,
										onClick: (e) => {
											e.stopPropagation()
											if (overrideDelete) {
												overrideDelete(video.tip_id)
											} else {
												setDeleteVideoId(video.tip_id)
											}
										}
								  }
								: undefined
						}
						btnAddClass={
							!viewButton
								? {
										disabled: isForSale && !hasPaid,
										onClick: (e) => {
											e.stopPropagation()
											if (overrideAddToClass) {
												overrideAddToClass(video.tip_id)
											} else {
												setHandleFavoriteFn(() => handleFavorite)
												setFavoriteVideo(isFavorite)
												setAddToClassVideo(video.tip_id)
												setVideoClasses(video?.class_tips?.map((item) => item.class) || [])
											}
										}
								  }
								: undefined
						}
						btnView={
							viewButton
								? {
										disabled: isForSale && !hasPaid,
										onClick: (e) => {
											e.stopPropagation()
											openVideoPlayer()
										}
								  }
								: undefined
						}
					/>
				</Box>
			</Box>
		)
	}

	const handleDelete = async (): Promise<void> => {
		if (deleteVideoId && deleteVideoId !== 0) {
			deleteVideo({
				variables: {
					tipId: deleteVideoId
				},
				onCompleted: (data) => {
					cache.evict({
						id: `tip:${data?.delete_tip_by_pk?.tip_id}`
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'tip_aggregate'
					})
				}
			})
		}
	}

	const RenderElement = returnItemAlone ? (
		<>
			<Box
				data-cy={getCyKey(ListVideos, dataCy)}
				className="itemBox"
				key={concatenate(
					[listItemNumber as number, listItem?.tip_id as number, listItem?.artist_id ?? ''],
					'-'
				)}
				overflow="hidden"
				boxShadow={spacing > 0 ? 1 : 0}
				borderRadius={spacing > 0 ? 4 : 0}>
				<ItemVideo video={listItem as Tip} />
				{lineDivider && data.length !== (listItemNumber as number) + 1 && <Divider variant="middle" />}
			</Box>
		</>
	) : (
		<>
			<List className={classes.boxVideos}>
				{data
					.filter((d) => d && !isEmpty(d))
					.map((video, i) => (
						<Box
							data-cy={getCyKey(ListVideos, dataCy)}
							className="itemBox"
							key={concatenate([i, video.tip_id, video.artist_id ?? ''], '-')}
							overflow="hidden"
							boxShadow={spacing > 0 ? 1 : 0}
							borderRadius={spacing > 0 ? 4 : 0}>
							<ItemVideo video={video} />
							{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
						</Box>
					))}
			</List>
		</>
	)

	return (
		<Fragment>
			{RenderElement}

			<VideoPlayer
				classId={classId}
				video={video}
				setVideo={setVideo}
				onAddtoClass={() => {
					setVideoClasses(video?.class_tips.map((item) => item.class) || [])
					setAddToClassVideo(video?.tip_id)
				}}
				loading={loading}
				favoriteVideo={favoriteVideo}
				handleFavoriteFn={handleFavoriteFn}
				dontAsk={dontAsk}
			/>

			<LessonsSong
				teacherId={teacherId}
				lessonSelectedSongId={lessonSelectedVideoId}
				setLessonSelectedSongId={setLessonSelectedVideoId}
				isVideo
			/>

			<AddToClassDialog
				isOpen={!!addToClassVideo}
				setIsOpen={setAddToClassVideo}
				itemClasses={videoClasses}
				setSearchText={setSearchText}
				searchText={searchText}
				itemName="Video"
				title="Save Video to Class"
				description="This will save the Video so that you can view and teach right from your Class page.
				This content will be visible to the students that are in the class."
				onConfirm={async (selectedClasses) => {
					const array = [...selectedClasses]
					const classes = array.map((item) => {
						return {
							tip_id: addToClassVideo,
							class_id: item.class_id
						} as Class_Tip_Insert_Input
					})
					if (classes && classes.length) {
						await insertTips({
							variables: { tips: classes },
							update: (cache, { data }) => {
								const classesToUpdateInCache = data?.insert_class_tip?.returning
								if (!classesToUpdateInCache) return

								let identify
								classesToUpdateInCache.forEach((cls) => {
									identify = cache.identify(cls.class)

									cache.evict({
										id: identify,
										fieldName: 'class_tips'
									})
									cache.evict({
										id: identify,
										fieldName: 'class_tips_aggregate'
									})
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'tip_aggregate'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'tip'
								})
							}
						})
						setVideo(undefined)
					}
				}}
				handleFavorite={{
					handler: () => handleFavoriteFn(favoriteVideo, dontAsk),
					isFavorite: favoriteVideo
				}}
				isLoading={loading}
			/>

			<RemoveFavoriteDialog
				itemName="Video"
				pageItemName={Pages.Videos.name}
				removeItemFn={removeVideoFn}
				setRemoveItemFn={setRemoveVideoFn}
				dontAsk={dontAsk}
				setDontAsk={setDontAsk}
			/>
			<DeleteItemDialog
				itemName="Video"
				isOpen={!!deleteVideoId}
				setIsOpen={setDeleteVideoId}
				onConfirm={handleDelete}
			/>
		</Fragment>
	)
}
