import * as icons from '@material-ui/icons'
import stringSimilarity from 'string-similarity'

export const useIcon = (word: string) => {
	const iconsNames = Object.keys(icons)

	const matches = stringSimilarity.findBestMatch(word, iconsNames)
	const bestMathch = matches.bestMatch.target
	const Icon = icons[bestMathch as keyof typeof icons]

	return Icon
}
