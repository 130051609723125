import { Box, Button, Link, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import CallMadeIcon from '@material-ui/icons/CallMade'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import { Location } from 'history'
import { Link as LinkRD } from 'react-router-dom'
import { useLoginContext } from 'src/hooks/useLogin'

import { CarouselOfAny, ImageButton, ImageButtonProps } from '.'
import { useGetPlaylistsQuery } from '../graphql/autogenerate/hooks'
import { Order_By, Playlist } from '../graphql/autogenerate/schemas'
import { Pages, SlugEnum } from '../routes/teacherPages'
import { buildRouteParameters, getCyKey } from '../utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link: {
			color: theme.palette.secondary.dark
		},
		panel: {
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white,
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			},
			'& .MuiButton-root:first-child': {
				paddingLeft: theme.spacing(4),
				backgroundColor: fade(theme.palette.common.black, 0.3),
				color: theme.palette.common.white,
				boxShadow: 'none'
			}
		},
		banner: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '10/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		}
	})
)

export const FeaturedPlaylists = () => {
	const styles = useStyles()

	const { teacherData } = useLoginContext()
	const { data: getPlaylistsData } = useGetPlaylistsQuery({
		variables: { order_by: { order: Order_By.Asc }, teacher_id: teacherData.teacher_id }
	})
	const playlists = (getPlaylistsData?.playlist || []) as Playlist[]

	const LinkToPlaylists = (location: Location) => {
		return {
			...location,
			pathname: Pages.Playlists.path,
			state: {
				catalog: SlugEnum.Playlists
			}
		}
	}

	const LinePanel = ({ playlist }: { playlist: Playlist }) => {
		return (
			<Box className={styles.panel} display="flex" flexWrap="wrap" alignItems="center">
				<Button startIcon={<PlaylistPlayIcon />}>{playlist.tip_playlist?.length ?? '0'} Videos</Button>
				<div style={{ flexGrow: 1 }} />
				<Button endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}>View</Button>
			</Box>
		)
	}

	return (
		<>
			<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>Playlists</b>
				</Typography>
				<div style={{ flexGrow: 1 }} />
				<Link className={styles.link} component={LinkRD} to={LinkToPlaylists}>
					<b data-cy={getCyKey(FeaturedPlaylists, 'btnViewAllPlaylists')}>View All</b>
				</Link>
			</Box>
			<CarouselOfAny
				slidesPerPage={{ xs: 1, sm: 2 }}
				spacing={3}
				data={playlists.map((playlist) => ({
					backgroundImageUrl: playlist.image_path ?? '',
					title: (
						<Typography data-cy={getCyKey(FeaturedPlaylists, 'NamePlaylist')} style={{ fontSize: 28 }}>
							{playlist.name}
						</Typography>
					),
					element: <LinePanel playlist={playlist} />,
					linkTo: {
						pathname: buildRouteParameters(Pages.PlaylistDetails, { playlistId: playlist.playlist_id })
					}
				}))}
				renderItem={(item: ImageButtonProps) => (
					<ImageButton
						titleHeight={{ xs: 250, md: 277 }}
						className={styles.banner}
						backgroundColor={item.backgroundColor}
						backgroundImageUrl={item.backgroundImageUrl}
						title={item.title}
						titlePosition="initial"
						element={item.element}
						elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
						linkTo={item.linkTo}
					/>
				)}
			/>
		</>
	)
}
