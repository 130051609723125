import { useEffect, useState } from 'react'

import { cache } from 'src/apollo/state'
import { ProcessingStatusForPlayer } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useDeleteWurrlyReactionMutation,
	useGetClassWurrlyLazyQuery,
	useGetReactionsQuery,
	useGetWurrlyProcessingStatusSubscription,
	useInsertWurrlyReactionMutation,
	useUpdateWurrlyReactionByPkMutation
} from 'src/graphql/autogenerate/hooks'
import { Wurrly, Wurrly_Processing_Status_Enum, Wurrly_Reaction } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'

export type WURRLY_QUERY_REACTION = {
	label: string
	icon: string
	count: number
	reaction_id: number
}

const useClassWurrly = () => {
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const [classId, setClassId] = useState(0)
	const [wurrlyId, setWurrlyId] = useState(0)
	const [reactions, setReactions] = useState<Wurrly_Reaction[]>([])
	const [filteredReactions, setFilteredReactions] = useState<WURRLY_QUERY_REACTION[]>([])
	const [hasReacted, setHasReacted] = useState(false)
	const [getClassWurrlies, { data }] = useGetClassWurrlyLazyQuery()

	const wurrly = data?.wurrly[0] as Wurrly
	const wurrlyClass = wurrly?.class
	const student = wurrly?.student
	const wurrlyState = wurrly?.submission_wurrly?.state
	const submissionState = wurrly?.submission_wurrly?.submission?.state
	const wurrlyType = wurrly?.wurrly_type
	const wurrlyVisibility = wurrly?.privacy
	const owner = wurrly?.student_id_owner === studentId

	const [processingStatus, setProcessingStatus] = useState<ProcessingStatusForPlayer>({
		status: Wurrly_Processing_Status_Enum.Done,
		isLoading: true
	})
	const { data: processingStatusData, loading: processingStatusLoading } =
		useGetWurrlyProcessingStatusSubscription({
			variables: { wurrlyId }
		})

	const { data: allReactions } = useGetReactionsQuery()

	const [insertReaction, { data: insertReactionData, loading: loadingInsertReaction }] =
		useInsertWurrlyReactionMutation()
	const [deleteReaction, { data: deleteReactionData, loading: loadingDeleteReaction }] =
		useDeleteWurrlyReactionMutation()
	const [updateReaction, { data: updateReactionData, loading: loadingUpdateReaction }] =
		useUpdateWurrlyReactionByPkMutation()

	useEffect(() => {
		if (wurrly) {
			setReactions(wurrly.wurrly_reactions)
		}
	}, [wurrly])

	useEffect(() => {
		setProcessingStatus({
			status: processingStatusData?.wurrly_by_pk?.processing_status ?? Wurrly_Processing_Status_Enum.Done,
			isLoading: processingStatusLoading
		})
		if (processingStatusData?.wurrly_by_pk?.processing_status && !processingStatusLoading && wurrly) {
			cache.evict({
				id: cache.identify(wurrly),
				fieldName: 'final_resource_path'
			})
		}
	}, [processingStatusData, processingStatusLoading, wurrly])

	useEffect(() => {
		const reactionsMap = new Map()
		reactions.map((item) => {
			if (reactionsMap.has(item.reaction_id)) {
				reactionsMap.get(item.reaction_id).count += 1
			} else {
				const reaction = allReactions?.reaction.find(
					(reaction) => reaction.reaction_id === item.reaction_id
				)
				reactionsMap.set(item.reaction_id, {
					icon: reaction ? reaction.icon : '',
					count: 1,
					reaction_id: item.reaction_id
				})
			}
		})

		const res = Array.from(reactionsMap.values())
		setFilteredReactions(res)
		const reaction = reactions.find((reaction) => reaction.student_id === studentId)
		setHasReacted(reaction ? true : false)
	}, [reactions])

	useEffect(() => {
		if (classId && classId) getClassWurrlies({ variables: { classId, wurrlyId } })
	}, [classId, updateReactionData, insertReactionData, deleteReactionData])

	const updateFilteredReactions = (newReactions: WURRLY_QUERY_REACTION[]) => {
		setFilteredReactions(newReactions)
	}

	const loadingReaction = loadingDeleteReaction || loadingInsertReaction || loadingUpdateReaction

	return {
		wurrly,
		wurrlyClass,
		student,
		wurrlyState,
		setClassId,
		setWurrlyId,
		processingStatus,
		submissionState,
		wurrlyType,
		wurrlyVisibility,
		insertReaction,
		deleteReaction,
		updateReaction,
		wurrlyId,
		filteredReactions,
		reactions,
		updateFilteredReactions,
		hasReacted,
		setHasReacted,
		owner,
		allReactions,
		loadingReaction
	}
}

export const [ClassWurrlyProvider, useClassWurrlyContext] = buildGenericContext(useClassWurrly)
