import { useState } from 'react'

import { Box } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useHistory } from 'react-router-dom'
import { SyncIcon } from 'src/assets/icons/CustomIcons'
import { useGetInProgressCleverRosterLogSubscription } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { SyncClassDialog } from 'src/scenes/Teacher/scenes/2-Classes/components/SyncClassDialog'

import { PagesProps } from '../../../../../@types'
import { InfoDialog, MainTitle } from '../../../../../components'
import { Pages } from '../../../../../routes/teacherPages'
import { useClassesContext } from '../useClasses'

export const ClassTitle = ({ page }: PagesProps) => {
	const history = useHistory()
	const { loading, classes, teacher, refetch: refetchClasses } = useClassesContext()
	const {
		teacherData: { teacher_id: teacherId }
	} = useLoginContext()
	const [isLTIWarningOpen, setIsLTIWarningOpen] = useState(false)
	const [isSyncClassDialogOpen, setIsSyncClassDialogOpen] = useState(false)
	const [syncClassDisabled, setSyncClassDisabled] = useState(false)
	useGetInProgressCleverRosterLogSubscription({
		variables: { teacherId },
		onSubscriptionData: ({ subscriptionData }) => {
			if (subscriptionData.data?.clever_roster_log?.length) {
				setSyncClassDisabled(true)
			} else {
				setSyncClassDisabled((prevState) => {
					if (prevState) refetchClasses()

					return false
				})
			}
		}
	})

	const goToCreateClass = () => {
		// if some class is LTI (clever), then we
		if (teacher.is_enterprise && classes.some((c) => c.clever_id)) {
			setIsLTIWarningOpen(true)
		} else {
			history.push(Pages.ClassCreation.path)
		}
	}

	const openSyncClassDialog = () => {
		setIsSyncClassDialogOpen(true)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Manage your Classes and interact with your Students, all in one place."
				rightActions={[
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <SyncIcon />,
						name: 'Sync Class',
						disabled: syncClassDisabled,
						tooltipText: syncClassDisabled ? 'Sync in progess' : '',
						onClick: openSyncClassDialog
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <AddCircleIcon />,
						name: 'Create Class',
						disabled: loading,
						onClick: goToCreateClass
					}
				]}
			/>

			<InfoDialog
				open={isLTIWarningOpen}
				onClose={() => setIsLTIWarningOpen(false)}
				title="You are Using an LTI Sync Integration"
				body="Due to an LTI sync integration you cannot edit, delete or add Classes to Students. To add, edit Classes or Students, please amend the information on the LTI directly, or contact us for support."
				discardLabel="Take Me Back"
				onDiscard={() => {
					setIsLTIWarningOpen(false)
				}}
				confirmLabel="Contact us"
				onConfirm={() => {
					setIsLTIWarningOpen(false)
				}}
				icon="!"
			/>

			<SyncClassDialog open={isSyncClassDialogOpen} setOpen={setIsSyncClassDialogOpen} />
		</Box>
	)
}
