import * as yup from 'yup'

const limit = {
	songs: 10,
	videos: 5,
	files: 5
}
export const useStepFiveValidation = () => {
	const stepFiveValidation = yup.object({
		songs: yup.array().required()
	})

	return { stepFiveValidation, limit }
}
