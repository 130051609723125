import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { ReInviteIcon, ThinCheckIcon } from 'src/assets/icons'

import { InfoDialog } from './InfoDialog'

type OptionalMessages = {
	mainMessages?: {
		title?: string
		body?: string
		buttonLabel?: string
	}
	confirmation?: {
		title?: string
		body?: string
	}
}
type ReInviteDialogProps = {
	isOpen: boolean
	onConfirm: () => Promise<void>
	onDone?: () => void
	setIsOpen: React.Dispatch<React.SetStateAction<number | undefined>>
	optionalMessages?: OptionalMessages
}

export const ReInviteDialog = ({
	isOpen,
	setIsOpen,
	onConfirm,
	optionalMessages,
	onDone
}: ReInviteDialogProps) => {
	const [isOnSuccessOpen, setIsOnSuccessOpen] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	return (
		<Box>
			<InfoDialog
				open={isOnSuccessOpen}
				onClose={() => {
					setIsOnSuccessOpen(false)
				}}
				icon={<ThinCheckIcon />}
				title={optionalMessages?.confirmation?.title || `Student Re-Invited!`}
				body={optionalMessages?.confirmation?.body || `You have successfully re-invited this Student.`}
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnSuccessOpen(false)
					if (onDone) onDone()
				}}
			/>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {
					setIsOnErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
			/>
			<InfoDialog
				open={isOpen}
				onClose={() => {
					if (setIsOpen && !loading) setIsOpen(undefined)
				}}
				icon={<ReInviteIcon color="secondary" style={{ height: '50px', width: '50px' }} />}
				title={optionalMessages?.mainMessages?.title || `Would You Like to Re-Invite this Student?`}
				body={
					optionalMessages?.mainMessages?.body ||
					`We will re-send them an invite to join WURRLY as one of your Students.`
				}
				discardLabel="Cancel"
				onDiscard={() => {
					if (setIsOpen && !loading) setIsOpen(undefined)
				}}
				discardProps={{
					disabled: loading
				}}
				confirmLabel={optionalMessages?.mainMessages?.buttonLabel || 'Re-Invite Student'}
				confirmProps={{
					disabled: loading,
					color: 'secondary'
				}}
				onConfirm={async () => {
					try {
						setLoading(true)
						await onConfirm()
						setIsOpen(undefined)
						setIsOnSuccessOpen(true)
					} catch (error) {
						setIsOpen(undefined)
						setIsOnErrorOpen(true)
					} finally {
						setLoading(false)
					}
				}}
			/>
		</Box>
	)
}
