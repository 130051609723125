import { useEffect, useState } from 'react'

import { useScrollElement } from 'src/hooks'

export const useScroll = () => {
	// scroll to top
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
}

// TODO: Accept a DOM node or a reference as parameter and measure scroll of it
export const useScrollPercentage = () => {
	const [scrollLevel, setScrollLevel] = useState(0)
	const [nodeHeight] = useState(window.innerHeight)

	const scrollHandler = () => {
		const totalHeight = document.documentElement.offsetHeight - nodeHeight
		const currentScrollLevel = nodeHeight + document.documentElement.scrollTop - nodeHeight
		const percentage = Math.round((currentScrollLevel / totalHeight) * 100)
		setScrollLevel(percentage)
	}

	useEffect(() => {
		window.addEventListener('scroll', scrollHandler)

		return () => window.removeEventListener('scroll', scrollHandler)
	}, [])

	return { scrollLevel, nodeHeight }
}

export const useElementScrollPercentage = (element: React.RefObject<Element>, wait?: 200) => {
	const { y } = useScrollElement({ element, wait })
	const [scrollLevel, setScrollLevel] = useState(0)

	const getScrollableHeight = () => {
		if (!element.current) return 0

		return element.current.scrollHeight - element.current.clientHeight
	}

	useEffect(() => {
		const scrollableHeight = getScrollableHeight()
		const percentage = Math.round(((y ?? 0) / scrollableHeight) * 100)
		setScrollLevel(percentage)
	}, [y])

	return { scrollLevel }
}
