import { useEffect } from 'react'

import { Box, Typography, Button, Link } from '@material-ui/core'
import { Link as LinkRD } from 'react-router-dom'
import { ImageButton, ImageButtonProps } from 'src/components/Buttons/ImageButton'
import { CarouselOfAny } from 'src/components/CarouselOfAny/CarouselOfAny'
import { useGetAllPartnerLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Pages } from 'src/routes/teacherPages'
import { buildImagePath, buildRouteParameters } from 'src/utils'

import { useLessonsStyles } from '../Lessons.styles'

export const PartnersCarousel = () => {
	const styles = useLessonsStyles()
	const [getPartners, { data: partners }] = useGetAllPartnerLazyQuery()

	useEffect(() => {
		getPartners()
	}, [])

	const LinkToAllPartners = (location: Location) => {
		return {
			...location,
			pathname: Pages.AllPartners.path
		}
	}

	const partnersList = partners?.partner || []

	return (
		<>
			<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>Partners</b>
				</Typography>
				<div style={{ flexGrow: 1 }} />
				<Link className={styles.link} component={LinkRD} to={LinkToAllPartners}>
					<b>View All</b>
				</Link>
			</Box>

			<CarouselOfAny
				slidesPerPage={{ xs: 1, sm: 1 }}
				spacing={3}
				data={partnersList?.map((partner) => ({
					backgroundImageUrl: partner.banner_image_path ?? '',
					title: <Typography style={{ fontSize: 28 }}>{partner.title}</Typography>,
					subtitle: <Typography style={{ fontSize: 20 }}>{partner.banner_description}</Typography>,
					element: (
						<Box className={styles.partner}>
							<img className={styles.logo} src={buildImagePath(partner.logo_image_path)} />
							<Button>Go To Partner Page</Button>
						</Box>
					),
					linkTo: {
						pathname: buildRouteParameters(Pages.PartnerPage, { partnerId: partner.partner_id })
					}
				}))}
				renderItem={(item: ImageButtonProps) => (
					<Box position={'relative'}>
						<ImageButton
							fitToContainer
							className={styles.banner}
							titleHeight={{ xs: 250, md: 400 }}
							backgroundColor={item.backgroundColor}
							backgroundImageUrl={item.backgroundImageUrl}
							title={item.title}
							subtitle={item.subtitle}
							titlePosition="initial"
							element={item.element}
							elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
							linkTo={item.linkTo}
							partner={true}
							imageHeight={{ xs: 250, md: 400 }}
						/>
					</Box>
				)}
			/>
		</>
	)
}
