import { useEffect, useMemo, useState } from 'react'

import { SelectedEventType } from 'src/@types'
import { DateFilterEnum } from 'src/environment'
import {
	GetAssignmentsWithNoSubmissionsDocument,
	useGetAssignmentsWithNoSubmissionsLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Class_Assignment, Order_By } from 'src/graphql/autogenerate/schemas'
import {
	ASSIGNMENT_PER_VIEW,
	AssignmentSortOrder,
	RefetchProductsEnum,
	getComparisonDate,
	getExistingCache,
	refetchCache
} from 'src/utils'

type UseAssignmentsNoSubmissionsProps = {
	classId: number
	date: DateFilterEnum
}
export const useAssignmentsNoSubmissions = ({ classId, date }: UseAssignmentsNoSubmissionsProps) => {
	const [selectedClassId, setSelectedClassId] = useState(classId)
	const [selectedDateFilter, setSelectedDateFilter] = useState(DateFilterEnum.thisMonth)

	const [sort, setSort] = useState(AssignmentSortOrder.AlphAsc)

	const [seeMoreEnabled, setSeeMoreEnabled] = useState(true)
	const [isFetching, setIsFetching] = useState(false)
	const [isRefetch, setIsRefetch] = useState(false)

	const [totalAssignments, setTotalAssignments] = useState(0)

	const [assignments, setAssignments] = useState<Class_Assignment[]>([])

	const { fromDate, toDate } = useMemo(() => getComparisonDate(selectedDateFilter), [selectedDateFilter])

	const limit = ASSIGNMENT_PER_VIEW

	const variables = {
		classId: selectedClassId,
		fromDate: fromDate ?? undefined,
		toDate: toDate ?? undefined,
		limit,
		order_by:
			sort === AssignmentSortOrder.AlphAsc || sort === AssignmentSortOrder.AlphDesc
				? {
						assignment: {
							name: sort === AssignmentSortOrder.AlphAsc ? Order_By.Asc : Order_By.Desc
						}
				  }
				: {
						assignment: {
							due_date: sort === AssignmentSortOrder.DateAsc ? Order_By.Asc : Order_By.Desc
						}
				  }
	}

	const [getAssignments, { data, loading, refetch }] = useGetAssignmentsWithNoSubmissionsLazyQuery()

	const seeMore = () => {
		setIsFetching(true)
	}

	const handleSort = (event: SelectedEventType) => {
		const selectedSort = event.target.value as AssignmentSortOrder
		setSort(selectedSort)
	}

	useEffect(() => {
		setIsFetching(false)
		if (data?.class_assignment && !isFetching) {
			setIsRefetch(
				refetchCache({
					data: data.class_assignment as Class_Assignment[],
					type: RefetchProductsEnum.ClassAssignment,
					isFetching,
					variables,
					query: GetAssignmentsWithNoSubmissionsDocument
				}) as boolean
			)
		}
		if (data) {
			setTotalAssignments(data?.class_assignment_aggregate?.aggregate?.count || 0)
		}
	}, [data, loading])

	useEffect(() => {
		setSeeMoreEnabled(totalAssignments > assignments.length)
	}, [assignments])

	useEffect(() => {
		if (isRefetch) {
			const existingCache = getExistingCache({
				variables,
				query: GetAssignmentsWithNoSubmissionsDocument
			}) as {
				class_assignment: Class_Assignment[]
			}
			setAssignments(existingCache.class_assignment)
			setIsRefetch(false)
		}
	}, [isRefetch])

	useEffect(() => {
		if (!isFetching) return

		const currentLength = assignments.length
		refetch({
			offset: currentLength,
			limit
		}).then((fetchMoreResult) => {
			setIsRefetch(
				refetchCache({
					data: fetchMoreResult.data.class_assignment as Class_Assignment[],
					type: RefetchProductsEnum.ClassAssignment,
					isFetching,
					variables,
					query: GetAssignmentsWithNoSubmissionsDocument
				}) as boolean
			)

			setIsFetching(false)
		})
	}, [isFetching])

	useEffect(() => {
		setSelectedClassId(classId)
		setSelectedDateFilter(date)
	}, [classId, date])

	useEffect(() => {
		if (selectedClassId !== 0) {
			getAssignments({
				variables
			})
		}
	}, [selectedClassId, selectedDateFilter, sort])

	return {
		totalAssignments,
		assignments,
		seeMoreEnabled,
		seeMore,
		loading,
		refetch,
		sort,
		handleSort
	}
}
