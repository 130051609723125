import { useEffect, useMemo, useState } from 'react'

import { ROOT_CHORDS } from 'src/utils'

import { useUploadSongTrackContext } from '../../useUploadSongTrack'

export type TimedChord = {
	chord: string
	time: number
}

type ChordQuality = {
	chord: string
	quality?: string
}

export enum PreviewType {
	Chord,
	LineBreak,

	Lyric
}

type SegmentedPreview = {
	time: number
	type: PreviewType
	label: string
}

export const useChordSheetView = () => {
	const [selectedChord, setSelectedChord] = useState<string>(ROOT_CHORDS[0])
	const [selectedQuality, setSelectedQuality] = useState<string>()
	const [chords, setChords] = useState<TimedChord[]>([])
	const [breaks, setBreaks] = useState<number[]>([])
	const [showChordsDialog, setShowChordsDialog] = useState(false)
	const [edittingChordIndex, setEdittingChordIndex] = useState<number>()
	const [showChordsPreview, setShowChordsPreview] = useState(false)

	const {
		playing,
		togglePlayPause,
		durationInMiliseconds,
		durationInSeconds,
		speed,
		handleSpeedChange,
		audioUrl,
		resetSongTime,
		lyrics,
		lyricsArray,
		setLyrics,
		withLyrics,
		time,
		audio,
		setIsChordsStepValid,
		chords: chordsFromContext,
		breaks: breaksFromContext,
		setChords: setChordsFromContext,
		setBreaks: setBreaksFromContext,
		setViewTitle,
		setViewDescription
	} = useUploadSongTrackContext()

	useEffect(() => {
		setViewTitle('Create Your Chord Sheet')
		setViewDescription(
			'Create your chord sheet by customizing your chord, clicking the current chord onto the timeline below. You can also click line breaks as needed.'
		)

		return () => {
			setViewTitle('')
			setViewDescription('')
		}
	}, [])

	const addChord = (time: number) => {
		if (selectedChord) {
			setChords(
				[...chords, { chord: `${selectedChord}${selectedQuality ?? ''}`, time }].sort(
					(a, b) => a.time - b.time
				)
			)
		}
	}

	const replaceChord = (atIndex: number, withChord: string) => {
		const newChords = chords.map((chord, index) =>
			atIndex === index ? { time: chord.time, chord: withChord } : chord
		)

		setChords(newChords)
	}

	const addBreak = (time: number) => {
		setBreaks([...breaks, time].sort((a, b) => a - b))
	}

	const removeChord = (at: number) => {
		setChords(chords.filter((_, index) => index !== at))
	}

	const removeBreak = (at: number) => {
		setBreaks(breaks.filter((_, index) => index !== at))
	}

	const removeWord = (at: number) => {
		setLyrics(lyrics.filter((_, index) => index !== at))
	}

	const preview = useMemo(() => {
		const typedChords = chords.map(({ chord, time }) => ({
			time,
			label: chord,
			type: PreviewType.Chord
		}))

		const typedBreaks = breaks.map((time) => ({
			time,
			label: time.toString(),
			type: PreviewType.LineBreak
		}))

		const typedLyrics = lyrics.map(({ time, text }) => ({
			time,
			label: text,
			type: PreviewType.Lyric
		}))

		const ordered = [...typedChords, ...typedBreaks, ...typedLyrics].sort((a, b) => a.time - b.time)

		const segmented = ordered.reduce(
			(acc, curr) => {
				if (curr.type === PreviewType.LineBreak) {
					acc.push([])
				} else {
					acc[acc.length - 1].push(curr)
				}

				return acc
			},
			[[]] as SegmentedPreview[][]
		)

		return segmented
	}, [chords, breaks])

	const formatByMinuteAndSecond = (timeInMs: number) => {
		const minute = ((timeInMs / 1000 / 60) % 60).toFixed().padStart(2, '0')
		const seconds = ((timeInMs / 1000) % 60).toFixed().padStart(2, '0')

		return `${minute}:${seconds}`
	}

	const preview2 = useMemo(() => {
		return lyricsArray.map((lyricSet) =>
			lyricSet.map((item) => ({
				...item,
				chords: chords
					.filter(
						({ time }) => formatByMinuteAndSecond(time) === formatByMinuteAndSecond(item.time ?? 0)
					)
					.filter(({ chord }, index, self) => self.findIndex((e) => e.chord === chord) === index)
			}))
		)
	}, [chords, breaks])

	const handleConfirmChordSelection = ({ chord, quality }: ChordQuality) => {
		setSelectedChord(chord)
		setSelectedQuality(quality)
		if (edittingChordIndex !== undefined) {
			replaceChord(edittingChordIndex, `${chord}${quality}`)
			setEdittingChordIndex(undefined)
		}
		setShowChordsDialog(false)
	}

	useEffect(() => {
		setIsChordsStepValid(chords.length > 0)
	}, [chords])

	/* Setting the context's chords and breaks from the state. */
	useEffect(() => {
		setBreaksFromContext(breaks)
		setChordsFromContext(chords)
	}, [chords, breaks])

	/* Setting the chords and breaks from the context to the state. */
	useEffect(() => {
		setChords(chordsFromContext)
		setBreaks(breaksFromContext)
	}, [])

	return {
		selectedChord,
		setSelectedChord,
		selectedQuality,
		setSelectedQuality,
		chords,
		addChord,
		breaks,
		addBreak,
		removeChord,
		removeBreak,
		preview,
		replaceChord,
		showChordsDialog,
		setShowChordsDialog,
		edittingChordIndex,
		setEdittingChordIndex,
		showChordsPreview,
		setShowChordsPreview,
		handleConfirmChordSelection,

		playing,
		togglePlayPause,
		durationInMiliseconds,
		durationInSeconds,
		speed,
		handleSpeedChange,
		audioUrl,
		resetSongTime,
		lyrics,
		withLyrics,
		time,
		audio,
		removeWord,
		preview2
	}
}
