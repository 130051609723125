import { useEffect, useState } from 'react'

import { GetClassesQuery } from 'src/graphql/autogenerate/operations'
import { useScrollPercentage } from 'src/hooks'
import { MAX_LIST_ITEMS } from 'src/utils'

import { buildGenericContext } from '../../../../context/genericContext'
import { useGetClassesQuery } from '../../../../graphql/autogenerate/hooks'
import { Class } from '../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../hooks/useLogin'
import { ClassStateOptions } from '../../../../utils/enums'
interface ClassWithVideoCount extends Class {
	totalVideos: number
}

const useClasses = () => {
	const { teacherData: teacher } = useLoginContext()
	const [selectedClassState, setSelectedClassState] = useState<ClassStateOptions>(ClassStateOptions.Active)
	const [classes, setClasses] = useState<ClassWithVideoCount[]>([])
	const [queryLimit, setQueryLimit] = useState(MAX_LIST_ITEMS)
	const [totalClassesCount, setTotalClassesCount] = useState(0)
	const [loading, setLoading] = useState(true)

	const {
		loading: queryLoading,
		data: classData,
		refetch
	} = useGetClassesQuery({
		variables: { teacherId: teacher.teacher_id, rightNow: null, getActiveClasses: true, limit: queryLimit },
		skip: !teacher.teacher_id
	})

	const { scrollLevel } = useScrollPercentage()

	// TODO: -- Implement this again when the class status selector is approach is defined --
	// const [dateTimeNow, setDateTimeNow] = useState<Date | null>(new Date())
	// const [getActiveClasses, setGetActiveClasses] = useState<boolean>(true)
	// useEffect(() => {
	// 	if (selectedClassState === ClassStateOptions.All) {
	// 		setDateTimeNow(null)
	// 	} else {
	// 		setDateTimeNow(new Date())
	// 		if (selectedClassState === ClassStateOptions.Active) setGetActiveClasses(true)
	// 		else setGetActiveClasses(false)
	// 	}
	// }, [selectedClassState])
	// -- end section to implement --

	const getClassListWithCounters = (data: GetClassesQuery['teacher_by_pk'], key: 'active' | 'inactive') => {
		if (!data?.[key]) return []
		const classesWithCounters = data?.[key]?.map((classItem) => {
			// get videos counters
			const tips = classItem.class.tipsCount.map((tip) => tip.tip_id)
			const tipsInPlaylist =
				classItem.class.playlistCount
					?.map((playlist) => playlist.playlist.tip_playlist.map((tip) => tip.tip_id))
					.flat() || []

			const totalVideos = new Set([...tips, ...tipsInPlaylist]).size

			// get lessons counters
			const lessons = classItem.class.lessonPlanList.map((lesson) => lesson.lesson_plan_id)
			const lessonsInModules = classItem.class.lessonsInModules
				.map((class_module) =>
					class_module.module.module_lesson_plan.map((lesson) => lesson.lesson_plan_id)
				)
				.flat()

			const lessonsInSequences = classItem.class.lessonsInSecuences
				.map((class_sequence) =>
					class_sequence.sequence.sequence_lesson_plans.map((lesson) => lesson.lesson_plan_id)
				)
				.flat()

			const totalLessons = new Set([...lessons, ...lessonsInModules, ...lessonsInSequences]).size

			return { ...classItem.class, totalVideos, totalLessons }
		})

		return classesWithCounters || []
	}

	useEffect(() => {
		const data = classData?.teacher_by_pk
		const hasActiveClasses = selectedClassState === ClassStateOptions.Active
		const hasInactiveClasses = selectedClassState === ClassStateOptions.Inactive
		const hasAllClasses = selectedClassState === ClassStateOptions.All

		if (queryLoading || !data || !(hasActiveClasses || hasInactiveClasses || hasAllClasses)) return

		let classesResult = [] as ClassWithVideoCount[]
		let classCount = 0

		if (hasActiveClasses || (hasAllClasses && data.active)) {
			classesResult = (getClassListWithCounters(data, 'active') as unknown as ClassWithVideoCount[]) ?? []
			classCount = data.active_aggregate?.aggregate?.count ?? 0
		} else {
			classesResult = (getClassListWithCounters(data, 'inactive') as unknown as ClassWithVideoCount[]) ?? []
			classCount = data.inactive_aggregate?.aggregate?.count ?? 0
		}

		setClasses(classesResult)
		setTotalClassesCount(classCount)
		setLoading(false)
	}, [classData, queryLoading])

	useEffect(() => {
		const canFetchMore = classes.length < totalClassesCount
		if (scrollLevel === 100 && canFetchMore) {
			setLoading(true)
			setQueryLimit((prev) => prev + MAX_LIST_ITEMS)
		}
	}, [scrollLevel, totalClassesCount])

	return { loading, classes, teacher, selectedClassState, setSelectedClassState, refetch }
}

export const [ClassesProvider, useClassesContext] = buildGenericContext(useClasses)
