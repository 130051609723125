import { createStyles, fade, makeStyles, Theme } from '@material-ui/core'

export const useClassDetailsTitleStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainTitle: {
			color: theme.palette.background.paper,
			opacity: '0.7'
		},
		title: {
			color: theme.palette.background.paper,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			'-webkit-line-clamp': 3,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word'
		},
		boxContainer: {
			backgroundImage: ({ url }: { url: string }) =>
				`${'linear-gradient(0deg, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 100%, 0) 100%)'}, url(${url})`,
			backgroundColor: fade(theme.palette.primary.dark, 0.8),
			backgroundSize: 'cover'
		}
	})
)

export default useClassDetailsTitleStyles
