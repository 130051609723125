import { StudentLoginDialog } from './components/StudentLoginDialog'
import { StudentLoginProvider } from './useStudentLoginPage'

export const StudentLoginPage = () => {
	return (
		<StudentLoginProvider>
			<StudentLoginDialog />
		</StudentLoginProvider>
	)
}
