import { Theme, createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonOption: {
			height: '100%',
			position: 'relative',
			'& .MuiButton-iconSizeLarge > *:first-child': {
				fontSize: 50
			},
			borderRadius: '5px'
		},
		uploadIcon: {
			backgroundColor: theme.palette.background.default,
			padding: 5,
			borderRadius: '3px'
		},
		menu: {
			'& .MuiPopover-paper': {
				maxWidth: '100%'
			},
			'& .MuiMenu-list': {
				width: '180px'
			}
		}
	})
)

export default useStyles
