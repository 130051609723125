import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentCardListStyles = makeStyles(() =>
	createStyles({
		homeAssignments: {
			marginBottom: 10,
			padding: '8px 16px'
		},
		seeMore: { cursor: 'pointer', textAlign: 'end', marginTop: 20 },
		loader: {
			margin: 20
		}
	})
)

export default useAssignmentCardListStyles
