import React from 'react'

import { Box, ButtonProps, ListItem, ListItemText, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CallMadeIcon from '@material-ui/icons/CallMade'
import { buildImagePath } from 'src/utils'

import { BaseButton } from '../../../../../components'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			padding: '0 20px 0 0',
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'auto',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		avatarImage: {
			position: 'relative',
			backgroundSize: 'cover',
			backgroundPosition: 'center 50%',
			color: theme.palette.common.white,
			'& .main': {
				width: '100%',
				alignItems: 'center',
				padding: theme.spacing(2),
				paddingTop: 0,
				paddingBottom: theme.spacing(5),
				[theme.breakpoints.down('sm')]: {
					padding: theme.spacing(2)
				},
				backgroundColor: fade(theme.palette.grey[400], 0.2)
			},
			'& .bottomBar': {
				width: '100%',
				padding: theme.spacing(0.5),
				fontSize: 12,
				color: fade(theme.palette.common.white, 0.8),
				backgroundColor: fade(theme.palette.common.black, 0.3)
			}
		}
	})
)

export type ListItemSequenceProps = {
	id?: number
	imageUrl: string
	title: string
	description: string
	genres: string
	footer: string
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	btnAddClass?: { selected?: boolean } & ButtonProps
}

export const ListItemSequence = ({
	imageUrl,
	title,
	description,
	genres,
	footer,
	btnAddClass,
	onClick
}: ListItemSequenceProps) => {
	const classes = useStyles()

	return (
		<ListItem className={classes.listItem}>
			<Box
				width="230px"
				className={classes.avatarImage}
				style={{ backgroundImage: `url(${buildImagePath(imageUrl)})` }}>
				<Box className="main" display="flex" height={{ xs: 250, md: 160 }}>
					<Typography variant="h6" align="left">
						<b>{title}</b>
					</Typography>
				</Box>
				<Box
					className="bottomBar"
					alignItems="center"
					position="absolute"
					display="flex"
					height={40}
					bottom={0}>
					<Typography style={{ marginLeft: 15 }} variant="subtitle2">
						<b>{footer}</b>
					</Typography>
				</Box>
			</Box>
			<Box width="100%" flexDirection="row">
				<ListItemText
					style={{ marginLeft: 20 }}
					primary={
						<Box>
							<Typography style={{ fontSize: 14 }}>
								<b>{description}</b>
							</Typography>
						</Box>
					}
					secondary={genres}
				/>
				<Box display="flex" className="boxActions" justifyContent="flex-end">
					<BaseButton
						color="default"
						className={'addClass'}
						endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}
						onClick={onClick}>
						<Typography>Learn More</Typography>
					</BaseButton>
					{btnAddClass && (
						<BaseButton color="primary" className={'addClass'} endIcon={<AddIcon />} {...btnAddClass}>
							<Typography>Add to class</Typography>
						</BaseButton>
					)}
				</Box>
			</Box>
		</ListItem>
	)
}
