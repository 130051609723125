import { Paper, Typography } from '@material-ui/core'

import useStyles from './Lytics.styles'

type LyricProps = {
	name: string
	time: number
	highlight?: boolean
	xPos: number
	yPos: number
	lyricFontSize: number
}
export const Lyric = ({ name, highlight = false, xPos, yPos, lyricFontSize }: LyricProps) => {
	const styles = useStyles({ xPos, yPos, lyricFontSize })

	return (
		<Paper className={styles.lyrics}>
			<Typography style={{ color: highlight ? '#F3AB34' : '#1D273D', fontWeight: 'bold' }}>
				{name}
			</Typography>
		</Paper>
	)
}
