import React, { Fragment, useState } from 'react'

import { Box, styled, Typography, Grid } from '@material-ui/core'
import { BaseDialog, BaseLoading } from 'src/components'
import { useUpdateStudentCoverMutation, useGetStudentBannersQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { useStudentClassDetailsContext } from 'src/scenes/Student/1-Home/hooks/useClassDetails'
import { buildImagePath } from 'src/utils'

type HowToEarnBadgesProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type BadgesBodyProps = {
	setSelected: (selected: string) => void
	selected: string
	banners: string[]
}

export const EditWallpaper = ({ isOpen, setIsOpen }: HowToEarnBadgesProps) => {
	const [newCover] = useUpdateStudentCoverMutation()
	const { studentData } = useLoginContext()
	const studentId = studentData?.student_id
	const { cover } = useStudentClassDetailsContext()
	const [selected, setSelected] = useState(cover || '')
	const { data: bannersData, loading: bannersLoading } = useGetStudentBannersQuery({ skip: !isOpen })
	const saveWallpaper = () => {
		setCover(studentId, selected)
		setIsOpen(false)
	}
	const closeModal = () => {
		setSelected(cover || '')
		setIsOpen(false)
	}

	const setCover = async (studentId: number, selectedCover: string) => {
		await newCover({
			variables: {
				student_id: studentId,
				cover_image_path: selectedCover
			}
		})
	}

	return (
		<BaseDialog
			header={
				<Fragment>
					<Typography variant="h4" align="center" style={{ marginTop: '10px', color: '#1D273D' }}>
						<b>Edit Background</b>
					</Typography>
					<Typography variant="h6" align="center" style={{ paddingBottom: 10 }}>
						Earn points and continue unlocking backgrounds!{' '}
					</Typography>
				</Fragment>
			}
			confirmLabel="Select Background"
			onConfirm={saveWallpaper}
			open={isOpen}
			onDiscard={closeModal}
			discardLabel="Cancel"
			onClose={closeModal}
			onDiscardProps={{ style: { marginLeft: '10px', padding: '6px 40px' } }}
			dividers={false}
			body={
				<Box mt={2} display="flex" flexDirection="column">
					{bannersLoading && <BaseLoading />}
					<BadgesBody
						setSelected={setSelected}
						selected={selected}
						banners={bannersData?.student_banner?.map((i) => buildImagePath(i.image_path, true)) || []}
					/>
				</Box>
			}
			paperProps={{ style: { maxWidth: '50%' } }}
		/>
	)
}

const StyledGrid = styled(Grid)({
	width: '100%',
	height: '100%'
})

const BadgesBody = ({ setSelected, selected, banners }: BadgesBodyProps) => {
	return (
		<Box>
			<StyledGrid container spacing={2}>
				{banners.map((covers) => (
					<Badge key={covers} covers={covers} setSelected={setSelected} selected={selected} />
				))}
			</StyledGrid>
		</Box>
	)
}

const StyledBadge = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
	borderRadius: '50px',
	margin: '10px',
	'& img': {
		width: 'auto',
		height: 'auto'
	}
})

type BadgeProps = {
	covers: string
	setSelected: (selected: string) => void
	selected: string
}

const Badge = ({ covers, setSelected, selected }: BadgeProps) => {
	return (
		<Grid item xs={4}>
			<StyledBadge>
				<img
					src={buildImagePath(covers)}
					alt={`${covers} badge`}
					onClick={() => {
						setSelected(covers)
					}}
					style={{
						cursor: 'pointer',
						borderRadius: '5px',
						opacity: selected === covers ? '100%' : '60%',
						boxShadow: selected === covers ? '0px 0px 9px 1px grey' : ''
					}}
				/>
			</StyledBadge>
		</Grid>
	)
}
