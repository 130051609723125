import { useEffect, useMemo, useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { noop } from 'lodash'
import moment from 'moment'
import { PagesProps } from 'src/@types'
import { CartIcon, DisabledIcon } from 'src/assets/icons'
import { BaseLoading, ListLessons, MainTitle } from 'src/components'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { StandardSideBar } from 'src/components/StandardSideBar'
import { useGetModuleByPkQuery, useGetPartnerTitleQuery } from 'src/graphql/autogenerate/hooks'
import {
	Lesson_Plan,
	Maybe,
	Module,
	Order_Status_Enum,
	Product_Name_Enum,
	Visibility_Enum
} from 'src/graphql/autogenerate/schemas'
import { useScroll, useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { useProfileSubscription } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useProfileSubscription'
import { buildBreadCrumbs, buildImagePath, getModuleCatalogItems, slugToName } from 'src/utils'

import { AddModuleToClass } from '../components/AddModuleToClass'

export const ModuleDetails = ({ page }: PagesProps) => {
	useScroll()
	const { teacherData: teacher } = useLoginContext()

	const params = useWurrlyParams<typeof Pages.ModuleDetails.params[number]>()
	const teacherId = teacher.teacher_id

	const { moduleId } = params

	const [isAddModuleToClassOpen, setIsAddModuleToClassOpen] = useState(false)
	const [moduleObject, setModuleObject] = useState<Lesson_Plan>()
	const [module, setModule] = useState<Module>()
	const [status, setStatus] = useState<Order_Status_Enum>()
	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [isOpen, setIsOpen] = useState(false)

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	const { data: moduleData, loading } = useGetModuleByPkQuery({
		variables: {
			moduleId,
			teacherId
		}
	})
	// request partner title if necessary
	const { data: partnerData } = useGetPartnerTitleQuery({
		skip: !params.partnerId,
		variables: { partner_id: params.partnerId }
	})

	useEffect(() => {
		setModule(moduleData?.module[0] as Module)
		setSubscription(moduleData?.module[0]?.is_purchased_by_teacher)
		setVisibility(moduleData?.module[0]?.lockable_content?.visibility)
	}, [moduleData])

	const { data: subscriptionData, loading: loadingSubscriptionData } = useProfileSubscription()
	const [expiresAt, setExpiresAt] = useState<string>()
	const [showExpiresAt, setShowExpiresAt] = useState<boolean>(false)

	useEffect(() => {
		if (subscriptionData && !loadingSubscriptionData) {
			const url = window.location.href

			const matchingElement = subscriptionData.find((element) => {
				return url.includes(`${element.productType}s/${element.productId}`)
			})
			const currentDate = moment()
			const targetDate = moment(matchingElement?.expiresAt)

			const duration = moment.duration(targetDate.diff(currentDate))
			const days = duration.asDays()
			setExpiresAt(days.toFixed(0))
			setStatus(matchingElement?.status)
			setShowExpiresAt(!matchingElement || duration.asDays() < 0 ? false : true)
		}
	}, [subscriptionData])

	const breadcrumbs = useMemo(() => {
		// if we arrive from a partner page, we use another structure for the breadcrumbs partnets > partnet name > module name
		if (partnerData?.partner_by_pk?.title) {
			return buildBreadCrumbs({
				page,
				replaceIndexes: [
					{
						index: 1,
						overrideName: 'Partners'
					},
					{
						index: 2,
						overrideName: slugToName(partnerData?.partner_by_pk?.title)
					},
					{
						index: 3,
						overrideName: slugToName(module?.title)
					}
				],
				params
			})
		}

		return buildBreadCrumbs({
			page,
			replaceIndexes: [
				{
					index: 1,
					overrideName: 'Modules'
				},
				{
					index: 2,
					overrideName: slugToName(module?.title)
				}
			],
			params
		})
	}, [params, module])

	const availableLessons = module?.module_lesson_plan?.map((item) => item?.lesson_plan)

	const AssetToDisplay = module?.display_video
		? module?.video_path && (
				<ReactCustomVideoPlayer
					url={module.video_path}
					light={module.video_thumbnail_image_path || ''}
					width={465}
					height={265}
				/>
		  )
		: module?.header_image_path && (
				<img width={465} height={265} src={buildImagePath(module?.header_image_path) ?? ''} />
		  )

	const subscriptionLabel = useMemo(() => {
		if (visibility !== Visibility_Enum.ForSale) return []
		if (status === Order_Status_Enum.Canceled)
			return [
				{
					variant: 'contained',
					className: 'subscribed',
					color: 'secondary',
					style: {
						opacity: 0.8
					},
					startIcon: <DisabledIcon style={{ opacity: 0.8 }} />,
					name: 'UNSUBSCRIBED'
				}
			]
		if (subscription)
			return [
				{
					variant: 'contained',
					color: 'primary',
					className: 'addClass',
					endIcon: <AddIcon />,
					name: 'Add Module to Class',
					onClick: (e: { stopPropagation: () => void }) => {
						e.stopPropagation()
						setIsAddModuleToClassOpen(true)
					}
				}
			]

		return [
			{
				variant: 'contained',
				startIcon: <CartIcon style={{ color: '#221F31' }} />,
				onClick: handleOpenProductDialog,
				name: 'SUBSCRIBE TO MODULE',
				style: {
					minWidth: '172px',
					background: 'linear-gradient(256.66deg, #FFC20C 21.57%, #EDA42B 61.82%)'
				}
			}
		]
	}, [subscription, visibility, status])

	return (
		<Box>
			{loading && <BaseLoading />}
			{!loading && (
				<Box>
					<MainTitle
						breadcrumbs={breadcrumbs}
						title={module?.title ?? page.name}
						description={module?.description ?? ''}
						list={getModuleCatalogItems(module)}
						assetsDisplay={AssetToDisplay as JSX.Element}
						rightActions={subscriptionLabel as []}
					/>
					{showExpiresAt && (
						<Typography
							variant="overline"
							style={{
								fontSize: '14px'
							}}>
							Subscription expires on:{' '}
							<b
								style={{
									color: '#FFC20C'
								}}>
								{expiresAt} days
							</b>
						</Typography>
					)}
					<StandardSideBar lesson={moduleObject} onClose={() => setModuleObject(undefined)} />
					<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>There are {availableLessons?.length ?? 0} available Lessons in this Module</b>
						</Typography>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs>
							<ListLessons
								moduleId={moduleId}
								teacherId={teacherId}
								partnerId={params.partnerId}
								data={availableLessons ?? []}
								spacing={1.5}
								addclassButton={subscription ? false : true}
							/>
						</Grid>
					</Grid>
				</Box>
			)}
			<AddModuleToClass
				isOpen={isAddModuleToClassOpen}
				setIsOpen={setIsAddModuleToClassOpen}
				module={module}
			/>
			{isOpen && moduleId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={moduleId}
					productName={Product_Name_Enum.Module}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
		</Box>
	)
}
