export interface IChord {
	name: string
	tempo: number
}

export interface ILyric {
	name: string
	tempo: number
}

export enum InstrumentsGuideType {
	piano = 'piano',
	guitar = 'guitar',
	ukulele = 'ukulele',
	audio = 'audio'
}
