import { useState } from 'react'

import { DateRange } from '@material-ui/icons'
import { DatePicker, DatePickerProps } from '@material-ui/pickers'

import useDateInputStyles from './DateInput.styles'

const DateInput = (props: DatePickerProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const styles = useDateInputStyles()

	const handleOpen = () => setIsOpen(true)
	const handleClose = () => setIsOpen(false)

	return (
		<DatePicker
			format="ddd, MMMM DD, YYYY"
			variant="inline"
			inputVariant="outlined"
			className={styles.dateField}
			allowKeyboardControl={false}
			placeholder="Pick Date"
			color="secondary"
			onOpen={handleOpen}
			onClose={handleClose}
			disablePast
			PopoverProps={{
				className: styles.selector,
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				transformOrigin: { horizontal: 'right', vertical: 'bottom' }
			}}
			InputProps={{
				endAdornment: <DateRange color={isOpen ? 'secondary' : 'action'} />
			}}
			{...props}
		/>
	)
}

export default DateInput
