import { useState } from 'react'

import { buildGenericContext } from '../../../../../../../../context/genericContext'
import { useStudentSignInOptionsContext } from '../../useStudentSignInOptions'

const useVerificationDialog = () => {
	const [isInfoDialogVisibile, setInfoDialogVisibility] = useState<boolean>(false)
	const { handleClose, setStudentAnswerValue, setStudentCode } = useStudentSignInOptionsContext()

	return {
		handleClose,
		setStudentCode,
		setStudentAnswerValue,
		setInfoDialogVisibility,
		isInfoDialogVisibile
	}
}

export const [VerificationDialogProvider, useVerificationDialogContext] =
	buildGenericContext(useVerificationDialog)
