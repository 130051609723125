import { Box } from '@material-ui/core'

import { PagesProps } from '../../../../@types'
import { MainTitle } from '../../../../components'

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       fleGrow: 1
//     }
//   }),
// )

export const Schedule = ({ page }: PagesProps) => {
	// const classes = useStyles()

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis dolorum, similique exercitationem perferendis aspernatur sunt laborum fuga fugiat."
			/>
		</Box>
	)
}
