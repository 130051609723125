import { makeStyles, Theme } from '@material-ui/core'
export const useStudentLoginStyles = makeStyles((theme: Theme) => ({
	baseDialog: {
		'& .MuiBackdrop-root': {
			opacity: '0!important',
			backgroundColor: theme.palette.primary.main
		}
	},
	forgotPasswordLabel: {
		textAlign: 'end',
		color: '#7C7C7C',
		fontSize: '13px',
		marginTop: '10px',
		cursor: 'pointer'
	},
	errorLabelContainer: {
		height: '24px'
	},
	errorLabel: {
		color: 'red',
		textAlign: 'center',
		paddingTop: '20px'
	},
	paper: {
		maxHeight: 'calc(100% - 60px)',
		width: '100%',
		maxWidth: '500px',
		padding: '10px 25px 20px'
	},
	confirm: {
		margin: '10px auto',
		textTransform: 'capitalize',
		fontWeight: 'bold',
		padding: '6px 30px'
	},
	footer: {
		marginTop: '15px'
	},
	bold: {
		fontWeight: 'bold'
	}
}))
