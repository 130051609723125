import { useState, useEffect } from 'react'

import {
	useGetWurrlyByClassByStudentQuery,
	useGetWurrliesByStudentClassesQuery
} from 'src/graphql/autogenerate/hooks'
import { Wurrly, Order_By, Wurrly_Type_Enum, Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { useScrollPercentage } from 'src/hooks/useScroll'
import { MAX_LIST_ITEMS } from 'src/utils/constants'

type useStudentClassWurrliesProps = {
	studentId: number
	order: Order_By
}
type useStudentWurrliesProps = useStudentClassWurrliesProps & {
	filter?: Wurrly_Type_Enum
}

export const useStudentWurrlies = ({ studentId, filter }: useStudentWurrliesProps) => {
	const [wurrlies, setWurrlies] = useState<Wurrly[]>([])
	const [count, setCount] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	// const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	// const { scrollLevel } = useScrollPercentage()

	const queryResult = useGetWurrlyByClassByStudentQuery({
		variables: {
			studentId,
			orderTitle: Order_By.Asc,
			orderDate: undefined,
			type: filter
		},
		skip: !studentId
	})

	// const canFetchMore = () => wurrlies.length < count

	useEffect(() => {
		setIsLoading(true)
		if (queryResult?.data) {
			setWurrlies((queryResult.data?.wurrly as Wurrly[]) ?? [])
			setCount(queryResult.data?.wurrly_aggregate?.aggregate?.count ?? 0)
			setIsLoading(false)
		}
	}, [queryResult])

	// useEffect(() => {
	// 	if (scrollLevel === 100 && !isLoading && canFetchMore()) {
	// 		setLimit((prevLimit) => prevLimit + MAX_LIST_ITEMS)
	// 	}
	// }, [scrollLevel])

	return {
		wurrlies,
		count,
		loading: isLoading
	}
}

export const useStudentClassesWurrlies = ({ studentId, order }: useStudentClassWurrliesProps) => {
	const [wurrlies, setWurrlies] = useState<Wurrly[]>([])
	const [count, setCount] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const { scrollLevel } = useScrollPercentage()
	const privacySettings = [Privacy_Enum.Class] // this section only shows public wurrlies.

	const queryResult = useGetWurrliesByStudentClassesQuery({
		variables: {
			studentId,
			limit,
			offset: 0,
			order,
			privacySettings
		}
	})

	const canFetchMore = () => wurrlies.length < count

	useEffect(() => {
		setIsLoading(true)
		if (queryResult?.data) {
			setWurrlies((queryResult.data?.wurrly as Wurrly[]) ?? [])
			setCount(queryResult.data?.wurrly_aggregate?.aggregate?.count ?? 0)
			setIsLoading(false)
		}
	}, [queryResult])

	useEffect(() => {
		if (scrollLevel === 100 && !isLoading && canFetchMore()) {
			setLimit((prevLimit) => prevLimit + MAX_LIST_ITEMS)
		}
	}, [scrollLevel])

	return {
		wurrlies,
		count,
		loading: isLoading
	}
}
