import { Box, IconButton, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { Preview } from 'src/assets/images'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { buildImagePath } from 'src/utils'

import useStyles from './VideoCard.styles'

type VideoCardProps = {
	imgSource: string
	title: string
	description: string
	genres?: string | string[]
	setSelectedVideos?: (songs: Tip[]) => void
	selectedVideos?: Tip[]
	tip_id?: number
}

export const VideoCard = ({
	imgSource,
	title,
	description,
	genres,
	selectedVideos,
	setSelectedVideos,
	tip_id
}: VideoCardProps) => {
	const styles = useStyles()
	const { isLoggedAsTeacher } = useLoginContext()
	const handleRemove = () => {
		if (selectedVideos && setSelectedVideos) {
			const arr = selectedVideos.filter((el) => el.tip_id !== tip_id)
			setSelectedVideos(arr)
		}
	}

	return (
		<Box className={styles.container}>
			<Box style={{ display: 'flex' }}>
				<img src={!!imgSource ? buildImagePath(imgSource) : Preview} className={styles.image} />
				<Box className={styles.details}>
					<Typography variant="h6" className={styles.title}>
						{title}
					</Typography>
					<Typography variant="h6" className={styles.subtitle}>
						{description}
					</Typography>
					{!!genres?.length && (
						<Typography variant="subtitle1" className={styles.catalogs}>
							• {typeof genres === 'string' ? genres : genres.join(', ')}
						</Typography>
					)}
				</Box>
			</Box>
			<Box style={{ display: 'flex' }}>
				{isLoggedAsTeacher && (
					<IconButton className={styles.iconButton} onClick={handleRemove}>
						<Clear />
					</IconButton>
				)}
			</Box>
		</Box>
	)
}
