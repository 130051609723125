import { useState, useEffect } from 'react'

import { Page, PagesProps } from 'src/@types'
import { useGetCatalogByPkLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Catalog_Item } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { MusicCatalogBody } from 'src/scenes/Teacher/scenes/7-MusicCatalog/components/MusicCatalogBody/MusicCatalogBody'
import { capitalize } from 'src/utils'
import { buildBreadCrumbs } from 'src/utils/utils'

export const CatalogSongs = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const params = useWurrlyParams<typeof Pages.CatalogItemSongs.params[number]>()

	const { catalogId, catalogItemId } = params

	const [getData, { data: catalogData }] = useGetCatalogByPkLazyQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})
	const [title, setTitle] = useState<string>()
	const [breadcrumbs, setBreadcrumbs] = useState<Page[]>()
	const catalogName = catalogData?.catalog_by_pk?.name
	const catalogItem = catalogData?.catalog_by_pk?.catalog_items.find(
		(item) => item.catalog_item_id === catalogItemId
	) as Catalog_Item | undefined

	useEffect(() => {
		getData()
	}, [])

	useEffect(() => {
		setTitle(
			(page.path === Pages.RecommendedSongs.path && 'Songs') ||
				catalogItem?.name ||
				(!catalogId && 'Song Search Results') ||
				(catalogId && capitalize(catalogName)) ||
				page.name
		)

		const value =
			page.path === Pages.RecommendedSongs.path
				? buildBreadCrumbs({
						page,
						replaceIndexes: catalogItemId
							? [
									{
										index: 1,
										overrideName: capitalize(catalogName)
									},
									{
										index: 2,
										overrideName: title
									}
							  ]
							: [
									{
										index: 1,
										overrideName: 'All Songs'
									}
							  ],
						params
				  })
				: buildBreadCrumbs({
						page,
						replaceIndexes: catalogItemId
							? [
									{
										index: 1,
										overrideName: `${capitalize(catalogName)}`
									},
									{
										index: 2,
										overrideName: title
									}
							  ]
							: [
									{
										index: 1,
										overrideName: catalogId
											? `${capitalize(catalogName)}`
											: 'Song Search Results'
									}
							  ],
						params
				  })

		setBreadcrumbs(value)
	}, [catalogData])

	return (
		<>
			{breadcrumbs && (
				<MusicCatalogBody
					page={page}
					title={title as string}
					catalogId={catalogId}
					teacherId={teacherId}
					breadcrumbs={breadcrumbs}
					catalogItemId={catalogItem?.catalog_item_id}
					setTitle={setTitle}
					setBreadcrumbs={setBreadcrumbs}
				/>
			)}
		</>
	)
}
