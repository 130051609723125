import { useEffect, useRef, useState } from 'react'

import { addRotatedImage } from 'src/components/CanvasThumbnail/utils'
import { Filter_Color_Name_Enum } from 'src/graphql/autogenerate/schemas'

import { StickerElement } from '../ResizableElement/ResizableElement'
import useStyles from './CanvasThumbnail.styles'
import { applyColorFilter, addImage } from './utils'
type CanvasThumbnailProps = {
	imageUrl: string
	canvasWidth: number
	canvasHeight: number
	frameUrl: string | undefined
	stickers: StickerElement[]
	colorFilter: Filter_Color_Name_Enum | undefined
	onFilterApplied: (dataURL: string) => void
}

export const CanvasThumbnail = ({
	imageUrl,
	canvasWidth,
	canvasHeight,
	frameUrl,
	stickers,
	colorFilter,
	onFilterApplied
}: CanvasThumbnailProps) => {
	const ref = useRef<HTMLCanvasElement | null>(null)
	const styles = useStyles()
	const [render, setRender] = useState(true)
	useEffect(() => {
		if (!stickers.length && !frameUrl && !colorFilter) return onFilterApplied(imageUrl)
		if (!ref || !ref?.current) return
		const context = ref?.current?.getContext('2d')

		const createThumbnails = async () => {
			// Thumbnail image
			const thumbnailImage = await addImage(imageUrl, canvasWidth, canvasHeight)
			// Color filter
			const drawImage = () => context?.drawImage(thumbnailImage, 0, 0, canvasWidth, canvasHeight)
			if (colorFilter && context) {
				await applyColorFilter(
					context,
					{ width: canvasWidth, height: canvasHeight },
					colorFilter,
					drawImage
				)
			} else {
				drawImage()
			}

			if (context) context.filter = 'none'

			// frame image
			if (frameUrl) {
				const frameImage = await addImage(frameUrl, canvasWidth, canvasHeight)
				context?.drawImage(frameImage, 0, 0, canvasWidth, canvasHeight)
			}

			// stickers images
			if (stickers.length && context) {
				const promisses: Promise<void>[] = []
				for (const sticker of stickers) {
					promisses.push(
						addRotatedImage(context, {
							x: sticker.positionX,
							y: sticker.positionY,
							url: sticker.imageUrl,
							width: sticker.width,
							height: sticker.height,
							rotation: sticker.rotation
						})
					)
				}
				await Promise.all(promisses)
			}
			const imageURL = ref.current?.toDataURL()
			onFilterApplied(imageURL || imageUrl)
			setRender(false)
		}
		createThumbnails()
	}, [])

	return (
		<>{render && <canvas ref={ref} width={canvasWidth} height={canvasHeight} className={styles.canvas} />}</>
	)
}
