import { gql } from '@apollo/client'
import { Song, Tip } from 'src/graphql/autogenerate/schemas'
import { songReducedFragment, tipFragment } from 'src/scenes/Teacher/fragments'
import { TypeEnum } from 'src/utils'

export const querySearchAddSong = (type: TypeEnum) => {
	switch (type) {
		case TypeEnum.Song:
			return gql`
				query fetchSongs(
					$conditions: [song_bool_exp!] = [{ title: { _ilike: "%%" } }]
					$offset: Int
					$limit: Int
					$order: order_by = asc
				) {
					total: song_aggregate {
						aggregate {
							count
						}
					}
					song(
						where: { _or: $conditions, song_id: { _is_null: false } }
						offset: $offset
						limit: $limit
						order_by: { title: $order }
					) {
						...songReducedFragment
					}
				}
				${songReducedFragment}
			`
		case TypeEnum.Video:
			return gql`
				query getVideos(
					$conditions: [tip_bool_exp!] = [{ title: { _ilike: "%%" } }]
					$offset: Int
					$limit: Int
					$order: order_by = asc
				) {
					total: tip_aggregate {
						aggregate {
							count
						}
					}
					tip(
						where: { _or: $conditions, tip_id: { _is_null: false } }
						offset: $offset
						limit: $limit
						order_by: { title: $order }
					) {
						...tipFragment
					}
				}
				${tipFragment}
			`
		default:
			return gql`
				query {
					song {
						song_id
					}
				}
			`
	}
}
export const QUERY_SEARCH_SONGS = gql`
	query fetchSongs($search: String = "%%", $offset: Int, $limit: Int, $order: order_by = asc) {
		song(
			where: {
				_or: [{ title: { _ilike: $search } }, { artist: { name: { _ilike: $search } } }]
				song_id: { _is_null: false }
			}
			offset: $offset
			limit: $limit
			order_by: { title: $order }
		) {
			...songReducedFragment
		}
	}
	${songReducedFragment}
`
export type QuerySearchResultType = {
	song: Song[]
	tip: Tip[]
}
