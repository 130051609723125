import { Fragment } from 'react'

import { Box, Divider, List } from '@material-ui/core'
import { ItemAssignments } from 'src/components/listAssignments/itemAssignments'
import { Assignment } from 'src/graphql/autogenerate/schemas'

import { Submission } from '../../graphql/autogenerate/schemas'
import useStyles from './ListAsignments.styles'

type ListAssignmentsProps = {
	spacing?: number
	lineDivider?: boolean
	data: Submission[]
	isInStudentView?: boolean
	expired?: boolean
}

export const ListAssignments = ({
	spacing = 0,
	lineDivider,
	data,
	isInStudentView = false,
	expired
}: ListAssignmentsProps) => {
	const styles = useStyles()

	return (
		<Fragment>
			<List className={styles.boxAssignments}>
				{data?.map((submission, i) => {
					const assignment = submission?.class_assignment?.assignment

					return (
						<Box
							className="itemBox"
							key={submission.submission_id}
							overflow="hidden"
							boxShadow={spacing > 0 ? 1 : 0}
							borderRadius={spacing > 0 ? 4 : 0}>
							<ItemAssignments
								expired={expired}
								assignment={assignment as Assignment}
								submission={submission as Submission}
								isInStudentView={isInStudentView}
							/>
							{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
						</Box>
					)
				})}
			</List>
		</Fragment>
	)
}
