import { useEffect, useState } from 'react'

import { Box, useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { NotesIcon, ThinCheckIcon } from 'src/assets/icons'
import { InfoDialog } from 'src/components'
import { LoginPages } from 'src/routes/loginPages'

import { useParentAcceptanceContext } from './../useParentAcceptance'

export const AccountActivation = () => {
	const theme = useTheme()
	const history = useHistory()
	const { insertAcceptance, parentEmail, code, data, loading, error } = useParentAcceptanceContext()
	const [successState, setSuccessDialoge] = useState(false)
	const [ErrorState, setErrorDialoge] = useState(false)

	useEffect(() => {
		handdleIncertAcceptance()
	}, [])

	useEffect(() => {
		if (!loading && !error && data?.acceptConsent?.error === '') {
			setSuccessDialoge(true)
		} else if (error) {
			setErrorDialoge(true)
		}
	}, [data, error])

	const handdleIncertAcceptance = async () => {
		if (parentEmail && code) {
			try {
				await insertAcceptance({ variables: { filter: { parentEmail, code } } })
			} catch (error) {
				console.error(error)
			}
		}
	}

	const goToLogin = () => {
		history.push(LoginPages.Student.path)
	}

	return (
		<Box
			style={{
				backgroundColor: theme.palette.primary.main,
				width: '100%',
				height: '100%',
				position: 'fixed',
				left: '0',
				top: '0'
			}}>
			<img
				src={NotesIcon}
				style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
			/>
			<InfoDialog
				open={successState}
				onClose={goToLogin}
				icon={<ThinCheckIcon />}
				title={`Student Added!`}
				body={`You have successfully added this Student`}
				confirmLabel={'Sign In'}
				onConfirm={goToLogin}
			/>

			<InfoDialog
				open={ErrorState}
				onClose={goToLogin}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={goToLogin}
			/>
		</Box>
	)
}
