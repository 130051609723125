import { createStyles, makeStyles } from '@material-ui/core'

export const useInviteStudentsOneByOneDialogStyles = makeStyles(() =>
	createStyles({
		title: { fontWeight: 'bold' },
		bottomButton: { width: 200 },
		discardButton: {
			width: 150,
			padding: '6px 0'
		},
		discardLabel: { lineHeight: 1, fontSize: 14 }
	})
)

export default useInviteStudentsOneByOneDialogStyles
