import { PagesProps } from 'src/@types'
import { BaseLoading } from 'src/components'
import { useSearchQuery, useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'

import { AddAssignment } from '../AddAssignment/AddAssignment'
import { useLessonForAddAssignment } from './useLessonForAddAssignment'

export const AddAssignmentFromLesson = (props: PagesProps) => {
	const { lessonPlanId } = useWurrlyParams<typeof Pages.AddAssignmentFromLesson.params[number]>()
	const query = useSearchQuery()
	const selectedClassId = +(query.get('selectedClassId') ?? 0)
	const { lesson, loading } = useLessonForAddAssignment(lessonPlanId, selectedClassId)

	return loading ? <BaseLoading /> : <AddAssignment {...props} initialData={lesson} />
}
