import React, { useEffect, useMemo } from 'react'

import { QueryCatalogItemsType } from 'src/@types'
import { Catalogs } from 'src/components/Catalogs'
import { useGetCatalogItemsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { getCatalogItemFilter } from 'src/utils'
import { TypeEnum } from 'src/utils/enums'

const CatalogListComponent = () => {
	const [getCatalogItems, { data: catalogsData, loading: CatalogLoading }] = useGetCatalogItemsLazyQuery()

	useEffect(() => {
		const { filter, order } = getCatalogItemFilter(TypeEnum.Lesson)
		getCatalogItems({ variables: { catalogFilter: filter, catalogOrder: order } })
	}, [])

	const catalogs: QueryCatalogItemsType = useMemo(
		() => catalogsData || { catalog_item: [] },
		[catalogsData]
	) as QueryCatalogItemsType

	if (CatalogLoading || !catalogs || !catalogs?.catalog_item?.length) return null

	return <Catalogs parentPage={'lessonPlan'} data={catalogs} />
}

export const CatalogList = React.memo(CatalogListComponent)
