import { ChangeEvent, useState } from 'react'

import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Pagination, Skeleton } from '@material-ui/lab'
import { AssignmentCard } from 'src/components/Cards/AssignmentCard/AssignmentCard'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'

import { generateUniqueId } from '../utils'
import useStyles from './AssignmentCardList.styles'

type PaginationListType = {
	loading: boolean
	totalRows: number
	rowsPerPage: number
	limit: number
	data: Class_Assignment[] | undefined
	onPageChange: (page: number) => void
}
export const AssignmentPaginationList = ({
	totalRows,
	rowsPerPage,
	limit,
	data,
	loading,
	onPageChange
}: PaginationListType) => {
	const styles = useStyles()
	const [currentPage, setCurrentPage] = useState<number>(1)
	const totalPages = Math.ceil(totalRows / rowsPerPage)

	const handlePagination = (_: ChangeEvent<unknown>, page: number) => {
		if (currentPage !== page) {
			setCurrentPage(page)
			onPageChange(page)
		}
	}

	const previousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
			onPageChange(currentPage - 1)
		}
	}

	const nextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1)
			onPageChange(currentPage + 1)
		}
	}

	return (
		<List className={styles.listStyle}>
			<List disablePadding>
				{loading
					? [...Array(rowsPerPage)].map(() => (
							<ListItem key={generateUniqueId()}>
								<ListItemAvatar>
									<Skeleton className={styles.skeletonStyle} />
								</ListItemAvatar>
								<ListItemText
									primary={<Skeleton className={styles.secondSkeletonStyle} />}
									secondary={<Skeleton animation="wave" height={10} width="40%" />}
								/>
							</ListItem>
					  ))
					: data?.map((item) => (
							<AssignmentCard
								key={item.assignment_id}
								classAssignment={item}
								className={styles.assignmentCard}
								viewButton={false}
								editButton={false}
								showInstructions={false}
							/>
					  ))}
			</List>
			<ListItem style={{ height: 50 }}>
				<ListItemText
					primary={
						loading ? (
							<Skeleton animation="wave" height={40} width="90%" style={{ marginBottom: 6 }} />
						) : (
							<Box display="flex" justifyContent="space-between">
								<Typography color="textSecondary">
									{(currentPage - 1) * rowsPerPage + 1} to{' '}
									{limit + (currentPage - 1) * rowsPerPage} of {totalRows}
								</Typography>
								<Box display="flex">
									<Pagination
										key={currentPage}
										count={totalPages}
										page={currentPage}
										onChange={handlePagination}
										size="small"
										hideNextButton
										hidePrevButton
									/>
									<IconButton onClick={previousPage} color="secondary" size="small">
										<NavigateBeforeIcon fontSize="inherit" />
									</IconButton>
									<IconButton onClick={nextPage} color="secondary" size="small">
										<NavigateNextIcon fontSize="inherit" />
									</IconButton>
								</Box>
							</Box>
						)
					}
				/>
			</ListItem>
		</List>
	)
}
