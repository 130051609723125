import { createStyles, makeStyles } from '@material-ui/core'

export const useTimeSlideItemStyles = makeStyles<unknown, { width: number; highlight: boolean }>(() =>
	createStyles({
		container: {
			minWidth: ({ width }) => width,
			maxWidth: ({ width }) => width,
			justifyContent: ({ highlight }) => (highlight ? 'space-between' : 'flex-end')
		},
		text: {
			transform: ({ highlight }) => (highlight ? 'translateX(-20px)' : 'translateX(-10px)'),
			fontSize: ({ highlight }) => (highlight ? 13 : 7)
		},
		divider: { height: ({ highlight }) => (highlight ? 20 : 10) }
	})
)

export default useTimeSlideItemStyles
