import { useEffect, useState } from 'react'

import { ApolloCache } from '@apollo/client'
import { BaseDialogProps } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'
import {
	useInsertTipsToClassesMutation,
	useInsertClassPlaylistMutation,
	useInsertClassLessonMutation,
	useInsertClassSequenceMutation,
	useInsertClassSongsMutation,
	useInsertClassModuleMutation,
	useGetClassesDialogQuery,
	useInsertStudentToClassesMutation
} from 'src/graphql/autogenerate/hooks'
import {
	InsertClassLessonMutation,
	InsertClassSequenceMutation,
	InsertStudentToClassesMutation,
	InsertTipsToClassesMutation,
	InsertClassPlaylistMutation,
	InsertClassSongsMutation,
	InsertClassModuleMutation,
	GetClassesDialogQuery
} from 'src/graphql/autogenerate/operations'
import { Class, Wurrly_Role_Enum } from 'src/graphql/autogenerate/schemas'
import { buildSearchText, uniq } from 'src/utils'

import { AddToClassDialog, ClassForSelect } from './AddToClassDialog'

type AddToClassDialogContainerProps = BaseDialogProps & {
	open: boolean
	onClose: () => void
	insertId: number
	role: Wurrly_Role_Enum | 'Video' | 'Playlist' | 'Lesson' | 'Sequence' | 'Song' | 'Module'
	subtitle?: string
}
export const AddToClassDialogContainer = ({
	open,
	onClose,
	role,
	insertId,
	subtitle
}: AddToClassDialogContainerProps) => {
	const [searchText, setSearchText] = useState('')
	const [selectedClasses, setSelectedClasses] = useState<number[]>([])
	const [classes, setClasses] = useState<ClassForSelect[]>([])
	const isUser = role === Wurrly_Role_Enum.Student || role === Wurrly_Role_Enum.Teacher
	const {
		data: classesData,
		loading: classesLoading,
		fetchMore
	} = useGetClassesDialogQuery({
		variables: {
			limit: 17,
			userId: isUser ? insertId : null,
			tipId: role === 'Video' ? insertId : null,
			playlistId: role === 'Playlist' ? insertId : null,
			lessonPlanId: role === 'Lesson' ? insertId : null,
			sequenceId: role === 'Sequence' ? insertId : null,
			songId: role === 'Song' ? insertId : null,
			moduleId: role === 'Module' ? insertId : null,
			searchTexts: buildSearchText(searchText, ['title'])
		},
		fetchPolicy: 'no-cache',
		skip: !open
	})
	const [insertUser, { loading: insertUserLoading, error: insertUserError, data: insertUserData }] =
		useInsertStudentToClassesMutation()
	const [insertTip, { loading: insertTipLoading, error: insertTipError, data: insertTipData }] =
		useInsertTipsToClassesMutation()
	const [
		insertPlaylist,
		{ loading: insertPlaylistLoading, error: insertPlaylistError, data: insertPlaylistData }
	] = useInsertClassPlaylistMutation()
	const [insertLesson, { loading: insertLessonLoading, error: insertLessonError, data: insertLessonData }] =
		useInsertClassLessonMutation()
	const [
		insertSequence,
		{ loading: insertSequenceLoading, error: insertSequenceError, data: insertSequenceData }
	] = useInsertClassSequenceMutation()
	const [insertSong, { loading: insertSongLoading, error: insertSongError, data: insertSongData }] =
		useInsertClassSongsMutation()
	const [insertModule, { loading: insertModuleLoading, error: insertModuleError, data: insertModuleData }] =
		useInsertClassModuleMutation()
	const [fetchingMore, setFetchingMore] = useState(false)
	const totalClasses = classesData?.class_aggregate?.aggregate?.count || 0

	const fetchMoreClasses = () => {
		if ((classesData?.class.length || 0) < totalClasses) setFetchingMore(true)

		fetchMore({
			variables: {
				offset: classesData?.class?.length || 0
			}
		}).then((data) => {
			setClasses((prev) => [...prev, ...(data?.data?.class as unknown as Class[])])
		})
	}
	const evictData = (classIds: number[], cache: ApolloCache<unknown>) => {
		if (!classIds?.length) return
		for (const classId of classIds) {
			cache.evict({ id: `class:${classId}` })
		}
	}

	const handleSave = () => {
		let idField = ''
		switch (role) {
			case Wurrly_Role_Enum.Student:
				idField = 'student_id'
				break
			case Wurrly_Role_Enum.Teacher:
				idField = 'teacher_id'
				break
			case 'Video':
				idField = 'tip_id'
				break
			case 'Playlist':
				idField = 'playlist_id'
				break
			case 'Lesson':
				idField = 'lesson_plan_id'
				break
			case 'Sequence':
				idField = 'sequence_id'
				break
			case 'Song':
				idField = 'song_id'
				break
			case 'Module':
				idField = 'module_id'
				break
			default:
				break
		}

		const insertObject = selectedClasses.map((classId) => ({
			class_id: classId,
			[idField]: insertId
		}))
		if (isUser)
			insertUser({
				variables: {
					classObject: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const studentsAdded = data as InsertStudentToClassesMutation
					const clasIds: number[] = []
					if (studentsAdded?.insert_class_student) {
						studentsAdded.insert_class_student.returning.forEach((student) => {
							clasIds.push(student.class_id)
						})
					}

					evictData(clasIds, cache)
					onClose()
				}
			})
		if (role === 'Video')
			insertTip({
				variables: {
					classObject: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const videosAdded = data as InsertTipsToClassesMutation
					evictData(videosAdded?.insert_class_tip?.returning.map((i) => i.class_id) || [], cache)
					onClose()
				}
			})
		if (role === 'Playlist') {
			insertPlaylist({
				variables: {
					classPlaylists: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const playlistsAdded = data as InsertClassPlaylistMutation
					evictData(playlistsAdded?.insert_class_playlist?.returning.map((i) => i.class_id) || [], cache)
					onClose()
				}
			})
		}
		if (role === 'Lesson') {
			insertLesson({
				variables: {
					classLesson: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const lessonsAdded = data as InsertClassLessonMutation
					evictData(
						lessonsAdded?.insert_class_lesson_plan?.returning.map((i) => i.class_id) || [],
						cache
					)
					onClose()
				}
			})
		}
		if (role === 'Sequence') {
			insertSequence({
				variables: {
					classSequence: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const sequencesAdded = data as InsertClassSequenceMutation
					evictData(sequencesAdded?.insert_class_sequence?.returning.map((i) => i.class_id) || [], cache)
					onClose()
				}
			})
		}
		if (role === 'Song') {
			insertSong({
				variables: {
					classObject: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const songsAdded = data as InsertClassSongsMutation
					evictData(songsAdded?.insert_class_song?.returning.map((i) => i.class_id) || [], cache)
					onClose()
				}
			})
		}
		if (role === 'Module') {
			insertModule({
				variables: {
					classModule: insertObject
				},
				update: (cache, { data }) => {
					if (!data) return
					const moduleAdded = data as InsertClassModuleMutation
					evictData(moduleAdded?.insert_class_module?.returning.map((i) => i.class_id) || [], cache)
					onClose()
				}
			})
		}
	}

	const formatClass = (classToFormat: GetClassesDialogQuery['class'][number]): ClassForSelect => {
		let saved = false
		switch (role) {
			case Wurrly_Role_Enum.Student:
				saved = !!classToFormat.class_students?.length
				break
			case Wurrly_Role_Enum.Teacher:
				saved = !!classToFormat.class_teachers?.length
				break
			case 'Video':
				saved = !!classToFormat.class_tips?.length
				break
			case 'Playlist':
				saved = !!classToFormat.class_playlists?.length
				break
			case 'Lesson':
				saved = !!classToFormat.class_lesson_plans?.length
				break
			case 'Sequence':
				saved = !!classToFormat.class_sequences?.length
				break
			case 'Song':
				saved = !!classToFormat.class_songs?.length
				break
			case 'Module':
				saved = !!classToFormat.class_modules?.length
				break
		}

		return {
			...classToFormat,
			saved
		}
	}

	useEffect(() => {
		if (insertUserError || insertTipError) onClose()
	}, [insertUserError, insertTipError, insertPlaylistError])

	useEffect(() => {
		setClasses((classesData?.class?.map(formatClass) || []) as Class[])
	}, [classesData])

	useEffect(() => {
		if (!open) setSearchText('')
	}, [open])

	return (
		<>
			<AddToClassDialog
				open={open}
				onClose={onClose}
				selectedClasses={selectedClasses}
				setSelectedClasses={setSelectedClasses}
				title={`Add ${role} to class`}
				searchText={searchText}
				subtitle={subtitle}
				setSearchText={setSearchText}
				totalClasses={totalClasses}
				fetchMore={fetchMoreClasses}
				classesLoading={classesLoading}
				fetchingMore={fetchingMore}
				classes={uniq(classes)}
				onConfirm={handleSave}
				isConfirmDisabled={
					insertUserLoading ||
					insertTipLoading ||
					insertPlaylistLoading ||
					insertLessonLoading ||
					insertSequenceLoading ||
					insertSongLoading ||
					insertModuleLoading
				}
			/>
			<MutationDialog
				open={false}
				error={
					!!insertSequenceError ||
					!!insertLessonError ||
					!!insertPlaylistError ||
					!!insertTipError ||
					!!insertUserError ||
					!!insertSongError ||
					!!insertModuleError
				}
				success={
					!!insertSequenceData ||
					!!insertLessonData ||
					!!insertPlaylistData ||
					!!insertTipData ||
					!!insertUserData ||
					!!insertSongData ||
					!!insertModuleData
				}
				icon="question"
				successProps={{
					title: 'Success!',
					body: `You have successfully added this ${role} to the selected class(es)`
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while adding this ${role} to the selected class(es).`,
					icon: 'error'
				}}
			/>
		</>
	)
}
