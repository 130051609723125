import { useEffect, useState, forwardRef } from 'react'

import { Box, Divider, Typography, BoxProps } from '@material-ui/core'
import AutoSize from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { formatMsToMinutesAndSeconds } from 'src/utils'

import useSlideTimeItemStyles from './SlideTimeItem.styles'
import useStyles from './TimeSlide.styles'
type TimeSlideProps = {
	/** Total time to render in miliseconds */
	miliseconds: number
	/** Show time every X miliseconds */
	showTimeEvery: number
	/** Width in pixels that represents a milisecond */
	pxPerMs: number
	onScroll?: (offset: number) => void
	sliderLeftOffset?: number
}

export const TimeSlide = forwardRef<FixedSizeList<unknown>, TimeSlideProps>(
	({ miliseconds, showTimeEvery, pxPerMs, onScroll, sliderLeftOffset = 0 }, ref) => {
		const styles = useStyles()
		const [sliderTime, setSliderTime] = useState<number[]>([])

		useEffect(() => {
			const sliderTime: number[] = []
			for (let time = 0; time <= miliseconds; time += showTimeEvery) {
				sliderTime.push(time)
			}
			setSliderTime(sliderTime)
		}, [miliseconds])

		return (
			<Box className={styles.container}>
				<AutoSize>
					{({ width, height }) => {
						const calculatedWidth = showTimeEvery * pxPerMs

						return (
							<FixedSizeList
								useIsScrolling
								ref={ref}
								onScroll={({ scrollOffset }) => onScroll && onScroll(scrollOffset)}
								height={height}
								itemCount={sliderTime.length}
								itemSize={calculatedWidth}
								layout="horizontal"
								width={width}>
								{({ index, style }) => {
									const milisecond = sliderTime[index]
									const isSeconds = milisecond % 1000 === 0
									if (milisecond % showTimeEvery !== 0) return null

									return (
										<SlideTimeItem
											key={milisecond}
											highlight={isSeconds}
											style={{
												...style,
												left: `${parseFloat(style.left as string) + sliderLeftOffset}px`
											}}
											width={calculatedWidth}>
											{formatMsToMinutesAndSeconds(milisecond)}
										</SlideTimeItem>
									)
								}}
							</FixedSizeList>
						)
					}}
				</AutoSize>
			</Box>
		)
	}
)

const SlideTimeItem = ({
	children,
	highlight,
	width,
	...props
}: BoxProps & { highlight: boolean; width: number | string }) => {
	const styles = useSlideTimeItemStyles({ highlight, width })

	return (
		<Box display="flex" flexDirection="column" className={styles.container} {...props}>
			<Typography variant="caption" color="textPrimary" className={styles.text}>
				{children}
			</Typography>
			<Divider orientation="vertical" className={styles.divider} />
		</Box>
	)
}
