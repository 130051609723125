import { createStyles, makeStyles } from '@material-ui/core'

export const useRescheduleDialogStyles = makeStyles(() =>
	createStyles({
		assignmentIcon: {
			height: 60,
			width: 'auto'
		},
		description: {
			paddingBottom: 10
		},
		inputLabel: {
			textAlign: 'start',
			fontWeight: 'bold',
			padding: '10px 0'
		},
		paper: {
			width: '70%',
			maxWidth: '500px'
		},
		confirmButton: {
			display: 'flex',
			alignItems: 'center',
			'& b': {
				marginRight: 10
			}
		}
	})
)

export default useRescheduleDialogStyles
