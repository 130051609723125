import React, { useState, Fragment } from 'react'

import { Box, CircularProgress, fade, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { Student } from '../../graphql/autogenerate/schemas'
import { ListAddItems } from '../../scenes/Teacher/scenes/2-Classes/scenes/ClassStudents/components/AddDialog/ListAddItems'
import { BaseLink } from '../Buttons/BaseLink'
import { BaseDialog } from './BaseDialog/BaseDialog'
import { ContactDialog } from './ContactDialog/ContactDialog'

const useStyles = makeStyles((theme: Theme) => ({
	boxInput: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		backgroundColor: fade(theme.palette.text.secondary, 0.1),
		'& .MuiPaper-root :focus': {
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`
		},
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	boxInputFocus: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`,
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	discardButton: {
		width: '346px'
	},
	confirmButton: {
		width: '346px'
	}
}))
type AddStudentIntegrationDialogType = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	primaryTitle: string
	itemsPrimary: ({ selected: boolean } & Student)[]
	secondaryTitle: string
	itemsSecondary: ({ selected: boolean } & Student)[]
	loading: boolean
	isConfirmDisabled?: boolean
	onSave: () => void
	onDiscard: () => void
}
export const AddStudentIntegrationDialog = ({
	open,
	setOpen,
	primaryTitle,
	itemsPrimary,
	secondaryTitle,
	itemsSecondary,
	loading,
	onSave,
	isConfirmDisabled,
	onDiscard
}: AddStudentIntegrationDialogType) => {
	const styles = useStyles(focus)
	const [isContactDialogOpen, setIsContactDialogOpen] = useState<boolean>(false)
	const closeContactDialog = () => setIsContactDialogOpen(false)

	return (
		<Fragment>
			<BaseDialog
				open={open}
				onClose={() => {}}
				header={
					<Fragment>
						<Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
							<b>Add Students to Class</b>
						</Typography>
					</Fragment>
				}
				bodyProps={{
					style: {
						minHeight: itemsPrimary?.length && itemsSecondary?.length ? '360px' : 'auto'
					}
				}}
				body={
					<Fragment>
						<Grid style={{ margin: '20px 0 0 0', width: '100%' }} container spacing={3}>
							<Grid item xs={12} style={{ padding: '0' }}>
								<Box display="flex" alignItems="center">
									<Typography variant="h6">
										<b>{primaryTitle}</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />
								</Box>
							</Grid>
						</Grid>
						{!loading ? (
							<Box
								style={{
									background: 'linear-gradient(to bottom, rgb(255,255,255,1), #fff )'
								}}>
								<Grid item xs style={{ overflow: 'auto', maxHeight: '120px' }}>
									<ListAddItems
										hideCheckbox={true}
										spacing={1.5}
										data={itemsPrimary}
										onSelect={() => {}}
									/>
									{itemsPrimary.length <= 0 && (
										<Box style={{ opacity: 0.5 }}>
											<Typography variant="h5">No new students to import</Typography>
										</Box>
									)}
								</Grid>
							</Box>
						) : (
							<Box style={{ margin: 20, textAlign: 'center' }}>
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
						<Grid style={{ margin: '20px 0 0 0', width: '100%' }} container spacing={3}>
							<Grid item xs={12} style={{ padding: '0' }}>
								<Box display="flex" alignItems="center">
									<Typography variant="h6">
										<b>{secondaryTitle}</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />
								</Box>
							</Grid>
						</Grid>
						{!loading ? (
							<Box
								style={{
									background: 'linear-gradient(to bottom, rgb(255,255,255,1), #fff )',
									maxHeight: '120px'
								}}>
								<Grid item xs style={{ overflow: 'auto', maxHeight: '120px' }}>
									<ListAddItems
										hideCheckbox={true}
										spacing={1.5}
										data={itemsSecondary}
										onSelect={() => {}}
									/>
									{itemsSecondary.length <= 0 && (
										<Box style={{ opacity: 0.5 }}>
											<Typography variant="h5">No students already in the class</Typography>
										</Box>
									)}
								</Grid>
							</Box>
						) : (
							<Box style={{ margin: 20, textAlign: 'center' }}>
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
					</Fragment>
				}
				onDiscard={() => {
					setOpen(false)
					onDiscard()
				}}
				onDiscardProps={{
					className: styles.discardButton
				}}
				discardLabel="Go Back"
				onConfirm={() => {
					onSave()
				}}
				onConfirmProps={{
					className: styles.confirmButton,
					color: 'secondary',
					startIcon: <AddIcon />
				}}
				confirmLabel={'Add Students'}
				isConfirmDisabled={!itemsPrimary?.length || isConfirmDisabled}
				footer={
					<Typography
						style={{
							fontSize: 15,
							fontWeight: 500
						}}>
						Don't See Your Student?{' '}
						<BaseLink
							onClick={() => {
								setIsContactDialogOpen(true)
								// TODO: set link
							}}>
							Contact us
						</BaseLink>
					</Typography>
				}
				typographyProps={{
					variant: 'body1'
				}}
				maxWidth="sm"
				dividers={false}
			/>
			<ContactDialog
				open={isContactDialogOpen}
				onClose={closeContactDialog}
				closeDialog={closeContactDialog}
			/>
		</Fragment>
	)
}
