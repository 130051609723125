import { useEffect, useState } from 'react'

import {
	GetLessonPlansByClassIdDocument,
	GetModulesByClassIdDocument,
	GetPlaylistByClassIdDocument,
	GetSongByClassIdDocument,
	GetTipByClassIdDocument,
	useGetClassNameByPkLazyQuery,
	useGetLessonPlansByClassIdLazyQuery,
	useGetModulesByClassIdLazyQuery,
	useGetPlaylistByClassIdLazyQuery,
	useGetSearchLessonPlansByClassIdLazyQuery,
	useGetSearchModulesByClassIdLazyQuery,
	useGetSearchPlaylistByClassIdLazyQuery,
	useGetSearchSongByClassIdLazyQuery,
	useGetSearchTipByClassIdLazyQuery,
	useGetSongByClassIdLazyQuery,
	useGetTipByClassIdLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan, Module, Playlist, Song, Tip } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import useAllClassProductsStyles from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/AllClassProducts.styles'
import {
	MAX_LIST_ITEMS,
	buildBreadCrumbs,
	buildSearchText,
	refetchCache,
	RefetchProductsEnum,
	getExistingCache,
	getId
} from 'src/utils'
import { ProductTitleEnum } from 'src/utils/enums'

export type ProductDataItemType = Lesson_Plan | Module | Playlist | Tip | Song
export type ProductDataType = Lesson_Plan[] | Module[] | Playlist[] | Tip[] | Song[]
export const useAllClassProducts = () => {
	const { classId, type } = useWurrlyParams<string, string>()
	const [className, setClassName] = useState('')
	const limit = MAX_LIST_ITEMS
	const styles = useAllClassProductsStyles()
	const [showAddDialog, setShowAddDialog] = useState(false)
	const [selectedData, setSelectedData] = useState<ProductDataType>([])
	const [moreResults, setMoreResults] = useState(false)
	const [totalData, setTotalData] = useState(0)
	const [searchbarData, setSearchbarData] = useState<ProductDataType>([])
	const [searchbarLoading, setSearchbarLoading] = useState(false)
	const [searchbarText, setSearchbarText] = useState('')
	const [data, setData] = useState<ProductDataType>([])
	const [isOnClose, setIsOnClose] = useState(false)
	const [loadingData, setLoadingData] = useState(true)
	const [isModulesDialogOpen, setIsModulesDialogOpen] = useState<boolean>(false)
	const [lessonPlanId, setLessonPlanId] = useState(0)

	const [isRefetch, setIsRefetch] = useState(false)

	const [isFetching, setIsFetching] = useState(false)

	const [
		getSelectedLessons,
		{ data: selectedLessonsData, loading: selectedLessonsLoading, refetch: selectedLessonsRefetch }
	] = useGetLessonPlansByClassIdLazyQuery()
	const [
		getSelectedModules,
		{ data: selectedModulesData, loading: selectedModuleLoading, refetch: selectedModulesRefetch }
	] = useGetModulesByClassIdLazyQuery()
	const [
		getSelectedPlaylists,
		{ data: selectedPlaylistData, loading: selectedPlaylistLoading, refetch: selectedPlaylistRefetch }
	] = useGetPlaylistByClassIdLazyQuery()
	const [
		getSelectedSongs,
		{ data: selectedSongsData, loading: selectedSongsLoading, refetch: selectedSongsRefetch }
	] = useGetSongByClassIdLazyQuery()
	const [
		getSelectedTips,
		{ data: selectedTipsData, loading: selectedTipsLoading, refetch: selectedTipsRefetch }
	] = useGetTipByClassIdLazyQuery()

	const [getSearchModules, { data: searchbarDataModule, loading: searchbarLoadingModules }] =
		useGetSearchModulesByClassIdLazyQuery()
	const [getSearchTips, { data: searchbarDataTip, loading: searchbarLoadingTips }] =
		useGetSearchTipByClassIdLazyQuery()
	const [getSearchSongs, { data: searchbarDataSong, loading: searchbarLoadingSongs }] =
		useGetSearchSongByClassIdLazyQuery()
	const [getSearchPlaylists, { data: searchbarDataPlaylist, loading: searchbarLoadingPlaylists }] =
		useGetSearchPlaylistByClassIdLazyQuery()

	const [getSearchLessons, { data: searchbarDataLessonPlan, loading: searchbarLoadingLessons }] =
		useGetSearchLessonPlansByClassIdLazyQuery()

	const [getClass, { data: getClassData }] = useGetClassNameByPkLazyQuery()

	const breadCrumbs = buildBreadCrumbs({
		page: Pages.AllClassProducts,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			},
			{
				index: 2,
				overrideName: 'Class Lessons'
			},
			{
				index: 3,
				overrideName: `All Class ${type}`
			}
		],
		params: { classId: Number(classId) }
	})

	const moduleVariables = {
		search: buildSearchText(searchbarText, ['title', 'description']),
		limit,
		classId: Number(classId)
	}

	const lessonsVariables = {
		search: buildSearchText(searchbarText, ['title', 'description']),
		limit,
		classId: Number(classId)
	}

	const songVariables = {
		search: buildSearchText(searchbarText, ['title', 'description']),
		limit,
		classId: Number(classId)
	}

	const tipVariables = {
		search: buildSearchText(searchbarText, ['title', 'description']),
		limit,
		classId: Number(classId)
	}

	const playlistVariables = {
		search: buildSearchText(searchbarText, ['name', 'description']),
		limit,
		classId: Number(classId)
	}

	const getData = (text: string) => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				getSelectedLessons({
					variables: {
						search: buildSearchText(text, ['title', 'description']),
						classId: Number(classId),
						limit
					}
				})
				break
			case ProductTitleEnum.Module:
				getSelectedModules({
					variables: {
						search: buildSearchText(text, ['title', 'description']),
						classId: Number(classId),
						limit
					}
				})
				break
			case ProductTitleEnum.Song:
				getSelectedSongs({
					variables: {
						search: buildSearchText(text, ['title']),
						classId: Number(classId),
						limit
					}
				})
				break
			case ProductTitleEnum.Tip:
				getSelectedTips({
					variables: {
						search: buildSearchText(text, ['title', 'description']),
						classId: Number(classId),
						limit
					}
				})
				break
			case ProductTitleEnum.Playlist:
				getSelectedPlaylists({
					variables: {
						search: buildSearchText(text, ['name', 'description']),
						classId: Number(classId),
						limit
					}
				})
				break
			default:
				break
		}
	}

	const handleSearchbarTextEnter = (text: string) => {
		setMoreResults(true)
		getData(text)
	}

	const handleSearchbarTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchbarText(e.target.value)
	}

	const handleAddProductDialog = () => setShowAddDialog(true)

	const handleCancelAddDialog = () => {
		setShowAddDialog(false)
		setIsOnClose(true)
	}

	const fetchMore = () => {
		setIsFetching(true)
		const currentLength = data.length
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				selectedLessonsRefetch({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.lesson_plan as Lesson_Plan[],
							isFetching: true,
							type: RefetchProductsEnum.LessonPlan,
							variables: lessonsVariables,
							query: GetLessonPlansByClassIdDocument
						}) as boolean
					)
					if (
						fetchMoreResult.data?.lesson_plan.length !== 0 &&
						fetchMoreResult.data?.lesson_plan.length >= limit
					) {
						setMoreResults(true)
					} else {
						setMoreResults(false)
					}
				})
				break
			case ProductTitleEnum.Module:
				selectedModulesRefetch({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.module as Module[],
							isFetching: true,
							type: RefetchProductsEnum.Module,
							variables: moduleVariables,
							query: GetModulesByClassIdDocument
						}) as boolean
					)
					if (
						fetchMoreResult.data?.module.length !== 0 &&
						fetchMoreResult.data?.module.length >= limit
					) {
						setMoreResults(true)
					} else {
						setMoreResults(false)
					}
				})
				break
			case ProductTitleEnum.Song:
				selectedSongsRefetch({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.song as Song[],
							isFetching: true,
							type: RefetchProductsEnum.Song,
							variables: songVariables,
							query: GetSongByClassIdDocument
						}) as boolean
					)
					if (fetchMoreResult.data?.song.length !== 0 && fetchMoreResult.data?.song.length >= limit) {
						setMoreResults(true)
					} else {
						setMoreResults(false)
					}
				})

				break
			case ProductTitleEnum.Tip:
				selectedTipsRefetch({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.tip as Tip[],
							isFetching: true,
							type: RefetchProductsEnum.Tip,
							variables: tipVariables,
							query: GetTipByClassIdDocument
						}) as boolean
					)
					if (fetchMoreResult.data?.tip.length !== 0 && fetchMoreResult.data?.tip.length >= limit) {
						setMoreResults(true)
					} else {
						setMoreResults(false)
					}
				})
				break
			case ProductTitleEnum.Playlist:
				selectedPlaylistRefetch({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.playlist as Playlist[],
							isFetching: true,
							type: RefetchProductsEnum.Playlist,
							variables: playlistVariables,
							query: GetPlaylistByClassIdDocument
						}) as boolean
					)
					if (
						fetchMoreResult.data?.playlist.length !== 0 &&
						fetchMoreResult.data?.playlist.length >= limit
					) {
						setMoreResults(true)
					} else {
						setMoreResults(false)
					}
				})
				break
			default:
				break
		}
	}

	useEffect(() => {
		if (isRefetch) {
			let existingCache
			switch (type) {
				case ProductTitleEnum.LessonPlan:
					existingCache = getExistingCache({
						variables: lessonsVariables,
						query: GetLessonPlansByClassIdDocument
					}) as {
						lesson_plan: Lesson_Plan[]
					}
					setData(existingCache.lesson_plan as [])
					break
				case ProductTitleEnum.Module:
					existingCache = getExistingCache({
						variables: moduleVariables,
						query: GetModulesByClassIdDocument
					}) as {
						module: Module[]
					}
					setData(existingCache.module as [])
					break
				case ProductTitleEnum.Song:
					existingCache = getExistingCache({
						variables: songVariables,
						query: GetSongByClassIdDocument
					}) as {
						song: Song[]
					}
					setData(existingCache.song as [])
					break
				case ProductTitleEnum.Tip:
					existingCache = getExistingCache({
						variables: tipVariables,
						query: GetTipByClassIdDocument
					}) as {
						tip: Tip[]
					}
					setData(existingCache.tip as [])
					break
				case ProductTitleEnum.Playlist:
					existingCache = getExistingCache({
						variables: playlistVariables,
						query: GetPlaylistByClassIdDocument
					}) as {
						playlist: Playlist[]
					}
					setData(existingCache.playlist as [])
					break
				default:
					break
			}
			setIsRefetch(false)
			setIsFetching(false)
		}
	}, [isRefetch])
	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (searchbarDataLessonPlan)
					setSearchbarData(searchbarDataLessonPlan?.lesson_plan as Lesson_Plan[])
				break
			case ProductTitleEnum.Module:
				if (searchbarDataModule) setSearchbarData(searchbarDataModule?.module as Module[])
				break
			case ProductTitleEnum.Song:
				if (searchbarDataSong) setSearchbarData(searchbarDataSong?.song as Song[])
				break
			case ProductTitleEnum.Tip:
				if (searchbarDataTip) setSearchbarData(searchbarDataTip?.tip as Tip[])
				break
			case ProductTitleEnum.Playlist:
				if (searchbarDataPlaylist) setSearchbarData(searchbarDataPlaylist?.playlist as Playlist[])
				break
			default:
				break
		}
	}, [searchbarDataLessonPlan, searchbarDataModule, searchbarDataSong, searchbarDataTip, searchbarDataPlaylist])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				getSearchLessons({
					variables: {
						limit,
						search: buildSearchText(searchbarText, ['title', 'description']),
						classId: Number(classId)
					}
				})
				break
			case ProductTitleEnum.Module:
				getSearchModules({
					variables: {
						limit,
						search: buildSearchText(searchbarText, ['title', 'description']),
						classId: Number(classId)
					}
				})
				break
			case ProductTitleEnum.Song:
				getSearchSongs({
					variables: {
						limit,
						search: buildSearchText(searchbarText, ['title']),
						classId: Number(classId)
					}
				})
				break
			case ProductTitleEnum.Tip:
				getSearchTips({
					variables: {
						limit,
						search: buildSearchText(searchbarText, ['title', 'description']),
						classId: Number(classId)
					}
				})
				break
			case ProductTitleEnum.Playlist:
				getSearchPlaylists({
					variables: {
						limit,
						search: buildSearchText(searchbarText, ['name', 'description']),
						classId: Number(classId)
					}
				})
				break
			default:
				break
		}
	}, [searchbarText])
	useEffect(() => {
		if (getClassData) {
			setClassName(getClassData.class_by_pk?.title || '')
		}
	}, [getClassData])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				setSearchbarLoading(searchbarLoadingLessons)
				break
			case ProductTitleEnum.Module:
				setSearchbarLoading(searchbarLoadingModules)
				break
			case ProductTitleEnum.Song:
				setSearchbarLoading(searchbarLoadingSongs)
				break
			case ProductTitleEnum.Tip:
				setSearchbarLoading(searchbarLoadingTips)
				break
			case ProductTitleEnum.Playlist:
				setSearchbarLoading(searchbarLoadingPlaylists)
				break
			default:
				break
		}
	}, [
		searchbarLoadingLessons,
		searchbarLoadingModules,
		searchbarLoadingSongs,
		searchbarLoadingTips,
		searchbarLoadingPlaylists
	])

	useEffect(() => {
		getClass({
			variables: {
				classId: Number(classId)
			}
		})
		getData('')
	}, [])

	useEffect(() => {
		if (!isFetching)
			switch (type) {
				case ProductTitleEnum.LessonPlan:
					if (selectedLessonsData) {
						setData(selectedLessonsData?.lesson_plan.map((item) => item) as Lesson_Plan[])
						setIsRefetch(
							refetchCache({
								data: selectedLessonsData.lesson_plan as Lesson_Plan[],
								type: RefetchProductsEnum.LessonPlan,
								isFetching: false,
								variables: lessonsVariables,
								query: GetLessonPlansByClassIdDocument
							}) as boolean
						)
						setLoadingData(selectedLessonsLoading)
						setTotalData(selectedLessonsData?.lesson_plan_aggregate.aggregate?.count || 0)
						setSelectedData(selectedLessonsData?.lesson_plan.map((item) => item) as Lesson_Plan[])
					}
					break
				case ProductTitleEnum.Module:
					if (selectedModulesData) {
						setData(selectedModulesData?.module.map((item) => item) as Module[])
						setIsRefetch(
							refetchCache({
								data: selectedModulesData.module as Module[],
								type: RefetchProductsEnum.Module,
								isFetching: false,
								variables: moduleVariables,
								query: GetModulesByClassIdDocument
							}) as boolean
						)
						setLoadingData(selectedModuleLoading)
						setTotalData(selectedModulesData?.module_aggregate.aggregate?.count || 0)
						setSelectedData(selectedModulesData?.module.map((item) => item) as Module[])
					}
					break
				case ProductTitleEnum.Song:
					if (selectedSongsData) {
						setData(selectedSongsData?.song.map((item) => item) as Song[])
						setIsRefetch(
							refetchCache({
								data: selectedSongsData.song as Song[],
								type: RefetchProductsEnum.Song,
								isFetching: false,
								variables: songVariables,
								query: GetSongByClassIdDocument
							}) as boolean
						)
						setLoadingData(selectedSongsLoading)
						setTotalData(selectedSongsData?.song_aggregate.aggregate?.count || 0)
						setSelectedData(selectedSongsData?.song.map((item) => item) as Song[])
					}
					break
				case ProductTitleEnum.Tip:
					if (selectedTipsData) {
						setData(selectedTipsData?.tip.map((item) => item) as Tip[])
						setIsRefetch(
							refetchCache({
								data: selectedTipsData.tip as Tip[],
								type: RefetchProductsEnum.Tip,
								isFetching: false,
								variables: tipVariables,
								query: GetTipByClassIdDocument
							}) as boolean
						)
						setLoadingData(selectedTipsLoading)
						setTotalData(selectedTipsData?.tip_aggregate.aggregate?.count || 0)
						setSelectedData(selectedTipsData?.tip.map((item) => item) as Tip[])
					}
					break
				case ProductTitleEnum.Playlist:
					if (selectedPlaylistData) {
						setData(selectedPlaylistData?.playlist.map((item) => item) as Playlist[])
						setIsRefetch(
							refetchCache({
								data: selectedPlaylistData.playlist as Playlist[],
								type: RefetchProductsEnum.Playlist,
								isFetching: false,
								variables: playlistVariables,
								query: GetPlaylistByClassIdDocument
							}) as boolean
						)
						setLoadingData(selectedPlaylistLoading)
						setTotalData(selectedPlaylistData?.playlist_aggregate.aggregate?.count || 0)
						setSelectedData(selectedPlaylistData?.playlist.map((item) => item) as Playlist[])
					}
					break
				default:
					break
			}
	}, [selectedLessonsData, selectedModulesData, selectedSongsData, selectedTipsData, selectedPlaylistData])
	useEffect(() => {
		if (!showAddDialog && isOnClose) {
			switch (type) {
				case ProductTitleEnum.LessonPlan:
					setSelectedData(selectedLessonsData?.lesson_plan.map((item) => item) as Lesson_Plan[])
					break
				case ProductTitleEnum.Module:
					setSelectedData(selectedModulesData?.module.map((item) => item) as Module[])
					break
				case ProductTitleEnum.Song:
					setSelectedData(selectedSongsData?.song.map((item) => item) as Song[])
					break
				case ProductTitleEnum.Tip:
					setSelectedData(selectedTipsData?.tip.map((item) => item) as Tip[])
					break
				case ProductTitleEnum.Playlist:
					setSelectedData(selectedPlaylistData?.playlist.map((item) => item) as Playlist[])
					break
				default:
					break
			}
			setIsOnClose(false)
		}
	}, [showAddDialog])

	return {
		data,
		loadingData,
		totalData,
		styles,
		searchbarText,
		handleAddProductDialog,
		type,
		breadCrumbs,
		getId,
		isModulesDialogOpen,
		lessonPlanId,
		setLessonPlanId,
		setIsModulesDialogOpen,
		handleCancelAddDialog,
		setShowAddDialog,
		setSelectedData,
		showAddDialog,
		selectedData,
		handleSearchbarTextChange,
		handleSearchbarTextEnter,
		searchbarData,
		searchbarLoading,
		fetchMore,
		moreResults
	}
}
