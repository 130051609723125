import { useEffect, useState } from 'react'

import { Box, Button, Grid, styled, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Doughnut } from 'react-chartjs-2'
import { cache } from 'src/apollo/state'
import { LinkButton } from 'src/components/Buttons/LinkButton'
import { useGetPracticeEventLazyQuery, useStudentGoalLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { SetGoal } from 'src/scenes/Student/1-Home/components/SetGoal'
import { getPreviousMonday } from 'src/utils'

import { ActivityBoxEmpty } from '../../../../../components/ActivityBoxEmpty'
import useStyles from './StudentGoals.styles'

const Title = styled(Typography)({
	marginBottom: '1.1em'
})

export const StudentGoals = () => {
	const styles = useStyles()
	const [goalOpen, setGoalOpen] = useState(false)
	const { studentData: student } = useLoginContext()
	const [getPractive, { data }] = useGetPracticeEventLazyQuery()
	const [getGoal, { data: goalData }] = useStudentGoalLazyQuery()
	const goal = goalData?.student_goal[0]?.weekly_goal
	const practice = data?.student_badge_event_aggregate?.aggregate?.count || 0
	const left = Number(goal) - Number(practice)
	const percent = (Number(practice) / Number(goal)) * 100

	useEffect(() => {
		if (!student.student_id) return
		const prevMonday = getPreviousMonday()
		cache.evict({
			id: 'ROOT_QUERY',
			fieldName: 'student_badge_event_aggregate'
		})
		getPractive({
			variables: { studentId: student.student_id, date: prevMonday }
		})
		getGoal({ variables: { studentId: student.student_id } })
	}, [student.student_id])

	return (
		<Grid xs={6} item>
			<Title variant="h6">
				<b>Your Goal this Week</b>
			</Title>
			<ActivityBoxEmpty className={styles.activityEmpty}>
				<Box>
					{!goal && (
						<Button
							variant="contained"
							color="primary"
							endIcon={<AddIcon />}
							onClick={() => setGoalOpen(true)}>
							Set A Goal to Earn Rewards!
						</Button>
					)}
					{goal && (
						<Box className={styles.box}>
							<Box className={styles.linkButtonHolder}>
								<LinkButton
									className={styles.linkButton}
									label="Edit"
									onClick={() => setGoalOpen(true)}
								/>
							</Box>
							<Box>
								<Box className={styles.chart}>
									<Doughnut
										data={{
											datasets: [
												{
													data: [practice, left >= 0 ? left : 0],
													backgroundColor: ['#F3AB34', '#E9E9EA'],
													borderWidth: 0
												}
											]
										}}
										options={{
											responsive: true,
											cutout: '80%',
											radius: 110,
											plugins: {
												tooltip: { enabled: false }
											}
										}}
									/>
								</Box>
								<Typography className={styles.practice} variant="body1">
									{left > 0 ? (
										<strong>{`Practice ${left} more times to reach your goal!`}</strong>
									) : (
										<strong>You've reached your weekly goal!</strong>
									)}
								</Typography>
								<Typography className={styles.youAre} variant="body1">
									{`You are ${
										percent <= 100 ? Math.round(percent) : 100
									}% complete - you got this.`}
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
			</ActivityBoxEmpty>

			<SetGoal isOpen={goalOpen} setIsOpen={setGoalOpen} studentId={student.student_id} />
		</Grid>
	)
}
