import { NotesIcon } from '../../../../assets/icons'
import { FixedBackground } from '../../../../components'
import { StudentAccountActivationDialog } from './components/StudentAccountActivationDialog'
import { StudentAccountActivationProvider } from './useStudentAccountActivation'

export const StudentAccountActivation = () => {
	return (
		<StudentAccountActivationProvider>
			<FixedBackground>
				<img
					src={NotesIcon}
					style={{
						width: '95%',
						position: 'fixed',
						bottom: '40px',
						left: '50%',
						transform: 'translateX(-50%)'
					}}
				/>
				<StudentAccountActivationDialog />
			</FixedBackground>
		</StudentAccountActivationProvider>
	)
}
