import { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { Page } from 'src/@types/types'
import { useGetSearchVideosLazyQuery, useGetVideosInCatalogIdsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Tip, Tip_Bool_Exp } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, buildSearchText, MAX_LIST_ITEMS, SortOrder } from 'src/utils'

type useCatalogVideosParams = {
	teacherId: number
	catalogId?: number
	catalogItemId?: number
	featured: boolean | undefined
	catalogFilters: number[]
	page?: Page
}

export const useCatalogVideos = ({
	catalogFilters,
	catalogId,
	featured,
	teacherId,
	catalogItemId,
	page
}: useCatalogVideosParams) => {
	const location = useLocation()
	const [sort, setSort] = useState<SortOrder>(SortOrder.Up)
	const state = location.state as Record<string, string>
	const search = state?.searchbarText
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [videos, setVideos] = useState<Tip[]>([])
	const [count, setCount] = useState(0)
	const [isFetching, setIsFetching] = useState(false)
	const [loading, setLoading] = useState(true)
	const [moreResults, setMoreResults] = useState(true)
	const [searchBarTextState, setSearchBarTextState] = useState('')
	const history = useHistory()
	const order = sort === SortOrder.Up ? Order_By.Asc : Order_By.Desc
	const queryVariables = {
		teacherId,
		searchConditions: buildSearchText(search || '', ['title', 'artist.name']),
		offset: 0,
		limit,
		order,
		featured: featured ? { _eq: true } : { _is_null: false },
		conditions: buildLessonSearchFilters(catalogFilters, { catalogId, catalogItemId })
	}

	// get queries
	const [getVideosInCatalogIds, getVideosInCatalogIdsData] = useGetVideosInCatalogIdsLazyQuery({
		variables: {
			...queryVariables
		}
	})

	const [getSearchVideos, getSearchVideosData] = useGetSearchVideosLazyQuery({
		variables: {
			...queryVariables
		}
	})

	const onSearch = (search: string) => {
		getSearchVideos({
			variables: {
				teacherId,
				searchConditions: buildSearchText(search || '', ['title', 'artist.name']),
				limit,
				order
			}
		})
	}
	const onSearchEnter = (searchbarText: string) => {
		if ((page && page.path === Pages.SearchVideos.path) || !searchbarText) {
			getVideosInCatalogIds({
				variables: {
					teacherId,
					searchConditions: buildSearchText(searchbarText || '', ['title', 'artist.name']),
					offset: 0,
					limit,
					order,
					featured: featured ? { _eq: true } : { _is_null: false },
					conditions: [] // buildLessonSearchFilters(catalogFilters, { catalogId, catalogItemId })
				}
			})

			return
		}

		history.push(buildRouteParameters(Pages.SearchVideos), {
			searchbarText
		})
	}

	const handleSort = (value: string) => {
		const selectedSort = value as SortOrder
		setSort(selectedSort)
	}

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [videos])

	useEffect(() => {
		if (searchBarTextState === search) return
		if (search) {
			setSearchBarTextState(search)
		}
	}, [search])

	useEffect(() => {
		if (getVideosInCatalogIdsData.loading) return setLoading(true)

		const data = getVideosInCatalogIdsData.data
		if (data) {
			if (!videos?.length && !data?.tip?.length) {
				setIsFetching(false)
				setLoading(false)
			}
			setVideos((data.tip || []) as Tip[])
			setCount(data.tip_aggregate.aggregate?.count || 0)
		}
	}, [getVideosInCatalogIdsData, getSearchVideosData])

	useEffect(() => {
		// if (catalogId) {
		getVideosInCatalogIds()
		// } else {
		// 	getSearchVideos()
		// }
	}, [catalogId, catalogFilters, search, featured, sort])

	useEffect(() => {
		if (!isFetching) return
		if (getVideosInCatalogIdsData.loading || getSearchVideosData.loading) return

		let fetchFun
		if (catalogId) {
			fetchFun = getVideosInCatalogIdsData.fetchMore
		} else {
			fetchFun = getSearchVideosData.fetchMore
		}

		const currentLength = videos.length
		if (fetchFun) {
			fetchFun({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.tip.length !== 0) {
					setLimit(currentLength + (fetchMoreResult.data?.tip.length || 0))
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	return {
		videos,
		isFetching,
		moreResults,
		loading,
		count,
		onSearchEnter,
		onSearch,
		getSearchVideosData,
		getSearchVideos,
		sort,
		handleSort,
		searchBarTextState
	}
}

/**
 *
 * @param filtersArray catalog_item_ids to filter
 * @param catalogId if it is the only filter, it will filter by just catalog_id which is the general catalog like "Instruments"
 * @param catalogItemId if it is included it will filter by catalog_item_id which is a specific catalog like "Guitar"
 * @returns Tip_Bool_Exp to be used in the query
 */

export const buildLessonSearchFilters = (
	filtersArray: number[],
	{ catalogId, catalogItemId }: { catalogId?: number; catalogItemId?: number }
) => {
	const filters = filtersArray.map<Tip_Bool_Exp>((catalogItemId) => ({
		tip_catalog_item: { catalog_item_id: { _eq: catalogItemId } }
	}))
	// if !catalogItemId, it means that we are filtering by catalog_id
	if (catalogId && !catalogItemId) {
		filters.push({
			tip_catalog_item: { catalog_item: { catalog_id: { _eq: catalogId } } }
		})
		// if both filter by catalog_item_id
	} else if (catalogId && catalogItemId) {
		filters.push({ tip_catalog_item: { catalog_item: { catalog_item_id: { _eq: catalogItemId } } } })
	}

	return filters
}
