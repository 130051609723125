import { Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { CheckIcon, DisabledIcon, LockIcon, OpenLookIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { Order_Status_Enum } from 'src/graphql/autogenerate/schemas'

import useStyles from './StatusProductButton.styles'

type StatusProductButtonProps = {
	isSubscription?: boolean
	isDistrict?: boolean
	status: string
	isSubscriptionCard?: boolean
}
export const StatusProductButton = ({
	isSubscription = false,
	isDistrict = true,
	status,
	isSubscriptionCard = false
}: StatusProductButtonProps) => {
	const styles = useStyles()

	return (
		<>
			{!isSubscription && !isSubscriptionCard && (
				<Tooltip title="You don't own this product">
					<BaseButton
						color="primary"
						startIcon={<LockIcon />}
						className={clsx(styles.button, styles.locked)}
						onClick={(e) => {
							e.stopPropagation()
						}}>
						<Typography className={styles.text}>Locked</Typography>
					</BaseButton>
				</Tooltip>
			)}
			{(isSubscription || isSubscriptionCard) && status === Order_Status_Enum.Active && (
				<Tooltip title="You own this product">
					<BaseButton
						startIcon={<CheckIcon />}
						className={clsx(styles.check, styles.button)}
						onClick={(e) => {
							e.stopPropagation()
						}}>
						<Typography className={styles.text}>Subscribed</Typography>
					</BaseButton>
				</Tooltip>
			)}

			{(isSubscription || isSubscriptionCard) && status === Order_Status_Enum.Canceled && (
				<Tooltip title="You have unsubscribed from this product">
					<BaseButton
						startIcon={<DisabledIcon />}
						className={clsx(styles.button, styles.disabled)}
						onClick={(e) => {
							e.stopPropagation()
						}}>
						<Typography className={styles.text}>Unsubscribed</Typography>
					</BaseButton>
				</Tooltip>
			)}

			{isSubscription && status !== Order_Status_Enum.Active && status !== Order_Status_Enum.Canceled && (
				<Tooltip title="You own this product">
					<BaseButton
						startIcon={<OpenLookIcon />}
						className={clsx(styles.button, styles.openLookIcon)}
						onClick={(e) => {
							e.stopPropagation()
						}}>
						<Typography className={styles.text}>{isDistrict ? 'District' : 'School'}</Typography>
					</BaseButton>
				</Tooltip>
			)}
		</>
	)
}
