import { ChangeEvent, useEffect, useState } from 'react'

import {
	Box,
	createStyles,
	Grid,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Tooltip,
	Typography
} from '@material-ui/core'
import { ChartData } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { DateFilterEnum } from 'src/environment'
import { Class } from 'src/graphql/autogenerate/schemas'
import { getCyKey } from 'src/utils'

import { BigBaseButton } from '../../../../../../../../../components'
import { useStudentAnalyticsContext } from '../useStudentAnalytics'

const useStyles = makeStyles(() =>
	createStyles({
		select: {
			'& .MuiOutlinedInput-input': {
				padding: '10px 14px'
			}
		}
	})
)

const NO_CLASS_LABEL = 'No Class'
export const StudentAnalyticsInsights = () => {
	const styles = useStyles()
	const { chartData, setFilter, setClassId, classes } = useStudentAnalyticsContext()
	const [classTitle, setClassTitle] = useState(NO_CLASS_LABEL)

	const handleClassSelect = (c: Class) => {
		setClassId(c.class_id)
		setClassTitle(c.title)
	}
	const handleDefaultClassSelect = () => setClassTitle(NO_CLASS_LABEL)
	const handleDateSelect = (
		e: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => setFilter(String(e.target.value) as DateFilterEnum)

	useEffect(() => {
		if (classes?.length) {
			setClassId(classes[0].class_id)
			setClassTitle(classes[0].title)
		}
	}, [classes.length])

	return (
		<Box pt={3}>
			<Box display="flex" justifyContent="space-between" marginBottom={2}>
				<Box>
					<Typography variant="h6">
						<b>Check Insights</b>
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Select
								className={styles.select}
								style={{ marginTop: '10px', minWidth: '240px', height: '40px' }}
								variant="outlined"
								color="secondary"
								onClick={(e) => e.stopPropagation()}
								value={classTitle}>
								{classes?.length ? (
									classes?.map((i) => (
										<MenuItem
											onClick={() => handleClassSelect(i)}
											value={i.title}
											key={i.class_id}>
											{i.title}
										</MenuItem>
									))
								) : (
									<MenuItem onClick={handleDefaultClassSelect} value={NO_CLASS_LABEL}>
										{NO_CLASS_LABEL}
									</MenuItem>
								)}
							</Select>
						</Grid>
						<Grid item xs={6}>
							<Select
								className={styles.select}
								style={{ marginTop: '10px', minWidth: '240px', height: '40px' }}
								variant="outlined"
								color="secondary"
								onClick={(e) => e.stopPropagation()}
								defaultValue={DateFilterEnum.lastWeek}
								onChange={handleDateSelect}>
								<MenuItem value={DateFilterEnum.lastWeek}>Last 7 Days</MenuItem>
								<MenuItem value={DateFilterEnum.thisMonth}>This Month</MenuItem>
								<MenuItem value={DateFilterEnum.lastMonth}>Last Month</MenuItem>
								<MenuItem value={DateFilterEnum.lastSixMonths}>Last 6 Month</MenuItem>
								<MenuItem value={DateFilterEnum.thisYear}>This Year</MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Box>

				<Tooltip title="Coming soon">
					<BigBaseButton
						data-cy={getCyKey(StudentAnalyticsInsights, 'btnExport')}
						style={{
							width: '220px'
						}}
						color="default"
						onClick={() => {}}>
						<b>Export</b>
					</BigBaseButton>
				</Tooltip>
			</Box>
			<Box mb={5} mt={3}>
				<Paper elevation={3}>
					<Box pt={2} pl={3}>
						<Typography variant="h5">
							<b>Student Productivity</b>
						</Typography>
					</Box>
					<Line
						data={chartData as ChartData<'line', number[], string>}
						options={{
							layout: {
								padding: {
									top: 20,
									left: 20,
									right: 20,
									bottom: 50
								}
							},
							plugins: {
								tooltip: {
									titleFont: { size: 14 },
									bodyFont: { size: 14 },
									mode: 'index',
									position: 'nearest'
								},
								legend: {
									align: 'end',
									labels: {
										font: {
											size: 14,
											weight: 'bold'
										},
										usePointStyle: true
									}
								}
							},

							scales: {
								yAxis: {
									beginAtZero: true
								},
								xAxis: {
									beginAtZero: true,
									ticks: {
										maxRotation: 90,
										minRotation: 90
									}
								}
							}
						}}
					/>
				</Paper>
			</Box>
		</Box>
	)
}
