import { createStyles, makeStyles } from '@material-ui/core'

export const useGradeStarStyles = makeStyles(() =>
	createStyles({
		avatarIcon: {
			height: '60px',
			width: '60px'
		},
		gradeValue: {
			color: '#00BA70',
			textTransform: 'capitalize',
			position: 'absolute',
			top: '52%',
			left: '50%',
			transform: 'translate3D(-50%,-50%,0)',
			width: '38px',
			overflowWrap: 'break-word',
			textAlign: 'center',
			lineHeight: '1',
			fontSize: '12px'
		}
	})
)

export default useGradeStarStyles
