import { Box } from '@material-ui/core'

import { PagesProps } from '../../../../../../@types'
import { BOX_PADDING } from '../../../../../../styles/constants'
import { ClassDetailsTitle } from './components/ClassDetailsTitle'
import { ClassWurrlies } from './components/ClassWurrlies'
import { ClassDetailsProvider } from './hooks/useClassDetails'

export const ClassDetails = (props: PagesProps) => {
	return (
		<Box>
			<ClassDetailsProvider>
				<ClassDetailsTitle {...props} />
				<Box p={BOX_PADDING}>
					<ClassWurrlies />
				</Box>
			</ClassDetailsProvider>
		</Box>
	)
}
