import { Button, ButtonProps, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export const LinkButton = ({ variant = 'text', color, label, ...props }: ButtonProps & { label: string }) => {
	const theme = useTheme()

	const getColor = (color?: 'inherit' | 'primary' | 'secondary' | 'default') => {
		if (color === 'primary' && theme.palette.type === 'dark') return 'default'
		else return color
	}

	return (
		<Button variant={variant} color={getColor(color)} {...props}>
			<Typography color="secondary">
				<b>{label}</b>
			</Typography>
		</Button>
	)
}
