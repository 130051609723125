import { Box, CircularProgress, Typography } from '@material-ui/core'
import { ListLessons } from 'src/components'
import { MyFilesAccordion } from 'src/components/MyFilesAccordion'

import useStyles from './LessonUploads.styles'
import { useLessonUploads } from './useLessonUploads'

export const LessonUploads = () => {
	const styles = useStyles()
	const { totalLessons, lessons, sort, handleSort, seeMoreEnabled, seeMore, loading, teacherId } =
		useLessonUploads()

	return (
		<MyFilesAccordion
			title="Lesson Uploads"
			count={totalLessons}
			details={
				<>
					{!loading && <ListLessons teacherId={teacherId} spacing={1.5} data={lessons} />}
					{loading && (
						<Box className={styles.boxLoading}>
							<CircularProgress color="secondary" size={30} />
						</Box>
					)}
					{seeMoreEnabled && (
						<Typography color="textSecondary" className={styles.seeMore} onClick={seeMore}>
							<b>See More</b>
						</Typography>
					)}
				</>
			}
			sort={sort}
			handleSort={handleSort}
		/>
	)
}
