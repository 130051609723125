import { Box, Typography } from '@material-ui/core'

import { useLoginStyles } from '../styles'

type DialogHeaderProps = {
	title: string
	subtitle: string
}

export const DialogHeader = ({ title, subtitle }: DialogHeaderProps) => {
	const classes = useLoginStyles({})

	return (
		<Box className={classes.roleTitle}>
			<Typography variant="h4" align="center">
				<b>{title}</b>
			</Typography>
			<Typography align="center" className={classes.subtitle}>
				{subtitle}
			</Typography>
		</Box>
	)
}
