/* import React from 'react' */

import { Grid } from '@material-ui/core'

import { SectionHeader } from '../SectionHeader'
import { StudentsActivities } from './StudentActivities/StudentsActivities'
import { StudentAssignments } from './StudentAssignments/StudentAssignments'

export const StudentsSummmary = () => {
	return (
		<section>
			<SectionHeader content="See What Your Students Are Up To" />
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<SectionHeader content="Student Activities" />
					<StudentsActivities />
				</Grid>
				<Grid item xs={6}>
					<SectionHeader content="Assignments" />
					<StudentAssignments />
				</Grid>
			</Grid>
		</section>
	)
}
