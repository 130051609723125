import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

enum HoverColor {
	Dark = 'rgba(50, 51, 66, 1)',
	Light = 'rgba(255, 255, 255, 1)'
}
const CARD_CONTENT_COLOR = '#8E939E'
export const useClassHeaderCardStyles = makeStyles<Theme, { isTeacher?: boolean }>((theme) =>
	createStyles({
		cardsRow: {
			position: 'absolute',
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			paddingTop: 0,
			paddingBottom: 0,
			marginTop: '-5em'
		},
		cardPaper: ({ isTeacher }) => ({
			height: '10em',
			flex: '1 1 15em',
			maxWidth: '18em',
			minWidth: '12em',
			padding: '20px 0',
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
			justifyContent: 'space-between',
			color: CARD_CONTENT_COLOR,
			cursor: 'pointer',
			'& > * path': {
				fill: CARD_CONTENT_COLOR
			},
			'&:not(:first-child)': {
				marginLeft: '1em'
			},
			'&:hover': {
				backgroundColor: theme.palette.secondary.main,
				color: HoverColor.Dark,
				'& > * path': {
					fill: isTeacher ? HoverColor.Dark : HoverColor.Light
				}
			}
		})
	})
)

export default useClassHeaderCardStyles
