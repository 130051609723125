import { Box } from '@material-ui/core'

// TODO: move this logic to add students
export const ClassDetailsCodeGeneratorDialogs = () => {
	// const history = useHistory()
	// const { data: teacher } = useGetTeacher()
	// const { classId } = useClassDetailsContext()
	// const [isPaymentOpen, setIsPaymentOpen] = useState('')
	// const [isContactUsOpen, setIsContactUsOpen] = useState(false)
	// const [isPurchasedSuccess, setIsPurchasedSuccess] = useState(false)

	// const [updateClass] = useSetClassSettingsMutation()

	// const selectYourPackageOpts: IconActionOptionType[] = [
	// 	{
	// 		id: WURRLY_PACKAGES.Monthly.fullName,
	// 		name: (
	// 			<Box>
	// 				<Typography style={{ fontSize: 24 }}>
	// 					{WURRLY_PACKAGES.Monthly.unit}
	// 					{WURRLY_PACKAGES.Monthly.price}
	// 				</Typography>
	// 				<Typography style={{ fontSize: 12 }}>{WURRLY_PACKAGES.Monthly.frequency}</Typography>
	// 			</Box>
	// 		),
	// 		icon: <MusicNote />
	// 	},
	// 	{
	// 		id: WURRLY_PACKAGES.Yearly.fullName,
	// 		name: (
	// 			<Box>
	// 				<Typography style={{ fontSize: 24 }}>
	// 					{WURRLY_PACKAGES.Yearly.unit}
	// 					{WURRLY_PACKAGES.Yearly.price}
	// 				</Typography>
	// 				<Typography style={{ fontSize: 12 }}>{WURRLY_PACKAGES.Yearly.frequency}</Typography>
	// 			</Box>
	// 		),
	// 		icon: <QueueMusic />
	// 	}
	// ]

	return (
		<Box>
			{/* <ListDialog
				open={isGenerateClassCodeOpen}
				cols={2}
				onClose={() => setIsGenerateClassCodeOpen(false)}
				title="Select your package"
				description="Become a subscriber to add students to your class, providing them access thousands of songs, videos, and recording and assignment management tools!"
				optionSelected={''}
				options={selectYourPackageOpts}
				handleSelectOption={(opt: string) => {
					setIsGenerateClassCodeOpen(false)
					setIsPaymentOpen(opt)
				}}
				cardHeight={160}
				footer={
					<Typography>
						Need a quote or interested in enterprise packages?{' '}
						<BaseLink
							onClick={() => {
								setIsGenerateClassCodeOpen(false)
								setIsContactUsOpen(true)
							}}>
							Contact us
						</BaseLink>
					</Typography>
				}
			/>
			<ContactForm
				open={isContactUsOpen}
				onClose={() => setIsContactUsOpen(false)}
				onDiscard={() => {
					setIsContactUsOpen(false)
					setIsGenerateClassCodeOpen(true)
				}}
			/>
			<Elements stripe={STRIPE_PROMISE}>
				<PaymentForm
					open={!!isPaymentOpen}
					selectedPackage={isPaymentOpen}
					onCompletition={async () => {
						// generate class code
						await updateClass({
							variables: {
								classId: Number(classId),
								teacherId: teacher.teacher_id,
								classData: { code: generateUniqueId() }
							},
							awaitRefetchQueries: true,
							refetchQueries: [
								refetchGetClassByPkQuery({
									classId
								})
							]
						})
						// TODO: make user enterprise user
						setIsPaymentOpen('')
						setIsPurchasedSuccess(true)
					}}
					onClose={() => {}}
					onDiscard={() => {
						setIsPaymentOpen('')
						setIsGenerateClassCodeOpen(true)
					}}
				/>
			</Elements>
			<InfoDialog
				open={isPurchasedSuccess}
				onClose={() => setIsPurchasedSuccess(false)}
				title={
					<Typography align="center" style={{ fontWeight: 'bold', fontSize: 32 }}>
						Package Purchased {'\n'}
						Successfully!
					</Typography>
				}
				body="You can now add Students and share the Class Code. You can change your payment details from your Settings at any time."
				discardLabel="Back to Class"
				onDiscard={() => {
					setIsPurchasedSuccess(false)
					history.push(Pages.Classes.path)
				}}
				confirmLabel="Add Students"
				confirmProps={{ color: 'secondary' }}
				onConfirm={() => {
					setIsPurchasedSuccess(false)
					if (classId) {
						history.push(buildRouteParameters(Pages.ClassStudent, { selected: classId }))
					}
				}}
				icon={<Check />}
			/>
			<InfoDialog
				open={isCopyClassCodeOpen}
				onClose={() => setIsCopyClassCodeOpen(false)}
				title={
					<Typography align="center" style={{ fontWeight: 500, fontSize: 32 }}>
						Class Code Copied!
					</Typography>
				}
				body="You can now send this code to your Students to invite them to a Class directly."
				discardLabel="Close"
				onDiscard={() => {
					setIsCopyClassCodeOpen(false)
				}}
				icon={<Check />}
			/> */}
		</Box>
	)
}
