import { CSSProperties, ReactNode } from 'react'

import { Box, styled, Typography, TypographyProps } from '@material-ui/core'

type ItemTextProps = {
	disabled?: boolean
}
const Title = styled((props: TypographyProps & ItemTextProps) => <Typography {...props} />)({
	fontSize: '1.3em',
	color: ({ disabled }: ItemTextProps) => (!disabled ? 'black' : '#BFBFBF'),
	opacity: 0.87,
	fontWeight: 500
})
const Value = styled((props: TypographyProps & ItemTextProps) => <Typography {...props} />)({
	fontSize: '1.3em',
	color: ({ disabled }: ItemTextProps) => (!disabled ? '#1D273D' : '#BFBFBF'),
	opacity: 0.7
})
const Row = styled(Box)({
	width: '100%',
	'&:not(:last-child)': {
		marginBottom: '1.5em'
	}
})

type InfoItemProps = {
	title?: ReactNode
	value: ReactNode
	secondaryValue?: ReactNode
	disabled?: boolean
	style?: CSSProperties
}

export const InfoItem = ({ title, value, secondaryValue, disabled, style }: InfoItemProps) => {
	return (
		<Row style={style}>
			<Title disabled={disabled}>{title}</Title>
			{typeof value === 'string' ? <Value disabled={disabled}>{value}</Value> : value}
			{typeof secondaryValue === 'string' ? (
				<Value disabled={disabled}>{secondaryValue}</Value>
			) : (
				secondaryValue
			)}
		</Row>
	)
}
