import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { StudentWurrliesDetailsFormConfig } from './components/StudentWurrliesDetailsFormConfig'
import { StudentWurrliesDetailsTitle } from './components/StudentWurrliesDetailsTitle'
import { StudentWurrliesDetailsProvider } from './useStudentWurrliesDetails'

export const StudentWurrliesDetails = (props: PagesProps) => {
	return (
		<Box>
			<StudentWurrliesDetailsProvider>
				<StudentWurrliesDetailsTitle {...props} />
				<StudentWurrliesDetailsFormConfig />
			</StudentWurrliesDetailsProvider>
		</Box>
	)
}
