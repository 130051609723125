import { makeStyles, Theme } from '@material-ui/core'

export const useStudentGoalsStyles = makeStyles((theme: Theme) => ({
	activityEmpty: {
		padding: '3em',
		backgroundColor: theme.palette.common.white
	},
	box: {
		position: 'relative'
	},
	linkButtonHolder: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		width: '100%',
		'& button': {
			width: 'fit-content',
			textAlign: 'center'
		}
	},
	linkButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	chart: {
		position: 'relative',
		width: '90%',
		maxWidth: '250px',
		margin: '0 auto'
	},
	practice: {
		marginTop: '50px',
		color: 'black',
		fontSize: '20px'
	},
	youAre: {
		marginBottom: '50px',
		color: 'black',
		fontSize: '20px'
	}
}))

export default useStudentGoalsStyles
