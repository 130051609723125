import React, { Fragment, useEffect, useState } from 'react'

import { Box, InputAdornment, Typography } from '@material-ui/core'
import { CopyIcon, SyncClassLinkIcon, SyncCleverIcon } from 'src/assets/icons'
import { SendIcon } from 'src/assets/icons/CustomIcons'
import GoogleIcon from 'src/assets/icons/LoginIntegrations/GoogleIcon.svg'
import { BaseLink, TextInput, Tooltip, BaseDialog, BaseDialogProps } from 'src/components'
import { useAddStudentsSendEmailsMutation, useSetStudentEmailMutation } from 'src/graphql/autogenerate/hooks'
import { Integration_Name_Enum, StudentFromCsv } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { getCyKey, validateEmail } from 'src/utils'

import useStyles from './StudentInvitationDetailsDialog.styles'
type Props = {
	classId: number
	title: string | React.ReactNode
	description?: string | React.ReactNode
	studentName: string
	studentCode: string
	emailHeader: string
	studentEmail: string
	setStudentEmail?: React.Dispatch<React.SetStateAction<string>>
	setIsStudentInvitedDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
	setIsQRCodeDownloadedDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
	isSendEmail: boolean
	downloadQRCodeURL: string
	integrationName?: Integration_Name_Enum
}

export const StudentInvitationDetailsDialog = ({
	classId,
	title,
	description,
	studentName,
	studentCode,
	studentEmail,
	emailHeader,
	setStudentEmail,
	setIsStudentInvitedDialogOpen,
	setIsQRCodeDownloadedDialogOpen,
	isSendEmail,
	downloadQRCodeURL,
	integrationName,
	...ext
}: Props & BaseDialogProps) => {
	const styles = useStyles()
	const [email, setEmail] = useState(studentEmail)
	const { teacherData: teacher } = useLoginContext()
	const [
		addStudentsSendEmailsMutation,
		{ data: addStudentsSendEmailsData, loading: addStudentsSendEmailsLoading }
	] = useAddStudentsSendEmailsMutation()
	const [invalidEmailOpen, setInvalidEmailOpen] = useState(false)

	const [updateStudentEmail] = useSetStudentEmailMutation()
	const [isCopyTooltipOpen, setIsCopyTooltipOpen] = useState(false)
	const openTooltip = () => setIsCopyTooltipOpen(true)
	const closeTooltip = () => setIsCopyTooltipOpen(false)

	const isValidEmail = validateEmail(email)

	const handleCopy = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
		e.stopPropagation()
		copyCode()
	}
	const copyCode = () => {
		navigator.clipboard.writeText(studentCode)
		openTooltip()
	}

	useEffect(() => {
		setEmail(studentEmail)
	}, [studentEmail])

	useEffect(() => {
		if (addStudentsSendEmailsData) {
			if (setIsStudentInvitedDialogOpen) {
				setIsStudentInvitedDialogOpen(true)
			} else {
				alert('Mail sent')
			}
		}
	}, [addStudentsSendEmailsData])

	const handleStudentSendMultipleEmails = async () => {
		if (email) {
			try {
				const [firstName, lastName] = studentName.split(' ')
				const students = [
					{
						firstName,
						lastName,
						email,
						code: studentCode
					} as StudentFromCsv
				]
				await updateStudentEmail({ variables: { code: studentCode, email: studentEmail } })
				await addStudentsSendEmailsMutation({
					variables: {
						filter: {
							students,
							classId,
							teacherName: `${teacher.name_first} ${teacher.name_last}`
						}
					}
				})
			} catch (e) {
				console.error(e)
			}
		}
	}

	const handleSendEmail = () => {
		if (!addStudentsSendEmailsLoading && isSendEmail && isValidEmail) {
			handleStudentSendMultipleEmails()
		}
	}

	const handleSendEmailMouseEnter = () => {
		if (!isValidEmail) {
			setInvalidEmailOpen(true)
		} else {
			setInvalidEmailOpen(false)
		}
	}
	const handleSendEmailMouseLeave = () => setInvalidEmailOpen(false)

	useEffect(() => {
		if (isValidEmail && invalidEmailOpen) setInvalidEmailOpen(false)
	}, [isValidEmail])

	return (
		<Box>
			<BaseDialog
				dividers={false}
				header={
					<Fragment>
						<Typography variant="h4" align="center">
							<strong>{title}</strong>
						</Typography>
						{description && (
							<Typography align="center" variant="body2" className={styles.description}>
								{description}
							</Typography>
						)}
					</Fragment>
				}
				body={
					<Box>
						{integrationName && (
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-around"
								className={styles.integrationHolder}>
								<Box display="flex" alignItems="center">
									<img
										className={styles.integrationImage}
										src={
											integrationName === Integration_Name_Enum.Clever
												? SyncCleverIcon
												: integrationName === Integration_Name_Enum.ClassLink
												? SyncClassLinkIcon
												: GoogleIcon
										}
									/>{' '}
									<Typography className={styles.integrationText}>
										This student uses{' '}
										{integrationName === Integration_Name_Enum.Clever
											? 'Clever'
											: integrationName === Integration_Name_Enum.ClassLink
											? 'ClassLink'
											: 'Google Classroom'}
									</Typography>
								</Box>
							</Box>
						)}
						<TextInput
							data-cy={getCyKey(StudentInvitationDetailsDialog, 'StudentCodeTextInput')}
							id="Copy Student Code"
							name="Copy Student Code"
							className={styles.studentCode}
							value={studentCode}
							onChange={(value) => console.warn(value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip
											title="Copied!"
											placement="right"
											leaveDelay={200}
											open={isCopyTooltipOpen}
											onClose={closeTooltip}
											arrow
											interactive>
											<img
												data-cy={getCyKey(
													StudentInvitationDetailsDialog,
													'StudentCodeCopyIcon'
												)}
												alt="CopyIcon"
												src={CopyIcon}
												onClick={handleCopy}
												className={styles.cursorPointer}
											/>
										</Tooltip>
									</InputAdornment>
								)
							}}
						/>

						{!integrationName && (
							<TextInput
								data-cy={getCyKey(StudentInvitationDetailsDialog, 'StudentEmailTextInput')}
								disabled={addStudentsSendEmailsLoading}
								id="Invite By Email"
								customMargin="0px"
								name={emailHeader}
								value={email}
								onChange={(value) => {
									setEmail(value.target.value)
									if (setStudentEmail) setStudentEmail(value.target.value)
								}}
								InputProps={{
									endAdornment: isSendEmail && (
										<Tooltip
											title="Invalid email"
											placement="right"
											open={invalidEmailOpen}
											arrow
											interactive
											leaveDelay={200}>
											<InputAdornment position="end">
												<SendIcon
													onClick={handleSendEmail}
													onMouseEnter={handleSendEmailMouseEnter}
													onMouseLeave={handleSendEmailMouseLeave}
													className={
														!isValidEmail
															? styles.disabledSendEmail
															: styles.cursorPointer
													}
												/>
											</InputAdornment>
										</Tooltip>
									)
								}}
							/>
						)}
						<BaseLink
							href={downloadQRCodeURL}
							download={`${studentName}.pdf`}
							target="_blank"
							onClick={() => {
								if (setIsQRCodeDownloadedDialogOpen) setIsQRCodeDownloadedDialogOpen(true)
							}}>
							<Box mt={1.5}>
								<b>Or Download QR Code</b>
							</Box>
						</BaseLink>
					</Box>
				}
				onConfirm={() => {
					if (!addStudentsSendEmailsLoading && isSendEmail) {
						handleStudentSendMultipleEmails()
					}
				}}
				confirmLabel="Send"
				isConfirmDisabled={!isValidEmail}
				onConfirmProps={{
					style: {
						width: 150
					},
					type: 'submit'
				}}
				{...ext}
			/>
		</Box>
	)
}
