import { useEffect, useState } from 'react'

import moment from 'moment'
import { DateRange } from 'src/@types'
import {
	useGetClassAssignmentAnalyticsLazyQuery,
	useGetClassTeacherByPkQuery,
	useGetClassWurrliesCountLazyQuery,
	useGetCompletedAsignmentsLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Class } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { AssignmentSortOrder } from 'src/utils'

type useCountCardsProps = {
	classId: number
	dateRange: DateRange
}
export const useCountCards = ({ classId, dateRange }: useCountCardsProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const [sortOrder, setSortOrder] = useState(AssignmentSortOrder.AlphAsc)
	const [getClassAssignmentAnalyitics, { data: classAssignmentsCount }] =
		useGetClassAssignmentAnalyticsLazyQuery()
	const [getClassWurrliesCount, { data: classWurrliesCount }] = useGetClassWurrliesCountLazyQuery()
	const [getCompletedAssignments, { data: completedClassAssignments }] = useGetCompletedAsignmentsLazyQuery()

	const totalAssignments = classAssignmentsCount?.total?.aggregate?.count || 0
	const totalSubmitted = classAssignmentsCount?.submitted?.aggregate?.count || 0
	const totalComparisonSubmitted = classAssignmentsCount?.comparisonSubmitted?.aggregate?.count || 0
	const totalWurrlies = classWurrliesCount?.recordingSessions?.aggregate?.count || 0
	const totalComparisonWurlies = classWurrliesCount?.comparisonRecordingSessions?.aggregate?.count || 0

	useEffect(() => {
		const comparisonFromDate = moment(dateRange.fromDate).subtract(
			moment(dateRange.toDate).diff(moment(dateRange.fromDate), 'days'),
			'days'
		)
		const variables = {
			classId,
			fromDate: dateRange.fromDate,
			toDate: dateRange.toDate,
			comparisonToDate: dateRange.fromDate,
			comparisonFromDate
		}
		getClassAssignmentAnalyitics({ variables })
		getClassWurrliesCount({ variables })
	}, [dateRange, classId])

	useEffect(() => {
		if (classId)
			getCompletedAssignments({
				variables: {
					classId,
					fromDate: dateRange.fromDate,
					toDate: dateRange.toDate
				}
			})
	}, [sortOrder, dateRange, classId])

	const { data, loading: loadingClass } = useGetClassTeacherByPkQuery({
		variables: {
			teacherId,
			classId
		}
	})

	return {
		totalAssignments,
		totalSubmitted,
		totalComparisonSubmitted,
		totalWurrlies,
		totalComparisonWurlies,
		classData: data?.teacher_by_pk?.class_teachers[0]?.class as Class,
		loadingClass,
		completedClassAssignments: completedClassAssignments?.class_assignment || [],
		setSortOrder
	}
}
