import { config } from 'dotenv'

config()

interface Config {
	appVersion: string
	appName: string
	hasuraEndpoint: string
	env: EnvironmentEnum
	isProduction: boolean
	port: number
	awsUserPoolId: string
	awsAppClientCognito: string
	publicUrl: string
	stripePK: string
	ciNodeTotal: number
	sentryDSN: string
	sentryEnabled: boolean
	s3Bucket: string

	googleMapsApiKey: string
	googleClientId: string
	assetProcessor: string
	enableWDYR: boolean
	appcuesAccountId: string
}

export enum EnvironmentEnum {
	local = 'local',
	develop = 'develop',
	staging = 'staging',
	production = 'production'
}

export enum DateFilterEnum {
	thisWeek = 'thisWeek',
	lastWeek = 'lastWeek',
	lastSevenDays = 'lastSevenDays',
	thisMonth = 'thisMonth',
	lastMonth = 'lastMonth',
	lastSixMonths = 'lastSixMonths',
	thisYear = 'thisYear',
	lastYear = 'lastYear',
	nextSevenDays = 'nextSevenDays',
	nextThreeMonths = 'nextThreeMonths',
	allTime = 'allTime'
}

const {
	REACT_APP_HASURA_ENDPOINT,
	REACT_APP_ENV,
	PORT,
	WURRLY_USER_POOL_ID,
	WURRLY_APP_CLIENT_COGNITO,
	PUBLIC_URL,
	REACT_APP_STRIPE_PK,
	CI_NODE_TOTAL,
	REACT_APP_SENTRY_ENABLED,
	REACT_APP_SENTRY_DSN,
	REACT_APP_VERSION,
	REACT_APP_NAME,
	REACT_APP_BASE_URL,
	REACT_APP_GOOGLE_MAPS_API_KEY,
	REACT_APP_GOOGLE_CLIENT_ID,
	REACT_APP_ASSET_PROCESSOR,
	REACT_APP_WDYR_ENABLED,
	REACT_APP_APPCUES
} = process.env as Record<string, string>
export const environment: Config = {
	appVersion: REACT_APP_VERSION,
	appName: REACT_APP_NAME,
	hasuraEndpoint: REACT_APP_HASURA_ENDPOINT,
	env: REACT_APP_ENV ? (REACT_APP_ENV as unknown as EnvironmentEnum) : EnvironmentEnum.local,
	isProduction: REACT_APP_ENV === `${EnvironmentEnum.production}`,
	port: Number(PORT),
	awsUserPoolId: WURRLY_USER_POOL_ID,
	awsAppClientCognito: WURRLY_APP_CLIENT_COGNITO,
	publicUrl: PUBLIC_URL,
	stripePK: REACT_APP_STRIPE_PK,
	ciNodeTotal: Number(CI_NODE_TOTAL),
	sentryDSN: REACT_APP_SENTRY_DSN,
	sentryEnabled: REACT_APP_SENTRY_ENABLED === 'true',
	s3Bucket: REACT_APP_BASE_URL,
	googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
	googleClientId: REACT_APP_GOOGLE_CLIENT_ID,
	assetProcessor: REACT_APP_ASSET_PROCESSOR,
	enableWDYR: REACT_APP_WDYR_ENABLED === 'true',
	appcuesAccountId: REACT_APP_APPCUES
}
