import { buildGenericContext } from '../../../../context/genericContext'
import { useSetAcceptConcentMutation } from '../../../../graphql/autogenerate/hooks'
import { useSearchQuery } from '../../../../hooks'

const useParentAcceptance = () => {
	const query = useSearchQuery()
	const parentEmail = query.get('parentEmail')
	const code = query.get('code')
	const [insertAcceptance, { data, loading, error }] = useSetAcceptConcentMutation()

	return { insertAcceptance, parentEmail, code, data, loading, error }
}

export const [ParentAcceptanceProvider, useParentAcceptanceContext] = buildGenericContext(useParentAcceptance)
