import { useEffect, useState } from 'react'

import { Box, CircularProgress, ImageList, ImageListItem, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import UploadIcon from '@material-ui/icons/Publish'
import axios, { AxiosRequestConfig } from 'axios'
import clsx from 'clsx'
import { InputHeader } from 'src/components/Inputs/InputHeader'
import { useGetPresignedUrlLazyQuery } from 'src/graphql/autogenerate/hooks'
import { FileEnum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'
import { DEFAULT_THUMBNAIL_NAME } from 'src/utils/constants'

import { useSelectThumbnailStyles } from './SelectThumbnail.styles'
type SelectThumbnailProps = {
	handleSelectThumbnail: (thumbnail: string) => void
	handleSetThumbnailList: (thumbnail: string[]) => void
	thumbnailList: string[]
	thumbnailValue: string
	/**
	 * @param multiImage if true, the user can select multiple images
	 * @default true
	 */
	multiImage?: boolean
	isRequired?: boolean
}

export const SelectThumbnail: React.FC<SelectThumbnailProps> = ({
	handleSelectThumbnail,
	handleSetThumbnailList,
	thumbnailValue,
	thumbnailList,
	multiImage = true,
	isRequired = true
}) => {
	const styles = useSelectThumbnailStyles()

	const [thumbnailUpload, setThumbnailUpload] = useState<File>()
	const [isUploading, setIsUploading] = useState<boolean>(false)
	const [getPresignedUrlQuery, { loading, data }] = useGetPresignedUrlLazyQuery()

	const handleImageFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0]

			if (file) {
				const ext = file.name.split('.')[file.name.split('.').length - 1]

				getPresignedUrlQuery({
					variables: {
						filter: { conType: file.type, ext, type: FileEnum.Images, isTemporal: true }
					}
				})
				setIsUploading(true)
				setThumbnailUpload(file)
			}
		}
	}

	const handleSelect = (thumbnail: string) => {
		handleSelectThumbnail(thumbnail)
	}

	useEffect(() => {
		if (!thumbnailList.includes(DEFAULT_THUMBNAIL_NAME) || thumbnailList.length === 0 || !thumbnailList) {
			const list = thumbnailList.length === 0 ? [] : thumbnailList
			handleSetThumbnailList([DEFAULT_THUMBNAIL_NAME, ...list])
			if (thumbnailList.length === 0) handleSelectThumbnail(DEFAULT_THUMBNAIL_NAME)
		}
	}, [thumbnailList])

	useEffect(() => {
		const putThumbnail = async (options: AxiosRequestConfig, thumbnail: string) => {
			const response = await axios.request(options)

			if (response?.status === 200) {
				handleSelect(thumbnail)
				setIsUploading(false)
				if (!multiImage) {
					handleSetThumbnailList([thumbnail])

					return
				}
				handleSetThumbnailList([...thumbnailList, thumbnail])
			}
		}

		if (!loading && data) {
			const presigndedUrl = data.presignedUrl?.url as string
			const key = data.presignedUrl?.key as string
			try {
				const options: AxiosRequestConfig = {
					method: 'put',
					data: thumbnailUpload,
					url: presigndedUrl,
					headers: {
						'Content-Type': thumbnailUpload?.type as string
					}
				}
				putThumbnail(options, key)
			} catch (err) {
				setIsUploading(false)
			}
		}
	}, [loading, data])

	return (
		<Box marginTop="40px">
			<InputHeader name="Select a thumbnail" isRequired={isRequired} />

			<ImageList cols={4} gap={25}>
				{thumbnailList.map((thumbnail) => (
					<ImageListItem
						key={thumbnail}
						className={clsx(styles.thumbnail, {
							[styles.imageListSelected]: thumbnailValue === thumbnail
						})}
						onClick={() => handleSelect(thumbnail)}
						cols={1}>
						<img src={buildImagePath(thumbnail)} />
						{thumbnailValue === thumbnail && (
							<CheckCircleIcon className={styles.thumbnailIcon} fontSize="small" color="secondary" />
						)}
					</ImageListItem>
				))}
				<ImageListItem className={clsx(styles.thumbnail, styles.upload)} cols={1}>
					<>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="contained-button-file"
							type="file"
							onChange={handleImageFile}
						/>
						<Box className={styles.boxUpload}>
							{isUploading ? (
								<CircularProgress color="secondary" size={40} />
							) : (
								<label htmlFor="contained-button-file">
									<>
										<UploadIcon color="secondary" fontSize="medium" />
										<Typography color="secondary">
											<b>Upload Image</b>
										</Typography>
									</>
								</label>
							)}
						</Box>
					</>
				</ImageListItem>
			</ImageList>
		</Box>
	)
}
