import { Box, Paper, SvgIconProps, Typography, BoxProps } from '@material-ui/core'
import { getCyKey } from 'src/utils'

import useStyles from './ClassHeaderCard.styles'

export type HeaderCardProps = {
	title: string
	Icon?: (props: SvgIconProps) => JSX.Element
	count?: number
	onClick?: () => void
	isTeacher?: boolean
}

export const HeaderCard = ({ title, count, Icon, onClick, isTeacher }: HeaderCardProps) => {
	const styles = useStyles({ isTeacher })
	const handleClick = () => {
		if (onClick) onClick()
	}

	return (
		<Paper onClick={handleClick} className={styles.cardPaper}>
			<Typography>{Icon && <Icon height={35} width={35} />}</Typography>
			<Typography data-cy={getCyKey(HeaderCard, `squareBoxAboveCount-${title}`)} variant="h5">
				{count}
			</Typography>
			<Typography data-cy={getCyKey(HeaderCard, `squareBoxAbove-${title}`)} variant="caption">
				{title}
			</Typography>
		</Paper>
	)
}

/**
 * Useful for displaying the header cards, but you can use your own
 */
export const StyledCardsRow = (props: BoxProps) => {
	const styles = useStyles({})

	return <Box {...props} className={styles.cardsRow} />
}
