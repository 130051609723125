import { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { buildGenericContext } from 'src/context/genericContext'
import { useGetSongForStudioLazyQuery } from 'src/graphql/autogenerate/hooks'
import { GetSongForStudioQuery } from 'src/graphql/autogenerate/operations'
import { useWurrlyParams } from 'src/hooks'
import { StudentPages } from 'src/routes/studentpages'

const useStudioSetup = () => {
	const { songId, classId } = useWurrlyParams<typeof StudentPages.IndieStudentStudio.params[number]>()
	const { submissionId } = useWurrlyParams<typeof StudentPages.AssignmentStudentStudio.params[number]>()
	const { challengeId } = useWurrlyParams<typeof StudentPages.ChallengeStudentStudio.params[number]>()
	const [getSong, { data, loading: songLoading }] = useGetSongForStudioLazyQuery({ variables: { songId } })
	const history = useHistory()
	const [chords, setChords] = useState<string[]>()
	const [vocalGuide, setVocalGuide] = useState(false)
	const [instrument, setInstrument] = useState('')
	const [speed, setSpeed] = useState(0.0)
	const [key, setKey] = useState(0)
	const [music, setMusic] = useState<GetSongForStudioQuery['song_by_pk']>()
	const [loadingMusic, setLoadingMusic] = useState(true)

	useEffect(() => {
		if (data?.song_by_pk) {
			setMusic(data.song_by_pk)
			setLoadingMusic(false)
		} else if (data?.song_by_pk === null) {
			setLoadingMusic(false)
		}
	}, [data])

	return {
		setChords,
		chords,
		vocalGuide,
		setVocalGuide,
		instrument,
		setInstrument,
		speed,
		setSpeed,
		key,
		setKey,
		songId,
		history,
		songLoading,
		getSong,
		data,
		music,
		classId,
		submissionId,
		loadingMusic,
		challengeId
	}
}

export const [StudioSetupProvider, useStudioSetupContext] = buildGenericContext(useStudioSetup)
