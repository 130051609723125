import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { environment, EnvironmentEnum } from '../environment'
import { Wurrly_Role_Enum } from '../graphql/autogenerate/schemas'

export const loadSentry = () => {
	if (environment.sentryEnabled) {
		const options: Sentry.BrowserOptions = {
			dsn: environment.sentryDSN,
			release: 'wurrly-refactor-fe@' + environment.appVersion,
			integrations: [new Integrations.BrowserTracing()],
			tracesSampleRate: environment.env !== EnvironmentEnum.production ? 1.0 : 0.2,
			environment: `${environment.env}`,
			debug: false
		}

		Sentry.init(options)
	}
}

type SentryUser = {
	id: string
	role: Wurrly_Role_Enum
	email?: string
}

export const setSentryCurrentUser = (userInfo: SentryUser) => {
	Sentry.setUser(userInfo)
}

export const clearSentryCurrentUser = () => {
	Sentry.configureScope((scope) => scope.setUser(null))
}
