import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useSongTrackCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			padding: '24px',
			display: 'flex',
			gap: '12px',
			justifyContent: 'space-between',
			alignItems: 'center'
		},

		content: {
			display: 'flex',
			gap: '2px',
			flexDirection: 'column',
			flexGrow: 1
		},

		sides: {
			display: 'flex',
			gap: '12px',
			flexShrink: 0
		},

		icon: {
			color: theme.palette.secondary.main,
			border: `2px solid ${theme.palette.secondary.main}`,
			borderRadius: '4px',
			width: '46px',
			height: '46px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	})
)

export default useSongTrackCardStyles
