import { Page } from 'src/@types'
import { StudioRecord } from 'src/scenes/Teacher/scenes/14-TeacherStudio/scenes/StudioRecord'
import { StudioReview } from 'src/scenes/Teacher/scenes/14-TeacherStudio/scenes/StudioReview'
import { Frame } from 'src/scenes/Teacher/scenes/15-Frame'
import { UpdateSong } from 'src/scenes/Teacher/scenes/17-Songs/scenes/UpdateSong/UpdateSong'
import { UploadSong } from 'src/scenes/Teacher/scenes/17-Songs/scenes/UploadSong/UploadSong'
import { UploadSongTrack } from 'src/scenes/Teacher/scenes/17-Songs/scenes/UploadSongTrack/UploadSongTrack'
import { MySubscriptions } from 'src/scenes/Teacher/scenes/18-Subscriptions/Subscriptions'
import { AllClassProducts } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/AllClassProducts'
import { ClassAssignments } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/ClassAssignments'
import { ClassAssignmentGrade } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/scenes/ClassAssignmentGrade/ClassAssignmentGrade.container'
import { ClassLessons } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/ClassLessons'
import { ClassStudentWurrliesDetails } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassStudentWurrlies/ClassStudentWurrliesDetails'
import { ClassStudentWurrlies } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassWurrlies/scenes/ClassStudentWurrlies'
import { AddAssignment } from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/AddAssignment'
import { CatalogItemLessons } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/CatalogItemLessons/CatalogItemLessons'
import { LessonsAll } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonsAll.tsx/LessonsAll'
import { PartnerPage } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Partners/PartnerPage'
import { SearchLessons } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/SearchLessons/SearchLessons'
import { AllVideos } from 'src/scenes/Teacher/scenes/8-Videos/scenes/AllVideos/AllVideos'
import { CatalogItemVideos } from 'src/scenes/Teacher/scenes/8-Videos/scenes/CatalogItemVideos/CatalogItemVideos'
import { SearchVideos } from 'src/scenes/Teacher/scenes/8-Videos/scenes/SearchVideos/SearchVideos'
import { BillingDetails } from 'src/scenes/Teacher/scenes/BillingDetails/BillingDetails'
import { AllChannels } from 'src/scenes/Teacher/scenes/channels/Channels'
import { AllPartners } from 'src/scenes/Teacher/scenes/partners/Partners'

import Home from '../scenes/Teacher/scenes/1-Home'
import { Resources } from '../scenes/Teacher/scenes/10-Resources'
import { MyFiles } from '../scenes/Teacher/scenes/11-MyFiles/MyFiles'
import { Notifications } from '../scenes/Teacher/scenes/12-Notifications'
import { EditInformation } from '../scenes/Teacher/scenes/13-Profile/EditInformation'
import { Profile } from '../scenes/Teacher/scenes/13-Profile/Profile'
import { StudioSetup } from '../scenes/Teacher/scenes/14-TeacherStudio/scenes/StudioSetup'
import { Classes } from '../scenes/Teacher/scenes/2-Classes/Classes'
import { ClassAnalytics } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassAnalytics/ClassAnalytics'
import { ClassAssignmentDetail } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/scenes/ClassAssignmentDetail/ClassAssignmentDetail'
import { ClassCreation } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassCreation/ClassCreation'
import { ClassDetails } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/ClassDetails'
import { SavedLessonsViewAll } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/components/SavedLessonsViewAll'
import { ClassModuleDetail } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/scenes/ClassModuleDetail'
import { ClassModules } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/scenes/ClassModules'
import { ClassSequenceItemDetail } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/scenes/ClassSequenceItemDetail'
import { ClassSequenceItems } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/scenes/ClassSequenceItems'
import { ClassSettings } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassSettings/ClassSettings'
import { ClassSongs } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassSongs/ClassSongs'
import { ClassStudents } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassStudents/ClassStudents'
import { ClassVideos } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassVideos/ClassVideos'
import { ClassVideosPlayListDetails } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassVideos/scenes/ClassVideosPlayListDetails'
import { ClassVideosViewAllPlatlists } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassVideos/scenes/ClassVideosViewAllPlatlists'
import { ClassWurrlies } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassWurrlies/ClassWurrlies'
import { ClassWurrliesDetails } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassWurrlies/scenes/ClassWurrliesDetails'
import { StudentAnalytics } from '../scenes/Teacher/scenes/3-Students/scenes/StudentDetail/scenes/StudentAnalytics/StudentAnalytics'
import { StudentDetail } from '../scenes/Teacher/scenes/3-Students/scenes/StudentDetail/StudentDetail'
import { StudentWurrliesDetails } from '../scenes/Teacher/scenes/3-Students/scenes/StudentWurrlies/Scenes/StudentWurrliesDetails'
import { StudentWurrlies } from '../scenes/Teacher/scenes/3-Students/scenes/StudentWurrlies/StudentWurrlies'
import { Students } from '../scenes/Teacher/scenes/3-Students/Students'
import { Schedule } from '../scenes/Teacher/scenes/4-Schedule'
import { Assignments } from '../scenes/Teacher/scenes/5-Assignments'
import { AddAssignmentFromLesson } from '../scenes/Teacher/scenes/5-Assignments/scenes/AddAssignmentFromLesson/AddAssignmentFromLesson'
import { Lessons } from '../scenes/Teacher/scenes/6-Lessons/Lessons'
import { CatalogLessons } from '../scenes/Teacher/scenes/6-Lessons/scenes/CatalogLessons/CatalogLessons'
import { LessonEdit } from '../scenes/Teacher/scenes/6-Lessons/scenes/LessonEdit/LessonEdit'
import { LessonPage } from '../scenes/Teacher/scenes/6-Lessons/scenes/LessonPage/LessonPage'
import { LessonPDF } from '../scenes/Teacher/scenes/6-Lessons/scenes/LessonPage/PrintLesson'
import { LessonUpload } from '../scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload'
import { ModulesPage } from '../scenes/Teacher/scenes/6-Lessons/scenes/Modules/ModulesPage'
import { ModuleDetails } from '../scenes/Teacher/scenes/6-Lessons/scenes/Modules/scenes/ModuleDetails'
import { SequenceItemDetail } from '../scenes/Teacher/scenes/6-Lessons/scenes/Sequences/scenes/SequenceItemDetail'
import { Sequences } from '../scenes/Teacher/scenes/6-Lessons/scenes/Sequences/Sequences'
import { MusicCatalog } from '../scenes/Teacher/scenes/7-MusicCatalog/MusicCatalog'
import { CatalogSongs } from '../scenes/Teacher/scenes/7-MusicCatalog/scenes/CatalogSongs/CatalogSongs'
import { CatalogVideos } from '../scenes/Teacher/scenes/8-Videos/scenes/CatalogVideos/CatalogVideos'
import { EditVideo } from '../scenes/Teacher/scenes/8-Videos/scenes/EditVideo/EditVideo'
import { PlaylistPage } from '../scenes/Teacher/scenes/8-Videos/scenes/Playlist/PlaylistPage'
import { PlaylistDetails } from '../scenes/Teacher/scenes/8-Videos/scenes/PlaylistDetails'
import { VideoUpload } from '../scenes/Teacher/scenes/8-Videos/scenes/VideoUpload/VideoUpload'
import { Videos } from '../scenes/Teacher/scenes/8-Videos/Videos'
import { MyFavorites } from '../scenes/Teacher/scenes/9-MyFavorites'
import { ChannelPage } from '../scenes/Teacher/scenes/channels/ChannelPage'

export enum SlugEnum {
	Login = '/login',
	Modules = '/modules',
	Featured = '/featured',
	Recommended = '/featured',
	Chords = '/chords',
	Playlists = '/playlists',
	Student = '/student'
}

export const Pages: Record<string, Page> = {
	Home: {
		name: 'Home',
		path: '/teacher',
		component: Home,
		params: [],
		noPadding: true
	},
	/* start subscriptions */
	MySubscriptions: {
		parentName: 'Profile',
		name: 'My Subscriptions',
		path: '/teacher/profile/my-subscriptions',
		component: MySubscriptions,
		params: []
	},
	Profile: {
		name: 'Profile',
		path: '/teacher/profile',
		component: Profile,
		params: [],
		noPadding: true
	},
	ProfileEdit: {
		parentName: 'Profile',
		name: 'Edit Profile',
		path: '/teacher/profile/edit',
		component: EditInformation,
		params: [],
		noPadding: true
	},
	BillingDetails: {
		parentName: 'Profile',
		name: 'Billing Details',
		path: '/teacher/profile/Billing Details',
		component: BillingDetails,
		params: []
	},
	/* begin classes */
	Classes: {
		name: 'Classes',
		path: '/teacher/classes',
		component: Classes,
		params: []
	},
	ClassCreation: {
		parentName: 'Classes',
		name: 'Create your Class',
		path: '/teacher/classes/create',
		component: ClassCreation,
		params: []
	},
	ClassSettings: {
		parentName: 'ClassDetail',
		name: 'Class Settings',
		path: '/teacher/classes/:classId/settings',
		component: ClassSettings,
		params: ['classId']
	},
	ClassDetail: {
		parentName: 'Classes',
		name: 'Class Details',
		path: '/teacher/classes/:classId/details',
		component: ClassDetails,
		params: ['classId'],
		noPadding: true
	},
	ClassWurrlies: {
		parentName: 'ClassDetail',
		name: 'Class Wurrlies',
		path: '/teacher/classes/:classId/wurrlies',
		component: ClassWurrlies,
		params: ['classId']
	},
	WurrliesDetails: {
		parentName: 'ClassWurrlies',
		name: 'Class Wurrlies Details',
		path: '/teacher/classes/:classId/wurrlies/:wurrlyId',
		component: ClassWurrliesDetails,
		params: ['classId', 'wurrlyId']
	},
	ClassStudent: {
		parentName: 'ClassDetail',
		name: 'Students',
		path: '/teacher/classes/:classId/students',
		component: ClassStudents,
		params: ['classId']
	},
	ClassStudentDetail: {
		parentName: 'ClassStudent',
		name: 'Class Student Detail',
		path: '/teacher/classes/:classId/students/:studentId/details',
		component: StudentDetail,
		params: ['classId', 'studentId']
	},
	ClassStudentWurrlies: {
		parentName: 'ClassStudentDetail',
		name: 'Class Wurrlies',
		path: '/teacher/classes/:classId/students/:studentId/wurrlies',
		component: ClassStudentWurrlies,
		params: ['classId', 'studentId']
	},
	ClassStudentWurrliesDetails: {
		parentName: 'ClassStudentWurrlies',
		name: 'Class Wurrlies Details',
		path: '/teacher/classes/:classId/students/:studentId/wurrlies/:wurrlyId',
		component: ClassStudentWurrliesDetails,
		params: ['classId', 'studentId', 'wurrlyId']
	},
	AllClassProducts: {
		name: 'AllClassLessons',
		parentName: 'ClassLessons',
		path: '/teacher/classes/:classId/:type/allClassProducts',
		component: AllClassProducts,
		params: ['classId', 'type']
	},
	ClassLessons: {
		name: 'ClassLessons',
		parentName: 'ClassDetail',
		path: '/teacher/classes/:classId/lessons',
		component: ClassLessons,
		params: ['classId']
	},
	ClassLessonDetail: {
		parentName: 'ClassLesson',
		name: 'Class Lesson Detail',
		path: '/teacher/classes/:classId/lessons/:lessonPlanId/details',
		component: LessonPage,
		params: ['classId', 'lessonPlanId']
	},
	ClassModules: {
		parentName: 'ClassLesson',
		name: 'Class Modules',
		path: '/teacher/classes/:classId/lessons/modules',
		component: ClassModules,
		params: ['classId']
	},
	ClassViewAllLessons: {
		parentName: 'ClassLesson',
		name: 'Class Lessons View All',
		path: '/teacher/classes/:classId/lessons/lessons',
		component: SavedLessonsViewAll,
		params: ['classId']
	},
	ClassModuleDetail: {
		parentName: 'ClassModules',
		name: 'Class Module Details',
		path: '/teacher/classes/:classId/lessons/modules/:moduleId',
		component: ClassModuleDetail,
		params: ['classId', 'moduleId']
	},
	ClassSequenceItems: {
		parentName: 'ClassLesson',
		name: 'Class Sequences',
		path: '/teacher/classes/:classId/lessons/sequenceItems',
		component: ClassSequenceItems,
		params: ['classId']
	},
	ClassSequenceItemDetail: {
		parentName: 'ClassSequenceItems',
		name: 'Class Sequence Details',
		path: '/teacher/classes/:classId/lessons/sequenceItems/:sequenceItemId',
		component: ClassSequenceItemDetail,
		params: ['classId', 'sequenceItemId']
	},
	ClassAnalytics: {
		parentName: 'ClassDetail',
		name: 'Class Analytics',
		path: '/teacher/classes/:classId/analytics',
		component: ClassAnalytics,
		params: ['classId'],
		noPadding: true
	},
	ClassSongs: {
		parentName: 'ClassDetail',
		name: 'Class Songs',
		path: '/teacher/classes/:classId/songs',
		component: ClassSongs,
		params: ['classId']
	},
	ClassVideos: {
		parentName: 'ClassDetail',
		name: 'Class Videos',
		path: '/teacher/classes/:classId/videos',
		component: ClassVideos,
		params: ['classId']
	},
	ClassVideosPlaylistDetails: {
		parentName: 'ClassVideosViewAllPlaylist',
		name: 'Class Playlist Details',
		path: '/teacher/classes/:classId/videos/playlists/:playlistId',
		component: ClassVideosPlayListDetails,
		params: ['classId', 'playlistId']
	},
	ClassVideosViewAllPlaylist: {
		parentName: 'ClassVideos',
		name: 'Class Playlist',
		path: '/teacher/classes/:classId/videos/playlists',
		component: ClassVideosViewAllPlatlists,
		params: ['classId']
	},
	ClassAssignments: {
		parentName: 'ClassDetail',
		name: 'Assignments',
		path: '/teacher/classes/:classId/assignments',
		component: ClassAssignments,
		params: ['classId']
	},
	ClassAssignmentDetail: {
		parentName: 'ClassAssignments',
		name: 'Assignment Detail',
		path: '/teacher/classes/:classId/assignments/:classAssignmentId/details',
		component: ClassAssignmentDetail,
		params: ['classId', 'classAssignmentId']
	},
	ClassAssignmentGrade: {
		parentName: 'ClassAssignmentDetail',
		name: 'Grade',
		path: '/teacher/classes/:classId/assignments/:classAssignmentId/grade',
		component: ClassAssignmentGrade,
		params: ['classId', 'classAssignmentId']
	},

	/* end classes */

	/* begin students */
	Students: {
		name: 'Students',
		path: '/teacher/students',
		component: Students,
		params: []
	},
	StudentDetail: {
		parentName: 'Students',
		name: 'Student Detail',
		path: '/teacher/students/:studentId/details',
		component: StudentDetail,
		params: ['studentId']
	},
	StudentWurrlies: {
		parentName: 'StudentDetail',
		name: 'Student Wurrlies',
		path: '/teacher/students/:studentId/wurrlies',
		component: StudentWurrlies,
		params: ['studentId']
	},
	StudentWurrliesDetails: {
		parentName: 'StudentDetail',
		name: 'Class Wurrlies Details',
		path: '/teacher/students/:studentId/wurrlies/:wurrlyId',
		component: StudentWurrliesDetails,
		params: ['studentId', 'wurrlyId']
	},
	StudentAnalytics: {
		parentName: 'StudentDetail',
		name: 'Student Analytics',
		path: '/teacher/students/:studentId/analytics',
		component: StudentAnalytics,
		params: ['studentId']
	},
	/* end students */

	/* begin schedule */
	Schedule: {
		name: 'Schedule',
		path: '/teacher/schedule',
		component: Schedule,
		params: []
	},
	/* end schedule */

	/* begin assignments */
	Assignments: {
		name: 'Assignments',
		path: '/teacher/assignments',
		component: Assignments,
		params: []
	},
	AddAssignment: {
		parentName: 'Assignments',
		name: 'Add Assignment',
		path: '/teacher/assignments/create',
		component: AddAssignment,
		params: []
	},
	AddAssignmentFromLesson: {
		parentName: 'Assignments',
		name: 'Add Assignment from Lesson Plan',
		path: '/teacher/assignments/createFromLesson/:lessonPlanId',
		component: AddAssignmentFromLesson,
		params: ['lessonPlanId']
	},
	/* end assignments */

	/* begin lessons */
	Lessons: {
		name: 'Lessons',
		path: '/teacher/lessons',
		component: Lessons,
		params: []
	},
	LessonUpload: {
		parentName: 'Lessons',
		name: 'Upload Your Own',
		path: '/teacher/lessons/upload',
		component: LessonUpload,
		params: []
	},
	LessonDetail: {
		parentName: 'ModuleDetails',
		name: 'Lesson Details',
		path: '/teacher/lessons/modules/:moduleId/:lessonPlanId/details',
		component: LessonPage,
		params: ['lessonPlanId', 'moduleId']
	},
	LessonDetailNoModule: {
		parentName: 'Lessons',
		name: 'Lesson Details',
		path: '/teacher/lessons/:lessonPlanId/details',
		component: LessonPage,
		params: ['lessonPlanId']
	},
	LessonEdit: {
		parentName: 'Lessons',
		name: 'Edit Lesson',
		path: '/teacher/lessons/:lessonPlanId/edit',
		component: LessonEdit,
		params: ['lessonPlanId']
	},
	LessonDetailPDF: {
		parentName: 'Lessons',
		name: 'Lessons Detail PDF',
		path: '/teacher/lessons/:lessontitle/preview',
		component: LessonPDF,
		params: ['lessontitle']
	},
	Frame: {
		name: 'Frame',
		path: '/teacher/frame',
		component: Frame,
		noDrawer: true,
		noPadding: true,
		noTemplate: true,
		params: ['url']
	},
	Modules: {
		parentName: 'Lessons',
		name: 'All Modules',
		path: '/teacher/lessons/modules',
		component: ModulesPage,
		params: []
	},
	ModulesSubs: {
		parentName: 'MySubscriptions',
		name: 'All Modules',
		path: '/teacher/profile/modules',
		component: ModulesPage,
		params: []
	},
	ModuleDetails: {
		parentName: 'Modules',
		name: 'Module Details',
		path: '/teacher/lessons/modules/:moduleId',
		component: ModuleDetails,
		params: ['moduleId']
	},
	PartnerModuleDetails: {
		parentName: 'PartnerPage',
		name: 'Module Details',
		path: '/teacher/lessons/partner/:partnerId/modules/:moduleId',
		component: ModuleDetails,
		params: ['moduleId', 'partnerId']
	},
	PartnerModuleLessonDetails: {
		parentName: 'PartnerModuleDetails',
		name: 'Module Details',
		path: '/teacher/lessons/partner/:partnerId/modules/:moduleId/:lessonPlanId/details',
		component: LessonPage,
		params: ['moduleId', 'partnerId', 'lessonPlanId']
	},
	PartnerPage: {
		parentName: 'AllPartners',
		name: 'Partners',
		path: '/teacher/lessons/partner/:partnerId',
		component: PartnerPage,
		params: ['partnerId']
	},
	Partners: {
		parentName: 'Lessons',
		name: 'Partners',
		path: '/teacher/lessons/partners',
		component: PartnerPage,
		params: []
	},
	AllPartners: {
		name: 'All Partners',
		parentName: 'Lessons',
		params: [],
		path: '/teacher/lessons/allpartners',
		component: AllPartners
	},
	AllPartnersSubs: {
		name: 'All Partners',
		parentName: 'MySubscriptions',
		params: [],
		path: '/teacher/profile/allpartners',
		component: AllPartners
	},
	Channels: {
		name: 'All Channels',
		parentName: 'Videos',
		params: [],
		path: '/teacher/videos/channels',
		component: AllChannels
	},
	ChannelsSubs: {
		name: 'All Channels',
		parentName: 'MySubscriptions',
		params: [],
		path: '/teacher/profile/channels',
		component: AllChannels
	},
	ChannelPage: {
		name: 'Channel Page',
		parentName: 'Videos',
		params: ['channelId'],
		path: '/teacher/videos/channels/:channelId',
		component: ChannelPage
	},
	AllLessons: {
		parentName: 'Lessons',
		name: 'All Lessons',
		path: '/teacher/lessons/all',
		component: CatalogLessons,
		params: []
	},
	SearchLessons: {
		parentName: 'Lessons',
		name: 'Search',
		path: '/teacher/lessons/search',
		component: SearchLessons,
		params: []
	},
	CatalogLessons: {
		parentName: 'Lessons',
		name: 'Catalog Lessons Chords',
		path: '/teacher/lessons/catalogs/:catalogId/list',
		component: CatalogLessons,
		params: ['catalogId']
	},
	CatalogItemLessons: {
		parentName: 'CatalogLessons',
		name: 'Catalog Lessons',
		path: '/teacher/lessons/catalogs/:catalogId/items/:catalogItemId',
		component: CatalogItemLessons,
		params: ['catalogId', 'catalogItemId']
	},
	LessonsAll: {
		parentName: 'Lessons',
		name: 'All Lessons',
		path: '/teacher/lessons/all',
		component: LessonsAll,
		params: []
	},
	SequenceDetail: {
		parentName: 'CatalogItemLessons',
		name: 'Sequence Detail',
		path: '/teacher/lessons/catalogs/:catalogId/items/:catalogItemId/sequences/:sequenceTopicId',
		component: Sequences,
		params: ['catalogId', 'catalogItemId', 'sequenceTopicId']
	},
	SequenceItemDetail: {
		parentName: 'SequenceDetail',
		name: 'Sequence',
		path: '/teacher/lessons/catalogs/:catalogId/items/:catalogItemId/sequences/:sequenceTopicId/sequenceItems/:sequenceItemId',
		component: SequenceItemDetail,
		params: ['catalogId', 'catalogItemId', 'sequenceTopicId', 'sequenceItemId']
	},

	/* end lessons */

	/* begin music catalog */
	MusicCatalog: {
		name: 'Music Catalog',
		path: '/teacher/music-catalog',
		component: MusicCatalog,
		params: []
	},
	BrowseByChords: {
		parentName: 'MusicCatalog',
		name: 'Browse By Chords',
		path: '/teacher/music-catalog/chords',
		component: CatalogSongs,
		params: []
	},
	SearchSongs: {
		parentName: 'MusicCatalog',
		name: 'Search',
		path: '/teacher/music-catalog/search',
		component: CatalogSongs,
		params: []
	},
	CatalogSongs: {
		parentName: 'MusicCatalog',
		name: 'Catalog Song Chords',
		path: '/teacher/music-catalog/catalogs/:catalogId/songs',
		component: CatalogSongs,
		params: ['catalogId']
	},
	CatalogItemSongs: {
		parentName: 'CatalogSongs',
		name: 'Catalog Songs',
		path: '/teacher/music-catalog/catalogs/:catalogId/items/:catalogItemId',
		component: CatalogSongs,
		params: ['catalogId', 'catalogItemId']
	},
	RecommendedSongs: {
		parentName: 'MusicCatalog',
		name: 'Songs',
		path: '/teacher/music-catalog/all',
		component: CatalogSongs,
		params: []
	},
	// NoResults: {
	// 	parentName: 'MusicCatalog',
	// 	name: 'Song Search Results',
	// 	path: '/teacher/music-catalog/search',
	// 	component: NoResults,
	// 	params: []
	// },
	/* end music catalog */

	/* begin videos */
	Videos: {
		name: 'Videos',
		path: '/teacher/videos',
		component: Videos,
		params: []
	},
	SearchVideos: {
		parentName: 'Videos',
		name: 'Search',
		path: '/teacher/videos/search',
		component: SearchVideos,
		params: []
	},
	VideoUpload: {
		parentName: 'Videos',
		name: 'Upload Your Own',
		path: '/teacher/videos/upload',
		component: VideoUpload,
		params: []
	},
	Playlists: {
		parentName: 'Videos',
		name: 'All Playlists',
		path: '/teacher/videos/playlists',
		component: PlaylistPage,
		params: []
	},
	PlaylistsSubs: {
		parentName: 'MySubscriptions',
		name: 'All Playlists',
		path: '/teacher/profile/playlists',
		component: PlaylistPage,
		params: []
	},
	PlaylistDetails: {
		parentName: 'Videos',
		name: 'Playlist',
		path: '/teacher/videos/playlist/:playlistId',
		component: PlaylistDetails,
		params: ['playlistId']
	},
	FeaturedVideos: {
		parentName: 'Videos',
		name: 'Featured',
		path: '/teacher/videos/all',
		component: AllVideos,
		params: []
	},
	CatalogVideos: {
		parentName: 'Videos',
		name: 'Catalog Song Chords',
		path: '/teacher/videos/catalogs/:catalogId/videos',
		component: CatalogVideos,
		params: ['catalogId']
	},
	CatalogItemVideos: {
		parentName: 'CatalogVideos',
		name: 'Catalog Videos',
		path: '/teacher/videos/catalogs/:catalogId/items/:catalogItemId',
		component: CatalogItemVideos,
		params: ['catalogId', 'catalogItemId']
	},
	EditVideo: {
		parentName: 'Videos',
		name: 'Edit Your Video',
		path: '/teacher/my-files/editVideo/:tipId',
		component: EditVideo,
		params: ['tipId']
	},
	// CatalogItemVideos: {
	// 	parentName: 'Videos',
	// 	name: 'Catalog',
	// 	path: '/teacher/videos/:catalog/:selected',
	// 	component: CatalogVideos,
	// 	params: ['catalog', 'selected']
	// },
	/* end videos */

	SongUpload: {
		parentName: 'MyFiles',
		name: 'Upload Your Own',
		path: '/teacher/songs/upload',
		component: UploadSong,
		params: []
	},
	SongUploadTrack: {
		name: 'Upload new Track',
		path: '/teacher/songs/upload/:songId/track',
		parentName: 'MyFiles',
		component: UploadSongTrack,
		params: ['songId']
	},
	SongEdit: {
		name: 'Edit Song',
		path: '/teacher/songs/:songId/edit',
		parentName: 'MyFiles',
		component: UpdateSong,
		params: ['songId']
	},

	/* begin my favorites */
	MyFavorites: {
		name: 'My Favorites',
		path: '/teacher/my-favorites',
		component: MyFavorites,
		params: []
	},
	/* end my favorites */

	/* begin resources */
	Resources: {
		name: 'Resources',
		path: '/teacher/resources',
		component: Resources,
		params: []
	},
	/* end resources */

	/* begin my files */
	MyFiles: {
		name: 'My Files',
		path: '/teacher/my-files',
		component: MyFiles,
		params: []
	},
	/* end my files */

	/* begin notifications */
	Notifications: {
		name: 'Notifications',
		path: '/teacher/notifications',
		component: Notifications,
		params: []
	},
	/* end notifications */

	/* begin studio */
	TeacherStudio: {
		name: 'Teacher Studio',
		path: '/teacher/studio/:songId',
		component: StudioSetup,
		params: ['songId'],
		noDrawer: true
	},
	TeacherRecord: {
		name: 'Teacher Video Recorder',
		path: '/teacher/studio/:songId/recorder',
		component: StudioRecord,
		params: ['songId'],
		noDrawer: true
	},
	TeacherStudioReview: {
		name: 'Teacher Video Review',
		path: '/teacher/studio/:songId/review',
		component: StudioReview,
		params: ['songId'],
		noDrawer: true
	}
	/* end studio */
} as const

// Object.keys(Pages).forEach((key) => {
// 	const page = Pages[key]
// 	const breadcrumbs = page.path.split('/')
// 	const params = {}
// 	breadcrumbs
// 		.filter((b) => b.includes(':'))
// 		.forEach((b) => {
// 			const param = b.replace(':', '')
// 			params[param] = param
// 		})
// 	page.params = params
// })
