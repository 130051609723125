import { useMemo } from 'react'

import { CarouselOfAny } from 'src/components'
import { useGetColorFiltersQuery } from 'src/graphql/autogenerate/hooks'
import { Filter_Color } from 'src/graphql/autogenerate/schemas'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { buildImagePath } from 'src/utils'

import { EffectThumbnail } from '../EffectThumbnail/EffectThumbnail'

export type SelectedColorFilter = Pick<
	Filter_Color,
	'name' | 'display_name' | 'thumbnail_path' | 'filter_color_id'
>

export const ColorFilter = () => {
	const { setSelectedColorFilter, selectedColorFilter } = useVideoSubmitContext()
	const { data } = useGetColorFiltersQuery()
	const effects = data?.filter_color || []

	const onFilterSelect = (colorFilter: SelectedColorFilter) => {
		setSelectedColorFilter((prev) => {
			if (prev?.name === colorFilter.name) {
				// if the same filter is selected, unselect it
				return undefined
			}

			return colorFilter
		})
	}

	const carousel = useMemo(
		() => (
			<CarouselOfAny
				slidesPerPage={{ sm: 3, md: 4, xl: 5 }}
				spacing={1}
				centerSlide
				arrowType="secondary"
				data={[
					...effects.map(({ filter_color_id, thumbnail_path, name, display_name }) => ({
						element: (
							<EffectThumbnail
								key={filter_color_id}
								name={display_name ?? ''}
								imageUrl={buildImagePath(thumbnail_path ?? '')}
								selectable
								selected={selectedColorFilter?.filter_color_id === filter_color_id}
								onClick={() =>
									onFilterSelect({ name, display_name, thumbnail_path, filter_color_id })
								}
							/>
						),
						linkTo: {}
					}))
				]}
				renderItem={(item) => (item.element ? item.element : <div></div>)}
			/>
		),
		[effects?.length]
	)

	return carousel
}
