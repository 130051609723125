import { ChangeEvent } from 'react'

import { Box, Checkbox, Typography } from '@material-ui/core'
import { BaseLink, InputHeader, TextInput } from 'src/components'
import { PasswordInput } from 'src/scenes/Login/components/PasswordInput'

import useStyles from './ActivateAccountInputs.styles'

type ActivateAccountInputsProps = {
	email: string
	password: string
	isPasswordValid: boolean
	passwordConfirm: string
	passwordsMatch: boolean
	acceptedTermsOfService: boolean
	acceptedPrivacyPolicy: boolean
	handleFormChange: (event: ChangeEvent<unknown>) => void
	setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => Promise<unknown>
}

export const ActivateAccountInputs = ({
	email,
	password,
	isPasswordValid,
	passwordConfirm,
	passwordsMatch,
	acceptedTermsOfService,
	acceptedPrivacyPolicy,
	handleFormChange,
	setFieldValue
}: ActivateAccountInputsProps) => {
	const styles = useStyles()

	const handlePassword = (newPassword: string) => {
		setFieldValue('password', newPassword)
	}
	const handleConfirmPassword = (confirmPassword: string) => {
		setFieldValue('passwordConfirm', confirmPassword)
	}

	return (
		<>
			<TextInput headerLabel="Email" value={email} id="email" disabled />

			<InputHeader name="Create Password" customMargin="15px" />
			<PasswordInput
				id="password"
				password={password}
				setPassword={handlePassword}
				className={styles.passwordBox}
			/>
			{!isPasswordValid && !!password.length && (
				<Typography className={styles.errorText}>Password don't fulfill requirements</Typography>
			)}

			<InputHeader name="Confirm Password" customMargin="20px" />
			<PasswordInput
				id="passwordConfirm"
				password={passwordConfirm}
				setPassword={handleConfirmPassword}
				className={styles.passwordBox}
			/>
			{!passwordsMatch && !!password.length && (
				<Typography className={styles.errorText}>Passwords don't match</Typography>
			)}

			<Box mt={1} display="flex" flexWrap="nowrap" alignItems="center">
				<Checkbox
					className={styles.checkbox}
					onChange={handleFormChange}
					id="acceptedTermsOfService"
					checked={acceptedTermsOfService}
				/>
				<Typography variant="caption" className={styles.checkboxText}>
					I Have read and accept the WURRLY{' '}
					<BaseLink href="https://www.wurrlyedu.com/terms">
						<Typography variant="caption">Terms of Service.</Typography>
					</BaseLink>
				</Typography>{' '}
			</Box>
			<Box>
				<Checkbox
					className={styles.checkbox}
					id="acceptedPrivacyPolicy"
					onChange={handleFormChange}
					checked={acceptedPrivacyPolicy}
				/>
				<Typography variant="caption" className={styles.checkboxText}>
					I Have read, underatand and accept the WURRLY{' '}
					<BaseLink href="https://www.wurrlyedu.com/privacy">
						<Typography variant="caption">Privacy Policy.</Typography>
					</BaseLink>
				</Typography>{' '}
			</Box>
		</>
	)
}
