import { useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import { useDropzone } from 'react-dropzone'

import { FileIcon } from '../assets/icons'
import { COLORS } from '../styles/colors'

const borderStyles = {
	border: '2px dashed #00000033',
	borderStyle: 'dashed',
	borderWidth: '2px',
	borderRadius: '2px'
}
const baseStyle = {
	backgroundColor: COLORS.secondary.light,
	...borderStyles
}

const rejectStyle = {
	backgroundColor: COLORS.error.light,
	...borderStyles
}

/**
 * Params:
 *
 * @param {string} accept the mime type of the file, default to 'text/csv'
 * @param {function} onAccept fuction to execute on valid files
 * @param {string} fileTypeName the type name to be displayed
 */
type DragDropType = {
	accept?: string
	onAccept: (reader: FileReader) => void
	onReject?: () => void
	fileTypeName: string
}
export const DragDrop = ({ accept = 'text/csv', onAccept, onReject, fileTypeName }: DragDropType) => {
	let style = { ...baseStyle }

	useEffect(() => {
		return () => {
			clean()
		}
	}, [])

	const onDropAccepted = async (acceptedFiles: Blob[]) => {
		const file = [...acceptedFiles].pop()
		if (file) {
			const reader = new FileReader()
			reader.readAsText(file)
			reader.onload = async () => {
				onAccept(reader)
			}
		}
	}

	const onDropRejected = async () => {
		if (onReject) onReject()
	}

	const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
		onDropAccepted,
		onDropRejected,
		accept,
		maxFiles: 1
	})

	const clean = () => {
		acceptedFiles.pop()
	}

	const files = acceptedFiles.map((file: File, index) => (
		<Box key={file.name + '-' + index} display="flex" alignItems="center" style={{ padding: 20 }}>
			<img src={FileIcon} style={{ marginRight: 14 }} />
			<Typography>
				{file.name} - {file.size} bytes
			</Typography>
		</Box>
	))

	const fileRejectionItems = fileRejections.map(({ file, errors }, index) => {
		style = { ...rejectStyle }

		return (
			<Box key={file.name + '-' + index} display="flex" alignItems="center" style={{ padding: 20 }}>
				<CancelIcon color="error" style={{ marginRight: 14 }} />
				{fileRejections.length === 1 ? (
					<Typography>
						{file.name} {errors.map((e) => e.message)}
					</Typography>
				) : (
					<Typography>{errors.map((e) => e.message)}</Typography>
				)}
			</Box>
		)
	})

	return (
		<div
			{...getRootProps({
				style
			})}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Drop the files here ...</p>
			) : files.length > 0 ? (
				files.pop()
			) : fileRejectionItems.length > 0 ? (
				fileRejectionItems.pop()
			) : (
				<Box display="flex" style={{ padding: 20 }}>
					<Typography>Drag & Drop Your {fileTypeName}</Typography>
					<div style={{ flexGrow: 1 }} />
					<AddIcon color="secondary" />
				</Box>
			)}
		</div>
	)
}
