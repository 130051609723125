import { articleSchema, limit } from 'src/scenes/Teacher/scenes/6-Lessons/utils/constants'
import * as yup from 'yup'

export const useStepTwoValidation = () => {
	const stepTwoSchema = yup.object({
		stepTwoArticles: yup.array().of(articleSchema)
	})

	return { stepTwoSchema, limit }
}
