import { useEffect, useRef } from 'react'

import { Avatar, Box, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'
import { CallMade, ChatBubble } from '@material-ui/icons'
import clsx from 'clsx'
import moment from 'moment'
import { Book, Star } from 'src/assets/icons'
import { FullBandIcon, GuitarIcon, PianoIcon } from 'src/assets/icons/CustomIcons'
import { Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath, ClassWurrlyType, getWurrlyType, TrackTypeEnum } from 'src/utils'

import { getCyKey } from '../../utils/utils'
import { BaseButton } from '../Buttons/BaseButton'
import useStyles, { LightTooltip } from './WurrlyCard.styles'

type Props = {
	type?: string
	header: {
		title: string
		subtitle?: string
		avatar?: string
		date: string
		privacy?: string
	}
	cardMedia: string
	content: {
		title: string
		subtitle: string
		trackType: string
		comment?: string
		grade?: string
	}
	onClick: () => void
	currentTab?: ClassWurrlyType
	highlighted?: boolean
}

export const WurrlyCard = ({ type, header, cardMedia, content, onClick, highlighted, currentTab }: Props) => {
	const styles = useStyles({ highlighted })
	const cardRef = useRef<HTMLDivElement>()

	const getIcon = (type: string) => {
		switch (type) {
			case TrackTypeEnum.Piano:
				return <PianoIcon className={styles.cardIcon} />
			case TrackTypeEnum.Band:
				return <FullBandIcon className={styles.cardIcon} />
			case TrackTypeEnum.Guitar:
				return <GuitarIcon className={styles.cardIcon} />
			default:
				return null
		}
	}

	useEffect(() => {
		if (highlighted && cardRef.current?.scrollIntoView) cardRef.current.scrollIntoView({ behavior: 'smooth' })
	}, [highlighted])

	return (
		<Box className={styles.root} {...{ ref: cardRef }}>
			<Card>
				<div className={styles.header}>
					{header.avatar && (
						<Avatar
							className={clsx(styles.avatar, styles.headerAvatar)}
							src={buildImagePath(header.avatar)}
						/>
					)}
					<div className={styles.headerContainer}>
						<Typography
							data-cy={getCyKey(WurrlyCard, 'studentNameCard')}
							className={styles.headerTitle}
							variant="h6">
							{getWurrlyType(type)}
						</Typography>
						<Typography className={styles.headerSubtitle} variant="subtitle1">
							{header.subtitle}
						</Typography>
						<Typography className={styles.headerDate} variant="caption">
							{moment(header.date).format('YYYY/MM/DD')}
						</Typography>
					</div>

					{type === Wurrly_Type_Enum.Assignment && (
						<div className={styles.headerBadge}>
							<img src={Book} />
						</div>
					)}
					{type === Wurrly_Type_Enum.Challenge && (
						<div className={styles.headerBadge}>
							<img src={Star} />
						</div>
					)}
				</div>
				<CardMedia className={styles.cardMedia} title={content.title} image={buildImagePath(cardMedia)}>
					{currentTab !== ClassWurrlyType.ClassWurrlies && type === Wurrly_Type_Enum.Assignment && (
						<>
							{!content?.grade ? (
								<>
									{content?.comment && content?.comment !== '' ? (
										<>
											<Typography className={styles.reviewedBadge}>Reviewed</Typography>
										</>
									) : (
										<>
											<Typography className={styles.ungradedBadge}>Ungraded</Typography>
										</>
									)}
								</>
							) : (
								<Typography className={styles.reviewedBadge}>Graded</Typography>
							)}
						</>
					)}
				</CardMedia>
				<CardContent className={styles.content}>
					<Avatar className={clsx(styles.avatar, styles.contentAvatar)}>
						{getIcon(content.trackType)}
					</Avatar>
					<div className={styles.contentContainer}>
						<Typography className={styles.contentTitle} variant="h6">
							<div data-cy={getCyKey(WurrlyCard, 'songTitle')} title={content.title}>
								{content.title}
							</div>
							{content.comment && (
								<LightTooltip title={content.comment} placement="bottom-end" arrow>
									<ChatBubble className={styles.contentChatIcon} />
								</LightTooltip>
							)}
						</Typography>
						<Typography className={styles.contentDescription} variant="caption">
							{content.subtitle}
						</Typography>
					</div>
				</CardContent>
				<CardActions className={styles.actions}>
					<BaseButton
						data-cy={getCyKey(WurrlyCard, 'viewButtonCard')}
						className={styles.actionsButton}
						onClick={onClick}
						fullWidth={true}
						color="primary"
						endIcon={<CallMade className={styles.actionsButtonIcon} />}>
						View
					</BaseButton>
				</CardActions>
			</Card>
		</Box>
	)
}
