import { createStyles, makeStyles } from '@material-ui/core'

export const useClassAssignmentDetailStyles = makeStyles((theme) =>
	createStyles({
		assignmentCardContainer: {
			padding: theme.spacing(3),
			paddingLeft: theme.spacing(3),
			marginBottom: theme.spacing(2)
		}
	})
)

export default useClassAssignmentDetailStyles
