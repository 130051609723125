import { Fragment, useState } from 'react'

import { Box } from '@material-ui/core'
import { IntegrationButtonCanvas } from 'src/scenes/Login/components/IntegrationButtonCanvas'
import { IntegrationButtonClassLink } from 'src/scenes/Login/components/IntegrationButtonClassLink'

import { Email, QRCode, NumericCode } from '../../../../../../../assets/icons'
import { BigBaseButton, BaseDialog, InfoDialog } from '../../../../../../../components'
import { getCyKey } from '../../../../../../../utils/utils'
import { IntegrationButtonActions, StudentSignInOptions } from '../../../../../enums'
import { DialogHeader } from '../components/DialogHeader'
import { useStudentSignInOptionsStyles } from '../styleStudentSignInOptions'
import { useStudentSignInOptionsContext } from '../useStudentSignInOptions'

const signInOptions = [
	{
		name: StudentSignInOptions.QRCode,
		description: 'QRCode',
		icon: QRCode
	},
	{
		name: StudentSignInOptions.StudentCode,
		description: 'Student Code',
		icon: NumericCode
	},
	{
		name: StudentSignInOptions.Email,
		description: 'Email',
		icon: Email
	}
]

export const SelectOptionDialog = () => {
	const styles = useStudentSignInOptionsStyles()

	const { setDialog, errorDialogOpen, setErrorDialogOpen } = useStudentSignInOptionsContext()
	const [loadingIntegration, setLoadingIntegration] = useState(false)

	return (
		<>
			<BaseDialog
				open
				className={styles.baseDialog}
				paperProps={{ className: styles.paper }}
				onClose={() => {}}
				onDiscard={() => {}}
				dividers={false}
				header={<DialogHeader title="How Would You Like To Sign In?" style={{ marginBottom: 0 }} />}
				isActionsHidden
				body={
					<Fragment>
						<Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">
							{signInOptions.map((role) => (
								<Box
									key={role.name}
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									flexDirection="column">
									<Box
										display="flex"
										alignItems="center"
										justifyContent="space-around"
										className={styles.optionsContainer}>
										<img src={role.icon} />
									</Box>
									<BigBaseButton
										data-cy={getCyKey(SelectOptionDialog, role.name)}
										disabled={loadingIntegration}
										className={styles.optionBtn}
										color="secondary"
										onClick={() => {
											setDialog(role.name)
										}}>
										<b>{role.description}</b>
									</BigBaseButton>
								</Box>
							))}
						</Box>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							className={styles.dividersContainer}>
							<Box className={`${styles.divider} left`} />
							<Box className={styles.or}>Or</Box>
							<Box className={`${styles.divider} right`} />
						</Box>
						<Box display="flex" justifyContent="center" height="47px">
							{/* <IntegrationButton
								style={{ width: '40%' }}
								customLabel="Sign In with Clever"
								integrationType={Integration_Name_Enum.Clever}
								selectedIntegrationType={Integration_Name_Enum.Clever}
								action={IntegrationButtonActions.Login}
								setLoading={setLoadingIntegration}
								role={Wurrly_Role_Enum.Student}
							/> */}
							<IntegrationButtonClassLink
								customLabel="Sign In with ClassLink"
								className={styles.classLinkButton}
								action={IntegrationButtonActions.Login}
								setLoading={setLoadingIntegration}
								disabled={loadingIntegration}
								loading={loadingIntegration}
							/>
							<IntegrationButtonCanvas
								customLabel="Sign In with Canvas"
								className={styles.classLinkButton}
								action={IntegrationButtonActions.Login}
								setLoading={setLoadingIntegration}
								disabled={loadingIntegration}
								loading={loadingIntegration}
							/>
						</Box>
					</Fragment>
				}
			/>
			<InfoDialog
				open={errorDialogOpen}
				onClose={() => {
					setErrorDialogOpen(false)
				}}
				icon="x"
				title="Wrong Answer"
				body="Only one attempt allowed."
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setErrorDialogOpen(false)
				}}
			/>
		</>
	)
}
