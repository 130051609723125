import React, { Fragment, useEffect, useState } from 'react'

import { Box, CircularProgress, Tooltip, Typography } from '@material-ui/core'
import csv from 'csvtojson/v2'
import { ThinCheckIcon } from 'src/assets/icons'

import { AddStudentsTableRow, BaseLink, BigBaseButton, DragDrop, InfoDialog, Stepper } from '..'
import {
	useAddStudentsSendEmailsMutation,
	useGetStudentQRsPdfLazyQuery,
	useValidateStudentsInClassLazyQuery
} from '../../graphql/autogenerate/hooks'
import { StudentData, StudentFromCsv } from '../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../hooks/useLogin'
import { ADD_STUDENTS_HEADER, BASE_URL, buildImagePath, generateUniqueId } from '../../utils'
import { AddStudentsEnhancedTableHeader } from '../Tables/AddStudentsEnhancedTableHeader'
import { BaseTable } from '../Tables/BaseTable'
import { BaseDialog } from './BaseDialog/BaseDialog'

export type StudentsFromCSV = {
	id: string
	isSelected: boolean
	firstName: string
	lastName: string
	email?: string
	code?: string
	qrCode?: string
	parentEmail?: string
}

type props = {
	classId: number
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsManualInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const InviteStudentsByBulkStepperDialog = ({
	classId,
	isOpen,
	setIsOpen,
	setIsManualInviteOpen
}: props) => {
	const { teacherData: teacher } = useLoginContext()
	const [isAcceptedFiles, setIsAcceptedFiles] = useState(false)
	const [isSecondStepOpen, setIsSecondStepOpen] = useState(false)
	const [isStudentsWithoutEmailOpen, setIsStudentsWithoutEmailOpen] = useState(false)
	const [isStudentInvitedDialogOpen, setIsStudentInvitedDialogOpen] = useState(false)
	const [isQRCodesDownloadedDialogOpen, setIsQRCodesDownloadedDialogOpen] = useState(false)
	const [isGoBackDataLostWarningOpen, setIsGoBackDataLostWarningOpen] = useState(false)
	const [studentsFromCSVArr, setStudentsFromCSVArr] = useState<StudentsFromCSV[]>([])
	const [studentsFromCSVPivotArr, setStudentsFromCSVPivotArr] = useState<StudentData[]>([])
	const [selectedStudents, setSelectedStudents] = useState<StudentsFromCSV[]>([])
	const [
		getStudentQRsPdfLazyQuery,
		{ loading: studentsQRsPDFLoading, data: studentsQRsPDFData, error: studentsQRsPDFError }
	] = useGetStudentQRsPdfLazyQuery({ fetchPolicy: 'no-cache' })
	const [
		validateStudentsInClassQuery,
		{
			data: validateStudentsInClassData,
			loading: validateStudentsInClassLoading,
			error: validateStudentsInClassError
		}
	] = useValidateStudentsInClassLazyQuery({ fetchPolicy: 'no-cache' })
	const [
		addStudentsSendEmailsMutation,
		{
			data: addStudentsSendEmailsData,
			loading: addStudentsSendEmailsLoading,
			error: addStudentsSendEmailsError
		}
	] = useAddStudentsSendEmailsMutation()

	useEffect(() => {
		return cleanUploadedFile()
	}, [])

	useEffect(() => {
		if (!validateStudentsInClassLoading && validateStudentsInClassData) {
			const studentsNewInClass = [] as StudentsFromCSV[]
			const studentsAlreadyInClass = [] as StudentsFromCSV[]
			if (
				validateStudentsInClassData.validateStudentsInClass &&
				validateStudentsInClassData.validateStudentsInClass.studentsNewInClass
			)
				validateStudentsInClassData.validateStudentsInClass.studentsNewInClass.forEach((student) => {
					if (student)
						studentsNewInClass.push({
							id: generateUniqueId(),
							isSelected: false,
							firstName: student.first_name,
							lastName: student.last_name,
							email: student.email,
							code: student.code
						} as StudentsFromCSV)
				})
			if (
				validateStudentsInClassData.validateStudentsInClass &&
				validateStudentsInClassData.validateStudentsInClass.studentsAlreadyInClass
			)
				validateStudentsInClassData.validateStudentsInClass.studentsAlreadyInClass.forEach((student) => {
					if (student) {
						const studentPivot = studentsFromCSVPivotArr.find(
							(studentPivot) => studentPivot.email === student.email
						)
						studentsAlreadyInClass.push({
							id: generateUniqueId(),
							isSelected: false,
							firstName: studentPivot?.first_name || student.first_name,
							lastName: studentPivot?.last_name || student.last_name,
							email: student.email,
							code: student.code
						} as StudentsFromCSV)
					}
				})

			setStudentsFromCSVArr([...studentsNewInClass, ...studentsAlreadyInClass].sort())
		}
	}, [validateStudentsInClassData])

	useEffect(() => {
		if (
			studentsQRsPDFData &&
			studentsQRsPDFData.addStudentsByBulkQRs &&
			studentsQRsPDFData.addStudentsByBulkQRs.path
		) {
			const link = document.createElement('a')
			link.href = `${BASE_URL}${studentsQRsPDFData.addStudentsByBulkQRs.path}`
			link.target = '_blank'
			link.download = 'student_QRs.pdf'
			// Append to html link element page
			document.body.appendChild(link)
			// Start download
			link.click()
			// Clean up and remove the link
			link.parentNode?.removeChild(link)
			setIsQRCodesDownloadedDialogOpen(true)
			setIsSecondStepOpen(false)
		}
	}, [studentsQRsPDFData])

	useEffect(() => {
		if (studentsFromCSVArr.length > 0) {
			setStudentsFromCSVArr(
				studentsFromCSVArr.map((student) => {
					if (selectedStudents.some((st) => st.id === student.id)) {
						return { ...student, isSelected: true } as StudentsFromCSV
					} else {
						return { ...student, isSelected: false } as StudentsFromCSV
					}
				})
			)
		}
	}, [selectedStudents])

	useEffect(() => {
		if (addStudentsSendEmailsData) {
			setIsSecondStepOpen(false)
			setIsStudentInvitedDialogOpen(true)
		}
	}, [addStudentsSendEmailsData])

	const handleStudentQRsPDFDownload = () => {
		if (selectedStudents.length) {
			const students = selectedStudents.map(mapStudentData)
			getStudentQRsPdfLazyQuery({
				variables: {
					filter: {
						students,
						classId
					}
				}
			})
		}
	}

	const handleStudentSendMultipleEmails = () => {
		if (selectedStudents.length) {
			const studentsWithoutEmail = selectedStudents.filter((student) => !student.email)
			if (studentsWithoutEmail.length) {
				setIsStudentsWithoutEmailOpen(true)

				return
			}
			try {
				const students = selectedStudents.map(mapStudentData)
				addStudentsSendEmailsMutation({
					variables: {
						filter: {
							students,
							classId,
							teacherName: `${teacher.name_first} ${teacher.name_last}`
						}
					}
				})
			} catch (e) {
				console.error(e)
			}
		}
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedStudents(studentsFromCSVArr)

			return
		}
		setSelectedStudents([])
	}

	const onDelete = (student: StudentsFromCSV) => {
		const studentArr = [...studentsFromCSVArr]
		const studentArrIndex = studentArr.findIndex((st) => st.id === student.id)
		studentArr.splice(studentArrIndex, 1)
		setStudentsFromCSVArr(studentArr)

		const selectedArr = [...selectedStudents]
		const selectedArrIndex = selectedArr.findIndex((st) => st.id === student.id)
		selectedArr.splice(selectedArrIndex, 1)
		setSelectedStudents(selectedArr)
	}

	const onDropAccepted = async (reader: FileReader) => {
		const text = reader.result as string
		const jsonArray = (await csv().fromString(text)).map((row) => {
			return {
				first_name: row['Student First Name'],
				last_name: row['Student Last Name'],
				email: row['Student Email Address']
			} as StudentData
		}) as StudentData[]
		setStudentsFromCSVPivotArr(jsonArray)
		validateStudentsInClassQuery({
			variables: {
				students: {
					classId,
					students: jsonArray
				}
			}
		})
	}

	const cleanUploadedFile = () => {
		setIsAcceptedFiles(false)
		setSelectedStudents([])
		setStudentsFromCSVArr([])
		setStudentsFromCSVPivotArr([])
	}
	const mapStudentData = (student: StudentsFromCSV) => {
		return {
			firstName: student.firstName,
			lastName: student.lastName,
			code: student.code,
			email: student.email
		} as StudentFromCsv
	}

	return (
		<Box>
			<BaseDialog
				dividers={false}
				open={isOpen}
				onClose={() => {}}
				header={
					<Fragment>
						<Stepper
							stepDescription="Invite"
							currentStep={1}
							totalSteps={2}
							stepBoxProps={{ mb: 4 }}
						/>
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Add Students
						</Typography>
						<Typography
							align="center"
							variant="body2"
							style={{
								paddingTop: 12,
								paddingBottom: 12,
								paddingRight: 10,
								paddingLeft: 10,
								fontSize: 18,
								fontWeight: 'normal'
							}}>
							Please upload your CSV document in the following format:
							{<b> First Name, Last Name, Email Address (if you have it). </b>}
							For example: John, Smith, john.smith@gmail.com.
						</Typography>
					</Fragment>
				}
				body={
					<Box mt={3}>
						<DragDrop
							onAccept={(reader) => {
								onDropAccepted(reader)
								setIsAcceptedFiles(true)
							}}
							onReject={() => setIsAcceptedFiles(false)}
							fileTypeName="CSV"
						/>
						<Box mt={3} display="flex" justifyContent="center">
							<Typography>
								<b>No CSV File? </b>
								<BaseLink href={buildImagePath('resources/StudentsTemplate.csv')} download>
									<b>Download Template</b>
								</BaseLink>
							</Typography>
						</Box>
					</Box>
				}
				onDiscard={(e) => {
					e.stopPropagation()
					if (isAcceptedFiles) {
						setIsGoBackDataLostWarningOpen(true)
					} else {
						setIsOpen(false)
						setIsManualInviteOpen(true)
					}
				}}
				discardLabel="Go Back"
				onDiscardProps={{
					style: {
						width: 200
					}
				}}
				onConfirm={(e) => {
					e.stopPropagation()
					setIsOpen(false)
					setIsSecondStepOpen(true)
				}}
				confirmLabel="Continue"
				onConfirmProps={{
					style: {
						width: 200
					}
				}}
				isConfirmDisabled={
					!isAcceptedFiles ||
					!!validateStudentsInClassError ||
					!!studentsQRsPDFError ||
					!!addStudentsSendEmailsError
				}
			/>
			<BaseDialog
				dividers={false}
				maxWidth="md"
				PaperProps={{
					style: {
						width: '850px'
					}
				}}
				open={isSecondStepOpen}
				onClose={() => {
					setIsSecondStepOpen(false)
					cleanUploadedFile()
				}}
				header={
					<Box mb={3}>
						<Stepper
							stepDescription="Finalise"
							currentStep={2}
							totalSteps={2}
							stepBoxProps={{ mb: 4 }}
						/>
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Happy With These Invites?
						</Typography>
						<Typography
							align="center"
							variant="body2"
							style={{
								paddingTop: 12,
								paddingBottom: 12,
								paddingRight: 110,
								paddingLeft: 110,
								fontSize: 18,
								fontWeight: 'normal'
							}}>
							Please confirm the details below to invite these students. You can edit & upload a new
							CSV if something is incorrect.
						</Typography>
					</Box>
				}
				body={
					<Box>
						<BaseTable
							header={
								<AddStudentsEnhancedTableHeader
									onSelectAllClick={handleSelectAllClick}
									headerCells={ADD_STUDENTS_HEADER}
									rowCount={studentsFromCSVArr.length}
									selectedCount={selectedStudents.length}
								/>
							}
							rows={studentsFromCSVArr}
							renderRow={(student: StudentsFromCSV) => (
								<AddStudentsTableRow
									classId={classId}
									student={student}
									selectedStudents={selectedStudents}
									setSelectedStudents={setSelectedStudents}
									onDelete={onDelete}
								/>
							)}
							style={{
								maxHeight: '220px'
							}}
						/>
					</Box>
				}
				bodyProps={{
					style: {
						overflowY: 'hidden'
					}
				}}
				discardLabel="Done"
				onDiscard={(e) => {
					e.stopPropagation()
					setIsSecondStepOpen(false)
				}}
				onDiscardProps={{
					style: {
						width: '220px'
					}
				}}
				leftActions={
					<Tooltip
						title="Select at least one Student"
						placement="bottom"
						disableHoverListener={selectedStudents.length > 0}>
						<span>
							<BigBaseButton
								style={{ padding: '6px 50px', width: '220px' }}
								color="secondary"
								autoFocus
								onClick={(e) => {
									e.stopPropagation()
									handleStudentSendMultipleEmails()
								}}
								startIcon={
									addStudentsSendEmailsLoading ? (
										<CircularProgress color="secondary" size={20} />
									) : undefined
								}
								disabled={
									!selectedStudents.length ||
									addStudentsSendEmailsLoading ||
									studentsQRsPDFLoading
								}>
								<Typography variant="body1">Send Emails</Typography>
							</BigBaseButton>
						</span>
					</Tooltip>
				}
				rightActions={
					<Tooltip
						title="Select at least one Student"
						placement="bottom"
						disableHoverListener={selectedStudents.length > 0}>
						<span>
							<BigBaseButton
								style={{ padding: '6px 50px' }}
								color="secondary"
								autoFocus
								onClick={(e) => {
									e.stopPropagation()
									handleStudentQRsPDFDownload()
								}}
								startIcon={
									studentsQRsPDFLoading ? (
										<CircularProgress color="secondary" size={20} />
									) : undefined
								}
								disabled={
									!selectedStudents.length ||
									studentsQRsPDFLoading ||
									addStudentsSendEmailsLoading
								}>
								<Typography variant="body1">Download QR Codes</Typography>
							</BigBaseButton>
						</span>
					</Tooltip>
				}
				confirmLabel="Download QR Codes"
				footer={
					<BaseLink
						onClick={() => {
							setIsSecondStepOpen(false)
							setIsOpen(true)
						}}>
						<b>Upload New CSV File</b>
					</BaseLink>
				}
			/>
			<InfoDialog
				open={isGoBackDataLostWarningOpen}
				onClose={() => {}}
				onDiscard={() => {
					setIsGoBackDataLostWarningOpen(false)
					cleanUploadedFile()
					setIsManualInviteOpen(true)
				}}
				onConfirm={() => {
					setIsGoBackDataLostWarningOpen(false)
					setIsOpen(true)
				}}
				icon="!"
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				confirmLabel="No, Cancel"
			/>
			<InfoDialog
				open={isQRCodesDownloadedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsQRCodesDownloadedDialogOpen(false)
					setIsSecondStepOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="QR Codes Downloaded!"
				body="You have successfully downloades these Students QR."
				confirmLabel="Got it!"
			/>
			<InfoDialog
				open={isStudentInvitedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsStudentInvitedDialogOpen(false)
					setIsSecondStepOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="Students Invited!"
				body="You have successfully invited these Students."
				confirmLabel="Got it!"
			/>
			<InfoDialog
				open={isStudentsWithoutEmailOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsStudentsWithoutEmailOpen(false)
				}}
				icon="!"
				title="Students without email"
				body="You have selected students without email, please correct your selection and try again."
				confirmLabel="Correct Selection"
			/>
		</Box>
	)
}
