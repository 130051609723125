import { Accordion, AccordionSummary, Typography, AccordionDetails, Box } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ListLessons } from 'src/components'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'

import useStyles from './SkillCoveredBySavedLessons.styles'
export const StandardItem = ({
	teacherId,
	standardName,
	standards
}: {
	teacherId: number
	standardName: string
	standards: { [key: string]: { title: string; description: string; lessons: Lesson_Plan[] }[] }
}) => {
	const styles = useStyles()

	return (
		<Accordion key={standardName} className={styles.Accordion}>
			<AccordionSummary className={styles.AccordionSumary} expandIcon={<ExpandMoreIcon />}>
				<Typography className={styles.accordionTitle}>{standardName}</Typography>
			</AccordionSummary>
			<AccordionDetails className={styles.accordionDetails}>
				{standards[standardName].map((standardDetails) => (
					<Accordion key={standardDetails.title} className={styles.Accordion}>
						<AccordionSummary className={styles.AccordionSumary} expandIcon={<ExpandMoreIcon />}>
							<Typography className={styles.accordionTitle}>{standardDetails.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box width="100%">
								<ListLessons
									teacherId={teacherId}
									spacing={1.5}
									data={standardDetails.lessons}
									addAssignmentButton={false}
									addclassButton={false}
									standardButton={false}
								/>
							</Box>
						</AccordionDetails>
					</Accordion>
				))}
			</AccordionDetails>
		</Accordion>
	)
}
