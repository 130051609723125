import { Box, Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import { Datetime } from 'src/components'
import { getCyKey } from 'src/utils'

import useStyles from './AssignmentDescription.styles'

type AssignmentDescriptionProps = {
	instructions: string
	dueDateFormatted: string
}
export const AssignmentDescription = ({ instructions, dueDateFormatted }: AssignmentDescriptionProps) => {
	const styles = useStyles()

	return (
		<Box>
			<Typography data-cy={getCyKey(AssignmentDescription, 'instructions')} className={styles.intructions}>
				{parse(instructions)}
			</Typography>
			<Box data-cy={getCyKey(AssignmentDescription, 'date')} className={styles.dateHolder}>
				<Datetime color="secondary" text={dueDateFormatted} />
			</Box>
		</Box>
	)
}
