import { useEffect } from 'react'

import { Box, MenuItem, Select, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'

import { BigBaseButton, CarouselOfAny } from '../../../../../../../components'
import { useClassAnalyticsContext } from '../../../../../hooks/useClassAnalytics'
import { useStudentProductivityData } from '../hooks'
import { StackedBar } from '../StackedBar'
import useStyles from './ClassStudentProductivity.styles'

export const ClassStudentProductivity = () => {
	const styles = useStyles()
	const { classId } = useWurrlyParams<typeof Pages.ClassAnalytics.params[number]>()

	const { setClassId, setSelectedMonth, selectedMonth } = useClassAnalyticsContext()
	const { chartData: studentProductivityData } = useStudentProductivityData(classId, selectedMonth)

	const charts = [
		{
			element: <StackedBar data={studentProductivityData} title="Student Productivity" />
		}
	]

	// Set the classId to the context
	useEffect(() => {
		setClassId(classId)
	}, [classId])

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" marginBottom={2}>
				<Box>
					<Typography variant="h6">
						<b>Check Insights</b>
					</Typography>
					<Select
						className={styles.select}
						style={{ marginTop: '10px', minWidth: '240px', height: '40px' }}
						variant="outlined"
						color="secondary"
						defaultValue={selectedMonth}
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							setSelectedMonth(Number(e.target.value))
						}}>
						<MenuItem value={moment().month()}>Last Month</MenuItem>
						<MenuItem value={moment().month() + 1}>Current Month</MenuItem>
					</Select>
				</Box>
				<Tooltip title="Coming soon">
					<BigBaseButton
						style={{
							width: '220px'
						}}
						color="default"
						onClick={() => {}}>
						<b>Export</b>
					</BigBaseButton>
				</Tooltip>
			</Box>
			<Box marginBottom={2}>
				<CarouselOfAny
					slidesPerPage={1}
					spacing={3}
					data={[
						...charts.map(({ element }) => ({
							element,
							linkTo: {}
						}))
					]}
					renderItem={(item) => (item.element ? item.element : <div />)}
				/>
			</Box>
		</Box>
	)
}
