import { GUITAR_REMAPPED_CHORDS } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/constants'
import { Chord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/types'
interface GuitarChordMap {
	[key: string]: string
}
export const remapChord = (chord: Chord) => {
	if (!chord?.name) return chord
	const newChord = { name: chord.name }

	const keys = Object.keys(GUITAR_REMAPPED_CHORDS)

	for (const key of keys) {
		if (newChord.name.indexOf(key) > -1) {
			newChord.name = newChord.name.replace(key, (GUITAR_REMAPPED_CHORDS as GuitarChordMap)[key])
		}
	}

	return newChord
}
