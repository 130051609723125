import { ChangeEvent, useState } from 'react'

import { Box, Card, Radio, Typography } from '@material-ui/core'
import { Song } from 'src/graphql/autogenerate/schemas'

import { SongItem } from '../SongItem/SongItem'
import useStyles from './SelectedSongs.styles'

type SelectedSongsProps = {
	songs: Song[]
	selectedSongId: number
	setSelectedSongId: (id: number) => void
}

const SelectedSongs = ({ songs, selectedSongId, setSelectedSongId }: SelectedSongsProps) => {
	const styles = useStyles()
	const [songPlaying, setSongPlaying] = useState(0)
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setSelectedSongId(+e.target.value)

	return (
		<Box>
			<Typography className={styles.title} variant="h6">
				Choose a Song
			</Typography>
			<Box className={styles.songsContainer}>
				{songs.map((song) => (
					<Card key={song.song_id} className={styles.songCard}>
						<Radio
							checked={song.song_id === selectedSongId}
							value={song.song_id}
							onChange={handleChange}
						/>
						<SongItem song={song} songPlaying={songPlaying} setSongPlaying={setSongPlaying} />
					</Card>
				))}
			</Box>
		</Box>
	)
}

export default SelectedSongs
