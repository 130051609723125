import React, { Fragment } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import { BaseDialog, BaseDialogProps } from '..'
import { Student } from '../../graphql/autogenerate/schemas'

type Props = {
	title: string
	description: string
	list: React.ReactNode
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	setSelectedStudent: React.Dispatch<React.SetStateAction<Student | undefined>>
}
export const ListCardDialog = ({
	title,
	description,
	list,
	setOpen,
	setSelectedStudent,
	...ext
}: Props & BaseDialogProps) => {
	return (
		<BaseDialog
			maxWidth="md"
			header={
				<Fragment>
					<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
						{title}
					</Typography>
					{description && (
						<Typography align="left" variant="h6" style={{ padding: 14 }}>
							<b>{description}</b>
						</Typography>
					)}
				</Fragment>
			}
			body={
				<Box style={{ background: 'linear-gradient(to bottom, rgb(255,255,255,1), #fff )' }}>
					<Grid item xs style={{ overflow: 'auto', maxHeight: '500px' }}>
						{list}
					</Grid>
				</Box>
			}
			onDiscardProps={{
				style: {
					padding: '6px 50px'
				}
			}}
			discardLabel="Close"
			confirmLabel="View All Classes"
			onDiscard={() => {
				setSelectedStudent(undefined)
				setOpen(false)
			}}
			onConfirm={() => undefined}
			dividers={false}
			{...ext}
		/>
	)
}
