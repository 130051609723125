import { Box, Typography } from '@material-ui/core'
import { SelectedEventType } from 'src/@types'
import { AccordionWrapper } from 'src/components'
import { AssignmentSort } from 'src/components/AssignmentSort/AssignmentSort'
import { AssignmentSortOrder } from 'src/utils'

type AssignmentAccordionProps = {
	countTitle: string
	count: number
	details: React.ReactNode
	sort?: AssignmentSortOrder
	handleSort?: (event: SelectedEventType) => void
	dateSort?: boolean
}
export const AssignmentAccordion = ({
	countTitle,
	count,
	details,
	sort,
	handleSort,
	dateSort = true
}: AssignmentAccordionProps) => {
	return (
		<Box marginTop={4}>
			<AccordionWrapper
				defaultExpanded
				summary={
					<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>
								{countTitle} ({count})
							</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />

						{count !== 0 && sort !== undefined && !!handleSort && (
							<AssignmentSort value={sort} onChange={handleSort} dateSort={dateSort} />
						)}
					</Box>
				}
				details={details}
			/>
		</Box>
	)
}
