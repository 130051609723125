import { useEffect, useState } from 'react'

import {
	useGetGoogeOAuthTokenLazyQuery,
	useGetGoogleAccountLazyQuery,
	useGetGoogleGroupsLazyQuery,
	useGetGoogleOAuthUrlQuery,
	useGetGoogleStudentsByCourseIdLazyQuery,
	useGetTeacherIntegrationLazyQuery,
	useInsertTeacherIntegrationMutation
} from '../../graphql/autogenerate/hooks'
import { GoogleGroup, Integration_Name_Enum } from '../../graphql/autogenerate/schemas'
import { IntegrationAccount, IntegrationStudent } from '../useIntegration'

type GoogleIntanceType = {
	setClasses: (classes: GoogleGroup[]) => void
	setStudents: (students: IntegrationStudent[]) => void
	setLoadingClasses: (loading: boolean) => void
	setLoadingStudents: (loading: boolean) => void
	teacherId: number
	setTeacherIntegrationId: (id: number) => void
}
export const useGoogleInstance = ({
	setClasses,
	setStudents,
	setLoadingClasses,
	setLoadingStudents,
	teacherId,
	setTeacherIntegrationId
}: GoogleIntanceType) => {
	const [account, setAccount] = useState<IntegrationAccount>()
	const [isOpen, setIsOpen] = useState(false)
	const [getGoogleToken, { data: googleTokenData }] = useGetGoogeOAuthTokenLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const { data: loginUrl } = useGetGoogleOAuthUrlQuery({
		fetchPolicy: 'no-cache'
	})
	const [getGoogleStudents, { data: googleStudentsData, loading: loadingStudents }] =
		useGetGoogleStudentsByCourseIdLazyQuery({
			fetchPolicy: 'no-cache'
		})
	const [getGoogleCourses, { data: googleCoursesData, loading: loadingCourses }] = useGetGoogleGroupsLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [getGoogleAccount, { data: googleAcountData, loading: googleAccountLoading }] =
		useGetGoogleAccountLazyQuery({
			fetchPolicy: 'no-cache'
		})
	const [
		teacherIntegrationMutation,
		{ data: teacherIntegrationMutationData, loading: teacherIntegrationMutationLoading }
	] = useInsertTeacherIntegrationMutation()
	const [getTeacherIntegration, { data: teacherIntegrationData, loading: loadingTeacherIntegration }] =
		useGetTeacherIntegrationLazyQuery()
	// getting the courses when we get the token from be
	useEffect(() => {
		if (googleTokenData?.getGoogleToken) {
			const token = googleTokenData.getGoogleToken
			const { id_token, refresh_token, scope, token_type, access_token, expiry_date } = token
			setAccount({
				...account,
				token: {
					id_token,
					refresh_token,
					scope,
					token_type,
					access_token,
					expiry_date
				}
			})
			getGoogleCourses({
				variables: {
					credentials: { id_token, refresh_token, scope, token_type, access_token, expiry_date }
				}
			})
			getGoogleAccount({
				variables: {
					credentials: {
						id_token,
						refresh_token,
						scope,
						token_type,
						access_token,
						expiry_date
					}
				}
			})
		}
	}, [googleTokenData])
	useEffect(() => {
		if (googleCoursesData) {
			setAccount({
				...account,
				courses: googleCoursesData?.getGoogleGroups?.map((i) => ({ name: i?.name, id: i?.id }))
			})
		}
	}, [googleCoursesData])
	useEffect(() => {
		if (googleAcountData && !googleAccountLoading) {
			const teacherEmail = googleAcountData?.getGoogleAccount?.teacherEmail
			const integrationId = googleAcountData?.getGoogleAccount?.integrationId
			if (teacherEmail && teacherEmail)
				getTeacherIntegration({
					variables: {
						teacherId,
						integrationName: Integration_Name_Enum.Google,
						teacherEmail: teacherEmail as string
					}
				})
			if (teacherEmail && integrationId)
				setAccount({
					...account,
					teacherEmail,
					integrationId
				})
		}
	}, [googleAcountData])

	useEffect(() => {
		if (
			teacherIntegrationData &&
			teacherIntegrationData?.teacher_integration.length === 0 &&
			!loadingTeacherIntegration &&
			teacherId &&
			account?.token &&
			!teacherIntegrationMutationLoading &&
			!teacherIntegrationMutationData
		) {
			insertTeacherIntegration()
		} else if (teacherIntegrationData?.teacher_integration[0]?.teacher_integration_id) {
			setTeacherIntegrationId(teacherIntegrationData.teacher_integration[0].teacher_integration_id)
		} else if (
			!teacherIntegrationData?.teacher_integration.length &&
			teacherIntegrationMutationData?.insert_teacher_integration_one?.teacher_integration_id
		) {
			setTeacherIntegrationId(
				teacherIntegrationMutationData?.insert_teacher_integration_one?.teacher_integration_id
			)
		}
	}, [
		teacherIntegrationData,
		loadingTeacherIntegration,
		teacherIntegrationMutationData,
		teacherIntegrationMutationLoading
	])

	// set students
	useEffect(() => {
		if (googleStudentsData?.getGoogleMembers) {
			setStudents([])
			setStudents(
				googleStudentsData.getGoogleMembers.map((i) => ({
					email: i?.emailAddress || '',
					name: { first: i?.name?.givenName, last: i?.name?.familyName },
					integrationId: i?.id
				})) as IntegrationStudent[]
			)
		}
	}, [googleStudentsData])

	// set classes
	useEffect(() => {
		if (googleCoursesData?.getGoogleGroups) {
			setClasses(googleCoursesData.getGoogleGroups as GoogleGroup[])
		}
	}, [googleCoursesData])

	useEffect(() => {
		setLoadingClasses(loadingCourses)
	}, [loadingCourses])

	useEffect(() => {
		setLoadingStudents(loadingStudents)
	}, [loadingStudents])

	// inserting the teacher_integration in db
	const insertTeacherIntegration = () => {
		if (account?.teacherEmail && account.integrationId && teacherId) {
			const { teacherEmail: email, integrationId: integration_id } = account
			teacherIntegrationMutation({
				variables: {
					teacher: {
						email,
						teacher_id: teacherId,
						integration_name: Integration_Name_Enum.Google,
						integration_id
					}
				},
				update: (cache, { data }) => {
					const integrationCacheUpdate = data?.insert_teacher_integration_one
					if (!integrationCacheUpdate) return
					const identify = cache.identify(integrationCacheUpdate)
					cache.evict({
						id: identify,
						fieldName: 'teacher_integration'
					})
				}
			})
		}
	}
	const openPopup = () => {
		if (isOpen) return

		return new Promise((resolve) => {
			setIsOpen(true)
			const url = loginUrl?.getGoogleAuthUrl || ''
			const loginWindow = window.open(url, 'name', 'height=600,width=450')
			if (loginWindow) {
				loginWindow.focus()

				try {
					const oauthInterval = setInterval(() => {
						try {
							if (loginWindow.closed) {
								setIsOpen(false)
								clearInterval(oauthInterval)
							}
							if (!loginWindow.location.href.includes('about:blank')) {
								const codeURL = new URL(loginWindow.location.href)
								const code = codeURL.searchParams.get('code')
								if (!code) return
								getGoogleToken({ variables: { code } })
								setClasses([])
								loginWindow.close()
								resolve(true)
							}
						} catch {
							// reject(e)
						}
					}, 200)
				} catch (e) {
					// reject(e)
				}
			}
		})
	}

	const getAccount = () => {
		return account
	}

	const getClassStudents = (classId: string) => {
		setStudents([])
		if (typeof account?.token !== 'string' && account?.token?.access_token)
			getGoogleStudents({
				variables: {
					credentials: account.token,
					groupId: classId
				}
			})
	}

	const clearToken = () => {
		if (account) setAccount({ ...account, token: null })
	}

	return { openPopup, getAccount, getClassStudents, clearToken }
}
