import { useEffect, useState } from 'react'

import { CircularProgress, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import { useMeasure } from 'react-use'
import { getFramesFromVideo } from 'src/utils'

import { RECORD_PREVIEW_THUMBNAIL } from '../../../utils/constants'
import { BackgroundContainer, ErrorContainer } from './Shared'

type ThumbnailsBackgroundProps = {
	videoUrl?: string
}
export const ThumbnailsBackground = ({ videoUrl }: ThumbnailsBackgroundProps) => {
	const [hasError, setHasError] = useState(false)
	const [thumbnailUrls, setThumbnailUrls] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const [ref, { width: elementWidth }] = useMeasure<HTMLDivElement>()

	useEffect(() => {
		if (!videoUrl || videoUrl === '' || !elementWidth) return

		const getFrames = async () => {
			setIsLoading(true)
			const thumbnailWidth = RECORD_PREVIEW_THUMBNAIL.width
			const thumbnailHeight = RECORD_PREVIEW_THUMBNAIL.height
			const thumbnailsCount = Math.ceil(elementWidth / thumbnailWidth)

			try {
				await getFramesFromVideo({
					videoSrc: videoUrl,
					framesCount: thumbnailsCount,
					width: thumbnailWidth,
					height: thumbnailHeight,
					setValues: setThumbnailUrls
				})
			} catch (error) {
				console.error(error)
				setHasError(true)
			}
			setIsLoading(false)
		}
		getFrames()
	}, [videoUrl, elementWidth])

	return (
		<div ref={ref} style={{ width: '100%', height: '100%' }}>
			{(hasError || !videoUrl) && (
				<ErrorContainer>
					<ErrorIcon style={{ margin: '.5em' }} />
					<Typography>Couldn't generate thumbnails</Typography>
				</ErrorContainer>
			)}
			{!hasError && (
				<BackgroundContainer>
					{thumbnailUrls.map((url, i) => (
						<img src={url} key={`thumbnail_${i}`} />
					))}
					{isLoading && videoUrl && <CircularProgress style={{ marginLeft: '30px', opacity: 0.5 }} />}
				</BackgroundContainer>
			)}
		</div>
	)
}
