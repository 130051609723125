import React, { useEffect, useState } from 'react'

import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { ActivityBoxEmpty, PaginationList, PaginationListDataItem, SelectSort } from 'src/components'
import { buildStudentActivityPaginationItem } from 'src/components/PaginationList/utils'
import { useGetClassStudentActivitiesQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Student_Activity_Order_By } from 'src/graphql/autogenerate/schemas'
import { getCyKey, SortOrder } from 'src/utils'

import { useClassDetailsContext } from '../useClassDetails'

export const ClassStudentActivities = () => {
	const pageElements = 5
	const { classId } = useClassDetailsContext()
	const [activityItems, setActivityItems] = useState<PaginationListDataItem[]>([])
	const [loadingStudents, setLoadingStudents] = useState<boolean>(false)
	const [sort, setSort] = useState<SortOrder>(SortOrder.Type)
	const [order, setOrder] = useState<Student_Activity_Order_By>({
		student_activity_type: Order_By.Asc
	})

	const {
		data: dataStudentActivities,
		loading: loadingStudentActivities,
		refetch: studentActivitiesRefetch
	} = useGetClassStudentActivitiesQuery({
		variables: {
			classId,
			offset: 0,
			limit: pageElements,
			order: order as unknown as Student_Activity_Order_By
		}
	})

	useEffect(() => {
		setLoadingStudents(loadingStudentActivities)
		if (!loadingStudentActivities && dataStudentActivities?.student_activity) {
			const cyKey = getCyKey(ClassStudentActivities, 'daysActivites')

			const items = dataStudentActivities.student_activity.map((item) =>
				buildStudentActivityPaginationItem(item, cyKey)
			)
			setActivityItems(items)
		}
	}, [dataStudentActivities, loadingStudentActivities])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		switch (selectedSort) {
			case SortOrder.Recent:
				setOrder({ created_at: Order_By.Desc })
				break
			case SortOrder.Type:
				setOrder({ student_activity_type: Order_By.Asc })
				break

			default:
				setOrder({ student_activity_type: Order_By.Asc })
				break
		}
		setSort(selectedSort)
	}

	const handlePageChange = (page: number) => {
		setLoadingStudents(true)
		studentActivitiesRefetch({ offset: (page - 1) * pageElements })
	}

	return loadingStudentActivities || activityItems.length > 0 ? (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Student Activities</b>
				</Typography>
				<SelectSort
					cyKey="StudentActivities"
					showType
					showRecent
					notAlphabetical
					value={sort}
					onChange={handleSort}
				/>
			</Box>
			<Paper>
				<PaginationList
					rowsPerPage={pageElements}
					limit={activityItems.length}
					data={activityItems}
					onPageChange={handlePageChange}
					loading={loadingStudents}
					totalRows={dataStudentActivities?.student_activity_aggregate.aggregate?.count ?? 0}
				/>
			</Paper>
		</Grid>
	) : (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>See What Your Students Are Up To</b>
				</Typography>
			</Box>
			<ActivityBoxEmpty
				title="Your Student feed is empty."
				description="Start adding Assignments, Lessons & Modules to get your Students engaged, The activities of your classes will appear here."
			/>
		</Grid>
	)
}
