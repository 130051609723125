import { ChangeEvent } from 'react'

import { MenuItem, SelectProps } from '@material-ui/core'
import clsx from 'clsx'
import { Select } from 'src/components'
import { Class } from 'src/graphql/autogenerate/schemas'

import useStyles from './SelectClass.styles'

type SelectClassProps = {
	selectedClass: Class
	setSelectedClass: (selectedClass: Class) => void
	options: Class[]
	selectProps?: SelectProps
}
export const SelectClass = ({ selectedClass, setSelectedClass, options, selectProps = {} }: SelectClassProps) => {
	const styles = useStyles()

	const handleSelect = (event: ChangeEvent<{ value: unknown }>) => {
		const classFromOptions = options.find((option) => option.class_id === event.target.value)
		if (classFromOptions) setSelectedClass(classFromOptions)
	}
	const getClassTitle = (classId: unknown) => {
		return options.find((option) => option.class_id === (classId as number))?.title ?? ''
	}

	return (
		<Select
			value={selectedClass.class_id}
			onChange={handleSelect}
			renderValue={getClassTitle}
			className={clsx(styles.select, selectProps.className || '')}
			{...selectProps}>
			{options.map((classObj) => (
				<MenuItem
					value={classObj.class_id}
					key={classObj.class_id}
					selected={classObj.class_id === selectedClass.class_id}
					className={styles.menuItem}>
					{classObj.title}
				</MenuItem>
			))}
		</Select>
	)
}
