import { Fragment } from 'react'

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography
} from '@material-ui/core'
import CallMadeIcon from '@material-ui/icons/CallMade'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { BreadcrumbsArrayType, Page } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons/CustomIcons'
import { CarouselOfAny, ImageButton, ImageButtonProps, ListLessons, MainTitle, SelectSort } from 'src/components'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { useGetSequenceTopicsByCatalogItemQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan, Sequence_Topic } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { useCatalogFilters } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useCatalogFilters'
import { useCatalogLessons } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useCatalogLessons'
import { SortOrder, buildRouteParameters, getCyKey } from 'src/utils'

import { useStyles } from './LessonsCatalogBody.styles'
export type LessonsCatalogBodyProps = {
	breadcrumbs: BreadcrumbsArrayType
	catalogItemId?: number
	catalogId?: number
	title: string
	teacherId: number
	catalogName?: string
	page?: Page
}

type LinePanelType = {
	sequenceTopic: Sequence_Topic
}

export const LessonsCatalogBody: React.FC<LessonsCatalogBodyProps> = ({
	breadcrumbs,
	title,
	teacherId,
	catalogItemId,
	catalogId,
	page
}) => {
	const styles = useStyles()
	const { data: sequencesData } = useGetSequenceTopicsByCatalogItemQuery({
		variables: {
			catalogItemId
		},
		skip: !catalogItemId
	})

	const { filters, handleCheck, selectedFilters } = useCatalogFilters({ catalog_id: catalogId, catalogItemId })

	const {
		count: countData,
		isFetching,
		lessons: lessonsFiltered,
		loading,
		moreResults,
		onSearchEnter,
		sort,
		handleSort,
		lessonSearchList,
		onSearch,
		searchBarTextState: searchbarText
	} = useCatalogLessons({
		teacherId,
		catalogId,
		featured: false,
		filters: selectedFilters,
		order: SortOrder.Up,
		page
	})

	const count = countData?.aggregate?.count

	const sequenceTopics = (sequencesData?.sequence_topic || []) as Sequence_Topic[]

	const LinePanel = ({ sequenceTopic }: LinePanelType) => {
		const names = sequenceTopic.sequence_topic_sequences.map(({ sequence }) => sequence.name).join(' | ')

		return (
			<Box className={styles.panel} display="flex" flexWrap="wrap" alignItems="center">
				{names && (
					<Button className={styles.sequenceNamesButon}>
						<b className={styles.sequenceNames}>{names}</b>
					</Button>
				)}
				<div style={{ flexGrow: 1 }} />
				<Button endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}>View</Button>
			</Box>
		)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={title}
				description="Engage students with fun and effective Modules, Sequences and stand alone Lessons all using the four step learning process of Inspire, Practice, Record, Reflect."
				rightActions={[
					{
						variant: 'outlined',
						style: { border: '1px solid rgba(50, 51, 66, 0.5)' },
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.LessonUpload.path
					}
				]}
			/>

			<SearchBar
				initialValue={searchbarText}
				placeholder="Find Lessons by Title, Song, Standard, or Keyword"
				onChange={(e) => onSearch(e.target.value)}
				onEnterPressed={onSearchEnter}
				loading={lessonSearchList.loading}
				dropdownItems={lessonSearchList.data?.lesson_plan.map((el, key) => {
					return (
						<ListLessons
							key={key}
							data={[]}
							returnItemAlone
							listItem={el as unknown as Lesson_Plan}
							teacherId={teacherId}
							spacing={1.5}
						/>
					)
				})}
			/>

			{catalogItemId && sequenceTopics.length > 0 && (
				<Fragment>
					<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Sequences</b>
						</Typography>
					</Box>
					<CarouselOfAny
						slidesPerPage={{ xs: 1, sm: 2 }}
						spacing={3}
						data={sequenceTopics.map((sequenceTopic) => ({
							backgroundImageUrl: sequenceTopic.image_path ?? '',
							title: (
								<Typography
									data-cy={getCyKey(LinePanel, `sequenceTopic-${sequenceTopic.title}`)}
									style={{ fontSize: 28 }}>
									{sequenceTopic.title}
								</Typography>
							),
							element: <LinePanel sequenceTopic={sequenceTopic} />,
							linkTo: {
								pathname: buildRouteParameters(Pages.SequenceDetail, {
									catalogId:
										catalogId ||
										sequenceTopic?.sequence_topic_catalog_items[0]?.catalog_item?.catalog_id,
									catalogItemId,
									sequenceTopicId: sequenceTopic.sequence_topic_id
								})
							}
						}))}
						renderItem={(item: ImageButtonProps) => (
							<ImageButton
								className={styles.smallBanners}
								titleHeight={{ xs: 250, md: 277 }}
								backgroundColor={item.backgroundColor}
								backgroundImageUrl={item.backgroundImageUrl}
								title={item.title}
								titlePosition="initial"
								element={item.element}
								elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
								linkTo={item.linkTo}
							/>
						)}
					/>
				</Fragment>
			)}
			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12} md={9}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b>Lessons ({count || 0})</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						{(count || 0) !== 0 && (
							<SelectSort
								cyKey="Lessons"
								value={sort}
								onChange={(e) => handleSort(e.target.value as string)}
							/>
						)}
					</Box>
				</Grid>
			</Grid>

			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					{isFetching || !loading ? (
						<ListLessons teacherId={teacherId} spacing={1.5} data={lessonsFiltered} />
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{isFetching && moreResults && (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{!loading && lessonsFiltered.length > 0 && !moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)}
					{!loading && lessonsFiltered.length <= 0 && (
						<div>
							<Box my={4}>
								{searchbarText ? (
									<Fragment>
										<Typography variant="h6" color="textSecondary">
											Your search for "{searchbarText}" did not return any results.
										</Typography>
									</Fragment>
								) : (
									<Fragment>
										<Typography variant="h6" color="textSecondary">
											No results found
										</Typography>
										<Typography color="textSecondary">
											Your filter selection did not return any results
										</Typography>
										<Typography color="textSecondary">
											Try removing a filter or upload own by clicking the button top right
										</Typography>
									</Fragment>
								)}
							</Box>
						</div>
					)}
				</Grid>

				<Grid item xs={12} md>
					<Paper>
						<Box py={2} px={3}>
							<Typography
								variant="h6"
								style={{
									fontSize: 18
								}}>
								<b>Filters</b>
							</Typography>
						</Box>
						<Divider />
						<Box py={1} px={3}>
							{filters.map((filter, indexFilter) => (
								<List key={`filter-${indexFilter}`} dense>
									<Typography
										variant="h6"
										style={{
											fontSize: 18
										}}>
										<b>{filter.name}</b>
									</Typography>
									{filter.items.map((item, indexItem) => (
										<ListItem
											key={item.id}
											button
											disableGutters
											onClick={() => handleCheck(indexFilter, indexItem)}>
											<ListItemIcon
												style={{
													minWidth: 'auto'
												}}>
												<Checkbox
													edge="start"
													checked={selectedFilters.includes(item.id)}
													checkedIcon={<CheckBoxOutlinedIcon />}
													tabIndex={-1}
													disableRipple
													inputProps={{
														'aria-labelledby': item.label
													}}
												/>
											</ListItemIcon>
											<ListItemText id={item.id.toString()} primary={item.label} />
										</ListItem>
									))}
								</List>
							))}
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}
