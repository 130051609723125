import { Theme, createStyles, fade, makeStyles } from '@material-ui/core'

export const useChannelPageStyles = makeStyles((theme: Theme) =>
	createStyles({
		cover: {
			marginBottom: '50px',
			'& .MuiBox-root': {
				aspectRatio: '19/5',
				backgroundSize: 'cover'
			},
			'& .MuiButton-contained': { aspectRatio: '12/3' }
		},
		column: {
			display: 'flex',
			flexDirection: 'column'
		},
		videoBox: {
			height: '100%'
		},
		listToolbar: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '12px',
			alignItems: 'center'
		},
		about: {
			marginTop: '50px'
		},
		panel: {
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white,
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			},
			'& .MuiButton-root:first-child': {
				paddingLeft: theme.spacing(4),
				backgroundColor: fade(theme.palette.common.black, 0.3),
				color: theme.palette.common.white,
				boxShadow: 'none'
			}
		}
	})
)

export default useChannelPageStyles
