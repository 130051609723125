import { Box, Card, Grid, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { useHistory } from 'react-router'
import { PagesProps } from 'src/@types/types'
import { BaseLoading } from 'src/components'
import { SubmissionCardContainer as SubmissionCard } from 'src/components/Cards/SubmissionCard/SubmissionCard.container'
import { MainTitle } from 'src/components/MainTitle'
import { useScroll, useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils/routes-utils'
import { buildBreadCrumbs } from 'src/utils/utils'

import { AssignmentDescription } from '../../components/AssignmentDescription/AssignmentDescription'
import { useAssignmentAttachments } from '../../hooks/useAssignmentAttachments'
import { useClassAssignment } from '../../hooks/useClassAssignment'
import useStyles from './ClassAssignmentDetail.styles'
import { AssignmentAttachments } from './components/AssignmentAttachments/AssignmentAttachments'
import { SubmissionsHeader } from './components/SubmissionsHeader/SubmissionsHeader'
export const ClassAssignmentDetail = ({ page }: PagesProps) => {
	useScroll()
	const history = useHistory()
	const styles = useStyles()
	const { teacherData } = useLoginContext()
	const { classId, classAssignmentId } = useWurrlyParams<typeof Pages.ClassAssignmentDetail.params[number]>()
	const { submissionsLoading, className, assignment, submissions, sortOrder, setSortOrder, totalSubmissions } =
		useClassAssignment(classAssignmentId)
	const {
		songs,
		videos,
		assets,
		loading: areAttachmentsLoading
	} = useAssignmentAttachments(classAssignmentId, teacherData.teacher_id)

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			},
			{
				index: 3,
				overrideName: assignment?.name
			}
		],
		params: {
			classAssignmentId,
			classId
		}
	})

	const goToReviewAndGrade = () => {
		history.push(buildRouteParameters(Pages.ClassAssignmentGrade, { classAssignmentId, classId }))
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={assignment?.name}
				description={
					<AssignmentDescription
						instructions={assignment?.instructions ?? 'No instructions provided'}
						dueDateFormatted={assignment?.dueDateFormatted ?? ''}
					/>
				}
				rightActions={[
					{
						variant: 'contained',
						color: 'secondary',
						endIcon: <ChevronRight />,
						name: 'Review & Grade',
						disabled: totalSubmissions === 0,
						onClick: goToReviewAndGrade
					}
				]}
			/>
			<SubmissionsHeader
				submissionCount={totalSubmissions}
				sortOrder={sortOrder}
				setSortOrder={setSortOrder}
			/>

			<Grid container spacing={3}>
				<Grid item sm={12} md={9}>
					{submissionsLoading && <BaseLoading />}
					{!submissionsLoading &&
						submissions.map((submission) => (
							<Card key={submission.submission_id} className={styles.assignmentCardContainer}>
								<SubmissionCard
									submission={submission}
									assignmentDueDate={[assignment?.due_date, assignment?.due_time].join('T')}
									assignmentGradingSystem={assignment?.grading_system}
								/>
							</Card>
						))}
					{!submissionsLoading && totalSubmissions === 0 && (
						<Box my={4} mb={7}>
							<Typography variant="h6" color="textSecondary">
								No submissions yet
							</Typography>
							<Typography color="textSecondary">
								Your students' submissions will appear here once they are uploaded.
							</Typography>
						</Box>
					)}
				</Grid>
				<Grid item sm={12} md={3}>
					{areAttachmentsLoading ? (
						<BaseLoading />
					) : (
						<AssignmentAttachments
							gradingSystemLabel={assignment?.grading_system?.label ?? ''}
							songs={songs}
							videos={videos}
							assets={assets}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	)
}
