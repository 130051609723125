import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

type PlaylistCardStylesProps = {
	imageUrl: string
}
export const usePlaylistCardStyles = makeStyles<Theme, PlaylistCardStylesProps>(() =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			height: '217px',
			cursor: 'pointer',
			boxShadow:
				'0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.15)',
			borderRadius: '4px'
		},
		imageContainer: {
			position: 'relative',
			height: '100%',
			width: '25%',
			backgroundImage: ({ imageUrl }: PlaylistCardStylesProps) => `url(${imageUrl})`,
			backgroundPosition: 'center',
			backgroundColor: 'gray',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			borderRadius: '4px 0px 4px 0 px'
		},
		blank: {
			position: 'relative',
			height: '100%',
			width: '25%',
			backgroundPosition: 'center',
			backgroundColor: 'gray',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			borderRadius: '4px 0px 4px 0 px'
		},

		genres: {
			color: '#1D273D',
			opacity: 0.5
		},
		ellipsis: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap'
		},
		ellipsisSecondLine: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			height: '46px',
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical'
		},
		details: {
			padding: 24,
			width: '75%'
		},
		buttons: {
			display: 'flex',
			marginTop: '20px',
			alignItems: 'stretch',
			gap: '12px',
			justifyContent: 'flex-end'
		},
		description: {
			marginBottom: 10,
			fontSize: '1.3rem',
			marginRight: '10px'
		},
		title: {
			color: '#FFFFFF',
			maerginTop: 50,
			padding: '40px 20px 0 20px'
		},
		flag: {
			padding: '10px 0',
			backgroundColor: 'rgba(0,0,0,0.3)',
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		flagText: {
			display: 'flex',
			color: 'white',
			marginLeft: 15
		},
		viewButton: {
			background: '#E9E9EA',
			color: '#1D273D'
		},
		switch: {
			alignSelf: 'flex-start',
			marginRight: '25%'
		},
		visibility: {
			textAlign: 'center',
			alignSelf: 'center',
			fontSize: '14px',
			color: '#8B909B'
		},
		containerSmall: {
			display: 'flex',
			gap: '24px',
			padding: '24px'
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			flexGrow: 1
		},
		buttonsSmall: {
			display: 'flex',
			gap: '8px',
			flexShrink: 0
		},
		titleSmall: {
			fontWeight: 'bold'
		},
		locked: {
			fontSize: '0.75rem',
			marginTop: '1px',
			marginLeft: '2px'
		},
		lockedButton: {
			borderRadius: '100px',
			width: '125px',
			height: '27px'
		},
		genresSmall: {
			color: '#1D273D',
			opacity: 0.5
		},
		subscription: {
			backgroundColor: '#FFF6DB',
			borderColor: '#F3AB34',
			border: '2px solid',
			width: 'auto'
		},
		subscribe: { color: '#F3AB34' },
		subscribed: { backgroundColor: '#BDEAD7', border: '2px solid #ADCEC1', borderRadius: ' 4px' }
	})
)

export default usePlaylistCardStyles
