import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useLoginStyles = makeStyles<Theme, { searchValue?: string; loading?: boolean }>((theme: Theme) =>
	createStyles({
		listBox: {
			maxHeight: '200px',
			overflowY: 'scroll',
			paddingLeft: 0,
			margin: 0
		},
		noOptions: {
			backgroundColor: ({ searchValue, loading }) =>
				loading
					? theme.palette.info.light
					: searchValue
					? 'rgba(255, 61, 0, 0.12)'
					: 'rgba(255, 194, 12, 0.1)'
		},
		roleTitle: {
			marginBottom: '10px',
			'& h4': {
				paddingBottom: '10px'
			}
		},
		label: {
			margin: '25px 0 10px 0'
		},
		smallMarginLabel: {
			margin: '15px 0 10px 0'
		},
		visibilityIcon: {
			cursor: 'pointer',
			position: 'absolute',
			right: '20px',
			top: '50%',
			transform: 'translateY(-50%)'
		},
		input: {
			borderRadius: '4px',
			border: '1px solid #DDDDDD',
			padding: '10px 30px',
			'& input:-webkit-autofill': {
				'-webkit-box-shadow': '0 0 0 30px white inset'
			}
		},
		link: {
			textDecoration: 'none',
			color: '#3c61f4'
		},
		getStartedButton: {
			margin: '30px 0 15px 0',
			padding: '0 30px'
		},
		goBackButton: {
			cursor: 'pointer',
			fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
			fontSize: '13px'
		},
		dialogsPaper: {
			width: '100%',
			padding: '30px 80px'
		},
		checkbox: {
			paddingLeft: '0px'
		},
		checkboxText: {
			color: theme.palette.grey[500]
		},
		subtitle: {
			color: theme.palette.secondary.contrastText,
			opacity: '80%'
		},
		schoolContact: {
			marginTop: 20,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			'& div': {
				display: 'flex',
				alignItems: 'center'
			}
		},
		contactUs: {
			fontSize: '14px',
			marginLeft: 5,
			cursor: 'pointer'
		}
	})
)
