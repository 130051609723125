import { AWS_S3_RESOURCES } from '../../../utils'

export const STUDENT_DASH_RESOURCES = `${AWS_S3_RESOURCES}/Student_Dashboard`

export const CHALLENGE_CHAMP_BADGE = `${STUDENT_DASH_RESOURCES}/challenge_champ_badge.svg`
export const GOAL_MEDALIST_BADGE = `${STUDENT_DASH_RESOURCES}/goal_medalist_badge.svg`
export const PRACTICE_PRO_BADGE = `${STUDENT_DASH_RESOURCES}/practice_pro_badge.svg`
export const RECORDING_ARTIST_BADGE = `${STUDENT_DASH_RESOURCES}/recording_artist_badge.svg`
export const STREAK_VICTOR_BADGE = `${STUDENT_DASH_RESOURCES}/streak_victor_badge.svg`
export const ULTIMATE_EXPLORER_BADGE = `${STUDENT_DASH_RESOURCES}/ultimate_explorer_badge.svg`

export const DISCOVER_MUSIC_IMG = `${STUDENT_DASH_RESOURCES}/DiscoverMusic.png`
export const CHALLENGE_IMG = `${STUDENT_DASH_RESOURCES}/Challenge.png`
