import { useRouteMatch } from 'react-router-dom'
import { StudioStep } from 'src/components/Studio/constants'
import { ReviewAndSubmitProvider, useReviewAndSubmitContext } from 'src/components/Studio/Hooks/useReviewAndSubmit'
import { StudentPages } from 'src/routes/studentpages'
import { VideoSubmitProvider } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { StudioSubmit } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/VideoSubmitDialog'
import { StudioRecord } from 'src/scenes/Student/scenes/StudentStudio/StudentRecord'
import { StudioReview } from 'src/scenes/Student/scenes/StudentStudio/StudentReview'

export const ReviewAndSubmit = () => (
	<ReviewAndSubmitProvider>
		<VideoSubmitProvider>
			<ReviewAndSubmitInner />
		</VideoSubmitProvider>
	</ReviewAndSubmitProvider>
)

const ReviewAndSubmitInner = () => {
	const { step } = useReviewAndSubmitContext()
	const isRecordRoute = !!useRouteMatch(StudentPages.StudentRecord.path)

	if (isRecordRoute) return <StudioRecord />

	return step === StudioStep.Review ? <StudioReview /> : <StudioSubmit />
}
