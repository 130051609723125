import React from 'react'

import {
	Avatar,
	Box,
	ButtonProps,
	CircularProgress,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography
} from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import LinkIcon from '@material-ui/icons/Link'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { Visibility_Enum } from 'src/graphql/autogenerate/schemas'

import { ActionButton, BaseButton } from '.'
import {
	EditIcon,
	LessonsSmallIcon,
	MusicNoteV2SmallIcon,
	StandardIcon,
	StarSmallIcon
} from '../assets/icons/CustomIcons'
import { getCyKey } from '../utils'
import { AssignmentAdded } from './Buttons/AssignmentAdded/AssignmentAdded'
import { SmallBaseButton } from './Buttons/SmallBaseButton'
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			minHeight: 75,
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			},
			'&:hover': {
				'& $thumbnail': {
					backgroundColor: fade(theme.palette.common.black, 0.4),
					position: 'absolute',
					width: '100%',
					height: '100%'
				},
				'& $playIcon': {
					opacity: 1
				}
			}
		},
		thumbnail: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor: fade(theme.palette.grey[700], 0),
			transition: 'background-color 0.3s',
			borderRadius: '4px'
		},
		avatar: {
			marginTop: 7,
			marginBottom: 6
		},
		avatarVideo: {
			position: 'relative',
			margin: theme.spacing(1, 2, 1, 0),
			alignSelf: 'flex-start',
			'& .MuiAvatar-root': {
				width: '70px',
				height: '50px'
			}
		},
		itemTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word'
		},
		text: {
			alignSelf: 'flex-start'
		},
		playIcon: {
			color: theme.palette.common.white,
			opacity: 0,
			position: 'absolute',
			top: '12px',
			left: '22px',
			transition: 'opacity 0.3s'
		},
		listItemDisabled: {
			minHeight: 75,
			opacity: 0.5,
			'& .boxActionsDisabled': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1),
						opacity: 0.5
					},
					'&:last-child, &.addClassDisabled': {
						marginLeft: theme.spacing(1),
						opacity: 0.5
					}
				}
			},
			'&:hover': {
				'& $thumbnailDisabled': {
					backgroundColor: fade(theme.palette.common.black, 0.4),
					position: 'absolute',
					width: '100%',
					height: '100%',
					opacity: 0.5
				},
				'& $playIconDisabled': {
					opacity: 1
				}
			}
		},
		thumbnailDisabled: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor: fade(theme.palette.grey[700], 0),
			transition: 'background-color 0.3s',
			borderRadius: '4px',
			opacity: 0.5
		},
		avatarDisabled: {
			marginTop: 7,
			marginBottom: 6,
			opacity: 0.5
		},
		avatarVideoDisabled: {
			position: 'relative',
			margin: theme.spacing(1, 2, 1, 0),
			alignSelf: 'flex-start',
			opacity: 0.5,
			'& .MuiAvatar-root': {
				width: '70px',
				height: '50px',
				opacity: 0.5
			}
		},
		itemTitleDisabled: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word',
			opacity: 0.5
		},
		textDisabled: {
			alignSelf: 'flex-start',
			opacity: 0.5
		},
		enableToolTip: {
			'& button': {
				pointerEvents: 'stroke!important'
			}
		},
		playIconDisabled: {
			color: theme.palette.common.white,
			opacity: 0,
			position: 'absolute',
			top: '12px',
			left: '22px',
			transition: 'opacity 0.3s'
		}
	})
)

export type ListItemSongProps = {
	imageUrl: string
	title: string
	author: string
	genres: string
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	btnLesson?: ButtonProps
	btnFavorite?: { selected?: boolean } & ButtonProps
	loadingAddFav?: boolean
	loadingRemoveFav?: boolean
	resetTooltip?: () => void
	btnLyric?: ButtonProps
	btnAddClass?: ButtonProps
	btnRemove?: ButtonProps & { tooltipText?: string }
	btnEdit?: ButtonProps
	btnStandard?: ButtonProps
	btnView?: ButtonProps
	btnCopyLink?: ButtonProps
	btnAddAssignment?: ButtonProps
	btnAssignmentAdded?: ButtonProps
	btnModules?: ButtonProps
	btnStudio?: ButtonProps
	isVideo?: boolean
	btnCopyTooltip?: string
	visibility?: Visibility_Enum
	isPurchased?: boolean
	moduleCount?: number
}

export const ListItemSong = ({
	imageUrl,
	title,
	author,
	genres,
	btnLesson,
	btnFavorite,
	loadingAddFav,
	loadingRemoveFav,
	btnLyric,
	btnAddClass,
	btnStandard,
	btnRemove,
	btnEdit,
	btnCopyLink,
	btnCopyTooltip,
	resetTooltip,
	btnView,
	btnAddAssignment,
	btnAssignmentAdded,
	btnStudio,
	isVideo = false,
	visibility,
	isPurchased,
	moduleCount,
	btnModules
}: ListItemSongProps) => {
	const styles = useStyles()

	const isForSale = visibility === Visibility_Enum.ForSale

	return isForSale && !isPurchased ? (
		<ListItem className={styles.listItemDisabled}>
			<ListItemAvatar className={!isVideo ? styles.avatarDisabled : styles.avatarVideoDisabled}>
				<>
					<Avatar variant="rounded" src={imageUrl} />
					<Box className={styles.thumbnailDisabled}>
						{isVideo && <PlayArrowIcon className={styles.playIconDisabled} />}
					</Box>
				</>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				className={styles.textDisabled}
				primary={
					<div>
						<Typography className={styles.itemTitleDisabled} variant="h6" style={{ fontSize: 18 }}>
							<b data-cy={getCyKey(ListItemSong, 'Title')}>{title}</b>
						</Typography>
						<Typography noWrap style={{ fontSize: 14 }}>
							<b>{author}</b>
						</Typography>
					</div>
				}
				secondary={
					<Typography variant="caption" color="textSecondary">
						<b>{genres}</b>
					</Typography>
				}
			/>
			<Box display="flex" className="boxActionsDisabled" alignSelf="flex-start" marginTop="6px">
				<div style={{ flexGrow: 1 }} />
				{btnCopyLink && (
					<Tooltip title={btnCopyTooltip || ''}>
						<ActionButton
							disabled={true}
							{...btnCopyLink}
							icon={<LinkIcon />}
							onMouseLeave={resetTooltip}
						/>
					</Tooltip>
				)}
				{btnEdit && (
					<Tooltip title="Edit">
						<ActionButton {...btnEdit} disabled={true} icon={<EditIcon />} />
					</Tooltip>
				)}
				{btnFavorite && (
					<Tooltip title="Favorite">
						<ActionButton
							data-cy={getCyKey(ListItemSong, 'Favorite')}
							{...btnFavorite}
							selected={btnFavorite.selected}
							disabled={true}
							icon={
								loadingAddFav || loadingRemoveFav ? (
									<CircularProgress color="secondary" size={20} />
								) : (
									<StarSmallIcon />
								)
							}
						/>
					</Tooltip>
				)}
				{btnLesson && (
					<Tooltip title="Lessons">
						<ActionButton
							data-cy={getCyKey(ListItemSong, 'Lessons')}
							{...btnLesson}
							disabled={true}
							icon={<LessonsSmallIcon />}
						/>
					</Tooltip>
				)}
				{btnLyric && (
					<Tooltip title="Lyrics">
						<ActionButton
							data-cy={getCyKey(ListItemSong, 'Lyrics')}
							{...btnLyric}
							disabled={true}
							icon={<MusicNoteV2SmallIcon />}
						/>
					</Tooltip>
				)}

				{btnStandard && (
					<Tooltip title="Standard">
						<ActionButton {...btnStandard} disabled={true} icon={<StandardIcon />} />
					</Tooltip>
				)}
				{btnRemove && (
					<Tooltip title={btnRemove.tooltipText || 'Remove'}>
						<Box className={styles.enableToolTip}>
							<ActionButton
								data-cy={getCyKey(ListItemSong, 'btnRemove')}
								icon={<DeleteIcon fontSize="small" />}
								{...btnRemove}
								disabled={true}
							/>
						</Box>
					</Tooltip>
				)}
				{btnAddClass && (
					<Tooltip title="Add to class">
						<SmallBaseButton
							data-cy={getCyKey(ListItemSong, 'btnAddClass')}
							color="primary"
							disabled={true}
							className={'addClassDisabled'}
							endIcon={<AddIcon />}
							{...btnAddClass}>
							<Typography>Add to class</Typography>
						</SmallBaseButton>
					</Tooltip>
				)}
				{btnStudio && (
					<Tooltip title="Go to Studio">
						<SmallBaseButton
							data-cy={getCyKey(ListItemSong, 'btnStudio')}
							color="primary"
							className={'addClassDisabled'}
							disabled={true}
							{...btnStudio}>
							<Typography>Studio</Typography>
						</SmallBaseButton>
					</Tooltip>
				)}
				{btnView && (
					<Tooltip title="View">
						<BaseButton
							data-cy={getCyKey(ListItemSong, 'btnView')}
							color="primary"
							className={'addClassDisabled'}
							disabled={true}
							endIcon={<ArrowForwardIcon />}
							{...btnView}>
							<Typography>View</Typography>
						</BaseButton>
					</Tooltip>
				)}
				{btnAddAssignment && (
					<Tooltip title="Add Assignment">
						<BaseButton
							color="primary"
							className={'addClassDisabled'}
							disabled={true}
							endIcon={<AddIcon />}
							{...btnAddAssignment}>
							<Typography>Add Assignment</Typography>
						</BaseButton>
					</Tooltip>
				)}
				{btnAssignmentAdded && <AssignmentAdded {...btnAssignmentAdded} />}
			</Box>
		</ListItem>
	) : (
		<ListItem className={styles.listItem}>
			<ListItemAvatar className={!isVideo ? styles.avatar : styles.avatarVideo}>
				<>
					<Avatar variant="rounded" src={imageUrl} />
					<Box className={styles.thumbnail}>
						{isVideo && <PlayArrowIcon className={styles.playIcon} />}
					</Box>
				</>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				className={styles.text}
				primary={
					<div>
						<Typography className={styles.itemTitle} variant="h6" style={{ fontSize: 18 }}>
							<b data-cy={getCyKey(ListItemSong, 'Title')}>{title}</b>
						</Typography>
						<Typography noWrap style={{ fontSize: 14 }}>
							<b>{author}</b>
						</Typography>
					</div>
				}
				secondary={
					<Typography variant="caption" color="textSecondary">
						<b>{genres}</b>
					</Typography>
				}
			/>
			<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
				<div style={{ flexGrow: 1 }} />
				{btnModules && (
					<Tooltip title={moduleCount === 0 ? 'This lesson is not assigned to any module yet' : ''}>
						<span>
							<ActionButton {...btnModules} icon={<InfoIcon />} />
						</span>
					</Tooltip>
				)}
				{btnCopyLink && (
					<Tooltip title={btnCopyTooltip || ''}>
						<ActionButton {...btnCopyLink} icon={<LinkIcon />} onMouseLeave={resetTooltip} />
					</Tooltip>
				)}
				{btnEdit && (
					<Tooltip title="Edit">
						<ActionButton {...btnEdit} disabled={btnEdit.disabled} icon={<EditIcon />} />
					</Tooltip>
				)}
				{btnFavorite && (
					<Tooltip title="Favorite">
						<ActionButton
							data-cy={getCyKey(ListItemSong, 'Favorite')}
							{...btnFavorite}
							selected={btnFavorite.selected}
							disabled={btnFavorite.disabled}
							icon={
								loadingAddFav || loadingRemoveFav ? (
									<CircularProgress color="secondary" size={20} />
								) : (
									<StarSmallIcon />
								)
							}
						/>
					</Tooltip>
				)}
				{btnLesson && (
					<Tooltip title="Lessons">
						<ActionButton
							data-cy={getCyKey(ListItemSong, 'Lessons')}
							{...btnLesson}
							disabled={btnLesson.disabled}
							icon={<LessonsSmallIcon />}
						/>
					</Tooltip>
				)}
				{btnLyric && (
					<Tooltip title="Lyrics">
						<ActionButton
							data-cy={getCyKey(ListItemSong, 'Lyrics')}
							{...btnLyric}
							disabled={btnLyric.disabled}
							icon={<MusicNoteV2SmallIcon />}
						/>
					</Tooltip>
				)}

				{btnStandard && (
					<Tooltip title="Standard">
						<ActionButton {...btnStandard} disabled={btnStandard.disabled} icon={<StandardIcon />} />
					</Tooltip>
				)}
				{btnRemove && (
					<Tooltip title={btnRemove.tooltipText || 'Remove'}>
						<Box className={styles.enableToolTip}>
							<ActionButton
								data-cy={getCyKey(ListItemSong, 'btnRemove')}
								icon={<DeleteIcon fontSize="small" />}
								{...btnRemove}
							/>
						</Box>
					</Tooltip>
				)}
				{btnAddClass && (
					<Tooltip title="Add to class">
						<SmallBaseButton
							data-cy={getCyKey(ListItemSong, 'btnAddClass')}
							color="primary"
							className={'addClass'}
							endIcon={<AddIcon />}
							{...btnAddClass}>
							<Typography>Add to class</Typography>
						</SmallBaseButton>
					</Tooltip>
				)}
				{btnStudio && (
					<Tooltip title="Go to Studio">
						<SmallBaseButton
							data-cy={getCyKey(ListItemSong, 'btnStudio')}
							color="primary"
							className={'addClass'}
							{...btnStudio}>
							<Typography>Studio</Typography>
						</SmallBaseButton>
					</Tooltip>
				)}
				{btnView && (
					<Tooltip title="View">
						<BaseButton
							data-cy={getCyKey(ListItemSong, 'btnView')}
							color="primary"
							className={'addClass'}
							endIcon={<ArrowForwardIcon />}
							{...btnView}>
							<Typography>View</Typography>
						</BaseButton>
					</Tooltip>
				)}
				{btnAddAssignment && (
					<Tooltip title="Add Assignment">
						<BaseButton
							color="primary"
							className={'addClass'}
							endIcon={<AddIcon />}
							{...btnAddAssignment}>
							<Typography>Add Assignment</Typography>
						</BaseButton>
					</Tooltip>
				)}
				{btnAssignmentAdded && <AssignmentAdded {...btnAssignmentAdded} />}
			</Box>
		</ListItem>
	)
}
