import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useGetClassLessonsByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan, Order_By } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { ProductTitleEnum, buildRouteParameters } from 'src/utils'
export const useLessonsWrapper = () => {
	const history = useHistory()
	const { classId } = useWurrlyParams()
	const [count, setCount] = useState(0)
	const [data, setData] = useState<Lesson_Plan[]>([])

	const type = ProductTitleEnum.LessonPlan

	const handleViewAll = () => {
		history.push(
			buildRouteParameters(Pages.AllClassProducts, {
				classId: Number(classId),
				type: ProductTitleEnum.LessonPlan
			})
		)
	}
	const [getLessons, { data: lessonsData, loading }] = useGetClassLessonsByClassIdLazyQuery()

	useEffect(() => {
		getLessons({
			variables: {
				limit: 5,
				order: Order_By.Asc,
				classId: Number(classId)
			}
		})
	}, [])
	useEffect(() => {
		if (lessonsData) {
			setCount(lessonsData.lesson_plan_aggregate.aggregate?.count || 0)
			setData(lessonsData.lesson_plan as Lesson_Plan[])
		}
	}, [lessonsData])

	return {
		loading,
		data,
		type,
		count,
		handleViewAll
	}
}
