import { Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { BaseButton, ImageButton } from 'src/components'
import { IconButton } from 'src/components/Buttons/IconButton'
import { CardTitle } from 'src/components/CardTitle'
import { Pages } from 'src/routes/teacherPages'
import { DEFAULT_CLASS_LESSON_IMG, DESKTOP_IMG, STAGE_IMG } from 'src/utils'

import useStyles from './AssignmentTiles.styles'
type AssignmentTilesProps = React.HTMLAttributes<HTMLDivElement> & { hasAssignments: boolean; hasLessons: boolean }

const elementPosition = { right: 16, bottom: 16 }

export const AssignmentTiles = ({ hasAssignments, hasLessons, ...props }: AssignmentTilesProps) => {
	const styles = useStyles()
	const assignmentTileSize = !hasAssignments && !hasLessons ? 6 : 12
	const assignmentTileClass = !hasAssignments && !hasLessons ? '' : styles.assignmentTile
	const TilesContent = {
		addAssignments: {
			backgroundImageUrl: DESKTOP_IMG,
			linkTo: Pages.AddAssignment.path,
			title: <CardTitle text="Add Your Own Assignments" bold />,
			element: (
				<BaseButton color="secondary" endIcon={<AddIcon />}>
					<Typography>Add Assignment</Typography>
				</BaseButton>
			),
			elementPosition
		},
		lessons: {
			backgroundImageUrl: DEFAULT_CLASS_LESSON_IMG,
			linkTo: Pages.Lessons.path,
			title: <CardTitle text="Find Lessons" bold />,
			element: <IconButton icon="notebook" text="Lessons" />,
			elementPosition
		},
		favorites: {
			backgroundImageUrl: STAGE_IMG,
			linkTo: Pages.MyFavorites.path,
			title: <CardTitle text="Add From Favorites" bold />,
			element: <IconButton icon="star" text="Favorites" />,
			elementPosition
		}
	}

	return (
		<Grid container spacing={1} {...props} className={styles.container}>
			{!hasAssignments && (
				<Grid item xs={assignmentTileSize} className={assignmentTileClass}>
					<ImageButton
						titlePosition="initial"
						{...TilesContent.addAssignments}
						titleHeight="100%"
						wrapperProps={{ className: styles.imageButtonWrapper }}
						className={styles.bigImage}
					/>
				</Grid>
			)}

			{!hasLessons && (
				<Grid item xs={hasAssignments ? 12 : 6} className={styles.fullheight}>
					<Grid container spacing={1} className={styles.fullheight}>
						<Grid item xs={hasAssignments ? 6 : 12}>
							<ImageButton
								titlePosition="initial"
								{...TilesContent.lessons}
								className={styles.bottomImage}
							/>
						</Grid>
						<Grid item xs={hasAssignments ? 6 : 12}>
							<ImageButton
								titlePosition="initial"
								{...TilesContent.favorites}
								className={styles.bottomImage}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}
