import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { Redirect, useLocation } from 'react-router-dom'
import { LocationState } from 'src/@types'
import { NotesIcon } from 'src/assets/icons'
import { InfoDialog, FixedBackground } from 'src/components'
import {
	useCompleteTeacherIntegrationMutation,
	useSignUpTeacherIntegrationMutation,
	useSignupTeacherMutation
} from 'src/graphql/autogenerate/hooks'
import { Integration_Name_Enum, Role_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { SelectRoleDialog } from 'src/scenes/Login/components/SelectRoleDialog/SelectRoleDialog'
import { SignUpMainDialog } from 'src/scenes/Login/components/SignUpMainDialog'
import { SignUpData } from 'src/scenes/Login/enums'
import { CleverSignupState } from 'src/scenes/Login/scenes/4-CleverLogin/CleverLogin'

type SignUpState = {
	token: string
	integrationName: Integration_Name_Enum
	teacherId?: number
}

export const SignupPage = () => {
	const { state } = useLocation<LocationState | CleverSignupState | SignUpState>()
	const { loginSignIn, integrationName, setIntegrationName } = useLoginContext()
	const [signUpData, setSignUpData] = useState<SignUpData>({})
	const [signUpLoginMainDialog, setSignUpLoginMainDialog] = useState<boolean>(true)
	const [selectRoleDialogOpen, setSelectRoleDialogOpen] = useState<boolean>(false)
	const [signupTeacher, { loading: insertTeacherLoading }] = useSignupTeacherMutation()
	const [signUpTeacherIntegration, { loading: signupIntegrationLoading }] = useSignUpTeacherIntegrationMutation()
	const [completeTeacherIntegration, { loading: completeTeacherLoading }] =
		useCompleteTeacherIntegrationMutation()
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false)
	const [redirectToReferrer, setRedirectToReferrer] = useState(false)

	// this is executed when the user clicks the signin button choosing any of the possible integrations: Clever, Google, Microsoft
	useEffect(() => {
		if (signUpData.token && signUpData.integrationId && signUpLoginMainDialog && !selectRoleDialogOpen) {
			setSignUpLoginMainDialog(false)
			setSelectRoleDialogOpen(true)
			setIntegrationName(signUpData.integrationId)
		}
	}, [signUpData.token])

	// Set token in case its redirected from /oauth /oauth-classlink, specifically for Clever and ClassLink
	useEffect(() => {
		const currState = state as SignUpState
		if (currState?.token) {
			setIntegrationName(currState?.integrationName)
			setSignUpData({
				token: currState.token,
				integrationId: currState.integrationName,
				teacherId: currState.teacherId
			})
		}
	}, [state])

	const signup = async () => {
		const {
			email,
			password,
			role,
			schoolId,
			privateTeacherData,
			token,
			nameFirst,
			nameLast,
			integrationId,
			teacherId
		} = signUpData
		let resultFromSignup = ''
		if (email && password && role && nameFirst && nameLast && !token) {
			try {
				const { data, errors } = await signupTeacher({
					variables: {
						email,
						password,
						role,
						address: privateTeacherData?.address,
						organizationName: privateTeacherData?.organizationName,
						schoolId,
						nameFirst,
						nameLast
					}
				})
				if (!errors?.length && data?.teacherSignup?.token)
					resultFromSignup = data.teacherSignup.token as string
			} catch (e) {
				console.error(e)
				setErrorDialogOpen(true)
			}
		} else if (role && token) {
			try {
				if (integrationId === Integration_Name_Enum.ClassLink && teacherId) {
					const { data, errors } = await completeTeacherIntegration({
						variables: {
							data: {
								teacherId,
								role,
								schoolId,
								address: privateTeacherData?.address,
								organizationName: privateTeacherData?.organizationName
							}
						}
					})
					if (!errors?.length && data?.completeTeacherIntegration?.token)
						resultFromSignup = data.completeTeacherIntegration.token as string
				} else {
					const { data, errors } = await signUpTeacherIntegration({
						variables: {
							token,
							role,
							schoolId,
							address: privateTeacherData?.address,
							organizationName: privateTeacherData?.organizationName
						}
					})
					if (!errors?.length && data?.teacherSignupIntegration?.token)
						resultFromSignup = data.teacherSignupIntegration.token as string
				}
			} catch (e) {
				console.error(e)
				setErrorDialogOpen(true)
			}
		}

		if (resultFromSignup) {
			const decodedToken = loginSignIn(resultFromSignup)
			if (decodedToken) {
				setRedirectToReferrer(true)
				setIntegrationName(integrationId)
			} else setErrorDialogOpen(true)
		}
	}

	if (redirectToReferrer === true) {
		const currState = state as LocationState
		console.info({ redirectToReferrer, currState })

		return <Redirect to={currState?.from || '/teacher'} />
	}

	return (
		<FixedBackground>
			<img
				src={NotesIcon}
				style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
			/>
			<SignUpMainDialog
				signUpDialogOpen={signUpLoginMainDialog}
				setSignUpDialogOpen={setSignUpLoginMainDialog}
				setSignUpData={setSignUpData}
				setSelectRoleDialogOpen={setSelectRoleDialogOpen}
				isLogin={false}
			/>
			<SelectRoleDialog
				isLoading={insertTeacherLoading || signupIntegrationLoading || completeTeacherLoading}
				open={selectRoleDialogOpen}
				integrationName={integrationName}
				setOpen={setSelectRoleDialogOpen}
				onClose={() => {
					setSelectRoleDialogOpen(false)
					setSignUpLoginMainDialog(true)
					setSignUpData({ ...signUpData, token: '' })
				}}
				setSignUpRole={(role: Role_Name_Enum) => {
					setSignUpData({ ...signUpData, role })
				}}
				setSchoolId={(schoolId: number) => {
					setSignUpData((prevState) => ({ ...prevState, schoolId }))
				}}
				setPrivateTeacherData={(organizationName: string, address: string) => {
					setSignUpData((prevState) => ({
						...prevState,
						privateTeacherData: { organizationName, address }
					}))
				}}
				onSignUp={signup}
				signUpData={signUpData}
			/>
			<InfoDialog
				open={errorDialogOpen}
				onClose={() => {}}
				onDiscard={() => {
					setErrorDialogOpen(false)
				}}
				icon="x"
				title={
					<Typography variant="h4">
						<b>Ups! something went wrong...</b>
					</Typography>
				}
				body="Something went wrong while signing up."
				onConfirm={() => {
					setSignUpData({})
					setSelectRoleDialogOpen(false)
					setErrorDialogOpen(false)
					setSignUpLoginMainDialog(true)
				}}
				confirmLabel="close"
			/>
		</FixedBackground>
	)
}
