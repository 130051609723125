import { Dispatch, SetStateAction, useCallback, FunctionComponent } from 'react'

import { Box, Button, Card, Fab, MenuItem, Typography } from '@material-ui/core'
import { RefreshOutlined } from '@material-ui/icons'
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import TimerIcon from '@material-ui/icons/Timer'
import { LineBreakIcon } from 'src/assets/icons'
import { BaseSlider } from 'src/components'
import { MenuButton } from 'src/components/Buttons/MenuButton/MenuButton'
import { InputHeader } from 'src/components/Inputs/InputHeader/InputHeader'
import { TimeSlider } from 'src/components/Slider/TimeSlider/TimeSlider'
import {
	MAX_AUDIO_SPEED_FAST,
	MAX_AUDIO_SPEED_SLOW,
	CHORD_QUALITIES,
	ROOT_CHORDS,
	DEFAULT_AUDIO_SPEED_STEPS
} from 'src/utils'

import { PreviewTimingDialog } from '../../../../components/PreviewTimingDialog'
import { SelectChordDialog } from '../../../../components/SelectChordDialog/SelectChordDialog'
import useStyles from './ChordSheetView.styles'
import { useChordSheetView } from './useChordSheetView'

const PIXELS_PER_MILISECOND = 20
const SLIDER_LEFT_OFFSET = 200

export const ChordSheetView: FunctionComponent = () => {
	const styles = useStyles()
	const {
		selectedChord,
		selectedQuality,
		setSelectedChord,
		setSelectedQuality,
		addChord,
		chords,
		breaks,
		addBreak,
		removeChord,
		removeBreak,
		preview,
		preview2,
		showChordsDialog,
		setShowChordsDialog,
		edittingChordIndex,
		setEdittingChordIndex,
		handleConfirmChordSelection,
		showChordsPreview,
		setShowChordsPreview,
		playing,
		togglePlayPause,
		durationInMiliseconds,
		durationInSeconds,
		speed,
		handleSpeedChange,
		audioUrl,
		resetSongTime,
		lyrics,
		withLyrics,
		time,
		audio,
		removeWord
	} = useChordSheetView()

	const handleRemoveAnchor = (
		index: number,
		method: (index: number) => void,
		setAnchor: Dispatch<SetStateAction<HTMLElement | null>>
	) => {
		method(index)
		setAnchor(null)
	}

	const buildRows = () => {
		const rows = [
			{
				icon: <LibraryMusicIcon className={styles.timeSliderSideButton} />,
				handleAdd: addChord,
				content: chords.map((chord, index) => ({
					time: chord.time,
					component: (
						<MenuButton label={chord.chord}>
							{({ setAnchor }) => (
								<>
									<MenuItem
										onClick={() =>
											handleRemoveAnchor(index, setEdittingChordIndex, setAnchor)
										}>
										Edit Chord
									</MenuItem>
									<MenuItem onClick={() => handleRemoveAnchor(index, removeChord, setAnchor)}>
										Delete Chord
									</MenuItem>
								</>
							)}
						</MenuButton>
					)
				}))
			},
			{
				icon: <LineBreakIcon className={styles.timeSliderSideButton} />,
				handleAdd: addBreak,
				content: breaks.map((time, index) => ({
					time,
					component: (
						<MenuButton
							key={0}
							className={styles.padding}
							label={<LineBreakIcon width={15} height={15} />}>
							{({ setAnchor }) => (
								<MenuItem onClick={() => handleRemoveAnchor(index, removeBreak, setAnchor)}>
									Delete Break
								</MenuItem>
							)}
						</MenuButton>
					)
				}))
			}
		]

		if (withLyrics && lyrics.length > 0) {
			rows.splice(1, 0, {
				icon: <LibraryMusicIcon className={styles.timeSliderSideButton} />,
				handleAdd: () => {},
				content: lyrics.map((lyric, index) => ({
					time: lyric.time,
					component: (
						<MenuButton label={lyric.text}>
							{({ setAnchor }) => (
								<MenuItem onClick={() => handleRemoveAnchor(index, removeWord, setAnchor)}>
									Delete word
								</MenuItem>
							)}
						</MenuButton>
					)
				}))
			})
		}

		return rows
	}

	const handleOnScrollToTime = useCallback(
		(time: number) => {
			audio.currentTime = time
		},
		[audio]
	)

	return (
		<Box className={styles.container}>
			<Box className={styles.chordSheetContainer}>
				<Box>
					<InputHeader name="Root Chord" />
					<Box className={styles.chordSheet}>
						{ROOT_CHORDS.map((current) => (
							<Button
								key={current}
								className={styles.chordButton}
								color={selectedChord === current ? 'secondary' : 'default'}
								onClick={() => setSelectedChord(current)}>
								{current}
							</Button>
						))}
					</Box>
				</Box>
				<Box>
					<InputHeader name="Quality" />
					<Box className={styles.chordSheet}>
						{CHORD_QUALITIES.slice(0, 11).map((current) => (
							<Button
								key={current}
								className={styles.chordButton}
								color={selectedQuality === current ? 'secondary' : 'default'}
								onClick={() =>
									setSelectedQuality((prev) => (prev === current ? undefined : current))
								}>
								{current}
							</Button>
						))}

						<Button variant="text" color="secondary" onClick={() => setShowChordsDialog(true)}>
							More
						</Button>
					</Box>
				</Box>

				<Box className={styles.dragChord}>
					<InputHeader name="Drag your chord" />
					<Button className={styles.previewButton}>
						{selectedChord}
						{selectedQuality ?? ''}
					</Button>
				</Box>
			</Box>

			<Box className={styles.editorContainer}>
				<Box className={styles.editorWrap}>
					<TimeSlider
						waveformProps={{
							mediaLength: durationInSeconds,
							fileSrc: audioUrl
						}}
						rows={buildRows()}
						sliderLeftOffset={SLIDER_LEFT_OFFSET}
						pxPerMs={0.2}
						time={time}
						onScrollToTime={handleOnScrollToTime}
						songDuration={durationInMiliseconds}
					/>

					<Box className={styles.controlsContainer}>
						<Fab size="medium" className={styles.bgWhite} onClick={togglePlayPause}>
							{playing ? <PauseIcon /> : <PlayArrowIcon />}
						</Fab>
						<Button className={styles.bgWhite} onClick={resetSongTime}>
							<RefreshOutlined />
						</Button>
						<Card className={styles.controlsCard}>
							<TimerIcon />
							<BaseSlider
								value={speed}
								onChange={handleSpeedChange}
								aria-labelledby="discrete-slider"
								color="secondary"
								valueLabelDisplay="off"
								step={DEFAULT_AUDIO_SPEED_STEPS}
								marks
								min={MAX_AUDIO_SPEED_SLOW}
								max={MAX_AUDIO_SPEED_FAST}
							/>
						</Card>
					</Box>
				</Box>
				<Box className={styles.previewChordsList}>
					<Box className={styles.previewChordsLabels}>
						<Typography>
							<b>Preview</b>
						</Typography>
						<Button variant="text" color="secondary" onClick={() => setShowChordsPreview(true)}>
							View Full
						</Button>
					</Box>
					<Card className={styles.chordPreview}>
						{!withLyrics ? (
							<ul>
								{preview.map((row, idx) => (
									<li key={idx}>
										{row.map(({ label }, key) => (
											<b key={key}>{label}</b>
										))}
									</li>
								))}
							</ul>
						) : (
							<ul>
								{preview2.map((array, i) => (
									<li key={i}>
										{array.map((set, j) => (
											<div key={j} className={styles.previewRow}>
												<div style={{ display: 'flex' }}>
													{set.chords.map((chord, k) => (
														<b key={k}>{chord.chord}</b>
													))}
												</div>
												<span>{set.text}</span>
											</div>
										))}
									</li>
								))}
							</ul>
						)}
					</Card>
				</Box>
			</Box>

			<SelectChordDialog
				open={showChordsDialog || edittingChordIndex !== undefined}
				onClose={() => setShowChordsDialog(false)}
				onConfirm={handleConfirmChordSelection}
				onDiscard={() => setShowChordsDialog(false)}
				chord={selectedChord}
				quality={selectedQuality}
			/>

			<PreviewTimingDialog
				open={showChordsPreview}
				onClose={() => setShowChordsPreview(false)}
				pxPerMs={PIXELS_PER_MILISECOND}
				audioURL={audioUrl as string}
				chordsData={chords.map((chord) => ({ chord: chord.chord, time: chord.time / 1000 }))}
			/>
		</Box>
	)
}
