import { ChangeEvent } from 'react'

import { useQuery } from '@apollo/client'
import {
	Box,
	FormControl,
	RadioGroup,
	Card,
	FormControlLabel,
	Radio,
	TextField,
	Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Autocomplete } from '@material-ui/lab'
import { BigBaseButton, MainTitle } from 'src/components'
import { DebouncedAutocomplete } from 'src/components/Inputs/DebouncedAutocomplete/DebouncedAutocomplete'
import InputFile from 'src/components/Inputs/InputFile'
import { InputHeader } from 'src/components/Inputs/InputHeader/InputHeader'
import { Select } from 'src/components/Inputs/Select/Select'
import { TextInput } from 'src/components/Inputs/TextInput/TextInput'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'
import { Artist, FileEnum, Grade } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { QueryCatalogItemsByCatalogId, QueryCatalogsByCatalogId } from 'src/scenes/Teacher/queries'
import { AutoCompleteStyle } from 'src/scenes/Teacher/scenes/17-Songs/scenes/UploadSong/components/CustomAutoComplete'
import { buildBreadCrumbs, CHORDS, sortGradeLevels, TypeEnum } from 'src/utils'

import useStyles from './UploadSong.styles'
import { useUploadSong } from './useUploadSong'

type ArtistSelectOptionType = Pick<Artist, 'name' | 'artist_id'>

export const UploadSong = () => {
	const styles = useStyles()
	const {
		formik,
		artists,
		scales,
		handleCancel,
		insertError,
		insertData,
		goToUploadTracks,
		goBackToSongs,
		gradesCatalog
	} = useUploadSong()

	const breadcrumbs = buildBreadCrumbs({
		page: Pages.SongUpload
	})

	const CATALOG_THEMES_ID = 3
	const CATALOG_GENRES_ID = 1
	const CATALOG_AGES_ID = 4

	const SONG_AGES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Song, CATALOG_AGES_ID)
	)
	const SONG_THEMES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Song, CATALOG_THEMES_ID)
	)

	const SONG_GENRES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Song, CATALOG_GENRES_ID)
	)

	const handleSelectedLevels = (_event: ChangeEvent<unknown>, levels: Grade[]) => {
		sortGradeLevels(levels)
		formik.setFieldValue('levels', levels)
	}

	return (
		<>
			<MainTitle breadcrumbs={breadcrumbs} title="Create a Song" />

			<Box className={styles.form}>
				<TextInput
					label="Song title"
					required
					placeholder="Slowly Getting There"
					name="title"
					value={formik.values.title}
					onFocus={() => formik.setFieldTouched('title')}
					error={Boolean(formik.errors.title) && formik.touched.title}
					helperText={formik.touched.title ? formik.errors.title : undefined}
					onChange={formik.handleChange}
				/>

				<InputHeader name="Artist" />
				<DebouncedAutocomplete<ArtistSelectOptionType | undefined, false, true, true>
					options={artists}
					getOptionLabel={(option) => option?.name ?? ''}
					inputMode="search"
					onChange={(_, value) => {
						formik.setFieldValue('artist_id', (value as ArtistSelectOptionType)?.artist_id as number)
					}}
					value={{
						name: artists.find((item) => item.artist_id === formik.values.artist_id)?.name ?? '',
						artist_id: formik.values.artist_id
					}}
					inputProps={{
						placeholder: 'Search for Artists'
					}}
				/>

				<InputHeader name="Album art" />
				<InputFile.Container
					advise={'Accepted Format: JPG, PNG. Max Size: 200mb'}
					placeholder="Upload an Image"
					type={FileEnum.Images}
					handleFileUrl={(key) => {
						formik.setFieldValue('image_path', key)
					}}
					isTemporal={false}
				/>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={styles.fieldTitle}>
							<b>Themes</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-theme',
							className: styles.chip,
							deleteIcon: (
								<Typography data-cy="remove-themes-selected" className={styles.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: styles.listBox
						}}
						multiple
						options={SONG_THEMES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.themes}
						onChange={(_, value) => {
							formik.setFieldValue('themes', value)
						}}
						closeIcon={<CloseIcon data-cy="themes-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								className={styles.whiteBg}
								{...params}
								data-cy="upload-song-themes"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={styles.fieldTitle}>
							<b>Genres</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-genre',
							className: styles.chip,
							deleteIcon: (
								<Typography data-cy="remove-genres-selected" className={styles.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: styles.listBox
						}}
						multiple
						options={SONG_GENRES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.genres}
						onChange={(_, value) => {
							formik.setFieldValue('genres', value)
						}}
						closeIcon={<CloseIcon data-cy="genres-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								className={styles.whiteBg}
								{...params}
								data-cy="upload-song-genres"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={styles.fieldTitle}>
							<b>Ages</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-ages',
							className: styles.chip,
							deleteIcon: (
								<Typography data-cy="remove-ages-selected" className={styles.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: styles.listBox
						}}
						multiple
						options={SONG_AGES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.ages}
						onChange={(_, value) => {
							formik.setFieldValue('ages', value)
						}}
						closeIcon={<CloseIcon data-cy="ages-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								className={styles.whiteBg}
								{...params}
								data-cy="upload-video-ages"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={styles.fieldTitle}>
							<b>Grade Level*</b>
						</Typography>
					</Box>
					<Autocomplete
						className={styles.whiteBg}
						ChipProps={{
							'data-cy': 'selected-level',
							className: styles.chip,
							deleteIcon: (
								<Typography data-cy="remove-levels-selected" className={styles.closeChipIcon}>
									x
								</Typography>
							)
						}}
						multiple
						disableCloseOnSelect
						autoHighlight
						ListboxProps={{
							className: styles.listBox,
							style: AutoCompleteStyle
						}}
						options={gradesCatalog.data?.grade || []}
						value={formik.values.levels}
						onChange={handleSelectedLevels}
						closeIcon={<CloseIcon data-cy="levels-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								onFocus={() => formik.setFieldTouched('levels')}
								{...params}
								data-cy="upload-lesson-levels"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
								error={!!formik.errors.levels && !!formik.touched.levels}
								helperText={
									!!formik.errors.levels && formik.touched.levels ? formik.errors.levels : ''
								}
							/>
						)}
					/>
				</Box>

				<Box className={styles.keyAndScaleSection}>
					<Box>
						<InputHeader name="Song Key" />
						<Select
							options={CHORDS.map((value) => ({ name: value, value }))}
							value={formik.values.key}
							onChange={(event) => {
								formik.setFieldValue('key', event.target.value)
							}}
						/>
					</Box>
					<Box>
						<InputHeader name="Mode" />

						<FormControl component="fieldset">
							<RadioGroup
								aria-label="scale id"
								name="scale_id"
								onChange={(event) => formik.setFieldValue('scale_id', Number(event.target.value))}
								value={formik.values.scale_id}
								className={styles.radios}>
								{scales.map((scale) => (
									<Card key={scale.scale_id}>
										<FormControlLabel
											className={styles.modeCard}
											value={scale.scale_id}
											control={<Radio />}
											label={scale.name}
										/>
									</Card>
								))}
							</RadioGroup>
						</FormControl>
					</Box>
				</Box>

				<Box className={styles.buttonsSection}>
					<BigBaseButton className={styles.button} onClick={handleCancel}>
						Cancel
					</BigBaseButton>
					<BigBaseButton
						color="secondary"
						className={styles.button}
						disabled={formik.isSubmitting || !formik.isValid}
						onClick={formik.submitForm}>
						Create Song
					</BigBaseButton>
				</Box>
			</Box>

			<MutationDialog
				open={false}
				error={!!insertError}
				success={!!insertData?.insert_song_one}
				icon="question"
				successProps={{
					title: `Your Song has been created!`,
					body: `You have to add tracks to this Song`,
					discardButton: {
						onClick: goBackToSongs,
						label: 'Back to Songs'
					},
					confirmButton: {
						onClick: goToUploadTracks,
						label: 'Add Tracks'
					}
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while creating the Song.`,
					icon: 'error'
				}}
			/>
		</>
	)
}
