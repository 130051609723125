import { createStyles, makeStyles } from '@material-ui/core'

export const useSongUploadsStyles = makeStyles(() =>
	createStyles({
		seeMore: { cursor: 'pointer', textAlign: 'end', marginTop: 20 },
		boxLoading: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}
	})
)

export default useSongUploadsStyles
