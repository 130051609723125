import { Fragment, useEffect, useState } from 'react'

import { Box, Link, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { Link as LinkIcon, Add as AddIcon } from 'src/assets/icons'
import { BigBaseButton, IconActionOptionType, InfoDialog, MainTitle, Stepper } from 'src/components'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, buildRouteParameters } from 'src/utils'

import { LinkVideo, UploadFile, UploadVideoForm, OptionButton } from '../../components'
import useStyles from './VideoUpload.styles'

export const VideoUpload = ({ page }: PagesProps) => {
	const history = useHistory()
	const styles = useStyles()

	const OPTIONS: Pick<IconActionOptionType, 'id' | 'title' | 'icon'>[] = [
		{
			id: 'Upload',
			title: 'Upload Your Video',
			icon: <img src={AddIcon} />
		},
		{
			id: 'Link',
			title: 'Link Your Video',
			icon: <img src={LinkIcon} />
		}
	]
	const [optionSelected, setOptionSelected] = useState('')
	const [url, setUrl] = useState<string>()
	const [nextEnabled, setNextEnabled] = useState<boolean>(false)
	const [title, setTitle] = useState<string>('')
	const [thumbnail, setThumbnail] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [currentStep, setCurrentStep] = useState(0)
	const [onCancel, setOnCancel] = useState(false)

	const goToPreviousPage = (): void => {
		history.push(buildRouteParameters(Pages.Videos))
	}

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: []
	})

	const handleSelectOption = (opt: string) => {
		setOptionSelected(opt)
		setNextEnabled(true)
	}

	const handleLinkVideo = (data: {
		url: string
		title: string
		description: string
		image_path: string
		terms: boolean
	}) => {
		setUrl(data.url)
		setTitle(data.title)
		setDescription(data.description)
		setThumbnail(data.image_path)
		if (data.terms) {
			setNextEnabled(true)
		}
	}

	const handleFileUrl = (data: { url: string; terms: boolean }) => {
		setUrl(data.url)
		if (data.terms) {
			setNextEnabled(true)
		}
	}

	const steps = [
		{
			step: 0,
			stepName: 'Choose an Option'
		},
		{
			step: 1,
			stepName: `${optionSelected} Your Video`
		},
		{
			step: 2,
			stepName: `View Details`
		}
	]

	// scroll to top
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const handleCancel = () => setOnCancel(true)

	const handleNext = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1)
			setNextEnabled(false)
		}
	}

	const ControllerButtons = () => (
		<Box className={styles.buttonDisabled}>
			<BigBaseButton className={styles.bigButton} variant="contained" color="default" onClick={handleCancel}>
				<Typography variant="caption">Cancel</Typography>
			</BigBaseButton>

			<BigBaseButton
				className={styles.bigButton}
				color="secondary"
				disabled={!nextEnabled}
				onClick={handleNext}>
				<Typography variant="caption">Next</Typography>
			</BigBaseButton>
		</Box>
	)

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={
					currentStep > 0
						? OPTIONS.find((opt) => opt.id === optionSelected)?.title ?? page.name
						: page.name
				}
				description={
					<Typography className={styles.terms}>
						Upload or link a Video and assing it to a Class to share it with students! By uploading a
						Video you confirm that you are complying with all the legal guidelines set forth in our{' '}
						<Link color="secondary" target="_blank" href="https://www.wurrlyedu.com/terms">
							Terms of Use
						</Link>
						. If you have any questions, please refer to our email{' '}
						<Link color="secondary" target="_blank" href="mailto:support@wurrlyedu.com">
							support@wurrlyedu.com
						</Link>
					</Typography>
				}
			/>
			<Box marginBottom="60px">
				<Stepper
					stepDescription={steps.find((step) => step.step === currentStep)?.stepName ?? ''}
					currentStep={currentStep}
					totalSteps={steps.length - 1}
					stepBoxProps={{ mt: 6 }}
				/>
			</Box>
			{currentStep === 0 && (
				<Fragment>
					<div className={styles.containerOptions}>
						{OPTIONS.map((option) => (
							<div className={styles.boxOption} key={option.id}>
								<Typography className={styles.boxTitle}>
									<strong>{option.title}</strong>
								</Typography>
								<OptionButton
									value={option.id}
									selected={optionSelected === option.id}
									onChange={handleSelectOption}>
									{option.icon}
								</OptionButton>
							</div>
						))}
					</div>

					<ControllerButtons />
				</Fragment>
			)}
			{currentStep === 1 && (
				<Fragment>
					{optionSelected === 'Link' ? (
						<LinkVideo handleLink={handleLinkVideo} setNextEnabled={setNextEnabled} />
					) : (
						<UploadFile handleFileUrl={handleFileUrl} />
					)}
					<ControllerButtons />
				</Fragment>
			)}
			{currentStep === 2 && (
				<UploadVideoForm
					title={optionSelected === 'Link' ? title : undefined}
					description={optionSelected === 'Link' ? description : undefined}
					thumbnail={optionSelected === 'Link' ? thumbnail : undefined}
					url={url ?? ''}
				/>
			)}
			<InfoDialog
				open={onCancel}
				onConfirm={() => setOnCancel(false)}
				onClose={() => setOnCancel(false)}
				onDiscard={goToPreviousPage}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				confirmLabel="No, Cancel"
				icon="!"
			/>
		</Box>
	)
}
