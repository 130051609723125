import { URL_CONTACT_US } from 'src/utils'

import { BaseLink } from '../..'
import { useStyles } from './ContactButton.styles'
export const ContactButton = ({ font = 16, marginLeft = 0 }: { font?: number; marginLeft?: number }) => {
	const classes = useStyles({ font, marginLeft })

	return (
		<>
			<BaseLink>
				<a className={classes.defaultStyle} href={URL_CONTACT_US}>
					Contact us
				</a>
			</BaseLink>
		</>
	)
}
