import { makeStyles } from '@material-ui/core'

export const useFormItemStyles = makeStyles((theme) => ({
	formItemContainer: {
		marginTop: '2em'
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '.5em'
	},
	extraInfo: {
		color: theme.palette.text.secondary
	},
	contentContainer: {
		width: '100%'
	}
}))

export default useFormItemStyles
