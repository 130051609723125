import { buildGenericContext } from 'src/context/genericContext'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'

const useClassSongs = () => {
	const params = useWurrlyParams<typeof Pages.ClassSongs.params[number]>()
	const classId = Number(params.classId)

	return {
		classId,
		// some component use it but, should be re evaluated
		teacherId: 0
	}
}

export const [ClassSongsProvider, useClassSongsContext] = buildGenericContext(useClassSongs)
