import { useEffect, useState } from 'react'

import { Box, LinearProgress, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'

import { getAudioWaveform } from './utils'
import useStyles from './Waveform.styles'

export type WaveformProps = {
	fileSrc?: string
	mediaLength?: number
}
type DataType = {
	min: number
	max: number
}

export const Waveform = ({ fileSrc, mediaLength }: WaveformProps) => {
	const styles = useStyles()
	const [waveformData, setWaveformData] = useState<DataType[] | undefined>()
	const [isLoading, setIsLoading] = useState(true)
	const [hasError, setHasError] = useState(false)

	useEffect(() => {
		if (!fileSrc || !mediaLength || !isFinite(mediaLength)) return

		const getWaveform = async () => {
			setIsLoading(true)
			try {
				const waveformDataRes = await getAudioWaveform({ fileSrc, videoLength: mediaLength })
				const data = [] as DataType[]

				for (let i = 0; i < waveformDataRes.min.length; i++) {
					data.push({
						min: waveformDataRes.min[i],
						max: waveformDataRes.max[i]
					})
				}
				setWaveformData(data)
				setHasError(false)
			} catch (error) {
				console.error('Could not get waveform data', error)
				setHasError(true)
			} finally {
				setIsLoading(false)
			}
		}
		getWaveform()
	}, [fileSrc, mediaLength])

	return (
		<Box className={styles.backgroundContainer}>
			{(hasError || !fileSrc) && (
				<Box className={styles.errorContainer}>
					<ErrorIcon className={styles.errorIcon} />
					<Typography>Couldn't generate waveform</Typography>
				</Box>
			)}
			{!hasError && !isLoading && waveformData && (
				<ResponsiveContainer width="100%" height="80%">
					<AreaChart data={waveformData}>
						<Area type="monotone" dataKey="min" stroke="#cccccc" fill="#cccccc" />
						<Area type="monotone" dataKey="max" stroke="#cccccc" fill="#cccccc" />
					</AreaChart>
				</ResponsiveContainer>
			)}
			{isLoading && <LinearProgress className={styles.loader} />}
		</Box>
	)
}
