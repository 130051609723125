import { useState } from 'react'

import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PersonIcon } from 'src/assets/icons'
import { InfoDialog } from 'src/components'
import { useActivateTeacherMutation } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { LoginPages } from 'src/routes/loginPages'

type AccountActivationDialogProps = {
	token?: string
}
export const AccountActivationDialog = ({ token }: AccountActivationDialogProps) => {
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const [errorMessage, setErrorMessage] = useState<string>()
	const [activationSucces, setActivationSucces] = useState(false)
	const [activateTeacher, { loading: isActivateTeacherLoading }] = useActivateTeacherMutation()

	const handleActivate = async () => {
		if (!token) return

		try {
			const activateAccountResult = await activateTeacher({
				variables: {
					filter: {
						token
					}
				},
				update: (cache) => {
					cache.evict({
						id: `teacher:${teacher.teacher_id}`,
						fieldName: 'is_active'
					})
				}
			})
			if (!activateAccountResult.data?.activateTeacher?.success) {
				throw new Error('Something went wrong. Please try again.')
			}
			setActivationSucces(true)
		} catch (error) {
			console.error('Could not activate account:', error)
			setErrorMessage(String(error))
		}
	}

	const goToLogin = () => {
		history.push(LoginPages.Teacher.path)
	}

	return (
		<>
			<InfoDialog
				open={!activationSucces}
				icon={<PersonIcon height={'70%'} width={'70%'} />}
				title={!isActivateTeacherLoading ? 'Activate Account?' : 'Activating'}
				onConfirm={handleActivate}
				confirmLabel="Activate"
				body={isActivateTeacherLoading ? <CircularProgress color="secondary" size={30} /> : <></>}
			/>

			<InfoDialog
				icon="error"
				open={!!errorMessage}
				title="Could not activate account"
				body={<Typography>{errorMessage}</Typography>}
			/>
			<InfoDialog
				icon="success"
				open={activationSucces}
				title="Your account has been Activated!"
				onConfirm={goToLogin}
				confirmLabel="Go To Login"
				body={<></>}
			/>
		</>
	)
}
