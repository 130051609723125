import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'

type SelectWurrliesFilterProps = {
	value: Wurrly_Type_Enum
	onChange?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => void
}

export const SelectWurrliesFilter = ({ value, onChange }: SelectWurrliesFilterProps) => {
	return (
		<Box display="flex" alignItems="center">
			<Typography style={{ marginRight: '10px' }}>Filter</Typography>
			<Select
				options={Object.entries(Wurrly_Type_Enum).map(([name, value]) => ({ name, value }))}
				onChange={onChange}
				value={value}
			/>
		</Box>
	)
}
