import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { useWurrlyParams } from 'src/hooks'
import { StudentPages } from 'src/routes/studentpages'
import { LocationState } from 'src/scenes/Student/scenes/3-MusicCatalog/types'

export const useClassIdParam = () => {
	const { classId: paramClassId } = useWurrlyParams<typeof StudentPages.MusicCatalog.params[number]>()
	const { classId: catalogParamClassId } = useWurrlyParams<typeof StudentPages.CatalogItemSongs.params[number]>()
	const [classId, setClassId] = useState(0)
	const [gotParamFromState, setGotParamFromState] = useState(false)
	const location = useLocation()

	useEffect(() => {
		if (paramClassId || catalogParamClassId) {
			setClassId(paramClassId || catalogParamClassId)
			setGotParamFromState(false)
		} else if (location?.state) {
			const { classId } = location?.state as LocationState
			if (classId) setClassId(classId)
			setGotParamFromState(true)
		}
	}, [location, paramClassId])

	return { classId, gotParamFromState }
}
