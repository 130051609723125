import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useGetChannelDataLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Maybe, Playlist, Product_Name_Enum, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { useProfileSubscription } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useProfileSubscription'
import { buildBreadCrumbs } from 'src/utils'

export const useChannelPage = () => {
	const params = useWurrlyParams<typeof Pages.ChannelPage.params[number]>()
	const { channelId } = params
	const { data, loading } = useProfileSubscription()

	const [playlistData, setPlaylistData] = useState<Playlist[]>([])
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [startedPlaying, setStartedPlaying] = useState(false)
	const [expiresAt, setExpiresAt] = useState<string>()
	const [showExpiresAt, setShowExpiresAt] = useState<boolean>(false)
	const [addToClassDialog, setAddToClassDialog] = useState<Playlist>()
	const [isAddPlaylistToClassOpen, setIsAddPlaylistToClassOpen] = useState(false)
	const [product, setProduct] = useState<{ id: number; productName: Product_Name_Enum }>()

	const [getChannel, { data: channelData, loading: loadingChannel }] = useGetChannelDataLazyQuery()

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault()
	const handleOnPlay = () => {
		setStartedPlaying(true)
	}

	const [isOpen, setIsOpen] = useState(false)

	const breadcrumbs = buildBreadCrumbs({
		page: Pages.ChannelPage,
		replaceIndexes: [
			{
				index: channelId,
				overrideName: channelData?.channel[0]?.title || ''
			}
		]
	})

	useEffect(() => {
		if (channelId) getChannel({ variables: { channelId } })
	}, [channelId])

	useEffect(() => {
		if (channelData) {
			const playlists =
				channelData.channel[0]?.channel_playlists.map((playlist) => playlist.playlist as Playlist) || []
			setPlaylistData(playlists)
		}
	}, [channelId])

	useEffect(() => {
		if (channelData && !loadingChannel) {
			const playlists =
				channelData.channel[0]?.channel_playlists.map((playlist) => playlist.playlist as Playlist) || []
			setPlaylistData(playlists)
			setSubscription(channelData.channel[0]?.is_purchased_by_teacher)
			setVisibility(channelData.channel[0]?.lockable_content.visibility)
		}
	}, [channelData, loadingChannel])

	useEffect(() => {
		if (data && !loading) {
			const url = window.location.href
			const matchingElement = data.find((element) => {
				return url.includes(`${element.productType}s/${element.productId}`)
			})
			const currentDate = moment()
			const targetDate = moment(matchingElement?.expiresAt)
			const duration = moment.duration(targetDate.diff(currentDate))
			const days = duration.asDays()
			setExpiresAt(days.toFixed(0))
			setShowExpiresAt(!matchingElement || duration.asDays() < 0 ? false : true)
		}
	}, [data])

	return {
		playlistData,
		handleContextMenu,
		startedPlaying,
		handleOnPlay,
		visibility,
		subscription,
		handleOpenProductDialog,
		handleCloseProductDialog,
		isOpen,
		expiresAt,
		showExpiresAt,
		addToClassDialog,
		setAddToClassDialog,
		isAddPlaylistToClassOpen,
		setIsAddPlaylistToClassOpen,
		product,
		setProduct,
		breadcrumbs,
		channelData,
		loadingChannel,
		channelId
	}
}
