import { Fragment, useEffect, useState } from 'react'

import { Box, Checkbox, Chip, InputAdornment, TextField, Typography, styled } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import {
	OptionsContainer,
	ScrollSectionWrapper,
	StyledPaper
} from 'src/components/Select/SelectMusicStandard/SelectMusicStandard'
import {
	Category_Standard_Enum,
	Standard,
	Standard_Option,
	Standard_Subcategory
} from 'src/graphql/autogenerate/schemas'
import {
	CustomTooltip,
	stepFourTooltipContent
} from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload/Tooltips'
import { LEVELS } from 'src/utils'

type StandardsType = {
	standard: Standard
	changeData: (field: string, value: unknown, shouldValidate?: boolean) => void
	initialValues?: number[]
}
type selectedItemsType = {
	title: string
	standar_option_id: number
}
type OptionProps = {
	item: Standard_Option
	toggleItem: (item: Standard_Option) => void
	isItemSelected: (item: Standard_Option) => boolean
}
type NestedOptionsProps = {
	options: Standard_Option[]
	toggleItem: (item: Standard_Option) => void
	isItemSelected: (item: Standard_Option) => boolean
}
type CategoryItemProps = {
	item: Category_Standard_Enum
	onMouseEnter: () => void
	hoverItem: string | undefined
}

type SubCategoryItemProps = {
	item: Standard_Subcategory
	onMouseEnter: () => void
	getOptionLabel: (option: Standard_Subcategory) => string
	hoverSubItem: string | undefined
}
export const Standards = ({ standard, changeData, initialValues }: StandardsType) => {
	const [hoverItem, setHoverItem] = useState<string | undefined>(undefined)
	const [hoverSubItem, setHoverSubItem] = useState<string | undefined>(undefined)
	const [options, setOptions] = useState<Standard>({} as Standard)
	const [nestedOptions, setNestedOptions] = useState<Standard_Option[]>([])
	const [selectedItems, setSelectedItems] = useState<selectedItemsType[]>([])
	const [open, setOpen] = useState<boolean>(false)
	const [search, setSearch] = useState<string>('')
	const [searchResults, setSearchResults] = useState<Standard_Option[]>([])
	const categoryStandards = [
		Category_Standard_Enum.Connecting,
		Category_Standard_Enum.Creating,
		Category_Standard_Enum.Performing,
		Category_Standard_Enum.Responding
	]
	const handleChipDelete = (value: selectedItemsType) => {
		setSelectedItems(selectedItems.filter((x) => x.standar_option_id !== value.standar_option_id))
	}

	const handleHover = (item: Category_Standard_Enum) => {
		setHoverItem(item)
	}

	const isItemSelected = (item: Standard_Option): boolean => {
		return selectedItems.some((category) => category.standar_option_id === item.standar_option_id)
	}

	const toggleItem = (item: Standard_Option) => {
		if (isItemSelected(item)) {
			setSelectedItems(selectedItems.filter((x) => x.standar_option_id !== item.standar_option_id))
		} else {
			setSelectedItems([
				...selectedItems,
				{ title: item.title, standar_option_id: item.standar_option_id } as selectedItemsType
			])
		}
		if (search !== '') setSearch('')
	}

	const getOptionLabel = (option: Standard_Subcategory): string => {
		let label = option.name
		if (option.name in selectedItems) label += ` (${selectedItems.length})`

		return label
	}

	useEffect(() => {
		setNestedOptions(
			options.standard_subcategories?.find(({ name }) => name === hoverSubItem)?.standar_options ?? []
		)
	}, [hoverSubItem])

	useEffect(() => {
		setSearchResults(
			options.standard_subcategories
				?.map(({ standar_options }) =>
					standar_options.filter((x) => x.title?.toLowerCase().includes(search.toLowerCase()))
				)
				.flat()
		)
	}, [search])

	useEffect(() => {
		setOptions(standard)
	}, [standard, setOptions])

	useEffect(() => {
		if (initialValues) {
			setSelectedItems(
				initialValues
					.map((x) =>
						standard.standard_subcategories
							?.map(({ standar_options }) =>
								standar_options.filter((e) => e.standar_option_id === x)
							)
							.flat()
					)
					.flat()
					.map((x) => {
						return { title: x.title, standar_option_id: x.standar_option_id } as selectedItemsType
					})
			)
		}
	}, [])
	useEffect(() => {
		if (selectedItems.length > 0) {
			changeData(
				'process',
				selectedItems.map((x) => x.standar_option_id)
			)
		}
	}, [selectedItems])

	return (
		<>
			<Box marginTop="40px">
				<Box display="flex" justifyContent="space-between" mt={5}>
					<Typography>{standard.description}</Typography>
					<CustomTooltip content={stepFourTooltipContent.performing} />
				</Box>
				<TextField
					style={{ width: '100%' }}
					onClick={() => setOpen(!open)}
					data-cy="upload-lesson-levels"
					variant="outlined"
					color="secondary"
					placeholder={selectedItems.length > 0 ? '' : 'Select all that apply'}
					value={search}
					onChange={(value) => {
						setSearch(value.target.value)
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<ArrowDropDownIcon></ArrowDropDownIcon>
							</InputAdornment>
						),
						startAdornment: selectedItems.map((value) => (
							<Chip
								key={value.standar_option_id}
								label={value.title}
								onDelete={() => handleChipDelete(value)}
							/>
						))
					}}
				/>
				{search && searchResults?.length > 0 && (
					<ScrollSectionWrapper>
						<StyledPaper>
							{searchResults.slice(0, 10).map((item) => {
								return (
									<Option
										key={item.standar_option_id}
										item={item}
										toggleItem={toggleItem}
										isItemSelected={isItemSelected}
									/>
								)
							})}
						</StyledPaper>
					</ScrollSectionWrapper>
				)}
				{open && !search && (
					<OptionsContainer
						onMouseLeave={() => {
							setHoverItem(undefined)
							setOpen(false)
						}}>
						<ScrollSectionWrapper>
							<StyledPaper>
								{categoryStandards.map((category) => {
									return (
										<CategoryItem
											hoverItem={hoverItem}
											key={category}
											item={category}
											onMouseEnter={() => handleHover(category)}
										/>
									)
								})}
							</StyledPaper>
						</ScrollSectionWrapper>
						{hoverItem && (
							<OptionsContainer onMouseLeave={() => setHoverSubItem(undefined)}>
								<ScrollSectionWrapper>
									<StyledPaper>
										{options.standard_subcategories
											.filter((x) => x.category_standard === hoverItem)
											.map((item) => {
												return (
													<SubCategoryItem
														getOptionLabel={getOptionLabel}
														hoverSubItem={hoverSubItem}
														key={item.name}
														item={item}
														onMouseEnter={() => setHoverSubItem(item.name)}
													/>
												)
											})}
									</StyledPaper>
								</ScrollSectionWrapper>
								{hoverSubItem && (
									<ScrollSectionWrapper>
										<StyledPaper>
											<NestedOptions
												options={nestedOptions}
												toggleItem={toggleItem}
												isItemSelected={isItemSelected}
											/>
										</StyledPaper>
									</ScrollSectionWrapper>
								)}
							</OptionsContainer>
						)}
					</OptionsContainer>
				)}
			</Box>
		</>
	)
}

const SubCategoryItem = ({ item, onMouseEnter, getOptionLabel, hoverSubItem }: SubCategoryItemProps) => {
	return (
		<StyledSubCategory
			onMouseEnter={onMouseEnter}
			style={{ backgroundColor: hoverSubItem === item.name ? '#F6F6F7' : 'inherit' }}>
			<Row>
				<OptionTitle>
					<strong>{getOptionLabel(item)}</strong>
				</OptionTitle>
				<ArrowRightIcon fontSize="small" />
			</Row>
			<OptionDescription>
				{item.description}
				<strong> Enduring understanding: </strong>
				{item.enduring_understanding}
			</OptionDescription>
		</StyledSubCategory>
	)
}

const CategoryItem = ({ item, onMouseEnter, hoverItem }: CategoryItemProps) => {
	return (
		<StyledSubCategory
			onMouseEnter={onMouseEnter}
			style={{ backgroundColor: hoverItem === item ? '#F6F6F7' : 'inherit' }}>
			<RowCategory>
				<OptionTitle>
					<strong>{item}</strong>
				</OptionTitle>
				<ArrowRightIcon fontSize="small" />
			</RowCategory>
		</StyledSubCategory>
	)
}

export const Option = ({ item, toggleItem, isItemSelected }: OptionProps) => {
	const handleChange = () => {
		toggleItem(item)
	}

	return (
		<StyledOption onClick={handleChange}>
			<Row>
				<OptionTitle>
					<strong>{item.title}</strong>
				</OptionTitle>
				<Checkbox checked={isItemSelected(item)} style={{ padding: 0 }} />
			</Row>
			<OptionDescription>{item.description}</OptionDescription>
		</StyledOption>
	)
}

const NestedOptions = ({ options, isItemSelected, toggleItem }: NestedOptionsProps) => {
	return (
		<>
			{LEVELS.map((level, i) => {
				if (!options.find((x) => x.grade?.name === level)) return

				return (
					<Fragment key={`${level}_${i}`}>
						<Group>
							<LevelBadge>
								<Typography variant="h1">
									<strong>{level}</strong>
								</Typography>
							</LevelBadge>
							<Column>
								{options
									.filter((x) => x.grade?.name === level)
									.map((item, i) => (
										<Option
											isItemSelected={isItemSelected}
											toggleItem={toggleItem}
											item={item}
											key={`option_${item.standar_option_id}_${item.title}_${i}`}
										/>
									))}
							</Column>
						</Group>

						<hr
							style={{
								borderTop: '1px solid rgba(0, 0, 0, 8%)',
								borderRight: 'none',
								borderBottom: 'none',
								borderLeft: 'none',
								width: '90%'
							}}
						/>
					</Fragment>
				)
			})}
		</>
	)
}

const StyledSubCategory = styled(Box)({
	width: '100%',
	padding: '1.5em',
	wordBreak: 'break-word',

	'&:hover': {
		backgroundColor: '#F6F6F7'
	}
})

const OptionTitle = styled(Typography)({
	color: '#1D273D',
	fontSize: '18px',
	marginBottom: '.1em'
})
const OptionDescription = styled(Typography)({
	fontSize: '14px',
	color: '#9CA0AA',
	lineHeight: '120%',

	'& strong': {
		fontWeight: 'bold'
	}
})

const Row = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	'& .MuiSvgIcon-root': {
		fill: '#757575'
	}
})

const RowCategory = styled(Box)({
	display: 'flex',
	height: '65px',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	'& .MuiSvgIcon-root': {
		fill: '#757575'
	}
})

const Column = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	width: '100%'
})

const StyledOption = styled(Box)({
	width: '100%',
	padding: '.25em 1.5em .25em 1em',
	wordBreak: 'break-word',

	'&:hover': {
		backgroundColor: '#F6F6F7'
	}
})

const LevelBadge = styled(Box)({
	width: '48px',
	height: '48px',
	backgroundColor: 'rgba(29, 39, 61, 0.16)',
	marginTop: '10px',
	borderRadius: '10px',
	display: 'flex',
	flex: '0 0 48px',
	alignItems: 'center',
	justifyContent: 'center',

	'& h1': {
		fontSize: '18px'
	}
})

const Group = styled(Box)({
	display: 'flex',
	padding: '10px 0 0 1.5em',
	alignItems: 'flex-start'
})
