import { createStyles, makeStyles } from '@material-ui/core'

export const useInsightsEmptyStateStyles = makeStyles(() =>
	createStyles({
		divider: {
			margin: '1em 0'
		},
		emptyState: {
			height: '10em',
			padding: '4em 0',
			justifyContent: 'center'
		}
	})
)

export default useInsightsEmptyStateStyles
