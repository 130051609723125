import { makeStyles } from '@material-ui/core'

import { DatetimeProps } from './Datetime'

const handleColor = ({ color }: { color: DatetimeProps['color'] }) => {
	if (color === 'primary') {
		return '#9e9e9e'
	} else if (color === 'secondary') {
		return '#ffc20c'
	}

	return '#9e9e9e'
}

export const useDatetimeStyles = makeStyles({
	date: {
		color: '#9e9e9e',
		display: 'flex',
		'& svg': {
			color: handleColor,
			marginRight: 7
		}
	}
})

export default useDatetimeStyles
