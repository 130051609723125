import { Fragment, useEffect, useState } from 'react'

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { client } from 'src/apollo/client'
import { StorageEnum } from 'src/utils'

import { InfoDialog } from '..'
import { LogoutIcon } from '../../assets/icons'

export const LogoutButton = () => {
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
	const [loading, setLoading] = useState(false)

	const handleClose = () => !loading && setIsConfirmDialogOpen(false)
	const handleOpen = () => !loading && setIsConfirmDialogOpen(true)

	useEffect(() => {
		const realHandleLogout = async () => {
			await client.clearStore()
			setTimeout(() => {
				setLoading(false)
				setIsConfirmDialogOpen(false)
				window.location.reload()
			}, 5000)
		}
		if (loading && !localStorage.getItem(StorageEnum.loginToken)) realHandleLogout()
	}, [localStorage, loading])

	const title = (
		<b>{!loading ? 'Are you sure you want to logout?' : 'We are logging you out... Wait a moment'}</b>
	)

	const handleLogout = () => {
		setLoading(true)
		localStorage.setItem(StorageEnum.loginToken, '')
	}

	return (
		<Fragment>
			<ListItem button onClick={handleOpen}>
				<ListItemIcon>
					<LogoutIcon />
				</ListItemIcon>
				<ListItemText primary="Logout" />
			</ListItem>
			<InfoDialog
				open={isConfirmDialogOpen}
				onClose={handleClose}
				icon="?"
				title={title}
				body=""
				discardLabel="Go back"
				onDiscard={handleClose}
				confirmLabel="Logout"
				onConfirm={handleLogout}
				confirmProps={{ style: { fontWeight: 'bold' }, disabled: loading }}
				discardProps={{ style: { fontWeight: 'bold' }, disabled: loading }}
			/>
		</Fragment>
	)
}
