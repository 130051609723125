import { forwardRef, Fragment, useMemo, useState } from 'react'

import { Box, CircularProgress, Divider, List, Typography } from '@material-ui/core'
import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import { cache } from 'src/apollo/state'
import { SongPlayer } from 'src/components'

import {
	useDeleteSongByPkMutation,
	useDeleteStudentSongFavoriteMutation,
	useDeleteTeacherSongFavoriteMutation,
	useInsertSongToClassMutation,
	useInsertStudentSongFavoriteMutation,
	useInsertTeacherSongFavoriteMutation
} from '../graphql/autogenerate/hooks'
import { Class, Song } from '../graphql/autogenerate/schemas'
import { useLoginContext } from '../hooks/useLogin'
import { Pages } from '../routes/teacherPages'
import { LessonsSong } from '../scenes/Teacher/scenes/7-MusicCatalog/components/LessonsSong/index'
import { BLANK_SONG_TITLE, buildImagePath, buildRouteParameters, concatenate, getSongGenres } from '../utils'
import { getCyKey } from '../utils/utils'
import { AddToClassDialog } from './Dialogs/AddToClassDialog'
import { DeleteItemDialog } from './Dialogs/DeleteItemDialog'
import { RemoveFavoriteDialog } from './Dialogs/RemoveFavoriteDialog'
import { ListItemSong } from './ListItemSong'

const useStyles = makeStyles((theme: Theme) => ({
	boxSongs: {
		padding: 0,
		'& .itemBox': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	},
	boxLoading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.common.black, 0.1)
	}
}))
type ListSongsProps = {
	teacherId: number
	studentId?: number
	classId?: number
	spacing?: number
	lineDivider?: boolean
	data: Song[]
	selectedChords?: string[]
	allTranspositions: boolean
	lessonsButton?: boolean
	removeButton?: boolean
	editButton?: boolean
	viewButton?: boolean
	studioButton?: boolean
	overrideAddToClass?: (classId: number) => Promise<void>
	overrideDelete?: (classId: number) => void
	overrideOnClick?: (classId: number, songId: number, isBlankSong: boolean) => void
	returnItemAlone?: boolean
	listItem?: Song
	listItemNumber?: number
}

export const ListSongs = forwardRef(
	(
		{
			teacherId,
			spacing = 0,
			lineDivider,
			data,
			selectedChords,
			allTranspositions,
			removeButton = false,
			lessonsButton = false,
			editButton = false,
			viewButton = false,
			studioButton = false, // classId must also be received to display StudioButton
			classId,
			overrideAddToClass,
			overrideDelete,
			overrideOnClick,
			returnItemAlone = false,
			listItem,
			listItemNumber
		}: ListSongsProps,
		ref
	) => {
		const styles = useStyles(spacing)
		const history = useHistory()
		const [songId, setSongId] = useState<number>()
		const [viewDetails, setViewDetails] = useState(false)
		const [lessonSelectedSongId, setLessonSelectedSongId] = useState<number | undefined>()
		const [addToClassSong, setAddToClassSong] = useState<number | undefined>()
		const [songMaturity, setSongMaturity] = useState('')
		const [songClasses, setSongClasses] = useState<Class[]>([])
		const [insertSongToClass] = useInsertSongToClassMutation()
		const [removeSongFn, setRemoveSongFn] = useState<() => Promise<void>>()
		const [loading, setLoading] = useState(false)
		const [favoriteSong, setFavoriteSong] = useState(false)
		const [handleFavoriteFn, setHandleFavoriteFn] = useState<(isFavorite: boolean, noDialog: boolean) => void>(
			() => {}
		)
		const [deleteSongId, setDeleteSongId] = useState<number | undefined>()
		const [dontAsk, setDontAsk] = useState(false)
		const { teacherData: teacher, studentData } = useLoginContext()
		const [searchText, setSearchtText] = useState('')
		const [deleteSong] = useDeleteSongByPkMutation()

		const ItemSong = (props: { song: Song; isEditable: boolean }) => {
			const { song, isEditable = false } = props
			const isBlankSong = song.title.toLowerCase() === BLANK_SONG_TITLE

			const [loadingItem, setLoadingItem] = useState(false)
			const [addFavoriteSong, { loading: loadingAdd, error: errorAdd }] = teacher?.teacher_id
				? useInsertTeacherSongFavoriteMutation()
				: useInsertStudentSongFavoriteMutation()

			const [deleteFavoriteSong, { loading: loadingDel, error: errorDel }] = teacher?.teacher_id
				? useDeleteTeacherSongFavoriteMutation()
				: useDeleteStudentSongFavoriteMutation()

			const [isFavorite, setIsFavorite] = useState(!!song.is_favorite || !!song.is_student_favorite)

			const removeFavorite = async () => {
				setRemoveSongFn(undefined) // close Remove Favorited Dialog

				setLoading(true)
				await deleteFavoriteSong({
					variables: { teacherId, songId: song.song_id, studentId: studentData?.student_id },
					update: (cache) => {
						cache.evict({
							id: 'ROOT_QUERY',
							fieldName: 'teacher_song_favorite'
						})
						cache.evict({
							id: 'ROOT_QUERY',
							fieldName: 'student_song_favorite'
						})
					}
				})
				setLoading(false)

				if (!errorDel && !loadingDel) {
					setIsFavorite(false)
					setFavoriteSong(false)
				}
			}

			const addFavorite = async () => {
				setLoading(true)
				await addFavoriteSong({
					variables: { teacherId, songId: song.song_id, studentId: studentData.student_id },
					update: (cache) => {
						const identify = cache.identify(teacher)
						cache.evict({
							id: identify,
							fieldName: 'teacher_songs_favorites'
						})
						cache.evict({
							id: 'ROOT_QUERY',
							fieldName: 'teacher_song_favorite'
						})
						cache.evict({
							id: 'ROOT_QUERY',
							fieldName: 'student_song_favorite'
						})
					}
				})
				setLoading(false)

				if (!errorAdd && !loadingAdd) {
					setIsFavorite(true)
					setFavoriteSong(true)
				}
			}

			const handleFavorite = (isFavorite: boolean) => {
				if (isFavorite) {
					if (
						teacher?.teacher_setting?.favorites_dont_ask_again_to_delete ||
						studentData?.student_setting?.favorites_dont_ask_again_to_delete
					)
						removeFavorite()
					else setRemoveSongFn(() => removeFavorite)
				} else addFavorite()
			}

			const openSongPlayer = (isDetails: boolean) => {
				setViewDetails(isDetails)
				setHandleFavoriteFn(() => handleFavorite)
				setFavoriteSong(isFavorite)
				setSongId(song.song_id)
			}

			if (!song) return <Fragment></Fragment>

			return (
				<Box position="relative">
					{(loadingAdd || loadingDel || loadingItem) && (
						<Box className={styles.boxLoading}>
							<CircularProgress color="secondary" size={20} />
						</Box>
					)}
					<Box
						data-cy={getCyKey(ListItemSong)}
						track-type={song?.tracks?.map((i) => i?.track_type?.name).join('|')}
						scale-mode={song?.scale?.name}
						style={{ cursor: 'pointer' }}
						onClick={() =>
							studioButton && classId && overrideOnClick
								? overrideOnClick(classId, song.song_id, isBlankSong)
								: openSongPlayer(true)
						}>
						<ListItemSong
							imageUrl={buildImagePath(song.image_path)}
							title={song.title}
							author={song.artist.name}
							genres={`• ${getSongGenres(song)}`}
							btnLesson={
								lessonsButton
									? {
											onClick: (e) => {
												e.stopPropagation()
												setViewDetails(false)
												setLessonSelectedSongId(song.song_id)
											}
									  }
									: undefined
							}
							btnFavorite={{
								selected: isFavorite,
								onClick: (e) => {
									e.stopPropagation()
									handleFavorite(isFavorite)
								}
							}}
							btnLyric={{
								onClick: (e) => {
									e.stopPropagation()
									openSongPlayer(false)
								}
							}}
							btnEdit={
								editButton || isEditable
									? {
											onClick: (e) => {
												e.stopPropagation()
												history.push(
													buildRouteParameters(Pages.SongEdit, { songId: song.song_id })
												)
											}
									  }
									: undefined
							}
							btnRemove={
								removeButton
									? {
											onClick: (e) => {
												e.stopPropagation()
												if (overrideDelete) {
													overrideDelete(song.song_id)
												} else {
													setDeleteSongId(song.song_id)
												}
											}
									  }
									: undefined
							}
							btnAddClass={
								!viewButton && !studioButton
									? {
											onClick: async (e) => {
												e.stopPropagation()
												if (overrideAddToClass) {
													setLoadingItem(true)
													await overrideAddToClass(song.song_id)
													setLoadingItem(false)
												} else {
													setSongClasses(
														song.class_songs?.map((item) => item.class) ?? []
													)
													setAddToClassSong(song.song_id)
													setSongMaturity(song?.song_maturity_level as string)
													setHandleFavoriteFn(() => handleFavorite)
													setFavoriteSong(isFavorite)
												}
											}
									  }
									: undefined
							}
							btnStudio={
								studioButton && classId !== undefined
									? {
											onClick: async (e) => {
												e.stopPropagation()
												if (overrideOnClick)
													overrideOnClick(classId, song.song_id, isBlankSong)
											}
									  }
									: undefined
							}
							btnView={
								viewButton
									? {
											onClick: (e) => {
												e.stopPropagation()
												openSongPlayer(true)
											}
									  }
									: undefined
							}
						/>
					</Box>
				</Box>
			)
		}

		const handleDelete = async (): Promise<void> => {
			if (deleteSongId && deleteSongId !== 0) {
				deleteSong({
					variables: {
						songId: deleteSongId
					},
					onCompleted: (data) => {
						cache.evict({
							id: `song:${data?.delete_song_by_pk?.song_id}`
						})
						cache.evict({
							id: 'ROOT_QUERY',
							fieldName: 'song_aggregate'
						})
					}
				})
			}
		}

		const RenderElement = returnItemAlone ? (
			<>
				<Box
					className="itemBox"
					key={concatenate(
						[listItemNumber as number, listItem?.song_id as number, listItem?.title as string],
						'-'
					)}
					overflow="hidden"
					boxShadow={spacing > 0 ? 1 : 0}
					borderRadius={spacing > 0 ? 4 : 0}>
					<ItemSong isEditable={listItem?.teacher_id === teacher.teacher_id} song={listItem as Song} />
					<Typography data-cy="scale" variant="srOnly">
						{listItem?.scale?.name ?? ''}
					</Typography>
					{lineDivider && data.length !== (listItemNumber as number) + 1 && <Divider variant="middle" />}
				</Box>
			</>
		) : (
			<>
				<List className={styles.boxSongs}>
					{data.map((song, i) => (
						<Box
							className="itemBox"
							key={concatenate([i, song.song_id, song.title], '-')}
							overflow="hidden"
							boxShadow={spacing > 0 ? 1 : 0}
							borderRadius={spacing > 0 ? 4 : 0}>
							<ItemSong isEditable={song.teacher_id === teacher.teacher_id} song={song} />
							<Typography data-cy="scale" variant="srOnly">
								{song.scale?.name ?? ''}
							</Typography>
							{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
						</Box>
					))}
				</List>
			</>
		)

		return (
			<div ref={ref as React.MutableRefObject<HTMLDivElement>}>
				{useMemo(() => RenderElement, [data])}
				<LessonsSong
					teacherId={teacherId}
					lessonSelectedSongId={lessonSelectedSongId}
					setLessonSelectedSongId={setLessonSelectedSongId}
				/>
				<AddToClassDialog
					searchText={searchText}
					setSearchText={setSearchtText}
					title="Save Song to Class"
					description="This will save the Song so that you can view and teach right from your Class page. This content will be visible to the students that are in the Class."
					itemName="Song"
					songMaturity={songMaturity}
					onSuccess={{
						confirmLabel: 'Create Assignment',
						discardLabel: 'View Classes',
						onDiscard: () => {
							history.push(buildRouteParameters(Pages.Classes))
						}
					}}
					onDone={() => {
						history.push(buildRouteParameters(Pages.AddAssignment))
					}}
					isOpen={!!addToClassSong}
					setIsOpen={setAddToClassSong}
					itemClasses={songClasses}
					onConfirm={async (selectedClasses) => {
						if (addToClassSong) {
							const array = [...selectedClasses]
							const classes = array.map((item) => {
								return insertSongToClass({
									variables: {
										songId: addToClassSong,
										classId: item.class_id,
										teacherId
									},
									update: (cache, { data }) => {
										const classesToUpdateInCache = data?.insert_class_song?.returning
										if (!classesToUpdateInCache) return

										let identify

										classesToUpdateInCache.forEach((cls) => {
											identify = cache.identify(cls.class)
											cache.evict({
												id: identify,
												fieldName: 'class_songs'
											})
											cache.evict({
												id: identify,
												fieldName: 'class_songs_aggregate'
											})
										})
										cache.evict({
											id: 'ROOT_QUERY',
											fieldName: 'song'
										})
									}
								})
							})
							await Promise.all(classes)
						}
					}}
					handleFavorite={{
						isFavorite: favoriteSong,
						handler: () => handleFavoriteFn(favoriteSong, dontAsk)
					}}
					isLoading={loading}
				/>
				<SongPlayer
					songId={songId}
					setSongId={setSongId}
					viewDetails={viewDetails}
					setViewDetails={setViewDetails}
					loading={loading}
					isFavorite={favoriteSong}
					handleFavoriteFn={handleFavoriteFn}
					dontAsk={dontAsk}
					classId={classId}
					selectedChords={selectedChords}
					allTranspositions={allTranspositions}
				/>
				<RemoveFavoriteDialog
					itemName="Song"
					pageItemName={Pages.MusicCatalog.name}
					removeItemFn={removeSongFn}
					setRemoveItemFn={setRemoveSongFn}
					dontAsk={dontAsk}
					setDontAsk={setDontAsk}
				/>
				<DeleteItemDialog
					itemName="Song"
					isOpen={!!deleteSongId}
					setIsOpen={setDeleteSongId}
					onConfirm={handleDelete}
				/>
			</div>
		)
	}
)
