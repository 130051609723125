import { createStyles, makeStyles } from '@material-ui/core'

export const useCanvasThumbnailStyles = makeStyles(() =>
	createStyles({
		canvas: {
			display: 'none'
		}
	})
)

export default useCanvasThumbnailStyles
