import { useEffect, Dispatch, SetStateAction } from 'react'

import { ImageList, ImageListItem, Typography } from '@material-ui/core'
import { ClassCardDialog } from 'src/components/Cards/ClassCardDialog/ClassCardDialog'
import { BaseLoading } from 'src/components/Loading/BaseLoading'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { Class } from 'src/graphql/autogenerate/schemas'

import { BaseDialog, BaseDialogProps } from '../BaseDialog/BaseDialog'
import useStyles from './AddToClassDialog.styles'

export type ClassForSelect = Pick<Class, 'class_id' | 'title' | 'image_path' | 'maturity_level'> & {
	saved?: boolean
}
type AddToClassDialogProps = BaseDialogProps & {
	open: boolean
	onClose: () => void
	selectedClasses: number[]
	setSelectedClasses: Dispatch<SetStateAction<number[]>>
	title: string
	subtitle?: string
	searchText: string
	setSearchText: Dispatch<SetStateAction<string>>
	totalClasses: number
	fetchMore: () => void
	classesLoading: boolean
	fetchingMore: boolean
	classes: ClassForSelect[]
	onConfirm: () => void
	singleClass?: boolean
}

export const AddToClassDialog = ({
	open,
	onClose,
	selectedClasses,
	setSelectedClasses,
	title,
	subtitle,
	searchText,
	setSearchText,
	totalClasses,
	fetchMore,
	classesLoading,
	classes,
	fetchingMore,
	onConfirm,
	singleClass,
	...props
}: AddToClassDialogProps) => {
	const styles = useStyles()

	const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		setSearchText(e.target.value)

	const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
			fetchMore()
		}
	}

	const handleSelect = (classId: number, saved?: boolean) => {
		if (saved) return
		setSelectedClasses((prev) => {
			if (prev.includes(classId)) {
				return prev.filter((i) => i !== classId)
			} else if (singleClass) {
				return [classId]
			} else {
				return [...prev, classId]
			}
		})
	}

	useEffect(() => {
		if (!open) setSelectedClasses([])
	}, [open])

	return (
		<>
			<BaseDialog
				open={open}
				onClose={onClose}
				dividers={false}
				maxWidth="sm"
				header={
					<>
						<Typography variant="h4" align="center">
							{title}
						</Typography>
						{!!subtitle && (
							<Typography className={styles.subtitle} variant="h5">
								{subtitle}
							</Typography>
						)}
						<SearchBar
							placeholder="Search class by title"
							hasDropDown={false}
							onChange={handleSearch}
							paperProps={{ className: styles.searchBar }}
						/>
						<Typography variant="h6">Classes ({totalClasses})</Typography>
					</>
				}
				confirmLabel="Save"
				onConfirm={onConfirm}
				onDiscard={onClose}
				discardLabel="Cancel"
				bodyProps={{
					onScroll: handleScroll,
					className: styles.dialogContent
				}}
				body={
					<>
						<ImageList rowHeight={100} cols={3} style={{ margin: 0 }}>
							{!classesLoading &&
								classes.map((currentClass) => (
									<ImageListItem key={currentClass.class_id} cols={1}>
										<ClassCardDialog
											saved={currentClass.saved}
											selected={selectedClasses.includes(currentClass.class_id)}
											selectable
											classData={{
												maturity_level: currentClass.maturity_level,
												title: currentClass.title || '',
												image_path: currentClass.image_path,
												class_id: currentClass.class_id
											}}
											onClick={() => handleSelect(currentClass.class_id, currentClass.saved)}
										/>
									</ImageListItem>
								))}
						</ImageList>

						{(classesLoading || fetchingMore) && <BaseLoading />}
						{!classesLoading && !classes.length && (
							<Typography className={styles.noResult}>
								Your search for "{searchText}" did not return any results.
							</Typography>
						)}
					</>
				}
				{...props}
			/>
		</>
	)
}
