import { Fragment } from 'react'

import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { PagesProps } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons/CustomIcons'
import { ActivityBoxEmpty, MainTitle, SelectSortOrderBy } from 'src/components'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { UnsuscribeDialog } from 'src/components/Dialogs/UnsuscribeDialog/UnsuscribeDialog'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { SelectFilter } from 'src/components/SelectFilter'
import { SubscriptionsCard } from 'src/components/SubscriptionsCard/SubscriptionsCard'
import { Playlist, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { AddPlaylistToClass } from 'src/scenes/Teacher/scenes/8-Videos/components/AddPlaylistToClass'
import { PlaylistCardContainer } from 'src/scenes/Teacher/scenes/8-Videos/scenes/Playlist/components/PlaylistContainer'
import { generateFlagText } from 'src/utils'

import { usePlaylistPage } from './usePlaylistPage'
export const PlaylistPage = ({ page }: PagesProps) => {
	const {
		isFetching,
		moreResults,
		breadcrumbs,
		searchbarText,
		showSubscriptions,
		sort,
		handleSort,
		onFilterChange,
		filter,
		searchbarData,
		playlist,
		playlistId,
		isOpen,
		totalPlaylists,
		playlistSuscriptions,
		searchTeacherSubscriptionsData,
		playlistDataLoading,
		suscriptionDataLoading,
		searchSuscriptionDataLoading,
		searchbarPlaylistsLoading,
		handleSearchbarTextEnter,
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		setAddToClassDialog,
		setIsAddPlaylistToClassOpen,
		setPlaylistId,
		isAddPlaylisToClassOpen,
		addToClassDialog,
		onUnsubscribe,
		goToPlaylist,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	} = usePlaylistPage()

	const searchBarItems = () => {
		return !showSubscriptions
			? searchbarData.map((playlist) => (
					<PlaylistCardContainer
						playlist={playlist as Playlist}
						key={playlist.playlist_id}
						elevation={0}
						btnSubscriptions={{
							onClick: (e) => {
								e.stopPropagation()
								setPlaylistId(playlist.playlist_id)
								handleOpenProductDialog()
							}
						}}
						btnAddClass={{
							onClick: (e) => {
								e.stopPropagation()
								setAddToClassDialog(playlist)
								setIsAddPlaylistToClassOpen(true)
							}
						}}
					/>
			  ))
			: searchTeacherSubscriptionsData.map((playlist) => (
					<SubscriptionsCard
						isSearch
						key={playlist.orderId}
						data={playlist}
						allSubscriptionsCard
						onView={() => goToPlaylist(playlist.productId)}
						onUnsubscribe={() => setItem(playlist)}
						dataflagText={generateFlagText(playlist)}
					/>
			  ))
	}

	return (
		<>
			{isOpen && playlistId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={playlistId}
					productName={Product_Name_Enum.Playlist}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<UnsuscribeDialog
				isOpen={unSuscribeOpenDialog}
				setItem={setItem}
				onConfirm={(stripeSubscriptionId) => onUnsubscribe(stripeSubscriptionId)}
				setIsOpen={setUnSuscribeOpenDialog}
				item={item}
				onDone={(stripeSubscriptionId) => handleRefetch(stripeSubscriptionId)}
			/>
			<AddPlaylistToClass
				isOpen={isAddPlaylisToClassOpen}
				setIsOpen={setIsAddPlaylistToClassOpen}
				playlist={addToClassDialog}
			/>
			<Box>
				<MainTitle
					breadcrumbs={breadcrumbs}
					title={page.name}
					description={
						showSubscriptions
							? 'Here are all the Playlists you are currently subscribed to'
							: 'Short videos that provide real, helpful advice from Grammy award winning artists, and activities by industry professionals.'
					}
					rightActions={
						showSubscriptions
							? []
							: [
									{
										variant: 'outlined',
										style: { border: '1px solid rgba(50, 51, 66, 0.5)' },
										startIcon: <ResourcesSmallIcon />,
										name: 'My Files',
										linkTo: Pages.MyFiles.path
									},
									{
										variant: 'contained',
										color: 'secondary',
										startIcon: <FileUploadIcon />,
										name: 'Upload Your Own',
										linkTo: Pages.VideoUpload.path
									}
							  ]
					}
				/>
				<SearchBar
					placeholder="Find Playlist by Title or Description"
					onChange={handleSearchbarTextChange}
					onEnterPressed={handleSearchbarTextEnter}
					loading={searchbarPlaylistsLoading || searchSuscriptionDataLoading}
					dropdownItems={searchBarItems()}
				/>

				<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
					<Typography variant="h6">
						<b>Playlists</b> ({totalPlaylists})
					</Typography>
					<div style={{ flexGrow: 1 }} />
					{totalPlaylists !== 0 && showSubscriptions && (
						<SelectFilter value={filter} onChange={onFilterChange} />
					)}
					{totalPlaylists !== 0 && (
						<SelectSortOrderBy cyKey="Playlist" value={sort} onChange={handleSort} />
					)}
				</Box>
				<Grid container spacing={3}>
					<Grid item xs>
						{playlistSuscriptions &&
							showSubscriptions &&
							playlistSuscriptions.length > 0 &&
							playlistSuscriptions.map((playlist) => (
								<Box marginBottom={'15px'} marginTop={'15px'} key={playlist.orderId}>
									<SubscriptionsCard
										key={playlist.orderId}
										data={playlist}
										allSubscriptionsCard
										onView={() => goToPlaylist(playlist.productId)}
										onUnsubscribe={() => setItem(playlist)}
										dataflagText={generateFlagText(playlist)}
									/>
								</Box>
							))}
						{playlist &&
							!showSubscriptions &&
							playlist.length > 0 &&
							playlist.map((playlist) => (
								<Box marginBottom={'15px'} marginTop={'15px'} key={playlist.playlist_id}>
									<PlaylistCardContainer
										playlist={playlist as Playlist}
										key={playlist.playlist_id}
										elevation={1.5}
										btnAddClass={{
											onClick: (e) => {
												e.stopPropagation()
												setAddToClassDialog(playlist)
												setIsAddPlaylistToClassOpen(true)
											}
										}}
										btnSubscriptions={{
											onClick: (e) => {
												e.stopPropagation()
												setPlaylistId(playlist.playlist_id)
												handleOpenProductDialog()
											}
										}}
									/>
								</Box>
							))}
						{((isFetching && moreResults) || suscriptionDataLoading || playlistDataLoading) && (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}

						{((!playlistDataLoading && playlist.length > 0) ||
							(!suscriptionDataLoading && playlistSuscriptions.length > 0)) &&
							!moreResults && (
								<Box mt={4} textAlign="center">
									<Button
										onClick={() => {
											window.scrollTo(0, 0)
										}}
										variant="text"
										color="secondary">
										Back to Top
									</Button>
								</Box>
							)}

						{totalPlaylists === 0 && (
							<>
								{searchbarText ? (
									<Fragment>
										<Typography variant="h6" color="textSecondary">
											Your search for "{searchbarText}" did not return any results.
										</Typography>
									</Fragment>
								) : (
									<ActivityBoxEmpty description="You have not subscribed to any playlist yet" />
								)}
							</>
						)}
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
