import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { HomeIcon, LessonsIcon, DoubleMusicNoteIcon, VideoIcon, PersonIcon } from 'src/assets/icons/CustomIcons'
import { ImageButton } from 'src/components'
import { Pages } from 'src/routes/teacherPages'
import {
	DESKTOP_IMG,
	DEFAULT_CLASS_LESSON_IMG,
	DEFAULT_CLASS_SONG_IMG,
	DEFAULT_CLASS_VIDEOS_IMG,
	CLASSROOM_IMG,
	LIBRARY_IMG
} from 'src/utils/constants'

import useStyles from './404.styles'
import { ErrorPage } from './components/ErrorPage'

type ItemButtonProps = {
	image: string
	title: string
	buttonIcon: React.ReactNode
	buttonText: string
	buttonLink: string
}

const ItemButton = ({ image, title, buttonIcon, buttonText, buttonLink }: ItemButtonProps) => {
	const styles = useStyles()

	const titleHeight = { xs: 155 }
	const elementPosition = {
		top: 'auto',
		right: 16,
		left: 'auto',
		bottom: 16
	}

	return (
		<ImageButton
			className={styles.itemButton}
			titleHeight={titleHeight}
			backgroundImageUrl={image}
			title={<Typography className={styles.title}>{title}</Typography>}
			titlePosition="initial"
			element={
				<Button className={styles.button} startIcon={buttonIcon}>
					{buttonText}
				</Button>
			}
			elementPosition={elementPosition}
			linkTo={buttonLink}
		/>
	)
}

type NotFoundProps = {
	isLoggedIn: boolean
}

const NotFound = ({ isLoggedIn }: NotFoundProps) => {
	const styles = useStyles()
	const description = 'Oops! The page that you’re looking for doesn’t exist.'
	const caption = 'Explore the content areas below to find what you’re looking for.'

	return (
		<ErrorPage errorCode={404} description={description} caption={caption}>
			{isLoggedIn && (
				<div className={styles.grid}>
					<Grid container spacing={1}>
						<Grid sm={4} item>
							<ItemButton
								image={DESKTOP_IMG}
								title="Your Dashboard"
								buttonIcon={<HomeIcon />}
								buttonText={Pages.Home.name}
								buttonLink={Pages.Home.path}
							/>
						</Grid>
						<Grid sm={4} item>
							<ItemButton
								image={DEFAULT_CLASS_SONG_IMG}
								title="Discover Music"
								buttonIcon={<DoubleMusicNoteIcon />}
								buttonText={Pages.MusicCatalog.name}
								buttonLink={Pages.MusicCatalog.path}
							/>
						</Grid>
						<Grid sm={4} item>
							<ItemButton
								image={DEFAULT_CLASS_VIDEOS_IMG}
								title="Watch videos"
								buttonIcon={<VideoIcon />}
								buttonText={Pages.Videos.name}
								buttonLink={Pages.Videos.path}
							/>
						</Grid>
						<Grid sm={4} item>
							<ItemButton
								image={DEFAULT_CLASS_LESSON_IMG}
								title="Find Lessons"
								buttonIcon={<LessonsIcon />}
								buttonText={Pages.Lessons.name}
								buttonLink={Pages.Lessons.path}
							/>
						</Grid>
						<Grid sm={4} item>
							<ItemButton
								image={CLASSROOM_IMG}
								title="Your Classes"
								buttonIcon={<PersonIcon />}
								buttonText={Pages.Classes.name}
								buttonLink={Pages.Classes.path}
							/>
						</Grid>
						<Grid sm={4} item>
							<ItemButton
								image={LIBRARY_IMG}
								title="Explore Modules"
								buttonIcon={<LessonsIcon />}
								buttonText={Pages.Modules.name}
								buttonLink={Pages.Modules.path}
							/>
						</Grid>
					</Grid>
				</div>
			)}
		</ErrorPage>
	)
}

export default NotFound
