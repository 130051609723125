import { Box, Typography } from '@material-ui/core'
import { BigBaseButton, MainTitle, Stepper } from 'src/components'
import { InfoDialog } from 'src/components/MutationDialog/InfoDialog'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs } from 'src/utils'

import { UploadAudioView } from './components/Step-1/UploadAudioView'
import { ChordSheetView } from './components/Step-2/ChordSheetView'
import { TimeLyricsView } from './components/Step-3/TimeLyricsView'
import useStyles from './UploadSongTrack.styles'
import { useUploadSongTrackContext, withUploadSongTrackProvider } from './useUploadSongTrack'

export const UploadSongTrack = withUploadSongTrackProvider(() => {
	const styles = useStyles()
	const {
		step,
		shouldDisableNext,
		withChords,
		withLyrics,
		handlePrevButton,
		handleNextButton,
		saveTrack,
		goToUploadTracks,
		goBackToSongs,
		insertTrackData,
		errorTrack,
		viewTitle,
		viewDescription,
		onCancel,
		toggleOnCancel,
		goBackToEditSong
	} = useUploadSongTrackContext()

	const breadcrumbs = buildBreadCrumbs({
		page: Pages.SongUploadTrack
	})

	const steps = [
		{
			step: 1,
			stepName: 'Progress',
			component: <UploadAudioView />,
			show: true
		},
		{
			step: 2,
			stepName: `Progress`,
			component: <TimeLyricsView />,
			show: withLyrics
		},
		{
			step: 3,
			stepName: `Progress`,
			component: <ChordSheetView />,
			show: withChords
		}
	].filter((item) => item.show)

	return (
		<>
			<MainTitle breadcrumbs={breadcrumbs} title={viewTitle} description={viewDescription} />

			<Stepper
				stepDescription={steps.find((_, index) => index + 1 === step)?.stepName ?? ''}
				currentStep={step}
				totalSteps={steps.length}
				stepBoxProps={{ mt: 6 }}
			/>

			{steps.find((_, index) => index + 1 === step)?.component ?? null}

			<Box className={styles.buttonDisabled} marginTop="35px" display="flex" justifyContent="space-between">
				<Box>
					{step === 1 && (
						<BigBaseButton variant="contained" color="default" onClick={toggleOnCancel}>
							<Typography variant="caption">Cancel</Typography>
						</BigBaseButton>
					)}
					{step !== 1 && (
						<BigBaseButton
							className={styles.button}
							variant="contained"
							color="default"
							onClick={handlePrevButton}>
							<Typography variant="caption">Back</Typography>
						</BigBaseButton>
					)}
				</Box>

				<BigBaseButton
					className={styles.button}
					color="secondary"
					disabled={
						shouldDisableNext(steps.find((_, index) => index + 1 === step)?.step ?? 0) ||
						(!withChords && !withLyrics)
					}
					onClick={step === steps.length ? saveTrack : handleNextButton}>
					<Typography variant="caption">
						{step === steps.length && step !== 1 ? 'Save' : 'Next'}
					</Typography>
				</BigBaseButton>
			</Box>

			<InfoDialog
				open={onCancel}
				onClose={toggleOnCancel}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardButton={{
					label: 'Yes, Go Back',
					onClick: goBackToEditSong
				}}
				confirmButton={{
					label: 'No, Cancel',
					onClick: toggleOnCancel
				}}
				icon="warning"
			/>

			<MutationDialog
				maxWidth="xs"
				open={false}
				error={!!errorTrack}
				success={!!insertTrackData?.customTrackUpload}
				icon="question"
				successProps={{
					title: `Your track has been uploaded!`,
					body: ``,
					discardButton: {
						onClick: goBackToSongs,
						label: 'Return to Songs'
					},
					confirmButton: {
						onClick: goToUploadTracks,
						label: 'Upload Another Track'
					}
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while uploading the Track.`,
					icon: 'error'
				}}
			/>
		</>
	)
})
