import { Box, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	loadingWrapper: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '100%'
	}
}))
export const BaseLoading = () => {
	const styles = useStyles()

	return (
		<Box className={styles.loadingWrapper}>
			<CircularProgress
				color="secondary"
				size={40}
				style={{
					justifyContent: 'center'
				}}
			/>
		</Box>
	)
}
