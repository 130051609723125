import axios, { AxiosRequestConfig } from 'axios'
import { client } from 'src/apollo/client'
import { GetPresignedUrlDocument } from 'src/graphql/autogenerate/hooks'
import { GetPresignedUrlQuery, GetPresignedUrlQueryVariables } from 'src/graphql/autogenerate/operations'
import { FileEnum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils/utils'

export interface Loader {
	file: Promise<File>
	uploadTotal: number
	uploaded: number
}

export class UploadAdapterCKEditor {
	loader: Loader

	constructor(loader: Loader) {
		this.loader = loader
	}

	upload() {
		return this.loader.file.then((file) => {
			return new Promise((resolve, reject) => {
				const ext = file?.name?.split('.')[file.name.split('.')?.length - 1]
				const type = file.type
				client
					.query<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>({
						query: GetPresignedUrlDocument,
						variables: {
							filter: {
								conType: type,
								ext,
								type: FileEnum.Images,
								isTemporal: false
							}
						},
						fetchPolicy: 'no-cache'
					})
					.then((data) => {
						this.send(file, data.data)
							.then((res) => {
								resolve({
									default: buildImagePath(res)
								})
							})
							.catch(reject)
					})
					.catch(reject)
			})
		})
	}

	send(file: File, data: GetPresignedUrlQuery): Promise<string> {
		const presigndedUrl = data?.presignedUrl?.url
		const key = data?.presignedUrl?.key
		if (!key || !presigndedUrl) throw new Error('Error uploading image')

		const CancelToken = axios.CancelToken
		const source = CancelToken.source()
		const options: AxiosRequestConfig = {
			method: 'put',
			data: file,
			url: presigndedUrl,
			headers: {
				'Content-Type': file?.type as string
			},

			cancelToken: source.token
		}

		return axios.request(options).then((res) => {
			if (res.status === 200) {
				return key
			} else {
				throw new Error('Error uploading image')
			}
		})
	}
}
