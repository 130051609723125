import React, { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'

import { useVideoPlayerStyles } from './VideoPlayer.styles'

type VideoPlayerProps = React.HTMLAttributes<HTMLDivElement> & {
	isVideo: boolean
	onReady?: (player: ReactPlayer | null) => void
	onPlay?: () => void
	onPause?: () => void
	url?: string
	imageForAudio?: string
}

export const VideoPlayer = ({
	onReady,
	onPlay,
	onPause,
	url,
	imageForAudio = '',
	isVideo,
	...props
}: VideoPlayerProps) => {
	const styles = useVideoPlayerStyles({ imageUrl: imageForAudio })
	const playerRef = useRef<ReactPlayer | null>(null)
	const [bufferedRanges, setBufferedRanges] = useState<TimeRanges | null>(null)

	useEffect(() => {
		if (onReady && playerRef.current) {
			onReady(playerRef.current)
		}
	}, [onReady])

	const handlePlay = () => {
		if (onPlay) {
			onPlay()
		}
	}

	const handlePause = () => {
		if (onPause) {
			onPause()
		}
	}

	const handleProgress = ({ played }: ReactPlayerProps) => {
		if (played && played.length > 0) {
			const currentTime = played[played.length - 1]
			const videoElement = playerRef.current?.getInternalPlayer()
			if (videoElement) {
				setBufferedRanges(videoElement.buffered)
				if (!isSeekAllowed(currentTime)) {
					const bufferedEnd = videoElement.buffered.end(videoElement.buffered.length - 1)
					if (currentTime < bufferedEnd) {
						videoElement.currentTime = currentTime
					} else {
						videoElement.currentTime = bufferedEnd
					}
				}
			}
		}
	}
	const isSeekAllowed = (seekTime: number) => {
		if (bufferedRanges) {
			for (let i = 0; i < bufferedRanges.length; i++) {
				const start = bufferedRanges.start(i)
				const end = bufferedRanges.end(i)
				if (seekTime >= start && seekTime <= end) {
					return true
				}
			}
		}

		return false
	}

	return (
		<div {...props}>
			<ReactCustomVideoPlayer
				ref={playerRef}
				url={url}
				className={clsx(styles.videoHolder, isVideo ? '' : styles.videoBackground)}
				onPlay={handlePlay}
				onPause={handlePause}
				onProgress={handleProgress}
			/>
		</div>
	)
}
