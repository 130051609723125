import { useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { RedoIcon, WurrlyLogoIcon } from 'src/assets/icons'
import { PeaceIcon } from 'src/assets/icons/CustomIcons'
import { BigBaseButton, FixedBackground, InfoDialog } from 'src/components'
import { useReviewAndSubmitContext } from 'src/components/Studio/Hooks/useReviewAndSubmit'
import { RedoDialog } from 'src/components/Studio/Record/RedoDialog'
import { useStudioBackgroundStyles } from 'src/components/Studio/Setup/styles/studioBackgroundStyles'
import { StudioDialog } from 'src/components/Studio/StudioDialog/StudioDialog'
import { Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { StudentPages } from 'src/routes/studentpages'
import { ClassWurrlyDisplayConfig } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassDetail/components/ClassWurrlies'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { VideoSubmit } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/VideoSubmit'
import { buildImagePath, GOALSICONS } from 'src/utils'
import { ClassWurrlyType, SortOrder } from 'src/utils/enums'
import { buildRouteParameters } from 'src/utils/routes-utils'

/**
 * This component should be called as a child of the `ReviewAndSubmitProvider`, the previous step `VideoReview`
 * must also be a child of the same `ReviewAndSubmitProvider`
 */
export const StudioSubmit = () => {
	const classes = useStudioBackgroundStyles()

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={classes.logoPosition} />}
			className={classes.backgroundImage}>
			<VideoSubmitDialog />
		</FixedBackground>
	)
}

const VideoSubmitDialog = () => {
	const history = useHistory()
	const {
		isVideoUploading,
		videoUploadProgress,
		submitWurrly,
		isSubmittingWurrly,
		uploadSuccess,
		songId,
		classId,
		isSubmitDisabled,
		uploadedWurrlyId,
		wurrlyType
	} = useVideoSubmitContext()
	const [isRedoDialogOpen, setIsRedoDialogOpen] = useState(false)
	const { submissionId } = useReviewAndSubmitContext()
	const BACKGROUND = GOALSICONS[0]
	const CONFETTI = GOALSICONS[1]

	const goToMyWurrlies = () => {
		history.push(
			buildRouteParameters(StudentPages.ClassDetail, { classId }, true, {
				// Query params for higlighting created wurrly
				sortBy: SortOrder.Recent,
				wurrlyType: ClassWurrlyType.MyWurrlies,
				highlightWurrlyId: uploadedWurrlyId
			} as ClassWurrlyDisplayConfig)
		)
	}
	const goBackToPlayer = () => {
		history.push(buildRouteParameters(StudentPages.IndieStudentStudio, { classId, songId }))
	}
	const goToDashboard = () => {
		history.push(StudentPages.Home.path)
	}

	const BottomActions = () => (
		<>
			<BigBaseButton
				style={{ minWidth: '125px' }}
				startIcon={<RedoIcon />}
				onClick={() => setIsRedoDialogOpen(true)}>
				Redo
			</BigBaseButton>

			<BigBaseButton
				style={{ minWidth: '125px' }}
				disabled={isSubmitDisabled}
				onClick={submitWurrly}
				color="secondary"
				startIcon={isVideoUploading || isSubmittingWurrly ? <CircularProgress size="1em" /> : undefined}>
				{isVideoUploading && `Uploading wurrly: ${videoUploadProgress}%`}
				{isSubmittingWurrly && 'Submitting'}
				{!isVideoUploading && !isSubmittingWurrly && 'Submit'}
			</BigBaseButton>
		</>
	)

	return (
		<>
			<StudioDialog body={<VideoSubmit />} bottomActions={<BottomActions />} />
			{wurrlyType === Wurrly_Type_Enum.Challenge ? (
				<InfoDialog
					open={uploadSuccess}
					onClose={() => {}}
					icon={
						<Box style={{ position: 'relative', width: '140px', margin: 'auto' }}>
							<img
								src={buildImagePath(BACKGROUND)}
								alt={`BACKGROUND`}
								style={{
									marginLeft: 'auto',
									marginRight: 'auto'
								}}></img>
							<img
								src={buildImagePath(CONFETTI)}
								alt={`BACKGROUND`}
								style={{
									position: 'absolute',
									margin: '33px',
									width: '74px',
									height: '74px',
									top: '0',
									right: '0'
								}}></img>
						</Box>
					}
					title="Success!"
					body="You completed the challenge and earned yourself the badge and 10 points!"
					discardLabel="Go To My Wurrlies"
					onDiscard={goToMyWurrlies}
					confirmLabel="Go To Dashboard"
					onConfirm={goToDashboard}
				/>
			) : (
				<InfoDialog
					open={uploadSuccess}
					onClose={() => {}}
					icon={<PeaceIcon />}
					title="Success!"
					body="Your song has been uploaded."
					discardLabel="Go To My Wurrlies"
					onDiscard={goToMyWurrlies}
					confirmLabel="Record Another"
					onConfirm={goBackToPlayer}
				/>
			)}
			<RedoDialog
				isOpen={isRedoDialogOpen}
				setIsOpen={setIsRedoDialogOpen}
				currentSongId={songId}
				classId={classId}
				submissionId={submissionId}
			/>
		</>
	)
}
