import { useHistory } from 'react-router-dom'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { PartnerCard, PartnerCardProps } from './PartnerCard'

export const PartnerCardContainer = ({ partner, ...props }: PartnerCardProps) => {
	const history = useHistory()
	const goToPartner = () =>
		history.push(buildRouteParameters(Pages.PartnerPage, { partnerId: partner.partner_id }))

	return (
		<>
			<PartnerCard partner={partner} deleteTooltip="Delete Partner" {...props} onView={goToPartner} />
		</>
	)
}
