import React, { Fragment, useEffect, useState, useMemo, CSSProperties } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Avatar, Box, Button, CircularProgress, Typography, IconButton } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'

import { NoteUploadIcon } from '../assets/icons/CustomIcons'
import { getPresignedUploadUrl, PresignedUrlType } from '../scenes/Teacher/mutations'
import { buildQueryVariables, FileTypeEnum } from '../utils'
import { buildImagePath } from '../utils/utils'
import { BaseAlert } from './BaseAlert'
import { ActionButton } from './Buttons/ActionButton'
import { AssetReduced } from './InputMultipleFile'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	input: {
		display: 'none'
	}
}))

const useTextStyles = makeStyles((theme) => ({
	root: {
		padding: '4px 14px 4px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		border: '1px solid rgba(0, 0, 0, .23)',
		borderRadius: '4px',
		'&:hover': {
			border: '1px solid rgba(0, 0, 0)',
			borderRadius: '4px'
		}
	},
	input: {
		padding: '10px 0',
		marginLeft: theme.spacing(1),
		flex: 1,
		alignItems: 'center',
		color: theme.palette.text.disabled
	},
	inputComplete: {
		padding: '10px 0',
		marginLeft: theme.spacing(1),
		flex: 1,
		alignItems: 'center'
	},
	cancelButton: {
		padding: 5,
		color: theme.palette.text.disabled
	},
	progressText: {
		'& .MuiAlert-message': {
			padding: 4
		},
		color: fade(theme.palette.warning.dark, 0.7),
		padding: '0 4px'
	},
	completeText: {
		'& .MuiAlert-message': {
			padding: 4
		},
		color: fade(theme.palette.success.dark, 0.7),
		padding: '0 4px'
	},
	divider: {
		height: 28,
		margin: 4
	}
}))

export enum UploadFileStatus {
	NO_FILE = 0,
	UPLOADING = 1,
	COMPLETE = 2
}
type UploadFileType = {
	handleFileUrl?: (url: string) => void
	handleAsset?: (asset: AssetReduced) => void
	advise: React.ReactNode
	type: FileTypeEnum
	overrideCustomTypes?: string[]
	placeholder: string
	initialFile?: AssetReduced
	isTemporal?: boolean
	formStyles?: CSSProperties
}

/**
 * you can use this prop to override the `type` props acceptance
 * using custom types like ['image', 'audio', 'video', 'application']
 */
export const InputFile = ({
	handleAsset,
	handleFileUrl,
	advise,
	type,
	placeholder,
	initialFile,
	isTemporal,
	formStyles,
	overrideCustomTypes
}: UploadFileType) => {
	const [status, setStatus] = useState<UploadFileStatus>(UploadFileStatus.NO_FILE)
	const [progress, setProgress] = useState<number>(30)
	const [error, setError] = useState<string>()
	const [file, setFile] = useState<File>()
	const [asset, setAsset] = useState<AssetReduced>()
	const [cancelUpload, setCancelUpload] = useState<CancelTokenSource>()
	const [hasUsedInitialData, setHasUsedInitialData] = useState(false)
	const classes = useStyles()
	const textClasses = useTextStyles()

	const [getPresignedUrl, presignedUploadUrl] = useLazyQuery<PresignedUrlType>(getPresignedUploadUrl, {
		fetchPolicy: 'no-cache'
	})

	const uploadFile = async (file: File) => {
		if (file) {
			const allowedTypes = overrideCustomTypes ?? []

			if (allowedTypes.length > 0) {
				const containsValidType = allowedTypes.some((item) => {
					return file.type.startsWith(item)
				})

				if (!containsValidType) {
					setError('Please pick a valid file type')

					return
				}

				setupUsingFile(file)

				return
			}

			if (type === FileTypeEnum.Video) {
				if (!file.type.startsWith('video')) {
					setError('Please pick a valid file type')

					return
				}
				if (file.size > 200000000) {
					setError('Max File size: 200MB')

					return
				}
			}
			if (type === FileTypeEnum.Images) {
				if (!file.type.startsWith('image')) {
					setError('Please pick a valid file type')

					return
				}
			}
			if (type === FileTypeEnum.Song) {
				if (!file.type.startsWith('audio')) {
					setError('Please pick a valid file type')

					return
				}
			}
			if (type === FileTypeEnum.Asset) {
				const availableTypes = ['image', 'application']

				if (!availableTypes.some((item) => file.type.startsWith(item))) {
					setError('Please pick a valid file type')

					return
				}

				if (file.size > 10000000) {
					setError('Max File size: 10MB')

					return
				}
			}

			setupUsingFile(file)
		}
	}

	const setupUsingFile = (file: File) => {
		setError(undefined)
		const ext = file.name.split('.')[file.name.split('.').length - 1]
		getPresignedUrl(
			buildQueryVariables({ filter: { conType: file.type, ext, type, isTemporal: isTemporal ?? true } })
		)
		setFile(file)
		setStatus(UploadFileStatus.UPLOADING)
		setProgress(0)
	}

	useEffect(() => {
		if (!presignedUploadUrl.loading && presignedUploadUrl.data) {
			const presigndedUrl = presignedUploadUrl.data.presignedUrl.url
			const key = presignedUploadUrl.data.presignedUrl.key
			try {
				const CancelToken = axios.CancelToken
				const source = CancelToken.source()
				setCancelUpload(source)
				const options: AxiosRequestConfig = {
					method: 'put',
					data: file,
					url: presigndedUrl,
					headers: {
						'Content-Type': file?.type
					},
					// withCredentials: true,
					onUploadProgress: (progress: { loaded: number; total: number }) => {
						setStatus(UploadFileStatus.UPLOADING)
						const percent = ((progress.loaded / progress.total) * 100).toFixed(2)
						setProgress(+percent)
					},
					cancelToken: source.token
				}
				axios
					.request(options)
					.then((res) => {
						if (res?.status === 200) {
							setAsset({ resource_path: key, name: file?.name ?? '' })
							setStatus(UploadFileStatus.COMPLETE)
							if (type === FileTypeEnum.Asset && handleAsset)
								handleAsset({ resource_path: key, name: file?.name ?? '' })
							else if (handleFileUrl) handleFileUrl(key)
							setProgress(0)
						} else {
							setError(res.statusText)
						}
					})
					.catch((e) => {
						if (axios.isCancel(e)) {
							setStatus(UploadFileStatus.NO_FILE)
							setProgress(0)
						} else {
							if (typeof e !== 'string') setError(JSON.stringify(e))
							else setError(e)
						}
					})
			} catch (err) {
				if (typeof err !== 'string') setError(JSON.stringify(err))
				else setError(err)
			}
		}
	}, [presignedUploadUrl])

	useEffect(() => {
		if (initialFile && !hasUsedInitialData) {
			setAsset(initialFile)
			setStatus(UploadFileStatus.COMPLETE)
			setHasUsedInitialData(true)
			if (handleFileUrl) handleFileUrl(initialFile.resource_path)
		}
	}, [initialFile])

	const handleClose = () => {
		setError(undefined)
	}
	const getFileType = () => {
		switch (type) {
			case FileTypeEnum.Video:
			case FileTypeEnum.Wurrly:
				return 'video/*'
			case FileTypeEnum.Images:
				return 'image/*'
			case FileTypeEnum.Song:
				return 'audio*'
			case FileTypeEnum.Asset:
				return ''
		}
	}

	const PreviewIcon = useMemo(() => {
		return function () {
			if (status === UploadFileStatus.UPLOADING) {
				return <CircularProgress color="secondary" size={30} />
			} else {
				switch (type) {
					case FileTypeEnum.Video:
					case FileTypeEnum.Wurrly:
						return <NoteUploadIcon />
					case FileTypeEnum.Images:
						return (
							<Avatar
								variant="rounded"
								src={
									!hasUsedInitialData
										? URL.createObjectURL(file)
										: buildImagePath(asset?.resource_path)
								}
							/>
						)
					case FileTypeEnum.Song:
						return <NoteUploadIcon />
					case FileTypeEnum.Asset:
						return <ActionButton onClick={handleDelete} color="secondary" icon={<DeleteIcon />} />
				}
			}
		}
	}, [asset, file, status, hasUsedInitialData])

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (status === UploadFileStatus.UPLOADING) handleCancelUpload()
		else if (status === UploadFileStatus.COMPLETE) handleDelete()

		if (e.target.files) {
			uploadFile(e.target.files[0])
		}
	}

	const handleCancelUpload = () => {
		if (cancelUpload) cancelUpload.cancel('Upload canceled by the user.')
	}

	const handleDelete = () => {
		setFile(undefined)
		setStatus(UploadFileStatus.NO_FILE)
	}

	return (
		<Box>
			{status === UploadFileStatus.NO_FILE ? (
				<Fragment>
					<input
						accept={getFileType()}
						className={classes.input}
						id="contained-button-file"
						type="file"
						onChange={handleUpload}
					/>
					<label htmlFor="contained-button-file">
						<Box
							component="form"
							className={textClasses.root}
							style={{ cursor: 'pointer', ...formStyles }}>
							<Typography className={textClasses.input}>{placeholder}</Typography>
							<AddIcon color="secondary" />
						</Box>
					</label>
				</Fragment>
			) : (
				<Box component="form" className={textClasses.root} style={formStyles}>
					<Box
						className={
							status === UploadFileStatus.UPLOADING ? textClasses.input : textClasses.inputComplete
						}
						display="flex">
						<Typography color="secondary">
							<PreviewIcon />
						</Typography>
						<Typography style={{ marginLeft: '10px' }}>
							{!hasUsedInitialData ? file?.name : initialFile?.name}
						</Typography>
					</Box>
					{status === UploadFileStatus.UPLOADING && (
						<Fragment>
							<Alert className={textClasses.progressText} severity="error" icon={false}>
								<Typography variant="caption">
									<b>{progress}% UPLOADED</b>
								</Typography>
							</Alert>
							<Button
								onClick={handleCancelUpload}
								className={textClasses.cancelButton}
								variant="text">
								Cancel
							</Button>
						</Fragment>
					)}
					{status === UploadFileStatus.COMPLETE && (
						<Fragment>
							<Alert className={textClasses.completeText} severity="success" icon={false}>
								<Typography variant="caption">
									<b>COMPLETE</b>
								</Typography>
							</Alert>
							<IconButton onClick={handleDelete}>
								<DeleteIcon color="secondary" />
							</IconButton>
						</Fragment>
					)}
				</Box>
			)}
			<Box marginTop="10px">
				<BaseAlert severity="error" show={!!error} handleClose={handleClose}>
					{error}
				</BaseAlert>
			</Box>
			<Box textAlign="end">
				<Typography variant="caption" className={textClasses.input}>
					{advise}
				</Typography>
			</Box>
		</Box>
	)
}
