import { useEffect, useState } from 'react'

import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { AssetReduced } from 'src/components/InputMultipleFile'
import { useGetPresignedUrlLazyQuery } from 'src/graphql/autogenerate/hooks'
import { FileEnum } from 'src/graphql/autogenerate/schemas'

import { InputFile, UploadFileProps, UploadFileStatus } from './InputFile'

type InputFileContainerProps = Omit<
	UploadFileProps,
	| 'status'
	| 'uploadFile'
	| 'progress'
	| 'file'
	| 'handleUpload'
	| 'handleCancelUpload'
	| 'clearErrorts'
	| 'clearError'
> & {
	handleFileUrl?: (url: string) => void
	handleAsset?: (asset: AssetReduced) => void
	isTemporal?: boolean
	onDelete?: () => void
	id?: string
}

export const InputFileContainer = ({
	type,
	handleAsset,
	handleFileUrl,
	isTemporal,
	initialFile,
	onDelete,
	...props
}: InputFileContainerProps) => {
	const [status, setStatus] = useState(UploadFileStatus.NO_FILE)
	const [progress, setProgress] = useState<number>(30)
	const [error, setError] = useState<string>()
	const [file, setFile] = useState<File>()
	const [asset, setAsset] = useState<AssetReduced>()
	const [cancelUpload, setCancelUpload] = useState<CancelTokenSource>()
	const [hasUsedInitialData, setHasUsedInitialData] = useState(false)
	const [getPresignedUrl, presignedUploadUrl] = useGetPresignedUrlLazyQuery({
		fetchPolicy: 'no-cache'
	})

	const uploadFile = async (file: File) => {
		let error
		if (file) {
			if (type === FileEnum.Tips) {
				if (!file.type.startsWith('video')) error = 'Please pick a valid file type'
				if (file.size > 200000000) error = 'Max File size: 200MB'
			}
			if (type === FileEnum.Images) {
				if (!file.type.startsWith('image')) error = 'Please pick a valid file type'
			}
			if (type === FileEnum.Songs) {
				if (!file.type.startsWith('audio')) error = 'Please pick a valid file type'
			}
			if (type === FileEnum.Assets) {
				if (!(file.type.startsWith('image') || file.type.startsWith('application')))
					error = 'Please pick a valid file type'
				if (file.size > 10000000) error = 'Max File size: 10MB'
			}
			setError(error)

			if (!error) {
				const ext = file?.name?.split('.')[file.name.split('.')?.length - 1]
				getPresignedUrl({
					variables: {
						filter: { conType: file.type, ext, type: type as FileEnum, isTemporal: isTemporal ?? true }
					}
				})
				setFile(file)
				setStatus(UploadFileStatus.UPLOADING)
				setProgress(0)
			}
		}
	}

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (status === UploadFileStatus.UPLOADING) handleCancelUpload()
		else if (status === UploadFileStatus.COMPLETE) handleDelete()

		if (e.target.files) {
			uploadFile(e.target.files[0])
		}
	}

	const handleCancelUpload = () => {
		if (cancelUpload) cancelUpload.cancel('Upload canceled by the user.')
	}

	const handleDelete = () => {
		setFile(undefined)
		setStatus(UploadFileStatus.NO_FILE)
		onDelete?.()
	}

	const clearError = () => setError(undefined)
	useEffect(() => {
		if (!presignedUploadUrl.loading && presignedUploadUrl.data) {
			const presigndedUrl = presignedUploadUrl?.data?.presignedUrl?.url
			const key = presignedUploadUrl?.data?.presignedUrl?.key
			if (!key || !presigndedUrl) return
			try {
				const CancelToken = axios.CancelToken
				const source = CancelToken.source()
				setCancelUpload(source)
				const options: AxiosRequestConfig = {
					method: 'put',
					data: file,
					url: presigndedUrl,
					headers: {
						'Content-Type': file?.type as string
					},
					// withCredentials: true,
					onUploadProgress: (progress: { loaded: number; total: number }) => {
						setStatus(UploadFileStatus.UPLOADING)
						const percent = ((progress.loaded / progress.total) * 100).toFixed(2)
						setProgress(+percent)
					},
					cancelToken: source.token
				}
				axios
					.request(options)
					.then((res) => {
						if (res?.status === 200) {
							setAsset({ resource_path: key, name: file?.name ?? '' })
							setStatus(UploadFileStatus.COMPLETE)
							if (type === FileEnum.Assets && handleAsset)
								handleAsset({ resource_path: key, name: file?.name ?? '' })
							else if (handleFileUrl) handleFileUrl(key)
							setProgress(0)
						} else {
							setError(res.statusText)
						}
					})
					.catch((e) => {
						if (axios.isCancel(e)) {
							setStatus(UploadFileStatus.NO_FILE)
							setProgress(0)
						} else {
							if (typeof e !== 'string') setError(JSON.stringify(e))
							else setError(e)
						}
					})
			} catch (err) {
				if (typeof err !== 'string') setError(JSON.stringify(err))
				else setError(err)
			}
		}
	}, [presignedUploadUrl.data?.presignedUrl?.key])

	useEffect(() => {
		if (initialFile && !hasUsedInitialData) {
			setAsset(initialFile)
			setStatus(UploadFileStatus.COMPLETE)
			setHasUsedInitialData(true)
			if (handleFileUrl) handleFileUrl(initialFile.resource_path)
		}
	}, [initialFile])

	return (
		<>
			<InputFile
				{...props}
				handleUpload={handleUpload}
				type={type}
				status={status}
				progress={progress}
				error={error}
				asset={asset}
				handleCancelUpload={handleCancelUpload}
				file={file}
				clearError={clearError}
				handleDelete={handleDelete}
			/>
		</>
	)
}
