import { useParams } from 'react-router-dom'

import { LiteralTypes } from '../@types/types'

export const useWurrlyParams = <T extends string | number | symbol, U = number>(): LiteralTypes<T, U> => {
	const params = useParams<LiteralTypes<T>>()

	Object.keys(params).forEach((key) => {
		const keyFixed = key as keyof typeof params
		const value = params[keyFixed]
		const numberValue = Number(value)

		if (!isNaN(numberValue)) {
			params[keyFixed] = numberValue
		}
	})

	return params as LiteralTypes<T, U>
}
