import { Typography, TypographyProps } from '@material-ui/core'

type DialogTitleProps = {
	text: string
}
export const DialogTitle = ({ text, ...props }: DialogTitleProps & TypographyProps) => (
	<Typography variant="h4" {...props}>
		<b>{text}</b>
	</Typography>
)
