import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { Price_Type_Enum } from 'src/graphql/autogenerate/schemas'

type SelectFilterProps = {
	value: Price_Type_Enum | string
	susbcription?: boolean
	onChange?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => void
}

export const SelectFilter = ({ value, onChange, susbcription = true }: SelectFilterProps) => {
	const options = []

	if (susbcription) {
		options.push({ name: 'None', value: 'None' })
		options.push({ name: 'Annual', value: Price_Type_Enum.Annual })
		options.push({ name: 'Monthly', value: Price_Type_Enum.Monthly })
	}

	return (
		<Box display="flex" alignItems="center">
			<Typography style={{ marginRight: '10px' }}>Filter By</Typography>
			<Select style={{ marginRight: '40px' }} options={options} onChange={onChange} value={value} />
		</Box>
	)
}
