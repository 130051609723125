import { Avatar, Box, Typography } from '@material-ui/core'
import { Catalog, Tip } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'

import useStyles from '../ListItem.styles'

export type TipForListItem = Pick<Tip, 'tip_id' | 'title' | 'description' | 'image_path' | 'is_favorite'> & {
	tip_catalog_item?: {
		catalog_item?: {
			catalog?: Pick<Catalog, 'name'>
		}
	}[]
}

type ListVideoItemProps = {
	tip: TipForListItem
	onClick: (tip: TipForListItem) => void
}

export const ListVideoItem = ({ tip, onClick }: ListVideoItemProps) => {
	const styles = useStyles()
	const handleClick = () => onClick(tip)

	return (
		<Box className={styles.container} onClick={handleClick}>
			<Avatar variant="rounded" src={buildImagePath(tip.image_path)} />
			<Box className={styles.info}>
				<Typography variant="caption">
					<strong>{tip.title}</strong>
				</Typography>
			</Box>
		</Box>
	)
}
