import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const use404Styles = makeStyles((theme: Theme) =>
	createStyles({
		grid: {
			flexGrow: 1,
			marginTop: 60,
			width: 920
		},
		title: {
			fontSize: 20
		},
		itemButton: {
			'& .main': {
				padding: '15px 20px'
			}
		},
		button: {
			pointerEvents: 'none',
			backgroundColor: theme.palette.common.white,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	})
)

export default use404Styles
