import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { HiddenPassword } from 'src/components/Profile/HiddenPassword'
import { InfoItem } from 'src/components/Profile/InfoItem'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { TEACHING_ENVS } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'

export const ProfileInfo = () => {
	const history = useHistory()
	const goToEdit = () => {
		history.push(buildRouteParameters(Pages.ProfileEdit))
	}

	return (
		<ProfileSection
			title="Information"
			body={<InfoContent />}
			editBtn={{
				label: 'Edit Profile',
				action: goToEdit
			}}
		/>
	)
}

const InfoContent = () => {
	const [teachingEnvironment, setTeachingEnvironment] = useState('')
	const [organizationName, setOrganizationName] = useState('')
	const { teacherData } = useLoginContext()
	const latName = teacherData.name_last ? ` ${teacherData.name_last}` : ''

	useEffect(() => {
		const { role_name, organization_name, school } = teacherData
		setTeachingEnvironment(TEACHING_ENVS[role_name])

		if (school) setOrganizationName(school.name)
		else setOrganizationName(organization_name ?? '')
	}, [teacherData])

	return (
		<Box>
			<InfoItem title="Full Name" value={`${teacherData.name_first}${latName}`} />

			<InfoItem title="Email" value={teacherData.email} />

			<InfoItem title="Teaching Environment" value={teachingEnvironment} />

			<InfoItem title="Organization Name" value={organizationName} />

			<InfoItem title="Password" value={<HiddenPassword length={8} />} />
		</Box>
	)
}
