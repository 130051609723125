// Amount of PX that represent a second of song in the slider
export const PX_PER_SECOND = 200

// The left position of the slider it self
export const INITIAL_X_OFFSET = 230

// The position of the chord in case there is no overlap
export const CHORD_MAIN_Y_POS = 100

// The position of the chord in case it overlaps with the previous chord
export const CHORD_ASC_Y_POS = 40

// The position of the lyric in case there is no overlap
export const LYRIC_MAIN_Y_POS = 120

// The position of the lyric in case there is no overlap and is on audio
export const LYRIC_MAIN_Y_POS_AUDIO = 170

// The position of the lyric in case it overlaps with the previous lyric and is not audio
export const LYRIC_DESC_Y_POS = 90

// The position of the lyric in case it overlaps with the previous lyric and is audio
export const LYRIC_DESC_Y_POS_AUDIO = 140

// The position of the lyric in case it overlaps with the anteultimate lyric and is not audio
export const LYRIC_SECOND_DESC_Y_POS = 60

// The position of the lyric in case it overlaps with the anteultimate lyric and is audio
export const LYRIC_SECOND_DESC_Y_POS_AUDIO = 110
