import { Divider } from '@material-ui/core'
import { ActivityBoxEmpty } from 'src/components'

import useStyles from './InsightsEmptyState.styles'

export const InsightsEmptyState = () => {
	const styles = useStyles()

	return (
		<>
			<Divider className={styles.divider} />
			<ActivityBoxEmpty
				title="You have no insights to check yet."
				description="Once your students start engaging, their insights will appear here. "
				noBorder
				className={styles.emptyState}
			/>
			<Divider className={styles.divider} />
		</>
	)
}
