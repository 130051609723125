import React from 'react'

import { Box, styled, Typography, Grid } from '@material-ui/core'

import { BaseDialog } from '../../../../components'
import {
	PRACTICE_PRO_BADGE,
	RECORDING_ARTIST_BADGE,
	CHALLENGE_CHAMP_BADGE,
	GOAL_MEDALIST_BADGE,
	STREAK_VICTOR_BADGE,
	ULTIMATE_EXPLORER_BADGE
} from '../constants'

type HowToEarnBadgesProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type BadgeContent = {
	name: string
	imageUrl: string
	description: string
}

const badges_content: BadgeContent[] = [
	{
		name: 'Practice Pro',
		imageUrl: PRACTICE_PRO_BADGE,
		description:
			'Earn this badge and grab 3 extra points by completing at least 3 unsaved practice sessions per week!'
	},
	{
		name: 'Recording Artist',
		imageUrl: RECORDING_ARTIST_BADGE,
		description: 'Earn this badge and grab 5 extra points by saving at least 3 recordings per week!'
	},
	{
		name: 'Challenge Champ',
		imageUrl: CHALLENGE_CHAMP_BADGE,
		description: 'Earn this badge and grab 10 extra points by completing the weekly WURRLY song challenge!'
	},
	{
		name: 'Goal Medalist',
		imageUrl: GOAL_MEDALIST_BADGE,
		description: 'Earn this badge and grab 15 extra points by hitting your weekly practice goals!'
	},
	{
		name: 'Streak Victor',
		imageUrl: STREAK_VICTOR_BADGE,
		description: 'Earn this badge and grab 5 extra points by logging on at least 3 days in a week!'
	},
	{
		name: 'Ultimate Explorer',
		imageUrl: ULTIMATE_EXPLORER_BADGE,
		description:
			'Earn this badge and grab 5 extra points by exploring our music catalog, and streaming at least 10 songs in a week!'
	}
]

export const HowToEarnBadges = ({ isOpen, setIsOpen }: HowToEarnBadgesProps) => {
	const closeModal = () => setIsOpen(false)

	return (
		<BaseDialog
			confirmLabel="Got it!"
			onConfirm={closeModal}
			onConfirmProps={{ style: { width: '100%' } }}
			open={isOpen}
			onClose={closeModal}
			dividers={false}
			body={<BadgesBody />}
			paperProps={{ style: { maxWidth: '50%' } }}
		/>
	)
}

const StyledGrid = styled(Grid)({
	width: '100%',
	height: '100%'
})

const StyledFooter = styled(Box)({
	position: 'sticky',
	bottom: 0,
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	background: 'white',
	paddingTop: '1em'
})

const BadgesBody = () => {
	return (
		<Box>
			<StyledGrid container spacing={2}>
				{badges_content.map((badge) => (
					<Badge badge={badge} key={badge.name} />
				))}
			</StyledGrid>
			<StyledFooter>
				<Typography>
					<strong>Earn points to unlock backgrounds!</strong>
				</Typography>
			</StyledFooter>
		</Box>
	)
}

const StyledBadge = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	padding: '.5em',
	textAlign: 'center',
	'& img': {
		margin: '0 .5em 1em',
		maxWidth: '200px',
		marginLeft: 'auto',
		marginRight: 'auto'
	}
})

type BadgeProps = {
	badge: BadgeContent
}

const Badge = ({ badge }: BadgeProps) => {
	return (
		<Grid item xs={4}>
			<StyledBadge>
				<img src={badge.imageUrl} alt={`${badge.name} badge`} />
				<Typography variant="body2">{badge.description}</Typography>
			</StyledBadge>
		</Grid>
	)
}
