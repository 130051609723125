import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { InfoDialog, MainTitle } from 'src/components'
import { useGetTipsByIdForEditQuery } from 'src/graphql/autogenerate/hooks'
import { Class_Tip } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, buildRouteParameters } from 'src/utils'

import { EditVideoForm } from '../../components/EditVideoForm/index'

export const EditVideo = ({ page }: PagesProps) => {
	const history = useHistory()

	const params = useWurrlyParams<typeof Pages.EditVideo.params[number]>()
	const { tipId } = params

	const [onCancel, setOnCancel] = useState(false)
	const closeCancelDialog = () => {
		setOnCancel(false)
	}

	const { data: tipData } = useGetTipsByIdForEditQuery({
		variables: {
			tipId
		}
	})

	const goToPreviousPage = (): void => {
		history.push(buildRouteParameters(Pages.Videos))
	}

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: []
	})

	// scroll to top
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Box>
			<MainTitle breadcrumbs={breadcrumbs} title={page.name} />

			{tipData && (
				<EditVideoForm
					tipId={tipId}
					asset_path={tipData.tip[0].asset_path || ''}
					title={tipData.tip[0].title || ''}
					classTips={tipData.tip[0].class_tips as unknown as Class_Tip[]}
					description={tipData.tip[0].description || ''}
					thumbnail={tipData.tip[0].image_path || ''}
					url={tipData.tip[0].resource_path || ''}
					subjects={tipData.tip[0].subjects.map((subject) => subject.catalog_item) || []}
					ages={tipData.tip[0].ages.map((age) => age.catalog_item) || []}
					instruments={tipData.tip[0].instruments.map((instrument) => instrument.catalog_item) || []}
					keywords={tipData.tip[0].tip_keywords.map((keyword) => keyword.keyword) || []}
				/>
			)}

			<InfoDialog
				open={onCancel}
				onConfirm={closeCancelDialog}
				onClose={closeCancelDialog}
				onDiscard={goToPreviousPage}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				confirmLabel="No, Cancel"
				icon="!"
			/>
		</Box>
	)
}
