import { makeStyles } from '@material-ui/styles'

export const useOptionButtonStyles = makeStyles({
	button: {
		backgroundColor: '#fff',
		outline: '1px solid #ddd',
		display: 'flex',
		height: 160,
		position: 'relative',
		width: '100%',
		'&:hover': {
			outline: '2px solid #F3AB34',
			backgroundColor: '#FFF8E5'
		},
		'& .selectedCheckIcon': {
			color: '#F3AB34',
			display: 'none',
			position: 'absolute',
			right: 10,
			top: 10
		}
	},
	buttonSelected: {
		outline: '2px solid #F3AB34',
		'& .selectedCheckIcon': {
			display: 'block'
		}
	}
})

export default useOptionButtonStyles
