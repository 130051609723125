import { Box, TextField, styled, Typography, Link, Divider } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useHistory } from 'react-router-dom'
import { InfoDialog } from 'src/components'
import { InfoItem } from 'src/components/Profile/InfoItem'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { StudentPages } from 'src/routes/studentpages'
import { useEditProfileContext } from 'src/scenes/Student/scenes/5-Profile/Components/hooks/useEditProfileData'
import { buildRouteParameters } from 'src/utils'

export const EditProfileInfo = () => {
	return <ProfileSection body={<EditProfileBody />} />
}

const StyledInput = styled(TextField)({
	marginTop: '1em'
})

const EditProfileBody = () => {
	const history = useHistory()
	const { isLoading, updateError, errorMessage, setUpdateError, updateSuccess, formik } = useEditProfileContext()

	const goToProfile = () => {
		history.push(buildRouteParameters(StudentPages.Profile))
		window.location.reload()
	}

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<InfoItem
					style={{ paddingRight: '10px' }}
					title="Name*"
					value={
						<StyledInput
							variant="outlined"
							value={formik.values.firstName}
							onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
							fullWidth
							disabled={isLoading}
						/>
					}
				/>
				<InfoItem
					style={{}}
					title="Last Name*"
					value={
						<StyledInput
							variant="outlined"
							value={formik.values.lastName}
							onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
							fullWidth
							disabled={isLoading}
						/>
					}
				/>
				<InfoItem
					style={{ paddingRight: '10px' }}
					title="Stage Name*"
					value={
						<StyledInput
							variant="outlined"
							value={formik.values.stageName}
							onChange={(e) => formik.setFieldValue('stageName', e.target.value)}
							error={formik.values.stageName.length > 20}
							fullWidth
							disabled={isLoading}
						/>
					}
				/>

				<InfoDialog
					open={updateError}
					onClose={() => setUpdateError(false)}
					title="Couldn't update your information"
					body={
						<>
							<Typography variant="h6" style={{ fontSize: '1em' }} color="error">
								<strong>Error:</strong>
							</Typography>
							<Typography>
								{errorMessage}
								<Divider style={{ margin: '1em' }} />
								Please try again. <br />
								If the problem persists, contact support:{' '}
								<Link color="secondary" target="_blank" href="mailto:support@wurrlyedu.com">
									support@wurrlyedu.com
								</Link>
							</Typography>
						</>
					}
					discardLabel="Close"
					onDiscard={() => {
						setUpdateError(false)
					}}
					icon="!"
				/>
				<InfoDialog
					open={updateSuccess}
					icon={<CheckIcon />}
					title="Profile Saved!"
					body="Your Profile information was saved successfully!"
					discardLabel="Return to Profile"
					onDiscard={goToProfile}
				/>
			</form>
		</Box>
	)
}
