import { useEffect, useState } from 'react'

import { Box, Link, Typography } from '@material-ui/core'
import { InfoItem } from 'src/components/Profile/InfoItem'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { useLoginContext } from 'src/hooks/useLogin'
import { TEACHING_ENVS } from 'src/utils'
export const EditDisabledOptions = () => {
	return <ProfileSection body={<DisabledOptionsBody />} />
}

const DisabledOptionsBody = () => {
	const [teachingEnvironment, setTeachingEnvironment] = useState('')
	const [organizationName, setOrganizationName] = useState('')
	const { teacherData } = useLoginContext()

	useEffect(() => {
		const { role_name, organization_name, school } = teacherData
		setTeachingEnvironment(TEACHING_ENVS[role_name])

		if (school) setOrganizationName(school.name)
		else setOrganizationName(organization_name ?? '')
	}, [teacherData])

	return (
		<Box>
			<InfoItem title="Email" value={teacherData.email} disabled />

			<InfoItem title="Teaching Environment" value={teachingEnvironment} disabled />

			<InfoItem title="Organization Name" value={organizationName} disabled />

			<Typography style={{ color: '#7C7C7C' }}>
				** Please contact us at&nbsp;
				<Link color="textSecondary" target="_blank" href="mailto:admin@wurrlyedu.com">
					admin@wurrlyedu.com
				</Link>
				&nbsp;to edit your Email, Teaching Enviroment or Organization Name
			</Typography>
		</Box>
	)
}
