import { useRef } from 'react'

import { Box, Card, Fab, IconButton, Typography } from '@material-ui/core'
import { Check, RefreshOutlined } from '@material-ui/icons'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import clsx from 'clsx'
import { BaseSlider, BigBaseButton } from 'src/components'
import { TextInput } from 'src/components/Inputs/TextInput/TextInput'

import useStyles from './TimeLyricsView.styles'
import { useTimeLyricsView } from './useTimeLyricsView'

export const TimeLyricsView = () => {
	const styles = useStyles()
	const ref = useRef<HTMLTextAreaElement>()
	const {
		formik,
		validity,
		audio,
		handleTimeChange,
		formatTime,
		progress,
		lyricsArray,
		coords,
		playing,
		time,
		handlePlayPauseControl,
		resetTimingRow,
		goNextWord,
		boxRef,
		areLyricsTimesFullfilled,
		getCoordinateButtonId,
		edittingMode,
		setEdittingMode,
		getMinutes,
		updateMinuteForWordAtCoords,
		updateSecondForWordAtCoords,
		getWordMinuteAtCoords,
		getWordSecondAtCoords
	} = useTimeLyricsView()

	const highlight = (index: number) => {
		if (!ref.current) return

		const value = ref.current?.value ?? ''
		const lines = value.split('\n').filter(Boolean)

		const lineContent = lines[index - 1]
		const firstIndex = value.indexOf(lineContent)
		const lastIndex = value.indexOf('\n', firstIndex)

		ref.current.focus()
		ref.current.setSelectionRange(firstIndex, lastIndex)
	}

	return (
		<div>
			{edittingMode === 'writting' ? (
				<>
					<TextInput
						inputRef={ref}
						multiline
						label="Song lyrics"
						name="lyrics"
						value={formik.values.lyrics}
						onChange={formik.handleChange}
						required
					/>
					<Box className={styles.helpText}>
						<div className={styles.errorContainer}>
							{validity.conjunction.length > 0 && (
								<div>
									Friendly tip: You can press the number of the line to highlight.
									<div>
										<span>{validity.message}: </span>
										<div className={styles.fixButtonsContainer}>
											{validity.conjunction.map((item, key) =>
												item.type === 'literal' ? (
													<span key={key}>
														{item.value.trim() === 'and' ? (
															<>&nbsp;{item.value}&nbsp;</> // and
														) : (
															<>{item.value}&nbsp;</> // comma
														)}
													</span>
												) : (
													<button
														onClick={() => highlight(parseInt(item.value, 10))}
														key={key}
														className={styles.fixButton}>
														{item.value}
													</button>
												)
											)}
										</div>
									</div>
								</div>
							)}
						</div>
						<span>25 characters per line</span>
					</Box>

					<Box className={styles.processLyricsContainer}>
						<BigBaseButton
							disabled={validity.hasErrors}
							style={{ width: '216px' }}
							onClick={() => setEdittingMode('timing')}>
							Process lyrics times
						</BigBaseButton>
					</Box>
				</>
			) : (
				<Box mt={4}>
					<Card ref={boxRef}>
						<Box py={2}>
							<ul className={styles.lyricsBox}>
								{lyricsArray.map((line, $0) => (
									<li key={$0} className={clsx({ [styles.currentRow]: $0 === coords[0] })}>
										<Box>
											{line.map((word, $1) => (
												<button
													id={getCoordinateButtonId([$0, $1])}
													className={clsx({
														active: $0 === coords[0] && $1 === coords[1]
													})}
													onKeyDown={(event) => {
														event.stopPropagation()

														if (event.key === 'Tab') {
															event.preventDefault()
														}

														if (event.key === ' ') {
															goNextWord()
														}
													}}
													key={$1}>
													{word.time !== undefined && (
														<div className={styles.timeRecord}>
															<select
																value={getWordMinuteAtCoords([$0, $1])}
																onChange={(event) =>
																	updateMinuteForWordAtCoords(
																		[$0, $1],
																		event.target.value
																	)
																}
																className={styles.select}>
																<optgroup label="minute">
																	{getMinutes().map((minute, k) => (
																		<option value={minute} key={k}>
																			{minute}
																		</option>
																	))}
																</optgroup>
															</select>
															:
															<select
																value={getWordSecondAtCoords([$0, $1])}
																onChange={(event) =>
																	updateSecondForWordAtCoords(
																		[$0, $1],
																		event.target.value
																	)
																}
																className={styles.select}>
																<optgroup label="second">
																	{Array.from({ length: 60 }, (_, idx) => (
																		<option
																			key={idx}
																			value={idx
																				.toString()
																				.padStart(2, '0')}>
																			{idx.toString().padStart(2, '0')}
																		</option>
																	))}
																</optgroup>
															</select>
														</div>
													)}

													<span
														className={clsx({
															[styles.currentRowText]:
																($0 === coords[0] && $1 === coords[1]) ||
																word.time !== undefined
														})}>
														{word.text}
													</span>
												</button>
											))}
										</Box>
										{line.some(({ time }) => time !== undefined) && (
											<IconButton onClick={() => resetTimingRow($0)}>
												<RefreshOutlined className={styles.flippedRedoIcon} />
											</IconButton>
										)}
									</li>
								))}
								{areLyricsTimesFullfilled && (
									<li id="completed-alert" className={styles.completedListItem}>
										<span className={styles.checkIcon}>
											<Check />
										</span>

										<span>COMPLETE</span>
									</li>
								)}
							</ul>
						</Box>
					</Card>
				</Box>
			)}

			{edittingMode === 'timing' && (
				<>
					<Box className={styles.boxFab}>
						<Fab className={styles.fab} size="medium" onClick={handlePlayPauseControl}>
							{playing ? <PauseIcon color="secondary" /> : <PlayArrowIcon color="secondary" />}
						</Fab>
						<Box className={styles.boxSongTime} boxShadow={1}>
							<Typography className={styles.tipoSongTime}>
								{formatTime(time)} / {formatTime(audio.duration || 0)}
							</Typography>
							<Box className={styles.boxSlider}>
								<BaseSlider
									value={progress}
									onChange={handleTimeChange}
									aria-labelledby="continuous-slider"
									color="secondary"
								/>
							</Box>
						</Box>
					</Box>

					<Box className={styles.processLyricsContainer}>
						<BigBaseButton
							disabled={validity.hasErrors}
							style={{ width: '216px' }}
							onClick={() => setEdittingMode('writting')}>
							Rewrite lyrics
						</BigBaseButton>
					</Box>
				</>
			)}
		</div>
	)
}
