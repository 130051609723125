import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ListLessons } from 'src/components'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'

import useStyles from './SkillCoveredBySavedLessons.styles'

type SkillItemProps = {
	teacherId: number
	skillName: string
	lessons: Lesson_Plan[]
}
export const SkillItem = ({ teacherId, skillName, lessons }: SkillItemProps) => {
	const styles = useStyles()

	return (
		<Accordion key={skillName} className={styles.Accordion}>
			<AccordionSummary className={styles.AccordionSumary} expandIcon={<ExpandMoreIcon />}>
				<Typography className={styles.accordionTitle}>{skillName}</Typography>
			</AccordionSummary>
			<AccordionDetails className={styles.accordionDetails}>
				<ListLessons
					teacherId={teacherId}
					spacing={1.5}
					data={lessons}
					addAssignmentButton={false}
					addclassButton={false}
					standardButton={false}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
