import { useState } from 'react'

import { useGetClassesNamesQuery, useGetClassAssignmentsExtendedQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Student_Activity_Order_By } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { SortOrder } from 'src/utils'

export const useStudentAssignments = () => {
	const PAGE_ELEMENTS = 5

	const { teacherData } = useLoginContext()
	const [selectedClassId, setSelectedClassId] = useState(0)
	const [sort, setSort] = useState<SortOrder>(SortOrder.Up)
	const [sortBy, setSortBy] = useState<SortOrder>(SortOrder.Up)
	const [order, setOrder] = useState<Student_Activity_Order_By>({
		student_activity_type: Order_By.Asc
	})
	const { data: classes } = useGetClassesNamesQuery({
		variables: { teacherId: teacherData.teacher_id },
		skip: !teacherData.teacher_id
	})

	const { data, loading, refetch } = useGetClassAssignmentsExtendedQuery({
		variables: {
			classId: selectedClassId,
			offset: 0,
			limit: PAGE_ELEMENTS,
			order: sortBy as unknown as Order_By
		},
		skip: !selectedClassId
	})

	return {
		PAGE_ELEMENTS,
		data,
		loading,
		refetch,
		classes,
		setSelectedClassId,
		selectedClassId,
		setSort,
		sort,
		order,
		setOrder,
		setSortBy,
		sortBy
	}
}
