import React, { useEffect, useState } from 'react'

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useGetFavoriteSongsQuery } from 'src/graphql/autogenerate/hooks'
import { Song } from 'src/graphql/autogenerate/schemas'

import { PagesProps } from '../../../../@types'
import { DoubleMusicNoteIcon } from '../../../../assets/icons/CustomIcons'
import { AccordionWrapper, ImageButton, ListSongs, MainTitle, SelectSort } from '../../../../components'
import { SeeAllBtnTooltip } from '../../../../components/SeeAllTooltip'
import { Pages } from '../../../../routes/teacherPages'
import { SortOrder } from '../../../../utils'
import { DEFAULT_CLASS_SONG_IMG, MAX_FAVOURITE_ITEMS } from '../../../../utils/constants'
import { shouldSortTitleOrDate } from '../../../../utils/utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btn: {
			pointerEvents: 'none',
			backgroundColor: theme.palette.common.white,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		},
		banner: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '14/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		}
	})
)

export const MyFavorites = ({ page, teacherId }: PagesProps) => {
	const classes = useStyles()
	const [sortSongs, setSortSongs] = useState(SortOrder.Up)
	const [songs, setSongs] = useState<Song[]>([])
	const [totalSongs, setTotalSongs] = useState(0)
	const [orderTitle, orderDate] = shouldSortTitleOrDate(sortSongs)
	const studentId = teacherId || 0

	const [limitSongs, setLimitSongs] = useState(MAX_FAVOURITE_ITEMS)

	const { data: favoriteDataQuery, loading: loadingSongs } = useGetFavoriteSongsQuery({
		variables: {
			studentId,
			limit: limitSongs,
			orderTitle,
			orderDate
		}
	})

	useEffect(() => {
		if (!loadingSongs && favoriteDataQuery?.student_song_favorite.length) {
			setSongs(favoriteDataQuery?.student_song_favorite.map((fv) => fv.song) as Song[])
			setTotalSongs(favoriteDataQuery?.student_song_favorite_aggregate?.aggregate?.count || 0)
		}
	}, [favoriteDataQuery])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		setSort: React.Dispatch<React.SetStateAction<SortOrder>>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Here are the Songs, Videos & Lessons that you've Favorited."
			/>

			{loadingSongs ? (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			) : songs.length > 0 ? (
				<Box pt={3}>
					{songs.length > 0 && (
						<AccordionWrapper
							defaultExpanded
							summary={
								<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
									<Typography variant="h6">
										<b>Song Favorites ({totalSongs})</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />

									<SelectSort
										cyKey="Songs"
										showRecent
										value={sortSongs}
										onChange={(e) => handleSort(e, setSortSongs)}
									/>
								</Box>
							}
							details={
								songs && (
									<ListSongs
										teacherId={teacherId}
										allTranspositions
										lessonsButton
										spacing={1.5}
										data={songs}
									/>
								)
							}
							actions={
								songs.length > 0 && (
									<SeeAllBtnTooltip
										canFetchMore={songs.length < totalSongs}
										name="songs"
										onClick={() =>
											setLimitSongs((prevState) => prevState + MAX_FAVOURITE_ITEMS)
										}
									/>
								)
							}
						/>
					)}
				</Box>
			) : (
				<Box>
					<Divider />
					<Box my={4} textAlign="center">
						<Typography variant="h6" color="textSecondary">
							You haven't Favorited any content yet.
						</Typography>
						<Typography color="textSecondary">
							It's easy to find your next Favorited by exploring the content below.
						</Typography>
					</Box>
					<Divider />
				</Box>
			)}

			{songs?.length <= 0 && (
				<Box pt={3} pb={2}>
					<Typography variant="h6">
						<b>Explore Content</b>
					</Typography>
				</Box>
			)}
			<Grid container spacing={3}>
				{songs?.length <= 0 && (
					<Grid item xs={12} sm={12} md={6}>
						<ImageButton
							titleHeight={{ xs: 232 }}
							backgroundImageUrl={DEFAULT_CLASS_SONG_IMG}
							className={classes.banner}
							title={<Typography style={{ fontSize: 28 }}>Discover Music</Typography>}
							titlePosition="initial"
							element={
								<Button className={classes.btn} startIcon={<DoubleMusicNoteIcon />}>
									{Pages.MusicCatalog.name}
								</Button>
							}
							elementPosition={{ top: 'auto', right: 16, left: 'auto', bottom: 16 }}
							linkTo={Pages.MusicCatalog.path}
						/>
					</Grid>
				)}
			</Grid>
		</Box>
	)
}
