import { Fragment, useState } from 'react'

import { Box, Divider, List } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { noop } from 'lodash'
import { useHistory } from 'react-router-dom'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { Playlist, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, concatenate, getPlaylistGenres } from 'src/utils'

import { AddPlaylistToClass } from './AddPlaylistToClass'
import { ListItemPlaylist } from './ListItemPlaylist/ListItemPlaylist'

const useStyles = makeStyles((theme: Theme) => ({
	boxPlaylist: {
		padding: 0,
		'& .listItem': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

type ListPlaylistsProps = {
	spacing?: number
	lineDivider?: boolean
	data?: Playlist[]
}

export const ListPlaylists = ({ spacing = 0, lineDivider, data }: ListPlaylistsProps) => {
	const history = useHistory()

	const classes = useStyles(spacing)
	const [addToClassDialog, setAddToClassDialog] = useState<Playlist>()
	const [isAddPlaylisToClassOpen, setIsAddPlaylistToClassOpen] = useState(false)
	const [playlistId, setPlaylistId] = useState<number>()
	const [isOpen, setIsOpen] = useState(false)

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	return (
		<>
			{isOpen && playlistId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={playlistId}
					productName={Product_Name_Enum.Playlist}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<Fragment>
				<List className={classes.boxPlaylist}>
					{data &&
						data.map((playlist, i) => (
							<Box
								className="listItem"
								style={{
									cursor: 'pointer'
								}}
								key={concatenate([i, playlist.playlist_id, playlist.name], '-')}
								overflow="hidden"
								boxShadow={spacing > 0 ? 1 : 0}
								borderRadius={spacing > 0 ? 4 : 0}
								onClick={() => {
									history.push(
										buildRouteParameters(
											Pages.PlaylistDetails, // Route with paramters
											{
												playlistId: playlist.playlist_id
											}
										)
									)
								}}>
								<ListItemPlaylist
									id={playlist.playlist_id}
									imageUrl={playlist.image_path ?? ''}
									title={playlist.name}
									description={playlist.description}
									genres={getPlaylistGenres(playlist)}
									totalvideos={playlist.tip_playlist.length}
									btnLearnMore={{
										onClick: (e) => {
											e.stopPropagation()
											history.push(
												buildRouteParameters(
													Pages.PlaylistDetails, // Route with paramters
													{
														playlistId: playlist.playlist_id
													}
												)
											)
										}
									}}
									btnAddClass={{
										onClick: (e) => {
											e.stopPropagation()
											setAddToClassDialog(playlist)
											setIsAddPlaylistToClassOpen(true)
										}
									}}
									btnSuscription={{
										onClick: (e) => {
											e.stopPropagation()
											setPlaylistId(playlist.playlist_id)
											handleOpenProductDialog()
										}
									}}
									subscription={playlist.is_purchased_by_teacher?.valueOf()}
									visibility={playlist.lockable_content?.visibility}
								/>
								{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
							</Box>
						))}
				</List>
				<AddPlaylistToClass
					isOpen={isAddPlaylisToClassOpen}
					setIsOpen={setIsAddPlaylistToClassOpen}
					playlist={addToClassDialog}
				/>
			</Fragment>
		</>
	)
}
