import { useCallback, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { StudentPages } from 'src/routes/studentpages'
import { MAX_AMOUNT_STUDENT_WRONG_LOGIN_ANSWER } from 'src/utils'

import { buildGenericContext } from '../../../../../../context/genericContext'
import {
	useGetLoginStudentByCodeLazyQuery,
	useGetStudentLoginAttemptFailedEmailLazyQuery,
	useGetStudentQuestionaryLazyQuery
} from '../../../../../../graphql/autogenerate/hooks'
import { useLoginContext } from '../../../../../../hooks/useLogin'
import { StudentSignInOptions } from '../../../../enums'

const useStudentSignInOptions = () => {
	const history = useHistory()
	const login = useLoginContext()

	const [dialog, setDialog] =
		useState<
			StudentSignInOptions | 'default' | 'studentVerification' | 'privateTeacherVerification' | 'avatar'
		>('default')

	const handleClose = useCallback(() => {
		setDialog('default')
	}, [dialog])

	const goToAvatarSelectionScreen = () => history.push(StudentPages.AvatarSelection.path)
	const [studentCode, setStudentCode] = useState('')
	const [studentAnswerValue, setStudentAnswerValue] = useState<number>()
	const [wrongAnswerCount, setWrongAnswerCount] = useState(0)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)

	const [
		GetStudentQuestionaryQuery,
		{
			data: GetStudentQuestionaryData,
			loading: GetStudentQuestionaryLoading,
			error: GetStudentQuestionaryError
		}
	] = useGetStudentQuestionaryLazyQuery({ fetchPolicy: 'no-cache' })

	const [
		LoginStudentByCodeQuery,
		{ data: LoginStudentByCodeData, loading: LoginStudentByCodeLoading, error: LoginStudentByCodeError }
	] = useGetLoginStudentByCodeLazyQuery({ fetchPolicy: 'no-cache' })

	const [getStudentLoginAttemptFailedEmail] = useGetStudentLoginAttemptFailedEmailLazyQuery({
		fetchPolicy: 'no-cache'
	})

	useEffect(() => {
		if (LoginStudentByCodeError) {
			if (wrongAnswerCount === MAX_AMOUNT_STUDENT_WRONG_LOGIN_ANSWER - 1) {
				setWrongAnswerCount(0)
				setErrorDialogOpen(true)
				getStudentLoginAttemptFailedEmail({ variables: { code: studentCode } })
				setStudentCode('')
				handleClose()
				// if (GetStudentQuestionaryRefetch) GetStudentQuestionaryRefetch()
			} else {
				setWrongAnswerCount(wrongAnswerCount + 1)
			}
		}
	}, [LoginStudentByCodeError])

	useEffect(() => {
		if (
			!GetStudentQuestionaryLoading &&
			GetStudentQuestionaryData &&
			GetStudentQuestionaryData.getStudentQuestionary
		) {
			const isSchoolTeacher = GetStudentQuestionaryData.getStudentQuestionary.isSchoolTeacher
			if (isSchoolTeacher) setDialog('studentVerification')
			else setDialog('privateTeacherVerification')
		}
	}, [GetStudentQuestionaryData, GetStudentQuestionaryLoading])

	useEffect(() => {
		if (studentCode) {
			GetStudentQuestionaryQuery({ variables: { filter: { code: studentCode } } })
		}
	}, [studentCode])

	useEffect(() => {
		if (studentAnswerValue && studentCode) {
			LoginStudentByCodeQuery({ variables: { filter: { code: studentCode, id: studentAnswerValue } } })
		}
	}, [studentAnswerValue])

	useEffect(() => {
		if (
			!LoginStudentByCodeLoading &&
			LoginStudentByCodeData &&
			LoginStudentByCodeData.loginStudentByCode &&
			LoginStudentByCodeData.loginStudentByCode.token
		) {
			const token = LoginStudentByCodeData.loginStudentByCode.token
			const avatarSelected = LoginStudentByCodeData.loginStudentByCode.isAvatarSelected
			if (token) {
				login.loginSignIn(token)
				if (!avatarSelected) goToAvatarSelectionScreen()
			}
		}
	}, [LoginStudentByCodeData, LoginStudentByCodeLoading])

	return {
		dialog,
		setDialog,
		setStudentCode,
		setStudentAnswerValue,
		history,
		handleClose,
		errorDialogOpen,
		setErrorDialogOpen,
		GetStudentQuestionaryQuery,
		GetStudentQuestionaryData,
		GetStudentQuestionaryLoading,
		GetStudentQuestionaryError,
		LoginStudentByCodeQuery,
		LoginStudentByCodeData,
		LoginStudentByCodeLoading,
		LoginStudentByCodeError
	}
}

export const [StudentSignInOptionsProvider, useStudentSignInOptionsContext] =
	buildGenericContext(useStudentSignInOptions)
