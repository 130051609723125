import { makeStyles } from '@material-ui/core/styles'

export const useAvatarSelectionDialogStyles = makeStyles({
	container: {
		position: 'relative'
	},
	avatar: {
		height: '72px',
		width: '72px',
		cursor: 'pointer',
		fontSize: '25px',
		fontWeight: 'bolder'
	},
	selectedAvatar: {
		borderRadius: '50%',
		border: '3px solid #f2ab34'
	},
	checkIcon: {
		backgroundColor: '#f2ab34',
		borderRadius: '50%',
		color: '#fff',
		height: '0.7em',
		padding: 1,
		position: 'absolute',
		right: 2,
		top: 2,
		width: '0.7em'
	},
	paper: {
		maxWidth: '679px',
		'& .MuiDialogTitle-root': {
			height: '70px'
		}
	},
	btn: {
		height: '56px',
		width: '214px'
	},
	disabledBtn: {
		'&:disabled': {
			backgroundColor: '#FFC20C !important',
			color: '#323342',
			opacity: 0.5,
			boxShadow:
				'0px 4px 6px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1)'
		}
	}
})

export default useAvatarSelectionDialogStyles
