import { useEffect, useState } from 'react'

import {
	Avatar,
	Box,
	CircularProgress,
	Fab,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { IName } from 'src/@types'
import { ScheduleIcon, ThinCheckIcon } from 'src/assets/icons'
import { DropdownInput, InfoDialog, TextInput } from 'src/components'
import { BaseLink } from 'src/components/Buttons/BaseLink'
import { BigBaseButton } from 'src/components/Buttons/BigBaseButton'
import { MessageBox } from 'src/components/MessageBox/MessageBox'
import { WurrlyPlayer, WurrlyToPlay } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import {
	useUpdateWurrlyInsertCommentMutation,
	useUpdateWurrlyUpdateCommentMutation
} from 'src/graphql/autogenerate/hooks'
import { Privacy, Privacy_Enum, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildImagePath, buildRouteParameters, getCyKey, PRIVACY_ARRAY, PRIVACY_LABELS } from 'src/utils'

import { useWurrliesDetailsContext } from '../useWurrliesDetails'

const useStyles = makeStyles((theme) => ({
	listBox: {
		'& .MuiAutocomplete-option[aria-selected="true"]': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(255, 194, 12, 0.3)'
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(255, 194, 12, 0.1)'
		}
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	},
	teacherComment: {
		marginLeft: '30px'
	},
	grade: {
		marginTop: 30,
		display: 'flex',
		alignItems: 'center'
	},
	relative: { position: 'relative' }
}))

type SaveFormType = {
	visibility: Privacy
	comment?: string
}
export const WurrliesDetailsFormConfig = () => {
	const {
		classId,
		teacherId,
		wurrlyDetails,
		classWurrliesDetailsLoading,
		hasPrev,
		hasNext,
		nextValue,
		previousValue,
		processingStatus
	} = useWurrliesDetailsContext()

	const styles = useStyles()
	const history = useHistory()
	const [isSending, setIsSeding] = useState(false)
	const [isPreviousNext, setIsPreviousNext] = useState(false) // false = previous, true = next
	const [onSuccessMutation, setOnSuccessMutation] = useState(false)
	const [onErrorMutation, setOnErrorMutation] = useState(false)
	const [isDataLostWarning, setIsDataLostWarning] = useState(false)
	const [isOpenContinueDataLostWarning, setIsOpenContinueDataLostWarning] = useState(false)
	const [isOpenGoBackDataLostWarning, setIsOpenGoBackDataLostWarning] = useState(false)
	const [updateWurrlyInsertCommentMutation] = useUpdateWurrlyInsertCommentMutation()
	const [updateWurrlyUpdateCommentMutation] = useUpdateWurrlyUpdateCommentMutation()

	const { handleSubmit, setValue, formState, control, reset } = useForm()

	const [message, setMessage] = useState('')
	const [comment, setComment] = useState('')
	const [privacy, setPrivacy] = useState<IName>()

	useEffect(() => {
		if (formState.isDirty) {
			setIsDataLostWarning(true)
		}
	}, [formState])

	// set values
	useEffect(() => {
		if (wurrlyDetails) {
			const { message, submission_wurrly, privacy: privacyData } = wurrlyDetails
			const comment =
				submission_wurrly?.submission?.teacher_comment ||
				wurrlyDetails?.teacher_wurrly_comments[0]?.comment ||
				''

			const privacyName = PRIVACY_ARRAY.find((p) => p.name === privacyData)

			setPrivacy(privacyName)
			setMessage(message || '')
			setComment(comment || '')
			setValue('visibility', privacyName)
			setValue('comment', comment)
		}
	}, [wurrlyDetails])

	const saveFormData = (formDataToSave: SaveFormType) => {
		if (wurrlyDetails) {
			setIsSeding(true)
			if (wurrlyDetails?.teacher_wurrly_comments.length > 0) {
				updateWurrlyUpdateComment(formDataToSave)
			} else {
				updateWurrlyInsertComment(formDataToSave)
			}
		}
	}

	const updateWurrlyInsertComment = async (formDataToSave: SaveFormType) => {
		if (wurrlyDetails && formDataToSave) {
			try {
				await updateWurrlyInsertCommentMutation({
					variables: {
						wurrlyId: wurrlyDetails?.wurrly_id,
						privacyId: (formDataToSave?.visibility?.name as Privacy_Enum) || wurrlyDetails?.privacy,
						teacherWurrlyComment: {
							wurrly_id: wurrlyDetails?.wurrly_id,
							teacher_id: teacherId,
							comment: formDataToSave.comment || ''
						}
					},
					update: (cache, { data }) => {
						const classToUpdateInCache = data?.update_wurrly?.returning[0].class
						if (!classToUpdateInCache) return
						const identify = cache.identify(classToUpdateInCache)
						cache.evict({
							id: identify,
							fieldName: 'wurrlies'
						})
					}
				})
				setOnSuccessMutation(true)
				reset()
				setIsDataLostWarning(false)
			} catch (err) {
				console.error(err)
				setOnErrorMutation(true)
			}
		}
	}

	const updateWurrlyUpdateComment = async (formDataToSave: SaveFormType) => {
		if (wurrlyDetails && formDataToSave) {
			try {
				await updateWurrlyUpdateCommentMutation({
					variables: {
						wurrlyId: wurrlyDetails?.wurrly_id,
						privacyId: (formDataToSave?.visibility?.name as Privacy_Enum) || wurrlyDetails?.privacy,
						submissionId: wurrlyDetails?.submission_wurrly?.submission_id || 0,
						teacherComment: formDataToSave.comment || ''
					},
					update: (cache, { data }) => {
						const classToUpdateInCache = data?.update_wurrly?.returning[0].class
						if (!classToUpdateInCache) return
						const identify = cache.identify(classToUpdateInCache)
						cache.evict({
							id: identify,
							fieldName: 'wurrlies'
						})
					}
				})
				setOnSuccessMutation(true)
				reset()
				setIsDataLostWarning(false)
			} catch (err) {
				console.error(err)
			}
		}
	}

	const openContinueDataLostWarning = () => {
		setIsOpenContinueDataLostWarning(isDataLostWarning)
	}

	const openGoBackDataLostWarning = () => {
		setIsOpenGoBackDataLostWarning(isDataLostWarning)
	}

	const handleClickPrev = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault()
		setIsPreviousNext(false)
		if (isDataLostWarning) openContinueDataLostWarning()
		else previousValue()
	}

	const handleClickNext = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault()
		setIsPreviousNext(true)
		if (isDataLostWarning) openContinueDataLostWarning()
		else nextValue()
	}

	const isReviewed =
		wurrlyDetails?.wurrly_type === Wurrly_Type_Enum.Assignment &&
		wurrlyDetails?.submission_wurrly?.submission?.grade
			? true
			: false

	return (
		<Box>
			<form onSubmit={handleSubmit(saveFormData)}>
				{classWurrliesDetailsLoading && (
					<Box m={3} textAlign="center">
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
				{!classWurrliesDetailsLoading && (
					<Box>
						<Box mt={5} mb={2} display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b>Wurrly Details</b>
							</Typography>
						</Box>
						<ListItem>
							<ListItemAvatar>
								<Avatar
									className={styles.large}
									src={buildImagePath(wurrlyDetails?.student?.image_path || '')}
								/>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<Box ml={3}>
										<Typography noWrap variant="h6">
											<b>
												{wurrlyDetails?.student?.first_name || ''}{' '}
												{wurrlyDetails?.student?.last_name || ''}
											</b>
										</Typography>
									</Box>
								}
								secondary={
									<Box ml={3} display="flex">
										<ScheduleIcon />
										<Typography variant="caption" color="textSecondary">
											<b>
												{' '}
												{moment(wurrlyDetails?.created_at).format('ddd, DD MMM HH:mmA')}
											</b>
										</Typography>
									</Box>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								disableTypography
								primary={
									<Box mt={4}>
										<Typography noWrap variant="h6">
											<b data-cy={getCyKey(WurrliesDetailsFormConfig, 'songTitle')}>
												{wurrlyDetails?.track?.song?.title || ''}
											</b>
										</Typography>
									</Box>
								}
								secondary={
									<Box>
										<Typography variant="caption" color="textSecondary">
											<b data-cy={getCyKey(WurrliesDetailsFormConfig, 'songSubTitle')}>
												{wurrlyDetails?.track?.song?.artist?.name || ''}
											</b>
										</Typography>
									</Box>
								}
							/>
						</ListItem>

						{wurrlyDetails && PRIVACY_ARRAY.length > 0 && (
							<Box position="relative">
								<Paper elevation={2} style={{ padding: '40px', width: '100%' }}>
									<WurrlyPlayer
										wurrly={wurrlyDetails as unknown as WurrlyToPlay}
										processingStatus={processingStatus}
										key={wurrlyDetails?.wurrly_id}
									/>
									{!!message && <MessageBox text={message} />}
									{privacy && (
										<Controller
											name="visibility"
											control={control}
											render={({ field: { onChange, value } }) => (
												<DropdownInput
													data-cy={getCyKey(
														WurrliesDetailsFormConfig,
														'visibilityDropdown'
													)}
													id="visibility"
													name="visibility"
													options={
														teacherId
															? PRIVACY_ARRAY.filter(
																	(i) => i.name !== Privacy_Enum.OnlyMe
															  )
															: PRIVACY_ARRAY
													}
													value={value}
													onChange={(_, selectedItem: IName | null) => {
														if (selectedItem) onChange(selectedItem)
													}}
													getOptionLabel={(option) =>
														PRIVACY_LABELS[option.name as Privacy_Enum]
													}
													ListboxProps={{
														className: styles.listBox
													}}
													defaultValue={privacy}
												/>
											)}
										/>
									)}

									{(wurrlyDetails?.submission_wurrly?.submission_id ||
										wurrlyDetails?.teacher_wurrly_comments[0]?.hasOwnProperty('comment') ||
										wurrlyDetails?.teacher_wurrly_comments?.length === 0) && (
										<Controller
											name="comment"
											control={control}
											render={({ field: { onChange, value } }) => (
												<TextInput
													data-cy={getCyKey(WurrliesDetailsFormConfig, 'textInputBox')}
													id="comment"
													name="comment"
													disabled={!isReviewed}
													placeholder={
														!isReviewed
															? 'Reminder: This Wurrly must be reviewed and graded first for comments to enable here'
															: "Teacher's comment 2"
													}
													multiline
													rows={8}
													value={value}
													onChange={(value) => onChange(value)}
													defaultValue={comment}
												/>
											)}
										/>
									)}
								</Paper>
								<Box>
									{hasPrev && (
										<Box
											position="absolute"
											top={560}
											bottom={560}
											left={-21}
											alignItems="center"
											zIndex={10}>
											<Fab
												style={{ backgroundColor: '#fff' }}
												size="small"
												onClick={handleClickPrev}>
												<KeyboardArrowLeft
													data-cy={getCyKey(WurrliesDetailsFormConfig, 'arrowLeft')}
													color="secondary"
												/>
											</Fab>
										</Box>
									)}
									{hasNext && (
										<Box
											position="absolute"
											top={560}
											bottom={560}
											right={-21}
											alignItems="center"
											zIndex={10}>
											<Fab
												style={{ backgroundColor: '#fff' }}
												size="small"
												onClick={handleClickNext}>
												<KeyboardArrowRight
													data-cy={getCyKey(WurrliesDetailsFormConfig, 'arrowRight')}
													color="secondary"
												/>
											</Fab>
										</Box>
									)}
								</Box>
							</Box>
						)}

						<Box mt={6} display="flex" justifyContent="space-between">
							<BigBaseButton
								onClick={(e) => {
									e.preventDefault()
									if (isDataLostWarning) openGoBackDataLostWarning()
									else {
										history.goBack()
									}
								}}>
								<Typography
									data-cy={getCyKey(WurrliesDetailsFormConfig, 'btnGoBack')}
									style={{ margin: '0 65px' }}>
									Go Back
								</Typography>
							</BigBaseButton>
							<BigBaseButton type="submit" color="secondary" disabled={isSending}>
								<Typography
									data-cy={getCyKey(WurrliesDetailsFormConfig, 'btnSaveChanges')}
									style={{ margin: '0 40px' }}>
									Save Changes
								</Typography>
							</BigBaseButton>
						</Box>
					</Box>
				)}
				<InfoDialog
					open={onSuccessMutation}
					onClose={() => {
						setOnSuccessMutation(false)
						setIsSeding(false)
					}}
					onDiscard={() => {
						setOnSuccessMutation(false)
						setIsSeding(false)
						history.push(
							buildRouteParameters(
								Pages.ClassWurrlies, // Route with paramters
								{ classId }
							)
						)
					}}
					onConfirm={() => {
						setOnSuccessMutation(false)
						setIsSeding(false)
					}}
					icon={<ThinCheckIcon />}
					title="Wurrly Updated!"
					body="You have successfully updated this Wurrly."
					discardLabel="Go Back"
					confirmLabel="Done"
					footer={
						<BaseLink
							onClick={() => {
								setOnSuccessMutation(false)
								setIsSeding(false)
								nextValue()
							}}>
							<Typography data-cy={getCyKey(WurrliesDetailsFormConfig, 'btnReviewNextWurrly')}>
								Review Next Wurrly
							</Typography>
						</BaseLink>
					}
				/>
				<InfoDialog
					open={onErrorMutation}
					onClose={() => {}}
					icon="x"
					title="Something went wrong"
					body="Contact us for support"
					confirmLabel="Done"
					onConfirm={() => {
						setOnErrorMutation(false)
					}}
				/>
				<InfoDialog
					open={isOpenContinueDataLostWarning}
					onClose={() => setIsOpenContinueDataLostWarning(false)}
					onDiscard={() => {
						setIsDataLostWarning(false)
						reset()
						setIsOpenContinueDataLostWarning(false)
						if (isPreviousNext) nextValue()
						else previousValue()
					}}
					onConfirm={() => {
						setIsOpenContinueDataLostWarning(false)
					}}
					icon="!"
					title="Continue Without Saving?"
					body="You will lose all the details you've entered up until this point."
					discardLabel={`Yes, Go ${isPreviousNext ? 'Forward' : 'Back'}`}
					confirmLabel="No, Cancel"
				/>
				<InfoDialog
					open={isOpenGoBackDataLostWarning}
					onClose={() => setIsOpenGoBackDataLostWarning(false)}
					onDiscard={() => {
						setIsDataLostWarning(false)
						reset()
						setIsOpenGoBackDataLostWarning(false)
						history.push(
							buildRouteParameters(
								Pages.ClassWurrlies, // Route with paramters
								{ classId }
							)
						)
					}}
					onConfirm={() => {
						setIsOpenGoBackDataLostWarning(false)
					}}
					icon="!"
					title="Go Back Without Saving?"
					body="You will lose all the details you've entered up until this point."
					discardLabel="Yes, Go Back"
					confirmLabel="No, Cancel"
				/>
			</form>
		</Box>
	)
}
