import React, { ChangeEvent, useEffect, useState } from 'react'

import { Box, Grid, Paper } from '@material-ui/core'
import { ActivityBoxEmpty, PaginationList, PaginationListDataItem, SelectSort, Select } from 'src/components'
import { buildStudentActivityPaginationItem } from 'src/components/PaginationList/utils'
import { Order_By } from 'src/graphql/autogenerate/schemas'
import { getCyKey, SortOrder } from 'src/utils'

import useStyles from './StudentsActivities.styles'
import { useStudentActivities } from './useStudentActivities'
export const StudentsActivities = () => {
	const styles = useStyles()
	const {
		PAGE_ELEMENTS,
		dataStudentActivities,
		loadingStudentActivities,
		refetchStudentActivities,
		classes,
		selectedClassId,
		setSelectedClassId,
		setOrder
	} = useStudentActivities()

	const [sort, setSort] = useState<SortOrder>(SortOrder.Type) // internal use in FE only
	const [activityItems, setActivityItems] = useState<PaginationListDataItem[]>([])
	const [loadingStudents, setLoadingStudents] = useState<boolean>(false)

	useEffect(() => {
		setLoadingStudents(loadingStudentActivities)
		if (!loadingStudentActivities && dataStudentActivities?.student_activity) {
			const cyKey = getCyKey(StudentsActivities, 'daysActivites')
			// TODO: handle grouping of events
			const items = dataStudentActivities.student_activity.map((item) =>
				buildStudentActivityPaginationItem(item, cyKey)
			)

			setActivityItems(items)
		}
	}, [dataStudentActivities, loadingStudentActivities])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		switch (selectedSort) {
			case SortOrder.Recent:
				setOrder({ created_at: Order_By.Desc })
				break
			case SortOrder.Type:
				setOrder({ student_activity_type: Order_By.Asc })
				break

			default:
				setOrder({ student_activity_type: Order_By.Asc })
				break
		}
		setSort(selectedSort)
	}

	const handleSelectedClass = (
		e: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => setSelectedClassId(Number(e.target.value))

	const handlePageChange = (page: number) => {
		setLoadingStudents(true)
		refetchStudentActivities({ offset: (page - 1) * PAGE_ELEMENTS })
	}

	return (
		<Grid xs={12} item>
			<Grid xs={12} item className={styles.containerBox}>
				{classes && (
					<Box className={styles.headerBox}>
						<Select
							className={styles.classSelect}
							options={classes?.class?.map((i) => ({ name: i.title, value: i.class_id }))}
							onChange={handleSelectedClass}
							value={selectedClassId}
						/>
						<Box className={styles.dateSelect}>
							<SelectSort
								cyKey="StudentActivities"
								showType
								showRecent
								notAlphabetical
								value={sort}
								onChange={handleSort}
							/>
						</Box>
					</Box>
				)}
			</Grid>
			{activityItems.length > 0 ? (
				<Paper className={styles.noPadding}>
					<PaginationList
						rowsPerPage={PAGE_ELEMENTS}
						limit={activityItems.length}
						data={activityItems}
						onPageChange={handlePageChange}
						loading={loadingStudents}
						totalRows={dataStudentActivities?.student_activity_aggregate.aggregate?.count ?? 0}
					/>
				</Paper>
			) : (
				<Grid xs={12} item>
					<ActivityBoxEmpty
						title="Your Student feed is empty."
						description="Start adding Assignments, Lessons & Modules to get your Students engaged, The activities of your classes will appear here."
					/>
				</Grid>
			)}
		</Grid>
	)
}
