import { useEffect, useMemo, useState } from 'react'

import { Box, Button, CircularProgress, Grid, List, Typography } from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { noop } from 'lodash'
import moment from 'moment'
import { PagesProps } from 'src/@types'
import { CartIcon, DisabledIcon } from 'src/assets/icons'
import { ActivityBoxEmpty, ListModule, MainTitle } from 'src/components'
import { ImageButton } from 'src/components/Buttons/ImageButton'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useGetPartnerDataLazyQuery } from 'src/graphql/autogenerate/hooks'
import {
	Maybe,
	Module,
	Order_Status_Enum,
	Playlist,
	Product_Name_Enum,
	Visibility_Enum
} from 'src/graphql/autogenerate/schemas'
import { useScroll, useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { useProfileSubscription } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useProfileSubscription'
import { AddModuleToClass } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Modules/components/AddModuleToClass'
import { PlaylistCardContainer } from 'src/scenes/Teacher/scenes/8-Videos/scenes/Playlist/components/PlaylistContainer'
import { buildBreadCrumbs, slugToName } from 'src/utils'

import useStyles from './PartnerPage.styles'

export const PartnerPage = ({ page }: PagesProps) => {
	useScroll()
	const styles = useStyles(1.5)
	const params = useWurrlyParams<typeof Pages.ModuleDetails.params[number]>()

	const { partnerId } = params
	const [modulesData, setModulesData] = useState<Module[]>([])
	const [playlistData, setPlaylistData] = useState<Playlist[]>([])

	const [addToClassModule] = useState<Module>()
	const [status, setStatus] = useState<Order_Status_Enum>()
	const [isAddModuleToClassOpen, setIsAddModuleToClassOpen] = useState(false)

	const [getPartner, { data: partnerData, loading: loadingPartner }] = useGetPartnerDataLazyQuery()

	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [isOpen, setIsOpen] = useState(false)

	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const partnerHasBriefDescription =
		partnerData?.partner[0]?.short_description && partnerData?.partner[0]?.short_description?.length > 0
	const partnerHasDescription =
		partnerData?.partner[0]?.description && partnerData?.partner[0]?.description?.length > 0
	const partnerHasModules =
		partnerData?.partner[0]?.partner_modules && partnerData?.partner[0]?.partner_modules?.length > 0
	const partnerHasBanner = partnerData?.partner[0]?.image_path && partnerData?.partner[0]?.image_path?.length > 0

	const partnerHasPlaylists = Number(partnerData?.partner?.[0]?.partner_playlists?.length) > 0

	const { data, loading } = useProfileSubscription()
	const [expiresAt, setExpiresAt] = useState<string>()
	const [showExpiresAt, setShowExpiresAt] = useState<boolean>(false)

	useEffect(() => {
		if (data && !loading) {
			const url = window.location.href

			const matchingElement = data.find((element) => {
				return url.includes(`${element.productType}/${element.productId}`)
			})
			const currentDate = moment()
			const targetDate = moment(matchingElement?.expiresAt)

			const duration = moment.duration(targetDate.diff(currentDate))
			const days = duration.asDays()
			setExpiresAt(days.toFixed(0))
			setShowExpiresAt(!matchingElement || duration.asDays() < 0 ? false : true)
			setStatus(matchingElement?.status)
		}
	}, [data])

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: 'Partners'
			},
			{
				index: 2,
				overrideName: slugToName(partnerData?.partner[0]?.title)
			}
		],
		params
	})

	useEffect(() => {
		if (partnerId) getPartner({ variables: { partnerId } })
	}, [partnerId])

	useEffect(() => {
		if (partnerData && !loadingPartner) {
			const isSubscribed = partnerData.partner[0]?.is_purchased_by_teacher
			setModulesData(partnerData.partner[0]?.partner_modules.map((module) => module.module as Module) || [])
			setSubscription(isSubscribed)
			setVisibility(partnerData.partner[0]?.lockable_content.visibility)
			setPlaylistData(
				partnerData.partner[0]?.partner_playlists.map((playlist) => playlist.playlist as Playlist) || []
			)
		}
	}, [partnerData, loadingPartner])

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	const subscriptionLabel = useMemo(() => {
		if (visibility !== Visibility_Enum.ForSale) return []

		if (status === Order_Status_Enum.Canceled)
			return [
				{
					variant: 'contained',
					className: 'subscribed',
					color: 'secondary',
					style: {
						opacity: 0.8
					},
					startIcon: <DisabledIcon style={{ opacity: 0.8 }} />,
					name: 'UNSUBSCRIBED'
				}
			]
		if (subscription)
			return [
				{
					variant: 'contained',
					className: 'subscribed',
					color: 'primary',
					style: {
						color: 'black',
						backgroundColor: '#BDEAD7',
						border: '2px solid #ADCEC1',
						borderRadius: ' 4px'
					},
					startIcon: <CheckBoxOutlinedIcon style={{ color: '#000000' }} />,
					name: 'SUBSCRIBED'
				}
			]

		return [
			{
				variant: 'contained',
				startIcon: <CartIcon style={{ color: '#221F31' }} />,
				onClick: handleOpenProductDialog,
				name: 'SUBSCRIBE TO PARTNER',
				style: {
					minWidth: '172px',
					background: 'linear-gradient(256.66deg, #FFC20C 21.57%, #EDA42B 61.82%)'
				}
			}
		]
	}, [subscription, visibility, status])

	return (
		<>
			{isOpen && partnerId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={partnerId}
					productName={Product_Name_Enum.Partner}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<Box>
				<MainTitle
					breadcrumbs={breadcrumbs}
					title={partnerData?.partner[0]?.title}
					rightActions={subscriptionLabel as []}
				/>
				{showExpiresAt && (
					<Typography
						variant="overline"
						style={{
							fontSize: '14px'
						}}>
						Subscription expires on:{' '}
						<b
							style={{
								color: '#FFC20C'
							}}>
							{expiresAt} days
						</b>
					</Typography>
				)}
				{partnerHasBanner ? (
					<ImageButton
						backgroundImageUrl={partnerData?.partner[0]?.image_path || ''}
						className={styles.cover}
						hideTitles
						disableRipple
						cursor="default"
					/>
				) : (
					<ActivityBoxEmpty
						description="No banner image has been provided for this partner"
						style={{ height: '200px', marginBottom: '50px' }}
					/>
				)}

				<Grid container wrap="nowrap" style={{ minHeight: 380 }} justifyContent="space-between">
					<Grid xs={5} className={styles.column} item>
						<Typography variant="h4">
							<b>{partnerData?.partner[0].heading_short_description}</b>
						</Typography>
						{partnerHasBriefDescription ? (
							<Typography variant="h6">
								{partnerData?.partner[0]?.short_description || ''}
							</Typography>
						) : (
							<ActivityBoxEmpty
								description="No brief description has been provided"
								className={styles.videoBox}
							/>
						)}
					</Grid>

					{partnerData?.partner?.[0]?.video_path ? (
						<ReactCustomVideoPlayer
							width={540}
							height={320}
							light={partnerData?.partner[0]?.video_thumbnail_image_path || ''}
							url={partnerData?.partner[0]?.video_path}
						/>
					) : (
						<ActivityBoxEmpty description="No video has been provided" className={styles.videoBox} />
					)}
				</Grid>

				<Typography variant="h4" className={styles.about}>
					<b>{partnerData?.partner[0].heading_description}</b>
				</Typography>
				{partnerHasDescription ? (
					<Typography variant="h6">{partnerData?.partner[0]?.description || ''}</Typography>
				) : (
					<ActivityBoxEmpty
						description="No description has been provided"
						style={{
							height: '200px'
						}}
					/>
				)}

				{partnerHasModules && (
					<>
						<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b>Modules</b>
							</Typography>
							<div style={{ flexGrow: 1 }} />
						</Box>
						<Grid container spacing={3}>
							<Grid item xs>
								{!loadingPartner ? (
									<ListModule partner_id={partnerId} data={modulesData} spacing={1.5} />
								) : (
									<Box m={3} textAlign="center">
										<CircularProgress color="secondary" size={40} />
									</Box>
								)}
							</Grid>
						</Grid>
					</>
				)}

				{partnerHasPlaylists && (
					<>
						<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b>Playlists</b>
							</Typography>
							<div style={{ flexGrow: 1 }} />
						</Box>
						<List>
							{playlistData.map((playlist) => (
								<Box marginBottom={'15px'} marginTop={'15px'} key={playlist.playlist_id}>
									<PlaylistCardContainer
										playlist={playlist as Playlist}
										key={playlist.playlist_id}
										elevation={1.5}
									/>
								</Box>
							))}
						</List>
					</>
				)}

				{!loadingPartner && (!!modulesData.length || !!playlistData?.length) && (
					<Box mt={4} textAlign="center">
						<Button
							onClick={() => {
								window.scrollTo(0, 0)
							}}
							variant="text"
							color="secondary">
							Back to Top
						</Button>
					</Box>
				)}
				<AddModuleToClass
					isOpen={isAddModuleToClassOpen}
					setIsOpen={setIsAddModuleToClassOpen}
					module={addToClassModule}
				/>
			</Box>
		</>
	)
}
