import { makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
	inputDefault: {
		'& .MuiInputBase-root': {
			backgroundColor: 'white'
		}
	}
}))

export const InputField = ({ className, ...props }: Omit<TextFieldProps, 'color' | 'variant'>) => {
	const styles = useStyles()

	return (
		<TextField
			className={clsx(styles.inputDefault, className)}
			color="secondary"
			variant="outlined"
			{...props}
		/>
	)
}
