import { Fragment, useState } from 'react'

import {
	Box,
	CircularProgress,
	createStyles,
	fade,
	makeStyles,
	Theme,
	Tooltip,
	Typography,
	useTheme
} from '@material-ui/core'
import { CalendarToday, Edit, EqualizerOutlined, Settings } from '@material-ui/icons'
import { useHistory } from 'react-router'
import { PagesProps } from 'src/@types'
import { AssignmentsIcon } from 'src/assets/icons'
import {
	BaseDialog,
	BigBaseButton,
	ActionButton,
	BaseButton,
	HorizontalGrid,
	ImageActionOptionType,
	MainTitle
} from 'src/components'
import { HeaderCard, StyledCardsRow } from 'src/components/Cards/ClassHeaderCard/ClassHeaderCard'
import {
	GetClassByPkDocument,
	useGetClassImagesQuery,
	useSetClassSettingsMutation
} from 'src/graphql/autogenerate/hooks'
import { Pages } from 'src/routes/teacherPages'
import { RouteNotFound } from 'src/scenes/Errors/404.container'
import { BOX_PADDING } from 'src/styles/constants'
import {
	buildBreadCrumbs,
	buildImagePath,
	buildRouteParameters,
	generateUniqueId,
	getCyKey,
	slugToName,
	formatDate,
	BASE_URL
} from 'src/utils'

import { useClassDetailsContext } from '../useClassDetails'

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			mainTitle: {
				color: theme.palette.background.paper,
				opacity: '0.7'
			},
			titleText: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				'-webkit-line-clamp': 3,
				'-webkit-box-orient': 'vertical',
				wordBreak: 'break-word'
			},
			headerCards: {
				color: theme.palette.text.secondary,
				padding: '20px 80px',
				height: 140,
				width: 212
			},
			itemContainer: {
				'&& :hover': {
					backgroundColor: theme.palette.secondary.main,
					color: theme.palette.text.primary
				},
				cursor: 'pointer'
			},
			imageCardButton: {
				marginTop: 0
			}
		}),
	{ index: 1 }
)

export const ClassDetailsTitle = ({ page }: PagesProps) => {
	const styles = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const { classId, classData, headerCards, teacherId, loadingClass } = useClassDetailsContext()
	const { data: dataClassImages } = useGetClassImagesQuery()
	const [selectImageOpen, setSelectedImageOpen] = useState(false)

	let classImages: ImageActionOptionType[] = []

	if (dataClassImages?.class_images) {
		classImages = dataClassImages?.class_images.map((row) => ({
			id: row.id as unknown as string,
			imagePath: `${BASE_URL}${row.imagePath}`
		}))
	}

	const [imageSelected, setImageSelected] = useState<string>(
		() => classImages?.find((i) => i.imagePath === classData?.image_path)?.id || '1'
	)
	const [updateClass, { loading: updateClassLoading }] = useSetClassSettingsMutation({
		refetchQueries: [
			{
				query: GetClassByPkDocument,
				variables: {
					classId,
					teacherId
				}
			}
		]
	})

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.title || '')
			}
		]
	})

	const goToClassAssignments = () => history.push(buildRouteParameters(Pages.ClassAssignments, { classId }))

	return !classData && !loadingClass ? (
		<RouteNotFound />
	) : (
		<>
			<Box
				p={BOX_PADDING}
				style={{
					backgroundImage: `${'linear-gradient(0deg, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 100%, 0) 100%)'},url(${buildImagePath(
						classData?.image_path ?? ''
					)})`,
					backgroundColor: fade(theme.palette.primary.dark, 0.8),
					backgroundSize: 'cover'
				}}>
				<MainTitle
					breadcrumbs={breadcrumbs}
					breadcrumbPageStyle={styles.mainTitle}
					title={
						<Tooltip title={classData?.title || ''}>
							<Typography variant="h3" style={{ color: theme.palette.background.paper }}>
								<b
									className={styles.titleText}
									data-cy={getCyKey(ClassDetailsTitle, 'classMainTitle')}>
									{classData?.title ?? ''}
								</b>
							</Typography>
						</Tooltip>
					}
					actionButtons={[
						<BaseButton
							key={0}
							style={{
								backgroundColor: 'white',
								color: '#F3AB34',
								height: 56,
								padding: '0px 30px'
							}}
							startIcon={<EqualizerOutlined />}
							onClick={() => {
								history.push(Pages.Classes.path + `/${classData?.class_id ?? ''}/analytics`)
							}}>
							<b>Analytics</b>
						</BaseButton>,
						<BaseButton
							key={1}
							style={{
								height: 56,
								padding: '0px 30px'
							}}
							color="secondary"
							startIcon={<AssignmentsIcon />}
							onClick={goToClassAssignments}>
							<b>Assignments</b>
						</BaseButton>,
						<ActionButton
							key={2}
							data-cy={getCyKey(ClassDetailsTitle, 'btnSettingsClass')}
							onClick={() => {
								history.push(
									buildRouteParameters(
										Pages.ClassSettings, // Route with paramters
										{ classId }
									)
								)
							}}
							size="large"
							icon={<Settings />}
						/>
					]}
				/>
				<Box>
					<BaseButton
						variant="outlined"
						color="secondary"
						style={{
							position: 'relative',
							bottom: '-130px',
							left: '-95px',
							zIndex: 1
						}}
						onClick={() => {
							setSelectedImageOpen(true)
						}}>
						<Edit />
					</BaseButton>
				</Box>
				<BaseDialog
					paperProps={{ style: { width: '100%', maxWidth: '800px' } }}
					maxWidth="md"
					onClose={() => {
						if (!updateClassLoading) setSelectedImageOpen(false)
					}}
					open={selectImageOpen}
					dividers={false}
					header={
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Select Your Class Image
						</Typography>
					}
					body={
						<Box maxWidth="99%" marginTop="30px">
							<Typography>
								<b>Select an image*</b>
							</Typography>
							<HorizontalGrid
								key={generateUniqueId()}
								spacing={1}
								cardHeight={126}
								optionSelected={imageSelected}
								options={classImages}
								handleSelectOption={(imagePath) => setImageSelected(imagePath)}
								gridItemProps={{ xs: 3 }}
								imageButtonProps={{ className: styles.imageCardButton }}
							/>
						</Box>
					}
					leftActions={
						<Fragment>
							<BigBaseButton
								style={{
									padding: '6px 40px',
									width: '-webkit-fill-available'
								}}
								variant="contained"
								color="default"
								disabled={updateClassLoading}
								onClick={() => setSelectedImageOpen(false)}>
								<Typography variant="caption">Close</Typography>
							</BigBaseButton>
							<BigBaseButton
								style={{
									padding: '6px 50px',
									width: '-webkit-fill-available'
								}}
								color="secondary"
								autoFocus
								disabled={!imageSelected || updateClassLoading}
								onClick={async () => {
									if (updateClassLoading) return
									await updateClass({
										variables: {
											classId: Number(classId),
											teacherId,
											classData: {
												image_path: classImages
													.flatMap((r) => r)
													.find((r) => r.id === imageSelected)?.imagePath
											}
										},
										awaitRefetchQueries: true
									})
									setSelectedImageOpen(false)
								}}>
								<Typography variant="caption">
									{updateClassLoading ? <CircularProgress size={20} /> : 'Save'}
								</Typography>
							</BigBaseButton>
						</Fragment>
					}
				/>
				<Box display="flex" alignItems="center">
					<CalendarToday style={{ marginRight: '5px' }} className={styles.mainTitle} />
					<Typography className={styles.mainTitle}>
						{formatDate(classData?.start_date)} - {formatDate(classData?.end_date)}
					</Typography>
				</Box>
			</Box>
			<StyledCardsRow padding={BOX_PADDING}>
				{headerCards.map((card) => (
					<HeaderCard key={card.title} {...card} isTeacher />
				))}
			</StyledCardsRow>
		</>
	)
}
