import { createStyles, makeStyles } from '@material-ui/core'

export const useClassAssignmentDetailsContentStyles = makeStyles(() =>
	createStyles({
		divider: {
			margin: '40px 0 0 0'
		},
		loaderHolder: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around'
		},
		container: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'right'
		},
		dateSelect: {
			width: '50%',
			height: 50
		}
	})
)

export default useClassAssignmentDetailsContentStyles
