import { CSSProperties } from '@material-ui/styles'

import { COLORS } from './colors'

/**
 * STOP USING THIS, IT WILL BE REMOVED
 */
export const masterStyles: { [key: string]: CSSProperties } = {
	container: {
		width: '100%',
		height: '100%',
		backgroundColor: COLORS.white
	},
	formContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: 20
	},
	formContainerNoPadding: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap'
	}
}
