import { makeStyles } from '@material-ui/core'

export const useSongsTitleStyles = makeStyles(() => ({
	container: {
		marginTop: '40px'
	},
	title: {
		display: 'flex',
		alignItems: 'center'
	},
	separator: {
		flexGrow: 1
	},
	results: {
		textAlign: 'right'
	},
	loadingResult: {
		display: 'inline',
		color: 'transparent'
	}
}))

export default useSongsTitleStyles
