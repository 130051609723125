import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { ClassVideosPlaylists } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/components/ClassVideosPlaylists'
import { ClassVideosSaved } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/components/ClassVideosSaved'
import { ClassVideosTitle } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/components/ClassVideosTitle'
import { ClassVideosProvider } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/useClassVideos'

export const ClassVideos = (props: PagesProps) => {
	return (
		<Box>
			<ClassVideosProvider>
				<ClassVideosTitle {...props} />
				<ClassVideosSaved />
				<ClassVideosPlaylists />
			</ClassVideosProvider>
		</Box>
	)
}
