import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

type AllClassProductsCardStylesProps = {
	imageUrl: string
	hideFunctionalities?: boolean
}
export const useAllClassProductsCardStyles = makeStyles<Theme, AllClassProductsCardStylesProps>((theme) =>
	createStyles({
		image: {
			width: '100%',
			height: '100%',
			borderRadius: 4
		},
		AddToClassButton: {
			minWidth: 'fit-content',
			height: 40,
			pointerEvents: 'stroke',
			display: 'flex',
			alignItems: 'center',
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary.dark
			}
		},
		imageContainerLessons: {
			height: 90,
			width: 120,
			borderRadius: 4,
			marginRight: 20,
			position: 'relative',
			cursor: 'pointer'
		},
		container: {
			display: 'flex',
			alignItems: 'center',
			height: '217px',
			cursor: 'pointer',
			boxShadow:
				'0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.15)',
			borderRadius: '4px'
		},
		paper: {
			width: '100%',
			marginBottom: '20px'
		},

		imageContainer: {
			position: 'relative',
			height: '100%',
			width: '25%',
			backgroundImage: ({ imageUrl }: AllClassProductsCardStylesProps) => `url(${imageUrl})`,
			backgroundPosition: 'center',
			backgroundColor: 'gray',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			borderRadius: '4px 0px 4px 0 px'
		},

		blank: {
			position: 'relative',
			height: '100%',
			width: '25%',
			backgroundPosition: 'center',
			backgroundColor: 'gray',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			borderRadius: '4px 0px 4px 0 px'
		},

		genres: {
			color: '#1D273D',
			opacity: 0.5
		},
		ellipsis: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			width: '100%'
		},
		styledEllipsis: {
			display: 'block',
			textOverflow: 'ellipsis',
			wordWrap: 'break-word',
			overflow: 'hidden',
			maxHeight: '3.6em',
			lineHeight: '1.8em'
		},
		ellipsisSecondLine: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			height: '46px',
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical'
		},
		details: {
			width: '75%',
			height: '100%',
			padding: 20,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between'
		},
		buttons: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			bottom: 20,
			right: 0,
			gap: '10px'
		},
		description: {
			marginBottom: 10,
			fontSize: '1.3rem',
			marginRight: '10px'
		},
		title: {
			color: '#FFFFFF',
			maerginTop: 50,
			padding: '40px 20px 0 20px'
		},
		flag: {
			padding: '10px 0',
			backgroundColor: 'rgba(0,0,0,0.3)',
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		noFlag: {
			padding: '10px 0',
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		flagText: {
			color: 'white',
			marginLeft: 15
		},
		viewButton: {
			background: '#E9E9EA',
			color: '#1D273D'
		},

		visibility: {
			textAlign: 'left',
			alignSelf: 'center',
			fontSize: '14px',
			color: '#8B909B',
			width: '100px'
		},
		containerLessons: {
			display: 'flex',
			marginBottom: '20px',
			justifyContent: 'space-between',
			padding: 20,
			cursor: 'pointer',
			position: 'relative'
		},
		containerChangedPrivacyItems: {
			backgroundColor: 'rgba(255, 0, 0, 0.3)'
		},
		titleLessons: {
			fontWeight: 'bolder'
		},
		genresLessons: {
			color: '#1D273D',
			opacity: 0.5
		},
		ellipsisLessons: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap'
		},
		visibilityLessons: {
			paddingLeft: 10,
			color: '#8B909B',
			textTransform: 'capitalize'
		},
		draggableIcon: {
			padding: '0 15px 0 5px',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around'
		},
		detailsLessons: {
			width: ({ hideFunctionalities }) => (hideFunctionalities ? '100%' : '50%')
		},
		detailsText: {
			width: ({ hideFunctionalities }) => (hideFunctionalities ? '70%' : '60%')
		},
		checkbox: {
			position: 'absolute',
			top: 10,
			right: 10
		},
		editButton: {
			height: '40px',
			width: '40px',
			padding: 0,
			marginRight: '10px'
		},
		deleteButton: {
			height: '40px',
			width: '40px',
			padding: 0,
			marginRight: '10px'
		},
		icon: {
			marginRight: 10
		},
		iconButton: {
			padding: 0,
			backgroundColor: 'transparent',
			boxShadow: 'none',
			boderRadius: '50%',
			'&:hover': {
				backgroundColor: 'transparent',
				boxShadow: 'none'
			}
		},
		containerButtons: {
			display: 'flex',
			justifyContent: 'space-between'
		}
	})
)

export default useAllClassProductsCardStyles
