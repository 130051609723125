import { createStyles, makeStyles } from '@material-ui/core'

export const useSkillCoveredBySavedLessonsStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiDrawer-paper ': {
				width: '30%'
			}
		},
		Accordion: {
			border: 'none',
			boxShadow: 'none',
			marginTop: 5,
			marginBottom: 0,
			'&:not(:last-child)': {
				borderBottom: 0
			},
			'&:before': {
				display: 'none'
			},
			'&$expanded': {
				margin: 0
			},
			'&.MuiAccordion-root.Mui-expanded': {
				marginTop: 5,
				marginBottom: 0
			},
			'&.MuiAccordionSummary-content.Mui-expanded': {
				marign: 0
			}
		},
		AccordionSumary: {
			backgroundColor: '#F0F2F5',
			border: 'none',
			marginBottom: -1,
			minHeight: 40,
			'&.MuiAccordionSummary-root.Mui-expanded': {
				minHeight: 40
			},
			'&.MuiAccordionSummary-content.Mui-expanded': {
				marign: 0
			},
			'&.Mui-expanded': {
				marign: 0
			}
		},
		accordionDetails: {
			display: 'flex',
			flexDirection: 'column'
		},
		nextedAccordion: {
			width: '100%'
		},
		accordionTitle: { textTransform: 'capitalize', fontWeight: 'bold' }
	})
)

export default useSkillCoveredBySavedLessonsStyles
