import { useEffect, useState } from 'react'

import { Class } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'

import { buildGenericContext } from '../../../../context/genericContext'
import {
	useGetClassesByStudentByPkLazyQuery,
	useGetClassesByStudentLazyQuery,
	useGetStudentByPkExtendedLazyQuery
} from '../../../../graphql/autogenerate/hooks'

const useClassDetails = () => {
	const { studentData: student } = useLoginContext()
	const studentId = student.student_id
	const [classSelectedId, setClassSelectedId] = useState<number>()

	const [getStudentByPkExtended, { data: studentData, loading: studentLoading }] =
		useGetStudentByPkExtendedLazyQuery({
			fetchPolicy: 'no-cache'
		})

	const [getClassesByStudentByPk, { data, loading: loadingStudentClasses }] =
		useGetClassesByStudentByPkLazyQuery()

	const [getClassesByStudent, { loading: loadingGrid, data: classData }] = useGetClassesByStudentLazyQuery()
	const classGrid = classData?.class?.map((studentClass) => studentClass as Class) || []

	useEffect(() => {
		if (!studentId) return
		getStudentByPkExtended({
			variables: {
				studentId
			}
		})
		getClassesByStudentByPk({
			variables: {
				studentId
			}
		})
		getClassesByStudent({
			variables: {
				studentId
			}
		})
	}, [studentId])

	return {
		firstName: data?.student_by_pk?.first_name,
		stageName: data?.student_by_pk?.stage_name,
		loadingStudentClasses,
		classData: data?.student_by_pk?.class_students,
		cover: data?.student_by_pk?.cover_image_path,
		classGrid,
		loadingGrid,
		classSelectedId,
		setClassSelectedId,
		studentLoading,
		studentData
	}
}

export const [StudentClassDetailsProvider, useStudentClassDetailsContext] = buildGenericContext(useClassDetails)
