import { ChangeEvent, useEffect } from 'react'

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { ListAssignments } from 'src/components/listAssignments/ListAsignments'
import { SeeAllBtnTooltip } from 'src/components/SeeAllTooltip'
import { DateFilterEnum } from 'src/environment'
import { Submission } from 'src/graphql/autogenerate/schemas'
import { useAssignmentsContext } from 'src/scenes/Student/hooks/useAssignments'
import { AssignmentAccordion } from 'src/scenes/Student/scenes/2-Classes/components/AssignmentAccordion/AssignmentAccordion'
import { AssignmentSortOrder } from 'src/utils'

import useStyles from './AssignmentsDetailsContent.styles'
export const AssignmentsDetailsContent = () => {
	const styles = useStyles()
	const {
		selectedClassId,
		setSelectedClassId,
		setSelectedDateFilter,
		selectedDateFilter,
		notSubmittedAssignments,
		submittedAssignments,
		loadingNotSubmittedAssignments,
		loadingSubmittedAssignments,
		clearLimits,
		submittedSort,
		setSubmittedSort,
		notSubmittedSort,
		setNotSubmittedSort,
		classes,
		seeMoreSubmitted,
		seeMoreNotSubmitted,
		submittedAssignmentsCount,
		notSubmittedAssignmentsCount,
		submittedLimit,
		notSubmittedLimit
	} = useAssignmentsContext()

	useEffect(() => {
		if (classes?.class?.length && setSelectedClassId) {
			setSelectedClassId(classes?.class[0].class_id)
		}
	}, [classes])

	// Clear queries limits on unmount
	useEffect(() => {
		return () => {
			clearLimits()
		}
	}, [])

	const handleSortSubmitted = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as AssignmentSortOrder
		setSubmittedSort(selectedSort)
	}
	const handleSortNotSubmitted = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as AssignmentSortOrder
		setNotSubmittedSort(selectedSort)
	}

	const handleDateSelected = (
		e: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		setSelectedDateFilter(e.target.value as DateFilterEnum)
	}

	const handleClassSelected = (
		e: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		setSelectedClassId(e.target.value as number)
	}

	const optionsDateFilter = [
		{ name: 'Next 7 Days', value: DateFilterEnum.nextSevenDays },
		{ name: 'This Month', value: DateFilterEnum.thisMonth },
		{ name: 'Next 3 Months', value: DateFilterEnum.nextThreeMonths },
		{ name: 'Last 6 Months', value: DateFilterEnum.lastSixMonths },
		{ name: 'This Year', value: DateFilterEnum.thisYear },
		{ name: 'All Time', value: DateFilterEnum.allTime }
	]

	return (
		<Box>
			<Box className={styles.container}>
				{classes?.class && classes?.class?.length > 1 && (
					<Select
						className={styles.classSelect}
						options={classes?.class?.map((i) => ({ name: i.title, value: i.class_id }))}
						onChange={handleClassSelected}
						value={selectedClassId}
					/>
				)}
				<Select
					className={styles.dateSelect}
					options={optionsDateFilter}
					onChange={handleDateSelected}
					value={selectedDateFilter}
				/>
			</Box>
			<AssignmentAccordion
				countTitle="To Be Submitted"
				count={notSubmittedAssignmentsCount ?? 0}
				details={
					loadingNotSubmittedAssignments ? (
						<Box className={styles.loaderHolder}>
							<CircularProgress color="secondary" size={50} />
						</Box>
					) : !!notSubmittedAssignmentsCount ? (
						<Box my={4} mb={7}>
							<Box my={4} mb={7}>
								<ListAssignments
									isInStudentView
									spacing={1.5}
									data={notSubmittedAssignments as Submission[]}
								/>
							</Box>
						</Box>
					) : (
						<Box my={4} mb={7}>
							<Typography variant="h6" color="textSecondary">
								This class doesn't have any assignment.
							</Typography>
						</Box>
					)
				}
				sort={notSubmittedSort}
				handleSort={handleSortNotSubmitted}
				actions={
					<SeeAllBtnTooltip
						canFetchMore={notSubmittedLimit < (notSubmittedAssignmentsCount || 0)}
						name="submissions"
						onClick={seeMoreNotSubmitted}
					/>
				}
			/>

			<Divider className={styles.divider} />
			<AssignmentAccordion
				countTitle="Submitted"
				count={submittedAssignmentsCount ?? 0}
				details={
					loadingSubmittedAssignments ? (
						<Box className={styles.loaderHolder}>
							<CircularProgress color="secondary" size={50} />
						</Box>
					) : !!submittedAssignmentsCount ? (
						<Box my={4} mb={7}>
							<Box my={4} mb={7}>
								<ListAssignments
									isInStudentView
									spacing={1.5}
									data={submittedAssignments as Submission[]}
								/>
							</Box>
						</Box>
					) : (
						<Box my={4} mb={7}>
							<Typography variant="h6" color="textSecondary">
								You haven’t submitted any Wurrly yet.
							</Typography>
							<Typography color="textSecondary">
								It's easy to submit a Wurrly, go to the assignment and record a Wurrly.
							</Typography>
						</Box>
					)
				}
				sort={submittedSort}
				handleSort={handleSortSubmitted}
				actions={
					<SeeAllBtnTooltip
						canFetchMore={submittedLimit < (submittedAssignmentsCount || 0)}
						name="submissions"
						onClick={seeMoreSubmitted}
					/>
				}
			/>
		</Box>
	)
}
