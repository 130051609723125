import { PagesProps } from 'src/@types'
import { MainTitle } from 'src/components'
import { useGetClassByPkQuery } from 'src/graphql/autogenerate/hooks'
import { useClassVideosContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/useClassVideos'
import { buildBreadCrumbs, slugToName } from 'src/utils'

export const ClassVideosTitle = ({ page }: PagesProps): JSX.Element => {
	const { classId } = useClassVideosContext()

	const { data: classData } = useGetClassByPkQuery({ variables: { classId } })
	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.class_by_pk?.title || '')
			},
			{
				index: 2,
				overrideName: 'Videos'
			}
		],
		params: {
			classId
		},
		isStudentPage: true
	})

	return (
		<MainTitle
			breadcrumbs={breadCrumbs}
			title="Class Videos"
			description="These are the Videos that have been added to this Class."
		/>
	)
}
