import React from 'react'

import { Avatar, Box, Checkbox, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					height: 36,
					'&:not(.addClass)': {
						width: 36,
						padding: theme.spacing(0.75)
					},
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		}
	})
)

export type ListItemSequenceProps = {
	imageUrl: string
	title: string
	genres: string
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	selected: boolean
}

export const ListItemAddSequence = ({ imageUrl, title, genres, selected }: ListItemSequenceProps) => {
	const classes = useStyles()

	return (
		<ListItem className={classes.listItem}>
			<ListItemAvatar>
				<Avatar variant="rounded" src={imageUrl} />
			</ListItemAvatar>
			<ListItemText
				style={{ marginRight: '20px' }}
				disableTypography
				primary={
					<div>
						<Typography noWrap variant="h6" style={{ fontSize: 18 }}>
							<b>{title}</b>
						</Typography>
					</div>
				}
				secondary={genres}
			/>
			<Box position="absolute" top={8} right={8}>
				<Checkbox checked={selected} />
			</Box>
		</ListItem>
	)
}
