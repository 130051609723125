import { makeStyles } from '@material-ui/core'

export const useVersionStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#fff',
		fontWeight: 600,
		height: 55,
		position: 'fixed',
		left: 0,
		right: 0,
		top: 0,
		textAlign: 'center',
		zIndex: 9999,
		opacity: 1,
		transition: 'opacity 3s'
	},
	p: {
		display: 'inline-block',
		marginRight: 15
	},
	fade: {
		opacity: 0
	}
})

export default useVersionStyles
