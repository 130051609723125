import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useClassStudentProductivityStyles = makeStyles((theme: Theme) =>
	createStyles({
		select: {
			margin: '10px 0',
			backgroundColor: theme.palette.common.white,
			'& .MuiOutlinedInput-input': {
				padding: '10px 14px'
			}
		}
	})
)

export default useClassStudentProductivityStyles
