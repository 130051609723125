export enum SortOrder {
	Type = 'type',
	Recent = 'recent',
	Up = 'asc',
	Down = 'desc',
	Grade = 'grade',
	DateAsc = 'dateasc',
	DateDesc = 'datedesc'
}
export enum AssignmentSortOrder {
	AlphAsc,
	AlphDesc,
	DateAsc,
	DateDesc
}
export enum TypeEnum {
	Song = 'song',
	Video = 'tip',
	Lesson = 'lesson_plan',
	Student = 'student'
}

export enum ProductIdsEnum {
	LessonPlan = 'lesson_plan_id',
	Module = 'module_id',
	Playlist = 'playlist_id',
	Song = 'song_id',
	Tip = 'tip_id'
}
export enum ProductTitleEnum {
	LessonPlan = 'Lessons',
	Module = 'Modules',

	Playlist = 'Playlists',

	Song = 'Songs',
	Tip = 'Videos'
}
export enum FileTypeEnum {
	Asset = 'assets',
	Song = 'songs',
	Video = 'tips',
	Images = 'images',
	Wurrly = 'wurrlies'
}

export enum StatusEnum {
	Created = 'CREATED',
	NotStarted = 'NOT STARTED',
	InProgress = 'IN PROGRESS',
	Graded = 'GRADED'
}

export enum TrackTypeEnum {
	// track_type_id: 1,
	Piano = 'Piano',
	// track_type_id: 2,
	Band = 'Band',
	// track_type_id: 3,
	Guitar = 'Guitar',
	// track_type_id: 4,
	Vocal = 'Vocal'
}

export enum RefetchProductsEnum {
	Channel = 'channel',
	LessonPlan = 'lesson_plan',
	Module = 'module',
	Partner = 'partner',
	Playlist = 'playlist',
	ClassAssignment = 'class_assignment',
	Song = 'song',
	Tip = 'tip'
}

export enum StudentActivityTypeEnum {
	HasWatched = 'has_watched',
	HasRecorded = 'has_recorded',
	HasBeenPracticing = 'has_been_practicing',
	HasJoined = 'has_joined',
	HasCompletedAssignment = 'has_completed_assignment',
	HasHandedAssignment = 'handed_assignment'
}
export enum StripeBrandEnum {
	'visa' = 'visa',
	'mastercard' = 'mastercard',
	'amex' = 'amex',
	'discover' = 'discover',
	'diners' = 'diners',
	'jcb' = 'jcb',
	'unionpay' = 'unionpay',
	'unknown' = 'unknown'
}

export enum ClassChartType {
	standards,
	skills
}

export enum IntegrationOptsType {
	schoology = 'Use schoology',
	clever = 'Use clever',
	google = 'Use Google Classroom',
	teams = 'Use Microsoft Teams',

	classLink = 'Use ClassLink'
}

export enum MailTemplates {
	inviteStudent = 'd-1d88c20243fc4c87ad8eb75e296d0c07',
	parentConsent = 'd-1ead10813899446eaff4bcaaec87da93'
}

export enum StorageEnum {
	userData = 'userData',
	loginToken = 'loginToken',
	isLoggedAsTeacher = 'isLoggedAsTeacher',
	appVersion = 'appVersion',
	audioStartTime = 'audioStartTime'
}

export enum BadgeId {
	PracticeProId = 1,
	RecordingArtistId = 2,
	ChallengeChampId = 3,
	GoalMedalistId = 4,
	StreakVictor = 5,
	UltimateExplorer = 6
}

export enum ClassStateOptions {
	Active,
	Inactive,
	All
}

export enum ClassWurrlyType {
	ClassWurrlies = 'class_wurrlies',
	MyWurrlies = 'my_wurrlies'
}

export enum BrowserPermissions {
	granted = 'granted',
	denied = 'denied'
}

export enum CatalogIds {
	GENRES = 1,
	SUBJECTS = 2,
	THEMES = 3,
	AGES = 4,
	INSTRUMENTS = 5
}
