import { useState } from 'react'

import { useCanvasInstance } from 'src/hooks/integrations/useCanvasInstance'
import { useClassLinkInstance } from 'src/hooks/integrations/useClassLinkInstance'

import { buildGenericContext } from '../context/genericContext'
import {
	GoogleGroup,
	GoogleOAuthToken,
	CleverGroup,
	MsGroup,
	ClassLinkGroup,
	CanvasGroup
} from '../graphql/autogenerate/schemas'
import { IntegrationOptsType } from '../utils/enums'
import { useCleverInstance } from './integrations/useCleverInstance'
import { useGoogleInstance } from './integrations/useGoogleInstance'
import { useMSTeamsInstance } from './integrations/useMSTeamsInstance'
import { useLoginContext } from './useLogin'

export type IntegrationStudent = {
	name: {
		first: string
		last: string
	}
	email: string
	integrationId: string
}

export interface IntegrationAccount {
	teacherEmail?: string
	integrationId?: string
	token?: GoogleOAuthToken | string | null
	courses?: GoogleGroup[]
}

const useIntegration = () => {
	const { teacherData: teacher } = useLoginContext()
	const [integrationType, setIntegrationType] = useState<IntegrationOptsType | null>(null)
	const [teacherIntegrationId, setTeacherIntegrationId] = useState<number>()
	const [loadingClasses, setLoadingClasses] = useState(false)
	const [loadingStudents, setLoadingStudents] = useState(false)
	const [classes, setClasses] = useState<
		GoogleGroup[] | CleverGroup[] | MsGroup[] | ClassLinkGroup[] | CanvasGroup[]
	>([])
	const [students, setStudents] = useState<IntegrationStudent[]>([])

	const canvasInstance = useCanvasInstance({ teacherId: teacher.teacher_id, setClasses })

	const googleInstance = useGoogleInstance({
		setClasses,
		setStudents,
		setLoadingClasses,
		setLoadingStudents,
		setTeacherIntegrationId,
		teacherId: teacher.teacher_id
	})
	const msTeamsInstance = useMSTeamsInstance({
		setClasses,
		setStudents,
		setLoadingClasses,
		setLoadingStudents,
		setTeacherIntegrationId,
		teacherId: teacher.teacher_id
	})
	const cleverInstance = useCleverInstance({
		setClasses,
		setStudents,
		setLoadingClasses,
		setLoadingStudents,
		setTeacherIntegrationId,
		teacherId: teacher.teacher_id
	})
	const classLinkInstance = useClassLinkInstance({
		setClasses,
		setStudents
	})

	return {
		classes,
		students,
		loadingClasses,
		loadingStudents,
		googleInstance,
		msTeamsInstance,
		cleverInstance,
		setIntegrationType,
		integrationType,
		teacherIntegrationId,
		classLinkInstance,
		canvasInstance
	}
}

export const [IntegrationProvider, useIntegrationContext] = buildGenericContext(useIntegration)
