import { Box, TextFieldProps } from '@material-ui/core'
import clsx from 'clsx'

import { InputField } from '../InputField/InputField'
import { InputHeader, InputHeaderProps } from '../InputHeader/InputHeader'

type TextInputProps = {
	label?: string
	classes?: {
		root?: string
		label?: string
		input?: string
	}
}

type Props = TextInputProps & InputHeaderProps & Omit<TextFieldProps, 'classes'>

export const TextInput = ({
	id,
	name,
	label,
	classes,
	className,
	required,
	customMargin,
	endText,
	endTextProps,
	fullWidth = true,
	...props
}: Props) => (
	<Box className={classes?.root}>
		<InputHeader
			className={classes?.label}
			name={label ?? name}
			isRequired={required}
			customMargin={customMargin}
			endText={endText}
			endTextProps={endTextProps}
		/>
		<InputField
			className={clsx(className, classes?.input)}
			id={id}
			name={name}
			color="secondary"
			variant="outlined"
			fullWidth={fullWidth}
			{...props}
		/>
	</Box>
)
