import { createStyles, makeStyles } from '@material-ui/core'

export const useBillingInfoStyles = makeStyles(() =>
	createStyles({
		container: {
			width: '70%',
			margin: '20px 0px'
		},
		gridContainer: {
			gap: '20px'
		},
		divider: {
			width: '100%',
			margin: '0px'
		},
		text: {
			fontWeight: 500,
			fontSize: '17px',
			lineHeight: '20px'
		},
		price: {
			display: 'flex',
			justifyContent: 'flex-end'
		}
	})
)

export default useBillingInfoStyles
