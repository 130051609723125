import { createStyles, makeStyles } from '@material-ui/core'

export const useStickerFilterStyles = makeStyles(
	() =>
		createStyles({
			sticker: {
				width: '50px',
				height: 'auto'
			}
		}),
	{ index: 3 }
)

export default useStickerFilterStyles
