import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { ActivityBoxEmpty, MainTitle, SelectSort } from 'src/components'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { UnsuscribeDialog } from 'src/components/Dialogs/UnsuscribeDialog/UnsuscribeDialog'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { SelectFilter } from 'src/components/SelectFilter'
import { SubscriptionsCard } from 'src/components/SubscriptionsCard/SubscriptionsCard'
import { Channel, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { ChannelCardContainer } from 'src/scenes/Teacher/scenes/channels/components/ChannelCardContainer'
import { useChannels } from 'src/scenes/Teacher/scenes/channels/useChannels'
import { generateFlagText } from 'src/utils'

import useChannelPageStyles from './ChannelPage.styles'

export const AllChannels = () => {
	const styles = useChannelPageStyles()
	const {
		channels,
		totalChannels,
		sort,
		filter,
		onFilterChange,
		onSortChange,
		searchbarChannelsLoading,
		searchbarData,
		handleSearchbarTextChange,
		handleSearchbarTextEnter,
		handleOpenProductDialog,
		handleCloseProductDialog,
		isOpen,
		channelId,
		breadcrumbs,
		setChannelId,
		showSubscriptions,
		searchTeacherSubscriptionsData,
		searchSuscriptionDataLoading,
		goToChannel,
		onUnsubscribe,
		channelSuscriptions,
		searchbarText,
		isFetching,
		moreResults,
		channelDataLoading,
		suscriptionDataLoading,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	} = useChannels()

	const searchBarItems = () => {
		return !showSubscriptions
			? searchbarData.map((channel) => (
					<ChannelCardContainer channel={channel as Channel} key={channel.channel_id} elevation={0} />
			  ))
			: searchTeacherSubscriptionsData.map((channel) => (
					<SubscriptionsCard
						isSearch
						key={channel.orderId}
						data={channel}
						allSubscriptionsCard
						onView={() => goToChannel(channel.productId)}
						onUnsubscribe={() => setItem(channel)}
						dataflagText={generateFlagText(channel)}
					/>
			  ))
	}

	return (
		<>
			{isOpen && channelId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={channelId}
					productName={Product_Name_Enum.Channel}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<UnsuscribeDialog
				isOpen={unSuscribeOpenDialog}
				setItem={setItem}
				onConfirm={(stripeSubscriptionId) => onUnsubscribe(stripeSubscriptionId)}
				setIsOpen={setUnSuscribeOpenDialog}
				item={item}
				onDone={(stripeSubscriptionId) => handleRefetch(stripeSubscriptionId)}
			/>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title="All Channels"
				description={'Here are all the Channels you are currently subscribed to'}
			/>
			<SearchBar
				placeholder="Find Channels by Title or Description"
				onChange={handleSearchbarTextChange}
				onEnterPressed={handleSearchbarTextEnter}
				loading={searchbarChannelsLoading || searchSuscriptionDataLoading}
				dropdownItems={searchBarItems()}
			/>
			<Box className={styles.listToolbar}>
				<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
					<Typography variant="h6">
						<b>Channels </b> ({totalChannels})
					</Typography>
					<div style={{ flexGrow: 1 }} />
					{totalChannels !== 0 && showSubscriptions && (
						<SelectFilter value={filter} onChange={onFilterChange} />
					)}
					{totalChannels !== 0 && <SelectSort cyKey="Songs" value={sort} onChange={onSortChange} />}
				</Box>
			</Box>
			{channelSuscriptions &&
				showSubscriptions &&
				channelSuscriptions.length > 0 &&
				channelSuscriptions.map((channel) => (
					<Box marginBottom={'15px'} marginTop={'15px'} key={channel.productId}>
						<SubscriptionsCard
							key={channel.orderId}
							data={channel}
							allSubscriptionsCard
							onView={() => goToChannel(channel.productId)}
							onUnsubscribe={() => setItem(channel)}
							dataflagText={generateFlagText(channel)}
						/>
					</Box>
				))}
			{channels &&
				!showSubscriptions &&
				channels.length > 0 &&
				channels.map((channel) => (
					<Box marginBottom={'15px'} marginTop={'15px'} key={channel.channel_id}>
						<ChannelCardContainer
							channel={channel as Channel}
							key={channel.channel_id}
							elevation={0}
							btnSuscription={{
								onClick: (e) => {
									e.stopPropagation()
									setChannelId(channel.channel_id)
									handleOpenProductDialog()
								}
							}}
						/>
					</Box>
				))}
			{((isFetching && moreResults) || channelDataLoading || suscriptionDataLoading) && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{((!channelDataLoading && channels.length > 0) ||
				(!suscriptionDataLoading && channelSuscriptions.length > 0)) &&
				!moreResults && (
					<Box mt={4} textAlign="center">
						<Button
							onClick={() => {
								window.scrollTo(0, 0)
							}}
							variant="text"
							color="secondary">
							Back to Top
						</Button>
					</Box>
				)}
			{totalChannels === 0 && (
				<>
					{searchbarText && (
						<Typography variant="h6" color="textSecondary">
							Your search for "{searchbarText}" did not return any results.
						</Typography>
					)}
					{!searchbarText && (
						<ActivityBoxEmpty description="You have not subscribed to any channels yet" />
					)}
				</>
			)}
		</>
	)
}
