import { ChangeEvent } from 'react'

import { MenuItem, Select, CircularProgress } from '@material-ui/core'
import { useGetDefaultGradingSystemsQuery } from 'src/graphql/autogenerate/hooks'
import { getCyKey } from 'src/utils'

import useStyles from './SelectGradingSystem.styles'

type SelectGradingSystemProps = {
	selected: number | undefined
	setSelected: (newValue: number) => void
}
const SelectGradingSystem = ({ selected, setSelected }: SelectGradingSystemProps) => {
	const styles = useStyles()
	const { data: gradingSystem, loading: gradingSystemLoading } = useGetDefaultGradingSystemsQuery()
	const options = gradingSystem?.grading_system

	const handleChange = (
		event: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => setSelected(event.target.value as number)

	return (
		<Select
			data-cy={getCyKey(SelectGradingSystem, 'gradingSystem')}
			value={selected}
			onChange={handleChange}
			className={styles.select}
			variant="outlined"
			color="secondary">
			{!gradingSystemLoading &&
				options &&
				options.map((option) => (
					<MenuItem
						value={option.grading_system_id}
						className={styles.menuItem}
						key={option.grading_system_id}>
						{option.label}
					</MenuItem>
				))}
			{gradingSystemLoading && (
				<MenuItem disabled>
					Loading <CircularProgress />
				</MenuItem>
			)}
		</Select>
	)
}

export default SelectGradingSystem
