import { CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { BaseButton } from 'src/components'
import useStyles from 'src/components/Version/Version.styles'

type VersionProps = {
	handleActivate: () => void
	success: boolean
	loading: boolean
}

export const ActivateAccount = ({ handleActivate, loading, success }: VersionProps) => {
	const styles = useStyles()

	return (
		<div className={clsx(styles.root, { [styles.fade]: success })}>
			{loading && <CircularProgress color="secondary" size={30} />}
			{!loading && !success && (
				<>
					<p className={styles.p}>Your Account isn&apos;t verified, please verify your account</p>
					<BaseButton color="secondary" onClick={handleActivate}>
						Verify
					</BaseButton>
				</>
			)}
			{success && (
				<>
					<p className={styles.p}>Email Sent!.</p>
				</>
			)}
		</div>
	)
}
