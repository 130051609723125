import React, { Fragment, useEffect, useState } from 'react'

import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons/CustomIcons'
import { MainTitle, Search, SelectSort } from 'src/components'
import { useGetCatalogByPkQuery } from 'src/graphql/autogenerate/hooks'
import { Catalog, Catalog_Item } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, capitalize, shouldSortTitleOrDate, SortOrder, TypeEnum } from 'src/utils'

import { ListSequence } from '../../components'
import { useGetSequenceTopics } from './hooks'

export const Sequences = ({ page, teacherId }: PagesProps) => {
	const params = useWurrlyParams<typeof Pages.SequenceDetail.params[number]>()
	const { catalogId, catalogItemId, sequenceTopicId } = params
	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})
	const catalog = catalogData?.catalog_by_pk as Catalog | undefined
	const catalogItem = catalogData?.catalog_by_pk?.catalog_items.find(
		(item) => item.catalog_item_id === catalogItemId
	) as Catalog_Item | undefined

	const [sort, setSort] = useState(SortOrder.Up)
	const [searchText, setSearchText] = useState<string>()

	const [sequenceTopic, sequenceItems, isFetching, moreResults, loading] = useGetSequenceTopics(
		searchText,
		shouldSortTitleOrDate(sort)[0],
		sequenceTopicId
	)

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: capitalize(catalog?.name)
			},
			{
				index: 2,
				overrideName: capitalize(catalogItem?.name)
			},
			{
				index: 3,
				overrideName: sequenceTopic?.title
			}
		],
		params
	})

	// scroll to top
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const handleSearch = (search: string) => {
		setSearchText(search)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={sequenceTopic?.title}
				description={sequenceTopic?.description}
				rightActions={[
					{
						variant: 'outlined',
						style: { border: '1px solid rgba(50, 51, 66, 0.5)' },
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.LessonUpload.path
					}
				]}
			/>

			<Search teacherId={teacherId} type={TypeEnum.Video} onChange={handleSearch} textSearch={searchText} />

			<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>Sequences</b>
				</Typography>
				<div style={{ flexGrow: 1 }} />

				<SelectSort cyKey="Sequences" value={sort} onChange={handleSort} />
			</Box>
			{catalog && sequenceTopic && catalogItem && (
				<Grid container spacing={3}>
					<Grid item xs>
						{isFetching || !loading ? (
							<>
								<ListSequence
									catalog={catalog}
									catalogItem={catalogItem}
									sequenceTopic={sequenceTopic}
									data={sequenceItems}
									spacing={1.5}
								/>
							</>
						) : (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}

						{isFetching && moreResults && (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
						{!loading && sequenceItems.length > 0 && !moreResults && (
							<Box mt={4} textAlign="center">
								<Button
									onClick={() => {
										window.scrollTo(0, 0)
									}}
									variant="text"
									color="secondary">
									Back to Top
								</Button>
							</Box>
						)}
						{!loading && sequenceItems.length <= 0 && (
							<div>
								<Divider />
								<Box my={4}>
									{searchText ? (
										<Fragment>
											<Typography variant="h6" color="textSecondary">
												Your search for "{searchText}" did not return any results.
											</Typography>
										</Fragment>
									) : (
										<Fragment>
											<Typography variant="h6" color="textSecondary">
												No results found
											</Typography>
											<Typography color="textSecondary">
												Your filter selection did not return any results
											</Typography>
											<Typography color="textSecondary">
												Try removing a filter or upload own by clicking the button top
												right
											</Typography>
										</Fragment>
									)}
								</Box>
								<Divider />
							</div>
						)}
					</Grid>
				</Grid>
			)}
		</Box>
	)
}
