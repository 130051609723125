import React, { useEffect, useState } from 'react'

import { Box, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import clsx from 'clsx'
import { ActivityBoxEmpty, PaginationList, PaginationListDataItem, SelectSort } from 'src/components'
import { GradeSquare } from 'src/components/Squares/GradeSquare/GradeSquare'
import { useGetStudentClassSubmissionLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By } from 'src/graphql/autogenerate/schemas'
import { dueDateExpired, formatSubmissionDate, SortOrder } from 'src/utils'

import { getCyKey } from '../../../../../../../../utils/utils'
import { useStudentDetailContext } from '../../useStudentDetail'
import useStyles from './StudentAssignments.styles'
export const StudentAssignments = () => {
	const styles = useStyles()
	const pageElements = 5
	const { classSelectedId, setHasAssignmentsOrActivities, studentId } = useStudentDetailContext()
	const [assignmentsItems, setAssignmentsItems] = useState<PaginationListDataItem[]>([])
	const [sort, setSort] = useState(SortOrder.Up)

	const [getStudentSubmissions, { data, loading }] = useGetStudentClassSubmissionLazyQuery({
		variables: {
			classId: classSelectedId,
			offset: 0,
			limit: pageElements,
			orderBy: sort as unknown as Order_By,
			studentId
		},
		fetchPolicy: 'no-cache'
	})

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const refetchData = (page: number) => {
		getStudentSubmissions({
			variables: {
				offset: (page - 1) * pageElements,
				classId: classSelectedId,
				limit: pageElements,
				orderBy: sort as unknown as Order_By,
				studentId
			}
		})
	}

	useEffect(() => {
		getStudentSubmissions({
			variables: {
				classId: classSelectedId,
				offset: 0,
				limit: pageElements,
				orderBy: sort as unknown as Order_By,
				studentId
			}
		})
	}, [classSelectedId, sort])

	useEffect(() => {
		if (!loading && data && data.submission) {
			if (data.submission.length) setHasAssignmentsOrActivities(true)
			const items = data.submission.map((item) => {
				const formattedDate = formatSubmissionDate({ fullDateString: item.date_submitted })
				const dueDate = item.class_assignment?.assignment?.due_date
				const dueTime = item.class_assignment?.assignment?.due_time
				const submittedAfterDueDate = dueDateExpired(dueDate, dueTime, item.date_submitted)

				return {
					notAvatarIcon: <GradeSquare grade={item.grade || undefined} className={styles.grade} />,
					primary: (
						<Tooltip title={item.class_assignment?.assignment?.name || ''} placement="top-start">
							<Typography data-cy={getCyKey(StudentAssignments, 'assignmentName')} variant="h6">
								<b>{item.class_assignment?.assignment?.name}</b>
							</Typography>
						</Tooltip>
					),
					secondary: (
						<Box
							className={clsx(
								submittedAfterDueDate ? styles.redDate : styles.greenDate,
								styles.date
							)}>
							<AccessTime fontSize="inherit" />
							<Box>
								<Typography>{formattedDate}</Typography>
							</Box>
						</Box>
					)
				}
			})
			setAssignmentsItems(items)
		}
	}, [data, loading])

	return (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Assignments</b>
				</Typography>
				{loading ||
					(assignmentsItems.length > 0 && (
						<SelectSort cyKey="StudentAssignments" value={sort} onChange={handleSort} />
					))}
			</Box>
			{loading || assignmentsItems.length > 0 ? (
				<Paper>
					<PaginationList
						rowsPerPage={pageElements}
						limit={assignmentsItems.length}
						data={assignmentsItems}
						onPageChange={refetchData}
						loading={loading}
						totalRows={data?.submission_aggregate?.aggregate?.count ?? 0}
					/>
				</Paper>
			) : (
				<ActivityBoxEmpty
					title="No upcoming Assignments."
					description="Start by creating an Assignment for a Class. The upcoming Assignments will appear here."
				/>
			)}
		</Grid>
	)
}
