import { PagesProps } from '../../../../../../../@types'
import { MainTitle } from '../../../../../../../components'
import { useGetClassTeacherByPkQuery } from '../../../../../../../graphql/autogenerate/hooks'
import { buildBreadCrumbs, slugToName } from '../../../../../../../utils'
import { useClassWurrliesContext } from '../useClassWurrlies'

export const ClassWurrliesTitle = ({ page }: PagesProps) => {
	const { teacherId, classId } = useClassWurrliesContext()
	const { data: classData } = useGetClassTeacherByPkQuery({ variables: { teacherId, classId } })

	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
			},
			{
				index: 2,
				overrideName: 'Wurrlies'
			}
		],
		params: {
			classId
		}
	})

	return (
		<MainTitle
			breadcrumbs={breadCrumbs}
			title={'Class Wurrlies'}
			description={'These are the Wurrlies that the Students have submitted to this Class.'}
		/>
	)
}
