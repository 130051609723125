import { fade, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
	boxInput: {
		marginBottom: theme.spacing(6),
		backgroundColor: fade(theme.palette.text.secondary, 0.1),
		border: `1px solid ${fade(theme.palette.text.secondary, 0)}`,
		'& .MuiPaper-root :focus': {
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`
		},
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	boxInputFocus: {
		marginBottom: theme.spacing(6),
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`,
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	listItems: {
		overflow: 'hidden',
		position: 'absolute',
		top: 64,
		left: 0,
		right: 0,
		zIndex: 1,
		padding: theme.spacing(1),
		'& .MuiBox-root': {
			'&::-webkit-scrollbar': {
				width: theme.spacing(1),
				height: theme.spacing(1)
				// display: 'none'
			},
			'&:hover::-webkit-scrollbar': {
				display: 'block'
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: fade(theme.palette.text.secondary, 0.2),
				borderRadius: theme.spacing(1),
				'&:hover': {
					backgroundColor: fade(theme.palette.text.secondary, 0.25)
				},
				'&:active': {
					backgroundColor: fade(theme.palette.text.secondary, 0.3)
				}
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: fade(theme.palette.text.secondary, 0.1),
				borderRadius: theme.spacing(1)
			}
		}
	}
}))
