import { createStyles, fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
	createStyles({
		listBox: {
			alignItems: 'left',
			padding: 0,
			'& .MuiAutocomplete-option[aria-selected="true"]': {
				color: theme.palette.secondary.main,
				backgroundColor: 'rgba(255, 194, 12, 0.3)'
			},
			'& .MuiAutocomplete-option[data-focus="true"]': {
				color: theme.palette.secondary.main,
				backgroundColor: 'rgba(255, 194, 12, 0.1)'
			}
		},
		buttonOption: {
			height: '100%',
			position: 'relative',
			backgroundSize: 'cover',
			backgroundPosition: 'center 40%',
			backgroundRepeat: 'no-repeat',
			'& .MuiButton-iconSizeLarge > *:first-child': {
				fontSize: 50
			}
		},
		buttonDisabled: {
			'& .MuiButton-contained.Mui-disabled': {
				backgroundColor: 'rgba(255, 194, 12, 0.4)'
			}
		},
		selectInput: {
			width: '100%'
		},
		inputDefault: {
			padding: '10px 0',
			width: '100%'
		},
		inputSuccess: {
			padding: '10px 0',
			width: '100%',
			'& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.success.dark
			}
		},
		inputError: {
			padding: '10px 0',
			width: '100%',
			'& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.error.dark
			}
		},
		textDefault: {
			color: theme.palette.text.secondary
		},
		textSuccess: {
			color: theme.palette.success.dark
		},
		textError: {
			color: theme.palette.error.dark
		},
		chip: {
			color: theme.palette.background.default,
			backgroundColor: theme.palette.text.secondary,
			borderRadius: 4,
			marginRight: 5
		},
		closeChipIcon: {
			color: fade(theme.palette.background.default, 0.6),
			height: 26,
			width: 18,
			marginLeft: 3,
			'& .MuiChip-deleteIcon:hover': {
				color: theme.palette.background.default
			}
		},
		thumbnail: {
			'& .MuiGridListTile-tile': {
				borderRadius: 5
			}
		},
		spinner: {
			marginLeft: theme.spacing(1)
		}
	})
)
