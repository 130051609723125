import { createStyles, makeStyles } from '@material-ui/core'

export const useVideoRecorderStyles = makeStyles(() =>
	createStyles({
		guideButtonsContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'baseline',
			width: '400px'
		},
		guideButton: {
			marginRight: '1em',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around'
		},
		actionButton: {
			padding: '0.7em',
			margin: '0.3em 0',
			'&.selected': {
				backgroundColor: '#FFEFD3'
			}
		},
		centerContainer: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			margin: 0
		},
		growForCenteringButton: {
			width: 'calc(400px + 2em)'
		}
	})
)

export default useVideoRecorderStyles
