import { useEffect, useState } from 'react'

import { useTheme } from '@material-ui/core/styles'
import { SubscriptionsCardType } from 'src/@types'

export const useUnsuscribeDialog = (
	isOpen: boolean,
	item: SubscriptionsCardType | undefined,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
	const theme = useTheme()
	const [isOnSuccessOpen, setIsOnSuccessOpen] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (isOpen && item === undefined) {
			setIsOnErrorOpen(true)
			setIsOnSuccessOpen(false)
			setIsOpen(false)
		}
	}, [])

	return {
		isOnSuccessOpen,
		loading,
		setLoading,
		setIsOnSuccessOpen,
		isOnErrorOpen,
		setIsOnErrorOpen,
		theme
	}
}
