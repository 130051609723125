import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { AddToClassDialog, InfoDialog } from 'src/components'
import { useSetInsertModuleToClassesMutation } from 'src/graphql/autogenerate/hooks'
import { Class, Class_Module_Insert_Input, Module } from 'src/graphql/autogenerate/schemas'

type AddPlaylistToClassProps = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	module?: Module
}

export const AddModuleToClass = ({ module, isOpen, setIsOpen }: AddPlaylistToClassProps) => {
	const [isErrorOpen, setIsErrorOpen] = useState(false)
	const [moduleId, setModuleId] = useState<number | undefined>()
	const [itemClasses, setItemClasses] = useState<Class[]>([])
	const [insertModuleToClasses] = useSetInsertModuleToClassesMutation()
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		return () => {
			setModuleId(undefined)
			setIsOpen(false)
			setIsErrorOpen(false)
			setItemClasses([])
		}
	}, [])

	useEffect(() => {
		if (!moduleId) setIsOpen(false)
	}, [moduleId])

	useEffect(() => {
		if (isOpen && module) {
			setModuleId(module.module_id)
			setItemClasses(module.class_modules?.map((item) => item.class) || [])
		} else if (isOpen && !module) {
			setIsErrorOpen(true)
		}
	}, [isOpen])

	return (
		<Box>
			<InfoDialog
				open={isErrorOpen}
				onClose={() => {
					setIsErrorOpen(false)
					setIsOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsErrorOpen(false)
					setIsOpen(false)
				}}
			/>

			<AddToClassDialog
				isOpen={!!moduleId}
				setIsOpen={setModuleId}
				itemClasses={itemClasses}
				setSearchText={setSearchText}
				searchText={searchText}
				itemName="Module"
				title="Save Module to Class"
				description="This will save the Module so that you can view and teach right from your Class page.
				It will not be visible to your students, but you can share content from the Lesson by creating an Assignment or adding Songs or Videos to a Class."
				onConfirm={async (selectedClasses) => {
					const array = [...selectedClasses]
					const classes = array.map((cls) => {
						return {
							module_id: moduleId,
							class_id: cls.class_id
						} as Class_Module_Insert_Input
					})
					if (classes && classes.length) {
						await insertModuleToClasses({
							variables: { classes },
							update: (cache, { data }) => {
								const classesToUpdateInCache = data?.insert_class_module?.returning
								if (!classesToUpdateInCache) return

								let identify
								classesToUpdateInCache.forEach((cls) => {
									identify = cache.identify(cls.class)

									cache.evict({
										id: identify,
										fieldName: 'class_modules'
									})
									cache.evict({
										id: identify,
										fieldName: 'class_modules_aggregate'
									})
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'module_by_pk'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'module'
								})
							}
						})
					}
				}}
			/>
		</Box>
	)
}
