import { Box } from '@material-ui/core'
import { SectionHeader } from 'src/scenes/Student/1-Home/components/SectionHeader'
import { InsightsEmptyState } from 'src/scenes/Teacher/scenes/1-Home/components/Insights/components/InsightsEmptyState/InsightsEmptyState'
import { SelectDateRange } from 'src/scenes/Teacher/scenes/1-Home/components/Insights/components/SelectDateRange/SelectDateRange'
import { StudentPointsChart } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/CheckInsights/studentPointsChart/StudentPointsChart'
import { useClassDetailsContext } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/useClassDetails'

import useStyles from './Insights.styles'

type InsightsInnerType = {
	classId: number
}

export const InsightsInner = ({ classId }: InsightsInnerType) => {
	const styles = useStyles()
	const { dateRange, setDateRange, totalPoints } = useClassDetailsContext()

	return (
		<Box className={styles.section}>
			<SectionHeader content="Check Insights" />
			{totalPoints === 0 && <InsightsEmptyState />}
			{totalPoints > 0 && (
				<>
					<Box className={styles.selectorsRow}>
						<SelectDateRange selectedDateRange={dateRange} setSelectedDateRange={setDateRange} />
					</Box>
					<StudentPointsChart classId={classId} dateRange={dateRange} />
				</>
			)}
		</Box>
	)
}
