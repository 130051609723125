import { createStyles, makeStyles } from '@material-ui/core'

export const useAllClassProductsStyles = makeStyles(() =>
	createStyles({
		listToolbar: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '12px',
			alignItems: 'center',
			marginBottom: '20px'
		},
		infiniteScroll: {
			'&::-webkit-scrollbar': {
				display: 'none'
			},
			'&': {
				'-ms-overflow-style': 'none' /* IE and Edge */,
				'scrollbar-width': 'none' /* Firefox */
			}
		}
	})
)

export default useAllClassProductsStyles
