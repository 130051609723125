import React, { useEffect, useState, Fragment } from 'react'

import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	GridList,
	GridListTile,
	Typography,
	useTheme
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import { BaseDialog } from 'src/components'
import { useGetClassesByStudentLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Class } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { buildImagePath, DEFAULT_MUSIC_BG } from 'src/utils'

import useStyles from './SelectClassDialog.styles'

type AddToClassProps = {
	// classes where the item belongs to (already saved on db)
	itemName?: string
	setSelectedClass: React.Dispatch<React.SetStateAction<number>>
	selectedClass: number
	title?: string
	onConfirm: (classId: number) => void
	onDone?: () => void
	onSuccess?: {
		shouldClose?: boolean
		onDiscard?: () => void
		confirmLabel?: string
		discardLabel?: string
	}
	footer?: React.ReactNode
	sendParamAsState?: boolean
}

type ClassroomType = {
	index?: boolean
	selected?: boolean
} & Class

export const SelectChallengeClassDialog = ({
	title,
	setSelectedClass,
	selectedClass,
	onConfirm
}: AddToClassProps) => {
	const style = useStyles()
	const theme = useTheme()
	const { studentData: student } = useLoginContext()
	const studentId = student.student_id

	const [getClassesByStudent, { loading, data: classData }] = useGetClassesByStudentLazyQuery()
	const classGrid = classData?.class?.map((studentClass) => studentClass as Class) || []

	const [openDialog, setOpenDialog] = useState(false)
	const [classes, setClasses] = useState<ClassroomType[]>([])

	useEffect(() => {
		if (!studentId) return
		getClassesByStudent({
			variables: {
				studentId
			}
		})
	}, [studentId])

	useEffect(() => {
		if (loading) return

		if (classGrid.length) {
			if (classGrid.length === 1) {
				setSelectedClass(classGrid[0].class_id)
				onConfirm(classGrid[0].class_id)
			} else {
				setClasses(classGrid as ClassroomType[])
				setOpenDialog(true)
			}
		}
	}, [JSON.stringify(classGrid), loading])

	if (!openDialog) {
		return <></>
	}

	return (
		<Box>
			<BaseDialog
				PaperProps={{ style: { width: '765px' } }}
				dividers={false}
				onClose={() => {
					setOpenDialog(false)
					setSelectedClass(0)
				}}
				open={openDialog}
				header={
					<Fragment>
						<Typography variant="h5" align="center">
							<b>{title}</b>
						</Typography>
					</Fragment>
				}
				body={
					<Box className={style.body}>
						<GridList className={style.grid}>
							{classes.map((iclass, i) => (
								<GridListTile key={iclass.class_id} cols={1}>
									<Card
										variant={iclass.selected ? 'outlined' : 'elevation'}
										elevation={3}
										style={{
											backgroundImage: `url( ${
												iclass.image_path
													? buildImagePath(iclass.image_path)
													: DEFAULT_MUSIC_BG
											})`,
											backgroundColor: theme.palette.primary.main,
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'cover',
											backgroundPosition: 'center 40%',
											height: '-webkit-fill-available',
											color: theme.palette.common.white,
											margin: 5
										}}
										color="secondary"
										onClick={() => {
											setSelectedClass(iclass.class_id)
										}}>
										<CardActionArea
											className={iclass.selected ? style.selected : style.nonSelected}>
											<CardContent>
												<Box position="absolute" bottom={5} left={5}>
													<Typography gutterBottom variant="body2">
														<b>{iclass.title}</b>
													</Typography>
												</Box>
												<Box className={style.grid}>
													{classes[i].class_id === selectedClass ? (
														<CheckCircleIcon fontSize="small" color="secondary" />
													) : (
														<RadioButtonUncheckedOutlinedIcon
															fontSize="small"
															color="disabled"
														/>
													)}
												</Box>
											</CardContent>
										</CardActionArea>
									</Card>
								</GridListTile>
							))}
						</GridList>
					</Box>
				}
				onConfirm={() => onConfirm(selectedClass)}
				onConfirmProps={{ style: { width: '154px', marginRight: '10px' } }}
				isConfirmDisabled={!selectedClass || loading}
				confirmLabel="Next"
				onDiscard={() => {
					if (loading) return

					setOpenDialog(false)
					setSelectedClass(0)
				}}
				onDiscardProps={{
					disabled: loading,
					style: {
						width: '154px',
						marginLeft: '10px'
					}
				}}
				discardLabel={'Cancel'}
			/>
		</Box>
	)
}
