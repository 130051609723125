import React, { ReactNode, useState } from 'react'

import { Box, BoxProps, Popover, Typography, TypographyProps } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import clsx from 'clsx'
import { capitalize } from 'src/utils'

import useStyles from './InputHeader.styles'

export type InputHeaderProps = BoxProps & {
	name?: ReactNode | string
	isRequired?: boolean
	popOverLabel?: JSX.Element
	endAdornment?: JSX.Element
	customMargin?: string
	endText?: string
	endTextProps?: TypographyProps
}

export const InputHeader = ({
	name,
	className,
	isRequired = false,
	popOverLabel,
	endAdornment,
	customMargin = '40px',
	endText,
	endTextProps,
	...props
}: InputHeaderProps) => {
	const styles = useStyles()
	const [anchorEl, setAnchorEl] = useState<EventTarget & SVGSVGElement>()
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined) => {
		setAnchorEl(event?.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(undefined)
	}

	return (
		<Box className={clsx(styles.root, className)} marginTop={customMargin} {...props}>
			<Box display="flex" justifyContent="space-between">
				<Typography className={styles.label}>
					{typeof name === 'string' ? capitalize(name) : name}
					{isRequired ? '*' : ''}
				</Typography>
				{popOverLabel && (
					<Box display="flex" alignItems="center">
						<HelpIcon
							aria-owns={anchorEl?.id === 'songCatalogType' ? 'mouse-over-popover' : undefined}
							aria-haspopup="true"
							id="songCatalogType"
							style={{ marginLeft: '5px' }}
							onMouseEnter={(e) => handlePopoverOpen(e)}
							fontSize="small"
							color="secondary"
						/>
					</Box>
				)}
				{endText && (
					<Typography color={'textSecondary'} {...endTextProps}>
						{endText}
					</Typography>
				)}
				{endAdornment && (
					<Box display="flex" alignItems="center">
						{endAdornment}
					</Box>
				)}
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				classes={{
					paper: styles.paper
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus>
				<Typography>{popOverLabel}</Typography>
			</Popover>
		</Box>
	)
}
