import { Box, Button, CircularProgress, styled, Typography } from '@material-ui/core'
import { ClassWithCounters, Page } from 'src/@types'
import { DoubleMusicNoteIcon, LessonsIcon, PersonIcon, VideoIcon } from 'src/assets/icons'
import { Datetime, ImageButton } from 'src/components'
import { buildRouteParameters, DEFAULT_MUSIC_BG, formatDate } from 'src/utils'

import { getCyKey } from '../../../utils/utils'
import useStyles from './ClassCard.styles'

export type ClassForCard = ClassForLinePanel &
	Pick<
		ClassWithCounters,
		| 'class_id'
		| 'title'
		| 'image_path'
		| 'start_date'
		| 'end_date'
		| 'maturity_level'
		| 'total_lessons_in_class'
		| 'grade_id'
		| 'totalVideos'
		| 'totalLessons'
	> & {
		isLoading?: boolean
	}
type ClassForLinePanel = Pick<
	ClassWithCounters,
	| 'class_students_aggregate'
	| 'class_lesson_plans_aggregate'
	| 'class_songs_aggregate'
	| 'class_tips_aggregate'
	| 'class_modules'
	| 'class_sequences'
	| 'totalVideos'
>

type ClassCardProps = {
	classElement: ClassForCard
	pages: Record<string, Page>
}

export const ClassCard = ({ classElement, pages }: ClassCardProps) => {
	const styles = useStyles()

	const dateTextStart = classElement.start_date ? formatDate(classElement.start_date) : 'Missing Start Date'
	const dateTextEnd = classElement.end_date ? formatDate(classElement.end_date) : 'Missing End Date'

	return !classElement.isLoading ? (
		<ImageButton
			titleHeight={{ xs: 250, md: 277 }}
			backgroundImageUrl={classElement.image_path || DEFAULT_MUSIC_BG}
			title={
				<Typography data-cy={getCyKey(ClassCard, classElement.title)} className={styles.title}>
					{classElement.title}
				</Typography>
			}
			titleSuffix={
				!!classElement.grade_id ? undefined : (
					<Typography className={styles.ungradedBadge}>Ungraded</Typography>
				)
			}
			titlePosition="initial"
			element={
				<LinePanel
					classElement={classElement}
					lessonCounter={classElement.class_lesson_plans_aggregate.aggregate?.count || 0}
				/>
			}
			elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
			linkTo={{
				pathname: buildRouteParameters(pages.ClassDetail, {
					classId: classElement.class_id
				})
			}}
			subtitle={
				<Datetime className={styles.datetime} color="primary" text={`${dateTextStart} - ${dateTextEnd}`} />
			}
		/>
	) : (
		<LoaderBox>
			<CircularProgress color="secondary" />
		</LoaderBox>
	)
}

const LinePanel = ({
	classElement,
	lessonCounter
}: {
	classElement: ClassForLinePanel
	lessonCounter: number
}) => {
	const iconSize = 20

	return (
		<StyledBox>
			<Button startIcon={<PersonIcon height={iconSize} width={iconSize} />}>
				{classElement?.class_students_aggregate?.aggregate?.count ?? '0'}
			</Button>
			<Button startIcon={<LessonsIcon height={iconSize} width={iconSize} />}>{lessonCounter}</Button>
			<Button startIcon={<DoubleMusicNoteIcon height={iconSize} width={iconSize} />}>
				{classElement?.class_songs_aggregate?.aggregate?.count ?? '0'}
			</Button>
			<Button startIcon={<VideoIcon height={iconSize} width={iconSize} />}>
				{classElement?.totalVideos ?? '0'}
			</Button>
			<div style={{ flexGrow: 1 }} />
		</StyledBox>
	)
}

const StyledBox = styled(Box)(({ theme }) => ({
	width: '100%',
	pointerEvents: 'none',
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	'& .MuiButton-root': {
		backgroundColor: theme.palette.common.white
	},
	'& .MuiButton-root:not(:last-child)': {
		marginRight: 5
	},
	'& .MuiButton-root:first-child': {
		marginLeft: 32
	},
	// view button
	'& .MuiButton-root:last-child': {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginRight: 32
	}
}))

const LoaderBox = styled(Box)({
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
})
