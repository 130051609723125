import { Page } from 'src/@types'
import { Challenge } from 'src/scenes/Student/1-Home/components/Challenge'
import { ClassAssignments } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassAssignments'
import { ClassSongs } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/ClassSongs'
import { ClassVideos } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/ClassVideos'
import { ClassWurrly } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/ClassWurrly'
import { MusicCatalog } from 'src/scenes/Student/scenes/3-MusicCatalog/MusicCatalog'
import { CatalogSongs } from 'src/scenes/Student/scenes/3-MusicCatalog/scenes/CatalogSongs/CatalogSongs'
import { MyFavorites } from 'src/scenes/Student/scenes/4-MyFavorites'
import { EditInformation } from 'src/scenes/Student/scenes/5-Profile/EditInformation'
import { Profile } from 'src/scenes/Student/scenes/5-Profile/Profile'
import { Assignments } from 'src/scenes/Student/scenes/6-Assignments'
import { ViewAssignment } from 'src/scenes/Student/scenes/6-Assignments/scenes/Details/ViewAssignment'
import { MyWurrlies } from 'src/scenes/Student/scenes/7-MyWurrlies/MyWurrlies'
import { ReviewAndSubmit } from 'src/scenes/Student/scenes/StudentStudio/ReviewAndSubmit'
import { ChooseBestTake } from 'src/scenes/Student/scenes/StudentStudio/scenes/StudentChooseBestTake/ChooseBestTake'
import { EditCaption } from 'src/scenes/Student/scenes/StudentStudio/scenes/StudentChooseBestTake/scenes/EditCaption'

import { AvatarSelection } from '../scenes/Login/scenes/2-StudentLogin/scenes/SignInOptions/scenes/AvatarSelectionDialog/components/AvatarSelection'
import { StudentHome } from '../scenes/Student/1-Home'
import { Classes } from '../scenes/Student/scenes/2-Classes/Classes'
import { ClassDetails } from '../scenes/Student/scenes/2-Classes/scenes/ClassDetail/ClassDetails'
import { StudentStudio } from '../scenes/Student/scenes/StudentStudio/StudentStudio'
import { Notifications } from '../scenes/Teacher/scenes/12-Notifications'

// Use SlugEnum when you don't want the Drawer to be shown
export enum StudentNoDrawerEnum {
	AvatarSelector = '/student/avatar'
}

export const StudentPages: Record<string, Page> = {
	Home: {
		name: 'Dashboard',
		path: '/student',
		component: StudentHome,
		params: ['page', 'studentId'],
		noPadding: true
	},
	Profile: {
		name: 'Profile',
		path: '/student/profile',
		component: Profile,
		params: [],
		noPadding: true
	},
	ProfileEdit: {
		parentName: 'Profile',
		name: 'Edit Profile',
		path: '/student/profile/edit',
		component: EditInformation,
		params: [],
		noPadding: true
	},
	AvatarSelection: {
		name: 'Avatar Selection',
		path: '/student/avatar',
		component: AvatarSelection,
		params: [],
		noDrawer: true
	},
	/* begin classes */
	Classes: {
		name: 'Classes',
		path: '/student/classes',
		component: Classes,
		params: []
	},
	ClassDetail: {
		parentName: 'Classes',
		name: 'Class Details',
		path: '/student/classes/:classId/details',
		component: ClassDetails,
		params: ['classId'],
		noPadding: true
	},
	ClassAssignments: {
		parentName: 'ClassDetail',
		name: 'Assignments',
		path: '/student/classes/:classId/assignments',
		component: ClassAssignments,
		params: ['classId']
	},
	/* end classes */
	/* begin notifications */
	Notifications: {
		name: 'Notifications',
		path: '/student/notifications',
		component: Notifications,
		params: []
	},
	/* end notifications */
	/* begin studio */
	IndieStudentStudio: {
		name: 'Student Studio',
		path: '/student/classes/:classId/song/:songId/studio',
		component: StudentStudio,
		params: ['songId', 'classId'],
		noDrawer: true
	},
	AssignmentStudentStudio: {
		name: 'Student Assigment',
		path: '/student/class/:classId/assignments/:submissionId/song/:songId/studio',
		component: StudentStudio,
		params: ['songId', 'submissionId', 'classId'],
		noDrawer: true
	},
	ChallengeSong: {
		name: 'Challenge',
		path: '/student/challenge/:challengeId',
		component: Challenge,
		params: ['challengeId']
	},
	ChallengeStudentStudio: {
		name: 'Student Challenge',
		path: '/student/class/:classId/challenge/:challengeId/song/:songId/studio',
		component: StudentStudio,
		params: ['songId', 'challengeId', 'classId'],
		noDrawer: true
	},
	StudentRecord: {
		name: 'Student Video Recorder',
		path: '/student/studio/:songId/recorder',
		component: ReviewAndSubmit,
		params: ['songId'],
		noDrawer: true
	},
	StudioReview: {
		name: 'Student Video Review',
		path: '/student/studio/:songId/review',
		component: ReviewAndSubmit,
		params: ['songId'],
		noDrawer: true
	},
	ChooseBestTake: {
		name: 'Student Init Video Recording',
		path: '/student/classes/studio/:songId/recorder/chooseBestTake',
		component: ChooseBestTake,
		params: ['songId'],
		noDrawer: true
	},
	EditCaption: {
		name: 'Edit Caption',
		path: '/student/classes/studio/:songId/recorder/editCaption',
		component: EditCaption,
		params: ['songId'],
		noDrawer: true
	},
	/* end studio */
	/* begin wurrlies */
	ClassWurrly: {
		parentName: 'ClassDetail',
		name: 'Wurrly',
		path: '/student/classes/:classId/wurrly/:wurrlyId',
		component: ClassWurrly,
		params: ['classId', 'wurrlyId']
	},
	ClassWurrlyRouteMyWurrlies: {
		parentName: 'MyWurrlies',
		name: 'Wurrly',
		path: '/student/classes/:classId/wurrly/:wurrlyId/:route',
		component: ClassWurrly,
		params: ['classId', 'wurrlyId', 'route']
	},
	/* end wurrlies */
	/* begin music catalog */
	MusicCatalog: {
		name: 'Music Catalog',
		path: '/student/classes/:classId/music-catalog',
		component: MusicCatalog,
		params: ['classId']
	},
	MusicCatalogDrawer: {
		name: 'Music Catalog Drawer',
		path: '/student/music-catalog',
		component: MusicCatalog,
		params: []
	},
	BrowseByChords: {
		parentName: 'MusicCatalog',
		name: 'Browse By Chords',
		path: '/student/classes/:classId/music-catalog/chords',
		component: CatalogSongs,
		params: ['classId']
	},
	BrowseByChordsDrawer: {
		parentName: 'MusicCatalogDrawer',
		name: 'Browse By Chords Drawer',
		path: '/student/music-catalog/chords',
		component: CatalogSongs,
		params: []
	},
	SearchSongs: {
		parentName: 'MusicCatalog',
		name: 'Search',
		path: '/student/classes/:classId/music-catalog/search',
		component: CatalogSongs,
		params: ['classId']
	},
	SearchSongsDrawer: {
		parentName: 'MusicCatalogDrawer',
		name: 'Search Songs Drawer',
		path: '/student/music-catalog/search',
		component: CatalogSongs,
		params: []
	},
	CatalogSongs: {
		parentName: 'MusicCatalog',
		name: 'Catalog Song Chords',
		path: '/student/classes/:classId/music-catalog/catalogs/:catalogId/songs',
		component: CatalogSongs,
		params: ['catalogId', 'classId']
	},
	CatalogSongsDrawer: {
		parentName: 'MusicCatalogDrawer',
		name: 'Catalog Song Chords Drawer',
		path: '/student/music-catalog/catalogs/:catalogId/songs',
		component: CatalogSongs,
		params: ['catalogId']
	},
	CatalogItemSongs: {
		parentName: 'CatalogSongs',
		name: 'Catalog Songs',
		path: '/student/classes/:classId/music-catalog/catalogs/:catalogId/items/:catalogItemId',
		component: CatalogSongs,
		params: ['classId', 'catalogId', 'catalogItemId']
	},
	CatalogItemSongsDrawer: {
		parentName: 'CatalogSongsDrawer',
		name: 'Catalog Songs Drawer',
		path: '/student/music-catalog/catalogs/:catalogId/items/:catalogItemId',
		component: CatalogSongs,
		params: ['catalogId', 'catalogItemId']
	},
	MyFavorites: {
		name: 'My Favorites',
		path: '/student/my-favorites',
		component: MyFavorites,
		params: []
	},
	Assignments: {
		name: 'Assignments',
		path: '/student/assignments',
		component: Assignments,
		params: []
	},
	AssignmentDetails: {
		parentName: 'Assignments',
		name: 'Assignment Details',
		path: '/student/assignments/:submissionId/details',
		component: ViewAssignment,
		params: ['submissionId']
	},
	MyWurrlies: {
		name: 'My Wurrlies',
		path: '/student/my-wurrlies',
		component: MyWurrlies,
		params: []
	},
	ClassSongs: {
		parentName: 'ClassDetail',
		name: 'Class Songs',
		path: '/student/classes/:classId/songs',
		component: ClassSongs,
		params: ['classId']
	},
	ClassVideos: {
		parentName: 'ClassDetail',
		name: 'Class Videos',
		path: '/student/classes/:classId/videos',
		component: ClassVideos,
		params: ['classId']
	}
} as const
