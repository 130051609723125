import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router'
import { LessonsSmallIcon } from 'src/assets/icons'
import { ActionButton, BaseButton } from 'src/components'
import { AssignmentAdded } from 'src/components/Buttons/AssignmentAdded/AssignmentAdded'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildImagePath, buildRouteParameters } from 'src/utils'

import useStyles from './LessonCard.styles'
type LessonCardProps = React.HTMLAttributes<HTMLDivElement> & {
	lesson: Lesson_Plan
	selectedClassId?: number
}
export const LessonCard = ({ lesson, className, selectedClassId }: LessonCardProps) => {
	const styles = useStyles()
	const history = useHistory()
	const imageUrl = buildImagePath(lesson?.image_path)
	const title = lesson?.title
	const description = lesson?.description

	const goToLessonDetails = () => {
		history.push(
			buildRouteParameters(
				Pages.LessonDetail, // Route with paramters
				{ lessonPlanId: lesson?.lesson_plan_id }
			)
		)
	}

	const goToCreateFromLesson = () => {
		if (lesson?.lesson_plan_id && selectedClassId) {
			history.push(
				buildRouteParameters(
					Pages.AddAssignmentFromLesson,
					{ lessonPlanId: lesson.lesson_plan_id },
					true,
					{ selectedClassId }
				)
			)
		}
	}

	const goToClassAssignment = () => {
		const classAssignmentId = lesson?.assignments[0]?.class_assignments[0]?.class_assignment_id

		if (classAssignmentId && selectedClassId) {
			history.push(
				buildRouteParameters(Pages.ClassAssignmentDetail, { classAssignmentId, classId: selectedClassId })
			)
		}
	}

	return (
		<ListItem className={styles.listItem + ' ' + className}>
			<ListItemAvatar className={styles.avatar}>
				<Avatar variant="rounded" src={imageUrl} />
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={
					<div>
						<Typography className={styles.lessonTitle} variant="h6">
							<b>{title}</b>
						</Typography>
						<Typography noWrap className={styles.lessonDescription}>
							{description}
						</Typography>
					</div>
				}
			/>
			<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
				<Tooltip title="View Lesson">
					<ActionButton icon={<LessonsSmallIcon />} onClick={goToLessonDetails} />
				</Tooltip>
				{!lesson.assignment_created && (
					<Tooltip title="Add Assignment">
						<BaseButton color="primary" endIcon={<AddIcon />} onClick={goToCreateFromLesson}>
							<Typography>Add Assignment</Typography>
						</BaseButton>
					</Tooltip>
				)}
				{lesson.assignment_created && <AssignmentAdded onClick={goToClassAssignment} />}
			</Box>
		</ListItem>
	)
}
