import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core'

export const useListItemPlaylistStyles = makeStyles<Theme, { imageUrl: string }>((theme: Theme) =>
	createStyles({
		listItem: {
			display: 'flex',
			flexWrap: 'wrap',
			backgroundColor: theme.palette.common.white,
			cursor: 'pointer',
			boxShadow: theme.shadows[1],
			borderRadius: '4px',
			overflow: 'hidden',
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'auto',
					height: 36,
					'&:not(.addClass)': {
						width: 36,
						padding: theme.spacing(0.75),
						color: theme.palette.primary.main
					},
					'&.subscription': {
						backgroundColor: '#FFF6DB',
						borderColor: '#F3AB34',
						border: '2px solid',
						width: 'auto',
						padding: theme.spacing(0.75, 1.5)
					},
					'&.addToClass': {
						backgroundColor: '#221f31',
						width: 'auto',
						padding: theme.spacing(0.75, 1.5),
						color: '#E2E2E4'
					},
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		avatarImage: {
			width: '25%',
			minWidth: '230px',
			position: 'relative',
			backgroundSize: 'cover',
			backgroundPosition: 'center 50%',
			color: theme.palette.common.white,
			backgroundImage: ({ imageUrl }) => `url(${imageUrl})`
		},
		bottomBar: {
			position: 'absolute',
			display: 'flex',
			height: '30px',
			bottom: 0,
			width: '100%',
			paddingRight: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			color: theme.palette.common.white,
			backgroundColor: alpha(theme.palette.common.black, 0.4),
			alignItems: 'center'
		},
		avatarMain: {
			display: 'flex',
			justifyContent: 'initial',
			alignItems: 'initial',
			width: '100%',
			height: '160px',
			padding: theme.spacing(2),
			paddingBottom: '30px',
			[theme.breakpoints.down('xs')]: {
				height: '250px'
			},
			backgroundColor: alpha(theme.palette.common.black, 0.4)
		},
		avatarTitle: {
			display: 'webkit-box',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: 2,
			overflow: 'hidden',
			blockOverflow: 'ellipsis',
			maxLines: 3,
			textAlign: 'left',
			textOverflow: 'ellipsis'
		},
		content: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'wrap',
			padding: '24px'
		},
		description: {
			width: '100%',
			'& .MuiTypography-root': {
				fontSize: 14
			}
		},

		tiltedIcon: {
			transform: 'rotate(45deg)'
		},
		locked: {
			fontSize: '0.75rem',
			marginTop: '1px',
			marginLeft: '2px'
		},
		lockedButton: {
			borderRadius: '100px',
			width: '125px',
			height: '27px'
		},
		subscribe: {
			color: '#F3AB34'
		}
	})
)

export default useListItemPlaylistStyles
