import { useEffect, useState } from 'react'

import { Box, makeStyles, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { BaseLoading, BigBaseButton, MainTitle } from 'src/components'
import { MusicCatalog } from 'src/components/MusicCatalog/MusicCatalog'
import { MusicCatalogProvider } from 'src/components/MusicCatalog/useMusicCatalog'
import { useGetChallengeByIdQuery } from 'src/graphql/autogenerate/hooks'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { StudentPages } from 'src/routes/studentpages'
import SelectedSongs from 'src/scenes/Student/scenes/6-Assignments/scenes/Details/components/StepThreeForm/components/SelectedSongs'
import { buildBreadCrumbs, buildRouteParameters } from 'src/utils'

const useStyles = makeStyles({
	box: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 45,
		'& .MuiButtonBase-root': {
			padding: '6px 40px'
		}
	},
	title: {
		wordBreak: 'break-word'
	}
})

export const Challenge = ({ page }: PagesProps) => {
	const styles = useStyles()
	const history = useHistory()
	const { challengeId } = useWurrlyParams()
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const classId = params.get('classId') as unknown as number

	const [selectedSongId, setSelectedSongId] = useState<number>(0)
	const [songs, setSongs] = useState<Song[]>([])

	const { data } = useGetChallengeByIdQuery({ variables: { challengeId } })
	const title = data?.challenge_by_pk?.title ?? ''

	const breadcrumbs = buildBreadCrumbs({
		page
	})

	useEffect(() => {
		if (data?.challenge_by_pk?.challenge_songs) {
			setSongs(data?.challenge_by_pk?.challenge_songs.map((row) => row.song as Song))
		}
	}, [data])

	const handleGoBack = () => {
		history.push(StudentPages.Home.path)
	}

	const handleGoCreate = () => {
		history.push(
			buildRouteParameters(StudentPages.ChallengeStudentStudio, {
				challengeId,
				songId: selectedSongId,
				classId
			})
		)
	}

	if (!classId) {
		return <BaseLoading />
	}

	return (
		<>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={
					<Box width="1000px" className={styles.title}>
						<Typography variant={title.length > 40 ? 'h4' : 'h3'}>
							<b>{title}</b>
						</Typography>
					</Box>
				}
				description={
					<Box width="1000px">
						<Typography noWrap>{data?.challenge_by_pk?.description}</Typography>
					</Box>
				}
			/>

			{data?.challenge_by_pk?.include_all_songs ? (
				<MusicCatalogProvider>
					<MusicCatalog />
				</MusicCatalogProvider>
			) : (
				<>
					<SelectedSongs
						songs={songs}
						selectedSongId={selectedSongId}
						setSelectedSongId={setSelectedSongId}
					/>

					<Box className={styles.box}>
						<BigBaseButton onClick={handleGoBack}>Go Back</BigBaseButton>
						<BigBaseButton color="secondary" onClick={handleGoCreate} disabled={!selectedSongId}>
							Go Create!
						</BigBaseButton>
					</Box>
				</>
			)}
		</>
	)
}
