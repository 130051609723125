import { useEffect, useState } from 'react'

import { Box, BoxProps, LinearProgress, Typography } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

// const BorderLinearProgress = withStyles(() => ({
// 	root: {
// 		height: 5
// 	},
// 	colorSecondary: {
// 		backgroundColor: '#bfbfbf'
// 	}
// }))(LinearProgress)

type StepperProps = {
	currentStep: number
	totalSteps: number
	stepDescription: string
	stepBoxProps?: BoxProps
	stepDescriptionClassName?: string
	stepText?: string
}
export const Stepper = ({
	currentStep,
	totalSteps,
	stepDescription,
	stepBoxProps,
	stepDescriptionClassName,
	stepText
}: StepperProps) => {
	const [progress, setProgress] = useState(0)

	useEffect(() => {
		setProgress((currentStep / totalSteps) * 100)
	}, [currentStep, totalSteps])

	return (
		<Box {...stepBoxProps}>
			<Box position="relative" display="flex" justifyContent="space-between">
				<Typography variant="subtitle1" className={stepDescriptionClassName}>
					<b>{stepDescription}</b>
				</Typography>
				<Typography variant="subtitle1">
					{stepText ? (
						<b>{stepText}</b>
					) : (
						<b>
							Step {currentStep}/{totalSteps}
						</b>
					)}
				</Typography>
			</Box>
			<Box mt={-2.5}>
				<PlayArrowIcon
					color="secondary"
					style={{
						position: 'relative',
						left: `calc(${progress}% - 12px)`,
						top: 10,
						transform: 'rotate(90deg)'
					}}
				/>
				<LinearProgress
					variant="determinate"
					value={progress}
					aria-labelledby="continuous-slider"
					color="secondary"
				/>
			</Box>
		</Box>
	)
}
