import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { WurrliesGrid } from 'src/components/Grids/WurrliesGrid'
import { queryParamsStudentWurrlies } from 'src/components/Grids/WurrliesGrid.types'
import { Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { SelectClasses } from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentDetail/components/SelectClasses'
import {
	StudentDetailProvider,
	useStudentDetailContext
} from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentDetail/useStudentDetail'
import { useStudentWurrliesGrid } from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentWurrlies/components/StudentWurrliesGrid/hooks'
import { StudentWurrliesTitle } from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentWurrlies/components/StudentWurrliesTitle'
import { buildRouteParameters, shouldSortTitleOrDate, SortOrder } from 'src/utils'

type Props = PagesProps

export const ClassStudentWurrlies = (props: Props) => {
	return (
		<Box>
			<StudentDetailProvider>
				<StudentWurrliesTitle {...props} />
				<SelectClasses />
				<StudentWurrliesGrid />
			</StudentDetailProvider>
		</Box>
	)
}

const StudentWurrliesGrid = () => {
	const [sort, setSort] = useState(SortOrder.Up)
	const history = useHistory()

	const { studentId, classId, classSelectedId } = useStudentDetailContext()
	const { setWurrliesVariables, wurrlies, count, loading, moreResults } = useStudentWurrliesGrid()

	useEffect(() => {
		const [orderTitle, orderDate] = shouldSortTitleOrDate(sort)
		setWurrliesVariables({
			studentId,
			classId: classSelectedId,
			orderTitle,
			orderDate,
			privacySettings: [Privacy_Enum.TeacherAndMe, Privacy_Enum.Class]
		})
	}, [sort, classSelectedId])

	const handleWurrly = (wurrlyId: number) => {
		history.push(
			buildRouteParameters(
				Pages.ClassStudentWurrliesDetails,
				{
					studentId,
					classId: classSelectedId || classId,
					wurrlyId
				},
				true,
				{ sort } as queryParamsStudentWurrlies
			)
		)
	}

	return (
		<WurrliesGrid
			count={count}
			wurrlies={wurrlies}
			loading={loading}
			moreResults={moreResults}
			sort={{ sort, handleSort: setSort }}
			title="Student Wurrlies"
			onWurrlyClick={handleWurrly}
		/>
	)
}
