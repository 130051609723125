import * as jwt from 'jsonwebtoken'

import { Wurrly_Role_Enum } from '../graphql/autogenerate/schemas'

export const decodeHasuraToken = (token: string) => {
	const decoded = jwt.decode(token) as jwt.JwtPayload
	const key = 'https://hasura.io/jwt/claims'

	const hasuraClaims = decoded[key]
	if (!decoded || !hasuraClaims['x-hasura-default-role'] || !hasuraClaims['x-hasura-user-id']) return null

	const role = hasuraClaims['x-hasura-default-role'] as Wurrly_Role_Enum
	const id = +hasuraClaims['x-hasura-user-id']
	const exp = decoded.exp as number

	return {
		role,
		id,
		exp
	}
}

export const validToken = (token: string) => {
	if (!token) return false
	const decoded = decodeHasuraToken(token)
	if (!decoded) return false
	if (decoded.exp < Date.now() / 1000) return false

	return decoded
}
