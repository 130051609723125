import { makeStyles } from '@material-ui/core'

export const useSelectGradingSystemStyles = makeStyles(() => ({
	select: {
		width: '100%'
	},
	menuItem: {
		'&.Mui-selected': {
			background: 'rgba(243, 171, 52, 0.12)',
			color: 'rgba(243, 171, 52, 1)'
		}
	}
}))

export default useSelectGradingSystemStyles
