import React from 'react'

import {
	Box,
	Breadcrumbs,
	Button,
	ButtonProps,
	Grid,
	Link,
	Tooltip,
	Typography,
	useTheme
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Link as LinkRD } from 'react-router-dom'

import { BaseButton } from '.'
import { BreadcrumbsArrayType } from '../@types'
import { BREADCRUMBS_LENGTH, getCyKey } from '../utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .MuiButton-outlined': {
				borderWidth: 2,
				'&:hover': {
					borderWidth: 2
				}
			},
			'& .MuiButton-root:not(:last-child)': {
				marginRight: theme.spacing(2)
			}
		},
		linkCrumb: {
			color: theme.palette.secondary.dark
		},
		separatorCrumb: {
			'&& .MuiBreadcrumbs-separator': {
				color: theme.palette.background.paper,
				opacity: '0.7'
			}
		},
		titleText: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			'-webkit-line-clamp': 3,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word'
		},
		button: ({}) => ({})
	})
)

type MainTitleProps = {
	breadcrumbs: BreadcrumbsArrayType
	title: React.ReactNode
	description?: React.ReactNode
	list?: React.ReactNode
	rightActions?: ({
		name?: string
		linkTo?: string
		customWrapper?: ({ children }: { children: JSX.Element }) => JSX.Element
		tooltipText?: string
		isComingSoon?: boolean
	} & ButtonProps)[]
	actionButtons?: React.ReactNode[]
	breadcrumbPageStyle?: string
	titleMargin?: number
	marginBottom?: number | string
	locationState?: { [key: string]: unknown }
	assetsDisplay?: JSX.Element
	// tooltip?: boolean
}
export const MainTitle = ({
	breadcrumbs,
	title,
	description,
	list,
	rightActions,
	actionButtons,
	breadcrumbPageStyle,
	titleMargin,
	marginBottom,
	locationState = {},
	assetsDisplay
}: MainTitleProps) => {
	const styles = useStyles()
	const theme = useTheme()

	const getFinalPath = (page: typeof breadcrumbs[0]) => {
		return page.linkTo || { pathname: page.path, state: locationState }
	}

	const getPagePath = (page: typeof breadcrumbs[0]) => {
		return page.linkTo ? '' : page.path
	}

	return (
		<Box mb={marginBottom ?? 3} className={styles.root}>
			<Breadcrumbs
				data-cy={getCyKey(MainTitle, `breadcrumb`)}
				className={breadcrumbPageStyle && styles.separatorCrumb}
				aria-label="breadcrumb">
				{breadcrumbs.map((page, i) => {
					return (
						<Tooltip
							title={page.name && page.name.length < BREADCRUMBS_LENGTH ? '' : page.name}
							key={i}>
							<div key={page.name}>
								{i === breadcrumbs.length - 1 ? (
									<Typography
										data-cy={getCyKey(MainTitle, 'assignmentName')}
										className={clsx(breadcrumbPageStyle, styles.titleText)}
										style={
											breadcrumbPageStyle ? undefined : { color: 'rgba(46, 38, 66, 0.4)' }
										}>
										{page.name && page.name.length > BREADCRUMBS_LENGTH
											? page.name.substring(0, BREADCRUMBS_LENGTH) + '...'
											: page.name}
									</Typography>
								) : (
									<Link
										data-cy={getCyKey(MainTitle, `breadcrumbClassName${page.name}`)}
										className={styles.linkCrumb}
										onClick={() => {
											if (window.location.pathname === getPagePath(page)) {
												window.location.reload()
											}
										}}
										component={LinkRD}
										to={getFinalPath(page)}>
										{page.name && page.name.length > BREADCRUMBS_LENGTH
											? page.name.substring(0, BREADCRUMBS_LENGTH) + '...'
											: page.name}
									</Link>
								)}
							</div>
						</Tooltip>
					)
				})}
			</Breadcrumbs>

			<Box
				mt={titleMargin ?? 4}
				mb={1}
				display="flex"
				style={{
					justifyContent: 'space-between'
				}}>
				<Box width="50%" style={{ wordBreak: 'break-word' }}>
					{typeof title === 'string' ? (
						<Typography variant={title.length > 40 ? 'h4' : 'h3'}>
							<b data-cy={getCyKey(MainTitle)}>{title}</b>&nbsp;
						</Typography>
					) : (
						title
					)}
				</Box>
				<Box display="flex">
					{actionButtons?.map((btn, index) => (
						<Box marginRight="15px" key={`action-btn-${index}`}>
							{btn}
						</Box>
					))}
					<Box>
						{rightActions?.map((btn, index) => {
							// const ButtonCommonProsp = {
							// 	'data-cy': getCyKey(MainTitle, btn.name),
							// 	className: btn.className,
							// 	key: index + '-' + btn.name,
							// 	variant: btn.variant,
							// 	style: { ...btn.style, ...{ height: '56px' } },
							// 	color: btn.color,
							// 	startIcon: !btn.name ? undefined : btn.startIcon
							// }
							const ButtonLabel = !btn.name ? btn.startIcon : <b>{btn.name}</b>
							const ButtonStyle = { height: '56px', minWidth: btn.name ? '175px' : undefined }

							const ButtonUsed = btn.linkTo ? (
								<Button
									data-cy={getCyKey(MainTitle, btn.name)}
									className={btn.className}
									key={index + '-' + btn.name}
									variant={btn.variant}
									style={{
										...btn.style,
										...ButtonStyle
									}}
									color={btn.color}
									startIcon={!btn.name ? undefined : btn.startIcon}
									component={LinkRD}
									to={btn.linkTo}
									disabled={btn.disabled}>
									{ButtonLabel}
								</Button>
							) : btn.onClick ? (
								<Button
									data-cy={getCyKey(MainTitle, btn.name)}
									className={btn.className}
									key={index + '-' + btn.name}
									variant={btn.variant}
									style={{
										...btn.style,
										...ButtonStyle
									}}
									color={btn.color}
									startIcon={!btn.name ? undefined : btn.startIcon}
									endIcon={btn.endIcon}
									onClick={btn.onClick}
									disabled={btn.disabled}>
									{ButtonLabel}
								</Button>
							) : (
								<BaseButton
									key={index + '-' + btn.name}
									data-cy={getCyKey(MainTitle, btn.name)}
									{...btn}
									className={btn.className}
									style={{
										...btn.style,
										...ButtonStyle
									}}
									startIcon={!btn.name ? undefined : btn.startIcon}>
									{ButtonLabel}
								</BaseButton>
							)
							const Wrapper = btn.customWrapper
							const buttonWithTooltip =
								btn.tooltipText || btn.isComingSoon ? (
									<Tooltip title={btn.tooltipText || 'Coming Soon'}>{ButtonUsed}</Tooltip>
								) : (
									ButtonUsed
								)

							return Wrapper ? <Wrapper>{buttonWithTooltip}</Wrapper> : buttonWithTooltip

							/* return btn.tooltipText || btn.isComingSoon ? (
								<Tooltip title={btn.tooltipText || 'Coming Soon'}>{ButtonWithWrapper}</Tooltip>
							) : (
								ButtonWithWrapper
							) */
						})}
					</Box>
				</Box>
			</Box>
			{(description || assetsDisplay) && (
				<Grid style={{ marginTop: 20 }} container justifyContent="space-between" spacing={1}>
					{typeof description === 'string' ? (
						<Grid item xs={5}>
							<Typography
								data-cy={getCyKey(MainTitle, 'classDescription')}
								style={{ fontSize: 18, maxWidth: 510, textAlign: 'justify' }}>
								{description}
							</Typography>
						</Grid>
					) : (
						description
					)}
					{assetsDisplay && assetsDisplay}
				</Grid>
			)}

			{typeof list === 'string' ? (
				<Typography
					data-cy={getCyKey(MainTitle, 'classList')}
					style={{ fontSize: 17, maxWidth: 510, color: theme.palette.grey[600], paddingTop: 16 }}>
					{list}
				</Typography>
			) : (
				list
			)}
		</Box>
	)
}
