import { useEffect, useState, Fragment } from 'react'

import { Box, Typography } from '@material-ui/core'
import { SearchType } from 'src/@types'
import { BaseDialog, BigBaseButton, ContactDialog } from 'src/components'
import { ContactButton } from 'src/components/Buttons/ContactButton/ContactButton'
import { useGetDistrictsLazyQuery, useGetSchoolsByDistrictLazyQuery } from 'src/graphql/autogenerate/hooks'
import { buildSearchText, displayDistrict, getCyKey, MAX_SEARCH_ITEMS } from 'src/utils'

import { useLoginStyles } from '../styles'
import { DialogHeader } from './DialogHeader'
import { SignUpAutocomplete } from './SignUpAutocomplete'

type SchoolDialogProps = {
	open: boolean
	onClose: () => void
	onConfirm: () => void
	onDiscard: () => void
	setSchoolId: (schoolId: number) => void
	isLoading: boolean
}

export const SchoolDialog = ({
	open,
	onClose,
	onConfirm,
	onDiscard,
	setSchoolId,
	isLoading
}: SchoolDialogProps) => {
	const classes = useLoginStyles({})
	const [selectedDistrict, setSelectedDistrict] = useState<SearchType>()
	const [selectedSchool, setSelectedSchool] = useState<SearchType>()
	const [searchDistrictName, setSearchDistrictName] = useState('')
	const [searchSchoolName, setSearchSchoolName] = useState('')
	const [schoolDialogOpen, setSchoolDialogOpen] = useState(false)
	const [contactDialogOpen, setContactDialogOpen] = useState(false)

	const [getDistricts, { data: getDistrictsData, loading: getDistrictsLoading }] = useGetDistrictsLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [getSchools, { data: getSchoolsData, loading: getSchoolsLoading }] = useGetSchoolsByDistrictLazyQuery({
		fetchPolicy: 'no-cache'
	})

	const [schools, setSchools] = useState<SearchType[]>([])
	const schoolsInSelectedDistrict =
		getDistrictsData?.district.find((d) => d.district_id === selectedDistrict?.id)?.schools_aggregate.aggregate
			?.count || 0

	const closeContactDialog = () => {
		setContactDialogOpen(false)
		setSchoolDialogOpen(true)
	}

	useEffect(() => {
		if (open) {
			setSchoolDialogOpen(true)
			setContactDialogOpen(false)
		} else {
			setSchoolDialogOpen(false)
			setContactDialogOpen(false)
		}
	}, [open])

	useEffect(() => {
		// on search new district name
		if (!getDistrictsLoading) {
			const search = searchDistrictName.trim().replace(/\s{2,}/g, ' ')
			getDistricts({
				variables: {
					search: `%${search}%`,
					limit: MAX_SEARCH_ITEMS
				}
			})
		}
	}, [searchDistrictName])

	useEffect(() => {
		// on select district or search school name
		if (selectedDistrict?.id && !getSchoolsLoading) {
			getSchools({
				variables: {
					districtId: selectedDistrict?.id,
					search: buildSearchText(searchSchoolName, ['name', 'address_line_1', 'address_line_2']),
					limit: MAX_SEARCH_ITEMS
				}
			})
		}
	}, [searchSchoolName, selectedDistrict?.id])

	useEffect(() => {
		// on search by school name
		if (!getSchoolsLoading) {
			setSchools(
				getSchoolsData?.school.map((i) => ({
					id: i.school_id,
					name: i.name,
					description: i.address_line_1 || ''
				})) || []
			)
		}
	}, [getSchoolsData])

	useEffect(() => {
		// set id for parent component
		if (selectedSchool?.id) {
			setSchoolId(selectedSchool?.id)
		}
	}, [selectedSchool])

	// clean states functions
	const cleanStates = (opts: { all?: boolean; districts?: boolean; schools?: boolean }) => {
		const { all = false, districts = false, schools = false } = opts

		const clearDistrictState = () => {
			setSearchDistrictName('')
			setSelectedDistrict(undefined)
		}

		const clearSchoolState = () => {
			setSchools([])
			setSearchSchoolName('')
			setSelectedSchool(undefined)
		}

		if (districts || all) clearDistrictState()
		if (schools || all) clearSchoolState()
	}

	const isNextButtonDisabled =
		!selectedSchool?.id || !selectedDistrict?.id || !searchSchoolName || !searchDistrictName || isLoading

	return (
		<>
			<BaseDialog
				open={schoolDialogOpen}
				paperProps={{ className: classes.dialogsPaper }}
				onClose={() => {
					cleanStates({ all: true })
					if (onClose) onClose()
				}}
				backdropStyles={{ opacity: '0 !important' }}
				dividers={false}
				header={<DialogHeader title="I’m a School" subtitle="Last couple of questions..." />}
				isActionsHidden={true}
				body={
					<Fragment>
						<Box>
							<Typography className={classes.label}>
								<b>What’s the name of your district?</b>
							</Typography>
							<SignUpAutocomplete
								placeholder="Start typing to see a list"
								items={
									getDistrictsData?.district?.map((i) => ({
										id: i.district_id,
										name: displayDistrict(i),
										schoolsCount: i.schools_aggregate.aggregate?.count || 0
									})) || []
								}
								searchValue={searchDistrictName}
								setSearchValue={setSearchDistrictName}
								selectedValue={selectedDistrict}
								setSelectedValue={(district) => {
									setSelectedDistrict(district)

									cleanStates({ schools: true })
								}}
							/>
						</Box>
						<Box>
							<Typography className={classes.label}>
								<b>What’s the name of your school?</b>
							</Typography>
							<SignUpAutocomplete
								placeholder={
									!selectedDistrict?.id
										? 'Please select a district first'
										: !selectedDistrict.schoolsCount
										? 'No schools on this district'
										: schoolsInSelectedDistrict > MAX_SEARCH_ITEMS
										? `Start typing to see more... (${schoolsInSelectedDistrict} schools)`
										: 'Select one school'
								}
								disabled={!selectedDistrict}
								items={schools}
								searchValue={searchSchoolName}
								setSearchValue={setSearchSchoolName}
								selectedValue={selectedSchool}
								setSelectedValue={setSelectedSchool}
								totalAmount={schoolsInSelectedDistrict}
							/>
						</Box>

						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="space-around">
							<BigBaseButton
								data-cy={getCyKey(SchoolDialog, 'btnGetStartedSchool')}
								color="secondary"
								variant="contained"
								disabled={isNextButtonDisabled}
								className={classes.getStartedButton}
								onClick={() => {
									if (onConfirm) onConfirm()
								}}
								isLoading={isLoading}>
								<b>Get Started! </b>
							</BigBaseButton>
							<span
								data-cy={getCyKey(SchoolDialog, 'btnGoBack')}
								className={classes.goBackButton}
								onClick={() => {
									cleanStates({ all: true })
									if (onDiscard) onDiscard()
								}}>
								Go Back
							</span>
						</Box>
						<Box className={classes.schoolContact}>
							<Box>
								<Typography variant="caption" className={classes.checkboxText}>
									Don't see your school?
								</Typography>

								<ContactButton font={14} marginLeft={5} />
							</Box>
						</Box>
					</Fragment>
				}
			/>

			<ContactDialog
				open={contactDialogOpen}
				onClose={closeContactDialog}
				onDiscard={closeContactDialog}
				closeDialog={closeContactDialog}
			/>
		</>
	)
}
