import { useState, useEffect } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Box, Typography, CircularProgress } from '@material-ui/core'
import ClassicEditor from 'capicua-wurrly-refactor-ckeditor5'
import { useFormik } from 'formik'
import { InputHeader } from 'src/components'
import { InputField } from 'src/components/Inputs/InputField/InputField'
import { useStepTwoValidation } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useStepTwoValidation'
import { LessonFormInitialDataType } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonEdit/useInitialLessonData'
import { defaulStepTwoArticleTitles, TitleLimit, limit } from 'src/scenes/Teacher/scenes/6-Lessons/utils/constants'
import { Loader, UploadAdapterCKEditor } from 'src/utils/uploadAdapterCKEditor'

import { LessonFormDataType, StepFormType } from '.'
import { mediaEmbed } from '../../utils/mediaEmbed'
import { useStyles } from './styles'
import { CustomTooltip } from './Tooltips'

export const StepTwoForm = ({ saveData, lessonData, handleInitialData }: StepFormType) => {
	const [isLoading, setIsLoading] = useState(true)

	const { stepTwoSchema } = useStepTwoValidation()

	const classes = useStyles()
	const formik = useFormik({
		initialValues: {
			stepTwoArticles: defaulStepTwoArticleTitles.map((article) => ({
				...article,
				content: '',
				articleLen: 0
			}))
		},
		validationSchema: stepTwoSchema,
		validateOnMount: true,
		onSubmit: () => {}
	})
	const getClassName = (value: string, limit: number) => {
		if (value === '') return 'Default'
		if (value.length <= limit) return 'Success'
		else return 'Error'
	}

	const getClassNameWrapper = (val: string, limit: number) => {
		return classes[`text${getClassName(val, limit)}` as keyof typeof classes]
	}

	useEffect(() => {
		let dataSource: LessonFormDataType | LessonFormInitialDataType

		if (handleInitialData?.initialData && !handleInitialData.stepHasFilledInitialData) {
			dataSource = handleInitialData.initialData
			handleInitialData.setInitialDataUsed()
		} else dataSource = lessonData

		if ('stepTwoArticles' in dataSource && dataSource?.stepTwoArticles?.length) {
			formik.setValues({
				stepTwoArticles: dataSource?.stepTwoArticles?.map((article, index) => {
					return {
						...defaulStepTwoArticleTitles[index],
						title: article.title || '',
						content: article.content || '',
						articleLen: article.content?.length || 0
					}
				})
			})
			setIsLoading(false)

			return
		}

		// if is the old version, we proceed to format it and continue
		const { inspire, practice, record, reflect } = dataSource

		formik.setValues({
			stepTwoArticles: [
				{ ...defaulStepTwoArticleTitles[0], content: inspire || '', articleLen: inspire?.length || 0 },
				{ ...defaulStepTwoArticleTitles[1], content: practice || '', articleLen: practice?.length || 0 },
				{ ...defaulStepTwoArticleTitles[2], content: record || '', articleLen: record?.length || 0 },
				{ ...defaulStepTwoArticleTitles[3], content: reflect || '', articleLen: reflect?.length || 0 }
			]
		})

		setIsLoading(false)
	}, [])

	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	if (isLoading) return <CircularProgress />

	return (
		<Box marginTop="80px">
			<form onSubmit={formik.handleSubmit}>
				{formik.values.stepTwoArticles.map((article, index) => {
					return (
						<Box marginTop="40px" key={`title-${index}`} display={'flex'} flexDirection={'column'}>
							<Box display="flex" flexDirection={'column'} justifyContent="space-between" mb={2}>
								<InputHeader
									name={`Title *`}
									endAdornment={
										<Typography className={getClassNameWrapper(article.title, TitleLimit)}>
											{article.title.length}/{TitleLimit}
										</Typography>
									}
								/>
								<InputField
									id={`Title-${index}`}
									name={`Title-${index}`}
									value={article.title}
									onChange={(e) => {
										if ('value' in e.target) {
											formik.setFieldValue(`stepTwoArticles[${index}].title`, e.target.value)
										}
									}}
									onFocus={() => formik.setFieldTouched(`stepTwoArticles[${index}].title`)}
									error={
										Boolean(
											(formik.errors.stepTwoArticles?.[index] as { title: string })?.title
										) && formik.touched?.stepTwoArticles?.[index]?.title
									}
									helperText={
										formik.touched.stepTwoArticles?.[index]?.title
											? (formik.errors.stepTwoArticles?.[index] as { title: string })?.title
											: undefined
									}
									placeholder="Enter Title"
									fullWidth
								/>
							</Box>
							<Box
								display="flex"
								alignItems="center"
								style={{
									marginLeft: 'auto'
								}}>
								<Typography
									className={getClassNameWrapper(article.content, limit)}
									color="textSecondary">
									{`${article.articleLen}/${limit}`}
								</Typography>
								<CustomTooltip content={article.tooltip} />
							</Box>

							<CKEditor
								editor={ClassicEditor}
								data={article.content}
								id="inspire-editor"
								onReady={(editor) => {
									editor.plugins.get('FileRepository').createUploadAdapter = (
										loader: Loader
									) => {
										return new UploadAdapterCKEditor(loader)
									}
								}}
								config={{
									mediaEmbed,
									placeholder: article.placeholder,
									wordCount: {
										container: window.document.createElement('div'),
										onUpdate: (stats: { [key: string]: string }) => {
											formik.setFieldValue(
												`stepTwoArticles[${index}].articleLen`,
												stats.words
											)
										}
									}
								}}
								onChange={(_: unknown, editor: { getData: () => string }) => {
									const data = editor.getData()
									formik.setFieldValue(`stepTwoArticles[${index}].content`, `${data}`)
								}}
							/>
						</Box>
					)
				})}
			</form>
		</Box>
	)
}
