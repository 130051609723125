import { Grid } from '@material-ui/core'
import { ImageButton } from 'src/components'
import { Pages } from 'src/routes/teacherPages'
import { DEFAULT_CLASS_STUDENT_IMG, CLASSROOM_IMG } from 'src/utils'

import { CardTitle } from '../../../../../../components/CardTitle'
import { IconButton } from '../IconButton'

const elementPosition = { right: 16, bottom: 16 }

export const EmptyStateCards = () => {
	const TilesContent = {
		setUpClass: {
			backgroundImageUrl: CLASSROOM_IMG,
			linkTo: Pages.ClassCreation.path,
			title: <CardTitle text="Set up your Class" />,
			element: <IconButton text="Create a Class" icon="add" color="secondary" />,
			elementPosition
		},
		addStudents: {
			backgroundImageUrl: DEFAULT_CLASS_STUDENT_IMG,
			linkTo: Pages.Students.path,
			title: <CardTitle text="Add Your Students" />,
			element: <IconButton text="Add Students" icon="add" color="secondary" />,
			elementPosition
		}
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={6}>
				<ImageButton titlePosition="initial" {...TilesContent.setUpClass} />
			</Grid>
			<Grid item xs={6}>
				<ImageButton titlePosition="initial" {...TilesContent.addStudents} />
			</Grid>
		</Grid>
	)
}
