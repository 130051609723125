import { Box, CircularProgress, styled } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { ClassList } from 'src/components/ClassList'
import { Pages } from 'src/routes/teacherPages'

import { ClassTitle } from './components/ClassTitle'
import { CreateClassCard } from './components/CreateClassCard'
import { ClassesProvider, useClassesContext } from './useClasses'

export const Classes = (props: PagesProps) => {
	return (
		<Box>
			<ClassesProvider>
				<ClassTitle {...props} />
				<CreateClassCard />
				<TeacherClassList />
			</ClassesProvider>
		</Box>
	)
}

const TeacherClassList = () => {
	const { loading, classes } = useClassesContext()

	return (
		<Box>
			{/* //TODO: Use the class status selector again when the preffered approach is defined
				// `selectedClassState` and `setSelectedClassState` are exported from `useClassContext()`
				<SelectClassState value={selectedClassState} setValue={setSelectedClassState} />
			*/}
			<ClassList classes={classes} pages={Pages} />
			{loading && <Loading />}
		</Box>
	)
}

const Loading = () => (
	<CenteredBox>
		<CircularProgress color="secondary" />
	</CenteredBox>
)

const CenteredBox = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '2em'
})
