import React, { useMemo } from 'react'

import { Avatar, Box, Button, CircularProgress, Typography, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import { NoteUploadIcon } from 'src/assets/icons'
import { BaseAlert } from 'src/components/BaseAlert'
import { AssetReduced } from 'src/components/InputMultipleFile'
import { FileEnum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'

import useStyles from './InputFile.styles'

export enum UploadFileStatus {
	NO_FILE = 0,
	UPLOADING = 1,
	COMPLETE = 2
}
export type UploadFileProps = {
	advise: React.ReactNode
	type: Exclude<FileEnum, FileEnum.Lessons | FileEnum.Resources>
	placeholder: string
	initialFile?: AssetReduced
	status: UploadFileStatus
	handleDelete?: () => void
	hasUsedInitialData?: boolean
	file: File | undefined
	handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
	asset?: AssetReduced
	progress: number
	handleCancelUpload: () => void
	clearError: () => void
	error?: string
	id?: string
}
export const InputFile = ({
	advise,
	type,
	placeholder,
	handleDelete,
	status,
	hasUsedInitialData,
	file,
	handleUpload,
	asset,
	progress,
	handleCancelUpload,
	clearError,
	id,
	error
}: UploadFileProps) => {
	const styles = useStyles()

	const getFileType = () => {
		const fileTypeObject = {
			[FileEnum.Tips]: '',
			[FileEnum.Images]: 'image/*',
			[FileEnum.Songs]: 'audio/*',
			[FileEnum.Assets]: 'image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,audio/*',
			[FileEnum.Wurrlies]: 'video/*'
		}

		return fileTypeObject[type]
	}
	const fileImage =
		!hasUsedInitialData && !!file
			? URL.createObjectURL(file as unknown as Blob)
			: buildImagePath(asset?.resource_path)

	const PreviewIcon = useMemo(() => {
		if (status === UploadFileStatus.UPLOADING) {
			return <CircularProgress color="secondary" size={30} />
		} else {
			// Icons with <></> are empty because they are not needed.
			const previewIconObject = {
				[FileEnum.Tips]: <></>,
				[FileEnum.Wurrlies]: <NoteUploadIcon />,
				[FileEnum.Images]: <Avatar variant="rounded" src={fileImage} />,
				[FileEnum.Songs]: <NoteUploadIcon />,
				[FileEnum.Assets]: <></>
			}

			return previewIconObject[type]
		}
	}, [asset, file, status, hasUsedInitialData])

	return (
		<Box>
			{status === UploadFileStatus.NO_FILE ? (
				<>
					<input
						accept={getFileType()}
						className={styles.fileInput}
						id={id || 'contained-button-file'}
						type="file"
						onChange={handleUpload}
					/>
					<label htmlFor="contained-button-file">
						<Box component="form" className={styles.root} style={{ cursor: 'pointer' }}>
							<Typography className={styles.text}>{placeholder}</Typography>
							<AddIcon color="secondary" />
						</Box>
					</label>
				</>
			) : (
				<Box component="form" className={styles.root}>
					<Box
						className={status === UploadFileStatus.UPLOADING ? styles.text : styles.inputComplete}
						display="flex">
						<Box>{PreviewIcon}</Box>
						<Typography style={{ marginLeft: '10px' }}>
							{!file?.name && asset?.name ? asset?.name.substring(19) : file?.name}
						</Typography>
					</Box>
					{status === UploadFileStatus.UPLOADING && (
						<>
							<Alert className={styles.progressText} severity="error" icon={false}>
								<Typography variant="caption">
									<b>{progress}% UPLOADED</b>
								</Typography>
							</Alert>
							<Button onClick={handleCancelUpload} className={styles.cancelButton} variant="text">
								Cancel
							</Button>
						</>
					)}
					{status === UploadFileStatus.COMPLETE && (
						<>
							<Alert className={styles.completeText} severity="success" icon={false}>
								<Typography variant="caption">
									<b>COMPLETE</b>
								</Typography>
							</Alert>
							<IconButton onClick={handleDelete}>
								<DeleteIcon color="secondary" />
							</IconButton>
						</>
					)}
				</Box>
			)}
			<Box marginTop="10px">
				<BaseAlert severity="error" show={!!error} handleClose={clearError}>
					{error}
				</BaseAlert>
			</Box>
			<Box textAlign="end">
				<Typography variant="caption" className={styles.text}>
					{advise}
				</Typography>
			</Box>
		</Box>
	)
}
