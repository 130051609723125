import {
	stepThreeTooltipContent,
	stepTwoTooltipContent
} from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload/Tooltips'
import * as yup from 'yup'

export const limit = 200 as const

export const TitleLimit = 80

export const articleSchema = yup.object({
	title: yup.string().max(TitleLimit, `Must be at most ${TitleLimit} characters`).required('Title is required'),
	content: yup.string().max(limit, `Must be at most ${limit} characters`)
})

export const defaulStepTwoArticleTitles = [
	{
		title: 'Inspire',
		placeholder: 'What content or experience will you share to engage your students and start a conversation?',
		tooltip: stepTwoTooltipContent.inspire,
		order: 1
	},
	{
		title: 'Practice',
		placeholder: 'Share some activities that will allow students to practice the skills being developed.',
		tooltip: stepTwoTooltipContent.practice,
		order: 2
	},

	{
		title: 'Create',
		placeholder: 'What aspect of the project will the student(s) record, and how will they go about doing it?',
		tooltip: stepTwoTooltipContent.record,
		order: 3
	},

	{
		title: 'Reflect',
		placeholder:
			'What are some questions that will guide students reflections as they watch back their recordings?',
		tooltip: stepTwoTooltipContent.reflect,
		order: 4
	}
]

export const defaulStepThreeArticleTitles = [
	{
		title: 'Take It Further',
		placeholder:
			'If this lesson was to be repeated, what aspects could be changed, and how could it be altered to be more challenging?',
		tooltip: stepThreeTooltipContent.takeItFurther,
		order: 5
	},
	{
		title: 'Materials Needed',
		placeholder: 'What materials will the teacher and students need for this lesson?',
		tooltip: stepThreeTooltipContent.materialsNeeded,
		order: 6
	},

	{
		title: 'Differentiations',
		placeholder:
			'How could the class be offered under different circumstances, such as with differently abled students, variations for repeating the class, or teaching without access to a smart device?',
		tooltip: stepThreeTooltipContent.differentiation,
		order: 7
	},

	{
		title: 'Suggested Assignments',
		placeholder: 'Instructions to the student for an assignment that accompanies this lesson.',
		tooltip: stepThreeTooltipContent.suggestedAssignments,
		order: 8
	}
]
