import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'

import useStyles from './OptionButton.styles'

type OptionButtonProps = {
	children: React.ReactNode
	selected: boolean
	onChange: (value: string) => void
	value: string
}

export const OptionButton = ({ children, selected, onChange, value }: OptionButtonProps) => {
	const styles = useStyles()

	return (
		<div>
			<Button
				className={clsx(styles.button, { [styles.buttonSelected]: selected })}
				onClick={() => onChange(value)}>
				<CheckCircleIcon className="selectedCheckIcon" />
				{children}
			</Button>
		</div>
	)
}
