import { Integration_Name_Enum, Role_Name_Enum } from 'src/graphql/autogenerate/schemas'

export enum StudentSignInOptions {
	QRCode = 'QRCode',
	StudentCode = 'StudentCode',
	Email = 'Email'
}

export enum IntegrationButtonActions {
	Signup,
	Login
}

export type PrivateTeacher = {
	organizationName: string
	address: string
}

export type SignUpData = {
	email?: string
	password?: string
	role?: Role_Name_Enum
	schoolId?: number
	privateTeacherData?: PrivateTeacher
	token?: string
	nameFirst?: string
	nameLast?: string
	integrationId?: Integration_Name_Enum
	teacherId?: number
}
