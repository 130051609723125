import { createStyles, makeStyles } from '@material-ui/core'
import { alpha, Theme } from '@material-ui/core/styles'
import { COLORS } from 'src/styles/colors'

export const useClassCardDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			backgroundImage: ({ imagePath }: { imagePath: string }) => `url(${imagePath})`,
			backgroundColor: theme.palette.primary.main,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center 40%',
			height: '-webkit-fill-available',
			color: theme.palette.common.white,
			margin: 5
		},
		cardActionArea: {
			height: '-webkit-fill-available'
		},
		title: {
			position: 'absolute',
			bottom: 5,
			left: 5
		},
		maturity: {
			display: 'flex',
			position: 'absolute',
			top: 5,
			right: 8,
			color: COLORS.lightgrey,
			opacity: 0.7
		},
		maturityText: {
			marginRight: 3
		},
		selectIcon: {
			display: 'flex',
			position: 'absolute',
			top: 5,
			right: 8
		},
		selected: {
			borderColor: theme.palette.secondary.main,
			backgroundColor: alpha(theme.palette.secondary.main, 0.25)
		},
		saved: {
			backgroundColor: alpha(theme.palette.grey[700], 0.7)
		},
		nonSelected: {
			backgroundColor: alpha(theme.palette.primary.dark, 0.1)
		}
	})
)

export default useClassCardDialogStyles
