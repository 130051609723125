import { useEffect } from 'react'

import { Container } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { Page } from 'src/@types'
import { WurrlyLogoIcon } from 'src/assets/icons'
import { BaseDialog, FixedBackground } from 'src/components'
import { IndieWurrlyPage } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/components/IndieWurrlyPage'
import {
	ClassWurrlyProvider,
	useClassWurrlyContext
} from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'
import { useChooseBestTakeStyles } from 'src/scenes/Student/scenes/StudentStudio/scenes/StudentChooseBestTake/stylesChooseBestTake'

type LocationState = {
	selectedCard: number
	classId: number
}

export const EditCaption = ({ page }: { page: Page }) => {
	return (
		<ClassWurrlyProvider>
			<CaptionBody page={page} />
		</ClassWurrlyProvider>
	)
}

export const CaptionBody = ({ page }: { page: Page }) => {
	const styles = useChooseBestTakeStyles()
	const location = useLocation()
	const { setWurrlyId, setClassId } = useClassWurrlyContext()

	useEffect(() => {
		if (location.state) {
			const state = location.state as LocationState
			const selectedCard = state.selectedCard as number
			const classId = state.classId as number
			setWurrlyId(selectedCard)
			setClassId(classId)
		}
	}, [location])

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={styles.logoPosition} />}
			className={styles.backgroundImage}>
			<BaseDialog
				maxWidth="xl"
				open
				onClose={() => {}}
				bodyProps={{
					className: styles.dialogContent
				}}
				className={styles.baseDialog}
				body={
					<Container className={styles.centerContainer}>
						<IndieWurrlyPage {...page} practice={true} />
					</Container>
				}
			/>
		</FixedBackground>
	)
}
