import { createStyles, makeStyles } from '@material-ui/core'
import { InstrumentsGuideType } from 'src/@types'
import {
	LYRIC_MAIN_Y_POS_AUDIO,
	LYRIC_MAIN_Y_POS
} from 'src/components/Studio/Record/InstrumentNotesPanel/contants'

export const useNoteStyles = makeStyles(() =>
	createStyles({
		noteChord: ({ xPos }: { xPos: number }) => ({
			position: 'absolute',
			left: xPos,
			bottom: InstrumentsGuideType.audio ? LYRIC_MAIN_Y_POS_AUDIO - 20 : LYRIC_MAIN_Y_POS - 20
		}),
		onlyChords: ({ xPos }: { xPos: number }) => ({
			position: 'absolute',
			left: xPos,
			bottom: LYRIC_MAIN_Y_POS_AUDIO - 50
		})
	})
)

export default useNoteStyles
