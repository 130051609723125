import { Fragment, useState } from 'react'

import { Box, CircularProgress, FilledInput, InputAdornment, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { PromotionCodeInput } from 'src/components/Inputs/PromotionCodeInput/PromotionCodeInput'
import { usePriceDiscount } from 'src/hooks/usePriceDiscount'
import * as yup from 'yup'

import { useCreateSubscriptionMutation } from '../../graphql/autogenerate/hooks'
import { Membership_Type } from '../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../hooks/useLogin'
import { Pages } from '../../routes/teacherPages'
import { masterStyles } from '../../styles/master-styles.deprecated'
import { getCyKey, getPriceInDollars } from '../../utils'
import { CardInput } from '../Inputs/CardInput'
import { TextInput } from '../Inputs/TextInput'
import { BaseDialog, BaseDialogProps } from './BaseDialog/BaseDialog'
import { InfoDialog } from './InfoDialog'

type Props = {
	selectedPackage: Membership_Type | undefined
	noStudents?: boolean
	onSuccess: () => void
	close: () => void
	updateTeacherToEnterprise: () => void
}

const validationSchema = yup.object({
	cardHolder: yup.string().required('Name is required').ensure(),
	promotion_code: yup.string().ensure().nullable(),
	promotion_code_is_valid: yup.boolean().when('promotion_code', (promotion_code, schema) => {
		if (!promotion_code) return schema

		return schema.equals([true], 'Promotion code is invalid')
	})
})

export const PaymentDialog = ({
	selectedPackage,
	onSuccess,
	updateTeacherToEnterprise,
	close,
	noStudents,
	...ext
}: Props & BaseDialogProps) => {
	const stripe = useStripe()
	const elements = useElements()
	const { teacherData: teacher } = useLoginContext()

	const [cardComplete, setCardComplete] = useState(false)
	const [error, setError] = useState<string>('')
	const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)

	const history = useHistory()

	const [createSubscription] = useCreateSubscriptionMutation()
	const { handleDiscountAmount, handleDiscountPercentage, totalPrice } = usePriceDiscount(selectedPackage?.price)

	const formik = useFormik({
		initialValues: {
			cardHolder: '',
			promotion_code: '',
			promotion_code_is_valid: false
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async (variables, { resetForm }) => {
			try {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				const clientSecret = await getClientSecret()
				if (!stripe || !elements) return
				if (!cardComplete) return
				if (!clientSecret) return

				// reset error
				setError('')

				const cardElement = elements.getElement(CardNumberElement) as StripeCardNumberElement
				const payload = await stripe.confirmCardPayment(clientSecret, {
					payment_method: {
						card: cardElement,
						billing_details: {
							name: variables.cardHolder
						}
					}
				})

				if (payload.error) {
					setError(`Payment failed ${payload.error.message}`)
				} else {
					// reseting the form
					resetForm()
					close()
					setIsSuccessPopupOpen(true)
				}
				// resetForm()
			} catch (error) {
				console.error({ error })
				if (error instanceof Error) setError(error?.message)
			}
		}
	})
	const getClientSecret = async () => {
		try {
			if (selectedPackage?.membership_type_id) {
				// get client secret from subscription
				const res = await createSubscription({
					variables: {
						membershipId: selectedPackage?.membership_type_id,
						teacherId: teacher.teacher_id,
						promotionCode: formik.values.promotion_code
					}
				})
				const clientSecret = res.data?.createSubscription?.clientSecret
				if (!clientSecret) {
					return null
				}

				return clientSecret
			}
		} catch (error) {
			console.error({ error })
			if (error instanceof Error) setError(error?.message)

			return null
		}

		return null
	}

	return (
		<Fragment>
			<BaseDialog
				dividers={false}
				header={
					<Fragment>
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Confirm Your Payment Details
						</Typography>
						<Typography align="center" variant="body2" style={{ padding: 14, fontWeight: 'normal' }}>
							Please confirm your payment details in order to add Students. {'\n'}
							You’ll only be charged for Students that join successfully.
						</Typography>
					</Fragment>
				}
				confirmLabel={
					!formik.isValid || formik.isSubmitting ? (
						<CircularProgress variant="indeterminate" size={20} />
					) : (
						'Confirm'
					)
				}
				onConfirm={() => {
					formik.submitForm()
				}}
				onConfirmProps={{
					fullWidth: true
				}}
				isConfirmDisabled={!formik.isValid || formik.isSubmitting || !cardComplete}
				discardLabel="Go Back"
				onDiscardProps={{
					disabled: formik.isSubmitting,
					fullWidth: true
				}}
				body={
					<Box padding="0px 10px">
						<form style={masterStyles.formContainerNoPadding} onSubmit={formik.handleSubmit}>
							<FilledInput
								inputProps={{
									style: {
										padding: '18.5px'
									}
								}}
								fullWidth
								disabled
								value="Package"
								disableUnderline={true}
								// placeholder="Package"
								endAdornment={
									<InputAdornment position="end">
										${getPriceInDollars(totalPrice)} {selectedPackage?.billing_method}
									</InputAdornment>
								}
							/>
							<TextInput
								data-cy={getCyKey(PaymentDialog, 'cardholderName')}
								name="Cardholder Name"
								isRequired
								value={formik.values.cardHolder}
								onChange={(e) => formik.setFieldValue('cardHolder', e.target.value)}
								error={formik.touched.cardHolder && Boolean(formik.errors.cardHolder)}
								helperText={formik.touched.cardHolder && formik.errors.cardHolder}
								placeholder="Enter Name"
							/>
							<CardInput setCardComplete={setCardComplete} />
							<PromotionCodeInput
								onChangeDiscountAmount={handleDiscountAmount}
								onChangeDiscountPercentage={handleDiscountPercentage}
								onChangeValid={formik.setFieldValue}
								isValid={formik.values.promotion_code_is_valid}
								onFocus={() => formik.setFieldTouched('promotion_code', true)}
								onChange={formik.setFieldValue}
								value={formik.values.promotion_code}
								helperText={formik.values.promotion_code && formik.errors.promotion_code_is_valid}
								error={
									formik.touched.promotion_code && Boolean(formik.errors.promotion_code_is_valid)
								}
							/>
						</form>
						{!!error && <ErrorMessage error={error} />}
					</Box>
				}
				{...ext}
			/>

			<InfoDialog
				open={isSuccessPopupOpen}
				onClose={() => {
					// update teacher to enterprise
					updateTeacherToEnterprise()
					setIsSuccessPopupOpen(false)
				}}
				title={
					<Typography align="center" style={{ fontWeight: 'bold', fontSize: 32 }}>
						Package Purchased {'\n'}
						Successfully!
					</Typography>
				}
				body={
					noStudents
						? 'Thank you for your purchase'
						: 'You can now add Students. You can change your payment details from your Settings at any time.'
				}
				discardLabel={noStudents ? 'Back' : 'Back to Class'}
				onDiscard={() => {
					// update teacher to enterprise
					updateTeacherToEnterprise()
					setIsSuccessPopupOpen(false)
					history.push(Pages.Classes.path)
				}}
				confirmLabel={noStudents ? 'Ok' : 'Add Students'}
				confirmProps={{ color: 'secondary' }}
				onConfirm={() => {
					setIsSuccessPopupOpen(false)
					// update teacher to enterprise
					updateTeacherToEnterprise()
					onSuccess()
				}}
				icon={<Check />}
			/>
		</Fragment>
	)
}

const ErrorMessage = (props: { error: string }) => {
	return (
		<Box style={{ display: 'flex', flexDirection: 'row' }}>
			<CloseIcon color="error" fontSize="small" style={{ marginRight: 5 }} />
			<Typography color="error" style={{ fontSize: 14 }}>
				{props.error}
			</Typography>
		</Box>
	)
}
