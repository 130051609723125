import { useEffect, useState } from 'react'

import { Avatar, Box, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useFormik } from 'formik'
import { AssetReduced } from 'src/components/InputMultipleFile'
import { useStepFiveValidation } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useStepFiveValidation'

import { StepFormType } from '.'
import { BaseAlert, InputMultipleFile } from '../../../../../../components'
import { AddDialog } from '../../../../../../components/Dialogs/AddDialog'
import { useGetTipsByIdsQuery, useGetSongsByPkQuery } from '../../../../../../graphql/autogenerate/hooks'
import { Song, Tip } from '../../../../../../graphql/autogenerate/schemas'
import { FileTypeEnum, TypeEnum } from '../../../../../../utils'
import { buildImagePath } from '../../../../../../utils/utils'
import { useStyles } from './styles'

export const StepFiveForm = ({ saveData, lessonData, handleInitialData, onAssetDelete }: StepFormType) => {
	const classes = useStyles()

	const { stepFiveValidation, limit } = useStepFiveValidation()

	const formik = useFormik({
		initialValues: {
			songs: [] as Song[],
			videos: [] as Tip[],
			assets: [] as { resource_path: string; name: string; asset_id: number }[]
		},
		validationSchema: stepFiveValidation,
		validateOnMount: true,
		onSubmit: () => {}
	})

	const [addSongs, setAddSongs] = useState(false)
	const [addVideos, setAddVideos] = useState(false)
	const [error, setError] = useState<string>()
	const [usedInitialSongs, setUsedInitialSongs] = useState(false)
	const [usedInitialTips, setUsedInitialTips] = useState(false)

	const handleClose = () => {
		setError(undefined)
	}

	const onAddSongs = (selectedSongs: (Song | Tip)[]) => {
		formik.setFieldValue('songs', selectedSongs)
	}

	const onAddVideos = (selectedVideos: (Song | Tip)[]) => {
		formik.setFieldValue('videos', selectedVideos)
	}

	const initialTips = useGetTipsByIdsQuery({
		variables: {
			tipIds: handleInitialData?.initialData?.videos ?? []
		}
	})

	const initialSongs = useGetSongsByPkQuery({
		variables: {
			songIds: handleInitialData?.initialData?.songs ?? []
		}
	})

	useEffect(() => {
		const { songs, videos } = lessonData
		formik.setValues({
			songs: songs ?? [],
			videos: videos ?? [],
			assets: []
		})
	}, [])

	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	useEffect(() => {
		if (initialTips.data?.tip.length && !handleInitialData?.stepHasFilledInitialData) {
			formik.setFieldValue('videos', initialTips.data.tip as Tip[])
			setUsedInitialTips(true)
		}
	}, [initialTips.data])

	useEffect(() => {
		if (initialSongs.data?.song.length && !handleInitialData?.stepHasFilledInitialData) {
			formik.setFieldValue('songs', initialSongs.data.song as Song[])
			setUsedInitialSongs(true)
		}
	}, [initialSongs.data])

	useEffect(() => {
		if (usedInitialTips && usedInitialSongs && handleInitialData) {
			handleInitialData.setInitialDataUsed()
		}
	}, [usedInitialTips, usedInitialSongs])

	return (
		<Box marginTop="80px">
			<form onSubmit={formik.handleSubmit}>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Add Songs</Typography>
						<Typography className={classes.textDefault} color="textSecondary">
							Up to {limit.songs}
						</Typography>
					</Box>
					{formik.values.songs.length < 1 ? (
						<Box
							className={classes.pickInput}
							onClick={() => {
								setAddSongs(true)
							}}>
							<Typography className={classes.labelImage}>Pick Songs</Typography>
							<AddIcon color="secondary" />
						</Box>
					) : (
						formik.values.songs.map((song, index) => {
							if (index === 0) {
								return (
									<Box
										className={classes.pickInputMultiple}
										onClick={() => {
											setAddSongs(true)
										}}>
										<Box display="flex" className={classes.labelImage}>
											<Avatar variant="rounded" src={buildImagePath(song.image_path)} />
											<Typography style={{ marginLeft: '10px' }} color="textPrimary">
												{song.title}
											</Typography>
										</Box>
										<AddIcon color="secondary" />
									</Box>
								)
							} else {
								return (
									<Box className={classes.pickInputMultiple}>
										<Box display="flex" className={classes.labelImage}>
											<Avatar variant="rounded" src={buildImagePath(song.image_path)} />
											<Typography style={{ marginLeft: '10px' }} color="textPrimary">
												{song.title}
											</Typography>
										</Box>
									</Box>
								)
							}
						})
					)}
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Add Videos</Typography>
						<Typography className={classes.textDefault} color="textSecondary">
							Up to {limit.videos}
						</Typography>
					</Box>
					{formik.values.videos.length < 1 ? (
						<Box
							className={classes.pickInput}
							onClick={() => {
								setAddVideos(true)
							}}>
							<Typography className={classes.labelImage}>Pick Videos</Typography>
							<AddIcon color="secondary" />
						</Box>
					) : (
						formik.values.videos.map((video, index) => {
							if (index === 0) {
								return (
									<Box
										className={classes.pickInputMultiple}
										onClick={() => {
											setAddVideos(true)
										}}>
										<Box display="flex" className={classes.labelImage}>
											<Avatar variant="rounded" src={buildImagePath(video.image_path)} />
											<Typography style={{ marginLeft: '10px' }} color="textPrimary">
												{video.title}
											</Typography>
										</Box>
										<AddIcon color="secondary" />
									</Box>
								)
							} else {
								return (
									<Box className={classes.pickInputMultiple}>
										<Box display="flex" className={classes.labelImage}>
											<Avatar variant="rounded" src={buildImagePath(video.image_path)} />
											<Typography style={{ marginLeft: '10px' }} color="textPrimary">
												{video.title}
											</Typography>
										</Box>
									</Box>
								)
							}
						})
					)}
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Add Assets</Typography>
					</Box>
					<Box style={{ cursor: 'pointer' }}>
						<InputMultipleFile
							handleAsset={(assetData) => {
								formik.setFieldValue(
									'assets',
									assetData.filter(
										(i) =>
											!handleInitialData?.initialData?.assets?.find(
												(x: AssetReduced) => x.asset_id === i.asset_id
											)
									)
								)
							}}
							placeholder="Pick Files"
							type={FileTypeEnum.Asset}
							advise={'File Type: PDF, JPEG, PNG. Max File size: 10MB.'}
							initialAssets={handleInitialData?.initialData?.assets}
							onAssetDelete={onAssetDelete}
						/>
					</Box>
					<Box>
						<Box marginTop="10px">
							<BaseAlert severity="error" show={!!error} handleClose={handleClose}>
								{error}
							</BaseAlert>
						</Box>
						<Box textAlign="end">
							<Typography variant="caption" className={classes.labelImage}></Typography>
						</Box>
					</Box>
				</Box>
			</form>
			{addVideos && (
				<AddDialog
					limit={limit.videos}
					itemsSelected={formik.values.videos}
					onSave={onAddVideos}
					open={addVideos}
					setOpen={setAddVideos}
					type={TypeEnum.Video}
					initialSelectedIds={handleInitialData?.initialData?.videos}
				/>
			)}
			{addSongs && (
				<AddDialog
					limit={limit.songs}
					itemsSelected={formik.values.songs}
					onSave={onAddSongs}
					open={addSongs}
					setOpen={setAddSongs}
					type={TypeEnum.Song}
					initialSelectedIds={handleInitialData?.initialData?.songs}
				/>
			)}
		</Box>
	)
}
