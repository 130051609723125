import { createStyles, makeStyles } from '@material-ui/core'

export const useResizableElementStyles = makeStyles(() =>
	createStyles({
		deleteIcon: {
			position: 'absolute',
			background: '#4af',
			padding: '2px 4px',
			color: 'white',
			fontSize: '13px',
			whiteSpace: 'nowrap',
			fontWeight: 'bold',
			willChange: 'transform',
			transform: 'translate(-50%, 0px)',
			backgroundColor: 'rgba(0,0,0,0.5)',
			borderRadius: '100%',
			border: '1px solid white',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around'
		},
		hiddenDeleteIcon: {
			display: 'none'
		},
		container: {
			position: 'absolute',
			zIndex: 1,
			cursor: 'move',
			'& .moveable-origin': {
				display: 'none'
			}
		},
		draggableItem: {
			width: 100,
			height: 100,
			'& img': {
				userSelect: 'none',
				mozUserSelect: 'none',
				webkitUserSelect: 'none',
				width: '100%',
				height: '100%'
			}
		},
		hideControls: {
			'& .moveable-control': {
				display: 'none'
			},
			'& .moveable-direction': {
				display: 'none'
			},
			'& .moveable-n': {
				display: 'none'
			},
			'& .moveable-nw': {
				display: 'none'
			},
			'& .moveable-ne': {
				display: 'none'
			},
			'& .moveable-e': {
				display: 'none'
			},
			'& .moveable-w': {
				display: 'none'
			},
			'& .moveable-s': {
				display: 'none'
			},
			'& .moveable-sw': {
				display: 'none'
			},
			'& .moveable-se': {
				display: 'none'
			},
			'& .moveable-line': {
				dsplay: 'none'
			},
			'& .moveable-rotation': {
				display: 'none'
			}
		}
	})
)

export default useResizableElementStyles
