import { useHistory } from 'react-router-dom'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { PlaylistCard, PlaylistCardProps } from './PlaylistCard'

export const PlaylistCardContainer = ({ playlist, ...props }: PlaylistCardProps) => {
	const history = useHistory()
	const goToPlaylist = () =>
		history.push(buildRouteParameters(Pages.PlaylistDetails, { playlistId: playlist.playlist_id }))

	return (
		<>
			<PlaylistCard playlist={playlist} deleteTooltip="Delete Playlist" {...props} onView={goToPlaylist} />
		</>
	)
}
