import React, { useEffect, useState } from 'react'

import { AddToClassDialog } from '../../../../../components'
import { useInsertStudentsMutation } from '../../../../../graphql/autogenerate/hooks'
import { Class, Student } from '../../../../../graphql/autogenerate/schemas'
import { useStudentsGridContext } from './StudentsGrid/useStudentsGrid'

type Props = {
	setAddToClassStudent: React.Dispatch<React.SetStateAction<Student | undefined>>
	addToClassStudent: Student | undefined
	refetchStudent?: () => void
}

export const AddStudentToClass = ({ addToClassStudent, setAddToClassStudent, refetchStudent }: Props) => {
	const { refetch } = useStudentsGridContext()
	const [studentId, setStudentId] = useState<number | undefined>()
	const [studentClasses, setStudentClasses] = useState<Class[]>([])
	const [insertStudents] = useInsertStudentsMutation()
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		if (addToClassStudent) {
			setStudentId(addToClassStudent.student_id)
			setStudentClasses(addToClassStudent?.class_students.map((item) => item.class) || [])
			setAddToClassStudent(undefined)
		}
	}, [addToClassStudent])

	useEffect(() => {
		if (!studentId) {
			setStudentClasses([])
		}
	}, [studentId])

	return (
		<AddToClassDialog
			isOpen={!!studentId}
			searchText={searchText}
			setIsOpen={setStudentId}
			title="Add Student to Class"
			setSearchText={setSearchText}
			description="Your Student will be able to access all content that you’ve added to the Class. They’ll also be able to work on any Assignments that you set for them within the Class."
			itemName="Student"
			itemClasses={studentClasses}
			onConfirm={async (selectedClasses) => {
				const array = [...selectedClasses]
				const classes = array.map((item) => {
					return {
						student_id: studentId,
						class_id: item.class_id
					}
				})
				if (classes && classes.length > 0) {
					await insertStudents({
						variables: {
							students: classes
						},
						update: (cache, data) => {
							const classesToUpdateInCache = data.data?.insert_class_student?.returning
							if (!classesToUpdateInCache) return

							let identify
							classesToUpdateInCache.forEach((cls) => {
								identify = cache.identify(cls.class)

								cache.evict({
									id: `student:${studentId}`,
									fieldName: 'class_students'
								})

								cache.evict({
									id: identify,
									fieldName: 'class_students'
								})
								cache.evict({
									id: identify,
									fieldName: 'class_students_aggregate'
								})
							})
							if (refetchStudent) refetchStudent()
							else refetch()
						}
					})
				}
			}}
		/>
	)
}
