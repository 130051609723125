import { Box, Typography, useTheme } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

import { ImageButton, SmallBaseButton } from '../../../../../components'
import { NoItems } from '../../../../../components/No/NoItems'
import { useLoginContext } from '../../../../../hooks/useLogin'
import { DEFAULT_CLASS_STUDENT_IMG } from '../../../../../utils'
import { useStudentsContext } from '../useStudents'

type EmptyStudentListProp = {
	title: string
	description: string
}
export const EmptyStudentList = ({ title, description }: EmptyStudentListProp) => {
	const theme = useTheme()
	const { teacherData: teacher, teacherTemporarilyEnterprise } = useLoginContext()
	const { setIsSelectClassOpen, setIsSelectPackageOpen } = useStudentsContext()

	const handleOnClickImage = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.preventDefault()
		if (
			teacher.is_enterprise ||
			teacher.is_school_enterprise ||
			teacher.is_district_enterprise ||
			teacherTemporarilyEnterprise
		)
			setIsSelectClassOpen(true)
		else setIsSelectPackageOpen(true)
	}

	return (
		<Box>
			<NoItems title={title} description={description} />

			<ImageButton
				backgroundImageUrl={DEFAULT_CLASS_STUDENT_IMG}
				title={
					<Typography
						style={{
							color: theme.palette.background.default
						}}
						variant="h6">
						<b>Add Your Students </b>
					</Typography>
				}
				titlePosition="initial"
				element={
					<SmallBaseButton color="secondary">
						<AddCircle height={15} width={15} />
						<Typography
							variant="subtitle2"
							style={{
								marginLeft: '5px',
								marginRight: '5px'
							}}>
							Add Students
						</Typography>
					</SmallBaseButton>
				}
				elementPosition={{
					right: 20,
					bottom: 20
				}}
				linkTo={{}}
				onClick={handleOnClickImage}
				titleHeight={{
					xs: 175,
					sm: 232
				}}
			/>
		</Box>
	)
}
