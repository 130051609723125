import React, { useState } from 'react'

import { Box, Button, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SyncIcon from '@material-ui/icons/Sync'
import { useHistory } from 'react-router-dom'
import { SyncClassDialog } from 'src/scenes/Teacher/scenes/2-Classes/components/SyncClassDialog'

import { IconActionCard, IconActionOptionType } from '../../../../../components'
import { Pages } from '../../../../../routes/teacherPages'
import { getCyKey } from '../../../../../utils'
import { useClassesContext } from '../useClasses'

export const CreateClassCard = () => {
	const history = useHistory()
	const { loading, classes } = useClassesContext()
	const [syncClassDialogOpen, setSyncClassDialogOpen] = useState(false)

	const handleAddSyncClass = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// if the opt has a second link, we prevent the default action
		setSyncClassDialogOpen(true)
		event.stopPropagation()
	}

	const goToCreateClass = () => {
		history.push(Pages.ClassCreation.path)
	}

	const createClassButton: IconActionOptionType = {
		id: 'Create',
		title: 'Create Class',
		icon: <AddIcon fontSize="large" color="secondary" />,
		secondLink: (
			<Button data-cy={getCyKey(CreateClassCard, 'btnCreateClass4')} onClick={(e) => handleAddSyncClass(e)}>
				<Typography
					color="secondary"
					style={{
						display: 'flex',
						alignItems: 'center'
					}}>
					<SyncIcon />
					Or sync a class from an app that you already use
				</Typography>
			</Button>
		)
	}

	return !loading && classes && classes.length === 0 ? (
		<Box>
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<IconActionCard option={createClassButton} handleClick={goToCreateClass} cardHeight={160} />
				</Grid>
			</Grid>
			<SyncClassDialog open={syncClassDialogOpen} setOpen={setSyncClassDialogOpen} />
		</Box>
	) : (
		<Box />
	)
}
