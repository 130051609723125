import { gql } from '@apollo/client'

export const songFragment = gql`
	fragment songFragment on song {
		song_id
		title
		image_path
		lyrics
		artist {
			name
		}
		scale {
			name
		}
		tracks {
			track_type {
				name
			}
			resource_path
			midis {
				chord_map_object
				chords_Array
				chords_Array_Zero
			}
		}
		class_songs {
			class {
				class_id
				title
			}
		}
		song_catalog_item {
			catalog_item {
				name
				catalog_item_id
				catalog_id
				catalog {
					catalog_id
					name
				}
			}
		}
	}
`
export const songFragmentNoTracks = gql`
	fragment songFragmentNoTracks on song {
		song_id
		title
		image_path
		lyrics
		artist {
			name
		}
		scale {
			name
		}
		class_songs {
			class {
				class_id
				title
			}
		}
		song_catalog_item {
			catalog_item {
				name
				catalog_item_id
				catalog_id
				catalog {
					catalog_id
					name
				}
			}
		}
	}
`

export const songReducedFragment = gql`
	fragment songReducedFragment on song {
		song_id
		title
		image_path
		artist {
			name
		}
		scale {
			name
		}
		class_songs {
			class {
				class_id
				title
			}
		}
		song_catalog_item {
			catalog_item {
				catalog_item_id
				name
				catalog_id
				catalog {
					catalog_id
					name
				}
			}
		}
	}
`

export const lessonFragment = gql`
	fragment lessonFragment on lesson_plan {
		lesson_plan_id
		title
		description
		image_path
		inspire
		practice
		process
		record
		reflect
		take_it_further
		suggested_assignments
		differentiations
		materials_needed
		is_purchased_by_teacher
		lockable_content {
			visibility
		}
		lesson_plan_catalog_item {
			catalog_item {
				catalog {
					name
				}
				catalog_id
				name
			}
		}
		lesson_plan_song(where: { song: { song_id: { _is_null: false } } }) {
			song {
				title
				image_path
				artist {
					name
				}
			}
		}
		lesson_plan_asset {
			asset {
				name
				resource_path
			}
		}
		lesson_plan_tip(where: { tip: { tip_id: { _is_null: false } } }) {
			tip {
				title
				image_path
			}
		}
		lesson_plan_keyword {
			keyword {
				name
				keyword_id
			}
		}
		lesson_plan_grade {
			grade {
				name
				grade_id
			}
		}
		lesson_plan_musical_skill {
			musical_skill {
				name
				musical_skill_id
			}
		}
		lesson_plan_non_musical_skill {
			non_musical_skill {
				name
				non_musical_skill_id
			}
		}
		module_lesson_plans_aggregate {
			aggregate {
				count
			}
		}
	}
`

export const tipFragment = gql`
	fragment tipFragment on tip {
		tip_id
		title
		description
		resource_path
		image_path
		featured
		lockable_content {
			visibility
		}
		tip_catalog_item {
			tip_catalog_item_id
			catalog_item {
				catalog_item_id
				catalog_id
				name
				catalog {
					catalog_id
					name
				}
			}
		}
		artist {
			name
		}
	}
`
