import { gql } from '@apollo/client'
import { Lesson_Plan, Song, Tip } from 'src/graphql/autogenerate/schemas'
import { TypeEnum } from 'src/utils'

import { lessonFragment, songFragment, songFragmentNoTracks, tipFragment } from '../../scenes/Teacher/fragments'

export const querySearchSong = (type: TypeEnum, isTeacher = false) => {
	switch (type) {
		case TypeEnum.Song:
			return gql`
				query fetchSongs(
					$teacherId: Int
					$studentId: Int
					$conditions: [song_bool_exp!] = [{ title: { _ilike: "%%" } }]
					$offset: Int
					$limit: Int
					$order: order_by = asc
					$excludeMaturityLevel: [maturity_level_enum!] = []
				) {
					song(
						where: {
							_or: $conditions
							tracks: { midis: { maturity_level: { _nin: $excludeMaturityLevel } } }
							song_id: { _is_null: false}
							active: { _eq: true }

						}

						offset: $offset
						limit: $limit
						order_by: { title: $order }
					) {
						song_maturity_level
						${
							isTeacher
								? `is_favorite(args: { teacherid: $teacherId })`
								: `is_student_favorite(args: {studentid: $studentId})`
						}
						tracks(where: { midis: { maturity_level: { _nin: $excludeMaturityLevel } } }) {
							track_type {
								name
							}
							resource_path
							midis {
								chord_map_object
								chords_Array
								chords_Array_Zero
							}
						}
						...songFragmentNoTracks
					}
				}
				${songFragmentNoTracks}
			`
		case TypeEnum.Video:
			return gql`
				query getVideos(
					$conditions: [tip_bool_exp!] = [{ title: { _ilike: "%%" } }]
					$offset: Int
					$limit: Int
					$order: order_by = asc
				) {
					tip(
						where: { _or: $conditions, tip_id: { _is_null: false }, active: { _eq: true } }
						offset: $offset
						limit: $limit
						order_by: { title: $order }
					) {
						...tipFragment
					}
				}
				${tipFragment}
			`
		case TypeEnum.Lesson:
			return gql`
				query getLessons(
					$conditions: [lesson_plan_bool_exp!] = [{ title: { _ilike: "%%" } }]
					$offset: Int
					$limit: Int
					$order: order_by = asc
					$teacherId: Int
				) {
					lesson_plan(
						where: { _or: $conditions, lesson_plan_id: { _is_null: false }, active: { _eq: true } }
						offset: $offset
						limit: $limit
						order_by: { title: $order }
					) {
						is_favorite(args: { teacherid: $teacherId })
						class_lesson_plans {
							class_lesson_plan_id
							class {
								class_id
								title
							}
						}
						module_lesson_plans_aggregate {
							aggregate {
								count
							}
						}
						...lessonFragment
					}
				}
				${lessonFragment}
			`
		default:
			return gql`
				query {
					song(where: { song_id: { _is_null: false } }) {
						song_id
					}
				}
			`
	}
}
export const QUERY_SEARCH_SONGS = gql`
	query fetchSongs($search: String = "%%", $offset: Int, $limit: Int, $order: order_by = asc) {
		song(
			where: {
				_or: [{ title: { _ilike: $search } }, { artist: { name: { _ilike: $search } } }]
				song_id: { _is_null: false }
			}
			offset: $offset
			limit: $limit
			order_by: { title: $order }
		) {
			...songFragment
		}
	}
	${songFragment}
`
export type QuerySearchResultType = {
	song: Song[]
	tip: Tip[]
	lesson_plan: Lesson_Plan[]
}
export type QuerySearchSongsType = {
	song: Song[]
}
