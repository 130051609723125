import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useindieWurrlyPageStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerBox: {
			backgroundColor: theme.palette.common.white,
			borderRadius: 4,
			boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 15%)',
			padding: '20px 30px'
		},
		messageBox: {
			backgroundColor: '#E9E9EA',
			padding: '20px 30px',
			borderBottomLeftRadius: 4,
			borderBottomRightRadius: 4,
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			justifyContent: 'space-between'
		},
		grey: {
			color: theme.palette.grey[500]
		},
		button: {
			width: '50%',
			maxWidth: '160px'
		},
		avatarIcon: {
			height: '60px',
			width: '60px'
		},
		grade: {
			marginTop: 30,
			display: 'flex',
			alignItems: 'center'
		},
		teacherComment: {
			marginLeft: '30px'
		},
		flexCenter: { display: 'flex', alignItems: 'center' },
		chatBubbleIcon: { width: 20 },
		message: { marginLeft: 20 },
		gradeValue: {
			color: '#00BA70',
			textTransform: 'capitalize',
			position: 'absolute',
			fontSize: '12px',
			top: '52%',
			left: '50%',
			transform: 'translate3D(-50%,-50%,0)'
		},
		relative: { position: 'relative' },
		editIcon: {
			cursor: 'pointer',
			marginLeft: 10
		},
		fieldTitle: {
			fontSize: '1rem',
			fontWeight: 500,
			marginBottom: '.8em',
			marginTop: '40px',
			'& strong': {
				fontWeight: 500
			}
		}
	})
)

export default useindieWurrlyPageStyles
