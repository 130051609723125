import { useState, useEffect } from 'react'

import { Box, Link, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import { Link as LinkRD, useHistory } from 'react-router-dom'
import { Page, PagesProps } from 'src/@types'
import { InfoDialog, MainTitle, Stepper, AddToClassDialog, BigBaseButton } from 'src/components'
import { useInsertLessonToClassesByLessonPkMutation, useAddLessonMutation } from 'src/graphql/autogenerate/hooks'
import {
	Class,
	Visibility_Enum,
	Catalog_Item,
	Grade,
	Keyword,
	Musical_Skill,
	Non_Musical_Skill,
	Song,
	Tip,
	Lesson_Plan_Insert_Input,
	Lesson_Plan_Grade_Insert_Input,
	Lesson_Plan_Keyword_Insert_Input,
	Lesson_Plan_Musical_Skill_Insert_Input,
	Lesson_Plan_Non_Musical_Skill_Insert_Input,
	Lesson_Plan_Song_Insert_Input,
	Lesson_Plan_Tip_Insert_Input,
	Lesson_Plan_Asset_Insert_Input,
	Lesson_Plan_Article_Insert_Input,
	Discipline
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useScroll } from 'src/hooks/useScroll'
import { Pages } from 'src/routes/teacherPages'
import { LessonFormInitialDataType } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonEdit/useInitialLessonData'
import { buildRouteParameters } from 'src/utils'

import { StepFiveForm } from './StepFiveForm'
import { StepFourForm } from './StepFourForm'
import { StepOneForm } from './StepOneForm'
import { StepThreeForm } from './StepThreeForm'
import { StepTwoForm } from './StepTwoForm'
import { useStyles as useSharedStyles } from './styles'

const useStyles = makeStyles(() =>
	createStyles({
		buttonDisabled: {
			'& .MuiButton-contained.Mui-disabled': {
				backgroundColor: 'rgba(255, 194, 12, 0.4)'
			}
		}
	})
)
export type StepFormType = {
	saveData: (stepData: LessonFormDataType) => void
	lessonData: LessonFormDataType
	setStepIsValid?: (stepIsValid: boolean) => void
	handleInitialData?: InitialDataHandlerType
	onAssetDelete?: (assetId: number) => void
	lesson_plan_id?: number
}

export type ValidatedStepFormType = {
	saveData: (stepData: LessonFormDataType) => void
	lessonData: LessonFormDataType
	setStepIsValid: (stepIsValid: boolean) => void
	handleInitialData?: InitialDataHandlerType
	onAssetDelete?: (assetId: number) => void
}

export type InitialDataHandlerType = {
	initialData?: LessonFormInitialDataType
	stepHasFilledInitialData: boolean
	setInitialDataUsed: () => void
}
export type DisciplineType = {
	dance: boolean
	music: boolean
	theatre: boolean
	media_arts: boolean
	visual_arts: boolean
}

export type DisciplineTypeFields = keyof DisciplineType

export type ProcessType = {
	creating: string[]
	connecting: string[]
	performing: string[]
	responding: string[]
}

export type ProcessTypeFields = keyof ProcessType

export type HandleProcessFormValues = {
	values: string[]
	processType: ProcessTypeFields
}

type ArticleFormType = {
	content?: string
	articleLen?: number
	title?: string
	lesson_plan_article_id?: number
	order?: number
}

export type LessonFormDataType = {
	title?: string
	description?: string
	image_path?: string
	keywords?: Keyword[]
	subjects?: Catalog_Item[]
	ages?: Catalog_Item[]
	instruments?: Catalog_Item[]
	stepTwoArticles?: ArticleFormType[]
	stepThreeArticles?: ArticleFormType[]
	inspire?: string
	practice?: string
	record?: string
	reflect?: string
	takeItFurther?: string
	materials?: string
	differentiations?: string
	suggestedAssignments?: string
	discipline?: Discipline[]
	process?: number[]
	levels?: Grade[]
	musicalSkills?: Musical_Skill[]
	nonMusicalSkills?: Non_Musical_Skill[]
	songs?: Song[]
	videos?: Tip[]
	assets?: { resource_path: string; name: string; asset_id: number }[]
	errors?: {
		title?: string
		description?: string
		image_path?: string
		keywords?: Keyword[]
		subjects?: Catalog_Item[]
		ages?: Catalog_Item[]
		instruments?: Catalog_Item[]
		inspire?: string
		practice?: string
		record?: string
		reflect?: string
		takeItFurther?: string
		materials?: string
		differentiations?: string
		suggestedAssignments?: string
		discipline?: DisciplineType
		process?: ProcessType
		levels?: Grade[]
		musicalSkills?: Musical_Skill[]
		nonMusicalSkills?: Non_Musical_Skill[]
		songs?: Song[]
		videos?: Tip[]
		assets?: { resource_path: string; name: string; asset_id: number }[]
	}
}
export const LessonUpload = ({ page }: PagesProps) => {
	const classes = useStyles()
	const sharedClasses = useSharedStyles()
	const parentPage: Page = Pages[(page.parentName ?? '') as keyof typeof Pages]
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	// const [optionSelected, setOptionSelected] = useState('')
	const [currentStep, setCurrentStep] = useState(1)
	const [onCancel, setOnCancel] = useState(false)
	const [onError, setOnError] = useState(false)
	const [onSave, setOnSave] = useState(false)
	const [lessonData, setLessonData] = useState<LessonFormDataType>({})
	const breadcrumbs = [parentPage, ...(currentStep > 0 ? [page] : [page])]
	const [addToClassLesson, setAddToClassLesson] = useState<number>()
	const [addedLessonId, setAddedLessonId] = useState<number | undefined>(0)
	const [addLesson] = useAddLessonMutation()
	const [insertLessonToClassesMutation] = useInsertLessonToClassesByLessonPkMutation()
	const [stepOneIsValid, setStepOneIsValid] = useState(false)
	const [stepTwoIsValid, setStepTwoIsValid] = useState(true)
	const [stepThreeIsValid, setStepThreeIsValid] = useState(true)
	const [stepFourIsValid, setStepFourIsValid] = useState(false)
	const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true)

	useEffect(() => {
		let isValid = true
		if (currentStep === 1 && !stepOneIsValid) isValid = false
		if (currentStep === 2 && !stepTwoIsValid) isValid = false
		if (currentStep === 3 && !stepThreeIsValid) isValid = false
		else if (currentStep === 4 && !stepFourIsValid) isValid = false
		setIsNextBtnDisabled(!isValid)
	}, [currentStep, stepOneIsValid, stepTwoIsValid, stepThreeIsValid, stepFourIsValid])

	const saveData = (stepData: LessonFormDataType) => {
		setLessonData({ ...lessonData, ...stepData })
	}

	const steps = [
		{
			step: 1,
			stepName: 'Lesson Details',
			component: (
				<StepOneForm lessonData={lessonData} saveData={saveData} setStepIsValid={setStepOneIsValid} />
			)
		},
		{
			step: 2,
			stepName: `Main Lesson Sections`,
			component: (
				<StepTwoForm lessonData={lessonData} saveData={saveData} setStepIsValid={setStepTwoIsValid} />
			)
		},
		{
			step: 3,
			stepName: `Secondary Lesson Sections`,
			component: (
				<StepThreeForm lessonData={lessonData} saveData={saveData} setStepIsValid={setStepThreeIsValid} />
			)
		},
		{
			step: 4,
			stepName: `Core Standards`,
			component: (
				<StepFourForm lessonData={lessonData} saveData={saveData} setStepIsValid={setStepFourIsValid} />
			)
		},
		{
			step: 5,
			stepName: `Add Content`,
			component: <StepFiveForm lessonData={lessonData} saveData={saveData} />
		}
	]

	useScroll()

	const saveLesson = () => {
		const levels = {
			data:
				lessonData.levels?.map<Lesson_Plan_Grade_Insert_Input>((level) => ({
					grade_id: level.grade_id
				})) || []
		}
		const lessonKeywords = {
			data:
				lessonData.keywords?.map<Lesson_Plan_Keyword_Insert_Input>((keyword) => ({
					keyword_id: keyword.keyword_id
				})) || []
		}
		const instruments =
			lessonData.instruments?.map((catalogItem) => ({ catalog_item_id: catalogItem.catalog_item_id })) ?? []
		const subjects =
			lessonData.subjects?.map((catalogItem) => ({ catalog_item_id: catalogItem.catalog_item_id })) ?? []
		const ages =
			lessonData.ages?.map((catalogItem) => ({ catalog_item_id: catalogItem.catalog_item_id })) ?? []

		const lessonCatalogItem = {
			data: [...instruments, ...subjects, ...ages]
		}
		const musicalSkill = {
			data:
				lessonData.musicalSkills?.map<Lesson_Plan_Musical_Skill_Insert_Input>((musical) => ({
					musical_skill_id: musical.musical_skill_id
				})) || []
		}
		const nonMusicalSkill = {
			data:
				lessonData.nonMusicalSkills?.map<Lesson_Plan_Non_Musical_Skill_Insert_Input>((nonmusical) => ({
					non_musical_skill_id: nonmusical.non_musical_skill_id
				})) || []
		}
		const songs = {
			data: lessonData.songs?.map<Lesson_Plan_Song_Insert_Input>((song) => ({ song_id: song.song_id })) || []
		}
		const tips = {
			data: lessonData.videos?.map<Lesson_Plan_Tip_Insert_Input>((video) => ({ tip_id: video.tip_id })) || []
		}
		const assets = {
			data:
				lessonData.assets?.map<Lesson_Plan_Asset_Insert_Input>((asset) => ({ asset: { data: asset } })) ||
				[]
		}

		const articles = {
			data: [
				...(lessonData.stepTwoArticles?.map<Lesson_Plan_Article_Insert_Input>((article) => ({
					title: article.title,
					content: article.content,
					order: article.order
				})) || []),
				...(lessonData.stepThreeArticles?.map<Lesson_Plan_Article_Insert_Input>((article) => ({
					title: article.title,
					content: article.content,
					order: article.order
				})) || [])
			]
		}

		const {
			title,
			description,
			image_path,
			inspire,
			practice,
			record,
			reflect,
			takeItFurther,
			materials,
			differentiations,
			suggestedAssignments,
			discipline,
			process
		} = lessonData

		const formatLesson: Lesson_Plan_Insert_Input = {
			title,
			teacher_id: teacher.teacher_id,
			description,
			image_path,
			inspire,
			practice,
			record,
			reflect,
			take_it_further: takeItFurther,
			materials_needed: materials,
			differentiations,
			suggested_assignments: suggestedAssignments,
			discipline,
			process,
			lesson_plan_grade: levels,
			lesson_plan_keyword: lessonKeywords,
			lesson_plan_catalog_item: lessonCatalogItem.data.length === 0 ? null : lessonCatalogItem,
			lesson_plan_musical_skill: musicalSkill,
			lesson_plan_non_musical_skill: nonMusicalSkill,
			lesson_plan_song: songs,
			lesson_plan_tip: tips,
			lesson_plan_asset: assets,
			lesson_plan_articles: articles
		}

		addLesson({
			variables: {
				data: {
					...formatLesson,
					lockable_content: { data: { visibility: Visibility_Enum.Private, table: 'lesson_plan' } }
				}
			},
			onCompleted: (res) => {
				const rowId = res?.insert_lesson_plan_one?.lesson_plan_id
				setAddedLessonId(rowId)
				setOnSave(true)
			},
			onError: (err) => {
				alert(`Add lesson failed - ${JSON.stringify(err, null, 2)}`)
			}
		})
	}

	const insertLessonToClass = (classes: Class[], lessonPlanId: number | undefined) => {
		const mutationObject = classes.map((i) => ({ class_id: i.class_id, lesson_plan_id: lessonPlanId }))

		insertLessonToClassesMutation({
			variables: { classes: mutationObject },
			update: (cache) => {
				classes.forEach((i) => {
					const identify = cache.identify(i)
					cache.evict({
						id: identify,
						fieldName: 'class_lesson_plans'
					})
				})
			}
		})
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title="Upload Your Lesson"
				description={
					<Typography style={{ fontSize: 18, maxWidth: 500 }}>
						Upload your own Lesson here. Your Lessons will not be visible to your students, but you can
						share content from the Lesson by creating an Assignment or adding Songs or Videos to a
						Class. By uploading this Lesson, you confirm that you are complying with all legal
						guidelines set forth in our{' '}
						<Link color="secondary" target="_blank" href="https://www.wurrlyedu.com/terms">
							Terms of Use
						</Link>
						. If you have any questions, please refer to our email{' '}
						<Link color="secondary" target="_blank" href="mailto:support@wurrlyedu.com">
							support@wurrlyedu.com
						</Link>
					</Typography>
				}
			/>
			<Stepper
				stepDescription={steps.find((step) => step.step === currentStep)?.stepName ?? ''}
				currentStep={currentStep}
				totalSteps={steps.length}
				stepBoxProps={{ mt: 6 }}
				stepDescriptionClassName={sharedClasses.fieldTitle}
			/>
			{steps.find((steps) => steps.step === currentStep)?.component ?? null}

			<Box className={classes.buttonDisabled} marginTop="35px" display="flex" justifyContent="space-between">
				<Box>
					<BigBaseButton
						style={{ padding: '6px 40px' }}
						variant="contained"
						color="default"
						onClick={() => {
							setOnCancel(true)
						}}>
						<Typography variant="caption">Cancel</Typography>
					</BigBaseButton>
					{currentStep !== 1 && (
						<BigBaseButton
							style={{ marginLeft: '10px', padding: '6px 40px' }}
							variant="contained"
							color="default"
							onClick={() => {
								if (currentStep > 1) {
									setCurrentStep(currentStep - 1)
								}
							}}>
							<Typography variant="caption">Back</Typography>
						</BigBaseButton>
					)}
				</Box>

				<BigBaseButton
					style={{ padding: '6px 50px' }}
					color="secondary"
					onClick={() => {
						if (currentStep < steps.length) {
							setCurrentStep(currentStep + 1)
							window.scrollTo(0, 0)
						} else if (currentStep === steps.length) {
							if (lessonData.errors?.title) {
								setOnError(true)
							} else {
								saveLesson()
							}
						}
					}}
					disabled={isNextBtnDisabled}>
					<Typography variant="caption">{currentStep === steps.length ? 'Save' : 'Next'}</Typography>
				</BigBaseButton>
			</Box>
			<InfoDialog
				open={onSave}
				onClose={() => {}}
				title="Lesson Saved!"
				body='Your Lesson was uploaded successfully! It will appear under "My Files"'
				discardLabel="Upload Another Lesson"
				onDiscard={() => {
					setOnSave(false)
					window.location.reload()
				}}
				confirmLabel="Add to Class"
				confirmProps={{ color: 'primary', endIcon: <AddIcon /> }}
				onConfirm={() => {
					setAddToClassLesson(addedLessonId)
					// setOnSave(false)
				}}
				icon={<CheckIcon />}
				footer={
					<Link component={LinkRD} to={Pages.MyFiles.path}>
						<Typography color="secondary">View My Files</Typography>
					</Link>
				}
			/>
			<InfoDialog
				open={onCancel}
				onClose={() => setOnCancel(false)}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={() => {
					setOnCancel(false)
					history.push(buildRouteParameters(Pages.Lessons))
				}}
				confirmLabel="No, Cancel"
				onConfirm={() => {
					setOnCancel(false)
				}}
				icon="!"
			/>

			<InfoDialog
				open={onError}
				onClose={() => setOnError(false)}
				title="Complete Information"
				body={`Please complete the requeried fields: title`}
				discardLabel="Close"
				onDiscard={() => {
					setOnError(false)
				}}
				icon="!"
			/>
			<AddToClassDialog
				isOpen={!!addToClassLesson}
				setIsOpen={setAddToClassLesson}
				onSuccess={{ shouldClose: false }}
				itemName="Lesson"
				title="Save Lesson to Class"
				description="This will save the Lesson so that you can view and teach right from your Class page.
				It will not be visible to your students, but you can share content from the Lesson by creating an Assignment or adding Songs or Videos to a Class."
				itemClasses={[]}
				onDone={() => {
					history.push(buildRouteParameters(Pages.Lessons))
				}}
				onConfirm={async (classes) => {
					setOnSave(false)
					insertLessonToClass(classes, addToClassLesson)
				}}
			/>
		</Box>
	)
}
