import React, { Fragment, useEffect, useState } from 'react'

import { Box, GridList, GridListTile, Tooltip, Typography } from '@material-ui/core'
import {
	BaseDialog,
	BaseDialogProps,
	IconActionCard,
	IconActionOptionType,
	ImageActionCard,
	ImageActionOptionType
} from 'src/components'
import { useStyles } from 'src/components/Dialogs/ListDialog.style'
import { DEFAULT_CARD_HEIGHT } from 'src/utils'
import { generateUniqueId } from 'src/utils/utils'

type Props = {
	stepper?: React.ReactNode
	// title of modal
	title: string | React.ReactNode
	// description below
	description?: string | React.ReactNode
	// option id to be selected by default
	optionSelected?: string
	// list of options with id and their own props
	options: ((IconActionOptionType | ImageActionOptionType) & { cols?: number })[]
	// action after selecting a value using the selected id
	handleSelectOption: (id: string) => void | React.ReactNode
	// card height, it will be used to define the cellHeight (cellHeight = cardHeight + 20)
	cardHeight?: number
	// card height, it will be used to define the cellHeight (cellHeight = cardHeight + 20)
	cardWidth?: number | string
	// number of colums, 2 by default
	cols: number
	// if true, hovering the elements won't change the cards color to dark yellow
	disableHover?: boolean
	children?: React.ReactElement
}

export const ListDialog = ({
	stepper,
	title,
	description,
	options,
	optionSelected = '',
	handleSelectOption,
	cardHeight = DEFAULT_CARD_HEIGHT,
	cardWidth = '100%',
	cols = 2,
	children,
	disableHover = false,
	...ext
}: Props & BaseDialogProps) => {
	const styles = useStyles()

	const [optionHovered, setOptionHovered] = useState('')

	useEffect(() => {
		return () => {
			setOptionHovered('')
		}
	}, [])

	return (
		<BaseDialog
			dividers={false}
			header={
				<Fragment>
					{stepper && stepper}
					<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
						{title}
					</Typography>
					{description && (
						<Typography align="center" variant="body2" className={styles.description}>
							{description}
						</Typography>
					)}
					{children}
				</Fragment>
			}
			body={
				<Box padding="6px 10px">
					<GridList cols={cols} cellHeight={(cardHeight || 0) + 20}>
						{options.length &&
							options.map((opt) => {
								const iconEl = opt as IconActionOptionType
								const imageEl = opt as ImageActionOptionType

								return (
									<Tooltip
										key={opt.id}
										title={iconEl?.disabled && !iconEl?.noTooltip ? 'Coming soon...' : ''}>
										<GridListTile key={generateUniqueId()} cols={opt.cols || 1}>
											{imageEl.imagePath ? (
												<ImageActionCard
													option={imageEl}
													isSelected={!!optionSelected && optionSelected === opt.id}
													handleClick={() => handleSelectOption(opt.id)}
													cardHeight={cardHeight}
													cardWidth={cardWidth}
												/>
											) : (
												<IconActionCard
													option={iconEl}
													isSelected={!!optionSelected && optionSelected === opt.id}
													handleClick={() => handleSelectOption(opt.id)}
													cardHeight={cardHeight}
													cardWidth={cardWidth}
													isSomeCardHovered={!!optionHovered}
													isHovered={optionHovered === opt.id}
													setIsHovered={(isHovered: boolean) => {
														if (!disableHover)
															setTimeout(() => {
																setOptionHovered(isHovered ? opt.id : '')
															}, 50)
													}}
												/>
											)}
										</GridListTile>
									</Tooltip>
								)
							})}
					</GridList>
				</Box>
			}
			{...ext}
		/>
	)
}
