import { ProfileSection } from 'src/components/Profile/ProfileSection'

export const ProfilePayment = () => {
	return (
		<ProfileSection
			title="Payment Methods"
			body={<h3>To be implemented</h3>}
			editBtn={{
				label: 'Edit Payment',
				action: () => console.warn('Edit Payment')
			}}
		/>
	)
}
