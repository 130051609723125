import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actionButton: {
			style: { border: '1px solid rgba(50, 51, 66, 0.5)' }
		},
		link: {
			color: theme.palette.secondary.dark
		}
	})
)
