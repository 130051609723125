import { ChangeEvent, useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { BaseDialog, SelectSort } from 'src/components'
import { AssignmentCard } from 'src/components/Cards/AssignmentCard/AssignmentCard'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'
import { AssignmentSortOrder, SortOrder } from 'src/utils'

import useStyles from './ClassAssignmentsDialog.style'

type ClassAssignmentsDialogProps = {
	open: boolean
	closeDialog: () => void
	className: string
	classAssignments: Class_Assignment[]
	setSort: (sort: AssignmentSortOrder) => void
}
export const ClassAssignmentsDialog = ({
	open,
	closeDialog,
	className,
	classAssignments,
	setSort
}: ClassAssignmentsDialogProps) => {
	const styles = useStyles()
	const [sortOrder, setSortOrder] = useState(SortOrder.Up)
	const handleSortOrder = (
		event: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => setSortOrder(event.target.value as SortOrder)

	useEffect(() => {
		setSort(
			sortOrder === SortOrder.Up
				? AssignmentSortOrder.AlphAsc
				: sortOrder === SortOrder.Down
				? AssignmentSortOrder.AlphDesc
				: AssignmentSortOrder.DateAsc
		)
	}, [sortOrder])

	return (
		<BaseDialog
			open={open}
			onDiscard={closeDialog}
			onClose={closeDialog}
			discardLabel="Close"
			className={styles.dialog}
			dividers={false}
			onDiscardProps={{ className: styles.discardButton }}
			header={
				<Box>
					<Typography variant="h4" className={styles.title}>
						<b>{className}'s Completed Assignments</b>
					</Typography>
					<Box className={styles.sort}>
						<Typography variant="h6">Assignments ({classAssignments?.length || 0})</Typography>
						<SelectSort value={sortOrder} onChange={handleSortOrder} dateSubmitted />
					</Box>
				</Box>
			}
			body={
				classAssignments?.length ? (
					classAssignments?.map((classAssignment) => (
						<Box className={styles.assignmentCard} key={classAssignment?.class_assignment_id}>
							<AssignmentCard classAssignment={classAssignment as unknown as Class_Assignment} />
						</Box>
					))
				) : (
					<Typography variant="h6" className={styles.noCompletedAssignments}>
						No Completed Assignments
					</Typography>
				)
			}
		/>
	)
}
