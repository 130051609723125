import { makeStyles } from '@material-ui/core'

export const useDateInputStyles = makeStyles((theme) => ({
	dateField: {
		width: '100%'
	},
	selector: {
		'& .MuiPickersToolbar-toolbar, & .MuiPickersDay-daySelected': {
			backgroundColor: theme.palette.secondary.main,
			color: 'white'
		},
		'& .MuiPickersToolbarText-toolbarBtnSelected': {
			color: 'white'
		},
		'& .MuiPickersToolbarText-toolbarTxt': {
			color: 'white'
		}
	}
}))

export default useDateInputStyles
