import { Box, CircularProgress, Typography } from '@material-ui/core'
import { ListVideos } from 'src/components/ListVideos'
import { MyFilesAccordion } from 'src/components/MyFilesAccordion'

import { useVideoUploads } from './useVideoUploads'
import useStyles from './VideoUploads.styles'

export const VideoUploads = () => {
	const styles = useStyles()
	const { totalVideos, videos, sort, handleSort, seeMoreEnabled, seeMore, loading, teacherId } =
		useVideoUploads()

	return (
		<MyFilesAccordion
			title="Video Uploads"
			count={totalVideos}
			details={
				<>
					{!loading && (
						<ListVideos
							copyLinkButton
							teacherId={teacherId}
							spacing={1.5}
							data={videos}
							removeButton
							editButton
						/>
					)}
					{loading && (
						<Box className={styles.boxLoading}>
							<CircularProgress color="secondary" size={30} />
						</Box>
					)}
					{seeMoreEnabled && (
						<Typography color="textSecondary" className={styles.seeMore} onClick={seeMore}>
							<b>See More</b>
						</Typography>
					)}
				</>
			}
			sort={sort}
			handleSort={handleSort}
		/>
	)
}
