import { useEffect, useState } from 'react'

import {
	useGetCanvasAuthUrlQuery,
	useGetCanvasUserLazyQuery,
	useLoginOrSignUpTeacherIntegrationLazyQuery,
	useStudentIntegrationLoginLazyQuery
} from 'src/graphql/autogenerate/hooks'
import {
	CanvasGroup,
	GetCanvasUserData,
	Integration_Name_Enum,
	LoginOrSignupTeacherIntegrationData
} from 'src/graphql/autogenerate/schemas'
import { IntegrationStudent } from 'src/hooks/useIntegration'
import { useLoginContext } from 'src/hooks/useLogin'
import { NO_CACHE } from 'src/utils'
import { getErrorMessage } from 'src/utils/errorHandler'

type CanvasInstancType = {
	teacherId: number
	setClasses: (classes: CanvasGroup[]) => void
	setStudents?: (students: IntegrationStudent[]) => void
}

export type CanvasTeacherData = Omit<LoginOrSignupTeacherIntegrationData, '__typename'>

export const useCanvasInstance = ({ setClasses }: CanvasInstancType) => {
	const [account, setAccount] =
		useState<Pick<GetCanvasUserData, 'email' | 'isTeacher' | 'sourcedId' | 'displayName' | 'access_token'>>()
	const [isOpen, setIsOpen] = useState(false)
	const [data, setData] = useState<CanvasTeacherData>()
	const { loginSignIn } = useLoginContext()
	const [error, setError] = useState<string>('')
	const [loginOrSignUpTeacher, loginOrSignupInfo] = useLoginOrSignUpTeacherIntegrationLazyQuery(NO_CACHE)
	const { data: loginUrl } = useGetCanvasAuthUrlQuery({ fetchPolicy: 'network-only' })
	const [getCanvas, { loading }] = useGetCanvasUserLazyQuery()
	const [loginStudent, { loading: loginStudentLoading, error: loginStudentError }] =
		useStudentIntegrationLoginLazyQuery(NO_CACHE)

	const requestCanvasUser = async (code: string) => {
		const { data } = await getCanvas({ variables: { code } })
		if (!data) return

		const {
			getCanvasUser: { isTeacher, sourcedId, email, displayName, access_token, classes }
		} = data
		setClasses(classes)
		setAccount((prev) => ({ ...prev, isTeacher, sourcedId, email, displayName, access_token }))
	}

	useEffect(() => {
		if (account?.sourcedId) {
			handleLoginOrSignUp()
		}
	}, [account])

	useEffect(() => {
		if (loginStudentError?.message) {
			setErrorWrapper(loginStudentError)

			return
		}
		if (loginOrSignupInfo.error?.message) {
			setErrorWrapper(loginOrSignupInfo.error)

			return
		}
	}, [loginStudentError, loginOrSignupInfo.error])

	const setErrorWrapper = (message: unknown) => {
		const formatted = getErrorMessage(message)
		console.error({ error: formatted })
		setError(formatted)
	}

	const logInTeacher = async () => {
		if (!account) return undefined
		const { email, sourcedId, access_token } = account
		const { data } = await loginOrSignUpTeacher({
			variables: {
				email: email || '',
				accesToken: access_token,
				integrationId: sourcedId,
				integrationName: Integration_Name_Enum.Canvas
			}
		})

		if (!data || !data?.loginOrSignupTeacherIntegration?.token) return undefined

		setData(() => ({ ...data?.loginOrSignupTeacherIntegration } as LoginOrSignupTeacherIntegrationData))

		return data?.loginOrSignupTeacherIntegration?.token
	}

	const logInStudent = async () => {
		if (!account) return
		const { sourcedId } = account
		const { data } = await loginStudent({
			variables: { integrationId: sourcedId }
		})

		if (!data || !data?.studentLoginIntegration?.token) return

		setData(() => ({ token: data?.studentLoginIntegration?.token } as LoginOrSignupTeacherIntegrationData))

		return data?.studentLoginIntegration?.token
	}

	const handleLoginOrSignUp = async () => {
		if (!account || !account.sourcedId) return
		const { isTeacher } = account
		const token = isTeacher ? await logInTeacher() : await logInStudent()
		if (!token) return
		loginSignIn(token, Integration_Name_Enum.Canvas)
	}

	const runLoadStudents = () => {}

	const openPopup = () => {
		const url = loginUrl?.getCanvasAuthUrl

		if (isOpen || !url) return

		return new Promise((resolve) => {
			setIsOpen(true)
			const loginWindow = window.open(url, 'name', 'height=600,width=450')

			if (loginWindow) {
				loginWindow.focus()

				try {
					const oauthInterval = setInterval(() => {
						try {
							if (loginWindow.closed) {
								setIsOpen(false)
								clearInterval(oauthInterval)
							}
							if (loginWindow.location.href && !loginWindow.location.href.includes('about:blank')) {
								const codeURL = new URL(loginWindow.location.href)
								const code = codeURL.searchParams.get('code')
								if (!code) return
								requestCanvasUser(code)
								loginWindow.close()
								resolve(true)
							}
						} catch (err) {
							console.error({ err })
						}
					}, 200)
				} catch (err) {
					console.error({ err })
				}
			}
		})
	}

	return {
		openPopup,
		account,
		data,
		runLoadStudents,
		loading: loginOrSignupInfo.loading || loading || loginStudentLoading,
		error
	}
}
