import { createStyles, makeStyles } from '@material-ui/core'

export const useTokenAccountActivationStyles = makeStyles(() =>
	createStyles({
		notesIcon: {
			position: 'fixed',
			bottom: '40px',
			left: '50%',
			transform: 'translateX(-50%)'
		}
	})
)

export default useTokenAccountActivationStyles
