import { Grading_System, Submission } from 'src/graphql/autogenerate/schemas'

import { SubmissionCard } from './SubmissionCard'

type SubmissionCardContainerProps = {
	submission: Submission
	assignmentDueDate?: string
	assignmentGradingSystem: Pick<Grading_System, 'label' | 'values'> | undefined
}
export const SubmissionCardContainer = ({
	submission,
	assignmentDueDate,
	assignmentGradingSystem
}: SubmissionCardContainerProps) => {
	const submission_student = submission?.submission_wurrlies[0]?.wurrly?.student
	const avatarUrl = submission_student?.image_path ?? ''
	const submissionDate = submission?.date_submitted ?? ''
	const grade = submission?.grade ?? undefined
	const message = submission?.submission_wurrlies[0]?.wurrly?.message ?? undefined
	const name = submission?.name ?? ''
	const gradingSystem = assignmentGradingSystem
	const teacherComment = submission?.teacher_comment as string

	return (
		<SubmissionCard
			avatarUrl={avatarUrl}
			submitterName={name}
			submissionDate={submissionDate}
			grade={grade}
			message={message}
			key={submission.submission_id}
			dueDateString={assignmentDueDate}
			gradingSystem={gradingSystem}
			teacherComment={teacherComment}
		/>
	)
}
