import { makeStyles } from '@material-ui/core'

export const useAssignmentDescriptionStyles = makeStyles({
	intructions: {
		fontSize: 18,
		maxWidth: 500
	},
	dateHolder: {
		marginTop: 10,
		display: 'flex',
		alignItems: 'center'
	}
})

export default useAssignmentDescriptionStyles
