import { Box, BoxProps, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import {
	ScrubberBackground,
	ScrubberBackgroundProps
} from 'src/components/VideoControls/Background/ScrubberBackground'
import { Scrubber, ScrubberProps } from 'src/components/VideoControls/Scrubber'
import { TrimmerProps } from 'src/components/VideoControls/Trimmer'

import useStyles from './TrimmerScrubber.styles'

type TrimmerScrubberProps = {
	scrubberProps: ScrubberProps
	trimmerProps: TrimmerProps
	scrubberBgProps: ScrubberBackgroundProps
	boxProps?: BoxProps
	hideTab?: () => void
}
export const TrimmerScrubber = ({ scrubberProps, scrubberBgProps, boxProps, hideTab }: TrimmerScrubberProps) => {
	const styles = useStyles()

	return (
		<Box {...boxProps} className={clsx(styles.container, boxProps?.className)}>
			<Box className={styles.innerBar}>
				<ScrubberBackground {...scrubberBgProps} />
				<Scrubber {...scrubberProps} />
				{/* <Trimmer {...trimmerProps} /> */}
			</Box>
			{hideTab && (
				<IconButton className={styles.closeButton} onClick={hideTab}>
					<CloseIcon fontSize="small" />
				</IconButton>
			)}
		</Box>
	)
}
