import { AssignmentsProvider } from 'src/scenes/Student/hooks/useAssignments'
import { AssignmentsDetailsContent } from 'src/scenes/Student/scenes/6-Assignments/components/AssignmentsDetails/AssignmentsDetailsContent'

export const AssignmentsDetails = () => {
	return (
		<AssignmentsProvider>
			<AssignmentsDetailsContent />
		</AssignmentsProvider>
	)
}
