import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

export const useMyFiles = () => {
	const history = useHistory()

	const [menuUpload, setMenuUpload] = useState<EventTarget & HTMLButtonElement>()

	const handleCloseUpload = () => {
		setMenuUpload(undefined)
	}
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setMenuUpload(event?.currentTarget)
	}

	return {
		history,
		menuUpload,
		handleCloseUpload,
		handleClick
	}
}
