import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { InfoDialog, ReInviteDialog } from 'src/components'
import { useGetSendStudentRemainderEmailLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Student } from 'src/graphql/autogenerate/schemas'

type Props = {
	setStudentReinvite: React.Dispatch<React.SetStateAction<Student | undefined>>
	studentReinvite: Student | undefined
}

export const ReInviteStudentDialog = ({ setStudentReinvite, studentReinvite }: Props) => {
	const [idToReInvite, setIdToReInvite] = useState<number | undefined>() // Set the Id to be re-invited
	const [isWarningOpen, setIsWarningOpen] = useState(false)
	const [sendReinviteEmail, {}] = useGetSendStudentRemainderEmailLazyQuery({ fetchPolicy: 'no-cache' })

	useEffect(() => {
		if (!idToReInvite) {
			setStudentReinvite(undefined)
		}
	}, [idToReInvite])

	useEffect(() => {
		if (studentReinvite) {
			if (!studentReinvite.email) {
				setIsWarningOpen(true)
			} else {
				setIdToReInvite(studentReinvite.student_id)
			}
		}
	}, [studentReinvite])

	return (
		<Box>
			<ReInviteDialog
				isOpen={!!idToReInvite}
				setIsOpen={setIdToReInvite}
				onConfirm={async () => {
					if (studentReinvite && studentReinvite.email && studentReinvite.code) {
						await sendReinviteEmail({
							variables: {
								filter: {
									student: {
										code: studentReinvite.code,
										firstName: studentReinvite.first_name,
										lastName: studentReinvite.last_name,
										email: studentReinvite.email
									}
								}
							}
						})
						setStudentReinvite(undefined)
					}
				}}
			/>
			<InfoDialog
				open={isWarningOpen}
				onClose={() => {
					setIsWarningOpen(false)
				}}
				icon="!"
				title="Unable to send email"
				body="This Student does not have an email set. Add student email first."
				confirmLabel="Done"
				onConfirm={() => {
					setIsWarningOpen(false)
				}}
			/>
		</Box>
	)
}
