import { WurrlyLogoIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { ReviewAndSubmitProvider } from 'src/components/Studio/Hooks/useReviewAndSubmit'
import { StudioReviewProvider } from 'src/components/Studio/Review/useStudioReview'
import { VideoReviewDialog } from 'src/components/Studio/Review/VideoReviewDialog'
import { useStudioBackgroundStyles } from 'src/components/Studio/Setup/styles/studioBackgroundStyles'
import { VideoSubmitProvider } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'

export const StudioReview = () => {
	const styles = useStudioBackgroundStyles()

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={styles.logoPosition} />}
			className={styles.backgroundImage}>
			<VideoSubmitProvider>
				<ReviewAndSubmitProvider>
					<StudioReviewProvider>
						<VideoReviewDialog isTeacher />
					</StudioReviewProvider>
				</ReviewAndSubmitProvider>
			</VideoSubmitProvider>
		</FixedBackground>
	)
}
