import { createStyles, makeStyles } from '@material-ui/core'

export const useSongItemStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			padding: theme.spacing(2),
			paddingLeft: 0
		},
		songContainer: {
			width: '60%'
		}
	})
)

export default useSongItemStyles
