import { Box, IconButton, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { buildImagePath } from 'src/utils'

import useStyles from './SongCard.styles'

type SongCardProps = {
	imgSource: string
	songTitle: string
	artistName: string
	catalogs: string[]
	setSelectedSongs?: (songs: Song[]) => void
	selectedSongs?: Song[]
	song_id?: number
}

export const SongCard = ({
	imgSource,
	songTitle,
	artistName,
	catalogs,
	setSelectedSongs,
	selectedSongs,
	song_id
}: SongCardProps) => {
	const styles = useStyles()
	const { isLoggedAsTeacher } = useLoginContext()
	const handleRemove = () => {
		if (selectedSongs && setSelectedSongs) {
			const arr = selectedSongs.filter((el) => el.song_id !== song_id)
			setSelectedSongs(arr)
		}
	}

	return (
		<Box className={styles.container}>
			<Box style={{ display: 'flex' }}>
				<img src={buildImagePath(imgSource)} className={styles.image} />
				<Box className={styles.details}>
					<Typography variant="h6" className={styles.title}>
						{songTitle}
					</Typography>
					<Typography variant="h6" className={styles.subtitle}>
						{artistName}
					</Typography>
					{!!catalogs.length && (
						<Typography variant="subtitle1" className={styles.catalogs}>
							• {catalogs.join(', ')}
						</Typography>
					)}
				</Box>
			</Box>
			<Box style={{ display: 'flex' }}>
				{isLoggedAsTeacher && (
					<IconButton className={styles.iconButton} onClick={handleRemove}>
						<Clear />
					</IconButton>
				)}
			</Box>
		</Box>
	)
}
