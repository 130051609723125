import '@brainhubeu/react-carousel/lib/style.css'

import { useEffect, useState } from 'react'

import Carousel, { autoplayPlugin, CarouselPluginTypes, slidesToShowPlugin } from '@brainhubeu/react-carousel'
import { Box, Fab } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import clsx from 'clsx'

import useStyles from './CarouselOfAny.styles'
type CarouselOfAnyProps<T> = {
	draggable?: boolean
	autoPlay?: number
	animationSpeed?: number
	slidesPerPage?: number | { xs?: number; sm?: number; md?: number; lg?: number; xl?: number }
	infinite?: boolean
	spacing?: number
	useCarouselOnSingle?: boolean
	data: T[]
	renderItem: (item: T) => JSX.Element
	setIsViewingLastItem?: (isLastItem: boolean) => void
	onNextClick?: (nextStep: number) => void
	onPrevClick?: (prevStep: number) => void
	goToSlide?: number | undefined
	centerSlide?: boolean
	arrowType?: 'primary' | 'secondary'
}

export const CarouselOfAny = <T extends unknown>({
	draggable = false,
	autoPlay,
	animationSpeed = 500,
	slidesPerPage = 1,
	infinite,
	spacing = 0,
	data,
	useCarouselOnSingle = false,
	renderItem,
	setIsViewingLastItem,
	onNextClick,
	onPrevClick,
	goToSlide,
	centerSlide,
	arrowType = 'primary'
}: CarouselOfAnyProps<T>): JSX.Element => {
	const styles = useStyles()
	const [activeStep, setActiveStep] = useState(0)
	const [numSlides, setNumSlides] = useState(1)
	const [carouselPlugins, setCarouselPlugins] = useState<(string | CarouselPluginTypes)[]>([])
	const theme = useTheme()
	const getNumberOfSlides = (width: number) => {
		if (typeof slidesPerPage === 'number') return slidesPerPage
		else {
			if (slidesPerPage.xl && width >= theme.breakpoints.width('xl')) return slidesPerPage.xl
			else if (slidesPerPage.lg && width >= theme.breakpoints.width('lg')) return slidesPerPage.lg
			else if (slidesPerPage.md && width >= theme.breakpoints.width('md')) return slidesPerPage.md
			else if (slidesPerPage.sm && width >= theme.breakpoints.width('sm')) return slidesPerPage.sm
			else return slidesPerPage.xs ? slidesPerPage.xs : 1
		}
	}

	useEffect(() => setActiveStep(0), [numSlides])

	useEffect(() => {
		if (typeof goToSlide === 'number') {
			setActiveStep(goToSlide)
		}
	}, [goToSlide])

	useEffect(() => {
		// Creamos una función para actualizar el estado con el clientWidth
		const updateWidth = () => {
			const width = document.body.clientWidth
			const num = getNumberOfSlides(width)
			if (numSlides !== num) setNumSlides(num)
		}
		// Actualizaremos el width al montar el componente
		updateWidth()
		// Nos suscribimos al evento resize de window
		window.addEventListener('resize', updateWidth)

		// Devolvemos una función para anular la suscripción al evento
		return () => {
			window.removeEventListener('resize', updateWidth)
		}
	}, [])

	useEffect(() => {
		const plugins = [] as (string | CarouselPluginTypes)[]

		plugins.push({
			resolve: slidesToShowPlugin,
			options: {
				numberOfSlides: numSlides
			}
		})
		if (infinite) {
			plugins.push('infinite')
		}
		if (typeof autoPlay === 'number') {
			plugins.push({
				resolve: autoplayPlugin,
				options: {
					interval: autoPlay
				}
			})
		}
		setCarouselPlugins(plugins)
	}, [autoPlay, infinite, numSlides])

	useEffect(() => {
		if (setIsViewingLastItem) {
			const isLastItemInView = activeStep + numSlides >= data.length
			setIsViewingLastItem(isLastItemInView)
		}
	}, [activeStep])

	const getAddNum = (i: number) => {
		if (numSlides > 1) {
			if (data.length - i >= numSlides * 2) return numSlides
			else return data.length - i - numSlides
		} else return 1
	}

	const getSubNum = (i: number) => {
		if (numSlides > 1) {
			if (i >= numSlides) return numSlides
			else return i
		} else return 1
	}

	const handleNext = () => {
		const nextStep = activeStep + getAddNum(activeStep)
		if (onNextClick) return onNextClick(nextStep)
		setActiveStep(nextStep)
	}

	const handleBack = () => {
		const prevStep = activeStep - getSubNum(activeStep)
		if (onPrevClick) return onPrevClick(prevStep)
		setActiveStep(prevStep)
	}

	const handleStepChange = (step: number) => {
		setActiveStep(step)
	}

	if (data.length === 0) return <></>

	if (data.length === 1 && !useCarouselOnSingle) {
		return <Box m={spacing / 2}>{renderItem(data[0])}</Box>
	}

	return (
		<Box position="relative" m={-spacing / 2} className={centerSlide ? styles.centerContent : ''}>
			{/* Carousel with one element is getting unexpected issues with rezising */}

			<Carousel
				value={activeStep}
				onChange={handleStepChange}
				draggable={draggable}
				plugins={carouselPlugins}
				animationSpeed={animationSpeed}>
				{data.map((item, index) => (
					<Box
						key={index}
						width="100%"
						m={spacing / 2}
						className={centerSlide ? styles.centerSlide : ''}>
						{renderItem(item)}
					</Box>
				))}
			</Carousel>

			{data.length > numSlides && arrowType === 'primary' && (
				<Box>
					<Box
						position="absolute"
						top={(spacing * 8) / 2}
						left={(spacing * 8) / 2 - 24}
						bottom={(spacing * 8) / 2}
						display={!infinite && activeStep === 0 ? 'none' : 'flex'}
						alignItems="center"
						zIndex={10}>
						<Fab
							style={{ backgroundColor: '#fff' }}
							size="medium"
							onClick={handleBack}
							// disabled={!infinite && activeStep === 0}
						>
							<KeyboardArrowLeft color="secondary" />
						</Fab>
					</Box>

					<Box
						position="absolute"
						top={(spacing * 8) / 2}
						right={(spacing * 8) / 2 - 24}
						bottom={(spacing * 8) / 2}
						display={!infinite && activeStep + numSlides === data.length ? 'none' : 'flex'}
						alignItems="center"
						zIndex={10}>
						<Fab
							style={{ backgroundColor: '#fff' }}
							size="medium"
							onClick={handleNext}
							// disabled={!infinite && (activeStep + slidesPerPage) === data.length - 1}
						>
							<KeyboardArrowRight color="secondary" />
						</Fab>
					</Box>
				</Box>
			)}
			{data.length > numSlides && arrowType === 'secondary' && (
				<Box>
					<Box
						display={!infinite && activeStep === 0 ? 'none' : 'flex'}
						className={clsx(styles.arrowsSecondary, styles.leftArrow)}
						onClick={handleBack}></Box>

					<Box
						display={!infinite && activeStep + numSlides === data.length ? 'none' : 'flex'}
						alignItems="center"
						onClick={handleNext}
						className={clsx(styles.arrowsSecondary, styles.rightArrow)}></Box>
				</Box>
			)}
		</Box>
	)
}
