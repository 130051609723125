import { useEffect } from 'react'

import MomentUtils from '@date-io/moment'
import { Box, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useFormik } from 'formik'
import { chunk } from 'lodash'
import moment from 'moment'
import { PagesProps } from 'src/@types'
import {
	BigBaseButton,
	DropdownInput,
	HorizontalGrid,
	ImageActionOptionType,
	InputHeader,
	TextInput
} from 'src/components'
import { ContactButton } from 'src/components/Buttons/ContactButton/ContactButton'
import {
	GetClassesDocument,
	useCreateClassMutation,
	useGetClassImagesQuery,
	useGetSubjectsQuery,
	useGetGradesQuery,
	useGetDefaultGradingSystemsQuery
} from 'src/graphql/autogenerate/hooks'
import {
	Catalog_Item,
	Grade,
	Grading_System,
	Grading_System_Type_Enum,
	Maturity_Level_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { BASE_URL, getCyKey } from 'src/utils'
import { generateUniqueId } from 'src/utils/utils'
import * as yup from 'yup'

import { useStyles } from './styles'
type Props = {
	handleCancel: () => void
	handleAfterSave: (classId: number) => void
} & PagesProps

const validationSchema = yup.object({
	name: yup.string().required('Name is required').ensure(),
	maturityLevel: yup.string().required('Subject is required').ensure(),
	subjectId: yup.string(),
	initDate: yup.date().nullable(),
	endDate: yup
		.date()
		.min(yup.ref('initDate'), 'Date should not be before start date')
		.nullable()
		// simpliest way to check multiple scenarios between endDate and initDate
		.test('testValidDates', 'Start Date and End Date are needed', function (endDate) {
			const { initDate } = this.parent
			if ((!initDate && endDate) || (initDate && !endDate)) return false

			return true
		}),
	gradeId: yup.string().required('Grade is required'),
	imagePath: yup.string().required('Image is required').ensure(),
	gradingSystem: yup.object().shape({
		name: yup.string().required('Grading System is required').ensure(),
		grading_system_id: yup.number().required('Grading System is required'),
		label: yup.string().required('Grading System is required').ensure()
	})
})

export const ClassForm = ({ handleCancel, handleAfterSave: handleSave }: Props) => {
	const { teacherData: teacher } = useLoginContext()
	const { data: subjectsData } = useGetSubjectsQuery()
	const { data: classImages } = useGetClassImagesQuery()
	const { data: gradesData } = useGetGradesQuery()
	const { data: gradingSystem } = useGetDefaultGradingSystemsQuery()
	const [createClass] = useCreateClassMutation()
	const subjects = (subjectsData?.catalog_item || undefined) as Catalog_Item[]
	const styles = useStyles()

	let imageRows: ImageActionOptionType[][] = []
	if (classImages?.class_images) {
		const images = classImages?.class_images.map((row) => ({
			id: row.id as unknown as string,
			imagePath: `${BASE_URL}${row.imagePath}`
		}))

		imageRows = chunk(images, 5)
	}

	const formik = useFormik({
		initialValues: {
			name: '',
			// in order to work properly the dates components needs the initial value as null
			initDate: null,
			endDate: null,
			maturityLevel: '',
			subjectId: '',
			gradeId: '',
			imagePath: undefined,
			gradingSystem: {
				name: Grading_System_Type_Enum.None,
				grading_system_id: NaN,
				label: ''
			} as Grading_System
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async (values) => {
			try {
				const createdClass = await createClass({
					variables: {
						objects: [
							{
								title: values.name,
								start_date: !!values.initDate ? moment().format('YYYY-MM-DD') : values.initDate,
								end_date: !!values.endDate
									? moment().add(1, 'months').format('YYYY-MM-DD')
									: values.endDate,
								school_id: teacher.school?.school_id,
								grading_system_id: values.gradingSystem.grading_system_id,
								maturity_level: values.maturityLevel.toLowerCase() as Maturity_Level_Enum,
								catalog_item_id: values.subjectId !== '' ? +values.subjectId : null,
								class_teachers: { data: [{ teacher_id: teacher.teacher_id }] },
								grade_id: +values.gradeId,
								image_path: imageRows.flatMap((r) => r).find((r) => r.id === values.imagePath)
									?.imagePath
							}
						]
					},
					refetchQueries: [
						{
							query: GetClassesDocument,
							variables: {
								teacherId: teacher.teacher_id
							}
						}
					],
					update: (cache) => {
						// Evict on `teacher_by_pk` since the classes are queried using the teacher.class_teacher.class relation
						cache.evict({ id: 'ROOT_QUERY', fieldName: 'teacher_by_pk' })
						cache.gc()
					}
				})
				const classId = createdClass.data?.insert_class?.returning[0].class_id
				if (!classId) throw new Error('Id not created')
				handleSave(classId)
			} catch (error) {
				// TODO: handle errors on save
				console.error({ error })
			}
		}
	})

	useEffect(() => {
		if (formik.values.gradeId === undefined) {
			formik.setFieldTouched('gradeId')
		}

		if (formik.values.maturityLevel === undefined) {
			formik.setFieldTouched('maturityLevel')
		}

		if (formik.values.gradingSystem.label === undefined) {
			formik.setFieldTouched('gradingSystem')
		}
	}, [formik.values])

	const popOverLabel = ( // TODO: Contact us link
		<Typography>
			For your convenience, the WURRLY team has rated the music catalog based on the song lyrics. While the
			entire catalog excludes explicit lyrics, the Mature catalog may contain songs with references to sex,
			drugs, alcohol or violence. WURRLY takes no responsibility for such ratings so please check suitability
			for your Classes. Something doesn't look right? <ContactButton />
		</Typography>
	)

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<TextInput
					data-cy={getCyKey(ClassForm, TextInput.name)}
					name="Name"
					placeholder="Enter the name of your Class"
					isRequired
					value={formik.values.name}
					onChange={(e) => formik.setFieldValue('name', e.target.value)}
					onFocus={() => formik.setFieldTouched('name')}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
				/>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Class Date</b>
						</Typography>
						<Typography className={styles.textDefault} color="textSecondary">
							{
								'If no dates are selected, your class will automatically span one month from its creation date'
							}
						</Typography>
					</Box>

					<Box display="flex" justifyContent="space-between">
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<KeyboardDatePicker
								style={{ flexGrow: 1, marginRight: 15 }}
								margin="normal"
								id="initDate"
								label="Start Date"
								format="ddd, DD MMM yyyy"
								value={formik.values.initDate}
								onChange={(_, value) => {
									formik.setFieldValue('initDate', moment(value))
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
								onBlur={() => formik.setFieldTouched('initDate')}
								helperText={formik.touched.initDate && formik.errors.initDate}
								error={formik.touched.initDate && Boolean(formik.errors.initDate)}
							/>
						</MuiPickersUtilsProvider>

						<Typography variant="h5" className={styles.textDefault} style={{ alignSelf: 'center' }}>
							{'‒'}
						</Typography>

						<MuiPickersUtilsProvider utils={MomentUtils}>
							<KeyboardDatePicker
								style={{ flexGrow: 1, marginLeft: 15 }}
								margin="normal"
								id="endDate"
								label="End Date"
								format="ddd, DD MMM yyyy"
								value={formik.values.endDate}
								onChange={(_, value) => {
									formik.setFieldValue('endDate', moment(value))
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
								onBlur={() => formik.setFieldTouched('endDate')}
								helperText={formik.errors.endDate}
								error={formik.touched.endDate && Boolean(formik.errors.endDate)}
							/>
						</MuiPickersUtilsProvider>
					</Box>
				</Box>

				<DropdownInput
					data-cy={getCyKey(ClassForm, 'gradeDropdownInput')}
					name="Grade"
					isRequired
					placeholder="Select a Grade"
					options={gradesData?.grade ?? []}
					value={gradesData?.grade?.find((grade) => grade.grade_id.toString() === formik.values.gradeId)}
					onChange={(_, value: Grade | null) => {
						formik.setFieldValue('gradeId', value?.grade_id)
					}}
					onFocus={() => formik.setFieldTouched('gradeId')}
					getOptionLabel={(option) => option.name}
					inputProps={{
						error: formik.touched.gradeId && Boolean(formik.errors.gradeId),
						helperText: formik.touched.gradeId && formik.errors.gradeId
					}}
				/>

				<DropdownInput
					data-cy={getCyKey(ClassForm, 'subjectsDropdownInput')}
					name="Subject"
					placeholder="Select a Subject"
					options={subjects || []}
					value={subjects?.find((s) => s.catalog_item_id === +formik.values.subjectId)}
					onChange={(_, value: Catalog_Item | null) => {
						formik.setFieldValue('subjectId', value?.catalog_item_id)
					}}
					getOptionLabel={(option) => option.name}
				/>
				<DropdownInput
					data-cy={getCyKey(ClassForm, 'songCatalogTypeDropdownInput')}
					name="Song Catalog Type"
					placeholder="Select a Catalog Type"
					isRequired
					options={Object.keys(Maturity_Level_Enum).map((i) => ({ name: i }))}
					value={{ name: formik.values.maturityLevel }}
					onChange={(_, value: { name: string } | null) => {
						formik.setFieldValue('maturityLevel', value?.name)
					}}
					getOptionLabel={(option) => option.name}
					popOverLabel={popOverLabel}
					onFocus={() => formik.setFieldTouched('maturityLevel')}
					capitalize
					inputProps={{
						error: formik.touched.maturityLevel && Boolean(formik.errors.maturityLevel),
						helperText: formik.touched.maturityLevel && formik.errors.maturityLevel
					}}
				/>

				<DropdownInput
					data-cy={getCyKey(ClassForm, 'defaultGradingSystem')}
					name="Default Grading System"
					isRequired
					options={gradingSystem?.grading_system ?? []}
					value={formik.values?.gradingSystem}
					onChange={(_, value) => {
						formik.setFieldValue('gradingSystem', value ?? {})
					}}
					getOptionLabel={(option) => option.label ?? ''}
					inputProps={{
						error: formik.touched.gradingSystem && Boolean(formik.errors.gradingSystem?.label),
						helperText: formik.touched.gradingSystem && formik.errors.gradingSystem?.label
					}}
					onFocus={() => formik.setFieldTouched('gradingSystem')}
				/>

				<InputHeader name="Select an image" isRequired />
				{imageRows.map((row) => (
					<HorizontalGrid
						key={generateUniqueId()}
						optionSelected={formik.values.imagePath}
						options={row}
						handleSelectOption={(imagePath) => formik.setFieldValue('imagePath', imagePath)}
					/>
				))}
				<Box marginTop="35px" display="flex" justifyContent="space-between">
					<BigBaseButton
						data-cy={getCyKey(ClassForm, 'btnCancel')}
						style={{ padding: '6px 40px' }}
						variant="contained"
						color="default"
						onClick={handleCancel}>
						<Typography variant="caption">Cancel</Typography>
					</BigBaseButton>

					<BigBaseButton
						data-cy={getCyKey(ClassForm, 'btnCreateClass')}
						style={{ padding: '6px 50px' }}
						disabled={!formik.isValid || formik.isSubmitting}
						color="secondary"
						type="submit">
						<Typography variant="caption">Create Class</Typography>
					</BigBaseButton>
				</Box>
			</form>
		</Box>
	)
}
