import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import axios, { AxiosRequestConfig } from 'axios'
import { useLocation } from 'react-router-dom'
import { StudioReviewLocationState } from 'src/@types'
import { StickerElement } from 'src/components/ResizableElement/ResizableElement'
import { useReviewAndSubmitContext } from 'src/components/Studio/Hooks/useReviewAndSubmit'
import { VideoDimensions } from 'src/components/Studio/Preview/VideoPreview'
import { SelectedColorFilter } from 'src/components/Studio/Review/effects/ColorFilter/ColorFilter'
import { buildGenericContext } from 'src/context/genericContext'
import { useCustomCreateWurrlyMutation, useInsertStudentBadgeEventMutation } from 'src/graphql/autogenerate/hooks'
import {
	Privacy_Enum,
	Submission_Wurrly_State_Enum,
	Wurrly_Type_Enum,
	Filter_Frame,
	CustomCreateWurrlyFilterOptions,
	Badge_Event_Name_Enum
} from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { getPresignedUploadUrl, PresignedUrlType } from 'src/scenes/Teacher/mutations'
import { RECORD_SELECT_THUMBNAIL, DEFAULT_THUMBNAILS } from 'src/utils/constants'
import { FileTypeEnum } from 'src/utils/enums'
import { buildQueryVariables, getFramesFromVideo, getBlob } from 'src/utils/utils'

import { MAX_CAPTION_LENGTH } from '../../constants'

export type UploadThumbnailProps = {
	presignedUrl: string
	blob: Blob
}

type SelectedThumbnail = {
	default: boolean
	index: number
}

export type VideoPosition = {
	minX: number
	minY: number
	maxX: number
	maxY: number
}
const useVideoSubmit = () => {
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const { songId } = useWurrlyParams<typeof StudentPages.StudioReview.params[number]>()

	const location = useLocation()

	const {
		audioUrl,
		videoUrl,
		trackId,
		isVideoOn,
		selectedThumbnail: selectedThumbnailFromReview,
		audioBalance,
		uploadedVideoKey,
		setUploadError,
		uploadError,
		isVideoUploading,
		videoUploadProgress,
		classId,
		submissionId,
		challengeId,
		wurrlyType
	} = useReviewAndSubmitContext()

	const [caption, setCaption] = useState('')
	const [privacySetting, setPrivacySetting] = useState<Privacy_Enum>(Privacy_Enum.TeacherAndMe)
	const [videoThumbnails, setVideoThumbnails] = useState<string[]>([])
	const [processedThumbnails, setProcessedThumbnails] = useState<string[]>([])
	const [selectedThumbnail, setSelectedThumbnail] = useState<SelectedThumbnail>()
	const [getThumbnailPresignedUrl, thumbnailPresignedUploadUrl] = useLazyQuery<PresignedUrlType>(
		getPresignedUploadUrl,
		{ fetchPolicy: 'no-cache' }
	)
	const [isSubmittingWurrly, setIsSubmittingWurrly] = useState(false)
	const [uploadedWurrlyId, setUploadedWurrlyId] = useState<number>()
	const [validThumbnail, setValidThumbnail] = useState(false)
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
	const [isMirrored, setIsMirrored] = useState(false)
	const [selectedColorFilter, setSelectedColorFilter] = useState<SelectedColorFilter>()
	const [selectedFrameFilter, setSelectedFrameFilter] = useState<Filter_Frame>()
	const [selectedStickerFilter, setSelectedStickerFilter] = useState<StickerElement[]>([])
	const [updatedStickers, setUpdatedStickers] = useState<StickerElement[]>(selectedStickerFilter)
	const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 })
	const [videoDimmensions, setVideoDimmensions] = useState<VideoPosition>({
		minX: 0,
		minY: 0,
		maxX: 0,
		maxY: 0
	})
	const [insertWurrly] = useCustomCreateWurrlyMutation()
	const [badgeEvent] = useInsertStudentBadgeEventMutation()

	const getThumbnails = async () => {
		try {
			await getFramesFromVideo({
				videoSrc: videoUrl,
				framesCount: 4,
				width: RECORD_SELECT_THUMBNAIL.width,
				height: RECORD_SELECT_THUMBNAIL.height,
				setValues: setVideoThumbnails
			})
		} catch (error) {
			console.error(error)
		}
	}

	const uploadThumbnail = async ({ presignedUrl, blob }: UploadThumbnailProps) => {
		const options: AxiosRequestConfig = {
			method: 'put',
			data: blob,
			url: presignedUrl,
			headers: {
				'Content-Type': blob.type
			}
		}
		const result = await axios.request(options)
		if (result.status !== 200) {
			throw new Error(`Could not upload thumbnail ${result}`)
		}
	}

	const submitWurrly = async () => {
		if (!validThumbnail || !uploadedVideoKey) return
		let thumbnailUrl: string
		setIsSubmittingWurrly(true)

		try {
			if (!selectedThumbnail) {
				thumbnailUrl = selectedThumbnailFromReview
			} else if (selectedThumbnail.default) {
				thumbnailUrl = DEFAULT_THUMBNAILS[selectedThumbnail.index]
			} else {
				// Upload thumbnail from video
				const { url, key } = thumbnailPresignedUploadUrl.data?.presignedUrl ?? { url: '', key: '' }
				const blob = await getBlob(processedThumbnails[selectedThumbnail.index])
				await uploadThumbnail({ presignedUrl: url, blob })
				thumbnailUrl = key
			}

			const { data: uploadedWurrlyData } = await insertWurrly({
				variables: {
					studentIdOwner: studentId,
					studentIdSecondary: studentId,
					trackId,
					privacyId: privacySetting,
					resourcePath: uploadedVideoKey,
					imagePath: thumbnailUrl,
					classId,
					message: caption,
					wurrlyType,
					audioUrl,
					audioBalance,
					isMirrored,
					challengeId: challengeId === 0 ? undefined : challengeId,
					submission:
						wurrlyType !== Wurrly_Type_Enum.Assignment
							? undefined
							: {
									submissionId,
									submissionWurrlyState: Submission_Wurrly_State_Enum.Submitted
							  },
					filters: getFiltersParams()
				},
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'wurrly'
					})
					cache.evict({
						id: `submission:${submissionId}`,
						fieldName: 'state'
					})
					cache.gc()
				}
			})
			const wurrlyId = uploadedWurrlyData?.customCreateWurrly?.wurrlyId

			if (wurrlyId && typeof wurrlyId === 'number') {
				setUploadedWurrlyId(wurrlyId)
				if (challengeId !== 0)
					badgeEvent({
						variables: {
							studentId,
							eventName: Badge_Event_Name_Enum.ChallengeCompletion,
							classId,
							challengeId
						}
					})
				badgeEvent({
					variables: { studentId, eventName: Badge_Event_Name_Enum.SavedRecording, classId }
				})
			} else {
				throw new Error('Could not create wurrly')
			}
		} catch (error) {
			console.error('Could not submit wurrly:', error)
			setUploadError(true)
		}
		setIsSubmittingWurrly(false)
	}

	const removeSticker = (renderId: string) => {
		setSelectedStickerFilter((prev) => prev.filter((i) => i.renderId !== renderId))
		setUpdatedStickers((prev) => prev.filter((i) => i.renderId !== renderId))
	}

	const selectSticker = (sticker: StickerElement) => {
		const updateStickers = (prev: StickerElement[]) => {
			const stickers: StickerElement[] = []
			let prevSticker: StickerElement | null = null
			prev.forEach((i) => {
				if (i.renderId !== sticker.renderId) {
					stickers.push({ ...i, selected: false })
				} else {
					prevSticker = { ...i, selected: true }
				}
			})
			if (prevSticker !== null) stickers.push(prevSticker as StickerElement)

			return stickers
		}
		setSelectedStickerFilter(updateStickers)
		setUpdatedStickers(updateStickers)
	}

	const updateSticker = (sticker: StickerElement) => {
		setUpdatedStickers((prev) => {
			if (prev.find((i) => i.renderId === sticker.renderId))
				return prev.map((i) => (i.renderId === sticker.renderId ? sticker : i))
			setSelectedStickerFilter((prevStickers) => [...prevStickers, sticker])

			return [...prev, sticker]
		})
	}

	const unselectStickers = () => {
		setSelectedStickerFilter((prev) => prev.map((i) => ({ ...i, selected: false })))
	}

	const getFiltersParams = () => {
		const filtersParams: CustomCreateWurrlyFilterOptions = {}

		if (selectedFrameFilter?.filter_frame_id) {
			const frames = {
				filterFrameId: selectedFrameFilter?.filter_frame_id,
				frameEnum: selectedFrameFilter?.image_path
			}
			filtersParams.frames = frames
		}

		if (updatedStickers.length) {
			const stickers = updatedStickers.map((sticker) => {
				const { width, height, positionX, positionY, stickerId, imageUrl, rotation } = sticker

				return {
					width: Math.floor(width),
					height: Math.floor(height),
					positionX: Math.floor(positionX),
					positionY: Math.floor(positionY),
					rotation: Math.floor(rotation),
					stickerId,
					imageUrl
				}
			})
			filtersParams.stickers = {
				videoUrl: uploadedVideoKey || '',
				videoDisplayWidth: Math.floor(canvasDimensions.width),
				videoDisplayHeight: Math.floor(canvasDimensions.height),
				stickers
			}
		}

		if (selectedColorFilter) {
			const color = {
				filterColorId: selectedColorFilter.filter_color_id,
				filterColorName: selectedColorFilter.name
			}
			filtersParams.color = color
		}

		return filtersParams
	}
	const updateVideoDimensions = (dimenssions: VideoDimensions) => {
		setVideoDimmensions({
			minX: dimenssions.offsetLeft,
			maxX: dimenssions.offsetLeft + dimenssions.width,
			minY: dimenssions.offsetTop,
			maxY: dimenssions.offsetTop + dimenssions.height
		})
	}

	const addProcessedThumbnail = (url: string, index: number) =>
		setProcessedThumbnails((prev) => {
			const copy = [...prev]
			copy[index] = url

			return copy
		})

	useEffect(() => {
		if (!location.state) return
		const { color, frame, stickers } = location.state as StudioReviewLocationState
		if (color) setSelectedColorFilter(color)
		if (frame) setSelectedFrameFilter(frame)
		if (stickers?.length) {
			const unselectedSticker = stickers.map((i) => ({ ...i, selected: false }))
			setSelectedStickerFilter(unselectedSticker)
			setUpdatedStickers(unselectedSticker)
		}
	}, [location])

	useEffect(() => {
		if (videoUrl === '') return
		getThumbnails()
	}, [videoUrl])

	useEffect(() => {
		if (selectedThumbnail && !selectedThumbnail.default) {
			getThumbnailPresignedUrl(
				buildQueryVariables({
					filter: {
						type: FileTypeEnum.Images,
						conType: 'image/png',
						ext: 'png',
						isTemporal: false
					}
				})
			)
		}
	}, [selectedThumbnail])

	useEffect(() => {
		setValidThumbnail(!!selectedThumbnail || selectedThumbnailFromReview !== '')
	}, [selectedThumbnail, selectedThumbnailFromReview])

	useEffect(() => {
		setIsSubmitDisabled(
			isVideoUploading ||
				isSubmittingWurrly ||
				uploadError ||
				!validThumbnail ||
				caption.length > MAX_CAPTION_LENGTH
		)
	}, [isVideoUploading, isSubmittingWurrly, uploadError, !validThumbnail, caption])

	useEffect(() => {
		if (videoThumbnails.length) {
			setProcessedThumbnails(new Array(videoThumbnails.length).fill(''))
		}
	}, [videoThumbnails.length])

	return {
		caption,
		setCaption,
		privacySetting,
		setPrivacySetting,
		videoThumbnails,
		isVideoUploading,
		videoUploadProgress,
		uploadError,
		submitWurrly,
		selectedThumbnail,
		setSelectedThumbnail,
		isSubmittingWurrly,
		uploadSuccess: !!uploadedWurrlyId,
		uploadedWurrlyId,
		isVideoOn,
		validThumbnail,
		songId,
		classId,
		isSubmitDisabled,
		isMirrored,
		setIsMirrored,
		selectedColorFilter,
		setSelectedColorFilter,
		selectedFrameFilter,
		setSelectedFrameFilter,
		selectedStickerFilter,
		setSelectedStickerFilter,
		removeSticker,
		updateSticker,
		updatedStickers,
		setCanvasDimensions,
		unselectStickers,
		selectSticker,
		videoDimmensions,
		updateVideoDimensions,
		canvasDimensions,
		processedThumbnails,
		addProcessedThumbnail,
		wurrlyType
	}
}

export const [VideoSubmitProvider, useVideoSubmitContext] = buildGenericContext(useVideoSubmit)
