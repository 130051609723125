import { Fragment } from 'react'

import { Box } from '@material-ui/core'
import QrReader from 'react-qr-reader'

import { QRCode } from '../../../../../../../../assets/icons/CustomIcons'
import { BaseDialog } from '../../../../../../../../components'
import { DialogHeader } from '../../components/DialogHeader'
import { useStudentSignInOptionsStyles } from '../../styleStudentSignInOptions'
import { useStudentSignInOptionsContext } from '../../useStudentSignInOptions'
import { useQRCodeDialogStyles } from './stylesQRCodeDialog'

export const QRCodeDialog = () => {
	const genericStyles = useStudentSignInOptionsStyles()
	const qrCodeDialogStyles = useQRCodeDialogStyles()
	const { handleClose, setStudentCode } = useStudentSignInOptionsContext()

	return (
		<Fragment>
			<BaseDialog
				id="QRCodeDialog"
				className={`${genericStyles.baseDialog} ${qrCodeDialogStyles.qrContainer}`}
				open
				paperProps={{ className: genericStyles.paper }}
				onClose={handleClose}
				onDiscard={handleClose}
				dividers={false}
				header={<DialogHeader title="Please Scan Your QR Code" style={{ marginBottom: 0 }} />}
				isActionsHidden
				body={
					<Box
						height="345px"
						width="605px"
						position="relative"
						display="flex"
						justifyContent="center"
						alignItems="center">
						<QrReader
							delay={500}
							onScan={(code: string | null) => {
								if (code) {
									setStudentCode(code)
								}
							}}
							onError={() => {}}
							// onError={handleError}
						/>
						<QRCode fill="white" style={{ position: 'absolute' }} />
					</Box>
				}
			/>
		</Fragment>
	)
}
