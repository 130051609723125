import { createStyles, makeStyles } from '@material-ui/core'
import { alpha, Theme } from '@material-ui/core/styles'

export const useSearchbarStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative'
		},
		boxInput: {
			marginBottom: theme.spacing(3),
			marginTop: theme.spacing(3),
			backgroundColor: alpha(theme.palette.text.secondary, 0.1),
			border: ' 1px solid rgba(0,0,0,0)',
			padding: theme.spacing(0.3),
			'& .MuiPaper-root :focus': {
				backgroundColor: theme.palette.common.white,
				border: `1px solid ${alpha(theme.palette.text.secondary, 0.1)}`
			},
			paddingLeft: theme.spacing(1),
			display: 'flex',
			'& .MuiInputBase-root': {
				flex: 1
			}
		},
		boxInputFocus: {
			marginBottom: theme.spacing(3),
			padding: theme.spacing(0.3),
			marginTop: theme.spacing(3),
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${alpha(theme.palette.text.secondary, 0.1)}`,
			paddingLeft: theme.spacing(1),
			display: 'flex',
			'& .MuiInputBase-root': {
				flex: 1
			}
		},
		itemsList: {
			position: 'absolute',
			top: 65,
			left: 0,
			zIndex: 2,
			width: '100%',
			backgroundColor: theme.palette.common.white,
			maxHeight: 300,
			boxShadow: '-1px 15px 22px 5px rgb(0 0 0 / 33%)'
		},
		divider: {
			margin: '0 auto',
			width: '90%'
		},
		scrollable: {
			overflowY: 'scroll',
			boxShadow: '-1px 15px 22px 5px rgb(0 0 0 / 33%)'
		},
		noResultsFound: {
			backgroundColor: alpha(theme.palette.secondary.main, 0.2),
			padding: 15
		},
		loader: {
			display: 'flex',
			alignItems: 'center'
		}
	})
)

export default useSearchbarStyles
