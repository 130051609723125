import React from 'react'

import { Button, ButtonProps } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { getCyKey } from 'src/utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actionButton: {
			'& .MuiButton-root': {
				minWidth: 'auto',
				height: 40,
				width: 40,
				padding: theme.spacing(0.75),
				color: theme.palette.primary.main,
				'&.selected': {
					backgroundColor: fade(theme.palette.secondary.main, 0.26),
					color: theme.palette.secondary.dark
				},
				'&.disabled': {
					color: fade(theme.palette.primary.main, 0.26)
				}
			}
		},
		actionButtonLarge: {
			'& .MuiButton-root': {
				minWidth: 'auto',
				height: 56,
				width: 56,
				padding: theme.spacing(0.75),
				color: theme.palette.primary.main,
				'&:not(:last-child)': {
					marginRight: theme.spacing(1)
				},
				'&.selected': {
					backgroundColor: fade(theme.palette.secondary.main, 0.26),
					color: theme.palette.secondary.dark
				},
				'&.disabled': {
					color: fade(theme.palette.primary.main, 0.26)
				}
			}
		}
	})
)

export type ActionButtonProps = {
	icon: React.ReactNode
	selected?: boolean
	size?: string
} & ButtonProps

export const ActionButton = ({
	onClick,
	disabled,
	selected,
	className,
	icon,
	size,
	...props
}: ActionButtonProps) => {
	const classes = useStyles()

	return (
		<div className={size === 'large' ? classes.actionButtonLarge : classes.actionButton}>
			<Button
				data-cy={getCyKey(ActionButton, className)}
				className={clsx({ disabled: !!disabled, selected: !!selected }, className)}
				variant="contained"
				color="default"
				disabled={disabled}
				onClick={onClick}
				{...props}>
				{icon}
			</Button>
		</div>
	)
}
