import { useEffect, useState } from 'react'

import { Typography } from '@material-ui/core'
import { ActivateAccount } from 'src/components/ActivateAccount/ActivateAccount'
import { InfoDialog } from 'src/components/Dialogs/InfoDialog'
import { useSendTeacherActivationEmailLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'

export type AppVersion = {
	version: string
	created_at: string
}

export const AccountActivationContainer = () => {
	const { teacherData: teacher } = useLoginContext()
	const [errorMessage, setErrorMessage] = useState<string>()
	const activeTeacher = teacher.is_active
	const [send, setSend] = useState(false)
	const [sendEmail, { data, loading }] = useSendTeacherActivationEmailLazyQuery()
	const [openActivate, setOpenActivate] = useState(true)

	const handleActivate = async () => {
		setSend(true)
	}

	useEffect(() => {
		if (!loading && send) sendEmail({ variables: {} })
	}, [send, data, loading])

	useEffect(() => {
		if (!data?.sendTeacherActivationEmail?.success && !loading && send) {
			setErrorMessage(String('Please try again.'))
		}
	}, [data])

	useEffect(() => {
		if (data?.sendTeacherActivationEmail?.success) {
			setTimeout(() => {
				setOpenActivate(false)
			}, 3000)
		}
	}, [data])

	return (
		<>
			{!activeTeacher && openActivate && (
				<ActivateAccount
					handleActivate={handleActivate}
					loading={loading}
					success={data?.sendTeacherActivationEmail?.success as boolean}
				/>
			)}
			<InfoDialog
				icon="error"
				open={!!errorMessage}
				title="Something went wrong"
				body={<Typography>{errorMessage}</Typography>}
				onDiscard={() => setErrorMessage('')}
				discardLabel="close"
			/>
		</>
	)
}
