import { useCallback, useEffect, useState } from 'react'

import { buildGenericContext } from '../../../../../../context/genericContext'
import { useGetStudentsByClassQuery } from '../../../../../../graphql/autogenerate/hooks'
import { Order_By, Student } from '../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../hooks/useLogin'
import { useWurrlyParams } from '../../../../../../hooks/useWurrlyParams'
import { Pages } from '../../../../../../routes/teacherPages'
import { MAX_LIST_ITEMS } from '../../../../../../utils'

const useClassStudents = () => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.ClassStudent.params[number]>()
	const classId = Number(params.classId)
	const teacherId = teacher.teacher_id
	const [count, setCount] = useState(0)
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [sort, setSort] = useState(Order_By.Asc)
	const [loading, setLoading] = useState(true)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(false)
	const [students, setStudents] = useState<Student[]>([])

	const INITIAL_QUERY_VARIABLES = {
		classId,
		teacherId,
		limit,
		offset: 0
	}

	const {
		data,
		loading: queryLoading,
		fetchMore,
		refetch
	} = useGetStudentsByClassQuery({
		variables: INITIAL_QUERY_VARIABLES,
		fetchPolicy: 'no-cache'
	})

	const handleRefetch = useCallback(() => {
		refetch({
			...INITIAL_QUERY_VARIABLES,
			sortOrder: sort
		})
	}, [sort])

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [students])

	useEffect(() => {
		if (queryLoading) setLoading(true)
		if (!queryLoading && data) {
			setStudents((data.student || []) as Student[])
			setCount(data.student_aggregate?.aggregate?.count ?? 0)
		}
	}, [queryLoading, data])

	useEffect(() => {
		if (!isFetching) return
		if (!queryLoading && fetchMore) {
			const currentLength = students.length
			fetchMore({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				const fetchMoreResultLength = fetchMoreResult.data?.student?.length || 0
				if (fetchMoreResultLength !== 0) {
					const newLimit = currentLength + fetchMoreResultLength
					setLimit(newLimit)
					refetch({ limit: newLimit, sortOrder: sort })
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	return {
		teacher,
		teacherId,
		classId,
		sort,
		setSort,
		limit,
		loading,
		students,
		count,
		moreResults,
		isFetching,
		refetch,
		handleRefetch
	}
}

export const [ClassStudentsProvider, useClassStudentsContext] = buildGenericContext(useClassStudents)
