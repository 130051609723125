import { useEffect, useState } from 'react'

import { Box, Grid, styled } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { AVATARS, buildImagePath, getFirstLetters } from 'src/utils'

import useStyles from './AvatarList.styles'
import { StudentAvatar } from './StudentAvatar'

type AvatarType = {
	stageName: string
	setSelectedAvatarImg: React.Dispatch<React.SetStateAction<string | null>>
	initialAvatarImg: string | undefined | null
	handleExtraOptionClick?: () => void
	handleFile?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const AvatarList = ({
	stageName,
	setSelectedAvatarImg,
	initialAvatarImg,
	handleExtraOptionClick,
	handleFile
}: AvatarType) => {
	const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<number | null>(null)
	const stageNameInitials = getFirstLetters(stageName)
	const styles = useStyles()

	useEffect(() => {
		if (initialAvatarImg) {
			AVATARS.forEach((avatar, index) => {
				if (buildImagePath(avatar) === initialAvatarImg) setSelectedAvatarIndex(index)
			})
		}
	}, [initialAvatarImg])

	return (
		<StyledGrid container spacing={5}>
			<Grid item>
				<StudentAvatar
					stageNameInitials={stageNameInitials}
					isSelected={selectedAvatarIndex === null}
					onClickHandler={() => {
						setSelectedAvatarIndex(null)
						setSelectedAvatarImg(null)
					}}
				/>
			</Grid>
			{/* Omit the last option if showing an extra option so it fits */}
			{AVATARS.slice(0, AVATARS.length - +!!handleExtraOptionClick).map((avatar, index) => (
				<Grid key={avatar} item>
					<StudentAvatar
						imgUrl={buildImagePath(avatar)}
						isSelected={selectedAvatarIndex === index}
						onClickHandler={() => {
							setSelectedAvatarIndex(index)
							setSelectedAvatarImg(buildImagePath(avatar))
						}}
					/>
				</Grid>
			))}
			{handleExtraOptionClick && (
				<Grid item>
					<>
						<input
							// accept={getFileType()}
							className={styles.fileInput}
							id="contained-button-file"
							type="file"
							onChange={handleFile}
						/>
						<label htmlFor="contained-button-file">
							<Box component="form" className={styles.styledFab} style={{ cursor: 'pointer' }}>
								<AddIcon />
							</Box>
						</label>
					</>
				</Grid>
			)}
		</StyledGrid>
	)
}

const StyledGrid = styled(Grid)({
	display: 'flex',
	justifyContent: 'space-around'
})
