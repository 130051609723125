import { useEffect, useMemo, useRef } from 'react'

import { Box, Button, CircularProgress, Container, Divider, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useMeasure } from 'react-use'
import { ListSongs } from 'src/components'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useElementScrollPercentage } from 'src/hooks/useScroll'
import { scrollToTop } from 'src/utils'

import { SongFilter, SongFilterProps } from '../SongFilter/SongFilter'
import useStyles from './SongListAndFilter.styles'

type SongListProps = {
	chords: string[]
	showTransposed: boolean
	songs: Song[]
	overrideSongClick: (classId: number, songId: number) => void
	classId: number
}
type SongListAndFilterProps = SongListProps &
	SongFilterProps & {
		isLoading: boolean
		fetchMore: () => void
		searchString: string
		moreResults: boolean
	}
export const SongListAndFilter = ({
	chords,
	showTransposed,
	songs,
	toggleVocalGuide,
	toggleChordGuide,
	withVocalGuide,
	withChordGuide,
	selectedFilters,
	setSelectedFilters,
	scale,
	setScale,
	isLoading,
	overrideSongClick,
	selectedCatalog,
	fetchMore,
	moreResults,
	searchString,
	classId
}: SongListAndFilterProps) => {
	const scrollRef = useRef<HTMLElement>(null)
	const [songFilterRef, { height: songFilterHeight }] = useMeasure()
	const { scrollLevel } = useElementScrollPercentage(scrollRef)
	const styles = useStyles({ maxHeightPx: songFilterHeight })
	const isOverlayVisible = useMemo(
		() => scrollLevel < 95 || moreResults || isLoading,
		[scrollLevel, moreResults, isLoading]
	)

	useEffect(() => {
		if (scrollLevel >= 95 && moreResults && !isLoading) fetchMore()
	}, [scrollLevel])

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={9}>
				{!!songs.length && (
					<Box className={styles.overlayContainer}>
						<Container
							className={styles.songsContainer}
							ref={scrollRef as React.MutableRefObject<HTMLDivElement>}>
							<ListSongs
								teacherId={NaN}
								classId={classId}
								selectedChords={chords}
								allTranspositions={showTransposed}
								studioButton
								spacing={1.5}
								data={songs}
								overrideOnClick={overrideSongClick}
							/>
						</Container>
						<Box className={clsx(styles.overlay, { [styles.overlayHidden]: !isOverlayVisible })} />
					</Box>
				)}
				{isLoading && (
					<Box m={3} textAlign="center">
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
				{!isLoading && !!songs.length && !moreResults && (
					<Box className={styles.buttonContainer}>
						<Button variant="text" color="secondary" onClick={scrollToTop}>
							Back to Top
						</Button>
					</Box>
				)}
				{!isLoading && !songs.length && (
					<Box>
						<Divider />
						<Box my={4}>
							<Typography color="textSecondary" variant="h6">
								{searchString && !selectedFilters.length
									? `Your search for "${searchString}" did not return any results.`
									: 'No results found'}
							</Typography>
							<Typography color="textSecondary">
								{searchString && !selectedFilters.length
									? `Remember it's easy to upload your own by clicking the button on the top right!`
									: 'Your filter selection did not return any results'}
								<br />
								{!!selectedFilters.length && 'Try removing a filter'}
							</Typography>
						</Box>
					</Box>
				)}
			</Grid>
			<Grid item xs={12} md={3}>
				<SongFilter
					toggleVocalGuide={toggleVocalGuide}
					toggleChordGuide={toggleChordGuide}
					withVocalGuide={withVocalGuide}
					withChordGuide={withChordGuide}
					selectedFilters={selectedFilters}
					setSelectedFilters={setSelectedFilters}
					scale={scale}
					setScale={setScale}
					selectedCatalog={selectedCatalog}
					paperProps={{ ref: songFilterRef }}
				/>
			</Grid>
		</Grid>
	)
}
