import { createStyles, makeStyles } from '@material-ui/core'

export const useStepOneFormStyles = makeStyles(() =>
	createStyles({
		title: {
			fontSize: 18,
			maxWidth: 500,
			marginBottom: 20
		},

		margin: { marginTop: '10px' },
		position: { position: 'relative' },
		paper: {
			padding: '30px',
			width: '100%',
			height: '100%'
		},
		playerWrapper: {
			height: '400px',
			'& video': {
				borderRadius: '4px'
			}
		},
		player: {
			maxHeight: '400',
			width: '100%'
		},
		noVideosText: { marginTop: 15, marginBottom: 40 }
	})
)

export default useStepOneFormStyles
