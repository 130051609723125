import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core'

export const useDebouncedAutocompleteStyles = makeStyles((theme: Theme) =>
	createStyles({
		chip: {
			color: theme.palette.background.default,
			backgroundColor: theme.palette.text.secondary,
			borderRadius: '4px',
			marginRight: '5px',
			marginTop: '5px'
		},
		closeChipIcon: {
			color: alpha(theme.palette.background.default, 0.6),
			height: '26px',
			width: '18px',
			marginLeft: '3px',
			'& .MuiChip-deleteIcon:hover': {
				color: theme.palette.background.default
			}
		},
		listBox: {
			padding: 0,
			'& .MuiAutocomplete-option': {
				'&[aria-selected=true], &[data-focus=true]': {
					backgroundColor: 'rgba(243, 171, 52, 0.12)',
					color: '#F3AB34',
					'&:hover': {
						backgroundColor: 'rgba(243, 171, 52, 0.25)'
					}
				},
				'&:hover': {
					backgroundColor: 'rgba(243, 171, 52, 0.25)'
				}
			}
		}
	})
)

export default useDebouncedAutocompleteStyles
