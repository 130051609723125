import React, { useState } from 'react'

import { QueryResult } from '@apollo/client'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useGetFavoriteVideosNotInClassQuery,
	useGetTipPlayListsByClassQuery,
	useGetTipsByStudentClassQuery
} from 'src/graphql/autogenerate/hooks'
import {
	GetFavoriteVideosNotInClassQuery,
	GetFavoriteVideosNotInClassQueryVariables,
	GetTipPlayListsByClassQuery,
	GetTipPlayListsByClassQueryVariables,
	GetTipsByStudentClassQuery,
	GetTipsByClassQueryVariables,
	GetTipsByStudentClassQueryVariables
} from 'src/graphql/autogenerate/operations'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { MAX_LIST_ITEMS } from 'src/utils'

export type useClassVideosType = {
	videosSavedVariables: GetTipsByClassQueryVariables
	setVideosSavedVariables: React.Dispatch<React.SetStateAction<GetTipsByClassQueryVariables>>
	videosSavedQuery: QueryResult<GetTipsByStudentClassQuery, GetTipsByStudentClassQueryVariables>

	videosFavoritesVariables: GetFavoriteVideosNotInClassQueryVariables
	setVideosFavoritesVariables: React.Dispatch<React.SetStateAction<GetFavoriteVideosNotInClassQueryVariables>>
	videosFavoritesQuery: QueryResult<GetFavoriteVideosNotInClassQuery, GetFavoriteVideosNotInClassQueryVariables>

	videosPlaylistVariables: GetTipPlayListsByClassQueryVariables
	setVideosPlaylistVariables: React.Dispatch<React.SetStateAction<GetTipPlayListsByClassQueryVariables>>
	videosPlaylistQuery: QueryResult<GetTipPlayListsByClassQuery, GetTipPlayListsByClassQueryVariables>

	classId: number
	teacherId: number
}

const useClassVideos = (): useClassVideosType => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.ClassVideos.params[number]>()
	const classId = Number(params.classId)
	const teacherId = teacher.teacher_id
	const limit = MAX_LIST_ITEMS

	const INITIAL_QUERY_VARIABLES = {
		teacherId,
		classId,
		limit
	}

	const [videosSavedVariables, setVideosSavedVariables] = useState<GetTipsByClassQueryVariables>({
		...INITIAL_QUERY_VARIABLES
	})
	const [videosFavoritesVariables, setVideosFavoritesVariables] =
		useState<GetFavoriteVideosNotInClassQueryVariables>({
			...INITIAL_QUERY_VARIABLES
		})
	const [videosPlaylistVariables, setVideosPlaylistVariables] = useState<GetTipPlayListsByClassQueryVariables>({
		...INITIAL_QUERY_VARIABLES
	})

	const videosSavedQuery = useGetTipsByStudentClassQuery({ variables: videosSavedVariables })
	const videosFavoritesQuery = useGetFavoriteVideosNotInClassQuery({ variables: videosFavoritesVariables })
	const videosPlaylistQuery = useGetTipPlayListsByClassQuery({
		variables: {
			classId: videosPlaylistVariables.classId,
			limit: videosPlaylistVariables.limit
		}
	})

	return {
		videosSavedVariables,
		setVideosSavedVariables,
		videosSavedQuery,
		videosFavoritesVariables,
		setVideosFavoritesVariables,
		videosFavoritesQuery,
		videosPlaylistVariables,
		setVideosPlaylistVariables,
		videosPlaylistQuery,
		classId,
		teacherId
	}
}

export const [ClassVideosProvider, useClassVideosContext] = buildGenericContext(useClassVideos)
