import { PagesProps } from 'src/@types'
import { MainTitle } from 'src/components'
import { AssignmentsDetails } from 'src/scenes/Student/scenes/6-Assignments/components/AssignmentsDetails/AssignmentsDetailsContent.container'

export const Assignments = ({ page }: PagesProps) => {
	return (
		<>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Manage all your Assignments in one easy place."
				rightActions={[]}
			/>
			<AssignmentsDetails />
		</>
	)
}
