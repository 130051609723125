import { TextField, TextFieldProps } from '@material-ui/core'

import useStyles from './TextInput.styles'

const TextInput = (props: TextFieldProps) => {
	const styles = useStyles()

	return <TextField variant="outlined" color="secondary" className={styles.textField} {...props} />
}

export default TextInput
