import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { MainTitle } from 'src/components'
import { useGetClassByPkQuery } from 'src/graphql/autogenerate/hooks'
import { useClassSongsContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/useClassSongs'
import { buildBreadCrumbs, slugToName } from 'src/utils'

export const ClassSongsTitle = ({ page }: PagesProps) => {
	const { classId } = useClassSongsContext()
	const { data: classData } = useGetClassByPkQuery({ variables: { classId } })

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.class_by_pk?.title || '')
			},
			{
				index: 2,
				overrideName: 'Songs'
			}
		],
		params: {
			classId
		},
		isStudentPage: true
	})

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title="Class Songs"
				description="These are the Songs that have been added to this Class so students can easily find them in their Music Catalog"
			/>
		</Box>
	)
}
