import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { noop } from 'lodash'
import { ActivityBoxEmpty, MainTitle } from 'src/components'
import { ImageButton } from 'src/components/Buttons/ImageButton'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { Playlist, Product_Name_Enum, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { AddPlaylistToClass } from 'src/scenes/Teacher/scenes/8-Videos/components/AddPlaylistToClass'
import { PlaylistCardContainer } from 'src/scenes/Teacher/scenes/8-Videos/scenes/Playlist/components/PlaylistContainer'
import useChannelPageStyles from 'src/scenes/Teacher/scenes/channels/ChannelPage.styles'
import { useChannelPage } from 'src/scenes/Teacher/scenes/channels/useChannelPage'

import { CartIcon } from '../../../../assets/icons/CustomIcons'

export const ChannelPage = () => {
	const styles = useChannelPageStyles()
	const {
		playlistData,
		handleOnPlay,
		visibility,
		subscription,
		handleOpenProductDialog,
		handleCloseProductDialog,
		isOpen,
		expiresAt,
		showExpiresAt,
		addToClassDialog,
		setAddToClassDialog,
		isAddPlaylistToClassOpen,
		setIsAddPlaylistToClassOpen,
		product,
		setProduct,
		breadcrumbs,
		channelData,
		loadingChannel,
		channelId
	} = useChannelPage()

	return (
		<Box>
			<>
				{isOpen && product && (
					<SelectProductDialog
						onSuccess={noop}
						productId={product.id}
						productName={product.productName}
						handleCloseProductDialog={handleCloseProductDialog}
					/>
				)}
				<AddPlaylistToClass
					isOpen={isAddPlaylistToClassOpen}
					setIsOpen={setIsAddPlaylistToClassOpen}
					playlist={addToClassDialog}
				/>
				<MainTitle
					breadcrumbs={breadcrumbs}
					title={channelData?.channel[0]?.title ?? ''}
					rightActions={
						visibility === Visibility_Enum.ForSale
							? subscription
								? [
										{
											variant: 'contained',
											className: 'subscribed',
											style: {
												backgroundColor: '#BDEAD7',
												border: '2px solid #ADCEC1',
												borderRadius: ' 4px'
											},
											startIcon: <CheckBoxOutlinedIcon style={{ color: '#000000' }} />,
											name: 'SUBSCRIBED'
										}
								  ]
								: [
										{
											variant: 'contained',
											startIcon: <CartIcon style={{ color: '#221F31' }} />,
											onClick: () => {
												setProduct({
													id: channelId,
													productName: Product_Name_Enum.Channel
												})
												handleOpenProductDialog()
											},
											name: 'SUBSCRIBE TO CHANNEL',
											style: {
												minWidth: '172px',
												background:
													'linear-gradient(256.66deg, #FFC20C 21.57%, #EDA42B 61.82%)'
											}
										}
								  ]
							: []
					}
				/>
				{showExpiresAt && (
					<Typography
						variant="overline"
						style={{
							fontSize: '14px'
						}}>
						Subscription expires on:{' '}
						<b
							style={{
								color: '#FFC20C'
							}}>
							{expiresAt} days
						</b>
					</Typography>
				)}
				<ImageButton
					backgroundImageUrl={channelData?.channel[0]?.image_path || ''}
					className={styles.cover}
					hideTitles
					disableRipple
					cursor="default"
				/>
				<Box>
					<Grid container justifyContent="space-between" wrap="nowrap" style={{ minHeight: 380 }}>
						<Grid xs={5} className={styles.column} item>
							<Typography variant="h4">
								<b>{channelData?.channel[0]?.heading_short_description}</b>
							</Typography>
							{channelData?.channel[0]?.short_description ? (
								<Typography style={{ wordBreak: 'break-word' }} variant="h6">
									{channelData?.channel[0]?.short_description || ''}
								</Typography>
							) : (
								<ActivityBoxEmpty
									description="No brief description has been provided"
									className={styles.videoBox}
								/>
							)}
						</Grid>

						{channelData?.channel?.[0].video_path ? (
							<ReactCustomVideoPlayer
								width={540}
								height={320}
								onPlay={handleOnPlay}
								light={channelData?.channel[0]?.video_thumbnail_image_path || ''}
								style={{
									flexDirection: 'row-reverse',
									display: 'flex'
								}}
								config={
									{
										file: {
											attributes: {
												disablepictureinpicture: 'true',
												controlsList: 'nodownload'
											}
										}
									} as unknown as Record<string, string>
								}
								url={channelData?.channel[0]?.video_path || ''}
							/>
						) : (
							<ActivityBoxEmpty
								description="No video has been provided"
								className={styles.videoBox}
							/>
						)}
					</Grid>
				</Box>
				<Typography variant="h4" className={styles.about}>
					<b>{channelData?.channel[0]?.heading_description}</b>
				</Typography>
				<Typography variant="h6">{channelData?.channel[0]?.description || ''}</Typography>

				<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
					<Typography variant="h6">
						<b>Playlists</b>
					</Typography>
					<div style={{ flexGrow: 1 }} />
				</Box>
				<Grid container spacing={3}>
					<Grid item xs>
						{!loadingChannel ? (
							playlistData.map((playlist) => (
								<Box marginBottom={'15px'} marginTop={'15px'} key={playlist.playlist_id}>
									<PlaylistCardContainer
										playlist={playlist as Playlist}
										key={playlist.playlist_id}
										elevation={0}
										btnSubscriptions={{
											onClick: (e) => {
												e.stopPropagation()
												setProduct({
													id: playlist.playlist_id,
													productName: Product_Name_Enum.Playlist
												})
												handleOpenProductDialog()
											}
										}}
										btnAddClass={{
											onClick: (e) => {
												e.stopPropagation()
												setAddToClassDialog(playlist)
												setIsAddPlaylistToClassOpen(true)
											}
										}}
									/>
								</Box>
							))
						) : (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
						{!loadingChannel && !!playlistData.length && (
							<Box mt={4} textAlign="center">
								<Button
									onClick={() => {
										window.scrollTo(0, 0)
									}}
									variant="text"
									color="secondary">
									Back to Top
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>
			</>
		</Box>
	)
}
