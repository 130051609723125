import {
	Avatar,
	Box,
	ButtonProps,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Typography,
	TypographyProps
} from '@material-ui/core'
import clsx from 'clsx'
import { ExclamationIcon, QuestionIcon, SuccessIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components/Buttons/BaseButton'

import useStyles from './InfoDialog.styles'

type ActionButton = ButtonProps & {
	label?: string
	onClick: () => void
	disabled?: boolean
}
export type InfoDialogProps = DialogProps & {
	icon: React.ReactNode | 'error' | 'warning' | 'success' | 'question'
	title?: string | React.ReactNode
	body?: string | React.ReactNode
	titleProps?: TypographyProps
	bodyProps?: TypographyProps
	discardButton?: ActionButton
	confirmButton?: ActionButton
	loading?: boolean
	footer?: React.ReactNode
}
export const InfoDialog = ({
	title,
	icon,
	body,
	titleProps,
	bodyProps,
	discardButton,
	confirmButton,
	loading,
	footer,
	...props
}: InfoDialogProps) => {
	const styles = useStyles()

	const renderIcon =
		icon === 'error' ? (
			'x'
		) : icon === 'warning' ? (
			<ExclamationIcon />
		) : icon === 'success' ? (
			<SuccessIcon />
		) : icon === 'question' ? (
			<QuestionIcon />
		) : (
			icon
		)

	return (
		<Dialog fullWidth maxWidth="xs" {...props}>
			<DialogTitle disableTypography className={styles.dialogTitle}>
				<Avatar className={styles.icon}>{renderIcon}</Avatar>
				<Typography variant="h6" align="center" {...titleProps}>
					<b>{title}</b>
				</Typography>
			</DialogTitle>
			<DialogContent className={styles.horizontalPadding}>
				<Typography align="center" {...bodyProps}>
					{body}
				</Typography>
			</DialogContent>
			<DialogActions className={clsx(styles.actions, styles.horizontalPadding)}>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					{discardButton && (
						<Box className={styles.actionButton}>
							<BaseButton onClick={discardButton.onClick} disabled={discardButton.disabled}>
								{discardButton.label || ''}
							</BaseButton>
						</Box>
					)}
					{confirmButton && (
						<Box className={styles.actionButton}>
							<BaseButton
								color="secondary"
								disabled={confirmButton.disabled || loading}
								isLoading={!!loading}
								{...confirmButton}>
								{confirmButton.label || ''}
							</BaseButton>
						</Box>
					)}
				</Box>
			</DialogActions>
			{footer && <Box className={styles.footer}>{footer}</Box>}
		</Dialog>
	)
}
