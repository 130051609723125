import { Box } from '@material-ui/core'
import { DoughnutCharts } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAnalytics/components/DoughnutCharts'

import { PagesProps } from '../../../../../../@types'
import { BOX_PADDING } from '../../../../../../styles/constants'
import { ClassAnalyticsProvider } from '../../../../hooks/useClassAnalytics'
import { ClassAnalyticsCountCards } from './components/ClassAnalyticsCountCards'
import { ClassAnalyticsTitle } from './components/ClassAnalyticsTitle'
import { ClassStudentProductivity } from './components/ClassStudentProductivity'

export const ClassAnalytics = (props: PagesProps) => {
	return (
		<Box>
			<ClassAnalyticsProvider>
				<ClassAnalyticsTitle {...props} />
				<Box style={{ paddingTop: '60px' }} p={BOX_PADDING}>
					<ClassStudentProductivity />
					<ClassAnalyticsCountCards />
					<DoughnutCharts />
				</Box>
			</ClassAnalyticsProvider>
		</Box>
	)
}
