import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { Chord, InstrumentsGuideType } from 'src/@types'
import { NOTES_SIZE } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/constants'
import { GuitarDiagram } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/GuitarDiagram'
import { KeyboardDiagram } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/KeyboardDiagram'
import { UkuleleDiagram } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/UkuleleDiagram'

import useStyles from './Note.styles'

type NoteProps = {
	chord: Chord
	guide: InstrumentsGuideType
	time: number
	showDiagram?: boolean
	highLight?: boolean
	xPos?: number
	lyrics?: boolean
}

export const Note = ({ chord, guide, showDiagram, highLight, xPos = 0, lyrics }: NoteProps) => {
	const styles = useStyles({ xPos })
	const noteWidth =
		guide === InstrumentsGuideType.guitar
			? NOTES_SIZE.guitar
			: guide === InstrumentsGuideType.ukulele
			? NOTES_SIZE.ukulele
			: NOTES_SIZE.piano

	return (
		<Box
			alignItems="center"
			className={showDiagram ? '' : clsx({ [styles.onlyChords]: !lyrics, [styles.noteChord]: lyrics })}
			display="flex"
			flexDirection="column">
			{showDiagram && (
				<Box style={{ width: noteWidth }}>
					{guide === InstrumentsGuideType.guitar && (
						<GuitarDiagram chord={chord} isHighlighted={highLight} />
					)}
					{guide === InstrumentsGuideType.ukulele && (
						<UkuleleDiagram chord={chord} isHighlighted={highLight} />
					)}
					{guide === InstrumentsGuideType.piano && (
						<KeyboardDiagram chord={chord} isHighlighted={highLight} />
					)}
				</Box>
			)}
			{guide !== InstrumentsGuideType.audio && (
				<Box style={{ color: '#FE5935', textAlign: 'left', fontWeight: 'bold' }}>{chord.name}</Box>
			)}
		</Box>
	)
}
