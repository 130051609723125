import { Box, styled } from '@material-ui/core'

export const BackgroundContainer = styled(Box)({
	position: 'relative',
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center'
})

export const ErrorContainer = styled(Box)(({ theme }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: theme.palette.error.main
}))
