import { Box, Container, Typography } from '@material-ui/core'
import { WurrlyLogoIcon } from 'src/assets/icons'
import { BaseDialog, FixedBackground } from 'src/components'
import { WurrliesGrid } from 'src/components/Grids/WurrliesGrid'
import { StudentDetailProvider, useStudentDetailContext } from 'src/scenes/Student/1-Home/hooks/useStudentDetail'
import { ChooseBestTakeFooter } from 'src/scenes/Student/scenes/StudentStudio/scenes/StudentChooseBestTake/components/ChooseBestTakeFooter'
import { useChooseBestTakeStyles } from 'src/scenes/Student/scenes/StudentStudio/scenes/StudentChooseBestTake/stylesChooseBestTake'

export const ChooseBestTake = () => {
	const styles = useChooseBestTakeStyles()

	return (
		<StudentDetailProvider>
			<FixedBackground
				logo={<WurrlyLogoIcon className={styles.logoPosition} />}
				className={styles.backgroundImage}>
				<BaseDialog
					maxWidth="xl"
					open
					onClose={() => {}}
					bodyProps={{
						className: styles.dialogContent
					}}
					className={styles.baseDialog}
					header={
						<Typography variant="h3" style={{ marginTop: '26px' }}>
							Choose Your Best Take!
						</Typography>
					}
					body={
						<Box>
							<Typography className={styles.subtitle}>
								Select your best performance and Submit your Assignment!
							</Typography>
							<Container className={styles.centerContainer}>
								<TakesGrid />
								<ChooseBestTakeFooter />
							</Container>
						</Box>
					}
				/>
			</FixedBackground>
		</StudentDetailProvider>
	)
}

export const TakesGrid = () => {
	const { wurrlies, loading } = useStudentDetailContext()

	return <WurrliesGrid selectable={true} wurrlies={wurrlies} loading={loading} />
}
