import {SkillCoveredBySavedLessons} from 'src/components/SkillCoveredBySavedLessons/SkillCoveredBySavedLessons'
import { useClassAnalyticsContext } from 'src/scenes/Teacher/hooks/useClassAnalytics'

export const DoughnutCharts = () => {
	const { chartStandardsData, chartSkillsData } = useClassAnalyticsContext()

	return (
		<SkillCoveredBySavedLessons chartStandardsData={chartStandardsData} chartSkillsData={chartSkillsData}/>
	)
}
