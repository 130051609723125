import { Box, styled } from '@material-ui/core'
import { ThumbnailsBackground } from 'src/components/VideoControls/Background/ThumbnailsBackground'

import { WaveformBackground } from './WaveformBackground'

export type ScrubberBackgroundProps = {
	videoUrl?: string
	mediaLength: number
	showWaveform?: boolean
}
export const ScrubberBackground = ({ videoUrl, mediaLength, showWaveform }: ScrubberBackgroundProps) => {
	return (
		<StyledScrubberBackGround>
			{showWaveform ? (
				<WaveformBackground videoUrl={videoUrl} mediaLength={mediaLength} />
			) : (
				<ThumbnailsBackground videoUrl={videoUrl} />
			)}
		</StyledScrubberBackGround>
	)
}

const StyledScrubberBackGround = styled(Box)({
	position: 'absolute',
	zIndex: 2,
	width: '100%',
	height: '100%',
	background: '#E9E9EA',
	borderRadius: '7px',
	overflow: 'hidden'
})
