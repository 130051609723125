import React, { useEffect, useRef, useState } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { AccordionWrapper, ListSongs, NoItems, SelectSort } from 'src/components'
import { SeeAllBtnTooltip } from 'src/components/SeeAllTooltip'
import { Song } from 'src/graphql/autogenerate/schemas'
import { StudentPages } from 'src/routes/studentpages'
import { useGetSongs } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/hooks'
import { useClassSongsContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassSongs/useClassSongs'
import { buildRouteParameters, fetchOnScrollDown, getCyKey, SortOrder } from 'src/utils'

export const ClassSongsSaved = () => {
	const { classId, teacherId } = useClassSongsContext()

	const [songsSavedSort, setSongsSavedSort] = useState(SortOrder.Up)
	const [, setSongsSavedLimit] = useState<number | undefined>(5)
	const scrollRef = useRef(null)

	const order: SortOrder = SortOrder.Up
	const [songs, isFetching, moreResults, loading, count, setIsFetching] = useGetSongs(order, classId)
	useEffect(() => {
		if (scrollRef?.current)
			window.addEventListener('scroll', () => {
				const htmlElement = scrollRef?.current as unknown as HTMLElement
				fetchOnScrollDown(isFetching, setIsFetching, htmlElement)
			})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [scrollRef])

	const song: Song[] = []

	songs.map((i) => song.push(i.song as Song))

	const handleSort = (
		e: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		setSongsSavedSort(e.target.value as SortOrder)
	}

	const seeAllSongsSaved = () => {
		setSongsSavedLimit(undefined)
	}

	const overrideOnClick = (classId: number, songId: number) => {
		window
			.open(
				buildRouteParameters(StudentPages.IndieStudentStudio, {
					songId,
					classId
				}),
				'_blank'
			)
			?.focus()
	}

	return (
		<Box pt={3}>
			{loading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}

			{!loading && !song?.length && (
				<NoItems
					title="Seems no Songs have been added yet to this Class!"
					description="Come back Later!"
				/>
			)}
			{!loading && !!song?.length && (
				<AccordionWrapper
					defaultExpanded
					summary={
						<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b data-cy={getCyKey(ClassSongsSaved, `countSongsSaved-${count}`)}>
									Songs ({count})
								</b>
							</Typography>
							<div style={{ flexGrow: 1 }}></div>
							<SelectSort
								cyKey={getCyKey(ClassSongsSaved)}
								showRecent
								value={songsSavedSort}
								onChange={handleSort}
							/>
						</Box>
					}
					details={
						<>
							{isFetching || !loading ? (
								<ListSongs
									ref={scrollRef}
									teacherId={teacherId}
									selectedChords={[]}
									studioButton
									spacing={1.5}
									data={song}
									classId={classId}
									overrideOnClick={overrideOnClick}
									allTranspositions={false}
								/>
							) : (
								<Box m={3} textAlign="center">
									<CircularProgress color="secondary" size={40} />
								</Box>
							)}
							{isFetching && moreResults && (
								<Box m={3} textAlign="center">
									<CircularProgress color="secondary" size={40} />
								</Box>
							)}
						</>
					}
					actions={
						count > 0 && (
							<SeeAllBtnTooltip
								canFetchMore={!loading}
								name="saved songs"
								onClick={seeAllSongsSaved}
							/>
						)
					}
				/>
			)}
		</Box>
	)
}
