import { createStyles, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { useClassWurrlyContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'

export type REACTION_POPPER_REACTION = {
	reaction_id: number
	student_id: number
	wurrly_id: number
	wurrly_reaction_id: number
}

type ReactionsPopperProps = {
	open: boolean
	anchor: null | HTMLElement
	hasReacted: boolean
	wurrlyReaction: REACTION_POPPER_REACTION | undefined
	setOpenReactions: (value: boolean) => void
	setHasReacted: (value: boolean) => void
	setAnchor: (value: null | HTMLElement) => void
	addReaction: (reactionId: number) => void
	updateReaction: (wurrly_reaction_id: number, reactionId: number) => void
	removeReaction: () => void
}

const useStyles = makeStyles(() =>
	createStyles({
		horizMenu: {
			'& div': {
				display: 'flex',
				flexDirection: 'row'
			},

			'& .MuiMenu-list': {
				display: 'flex',
				flexDirection: 'row',
				borderRadius: '10px'
			},
			'& .MuiMenuItem-root': {
				paddingLeft: '5px',
				paddingRight: '5px',
				paddingTop: '0px',
				paddingBottom: '0px'
			},
			'& .MuiMenuItem-root:first-child': {
				paddingLeft: '15px',
				paddingRight: '5px'
			},
			'& .MuiMenuItem-root:last-child': {
				paddingLeft: '5px',
				paddingRight: '15px'
			}
		},
		emoji: {
			fontSize: '25px'
		}
	})
)
const ReactionsPopper = ({
	open,
	anchor,
	hasReacted,
	wurrlyReaction,
	setOpenReactions,
	setAnchor,
	addReaction,
	setHasReacted,
	updateReaction,
	removeReaction
}: ReactionsPopperProps) => {
	const styles = useStyles()
	const { filteredReactions, updateFilteredReactions, allReactions } = useClassWurrlyContext()

	const handleAddReaction = async (reactionId: number) => {
		await addReaction(reactionId)
		const updatedReactions = [...filteredReactions]
		const reactionIndex = updatedReactions.findIndex((reaction) => reaction.reaction_id === reactionId)
		if (reactionIndex !== -1) {
			updatedReactions[reactionIndex].count += 1
		} else {
			const reaction = allReactions?.reaction.find((reaction) => reaction.reaction_id === reactionId)
			if (reaction) {
				updatedReactions.push({
					label: reaction.name,
					icon: reaction.icon,
					reaction_id: reaction.reaction_id,
					count: 1
				})
			}
		}
		updateFilteredReactions(updatedReactions)
	}

	const handleRemoveReaction = async (reactionId: number) => {
		await removeReaction()
		const updatedReactions = [...filteredReactions]
		const reactionIndex = updatedReactions.findIndex((reaction) => reaction.reaction_id === reactionId)
		if (reactionIndex !== -1) {
			updatedReactions[reactionIndex].count -= 1
			if (updatedReactions[reactionIndex].count < 0) {
				updatedReactions[reactionIndex].count = 0
			}
		}
		updateFilteredReactions(updatedReactions)
	}
	const handleUpdateReaction = async (wurrlyReactionId: number, reactionId: number) => {
		await updateReaction(wurrlyReactionId, reactionId)
		const updatedReactions = [...filteredReactions]
		const reactionIndex = updatedReactions.findIndex((reaction) => reaction.reaction_id === reactionId)
		if (reactionIndex !== -1) {
			updatedReactions[reactionIndex] = {
				...updatedReactions[reactionIndex]
			}
		}
		updateFilteredReactions(updatedReactions)
	}

	const handleReaction = (reactionId: number) => {
		if (!hasReacted) {
			handleAddReaction(reactionId)
			setHasReacted(true)

			return
		}
		if (hasReacted && wurrlyReaction?.reaction_id && wurrlyReaction?.reaction_id !== reactionId) {
			handleUpdateReaction(wurrlyReaction?.wurrly_reaction_id, reactionId)
			setHasReacted(true)

			return
		}
		if (hasReacted && wurrlyReaction?.reaction_id === reactionId) {
			handleRemoveReaction(reactionId)
			setHasReacted(false)

			return
		}
	}

	return (
		<Menu
			id="basic-menu"
			open={open}
			anchorEl={anchor}
			onClose={() => {
				setAnchor(null)
				setOpenReactions(false)
			}}
			className={styles.horizMenu}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			MenuListProps={{
				'aria-labelledby': 'basic-button'
			}}>
			{allReactions?.reaction.map((reaction) => (
				<MenuItem
					onClick={() => {
						handleReaction(reaction.reaction_id)
						setAnchor(null)
						setOpenReactions(false)
					}}
					key={reaction.reaction_id}>
					<span className={styles.emoji}>{reaction.icon}</span>
				</MenuItem>
			))}
		</Menu>
	)
}
export default ReactionsPopper
