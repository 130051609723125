import { Fragment, ReactNode, useEffect, useMemo } from 'react'

import { Box, Container, SvgIconProps, Typography } from '@material-ui/core'
import {
	StartRecordingYellowIcon,
	StopRecordingYellowIcon,
	PianoIcon,
	ElectricGuitarIcon,
	AudioIcon,
	UkuleleIcon,
	EditIconDecorated
} from 'src/assets/icons/CustomIcons'
import { InstrumentsGuideType } from 'src/components/Studio/Record/InstrumentNotesPanel/types'
import { PermissionsDeniedDialog } from 'src/components/Studio/Record/PermissionsDeniedDialog'

import { ActionButton } from '../../..'
import { StudioDialog } from '../../StudioDialog/StudioDialog'
import { HeadphonesConnectivityDialog } from '../HeadphonesConnectivityDialog'
import { useDialogButtonStyles } from '../styles/studioStyles'
import { Tooltip } from '../Tooltip/Tooltip'
import { useStudioRecordContext } from '../useStudioRecord'
import { VideoRecorder } from '../VideoRecorder'
import useStyles from './VideoRecorderDialog.styles'

/**
 * This component should be called with the selected song parameters as `location.state`
 * in the structucture of `RecordStudioLocationState`.
 *
 * @example usage with `history.push`
 * 	history.push({
 *		pathname: buildRouteParameters(StudentPages.StudentRecord, { songId, classId }),
 *		state: {
 *			speed: 1,
 *			transposition: -3,
 *			instrument: 'Guitar',
 *          classId: 42,
 *          assignmentId: 69,
 *          wurrlyType: Wurrly_Type_Enum.Indie
 *		} as RecordStudioLocationState
 *	})
 */

type VideoRecorderDialogProps = {
	isTeacher?: boolean
}
export const VideoRecorderDialog = ({ isTeacher }: VideoRecorderDialogProps) => {
	const styles = useStyles()
	const buttonStyles = useDialogButtonStyles()
	const {
		isRecording,
		isCameraOn,
		toggleRecording,
		showHeadphonesConnectivity,
		selectedInstrumentGuide,
		selectInstrumentGuide,
		audioElement,
		isTooltipHidden,
		setIsTeacher,
		isApplyingEffects,
		setIsApplyingEffects,
		isBlankSong,
		isPermissionsDenied
	} = useStudioRecordContext()
	const getIcon = (props: SvgIconProps): Record<InstrumentsGuideType, (props: SvgIconProps) => ReactNode> => ({
		audio: () => <AudioIcon {...props} />,
		guitar: () => <ElectricGuitarIcon {...props} />,
		piano: () => <PianoIcon {...props} stroke={props.fill} />,
		ukulele: () => <UkuleleIcon {...props} />
	})

	const handleInstrumentGuideSelection = (instrument: InstrumentsGuideType) => {
		selectInstrumentGuide(instrument)
	}

	const permissionsDeniedStyles = useMemo(() => {
		if (isPermissionsDenied) {
			return {
				style: {
					opacity: 0.5,
					pointerEvents: 'none'
				},
				stroke: '#888',
				fill: '#888'
			} as SvgIconProps
		}

		return {}
	}, [isPermissionsDenied])

	useEffect(() => {
		setIsTeacher(!!isTeacher)
	}, [isTeacher])

	const leftActions = useMemo(
		() => (
			<Box className={styles.guideButtonsContainer}>
				<Typography>Guides</Typography>
				<Box display="flex">
					{Object.keys(InstrumentsGuideType).map((i) => {
						const isSelected = selectedInstrumentGuide === i
						const iconColor = isSelected ? '#F3AB34' : '#1D273D'
						const IconComponent = getIcon({ fill: iconColor })[i as InstrumentsGuideType]({})

						return (
							<Box className={styles.guideButton} key={i}>
								<ActionButton
									selected={isSelected}
									className={styles.actionButton}
									name={i}
									onClick={() => handleInstrumentGuideSelection(i as InstrumentsGuideType)}
									icon={IconComponent}
								/>
								<span style={{ textTransform: 'capitalize' }}>{i}</span>
							</Box>
						)
					})}
				</Box>
			</Box>
		),
		[handleInstrumentGuideSelection, selectedInstrumentGuide]
	)

	const rightActions = useMemo(
		() => (
			<Container className={styles.centerContainer}>
				{!isTooltipHidden && (
					<Box position="absolute" right="10px" top="-76px">
						<Tooltip />
					</Box>
				)}

				<ActionButton
					className={buttonStyles.playBtn}
					disabled={isPermissionsDenied}
					icon={
						isRecording ? (
							<StopRecordingYellowIcon />
						) : (
							<StartRecordingYellowIcon {...permissionsDeniedStyles} />
						)
					}
					onClick={toggleRecording}
				/>
				{!isRecording && isCameraOn && (
					<ActionButton
						className={buttonStyles.editBtn}
						icon={<EditIconDecorated fill={isApplyingEffects ? '#F3AB34' : undefined} />}
						onClick={() => setIsApplyingEffects((prev) => !prev)}
						selected={isApplyingEffects}
					/>
				)}
			</Container>
		),
		[isRecording, isCameraOn, isApplyingEffects, isTooltipHidden, toggleRecording, setIsApplyingEffects]
	)

	return (
		<Fragment>
			<StudioDialog
				hasVideo
				body={<VideoRecorder />}
				bottomActions={
					<>
						{!isBlankSong && leftActions}
						{rightActions}
						{!isBlankSong && <Box className={styles.growForCenteringButton} />}
					</>
				}
			/>
			{showHeadphonesConnectivity && <HeadphonesConnectivityDialog />}
			<PermissionsDeniedDialog />
			<div style={{ display: 'hidden' }}>{audioElement}</div>
		</Fragment>
	)
}
