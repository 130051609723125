import { createStyles, makeStyles } from '@material-ui/core'

export const useAssetItemStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			padding: '15px 0',
			'&:not(:first-child)': {
				borderTop: '1px solid rgba(0, 0, 0, 0.08)'
			}
		},
		downloadButton: { marginRight: 15 },
		ellipsis: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }
	})
)

export default useAssetItemStyles
