import { Fragment, useMemo } from 'react'

import { Box, Divider, fade, List, makeStyles, Theme } from '@material-ui/core'

import { Wurrly } from '../graphql/autogenerate/schemas'
import { generateUniqueId } from '../utils'
import { ListItemWurrly } from './ListItemWurrly'

const useStyles = makeStyles((theme: Theme) => ({
	boxSongs: {
		padding: 0,
		'& .itemBox': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	},
	boxLoading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.common.black, 0.1)
	},
	pointer: {
		cursor: 'pointer'
	}
}))
type ListWurrliesProps = {
	data: Wurrly[]
	spacing?: number
	viewButton?: boolean
	onClick?: (wurrlyId: number, classId: number) => void
}
type ItemWurrlyProps = {
	wurrly: Wurrly
	offSet: number
}
export const ListWurrlies = ({ data, spacing = 0, viewButton = true, onClick }: ListWurrliesProps) => {
	const styles = useStyles(spacing)
	const lineDivider = false

	const ItemWurrly = ({ wurrly }: ItemWurrlyProps) => {
		return (
			<Box className={styles.pointer} onClick={() => onClick?.(wurrly.wurrly_id, wurrly.class_id)}>
				<ListItemWurrly
					studentImagePath={wurrly.student?.image_path ?? ''}
					studentName={`${wurrly.student?.first_name} ${wurrly.student?.last_name}`}
					wurrlyCreatedDate={wurrly.created_at}
					wurrlyImagePath={wurrly.image_path ?? ''}
					wurrlySongArtist={wurrly.track?.song?.artist?.name ?? ''}
					wurrlySongTitle={wurrly.track?.song?.title ?? ''}
					wurrlyTrackType={wurrly.track?.track_type?.name ?? ''}
					btnView={
						viewButton
							? {
									onClick: (e) => {
										e.stopPropagation()

										onClick?.(wurrly.wurrly_id, wurrly.class_id)
									}
							  }
							: undefined
					}
				/>
			</Box>
		)
	}

	return (
		<Fragment>
			{useMemo(
				() => (
					<List className={styles.boxSongs}>
						{data.map((wurrly, i) => (
							<Box
								className="itemBox"
								boxShadow={spacing > 0 ? 1 : 0}
								borderRadius={spacing > 0 ? 4 : 0}
								key={generateUniqueId()}>
								<ItemWurrly wurrly={wurrly} offSet={i} />
								{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
							</Box>
						))}
					</List>
				),
				[data]
			)}
		</Fragment>
	)
}
