import { useMemo, useState } from 'react'

import { FilterItemType } from 'src/@types'
import {
	useGetLessonCatalogFiltersQuery,
	useGetVideoCatalogFiltersQuery,
	useGetSongCatalogFiltersQuery
} from 'src/graphql/autogenerate/hooks'
import { capitalize, TypeEnum } from 'src/utils'

export const useCatalogFilters = ({
	catalogItemId,
	catalog_id,
	product = TypeEnum.Lesson
}: { catalogItemId?: number; catalog_id?: number; product?: TypeEnum } = {}) => {
	const [selectedFilters, setSelectedFilters] = useState<number[]>(() =>
		catalogItemId ? [catalogItemId || 0] : []
	)
	const secondaryFiltersVideo = useGetVideoCatalogFiltersQuery({ skip: product !== TypeEnum.Video })
	const secondaryFiltersSong = useGetSongCatalogFiltersQuery({ skip: product !== TypeEnum.Song })
	const secondaryFiltersLesson = useGetLessonCatalogFiltersQuery({ skip: product !== TypeEnum.Lesson })

	const filters = useMemo<FilterItemType[]>(() => {
		let secondaryFilters
		switch (product) {
			case TypeEnum.Song:
				secondaryFilters = secondaryFiltersSong
				break
			case TypeEnum.Video:
				secondaryFilters = secondaryFiltersVideo
				break
			case TypeEnum.Lesson:
				secondaryFilters = secondaryFiltersLesson
				break
			default:
				secondaryFilters = secondaryFiltersLesson
				break
		}

		if (!secondaryFilters.data) return []

		const { catalog } = secondaryFilters.data

		return catalog
			.filter((el) => !catalogItemId || el.catalog_id !== catalog_id)
			.map((itemCatalog) => ({
				name: capitalize(itemCatalog.name),
				items: itemCatalog.catalog_items.map((el) => ({
					id: el.catalog_item_id,
					label: el.name,
					selected: false
				}))
			}))
	}, [secondaryFiltersLesson, secondaryFiltersVideo, secondaryFiltersSong])

	const handleCheck = (indexFilter: number, indexItem: number) => {
		const cpy = [...filters]
		const filter = cpy[indexFilter]
		const item = filter.items[indexItem]
		item.selected = selectedFilters.some((id) => id === item.id)
		if (!item.selected) {
			setSelectedFilters([...selectedFilters, item.id])
		} else {
			if (item.id === catalogItemId) return
			const excludedFilter = selectedFilters.filter((id) => id !== item.id)
			setSelectedFilters(excludedFilter)
		}
	}

	return { selectedFilters, filters, handleCheck }
}
