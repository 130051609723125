import { PagesProps } from 'src/@types'

import { ClassAssignmentGradeContent } from './ClassAssignmentGrade'
import { GradingProvider } from './useGrading'

export const ClassAssignmentGrade = (props: PagesProps) => {
	return (
		<GradingProvider>
			<ClassAssignmentGradeContent {...props} />
		</GradingProvider>
	)
}
