import React, { useEffect, useState } from 'react'

import { Box, Paper, TextField } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab'
import { Category_Standard_Enum, Standard, Standard_Option } from 'src/graphql/autogenerate/schemas'

import { SubCategoryItem, NestedOptions, CategoryItem } from './OptionComponents'
import { SelectDataProvider, useSelectDataContext } from './useFormData'

const useStyles = makeStyles((theme) => ({
	listBox: {
		padding: 0,
		margin: 0,
		'& li:not(:last-child)': {
			borderBottom: '1px solid rgba(0, 0, 0, 8%)'
		},
		'& .MuiAutocomplete-option': {
			padding: 0,
			backgroundColor: 'inherit'
		},
		'& .MuiAutocomplete-option[aria-selected="true"]': {
			backgroundColor: 'inherit'
		}
	},
	chip: {
		color: theme.palette.background.default,
		backgroundColor: theme.palette.text.secondary,
		borderRadius: '4px',
		marginRight: '5px'
	},
	inputFocused: {
		'& .MuiSvgIcon-root': {
			fill: theme.palette.secondary.dark
		}
	}
}))

type SelectProcessesProps = {
	standard: Standard
	handleSelectedValues: (values: number[]) => void
	initialValues?: number[]
}
const SelectMusicStandardInner = ({ standard, handleSelectedValues, initialValues }: SelectProcessesProps) => {
	const classes = useStyles()
	const [isFocused, setIsFocused] = useState(false)

	const {
		setHoverItem,
		selectedCategories,
		setOptions,
		categoryStandards,
		getOptionCategoryLabel,
		clearSelection,
		selectedItems,
		setInitialData
	} = useSelectDataContext()

	const handleHover = (item: Category_Standard_Enum) => {
		setHoverItem(item)
	}

	const handleChange = (_e: unknown, _v: unknown, reason: AutocompleteChangeReason) => {
		if (reason === 'clear') clearSelection()
	}

	useEffect(() => {
		let values = [] as number[]
		for (const selectedArray of Object.values(selectedItems)) {
			values = values.concat(selectedArray)
		}
		handleSelectedValues(values)
	}, [selectedItems])

	useEffect(() => {
		setOptions(standard)
	}, [standard, setOptions])

	useEffect(() => {
		if (initialValues && initialValues.length > 0) {
			setInitialData(initialValues)
		}
	}, [initialValues])

	return (
		<Autocomplete
			// open
			multiple
			disableCloseOnSelect
			openOnFocus
			PaperComponent={OverridePaper}
			ChipProps={{ className: classes.chip, onDelete: undefined }}
			ListboxProps={{ className: classes.listBox }}
			options={categoryStandards ?? []}
			value={selectedCategories}
			onChange={handleChange}
			getOptionLabel={getOptionCategoryLabel}
			renderOption={(item) => <CategoryItem item={item} onMouseEnter={() => handleHover(item)} />}
			onOpen={() => setIsFocused(true)}
			onClose={() => setIsFocused(false)}
			renderInput={(params) => (
				<TextField
					{...params}
					className={isFocused ? classes.inputFocused : ''}
					data-cy="upload-lesson-levels"
					variant="outlined"
					color="secondary"
					placeholder="Select all that apply"
				/>
			)}
		/>
	)
}

export const OptionsContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	marginTop: '5px'
})

export const StyledPaper = styled(Box)({
	height: 'fit-content',
	maxHeight: '450px',
	overflow: 'auto',

	'&::-webkit-scrollbar': {
		width: '4px',
		height: '4px'
	},
	'&::-webkit-scrollbar-track': {
		margin: '10px',
		borderRadius: '4px',
		backgroundColor: 'rgba(174, 174, 174, 24%)'
	},
	'&::-webkit-scrollbar-thumb': {
		margin: '10px',
		borderRadius: '4px',
		backgroundColor: 'rgba(174, 174, 174, 100%)'
	}
})

export const ScrollSectionWrapper = styled(Paper)({
	width: '50%',
	paddingRight: '10px',
	boxShadow:
		'0px 9px 27px rgba(202, 202, 202, 0.49), 0px 3.75998px 11.28px rgba(202, 202, 202, 0.352239), 0px 2.01027px 6.0308px rgba(202, 202, 202, 0.292093), 0px 1.12694px 3.38082px rgba(202, 202, 202, 0.245), 0px 0.598509px 1.79553px rgba(202, 202, 202, 0.197907), 0px 0.249053px 0.747159px rgba(202, 202, 202, 0.137761)'
})

const OverridePaper: React.ComponentType<React.HTMLAttributes<HTMLElement>> = (props) => {
	const { hoverItem, options, setHoverItem, hoverSubItem, setHoverSubItem } = useSelectDataContext()
	const [nestedOptions, setOptions] = useState<Standard_Option[]>([])

	useEffect(() => {
		setOptions(
			options.standard_subcategories?.find(({ name }) => name === hoverSubItem)?.standar_options ?? []
		)
	}, [hoverSubItem])

	return (
		<OptionsContainer onMouseLeave={() => setHoverItem(undefined)}>
			<ScrollSectionWrapper>
				<StyledPaper>{props.children}</StyledPaper>
			</ScrollSectionWrapper>

			{hoverItem && (
				<OptionsContainer onMouseLeave={() => setHoverSubItem(undefined)}>
					<ScrollSectionWrapper>
						<StyledPaper>
							{options.standard_subcategories
								.filter((x) => x.category_standard === hoverItem)
								.map((item) => {
									return (
										<SubCategoryItem
											key={item.name}
											item={item}
											onMouseEnter={() => setHoverSubItem(item.name)}
										/>
									)
								})}
						</StyledPaper>
					</ScrollSectionWrapper>
					{hoverSubItem && (
						<ScrollSectionWrapper>
							<StyledPaper onMouseDown={(event) => event.preventDefault()}>
								<NestedOptions options={nestedOptions} />
							</StyledPaper>
						</ScrollSectionWrapper>
					)}
				</OptionsContainer>
			)}
		</OptionsContainer>
	)
}

/**
 * This is just a wrapper for providing the select context
 */
export const SelectMusicStandard = (props: SelectProcessesProps) => {
	return (
		<SelectDataProvider>
			<SelectMusicStandardInner {...props} />
		</SelectDataProvider>
	)
}
