import { SignupPage } from 'src/scenes/Login/scenes/1-TeacherLogin/SignupPage'

export const SignupPages = {
	Teacher: {
		name: 'Sign Up',
		path: '/signup/teacher',
		component: SignupPage,
		params: []
	}
}
