import { createStyles, makeStyles } from '@material-ui/core'

const useProductCardButtonStyles = makeStyles(() =>
	createStyles({
		iconButton: {
			minWidth: 0,
			width: 50,
			height: 40
		}
	})
)

export default useProductCardButtonStyles
