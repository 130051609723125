import { useState, useEffect } from 'react'

import { useGetWurrliesByClassIdQuery } from 'src/graphql/autogenerate/hooks'
import { Wurrly, Order_By, Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { useScrollPercentage } from 'src/hooks/useScroll'
import { MAX_LIST_ITEMS } from 'src/utils/constants'

export type useClassWurrliesProps = {
	classId: number
	studentId?: number
	orderTitle?: Order_By | undefined
	orderDate?: Order_By | undefined
	privacySettings?: Privacy_Enum[]
}

export const useClassWurrlies = ({ classId, studentId, orderTitle, orderDate }: useClassWurrliesProps) => {
	const [wurrlies, setWurrlies] = useState<Wurrly[]>([])
	const [count, setCount] = useState(0)
	const [isLoading, setIsLoading] = useState(true)
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const { scrollLevel } = useScrollPercentage()

	const queryResult = useGetWurrliesByClassIdQuery({
		variables: {
			classId,
			studentId,
			limit,
			offset: 0,
			orderTitle,
			orderDate
		}
	})

	const canFetchMore = () => wurrlies.length < count

	useEffect(() => {
		setIsLoading(true)
		if (queryResult?.data) {
			setWurrlies((queryResult.data?.wurrly as Wurrly[]) ?? [])
			setCount(queryResult.data?.wurrly_aggregate?.aggregate?.count ?? 0)
			setIsLoading(false)
		}
	}, [queryResult])

	useEffect(() => {
		if (scrollLevel === 100 && !isLoading && canFetchMore()) {
			setLimit((prevLimit) => prevLimit + MAX_LIST_ITEMS)
		}
	}, [scrollLevel])

	return {
		wurrlies,
		count,
		loading: isLoading
	}
}
