import { useEffect, useState } from 'react'

import {
	Avatar,
	Box,
	CircularProgress,
	Fab,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Paper,
	Typography
} from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { IName } from 'src/@types'
import { ScheduleIcon, ThinCheckIcon } from 'src/assets/icons'
import { BaseLink, BigBaseButton, DropdownInput, InfoDialog, TextInput } from 'src/components'
import { MessageBox } from 'src/components/MessageBox/MessageBox'
import { WurrlyPlayer, WurrlyToPlay } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import {
	useUpdateWurrlyInsertCommentMutation,
	useUpdateWurrlyUpdateCommentMutation
} from 'src/graphql/autogenerate/hooks'
import { Privacy, Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildImagePath, buildRouteParameters, getCyKey, PRIVACY_ARRAY } from 'src/utils'

import { WurrliesDetailsFormConfig } from '../../../../../2-Classes/scenes/ClassWurrlies/scenes/components/WurrliesDetailsFormConfig'
import { useStudentWurrliesDetailsContext } from '../useStudentWurrliesDetails'

const useStyles = makeStyles((theme) => ({
	listBox: {
		'& .MuiAutocomplete-option[aria-selected="true"]': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(255, 194, 12, 0.3)'
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(255, 194, 12, 0.1)'
		}
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	}
}))

type SaveFormType = {
	visibility: Privacy
	comment?: string
}
export const StudentWurrliesDetailsFormConfig = () => {
	const {
		studentId,
		teacher,
		wurrlyDetails,
		wurrlyDetailsLoading,
		hasPrev,
		hasNext,
		nextValue,
		previousValue,
		processingStatus
	} = useStudentWurrliesDetailsContext()
	const styles = useStyles()
	const history = useHistory()
	const [isPreviousNext, setIsPreviousNext] = useState(false) // false = previous, true = next
	const [onSuccessMutation, setOnSuccessMutation] = useState(false)
	const [isDataLostWarning, setIsDataLostWarning] = useState(false)
	const [isOpenContinueDataLostWarning, setIsOpenContinueDataLostWarning] = useState(false)
	const [isOpenGoBackDataLostWarning, setIsOpenGoBackDataLostWarning] = useState(false)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [updateWurrlyInsertCommentMutation] = useUpdateWurrlyInsertCommentMutation()
	const [updateWurrlyUpdateCommentMutation] = useUpdateWurrlyUpdateCommentMutation()
	const { handleSubmit, setValue, formState, control, reset } = useForm()
	const [message, setMessage] = useState('')
	const [comment, setComment] = useState('')
	const [privacy, setPrivacy] = useState<IName>()

	useEffect(() => {
		if (formState.isDirty) {
			setIsDataLostWarning(true)
		}
	}, [formState])

	// set values
	useEffect(() => {
		if (wurrlyDetails) {
			const { message, submission_wurrly } = wurrlyDetails
			const comment = submission_wurrly?.submission?.teacher_comment || ''

			const privacyName = PRIVACY_ARRAY.find((p) => p.name === privacy?.name)
			setPrivacy(privacyName)
			setMessage(message || '')
			setComment(comment || '')
			setValue('visibility', privacyName)
			setValue('comment', comment)
		}
	}, [wurrlyDetails])

	const saveFormData = (formDataToSave: SaveFormType) => {
		if (wurrlyDetails) {
			if (wurrlyDetails?.teacher_wurrly_comments?.length > 0) {
				updateWurrlyUpdateComment(formDataToSave)
			} else {
				updateWurrlyInsertComment(formDataToSave)
			}
		}
	}

	const updateWurrlyInsertComment = async (formDataToSave: SaveFormType) => {
		if (wurrlyDetails && formDataToSave) {
			try {
				await updateWurrlyInsertCommentMutation({
					variables: {
						wurrlyId: wurrlyDetails.wurrly_id,
						privacyId: (formDataToSave?.visibility?.name as Privacy_Enum) || wurrlyDetails.privacy,
						teacherWurrlyComment: {
							wurrly_id: wurrlyDetails.wurrly_id,
							teacher_id: teacher.teacher_id,
							comment: formDataToSave.comment || ''
						}
					},
					update: (cache, { data }) => {
						const classToUpdateInCache = data?.update_wurrly?.returning[0].class
						if (!classToUpdateInCache) return
						const identify = cache.identify(wurrlyDetails)
						cache.evict({
							id: identify,
							fieldName: 'teacher_wurrly_comments'
						})
					}
				})
				setOnSuccessMutation(true)
				reset()
				setIsDataLostWarning(false)
			} catch (err) {
				setErrorDialogOpen(true)
				console.error(err)
			}
		}
	}

	const updateWurrlyUpdateComment = async (formDataToSave: SaveFormType) => {
		if (wurrlyDetails && formDataToSave) {
			try {
				await updateWurrlyUpdateCommentMutation({
					variables: {
						wurrlyId: wurrlyDetails.wurrly_id,
						privacyId: (formDataToSave?.visibility?.name as Privacy_Enum) || wurrlyDetails.privacy,
						submissionId: wurrlyDetails?.submission_wurrly?.submission_id || 0,
						teacherComment: formDataToSave.comment || ''
					},
					update: (cache, { data }) => {
						const classToUpdateInCache = data?.update_wurrly?.returning[0].class
						if (!classToUpdateInCache) return
						const identify = cache.identify(wurrlyDetails)
						cache.evict({
							id: identify,
							fieldName: 'teacher_wurrly_comments'
						})
					}
				})
				setOnSuccessMutation(true)
				reset()
				setIsDataLostWarning(false)
			} catch (err) {
				setErrorDialogOpen(true)
				console.error(err)
			}
		}
	}

	const openContinueDataLostWarning = () => {
		setIsOpenContinueDataLostWarning(isDataLostWarning)
	}

	const openGoBackDataLostWarning = () => {
		setIsOpenGoBackDataLostWarning(isDataLostWarning)
	}

	const handleClickPrev = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault()
		setIsPreviousNext(false)
		if (isDataLostWarning) openContinueDataLostWarning()
		else previousValue()
	}

	const handleClickNext = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault()
		setIsPreviousNext(true)
		if (isDataLostWarning) openContinueDataLostWarning()
		else nextValue()
	}

	return (
		<Box>
			<form onSubmit={handleSubmit(saveFormData)}>
				{wurrlyDetailsLoading && (
					<Box m={3} textAlign="center">
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
				{!wurrlyDetailsLoading && (
					<Box>
						<Box mt={5} mb={2} display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b>Wurrly Details</b>
							</Typography>
						</Box>
						<ListItem>
							<ListItemAvatar>
								<Avatar
									className={styles.large}
									src={buildImagePath(wurrlyDetails?.student?.image_path || '')}
								/>
							</ListItemAvatar>
							<ListItemText
								disableTypography
								primary={
									<Box ml={3}>
										<Typography noWrap variant="h6">
											<b>
												{wurrlyDetails?.student?.first_name || ''}
												{wurrlyDetails?.student?.last_name || ''}
											</b>
										</Typography>
									</Box>
								}
								secondary={
									<Box ml={3} display="flex" style={{ alignItems: 'center' }}>
										<ScheduleIcon />
										<Typography variant="caption" color="textSecondary">
											<b>{moment(wurrlyDetails?.created_at).format('ddd, DD MMM HH:mmA')}</b>
										</Typography>
									</Box>
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								disableTypography
								primary={
									<Box mt={4}>
										<Typography noWrap variant="h6">
											<b data-cy={getCyKey(WurrliesDetailsFormConfig, 'songTitle')}>
												{wurrlyDetails?.track?.song?.title || ''}
											</b>
										</Typography>
									</Box>
								}
								secondary={
									<Box>
										<Typography variant="caption" color="textSecondary">
											<b>{wurrlyDetails?.track?.song?.artist?.name || ''}</b>
										</Typography>
									</Box>
								}
							/>
						</ListItem>

						{wurrlyDetails && PRIVACY_ARRAY.length > 0 && (
							<Box position="relative">
								<Paper elevation={2} style={{ padding: '40px', width: '100%' }}>
									<WurrlyPlayer
										key={wurrlyDetails.wurrly_id}
										wurrly={wurrlyDetails as unknown as WurrlyToPlay}
										processingStatus={processingStatus}
									/>
									{!!message && <MessageBox text={message} />}
									{privacy && (
										<Controller
											name="visibility"
											control={control}
											render={({ field: { onChange, value } }) => (
												<DropdownInput
													data-cy={getCyKey(
														WurrliesDetailsFormConfig,
														'visibilityDropdown'
													)}
													id="visibility"
													name="visibility"
													options={PRIVACY_ARRAY}
													value={value}
													onChange={(_, selectedItem: IName | null) => {
														if (selectedItem) onChange(selectedItem)
													}}
													getOptionLabel={(option) => option.name}
													ListboxProps={{
														className: styles.listBox
													}}
													defaultValue={privacy}
												/>
											)}
										/>
									)}

									{wurrlyDetails?.submission_wurrly?.submission_id && (
										<Controller
											name="comment"
											control={control}
											render={({ field: { onChange, value } }) => (
												<TextInput
													data-cy={getCyKey(WurrliesDetailsFormConfig, 'textInputBox')}
													id="comment"
													name="comment"
													placeholder="Teacher's comment"
													multiline
													rows={8}
													value={value}
													onChange={(value) => onChange(value)}
													defaultValue={comment}
												/>
											)}
										/>
									)}
								</Paper>
								<Box>
									{hasPrev && (
										<Box
											position="absolute"
											style={{ top: '43%' }}
											left={-21}
											alignItems="center"
											zIndex={10}>
											<Fab
												style={{ backgroundColor: '#fff' }}
												size="small"
												onClick={handleClickPrev}>
												<KeyboardArrowLeft
													data-cy={getCyKey(WurrliesDetailsFormConfig, 'arrowLeft')}
													color="secondary"
												/>
											</Fab>
										</Box>
									)}
									{hasNext && (
										<Box
											style={{ top: '43%' }}
											position="absolute"
											right={-21}
											alignItems="center"
											zIndex={10}>
											<Fab
												style={{ backgroundColor: '#fff' }}
												size="small"
												onClick={handleClickNext}>
												<KeyboardArrowRight
													data-cy={getCyKey(WurrliesDetailsFormConfig, 'arrowRight')}
													color="secondary"
												/>
											</Fab>
										</Box>
									)}
								</Box>
							</Box>
						)}

						<Box mt={6} display="flex" justifyContent="space-between">
							<BigBaseButton
								onClick={(e) => {
									e.preventDefault()
									if (isDataLostWarning) openGoBackDataLostWarning()
									else {
										history.goBack()
									}
								}}>
								<Typography
									data-cy={getCyKey(WurrliesDetailsFormConfig, 'btnGoBack')}
									style={{ margin: '0 65px' }}>
									Go Back
								</Typography>
							</BigBaseButton>
						</Box>
					</Box>
				)}
				<InfoDialog
					open={onSuccessMutation}
					onClose={() => {
						setOnSuccessMutation(false)
					}}
					onDiscard={() => {
						setOnSuccessMutation(false)
						history.push(buildRouteParameters(Pages.StudentDetail, { studentId }))
					}}
					onConfirm={() => {
						setOnSuccessMutation(false)
					}}
					icon={<ThinCheckIcon />}
					title="Wurrly Updated!"
					body="You have successfully updated this Wurrly."
					discardLabel="Go Back"
					confirmLabel="Done"
					footer={
						<BaseLink
							onClick={() => {
								setOnSuccessMutation(false)
								nextValue()
							}}>
							<Typography data-cy={getCyKey(WurrliesDetailsFormConfig, 'btnReviewNextWurrly')}>
								Review Next Wurrly
							</Typography>
						</BaseLink>
					}
				/>
				<InfoDialog
					open={isOpenContinueDataLostWarning}
					onClose={() => setIsOpenContinueDataLostWarning(false)}
					onDiscard={() => {
						setIsDataLostWarning(false)
						reset()
						setIsOpenContinueDataLostWarning(false)
						if (isPreviousNext) nextValue()
						else previousValue()
					}}
					onConfirm={() => {
						setIsOpenContinueDataLostWarning(false)
					}}
					icon="!"
					title="Continue Without Saving?"
					body="You will lose all the details you've entered up until this point."
					discardLabel={`Yes, Go ${isPreviousNext ? 'Forward' : 'Back'}`}
					confirmLabel="No, Cancel"
				/>
				<InfoDialog
					open={isOpenGoBackDataLostWarning}
					onClose={() => setIsOpenGoBackDataLostWarning(false)}
					onDiscard={() => {
						setIsDataLostWarning(false)
						reset()
						setIsOpenGoBackDataLostWarning(false)
						history.push(buildRouteParameters(Pages.StudentDetail, { studentId }))
					}}
					onConfirm={() => {
						setIsOpenGoBackDataLostWarning(false)
					}}
					icon="!"
					title="Go Back Without Saving?"
					body="You will lose all the details you've entered up until this point."
					discardLabel="Yes, Go Back"
					confirmLabel="No, Cancel"
				/>
				<InfoDialog
					open={errorDialogOpen}
					onClose={() => {
						setErrorDialogOpen(false)
					}}
					icon="x"
					title="Something went wrong"
					body="Something went wrong while updating the wurrly"
					confirmLabel="Done"
					onConfirm={() => {
						setErrorDialogOpen(false)
					}}
				/>
			</form>
		</Box>
	)
}
