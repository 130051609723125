import React, { Fragment } from 'react'

import { Checkbox, TableCell, TableRow, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { BaseButton, StudentsFromCSV } from '../../'

type AddStudentsUnder12TableRowProps = {
	student: StudentsFromCSV
	selectedStudents: StudentsFromCSV[]
	setSelectedStudents: React.Dispatch<React.SetStateAction<StudentsFromCSV[]>>
	onDelete: (student: StudentsFromCSV) => void
}
export const AddStudentsUnder12TableRow = ({
	student,
	selectedStudents,
	setSelectedStudents,
	onDelete
}: AddStudentsUnder12TableRowProps) => {
	const onSelect = (student: StudentsFromCSV) => {
		const array = [...selectedStudents]
		const index = array.findIndex((st) => st.id === student.id)
		if (index !== -1) {
			array.splice(index, 1)
		} else {
			array.push(student)
		}
		setSelectedStudents(array)
	}

	return (
		<Fragment>
			<TableRow key={student.id} hover>
				<TableCell>
					<Checkbox
						checked={student.isSelected}
						onClick={(e) => {
							e.stopPropagation()
							onSelect(student)
						}}
					/>
				</TableCell>
				<TableCell>
					<Typography>{`${student.firstName} ${student.lastName}`}</Typography>
				</TableCell>
				<TableCell>
					<Typography>{student.parentEmail}</Typography>
				</TableCell>
				<TableCell>
					<BaseButton
						style={{ minWidth: '35px', width: '35px', height: '35px' }}
						onClick={(e) => {
							e.stopPropagation()
							onDelete(student)
						}}>
						<DeleteIcon />
					</BaseButton>
				</TableCell>
			</TableRow>
		</Fragment>
	)
}
