import { Box, Paper, PaperProps, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverOutlined'
import clsx from 'clsx'
import { SubscriptionsCardType } from 'src/@types'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { StatusProductButton } from 'src/components/Buttons/StatusProductButton/StatusProductButton'
import { useSubscriptionsCard } from 'src/components/SubscriptionsCard/useSubscriptionsCard'
import { buildImagePath, capitalize, getPriceInDollars } from 'src/utils'

import useStyles from './SubscriptionsCard.styles'

export type SubscriptionsCardProps = PaperProps & {
	data: SubscriptionsCardType
	onDelete?: () => void
	onEdit?: () => void
	editTooltip?: string
	deleteTooltip?: string
	onActiveChange?: (active: boolean) => void
	onView?: () => void
	onUnsubscribe?: () => void
	allSubscriptionsCard: boolean
	isSearch?: boolean
	dataflagText?: string
}

export const SubscriptionsCard = ({
	data,
	allSubscriptionsCard,
	isSearch = false,
	onView,
	onUnsubscribe,
	dataflagText,
	...props
}: SubscriptionsCardProps) => {
	const styles = useStyles({ imageUrl: buildImagePath(data.image_path) || '', isSearch })
	const { expiringDate, isSubscribed } = useSubscriptionsCard(data)

	return (
		<>
			<Paper {...props}>
				<Box className={styles.container}>
					<Box
						className={clsx(
							styles.imageContainer,
							data.image_path ? styles.image : null,
							!allSubscriptionsCard ? styles.titleCenter : null
						)}>
						<Typography
							variant="h5"
							className={clsx(styles.ellipsis, allSubscriptionsCard ? styles.title : null)}>
							{data.title}
						</Typography>

						{allSubscriptionsCard && (
							<Box className={styles.flag}>
								<Typography className={clsx(styles.flagText, styles.ellipsisSecondLine)}>
									{dataflagText && <b>{`${dataflagText}`}</b>}
								</Typography>
							</Box>
						)}
					</Box>

					<Box className={styles.details}>
						<Box className={styles.descriptionContainer}>
							<Typography className={clsx(styles.ellipsis, styles.description)}>
								{data.description}
							</Typography>
							{allSubscriptionsCard && (
								<Typography className={clsx(styles.price)}>
									${getPriceInDollars(data.price)}
								</Typography>
							)}
						</Box>

						{allSubscriptionsCard && <StatusProductButton isSubscriptionCard status={data.status} />}
						<Box className={styles.footer}>
							{allSubscriptionsCard ? (
								<Box className={styles.expireText}>
									<Box className={styles.expire}>
										<Typography
											className={styles.textNameColor}>{`TYPE OF SUBSCRIPTION:`}</Typography>
										<Typography
											className={
												styles.textNameColor
											}>{`SUBSCRIPTION EXPIRES ON:`}</Typography>
									</Box>
									<Box className={styles.expire}>
										<Typography className={styles.text}>
											{capitalize(data.priceType)}
										</Typography>

										<Typography className={styles.text}>{expiringDate}</Typography>
									</Box>
								</Box>
							) : (
								<Box className={clsx(styles.expireInLine, styles.expire)}>
									<Box className={styles.expireText}>
										<Typography className={styles.textNameColor}>{`EXPIRES ON:`}</Typography>
										<Typography className={styles.text}>{expiringDate}</Typography>
									</Box>
									<Box className={styles.expireText}>
										<Typography className={styles.textNameColor}>{`TYPE:`}</Typography>
										<Typography className={styles.text}>
											{capitalize(data.priceType)}
										</Typography>
									</Box>
									<BaseButton
										color="primary"
										className={styles.lockedButton}
										onClick={(e) => {
											e.stopPropagation()
										}}>
										<Typography className={styles.locked}>
											{capitalize(data.productType)}
										</Typography>
									</BaseButton>
								</Box>
							)}
							{allSubscriptionsCard && (
								<Box className={styles.buttons}>
									<BaseButton
										color="primary"
										className={styles.viewButton}
										endIcon={<ArrowForwardIcon />}
										onClick={onView}>
										<Typography>View</Typography>
									</BaseButton>
									{isSubscribed && (
										<BaseButton
											onClick={onUnsubscribe}
											className={styles.unsubscribe}
											endIcon={<DeleteForeverIcon style={{ color: '#FFFFFF' }} />}>
											<Typography style={{ color: '#FFFFFF' }}>Unsubscribe</Typography>
										</BaseButton>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Paper>
		</>
	)
}
