import { useState } from 'react'

import { Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { PagesProps } from 'src/@types'
import { cache } from 'src/apollo/state'
import { useLoginContext } from 'src/hooks/useLogin'

import { ControlTower, MainTitle, SelectPackageDialog } from '../../../../../../../components'
import { useGetClassTeacherByPkQuery, useGetTeacherQuery } from '../../../../../../../graphql/autogenerate/hooks'
import { buildBreadCrumbs, slugToName } from '../../../../../../../utils'
import { useClassStudentsContext } from '../useClassStudents'

export const ClassStudentsTitle = ({ page }: PagesProps) => {
	const { teacherId, classId, handleRefetch } = useClassStudentsContext()
	const {
		teacherData: teacher,
		teacherTemporarilyEnterprise,
		setTeacherTemporarilyEnterprise
	} = useLoginContext()

	const [isAddStudentsPlatformOpen, setIsAddStudentsPlatformOpen] = useState(false)
	const [isSelectPackageOpen, setIsSelectPackageOpen] = useState(false)

	const { data: classData, loading } = useGetClassTeacherByPkQuery({ variables: { teacherId, classId } })

	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(
					classData && classData.teacher_by_pk
						? classData.teacher_by_pk?.class_teachers[0]?.class?.title
						: ''
				)
			},
			{
				index: 2,
				overrideName: 'Students'
			}
		],
		params: {
			classId
		}
	})

	// refetch
	const { data: teacherData } = useGetTeacherQuery({
		variables: {
			teacherId: teacher.teacher_id
		},
		fetchPolicy: 'network-only'
	})

	// this functions relies on webhook to update that value properly
	// otherwise the fetchPolicy: 'network-only' is going to update it again
	const updateTeacherToEnterprise = () => {
		if (teacherData?.teacher_by_pk) {
			setTeacherTemporarilyEnterprise(true)
			cache.modify({
				id: cache.identify(teacherData?.teacher_by_pk),
				fields: {
					is_enterprise() {
						return true
					}
				},
				broadcast: false // Include this to prevent automatic query refresh */
			})
		}
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadCrumbs}
				title="Class Students"
				description="These are the Students that have been added to this Class."
				rightActions={[
					{
						style: {
							height: 56,
							padding: '0px 30px'
						},
						variant: 'contained',
						color: 'secondary',
						startIcon: <AddIcon />,
						name: 'Add Students',
						disabled: loading,
						onClick: () => {
							if (
								teacher.is_enterprise ||
								teacher.is_school_enterprise ||
								teacher.is_district_enterprise ||
								teacherTemporarilyEnterprise
							)
								setIsAddStudentsPlatformOpen(true)
							else setIsSelectPackageOpen(true)
						}
					}
				]}
			/>
			<SelectPackageDialog
				open={isSelectPackageOpen}
				setIsOpen={setIsSelectPackageOpen}
				onSuccess={() => setIsAddStudentsPlatformOpen(true)}
				updateTeacherToEnterprise={updateTeacherToEnterprise}
			/>
			<ControlTower
				classId={classId}
				classTitle={slugToName(
					classData && classData.teacher_by_pk?.class_teachers[0]?.class
						? classData.teacher_by_pk.class_teachers[0].class.title
						: ''
				)}
				isOpen={isAddStudentsPlatformOpen}
				setIsOpen={setIsAddStudentsPlatformOpen}
				onSuccess={handleRefetch}
			/>
		</Box>
	)
}
