import { createStyles, makeStyles } from '@material-ui/core'

export const useAppliedStickersStyles = makeStyles(() =>
	createStyles({
		container: {
			width: '100%',
			height: '100%'
		}
	})
)

export default useAppliedStickersStyles
