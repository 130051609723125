export const createScrollStopListener = (
	element: HTMLElement,
	callback: (event: Event) => void,
	timeout = 150
) => {
	let removed = false
	let handle: NodeJS.Timeout | undefined
	const onScroll = (event: Event) => {
		if (handle) {
			clearTimeout(handle)
		}
		handle = setTimeout(() => callback(event), timeout)
	}
	element.addEventListener('scroll', onScroll)

	return () => {
		if (removed) {
			return
		}
		removed = true
		if (handle) {
			clearTimeout(handle)
		}
		element.removeEventListener('scroll', onScroll)
	}
}
