import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { AssignmentsDetails } from './components/AssignmentDetails/AssignmentsDetails'
import { AssignmentsTitle } from './components/AssignmentsTitle/AssignmentsTitle'

export const Assignments = (props: PagesProps) => {
	return (
		<Box>
			<AssignmentsTitle {...props} />
			<AssignmentsDetails />
		</Box>
	)
}
