import { Loader } from 'appcues-loader'
import { GroupProperties, UserProperties, WurrlyAppcues } from 'src/@types'
import {
	Billing_Method_Enum,
	Class,
	GetSubscriptionData,
	Role_Name_Enum,
	Student,
	Teacher,
	Wurrly_Role_Enum
} from 'src/graphql/autogenerate/schemas'

import { environment, EnvironmentEnum } from '../environment'

export const appcuesInitTeacherV1 = async (data: Teacher, subscription: GetSubscriptionData) => {
	if (environment.env !== EnvironmentEnum.production) return

	const loader = new Loader(environment.appcuesAccountId)
	const appcues = (await loader.load()) as WurrlyAppcues

	const { teacher_id, name_first, name_last, email, school, role_name, is_enterprise, teacher_integrations } =
		data

	if (!teacher_id) return

	const signUpSource =
		teacher_integrations.find((item) => item.is_sso)?.integration_name ?? 'WurrlyEduRefactor-web'
	const integration_name = teacher_integrations[0]?.integration_name ?? 'none' // Should be the latest used integration
	const { hasSubscription, billingMethod, price, periodStart, periodEnd } = subscription
	const subscriptionBillingMethod =
		hasSubscription && billingMethod ? (billingMethod as Billing_Method_Enum) : 'none'
	const subscriptionPrice = price ?? 0
	const subscriptionStart = periodStart ? new Date(periodStart).toISOString() : undefined
	const subscriptionEnd = periodEnd ? new Date(periodEnd).toISOString() : undefined

	let teacherMetadata: UserProperties = {}
	let groupMetadata: GroupProperties = {}
	const userId = String(teacher_id ?? 0)
	const firstName = name_first ?? ''
	const lastName = name_last ?? ''

	teacherMetadata = {
		firstName,
		lastName,
		fullName: `${firstName ? firstName + ' ' : ''}${lastName || ''}`,
		email: email || '',
		ncesId: school?.nces_id || '',
		schoolName: school?.name || '',
		state: school?.state || '',
		district: school?.district?.name || '',
		signUpSource,
		integration: integration_name,
		role: role_name || Role_Name_Enum.Private,
		licenseStatus: !!is_enterprise,
		membershipLevel: subscriptionBillingMethod,
		planPrice: subscriptionPrice,
		contractStartDate: subscriptionStart || '',
		contractEndDate: subscriptionEnd || ''
	}

	appcues.identify(userId, teacherMetadata)

	if (school && school.school_id) {
		const schoolId = String(school.school_id)
		groupMetadata = {
			schoolName: school.name,
			ncesId: String(school.nces_id),
			district: school.district?.name || '',
			licenseStatus: !!is_enterprise,
			membershipLevel: subscriptionBillingMethod,
			planPrice: subscriptionPrice,
			contractStartDate: subscriptionStart || '',
			contractEndDate: subscriptionEnd || ''
		}

		appcues.group(schoolId, groupMetadata)
	}
	appcues.track('login')
}

export const appcuesInitStudentV1 = async (student: Student, teacher: Teacher, firstClass: Class) => {
	if (environment.env !== EnvironmentEnum.production) return

	const loader = new Loader(environment.appcuesAccountId)
	const appcues = (await loader.load()) as WurrlyAppcues

	const { student_id, wurrly_role, student_integrations } = student
	const { school, role_name: teacherRole } = teacher

	let studentMetadata: UserProperties = {}
	const userId = String(student_id)

	studentMetadata = {
		schoolName: school?.name || '',
		districtName: school?.district?.name || '',
		className: firstClass.title || '',
		teacherName: `${teacher.name_first ? teacher.name_first + ' ' : ''}${teacher.name_last}`,
		gradeLevel: firstClass.grade?.name || '',
		integration: student_integrations.length ? student_integrations[0]?.integration_name : 'none',
		role: wurrly_role || Wurrly_Role_Enum.Student
	}

	appcues.identify(userId, studentMetadata)

	// Students added by a teacher from a school use School Name + NCES Number (i.e 21st Century Preparatory School Agency 5500045).
	// If added by a teacher from a Private School / Camp use Organization Name
	let accountId = ''
	if (teacherRole === Role_Name_Enum.School) {
		if (school?.name) accountId = school?.name + (school?.nces_id ? ` ${school?.nces_id}` : '')
		else accountId = school?.nces_id || ''
	} else accountId = teacher.organization_name || ''

	if (accountId) {
		appcues.group(accountId)
	}
	appcues.track('login')
}
