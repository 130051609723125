import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentDetailsStyles = makeStyles(() =>
	createStyles({
		classSelect: { width: '50%', marginRight: '30px', height: 50 },
		dateSelect: { width: '50%', height: 50 },
		divider: { margin: '40px 0 0 0' },
		tiles: { marginTop: 20 }
	})
)

export default useAssignmentDetailsStyles
