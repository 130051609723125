import { useState } from 'react'

import { Box, Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import { CarouselOfAny } from 'src/components'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useUpdateProductivityMutation } from 'src/graphql/autogenerate/hooks'
import { Assignment_Tip } from 'src/graphql/autogenerate/schemas'
import { getVideoGenres } from 'src/utils'

import useStyles from './StepOneForm.styles'

type StepOneFormProps = {
	assignmentTips: Assignment_Tip[]
	studentId: number
	classId: number
}

export const StepOneForm = ({ assignmentTips, studentId, classId }: StepOneFormProps) => {
	const styles = useStyles()

	const [upsertProductivity] = useUpdateProductivityMutation()
	const [startedPlaying, setStartedPlaying] = useState(false)

	const handleOnPlay = () => {
		upsertProductivity({
			variables: {
				studentId,
				classId,
				day: Number(moment().format('DD')),
				month: Number(moment().format('MM')),
				year: Number(moment().format('YYYY')),
				videoViews: 1,
				practiceSessions: 0,
				recordingSessions: 0
			}
		})
		setStartedPlaying(true)
	}

	return (
		<Box>
			<Typography className={styles.title}>
				<b>Watch Video</b>
			</Typography>
			{!assignmentTips?.length && (
				<Typography className={styles.noVideosText} color="textSecondary">
					This assignment doesn't have any videos.
				</Typography>
			)}
			<CarouselOfAny
				slidesPerPage={{ xs: 1, sm: 1 }}
				spacing={1}
				useCarouselOnSingle={true}
				data={[
					...assignmentTips.map((tips) => ({
						element: (
							<Box className={styles.margin}>
								<Box className={styles.position}>
									<Paper elevation={2} className={styles.paper}>
										<Box className={styles.playerWrapper}>
											<ReactCustomVideoPlayer
												width="100%"
												height="100%"
												playing={startedPlaying}
												onPlay={handleOnPlay}
												className={styles.player}
												url={tips.tip?.resource_path}
											/>
										</Box>
										<Box mt={2}>
											<Typography variant="h5">{tips.tip?.title}</Typography>
											<Typography variant="body1">{tips.tip?.description}</Typography>
											<Typography variant="subtitle1" color="textSecondary">
												{tips.tip && '• ' + getVideoGenres(tips.tip)}
											</Typography>
										</Box>
									</Paper>
								</Box>
							</Box>
						),
						linkTo: {}
					}))
				]}
				renderItem={(item) => (item.element ? item.element : <div />)}
			/>
		</Box>
	)
}
