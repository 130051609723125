import { useEffect, useState } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import ClassicEditor from 'capicua-wurrly-refactor-ckeditor5'
import { useFormik } from 'formik'
import { InputField } from 'src/components/Inputs/InputField/InputField'
import { InputHeader } from 'src/components/Inputs/InputHeader'
import { useStepThreeValidation } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useStepThreeValidation'
import { LessonFormInitialDataType } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonEdit/useInitialLessonData'
import { defaulStepThreeArticleTitles, TitleLimit } from 'src/scenes/Teacher/scenes/6-Lessons/utils/constants'
import { Loader, UploadAdapterCKEditor } from 'src/utils/uploadAdapterCKEditor'

import { LessonFormDataType, StepFormType } from '.'
import { mediaEmbed } from '../../utils/mediaEmbed'
import { useStyles } from './styles'
import { CustomTooltip } from './Tooltips'

export const StepThreeForm = ({ saveData, lessonData, handleInitialData }: StepFormType) => {
	const [isLoading, setIsLoading] = useState(true)
	const { stepThreeValidationSchema, limit } = useStepThreeValidation()
	const classes = useStyles()
	const formik = useFormik({
		initialValues: {
			stepThreeArticles: defaulStepThreeArticleTitles.map((article) => ({
				...article,
				content: '',
				articleLen: 0
			}))
		},
		validationSchema: stepThreeValidationSchema,
		validateOnMount: true,
		onSubmit: () => {}
	})

	useEffect(() => {
		let dataSource: LessonFormDataType | LessonFormInitialDataType

		if (handleInitialData?.initialData && !handleInitialData.stepHasFilledInitialData) {
			dataSource = handleInitialData.initialData
			handleInitialData.setInitialDataUsed()
		} else dataSource = lessonData

		if ('stepThreeArticles' in dataSource && dataSource?.stepThreeArticles?.length) {
			formik.setValues({
				stepThreeArticles: dataSource?.stepThreeArticles?.map((article, index) => {
					return {
						...defaulStepThreeArticleTitles[index],
						title: article.title || '',
						content: article.content || '',
						articleLen: article.content?.length || 0
					}
				})
			})
			setIsLoading(false)

			return
		}
		// if is the old version, we proceed to format it and continue
		const { takeItFurther, materials, differentiations, suggestedAssignments } =
			dataSource as LessonFormDataType

		formik.setValues({
			stepThreeArticles: [
				{
					...defaulStepThreeArticleTitles[0],
					content: takeItFurther || '',
					articleLen: takeItFurther?.length || 0
				},
				{
					...defaulStepThreeArticleTitles[1],
					content: materials || '',
					articleLen: materials?.length || 0
				},
				{
					...defaulStepThreeArticleTitles[2],
					content: differentiations || '',
					articleLen: differentiations?.length || 0
				},
				{
					...defaulStepThreeArticleTitles[3],
					content: suggestedAssignments || '',
					articleLen: suggestedAssignments?.length || 0
				}
			]
		})
		setIsLoading(false)
	}, [])

	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	const getClassName = (value: string, limit: number) => {
		if (value === '') return 'Default'
		if (value.length <= limit) return 'Success'
		else return 'Error'
	}

	const getClassNameWrapper = (val: string, limit: number) => {
		return classes[`text${getClassName(val, limit)}` as keyof typeof classes]
	}

	if (isLoading) return <CircularProgress />

	return (
		<Box marginTop="80px">
			<form onSubmit={formik.handleSubmit}>
				{formik.values.stepThreeArticles.map((article, index) => {
					return (
						<Box marginTop="40px" key={`title-${index}`} display={'flex'} flexDirection={'column'}>
							<Box display="flex" flexDirection={'column'} justifyContent="space-between" mb={2}>
								<InputHeader
									name={`Title *`}
									endAdornment={
										<Typography className={getClassNameWrapper(article.title, TitleLimit)}>
											{article.title.length}/{TitleLimit}
										</Typography>
									}
								/>
								<InputField
									id={`Title-${index}`}
									name={`Title-${index}`}
									value={article.title}
									onChange={(e) => {
										if ('value' in e.target) {
											formik.setFieldValue(
												`stepThreeArticles[${index}].title`,
												e.target.value
											)
										}
									}}
									onFocus={() => formik.setFieldTouched(`stepThreeArticles[${index}].title`)}
									error={
										Boolean(
											(formik.errors.stepThreeArticles?.[index] as { title: string })?.title
										) && formik.touched?.stepThreeArticles?.[index]?.title
									}
									helperText={
										formik.touched.stepThreeArticles?.[index]?.title
											? (formik.errors.stepThreeArticles?.[index] as { title: string })
													?.title
											: undefined
									}
									placeholder="Enter Title"
									fullWidth
								/>
							</Box>
							<Box
								display="flex"
								alignItems="center"
								style={{
									marginLeft: 'auto'
								}}>
								<Typography
									className={getClassNameWrapper(article.content, limit)}
									color="textSecondary">
									{`${article.articleLen}/${limit}`}
								</Typography>
								<CustomTooltip content={article.tooltip} />
							</Box>

							<CKEditor
								editor={ClassicEditor}
								data={article.content}
								id="inspire-editor"
								onReady={(editor) => {
									editor.plugins.get('FileRepository').createUploadAdapter = (
										loader: Loader
									) => {
										return new UploadAdapterCKEditor(loader)
									}
								}}
								config={{
									mediaEmbed,
									placeholder: article.placeholder,
									wordCount: {
										container: window.document.createElement('div'),
										onUpdate: (stats: { [key: string]: string }) => {
											formik.setFieldValue(
												`stepThreeArticles[${index}].articleLen`,
												stats.words
											)
										}
									}
								}}
								onChange={(_: unknown, editor: { getData: () => string }) => {
									const data = editor.getData()
									formik.setFieldValue(`stepThreeArticles[${index}].content`, `${data}`)
								}}
							/>
						</Box>
					)
				})}
			</form>
		</Box>
	)
}
