import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStudioStyles = makeStyles((theme: Theme) =>
	createStyles({
		recordBtn: {
			position: 'absolute',
			top: '45px',
			left: '33px',
			zIndex: 2,
			'&.MuiButton-root': {
				width: '67px',
				height: '57px'
			},
			'&.MuiButton-root.selected': {
				backgroundColor: '#FFEFD3'
			}
		},
		mirrorBtn: {
			position: 'absolute',
			top: '45px',
			left: '140px',
			zIndex: 2,
			'&.MuiButton-root': {
				width: '67px ',
				height: '57px '
			},
			'&.MuiButton-root.selected': {
				backgroundColor: '#FFEFD3'
			}
		},
		mirrorIconSelected: {
			fill: theme.palette.secondary.main
		},
		noBackground: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			'&:hover': {
				backgroundColor: 'transparent',
				boxShadow: 'none'
			}
		},
		videoContainer: {
			position: 'relative',
			width: '100%',
			height: '100%',
			aspectRatio: '1.777 / 1',
			background: 'white'
		},
		bottomActionBtn: {
			height: '57px',
			width: '125px'
		},
		hidden: {
			display: 'none'
		},
		stickers: {
			position: 'absolute',
			top: 0,
			left: 0
		},
		reviewControls: {
			display: 'flex',
			alignItems: 'center'
		}
	})
)

export const useDialogPaperStyles = makeStyles(
	() => ({
		dialogPaper: {
			'&.MuiDialog-paper': {
				padding: 0
			}
		}
	}),
	{ index: 10 }
)

export const useDialogButtonStyles = makeStyles(
	() => ({
		editBtn: {
			marginRight: '1em',
			'&.MuiButton-root': {
				height: '57px',
				width: '57px',
				paddingLeft: '15px',
				paddingTop: '3px'
			}
		},
		scrubberBtn: {
			'&.MuiButton-root': {
				height: '57px',
				width: '57px'
			}
		},
		playBtn: {
			borderRadius: '100%',
			backgroundColor: 'transparent',
			marginLeft: '2.5em',
			'&.MuiButton-contained': {
				boxShadow:
					'0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 4px 6px 0px rgba(0, 0, 0, 0.14)'
			},

			'&:hover': {
				backgroundColor: 'transparent',
				boxShadow:
					'0px 2px 2px 0px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 4px 6px 0px rgba(0, 0, 0, 0.14)'
			},
			'&.MuiButton-root': {
				height: '5.5em',
				width: '5.5em',
				marginRight: '2em',
				padding: '0px'
			},
			'& span.MuiTouchRipple-root': {
				borderRadius: '100%'
			},
			'&.MuiButton-contained:active': {
				boxShadow: 'none'
			}
		}
	}),
	{ index: 10 }
)

export default useStudioStyles
