import React, { useEffect, useState } from 'react'

import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	Grid,
	List,
	ListItem,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import { useHistory } from 'react-router-dom'
import { ArrayElement } from 'src/@types'
import { DoubleMusicNoteIcon, LessonsIcon, PersonIcon, VideoIcon } from 'src/assets/icons'
import { ImageButton, BaseButton, DeleteItemDialog, ListStudent, SelectSortOrderBy, NoItems } from 'src/components'
import { ListCardDialog } from 'src/components/Dialogs/ListCardDialog'
import { useDeleteStudentByIdMutation, useGetStudentClassesLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Class, Order_By, Student } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, DEFAULT_MUSIC_BG, formatDate, getCyKey } from 'src/utils'

import { ReInviteStudentDialog } from '../../../../3-Students/components/ReInviteStudentDialog'
import { useClassStudentsContext } from '../useClassStudents'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		panel: {
			width: '100%',
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white
			},
			'& .MuiButton-root:not(:last-child)': {
				marginRight: 5
			},
			'& .MuiButton-root:first-child': {
				marginLeft: 32
			},
			// view button
			'& .MuiButton-root:last-child': {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				marginRight: 32
			}
		}
	})
)

export const ClassStudentsGrid = () => {
	const { teacherId, classId, handleRefetch, sort, setSort, students, count, loading, isFetching, moreResults } =
		useClassStudentsContext()
	const history = useHistory()
	const [isStudentDetails, setIsStudentDetails] = useState(false)
	const [studentName, setStudentName] = useState<string>('')
	const [studentDetails, setStudentDetails] = useState<Student | undefined>()
	const [studentDelete, setStudentDelete] = useState<Student | undefined>()
	const [studentReInvite, setStudentReInvite] = useState<Student | undefined>()
	const [studentIdToDelete, setStudentIdToDelete] = useState<number>()
	const [deleteStudent] = useDeleteStudentByIdMutation()
	const [studentClassesQuery, { data: classData, loading: classLoading }] = useGetStudentClassesLazyQuery()

	const classes =
		classData?.teacher_by_pk?.class_teachers.map((teacherClass) => teacherClass.class as Class) || []
	const studentClasses = classData?.teacher_by_pk?.class_teachers_aggregate.aggregate?.count || 0

	const dialogMessages = {
		mainMessages: {
			title: 'Remove this Student from your Class?',
			body: "All of the Student's class work will be lost, but you will be able to re-invite them at any time.",
			buttonLabel: 'Remove Student'
		},
		confirmation: {
			title: 'Student Removed!',
			body: 'You have successfully removed this Student from your Class.'
		}
	}

	useEffect(() => {
		handleRefetch()
	}, [sort])

	useEffect(() => {
		if (studentDelete) {
			setStudentIdToDelete(studentDelete.student_id)
			setStudentName(studentDelete.first_name)
			setStudentDelete(undefined)
		}
	}, [studentDelete])

	useEffect(() => {
		if (studentDetails) {
			setIsStudentDetails(true)
			studentClassesQuery({
				variables: { studentId: studentDetails.student_id, teacherId }
			})
		}
	}, [studentDetails])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as Order_By
		setSort(selectedSort)
	}

	const ClassList = () => {
		const styles = useStyles()

		// students, lessons, songs and videos icons
		const LinePanel = ({ classElement }: { classElement: ArrayElement<typeof classes> }) => {
			const iconSize = 20

			return (
				<Box className={styles.panel} display="flex" flexWrap="wrap" alignItems="center">
					<Button startIcon={<PersonIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_students_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<Button startIcon={<LessonsIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_lesson_plans_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<Button startIcon={<DoubleMusicNoteIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_songs_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<Button startIcon={<VideoIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_tips_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<div style={{ flexGrow: 1 }} />
					{/* <Button endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}>View</Button> */}
				</Box>
			)
		}

		return (
			<Box>
				{!classLoading && classes && (
					<List>
						{classes.map((classElement, index) => (
							<Box key={'item-' + index} width="100%">
								<ListItem>
									<ImageButton
										titleHeight={{ xs: 250, md: 277 }}
										backgroundImageUrl={classElement.image_path || DEFAULT_MUSIC_BG}
										title={
											<Box display="flex">
												<Typography style={{ fontSize: 28 }}>
													{classElement.title}
												</Typography>
												<div style={{ flexGrow: 1 }} />
												<BaseButton
													variant="contained"
													startIcon={<NotificationImportantIcon />}
													style={{
														color: '#ff0000',
														backgroundColor: 'rgba(255, 0, 0, 0.25)'
													}}
													// color={COLORS.error.main}
												>
													<Typography>2</Typography>
												</BaseButton>
											</Box>
										}
										titlePosition="initial"
										subtitle={
											<Box display="flex" mt={1.5}>
												<Typography style={{}}>
													<CalendarTodayIcon />{' '}
													{classElement.start_date
														? formatDate(classElement.start_date)
														: 'Missing Start Date'}{' '}
													-{' '}
													{classElement.end_date
														? formatDate(classElement.end_date)
														: 'Missing Start Date'}
												</Typography>
											</Box>
										}
										element={<LinePanel classElement={classElement} />}
										elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
										linkTo={{}}
									/>
								</ListItem>
							</Box>
						))}
					</List>
				)}

				{classLoading && (
					<Box style={{ margin: 20, textAlign: 'center' }}>
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
			</Box>
		)
	}

	return (
		<Box>
			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<Typography data-cy={getCyKey(ClassStudentsGrid, 'studentsCount')} variant="h6">
							<b>Students ({count})</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						{(count || 0) !== 0 && (
							<SelectSortOrderBy cyKey="Students" value={sort} onChange={handleSort} />
						)}
					</Box>
				</Grid>
			</Grid>
			{loading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!loading && (
				<Grid container spacing={3}>
					<Grid item xs>
						{count ? (
							<ListStudent
								data={students}
								classId={classId}
								spacing={1.5}
								deleteStudent={setStudentDelete}
								detailsStudent={setStudentDetails}
								reinviteStudent={setStudentReInvite}
								overrideOnClick={(studentId) => {
									history.push(
										buildRouteParameters(
											Pages.ClassStudentDetail, // Route with paramters
											{ classId, studentId }
										)
									)
								}}
							/>
						) : (
							<NoItems
								title="You haven't added any Students to this Class yet."
								description="It's easy to add Students. Just click on the button above to find more."
							/>
						)}
						{isFetching && moreResults && (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
						{!loading && students.length > 0 && !moreResults && (
							<Box mt={4} textAlign="center">
								<Button
									onClick={() => {
										window.scrollTo(0, 0)
									}}
									variant="text"
									color="secondary">
									Back to Top
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>
			)}
			<DeleteItemDialog
				itemName={studentName}
				isOpen={!!studentIdToDelete}
				setIsOpen={setStudentIdToDelete}
				onConfirm={async () => {
					try {
						await deleteStudent({
							variables: {
								studentId: Number(studentIdToDelete),
								classId: Number(classId)
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_class_student?.returning[0].class
								if (!classToUpdateInCache) return
								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'class_students'
								})
								cache.evict({
									id: identify,
									fieldName: 'class_students_aggregate'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'student'
								})
								data?.delete_submission?.returning.forEach((classAssignment) => {
									if (!classAssignment.class_assignment) return
									const classAssignmentCacheId = cache.identify(classAssignment.class_assignment)
									cache.evict({
										id: classAssignmentCacheId,
										fieldName: 'submission'
									})
									cache.evict({
										id: classAssignmentCacheId,
										fieldName: 'submission_aggregate'
									})
								})
							}
						})
					} catch (e) {
						console.error(e)
					}
				}}
				onDone={handleRefetch}
				optionalMessages={dialogMessages}
			/>

			<ListCardDialog
				open={isStudentDetails}
				setOpen={setIsStudentDetails}
				onClose={() => {
					setIsStudentDetails(false)
					setStudentDetails(undefined)
				}}
				onConfirm={() => {
					history.push(Pages.Classes.path)
				}}
				title={`${studentDetails?.first_name} Classes` || ''}
				description={`${studentClasses} Classes`}
				list={<ClassList />}
				setSelectedStudent={setStudentDetails}
			/>

			<ReInviteStudentDialog studentReinvite={studentReInvite} setStudentReinvite={setStudentReInvite} />
		</Box>
	)
}
