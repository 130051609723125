import { PagesProps } from 'src/@types'
import { useLoginContext } from 'src/hooks/useLogin'
import { LessonsCatalogBody } from 'src/scenes/Teacher/scenes/6-Lessons/components/LessonsCatalogBody'
import { buildBreadCrumbs } from 'src/utils'
export const LessonsAll = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()

	const teacherId = teacher.teacher_id

	const title = page.name

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: title || ''
			}
		],
		params: {}
	})

	return <LessonsCatalogBody breadcrumbs={breadcrumbs} teacherId={teacherId} title={title} />
}
