import { createTheme } from '@material-ui/core'

import { COLORS } from './colors'

export const createBaseStyles = (darkMode: boolean) =>
	createTheme({
		palette: {
			type: darkMode ? 'dark' : 'light',
			...COLORS
		},
		props: {
			MuiButton: {
				variant: 'contained'
			},
			MuiCircularProgress: {
				color: 'secondary'
			}
		},
		overrides: {
			MuiButton: {
				root: {
					textTransform: 'none'
				}
			},
			MuiCssBaseline: {
				'@global': {
					body: {
						color: COLORS.primary.text,
						backgroundColor: COLORS.appBackground
					},
					'*::-webkit-scrollbar': {
						width: '4px',
						height: '4px'
					},
					'*::-webkit-scrollbar-track': {
						borderRadius: '4px',
						backgroundColor: 'rgba(174, 174, 174, 24%)'
					},
					'*::-webkit-scrollbar-thumb': {
						borderRadius: '4px',
						backgroundColor: 'rgba(174, 174, 174, 100%)'
					},
					'*::-webkit-scrollbar-button': {
						display: 'none'
					}
				}
			}
		}
	})
