import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useEffectsTabsStyles = makeStyles((theme: Theme) =>
	createStyles({
		tabsContainer: {
			position: 'absolute',
			bottom: 0,
			left: '50%',
			transform: 'translateX(-50%)',
			width: '60%',
			padding: '5px 20px',
			height: '185px',
			background: 'rgba(255, 255, 255, 0.95)',
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
			zIndex: 10
		},
		effectContainer: {
			width: '100%',
			height: '100%',
			paddingBottom: '40px'
		},
		tabs: {
			minWidth: '60px',
			'& .MuiTabs-indicator': {
				backgroundColor: '#1D273D',
				height: '3px'
			},
			'& .MuiTab-root': {
				textTransform: 'none'
			},
			[theme.breakpoints.up('md')]: {
				'& .MuiTab-root': {
					minWidth: 0
				}
			}
		},
		tabName: {
			fontSize: '0.9rem'
		},
		closeButton: {
			position: 'absolute',
			top: '3px',
			right: '3px',
			width: '15px',
			height: '15px'
		}
	})
)

export default useEffectsTabsStyles
