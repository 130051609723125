import { useEffect } from 'react'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Song } from 'src/graphql/autogenerate/schemas'

import { OverridePageTitle } from '../../types'
import SelectedSongs from './components/SelectedSongs'
import { WholeCatalogContainer as WholeCatalog } from './components/WholeCatalog/WholeCatalog.container'
import useStyles from './StepThreeForm.styles'

type StepThreeFormProps = {
	songs: Song[]
	useAllSongs: boolean
	setParentTitle: (newDescription: OverridePageTitle | undefined) => void
	classId: number
	selectedSongId: number
	setSelectedSongId: (newId: number) => void
	goToAssignmentStudio: (songId: number) => void
}

const StepThreeForm = ({
	songs,
	useAllSongs,
	setParentTitle,
	classId,
	selectedSongId,
	setSelectedSongId,
	goToAssignmentStudio
}: StepThreeFormProps) => {
	const styles = useStyles()

	useEffect(() => {
		if (useAllSongs) {
			setParentTitle({
				title: 'Browse',
				description: (
					<Typography className={styles.description}>
						Get inspired by the songs you love! Find a Song and get to the studio to practice and
						record, or Favorite it for later!
					</Typography>
				)
			})
		} else {
			setParentTitle(undefined)
		}

		return () => setParentTitle(undefined)
	}, [useAllSongs])

	return (
		<Box>
			{useAllSongs ? (
				<WholeCatalog classId={classId} overrideSongOnClick={goToAssignmentStudio} />
			) : (
				<SelectedSongs
					songs={songs}
					selectedSongId={selectedSongId}
					setSelectedSongId={setSelectedSongId}
				/>
			)}
		</Box>
	)
}

export default StepThreeForm
