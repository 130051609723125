import { useEffect, useState } from 'react'

import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useHistory } from 'react-router'
import { PagesProps } from 'src/@types'
import { BigBaseButton, InfoDialog, MainTitle, Stepper } from 'src/components'
import { useCreateAssignmentMutation } from 'src/graphql/autogenerate/hooks'
import { useScroll } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, buildRouteParameters, getCyKey, getTimetzFromStringDate, scrollToTop } from 'src/utils'

import useStyles from './AddAssignment.styles'
import { AssignmentFormDataType } from './AddAssingment.types'
import { StepOneForm } from './components/StepOneForm/StepOneForm'
import { StepTwoForm } from './components/StepTwoForm/StepTwoForm'

type AddAssignmentProps = PagesProps & {
	initialData?: AssignmentFormDataType
}

export const AddAssignment = ({ page, initialData }: AddAssignmentProps) => {
	useScroll()
	const [currentStep, setCurrentStep] = useState(1)
	const [assignmentData, setAssignmentData] = useState<AssignmentFormDataType>({})
	const [stepIsValid, setStepIsValid] = useState(false)
	const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
	const [isExitDialogOpen, setIsExitDialogOpen] = useState(false)
	const [createdAssignmentId, setCreatedAssignmentId] = useState<number>()
	const breadcrumbs = buildBreadCrumbs({ page })
	const styles = useStyles()
	const history = useHistory()
	const [insertAssignment, { loading: isCreatingAssignment }] = useCreateAssignmentMutation()
	const [stepsUsedInitialData, setStepsUsedInitialData] = useState({ 1: false, 2: false })
	useEffect(() => {
		if (initialData) setAssignmentData(initialData)
	}, [initialData])

	const saveData = (stepData: AssignmentFormDataType) => {
		setAssignmentData((prev) => ({ ...prev, ...stepData }))
	}

	const handleStepUsedInitialData = (step: number) => {
		if (step in stepsUsedInitialData) {
			setStepsUsedInitialData((prev) => ({ ...prev, [step]: true }))
		}
	}

	const steps = [
		{
			stepName: 'Assignment Details',
			component: (
				<StepOneForm
					saveData={saveData}
					assignmentData={assignmentData}
					setStepIsValid={setStepIsValid}
					handleInitialData={{
						initialData,
						stepHasFilledInitialData: stepsUsedInitialData[1],
						setInitialDataUsed: () => handleStepUsedInitialData(1)
					}}
				/>
			)
		},
		{
			stepName: `Add Content`,
			component: (
				<StepTwoForm
					saveData={saveData}
					assignmentData={assignmentData}
					setStepIsValid={setStepIsValid}
					handleInitialData={{
						initialData,
						stepHasFilledInitialData: stepsUsedInitialData[2],
						setInitialDataUsed: () => handleStepUsedInitialData(2)
					}}
				/>
			)
		}
	]

	const openSuccessDialog = () => setIsSuccessDialogOpen(true)
	const closeSuccessDialog = () => setIsSuccessDialogOpen(false)
	const openExitDialog = () => setIsExitDialogOpen(true)
	const closeExitDialog = () => setIsExitDialogOpen(false)
	const returnToAssignments = () => history.push(Pages.Assignments.path)
	const goToSelectedClass = () => {
		history.push(
			buildRouteParameters(Pages.ClassDetail, { classId: assignmentData.classes?.[0]?.class_id ?? 0 })
		)
	}
	const goToCreatedAssignment = () => {
		history.push(
			buildRouteParameters(Pages.ClassAssignmentDetail, {
				classId: assignmentData.classes?.[0]?.class_id ?? 0,
				classAssignmentId: createdAssignmentId ?? 0
			})
		)
	}
	const cleanForm = () => {
		setAssignmentData({})
		setCurrentStep(1)
		closeSuccessDialog()
		setTimeout(scrollToTop)
	}
	const handleSaveNextBtn = () => {
		if (currentStep < steps.length) {
			// Next step
			setCurrentStep((prevStep) => prevStep + 1)
			scrollToTop()
		} else if (stepIsValid) {
			saveAssignment()
		}
	}
	const saveAssignment = async () => {
		try {
			const variables = getMutationVariables()
			const { data } = await insertAssignment({
				variables,
				update: (cache) => {
					cache.evict({
						id: `lesson_plan:${assignmentData.reference_lesson_plan_id}`,
						fieldName: 'assignment_created'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'class_assignment'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'class_assignment_aggregate'
					})
				}
			})
			setCreatedAssignmentId(data?.customCreateAssignment?.classAssignmentIds?.[0] ?? 0)
			openSuccessDialog()
		} catch (error) {
			console.error('Could not upload Assignment:', error)
		}
	}
	const getMutationVariables = () => {
		const classes = assignmentData.classes?.map((item) => item.class_id) ?? []
		const songs = assignmentData.songs?.map((item) => item.song_id) ?? []
		const videos = assignmentData.videos?.map((item) => item.tip_id) ?? []
		const dueDate = (assignmentData.due_date ?? new Date()).toISOString().substring(0, 10)
		const dueTime = getTimetzFromStringDate(assignmentData.due_time ?? new Date())
		const assets =
			assignmentData.assets?.map(({ resource_path, name }) => ({ resourcePath: resource_path, name })) ?? []
		const lessonPlanId = assignmentData.reference_lesson_plan_id

		return {
			name: assignmentData.name ?? '',
			classes,
			instructions: assignmentData.instructions ?? '',
			dueDate,
			dueTime,
			gradingSystemId: assignmentData.grading_system ?? 1,
			songs,
			includeAllSongs: !!assignmentData.include_all_songs,
			videos,
			assets,
			lessonPlanId,
			allowExternalFiles: assignmentData.allow_external_files
		}
	}

	const handleBackCancelBtn = () => {
		if (currentStep > 1) {
			// Prev step
			setCurrentStep((prevStep) => prevStep - 1)
			scrollToTop()
		} else {
			openExitDialog()
		}
	}

	return (
		<>
			<Box>
				<MainTitle
					breadcrumbs={breadcrumbs}
					title="Add Assignment"
					description={
						<Typography className={styles.titleDescription}>
							Please fill in all the required fields to set up your Assignment.
						</Typography>
					}
				/>
				<Stepper
					stepDescription={steps[currentStep - 1]?.stepName ?? ''}
					currentStep={currentStep}
					totalSteps={steps.length}
					stepBoxProps={{ mt: 6 }}
					stepDescriptionClassName={'text'}
				/>

				<Box className={styles.stepContainer}>{steps[currentStep - 1]?.component ?? null}</Box>

				<Box className={styles.bottomButtonsBox}>
					<BigBaseButton className={styles.bottomButton} onClick={handleBackCancelBtn}>
						<Typography>{currentStep === 1 ? 'Cancel' : 'Back'}</Typography>
					</BigBaseButton>
					<BigBaseButton
						data-cy={getCyKey(BigBaseButton, 'nextButton')}
						className={styles.bottomButton}
						color="secondary"
						onClick={handleSaveNextBtn}
						disabled={!stepIsValid || isCreatingAssignment}>
						<Typography>
							{currentStep === steps.length ? 'Save' : 'Next'}
							{isCreatingAssignment && <CircularProgress size="1em" className={styles.spinner} />}
						</Typography>
					</BigBaseButton>
				</Box>
			</Box>
			<InfoDialog
				open={isSuccessDialogOpen}
				icon={<CheckIcon />}
				title="Assignment Created!"
				body="You have successfully added the Assignment to your Class!"
				discardLabel="Add Another"
				confirmLabel="View Assignment"
				onDiscard={cleanForm}
				onConfirm={goToCreatedAssignment}
				footer={
					<Button
						color="secondary"
						onClick={goToSelectedClass}
						disabled={!assignmentData.classes?.length}>
						View Class
					</Button>
				}
			/>
			<InfoDialog
				open={isExitDialogOpen}
				icon="!"
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				confirmLabel="No, Cancel"
				onDiscard={returnToAssignments}
				onConfirm={closeExitDialog}
				onClose={closeExitDialog}
			/>
		</>
	)
}
