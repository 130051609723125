import { ChangeEvent, useEffect, useState } from 'react'

import { Box, Checkbox, FormControlLabel, Link, Paper, TextField, Typography } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'

import { BaseAlert } from '../../../../../components/BaseAlert'
import { useGetLinkMetaDataLazyQuery } from '../../../../../graphql/autogenerate/hooks'

const useTextStyles = makeStyles((theme) => ({
	root: {
		padding: '4px 14px 4px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	},
	input: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderStyle: 'hidden'
		},
		padding: 0,
		marginLeft: theme.spacing(1),
		flex: 1,
		color: theme.palette.text.disabled
	},
	inputError: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderStyle: 'hidden'
		},
		'& .MuiOutlinedInput-input': {
			color: theme.palette.error.main
		},
		padding: 0,
		marginLeft: theme.spacing(1),
		flex: 1
	},
	cancelButton: {
		padding: 5,
		color: theme.palette.text.disabled
	},
	progressText: {
		'& .MuiAlert-message': {
			padding: 4
		},
		color: fade(theme.palette.warning.dark, 0.7),
		padding: '0 4px'
	},
	completeIcon: {
		color: fade(theme.palette.success.dark, 0.7)
	},
	divider: {
		height: 28,
		margin: 4
	}
}))
type LinkVideoType = {
	handleLink: (data: {
		url: string
		title: string
		description: string
		image_path: string
		terms: boolean
	}) => void
	setNextEnabled?: (enabled: boolean) => void
}
export const LinkVideo = ({ handleLink, setNextEnabled }: LinkVideoType) => {
	const [value, setValue] = useState<string>('')
	const [error, setError] = useState<string>()
	const [terms, setTerms] = useState<boolean>(false)
	const textClasses = useTextStyles()
	const [loadMetadata, { data: urlMetaData, loading: urlMetaDataLoading, error: urlMetaDataError }] =
		useGetLinkMetaDataLazyQuery()

	useEffect(() => {
		if (terms && !urlMetaDataLoading && urlMetaData) {
			const data = {
				url: urlMetaData.linkMetaData?.url ?? '',
				title: urlMetaData.linkMetaData?.title ?? '',
				description: urlMetaData.linkMetaData?.description ?? '',
				image_path: urlMetaData.linkMetaData?.images
					? (urlMetaData.linkMetaData?.images[0] as string)
					: '',
				terms
			}
			handleLink(data)
			setError(undefined)
		} else if (urlMetaDataError) {
			setError(urlMetaDataError.message)
		}
	}, [terms, urlMetaData])

	const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (e.target.value === '' && setNextEnabled) {
			setError('ERROR: Please enter a valid link')
			setNextEnabled(false)
		}
		setValue(e.target.value)
		loadMetadata({ variables: { filter: { link: e.target.value } } })
	}

	return (
		<Box marginTop="20px">
			<Box>
				<Box position="relative" display="flex" justifyContent="space-between">
					<Typography variant="subtitle1">
						<b>Enter Your Video Link</b>
					</Typography>
					<FormControlLabel
						control={
							<Checkbox
								checked={terms}
								onClick={() => setTerms(!terms)}
								color="secondary"
								name="Terms"
							/>
						}
						label={
							<Typography>
								This Video Follows WURRLY´s{' '}
								<Link color="secondary" target="_blank" href="#">
									Terms of Use
								</Link>
							</Typography>
						}
					/>
				</Box>
				<Box>
					<Paper component="form" className={textClasses.root}>
						<TextField
							variant="outlined"
							className={!!error ? textClasses.inputError : textClasses.input}
							placeholder="Enter Video Link"
							value={value}
							onChange={onChange}
						/>
						{value !== '' && !error && (
							<CheckIcon className={textClasses.completeIcon} fontSize="inherit" />
						)}
					</Paper>
					<Box marginTop="10px">
						<BaseAlert
							severity="error"
							show={!!error}
							handleClose={() => {
								setError(undefined)
							}}>
							{error}
						</BaseAlert>
					</Box>
					<Box textAlign="end">
						<Typography variant="caption" className={textClasses.input}>
							Please ensure the content suitability for your class
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
