import { NotesIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'

import { StudentSelectSignInOptions } from './scenes/StudentSelectSignInOption'
import { StudentSignInOptionsProvider } from './useStudentSignInOptions'

export const StudentSignInOptionsPage = () => {
	return (
		<StudentSignInOptionsProvider>
			<FixedBackground>
				<img
					src={NotesIcon}
					style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
				/>
				<StudentSelectSignInOptions />
			</FixedBackground>
		</StudentSignInOptionsProvider>
	)
}
