import { ComponentType, Context, createContext, ReactNode, useContext } from 'react'

export const buildGenericContext = <T,>(
	getData: () => T
): [
	({ children }: { children: ReactNode }) => JSX.Element,
	() => T,
	{ Context: Context<T>; withProvider: <T>(Component: ComponentType<T>) => (hocProps: T) => JSX.Element }
] => {
	const Context = createContext<T>({} as T)

	const Provider = ({ children }: { children: ReactNode }) => {
		const comonData = getData()

		return <Context.Provider value={comonData}>{children}</Context.Provider>
	}

	const useProviderContext = () => useContext(Context)

	const withProvider = <P,>(Component: ComponentType<P>) => {
		return function (props: P) {
			return (
				<Provider>
					<Component {...props} />
				</Provider>
			)
		}
	}

	return [Provider, useProviderContext, { Context, withProvider }]
}
