import { useEffect, useState } from 'react'

import MomentUtils from '@date-io/moment'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useFormik } from 'formik'
import moment from 'moment'
import { BigBaseButton, DropdownInput, InfoDialog, TextInput } from 'src/components'
import { ContactButton } from 'src/components/Buttons/ContactButton/ContactButton'
import { useSetClassSettingsMutation } from 'src/graphql/autogenerate/hooks'
import {
	Catalog_Item,
	Class,
	Grade,
	Grading_System,
	Grading_System_Type_Enum,
	Maturity_Level_Enum,
	Skill_Level
} from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, getCyKey } from 'src/utils'
import * as yup from 'yup'

import { useStyles } from '../styles'
import { useClassSettingsContext } from '../useClassSettings'

export const ClassSettingsFormConfig = () => {
	const styles = useStyles()
	const {
		classId,
		teacherId,
		history: historyy,
		subjects,
		gradingSystem,
		skillsData,
		gradesData,
		classSettings,
		classSettingsLoading
	} = useClassSettingsContext()
	const [onCancel, setOnCancel] = useState(false)
	const [onSave, setOnSave] = useState(false)

	const [setSettings, { loading }] = useSetClassSettingsMutation()

	const validationSchema = yup.object({
		name: yup.string().required('Name is required'),
		initDate: yup.date().required('Start date is required'),
		grade: yup.object().shape({
			grade_id: yup.number().required('Grade is required'),
			name: yup.string().required('Grade is required').ensure()
		}),
		endDate: yup
			.date()
			.min(yup.ref('initDate'), 'Date should not be before start date')
			.required('End date is required'),
		maturityLevel: yup.string().required('Catalog Type is required').label('Catalog Type'),
		subjects: yup.object({
			catalog_item_id: yup.string()
		}),
		gradingSystem: yup.object().shape({
			name: yup.string().required('Grading System is required').ensure(),
			grading_system_id: yup.number().required('Grading System is required'),
			label: yup.string().required('Grading System is required').ensure()
		})
	})
	const ClassSettingsForm = ({
		title,
		start_date,
		end_date,
		catalog_item,
		grade,
		skill_level,
		grading_system,
		maturity_level
	}: Class) => {
		const formik = useFormik({
			initialValues: {
				name: title ?? '',
				initDate: new Date(start_date) ?? new Date(),
				endDate: new Date(end_date) ?? new Date(),
				subjects: catalog_item ?? ({ name: '' } as Catalog_Item),
				grade: grade ?? ({ name: '' } as Grade),
				skill: skill_level ?? ({ name: '' } as Skill_Level),
				gradingSystem:
					grading_system ??
					({
						name: Grading_System_Type_Enum.None,
						grading_system_id: NaN,
						label: ''
					} as Grading_System),
				maturityLevel: maturity_level as Maturity_Level_Enum
			},
			validationSchema,
			validateOnMount: true,
			onSubmit: async (values, { resetForm }) => {
				const {
					name,
					initDate,
					endDate,
					subjects: { catalog_item_id },
					grade: { grade_id },
					skill: { skill_level_id },
					gradingSystem: { grading_system_id },
					maturityLevel
				} = values
				const settings = {
					title: name,
					start_date: initDate,
					end_date: endDate,
					catalog_item_id,
					grade_id,
					skill_level_id,
					grading_system_id,
					maturity_level: maturityLevel.toLowerCase() as Maturity_Level_Enum
				}

				try {
					await setSettings({
						variables: {
							classId,
							teacherId,
							classData: { ...settings }
						}
					})
					setOnSave(true)
					resetForm()
				} catch (err) {
					alert(`Add video failed - ${JSON.stringify(err, null, 2)}`)
				}
			}
		})

		const popOverLabel = (
			// TODO: Contact us link
			<Typography style={{ fontSize: 16, maxWidth: 500 }}>
				For your convenience, the WURRLY team has rated the music catalog based on the song lyrics. While
				the entire catalog excludes explicit lyrics, the Mature catalog may contain songs with references
				to sex, drugs, alcohol or violence. WURRLY takes no responsibility for such ratings so please check
				suitability for your Classes. Something doesn't look right? <ContactButton />
			</Typography>
		)

		useEffect(() => {
			if (!formik.values.grade.name) {
				formik.setFieldTouched('grade')
			}

			if (!formik.values.maturityLevel) {
				formik.setFieldTouched('maturityLevel')
			}

			if (!formik.values.gradingSystem.label) {
				formik.setFieldTouched('gradingSystem')
			}
		}, [formik.values])

		return (
			<Box>
				{classSettingsLoading && (
					<Box m={3} textAlign="center">
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
				{!classSettingsLoading && (
					<Box>
						<form onSubmit={formik.handleSubmit}>
							<TextInput
								data-cy={getCyKey(ClassSettingsFormConfig, 'classSettingsFormName')}
								name="Name"
								isRequired
								value={formik.values.name}
								onChange={(e) => formik.setFieldValue('name', e.target.value)}
								onFocus={() => formik.setFieldTouched('name')}
								error={Boolean(formik.errors.name) && formik.touched.name}
								helperText={formik.touched.name && formik.errors.name}
								placeholder="Class name"
							/>

							<Box marginTop="40px">
								<Box display="flex" justifyContent="space-between">
									<Typography>
										<b>Class Date*</b>
									</Typography>
									<Typography className={styles.textDefault} color="textSecondary">
										{
											'If no dates are selected, your class will automatically span one month from its creation date'
										}
									</Typography>
								</Box>

								<Box display="flex" justifyContent="space-between">
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											style={{ flexGrow: 1, marginRight: 15 }}
											margin="normal"
											id="initDate"
											label="Start Date"
											format="ddd, DD MMM yyyy"
											value={formik.values.initDate}
											onChange={(_, value) => {
												formik.setFieldValue('initDate', moment(value))
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date'
											}}
											onFocus={() => formik.setFieldTouched('initDate')}
											helperText={formik.touched.initDate && formik.errors.initDate}
											error={formik.touched.initDate && Boolean(formik.errors.initDate)}
										/>
									</MuiPickersUtilsProvider>

									<Typography
										variant="h5"
										className={styles.textDefault}
										style={{ alignSelf: 'center' }}>
										{'‒'}
									</Typography>

									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											style={{ flexGrow: 1, marginLeft: 15 }}
											margin="normal"
											id="endDate"
											label="End Date"
											format="ddd, DD MMM yyyy"
											value={formik.values.endDate}
											onChange={(_, value) => {
												formik.setFieldValue('endDate', moment(value))
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date'
											}}
											onFocus={() => formik.setFieldTouched('endDate')}
											helperText={formik.errors.endDate}
											error={formik.touched.endDate && Boolean(formik.errors.endDate)}
										/>
									</MuiPickersUtilsProvider>
								</Box>
							</Box>

							<DropdownInput
								name="Subject"
								options={subjects ?? []}
								value={formik.values.subjects}
								onChange={(_, value) => {
									formik.setFieldValue('subjects', value ?? {})
								}}
								getOptionLabel={(option) => option.name ?? ''}
							/>

							<DropdownInput
								name="Grade Level"
								isRequired
								options={gradesData?.grade ?? []}
								value={formik.values.grade}
								onChange={(_, value) => {
									formik.setFieldValue('grade', value ?? {})
								}}
								onFocus={() => formik.setFieldTouched('grade')}
								inputProps={{
									error: formik.touched.grade && Boolean(formik.errors.grade),
									helperText: formik.touched.grade && formik.errors.grade?.name
								}}
								getOptionLabel={(option) => option.name ?? ''}
							/>

							<DropdownInput
								name="Skill Level"
								isRequired={false}
								options={skillsData?.skill_level ?? []}
								value={formik.values.skill}
								onChange={(_, value) => {
									formik.setFieldValue('skill', value ?? {})
								}}
								getOptionLabel={(option) => option.name ?? ''}
							/>

							<DropdownInput
								name="Default Grading System"
								isRequired
								options={gradingSystem?.grading_system ?? []}
								value={formik.values?.gradingSystem}
								onChange={(_, value) => {
									formik.setFieldValue('gradingSystem', value ?? {})
								}}
								getOptionLabel={(option) => option.label ?? ''}
								inputProps={{
									error:
										formik.touched.gradingSystem &&
										Boolean(formik.errors.gradingSystem?.label),
									helperText: formik.touched.gradingSystem && formik.errors.gradingSystem?.label
								}}
								onFocus={() => formik.setFieldTouched('gradingSystem')}
							/>
							<DropdownInput
								name="Song Catalog Type"
								placeholder="Select a Catalog Type"
								isRequired
								options={Object.keys(Maturity_Level_Enum).map((i) => ({ name: i }))}
								value={{ name: formik.values.maturityLevel }}
								onChange={(_, value: { name: string } | null) => {
									formik.setFieldValue('maturityLevel', value?.name)
								}}
								getOptionLabel={(option) => option.name}
								popOverLabel={popOverLabel}
								onFocus={() => formik.setFieldTouched('maturityLevel')}
								capitalize
								inputProps={{
									error: formik.touched.maturityLevel && Boolean(formik.errors.maturityLevel),
									helperText: formik.touched.maturityLevel && formik.errors.maturityLevel
								}}
							/>

							<Box
								className={styles.buttonDisabled}
								marginTop="35px"
								display="flex"
								justifyContent="space-between">
								<BigBaseButton
									style={{ padding: '6px 40px' }}
									variant="contained"
									color="default"
									onClick={() => {
										setOnCancel(true)
									}}>
									<Typography
										data-cy={getCyKey(ClassSettingsFormConfig, 'btnCSettingsGoBack')}
										variant="caption">
										<b>Go Back</b>
									</Typography>
								</BigBaseButton>

								<BigBaseButton
									style={{ padding: '6px 50px' }}
									disabled={!formik.isValid || loading}
									color="secondary"
									type="submit">
									<Typography
										data-cy={getCyKey(ClassSettingsFormConfig, 'btnCSettingsSave')}
										variant="caption">
										<b>Save</b>
									</Typography>
								</BigBaseButton>
							</Box>
						</form>

						<InfoDialog
							open={onCancel}
							onClose={() => setOnCancel(false)}
							title="Go Back Without Saving?"
							body="You will lose all the details you've entered up until this point."
							discardLabel="Yes, Go Back"
							onDiscard={() => {
								setOnCancel(false)
								history.back()
							}}
							confirmLabel="No, Cancel"
							onConfirm={() => {
								setOnCancel(false)
							}}
							icon="!"
						/>

						<InfoDialog
							open={onSave}
							onClose={() => {}}
							title="Class Settings Updated!"
							body="You have succesfully updated your Class Settings."
							discardLabel="Back to Settings"
							onDiscard={() => {
								window.location.reload()
								setOnSave(false)
							}}
							confirmLabel="View Class"
							confirmProps={{ color: 'secondary' }}
							onConfirm={() => {
								setOnSave(false)
								historyy.push(buildRouteParameters(Pages.ClassDetail, { classId }))
							}}
							icon={<CheckIcon />}
						/>
					</Box>
				)}
			</Box>
		)
	}

	return <Box>{!classSettingsLoading && classSettings && <ClassSettingsForm {...classSettings} />}</Box>
}
