import { useEffect, useState } from 'react'

import { Box, ButtonProps, Paper, PaperProps, Tooltip, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import clsx from 'clsx'
import { CartIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { StatusProductButton } from 'src/components/Buttons/StatusProductButton/StatusProductButton'
import { Maybe, Channel, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import useChannelCardStyles from 'src/scenes/Teacher/scenes/channels/components/ChannelCard.styles'
import { buildImagePath } from 'src/utils'

export type ChannelCardProps = PaperProps & {
	channel: Channel
	onDelete?: () => void
	onEdit?: () => void
	editTooltip?: string
	deleteTooltip?: string
	onActiveChange?: (active: boolean) => void
	onView?: () => void
	btnSuscription?: ButtonProps
}

export const ChannelCard = ({ channel: channel, onClick, onView, btnSuscription, ...props }: ChannelCardProps) => {
	const styles = useChannelCardStyles({ imageUrl: buildImagePath(channel.image_path) || '' })
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [status, setStatus] = useState('')
	const [isDistrict, setIsDistrict] = useState(true)

	useEffect(() => {
		if (channel) {
			setVisibility(channel.lockable_content.visibility)
			setSubscription(channel.is_purchased_by_teacher)
			setStatus(channel.get_status_by_teacher as string)
			setIsDistrict(channel.lockable_content?.districts?.length > 0)
		}
	}, [channel])
	const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target !== e.currentTarget || !onClick) return
		onClick(e)
	}

	const channelPlaylists = channel.channel_playlists.length
	let tipPlaylist = 0

	channel?.channel_playlists.map(
		(playList) => (tipPlaylist += playList?.playlist?.tip_playlist_aggregate?.aggregate?.count || 0)
	)

	return (
		<Paper {...props} onClick={handleOnClick}>
			<Box className={styles.container}>
				<Box className={channel.image_path ? styles.imageContainer : styles.blank} onClick={onClick}>
					<Box className={styles.opacity} />
					<Typography variant="h5" className={clsx(styles.ellipsis, styles.title)}>
						{channel.title}
					</Typography>

					<Box className={styles.flag}>
						<Typography className={clsx(styles.flagText, styles.ellipsisSecondLine)}>
							<b>{`${channelPlaylists} PlayList | ${tipPlaylist || 0} Videos`}</b>
						</Typography>
					</Box>
				</Box>

				<Box className={styles.details}>
					<Typography className={clsx(styles.ellipsis, styles.description)} onClick={onClick}>
						{channel.description}
					</Typography>
					<Typography className={clsx(styles.genres, styles.ellipsis)} onClick={onClick}>
						• {channel.short_description}
					</Typography>
					{visibility === Visibility_Enum.ForSale && (
						<StatusProductButton
							isSubscription={subscription as boolean}
							isDistrict={isDistrict}
							status={status}
						/>
					)}
					<Box className={styles.buttons}>
						<Tooltip title="View">
							<BaseButton
								color="primary"
								className={styles.viewButton}
								endIcon={<ArrowForwardIcon />}
								onClick={onView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
						{!subscription && visibility === Visibility_Enum.ForSale && (
							<BaseButton
								className={styles.subscription}
								startIcon={<CartIcon style={{ color: '#F3AB34' }} />}
								{...btnSuscription}>
								<Typography className={styles.subscribe}>Subscribe to Channel</Typography>
							</BaseButton>
						)}
					</Box>
				</Box>
			</Box>
		</Paper>
	)
}
