import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, { font: number; marginLeft: number }>(() => ({
	defaultStyle: {
		cursor: 'pointer',
		color: '#F3AB34',
		fontWeight: 500,
		textDecoration: 'none',
		fontSize: ({ font }) => font,
		marginLeft: ({ marginLeft }) => marginLeft
	}
}))
