import { createStyles, makeStyles } from '@material-ui/core'

export const useActionDialogsStyles = makeStyles(() =>
	createStyles({
		submitGrades: {
			display: 'flex',
			alignItems: 'center',
			fontSize: '0.875rem'
		},
		checkIcon: {
			width: 15,
			height: 15,
			marginLeft: 10
		},
		loader: {
			marginLeft: 10
		}
	})
)

export default useActionDialogsStyles
