import { Box, Grid } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { useScroll } from 'src/hooks'
import { InsightsInner } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/CheckInsights/CheckInsights'
import { ClassAssignments } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/ClassAssignments'
import { ClassStudentActivities } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/ClassStudentActivities'
import { ClassStudentBadgeActivity } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/ClassStudentBadgeActivity'
import { ClassStudentBadgeSummary } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/ClassStudentBadgeSummary'
import { BOX_PADDING } from 'src/styles/constants'

import { ClassDetailsCodeGeneratorDialogs } from './components/ClassDetailsCodeGeneratorDialogs'
import { ClassDetailsNoContent } from './components/ClassDetailsNoContent'
import { ClassDetailsTitle } from './components/ClassDetailsTitle'
import { ClassDetailsWurrlies } from './components/ClassDetailsWurrlies'
import { ClassDetailsProvider, useClassDetailsContext } from './useClassDetails'

export const ClassDetails = (props: PagesProps) => {
	useScroll()

	return (
		<Box>
			<ClassDetailsProvider>
				<ClassContent {...props} />
			</ClassDetailsProvider>
		</Box>
	)
}

export const ClassContent = (props: PagesProps) => {
	const { showPointsSection, classId } = useClassDetailsContext()

	return (
		<Box>
			<ClassDetailsTitle {...props} />
			{!showPointsSection && (
				<Box p={BOX_PADDING}>
					<Grid container wrap="nowrap" spacing={2}>
						<ClassStudentActivities />
						<ClassAssignments />
					</Grid>

					<ClassDetailsNoContent />
					<ClassDetailsWurrlies />
				</Box>
			)}
			{showPointsSection && (
				<Box p={BOX_PADDING}>
					<Grid container wrap="nowrap" spacing={2}>
						<ClassStudentBadgeActivity />
						<ClassStudentBadgeSummary />
					</Grid>
					<InsightsInner classId={classId} />
				</Box>
			)}
			<ClassDetailsCodeGeneratorDialogs />
		</Box>
	)
}
