import { Box, Grid, Button, Typography, CircularProgress } from '@material-ui/core'
import { ImageButton } from 'src/components'
import { ImageButtonProps } from 'src/components/Buttons/ImageButton'
import { CardTitle } from 'src/components/CardTitle'
import { ChallengeDialog } from 'src/components/ChallengeDialog/ChallengeDialog'
import { GetBadgesQuery, GetStudentBadgesQuery } from 'src/graphql/autogenerate/operations'
import { Challenge } from 'src/graphql/autogenerate/schemas'
import { GoToClassDialog } from 'src/scenes/Student/1-Home/components/ClassGrid'
import { buildImagePath } from 'src/utils/utils'

import { IconButton } from '../../../../Teacher/scenes/1-Home/components/IconButton'
import { DISCOVER_MUSIC_IMG, CHALLENGE_IMG } from '../../constants'
import { BadgeInfo } from '../BadgeInfo/BadgeInfo'
import { HowToEarnBadges } from '../HowToEarnBadges'
import { SectionHeader } from '../SectionHeader'
import useStyles from './StudentContent.styles'
import { useStudentContent } from './useStudentContent'

const elementPosition = { right: 16, bottom: 16 }

type BadgesRowType = {
	showBadgeInfo: (badge: studentBadgeType) => void
	badgesContent: GetBadgesQuery | undefined
	loadingBadges: boolean
	studentBadges: GetStudentBadgesQuery | undefined
	loadingStudentBadges: boolean
}

type studentBadgeType = BadgeType & StudentBadgeType

type BadgeType = GetBadgesQuery['badge'][0]
type StudentBadgeType = GetStudentBadgesQuery['student_badge_resume'][0]

export const StudentContent = () => {
	const styles = useStyles()
	const {
		showBadgeInfo,
		student,
		showHowToEarnBadges,
		isBadgesModalOpen,
		setIsBadgesModalOpen,
		badgeInfo,
		challengeData,
		ladingChallenge,
		challenges,
		isbadgeInfoOpen,
		setisbadgeInfoOpen,
		challengesOpen,
		setChallengesOpen,
		isSelectClassOpen,
		setIsSelectClassOpen,
		badgesContent,
		loadingBadges,
		studentBadges,
		loadingStudentBadges,
		totalPoints,
		finishedChallenges
	} = useStudentContent()

	const TilesContent: Record<string, ImageButtonProps> = {
		music: {
			backgroundImageUrl: DISCOVER_MUSIC_IMG,
			title: <CardTitle bold text="Discover Music" />,
			element: <IconButton icon="musicNote" text="Music Catalog" />,
			elementPosition
		},
		song_challenge: {
			backgroundImageUrl: CHALLENGE_IMG,
			title: <CardTitle bold text="WURRLY Song Challenge" />,
			element: <IconButton icon="notebook" text={!!challenges.length ? 'Check it out!' : 'Nothing New'} />,
			elementPosition
		},
		badges: {
			backgroundColor: '#221F31',
			title: (
				<Box className={styles.title}>
					<CardTitle bold text="Your Badges" />
					<Button onClick={() => showHowToEarnBadges()}>
						<b>{totalPoints === 0 ? 'Learn How To Earn Badges!' : `${String(totalPoints)} points!`}</b>
					</Button>
				</Box>
			),
			element: (
				<BadgesRow
					showBadgeInfo={showBadgeInfo}
					badgesContent={badgesContent}
					loadingBadges={loadingBadges}
					studentBadges={studentBadges}
					loadingStudentBadges={loadingStudentBadges}
				/>
			),
			elementContainerStyle: {
				width: '100%',
				padding: '1em'
			},
			elementPosition: { left: 0, bottom: 0 }
		}
	}

	return (
		<section>
			<SectionHeader content="Browse Our Content" />
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<ImageButton
								className={styles.longImage}
								titlePosition="initial"
								{...TilesContent.music}
								onClick={() => setIsSelectClassOpen(true)}
							/>
						</Grid>
						<Grid item xs={12}>
							<ImageButton
								className={styles.longImage}
								titlePosition="initial"
								{...TilesContent.badges}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<ImageButton
						titlePosition="initial"
						className={styles.bigImage}
						disableButton={!challenges.length}
						{...TilesContent.song_challenge}
						titleHeight="100%"
						wrapperProps={{ style: { height: '100%' } }}
						onClick={() => setChallengesOpen(true)}
					/>
				</Grid>
			</Grid>
			{isSelectClassOpen && (
				<GoToClassDialog
					isOpen={isSelectClassOpen}
					setIsOpen={setIsSelectClassOpen}
					title="Select a Class"
					skipIfOnlyOne
				/>
			)}
			<HowToEarnBadges isOpen={isBadgesModalOpen} setIsOpen={setIsBadgesModalOpen} />
			<BadgeInfo isOpen={isbadgeInfoOpen} setIsOpen={setisbadgeInfoOpen} badgeInfo={badgeInfo} />

			{!!challengeData?.getStudentChallenges.challenges.length && challengesOpen && (
				<ChallengeDialog
					challengeData={challengeData?.getStudentChallenges.challenges as Challenge[]}
					finishedChallenges={finishedChallenges}
					ladingChallenge={ladingChallenge}
					studentId={student.student_id}
					disableAsSeen={true}
					handleExternalClose={() => setChallengesOpen(false)}
				/>
			)}
		</section>
	)
}

const BadgesRow = ({ showBadgeInfo, badgesContent, loadingBadges, studentBadges }: BadgesRowType) => {
	const styles = useStyles()

	const badgeData = studentBadges?.student_badge_resume

	return (
		<Box className={styles.badgesPanel}>
			<Grid container spacing={2}>
				<Grid item xs={12}></Grid>
				{loadingBadges && <CircularProgress />}
				{!loadingBadges &&
					badgesContent?.badge.map((badge, key) => (
						<Grid item xs={2} key={key}>
							<Button
								className={styles.badges}
								key={badge.badge_id}
								onClick={() => {
									const badgeCounter = badgeData?.find((i) => i.badge_id === badge.badge_id)
									const badgeInfo = { ...badgeCounter, ...badge }

									showBadgeInfo(badgeInfo as studentBadgeType)
								}}>
								<img
									src={buildImagePath(
										badgeData?.find((studentBadge) => studentBadge.badge_id === badge.badge_id)
											?.total_badges > 0
											? badge.icon_awarded
											: badge.icon_empty
									)}
									alt="Image 1"
									style={{ width: '100%', height: '100%', objectFit: 'cover' }}
								/>
								<Box className={styles.points}>
									<Typography
										variant="body2"
										style={{
											color: String(badge.color),
											fontSize: 'calc(1vw + 0vh)',
											textAlign: 'center'
										}}>
										<b>
											{
												badgeData?.find(
													(studentBadge) => studentBadge.badge_id === badge.badge_id
												)?.total_badges
											}
										</b>
									</Typography>
								</Box>
							</Button>
						</Grid>
					))}
			</Grid>
		</Box>
	)
}
