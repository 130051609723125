import React, { useEffect, useState, Fragment, Dispatch, SetStateAction } from 'react'

import { Box, Card, CardActionArea, CardContent, GridList, GridListTile, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import { useHistory } from 'react-router-dom'
import { ThinCheckIcon } from 'src/assets/icons'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { buildSearchText, DEFAULT_MUSIC_BG } from 'src/utils'

import { BaseDialog, InfoDialog } from '..'
import { useGetClassesQuery } from '../../graphql/autogenerate/hooks'
import { Class } from '../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../hooks/useLogin'
import { Pages } from '../../routes/teacherPages'
import { COLORS } from '../../styles/colors'
import { buildImagePath, generateUniqueId, getCyKey } from '../../utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		selected: {
			borderColor: theme.palette.secondary.main,
			backgroundColor: fade(theme.palette.secondary.main, 0.25)
		},
		saved: {
			backgroundColor: fade(theme.palette.grey[700], 0.7)
		},
		nonSelected: {
			backgroundColor: fade(theme.palette.primary.dark, 0.1)
		},
		noResult: {
			color: 'rgba(0,0,0,0.5)'
		}
	})
)

type HandleFavoriteProps = {
	isFavorite: boolean
	handler: () => void
}

type AddToClassProps = {
	// classes where the item belongs to (already saved on db)
	itemClasses: Class[]
	itemName?: string
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<number | undefined>> | ((state: undefined) => void)
	title?: string
	description?: string
	onConfirm: (selectedClasses: Class[]) => Promise<void>
	onDone?: () => void
	onSuccess?: {
		shouldClose?: boolean
		onDiscard?: () => void
		confirmLabel?: string
		discardLabel?: string
		confirmDisabled?: boolean
	}
	handleFavorite?: HandleFavoriteProps
	footer?: React.ReactNode
	isLoading?: boolean
	songMaturity?: string
	searchText?: string
	setSearchText?: Dispatch<SetStateAction<string>>
}

type ClassroomType = {
	saved?: boolean
	selected?: boolean
} & Class

export const AddToClassDialog = ({
	isLoading,
	isOpen,
	setIsOpen,
	itemClasses,
	title,
	description,
	onConfirm,
	itemName,
	onDone,
	onSuccess,
	handleFavorite,
	songMaturity,
	footer,
	searchText,
	setSearchText
}: AddToClassProps) => {
	const style = useStyles()
	const theme = useTheme()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const history = useHistory()
	const [rightNow] = useState(null) // TODO: Use `new Date()` to query active classes

	const { data: classData, loading: classLoading } = useGetClassesQuery({
		variables: { teacherId, rightNow, search: buildSearchText(searchText ?? '', ['title']) },
		skip: !teacherId
	})

	const [classes, setClasses] = useState<ClassroomType[]>([])
	const [isOnSuccessOpen, setIsOnSuccessOpen] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (setSearchText) {
			setSearchText(e?.target?.value)
		}
	}

	useEffect(() => {
		if (classData?.teacher_by_pk?.active?.length === 0 && searchText) {
			setClasses([])

			return
		}

		if (!classLoading && classData?.teacher_by_pk?.active) {
			const teacherClasses = classData.teacher_by_pk.active.map((teacherClass) => {
				if (itemClasses.some((item) => item?.class_id === teacherClass.class.class_id)) {
					return { ...teacherClass.class, saved: true, selected: false }
				} else return { ...teacherClass.class, saved: false, selected: false }
			})
			setClasses([...(teacherClasses as unknown as ClassroomType[])])
		}
	}, [classData, classLoading, itemClasses])

	const goToCreateClass = () => {
		history.push(Pages.ClassCreation.path)
	}

	return (
		<Box>
			<InfoDialog
				open={isOnSuccessOpen}
				onClose={() => {
					if (onSuccess?.shouldClose === false) return
					setIsOnSuccessOpen(false)
				}}
				icon={<ThinCheckIcon />}
				title={`${itemName || ''} Added!`}
				body={`You have successfully added this ${itemName ?? ''}.`}
				confirmLabel={onSuccess?.confirmLabel || 'Done'}
				discardLabel={onSuccess?.discardLabel || ''}
				confirmProps={{ style: { fontWeight: 'bold' }, disabled: !!onSuccess?.confirmDisabled }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onDiscard={() => {
					if (onSuccess?.onDiscard) onSuccess.onDiscard()
					setIsOnSuccessOpen(false)
				}}
				onConfirm={() => {
					setIsOnSuccessOpen(false)
					if (onDone) onDone()
				}}
				footer={footer}
			/>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {
					setIsOnErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
			/>
			{classes.length === 0 && !searchText ? (
				<InfoDialog
					onClose={() => {
						if (setIsOpen && !loading) setIsOpen(undefined)
					}}
					open={isOpen}
					icon="!"
					title="Set up a class"
					body="You need to have a class set up before you can add content to it."
					discardLabel={<b>{`${handleFavorite?.isFavorite ? 'Remove from' : 'Add to'} Favorites`}</b>}
					onDiscard={handleFavorite?.handler}
					discardProps={{ disabled: isLoading, style: { lineHeight: '15px' } }}
					confirmLabel={<b>Create Class</b>}
					onConfirm={goToCreateClass}
				/>
			) : (
				<BaseDialog
					dividers={false}
					onClose={() => {
						setIsOpen(undefined)
					}}
					open={isOpen}
					header={
						<Fragment>
							<Typography data-cy={getCyKey(AddToClassDialog, `title`)} variant="h4" align="center">
								{title}
							</Typography>
							<Typography style={{ padding: 14 }} align="center" variant="body2">
								{description}
							</Typography>
							<SearchBar
								placeholder="Search class by title"
								hasDropDown={false}
								onChange={handleSearch}
								onEnterPressed={(searchText) => {
									if (setSearchText) setSearchText(searchText)
								}}
							/>
							{!loading && !classes.length && (
								<Typography className={style.noResult}>
									Your search for "{searchText}" did not return any results.
								</Typography>
							)}
						</Fragment>
					}
					body={
						<Box padding="6px 10px">
							<GridList cellHeight={100} cols={3}>
								{classes.map((iclass, index) => (
									<GridListTile key={`${generateUniqueId()}`} cols={1}>
										<Card
											// data-cy={getCyKey(AddToClassDialog, `CardClass${generateUniqueId()}`)}
											data-cy={getCyKey(AddToClassDialog, `CardClass`)}
											variant={iclass.selected ? 'outlined' : 'elevation'}
											elevation={
												iclass.saved ||
												(songMaturity === 'mature' && iclass.maturity_level === 'general')
													? 0
													: 3
											}
											style={{
												backgroundImage: `url( ${
													iclass.image_path
														? buildImagePath(iclass.image_path)
														: DEFAULT_MUSIC_BG
												})`,
												backgroundColor: theme.palette.primary.main,
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'cover',
												backgroundPosition: 'center 40%',
												height: '-webkit-fill-available',
												color: theme.palette.common.white,
												margin: 5
											}}
											color="secondary"
											onClick={() => {
												if (
													!iclass.saved &&
													!(
														songMaturity === 'mature' &&
														iclass.maturity_level === 'general'
													)
												) {
													classes[index].selected = !iclass.selected
													setClasses([...classes])
												}
											}}>
											<CardActionArea
												style={{ height: '-webkit-fill-available' }}
												className={
													iclass.saved ||
													(songMaturity === 'mature' &&
														iclass.maturity_level === 'general')
														? style.saved
														: iclass.selected
														? style.selected
														: style.nonSelected
												}>
												<CardContent>
													<Box position="absolute" bottom={5} left={5}>
														<Typography gutterBottom variant="body2">
															<b>{iclass.title}</b>
														</Typography>
													</Box>
													{iclass.saved ||
													(songMaturity === 'mature' &&
														iclass.maturity_level === 'general') ? (
														<Box
															style={{
																display: 'flex',
																color: COLORS.lightgrey,
																opacity: 0.7
															}}
															position="absolute"
															top={5}
															right={8}>
															<Typography
																style={{ marginRight: 3 }}
																variant="caption">
																<b>
																	{iclass.maturity_level === 'general'
																		? 'GENERAL CLASS'
																		: 'ADDED'}
																</b>
															</Typography>
															<Typography variant="subtitle2">
																{iclass.saved && (
																	<CheckCircleIcon fontSize="inherit" />
																)}
															</Typography>
														</Box>
													) : (
														<Box
															style={{ display: 'flex' }}
															position="absolute"
															top={5}
															right={8}>
															{iclass.selected ? (
																<CheckCircleIcon
																	fontSize="small"
																	color="secondary"
																/>
															) : (
																<RadioButtonUncheckedOutlinedIcon
																	fontSize="small"
																	color="disabled"
																/>
															)}
														</Box>
													)}
												</CardContent>
											</CardActionArea>
										</Card>
									</GridListTile>
								))}
							</GridList>
						</Box>
					}
					onConfirm={async () => {
						try {
							setLoading(true)
							await onConfirm(
								classes
									.filter((item) => item.selected)
									.map((item) => {
										const selectedClass = { ...item }
										delete selectedClass.selected
										delete selectedClass.saved

										return selectedClass
									})
							)
							setIsOpen(undefined)
							setIsOnSuccessOpen(true)
						} catch (error) {
							console.error(error)
							setIsOpen(undefined)
							setIsOnErrorOpen(true)
						} finally {
							setLoading(false)
						}
					}}
					onConfirmProps={{ style: { width: '214px' } }}
					isConfirmDisabled={!classes.some((item) => item.selected) || loading}
					confirmLabel="Save"
					onDiscard={() => {
						if (setIsOpen && !loading) setIsOpen(undefined)
					}}
					onDiscardProps={{ disabled: loading, style: { width: '214px' } }}
					discardLabel="Cancel"
				/>
			)}
		</Box>
	)
}
