import { alpha, createStyles, makeStyles } from '@material-ui/core'

export const useListItemMediaStyles = makeStyles((theme) =>
	createStyles({
		draggableIcon: {
			padding: '5px 25px 0 5px',
			cursor: 'pointer',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around'
		},
		listItem: {
			display: 'flex',
			alignItems: 'start',
			minHeight: 75,
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			},
			'&:hover': {
				'& $thumbnail': {
					backgroundColor: alpha(theme.palette.common.black, 0.4),
					position: 'absolute',
					width: '100%',
					height: '100%'
				},
				'& $playIcon': {
					opacity: 1
				}
			}
		},
		listChangedPrivacyItems: {
			display: 'flex',
			alignItems: 'start',
			minHeight: 75,
			backgroundColor: 'rgba(255, 0, 0, 0.3)',
			borderRadius: '10px',
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			},
			'&:hover': {
				'& $thumbnail': {
					backgroundColor: alpha(theme.palette.common.black, 0.4),
					position: 'absolute',
					width: '100%',
					height: '100%'
				},
				'& $playIcon': {
					opacity: 1
				}
			}
		},
		thumbnail: {
			background: 'red',
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor: alpha(theme.palette.grey[700], 0),
			transition: 'background-color 0.3s',
			borderRadius: '4px'
		},
		avatar: {
			marginTop: 7,
			marginBottom: 6
		},
		avatarVideo: {
			position: 'relative',
			margin: theme.spacing(1, 2, 1, 0),
			alignSelf: 'flex-start',
			'& .MuiAvatar-root': {
				width: '70px',
				height: '50px'
			}
		},
		itemTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word'
		},
		text: {
			alignSelf: 'flex-start'
		},
		playIcon: {
			color: theme.palette.common.white,
			opacity: 0,
			position: 'absolute',
			top: '12px',
			left: '22px',
			transition: 'opacity 0.3s'
		},
		iconButton: {
			padding: 0
		},
		enableToolTip: {
			'& button': {
				pointerEvents: 'stroke!important'
			}
		}
	})
)

export default useListItemMediaStyles
