import { createStyles, makeStyles } from '@material-ui/core'
import { alpha, Theme } from '@material-ui/core/styles'

export const useInputFileStyles = makeStyles((theme: Theme) =>
	createStyles({
		fileInput: {
			display: 'none'
		},
		root: {
			padding: '4px 14px 4px 4px',
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			border: '1px solid rgba(0, 0, 0, .23)',
			borderRadius: '4px',
			'&:hover': {
				border: '1px solid rgba(0, 0, 0)',
				borderRadius: '4px'
			},
			backgroundColor: 'white'
		},
		text: {
			padding: '10px 0',
			marginLeft: theme.spacing(1),
			flex: 1,
			alignItems: 'center',
			color: theme.palette.text.disabled
		},
		inputComplete: {
			padding: '10px 0',
			marginLeft: theme.spacing(1),
			flex: 1,
			alignItems: 'center'
		},
		cancelButton: {
			padding: 5,
			color: theme.palette.text.disabled
		},
		progressText: {
			'& .MuiAlert-message': {
				padding: 4
			},
			color: alpha(theme.palette.warning.dark, 0.7),
			padding: '0 4px'
		},
		completeText: {
			'& .MuiAlert-message': {
				padding: 4
			},
			color: alpha(theme.palette.success.dark, 0.7),
			padding: '0 4px'
		},
		divider: {
			height: 28,
			margin: 4
		}
	})
)

export default useInputFileStyles
