import { useEffect, useState } from 'react'

import { SelectedEventType } from 'src/@types'
import { DateFilterEnum } from 'src/environment'
import { useGetClassesNamesQuery } from 'src/graphql/autogenerate/hooks'
import { useSearchQuery } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'

export const useAssignmentsDetails = () => {
	const query = useSearchQuery()
	const classIdFromParams = +(query.get('classId') ?? 0)
	const { teacherData } = useLoginContext()

	const { data: classes } = useGetClassesNamesQuery({
		variables: { teacherId: teacherData.teacher_id },
		skip: !teacherData.teacher_id
	})

	const [selectedClassId, setSelectedClassId] = useState(classIdFromParams)
	const [selectedDateFilter, setSelectedDateFilter] = useState(DateFilterEnum.thisMonth)
	useEffect(() => {
		const idFromParamsIsValid = classes?.class?.findIndex((item) => item.class_id === classIdFromParams) !== -1
		if (idFromParamsIsValid) {
			setSelectedClassId(classIdFromParams)
		} else if (classes?.class?.length) {
			setSelectedClassId(classes?.class[0].class_id)
		}
	}, [classes, classIdFromParams])

	const handleClassSelected = (e: SelectedEventType) => {
		setSelectedClassId(e.target.value as number)
	}

	const handleDateSelected = (e: SelectedEventType) => {
		setSelectedDateFilter(e.target.value as DateFilterEnum)
	}

	return {
		classes,
		selectedClassId,
		selectedDateFilter,
		handleClassSelected,
		handleDateSelected
	}
}
