import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'

import { HeadphonesIcon } from '../../../assets/icons'
import { InfoDialog } from '../../Dialogs/InfoDialog'
import { useStudioRecordContext } from './useStudioRecord'

export const PermissionsDeniedDialog = () => {
	const { isPermissionsDenied } = useStudioRecordContext()
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		if (isPermissionsDenied) {
			setIsOpen(true)
		}
	}, [isPermissionsDenied])

	const renderTitle = () => (
		<Box>
			<Typography style={{ fontSize: '32px' }}>Please Allow</Typography>
			<Typography style={{ fontSize: '32px', lineHeight: '25px' }}>Permissions</Typography>
		</Box>
	)

	return (
		<InfoDialog
			title={renderTitle()}
			open={isOpen}
			icon={<HeadphonesIcon />}
			confirmLabel="Got it"
			onClose={() => setIsOpen(false)}
			onConfirm={() => setIsOpen(false)}
			body={
				<Typography style={{ paddingTop: '10px' }}>
					Hey there! Ready to rock your Wurrly experience? Please allow permissions to record your
					awesome performances!
				</Typography>
			}
		/>
	)
}
