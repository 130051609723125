import { ReactNode, useEffect, useState } from 'react'

import {
	Avatar,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	styled,
	Typography
} from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Pagination, Skeleton } from '@material-ui/lab'
import { generateUniqueId } from 'src/utils'

const StyledListItemText = styled(ListItemText)({
	'& h6, p ': {
		paddingRight: '5px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	}
})

export type PaginationListDataItem = {
	avatar?: ReactNode
	avatarPath?: string
	primary?: ReactNode
	secondary?: ReactNode
	secondaryAction?: ReactNode
	notAvatarIcon?: ReactNode
}
type PaginationListType = {
	loading: boolean
	totalRows: number
	rowsPerPage: number
	limit: number
	data: PaginationListDataItem[]
	onPageChange: (page: number) => void
	isCustomListItem?: boolean
}
export const PaginationList = ({
	totalRows,
	rowsPerPage,
	limit,
	data,
	loading,
	onPageChange,
	isCustomListItem
}: PaginationListType) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const totalPages = Math.ceil(totalRows / rowsPerPage)
	const listHeight = rowsPerPage * 100

	useEffect(() => {
		setCurrentPage(1)
	}, [totalRows])

	return (
		<List
			style={{
				height: listHeight,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}>
			<List disablePadding>
				{loading
					? [...Array(rowsPerPage)].map(() => (
							<ListItem key={generateUniqueId()}>
								<ListItemAvatar>
									<Skeleton animation="wave" variant="circle" height={48} width={48} />
								</ListItemAvatar>
								<ListItemText
									primary={
										<Skeleton
											animation="wave"
											height={10}
											width="80%"
											style={{ marginBottom: 6 }}
										/>
									}
									secondary={<Skeleton animation="wave" height={10} width="40%" />}
								/>
							</ListItem>
					  ))
					: data.map((item) =>
							isCustomListItem ? (
								<ListItem
									divider
									key={generateUniqueId()}
									style={{ height: (listHeight - 60) / rowsPerPage }}>
									{item.avatar}
									{item?.notAvatarIcon}
									{item.primary}
									{item.secondary}
									{item.secondaryAction}
								</ListItem>
							) : (
								<ListItem
									divider
									key={generateUniqueId()}
									style={{ height: (listHeight - 60) / rowsPerPage }}>
									{(item.avatarPath || item.avatar) && (
										<ListItemAvatar>
											{item.avatarPath ? (
												<Avatar
													src={item.avatarPath}
													style={{ width: '50px', height: '50px' }}
												/>
											) : (
												<Avatar
													style={
														item.avatar
															? {
																	backgroundColor: 'rgba(22, 22, 22, 0.08)',
																	color: 'rgba(29, 39, 61, 0.5)',
																	width: '50px',
																	height: '50px'
															  }
															: {}
													}>
													{item.avatar}
												</Avatar>
											)}
										</ListItemAvatar>
									)}
									{item?.notAvatarIcon}
									<StyledListItemText primary={item.primary} secondary={item.secondary} />
									{item.secondaryAction}
								</ListItem>
							)
					  )}
			</List>
			<ListItem style={{ height: 50 }}>
				<ListItemText
					primary={
						loading ? (
							<Skeleton animation="wave" height={40} width="90%" style={{ marginBottom: 6 }} />
						) : (
							<Box display="flex" justifyContent="space-between">
								<Typography color="textSecondary">
									{(currentPage - 1) * rowsPerPage + 1} to{' '}
									{limit + (currentPage - 1) * rowsPerPage} of {totalRows}
								</Typography>
								<Box display="flex">
									<Pagination
										count={totalPages}
										page={currentPage}
										onChange={(_, page) => {
											if (currentPage !== page) {
												setCurrentPage(page)
												onPageChange(page)
											}
										}}
										size="small"
										hideNextButton
										hidePrevButton
									/>
									<IconButton
										onClick={() => {
											if (currentPage > 1) {
												setCurrentPage(currentPage - 1)
												onPageChange(currentPage - 1)
											}
										}}
										color="secondary"
										size="small">
										<NavigateBeforeIcon fontSize="inherit" />
									</IconButton>
									<IconButton
										onClick={() => {
											if (currentPage < totalPages) {
												setCurrentPage(currentPage + 1)
												onPageChange(currentPage + 1)
											}
										}}
										color="secondary"
										size="small">
										<NavigateNextIcon fontSize="inherit" />
									</IconButton>
								</Box>
							</Box>
						)
					}
				/>
			</ListItem>
		</List>
	)
}
