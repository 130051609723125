import { useEffect, useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import { client } from 'src/apollo/client'
import { FixedBackground } from 'src/components/FixedBackground'
import { useSearchQuery } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StorageEnum } from 'src/utils'

import useStyles from './ImpersonateTeacher.styles'

export const ImpersonateTeacher = () => {
	const styles = useStyles()
	const { teacherData: teacher, loginSignIn, loading } = useLoginContext()
	const [loadingSignOut, setLoadingSignOut] = useState(false)
	const queryParams = useSearchQuery()
	const token = queryParams.get('token')

	useEffect(() => {
		localStorage.setItem(StorageEnum.loginToken, '')
		setLoadingSignOut(true)
		if (teacher.teacher_id) {
			realHandleLogout()
		}
		if (token && !teacher.teacher_id) {
			setLoadingSignOut(false)
			loginSignIn(token)
		}
	}, [token, teacher])

	const realHandleLogout = async () => {
		await client.clearStore()
		setTimeout(() => {
			window.location.reload()
		}, 5000)
	}

	return (
		<>
			<FixedBackground>
				<Box className={styles.center}>
					{(loading || loadingSignOut) && <CircularProgress color="secondary" size={60} />}
				</Box>
			</FixedBackground>
		</>
	)
}
