import { useEffect, useState } from 'react'

import { ClassForCard } from 'src/components/Cards/ClassCard/ClassCard'
import { useGetClassesLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { MAX_DASHBOARD_QUERY_ITEMS } from 'src/utils'

export const useHomeClasses = () => {
	const {
		teacherData: { teacher_id }
	} = useLoginContext()
	const [classes, setClasses] = useState<ClassForCard[]>([])
	const [totalClassesCount, setTotalClassesCount] = useState(0)
	const [currentFetchedCount, setCurrentFetchCount] = useState(0)
	const [queryLimit, setQueryLimit] = useState(MAX_DASHBOARD_QUERY_ITEMS)
	const [shouldFetchMore, setShouldFetchMore] = useState(false)
	const [getClasses, { data: queryData, loading: isLoading }] = useGetClassesLazyQuery()

	useEffect(() => {
		getClasses({ variables: { teacherId: teacher_id, limit: queryLimit } })
	}, [queryLimit])

	useEffect(() => {
		if (queryData && !isLoading) {
			const classesFromQuery = (queryData.teacher_by_pk?.active ?? []).map(
				(classTeacher) => ({ ...classTeacher.class, isLoading: false } as unknown as ClassForCard)
			)
			setCurrentFetchCount(classesFromQuery.length)
			const classCount = queryData.teacher_by_pk?.active_aggregate?.aggregate?.count ?? 0

			if (classesFromQuery.length < classCount) {
				// We add a last item in loading state to show laoder while fetching more
				classesFromQuery.push({ ...classesFromQuery[0], isLoading: true })
			}
			setClasses(classesFromQuery)
			setTotalClassesCount(classCount)
		}
	}, [queryData, isLoading])

	useEffect(() => {
		if (shouldFetchMore && currentFetchedCount < totalClassesCount && !isLoading) {
			setQueryLimit((prevLimit) => prevLimit + MAX_DASHBOARD_QUERY_ITEMS)
			setShouldFetchMore(false)
		}
	}, [shouldFetchMore])

	return {
		classes,
		setQueryLimit,
		isLoading,
		setShouldFetchMore
	}
}
