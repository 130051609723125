import React from 'react'

import { environment, EnvironmentEnum } from './environment'

if (environment.env === EnvironmentEnum.develop && environment.enableWDYR) {
	const whyDidYouRender = require('@welldone-software/why-did-you-render')
	whyDidYouRender(React, {
		trackAllPureComponents: true
	})
}
