import { Box, Typography } from '@material-ui/core'
import { InputMultipleFile, UploadFileType } from 'src/components'
import { MAX_ASSETS } from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/components/StepTwoForm/utils'
import { FileTypeEnum } from 'src/utils'

import useStyles from './UploadAssetsStudent.styles'

type UploadExternalFileStepProps = Pick<UploadFileType, 'onAddAsset' | 'onAssetDelete'> & {
	files: UploadFileType['initialAssets']
}

export const UploadAssetsStudent = ({ onAddAsset, files, onAssetDelete }: UploadExternalFileStepProps) => {
	const styles = useStyles()

	return (
		<Box marginTop="40px">
			<Box display="flex" justifyContent="space-between">
				<Typography className={styles.fieldTitle}>Upload file</Typography>
			</Box>
			<Box>
				<InputMultipleFile
					onAddAsset={onAddAsset}
					onAssetDelete={onAssetDelete}
					reloadInitialAssetsOnChange={true}
					advise={'File type: MP3, MP4, WAV, PDF Max File size: 10MB.'}
					type={FileTypeEnum.Asset}
					placeholder="Pick files"
					initialAssets={files ?? undefined}
					maxAssets={MAX_ASSETS}
				/>
			</Box>
		</Box>
	)
}
