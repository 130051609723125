import { createStyles, makeStyles } from '@material-ui/core'

export const useInsightsStyles = makeStyles((theme) =>
	createStyles({
		selectorsRow: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: theme.spacing(2)
		},
		section: {
			margin: '3em 0'
		}
	})
)

export default useInsightsStyles
