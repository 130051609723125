import { ChangeEvent, useState } from 'react'

import { TextFieldProps, Typography } from '@material-ui/core'
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab'
import _ from 'lodash'
import { InputField } from 'src/components/Inputs/InputField/InputField'

import { BaseLoading } from '../../Loading/BaseLoading'
import useStyles from './DebouncedAutocomplete.styles'

type DebouncedAutocompleteProps<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
	onDebounceChange?: (text: string) => void
	renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
	inputProps?: TextFieldProps
	disableCloseOnSelect?: boolean
}

export const DebouncedAutocomplete = <
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
>({
	onDebounceChange,
	renderInput,
	inputProps,
	disableCloseOnSelect,
	...props
}: DebouncedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
	const styles = useStyles()
	const [, setDebouncedQuery] = useState<_.DebouncedFunc<() => void>>()
	const handleChange = (_event: ChangeEvent<unknown>, value: string) => {
		const debouncedFunction = _.debounce(() => {
			onDebounceChange?.(value)
		}, 500)
		if (onDebounceChange) {
			setDebouncedQuery((prevDebounce) => {
				if (prevDebounce?.cancel) prevDebounce?.cancel()

				return debouncedFunction
			})
		}
		debouncedFunction()
	}

	return (
		<>
			<Autocomplete
				loadingText={<BaseLoading />}
				noOptionsText="No Options Found"
				disableCloseOnSelect={disableCloseOnSelect}
				ChipProps={{
					className: styles.chip,
					deleteIcon: (
						<Typography data-cy="remove-selected" className={styles.closeChipIcon}>
							x
						</Typography>
					)
				}}
				renderInput={
					renderInput
						? renderInput
						: (params) => <InputField className={inputProps?.className} {...params} {...inputProps} />
				}
				ListboxProps={{
					className: styles.listBox
				}}
				onInputChange={handleChange}
				{...props}
			/>
		</>
	)
}
