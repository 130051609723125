import { useEffect, useState } from 'react'

import { useDebounce } from 'react-use'
import { useGetStudentByPkLazyQuery, useGetTeacherLazyQuery } from 'src/graphql/autogenerate/hooks'
import {
	Role_Name_Enum,
	Wurrly_Role_Enum,
	Integration_Name_Enum,
	Badge_Event_Name_Enum
} from 'src/graphql/autogenerate/schemas'
import { useAppcues } from 'src/hooks/useAppcues'
import { useSentry } from 'src/hooks/useSentry'
import { StorageEnum, validToken } from 'src/utils'

import { buildGenericContext } from '../context/genericContext'
import { useInsertStudentBadgeEventMutation } from '../graphql/autogenerate/hooks'
import { useLocalStorage } from './useLocalStorage'

const useLogin = () => {
	const [loading, setLoading] = useState(true)
	const [studentIdToSendEvent, setStudentIdToSendEvent] = useState<number>()
	const [loginToken, setLoginToken] = useLocalStorage(StorageEnum.loginToken, '')
	const [isLoggedAsTeacher, setIsLoggedAsTeacher] = useLocalStorage(StorageEnum.isLoggedAsTeacher, false)
	const [getTeacher, { data: teacherData, loading: getTeacherLoading }] = useGetTeacherLazyQuery()
	const [getStudent, { data: studentData, loading: getStudentLoading }] = useGetStudentByPkLazyQuery()
	const [loginEvent] = useInsertStudentBadgeEventMutation()
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [teacherTemporarilyEnterprise, setTeacherTemporarilyEnterprise] = useState(false)
	const [signedUpWithClever, setSignedUpWithClever] = useState(false)
	const [integrationName, setIntegrationName] = useState<Integration_Name_Enum>()

	useAppcues({ teacherData: teacherData?.teacher_by_pk, studentData: studentData?.student_by_pk })
	useSentry({
		isAuthenticated,
		teacherData: teacherData?.teacher_by_pk,
		studentData: studentData?.student_by_pk
	})

	const teacher = teacherData?.teacher_by_pk || {
		__typename: 'teacher',
		teacher_id: 0,
		is_enterprise: false,
		is_school_enterprise: false,
		is_district_enterprise: false,
		name_first: '',
		name_last: '',
		full_name: '',
		organization_name: '',
		image_path: '',
		email: '',
		is_active: false,
		school: {
			school_id: 0,
			name: '',
			nces_id: '',
			district: {
				district_id: 0,
				name: ''
			}
		},
		teacher_setting: {
			teacher_setting_id: 0,
			favorites_dont_ask_again_to_delete: false,
			sequence_lesson_dont_ask_again_add_to_class: false,
			teacher_id: 0
		},
		role_name: Role_Name_Enum.School
		// role_name: Role_Name_Enum.Private
	}

	const student = studentData?.student_by_pk || {
		__typename: 'student',
		student_id: 0,
		first_name: '',
		last_name: '',
		full_name: '',
		is_active: false,
		email: '',
		image_path: '',
		avatar_selected: false,
		stage_name: '',
		district: {
			district: []
		},
		grades: {
			grade: []
		},
		student_setting: {
			student_setting_id: 0,
			favorites_dont_ask_again_to_delete: false,
			student_id: 0,
			dont_ask_again_record: false
		}
	}

	useEffect(() => {
		return () => {
			setTeacherTemporarilyEnterprise(false)
		}
	}, [])

	useEffect(() => {
		setLoading(getTeacherLoading || getStudentLoading)
	}, [getTeacherLoading, getStudentLoading])

	// Clean userData when token is empty and set it in the client header when it has a value
	useEffect(() => {
		if (loginToken) loginSignIn(loginToken)
	}, [loginToken])

	useDebounce(
		() => {
			if (studentIdToSendEvent) {
				loginEvent({
					variables: { studentId: studentIdToSendEvent, eventName: Badge_Event_Name_Enum.Login }
				})
			}
		},
		500,
		[studentIdToSendEvent]
	)

	const loginSignIn = (token: string, integrationName?: Integration_Name_Enum): boolean => {
		setLoading(false)

		if (integrationName) setIntegrationName(integrationName)
		const decoded = validToken(token)
		if (decoded && decoded.id && decoded.role) {
			setLoginToken(token)
			const { role, id } = decoded
			if (role === Wurrly_Role_Enum.Teacher) {
				getTeacher({
					variables: {
						teacherId: id
					}
				})
				setIsLoggedAsTeacher(true)
			} else if (role === Wurrly_Role_Enum.Student) {
				setStudentIdToSendEvent(id)
				getStudent({
					variables: {
						studentId: id
					}
				})
				setIsLoggedAsTeacher(false)
			}
			setIsAuthenticated(true)
		} else {
			setLoginToken('')
			setIsAuthenticated(false)
		}

		return !!decoded
	}

	return {
		teacherData: teacher,
		studentData: student,
		loading,
		hasToken: !!loginToken,
		setLoading,
		isAuthenticated,
		loginSignIn,
		isLoggedAsTeacher,
		teacherTemporarilyEnterprise,
		setTeacherTemporarilyEnterprise,
		signedUpWithClever,
		setSignedUpWithClever,
		integrationName,
		setIntegrationName,
		// teacher data and studentData are null if the lazy query has never been executed it means that if there is a login token it has not been validated yet
		haveRequestedData: !!(teacherData || studentData)
	}
}

export const [LoginProvider, useLoginContext] = buildGenericContext(useLogin)
