import { useEffect, useState } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { cache } from 'src/apollo/state'
import { NotesIcon, ThinCheckIcon } from 'src/assets/icons'
import { BigBaseButton, TextInput, BaseDialog, InfoDialog, FixedBackground } from 'src/components'
import { useUpdateStudentAvatarAndStageNameMutation } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { CustomTooltip } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload/Tooltips'
import { buildRouteParameters, hasSpecialChar } from 'src/utils'

import { DialogHeader } from '../../../components/DialogHeader'
import { useStudentSignInOptionsStyles } from '../../../styleStudentSignInOptions'
import { useAvatarSelectionDialogStyles } from '../styleAvatarSelectionDialog'
import { AvatarList } from './AvatarList'

export const AvatarSelection = () => {
	const history = useHistory()
	const { studentData } = useLoginContext()
	const genericStyles = useStudentSignInOptionsStyles()
	const avatarStyles = useAvatarSelectionDialogStyles()
	const [stageName, setStageName] = useState(studentData.stage_name || '')
	const [selectedAvatarImg, setSelectedAvatarImg] = useState<string | null>(null)
	const [hasSpecialChars, setHasSpecialChars] = useState(false)
	const [mainDialogOpen, setMainDialogOpen] = useState(false)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [successDialogOpen, setSuccessDialogOpen] = useState(false)
	const [
		updateStudentAvatarMutation,
		{ data: updateStudentAvatarData, loading: updateStudentAvatarLoading, error: updateStudentAvatarError }
	] = useUpdateStudentAvatarAndStageNameMutation()
	const alreadySelectedAvatar = studentData.image_path

	const handleStageNameChange = (text: string) => {
		setStageName(text.trimStart())
		setHasSpecialChars(hasSpecialChar(text))
	}
	const isButtonDisabled = hasSpecialChars || !stageName.length
	const updateStudentAvatar = () => {
		updateStudentAvatarMutation({
			variables: {
				studentId: studentData.student_id,
				stageName: stageName.trim(),
				imagePath: selectedAvatarImg
			}
		})
	}

	const redirectToHome = () => {
		// Workaround, remove cache.evict un mutation update
		const identify = cache.identify(studentData)
		cache.evict({
			id: identify,
			fieldName: 'image_path'
		})
		cache.evict({
			id: identify,
			fieldName: 'stage_name'
		})
		history.push(buildRouteParameters(StudentPages.Home))
	}

	useEffect(() => {
		setTimeout(() => {
			setMainDialogOpen(true)
		}, 500)
	}, [])
	useEffect(() => {
		if (updateStudentAvatarError) {
			setErrorDialogOpen(true)
		} else if (updateStudentAvatarData) {
			setSuccessDialogOpen(true)
		}
	}, [updateStudentAvatarData, updateStudentAvatarError])

	return (
		<>
			<FixedBackground>
				<img
					src={NotesIcon}
					style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
				/>
				{!mainDialogOpen ? (
					<Box
						style={{
							zIndex: 1000,
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate3d(-50%,-50%,0)'
						}}>
						<CircularProgress color="secondary" size={80} />
					</Box>
				) : (
					<></>
				)}
				<BaseDialog
					className={genericStyles.baseDialogNoBackground}
					style={{ backgroundImage: 'none' }}
					open={mainDialogOpen}
					paperProps={{ className: avatarStyles.paper }}
					onClose={() => {}}
					dividers={false}
					header={<DialogHeader />}
					isActionsHidden
					body={
						<Box
							p="19px 45px 40px"
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							flexDirection="column">
							<Box display="flex" flexDirection="column" width="100%">
								<Box display="flex" justifyContent="space-between" alignItems="baseline">
									<Typography>
										<b>Stage Name</b>
									</Typography>
									<CustomTooltip
										content={
											<Typography>
												Your artist name or how'd you like to be called.
											</Typography>
										}
									/>
								</Box>
								<TextInput
									value={stageName}
									onChange={(e) => handleStageNameChange(e.target.value)}
								/>
								{hasSpecialChars && (
									<Typography style={{ color: 'red' }}>
										The stage name can only contain letters, white space and numbers
									</Typography>
								)}
							</Box>
							<Box>
								<Typography style={{ margin: '15px 0px 20px 0px' }}>
									<b>Select Your Avatar</b>
								</Typography>
								<AvatarList
									initialAvatarImg={alreadySelectedAvatar}
									setSelectedAvatarImg={setSelectedAvatarImg}
									stageName={stageName}
								/>
							</Box>
							<Box display="flex" justifyContent="space-between" pt="50px" width="100%">
								<BigBaseButton onClick={redirectToHome} className={avatarStyles.btn}>
									Cancel
								</BigBaseButton>
								<BigBaseButton
									color="secondary"
									className={clsx(avatarStyles.btn, avatarStyles.disabledBtn)}
									disabled={isButtonDisabled || updateStudentAvatarLoading}
									onClick={updateStudentAvatar}>
									Save
									{updateStudentAvatarLoading && <CircularProgress color="primary" size={30} />}
								</BigBaseButton>
							</Box>
						</Box>
					}
				/>
				<InfoDialog
					open={errorDialogOpen}
					onClose={() => {
						setErrorDialogOpen(false)
					}}
					icon="x"
					title="Something went wrong"
					body="Something went wrong while updating your settings"
					confirmLabel="Done"
					confirmProps={{ style: { fontWeight: 'bold' } }}
					discardProps={{ style: { fontWeight: 'bold' } }}
					onConfirm={() => {
						setErrorDialogOpen(false)
					}}
				/>
				<InfoDialog
					open={successDialogOpen}
					onClose={() => {}}
					icon={<ThinCheckIcon />}
					title="Setting updated!"
					body="You have successfully updated your avatar and stage name."
					confirmLabel="Done"
					onConfirm={() => redirectToHome()}
				/>
			</FixedBackground>
		</>
	)
}
