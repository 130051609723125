import { useHistory } from 'react-router-dom'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { ModulesCard, ModulesCardProps } from './ModulesCard'

export const ModulesCardContainer = ({ module, ...props }: ModulesCardProps) => {
	const history = useHistory()
	const goToModule = () =>
		history.push(buildRouteParameters(Pages.ModuleDetails, { moduleId: module.module_id }))

	return (
		<>
			<ModulesCard module={module} deleteTooltip="Delete Module" {...props} onView={goToModule} />
		</>
	)
}
