import { Tooltip, Typography } from '@material-ui/core'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import clsx from 'clsx'
import { BaseButton, BaseButtonProps } from 'src/components'

import useStyles from './AssignmentAdded.styles'

export const AssignmentAdded = (props: BaseButtonProps) => {
	const styles = useStyles()

	return (
		<Tooltip title="Go to assignment">
			<BaseButton
				endIcon={<AssignmentTurnedInIcon className={styles.whiteColor} />}
				{...props}
				className={clsx(styles.addedAssignmentButton, props.className)}>
				<Typography className={styles.whiteColor}>Assignment Added</Typography>
			</BaseButton>
		</Tooltip>
	)
}
