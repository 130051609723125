import { useMemo, useState } from 'react'

import { ApolloProvider } from '@apollo/client'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { Amplify } from 'aws-amplify'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AccountActivationContainer } from 'src/components/ActivateAccount/AccountActivation.container'
import { VersionContainer } from 'src/components/Version/Version.container'
import { AccountActivation } from 'src/scenes/Accountactivations/AccountActivation'
import { ParentAcceptance } from 'src/scenes/Accountactivations/scenes/ParentAcceptance/ParentAcceptance'
import { TeacherTokenAccountActivation } from 'src/scenes/Accountactivations/scenes/TeacherAccountActivation/TeacherTokenAccountActivation'
import { CaptureRouteNotFound, RouteNotFound } from 'src/scenes/Errors/404.container'
import { LoginPage } from 'src/scenes/Login/scenes/1-TeacherLogin/LoginPage'
import { SignupPage } from 'src/scenes/Login/scenes/1-TeacherLogin/SignupPage'
import { StudentSignInOptionsPage } from 'src/scenes/Login/scenes/2-StudentLogin/scenes/SignInOptions/StudentSigInOptionsPage'
import { RecoverPassword } from 'src/scenes/Login/scenes/3-RecoverPassword/RecoverPassword'
import { CleverLogin } from 'src/scenes/Login/scenes/4-CleverLogin/CleverLogin'
import { ClassLinkLogin } from 'src/scenes/Login/scenes/5-ClassLinkLogin/ClassLinkLogin'
import { SelectLogin } from 'src/scenes/Login/scenes/6-SelectLogin/SelectLogin'
import { CanvasLogIn } from 'src/scenes/Login/scenes/7-CanvasLogin'
import { StudentRoutes } from 'src/scenes/Student'
import { TeacherRoutes } from 'src/scenes/Teacher'
import { ImpersonateTeacher } from 'src/scenes/Teacher/scenes/16-ImpersonateTeacher/ImpersonateTeacher'
import { createBaseStyles } from 'src/styles/baseStyles'

import { client } from './apollo/client'
import awsconfig from './aws-exports'
import { IntegrationProvider } from './hooks/useIntegration'
import { LoginProvider } from './hooks/useLogin'
import { PublicRoute } from './routes/routes'
import { StudentPages } from './routes/studentpages'
import { Pages } from './routes/teacherPages'
import { loadSentry } from './utils/sentry'

Amplify.configure(awsconfig)

// loading sentry
loadSentry()

const AppContent = () => {
	return (
		<BrowserRouter>
			<CssBaseline />
			<CaptureRouteNotFound>
				{/** direct children of switch must be Route components */}
				<Switch>
					<PublicRoute exact path="/" component={() => <SelectLogin App={App} />} />
					<PublicRoute exact path="/impersonate" component={ImpersonateTeacher} />
					<PublicRoute exact path="/login/student" component={StudentSignInOptionsPage} />
					<PublicRoute exact path="/login/teacher" component={LoginPage} />
					<PublicRoute exact path="/signup/teacher" component={SignupPage} />
					<PublicRoute exact path="/oauth" component={CleverLogin} />
					<PublicRoute exact path="/oauth-classlink" component={ClassLinkLogin} />
					<PublicRoute exact path="/oauth-canvas" component={CanvasLogIn} />
					<PublicRoute exact path="/activate" component={AccountActivation} />
					<PublicRoute
						exact
						path="/activate/teacher"
						component={TeacherTokenAccountActivation}
						dontValidateAutentication
					/>
					<PublicRoute exact path="/parentAcceptance" component={ParentAcceptance} />
					<PublicRoute exact path="/recoverPassword" component={RecoverPassword} />
					<Route path={Pages.Home.path}>
						<VersionContainer />
						<AccountActivationContainer />
						<TeacherRoutes />
					</Route>
					<Route path={StudentPages.Home.path}>
						<VersionContainer />
						<StudentRoutes />
					</Route>

					<RouteNotFound />
				</Switch>
			</CaptureRouteNotFound>
		</BrowserRouter>
	)
}

const App = () => {
	const [darkMode] = useState(false)
	const theme = useMemo(() => createBaseStyles(darkMode), [darkMode])

	return (
		<ApolloProvider client={client}>
			<MuiThemeProvider theme={theme}>
				<LoginProvider>
					<IntegrationProvider>
						<AppContent />
					</IntegrationProvider>
				</LoginProvider>
			</MuiThemeProvider>
		</ApolloProvider>
	)
}

export default App
