import { useEffect, useState, Fragment, ChangeEvent } from 'react'

import { Box, CircularProgress, TextField, Typography } from '@material-ui/core'
import { getCyKey } from 'src/utils'

import { BaseDialog, BigBaseButton } from '../../../components'
import { AutocompletePlaces } from '../../../components/Inputs/AutocompletePlaces'
import { useLoginStyles } from '../styles'
import { DialogHeader } from './DialogHeader'

type SchoolDialogProps = {
	open: boolean
	onClose: () => void
	onConfirm: () => void
	onDiscard: () => void
	setPrivateTeacherData: (organizationName: string, address: string) => void
	isLoading: boolean
}

export const PrivateTeacherDialog = ({
	open,
	onClose,
	onConfirm,
	onDiscard,
	setPrivateTeacherData,
	isLoading
}: SchoolDialogProps) => {
	const classes = useLoginStyles({})
	const [organizationName, setOrganizationName] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const handleAddressChange = (_event: ChangeEvent<unknown>, newValue: string) => {
		setAddress(newValue)
	}

	useEffect(() => {
		setPrivateTeacherData(organizationName, address)
	}, [organizationName, address])

	return (
		<BaseDialog
			open={open}
			paperProps={{ className: classes.dialogsPaper }}
			onClose={() => {
				setOrganizationName('')
				setAddress('')
				if (onClose) onClose()
			}}
			backdropStyles={{ opacity: '0 !important' }}
			dividers={false}
			header={<DialogHeader title="I’m a Private Teacher" subtitle="Last couple of questions..." />}
			isActionsHidden={true}
			body={
				<Fragment>
					<Box>
						<Typography className={classes.label}>
							<b>Organization Name</b>
						</Typography>
						<TextField
							data-cy={getCyKey(PrivateTeacherDialog, 'organizationName')}
							style={{ width: '100%' }}
							value={organizationName}
							onChange={(e) => setOrganizationName(e.target.value)}
							variant="outlined"
							color="secondary"
						/>
					</Box>
					<Box>
						<Typography className={classes.label}>
							<b>Address</b>
						</Typography>
						<AutocompletePlaces inputValue={address} onInputChange={handleAddressChange} />
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around">
						<BigBaseButton
							color="secondary"
							variant="contained"
							disabled={!address || !organizationName || isLoading}
							className={classes.getStartedButton}
							onClick={() => {
								if (onConfirm) onConfirm()
							}}>
							<b>Get Started! </b>
							{isLoading && <CircularProgress color="secondary" />}
						</BigBaseButton>
						<span
							data-cy={getCyKey(PrivateTeacherDialog, 'btnGoBack')}
							className={classes.goBackButton}
							onClick={() => {
								setOrganizationName('')
								setAddress('')
								if (onDiscard) onDiscard()
							}}>
							Go Back
						</span>
					</Box>
				</Fragment>
			}
		/>
	)
}
