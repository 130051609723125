import { Box } from '@material-ui/core'
import { SongsWrapper } from 'src/components/Song/SongsWrapper'

import { ClassSongsTitle } from './components/ClassSongsTitle'

export const ClassSongs = () => {
	return (
		<Box>
			<ClassSongsTitle />
			<SongsWrapper />
		</Box>
	)
}
