import React, { useEffect, useState } from 'react'

import { Box, Button, List, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'

import {
	AccordionWrapper,
	DeleteItemDialog,
	BaseLoading,
	MainTitle,
	SelectSort
} from '../../../../../../../components'
import {
	useDeleteClassModuleMutation,
	useGetClassTeacherByPkQuery,
	useGetClassModulesQuery
} from '../../../../../../../graphql/autogenerate/hooks'
import { Module, Order_By } from '../../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../../hooks/useLogin'
import { useScroll } from '../../../../../../../hooks/useScroll'
import { useWurrlyParams } from '../../../../../../../hooks/useWurrlyParams'
import { Pages } from '../../../../../../../routes/teacherPages'
import { SortOrder } from '../../../../../../../utils'
import { MAX_LIST_ITEMS } from '../../../../../../../utils/constants'
import { buildRouteParameters } from '../../../../../../../utils/routes-utils'
import { buildBreadCrumbs, getCyKey } from '../../../../../../../utils/utils'
import { ListItemModule } from '../../../../6-Lessons/components'

const useStyles = makeStyles((theme: Theme) => ({
	boxList: {
		padding: 0,
		'& .listItem': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

export const ClassModules = ({ page }: PagesProps) => {
	useScroll()
	const history = useHistory()
	const styles = useStyles(1.5)
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const params = useWurrlyParams<typeof Pages.ClassModules.params[number]>()
	const { classId } = params
	const { data: classData } = useGetClassTeacherByPkQuery({
		variables: {
			teacherId,
			classId
		}
	})

	const [limitModules, setLimitModules] = useState<number | undefined>(MAX_LIST_ITEMS)
	const [sortModules, setSortModules] = useState(SortOrder.Up)
	const {
		data: modulesData,
		loading,
		refetch
	} = useGetClassModulesQuery({
		variables: {
			classId,
			limit: limitModules
		}
	})
	const modules = (modulesData?.class_by_pk?.class_modules?.filter((i) => i.module).map((el) => el.module) ||
		[]) as Module[]
	const count = modulesData?.class_by_pk?.class_modules?.filter((i) => i.module)?.length || 0
	const [moduleIdToDelete, setModuleIdToDelete] = useState<number>()
	const [deleteClassModule] = useDeleteClassModuleMutation()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: classData?.teacher_by_pk?.class_teachers[0]?.class.title
			},
			{
				index: 2,
				overrideName: 'Lessons'
			},
			{
				index: 3,
				overrideName: 'Modules'
			}
		],
		params
	})

	useEffect(() => {
		refetch({
			classId,
			limit: limitModules,
			orderTitle:
				sortModules !== SortOrder.Recent
					? sortModules !== SortOrder.Down
						? Order_By.Asc
						: Order_By.Desc
					: undefined
		})
	}, [sortModules, limitModules])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSortModules(selectedSort)
	}

	const handleSeeAll = () => {
		setLimitModules(undefined)
	}

	const handleSelectOne = (moduleId: number) => {
		history.push(
			buildRouteParameters(
				Pages.ClassModuleDetail, // Route with paramters
				{
					classId,
					moduleId
				}
			)
		)
	}

	return (
		<Box pt={3}>
			{loading && <BaseLoading />}

			{!loading && (
				<Box>
					<MainTitle
						breadcrumbs={breadcrumbs}
						title={'Class Modules'}
						description={'These are the Modules that have been added to this Class.'}
						rightActions={[
							{
								variant: 'contained',
								color: 'secondary',
								startIcon: <AddIcon />,
								name: 'Add Module',
								onClick: () => {
									history.push(Pages.Modules.path)
								}
							}
						]}
					/>
					<AccordionWrapper
						defaultExpanded
						summary={
							<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
								<Typography variant="h6">
									<b>Saved modules ({count})</b>
								</Typography>
								<div style={{ flexGrow: 1 }} />

								<SelectSort
									cyKey={getCyKey(ClassModules)}
									value={sortModules}
									onChange={handleSort}
								/>
							</Box>
						}
						details={
							<List className={styles.boxList}>
								{modules.map((module) => (
									<Box key={module.module_id} className="listItem">
										<ListItemModule
											subscription={module.is_purchased_by_teacher?.valueOf()}
											module_id={module.module_id}
											imageUrl={module.image_path ?? ''}
											title={module.title}
											description={module.description}
											genres={`•`}
											totallessons={module.module_lesson_plan.length}
											onClick={() => handleSelectOne(module.module_id)}
											btnStandard={{}}
											btnRemove={{
												onClick: (e) => {
													e.stopPropagation()
													setModuleIdToDelete(module.module_id)
												}
											}}
											btnView={{
												onClick: (e) => {
													e.stopPropagation()
													handleSelectOne(module.module_id)
												}
											}}
										/>
									</Box>
								))}
							</List>
						}
						actions={
							<Button onClick={() => handleSeeAll()} disabled={modules.length === count}>
								<Typography color="textSecondary">
									<b>See All</b>
								</Typography>
							</Button>
						}
					/>
				</Box>
			)}
			<DeleteItemDialog
				itemName="Module"
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Module from your Class?',
						body: 'You’ll be able to re-add it from the Lessons page at any time.',
						buttonLabel: ' Delete Module'
					},
					confirmation: {
						title: 'Module Deleted!',
						body: 'You have successfully deleted this Module from your Class.'
					}
				}}
				isOpen={!!moduleIdToDelete}
				setIsOpen={setModuleIdToDelete}
				onConfirm={async () => {
					if (moduleIdToDelete) {
						await deleteClassModule({
							variables: {
								classId,
								moduleId: moduleIdToDelete
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_class_module?.returning[0].class
								if (!classToUpdateInCache) return
								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'class_modules'
								})
							}
						})
					}
				}}
			/>
		</Box>
	)
}
