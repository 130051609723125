import { alpha, makeStyles } from '@material-ui/core'
import { COLORS } from 'src/styles/colors'

export const useWurrliesGridStyles = makeStyles((theme) => ({
	grid: { marginTop: '40px' },
	boxGrow: { flexGrow: 1 },
	actionButton: {
		marginRight: 20,
		backgroundColor: '#fff'
	},
	actionButtonColor: {
		marginRight: 20,
		backgroundColor: alpha(COLORS.yellow, 0.2)
	},
	boxSelected: {
		backgroundColor: 'rgba(243, 171, 52, 0.24)',
		borderRadius: '5px',
		border: '2px solid #F3AB34',
		height: '405.5px',
		position: 'absolute',
		right: 16,
		top: 16,
		width: '246.45px'
	},
	boxHighlighted: {
		border: '3px solid',
		borderColor: theme.palette.secondary.main,
		borderRadius: '5px'
	},
	boxIcon: {
		display: 'flex',
		position: 'absolute',
		right: 24,
		top: 21
	},
	activity: { height: '5em' }
}))

export default useWurrliesGridStyles
