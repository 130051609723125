import React, { CSSProperties } from 'react'

import { Button } from '@material-ui/core'
import clsx from 'clsx'
import CanvasIcon from 'src/assets/icons/LoginIntegrations/CanvasIcon.svg'
import { useIntegrationContext } from 'src/hooks/useIntegration'
import { IntegrationButtonActions, SignUpData } from 'src/scenes/Login/enums'

import useStyles from './IntegrationButtonClassLink.styles'

type IntegrationButtonProps = {
	className?: string
	customLabel?: string
	style?: CSSProperties
	disabled?: boolean
	loading: boolean
	action: IntegrationButtonActions
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
	setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>
	onButtonClick?: () => void
}

const LABEL = 'Canvas'
const ICON = CanvasIcon
// const integrationType = 'Canvas' as unknown as Integration_Name_Enum
export const IntegrationButtonCanvas = ({
	className,
	style,
	disabled = false,
	loading: inheritedLoading,
	action,
	onButtonClick
}: IntegrationButtonProps) => {
	const styles = useStyles({ inheritedLoading })

	const {
		canvasInstance: { openPopup }
	} = useIntegrationContext()

	const handleOnclickButton = () => {
		if (onButtonClick) onButtonClick()
		if (action === IntegrationButtonActions.Login) {
			openPopup()
		}
	}

	return (
		<>
			<Button
				className={clsx(styles.button, className)}
				disabled={disabled || inheritedLoading}
				onClick={handleOnclickButton}
				style={style}>
				<img height={25} src={ICON} alt={LABEL} />
				{!inheritedLoading && <b className={styles.label}>{LABEL}</b>}
			</Button>
		</>
	)
}
