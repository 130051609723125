import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import {
	EditIconDecorated,
	PlayIconYellow,
	RedoIcon,
	ScissorsCutLine,
	StopRecordingYellowIcon
} from 'src/assets/icons/CustomIcons'
import { ActionButton, BaseButton } from 'src/components'
import { StudioStep } from 'src/components/Studio/constants'
import { useReviewAndSubmitContext } from 'src/components/Studio/Hooks/useReviewAndSubmit'
import { RedoDialog } from 'src/components/Studio/Record/RedoDialog'
import { VideoReview } from 'src/components/Studio/Review/effects/VideoReview/VideoReview'
import { useStudioReviewContext } from 'src/components/Studio/Review/useStudioReview'
import { StudioDialog } from 'src/components/Studio/StudioDialog/StudioDialog'

import { useStudioStyles, useDialogButtonStyles } from '../Record/styles/studioStyles'

type VideoReviewDialogProps = {
	isTeacher?: boolean
}
/**
 * This component should be called with the selected song parameters as `location.state`
 * in the structucture of `StudioReviewLocationState`.
 *
 * @example usage with `history.push`
 * 	history.push({
 *		pathname: buildRouteParameters(StudentPages.StudioReview, { songId }),
 *		state: {
 *			audioUrl: 'http://some_audio_page.com/track/42',
 *			videoUrl: 'blob:http://localhost:3006/a-long-hash-f23d4a-42069-a3c9bf',
 *			trackId: 11,
 *			isCameraOn: true,
 *  		classId: 42,
 *  		submissionId: 69,
 *  		wurrlyType: Wurrly_Type_Enum.Indie,
 *		} as StudioReviewLocationState
 *	})
 */
export const VideoReviewDialog = ({ isTeacher }: VideoReviewDialogProps) => {
	const styles = useStudioStyles()
	const buttonStyles = useDialogButtonStyles()
	const {
		isMediaPlaying,
		toggleMedia,
		songId,
		isScrubberVisible,
		isApplyingEffects,
		toggleScrubber,
		toggleEffects,
		isBlankSong
	} = useStudioReviewContext()
	const { setStep, setShouldUploadVideo, classId, submissionId } = useReviewAndSubmitContext()
	const [isRedoDialogOpen, setIsRedoDialogOpen] = useState(false)

	const goToSubmit = () => {
		setStep(StudioStep.Submit)
	}
	const closeStudio = () => {
		window.close()
	}

	useEffect(() => {
		setShouldUploadVideo(!isTeacher)
	}, [isTeacher])

	return (
		<>
			<StudioDialog
				hasVideo
				body={<VideoReview />}
				bottomActions={
					<BottomActions
						{...{
							styles,
							setIsRedoDialogOpen,
							isMediaPlaying,
							toggleMedia,
							buttonStyles,
							isApplyingEffects,
							toggleEffects,
							isScrubberVisible,
							toggleScrubber,
							isTeacher,
							closeStudio,
							goToSubmit
						}}
					/>
				}
			/>
			<RedoDialog
				isOpen={isRedoDialogOpen}
				setIsOpen={setIsRedoDialogOpen}
				currentSongId={songId}
				classId={classId}
				submissionId={submissionId}
				isTeacher={isTeacher}
				isBlankSong={isBlankSong}
			/>
		</>
	)
}

const BottomActions: React.FC<{
	styles: ReturnType<typeof useStudioStyles>
	setIsRedoDialogOpen: (x: boolean) => void
	toggleMedia: () => void
	isMediaPlaying: boolean
	buttonStyles: ReturnType<typeof useDialogButtonStyles>
	isApplyingEffects: boolean
	toggleEffects: () => void
	isScrubberVisible: boolean
	toggleScrubber: () => void
	isTeacher?: boolean
	closeStudio: () => void
	goToSubmit: () => void
}> = ({
	styles,
	setIsRedoDialogOpen,
	isMediaPlaying,
	toggleMedia,
	buttonStyles,
	isApplyingEffects,
	toggleEffects,
	isScrubberVisible,
	toggleScrubber,
	isTeacher,
	closeStudio,
	goToSubmit
}) => (
	<>
		<BaseButton
			className={styles.bottomActionBtn}
			startIcon={<RedoIcon />}
			onClick={() => setIsRedoDialogOpen(true)}>
			Redo
		</BaseButton>

		<Box className={styles.reviewControls}>
			<ActionButton
				onClick={toggleMedia}
				className={buttonStyles.playBtn}
				icon={isMediaPlaying ? <StopRecordingYellowIcon /> : <PlayIconYellow />}
			/>
			<ActionButton
				className={buttonStyles.editBtn}
				icon={<EditIconDecorated fill={isApplyingEffects ? '#F3AB34' : undefined} />}
				onClick={toggleEffects}
				selected={isApplyingEffects}
			/>
			<ActionButton
				className={buttonStyles.scrubberBtn}
				icon={<ScissorsCutLine fill={isScrubberVisible ? '#F3AB34' : undefined} />}
				onClick={toggleScrubber}
				selected={isScrubberVisible}
			/>
		</Box>
		<BaseButton
			color="secondary"
			className={styles.bottomActionBtn}
			onClick={isTeacher ? closeStudio : goToSubmit}>
			{isTeacher ? 'Close Studio' : 'Confirm'}
		</BaseButton>
	</>
)
