import { useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'

import { StandardIcon } from '../../../../../../../assets/icons'
import { ActionButton, DeleteItemDialog, ListLessons, MainTitle } from '../../../../../../../components'
import {
	useDeleteClassSequenceMutation,
	useGetClassTeacherByPkQuery,
	useGetSequenceItemByPkQuery
} from '../../../../../../../graphql/autogenerate/hooks'
import { Lesson_Plan, Sequence } from '../../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../../hooks/useLogin'
import { useScroll } from '../../../../../../../hooks/useScroll'
import { useWurrlyParams } from '../../../../../../../hooks/useWurrlyParams'
import { Pages } from '../../../../../../../routes/teacherPages'
import { COLORS } from '../../../../../../../styles/colors'
import { buildBreadCrumbs, buildRouteParameters } from '../../../../../../../utils'
import { getSequenceItemCatalogItems } from '../../../../../../../utils/utils'

export const ClassSequenceItemDetail = ({ page }: PagesProps) => {
	useScroll()
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const params = useWurrlyParams<typeof Pages.ClassSequenceItemDetail.params[number]>()
	const { sequenceItemId, classId } = params

	const { data: classData } = useGetClassTeacherByPkQuery({
		variables: {
			teacherId,
			classId
		}
	})
	const { data: sequenceItemData } = useGetSequenceItemByPkQuery({
		variables: {
			sequenceItemId,
			teacherId,
			classId
		}
	})
	const sequence = sequenceItemData?.sequence_by_pk as Sequence | undefined

	const [sequenceItemIdToDelete, setSequenceItemIdToDelete] = useState<number>()
	const [deleteClassSequenceItem] = useDeleteClassSequenceMutation()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: classData?.teacher_by_pk?.class_teachers[0]?.class?.title
			},
			{
				index: 2,
				overrideName: 'Lessons'
			},
			{
				index: 3,
				overrideName: 'Sequences'
			},
			{
				index: 4,
				overrideName: sequence?.name
			}
		],
		params
	})

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={`${sequence?.sequence_topic_sequences[0]?.sequence_topic.title ?? ''} (${sequence?.name})`}
				description={sequence?.description ?? ''}
				list={getSequenceItemCatalogItems(sequence)}
				actionButtons={[
					<ActionButton key={0} disabled onClick={() => {}} size="large" icon={<StandardIcon />} />
				]}
				rightActions={[
					{
						style: {
							backgroundColor: COLORS.error.main,
							color: 'white',
							height: 56,
							padding: '0px 30px'
						},
						variant: 'contained',
						startIcon: <DeleteIcon />,
						name: 'Delete Sequence',
						onClick: () => {
							setSequenceItemIdToDelete(sequenceItemId)
						}
					}
				]}
			/>

			<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>There are {sequence?.sequence_lesson_plans?.length ?? '0'} Lessons in this Sequence</b>
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs>
					<ListLessons
						teacherId={teacherId}
						data={
							(sequence?.sequence_lesson_plans?.map((item) => item.lesson_plan) ||
								[]) as Lesson_Plan[]
						}
						spacing={1.5}
						classId={classId}
						addAssignmentButton
					/>
				</Grid>
			</Grid>

			<DeleteItemDialog
				itemName="Sequence"
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Sequence from your Class?',
						body: 'You’ll be able to re-add it from the Lessons page at any time.',
						buttonLabel: ' Delete Sequence'
					},
					confirmation: {
						title: 'Sequence Deleted!',
						body: 'You have successfully deleted this Sequence from your Class.'
					}
				}}
				isOpen={!!sequenceItemIdToDelete}
				setIsOpen={setSequenceItemIdToDelete}
				onConfirm={async () => {
					if (sequenceItemIdToDelete) {
						await deleteClassSequenceItem({
							variables: {
								classId,
								sequenceItemId: sequenceItemIdToDelete
							},
							update: (cache) => {
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_topic'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_topic_by_pk'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_by_pk'
								})
							}
						})
					}
				}}
				onDone={() => {
					history.push(
						buildRouteParameters(
							Pages.ClassLesson, // Route with paramters
							{ classId }
						)
					)
				}}
			/>
		</Box>
	)
}
