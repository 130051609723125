import { useState } from 'react'

import { Version } from 'src/components/Version/Version'
import { useGetVersionAppSubscription } from 'src/graphql/autogenerate/hooks'
import { useLocalStorage } from 'src/hooks'
import { StorageEnum } from 'src/utils'

export type AppVersion = {
	version: string
	created_at: string
}

export const VersionContainer = () => {
	const [localVersion, setLocalVersion] = useLocalStorage<AppVersion>(StorageEnum.appVersion, {
		version: '',
		created_at: ''
	})
	const [version, setVersion] = useState<AppVersion>(localVersion)
	const [newVersionAvailable, setNewVersionAvailable] = useState(false)

	const handleRefresh = () => {
		setLocalVersion(version)
		setNewVersionAvailable(false)
	}

	useGetVersionAppSubscription({
		onSubscriptionData: ({ subscriptionData }) => {
			// if there are a new version available

			if (!localVersion.version) {
				setLocalVersion(subscriptionData.data?.app[0] as AppVersion)

				return
			}
			if (subscriptionData.data?.app[0] && version.version !== subscriptionData.data?.app[0].version) {
				setVersion(subscriptionData.data?.app[0] as AppVersion)
				setNewVersionAvailable(true)
			}
		}
	})

	// check if there is no version available
	if (!newVersionAvailable) {
		return null
	}

	return <Version version={version.version} onRefresh={handleRefresh} />
}
