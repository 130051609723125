import { useEffect, useState } from 'react'

import moment, { Moment } from 'moment'
import { ChartDataType } from 'src/@types'
import { buildGenericContext } from 'src/context/genericContext'
import { DateFilterEnum } from 'src/environment'
import {
	useGetStudentProductivityByPkQuery,
	useGetStudentClassAssignmentsLazyQuery,
	useGetWurrliesCountSinceLastWeekByStudentIdLazyQuery,
	useGetStudentClassesQuery,
	useGetStudentCompletedAssignmentsQuery,
	useGetStudentByPkExtendedLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { GetStudentProductivityByPkQueryVariables } from 'src/graphql/autogenerate/operations'
import {
	Assignment,
	Class,
	Submission,
	Order_By,
	Student_Productivity_Daily
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { AssignmentSortOrder, getComparisonDate } from 'src/utils'

import {
	buildLabels,
	getWurrliesPercentage,
	buildDataForDataset,
	buildDataset,
	getComparisonMoment,
	GrafEnum
} from './utils'

export type StudentCompletedAssignment = {
	assignment: Assignment
	submission: Submission | undefined
	totalSubmitted: number
	totalGraded: number
}

const useStudentAnalytics = () => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.StudentAnalytics.params[number]>()
	const studentId = params.studentId
	const teacherId = teacher.teacher_id
	const [chartData, setChartData] = useState<ChartDataType>({ labels: [], datasets: [] })
	const [sinceLastWeekWurrlies, setSinceLastWeekWurrlies] = useState(0)
	const [recordingSessions, setRecordingSessions] = useState(0)
	const [filter, setFilter] = useState<DateFilterEnum>(DateFilterEnum.lastWeek)
	const [classId, setClassId] = useState(0)
	const [fromDate, setFromDate] = useState<Moment>()
	const [toDate, setToDate] = useState<Moment>()
	const [labels, setLabels] = useState<string[]>([])
	const [graf, setGraf] = useState(GrafEnum.Days)
	const [studentCompletedAssignments, setStudentCompletedAssignments] = useState<StudentCompletedAssignment[]>(
		[]
	)
	const [sortCompletedAssignmentOrder, setSortCompletedAssignmentOrder] = useState(AssignmentSortOrder.AlphAsc)
	const [studentProductivityVariables, setStudentProductivityVariables] =
		useState<GetStudentProductivityByPkQueryVariables>({
			studentId,
			year: Number(moment().year()),
			minMonth: Number(fromDate?.month()),
			maxMonth: Number(toDate?.month()),
			minDay: Number(fromDate?.date()),
			maxDay: Number(toDate?.date())
		})

	const { data: completedClassAssignents } = useGetStudentCompletedAssignmentsQuery({
		variables: {
			studentId,
			classId,
			fromDate,
			toDate,
			orderAlph:
				sortCompletedAssignmentOrder === AssignmentSortOrder.AlphAsc
					? Order_By.Asc
					: sortCompletedAssignmentOrder === AssignmentSortOrder.AlphDesc
					? Order_By.Desc
					: undefined,
			orderDate:
				sortCompletedAssignmentOrder === AssignmentSortOrder.DateAsc
					? Order_By.Asc
					: sortCompletedAssignmentOrder === AssignmentSortOrder.DateDesc
					? Order_By.Desc
					: undefined
		}
	})
	const { data: classData, loading: classLoading } = useGetStudentClassesQuery({
		variables: { studentId, teacherId }
	})
	const [getWurrliesCount, { data: wurrliesCount, loading: wurrliesLoading }] =
		useGetWurrliesCountSinceLastWeekByStudentIdLazyQuery()
	const [getStudentByPkExtended, { data, loading }] = useGetStudentByPkExtendedLazyQuery()
	const { data: studentProductivityData } = useGetStudentProductivityByPkQuery({
		variables: studentProductivityVariables
	})
	const [getStudentClassAssignments, { data: classAssignmentsCount }] = useGetStudentClassAssignmentsLazyQuery()

	const classes =
		classData?.teacher_by_pk?.class_teachers.map((teacherClass) => teacherClass.class as Class) || []

	useEffect(() => {
		if (!studentId) return

		getStudentByPkExtended({
			variables: {
				studentId,
				teacherId
			}
		})
	}, [teacherId, studentId])

	useEffect(() => {
		const comparisonDate = getComparisonDate(filter)
		getStudentClassAssignments({
			variables: {
				classId,
				studentId,
				fromDate,
				toDate,
				comparisonFromDate: comparisonDate.fromDate,
				comparisonToDate: comparisonDate.toDate
			}
		})
		getWurrliesCount({
			variables: {
				studentId,
				classId,
				W1From: comparisonDate.fromDate,
				W1To: comparisonDate.toDate,
				W2From: fromDate,
				W2To: toDate
			}
		})
	}, [fromDate, toDate])

	useEffect(() => {
		if (!classLoading) {
			const { fromDate, toDate, graf } = getComparisonMoment(filter)
			setFromDate(fromDate)
			setToDate(toDate)
			setGraf(graf)
		}
	}, [filter, classLoading, classId])

	useEffect(() => {
		if (!wurrliesLoading && wurrliesCount) {
			const totalWurrlies = wurrliesCount.recordingSessions?.aggregate?.count ?? 0
			const lastWeekWurrlies = wurrliesCount.recordingSessionsLastWeek.aggregate?.count ?? 0
			const percentage = getWurrliesPercentage(totalWurrlies, lastWeekWurrlies)
			setSinceLastWeekWurrlies(percentage)
			setRecordingSessions(totalWurrlies)
		}
	}, [wurrliesCount])

	useEffect(() => {
		if (graf && fromDate && toDate) {
			const labelsResult = buildLabels(graf, fromDate, toDate)
			setLabels(labelsResult)
		}
	}, [fromDate])

	useEffect(() => {
		if (studentProductivityData?.student_by_pk?.student_productivity_daily) {
			const { practiceSessionsArr, recordingSessionsArr, videoViewsArr } = buildDataForDataset(
				graf,
				studentProductivityData?.student_by_pk?.student_productivity_daily as Student_Productivity_Daily[],
				labels
			)

			setChartData({
				labels,
				datasets: buildDataset(practiceSessionsArr, recordingSessionsArr, videoViewsArr)
			})
		}
	}, [labels])

	useEffect(() => {
		const completedAssignments: StudentCompletedAssignment[] = []
		if (completedClassAssignents?.class_assignment?.length) {
			completedClassAssignents.class_assignment.forEach((assignment) => {
				completedAssignments.push({
					assignment: assignment.assignment as Assignment,
					totalSubmitted: assignment.totalSubmitted.aggregate?.count || 0,
					totalGraded: assignment.totalGraded.aggregate?.count || 0,
					submission: assignment?.submission[0] as Submission
				})
			})
			setStudentCompletedAssignments(completedAssignments)
		}
	}, [completedClassAssignents])

	return {
		studentId,
		data,
		loading,
		teacherId,
		teacher,
		studentProductivityData,
		chartData,
		sinceLastWeekWurrlies,
		recordingSessions,
		setStudentProductivityVariables,
		setFilter,
		filter,
		classAssignmentsCount,
		classLoading,
		classes,
		setClassId,
		classId,
		studentCompletedAssignments,
		setSortCompletedAssignmentOrder
	}
}

export const [StudentAnalyticsProvider, useStudentAnalyticsContext] = buildGenericContext(useStudentAnalytics)
