import { SetStateAction, useEffect, useState } from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import MomentUtils from '@date-io/moment'
import { Box, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import ClassicEditor from 'capicua-wurrly-refactor-ckeditor5'
import { useFormik } from 'formik'
import DateInput from 'src/components/Inputs/DateInput'
import TimeInput from 'src/components/Inputs/TimeInput'
import { Class } from 'src/graphql/autogenerate/schemas'
import {
	AssignmentFormDataType,
	StepFormProps
} from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/AddAssingment.types'
import FormItem from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/components/FormItem'
import { SelectClass } from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/components/SelectClass/SelectClass'
import SelectGradingSystem from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/components/SelectGradingSystem'
import TextInput from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/components/TextInput'
import { mediaEmbed } from 'src/scenes/Teacher/scenes/6-Lessons/utils/mediaEmbed'
import { getCyKey } from 'src/utils'
import { Loader, UploadAdapterCKEditor } from 'src/utils/uploadAdapterCKEditor'

import { useStyles } from './styles'
import { MAX_INSTRUCTIONS_LENGTH, MAX_NAME_LENGHT, stepOneInitialValues, stepOneValidationSchema } from './utils'

export const StepOneForm = ({ saveData, assignmentData, setStepIsValid, handleInitialData }: StepFormProps) => {
	const classes = useStyles()

	const [ckeditorKey, setCkeditorKey] = useState('')
	const [grading_system_id, setGrading_system_id] = useState<SetStateAction<number>>()

	const formik = useFormik({
		initialValues: stepOneInitialValues,
		validationSchema: stepOneValidationSchema,
		validateOnMount: false,
		validateOnBlur: true,
		onSubmit: () => {}
	})

	const touchClasses = () => formik.setFieldTouched('classes')

	const handleDate = (value: unknown) => formik.setFieldValue('due_date', value)
	const handleTime = (value: MaterialUiPickersDate) => formik.setFieldValue('due_time', value?.toDate())
	const handleGradingSystem = (value: number) => formik.setFieldValue('grading_system', value)
	const handleClasses = (values: Class[]) => {
		setGrading_system_id(values[0].grading_system?.grading_system_id)
		formik.setFieldValue('classes', values)
	}

	const setReceivedDataToFormik = (data: AssignmentFormDataType) => {
		const { classes, name, instructions, due_date, due_time, grading_system } = data
		const initial = stepOneInitialValues
		formik.setValues({
			classes: classes ?? initial.classes,
			name: name ?? initial.name,
			instructions: instructions ?? initial.instructions,
			due_date: due_date ?? initial.due_date,
			due_time: due_time ?? initial.due_time,
			grading_system: grading_system ?? initial.grading_system,
			instructionsLen: instructions?.length ?? initial.instructionsLen
		})
	}

	const getClassNameWrapper = (val: number, limit: number) => {
		return classes[`text${getClassName(val, limit)}` as keyof typeof classes]
	}

	const getClassName = (value: number, limit: number) => {
		if (value === 0) return 'Default'
		if (value <= limit) return 'Success'
		else return 'Error'
	}

	// Persist data between steps
	useEffect(() => {
		setReceivedDataToFormik(assignmentData)
	}, [])

	useEffect(() => {
		if (grading_system_id) {
			formik.values.grading_system = Number(grading_system_id)
		}
	}, [grading_system_id])

	// Use received initial data
	useEffect(() => {
		if (handleInitialData?.initialData && !handleInitialData.stepHasFilledInitialData) {
			handleInitialData.setInitialDataUsed()
			setReceivedDataToFormik(handleInitialData.initialData)
			setCkeditorKey(handleInitialData.initialData.instructions ?? '')
		}
	}, [handleInitialData])

	// Save data when form changes
	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	// Validate data
	useEffect(() => {
		const hasError = Object.values(formik.errors).some((err) => !!err.length)
		setStepIsValid(!hasError)
	}, [formik.errors])

	return (
		<Box>
			<FormItem
				title="Select a Class*"
				content={
					<SelectClass
						error={!!(formik.errors.classes && formik.touched.classes)}
						setSelectedClasses={handleClasses}
						selectedClasses={formik.values.classes}
						boxProps={{
							onFocus: touchClasses
						}}
					/>
				}
			/>
			<FormItem
				title="Assignment Name*"
				extraInfo={`${formik.values.name.length} / ${MAX_NAME_LENGHT}`}
				content={
					<TextInput
						data-cy={getCyKey(StepOneForm, 'assignmentName')}
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						error={!!(formik.errors.name && formik.touched.name)}
						onBlur={formik.handleBlur}
						helperText={!!(formik.errors.name && formik.touched.name) ? formik.errors.name : undefined}
						placeholder="Type in something here"
					/>
				}
			/>

			<Box marginTop="40px">
				<Box display="flex" justifyContent="space-between">
					<Typography className={classes.fieldTitle}>Instructions*</Typography>
					<Box display="flex" alignItems="center">
						<Typography
							className={getClassNameWrapper(formik.values.instructionsLen, MAX_INSTRUCTIONS_LENGTH)}
							color="textSecondary">
							{`${formik.values.instructionsLen}/${MAX_INSTRUCTIONS_LENGTH}`}
						</Typography>
					</Box>
				</Box>

				<CKEditor
					key={ckeditorKey}
					editor={ClassicEditor}
					data={formik.values.instructions}
					config={{
						placeholder:
							'What content or experience will you share to engage your students and start a conversation?',
						wordCount: {
							container: window.document.createElement('div'),
							onUpdate: (stats: { [key: string]: string }) => {
								formik.setFieldValue('instructionsLen', stats.characters)
							}
						},
						mediaEmbed
					}}
					onReady={(editor) => {
						editor.plugins.get('FileRepository').createUploadAdapter = (loader: Loader) => {
							return new UploadAdapterCKEditor(loader)
						}
						editor?.setData(formik.values.instructions)
					}}
					onChange={(_: unknown, editor: { getData: () => string }) => {
						const data = editor.getData()
						formik.setFieldValue('instructions', `${data}`)
					}}
				/>
			</Box>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<FormItem
					title="Due Date*"
					content={
						<DateInput
							data-cy={getCyKey(StepOneForm, 'dueDate')}
							name="due_date"
							value={formik.values.due_date ?? null}
							onChange={handleDate}
							minDate={new Date()}
						/>
					}
				/>
				<FormItem
					title="Select a Time*"
					content={
						<TimeInput
							data-cy={getCyKey(StepOneForm, 'timeInput')}
							name="due_time"
							value={formik.values.due_time ?? null}
							onChange={handleTime}
						/>
					}
				/>
			</MuiPickersUtilsProvider>
			<FormItem
				title="Grading System*"
				content={
					<SelectGradingSystem
						selected={formik.values.grading_system}
						setSelected={handleGradingSystem}
					/>
				}
			/>
		</Box>
	)
}
