import WaveformData from 'waveform-data'

type getAudioBufferProps = {
	/** The audio/video file to be loaded */
	fileSrc: string
	/** In seconds */
	videoLength: number
}

type WaveformDataType = {
	min: number[]
	max: number[]
}

/**
 * These values are used for the OfflineAudioContext
 * https://developer.mozilla.org/en-US/docs/Web/API/OfflineAudioContext/OfflineAudioContext
 */
const numberOfChannels = 2
const sampleRate = 44100

/**
 * This value is used for the WaveformData constructor
 * https://www.npmjs.com/package/waveform-data
 */
const scale = 256

export const getAudioBuffer = async ({ fileSrc, videoLength }: getAudioBufferProps) => {
	const blob = await fetch(fileSrc)
		.then((result) => result.blob())
		.catch(() => null)

	if (!blob) throw new Error('Failed to fetch video blob')

	const audioContext = new OfflineAudioContext({
		numberOfChannels,
		length: sampleRate * videoLength,
		sampleRate
	})
	const soundSource = audioContext.createBufferSource()
	const reader = new FileReader()
	reader.readAsArrayBuffer(blob)

	const errorTimeout = setTimeout(() => {
		throw new Error('Time out exceeded ')
	}, 5000)

	const readerLoadHandler = new Promise<AudioBuffer>((res) => {
		const handleReaderLoad = async () => {
			const fileAsBuffer = reader.result
			if (!fileAsBuffer || typeof fileAsBuffer === 'string') return

			const decodedAudioData = await audioContext.decodeAudioData(fileAsBuffer)
			soundSource.buffer = decodedAudioData
			soundSource.connect(audioContext.destination)
			soundSource.start()
			const audioBuffer = await audioContext.startRendering()
			clearTimeout(errorTimeout)
			res(audioBuffer)

			return audioBuffer
		}
		reader.onload = handleReaderLoad
	})

	const audioBuffer = await readerLoadHandler

	return audioBuffer
}

export const getAudioWaveform = async ({ fileSrc, videoLength }: getAudioBufferProps) => {
	let audioBuffer: AudioBuffer
	try {
		audioBuffer = await getAudioBuffer({ fileSrc, videoLength })
	} catch {
		throw new Error("Couldn't get audio buffer")
	}

	const options = {
		audio_buffer: audioBuffer,
		scale
	}
	const waveformAudioPromise = new Promise<WaveformDataType>((res, rej) => {
		WaveformData.createFromAudio(options, (err, waveform) => {
			if (err) rej(err.message)

			const channel = waveform.channel(0)

			return res({ min: channel.min_array(), max: channel.max_array() })
		})
	})

	const waveformAudio = await waveformAudioPromise

	return waveformAudio
}
