import { useEffect, useState } from 'react'

import { SelectedEventType } from 'src/@types'
import { GetLessonsDocument, useGetLessonsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import {
	MAX_MY_FILES_ITEMS,
	RefetchProductsEnum,
	SortOrder,
	getExistingCache,
	orderBySortOrder,
	refetchCache
} from 'src/utils'

export const useLessonUploads = () => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [totalLessons, setTotalLessons] = useState(0)
	const [lessons, setLessons] = useState<Lesson_Plan[]>([])
	const [sort, setSort] = useState(SortOrder.Up)
	const [seeMoreEnabled, setSeeMoreEnabled] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [isRefetch, setIsRefetch] = useState(false)
	const limit = MAX_MY_FILES_ITEMS

	const variables = {
		teacherId,
		limit,
		order_by:
			sort === SortOrder.Recent ? { created_at: orderBySortOrder[sort] } : { title: orderBySortOrder[sort] }
	}

	const [getLessons, { data, loading, refetch }] = useGetLessonsLazyQuery()

	const handleSort = (event: SelectedEventType) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const seeMore = () => {
		setIsFetching(true)
	}

	useEffect(() => {
		setSeeMoreEnabled(totalLessons > lessons.length)
	}, [lessons])

	useEffect(() => {
		if (isRefetch) {
			const existingCache = getExistingCache({ variables, query: GetLessonsDocument })
			setLessons(existingCache.lesson_plan as Lesson_Plan[])
			setIsRefetch(false)
		}
	}, [isRefetch])
	useEffect(() => {
		if (!isFetching) return

		const currentLength = lessons.length
		refetch({
			offset: currentLength,
			limit
		}).then((fetchMoreResult) => {
			setIsRefetch(
				refetchCache({
					data: fetchMoreResult.data.lesson_plan as Lesson_Plan[],
					type: RefetchProductsEnum.LessonPlan,
					isFetching,
					variables,
					query: GetLessonsDocument
				}) as boolean
			)

			setIsFetching(false)
		})
	}, [isFetching])
	useEffect(() => {
		setIsFetching(false)
		if (data?.lesson_plan && !isFetching) {
			setIsRefetch(
				refetchCache({
					data: data.lesson_plan as Lesson_Plan[],
					type: RefetchProductsEnum.LessonPlan,
					isFetching,
					variables,
					query: GetLessonsDocument
				}) as boolean
			)
		}
		if (data) {
			setTotalLessons(data?.lesson_plan_aggregate?.aggregate?.count || 0)
		}
	}, [data, loading])

	useEffect(() => {
		getLessons({
			variables
		})
	}, [sort])

	return {
		totalLessons,
		lessons,
		sort,
		handleSort,
		seeMoreEnabled,
		seeMore,
		loading,
		teacherId
	}
}
