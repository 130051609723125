import { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { Page } from 'src/@types'
import { useGetLessonsCatalogLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan, Lesson_Plan_Aggregate, Lesson_Plan_Bool_Exp } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import {
	buildLessonSearchParams,
	buildQueryVariables,
	buildRouteParameters,
	MAX_LIST_ITEMS,
	SortOrder
} from 'src/utils'

type useLessonsParams = {
	teacherId: number
	catalogId?: number
	catalogItemId?: number
	featured: boolean | undefined
	order: SortOrder
	filters: number[]
	page?: Page
}
export const useCatalogLessons = ({
	teacherId,
	catalogId,
	catalogItemId,
	featured,
	order = SortOrder.Up,
	filters = [],
	page
}: useLessonsParams) => {
	const location = useLocation()

	const state = location.state as Record<string, string>
	const search = state?.search
	const [searchBarTextState, setSearchBarTextState] = useState('')

	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [lessons, setLessons] = useState<Lesson_Plan[]>([])
	const history = useHistory()
	const [sort, setSort] = useState<SortOrder>(order)
	const [count, setCount] = useState<Lesson_Plan_Aggregate>({
		nodes: [],
		aggregate: { count: 0 }
	})
	const [isFetching, setIsFetching] = useState(false)
	const [loading, setLoading] = useState(true)
	const [moreResults, setMoreResults] = useState(true)

	const queryVariables = buildQueryVariables({
		teacherId,
		conditions: [...buildLessonSearchParams(search || '')],
		offset: 0,
		catalogId: catalogId || undefined,
		limit,
		order,
		filters: buildLessonSearchFilters(filters, { catalogId, catalogItemId })
	})
	// get queries
	const [loadLessonList, lessonList] = useGetLessonsCatalogLazyQuery({
		variables: {
			...queryVariables.variables,
			featured: featured ? { _eq: true } : { _is_null: false }
		}
	})

	const [loadSearchLessonList, lessonSearchList] = useGetLessonsCatalogLazyQuery({
		variables: {
			...queryVariables.variables,
			featured: featured ? { _eq: true } : { _is_null: false }
		}
	})

	useEffect(() => {
		if (searchBarTextState === search) return
		if (search) {
			setSearchBarTextState(search)
		}
	}, [search])

	const onSearch = (search: string) => {
		loadSearchLessonList({
			variables: {
				...queryVariables.variables,
				featured: featured ? { _eq: true } : { _is_null: false },
				conditions: [...buildLessonSearchParams(search || '')]
			}
		})
	}

	const onSearchEnter = (searchbarText: string) => {
		if (page && page.path === Pages.SearchLessons.path) {
			loadLessonList({
				variables: {
					...queryVariables.variables,
					featured: featured ? { _eq: true } : { _is_null: false },
					conditions: [...buildLessonSearchParams(searchbarText || '')]
				}
			})

			return
		}
		history.push(buildRouteParameters(Pages.SearchLessons), { search: searchbarText })
	}

	const handleSort = (value: string) => {
		const selectedSort = value as SortOrder
		setSort(selectedSort)
	}

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [lessons])

	useEffect(() => {
		if (lessonList.loading) setLoading(true)
		if (!lessonList.loading && lessonList.data) {
			if (!lessonList?.data?.lesson_plan.length && !lessons?.length) {
				setIsFetching(false)
				setLoading(false)
			}

			setLessons((lessonList.data.lesson_plan || []) as Lesson_Plan[])
			setCount(lessonList?.data?.lesson_plan_aggregate as Lesson_Plan_Aggregate)
		}
	}, [lessonList])

	useEffect(() => {
		loadLessonList()
	}, [catalogId, order, search, featured, loadLessonList])

	useEffect(() => {
		if (!isFetching) return
		if (!lessonList.loading && lessonList.fetchMore) {
			const currentLength = lessons.length
			lessonList
				.fetchMore({
					variables: {
						offset: currentLength,
						limit: MAX_LIST_ITEMS
					}
				})
				.then((fetchMoreResult) => {
					if (fetchMoreResult.data?.lesson_plan.length !== 0) {
						setLimit(currentLength + (fetchMoreResult.data?.lesson_plan.length || 0))
						setMoreResults(true)
					} else {
						setIsFetching(false)
						setMoreResults(false)
					}
				})
		}
	}, [isFetching])

	return {
		lessons,
		isFetching,
		moreResults,
		loading,
		count,
		onSearchEnter,
		sort,
		handleSort,
		lessonSearchList,
		onSearch,
		searchBarTextState
	}
}

/**
 *
 * @param filtersArray catalog_item_ids to filter
 * @param catalogId if it is the only filter, it will filter by just catalog_id which is the general catalog like "Instruments"
 * @param catalogItemId if it is included it will filter by catalog_item_id which is a specific catalog like "Guitar"
 * @returns Lesson_Plan_Bool_Exp to be used in the query
 */
export const buildLessonSearchFilters = (
	filtersArray: number[],
	{ catalogId, catalogItemId }: { catalogId?: number; catalogItemId?: number }
) => {
	const filters = filtersArray.map<Lesson_Plan_Bool_Exp>((catalogItemId) => ({
		lesson_plan_catalog_item: { catalog_item_id: { _eq: catalogItemId } }
	}))
	// if !catalogItemId, it means that we are filtering by catalog_id
	if (catalogId && !catalogItemId) {
		filters.push({
			_or: [
				{ lesson_plan_catalog_item: { catalog_item: { catalog_id: { _eq: catalogId } } } },
				{
					sequence_lesson_plan: {
						sequence: {
							sequence_topic_sequences: {
								sequence_topic: {
									sequence_topic_catalog_items: {
										catalog_item: { catalog_id: { _eq: catalogId } }
									}
								}
							}
						}
					}
				}
			]
		})
		// if both filter by catalog_item_id
	} else if (catalogId && catalogItemId) {
		filters.push({
			_or: [
				{ lesson_plan_catalog_item: { catalog_item: { catalog_item_id: { _eq: catalogItemId } } } },
				{
					sequence_lesson_plan: {
						sequence: {
							sequence_topic_sequences: {
								sequence_topic: {
									sequence_topic_catalog_items: {
										catalog_item: { catalog_item_id: { _eq: catalogItemId } }
									}
								}
							}
						}
					}
				}
			]
		})
	}

	return filters
}
