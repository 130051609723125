import { createStyles, makeStyles } from '@material-ui/core'

export const useCarouselOfAnyStyles = makeStyles(() =>
	createStyles({
		centerContent: {
			height: '100%',
			'& .BrainhubCarousel__container': {
				height: '100%'
			},
			'& .BrainhubCarousel': {
				height: '100%'
			}
		},
		centerSlide: {
			display: 'flex',
			justifyContent: 'space-around'
		},
		arrowsSecondary: {
			width: '20px',
			height: '20px',
			borderColor: 'black',
			backgroundColor: 'transparent',
			transform: 'rotate(45deg) translate3D(-50%,-50%,0)',
			borderStyle: 'solid',
			cursor: 'pointer',
			position: 'absolute',
			top: '50%',
			zIndex: 10,
			'&:hover': {
				borderColor: 'rgba(0, 0, 0, 0.5)'
			}
		},
		leftArrow: {
			borderWidth: ' 0 0 2px 2px',
			left: 0
		},
		rightArrow: {
			borderWidth: ' 2px 2px 0 0',
			right: 0
		}
	})
)

export default useCarouselOfAnyStyles
