import { useEffect, useMemo, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import moment from 'moment'
import { useHistory } from 'react-router'
import { PagesProps } from 'src/@types'
import { BigBaseButton, InfoDialog, MainTitle, Stepper, Datetime } from 'src/components'
import {
	useAddSubmissionAssetMutation,
	useDeleteSubmissionAssetsMutation,
	useGetAssignmentBySubmissionIdQuery
} from 'src/graphql/autogenerate/hooks'
import { Asset, Assignment, Assignment_Tip, Song } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { FormAsset } from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/AddAssingment.types'
import { buildBreadCrumbs, buildRouteParameters, slugToName, formatAMPM, BLANK_SONG_TITLE } from 'src/utils'

import { getCyKey } from '../../../../../../utils/utils'
import { StepFourForm } from './components/StepFourForm/StepFourForm'
import { StepOneForm } from './components/StepOneForm/StepOneForm'
import StepThreeForm from './components/StepThreeForm/StepThreeForm'
import { StepTwoForm } from './components/StepTwoForm/StepTwoForm'
import { UploadAssetsStudent } from './components/UploadAssetsStudent/UploadAssetsStudent'
import { OverridePageTitle } from './types'
import { useViewAssignmentsStyles } from './ViewAssignments.styles'

const formatTime = (timeString: string) => {
	// Set Date
	const date = new Date()

	// Set Time
	if (timeString) {
		const [hour, minute] = timeString.split(':')

		date.setHours(+hour, +minute, 0, 0)
	}
	const time = formatAMPM(date)

	return { time }
}

export const ViewAssignment = ({ page }: PagesProps) => {
	const history = useHistory()
	const styles = useViewAssignmentsStyles()
	const { submissionId } = useWurrlyParams<typeof StudentPages.AssignmentDetails.params[number]>()
	const { data, refetch } = useGetAssignmentBySubmissionIdQuery({ variables: { submissionId } })
	const [currentStep, setCurrentStep] = useState(1)
	const [addSubmissionAsset] = useAddSubmissionAssetMutation()
	const [deleteSubmission] = useDeleteSubmissionAssetsMutation()
	const [onCancel, setOnCancel] = useState(false)
	const [title, setTitle] = useState('')
	const [dueDate, setDueDate] = useState('')
	const [dueTime, setDueTime] = useState('')
	const [instructions, setInstructions] = useState('')
	const [assignmentTips, setAssignmentTips] = useState<Assignment_Tip[]>([])
	const [assignmentAssets, setAssignmentAssets] = useState<Asset[]>([])
	const [songs, setSongs] = useState<Song[]>([])
	const [includeAllSongs, setIncludeAllSongs] = useState(false)
	const [overrideDescription, setOverrideDescription] = useState<OverridePageTitle>()
	const [assignment, setAssignment] = useState<Assignment>()
	const [classId, setClassId] = useState(0)
	const [selectedSongId, setSelectedSongId] = useState<number>(0)

	const { studentData: student } = useLoginContext()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(title || '')
			}
		],
		isStudentPage: true
	})

	const currentAssets = useMemo(() => {
		return data?.submission_by_pk?.submission_assets.map((i) => i.asset) || []
	}, [data])

	const goToNextStep = () => {
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1)
			window.scrollTo(0, 0)
		} else if (currentStep === steps.length - 1 && !includeAllSongs) {
			goToAssignmentStudio(selectedSongId)
		}
	}

	const updateExternalFile = async (asset: FormAsset) => {
		const submissionId = data?.submission_by_pk?.submission_id as number

		await addSubmissionAsset({
			variables: {
				newAsset: {
					submission_id: submissionId,
					asset: {
						data: {
							name: asset.name,
							resource_path: asset.resource_path
						}
					}
				}
			}
		})

		await refetch()
	}

	const onDeleteAsset = async (assetId: number) => {
		await deleteSubmission({
			variables: {
				asset_id: assetId
			}
		})
		await refetch()
	}

	const goToAssignmentStudio = (songId: number) => {
		const song = songs.find((song) => song.song_id === songId)
		const isBlankTrack = song?.title.toLowerCase() === BLANK_SONG_TITLE

		if (!isBlankTrack) {
			const link = buildRouteParameters(StudentPages.AssignmentStudentStudio, {
				classId,
				songId,
				submissionId
			})
			localStorage.setItem('link', link)
			history.push(link)
		} else {
			history.push(
				buildRouteParameters(
					StudentPages.StudentRecord,
					{
						songId: songId ?? 0
					},
					true,
					{ isBlankSong: 'true', classId, submissionId }
				)
			)
		}
	}

	const handleCancelBtn = () => setOnCancel(true)
	const abortCancel = () => setOnCancel(false)

	const cancel = () => {
		setOnCancel(false)
		history.push(buildRouteParameters(StudentPages.Assignments))
	}

	const goBack = () => {
		if (currentStep > 1) {
			setCurrentStep(currentStep - 1)
		}
	}

	useEffect(() => {
		if (data?.submission_by_pk?.class_assignment?.assignment) {
			setAssignment(data.submission_by_pk.class_assignment.assignment as Assignment)
			setClassId(data.submission_by_pk.class_assignment.class_id)
		}
	}, [data])

	useEffect(() => {
		if (assignment) {
			const time = formatTime(assignment.due_time)
			const date = moment(assignment.due_date).format('ddd, DD MMM')

			setTitle(assignment.name ?? '')
			setDueDate(date)
			setDueTime(time.time)
			setInstructions(assignment.instructions ?? '')
			setIncludeAllSongs(!!assignment.include_all_songs)
			if (assignment.assignment_tips) setAssignmentTips(assignment.assignment_tips as Assignment_Tip[])
			if (assignment.assignment_assets)
				setAssignmentAssets(assignment.assignment_assets?.map((i) => i.asset as Asset))
			if (assignment.assignment_songs) setSongs(assignment.assignment_songs.map((i) => i.song as Song))
		}
	}, [assignment])

	const buildSteps = () => {
		const steps = [
			{
				step: 1,
				stepName: 'Progress',
				component: (
					<StepOneForm
						assignmentTips={assignmentTips}
						studentId={student.student_id}
						classId={classId}
					/>
				)
			},
			{
				step: 2,
				stepName: `Progress`,
				component: <StepTwoForm assignmentAssets={assignmentAssets} />
			},
			{
				step: 3,
				stepName: 'Progress',
				component: (
					<StepThreeForm
						songs={songs}
						useAllSongs={includeAllSongs}
						setParentTitle={setOverrideDescription}
						classId={classId}
						selectedSongId={selectedSongId}
						setSelectedSongId={setSelectedSongId}
						goToAssignmentStudio={goToAssignmentStudio}
					/>
				)
			},
			{
				step: 4,
				stepName: `Progress`,
				component: <StepFourForm />
			}
		]

		if (assignment?.allow_external_files) {
			steps.splice(2, 0, {
				step: 4,
				stepName: `Progress`,
				component: (
					<UploadAssetsStudent
						files={currentAssets}
						onAddAsset={updateExternalFile}
						onAssetDelete={onDeleteAsset}
					/>
				)
			})
		}

		// map to override the steps numbers with the new ones
		return steps.map((item, index) => ({
			...item,
			step: index + 1
		}))
	}

	const steps = buildSteps()

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={overrideDescription?.title ?? title}
				description={
					overrideDescription?.description ?? (
						<Box>
							<Datetime text={`${dueDate} - ${dueTime}`} color="secondary" />
							<Typography className={styles.instructionsTitle}>
								<b>Read Instructions:</b>
							</Typography>
							<Typography className={styles.instructions}>{parse(instructions)}</Typography>
						</Box>
					)
				}
			/>
			<Box className={styles.stepper}>
				<Stepper
					stepDescription={steps[currentStep - 1]?.stepName ?? ''}
					currentStep={currentStep}
					totalSteps={steps.length}
					stepBoxProps={{ mt: 6 }}
					stepDescriptionClassName={'text'}
				/>
			</Box>
			{steps[currentStep - 1]?.component ?? null}

			<Box className={styles.buttonDisabled}>
				<Box>
					<BigBaseButton
						className={styles.standardButton}
						variant="contained"
						color="default"
						onClick={handleCancelBtn}>
						<Typography variant="caption">
							<strong>Cancel</strong>
						</Typography>
					</BigBaseButton>
					{currentStep !== 1 && (
						<BigBaseButton
							className={styles.backButton}
							variant="contained"
							color="default"
							onClick={goBack}>
							<Typography variant="caption">
								<strong>Back</strong>
							</Typography>
						</BigBaseButton>
					)}
				</Box>
				{(currentStep < steps.length - 1 || !includeAllSongs) && (
					<BigBaseButton
						className={styles.backButton}
						color="secondary"
						onClick={goToNextStep}
						disabled={currentStep === steps.length - 1 && !selectedSongId}>
						<Typography data-cy={getCyKey(ViewAssignment, 'nextButton')} variant="caption">
							<strong>Next</strong>
						</Typography>
					</BigBaseButton>
				)}
			</Box>

			<InfoDialog
				open={onCancel}
				onClose={abortCancel}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={cancel}
				confirmLabel="No, Cancel"
				onConfirm={abortCancel}
				icon="!"
			/>
		</Box>
	)
}
