import { Box, Typography } from '@material-ui/core'
import { WurrlyYellowLogo } from 'src/assets/icons/CustomIcons'

type DialogHeaderProps = {
	title?: string
	style?: React.CSSProperties
}

export const DialogHeader = ({ title, ...props }: DialogHeaderProps) => (
	<Box marginBottom="60px" {...props}>
		<Box display="flex" justifyContent="center" pb="10px">
			<WurrlyYellowLogo />
		</Box>
		{title && (
			<Typography align="center">
				<b>{title}</b>
			</Typography>
		)}
	</Box>
)
