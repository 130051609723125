import { Fragment, useState } from 'react'

import { Box, Button, CircularProgress, IconButton, InputBase, Paper, Typography } from '@material-ui/core'
import { fade, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import { Lesson_Plan, Song, Tip } from 'src/graphql/autogenerate/schemas'
import { getCyKey, TypeEnum } from 'src/utils'

import { ListLessons } from '../ListLessons'
import { ListSongs } from '../ListSongs'
import { ListVideos } from '../ListVideos'
import { useSearch } from './hooks'

const useStyles = makeStyles((theme: Theme) => ({
	boxInput: {
		marginBottom: theme.spacing(4),
		backgroundColor: fade(theme.palette.text.secondary, 0.1),
		border: `1px solid ${fade(theme.palette.text.secondary, 0)}`,
		'& .MuiPaper-root :focus': {
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`
		},
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	boxInputFocus: {
		marginBottom: theme.spacing(4),
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`,
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	listSongs: {
		overflow: 'hidden',
		position: 'absolute',
		top: 64,
		left: 0,
		right: 0,
		zIndex: 11,
		padding: theme.spacing(1),
		'& .MuiBox-root': {
			'&::-webkit-scrollbar': {
				width: theme.spacing(1),
				height: theme.spacing(1)
				// display: 'none'
			},
			'&:hover::-webkit-scrollbar': {
				display: 'block'
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: fade(theme.palette.text.secondary, 0.2),
				borderRadius: theme.spacing(1),
				'&:hover': {
					backgroundColor: fade(theme.palette.text.secondary, 0.25)
				},
				'&:active': {
					backgroundColor: fade(theme.palette.text.secondary, 0.3)
				}
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: fade(theme.palette.text.secondary, 0.1),
				borderRadius: theme.spacing(1)
			}
		}
	}
}))

const searchText = {
	song: {
		placeholder: 'Find songs by Artist or Song Title',
		suggestText: ' - Search for Song'
	},
	tip: {
		placeholder: 'Find Video by Title, Subject or Keyword',
		suggestText: ' - Search for Videos'
	},
	lesson_plan: {
		placeholder: 'Find Lessons by Title, Song, Standard, or Keyword',
		suggestText: ' - Search for Lessons'
	}
}

type SearchProps = {
	teacherId: number
	studentId?: number
	onChange: (value: string) => void
	textSearch?: string
	type: TypeEnum
	classId?: number
	overrideSongClick?: (classId: number, songId: number, isBlankSong: boolean) => void
}

export const Search = ({
	teacherId,
	studentId,
	onChange,
	textSearch = '',
	type,
	classId,
	overrideSongClick
}: SearchProps) => {
	const [focus, setFocus] = useState(false)
	const classes = useStyles(focus)
	const theme = useTheme()

	const [
		handleChange,
		handleKeydown,
		handleClean,
		text,
		inputRef,
		showClean,
		showResults,
		loading,
		songs,
		handleSearch
	] = useSearch(teacherId, type, textSearch, onChange, studentId)

	const showList = () => {
		switch (type) {
			case TypeEnum.Song:
				return (
					<ListSongs
						teacherId={teacherId}
						data={songs as Song[]}
						lineDivider
						allTranspositions={false}
						lessonsButton
						studioButton={!!studentId}
						classId={classId}
						overrideOnClick={overrideSongClick}
					/>
				)
			case TypeEnum.Video:
				return (
					<ListVideos
						teacherId={teacherId}
						dataCy={getCyKey(Search, 'Suggestions')}
						data={songs as Tip[]}
						lineDivider
					/>
				)
			case TypeEnum.Lesson:
				return <ListLessons teacherId={teacherId} data={songs as Lesson_Plan[]} lineDivider />
			default:
				return null
		}
	}

	const handleFocus = () => setFocus(true)

	const handleBlur = () => setFocus(false)

	return (
		<Box position="relative">
			<Paper component="form" elevation={0} className={focus ? classes.boxInputFocus : classes.boxInput}>
				<IconButton onClick={handleSearch} aria-label="search">
					<SearchIcon />
				</IconButton>
				<InputBase
					data-cy={getCyKey(Search)}
					value={text}
					inputRef={inputRef}
					onChange={handleChange}
					onKeyDown={handleKeydown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					placeholder={searchText[type as keyof typeof searchText].placeholder}
					inputProps={{ 'aria-label': 'search song' }}
				/>
				{showClean && (
					<Fragment>
						<Button
							data-cy={getCyKey(Search, 'Clean')}
							style={{ color: 'rgba(50, 51, 66, 0.5)' }}
							variant="text"
							onClick={handleClean}>
							<HighlightOffIcon />
						</Button>
					</Fragment>
				)}
			</Paper>

			{text && showResults && (
				<Paper
					className={classes.listSongs}
					elevation={3}
					style={songs.length === 0 ? { padding: 0 } : undefined}>
					<Box overflow="auto" maxHeight={378}>
						{songs.length === 0 && (
							<Box
								py={0.5}
								px={1}
								display="flex"
								alignItems="center"
								style={{ backgroundColor: fade(theme.palette.secondary.main, 0.2) }}>
								<IconButton aria-label="search">
									<SearchIcon />
								</IconButton>
								<Typography>
									<span style={{ color: theme.palette.secondary.dark }}>No results found</span>
								</Typography>
								<div style={{ flexGrow: 1 }} />
								{loading && (
									<IconButton aria-label="search" disabled>
										<CircularProgress color="secondary" size={20} />
									</IconButton>
								)}
							</Box>
						)}
						{showList()}
					</Box>
				</Paper>
			)}
		</Box>
	)
}
