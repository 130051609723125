import { useEffect, useState } from 'react'

import { Box, Checkbox, Typography } from '@material-ui/core'
import { FormikProps, useFormik } from 'formik'
import { InputMultipleFile } from 'src/components'
import { Song, Tip } from 'src/graphql/autogenerate/schemas'
import { FileTypeEnum } from 'src/utils'

import { AssignmentFormDataType, FormAsset, StepFormProps } from '../../AddAssingment.types'
import FormItem from '../FormItem'
import { SelectSong } from '../SelectSong/SelectSong'
import { SelectVideo } from '../SelectVideo/SelectVideo'
import { MAX_ASSETS, MAX_SONGS, MAX_VIDEOS, stepTwoInitialValues, stepTwoValidationSchema } from './utils'

export const StepTwoForm = ({ saveData, assignmentData, setStepIsValid, handleInitialData }: StepFormProps) => {
	const formik = useFormik({
		initialValues: stepTwoInitialValues,
		validationSchema: stepTwoValidationSchema,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: () => {}
	})

	const [songsCounter, setSongsCounter] = useState<number>(0)
	const [videosCounter, setVideosCounter] = useState<number>(0)

	const handleSongs = (songs: Song[]) => {
		formik.setFieldValue('songs', songs)
		setSongsCounter(songs.length)
	}
	const handleUseAllSongs = (useAllSongs: boolean) => formik.setFieldValue('include_all_songs', useAllSongs)
	const handleVideos = (videos: Tip[]) => {
		formik.setFieldValue('videos', videos)
		setVideosCounter(videos.length)
	}
	const handleAssets = (assets: FormAsset[]) => {
		formik.setFieldValue('assets', assets)
	}

	const setReceivedDataToFormik = (data: AssignmentFormDataType) => {
		const { songs, include_all_songs, videos, assets } = data
		const initial = stepTwoInitialValues
		formik.setValues({
			songs: songs?.slice(0, 10) ?? initial.songs,
			include_all_songs: include_all_songs ?? initial.include_all_songs,
			videos: videos ?? initial.videos,
			assets: assets ?? initial.assets,
			allow_external_files: false
		})
	}

	// Persist data between steps
	useEffect(() => {
		if (assignmentData) {
			setSongsCounter(assignmentData.songs?.length ?? 0)
			setVideosCounter(assignmentData.videos?.length ?? 0)
		}
		setReceivedDataToFormik(assignmentData)
	}, [])

	// Use received initial data
	useEffect(() => {
		if (handleInitialData?.initialData && !handleInitialData.stepHasFilledInitialData) {
			handleInitialData.setInitialDataUsed()
			setReceivedDataToFormik(handleInitialData.initialData)
		}
	}, [handleInitialData])

	// Save data when form changes
	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	// Validate data
	useEffect(() => {
		const hasError = Object.values(formik.errors).some((err) => !!err.length)
		setStepIsValid(!hasError)
	}, [formik.errors])

	return (
		<Box>
			<FormItem
				title="Add Songs*"
				extraInfo={formik.values.include_all_songs ? '' : `${songsCounter || 0} of ${MAX_SONGS}`}
				content={
					<SelectSong
						formik={formik as unknown as FormikProps<Record<string, string>>}
						selectedSongs={formik.values.songs}
						useAllSongs={formik.values.include_all_songs}
						setSelectedSongs={handleSongs}
						setUseAllSongs={handleUseAllSongs}
						setSongsCounter={setSongsCounter}
						limit={MAX_SONGS}
					/>
				}
			/>
			<FormItem
				title="Add Videos"
				extraInfo={`${videosCounter || 0} of ${MAX_VIDEOS}`}
				content={
					<SelectVideo
						formik={formik as unknown as FormikProps<Record<string, string>>}
						setVideosCounter={setVideosCounter}
						selectedVideos={formik.values.videos}
						setSelectedVideos={handleVideos}
						limit={MAX_VIDEOS}
					/>
				}
			/>
			<FormItem
				title="Add Assets"
				extraInfo={`${formik.values.assets?.length || 0} of ${MAX_ASSETS}`}
				content={
					<InputMultipleFile
						handleAsset={handleAssets}
						advise={'File Type: PDF, JPEG, PNG. Max File size: 10MB.'}
						type={FileTypeEnum.Asset}
						placeholder="Pick files"
						initialAssets={assignmentData.assets ?? undefined}
						maxAssets={MAX_ASSETS}
					/>
				}
			/>

			<Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="start">
				<Checkbox
					onClick={(e) => e.stopPropagation()}
					onChange={(e, checked) => {
						e.stopPropagation()
						formik.setFieldValue('allow_external_files', checked)
					}}
				/>

				<Typography>Accept Uploaded Files (MP3, MP4, WAV, PDF accepted)</Typography>
			</Box>
		</Box>
	)
}
