import { useEffect, useState } from 'react'

import { DocumentNode, useQuery } from '@apollo/client'
import { shouldSortTitleOrDate } from 'src/utils'

import { buildQueryVariables, SortOrder, TypeEnum } from '../../../../utils'

type aggregateResult = {
	aggregate: {
		count: number
	}
}

export const useGetFavorites = <T>(
	queryFavorite: DocumentNode,
	teacherId: number,
	limit: number,
	sort: SortOrder,
	type: TypeEnum
): [T[], boolean, number] => {
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState<T[]>([])
	const [totalCount, setTotalCount] = useState(0)
	const [orderTitle, orderDate] = shouldSortTitleOrDate(sort)

	const queryVariables = buildQueryVariables({
		teacherId,
		limit,
		orderTitle,
		orderDate
	})

	type QueryType = { [key: string]: { [subKey: string]: T }[] }
	const favoriteDataQuery = useQuery<QueryType>(queryFavorite, queryVariables)

	useEffect(() => {
		if (!favoriteDataQuery.loading && favoriteDataQuery.data) {
			const favorites = favoriteDataQuery.data[`teacher_${type}_favorite`].map((fv) => fv[type]) || []
			const aggregateData = favoriteDataQuery.data[
				`teacher_${type}_favorite_aggregate`
			] as unknown as aggregateResult
			setData(favorites)
			setLoading(false)
			setTotalCount(aggregateData.aggregate.count)
		}
	}, [favoriteDataQuery])

	return [data, loading, totalCount]
}
