import { ChartOptions } from 'chart.js'

export const DEFAULT_STACKED_BAR_OPTIONS: ChartOptions<'bar'> = {
	scales: {
		x: {
			stacked: true,
			beginAtZero: true
		},
		y: {
			stacked: true
		}
	},
	plugins: {
		legend: {
			position: 'top',
			align: 'end',
			labels: {
				boxWidth: 15
			}
		}
	}
}
