import { createStyles, makeStyles } from '@material-ui/core'

export const useGradedProgressStyles = makeStyles(() =>
	createStyles({
		assignmentNumber: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%,-50%)',
			textAlign: 'center'
		},
		upperNumber: { padding: '0 10px', fontWeight: 'bold' },
		lowerNumber: { borderTop: '1px solid', padding: '0 10px', fontWeight: 'bold' },
		container: {
			position: 'relative',
			width: 50,
			height: 50,
			borderRadius: '100%'
		},
		graded: {
			color: '#00BA70'
		},
		lightGraded: {
			backgroundColor: 'rgba(0,186,112,0.2)'
		},
		startedGraded: {
			color: '#F3AB34'
		},
		lightStartedGraded: {
			backgroundColor: 'rgba(243,171,52,0.2)'
		},
		notGraded: {
			color: '#7367A3'
		},
		lightNotGraded: {
			backgroundColor: 'rgba(115,103,163,0.2)'
		}
	})
)

export default useGradedProgressStyles
