import { useState } from 'react'

import { useMutation } from '@apollo/client'
import { TipForListItem } from 'src/components/ListItems/ListVideoItem/ListVideoItem'
import { useLoginContext } from 'src/hooks/useLogin'
import { mutationDeleteFavorite, mutationInsertFavorite } from 'src/scenes/Teacher/queries'
import { TypeEnum } from 'src/utils'
export const useVideoForVideoPlayer = () => {
	const { teacherData: teacher } = useLoginContext()

	const [selectedVideo, setSelectedVideo] = useState<TipForListItem>()
	const [saveFavoriteVideo, { loading: loadingAddVideo }] = useMutation(mutationInsertFavorite(TypeEnum.Video))
	const [deleteFavoriteVideo, { loading: loadingDelVideo }] = useMutation(mutationDeleteFavorite(TypeEnum.Video))

	const handleFavoriteVideo = (isVideoFavorite: boolean) => {
		if (selectedVideo) {
			if (isVideoFavorite) removeFavoriteVideo()
			else addFavoriteVideo()
		}
	}

	const removeFavoriteVideo = async () => {
		try {
			await deleteFavoriteVideo({
				variables: { teacherId: teacher.teacher_id, itemId: selectedVideo?.tip_id ?? 0 },
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_songs_favorites'
					})
				}
			})
			setSelectedVideo((prev) => {
				if (!prev) return undefined

				return { ...prev, is_favorite: false }
			})
		} catch (error) {
			console.error('Could not remove video from favorite', error)
		}
	}
	const addFavoriteVideo = async () => {
		try {
			await saveFavoriteVideo({
				variables: { teacherId: teacher.teacher_id, itemId: selectedVideo?.tip_id ?? 0 },
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_tip_favorite'
					})
				}
			})
			setSelectedVideo((prev) => {
				if (!prev) return undefined

				return { ...prev, is_favorite: true }
			})
		} catch (error) {
			console.error('Could not favorite video', error)
		}
	}

	return {
		selectedVideo,
		setSelectedVideo,
		handleFavoriteVideo,
		loadingVideoFavorite: loadingAddVideo || loadingDelVideo,
		isVideoFavorite: !!selectedVideo?.is_favorite
	}
}
