import { useState, useEffect } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import { useGetSongCatalogLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Catalog } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useGetSongs } from 'src/scenes/Student/scenes/3-MusicCatalog/scenes/CatalogSongs/hooks'
import { SortOrder } from 'src/utils/enums'

const useWholeCatalog = () => {
	const { studentData } = useLoginContext()
	const [getSongCatalog, catalogs] = useGetSongCatalogLazyQuery()

	const [searchString, setSearchString] = useState('')
	const [showCatalog, setShowCatalog] = useState(true)
	const [chords, setChords] = useState<string[]>([])
	const [selectedCatalog, setSelectedCatalog] = useState<Catalog>()
	const [sortResults, setSortResults] = useState<SortOrder>(SortOrder.Up)
	const [showTransposed, setShowTransposed] = useState(false)
	const [selectedScaleId, setSelectedScaleId] = useState<number>(0)
	const [withVocalGuide, setWithVocalGuide] = useState(false)
	const [withChordGuide, setWithChordGuide] = useState(false)
	const [selectedFilters, setSelectedFilters] = useState<number[]>([])
	const [scale, setScale] = useState<number>(0)

	const [songs, isFetching, moreResults, loading, count, setIsFetching] = useGetSongs(
		studentData.student_id,
		selectedCatalog?.catalog_id ?? 0,
		searchString,
		sortResults,
		chords,
		showTransposed,
		selectedScaleId,
		withVocalGuide,
		withChordGuide,
		selectedFilters
	)

	const toggleVocalGuide = () => setWithVocalGuide((prev) => !prev)
	const toggleChordGuide = () => setWithChordGuide((prev) => !prev)
	const toggleShowTransposed = () => setShowTransposed((prev) => !prev)
	const fetchMore = () => setIsFetching(true)

	// Hide the catalogs after selecting one
	useEffect(() => {
		setShowCatalog(!selectedCatalog)
	}, [selectedCatalog])

	useEffect(() => {
		if (searchString.length) setShowCatalog(false)
	}, [searchString])

	useEffect(() => {
		getSongCatalog()
	}, [])

	return {
		isLoading: loading || isFetching,
		searchString,
		setSearchString,
		showCatalog,
		setShowCatalog,
		chords,
		setChords,
		catalogs,
		selectedCatalog,
		setSelectedCatalog,
		sortResults,
		setSortResults,
		showTransposed,
		setShowTransposed,
		selectedScaleId,
		setSelectedScaleId,
		withVocalGuide,
		setWithVocalGuide,
		withChordGuide,
		setWithChordGuide,
		selectedFilters,
		setSelectedFilters,
		songs,
		moreResults,
		count,
		setIsFetching,
		toggleChordGuide,
		toggleVocalGuide,
		scale,
		setScale,
		toggleShowTransposed,
		fetchMore
	}
}

export const [WholeCatalogProvider, useWholeCatalogContext] = buildGenericContext(useWholeCatalog)
