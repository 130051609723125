import { useState } from 'react'

import { Box, Card, Popover } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useMeasure } from 'react-use'
import { SubmissionCard } from 'src/components/Cards/SubmissionCard/SubmissionCard'
import { Grading_System, Submission } from 'src/graphql/autogenerate/schemas'
import { SortOrder } from 'src/utils'

import { StudentsFilterDropDown } from './components/StudentsFilterDropDown'
import useStyles from './StudentsFilter.styles'

type StudentsFilterProps = {
	currentSubmission: Submission
	submissions: Submission[]
	sortOrder: SortOrder
	assignmentDueDateString: string
	setSortOrder: (sortOrder: SortOrder) => void
	onClick?: (submission: Submission) => void
	gradingSystem: Pick<Grading_System, 'label' | 'values'> | undefined
}

export const StudentsFilter = ({
	currentSubmission,
	submissions,
	sortOrder,
	setSortOrder,
	onClick,
	assignmentDueDateString,
	gradingSystem
}: StudentsFilterProps) => {
	const styles = useStyles()
	const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null)
	const [ref, { width }] = useMeasure()

	const openPopover = (event: React.MouseEvent<HTMLDivElement>) => setAnchorElement(event.currentTarget)
	const closePopover = () => setAnchorElement(null)

	const open = Boolean(anchorElement)

	return (
		<Box className={styles.container}>
			<Card className={styles.card} onClick={openPopover} ref={ref}>
				<SubmissionCard
					avatarUrl={currentSubmission.submission_wurrlies?.[0]?.wurrly?.student?.image_path ?? ''}
					submitterName={currentSubmission.name ?? ''}
					submissionDate={currentSubmission.date_submitted ?? ''}
					grade={currentSubmission.grade ?? ''}
					dueDateString={assignmentDueDateString}
					gradingSystem={gradingSystem}
					teacherComment={currentSubmission.teacher_comment as string}
				/>
				<ArrowDropDownIcon fontSize="small" className={styles.dropDownIcon} />
			</Card>
			<Popover
				className={styles.popover}
				open={open}
				anchorEl={anchorElement}
				onClose={closePopover}
				PaperProps={{
					className: styles.popoverPaper
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}>
				<StudentsFilterDropDown
					parentCardWidth={width}
					submissions={submissions}
					sortOrder={sortOrder}
					setSortOrder={setSortOrder}
					onClick={onClick}
					assignmentDueDateString={assignmentDueDateString}
					gradingSystem={gradingSystem}
				/>
			</Popover>
		</Box>
	)
}
