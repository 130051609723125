import { Box, Checkbox } from '@material-ui/core'

import { BigBaseButton } from '../../..'
import { UncheckedIcon, CheckedIcon } from '../../../../assets/icons'
import { useStudioRecordContext } from '../useStudioRecord'
import { useTooltipStyles } from './styleTooltip'

export const Tooltip = () => {
	const styles = useTooltipStyles()
	const { saveSetting, toggleDontAskAgain, isDontAskAgainChecked } = useStudioRecordContext()

	return (
		<Box className={styles.box} style={{ zIndex: 10 }}>
			<Box className={styles.polygon} />
			<Box display="flex" flexDirection="column">
				<Box fontSize="16px" ml="5px">
					{`Hit record then play and get creative! You can hit re-do as many times as you like, and save
					only the performances you want to save. When you are done, you can share with the class, the
					teacher or just keep it to yourself!`}
				</Box>
				<Box display="flex" justifyContent="space-between" alignItems="center" mt="-9px">
					<Box display="flex" alignItems="center">
						<Checkbox
							checked={isDontAskAgainChecked}
							icon={<UncheckedIcon />}
							checkedIcon={<CheckedIcon />}
							className={styles.checkbox}
							name="Advice"
							onClick={toggleDontAskAgain}
						/>
						<span className={styles.checkboxLabel}>Don't show me again</span>
					</Box>
					<BigBaseButton onClick={saveSetting} className={styles.button} color="secondary">
						Ok
					</BigBaseButton>
				</Box>
			</Box>
		</Box>
	)
}
