import { useEffect, useState } from 'react'

import { cache } from 'src/apollo/state'
import { ProcessingStatusForPlayer } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useGetStudentWurrliesLazyQuery,
	useGetWurrlyProcessingStatusSubscription
} from 'src/graphql/autogenerate/hooks'
import { Privacy_Enum, Wurrly, Wurrly_Processing_Status_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useSearchQuery } from 'src/hooks/useQuery'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { shouldSortTitleOrDate, SortOrder } from 'src/utils'

const useStudentWurrliesDetails = () => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.StudentWurrliesDetails.params[number]>()
	const teacherId = teacher.teacher_id
	const studentId = Number(params.studentId)
	const wurrlyId = Number(params.wurrlyId)
	const privacySettings = [Privacy_Enum.Class, Privacy_Enum.TeacherAndMe]
	const [index, setIndex] = useState<number>()
	const [wurrlyDetails, setWurrlyDetails] = useState<Wurrly>()
	const [hasPrev, setHasPrev] = useState<boolean>()
	const [hasNext, setHasNext] = useState<boolean>()
	const searchQuery = useSearchQuery()

	const [getStudentWurrliesQuery, getWurrliesByClassResult] = useGetStudentWurrliesLazyQuery()
	const { data: wurrlyDetailsData, loading: wurrlyDetailsLoading, refetch } = getWurrliesByClassResult

	const wurrlies = (wurrlyDetailsData?.student_by_pk?.wurrly as Wurrly[]) || []

	const [processingStatus, setProcessingStatus] = useState<ProcessingStatusForPlayer>({
		status: Wurrly_Processing_Status_Enum.Done,
		isLoading: true
	})
	const { data: processingStatusData, loading: processingStatusLoading } =
		useGetWurrlyProcessingStatusSubscription({
			variables: { wurrlyId: wurrlyDetails?.wurrly_id ?? 0 }
		})

	useEffect(() => {
		setProcessingStatus({
			status: processingStatusData?.wurrly_by_pk?.processing_status ?? Wurrly_Processing_Status_Enum.Done,
			isLoading: processingStatusLoading
		})
		if (processingStatusData?.wurrly_by_pk?.processing_status && !processingStatusLoading && wurrlyDetails) {
			cache.evict({
				id: cache.identify(wurrlyDetails),
				fieldName: 'final_resource_path'
			})
		}
	}, [processingStatusData, processingStatusLoading])

	// set sort by query param
	useEffect(() => {
		const querySort = searchQuery.get('sort') as SortOrder
		// const classId = Number(searchQuery.get('classId'))

		if (querySort) {
			const [orderTitle, orderDate] = shouldSortTitleOrDate(querySort)

			// call the query
			getStudentWurrliesQuery({
				variables: { teacherId, studentId, orderTitle, orderDate, privacySettings }
			})
		}
	}, [])

	// select the wurrly when finish the query
	useEffect(() => {
		wurrlies.forEach((wurrly, index) => {
			if (wurrly.wurrly_id === params.wurrlyId) {
				setWurrlyDetails(wurrly)
				setHasPrev(index > 0)
				setHasNext(index < wurrlies.length - 1)
				setIndex(index)
			}
		})
	}, [wurrlies])

	// update wurrly selected by index
	useEffect(() => {
		if (index !== undefined) {
			setWurrlyDetails(wurrlies[index])
			setHasPrev(index > 0)
			setHasNext(index < wurrlies.length - 1)
		}
	}, [index])

	const previousValue = () => {
		if (index !== undefined && hasPrev) {
			setIndex(index - 1)
		}
	}

	const nextValue = () => {
		if (index !== undefined && hasNext) {
			setIndex(index + 1)
		}
	}

	return {
		studentId,
		wurrlyId,
		teacher,
		refetch,

		wurrlyDetails,
		wurrlyDetailsLoading,

		hasPrev,
		hasNext,

		nextValue,
		previousValue,
		processingStatus
	}
}

export const [StudentWurrliesDetailsProvider, useStudentWurrliesDetailsContext] =
	buildGenericContext(useStudentWurrliesDetails)
