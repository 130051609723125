import { useState, useEffect } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CarouselOfAny } from 'src/components'
import { BigBaseButton } from 'src/components/Buttons/BigBaseButton'
import { SelectChallengeClassDialog } from 'src/components/ChallengeDialog/SelectChallengeClassDialog'
import { BaseDialog } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useChallengeSeenMutation, useGetBadgeDataQuery } from 'src/graphql/autogenerate/hooks'
import { GetFinishedChallengesQuery } from 'src/graphql/autogenerate/operations'
import { Challenge, Challenge_Student_Insert_Input, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { StudentPages } from 'src/routes/studentpages'
import { StudentClassDetailsProvider } from 'src/scenes/Student/1-Home/hooks/useClassDetails'
import { buildRouteParameters, buildImagePath, BadgeId } from 'src/utils'

import useStyles from './challengeDialog.styles'

type ChallengePopupType = {
	challengeData: Challenge[] | undefined
	ladingChallenge: boolean
	studentId: number
	disableAsSeen?: boolean
	finishedChallenges: GetFinishedChallengesQuery | undefined
	handleExternalClose?: () => void
}
export const ChallengeDialog = ({
	ladingChallenge,
	challengeData,
	studentId,
	disableAsSeen = false,
	finishedChallenges,
	handleExternalClose
}: ChallengePopupType) => {
	const styles = useStyles()
	const history = useHistory()
	const [activeStep, setActiveStep] = useState<number>(0)
	const [openDialog, setOpenDialog] = useState(false)
	const [isSelectClassOpen, setIsSelectClassOpen] = useState(false)
	const [selectedClass, setSelectedClass] = useState(0)

	const { data: badge } = useGetBadgeDataQuery({ variables: { badgeId: BadgeId.ChallengeChampId } })

	const [seen, {}] = useChallengeSeenMutation()

	useEffect(() => {
		if (challengeData) {
			setOpenDialog(true)
		}
	}, [challengeData])

	const challengeAsSeen = () => {
		if (challengeData) {
			const challengeConditions = [] as Challenge_Student_Insert_Input[]
			challengeData.map((challenge) =>
				challengeConditions.push({
					student_id: studentId,
					seen: true,
					challenge_id: challenge.challenge_id
				})
			)

			seen({
				variables: {
					conditions: challengeConditions
				}
			})
		}
	}

	const handleClose = () => {
		if (!disableAsSeen) challengeAsSeen()
		setOpenDialog(false)
		if (handleExternalClose) handleExternalClose()
	}
	const handleGoCreate = (classId: number) => {
		if (!disableAsSeen) challengeAsSeen()
		if (!classId) return

		setIsSelectClassOpen(false)

		history.push(
			buildRouteParameters(
				StudentPages.ChallengeSong,
				{
					challengeId: challengeData ? challengeData[activeStep]?.challenge_id : 0
				},
				true,
				{
					classId
				}
			)
		)
	}

	const handleGoMyChallenges = () => {
		if (!disableAsSeen) challengeAsSeen()
		history.push({
			pathname: buildRouteParameters(StudentPages.MyWurrlies),
			state: {
				challenge: Wurrly_Type_Enum.Challenge
			}
		})
		handleClose()
	}

	const onNextClick = (nextStep: number) => {
		setActiveStep(nextStep)
	}

	const onPrevClick = (prevStep: number) => {
		setActiveStep(prevStep)
	}

	const finished = challengeData
		? !!finishedChallenges?.wurrly.find((i) => i.challenge_id === challengeData[activeStep].challenge_id)
		: false

	return (
		<>
			{challengeData && (
				<StudentClassDetailsProvider>
					{ladingChallenge && <CircularProgress color="secondary" />}

					<BaseDialog
						dividers={false}
						open={openDialog}
						maxWidth="md"
						PaperProps={{
							style: {
								width: '100%'
							}
						}}
						onClose={handleClose}
						header={
							<Box className={styles.content}>
								<Typography variant="h4" className={styles.title}>
									{challengeData[activeStep]?.title ?? ''}
								</Typography>
								<Typography className={styles.subtitle}>
									{`Complete this challenge and collect ${badge?.badge_by_pk?.points} points and the ${badge?.badge_by_pk?.name} Badge!`}
								</Typography>
							</Box>
						}
						body={
							<Box className={styles.carouselContainter}>
								<CarouselOfAny
									slidesPerPage={1}
									centerSlide
									goToSlide={activeStep}
									onNextClick={onNextClick}
									onPrevClick={onPrevClick}
									useCarouselOnSingle
									data={challengeData.map((challenge, key) => ({
										element: (
											<Box>
												<Box className={styles.container}>
													{challenge.video_path ? (
														<ReactCustomVideoPlayer
															key={key}
															width="575px"
															style={{ maxHeight: 400 }}
															url={challenge?.video_path}
														/>
													) : (
														<img
															src={buildImagePath(challenge?.image_path)}
															className={styles.thumbnail}
														/>
													)}
													<div className={styles.headerBadge}>
														<img
															src={buildImagePath(badge?.badge_by_pk?.icon)}
															className={styles.challengeLogo}
														/>
													</div>
												</Box>

												<Typography className={styles.description}>
													{challenge?.description}
												</Typography>
											</Box>
										),
										linkTo: {}
									}))}
									renderItem={(item) => (item.element ? item.element : <div />)}
								/>
							</Box>
						}
						discardLabel="Close"
						typographyProps={{
							className: styles.closeButton
						}}
						onDiscard={handleClose}
						rightActions={
							<Box justifyContent="flex-end">
								<BigBaseButton
									variant="contained"
									onClick={handleGoMyChallenges}
									color="default"
									className={styles.viewButton}>
									<Typography variant="caption">View My Challenges</Typography>
								</BigBaseButton>
								<BigBaseButton
									variant="contained"
									onClick={() => {
										setIsSelectClassOpen(true)
										setOpenDialog(false)
									}}
									disabled={finished}
									color="secondary">
									<Typography variant="caption">
										{finished ? 'Already Done' : 'Go Create!'}
									</Typography>
								</BigBaseButton>
							</Box>
						}
					/>

					{isSelectClassOpen && (
						<SelectChallengeClassDialog
							selectedClass={selectedClass}
							title="Select a Class"
							setSelectedClass={setSelectedClass}
							onConfirm={handleGoCreate}
							sendParamAsState
						/>
					)}
				</StudentClassDetailsProvider>
			)}
		</>
	)
}
