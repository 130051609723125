import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { LessonCard } from 'src/components/LessonCard/LessonCard'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'

import useStyles from './LessonCardList.styles'

type LessonCardListProps = {
	lessonPlans: Lesson_Plan[] | undefined
	seeMore: boolean
	onSeeMore: () => void
	loading: boolean
	selectedClassId?: number
	addFromFavorites?: boolean
}

export const LessonCardList = ({
	lessonPlans,
	seeMore,
	onSeeMore,
	loading,
	selectedClassId,
	addFromFavorites = true
}: LessonCardListProps) => {
	const styles = useStyles()

	return (
		<Box>
			{!!lessonPlans?.length &&
				lessonPlans
					.filter(Boolean)
					.map((i) => (
						<LessonCard
							key={i.lesson_plan_id}
							lesson={i}
							className={styles.lessonCard}
							selectedClassId={selectedClassId}
						/>
					))}

			{!!lessonPlans?.length && (
				<Box display="flex" alignItems="center" justifyContent="space-between" marginTop={2}>
					{addFromFavorites && (
						<Typography variant="caption" color="secondary">
							<Link className={styles.addFromFavorite} to={Pages.MyFavorites.path}>
								Add From Favorites
							</Link>
						</Typography>
					)}
					<Typography color="textSecondary" className={styles.seeMore} onClick={onSeeMore}>
						{seeMore && <b>See More</b>}
					</Typography>
				</Box>
			)}
			{loading && (
				<Box display="flex" justifyContent="space-around" alignItems="center" className={styles.loader}>
					<CircularProgress color="secondary" size={50} />
				</Box>
			)}
			{!loading && !lessonPlans?.length && (
				<Box my={4} mb={7}>
					<Typography variant="h6" color="textSecondary">
						You haven’t added any Lessons to this Class yet.
					</Typography>
					<Typography color="textSecondary">
						It's easy to add from a saved Lesson or add from your Favorites by selecting one of the
						cards below.
					</Typography>
				</Box>
			)}
		</Box>
	)
}
