import AddIcon from '@material-ui/icons/Add'
import { MainTitle } from 'src/components'
import { AddProductToClassDialog } from 'src/components/Dialogs/AddProductToClassDialog/AddProductToClassDialog'
import { useClassSongsTitle } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassSongs/components/useClassSongsTitle'
import { ProductTitleEnum } from 'src/utils'

export const ClassSongsTitle = () => {
	const {
		breadCrumbs,
		handleCancelSongs,
		handleOpenSelectProductDialog,
		showSongsDialog,
		selectedSongs,
		setShowSongsDialog,
		setSelectedSongs
	} = useClassSongsTitle()

	return (
		<>
			<MainTitle
				breadcrumbs={breadCrumbs}
				title="Class Songs"
				description="These are the Songs that have been added to this Class."
				rightActions={[
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <AddIcon />,
						name: 'Add Songs',
						onClick: handleOpenSelectProductDialog
					}
				]}
			/>
			<AddProductToClassDialog
				onClose={() => {
					setShowSongsDialog(false)
				}}
				selectedData={selectedSongs}
				setSelected={setSelectedSongs}
				open={showSongsDialog}
				handleCancel={handleCancelSongs}
				type={ProductTitleEnum.Song}
			/>
		</>
	)
}
