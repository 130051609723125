import { WurrlyLogoIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { RecordProvider } from 'src/components/Studio/Record/useStudioRecord'
import { VideoRecorderDialog } from 'src/components/Studio/Record/VideoRecorderDialog/VideoRecorderDialog'
import { useStudioBackgroundStyles } from 'src/components/Studio/Setup/styles/studioBackgroundStyles'

export const StudioRecord = () => {
	const styles = useStudioBackgroundStyles()

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={styles.logoPosition} />}
			className={styles.backgroundImage}>
			<RecordProvider>
				<VideoRecorderDialog />
			</RecordProvider>
		</FixedBackground>
	)
}
