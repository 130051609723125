import { useEffect, useState } from 'react'

import { Box, BoxProps } from '@material-ui/core'
import clsx from 'clsx'
import { useMeasure } from 'react-use'
import { ResizableElement } from 'src/components/ResizableElement/ResizableElement'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'

import useStyles from './AppliedStickers.styles'

type AppliedStickersProps = {
	boxProps?: BoxProps
	setCanvasDimensions?: (dimensions: { width: number; height: number }) => void
}

export const AppliedStickers = ({ boxProps, setCanvasDimensions }: AppliedStickersProps) => {
	const {
		selectedStickerFilter,
		removeSticker,
		updateSticker,
		unselectStickers,
		selectSticker,
		videoDimmensions
	} = useVideoSubmitContext()
	const styles = useStyles()
	const [addedListener, setAddedListener] = useState(false)
	const [refForMeasure, { width, height }] = useMeasure()

	useEffect(() => {
		if (!addedListener) {
			window.addEventListener('click', unselectStickers)
			setAddedListener(true)
		}

		return () => {
			window.removeEventListener('click', unselectStickers)
		}
	}, [])

	useEffect(() => {
		if (setCanvasDimensions) setCanvasDimensions({ width, height })
	}, [width, height, setCanvasDimensions])

	return (
		<Box {...{ ...boxProps, ref: refForMeasure }} className={clsx(styles.container, boxProps?.className)}>
			{selectedStickerFilter?.map((i) => (
				<ResizableElement
					key={i.renderId}
					element={i}
					onDelete={removeSticker}
					updateSticker={updateSticker}
					onSelect={selectSticker}
					videoDimensions={videoDimmensions}
				/>
			))}
		</Box>
	)
}
