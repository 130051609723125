import React, { useEffect, useState, Fragment } from 'react'

import { Box, Card, CardActionArea, CardContent, GridList, GridListTile, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import { useHistory } from 'react-router-dom'
import { BaseDialog } from 'src/components'
import { Class } from 'src/graphql/autogenerate/schemas'
import { useStudentClassDetailsContext } from 'src/scenes/Student/1-Home/hooks/useClassDetails'
import { buildImagePath, buildRouteParameters, DEFAULT_MUSIC_BG, generateUniqueId, getCyKey } from 'src/utils'

import { StudentPages } from '../../../../routes/studentpages'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		selected: {
			borderColor: theme.palette.secondary.main,
			backgroundColor: fade(theme.palette.secondary.main, 0.25)
		},
		saved: {
			backgroundColor: fade(theme.palette.grey[700], 0.7)
		},
		nonSelected: {
			backgroundColor: fade(theme.palette.primary.dark, 0.1)
		}
	})
)

type AddToClassProps = {
	// classes where the item belongs to (already saved on db)
	itemName?: string
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	title?: string
	onDone?: () => void
	onSuccess?: {
		shouldClose?: boolean
		onDiscard?: () => void
		confirmLabel?: string
		discardLabel?: string
	}
	footer?: React.ReactNode
	skipIfOnlyOne?: boolean
	sendParamAsState?: boolean
}

type ClassroomType = {
	index?: boolean
	selected?: boolean
} & Class

export const GoToClassDialog = ({
	isOpen,
	setIsOpen,
	title,
	skipIfOnlyOne,
	sendParamAsState = false
}: AddToClassProps) => {
	const style = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const { loadingGrid, classGrid } = useStudentClassDetailsContext()
	const [classes, setClasses] = useState<ClassroomType[]>([])
	const [selectedClass, setSelectedClass] = useState(0)
	const [loading, setLoading] = useState(false)
	const [index, setIndex] = useState(0)
	const goToClassMusicCatalog = (classElement: Class) => {
		if (!sendParamAsState) {
			const newUrl = buildRouteParameters(StudentPages.MusicCatalog, {
				classId: classElement.class_id
			})
			history.push(newUrl)
		} else {
			const newUrl = buildRouteParameters(StudentPages.MusicCatalogDrawer)
			history.push({ pathname: newUrl, state: { classId: classElement.class_id } })
		}
	}

	useEffect(() => {
		if (skipIfOnlyOne && isOpen && classes.length === 1) goToClassMusicCatalog(classes[0])
	}, [classes, isOpen])

	useEffect(() => {
		if (!loadingGrid && classGrid) {
			setClasses(classGrid as ClassroomType[])
			setLoading(false)
		} else setLoading(true)
	}, [classGrid, loadingGrid])

	return (
		<Box>
			<BaseDialog
				PaperProps={{ style: { width: '765px' } }}
				dividers={false}
				onClose={() => {
					setIsOpen(false)
					setSelectedClass(0)
				}}
				open={isOpen}
				header={
					<Fragment>
						<Typography data-cy={getCyKey(GoToClassDialog, `title`)} variant="h5" align="center">
							<b>{title}</b>
						</Typography>
					</Fragment>
				}
				body={
					<Box padding="6px 10px" mt="20px">
						<GridList cellHeight={100} cols={3}>
							{classes.map((iclass, i) => (
								<GridListTile key={`${generateUniqueId()}`} cols={1}>
									<Card
										// data-cy={getCyKey(AddToClassDialog, `CardClass${generateUniqueId()}`)}
										data-cy={getCyKey(GoToClassDialog, `CardClass`)}
										variant={iclass.selected ? 'outlined' : 'elevation'}
										elevation={3}
										style={{
											backgroundImage: `url( ${
												iclass.image_path
													? buildImagePath(iclass.image_path)
													: DEFAULT_MUSIC_BG
											})`,
											backgroundColor: theme.palette.primary.main,
											backgroundRepeat: 'no-repeat',
											backgroundSize: 'cover',
											backgroundPosition: 'center 40%',
											height: '-webkit-fill-available',
											color: theme.palette.common.white,
											margin: 5
										}}
										color="secondary"
										onClick={() => {
											setSelectedClass(iclass.class_id)
											setIndex(i)
										}}>
										<CardActionArea
											style={{ height: '-webkit-fill-available' }}
											className={iclass.selected ? style.selected : style.nonSelected}>
											<CardContent>
												<Box position="absolute" bottom={5} left={5}>
													<Typography gutterBottom variant="body2">
														<b>{iclass.title}</b>
													</Typography>
												</Box>
												<Box
													style={{ display: 'flex' }}
													position="absolute"
													top={5}
													right={8}>
													{classes[i].class_id === selectedClass ? (
														<CheckCircleIcon fontSize="small" color="secondary" />
													) : (
														<RadioButtonUncheckedOutlinedIcon
															fontSize="small"
															color="disabled"
														/>
													)}
												</Box>
											</CardContent>
										</CardActionArea>
									</Card>
								</GridListTile>
							))}
						</GridList>
					</Box>
				}
				onConfirm={() => {
					goToClassMusicCatalog(classGrid[index])
				}}
				onConfirmProps={{ style: { width: '154px', marginRight: '10px' } }}
				isConfirmDisabled={selectedClass === 0 || loading}
				confirmLabel="Next"
				onDiscard={() => {
					if (setIsOpen && !loading) {
						setIsOpen(false)
						setSelectedClass(0)
					}
				}}
				onDiscardProps={{
					disabled: loading,
					style: {
						width: '154px',
						marginLeft: '10px'
					}
				}}
				discardLabel={'Cancel'}
			/>
		</Box>
	)
}
