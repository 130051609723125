import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { ClassWurrliesGrid } from './components/ClassWurrliesGrid'
import { ClassWurrliesTitle } from './components/ClassWurrliesTitle'
import { ClassWurrliesProvider } from './useClassWurrlies'

export const ClassWurrlies = (props: PagesProps) => {
	return (
		<Box>
			<ClassWurrliesProvider>
				<ClassWurrliesTitle {...props} />
				<ClassWurrliesGrid />
			</ClassWurrliesProvider>
		</Box>
	)
}
