import { useState } from 'react'

import { Box, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { ProgressStatusCard } from '../../../../../../../../../../components'
import { Pages } from '../../../../../../../../../../routes/teacherPages'
import { buildRouteParameters } from '../../../../../../../../../../utils'
import { useStudentAnalyticsContext } from '../../useStudentAnalytics'
import { getAssignmentPercentage, getTitle } from '../../utils'
import { StudentCompletedAssignmentsDialog } from '../StudentCompletedAssignmentsDialog/StudentCompletedAssignmentsDialog'
export const StudentAnalyticsProgress = () => {
	const history = useHistory()
	const {
		sinceLastWeekWurrlies,
		recordingSessions,
		studentId,
		classAssignmentsCount,
		filter,
		studentCompletedAssignments,
		setSortCompletedAssignmentOrder
	} = useStudentAnalyticsContext()
	const [studentSubmissionDialog, setStudentSubmissionDialog] = useState(false)
	const currentSubmited = classAssignmentsCount?.class[0]?.submmited?.length || 0

	const previousSubmited = classAssignmentsCount?.class[0]?.submmitedComparison?.length || 0

	const totalClassAsignments = classAssignmentsCount?.class[0]?.total?.aggregate?.count || 0

	const sinceTitle = getTitle(filter)

	const goToStudentWurrlies = () =>
		history.push(
			buildRouteParameters(
				Pages.StudentWurrlies, // Route with paramters
				{ studentId }
			)
		)

	const viewStudentSubmissions = () => setStudentSubmissionDialog(true)

	const closeStudentSubmissionDialog = () => setStudentSubmissionDialog(false)

	return (
		<Box>
			<Grid container spacing={3}>
				<ProgressStatusCard
					title="Completed Assignments"
					body={
						<b>
							{currentSubmited}/{totalClassAsignments}
						</b>
					}
					progressSinceLastX={getAssignmentPercentage(currentSubmited, previousSubmited)}
					sinceLastText={sinceTitle}
					isView
					viewAction={viewStudentSubmissions}
				/>
				<ProgressStatusCard
					title="Student Recording Sessions"
					body={<b>{recordingSessions}</b>}
					progressSinceLastX={sinceLastWeekWurrlies}
					sinceLastText={sinceTitle}
					isView
					viewAction={goToStudentWurrlies}
				/>
			</Grid>
			<StudentCompletedAssignmentsDialog
				open={studentSubmissionDialog}
				closeDialog={closeStudentSubmissionDialog}
				studentName={'Student'}
				classAssignments={studentCompletedAssignments}
				setSort={setSortCompletedAssignmentOrder}
			/>
		</Box>
	)
}
