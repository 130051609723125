import { useHistory } from 'react-router-dom'
import { queryParamsStudentWurrlies } from 'src/components/Grids/WurrliesGrid.types'
import { WurrlyCarousel } from 'src/components/Wurrly/WurrlyCarousel/WurrlyCarousel'
import { useGetWurrlyByClassByStudentQuery } from 'src/graphql/autogenerate/hooks'
import { Privacy_Enum, Order_By, Wurrly } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, SortOrder } from 'src/utils'

import { useStudentDetailContext } from '../useStudentDetail'

export const StudentCarouselWurrlies = () => {
	const history = useHistory()
	const privacySettings = [Privacy_Enum.Class, Privacy_Enum.TeacherAndMe]
	const { studentId, classSelectedId, classId } = useStudentDetailContext()

	const { data } = useGetWurrlyByClassByStudentQuery({
		variables: {
			studentId,
			classId: classSelectedId,
			privacySettings,
			orderDate: Order_By.Desc
		}
	})

	const wurrlies = (data?.wurrly || []) as Wurrly[]

	const handleWurrlyCard = (wurrlyId: number) => {
		// if comes from ClassStudent
		if (classId) {
			history.push(
				buildRouteParameters(
					Pages.ClassStudentWurrliesDetails,
					{
						studentId,
						classId,
						wurrlyId
					},
					true,
					{ sort: SortOrder.Recent } as queryParamsStudentWurrlies
				)
			)
		} else {
			history.push(
				buildRouteParameters(
					Pages.StudentWurrliesDetails,
					{
						studentId,
						wurrlyId
					},
					true,
					{ sort: SortOrder.Recent } as queryParamsStudentWurrlies
				)
			)
		}
	}

	let link
	if (classId) {
		link = buildRouteParameters(Pages.ClassStudentWurrlies, { classId, studentId })
	} else {
		link = buildRouteParameters(Pages.StudentWurrlies, { studentId })
	}

	return (
		<WurrlyCarousel
			title="Student Wurrlies"
			wurrlies={wurrlies}
			viewAllLink={link}
			onWurrlyClick={handleWurrlyCard}
		/>
	)
}
