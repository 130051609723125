import { useState } from 'react'

import { Avatar, Typography } from '@material-ui/core'
import { StateTypeEnum } from 'src/components/listAssignments/ListItemAssignment.types'
import { GREEN_STAR, YELLOW_STAR, PURPLE_STAR } from 'src/utils'

import useStyles from './GradeStar.styles'

export type GradeStarProps = {
	state: string
	grade?: string
}

export const formatGrade = (grade: string) => {
	switch (grade) {
		case 'excellent':
			return 'Exc'
		case 'satisfactory':
			return 'Sat'
		case 'needs improvement':
			return 'Imp'
		case 'unsatisfactory':
			return 'Unsat'
		default:
			return grade as string
	}
}

export const formartESNU = (grade: string) => {
	switch (grade) {
		case 'excellent':
			return 'E'
		case 'satisfactory':
			return 'S'
		case 'needs improvement':
			return 'N'
		case 'unsatisfactory':
			return 'U'
		default:
			return grade as string
	}
}

export const GradeStar = ({ state, grade }: GradeStarProps) => {
	const styles = useStyles()

	const startColor = () => {
		switch (state) {
			case StateTypeEnum.Graded:
				return GREEN_STAR
			case StateTypeEnum.Submitted:
				return YELLOW_STAR
			default:
				return PURPLE_STAR
		}
	}

	const [gradeFormatted] = useState(formatGrade(grade as string))

	return (
		<>
			<Avatar className={styles.avatarIcon} variant="rounded" src={startColor()} />
			{state === StateTypeEnum.Graded && (
				<Typography className={styles.gradeValue}>
					<b>{gradeFormatted}</b>
				</Typography>
			)}
		</>
	)
}
