import { makeStyles } from '@material-ui/core'

const useSignUpMainDialogStyles = makeStyles({
	classLinkButton: {
		'& b': {
			paddingLeft: '5px !important'
		}
	}
})

export default useSignUpMainDialogStyles
