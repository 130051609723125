import { Button, ButtonProps, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import useProductCardButtonStyles from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/productCardButton/ProductCardButton.styles'

export type ProductCardButtonProps = ButtonProps & {
	tooltipTitle?: string
}
export const ProductCardButton = ({ tooltipTitle, ...props }: ProductCardButtonProps) => {
	const styles = useProductCardButtonStyles()

	return (
		<Tooltip title={tooltipTitle || ''}>
			<span>
				<Button {...props} className={clsx(styles.iconButton, props.className)}>
					{props.children}
				</Button>
			</span>
		</Tooltip>
	)
}
