import React, { useEffect, useState } from 'react'

import {
	Box,
	Button,
	Chip,
	Divider,
	Drawer,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Tooltip,
	Typography
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import clsx from 'clsx'
import { Link, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import {
	AssignmentsIcon,
	DoubleMusicNoteIcon,
	HomeIcon,
	PeopleIcon,
	StarIcon,
	WurrlyIcon,
	WurrlyTextIcon
} from 'src/assets/icons/CustomIcons'
import { LogoutButton } from 'src/components'
import { UserAvatar } from 'src/components/UserAvatar'
import { AppVersion } from 'src/components/Version/Version.container'
import { EnvironmentEnum, environment } from 'src/environment'
import { Student, Wurrly_Role_Enum } from 'src/graphql/autogenerate/schemas'
import { useLocalStorage } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { PrivateRoute } from 'src/routes/routes'
import { StudentPages } from 'src/routes/studentpages'
import { RouteNotFound } from 'src/scenes/Errors/404.container'
import { GoToClassDialog } from 'src/scenes/Student/1-Home/components/ClassGrid'
import { StudentClassDetailsProvider } from 'src/scenes/Student/1-Home/hooks/useClassDetails'
import { BOX_PADDING } from 'src/styles/constants'
import { buildRouteParameters, StorageEnum } from 'src/utils'

import useStyles from './Student.styles'

type AdminProps = {
	darkMode?: boolean
	setDarkMode?: React.Dispatch<React.SetStateAction<boolean>>
}

const MusicCatalog = () => {
	const [isSelectClassOpen, setIsSelectClassOpen] = useState(false)
	const match = useRouteMatch({
		path: StudentPages.MusicCatalogDrawer.path,
		exact: false
	})

	return (
		<StudentClassDetailsProvider>
			<ListItem
				selected={!!match}
				button
				onClick={() => {
					setIsSelectClassOpen(true)
				}}>
				<ListItemIcon>
					<DoubleMusicNoteIcon />
				</ListItemIcon>
				<ListItemText primary="Music Catalog" />
			</ListItem>
			{isSelectClassOpen && (
				<GoToClassDialog
					isOpen={isSelectClassOpen}
					setIsOpen={setIsSelectClassOpen}
					title="Select a Class"
					skipIfOnlyOne
					sendParamAsState
				/>
			)}
		</StudentClassDetailsProvider>
	)
}

const drawerNavigation = [
	{
		...StudentPages.Home,
		icon: <HomeIcon />
	},
	{
		...StudentPages.Classes,
		icon: <PeopleIcon />
	},
	{
		...StudentPages.MusicCatalog,
		renderComponent: <MusicCatalog />
	},
	{
		...StudentPages.Assignments,
		icon: <AssignmentsIcon />
	},
	{
		...StudentPages.MyFavorites,
		icon: <StarIcon />
	},
	{
		...StudentPages.MyWurrlies,
		icon: <MusicNoteIcon />
	}
]

const DrawerContent: React.FC<{ drawerExpanded: boolean }> = ({ drawerExpanded }) => {
	const isViewingProfile = !!useRouteMatch({ path: StudentPages.Profile.path })
	const history = useHistory()
	const styles = useStyles()
	const { studentData: userData } = useLoginContext()
	const [localVersion] = useLocalStorage<AppVersion>(StorageEnum.appVersion, {
		version: '',
		created_at: ''
	})

	const goToProfile = () => {
		history.push(buildRouteParameters(StudentPages.Profile))
	}

	return (
		<div>
			<List>
				<Box pt={3} pb={3} position="relative">
					<ListItem style={drawerExpanded ? undefined : { paddingLeft: 13, paddingRight: 13 }}>
						<ListItemText primary={drawerExpanded ? <WurrlyTextIcon /> : <WurrlyIcon />} />
					</ListItem>
				</Box>
				<ListItem
					selected={isViewingProfile}
					button
					style={drawerExpanded ? undefined : { paddingLeft: 8, paddingRight: 8 }}
					onClick={goToProfile}>
					<ListItemAvatar>
						<UserAvatar user={userData} />
					</ListItemAvatar>
					<ListItemText
						primary={
							<Box className={styles.profileItemContainer}>
								<Typography className={styles.profileItemContainer}>
									{userData?.first_name}
								</Typography>
								{isViewingProfile && <ArrowRightIcon className={styles.profileItemArrow} />}
							</Box>
						}
					/>
				</ListItem>
			</List>
			<Box mt={1} mb={1}>
				<Divider />
			</Box>
			<List>
				{drawerNavigation.map((page, index) => {
					if ('renderComponent' in page) {
						const RenderComponent = () => page.renderComponent as JSX.Element

						return <RenderComponent key={page.name} />
					}
					if (
						page.onlyDev &&
						![EnvironmentEnum.develop, EnvironmentEnum.local].includes(environment.env)
					)
						return null

					return (
						<CustomLink
							key={page.name}
							activeOnlyWhenExact={index === 0}
							name={page.name}
							icon={page.icon}
							to={page.path}
							onlyDev={page.onlyDev}
						/>
					)
				})}
			</List>
			<Box mt={1} mb={1}>
				<Divider />
			</Box>
			<List subheader={drawerExpanded ? <ListSubheader>Settings</ListSubheader> : undefined}>
				{/* <ListItem button onClick={handleDarkMode}>
						<ListItemIcon >{darkMode ? <Brightness7Icon /> : <Brightness4Icon />}</ListItemIcon>
						<ListItemText id="switch-list-label-darkmode" primary="Dark Mode" />
						{expanded && <ListItemSecondaryAction>
							<Switch
								edge="end"
								onChange={handleDarkMode}
								checked={darkMode}
								inputProps={{ 'aria-labelledby': 'switch-list-label-darkmode' }}
							/>
						</ListItemSecondaryAction>}
					</ListItem> */}
				<LogoutButton />
			</List>
			{!!localVersion.version.length && <ListSubheader>v{localVersion.version}</ListSubheader>}
		</div>
	)
}

export const StudentRoutes: React.FC<AdminProps> = () => {
	const styles = useStyles()

	const { studentData: userData } = useLoginContext()
	const [drawerExpanded, setDrawerExpanded] = useState(true)
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)
	const [isDrawerVisible, setIsDrawerVisible] = useState(true)

	const handleDrawerExpand = () => {
		if (mobileDrawerOpen) setMobileDrawerOpen(false)
		else setDrawerExpanded(!drawerExpanded)
	}

	const handleDrawerToggle = () => {
		setMobileDrawerOpen(!mobileDrawerOpen)
		if (!drawerExpanded) setDrawerExpanded(true)
	}

	return (
		<div className={styles.root}>
			{isDrawerVisible && (
				<>
					<Hidden smUp implementation="css">
						<Drawer
							variant="temporary"
							className={styles.drawer}
							classes={{
								paper: styles.drawerPaper
							}}
							anchor="left"
							open={mobileDrawerOpen}
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}>
							<DrawerContent drawerExpanded={drawerExpanded} />
						</Drawer>
					</Hidden>

					<Hidden xsDown implementation="css">
						<Drawer
							variant="permanent"
							className={clsx(styles.drawer, {
								[styles.drawerOpen]: drawerExpanded,
								[styles.drawerClose]: !drawerExpanded
							})}
							classes={{
								paper: clsx(styles.drawerPaper, {
									[styles.drawerOpen]: drawerExpanded,
									[styles.drawerClose]: !drawerExpanded
								})
							}}
							anchor="left">
							<DrawerContent drawerExpanded={drawerExpanded} />
						</Drawer>
					</Hidden>

					<Hidden xsDown implementation="css">
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleDrawerExpand}
							className={styles.chevronIcon}>
							{drawerExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</Button>
					</Hidden>
				</>
			)}

			<Box
				width="100%"
				position="relative"
				className={clsx({
					[styles.widthExpanded]: drawerExpanded,
					[styles.widthCollapsed]: !drawerExpanded
				})}>
				{isDrawerVisible && (
					<Hidden smUp implementation="css">
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerToggle}
							className={styles.menuButton}>
							<MenuIcon />
						</IconButton>
					</Hidden>
				)}

				<RenderRoutes setIsDrawerVisible={setIsDrawerVisible} userData={userData as Student} />
			</Box>
		</div>
	)
}

const RenderRoutes: React.FC<{ setIsDrawerVisible: (x: boolean) => void; userData: Partial<Student> }> =
	React.memo(({ setIsDrawerVisible, userData }) => {
		return (
			<Switch>
				{Object.values(StudentPages).map((page) => {
					const Component = page.component
					const isViewingPage = !!useRouteMatch({ path: page.path, exact: true })

					useEffect(() => {
						if (isViewingPage) setIsDrawerVisible(!page.noDrawer)
					}, [isViewingPage])

					return (
						Component && (
							<PrivateRoute
								key={page.path}
								exact
								path={page.path}
								role={Wurrly_Role_Enum.Student}
								component={() => {
									return page.noPadding ? (
										<Component teacherId={userData?.student_id as number} page={page} />
									) : (
										<Box p={BOX_PADDING}>
											<Component teacherId={userData?.student_id as number} page={page} />
										</Box>
									)
								}}
							/>
						)
					)
				})}
				<RouteNotFound />
			</Switch>
		)
	})

const CustomLink = (props: {
	name: string
	disabled?: boolean
	icon: JSX.Element
	to: string
	activeOnlyWhenExact?: boolean
	onlyDev?: boolean
}) => {
	const match = useRouteMatch({
		path: props.to,
		exact: props.activeOnlyWhenExact
	})

	const el = (
		<ListItem button selected={!!match} component={Link} to={props.to} disabled={!!props.disabled}>
			<ListItemIcon>{props.icon}</ListItemIcon>
			<ListItemText primary={props.name} />
			{props.onlyDev && <Chip label="dev" color="secondary" size="small" />}
		</ListItem>
	)

	if (props.disabled)
		return (
			<Tooltip title="Coming soon" placement="top" style={{ zIndex: 100 }}>
				{el}
			</Tooltip>
		)
	else return el
}
