import { useEffect, useState } from 'react'

import { GetLessonPlanByClassIdDocument, useGetLessonPlanByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { ASSIGNMENT_PER_VIEW, RefetchProductsEnum, getExistingCache, refetchCache } from 'src/utils'

type UseAssignmentsLessonsProps = {
	classId: number
}
export const useAssignmentsLessons = ({ classId }: UseAssignmentsLessonsProps) => {
	const [selectedClassId, setSelectedClassId] = useState(classId)

	const [seeMoreEnabled, setSeeMoreEnabled] = useState(true)
	const [isFetching, setIsFetching] = useState(false)
	const [isRefetch, setIsRefetch] = useState(false)

	const [totalLessons, setTotalLessons] = useState(0)

	const [lessonPlans, setLessonPlans] = useState<Lesson_Plan[]>([])

	const limit = ASSIGNMENT_PER_VIEW

	const variables = {
		classId: selectedClassId,
		limit
	}

	const [getLessons, { data, loading, refetch }] = useGetLessonPlanByClassIdLazyQuery()

	const seeMore = () => {
		setIsFetching(true)
	}

	useEffect(() => {
		setIsFetching(false)
		if (data?.lesson_plan && !isFetching) {
			setIsRefetch(
				refetchCache({
					data: data.lesson_plan as Lesson_Plan[],
					type: RefetchProductsEnum.LessonPlan,
					isFetching,
					variables,
					query: GetLessonPlanByClassIdDocument
				}) as boolean
			)
		}
		if (data) {
			setTotalLessons(data?.lesson_plan_aggregate?.aggregate?.count || 0)
		}
	}, [data, loading])

	useEffect(() => {
		setSeeMoreEnabled(totalLessons > lessonPlans.length)
	}, [lessonPlans])

	useEffect(() => {
		if (isRefetch) {
			const existingCache = getExistingCache({ variables, query: GetLessonPlanByClassIdDocument }) as {
				lesson_plan: Lesson_Plan[]
			}
			setLessonPlans(existingCache.lesson_plan)
			setIsRefetch(false)
		}
	}, [isRefetch])

	useEffect(() => {
		if (!isFetching) return

		const currentLength = lessonPlans.length
		refetch({
			offset: currentLength,
			limit
		}).then((fetchMoreResult) => {
			setIsRefetch(
				refetchCache({
					data: fetchMoreResult.data.lesson_plan as Lesson_Plan[],
					type: RefetchProductsEnum.LessonPlan,
					isFetching,
					variables,
					query: GetLessonPlanByClassIdDocument
				}) as boolean
			)

			setIsFetching(false)
		})
	}, [isFetching])

	useEffect(() => {
		setSelectedClassId(classId)
	}, [classId])

	useEffect(() => {
		if (selectedClassId !== 0) {
			getLessons({
				variables
			})
		}
	}, [selectedClassId])

	return {
		totalLessons,
		lessonPlans,
		seeMoreEnabled,
		seeMore,
		loading
	}
}
