import React, { useEffect, useState } from 'react'

import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	Grid,
	List,
	ListItem,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { useHistory } from 'react-router-dom'
import { ArrayElement } from 'src/@types'
import { DoubleMusicNoteIcon, LessonsIcon, PersonIcon, VideoIcon } from 'src/assets/icons'
import { ImageButton, ListStudent, SelectSortOrderBy } from 'src/components'
import { ListCardDialog } from 'src/components/Dialogs/ListCardDialog'
import { useGetStudentClassesLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Class, Order_By, Student } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildStudentSearchParams, DEFAULT_MUSIC_BG, formatDate, getCyKey } from 'src/utils'

import { useLoginContext } from '../../../../../../hooks/useLogin'
import { useStudentsContext } from '../../useStudents'
import { AddStudentToClass } from '../AddStudentToClass'
import { DeleteStudentDialog } from '../DeleteStudentDialog'
import { EmptyStudentList } from '../EmptyStudentList'
import { ReInviteStudentDialog } from '../ReInviteStudentDialog'
import { useStudentsGridContext } from './useStudentsGrid'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		panel: {
			width: '100%',
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white
			},
			'& .MuiButton-root:not(:last-child)': {
				marginRight: 5
			},
			'& .MuiButton-root:first-child': {
				marginLeft: 32
			},
			// view button
			'& .MuiButton-root:last-child': {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				marginRight: 32
			}
		}
	})
)
export const StudentsGrid = () => {
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const { searchText } = useStudentsContext()
	const [studentDelete, setStudentDelete] = useState<Student | undefined>()
	const [studentReInvite, setStudentReInvite] = useState<Student | undefined>()
	const [addToClassStudent, setAddToClassStudent] = useState<Student | undefined>()
	const [isStudentDetails, setIsStudentDetails] = useState(false)
	const [studentDetails, setStudentDetails] = useState<Student | undefined>()

	const [studentClassesQuery, { data: classData, loading: classLoading }] = useGetStudentClassesLazyQuery()

	const classes =
		classData?.teacher_by_pk?.class_teachers.map((teacherClass) => teacherClass.class as Class) || []
	const studentClasses = classData?.teacher_by_pk?.class_teachers_aggregate.aggregate?.count || 0

	const { refetch, limit, sort, setSort, setSearchText, students, count, loading, isFetching, moreResults } =
		useStudentsGridContext()

	useEffect(() => {
		handleRefetch()
		setSearchText(searchText)
	}, [searchText, sort])
	const handleRefetch = () => {
		refetch({
			teacherId,
			conditions: buildStudentSearchParams(searchText),
			order: sort,
			limit
		})
	}

	useEffect(() => {
		if (studentDetails?.student_id) {
			setIsStudentDetails(true)
			studentClassesQuery({
				variables: { studentId: studentDetails.student_id, teacherId }
			})
		}
	}, [studentDetails])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as Order_By
		setSort(selectedSort)
	}

	const ClassList = () => {
		const styles = useStyles()

		// students, lessons, songs and videos icons
		const LinePanel = ({ classElement }: { classElement: ArrayElement<typeof classes> }) => {
			const iconSize = 20

			return (
				<Box className={styles.panel} display="flex" flexWrap="wrap" alignItems="center">
					<Button startIcon={<PersonIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_students_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<Button startIcon={<LessonsIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_lesson_plans_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<Button startIcon={<DoubleMusicNoteIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_songs_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<Button startIcon={<VideoIcon height={iconSize} width={iconSize} />}>
						{classElement?.class_tips_aggregate?.aggregate?.count ?? '0'}
					</Button>
					<div style={{ flexGrow: 1 }} />
					{/* <Button endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}>View</Button> */}
				</Box>
			)
		}

		return (
			<Box>
				{!classLoading && classes && (
					<List
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(2, 2fr)',
							gap: '2px'
						}}>
						{classes.map((classElement, index) => (
							<Box key={'item-' + index}>
								<ListItem>
									<ImageButton
										titleHeight={{ xs: 200, md: 220 }}
										backgroundImageUrl={classElement.image_path || DEFAULT_MUSIC_BG}
										title={
											<Box display="flex" width={'100%'}>
												<Typography style={{ fontSize: 28 }}>
													{classElement.title.length > 20
														? `${classElement.title.substring(0, 20)}...`
														: `
														${classElement.title}`}
												</Typography>
												<div style={{ flexGrow: 1 }} />
											</Box>
										}
										titlePosition="initial"
										subtitle={
											<Box display="flex" mt={1.5}>
												<Typography style={{}}>
													<CalendarTodayIcon />{' '}
													{classElement.start_date
														? formatDate(classElement.start_date)
														: 'Missing Start Date'}{' '}
													-{' '}
													{classElement.end_date
														? formatDate(classElement.end_date)
														: 'Missing End Date'}
												</Typography>
											</Box>
										}
										element={<LinePanel classElement={classElement} />}
										elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 20 }}
										linkTo={`classes/${classElement.class_id}/details`}
									/>
								</ListItem>
							</Box>
						))}
					</List>
				)}

				{classLoading && (
					<Box style={{ margin: 20, textAlign: 'center' }}>
						<CircularProgress color="secondary" size={40} />
					</Box>
				)}
			</Box>
		)
	}

	return (
		<Box marginTop={10}>
			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b data-cy={getCyKey(StudentsGrid, 'studentsCount')}>Students ({count})</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<SelectSortOrderBy cyKey="Students" value={sort} onChange={handleSort} />
					</Box>
				</Grid>
			</Grid>
			{loading || count !== 0 ? (
				<Grid container spacing={3}>
					<Grid item xs>
						{count !== 0 ? (
							<ListStudent
								data={students}
								spacing={1.5}
								detailsStudent={setStudentDetails}
								deleteStudent={setStudentDelete}
								reinviteStudent={setStudentReInvite}
								addToClassStudent={setAddToClassStudent}
								viewButton={false}
							/>
						) : (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
						{isFetching && moreResults && (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}
						{!loading && students.length > 0 && !moreResults && (
							<Box mt={4} textAlign="center">
								<Button
									onClick={() => {
										window.scrollTo(0, 0)
									}}
									variant="text"
									color="secondary">
									Back to Top
								</Button>
							</Box>
						)}
					</Grid>
					<ReInviteStudentDialog
						studentReinvite={studentReInvite}
						setStudentReinvite={setStudentReInvite}
					/>
					<DeleteStudentDialog
						studentDelete={studentDelete}
						setStudentDelete={setStudentDelete}
						onDone={handleRefetch}
					/>
					<AddStudentToClass
						addToClassStudent={addToClassStudent}
						setAddToClassStudent={setAddToClassStudent}
					/>
				</Grid>
			) : (
				<EmptyStudentList
					title={
						searchText
							? `Your search for "${searchText}" did not return any results.`
							: 'You haven’t added any Students yet.'
					}
					description="It’s easy to invite your Students by adding them below."
				/>
			)}
			<ListCardDialog
				open={isStudentDetails}
				setOpen={setIsStudentDetails}
				onClose={() => {
					setIsStudentDetails(false)
					setStudentDetails(undefined)
				}}
				onConfirm={() => {
					history.push(Pages.Classes.path)
				}}
				title={`${studentDetails?.first_name} Classes` || ''}
				description={`${studentClasses} Classes`}
				list={<ClassList />}
				setSelectedStudent={setStudentDetails}
			/>
		</Box>
	)
}
