import { createStyles, makeStyles } from '@material-ui/core'

export const useStudentsFilterStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginBottom: theme.spacing(3)
		},
		card: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			padding: theme.spacing(2),
			cursor: 'pointer'
		},
		dropDownIcon: {
			marginLeft: theme.spacing(3),
			color: 'rgba(0, 0, 0, 0.54)'
		},
		popover: {
			width: '100%'
		},
		popoverPaper: {
			background: 'transparent',
			boxShadow: 'none',
			marginLeft: -theme.spacing(1)
		}
	})
)

export default useStudentsFilterStyles
