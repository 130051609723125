import { ChangeEvent } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { SelectSort } from 'src/components/SelectSort'
import { SortOrder } from 'src/utils/enums'

import useStyles from './SubmissionsHeader.styles'

type SubmissionsHeaderProps = {
	submissionCount: number
	sortOrder: SortOrder
	setSortOrder: (sortOrder: SortOrder) => void
}
export const SubmissionsHeader = ({ submissionCount, sortOrder, setSortOrder }: SubmissionsHeaderProps) => {
	const styles = useStyles()

	const handleSortOrder = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		setSortOrder(event.target.value as SortOrder)
	}

	return (
		<Grid container>
			<Grid item xs={12} md={9}>
				<Box className={styles.container}>
					<Typography variant="h6">
						<strong>Student Submissions ({submissionCount})</strong>
					</Typography>

					<SelectSort
						value={sortOrder}
						onChange={handleSortOrder}
						dateSubmitted
						grade
						cyKey="SelectSubmissionSortOrder"
					/>
				</Box>
			</Grid>
		</Grid>
	)
}
