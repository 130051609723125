import { useEffect, useState } from 'react'

import {
	useGetClassAssignmentQuery,
	useGetSubmissionsByClassAssignmentIdQuery
} from 'src/graphql/autogenerate/hooks'
import { Assignment, Order_By, Submission, Grading_System } from 'src/graphql/autogenerate/schemas'
import { formatSubmissionDate } from 'src/utils'
import { SortOrder } from 'src/utils/enums'

import { orderSubmissionsByGrade, submittedAndGradedSubmissionStates } from './utils'

type AssignmentWithFormattedDate = Pick<
	Assignment,
	'assignment_id' | 'due_date' | 'due_time' | 'name' | 'instructions'
> & {
	dueDateFormatted?: string
	grading_system: Pick<Grading_System, 'label' | 'values'>
}

export const useClassAssignment = (classAssignmentId: number) => {
	const [className, setClassName] = useState('')
	const [assignment, setAssignment] = useState<AssignmentWithFormattedDate>()
	const [submissions, setSubmissions] = useState<Submission[]>([])
	const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Up)
	const [totalSubmissions, setTotalSubmissions] = useState(0)
	const [sortByName, setSortByName] = useState<Order_By | undefined>(Order_By.Asc)
	const [sortByDate, setSortByDate] = useState<Order_By>()
	const {
		data: assignmentData,
		error: assignmentError,
		loading: assignmentLoading
	} = useGetClassAssignmentQuery({ variables: { classAssignmentId } })
	const {
		data: submissionsData,
		error: submissionsError,
		loading: submissionsLoading
	} = useGetSubmissionsByClassAssignmentIdQuery({
		variables: {
			classAssignmentId,
			sortByName,
			sortByDate,
			submissionStates: submittedAndGradedSubmissionStates
		}
	})

	// Extract assignment data
	useEffect(() => {
		if (assignmentData && !assignmentLoading && !assignmentError) {
			const classNameFromQuery = assignmentData.class_assignment_by_pk?.class?.title ?? ''
			const assignmentFromQuery = assignmentData.class_assignment_by_pk?.assignment
			const dueDateFormatted = formatSubmissionDate({
				dateString: assignmentFromQuery?.due_date,
				timeString: assignmentFromQuery?.due_time
			})
			setClassName(classNameFromQuery)
			if (assignmentFromQuery) setAssignment({ ...assignmentFromQuery, dueDateFormatted })
		}
	}, [assignmentData, assignmentLoading, assignmentError])

	// Extract submissions data
	useEffect(() => {
		if (submissionsData && !submissionsLoading && !submissionsError) {
			const submissionsFromQuery = submissionsData.submission
			if (submissionsFromQuery) setSubmissions(submissionsFromQuery as Submission[])
			setTotalSubmissions(submissionsData.submission_aggregate?.aggregate?.count ?? 0)
		}
	}, [submissionsData, submissionsLoading, submissionsError])

	// Set the sort variables
	useEffect(() => {
		const submissionsFromQuery = submissionsData?.submission

		switch (sortOrder) {
			case SortOrder.Up:
			case SortOrder.Down:
				if (sortByName === (sortOrder as unknown as Order_By))
					setSubmissions((submissionsFromQuery as Submission[]) || [])
				setSortByName(sortOrder as unknown as Order_By)
				setSortByDate(undefined)
				break
			case SortOrder.Recent:
				if (sortByDate === Order_By.Asc) setSubmissions((submissionsFromQuery as Submission[]) || [])
				setSortByName(undefined)
				setSortByDate(Order_By.Asc)
				break
			case SortOrder.Grade:
				setSubmissions(orderSubmissionsByGrade(submissions, assignment?.grading_system?.values))
				break
		}
	}, [sortOrder])

	return {
		assignmentError,
		assignmentLoading,
		submissionsError,
		submissionsLoading,
		className,
		assignment,
		submissions,
		totalSubmissions,
		sortOrder,
		setSortOrder
	}
}
