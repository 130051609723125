import { Theme, makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const useStyles = makeStyles<Theme, { isValid?: boolean }>(() => ({
	input: {
		'& .MuiFormHelperText-root': {
			color: ({ isValid }) => (isValid ? green[500] : '')
		}
	}
}))

export default useStyles
