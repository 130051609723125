import { useMemo, useState } from 'react'

import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import useAudio from 'react-use/lib/useAudio'
import { InstrumentsGuideType } from 'src/@types'
import { PlayIconYellow, StopRecordingYellowIcon } from 'src/assets/icons'
import { BaseDialog, BaseDialogProps, BaseSlider } from 'src/components'
import { Lyric } from 'src/components/Song/Lyrics'
import { Note } from 'src/components/Song/Note'
import {
	LYRIC_MAIN_Y_POS_AUDIO,
	LYRIC_MAIN_Y_POS,
	LYRIC_DESC_Y_POS_AUDIO,
	LYRIC_DESC_Y_POS,
	LYRIC_SECOND_DESC_Y_POS_AUDIO,
	LYRIC_SECOND_DESC_Y_POS,
	INITIAL_X_OFFSET
} from 'src/components/Studio/Record/InstrumentNotesPanel/contants'
import { Class } from 'src/graphql/autogenerate/schemas'

import useStyles from './PreviewTimingDialog.styles'

export type ClassForSelect = Pick<Class, 'class_id' | 'title' | 'image_path' | 'maturity_level'> & {
	saved?: boolean
}

type PreviewTimingDialogProps = BaseDialogProps & {
	lyricsData?: { text: string; time: number }[]
	chordsData?: { chord: string; time: number }[]
	pxPerMs: number
	open: boolean
	audioURL: string
	onClose: () => void
}

export const PreviewTimingDialog = ({
	lyricsData = [],
	chordsData = [],
	pxPerMs,
	open,
	audioURL,
	onClose
}: PreviewTimingDialogProps) => {
	const [playing, setPlaying] = useState(false)
	const [allChords, setAllChords] = useState<string[]>([])
	const [selectedTab, setSelectedTab] = useState(1)

	const [audioElement, audioState, audioControls] = useAudio({
		src: audioURL,
		preload: 'auto'
	})

	const togglePlay = () => {
		const time = 0
		if (!playing) {
			audioControls.play()
			setPlaying(true)
		} else {
			audioControls.pause()
			audioControls.seek(time)
			setPlaying(false)
		}
	}

	const guides = [InstrumentsGuideType.piano, InstrumentsGuideType.guitar, InstrumentsGuideType.ukulele]
	const selectedGuide = guides[selectedTab]

	const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
		setSelectedTab(newValue)
	}

	const translateDuration = audioState.duration + INITIAL_X_OFFSET / pxPerMs

	const chords = useMemo(() => {
		if (!chordsData.length) return null
		const chordsArr: JSX.Element[] = []
		const allChords: string[] = []
		for (const chord of chordsData) {
			// Calculate if chord should go up or in the middle
			const xPos = chord.time * pxPerMs + INITIAL_X_OFFSET

			if (!allChords.includes(chord.chord)) allChords.push(chord.chord)
			chordsArr.push(
				<Note
					key={`${chord.time}_note`}
					guide={selectedGuide}
					chord={{ name: chord.chord }}
					lyrics={!!lyricsData.length}
					xPos={xPos}
					time={chord.time}
				/>
			)
		}

		setAllChords(allChords)

		return chordsArr
	}, [chordsData])

	const lyrics = useMemo(() => {
		if (!lyricsData.length) return null
		const lyricsArr: JSX.Element[] = []
		let lastWordX = 0
		let lastWordY = 0
		let lastWordWidth = 0
		let anteultimateWordX = 0
		let anteultimateWordWidth = 0
		const mainYPos = selectedGuide === InstrumentsGuideType.audio ? LYRIC_MAIN_Y_POS_AUDIO : LYRIC_MAIN_Y_POS
		const descYPos = selectedGuide === InstrumentsGuideType.audio ? LYRIC_DESC_Y_POS_AUDIO : LYRIC_DESC_Y_POS
		const descYPosSecond =
			selectedGuide === InstrumentsGuideType.audio ? LYRIC_SECOND_DESC_Y_POS_AUDIO : LYRIC_SECOND_DESC_Y_POS
		for (const text of lyricsData) {
			// Calculate if lyrics should go in middle or down
			const xPos = text.time * pxPerMs + INITIAL_X_OFFSET
			const overlap = lastWordX + lastWordWidth >= xPos || anteultimateWordX + anteultimateWordWidth >= xPos
			const yPos = overlap
				? lastWordY === mainYPos
					? descYPos
					: lastWordY === descYPos
					? descYPosSecond
					: mainYPos
				: mainYPos
			anteultimateWordX = lastWordX
			anteultimateWordWidth = lastWordWidth
			lastWordX = xPos
			lastWordY = yPos
			const lyricFontSize = selectedGuide === InstrumentsGuideType.audio ? 17 : 14
			// Give extra 5px so the word breaks even if its not overlapped, but really next to each other
			const wordWidth = getTextWidth(text.text, `${lyricFontSize}px Roboto`) + 5
			lastWordWidth = wordWidth

			lyricsArr.push(
				<Lyric
					key={`${text.time}_lyric`}
					name={text.text}
					time={text.time}
					xPos={xPos}
					yPos={yPos}
					lyricFontSize={lyricFontSize}
				/>
			)
		}

		return lyricsArr
	}, [chordsData])

	function getTextWidth(text: string, font?: string) {
		const canvas = document.createElement('canvas')
		const context = canvas.getContext('2d')
		if (context?.font) {
			context.font = font || getComputedStyle(document.body).font

			return context.measureText(text).width
		}

		return 0
	}

	const currentChord = chordsData?.filter((chord) => chord.time <= audioState.time).pop()
	const styles = useStyles({ audioState, pxPerMs, translateDuration })

	return (
		<>
			<BaseDialog
				open={open}
				onClose={onClose}
				dividers={false}
				maxWidth="sm"
				fullWidth
				header={
					<Typography variant="h4" align="center">
						Preview Timing
					</Typography>
				}
				onDiscard={onClose}
				discardLabel="Close"
				bodyProps={{}}
				body={
					<>
						<Box className={styles.chordsContainer}>
							<Box className={styles.chordsContainerInner}>
								<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
									{Object.keys(InstrumentsGuideType).map((instrument) => (
										<Tab
											label={instrument.toUpperCase()}
											key={instrument}
											id={`simple-tab-${instrument}`}
											aria-controls={`simple-tabpanel-${instrument}`}
										/>
									))}
								</Tabs>
							</Box>
						</Box>

						<Box display={'flex'} justifyContent={'space-evenly'} width={'100%'} flexWrap={'wrap'}>
							{allChords.map((chord) => (
								<Note
									key={chord}
									guide={selectedGuide}
									chord={{ name: chord }}
									showDiagram
									highLight={chord === currentChord?.chord}
									time={4}
								/>
							))}
						</Box>

						<Paper className={styles.paper}>
							<BaseSlider className={styles.slider} max={100} disabled />
							<Box className={styles.barSlider}></Box>
							<Box className={styles.chordsAndLyrics}>
								{chords && chords.map((i) => i)}
								{lyrics && lyrics.map((i) => i)}
							</Box>
						</Paper>
						<Box className={styles.playButton} onClick={togglePlay}>
							{!playing ? <PlayIconYellow /> : <StopRecordingYellowIcon />}
						</Box>

						<div className={styles.hidden}>{audioElement}</div>
					</>
				}
			/>
		</>
	)
}
