import { alpha, Avatar, Theme, Typography, createStyles, makeStyles } from '@material-ui/core'
import { ActivityBoxEmpty } from 'src/components'

export const useInvalidTokenMessageStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			margin: '2rem 0 1.5rem',
			alignItems: 'center',
			gap: '1rem',
			padding: '5em',
			height: '25em'
		},
		icon: {
			color: theme.palette.secondary.dark,
			backgroundColor: alpha(theme.palette.secondary.main, 0.25),
			width: 130,
			height: 130,
			fontSize: 80,
			'& .MuiSvgIcon-root': {
				fontSize: 80
			}
		}
	})
)

type InvalidTokenMessageProps = {
	expired?: boolean
	hasToken?: boolean
}
export const InvalidTokenMessage = ({ expired, hasToken }: InvalidTokenMessageProps) => {
	const styles = useInvalidTokenMessageStyles()

	return (
		<ActivityBoxEmpty noBorder className={styles.box}>
			<>
				<Avatar className={styles.icon}>!</Avatar>
				{hasToken ? (
					<>
						<Typography variant="h5">
							Activation token {expired ? 'has expired' : 'is invalid'}.
						</Typography>
						<Typography>Please request a new one.</Typography>
					</>
				) : (
					<>
						<Typography variant="h5">No activation token found.</Typography>
						<Typography>Please request an invitation to activate your account.</Typography>
					</>
				)}
			</>
		</ActivityBoxEmpty>
	)
}
