import React, { useEffect, useState, Fragment } from 'react'

import { Box, Checkbox, InputBase, Typography } from '@material-ui/core'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { LocationState } from 'src/@types'
import { WurrlyYellowLogo } from 'src/assets/icons/CustomIcons'
import { BaseDialog, BaseLink } from 'src/components'
import { useLoginTeacherLazyQuery, useTeacherEmailExistsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { LoginPages } from 'src/routes/loginPages'
import { SignupPages } from 'src/routes/signupPages'
import { IntegrationButtonCanvas } from 'src/scenes/Login/components/IntegrationButtonCanvas'
import { RecoverPasswordMailSendDialog } from 'src/scenes/Login/components/RecoverPasswordMailSendDialog'
import { validateEmail, getCyKey, NO_CACHE } from 'src/utils'

import { IntegrationButtonActions, SignUpData } from '../enums'
import { useLoginStyles } from '../styles'
import { IntegrationButton } from './IntegrationButton'
import { IntegrationButtonClassLink } from './IntegrationButtonClassLink'
import { PasswordInput } from './PasswordInput'
import useStyles from './SignUpMainDialog.styles'

type SignUpMainDialogProps = {
	setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>
	setSelectRoleDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
	signUpDialogOpen?: boolean
	setSignUpDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
	isLogin: boolean
}
export const SignUpMainDialog = ({
	setSignUpData = () => {},
	setSelectRoleDialogOpen = () => {},
	signUpDialogOpen = false,
	setSignUpDialogOpen = () => {},
	isLogin
}: SignUpMainDialogProps) => {
	const styles = useStyles()
	const classes = useLoginStyles({})
	const { state } = useLocation<LocationState>()
	const { loginSignIn } = useLoginContext()
	const history = useHistory()
	const [signupEmail, setSignupEmail] = useState<string>('')
	const [signupFirstName, setSignupFirstName] = useState<string>('')
	const [signupLastName, setSignupLastName] = useState<string>('')
	const [createPassword, setCreatePassword] = useState<string>('')
	const [confirmPassword, setConfirmPassword] = useState<string>('')
	const [loginEmail, setLoginEmail] = useState<string>('')
	const [loginPassword, setLoginPassword] = useState<string>('')
	const [passwordFulfillRequirements, setPasswordFulfillRequirements] = useState<boolean>(false)
	const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState<boolean>(false)
	const [isTermsOfService, setIsTermsOfService] = useState(false)
	const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false)
	const [loginTeacher, { data: loginTeacherData, loading: loginTeacherLoading, error: loginTeacherError }] =
		useLoginTeacherLazyQuery(NO_CACHE)
	const [teacherEmailExists, { data: teacherEmailExistData, loading: teacherEmailExistLoading }] =
		useTeacherEmailExistsLazyQuery(NO_CACHE)
	const [error, setError] = useState('')
	const [emailAlreadyExists, setEmailAlreadyExists] = useState(false)
	const [redirectToReferrer, setRedirectToReferrer] = useState(false)
	const [loadingIntegration, setLoadingIntegration] = useState(false)
	const [selectedIntegrationType, setSelectedIntegrationType] = useState<Integration_Name_Enum>()
	const integrationButtonAction = isLogin ? IntegrationButtonActions.Login : IntegrationButtonActions.Signup

	const goToSignup = () => {
		history.push(SignupPages.Teacher.path)
	}

	const goToLogIn = () => {
		history.push(LoginPages.Teacher.path)
	}

	useEffect(() => {
		if (!teacherEmailExistLoading && teacherEmailExistData) {
			if (teacherEmailExistData?.teacherEmailExists) return setEmailAlreadyExists(true)
			setSignUpData({
				email: signupEmail,
				password: createPassword,
				nameFirst: signupFirstName,
				nameLast: signupLastName
			})
			setSelectRoleDialogOpen(true)
			setSignUpDialogOpen(false)
		}
	}, [teacherEmailExistData, teacherEmailExistLoading])

	useEffect(() => {
		if (loginTeacherError) {
			if (
				loginTeacherError.message === 'Invalid credentials' ||
				loginTeacherError.message === 'No user found with that email'
			)
				handleError('Invalid Username or Password')
			else handleError('Uh oh, unexpected issue, try again!')

			return
		} else if (loginTeacherData?.login?.token) {
			const success = loginSignIn(loginTeacherData?.login?.token)
			if (success) setRedirectToReferrer(success)
			else handleError('Uh oh, something happened! Try again')
		}
	}, [loginTeacherData, loginTeacherError])

	if (redirectToReferrer === true) {
		return <Redirect to={state?.from || '/teacher'} />
	}

	const handleError = (message: string) => {
		setError(message)
		setTimeout(() => {
			setError('')
		}, 5000)
	}

	return (
		<Fragment>
			<BaseDialog
				dividers={false}
				style={{ width: '100%', color: 'red' }}
				backdropStyles={{ opacity: '0 !important' }}
				open={signUpDialogOpen}
				onClose={() => {}}
				paperProps={{
					style: {
						maxHeight: 'calc(100% - 100px)',
						width: '100%',
						maxWidth: '600px',
						padding: '20px 40px'
					}
				}}
				header={
					<Fragment>
						<Box display="flex" flexDirection="column" alignItems="center">
							<WurrlyYellowLogo style={{ width: 200 }} />
							<Typography>
								<b>{isLogin ? 'Sign In' : "Let's Get You Signed Up"}</b>
							</Typography>
						</Box>
						<Box display="flex" style={{ marginTop: '15px', justifyContent: 'space-between' }}>
							<IntegrationButton
								integrationType={Integration_Name_Enum.Google}
								selectedIntegrationType={selectedIntegrationType}
								disabled={loadingIntegration}
								action={integrationButtonAction}
								setLoading={setLoadingIntegration}
								setSignUpData={setSignUpData}
								onButtonClick={() => setSelectedIntegrationType(Integration_Name_Enum.Google)}
							/>
							{/* <IntegrationButton
								integrationType={Integration_Name_Enum.Clever}
								selectedIntegrationType={selectedIntegrationType}
								disabled={loadingIntegration}
								action={integrationButtonAction}
								setLoading={setLoadingIntegration}
								setSignUpData={setSignUpData}
								onButtonClick={() => setSelectedIntegrationType(Integration_Name_Enum.Clever)}
							/> */}
							<IntegrationButtonClassLink
								className={styles.classLinkButton}
								action={integrationButtonAction}
								setLoading={setLoadingIntegration}
								disabled={loadingIntegration}
								loading={loadingIntegration}
								setSignUpData={setSignUpData}
								onButtonClick={() => setSelectedIntegrationType(Integration_Name_Enum.ClassLink)}
							/>

							<IntegrationButtonCanvas
								className={styles.classLinkButton}
								action={integrationButtonAction}
								setLoading={setLoadingIntegration}
								disabled={loadingIntegration}
								loading={loadingIntegration}
								setSignUpData={setSignUpData}
								onButtonClick={() => setSelectedIntegrationType(Integration_Name_Enum.Canvas)}
							/>
						</Box>
						<Box
							style={{
								borderTop: '1px solid #221F31',
								width: '100%',
								position: 'relative',
								margin: '30px 0 20px 0'
							}}>
							<p
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate3d(-50%,-50%,0)',
									backgroundColor: 'white',
									padding: '0 20px',
									margin: '0',
									fontSize: '13px'
								}}>
								Or
							</p>
						</Box>
						{isLogin ? (
							<Fragment>
								<Typography className={classes.smallMarginLabel}>
									<b>Email</b>
								</Typography>
								<InputBase
									data-cy={getCyKey(SignUpMainDialog, 'loginEmail')}
									className={classes.input}
									value={loginEmail}
									fullWidth={true}
									onChange={(e) => {
										setLoginEmail(e.target.value)
									}}
								/>
								<Typography className={classes.smallMarginLabel}>
									<b>Password</b>
								</Typography>
								<PasswordInput
									data-cy={getCyKey(SignUpMainDialog, 'loginPassword')}
									password={loginPassword}
									setPassword={setLoginPassword}
									disableValidation={true}
								/>
								<Typography
									data-cy={getCyKey(SignUpMainDialog, 'ForgotPassword')}
									style={{
										textAlign: 'end',
										color: '#7C7C7C',
										fontSize: '13px',
										marginTop: '10px',
										cursor: 'pointer'
									}}
									onClick={() => {
										setResetPasswordDialogOpen(true)
										setSignUpDialogOpen(false)
									}}>
									Forgot Password?
								</Typography>
								{!!error && (
									<Typography style={{ color: 'red' }}>
										{/* Incorrect username or password */}
										{error}
									</Typography>
								)}
							</Fragment>
						) : (
							<Fragment>
								<Typography className={classes.label}>
									<b>First Name</b>
								</Typography>
								<InputBase
									data-cy={getCyKey(SignUpMainDialog, 'InputFirstName')}
									className={classes.input}
									value={signupFirstName}
									fullWidth={true}
									onChange={(e) => setSignupFirstName(e.target.value)}
								/>
								<Typography className={classes.label}>
									<b>Last Name</b>
								</Typography>
								<InputBase
									data-cy={getCyKey(SignUpMainDialog, 'InputLastName')}
									className={classes.input}
									value={signupLastName}
									fullWidth={true}
									onChange={(e) => setSignupLastName(e.target.value)}
								/>

								<Typography className={classes.label}>
									<b>Email</b>
								</Typography>
								<InputBase
									data-cy={getCyKey(SignUpMainDialog, 'InputEmail')}
									className={classes.input}
									value={signupEmail}
									fullWidth={true}
									onChange={(e) => {
										setSignupEmail(e.target.value)
										setEmailAlreadyExists(false)
									}}
									style={
										(signupEmail.length > 0 && !validateEmail(signupEmail)) ||
										emailAlreadyExists
											? { border: '1px solid red' }
											: {}
									}
								/>
								{signupEmail.length > 0 && !validateEmail(signupEmail) && (
									<Typography style={{ color: 'red' }}>Use a valid Email</Typography>
								)}
								{emailAlreadyExists && (
									<Typography style={{ color: 'red' }}>
										An account with that email already exists
									</Typography>
								)}
								<Typography className={classes.label}>
									<b>Create Password</b>
								</Typography>
								<PasswordInput
									data-cy={getCyKey(SignUpMainDialog, 'createPassword')}
									password={createPassword}
									setPassword={setCreatePassword}
									setFulfillRequirements={setPasswordFulfillRequirements}
									style={
										createPassword.length > 0 && !passwordFulfillRequirements
											? { border: '1px solid red' }
											: {}
									}
								/>
								{createPassword.length > 0 && !passwordFulfillRequirements && (
									<Typography style={{ color: 'red' }}>
										Password don't fulfill requirements
									</Typography>
								)}
								<Typography className={classes.label}>
									<b>Confirm Password</b>
								</Typography>
								<PasswordInput
									data-cy={getCyKey(SignUpMainDialog, 'confirmPassword')}
									password={confirmPassword}
									setPassword={setConfirmPassword}
									disableValidation={true}
									style={
										createPassword && confirmPassword && createPassword !== confirmPassword
											? { border: '1px solid red' }
											: {}
									}
								/>
								{createPassword && confirmPassword && createPassword !== confirmPassword && (
									<Typography style={{ color: 'red' }}>Passwords don't match</Typography>
								)}
								<Box mt={1} display="flex" flexWrap="nowrap" alignItems="center">
									<Checkbox
										data-cy={getCyKey(SignUpMainDialog, 'checkbox1')}
										className={classes.checkbox}
										onClick={() => setIsTermsOfService(!isTermsOfService)}
										checked={isTermsOfService}
									/>
									<Typography variant="caption" className={classes.checkboxText}>
										I Have read and accept the WURRLY{' '}
										<BaseLink href="https://www.wurrlyedu.com/terms">
											<Typography variant="caption">Terms of Service.</Typography>
										</BaseLink>
									</Typography>{' '}
								</Box>
								<Box>
									<Checkbox
										data-cy={getCyKey(SignUpMainDialog, 'checkbox2')}
										className={classes.checkbox}
										onClick={() => setIsPrivacyPolicy(!isPrivacyPolicy)}
										checked={isPrivacyPolicy}
									/>
									<Typography variant="caption" className={classes.checkboxText}>
										I Have read, understand and accept the WURRLY{' '}
										<BaseLink href="https://www.wurrlyedu.com/privacy">
											<Typography variant="caption">Privacy Policy.</Typography>
										</BaseLink>
									</Typography>{' '}
								</Box>
							</Fragment>
						)}
					</Fragment>
				}
				footer={
					<Fragment>
						{isLogin ? (
							<Typography style={{ margin: 0 }}>
								Don't have an account?{' '}
								<b
									data-cy={getCyKey(SignUpMainDialog, 'btnSignUp')}
									onClick={goToSignup}
									style={{ cursor: 'pointer' }}>
									Sign Up
								</b>
							</Typography>
						) : (
							<Typography style={{ margin: 0 }}>
								Already have an account?{' '}
								<b
									data-cy={getCyKey(SignUpMainDialog, 'btnGoLogIn')}
									style={{ cursor: 'pointer' }}
									onClick={goToLogIn}>
									Sign In
								</b>
							</Typography>
						)}
					</Fragment>
				}
				onConfirmProps={{
					style: {
						margin: '0 auto',
						textTransform: 'capitalize',
						fontWeight: 'bold',
						padding: '6px 30px'
					}
				}}
				isConfirmDisabled={
					(!isLogin && !isTermsOfService) ||
					(!isLogin && !isPrivacyPolicy) ||
					(!isLogin && !passwordFulfillRequirements) ||
					(!isLogin && createPassword !== confirmPassword) ||
					(!isLogin && emailAlreadyExists) ||
					(!isLogin && !validateEmail(signupEmail)) ||
					(!isLogin && !signupLastName.length) ||
					(!isLogin && !signupFirstName.length) ||
					(isLogin && loginTeacherLoading) ||
					(isLogin && (loginEmail.length === 0 || loginPassword.length === 0)) ||
					loadingIntegration
				}
				isConfirmLoading={teacherEmailExistLoading}
				confirmLabel={isLogin ? 'Sign In' : 'Sign Up'}
				typographyProps={{ style: { fontWeight: 'bold' } }}
				footerProps={{ style: { marginTop: '15px' } }}
				onConfirm={async () => {
					if (isLogin) loginTeacher({ variables: { email: loginEmail, password: loginPassword } })
					if (!isLogin) {
						teacherEmailExists({ variables: { email: signupEmail } })
					}
				}}
			/>
			<RecoverPasswordMailSendDialog
				open={resetPasswordDialogOpen}
				isTeacher
				closeDialog={() => {
					setResetPasswordDialogOpen(false)
					setSignUpDialogOpen(true)
				}}
			/>
		</Fragment>
	)
}
