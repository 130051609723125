import { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { useUpdateTeacherInformationMutation } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import * as yup from 'yup'

import { buildGenericContext } from '../../../../../../context/genericContext'

type formData = {
	teacherId: number
	firstName: string
	lastName: string
	currentPassword: string
	newPassword: string
}

const useEditProfileData = () => {
	const { teacherData } = useLoginContext()
	const [newPasswordIsValid, setNewPasswordIsValid] = useState(false)
	const [passwordsAreValid, setPasswordsAreValid] = useState(false)
	const [updateError, setUpdateError] = useState(false)
	const [updateSuccess, setUpdateSuccess] = useState(false)
	const [validateCurrentPassword, setValidateCurrentPassword] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [updateInfo, { loading: isLoading }] = useUpdateTeacherInformationMutation({ fetchPolicy: 'no-cache' })

	const handelClose = () => {
		setValidateCurrentPassword(false)
	}
	const saveChanges = async (filter: formData) => {
		try {
			const { data } = await updateInfo({
				variables: filter,
				update: (cache) => {
					cache.evict({ id: cache.identify(teacherData) })
					cache.gc()
				}
			})
			const updated = !!data?.editTeacherInformation
			setUpdateSuccess(updated)
			setUpdateError(!updated)
		} catch (error) {
			console.error(error)
			setErrorMessage(error.message ?? 'Internal error.')
			setUpdateError(true)
		}
	}

	const validationSchema = yup.object({
		firstName: yup.string().required('FirstName is required'),
		lastName: yup.string().required('LastName is required')
	})

	const formik = useFormik({
		initialValues: {
			teacherId: teacherData.teacher_id,
			firstName: teacherData.name_first ?? '',
			lastName: teacherData.name_last ?? '',
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: ''
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async (variables, { resetForm }) => {
			saveChanges(variables)
			resetForm()
		}
	})

	useEffect(() => {
		if (!formik.values.newPassword.length && !formik.values.confirmNewPassword.length)
			setPasswordsAreValid(formik.isValid)
		else
			setPasswordsAreValid(
				newPasswordIsValid &&
					formik.isValid &&
					formik.values.newPassword === formik.values.confirmNewPassword
			)
	}, [newPasswordIsValid, formik.values, formik.isValid])

	return {
		newPasswordIsValid,
		setNewPasswordIsValid,
		saveChanges,
		passwordsAreValid,
		updateError,
		setUpdateError,
		errorMessage,
		updateSuccess,
		isLoading,
		formik,
		validateCurrentPassword,
		setValidateCurrentPassword,
		handelClose
	}
}

export const [EditProfileProvider, useEditProfileContext] = buildGenericContext(useEditProfileData)
