import React from 'react'

import {
	Avatar,
	Box,
	ButtonProps,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteIcon from '@material-ui/icons/Delete'
import GroupIcon from '@material-ui/icons/Group'

import { ReInviteIcon } from '../../../../../../../assets/icons'
import { ActionButton, BaseButton, SmallBaseButton } from '../../../../../../../components'
import { getCyKey } from '../../../../../../../utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		itemTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word'
		}
	})
)

export type ListItemStudentProps = {
	imageUrl: string
	name: string
	lastName: string
	email?: string
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	btnStudentClasses?: ButtonProps
	btnStudentDelete?: ButtonProps
	btnStudentView?: ButtonProps
	btnStudentReinvite?: ButtonProps
	btnAddClass?: ButtonProps
	isActive: boolean
}

export const ListItemStudent = ({
	imageUrl,
	name,
	lastName,
	email,
	btnStudentClasses,
	btnStudentDelete,
	btnStudentView,
	btnStudentReinvite,
	btnAddClass,
	isActive
}: ListItemStudentProps) => {
	const classes = useStyles()

	return (
		<ListItem className={classes.listItem}>
			<ListItemAvatar>
				<Avatar style={{ opacity: isActive ? 1 : 0.5 }} variant="circular" src={imageUrl} />
			</ListItemAvatar>
			<ListItemText
				disableTypography
				style={{ opacity: isActive ? 1 : 0.5 }}
				primary={
					<div>
						<Typography className={classes.itemTitle} variant="h6" style={{ fontSize: 18 }}>
							<b data-cy={getCyKey(ListItemStudent, 'Name')}>{`${name} ${lastName}`}</b>
						</Typography>
					</div>
				}
				secondary={
					<Typography variant="caption" color="textSecondary">
						<b>{email}</b>
					</Typography>
				}
			/>
			<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
				<div style={{ flexGrow: 1 }} />
				{btnStudentReinvite && (
					<Tooltip title="Re-Invite">
						<ActionButton
							data-cy={getCyKey(ListItemStudent, 'btnReinviteStudent')}
							{...btnStudentReinvite}
							icon={<ReInviteIcon />}
						/>
					</Tooltip>
				)}

				{btnStudentClasses && (
					<Tooltip title="Student Classes">
						<ActionButton {...btnStudentClasses} icon={<GroupIcon />} />
					</Tooltip>
				)}

				{btnStudentDelete && (
					<Tooltip title="Remove">
						<ActionButton
							data-cy={getCyKey(ListItemStudent, 'btnRemoveStudent')}
							icon={<DeleteIcon fontSize="small" />}
							{...btnStudentDelete}
						/>
					</Tooltip>
				)}
				{btnAddClass && (
					<Tooltip title="Add to class">
						<SmallBaseButton color="primary" className={'addClass'} endIcon={<Add />} {...btnAddClass}>
							<Typography>Add to class</Typography>
						</SmallBaseButton>
					</Tooltip>
				)}
				{btnStudentView && (
					<Tooltip title="View">
						<BaseButton
							color="primary"
							className={'addClass'}
							endIcon={<ArrowForwardIcon />}
							{...btnStudentView}>
							<Typography>View</Typography>
						</BaseButton>
					</Tooltip>
				)}
			</Box>
		</ListItem>
	)
}
