import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
	Box,
	Divider,
	List,
	Paper,
	Typography,
	ListItem,
	Checkbox,
	ListItemIcon,
	ListItemText,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	CircularProgress,
	PaperProps
} from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { FilterItemType } from 'src/@types'
import { useGetScalesQuery } from 'src/graphql/autogenerate/hooks'
import { Scale, Catalog } from 'src/graphql/autogenerate/schemas'
import { QueryCatalogs, QueryCatalogsType } from 'src/scenes/Teacher/queries'
import { capitalize, TypeEnum } from 'src/utils'

import { FilterNameEnum } from '../../../../../../../../../3-MusicCatalog/scenes/enums'
import useStyles from './SongFilter.styles'

export type SongFilterProps = {
	withVocalGuide: boolean
	withChordGuide: boolean
	toggleVocalGuide: () => void
	toggleChordGuide: () => void
	selectedFilters: number[]
	setSelectedFilters: (selectedFilters: number[]) => void
	scale: number
	setScale: (scale: number) => void
	selectedCatalog?: Catalog
	paperProps?: PaperProps
}

export const SongFilter = ({
	withVocalGuide,
	withChordGuide,
	toggleVocalGuide,
	toggleChordGuide,
	selectedFilters,
	setSelectedFilters,
	scale,
	setScale,
	selectedCatalog,
	paperProps
}: SongFilterProps) => {
	const styles = useStyles()
	const [filters, setFilters] = useState<FilterItemType[]>([])
	const [scales, setScales] = useState<Scale[]>([])
	const scalesData = useGetScalesQuery()
	const secondaryFilters = useQuery<QueryCatalogsType>(QueryCatalogs(TypeEnum.Song))

	const handleFilter = (filterId: number) => {
		const arrCopy = [...selectedFilters]
		if (selectedFilters.includes(filterId)) {
			arrCopy.splice(arrCopy.indexOf(filterId), 1)
		} else {
			arrCopy.push(filterId)
		}
		setSelectedFilters(arrCopy)
	}
	const handleScale = (event: React.ChangeEvent<HTMLInputElement>) => {
		setScale(+(event.target as HTMLInputElement).value)
	}

	// Format filters
	useEffect(() => {
		if (!secondaryFilters.loading && secondaryFilters.data) {
			const { catalog } = secondaryFilters.data

			const tmpcatalog = catalog.map((itemCatalog) => ({
				name: capitalize(itemCatalog.name),
				items: itemCatalog.catalog_items.map((el) => ({
					id: el.catalog_item_id,
					label: el.name,
					selected: false
				}))
			}))
			setFilters(tmpcatalog)
		}
	}, [secondaryFilters])

	// Get scales
	useEffect(() => {
		if (!scalesData.loading && scalesData.data) {
			setScales(scalesData.data.scale)
		}
	}, [scalesData])

	return (
		<Paper {...paperProps}>
			<Box py={2} px={3}>
				<Typography variant="h6" className={styles.title}>
					Filter
				</Typography>
			</Box>

			<Divider />
			<Box py={1} px={3}>
				<List dense>
					<Typography variant="h6" className={styles.title}>
						Guides
					</Typography>

					<ListItem button disableGutters onClick={toggleVocalGuide}>
						<ListItemIcon className={styles.listItemIcon}>
							<Checkbox
								edge="start"
								checked={withVocalGuide}
								checkedIcon={<CheckBoxOutlinedIcon />}
								inputProps={{ 'aria-labelledby': 'vocalGuide' }}
							/>
						</ListItemIcon>
						<ListItemText id="vocalGuide" primary="With Vocal Guide" />
					</ListItem>

					<ListItem button disableGutters onClick={toggleChordGuide}>
						<ListItemIcon className={styles.listItemIcon}>
							<Checkbox
								edge="start"
								checked={withChordGuide}
								checkedIcon={<CheckBoxOutlinedIcon />}
								inputProps={{ 'aria-labelledby': 'chordGuide' }}
							/>
						</ListItemIcon>
						<ListItemText id="chordGuide" primary="With Chord Guide" />
					</ListItem>
				</List>

				{filters.map(
					(filter) =>
						filter.name.toLowerCase() !== selectedCatalog?.name.toLowerCase() && (
							<List key={`filter-${filter.name}`} dense>
								<Typography variant="h6" className={styles.title}>
									{filter.name}
								</Typography>
								{filter.items.map((item) => (
									<ListItem
										key={item.id}
										button
										disableGutters
										onClick={() => handleFilter(item.id)}>
										<ListItemIcon className={styles.listItemIcon}>
											<Checkbox
												edge="start"
												checked={selectedFilters.includes(item.id)}
												checkedIcon={<CheckBoxOutlinedIcon />}
												disableRipple
												inputProps={{ 'aria-labelledby': item.label }}
											/>
										</ListItemIcon>
										<ListItemText id={item.label} primary={item.label} />
									</ListItem>
								))}
								{secondaryFilters.loading && <CircularProgress color="secondary" />}
							</List>
						)
				)}

				<FormControl component="fieldset" className={styles.modeFields}>
					<Typography variant="h6" className={styles.title}>
						{FilterNameEnum.Modes}
					</Typography>
					<RadioGroup aria-label="select" name="select" value={scale} onChange={handleScale}>
						{scales.map((item) => (
							<ListItem key={item.scale_id} button disableGutters>
								<FormControlLabel
									className={styles.modeFieldLabel}
									value={item.scale_id}
									control={<Radio disableRipple />}
									label={item.name}
								/>
							</ListItem>
						))}
						{scalesData.loading && <CircularProgress color="secondary" />}
					</RadioGroup>
				</FormControl>
			</Box>
		</Paper>
	)
}
