import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStudentInvitationDetailsDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		description: {
			paddingTop: 12,
			paddingBottom: 12,
			paddingRight: 10,
			paddingLeft: 10,
			fontSize: 18,
			fontWeight: 'normal'
		},
		integrationHolder: {
			width: '100%',
			border: '1px solid rgba(0,0,0,0.2)',
			padding: '13px 0',
			borderRadius: 4,
			marginTop: 40
		},
		integrationImage: {
			width: 30,
			height: 30,
			marginRight: 20
		},
		integrationText: {
			fontWeight: 'bold',
			textTransform: 'capitalize'
		},
		cursorPointer: {
			cursor: 'pointer'
		},
		studentCode: {
			marginTop: 10,
			marginBottom: 10,
			width: '100%'
		},
		disabledSendEmail: {
			'& path': {
				fill: theme.palette.grey[400],
				stroke: theme.palette.grey[400]
			}
		}
	})
)

export default useStudentInvitationDetailsDialogStyles
