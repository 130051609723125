import { useState } from 'react'

import ScheduleIcon from '@material-ui/icons/Schedule'
import { TimePicker, TimePickerProps } from '@material-ui/pickers'

import useStyles from './TimeInput.styles'

const DateInput = (props: TimePickerProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const styles = useStyles()

	const handleOpen = () => setIsOpen(true)
	const handleClose = () => setIsOpen(false)

	return (
		<TimePicker
			format="LT"
			variant="inline"
			inputVariant="outlined"
			className={styles.timeField}
			placeholder="Pick Time"
			color="secondary"
			onOpen={handleOpen}
			onClose={handleClose}
			PopoverProps={{
				className: styles.selector,
				anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
				transformOrigin: { horizontal: 'right', vertical: 'bottom' }
			}}
			InputProps={{
				endAdornment: <ScheduleIcon color={isOpen ? 'secondary' : 'action'} />
			}}
			{...props}
		/>
	)
}

export default DateInput
