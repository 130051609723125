import { useCallback, useEffect, useState } from 'react'

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import { IName, Page } from 'src/@types'
import { EditPencilIcon } from 'src/assets/icons'
import { BigBaseButton, DropdownInput, InfoDialog, TextInput } from 'src/components'
import { MessageBox } from 'src/components/MessageBox/MessageBox'
import ReactionsBox from 'src/components/Reactions/Reactions'
import { GradeSquare } from 'src/components/Squares/GradeSquare/GradeSquare'
import {
	useDeleteWurrlyMutation,
	useUpdateWurrlyMessageAndVisibilityMutation
} from 'src/graphql/autogenerate/hooks'
import { Submission_State_Enum, Privacy_Enum, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { ClassWurrlyDetails } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/components/ClassWurrlyDetails/ClassWurrlyDetails'
import { ClassWurrlyTitle } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/components/ClassWurrlyTitle'
import { useClassWurrlyContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'
import { PRIVACY_ARRAY } from 'src/utils'
import { PRIVACY_LABELS } from 'src/utils/constants'

import useStyles from './indieWurrlyPage.styles'

export const IndieWurrlyPage = (page: Page, { practice = false }: Page) => {
	const styles = useStyles()
	const { wurrlyClass, wurrly, submissionState, owner } = useClassWurrlyContext()
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const history = useHistory()
	const [editMessage, setEditMessage] = useState(false)
	const [editedMessage, setEditedMessage] = useState('')
	const [privacyOption, setPrivacyOption] = useState<IName | null>(null)

	const [deleteWurrly, { data: deleteData, error: deleteError, loading: deleteLoading }] =
		useDeleteWurrlyMutation()
	const [updateWurrly, { data: updateData, error: updateError, loading: updateLoading }] =
		useUpdateWurrlyMessageAndVisibilityMutation()
	const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)
	const [isDeleteSuccessDialogOpen, setIsDeleteSuccessDialogOpen] = useState(false)
	const [isDeleteErrorDialogOpen, setIsDeleteErrorDialogOpen] = useState(false)
	const [isUpdateErrorDialogOpen, setIsUpdateErrorDialogOpen] = useState(false)
	const [isUpdateSuccessDialogOpen, setIsUpdateSuccessDialogOpen] = useState(false)

	const studentIsWurrlyCreator = studentId === wurrly?.student?.student_id

	// Set wurrly message in edit mode
	useEffect(() => {
		if (wurrly?.message) setEditedMessage(wurrly?.message)
	}, [wurrly?.message])

	// Open dialogs in case of an error while deleting the wurrly
	useEffect(() => {
		if (deleteData) {
			setIsDeleteSuccessDialogOpen(true)
		} else if (deleteError) {
			setIsDeleteErrorDialogOpen(true)
		}
	}, [deleteData, deleteError])

	// Set privacy default option
	useEffect(() => {
		if (wurrly?.privacy) setPrivacyOption(PRIVACY_ARRAY.find((i) => i.name === wurrly.privacy) || null)
	}, [wurrly?.privacy])

	// Open update details dialog
	useEffect(() => {
		if (updateData) {
			setIsUpdateSuccessDialogOpen(true)
		} else if (updateError) {
			setIsUpdateErrorDialogOpen(true)
		}
	}, [updateData, updateError])

	// Go to top when unmounted
	useEffect(() => {
		return () => {
			window.scrollTo(0, 0)
		}
	}, [])

	const goToPreviousPage = () => {
		history.goBack()
	}
	const handleClose = () => {
		setConfirmDeleteDialogOpen(false)
	}
	const handleDiscard = () => {
		setConfirmDeleteDialogOpen(false)
	}
	const handleConfirm = () => {
		if (wurrly?.wurrly_id) deleteWurrly({ variables: { wurrlyId: wurrly.wurrly_id } })
		setConfirmDeleteDialogOpen(false)
	}
	const handleSuccessClose = () => {
		setIsDeleteSuccessDialogOpen(false)
		goToPreviousPage()
	}
	const handleSuccessDiscard = () => {
		setIsDeleteSuccessDialogOpen(false)
		goToPreviousPage()
	}
	const handleSuccessConfirm = () => {
		setIsDeleteSuccessDialogOpen(false)
		goToPreviousPage()
	}
	const handleErrorClose = () => {
		setIsDeleteErrorDialogOpen(false)
	}
	const handleErrorConfirm = () => {
		setIsDeleteErrorDialogOpen(false)
	}

	const InfoDialogs = useCallback(() => {
		return (
			<>
				<InfoDialog
					open={confirmDeleteDialogOpen}
					onClose={handleClose}
					icon="?"
					title={
						<Typography variant="h4">
							<b>Are you sure you want to delete this wurrly?</b>
						</Typography>
					}
					body=""
					discardLabel="Go Back"
					onDiscard={handleDiscard}
					confirmLabel="Delete"
					onConfirm={handleConfirm}
				/>
				<InfoDialog
					open={isDeleteSuccessDialogOpen}
					onClose={handleSuccessClose}
					icon={<CheckIcon />}
					title={
						<Typography variant="h4">
							<b>Wurrly Deleted!</b>
						</Typography>
					}
					body="You have successfully deleted this wurrly."
					onDiscard={handleSuccessDiscard}
					confirmLabel="Done"
					onConfirm={handleSuccessConfirm}
				/>
				<InfoDialog
					open={isDeleteErrorDialogOpen}
					onClose={handleErrorClose}
					icon={<CloseIcon />}
					title="Something went wrong"
					body="An unexpected error occurred while deleting this wurrly."
					confirmLabel="Done"
					confirmProps={{ style: { fontWeight: 'bold' } }}
					discardProps={{ style: { fontWeight: 'bold' } }}
					onConfirm={handleErrorConfirm}
				/>
				<InfoDialog
					open={isUpdateSuccessDialogOpen}
					onClose={() => setIsUpdateSuccessDialogOpen(false)}
					icon={<CheckIcon />}
					title={
						<Typography variant="h4">
							<b>Wurrly Updated!</b>
						</Typography>
					}
					body="You have successfully updated this wurrly."
					onDiscard={() => setIsUpdateSuccessDialogOpen(false)}
					confirmLabel="Done"
					onConfirm={() => setIsUpdateSuccessDialogOpen(false)}
				/>
				<InfoDialog
					open={isUpdateErrorDialogOpen}
					onClose={() => setIsUpdateErrorDialogOpen(false)}
					icon={<CloseIcon />}
					title="Something went wrong"
					body="An unexpected error occurred while updating this wurrly."
					confirmLabel="Done"
					confirmProps={{ style: { fontWeight: 'bold' } }}
					discardProps={{ style: { fontWeight: 'bold' } }}
					onConfirm={() => setIsUpdateErrorDialogOpen(false)}
				/>
			</>
		)
	}, [
		confirmDeleteDialogOpen,
		isDeleteSuccessDialogOpen,
		isDeleteErrorDialogOpen,
		isUpdateErrorDialogOpen,
		isUpdateSuccessDialogOpen
	])

	const hasDelete = submissionState !== Submission_State_Enum.Graded && owner

	return (
		<>
			<ClassWurrlyTitle
				page={page}
				title={wurrlyClass?.title || ''}
				onDelete={hasDelete ? () => setConfirmDeleteDialogOpen(true) : undefined}
				loadingDelete={deleteLoading}
			/>
			<ClassWurrlyDetails
				practice={page.practice}
				footer={
					<>
						{editMessage || practice ? (
							<Box>
								<TextInput
									name="Edit Caption"
									value={editedMessage}
									onChange={(e) => setEditedMessage(e.target.value)}
									placeholder="Enter Caption"
								/>
							</Box>
						) : (
							<MessageBox className={styles.messageBox} text={wurrly?.message || ''}>
								{studentIsWurrlyCreator && (
									<Box className={styles.editIcon} onClick={() => setEditMessage(true)}>
										<EditPencilIcon />
									</Box>
								)}
							</MessageBox>
						)}
						<Box mt={2}>
							<Divider />
						</Box>
						{page.showReact && <ReactionsBox />}
						{page.grade && (
							<Box>
								{submissionState === Submission_State_Enum.Graded ? (
									<Box className={styles.grade}>
										<Box className={styles.relative}>
											<GradeSquare
												grade={wurrly.submission_wurrly?.submission?.grade || ''}
											/>
										</Box>
										{wurrly.submission_wurrly?.submission?.teacher_comment && (
											<Box
												display={'flex'}
												flexDirection={'column'}
												className={styles.teacherComment}>
												<Typography className={styles.fieldTitle} style={{ marginTop: 0 }}>
													<b>Teacher’s comment</b>
												</Typography>
												<Typography>
													{wurrly.submission_wurrly?.submission?.teacher_comment}
												</Typography>
											</Box>
										)}
									</Box>
								) : (
									<Box></Box>
								)}
							</Box>
						)}
						{wurrly?.teacher_wurrly_comments.length > 0 &&
							wurrly?.teacher_wurrly_comments[0].comment &&
							wurrly.wurrly_type !== Wurrly_Type_Enum.Assignment && (
								<>
									<Box>
										<Typography className={styles.fieldTitle}>
											<b>Teacher’s comment</b>
										</Typography>

										<Box className={styles.grade}>
											<Typography>{wurrly.teacher_wurrly_comments[0]?.comment}</Typography>
										</Box>
									</Box>
								</>
							)}

						{page.visibility && studentIsWurrlyCreator && (
							<DropdownInput
								id="visibility"
								name="Visibility"
								options={PRIVACY_ARRAY}
								value={privacyOption}
								onChange={(_, selectedItem: IName | null) => {
									if (selectedItem) setPrivacyOption(selectedItem)
								}}
								getOptionLabel={(option) => PRIVACY_LABELS[option.name as Privacy_Enum]}
								defaultValue={privacyOption}
							/>
						)}
					</>
				}
				footerButtons={
					<>
						<BigBaseButton
							disabled={updateLoading}
							className={styles.button}
							variant="contained"
							color="default"
							onClick={goToPreviousPage}>
							Go Back
						</BigBaseButton>
						{studentIsWurrlyCreator && (
							<BigBaseButton
								disabled={updateLoading}
								className={styles.button}
								color="secondary"
								onClick={() => {
									if (wurrly?.wurrly_id && privacyOption)
										updateWurrly({
											variables: {
												wurrlyId: wurrly.wurrly_id,
												privacyId: privacyOption.name as Privacy_Enum,
												message: editedMessage
											}
										})
								}}>
								Save Changes
								{updateLoading && <CircularProgress color="secondary" />}
							</BigBaseButton>
						)}
					</>
				}
			/>
			<InfoDialogs />
		</>
	)
}
