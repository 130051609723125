import { createStyles, makeStyles } from '@material-ui/core'

export const useTrimmerScrubberStyles = makeStyles(() =>
	createStyles({
		container: {
			position: 'absolute',
			bottom: 0,
			width: '100%',
			padding: '10px 40px',
			height: '100px',
			background: '#FFFFFFE6',
			zIndex: 10
		},
		innerBar: {
			position: 'relative',
			width: '100%',
			height: '100%'
		},
		closeButton: {
			position: 'absolute',
			top: '3px',
			right: '3px',
			width: '15px',
			height: '15px'
		}
	})
)

export default useTrimmerScrubberStyles
