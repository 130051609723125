import { createStyles, fade, makeStyles, Theme } from '@material-ui/core'

export const useListAsignmentsStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxAssignments: {
			padding: 0,
			'& .itemBox': {
				backgroundColor: theme.palette.common.white,
				'&:not(:last-child)': {
					marginBottom: theme.spacing(1)
				}
			}
		},
		boxLoading: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			zIndex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: fade(theme.palette.common.black, 0.1)
		}
	})
)

export default useListAsignmentsStyles
