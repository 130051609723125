import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { SubscriptionsCardType } from 'src/@types'
import {
	useCancelProductSubscriptionMutation,
	useGetModulesLazyQuery,
	useGetTeacherSubscriptionsLazyQuery
} from 'src/graphql/autogenerate/hooks'
import {
	CancelProductSubscriptionMutation,
	GetTeacherSubscriptionsQuery
} from 'src/graphql/autogenerate/operations'
import {
	Module,
	Order,
	Order_Status_Enum,
	Price_Type_Enum,
	Product_Name_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import {
	buildBreadCrumbs,
	buildRouteParameters,
	buildSearchText,
	createSuscriptionCardData,
	MAX_LIST_ITEMS,
	orderBySortOrder,
	SortOrder
} from 'src/utils'

export const useGetModules = () => {
	const history = useHistory()
	const { teacherData } = useLoginContext()
	const [searchbarText, setSearchbarText] = useState('')
	const [moduleId, setModuleId] = useState<number>()
	const [addToClassModule, setAddToClassModule] = useState<Module>()
	const [isAddModuleToClassOpen, setIsAddModuleToClassOpen] = useState(false)
	const showSubscriptions = history.location.search === '?from=subs'
	const [sort, setSort] = useState(SortOrder.Up)
	const [filter, setFilter] = useState('None')
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [isOpen, setIsOpen] = useState(false)
	const [totalModules, setTotalModules] = useState<number>()
	const [moduleSuscription, setModuleSuscription] = useState<SubscriptionsCardType[]>([])
	const [modules, setModules] = useState<Module[]>([])
	const limit = MAX_LIST_ITEMS
	const [searchTeacherSubscriptionsData, setSearchTeacherSubscriptionsData] = useState<SubscriptionsCardType[]>(
		[]
	)
	const [unSuscribeOpenDialog, setUnSuscribeOpenDialog] = useState(false)
	const [item, setItem] = useState<SubscriptionsCardType>()
	const [onCompleteRes, setOnCompleteRes] = useState<boolean | undefined>(undefined)

	useEffect(() => {
		setIsFetching(true)
		setMoreResults(true)
	}, [])
	const breadcrumbs = buildBreadCrumbs({
		page: showSubscriptions ? Pages.ModulesSubs : Pages.Modules
	})

	const INIT_VARIABLES = {
		search: buildSearchText(searchbarText, ['title', 'description']),
		teacher_id: teacherData.teacher_id,
		offset: 0,
		limit,
		order_by: {
			title: orderBySortOrder[sort]
		}
	}
	const [cancelProductSubscription] = useCancelProductSubscriptionMutation()

	const [
		getTeacherSubscriptions,
		{ data: suscriptionData, loading: moduleSuscriptionLoading, refetch: teacherSubscriptionsFefetch }
	] = useGetTeacherSubscriptionsLazyQuery()
	const [getSearchTeacherSubscriptions, { data: searchSuscriptionData, loading: searchSuscriptionDataLoading }] =
		useGetTeacherSubscriptionsLazyQuery()

	const [getModules, { data: modulesData, loading: modulesLoading, refetch }] = useGetModulesLazyQuery()

	const [getSearchModules, { data: searchbarModulesData, loading: searchbarModulesLoading }] =
		useGetModulesLazyQuery()

	useEffect(() => {
		if (showSubscriptions && searchSuscriptionData) {
			tranformSuscriptionData(searchSuscriptionData, true)
		}
	}, [searchSuscriptionData])

	useEffect(() => {
		setIsFetching(false)
		if (suscriptionData) {
			setTotalModules(suscriptionData?.order_aggregate.aggregate?.count || 0)
			tranformSuscriptionData(suscriptionData, false)
		}
		if (modulesData) {
			setTotalModules(modulesData.module_aggregate.aggregate?.count || 0)
			setModules((module) => [...module, ...((modulesData?.module ?? []) as Module[])])
		}
	}, [suscriptionData, modulesData])

	useEffect(() => {
		if (showSubscriptions) {
			setSearchTeacherSubscriptionsData([])
			getSearchTeacherSubscriptions({
				variables: {
					limit,
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Module,
					order_by: {
						module: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(searchbarText, ['title', 'description'], Product_Name_Enum.Module)
				}
			})
		} else {
			getSearchModules({
				variables: INIT_VARIABLES
			})
		}
	}, [searchbarText])

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setMoreResults(true)
		if (showSubscriptions) {
			setModuleSuscription([])
			getTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Module,
					order_by: {
						module: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(searchbarText, ['title', 'description'], Product_Name_Enum.Module),
					price_type: filter !== 'None' ? (filter as Price_Type_Enum) : undefined
				}
			})
		} else {
			setModules([])
			getModules({
				variables: INIT_VARIABLES
			})
		}
	}, [sort, filter])

	useEffect(() => {
		if (!isFetching || !moreResults) return
		if (showSubscriptions) {
			const currentLength = moduleSuscription.length
			teacherSubscriptionsFefetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.order.length !== 0 && fetchMoreResult.data?.order.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		} else {
			const currentLength = modules.length
			refetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.module.length !== 0 && fetchMoreResult.data?.module.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	useEffect(() => {
		if (item) {
			setUnSuscribeOpenDialog(true)
		}
	}, [item])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const handleSearchbarTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchbarText(e.target.value)
	}

	const onSearchEnter = (searchbarText: string) => {
		setMoreResults(true)
		if (showSubscriptions) {
			if (searchbarText) setModuleSuscription([])
			setFilter('None')
			setSort(SortOrder.Up)
			getTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Module,
					order_by: {
						module: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(searchbarText, ['title', 'description'], Product_Name_Enum.Module)
				}
			})
		} else {
			setModules([])
			getModules({
				variables: {
					...INIT_VARIABLES,
					search: buildSearchText(searchbarText, ['title', 'description'])
				}
			})
		}
	}

	const onFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const selectedFilter = event.target.value as Price_Type_Enum
		setFilter(selectedFilter)
	}

	const tranformSuscriptionData = (data: GetTeacherSubscriptionsQuery, isSearchData: boolean) => {
		data.order.map((item) => {
			const order = item as Order
			if (isSearchData) {
				setSubscriptionSearchData(createSuscriptionCardData(searchTeacherSubscriptionsData, order))
			} else {
				setSubscriptionData(createSuscriptionCardData(moduleSuscription, order))
			}
		})
	}

	const setSubscriptionSearchData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setSearchTeacherSubscriptionsData((subscription) => [...subscription, data])
		}
	}
	const setSubscriptionData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setModuleSuscription((subscription) => [...subscription, data])
		}
	}

	const goToModule = (moduleId: number) => {
		history.push(buildRouteParameters(Pages.ModuleDetails, { moduleId }))
	}

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)
	const onUnsubscribe = async (stripeSubcriptiptionId: string) => {
		return new Promise<CancelProductSubscriptionMutation>((resolve, reject) => {
			cancelProductSubscription({
				variables: {
					teacherId: teacherData.teacher_id,
					stripeSubcriptiptionId
				},
				onCompleted: (res) => {
					setOnCompleteRes(res?.cancelProductSubscription?.success)
					resolve(res)
				},
				onError: () => {
					reject(new Error('Unsubscribe error'))
				}
			})
		})
	}

	const handleRefetch = (stripeSubcriptiptionId: string) => {
		if (onCompleteRes) {
			const moduleSuscriptionItem = moduleSuscription.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			const searchTeacherSubscriptionsDataItem = searchTeacherSubscriptionsData.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			if (moduleSuscriptionItem) {
				moduleSuscriptionItem.status = Order_Status_Enum.Canceled
			}
			if (searchTeacherSubscriptionsDataItem) {
				searchTeacherSubscriptionsDataItem.status = Order_Status_Enum.Canceled
			}
		}
	}

	return {
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		setModuleId,
		modules,
		modulesLoading,
		moduleSuscriptionLoading,
		searchbarText,
		searchbarModulesData,
		searchbarModulesLoading,
		onSearchEnter,
		sort,
		isOpen,
		moduleId,
		handleSort,
		showSubscriptions,
		setAddToClassModule,
		setIsAddModuleToClassOpen,
		addToClassModule,
		breadcrumbs,
		isAddModuleToClassOpen,
		onFilterChange,
		filter,
		searchSuscriptionDataLoading,
		moduleSuscription,
		searchTeacherSubscriptionsData,
		totalModules,
		goToModule,
		onUnsubscribe,
		isFetching,
		moreResults,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	}
}
