import { createStyles, makeStyles } from '@material-ui/core'

export const useModulesCardStyles = makeStyles(() =>
	createStyles({
		text: {
			fontSize: '0.75rem',
			marginTop: '1px',
			marginLeft: '2px'
		},
		locked: {
			backgroundColor: '#353550',
			border: '2px solid #221F31'
		},
		check: {
			width: '120px !important',
			backgroundColor: '#BDEAD7',
			border: '2px solid #ADCEC1'
		},
		disabled: {
			width: '135px !important',
			backgroundColor: '#FFFFFF',
			border: '2px solid #F3AB34'
		},
		button: {
			borderRadius: '100px',
			marginTop: '10px',
			width: '100px',
			height: '27px'
		},
		openLookIcon: {
			backgroundColor: '#ADCFF1',
			border: '2px solid #89CFF0'
		}
	})
)

export default useModulesCardStyles
