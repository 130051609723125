import { useEffect, useState } from 'react'

import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { ActivityBoxEmpty, PaginationList, PaginationListDataItem, SelectSort } from 'src/components'
import { buildStudentPointsSummaryPaginationItem } from 'src/components/PaginationList/utils'
import { useClassDetailsContext } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/useClassDetails'
import { SortOrder } from 'src/utils'

export const ClassStudentBadgeSummary = () => {
	const { badgeTotalData, loadingBadgeData, refetch, pageElements } = useClassDetailsContext()
	const [activityItems, setActivityItems] = useState<PaginationListDataItem[]>([])
	const [loadingStudents, setLoadingStudents] = useState<boolean>(false)
	const [sort, setSort] = useState<SortOrder>(SortOrder.DateAsc)
	const [studentData, setStudentData] = useState<{ image_path: string; full_name: string; points: number }[]>([])

	useEffect(() => {
		setLoadingStudents(loadingBadgeData)
		if (badgeTotalData) {
			let badgeCardData: PaginationListDataItem[] = []

			badgeTotalData.student.map((i) =>
				studentData.push({
					image_path: i?.image_path ?? '',
					full_name: i.full_name ?? '',
					points: Object.values(i.student_badges).reduce((a, b) => a + b.badge.points, 0)
				})
			)

			setStudentData(studentData)

			studentData.sort((a, b) => {
				return b.points - a.points
			})

			badgeCardData = studentData.map((student) => buildStudentPointsSummaryPaginationItem(student))
			setActivityItems(badgeCardData)
		}
	}, [badgeTotalData, loadingBadgeData])

	const handlePageChange = (page: number) => {
		setLoadingStudents(true)
		refetch({ offset: (page - 1) * pageElements })
	}

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	useEffect(() => {
		let badgeCardData: PaginationListDataItem[] = []
		if (sort === SortOrder.DateAsc) {
			studentData.sort((a, b) => {
				return b.points - a.points
			})
		}
		if (sort === SortOrder.DateDesc) {
			studentData.sort((a, b) => {
				return a.points - b.points
			})
		}
		if (sort === SortOrder.Up) {
			studentData.sort(function (a, b) {
				if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
					return -1
				}
				if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
					return 1
				}

				return 0
			})
		}
		if (sort === SortOrder.Down) {
			studentData.sort(function (a, b) {
				if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
					return 1
				}
				if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
					return -1
				}

				return 0
			})
		}
		badgeCardData = studentData.map((student) => buildStudentPointsSummaryPaginationItem(student))
		setActivityItems(badgeCardData)
	}, [sort])

	return activityItems.length > 0 ? (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Leaderboard</b>
				</Typography>
				<SelectSort cyKey="StudentAssignments" value={sort} onChange={handleSort} leaderboard />
			</Box>
			<Paper>
				<PaginationList
					rowsPerPage={pageElements}
					limit={activityItems.length}
					data={activityItems}
					onPageChange={handlePageChange}
					loading={loadingStudents}
					totalRows={badgeTotalData?.student_aggregate?.aggregate?.count ?? 0}
				/>
			</Paper>
		</Grid>
	) : (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Total Points</b>
				</Typography>
			</Box>
			<ActivityBoxEmpty title="Your Students haven no points yet." />
		</Grid>
	)
}
