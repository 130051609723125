import { PagesProps } from '../../../../../../../@types'
import { MainTitle } from '../../../../../../../components'
import { useStudentByPkQuery } from '../../../../../../../graphql/autogenerate/hooks'
import { buildBreadCrumbs } from '../../../../../../../utils'
import { useStudentDetailContext } from '../../StudentDetail/useStudentDetail'

export const StudentWurrliesTitle = (props: PagesProps) => {
	const { page } = props
	const { studentId } = useStudentDetailContext()
	const { data, loading } = useStudentByPkQuery({ variables: { student_id: studentId } })
	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: `${data?.student_by_pk?.first_name ?? ''} ${data?.student_by_pk?.last_name ?? ''}`
			},
			{
				index: 2,
				overrideName: 'Wurrlies'
			}
		],
		params: {
			studentId
		}
	})

	return (
		<MainTitle
			breadcrumbs={loading ? [] : breadcrumbs}
			title={loading ? '' : `${data?.student_by_pk?.first_name ?? ''}'s Wurrlies`}
			description={
				loading ? '' : `These are the Wurrlies that ${data?.student_by_pk?.first_name ?? ''} has submited.`
			}
		/>
	)
}
