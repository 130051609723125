import { Fragment } from 'react'

import {
	Grid,
	makeStyles,
	Theme,
	Typography,
	createStyles,
	GridList,
	GridListTile,
	Button,
	Box,
	useTheme
} from '@material-ui/core'

import { buildImagePath, getCyKey, TypeEnum } from '../../../../../../../utils'
import { useClassDetailsContext } from '../useClassDetails'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonOption: {
			height: '100%',
			position: 'relative',
			'& .MuiButton-iconSizeLarge > *:first-child': {
				fontSize: 50
			},
			borderRadius: '10px'
		},
		title: {
			marginBottom: '10px'
		},
		uploadIcon: {
			backgroundColor: theme.palette.background.default,
			padding: '5px 10px',
			borderRadius: '3px'
		},
		gridList: {
			width: '100%'
		}
	})
)
export const ClassDetailsNoContent = () => {
	const styles = useStyles()
	const { bottomCards, lessonsCount, songsCount, videosCount, studentsCount } = useClassDetailsContext()

	const getHeight = () => {
		const aux = (lessonsCount === 0 ? 1 : 0) + (songsCount === 0 ? 1 : 0) + (videosCount === 0 ? 1 : 0)

		return (aux === 0 ? 1 : aux) * 165
	}

	const getYourStudentHeight = () => {
		const aux = (lessonsCount === 0 ? 1 : 0) + (songsCount === 0 ? 1 : 0) + (videosCount === 0 ? 1 : 0)

		return aux === 0 ? 1 * 165 : 165 + (aux - 1) * 160
	}

	return (
		<Fragment>
			<Grid container spacing={2} alignItems="stretch">
				{(lessonsCount === 0 || songsCount === 0 || videosCount === 0) && (
					<Grid xs={6} item>
						<Typography variant="h6" className={styles.title}>
							<b>Add Content</b>
						</Typography>
					</Grid>
				)}
				{studentsCount === 0 && (
					<Grid xs={6} item>
						<Typography className={styles.title} variant="h6">
							<b>Add Students</b>
						</Typography>
					</Grid>
				)}
			</Grid>
			<GridList cellHeight={getHeight()} className={styles.gridList} cols={2}>
				{(lessonsCount === 0 || songsCount === 0 || videosCount === 0) && (
					<GridListTile
						cols={
							(lessonsCount === 0 || songsCount === 0 || videosCount === 0) && studentsCount === 0
								? 1
								: 2
						}>
						<GridList cellHeight={160} className={styles.gridList} cols={1}>
							{bottomCards
								.filter((card) => card.type !== TypeEnum.Student)
								.map((card) => {
									if (card.type === TypeEnum.Lesson && lessonsCount > 0) return null
									if (card.type === TypeEnum.Song && songsCount > 0) return null
									if (card.type === TypeEnum.Video && videosCount > 0) return null

									return (
										<GridListTile
											key={`left-class-card-${card.title}`}
											className={styles.gridList}
											cols={1}>
											<Button
												data-cy={getCyKey(ClassDetailsNoContent, `boxBelow-${card.title}`)}
												style={{
													backgroundImage: `url(${buildImagePath(card.image_path)})`,
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',
													backgroundSize: 'cover'
												}}
												className={styles.buttonOption}
												size="large"
												fullWidth
												color="default"
												onClick={() => {
													if (card.onClick) card.onClick()
												}}>
												<Box>
													<Box position="absolute" top={20} left={20}>
														<Typography
															style={{
																color: useTheme().palette.background.default
															}}
															variant="h6">
															<b>{card.buttonTitle} </b>
														</Typography>
													</Box>
													<Box position="absolute" bottom={20} right={20}>
														<Box
															className={styles.uploadIcon}
															display="flex"
															alignItems="center">
															{card.smallIcon}
															<Typography
																variant="subtitle2"
																style={{
																	marginLeft: '5px',
																	marginRight: '5px'
																}}>
																{card.buttonLabel}
															</Typography>
														</Box>
													</Box>
												</Box>
											</Button>
										</GridListTile>
									)
								})}
						</GridList>
					</GridListTile>
				)}
				{studentsCount === 0 && (
					<GridListTile
						cols={
							(lessonsCount === 0 || songsCount === 0 || videosCount === 0) && studentsCount === 0
								? 1
								: 2
						}>
						{bottomCards.map((card) => {
							if (card.type === TypeEnum.Student) {
								return (
									<GridList
										key={`right-cards-${card.title}`}
										cellHeight={getYourStudentHeight()}
										style={{ margin: '0 px' }}
										className={styles.gridList}
										cols={1}>
										<Button
											data-cy={getCyKey(
												ClassDetailsNoContent,
												`boxBelowRight-${card.title}`
											)}
											style={{
												backgroundImage: `url(${buildImagePath(card.image_path)})`,
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'cover'
											}}
											className={styles.buttonOption}
											size="large"
											fullWidth
											color="default"
											onClick={() => {
												if (card.onClick) card.onClick()
											}}>
											<Box>
												<Box position="absolute" top={20} left={20}>
													<Typography
														style={{
															color: useTheme().palette.background.default
														}}
														variant="h6">
														<b>{card.buttonTitle} </b>
													</Typography>
												</Box>
												<Box position="absolute" bottom={20} right={20}>
													<Box
														className={styles.uploadIcon}
														display="flex"
														alignItems="center">
														{card.smallIcon}
														<Typography
															variant="subtitle2"
															style={{ marginLeft: '5px', marginRight: '5px' }}>
															{card.buttonLabel}
														</Typography>
													</Box>
												</Box>
											</Box>
										</Button>
									</GridList>
								)
							} else return null
						})}
					</GridListTile>
				)}
			</GridList>
		</Fragment>
	)
}
