import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { SearchType } from 'src/@types'
import { BaseDialog } from 'src/components'
import { useGetCleverDistrictsQuery } from 'src/graphql/autogenerate/hooks'
import { SignUpAutocomplete } from 'src/scenes/Login/components/SignUpAutocomplete'

type SelectDistrictDialogType = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onDistrictSelect: (districtId: string) => void
	onConfirm: () => void
}
export const SelectDistrictDialog = ({ open, setOpen, onConfirm, onDistrictSelect }: SelectDistrictDialogType) => {
	const [selectedDistrict, setSelectedDistrict] = useState<SearchType>({ name: '', id: 0 })
	const [searchDistrictName, setSearchDistrictName] = useState('')
	const { data: getDistrictsData, loading: getDistrictsLoading } = useGetCleverDistrictsQuery({
		fetchPolicy: 'no-cache'
	})
	useEffect(() => {
		if (getDistrictsData && getDistrictsData?.getCleverDistricts) {
			const district = {
				name: getDistrictsData?.getCleverDistricts[0]?.name || '',
				id: 0,
				districtId: getDistrictsData?.getCleverDistricts[0]?.id || ''
			}
			setSelectedDistrict(district)
		}
	}, [getDistrictsData])

	return (
		<BaseDialog
			open={open}
			onClose={() => setOpen(false)}
			onDiscard={() => setOpen(false)}
			onConfirm={() => {
				setOpen(false)
				onConfirm()
			}}
			isConfirmDisabled={!selectedDistrict?.districtId}
			confirmLabel="Confirm"
			discardLabel="Go Back"
			header={
				<Typography variant="h4" align="center">
					Select the clever district
				</Typography>
			}
			dividers={false}
			body={
				<Box style={{ padding: '50px 0' }}>
					<SignUpAutocomplete
						loadingData={getDistrictsLoading}
						placeholder="Start typing to see a list"
						items={
							getDistrictsData?.getCleverDistricts?.map((i, index) => ({
								id: index,
								name: i?.name || '',
								districtId: i?.id || ''
							})) || []
						}
						searchValue={searchDistrictName}
						setSearchValue={setSearchDistrictName}
						selectedValue={selectedDistrict}
						setSelectedValue={(district) => {
							if (!district) return
							setSelectedDistrict(district)
							onDistrictSelect(district?.districtId || '')
							setSearchDistrictName('')
						}}
					/>
				</Box>
			}
		/>
	)
}
