import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentAccordionStyles = makeStyles(() =>
	createStyles({
		title: {
			width: '100%',
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center'
		},
		accordionHolder: {
			marginTop: 4
		},
		grow: {
			flexGrow: 1
		}
	})
)

export default useAssignmentAccordionStyles
