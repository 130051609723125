import { useEffect, useState } from 'react'

import MomentUtils from '@date-io/moment'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { AssignmentsIcon, ThinCheckIcon } from 'src/assets/icons'
import DateInput from 'src/components/Inputs/DateInput'
import TimeInput from 'src/components/Inputs/TimeInput'
import { useUpdateAssignmentDueDateMutation } from 'src/graphql/autogenerate/hooks'
import { getTimetzFromStringDate } from 'src/utils'

import { InfoDialog } from '../InfoDialog'
import useStyles from './RescheduleDialog.styles'
type RescheduleDialogProps = {
	open?: boolean
	closeDialog: () => void
	assignmentDate: string
	assignmentTime: string
	assignmentId: number
	classAssignmentId: number
	refetch?: () => void
}
export const RescheduleDialog = ({
	open,
	closeDialog,
	assignmentDate,
	assignmentTime,
	assignmentId,
	classAssignmentId,
	refetch
}: RescheduleDialogProps) => {
	const styles = useStyles()
	const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false)
	const [successDialogOpen, setSuccessDialogOpen] = useState(false)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [date, setDate] = useState(assignmentDate)
	const [time, setTime] = useState(getDateTime(assignmentTime))
	const [updateAssignmentMutation, { data: updateData, error: updateError, loading: updateLoading }] =
		useUpdateAssignmentDueDateMutation()

	const closeScheduleDialog = () => setScheduleDialogOpen(false)
	const closeSuccessDialog = () => setSuccessDialogOpen(false)
	const closeErrorDialog = () => setErrorDialogOpen(false)
	const handleCloseDialog = () => {
		if (updateLoading) return
		closeScheduleDialog()
		closeDialog()
		closeErrorDialog()
		closeSuccessDialog()
	}
	const handleDate = (value: unknown) => setDate(value as string)
	const handleTime = (value: MaterialUiPickersDate) => {
		if (value?.toDate()) setTime(value?.toDate())
	}
	const handleError = () => {
		closeScheduleDialog()
		setErrorDialogOpen(true)
	}
	const handleSuccess = () => {
		closeScheduleDialog()
		setSuccessDialogOpen(true)
	}
	const updateAssignment = () => {
		updateAssignmentMutation({
			variables: {
				assignmentId,
				dueDate: date,
				dueTime: getTimetzFromStringDate(time)
			},
			update: (cache) => {
				cache.evict({
					id: `assignment:${assignmentId}`
				})
				cache.evict({
					id: `class_assignment:${classAssignmentId}`,
					fieldName: 'assignment'
				})
				if (refetch) refetch()
			}
		}).catch((e) => console.error(e))
	}

	useEffect(() => {
		if (open) {
			setScheduleDialogOpen(true)
			setTime(getDateTime(assignmentTime))
			setDate(assignmentDate)
		} else {
			closeScheduleDialog()
		}
		closeSuccessDialog()
		closeErrorDialog()
	}, [open])

	useEffect(() => {
		if (updateData) handleSuccess()
	}, [updateData])

	useEffect(() => {
		if (updateError) {
			handleError()
		}
	}, [updateError])

	return (
		<>
			<InfoDialog
				paperProps={{ className: styles.paper }}
				open={scheduleDialogOpen}
				onDiscard={handleCloseDialog}
				onClose={handleCloseDialog}
				discardLabel={<b>Cancel</b>}
				confirmLabel={
					<Box className={styles.confirmButton}>
						<b>Reschedule</b>
						{updateLoading && <CircularProgress color="secondary" size={30} />}
					</Box>
				}
				confirmProps={{
					disabled: updateLoading
				}}
				onConfirm={updateAssignment}
				icon={<AssignmentsIcon className={styles.assignmentIcon} />}
				title="Reschedule Assignment"
				body={
					<Box>
						<Typography className={styles.description}>
							Please select a Due Date & Time for the Assignment that you’re rescheduling.
						</Typography>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<Typography className={styles.inputLabel}>Select a Date</Typography>
							<DateInput
								name="due_date"
								value={date}
								onChange={handleDate}
								format="ddd, DD MMM"
								minDate={new Date()}
							/>
							<Typography className={styles.inputLabel}>Select a Time</Typography>
							<TimeInput name="due_time" value={time} onChange={handleTime} />
						</MuiPickersUtilsProvider>
					</Box>
				}
			/>
			<InfoDialog
				open={successDialogOpen}
				onClose={handleCloseDialog}
				icon={<ThinCheckIcon />}
				title="Assignment Rescheduled!"
				body="You have successfully rescheduled this Assignment."
				confirmLabel={<b>Back to Assignments</b>}
				onConfirm={handleCloseDialog}
			/>
			<InfoDialog
				open={errorDialogOpen}
				onClose={handleCloseDialog}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while updating the assignment."
				confirmLabel={<b>Back to Assignments</b>}
				onConfirm={handleCloseDialog}
			/>
		</>
	)
}

const getDateTime = (assignmentTime: string) => {
	const date = new Date()
	if (!assignmentTime) return date
	const [hour, minute] = assignmentTime.split(':')
	date.setHours(+hour, +minute, 0, 0)

	return date
}
