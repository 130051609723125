import { useEffect, useState } from 'react'

import moment from 'moment'
import { ChartDataType, DateRange } from 'src/@types'
import { useGetClassBadgesQuery, useGetTeacherBadgesQuery } from 'src/graphql/autogenerate/hooks'
import { GetTeacherBadgesQuery } from 'src/graphql/autogenerate/operations'
import { Student_Badge } from 'src/graphql/autogenerate/schemas'

type useClassProductivityChartProps = {
	classId: number
	dateRange: DateRange
}
export const useStudentPointsChart = ({ classId, dateRange }: useClassProductivityChartProps) => {
	const [chartData, setChartData] = useState<ChartDataType>({ labels: [], datasets: [] })

	const { data, loading } = useGetClassBadgesQuery({
		variables: {
			classId,
			fromDate: dateRange.fromDate,
			toDate: dateRange.toDate
		}
	})

	const { data: badgeData } = useGetTeacherBadgesQuery()

	useEffect(() => {
		if (!loading && data && data.student_badge && badgeData) {
			const extractedData = buildChartData(data.student_badge as Student_Badge[], badgeData, dateRange)
			setChartData(extractedData)
		}
	}, [data, loading, badgeData])

	return { chartData, loading }
}

const dateToDayOrMonth = (date: Student_Badge, dateRange: DateRange) => {
	if (moment(dateRange.toDate).diff(moment(dateRange.fromDate), 'days') >= 31) {
		return moment(date.created_at).format('MMMM')
	} else {
		return moment(date.created_at).format('DD MMMM')
	}
}

const buildChartData = (
	data: Student_Badge[],
	badgeData: GetTeacherBadgesQuery,
	dateRange: DateRange
): ChartDataType => {
	const labels: string[] = []
	const chartData: {
		id: number
		data: number[]
	}[] = [
		{ id: badgeData.badge[0].badge_id, data: [] },
		{ id: badgeData.badge[1].badge_id, data: [] },
		{ id: badgeData.badge[2].badge_id, data: [] },
		{ id: badgeData.badge[3].badge_id, data: [] },
		{ id: badgeData.badge[4].badge_id, data: [] },
		{ id: badgeData.badge[5].badge_id, data: [] }
	]

	data.map((item) => {
		if (!labels.includes(dateToDayOrMonth(item, dateRange))) {
			labels.push(dateToDayOrMonth(item, dateRange))

			chartData.find(({ id, data }) => {
				if (id === item.badge.badge_id) {
					data.push(item.badge.points)
				} else {
					data.push(0)
				}
			})
		} else {
			const prevIndex = labels.indexOf(dateToDayOrMonth(item, dateRange))

			chartData.find(({ id, data }) => {
				if (id === item.badge.badge_id) {
					data[prevIndex] += item.badge.points
				}
			})
		}
	})

	return {
		labels,
		datasets: [
			{
				label: badgeData.badge[0].name,
				data: chartData[0].data,
				backgroundColor: badgeData.badge[0]?.color as string
			},
			{
				label: badgeData.badge[1].name,
				data: chartData[1].data,
				backgroundColor: badgeData.badge[1].color as string
			},
			{
				label: badgeData.badge[2].name,
				data: chartData[2].data,
				backgroundColor: badgeData.badge[2].color as string
			},
			{
				label: badgeData.badge[3].name,
				data: chartData[3].data,
				backgroundColor: badgeData.badge[3].color as string
			},
			{
				label: badgeData.badge[4].name,
				data: chartData[4].data,
				backgroundColor: badgeData.badge[4].color as string
			},
			{
				label: badgeData.badge[5].name,
				data: chartData[5].data,
				backgroundColor: badgeData.badge[5].color as string
			}
		]
	}
}
