import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useActivateAccountInputsStyles = makeStyles((theme: Theme) =>
	createStyles({
		passwordBox: {
			marginTop: '5px'
		},
		errorText: {
			color: theme.palette.error.main
		},
		checkbox: {
			paddingLeft: '0px'
		},
		checkboxText: {
			color: theme.palette.grey[500]
		}
	})
)

export default useActivateAccountInputsStyles
