import { Box, Divider, Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { BillingItem } from 'src/scenes/Teacher/scenes/BillingDetails/useBillingDetails'
import { capitalize, getPriceInDollars } from 'src/utils'

import useStyles from './ItemList.styles'

type ItemListProps = {
	data: BillingItem
}
export const ItemList = ({ data }: ItemListProps) => {
	const styles = useStyles()

	return (
		<Box className={styles.container}>
			<Box className={styles.containerTitle}>
				<Typography style={{ flex: 1 }} className={styles.textOpacity}>
					{moment(data.date).format('MM/DD/YYYY')}
				</Typography>
				<Typography style={{ flex: 3 }} className={clsx(styles.text, styles.ellipsis)}>
					{data.name}
				</Typography>
				<Typography style={{ flex: 1 }} className={clsx(styles.text, styles.ellipsis)}>
					{capitalize(data.type)}
				</Typography>
				<Typography style={{ flex: 1 }} className={styles.text}>
					{getPriceInDollars(data.price)}
				</Typography>
			</Box>
			<Divider className={styles.divider} variant="inset" component="li" />
		</Box>
	)
}
