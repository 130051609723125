import React, { memo } from 'react'

import { Box, BoxProps, ButtonBase, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import { NumStr } from '../../@types/types'
import { buildImagePath } from '../../utils'
// ========= Core Components =========
// ========= Incons =========

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btnImage: {
			width: '100%',
			height: '100%',
			position: 'relative',
			backgroundSize: 'fill',
			backgroundPosition: 'center 40%',
			color: theme.palette.common.white,
			'&:hover': {
				zIndex: 1,
				'& .main': {
					backgroundColor: fade(theme.palette.common.black, 0.4)
				}
			},
			'& .main': {
				padding: theme.spacing(4),
				width: '100%',
				[theme.breakpoints.down('sm')]: {
					padding: theme.spacing(2)
				},
				backgroundColor: fade(theme.palette.grey[700], 0.1),
				transition: theme.transitions.create('background-color')
			}
		},
		btnImageSmall: {
			width: '100%',
			height: '100%',
			position: 'relative',
			backgroundPosition: 'center 40%',
			color: theme.palette.common.white,
			'&:hover': {
				zIndex: 1,
				'& .main': {
					backgroundColor: fade(theme.palette.common.black, 0.4)
				}
			},
			'& .main': {
				padding: theme.spacing(4),
				width: '100%',
				[theme.breakpoints.down('sm')]: {
					padding: theme.spacing(2)
				},
				backgroundColor: fade(theme.palette.grey[700], 0.1),
				transition: theme.transitions.create('background-color')
			}
		}
	})
)

type Position = {
	top?: NumStr
	right?: NumStr
	bottom?: NumStr
	left?: NumStr
}

export type ImageButtonProps = {
	className?: string
	id?: number
	backgroundColor?: string
	backgroundImageUrl?: string
	fitToContainer?: boolean
	centerTitle?: boolean
	icon?: string
	title?: string | JSX.Element
	titleSuffix?: string | JSX.Element
	subtitle?: string | JSX.Element
	titlePosition?: 'initial' | 'center'
	element?: JSX.Element
	elementContainerStyle?: React.CSSProperties
	elementPosition?: Position
	linkTo?: string | Record<string, unknown>
	width?: NumStr
	titleHeight?: NumStr | { xs?: number; sm?: number; md?: number; lg?: number; xl?: number }
	wrapperProps?: BoxProps
	disableButton?: boolean
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
	partner?: boolean
	hideTitles?: boolean
	disableRipple?: boolean
	cursor?: string
	imageHeight?: NumStr | { xs?: number; sm?: number; md?: number; lg?: number; xl?: number }
}

const ImageButtonComponent = ({
	className,
	backgroundColor,
	backgroundImageUrl,
	fitToContainer,
	centerTitle,
	title,
	titlePosition = 'center',
	subtitle,
	element,
	elementContainerStyle,
	icon,
	onClick,
	titleSuffix,
	elementPosition = {
		top: 16,
		right: 16,
		bottom: 'auto',
		left: 'auto'
	},
	linkTo,
	width = '100%',
	titleHeight = {
		xs: 140,
		sm: 175
	},
	disableButton,
	wrapperProps,
	partner = false,
	hideTitles = false,
	disableRipple = false,
	cursor = 'pointer',
	imageHeight = {
		xs: 140,
		sm: 175
	}
}: ImageButtonProps) => {
	const classes = useStyles()

	return (
		<Box
			className={className}
			borderRadius={10}
			boxShadow={1}
			overflow="hidden"
			width={width}
			{...wrapperProps}>
			<ButtonBase
				component={linkTo ? Link : Box}
				to={linkTo}
				focusRipple
				disableRipple={disableRipple}
				disabled={disableButton}
				onClick={onClick}
				className={fitToContainer ? classes.btnImageSmall : classes.btnImage}
				height={imageHeight}
				style={{
					backgroundColor,
					backgroundImage: backgroundImageUrl ? `url(${buildImagePath(backgroundImageUrl)})` : undefined,
					cursor
				}}>
				{!hideTitles && (
					<Box
						className="main"
						display="flex"
						justifyContent={titlePosition}
						alignItems={titlePosition}
						height={titleHeight}
						style={{
							flexDirection: subtitle ? 'column' : 'inherit',
							zIndex: 1
						}}>
						<Box
							display="flex"
							alignItems="start"
							gridGap="4px"
							justifyContent={centerTitle ? 'center' : 'space-between'}
							width={width}>
							{typeof title === 'string' ? (
								<Typography style={{ textAlign: 'center' }}>{title}</Typography>
							) : (
								title
							)}
							{titleSuffix}
						</Box>
						{subtitle && typeof subtitle === 'string' ? (
							<Typography style={{ textAlign: 'center' }}>{subtitle}</Typography>
						) : (
							subtitle
						)}
					</Box>
				)}

				{icon && (
					<Box position="absolute" top={15} right={15}>
						<img style={{ maxHeight: '30px', maxWidth: '30px' }} src={buildImagePath(icon)} />
					</Box>
				)}
				{partner && (
					<Box
						position={'absolute'}
						style={{
							width: '100%',
							height: '100%',
							top: 0,
							borderRadius: 10
						}}
					/>
				)}

				{element && (
					<Box
						position="absolute"
						top={elementPosition.top}
						right={elementPosition.right}
						left={elementPosition.left}
						bottom={elementPosition.bottom}
						style={elementContainerStyle}>
						{element}
					</Box>
				)}
			</ButtonBase>
		</Box>
	)
}

export const ImageButton = memo(ImageButtonComponent)
