import { makeStyles, Theme, createStyles, alpha } from '@material-ui/core'

const useStudentActivitiesStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: alpha(theme.palette.common.black, 0.4)
		},
		emptyText: {
			color: theme.palette.grey[500]
		},
		title: {
			marginBottom: 2
		},
		containerBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '12px',
			height: 40
		},
		headerBox: { display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' },
		classSelect: { width: '50%', height: '40px', marginRight: '10px' },
		dateSelect: { width: '50%', display: 'flex', justifyContent: 'flex-end' },
		noPadding: { '& .MuiList-padding': { padding: '0px' } }
	})
)

export default useStudentActivitiesStyles
