import React, { Fragment, useEffect, useState } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { cache } from 'src/apollo/state'
import { ThinCheckIcon } from 'src/assets/icons'
import { BigBaseButton, BaseDialog, InfoDialog, StudentInvitationDetailsDialog, TextInput } from 'src/components'
import { useGetStudentQRsPdfLazyQuery, useGetValidCodeLazyQuery } from 'src/graphql/autogenerate/hooks'
import { StudentFromCsv } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useClassStudentsContext } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassStudents/useClassStudents'
import { useStudentsGridContext } from 'src/scenes/Teacher/scenes/3-Students/components/StudentsGrid/useStudentsGrid'
import { BASE_URL, getCyKey } from 'src/utils'

import useStyles from './InviteStudentsOneByOneDialog.styles'

type InviteStudentsOneByOneDialogType = {
	classId: number
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsPreviousDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const InviteStudentsOneByOneDialog = ({
	classId,
	isOpen,
	setIsOpen,
	setIsPreviousDialogOpen
}: InviteStudentsOneByOneDialogType) => {
	const styles = useStyles()
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [isSendInviteDialogOpen, setIsSendInviteDialogOpen] = useState(false)
	const [isStudentInvitedDialogOpen, setIsStudentInvitedDialogOpen] = useState(false)
	const [isQRCodeDownloadedDialogOpen, setIsQRCodeDownloadedDialogOpen] = useState(false)
	const [downloadQRCodeURL, setDownloadQRCodeURL] = useState('')
	const [studentCode, setStudentCode] = useState('')
	const [studentName, setStudentName] = useState('')
	const [studentEmail, setStudentEmail] = useState('')
	const { control, watch, reset, getValues } = useForm()
	const [
		getStudentQRsPdfLazyQuery,
		{ loading: studentsQRsPDFLoading, data: studentsQRsPDFData, error: studentsQRsPDFError }
	] = useGetStudentQRsPdfLazyQuery()
	const [getValidCode, { data: validCode, loading: validCodeLoading }] = useGetValidCodeLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const { teacherData: teacherData } = useLoginContext()
	const { refetch: refetchClassStudents } = useClassStudentsContext()
	const { refetch: refetchStudentsGrid, limit, sort } = useStudentsGridContext()
	useEffect(() => {
		if (isQRCodeDownloadedDialogOpen || isStudentInvitedDialogOpen) {
			setIsSendInviteDialogOpen(false)
		}
	}, [isQRCodeDownloadedDialogOpen, isStudentInvitedDialogOpen])

	useEffect(() => {
		if (studentsQRsPDFError) setIsOnErrorOpen(true)
	}, [studentsQRsPDFError])

	useEffect(() => {
		if (
			studentsQRsPDFData &&
			studentsQRsPDFData.addStudentsByBulkQRs &&
			studentsQRsPDFData.addStudentsByBulkQRs.path
		) {
			const qrCodeURL = `${BASE_URL}${studentsQRsPDFData.addStudentsByBulkQRs.path}`
			setDownloadQRCodeURL(qrCodeURL)
			cache.evict({
				id: `class:${classId}`,
				fieldName: 'class_students_aggregate'
			})
			if (refetchClassStudents) refetchClassStudents()
			if (refetchStudentsGrid)
				refetchStudentsGrid({
					teacherId: teacherData.teacher_id,
					order: sort,
					limit
				})
		}
	}, [studentsQRsPDFData])

	useEffect(() => {
		if (!validCodeLoading && validCode) {
			const { firstName, lastName } = getValues()
			handleStudentQRsPDFDownload(firstName, lastName, validCode.getValidCode)
			setStudentCode(validCode.getValidCode)
		}
	}, [validCode, validCodeLoading])

	const cleanUpStates = () => {
		reset()
		setStudentName('')
		setStudentEmail('')
		setStudentCode('')
		setDownloadQRCodeURL('')
	}

	const handleStudentQRsPDFDownload = (firstName: string, lastName: string, code: string) => {
		const students = [{ firstName, lastName, code } as StudentFromCsv]
		getStudentQRsPdfLazyQuery({
			variables: {
				filter: {
					students,
					classId
				}
			}
		})
	}

	return (
		<Box>
			<BaseDialog
				dividers={false}
				open={isOpen}
				onClose={() => {}}
				header={
					<Fragment>
						<Typography variant="h4" align="center" className={styles.title}>
							Invite A Student
						</Typography>
					</Fragment>
				}
				body={
					<Box style={{ width: 480 }}>
						<Controller
							name="firstName"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextInput
									data-cy={getCyKey(InviteStudentsOneByOneDialog, 'StudentFirstName')}
									id="Student First Name"
									name="Student First Name"
									value={value}
									customMargin="20px"
									onChange={(value) => onChange(value)}
								/>
							)}
						/>
						<Controller
							name="lastName"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextInput
									data-cy={getCyKey(InviteStudentsOneByOneDialog, 'StudentLastName')}
									id="Student Last Name"
									name="Student Last Name"
									value={value}
									customMargin="20px"
									onChange={(value) => onChange(value)}
								/>
							)}
						/>
					</Box>
				}
				onDiscard={(e) => {
					e.stopPropagation()
					setIsOpen(false)
					setIsPreviousDialogOpen(true)
					cleanUpStates()
				}}
				discardLabel="Go Back"
				onDiscardProps={{ className: styles.bottomButton }}
				onConfirm={(e) => {
					e.stopPropagation()
					setIsOpen(false)
					setIsSendInviteDialogOpen(true)
					const { firstName, lastName } = getValues()
					setStudentName(`${firstName} ${lastName}`)
					if (!studentCode) {
						getValidCode()
					}
				}}
				confirmLabel={studentCode ? 'Next' : 'Generate Code'}
				isConfirmDisabled={
					!(watch().firstName && watch().lastName) ||
					!!studentsQRsPDFError ||
					studentsQRsPDFLoading ||
					validCodeLoading
				}
				loading={validCodeLoading}
				onConfirmProps={{
					className: styles.bottomButton,
					type: 'submit',
					startIcon: studentsQRsPDFLoading ? <CircularProgress color="secondary" size={20} /> : undefined
				}}
			/>
			<StudentInvitationDetailsDialog
				classId={classId}
				open={isSendInviteDialogOpen}
				onClose={() => {}}
				title="Invite A Student"
				description="Provide the student code, download the QR code or send the invitation by email.
				The student can sign up with any of these 3 ways."
				downloadQRCodeURL={downloadQRCodeURL}
				studentName={studentName}
				studentCode={studentCode}
				emailHeader={'Invite By Email'}
				studentEmail={studentEmail}
				isSendEmail={true}
				setStudentEmail={setStudentEmail}
				setIsStudentInvitedDialogOpen={setIsStudentInvitedDialogOpen}
				setIsQRCodeDownloadedDialogOpen={setIsQRCodeDownloadedDialogOpen}
				onDiscard={(e) => {
					e.stopPropagation()
					cleanUpStates()
					setIsSendInviteDialogOpen(false)
					setIsPreviousDialogOpen(true)
				}}
				discardLabel="Add Another Student"
				typographyProps={{ className: styles.discardLabel }}
				onDiscardProps={{
					className: styles.discardButton,
					// Couldn't get this padding to work without using !important or inline styles, I think
					// for this particular case inline is better to keep the ability to override it
					style: { padding: '6px 0' }
				}}
				rightActions={
					<BigBaseButton
						data-cy={getCyKey(BaseDialog, 'View Students')}
						color="secondary"
						style={{ width: '150px' }}
						onClick={(e) => {
							e.stopPropagation()
							cleanUpStates()
							setIsSendInviteDialogOpen(false)
						}}>
						View Students
					</BigBaseButton>
				}
			/>

			<InfoDialog
				open={isQRCodeDownloadedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsQRCodeDownloadedDialogOpen(false)
					setIsSendInviteDialogOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="QR Codes Downloaded!"
				body="You have successfully downloades these Students QR."
				confirmLabel="Got it!"
			/>

			<InfoDialog
				open={isStudentInvitedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsStudentInvitedDialogOpen(false)
					setIsSendInviteDialogOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="Students Invited!"
				body="You have successfully invited these Students."
				confirmLabel="Got it!"
			/>

			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
			/>
		</Box>
	)
}
