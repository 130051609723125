import { Fragment, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import { ContactButton } from 'src/components/Buttons/ContactButton/ContactButton'

import { IconActionOptionType, ListDialog } from '..'
import { useGetMembershipTypesQuery } from '../../graphql/autogenerate/hooks'
import { Membership_Type } from '../../graphql/autogenerate/schemas'
import { useIcon } from '../../hooks'
import { getPriceInDollars, STRIPE_PROMISE } from '../../utils'
import { BaseLoading } from '../Loading/BaseLoading'
import { ContactDialog } from './ContactDialog/ContactDialog'
import { PaymentDialog } from './PaymentDialog'

type SelectPackageType = {
	open: boolean
	setIsOpen: (val: boolean) => void
	onSuccess: () => void
	updateTeacherToEnterprise: () => void
	noStudents?: boolean
}
export const SelectPackageDialog = ({
	open,
	setIsOpen,
	onSuccess,
	updateTeacherToEnterprise,
	noStudents
}: SelectPackageType) => {
	const [isPaymentOpen, setIsPaymentOpen] = useState<Membership_Type>()
	const [isContactUsOpen, setIsContactUsOpen] = useState(false)

	const { loading, data } = useGetMembershipTypesQuery()

	const closeContactDialog = () => setIsContactUsOpen(false)
	const handleContactDialogDiscard = () => {
		closeContactDialog()
		setIsOpen(true)
	}
	const selectYourPackageOpts: IconActionOptionType[] = (data?.membership_type || []).map((m) => {
		const Icon = m.icon_name ? useIcon(m.icon_name) : () => null

		return {
			id: String(m.membership_type_id),
			name: (
				<Box>
					<Typography style={{ fontSize: 24 }}>${getPriceInDollars(m.price)}</Typography>
					<Typography style={{ fontSize: 12 }}>{m.name}</Typography>
				</Box>
			),
			icon: <Icon />
		}
	})

	return (
		<Box>
			{loading && <BaseLoading />}
			{!loading && (
				<Fragment>
					<ListDialog
						open={open}
						cols={2}
						onClose={() => setIsOpen(false)}
						title="Select your package"
						description="Become a subscriber to add students to your class, providing them access thousands of songs, videos, and recording and assignment management tools!"
						optionSelected={''}
						options={selectYourPackageOpts}
						handleSelectOption={(id: string) => {
							setIsOpen(false)
							setIsPaymentOpen(data?.membership_type.find((p) => p.membership_type_id === +id))
						}}
						cardHeight={160}
						footer={
							<Typography>
								Need a quote or interested in enterprise packages? <ContactButton />
							</Typography>
						}
					/>
					<ContactDialog
						open={isContactUsOpen}
						onClose={closeContactDialog}
						onDiscard={handleContactDialogDiscard}
						closeDialog={closeContactDialog}
					/>
					<Elements stripe={STRIPE_PROMISE}>
						<PaymentDialog
							open={!!isPaymentOpen}
							close={() => setIsPaymentOpen(undefined)}
							selectedPackage={isPaymentOpen}
							onSuccess={onSuccess}
							updateTeacherToEnterprise={updateTeacherToEnterprise}
							onClose={() => {}}
							onDiscard={() => {
								setIsPaymentOpen(undefined)
								setIsOpen(true)
							}}
							noStudents={noStudents}
						/>
					</Elements>
				</Fragment>
			)}
		</Box>
	)
}
