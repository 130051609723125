import { Box } from '@material-ui/core'

import { AccountActivation } from './components/AccountActivation'
import { ParentAcceptanceProvider } from './useParentAcceptance'

export const ParentAcceptance = () => {
	return (
		<Box>
			<ParentAcceptanceProvider>
				<AccountActivation />
			</ParentAcceptanceProvider>
		</Box>
	)
}
