import { createStyles, makeStyles } from '@material-ui/core'

export const useSelectClassStyles = makeStyles(() =>
	createStyles({
		select: {
			width: '240px',
			height: '40px',
			padding: '10px'
		},
		menuItem: {
			'&.Mui-selected': {
				color: 'rgba(243, 171, 52, 1)',
				backgroundColor: 'rgba(255, 194, 12, 0.12)'
			}
		}
	})
)

export default useSelectClassStyles
