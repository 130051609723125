import { Song, Tip } from 'src/graphql/autogenerate/schemas'
import * as yup from 'yup'

import { FormAsset } from '../../AddAssingment.types'

export const MAX_SONGS = 10
export const MAX_VIDEOS = 5
export const MAX_ASSETS = 5

export const stepTwoValidationSchema = yup.object({
	songs: yup.array().when('include_all_songs', {
		is: true,
		then: yup.array().notRequired(),
		otherwise: yup.array().required().max(MAX_SONGS)
	}),
	include_all_songs: yup.boolean(),
	videos: yup.array().max(MAX_VIDEOS),
	assets: yup.array().max(MAX_ASSETS)
})

export const stepTwoInitialValues = {
	songs: [] as Song[],
	include_all_songs: false,
	videos: [] as Tip[],
	assets: [] as FormAsset[],
	allow_external_files: false
}
