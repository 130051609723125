import { createStyles, makeStyles } from '@material-ui/core'

export const useVideoDialogStyles = makeStyles(() =>
	createStyles({
		title: {
			fontSize: '2rem'
		},
		paper: {
			width: '90%',
			maxWidth: '1015px'
		},
		subtitle: {
			fontSize: '1rem',
			margin: '15px 0'
		},
		genres: {
			color: '#1D273D',
			opacity: 0.5
		},
		ellipsis: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap'
		},
		margin: {
			margin: '0px 10px'
		},
		discardButton: {
			width: '40%!important',
			maxWidth: '215px'
		},
		actionButton: {
			padding: '27px 16px'
		},
		rightActions: {
			width: '60%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'end'
		},

		body: {
			margin: '35px 0 20px 0',
			'&.MuiDialogContent-root': {
				padding: 5,
				display: 'flex',
				justifyContent: 'center'
			}
		}
	})
)

export default useVideoDialogStyles
