import { FunctionComponent } from 'react'

import { Box, CardActionArea, CardActionAreaProps } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import clsx from 'clsx'

import useStyles from './SelectableOpacity.styles'

export type SelectableOpacityProps = CardActionAreaProps & {
	selected?: boolean
	hideRadioCheck?: boolean
}

export const SelectableOpacity: FunctionComponent<SelectableOpacityProps> = ({
	selected = false,
	children,
	hideRadioCheck = false,
	...props
}) => {
	const styles = useStyles()

	const buildRadioCheck = () => {
		if (!hideRadioCheck) {
			if (selected) {
				return (
					<CheckCircleIcon
						fontSize="small"
						className={styles.selectedTrackCheckmark}
						color="secondary"
					/>
				)
			}

			return (
				<RadioButtonUncheckedOutlinedIcon
					className={styles.selectedTrackCheckmark}
					fontSize="small"
					color="disabled"
				/>
			)
		}

		return <></>
	}

	return (
		<CardActionArea {...props} className={clsx(styles.base, props.className)}>
			{children}
			<Box
				className={clsx({
					[styles.selectedOverlay]: selected,
					[styles.overlay]: !selected
				})}></Box>
			{buildRadioCheck()}
		</CardActionArea>
	)
}
