import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Box, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import { usestepOneValidation } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useStepOneValidation'

import { StepFormType } from '.'
import { InputFile } from '../../../../../../components'
import { Catalog_Item, Keyword } from '../../../../../../graphql/autogenerate/schemas'
import { FileTypeEnum, TypeEnum } from '../../../../../../utils'
import {
	QueryCatalogItemsByCatalogId,
	QueryCatalogsByCatalogId,
	QueryKeyword,
	QueryKeywordType
} from '../../../../queries'
import { AutoCompleteStyle } from '../../components/CustomAutocomplete'
import { extractKeywords, extractCatalogItem } from '../LessonEdit/useInitialLessonData'
import { useStyles } from './styles'

export const StepOneForm = ({ saveData, lessonData, setStepIsValid, handleInitialData }: StepFormType) => {
	const classes = useStyles()

	const { stepOneSchema, limits: limit } = usestepOneValidation({ isRestricted: false })
	const formik = useFormik({
		initialValues: {
			title: '',
			description: '',
			image_path: '',
			keywords: [] as Keyword[],
			subjects: [] as Catalog_Item[],
			ages: [] as Catalog_Item[],
			instruments: [] as Catalog_Item[]
		},
		validationSchema: stepOneSchema,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: () => {}
	})

	const CATALOG_SUBJECTS_ID = 2
	const CATALOG_AGES_ID = 4
	const CATALOG_INSTRUMENTS_ID = 5

	const catalogSubjects = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_SUBJECTS_ID)
	)
	const catalogAges = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_AGES_ID)
	)
	const catalogInstruments = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_INSTRUMENTS_ID)
	)
	const catalogKeywords = useQuery<QueryKeywordType>(QueryKeyword(TypeEnum.Lesson))

	useEffect(() => {
		// This one is used for persisting data across steps
		const { title, description, image_path, keywords, subjects, ages, instruments } = lessonData
		formik.setValues({
			title: title ?? '',
			description: description ?? '',
			image_path: image_path ?? '',
			keywords: keywords ?? [],
			subjects: subjects ?? [],
			ages: ages ?? [],
			instruments: instruments ?? []
		})
	}, [])

	useEffect(() => {
		// This one for filling values with initial data (when editing lesson)
		if (
			handleInitialData?.initialData &&
			!handleInitialData.stepHasFilledInitialData &&
			catalogKeywords.data?.keyword &&
			catalogSubjects.data?.catalog_item &&
			catalogAges.data?.catalog_item &&
			catalogInstruments.data?.catalog_item
		) {
			const { title, description, keywords, catalog_items, image } = handleInitialData.initialData

			const extractedKeywords = extractKeywords({
				selected: keywords,
				options: catalogKeywords.data.keyword
			})

			const extractedSubjects = extractCatalogItem({
				selected: catalog_items,
				options: catalogSubjects.data?.catalog_item
			})
			const extractedAges = extractCatalogItem({
				selected: catalog_items,
				options: catalogAges.data?.catalog_item
			})
			const extractedInstruments = extractCatalogItem({
				selected: catalog_items,
				options: catalogInstruments.data?.catalog_item
			})

			formik.setValues({
				title: title ?? '',
				description: description ?? '',
				image_path: image?.resource_path ?? '',
				keywords: extractedKeywords,
				subjects: extractedSubjects,
				ages: extractedAges,
				instruments: extractedInstruments
			})
			handleInitialData.setInitialDataUsed()
		}
	}, [handleInitialData, catalogKeywords.data, catalogSubjects.data, catalogAges.data, catalogInstruments.data])

	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	useEffect(() => {
		const hasError = Object.values(formik.errors).some((err) => !!err.length)
		if (setStepIsValid) setStepIsValid(!hasError)
	}, [formik.errors])

	const getClassName = (value: string, limit: number) => {
		if (value === '') return 'Default'
		if (value.length <= limit) return 'Success'
		else return 'Error'
	}

	const getClassNameWrapper = (val: string, limit: number) => {
		return classes[`text${getClassName(val, limit)}` as keyof typeof classes]
	}

	return (
		<Box marginTop="80px">
			<form onSubmit={formik.handleSubmit}>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Title*</Typography>
						<Typography
							className={getClassNameWrapper(formik.values.title, limit.title)}
							color="textSecondary">
							{`${formik.values.title.length}/${limit.title}`}
						</Typography>
					</Box>
					<TextField
						className={getClassNameWrapper(formik.values.title, limit.title)}
						id="title"
						name="title"
						value={formik.values.title}
						onChange={formik.handleChange}
						onFocus={() => formik.setFieldTouched('title')}
						error={Boolean(formik.errors.title) && formik.touched.title}
						helperText={formik.touched.title ? formik.errors.title : undefined}
						data-cy="upload-video-title"
						color="secondary"
						variant="outlined"
						placeholder="Enter Title"
						fullWidth
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Image</Typography>
					</Box>
					<Box>
						<InputFile
							advise="File type: MP3, MP4, WAV, PDF"
							placeholder="Upload Your Image"
							type={FileTypeEnum.Images}
							handleFileUrl={(key) => formik.setFieldValue('image_path', key)}
							initialFile={
								handleInitialData && !handleInitialData?.stepHasFilledInitialData
									? handleInitialData?.initialData?.image
									: formik.values.image_path !== ''
									? { resource_path: formik.values.image_path, name: formik.values.image_path }
									: undefined
							}
							isTemporal={false}
						/>
					</Box>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Description</Typography>
						<Typography
							className={getClassNameWrapper(formik.values.description, limit.description)}
							color="textSecondary">
							{`${formik.values.description.length}/${limit.description}`}
						</Typography>
					</Box>
					<TextField
						className={getClassNameWrapper(formik.values.description, limit.description)}
						id="description"
						name="description"
						onFocus={() => formik.setFieldTouched('description')}
						error={formik.touched.description && Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
						value={formik.values.description}
						onChange={formik.handleChange}
						data-cy="upload-video-description"
						color="secondary"
						variant="outlined"
						placeholder="Enter description"
						fullWidth
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Keywords</Typography>
						<Typography className={classes.textDefault} color="textSecondary">
							Up to {limit.keywords}
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-keyword',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-keyword-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
						onFocus={() => formik.setFieldTouched('keywords')}
						multiple
						disableCloseOnSelect={true}
						noOptionsText={<Typography>No Matching Keywords - Please Try Again</Typography>}
						limitTags={limit.keywords}
						options={catalogKeywords.data?.keyword || []}
						value={formik.values.keywords}
						onChange={(_, value) => {
							if (formik.values.keywords.length < 5 || formik.values.keywords.length >= value.length)
								formik.setFieldValue('keywords', value)
						}}
						closeIcon={<CloseIcon data-cy="keywords-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								error={!!formik.errors.keywords && !!formik.touched.keywords}
								helperText={formik.touched.keywords ? formik.errors.keywords : ''}
								data-cy="upload-video-keywords"
								variant="outlined"
								color="secondary"
								placeholder="Enter keywords"
								{...params}
							/>
						)}
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Subjects</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-subject',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-subjects-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						onFocus={() => formik.setFieldTouched('subjects')}
						multiple
						disableCloseOnSelect={true}
						options={catalogSubjects.data?.catalog_item || []}
						value={formik.values.subjects}
						onChange={(_, value) => {
							formik.setFieldValue('subjects', value)
						}}
						closeIcon={<CloseIcon data-cy="subjects-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								error={!!formik.errors.subjects && !!formik.touched.subjects}
								helperText={formik.touched.subjects ? formik.errors.subjects : ''}
								{...params}
								data-cy="upload-video-subjects"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Ages</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-ages',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-ages-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						onFocus={() => formik.setFieldTouched('ages')}
						multiple
						disableCloseOnSelect={true}
						options={catalogAges.data?.catalog_item || []}
						value={formik.values.ages}
						onChange={(_, value) => {
							formik.setFieldValue('ages', value)
						}}
						closeIcon={<CloseIcon data-cy="ages-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								error={!!formik.errors.ages && !!formik.touched.ages}
								helperText={formik.touched.ages ? formik.errors.ages : ''}
								data-cy="upload-video-ages"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Instruments</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-instruments',
							className: classes.chip,
							deleteIcon: (
								<Typography
									data-cy="remove-instruments-selected"
									className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						onFocus={() => formik.setFieldTouched('instruments')}
						multiple
						disableCloseOnSelect={true}
						options={catalogInstruments.data?.catalog_item || []}
						value={formik.values.instruments}
						onChange={(_, value) => {
							formik.setFieldValue('instruments', value)
						}}
						closeIcon={<CloseIcon data-cy="instruments-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								error={!!formik.errors.instruments && !!formik.touched.instruments}
								helperText={formik.touched.instruments ? formik.errors.instruments : ''}
								{...params}
								data-cy="upload-video-instruments"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
					/>
				</Box>
			</form>
		</Box>
	)
}
