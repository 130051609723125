import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useSelectThumbnailStyles = makeStyles((theme: Theme) =>
	createStyles({
		thumbnail: {
			'& .MuiImageListItem-item': {
				borderRadius: 5,
				boxShadow: theme.shadows[1],
				cursor: 'pointer',
				position: 'relative'
			}
		},
		thumbnailIcon: {
			position: 'absolute',
			top: theme.spacing(1),
			right: theme.spacing(1)
		},
		imageListSelected: {
			'& .MuiImageListItem-item': {
				outline: `2px solid ${theme.palette.secondary.main}`
			}
		},
		upload: {
			'& .MuiImageListItem-item': {
				backgroundColor: theme.palette.background.paper,
				'& label': {
					alignItems: 'center',
					cursor: 'pointer',
					display: 'flex',
					flexDirection: 'column',
					'& .MuiSvgIcon-root': {
						marginBottom: theme.spacing(2)
					}
				}
			}
		},
		boxUpload: {
			alignItems: 'center',
			display: 'flex',
			height: '100%',
			justifyContent: 'center'
		}
	})
)

export default useSelectThumbnailStyles
