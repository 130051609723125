import { Box, Grid } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { StudentActivity } from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentDetail/components/StudentActivity'
import { StudentAssignments } from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentDetail/components/StudentAssignments/StudentAssignments'

import { SelectClasses } from './components/SelectClasses'
import { StudentCarouselWurrlies } from './components/StudentCarouselWurrlies'
import { Title } from './components/Title'
import { StudentDetailProvider } from './useStudentDetail'

export const StudentDetail = (props: PagesProps) => {
	return (
		<Box>
			<StudentDetailProvider>
				<Title {...props} />
				<SelectClasses />
				<Grid container wrap="nowrap" spacing={2} style={{ marginTop: '20px' }}>
					<StudentActivity />
					<StudentAssignments />
				</Grid>
				<StudentCarouselWurrlies />
			</StudentDetailProvider>
		</Box>
	)
}
