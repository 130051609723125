import { useEffect } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { MainTitle, Stepper, BigBaseButton } from 'src/components'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { AssignmentDescription } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/components/AssignmentDescription/AssignmentDescription'
import { useClassAssignment } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/hooks/useClassAssignment'
import { buildBreadCrumbs } from 'src/utils'

import { getCyKey } from '../../../../../../../../utils/utils'
import useStyles from './ClassAssignmentGrade.styles'
import { ActionDialogs, ActionDialogsEnum } from './components/ActionDialogs/ActionDialogs'
import { GradingCardCarousel } from './components/GradingCardCarousel/GradingCardCarousel'
import { useGradingContext } from './useGrading'
export const ClassAssignmentGradeContent = ({ page }: PagesProps) => {
	const styles = useStyles()
	const { classAssignmentId, classId } = useWurrlyParams<typeof Pages.ClassAssignmentGrade.params[number]>()
	const { submissionsLoading, className, assignment, submissions } = useClassAssignment(classAssignmentId)
	const {
		totalGraded,
		totalSubmissions,
		openDialog,
		setSubmissions,
		setAssignmentDueDateString,
		setClassAssignmentId
	} = useGradingContext()
	const handleStopGrading = () => openDialog(ActionDialogsEnum.GoBackWithouSaving)
	const handleSubmitGrade = () => openDialog(ActionDialogsEnum.ConfirmGrades)

	useEffect(() => {
		setClassAssignmentId(classAssignmentId)
	}, [classAssignmentId])

	// Set the order of submissions in the carousel
	useEffect(() => {
		setSubmissions(submissions.map((i) => ({ ...i })))
	}, [submissions])

	// Update the due date on the gradingContext
	useEffect(() => {
		setAssignmentDueDateString(`${assignment?.due_date}T${assignment?.due_time}`)
	}, [assignment])

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			},
			{
				index: 2,
				overrideName: 'Assignments'
			},
			{
				index: 3,
				overrideName: assignment?.name
			}
		],
		params: {
			classAssignmentId,
			classId
		}
	})

	return (
		<>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={assignment?.name}
				description={
					<AssignmentDescription
						instructions={assignment?.instructions ?? ''}
						dueDateFormatted={assignment?.dueDateFormatted ?? ''}
					/>
				}
			/>
			<Stepper
				stepDescription=""
				currentStep={totalGraded}
				totalSteps={totalSubmissions}
				stepBoxProps={{ mt: 6 }}
				stepText={`${totalGraded}/${totalSubmissions} Students Graded`}
			/>

			{submissionsLoading && <CircularProgress color="secondary" size={30} />}

			<GradingCardCarousel gradingValues={assignment?.grading_system.values} />
			<ActionDialogs classAssignmentId={classAssignmentId} classId={classId} />
			<Box className={styles.actionsHolder}>
				<BigBaseButton onClick={handleStopGrading} variant="contained" color="default">
					<Typography variant="caption">
						<b>Stop Grading</b>
					</Typography>
				</BigBaseButton>
				<BigBaseButton
					onClick={handleSubmitGrade}
					variant="contained"
					color="secondary"
					disabled={assignment?.grading_system.values.length ? !totalGraded : false}>
					<Typography data-cy={getCyKey(ClassAssignmentGradeContent, 'submitButton')} variant="caption">
						<b>Submit Grade</b>
					</Typography>
				</BigBaseButton>
			</Box>
		</>
	)
}
