import { AssignmentAccordion } from 'src/components/AssignmentAccordion'
import { AssignmentCardList } from 'src/components/AssignmentCardList/AssignmentCardList'
import { DateFilterEnum } from 'src/environment'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'
import { useAssignmentsToBeGraded } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/useAssignmentsToBeGraded'

type AssignmentsToBeGradedProps = {
	classId: number
	date: DateFilterEnum
}

export const AssignmentsToBeGraded = ({ classId, date }: AssignmentsToBeGradedProps) => {
	const { totalAssignments, assignments, seeMoreEnabled, seeMore, loading, refetch, sort, handleSort } =
		useAssignmentsToBeGraded({ classId, date })

	return (
		<AssignmentAccordion
			countTitle="To Be Graded"
			count={totalAssignments}
			details={
				<AssignmentCardList
					classAssignments={assignments as Class_Assignment[]}
					seeMore={seeMoreEnabled}
					onSeeMore={seeMore}
					loading={loading}
					home={true}
					refetch={refetch}
				/>
			}
			sort={sort}
			handleSort={handleSort}
		/>
	)
}
