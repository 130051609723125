import { WurrlyLogoIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { StudioSongPlayer } from 'src/components/Studio/Setup/StudioSongPlayer'
import { useStudioBackgroundStyles } from 'src/components/Studio/Setup/styles/studioBackgroundStyles'
import { StudioSetupProvider } from 'src/components/Studio/Setup/useStudioSetup'

export const StudioSetup = () => {
	const styles = useStudioBackgroundStyles()

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={styles.logoPosition} />}
			className={styles.backgroundImage}>
			<StudioSetupProvider>
				{/* <StudioGoBack /> */}
				<StudioSongPlayer isTeacher />
			</StudioSetupProvider>
		</FixedBackground>
	)
}
