import { Fragment, useEffect, useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import RICIBs from 'react-individual-character-input-boxes'

import { BaseDialog } from '../../../../../../../../components'
import { DialogHeader } from '../../components/DialogHeader'
import { useStudentSignInOptionsStyles } from '../../styleStudentSignInOptions'
import { useStudentSignInOptionsContext } from '../../useStudentSignInOptions'
import { useStudentCodeDialogStyles } from './styleStudentCodeDialog'

type InputBoxProps = {
	className: string
}

export const StudentCodeDialog = () => {
	const { handleClose, setStudentCode, GetStudentQuestionaryLoading, GetStudentQuestionaryError } =
		useStudentSignInOptionsContext()
	const genericStyles = useStudentSignInOptionsStyles()
	const studentCodeDialogStyles = useStudentCodeDialogStyles()
	const [showError, setShowError] = useState(false)

	useEffect(() => {
		setShowError(!!GetStudentQuestionaryError)
	}, [GetStudentQuestionaryError])

	const handleOutput = (code: string) => {
		if (showError) setShowError(false)
		if (code.length === 6) {
			setTimeout(() => {
				setStudentCode(code)
				// setDialog('studentVerification')
			}, 200)
		}
	}

	const getInputProps = () => {
		const props: InputBoxProps[] = []
		for (let i = 0; i < 6; i++) {
			props.push({ className: `${studentCodeDialogStyles.boxInput}` })
		}

		return props
	}

	return (
		<Fragment>
			<BaseDialog
				open
				paperProps={{ className: genericStyles.paper }}
				className={genericStyles.baseDialog}
				onClose={handleClose}
				onDiscard={handleClose}
				dividers={false}
				header={<DialogHeader title="Enter your student code" />}
				isActionsHidden
				body={
					<>
						<Box display="flex" alignItems="center" justifyContent="space-around" flexDirection="row">
							{GetStudentQuestionaryLoading && (
								<CircularProgress color="secondary" size={50} style={{ margin: '0 auto' }} />
							)}
							{!GetStudentQuestionaryLoading &&
								showError &&
								GetStudentQuestionaryError?.message === 'Student not found.' && (
									<Typography style={{ color: 'red' }}>Student code doesn't exists.</Typography>
								)}
						</Box>
						<Box display="flex" alignItems="center" justifyContent="space-around" flexDirection="row">
							<RICIBs
								amount={6}
								autoFocus
								handleOutputString={handleOutput}
								inputProps={getInputProps()}
								inputRegExp={/^[a-z0-9]+$/i}
							/>
						</Box>
					</>
				}
			/>
		</Fragment>
	)
}
