import { createStyles, makeStyles } from '@material-ui/core'

export const useMiniSongPlayerStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			width: '100%'
		},
		playPauseButton: {
			backgroundColor: 'white',
			minWidth: 48
		},
		progressContainer: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			marginLeft: theme.spacing(2),
			borderRadius: '4px',
			padding: theme.spacing(0.3, 1.5)
		},
		progress: {
			marginLeft: '1em'
		},
		songTime: {
			minWidth: 85,
			fontSize: 14
		}
	})
)

export default useMiniSongPlayerStyles
