import { useEffect, useState } from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useGetTeacherLicenseLazyQuery, useGetTotalSubscriptionsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Billing_Method_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, getPriceInDollars } from 'src/utils'

export const useBillingInfo = () => {
	const history = useHistory()
	const [teacherLicenseMonthlyPrice, setTeacherLicenseMonthlyPrice] = useState('0')
	const [teacherLicenseAnnualPrice, setTeacherLicenseAnnualPrice] = useState('0')

	const [subscriptionsMonthlyPrice, setSubscriptionsMonthlyPrice] = useState('0')
	const [subscriptionsAnnualPrice, setSubscriptionsAnnualPrice] = useState('0')

	const [totalMonthlyPrice, setTotalMonthlyPrice] = useState('0')
	const [totalAnnualPrice, setTotalAnnualPrice] = useState('0')
	const { teacherData } = useLoginContext()

	const [getTotalSubscriptions, { data: totalSubscriptions }] = useGetTotalSubscriptionsLazyQuery()
	const [getTeacherLicense, { data: totalTeacherSubscription }] = useGetTeacherLicenseLazyQuery()

	useEffect(() => {
		getTotalSubscriptions({
			variables: {
				teacherId: teacherData.teacher_id,
				startDate: moment().startOf('month').format('YYYY-MM-DD'),
				endDate: moment().endOf('month').format('YYYY-MM-DD')
			}
		})
		getTeacherLicense({
			variables: {
				teacherId: teacherData.teacher_id
			}
		})
	}, [])

	useEffect(() => {
		if (totalSubscriptions) {
			setSubscriptionsMonthlyPrice(
				getPriceInDollars(totalSubscriptions.Monthly_price.aggregate?.sum?.total || 0)
			)
			setSubscriptionsAnnualPrice(
				getPriceInDollars(totalSubscriptions.Annual_price.aggregate?.sum?.total || 0)
			)
		}
	}, [totalSubscriptions])
	useEffect(() => {
		if (totalTeacherSubscription && totalTeacherSubscription.teacher_subscription.length > 0) {
			if (
				totalTeacherSubscription.teacher_subscription[0].membership_type.billing_method ===
				Billing_Method_Enum.Annual
			) {
				setTeacherLicenseAnnualPrice(
					getPriceInDollars(totalTeacherSubscription?.teacher_subscription[0].membership_type.price || 0)
				)
			} else {
				setTeacherLicenseMonthlyPrice(
					getPriceInDollars(totalTeacherSubscription?.teacher_subscription[0].membership_type.price || 0)
				)
			}
		}
	}, [totalTeacherSubscription])

	useEffect(() => {
		setTotalMonthlyPrice(CalculateTotalPrice(teacherLicenseMonthlyPrice, subscriptionsMonthlyPrice))
	}, [teacherLicenseMonthlyPrice, subscriptionsMonthlyPrice])

	useEffect(() => {
		setTotalAnnualPrice(CalculateTotalPrice(teacherLicenseAnnualPrice, subscriptionsAnnualPrice))
	}, [teacherLicenseAnnualPrice, subscriptionsAnnualPrice])

	const CalculateTotalPrice = (teacherLicensePrice: string, subscriptionsPrice: string) => {
		return (Number(teacherLicensePrice) + Number(subscriptionsPrice)).toFixed(2)
	}

	const goToViewBillinDetails = () => {
		history.push(buildRouteParameters(Pages.BillingDetails))
	}

	return {
		goToViewBillinDetails,
		teacherLicenseMonthlyPrice,
		teacherLicenseAnnualPrice,
		subscriptionsMonthlyPrice,
		subscriptionsAnnualPrice,
		totalMonthlyPrice,
		totalAnnualPrice
	}
}
