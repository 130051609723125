import { useState, useEffect } from 'react'

import { useDebounce } from 'react-use'
import { ChordMapObjectType } from 'src/@types'
import { useCreateRoyaltyLogMutation, useGetSongForStudioQuery } from 'src/graphql/autogenerate/hooks'
import { GetSongForStudioQuery } from 'src/graphql/autogenerate/operations'
import { Screen_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { BLANK_SONG_TITLE, TrackTypeEnum, getAudioUrl } from 'src/utils'

type useStudioSongPlayerDialog = {
	songId?: number
	selectedChords?: string[]
	skipQuery?: boolean
	open?: boolean
}

export const useStudioSongPlayer = ({ songId, skipQuery, selectedChords, open }: useStudioSongPlayerDialog) => {
	const { submissionId } = useWurrlyParams<typeof Pages.AssignmentStudentStudio.params[number]>()
	const { data, loading: songLoading } = useGetSongForStudioQuery({
		variables: {
			songId: songId || 0
		},
		skip: !songId || skipQuery
	})
	const [chords, setChords] = useState<string[]>()
	const [vocalGuide, setVocalGuide] = useState(false)
	const [instrument, setInstrument] = useState('')
	const [speed, setSpeed] = useState(0.0)
	const [songKey, setSongKey] = useState(0)
	const [music, setMusic] = useState<GetSongForStudioQuery['song_by_pk']>()
	const [loadingMusic, setLoadingMusic] = useState(true)
	const [usedParams, setUsedParams] = useState(false)
	const [currentTrackId, setCurrentTrackId] = useState<number>()
	const [audioStartTime, setAudioStartTime] = useState<Date>()
	const [shouldHandleRoyalty, setShouldHandleRoyalty] = useState(false)
	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()
	const [audio, setAudio] = useState(new Audio(''))
	const [loaded, setLoaded] = useState(false)
	const [playing, setPlaying] = useState(false)
	const [progress, setProgress] = useState(0)
	const [currentTime, setCurrentTime] = useState(0)
	const [duration, setDuration] = useState(0)
	const [justIncludeVocalGuide, setJustIncludeVocalGuide] = useState(false)

	useEffect(() => {
		if (playing && open === false) {
			audio.pause()
			setPlaying(false)
		}
	}, [open])

	useEffect(() => {
		if (data?.song_by_pk) {
			setMusic(data.song_by_pk)
			setLoadingMusic(false)
		} else if (data?.song_by_pk === null) {
			setLoadingMusic(false)
		}
	}, [data])

	const formatTime = (seconds: number) => {
		const seg = Math.floor(seconds)
		const min = Math.floor(seg / 60)
		const hor = Math.floor(min / 60)

		const twoDigits = (num: number) => `0${num}`.slice(-2)

		return `${hor > 0 ? `${twoDigits(hor)}:` : ''}${twoDigits(min % 60)}:${twoDigits(seg % 60)}`
	}

	const handleTimeChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		audio.currentTime = ((value as number) * duration) / 100
	}

	const handleControl = () => {
		if (playing) {
			audio.pause()
			setPlaying(false)
			handleRoyaltyLog()
		} else {
			audio.playbackRate = speed + 1
			audio.play()
			setPlaying(true)
			setAudioStartTime(new Date())
		}
	}

	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const playSeconds = (audioPauseTime.getTime() - (audioStartTime ?? audioPauseTime).getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId: currentTrackId ?? 0,
					songId: songId ?? 0,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.VideoStudioSetup
				}
			})
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
		setAudioStartTime(undefined)
	}

	useDebounce(
		() => {
			if (shouldHandleRoyalty) {
				setShouldHandleRoyalty(false)
				handleRoyaltyLog()
			}
		},
		200,
		[shouldHandleRoyalty]
	)

	const handleActivate = () => {
		if (!vocalGuide) {
			setInstrument('Band')
		}
		setVocalGuide(!vocalGuide)
		setUsedParams(true)
	}

	const handleSelectInstrument = (inst: string) => {
		if (!vocalGuide) {
			setInstrument(inst)
			setUsedParams(true)
		}
	}
	const handleSpeedChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		setSpeed(value as number)
		setUsedParams(true)
	}

	const handleKeyChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		setSongKey(value as number)
		setUsedParams(true)
	}

	useEffect(() => {
		return () => audio.pause()
	}, [audio])

	useEffect(() => {
		if (!songId) {
			audio.pause()
		}
	}, [songId])

	useEffect(() => {
		if (!songLoading && music) {
			let transposition = 0
			let instrumentSelected = ''
			const justIncludeVocalGuide =
				music.tracks.length === 1 && music.tracks[0].track_type.name === TrackTypeEnum.Vocal

			if (music.tracks?.some((track) => track.track_type.name === TrackTypeEnum.Band)) {
				instrumentSelected = TrackTypeEnum.Band
			} else if (music.tracks?.some((track) => track.track_type.name === TrackTypeEnum.Guitar)) {
				instrumentSelected = TrackTypeEnum.Guitar
			} else if (music.tracks?.some((track) => track.track_type.name === TrackTypeEnum.Piano)) {
				instrumentSelected = TrackTypeEnum.Piano
			} else if (justIncludeVocalGuide) {
				instrumentSelected = TrackTypeEnum.Vocal
			}
			const track = music.tracks?.find((track) => track.track_type.name === instrumentSelected)
			setInstrument(instrumentSelected)

			if (selectedChords && selectedChords.length > 0) {
				const chord_map = Object.values(track?.midis[0]?.chord_map_object as ChordMapObjectType).find(
					(chordMap) => {
						return (
							new Set([...chordMap.chordArray, ...selectedChords]).size ===
							chordMap.chordArray.length
						)
					}
				)
				transposition = chord_map ? chord_map.transposition : 0
				setSongKey(transposition)
			} else {
				setChords(track?.midis?.flatMap((midi) => midi.chords_Array_Zero?.split(',') || []))
			}
			setAudio(new Audio(getAudioUrl(0.0, transposition, instrumentSelected, music)))
			setJustIncludeVocalGuide(justIncludeVocalGuide)
		}
	}, [music, songLoading, selectedChords])

	useEffect(() => {
		if (music) {
			audio.src = getAudioUrl(
				speed,
				songKey,
				vocalGuide || justIncludeVocalGuide ? TrackTypeEnum.Vocal : instrument,
				music
			)
			if (playing) {
				setLoaded(false)
				audio.playbackRate = speed + 1
				audio.currentTime = currentTime
				audio.play()
			}
			const track = music.tracks?.find((track) => track.track_type.name === instrument)
			if (track) {
				setChords(
					((track?.midis[0]?.chord_map_object || {}) as ChordMapObjectType)[songKey]?.chordArray || []
				)
				setCurrentTrackId(track?.track_id)
			}
		}
	}, [songKey, speed, instrument, vocalGuide, music])

	const handleLoadedData = () => {
		setLoaded(true)
		setDuration(audio.duration)
	}
	const handleAudioTimeUpdate = () => {
		setProgress((audio.currentTime * 100) / audio.duration) // percent
		setCurrentTime(audio.currentTime) // seconds
	}
	const handleAudioEnded = () => {
		setPlaying(false)
		setShouldHandleRoyalty(true)
	}

	useEffect(() => {
		audio.addEventListener('loadeddata', handleLoadedData)
		audio.addEventListener('timeupdate', handleAudioTimeUpdate)
		audio.addEventListener('ended', handleAudioEnded)

		return () => {
			audio.removeEventListener('loadeddata', handleLoadedData)
			audio.removeEventListener('timeupdate', handleAudioTimeUpdate)
			audio.removeEventListener('ended', handleAudioEnded)
		}
	}, [audio])

	return {
		chords,
		vocalGuide,
		instrument,
		speed,
		songKey,
		songLoading,
		data,
		music,
		submissionId,
		loadingMusic,
		usedParams,
		loaded,
		progress,
		playing,
		currentTime,
		duration,
		setChords,
		setVocalGuide,
		setInstrument,
		setSpeed,
		setSongKey,
		handleActivate,
		handleSelectInstrument,
		handleSpeedChange,
		handleKeyChange,
		setCurrentTrackId,
		formatTime,
		handleTimeChange,
		handleControl,
		isBlankSong: music?.title.toLowerCase() === BLANK_SONG_TITLE,
		justIncludeVocalGuide
	}
}
