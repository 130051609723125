import { useEffect, useState } from 'react'

import { Box, Grid, ListItemAvatar, ListItemText, Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import { SelectedEventType } from 'src/@types'
import {
	ActivityBoxEmpty,
	Datetime,
	PaginationList,
	PaginationListDataItem,
	Select,
	SelectSort
} from 'src/components'
import { GradeStar } from 'src/components/GradeStar/GradeStar'
import useStyles from 'src/components/listAssignments/ListItemAssignment.styles'
import { StateTypeEnum } from 'src/components/listAssignments/ListItemAssignment.types'
import { useGetAssignmentsLazyQuery, useGetClassesByStudentQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { buildImagePath, formatAMPM, GRADED, SortOrder, START, SUBMITTED } from 'src/utils'

export const StudentAssignments = () => {
	const styles = useStyles()
	const pageElements = 5
	const {
		studentData: { student_id }
	} = useLoginContext()
	const [assignmentsItems, setAssignmentsItems] = useState<PaginationListDataItem[]>([])
	const [loadingAssignments, setLoadingAssignments] = useState<boolean>(false)
	const [sort, setSort] = useState(SortOrder.Up)
	const [classId, setClassId] = useState<number | 'all'>('all')

	const { data: classesData } = useGetClassesByStudentQuery({
		variables: {
			studentId: student_id
		},
		skip: !student_id
	})

	const [getAssignments, { data, loading, refetch }] = useGetAssignmentsLazyQuery()

	useEffect(() => {
		getAssignments({
			variables: {
				studentId: student_id,
				classId: classId !== 'all' ? classId : null,
				offset: 0,
				limit: pageElements,
				orderAlph:
					sort === SortOrder.Up ? Order_By.Asc : sort === SortOrder.Down ? Order_By.Desc : undefined,
				orderDate:
					sort === SortOrder.DateAsc
						? Order_By.Asc
						: sort === SortOrder.DateDesc
						? Order_By.Desc
						: undefined
			}
		})
	}, [])

	useEffect(() => {
		getAssignments({
			variables: {
				studentId: student_id,
				classId: classId !== 'all' ? classId : null,
				offset: 0,
				limit: pageElements,
				orderAlph:
					sort === SortOrder.Up ? Order_By.Asc : sort === SortOrder.Down ? Order_By.Desc : undefined,
				orderDate:
					sort === SortOrder.DateAsc
						? Order_By.Asc
						: sort === SortOrder.DateDesc
						? Order_By.Desc
						: undefined
			}
		})
	}, [sort, classId])

	const handleSort = (event: SelectedEventType) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const formartTime = (dueTime: string) => {
		// Set Date
		const date = new Date()

		// Set Time
		const [hour, minute] = dueTime.split(':')

		date.setHours(+hour, +minute, 0, 0)

		return formatAMPM(date)
	}

	const handleSelectClass = (event: SelectedEventType) => {
		if (event.target.value) {
			setClassId(event.target.value as number)
		} else {
			setClassId('all')
		}
	}

	useEffect(() => {
		setLoadingAssignments(loading)
		if (!loading && data && data.submission) {
			const items = data.submission.map((item) => {
				return {
					avatar: (
						<ListItemAvatar className={styles.avatar}>
							<Box className={styles.relative}>
								<GradeStar state={item.state || ''} grade={item.grade || ''} />
							</Box>
						</ListItemAvatar>
					),
					primary: (
						<ListItemText
							disableTypography
							primary={
								<Box>
									<Typography className={styles.itemTitle} variant="h6">
										<b>{item.class_assignment?.assignment.name || ''}</b>
									</Typography>
									<Box display="flex" alignItems="center">
										<Datetime
											color="secondary"
											text={`${moment(item.class_assignment?.assignment.due_date).format(
												'ddd, DD MMM'
											)} - ${formartTime(item.class_assignment?.assignment.due_time)}`}
										/>
									</Box>
								</Box>
							}
						/>
					),
					secondary: (
						<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
							<div className={styles.divStyle} />
							<img
								src={buildImagePath(
									((item.state || '') as string) === StateTypeEnum.Graded
										? GRADED
										: ((item.state || '') as string) === StateTypeEnum.Submitted
										? SUBMITTED
										: START
								)}
							/>
						</Box>
					)
				}
			})
			setAssignmentsItems(items)
		}
	}, [data, loading])

	return (
		<Grid xs={6} item>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="1.1em"
				height={40}>
				<Typography variant="h6">
					<b>Assignments</b>
				</Typography>
			</Box>
			{!loading && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					marginBottom="1.1em"
					height={40}
					className={styles.toolbar}>
					<Select
						className={styles.classSelect}
						options={[
							{ name: 'All', value: 'all' },
							...(classesData?.class || []).map((item) => ({
								name: item.title,
								value: item.class_id
							}))
						]}
						onChange={handleSelectClass}
						value={classId}
					/>

					<SelectSort cyKey="StudentAssignments" value={sort} onChange={handleSort} dueDate />
				</Box>
			)}
			{loading || assignmentsItems.length > 0 ? (
				<Paper>
					<PaginationList
						isCustomListItem
						rowsPerPage={pageElements}
						limit={assignmentsItems.length}
						data={assignmentsItems}
						onPageChange={(page) => {
							setLoadingAssignments(true)
							refetch({ offset: (page - 1) * pageElements })
						}}
						loading={loadingAssignments}
						totalRows={data?.total.aggregate?.count ?? 0}
					/>
				</Paper>
			) : (
				<ActivityBoxEmpty title="You have no upcoming assignments." className={styles.activityEmpty} />
			)}
		</Grid>
	)
}
