import { createStyles, makeStyles } from '@material-ui/core'

export const useTimeSlideStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			width: '100%'
		}
	})
)

export default useTimeSlideStyles
