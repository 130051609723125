import { useEffect, useState } from 'react'

import { useGetSequenceTopicsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Sequence_Topic, Sequence } from 'src/graphql/autogenerate/schemas'
import { MAX_LIST_ITEMS } from 'src/utils'

export const useGetSequenceTopics = (
	search: string | undefined,
	order = Order_By.Asc,
	sequenceTopicId: number
): [Sequence_Topic | undefined, Sequence[], boolean, boolean, boolean] => {
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [sequenceItems, setSequenceItems] = useState<Sequence[]>([])
	const [isFetching, setIsFetching] = useState(false)
	const [loading, setLoading] = useState(true)
	const [moreResults, setMoreResults] = useState(true)

	const INITIAL_QUERY_VARIABLES = {
		search: search ? `%${search}%` : '%%',
		offset: 0,
		limit,
		order,
		sequenceTopicId
	}

	// get queries
	const [getSequence, sequenceQuery] = useGetSequenceTopicsLazyQuery()
	const sequenceTopic = sequenceQuery.data?.sequence_topic_by_pk as Sequence_Topic

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [sequenceItems])

	useEffect(() => {
		if (sequenceQuery.loading) setLoading(true)
		if (!sequenceQuery.loading && sequenceQuery.data) {
			setSequenceItems(
				(sequenceQuery?.data?.sequence_topic_by_pk?.sequence_topic_sequences.map((i) => i.sequence) ||
					[]) as Sequence[]
			)
		}
	}, [sequenceQuery])

	useEffect(() => {
		getSequence({
			variables: INITIAL_QUERY_VARIABLES
		})
	}, [order, search, getSequence])

	useEffect(() => {
		if (!isFetching) return
		if (!sequenceQuery.loading && sequenceQuery.fetchMore) {
			const currentLength = sequenceItems.length
			sequenceQuery
				.fetchMore({
					variables: {
						offset: currentLength,
						limit: MAX_LIST_ITEMS
					}
				})
				.then((fetchMoreResult) => {
					if (fetchMoreResult.data?.sequence_topic_by_pk?.sequence_topic_sequences?.length !== 0) {
						setLimit(
							currentLength +
								(fetchMoreResult.data?.sequence_topic_by_pk?.sequence_topic_sequences?.length || 0)
						)
						setMoreResults(true)
					} else {
						setIsFetching(false)
						setMoreResults(false)
					}
				})
		}
	}, [isFetching])

	return [sequenceTopic, sequenceItems, isFetching, moreResults, loading]
}
