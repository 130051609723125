import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useDisciplineStyles = makeStyles((theme) =>
	createStyles({
		fieldTitle: {
			fontSize: '1.3em',
			fontWeight: 500,
			textTransform: 'capitalize',
			marginBottom: '.8em',
			'& b, strong': {
				fontWeight: 500
			}
		},
		checkboxInput: {
			backgroundColor: theme.palette.background.paper,
			border: '1px solid rgba(0, 0, 0, .23)',
			padding: '5px 20px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		checkboxGridItem: {
			flexGrow: 1
		}
	})
)

export default useDisciplineStyles
