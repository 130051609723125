import React from 'react'

import { Box, BoxProps, Button, Typography, useTheme } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import useStyles from './IconActionCard.styles'

export type IconActionOptionType = {
	id: string
	title?: string
	name?: string | JSX.Element
	icon?: JSX.Element
	secondLink?: React.ReactNode
	cols?: number
	disabled?: boolean
	noTooltip?: boolean
}

export type IconActionCardType = {
	option: IconActionOptionType
	isSelected?: boolean
	handleClick: () => void
	cardHeight?: number
	cardWidth?: number | string
	isHovered?: boolean
	isSomeCardHovered?: boolean
	setIsHovered?: (isHovered: boolean) => void
	containerProps?: BoxProps
}

export const IconActionCard = ({
	option: { id, title, name, icon, secondLink, disabled },
	isSelected = false,
	handleClick,
	cardHeight = 140,
	cardWidth,
	isHovered,
	isSomeCardHovered,
	setIsHovered,
	containerProps
}: IconActionCardType) => {
	const styles = useStyles({ icon })
	const theme = useTheme()

	return (
		<Box margin="5px" {...containerProps}>
			{title && (
				<Typography variant="subtitle1">
					<b>{title}</b>
				</Typography>
			)}
			<Button
				data-cy={`button-${id}`}
				className={styles.buttonOption}
				style={{
					height: cardHeight,
					width: cardWidth,
					color: isSelected ? theme.palette.common.black : undefined,
					position: 'relative',
					borderWidth: isSelected ? 2 : undefined,
					backgroundColor: disabled
						? theme.palette.action.disabledBackground
						: isSelected
						? fade(theme.palette.secondary.main, 0.1)
						: isHovered
						? theme.palette.secondary.dark
						: theme.palette.common.white,

					opacity: isSomeCardHovered && !isHovered ? 0.5 : undefined
				}}
				disableRipple={!!secondLink}
				size="large"
				fullWidth={!cardWidth}
				startIcon={icon}
				variant={isSelected ? 'outlined' : 'contained'}
				color={isSelected ? 'secondary' : undefined}
				onClick={handleClick}
				onMouseEnter={() => setIsHovered && setIsHovered(true)}
				onMouseLeave={() => setIsHovered && setIsHovered(false)}>
				{isSelected && (
					<Box position="absolute" top={8} right={8}>
						<CheckCircleIcon fontSize="small" color="secondary" />
					</Box>
				)}
				{name && (
					<div>
						<Typography variant="subtitle1">
							<b>{name}</b>
						</Typography>
					</div>
				)}
				{secondLink && (
					<Box position="absolute" bottom={8}>
						{secondLink}
					</Box>
				)}
			</Button>
		</Box>
	)
}
