import React, { useEffect, useState } from 'react'

import { Box, Button, Chip, CircularProgress } from '@material-ui/core'
import ReactionsPopper, { REACTION_POPPER_REACTION } from 'src/components/ReactionsPopper'
import { Wurrly_Reaction } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useClassWurrlyContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassWurrly/hooks/useClassWurrly'
import { Like_Icon } from 'src/utils'

const ReactionsBox = ({}) => {
	const {
		wurrlyId,
		reactions,
		filteredReactions,
		insertReaction,
		deleteReaction,
		updateReaction,
		hasReacted,
		setHasReacted,
		loadingReaction
	} = useClassWurrlyContext()
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const [openReactions, setOpenReactions] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [wurrlyReaction, setWurrlyReaction] = useState<REACTION_POPPER_REACTION>()
	const [wurrlyReactionId, setWurrlyReactionId] = useState<number>()

	const handleReactionsPopper = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
		setOpenReactions(!openReactions)
	}
	const addReaction = (reactionId: number) => {
		return insertReaction({
			variables: { student_id: studentId, reaction_id: reactionId, wurrly_id: wurrlyId },
			update: (cache) => {
				cache.evict({
					id: `wurrly_reaction:${wurrlyReaction?.wurrly_reaction_id}`,
					fieldName: 'reaction_id'
				})
			}
		})
	}

	const removeReactionMutation = () => {
		return deleteReaction({
			variables: {
				wurrly_reaction_id: wurrlyReactionId as number,
				wurrly_id: wurrlyId
			},
			update: (cache) => {
				cache.evict({
					id: `wurrly_reaction:${wurrlyReaction?.wurrly_reaction_id}`,
					fieldName: 'reaction_id'
				})
			}
		})
	}

	const updateReactionMutation = (wurrly_reaction_id: number, reactionId: number) => {
		if (wurrlyReaction?.reaction_id !== reactionId && hasReacted && wurrlyReaction?.reaction_id)
			return updateReaction({
				variables: {
					wurrly_reaction_id,
					reaction_id: reactionId
				},
				update: (cache) => {
					cache.evict({
						id: `wurrly_reaction:${wurrlyReaction?.wurrly_reaction_id}`,
						fieldName: 'reaction_id'
					})
				}
			})
		else return
	}

	const getWurrlyReactionIdByStudentId = (reactions: Wurrly_Reaction[], studentId: number) => {
		const reaction = reactions.find((reaction) => reaction.student_id === studentId)
		setWurrlyReaction(reaction)
		setWurrlyReactionId(reaction?.wurrly_reaction_id)
	}

	useEffect(() => {
		getWurrlyReactionIdByStudentId(reactions, studentId)
	}, [filteredReactions, reactions, hasReacted])

	return (
		<>
			<Box>
				{!loadingReaction ? (
					<Box
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginTop: '10px'
						}}>
						{filteredReactions.map((reaction) => {
							return (
								reaction.count !== 0 && (
									<Chip
										key={reaction.label}
										style={
											hasReacted
												? { backgroundColor: '#7EA5D5' }
												: { backgroundColor: '#f6f6f6' }
										}
										label={`${reaction.icon} ${reaction.count}`}
									/>
								)
							)
						})}
					</Box>
				) : (
					<CircularProgress
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginTop: '10px'
						}}
					/>
				)}

				<Button
					startIcon={<img style={{ marginRight: '10px' }} src={Like_Icon} />}
					style={{
						backgroundColor: '#fff',
						border: 'none',
						boxShadow: 'none'
					}}
					onClick={(e) => {
						handleReactionsPopper(e)
						e.stopPropagation()
					}}>
					React
				</Button>
				<ReactionsPopper
					open={openReactions}
					setOpenReactions={setOpenReactions}
					anchor={anchorEl}
					setAnchor={() => setAnchorEl(null)}
					hasReacted={hasReacted}
					setHasReacted={setHasReacted}
					wurrlyReaction={wurrlyReaction}
					addReaction={addReaction}
					removeReaction={removeReactionMutation}
					updateReaction={updateReactionMutation}
				/>
			</Box>
		</>
	)
}

export default ReactionsBox
