import { Box, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { QueryCatalogItemsType } from 'src/@types'
import { Search, SelectChord } from 'src/components'
import { useGetSongCatalogQuery } from 'src/graphql/autogenerate/hooks'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { TypeEnum } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'

import { Catalogs } from './components/Catalogs'
import { CatalogSongs } from './components/CatalogSongs/CatalogSongs'
import { useMusicCatalogContext } from './useMusicCatalog'

export const MusicCatalog = () => {
	const history = useHistory()
	const { challengeId } = useWurrlyParams()
	const catalogs = useGetSongCatalogQuery()
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const classId = params.get('classId') as unknown as number
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const { chords, setChords, catalogId, search, setSearch } = useMusicCatalogContext()

	const handleChord = (chords: string[]) => {
		setChords(chords)
	}

	const handleSearch = (search: string) => {
		setSearch(search)
	}

	const onSearchStudioClick = (classId: number, songId: number) => {
		history.push(
			buildRouteParameters(StudentPages.ChallengeStudentStudio, {
				songId,
				challengeId,
				classId
			})
		)
	}

	return (
		<>
			<Search
				teacherId={0}
				type={TypeEnum.Song}
				onChange={handleSearch}
				studentId={studentId}
				overrideSongClick={onSearchStudioClick}
				classId={classId}
			/>

			<Box mb={2}>
				<Typography variant="h6">
					<b>Browse by Chords</b>
				</Typography>
			</Box>
			<SelectChord onChange={handleChord} />

			{!!chords.length || catalogId || search ? (
				<CatalogSongs onSongClick={onSearchStudioClick} />
			) : (
				<>
					{!catalogs.loading && catalogs.data && (
						<Catalogs data={catalogs.data as QueryCatalogItemsType} classId={classId} />
					)}
				</>
			)}
		</>
	)
}
