import { environment } from 'src/environment'

export const mediaEmbed = {
	previewsInData: true,
	extraProviders: [
		{
			name: 'extraProvider',
			url: /^https?.*?\.(mp4|mkv|wmv|m4v|avi|flv|webm|flac|mka|m4a|aac|ogg)/,
			html: (match: [string]) => {
				return `<video style="border:0;width:100%;" loop controls muted ><source src="${match[0]}" type="video/mp4" />`
			}
		},
		{
			name: 'extraProvider',
			url: /^https?.*?\.mov/,
			html: (match: [string]) => {
				return `<iframe src="${environment.publicUrl}/teacher/frame?url=${match[0]}" style="border:0;height:300px;width:100%;" allowfullscreen></iframe>`
			}
		}
	]
}
