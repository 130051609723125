export type musicStandard = {
	category: string
	subCategories: subCategoryType[]
}

export type subCategoryType = {
	name: string
	description: string
	enduringUnderstanding: string
	options: selectOptionType[]
}
export type selectOptionType = {
	level: string
	title: string
	description: string
}

export enum MusicStandars {
	Creating = 'creating',
	Connecting = 'connecting',
	Performing = 'performing',
	Responding = 'responding'
}

export const Creating: musicStandard = {
	category: 'Creating',
	subCategories: [
		{
			name: 'Imagine',
			description: 'Generate musical ideas for various purposes and contexts.',
			enduringUnderstanding:
				'The creative ideas, concepts, and feelings that influence musicians’ work emerge from a variety of sources.',
			options: [
				{
					level: 'PK',
					title: 'MU:Cr1.1.PKa',
					description: 'With substantial guidance, explore and experience a variety of music.'
				},
				{
					level: 'K',
					title: 'MU:Cr1.1.Ka',
					description:
						'With guidance, explore and experience music concepts (such as beat and melodic contour).'
				},
				{
					level: 'K',
					title: 'MU:Cr1.1.Kb',
					description: 'With guidance, generate musical ideas (such as movements or motives).'
				},
				{
					level: '1',
					title: 'MU:Cr1.1.1a',
					description:
						'With limited guidance, create musical ideas (such as answering a musical question) for a specific purpose.'
				},
				{
					level: '1',
					title: 'MU:Cr1.1b',
					description:
						'With limited guidance, generate musical ideas in multiple tonalities (such as major and minor) and meters (such as duple and triple).'
				},
				{
					level: '2',
					title: 'MU:Cr1.1.2a',
					description:
						'Improvise rhythmic and melodic patterns and musical ideas for a specific purpose.'
				},
				{
					level: '2',
					title: 'MU:Cr1.1.2b',
					description:
						'Generate musical patterns and ideas within the context of a given tonality (such as major and minor) and meter (such as duple and triple).'
				},
				{
					level: '3',
					title: 'MU:Cr1.1.3a',
					description:
						'Improvise rhythmic and melodic ideas, and describe connection to specific purpose and context (such as personal and social).'
				},
				{
					level: '3',
					title: 'MU:Cr1.1.3b',
					description:
						'Generate musical ideas (such as rhythms and melodies) within a given tonality and/or meter.'
				},
				{
					level: '4',
					title: 'MU:Cr1.1.4a',
					description:
						'Improvise rhythmic, melodic, and harmonic ideas, and explain connection to specific purpose and context (such as social and cultural).'
				},
				{
					level: '4',
					title: 'MU:Cr1.1.4b',
					description:
						'Generate musical ideas (such as rhythms, melodies, and simple accompaniment patterns) within related tonalities (such as major and minor) and meters.'
				},
				{
					level: '5',
					title: 'MU:Cr1.1.5a',
					description:
						'Improvise rhythmic, melodic, and harmonic ideas, and explain connection to specific purpose and context (such as social, cultural, and historical).'
				},
				{
					level: '5',
					title: 'MU:Cr1.1.5b',
					description:
						'Generate musical ideas (such as rhythms, melodies, and accompaniment patterns) within specific related tonalities, meters, and simple chord changes.'
				},
				{
					level: '6',
					title: 'MU:Cr1.1.6a',
					description:
						'Generate simple rhythmic, melodic, and harmonic phrases within AB and ABA forms that convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Cr1.1.7a',
					description:
						'Generate rhythmic, melodic, and harmonic phrases and variations over harmonic accompaniments within AB, ABA, or theme and variation forms that convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Cr1.1.8a',
					description:
						'Generate rhythmic, melodic and harmonic phrases and harmonic accompaniments within expanded forms (including introductions, transitions, and codas) that convey expressive intent'
				}
			]
		},
		{
			name: 'Plan and Make',
			description: 'Select and develop musical ideas for defined purposes and contexts.',
			enduringUnderstanding:
				'Musicians’ creative choices are influenced by their expertise, context, and expressive intent.',
			options: [
				{
					level: 'PK',
					title: 'MU:Cr2.1.PKa',
					description:
						'With substantial guidance, explore favorite musical ideas (such as movements, vocalizations, or instrumental accompaniments).'
				},
				{
					level: 'PK',
					title: 'MU:Cr2.1.PKb',
					description:
						'With substantial guidance, select and keep track of the order for performing original musical ideas, using iconic notation and/or recording technology.'
				},
				{
					level: 'K',
					title: 'MU:Cr2.1.Ka',
					description: 'With guidance, demonstrate and choose favorite musical ideas.'
				},
				{
					level: 'K',
					title: 'MU:Cr2.1.Kb',
					description:
						'With guidance, organize personal musical ideas using iconic notation and/or recording technology.'
				},
				{
					level: '1',
					title: 'MU:Cr2.1.1a',
					description:
						'With limited guidance, demonstrate and discuss personal reasons for selecting musical ideas that represent expressive intent.'
				},
				{
					level: '1',
					title: 'MU:Cr2.1.1b',
					description:
						'With limited guidance, use iconic or standard notation and/or recording technology to document and organize personal musical ideas.'
				},
				{
					level: '2',
					title: 'MU:Cr2.1.2a',
					description:
						'Demonstrate and explain personal reasons for selecting patterns and ideas for music that represent expressive intent.'
				},
				{
					level: '2',
					title: 'MU:Cr2.1.2b',
					description:
						'Use iconic or standard notation and/or recording technology to combine, sequence, and document personal musical ideas.'
				},
				{
					level: '3',
					title: 'MU:Cr2.1.3a',
					description:
						'Demonstrate selected musical ideas for a simple improvisation or composition to express intent, and describe connection to a specific purpose and context.'
				},
				{
					level: '3',
					title: 'MU:Cr2.1.3b',
					description:
						'Use standard and/or iconic notation and/or recording technology to document personal rhythmic and melodic musical ideas.'
				},
				{
					level: '4',
					title: 'MU:Cr2.1.4a',
					description:
						'Demonstrate selected and organized musical ideas for an improvisation, arrangement, or composition to express intent, and explain connection to purpose and context.'
				},
				{
					level: '4',
					title: 'MU:Cr2.1.4b',
					description:
						'Use standard and/or iconic notation and/or recording technology to document personal rhythmic, melodic, and simple harmonic musical ideas.'
				},
				{
					level: '5',
					title: 'MU:Cr2.1.5a',
					description:
						'Demonstrate selected and developed musical ideas for improvisations, arrangements, or compositions to express intent, and explain connection to purpose and context.'
				},
				{
					level: '5',
					title: 'MU:Cr2.1.5b',
					description:
						'Use standard and/or iconic notation and/or recording technology to document personal rhythmic, melodic, and two-chord harmonic musical ideas.'
				},
				{
					level: '6',
					title: 'MU:Cr2.1.6a',
					description:
						'Select, organize, construct, and document personal musical ideas for arrangements and compositions within AB or ABA form that demonstrate an effective beginning, middle, and ending, and convey expressive intent.'
				},
				{
					level: '6',
					title: 'MU:Cr2.1.6b',
					description:
						'Use standard and/or iconic notation and/or audio/ video recording to document personal simple rhythmic phrases, melodic phrases, and twochord harmonic musical ideas.'
				},
				{
					level: '7',
					title: 'MU:Cr2.1.7a',
					description:
						'Select, organize, develop and document personal musical ideas for arrangements, songs, and compositions within AB, ABA, or theme and variation forms that demonstrate unity and variety and convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Cr2.1.7b',
					description:
						'Use standard and/or iconic notation and/or audio/ video recording to document personal simple rhythmic phrases, melodic phrases, and harmonic sequences.'
				},
				{
					level: '8',
					title: 'MU:Cr2.1.8a',
					description:
						'Select, organize, and document personal musical ideas for arrangements, songs, and compositions within expanded forms that demonstrate tension and release, unity and variety, balance, and convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Cr2.1.8b',
					description:
						'Use standard and/or iconic notation and/or audio/ video recording to document personal rhythmic phrases, melodic phrases, and harmonic sequences.'
				}
			]
		},
		{
			name: 'Evaluate and Refine',
			description:
				'Evaluate and refine selected musical ideas to create musical work(s) that meet appropriate criteria.',
			enduringUnderstanding:
				'Musicians evaluate, and refine their work through openness to new ideas, persistence, and the application of appropriate criteria.',
			options: [
				{
					level: 'PK',
					title: 'MU:Cr3.1.PKa',
					description:
						'With substantial guidance, consider personal, peer, and teacher feedback when demonstrating and refining personal musical ideas.'
				},
				{
					level: '6',
					title: 'MU:Cr3.1.6b',
					description:
						'Describe the rationale for making revisions to the music based on evaluation criteria and feedback from their teacher.'
				},
				{
					level: 'K',
					title: 'MU:Cr3.1.Ka',
					description:
						'With guidance, apply personal, peer, and teacher feedback in refining personal musical ideas.'
				},
				{
					level: '7',
					title: 'MU:Cr3.1.7b',
					description:
						'Describe the rationale for making revisions to the music based on evaluation criteria and feedback from others (teacher and peers).'
				},
				{
					level: '1',
					title: 'MU:Cr3.1.1a',
					description:
						'With limited guidance, discuss and apply personal, peer, and teacher feedback to refine personal musical ideas.'
				},
				{
					level: '8',
					title: 'MU:Cr3.1.8b',
					description:
						'Describe the rationale for refining works by explaining the choices, based on evaluation criteria.'
				},
				{
					level: '2',
					title: 'MU:Cr3.1.2a',
					description:
						'Interpret and apply personal, peer, and teacher feedback to revise personal music.'
				},
				{
					level: '3',
					title: 'MU:Cr3.1.3a',
					description:
						'Evaluate, refine, and document revisions to personal musical ideas, applying teacher-provided and collaborativelydeveloped criteria and feedback.'
				},
				{
					level: '4',
					title: 'MU:Cr3.1.4a',
					description:
						'Evaluate, refine, and document revisions to personal music, applying teacherprovided and collaborativelydeveloped criteria and feedback to show improvement over time.'
				},
				{
					level: '5',
					title: 'MU:Cr3.1.5a',
					description:
						'Evaluate, refine, and document revisions to personal music, applying teacherprovided and collaborativelydeveloped criteria and feedback, and explain rationale for changes.'
				},
				{
					level: '6',
					title: 'MU:Cr3.1.6a',
					description:
						'Evaluate their own work, applying teacher-provided criteria such as application of selected elements of music, and use of sound sources.'
				},
				{
					level: '7',
					title: 'MU:Cr3.1.7a',
					description:
						'Evaluate their own work, applying selected criteria such as appropriate application of elements of music including style, form, and use of sound sources.'
				},
				{
					level: '8',
					title: 'MU:Cr3.1.8a',
					description:
						'Evaluate their own work by selecting and applying criteria including appropriate application of compositional techniques, style, form, and use of sound sources'
				}
			]
		},
		{
			name: 'Present',
			description:
				'Share creative musical work that conveys intent, demonstrates craftsmanship, and exhibits originality.',
			enduringUnderstanding:
				'Musicians’ presentation of creative work is the culmination of a process of creation and communication',
			options: [
				{
					level: 'PK',
					title: 'MU:Cr3.2.PKa',
					description: 'With substantial guidance, share revised personal musical ideas with peers.'
				},
				{
					level: 'K',
					title: 'MU:Cr3.2.Ka',
					description: 'With guidance, demonstrate a final version of personal musical ideas to peers.'
				},
				{
					level: '1',
					title: 'MU:Cr3.2.1a',
					description:
						'With limited guidance, convey expressive intent for a specific purpose by presenting a final version of personal musical ideas to peers or informal audience.'
				},
				{
					level: '2',
					title: 'MU:Cr3.2.2a',
					description:
						'Convey expressive intent for a specific purpose by presenting a final version of personal musical ideas to peers or informal audience.'
				},
				{
					level: '3',
					title: 'MU:Cr3.2.3a',
					description:
						'Present the final version of personal created music to others, and describe connection to expressive intent.'
				},
				{
					level: '4',
					title: 'MU:Cr3.2.4a',
					description:
						'Present the final version of personal created music to others, and explain connection to expressive intent.'
				},
				{
					level: '5',
					title: 'MU:Cr3.2.5a',
					description:
						'Present the final version of personal created music to others that demonstrates craftsmanship, and explain connection to expressive intent.'
				},
				{
					level: '6',
					title: 'MU:Cr3.2.6a',
					description:
						'Present the final version of their documented personal composition or arrangement, using craftsmanship and originality to demonstrate an effective beginning, middle, and ending, and convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Cr3.2.7a',
					description:
						'Present the final version of their documented personal composition, song, or arrangement, using craftsmanship and originality to demonstrate unity and variety, and convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Cr3.2.8a',
					description:
						'Present the final version of their documented personal composition, song, or arrangement, using craftsmanship and originality to demonstrate the application of compositional techniques for creating unity and variety, tension and release, and balance to convey expressive inten'
				}
			]
		}
	]
}
export const Performing: musicStandard = {
	category: 'Performing',
	subCategories: [
		{
			name: 'Select',
			description:
				'Select varied musical works to present based on interest, knowledge, technical skill, and context.',
			enduringUnderstanding:
				'Performers’ interest in and knowledge of musical works, understanding of their own technical skill, and the context for a performance influence the selection of repertoire',
			options: [
				{
					level: 'PK',
					title: 'MU:Pr4.1.PKa',
					description:
						'With substantial guidance, demonstrate and state preference for varied musical selections.'
				},
				{
					level: 'K',
					title: 'MU:Pr4.1.Ka',
					description:
						'With guidance, demonstrate and state personal interest in varied musical selections.'
				},
				{
					level: '1',
					title: 'MU:Pr4.1.1a',
					description:
						'With limited guidance, demonstrate and discuss personal interest in, knowledge about, and purpose of varied musical selections.'
				},
				{
					level: '2',
					title: 'MU:Pr4.1.2a',
					description:
						'Demonstrate and explain personal interest in, knowledge about, and purpose of varied musical selections.'
				},
				{
					level: '3',
					title: 'MU:Pr4.1.3a',
					description:
						'Demonstrate and explain how the selection of music to perform is influenced by personal interest, knowledge, purpose, and context.'
				},
				{
					level: '4',
					title: 'MU:Pr4.1.4a',
					description:
						'Demonstrate and explain how the selection of music to perform is influenced by personal interest, knowledge, context, and technical skill.'
				},
				{
					level: '5',
					title: 'MU:Pr4.1.5a',
					description:
						'Demonstrate and explain how the selection of music to perform is influenced by personal interest, knowledge, and context, as well as their personal and others’ technical skill.'
				},
				{
					level: '6',
					title: 'MU:Pr4.1.6a',
					description:
						'Apply teacher-provided criteria for selecting music to perform for a specific purpose and/or context, and explain why each was chosen.'
				},
				{
					level: '7',
					title: 'MU:Pr4.1.7a',
					description:
						'Apply collaborativelydeveloped criteria for selecting music of contrasting styles for a program with a specific purpose and/or context and, after discussion, identify expressive qualities, technical challenges, and reasons for choices.'
				},
				{
					level: '8',
					title: 'MU:Pr4.1.8a',
					description:
						'Apply personallydeveloped criteria for selecting music of contrasting styles for a program with a specific purpose and/or context, and explain expressive qualities, technical challenges, and reasons for choices.'
				}
			]
		},
		{
			name: 'Analyze',
			description:
				'Analyze the structure and context of varied musical works and their implications for performance.',
			enduringUnderstanding:
				'Analyzing creators’ context and how they manipulate elements of music provides insight into their intent and informs performance.',
			options: [
				{
					level: 'PK',
					title: 'MU:Pr4.2.PKa',
					description:
						'With substantial guidance, explore and demonstrate awareness of musical contrasts.'
				},
				{
					level: 'K',
					title: 'MU:Pr4.2.Ka',
					description:
						'With guidance, explore and demonstrate awareness of music contrasts (such as high/low, loud/soft, same/different) in a variety of music selected for performance.'
				},
				{
					level: '1',
					title: 'MU:Pr4.2.1a',
					description:
						'With limited guidance, demonstrate knowledge of music concepts (such as beat and melodic contour) in music from a variety of cultures selected for performance.'
				},
				{
					level: '1',
					title: 'MU:Pr4.2.1b',
					description:
						'When analyzing selected music, read and perform rhythmic patterns using iconic or standard notation.'
				},
				{
					level: '2',
					title: 'MU:Pr4.2.2a',
					description:
						'Demonstrate knowledge of music concepts (such as tonality and meter) in music from a variety of cultures selected for performance.'
				},
				{
					level: '2',
					title: 'MU:Pr4.2.2b',
					description:
						'When analyzing selected music, read and perform rhythmic and melodic patterns using iconic or standard notation.'
				},
				{
					level: '3',
					title: 'MU:Pr4.2.3a',
					description: 'Demonstrate understanding of the structure in music selected for performance.'
				},
				{
					level: '3',
					title: 'MU:Pr4.2.3b',
					description:
						'When analyzing selected music, read and perform rhythmic patterns and melodic phrases using iconic and standard notation.'
				},
				{
					level: '3',
					title: 'MU:Pr4.2.3c',
					description: 'Describe how context (such as personal and social) can inform a performance.'
				},
				{
					level: '4',
					title: 'MU:Pr4.2.4a',
					description:
						'Demonstrate understanding of the structure and the elements of music (such as rhythm, pitch, and form) in music selected for performance.'
				},
				{
					level: '4',
					title: 'MU:Pr4.2.4b',
					description:
						'When analyzing selected music, read and perform using iconic and/or standard notation.'
				},
				{
					level: '4',
					title: 'MU:Pr4.2.4c',
					description: 'Explain how context (such as social and cultural) informs a performance.'
				},
				{
					level: '5',
					title: 'MU:Pr4.2.5a',
					description:
						'Demonstrate understanding of the structure and the elements of music (such as rhythm, pitch, form, and harmony) in music selected for performance.'
				},
				{
					level: '5',
					title: 'MU:Pr4.2.5b',
					description: 'When analyzing selected music, read and perform using standard notation.'
				},
				{
					level: '5',
					title: 'MU:Pr4.2.5c',
					description:
						'Explain how context (such as social, cultural, and historical) informs performances.'
				},
				{
					level: '6',
					title: 'MU:Pr4.2.6a',
					description:
						'Explain how understanding the structure and the elements of music are used in music selected for performance.'
				},
				{
					level: '6',
					title: 'MU:Pr4.2.6b',
					description:
						'When analyzing selected music, read and identify by name or function standard symbols for rhythm, pitch, articulation, and dynamics.'
				},
				{
					level: '6',
					title: 'MU:Pr4.2.6c',
					description: 'Identify how cultural and historical context inform performances.'
				},
				{
					level: '7',
					title: 'MU:Pr4.2.7a',
					description:
						'Explain and demonstrate the structure of contrasting pieces of music selected for performance and how elements of music are used.'
				},
				{
					level: '7',
					title: 'MU:Pr4.2.7b',
					description:
						'When analyzing selected music, read and identify by name or function standard symbols for rhythm, pitch, articulation, dynamics, tempo, and form.'
				},
				{
					level: '7',
					title: 'MU:Pr4.2.7c',
					description:
						'Identify how cultural and historical context inform performances and result in different music interpretations.'
				},
				{
					level: '8',
					title: 'MU:Pr4.2.8a',
					description:
						'Compare the structure of contrasting pieces of music selected for performance, explaining how the elements of music are used in each.'
				},
				{
					level: '8',
					title: 'MU:Pr4.2.8b',
					description:
						'When analyzing selected music, sight-read in treble or bass clef simple rhythmic, melodic, and/or harmonic notation.'
				},
				{
					level: '8',
					title: 'MU:Pr4.2.8c',
					description:
						'Identity how cultural and historical context inform performances and result in different musical effects'
				}
			]
		},
		{
			name: 'Interpret',
			description: 'Develop personal interpretations that consider creators’ intent.',
			enduringUnderstanding:
				'Performers make interpretive decisions based on their understanding of context and expressive intent.',
			options: [
				{
					level: 'PK',
					title: 'MU:Pr4.3.PKa',
					description:
						'With substantial guidance, explore music’s expressive qualities (such as voice quality, dynamics, and tempo).'
				},
				{
					level: 'K',
					title: 'MU:Pr4.3.Ka',
					description:
						'With guidance, demonstrate awareness of expressive qualities (such as voice quality, dynamics, and tempo) that support the creators’ expressive intent.'
				},
				{
					level: '1',
					title: 'MU:Pr4.3.1a',
					description:
						'Demonstrate and describe music’s expressive qualities (such as dynamics and tempo).'
				},
				{
					level: '2',
					title: 'MU:Pr4.3.2a',
					description:
						'Demonstrate understanding of expressive qualities (such as dynamics and tempo) and how creators use them to convey expressive intent.'
				},
				{
					level: '3',
					title: 'MU:Pr4.3.3a',
					description:
						'Demonstrate and describe how intent is conveyed through expressive qualities (such as dynamics and tempo).'
				},
				{
					level: '4',
					title: 'MU:Pr4.3.4a',
					description:
						'Demonstrate and explain how intent is conveyed through interpretive decisions and expressive qualities (such as dynamics, tempo, and timbre).'
				},
				{
					level: '5',
					title: 'MU:Pr4.3.5a',
					description:
						'Demonstrate and explain how intent is conveyed through interpretive decisions and expressive qualities (such as dynamics, tempo, timbre, and articulation/style).'
				},
				{
					level: '6',
					title: 'MU:Pr4.3.6a',
					description:
						'Perform a selected piece of music demonstrating how their interpretations of the elements of music and the expressive qualities (such as dynamics, tempo, timbre, articulation/style, and phrasing) convey intent.'
				},
				{
					level: '7',
					title: 'MU:Pr4.3.7a',
					description:
						'Perform contrasting pieces of music demonstrating their interpretations of the elements of music and expressive qualities (such as dynamics, tempo, timbre, articulation/style, and phrasing) convey intent.'
				},
				{
					level: '8',
					title: 'MU:Pr4.3.8a',
					description:
						'Perform contrasting pieces of music, demonstrating as well as explaining how the music’s intent is conveyed by their interpretations of the elements of music and expressive qualities (such as dynamics, tempo, timbre, articulation/style, and phrasing).'
				}
			]
		},
		{
			name: 'Rehearse, Evaluate and Refine',
			description:
				'Evaluate and refine personal and ensemble performances, individually or in collaboration with others.',
			enduringUnderstanding:
				'To express their musical ideas, musicians analyze, evaluate, and refine their performance over time through openness to new ideas, persistence, and the application of appropriate criteria',
			options: [
				{
					level: 'PK',
					title: 'MU:Pr5.1.PKa',
					description:
						'With substantial guidance, practice and demonstrate what they like about their own performances.'
				},
				{
					level: 'PK',
					title: 'MU:Pr5.1.PKb',
					description:
						'With substantial guidance, apply personal, peer, and teacher feedback to refine performances.'
				},
				{
					level: 'K',
					title: 'MU:Pr5.1.Ka',
					description:
						'With guidance, apply personal, teacher, and peer feedback to refine performances.'
				},
				{
					level: 'K',
					title: 'MU:Pr5.1.Kb',
					description:
						'With guidance, use suggested strategies in rehearsal to improve the expressive qualities of music.'
				},
				{
					level: '1',
					title: 'MU:Pr5.1.1a',
					description:
						'With limited guidance, apply personal, teacher, and peer feedback to refine performances.'
				},
				{
					level: '1',
					title: 'MU:Pr5.1.1b',
					description:
						'With limited guidance, use suggested strategies in rehearsal to address interpretive challenges of music.'
				},
				{
					level: '2',
					title: 'MU:Pr5.1.2a',
					description:
						'Apply established criteria to judge the accuracy, expressiveness, and effectiveness of performances.'
				},
				{
					level: '2',
					title: 'MU:Pr5.1.2b',
					description:
						'– Rehearse, identify and apply strategies to address interpretive, performance, and technical challenges of music.'
				},
				{
					level: '3',
					title: 'MU:Pr5.1.3a',
					description:
						'Apply teacherprovided and collaborativelydeveloped criteria and feedback to evaluate accuracy of ensemble performances.'
				},
				{
					level: '3',
					title: 'MU:Pr5.1.3b',
					description:
						'Rehearse to refine technical accuracy, expressive qualities, and identified performance challenges.'
				},
				{
					level: '4',
					title: 'MU:Pr5.1.4a',
					description:
						'Apply teacher-provided and collaborativelydeveloped criteria and feedback to evaluate accuracy and expressiveness of ensemble and personal performances.'
				},
				{
					level: '4',
					title: 'MU:Pr5.1.4b',
					description:
						'Rehearse to refine technical accuracy and expressive qualities, and address performance challenges.'
				},
				{
					level: '5',
					title: 'MU:Pr5.1.5a',
					description:
						'Apply teacher-provided and established criteria and feedback to evaluate the accuracy and expressiveness of ensemble and personal performances.'
				},
				{
					level: '5',
					title: 'MU:Pr5.1.5b',
					description:
						'Rehearse to refine technical accuracy and expressive qualities to address challenges, and show improvement over time.'
				},
				{
					level: '6',
					title: 'MU:Pr5.1.6a',
					description:
						'Identify and apply teacher-provided criteria (such as correct interpretation of notation, technical accuracy, originality, and interest) to rehearse, refine, and determine when a piece is ready to perform.'
				},
				{
					level: '7',
					title: 'MU:Pr5.1.7a',
					description:
						'Identify and apply collaborativelydeveloped criteria (such as demonstrating correct interpretation of notation, technical skill of performer, originality, emotional impact, and interest) to rehearse, refine, and determine when the music is ready to perform.'
				},
				{
					level: '8',
					title: 'MU:Pr5.1.8a',
					description:
						'Identify and apply personallydeveloped criteria (such as demonstrating correct interpretation of notation, technical skill of performer, originality, emotional impact, variety, and interest) to rehearse, refine, and determine when the music is ready to perform'
				}
			]
		},
		{
			name: 'Present',
			description:
				'Perform expressively, with appropriate interpretation and technical accuracy, and in a manner appropriate to the audience and context.',
			enduringUnderstanding:
				'Musicians judge performance based on criteria that vary across time, place, and cultures. The context and how a work is presented influence the audience response.',
			options: [
				{
					level: 'PK',
					title: 'MU:Pr6.1.PKa',
					description: 'With substantial guidance, perform music with expression'
				},
				{ level: 'K', title: 'MU:Pr6.1.Ka', description: 'With guidance, perform music with expression' },
				{ level: 'K', title: 'MU:Pr6.1.Kb', description: 'Perform appropriately for the audience' },
				{
					level: '1',
					title: 'MU:Pr6.1.1a',
					description: 'With limited guidance, perform music for a specific purpose with expression.'
				},
				{
					level: '1',
					title: 'MU:Pr6.1.1b',
					description: 'Perform appropriately for the audience and purpose'
				},
				{
					level: '2',
					title: 'MU:Pr6.1.2a',
					description: 'Perform music for a specific purpose with expression and technical accuracy.'
				},
				{
					level: '2',
					title: 'MU:Pr6.1.2b',
					description: 'Perform appropriately for the audience and purpose'
				},
				{
					level: '3',
					title: 'MU:Pr6.1.3a',
					description: 'Perform music with expression and technical accuracy'
				},
				{
					level: '3',
					title: 'MU:Pr6.1.3b',
					description:
						'Demonstrate performance decorum and audience etiquette appropriate for the context and venue.'
				},
				{
					level: '4',
					title: 'MU:Pr6.1.4a',
					description:
						'Perform music, alone or with others, with expression and technical accuracy, and appropriate interpretation.'
				},
				{
					level: '4',
					title: 'MU:Pr6.1.4b',
					description:
						'Demonstrate performance decorum and audience etiquette appropriate for the context, venue, and genre.'
				},
				{
					level: '5',
					title: 'MU:Pr6.1.5a',
					description:
						'Perform music, alone or with others, with expression, technical accuracy, and appropriate interpretation.'
				},
				{
					level: '5',
					title: 'MU:Pr6.1.5b',
					description:
						'Demonstrate performance decorum and audience etiquette appropriate for the context, venue, genre, and style.'
				},
				{
					level: '6',
					title: 'MU:Pr6.1.6a',
					description: 'Perform the music with technical accuracy to convey the creator’s intent.'
				},
				{
					level: '6',
					title: 'MU:Pr6.1.6b',
					description:
						'Demonstrate performance decorum (such as stage presence, attire, and behavior) and audience etiquette appropriate for venue and purpose.'
				},
				{
					level: '7',
					title: 'MU:Pr6.1.7a',
					description:
						'Perform the music with technical accuracy and stylistic expression to convey the creator’s intent.'
				},
				{
					level: '7',
					title: 'MU:Pr6.1.7b',
					description:
						'Demonstrate performance decorum (such as stage presence, attire, and behavior) and audience etiquette appropriate for venue, purpose, and context.'
				},
				{
					level: '8',
					title: 'MU:Pr6.1.8a',
					description:
						'Perform the music with technical accuracy, stylistic expression, and culturally authentic practices in music to convey the creator’s intent.'
				},
				{
					level: '8',
					title: 'MU:Pr6.1.8b',
					description:
						'Demonstrate performance decorum (such as stage presence, attire, and behavior) and audience etiquette appropriate for venue, purpose, context, and style.'
				}
			]
		}
	]
}
export const Responding: musicStandard = {
	category: 'Responding',
	subCategories: [
		{
			name: 'Select',
			description: 'Choose music appropriate for a specific purpose or context.',
			enduringUnderstanding:
				'Individuals selection of musical works is influenced by their interests, experiences, understandings, and purposes. ',
			options: [
				{
					level: 'PK',
					title: 'MU:Re7.1.PKa',
					description:
						'With substantial guidance, state personal interests and demonstrate why they prefer some music selections over others.'
				},
				{
					level: 'K',
					title: 'MU:Re7.1.Ka',
					description:
						'With guidance, list personal interests and experiences and demonstrate why they prefer some music selections over others.'
				},
				{
					level: '1',
					title: 'MU:Re7.1.1a',
					description:
						'With limited guidance, identify and demonstrate how personal interests and experiences influence musical selection for specific purposes.'
				},
				{
					level: '2',
					title: 'MU:Re7.1.2a',
					description:
						'Explain and demonstrate how personal interests and experiences influence musical selection for specific purposes.'
				},
				{
					level: '3',
					title: 'MU:Re7.1.3a',
					description:
						'Demonstrate and describe how selected music connects to and is influenced by specific interests, experiences, or purposes.'
				},
				{
					level: '4',
					title: 'MU:Re7.1.4a',
					description:
						'Demonstrate and explain how selected music connects to and is influenced by specific interests, experiences, purposes, or contexts.'
				},
				{
					level: '5',
					title: 'MU:Re7.1.5a',
					description:
						'Demonstrate and explain, citing evidence, how selected music connects to and is influenced by specific interests, experiences, purposes, or contexts.'
				},
				{
					level: '6',
					title: 'MU:Re7.1.6a',
					description:
						'Select or choose music to listen to and explain the connections to specific interests or experiences for a specific purpose.'
				},
				{
					level: '7',
					title: 'MU:Re7.1.7a',
					description:
						'Select or choose contrasting music to listen to and compare the connections to specific interests or experiences for a specific purpose.'
				},
				{
					level: '8',
					title: 'MU:Re7.1.8a',
					description:
						'Select programs of music (such as a CD mix or live performances) and demonstrate the connections to an interest or experience for a specific purpose.'
				}
			]
		},
		{
			name: 'Analyze',
			description: 'Analyze how the structure and context of varied musical works inform the response.',
			enduringUnderstanding:
				'Response to music is informed by analyzing context (social, cultural, and historical) and how creators and performers manipulate the elements of music.',
			options: [
				{
					level: 'PK',
					title: 'MU:Re7.2.PKa',
					description: 'With substantial guidance, explore musical contrasts in music.'
				},
				{
					level: 'K',
					title: 'MU:Re7.2.Ka',
					description:
						'With guidance, demonstrate how a specific music concept (such as beat or melodic direction) is used in music.'
				},
				{
					level: '1',
					title: 'MU:Re7.2.1a',
					description:
						'With limited guidance, demonstrate and identify how specific music concepts (such as beat or pitch) are used in various styles of music for a purpose.'
				},
				{
					level: '2',
					title: 'MU:Re7.2.2a',
					description:
						'Describe how specific music concepts are used to support a specific purpose in music.'
				},
				{
					level: '3',
					title: 'MU:Re7.2.3a',
					description:
						'Demonstrate and describe how a response to music can be informed by the structure, the use of the elements of music, and context (such as personal and social).'
				},
				{
					level: '4',
					title: 'MU:Re7.2.4a',
					description:
						'Demonstrate and explain how responses to music are informed by the structure, the use of the elements of music, and context (such as social and cultural).'
				},
				{
					level: '5',
					title: 'MU:Re7.2.5a',
					description:
						'Demonstrate and explain, citing evidence, how responses to music are informed by the structure, the use of the elements of music, and context (such as social, cultural, and historical).'
				},
				{
					level: '6',
					title: 'MU:Re7.2.6a',
					description:
						'Describe how the elements of music and expressive qualities relate to the structure of the pieces.'
				},
				{
					level: '6',
					title: 'MU:Re7.2.6b',
					description:
						'Identify the context of music from a variety of genres, cultures, and historical periods.'
				},
				{
					level: '7',
					title: 'MU:Re7.2.7a',
					description:
						'Classify and explain how the elements of music and expressive qualities relate to the structure of contrasting pieces.'
				},
				{
					level: '7',
					title: 'MU:Re7.2.7b',
					description:
						'Identify and compare the context of music from a variety of genres, cultures, and historical periods.'
				},
				{
					level: '8',
					title: 'MU:Re7.2.8a',
					description:
						'Compare how the elements of music and expressive qualities relate to the structure within programs of music.'
				},
				{
					level: '8',
					title: 'MU:Re7.2.8b',
					description:
						'Identify and compare the context of programs of music from a variety of genres, cultures, and historical periods.'
				}
			]
		},
		{
			name: 'Interpret',
			description:
				'Support interpretations of musical works that reflect creators’/performers’ expressive intent.',
			enduringUnderstanding:
				'Through their use of elements and structures of music, creators and performers provide clues to their expressive intent. ',
			options: [
				{
					level: 'PK',
					title: 'MU:Re8.1.PKa',
					description:
						'With substantial guidance, explore music’s expressive qualities (such as dynamics and tempo).'
				},
				{
					level: 'K',
					title: 'MU:Re8.1.Ka',
					description:
						'With guidance, demonstrate awareness of expressive qualities (such as dynamics and tempo) that reflect creators’/perform ers’ expressive intent.'
				},
				{
					level: '1',
					title: 'MU:Re8.1.1a',
					description:
						'With limited guidance, demonstrate and identify expressive qualities (such as dynamics and tempo) that reflect creators’/ performers’ expressive intent.'
				},
				{
					level: '2',
					title: 'MU:Re8.1.2a',
					description:
						'Demonstrate knowledge of music concepts and how they support creators’/ performers’ expressive intent.'
				},
				{
					level: '3',
					title: 'MU:Re8.1.3a',
					description:
						'Demonstrate and describe how the expressive qualities (such as dynamics and tempo) are used in performers’ interpretations to reflect expressive intent.'
				},
				{
					level: '4',
					title: 'MU:Re8.1.4a',
					description:
						'Demonstrate and explain how the expressive qualities (such as dynamics, tempo, and timbre) are used in performers’ and personal interpretations to reflect expressive intent.'
				},
				{
					level: '5',
					title: 'MU:Re8.1.5a',
					description:
						'Demonstrate and explain how the expressive qualities (such as dynamics, tempo, timbre, and articulation) are used in performers’ and personal interpretations to reflect expressive intent.'
				},
				{
					level: '6',
					title: 'MU:Re8.1.6a',
					description:
						'Describe a personal interpretation of how creators’ and performers’ application of the elements of music and expressive qualities, within genres and cultural and historical context, convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Re8.1.7a',
					description:
						'Describe a personal interpretation of contrasting works and explain how creators’ and performers’ application of the elements of music and expressive qualities, within genres, cultures, and historical periods, convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Re8.1.8a',
					description:
						'Support personal interpretation of contrasting programs of music and explain how creators’ or performers’ apply the elements of music and expressive qualities, within genres, cultures, and historical periods to convey expressive intent'
				}
			]
		},
		{
			name: 'Evaluate',
			description:
				'Support evaluations of musical works and performances based on analysis, interpretation, and established criteria.',
			enduringUnderstanding:
				'The personal evaluation of musical work(s) and performance(s) is informed by analysis, interpretation, and established criteria.',
			options: [
				{
					level: 'PK',
					title: 'MU:Re9.1.PKa',
					description:
						'With substantial guidance, talk about personal and expressive preferences in music.'
				},
				{
					level: 'K',
					title: 'MU:Re9.1.Ka',
					description:
						'With guidance, apply personal and expressive preferences in the evaluation of music.'
				},
				{
					level: '1',
					title: 'MU:Re9.1.1a',
					description:
						'With limited guidance, apply personal and expressive preferences in the evaluation of music for specific purposes.'
				},
				{
					level: '2',
					title: 'MU:Re9.1.2a',
					description:
						'Apply personal and expressive preferences in the evaluation of music for specific purposes.'
				},
				{
					level: '3',
					title: 'MU:Re9.1.3a',
					description:
						'Evaluate musical works and performances, applying established criteria, and describe appropriateness to the context.'
				},
				{
					level: '4',
					title: 'MU:Re9.1.4a',
					description:
						'Evaluate musical works and performances, applying established criteria, and explain appropriateness to the context.'
				},
				{
					level: '5',
					title: 'MU:Re9.1.5a',
					description:
						'Evaluate musical works and performances, applying established criteria, and explain appropriateness to the context, citing evidence from the elements of music.'
				},
				{
					level: '6',
					title: 'MU:Re9.1.6a',
					description: 'Apply teacher-provided criteria to evaluate musical works or performances.'
				},
				{
					level: '7',
					title: 'MU:Re9.1.7a',
					description: 'Select from teacher-provided criteria to evaluate musical works or performances.'
				},
				{
					level: '8',
					title: 'MU:Re9.1.8a',
					description:
						'Apply appropriate personally developed criteria to evaluate musical works or performances.'
				}
			]
		}
	]
}
export const Connecting: musicStandard = {
	category: 'Connecting',
	subCategories: [
		{
			name: 'Connect #10 ',
			description: 'Synthesize and relate knowledge and personal experiences to make music.',
			enduringUnderstanding:
				'Musicians connect their personal interests, experiences, ideas, and knowledge to creating, performing, and responding.',
			options: [
				{
					level: 'PK',
					title: 'MU:Cn10.0.PKa',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: 'PK',
					title: 'MU:Cr3.2.PKa',
					description: 'With substantial guidance, share revised musical ideas with peers.'
				},
				{
					level: 'PK',
					title: 'MU:Pr4.1.PKa',
					description:
						'With substantial guidance, demonstrate and state preference for varied musical selections.'
				},
				{
					level: 'PK',
					title: 'MU:Pr4.3.PKa',
					description:
						'With substantial guidance, explore music’s expressive qualities (such as voice quality, dynamics, and tempo).'
				},
				{
					level: 'K',
					title: 'MU:Cn10.0.Ka',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: 'K',
					title: 'MU:Cr3.2.Ka',
					description: 'With guidance, demonstrate a final version of personal musical ideas to peers.'
				},
				{
					level: 'K',
					title: 'MU:Pr4.1.Ka',
					description:
						'With guidance, demonstrate and state personal interest in varied musical selections.'
				},
				{
					level: 'K',
					title: 'MU:Pr4.3.Ka',
					description:
						'With guidance, demonstrate awareness of expressive qualities (such as voice quality, dynamics, and tempo) that support the creators’ expressive intent.'
				},
				{
					level: '1',
					title: 'MU:Cn10.0.1a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '1',
					title: 'MU:Cr2.1.1a',
					description:
						'With limited guidance, demonstrate and discuss personal reasons for selecting musical ideas that represent expressive intent.'
				},
				{
					level: '1',
					title: 'MU:Cr3.2.1a',
					description:
						'With limited guidance, convey expressive intent for a specific purpose by presenting a final version of personal musical ideas to peers or informal audience.'
				},
				{
					level: '1',
					title: 'MU:Pr4.3.1a',
					description:
						'Demonstrate and describe music’s expressive qualities (such as dynamics and tempo).'
				},
				{
					level: '1',
					title: 'MU:Re7.1.1a',
					description:
						'With limited guidance, identify and demonstrate how personal interests and experiences influence musical selection for specific purposes.'
				},
				{
					level: '2',
					title: 'MU:Cn10.0.2a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '2',
					title: 'MU:Cr2.1.2a',
					description:
						'Demonstrate and explain personal reasons for selecting patterns and ideas for their music that represent expressive intent.'
				},
				{
					level: '2',
					title: 'MU:Cr3.2.2a',
					description:
						'Convey expressive intent for a specific purpose by presenting a final version of personal musical ideas to peers or informal audience.'
				},
				{
					level: '2',
					title: 'MU:Pr4.3.2a',
					description:
						'Demonstrate understanding of expressive qualities (such as dynamics and tempo) and how creators use them to convey expressive intent.'
				},
				{
					level: '2',
					title: 'MU:Re7.1.2a',
					description:
						'Explain and demonstrate how personal interests and experiences influence musical selection for specific purposes.'
				},
				{
					level: '3',
					title: 'MU:Cn10.0.3a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '3',
					title: 'MU:Cr2.1.3a',
					description:
						'Demonstrate selected musical ideas for a simple improvisation or composition to express intent, and describe connection to a specific purpose and context.'
				},
				{
					level: '3',
					title: 'MU:Cr3.2.3a',
					description:
						'Present the final version of created music for others, and describe connection to expressive intent.'
				},
				{
					level: '3',
					title: 'MU:Pr4.1.3a',
					description:
						'Demonstrate and explain how the selection of music to perform is influenced by personal interest, knowledge, purpose, and context.'
				},
				{
					level: '3',
					title: 'MU:Pr4.3.3a',
					description:
						'Demonstrate and describe how intent is conveyed through expressive qualities (such as dynamics and tempo).'
				},
				{
					level: '3',
					title: 'MU:Re7.1.3a',
					description:
						'Demonstrate and describe how selected music connects to and is influenced by specific interests, experiences, or purposes.'
				},
				{
					level: '4',
					title: 'MU:Cn10.0.4a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '4',
					title: 'MU:Cr2.1.4a',
					description:
						'Demonstrate selected and organized musical ideas for an improvisation, arrangement, or composition to express intent, and explain connection to purpose and context.'
				},
				{
					level: '4',
					title: 'MU:Cr3.2.4a',
					description:
						'Present the final version of created music for others, and explain connection to expressive intent.'
				},
				{
					level: '4',
					title: 'MU:Pr4.1.4a',
					description:
						'Demonstrate and explain how the selection of music to perform is influenced by personal interest, knowledge, context, and technical skill.'
				},
				{
					level: '4',
					title: 'MU:Pr4.3.4a',
					description:
						'Demonstrate and explain how intent is conveyed through interpretive decisions and expressive qualities (such as dynamics, tempo, and timbre).'
				},
				{
					level: '4',
					title: 'MU:Re7.1.4a',
					description:
						'Demonstrate and explain how selected music connects to and is influenced by specific interests, experiences, purposes, or contexts.'
				},
				{
					level: '5',
					title: 'MU:Cn10.0.5a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '5',
					title: 'MU:Cr2.1.5a',
					description:
						'Demonstrate selected and developed musical ideas for improvisations, arrangements, or compositions to express intent, and explain connection to purpose and context.'
				},
				{
					level: '5',
					title: 'MU:Cr3.2.5a',
					description:
						'Present the final version of created music for others that demonstrates craftsmanship, and explain connection to expressive intent.'
				},
				{
					level: '5',
					title: 'MU:Pr4.1.5a',
					description:
						'Demonstrate and explain how the selection of music to perform is influenced by personal interest, knowledge, context, as well as their personal and others’ technical skill.'
				},
				{
					level: '5',
					title: 'MU:Pr4.3.5a',
					description:
						'Demonstrate and explain how intent is conveyed through interpretive decisions and expressive qualities (such as dynamics, tempo, timbre, and articulation/style).'
				},
				{
					level: '5',
					title: 'MU:Re7.1.5a',
					description:
						'Demonstrate and explain, citing evidence, how selected music connects to, and is influenced by specific interests, experiences, purposes, or contexts.'
				},
				{
					level: '6',
					title: 'MU:Cn10.0.6a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '6',
					title: 'MU:Cr2.1.6a',
					description:
						'Select, organize, construct, and document personal musical ideas for arrangements and compositions within AB or ABA form that demonstrate an effective beginning, middle, and ending, and convey expressive intent.'
				},
				{
					level: '6',
					title: 'MU:Cr3.2.6a',
					description:
						'Present the final version of their documented personal composition or arrangement, using craftsmanship and originality to demonstrate an effective beginning, middle, and ending, and convey expressive intent.'
				},
				{
					level: '6',
					title: 'MU:Pr4.1.6a',
					description:
						'Apply teacher-provided criteria for selecting music to perform for a specific purpose and/or context and explain why each was chosen.'
				},
				{
					level: '6',
					title: 'MU:Pr4.3.6a',
					description:
						'Perform a selected piece of music demonstrating how their interpretations of the elements of music and the expressive qualities (such as dynamics, tempo, timbre, articulation/style, and phrasing) convey intent.'
				},
				{
					level: '6',
					title: 'MU:Re7.1.6a',
					description:
						'Select or choose music to listen to and explain the connections to specific interests or experiences for a specific purpose.'
				},
				{
					level: '7',
					title: 'MU:Cn10.0.7a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '7',
					title: 'MU:Cr2.1.7a',
					description:
						'Select, organize, develop and document personal musical ideas for arrangements, songs, and compositions within AB, ABA, or theme and variation forms that demonstrate unity and variety and convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Cr3.2.7a',
					description:
						'Present the final version of their documented personal composition, song, or arrangement, using craftsmanship and originality to demonstrate unity and variety, and convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Pr4.1.7a',
					description:
						'Apply collaborativelydeveloped criteria for selecting music of contrasting styles for a program with a specific purpose and/or context and, after discussion, identify expressive qualities, technical challenges, and reasons for choices.'
				},
				{
					level: '7',
					title: 'MU:Pr4.3.7a',
					description:
						'Perform contrasting pieces of music demonstrating their personal interpretations of the elements of music and expressive qualities (such as dynamics, tempo, timbre, articulation/style, and phrasing) convey intent.'
				},
				{
					level: '7',
					title: 'MU:Re7.1.7a',
					description:
						'Select or choose contrasting music to listen to and compare the connection to specific interests or experiences for a specific purpose.'
				},
				{
					level: '8',
					title: 'MU:Cn10.0.8a',
					description:
						'Demonstrate how interests, knowledge, and skills relate to personal choices and intent when creating, performing, and responding to music.'
				},
				{
					level: '8',
					title: 'MU:Cr2.1.8a',
					description:
						'Select, organize, and document personal musical ideas for arrangements, songs, and compositions within expanded forms that demonstrate tension and release, unity and variety, and balance, and convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Cr3.2.8a',
					description:
						'Present the final version of their documented personal composition, song, or arrangement, using craftsmanship and originality to demonstrate the application of compositional techniques for creating unity and variety, tension and release, and balance to convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Pr4.1.8a',
					description:
						'Apply personally-developed criteria for selecting music of contrasting styles for a program with a specific purpose and/or context and explain expressive qualities, technical challenges, and reasons for choices.'
				},
				{
					level: '8',
					title: 'MU:Pr4.3.8a',
					description:
						'Perform contrasting pieces of music, demonstrating as well as explaining how the music’s intent is conveyed by their interpretations of the elements of music and expressive qualities (such as dynamics, tempo, timbre, articulation/style, and phrasing).'
				},
				{
					level: '8',
					title: 'MU:Re7.1.8a',
					description:
						'Select programs of music (such as a CD mix or live performances) and demonstrate the connections to an interest or experience for a specific purpose.'
				}
			]
		},
		{
			name: 'Connect #11',
			description: 'Relate musical ideas and works with varied context to deepen understanding.',
			enduringUnderstanding:
				'Understanding connections to varied contexts and daily life enhances musicians’ creating, performing, and responding.',
			options: [
				{
					level: 'PK',
					title: 'MU:Cn11.0.PKa',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: 'PK',
					title: 'MU:Pr4.2.PKa',
					description:
						'With substantial guidance, explore and demonstrate awareness of musical contrasts.'
				},
				{
					level: 'PK',
					title: 'MU:Re7.2.PKa',
					description: 'With substantial guidance, explore musical contrasts in music.'
				},
				{
					level: 'PK',
					title: 'MU:Re9.1.PKa',
					description:
						'With substantial guidance, talk about their personal and expressive preferences in music.'
				},
				{
					level: 'K',
					title: 'MU:Cn11.0.Ka',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: 'K',
					title: 'MU:Pr4.2.Ka',
					description:
						'With guidance, explore and demonstrate awareness of music contrasts (such as high/low, loud/soft, same/different) in a variety of music selected for performance.'
				},
				{
					level: 'K',
					title: 'MU:Re7.2.Ka',
					description:
						'With guidance, demonstrate how a specific music concept (such as beat or melodic direction) is used in music.'
				},
				{
					level: 'K',
					title: 'MU:Re9.1.Ka',
					description:
						'With guidance, apply personal and expressive preferences in the evaluation of music.'
				},
				{
					level: '1',
					title: 'MU:Cn11.0.1a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '1',
					title: 'MU:Cr1.1.1a',
					description:
						'With limited guidance, create musical ideas (such as answering a musical question) for a specific purpose.'
				},
				{
					level: '1',
					title: 'MU:Pr4.2.1a',
					description:
						'With limited guidance, demonstrate knowledge of music concepts (such as beat and melodic contour) in music from a variety of cultures selected for performance.'
				},
				{
					level: '1',
					title: 'MU:Pr6.1.1a',
					description: 'With limited guidance, perform music for a specific purpose with expression.'
				},
				{
					level: '1',
					title: 'MU:Re7.2.1a',
					description:
						'With limited guidance, demonstrate and identify how specific music concepts (such as beat or pitch) is used in various styles of music for a purpose.'
				},
				{
					level: '1',
					title: 'MU:Re9.1.1a',
					description:
						'With limited guidance, apply personal and expressive preferences in the evaluation of music for specific purposes.'
				},
				{
					level: '2',
					title: 'MU:Cn11.0.2a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '2',
					title: 'MU:Cr1.1.2a',
					description:
						'Improvise rhythmic and melodic patterns and musical ideas for a specific purpose.'
				},
				{
					level: '2',
					title: 'MU:Pr4.2.2a',
					description:
						'Demonstrate knowledge of music concepts (such as tonality and meter) in music from a variety of cultures selected for performance.'
				},
				{
					level: '2',
					title: 'MU:Pr6.1.2a',
					description: 'Perform music for a specific purpose with expression and technical accuracy.'
				},
				{
					level: '2',
					title: 'MU:Re7.2',
					description:
						'Describe how specific music concepts are used to support a specific purpose in music.'
				},
				{
					level: '2',
					title: 'MU:Re9.1.2a',
					description:
						'Apply personal and expressive preferences in the evaluation of music for specific purposes.'
				},
				{
					level: '3',
					title: 'MU:Cn11.0.3a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '3',
					title: 'MU:Cr1.1.3a',
					description:
						'Improvise rhythmic and melodic ideas, and describe connection to specific purpose and context (such as personal and social).'
				},
				{
					level: '3',
					title: 'MU:Pr4.2.3c',
					description: 'Describe how context (such as personal and social) can inform a performance.'
				},
				{
					level: '3',
					title: 'MU:Pr6.1.3b',
					description:
						'Demonstrate performance decorum and audience etiquette appropriate for the context and venue.'
				},
				{
					level: '3',
					title: 'MU:Re7.2.3a',
					description:
						'Demonstrate and describe how a response to music can be informed by the structure, the use of the elements of music, and context (such as personal and social).'
				},
				{
					level: '3',
					title: 'MU:Re9.1.3a',
					description:
						'Evaluate musical works and performances, applying established criteria, and describe appropriateness to the context.'
				},
				{
					level: '4',
					title: 'MU:Cn11.0.4a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '4',
					title: 'MU:Cr1.1.4a',
					description:
						'Improvise rhythmic, melodic, and harmonic ideas, and explain connection to specific purpose and context (such as social and cultural).'
				},
				{
					level: '4',
					title: 'MU:Pr4.2.4c',
					description: 'Explain how context (such as social and cultural) informs a performance.'
				},
				{
					level: '4',
					title: 'MU:Pr6.1.4b',
					description:
						'Demonstrate performance decorum and audience etiquette appropriate for the context, venue, and genre.'
				},
				{
					level: '4',
					title: 'MU:Re7.2.4a',
					description:
						'Demonstrate and explain how responses to music are informed by the structure, the use of the elements of music, and context (such as social and cultural).'
				},
				{
					level: '4',
					title: 'MU:Re9.1.4a',
					description:
						'Evaluate musical works and performances, applying established criteria, and explain appropriateness to the context.'
				},
				{
					level: '5',
					title: 'MU:Cn11.0.5a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '5',
					title: 'MU:Cr1.1.5a',
					description:
						'Improvise rhythmic, melodic, and harmonic ideas, and explain connection to specific purpose and context (such as social, cultural, and historical).'
				},
				{
					level: '5',
					title: 'MU:Pr4.2.5c',
					description:
						'Explain how context (such as social, cultural, and historical) informs performances.'
				},
				{
					level: '5',
					title: 'MU:Pr6.1.5b',
					description:
						'Demonstrate performance decorum and audience etiquette appropriate for the context, venue, genre, and style.'
				},
				{
					level: '5',
					title: 'MU:Re7.2.5a',
					description:
						'Demonstrate and explain, citing evidence, how responses to music are informed by the structure, the use of the elements of music, and context (such as social, cultural, and historical).'
				},
				{
					level: '5',
					title: 'MU:Re9.1.5a',
					description:
						'Evaluate musical works and performances, applying established criteria, and explain appropriateness to the context, citing evidence from the elements of music.'
				},
				{
					level: '6',
					title: 'MU:Cn11.0.6a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '6',
					title: 'MU:Cr1.1.6a',
					description:
						'Generate simple rhythmic, melodic, and harmonic phrases within AB and ABA forms that convey expressive intent.'
				},
				{
					level: '6',
					title: 'MU:Pr4.2.6c',
					description: 'Identify how cultural and historical context inform the performances.'
				},
				{
					level: '6',
					title: 'MU:Pr6.1.6b',
					description:
						'Demonstrate performance decorum (such as stage presence, attire, and behavior) and audience etiquette appropriate for venue and purpose.'
				},
				{
					level: '6',
					title: 'MU:Re7.2.6b',
					description:
						'Identify the context of music from a variety of genres, cultures, and historical periods.'
				},
				{
					level: '6',
					title: 'MU:Re9.1.6a',
					description: 'Apply teacher-provided criteria to evaluate musical works or performances.'
				},
				{
					level: '7',
					title: 'MU:Cn11.0.7a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '7',
					title: 'MU:Cr1.1.7a',
					description:
						'Generate rhythmic, melodic, and harmonic phrases and variations over harmonic accompaniments within AB, ABA, or theme and variation forms that convey expressive intent.'
				},
				{
					level: '7',
					title: 'MU:Pr4.2.7c',
					description:
						'Identify how cultural and historical context inform performance and results in different music interpretations.'
				},
				{
					level: '7',
					title: 'MU:Pr6.1.7b',
					description:
						'Demonstrate performance decorum (such as stage presence, attire, and behavior) and audience etiquette appropriate for venue, purpose, and context.'
				},
				{
					level: '7',
					title: 'MU:Re7.2.7b',
					description:
						'Identify and compare the context of music from a variety of genres, cultures, and historical periods.'
				},
				{
					level: '7',
					title: 'MU:Re9.1.7a',
					description: 'Select from teacher-provided criteria to evaluate musical works or performances.'
				},
				{
					level: '8',
					title: 'MU:Cn11.0.8a',
					description:
						'Demonstrate understanding of relationships between music and the other arts, other disciplines, varied contexts, and daily life.'
				},
				{
					level: '8',
					title: 'MU:Cr1.1.8a',
					description:
						'Generate rhythmic, melodic and harmonic phrases and harmonic accompaniments within expanded forms (including introductions, transitions, and codas) that convey expressive intent.'
				},
				{
					level: '8',
					title: 'MU:Pr4.2.8c',
					description:
						'Identity how cultural and historical context inform performance and results in different musical effects.'
				},
				{
					level: '8',
					title: 'MU:Pr6.1.8b',
					description:
						'Demonstrate performance decorum (such as stage presence, attire, and behavior) and audience etiquette appropriate for venue, purpose, context, and style.'
				},
				{
					level: '8',
					title: 'MU:Re7.2.8b',
					description:
						'Identify and compare the context of programs of music from a variety of genres, cultures, and historical periods.'
				},
				{
					level: '8',
					title: 'MU:Re9.1.8a',
					description:
						'Apply appropriate personallydeveloped criteria to evaluate musical works or performances.'
				}
			]
		}
	]
}
