import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { Box, Divider, fade, List, makeStyles, Theme } from '@material-ui/core'
import { useHistory } from 'react-router'

import { Student } from '../graphql/autogenerate/schemas'
import { Pages } from '../routes/teacherPages'
import { ListItemStudent } from '../scenes/Teacher/scenes/2-Classes/scenes/ClassStudents/components/ListItemStudent'
import { buildImagePath, buildRouteParameters, concatenate, getCyKey } from '../utils'

const useStyles = makeStyles((theme: Theme) => ({
	boxSongs: {
		padding: 0,
		'& .itemBox': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	},
	boxLoading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.common.black, 0.1)
	}
}))

type ListStudentProps = {
	data: Student[]
	classId?: number
	spacing?: number
	deleteStudent?: React.Dispatch<React.SetStateAction<Student | undefined>>
	detailsStudent?: React.Dispatch<React.SetStateAction<Student | undefined>>
	reinviteStudent?: React.Dispatch<React.SetStateAction<Student | undefined>>
	addToClassStudent?: React.Dispatch<React.SetStateAction<Student | undefined>>
	overrideOnClick?: (studentId: number) => void
	viewButton?: boolean
}
export const ListStudent = ({
	data,
	classId,
	spacing = 0,
	deleteStudent,
	detailsStudent,
	reinviteStudent,
	addToClassStudent,
	overrideOnClick,
	viewButton = true
}: ListStudentProps) => {
	const history = useHistory()
	const styles = useStyles(spacing)
	const lineDivider = false
	const [student, setStudentArr] = useState<Student[]>([])

	useEffect(() => {
		if (data) setStudentArr(data)
	}, [data])

	const ItemStudent = (props: { student: Student }) => {
		const { student } = props

		return (
			<Box
				style={{ cursor: 'pointer' }}
				onClick={() => {
					if (overrideOnClick) overrideOnClick(student.student_id)
					else
						history.push(
							buildRouteParameters(
								Pages.StudentDetail, // Route with paramters
								{ studentId: student.student_id }
							)
						)
				}}>
				<ListItemStudent
					imageUrl={buildImagePath(student.image_path)}
					name={student.first_name}
					lastName={student.last_name}
					email={student.email || ''}
					isActive={student.is_active}
					btnStudentReinvite={
						!student.has_lti_integration && !student.is_active
							? {
									onClick: (e) => {
										e.stopPropagation()
										if (reinviteStudent) reinviteStudent(student)
									}
							  }
							: undefined
					}
					btnStudentClasses={{
						disabled: !!student.has_lti_integration,
						onClick: (e) => {
							e.stopPropagation()
							if (detailsStudent) detailsStudent(student)
						}
					}}
					btnStudentDelete={{
						disabled: !!student.has_lti_integration,
						onClick: (e) => {
							e.stopPropagation()
							if (deleteStudent) deleteStudent(student)
						}
					}}
					btnAddClass={
						!viewButton
							? {
									disabled: !!student.has_lti_integration,
									onClick: async (e) => {
										e.stopPropagation()
										if (addToClassStudent) addToClassStudent(student)
									}
							  }
							: undefined
					}
					btnStudentView={
						viewButton
							? {
									onClick: (e) => {
										e.stopPropagation()
										history.push(
											buildRouteParameters(
												Pages.ClassStudentDetail, // Route with paramters
												{ classId: classId ? classId : '', studentId: student.student_id }
											)
										)
									}
							  }
							: undefined
					}
				/>
			</Box>
		)
	}

	return (
		<Fragment>
			{useMemo(
				() => (
					<List className={styles.boxSongs}>
						{student.map((student, i) => (
							<Box
								data-cy={getCyKey(ListStudent)}
								className="itemBox"
								boxShadow={spacing > 0 ? 1 : 0}
								borderRadius={spacing > 0 ? 4 : 0}
								key={concatenate([i, student.student_id, student.first_name], '-')}>
								<ItemStudent student={student} />
								{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
							</Box>
						))}
					</List>
				),
				[student]
			)}
		</Fragment>
	)
}
