import { AvatarSelection } from './components/AvatarSelection'
import { AvatarSelectionDialogProvider } from './useAvatarSelectionDialog'

export const AvatarSelectionDialog = () => {
	return (
		<AvatarSelectionDialogProvider>
			<AvatarSelection />
		</AvatarSelectionDialogProvider>
	)
}
