import { useState } from 'react'

import { useQuery } from '@apollo/client'
import { Box, CircularProgress, Link, TextField, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import { Link as LinkRD, useHistory } from 'react-router-dom'
import { AddToClassDialog, BigBaseButton, InfoDialog } from 'src/components'
import InputFile from 'src/components/Inputs/InputFile'
import { SelectThumbnail } from 'src/components/Inputs/SelectThumbnail.tsx/SelectThumbnail'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useEditVideoMutation, useInsertTipsToClassMutation } from 'src/graphql/autogenerate/hooks'
import {
	Catalog_Item,
	Class,
	Class_Tip,
	Class_Tip_Insert_Input,
	FileEnum,
	Keyword,
	Tip_Set_Input
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import {
	QueryCatalogItemsByCatalogId,
	QueryCatalogsByCatalogId,
	QueryKeyword,
	QueryKeywordType
} from 'src/scenes/Teacher/queries'
import { BASE_URL, buildRouteParameters, TypeEnum } from 'src/utils'
import * as yup from 'yup'

import { useStyles } from './styles'

const limit = {
	title: 150,
	description: 200,
	keywords: 5
}
const validationSchema = yup.object({
	title: yup.string().required('Title is required').max(limit.title, 'Title too long'),
	description: yup.string().required('Description is required').max(limit.description, 'Descriptions too long'),
	keywords: yup.array().required().max(limit.keywords, `Up to ${limit.keywords}`),
	instruments: yup.array(),
	asset_path: yup.string()
})
type UploadVideoFormType = {
	url: string
	title?: string
	description?: string
	thumbnail?: string
	tipId: number
	classTips: Class_Tip[]
	keywords: Keyword[]
	subjects: Pick<Catalog_Item, 'name' | 'catalog_item_id'>[]
	ages: Pick<Catalog_Item, 'name' | 'catalog_item_id'>[]
	instruments: Pick<Catalog_Item, 'name' | 'catalog_item_id'>[]
	asset_path?: string
}

export const EditVideoForm = ({
	url,
	title,
	description,
	thumbnail,
	keywords,
	subjects,
	ages,
	tipId,
	classTips,
	instruments,
	asset_path
}: UploadVideoFormType) => {
	const classes = useStyles()
	const { teacherData: teacher } = useLoginContext()
	const [thumbnailSelected, setThumbnailSelected] = useState<string>(thumbnail ? `${BASE_URL}${thumbnail}` : '')
	const [thumbnails, setThumbnails] = useState<string[]>(thumbnail ? [`${BASE_URL}${thumbnail}`] : [])
	const [onCancel, setOnCancel] = useState(false)
	const [onSave, setOnSave] = useState(false)
	const [addToClassVideo, setAddToClassVideo] = useState<number>()
	const [updateVideo, { loading: isVideoUploading }] = useEditVideoMutation()
	const [videoClasses, setVideoClasses] = useState<Class[]>([])
	const history = useHistory()

	const [insertTips] = useInsertTipsToClassMutation()

	const formik = useFormik({
		initialValues: {
			title: title?.substring(0, 150) ?? '',
			description: description?.substring(0, 199) ?? '',
			keywords: keywords as Keyword[],
			subjects,
			ages: ages as Catalog_Item[],
			instruments: instruments as Catalog_Item[],
			asset_path
		},

		validationSchema,
		validateOnMount: true,
		onSubmit: (values, { resetForm }) => {
			const tip_keywords = values.keywords.map((keyword) => ({
				keyword_id: keyword.keyword_id,
				tip_id: tipId
			}))

			const tip_catalog_item = [
				...values.instruments.map((catalogItem) => ({
					catalog_item_id: catalogItem.catalog_item_id,
					tip_id: tipId
				})),
				...values.subjects.map((catalogItem) => ({
					catalog_item_id: catalogItem.catalog_item_id,
					tip_id: tipId
				})),
				...values.ages.map((catalogItem) => ({
					catalog_item_id: catalogItem.catalog_item_id,
					tip_id: tipId
				}))
			]

			const dataTip: Tip_Set_Input = {
				tip_id: tipId,
				title: values.title,
				teacher_id: teacher.teacher_id,
				description: values.description,
				resource_path: url,
				image_path: thumbnailSelected.replace(BASE_URL || '', '') || thumbnail,
				asset_path: values.asset_path
			}

			updateVideo({
				variables: { tipId, data: dataTip, tip_keywords, tip_catalog_item },
				update: (cache, { data }) => {
					const videoId = data?.update_tip?.returning[0]
					if (!videoId) return
					const identify = cache.identify(videoId)
					cache.evict({ id: identify, fieldName: 'title' })
					cache.evict({ id: identify, fieldName: 'lesson_plan_catalog_item' })
					cache.evict({ id: identify, fieldName: 'image_path' })
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'tip'
					})
				},
				onCompleted: () => {
					setOnSave(true)
					resetForm()
				},
				onError: (err) => alert(`edit video failed - ${JSON.stringify(err, null, 2)}`)
			})
		}
	})
	const CATALOG_SUBJECTS_ID = 2
	const CATALOG_AGES_ID = 4
	const CATALOG_INSTRUMENTS_ID = 5

	const TIP_SUBJECTS_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_SUBJECTS_ID)
	)
	const TIP_AGES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_AGES_ID)
	)
	const TIP_INSTRUMENTS_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_INSTRUMENTS_ID)
	)
	const KEYWORDS = useQuery<QueryKeywordType>(QueryKeyword(TypeEnum.Video))

	const getClassName = (value: string, limit: number) => {
		if (value === '') return 'Default'
		if (value.length <= limit) return 'Success'
		else return 'Error'
	}
	const getClassNameWrapper = (val: string, limit: number) => {
		return classes[`text${getClassName(val, limit)}` as keyof typeof classes]
	}

	const handleAddToClass = () => {
		setAddToClassVideo(tipId)
		setVideoClasses(classTips.map((item) => item.class) || [])
	}

	const handleConfirmClass = async (selectedClasses: Class[]) => {
		const array = [...selectedClasses]
		const classes = array.map((item) => {
			return {
				tip_id: addToClassVideo,
				class_id: item.class_id
			} as Class_Tip_Insert_Input
		})
		if (classes && classes.length) {
			await insertTips({
				variables: { tips: classes },
				update: (cache, { data }) => {
					const classesToUpdateInCache = data?.insert_class_tip?.returning
					if (!classesToUpdateInCache) return

					let identify
					classesToUpdateInCache.forEach((cls) => {
						identify = cache.identify(cls.class)
						cache.evict({
							id: identify,
							fieldName: 'class_tips'
						})
						cache.evict({
							id: identify,
							fieldName: 'class_tips_aggregate'
						})
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'tip'
					})
				}
			})
		}
	}

	const hasThumbnailUpload = /^tips\/*/i.test(url)

	return (
		<Box marginTop="80px">
			<ReactCustomVideoPlayer
				data-cy="video-player"
				width="100%"
				height={500}
				style={{
					maxWidth: '830px',
					margin: 'auto'
				}}
				config={
					{
						youtube: {
							playerVars: { showinfo: 0 }
						},
						file: {
							attributes: {
								disablePictureInPicture: 'true',
								controlsList: 'nodownload'
							}
						}
					} as unknown as Record<string, string>
				}
				url={url}
			/>
			<form onSubmit={formik.handleSubmit}>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Title*</b>
						</Typography>
						<Typography
							className={getClassNameWrapper(formik.values.title, limit.title)}
							color="textSecondary">
							{`${formik.values.title.length}/${limit.title}`}
						</Typography>
					</Box>
					<TextField
						className={getClassNameWrapper(formik.values.title, limit.title)}
						id="title"
						name="title"
						fullWidth
						value={formik.values.title}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						error={formik.touched.title && Boolean(formik.errors.title)}
						helperText={formik.touched.title && formik.errors.title}
						data-cy="upload-video-title"
						color="secondary"
						variant="outlined"
						placeholder="Enter Title"
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Description*</b>
						</Typography>
						<Typography
							className={getClassNameWrapper(formik.values.description, limit.description)}
							color="textSecondary">
							{`${formik.values.description.length}/${limit.description}`}
						</Typography>
					</Box>
					<TextField
						className={getClassNameWrapper(formik.values.description, limit.description)}
						id="description"
						name="description"
						fullWidth
						onBlur={formik.handleBlur}
						error={formik.touched.description && Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
						value={formik.values.description}
						onChange={formik.handleChange}
						data-cy="upload-video-description"
						color="secondary"
						variant="outlined"
						placeholder="Enter description"
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Keywords*</b>
						</Typography>
						<Typography className={classes.textDefault} color="textSecondary">
							Up to {limit.keywords}
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-keyword',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-keyword-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						noOptionsText={<Typography>No Matching Keywords - Please Try Again</Typography>}
						limitTags={limit.keywords}
						options={KEYWORDS.data?.keyword || []}
						value={formik.values.keywords}
						onChange={(_, value) => {
							formik.setFieldValue('keywords', value)
						}}
						closeIcon={<CloseIcon data-cy="keywords-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-keywords"
								variant="outlined"
								color="secondary"
								placeholder="Enter keywords"
							/>
						)}
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Subjects</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-subject',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-subjects-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={TIP_SUBJECTS_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.subjects}
						onChange={(_, value) => {
							formik.setFieldValue('subjects', value)
						}}
						closeIcon={<CloseIcon data-cy="subjects-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-subjects"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Instruments</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-instruments',
							className: classes.chip,
							deleteIcon: (
								<Typography
									data-cy="remove-instruments-selected"
									className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={TIP_INSTRUMENTS_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.instruments}
						onChange={(_, value) => {
							formik.setFieldValue('instruments', value)
						}}
						closeIcon={<CloseIcon data-cy="instruments-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-instruments"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Ages</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-ages',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-ages-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={TIP_AGES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.ages}
						onChange={(_, value) => {
							formik.setFieldValue('ages', value)
						}}
						closeIcon={<CloseIcon data-cy="ages-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-ages"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Asset</b>
						</Typography>
					</Box>
					<InputFile.Container
						advise="Max File size: 200MB."
						id="asset_path"
						placeholder="Pick an Asset for this video"
						type={FileEnum.Assets}
						initialFile={asset_path ? { name: asset_path, resource_path: asset_path } : undefined}
						handleFileUrl={(e) => {
							formik.setFieldValue('asset_path', e)
						}}
						onDelete={() => {
							formik.setFieldValue('asset_path', '')
						}}
						isTemporal={false}
					/>
				</Box>
				{hasThumbnailUpload && (
					<SelectThumbnail
						thumbnailList={thumbnails}
						thumbnailValue={thumbnailSelected}
						isRequired={false}
						handleSelectThumbnail={(thumbnail) => setThumbnailSelected(thumbnail)}
						handleSetThumbnailList={(vals) => setThumbnails(vals)}
					/>
				)}

				<Box
					className={classes.buttonDisabled}
					marginTop="35px"
					display="flex"
					justifyContent="space-between">
					<BigBaseButton
						style={{ padding: '6px 40px' }}
						variant="contained"
						color="default"
						onClick={() => {
							setOnCancel(true)
						}}>
						<Typography variant="caption">Cancel</Typography>
					</BigBaseButton>

					<BigBaseButton
						style={{ padding: '6px 50px' }}
						disabled={!formik.isValid || isVideoUploading}
						color="secondary"
						type="submit">
						<Typography variant="caption">Save</Typography>
						{isVideoUploading && <CircularProgress size={30} className={classes.spinner} />}
					</BigBaseButton>
				</Box>
			</form>

			<InfoDialog
				open={onSave}
				title="Video Saved!"
				body='Your Video was uploaded successfully! It will appear under "My Files"'
				discardLabel="Upload Another Video"
				onDiscard={() => {
					history.push(buildRouteParameters(Pages.VideoUpload))
					setOnSave(false)
				}}
				confirmLabel="Add to Class"
				confirmProps={{ color: 'primary', endIcon: <AddIcon /> }}
				onConfirm={handleAddToClass}
				icon={<CheckIcon />}
				footer={
					<Link component={LinkRD} to={Pages.MyFiles.path}>
						<Typography color="secondary">View My File</Typography>
					</Link>
				}
			/>
			<InfoDialog
				open={onCancel}
				onClose={() => setOnCancel(false)}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={() => {
					setOnCancel(false)
					history.push(buildRouteParameters(Pages.MyFiles))
				}}
				confirmLabel="No, Cancel"
				onConfirm={() => {
					setOnCancel(false)
				}}
				icon="!"
			/>
			<AddToClassDialog
				isOpen={!!addToClassVideo}
				setIsOpen={setAddToClassVideo}
				itemClasses={videoClasses}
				title="Save Video to Class"
				description="This will save the Video so that you can view and teach right from your Class page.
				This content will be visible to the students that are in the class."
				onConfirm={handleConfirmClass}
			/>
		</Box>
	)
}
