import { Box, Link, Tooltip, Typography } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import moment from 'moment'
import { AssignmentsIcon } from 'src/assets/icons'
import {
	Student_Activity,
	Student_Activity_Type_Enum,
	Class_Assignment,
	Assignment,
	Student_Badge
} from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { removeUnderscore } from 'src/scenes/Teacher/scenes/1-Home/components/StudentsSummary/StudentActivities/utils'
import { buildImagePath, buildRouteParameters } from 'src/utils'

import { PaginationListDataItem } from './PaginationList'
import { StyledAlert, StyledTypography } from './PaginationListItem.styles'

type StudentActivityForPaginationListItem = Pick<
	Student_Activity,
	'student_activity_id' | 'student_activity_type' | 'description' | 'url' | 'past_due' | 'created_at'
> & {
	student?: Pick<Student_Activity['student'], 'full_name' | 'image_path'>
	assignment?:
		| (Pick<Assignment, 'name'> & {
				class_assignments?: Pick<Class_Assignment, 'class_assignment_id' | 'class_id'>[]
		  })
		| null
}

export const buildStudentActivityPaginationItem = (
	item: StudentActivityForPaginationListItem,
	cyKey: string
): PaginationListDataItem => {
	switch (item.student_activity_type) {
		case Student_Activity_Type_Enum.HasBeenPracticing:
		case Student_Activity_Type_Enum.HasWatchedVideo:
		case Student_Activity_Type_Enum.HasRecorded:
			return {
				avatarPath: item ? buildImagePath(item?.student?.image_path ?? '') : undefined,
				primary: (
					<Typography variant="h6">
						<b>{item?.student?.full_name ?? ''}</b>
					</Typography>
				),
				secondary: (
					<Box width="80%">
						<Tooltip
							title={removeUnderscore(item?.student_activity_type) + ' ' + item?.description || ''}
							placement="top-start">
							<StyledTypography variant="subtitle2" color="textSecondary">
								{removeUnderscore(item?.student_activity_type) + ' '}
								<Link href={item?.url ?? '#'} color="secondary" target="_blank">
									{item?.description}
								</Link>
							</StyledTypography>
						</Tooltip>
					</Box>
				),
				secondaryAction: (
					<StyledAlert data-cy={cyKey} icon={<AccessTime fontSize="small" />} severity="info">
						{moment(item?.created_at).fromNow(true)}
					</StyledAlert>
				)
			}
		case Student_Activity_Type_Enum.HasCompletedTheirAssignment:
		case Student_Activity_Type_Enum.HasHandedInTheAssignment:
			return {
				avatar: <AssignmentsIcon />,
				primary: (
					<Typography variant="h6">
						<b>{item?.student?.full_name ?? ''}</b>
					</Typography>
				),
				secondary: (
					<Box width="80%">
						<Tooltip
							title={
								removeUnderscore(item?.student_activity_type) + ' ' + item?.assignment?.name || ''
							}
							placement="top-start">
							<StyledTypography variant="subtitle2" color="textSecondary">
								{removeUnderscore(item?.student_activity_type) + ' '}
								<Link
									data-cy={cyKey + 'AssignmentName'}
									href={buildRouteParameters(Pages.ClassAssignmentDetail, {
										classId: item?.assignment?.class_assignments?.[0]?.class_id ?? 0,
										classAssignmentId:
											item?.assignment?.class_assignments?.[0]?.class_assignment_id ?? 0
									})}
									color="secondary"
									target="_blank">
									"{item?.assignment?.name}"
								</Link>
								{item?.past_due ? ' (past due)' : ''}
							</StyledTypography>
						</Tooltip>
					</Box>
				),
				secondaryAction: (
					<StyledAlert data-cy={cyKey} icon={<AccessTime fontSize="small" />} severity="info">
						{moment(item?.created_at).fromNow(true)}
					</StyledAlert>
				)
			}
		case Student_Activity_Type_Enum.HasCompletedTheirGoal:
			return {
				avatarPath: item ? buildImagePath(item?.student?.image_path ?? '') : undefined,
				primary: (
					<Typography variant="h6">
						<b>{item?.student?.full_name ?? ''}</b>
					</Typography>
				),
				secondary: (
					<Box width="80%">
						<Tooltip
							title={removeUnderscore(item?.student_activity_type) + ' ' + item?.description || ''}
							placement="top-start">
							<StyledTypography variant="subtitle2" color="textSecondary">
								{removeUnderscore(item?.student_activity_type) + ' '}
								{item?.description}
							</StyledTypography>
						</Tooltip>
					</Box>
				),
				secondaryAction: (
					<StyledAlert data-cy={cyKey} icon={<AccessTime fontSize="small" />} severity="info">
						{moment(item?.created_at).fromNow(true)}
					</StyledAlert>
				)
			}
		default:
			return {}
	}
}

export const buildStudentPointsPaginationItem = (
	item: Student_Badge,
	badgePoints: number
): PaginationListDataItem => {
	return {
		avatarPath: item ? buildImagePath(item?.student?.image_path ?? '') : undefined,
		primary: (
			<Typography variant="h6">
				<b>{item?.student?.full_name ?? 'Student Name'}</b>
			</Typography>
		),
		secondary: (
			<Box width="80%">
				<Tooltip title="" placement="top-start">
					{item?.student?.student_badge_events[0].challenge?.challenge_id ? (
						<StyledTypography variant="subtitle2" color="textSecondary">
							has submmited
							<Link href={'#'} color="secondary" target="_blank">
								{item?.student?.student_badge_events[0].challenge?.title}
							</Link>
							challenge.
						</StyledTypography>
					) : (
						<StyledTypography variant="subtitle2" color="textSecondary">
							has earned {item.badge.name} badge.
						</StyledTypography>
					)}
				</Tooltip>
			</Box>
		),
		secondaryAction: (
			<Box
				height="28px"
				width="78px"
				display="flex"
				style={{
					background: 'rgba(115, 103, 163, 0.2)',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '4px'
				}}
				className="boxActions"
				alignSelf="flex-start"
				marginTop="6px">
				{`${badgePoints} Points`}
			</Box>
		)
	}
}

export const buildStudentPointsSummaryPaginationItem = (item: {
	image_path: string
	full_name: string
	points: number
}): PaginationListDataItem => {
	return {
		avatarPath: item ? buildImagePath(item?.image_path ?? '') : undefined,
		primary: (
			<Typography variant="h6">
				<b>{item?.full_name ?? ''}</b>
			</Typography>
		),
		secondary: <Box></Box>,
		secondaryAction: (
			<Box
				height="28px"
				width="78px"
				display="flex"
				style={{
					background: 'rgba(115, 103, 163, 0.2)',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '4px'
				}}
				className="boxActions"
				alignSelf="flex-start"
				marginTop="6px">{`${item.points} Points`}</Box>
		)
	}
}
