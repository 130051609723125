import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core'
import { COLORS } from 'src/styles/colors'

export const useMyWurrliesStyles = makeStyles((theme: Theme) =>
	createStyles({
		toolbar: {
			gap: '20px'
		},
		classSelect: {
			flexGrow: 1
		},
		grid: { marginTop: '40px' },
		boxGrow: { flexGrow: 1 },
		actionButton: {
			backgroundColor: '#fff'
		},
		actionButtonColor: {
			backgroundColor: alpha(COLORS.yellow, 0.2)
		},
		gapLess: {
			margin: 0
		},
		listToolbar: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '12px',
			alignItems: 'center'
		},
		infiniteScroll: {
			gap: '24px',
			display: 'flex',
			flexDirection: 'column',
			paddingTop: '24px'
		},
		fullWidth: {
			width: '100%'
		},
		selectedDisplay: {
			backgroundColor: `${theme.palette.secondary.light} !important`,
			border: `1px solid ${theme.palette.secondary.main}`
		},
		displayTypeContainer: {
			display: 'flex',
			gap: '8px'
		}
	})
)

export default useMyWurrliesStyles
