import { Box } from '@material-ui/core'
import { SkillCoveredBySavedLessons } from 'src/components/SkillCoveredBySavedLessons/SkillCoveredBySavedLessons'
import { useSkillsCoveredSavedLessonsData } from 'src/scenes/Teacher/scenes/1-Home/components/hooks'
import { useInsightsContext } from 'src/scenes/Teacher/scenes/1-Home/components/Insights/hooks/useInsights'
import { ClassChartType } from 'src/utils'

export const SkillsSection = () => {
	const { classId } = useInsightsContext()
	const { getChartData, standardChartData } = useSkillsCoveredSavedLessonsData(classId)

	const chartStandardsData = getChartData(ClassChartType.standards)
	const chartSkillsData = getChartData(ClassChartType.skills)

	return (
		<Box mt={5}>
			<SkillCoveredBySavedLessons
				chartStandardsData={chartStandardsData}
				chartSkillsData={chartSkillsData}
				standardChartData={standardChartData}
			/>
		</Box>
	)
}
