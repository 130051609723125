import { makeStyles, Theme } from '@material-ui/core'

export const useSongListAndFilterStyles = makeStyles<Theme, { maxHeightPx: number }>((theme) => ({
	buttonContainer: {
		marginTop: theme.spacing(4)
	},
	songsContainer: {
		minHeight: '120px',
		maxHeight: ({ maxHeightPx }) => `${maxHeightPx}px`,
		overflow: 'auto',
		padding: '0 5px 0 2px',
		position: 'relative',
		display: 'block',
		pointerEvents: 'auto'
	},
	overlayContainer: {
		position: 'relative'
	},
	overlay: {
		pointerEvents: 'none',
		position: 'absolute',
		bottom: 0,
		left: 0,
		width: 'calc(100% - 5px)',
		height: '120px',
		background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) -120.18%, #FAFAFA 81.65%)',
		animation: `$fadeIn 500ms ${theme.transitions.easing.easeInOut}`
	},
	overlayHidden: {
		opacity: 0,
		animation: `$fadeOut 500ms ${theme.transitions.easing.easeInOut}`
	},
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0
		},
		'100%': {
			opacity: 1
		}
	},
	'@keyframes fadeOut': {
		'0%': {
			opacity: 1
		},
		'100%': {
			opacity: 0
		}
	}
}))

export default useSongListAndFilterStyles
