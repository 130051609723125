import { useEffect, useState } from 'react'

import { Page } from 'src/@types'
import { useGetClassNameByPkLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs } from 'src/utils'
export const useClassAssignments = (page: Page) => {
	const { classId } = useWurrlyParams<typeof Pages.ClassAssignment.params[number]>()
	const [className, setClassName] = useState('')

	const [getClassName, { data }] = useGetClassNameByPkLazyQuery()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			}
		],
		params: {
			classId
		}
	})

	useEffect(() => {
		getClassName({
			variables: {
				classId
			}
		})
	}, [classId])

	useEffect(() => {
		if (data) {
			setClassName(data.class_by_pk?.title || '')
		}
	}, [data])

	return {
		breadcrumbs,
		classId
	}
}
