import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Box, Checkbox, CircularProgress, Divider, List, Typography } from '@material-ui/core'
import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { noop } from 'lodash/fp'
import { useHistory } from 'react-router-dom'
import { ModulesDialog } from 'src/components/Dialogs/ModulesDialog/ModulesDialog'
import { AddSequenceItemToClass } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Sequences/components/AddSequenceItemToClass'

import {
	GetClassLessonPlansDocument,
	GetTeacherFavLessonPlansDocument,
	useInsertLessonToClassesByLessonPkMutation,
	useSetUpdateSequenceLessonDontAskAgainMutation
} from '../graphql/autogenerate/hooks'
import { Class, Lesson_Plan, Sequence, Visibility_Enum } from '../graphql/autogenerate/schemas'
import { useLoginContext } from '../hooks/useLogin'
import { Pages } from '../routes/teacherPages'
import { mutationDeleteFavorite, mutationInsertFavorite } from '../scenes/Teacher/queries'
import {
	buildImagePath,
	buildRouteParameters,
	concatenate,
	getCyKey,
	getLessonSubjects,
	lessonHasStandards,
	TypeEnum
} from '../utils'
import { AddToClassDialog, RemoveFavoriteDialog } from './'
import { InfoDialog } from './Dialogs/InfoDialog'
import { ListItemSong } from './ListItemSong'
import { StandardSideBar } from './StandardSideBar'

const useStyles = makeStyles((theme: Theme) => ({
	boxVideos: {
		padding: 0,
		'& .itemBox': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	},
	boxLoading: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		zIndex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: fade(theme.palette.common.black, 0.1)
	}
}))

type ListVideosProps = {
	teacherId: number
	spacing?: number
	lineDivider?: boolean
	data: Lesson_Plan[]
	addAssignmentButton?: boolean
	removeButton?: boolean
	standardButton?: boolean
	addclassButton?: boolean
	overrideOnClick?: (lessonPlanId: number) => void
	overrideAddToClass?: (lessonPlanId: number) => Promise<void>
	overrideDelete?: (lessonPlanId: number) => void
	sequence?: Sequence
	classId?: number
	moduleId?: number
	partnerId?: number
	returnItemAlone?: boolean
	listItem?: Lesson_Plan
	listItemNumber?: number
	disableRemoveFromModule?: boolean
}

export const ListLessons = ({
	teacherId,
	spacing = 0,
	lineDivider,
	data,
	addAssignmentButton = false,
	removeButton = false,
	standardButton = true,
	addclassButton = true,
	overrideAddToClass,
	overrideDelete,
	// overrideOnClick,
	sequence,
	classId,
	moduleId,
	partnerId,
	returnItemAlone = false,
	listItem,
	listItemNumber,
	disableRemoveFromModule
}: ListVideosProps) => {
	const history = useHistory()
	const styles = useStyles(spacing)
	const { teacherData: teacher } = useLoginContext()
	const [addToClassLesson, setAddToClassLesson] = useState<Lesson_Plan>()
	const [lessonStandard, setLessonStandard] = useState<Lesson_Plan>()
	const [lessonPlan, setLessonPlan] = useState<Lesson_Plan>()
	const [removeLessonFn, setRemoveLessonFn] = useState<() => Promise<void>>()
	const [, setDeleteLessonId] = useState<number>()
	const [isOnlyAddThisLessonOpen, setIsOnlyAddThisLessonOpen] = useState(false)
	const [isAddSequenceToClassOpen, setIsAddSequenceToClassOpen] = useState(false)
	const dontAskAgainSetting = teacher.teacher_setting?.favorites_dont_ask_again_to_delete || false
	const dontAskAgainSequenceLesson =
		teacher.teacher_setting?.sequence_lesson_dont_ask_again_add_to_class || false

	const [dontAsk, setDontAsk] = useState(dontAskAgainSetting)
	const [isSequenceLessonDontAsk, setIsSequenceLessonDontAsk] = useState(dontAskAgainSequenceLesson)
	const [setTeacherSettingSequenceLessonDontAsk] = useSetUpdateSequenceLessonDontAskAgainMutation()
	const [lessonPlanId, setLessonPlanId] = useState(0)
	const [addedClasses, setAddedClasses] = useState<Class[]>([])
	const [insertLessonToClassesMutation] = useInsertLessonToClassesByLessonPkMutation()
	const [favoriteLesson, setFavoriteLesson] = useState(false)
	const [loading, setLoading] = useState(false)
	const [handleFavoriteFn, setHandleFavoriteFn] = useState<(isFavorite: boolean, noDialog: boolean) => void>(
		() => {}
	)

	const getLessonToolTip = useCallback(
		(lesson: Lesson_Plan) => {
			const moduleName = lesson?.module_lesson_plans?.[0]?.module.title
			const sequence = lesson?.sequence_lesson_plan?.[0]?.sequence.name

			if (!disableRemoveFromModule || (!moduleName && !sequence)) return undefined

			if (moduleName) {
				return `This lesson belongs to "${moduleName}" module, and cannot be deleted individually`
			}
			// there is sequence at this point

			return `This lesson belongs to "${sequence}" sequence, and cannot be deleted individually`
		},
		[listItem, disableRemoveFromModule]
	)

	const [isModulesDialogOpen, setIsModulesDialogOpen] = useState<boolean>(false)

	const insertLessonToClass = async (classes: Class[], lessonPlan: Lesson_Plan | undefined) => {
		if (!lessonPlan) return
		const classesIds = classes.map((i) => i.class_id)

		const mutationObject = classesIds.map((classId: number) => {
			return {
				class_id: classId,
				lesson_plan_id: lessonPlan.lesson_plan_id
			}
		})
		try {
			await insertLessonToClassesMutation({
				variables: { classes: mutationObject },
				refetchQueries: [GetTeacherFavLessonPlansDocument, GetClassLessonPlansDocument],
				update: (cache, { data }) => {
					const classesToUpdateInCache =
						data?.insert_class_lesson_plan?.returning[0].lesson_plan.class_lesson_plans
					if (!classesToUpdateInCache) return
					let class_identify
					classesToUpdateInCache.forEach((cls) => {
						class_identify = cache.identify(cls.class)
						cache.evict({
							id: class_identify,
							fieldName: 'class_lesson_plans'
						})
						cache.evict({
							id: class_identify,
							fieldName: 'class_lesson_plans_aggregate'
						})
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'lesson_plan'
					})
					cache.evict({
						id: cache.identify(lessonPlan),
						fieldName: 'class_lesson_plans'
					})
					cache.evict({
						id: cache.identify(lessonPlan),
						fieldName: 'lesson_plan_aggregate'
					})
				}
			})
			setAddedClasses([...classes])
		} catch (error) {
			console.error('Could not add lesson to class', { error })
		}
	}

	const addToClass = () => {
		if (lessonPlan) {
			setAddToClassLesson(lessonPlan)
			setAddedClasses((lessonPlan?.class_lesson_plans?.map((i) => i.class) || []) as Class[])
		}
	}

	const [searchText, setSearchText] = useState('')

	const ItemLesson = (props: { lesson: Lesson_Plan }): JSX.Element => {
		const [loadingItem, setLoadingItem] = useState(false)
		const { lesson } = props
		const lessonVisibility = lesson?.lockable_content?.visibility
		const isForSale = lesson?.lockable_content?.visibility === Visibility_Enum.ForSale
		const hasPaid = lesson?.is_purchased_by_teacher || false
		const hasAssignment = !!lesson?.assignment_created

		const [addFavoriteLesson, { loading: loadingAdd, error: errorAdd }] = useMutation(
			mutationInsertFavorite(TypeEnum.Lesson)
		)
		const [deleteFavoriteLesson, { loading: loadingDel, error: errorDel }] = useMutation(
			mutationDeleteFavorite(TypeEnum.Lesson)
		)

		const [isFavorite, setIsFavorite] = useState(false) // !!lesson.is_favorite

		useEffect(() => {
			setIsFavorite(!!lesson?.is_favorite)
		}, [loadingAdd, loadingDel])

		const handleRemoveFavoriteAndDontAskAgain = async () => {
			await removeFavorite()
		}

		const removeFavorite = async () => {
			setRemoveLessonFn(undefined) // close Remove Favorited Dialog
			setLoading(true)
			await deleteFavoriteLesson({
				variables: { teacherId, itemId: lesson?.lesson_plan_id },
				refetchQueries: [GetTeacherFavLessonPlansDocument],
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_lesson_plan_favorites'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_lesson_plan_favorite'
					})
				}
			})
			setLoading(false)

			if (!errorDel && !loadingDel) {
				setFavoriteLesson(false)
				setIsFavorite(false)
			}
		}

		const addFavorite = async () => {
			setLoading(true)
			await addFavoriteLesson({
				variables: { teacherId, itemId: lesson?.lesson_plan_id },
				refetchQueries: [GetTeacherFavLessonPlansDocument],
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_lesson_plan_favorite'
					})
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_lesson_plan_favorites'
					})
				}
			})
			setLoading(false)
			if (!errorAdd && !loadingAdd) {
				setFavoriteLesson(true)
				setIsFavorite(true)
			}
		}

		const handleFavorite = (isFavorite: boolean) => {
			if (isFavorite) {
				if (teacher?.teacher_setting?.favorites_dont_ask_again_to_delete) removeFavorite()
				else setRemoveLessonFn(() => handleRemoveFavoriteAndDontAskAgain)
			} else addFavorite()
		}

		const goToCreateFromLesson = () => {
			if (lesson?.lesson_plan_id && classId) {
				history.push(
					buildRouteParameters(
						Pages.AddAssignmentFromLesson,
						{ lessonPlanId: lesson.lesson_plan_id },
						true,
						{ selectedClassId: classId }
					)
				)
			}
		}

		const goToClassAssignment = () => {
			const classAssignmentId = lesson?.assignments[0]?.class_assignments[0]?.class_assignment_id
			if (classAssignmentId && classId) {
				history.push(
					buildRouteParameters(Pages.ClassAssignmentDetail, {
						classId,
						classAssignmentId
					})
				)
			}
		}

		if (!lesson) return <Fragment></Fragment>
		const handleClick = () => {
			const disabled = isForSale && !hasPaid
			if (partnerId && moduleId && !disabled) {
				history.push(
					buildRouteParameters(Pages.PartnerModuleLessonDetails, {
						lessonPlanId: lesson?.lesson_plan_id,
						partnerId,
						moduleId
					})
				)

				return
			}

			const route = moduleId
				? buildRouteParameters(Pages.LessonDetail, { lessonPlanId: lesson?.lesson_plan_id, moduleId })
				: buildRouteParameters(Pages.LessonDetailNoModule, {
						lessonPlanId: lesson?.lesson_plan_id
				  })

			if (isForSale && !hasPaid) noop()
			else history.push(route)
		}

		const lessonToolTip = useMemo(() => {
			return getLessonToolTip(lesson)
		}, [lesson])

		return (
			<Box position="relative">
				{(loadingAdd || loadingDel || loadingItem) && (
					<Box className={styles.boxLoading}>
						<CircularProgress color="secondary" size={20} />
					</Box>
				)}
				<Box
					data-cy={getCyKey(ListLessons)}
					style={
						isForSale && !hasPaid
							? {
									cursor: 'default'
							  }
							: {
									cursor: 'pointer'
							  }
					}
					onClick={handleClick}>
					<ListItemSong
						isPurchased={hasPaid}
						imageUrl={buildImagePath(lesson?.image_path)}
						title={lesson?.title}
						author={lesson?.description}
						genres={`• ${getLessonSubjects(lesson)}`}
						loadingAddFav={loadingAdd}
						loadingRemoveFav={loadingDel}
						visibility={lessonVisibility}
						moduleCount={lesson.module_lesson_plans_aggregate.aggregate?.count}
						btnModules={{
							disabled: lesson.module_lesson_plans_aggregate.aggregate?.count === 0,
							onClick: (e) => {
								e.stopPropagation()
								setLessonPlanId(lesson?.lesson_plan_id)
								setIsModulesDialogOpen(true)
							}
						}}
						btnFavorite={{
							disabled: isForSale && !hasPaid,
							selected: isFavorite,
							onClick: (e) => {
								e.stopPropagation()
								handleFavorite(isFavorite)
							}
						}}
						btnStandard={
							standardButton
								? {
										onClick: (e) => {
											e.stopPropagation()
											setLessonStandard(lesson)
										},
										disabled: !lessonHasStandards(lesson)
								  }
								: undefined
						}
						btnRemove={
							removeButton
								? {
										disabled: isForSale || Boolean(lessonToolTip),
										tooltipText: lessonToolTip,
										onClick: async (e) => {
											e.stopPropagation()
											if (overrideDelete) {
												overrideDelete(lesson?.lesson_plan_id)
											} else setDeleteLessonId(lesson?.lesson_plan_id)
										}
								  }
								: undefined
						}
						btnAddClass={
							!addAssignmentButton && addclassButton
								? {
										disabled: loadingItem || (isForSale && !hasPaid),
										onClick: async (e) => {
											e.stopPropagation()
											if (sequence && !dontAskAgainSequenceLesson) {
												setLessonPlan(lesson)
												setIsOnlyAddThisLessonOpen(true)
												setHandleFavoriteFn(() => handleFavorite)
												setFavoriteLesson(isFavorite)
											} else if (overrideAddToClass) {
												setLoadingItem(true)
												await overrideAddToClass(lesson?.lesson_plan_id)
												setLoadingItem(false)
											} else {
												setAddToClassLesson(lesson)
												setAddedClasses(
													(lesson?.class_lesson_plans?.map((i) => i.class) ||
														[]) as Class[]
												)
												setHandleFavoriteFn(() => handleFavorite)
												setFavoriteLesson(isFavorite)
											}
										}
								  }
								: undefined
						}
						btnAddAssignment={
							addAssignmentButton && !hasAssignment
								? {
										disabled: loadingItem || (isForSale && !hasPaid),
										onClick: async (e) => {
											e.stopPropagation()
											goToCreateFromLesson()
										}
								  }
								: undefined
						}
						btnAssignmentAdded={
							addAssignmentButton && hasAssignment
								? {
										disabled: loadingItem || (isForSale && !hasPaid),
										onClick: async (e) => {
											e.stopPropagation()
											goToClassAssignment()
										}
								  }
								: undefined
						}
					/>
				</Box>
			</Box>
		)
	}
	const RenderElement = returnItemAlone ? (
		<Box
			className="itemBox"
			key={concatenate([listItemNumber as number, listItem?.lesson_plan_id as number], '-')}
			overflow="hidden"
			boxShadow={spacing > 0 ? 1 : 0}
			borderRadius={spacing > 0 ? 4 : 0}>
			<ItemLesson lesson={listItem as Lesson_Plan} />
			{lineDivider && data.length !== (listItemNumber as number) + 1 && <Divider variant="middle" />}
		</Box>
	) : (
		<List className={styles.boxVideos}>
			{data?.map((lesson, i) => (
				<Box
					className="itemBox"
					key={concatenate([i, lesson?.lesson_plan_id], '-')}
					overflow="hidden"
					boxShadow={spacing > 0 ? 1 : 0}
					borderRadius={spacing > 0 ? 4 : 0}>
					<ItemLesson lesson={lesson} />
					{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
				</Box>
			))}
		</List>
	)

	return (
		<>
			<Fragment>
				{RenderElement}
				<StandardSideBar lesson={lessonStandard} onClose={() => setLessonStandard(undefined)} />
				<AddToClassDialog
					itemName="Lesson"
					isOpen={!!addToClassLesson}
					setIsOpen={setAddToClassLesson}
					title="Save Lesson to Class"
					description="This will save the Lesson so that you can view and teach right from your Class page.
				It will not be visible to your students, but you can share content from the Lesson by creating an Assignment or adding Songs or Videos to a Class."
					itemClasses={addedClasses}
					onConfirm={async (classes) => {
						insertLessonToClass(classes, addToClassLesson)
					}}
					handleFavorite={{
						isFavorite: favoriteLesson,
						handler: () => handleFavoriteFn(favoriteLesson, dontAsk)
					}}
					isLoading={loading}
					searchText={searchText}
					setSearchText={setSearchText}
				/>
				<InfoDialog
					title="Only Add this Lesson to a Class?"
					body={
						<Typography>
							You can either add each Lesson individually or add the full Sequence at once.
						</Typography>
					}
					paperProps={{ style: { width: '460px' } }}
					maxWidth="md"
					icon="?"
					open={isOnlyAddThisLessonOpen}
					onClose={() => {
						setIsOnlyAddThisLessonOpen(false)
						if (isSequenceLessonDontAsk) {
							setTeacherSettingSequenceLessonDontAsk({
								variables: {
									dontAsk: isSequenceLessonDontAsk,
									teacherId
								},
								update: (cache, { data }) => {
									const teacherToUpdateInCache =
										data?.update_teacher_setting?.returning[0].teacher
									if (!teacherToUpdateInCache) return

									const identify = cache.identify(teacherToUpdateInCache)
									cache.evict({
										id: identify,
										fieldName: 'teacher_setting'
									})
								}
							})
						}
					}}
					onDiscard={(e) => {
						e.stopPropagation()
						setIsOnlyAddThisLessonOpen(false)
						addToClass()
					}}
					discardLabel="Only This Lesson"
					onConfirm={async (e) => {
						e.stopPropagation()
						setIsOnlyAddThisLessonOpen(false)
						setIsAddSequenceToClassOpen(true)
					}}
					confirmLabel="Add Full Sequence"
					confirmProps={{ endIcon: <AddIcon />, color: 'primary' }}
					footer={
						<Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="center">
							<Checkbox
								onClick={(e) => e.stopPropagation()}
								onChange={(e) => {
									e.stopPropagation()
									setIsSequenceLessonDontAsk(e.target.checked)
								}}
							/>

							<Typography>Don't ask me again</Typography>
						</Box>
					}
				/>
				<AddSequenceItemToClass
					isOpen={isAddSequenceToClassOpen}
					setIsOpen={setIsAddSequenceToClassOpen}
					sequence={sequence}
				/>
				{/* <AddAssigment addToClassSongId={addToClassLessonId} setAddToClassSongId={setAddToClassLessonId} /> */}
				<RemoveFavoriteDialog
					itemName="Lesson"
					pageItemName={Pages.Lessons.name}
					removeItemFn={removeLessonFn}
					setRemoveItemFn={setRemoveLessonFn}
					dontAsk={dontAsk}
					setDontAsk={setDontAsk}
				/>
				<ModulesDialog
					lessonPlanId={lessonPlanId}
					isOpen={isModulesDialogOpen}
					setIsOpen={setIsModulesDialogOpen}></ModulesDialog>
			</Fragment>
		</>
	)
}
