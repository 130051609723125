import { Slider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
export const BaseSlider = withStyles({
	rail: {
		height: 2,
		opacity: 0.5,
		backgroundColor: '#bfbfbf'
	},
	mark: {
		backgroundColor: '#fff',
		height: 5,
		width: 2,
		marginTop: -2
	}
})(Slider)
