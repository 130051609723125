import { useEffect, useState } from 'react'

import { Box, Fab } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { ChartData } from 'chart.js'
import clsx from 'clsx'
import { ChartProps } from 'react-chartjs-2'
import { TypedChartComponent } from 'react-chartjs-2/dist/types'

import useStyles from './PaginatedChart.styles'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_STEP_SIZE } from './utils'

type PaginatedChartProps = {
	ChartComponent: TypedChartComponent
	chartProps: ChartProps
}
export type ChartPaginationProps = {
	itemsPerPage?: number
	stepSize?: number
}
export const PaginatedChart = ({
	ChartComponent,
	chartProps,
	itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
	stepSize = DEFAULT_STEP_SIZE
}: PaginatedChartProps & ChartPaginationProps) => {
	const styles = useStyles()
	const [paginatedData, setPaginatedData] = useState<ChartProps['data']>({ datasets: [] })
	const [offset, setOffset] = useState(0)
	const totalItems = (chartProps.data as ChartData)?.labels?.length ?? 0

	const handleBackBtn = () => {
		setOffset((prev) => Math.max(prev - stepSize, 0))
	}
	const handleNextBtn = () => {
		setOffset((prev) => Math.min(prev + stepSize, totalItems - itemsPerPage))
	}

	useEffect(() => {
		const { labels, datasets } = chartProps.data as ChartData
		const newLabels = labels?.slice(offset, offset + itemsPerPage)
		const newDatasets = datasets?.map((dataset) => ({
			...dataset,
			data: dataset.data?.slice(offset, offset + itemsPerPage)
		}))
		setPaginatedData({ labels: newLabels, datasets: newDatasets })
	}, [offset, chartProps.data])

	return (
		<Box className={styles.container}>
			{offset > 0 && (
				<Fab className={clsx(styles.fabButton, styles.btnLeft)} size="medium" onClick={handleBackBtn}>
					<KeyboardArrowLeft color="secondary" />
				</Fab>
			)}

			<ChartComponent {...chartProps} data={paginatedData} />

			{offset + itemsPerPage < totalItems && (
				<Fab className={clsx(styles.fabButton, styles.btnRight)} size="medium" onClick={handleNextBtn}>
					<KeyboardArrowRight color="secondary" />
				</Fab>
			)}
		</Box>
	)
}
