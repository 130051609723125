import { useMemo, useState, useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'
import { FormikProps } from 'formik'
import { ListDialog } from 'src/components'
import { AddDialog } from 'src/components/Dialogs/AddDialog'
import { Song, Tip } from 'src/graphql/autogenerate/schemas'
import { useIcon } from 'src/hooks'
import { TypeEnum } from 'src/utils'

import { AllSongsCard } from '../AllSongsCard/AllSongsCard'
import { FormBox } from '../FormBox/FormBox'
import { SongCard } from '../SongCard/SongCard'
import { MAX_SONGS } from '../StepTwoForm/utils'
import { SongOptions, Song_Option } from './utils'

type SelectSongProps = {
	selectedSongs: Song[]
	useAllSongs: boolean
	setSelectedSongs: (songs: Song[]) => void
	setUseAllSongs: (useAllSongs: boolean) => void
	setSongsCounter: (songs: number) => void
	limit?: number
	formik: FormikProps<Record<string, string>>
}

export const SelectSong = ({
	selectedSongs,
	useAllSongs,
	setSelectedSongs,
	setUseAllSongs,
	setSongsCounter,
	formik,
	limit = MAX_SONGS
}: SelectSongProps) => {
	const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false)

	const [isSongOptionDialogOpen, setIsSongOptionDialogOpen] = useState(false)
	const songSelectOptions = useMemo(
		() =>
			SongOptions.map((item) => {
				const Icon = useIcon(item.iconName)

				return {
					id: String(item.option),
					name: (
						<Box>
							<Typography>{item.title}</Typography>
							<Typography>{item.subtitle}</Typography>
						</Box>
					),
					icon: <Icon />
				}
			}),
		[]
	)

	const openAddSongsDialog = () => setIsSelectDialogOpen(true)
	const onSaveSongs = (selectedItems: (Song | Tip)[]) => setSelectedSongs(selectedItems as Song[])
	const openSongOptionDialog = () => setIsSongOptionDialogOpen(true)
	const closeSongOptionDialog = () => setIsSongOptionDialogOpen(false)
	const handleSelectOption = (option: string) => {
		const shouldSelectSongs = Number(option) === Song_Option.Select
		setUseAllSongs(!shouldSelectSongs)
		closeSongOptionDialog()
		if (shouldSelectSongs) openAddSongsDialog()
	}
	useEffect(() => {
		closeSongOptionDialog()
	}, [selectedSongs])

	return (
		<>
			<FormBox
				text="Pick Songs"
				withAddIcon
				onClick={openSongOptionDialog}
				content={
					useAllSongs ? (
						<AllSongsCard />
					) : (
						selectedSongs.length &&
						selectedSongs.map((song) => (
							<SongCard
								song_id={song.song_id}
								imgSource={song.image_path}
								songTitle={song.title}
								artistName={song.artist.name}
								catalogs={song.song_catalog_item.map((item) => item.catalog_item.name)}
								key={song.song_id}
								setSelectedSongs={setSelectedSongs}
								selectedSongs={selectedSongs}
							/>
						))
					)
				}
			/>
			<ListDialog
				open={isSongOptionDialogOpen}
				cols={2}
				onClose={closeSongOptionDialog}
				title="Add Songs to Assignment"
				description="Please select how you like to add Songs to this Assignment."
				handleSelectOption={handleSelectOption}
				options={songSelectOptions}
			/>

			{isSelectDialogOpen && (
				<AddDialog
					formik={formik}
					setCounter={setSongsCounter}
					limit={limit}
					itemsSelected={selectedSongs}
					onSave={onSaveSongs}
					open={isSelectDialogOpen}
					setOpen={setIsSelectDialogOpen}
					type={TypeEnum.Song}
				/>
			)}
		</>
	)
}
