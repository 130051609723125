import { styled } from '@material-ui/core'
import { Filter_Color_Name_Enum } from 'src/graphql/autogenerate/schemas'

export const getCssFilter = (filter?: Filter_Color_Name_Enum) => {
	switch (filter) {
		case Filter_Color_Name_Enum.Sepia:
			return 'grayscale(100%) sepia(70%) hue-rotate(340deg)'
		case Filter_Color_Name_Enum.BlackAndWhite:
			return 'grayscale(100%)'
		case Filter_Color_Name_Enum.Warm:
			return 'url(#WarmFilter)'
		case Filter_Color_Name_Enum.Diva:
			return 'url(#DivaFilter)'
		default:
			return 'none'
	}
}

export const OverlayFilter = ({
	filter,
	generatingThumbnails = false
}: {
	filter?: Filter_Color_Name_Enum
	generatingThumbnails?: boolean
}) => {
	switch (filter) {
		case Filter_Color_Name_Enum.Vintage:
			if (!generatingThumbnails) return <VintageFilter />

			return <></>
		case Filter_Color_Name_Enum.Warm:
			if (!generatingThumbnails) return <WarmFilter />

			return (
				<HiddenFilter>
					<WarmFilter />
				</HiddenFilter>
			)
		case Filter_Color_Name_Enum.Diva:
			if (!generatingThumbnails) return <DivaFilter />

			return (
				<HiddenFilter>
					<DivaSVGFilter />
				</HiddenFilter>
			)
		default:
			return <></>
	}
}

const BaseFilter = styled('div')({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0
})

const StyledSvg = styled('svg')({
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: 1
})

const VintageFilter = styled(BaseFilter)({
	backgroundColor: '#00075d',
	opacity: 0.72,
	mixBlendMode: 'exclusion'
})

const DivaFilterBase = styled(BaseFilter)({
	background: `radial-gradient(
		rgba(0, 0, 0, 0) 65%,
		rgba(0, 0, 0, 0.44441526610644255) 80%,
		rgba(0, 0, 0, 0.562062324929972) 100%
	)`,
	borderRadius: '10px',
	filter: 'blur(10px)',
	transform: 'scale(1.1)'
})

const HiddenFilter = styled('div')({
	position: 'fixed',
	top: 0,
	left: 0,
	visibility: 'hidden'
})

const WarmFilter = () => (
	<StyledSvg xmlns="http://www.w3.org/2000/svg" version="1.1">
		<defs>
			<filter id="WarmFilter" colorInterpolationFilters="sRGB">
				{/* Individual channel curves */}
				<feComponentTransfer>
					<feFuncR type="table" tableValues="0.0 0.15 0.3 0.44 0.57 0.71 0.84 0.96 1.0" />
					<feFuncG type="table" tableValues="0.0 0.08 0.18 0.33 0.48 0.65 0.8 0.9 1.0" />
					<feFuncB type="table" tableValues="0.05 0.28 0.51 0.73 0.96" />
				</feComponentTransfer>

				{/* Whitepoint curve (all three channels are the same) */}
				<feComponentTransfer>
					<feFuncR type="table" tableValues="0.09 0.32 0.55 0.77 1.0" />
					<feFuncG type="table" tableValues="0.09 0.32 0.55 0.77 1.0" />
					<feFuncB type="table" tableValues="0.09 0.32 0.55 0.77 1.0" />
				</feComponentTransfer>
			</filter>
		</defs>
	</StyledSvg>
)

const DivaSVGFilter = () => (
	<StyledSvg xmlns="http://www.w3.org/2000/svg" version="1.1">
		<defs>
			<filter id="DivaFilter" colorInterpolationFilters="sRGB">
				<feComponentTransfer>
					{/* Whitepoint curve (all three channels are the same) */}
					<feFuncR type="table" tableValues="0.0 0.1 0.23 0.38 0.51 0.64 0.79 0.92 1.0" />
					<feFuncG type="table" tableValues="0.0 0.1 0.23 0.38 0.51 0.64 0.79 0.92 1.0" />
					<feFuncB type="table" tableValues="0.0 0.1 0.23 0.38 0.51 0.64 0.79 0.92 1.0" />
				</feComponentTransfer>
			</filter>
		</defs>
	</StyledSvg>
)

const DivaFilter = () => {
	return (
		<DivaFilterBase>
			<DivaSVGFilter />
		</DivaFilterBase>
	)
}
