import { useRef, useEffect, MutableRefObject } from 'react'

import { Box, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import { ViewModuleOutlined } from '@material-ui/icons'
import ListIcon from '@material-ui/icons/List'
import { PagesProps } from 'src/@types'
import { ActionButton, MainTitle, SelectSortOrderBy, WurrlyCard, Select, InfoDialog } from 'src/components'
import { ListWurrlies } from 'src/components/ListWurrlies'
import { Wurrly, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { fetchOnScrollDown } from 'src/utils'

import useStyles from './MyWurrlies.styles'
import { useMyWurrlies, WurrlyDisplayType } from './useMyWurrlies'

export const MyWurrlies = ({ page }: PagesProps) => {
	const styles = useStyles()
	const scrollRef = useRef<HTMLDivElement>(null)

	const {
		wurrlies,
		isFetching,
		loading,
		setIsFetching,
		display,
		setDisplay,
		sort,
		handleSortType,
		wurrlyTypes,
		wurrlyType,
		handleSelectWurrlyType,
		classes,
		handleSelectClass,
		classId,
		isErrorOpen,
		setIsErrorOpen,
		goToWurrlyDetails
	} = useMyWurrlies()

	useEffect(() => {
		if (scrollRef?.current && !isFetching)
			window.addEventListener('scroll', () => {
				const htmlElement = scrollRef?.current as unknown as HTMLElement
				fetchOnScrollDown(isFetching, setIsFetching, htmlElement)
			})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [scrollRef])
	const buildWurrlyTitle = (wurrly: typeof wurrlies[number]): string => {
		switch (wurrly.wurrly_type) {
			case Wurrly_Type_Enum.Assignment:
			case Wurrly_Type_Enum.Indie:
				return wurrly.class?.title ?? ''
			case Wurrly_Type_Enum.Challenge:
				return 'Challenge'
		}
	}

	const buildWurrlySubtitle = (wurrly: typeof wurrlies[number]): string => {
		switch (wurrly.wurrly_type) {
			case Wurrly_Type_Enum.Assignment:
				return wurrly.submission_wurrly?.submission?.class_assignment?.assignment?.name ?? ''
			case Wurrly_Type_Enum.Indie:
				return 'Indie'
			case Wurrly_Type_Enum.Challenge:
				return wurrly.challenge?.title ?? wurrly.class?.title
		}
	}

	return (
		<div ref={scrollRef as MutableRefObject<HTMLDivElement>}>
			<MainTitle breadcrumbs={[page]} title="My Wurrlies" />

			<Box className={styles.listToolbar}>
				<Grid className={styles.grid} container spacing={3}>
					<Grid item xs={12}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							className={styles.toolbar}>
							<Select
								className={styles.classSelect}
								options={[
									{ name: 'All', value: 'all' },
									...classes.map((item) => ({ name: item.title, value: item.class_id }))
								]}
								onChange={handleSelectClass}
								value={classId}
							/>
							<Select
								options={[
									{ name: 'All', value: 'all' },
									...wurrlyTypes.map(({ name }) => ({ name, value: name }))
								]}
								onChange={handleSelectWurrlyType}
								value={wurrlyType}
							/>

							<ActionButton
								className={
									display === WurrlyDisplayType.Grid
										? styles.actionButtonColor
										: styles.actionButton
								}
								onClick={() => setDisplay(WurrlyDisplayType.Grid)}
								color={display === WurrlyDisplayType.Grid ? 'secondary' : 'default'}
								variant="outlined"
								icon={<ViewModuleOutlined />}
							/>
							<ActionButton
								className={
									display === WurrlyDisplayType.List
										? styles.actionButtonColor
										: styles.actionButton
								}
								onClick={() => setDisplay(WurrlyDisplayType.List)}
								color={display === WurrlyDisplayType.List ? 'secondary' : 'default'}
								variant="outlined"
								icon={<ListIcon />}
							/>
							<SelectSortOrderBy cyKey="Wurrlies" value={sort} onChange={handleSortType} />
						</Box>
					</Grid>
				</Grid>
			</Box>

			{wurrlies.length > 0 ? (
				<Box pt={3}>
					<Grid spacing={3} container>
						{display === WurrlyDisplayType.Grid ? (
							wurrlies.map((wurrly) => {
								const className = wurrly?.class?.title || ''

								const comment = wurrly?.submission_wurrly?.submission?.teacher_comment || ''

								return (
									<Grid item xs={4} key={wurrly.wurrly_id}>
										<WurrlyCard
											type={wurrly?.wurrly_type}
											header={{
												title: buildWurrlyTitle(wurrly),
												subtitle: buildWurrlySubtitle(wurrly),
												avatar: (!className ? wurrly.student?.image_path : '') as string,
												date: wurrly.created_at,
												privacy: wurrly?.privacy
											}}
											cardMedia={wurrly.image_path || ''}
											content={{
												title: wurrly.track?.song?.title || '',
												subtitle: wurrly.track?.song?.artist?.name ?? '',
												trackType: wurrly.track?.track_type?.name ?? '',
												comment,

												grade: wurrly?.submission_wurrly?.submission?.grade || ''
											}}
											onClick={() => goToWurrlyDetails(wurrly.wurrly_id, wurrly.class_id)}
										/>
									</Grid>
								)
							})
						) : (
							<Grid item xs>
								<ListWurrlies
									data={wurrlies as Wurrly[]}
									spacing={1.5}
									onClick={goToWurrlyDetails}
								/>
							</Grid>
						)}
					</Grid>
				</Box>
			) : (
				<Box>
					{!loading && (
						<>
							<Divider />
							<Box my={4} textAlign="center">
								<Typography variant="h6" color="textSecondary">
									You haven't any content yet.
								</Typography>
							</Box>
							<Divider />
						</>
					)}
				</Box>
			)}

			<InfoDialog
				open={isErrorOpen}
				onClose={() => {
					setIsErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while setting district to Enterprise"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsErrorOpen(false)
				}}
			/>

			{loading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
		</div>
	)
}
