import { Box } from '@material-ui/core'
import { PlaylistsWrapper } from 'src/components/Playlists/PlaylistsWrapper'
import { VideosWrapper } from 'src/components/Videos/VideosWrapper'

import { ClassVideosTitle } from './components/ClassVideosTitle'

export const ClassVideos = () => {
	return (
		<Box>
			<ClassVideosTitle />
			<VideosWrapper />
			<PlaylistsWrapper />
		</Box>
	)
}
