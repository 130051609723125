import { PagesProps } from 'src/@types'
import { useGetCatalogByPkQuery } from 'src/graphql/autogenerate/hooks'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { LessonsCatalogBody } from 'src/scenes/Teacher/scenes/6-Lessons/components/LessonsCatalogBody'
import { buildBreadCrumbs, capitalize } from 'src/utils'
export const CatalogLessons = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.CatalogItemLessons.params[number]>()

	const { catalogId } = params

	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})

	const teacherId = teacher.teacher_id

	const catalogName = catalogData?.catalog_by_pk?.name
	const title = catalogData?.catalog_by_pk?.name || page.name

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: capitalize(catalogName)
			},
			{
				index: 2,
				overrideName: title || ''
			}
		],
		params
	})

	return (
		<LessonsCatalogBody
			breadcrumbs={breadcrumbs}
			catalogId={catalogId}
			teacherId={teacherId}
			title={title}
			catalogName={catalogName}
		/>
	)
}
