import { useHistory } from 'react-router-dom'
import { Pages } from 'src/routes/teacherPages'
import { ChannelCard, ChannelCardProps } from 'src/scenes/Teacher/scenes/channels/components/ChannelCard'
import { buildRouteParameters } from 'src/utils'

export const ChannelCardContainer = ({ channel, ...props }: ChannelCardProps) => {
	const history = useHistory()
	const goToChannel = () =>
		history.push(buildRouteParameters(Pages.ChannelPage, { channelId: channel.channel_id }))

	return (
		<>
			<ChannelCard channel={channel} deleteTooltip="Delete Channel" {...props} onView={goToChannel} />
		</>
	)
}
