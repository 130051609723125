import { createStyles, makeStyles, alpha } from '@material-ui/core'

export const usePartnerStyles = makeStyles(() =>
	createStyles({
		listToolbar: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '12px',
			alignItems: 'center'
		},
		infiniteScroll: {
			gap: '24px',
			display: 'flex',
			flexDirection: 'column',
			paddingTop: '24px'
		},
		fullWidth: {
			width: '100%'
		},
		deleteButton: {
			background: '#f44336',
			color: 'white',
			'&:hover': {
				background: alpha('#f44336', 0.8)
			}
		}
	})
)

export default usePartnerStyles
