import { useMemo, useState } from 'react'

import {
	DIAGRAM_SVG_FILL_COLOR,
	DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT
} from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/constants'
import { remapChord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/utils'
import teoria from 'teoria'

type Chord = {
	name: string
}
type KeyboardDiagramType = {
	chord: Chord
	isHighlighted?: boolean
}

enum KeyColor {
	White = 'white',
	Black = 'black'
}

type KeyType = {
	type: KeyColor
	xPos: number
	width: number
	height: number
}

const BLACK_KEY_INDICATOR_STYLE = { fill: '#ffffff' }
const WHITE_KEY_INDICES = new Set([0, 2, 4, 5, 7, 9, 11, 12, 14, 16, 17, 19, 21, 23])
const KEY_WIDTH = 20
const KEY_HEIGHT = 100
export const KeyboardDiagram = ({ chord: currentChord, isHighlighted = false }: KeyboardDiagramType) => {
	const fillColor = isHighlighted ? DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT : DIAGRAM_SVG_FILL_COLOR
	const [keys, setKeys] = useState<KeyType[]>([])

	const chordToMidi = (chordName: string): number[] => {
		const notes = teoria.chord(chordName).notes()

		return notes.map((n: { midi: () => number }) => {
			return (n.midi() - 12) % 24
		})
	}

	const renderIndicators = () => {
		const chord = remapChord(currentChord)
		if (!chord) {
			return null
		}

		const tNotes = chordToMidi(chord.name)
		// const tNotes = [1, 2, 3, 4, 5, 6]

		return tNotes.map((note) => {
			const key = keys[note]
			if (!key) {
				return null
			}
			const type = key.type
			const xPos = key.xPos
			const width = key.width
			const height = key.height
			const cx = xPos + width / 2

			if (type === KeyColor.White) {
				const cy = height - height * 0.15
				const radius = (width * 0.66) / 2

				return <circle cx={cx - KEY_WIDTH} r={radius} cy={cy} style={{ fill: fillColor }} />
			} else {
				const cy = height - height * 0.25
				const radius = (width * 0.66) / 2

				return <circle cx={cx - KEY_WIDTH / 2} r={radius - 1} cy={cy} style={BLACK_KEY_INDICATOR_STYLE} />
			}
		})
	}

	const renderKeys = () => {
		let k = 0
		const whiteKeys = []
		const blackKeys = []
		const newKeys = []

		for (let i = 0; i < 24; i++) {
			if (WHITE_KEY_INDICES.has(i)) {
				const key = (
					<rect
						key={i + '_key'}
						style={{
							fill: 'rgb(255, 255, 255)',
							stroke: fillColor,
							strokeWidth: 1
						}}
						className={KeyColor.White}
						x={k * KEY_WIDTH}
						y={0}
						width={KEY_WIDTH}
						height={KEY_HEIGHT}
					/>
				)

				k += 1

				newKeys[i] = {
					type: KeyColor.White,
					xPos: k * KEY_WIDTH,
					width: KEY_WIDTH,
					height: KEY_HEIGHT
				}
				whiteKeys.push(key)
			} else {
				const key = (
					<rect
						key={i + '_key'}
						style={{
							fill: fillColor,
							stroke: fillColor,
							strokeWidth: 1
						}}
						className={KeyColor.Black}
						x={(k - 1) * KEY_WIDTH + 15}
						y={0}
						width={10}
						height={KEY_HEIGHT * 0.7}
					/>
				)
				newKeys[i] = {
					type: KeyColor.Black,
					xPos: k * KEY_WIDTH,
					width: KEY_WIDTH,
					height: KEY_HEIGHT * 0.7
				}
				blackKeys.push(key)
			}
		}

		setKeys(newKeys)

		return whiteKeys.concat(blackKeys)
	}

	const indicatorContent = <g style={{ opacity: 1 }}>{renderIndicators()}</g>

	const keyContent = useMemo(() => renderKeys(), [isHighlighted])

	return (
		<div className="chord-diagram-container keyboard">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 100">
				<g className="keyboard-keys">{keyContent}</g>
				{indicatorContent}
			</svg>
		</div>
	)
}
