import { ReactNode } from 'react'

import { Box, Paper, styled, Typography } from '@material-ui/core'

import { BigBaseButton } from '../Buttons/BigBaseButton'

const SectionContainer = styled(Box)({
	margin: '20px 0 35px'
})
const StyledTitle = styled(Typography)({
	fontSize: '1.5em',
	marginBottom: '.7em',
	fontWeight: 'bold'
})
const StyledBox = styled(Box)({
	padding: '45px',
	paddingTop: '35px',
	display: 'flex',
	flexDirection: 'column'
})
const StyledButton = styled(BigBaseButton)({
	width: 'fit-content',
	minWidth: '170px',
	alignSelf: 'flex-end'
})

type ProfileSectionProps = {
	title?: ReactNode
	body: ReactNode
	editBtn?: {
		label: string
		action: () => void
	}
}

export const ProfileSection = ({ title, body, editBtn }: ProfileSectionProps) => {
	return (
		<SectionContainer component="section">
			{title && typeof title === 'string' && <StyledTitle variant="h1">{title}</StyledTitle>}
			{title && typeof title !== 'string' && title}
			<Paper>
				<StyledBox>
					{body}
					{editBtn && (
						<StyledButton color="primary" onClick={editBtn.action}>
							{editBtn.label}
						</StyledButton>
					)}
				</StyledBox>
			</Paper>
		</SectionContainer>
	)
}
