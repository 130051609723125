import { makeStyles, Theme, createStyles, alpha } from '@material-ui/core'

export const useSelectableOpacity = makeStyles((theme: Theme) =>
	createStyles({
		base: {
			position: 'relative'
		},
		overlay: {
			position: 'absolute',
			zIndex: 0
		},
		selectedOverlay: {
			position: 'absolute',
			zIndex: 1,
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			borderColor: theme.palette.secondary.main,
			backgroundColor: alpha(theme.palette.secondary.main, 0.25)
		},
		selectedTrackCheckmark: {
			position: 'absolute',
			top: '5px',
			right: '5px'
		}
	})
)

export default useSelectableOpacity
