import { useState, useEffect, useMemo } from 'react'

import '@brainhubeu/react-carousel/lib/style.css'
import Carousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel'
import { Box, IconButton, styled } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useStudioReviewContext } from 'src/components/Studio/Review/useStudioReview'
import { DEFAULT_AUDIO_THUMBNAILS } from 'src/utils/constants'

export const SelectThumbnailCarousel = () => {
	const { setSelectedThumbnail } = useStudioReviewContext()
	const [currentSlide, setCurrentSlide] = useState(0)

	useEffect(() => {
		setSelectedThumbnail(DEFAULT_AUDIO_THUMBNAILS[currentSlide])
	}, [currentSlide])

	return (
		<CarouselContainer>
			{useMemo(
				() => (
					<Carousel
						value={currentSlide}
						onChange={setCurrentSlide}
						slides={DEFAULT_AUDIO_THUMBNAILS.map((src, i) => (
							<img
								src={src}
								alt={`thumbnail_${i}`}
								key={`thumbnail_${i}`}
								style={{ width: '100%' }}
							/>
						))}
						plugins={[
							'infinite',
							{
								resolve: arrowsPlugin,
								options: {
									arrowLeft: (
										<IconButton>
											<ChevronLeft style={{ color: 'white', fontSize: '38px' }} />
										</IconButton>
									),
									arrowRight: (
										<IconButton>
											<ChevronRight style={{ color: 'white', fontSize: '38px' }} />
										</IconButton>
									),
									addArrowClickHandler: true
								}
							}
						]}
					/>
				),
				[currentSlide, setCurrentSlide]
			)}

			<Dots value={currentSlide} onChange={setCurrentSlide} number={DEFAULT_AUDIO_THUMBNAILS.length} />
		</CarouselContainer>
	)
}

const CarouselContainer = styled(Box)({
	height: '100%',
	'& .BrainhubCarousel__container': {
		height: '100%'
	},
	'& .BrainhubCarousel': {
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between'
	},
	'& .BrainhubCarousel__trackContainer': {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%'
	},
	'& .BrainhubCarousel__customArrows': {
		zIndex: 1000
	},
	'& .BrainhubCarousel__track': {
		height: '100%'
	},
	'& .BrainhubCarousel__dots': {
		position: 'absolute',
		bottom: '115px ',
		width: '100% '
	},
	'& .BrainhubCarousel__dot': {
		opacity: 1
	},
	'& .BrainhubCarousel__dot:before': {
		width: '10px',
		height: '10px',
		border: '1px solid #fff',
		background: 'rgba(0,0,0,0)'
	},
	'& .BrainhubCarousel__dot--selected:before': {
		background: '#fff'
	}
})
