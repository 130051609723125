import { ChangeEvent, Fragment } from 'react'

import { Card, Box, Divider } from '@material-ui/core'
import { SelectSort } from 'src/components'
import { SubmissionCard } from 'src/components/Cards/SubmissionCard/SubmissionCard'
import { Grading_System, Submission } from 'src/graphql/autogenerate/schemas'
import { SortOrder } from 'src/utils/enums'

import useStyles from './StudentsFilterDropDown.styles'

type StudentsFilterDropDownProps = {
	parentCardWidth: number
	submissions: Submission[]
	sortOrder: SortOrder
	assignmentDueDateString: string
	setSortOrder: (sortOrder: SortOrder) => void
	onClick?: (submission: Submission) => void
	gradingSystem?: Pick<Grading_System, 'label' | 'values'> | undefined
}
export const StudentsFilterDropDown = ({
	parentCardWidth,
	submissions,
	sortOrder,
	setSortOrder,
	onClick,
	gradingSystem,
	assignmentDueDateString
}: StudentsFilterDropDownProps) => {
	const styles = useStyles({ width: parentCardWidth })

	const handleSortOrder = (
		event: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		setSortOrder(event.target.value as SortOrder)
	}

	return (
		<Box className={styles.container}>
			<Card className={styles.card}>
				<Box className={styles.scrollContainer}>
					<Box className={styles.cardContainer}>
						<SelectSort
							value={sortOrder}
							onChange={handleSortOrder}
							cyKey="sort_submissions"
							dateSubmitted
							grade
						/>
					</Box>
					{submissions.map((submission) => {
						const handleClick = () => onClick?.(submission)

						return (
							<Fragment key={submission.submission_id}>
								<Divider className={styles.divider} />
								<Box className={styles.cardContainer} onClick={handleClick}>
									<SubmissionCard
										avatarUrl={
											submission.submission_wurrlies?.[0]?.wurrly?.student?.image_path ?? ''
										}
										submitterName={submission.name ?? ''}
										submissionDate={submission.date_submitted ?? ''}
										grade={submission.grade ?? ''}
										dueDateString={assignmentDueDateString}
										gradingSystem={gradingSystem}
										teacherComment={submission.teacher_comment as string}
									/>
								</Box>
							</Fragment>
						)
					})}
				</Box>
			</Card>
		</Box>
	)
}
