import React, { useEffect, useState, Fragment } from 'react'

import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Tab,
	Tabs,
	Typography
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import { noop } from 'lodash/fp'
import { BaseDialog } from 'src/components'
import { useGetSequenceLazyQuery, useUpdateClassSequencesMutation } from 'src/graphql/autogenerate/hooks'
import { Sequence } from 'src/graphql/autogenerate/schemas'
import { useScrollElement } from 'src/hooks'
import { setTimeoutQuery, buildQueryVariables, buildSearchText } from 'src/utils'

import useStyles from './AddDialog.styles'
import { ListAddItems } from './ListAddItems'

type AddDialogType = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	setOpenSuccess: React.Dispatch<React.SetStateAction<boolean>>
	setOpenError: React.Dispatch<React.SetStateAction<boolean>>
	initialSelection?: Sequence[]
	classId: number
}

export const AddSequencesDialog = ({
	open,
	setOpen,
	setOpenSuccess,
	setOpenError,
	initialSelection,
	classId
}: AddDialogType) => {
	const [items, setItems] = useState<({ selected: boolean } & Sequence)[]>([])
	const [count, setCount] = useState<number>()
	const [selectedItems, setSelectedItems] = useState<Sequence[]>([])
	const [search, setSearch] = useState<string>()
	const [focus, setFocus] = useState(false)

	const [hasUsedInitialData, setHasUsedInitialData] = useState(false)
	const [timer, setTimer] = useState(0)
	const styles = useStyles(focus)

	const itemQueryVariables = buildQueryVariables({
		conditions: buildSearchText(search || '', ['name']),
		offset: 0,
		limit: 15
	})

	const [loadItemQuery, itemQuery] = useGetSequenceLazyQuery()

	const setItemQuery = () => {
		if (!itemQuery.loading && itemQuery?.data?.sequence !== undefined) {
			setCount(itemQuery.data.sequence_aggregate.aggregate?.count || 0)
			setItems(
				(itemQuery?.data?.sequence?.map((item) => ({
					...item,
					selected: selectedItems.some((it) => it.sequence_id === item.sequence_id)
				})) as ({ selected: boolean } & Sequence)[]) || []
			)
		}
	}

	useEffect(() => {
		loadItemQuery(itemQueryVariables)
		setItemQuery()
	}, [])

	useEffect(() => {
		if (initialSelection) setSelectedItems(initialSelection)
	}, [initialSelection])

	useEffect(() => {
		setTimeoutQuery(
			() => {
				loadItemQuery(itemQueryVariables)
			},
			setTimer,
			timer
		)
	}, [search])

	useEffect(() => {
		return setItemQuery()
	}, [itemQuery.data])

	useEffect(() => {
		if (items.length > 0) {
			setItems(
				items.map((item) => {
					if (selectedItems.some((it) => it.sequence_id === item.sequence_id)) {
						return { ...item, selected: true }
					} else {
						return { ...item, selected: false }
					}
				})
			)
		}
	}, [selectedItems])

	useEffect(() => {
		if (initialSelection && items.length && !hasUsedInitialData) {
			setItems(
				(prevItems) =>
					prevItems.map((item) => ({
						...item,
						selected: selectedItems.some((it) => it.sequence_id === item.sequence_id)
					})) || []
			)
			setHasUsedInitialData(true)
		}
	}, [initialSelection, items])

	const handleFocus = () => setFocus(true)

	const handleBlur = () => setFocus(false)

	const onSelect = (
		item: {
			selected: boolean
		} & Sequence
	) => {
		const array = [...selectedItems]
		const index = array.findIndex((it) => it.sequence_id === item.sequence_id)
		if (index !== -1) {
			array.splice(index, 1)
		} else {
			array.push(item)
		}
		setSelectedItems(array)
	}

	const scrollRef = React.useRef<HTMLDivElement>(null)
	const [isFetchingMore, setIsFetchingMore] = useState(false)

	const { y, direction } = useScrollElement({ wait: 500, element: scrollRef })

	useEffect(() => {
		const scrollHeight = scrollRef?.current?.scrollHeight || 0
		const clientHeight = scrollRef?.current?.clientHeight || 190

		const fetchMore = async () => {
			if (!itemQuery.fetchMore) {
				return
			}
			const fetchMoreResult = await itemQuery.fetchMore({
				variables: { offset: items?.length }
			})
			let newItems: Sequence[] = []

			newItems = fetchMoreResult.data.sequence as Sequence[]

			setItems((oldItems) => [
				...oldItems,
				...newItems.map((item) => ({
					...item,
					selected: selectedItems.some((it) => it.sequence_id === item.sequence_id)
				}))
			])

			setIsFetchingMore(false)
		}

		if (y && y > 0 && direction === 'down' && !isFetchingMore) {
			// fetch more data
			if (y >= scrollHeight - clientHeight * 3) {
				setIsFetchingMore(true)
				fetchMore()
			}
		}
	}, [y, direction])

	const [updateClassSequence, { data }] = useUpdateClassSequencesMutation()

	const handleSaveSequences = async () => {
		try {
			await updateClassSequence({
				variables: {
					objects: selectedItems.map((sequence) => ({
						class_id: classId,
						sequence_id: sequence.sequence_id
					}))
				}
			})
		} catch {
			setOpenError(true)
		}
	}

	useEffect(() => {
		if (data) {
			setOpen(false)
			setOpenSuccess(true)
		}
	}, [data])

	return (
		<BaseDialog
			onClose={noop}
			dividers={false}
			open={open}
			header={
				<Fragment>
					<Typography variant="h5" align="center" className={styles.title}>
						<b>Add Sequences</b>
					</Typography>
					<Paper elevation={0} className={focus ? styles.boxInputFocus : styles.boxInput}>
						<IconButton aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							value={search ?? ''}
							onChange={(e) => setSearch(e.target.value)}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder={'Find Sequences by Title'}
							inputProps={{ 'aria-label': 'search song' }}
						/>
						{!!search && (
							<Button
								className={styles.btnSearch}
								variant="text"
								onClick={() => setSearch(undefined)}>
								<HighlightOffIcon />
							</Button>
						)}
					</Paper>
					<Tabs className={styles.tabs} value={0} aria-label="Sequence Catalog">
						<Tab
							label={
								<Typography variant="caption" color={'secondary'}>
									<b>SEQUENCE CATALOG</b>
								</Typography>
							}
						/>
					</Tabs>
					<Typography variant="subtitle1">
						<b>{`${count} Sequences`}</b>
					</Typography>
				</Fragment>
			}
			bodyProps={{ style: { minWidth: 500 } }}
			body={
				!itemQuery.loading ? (
					<Box className={styles.bodyBox}>
						<Grid item xs className={styles.bodyGrid} ref={scrollRef}>
							<ListAddItems spacing={1.5} data={items} onSelect={onSelect} />
							{items.length <= 0 && (
								<Box style={{ opacity: 0.5 }}>
									<Typography>No results found</Typography>
								</Box>
							)}
							{isFetchingMore && (
								<Box className={styles.boxProgress}>
									<CircularProgress color="secondary" size={40} />
								</Box>
							)}
						</Grid>
					</Box>
				) : (
					<Box className={styles.boxProgress}>
						<CircularProgress color="secondary" size={40} />
					</Box>
				)
			}
			onConfirm={() => {
				handleSaveSequences()
				setOpen(false)
				setSelectedItems([])
			}}
			confirmLabel={`Add Sequences`}
			onDiscard={() => {
				setOpen(false)
				setSelectedItems([])
			}}
			discardLabel="Cancel"
		/>
	)
}
