import React from 'react'

import { Box } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { SubscriptionsCardType } from 'src/@types'
import { ThinCheckIcon } from 'src/assets/icons'
import { useUnsuscribeDialog } from 'src/components/Dialogs/UnsuscribeDialog/useUnsuscribeDialog'
import { CancelProductSubscriptionMutation } from 'src/graphql/autogenerate/operations'
import { capitalize } from 'src/utils'

import { InfoDialog } from '../InfoDialog'

type OptionalMessages = {
	mainMessages?: {
		title?: string
		body?: string
		buttonLabel?: string
	}
	confirmation?: {
		title?: string
		body?: string
	}
}
type UnsuscribeDialogProps = {
	item: SubscriptionsCardType | undefined
	isOpen: boolean
	onConfirm: (stripeSubscriptionId: string) => Promise<CancelProductSubscriptionMutation>
	onDone?: (stripeSubscriptionId: string) => void
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setItem: React.Dispatch<React.SetStateAction<SubscriptionsCardType | undefined>>
	optionalMessages?: OptionalMessages
}

export const UnsuscribeDialog = ({
	item,
	isOpen,
	setIsOpen,
	onConfirm,
	setItem,
	optionalMessages,
	onDone
}: UnsuscribeDialogProps) => {
	const { isOnSuccessOpen, loading, setLoading, setIsOnSuccessOpen, isOnErrorOpen, setIsOnErrorOpen, theme } =
		useUnsuscribeDialog(isOpen, item, setIsOpen)
	const handleClose = () => {
		setIsOnSuccessOpen(false)
		setIsOpen(false)
		if (onDone) onDone(item ? item.stripeSubscriptionId : '')
		setItem(undefined)
	}

	return (
		<Box>
			<InfoDialog
				open={isOnSuccessOpen && !loading}
				onClose={handleClose}
				icon={<ThinCheckIcon />}
				title={
					optionalMessages?.confirmation?.title ||
					`${item ? capitalize(item.productType) : ''} Unsubscribed!`
				}
				body={
					optionalMessages?.confirmation?.body ||
					`You have successfully unsubscribed from this ${item ? capitalize(item.productType) : ''}.`
				}
				confirmLabel="Done"
				onConfirm={handleClose}
				confirmProps={{ style: { fontWeight: 'bold' } }}
			/>
			<InfoDialog
				open={isOnErrorOpen && !loading}
				onClose={() => {
					setIsOnErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
				confirmProps={{ style: { fontWeight: 'bold' } }}
			/>
			<InfoDialog
				open={isOpen}
				loading={loading}
				onClose={() => {
					if (!loading) handleClose()
				}}
				icon="?"
				title={
					optionalMessages?.mainMessages?.title ||
					`Unsubscribe from this ${item ? capitalize(item.productType) : ''}`
				}
				body={
					optionalMessages?.mainMessages?.body ||
					`Are you sure you want to unsubscribe from this ${item ? capitalize(item.productType) : ''}?`
				}
				discardLabel="Cancel"
				onDiscard={() => {
					if (!loading) handleClose()
				}}
				discardProps={{
					disabled: loading,
					style: {
						fontWeight: 'bold'
					}
				}}
				confirmLabel={optionalMessages?.mainMessages?.buttonLabel || 'Unsubscribe'}
				confirmProps={{
					disabled: loading,
					style: {
						backgroundColor: theme.palette.error.main,
						color: theme.palette.background.paper,
						fontWeight: 'bold'
					},
					startIcon: <DeleteIcon />
				}}
				onConfirm={async () => {
					try {
						setLoading(true)
						await onConfirm(item ? item.stripeSubscriptionId : '')
						setIsOpen(false)
						setIsOnSuccessOpen(true)
					} catch (error) {
						setIsOpen(false)
						setIsOnErrorOpen(true)
						setItem(undefined)
					} finally {
						setIsOpen(false)
						setLoading(false)
					}
				}}
			/>
		</Box>
	)
}
