import { useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'

const useMusicCatalog = () => {
	const [chords, setChords] = useState<string[]>([])
	const [catalogId, setCatalogId] = useState<number | null>(null)
	const [catalogItemId, setCatalogItemId] = useState<number | null>(null)
	const [search, setSearch] = useState<string>('')

	return {
		chords,
		setChords,
		catalogId,
		setCatalogId,
		catalogItemId,
		setCatalogItemId,
		search,
		setSearch
	}
}

export const [MusicCatalogProvider, useMusicCatalogContext] = buildGenericContext(useMusicCatalog)
