import { CSSProperties, useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { CHORDS, getCyKey } from 'src/utils'

import { BaseButton } from '.'

const useStyles = makeStyles<Theme, { columns: number }>((theme) =>
	createStyles({
		chord: {
			padding: theme.spacing(0.65),
			width: ({ columns }) => `calc(100% / ${columns})`,
			fontWeight: 'bold',
			[theme.breakpoints.down('sm')]: {
				width: ({ columns }) => `calc(100% / ${columns - 2})`
			},
			[theme.breakpoints.down('xs')]: {
				width: ({ columns }) => `calc(100% / ${columns - 4})`
			},
			'& .MuiButton-root': {
				fontSize: 12,
				width: '100%',
				minWidth: 'auto'
			}
		},
		disabledButton: {
			'&.MuiButton-contained&.Mui-disabled': {
				backgroundColor: `${theme.palette.error.contrastText} `,
				color: 'black ',
				opacity: '100% '
			}
		}
	})
)

type SelectChordProps = {
	onChange?: (value: string[]) => void
	selectedChords?: string[]
	disabled?: boolean
	style?: CSSProperties
	columns?: number
	showSelectedOnly?: boolean
	showGreyButtons?: boolean
}
export const SelectChord = ({
	onChange,
	selectedChords,
	disabled,
	style,
	columns = 11,
	showSelectedOnly,
	showGreyButtons
}: SelectChordProps) => {
	const classes = useStyles({ columns })
	const [checked, setChecked] = useState<string[]>([])

	useEffect(() => {
		if (selectedChords) setChecked([...new Set(selectedChords)])
	}, [selectedChords])

	const handleChord = (value: string) => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
		if (onChange) onChange(newChecked)
	}

	const allChords = showSelectedOnly ? checked || [] : CHORDS

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			m={-0.75}
			style={disabled ? { pointerEvents: 'none', ...style } : undefined}
			data-cy={getCyKey(SelectChord)}>
			{allChords.map((chord) => (
				<div data-cy={getCyKey(SelectChord, chord)} key={chord} className={classes.chord}>
					<BaseButton
						disabled={checked.indexOf(chord) === -1 && disabled}
						color={checked.indexOf(chord) !== -1 && !showGreyButtons ? 'secondary' : undefined}
						style={checked.indexOf(chord) !== -1 || disabled ? undefined : { backgroundColor: '#fff' }}
						onClick={handleChord(chord)}
						className={classes.disabledButton}>
						{chord}
					</BaseButton>
				</div>
			))}
		</Box>
	)
}
