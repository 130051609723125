import { useSearchQuery } from 'src/hooks/useQuery'

import { StudentAccountActivation } from './scenes/StudentAccountActivation/StudentAccountActivation'
import { TokenAccountActivation } from './scenes/TokenAccountActivation/TokenAccountActivation'
export const AccountActivation = () => {
	const querySearch = useSearchQuery()
	const hasToken = querySearch.has('token')

	if (hasToken) return <TokenAccountActivation />

	return <StudentAccountActivation />
}
