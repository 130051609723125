import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { NotesIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
const useStyles = makeStyles({
	icon: {
		position: 'fixed',
		bottom: '40px',
		left: '50%',
		transform: 'translateX(-50%)'
	},
	boxProgress: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)'
	}
})
export const CanvasLogIn = () => {
	const styles = useStyles()

	// handle login or SignUp success

	// handle login or SignUp error

	return (
		<FixedBackground>
			<img src={NotesIcon} className={styles.icon} />
			<Box className={styles.boxProgress}>
				<CircularProgress color="secondary" size={40} />
			</Box>
		</FixedBackground>
	)
}
