import { useEffect, useState, ChangeEvent } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { Page } from 'src/@types'
import { useGetSongsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Maybe, Midi_Bool_Exp, Song_Bool_Exp, Track_Bool_Exp } from 'src/graphql/autogenerate/schemas'
import { Pages, SlugEnum } from 'src/routes/teacherPages'
import {
	buildRouteParameters,
	buildSearchText,
	MAX_LIST_ITEMS,
	shouldSortTitleOrDate,
	SONG_KEYS,
	SortOrder
} from 'src/utils'

type useGetSongsParams = {
	teacherId: number
	catalogId?: number
	catalogItemId?: number
	catalogFilters: number[]
	page?: Page
	chords: string[]
	transpose: boolean
	scaleId?: number
	vocalGuide: boolean
	chordGuide: boolean
	featured: boolean
	notFeatured: boolean
}

export const useGetSongs = ({
	teacherId,
	catalogId,
	chords,
	transpose,
	scaleId,
	vocalGuide,
	chordGuide,
	catalogFilters,
	featured,
	notFeatured,
	page
}: useGetSongsParams) => {
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [filters, setFilters] = useState(catalogFilters)
	const [songs, setSongs] = useState<typeof querySongs>([])
	const [count, setCount] = useState(0)

	const location = useLocation()
	const [sort, setSort] = useState<SortOrder>(SortOrder.Up)
	const state = location.state as Record<string, string>
	const search = state?.searchbarText

	const [searchBarTextState, setSearchBarTextState] = useState('')
	const history = useHistory()
	const handleSort = (
		value: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = value.target.value as SortOrder

		setSort(selectedSort)
	}

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	// get queries
	const [loadSongsCatalog, { data: loadSongsCatalogData, loading, fetchMore }] = useGetSongsLazyQuery({
		variables: {
			teacherId,
			conditions: buildSearchText(search || '', ['title', 'artist.name']),
			offset: 0,
			limit,
			order: shouldSortTitleOrDate(sort)[0],
			scaleId: scaleId || null,
			filters: buildSongsSearchFilters(filters),
			chordFilters: getChordFilter(chords, transpose),
			vocalCondition: vocalGuide ? { tracks: { track_type: { name: { _eq: 'Vocal' } } } } : {},
			chordGuideConfidition:
				chords.length > 0 || chordGuide
					? { tracks: { midis: { chords_Array: { _neq: '' }, chords_Array_Zero: { _neq: '' } } } }
					: {},
			featuredCondition: featured ? { featured: { _eq: true } } : {},
			notFeaturedCondition: notFeatured ? { featured: { _eq: false } } : {}
		}
	})
	const [loadSearchSongsCatalog, loadSearchSongsCatalogData] = useGetSongsLazyQuery({
		variables: {
			teacherId,
			conditions: buildSearchText(search || '', ['title', 'artist.name']),
			offset: 0,
			limit,
			order: shouldSortTitleOrDate(sort)[0],
			scaleId: scaleId || null,
			filters: buildSongsSearchFilters(filters),
			chordFilters: getChordFilter(chords, transpose),
			vocalCondition: vocalGuide ? { tracks: { track_type: { name: { _eq: 'Vocal' } } } } : {},
			chordGuideConfidition:
				chords.length > 0 || chordGuide
					? { tracks: { midis: { chords_Array: { _neq: '' }, chords_Array_Zero: { _neq: '' } } } }
					: {},
			featuredCondition: featured ? { featured: { _eq: true } } : {},
			notFeaturedCondition: notFeatured ? { featured: { _eq: false } } : {}
		}
	})
	const querySongs = loadSongsCatalogData?.song || []

	const onSearch = (searbarText: string) => {
		loadSearchSongsCatalog({
			variables: {
				teacherId,
				conditions: buildSearchText(searbarText || '', ['title', 'artist.name']),
				offset: 0,
				limit,
				order: shouldSortTitleOrDate(sort)[0],
				scaleId: scaleId || null,
				filters: buildSongsSearchFilters(filters),
				chordFilters: getChordFilter(chords, transpose),
				vocalCondition: vocalGuide ? { tracks: { track_type: { name: { _eq: 'Vocal' } } } } : {},
				chordGuideConfidition:
					chords.length > 0 || chordGuide
						? { tracks: { midis: { chords_Array: { _neq: '' }, chords_Array_Zero: { _neq: '' } } } }
						: {},
				featuredCondition: featured ? { featured: { _eq: true } } : {},
				notFeaturedCondition: notFeatured ? { featured: { _eq: false } } : {}
			}
		})
	}
	const onSearchEnter = (searchbarText: string) => {
		if ((page && page.path === Pages.SearchSongs.path) || !searchbarText) {
			loadSongsCatalog({
				variables: {
					teacherId,
					conditions: buildSearchText(searchbarText || '', ['title', 'artist.name']),
					offset: 0,
					limit,
					order: shouldSortTitleOrDate(sort)[0],
					scaleId: scaleId || null,
					filters: buildSongsSearchFilters(filters),
					chordFilters: getChordFilter(chords, transpose),
					vocalCondition: vocalGuide ? { tracks: { track_type: { name: { _eq: 'Vocal' } } } } : {},
					chordGuideConfidition:
						chords.length > 0 || chordGuide
							? {
									tracks: {
										midis: { chords_Array: { _neq: '' }, chords_Array_Zero: { _neq: '' } }
									}
							  }
							: {},
					featuredCondition: featured ? { featured: { _eq: true } } : {},
					notFeaturedCondition: notFeatured ? { featured: { _eq: false } } : {}
				}
			})

			return
		}

		history.push(buildRouteParameters(Pages.SearchSongs), {
			searchbarText
		})
	}

	useEffect(() => {
		if (searchBarTextState === search) return
		if (search) {
			setSearchBarTextState(search)
		}
	}, [search])

	useEffect(() => {
		if (loadSongsCatalogData?.song) {
			setSongs(loadSongsCatalogData?.song)
			setCount(loadSongsCatalogData?.song_aggregate?.aggregate?.count ?? 0)
		}
	}, [loadSongsCatalogData])

	useEffect(() => {
		loadSongsCatalog()
	}, [catalogId, sort, search, featured, notFeatured, loadSongsCatalog, catalogFilters])

	useEffect(() => {
		if (!loading) setIsFetching(false)
	}, [loading])

	useEffect(() => {
		if (!isFetching) return
		if (!loading && fetchMore) {
			const currentLength = songs.length
			fetchMore({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.song.length !== 0) {
					setLimit(currentLength + (fetchMoreResult.data?.song.length || 0))
					setMoreResults(true)
				} else {
					setMoreResults(false)
					setIsFetching(false)
				}
			})
		}
	}, [isFetching])

	useEffect(() => {
		if (catalogId) {
			setFilters([catalogId, ...catalogFilters])
		} else {
			setFilters(catalogFilters)
		}
	}, [catalogId, catalogFilters])

	const goToRecommended = (location: Location) => {
		return {
			...location,
			pathname: Pages.RecommendedSongs.path,
			state: {
				catalog: SlugEnum.Recommended
			}
		}
	}

	return {
		songs,
		isFetching,
		moreResults,
		loading,
		count,
		setIsFetching,
		onSearch,
		onSearchEnter,
		handleSort,
		sort,
		searchBarTextState,
		goToRecommended,
		loadSongsCatalogData,
		loadSearchSongsCatalogData
	}
}

const buildSongsSearchFilters = (filtersArray: number[]) => {
	const filters = filtersArray.map((catalogItemId) => ({
		song_catalog_item: { catalog_item_id: { _eq: catalogItemId } }
	}))

	return filters
}

export const getChordFilter = (chords: string[], transpose: boolean): Song_Bool_Exp[] => {
	let transpositionArray: Song_Bool_Exp[] = []
	if (transpose) {
		// map all transcriptions
		transpositionArray = SONG_KEYS.map((key) => {
			const array: Track_Bool_Exp[] = chords.map((chord) => ({
				midis: {
					_or: [
						{ [`${getColumnName(key)}`]: { _ilike: `${chord},%` } },
						{ [`${getColumnName(key)}`]: { _ilike: `%,${chord},%` } },
						{ [`${getColumnName(key)}`]: { _ilike: `,${chord}%` } },
						{ [`${getColumnName(key)}`]: { _ilike: `${chord}` } }
					]
				} as Maybe<Midi_Bool_Exp>
			}))

			return { tracks: { _and: array } }
		})
	} else {
		const array: Track_Bool_Exp[] = chords.map((chord) => ({
			midis: {
				_or: [
					{ chords_Array_Zero: { _ilike: `${chord},%` } },
					{ chords_Array_Zero: { _ilike: `%,${chord},%` } },
					{ chords_Array_Zero: { _ilike: `,${chord}%` } },
					{ chords_Array_Zero: { _ilike: `${chord}` } }
				]
			}
		}))

		transpositionArray = [
			{
				tracks: { _and: array }
			}
		]
	}

	return transpositionArray
}

const getColumnName = (key: number) =>
	`chords_Array${key.toString().replace(RegExp(/\d/g), '').replace('-', '_Neg')}_${numberToString(
		Math.abs(key)
	)}`

const numberToString = (number: number) => {
	switch (number) {
		case 0:
			return 'Zero'
		case 1:
			return 'One'
		case 2:
			return 'Two'
		case 3:
			return 'Three'
		case 4:
			return 'Four'
		case 5:
			return 'Five'
		case 6:
			return 'Six'
		default:
			return ''
	}
}
