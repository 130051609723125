import { useState, useEffect } from 'react'

import { useHistory } from 'react-router'

import { buildGenericContext } from '../../../../../../context/genericContext'
import {
	useGetClassSettingsQuery,
	useGetDefaultGradingSystemsQuery,
	useGetGradesQuery,
	useGetSkillLevelsQuery,
	useGetSubjectsQuery
} from '../../../../../../graphql/autogenerate/hooks'
import { Catalog_Item, Class } from '../../../../../../graphql/autogenerate/schemas'
import { useWurrlyParams } from '../../../../../../hooks'
import { useLoginContext } from '../../../../../../hooks/useLogin'
import { Pages } from '../../../../../../routes/teacherPages'

const useClassSettings = () => {
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.ClassSettings.params[number]>()
	const classId = Number(params.classId)
	const teacherId = Number(teacher.teacher_id)

	const { data: subjectsData } = useGetSubjectsQuery()
	const subjects = (subjectsData?.catalog_item || []) as Catalog_Item[]
	const { data: gradingSystem } = useGetDefaultGradingSystemsQuery()
	const { data: skillsData } = useGetSkillLevelsQuery()
	const { data: gradesData } = useGetGradesQuery()

	const { data: classSettingsData, loading: classSettingsLoading } = useGetClassSettingsQuery({
		variables: { classId, teacherId }
	})

	const [classSettings, setClassSettings] = useState<Class | undefined>()

	useEffect(() => {
		if (!classSettingsLoading && classSettingsData?.teacher_by_pk?.class_teachers[0]?.class) {
			const classSettings = classSettingsData.teacher_by_pk?.class_teachers[0].class as Class
			setClassSettings(classSettings)
		}
	}, [classSettingsLoading, classSettingsData, classSettings])

	return {
		history,
		teacher,
		classId,
		teacherId,
		subjects,
		gradingSystem,
		skillsData,
		gradesData,
		classSettingsData,
		classSettingsLoading,
		classSettings,
		setClassSettings
	}
}

export const [ClassSettingsProvider, useClassSettingsContext] = buildGenericContext(useClassSettings)
