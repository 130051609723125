import { createStyles, makeStyles, Theme } from '@material-ui/core'
export const useClassWurrlyDetailsStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerBox: {
			backgroundColor: theme.palette.common.white,
			borderRadius: 4,
			boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 15%)',
			padding: '20px 30px'
		},
		messageBox: {
			backgroundColor: '#E9E9EA',
			padding: '20px 30px',
			borderBottomLeftRadius: 4,
			borderBottomRightRadius: 4
		},
		button: {
			width: '50%',
			maxWidth: '160px'
		},
		icon: {
			color: theme.palette.grey[500],
			width: '1.3em',
			marginRight: '5px'
		},
		avatar: {
			width: 50,
			height: 50
		},
		subtitle: {
			margin: '0 5px',
			textTransform: 'capitalize',
			color: theme.palette.grey[500]
		},
		practiceSubtitle: {
			textTransform: 'capitalize',
			color: theme.palette.grey[500]
		},
		artist: { marginBottom: '1.5em' },
		divider: { margin: '10px 0 15px 0' },
		practiceSubHeader: { marginRight: '5px' },
		trackTitle: { margin: '0 5px', fontWeight: 'bold', marginTop: '1em' },
		footerButtons: { marginTop: 20 },
		title: { lineHeight: 1.3 },
		cardHeader: { paddingLeft: 0 }
	})
)

export default useClassWurrlyDetailsStyles
