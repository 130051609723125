import { Box, Typography } from '@material-ui/core'
import { Asset } from 'src/graphql/autogenerate/schemas'

import { AssetItem } from './components/AssetItem'
import useStyles from './StepTwoForm.styles'

type StepTwoFormProps = {
	assignmentAssets: Asset[]
}
export const StepTwoForm = ({ assignmentAssets }: StepTwoFormProps) => {
	const styles = useStyles()

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}>
				<b>Download Learning Resources</b>
				{!assignmentAssets?.length ? (
					<Typography className={styles.noAssetsText} color="textSecondary">
						This assignment doesn't have any learning resource.
					</Typography>
				) : (
					<Box className={styles.assetsList}>
						{assignmentAssets?.map((i) => (
							<AssetItem key={i?.asset_id} asset={i} />
						))}
					</Box>
				)}
			</Typography>
		</Box>
	)
}
