import React, { useState } from 'react'

import { Box, Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
	useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation,
	useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation,
	useInsertStudentDontAskAgainRemoveFavoriteMutation,
	useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation
} from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'

import { InfoDialog } from './InfoDialog'

type RemoveFavoriteDialogProps = {
	pageItemName: string
	itemName: string
	removeItemFn?: () => Promise<void>
	setRemoveItemFn: React.Dispatch<React.SetStateAction<(() => Promise<void>) | undefined>>
	dontAsk: boolean
	setDontAsk: React.Dispatch<React.SetStateAction<boolean>>
}

export const RemoveFavoriteDialog = ({
	itemName,
	pageItemName,
	removeItemFn,
	setRemoveItemFn,
	setDontAsk
}: RemoveFavoriteDialogProps) => {
	const theme = useTheme()
	const [isChecked, setIsChecked] = useState(false)
	const { teacherData: teacher, studentData } = useLoginContext()

	const [insertTeacherSettings] = useInsertTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation()
	const [updateTeacherSettings] = useUpdateTeacherSettingDontAskAgainDeleteLessonFromFavoritesMutation()
	const [insertStudentSettings] = useInsertStudentDontAskAgainRemoveFavoriteMutation()
	const [updateStudentSettings] = useUpdateStudentSettingDontAskAgainRemoveFavoriteMutation()

	const onRemove = async () => {
		// setDontAsk(dontAskTemp)
		if (teacher.teacher_id) {
			if (!teacher?.teacher_setting?.teacher_id) {
				insertTeacherSettings({
					variables: { teacherId: teacher.teacher_id, dontAsk: isChecked },
					update: (cache) => {
						const identify = cache.identify(teacher)
						cache.evict({ id: identify, fieldName: 'teacher_setting' })
					}
				})
			} else if (isChecked !== teacher?.teacher_setting?.favorites_dont_ask_again_to_delete) {
				updateTeacherSettings({
					variables: { teacherId: teacher.teacher_id, dontAsk: isChecked },
					update: (cache) => {
						const identify = cache.identify(teacher)
						cache.evict({ id: identify, fieldName: 'teacher_setting' })
					}
				})
			}
		} else if (studentData.student_id) {
			if (!studentData?.student_setting?.student_id) {
				insertStudentSettings({
					variables: { studentId: studentData.student_id, dontAsk: isChecked },
					update: (cache) => {
						const identify = cache.identify(studentData)
						cache.evict({ id: identify, fieldName: 'student_setting' })
					}
				})
			} else if (isChecked !== studentData?.student_setting.favorites_dont_ask_again_to_delete) {
				updateStudentSettings({
					variables: { studentId: studentData.student_id, dontAsk: isChecked },
					update: (cache) => {
						const identify = cache.identify(studentData)
						cache.evict({ id: identify, fieldName: 'student_setting' })
					}
				})
			}
		}
		if (removeItemFn) removeItemFn()
	}

	return (
		<InfoDialog
			open={
				!!removeItemFn &&
				!teacher?.teacher_setting?.favorites_dont_ask_again_to_delete &&
				!studentData?.student_setting?.favorites_dont_ask_again_to_delete
			}
			onClose={() => setRemoveItemFn(undefined)}
			icon="?"
			title={`Remove this ${itemName} From Your Favorites?`}
			body={`It will be removed from your favorites list. You can re-favorite it again at any time from ${pageItemName}.`}
			discardLabel="Go Back"
			onDiscard={() => setRemoveItemFn(undefined)}
			confirmLabel="Remove"
			confirmProps={{
				style: { backgroundColor: theme.palette.error.main, color: theme.palette.background.paper }
			}}
			onConfirm={onRemove}
			footer={
				<Box ml={-1.5} mt={1}>
					<ListItem
						button
						dense
						disableGutters
						onClick={() => {
							setIsChecked(!isChecked)
							setDontAsk((prevDontAsk) => !prevDontAsk)
						}}>
						<ListItemIcon
							style={{
								minWidth: 'auto'
							}}>
							<Checkbox
								// edge="start"
								checked={isChecked}
								tabIndex={-1}
								disableRipple
								inputProps={{
									'aria-labelledby': "Don't ask me again"
								}}
							/>
						</ListItemIcon>
						<ListItemText id="dontAsk" primary="Don't ask me again" />
					</ListItem>
				</Box>
			}
		/>
	)
}
