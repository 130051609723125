import { Tooltip as MuiTooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const StyledTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#FFF',
		color: 'rgba(29, 39, 61, 0.7)',
		border: `1px solid ${theme.palette.primary.contrastText}`,
		fontSize: '16px',
		padding: '.4em',
		borderRadius: '6px',
		boxShadow:
			'0px 1.6603538990020752px 2.6012210845947266px 0px rgba(0, 0, 0, 0.05), 0px 3.9900612831115723px 6.251095771789551px 0px rgba(0, 0, 0, 0.04), 0px 7.5129313468933105px 11.770258903503418px 0px rgba(0, 0, 0, 0.03), 0px 13.401785850524902px 20.996129989624023px 0px rgba(0, 0, 0, 0.03), 0px 25.066566467285156px 39.27095413208008px 0px rgba(0, 0, 0, 0.02), 0px 60px 94px 0px rgba(0, 0, 0, 0.01);'
	},
	arrow: {
		color: 'white',
		'&::before': {
			border: `1px solid ${theme.palette.primary.contrastText}`
		}
	}
}))(MuiTooltip)
