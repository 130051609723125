import { Box, Typography, styled } from '@material-ui/core'

const StyledBox = styled(Box)({
	position: 'absolute',
	zIndex: 15,
	bottom: 0,
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '330px',
	background: 'rgba(255, 239, 211, 0.4)'
})

const CountDownText = styled(Typography)({
	color: '#F3AB34',
	fontSize: '10em',
	fontWeight: 500
})

type RecordCountDownProps = {
	countDown?: number
}

export const RecordCountDown = ({ countDown }: RecordCountDownProps) => {
	if (!countDown) return null

	return (
		<StyledBox>
			<CountDownText variant="h1">{countDown}</CountDownText>
		</StyledBox>
	)
}
