import { useState } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router'
import { AnalyticsCountCards } from 'src/components/Analytics/AnalyticsCountCards/AnalyticsCountCards'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'

import { ClassAssignmentsDialog } from '../../../../../../../components/Dialogs/ClassAssignmentsDialog/ClassAssignmentsDialog'
import { buildRouteParameters } from '../../../../../../../utils/routes-utils'
import { useClassAnalyticsContext } from '../../../../../hooks/useClassAnalytics'

export const ClassAnalyticsCountCards = () => {
	const history = useHistory()
	const {
		classId,
		classAssignmentsCount,
		classWurrliesCount,
		selectedMonth,
		classData,
		completedClassAssignments,
		setSortOrder
	} = useClassAnalyticsContext()
	const [assignmentsDialogOpen, setAssignmentsDialogOpen] = useState(false)
	const totalAssignments = classAssignmentsCount?.total?.aggregate?.count || 0
	const totalSubmitted = classAssignmentsCount?.submitted?.aggregate?.count || 0
	const totalComparisonSubmitted = classAssignmentsCount?.comparisonSubmitted?.aggregate?.count || 0
	const totalWurrlies = classWurrliesCount?.recordingSessions?.aggregate?.count || 0
	const totalComparisonWurlies = classWurrliesCount?.comparisonRecordingSessions?.aggregate?.count || 0
	const goToClassWurrlies = () =>
		history.push(
			buildRouteParameters(Pages.ClassWurrlies, {
				classId
			})
		)

	const closeAssignmentsDialog = () => setAssignmentsDialogOpen(false)
	const openAssignmentsDialog = () => setAssignmentsDialogOpen(true)
	const getSinceLastText = (() => {
		if (selectedMonth === new Date().getMonth() + 1) {
			return 'Since Last Month'
		} else {
			return 'Compared To Previous Month'
		}
	})()

	return (
		<Box marginBottom={2}>
			<AnalyticsCountCards
				previousPeriodText={getSinceLastText}
				assignments={{
					title: 'Completed Assignments',
					submittedCurrentPeriod: totalSubmitted,
					submittedPreviousPeriod: totalComparisonSubmitted,
					totalCurrentPeriod: totalAssignments,
					viewAction: openAssignmentsDialog
				}}
				wurrlies={{
					title: 'Student Recording Sessions',
					totalCurrentPeriod: totalWurrlies,
					totalPreviousPeriod: totalComparisonWurlies,
					viewAction: goToClassWurrlies
				}}
			/>
			<ClassAssignmentsDialog
				open={assignmentsDialogOpen}
				closeDialog={closeAssignmentsDialog}
				className={classData?.title || 'Class'}
				classAssignments={completedClassAssignments as Class_Assignment[]}
				setSort={setSortOrder}
			/>
		</Box>
	)
}
