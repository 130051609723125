import { useEffect, useState } from 'react'

import { useHistory } from 'react-router'
import { DateRange } from 'src/@types'
import { DoubleMusicNoteIcon, LessonsIcon, PersonIcon, VideoIcon } from 'src/assets/icons'
import { HeaderCardProps } from 'src/components/Cards/ClassHeaderCard/ClassHeaderCard'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useGetClassTeacherByPkQuery,
	useGetClassPointsByBadgeEventLazyQuery,
	useGetStudentTotalPointsByClassLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import {
	buildRouteParameters,
	DEFAULT_CLASS_LESSON_IMG,
	DEFAULT_CLASS_SONG_IMG,
	DEFAULT_CLASS_STUDENT_IMG,
	DEFAULT_CLASS_VIDEOS_IMG,
	TypeEnum
} from 'src/utils'

import { StarIcon } from '../../../../../../assets/icons/CustomIcons'

/* type badgeContent = {
	imagePath: string
	fullName: string
	points: number
} */

const useClassDetails = () => {
	const pageElements = 5
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const { classId } = useWurrlyParams<typeof Pages.ClassDetail.params[number]>()
	const [lessonsCount, setLessonsCount] = useState(0)
	const [songsCount, setSongsCount] = useState(0)
	const [videosCount, setVideosCount] = useState(0)
	const [totalPoints, setTotalPoints] = useState(0)
	const [studentsCount, setStudentsCount] = useState(0)
	const [showPointsSection, setShowPointsSection] = useState(false)
	const [dateRange, setDateRange] = useState<DateRange>({ fromDate: new Date(), toDate: new Date() })

	const { data, loading: loadingClass } = useGetClassTeacherByPkQuery({
		variables: {
			teacherId,
			classId
		}
	})

	const [getBadgeData, { data: badgeData }] = useGetClassPointsByBadgeEventLazyQuery()

	const [getBadgeTotlaData, { data: badgeTotalData, loading: loadingBadgeData, refetch }] =
		useGetStudentTotalPointsByClassLazyQuery()

	useEffect(() => {
		if (classId !== 0)
			getBadgeTotlaData({
				variables: { classId, offset: 0, limit: pageElements }
			})
	}, [classId])

	useEffect(() => {
		if (classId !== 0)
			getBadgeData({
				variables: { classId }
			})
	}, [classId])

	useEffect(() => {
		if (badgeData) {
			let totalPoints = 0

			badgeData.student_badge.map((i) => (totalPoints += i.badge.points))
			setTotalPoints(totalPoints)
		}
	}, [badgeData])

	useEffect(() => {
		if (data?.teacher_by_pk?.class_teachers[0]?.class) {
			const { class_songs_aggregate, class_students_aggregate } =
				data?.teacher_by_pk?.class_teachers[0]?.class

			setLessonsCount(data?.total_lessons?.aggregate?.count ?? 0)
			setSongsCount(class_songs_aggregate.aggregate?.count ?? 0)
			setVideosCount(data.playlist_tip_aggregate?.aggregate?.count ?? 0)
			setStudentsCount(class_students_aggregate?.aggregate?.count ?? 0)
		}
	}, [data])

	const headerCards: HeaderCardProps[] = [
		{
			title: 'Students',
			Icon: PersonIcon,
			count: studentsCount,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassStudent, {
						classId
					})
				)
			}
		},
		{
			title: 'Lessons',
			Icon: LessonsIcon,
			count: lessonsCount,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassLessons, {
						classId
					})
				)
			}
		},
		{
			title: 'Songs',
			Icon: DoubleMusicNoteIcon,
			count: songsCount,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassSongs, {
						classId
					})
				)
			}
		},
		{
			title: 'Videos',
			Icon: VideoIcon,
			count: videosCount,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassVideos, {
						classId
					})
				)
			}
		},
		{
			title: 'Class Points',
			Icon: StarIcon,
			count: totalPoints,
			onClick: () => {
				setShowPointsSection(!showPointsSection)
			}
		}
	]

	const bottomCards = [
		{
			image_path: DEFAULT_CLASS_LESSON_IMG,
			type: TypeEnum.Lesson,
			title: 'Lessons',
			smallIcon: <LessonsIcon height={15} width={15} />,
			buttonTitle: 'Find Lessons',
			buttonLabel: Pages.Lessons?.name,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassLesson, {
						classId
					})
				)
			}
		},
		{
			image_path: DEFAULT_CLASS_SONG_IMG,
			type: TypeEnum.Song,
			title: 'Songs',
			smallIcon: <DoubleMusicNoteIcon height={15} width={15} />,
			buttonTitle: 'Discover Music',
			buttonLabel: Pages.MusicCatalog?.name,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassSongs, {
						classId
					})
				)
			}
		},
		{
			image_path: DEFAULT_CLASS_VIDEOS_IMG,
			type: TypeEnum.Video,
			title: 'Videos',
			smallIcon: <VideoIcon height={15} width={15} />,
			buttonTitle: 'Watch Videos',
			buttonLabel: Pages.Videos?.name,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassVideos, {
						classId
					})
				)
			}
		},
		{
			image_path: DEFAULT_CLASS_STUDENT_IMG,
			type: TypeEnum.Student,
			title: 'Students',
			smallIcon: <PersonIcon height={15} width={15} />,
			buttonTitle: 'Your Students',
			buttonLabel: Pages.Students?.name,
			onClick: () => {
				history.push(
					buildRouteParameters(Pages.ClassStudent, {
						classId
					})
				)
			}
		}
	]

	return {
		classId,
		teacherId,
		headerCards,
		loadingClass,
		classData: data?.teacher_by_pk?.class_teachers[0]?.class,
		lessonsCount,
		songsCount,
		videosCount,
		studentsCount,
		bottomCards,
		showPointsSection,
		badgeTotalData,
		loadingBadgeData,
		pageElements,
		refetch,
		dateRange,
		setDateRange,
		totalPoints
	}
}

export const [ClassDetailsProvider, useClassDetailsContext] = buildGenericContext(useClassDetails)
