import { makeStyles } from '@material-ui/core'

export const useStepThreeFormStyles = makeStyles(() => ({
	description: {
		maxWidth: '50%',
		fontSize: 18,
		marginTop: '1em'
	}
}))

export default useStepThreeFormStyles
