import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { ThinCheckIcon } from 'src/assets/icons'

import { InfoDialog } from './InfoDialog'

type OptionalMessages = {
	mainMessages?: {
		title?: string
		body?: string
		buttonLabel?: string
	}
	confirmation?: {
		title?: string
		body?: string
	}
}
type DeleteItemDialogProps = {
	itemName?: string
	isOpen: boolean
	onConfirm: () => Promise<void>
	onDone?: () => void
	setIsOpen: React.Dispatch<React.SetStateAction<number | undefined>>
	optionalMessages?: OptionalMessages
}

export const DeleteItemDialog = ({
	itemName,
	isOpen,
	setIsOpen,
	onConfirm,
	optionalMessages,
	onDone
}: DeleteItemDialogProps) => {
	const theme = useTheme()
	const [isOnSuccessOpen, setIsOnSuccessOpen] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	return (
		<Box>
			<InfoDialog
				open={isOnSuccessOpen}
				onClose={() => {
					setIsOnSuccessOpen(false)
					if (onDone) onDone()
				}}
				icon={<ThinCheckIcon />}
				title={optionalMessages?.confirmation?.title || `${itemName} Deleted!`}
				body={optionalMessages?.confirmation?.body || `You have successfully deleted this ${itemName}.`}
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnSuccessOpen(false)
					if (onDone) onDone()
				}}
				confirmProps={{ style: { fontWeight: 'bold' } }}
			/>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {
					setIsOnErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
				confirmProps={{ style: { fontWeight: 'bold' } }}
			/>
			<InfoDialog
				open={isOpen}
				onClose={() => {
					if (setIsOpen && !loading) setIsOpen(undefined)
				}}
				icon="?"
				title={optionalMessages?.mainMessages?.title || `Delete this ${itemName}?`}
				body={
					optionalMessages?.mainMessages?.body ||
					`Are you sure you want to delete this ${itemName}? This will also remove it from any Classes that you've added it to.`
				}
				discardLabel="Cancel"
				onDiscard={() => {
					if (setIsOpen && !loading) setIsOpen(undefined)
				}}
				discardProps={{
					disabled: loading,
					style: {
						fontWeight: 'bold'
					}
				}}
				confirmLabel={optionalMessages?.mainMessages?.buttonLabel || 'Delete'}
				confirmProps={{
					disabled: loading,
					style: {
						backgroundColor: theme.palette.error.main,
						color: theme.palette.background.paper,
						fontWeight: 'bold'
					},
					startIcon: <DeleteIcon />
				}}
				onConfirm={async () => {
					try {
						setLoading(true)
						await onConfirm()
						setIsOpen(undefined)
						setIsOnSuccessOpen(true)
					} catch (error) {
						setIsOpen(undefined)
						setIsOnErrorOpen(true)
					} finally {
						setLoading(false)
					}
				}}
			/>
		</Box>
	)
}
