import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useGetStudentSongsCatalogLazyQuery } from 'src/graphql/autogenerate/hooks'
import {
	Maturity_Level_Enum,
	Maybe,
	Midi_Bool_Exp,
	Song_Bool_Exp,
	Track_Bool_Exp
} from 'src/graphql/autogenerate/schemas'
import { buildSearchText, MAX_LIST_ITEMS, shouldSortTitleOrDate, SONG_KEYS, SortOrder } from 'src/utils'

export const useGetSongs = (
	studentId: number,
	catalogId: number | null,
	search: string | undefined,
	order: SortOrder = SortOrder.Up,
	chords: string[] = [],
	transpose: boolean,
	scaleId: number,
	vocalGuide: boolean,
	chordGuide: boolean,
	catalogFilters: number[] = [],
	maturityLevel: Maturity_Level_Enum = Maturity_Level_Enum.General
): [typeof querySongs, boolean, boolean, boolean, number, Dispatch<SetStateAction<boolean>>] => {
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [filters, setFilters] = useState(catalogFilters)
	const [songs, setSongs] = useState<typeof querySongs>([])
	const [count, setCount] = useState(0)

	// get queries
	const [loadSongsCatalog, { data, loading, fetchMore }] = useGetStudentSongsCatalogLazyQuery({
		variables: {
			studentId,
			conditions: buildSearchText(search || '', ['title', 'artist.name']),
			offset: 0,
			limit,
			order: shouldSortTitleOrDate(order)[0],
			scaleId: scaleId || null,
			filters: buildSongsSearchFilters(filters),
			chordFilters: getChordFilter(chords, transpose),
			vocalCondition: vocalGuide ? { tracks: { track_type: { name: { _eq: 'Vocal' } } } } : {},
			chordGuideConfidition:
				chords.length > 0 || chordGuide
					? { tracks: { midis: { chords_Array: { _neq: '' }, chords_Array_Zero: { _neq: '' } } } }
					: {},
			excludeMaturityLevel: maturityLevel === Maturity_Level_Enum.General ? [Maturity_Level_Enum.Mature] : []
		}
	})

	const querySongs = data?.song || []

	useEffect(() => {
		if (data && data.song) {
			setSongs(data.song)
			setCount(data?.song_aggregate?.aggregate?.count ?? 0)
		}
	}, [data])

	useEffect(() => {
		loadSongsCatalog()
	}, [catalogId, order, search, loadSongsCatalog])

	useEffect(() => {
		if (!loading) setIsFetching(false)
	}, [loading])

	useEffect(() => {
		const totalSongsCount = data?.song_aggregate?.aggregate?.count ?? 0
		const fetchedSongsCount = songs.length
		setMoreResults(fetchedSongsCount < totalSongsCount)
	}, [data?.song_aggregate?.aggregate?.count, songs])

	useEffect(() => {
		if (!isFetching) return
		if (!loading && fetchMore) {
			const currentLength = songs.length
			fetchMore({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.song.length !== 0) {
					setLimit(currentLength + (fetchMoreResult.data?.song.length || 0))
					setMoreResults(true)
				} else {
					setMoreResults(false)
					setIsFetching(false)
				}
			})
		}
	}, [isFetching])

	useEffect(() => {
		if (catalogId) {
			setFilters([catalogId, ...catalogFilters])
		} else {
			setFilters(catalogFilters)
		}
	}, [catalogId, catalogFilters])

	return [songs, isFetching, moreResults, loading, count, setIsFetching]
}

const buildSongsSearchFilters = (filtersArray: number[]) => {
	const filters = filtersArray.map((catalogItemId) => ({
		song_catalog_item: { catalog_item_id: { _eq: catalogItemId } }
	}))

	return filters
}

const getChordFilter = (chords: string[], transpose: boolean): Song_Bool_Exp[] => {
	let transpositionArray: Song_Bool_Exp[] = []
	if (transpose) {
		// map all transcriptions
		transpositionArray = SONG_KEYS.map((key) => {
			const array: Track_Bool_Exp[] = chords.map((chord) => ({
				midis: {
					_or: [
						{ [`${getColumnName(key)}`]: { _ilike: `${chord},%` } },
						{ [`${getColumnName(key)}`]: { _ilike: `%,${chord},%` } },
						{ [`${getColumnName(key)}`]: { _ilike: `,${chord}%` } },
						{ [`${getColumnName(key)}`]: { _ilike: `${chord}` } }
					]
				} as Maybe<Midi_Bool_Exp>
			}))

			return { tracks: { _and: array } }
		})
	} else {
		const array: Track_Bool_Exp[] = chords.map((chord) => ({
			midis: {
				_or: [
					{ chords_Array_Zero: { _ilike: `${chord},%` } },
					{ chords_Array_Zero: { _ilike: `%,${chord},%` } },
					{ chords_Array_Zero: { _ilike: `,${chord}%` } },
					{ chords_Array_Zero: { _ilike: `${chord}` } }
				]
			}
		}))

		transpositionArray = [
			{
				tracks: { _and: array }
			}
		]
	}

	return transpositionArray
}

const getColumnName = (key: number) =>
	`chords_Array${key.toString().replace(RegExp(/\d/g), '').replace('-', '_Neg')}_${numberToString(
		Math.abs(key)
	)}`

const numberToString = (number: number) => {
	switch (number) {
		case 0:
			return 'Zero'
		case 1:
			return 'One'
		case 2:
			return 'Two'
		case 3:
			return 'Three'
		case 4:
			return 'Four'
		case 5:
			return 'Five'
		case 6:
			return 'Six'
		default:
			return ''
	}
}
