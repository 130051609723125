import { fade, makeStyles, Theme } from '@material-ui/core'
export const useAddDialogStyles = makeStyles((theme: Theme) => ({
	boxInput: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		backgroundColor: fade(theme.palette.text.secondary, 0.1),
		'& .MuiPaper-root :focus': {
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`
		},
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	boxInputFocus: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`,
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	title: { marginTop: 10 },
	tabs: { marginBottom: 20, minWidth: 60 },
	btnSearch: { color: theme.palette.text.secondary },
	bodyBox: {
		background: 'linear-gradient(to bottom, rgb(255,255,255,1), #fff )'
	},
	bodyGrid: {
		overflow: 'auto',
		maxHeight: 190
	},
	boxProgress: {
		margin: 20,
		textAlign: 'center'
	}
}))

export default useAddDialogStyles
