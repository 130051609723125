import React, { useEffect, useState, memo, useMemo } from 'react'

import {
	Box,
	Button,
	Divider,
	Drawer,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Tooltip,
	ListSubheader,
	Typography,
	Chip
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import { Link, Switch, useRouteMatch } from 'react-router-dom'
import { Page } from 'src/@types'
import {
	AssignmentsIcon,
	DoubleMusicNoteIcon,
	HomeIcon,
	LessonsIcon,
	MyFilesIcon,
	PeopleIcon,
	PersonIcon,
	StarIcon,
	VideoIcon,
	WurrlyIcon,
	WurrlyTextIcon
} from 'src/assets/icons/CustomIcons'
import { LogoutButton } from 'src/components'
import { UserAvatar } from 'src/components/UserAvatar'
import { AppVersion } from 'src/components/Version/Version.container'
import { environment, EnvironmentEnum } from 'src/environment'
import { Wurrly_Role_Enum } from 'src/graphql/autogenerate/schemas'
import { useLocalStorage } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { PrivateRoute } from 'src/routes/routes'
import { Pages } from 'src/routes/teacherPages'
import { RouteNotFound } from 'src/scenes/Errors/404.container'
import { BOX_PADDING } from 'src/styles/constants'
import { buildRouteParameters, StorageEnum } from 'src/utils'

import useStyles from './Teacher.styles'

type AdminProps = {
	isDrawerVisible: boolean
	children: React.ReactNode
}

const Template: React.FC<AdminProps> = ({ isDrawerVisible, children }) => {
	const styles = useStyles()
	const { teacherData: userData } = useLoginContext()
	const [drawerExpanded, setDrawerExpanded] = useState(true)
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)
	const history = useHistory()
	const drawerNavigation: (Page & { icon: JSX.Element; disabled?: boolean })[] = [
		{
			...Pages.Home,
			icon: <HomeIcon />
		},
		{
			...Pages.Classes,
			icon: <PeopleIcon />
		},
		{
			...Pages.Students,
			icon: <PersonIcon />
		},
		// {
		// 	...Pages.Schedule,
		// 	icon: <ScheduleIcon />
		// },
		{
			...Pages.Assignments,
			icon: <AssignmentsIcon />
		},
		{
			...Pages.Lessons,
			icon: <LessonsIcon />
		},
		{
			...Pages.MusicCatalog,
			icon: <DoubleMusicNoteIcon />
		},
		{
			...Pages.Videos,
			icon: <VideoIcon />
		},
		{
			...Pages.MyFavorites,
			icon: <StarIcon />
		},
		{
			...Pages.MyFiles,
			icon: <MyFilesIcon />
		}
	]

	const goToProfile = () => {
		history.push(buildRouteParameters(Pages.Profile))
	}

	const handleDrawerExpand = () => {
		if (mobileDrawerOpen) setMobileDrawerOpen(false)
		else setDrawerExpanded(!drawerExpanded)
	}

	const handleDrawerToggle = () => {
		setMobileDrawerOpen(!mobileDrawerOpen)
		if (!drawerExpanded) setDrawerExpanded(true)
	}

	const DrawerContent = () => {
		const CustomLink = (props: {
			name: string
			disabled?: boolean
			icon: JSX.Element
			to: string
			activeOnlyWhenExact?: boolean
			onlyDev?: boolean
		}) => {
			const match = useRouteMatch({
				path: props.to,
				exact: props.activeOnlyWhenExact
			})
			if (props.disabled)
				return (
					<Tooltip title="Coming soon" placement="top" style={{ zIndex: 100 }}>
						<ListItem
							button
							selected={!!match}
							component={Link}
							to={props.to}
							disabled={!!props.disabled}>
							<ListItemIcon>{props.icon}</ListItemIcon>
							<ListItemText primary={props.name} />
						</ListItem>
					</Tooltip>
				)

			const sharedProps = props.disabled ? {} : { component: Link, to: props.to }

			const el = (
				<ListItem button selected={!!match} {...sharedProps}>
					<ListItemIcon>{props.icon}</ListItemIcon>
					<ListItemText primary={props.name} />
					{props.onlyDev && <Chip label="dev" color="secondary" size="small" />}
				</ListItem>
			)

			if (props.disabled)
				return (
					<Tooltip title="Coming Soon" placement="top" style={{ zIndex: 100 }}>
						{el}
					</Tooltip>
				)
			else return el
		}

		const isViewingProfile = !!useRouteMatch({ path: Pages.Profile.path })
		const [localVersion] = useLocalStorage<AppVersion>(StorageEnum.appVersion, {
			version: '',
			created_at: ''
		})

		return (
			<div>
				<List>
					<Box pt={3} pb={3} position="relative">
						<ListItem style={drawerExpanded ? undefined : { paddingLeft: 13, paddingRight: 13 }}>
							<ListItemText primary={drawerExpanded ? <WurrlyTextIcon /> : <WurrlyIcon />} />
						</ListItem>
					</Box>
					<ListItem
						selected={isViewingProfile}
						button
						style={drawerExpanded ? undefined : { paddingLeft: 8, paddingRight: 8 }}
						onClick={goToProfile}>
						<ListItemAvatar>
							<UserAvatar user={userData} />
						</ListItemAvatar>
						<ListItemText
							primary={
								<Box className={styles.profileItemContainer}>
									<Typography className={styles.profileItemLabel}>
										{userData?.name_first} {userData?.name_last}
									</Typography>
									{isViewingProfile && <ArrowRightIcon className={styles.profileItemArrow} />}
								</Box>
							}
						/>
					</ListItem>
				</List>
				<Box mt={1} mb={1}>
					<Divider />
				</Box>
				<List>
					{drawerNavigation.map((page, index) => {
						if (
							page.onlyDev &&
							![EnvironmentEnum.develop, EnvironmentEnum.local].includes(environment.env)
						)
							return null

						return (
							<CustomLink
								key={page.name}
								disabled={!!page.disabled}
								activeOnlyWhenExact={index === 0}
								name={page.name}
								icon={page.icon}
								to={page.path}
								onlyDev={page.onlyDev}
							/>
						)
					})}
				</List>
				<Box mt={1} mb={1}>
					<Divider />
				</Box>
				<List subheader={drawerExpanded ? <ListSubheader>Settings</ListSubheader> : undefined}>
					<LogoutButton />
				</List>
				{!!localVersion.version.length && <ListSubheader>v{localVersion.version}</ListSubheader>}
			</div>
		)
	}

	return (
		<div className={styles.root}>
			{isDrawerVisible && (
				<>
					<Hidden smUp implementation="css">
						<Drawer
							variant="temporary"
							className={styles.drawer}
							classes={{
								paper: styles.drawerPaper
							}}
							anchor="left"
							open={mobileDrawerOpen}
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true // Better open performance on mobile.
							}}>
							<DrawerContent />
						</Drawer>
					</Hidden>

					<Hidden xsDown implementation="css">
						<Drawer
							variant="permanent"
							className={clsx(styles.drawer, {
								[styles.drawerOpen]: drawerExpanded,
								[styles.drawerClose]: !drawerExpanded
							})}
							classes={{
								paper: clsx(styles.drawerPaper, {
									[styles.drawerOpen]: drawerExpanded,
									[styles.drawerClose]: !drawerExpanded
								})
							}}
							anchor="left">
							<DrawerContent />
						</Drawer>
					</Hidden>

					<Hidden xsDown implementation="css">
						<Button
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleDrawerExpand}
							className={styles.chevronIcon}>
							{drawerExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
						</Button>
					</Hidden>
				</>
			)}

			<Box
				width="100%"
				position="relative"
				className={clsx({
					[styles.widthExpanded]: drawerExpanded,
					[styles.widthCollapsed]: !drawerExpanded
				})}>
				{isDrawerVisible && (
					<Hidden smUp implementation="css">
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerToggle}
							className={styles.menuButton}>
							<MenuIcon />
						</IconButton>
					</Hidden>
				)}

				{children}
			</Box>
		</div>
	)
}

/**
 * @remarks there is still one "issue" where if you change the route the Private Route will unmount for some reason
 */
export const TeacherRoutes: React.FC = memo(() => {
	const { teacherData: userData } = useLoginContext()
	const [isDrawerVisible, setIsDrawerVisible] = useState(true)

	// component key should be consistent and not change on every render
	return (
		<Switch>
			{Object.entries(Pages).map(([key, page]) => {
				const Component = page.component
				const isViewingPage = !!useRouteMatch({ path: page.path, exact: true })

				useEffect(() => {
					if (isViewingPage)
						setIsDrawerVisible((prev) => (!page.noDrawer !== prev ? !page.noDrawer : prev))
				}, [isViewingPage])
				// we wrap the component in a useMemo to prevent it from re-rendering on multiple situations to care about performance
				const PageComponent = useMemo(
					() =>
						function () {
							if (page.noTemplate) {
								// noTemplate for pages without header and sidebar
								return <Component teacherId={userData?.teacher_id} page={page} />
							} else if (page.noPadding) {
								// noPadding for pages with a background image
								return (
									<Template isDrawerVisible={isDrawerVisible}>
										<Component teacherId={userData?.teacher_id} page={page} />
									</Template>
								)
							} else {
								return (
									<Template isDrawerVisible={isDrawerVisible}>
										<Box p={BOX_PADDING}>
											<Component teacherId={userData?.teacher_id} page={page} />
										</Box>
									</Template>
								)
							}
						},
					[page, userData, isDrawerVisible]
				)

				return (
					<PrivateRoute
						key={key}
						exact
						role={Wurrly_Role_Enum.Teacher}
						path={page.path}
						component={PageComponent}
					/>
				)
			})}
			<RouteNotFound />
		</Switch>
	)
})
