import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useRecoverPasswordMutation } from 'src/graphql/autogenerate/hooks'

import { BaseDialog, InfoDialog } from '../../../components'
import { useLoginStyles } from '../styles'
import { PasswordInput } from './PasswordInput'

const paramToken = new URL(window.location.href).searchParams.get('token') || ''

export const RecoverPassswordDialog = () => {
	const [dialogOpen] = useState<boolean>(true)
	const [createPassword, setCreatePassword] = useState<string>('')
	const [confirmPassword, setConfirmPassword] = useState<string>('')
	const [passwordFulfillRequirements, setPasswordFulfillRequirements] = useState<boolean>(false)
	const [infoDialogOpen, setInfoDialogOpen] = useState(false)
	const classes = useLoginStyles({})
	const [changePasswordMutation, { data, loading, error }] = useRecoverPasswordMutation()
	const history = useHistory()
	const confirmButtonEnabled =
		createPassword && confirmPassword && createPassword === confirmPassword && passwordFulfillRequirements
	useEffect(() => {
		if (data || error) setInfoDialogOpen(true)
	}, [data, error])

	return (
		<>
			<BaseDialog
				backdropStyles={{ opacity: '0!important' }}
				dialogPaperStyles={{ minWidth: 500 }}
				header={
					<>
						<Typography style={{ fontWeight: 'bold' }} variant="h4" align="center">
							Change Password
						</Typography>
						<Box>
							<Typography className={classes.label}>
								<b>New Password</b>
							</Typography>
							<PasswordInput
								password={createPassword}
								setPassword={setCreatePassword}
								setFulfillRequirements={setPasswordFulfillRequirements}
								style={
									createPassword.length > 0 && !passwordFulfillRequirements
										? { border: '1px solid red' }
										: {}
								}
							/>
							{createPassword.length > 0 && !passwordFulfillRequirements && (
								<Typography style={{ color: 'red' }}>
									Password don't fulfill requirements
								</Typography>
							)}
							<Typography className={classes.label}>
								<b>Confirm New Password</b>
							</Typography>
							<PasswordInput
								password={confirmPassword}
								setPassword={setConfirmPassword}
								disableValidation={true}
								style={
									createPassword && confirmPassword && createPassword !== confirmPassword
										? { border: '1px solid red' }
										: {}
								}
							/>
							{createPassword.length > 0 && createPassword !== confirmPassword && (
								<Typography style={{ color: 'red' }}>Passwords don't match</Typography>
							)}
						</Box>
					</>
				}
				discardLabel="Cancel"
				confirmLabel="Save"
				isConfirmLoading={loading}
				isConfirmDisabled={!confirmButtonEnabled}
				onConfirm={() => {
					changePasswordMutation({
						variables: { token: paramToken, newPassword: createPassword }
					}).catch((e) => console.error(e))
				}}
				open={dialogOpen}
				onClose={() => {}}
				onDiscard={() => {
					history.push('/')
				}}
				dividers={false}
				typographyProps={{
					style: {
						fontWeight: 'bold'
					}
				}}
				onConfirmProps={{
					style: {
						width: '40%'
					}
				}}
				onDiscardProps={{
					style: {
						width: '40%'
					}
				}}
				body={<></>}
			/>
			<InfoDialog
				open={infoDialogOpen}
				onClose={() => {
					if (error) {
						setInfoDialogOpen(false)
					}
				}}
				icon={error ? 'x' : '✔'}
				title={error ? 'Something went wrong' : 'Password changed!'}
				body={
					error
						? error.message === 'New password must be different from the current one'
							? error.message
							: 'An error ocurred while changing the password'
						: 'The password has been succesfully changed'
				}
				confirmLabel={error ? 'Close' : 'Login'}
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					if (error) {
						setInfoDialogOpen(false)
					} else {
						history.push('/')
					}
				}}
			/>
		</>
	)
}
