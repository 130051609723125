import { useState } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'

import { StandardIcon } from '../../../../../../../assets/icons'
import {
	ActionButton,
	BaseLoading,
	DeleteItemDialog,
	ListLessons,
	MainTitle
} from '../../../../../../../components'
import {
	useDeleteClassModuleMutation,
	useGetClassTeacherByPkQuery,
	useGetModuleByPkQuery
} from '../../../../../../../graphql/autogenerate/hooks'
import { Module } from '../../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../../hooks/useLogin'
import { useScroll } from '../../../../../../../hooks/useScroll'
import { useWurrlyParams } from '../../../../../../../hooks/useWurrlyParams'
import { Pages } from '../../../../../../../routes/teacherPages'
import { COLORS } from '../../../../../../../styles/colors'
import { buildBreadCrumbs, buildRouteParameters } from '../../../../../../../utils'
import { getModuleCatalogItems } from '../../../../../../../utils/utils'

export const ClassModuleDetail = ({ page }: PagesProps) => {
	useScroll()
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()

	const params = useWurrlyParams<typeof Pages.ClassModuleDetail.params[number]>()
	const teacherId = teacher.teacher_id
	const { classId, moduleId } = params
	const { data: moduleData, loading } = useGetModuleByPkQuery({
		variables: {
			moduleId,
			teacherId,
			classId
		}
	})
	const module = moduleData?.module[0] as Module | undefined

	const { data: classData } = useGetClassTeacherByPkQuery({
		variables: {
			teacherId,
			classId
		}
	})

	const [moduleIdToDelete, setModuleIdToDelete] = useState<number>()
	const [deleteClassModule] = useDeleteClassModuleMutation()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: classData?.teacher_by_pk?.class_teachers[0]?.class?.title
			},
			{
				index: 2,
				overrideName: 'Lessons'
			},
			{
				index: 3,
				overrideName: 'Modules'
			},
			{
				index: 4,
				overrideName: module?.title
			}
		],
		params
	})

	return (
		<Box>
			{loading && <BaseLoading />}
			{!loading && (
				<Box>
					<MainTitle
						breadcrumbs={breadcrumbs}
						title={module?.title ?? page.name}
						description={module?.description ?? ''}
						list={getModuleCatalogItems(module)}
						actionButtons={[
							<ActionButton
								key={0}
								disabled
								onClick={() => {}}
								size="large"
								icon={<StandardIcon />}
							/>
						]}
						rightActions={[
							{
								style: {
									backgroundColor: COLORS.error.main,
									color: 'white',
									height: 56,
									padding: '0px 30px'
								},
								variant: 'contained',
								startIcon: <DeleteIcon />,
								name: 'Delete Module',
								onClick: () => {
									setModuleIdToDelete(moduleId)
								}
							}
						]}
					/>

					<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>
								There are{' '}
								{module?.module_lesson_plan?.filter((item) => item.lesson_plan).length || 0}{' '}
								Lessons in this Module
							</b>
						</Typography>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs>
							<ListLessons
								teacherId={teacherId}
								data={module?.module_lesson_plan?.map((item) => item.lesson_plan) ?? []}
								spacing={1.5}
								addAssignmentButton
								classId={classId}
							/>
						</Grid>
					</Grid>
				</Box>
			)}
			<DeleteItemDialog
				itemName="Module"
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Module from your Class?',
						body: 'You’ll be able to re-add it from the Lessons page at any time.',
						buttonLabel: ' Delete Module'
					},
					confirmation: {
						title: 'Module Deleted!',
						body: 'You have successfully deleted this Module from your Class.'
					}
				}}
				isOpen={!!moduleIdToDelete}
				setIsOpen={setModuleIdToDelete}
				onConfirm={async () => {
					if (moduleIdToDelete) {
						await deleteClassModule({
							variables: {
								classId,
								moduleId: moduleIdToDelete
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_class_module?.returning[0].class
								if (!classToUpdateInCache) return

								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'class_modules'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'module_by_pk'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'module'
								})
							}
						})
					}
				}}
				onDone={() => {
					history.push(
						buildRouteParameters(
							Pages.ClassLesson, // Route with paramters
							{ classId }
						)
					)
				}}
			/>
		</Box>
	)
}
