import React, { useState } from 'react'

import { makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { WurrliesGrid } from 'src/components/Grids/WurrliesGrid'
import { Order_By, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { buildRouteParameters } from 'src/utils'

import { useStudentClassesWurrlies, useStudentWurrlies } from '../hooks/useStudentWurrlies'

export const StudentWurrlies = () => {
	const history = useHistory()
	const [currentTab, setCurrentTab] = useState(0)
	const [sort] = useState(Order_By.Asc)
	const [filter, setFilter] = useState<Wurrly_Type_Enum>(Wurrly_Type_Enum.Indie)
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()

	const studentWurrlies = useStudentWurrlies({ studentId, order: sort, filter })
	const classWurrlies = useStudentClassesWurrlies({ studentId, order: sort })

	const handleFilter = (
		event: React.ChangeEvent<{
			name?: string
			value: unknown
		}>
	) => {
		const selectedFilter = event.target.value as Wurrly_Type_Enum
		setFilter(selectedFilter)
	}

	const SharedTabSwitcher = () => (
		<TabSwitcher
			currentTab={currentTab}
			setCurrentTab={setCurrentTab}
			myWurrlies={studentWurrlies.count > 0}
			classWurrlies={classWurrlies.count > 0}
		/>
	)

	const handleWurrly = (wurrlyId: number, classId: number) => {
		history.push(
			buildRouteParameters(StudentPages.ClassWurrly, {
				classId,
				wurrlyId
			})
		)
	}

	if (studentWurrlies.count === 0 && classWurrlies.count === 0) return null

	return (
		<>
			{currentTab === 0 ? (
				<WurrliesGrid
					count={studentWurrlies.count}
					wurrlies={studentWurrlies.wurrlies}
					loading={studentWurrlies.loading}
					moreResults={studentWurrlies.wurrlies.length < studentWurrlies.count}
					title={<SharedTabSwitcher />}
					filter={{ filter, handleFilter }}
					onWurrlyClick={handleWurrly}
				/>
			) : (
				<WurrliesGrid
					count={classWurrlies.count}
					wurrlies={classWurrlies.wurrlies}
					loading={classWurrlies.loading}
					moreResults={classWurrlies.wurrlies.length < classWurrlies.count}
					title={<SharedTabSwitcher />}
					onWurrlyClick={handleWurrly}
				/>
			)}
		</>
	)
}

const useStyles = makeStyles({
	tabs: {
		marginBottom: '20px',
		minWidth: '60px',
		marginTop: '3em',

		'& .MuiTabs-indicator': {
			backgroundColor: '#1D273D',
			height: '3px'
		}
	}
})

type TabSwitcherProps = {
	currentTab: number
	setCurrentTab: React.Dispatch<React.SetStateAction<number>>
	myWurrlies: boolean
	classWurrlies: boolean
}

const TabSwitcher = ({ currentTab, setCurrentTab, myWurrlies, classWurrlies }: TabSwitcherProps) => {
	const classes = useStyles()

	const handleChangeTab = (_: React.ChangeEvent<unknown>, newValue: number) => {
		setCurrentTab(newValue)
	}

	return (
		<Tabs className={classes.tabs} value={currentTab} onChange={handleChangeTab}>
			{myWurrlies && (
				<Tab
					label={
						<Typography variant="caption" color={currentTab === 0 ? 'primary' : undefined}>
							<b>My wurrlies</b>
						</Typography>
					}
				/>
			)}
			{classWurrlies && (
				<Tab
					label={
						<Typography variant="caption" color={currentTab === 1 ? 'primary' : undefined}>
							<b>Class Wurrlies</b>
						</Typography>
					}
				/>
			)}
		</Tabs>
	)
}
