import { createStyles, makeStyles } from '@material-ui/core'

export const useAllSongsCardStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		},
		avatar: {
			background: 'rgba(236, 236, 236, 1)',
			color: 'rgba(133, 137, 148, 1)',
			height: '2.5em',
			width: '2.5em',
			marginRight: '.5em'
		},
		text: {
			textOverflow: 'wrap',
			fontWeight: 'bold',
			lineHeight: 1.2
		}
	})
)

export default useAllSongsCardStyles
