import { makeStyles } from '@material-ui/core'

export const useTimeInputStyles = makeStyles((theme) => ({
	timeField: {
		width: '100%'
	},
	selector: {
		'& .MuiPickersToolbar-toolbar, & .MuiPickersClockNumber-clockNumberSelected, & .MuiPickersClock-pin, & .MuiPickersClockPointer-pointer':
			{
				backgroundColor: theme.palette.secondary.main,
				color: 'white'
			},
		'& .MuiPickersClockPointer-thumb': {
			borderColor: theme.palette.secondary.main,
			backgroundColor: 'white'
		},
		'& .MuiPickersTimePickerToolbar-ampmLabel': {
			opacity: 0.7
		},
		'& .MuiPickersToolbarText-toolbarBtnSelected': {
			color: 'white',
			opacity: 1
		},
		'& .MuiPickersToolbarText-toolbarTxt': {
			color: 'white'
		}
	}
}))

export default useTimeInputStyles
