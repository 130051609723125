import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentCardListStyles = makeStyles(() =>
	createStyles({
		assignmentCard: {
			'& ListItem': { minHeight: 0 },
			'& h6': { fontSize: 16, minHeight: 0, padding: '8px 0px' },
			border: 'none',
			borderRadius: 0,
			borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
			boxShadow: 'none',
			minHeight: '77px',
			padding: '8px 16px'
		},
		loader: {
			margin: 20
		},
		listStyle: {
			height: 500,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between'
		},
		skeletonStyle: {
			animation: 'wave',
			variant: 'circle',
			height: 48,
			width: 48
		},
		secondSkeletonStyle: {
			animation: 'wave',
			height: 10,
			width: '80%',
			marginBottom: 6
		}
	})
)

export default useAssignmentCardListStyles
