import { useMemo, useState } from 'react'

import { Box } from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router'
import { DateRange } from 'src/@types'
import { AnalyticsCountCards } from 'src/components/Analytics/AnalyticsCountCards/AnalyticsCountCards'
import { ClassAssignmentsDialog } from 'src/components/Dialogs/ClassAssignmentsDialog/ClassAssignmentsDialog'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { useCountCards } from './useCountCards'

type CountCardsProps = {
	classId: number
	dateRange: DateRange
}
export const CountCards = ({ classId, dateRange }: CountCardsProps) => {
	const history = useHistory()
	const {
		totalAssignments,
		totalSubmitted,
		totalComparisonSubmitted,
		totalWurrlies,
		totalComparisonWurlies,
		completedClassAssignments,
		classData,
		setSortOrder
	} = useCountCards({ classId, dateRange })
	const [assignmentsDialogOpen, setAssignmentsDialogOpen] = useState(false)
	const closeAssignmentsDialog = () => setAssignmentsDialogOpen(false)
	const openAssignmentsDialog = () => setAssignmentsDialogOpen(true)

	const timePeriod = useMemo(() => {
		const { fromDate, toDate } = dateRange
		// Get relative time and remove "a " from "a month" and "a year"
		let relativeTime = moment(toDate).from(moment(fromDate), true).replace(/^a /, '')
		let prefix = 'Compared to previous'
		if (relativeTime === '7 days' && moment(new Date()).diff(fromDate, 'days') <= 7) {
			prefix = 'Since last'
			relativeTime = 'week'
		}

		return `${prefix} ${relativeTime}`
	}, [dateRange])

	const goToClassWurrlies = () =>
		history.push(
			buildRouteParameters(Pages.ClassWurrlies, {
				classId
			})
		)

	return (
		<Box mt={3}>
			<AnalyticsCountCards
				previousPeriodText={timePeriod}
				assignments={{
					title: 'Completed Assignments',
					submittedCurrentPeriod: totalSubmitted,
					submittedPreviousPeriod: totalComparisonSubmitted,
					totalCurrentPeriod: totalAssignments,
					viewAction: openAssignmentsDialog
				}}
				wurrlies={{
					title: 'Student Wurrlies',
					totalCurrentPeriod: totalWurrlies,
					totalPreviousPeriod: totalComparisonWurlies,
					viewAction: goToClassWurrlies
				}}
			/>
			<ClassAssignmentsDialog
				open={assignmentsDialogOpen}
				closeDialog={closeAssignmentsDialog}
				className={classData?.title || 'Class'}
				classAssignments={completedClassAssignments as Class_Assignment[]}
				setSort={setSortOrder}
			/>
		</Box>
	)
}
