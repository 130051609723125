import { NotesIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { SignUpMainDialog } from 'src/scenes/Login/components/SignUpMainDialog'

export const LoginPage = () => {
	// this is executed when the user clicks the signin button choosing any of the possible integrations: Clever, Google, Microsoft

	return (
		<FixedBackground>
			<img
				src={NotesIcon}
				style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
			/>
			<SignUpMainDialog signUpDialogOpen={true} isLogin={true} />
		</FixedBackground>
	)
}
