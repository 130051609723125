import { useEffect, useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import { useGetClassDetailsQuery, useGetClassAssignmentsCountQuery } from 'src/graphql/autogenerate/hooks'
import { Class } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { StudentPages } from 'src/routes/studentpages'

const useClassDetails = () => {
	const { classId } = useWurrlyParams<typeof StudentPages.ClassDetail.params[number]>()
	const { studentData } = useLoginContext()
	const [classData, setClassData] = useState<Class | undefined>()
	const [songsCount, setSongsCount] = useState(0)
	const [videosCount, setVideosCount] = useState(0)
	const [studentsCount, setStudentsCount] = useState(0)
	const [lessonsCount, setLessonsCount] = useState(0)
	const [assignmentsCount, setAssignmentsCount] = useState(0)
	const [loadingClass, setLoadingClass] = useState(true)

	const { data, loading } = useGetClassDetailsQuery({ variables: { classId } })
	const { data: assignmentsData } = useGetClassAssignmentsCountQuery({
		variables: { classId, studentId: studentData.student_id }
	})

	useEffect(() => {
		if (data) {
			setClassData(data.class_by_pk as Class)
			setSongsCount(data.class_by_pk?.class_songs_aggregate?.aggregate?.count ?? 0)
			setVideosCount(data.playlist_tip_aggregate.aggregate?.count ?? 0)
			setStudentsCount(data.class_by_pk?.class_students_aggregate?.aggregate?.count ?? 0)
			setLessonsCount(data.class_by_pk?.class_lesson_plans_aggregate?.aggregate?.count ?? 0)
			setLoadingClass(false)
		}
	}, [data])

	useEffect(() => {
		if (assignmentsData) {
			setAssignmentsCount(assignmentsData.submission_aggregate.aggregate?.count ?? 0)
		}
	}, [assignmentsData])

	return {
		assignmentsCount,
		classData,
		classId,
		loading,
		lessonsCount,
		songsCount,
		studentsCount,
		videosCount,
		loadingClass
	}
}

export const [ClassDetailsProvider, useClassDetailsContext] = buildGenericContext(useClassDetails)
