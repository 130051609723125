import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useLessonCardListStyles = makeStyles((theme: Theme) =>
	createStyles({
		lessonCard: {
			margin: '15px 0'
		},
		seeMore: { cursor: 'pointer' },
		loader: {
			margin: 20
		},
		addFromFavorite: {
			fontSize: 14,
			cursor: 'pointer',
			color: theme.palette.secondary.main,
			textDecoration: 'none'
		}
	})
)

export default useLessonCardListStyles
