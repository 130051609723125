import { useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { Box, CircularProgress, Link, TextField, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import { Link as LinkRD, useHistory } from 'react-router-dom'
import { cache } from 'src/apollo/state'
import { AddToClassDialog, BigBaseButton, InfoDialog } from 'src/components'
import InputFile from 'src/components/Inputs/InputFile'
import { SelectThumbnail } from 'src/components/Inputs/SelectThumbnail.tsx/SelectThumbnail'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { useAddVideoMutation, useInsertTipsToClassMutation } from 'src/graphql/autogenerate/hooks'
import {
	Catalog_Item,
	Class,
	Class_Tip_Insert_Input,
	FileEnum,
	InstancesEnum,
	Keyword,
	Tip_Insert_Input,
	Visibility_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { purgeTemporal } from 'src/scenes/Teacher/mutations'
import {
	QueryCatalogItemsByCatalogId,
	QueryCatalogsByCatalogId,
	QueryKeyword,
	QueryKeywordType
} from 'src/scenes/Teacher/queries'
import { buildQueryVariables, buildRouteParameters, buildVideoPath, TypeEnum } from 'src/utils'
import * as yup from 'yup'

import { useStyles } from './styles'

const limit = {
	title: 150,
	description: 200,
	keywords: 5
}
const validationSchema = yup.object({
	title: yup.string().required('Title is required').max(limit.title, 'Title too long'),
	description: yup.string().required('Description is required').max(limit.description, 'Descriptions too long'),
	keywords: yup.array().required().max(limit.keywords, `Up to ${limit.keywords}`),
	instruments: yup.array()
})
type UploadVideoFormType = {
	url: string
	title?: string
	description?: string
	thumbnail?: string
}

export const UploadVideoForm = ({ url, title, description, thumbnail }: UploadVideoFormType) => {
	const classes = useStyles()
	const { teacherData: teacher } = useLoginContext()
	const [onCancel, setOnCancel] = useState(false)
	const [onSave, setOnSave] = useState(false)
	const [savedVideoId, setSavedVideoId] = useState<number | undefined>()
	const [classesAdded, setClassesAdded] = useState<Class[]>([])
	const [isAddToClassDialogOpen, setIsAddToClassDialogOpen] = useState<number | undefined>(0)
	const [addVideo, { loading: isVideoUploading }] = useAddVideoMutation()
	const [purgeTemp] = useMutation(purgeTemporal)
	const [insertTips, { loading: isAddToClassLoading }] = useInsertTipsToClassMutation()
	const history = useHistory()
	const formik = useFormik({
		initialValues: {
			title: title?.substring(0, 150) ?? '',
			description: description?.substring(0, 199) ?? '',
			keywords: [] as Keyword[],
			subjects: [] as Catalog_Item[],
			ages: [] as Catalog_Item[],
			instruments: [] as Catalog_Item[],
			asset_path: '',
			image_path: '',
			thumbnail_list: []
		},

		validationSchema,
		validateOnMount: true,
		onSubmit: (values, { resetForm }) => {
			const tip_keywords = {
				data: values.keywords.map((keyword) => ({ keyword_id: keyword.keyword_id }))
			}
			const tip_catalog_item = {
				data: [
					...values.instruments.map((catalogItem) => ({ catalog_item_id: catalogItem.catalog_item_id })),
					...values.subjects.map((catalogItem) => ({ catalog_item_id: catalogItem.catalog_item_id })),
					...values.ages.map((catalogItem) => ({ catalog_item_id: catalogItem.catalog_item_id }))
				]
			}
			const dataTip: Tip_Insert_Input = {
				title: values.title,
				teacher_id: teacher.teacher_id,
				description: values.description,
				tip_keywords,
				resource_path: url,
				tip_catalog_item,
				image_path: thumbnail || values.image_path,
				asset_path: values.asset_path
			}

			addVideo({
				variables: {
					data: {
						...dataTip,
						lockable_content: { data: { visibility: Visibility_Enum.Private, table: 'tip' } }
					}
				},
				onError: (err) => alert(`Add video failed - ${JSON.stringify(err, null, 2)}`),
				onCompleted: (res) => {
					const rowId = res?.insert_tip_one?.tip_id
					setSavedVideoId(rowId ?? undefined)
					purgeTemp(buildQueryVariables({ filter: { instanceType: InstancesEnum.Tip, rowId } }))
					setOnSave(true)
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'tip'
					})
					setOnSave(true)
					resetForm()
				}
			})
		}
	})

	const CATALOG_SUBJECTS_ID = 2
	const CATALOG_AGES_ID = 4
	const CATALOG_INSTRUMENTS_ID = 5

	const TIP_SUBJECTS_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_SUBJECTS_ID)
	)
	const TIP_AGES_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_AGES_ID)
	)
	const TIP_INSTRUMENTS_CATALOG_ITEMS = useQuery<QueryCatalogItemsByCatalogId>(
		QueryCatalogsByCatalogId(TypeEnum.Video, CATALOG_INSTRUMENTS_ID)
	)
	const KEYWORDS = useQuery<QueryKeywordType>(QueryKeyword(TypeEnum.Video))

	const getClassName = (value: string, limit: number) => {
		if (value === '') return 'Default'
		if (value.length <= limit) return 'Success'
		else return 'Error'
	}
	const getClassNameWrapper = (val: string, limit: number) => {
		return classes[`text${getClassName(val, limit)}` as keyof typeof classes]
	}

	const handleAddToClass = async (selectedClasses: Class[]) => {
		if (!selectedClasses.length) return

		const classesToAdd = selectedClasses.map((item) => ({
			tip_id: savedVideoId,
			class_id: item.class_id
		})) as Class_Tip_Insert_Input[]

		try {
			await insertTips({
				variables: { tips: classesToAdd },
				update: (cache, { data }) => {
					const classesToUpdateInCache = data?.insert_class_tip?.returning
					if (!classesToUpdateInCache) return

					for (const item of classesToUpdateInCache) {
						const identify = cache.identify(item.class)
						cache.evict({
							id: identify,
							fieldName: 'class_tips'
						})
						cache.evict({
							id: identify,
							fieldName: 'class_tips_aggregate'
						})
					}
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'tip'
					})
				}
			})
			setClassesAdded([...selectedClasses])
		} catch (error) {
			console.error('Could not add video to class', { error })
		}
	}

	return (
		<Box marginTop="80px">
			<ReactCustomVideoPlayer
				data-cy="video-player"
				width="100%"
				height={500}
				style={{
					maxWidth: '830px',
					margin: 'auto'
				}}
				config={{
					youtube: {
						playerVars: { showinfo: 0 }
					},
					file: {
						attributes: {
							disablepictureinpicture: 'true',
							controlsList: 'nodownload'
						}
					}
				}}
				url={buildVideoPath(url)}
			/>
			<form onSubmit={formik.handleSubmit}>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Title*</b>
						</Typography>
						<Typography
							className={
								title ? classes.textDefault : getClassNameWrapper(formik.values.title, limit.title)
							}
							color="textSecondary">
							{title ? 'Created By Link' : `${formik.values.title.length}/${limit.title}`}
						</Typography>
					</Box>
					<TextField
						className={
							title ? classes.inputDefault : getClassNameWrapper(formik.values.title, limit.title)
						}
						id="title"
						name="title"
						fullWidth
						value={formik.values.title}
						onChange={formik.handleChange}
						error={formik.touched.title && Boolean(formik.errors.title)}
						helperText={formik.touched.title && formik.errors.title}
						data-cy="upload-video-title"
						color="secondary"
						variant="outlined"
						placeholder="Enter Title"
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Description*</b>
						</Typography>
						<Typography
							className={
								description
									? classes.textDefault
									: getClassNameWrapper(formik.values.description, limit.description)
							}
							color="textSecondary">
							{description
								? 'Created By Link'
								: `${formik.values.description.length}/${limit.description}`}
						</Typography>
					</Box>
					<TextField
						className={
							description
								? classes.inputDefault
								: getClassNameWrapper(formik.values.description, limit.description)
						}
						id="description"
						name="description"
						fullWidth
						error={formik.touched.description && Boolean(formik.errors.description)}
						helperText={formik.touched.description && formik.errors.description}
						value={formik.values.description}
						onChange={formik.handleChange}
						data-cy="upload-video-description"
						color="secondary"
						variant="outlined"
						placeholder="Enter description"
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Keywords*</b>
						</Typography>
						<Typography className={classes.textDefault} color="textSecondary">
							Up to {limit.keywords}
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-keyword',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-keyword-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						noOptionsText={<Typography>No Matching Keywords - Please Try Again</Typography>}
						limitTags={limit.keywords}
						options={KEYWORDS.data?.keyword || []}
						value={formik.values.keywords}
						onChange={(_, value) => {
							formik.setFieldValue('keywords', value)
						}}
						closeIcon={<CloseIcon data-cy="keywords-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-keywords"
								variant="outlined"
								color="secondary"
								placeholder="Enter keywords"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Subjects</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-subject',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-subjects-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={TIP_SUBJECTS_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.subjects}
						onChange={(_, value) => {
							formik.setFieldValue('subjects', value)
						}}
						closeIcon={<CloseIcon data-cy="subjects-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-subjects"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Instruments</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-instruments',
							className: classes.chip,
							deleteIcon: (
								<Typography
									data-cy="remove-instruments-selected"
									className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={TIP_INSTRUMENTS_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.instruments}
						onChange={(_, value) => {
							formik.setFieldValue('instruments', value)
						}}
						closeIcon={<CloseIcon data-cy="instruments-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-instruments"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Ages</b>
						</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							dataCy: 'selected-ages',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-ages-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox
						}}
						multiple
						options={TIP_AGES_CATALOG_ITEMS.data?.catalog_item || []}
						value={formik.values.ages}
						onChange={(_, value) => {
							formik.setFieldValue('ages', value)
						}}
						closeIcon={<CloseIcon data-cy="ages-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-video-ages"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography>
							<b>Learning Resource</b>
						</Typography>
					</Box>
					<InputFile.Container
						css={{
							backgroundColor: 'rgba(255, 194, 12, 0.1)'
						}}
						id="video_asset"
						advise="Max File size: 200MB."
						placeholder="Pick a Learning Resource for this video"
						type={FileEnum.Assets}
						handleFileUrl={(e) => {
							formik.setFieldValue('asset_path', e)
						}}
						onDelete={() => {
							formik.setFieldValue('asset_path', '')
						}}
						isTemporal={false}
					/>
				</Box>
				{!thumbnail && (
					<SelectThumbnail
						multiImage={false}
						handleSelectThumbnail={(e) => {
							formik.setFieldValue('image_path', e)
						}}
						handleSetThumbnailList={(e) => {
							formik.setFieldValue('thumbnail_list', e)
						}}
						thumbnailList={formik.values.thumbnail_list}
						thumbnailValue={formik.values.image_path}
						isRequired={false}
					/>
				)}

				<Box
					className={classes.buttonDisabled}
					marginTop="35px"
					display="flex"
					justifyContent="space-between">
					<BigBaseButton
						style={{ padding: '6px 40px' }}
						variant="contained"
						color="default"
						onClick={() => {
							setOnCancel(true)
						}}>
						<Typography variant="caption">Cancel</Typography>
					</BigBaseButton>

					<BigBaseButton
						style={{ padding: '6px 50px' }}
						disabled={!formik.isValid || isVideoUploading}
						color="secondary"
						type="submit">
						<Typography variant="caption">Save</Typography>
						{isVideoUploading && <CircularProgress size={30} className={classes.spinner} />}
					</BigBaseButton>
				</Box>
			</form>

			<InfoDialog
				open={onSave}
				title="Video Saved!"
				body='Your Video was uploaded successfully! It will appear under "My Files"'
				discardLabel="Upload Another Video"
				onDiscard={() => {
					window.location.reload()
					setOnSave(false)
				}}
				confirmLabel="Add to Class"
				confirmProps={{ color: 'primary', endIcon: <AddIcon /> }}
				onConfirm={() => setIsAddToClassDialogOpen(1)}
				icon={<CheckIcon />}
				footer={
					<Link component={LinkRD} to={Pages.MyFiles.path}>
						<Typography color="secondary">View My File</Typography>
					</Link>
				}
			/>
			<InfoDialog
				open={onCancel}
				onClose={() => setOnCancel(false)}
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={() => {
					setOnCancel(false)
					history.push(buildRouteParameters(Pages.Videos))
				}}
				confirmLabel="No, Cancel"
				onConfirm={() => {
					setOnCancel(false)
				}}
				icon="!"
			/>

			<AddToClassDialog
				isOpen={!!isAddToClassDialogOpen}
				setIsOpen={setIsAddToClassDialogOpen}
				itemClasses={classesAdded}
				title="Save video to Cass"
				description="This will save the Video so that you can view and teach right from your Class page.
				This content will be visible to the students that are in the class."
				onConfirm={handleAddToClass}
				isLoading={isAddToClassLoading}
			/>
		</Box>
	)
}
