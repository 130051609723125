import { useEffect, useState } from 'react'

import {
	useGetClassNameLazyQuery,
	useGetPlaylistByClassIdLazyQuery,
	useGetTipByClassIdLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Playlist, Tip } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, buildSearchText } from 'src/utils'

export const useClassVideosTitle = () => {
	const { classId } = useWurrlyParams()
	const [className, setClassName] = useState('')
	const [selectedVideos, setSelectedVideos] = useState<Tip[]>([])
	const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist[]>([])
	const [showVideosDialog, setShowVideosDialog] = useState(false)
	const [showPlaylistDialog, setShowPlaylistDialog] = useState(false)
	const [selectProductDialog, setSelectProductDialog] = useState(false)
	const [getSelectedVideos, { data: selectedVideosData }] = useGetTipByClassIdLazyQuery()
	const [getSelectedPlaylists, { data: selectedPlaylistData }] = useGetPlaylistByClassIdLazyQuery()
	const [getClass, { data }] = useGetClassNameLazyQuery()

	const handleAddVideos = () => {
		setShowVideosDialog(true)
		handleCancelSelectProductDialog()
	}

	const handleCancelVideos = () => {
		setShowVideosDialog(false)
	}

	const handleAddPlaylist = () => {
		setShowPlaylistDialog(true)
		handleCancelSelectProductDialog()
	}

	const handleCancelPlaylist = () => {
		setShowPlaylistDialog(false)
	}

	const handleOpenSelectProductDialog = () => {
		setSelectProductDialog(true)
	}

	const handleCancelSelectProductDialog = () => {
		setSelectProductDialog(false)
	}

	const breadCrumbs = buildBreadCrumbs({
		page: Pages.ClassVideos,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			},
			{
				index: 2,
				overrideName: 'Videos'
			}
		],
		params: { classId: Number(classId) }
	})

	useEffect(() => {
		if (selectedVideosData) setSelectedVideos(selectedVideosData.tip.map((item) => item) as Tip[])
	}, [selectedVideosData])

	useEffect(() => {
		if (selectedPlaylistData)
			setSelectedPlaylist(selectedPlaylistData.playlist.map((item) => item) as Playlist[])
	}, [selectedPlaylistData])

	useEffect(() => {
		getClass({
			variables: {
				classId: Number(classId)
			}
		})
		getSelectedVideos({
			variables: {
				classId: Number(classId),
				search: buildSearchText('', ['title', 'description'])
			}
		})
		getSelectedPlaylists({
			variables: {
				classId: Number(classId),
				search: buildSearchText('', ['name', 'description'])
			}
		})
	}, [])

	useEffect(() => {
		if (data) {
			setClassName(data.class_by_pk?.title || '')
		}
	}, [data])

	return {
		breadCrumbs,
		handleAddVideos,
		handleCancelVideos,
		handleAddPlaylist,
		handleCancelPlaylist,
		handleOpenSelectProductDialog,
		handleCancelSelectProductDialog,
		showVideosDialog,
		showPlaylistDialog,
		selectProductDialog,
		selectedVideos,
		selectedPlaylist,
		setShowVideosDialog,
		setShowPlaylistDialog,
		setSelectProductDialog,
		setSelectedVideos,
		setSelectedPlaylist
	}
}
