import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentAttachmentsStyles = makeStyles((theme) =>
	createStyles({
		section: {
			padding: theme.spacing(2, 3)
		},
		title: {
			marginBottom: theme.spacing(1)
		},
		gradingSystem: {
			color: theme.palette.grey[500],
			fontSize: '1.1em',
			lineHeight: '1.1em'
		}
	})
)

export default useAssignmentAttachmentsStyles
