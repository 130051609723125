import React from 'react'

import { Box, BoxProps, IconButton, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { getCyKey } from 'src/utils'

import useStyles from './FormBox.styles'

export type FormBoxProps = {
	text: string
	content?: React.ReactNode
	withAddIcon?: boolean
	onClick?: () => void
	error?: boolean
	boxProps?: BoxProps
}

export const FormBox = ({ text, content, withAddIcon, onClick, error, boxProps }: FormBoxProps) => {
	const styles = useStyles({ error })

	return (
		<Box data-cy={getCyKey(FormBox, text)} className={styles.card} onClick={onClick} {...boxProps}>
			<Box className={styles.content}>
				{content ? content : <Typography className={styles.placeholder}>{text}</Typography>}
			</Box>
			{withAddIcon && (
				<IconButton className={styles.iconButton} onClick={onClick}>
					<AddIcon color="secondary" />
				</IconButton>
			)}
			{!withAddIcon && (
				<IconButton className={styles.iconButton} onClick={onClick}>
					<Edit color="secondary" />
				</IconButton>
			)}
		</Box>
	)
}
