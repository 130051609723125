import React from 'react'

import { Box, createStyles, Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp, CallMade } from '@material-ui/icons'
import { getCyKey } from 'src/utils'

import { BaseButton } from '..'

const useStyles = makeStyles(() =>
	createStyles({
		countCards: {
			padding: '20px'
		}
	})
)

type ProgressStatusCardType = {
	title: string
	body: string | React.ReactNode
	progressSinceLastX: number
	sinceLastText: string
	isView?: boolean
	viewDisabled?: boolean
	viewAction?: () => void
}

export const ProgressStatusCard = ({
	title,
	body,
	progressSinceLastX,
	sinceLastText,
	isView,
	viewAction,
	viewDisabled = false
}: ProgressStatusCardType) => {
	const theme = useTheme()
	const styles = useStyles()

	const getColor = (number: number) => {
		return {
			color:
				number > 0
					? theme.palette.success.main
					: number < 0
					? theme.palette.error.main
					: theme.palette.primary.main
		}
	}

	const getIcon = (number: number) => {
		if (number > 0) return <ArrowDropUp color="inherit" fontSize="small" />
		else if (number < 0) return <ArrowDropDown color="inherit" fontSize="small" />

		return null
	}

	return (
		<Grid xs={6} item>
			<Paper className={styles.countCards}>
				<Typography variant="h6">
					<b>{title}</b>
				</Typography>
				<Typography style={{ marginTop: '10px' }} variant="h5">
					{body}
				</Typography>
				<Box display="flex" justifyContent="space-between" alignItems="flex-end">
					<Box display="flex" alignItems="center" style={getColor(progressSinceLastX)}>
						{getIcon(progressSinceLastX)}
						<Typography style={{ marginRight: '5px' }} color="inherit" variant="caption">
							{progressSinceLastX.toFixed()}%
						</Typography>
						<Typography color="textSecondary" variant="caption">
							{sinceLastText}
						</Typography>
					</Box>
					{isView && (
						<BaseButton
							disabled={viewDisabled}
							data-cy={getCyKey(ProgressStatusCard, 'btnView')}
							color="default"
							endIcon={<CallMade style={{ transform: 'rotate(45deg)' }} />}
							onClick={(e) => {
								e.stopPropagation()
								if (viewAction) viewAction()
							}}>
							View
						</BaseButton>
					)}
				</Box>
			</Paper>
		</Grid>
	)
}
