import { useState } from 'react'

import { Box, Tooltip, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { AssignmentsIcon, DoubleMusicNoteIcon, VideoIcon } from 'src/assets/icons'
import { MicrophoneIconFilled, GoalsIcon } from 'src/assets/icons/CustomIcons'
import { MainTitle, Datetime } from 'src/components'
import { HeaderCard, HeaderCardProps, StyledCardsRow } from 'src/components/Cards/ClassHeaderCard/ClassHeaderCard'
import { NotificationBadge } from 'src/components/NotificationBadge'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { StudentPages } from 'src/routes/studentpages'
import { RouteNotFound } from 'src/scenes/Errors/404.container'
import { SetGoal } from 'src/scenes/Student/1-Home/components/SetGoal'
import { BOX_PADDING } from 'src/styles/constants'
import { buildRouteParameters, buildBreadCrumbs, buildImagePath, formatDate, slugToName } from 'src/utils'

import { useClassDetailsContext } from '../hooks/useClassDetails'
import useStyles from './ClassDetailsTitle.styles'

export const ClassDetailsTitle = ({ page }: PagesProps) => {
	const { classData, assignmentsCount, loadingClass } = useClassDetailsContext()

	const styles = useStyles({ url: buildImagePath(classData?.image_path ?? '') })
	const history = useHistory()
	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.title || '')
			}
		],
		isStudentPage: true
	})

	const goToClassAssignments = () => {
		if (classData?.class_id)
			history.push(
				buildRouteParameters(StudentPages.ClassAssignments, {
					classId: classData?.class_id
				})
			)
	}

	return !classData && !loadingClass ? (
		<RouteNotFound />
	) : (
		<>
			<Box className={styles.boxContainer} p={BOX_PADDING}>
				<MainTitle
					breadcrumbs={breadcrumbs}
					breadcrumbPageStyle={styles.mainTitle}
					title={
						<Tooltip title={classData?.title || ''}>
							<Typography className={styles.title} variant="h3">
								<strong>{classData?.title ?? ''}</strong>
							</Typography>
						</Tooltip>
					}
					rightActions={[
						{
							variant: 'contained',
							color: 'secondary',
							startIcon: <AssignmentsIcon />,
							name: 'Assignments',
							onClick: goToClassAssignments,
							customWrapper: ({ children }) => (
								<NotificationBadge count={assignmentsCount}>{children}</NotificationBadge>
							)
						}
					]}
				/>
				<Box display="flex" alignItems="center">
					<Datetime
						color="primary"
						text={`${formatDate(classData?.start_date)} - ${formatDate(classData?.end_date)}`}
					/>
				</Box>
			</Box>
			<HeaderCards />
		</>
	)
}

const HeaderCards = () => {
	const { songsCount, videosCount } = useClassDetailsContext()

	const { studentData } = useLoginContext()
	const studentId = studentData.student_id

	const history = useHistory()
	const { classId } = useWurrlyParams<typeof StudentPages.ClassDetail.params[number]>()
	const [goalOpen, setGoalOpen] = useState(false)

	const cardsContent: HeaderCardProps[] = [
		{
			title: 'My Goals',
			Icon: GoalsIcon,
			onClick: () => setGoalOpen(true)
		},
		{
			title: 'Songs',
			Icon: DoubleMusicNoteIcon,
			onClick: () => {
				history.push({
					pathname: buildRouteParameters(StudentPages.ClassSongs, { classId })
				})
			},
			count: songsCount
		},
		{
			title: 'Videos',
			Icon: VideoIcon,
			onClick: () => {
				history.push({
					pathname: buildRouteParameters(StudentPages.ClassVideos, { classId })
				})
			},
			count: videosCount
		},
		{
			title: 'Go Create!',
			Icon: MicrophoneIconFilled,
			onClick: () => {
				const newUrl = buildRouteParameters(StudentPages.MusicCatalog, { classId })
				history.push(newUrl)
			}
		}
	]

	return (
		<StyledCardsRow padding={BOX_PADDING}>
			{cardsContent.map((card) => (
				<HeaderCard key={card.title} {...card} />
			))}
			<SetGoal isOpen={goalOpen} setIsOpen={setGoalOpen} studentId={studentId} />
		</StyledCardsRow>
	)
}
