import React, { useEffect, useState } from 'react'

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core'
import { AccordionWrapper, DeleteItemDialog, ListVideos, NoItems, SelectSort } from 'src/components'
import { SeeAllBtnTooltip } from 'src/components/SeeAllTooltip'
import { useDeleteTipFromClassMutation } from 'src/graphql/autogenerate/hooks'
import { Order_By, Tip } from 'src/graphql/autogenerate/schemas'
import { useClassVideosContext } from 'src/scenes/Student/scenes/2-Classes/scenes/ClassVideos/useClassVideos'
import { getCyKey, SortOrder } from 'src/utils'

export const ClassVideosSaved = () => {
	const {
		classId,
		teacherId,
		setVideosSavedVariables,
		videosSavedQuery: { data: videosSavedData, loading: videosSavedLoading },
		videosFavoritesQuery: { refetch: videosFavoritesRefetch }
	} = useClassVideosContext()
	const [videosSavedArr, setVideosSavedArr] = useState<Tip[]>([])
	const [videosSavedCount, setVideosSavedCount] = useState<number>(0)
	const [videosSavedLimit, setVideosSavedLimit] = useState<number | undefined>(5)
	const [videosSavedSort, SetVideosSavedSort] = useState(SortOrder.Up)

	const [videosSavedIdToDelete, setVideosSavedIdToDelete] = useState<number>()
	const [deleteTipFromClass] = useDeleteTipFromClassMutation()

	useEffect(() => {
		if (videosSavedData) {
			setVideosSavedArr(videosSavedData.tip as Tip[])
			setVideosSavedCount(videosSavedData.tip_aggregate.aggregate?.count || 0)
		}
	}, [videosSavedData])

	useEffect(() => {
		setVideosSavedVariables({
			classId,
			teacherId,
			orderTitle:
				videosSavedSort !== SortOrder.Recent
					? videosSavedSort !== SortOrder.Down
						? Order_By.Asc
						: Order_By.Desc
					: undefined,
			createdAt: videosSavedSort === SortOrder.Recent ? Order_By.Asc : undefined
		})
	}, [videosSavedLimit, videosSavedSort])

	const seeAllVideosSaved = () => {
		setVideosSavedLimit(undefined)
	}

	const handleSort = (
		e: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		SetVideosSavedSort(e.target.value as SortOrder)
	}

	return (
		<Box pt={3}>
			{videosSavedLoading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!videosSavedLoading && !videosSavedArr.length && (
				<NoItems
					title="Seems no Videos have been added yet to this Class!"
					description="Come back Later!"
				/>
			)}
			{!videosSavedLoading && !!videosSavedArr.length && (
				<AccordionWrapper
					defaultExpanded
					summary={
						<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b>Videos ({videosSavedCount})</b>
							</Typography>
							<div style={{ flexGrow: 1 }} />

							<SelectSort
								cyKey={getCyKey(ClassVideosSaved)}
								showDateAdded
								value={videosSavedSort}
								onChange={handleSort}
							/>
						</Box>
					}
					details={
						<ListVideos
							classId={classId}
							teacherId={teacherId}
							spacing={1.5}
							data={videosSavedArr}
							removeButton
							viewButton
							overrideDelete={(videosSavedId) => {
								setVideosSavedIdToDelete(videosSavedId)
							}}
						/>
					}
					actions={
						videosSavedCount > 0 && (
							<SeeAllBtnTooltip
								canFetchMore={videosSavedArr.length < videosSavedCount}
								name="videos"
								onClick={seeAllVideosSaved}
							/>
						)
					}
				/>
			)}
			{!videosSavedLoading && videosSavedCount > 0 && <Divider />}

			<DeleteItemDialog
				isOpen={!!videosSavedIdToDelete}
				setIsOpen={setVideosSavedIdToDelete}
				onConfirm={async () => {
					await deleteTipFromClass({
						variables: {
							classId,
							tipId: Number(videosSavedIdToDelete),
							limit: videosSavedLimit,
							orderTitle:
								videosSavedSort !== SortOrder.Recent
									? videosSavedSort !== SortOrder.Down
										? Order_By.Asc
										: Order_By.Desc
									: undefined,
							created_at: videosSavedSort === SortOrder.Recent ? Order_By.Asc : undefined
						},
						update: (cache, { data }) => {
							const classToUpdateInCache = data?.delete_class_tip?.returning[0].class
							if (!classToUpdateInCache) return
							const identify = cache.identify(classToUpdateInCache)
							cache.evict({
								id: identify,
								fieldName: 'class_tips'
							})
							cache.evict({
								id: identify,
								fieldName: 'class_tips_aggregate'
							})
							cache.evict({
								id: 'ROOT_QUERY',
								fieldName: 'tip'
							})
						}
					})
					await videosFavoritesRefetch()
				}}
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Video from your Class?',
						body: "You'll be able to re-add it from the Videos page at any time.",
						buttonLabel: 'Delete Video'
					},
					confirmation: {
						body: 'You have successfully deleted this Video from your Class.'
					}
				}}
				itemName={'Video'}
			/>
		</Box>
	)
}
