import { useEffect, useState } from 'react'

import { FormikProps } from 'formik'
import { AddDialog } from 'src/components/Dialogs/AddDialog/index'
import { Song, Tip } from 'src/graphql/autogenerate/schemas'
import { getVideoGenres, TypeEnum } from 'src/utils'

import { buildImagePath } from '../../../../../../../../utils/utils'
import { FormBox } from '../FormBox/FormBox'
import { MAX_VIDEOS } from '../StepTwoForm/utils'
import { VideoCard } from '../VideoCard/VideoCard'

type SelectVideoProps = {
	selectedVideos: Tip[]
	setSelectedVideos: (videos: Tip[]) => void
	limit?: number
	setVideosCounter: (videos: number) => void
	formik: FormikProps<Record<string, string>>
}

export const SelectVideo = ({
	selectedVideos,
	setSelectedVideos,
	limit = MAX_VIDEOS,
	setVideosCounter,
	formik
}: SelectVideoProps) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false)

	const openDialog = () => setIsDialogOpen(true)
	const closeOptionDialog = () => setIsDialogOpen(false)
	const onSaveVideos = (selectedItems: (Song | Tip)[]) => setSelectedVideos(selectedItems as Tip[])

	useEffect(() => {
		closeOptionDialog()
	}, [selectedVideos])

	return (
		<>
			<FormBox
				text="Pick Videos"
				withAddIcon
				onClick={openDialog}
				content={
					selectedVideos.length &&
					selectedVideos.map((video, i) => (
						<VideoCard
							tip_id={video.tip_id}
							imgSource={buildImagePath(video.image_path)}
							title={video.title}
							description={video.description ?? ''}
							genres={getVideoGenres(video)}
							key={`video_card_${i}`}
							selectedVideos={selectedVideos}
							setSelectedVideos={setSelectedVideos}
						/>
					))
				}
			/>
			{isDialogOpen && (
				<AddDialog
					setCounter={setVideosCounter}
					formik={formik}
					limit={limit}
					itemsSelected={selectedVideos}
					onSave={onSaveVideos}
					video={true}
					open={isDialogOpen}
					setOpen={setIsDialogOpen}
					type={TypeEnum.Video}
				/>
			)}
		</>
	)
}
