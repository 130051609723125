import { useState } from 'react'

import { Box } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { MainTitle } from 'src/components'
import { Pages } from 'src/routes/teacherPages'
import { ExportInsightsDialog } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentsTitle/ExportInsightsDialog'

export const AssignmentsTitle = ({ page }: PagesProps) => {
	const history = useHistory()
	const [isExportDialogOpen, setIsExportDialogOpen] = useState(false)

	const goToAddAssignment = () => history.push(Pages.AddAssignment.path)

	const openExportDialog = () => setIsExportDialogOpen(true)

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Manage all your Assignments in one easy place."
				rightActions={[
					{
						variant: 'contained',
						color: 'default',
						startIcon: '',
						name: 'Export',
						onClick: openExportDialog,
						style: { minWidth: '172px' }
					},
					{
						variant: 'contained',
						color: 'secondary',
						endIcon: <Add />,
						name: 'Add Assignment',
						onClick: goToAddAssignment
					}
				]}
			/>

			<ExportInsightsDialog open={isExportDialogOpen} setOpen={setIsExportDialogOpen} />
		</Box>
	)
}
