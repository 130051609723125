import React, { useEffect, useState } from 'react'

import {
	Avatar,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Fab,
	FormControlLabel,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Typography,
	useTheme
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import TimerIcon from '@material-ui/icons/Timer'
import { useDebounce } from 'react-use'
import { ChordMapObjectType } from 'src/@types'
import { FullBandIcon, GuitarIcon, PianoIcon } from 'src/assets/icons/CustomIcons'
import { BaseDialog, BaseSlider, InfoDialog } from 'src/components'
import { BigBaseButton } from 'src/components/Buttons/BigBaseButton'
import { FavoriteButton } from 'src/components/Buttons/FavoriteButton'
import { StudioSongPlayerInitialState } from 'src/components/Studio/Setup/StudioSongPlayer'
import {
	useGetSongByPkLazyQuery,
	useGetSongStudentByPkLazyQuery,
	useCreateRoyaltyLogMutation,
	useInsertStudentBadgeEventMutation
} from 'src/graphql/autogenerate/hooks'
import { Song, Screen_Type_Enum, Badge_Event_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import {
	AWS_SONG_URL,
	BLANK_SONG_TITLE,
	buildImagePath,
	buildRouteParameters,
	getCyKey,
	getSongGenres,
	SONG_KEYS,
	TrackTypeEnum
} from 'src/utils'
import { getAudioUrl, secondsToTimeString } from 'src/utils/utils'

import { SelectChord } from './SelectChord'

type SongPlayerProps = {
	songId: number | undefined
	setSongId: React.Dispatch<React.SetStateAction<number | undefined>>
	viewDetails: boolean
	setViewDetails: React.Dispatch<React.SetStateAction<boolean>>
	loading: boolean
	isFavorite: boolean
	handleFavoriteFn: (isFavorite: boolean, noDialog: boolean) => void
	dontAsk: boolean
	selectedChords?: string[]
	allTranspositions: boolean
	classId?: number
}

const useStyles = makeStyles((theme) => ({
	footerButtonText: {
		fontWeight: 'bold',
		fontSize: '13px'
	},
	instrumentButton: {
		'&.MuiButton-contained&.Mui-disabled': {
			color: `${theme.palette.primary.main}`,
			boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1)`
		}
	},
	instrumentIcon: {
		width: '40px'
	}
}))

export const SongPlayer = ({
	songId,
	setSongId,
	viewDetails,
	setViewDetails,
	loading,
	isFavorite,
	handleFavoriteFn,
	dontAsk,
	classId,
	selectedChords,
	allTranspositions
}: SongPlayerProps) => {
	const theme = useTheme()
	const classes = useStyles(theme)
	const { teacherData: teacher, studentData } = useLoginContext()
	const [getSong, { data, loading: songLoading }] = teacher?.teacher_id
		? useGetSongByPkLazyQuery()
		: useGetSongStudentByPkLazyQuery()
	const music = data?.song[0]
	const [audio, setAudio] = useState(new Audio(''))
	const [loaded, setLoaded] = useState(false)
	const [playing, setPlaying] = useState(false)
	const [progress, setProgress] = useState(0)
	const [currentTime, setCurrentTime] = useState(0)
	const [duration, setDuration] = useState(0)
	const [vocalGuide, setVocalGuide] = useState(false)
	const [instrument, setInstrument] = useState('')
	const [isBlankSong, setIsBlankSong] = useState(false)
	const [speed, setSpeed] = useState(0.0)
	const [key, setKey] = useState(0)
	const [chords, setChords] = useState<string[]>()
	const [isErrorOpen, setIsErrorOpen] = useState(false)
	const [songIsUnavailable, setSongIsUnavailable] = useState(false)
	const [audioStartTime, setAudioStartTime] = useState<Date>()
	const [currentTrackId, setCurrentTrackId] = useState<number>()
	const [shouldHandleRoyalty, setShouldHandleRoyalty] = useState(false)

	const [justVocalGuide, setJustVocalGuide] = useState(false)
	const [badgeEvent] = useInsertStudentBadgeEventMutation()
	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()
	const instruments = [
		{
			id: TrackTypeEnum.Piano,
			name: 'Piano',
			icon: <PianoIcon className={classes.instrumentIcon} />
		},
		{
			id: TrackTypeEnum?.Band,
			name: 'Full Band',
			icon: <FullBandIcon className={classes.instrumentIcon} />
		},
		{
			id: TrackTypeEnum.Guitar,
			name: 'Guitar',
			icon: <GuitarIcon className={classes.instrumentIcon} />
		}
	]

	const handleClose = () => {
		setSongId(undefined)
		setTimeout(() => {
			setLoaded(false)
			setPlaying(false)
			setProgress(0)
			setCurrentTime(0)
			setDuration(0)
			setSpeed(0.0)
			setKey(0)
			setVocalGuide(false)
			setInstrument('')

			audio.pause()
		}, 100)
	}

	useEffect(() => {
		if (songId && (teacher.teacher_id || studentData.student_id)) {
			const variables = teacher.teacher_id
				? { variables: { songId, teacherId: teacher.teacher_id } }
				: { variables: { songId, studentId: studentData.student_id } }
			getSong(variables)
		}
	}, [songId])

	useEffect(() => {
		if (!songLoading && music) {
			if (!music.tracks.length) {
				setSongIsUnavailable(true)
				setIsErrorOpen(true)

				return
			}
			setSongIsUnavailable(false)

			let transposition = 0
			let instrumentSelected = ''
			const justIncludeVocalGuide =
				music.tracks.length === 1 && music.tracks[0].track_type.name === TrackTypeEnum.Vocal

			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum.Guitar)) {
				instrumentSelected = TrackTypeEnum?.Guitar
			}
			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum.Piano)) {
				instrumentSelected = TrackTypeEnum?.Piano
			}
			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum?.Band)) {
				instrumentSelected = TrackTypeEnum?.Band
			}

			if (music.tracks.some((track) => track.track_type.name === TrackTypeEnum?.Vocal)) {
				instrumentSelected = TrackTypeEnum?.Band
			}

			setInstrument(instrumentSelected)
			setJustVocalGuide(justIncludeVocalGuide)
			const isBlank = music.title.toLowerCase() === BLANK_SONG_TITLE
			setIsBlankSong(isBlank)

			const track = music.tracks.find((track) => track.track_type.name === instrumentSelected)
			if (!track?.midis[0]) return
			if (selectedChords && selectedChords.length > 0 && allTranspositions) {
				const chord_map = Object.values(track?.midis[0]?.chord_map_object as ChordMapObjectType).find(
					(chordMap) => {
						return (
							new Set([...chordMap.chordArray, ...selectedChords]).size ===
							chordMap.chordArray.length
						)
					}
				)
				transposition = chord_map ? chord_map.transposition : 0
				setKey(transposition)
			} else {
				setChords(track?.midis.flatMap((midi) => midi.chords_Array_Zero?.split(',') || []))
			}
			setAudio(new Audio(getAudioUrl(0.0, transposition, instrumentSelected, music)))
		}
	}, [music, songLoading, selectedChords])

	useEffect(() => {
		setLoaded(false)
		if (playing) {
			audio.pause()
		}

		if (music) {
			audio.src = getAudioUrl(
				speed,
				key,
				vocalGuide || justVocalGuide ? TrackTypeEnum?.Vocal : instrument,
				music
			)
			if (playing) {
				audio.playbackRate = speed + 1
				audio.currentTime = currentTime
				audio.play()
			}
			const track = music.tracks.find((track) => track.track_type.name === instrument)
			if (track) {
				setChords(((track.midis[0]?.chord_map_object ?? {}) as ChordMapObjectType)[key]?.chordArray || [])
				setCurrentTrackId(track.track_id)
			}
		}
	}, [key, speed, instrument, vocalGuide, music])

	useDebounce(
		() => {
			if (shouldHandleRoyalty) {
				setShouldHandleRoyalty(false)
				handleRoyaltyLog()
			}
		},
		200,
		[shouldHandleRoyalty]
	)

	const handleLoadedData = () => {
		setLoaded(true)
		setDuration(audio.duration)
	}
	const handleAudioError = () => {
		if (audio?.src && audio?.src.includes(AWS_SONG_URL)) {
			setIsErrorOpen(true)
			setLoaded(true)
			setPlaying(false)
		}
	}
	const handleAudioTimeUpdate = () => {
		setProgress((audio.currentTime * 100) / audio.duration) // percent
		setCurrentTime(audio.currentTime) // seconds
	}
	const handleAudioEnded = () => {
		setPlaying(false)
		setShouldHandleRoyalty(true)
	}

	useEffect(() => {
		audio.addEventListener('loadeddata', handleLoadedData)
		audio.addEventListener('error', handleAudioError)
		audio.addEventListener('timeupdate', handleAudioTimeUpdate)
		audio.addEventListener('ended', handleAudioEnded)

		return () => {
			audio.removeEventListener('loadeddata', handleLoadedData)
			audio.removeEventListener('error', handleAudioError)
			audio.removeEventListener('timeupdate', handleAudioTimeUpdate)
			audio.removeEventListener('ended', handleAudioEnded)
		}
	}, [audio])

	const handleTimeChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		const val = ((value as number) * duration) / 100

		audio.currentTime = +val.toFixed(4)
	}

	const handleControl = () => {
		if (playing) {
			audio.pause()
			setPlaying(false)
			handleRoyaltyLog()
		} else {
			audio.playbackRate = speed + 1
			audio.play()
			setPlaying(true)
			setAudioStartTime(new Date())

			if (!teacher?.teacher_id)
				// send badge event only if student is logged in
				badgeEvent({
					variables: {
						studentId: studentData.student_id,
						eventName: Badge_Event_Name_Enum.Streaming,
						songId,
						classId
					}
				})
		}
	}

	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const playSeconds = (audioPauseTime.getTime() - (audioStartTime ?? audioPauseTime).getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId: currentTrackId ?? 0,
					songId: songId ?? 0,
					teacherId: teacher.teacher_id || null,
					studentId: studentData.student_id || null,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.SongPlayer
				}
			})
			setAudioStartTime(undefined)
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
	}

	const handleActivate = () => {
		if (!vocalGuide) {
			setInstrument(TrackTypeEnum?.Band)
		}
		setVocalGuide(!vocalGuide)
	}

	const handleSelectInstrument = (inst: string) => {
		if (!vocalGuide) {
			setInstrument(inst)
		}
	}
	const handleSpeedChange = (_: React.ChangeEvent<unknown>, value: number | number[]) =>
		setSpeed(value as number)

	const handleKeyChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => setKey(value as number)

	const handleView = () => setViewDetails(!viewDetails)

	const handlePractice = () => {
		const initialDataForStudio: StudioSongPlayerInitialState = {
			speed,
			key,
			instrument,
			vocalGuide: String(vocalGuide)
		}
		if (!isBlankSong) {
			window
				.open(
					buildRouteParameters(
						Pages.TeacherStudio,
						{
							songId: songId ?? 0
						},
						true,
						initialDataForStudio
					),
					'_blank'
				)
				?.focus()
		} else {
			window
				.open(
					buildRouteParameters(
						Pages.TeacherRecord,
						{
							songId: songId ?? 0
						},
						true,
						{ isBlankSong: isBlankSong.toString() }
					),
					'_blank'
				)
				?.focus()
		}
	}

	const getPlayerIcon = () => {
		if (songIsUnavailable) {
			return <ErrorIcon data-cy={getCyKey(SongPlayer, 'ErrorIcon')} color="secondary" />
		}
		if (!loaded) {
			return <CircularProgress color="secondary" style={{ width: 25, height: 25 }} />
		}
		if (playing) {
			return <PauseIcon data-cy={getCyKey(SongPlayer, 'PauseIcon')} color="secondary" />
		}

		return <PlayArrowIcon data-cy={getCyKey(SongPlayer, 'PlayArrowIcon')} color="secondary" />
	}

	return (
		<>
			<BaseDialog
				maxWidth="sm"
				fullWidth={true}
				data-cy={getCyKey(SongPlayer)}
				onClose={handleClose}
				open={!!songId}
				bodyProps={{ style: { padding: '0 0 20px 5px', marginBottom: 10 } }}
				dialogActionsStyles={{ padding: 0 }}
				header={
					<ListItem style={{ padding: '0px 0px 10px 0', alignItems: 'flex-start' }}>
						<ListItemAvatar>
							<Avatar
								variant="rounded"
								style={{ width: 135, height: 135, marginRight: 16 }}
								src={buildImagePath(music?.image_path || '')}
							/>
						</ListItemAvatar>
						<Box width="100%">
							<ListItemText
								style={{ margin: 0 }}
								primary={
									<div>
										<Typography variant="h6">
											<b>{music?.title}</b>
										</Typography>
										<Typography style={{ fontSize: 13 }}>
											<b>Made Famous By {music?.artist.name}</b>
										</Typography>
									</div>
								}
								secondary={music ? `• ${getSongGenres(music as Song)}` : ''}
							/>

							{songIsUnavailable && (
								<ErrorContainer>
									<ErrorIcon color="error" />
									<ErrorTypography>This song is currently unavailable</ErrorTypography>
								</ErrorContainer>
							)}
							{!isBlankSong && (
								<Box mt={2} display="flex" alignItems="center">
									<Fab
										data-cy={getCyKey(SongPlayer, 'Fab')}
										style={{
											backgroundColor: '#fff',
											minWidth: 48,
											pointerEvents: loaded ? 'initial' : 'none'
										}}
										size="medium"
										onClick={handleControl}>
										{getPlayerIcon()}
									</Fab>
									<Box
										ml={1}
										px={2}
										boxShadow={1}
										borderRadius={4}
										display="flex"
										alignItems="center"
										width="100%">
										<Typography
											style={{ minWidth: 85, fontSize: 14 }}
											data-cy={getCyKey(SongPlayer, 'SongTimes')}>
											{secondsToTimeString(currentTime)} / {secondsToTimeString(duration)}
										</Typography>
										<Box ml={2} width="100%" alignItems="center" display="flex">
											<BaseSlider
												data-cy={getCyKey(SongPlayer, 'SongSlider')}
												value={progress}
												onChange={handleTimeChange}
												aria-labelledby="continuous-slider"
												color="secondary"
												disabled={songIsUnavailable}
											/>
										</Box>
									</Box>
								</Box>
							)}
						</Box>
					</ListItem>
				}
				body={
					!isBlankSong ? (
						!viewDetails ? (
							<Box>
								{music &&
									music.lyrics &&
									(music.lyrics as string[]).map((line, indx) => {
										return <Typography key={`${indx}-${line}`}>{line}</Typography>
									})}
							</Box>
						) : (
							<Box>
								<Box display="flex" flexWrap="wrap" alignItems="center">
									<Typography>
										<b>Instrument</b>
									</Typography>
									<div style={{ flexGrow: 1 }} />
									<FormControlLabel
										style={{ margin: 0, paddingBottom: '10px' }}
										control={
											<Checkbox
												data-cy={getCyKey(SongPlayer, `ActivateVocalGuide`)}
												checked={vocalGuide || justVocalGuide}
												onChange={handleActivate}
												name="Activate Vocal Guide"
												color="secondary"
												disabled={
													!music?.tracks.some(
														(track) => track.track_type.name === 'Vocal'
													) || justVocalGuide
												}
											/>
										}
										label={
											<Typography style={{ fontSize: 14, paddingRight: '8px' }}>
												Activate Vocal Guide?
											</Typography>
										}
									/>
								</Box>
								<Box
									paddingRight="8px"
									display="flex"
									alignItems="center"
									justifyContent="space-between">
									{instruments.map((inst) => (
										<Box key={inst.id} style={{ width: '31%' }}>
											<Button
												data-cy={getCyKey(SongPlayer, inst.name)}
												style={{
													height: 60,
													color: instrument === inst.id ? '#111' : undefined, // negro
													position: 'relative',
													borderWidth: instrument === inst.id ? 2 : undefined,
													backgroundColor:
														instrument === inst.id
															? 'rgba(255, 194, 12, 0.1)'
															: '#fff',
													boxShadow:
														instrument !== inst.id
															? `0px 4px 6px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.1)`
															: '',
													fontWeight: instrument === inst.id ? 'bold' : 'normal'
												}}
												fullWidth
												className={classes.instrumentButton}
												disabled={
													!music?.tracks.some(
														(track) => track.track_type.name === inst.id
													) ||
													(instrument !== inst.id && vocalGuide)
												}
												startIcon={inst.icon}
												variant={instrument === inst.id ? 'outlined' : 'contained'}
												color={instrument === inst.id ? 'secondary' : undefined}
												onClick={() => handleSelectInstrument(inst.id)}>
												{inst.name}
												{instrument === inst.id && (
													<Box position="absolute" top={8} right={8}>
														<CheckCircleIcon fontSize="small" color="secondary" />
													</Box>
												)}
											</Button>
										</Box>
									))}
								</Box>

								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									style={{ width: 'calc(100% - 10px)' }}>
									<Grid item xs style={{ paddingRight: 30 }}>
										<Box mt={1} mb={1}>
											<Typography>
												<b>Speed</b>
											</Typography>
										</Box>
										<Box
											px={2}
											boxShadow={1}
											borderRadius={10}
											display="flex"
											alignItems="center"
											width="100%">
											<TimerIcon fontSize="small" />
											<Box ml={2} width="100%" alignItems="center" display="flex">
												<BaseSlider
													data-cy={getCyKey(SongPlayer, 'SpeedSlider')}
													value={speed}
													onChange={handleSpeedChange}
													aria-labelledby="discrete-slider"
													color="secondary"
													valueLabelDisplay="on"
													step={0.1}
													marks
													min={-0.3}
													max={0.3}
													disabled={songIsUnavailable || !loaded}
												/>
											</Box>
										</Box>
									</Grid>
									<Grid item xs>
										<Box mt={1} mb={1}>
											<Typography>
												<b>Key</b>
											</Typography>
										</Box>
										<Box
											px={2}
											boxShadow={1}
											borderRadius={4}
											display="flex"
											alignItems="center"
											width="100%">
											<MusicNoteIcon fontSize="small" />
											<Box ml={2} width="100%" alignItems="center" display="flex">
												<BaseSlider
													data-cy={getCyKey(SongPlayer, 'Slider')}
													value={key}
													onChange={handleKeyChange}
													aria-labelledby="discrete-slider"
													color="secondary"
													valueLabelDisplay="on"
													step={1}
													marks
													min={SONG_KEYS[0]}
													max={SONG_KEYS[SONG_KEYS.length - 1]}
													disabled={songIsUnavailable || !loaded}
												/>
											</Box>
										</Box>
									</Grid>
								</Box>
								<Box mt={3} mb={1}>
									<Typography>
										<b>Corresponding Chords</b>
									</Typography>
								</Box>
								<SelectChord
									showSelectedOnly
									showGreyButtons
									style={{ width: '100%' }}
									selectedChords={chords}
									disabled
									columns={8}
								/>
							</Box>
						)
					) : (
						<Box display="flex" flexWrap="wrap" alignItems="center">
							<Typography style={{ paddingTop: '9px' }}>
								Here’s a blank canvas for you to write your song! Enjoy!
							</Typography>
						</Box>
					)
				}
				rightActions={
					<Box justifyContent="flex-end">
						<FavoriteButton
							isFavorite={isFavorite}
							onClick={() => handleFavoriteFn(isFavorite, dontAsk)}
							loading={loading}
							disabled={songIsUnavailable}
							style={{ height: 47 }}
						/>
						{!isBlankSong && (
							<BigBaseButton
								data-cy={getCyKey(SongPlayer, 'LyricsViewSongDetails')}
								// style={viewDetails ? { padding: '6px 30px' } : { padding: '6px 20px' }}
								variant="contained"
								onClick={handleView}
								color="secondary"
								style={{ height: 47 }}
								disabled={songIsUnavailable}>
								<Typography className={classes.footerButtonText} variant="caption">
									{viewDetails ? 'Lyrics' : 'View Song Details'}
								</Typography>
							</BigBaseButton>
						)}
						{viewDetails && (
							<BigBaseButton
								data-cy={getCyKey(SongPlayer, 'PracticeSongDetails')}
								style={{ marginLeft: 10, width: 205, height: 47 }}
								variant="contained"
								onClick={handlePractice}
								color="secondary"
								disabled={songIsUnavailable}>
								<Typography className={classes.footerButtonText} variant="caption">
									Practice
								</Typography>
							</BigBaseButton>
						)}
					</Box>
				}
				onDiscard={handleClose}
				discardLabel="Close"
				onDiscardProps={{ style: { width: '20%', height: 47 } }}
				typographyProps={{ className: classes.footerButtonText }}
			/>

			<InfoDialog
				open={isErrorOpen}
				onClose={() => {
					setIsErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while retrieving the song"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsErrorOpen(false)
				}}
			/>
		</>
	)
}

const ErrorTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.error.dark,
	marginLeft: '.5em'
}))
const ErrorContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: '.7em'
})
