import { makeStyles, Theme } from '@material-ui/core/styles'
import MusicStudioBackground from 'src/assets/images/MusicStudioBackground.svg'

export const useChooseBestTakeStyles = makeStyles((theme: Theme) => ({
	baseDialog: {
		overflowY: 'auto',
		'& div.MuiBackdrop-root': {
			backgroundColor: 'transparent'
		},
		'& div.MuiDialogTitle-root': {
			padding: '10px 32px'
		},
		'& div.MuiDialogActions-root': {
			padding: '27px 0px 40px 0px'
		},
		'& div.MuiDialog-container': {
			height: 'auto',
			marginTop: '65px'
		}
	},
	dialogContent: {
		borderStyle: 'none',
		padding: 0
	},
	box: {
		position: 'relative'
	},
	subtitle: {
		fontWeight: 500,
		fontSize: '18px',
		color: '#1D273D',
		padding: '0px 32px'
	},
	bottomContainer: {
		position: 'absolute',
		display: 'flex',
		bottom: 20,
		width: '884px',
		height: '60px',
		justifyContent: 'space-between'
	},
	centerContainer: {
		position: 'relative',
		padding: '0px 74px',
		width: '1032px',
		height: '650px'
	},

	backgroundImage: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `url(${MusicStudioBackground})`,
		width: '100%',
		height: '100%',
		position: 'fixed',
		left: '0',
		top: '0'
	},
	logoPosition: {
		position: 'absolute',
		top: '36px',
		left: '16px'
	}
}))
