import React from 'react'

import { Box, Checkbox, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

export type AddStudentsTableHeader = {
	id: keyof AddStudentsColumns
	label: string
}

type AddStudentsColumns = {
	studentName: string
	studentEmail: string
	qrCode: string
	studentCode: string
}

type AddStudentsEnhancedTableHeaderProps = {
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	headerCells: AddStudentsTableHeader[]
	rowCount: number
	selectedCount: number
}

export const AddStudentsEnhancedTableHeader = ({
	onSelectAllClick,
	headerCells,
	rowCount,
	selectedCount
}: AddStudentsEnhancedTableHeaderProps) => {
	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						indeterminate={selectedCount > 0 && selectedCount < rowCount}
						checked={rowCount > 0 && selectedCount === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headerCells.map((headerCell) => (
					<TableCell key={headerCell.id}>
						<Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="left">
							<Typography noWrap>
								<b>{headerCell.label}</b>
							</Typography>
						</Box>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	)
}
