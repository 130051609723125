import { BaseButton } from 'src/components'
import useStyles from 'src/components/Version/Version.styles'

type VersionProps = {
	version: string
	onRefresh: () => void
}

export const Version = ({ version, onRefresh }: VersionProps) => {
	const styles = useStyles()

	const handleRefresh = () => {
		onRefresh()
		window.location.reload()
	}

	return (
		<div className={styles.root}>
			<p className={styles.p}>A new version ({version}) is available, please refresh to update Wurrly</p>
			<BaseButton data-cy={'Refresh-Button'} color="secondary" onClick={handleRefresh}>
				Refresh
			</BaseButton>
		</div>
	)
}
