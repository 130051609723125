import { useEffect, useState } from 'react'

import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { noop } from 'lodash/fp'
import { useHistory } from 'react-router-dom'
import { NotesIcon } from 'src/assets/icons'
import { FixedBackground, InfoDialog } from 'src/components'
import { Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useSearchQuery } from 'src/hooks'
import { ClassLinkTeacherData } from 'src/hooks/integrations/useClassLinkInstance'
import { useIntegrationContext } from 'src/hooks/useIntegration'
import { useLoginContext } from 'src/hooks/useLogin'
import { LoginPages } from 'src/routes/loginPages'

const useStyles = makeStyles({
	icon: {
		position: 'fixed',
		bottom: '40px',
		left: '50%',
		transform: 'translateX(-50%)'
	},
	boxProgress: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%,-50%)'
	}
})

export const ClassLinkLogin = () => {
	const styles = useStyles()
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const queryParams = useSearchQuery()
	const { loginSignIn } = useLoginContext()
	const {
		classLinkInstance: {
			// after this function (setCode) is called, useClassLinkInstance will do the work
			// and will return loginOrSignUpData or loginOrSignUpError
			setCode,
			account: classLinkAccount,
			data: loginOrSignupData,
			error: loginOrSignUpError
		}
	} = useIntegrationContext()
	const history = useHistory()
	const code = queryParams.get('code')
	const integrationName = Integration_Name_Enum?.ClassLink

	// get classlink token
	useEffect(() => {
		if (code) setCode(code)
	}, [code])

	// After loginOrSignUp data has been received
	useEffect(() => {
		if (loginOrSignUpError) checkLoginOrSignUpError()
		else if (loginOrSignupData) handleLoginOrSignUp()
	}, [loginOrSignupData, loginOrSignUpError])

	// handle login or SignUp success
	const handleLoginOrSignUp = () => {
		if (!loginOrSignupData || !classLinkAccount) return

		const { isTeacher } = classLinkAccount
		const token = loginOrSignupData.token
		if (isTeacher) {
			const { isSignup, teacherId, token, schoolId } = loginOrSignupData as ClassLinkTeacherData

			// start signup process
			if (!schoolId && isSignup) {
				return history.push({
					pathname: LoginPages.Teacher.path,
					state: { token, integrationName, teacherId }
				})
			}
		}

		// login for teacher or student
		loginSignIn(token)
	}

	// handle login or SignUp error
	const checkLoginOrSignUpError = () => {
		setErrorMessage('An error occured with this integration')
		setTimeout(() => {
			setIsOnErrorOpen(true)
		}, 500)
	}

	return (
		<FixedBackground>
			<img src={NotesIcon} className={styles.icon} />
			<Box className={styles.boxProgress}>
				<CircularProgress color="secondary" size={40} />
			</Box>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={noop}
				icon="error"
				title="Something went wrong"
				body={errorMessage}
				confirmLabel=""
				onConfirm={noop}
				confirmProps={{ style: { fontWeight: 'bold' } }}
			/>
		</FixedBackground>
	)
}
