import { createStyles, makeStyles } from '@material-ui/core'

export const useClassProductivityChartStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: theme.spacing(2),
			padding: theme.spacing(2.5)
		},
		emptyBox: {
			height: '300px'
		}
	})
)

export default useClassProductivityChartStyles
