import { useMemo } from 'react'

import { Avatar, Box, CircularProgress, Divider, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import PrintIcon from '@material-ui/icons/Print'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import { PagesProps } from 'src/@types'
import { StandardIcon, StarIcon } from 'src/assets/icons/CustomIcons'
import { ActionButton, DeleteItemDialog, MainTitle, RemoveFavoriteDialog, AddToClassDialog } from 'src/components'
import { ModulesDialog } from 'src/components/Dialogs/ModulesDialog/ModulesDialog'
import { DisplayRichText } from 'src/components/DisplayRichText/DisplayRichText'
import EditIcon from 'src/components/Icons/EditIcon'
import { SongPlayer } from 'src/components/SongPlayer'
import { StandardSideBar } from 'src/components/StandardSideBar'
import { Class } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { RouteNotFound } from 'src/scenes/Errors/404.container'
import { useLessonPage } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonPage/useLessonPage'
import { VideoPlayer } from 'src/scenes/Teacher/scenes/8-Videos/components'
import { buildImagePath } from 'src/utils'
import { convertLessonData, getCyKey, lessonHasStandards } from 'src/utils/utils'

import useStyles from './LessonPage.styles'

export const LessonPage = ({ page, teacherId }: PagesProps) => {
	const classes = useStyles()
	const {
		lesson,
		loadingLessonPDF,
		loadingAdd,
		loadingDel,
		isFavorite,
		removeLessonFn,
		goToEdit,
		handleFavorite,
		sortedLevels,
		selectedSongId,
		setSelectedSongId,
		viewSongDetails,
		setViewSongDetails,
		isSongFavorite,
		handleSongFavorite,
		selectedVideo,
		setSelectedVideo,
		isVideoFavorite,
		handleFavoriteVideo,
		loadingAddSong,
		loadingDelSong,
		loadingAddVideo,
		loadingDelVideo,
		loading,
		breadcrumbs,
		dontAsk,
		setLessonStandard,
		loadLessonPDF,
		classId,
		hasAssignment,
		lessonPlanId,
		showAddToClassPopUp,
		setShowAddToClassPopUp,
		addedClasses,
		addLessonToClasses,
		teacher,
		setIsDeleteDialogOpen,
		setIsSongFavorite,
		setIsVideoFavorite,
		lessonStandard,
		setRemoveLessonFn,
		setDontAsk,
		isDeleteDialogOpen,
		removeLesson,
		goToLessons,
		isModulesDialogOpen,
		setIsModulesDialogOpen,
		moduleCount
	} = useLessonPage({ page, teacherId })

	const articles = useMemo(() => {
		if (!lesson) {
			return []
		}
		if (!lesson?.lesson_plan_articles?.length) {
			return convertLessonData(lesson)
		}

		return lesson?.lesson_plan_articles || []
	}, [lesson])

	return loading ? (
		<Box textAlign="center" marginTop={20}>
			<CircularProgress color="secondary" size={60} />
		</Box>
	) : !lesson ? (
		<RouteNotFound />
	) : (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={lesson?.title}
				description={lesson?.description}
				actionButtons={[
					<Tooltip
						key={0}
						title={moduleCount === 0 ? 'This lesson is not assigned to any module yet' : ''}>
						<span>
							<ActionButton
								disabled={moduleCount === 0}
								onClick={() => {
									setIsModulesDialogOpen(true)
								}}
								size="large"
								icon={<InfoIcon />}
							/>
						</span>
					</Tooltip>,
					<ActionButton
						key={1}
						disabled={loadingAdd || loadingDel}
						selected={isFavorite}
						onClick={() => handleFavorite(isFavorite, dontAsk)}
						size="large"
						icon={
							loadingAdd || loadingDel ? (
								<CircularProgress color="secondary" size={20} />
							) : (
								<StarIcon />
							)
						}
					/>,
					<ActionButton
						key={2}
						disabled={!lessonHasStandards(lesson)}
						onClick={() => {
							setLessonStandard(lesson)
						}}
						size="large"
						icon={<StandardIcon />}
					/>,
					<ActionButton
						key={3}
						onClick={() => {
							loadLessonPDF({ variables: { lessonId: lesson?.lesson_plan_id } })
						}}
						size="large"
						icon={loadingLessonPDF ? <CircularProgress color="secondary" size={20} /> : <PrintIcon />}
					/>
				]}
				rightActions={[
					!classId
						? {
								variant: 'contained',
								color: 'primary',
								endIcon: <AddIcon />,
								name: 'Add to Class',
								onClick: () => {
									setShowAddToClassPopUp(lessonPlanId)
								}
						  }
						: !hasAssignment
						? {
								variant: 'contained',
								color: 'secondary',
								startIcon: <AddIcon />,
								name: 'Add Assignment',
								// disabled: loading,
								onClick: () => {},
								isComingSoon: true
						  }
						: {}
				]}
			/>
			<AddToClassDialog
				title="Save Lesson to Class"
				description="This will save the Lesson so that you can view and teach right from your Class page.
				It will not be visible to your students, but you can share content from the Lesson by creating an Assignment or adding Songs or Videos to a Class."
				itemName="Lesson"
				isOpen={!!showAddToClassPopUp}
				setIsOpen={setShowAddToClassPopUp}
				itemClasses={addedClasses}
				onConfirm={async (selectedClasses: Class[]) => {
					addLessonToClasses(selectedClasses, lessonPlanId)
				}}
			/>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Typography color="textSecondary">
					Keywords: {lesson?.lesson_plan_keyword.map((keyword) => keyword.keyword.name).join(', ')}
				</Typography>
				<div style={{ display: 'flex' }}>
					{!lesson?.featured && teacher?.teacher_id === lesson?.teacher_id && (
						<ActionButton
							style={{ marginRight: '8px' }}
							onClick={goToEdit}
							size="large"
							icon={<EditIcon style={{ color: 'red' }} />}
						/>
					)}
					{!lesson?.featured && teacher?.teacher_id === lesson?.teacher_id && (
						<ActionButton
							data-cy={getCyKey(LessonPage, 'btnRemove')}
							disabled={lesson?.featured}
							onClick={() => {
								setIsDeleteDialogOpen(lesson.lesson_plan_id)
							}}
							size="large"
							icon={<DeleteIcon />}
						/>
					)}
				</div>
			</div>
			<Grid container justifyContent="space-between" style={{ marginTop: 40 }}>
				<Grid item xs={9}>
					<Paper>
						{articles?.map((article) => {
							return (
								<Box padding="20px" key={article.lesson_plan_article_id}>
									<Typography color="secondary" variant="h6">
										<b>{article.title}</b>
									</Typography>
									<Box marginTop="10px" className={classes.ckEditor}>
										<DisplayRichText content={article.content as string} />
									</Box>
								</Box>
							)
						})}
					</Paper>
				</Grid>
				<Grid item xs={2}>
					<Paper>
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Levels</b>
							</Typography>
							<Box maxWidth="150px">
								{sortedLevels.map((level, i) => (
									<Typography
										key={i}
										style={{ margin: '10px 0' }}
										color="textSecondary"
										variant="body2">
										<b>{level.grade.name}</b>
									</Typography>
								))}
							</Box>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Musical Skills</b>
							</Typography>
							<Box maxWidth="150px">
								{lesson?.lesson_plan_musical_skill.map((lesson_plan_musical_skill, i) => (
									<Typography
										key={i}
										style={{ margin: '10px 0' }}
										color="textSecondary"
										variant="body2">
										<b>{lesson_plan_musical_skill.musical_skill.name}</b>
									</Typography>
								))}
							</Box>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Transferable Skills</b>
							</Typography>
							<Box maxWidth="150px">
								{lesson?.lesson_plan_non_musical_skill.map((lesson_plan_non_musical_skill, i) => (
									<Typography
										key={i}
										style={{ margin: '10px 0' }}
										color="textSecondary"
										variant="body2">
										<b>{lesson_plan_non_musical_skill.non_musical_skill.name}</b>
									</Typography>
								))}
							</Box>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Songs</b>
							</Typography>
							{!!(lesson?.lesson_plan_song.length ?? 0 > 0) &&
								lesson.lesson_plan_song.map(({ song }) => (
									<Box
										key={song.song_id}
										display="flex"
										onClick={() => {
											setSelectedSongId(song.song_id)
											setIsSongFavorite(!!song.is_favorite)
										}}>
										<Box marginRight="10px" marginTop="5px">
											<Avatar
												variant="rounded"
												src={buildImagePath(song.image_path ?? '')}
											/>
										</Box>
										<Box
											display="grid"
											maxWidth="150px"
											style={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden'
											}}>
											<Typography variant="caption">
												<b>{song.title}</b>
											</Typography>
											<Typography variant="caption" color="textSecondary">
												<b>{song.artist?.name ?? ''}</b>
											</Typography>
										</Box>
									</Box>
								))}
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Videos</b>
							</Typography>
							{!!(lesson?.lesson_plan_tip.length ?? 0 > 0) &&
								lesson.lesson_plan_tip.map(({ tip }) => (
									<Box
										key={tip.tip_id}
										display="flex"
										onClick={() => {
											setSelectedVideo(tip)
											setIsVideoFavorite(!!tip?.is_favorite)
										}}>
										<Box marginRight="10px" marginTop="5px">
											<Avatar variant="rounded" src={buildImagePath(tip.image_path ?? '')} />
										</Box>
										<Box
											maxWidth="150px"
											style={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden'
											}}>
											<Typography variant="caption">
												<b>{tip.title}</b>
											</Typography>
										</Box>
									</Box>
								))}
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Assets</b>
							</Typography>
							{!!(lesson?.lesson_plan_asset.length ?? 0 > 0) &&
								lesson.lesson_plan_asset.map(({ asset }) => (
									<Box key={asset.asset_id} display="flex" alignItems="center" marginTop="5px">
										<Box marginRight="15px">
											<ActionButton
												onClick={() => {
													const link = document.createElement('a')
													link.href = `${buildImagePath(asset.resource_path)}`
													link.target = '_blank'
													// Append to html link element page
													document.body.appendChild(link)
													// Start download
													link.click()
													// Clean up and remove the link
													link.parentNode?.removeChild(link)
												}}
												style={{
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden'
												}}
												color="secondary"
												icon={<VerticalAlignBottomIcon />}
											/>
										</Box>
										<Box
											maxWidth="150px"
											style={{
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
												overflow: 'hidden'
											}}>
											<Typography variant="caption">
												<b>{asset.name}</b>
											</Typography>
										</Box>
									</Box>
								))}
						</Box>
						<Divider />
					</Paper>
				</Grid>
			</Grid>

			<StandardSideBar lesson={lessonStandard} onClose={() => setLessonStandard(undefined)} />
			<ModulesDialog
				lessonPlanId={lesson?.lesson_plan_id ?? 0}
				isOpen={isModulesDialogOpen}
				setIsOpen={setIsModulesDialogOpen}></ModulesDialog>
			<RemoveFavoriteDialog
				itemName="Lesson"
				pageItemName={Pages.Lessons.name}
				removeItemFn={removeLessonFn}
				setRemoveItemFn={setRemoveLessonFn}
				dontAsk={dontAsk}
				setDontAsk={setDontAsk}
			/>
			<DeleteItemDialog
				itemName="Lesson"
				isOpen={!!isDeleteDialogOpen}
				setIsOpen={setIsDeleteDialogOpen}
				onConfirm={async () => {
					removeLesson()
				}}
				onDone={goToLessons}
			/>
			<SongPlayer
				songId={selectedSongId}
				setSongId={setSelectedSongId}
				viewDetails={viewSongDetails}
				setViewDetails={setViewSongDetails}
				loading={loadingAddSong || loadingDelSong}
				isFavorite={isSongFavorite}
				handleFavoriteFn={handleSongFavorite}
				dontAsk={!!teacher?.teacher_setting?.favorites_dont_ask_again_to_delete}
				allTranspositions
			/>
			<VideoPlayer
				video={selectedVideo}
				setVideo={setSelectedVideo}
				loading={loadingAddVideo || loadingDelVideo}
				favoriteVideo={isVideoFavorite}
				handleFavoriteFn={handleFavoriteVideo}
				dontAsk={!!teacher?.teacher_setting?.favorites_dont_ask_again_to_delete}
			/>
		</Box>
	)
}
