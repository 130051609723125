import { Box, FormControlLabel, Radio, RadioGroup, styled, TextField, Typography } from '@material-ui/core'
import { Privacy_Enum, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { SelectWurrlyThumbnail } from 'src/scenes/Student/scenes/StudentRecord/components/SelectThumbnail/SelectThumbnail'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'

import { MAX_CAPTION_LENGTH } from '../../constants'

export const VideoSubmit = () => {
	const { caption, setCaption, privacySetting, setPrivacySetting, isVideoOn, wurrlyType } =
		useVideoSubmitContext()

	return (
		<SubmitContainer>
			<StyledHeader>Submit</StyledHeader>
			<FieldTitle>Add caption</FieldTitle>
			<CaptionContainer>
				<StyledInput
					variant="outlined"
					multiline
					value={caption}
					onChange={(e) => setCaption(e.target.value)}
					error={caption.length > MAX_CAPTION_LENGTH}
				/>
				<CharCount>
					{caption.length}/{MAX_CAPTION_LENGTH}
				</CharCount>
			</CaptionContainer>

			{isVideoOn && (
				<>
					<FieldTitle>Select Cover Image</FieldTitle>
					<SelectWurrlyThumbnail />
				</>
			)}

			<FieldTitle>Privacy Settings*</FieldTitle>
			<StyledRadioGroup
				value={privacySetting}
				onChange={(event) => setPrivacySetting(event.target.value as Privacy_Enum)}>
				{wurrlyType !== Wurrly_Type_Enum.Assignment && (
					<FormControlLabel value={Privacy_Enum.OnlyMe} control={<Radio />} label="Only Me" />
				)}
				<FormControlLabel value={Privacy_Enum.TeacherAndMe} control={<Radio />} label="Teacher & Me" />
				<FormControlLabel value={Privacy_Enum.Class} control={<Radio />} label="Class" />
			</StyledRadioGroup>
		</SubmitContainer>
	)
}

const SubmitContainer = styled(Box)({
	padding: '40px 40px 20px'
})

const StyledHeader = styled(Typography)({
	fontWeight: 500,
	fontSize: '48px',
	padding: 0
})

const FieldTitle = styled(Typography)({
	color: '#000000DE',
	fontWeight: 500,
	margin: '1.5em 0'
})

const StyledInput = styled(TextField)({
	width: '100%',
	'& .MuiOutlinedInput-root': {
		borderRadius: '8px'
	}
})
const CaptionContainer = styled(Box)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column'
})
const CharCount = styled(Typography)({
	color: '#616877',
	fontSize: '18px',
	alignSelf: 'flex-end',
	paddingTop: 13
})
const StyledRadioGroup = styled(RadioGroup)({
	color: '#1D273D',
	'& .MuiTypography-root': {
		opacity: 0.7
	}
})
