import { useState } from 'react'

import { useFormik } from 'formik'
import { useUpdateStudentInformationMutation } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import * as yup from 'yup'

import { buildGenericContext } from '../../../../../../context/genericContext'

type formData = {
	studentId: number
	firstName: string
	lastName: string
	stageName: string
}

const useEditProfileData = () => {
	const { studentData } = useLoginContext()
	const [updateError, setUpdateError] = useState(false)
	const [updateSuccess, setUpdateSuccess] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const [updateInfo, { loading: isLoading }] = useUpdateStudentInformationMutation({ fetchPolicy: 'no-cache' })

	const saveChanges = async (filter: formData) => {
		try {
			const { data } = await updateInfo({
				variables: filter,
				update: (cache) => {
					const identify = cache.identify(studentData)
					cache.evict({ id: identify, fieldName: 'student_settings' })
				}
			})
			const updated = !!data?.update_student
			setUpdateSuccess(updated)
			setUpdateError(!updated)
		} catch (error) {
			console.error(error)
			setErrorMessage(error.message ?? 'Internal error.')
			setUpdateError(true)
		}
	}

	const validationSchema = yup.object({
		firstName: yup.string().required('FirstName is required'),
		lastName: yup.string().required('LastName is required'),
		stageName: yup.string().required('StageName is required')
	})

	const formik = useFormik({
		initialValues: {
			studentId: studentData.student_id,
			firstName: studentData.first_name ?? '',
			lastName: studentData.last_name ?? '',
			stageName: studentData.stage_name ?? ''
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async (variables, { resetForm }) => {
			saveChanges(variables)
			resetForm()
		}
	})

	return {
		saveChanges,
		updateError,
		setUpdateError,
		errorMessage,
		updateSuccess,
		isLoading,
		formik
	}
}

export const [EditProfileProvider, useEditProfileContext] = buildGenericContext(useEditProfileData)
