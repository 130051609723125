import { Box, Typography } from '@material-ui/core'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import { ActionButton } from 'src/components/Buttons/ActionButton'
import { Asset } from 'src/graphql/autogenerate/schemas'

import useStyles from '../ListItem.styles'

export type AssetForListItem = Pick<Asset, 'asset_id' | 'name' | 'resource_path'>

type ListAssetItemProps = {
	asset: AssetForListItem
	onClick: (asset: AssetForListItem) => void
}
export const ListAssetItem = ({ asset, onClick }: ListAssetItemProps) => {
	const styles = useStyles()
	const handleClick = () => onClick(asset)

	return (
		<Box className={styles.container} onClick={handleClick}>
			<ActionButton color="secondary" icon={<VerticalAlignBottomIcon />} />
			<Box className={styles.info}>
				<Typography variant="caption">
					<strong>{asset.name}</strong>
				</Typography>
			</Box>
		</Box>
	)
}
