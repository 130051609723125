import { useEffect, useState } from 'react'

import { useGetClassNameLazyQuery, useGetSongByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, buildSearchText } from 'src/utils'

export const useClassSongsTitle = () => {
	const { classId } = useWurrlyParams()

	const [className, setClassName] = useState('')
	const [selectedSongs, setSelectedSongs] = useState<Song[]>([])
	const [showSongsDialog, setShowSongsDialog] = useState(false)
	const [getSelectedSongs, { data: selectedSongsData }] = useGetSongByClassIdLazyQuery()
	const [getClass, { data }] = useGetClassNameLazyQuery()

	const handleCancelSongs = () => {
		setShowSongsDialog(false)
	}

	const handleOpenSelectProductDialog = () => setShowSongsDialog(true)

	const breadCrumbs = buildBreadCrumbs({
		page: Pages.ClassSongs,
		replaceIndexes: [
			{
				index: 1,
				overrideName: className
			},
			{
				index: 2,
				overrideName: 'Songs'
			}
		],
		params: { classId: Number(classId) }
	})

	useEffect(() => {
		if (selectedSongsData) setSelectedSongs(selectedSongsData.song.map((item) => item) as Song[])
	}, [selectedSongsData])

	useEffect(() => {
		getClass({
			variables: {
				classId: Number(classId)
			}
		})
		getSelectedSongs({
			variables: {
				classId: Number(classId),
				search: buildSearchText('', ['title'])
			}
		})
	}, [])

	useEffect(() => {
		if (data) {
			setClassName(data.class_by_pk?.title || '')
		}
	}, [data])

	return {
		breadCrumbs,
		handleCancelSongs,
		handleOpenSelectProductDialog,
		showSongsDialog,
		selectedSongs,
		setShowSongsDialog,
		setSelectedSongs
	}
}
