import { LoginPage } from '../scenes/Login/scenes/1-TeacherLogin/LoginPage'
import { StudentSignInOptionsPage } from '../scenes/Login/scenes/2-StudentLogin/scenes/SignInOptions/StudentSigInOptionsPage'

export const LoginPages = {
	Teacher: {
		name: 'Login',
		path: '/login/teacher',
		component: LoginPage,
		params: []
	},
	Student: {
		name: 'Login',
		path: '/login/student',
		component: StudentSignInOptionsPage,
		params: []
	}
}
