import { useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import {
	useDeleteLessonMutation,
	useDeleteTeacherSongFavoriteMutation,
	useGetClassTeacherByPkLazyQuery,
	useGetLessonPdfLazyQuery,
	useGetLessonsByPkQuery,
	useGetPartnerTitleQuery,
	useInsertLessonToClassesByLessonPkMutation,
	useInsertTeacherSongFavoriteMutation,
	useGetModuleByPkQuery
} from 'src/graphql/autogenerate/hooks'
import { Class, Lesson_Plan, Lesson_Plan_Grade, Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { mutationDeleteFavorite, mutationInsertFavorite } from 'src/scenes/Teacher/queries'
import { BASE_URL, buildRouteParameters, TypeEnum } from 'src/utils'
import { buildBreadCrumbs, slugToName, sortGradeLevels } from 'src/utils/utils'

export const useLessonPage = ({ page, teacherId }: PagesProps) => {
	const history = useHistory()
	const params = useWurrlyParams<typeof Pages.ClassLessonDetail.params[number]>()
	const classId = Number(params.classId)
	const lessonPlanId = Number(params.lessonPlanId)
	const [showAddToClassPopUp, setShowAddToClassPopUp] = useState<number | undefined>(0)
	const { teacherData: teacher } = useLoginContext()
	const [removeLessonFn, setRemoveLessonFn] = useState<() => Promise<void>>()
	const [dontAsk, setDontAsk] = useState(false)
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<number | undefined>(0)
	const [isModulesDialogOpen, setIsModulesDialogOpen] = useState<boolean>(false)
	const [deleteLessonMutation] = useDeleteLessonMutation()
	const [insertLessonToClassesMutation] = useInsertLessonToClassesByLessonPkMutation()
	const hasAssignment = false // TODO get assignments from the lesson
	const [sortedLevels, setSortedLevels] = useState<Lesson_Plan_Grade[]>([])
	const [lessonStandard, setLessonStandard] = useState<Lesson_Plan>()
	const [moduleCount, setModuleCount] = useState<number>(0)
	const { data: moduleData } = useGetModuleByPkQuery({
		variables: {
			moduleId: params.moduleId,
			teacherId,
			classId
		}
	})

	// request partner title if necessary
	const { data: partnerData } = useGetPartnerTitleQuery({
		skip: !params.partnerId,
		variables: { partner_id: params.partnerId }
	})

	const [addFavoriteLesson, { loading: loadingAdd, error: errorAdd }] = useMutation(
		mutationInsertFavorite(TypeEnum.Lesson)
	)
	const [deleteFavoriteLesson, { loading: loadingDel, error: errorDel }] = useMutation(
		mutationDeleteFavorite(TypeEnum.Lesson)
	)
	const [loadLessonPDF, { loading: loadingLessonPDF, data: lessonPDF }] = useGetLessonPdfLazyQuery()

	const [selectedSongId, setSelectedSongId] = useState<number | undefined>()
	const [viewSongDetails, setViewSongDetails] = useState(true)
	const [isSongFavorite, setIsSongFavorite] = useState(false)
	const [selectedVideo, setSelectedVideo] = useState<Tip | undefined>()
	const [isVideoFavorite, setIsVideoFavorite] = useState(false)
	const [addFavoriteSong, { loading: loadingAddSong }] = useInsertTeacherSongFavoriteMutation()
	const [deleteFavoriteSong, { loading: loadingDelSong }] = useDeleteTeacherSongFavoriteMutation()
	const [saveFavoriteVideo, { loading: loadingAddVideo }] = useMutation(mutationInsertFavorite(TypeEnum.Video))
	const [deleteFavoriteVideo, { loading: loadingDelVideo }] = useMutation(mutationDeleteFavorite(TypeEnum.Video))

	const [getClassTeacherByPk, { data: classData }] = useGetClassTeacherByPkLazyQuery()
	const { data: lessonQuery, loading } = useGetLessonsByPkQuery({
		variables: { lessonPlanId, teacherId: teacher.teacher_id }
	})

	const addedClasses = (lessonQuery?.lesson_plan_by_pk?.class_lesson_plans || []).map(
		(item) => item.class
	) as Class[]

	useEffect(() => {
		setModuleCount(lessonQuery?.lesson_plan_by_pk?.module_lesson_plans_aggregate.aggregate?.count || 0)
	}, [lessonQuery])

	useEffect(() => {
		getClassTeacherByPk({
			variables: { teacherId: teacher.teacher_id, classId }
		})
	}, [])

	const lesson = lessonQuery?.lesson_plan_by_pk as Lesson_Plan
	const [isFavorite, setIsFavorite] = useState(!!lesson?.is_favorite) // !!lesson.is_favorite

	const removeLesson = () => {
		if (lesson?.lesson_plan_id === undefined) return
		deleteLessonMutation({
			variables: {
				lessonPlanId: lesson?.lesson_plan_id,
				teacherId: teacher.teacher_id
			},
			update: (cache, { data }) => {
				const lessonToUpdateInCache = data?.delete_lesson_plan?.returning[0]
				if (!lessonToUpdateInCache) return
				// update class lesson plans
				const identify = cache.identify(lessonToUpdateInCache)
				cache.evict({
					id: identify
				})
			}
		})
		history.push(buildRouteParameters(Pages.Lessons))
	}
	const addLessonToClasses = (classes: Class[], lessonPlanId: number): void => {
		const mutationObject = classes.map((item: Class) => {
			return {
				class_id: item.class_id,
				lesson_plan_id: lessonPlanId
			}
		})
		insertLessonToClassesMutation({
			variables: { classes: mutationObject },
			update: (cache) => {
				classes.forEach((i) => {
					const identity = cache.identify(i)
					cache.evict({
						id: identity,
						fieldName: 'class_lesson_plans'
					})
					cache.evict({
						id: identity,
						fieldName: 'class_lesson_plans_aggregate'
					})
				})
			}
		})
	}

	const lessonSlugName = slugToName(lessonQuery?.lesson_plan_by_pk?.title || '')
	const module = moduleData?.module[0]?.title
	const getBreadReplaceIndexes = useMemo(() => {
		if (partnerData?.partner_by_pk && module) {
			return [
				{
					index: 1,
					overrideName: 'Partners'
				},
				{
					index: 2,
					overrideName: slugToName(partnerData?.partner_by_pk?.title)
				},
				{
					index: 3,
					overrideName: slugToName(module)
				},
				{
					index: 4,
					overrideName: lessonSlugName
				}
			]
		}

		if (classId) {
			return [
				{
					index: 1,
					overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
				},
				{
					index: 2,
					overrideName: 'Lessons'
				},
				{
					index: 3,
					overrideName: lessonSlugName
				}
			]
		}

		if (module) {
			return [
				{
					index: 1,
					overrideName: 'Modules'
				},
				{
					index: 2,
					overrideName: slugToName(module)
				},
				{
					index: 3,
					overrideName: lessonSlugName
				}
			]
		}

		return [
			{
				index: 1,
				overrideName: lessonSlugName
			}
		]
	}, [module, classId, partnerData])

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: getBreadReplaceIndexes,
		params
	})

	const removeFavorite = async () => {
		setRemoveLessonFn(undefined) // close Remove Favorited Dialog
		await deleteFavoriteLesson({
			variables: { teacherId, itemId: lesson?.lesson_plan_id }
		})

		if (!errorDel && !loadingDel) setIsFavorite(false)
	}

	const addFavorite = async () => {
		await addFavoriteLesson({
			variables: { teacherId, itemId: lesson?.lesson_plan_id }
		})

		if (!errorAdd && !loadingAdd) setIsFavorite(true)
	}

	const handleFavorite = (isFavorite: boolean, noDialog: boolean) => {
		if (isFavorite) {
			if (noDialog) removeFavorite()
			else setRemoveLessonFn(() => removeFavorite)
		} else addFavorite()
	}

	const goToEdit = () => {
		const newUrl = buildRouteParameters(Pages.LessonEdit, { lessonPlanId })
		history.push(newUrl)
	}

	const sortAndSetLevels = (levels: Lesson_Plan_Grade[]) => {
		const levelsCopy = [...levels]
		sortGradeLevels(levelsCopy)
		setSortedLevels(levelsCopy)
	}

	const handleSongFavorite = (songIsFavorite: boolean) => {
		if (songIsFavorite) removeSongFavorite()
		else addSongFavorite()
	}

	const removeSongFavorite = async () => {
		try {
			await deleteFavoriteSong({
				variables: { teacherId, songId: selectedSongId ?? 0 },
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_song_favorite'
					})
				}
			})
		} catch (error) {
			setIsSongFavorite(false)
		}
	}
	const addSongFavorite = async () => {
		try {
			await addFavoriteSong({
				variables: { teacherId, songId: selectedSongId },
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_songs_favorites'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_song_favorite'
					})
				}
			})
			setIsSongFavorite(true)
		} catch (error) {
			console.error('Could not favorite song')
		}
	}
	const removeFavoriteVideo = async () => {
		try {
			await deleteFavoriteVideo({
				variables: { teacherId, itemId: selectedVideo?.tip_id ?? 0 },
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_songs_favorites'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_tip_favorite'
					})
				}
			})
			setIsVideoFavorite(false)
		} catch (error) {
			console.error('Could not remove video from favorite', error)
		}
	}
	const addFavoriteVideo = async () => {
		try {
			await saveFavoriteVideo({
				variables: { teacherId, itemId: selectedVideo?.tip_id ?? 0 },
				update: (cache) => {
					const identify = cache.identify(teacher)
					cache.evict({
						id: identify,
						fieldName: 'teacher_songs_favorites'
					})
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'teacher_tip_favorite'
					})
				}
			})
			setIsVideoFavorite(true)
		} catch (error) {
			console.error('Could not favorite video', error)
		}
	}

	const handleFavoriteVideo = () => {
		if (selectedVideo) {
			if (isVideoFavorite) removeFavoriteVideo()
			else addFavoriteVideo()
		}
	}

	useEffect(() => {
		if (!loadingLessonPDF && lessonPDF && lessonPDF.lessonPlanPDF) {
			const link = document.createElement('a')
			link.href = `${BASE_URL}${lessonPDF.lessonPlanPDF.path}`
			link.target = '_blank'
			// Append to html link element page
			document.body.appendChild(link)
			// Start download
			link.click()
			// Clean up and remove the link
			link.parentNode?.removeChild(link)
		}
	}, [lessonPDF])
	useEffect(() => {
		setIsFavorite(!!lesson?.is_favorite)
		if (lesson?.lesson_plan_grade) sortAndSetLevels(lesson.lesson_plan_grade)
	}, [lesson])

	const goToLessons = () => {
		history.push(buildRouteParameters(Pages.Lessons))
	}

	return {
		lesson,
		loadingLessonPDF,
		loadingAdd,
		loadingDel,
		isFavorite,
		removeLessonFn,
		goToEdit,
		handleFavorite,
		sortedLevels,
		selectedSongId,
		setSelectedSongId,
		viewSongDetails,
		setViewSongDetails,
		isSongFavorite,
		handleSongFavorite,
		selectedVideo,
		setSelectedVideo,
		isVideoFavorite,
		handleFavoriteVideo,
		loadingAddSong,
		loadingDelSong,
		loadingAddVideo,
		loadingDelVideo,
		loading,
		breadcrumbs,
		dontAsk,
		setLessonStandard,
		loadLessonPDF,
		classId,
		hasAssignment,
		lessonPlanId,
		showAddToClassPopUp,
		setShowAddToClassPopUp,
		addedClasses,
		addLessonToClasses,
		teacher,
		setIsDeleteDialogOpen,
		setIsSongFavorite,
		setIsVideoFavorite,
		lessonStandard,
		setRemoveLessonFn,
		setDontAsk,
		isDeleteDialogOpen,
		removeLesson,
		goToLessons,
		isModulesDialogOpen,
		setIsModulesDialogOpen,
		moduleCount
	}
}
