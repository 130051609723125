import { useRef, useState } from 'react'

import {
	Grid,
	Switch,
	Typography,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	useTheme
} from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { capitalize } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Page } from 'src/@types'
import { BreadcrumbsArrayType } from 'src/@types/types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons'
import { ListSongs, MainTitle, SelectChord, SelectSort } from 'src/components'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { useGetScalesQuery } from 'src/graphql/autogenerate/hooks'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useScroll } from 'src/hooks/useScroll'
import { Pages } from 'src/routes/teacherPages'
import { useGetSongs } from 'src/scenes/Teacher/scenes/7-MusicCatalog/hooks/useGetSongs'
import { buildBreadCrumbs, getCyKey, TypeEnum } from 'src/utils'

import { FilterNameEnum } from '../../../../../Student/scenes/3-MusicCatalog/scenes/enums'
import { useCatalogFilters } from '../../../6-Lessons/hooks/useCatalogFilters'
import { LocationState } from '../../types'

type MusicCatalogBodyProps = {
	teacherId: number
	page?: Page
	breadcrumbs: BreadcrumbsArrayType
	title: string
	catalogId?: number
	catalogItemId?: number
	setTitle?: (title: string) => void
	setBreadcrumbs?: (breadcrumbs: BreadcrumbsArrayType) => void
}

export const MusicCatalogBody = ({
	teacherId,
	page,
	breadcrumbs,
	title,
	catalogId,
	catalogItemId,
	setTitle,
	setBreadcrumbs
}: MusicCatalogBodyProps) => {
	const [transpose, setTranspose] = useState(false)
	const theme = useTheme()
	const [scale, setScale] = useState(0)
	const { data: scales } = useGetScalesQuery()

	const location = useLocation()

	const state = location.state as LocationState
	const search = (state?.search as string) || ''

	const stateChords = (state?.chords as string[]) || []

	const [selectedChords, setSelectedChords] = useState<string[]>(stateChords || [])

	const [vocalGuide, setVocalGuide] = useState(false)
	const [chordGuide, setChordGuide] = useState(!!selectedChords?.length)

	const [featured, setFeatured] = useState(false)
	const [notFeatured, setNotFeatured] = useState(false)

	const { filters, handleCheck, selectedFilters } = useCatalogFilters({
		catalog_id: catalogId,
		catalogItemId,
		product: TypeEnum.Song
	})

	const IsAnyFilterSelected = !!selectedFilters.length || chordGuide || vocalGuide || featured || notFeatured
	const {
		songs: loadSongsCatalogData,
		isFetching,
		moreResults,
		loading,
		count,
		onSearch,
		onSearchEnter,
		searchBarTextState: searchbarText,
		sort,
		handleSort,
		loadSearchSongsCatalogData
	} = useGetSongs({
		page,
		teacherId,
		catalogItemId,
		chords: selectedChords,
		transpose,
		scaleId: scale,
		vocalGuide,
		chordGuide,
		featured,
		notFeatured,
		catalogFilters: selectedFilters
	})

	useScroll()
	const scrollRef = useRef(null)

	const handleScale = (scale_id: number) => {
		if (scale_id === scale) {
			setScale(0)

			return
		}
		setScale(+scale_id)
	}

	const handleChord = (chords: string[]) => {
		setSelectedChords(chords)
	}

	const handleTranspose = () => {
		setTranspose((prev) => !prev)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={title}
				description="Inspire your students with music they'll love. Find song, add it to your class & share it with your students."
				rightActions={[
					{
						variant: 'outlined',
						style: { border: '1px solid rgba(50, 51, 66, 0.5)' },
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.SongUpload.path
					}
				]}
			/>

			<SearchBar
				placeholder="Find songs by Artist or Song Title"
				onChange={(e) => {
					onSearch(e.target.value)
					if (e.target.value === '') {
						setTitle?.('Songs')
						setBreadcrumbs?.(
							buildBreadCrumbs({
								page: page as Page,
								replaceIndexes: [
									{
										index: 1,
										overrideName: 'All Songs'
									}
								]
							})
						)
					}
				}}
				onEnterPressed={(e) => {
					if (e !== '') {
						setTitle?.('Song Search Results')
						setBreadcrumbs?.(
							buildBreadCrumbs({
								page: page as Page,
								replaceIndexes: [
									{
										index: 1,
										overrideName: 'Song Search Results'
									}
								]
							})
						)
					}

					onSearchEnter(e)
				}}
				initialValue={searchbarText}
				loading={loading}
				dropdownItems={loadSearchSongsCatalogData?.data?.song?.map((el, key) => {
					return (
						<Box key={key}>
							<ListSongs
								returnItemAlone
								ref={scrollRef}
								teacherId={teacherId}
								selectedChords={selectedChords}
								allTranspositions={transpose}
								lessonsButton
								spacing={1.5}
								listItem={el as Song}
								listItemNumber={key}
								data={[]}
							/>
						</Box>
					)
				})}
			/>
			{!search && (
				<Box>
					<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Browse by Chords</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<FormControlLabel
							control={
								<Switch
									data-cy={getCyKey(MusicCatalogBody, 'ShowTransposedResults')}
									checked={transpose}
									onChange={handleTranspose}
									name="checkedA"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
								/>
							}
							label={
								<Typography
									style={{
										fontWeight: 'bold',
										color: !transpose ? theme.palette.grey[500] : theme.palette.grey[600]
									}}>
									Show Transposed Results
								</Typography>
							}
						/>
					</Box>
					<SelectChord selectedChords={selectedChords} onChange={handleChord} />
				</Box>
			)}

			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12} md={9}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b>Songs ({count})</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						{count !== 0 && <SelectSort cyKey="Songs" value={sort} onChange={handleSort} />}
					</Box>
				</Grid>
				<Grid item xs={12} md></Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					{isFetching || !loading ? (
						<ListSongs
							ref={scrollRef}
							teacherId={teacherId}
							selectedChords={selectedChords}
							allTranspositions={transpose}
							lessonsButton
							spacing={1.5}
							data={loadSongsCatalogData as Song[]}
						/>
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{isFetching && moreResults && (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{!loading && loadSongsCatalogData.length > 0 && !moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)}
					{!loading && loadSongsCatalogData.length <= 0 && (
						<div>
							<Box my={4}>
								<Typography variant="h6" color="textSecondary">
									{search && !IsAnyFilterSelected
										? `Your search for "${search}" did not return any results.`
										: 'No results found'}
								</Typography>
								<Typography color="textSecondary">
									{search && !IsAnyFilterSelected
										? `Remember it's easy to upload your own by clicking the button on the top right!`
										: 'Your filter selection did not return any results'}
								</Typography>
								<Typography color="textSecondary">
									{IsAnyFilterSelected &&
										'Try removing a filter or upload own by clicking the button top right'}
								</Typography>
							</Box>
						</div>
					)}
				</Grid>

				<Grid item xs={12} md>
					<Paper>
						<Box py={2} px={3}>
							<Typography
								variant="h6"
								style={{
									fontSize: 18
								}}>
								<b>Filters</b>
							</Typography>
						</Box>
						<Divider />
						<Box py={1} px={3}>
							<List key={`filter-guide`} dense>
								<Typography
									variant="h6"
									style={{
										fontSize: 18
									}}>
									<b>Guides</b>
								</Typography>
								<ListItem button disableGutters onClick={() => setVocalGuide(!vocalGuide)}>
									<ListItemIcon
										style={{
											minWidth: 'auto'
										}}>
										<Checkbox
											data-cy={getCyKey(MusicCatalogBody, 'WithVocalGuide')}
											edge="start"
											checked={vocalGuide}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'With Vocal Guide'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="vocalGuide" primary={'With Vocal Guide'} />
								</ListItem>
								<ListItem
									button
									disableGutters
									onClick={() => {
										if (selectedChords.length === 0) {
											setChordGuide(!chordGuide)
										}
									}}>
									<ListItemIcon
										style={{
											minWidth: 'auto'
										}}>
										<Checkbox
											data-cy={getCyKey(MusicCatalogBody, 'WithChordGuide')}
											edge="start"
											checked={selectedChords.length > 0 || chordGuide}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'With Chord Guide'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="chordGuide" primary={'With Chord Guide'} />
								</ListItem>
							</List>

							<List key={`filter-guide`} dense>
								<Typography
									variant="h6"
									style={{
										fontSize: 18
									}}>
									<b>General</b>
								</Typography>
								<ListItem button disableGutters onClick={() => setFeatured(!featured)}>
									<ListItemIcon
										style={{
											minWidth: 'auto'
										}}>
										<Checkbox
											edge="start"
											checked={featured}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'Featured'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="featured" primary={'Featured'} />
								</ListItem>
								<ListItem
									button
									disableGutters
									onClick={() => {
										setNotFeatured(!notFeatured)
									}}>
									<ListItemIcon
										style={{
											minWidth: 'auto'
										}}>
										<Checkbox
											edge="start"
											checked={notFeatured}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'Not Featured'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="notFeatured" primary={'Not Featured'} />
								</ListItem>
							</List>

							{filters.map((filter, indexFilter) => (
								<List key={`filter-${indexFilter}`} dense>
									<Typography
										variant="h6"
										style={{
											fontSize: 18
										}}>
										<b>{filter.name}</b>
									</Typography>
									{filter.items.map((item, indexItem) => (
										<ListItem
											key={item.id}
											button
											disableGutters
											onClick={() => handleCheck(indexFilter, indexItem)}>
											<ListItemIcon
												style={{
													minWidth: 'auto'
												}}>
												<Checkbox
													data-cy={getCyKey(MusicCatalogBody, item.label)}
													edge="start"
													checked={selectedFilters.includes(item.id)}
													checkedIcon={<CheckBoxOutlinedIcon />}
													tabIndex={-1}
													disableRipple
													inputProps={{
														'aria-labelledby': item.label
													}}
												/>
											</ListItemIcon>
											<ListItemText id={item.id.toString()} primary={item.label} />
										</ListItem>
									))}
								</List>
							))}
							<List dense>
								<Typography
									variant="h6"
									style={{
										fontSize: 18
									}}>
									<b>{FilterNameEnum.Modes}</b>
								</Typography>
								{scales?.scale?.map((item) => (
									<ListItem
										onClick={() => handleScale(item.scale_id)}
										// value={scale}
										key={item.scale_id}
										button
										disableGutters>
										<ListItemIcon
											style={{
												minWidth: 'auto'
											}}>
											<Checkbox
												edge="start"
												checked={scale === item.scale_id}
												checkedIcon={<CheckBoxOutlinedIcon />}
												tabIndex={-1}
												disableRipple
												inputProps={{
													'aria-labelledby': 'Featured'
												}}
											/>
										</ListItemIcon>
										<ListItemText
											id={`${capitalize(item.name)}`}
											primary={`${capitalize(item.name)}`}
										/>
									</ListItem>
								))}
							</List>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}
