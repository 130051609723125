import { useState } from 'react'

import { buildGenericContext } from '../../../../../../../../context/genericContext'
import { useStudentSignInOptionsContext } from '../../useStudentSignInOptions'

const useAvatarSelectionDialog = () => {
	const [stageName, setStageName] = useState<string>('')
	const { handleClose } = useStudentSignInOptionsContext()

	return {
		handleClose,
		stageName,
		setStageName
	}
}

export const [AvatarSelectionDialogProvider, useAvatarSelectionDialogContext] =
	buildGenericContext(useAvatarSelectionDialog)
