import { Page } from 'src/@types'
import { environment } from 'src/environment'

/**
 *
 * @param originalRoute page go to
 * @param replaces dictionary with key (parameter name) value (string or number)
 * @param queryParams dictionary with key (parameter name) value (string or number) to append as query parameters
 * @example originalRoute = 'teacher/classes/:classId/details', replaces = { classId: 1 }
			returns 'teacher/classes/1/details'
 */
export const buildRouteParameters = (
	originalRoute: Page,
	replaces: Record<string, string | number> = {},
	strictReplace = true,
	queryParams?: Record<string, string | number>,
	from?: string
) => {
	let finalRoute = originalRoute.path
	for (const key in replaces) {
		if (
			Object.prototype.hasOwnProperty.call(replaces, key) &&
			originalRoute.path.includes(key) &&
			originalRoute.params?.includes(key)
		) {
			const value = replaces[key]?.toString() || ''
			finalRoute = finalRoute.replace(`:${key}`, value)
		} else {
			if (strictReplace) throw new Error(`${originalRoute.path} doesn't include the key ${key}`)
		}
	}

	if (queryParams) {
		const queryString = Object.entries(queryParams)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')
		finalRoute = `${finalRoute}?${queryString}`
	}

	if (from) {
		finalRoute = `${finalRoute}?from=${encodeURIComponent(from)}`
	}

	return finalRoute
}

export const buildAssetUrl = (assetPath: string) => {
	return new URL(assetPath, environment.s3Bucket).toString()
}
