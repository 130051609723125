import React, { useEffect } from 'react'

import { Box } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2)
		}
	}
}))
type AlertType = {
	show: boolean
	severity: 'success' | 'info' | 'warning' | 'error' | undefined
	children?: React.ReactNode
	handleClose?: () => void
}
export const BaseAlert = ({ show = false, severity, children, handleClose }: AlertType) => {
	const classes = useStyles()
	const [open, setOpen] = React.useState(show)

	useEffect(() => {
		setOpen(show)
	}, [show])

	return (
		<Box className={classes.root}>
			<Collapse in={open}>
				<Alert
					severity={severity}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpen(false)
								if (handleClose) handleClose()
							}}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}>
					{children}
				</Alert>
			</Collapse>
		</Box>
	)
}
