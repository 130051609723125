import React from 'react'

import { Box, Checkbox, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

export type AddStudentsUnder12TableHeader = {
	id: keyof Columns
	label: string
}
type Columns = {
	studentName: string
	parentEmail: string
}
type AddStudentsUnder12TableHaderProps = {
	headerCells: AddStudentsUnder12TableHeader[]
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	rowCount: number
	selectedCount: number
}

export const AddStudentsUnder12EnhancedTableHeader = ({
	headerCells,
	onSelectAllClick,
	rowCount,
	selectedCount
}: AddStudentsUnder12TableHaderProps) => {
	return (
		<TableHead>
			<TableRow>
				<TableCell>
					<Checkbox
						indeterminate={selectedCount > 0 && selectedCount < rowCount}
						checked={rowCount > 0 && selectedCount === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headerCells.map((headerCell) => (
					<TableCell key={headerCell.id}>
						<Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="left">
							<Typography noWrap>
								<b>{headerCell.label}</b>
							</Typography>
						</Box>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	)
}
