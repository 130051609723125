import { Fragment, useState, useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'
import { MusicNote, QueueMusic } from '@material-ui/icons'
import { Elements } from '@stripe/react-stripe-js'
import { ContactButton } from 'src/components/Buttons/ContactButton/ContactButton'
import { useStyles } from 'src/components/Dialogs/ListDialog.style'

import { ListDialog } from '..'
import {
	useGetPartnerPriceLazyQuery,
	useGetModulePriceLazyQuery,
	useGetChannelPriceLazyQuery,
	useGetPlaylistPriceLazyQuery
} from '../../graphql/autogenerate/hooks'
import {
	Channel,
	Module,
	Partner,
	Playlist,
	Price_Type_Enum,
	Product_Name_Enum
} from '../../graphql/autogenerate/schemas'
import { capitalize, getPriceInDollars, STRIPE_PROMISE } from '../../utils'
import { BaseLoading } from '../Loading/BaseLoading'
import { ContactDialog } from './ContactDialog/ContactDialog'
import { PaymentDialogForProduct, SelectPeriodPaymentType } from './PaymentDialogForProduct'

type SelectPackageType = {
	productId: number
	productName: Product_Name_Enum
	onSuccess: () => void
	handleCloseProductDialog: () => void
}
type Option = {
	id: string
	name: JSX.Element
	icon: JSX.Element
}

export const SelectProductDialog = ({
	productId,
	productName,
	onSuccess,
	handleCloseProductDialog
}: SelectPackageType) => {
	const styles = useStyles()
	const [getPartners, { loading: loadingPartner }] = useGetPartnerPriceLazyQuery()
	const [getChannels, { loading: loadingChannel }] = useGetChannelPriceLazyQuery()
	const [getModules, { loading }] = useGetModulePriceLazyQuery()
	const [getPlaylists, { loading: loadingPlaylist }] = useGetPlaylistPriceLazyQuery()

	const [isPaymentOpen, setIsPaymentOpen] = useState<SelectPeriodPaymentType>()
	const [isContactUsOpen, setIsContactUsOpen] = useState(false)
	const [product, setProduct] = useState<Module | Partner | Channel | Playlist>()
	const [productNameDisplay, setProductNameDisplay] = useState('')
	const [options, setOptions] = useState<Option[]>([])
	const [isListOpen, setIsListOpen] = useState(false)

	const table = capitalize(productName)

	useEffect(() => {
		switch (productName) {
			case Product_Name_Enum.Module:
				getModules({
					variables: {
						productId
					}
				}).then(({ data }) => {
					setProduct(data?.module[0] as Module)
					setProductNameDisplay(data?.module[0]?.title || '')
				})
				break

			case Product_Name_Enum.Partner:
				getPartners({
					variables: {
						productId
					}
				}).then(({ data }) => {
					setProduct(data?.partner[0] as Partner)
					setProductNameDisplay(data?.partner[0]?.title || '')
				})
				break
			case Product_Name_Enum.Channel:
				getChannels({
					variables: {
						productId
					}
				}).then(({ data }) => {
					setProduct(data?.channel[0] as Channel)
					setProductNameDisplay(data?.channel[0]?.title || '')
				})
				break
			case Product_Name_Enum.Playlist:
				getPlaylists({
					variables: {
						productId
					}
				}).then(({ data }) => {
					setProduct(data?.playlist[0] as Playlist)
					setProductNameDisplay(data?.playlist[0]?.name || '')
				})
				break

			default:
				break
		}
	}, [productId])

	useEffect(() => {
		if (loadingPartner || loading || loadingChannel || loadingPlaylist) return
		if (!product) return

		setIsListOpen(true)
		setOptions([
			{
				id: String(product.monthly_price_stripe_price_id),
				name: (
					<Box>
						<Typography style={{ fontSize: 24 }}>
							${getPriceInDollars(product.monthly_price || 0)}
						</Typography>
						<Typography style={{ fontSize: 12 }}>Monthly</Typography>
					</Box>
				),
				icon: <MusicNote />
			},
			{
				id: String(product.annual_price_stripe_price_id),
				name: (
					<Box>
						<Typography style={{ fontSize: 24 }}>
							${getPriceInDollars(product.annual_price || 0)}
						</Typography>
						<Typography style={{ fontSize: 12 }}>Yearly</Typography>
					</Box>
				),
				icon: <QueueMusic />
			}
		])
	}, [loadingPartner, loading, loadingChannel, product])

	const handlePurchaseModalClose = () => {
		setIsListOpen(false)
		handleCloseProductDialog()
	}
	const handlePurchaseModalSelectOption = (id: string) => {
		setIsListOpen(false)
		setIsPaymentOpen({
			priceType:
				id === product?.monthly_price_stripe_price_id ? Price_Type_Enum.Monthly : Price_Type_Enum.Annual,
			price: Number(
				id === product?.monthly_price_stripe_price_id ? product?.monthly_price : product?.annual_price
			),
			productId,
			productName,
			stripeProductId: product?.stripe_product_id || '',
			stripePriceId: id,
			productNameDisplay
		})
	}

	const closeContactDialog = () => {
		setIsContactUsOpen(false)
		setIsListOpen(true)
	}

	const handleClosePaymentDialog = () => {
		setIsListOpen(false)
	}

	const handleGoBackPaymentDialog = () => {
		setIsListOpen(true)
		setIsPaymentOpen(undefined)
	}

	return (
		<Box>
			{(loading || loadingPartner) && <BaseLoading />}
			{!loading && !loadingPartner && options.length && (
				<Fragment>
					<ListDialog
						open={isListOpen}
						cols={2}
						onClose={handlePurchaseModalClose}
						title={`Purchase ${table}`}
						description={''}
						optionSelected={''}
						options={options}
						handleSelectOption={handlePurchaseModalSelectOption}
						cardHeight={160}
						footer={
							<Typography>
								Need a school or district quote? <ContactButton />
							</Typography>
						}>
						<Typography align="center" variant="body2" className={styles.description}>
							Unlock More Possibilities
							<br />
							Integrate diverse, dynamic curriculum content to elevate every student’s learning
							journey.
						</Typography>
					</ListDialog>
					<ContactDialog
						open={isContactUsOpen}
						onClose={closeContactDialog}
						onDiscard={closeContactDialog}
						closeDialog={closeContactDialog}
					/>
					<Elements stripe={STRIPE_PROMISE}>
						<PaymentDialogForProduct
							open={!!isPaymentOpen}
							close={handleClosePaymentDialog}
							selectedProduct={isPaymentOpen}
							onSuccess={onSuccess}
							onDiscard={handleGoBackPaymentDialog}
							table={table}
						/>
					</Elements>
				</Fragment>
			)}
		</Box>
	)
}
