import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useAssignmentCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			minHeight: 100,
			border: '1px solid rgba(0, 0, 0, 0.08)',
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 1px 4px 0px rgb(0 0 0 / 8%)',
			borderRadius: 4,
			// alignItems: 'flex-start',
			padding: 15,
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		avatar: {
			marginRight: 15
		},
		itemTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			fontSize: 18,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden'
		},
		itemInstructions: {
			WebkitLineClamp: 1,
			textOverflow: 'ellipsis',
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word',
			fontSize: 14,
			fontWeight: 'normal',
			padding: '5px 0',
			whiteSpace: 'pre-wrap'
		},
		itemDateExpired: {
			color: '#FF3D00 !important'
		},
		gradeStatus: {
			display: 'flex',
			alignItems: 'center',
			padding: '0 10px',
			borderRadius: 4
		},
		calendar: { marginRight: '5px', color: '#F3AB34', fontSize: 16 },
		graded: {
			color: '#00BA70',
			backgroundColor: 'rgba(0,186,112,0.2)'
		},
		startedGraded: {
			color: '#F3AB34',
			backgroundColor: 'rgba(243,171,52,0.2)'
		},
		notGraded: {
			color: '#7367A3',
			backgroundColor: 'rgba(115,103,163,0.2)'
		},

		grading: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			alignSelf: 'center'
		},
		gradeLabel: {
			fontSize: '1.2em',
			color: theme.palette.grey[500],
			marginRight: theme.spacing(1.5)
		}
	})
)

export default useAssignmentCardStyles
