import React, { useEffect, Fragment } from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useGetLessonsBySongLazyQuery } from 'src/graphql/autogenerate/hooks'

import { BaseDialog, BaseLoading, ListLessons } from '../../../../../../components'
import { Lesson_Plan } from '../../../../../../graphql/autogenerate/schemas'
import { Pages } from '../../../../../../routes/teacherPages'
import { buildPagination, buildQueryVariables, getCyKey } from '../../../../../../utils/utils'

type LessonsSongProps = {
	teacherId: number
	lessonSelectedSongId: number | undefined
	setLessonSelectedSongId: React.Dispatch<React.SetStateAction<number | undefined>>
	isVideo?: boolean
}

export const LessonsSong = ({
	teacherId,
	setLessonSelectedSongId,
	lessonSelectedSongId,
	isVideo
}: LessonsSongProps) => {
	const songId = lessonSelectedSongId
	const setSongId = setLessonSelectedSongId
	const history = useHistory()

	const [lessons, setLessons] = React.useState<Lesson_Plan[]>([])
	const [loadLessonsSong, lessonsSong] = useGetLessonsBySongLazyQuery(buildPagination())

	useEffect(() => {
		if (songId) {
			const querySongId = buildQueryVariables({
				songId: songId || null
			})
			loadLessonsSong(querySongId)
		}
	}, [songId, loadLessonsSong])

	useEffect(() => {
		if (!lessonsSong.loading) {
			setLessons((lessonsSong.data?.lesson_plan as Lesson_Plan[]) || [])
		}
	}, [lessonsSong])

	return (
		<BaseDialog
			dividers={false}
			data-cy={getCyKey(LessonsSong)}
			onClose={() => {
				setSongId(undefined)
			}}
			open={!!songId}
			dialogPaperStyles={{
				maxWidth: 700
			}}
			header={
				<Fragment>
					<Typography variant="h5" align="center" style={{ paddingBottom: 30 }}>
						<b>The {isVideo ? 'Video' : 'Song'} Lessons</b>
					</Typography>
					<Typography variant="subtitle1">
						<b>{`${lessons.length} Lessons`}</b>
					</Typography>
				</Fragment>
			}
			body={
				<Grid container style={{ paddingBottom: 30, paddingTop: 15, minWidth: 600 }}>
					{lessonsSong.loading ? (
						<BaseLoading />
					) : lessons.length > 0 ? (
						<Grid item style={{ paddingLeft: 1, marginRight: 26, minWidth: 500 }}>
							<ListLessons
								standardButton={false}
								teacherId={teacherId}
								spacing={1.5}
								data={lessons}
							/>
						</Grid>
					) : (
						<Box>
							<Typography variant="h6" color="textSecondary">
								No results found
							</Typography>
							<Typography color="textSecondary">This Song has no Lessons yet</Typography>
						</Box>
					)}
				</Grid>
			}
			onConfirm={() => {
				history.push(Pages.Lessons.path)
			}}
			confirmLabel="View All Lessons"
			onDiscard={() => {
				setSongId(undefined)
			}}
			discardLabel="Close"
		/>
	)
}
