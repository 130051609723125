import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actionButton: {
			border: '1px solid rgba(50, 51, 66, 0.5)',
			height: '56px'
		},
		link: {
			color: theme.palette.secondary.dark
		},
		banner: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '14/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		},
		channel: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			pointerEvents: 'none',
			'& .MuiButton-root': {
				marginLeft: '32px',
				pointerEvents: 'none',
				backgroundColor: 'rgba(0, 0, 0, 0.2)',
				color: theme.palette.common.white,
				boxShadow: 'none',
				border: '2px solid rgba(237, 237, 237, 1)'
			}
		},
		logo: {
			width: '10%',
			marginLeft: '32px',
			marginBottom: '20px'
		}
	})
)
