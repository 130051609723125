import { Box, createStyles, makeStyles, MenuItem, Select } from '@material-ui/core'

import { generateUniqueId } from '../../../../../../../utils'
import { useStudentDetailContext } from '../useStudentDetail'

const useStyles = makeStyles(() =>
	createStyles({
		root: {}
	})
)

export const SelectClasses = () => {
	const styles = useStyles()
	const { student, classSelectedId, setClassSelectedId } = useStudentDetailContext()
	const classes = student?.class_students.map((item) => item.class) || []

	return (
		<Box marginTop={5}>
			<Select
				className={styles.root}
				style={{ width: '100%', height: '56px', backgroundColor: 'white' }}
				variant="outlined"
				color="secondary"
				value={classSelectedId ?? 'All Classes'}
				onClick={(e) => e.stopPropagation()}
				onChange={(e) =>
					setClassSelectedId(e.target.value === 'All Classes' ? undefined : Number(e.target.value))
				}>
				<MenuItem value="All Classes">All Classes</MenuItem>
				{classes?.map((item) => (
					<MenuItem key={generateUniqueId()} value={item.class_id}>
						{item.title}
					</MenuItem>
				))}
			</Select>
		</Box>
	)
}
