import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useGetModulesDataByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Module } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { LIMIT, ProductTitleEnum, buildRouteParameters } from 'src/utils'
export const useModulesWrapper = () => {
	const history = useHistory()
	const { classId } = useWurrlyParams()
	const [count, setCount] = useState(0)
	const [data, setData] = useState<Module[]>([])

	const type = ProductTitleEnum.Module

	const [getModules, { data: moduleData, loading }] = useGetModulesDataByClassIdLazyQuery()

	const handleViewAll = () => {
		history.push(
			buildRouteParameters(Pages.AllClassProducts, {
				classId: Number(classId),
				type: ProductTitleEnum.Module
			})
		)
	}

	useEffect(() => {
		getModules({
			variables: {
				limit: LIMIT,
				classId: Number(classId)
			}
		})
	}, [])
	useEffect(() => {
		if (moduleData) {
			setCount(moduleData.module_aggregate.aggregate?.count || 0)
			setData(moduleData.module as Module[])
		}
	}, [moduleData])

	return {
		loading,
		type,
		data,
		count,
		handleViewAll
	}
}
