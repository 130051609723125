import { useEffect, useRef } from 'react'

import { environment } from 'src/environment'
import { useGetImagePathLazyQuery } from 'src/graphql/autogenerate/hooks'
import { DEFAULT_THUMBNAIL_NAME, buildImagePath } from 'src/utils'

import { useCreateModuleStyles } from './DisplayRichText.styles'
interface Props {
	content: string
}

const iframeYoutubeStyles: Partial<CSSStyleDeclaration> = {
	border: 'none',
	overflow: 'hidden',
	width: '100%',
	height: '100%',
	position: 'absolute',
	borderRadius: '10px'
}
const iframeYoutubeFigureStyles: Partial<CSSStyleDeclaration> = {
	height: '0',
	position: 'relative',
	paddingBottom: '56.25%'
}

const defaultImageStyles: Partial<CSSStyleDeclaration> = {
	width: '100%',
	cursor: 'pointer',
	borderRadius: '10px'
}

const customVideoFigureStyles: Partial<CSSStyleDeclaration> = {
	display: 'flex'
}

const customVideoStyles: Partial<CSSStyleDeclaration> = {
	borderRadius: '10px',
	width: '100%'
}

export const DisplayRichText = ({ content }: Props) => {
	const [getImagePath] = useGetImagePathLazyQuery()
	const videoRef = useRef<HTMLDivElement>(null)
	const classes = useCreateModuleStyles()
	const applyStyles = (element: HTMLElement, styles: Partial<CSSStyleDeclaration>) => {
		for (const [key, value] of Object.entries(styles)) {
			element.style[key as 'width'] = value as string
		}
	}

	const getFileNameFromHtml = async (html: string | undefined): Promise<string> => {
		const match = html?.match(/src="([^"]+)"/)
		let res = DEFAULT_THUMBNAIL_NAME
		if (match) {
			const url = match[1]
			const aux = new URL(url).pathname.split('/').pop()

			if (aux) {
				const filename = decodeURIComponent(aux)
				if (filename) {
					const aux = await getImagePath({ variables: { resourcePath: filename } })
					res = aux.data?.imagePath?.image_path || DEFAULT_THUMBNAIL_NAME
				}
			}
		}

		return buildImagePath(res)
	}
	useEffect(() => {
		if (!videoRef.current) return
		videoRef.current.innerHTML = ''
		videoRef.current.insertAdjacentHTML('beforeend', content)
		const videos = videoRef.current.getElementsByTagName('figure')

		const fetchData = async (video: string) => {
			const img = await getFileNameFromHtml(video)

			return img
		}

		if (videos?.length) {
			for (const video of videos) {
				// images also arrives with figure tag
				if (video.innerHTML.includes('<img')) continue

				// if figure is class table, continue
				if (video.classList.contains('table')) {
					// This is a workaroung to apply styles to the table
					const table = video.getElementsByTagName('table')[0]
					table.style.borderCollapse = 'collapse'
					table.style.borderSpacing = '0'
					// add border to all nested rows
					const tds = video.getElementsByTagName('td')
					const ths = video.getElementsByTagName('th')
					for (const row of [...tds, ...ths]) {
						row.style.border = '1px solid #bfbfbf'
						row.style.padding = '.4em'
						row.style.minWidth = '2em'

						// if header, apply a background color
						if (row.tagName === 'TH') {
							row.style.backgroundColor = 'hsla(0, 0%, 0%, 5%)'
						}
					}

					continue
				}

				// if in some part of the video there is a youtube video, don't replace it

				if (video.innerHTML.includes('youtube.com') || video.innerHTML.includes('vimeo.com')) {
					// ckeditor render youtube videos as oembed or iframe elements
					let targetElement: Element | undefined
					targetElement = video.getElementsByTagName('oembed')[0]

					if (!targetElement) {
						targetElement = video.getElementsByTagName('iframe')[0]
					}

					if (!targetElement) continue

					const iframe = document.createElement('iframe')

					iframe.src = targetElement.getAttribute('url') || targetElement.getAttribute('src') || ''
					applyStyles(iframe, iframeYoutubeStyles)

					const videoFigure = document.createElement('figure')
					applyStyles(videoFigure, iframeYoutubeFigureStyles)
					videoFigure.appendChild(iframe)

					replaceNode(videoRef.current, { target: videoFigure, current: video })
					continue
				}

				const div = document.createElement('div')
				const play = document.createElement('img')
				const defaultImage = document.createElement('img')
				;(async () => {
					const img = await fetchData(video.innerHTML)
					defaultImage.src = img
				})()

				applyStyles(defaultImage, defaultImageStyles)
				// figure element will allow us to apply some default styles to the video
				const newFigure = document.createElement('figure')
				applyStyles(newFigure, customVideoFigureStyles)

				const url =
					environment.env === 'staging' ? 'https://stage.wurrlyedu.com' : 'https://portal.wurrlyedu.com'
				play.src = `${url}/static/media/Play_Button_round_Black.png`
				play.style.top = '50%'
				play.style.left = '50%'
				play.style.position = 'absolute'
				div.style.position = 'relative'
				play.style.transform = 'translate(-50%, -50%)'
				div.appendChild(play)
				div.appendChild(defaultImage)
				newFigure.appendChild(div)

				div.addEventListener(
					'click',
					() => {
						const iframe = video.getElementsByTagName('iframe')[0] as HTMLIFrameElement
						const videoTag = video.getElementsByTagName('source')[0] as HTMLSourceElement
						const src: string = iframe?.src || videoTag?.src || ''
						const sourceTag = document.createElement('source')

						const newVideo = document.createElement('video')

						sourceTag.src = src
						newVideo.appendChild(sourceTag)
						newVideo.controls = true
						applyStyles(newVideo, customVideoStyles)

						newVideo.addEventListener('loadeddata', () => {
							newVideo.play()
						})

						replaceNode(newFigure as HTMLDivElement, {
							target: newVideo,
							current: div
						})
					},
					false
				)

				replaceNode(videoRef.current, { target: newFigure, current: video })
			}
		}
	}, [content])

	const replaceNode = (
		parent: HTMLDivElement,
		{ target, current }: { target: HTMLElement; current: HTMLElement }
	) => {
		parent.replaceChild(target, current)
	}

	return <div className={classes.content} ref={videoRef}></div>
}
