import { createStyles, makeStyles, Box, Grid, styled, Theme } from '@material-ui/core'

export const StyledGrid = styled(Grid)({
	width: '100%',
	height: '100%',
	justifyContent: 'center'
})

export const StyledFooter = styled(Box)({
	position: 'sticky',
	bottom: 0,
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	background: 'white',
	paddingTop: '1em'
})

export const StyledBadge = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	padding: '.5em',
	textAlign: 'center',
	justifyContent: 'center'
})

export const useBadgeInfoStyles = makeStyles<Theme, { color: string }>(() =>
	createStyles({
		image: {
			width: '37px',
			height: '37px',
			position: 'absolute',
			margin: '11px',
			top: '0',
			right: '0'
		},
		imageContainer: {
			position: 'relative',
			width: '140px',
			margin: 'auto'
		},
		text: {
			display: 'flex',
			fontSize: '32px',
			marginTop: '12px',
			marginBottom: '12px',
			justifyContent: 'center'
		},
		badgeCount: {
			position: 'absolute',
			top: '0',
			right: '0',
			fontSize: '18px',
			width: '37px',
			height: '37px',
			margin: '11px',
			color: ({ color }) => color
		},
		badge: {
			width: '140px',
			height: '140px',
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	})
)

export default useBadgeInfoStyles
