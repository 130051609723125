import { createStyles, makeStyles } from '@material-ui/core'
import { HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook'
import { INITIAL_X_OFFSET } from 'src/utils'

export const usePreviewTimingDialogStyles = makeStyles(() =>
	createStyles({
		chordsContainer: {
			width: '100%',
			marginBottom: '20px'
		},
		chordsContainerInner: {
			borderBottom: 1,
			borderColor: 'divider'
		},
		description: {
			padding: 14
		},
		noResult: {
			color: 'rgba(0,0,0,0.5)'
		},
		loader: {
			margin: '20px 0'
		},
		infiniteScroll: {
			padding: 5
		},
		dialogContent: {
			overflowX: 'hidden',
			width: 520
		},
		searchBar: {
			marginTop: 10,
			marginBottom: 10
		},
		subtitle: {
			fontSize: '1rem',
			textAlign: 'center',
			margin: '1em 0'
		},
		hidden: {
			display: 'hidden'
		},
		paper: {
			width: '98%',
			height: '140px',
			margin: '5px',
			overflow: 'hidden',
			position: 'relative'
		},
		playButton: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center'
		},
		slider: {
			position: 'absolute',
			top: '-10px !important',
			height: 100,
			width: 4,
			'& .MuiSlider-thumb::after': {
				width: 2,
				height: '140px',
				background: '#F2BE20',
				borderRadius: '2px',
				transform: 'matrix(1, 0, 0, -1, 0, 0)',
				top: '0px',
				left: 3,
				right: '0px',
				bottom: '0px'
			},
			'& .MuiSlider-track': {
				backgroundColor: 'transparent'
			},
			'& .MuiSlider-rail': {
				backgroundColor: 'transparent'
			},
			'& .Mui-disabled': {
				color: '#F2BE20',
				borderRadius: 2,
				height: 15
			},
			left: INITIAL_X_OFFSET,
			zIndex: 50
		},
		barSlider: {
			width: INITIAL_X_OFFSET,
			backgroundColor: '#FFEFD3',
			opacity: 0.5,
			position: 'absolute',
			zIndex: 40,
			left: 0,
			height: 400
		},

		chordsAndLyrics: ({
			audioState,
			pxPerMs,
			translateDuration
		}: {
			audioState: HTMLMediaState
			pxPerMs: number
			translateDuration: number
		}) => ({
			display: 'flex',
			position: 'relative',
			height: 190,
			width: audioState.duration * pxPerMs + INITIAL_X_OFFSET,
			transform: audioState.paused
				? `translateX(${audioState.time || 0}px)`
				: `translateX(-${audioState.duration * pxPerMs + INITIAL_X_OFFSET}px)`,
			transition: `transform ${audioState.paused ? 0 : translateDuration}s linear`
		})
	})
)

export default usePreviewTimingDialogStyles
