import { Box, Menu, MenuItem } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { PagesProps } from 'src/@types'
import { FileUploadIcon } from 'src/assets/icons/CustomIcons'
import { MainTitle } from 'src/components'
import { Pages } from 'src/routes/teacherPages'
import { LessonUploads } from 'src/scenes/Teacher/scenes/11-MyFiles/components/LessonUploads'
import { SongUploads } from 'src/scenes/Teacher/scenes/11-MyFiles/components/SongUploads'
import { VideoUploads } from 'src/scenes/Teacher/scenes/11-MyFiles/components/VideoUploads'
import { useMyFiles } from 'src/scenes/Teacher/scenes/11-MyFiles/useMyFiles'

import useStyles from './MyFiles.styles'

export const MyFiles = ({ page }: PagesProps) => {
	const theme = useTheme()
	const { history, menuUpload, handleCloseUpload, handleClick } = useMyFiles()
	const classes = useStyles(theme)

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={page.name}
				description="Here are the Songs, Videos & Lessons that you have uploaded."
				rightActions={[
					{
						variant: 'contained',
						color: !menuUpload ? 'secondary' : undefined,
						style: menuUpload
							? {
									backgroundColor: theme.palette.secondary.light,
									color: theme.palette.secondary.main
							  }
							: undefined,
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						onClick: (e) => handleClick(e)
					}
				]}
			/>
			<Menu
				id="upload-menu"
				anchorEl={menuUpload}
				getContentAnchorEl={null}
				keepMounted
				open={Boolean(menuUpload)}
				onClose={handleCloseUpload}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				className={classes.menu}
				style={{ marginTop: '10px' }}>
				<MenuItem onClick={() => history.push(Pages.SongUpload.path)}>Song</MenuItem>
				<MenuItem onClick={() => history.push(Pages.VideoUpload.path)}>Video</MenuItem>
				<MenuItem onClick={() => history.push(Pages.LessonUpload.path)}>Lesson</MenuItem>
			</Menu>
			<SongUploads />
			<VideoUploads />
			<LessonUploads />
		</Box>
	)
}
