import { useEffect } from 'react'

import { useGetAppcuesDataLazyQuery, useGetSubscriptionLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Class, Student, Teacher } from 'src/graphql/autogenerate/schemas'
import { appcuesInitStudentV1, appcuesInitTeacherV1 } from 'src/utils/appcues'

type useAppcuesProps = {
	studentData?: Pick<Student, 'student_id'> | null
	teacherData?: Pick<Teacher, 'teacher_id'> | null
}
export const useAppcues = ({ studentData, teacherData }: useAppcuesProps) => {
	const [getSubscription, { data: teacherSubscription }] = useGetSubscriptionLazyQuery()
	const [getStudentAppcuesData, { data: studentAppcuesData }] = useGetAppcuesDataLazyQuery()

	const fetchTeacherData = () => {
		const teacherId = teacherData?.teacher_id
		if (teacherId) {
			getSubscription({ variables: { teacherId } })
		}
	}

	const fetchStudentData = () => {
		const studentId = studentData?.student_id
		if (studentId) {
			getStudentAppcuesData({ variables: { studentId } })
		}
	}

	const initializeTeacherAppcues = () => {
		if (!(teacherData && teacherSubscription?.getSubscription)) return
		try {
			appcuesInitTeacherV1(teacherData as Teacher, teacherSubscription.getSubscription)
		} catch (error) {
			console.error('Could not initialize appcues for teacher:', error)
		}
	}

	const initializeStudentAppcues = () => {
		if (!(studentData && studentAppcuesData)) return

		const loggedStudent = studentData as Student
		const firstClass = studentAppcuesData.class_student?.[0]?.class as Class
		const studentTeacher = firstClass?.class_teachers?.[0]?.teacher as Teacher
		if (!(loggedStudent && firstClass && studentTeacher)) return

		try {
			appcuesInitStudentV1(loggedStudent, studentTeacher, firstClass)
		} catch (error) {
			console.error('Could not initialize appcues for student:', error)
		}
	}

	// Fetch Data for init
	useEffect(fetchTeacherData, [teacherData?.teacher_id])
	useEffect(fetchStudentData, [studentData?.student_id])

	// Initialize appcues for teacher and student
	useEffect(initializeTeacherAppcues, [teacherData?.teacher_id, JSON.stringify(teacherSubscription)])
	useEffect(initializeStudentAppcues, [studentData?.student_id, JSON.stringify(studentAppcuesData)])
}
