import { Box, BoxProps, Tooltip, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'

import useStyles from './EffectThumbnail.styles'
type EffectThumbnailProps = {
	name?: string
	imageUrl: string
	maxAmountReached?: boolean
	selected?: boolean
	selectable?: boolean
}
export const EffectThumbnail = ({
	name,
	imageUrl,
	maxAmountReached,
	selected,
	selectable,
	...props
}: EffectThumbnailProps & BoxProps) => {
	const styles = useStyles({ selected })

	return (
		<Tooltip title={maxAmountReached ? 'Reached max amount of stickers' : ''}>
			<Box
				{...props}
				className={clsx(styles.container, maxAmountReached ? styles.disabled : null, props.className)}>
				{selectable ? (
					<Box className={styles.selectableImageContainer}>
						<img src={imageUrl} alt={name} loading="lazy" />
						{selected && (
							<Box className={styles.selectedOverlay}>
								<CheckCircleIcon
									className={styles.selectedIcon}
									fontSize="small"
									color="secondary"
								/>
							</Box>
						)}
					</Box>
				) : (
					<img src={imageUrl} alt={name} loading="lazy" />
				)}
				<Typography className={styles.name}>{name}</Typography>
			</Box>
		</Tooltip>
	)
}
