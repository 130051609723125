import { Box } from '@material-ui/core'
import { DateFilterEnum } from 'src/environment'
import { CountCards } from 'src/scenes/Teacher/scenes/1-Home/components/Insights/components/CountCards/CountCards'
import { SkillsSection } from 'src/scenes/Teacher/scenes/1-Home/components/SkillsSection'

import { SectionHeader } from '../SectionHeader'
import { ClassProductivityChart } from './components/ClassProductivityChart/ClassProductivityChart'
import { InsightsEmptyState } from './components/InsightsEmptyState/InsightsEmptyState'
import { SelectClass } from './components/SelectClass/SelectClass'
import { SelectDateRange } from './components/SelectDateRange/SelectDateRange'
import { InsightsProvider, useInsightsContext } from './hooks/useInsights'
import useStyles from './Insights.styles'

export const Insights = () => {
	return (
		<InsightsProvider>
			<InsightsInner />
			<SkillsSection />
		</InsightsProvider>
	)
}

const InsightsInner = () => {
	const styles = useStyles()
	const { classOptions, selectedClass, setSelectedClass, dateRange, setDateRange, maxDate } =
		useInsightsContext()

	return (
		<Box className={styles.section}>
			<SectionHeader content="Check Insights" />
			{!classOptions.length && <InsightsEmptyState />}
			{!!classOptions.length && !!selectedClass && (
				<>
					<Box className={styles.selectorsRow}>
						<SelectClass
							selectedClass={selectedClass}
							setSelectedClass={setSelectedClass}
							options={classOptions}
						/>
						<SelectDateRange
							maxDate={maxDate}
							defaultOption={DateFilterEnum.lastSevenDays}
							selectedDateRange={dateRange}
							setSelectedDateRange={setDateRange}
						/>
					</Box>
					<ClassProductivityChart classId={selectedClass.class_id} dateRange={dateRange} />
					<CountCards classId={selectedClass.class_id} dateRange={dateRange} />
				</>
			)}
		</Box>
	)
}
