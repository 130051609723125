import makeStyles from '@material-ui/styles/makeStyles'

export const useErrorPageStyles = makeStyles({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: '40px 0',
		minHeight: 'calc(100vh - 80px)',
		textAlign: 'center'
	},
	numberError: {
		fontSize: 170,
		fontWeight: 'bold',
		letterSpacing: 10,
		lineHeight: '130px',
		marginBottom: 30,
		'& img': {
			margin: '0 10px'
		}
	},
	description: {
		color: '#1D273D',
		fontSize: 18,
		fontWeight: 'bold',
		marginBottom: 20
	},
	caption: {
		color: '#8B909B',
		fontSize: 16
	}
})

export default useErrorPageStyles
