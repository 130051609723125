import { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormik } from 'formik'
import { noop } from 'lodash'
import { useHistory } from 'react-router-dom'
import { WithOptional } from 'src/@types'
import { buildGenericContext } from 'src/context/genericContext'
import { useCustomInsertTrackMutation } from 'src/graphql/autogenerate/hooks'
import { LyricsSetType } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { TimedChord } from './components/Step-2/useChordSheetView'

const useUploadSongTrack = () => {
	const [step, setStep] = useState(1)
	const [audio, setAudio] = useState(new Audio(''))
	const [viewTitle, setViewTitle] = useState('')
	const [viewDescription, setViewDescription] = useState('')
	const [time, setTime] = useState(0)
	const [progress, setProgress] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [chords, setChords] = useState<TimedChord[]>([])
	const [breaks, setBreaks] = useState<number[]>([])
	const [durationInSeconds, setDurationInSeconds] = useState(0)
	const [playing, setPlaying] = useState(false)
	const [speed, setSpeed] = useState(0.0)
	const [isUploadtrackStepValid, setIsUploadTrackStepValid] = useState(false)
	const [isLyricsStepValid, setIsLyricsStepValid] = useState(false)
	const [isChordsStepValid, setIsChordsStepValid] = useState(false)
	const [withChords, setWithChords] = useState(false)
	const [withLyrics, setWithLyrics] = useState(false)
	const [withChordsAndLyrcis, setWithChordsAndLyrics] = useState(false)
	const [audioUrl, setAudioUrl] = useState<string>()
	const [lyricsArray, setLyricsArray] = useState<WithOptional<LyricsSetType, 'time'>[][]>([])
	const [lyrics, setLyrics] = useState<LyricsSetType[]>([])
	const [trackResourcePath, setTrackResourcePath] = useState<string>()
	const [trackTypeId, setTrackTypeId] = useState<number>()
	const [onCancel, setOnCancel] = useState(false)

	const formikStepOne = useFormik({
		initialValues: {
			resource_path: '', // audio file
			track_type_id: 0,
			terms: false
		},
		onSubmit: noop
	})

	const history = useHistory()

	const [insertTrackMutation, { data: insertTrackData, loading: loadingTrack, error: errorTrack }] =
		useCustomInsertTrackMutation()

	const { songId } = useWurrlyParams()

	const handleSpeedChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		setSpeed(value as number)
	}

	const shouldDisableNext = useCallback(
		(currentStep: number) => {
			switch (currentStep) {
				case 1:
					return !isUploadtrackStepValid
				case 2:
					return !isLyricsStepValid
				case 3:
					return !isChordsStepValid
				default:
					return false
			}
		},
		[isUploadtrackStepValid, isLyricsStepValid, isChordsStepValid]
	)

	const handleLoadedData = () => {
		setLoaded(true)
		setDurationInSeconds(audio.duration)
	}
	const handleAudioTimeUpdate = () => {
		const currentTimeInSeconds = audio.currentTime * 100
		const percent = currentTimeInSeconds / audio.duration

		setProgress(percent)
		setTime(audio.currentTime)
	}

	const handleAudioEnded = () => {
		setPlaying(false)
	}

	const formatTime = (timeInSeconds: number) => {
		const seconds = Math.floor(timeInSeconds)
		const mins = Math.floor(seconds / 60)
		const hours = Math.floor(mins / 60)

		const twoDigits = (num: number) => num.toString().padStart(2, '0')

		return `${hours > 0 ? `${twoDigits(hours)}:` : ''}${twoDigits(mins % 60)}:${twoDigits(seconds % 60)}`
	}

	useEffect(() => {
		audio.addEventListener('loadeddata', handleLoadedData)
		audio.addEventListener('timeupdate', handleAudioTimeUpdate)
		audio.addEventListener('ended', handleAudioEnded)

		return () => {
			audio.removeEventListener('loadeddata', handleLoadedData)
			audio.removeEventListener('timeupdate', handleAudioTimeUpdate)
			audio.removeEventListener('ended', handleAudioEnded)
		}
	}, [audio])

	useEffect(() => {
		if (audio) {
			audio.pause()
			setPlaying(false)
			audio.currentTime = 0
		}
	}, [step])

	useEffect(() => {
		if (!isNaN(audio.duration)) {
			if (playing) {
				audio.play()
			} else {
				audio.pause()
			}
		}
	}, [playing])

	useEffect(() => {
		audio.playbackRate = speed + 1
	}, [speed])

	const togglePlayPause = () => {
		setPlaying((prev) => !prev)
	}

	const resetSongTime = () => {
		audio.currentTime = 0
	}

	const durationInMiliseconds = useMemo(() => {
		return durationInSeconds * 1000
	}, [durationInSeconds])

	const handleNextButton = () => {
		setStep((prev) => ++prev)
		window.scrollTo(0, 0)
	}

	const handlePrevButton = () => {
		setStep((prev) => --prev)
		window.scrollTo(0, 0)
	}

	const saveTrack = async () => {
		try {
			await insertTrackMutation({
				variables: {
					filter: {
						songId,
						trackTypeId: trackTypeId as number,
						resourcePath: trackResourcePath as string,
						chords,
						breaks,
						lyrics: lyricsArray as LyricsSetType[][]
					}
				},
				update: (cache) => {
					cache.evict({ id: 'ROOT_QUERY', fieldName: `song_by_pk({"song_id":${songId}})` })
				}
			})
		} catch {}
	}

	const goToUploadTracks = () => {
		history.push(buildRouteParameters(Pages.SongUploadTrack, { songId }))
	}

	const goBackToSongs = () => {
		history.push(buildRouteParameters(Pages.MusicCatalog))
	}

	const toggleOnCancel = () => {
		setOnCancel((prev) => !prev)
	}

	const goBackToEditSong = () => {
		if (songId) {
			history.push(buildRouteParameters(Pages.SongEdit, { songId }))
		} else {
			history.goBack()
		}
	}

	return {
		step,
		setStep,

		audio,
		setAudio,
		progress,
		setProgress,
		loaded,
		durationInSeconds,
		durationInMiliseconds,
		playing,
		setPlaying,
		formatTime,
		togglePlayPause,
		speed,
		handleSpeedChange,

		isUploadtrackStepValid,
		setIsUploadTrackStepValid,
		isLyricsStepValid,
		setIsLyricsStepValid,
		isChordsStepValid,
		setIsChordsStepValid,

		shouldDisableNext,
		withChords,
		setWithChords,
		withLyrics,
		setWithLyrics,
		audioUrl,
		setAudioUrl,
		resetSongTime,
		time,
		setTime,
		handleNextButton,
		handlePrevButton,
		withChordsAndLyrcis,
		setWithChordsAndLyrics,
		setLyrics,
		lyrics,
		chords,
		setChords,
		breaks,
		setBreaks,
		saveTrack,
		insertTrackData,
		loadingTrack,
		errorTrack,
		trackResourcePath,
		setTrackResourcePath,
		trackTypeId,
		setTrackTypeId,
		goToUploadTracks,
		goBackToSongs,
		lyricsArray,
		setLyricsArray,
		formikStepOne,
		viewTitle,
		setViewTitle,
		viewDescription,
		setViewDescription,
		onCancel,
		setOnCancel,
		toggleOnCancel,
		goBackToEditSong
	}
}

export const [UploadSongTrackProvider, useUploadSongTrackContext, { withProvider: withUploadSongTrackProvider }] =
	buildGenericContext(useUploadSongTrack)
