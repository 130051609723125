import { useEffect, useState } from 'react'

import { AssetReduced } from 'src/components/InputMultipleFile'
import { useGetLessonByPkForEditQuery } from 'src/graphql/autogenerate/hooks'
import {
	Catalog_Item,
	Non_Musical_Skill,
	Keyword,
	Lesson_Plan,
	Musical_Skill,
	Discipline
} from 'src/graphql/autogenerate/schemas'

import { sortByName } from '../../../../../../utils/utils'
import { LessonFormDataType } from '../LessonUpload'

type LessonRelationFields = {
	keywords?: number[]
	catalog_items?: number[]
	levels?: number[]
	musical_skills?: number[]
	non_musical_skills?: number[]
	songs?: number[]
	videos?: number[]
}

export type LessonFormInitialDataType = Pick<
	Lesson_Plan,
	| 'title'
	| 'description'
	| 'inspire'
	| 'practice'
	| 'record'
	| 'reflect'
	| 'take_it_further'
	| 'materials_needed'
	| 'differentiations'
	| 'suggested_assignments'
	| 'discipline'
	| 'process'
> &
	LessonRelationFields & {
		image?: AssetReduced
		assets?: AssetReduced[]
	} & Pick<LessonFormDataType, 'stepTwoArticles' | 'stepThreeArticles'>

type GetIdsDifferenceProps = {
	newIds: number[]
	field: keyof LessonRelationFields
	resultId?: string
}

type ToAddRelationType = {
	data: Record<string, number>[]
}

type useInitialLessonDataProps = {
	lessonPlanId: number
}
export const useInitialLessonData = ({ lessonPlanId }: useInitialLessonDataProps) => {
	const [initialLessonData, setInitialLessonData] = useState<LessonFormInitialDataType>()

	const queryData = useGetLessonByPkForEditQuery({ variables: { lessonPlanId } })
	const getIdsToAdd = ({ newIds, field, resultId }: GetIdsDifferenceProps): ToAddRelationType => {
		const toAdd = newIds.filter((id) => !(initialLessonData?.[field] ?? []).includes(id))

		return { data: toAdd.map((id) => ({ [resultId ?? 'id']: id })) ?? [] }
	}

	const getIdsToDelete = ({ newIds, field }: GetIdsDifferenceProps): number[] => {
		return initialLessonData?.[field]?.filter((id) => !newIds.includes(id)) ?? []
	}

	useEffect(() => {
		if (queryData.data?.lesson_plan_by_pk) {
			const lessonData = queryData.data.lesson_plan_by_pk
			const keywords = lessonData.keywords.map((item) => item.keyword_id)
			const catalog_items = lessonData.catalog_items.map((item) => item.catalog_item_id)
			const levels = lessonData.levels.map((item) => item.grade_id)
			const musical_skills = lessonData.musical_skills.map((item) => item.musical_skill_id)
			const non_musical_skills = lessonData.non_musical_skills.map((item) => item.non_musical_skill_id)
			const songs = lessonData.songs.map((item) => item.song_id)
			const videos = lessonData.videos.map((item) => item.tip_id)
			const assets = lessonData.assets.map((asset) => asset.asset as AssetReduced)

			const stepTwoArticles = lessonData.articles?.slice(0, 4) || []
			const stepThreeArticles = lessonData.articles?.slice(4, lessonData.articles?.length) || []

			let image: AssetReduced | undefined

			if (lessonData.image_path.length) {
				const imagePathParts = lessonData.image_path.split('/')
				image = {
					resource_path: lessonData.image_path,
					name: imagePathParts[imagePathParts.length - 1]
				} as AssetReduced
			}

			setInitialLessonData({
				...(lessonData as unknown as LessonFormInitialDataType),
				process: lessonData.lesson_plan_standards.map((item) => item.standar_option_id) as number[],
				discipline: lessonData.lesson_plan_disciplines.map((item) => item.discipline) as Discipline[],
				keywords,
				catalog_items,
				levels,
				musical_skills,
				non_musical_skills,
				songs,
				videos,
				assets,
				image,
				stepTwoArticles,
				stepThreeArticles
			})
		}
	}, [queryData.data])

	const getRelationsToDelete = (lessonData: LessonFormDataType) => {
		const { instruments, subjects, ages } = lessonData
		const catalogItems = [...(instruments ?? []), ...(subjects ?? []), ...(ages ?? [])].map(
			(catalogItem) => catalogItem.catalog_item_id
		)
		const catalogItemIdsToDelete = getIdsToDelete({
			newIds: catalogItems,
			field: 'catalog_items'
		})
		const keywordIdsToDelete = getIdsToDelete({
			newIds: lessonData.keywords?.map((item) => item.keyword_id) ?? [],
			field: 'keywords'
		})
		const levelIdsToDelete = getIdsToDelete({
			newIds: lessonData.levels?.map((item) => item.grade_id) ?? [],
			field: 'levels'
		})
		const songIdsToDelete = getIdsToDelete({
			newIds: lessonData.songs?.map((item) => item.song_id) ?? [],
			field: 'songs'
		})
		const musicalSkillIdsToDelete = getIdsToDelete({
			newIds: lessonData.musicalSkills?.map((item) => item.musical_skill_id) ?? [],
			field: 'musical_skills'
		})
		const nonMusicalSkillIdsToDelete = getIdsToDelete({
			newIds: lessonData.nonMusicalSkills?.map((item) => item.non_musical_skill_id) ?? [],
			field: 'non_musical_skills'
		})
		const tipIdsToDelete = getIdsToDelete({
			newIds: lessonData.videos?.map((item) => item.tip_id) ?? [],
			field: 'videos'
		})

		return {
			catalogItemIdsToDelete,
			keywordIdsToDelete,
			levelIdsToDelete,
			songIdsToDelete,
			musicalSkillIdsToDelete,
			nonMusicalSkillIdsToDelete,
			tipIdsToDelete
		}
	}

	const getRelationsToAdd = (lessonData: LessonFormDataType) => {
		const { instruments, subjects, ages } = lessonData
		const catalogItems = [...(instruments ?? []), ...(subjects ?? []), ...(ages ?? [])].map(
			(catalogItem) => catalogItem.catalog_item_id
		)
		const lessonCatalogItems = getIdsToAdd({
			newIds: catalogItems,
			field: 'catalog_items',
			resultId: 'catalog_item_id'
		})
		const lessonKeywords = getIdsToAdd({
			newIds: lessonData.keywords?.map((item) => item.keyword_id) ?? [],
			field: 'keywords',
			resultId: 'keyword_id'
		})
		const levels = getIdsToAdd({
			newIds: lessonData.levels?.map((item) => item.grade_id) ?? [],
			field: 'levels',
			resultId: 'grade_id'
		})
		const songs = getIdsToAdd({
			newIds: lessonData.songs?.map((item) => item.song_id) ?? [],
			field: 'songs',
			resultId: 'song_id'
		})
		const musicalSkill = getIdsToAdd({
			newIds: lessonData.musicalSkills?.map((item) => item.musical_skill_id) ?? [],
			field: 'musical_skills',
			resultId: 'musical_skill_id'
		})
		const nonMusicalSkill = getIdsToAdd({
			newIds: lessonData.nonMusicalSkills?.map((item) => item.non_musical_skill_id) ?? [],
			field: 'non_musical_skills',
			resultId: 'non_musical_skill_id'
		})
		const tips = getIdsToAdd({
			newIds: lessonData.videos?.map((item) => item.tip_id) ?? [],
			field: 'videos',
			resultId: 'tip_id'
		})

		return {
			lessonCatalogItems,
			lessonKeywords,
			levels,
			songs,
			musicalSkill,
			nonMusicalSkill,
			tips
		}
	}

	return {
		initialLessonData,
		loading: queryData.loading,
		getRelationsToAdd,
		getRelationsToDelete
	}
}

type extractKeywordsProps = {
	selected?: number[]
	options?: Keyword[]
}
export const extractKeywords = ({ selected, options }: extractKeywordsProps): Keyword[] => {
	const result = [] as Keyword[]

	if (options && selected) {
		for (const keyword_id of selected) {
			const option = options.find((keyword) => keyword.keyword_id === keyword_id)
			if (option) result.push(option)
		}
	}

	return result
}

type extractCatalogItemsProps = {
	selected?: number[]
	options?: Catalog_Item[]
}
export const extractCatalogItem = ({ selected, options }: extractCatalogItemsProps): Catalog_Item[] => {
	const result = [] as Catalog_Item[]

	if (selected && options) {
		for (const selected_id of selected) {
			const option = options.find((item) => item.catalog_item_id === selected_id)
			if (option) result.push(option)
		}
	}

	return result
}

type extractMusicalSkillsProps = {
	selected?: number[]
	options?: Musical_Skill[]
}
export const extractMusicalSkills = ({ selected, options }: extractMusicalSkillsProps): Musical_Skill[] => {
	const result = [] as Musical_Skill[]

	if (selected && options) {
		for (const musical_skill_id of selected) {
			const option = options.find((item) => item.musical_skill_id === musical_skill_id)
			if (option) result.push(option)
		}
	}

	return sortByName(result)
}
type extractNonMusicalSkillsProps = {
	selected?: number[]
	options?: Non_Musical_Skill[]
}
export const extractNonMusicalSkills = ({
	selected,
	options
}: extractNonMusicalSkillsProps): Non_Musical_Skill[] => {
	const result = [] as Non_Musical_Skill[]

	if (selected && options) {
		for (const non_musical_skill_id of selected) {
			const option = options.find((item) => item.non_musical_skill_id === non_musical_skill_id)
			if (option) result.push(option)
		}
	}

	return sortByName(result)
}
