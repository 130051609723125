import { useMemo } from 'react'

import { Box, CircularProgress, BoxProps } from '@material-ui/core'
import clsx from 'clsx'
import { NOTES_SIZE } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/constants'
import {
	INITIAL_X_OFFSET,
	PX_PER_SECOND,
	CHORD_MAIN_Y_POS,
	CHORD_ASC_Y_POS,
	LYRIC_MAIN_Y_POS,
	LYRIC_MAIN_Y_POS_AUDIO,
	LYRIC_DESC_Y_POS,
	LYRIC_DESC_Y_POS_AUDIO,
	LYRIC_SECOND_DESC_Y_POS_AUDIO,
	LYRIC_SECOND_DESC_Y_POS
} from 'src/components/Studio/Record/InstrumentNotesPanel/contants'
import { useStudioRecordContext } from 'src/components/Studio/Record/useStudioRecord'

import { BaseSlider } from '../../..'
import { Lyric } from './components/Lyric'
import { Note } from './components/Note'
import { useInstrumentNotesPanelStyles } from './styleInstrumentNotesPanel'
import { InstrumentsGuideType } from './types'

type InstrumentNotesPanelProps = BoxProps & {
	guide: InstrumentsGuideType
}
enum TrackEventType {
	Chord = 'chord',
	Lyric = 'lyric'
}

export const InstrumentNotesPanel = ({ guide, ...props }: InstrumentNotesPanelProps) => {
	const styles = useInstrumentNotesPanelStyles()
	const { chordsData, chordsLoading, audioState } = useStudioRecordContext()

	const chords = useMemo(() => {
		if (!chordsData?.getSongTrackEvents?.eventData?.rows) return null
		const chordsArr: JSX.Element[] = []
		let lastChordX = 0
		let lastChordY = 0
		for (const row of chordsData?.getSongTrackEvents?.eventData?.rows) {
			if (row.subtype === TrackEventType.Chord) {
				// Calculate if chord should go up or in the middle
				const noteWidth = NOTES_SIZE[guide]
				const xPos = (row.time / 1000) * PX_PER_SECOND + INITIAL_X_OFFSET
				const overlap = lastChordX + noteWidth >= xPos
				const yPos = overlap
					? lastChordY === CHORD_MAIN_Y_POS
						? CHORD_ASC_Y_POS
						: CHORD_MAIN_Y_POS
					: CHORD_MAIN_Y_POS
				lastChordX = xPos
				lastChordY = yPos

				chordsArr.push(
					<Note
						key={`${row.time}_note`}
						guide={guide}
						chord={{ name: row.text }}
						style={{
							position: 'absolute',
							top: yPos,
							transform: 'translateY(-50%)',
							left: xPos
						}}
						time={row.time}
					/>
				)
			}
		}

		return chordsArr
	}, [chordsData, guide])

	const lyrics = useMemo(() => {
		if (!chordsData?.getSongTrackEvents?.eventData?.rows) return null
		const lyricsArr: JSX.Element[] = []
		let lastWordX = 0
		let lastWordY = 0
		let lastWordWidth = 0
		let anteultimateWordX = 0
		let anteultimateWordWidth = 0
		const mainYPos = guide === InstrumentsGuideType.audio ? LYRIC_MAIN_Y_POS_AUDIO : LYRIC_MAIN_Y_POS
		const descYPos = guide === InstrumentsGuideType.audio ? LYRIC_DESC_Y_POS_AUDIO : LYRIC_DESC_Y_POS
		const descYPosSecond =
			guide === InstrumentsGuideType.audio ? LYRIC_SECOND_DESC_Y_POS_AUDIO : LYRIC_SECOND_DESC_Y_POS
		for (const row of chordsData?.getSongTrackEvents?.eventData?.rows) {
			if (row.subtype === TrackEventType.Lyric) {
				// Calculate if lyrics should go in middle or down
				const xPos = (row.time / 1000) * PX_PER_SECOND + INITIAL_X_OFFSET
				const overlap =
					lastWordX + lastWordWidth >= xPos || anteultimateWordX + anteultimateWordWidth >= xPos
				const yPos = overlap
					? lastWordY === mainYPos
						? descYPos
						: lastWordY === descYPos
						? descYPosSecond
						: mainYPos
					: mainYPos
				anteultimateWordX = lastWordX
				anteultimateWordWidth = lastWordWidth
				lastWordX = xPos
				lastWordY = yPos
				const lyricFontSize = guide === InstrumentsGuideType.audio ? 17 : 14
				// Give extra 5px so the word breaks even if its not overlapped, but really next to each other
				const wordWidth = getTextWidth(row.text, `${lyricFontSize}px Roboto`) + 5
				lastWordWidth = wordWidth

				lyricsArr.push(
					<Lyric
						key={`${row.time}_lyric`}
						name={row.text}
						time={row.time}
						style={{
							position: 'absolute',
							bottom: yPos,
							left: xPos,
							fontSize: lyricFontSize
						}}
					/>
				)
			}
		}

		return lyricsArr
	}, [chordsData, guide])

	return (
		<Box display="flex" flexDirection="column" {...props} className={clsx(styles.container, props.className)}>
			<Box
				className={styles.wrapper}
				style={{
					minWidth: 200
				}}>
				<Box
					style={{
						width: INITIAL_X_OFFSET,
						backgroundColor: '#FFEFD3',
						opacity: 0.5,
						position: 'absolute',
						zIndex: 40,
						left: 0,
						height: 400
					}}></Box>
				<BaseSlider
					className={styles.slider}
					max={100}
					disabled
					style={{ left: INITIAL_X_OFFSET, zIndex: 50 }}
				/>
				<Box
					style={{
						display: 'flex',
						position: 'relative',
						height: 300,
						width: audioState.duration * PX_PER_SECOND + INITIAL_X_OFFSET,
						transform: `translateX(-${audioState.time * PX_PER_SECOND + INITIAL_X_OFFSET}px)`,
						transition: `transform 1s linear`
					}}>
					{chordsLoading && <CircularProgress color="secondary" size={30} />}
					{chords && chords.map((i) => i)}
					{lyrics && lyrics.map((i) => i)}
				</Box>
			</Box>
		</Box>
	)
}

function getTextWidth(text: string, font?: string) {
	const canvas = document.createElement('canvas')
	const context = canvas.getContext('2d')
	if (context?.font) {
		context.font = font || getComputedStyle(document.body).font

		return context.measureText(text).width
	}

	return 0
}
