import { useEffect, useState } from 'react'

import { buildGenericContext } from '../../../../../../context/genericContext'
import { useGetTeacherStudentsByKeywordQuery } from '../../../../../../graphql/autogenerate/hooks'
import { Order_By, Student } from '../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../hooks/useLogin'
import { buildStudentSearchParams, MAX_LIST_ITEMS } from '../../../../../../utils'

const useStudentsGrid = () => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [students, setStudents] = useState<Student[]>([])
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [sort, setSort] = useState(Order_By.Asc)
	const [count, setCount] = useState(0)
	const [loading, setLoading] = useState(true)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [searchText, setSearchText] = useState('')

	const INITIAL_QUERY_VARIABLES = {
		teacherId,
		limit,
		offset: 0,
		order: sort,
		conditions: buildStudentSearchParams(searchText)
	}

	const {
		loading: queryLoading,
		data,
		fetchMore,
		refetch
	} = useGetTeacherStudentsByKeywordQuery({
		variables: INITIAL_QUERY_VARIABLES,
		fetchPolicy: 'no-cache'
	})

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [students])

	useEffect(() => {
		if (queryLoading) setLoading(true)
		if (!queryLoading && data) {
			setStudents((data.student || []) as Student[])
			setCount(data.student_aggregate?.aggregate?.count ?? 0)
		}
	}, [queryLoading, data])

	useEffect(() => {
		if (!isFetching) return
		if (!queryLoading && fetchMore) {
			const currentLength = students.length
			fetchMore({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				const fetchMoreResultLength = fetchMoreResult.data?.student?.length || 0
				if (fetchMoreResultLength !== 0) {
					const newLimit = currentLength + fetchMoreResultLength
					setLimit(newLimit)
					refetch({ limit: newLimit, order: sort })
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	return { refetch, limit, sort, setSort, setSearchText, loading, students, count, moreResults, isFetching }
}

export const [StudentsGridProvider, useStudentsGridContext] = buildGenericContext(useStudentsGrid)
