import { useState, useEffect, ChangeEvent } from 'react'

import { Box, CircularProgress, styled, Typography } from '@material-ui/core'
import axios, { AxiosRequestConfig } from 'axios'
import Avatar from 'react-avatar-edit'
import { cache } from 'src/apollo/state'
import { ThinCheckIcon } from 'src/assets/icons'
import { BigBaseButton } from 'src/components/Buttons/BigBaseButton'
import { BaseDialog } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import { InfoDialog } from 'src/components/Dialogs/InfoDialog'
import {
	useGetPresignedUrlLazyQuery,
	useUpdateStudentAvatarMutation,
	useUpdateTeacherAvatarMutation
} from 'src/graphql/autogenerate/hooks'
import { FileEnum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { AvatarList } from 'src/scenes/Login/scenes/2-StudentLogin/scenes/SignInOptions/scenes/AvatarSelectionDialog/components/AvatarList'
import { buildImagePath } from 'src/utils'
type EditAvatarDialogProps = {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	isStudent?: boolean
}

export const EditAvatarDialog = ({ isOpen, setIsOpen, isStudent }: EditAvatarDialogProps) => {
	const { teacherData, studentData } = useLoginContext()
	const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null)
	const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
	const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
	const [editImagen, setEditImagen] = useState(false)

	const [updateTeacherAvatar, { loading: isTeacherAvatarLoading }] = useUpdateTeacherAvatarMutation()
	const [updateStudentAvatar, { loading: isStudentAvatarLoading }] = useUpdateStudentAvatarMutation()
	const [getPresignedUrl, presignedUploadUrl] = useGetPresignedUrlLazyQuery({
		fetchPolicy: 'no-cache'
	})

	const [file, setFile] = useState<File>()
	const [fileUpload, setFileUpload] = useState<File>()
	const [cropFile, setCropFile] = useState<string>('')
	const [previewFile, setPreviewFile] = useState<string>()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (file) {
			setEditImagen(true)
			setPreviewFile(URL.createObjectURL(file))
		}
	}, [file])

	useEffect(() => {
		if (file) {
			fetch(cropFile)
				.then((res) => res.blob())
				.then((blob) => {
					setFileUpload(new File([blob], file.name, { type: 'image/jpeg' }))
				})
				.catch(() => {
					setIsErrorDialogOpen(true)
				})
		}
	}, [cropFile])
	const handleCancel = () => {
		setSelectedAvatar(isStudent ? studentData?.image_path || null : teacherData?.image_path || null)
		setIsOpen(false)
		setEditImagen(false)
	}

	const uploadFile = async (file: File) => {
		if (file) {
			const ext = file?.name?.split('.')[file.name.split('.')?.length - 1]
			await getPresignedUrl({
				variables: {
					filter: {
						conType: file.type,
						ext,
						type: FileEnum.Images as FileEnum,
						isTemporal: false
					}
				}
			}).catch(() => {
				setIsErrorDialogOpen(true)
			})
			setFile(file)
		}
	}

	useEffect(() => {
		if (!presignedUploadUrl.loading && presignedUploadUrl.data) {
			const presigndedUrl = presignedUploadUrl?.data?.presignedUrl?.url
			const key = presignedUploadUrl?.data?.presignedUrl?.key
			if (presigndedUrl) {
			}
			if (!key || !presigndedUrl) return
		}
	}, [presignedUploadUrl.data?.presignedUrl?.key])

	useEffect(() => {
		if (!presignedUploadUrl.loading && presignedUploadUrl.data) {
			const presigndedUrl = presignedUploadUrl.data.presignedUrl?.url
			const key = presignedUploadUrl.data.presignedUrl?.key
			try {
				const CancelToken = axios.CancelToken
				const source = CancelToken.source()

				const options: AxiosRequestConfig = {
					method: 'put',
					data: fileUpload,
					url: presigndedUrl?.toString(),
					headers: {
						'Content-Type': fileUpload?.type
					},
					cancelToken: source.token
				}
				axios.request(options).then((res) => {
					if (res?.status === 200) {
						setSelectedAvatar(buildImagePath(key))
					}
				})
			} catch (err) {}
		}
	}, [presignedUploadUrl])

	useEffect(() => {
		setLoading(false)
		if (selectedAvatar) {
			handleSave()
		}
	}, [selectedAvatar])

	const handleSaveUpload = async () => {
		if (editImagen && file && fileUpload) {
			setLoading(true)
			await uploadFile(fileUpload)
		}
	}
	const handleSave = async () => {
		try {
			setEditImagen(false)
			setIsOpen(false)
			if (isStudent) {
				await updateStudentAvatar({
					variables: {
						studentId: studentData.student_id,
						imagePath: selectedAvatar
					}
				})
			} else {
				await updateTeacherAvatar({
					variables: {
						teacherId: teacherData.teacher_id,
						imagePath: selectedAvatar
					}
				})
			}
			setIsSuccessDialogOpen(true)
		} catch (error) {
			console.error('Could not update avatar:', error)
			setIsErrorDialogOpen(true)
		}
	}

	const cacheEvictAndCloseDialogs = () => {
		cache.evict({
			id: cache.identify(isStudent ? studentData : teacherData),
			fieldName: 'image_path'
		})
		cache.gc()
		setIsErrorDialogOpen(false)
		setIsSuccessDialogOpen(false)
		setIsOpen(false)
	}

	const onCrop = (preview: string) => {
		setCropFile(preview)
	}

	const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files?.length) {
			setFile(e.target.files[0])
		}
	}

	return (
		<>
			{editImagen && (
				<BaseDialog
					paperProps={{ style: { minWidth: '640px' } }}
					open={isOpen}
					onClose={() => setIsOpen(false)}
					dividers={false}
					header={<StyledTypography variant="h4">Profile Image</StyledTypography>}
					bodyProps={{ style: { overflow: 'unset' } }}
					body={
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							{loading ? (
								<CircularProgress size={190} color="secondary" />
							) : (
								<Avatar
									width={390}
									height={295}
									src={previewFile}
									onCrop={onCrop}
									onClose={() => {
										setEditImagen(false)
									}}
								/>
							)}
						</div>
					}
					footer={
						!loading && (
							<Footer>
								<FooterButton onClick={handleCancel}>Cancel</FooterButton>
								<FooterButton
									color="secondary"
									onClick={handleSaveUpload}
									disabled={isTeacherAvatarLoading || isStudentAvatarLoading}
									isLoading={isTeacherAvatarLoading || isStudentAvatarLoading}>
									Save
								</FooterButton>
							</Footer>
						)
					}
				/>
			)}
			{!editImagen && (
				<BaseDialog
					paperProps={{ style: { minWidth: '640px' } }}
					open={isOpen}
					onClose={() => setIsOpen(false)}
					dividers={false}
					header={<StyledTypography variant="h4">Profile Image</StyledTypography>}
					bodyProps={{ style: { overflow: 'unset' } }}
					body={
						<StyledBody>
							<AvatarList
								handleFile={handleFile}
								initialAvatarImg={isStudent ? studentData.image_path : teacherData.image_path}
								setSelectedAvatarImg={setSelectedAvatar}
								stageName={isStudent ? `${studentData.full_name}` : `${teacherData.full_name}`}
								handleExtraOptionClick={isStudent ? undefined : () => {}}
							/>
						</StyledBody>
					}
					footer={
						<Footer>
							<FooterButton onClick={handleCancel}>Cancel</FooterButton>
							<FooterButton
								color="secondary"
								onClick={handleSave}
								disabled={isTeacherAvatarLoading || isStudentAvatarLoading}
								isLoading={isTeacherAvatarLoading || isStudentAvatarLoading}>
								Save
							</FooterButton>
						</Footer>
					}
				/>
			)}

			<InfoDialog
				open={isErrorDialogOpen}
				onClose={() => setIsErrorDialogOpen(false)}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while updating your avatar"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={cacheEvictAndCloseDialogs}
			/>
			<InfoDialog
				open={isSuccessDialogOpen}
				onClose={cacheEvictAndCloseDialogs}
				icon={<ThinCheckIcon />}
				title="Avatar updated!"
				body="You have successfully updated your avatar."
				confirmLabel="Done"
				onConfirm={cacheEvictAndCloseDialogs}
			/>
		</>
	)
}

const StyledTypography = styled(Typography)({
	fontWeight: 500,
	fontSize: '32px',
	textAlign: 'center',
	marginBottom: '25px'
})
const StyledBody = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-around'
})
const Footer = styled(Box)({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between'
})
const FooterButton = styled(BigBaseButton)({
	minWidth: '200px'
})
