import { createStyles, alpha, makeStyles, Theme } from '@material-ui/core'

export const useInfoDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			'& .MuiDialog-paper': {
				padding: 25
			}
		},
		dialogTitle: { paddingBottom: '0' },
		actions: {
			display: 'inline',
			paddingBottom: 25,
			'& .MuiBox-root:not(:first-child)': {
				marginLeft: 0
			}
		},
		actionButton: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			paddingTop: 10,
			'& button': {
				width: '90%',
				fontWeight: 'bold',
				paddingTop: 15,
				paddingBottom: 15
			}
		},
		icon: {
			color: theme.palette.secondary.dark,
			backgroundColor: alpha(theme.palette.secondary.main, 0.25),
			width: 130,
			height: 130,
			fontSize: 80,
			margin: `${theme.spacing(1)}px auto ${theme.spacing(3)}px`,
			'& .MuiSvgIcon-root': {
				fontSize: 80
			}
		},
		button: {
			margin: 10
			// fontWeight: 'normal'
		},
		footer: {
			marginBottom: 20,
			width: '100%',
			display: 'flex',
			justifyContent: 'center'
		},
		horizontalPadding: {
			paddingLeft: 25,
			paddingRight: 25
		}
	})
)

export default useInfoDialogStyles
