import { createStyles, makeStyles } from '@material-ui/core'

export const useMessageBoxStyles = makeStyles(() =>
	createStyles({
		messageBox: {
			display: 'flex',
			alignItems: 'center',
			padding: '26px',
			backgroundColor: '#E9E9EA',
			borderBottomLeftRadius: 4,
			borderBottomRightRadius: 4
		},
		chatIcon: {
			marginRight: 20
		},
		text: {
			width: '100%'
		}
	})
)

export default useMessageBoxStyles
