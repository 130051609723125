import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core'

export const useUpdateSongStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: { display: 'flex', flexDirection: 'column' },
		switchesSection: { display: 'flex', marginTop: '24px' },
		keyAndScaleSection: { display: 'flex', flexDirection: 'row', gap: '12px' },
		radios: { display: 'flex', flexDirection: 'row', gap: '12px' },
		buttonsSection: {
			marginTop: '24px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			gap: '24px'
		},
		button: {
			width: '216px'
		},
		modeCard: { padding: '0 12px' },
		deleteButton: {
			background: '#f44336',
			color: 'white',
			'&:hover': {
				background: alpha('#f44336', 0.8)
			}
		},
		addTracksButton: {
			width: '446px',
			height: '160px',
			background: '#FFFFFF',
			color: theme.palette.secondary.main,
			'& > .MuiButton-label': {
				display: 'flex',
				flexDirection: 'column',
				gap: '12px',
				alignItems: 'center',
				justifyContent: 'center',
				'& > svg': {
					fontSize: '48px'
				}
			}
		},
		trackList: {
			display: 'flex',
			flexDirection: 'column',
			gap: '24px',
			alignItems: 'end'
		},
		tracksContainer: {
			display: 'flex',
			gap: '24px',
			flexDirection: 'column'
		},
		fieldTitle: {
			fontSize: '1rem',
			fontWeight: 700,
			marginBottom: '.8em',

			'&  strong': {
				fontWeight: 500
			}
		},
		chip: {
			color: theme.palette.background.default,
			backgroundColor: theme.palette.text.secondary,
			borderRadius: '4px',
			marginRight: '5px'
		},
		closeChipIcon: {
			color: alpha(theme.palette.background.default, 0.6),
			height: '26px',
			width: '18px',
			marginLeft: '3px',
			'& .MuiChip-deleteIcon:hover': {
				color: theme.palette.background.default
			}
		},

		listBox: {
			padding: 0,
			margin: 0,
			'& .MuiAutocomplete-option:not(last-child)': {
				padding: '15px'
			},
			'& .MuiAutocomplete-option[aria-selected="true"]': {
				color: theme.palette.secondary.main,
				backgroundColor: 'rgba(255, 194, 12, 0.3)'
			},
			'& .MuiAutocomplete-option[data-focus="true"]': {
				color: theme.palette.secondary.main,
				backgroundColor: 'rgba(255, 194, 12, 0.1)'
			}
		},
		whiteBg: {
			backgroundColor: '#FFF',
			'& .MuiFormHelperText-root': {
				backgroundColor: '#F3F5F8',
				paddingLeft: '14px',
				margin: 0
			}
		}
	})
)

export default useUpdateSongStyles
