import { useEffect, useMemo, useRef, useState } from 'react'

import { TextFieldProps } from '@material-ui/core'
import { TextInput } from 'src/components/Inputs/TextInput'
import { useValidatePromotionCodeLazyQuery } from 'src/graphql/autogenerate/hooks'

import { useStyles } from './PromotionCodeInput.styles'

type PromotionCodeProps = {
	onChange: (name: 'promotion_code', value: string) => void
	onChangeValid: (name: 'promotion_code_is_valid', value: boolean) => void
	onChangeDiscountPercentage: (value: number) => void
	onChangeDiscountAmount: (value: number) => void
	value: string
	isValid?: boolean
	handleValidate?: (isValidating: boolean) => void
	handleLoading?: (isLoading: boolean) => void
} & Omit<TextFieldProps, 'onChange'>

export const PromotionCodeInput: React.FC<PromotionCodeProps> = ({
	value,
	onChange,
	onChangeValid,
	onChangeDiscountAmount,
	onChangeDiscountPercentage,
	isValid,
	...props
}) => {
	const classes = useStyles({ isValid })
	const timer = useRef<number | undefined>(undefined)
	const [holdError, setHoldError] = useState<boolean | undefined>(undefined)
	const [validateCode, { loading }] = useValidatePromotionCodeLazyQuery()

	const handleValidatePromotionCode = () => {
		setHoldError(false)
		validateCode({
			variables: { code: value },
			onCompleted: (data) => {
				const validData = data.validatePromotionCode
				onChangeValid('promotion_code_is_valid', Boolean(validData?.isValid))

				if (validData?.discount_amount_off && onChangeDiscountAmount) {
					onChangeDiscountAmount(validData?.discount_amount_off)
				}
				if (validData?.discount_percent_off && onChangeDiscountPercentage) {
					onChangeDiscountPercentage(validData.discount_percent_off)
				}
			}
		})
	}

	const clearTimeOut = () => {
		setHoldError(false)
		onChangeValid('promotion_code_is_valid', false)
		onChangeDiscountAmount(0)
		onChangeDiscountPercentage(0)
		if (timer.current) clearTimeout(timer.current)
	}

	useEffect(() => {
		if (!value) return clearTimeOut

		if (timer.current) {
			clearTimeout(timer.current)
		}
		setHoldError(true)
		timer.current = window.setTimeout(() => {
			handleValidatePromotionCode()
		}, 1000)

		return clearTimeOut
	}, [value])

	const helperText = useMemo(() => {
		if (isValid) {
			return 'Promotion code is Valid'
		}

		return props.helperText
	}, [isValid, props.helperText])

	return (
		<TextInput
			{...props}
			role="textbox"
			className={classes.input}
			name="Promotion code"
			value={value}
			error={!holdError ? props.error : false}
			helperText={loading || holdError ? 'Validating...' : helperText}
			onChange={(e) => onChange('promotion_code', e.target.value)}
			placeholder="Enter promotion code"
		/>
	)
}
