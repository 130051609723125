import React, { useEffect, useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Radio,
	RadioGroup,
	Typography
} from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { useLocation } from 'react-router-dom'
import { FilterItemType } from 'src/@types'
import { ListSongs, SelectSort } from 'src/components'
import { useMusicCatalogContext } from 'src/components/MusicCatalog/useMusicCatalog'
import {
	useGetCatalogByPkQuery,
	useGetClassMaturityLevelQuery,
	useGetScalesQuery
} from 'src/graphql/autogenerate/hooks'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useScroll } from 'src/hooks/useScroll'
import { QueryCatalogs, QueryCatalogsType } from 'src/scenes/Teacher/queries'
import { capitalize, TypeEnum, SortOrder } from 'src/utils'
import { fetchOnScrollDown } from 'src/utils/utils'

import useStyles from './CatalogSongs.styles'
import { FilterNameEnum } from './enums'
import { useGetSongs } from './hooks'

export const CatalogSongs = ({ onSongClick }: { onSongClick: (classId: number, songId: number) => void }) => {
	const styles = useStyles()
	const { studentData } = useLoginContext()
	const studentId = studentData.student_id
	const { chords: selectedChords, catalogId, catalogItemId, search } = useMusicCatalogContext()
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const classId = params.get('classId') as unknown as number

	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})
	const catalogName = catalogData?.catalog_by_pk?.name

	const [scale, setScale] = useState(0)
	const [filters, setFilters] = useState<FilterItemType[]>([])
	const [sort, setSort] = useState(SortOrder.Up)
	const [selectedFilters, setSelectedFilters] = useState<number[]>([])
	const [songsFiltered, setSongsFiltered] = useState<Song[]>([])
	const [transpose] = useState(true)
	const [vocalGuide, setVocalGuide] = useState(false)
	const [chordGuide, setChordGuide] = useState(!!selectedChords?.length)
	const secondaryFilters = useQuery<QueryCatalogsType>(QueryCatalogs(TypeEnum.Song))
	const { data: classMaturityLevel } = useGetClassMaturityLevelQuery({ variables: { classId } })

	const [songs, isFetching, moreResults, loading, count, setIsFetching] = useGetSongs(
		studentId,
		catalogItemId,
		search,
		sort,
		selectedChords,
		transpose,
		scale,
		vocalGuide,
		chordGuide,
		selectedFilters,
		classMaturityLevel?.class_by_pk?.maturity_level
	)
	const { data: scales } = useGetScalesQuery()
	const scrollRef = useRef(null)
	const IsAnyFilterSelected = !!selectedFilters.length || chordGuide || vocalGuide
	useEffect(() => {
		if (scrollRef?.current)
			window.addEventListener('scroll', () => {
				const htmlElement = scrollRef?.current as unknown as HTMLElement
				fetchOnScrollDown(isFetching, setIsFetching, htmlElement)
			})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [scrollRef])

	useScroll()

	//  get secondary filters
	useEffect(() => {
		if (!secondaryFilters.loading && secondaryFilters.data) {
			const { catalog } = secondaryFilters.data

			const tmpcatalog = catalog.map((itemCatalog) => ({
				name: capitalize(itemCatalog.name),
				items: itemCatalog.catalog_items.map((el) => ({
					id: el.catalog_item_id,
					label: el.name,
					selected: false
				}))
			}))
			setFilters(tmpcatalog)
		}
	}, [secondaryFilters])

	useEffect(() => {
		if (!songs.length && !songsFiltered.length) return
		setSongsFiltered(songs as Song[])
	}, [songs])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const handleCheck = (indexFilter: number, indexItem: number) => () => {
		const cpy = [...filters]
		const filter = cpy[indexFilter]
		const item = filter.items[indexItem]

		item.selected = !item.selected
		if (item.selected) {
			setSelectedFilters([...selectedFilters, item.id])
		} else {
			const auxFilters = [...selectedFilters] // make a separate copy of the array
			const index = auxFilters.indexOf(item.id)
			if (index !== -1) {
				auxFilters.splice(index, 1)
				setSelectedFilters(auxFilters)
			}
		}
		setFilters(cpy)
	}

	const handleScale = (event: React.ChangeEvent<HTMLInputElement>) => {
		setScale(+(event.target as HTMLInputElement).value)
	}

	return (
		<>
			<Grid className={styles.grid} container spacing={3}>
				<Grid item xs={12} md={9}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b>Songs ({count})</b>
						</Typography>
						<div className={styles.flex} />
						{count !== 0 && <SelectSort cyKey="Songs" value={sort} onChange={handleSort} />}
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					{isFetching || !loading ? (
						<ListSongs
							ref={scrollRef}
							teacherId={0}
							selectedChords={selectedChords}
							allTranspositions={transpose}
							studioButton
							spacing={1.5}
							data={songsFiltered}
							classId={classId}
							overrideOnClick={onSongClick}
						/>
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{isFetching && moreResults && (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{!loading && songsFiltered.length > 0 && !moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)}
					{!loading && songsFiltered.length <= 0 && (
						<div>
							<Divider />
							<Box my={4}>
								<Typography variant="h6" color="textSecondary">
									{search && !IsAnyFilterSelected
										? `Your search for "${search}" did not return any results.`
										: 'No results found'}
								</Typography>
								<Typography color="textSecondary">
									{search && !IsAnyFilterSelected
										? `Remember it's easy to upload your own by clicking the button on the top right!`
										: 'Your filter selection did not return any results'}
								</Typography>
								<Typography color="textSecondary">
									{IsAnyFilterSelected &&
										'Try removing a filter or upload own by clicking the button top right'}
								</Typography>
							</Box>
							<Divider />
						</div>
					)}
				</Grid>

				<Grid item xs={12} md>
					<Paper>
						<Box py={2} px={3}>
							<Typography variant="h6" className={styles.title}>
								<b>Filters</b>
							</Typography>
						</Box>
						<Divider />
						<Box py={1} px={3}>
							<List key={`filter-guide`} dense>
								<Typography variant="h6" className={styles.title}>
									<b>Guides</b>
								</Typography>
								<ListItem button disableGutters onClick={() => setVocalGuide(!vocalGuide)}>
									<ListItemIcon className={styles.listItem}>
										<Checkbox
											edge="start"
											checked={vocalGuide}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'With Vocal Guide'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="vocalGuide" primary={'With Vocal Guide'} />
								</ListItem>
								<ListItem
									button
									disableGutters
									onClick={() => {
										if (selectedChords.length === 0) {
											setChordGuide(!chordGuide)
										}
									}}>
									<ListItemIcon className={styles.listItem}>
										<Checkbox
											edge="start"
											checked={selectedChords.length > 0 || chordGuide}
											checkedIcon={<CheckBoxOutlinedIcon />}
											tabIndex={-1}
											disableRipple
											inputProps={{
												'aria-labelledby': 'With Chord Guide'
											}}
										/>
									</ListItemIcon>
									<ListItemText id="chordGuide" primary={'With Chord Guide'} />
								</ListItem>
							</List>
							{filters.map(
								(filter, indexFilter) =>
									filter?.name?.toLowerCase() !== catalogName?.toLowerCase() && (
										<List key={`filter-${indexFilter}`} dense>
											<Typography variant="h6" className={styles.title}>
												<b>{filter.name}</b>
											</Typography>
											{filter.items.map((item, indexItem) => (
												<ListItem
													key={item.id}
													button
													disableGutters
													onClick={handleCheck(indexFilter, indexItem)}>
													<ListItemIcon className={styles.listItem}>
														<Checkbox
															edge="start"
															checked={item.selected}
															checkedIcon={<CheckBoxOutlinedIcon />}
															tabIndex={-1}
															disableRipple
															inputProps={{
																'aria-labelledby': item.label
															}}
														/>
													</ListItemIcon>
													<ListItemText id={item.id.toString()} primary={item.label} />
												</ListItem>
											))}
										</List>
									)
							)}
							<FormControl component="fieldset" className={styles.form}>
								<Typography variant="h6" className={styles.title}>
									<b>{FilterNameEnum.Modes}</b>
								</Typography>
								<RadioGroup aria-label="select" name="select" value={scale} onChange={handleScale}>
									{scales?.scale?.map((item) => (
										<ListItem key={item?.scale_id} dense button disableGutters>
											<FormControlLabel
												className={styles.formLabel}
												value={item?.scale_id}
												control={<Radio disableRipple />}
												label={item?.name}
											/>
										</ListItem>
									))}
								</RadioGroup>
							</FormControl>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</>
	)
}
