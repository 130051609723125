import React from 'react'

import { PagesProps } from 'src/@types'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { CatalogVideosBody } from 'src/scenes/Teacher/scenes/8-Videos/components/CatalogVideosBody/CatalogVideosBody'
import { buildBreadCrumbs } from 'src/utils/utils'

export const SearchVideos = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const params = useWurrlyParams<typeof Pages.CatalogItemVideos.params[number]>()
	// TODO: fix breadcrumbs logic here

	const title = 'Search Videos'

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: 'Search Videos'
			}
		],

		params
	})

	return <CatalogVideosBody page={page} title={title} teacherId={teacherId} breadcrumbs={breadcrumbs} />
}
