import { useState } from 'react'

import { buildGenericContext } from '../../../../context/genericContext'

const useStudents = () => {
	const [searchText, setSearchText] = useState('')
	const [isSelectClassOpen, setIsSelectClassOpen] = useState(false)
	const [isSelectPackageOpen, setIsSelectPackageOpen] = useState(false)

	return {
		searchText,
		setSearchText,
		isSelectClassOpen,
		setIsSelectClassOpen,
		isSelectPackageOpen,
		setIsSelectPackageOpen
	}
}

export const [StudentsProvider, useStudentsContext] = buildGenericContext(useStudents)
