import React from 'react'

import {
	Avatar,
	Box,
	Checkbox,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					height: 36,
					'&:not(.addClass)': {
						width: 36,
						padding: theme.spacing(0.75)
					},
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		}
	})
)

export type ListItemSongProps = {
	imageUrl: string
	title: string
	author: string
	genres: string
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	selected: boolean
	disableText: string
}

export const ListItemAddSong = ({ imageUrl, title, author, genres, selected, disableText }: ListItemSongProps) => {
	const classes = useStyles()

	return (
		<ListItem className={classes.listItem}>
			<ListItemAvatar>
				<Avatar variant="rounded" src={imageUrl} />
			</ListItemAvatar>
			<ListItemText
				style={{ marginRight: '20px' }}
				disableTypography
				primary={
					<Box display={'flex'} alignItems={'baseline'}>
						<Typography noWrap variant="h6" style={{ fontSize: 18 }}>
							<b>{title}</b>
						</Typography>
						{author && (
							<Typography noWrap style={{ fontSize: 14, marginLeft: 5 }}>
								<b>({author})</b>
							</Typography>
						)}
					</Box>
				}
				secondary={genres}
			/>
			<Tooltip title={disableText && !selected ? disableText : ''}>
				<Box position="absolute" top={8} right={8}>
					<Checkbox checked={selected} />
				</Box>
			</Tooltip>
		</ListItem>
	)
}
