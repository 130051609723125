import { useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { RedoIcon } from 'src/assets/icons'
import { BigBaseButton, InfoDialog } from 'src/components'
import { useWurrlyParams } from 'src/hooks'
import { StudentPages } from 'src/routes/studentpages'
import { useStudentDetailContext } from 'src/scenes/Student/1-Home/hooks/useStudentDetail'
import { useChooseBestTakeStyles } from 'src/scenes/Student/scenes/StudentStudio/scenes/StudentChooseBestTake/stylesChooseBestTake'
import { buildRouteParameters } from 'src/utils'

export const ChooseBestTakeFooter = () => {
	const history = useHistory()
	const styles = useChooseBestTakeStyles()
	const [deleteDraftOpen, setDeleteDraftOpen] = useState(false)
	const { selectedCard, classId } = useStudentDetailContext()
	const { songId } = useWurrlyParams<typeof StudentPages.StudentRecord.params[number]>()

	return (
		<Box>
			<Box className={styles.bottomContainer}>
				<BigBaseButton
					style={{
						padding: '0px 30px',
						fontWeight: 500,
						fontSize: '18px',
						color: '#1D273D',
						backgroundColor: '#E1E4E9'
					}}
					variant="contained"
					startIcon={<RedoIcon />}
					onClick={() => {
						history.push(
							buildRouteParameters(StudentPages.StudentRecord, {
								songId
							})
						)
					}}>
					<Typography>
						<strong>Redo</strong>
					</Typography>
				</BigBaseButton>

				<Box>
					<BigBaseButton
						style={{
							marginRight: '20px',
							padding: '10px 20px',
							backgroundColor: '#E23600',
							color: 'white'
						}}
						startIcon={<DeleteIcon />}
						onClick={() => {
							setDeleteDraftOpen(true)
						}}
						type="submit">
						<Typography variant="caption">
							<strong>Delete</strong>
						</Typography>
					</BigBaseButton>
					<BigBaseButton
						style={{ padding: '6px 50px' }}
						color="secondary"
						type="submit"
						onClick={() => {
							history.push(
								buildRouteParameters(StudentPages.EditCaption, {
									songId: 86
								}),
								{
									selectedCard,
									classId
								}
							)
						}}>
						<Typography style={{ fontSize: '16px' }}>
							<strong>Submit Assignment</strong>
						</Typography>
					</BigBaseButton>
				</Box>
			</Box>
			<InfoDialog
				open={deleteDraftOpen}
				onClose={() => {
					setDeleteDraftOpen(false)
				}}
				icon="?"
				title="Delete this Draft?"
				body="Are you sure you would like to delete this draft?"
				confirmLabel="Yes, Delete Draft"
				discardLabel="No, Take Me Back"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {}}
				onDiscard={() => {
					setDeleteDraftOpen(false)
				}}
			/>
		</Box>
	)
}
