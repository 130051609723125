import { createStyles, makeStyles, fade, Theme } from '@material-ui/core'

export const useSelectClassDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		selected: {
			height: '-webkit-fill-available',
			borderColor: theme.palette.secondary.main,
			backgroundColor: fade(theme.palette.secondary.main, 0.25)
		},
		saved: {
			backgroundColor: fade(theme.palette.grey[700], 0.7)
		},
		nonSelected: {
			height: '-webkit-fill-available',
			backgroundColor: fade(theme.palette.primary.dark, 0.1)
		},
		body: {
			padding: '6px 10px',
			mt: '20px'
		},
		grid: {
			cellHeight: '50%',
			cols: 3,
			width: '500px'
		},
		check: {
			display: 'flex',
			position: 'absolute',
			top: 5,
			right: 8
		}
	})
)

export default useSelectClassDialogStyles
