import React, { useCallback, useEffect, Fragment, useState } from 'react'

import { Box, InputBase, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { BaseDialog } from 'src/components'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { RecoverPasswordMailSendDialog } from 'src/scenes/Login/components/RecoverPasswordMailSendDialog'
import { validateEmail } from 'src/utils'

import { getCyKey } from '../../../../../../../../../utils/utils'
import { PasswordInput } from '../../../../../../../components/PasswordInput'
import { useLoginStyles } from '../../../../../../../styles'
import { DialogHeader } from '../../../components/DialogHeader'
import { useStudentLoginStyles } from '../styleStudentLoginPage'
import { useStudentLoginContext } from '../useStudentLoginPage'

export const StudentLoginDialog = () => {
	const classes = useLoginStyles({})
	const styles = useStudentLoginStyles()

	const {
		getStudentTokenData,
		getStudentTokenLoading,
		getStudentTokenError,
		loginEmail,
		loginPassword,
		setLoginEmail,
		setLoginPassword,
		getStudentToken,
		loginError,
		setLoginError,
		handleClose
	} = useStudentLoginContext()
	const { loginSignIn } = useLoginContext()
	const history = useHistory()
	const goToAvatarSelectionScreen = () => history.push(StudentPages.AvatarSelection.path)
	const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false)
	const [loginDialogOpen, setLoginDialogOpen] = useState(true)
	useEffect(() => {
		if (getStudentTokenData?.login?.token && !getStudentTokenLoading) {
			const { token, avatarSelected } = getStudentTokenData?.login
			if (!avatarSelected) goToAvatarSelectionScreen()
			loginSignIn(token)
		} else if (getStudentTokenError && !getStudentTokenLoading) {
			setLoginError('Incorrect username or password')
		}
	}, [getStudentTokenData, getStudentTokenLoading, getStudentTokenError])

	const handleEmailChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setLoginEmail(e.target.value)
			setLoginError('')
		},
		[loginEmail, loginError]
	)

	return (
		<>
			<BaseDialog
				dividers={false}
				className={styles.baseDialog}
				open={loginDialogOpen}
				onClose={handleClose}
				paperProps={{
					className: styles.paper
				}}
				// header={}
				header={
					<Fragment>
						<DialogHeader style={{ marginBottom: 0 }} title="Student Sign In" />
						<Box display="flex" flexDirection="column" alignItems="center"></Box>
						<Fragment>
							<Typography className={classes.label} style={{ marginTop: 0 }}>
								<b>Email</b>
							</Typography>
							<InputBase
								data-cy={getCyKey(StudentLoginDialog, 'Email')}
								className={classes.input}
								value={loginEmail}
								fullWidth
								onChange={handleEmailChange}
							/>
							<Typography className={classes.label}>
								<b>Password</b>
							</Typography>
							<PasswordInput
								password={loginPassword}
								setPassword={setLoginPassword}
								disableValidation
							/>
							<Typography
								className={styles.forgotPasswordLabel}
								onClick={() => {
									setResetPasswordDialogOpen(true)
									setLoginDialogOpen(false)
								}}>
								Forgot Password?
							</Typography>
							<Box className={styles.errorLabelContainer}>
								{!getStudentTokenLoading && loginError && (
									<Typography className={styles.errorLabel}>{loginError}</Typography>
								)}
							</Box>
						</Fragment>
					</Fragment>
				}
				onConfirmProps={{
					className: styles.confirm
				}}
				isConfirmDisabled={
					!validateEmail(loginEmail) ||
					getStudentTokenLoading ||
					!loginEmail.length ||
					!loginPassword.length
				}
				confirmLabel={'Sign In'}
				typographyProps={{ className: styles.bold }}
				footerProps={{ className: styles.footer }}
				onConfirm={() => {
					getStudentToken({ variables: { email: loginEmail, password: loginPassword } })
				}}
			/>
			<RecoverPasswordMailSendDialog
				open={resetPasswordDialogOpen}
				isTeacher={false}
				closeDialog={() => {
					setResetPasswordDialogOpen(false)
					setLoginDialogOpen(true)
				}}
			/>
		</>
	)
}
