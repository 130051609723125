import { useEffect, useMemo, useState } from 'react'

import { DateRange } from 'src/@types'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useGetClassesQuery,
	useGetNonMusicalSkillsLessonsCoveredByClassIdQuery
} from 'src/graphql/autogenerate/hooks'
import { Class } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useClassAnalytics } from 'src/scenes/Teacher/hooks/useClassAnalytics'
import { MusicStandars } from 'src/utils/MusicStandard'

const useInsights = () => {
	const classAnalytics = useClassAnalytics()

	const { teacherData } = useLoginContext()
	const [selectedClass, setSelectedClass] = useState<Class>()
	const [classOptions, setClassOptions] = useState<Class[]>([])
	const [chartAcordeonData, setChartAcordeonData] = useState<MusicStandars | undefined>(undefined)
	const [percentage, setPercentage] = useState('')
	const [dateRange, setDateRange] = useState<DateRange>({ fromDate: new Date(), toDate: new Date() })
	const { data: classData, loading: classesLoading } = useGetClassesQuery({
		variables: { teacherId: teacherData.teacher_id },
		skip: !teacherData.teacher_id
	})
	const { data: classLessons } = useGetNonMusicalSkillsLessonsCoveredByClassIdQuery({
		variables: {
			teacherId: teacherData.teacher_id,
			classId: selectedClass?.class_id || 0
		},
		skip: !teacherData.teacher_id || !selectedClass?.class_id
	})

	// Extract classes from query result
	useEffect(() => {
		if (classData && !classesLoading && classData.teacher_by_pk?.active?.length) {
			const teacherClasses = classData.teacher_by_pk.active.map((tc) => tc.class)
			setClassOptions(teacherClasses as Class[])
			setSelectedClass(teacherClasses[0] as Class)
		}
	}, [classData, classesLoading])

	// Keep local class and classAnalytics context's in sync
	useEffect(() => {
		if (selectedClass) {
			classAnalytics.setClassId(selectedClass.class_id)
		}
	}, [selectedClass])

	const maxDate = useMemo(() => {
		const date = new Date()
		const result = new Date()
		result.setDate(date.getDate() + 1)

		return result
	}, [])

	return {
		...classAnalytics,
		selectedClass,
		setSelectedClass,
		classOptions,
		dateRange,
		setDateRange,
		chartAcordeonData,
		setChartAcordeonData,
		percentage,
		setPercentage,
		classLessons,
		maxDate
	}
}

export const [InsightsProvider, useInsightsContext] = buildGenericContext(useInsights)
