import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core'

export const useTimeLyricsViewStyles = makeStyles((theme: Theme) =>
	createStyles({
		helpText: {
			display: 'flex',
			justifyContent: 'space-between',
			paddingTop: '12px',
			gap: '24px',
			'& > span:last-child': {
				flexShrink: 0
			}
		},
		select: {
			appearance: 'none',
			border: 'none',
			width: '15px'
		},
		processLyricsContainer: { display: 'flex', justifyContent: 'end', paddingTop: '24px' },
		errorContainer: { display: 'flex', flexDirection: 'column' },
		fixButtonsContainer: { display: 'inline-flex', flexWrap: 'wrap' },
		boxFab: {
			alignItems: 'center',
			display: 'flex',
			marginTop: theme.spacing(2)
		},
		fixButton: {
			background: 'transparent',
			padding: 0,
			margin: 0,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				color: theme.palette.secondary.main
			}
		},
		fab: {
			backgroundColor: '#fff',
			minWidth: 48
		},
		boxSongTime: {
			alignItems: 'center',
			borderRadius: 4,
			display: 'flex',
			marginLeft: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			width: '100%',
			background: 'white'
		},
		tipoSongTime: {
			minWidth: 85,
			fontSize: 14
		},
		boxSlider: {
			alignItems: 'center',
			display: 'flex',
			marginLeft: theme.spacing(2),
			width: '100%'
		},
		lyricsBox: {
			scrollBehavior: 'unset',
			overscrollBehavior: 'contain',
			width: '100%',
			height: '450px',
			overflowX: 'auto',
			overflowY: 'scroll',
			listStyle: 'none',
			padding: 0,
			margin: 0,
			display: 'flex',
			flexDirection: 'column',
			gap: '24px',
			'& > li': {
				display: 'flex',
				gap: '8px',
				alignItems: 'center'
			},
			'& > li:first-child': {
				paddingTop: '12px'
			},
			'& > li > button': {
				flexShrink: 0,
				width: '54px',
				height: '54px',
				marginRight: '12px',
				'&:focus': {
					outline: 0
				}
			},
			'& > li > div': {
				display: 'flex',
				gap: '12px',
				// margin: '8px 0',
				flexGrow: 1,
				'& > button': {
					border: 'none',
					appearance: 'none',
					background: 'transparent',
					position: 'relative',
					padding: '18px 25px',
					display: 'inline-flex',
					flexDirection: 'column',
					'&:focus': {
						outline: 0
					},
					'& > span': {
						fontSize: '20px',
						color: 'gray'
					},
					'&.active': {
						borderBottom: `3px solid ${alpha(theme.palette.secondary.main, 0.4)}`
					}
				}
			}
		},
		timeRecord: {
			fontSize: '12px !important',
			background: 'white',
			width: '100%',
			zIndex: 1,
			border: '1px solid gray',
			position: 'absolute',
			top: '-12px',
			padding: '2.5px 5px',
			borderRadius: '4px'
		},
		currentRow: {
			background: alpha(theme.palette.secondary.main, 0.4)
		},
		currentRowText: {
			color: `black !important`
		},
		completedListItem: {
			padding: '1rem 2rem',
			backgroundColor: '#DCEEE1',
			color: 'green',
			fontWeight: 'bold',
			display: 'flex',
			justifyContent: 'start',
			alignItems: 'center',
			gap: '12px'
		},
		checkIcon: {
			backgroundColor: '#52A86A',
			color: 'white',
			width: '30px',
			height: '30px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '15px'
		},
		flippedRedoIcon: {
			transform: 'scale(-1, 1)'
		}
	})
)

export default useTimeLyricsViewStyles
