import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AddToClassDialog, BaseLink, InfoDialog } from 'src/components'
import { useSetInsertSequenceToClassesMutation } from 'src/graphql/autogenerate/hooks'
import { Class, Class_Sequence_Insert_Input, Sequence } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

type AddSequenceItemToClassProp = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	sequence?: Sequence
}

export const AddSequenceItemToClass = ({ sequence, isOpen, setIsOpen }: AddSequenceItemToClassProp) => {
	const history = useHistory()
	const [isErrorOpen, setIsErrorOpen] = useState(false)
	const [sequenceItemId, setSequenceItemId] = useState<number | undefined>()
	const [itemClasses, setItemClasses] = useState<Class[]>([])
	const [insertSequenceItemToClasses] = useSetInsertSequenceToClassesMutation()
	const [searchText, setSearchText] = useState('')

	useEffect(() => {
		return () => {
			setSequenceItemId(undefined)
			setIsOpen(false)
			setIsErrorOpen(false)
			setItemClasses([])
		}
	}, [])

	useEffect(() => {
		if (!sequenceItemId) setIsOpen(false)
	}, [sequenceItemId])

	useEffect(() => {
		if (isOpen && sequence) {
			setSequenceItemId(sequence.sequence_id)
			setItemClasses(sequence.class_sequences?.map((item) => item.class) || [])
		} else if (isOpen && !sequence) {
			setIsErrorOpen(true)
		}
	}, [isOpen])

	return (
		<Box>
			<InfoDialog
				open={isErrorOpen}
				onClose={() => {
					setIsErrorOpen(false)
					setIsOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={(e) => {
					e.stopPropagation()
					setIsErrorOpen(false)
					setIsOpen(false)
				}}
			/>

			<AddToClassDialog
				isOpen={!!sequenceItemId}
				setIsOpen={setSequenceItemId}
				itemClasses={itemClasses}
				itemName="Sequence"
				setSearchText={setSearchText}
				searchText={searchText}
				title="Save Sequence to Class"
				description="This will save the Sequence so that you can view and teach right from your Class page.
				It will not be visible to your students, but you can share content from the Lesson by creating an Assignment or adding Songs or Videos to a Class."
				onConfirm={async (selectedClasses) => {
					const array = [...selectedClasses]
					const classes = array.map((cls) => {
						return {
							sequence_id: sequenceItemId,
							class_id: cls.class_id
						} as Class_Sequence_Insert_Input
					})
					if (classes && classes.length) {
						await insertSequenceItemToClasses({
							variables: { classes },
							update: (cache, { data }) => {
								const classesToUpdateInCache = data?.insert_class_sequence?.returning
								if (!classesToUpdateInCache) return

								let identify
								classesToUpdateInCache.forEach((cls) => {
									identify = cache.identify(cls.class)

									cache.evict({
										id: identify,
										fieldName: 'class_sequence_items'
									})
									cache.evict({
										id: identify,
										fieldName: 'class_sequences'
									})
									cache.evict({
										id: identify,
										fieldName: 'class_sequences_aggregate'
									})
									cache.evict({
										id: identify,
										fieldName: 'class_sequence_items_aggregate'
									})
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_topic'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_topic_by_pk'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_by_pk'
								})
							}
						})
					}
				}}
				onSuccess={{
					discardLabel: 'Back to Lessons',
					onDiscard: () => history.push(buildRouteParameters(Pages.Lessons)),
					confirmLabel: 'Add Assignment'
				}}
				footer={
					<BaseLink
						onClick={(e) => {
							e.stopPropagation()
							history.push(buildRouteParameters(Pages.Classes))
						}}>
						<Typography>
							<b>View Classes</b>
						</Typography>
					</BaseLink>
				}
			/>
		</Box>
	)
}
