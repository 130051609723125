import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { Videocam, VideocamOff } from '@material-ui/icons'
import { ActionButton } from 'src/components'
import { VideoPreview } from 'src/components/Studio/Preview/VideoPreview'
import { InstrumentNotesPanel } from 'src/components/Studio/Record/InstrumentNotesPanel/InstrumentNotesPanel'
import { RecordCountDown } from 'src/components/Studio/Record/RecordCountDown'
import { useStudioStyles } from 'src/components/Studio/Record/styles/studioStyles'
import { AppliedStickers } from 'src/components/Studio/Review/effects/AppliedStickers/AppliedStickers'
import { EffectsTabs } from 'src/components/Studio/Review/effects/EffectsTabs/EffectsTabs'
import { Filter_Frame } from 'src/graphql/autogenerate/schemas'

import { useStudioRecordContext } from './useStudioRecord'
import { videoConstraints } from './utils'

export const VideoRecorder = () => {
	const styles = useStudioStyles()
	const [previewStream, setPreviewStream] = useState<MediaStream | null>(null)

	const {
		isCameraOn,
		toggleCamera,
		countDown,
		isRecording,
		selectedInstrumentGuide,
		isApplyingEffects,
		setIsApplyingEffects,
		selectedFrameFilter,
		updateVideoDimensions,
		selectedColorFilter,
		selectedStickerFilter,
		isBlankSong
	} = useStudioRecordContext()

	const hideTab = () => {
		setIsApplyingEffects(false)
	}

	useEffect(() => {
		if (isCameraOn) {
			const handleSuccess = (stream: MediaStream) => setPreviewStream(stream)
			const handleError = (error: unknown) => console.error('Could not get preview', error)
			const constraints = { audio: false, video: videoConstraints }
			navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError)
		} else {
			setPreviewStream(null)
		}
	}, [isCameraOn])

	return (
		<Box className={styles.videoContainer} onDragOver={(e) => e.preventDefault()}>
			{!isRecording && !countDown && (
				<ActionButton
					onClick={toggleCamera}
					selected={isCameraOn}
					className={styles.recordBtn}
					icon={isCameraOn ? <Videocam /> : <VideocamOff />}
				/>
			)}
			<RecordCountDown countDown={countDown} />
			<VideoPreview
				stream={isCameraOn ? previewStream : null}
				selectedFrameFilter={selectedFrameFilter as Filter_Frame}
				updateVideoDimensions={updateVideoDimensions}
				selectedColorFilter={selectedColorFilter?.name}
				isMirrored
			/>
			{!isBlankSong && (
				<InstrumentNotesPanel
					guide={selectedInstrumentGuide}
					className={isApplyingEffects ? styles.hidden : undefined}
				/>
			)}
			<EffectsTabs
				hideBalance
				className={isApplyingEffects ? undefined : styles.hidden}
				stickersSelected={selectedStickerFilter?.length}
				hideTab={hideTab}
			/>
			<AppliedStickers boxProps={{ className: styles.stickers }} />
		</Box>
	)
}
