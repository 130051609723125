import { useEffect } from 'react'

import { Student, Teacher, Wurrly_Role_Enum } from 'src/graphql/autogenerate/schemas'
import { setSentryCurrentUser, clearSentryCurrentUser } from 'src/utils/sentry'

type useSentryProps = {
	isAuthenticated: boolean
	studentData?: Pick<Student, 'student_id' | 'email'> | null
	teacherData?: Pick<Teacher, 'teacher_id' | 'email'> | null
}

export const useSentry = ({ isAuthenticated, studentData, teacherData }: useSentryProps) => {
	const handleLoggedUser = () => {
		const userId = studentData?.student_id || teacherData?.teacher_id
		const role = studentData?.student_id ? Wurrly_Role_Enum.Student : Wurrly_Role_Enum.Teacher
		const email = studentData?.email || teacherData?.email

		if (!userId) return

		setSentryCurrentUser({
			id: String(userId),
			email,
			role
		})
	}

	const handleLogout = () => {
		clearSentryCurrentUser()
	}

	useEffect(() => {
		if (isAuthenticated) {
			handleLoggedUser()
		} else {
			handleLogout()
		}
	}, [isAuthenticated, studentData, teacherData])
}
