import { useEffect, useState } from 'react'

import { useGetModuleByLessonPlanIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Module } from 'src/graphql/autogenerate/schemas'

export const useModulesDialog = (isOpen: boolean, lessonPlanId: number) => {
	const [modules, setModules] = useState<Module[]>([])
	// const params = useWurrlyParams<typeof Pages.ClassLessonDetail.params[number]>()
	// const lessonPlanId = Number(params.lessonPlanId)
	const [moduleId, setModuleId] = useState<number>()
	const [addToClassModule, setAddToClassModule] = useState<Module>()
	const [isAddModuleToClassOpen, setIsAddModuleToClassOpen] = useState(false)
	const [isOpenProductDialog, setIsOpenProductDialog] = useState(false)
	const [getModule, { data, loading }] = useGetModuleByLessonPlanIdLazyQuery()

	useEffect(() => {
		if (data) setModules(data?.module as Module[])
	}, [data])

	useEffect(() => {
		if (isOpen)
			getModule({
				variables: {
					lessonPlanId
				}
			})
	}, [isOpen])

	const handleOpenProductDialog = () => setIsOpenProductDialog(true)
	const handleCloseProductDialog = () => setIsOpenProductDialog(false)

	return {
		modules,
		loading,
		setAddToClassModule,
		setIsAddModuleToClassOpen,
		isAddModuleToClassOpen,
		addToClassModule,
		handleOpenProductDialog,
		isOpenProductDialog,
		handleCloseProductDialog,
		setModuleId,
		moduleId
	}
}
