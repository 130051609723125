import { createStyles, fade, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actionButton: {
			style: { border: '1px solid rgba(50, 51, 66, 0.5)' }
		},
		link: {
			color: theme.palette.secondary.dark
		},
		panel: {
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white,
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			},
			'& .MuiButton-root:first-child': {
				paddingLeft: theme.spacing(4),
				backgroundColor: fade(theme.palette.common.black, 0.3),
				color: theme.palette.common.white,
				boxShadow: 'none'
			}
		},
		banner: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '10/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		}
	})
)
