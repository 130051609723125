import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ListItemAssignment } from 'src/components/listAssignments/ListItemAssignment'
import { Assignment, Submission, Submission_State_Enum } from 'src/graphql/autogenerate/schemas'
import { StudentPages } from 'src/routes/studentpages'
import { useAssignmentsContext } from 'src/scenes/Student/hooks/useAssignments'
import { buildRouteParameters } from 'src/utils'

type ItemAssignmentsProps = {
	assignment: Assignment
	submission: Submission
	isInStudentView?: boolean
	expired?: boolean
}

export const ItemAssignments = ({
	assignment,
	submission,
	isInStudentView = false,
	expired
}: ItemAssignmentsProps): JSX.Element => {
	const { classes, selectedClassId } = useAssignmentsContext()
	const history = useHistory()

	const goToAssignmentDetail = () => {
		const assignmentHasBeenSubmitted = [
			Submission_State_Enum.Submitted,
			Submission_State_Enum.Graded
		].includes(submission.state as Submission_State_Enum)

		if (isInStudentView) {
			const classId = submission.class_assignment?.class_id ?? 0
			const wurrlyId = submission.submission_wurrlies?.[0]?.wurrly_id ?? 0
			const submissionId = submission.submission_id ?? 0
			history.push(
				assignmentHasBeenSubmitted
					? buildRouteParameters(StudentPages.ClassWurrly, { classId, wurrlyId })
					: buildRouteParameters(StudentPages.AssignmentDetails, { submissionId })
			)
		}
		// TODO: Add redirect handler for teacher view
	}

	return (
		<Box position="relative">
			<Box style={{ cursor: 'pointer' }}>
				<ListItemAssignment
					expired={expired}
					title={assignment.name}
					instructions={assignment?.instructions}
					classTitle={classes?.class.find((i) => i.class_id === selectedClassId)?.title || ''}
					dueDate={assignment.due_date}
					dueTime={assignment.due_time}
					state={submission?.state || ''}
					grade={submission?.grade || ''}
					btnView={{ onClick: goToAssignmentDetail }}
				/>
			</Box>
		</Box>
	)
}
export default ItemAssignments
