import { useEffect, useState } from 'react'

import { InfoDialog, InfoDialogProps } from './InfoDialog'

type MutationDialogProps = InfoDialogProps & {
	deleteElement?: 'Lesson' | 'Video' | 'Sequence' | 'Sequence Topic' | 'Song' | 'Module' | 'Playlist' | 'Tip'
	success: boolean
	error: boolean
	successProps?: Omit<InfoDialogProps, 'open' | 'onClose' | 'icon'> & { onClose?: () => void }
	errorProps?: Omit<InfoDialogProps, 'open' | 'onClose'> & { onClose?: () => void }
	onSuccessLabel?: string
	onSuccessOnClick?: () => void
}
export const MutationDialog = ({
	deleteElement,
	title,
	body,
	icon,
	successProps,
	errorProps,
	onClose,
	success,
	error,
	confirmButton,
	discardButton,
	onSuccessLabel,
	onSuccessOnClick,
	maxWidth = 'xs',
	...props
}: MutationDialogProps) => {
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [successDialogOpen, setSuccessDialogOpen] = useState(false)

	const closeDeleteDialog = () => onClose?.({}, 'backdropClick')
	const openErrorDialog = () => setErrorDialogOpen(true)
	const closeErrorDialog = () => setErrorDialogOpen(false)
	const openSuccessDialog = () => setSuccessDialogOpen(true)
	const closeSuccessDialog = () => setSuccessDialogOpen(false)
	const closeAllDialogs = () => {
		closeDeleteDialog()
		closeSuccessDialog()
		closeErrorDialog()
	}

	useEffect(() => {
		setErrorDialogOpen(error)
		setSuccessDialogOpen(success)
	}, [error, success])

	const handleSuccessConfirm = () => {
		if (onSuccessOnClick) onSuccessOnClick()
		closeAllDialogs()
	}

	const handleErrorConfirm = () => {
		errorProps?.confirmButton?.onClick()
		closeAllDialogs()
	}

	useEffect(() => {
		if (error) {
			closeDeleteDialog()
			closeSuccessDialog()
			openErrorDialog()
		}
	}, [error])

	useEffect(() => {
		if (success) {
			closeDeleteDialog()
			closeErrorDialog()
			openSuccessDialog()
		}
	}, [success])

	return (
		<>
			<InfoDialog
				maxWidth={maxWidth}
				{...props}
				title={title || `Delete this ${deleteElement}?`}
				body={body || `Are you sure you want to delete this ${deleteElement}?`}
				icon={icon || 'question'}
				onClose={onClose}
				confirmButton={{
					label: confirmButton?.label || 'Yes',
					onClick: confirmButton?.onClick || closeAllDialogs,
					...confirmButton
				}}
				discardButton={{
					label: discardButton?.label || 'No',
					onClick: discardButton?.onClick || closeAllDialogs
				}}
			/>

			<InfoDialog
				maxWidth={maxWidth}
				onClose={closeErrorDialog}
				icon="warning"
				title={errorProps?.title || `Something went wrong`}
				body={errorProps?.body || `Something went wrong while deleting this ${deleteElement}`}
				open={errorDialogOpen}
				confirmButton={{ label: 'Close', onClick: handleErrorConfirm }}
				{...errorProps}
			/>
			<InfoDialog
				maxWidth={maxWidth}
				open={successDialogOpen}
				onClose={closeSuccessDialog}
				icon="success"
				title={successProps?.title || `Success!`}
				body={successProps?.body || `You have succesfully deleted this ${deleteElement}.`}
				confirmButton={{
					label: onSuccessLabel || 'Confirm',
					onClick: handleSuccessConfirm
				}}
				{...successProps}
			/>
		</>
	)
}
