import { Location } from 'history'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { useLoginContext } from 'src/hooks/useLogin'

import NotFound from './404'

export const NotFoundContainer = () => {
	const { isAuthenticated, teacherData: teacher } = useLoginContext()

	return <NotFound isLoggedIn={isAuthenticated && !!teacher.teacher_id} />
}

export const RouteNotFound = () => (
	<Redirect
		to={{
			pathname: '/404',
			state: { notFoundError: true }
		}}
	/>
)

interface IProps extends RouteComponentProps {
	location: Location
	children: React.ReactElement
}

export const CaptureRouteNotFound = withRouter(({ children, location }: IProps) => {
	return location?.state?.notFoundError ? <NotFoundContainer /> : children
})
