import React, { useEffect, useState } from 'react'

import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { ActivityBoxEmpty, SelectSort } from 'src/components'
import { AssignmentPaginationList } from 'src/components/AssignmentPaginationList'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'
import { useStudentAssignments } from 'src/scenes/Teacher/scenes/1-Home/components/StudentsSummary/StudentAssignments/useStudentAssignments'
import { SortOrder } from 'src/utils'

import { useClassDetailsContext } from '../useClassDetails'

export const ClassAssignments = () => {
	const { classId } = useClassDetailsContext()
	const [assignmentItems, setAssignmentItems] = useState<Class_Assignment[]>([])

	const { PAGE_ELEMENTS, loading, data, setSelectedClassId, refetch, sortBy, setSortBy } =
		useStudentAssignments()

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSortBy(selectedSort)
	}

	const handlePageChange = (page: number) => {
		refetch({ offset: (page - 1) * PAGE_ELEMENTS })
	}

	useEffect(() => {
		if (data?.class_assignment) {
			setAssignmentItems(data.class_assignment as unknown as Class_Assignment[])
		}
	}, [data, loading])

	useEffect(() => {
		setSelectedClassId(classId)
	}, [classId, setSelectedClassId])

	return (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Assignments graded</b>
				</Typography>
				{assignmentItems.length > 0 && (
					<SelectSort cyKey="StudentAssignments" value={sortBy} onChange={handleSort} />
				)}
			</Box>
			{loading || assignmentItems.length > 0 ? (
				<Paper>
					<AssignmentPaginationList
						rowsPerPage={PAGE_ELEMENTS}
						limit={assignmentItems.length}
						data={assignmentItems}
						onPageChange={handlePageChange}
						loading={loading}
						totalRows={data?.class_assignment_aggregate.aggregate?.count ?? 0}
					/>
				</Paper>
			) : (
				<ActivityBoxEmpty
					title="No upcoming Assignments."
					description="Start by creating an Assignment for a Class. The upcoming Assignments will appear here."
				/>
			)}
		</Grid>
	)
}
