import { useEffect, useState } from 'react'

import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { ActivityBoxEmpty, PaginationList, PaginationListDataItem } from 'src/components'
import { buildStudentPointsPaginationItem } from 'src/components/PaginationList/utils'
import { useGetClassPointsByBadgeEventLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Student_Badge } from 'src/graphql/autogenerate/schemas'
import { useClassDetailsContext } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/useClassDetails'

export const ClassStudentBadgeActivity = () => {
	const pageElements = 5
	const { classId } = useClassDetailsContext()
	const [pointItems, setPointsItems] = useState<PaginationListDataItem[]>([])
	const [loadingStudents, setLoadingStudents] = useState<boolean>(false)

	const [getBadgeData, { data: badgeData, loading: loadingBadgeData, refetch }] =
		useGetClassPointsByBadgeEventLazyQuery()

	useEffect(() => {
		if (classId !== 0) {
			getBadgeData({
				variables: { classId, offset: 0, limit: pageElements }
			})
		}
	}, [classId])

	useEffect(() => {
		setLoadingStudents(loadingBadgeData)
		if (badgeData) {
			let badgeCardData: PaginationListDataItem[] = []

			badgeCardData = badgeData.student_badge.map((event) =>
				buildStudentPointsPaginationItem(event as Student_Badge, event.badge.points)
			)

			setPointsItems(badgeCardData)
		}
	}, [badgeData, loadingBadgeData])

	const handlePageChange = (page: number) => {
		setLoadingStudents(true)
		refetch({ offset: (page - 1) * pageElements })
	}

	return pointItems.length > 0 ? (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Lastest Earned Points</b>
				</Typography>
			</Box>
			<Paper>
				<PaginationList
					rowsPerPage={pageElements}
					limit={pointItems.length}
					data={pointItems}
					onPageChange={handlePageChange}
					loading={loadingStudents}
					totalRows={badgeData?.student_badge_aggregate.aggregate?.count ?? 0}
				/>
			</Paper>
		</Grid>
	) : (
		<Grid xs={6} item>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} height={40}>
				<Typography variant="h6">
					<b>Lastest Earned Points</b>
				</Typography>
			</Box>
			<ActivityBoxEmpty title="Your Students haven't earned any badges yet." />
		</Grid>
	)
}
