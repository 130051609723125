import React from 'react'

import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		accordion: {
			backgroundColor: 'initial',
			boxShadow: 'none',
			margin: 0,
			'& .MuiAccordionSummary-root': {
				padding: 0,
				flexDirection: 'row-reverse',
				'& .MuiIconButton-root': {
					margin: 0,
					marginLeft: theme.spacing(-1.5)
				}
			},
			'& .MuiAccordionDetails-root': {
				padding: 0,
				display: 'block'
			},
			'& .MuiAccordionActions-root': {
				padding: `${theme.spacing(2.5)}px 0 0`
			}
		}
	})
)

type DropDownProps = {
	defaultExpanded?: boolean
	expanded?: boolean
	summary: React.ReactNode
	details: React.ReactNode
	actions?: React.ReactNode
}

export const AccordionWrapper = ({ summary, details, actions, defaultExpanded, expanded }: DropDownProps) => {
	const styles = useStyles()

	return (
		<Accordion defaultExpanded={defaultExpanded} className={styles.accordion} expanded={expanded}>
			{summary && (
				<AccordionSummary
					expandIcon={<ArrowDropDownIcon />}
					aria-controls="panel-content"
					id="panel-header">
					{summary}
				</AccordionSummary>
			)}
			<AccordionDetails>{details}</AccordionDetails>
			<AccordionActions>{actions}</AccordionActions>
		</Accordion>
	)
}
