import { Fragment, useState } from 'react'

import { Box, Divider, List } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { noop } from 'lodash'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { Module, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { AddModuleToClass } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Modules/components/AddModuleToClass'
import { generateUniqueId, buildImagePath } from 'src/utils'

import { ListItemModule } from './ListItemModule'

const useStyles = makeStyles((theme: Theme) => ({
	boxModule: {
		padding: 0,
		'& .listItemWrapper': {
			// backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

type ListModulesProps = {
	spacing?: number
	lineDivider?: boolean
	data?: Module[]
	partner_id?: number
}

export const ListModule = ({ spacing = 0, lineDivider, data, partner_id }: ListModulesProps) => {
	const styles = useStyles(spacing)
	const [addToClassModule, setAddToClassModule] = useState<Module>()
	const [isAddModuleToClassOpen, setIsAddModuleToClassOpen] = useState(false)
	const [moduleId, setmoduleId] = useState<number>()
	const [isOpen, setIsOpen] = useState(false)

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	return (
		<>
			{isOpen && moduleId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={moduleId}
					productName={Product_Name_Enum.Module}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<Fragment>
				<List className={styles.boxModule}>
					{data &&
						data.filter(Boolean).map((module, i) => (
							<Box className="listItemWrapper" key={generateUniqueId()}>
								<ListItemModule
									partner_id={partner_id}
									module_id={module.module_id}
									imageUrl={buildImagePath(module.image_path)}
									title={module.title}
									description={module.description}
									genres={module?.module_lesson_plan?.map((lesson) =>
										lesson?.lesson_plan?.lesson_plan_catalog_item.map(
											(item) => item?.catalog_item?.name
										)
									)}
									totallessons={module.module_lesson_plan_aggregate?.aggregate?.count ?? 0}
									btnAddToClass={{
										onClick: (e) => {
											e.stopPropagation()
											setAddToClassModule(module)
											setIsAddModuleToClassOpen(true)
										}
									}}
									btnSuscription={{
										onClick: (e) => {
											e.stopPropagation()
											setmoduleId(module.module_id)
											handleOpenProductDialog()
										}
									}}
									btnLearnMore={{}}
									subscription={module.is_purchased_by_teacher?.valueOf()}
									visibility={module.lockable_content?.visibility}
								/>
								{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
							</Box>
						))}
				</List>
				<AddModuleToClass
					isOpen={isAddModuleToClassOpen}
					setIsOpen={setIsAddModuleToClassOpen}
					module={addToClassModule}
				/>
			</Fragment>
		</>
	)
}
