import { useEffect, useState } from 'react'

import moment from 'moment'
import {
	useGetBadgesLazyQuery,
	useGetChallengesQuery,
	useGetFinishedChallengesLazyQuery,
	useGetStudentBadgesLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { GetBadgesQuery, GetStudentBadgesQuery } from 'src/graphql/autogenerate/operations'
import { Challenge } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'

type studentBadgeType = BadgeType & StudentBadgeType

type BadgeType = GetBadgesQuery['badge'][0]
type StudentBadgeType = GetStudentBadgesQuery['student_badge_resume'][0]

export const useStudentContent = () => {
	const { studentData: student } = useLoginContext()
	const [isBadgesModalOpen, setIsBadgesModalOpen] = useState(false)
	const [badgeInfo, setBadgeInfo] = useState<studentBadgeType>()
	const [challenges, setChallenges] = useState<Challenge[]>([])
	const [isbadgeInfoOpen, setisbadgeInfoOpen] = useState(false)
	const [challengesOpen, setChallengesOpen] = useState(false)
	const [isSelectClassOpen, setIsSelectClassOpen] = useState(false)
	const [totalPoints, setTotalPoints] = useState(0)
	const [getBadges, { data: badgesContent, loading: loadingBadges }] = useGetBadgesLazyQuery()
	const [getStudentBadges, { data: studentBadges, loading: loadingStudentBadges }] =
		useGetStudentBadgesLazyQuery()
	const [getFinishedChallenges, { data: finishedChallenges }] = useGetFinishedChallengesLazyQuery()

	const { data: challengeData, loading: ladingChallenge } = useGetChallengesQuery({
		variables: {
			studentId: student.student_id,
			firstTime: false,
			date: moment().format('YYYY-MM-DDTHH:mm:ss')
		}
	})

	useEffect(() => {
		getBadges()
		getStudentBadges({ variables: { studentId: student.student_id } })
		getFinishedChallenges({
			variables: { studentId: student.student_id }
		})
	}, [student.student_id])

	useEffect(() => {
		let counter = 0
		if (studentBadges) {
			studentBadges.student_badge_resume.map((i) => (counter += i.badge_total_points))
		}
		setTotalPoints(counter)
	}, [studentBadges])

	useEffect(() => {
		if (challengeData && !ladingChallenge)
			setChallenges(challengeData.getStudentChallenges.challenges as Challenge[])
	}, [challengeData, ladingChallenge])

	const showHowToEarnBadges = () => {
		setIsBadgesModalOpen(true)
	}
	const showBadgeInfo = (badge: studentBadgeType) => {
		setBadgeInfo(badge)
		setisbadgeInfoOpen(true)
	}

	return {
		showBadgeInfo,
		student,
		showHowToEarnBadges,
		isBadgesModalOpen,
		setIsBadgesModalOpen,
		badgeInfo,
		challengeData,
		challenges,
		ladingChallenge,
		isbadgeInfoOpen,
		setisbadgeInfoOpen,
		challengesOpen,
		setChallengesOpen,
		isSelectClassOpen,
		setIsSelectClassOpen,
		badgesContent,
		loadingBadges,
		finishedChallenges,
		setBadgeInfo,
		studentBadges: studentBadges ? studentBadges : undefined,
		loadingStudentBadges,
		totalPoints
	}
}
