import { useEffect, useState } from 'react'

import moment from 'moment'
import { ChallengeDialog } from 'src/components/ChallengeDialog/ChallengeDialog'
import { useGetChallengesLazyQuery, useGetFinishedChallengesLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Challenge } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { NO_CACHE } from 'src/utils/constants'

export const ChallengeDialogContainer = () => {
	const { studentData: student } = useLoginContext()
	const [currentChallenges, setCurrentChallenges] = useState<Challenge[]>([])
	const [getChallenges, { data: challengeData, loading: LoadingChallenge }] = useGetChallengesLazyQuery(NO_CACHE)
	const [getFinishedChallenges, { data: finishedChallenges }] = useGetFinishedChallengesLazyQuery()

	const todayDate = moment().format('YYYY-MM-DDTHH:mm:ss')

	useEffect(() => {
		if (student.student_id !== 0) {
			getChallenges({ variables: { studentId: student.student_id, firstTime: true, date: todayDate } })
			getFinishedChallenges({
				variables: { studentId: student.student_id }
			})
		}
	}, [student])

	useEffect(() => {
		if (challengeData?.getStudentChallenges.challenges.length) {
			setCurrentChallenges(challengeData.getStudentChallenges.challenges as Challenge[])
		}
	}, [challengeData])

	return (
		<>
			{!!currentChallenges.length && (
				<ChallengeDialog
					challengeData={currentChallenges}
					finishedChallenges={finishedChallenges}
					ladingChallenge={LoadingChallenge}
					studentId={student.student_id}
				/>
			)}
		</>
	)
}
