import { CircularProgress, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useHistory } from 'react-router-dom'
import { ThinCheckIcon } from 'src/assets/icons'
import { InfoDialog } from 'src/components'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { useGradingContext } from '../../useGrading'
import useStyles from './ActionDialogs.styles'
import { DialogTitle } from './DialogTitle'
type ActionDialogsProps = {
	classId: number
	classAssignmentId: number
}

export enum ActionDialogsEnum {
	GoBackWithouSaving,
	ChangeSubmissionWithoutGrading,
	ConfirmGrades,
	FinishedGrading,
	MutationSuccess,
	MutationError
}

export const ActionDialogs = ({ classAssignmentId, classId }: ActionDialogsProps) => {
	const styles = useStyles()
	const history = useHistory()
	const { isDialogOpen, closeDialog, updateSubmissionsMutation, mutationLoading } = useGradingContext()
	const discardGoBackWithoutSaving = () =>
		history.push(
			buildRouteParameters(Pages.ClassAssignmentDetail, {
				classId,
				classAssignmentId
			})
		)

	const closeGoBackWithoutSaving = () => closeDialog(ActionDialogsEnum.GoBackWithouSaving)
	const closeConfirmGrades = () => closeDialog(ActionDialogsEnum.ConfirmGrades)
	const closeFinishedGrading = () => closeDialog(ActionDialogsEnum.FinishedGrading)
	const closeMutationError = () => closeDialog(ActionDialogsEnum.MutationError)
	const closeMutationSuccess = () => closeDialog(ActionDialogsEnum.MutationSuccess)
	const confirmGrades = () => updateSubmissionsMutation()
	const confirmFinishedGrading = () =>
		history.push(
			buildRouteParameters(
				Pages.ClassAssignmentDetail, // Route with paramters
				{ classAssignmentId, classId }
			)
		)

	return (
		<>
			<InfoDialog
				open={isDialogOpen(ActionDialogsEnum.GoBackWithouSaving)}
				onClose={closeGoBackWithoutSaving}
				icon="!"
				title={<DialogTitle text="Go Back Without Saving?" />}
				body="You will lose all the details you’ve entered up until this point."
				discardLabel="Yes, Go Back"
				onDiscard={discardGoBackWithoutSaving}
				confirmLabel="No, Cancel"
				onConfirm={closeGoBackWithoutSaving}
			/>

			<InfoDialog
				open={isDialogOpen(ActionDialogsEnum.ConfirmGrades)}
				onClose={mutationLoading ? () => {} : closeGoBackWithoutSaving}
				icon="!"
				title={<DialogTitle text="Confirm Your Grades for this Assignment?" />}
				body="This will submit all your grades to your students at once."
				discardLabel="Cancel"
				onDiscard={closeConfirmGrades}
				confirmLabel={
					<Typography className={styles.submitGrades}>
						Submit Grades{' '}
						{mutationLoading ? (
							<CircularProgress className={styles.loader} color="secondary" size={20} />
						) : (
							<CheckCircleIcon className={styles.checkIcon} />
						)}
					</Typography>
				}
				confirmProps={{ disabled: mutationLoading }}
				onConfirm={confirmGrades}
			/>
			<InfoDialog
				open={isDialogOpen(ActionDialogsEnum.FinishedGrading)}
				onClose={closeFinishedGrading}
				icon={<ThinCheckIcon />}
				title={<DialogTitle text="You Have Finished Grading This Assignment!" />}
				body="You can review the grades you’ve submitted or view the graded Assignment."
				discardLabel="Review & Grade"
				onDiscard={closeFinishedGrading}
				confirmLabel="View Assignment"
				onConfirm={confirmFinishedGrading}
			/>
			<InfoDialog
				open={isDialogOpen(ActionDialogsEnum.MutationError)}
				onClose={closeMutationError}
				icon="x"
				title={<DialogTitle text="Something Went Wrong" />}
				body="Something went wrong"
				confirmLabel="Done"
				onConfirm={closeMutationError}
			/>

			<InfoDialog
				open={isDialogOpen(ActionDialogsEnum.MutationSuccess)}
				onClose={closeMutationSuccess}
				icon={<ThinCheckIcon />}
				title={<DialogTitle text="Success!" />}
				body="You have succesfully graded these wurrlies."
				confirmLabel="Done"
				onConfirm={closeMutationSuccess}
			/>
		</>
	)
}
