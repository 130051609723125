import { useEffect } from 'react'

import { Link } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Link as LinkRD } from 'react-router-dom'
import { ListLessons } from 'src/components/ListLessons'
import { useGetFeaturedLessonsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import useLessonsStyles from 'src/scenes/Teacher/scenes/6-Lessons/Lessons.styles'
import { buildPagination } from 'src/utils'

export type FeaturedLessonsProps = {
	teacherId: number
}

export const AllLessons: React.FC<FeaturedLessonsProps> = ({ teacherId }) => {
	const styles = useLessonsStyles()
	const [getFeaturedLessons, { data: featuredLessonsData }] = useGetFeaturedLessonsLazyQuery(
		buildPagination(teacherId, 0)
	)

	const lessons = (featuredLessonsData?.lesson_plan || []) as Lesson_Plan[]

	useEffect(() => {
		getFeaturedLessons()
	}, [])

	const LinkToAll = (location: Location) => {
		return {
			...location,
			pathname: Pages.LessonsAll.path
		}
	}

	return (
		<>
			<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>Lessons</b>
				</Typography>
				<div style={{ flexGrow: 1 }} />
				<Link className={styles.link} component={LinkRD} to={LinkToAll}>
					<b>View All</b>
				</Link>
			</Box>
			<ListLessons teacherId={teacherId} spacing={1.5} data={lessons} />
		</>
	)
}
