import { FiberManualRecord as Dot } from '@material-ui/icons'

type HiddenPasswordProps = {
	length: number
}

export const HiddenPassword = ({ length }: HiddenPasswordProps) => {
	return (
		<>
			{Array(length)
				.fill(0)
				.map((_i, index) => (
					<Dot key={index} style={{ width: '.4em', marginRight: '.2em' }} />
				))}
		</>
	)
}
