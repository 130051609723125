import { useEffect, useState } from 'react'

import { ChartDataType, DateRange } from 'src/@types'
import { useStudentProductivityTimeRangeQuery } from 'src/graphql/autogenerate/hooks'
import { Student_Productivity_Daily, Student_Productivity_Daily_Bool_Exp } from 'src/graphql/autogenerate/schemas'
import { COLORS } from 'src/styles/colors'

type useClassProductivityChartProps = {
	classId: number
	dateRange: DateRange
}

const getDateFilter2 = (date: DateRange): Student_Productivity_Daily_Bool_Exp => {
	const { fromDate, toDate } = date
	const dayStart = fromDate?.getDate() || 0
	const dayEnd = toDate?.getDate() || 0
	// months starts in 0 instead of 1 so we have to add 1
	const monthStart = (fromDate?.getMonth() || 0) + 1
	const monthEnd = (toDate?.getMonth() || 0) + 1
	const yearStart = fromDate?.getFullYear() || 0
	const yearEnd = toDate?.getFullYear() || 0

	return {
		_or: [
			{ month: { _gte: monthStart }, day: { _gte: dayStart }, year: { _gte: yearStart } },
			{
				month: { _eq: monthEnd },
				day: { _lte: dayEnd },
				year: { _eq: yearEnd }
			}
		]
	}
}

export const useClassProductivityChart = ({ classId, dateRange }: useClassProductivityChartProps) => {
	const [chartData, setChartData] = useState<ChartDataType>({ labels: [], datasets: [] })

	const { data: data, loading } = useStudentProductivityTimeRangeQuery({
		variables: { where: { class_id: { _eq: classId }, ...getDateFilter2(dateRange) } }
	})

	useEffect(() => {
		if (!loading && data && data.student_productivity_daily) {
			const extractedData = buildChartData(data.student_productivity_daily as Student_Productivity_Daily[])
			setChartData(extractedData)
		}
	}, [data, loading])

	return { chartData, loading }
}

const buildChartData = (monthlyData: Student_Productivity_Daily[]): ChartDataType => {
	const labels: string[] = []
	const videoViews: number[] = []
	const practiceSessions: number[] = []
	const recordingSessions: number[] = []

	for (const item of monthlyData) {
		// TODO: remove this once the student_productivity_* includes the student_full_name
		const { first_name, last_name } = item.student || { first_name: '', last_name: '' }
		const studentName = `${first_name} ${last_name}`

		if (!labels.includes(studentName)) {
			labels.push(studentName)
			videoViews.push(item.video_views)
			practiceSessions.push(item.practice_sessions)
			recordingSessions.push(item.recording_sessions)
		} else {
			const prevIndex = labels.indexOf(studentName)
			videoViews[prevIndex] += item.video_views
			practiceSessions[prevIndex] += item.practice_sessions
			recordingSessions[prevIndex] += item.recording_sessions
		}
	}

	return {
		labels,
		datasets: [
			{
				label: 'Video views',
				data: videoViews,
				backgroundColor: COLORS.blue
			},
			{
				label: 'Recording sessions',
				data: recordingSessions,
				backgroundColor: COLORS.purple
			},
			{
				label: 'Practice sessions',
				data: practiceSessions,
				backgroundColor: COLORS.yellow
			}
		]
	}
}
