import { useState } from 'react'

import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { AccordionWrapper, CarouselOfAny, DeleteItemDialog } from 'src/components'
import { useDeletePlaylistFromClassMutation } from 'src/graphql/autogenerate/hooks'
import { Playlist } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { ListItemPlaylist } from 'src/scenes/Teacher/scenes/8-Videos/components'
import { buildRouteParameters, getCyKey, getPlaylistGenres } from 'src/utils'

import { useClassVideosContext } from '../useClassVideos'

export const ClassVideosPlaylists = () => {
	const {
		classId,
		videosPlaylistQuery: { data: videosPlayListData, loading: videosPlayListLoading }
	} = useClassVideosContext()
	const history = useHistory()
	const videosPlaylistArr =
		(videosPlayListData?.class?.[0]?.class_playlists?.map((playlist) => {
			return { ...playlist.playlist }
		}) as Playlist[]) || []

	const videosPlaylistCount = videosPlayListData?.class?.[0]?.class_playlists_aggregate?.aggregate?.count || 0
	const [videosPlaylistIdToDelete, setVideosPlaylistIdToDelete] = useState<number>()
	const [deletePlaylistFromClass] = useDeletePlaylistFromClassMutation()

	return !!videosPlaylistCount ? (
		<Box pt={3}>
			{videosPlayListLoading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!videosPlayListLoading && (
				<AccordionWrapper
					defaultExpanded
					summary={
						<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
							<Typography variant="h6">
								<b>Playlists ({videosPlaylistCount})</b>
							</Typography>
							<Box style={{ flexGrow: 1 }} />
							<Button
								data-cy={getCyKey(ClassVideosPlaylists, 'btnViewAllPlaylists')}
								onClick={() => {
									history.push(
										buildRouteParameters(
											Pages.ClassVideosViewAllPlaylist, // Route with paramters
											{ classId }
										)
									)
								}}>
								<Typography color="secondary">
									<b>View All Playlists</b>
								</Typography>
							</Button>
						</Box>
					}
					details={
						<CarouselOfAny
							slidesPerPage={1}
							spacing={3}
							data={videosPlaylistArr}
							renderItem={(item) => (
								<ListItemPlaylist
									id={item.playlist_id}
									imageUrl={item.image_path ?? ''}
									title={item.name}
									description={item.description}
									genres={getPlaylistGenres(item)}
									totalvideos={item.tip_playlist_aggregate.aggregate?.count ?? 0}
									onClick={(e) => {
										e.stopPropagation()
										history.push(
											buildRouteParameters(
												Pages.ClassVideosPlaylistDetails, // Route with paramters
												{
													classId,
													playlistId: item.playlist_id
												}
											)
										)
									}}
									btnView={{
										onClick: (e) => {
											e.stopPropagation()
											history.push(
												buildRouteParameters(
													Pages.ClassVideosPlaylistDetails, // Route with paramters
													{
														classId,
														playlistId: item.playlist_id
													}
												)
											)
										}
									}}
									btnRemove={{
										onClick: (e) => {
											e.stopPropagation()
											setVideosPlaylistIdToDelete(item.playlist_id)
										}
									}}
								/>
							)}
						/>
					}
					actions={null}
				/>
			)}

			<DeleteItemDialog
				itemName="Playlist"
				isOpen={!!videosPlaylistIdToDelete}
				setIsOpen={setVideosPlaylistIdToDelete}
				onConfirm={async () => {
					if (videosPlaylistIdToDelete) {
						await deletePlaylistFromClass({
							variables: {
								classId,
								playlistId: videosPlaylistIdToDelete
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_class_playlist?.returning[0].class
								if (!classToUpdateInCache) return
								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'class_playlists'
								})
							}
						})
					}
				}}
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Playlist from your Class?',
						body: "You'll be able to re-add it from the Videos page at any time.",
						buttonLabel: 'Delete Playlist'
					},
					confirmation: {
						body: 'You have successfully deleted this Playlist from your Class.'
					}
				}}
			/>
		</Box>
	) : (
		<Box />
	)
}
