import { Typography } from '@material-ui/core'

type TextProp = {
	text: string
	bold?: boolean
}

export const CardTitle = ({ text, bold }: TextProp) => {
	return (
		<Typography style={{ fontSize: '1.5em', marginLeft: '-.5em', marginTop: '-.5em' }}>
			{bold ? <strong>{text}</strong> : text}
		</Typography>
	)
}
