import { useEffect, useState } from 'react'

import { useGetWurrlyByClassByStudentQuery } from 'src/graphql/autogenerate/hooks'
import { GetWurrlyByClassByStudentQueryVariables } from 'src/graphql/autogenerate/operations'
import { Order_By, Wurrly, Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { MAX_LIST_ITEMS } from 'src/utils'

import { useStudentDetailContext } from '../../../StudentDetail/useStudentDetail'

export const useStudentWurrliesGrid = () => {
	const [wurrlies, setWurrlies] = useState<Wurrly[]>([])
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [loading, setLoading] = useState(true)
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)

	const { studentId, classSelectedId } = useStudentDetailContext()

	const [wurrliesVariables, setWurrliesVariables] = useState<GetWurrlyByClassByStudentQueryVariables>({
		studentId,
		classId: classSelectedId,
		limit,
		offset: 0,
		orderTitle: Order_By.Asc,
		orderDate: undefined,
		privacySettings: [Privacy_Enum.Class]
	})

	const {
		data,
		loading: queryLoading,
		fetchMore
	} = useGetWurrlyByClassByStudentQuery({
		variables: wurrliesVariables
	})
	const count = data?.wurrly_aggregate.aggregate?.count || 0

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		setIsFetching(false)
		setLoading(false)
	}, [wurrlies])

	useEffect(() => {
		if (queryLoading) setLoading(true)
		if (!queryLoading && data) {
			setWurrlies((data.wurrly as Wurrly[]) || [])
			setLoading(false)
		}
	}, [queryLoading, data])

	useEffect(() => {
		if (!isFetching) return
		if (!queryLoading && fetchMore && studentId) {
			const currentLength = wurrlies.length
			fetchMore({
				variables: {
					studentId,
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				if (fetchMoreResult?.data?.wurrly?.length !== 0) {
					setLimit(currentLength + fetchMoreResult?.data?.wurrly?.length)
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	return { setWurrliesVariables, loading, wurrlies, count, moreResults, isFetching }
}
