import { CarouselOfAny } from 'src/components'
import { ClassCard, ClassForCard } from 'src/components/Cards/ClassCard/ClassCard'
import { Pages } from 'src/routes/teacherPages'

type ClassCarouselProps = {
	classes: ClassForCard[]
	setShouldFetchMore?: (shouldFetchMore: boolean) => void
}

export const ClassCarousel = ({ classes, setShouldFetchMore }: ClassCarouselProps) => {
	return (
		<CarouselOfAny
			data={classes}
			slidesPerPage={2}
			spacing={3}
			renderItem={(classElement) => <ClassCard classElement={classElement} pages={Pages} />}
			setIsViewingLastItem={setShouldFetchMore}
		/>
	)
}
