import { PagesProps } from 'src/@types'
import { MainTitle } from 'src/components'
import { useWurrlyParams } from 'src/hooks'
import { StudentPages } from 'src/routes/studentpages'
import { AssignmentsProvider, useAssignmentsContext } from 'src/scenes/Student/hooks/useAssignments'
import { buildBreadCrumbs } from 'src/utils'

import { ClassAssignmentsDetails } from './components/ClassAssignmentsDetails/ClassAssignmentsDetails'

export const ClassAssignmentsContent = ({ page }: PagesProps) => {
	const { classId } = useWurrlyParams<typeof StudentPages.ClassAssignment.params[number]>()
	const { selectedClassName } = useAssignmentsContext()
	const breadcrumbs = buildBreadCrumbs({
		page,
		isStudentPage: true,
		replaceIndexes: [
			{
				index: 1,
				overrideName: selectedClassName
			}
		],
		params: {
			classId
		}
	})

	return (
		<>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={page.name}
				description="Manage all your Assignments in one easy place."
				rightActions={[]}
			/>
			<ClassAssignmentsDetails classId={classId} />
		</>
	)
}

export const ClassAssignments = (props: PagesProps) => {
	return (
		<AssignmentsProvider>
			<ClassAssignmentsContent {...props} />
		</AssignmentsProvider>
	)
}
