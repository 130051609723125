import { useState, useEffect, Fragment } from 'react'

import { Typography, Checkbox, Box } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { Category_Standard_Enum, Standard_Option, Standard_Subcategory } from 'src/graphql/autogenerate/schemas'
import { LEVELS, RandomNumberKey } from 'src/utils'

import { useSelectDataContext } from './useFormData'

const StyledSubCategory = styled(Box)({
	width: '100%',
	padding: '1.5em',
	wordBreak: 'break-word',

	'&:hover': {
		backgroundColor: '#F6F6F7'
	}
})

const OptionTitle = styled(Typography)({
	color: '#1D273D',
	fontSize: '18px',
	marginBottom: '.1em'
})
const OptionDescription = styled(Typography)({
	fontSize: '14px',
	color: '#9CA0AA',
	lineHeight: '120%',

	'& strong': {
		fontWeight: 'bold'
	}
})

const Row = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	'& .MuiSvgIcon-root': {
		fill: '#757575'
	}
})

const RowCategory = styled(Box)({
	display: 'flex',
	height: '65px',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	'& .MuiSvgIcon-root': {
		fill: '#757575'
	}
})

const Column = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	width: '100%'
})

type SubCategoryItemProps = {
	item: Standard_Subcategory
	onMouseEnter: () => void
}
type CategoryItemProps = {
	item: Category_Standard_Enum
	onMouseEnter: () => void
}
export const CategoryItem = ({ item, onMouseEnter }: CategoryItemProps) => {
	const { hoverItem } = useSelectDataContext()

	const style: React.CSSProperties = {
		// This way the background remains in the hover color when hovering nested menu
		backgroundColor: hoverItem === item ? '#F6F6F7' : 'inherit'
	}

	return (
		<StyledSubCategory onMouseEnter={onMouseEnter} style={style}>
			<RowCategory>
				<OptionTitle>
					<strong>{item}</strong>
				</OptionTitle>
				<ArrowRightIcon fontSize="small" />
			</RowCategory>
		</StyledSubCategory>
	)
}

export const SubCategoryItem = ({ item, onMouseEnter }: SubCategoryItemProps) => {
	const { getOptionLabel, hoverSubItem } = useSelectDataContext()

	const style: React.CSSProperties = {
		// This way the background remains in the hover color when hovering nested menu
		backgroundColor: hoverSubItem === item.name ? '#F6F6F7' : 'inherit'
	}

	return (
		<StyledSubCategory onMouseEnter={onMouseEnter} style={style}>
			<Row>
				<OptionTitle>
					<strong>{getOptionLabel(item)}</strong>
				</OptionTitle>
				<ArrowRightIcon fontSize="small" />
			</Row>
			<OptionDescription>
				{item.description}
				<strong> Enduring understanding: </strong>
				{item.enduring_understanding}
			</OptionDescription>
		</StyledSubCategory>
	)
}

const StyledOption = styled(Box)({
	width: '100%',
	padding: '.25em 1.5em .25em 1em',
	wordBreak: 'break-word',

	'&:hover': {
		backgroundColor: '#F6F6F7'
	}
})

type OptionProps = {
	item: Standard_Option
}

export const Option = ({ item }: OptionProps) => {
	const { toggleItem, isItemSelected } = useSelectDataContext()

	const handleChange = () => {
		toggleItem(item)
	}

	return (
		<StyledOption onClick={handleChange}>
			<Row>
				<OptionTitle>
					<strong>{item.title}</strong>
				</OptionTitle>
				<Checkbox checked={isItemSelected(item)} style={{ padding: 0 }} />
			</Row>
			<OptionDescription>{item.description}</OptionDescription>
		</StyledOption>
	)
}

const LevelBadge = styled(Box)({
	width: '48px',
	height: '48px',
	backgroundColor: 'rgba(29, 39, 61, 0.16)',
	marginTop: '10px',
	borderRadius: '10px',
	display: 'flex',
	flex: '0 0 48px',
	alignItems: 'center',
	justifyContent: 'center',

	'& h1': {
		fontSize: '18px'
	}
})

const Group = styled(Box)({
	display: 'flex',
	padding: '10px 0 0 1.5em',
	alignItems: 'flex-start'
})

type NestedOptionsProps = {
	options: Standard_Option[]
}

type GroupedOptions = Record<string, Standard_Option[]>

export const NestedOptions = ({ options }: NestedOptionsProps) => {
	const [groupedOptions, setGroupedOptions] = useState<GroupedOptions>({})

	useEffect(() => {
		const groups = {} as GroupedOptions
		for (const option of options) {
			const level = option?.grade?.name || ''
			if (level in groups) groups[level].push(option)
			else groups[level] = [option]
		}

		setGroupedOptions(groups)
	}, [options])

	const itemsCount = Object.keys(groupedOptions).length
	let index = 0

	return (
		<>
			{LEVELS.map((level, i) => {
				const groupOptions = groupedOptions[level]

				if (!groupOptions) return <div key={`${level}_${i}_${RandomNumberKey()}`}></div>
				index++

				return (
					<Fragment key={`${level}_${i}_${RandomNumberKey()}`}>
						<Group>
							<LevelBadge>
								<Typography variant="h1">
									<strong>{level}</strong>
								</Typography>
							</LevelBadge>
							<Column>
								{groupOptions.map((item, i) => (
									<Option
										item={item}
										key={`option_${item.standar_option_id}_${item.title}_${i}`}
									/>
								))}
							</Column>
						</Group>
						{index < itemsCount && (
							<hr
								style={{
									borderTop: '1px solid rgba(0, 0, 0, 8%)',
									borderRight: 'none',
									borderBottom: 'none',
									borderLeft: 'none',
									width: '90%'
								}}
							/>
						)}
					</Fragment>
				)
			})}
		</>
	)
}
