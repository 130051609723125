import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useItemListStyles = makeStyles<Theme>(() =>
	createStyles({
		container: {
			margin: '0px 40px'
		},
		containerTitle: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		text: {
			fontWeight: 500,
			fontSize: '17px',
			lineHeight: '20px',
			padding: '0 20px 0 0'
		},
		textOpacity: {
			fontWeight: 400,
			fontSize: '15px',
			lineHeight: '24px',
			color: ' rgba(46, 38, 66, 0.6)',
			letterSppacing: '0.15px'
		},
		divider: {
			height: '1px',
			backgroundColor: 'rgba(46, 38, 66, 0.3)',
			width: '100%',
			margin: '12px 0'
		},
		ellipsis: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap'
		}
	})
)

export default useItemListStyles
