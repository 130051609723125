import { Box, Card, Tooltip, Typography } from '@material-ui/core'
import { Delete, Edit, MusicNote } from '@material-ui/icons'
import { ActionButton } from 'src/components'

import useStyles from './SongTrackCard.styles'

export type SongTrackCardProps = {
	title: string
	description?: string
	onEdit?: () => void
	onDelete?: () => void
}

export const SongTrackCard = ({ title, description, onEdit, onDelete }: SongTrackCardProps) => {
	const styles = useStyles()

	return (
		<Card className={styles.container}>
			<Box className={styles.sides}>
				<Box className={styles.icon}>
					<MusicNote />
				</Box>
				<Box className={styles.content}>
					<Typography>
						{/* get just the file name */}
						<b>{title.split('/').slice(-1).join('')}</b>
					</Typography>
					{description && <Typography>{description}</Typography>}
				</Box>
			</Box>
			<Box className={styles.sides}>
				<Tooltip title="Coming soon" placement="top">
					<ActionButton icon={<Edit />} onClick={onEdit} />
				</Tooltip>
				<ActionButton icon={<Delete />} onClick={onDelete} />
			</Box>
		</Card>
	)
}
