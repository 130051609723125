import { cloneElement, MutableRefObject, ReactElement, useEffect, useRef } from 'react'

import { HTMLMediaProps } from 'react-use/lib/factory/createHTMLMediaHook'
import { Filter_Frame, Filter_Color_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { buildFrameUrl, getOffset } from 'src/utils'

import { OverlayFilter } from './ColorFilters'
import useStyles from './VideoPreview.styles'

type VideoPreviewProps = {
	stream?: MediaStream | null
	videoSrc?: string | null
	videoElement?: ReactElement<HTMLMediaProps & { ref?: MutableRefObject<HTMLVideoElement | null> }> | null
	selectedFrameFilter?: Filter_Frame
	isMirrored?: boolean
	selectedColorFilter?: Filter_Color_Name_Enum
	updateVideoDimensions?: (dimmensions: VideoDimensions) => void
}

export type VideoDimensions = { width: number; height: number; offsetLeft: number; offsetTop: number }
export const VideoPreview = ({
	stream,
	videoSrc,
	videoElement,
	selectedFrameFilter,
	isMirrored,
	selectedColorFilter,
	updateVideoDimensions
}: VideoPreviewProps) => {
	const styles = useStyles({ selectedColorFilter, isMirrored })
	const videoRef = useRef<HTMLVideoElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const hasAvailableVideo = !!(stream || videoSrc || videoElement)

	useEffect(() => {
		if (videoRef.current && stream) {
			videoRef.current.srcObject = stream
		}
	}, [stream])

	useEffect(() => {
		const current = containerRef?.current
		if (updateVideoDimensions && current) {
			const { top, left } = getOffset(current)
			updateVideoDimensions({
				width: current.clientWidth,
				height: current.clientHeight,
				offsetTop: top,
				offsetLeft: left
			})
		}
	}, [containerRef.current])

	return (
		<div ref={containerRef} className={styles.videoPlayer}>
			{stream && <video ref={videoRef} className={styles.video} autoPlay />}
			{videoSrc && <video src={videoSrc} className={styles.video} />}
			{videoElement && cloneElement(videoElement, { className: styles.video })}

			{hasAvailableVideo && <OverlayFilter filter={selectedColorFilter} />}

			{selectedFrameFilter && (
				<img src={buildFrameUrl(selectedFrameFilter?.image_path)} className={styles.frame}></img>
			)}
		</div>
	)
}
