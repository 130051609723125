import { Box, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { AssignmentCard } from 'src/components/Cards/AssignmentCard/AssignmentCard'
import { Class_Assignment } from 'src/graphql/autogenerate/schemas'

import useStyles from './AssignmentCardList.styles'

type AssignmentCardListProps = {
	classAssignments: Class_Assignment[] | undefined
	seeMore: boolean
	onSeeMore: () => void
	loading: boolean
	home?: boolean
	refetch?: () => void
}

export const AssignmentCardList = ({
	classAssignments,
	seeMore,
	onSeeMore,
	loading,
	home,
	refetch
}: AssignmentCardListProps) => {
	const styles = useStyles()

	return (
		<>
			{!!classAssignments?.length &&
				classAssignments?.map((i) => (
					<AssignmentCard
						key={i.assignment_id}
						classAssignment={i}
						className={clsx({ [styles.homeAssignments]: home })}
						refetch={refetch}
					/>
				))}
			{seeMore && (
				<Typography color="textSecondary" className={styles.seeMore} onClick={onSeeMore}>
					<b>See More</b>
				</Typography>
			)}
			{loading && (
				<Box display="flex" justifyContent="space-around" alignItems="center" className={styles.loader}>
					<CircularProgress color="secondary" size={50} />
				</Box>
			)}
			{!loading && !classAssignments?.length && (
				<Box my={4} mb={7}>
					<Typography variant="h6" color="textSecondary">
						You haven’t added any Assignments to this Class yet.
					</Typography>
					<Typography color="textSecondary">
						It's easy to add your own or add from a saved Lesson below.
					</Typography>
				</Box>
			)}
		</>
	)
}
