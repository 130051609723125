import { makeStyles } from '@material-ui/core'

export const useStudioHeaderStyles = makeStyles({
	title: {
		fontSize: '30px',
		lineHeight: '1em'
	},
	description: {
		lineHeight: '1.2em',
		margin: '5px 0px 25px 0px'
	}
})

export default useStudioHeaderStyles
