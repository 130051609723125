import { Typography } from '@material-ui/core'

type LyricProps = {
	name: string
	time: number
	style: React.CSSProperties
	highlight?: boolean
}
export const Lyric = ({ name, style, highlight = false }: LyricProps) => {
	return (
		<Typography style={{ color: highlight ? '#F3AB34' : '#1D273D', fontWeight: 'bold', ...style }}>
			{name}
		</Typography>
	)
}
