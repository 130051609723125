import React, { CSSProperties, useEffect, useState } from 'react'

import { Box, Button, Checkbox, CircularProgress, Typography } from '@material-ui/core'
import { GoogleLoginResponse, GoogleLoginResponseOffline, useGoogleLogin } from 'react-google-login'
import { SyncCleverIcon } from 'src/assets/icons'
import ClassLinkIcon from 'src/assets/icons/LoginIntegrations/ClassLinkIcon.svg'
import GoogleIcon from 'src/assets/icons/LoginIntegrations/GoogleIcon.svg'
import MicrosoftIcon from 'src/assets/icons/LoginIntegrations/MicrosoftIcon.svg'
import { BaseDialog, BaseLink, InfoDialog } from 'src/components'
import { environment } from 'src/environment'
import {
	useGetSignupIntegrationTokenLazyQuery,
	useLoginIntegrationLazyQuery,
	useStudentIntegrationLoginLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Wurrly_Role_Enum, Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { IntegrationAccount, useIntegrationContext } from 'src/hooks/useIntegration'
import { useLoginContext } from 'src/hooks/useLogin'
import { IntegrationButtonActions, SignUpData } from 'src/scenes/Login/enums'
import { INTEGRATION_LOGIN_ERROR_RENDER, INTEGRATION_SIGNUP_ERROR_RENDER, NO_CACHE } from 'src/utils'

import { useLoginStyles } from '../styles'

type IntegrationButtonProps = {
	className?: string
	selectedIntegrationType?: Integration_Name_Enum
	integrationType: Integration_Name_Enum
	customLabel?: string
	style?: CSSProperties
	disabled?: boolean
	action: IntegrationButtonActions
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
	setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>
	role?: Wurrly_Role_Enum
	onButtonClick?: () => void
}

const integrationNames: Partial<{ [x in Integration_Name_Enum]: string }> = {
	[Integration_Name_Enum.Google]: 'Google',
	[Integration_Name_Enum.MicrosoftTeams]: 'Microsoft',
	[Integration_Name_Enum.Clever]: 'Clever',
	[Integration_Name_Enum.ClassLink]: 'ClassLink'
}
const integrationIcons: Partial<{ [x in Integration_Name_Enum]: string }> = {
	[Integration_Name_Enum.Google]: GoogleIcon,
	[Integration_Name_Enum.MicrosoftTeams]: MicrosoftIcon,
	[Integration_Name_Enum.Clever]: SyncCleverIcon,
	[Integration_Name_Enum.ClassLink]: ClassLinkIcon
}

export const IntegrationButton = ({
	className,
	customLabel,
	selectedIntegrationType,
	integrationType,
	style,
	disabled = false,
	action,
	setLoading,
	setSignUpData,
	role = Wurrly_Role_Enum.Teacher,
	onButtonClick
}: IntegrationButtonProps) => {
	const classes = useLoginStyles({})
	const [buttonLoading, setButtonLoading] = useState(false)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [integrationLoading, setIntegrationLoading] = useState(false)
	const [signupTermsDialogOpen, setSignupTermsDialogOpen] = useState(false)
	const [isTermsOfServiceChecked, setIsTermsOfServiceChecked] = useState(false)
	const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false)
	const [disabledButton, setDisabledButton] = useState(false)
	const [errorMessage, setErrorMessage] = useState('An error ocurred while trying to login')
	const [googleToken, setGoogleToken] = useState('')
	const [googleId, setGoogleId] = useState('')
	const [googleEmail, setGoogleEmail] = useState('')
	const { loginSignIn, setIntegrationName } = useLoginContext()
	const { cleverInstance } = useIntegrationContext()
	const { account: cleverAccount, queriesStates } = cleverInstance
	const [
		loginIntegrationQuery,
		{ data: loginIntegrationData, loading: loginIntegrationLoading, error: loginIntegrationError }
	] = useLoginIntegrationLazyQuery(NO_CACHE)
	const [
		getSignupIntegrationToken,
		{
			data: signupIntegrationTokenData,
			error: signupIntegrationTokenError,
			loading: signupIntegrationTokenLoading
		}
	] = useGetSignupIntegrationTokenLazyQuery(NO_CACHE)
	const [
		studentIntegrationLogin,
		{
			data: studentIntegrationLoginData,
			loading: studentIntegrationLoginLoading,
			error: studentIntegrationLoginError
		}
	] = useStudentIntegrationLoginLazyQuery(NO_CACHE)

	const { cleverAccountLoading, loadingCleverTokenData, loadingTeacherIntegration } = queriesStates
	const areCleverQueriesLoading =
		loginIntegrationLoading ||
		cleverAccountLoading ||
		loadingCleverTokenData ||
		loadingTeacherIntegration ||
		signupIntegrationTokenLoading

	// Remove disabled and loaders from buttons
	const enableButtons = () => {
		setDisabledButton(false)
		setLoading(false)
		setButtonLoading(false)
		setIntegrationLoading(false)
	}

	// clear integration loading when clever queries ended
	useEffect(() => {
		setIntegrationLoading(areCleverQueriesLoading)
	}, [areCleverQueriesLoading])

	// After clever account has complete data
	useEffect(() => {
		if (integrationCheck(Integration_Name_Enum?.Clever, cleverAccount)) {
			setDisabledButton(false)
			// Try to login with clever
			if (action === IntegrationButtonActions.Login) cleverLogin()
			// Get token for signup from BE
			if (action === IntegrationButtonActions.Signup) cleverSignup()
		}
	}, [cleverAccount?.integrationId, cleverAccount?.token])

	// Set clever loading false when integration query ended
	useEffect(() => {
		if (
			(!loginIntegrationLoading &&
				(loginIntegrationError || loginIntegrationData || studentIntegrationLoginLoading)) ||
			(!signupIntegrationTokenLoading && (signupIntegrationTokenError || signupIntegrationTokenData))
		)
			setIntegrationLoading(false)
	}, [
		loginIntegrationLoading,
		loginIntegrationError,
		loginIntegrationData,
		signupIntegrationTokenLoading,
		studentIntegrationLoginLoading
	])

	// Disabling buttons when loading
	useEffect(() => {
		// Case Clever
		if (selectedIntegrationType === Integration_Name_Enum.Clever) {
			// If Queries still loading, set button disabled with spinner
			if (areCleverQueriesLoading) {
				setIntegrationLoading(true)
			}
			// Set current button and  "Sign In"/"Sign Up" buttons as disabled/enabled
			setLoading(integrationLoading || studentIntegrationLoginLoading)
			setButtonLoading(integrationLoading || studentIntegrationLoginLoading)
			setDisabledButton(integrationLoading || studentIntegrationLoginLoading)
		} else if (selectedIntegrationType === Integration_Name_Enum.Google) {
			setLoading(loginIntegrationLoading || signupIntegrationTokenLoading || studentIntegrationLoginLoading)
			setButtonLoading(
				loginIntegrationLoading || signupIntegrationTokenLoading || studentIntegrationLoginLoading
			)
			setDisabledButton(
				loginIntegrationLoading || signupIntegrationTokenLoading || studentIntegrationLoginLoading
			)
		}
	}, [
		loginIntegrationLoading,
		queriesStates,
		integrationLoading,
		signupIntegrationTokenLoading,
		studentIntegrationLoginLoading,
		areCleverQueriesLoading
	])

	const integrationCheck = (IntegrationNeeded: Integration_Name_Enum, IntegrationToCheck?: IntegrationAccount) =>
		Boolean(
			IntegrationToCheck &&
				IntegrationToCheck?.integrationId &&
				IntegrationToCheck?.teacherEmail &&
				IntegrationToCheck?.token &&
				IntegrationNeeded === selectedIntegrationType
		)

	// Set session token after login/signup
	useEffect(() => {
		if (loginIntegrationData?.loginIntegration?.token && !loginIntegrationLoading && !loginIntegrationError) {
			const { token, cleverSignup } = loginIntegrationData?.loginIntegration
			if (cleverSignup) {
				setIntegrationName(Integration_Name_Enum.Clever)
			}
			loginSignIn(token)
		}
		if (loginIntegrationData || loginIntegrationError) setLoading(false)
		// Set Error message and popup
		if (loginIntegrationError) {
			if (
				loginIntegrationError?.message === INTEGRATION_LOGIN_ERROR_RENDER &&
				role === Wurrly_Role_Enum.Teacher
			) {
				if (
					selectedIntegrationType === Integration_Name_Enum.Clever &&
					cleverAccount?.teacherEmail &&
					cleverAccount.integrationId &&
					cleverAccount.token
				) {
					const variables = {
						email: cleverAccount?.teacherEmail,
						integrationId: cleverAccount.integrationId,
						accessToken: cleverAccount.token as string,
						isClever: true
					}
					getSignupIntegrationToken({
						variables
					})
				} else if (
					selectedIntegrationType === Integration_Name_Enum.Google &&
					googleId &&
					googleToken &&
					googleEmail
				) {
					const variables = {
						email: googleEmail,
						integrationId: googleId,
						accessToken: googleToken as string,
						isGoogle: true
					}
					getSignupIntegrationToken({
						variables
					})
				}
			} else {
				const error =
					loginIntegrationError?.message === INTEGRATION_LOGIN_ERROR_RENDER
						? INTEGRATION_LOGIN_ERROR_RENDER
						: ' An error occured while trying login'
				setErrorMessage(error)
				setTimeout(() => {
					setErrorDialogOpen(true)
				}, 500)
			}
		}
	}, [loginIntegrationData, loginIntegrationError])

	// Set login token for student integration login
	useEffect(() => {
		if (studentIntegrationLoginData && !studentIntegrationLoginLoading) {
			const token = studentIntegrationLoginData?.studentLoginIntegration?.token
			if (token) {
				loginSignIn(token)
			} else {
				setErrorMessage('No wurrly account associated to this clever account.')
				setTimeout(() => {
					setErrorDialogOpen(true)
				}, 500)
				cleverInstance.clearAccount()
				setIntegrationLoading(false)
			}
			enableButtons()
		}
	}, [studentIntegrationLoginData, studentIntegrationLoginLoading])

	// Set signup integration token (to decode in BE)
	useEffect(() => {
		// Case response has token
		const token = signupIntegrationTokenData?.getSignupIntegrationToken?.token
		if (setSignUpData && token && !signupIntegrationTokenLoading && selectedIntegrationType) {
			setSignUpData((prev) => ({
				...prev,
				token: token || '',
				integrationId: selectedIntegrationType
			}))
		}
		// Case Error
		else if (signupIntegrationTokenError) {
			const error =
				signupIntegrationTokenError?.message === INTEGRATION_SIGNUP_ERROR_RENDER
					? INTEGRATION_SIGNUP_ERROR_RENDER
					: ' An error occured while trying to signup'
			setErrorMessage(error)
			setTimeout(() => {
				setErrorDialogOpen(true)
			}, 500)
		}
		setButtonLoading(signupIntegrationTokenLoading)
	}, [signupIntegrationTokenData, signupIntegrationTokenError, signupIntegrationTokenLoading])

	// Enable buttons and stop spinners when an error in the login ocurred
	useEffect(() => {
		if (cleverInstance.error || studentIntegrationLoginError) {
			enableButtons()
			setErrorDialogOpen(true)
		}
	}, [cleverInstance.error, studentIntegrationLoginError])

	const text = integrationNames[integrationType] || ''

	const icon = integrationIcons[integrationType] || ''

	const cleverLogin = () => {
		if (
			selectedIntegrationType === Integration_Name_Enum.Clever &&
			cleverAccount?.teacherEmail &&
			cleverAccount?.token &&
			typeof cleverAccount?.token === 'string' &&
			cleverAccount?.integrationId
		) {
			const { teacherEmail: email, token: accessToken, integrationId } = cleverAccount
			if (role === Wurrly_Role_Enum.Teacher) {
				loginIntegrationQuery({
					variables: { email, integrationId, accessToken, isClever: true }
				})
			} else {
				setDisabledButton(true)

				studentIntegrationLogin({ variables: { integrationId } })
			}
		}
	}

	const cleverSignup = () => {
		// Get the token from BE
		if (
			selectedIntegrationType === Integration_Name_Enum.Clever &&
			cleverAccount?.teacherEmail &&
			cleverAccount?.token &&
			typeof cleverAccount?.token === 'string' &&
			cleverAccount?.integrationId
		) {
			const { teacherEmail: email, token: accessToken, integrationId } = cleverAccount
			getSignupIntegrationToken({ variables: { email, integrationId, accessToken, isClever: true } })
		}
	}

	const openCleverPopup = () => {
		cleverInstance.openPopup()
	}

	const buttonAction = () => {
		switch (selectedIntegrationType) {
			case Integration_Name_Enum.Clever:
				openCleverPopup()
				break
			case Integration_Name_Enum.Google:
				googleSignIn()
				break
			default:
				return
		}
	}

	const googleLogin = (res: GoogleLoginResponse) => {
		const {
			accessToken,
			googleId: integrationId,
			profileObj: { email }
		} = res
		setGoogleToken(accessToken)
		setGoogleId(integrationId)
		setGoogleEmail(email)

		loginIntegrationQuery({ variables: { email, integrationId, accessToken, isGoogle: true } })
	}

	const googleSignup = (res: GoogleLoginResponse) => {
		// Get token from BE
		const {
			accessToken,
			googleId: integrationId,
			profileObj: { email }
		} = res
		getSignupIntegrationToken({ variables: { email, integrationId, accessToken, isGoogle: true } })
	}

	const onGoogleLoginSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
		if (action === IntegrationButtonActions.Login) googleLogin(res as GoogleLoginResponse)
		if (action === IntegrationButtonActions.Signup) googleSignup(res as GoogleLoginResponse)
	}

	const { signIn: googleSignIn } = useGoogleLogin({
		onSuccess: onGoogleLoginSuccess,
		clientId: environment.googleClientId,
		cookiePolicy: 'single_host_origin',
		onFailure: (error) => {
			if (error.error !== 'popup_closed_by_user' && error.error !== 'idpiframe_initialization_failed') {
				setErrorDialogOpen(true)
			}
		}
	})

	return (
		<>
			<Button
				className={className}
				disabled={disabled || disabledButton}
				onClick={() => {
					if (onButtonClick) onButtonClick()
					if (action === IntegrationButtonActions.Login) {
						buttonAction()
					} else {
						setSignupTermsDialogOpen(true)
					}
				}}
				style={{
					margin: 0,
					width: '30%',
					borderRadius: '4px',
					marginLeft: '2px',
					marginRight: '2px',
					border: '1px solid #E2E2E2',
					...style
				}}>
				<img
					src={icon}
					style={{ height: '25px', opacity: buttonLoading ? 0.5 : 1 }}
					alt={customLabel || text}
				/>
				{!buttonLoading && (
					<b style={{ textTransform: 'capitalize', paddingLeft: '10px' }}>{customLabel || text}</b>
				)}
				{buttonLoading && (
					<CircularProgress
						color="secondary"
						style={{ height: 20, width: 20, display: 'flex', alignItems: 'center' }}
					/>
				)}
			</Button>
			<InfoDialog
				open={errorDialogOpen}
				onClose={() => {
					setErrorDialogOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body={errorMessage}
				confirmLabel="Close"
				onConfirm={() => {
					setErrorDialogOpen(false)
				}}
			/>
			<BaseDialog
				open={signupTermsDialogOpen}
				onClose={() => setSignupTermsDialogOpen(false)}
				dividers={false}
				header={
					<>
						<Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
							<b>To Sign Up you must accept the Terms of Service and Privacy Policy</b>
						</Typography>
					</>
				}
				body={
					<>
						<Box mt={1} display="flex" flexWrap="nowrap" alignItems="center">
							<Checkbox
								className={classes.checkbox}
								onClick={() => setIsTermsOfServiceChecked(!isTermsOfServiceChecked)}
								checked={isTermsOfServiceChecked}
							/>
							<Typography variant="caption" className={classes.checkboxText}>
								I Have read and accept the WURRLY{' '}
								<BaseLink href="https://www.wurrlyedu.com/terms">
									<Typography variant="caption">Terms of Service.</Typography>
								</BaseLink>
							</Typography>{' '}
						</Box>
						<Box>
							<Checkbox
								className={classes.checkbox}
								onClick={() => setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)}
								checked={isPrivacyPolicyChecked}
							/>
							<Typography variant="caption" className={classes.checkboxText}>
								I Have read, underatand and accept the WURRLY{' '}
								<BaseLink href="https://www.wurrlyedu.com/privacy">
									<Typography variant="caption">Privacy Policy.</Typography>
								</BaseLink>
							</Typography>{' '}
						</Box>
					</>
				}
				confirmLabel="Sign Up"
				discardLabel="close"
				onDiscard={() => setSignupTermsDialogOpen(false)}
				isConfirmDisabled={!isPrivacyPolicyChecked || !isTermsOfServiceChecked}
				onConfirm={() => {
					setSignupTermsDialogOpen(false)
					buttonAction()
				}}
			/>
			{/* <SelectDistrictDialog
				open={selectCleverDistrictOpen}
				setOpen={setSelectCleverDistrictOpen}
				onConfirm={() => openCleverPopup()}
				onDistrictSelect={(districtId: string) => setDistrictId(districtId)}
			/> */}
		</>
	)
}
