import { useState } from 'react'

import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useGetArtistsQuery, useGetScalesQuery, useInsertSongMutation } from 'src/graphql/autogenerate/hooks'
import { Catalog_Item, Grade } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { queryGrade, QueryGradeType } from 'src/scenes/Teacher/queries'
import { buildRouteParameters } from 'src/utils'
import * as yup from 'yup'

const validationSchema = yup.object({
	title: yup.string().required('Title is required'),
	levels: yup.array().min(1, 'Grade level is required').required('required')
})

export const useUploadSong = () => {
	const [searchArtist, setSearchArtist] = useState('')
	const { teacherData: teacher } = useLoginContext()
	const history = useHistory()
	const { data: scalesData, loading: scalesLoading } = useGetScalesQuery()
	const { data: artistsData, loading: artistsLoading } = useGetArtistsQuery()
	const [insertSongMutation, { data: insertData, error: insertError, loading: insertLoading }] =
		useInsertSongMutation()
	const gradesCatalog = useQuery<QueryGradeType>(queryGrade)

	const formik = useFormik({
		initialValues: {
			title: '',
			image_path: '',
			key: 'Am',
			featured: false,
			active: false,
			scale_id: 1,
			artist_id: 1,
			genres: [] as Catalog_Item[],
			themes: [] as Catalog_Item[],
			ages: [] as Catalog_Item[],
			levels: [] as Grade[]
		},
		validationSchema,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: () => handleSongInsert()
	})

	const handleSongInsert = async () => {
		try {
			const f = formik.values
			const songCatalogItem = {
				data: [
					...f.themes.map((catalogItem) => ({
						catalog_item_id: catalogItem.catalog_item_id
					})),
					...f.genres.map((catalogItem) => ({
						catalog_item_id: catalogItem.catalog_item_id
					})),
					...f.ages.map((catalogItem) => ({
						catalog_item_id: catalogItem.catalog_item_id
					}))
				]
			}

			const levels = { data: [...f.levels?.map((level) => ({ grade_id: level.grade_id }))] }
			await insertSongMutation({
				variables: {
					title: formik.values.title,
					img: formik.values.image_path,
					key: formik.values.key,
					featured: formik.values.featured,
					active: formik.values.active,
					scale_id: formik.values.scale_id,
					artist_id: formik.values.artist_id,
					teacher_id: teacher?.teacher_id,
					songCatalogItems: songCatalogItem,
					levels
				}
			})
		} catch {}
	}

	const handleCancel = () => {
		history.push(buildRouteParameters(Pages.MusicCatalog))
	}

	const goToUploadTracks = () => {
		if (insertData?.insert_song_one?.song_id) {
			history.push(
				buildRouteParameters(Pages.SongUploadTrack, {
					songId: insertData?.insert_song_one?.song_id
				})
			)
		}
	}

	const goBackToSongs = () => {
		history.push(buildRouteParameters(Pages.MusicCatalog))
	}

	return {
		formik,
		artists: artistsData?.artist ?? [],
		artistsLoading,
		searchArtist,
		setSearchArtist,

		scales: scalesData?.scale ?? [],
		scalesLoading,

		insertData,
		insertError,
		insertLoading,

		handleCancel,
		goToUploadTracks,
		goBackToSongs,
		gradesCatalog
	}
}
