import { makeStyles, Theme } from '@material-ui/core'
import MusicStudioBackground from 'src/assets/images/MusicStudioBackground.svg'

export const useStudioBackgroundStyles = makeStyles((theme: Theme) => ({
	backgroundImage: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `url(${MusicStudioBackground})`,
		width: '100%',
		height: '100%',
		position: 'fixed',
		left: '0',
		top: '0'
	},
	logoPosition: {
		position: 'absolute',
		top: '24px',
		left: '16px'
	}
}))
