import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Button, CircularProgress, Link, Typography } from '@material-ui/core'
import { Location } from 'history'
import { Link as LinkRD } from 'react-router-dom'
import { QueryCatalogItemsType, PagesProps } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons/CustomIcons'
import { CarouselOfAny, Catalogs, ImageButton, ImageButtonProps, ListVideos, MainTitle } from 'src/components'
import { FeaturedPlaylists } from 'src/components/FeaturedPlaylists'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { useGetBannerChannelLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { useScroll } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages, SlugEnum } from 'src/routes/teacherPages'
import { useVideos } from 'src/scenes/Teacher/scenes/8-Videos/hooks/useVideos'
import { buildImagePath, getCyKey, TypeEnum } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'

import { QueryCatalogItems } from '../../queries'
import { useStyles } from './Videos.style'

export const Videos = ({ page }: PagesProps) => {
	useScroll()
	const styles = useStyles()

	const { teacherData: teacher } = useLoginContext()
	const [getChannels, { data: channels, loading: loadingChannels }] = useGetBannerChannelLazyQuery()

	const teacherId = teacher.teacher_id

	const { onSearch, onSearchEnter, searchFeaturedVideosData, featuredVideosData, fetchFirstTime } = useVideos({
		page
	})

	const catalogs = useQuery<QueryCatalogItemsType>(QueryCatalogItems(TypeEnum.Video))

	useEffect(() => {
		getChannels()
		fetchFirstTime()
	}, [])

	const LinkToFeatured = (location: Location) => {
		return {
			...location,
			pathname: Pages.FeaturedVideos.path,
			state: {
				catalog: SlugEnum.Featured
			}
		}
	}

	const LinkToChannels = (location: Location) => {
		return {
			...location,
			pathname: Pages.Channels.path
		}
	}

	return (
		<Box>
			<MainTitle breadcrumbs={[page]} title={<></>} titleMargin={0} />
			{!loadingChannels && channels?.channel.length ? (
				<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
					<Typography variant="h6">
						<b>Channels</b>
					</Typography>
					<div style={{ flexGrow: 1 }} />
					<Link className={styles.link} component={LinkRD} to={LinkToChannels}>
						<b>View All</b>
					</Link>
				</Box>
			) : (
				<></>
			)}
			{!loadingChannels && channels ? (
				<CarouselOfAny
					slidesPerPage={{ xs: 1, sm: 1 }}
					spacing={3}
					data={[...channels.channel]?.map((channel) => ({
						backgroundImageUrl: channel.banner_image_path ?? '',
						title: <Typography style={{ fontSize: 28 }}>{channel.title}</Typography>,
						subtitle: <Typography style={{ fontSize: 20 }}>{channel.banner_description}</Typography>,
						element: (
							<Box className={styles.channel}>
								<img className={styles.logo} src={buildImagePath(channel.logo_image_path)} />
								<Button>Go To Channel Page</Button>
							</Box>
						),
						linkTo: {
							pathname: buildRouteParameters(Pages.ChannelPage, { channelId: channel.channel_id })
						}
					}))}
					renderItem={(item: ImageButtonProps) => (
						<Box position={'relative'}>
							<ImageButton
								className={styles.banner}
								fitToContainer
								titleHeight={{ xs: 250, md: 400 }}
								backgroundColor={item.backgroundColor}
								backgroundImageUrl={item.backgroundImageUrl}
								title={item.title}
								subtitle={item.subtitle}
								titlePosition="initial"
								element={item.element}
								elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
								linkTo={item.linkTo}
								partner={true}
								imageHeight={{ xs: 250, md: 400 }}
							/>
						</Box>
					)}
				/>
			) : (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			<MainTitle
				breadcrumbs={[]}
				title={page.name}
				description="Short videos that provide real, helpful advice from Grammy award winning artists, and activities by industry professionals."
				rightActions={[
					{
						variant: 'outlined',
						className: styles.actionButton,
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.VideoUpload.path
					}
				]}
			/>
			<SearchBar
				placeholder="Find Video by Title, Subject or Keyword"
				onChange={(e) => onSearch(e.target.value)}
				onEnterPressed={onSearchEnter}
				loading={searchFeaturedVideosData.loading}
				dropdownItems={searchFeaturedVideosData.data?.tip.map((el, key) => {
					return (
						<ListVideos
							returnItemAlone
							key={key}
							dataCy={getCyKey(Videos, `Featured`)}
							teacherId={teacherId}
							spacing={1.5}
							listItem={el as Tip}
							listItemNumber={key}
							data={[]}
							copyLinkButton
						/>
					)
				})}
			/>

			<FeaturedPlaylists />

			<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>Videos</b>
				</Typography>
				<div style={{ flexGrow: 1 }} />
				<Link className={styles.link} component={LinkRD} to={LinkToFeatured}>
					<b data-cy={getCyKey(Videos, 'btnViewAllFeatured')}>View All</b>
				</Link>
			</Box>
			{Boolean(featuredVideosData.data?.tip.length) && (
				<>
					<ListVideos
						dataCy={getCyKey(Videos, `Featured`)}
						teacherId={teacherId}
						spacing={1.5}
						data={(featuredVideosData.data?.tip as Tip[]) ?? []}
						copyLinkButton
					/>
				</>
			)}

			{!catalogs.loading && catalogs.data && <Catalogs parentPage={'video'} data={catalogs.data} />}
		</Box>
	)
}
