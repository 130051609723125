import { makeStyles, Theme } from '@material-ui/core'

type StyledCardProps = {
	error?: boolean
}

export const useFormBoxStyles = makeStyles<Theme, StyledCardProps>((theme) => ({
	card: {
		width: '100%',
		borderRadius: '4px',
		border: (props: StyledCardProps) =>
			'1px solid ' + (props.error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.23)'),
		padding: '18.5px 14px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover:not(:active)': {
			borderColor: (props: StyledCardProps) =>
				props.error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.87)'
		},
		'&:focus, &:active': {
			borderColor: theme.palette.secondary.main,
			borderWidth: '2px'
		}
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	placeholder: {
		color: 'rgb(166, 166, 166)',
		lineHeight: 1
	},
	iconButton: {
		padding: 0,
		alignSelf: 'flex-start'
	}
}))

export default useFormBoxStyles
