import { SyncClassLinkIcon } from 'src/assets/icons'
import { IconActionOptionType } from 'src/components/Cards/IconActionCard/IconActionCard'

import { SyncCleverIcon, SyncGoogleIcon, SyncMicrosoftIcon } from '../assets/icons'
import { IntegrationOptsType } from './enums'

export const INTEGRATIONS_OPTS: IconActionOptionType[] = [
	{
		id: IntegrationOptsType.clever,
		name: 'Use Clever',
		icon: <img style={{ width: '60px' }} alt="Clever" src={SyncCleverIcon} />
	},
	{
		id: IntegrationOptsType.classLink,
		name: 'Use Classlink',
		icon: <img style={{ width: '60px' }} alt="Clever" src={SyncClassLinkIcon} />
	},
	{
		id: IntegrationOptsType.google,
		name: 'Use Google Classroom',
		icon: <img alt="google" src={SyncGoogleIcon} />
	},
	{
		id: IntegrationOptsType.teams,
		name: 'Use Microsoft Teams',
		icon: <img alt="microsoft" src={SyncMicrosoftIcon} />,
		disabled: true
	}
]
