import React, { useEffect, useState, Fragment } from 'react'

import {
	Box,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Typography
} from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { ThinCheckIcon } from 'src/assets/icons'
import { SelectDistrictDialog } from 'src/scenes/Login/components/SelectDistrictDialog'

import { ListDialog } from '..'
import {
	useGetSchoolByCleverIdLazyQuery,
	useGetValidCodesLazyQuery,
	useInsertIntegrationStudentsToClassMutation,
	useValidateStudentsInClassLazyQuery
} from '../../graphql/autogenerate/hooks'
import {
	CleverGroup,
	GoogleGroup,
	Integration_Name_Enum,
	Student,
	StudentDataOutput,
	StudentInsertData
} from '../../graphql/autogenerate/schemas'
import { useIntegrationContext } from '../../hooks/useIntegration'
import { IntegrationOptsType, INTEGRATIONS_OPTS } from '../../utils'
import { AddStudentIntegrationDialog } from './AddStudentsIntegrationDialog'
import { BaseDialog } from './BaseDialog/BaseDialog'
import { InfoDialog } from './InfoDialog'

type AddStudentsPlatformSelectorDialogType = {
	isOpen: boolean
	setIsOPen: React.Dispatch<React.SetStateAction<boolean>>
	setIsManualInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
	classId: number | undefined
	classTitle: string | undefined
}
const useStyles = makeStyles(() => ({
	selectedClassBox: {
		color: '#F3AB34',
		backgroundColor: '#FFF8EA'
	},
	buttonSize: {
		width: '200px'
	}
}))
export const AddStudentsPlatformSelectorDialog = ({
	isOpen,
	setIsOPen,
	setIsManualInviteOpen,
	classId,
	classTitle
}: AddStudentsPlatformSelectorDialogType) => {
	const { selectedClassBox, buttonSize } = useStyles()
	const [isInsertStudentsInClass, setIsInsertStudentsInClass] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [isCoursesDialogOpen, setIsCoursesDialogOpen] = useState(false)
	const [isAddNewStudentsDialogOpen, setIsAddNewStudentsDialogOpen] = useState(false)
	const [isChangeEmailOpen, setIsChangeEmailOpen] = useState(false)
	const [newStudentsInClass, setNewStudentsInClass] = useState<
		(StudentDataOutput & { integrationId: string })[]
	>([])
	const [existingStudentsInClass, setExistingStudentsInClass] = useState<StudentDataOutput[]>([])
	const [selectedIntegrationClassId, setSelectedIntegrationClassId] = useState<string>()
	const [successfulAddition, setSuccessfulAddition] = useState<boolean>(false)
	const [selectCleverDistrictOpen, setSelectCleverDistrictOpen] = useState(false)
	const [getSchoolByCleverId, { data: schoolData, loading: schoolDataLoading }] =
		useGetSchoolByCleverIdLazyQuery()
	const [insertStudentsIntegrationInClass] = useInsertIntegrationStudentsToClassMutation()

	const [validateStudentsInClass, { data: validateStudentsData, loading: loadingStudentInClass }] =
		useValidateStudentsInClassLazyQuery()
	const [getValidCodes] = useGetValidCodesLazyQuery()

	const {
		googleInstance,
		classLinkInstance,
		msTeamsInstance,
		cleverInstance,
		classes,
		students,
		loadingStudents,
		loadingClasses,
		setIntegrationType,
		integrationType
	} = useIntegrationContext()

	useEffect(() => {
		const newStudents = validateStudentsData?.validateStudentsInClass?.studentsNewInClass?.map((i) => {
			const integrationId = students?.find((x) => x.email === i?.email)?.integrationId

			return { ...i, integrationId }
		})
		setNewStudentsInClass(newStudents as (StudentDataOutput & { integrationId: string })[])
		setExistingStudentsInClass(
			validateStudentsData?.validateStudentsInClass?.studentsAlreadyInClass as StudentDataOutput[]
		)
	}, [validateStudentsData])

	useEffect(() => {
		if (students?.length && classId) {
			const studentObject = students.map((i) => ({
				first_name: i.name?.first,
				last_name: i.name?.last,
				email: i?.email as string
			}))
			validateStudentsInClass({
				variables: { students: { classId, students: studentObject } }
			})
		}
	}, [students, classId])

	useEffect(() => {
		if (integrationType === IntegrationOptsType.classLink && classId !== undefined) {
			classLinkInstance.runLoadStudents(classId.toString())
		}
		// classLinkInstance.
	}, [])

	useEffect(() => {
		return cleverInstance.clearAccount
	}, [])

	const insertStudentsInClass = async () => {
		try {
			setIsInsertStudentsInClass(true)
			const { data: validCodesData } = await getValidCodes({
				variables: { filter: newStudentsInClass?.length || 1 }
			})
			if (!classId || !validCodesData?.getValidCodes.length) return
			const studentsInsertObject = newStudentsInClass.map(
				({ first_name, last_name, email, integrationId }, i) => ({
					first_name,
					last_name,
					email,
					code: validCodesData.getValidCodes[i],
					school_id: schoolData?.school[0]?.school_id || null,
					integration_id: integrationId
				})
			) as StudentInsertData[]
			const integrationName =
				integrationType === IntegrationOptsType.google
					? Integration_Name_Enum.Google
					: Integration_Name_Enum.Clever

			await insertStudentsIntegrationInClass({
				variables: {
					data: { classId, students: studentsInsertObject, integrationName }
				},
				update: (cache) => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'validateStudentsInClass'
					})
					setIsAddNewStudentsDialogOpen(false)
				},
				onCompleted: () => setSuccessfulAddition(true)
			})
		} catch (e) {
			setIsOnErrorOpen(true)
			console.error(e)
		} finally {
			setIsInsertStudentsInClass(false)
		}
	}
	const openLogin = async (instanceType: IntegrationOptsType) => {
		let authed
		if (instanceType === IntegrationOptsType.google) authed = await googleInstance?.openPopup()
		if (instanceType === IntegrationOptsType.teams) authed = await msTeamsInstance?.openPopup()
		if (instanceType === IntegrationOptsType.clever) authed = await cleverInstance?.openPopup()
		if (instanceType === IntegrationOptsType.classLink) authed = await classLinkInstance?.openPopup()
		if (!authed) return
		setIsCoursesDialogOpen(true)
	}

	return (
		<Box>
			<ListDialog
				open={isOpen}
				cols={2}
				onClose={() => {
					setIsOPen(false)
				}}
				title="Add Students"
				description="Please select how you'd like to add your Students."
				discardLabel="Cancel"
				onDiscard={() => {
					setIsOPen(false)
				}}
				onDiscardProps={{
					style: {
						width: '100%'
					}
				}}
				optionSelected={''}
				disableHover
				options={[
					...INTEGRATIONS_OPTS,
					{
						id: 'Manual Invite',
						name: (
							<Box display="flex">
								<AddCircle />
								<Typography style={{ paddingLeft: 10 }}>
									<b>Manual Invite</b>
								</Typography>
							</Box>
						)
					}
				]}
				handleSelectOption={(opt: string) => {
					switch (opt) {
						case IntegrationOptsType.google:
							setIntegrationType(IntegrationOptsType.google)
							if (googleInstance?.getAccount()) {
								setIsChangeEmailOpen(true)
							} else {
								openLogin(IntegrationOptsType.google)
							}
							setIsOPen(false)
							break
						case IntegrationOptsType.teams:
							return

						case IntegrationOptsType.clever:
							// setSelectCleverDistrictOpen open the district selector for clever login
							// if (false) setSelectCleverDistrictOpen(true)
							setIntegrationType(IntegrationOptsType.clever)
							setIsOPen(false)
							openLogin(IntegrationOptsType.clever)
							break
						case IntegrationOptsType.classLink:
							setIntegrationType(IntegrationOptsType.classLink)
							setIsOPen(false)
							openLogin(IntegrationOptsType.classLink)
							break
						case 'Manual Invite':
							setIsManualInviteOpen(true)
							setIsOPen(false)
							break
						default:
							break
					}
				}}
				cardHeight={140}
			/>

			<BaseDialog
				onConfirm={() => {
					if (!selectedIntegrationClassId) return
					if (integrationType === IntegrationOptsType.google)
						googleInstance?.getClassStudents(selectedIntegrationClassId)
					if (integrationType === IntegrationOptsType.clever)
						cleverInstance.getClassStudents(selectedIntegrationClassId)

					setIsCoursesDialogOpen(false)
					setIsAddNewStudentsDialogOpen(true)
				}}
				dividers={false}
				confirmLabel="Import"
				discardLabel="Close"
				onDiscard={() => {
					setIsCoursesDialogOpen(false)
				}}
				open={isCoursesDialogOpen}
				isConfirmDisabled={classes.length === 0 || !selectedIntegrationClassId}
				title="Invite Students"
				header={
					<Fragment>
						<Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
							<b>Invite Students</b>
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Box>
									<Typography style={{ textAlign: 'center', margin: '10px 0' }}>
										Select one class to import your students
									</Typography>
									<div style={{ flexGrow: 1 }} />
									<Box display="flex" alignItems="center">
										<Typography>
											<b>Classes</b>
										</Typography>
										<div style={{ flexGrow: 1 }} />
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Fragment>
				}
				onClose={() => {}}
				onDiscardProps={{
					className: buttonSize
				}}
				onConfirmProps={{
					className: buttonSize
				}}
				body={
					loadingClasses ? (
						<Box style={{ margin: 20, textAlign: 'center' }}>
							<CircularProgress color="secondary" size={40} />
						</Box>
					) : classes?.length ? (
						<List style={{ maxHeight: '400px' }}>
							{classes.map((i, index) => {
								return (
									<Box
										className={
											i.id === selectedIntegrationClassId ? selectedClassBox : undefined
										}
										key={index}
										boxShadow={1}
										margin="5px"
										borderRadius={4}
										marginBottom={2}
										paddingY={2}
										onClick={() => {
											const iClever = i as CleverGroup
											if (iClever?.id) {
												setSelectedIntegrationClassId(iClever.id)
												if (iClever?.school)
													getSchoolByCleverId({
														variables: { cleverId: iClever.school }
													})
											}
										}}>
										<ListItem style={{ cursor: 'pointer' }}>
											<ListItemText
												style={{ marginRight: '20px' }}
												disableTypography
												primary={
													<Typography noWrap>
														<b>{(i as GoogleGroup | CleverGroup).name}</b>
													</Typography>
												}
											/>
										</ListItem>
									</Box>
								)
							})}
						</List>
					) : (
						<Box style={{ opacity: 0.5 }}>
							<Typography variant="h5">No classes found</Typography>
						</Box>
					)
				}
			/>
			<BaseDialog
				onConfirm={() => {
					setIsChangeEmailOpen(false)
					setIsCoursesDialogOpen(true)
				}}
				confirmLabel="Yes"
				discardLabel="Change account"
				onDiscard={() => {
					setIsChangeEmailOpen(false)
					googleInstance?.clearToken()
					if (integrationType) openLogin(integrationType)
				}}
				open={isChangeEmailOpen}
				onClose={() => {
					setIsChangeEmailOpen(false)
				}}
				header={`Continue with ${
					integrationType === IntegrationOptsType.google
						? googleInstance?.getAccount()?.teacherEmail
						: ''
				}?`}
			/>
			<AddStudentIntegrationDialog
				isConfirmDisabled={schoolDataLoading || isInsertStudentsInClass}
				open={isAddNewStudentsDialogOpen}
				setOpen={setIsAddNewStudentsDialogOpen}
				primaryTitle={`New Students Imported (${newStudentsInClass?.length || 0})`}
				secondaryTitle={`Already in ${classTitle} (${existingStudentsInClass?.length || 0})`}
				itemsPrimary={
					newStudentsInClass?.map(
						(i) =>
							({ ...i, selected: false } as { selected: boolean; integrationId: string } & Student)
					) || []
				}
				itemsSecondary={
					existingStudentsInClass?.map(
						(i) => ({ ...i, selected: false } as { selected: boolean } & Student)
					) || []
				}
				loading={loadingStudentInClass || loadingStudents || isInsertStudentsInClass}
				onSave={insertStudentsInClass}
				onDiscard={() => {
					setIsCoursesDialogOpen(true)
				}}
			/>
			<InfoDialog
				open={successfulAddition}
				onClose={() => {
					setSuccessfulAddition(false)
				}}
				icon={<ThinCheckIcon />}
				title={
					<Typography variant="h4">
						<b>Students Added!</b>
					</Typography>
				}
				body="You have successfully added these Students to this Class."
				onDiscard={() => {
					setSuccessfulAddition(false)
				}}
				confirmLabel="Close"
				onConfirm={() => {
					setSuccessfulAddition(false)
				}}
			/>
			<SelectDistrictDialog
				open={selectCleverDistrictOpen}
				setOpen={setSelectCleverDistrictOpen}
				onConfirm={() => {
					setIsOPen(false)
					openLogin(IntegrationOptsType.clever)
				}}
				onDistrictSelect={(districtId: string) => {
					cleverInstance.setDistrictId(districtId)
				}}
			/>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {
					setIsOnErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
			/>
		</Box>
	)
}
