import { Dispatch, SetStateAction, RefObject } from 'react'

import { LazyQueryHookOptions, QueryLazyOptions } from '@apollo/client'
import toWav from 'audiobuffer-to-wav'
import _ from 'lodash'
import moment from 'moment'
import QRCode from 'qrcode'
import { Page, ChordMapObjectType, TranspositionType } from 'src/@types'
import {
	ComparisonObject,
	DateRange,
	extractLevelsProps,
	ProductName,
	Cache,
	SubscriptionsCardType
} from 'src/@types/types'
import { cache } from 'src/apollo/state'
import DEFAULT_THUMBNAIL from 'src/assets/images/default-thumbnail.svg'
import { DateFilterEnum } from 'src/environment'
import { GetSongByPkQuery, GetSongForStudioQuery } from 'src/graphql/autogenerate/operations'
import {
	Catalog_Name_Enum,
	Grade,
	Lesson_Plan,
	Module,
	Order_By,
	Playlist,
	Sequence,
	Song,
	Tip,
	Lesson_Plan_Grade,
	Wurrly_Type_Enum,
	Product_Name_Enum,
	Order,
	Partner_Module,
	Channel_Playlist,
	Catalog_Bool_Exp,
	Catalog_Order_By,
	District,
	Tip_Catalog_Item,
	Lesson_Plan_Catalog_Item,
	Visibility_Enum
} from 'src/graphql/autogenerate/schemas'
import { StudentPages } from 'src/routes/studentpages'
import { Pages as TeacherPages } from 'src/routes/teacherPages'
import { ProductDataItemType } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/useAllClassProducts'
import { v4 as uuidv4 } from 'uuid'
import WaveformData from 'waveform-data'
import * as yup from 'yup'

import {
	AWS_SONG_URL,
	BASE_URL,
	DEFAULT_THUMBNAIL_NAME,
	FRAMES,
	LEVELS,
	MAX_LIST_ITEMS,
	MILISECONDS_TO_MINUTES,
	WAIT_TIME,
	YOUTUBE_COM,
	YOUTUBE_BE,
	BAMBOO_VIDEO_COM
} from './constants'
import { ProductIdsEnum, ProductTitleEnum, SortOrder, TypeEnum } from './enums'
import { buildRouteParameters } from './routes-utils'

export const RandomNumberKey = () => {
	const randomNumber = Math.floor(Math.random() * 1000)

	return randomNumber
}
export const getModuleLessonPlanAggregate = (data: ProductDataItemType) => {
	if ('module_lesson_plans_aggregate' in data) {
		return data.module_lesson_plans_aggregate.aggregate?.count || 0
	} else {
		return 0
	}
}
export const getId = (data: ProductDataItemType) => {
	if (ProductIdsEnum.LessonPlan in data) {
		return data.lesson_plan_id
	} else if (ProductIdsEnum.Module in data) {
		return data.module_id
	} else if (ProductIdsEnum.Playlist in data) {
		return data.playlist_id
	} else if (ProductIdsEnum.Tip in data) {
		return data.tip_id
	} else if (ProductIdsEnum.Song in data) {
		return data.song_id
	} else {
		return 0
	}
}

export const getTeacherId = (data: ProductDataItemType) => {
	if ('teacher_id' in data) {
		return data.teacher_id
	} else {
		return 0
	}
}

export const getPermissionName = (visibility: Visibility_Enum | undefined) => {
	switch (visibility) {
		case Visibility_Enum.Public:
			return 'Public'
		case Visibility_Enum.AdminOnly:
			return 'Admin Only'
		case Visibility_Enum.Restricted:
			return 'Specific Users'
		case Visibility_Enum.Private:
			return 'Private'
		case Visibility_Enum.ForSale:
			return 'For Sale'
		default:
			return ''
	}
}
export const uniq = <T>(array: T[]): T[] => [...new Set(array)]
export const getCatalogItems = (
	catalogItem: Pick<Tip_Catalog_Item | Lesson_Plan_Catalog_Item, 'catalog_item'>[]
) => {
	return catalogItem.reduce((prev, curr) => {
		const currName = curr.catalog_item.name
		if (prev.includes(currName)) return prev

		return [...prev, currName]
	}, [] as string[])
}

export const getRoles = (type: string) => {
	switch (type) {
		case ProductTitleEnum.LessonPlan:
			return 'Lesson'
		case ProductTitleEnum.Module:
			return 'Module'
		case ProductTitleEnum.Playlist:
			return 'Playlist'
		case ProductTitleEnum.Song:
			return 'Song'
		case ProductTitleEnum.Tip:
			return 'Video'
		default:
			return 'Lesson'
	}
}
export const writeQueryCache = <T, U, V extends string>(data: ProductName<V>, cacheData: Cache<T, U>) => {
	const { variables, query } = cacheData

	cache.writeQuery({
		query,
		variables,
		data
	})
}

export const getExistingCache = <T, U, V extends string>(cacheData: Cache<T, U>) => {
	const { variables, query } = cacheData

	return cache.readQuery({
		query,
		variables
	}) as ProductName<V>
}

export const refetchCache = <T, U>(cacheData: Cache<T, U>) => {
	const { isFetching, data, type } = cacheData
	if (!cache.readQuery || type === undefined) return
	const existingCache = getExistingCache(cacheData)
	const cloneCache = _.cloneDeep(existingCache)
	if (!cloneCache) return

	if (isFetching) {
		;(cloneCache[type] as []).push(...(data as []))
	} else {
		cloneCache[type] = data as []
	}

	writeQueryCache(cloneCache, cacheData)

	return true
}

export const generateFlagText = (item: SubscriptionsCardType) => {
	switch (item.productType) {
		case Product_Name_Enum.Channel:
			return `${item.firstLevelAggregate} PlayList | ${item.secondLevelAggregate || 0} Videos`
		case Product_Name_Enum.Partner:
			return `${item.firstLevelAggregate} Modules | ${item.secondLevelAggregate || 0} Lessons`
		case Product_Name_Enum.Playlist:
			return `${item.firstLevelAggregate} Videos`
		case Product_Name_Enum.Module:
			return `${item.firstLevelAggregate} Lessons`
		default:
			return ''
	}
}
export const createSuscriptionCardData = (subscription: SubscriptionsCardType[], item: Order) => {
	if (subscription.find((subscription) => subscription.orderId === item.order_id)) return

	let title
	let description
	let image_path
	let productType
	let stripeSubscriptionId
	let firstLevelAggregate
	let productId
	let secondLevelAggregate

	if (item.channel != null) {
		title = item.channel.title
		description = item.channel.description
		image_path = `${item.channel.image_path}`
		productType = item.channel.__typename
		stripeSubscriptionId = item.stripe_subscription_id
		firstLevelAggregate = item.channel.channel_playlists_aggregate.aggregate?.count || 0
		productId = item.channel.channel_id
		secondLevelAggregate = getSecondLevelAggregate(item.channel.channel_playlists_aggregate.nodes)
	} else if (item.partner != null) {
		title = item.partner.title
		description = item.partner.description
		image_path = `${item.partner.image_path}`
		productType = item.partner.__typename
		stripeSubscriptionId = item.stripe_subscription_id
		firstLevelAggregate = item.partner.partner_modules_aggregate.aggregate?.count || 0
		productId = item.partner.partner_id
		secondLevelAggregate = getSecondLevelAggregate(item.partner.partner_modules_aggregate.nodes)
	} else if (item.playlist != null) {
		title = item.playlist.name
		description = item.playlist.description
		image_path = `${item.playlist.image_path}`
		productType = item.playlist.__typename
		stripeSubscriptionId = item.stripe_subscription_id
		firstLevelAggregate = item.playlist.tip_playlist_aggregate.aggregate?.count || 0
		productId = item.playlist.playlist_id
	} else if (item.module != null) {
		title = item.module.title
		description = item.module.description
		image_path = `${item.module.image_path}`
		productType = item.module.__typename
		stripeSubscriptionId = item.stripe_subscription_id
		firstLevelAggregate = item.module.module_lesson_plan_aggregate.aggregate?.count || 0
		productId = item.module.module_id
	}

	if (
		title == null ||
		description == null ||
		image_path == null ||
		productType == null ||
		stripeSubscriptionId == null ||
		firstLevelAggregate == null ||
		productId == null
	)
		return

	const cardData: SubscriptionsCardType = {
		status: item.status,
		title,
		description,
		image_path,
		orderId: item.order_id,
		priceType: item.price_type,
		price: item.total,
		expiresAt: item.expires_at,
		productType,
		stripeSubscriptionId,
		firstLevelAggregate,
		productId,
		secondLevelAggregate
	}

	return cardData
}

const getSecondLevelAggregate = (items: (Partner_Module | Channel_Playlist)[]) => {
	let aux = 0
	for (const item of items) {
		if (item.__typename === 'channel_playlist') {
			aux += item.playlist.tip_playlist_aggregate.aggregate?.count || 0
		} else if (item.__typename === 'partner_module') {
			aux += item.module.module_lesson_plan_aggregate.aggregate?.count || 0
		}
	}

	return aux
}

export const nameToSlug = (name = '') => `/${name.replace('/', '').toLowerCase().split(' ').join('-')}`
export const slugToName = (slug = '') =>
	slug
		.split('-')
		.map((word) => (word = word.slice(0, 1).toUpperCase() + word.slice(1)))
		.join(' ')
// export const tableNameFixed = (tableName = '') => capitalize(tableName.replace('catalog_', ''))
export const capitalize = (name = '') => name.charAt(0).toUpperCase() + name.slice(1)
export const concatenate = (elements: (string | number)[], separator = '/') =>
	elements.reduce((a, b) => `${a}${b}${separator}`, '')
export const getChords = (transpositions?: TranspositionType) => {
	const chordArray: string[] = []
	if (transpositions) {
		for (const { lines } of transpositions) {
			chordArray.push(...new Set([...lines.map((x) => x.words[0][1] as string)]))
		}
	}

	return chordArray
}
export const filterSongsByChord = (songs: Song[], chords: string[], transposed = false) => {
	return songs.filter((song) =>
		song.tracks.some((track) =>
			track.midis.some((midi) => {
				let songChords: string[]
				if (transposed) {
					songChords = (midi.chords_Array || '').split(',')
				} else {
					songChords = ((midi.chord_map_object || {}) as ChordMapObjectType)[0]?.chordArray || []
				}

				return chords.every((selectedChord) => songChords.includes(selectedChord))
			})
		)
	)
}
export const removeRepeatedById = <T extends { id: number }>(c: T, index: number, self: T[]) =>
	index === self.findIndex((t) => t.id === c.id)

export const buildQueryVariables = <T>(variables: { [key: string]: T }) => {
	const queryVar: QueryLazyOptions<typeof variables> = {
		variables
	}

	return queryVar
}

export const buildQueryVariablesV1 = <T>(variables: T) => {
	const queryVar: QueryLazyOptions<typeof variables> = {
		variables
	}

	return queryVar
}

export const buildPagination = (teacherId?: number, offset = 0, studentId?: number, limit = MAX_LIST_ITEMS) => {
	const opt: LazyQueryHookOptions = {
		variables: {
			...(teacherId ? { teacherId } : {}),
			...(studentId ? { studentId } : {}),
			offset,
			limit
		},
		fetchPolicy: 'cache-and-network'
	}

	return opt
}

export const buildImagePath = (path: string | undefined | null, fromResources?: boolean) => {
	if (!path) return ''

	if (path === DEFAULT_THUMBNAIL_NAME) {
		return DEFAULT_THUMBNAIL
	}
	let newPath = path
	if (fromResources && !path.includes('resources')) {
		if (path[0] === '/') newPath = `resources${path}`
		if (path[0] !== '/') newPath = `resources/${path}`
	}

	return newPath.startsWith('http')
		? newPath
		: newPath[0] === '/'
		? `${BASE_URL}${newPath.substring(1)}`
		: `${BASE_URL}${newPath}`
}

export const buildVideoPath = (path: string) =>
	path.startsWith('http') ? path : path[0] === '/' ? `${BASE_URL}${path.substring(1)}` : `${BASE_URL}${path}`

export const orderBySortOrder = {
	[SortOrder.DateAsc]: Order_By.Asc,
	[SortOrder.DateDesc]: Order_By.Desc,
	[SortOrder.Up]: Order_By.Asc,
	[SortOrder.Down]: Order_By.Desc,
	[SortOrder.Recent]: Order_By.Desc,
	[SortOrder.Type]: Order_By.Desc,
	[SortOrder.Grade]: Order_By.Desc
}

export const getSongGenres = (song: Song) => {
	return song.song_catalog_item
		? song.song_catalog_item
				.filter((item) => item.catalog_item.catalog.name === Catalog_Name_Enum.Genres)
				.map((item) => item.catalog_item.name)
				.join(', ')
		: ''
}
export const getVideoGenres = (video: Tip) => {
	return video.tip_catalog_item ? video.tip_catalog_item.map((item) => item.catalog_item.name).join(', ') : ''
}

export const getPlaylistGenres = (playlist: Playlist): string[] => {
	const genres: string[] = []
	for (const tipPlaylist of playlist.tip_playlist) {
		const tipCatalogItems = tipPlaylist.tip?.tip_catalog_item
		if (!tipCatalogItems) continue
		for (const tipCatalogItem of tipCatalogItems) {
			const catalogItemName = tipCatalogItem.catalog_item.name
			if (!genres.includes(catalogItemName)) {
				genres.push(catalogItemName)
			}
		}
	}

	return genres
}
export const getModuleGenres = (module: Module): string[] => {
	const genres: string[] = []
	module?.module_lesson_plan?.map((lesson) => {
		if (lesson.lesson_plan && lesson.module_id === 71) {
			lesson?.lesson_plan?.lesson_plan_catalog_item.map((item) => {
				const catalogItemName = item?.catalog_item?.name
				if (!genres.includes(catalogItemName)) {
					genres.push(catalogItemName)
				}
			})
		}
	})

	return genres
}

export const getLessonSubjects = (lesson: Lesson_Plan) => {
	return lesson?.lesson_plan_catalog_item
		? lesson?.lesson_plan_catalog_item
				.filter((item) => item.catalog_item.catalog.name === Catalog_Name_Enum.Subjects)
				.map((item) => item.catalog_item.name)
				.join(', ')
		: ''
}

export const getSequenceItemCatalogItems = (sequence?: Sequence) => {
	if (!sequence) return ''

	const catalogNames = []
	for (const sec of sequence.sequence_topic_sequences) {
		for (const catalog of sec.sequence_topic.sequence_topic_catalog_items) {
			catalogNames.push(catalog.catalog_item.name)
		}
	}
	const catalogItems = catalogNames.join(', ')

	return catalogItems && `• ${catalogItems}`
}

export const getModuleCatalogItems = (module?: Module) => {
	if (!module) return ''

	const catalogItems = (module.module_catalog_items || []).map((item) => item.catalog_item.name).join(', ')

	return catalogItems && `• ${catalogItems}`
}

export const sortByKey =
	<T>(keyGetter: (_: T) => string | number, descending = false) =>
	(left: T, right: T) => {
		const l = keyGetter(left)
		const r = keyGetter(right)
		if (l < r) {
			return descending ? 1 : -1
		}
		if (r < l) {
			return descending ? -1 : 1
		}

		return 0
	}

export const getCyKey = <T>(func: (props: T) => JSX.Element, key?: string) => `${func.name}${key ? `-${key}` : ''}`

export const formatDate = (dateTmp: string | Date, useMoment = false) => {
	const date = typeof dateTmp === 'string' ? new Date(dateTmp) : dateTmp
	if (useMoment) return moment(date).format('ddd, DD MMM YYYY')

	const opts: Intl.DateTimeFormatOptions = {
		month: 'short',
		day: '2-digit',
		weekday: 'short',
		year: 'numeric'
	}

	// @reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
	// 'en-US': US English uses month-day-year order
	// 'en-GB': British English uses day-month-year order
	return new Intl.DateTimeFormat('en-GB', opts).format(date)
}

export const generateUniqueId = () => {
	return uuidv4()
}

export const generateRandomString = (length = 7) => {
	let result = ``
	const characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}

	return result
}

export const spliceAndReturn = <T>(list: T[], getter: (el: T) => boolean): [T[], T] | null => {
	const cpy = [...list]
	const index = cpy.findIndex(getter)
	if (index < 0) return null

	const removedElement = cpy.splice(index, 1)[0]

	return [cpy, removedElement]
}

/**
 *
 * @param page current Page
 * @param replaceIndexes replace the recursive parent or current page (index starts at 0)
 * @param params replace in all paths the :identifier by its value on this value
 * @param studentPage if set to `true` the `StudentPages` will be used, else `TeacherPages`
 * @example
 * page: { name: Class Lessons, parentName: ClassDetails }
 * if no replaceIndexes, return Classes/Class Details/Class Lessons
 * otherwise, you can override the breadcum name updating the overrideName
 * and update the id in params
 * @returns list of breadcrumbs
 */
export const buildBreadCrumbs = ({
	page: currentPage,
	replaceIndexes = [],
	params,
	isStudentPage
}: {
	page: Page
	replaceIndexes?: {
		index: number
		overrideName?: string
	}[]
	params?: Record<string, string | number>
	isStudentPage?: boolean
}) => {
	let page = { ...currentPage }
	const breadcrumbs: Page[] = [page]
	const pages = isStudentPage ? StudentPages : TeacherPages

	while (1) {
		if (!page.parentName || page.name === page.parentName) break
		page = pages[page.parentName as keyof typeof pages]
		breadcrumbs.push({ ...page })
	}

	breadcrumbs.reverse()

	if (replaceIndexes.length > 0 && breadcrumbs.length > 0) {
		for (const { index, overrideName } of replaceIndexes) {
			if (index < breadcrumbs.length && index >= 0) {
				if (overrideName) breadcrumbs[index].name = overrideName
			}
		}
	}

	if (params)
		breadcrumbs.forEach((breadcrumb) => {
			breadcrumb.path = buildRouteParameters(breadcrumb, params, false)
		})

	return breadcrumbs
}

export const generateQR = (code: string): string => {
	let urlQR = ''
	QRCode.toDataURL(code, { scale: 10, type: 'image/webp', margin: 0 }, (err, url) => {
		if (!err) urlQR = url
	})

	return urlQR
}

export const getPriceInDollars = (priceInCents: number) => (priceInCents / 100).toFixed(2)

/**
 *
 * @param text search string
 * @param fields array with object name or nested object like "artist.name"
 * @param caseInsensitive default true, true: "_ilike" and false: "_like"
 * @returns array with objects comparison
 */
export const buildSearchText = (
	text: string,
	fields: string[],
	type?: Product_Name_Enum,
	caseInsensitive = true
): ComparisonObject[] => {
	const word = text.trim().replace(/\s+/g, ' ').split(' ').join('%')

	const comparisonObjects = fields.map((field) => {
		const obj = {}
		_.set(obj, field, {
			[caseInsensitive ? '_ilike' : '_like']: `%${word}%`
		})

		switch (type) {
			case Product_Name_Enum.Partner:
				return { partner: obj }
			case Product_Name_Enum.Channel:
				return { channel: obj }
			case Product_Name_Enum.Module:
				return { module: obj }
			case Product_Name_Enum.Playlist:
				return { playlist: obj }
			default:
				return obj
		}
	})

	return comparisonObjects.flat()
}

export const buildStudentSearchParams = (text: string) => {
	const searchText = text.trim()
	// full match condition
	const searchSplit = searchText.split(' ')

	const conditionsArray = [
		[
			{ last_name: { _ilike: `%${searchText}%` } },
			{ first_name: { _ilike: `%${searchText}%` } },
			{ email: { _ilike: `%${searchText}%` } },
			{
				_and: [
					{ first_name: { _ilike: `%${searchSplit[0]}%` } },
					{ last_name: { _ilike: `%${searchSplit[1]}%` } }
				]
			}
		]
	]

	const conditions = conditionsArray.reduce((acc, curr) => {
		return [...acc, ...curr]
	}, [])

	return conditions
}

// TODO: delete this function when is all migrate with buildSearchText function
export const buildTipSearchParams = (text: string) => {
	const searchText = text.trim()
	// full match condition
	const conditionsArray = [
		[{ title: { _ilike: `%${searchText}%` } }, { artist: { name: { _ilike: `%${searchText}%` } } }]
	]
	// conditionsArray = searchText.split(' ').map((i) => {
	// 	return [{ title: { _ilike: `%${i}%` } }, { artist: { name: { _ilike: `%${i}%` } } }]
	// })

	const conditions = conditionsArray.reduce((acc, curr) => {
		return [...acc, ...curr]
	}, [])

	return conditions
}

// TODO: delete this function when is all migrate with buildSearchText function
export const buildSongSearchParams = (text: string) => {
	const searchText = text.trim()
	// full match condition
	const conditionsArray = [
		[{ title: { _ilike: `%${searchText}%` } }, { artist: { name: { _ilike: `%${searchText}%` } } }]
	]
	// conditionsArray = searchText.split(' ').map((i) => {
	// 	return [{ title: { _ilike: `%${i}%` } }, { artist: { name: { _ilike: `%${i}%` } } }]
	// })

	const conditions = conditionsArray.reduce((acc, curr) => {
		return [...acc, ...curr]
	}, [])

	return conditions
}

export const buildLessonSearchParams = (text: string) => {
	const searchText = text.trim()
	// full match condition
	const conditionsArray = [
		[{ title: { _ilike: `%${searchText}%` } }, { description: { _ilike: `%${searchText}%` } }]
	]
	// conditionsArray = searchText.split(' ').map((i) => {
	// 	return [{ title: { _ilike: `%${i}%` } }, { description: { _ilike: `%${i}%` } }]
	// })

	const conditions = conditionsArray.reduce((acc, curr) => {
		return [...acc, ...curr]
	}, [])

	return conditions
}

export const buildPlaylistSearchParams = (text: string) => {
	const searchText = text.trim()
	// full match condition
	const conditionsArray = [
		[{ name: { _ilike: `%${searchText}%` } }, { description: { _ilike: `%${searchText}%` } }]
	]
	// conditionsArray = searchText.split(' ').map((i) => {
	// 	return [{ name: { _ilike: `%${i}%` } }, { description: { _ilike: `%${i}%` } }]
	// })

	const conditions = conditionsArray.reduce((acc, curr) => {
		return [...acc, ...curr]
	}, [])

	return conditions
}

export const hasSpecialChar = (text: string) => {
	const reg = /^[a-zA-Z0-9\s]*$/

	return !reg.test(text)
}

export const getFirstLetters = (text: string, maxAmount = 3) => {
	const words = text.trim().split(' ')
	let letters = ''
	for (let i = 0; i <= words.length && i <= maxAmount - 1; i++) {
		if (words[i] && words[i][0]) letters += words[i][0]
	}

	return letters
}

export const shouldSortTitleOrDate = (sort: SortOrder) => {
	const byTitle =
		sort !== SortOrder.Recent ? (sort !== SortOrder.Down ? Order_By.Asc : Order_By.Desc) : undefined
	const byDate = sort === SortOrder.Recent ? Order_By.Desc : undefined

	return [byTitle, byDate]
}

export const limitString = (word = '', opts?: { limit?: number; delimiter?: string }) => {
	const { limit = 40, delimiter = '...' } = opts || {}
	if (word.length <= limit) return word

	return `${word.substring(0, limit)}${delimiter}`
}

export const setTimeoutQuery = (
	query: () => void,
	setState: React.Dispatch<React.SetStateAction<number>>,
	timer: number
) => {
	clearTimeout(timer)
	setState(
		window.setTimeout(() => {
			query()
		}, 500)
	)
}

export const isWindowSmallerThanDocument = () => {
	return window.innerHeight < document.body.scrollHeight
}

export const getAudioUrl = (
	playbackrate: number,
	transposition: number,
	instrumentType: string,
	song: GetSongForStudioQuery['song_by_pk'] | GetSongByPkQuery['song'][0]
) => {
	let sufix = ''
	let audioUrl = ''
	const formattedSpeed = (1.0 + playbackrate) * 10
	if (transposition !== 0 || formattedSpeed !== 10) {
		sufix = `_${formattedSpeed.toString().length < 2 ? '0' + formattedSpeed : formattedSpeed}_${
			transposition * 100
		}`
	}
	if (song && song?.tracks) {
		const track = song.tracks.find((song_track) => song_track.track_type.name === instrumentType)
		if (track) {
			audioUrl = `${AWS_SONG_URL}${track.resource_path.replace('.m4a', sufix + '.m4a')}`
		} else {
			audioUrl = `${AWS_SONG_URL}${song.tracks[0].resource_path.replace('.m4a', sufix + '.m4a')}`
		}
	}

	return audioUrl
}

type getFramesFromVideoProps = {
	videoSrc: string
	framesCount: number
	width: number
	height: number
	setValues?: Dispatch<SetStateAction<string[]>>
}
/**
 * Extract evenly spaced stll frames from a video, returns an array with the corresponding `base64`
 * encoded images.
 *
 * if `setValues` is set it will add the new images as they are available, updating the `useState`
 * array on the fly without having to await the Promise with the results.
 */
export const getFramesFromVideo = async ({
	videoSrc,
	framesCount,
	width,
	height,
	setValues
}: getFramesFromVideoProps): Promise<string[]> => {
	return new Promise(async (resolve, reject) => {
		const handleVideoError = () => {
			const newError = 'Could not extract frames from the video'
			console.error(newError)

			return reject(new Error(newError))
		}

		const video = document.createElement('video')
		video.src = videoSrc
		video.width = width
		video.height = height
		const urls = [] as string[]

		video.onerror = () => {
			return handleVideoError()
		}
		if (!(await unstuckVideo(video))) return handleVideoError()

		let seekResolve: (value?: unknown) => void
		video.addEventListener('seeked', () => {
			if (seekResolve) seekResolve()
		})

		const stepSize = video.duration / framesCount

		for (let i = 0; i < framesCount; i++) {
			video.currentTime = i * stepSize
			await new Promise((r) => (seekResolve = r))

			const canvas = document.createElement('canvas')
			canvas.width = width
			canvas.height = height
			canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
			const imgSrc = canvas.toDataURL()
			urls.push(imgSrc)

			if (setValues) {
				setValues((prev) => [...prev, imgSrc])
			}
			canvas.remove()
		}
		video.remove()

		return resolve(urls)
	})
}
/**
 * __Workaround chromium metadata bug__ (https://bugs.chromium.org/p/chromium/issues/detail?id=642012)
 *
 * The problem is that video elements usually have the `duration` property as `Infinity` until
 * the whole video has been parsed, which doesn't happen until the video is playing
 *
 * @returns boolean if video was succesfully unstuck
 */
export const unstuckVideo = async (video: HTMLVideoElement): Promise<boolean> => {
	const errorTimeout = setTimeout(() => {
		return false
	}, WAIT_TIME)

	while ((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
		await new Promise((r) => setTimeout(r, 100))
		video.currentTime = 10000000 * Math.random()
	}
	clearTimeout(errorTimeout)
	video.currentTime = 0

	return true
}

type getAudioBufferProps = {
	videoSrc: string
	videoLength: number
}
export type WaveformDataType = {
	min: number[]
	max: number[]
}
export const getAudioWaveform = ({ videoSrc, videoLength }: getAudioBufferProps): Promise<WaveformDataType> => {
	return new Promise(async (resolve, reject) => {
		let audioBuffer: AudioBuffer
		try {
			audioBuffer = await getAudioBuffer({ videoSrc, videoLength })
		} catch (error) {
			console.error("Couldn't get audio buffer", error)

			return reject()
		}

		const options = {
			audio_buffer: audioBuffer,
			scale: 256
		}
		WaveformData.createFromAudio(options, (err, waveform) => {
			if (err) {
				console.error(err)

				return reject()
			}
			const channel = waveform.channel(0)

			return resolve({ min: channel.min_array(), max: channel.max_array() })
		})
	})
}
export const getAudioBuffer = ({ videoSrc, videoLength }: getAudioBufferProps): Promise<AudioBuffer> => {
	return new Promise(async (resolve, reject) => {
		const blob = await fetch(videoSrc)
			.then((result) => result.blob())
			.catch(() => null)

		if (!blob) return reject()

		const audioContext = new OfflineAudioContext({
			numberOfChannels: 2,
			length: 44100 * videoLength,
			sampleRate: 44100
		})
		const soundSource = audioContext.createBufferSource()
		const reader = new FileReader()
		reader.readAsArrayBuffer(blob)

		const errorTimeout = setTimeout(() => reject(), 5000)

		const handleReaderLoad = async () => {
			const fileAsBuffer = reader.result
			if (!fileAsBuffer || typeof fileAsBuffer === 'string') return

			try {
				const decodedAudioData = await audioContext.decodeAudioData(fileAsBuffer)
				soundSource.buffer = decodedAudioData
				soundSource.connect(audioContext.destination)
				soundSource.start()
				const audioBuffer = await audioContext.startRendering()
				clearTimeout(errorTimeout)

				return resolve(audioBuffer)
			} catch (error) {
				console.error("Couldn't extract audio Data", error)
				clearTimeout(errorTimeout)

				return reject()
			}
		}
		reader.onload = handleReaderLoad
	})
}

export const getBlob = async (fileSrc: string) => {
	const response = await fetch(fileSrc)
	const blob = await response.blob()

	return blob
}

export const getAudioBlob = async (videoSrc: string): Promise<Blob> => {
	const video = document.createElement('video')
	video.src = videoSrc

	if (!(await unstuckVideo(video))) {
		throw new Error('Could not extract audio from video')
	}
	const videoLength = video.duration
	const audioBuffer = await getAudioBuffer({ videoSrc, videoLength })
	const wavArrayBuffer = toWav(audioBuffer)

	return new Blob([new Uint16Array(wavArrayBuffer)], { type: 'audio/wav' })
}

/**
 * Wrapper for the native `Array.prototype.sort()` function
 * sorts the array in place and returns a reference to the same array.
 */
export const sortGradeLevels = (levels: Grade[] | Lesson_Plan_Grade[]) => {
	return levels.sort((a, b) => {
		let a_name = ''
		let b_name = ''

		if (a.__typename === 'grade' && b.__typename === 'grade') {
			a_name = a.name
			b_name = b.name
		} else if (a.__typename === 'lesson_plan_grade' && b.__typename === 'lesson_plan_grade') {
			a_name = a.grade.name
			b_name = b.grade.name
		}

		return LEVELS.indexOf(a_name) - LEVELS.indexOf(b_name)
	})
}

export const sortByName = <T extends { name: string }[]>(items: T): T => {
	return items.sort((a, b) => a.name.localeCompare(b.name))
}

/**
 * Set the state on true if scrolled to the bottom of the given component ref
 */
export const fetchOnScrollDown = (
	isFetching: boolean,
	setIsFetching: Dispatch<SetStateAction<boolean>>,
	element: HTMLElement
) => {
	if (isFetching || !element) return
	const scrollY = window.scrollY
	const screenHeight = window.innerHeight
	const offSetTop = element?.offsetTop
	const elementHeight = element?.offsetHeight
	if (scrollY + screenHeight >= offSetTop + elementHeight) setIsFetching(true)
}

export const lessonHasStandards = (lesson: Lesson_Plan | undefined) => {
	let hasStandards = false
	const process = lesson?.process
	if (process?.creating?.length) hasStandards = true
	if (process?.connecting?.length) hasStandards = true
	if (process?.performing?.length) hasStandards = true
	if (process?.responding?.length) hasStandards = true

	return hasStandards
}

export const getComparisonDate = (filter: DateFilterEnum): DateRange => {
	const today = new Date()
	const currentYear = today.getFullYear()
	const currentMonth = today.getMonth()
	const currentDay = today.getDate()
	const dayOfWeek = today.getDay()
	let comparisonFromDate: Date | null = null
	let comparisonToDate: Date | null = null

	switch (filter) {
		case DateFilterEnum.thisWeek:
			comparisonFromDate = new Date(currentYear, currentMonth, currentDay - dayOfWeek + 1)
			comparisonToDate = new Date(currentYear, currentMonth, currentDay - dayOfWeek + 7)
			break
		case DateFilterEnum.lastWeek:
			comparisonFromDate = new Date(currentYear, currentMonth, currentDay - dayOfWeek - 6)
			comparisonToDate = new Date(currentYear, currentMonth, currentDay - dayOfWeek)
			break
		case DateFilterEnum.lastSevenDays:
			comparisonFromDate = new Date(currentYear, currentMonth, currentDay - 6)
			comparisonToDate = new Date(currentYear, currentMonth, currentDay)
			break
		case DateFilterEnum.thisMonth:
			comparisonFromDate = new Date(currentYear, currentMonth, 1)
			comparisonToDate = new Date(currentYear, currentMonth + 1, 0)
			break
		case DateFilterEnum.lastMonth:
			comparisonFromDate = new Date(currentYear, currentMonth - 2, 1)
			comparisonToDate = new Date(currentYear, currentMonth - 1, 0)
			break
		case DateFilterEnum.lastSixMonths:
			comparisonFromDate = new Date(currentYear, currentMonth - 6, 1)
			comparisonToDate = new Date(currentYear, currentMonth, currentDay)
			break
		case DateFilterEnum.thisYear:
			comparisonFromDate = new Date(currentYear, 0, 1)
			comparisonToDate = new Date(currentYear, 11, 31)
			break
		case DateFilterEnum.lastYear:
			comparisonFromDate = new Date(currentYear - 1, currentMonth, currentDay)
			comparisonToDate = today
			break
		case DateFilterEnum.nextSevenDays:
			comparisonFromDate = today
			comparisonToDate = new Date(currentYear, currentMonth, currentDay + 7)
			break

		case DateFilterEnum.nextThreeMonths:
			comparisonFromDate = new Date(currentYear, currentMonth + 1, 1)
			comparisonToDate = new Date(currentYear, currentMonth + 4, 0)
			break
		case DateFilterEnum.allTime:
		default:
			comparisonFromDate = null
			comparisonToDate = null
	}

	return { fromDate: comparisonFromDate, toDate: comparisonToDate }
}

export const scrollToTop = () => window.scrollTo(0, 0)

export const downloadLink = (url: string) => {
	const link = document.createElement('a')
	link.href = url
	link.target = '_blank'
	// Append to html link element page
	document.body.appendChild(link)
	// Start download
	link.click()
	// Clean up and remove the link
	link.parentNode?.removeChild(link)
}

/**
 * Converts the given seconds number to a time string in the form [HH:]MM:SS
 * Hours are only included if its value at least 1
 */
export const secondsToTimeString = (seconds: number) => {
	const seg = Math.floor(seconds)
	const min = Math.floor(seg / 60)
	const hor = Math.floor(min / 60)

	const twoDigits = (num: number) => (isNaN(num) ? '--' : num.toString().padStart(2, '0'))

	return `${hor > 0 ? `${twoDigits(hor)}:` : ''}${twoDigits(min % 60)}:${twoDigits(seg % 60)}`
}

export const getDataFromDate = (dateString: string, timeString: string) => {
	// Set Date
	// Replace "-" with "/" so the days isn't one day off
	const date = new Date(dateString.replace(/-/g, '/'))
	const month = getMonthName(date.getMonth())
	const year = date.getFullYear()
	const dayNumber = date.getUTCDate()

	// Set Time
	if (timeString) {
		const [hour, minute] = timeString.split(':')
		date.setHours(+hour, +minute, 0, 0)
	}
	const time = formatAMPM(date)
	const dayName = getDayName(date.getDay())

	return { month, dayNumber, dayName, year, time }
}

type formatSubmissionDateProps = {
	fullDateString?: string
	dateString?: string
	timeString?: string
	dateObject?: Date
}
export const formatSubmissionDate = ({
	fullDateString,
	dateString,
	timeString,
	dateObject
}: formatSubmissionDateProps) => {
	if (!fullDateString && !dateString && !timeString && !dateObject) {
		throw new Error(
			'Must provide at least one of the following: fullDateString, dateString, timeString, dateObject'
		)
	}

	if (dateObject) {
		fullDateString = dateObject.toISOString()
	}
	if (fullDateString) {
		dateString = fullDateString.split('T')[0]
		timeString = fullDateString.split('T')[1]
	}
	if (dateString && timeString) {
		const { month, dayNumber, dayName, time } = getDataFromDate(dateString, timeString)

		return `${dayName}, ${dayNumber} ${month} - ${time}`
	}

	return 'invalid date'
}

export const getMonthName = (month: number) => {
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

	return monthNames[month]
}

export const getDayName = (day: number) => {
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

	return days[day]
}

export const formatAMPM = (date: Date) => {
	let hours: number | string = date.getHours()
	let minutes: number | string = date.getMinutes()
	const ampm = hours >= 12 ? 'PM' : 'AM'
	if (!hours) {
		hours = '00'
	} else {
		hours = hours % 12
		hours = hours ? hours : 12 // the hour '0' should be '12'
		hours = hours.toString().length === 1 ? '0' + hours : hours
	}
	minutes = minutes < 10 ? '0' + minutes : minutes
	const strTime = hours + ':' + minutes + ' ' + ampm

	return strTime
}

export const dueDateExpired = (dueDateString: string, dueTimeString: string, compareDate?: Date) => {
	// Set Date
	const dueDate = new Date(dueDateString)
	// Set Time
	if (dueTimeString) {
		const [hour, minute] = dueTimeString.split(':')
		dueDate.setHours(+hour, +minute, 0, 0)
	}
	if (!compareDate) compareDate = new Date()

	return compareDate > dueDate
}

export const isSSR = !(typeof window !== 'undefined' && window.document?.createElement)

export const getRefElement = <T>(element?: RefObject<Element> | T): Element | T | undefined | null => {
	if (element && 'current' in element) {
		return element.current
	}

	return element
}

export const getTimetzFromStringDate = (assignmentTime: Date) => {
	// TimeInput returns the time 3 hours later than selected, so i have to substract 3 hours from the given time
	assignmentTime.setHours(assignmentTime.getHours() - 3)
	const str = assignmentTime.toISOString()
	const time = str.split('T')[1]
	const [hour, minute, sec] = time.split(':')

	return `${+hour}:${minute}:${sec}`
}

export const validateEmail = (email: string) => {
	if (!email) return false

	return yup.string().email().isValidSync(email)
}

export const getOffset = (el: HTMLDivElement) => {
	const rect = el.getBoundingClientRect()
	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop

	return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const buildFrameUrl = (framePath: string) => FRAMES + framePath

/**
 * Remove undefined values from an object
 */
export const clearObject = (object: Record<string, unknown> | undefined) => {
	if (object === undefined) {
		return {}
	}

	const newObject = { ...object }
	Object.keys(newObject).forEach((key) => newObject[key] === undefined && delete newObject[key])

	return newObject
}

export const stopEventPropagation = (event: React.MouseEvent<HTMLElement, MouseEvent>) => event.stopPropagation()

export const formatMsToMinutesAndSeconds = (millis: number) => {
	const minutes = Math.floor(millis / MILISECONDS_TO_MINUTES)
	const seconds = Math.floor((millis % MILISECONDS_TO_MINUTES) / 1000)
	const miliseconds = Math.floor((millis - minutes * MILISECONDS_TO_MINUTES - seconds * 1000) / 10)

	return minutes + ':' + (seconds < 10 ? '0' : '') + seconds + ':' + (miliseconds < 10 ? '0' : '') + miliseconds
}

export const getWurrlyType = (type: string | undefined) => {
	switch (type) {
		default:
			return ''
		case Wurrly_Type_Enum.Assignment:
			return 'Assignment'
		case Wurrly_Type_Enum.Indie:
			return 'Indie'
		case Wurrly_Type_Enum.Challenge:
			return 'Challenge'
	}
}

export const extractLevels = ({ selected, options }: extractLevelsProps): Grade[] => {
	const result = [] as Grade[]

	if (selected && options) {
		for (const grade_id of selected) {
			const option = options.find((item) => item.grade_id === grade_id)
			if (option) result.push(option)
		}
	}
	sortGradeLevels(result)

	return result
}

export const getCatalogItemFilter = (type: TypeEnum): { filter: Catalog_Bool_Exp; order: Catalog_Order_By } => {
	return {
		filter: {
			[`${type}_order`]: { _is_null: false }
		},
		order: {
			[`${type}_order`]: Order_By.Asc
		}
	}
}

export const displayDistrict = (district: Pick<District, 'city' | 'name' | 'state' | 'district_id'>) => {
	return district.name.concat(
		district.city ? `, ${district.city}` : '',
		district.state ? `, ${district.state}` : ''
	)
}

export const isFromYoutube = (url: string) => {
	return url.includes(YOUTUBE_COM) || url.includes(YOUTUBE_BE)
}

export const isFromBamboo = (url: string) => {
	return url.includes(BAMBOO_VIDEO_COM)
}

export const createYoutubeThumbnailLink = (videoUrl: string) => {
	const isIdInUrlParams = videoUrl.includes('?')
	let videoId: string | null = ''
	if (isIdInUrlParams) {
		const stringParams = videoUrl.split('?')[1]
		const params = new URLSearchParams(stringParams)

		videoId = params.get('v')
	} else {
		videoId = videoUrl.split('youtu.be/')[1]
	}

	return videoId ? `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg` : ''
}

export const isEmbed = (url: string) => {
	try {
		const urlObject = new URL(url)

		return urlObject.searchParams.has('embed') || urlObject.pathname.includes('embed')
	} catch (e) {
		return false
	}
}

export const convertLessonData = (lesson: Lesson_Plan) => {
	const {
		inspire,
		practice,
		record,
		reflect,
		take_it_further,
		materials_needed,
		differentiations,
		suggested_assignments
	} = lesson

	return [
		{ title: 'INSPIRE', content: inspire, lesson_plan_article_id: 0 },
		{ title: 'PRACTICE', content: practice, lesson_plan_article_id: 1 },
		{ title: 'CREATE', content: record, lesson_plan_article_id: 2 },
		{ title: 'REFLECT', content: reflect, lesson_plan_article_id: 3 },
		{ title: 'TAKE IT FURTHER', content: take_it_further, lesson_plan_article_id: 4 },
		{ title: 'MATERIALS NEEDED', content: materials_needed, lesson_plan_article_id: 5 },
		{ title: 'DIFFERENTIATIONS', content: differentiations, lesson_plan_article_id: 6 },
		{ title: 'SUGGESTED ASSIGNMENT', content: suggested_assignments, lesson_plan_article_id: 7 }
	]
}
