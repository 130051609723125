import React from 'react'

import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import { WurrlyBlackIcon } from 'src/assets/icons/CustomIcons'
import LoginDesign from 'src/assets/images/LogInDesign.png'
import { BaseButton, BaseLink } from 'src/components'
import { COLORS } from 'src/styles/colors'
import { BOX_PADDING } from 'src/styles/constants'
import { getCyKey } from 'src/utils'

export type SelectLoginProps = {
	App: () => JSX.Element
}
const useStyles = makeStyles({
	navBar: {
		zIndex: 4,

		backgroundColor: COLORS.appBackground,
		boxShadow:
			'0px 0.6700000166893005px 2.009999990463257px 0px rgba(0, 0, 0, 0.15),0px 1.340000033378601px 0.6700000166893005px 0px rgba(0, 0, 0, 0.1),0px 0.6700000166893005px 0.6700000166893005px 0px rgba(0, 0, 0, 0.09)'
	},

	contentBox: {
		padding: 40,
		borderRadius: 24
	},
	title: {
		lineHeight: '36.4px',
		fontSize: 28,
		fontWeight: 700,
		marginBottom: 10
	},
	subtitle: {
		fontSize: 16,
		marginBottom: 10
	},
	buttons: {
		' & span': {
			color: `${COLORS.black}!important`
		}
	}
})

export const SelectLogin: React.FC<SelectLoginProps> = ({ App }) => {
	const classes = useStyles()

	return (
		<Box minHeight={'100vh'} display={'flex'} flexDirection={'column'}>
			<Box className={classes.navBar} p={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
				<WurrlyBlackIcon style={{ width: 200, opacity: 0.7 }} />
			</Box>
			<Box display={'flex'} flex={2}>
				<Box zIndex={2} flex={{ xs: 0, md: 1 }} textAlign={'center'} position={'relative'}>
					<img
						style={{ position: 'absolute', top: '-12%', left: 0 }}
						src={LoginDesign}
						height={'100%'}
					/>
				</Box>
				<Box
					marginLeft={{ xs: 0, md: '90px' }}
					padding={BOX_PADDING}
					flex={1}
					display={'flex'}
					zIndex={4}
					alignItems={'center'}>
					<Box className={classes.contentBox} bgcolor={COLORS.white}>
						<Typography className={classes.title} variant="h5" align="left">
							Log in
						</Typography>
						<Typography className={classes.subtitle} variant="body1" align="left">
							Select the type of user you want to log in as
						</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6}>
								<BaseLink style={{ flex: 1 }} href="/login/student">
									<BaseButton
										fullWidth
										className={classes.buttons}
										color={'secondary'}
										data-cy={getCyKey(App, 'btnTeacher')}
										variant="outlined">
										Student
									</BaseButton>
								</BaseLink>
							</Grid>
							<Grid item xs={12} sm={6}>
								<BaseLink style={{ flex: 1 }} href="/login/teacher">
									<BaseButton
										fullWidth
										className={classes.buttons}
										color={'secondary'}
										data-cy={getCyKey(App, 'btnTeacher')}
										variant="outlined">
										Teacher
									</BaseButton>
								</BaseLink>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
