import { useState, useEffect } from 'react'

import { NotesIcon } from 'src/assets/icons/CustomIcons'
import { FixedBackground } from 'src/components'
import { useSearchQuery } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import NotFound from 'src/scenes/Errors/404'

import { AccountActivationDialog } from './components/ActivateAccountDialog/AccountActivationDialog'
import useStyles from './TeacherTokenAccountActivation.styles'

export const TeacherTokenAccountActivation = () => {
	const styles = useStyles()
	const { isAuthenticated, teacherData: teacher } = useLoginContext()
	const [token, setToken] = useState<string>()

	const queryParams = useSearchQuery()

	useEffect(() => {
		const tokenFromUrl = queryParams.get('token')
		if (tokenFromUrl && typeof tokenFromUrl === 'string') {
			setToken(tokenFromUrl)
		}
	}, [queryParams])

	return (
		<FixedBackground>
			<NotesIcon className={styles.notesIcon} />
			{token ? (
				<AccountActivationDialog token={token} />
			) : (
				<NotFound isLoggedIn={isAuthenticated && !!teacher.teacher_id} />
			)}
		</FixedBackground>
	)
}
