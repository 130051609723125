import { makeStyles, Theme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type DialogStyles = {
	backdropStyles?: CSSProperties
	dialogPaperStyles?: CSSProperties
	dialogActionsStyles?: CSSProperties
	transparentBackdrop?: boolean
	isFullWidthButton?: boolean
}

export const useBaseDialogStyles = makeStyles<Theme, DialogStyles>((theme: Theme) => ({
	baseDialog: {
		'& .MuiBackdrop-root': {
			opacity: ({ backdropStyles, transparentBackdrop }) =>
				transparentBackdrop ? '0 !important' : backdropStyles?.opacity
		},
		'& .MuiDialog-paper': {
			padding: 40,
			maxWidth: ({ dialogPaperStyles }) => dialogPaperStyles?.maxWidth,
			minWidth: ({ dialogPaperStyles }) => dialogPaperStyles?.minWidth
		},
		'& .MuiDialogTitle-root': {
			padding: 0
		},
		'& .MuiDialogContent-root': {
			padding: 0
		},
		'& .MuiButton-contained.Mui-disabled': {
			backgroundColor: '#323342',
			opacity: 0.5,
			color: 'white'
		},
		'& .MuiDialogActions-root': {
			padding: ({ dialogActionsStyles }) => dialogActionsStyles?.padding ?? '20px 0px 0px 0px',
			margin: 0,
			justifyContent: ({ isFullWidthButton }) => (isFullWidthButton ? 'center' : 'space-between'),
			'& .boxActions': {
				'& .MuiButton-root': {
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		}
	},
	discardBtn: {
		padding: '6px 40px'
	},
	confirmButton: {
		padding: '6px 50px'
	},
	fullWidthButton: {
		padding: '6px 50px',
		width: '100%'
	},
	confirmTypography: {
		display: 'flex',
		alignItems: 'center'
	},
	confirmLoader: {
		marginLeft: '1em'
	},
	separator: {
		flexGrow: 1
	},
	footer: {
		marginTop: '40px',
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	title: {
		paddingTop: '10px',
		fontSize: '2rem'
	}
}))

export default useBaseDialogStyles
