import React, { useEffect, useState } from 'react'

import { Box, ButtonProps, ListItemText, Tooltip, Typography } from '@material-ui/core'
import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CallMadeIcon from '@material-ui/icons/CallMade'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'

import { ActionButton, BaseButton } from '.'
import { NumStr } from '../@types/types'
import { CartIcon, LockIcon, StandardIcon } from '../assets/icons/CustomIcons'
import { Pages } from '../routes/teacherPages'
import { getCyKey } from '../utils'
import { buildRouteParameters } from '../utils/routes-utils'

const useStyles = makeStyles((theme: Theme) => ({
	listItem: {
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: theme.palette.common.white,
		cursor: 'pointer',
		'& .boxActions': {
			'& .MuiButton-root': {
				minWidth: 'auto',
				height: 36,
				'&:not(.addClass)': {
					width: 36,
					padding: theme.spacing(0.75),
					color: theme.palette.primary.main
				},
				'&.subscription': {
					backgroundColor: '#FFF6DB',
					borderColor: '#F3AB34',
					border: '2px solid',
					width: 'auto',
					padding: theme.spacing(0.75, 1.5)
				},
				'&.addToClass': {
					backgroundColor: '#221f31',
					width: 'auto',
					padding: theme.spacing(0.75, 1.5),
					color: '#E2E2E4'
				},
				'&:not(:last-child)': {
					marginRight: theme.spacing(1)
				},
				'&:last-child, &.addClass': {
					marginLeft: theme.spacing(1)
				}
			}
		}
	},
	locked: {
		fontSize: '0.75rem',
		marginTop: '1px',
		marginLeft: '2px'
	},
	lockedButton: {
		borderRadius: '100px',
		width: '125px',
		height: '27px'
	},

	subscribe: { color: '#F3AB34' },
	avatarImage: {
		position: 'relative',
		backgroundSize: 'cover',
		backgroundPosition: 'center 50%',
		color: theme.palette.common.white,
		'& .main': {
			width: '100%',
			padding: theme.spacing(2),
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(2)
			},
			backgroundColor: fade(theme.palette.common.black, 0.4)
		},
		'& .bottomBar': {
			width: '100%',
			paddingRight: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			color: theme.palette.common.white,
			backgroundColor: fade(theme.palette.common.black, 0.4),
			alignItems: 'center'
		}
	}
}))

export type ListItemModuleProps = {
	module_id?: number
	imageUrl: string
	title: string
	description: string
	genres?: string[][] | string
	totallessons: number
	onClick?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
	btnStandard?: ButtonProps
	btnAddToClass?: { selected?: boolean } & ButtonProps
	btnSuscription?: ButtonProps
	btnLearnMore?: ButtonProps
	btnRemove?: ButtonProps
	btnView?: ButtonProps
	width?: NumStr
	subscription?: boolean
	visibility?: Visibility_Enum
	partner_id?: number
}

export const ListItemModule = ({
	module_id,
	imageUrl,
	title,
	description,
	genres,
	totallessons,
	btnStandard,
	btnAddToClass,
	btnSuscription,
	btnLearnMore,
	btnRemove,
	btnView,
	onClick,
	width = '100%',
	subscription,
	visibility,
	partner_id
}: ListItemModuleProps) => {
	const history = useHistory()
	const styles = useStyles()
	const [genresData, setGeresData] = useState<string>()

	const goToModuleDetail = () => {
		if (!module_id) return

		if (partner_id) {
			history.push(
				buildRouteParameters(Pages.PartnerModuleDetails, {
					moduleId: module_id,
					partnerId: partner_id
				})
			)

			return
		}

		history.push(
			buildRouteParameters(Pages.ModuleDetails, {
				moduleId: module_id
			})
		)
	}

	useEffect(() => {
		if (genres && genres?.length > 0 && typeof genres !== 'string') {
			const unfilteredGenres = genres?.filter((value) => value !== undefined).join(', ')
			const filteredGenres = unfilteredGenres
				?.split(',')
				.filter((value) => value.trim() !== '')
				.join(', ')
			setGeresData(filteredGenres)
		} else {
			setGeresData('')
		}
	}, [genres])

	return (
		<Box
			width={width}
			overflow="hidden"
			boxShadow={1}
			borderRadius={4}
			onClick={onClick || goToModuleDetail}
			className={styles.listItem}>
			<Box
				width="18%"
				className={styles.avatarImage}
				style={{ backgroundImage: `url(${buildImagePath(imageUrl)})` }}>
				<Box
					className="main"
					display="flex"
					justifyContent="initial"
					alignItems="initial"
					height={{ xs: 250, md: 160 }}>
					<Typography data-cy={getCyKey(ListItemModule, 'moduleName' + title)} variant="h5" align="left">
						{title}
					</Typography>
				</Box>
				<Box className="bottomBar" position="absolute" display="flex" height={44} bottom={0}>
					<Typography style={{ fontSize: 12 }}>{totallessons} Lessons</Typography>
				</Box>
			</Box>
			<Box width="82%" display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" padding="24px">
				<ListItemText
					style={{ width: '100%' }}
					primary={
						<Box>
							<Typography style={{ fontSize: 14 }}>
								<b data-cy={getCyKey(ListItemModule, 'itemCarousel')}>{description}</b>
							</Typography>
						</Box>
					}
					secondary={genresData ? `•${genresData}` : ''}
				/>
				<Box display="flex" width="100%">
					{!subscription && visibility === Visibility_Enum.ForSale && (
						<Tooltip title="You don't own this product">
							<BaseButton
								color="primary"
								startIcon={<LockIcon />}
								className={styles.lockedButton}
								onClick={(e) => {
									e.stopPropagation()
								}}>
								<Typography className={styles.locked}>Locked</Typography>
							</BaseButton>
						</Tooltip>
					)}
					<Box display="flex" className="boxActions" justifyContent="flex-end" width="100%">
						{btnStandard && (
							<Tooltip title="Standard">
								<ActionButton
									disabled
									onClick={goToModuleDetail}
									icon={<StandardIcon />}
									{...btnStandard}
								/>
							</Tooltip>
						)}
						{btnRemove && (
							<Tooltip title="Remove">
								<ActionButton
									data-cy={getCyKey(ListItemModule, 'btnRemove')}
									icon={<DeleteIcon fontSize="small" />}
									{...btnRemove}
								/>
							</Tooltip>
						)}
						{btnLearnMore && (
							<BaseButton
								color="default"
								className={'addClass'}
								endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}
								onClick={goToModuleDetail}
								{...btnLearnMore}>
								<Typography>Learn More</Typography>
							</BaseButton>
						)}
						{btnAddToClass &&
							(visibility !== Visibility_Enum.ForSale ||
								(visibility === Visibility_Enum.ForSale && subscription)) && (
								<Tooltip data-cy={getCyKey(ListItemModule, 'btnAddToClass')} title="Add to class">
									<BaseButton
										color="primary"
										className={'addClass'}
										endIcon={<AddIcon />}
										{...btnAddToClass}>
										<Typography>Add to class</Typography>
									</BaseButton>
								</Tooltip>
							)}
						{btnSuscription && !subscription && visibility === Visibility_Enum.ForSale && (
							<Tooltip title="Subscribe to Module">
								<BaseButton
									className={'subscription'}
									startIcon={<CartIcon style={{ color: '#F3AB34' }} />}
									{...btnSuscription}>
									<Typography className={styles.subscribe}>Subscribe to Module</Typography>
								</BaseButton>
							</Tooltip>
						)}
						{btnView && (
							<Tooltip title="View">
								<BaseButton
									data-cy={getCyKey(ListItemModule, 'btnView')}
									color="primary"
									className={'addClass'}
									endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}
									onClick={goToModuleDetail}
									{...btnView}>
									<Typography>View</Typography>
								</BaseButton>
							</Tooltip>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
