import { PagesProps } from 'src/@types'
import { useGetCatalogByPkQuery } from 'src/graphql/autogenerate/hooks'
import { Catalog_Item } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { LessonsCatalogBody } from 'src/scenes/Teacher/scenes/6-Lessons/components/LessonsCatalogBody'
import { buildBreadCrumbs, capitalize } from 'src/utils'
export const CatalogItemLessons = ({ page }: PagesProps) => {
	const { teacherData: teacher } = useLoginContext()

	const params = useWurrlyParams<typeof Pages.CatalogItemLessons.params[number]>()
	const { catalogId, catalogItemId } = params

	const { data: catalogData } = useGetCatalogByPkQuery({
		variables: {
			catalogId: catalogId || 0
		}
	})

	const teacherId = teacher.teacher_id

	const catalogName = catalogData?.catalog_by_pk?.name
	const catalogItem = catalogData?.catalog_by_pk?.catalog_items.find(
		(item) => item.catalog_item_id === catalogItemId
	) as Catalog_Item | undefined

	const title = catalogItem?.name || page.name

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: capitalize(catalogName)
			},
			{
				index: 2,
				overrideName: title || ''
			}
		],
		params
	})

	return (
		<LessonsCatalogBody
			breadcrumbs={breadcrumbs}
			catalogId={catalogId}
			catalogItemId={catalogItemId}
			teacherId={teacherId}
			title={title}
			catalogName={catalogName}
		/>
	)
}
