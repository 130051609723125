import { Dispatch, SetStateAction } from 'react'

import { Box, Button, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MainTitle, BaseLoading } from 'src/components'
import { AddProductToClassDialog } from 'src/components/Dialogs/AddProductToClassDialog/AddProductToClassDialog'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { AllClassProductsCard } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/AllClassProductsCard'
import { useAllClassProducts } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/useAllClassProducts'

export const AllClassProducts = () => {
	const {
		data,
		loadingData,
		totalData,
		styles,
		searchbarText,
		handleAddProductDialog,
		type,
		breadCrumbs,
		handleCancelAddDialog,
		setShowAddDialog,
		setSelectedData,
		showAddDialog,
		selectedData,
		handleSearchbarTextChange,
		handleSearchbarTextEnter,
		searchbarData,
		searchbarLoading,
		fetchMore,
		moreResults
	} = useAllClassProducts()

	return (
		<>
			<MainTitle
				breadcrumbs={breadCrumbs}
				title={`All Class ${type}`}
				rightActions={[
					{
						color: 'secondary',
						endIcon: <AddIcon />,
						name: `Add More ${type}`,
						onClick: handleAddProductDialog
					}
				]}
			/>

			<SearchBar
				placeholder={`Find ${type} by Title or Description`}
				onChange={handleSearchbarTextChange}
				onEnterPressed={handleSearchbarTextEnter}
				loading={searchbarLoading}
				dropdownItems={searchbarData.map((item, index) => (
					<AllClassProductsCard
						key={`AllClassProductsCardSearchBar-${index}`}
						data={item}
						type={type as string}
					/>
				))}
			/>
			<Box className={styles.listToolbar}>
				<Typography variant="h6">
					<b>
						{type} ({totalData})
					</b>
				</Typography>
			</Box>

			<InfiniteScroll
				dataLength={data.length}
				next={fetchMore}
				hasMore={!loadingData && data?.length < totalData}
				className={styles.infiniteScroll}
				endMessage={
					!loadingData &&
					totalData > 0 &&
					!moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)
				}
				loader={<BaseLoading />}>
				{data.map((item, index) => (
					<AllClassProductsCard
						key={`AllClassProductsCard-${index}`}
						data={item}
						type={type as string}
						addAssigment={true}
						addToClass={false}
					/>
				))}
			</InfiniteScroll>

			{totalData === 0 && !loadingData && (
				<Typography variant="h6" color="textSecondary">
					Your search for "{searchbarText}" did not return any results.
				</Typography>
			)}

			<AddProductToClassDialog<typeof selectedData[0]>
				onClose={() => setShowAddDialog(false)}
				selectedData={selectedData}
				setSelected={setSelectedData as Dispatch<SetStateAction<typeof selectedData[0][]>>}
				open={showAddDialog}
				handleCancel={handleCancelAddDialog}
				type={type as string}
			/>
		</>
	)
}
