import React from 'react'

import { Box, Paper, Table, TableBody, TableContainer, TableContainerProps } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
	createStyles({
		stickyHeader: {
			'& .MuiTableCell-stickyHeader': {
				backgroundColor: '#fff'
			}
		},
		row: {
			'& .MuiTableCell-root': {
				padding: '9px'
			}
		}
	})
)

type BaseTableProps<T> = {
	rows: T[]
	header: React.ReactNode
	renderRow: (row: T) => JSX.Element | undefined
}
export const BaseTable = <T extends unknown>({
	rows,
	header,
	renderRow,
	...ext
}: BaseTableProps<T> & TableContainerProps) => {
	const styles = useStyles()

	return (
		<Box>
			<TableContainer component={Paper} {...ext}>
				<Table
					stickyHeader
					aria-label="simple table"
					className={clsx(styles.row, styles.stickyHeader)}
					// style={{ marginRight: '20px' }}
				>
					{header}
					<TableBody>{rows.map((row) => renderRow(row))}</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}
