import React, { useState } from 'react'

import {
	Box,
	CircularProgress,
	createStyles,
	Divider,
	makeStyles,
	Tab,
	Tabs,
	Theme,
	Typography
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Lesson_Plan, Song, Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { queryFavoriteLessons, queryFavoriteSongs, queryFavoriteTips } from 'src/scenes/Teacher/queries'
import { useGetFavorites } from 'src/scenes/Teacher/scenes/9-MyFavorites/hooks'
import { buildRouteParameters, MAX_LIST_ITEMS, SortOrder, TypeEnum } from 'src/utils'

import { ActivityBoxEmpty, BaseLink, ListLessons, ListSongs, ListVideos } from '../../../../../components'
import { SectionHeader } from './SectionHeader'

export const YourFavorites = () => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [currentTab, setCurrentTab] = useState(0)

	const SharedTabSwitcher = () => (
		<Box>
			<TabSwitcher
				currentTab={currentTab}
				setCurrentTab={setCurrentTab}
				lessonFavTabEnabled={true}
				songFavTabEnabled={true}
				videoFavTabEnabled={true}
			/>
		</Box>
	)

	const NoContent = () => (
		<Box>
			<ActivityBoxEmpty
				title="You have not saved anything to your Favorites yet."
				description="Start browsing the content libraries to save the items you like most."
				style={{ height: '10em', padding: '4em 0', justifyContent: 'center' }}
				noBorder
			/>
			<Divider style={{ margin: '1em 0' }} />
		</Box>
	)

	const [limitSongs] = useState(MAX_LIST_ITEMS)
	const [limitTips] = useState(MAX_LIST_ITEMS)
	const [limitLessons] = useState(MAX_LIST_ITEMS)

	const [songs, loadingSongs] = useGetFavorites<Song>(
		queryFavoriteSongs,
		teacherId,
		limitSongs,
		SortOrder.Up,
		TypeEnum.Song
	)

	const [tips, loadingTips] = useGetFavorites<Tip>(
		queryFavoriteTips,
		teacherId,
		limitTips,
		SortOrder.Up,
		TypeEnum.Video
	)

	const [lessons, loadingLessons] = useGetFavorites<Lesson_Plan>(
		queryFavoriteLessons,
		teacherId,
		limitLessons,
		SortOrder.Up,
		TypeEnum.Lesson
	)

	return (
		<section style={{ margin: '3em 0' }}>
			<SectionHeader content="Your Favorites" />
			<Divider style={{ margin: '1em 0' }} />
			{}
			<SharedTabSwitcher />

			{loadingSongs || loadingTips || loadingLessons ? (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			) : currentTab === 0 ? (
				lessons.length > 0 ? (
					<ListLessons teacherId={teacherId} spacing={1.5} data={lessons} />
				) : (
					<NoContent />
				)
			) : currentTab === 1 ? (
				songs.length > 0 ? (
					<ListSongs teacherId={teacherId} allTranspositions lessonsButton spacing={1.5} data={songs} />
				) : (
					<NoContent />
				)
			) : currentTab === 2 ? (
				tips.length > 0 ? (
					<ListVideos teacherId={teacherId} spacing={1.5} data={tips} />
				) : (
					<NoContent />
				)
			) : (
				<NoContent />
			)}
		</section>
	)
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		tabs: {
			marginBottom: '20px',
			minWidth: '60px',
			marginTop: '3em',

			'& .MuiTabs-indicator': {
				backgroundColor: '#1D273D',
				height: '3px'
			}
		},
		link: {
			color: theme.palette.secondary.dark
		}
	})
)
type TabSwitcherProps = {
	currentTab: number
	setCurrentTab: React.Dispatch<React.SetStateAction<number>>
	lessonFavTabEnabled: boolean
	songFavTabEnabled: boolean
	videoFavTabEnabled: boolean
}

const TabSwitcher = ({
	currentTab,
	setCurrentTab,
	lessonFavTabEnabled,
	songFavTabEnabled,
	videoFavTabEnabled
}: TabSwitcherProps) => {
	const classes = useStyles()
	const history = useHistory()

	const handleChangeTab = (_: React.ChangeEvent<unknown>, newValue: number) => {
		setCurrentTab(newValue)
	}

	return (
		<Box display="flex" flexWrap="wrap" alignItems="center">
			<Tabs className={classes.tabs} value={currentTab} onChange={handleChangeTab}>
				{lessonFavTabEnabled && (
					<Tab
						label={
							<Typography variant="caption" color={currentTab === 0 ? 'primary' : undefined}>
								<b>Lessons</b>
							</Typography>
						}
					/>
				)}
				{songFavTabEnabled && (
					<Tab
						label={
							<Typography variant="caption" color={currentTab === 1 ? 'primary' : undefined}>
								<b>Songs</b>
							</Typography>
						}
					/>
				)}
				{videoFavTabEnabled && (
					<Tab
						label={
							<Typography variant="caption" color={currentTab === 1 ? 'primary' : undefined}>
								<b>Videos</b>
							</Typography>
						}
					/>
				)}
			</Tabs>
			<div style={{ flexGrow: 1 }} />
			<BaseLink
				onClick={() =>
					currentTab === 0
						? history.push(buildRouteParameters(Pages.LessonsAll))
						: currentTab === 1
						? history.push(buildRouteParameters(Pages.RecommendedSongs))
						: history.push(buildRouteParameters(Pages.FeaturedVideos))
				}>
				View All
			</BaseLink>
		</Box>
	)
}
