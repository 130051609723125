import React, { Fragment, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Avatar, Box, CircularProgress, Typography } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import axios, { AxiosRequestConfig } from 'axios'

import { NoteUploadIcon } from '../assets/icons/CustomIcons'
import { getPresignedUploadUrl, PresignedUrlType } from '../scenes/Teacher/mutations'
import { buildQueryVariables, FileTypeEnum } from '../utils'
import { BaseAlert } from './BaseAlert'
import { ActionButton } from './Buttons/ActionButton'
import { UploadFileStatus } from './InputFile'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	input: {
		display: 'none'
	}
}))

const useTextStyles = makeStyles((theme) => ({
	root: {
		padding: '4px 14px 4px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		border: '1px solid rgba(0, 0, 0, .23)',
		borderRadius: '4px',
		'&:hover': {
			border: '1px solid rgba(0, 0, 0)',
			borderRadius: '4px'
		}
	},
	input: {
		padding: '10px 0',
		marginLeft: theme.spacing(1),
		flex: 1,
		alignItems: 'center',
		color: theme.palette.text.disabled
	},
	inputComplete: {
		padding: '10px 0',
		marginLeft: theme.spacing(1),
		flex: 1,
		alignItems: 'center'
	},
	cancelButton: {
		padding: 5,
		color: theme.palette.text.disabled
	},
	progressText: {
		'& .MuiAlert-message': {
			padding: 4
		},
		color: fade(theme.palette.warning.dark, 0.7),
		padding: '0 4px'
	},
	completeText: {
		'& .MuiAlert-message': {
			padding: 4
		},
		color: fade(theme.palette.success.dark, 0.7),
		padding: '0 4px'
	},
	divider: {
		height: 28,
		margin: 4
	}
}))

export type AssetReduced = {
	resource_path: string
	name: string
	asset_id?: number
}

export type AssetType = AssetReduced & {
	status: UploadFileStatus
	progress: number
	file: File
}

export type UploadFileType = {
	handleAsset?: (asset: AssetReduced[]) => void
	onAddAsset?: (asset: AssetReduced) => void
	advise: React.ReactNode
	type: FileTypeEnum
	placeholder: string
	initialAssets?: AssetReduced[]
	maxAssets?: number
	onAssetDelete?: (assetId: number) => void
	reloadInitialAssetsOnChange?: boolean
}
export const InputMultipleFile = ({
	onAddAsset = () => {},
	advise,
	type,
	placeholder,
	initialAssets,
	maxAssets,
	onAssetDelete,
	reloadInitialAssetsOnChange,
	handleAsset
}: UploadFileType) => {
	const [error, setError] = useState<string>()
	const [currentAsset, setCurrentAsset] = useState<AssetType>()
	const [assets, setAssets] = useState<AssetType[]>([])
	const classes = useStyles()
	const textClasses = useTextStyles()
	const [hasUsedInitialAssets, setHasUsedInitialAssets] = useState(false)

	const [getPresignedUrl, presignedUploadUrl] = useLazyQuery<PresignedUrlType>(getPresignedUploadUrl, {
		fetchPolicy: 'no-cache'
	})

	const uploadFile = async (file: File) => {
		if (file) {
			if (type === FileTypeEnum.Video) {
				if (!file.type.startsWith('video')) {
					setError('Please pick a valid file type')

					return
				}
				if (file.size > 200000000) {
					setError('Max File size: 200MB')

					return
				}
			}
			if (type === FileTypeEnum.Images) {
				if (!file.type.startsWith('image')) {
					setError('Please pick a valid file type')

					return
				}
			}
			if (type === FileTypeEnum.Song) {
				if (!file.type.startsWith('audio')) {
					setError('Please pick a valid file type')

					return
				}
			}
			// since the input description say MP3, MP4, WAV, PDF,  we check for those types
			if (type === FileTypeEnum.Asset) {
				if (
					!file.type.startsWith('image') &&
					!file.type.startsWith('video') &&
					!file.type.startsWith('audio') &&
					!file.type.startsWith('application/pdf')
				) {
					setError('Please pick a valid file type')

					return
				}
				if (file.size > 10000000) {
					setError('Max File size: 10MB')

					return
				}
			}
			const ext = file.name.split('.')[file.name.split('.').length - 1]
			const newAsset = {
				resource_path: '',
				name: file.name,
				progress: 0,
				status: UploadFileStatus.UPLOADING,
				file
			}
			setCurrentAsset(newAsset)
			getPresignedUrl(buildQueryVariables({ filter: { conType: file.type, ext, type, isTemporal: false } }))
		}
	}

	useEffect(() => {
		if (!presignedUploadUrl.loading && presignedUploadUrl.data && currentAsset) {
			const presigndedUrl = presignedUploadUrl.data.presignedUrl.url
			const key = presignedUploadUrl.data.presignedUrl.key
			try {
				const options: AxiosRequestConfig = {
					method: 'put',
					data: currentAsset.file,
					url: presigndedUrl,
					headers: {
						'Content-Type': currentAsset.file.type
					},
					// withCredentials: true,
					onUploadProgress: (progress: { loaded: number; total: number }) => {
						const percent = ((progress.loaded / progress.total) * 100).toFixed(2)
						setCurrentAsset({
							...currentAsset,
							progress: +percent,
							status: UploadFileStatus.UPLOADING
						})
					}
				}
				axios
					.request(options)
					.then((res) => {
						if (res?.status === 200) {
							setCurrentAsset(undefined)
							setAssets([
								...assets,
								{
									...currentAsset,
									progress: 0,
									status: UploadFileStatus.COMPLETE,
									resource_path: key
								}
							])
							onAddAsset({ name: currentAsset.name, resource_path: key })
						} else {
							setError(res.statusText)
						}
					})
					.catch((e) => {
						if (typeof e !== 'string') setError(JSON.stringify(e))
						else setError(e)
					})
			} catch (err) {
				if (typeof err !== 'string') setError(JSON.stringify(err))
				else setError(err)
			}
		}
	}, [presignedUploadUrl])

	useEffect(() => {
		if (handleAsset)
			handleAsset(
				assets.map((asset) => ({
					name: asset.name,
					resource_path: asset.resource_path,
					asset_id: asset.asset_id
				}))
			)
	}, [assets])

	useEffect(() => {
		if (initialAssets && (!hasUsedInitialAssets || reloadInitialAssetsOnChange)) {
			const initialToSet = initialAssets.map(
				(asset) =>
					({
						...asset,
						status: UploadFileStatus.COMPLETE,
						progress: 100,
						file: new File([], asset.name)
					} as AssetType)
			)
			setHasUsedInitialAssets(true)
			setAssets(initialToSet)
		}
	}, [initialAssets])

	const handleClose = () => {
		setError(undefined)
	}
	const getFileType = () => {
		switch (type) {
			case FileTypeEnum.Video:
			case FileTypeEnum.Wurrly:
				return 'video/*'
			case FileTypeEnum.Images:
				return 'image/*'
			case FileTypeEnum.Song:
				return 'audio/*'
			case FileTypeEnum.Asset:
				return 'application/pdf, image/*, audio/*'
		}
	}

	const getPreviewIcon = (asset: AssetType, index: number) => {
		if (asset.status === UploadFileStatus.UPLOADING) {
			return <CircularProgress color="secondary" size={30} />
		} else {
			switch (type) {
				case FileTypeEnum.Video:
				case FileTypeEnum.Wurrly:
					return <NoteUploadIcon />
				case FileTypeEnum.Images:
					return <Avatar variant="rounded" src={URL.createObjectURL(asset.file)} />
				case FileTypeEnum.Song:
					return <NoteUploadIcon />
				case FileTypeEnum.Asset:
					return (
						<ActionButton
							onClick={() => {
								const auxAssets = [...assets]
								const removedAssetId = auxAssets.splice(index, 1)[0]?.asset_id
								if (onAssetDelete && removedAssetId) onAssetDelete(removedAssetId)
								setAssets(auxAssets)
							}}
							color="secondary"
							icon={<DeleteIcon />}
						/>
					)
			}
		}
	}

	return (
		<Fragment>
			{assets.length < 1 ? (
				<Fragment>
					{currentAsset ? (
						<Box component="form" className={textClasses.root}>
							<Box
								className={
									currentAsset.status === UploadFileStatus.UPLOADING
										? textClasses.input
										: textClasses.inputComplete
								}
								display="flex">
								<Typography color="secondary">{getPreviewIcon(currentAsset, 0)}</Typography>
								<Typography style={{ marginLeft: '10px' }}>{currentAsset.name}</Typography>
							</Box>
							{currentAsset.status === UploadFileStatus.UPLOADING && (
								<Alert className={textClasses.progressText} severity="error" icon={false}>
									<Typography variant="caption">
										<b>{currentAsset.progress}% UPLOADED</b>
									</Typography>
								</Alert>
							)}
							{currentAsset.status === UploadFileStatus.COMPLETE && (
								<Fragment>
									<Alert className={textClasses.completeText} severity="success" icon={false}>
										<Typography variant="caption">
											<b>COMPLETE</b>
										</Typography>
									</Alert>
									<AddIcon style={{ marginLeft: 5 }} color="secondary" />
								</Fragment>
							)}
						</Box>
					) : (
						<Box>
							<input
								accept={getFileType()}
								className={classes.input}
								id="contained-button-file"
								type="file"
								onChange={(e) => {
									if (e.target.files) {
										uploadFile(e.target.files[0])
									}
								}}
							/>
							<label htmlFor="contained-button-file">
								<Box component="form" className={textClasses.root} style={{ cursor: 'pointer' }}>
									<Typography className={textClasses.input}>{placeholder}</Typography>
									<AddIcon color="secondary" />
								</Box>
							</label>
						</Box>
					)}
				</Fragment>
			) : (
				<Fragment>
					{currentAsset && (
						<Box component="form" className={textClasses.root}>
							<Box
								className={
									currentAsset.status === UploadFileStatus.UPLOADING
										? textClasses.input
										: textClasses.inputComplete
								}
								display="flex">
								<Typography color="secondary">{getPreviewIcon(currentAsset, 0)}</Typography>
								<Typography style={{ marginLeft: '10px' }}>{currentAsset.name}</Typography>
							</Box>
							{currentAsset.status === UploadFileStatus.UPLOADING && (
								<Alert className={textClasses.progressText} severity="error" icon={false}>
									<Typography variant="caption">
										<b>{currentAsset.progress}% UPLOADED</b>
									</Typography>
								</Alert>
							)}
							{currentAsset.status === UploadFileStatus.COMPLETE && (
								<Fragment>
									<Alert className={textClasses.completeText} severity="success" icon={false}>
										<Typography variant="caption">
											<b>COMPLETE</b>
										</Typography>
									</Alert>
									<AddIcon style={{ marginLeft: 5 }} color="secondary" />
								</Fragment>
							)}
						</Box>
					)}
					{assets.map((asset, index) => {
						if (index === 0) {
							return (
								<Box>
									<input
										accept={getFileType()}
										className={classes.input}
										id="contained-button-file"
										type="file"
										disabled={maxAssets ? assets.length >= maxAssets : false}
										onChange={(e) => {
											if (e.target.files) {
												uploadFile(e.target.files[0])
											}
										}}
									/>
									<Box component="form" className={textClasses.root}>
										<Box
											className={
												asset.status === UploadFileStatus.UPLOADING
													? textClasses.input
													: textClasses.inputComplete
											}
											display="flex">
											<Typography color="secondary">
												{getPreviewIcon(asset, index)}
											</Typography>
											<Typography style={{ marginLeft: '10px' }}>{asset.name}</Typography>
										</Box>
										{asset.status === UploadFileStatus.UPLOADING && (
											<Alert
												className={textClasses.progressText}
												severity="error"
												icon={false}>
												<Typography variant="caption">
													<b>{asset.progress}% UPLOADED</b>
												</Typography>
											</Alert>
										)}
										{maxAssets && assets?.length >= maxAssets ? null : (
											<label htmlFor="contained-button-file">
												<AddIcon color="secondary" />
											</label>
										)}
									</Box>
								</Box>
							)
						} else {
							return (
								<Box component="form" className={textClasses.root}>
									<Box
										className={
											asset.status === UploadFileStatus.UPLOADING
												? textClasses.input
												: textClasses.inputComplete
										}
										display="flex">
										<Typography color="secondary">{getPreviewIcon(asset, index)}</Typography>
										<Typography style={{ marginLeft: '10px' }}>{asset.name}</Typography>
									</Box>
									{asset.status === UploadFileStatus.UPLOADING && (
										<Alert className={textClasses.progressText} severity="error" icon={false}>
											<Typography variant="caption">
												<b>{asset.progress}% UPLOADED</b>
											</Typography>
										</Alert>
									)}
								</Box>
							)
						}
					})}
				</Fragment>
			)}

			<Box marginTop="10px">
				<BaseAlert severity="error" show={!!error} handleClose={handleClose}>
					{error}
				</BaseAlert>
			</Box>
			<Box textAlign="end">
				<Typography variant="caption" className={textClasses.input}>
					{advise}
				</Typography>
			</Box>
		</Fragment>
	)
}
