import { Box, Card, CardActionArea, CardContent, CardProps, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import clsx from 'clsx'
import { Class, Maturity_Level_Enum } from 'src/graphql/autogenerate/schemas'
import { DEFAULT_MUSIC_BG, buildImagePath } from 'src/utils'

import useStyles from './ClassCardDialog.styles'

type ClassData = Pick<Class, 'image_path' | 'title' | 'maturity_level' | 'class_id' | '__typename'>
export type ClassCardProps = CardProps & {
	selected?: boolean
	saved?: boolean
	classData: ClassData
	songMaturity?: Maturity_Level_Enum
	selectable?: boolean
}
export const ClassCardDialog = ({
	selected,
	saved,
	songMaturity,
	classData,
	selectable,
	...props
}: ClassCardProps) => {
	const styles = useStyles({
		imagePath: classData.image_path ? buildImagePath(classData.image_path) : buildImagePath(DEFAULT_MUSIC_BG)
	})

	const isMaturityGeneral = classData.maturity_level === Maturity_Level_Enum.General
	const isSongMature = songMaturity === Maturity_Level_Enum.Mature

	return (
		<Card
			variant={selected ? 'outlined' : 'elevation'}
			elevation={saved || (isSongMature && isMaturityGeneral) ? 0 : 3}
			color="secondary"
			{...props}
			className={clsx(styles.card, props.className)}>
			<CardActionArea
				className={clsx({
					[styles.saved]: saved || (isSongMature && isMaturityGeneral),
					[styles.selected]: selected && !saved,
					[styles.nonSelected]: !selected && !saved,
					[styles.cardActionArea]: true
				})}>
				<CardContent>
					<Box className={styles.title}>
						<Typography gutterBottom variant="body2">
							<b>{classData.title}</b>
						</Typography>
					</Box>
					{saved || (isSongMature && isMaturityGeneral) ? (
						<Box className={styles.maturity}>
							<Typography className={styles.maturityText} variant="caption">
								<b>{isMaturityGeneral ? 'GENERAL CLASS' : 'ADDED'}</b>
							</Typography>
							<Typography variant="subtitle2">
								{saved && <CheckCircleIcon fontSize="inherit" />}
							</Typography>
						</Box>
					) : (
						<Box className={styles.selectIcon}>
							{!selectable ? (
								<></>
							) : selected ? (
								<CheckCircleIcon fontSize="small" color="secondary" />
							) : (
								<RadioButtonUncheckedOutlinedIcon fontSize="small" color="disabled" />
							)}
						</Box>
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	)
}
