import { Box, Button, CircularProgress, List, Paper } from '@material-ui/core'
import { HeaderList } from 'src/scenes/Teacher/scenes/BillingDetails/components/HeaderList/HeaderList'
import { ItemList } from 'src/scenes/Teacher/scenes/BillingDetails/components/ItemList/ItemList'
import { Title } from 'src/scenes/Teacher/scenes/BillingDetails/components/Title'
import { BillingItem } from 'src/scenes/Teacher/scenes/BillingDetails/useBillingDetails'

import useStyles from './PaymentsList.styles'
type PaymentsListProps = {
	data: BillingItem[]
	isFetching: boolean
	moreResults: boolean
	loading: boolean
}

export const PaymentsList = ({ data, isFetching, moreResults, loading }: PaymentsListProps) => {
	const styles = useStyles()

	return (
		<>
			<Title className={styles.title} title="Payments" />
			<Paper>
				<List className={styles.list}>
					<HeaderList />
					{data.map((item) => {
						return <ItemList data={item} key={item.orderId} />
					})}
					{isFetching && moreResults && (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{!loading && data.length > 0 && !moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)}
				</List>
			</Paper>
		</>
	)
}
