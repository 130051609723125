import { Box } from '@material-ui/core'
import { PagesProps } from 'src/@types'

import { ClassStudentWurrliesDetailsFormConfig } from './components/StudentWurrliesDetailsFormConfig'
import { ClassStudentWurrliesDetailsTitle } from './components/StudentWurrliesDetailsTitle'
import { ClasssStudentWurrliesDetailsProvider } from './useClassStudentWurrliesDetails'

export const ClassStudentWurrliesDetails = (props: PagesProps) => {
	return (
		<Box>
			<ClasssStudentWurrliesDetailsProvider>
				<ClassStudentWurrliesDetailsTitle {...props} />
				<ClassStudentWurrliesDetailsFormConfig />
			</ClasssStudentWurrliesDetailsProvider>
		</Box>
	)
}
