import { createStyles, makeStyles } from '@material-ui/core'

export const useListItemStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			'&:not(:last-child)': {
				marginBottom: theme.spacing(1)
			}
		},
		info: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: theme.spacing(1.5)
		}
	})
)

export default useListItemStyles
