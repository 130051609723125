import React from 'react'

import { Box, ButtonProps, ListItemText, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CallMadeIcon from '@material-ui/icons/CallMade'
import DeleteIcon from '@material-ui/icons/Delete'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import { useHistory } from 'react-router-dom'
import { NumStr } from 'src/@types/types'
import { CartIcon, LockIcon } from 'src/assets/icons/CustomIcons'
import { ActionButton, BaseButton } from 'src/components'
import { Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildImagePath, buildRouteParameters, getCyKey } from 'src/utils'

import useStyles from './ListItemPlaylist.styles'

export type ListItemPlaylistProps = {
	id: number
	imageUrl: string
	title: string
	description: string
	genres: string[]
	totalvideos: number
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	btnAddClass?: { selected?: boolean } & ButtonProps
	btnLearnMore?: ButtonProps
	btnRemove?: ButtonProps
	btnView?: ButtonProps
	btnSuscription?: ButtonProps
	width?: NumStr
	subscription?: boolean
	visibility?: string
}

export const ListItemPlaylist = ({
	id,
	imageUrl,
	title,
	description,
	genres,
	totalvideos,
	btnAddClass,
	btnLearnMore,
	btnRemove,
	btnView,
	btnSuscription,
	onClick,
	subscription,
	visibility,
	width = '100%'
}: ListItemPlaylistProps) => {
	const history = useHistory()
	const styles = useStyles({ imageUrl: buildImagePath(imageUrl) })
	const goToPlaylist = () => {
		history.push(
			buildRouteParameters(
				Pages.PlaylistDetails, // Route with paramters
				{
					playlistId: id
				}
			)
		)
	}

	return (
		<Box
			data-cy={getCyKey(ListItemPlaylist, 'allPlaylistBox')}
			width={width}
			onClick={onClick}
			className={styles.listItem}>
			<Box className={styles.avatarImage}>
				<Box className={styles.avatarMain}>
					<Tooltip title={title}>
						<Typography
							className={styles.avatarTitle}
							data-cy={getCyKey(ListItemPlaylist, 'nameItemPlaylist')}
							variant="h5">
							{title}
						</Typography>
					</Tooltip>
				</Box>
				<Box className={styles.bottomBar}>
					<PlaylistPlayIcon /> <Typography>{totalvideos} Videos</Typography>
				</Box>
			</Box>
			<Box className={styles.content}>
				<ListItemText
					className={styles.description}
					primary={
						<Box>
							<Typography>
								<strong>{description}</strong>
							</Typography>
						</Box>
					}
					secondary={genres.length ? '• ' + genres.join(', ') : ''}
				/>
				{!subscription && visibility === Visibility_Enum.ForSale && (
					<Tooltip title="You don't own this product">
						<BaseButton
							color="primary"
							startIcon={<LockIcon />}
							className={styles.lockedButton}
							onClick={(e) => {
								e.stopPropagation()
							}}>
							<Typography className={styles.locked}>Locked</Typography>
						</BaseButton>
					</Tooltip>
				)}
				<Box display="flex" className="boxActions" justifyContent="flex-end" width="100%">
					{btnRemove && (
						<Tooltip title="Remove">
							<ActionButton
								data-cy={getCyKey(ListItemPlaylist, 'btnRemove')}
								icon={<DeleteIcon fontSize="small" />}
								{...btnRemove}
							/>
						</Tooltip>
					)}
					{btnLearnMore && (
						<BaseButton
							data-cy={getCyKey(ListItemPlaylist, 'btnLearnMore')}
							color="default"
							className={'addClass'}
							endIcon={<CallMadeIcon className={styles.tiltedIcon} />}
							onClick={goToPlaylist}
							{...btnLearnMore}>
							<Typography>Learn More</Typography>
						</BaseButton>
					)}
					{btnAddClass &&
						(visibility !== Visibility_Enum.ForSale ||
							(visibility === Visibility_Enum.ForSale && subscription)) && (
							<BaseButton
								color="primary"
								className="addClass"
								endIcon={<AddIcon />}
								{...btnAddClass}>
								<Typography>Add to class</Typography>
							</BaseButton>
						)}
					{btnSuscription && !subscription && visibility === Visibility_Enum.ForSale && (
						<Tooltip title="Subscribe to Playlist">
							<BaseButton
								className={'subscription'}
								startIcon={<CartIcon style={{ color: '#F3AB34' }} />}
								{...btnSuscription}>
								<Typography className={styles.subscribe}>Subscribe to Playlist</Typography>
							</BaseButton>
						</Tooltip>
					)}
					{btnView && (
						<Tooltip title="View">
							<BaseButton
								data-cy={getCyKey(ListItemPlaylist, 'btnViewPlaylist')}
								color="primary"
								className={'addClass'}
								endIcon={<ArrowForwardIcon />}
								{...btnView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
					)}
				</Box>
			</Box>
		</Box>
	)
}
