import React, { Fragment, useEffect, useState } from 'react'

import { Box, Link, Typography } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { Controller, useForm } from 'react-hook-form'
import { ThinCheckIcon } from 'src/assets/icons'
import {
	BaseDialog,
	InfoDialog,
	InviteStudentsOneByOneDialog,
	TextInput,
	IconActionOptionType
} from 'src/components'
import { useAddStudentsSendParentConsentEmailsMutation } from 'src/graphql/autogenerate/hooks'
import { StudentsUnder12Data } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { validateEmail, getCyKey } from 'src/utils'

import { ListDialog } from './ListDialog'

enum AddStudentsOptsEnum {
	lowerEqTwelve = 'lowerEqTwelve',
	greaterEqThirteen = 'greaterEqThirteen'
}
type InviteStudentsOneByOnePrivateCampDialogType = {
	classId: number
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	setIsManualInviteOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const InviteStudentsOneByOnePrivateCampDialog = ({
	classId,
	isOpen,
	setIsOpen
}: InviteStudentsOneByOnePrivateCampDialogType) => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [isParentalConsentOpen, setIsParentalConsentOpen] = useState(false)
	const [isStudentUnder12Open, setIsStudentUnder12Open] = useState(false)
	const [isParentConsentSentOpen, setIsParentConsentSentOpen] = useState(false)
	const [isInviteStudentsOneByOneOpen, setIsInviteStudentsOneByOneOpen] = useState(false)
	const { control, watch, reset, getValues } = useForm()
	const addStudentsOpts: IconActionOptionType[] = [
		{
			id: AddStudentsOptsEnum.lowerEqTwelve,
			name: (
				<Box>
					<Typography style={{ fontSize: 26 }}>
						<b>12</b>
					</Typography>
					<Typography style={{ fontSize: 12 }}>
						<b>or younger</b>
					</Typography>
				</Box>
			),
			icon: <PersonIcon />
		},
		{
			id: AddStudentsOptsEnum.greaterEqThirteen,
			name: (
				<Box>
					<Typography style={{ fontSize: 26 }}>
						<b>13</b>
					</Typography>
					<Typography style={{ fontSize: 12 }}>
						<b>or older</b>
					</Typography>
				</Box>
			),
			icon: <PersonIcon />
		}
	]

	const [
		addStudentsSendParentConsentEmailsMutation,
		{
			data: studentsSendParentConsentEmailsData,
			loading: studentsSendParentConsentEmailsLoading
			// error: StudentsSendParentConsentEmailsError
		}
	] = useAddStudentsSendParentConsentEmailsMutation()

	useEffect(() => {
		if (studentsSendParentConsentEmailsData) {
			setIsStudentUnder12Open(false)
			setIsParentConsentSentOpen(true)
		}
	}, [studentsSendParentConsentEmailsData])

	const cleanUpStates = () => {
		reset()
	}

	return (
		<Box>
			<ListDialog
				open={isOpen}
				cols={2}
				onClose={() => setIsOpen(false)}
				title="Add A Student"
				description="What is the age of the Student that you'll be inviting?"
				discardLabel="Cancel"
				onDiscard={() => setIsOpen(false)}
				onDiscardProps={{
					style: {
						width: '100%'
					}
				}}
				options={addStudentsOpts}
				handleSelectOption={(opt: string) => {
					switch (opt) {
						case AddStudentsOptsEnum.lowerEqTwelve:
							setIsParentalConsentOpen(true)
							setIsOpen(false)
							break
						case AddStudentsOptsEnum.greaterEqThirteen:
							setIsInviteStudentsOneByOneOpen(true)
							setIsOpen(false)
							break
						default:
							break
					}
					setIsOpen(false)
				}}
				cardHeight={160}
				cardWidth={235}
			/>
			<BaseDialog
				dividers={false}
				open={isParentalConsentOpen}
				onClose={() => {}}
				header={
					<Typography
						data-cy={getCyKey(InviteStudentsOneByOneDialog, 'Title')}
						variant="h4"
						align="center"
						style={{ fontWeight: 'bold' }}>
						A Few Thing for your Students 12 and under
					</Typography>
				}
				body={
					<Box mt={3} display="flex" flexDirection="column">
						<Typography variant="h6" align="center">
							Privacy is important to us, so we want to ensure that we have the necessary permission
							to collect, store and share a user's personal information in accordance with our{' '}
							<Link color="secondary" target="_blank" href="#">
								<b>Privacy Policy.</b>
							</Link>{' '}
							The {<b>Child Online Privary and Protection Act</b>} requires verified parental consent
							for any use by children under the age of 13 years.
						</Typography>
						<br />
						<Typography variant="h6" align="center">
							As such, for children under the age of 13, we will ask you for their parent's email so
							we can send a unique link to verify their consent. If we do not receive parental
							consent within 14 days, we will delete all personal information from our records.
						</Typography>
					</Box>
				}
				discardLabel="Go Back"
				onDiscard={(e) => {
					e.stopPropagation()
					setIsParentalConsentOpen(false)
					setIsOpen(true)
				}}
				onDiscardProps={{
					style: {
						display: 'flex',
						flexGrow: 1,
						marginRight: '2%'
					}
				}}
				confirmLabel="I Understand and Accept"
				onConfirm={() => {
					setIsParentalConsentOpen(false)
					setIsStudentUnder12Open(true)
				}}
			/>
			<BaseDialog
				dividers={false}
				open={isStudentUnder12Open}
				onClose={() => {}}
				header={
					<Fragment>
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Invite A Student Under 12
						</Typography>
					</Fragment>
				}
				body={
					<Box style={{ width: 480 }}>
						<Controller
							name="studentName"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextInput
									data-cy={getCyKey(InviteStudentsOneByOnePrivateCampDialog, 'StudentNameInput')}
									id="Student Name"
									name="Student Name"
									value={value}
									onChange={(value) => onChange(value)}
								/>
							)}
						/>
						<Controller
							name="parentEmail"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextInput
									data-cy={getCyKey(InviteStudentsOneByOnePrivateCampDialog, 'ParentEmailInput')}
									id="Parent Email"
									name="Parent Email"
									value={value}
									onChange={(value) => onChange(value)}
									error={!!value && !validateEmail(value)}
									helperText={
										!!value && !validateEmail(value) && <Typography>Invalid Email</Typography>
									}
								/>
							)}
						/>
					</Box>
				}
				isConfirmDisabled={
					!(watch().studentName && watch().parentEmail && validateEmail(watch().parentEmail)) ||
					studentsSendParentConsentEmailsLoading
				}
				confirmLabel="Send Consent"
				onConfirmProps={{
					style: {
						width: 200
					},
					type: 'submit'
				}}
				onConfirm={(e) => {
					e.stopPropagation()
					const { studentName, parentEmail } = getValues()
					const [studentFirstName, studentLastName] = studentName.split(' ')
					const students = [
						{
							first_name: studentFirstName || '',
							last_name: studentLastName || '',
							parentEmail
						} as StudentsUnder12Data
					]
					addStudentsSendParentConsentEmailsMutation({
						variables: {
							set: {
								classId,
								teacherId,
								students
							}
						}
					})
				}}
				discardLabel="Go Back"
				onDiscardProps={{
					style: {
						width: 200
					}
				}}
				onDiscard={(e) => {
					e.stopPropagation()
					cleanUpStates()
					setIsStudentUnder12Open(false)
					setIsParentalConsentOpen(true)
				}}
			/>
			<InfoDialog
				open={isParentConsentSentOpen}
				onClose={() => {}}
				discardLabel="Add More Students"
				onDiscard={() => {
					setIsParentConsentSentOpen(false)
					cleanUpStates()
					setIsOpen(true)
				}}
				confirmLabel="View Students"
				onConfirm={() => {
					setIsParentConsentSentOpen(false)
					cleanUpStates()
				}}
				icon={<ThinCheckIcon />}
				title="Parent Consent Sent!"
				body="You have successfully sent the consent."
				bodyProps={{ style: { marginBottom: '15px' } }}
			/>
			<InviteStudentsOneByOneDialog
				classId={classId}
				isOpen={isInviteStudentsOneByOneOpen}
				setIsOpen={setIsInviteStudentsOneByOneOpen}
				setIsPreviousDialogOpen={setIsOpen}
			/>
		</Box>
	)
}
