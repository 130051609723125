import { Fragment } from 'react'

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography
} from '@material-ui/core'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { BreadcrumbsArrayType, Page } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons'
import { ListVideos, MainTitle, SelectSort } from 'src/components'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { useCatalogFilters } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useCatalogFilters'
import { useCatalogVideos } from 'src/scenes/Teacher/scenes/8-Videos/scenes/CatalogVideos/useCatalogVideos'
import { getCyKey, TypeEnum } from 'src/utils'

import { useStyles } from './CatalogVideosBody.styles'

export type VideosCatalogBodyProps = {
	breadcrumbs: BreadcrumbsArrayType
	catalogItemId?: number
	catalogId?: number
	title: string
	teacherId: number
	catalogName?: string
	page?: Page
}

export const CatalogVideosBody = ({
	breadcrumbs,
	teacherId,
	title,
	catalogId,
	catalogItemId,
	catalogName,
	page
}: VideosCatalogBodyProps) => {
	const styles = useStyles()

	const { filters, handleCheck, selectedFilters } = useCatalogFilters({
		catalog_id: catalogId,
		catalogItemId,
		product: TypeEnum.Video
	})

	const {
		isFetching,
		moreResults,
		loading,
		count,
		onSearchEnter,
		onSearch,
		getSearchVideosData,
		videos: videosFiltered,
		sort,
		handleSort,
		searchBarTextState: searchbarText
	} = useCatalogVideos({
		page,
		teacherId,
		catalogId,
		catalogItemId,
		featured: false,
		catalogFilters: selectedFilters
	})

	const availableFilters = filters.filter((filter) =>
		catalogItemId ? filter?.name?.toLowerCase() !== catalogName?.toLowerCase() : true
	)

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={title}
				description="Short videos that provide real, helpful advice from Grammy award winning artists, and activities by industry professionals."
				rightActions={[
					{
						variant: 'outlined',
						className: styles.actionButton,
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.VideoUpload.path
					}
				]}
			/>

			<SearchBar
				placeholder="Find Video by Title, Subject or Keyword"
				onChange={(e) => onSearch(e.target.value)}
				onEnterPressed={onSearchEnter}
				initialValue={searchbarText}
				loading={getSearchVideosData.loading}
				dropdownItems={getSearchVideosData.data?.tip.map((el, key) => {
					return (
						<Box key={key}>
							<ListVideos
								returnItemAlone
								listItem={el as Tip}
								listItemNumber={key}
								teacherId={teacherId}
								spacing={1.5}
								data={[]}
								copyLinkButton
							/>
						</Box>
					)
				})}
			/>

			<Grid style={{ marginTop: '40px' }} container spacing={3}>
				<Grid item xs={12} md={9}>
					<Box display="flex" alignItems="center">
						<Typography variant="h6">
							<b>Videos ({count})</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						{count !== 0 && (
							<SelectSort
								cyKey="Tips"
								value={sort}
								onChange={(e) => handleSort(e.target.value as string)}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					{isFetching || !loading ? (
						<ListVideos teacherId={teacherId} spacing={1.5} data={videosFiltered} copyLinkButton />
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{isFetching && moreResults && (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
					{!loading && videosFiltered.length > 0 && !moreResults && (
						<Box mt={4} textAlign="center">
							<Button
								onClick={() => {
									window.scrollTo(0, 0)
								}}
								variant="text"
								color="secondary">
								Back to Top
							</Button>
						</Box>
					)}
					{!loading && videosFiltered.length <= 0 && (
						<div>
							<Divider />
							<Box my={4}>
								{searchbarText ? (
									<Fragment>
										<Typography variant="h6" color="textSecondary">
											Your search for "{searchbarText}" did not return any results.
										</Typography>
										<Typography
											variant="subtitle2"
											color="textSecondary"
											data-cy={getCyKey(CatalogVideosBody, `NoResultsMessage`)}>
											You can always check out our Featured Videos below.
										</Typography>
									</Fragment>
								) : (
									<Fragment>
										<Typography variant="h6" color="textSecondary">
											No results found
										</Typography>
										<Typography color="textSecondary">
											Your filter selection did not return any results
										</Typography>
										<Typography color="textSecondary">
											Try removing a filter or upload own by clicking the button top right
										</Typography>
									</Fragment>
								)}
							</Box>
							<Divider />
						</div>
					)}
				</Grid>

				<Grid item xs={12} md>
					<Paper>
						<Box py={2} px={3}>
							<Typography
								variant="h6"
								style={{
									fontSize: 18
								}}>
								<b>Filters</b>
							</Typography>
						</Box>
						<Divider />
						<Box py={1} px={3}>
							{availableFilters.map((filter, indexFilter) => (
								<List key={`filter-${indexFilter}`} dense>
									<Typography
										variant="h6"
										style={{
											fontSize: 18
										}}>
										<b>{filter.name}</b>
									</Typography>
									{filter.items.map((item, indexItem) => (
										<ListItem
											data-cy={getCyKey(CatalogVideosBody, `${filter.name} ${item.label}`)}
											key={item.id}
											button
											disableGutters
											onClick={() => handleCheck(indexFilter, indexItem)}>
											<ListItemIcon
												style={{
													minWidth: 'auto'
												}}>
												<Checkbox
													edge="start"
													checked={selectedFilters.includes(item.id)}
													checkedIcon={<CheckBoxOutlinedIcon />}
													tabIndex={-1}
													disableRipple
													inputProps={{
														'aria-labelledby': item.label
													}}
												/>
											</ListItemIcon>
											<ListItemText id={item.id.toString()} primary={item.label} />
										</ListItem>
									))}
								</List>
							))}
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}
