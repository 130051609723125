import { NotesIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { RecoverPassswordDialog } from 'src/scenes/Login/components/RecoverPasswordDialog'

export const RecoverPassword = () => {
	return (
		<FixedBackground>
			<img
				src={NotesIcon}
				style={{ position: 'fixed', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}
			/>
			<RecoverPassswordDialog />
		</FixedBackground>
	)
}
