import { createStyles, makeStyles } from '@material-ui/core'

export const useAccountActivationDialogStyles = makeStyles(() =>
	createStyles({
		title: {
			paddingTop: '10px',
			fontSize: '1.3rem'
		},
		paper: {
			maxHeight: 'calc(100% - 100px)',
			width: '100%',
			maxWidth: '550px'
		},
		wurrlyIcon: {
			height: '3rem'
		},
		confirmButton: {
			maxWidth: '10rem'
		},
		base: {
			'& .MuiDialogActions-root': {
				justifyContent: 'center'
			}
		}
	})
)

export default useAccountActivationDialogStyles
