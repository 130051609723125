import { createStyles, makeStyles } from '@material-ui/core'

export const useEmptyCardStyles = makeStyles(() =>
	createStyles({
		emptyCard: {
			backgroundColor: '#ffffff',
			borderRadius: 4,
			boxShadow: '1px 2px 2px rgb(0 0 0 / 8%)',
			padding: 25,
			border: '1px solid rgba(0, 0, 0, 8%)'
		}
	})
)

export default useEmptyCardStyles
