import { createStyles, makeStyles, Theme } from '@material-ui/core'

type UploadSongSliderProps = {
	leftOffset: number
	rows: number
	rowHeight: number
}
export const useUploadSongSliderStyles = makeStyles<Theme, UploadSongSliderProps>((theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'flex'
		},
		timeIndicator: {
			position: 'absolute',
			width: '1px',
			background: theme.palette.secondary.main,
			top: '50px',
			bottom: 0,
			left: ({ leftOffset }) => leftOffset,
			zIndex: 2,
			'&::before': {
				position: 'absolute',
				content: `" "`,
				top: -10,
				width: '6px',
				height: '10px',
				background: theme.palette.secondary.main,
				transform: 'translateX(-50%)'
			}
		},
		timeIcon: { height: 41 },
		time: {
			height: 50,
			overflowY: 'hidden'
		},
		waveform: {
			backgroundColor: 'white'
		},
		rowsContainer: {
			overflowX: 'scroll'
		},
		iconRow: {
			height: ({ rowHeight }) => rowHeight
		},
		row: {
			height: ({ rowHeight }) => rowHeight,
			cursor: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346622 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM12.1151 8.80684H8.80793V12.1146H7.19207V8.80793H3.88486V7.19207H7.19262V3.88485H8.80793V7.19262H12.1157L12.1151 8.80684Z' fill='black'/%3E%3C/svg%3E%0A") 8 8, pointer`
		},
		icon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			minWidth: 80
		},
		content: {
			backgroundColor: '#DDDDDD',
			display: 'flex',
			alignItems: 'center'
		},
		divider: {
			backgroundColor: '#353550',
			opacity: 0.4
		},
		slider: {
			position: 'absolute',
			top: 70,
			width: 4,
			left: ({ leftOffset }: UploadSongSliderProps) => leftOffset + 80,
			zIndex: 5,
			'& .MuiSlider-thumb::after': {
				width: 2,
				height: ({ rows }: UploadSongSliderProps) => rows * 100,
				background: '#F2BE20',
				borderRadius: '2px',
				transform: 'matrix(1, 0, 0, -1, 0, 0)',
				top: '0px',
				left: 3,
				right: '0px',
				bottom: '0px'
			},
			'& .MuiSlider-track': {
				backgroundColor: 'transparent'
			},
			'& .MuiSlider-rail': {
				backgroundColor: 'transparent'
			},
			'& .Mui-disabled': {
				color: '#F2BE20',
				borderRadius: 2,
				height: 15
			}
		},
		scrollingContainer: { display: 'flex', flexDirection: 'column', overflowX: 'scroll' },
		waveformIcon: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-end',
			height: '100%'
		},
		rowsInnerContainer: {
			border: '1px solid #DDDDDD',
			position: 'relative'
		},
		contentItem: {
			position: 'absolute',
			zIndex: 2
		},
		contentRow: {
			width: '100%'
		}
	})
)

export default useUploadSongSliderStyles
