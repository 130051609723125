import { useEffect, useState } from 'react'

import { CircularProgress } from '@material-ui/core'
import { ListDialog } from 'src/components'
import { InfoDialog } from 'src/components/Dialogs/InfoDialog'
import { useIntegrationContext } from 'src/hooks/useIntegration'
import { ClassLinkClassRoasterDialog } from 'src/scenes/Teacher/scenes/1-Home/components/ClassLinkClassRoasterDialog'
import { CleverClassRoasterDialog } from 'src/scenes/Teacher/scenes/1-Home/components/CleverClassRoasterDialog'
import { INTEGRATIONS_OPTS } from 'src/utils'

import { IntegrationOptsType } from '../../../../../utils/enums'

type SelectIntegrationCardProps = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const SyncClassDialog = ({ open, setOpen }: SelectIntegrationCardProps) => {
	const { cleverInstance, classLinkInstance } = useIntegrationContext()
	const [selectIntegrationOpen, setSelectIntegrationOpen] = useState(false)
	const [classesDialogOpen, setClassesDialogOpen] = useState(false)
	const [cleverErrorOpen, setCleverErrorOpen] = useState(false)
	const [loadingCleverLogin, setLoadingCleverLogin] = useState(false)
	const cleverAccount = cleverInstance?.account

	const integrationOptions = INTEGRATIONS_OPTS.map((i) => {
		const availableIntegrationOptions = [IntegrationOptsType.clever, IntegrationOptsType.classLink]
		const isOptionAvailable = availableIntegrationOptions.includes(i.id as IntegrationOptsType)

		if (!isOptionAvailable) {
			return { ...i, disabled: true }
		}

		if (loadingCleverLogin && i.id === IntegrationOptsType.clever)
			return {
				...i,
				disabled: true,
				noTooltip: true,
				name: (
					<>
						{i.name} <CircularProgress color="secondary" size={15} />
					</>
				)
			}

		return i
	})

	const cleverLogin = () => {
		cleverInstance.openPopup()
	}

	useEffect(() => {
		cleverInstance.clearToken()
	}, [])

	const classlinkLogin = () => {
		classLinkInstance.openPopup()
	}

	useEffect(() => {
		if (!open) {
			setSelectIntegrationOpen(false)
			setClassesDialogOpen(false)
			setCleverErrorOpen(false)
			cleverInstance.clearAccount()
		} else {
			setSelectIntegrationOpen(true)
		}
	}, [open])

	useEffect(() => {
		if (cleverAccount?.token && selectIntegrationOpen) {
			setClassesDialogOpen(true)
			setSelectIntegrationOpen(false)
		}
	}, [cleverAccount?.token, selectIntegrationOpen])

	useEffect(() => {
		if (classLinkInstance.data?.token && selectIntegrationOpen) {
			setClassesDialogOpen(true)
			setSelectIntegrationOpen(false)
		}
	}, [classLinkInstance?.data?.token, selectIntegrationOpen])

	useEffect(() => {
		if (cleverInstance.error) setCleverErrorOpen(true)
	}, [cleverInstance.error])

	useEffect(() => {
		let loading = false
		if (
			cleverInstance.queriesStates.cleverAccountLoading ||
			cleverInstance.queriesStates.loadingCleverTokenData ||
			cleverInstance.queriesStates.loadingCourses ||
			cleverInstance.queriesStates.loadingStudents ||
			cleverInstance.queriesStates.loadingTeacherIntegration
		)
			loading = true
		setLoadingCleverLogin(loading)
	}, [cleverInstance.queriesStates])

	return (
		<>
			<ListDialog
				open={selectIntegrationOpen}
				cols={1}
				onClose={() => setOpen(false)}
				title="Sync Class"
				description="Please select the app that you'd like to sync your Class from."
				discardLabel="Cancel"
				onDiscard={() => setOpen(false)}
				onDiscardProps={{
					style: {
						width: '100%'
					}
				}}
				disableHover
				optionSelected={''}
				options={integrationOptions}
				handleSelectOption={(opt: string) => {
					switch (opt) {
						case IntegrationOptsType.google:
							break
						case IntegrationOptsType.teams:
							break
						case IntegrationOptsType.clever:
							if (!loadingCleverLogin) cleverLogin()
							break
						case IntegrationOptsType.classLink:
							if (!classLinkInstance.loading) classlinkLogin()
							break
						default:
							break
					}
				}}
				cardHeight={140}
			/>
			<InfoDialog
				open={cleverErrorOpen}
				onClose={() => {
					setCleverErrorOpen(false)
				}}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while login in with clever, please try again"
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={() => {
					setCleverErrorOpen(false)
				}}
			/>
			<CleverClassRoasterDialog
				isOpen={classesDialogOpen && Boolean(cleverAccount?.token)}
				setIsOpen={setOpen}
			/>
			<ClassLinkClassRoasterDialog
				isOpen={classesDialogOpen && Boolean(classLinkInstance.data?.token)}
				setIsOpen={setOpen}
			/>
		</>
	)
}
