import { useEffect, useState, useCallback } from 'react'

import { MediaPermissionsError, MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import moment from 'moment'
import { useReactMediaRecorder } from 'react-media-recorder'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useAudio, useInterval } from 'react-use'
import { RecordStudioLocationState, StudioReviewLocationState } from 'src/@types'
import { InstrumentsGuideType } from 'src/components/Studio/Record/InstrumentNotesPanel/types'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useGetStudentSettingByPkLazyQuery,
	useUpdateStudentSettingDontAskAgainRecordMutation,
	useGetSongForStudioLazyQuery,
	useGetSongChordsAndLyricsLazyQuery,
	useGetTeacherSettingByPkLazyQuery,
	useUpdateTeacherSettingDontAskAgainRecordMutation,
	useCreateRoyaltyLogMutation,
	useInsertStudentBadgeEventMutation,
	useUpdateProductivityMutation
} from 'src/graphql/autogenerate/hooks'
import { GetSongForStudioQuery } from 'src/graphql/autogenerate/operations'
import { Badge_Event_Name_Enum, Screen_Type_Enum, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useSearchQuery, useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { Pages as TeacherPages } from 'src/routes/teacherPages'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { BrowserPermissions, TrackTypeEnum, buildRouteParameters, getAudioUrl } from 'src/utils'

import { stopMediaStreams, videoConstraints } from './utils'

const useStudioRecord = () => {
	const {
		studentData: { student_id: studentId },
		teacherData: { teacher_id: teacherId }
	} = useLoginContext()
	const [isTeacher, setIsTeacher] = useState(false)
	const { songId } = useWurrlyParams<typeof StudentPages.StudentRecord.params[number]>()
	const queryParams = useSearchQuery()
	const isBlankSong = queryParams.get('isBlankSong') === 'true'

	const {
		selectedColorFilter,
		selectedFrameFilter,
		updatedStickers,
		selectedStickerFilter,
		updateVideoDimensions
	} = useVideoSubmitContext()
	const location = useLocation()
	const history = useHistory()

	const [isRecording, setIsRecording] = useState<boolean>(false)
	const [isCameraOn, setIsCameraOn] = useState<boolean>(true)
	const [isPermissionsDenied, setPermissionsDenied] = useState<boolean>(false)
	const [showHeadphonesConnectivity, setShowHeadphonesConnectivity] = useState<boolean>(true)
	const [selectedInstrumentGuide, selectInstrumentGuide] = useState<InstrumentsGuideType>(
		InstrumentsGuideType.audio
	)
	const [isApplyingEffects, setIsApplyingEffects] = useState(false)

	const [getStudentSettings, { data: studentSettingsQueryData, loading: studentSettingsLoading }] =
		useGetStudentSettingByPkLazyQuery({
			variables: { studentId }
		})
	const [getTeacherSettings, { data: teacherSettingsQueryData, loading: teacherSettingsLoading }] =
		useGetTeacherSettingByPkLazyQuery({
			variables: { teacherId }
		})

	const [isDontAskAgainChecked, setIsDontAskAgainChecked] = useState<boolean>(false)
	const [dontAskAgainRecordHint, setDontAskagainRecordHint] = useState(true)
	const [isTooltipHidden, setIsTooltipHidden] = useState<boolean>(dontAskAgainRecordHint)
	const [countDown, setCountDown] = useState<number | undefined>()
	const [intervalDelay, setIntervalDelay] = useState<number | null>(null)
	const [trackId, setTrackId] = useState(0)

	const [updateStudentSetting] = useUpdateStudentSettingDontAskAgainRecordMutation()
	const [updateTeacherSetting] = useUpdateTeacherSettingDontAskAgainRecordMutation()
	const [audioUrl, setAudioUrl] = useState('')
	const [song, setSong] = useState<GetSongForStudioQuery['song_by_pk']>()
	const [audioStartTime, setAudioStartTime] = useState<Date>()
	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()
	const [getSong, { data: songData, loading: isSongLoading }] = useGetSongForStudioLazyQuery()
	const [getSongsChordsAndLyrics, { data: chordsData, loading: chordsLoading }] =
		useGetSongChordsAndLyricsLazyQuery()
	const [practiceEvent] = useInsertStudentBadgeEventMutation()

	const [upsertProductivity] = useUpdateProductivityMutation()

	const [classId, setClassId] = useState<number>(0)
	const [challengeId, setChallengeId] = useState<number>(0)
	const [submissionId, setSubmissionId] = useState<number>(0)
	const [wurrlyType, setWurrlyType] = useState<Wurrly_Type_Enum>(Wurrly_Type_Enum.Indie)

	const { mediaBlobUrl, previewStream, startRecording, stopRecording } = useReactMediaRecorder({
		video: isCameraOn ? videoConstraints : undefined,
		askPermissionOnMount: false
	})
	const [audioElement, audioState, audioControls] = useAudio({ src: audioUrl, preload: 'auto' })

	const saveSetting = async () => {
		if (!dontAskAgainRecordHint && isDontAskAgainChecked) {
			try {
				if (isTeacher) await updateTeacherSetting({ variables: { teacherId } })
				else await updateStudentSetting({ variables: { studentId } })
			} catch (error) {
				console.error('Could not save user setting', { error })
			}
		}
		setIsTooltipHidden(true)
	}

	const recordingBegin = () => {
		startRecording()
		setIsRecording(true)
		setIsApplyingEffects(false)

		// Schedule the audio actions on the next macrotask
		setTimeout(() => {
			audioControls.play()
			setAudioStartTime(new Date())
		})
	}

	const recordingEnd = () => {
		stopRecording()
		setIsRecording(false)
		audioControls.pause()
		handleRoyaltyLog()
		stopMediaStreams()
		if (!isTeacher) {
			practiceEvent({
				variables: { studentId, eventName: Badge_Event_Name_Enum.PracticeGoal, classId }
			})

			practiceEvent({
				variables: { studentId, eventName: Badge_Event_Name_Enum.UnsavedPracticeSessions, classId }
			})

			upsertProductivity({
				variables: {
					studentId,
					classId,
					day: Number(moment().format('DD')),
					month: Number(moment().format('MM')),
					year: Number(moment().format('YYYY')),
					practiceSessions: 1,
					recordingSessions: 1,
					videoViews: 0
				}
			})
		}
	}

	const stopInterval = () => {
		setCountDown(undefined)
		setIntervalDelay(null)
	}

	const handleCountDown = () => {
		if (countDown) {
			if (countDown > 1) {
				setCountDown(countDown - 1)
			} else {
				stopInterval()
				recordingBegin()
			}
		}
	}

	const toggleRecording = () => {
		if (!isRecording && !countDown) {
			setCountDown(3)
			setIntervalDelay(1000) // 1 sec
		} else if (!isRecording && countDown) {
			stopInterval() // If clicks again before countdown ends
		} else {
			recordingEnd()
		}
	}

	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const playSeconds = (audioPauseTime.getTime() - (audioStartTime ?? audioPauseTime).getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId: trackId ?? 0,
					songId: songId ?? 0,
					teacherId: teacherId || null,
					studentId: studentId || null,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.VideoStudioRecord
				}
			})
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
		setAudioStartTime(undefined)
	}

	const toggleCamera = () => {
		setIsCameraOn((prev) => {
			if (prev) stopMediaStreams()

			return !prev
		})
	}

	const toggleDontAskAgain = useCallback(() => {
		setIsDontAskAgainChecked((prev) => !prev)
	}, [setIsDontAskAgainChecked])

	useInterval(handleCountDown, intervalDelay)

	useEffect(() => {
		if (songId) {
			getSong({ variables: { songId } })
		}
	}, [songId])

	useEffect(() => {
		if (songData?.song_by_pk) {
			setSong(songData.song_by_pk)
		}
	}, [songData])

	useEffect(() => {
		if (!location.state) return

		const {
			instrument,
			speed,
			transposition,
			classId: stateClassId,
			challengeId: stateChallengeId,
			submissionId: stateSubmissionId,
			wurrlyType: stateWurrlyType
		} = location.state as RecordStudioLocationState

		if (song && instrument && speed !== undefined && transposition !== undefined && stateWurrlyType) {
			setClassId(stateClassId ?? 0)
			setChallengeId(stateChallengeId ?? 0)
			setSubmissionId(stateSubmissionId ?? 0)
			setWurrlyType(stateWurrlyType)
			setAudioUrl(getAudioUrl(speed, transposition, instrument, song))
			const trackId =
				instrument === TrackTypeEnum.Vocal
					? song.tracks.find((song_track) => song_track.track_type.name === TrackTypeEnum.Band)?.track_id
					: song.tracks.find(
							(song_track) =>
								song_track.track_type.name === instrument ||
								song_track.track_type.name === TrackTypeEnum?.Vocal
					  )?.track_id
			if (trackId) getSongsChordsAndLyrics({ variables: { speed, transposition, trackId } })
			setTrackId(trackId ?? 0)
		}
	}, [location, song])

	useEffect(() => {
		if (!location.search) return

		const speed = 0
		const transposition = 0
		const instrument = TrackTypeEnum.Band
		const wurrlyType = Wurrly_Type_Enum.Indie

		if (song && instrument) {
			const stringClassId = queryParams.get('classId') ?? '0'
			const submId = queryParams.get('submissionId') ?? '0'
			setClassId(+stringClassId)
			setChallengeId(0)
			setSubmissionId(submId ? +submId : 0)
			setWurrlyType(wurrlyType)
			setAudioUrl(getAudioUrl(speed, transposition, instrument, song))
			const trackId = song.tracks.find(
				(song_track) => song_track.track_type.name === TrackTypeEnum.Band
			)?.track_id
			if (trackId) getSongsChordsAndLyrics({ variables: { speed, transposition, trackId } })
			setTrackId(trackId ?? 0)
		}
	}, [location, song])

	useEffect(() => {
		setIsTooltipHidden(dontAskAgainRecordHint)
	}, [dontAskAgainRecordHint])

	useEffect(() => {
		if (isTeacher && !teacherSettingsLoading && teacherSettingsQueryData?.teacher_setting[0]) {
			setDontAskagainRecordHint(!!teacherSettingsQueryData.teacher_setting[0].dont_ask_again_record)
		} else if (!isTeacher && !studentSettingsLoading && studentSettingsQueryData?.student_setting[0]) {
			setDontAskagainRecordHint(!!studentSettingsQueryData.student_setting[0].dont_ask_again_record)
		}
	}, [studentSettingsQueryData, teacherSettingsQueryData])

	useEffect(() => {
		if (isTeacher && teacherId) getTeacherSettings()
		else if (!isTeacher && studentId) getStudentSettings()
	}, [isTeacher, studentId, teacherId])

	useEffect(() => {
		if (mediaBlobUrl && !isRecording) {
			history.push({
				pathname: isTeacher
					? buildRouteParameters(TeacherPages.TeacherStudioReview, { songId })
					: buildRouteParameters(StudentPages.StudioReview, { songId }),
				state: {
					audioUrl,
					videoUrl: mediaBlobUrl,
					trackId,
					isCameraOn,
					classId,
					submissionId,
					challengeId,
					wurrlyType,
					color: selectedColorFilter,
					frame: selectedFrameFilter,
					stickers: updatedStickers,
					isBlankSong
				} as StudioReviewLocationState
			})
		}
	}, [mediaBlobUrl, isRecording])

	useEffect(() => {
		requestMediaPermissions()
			.then(() => {
				setPermissionsDenied(false)
			})
			.catch(async (err: MediaPermissionsError) => {
				const { type } = err

				switch (type) {
					// browser does not have permission to access camera or microphone
					case MediaPermissionsErrorType.SystemPermissionDenied:
					// user didn't allow app to access camera or microphone
					case MediaPermissionsErrorType.UserPermissionDenied:
						const cameraPermissionName = 'camera' as PermissionName
						const microphonePermissionName = 'microphone' as PermissionName

						const cameraStatus = await navigator.permissions.query({ name: cameraPermissionName })
						const microphoneStatus = await navigator.permissions.query({
							name: microphonePermissionName
						})

						// camera is denied
						if (cameraStatus.state === BrowserPermissions.denied) {
							// if camera is denied turn off camera
							setIsCameraOn(false)
						} else if (microphoneStatus.state === BrowserPermissions.granted) {
							// if camera is granted and microphone is granted turn on camera
							setIsCameraOn(true)
							setPermissionsDenied(false)
						}

						// microphone is denied
						if (microphoneStatus.state === BrowserPermissions.denied) {
							// if microphone is denied open the modal and turn off camera
							setPermissionsDenied(true)
							setIsCameraOn(false)
						} else if (microphoneStatus.state === BrowserPermissions.granted) {
							// if microphone is granted turn off camera
							setIsCameraOn(false)
							setPermissionsDenied(false)
						}

						break
				}
			})
	}, [])

	return {
		isRecording,
		setIsRecording,
		showHeadphonesConnectivity,
		setShowHeadphonesConnectivity,
		isCameraOn,
		setIsCameraOn,
		selectedInstrumentGuide,
		selectInstrumentGuide,
		isDontAskAgainChecked,
		isTooltipHidden,
		toggleCamera,
		toggleRecording,
		toggleDontAskAgain,
		saveSetting,
		mediaBlobUrl,
		previewStream,
		countDown,
		audioElement,
		audioState,
		audioControls,
		isSongLoading,
		chordsLoading,
		chordsData,
		setIsTeacher,
		isApplyingEffects,
		setIsApplyingEffects,
		selectedFrameFilter,
		selectedStickerFilter,
		updateVideoDimensions,
		selectedColorFilter,
		isBlankSong,
		isPermissionsDenied
	}
}

export const [RecordProvider, useStudioRecordContext] = buildGenericContext(useStudioRecord)
