import React, { useState, Fragment } from 'react'

import {
	Box,
	Button,
	CircularProgress,
	fade,
	Grid,
	IconButton,
	InputBase,
	makeStyles,
	Paper,
	Theme,
	Typography,
	useTheme
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'

import { BaseDialog, BaseLink } from '../../../../../../../../components'
import { SelectSortOrderBy } from '../../../../../../../../components/SelectSortOrderBy'
import { Order_By, Student } from '../../../../../../../../graphql/autogenerate/schemas'
import { getCyKey } from '../../../../../../../../utils'
import { ListAddItems } from './ListAddItems'

const useStyles = makeStyles((theme: Theme) => ({
	boxInput: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		backgroundColor: fade(theme.palette.text.secondary, 0.1),
		'& .MuiPaper-root :focus': {
			backgroundColor: theme.palette.common.white,
			border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`
		},
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	boxInputFocus: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${fade(theme.palette.text.secondary, 0.1)}`,
		padding: theme.spacing(0.5),
		paddingLeft: theme.spacing(1),
		display: 'flex',
		'& .MuiInputBase-root': {
			flex: 1
		}
	},
	discardButton: {
		width: '346px'
	},
	confirmButton: {
		width: '346px'
		// backgroundColor: theme.palette.error.main
	},
	sortRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: theme.spacing(1, 0)
	}
}))
type AddDialogV2Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
	setSelectedStudents: React.Dispatch<React.SetStateAction<Student[]>>
	setSort: React.Dispatch<React.SetStateAction<Order_By>>
	sort: Order_By
	count: number
	items: ({ selected: boolean } & Student)[]
	selectedItems: Student[]
	loading: boolean
	onSave: () => void
	isAddNewStudentsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const AddDialogV2 = ({
	open,
	setOpen,
	setSearch,
	setSelectedStudents,
	setSort,
	sort,
	count,
	items,
	selectedItems,
	loading,
	onSave,
	isAddNewStudentsOpen
}: AddDialogV2Props) => {
	const [text, setText] = useState<string>()

	const [focus, setFocus] = useState(false)
	const styles = useStyles(focus)
	const theme = useTheme()

	const handleFocus = () => setFocus(true)
	const handleBlur = () => setFocus(false)

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.keyCode === 13 || event.key === 'Enter') {
			setSearch(`%${text}%`)
			event.preventDefault()
		}
	}

	const onSelect = (
		item: {
			selected: boolean
		} & Student
	) => {
		const array = [...selectedItems]
		const index = array.findIndex((it) => it.student_id === item.student_id)
		if (index !== -1) {
			array.splice(index, 1)
		} else {
			array.push(item)
		}
		setSelectedStudents(array)
	}

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as Order_By
		setSort(selectedSort)
	}

	const selectAll = () => {
		setSelectedStudents(items.map((item) => ({ ...item, selected: true })))
	}

	const clearSelection = () => {
		setSelectedStudents([])
	}

	return (
		<BaseDialog
			open={open}
			onClose={() => {}}
			header={
				<Fragment>
					<Typography
						data-cy={getCyKey(AddDialogV2, 'dialogTitle')}
						variant="h5"
						align="center"
						style={{ marginTop: '10px' }}>
						<b>Add Students to Class</b>
					</Typography>
					<Paper
						style={{ marginTop: '12px', marginBottom: '12px' }}
						component="form"
						elevation={0}
						className={focus ? styles.boxInputFocus : styles.boxInput}>
						<IconButton aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							data-cy={getCyKey(AddDialogV2, 'searchBar')}
							value={text ?? ''}
							onChange={(e) => {
								setText(e.target.value)
							}}
							onKeyDown={handleKeyDown}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder="Find Student by Name, Email or Class"
							inputProps={{ 'aria-label': 'search student' }}
						/>
						{!!text && (
							<Fragment>
								<Button
									style={{ color: theme.palette.text.secondary }}
									variant="text"
									onClick={() => {
										setText('')
									}}>
									<HighlightOffIcon />
								</Button>
							</Fragment>
						)}
					</Paper>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Box display="flex" alignItems="center">
								<Typography variant="h6">
									<b>Students ({count})</b>
								</Typography>
								<div style={{ flexGrow: 1 }} />
							</Box>
						</Grid>
					</Grid>
					<Box className={styles.sortRow}>
						<SelectSortOrderBy cyKey="Students" value={sort} onChange={handleSort} />
						<Box display={'flex'}>
							<Box mr={1}>
								<Button color="secondary" onClick={selectAll}>
									<strong>ALL</strong>
								</Button>
							</Box>
							<Box ml={1}>
								<Button color="secondary" onClick={clearSelection}>
									<strong>NONE</strong>
								</Button>
							</Box>
						</Box>
					</Box>
				</Fragment>
			}
			body={
				!loading ? (
					<Box style={{ background: 'linear-gradient(to bottom, rgb(255,255,255,1), #fff )' }}>
						<Grid item xs style={{ maxHeight: '250px' }}>
							<ListAddItems spacing={1.5} data={items} onSelect={onSelect} />
							{items.length <= 0 && (
								<Box style={{ opacity: 0.5 }}>
									<Typography data-cy={getCyKey(AddDialogV2, 'NoResultsFound')} variant="h5">
										No results found
									</Typography>
								</Box>
							)}
						</Grid>
					</Box>
				) : (
					<Box style={{ margin: 20, textAlign: 'center' }}>
						<CircularProgress color="secondary" size={40} />
					</Box>
				)
			}
			onDiscard={() => {
				setOpen(false)
				setSelectedStudents([])
			}}
			onDiscardProps={{
				className: styles.discardButton
			}}
			discardLabel="Close"
			onConfirm={() => {
				setOpen(false)
				onSave()
			}}
			onConfirmProps={{
				className: styles.confirmButton,
				color: 'primary',
				startIcon: <AddIcon />
			}}
			confirmLabel={'Add Students'}
			isConfirmDisabled={selectedItems.length <= 0}
			footer={
				<Typography
					style={{
						fontSize: 15,
						fontWeight: 500
					}}>
					Don't See Your Student?{' '}
					<BaseLink
						data-cy={getCyKey(AddDialogV2, 'AddNewStudentBtn')}
						onClick={() => {
							setSelectedStudents([])
							isAddNewStudentsOpen(true)
							setOpen(false)
						}}>
						Add New Student
					</BaseLink>
				</Typography>
			}
			typographyProps={{
				variant: 'body1'
			}}
			maxWidth="sm"
			dividers={false}
		/>
	)
}
