import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
	createStyles({
		badgesPanel: {
			width: '100%',
			display: 'flex',
			flex: '1 1 0px'
		},
		title: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			justifyContent: 'space-between',
			height: 'min-content',
			marginTop: '-1em'
		},
		buttonBadge: {
			padding: 0
		},
		badges: {
			transition: 'ease .2s',
			zIndex: 1,
			background: 'transparent',
			border: 'none',
			boxShadow: 'none',
			padding: '5px',
			width: '100%',
			height: '100%',
			'&:hover': {
				background: 'transparent',
				transform: 'scale(1.1)'
			},
			'& img': {
				width: '100%',
				height: '100%'
			}
		},
		longImage: {
			height: '100%',
			aspectRatio: '23/6',
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			},
			'& .main': {
				height: '100%'
			}
		},
		bigImage: {
			height: '100%',
			aspectRatio: '13/14',
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			}
		},
		badgeImage: {
			width: '100%',
			height: '100%',
			aspectRatio: '1/1'
		},
		badgeContainer: {
			width: '100%',
			height: '100%',
			position: 'relative'
		},
		points: {
			display: 'flex',
			position: 'absolute',
			width: '45%',
			top: '7%',
			right: 0,
			alignItems: 'center',
			justifyContent: 'center'
		}
	})
)

export default useStyles
