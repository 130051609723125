import { useEffect } from 'react'

import { Box, Button, Link, Typography } from '@material-ui/core'
import CallMadeIcon from '@material-ui/icons/CallMade'
import { Link as LinkRD } from 'react-router-dom'
import { ImageButton, ImageButtonProps } from 'src/components/Buttons/ImageButton'
import { CarouselOfAny } from 'src/components/CarouselOfAny/CarouselOfAny'
import { useGetModulesLessonsPageLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Module } from 'src/graphql/autogenerate/schemas'
import { Pages, SlugEnum } from 'src/routes/teacherPages'
import { buildRouteParameters } from 'src/utils'

import { useLessonsStyles } from '../Lessons.styles'
export const ModulesCarousel = () => {
	const styles = useLessonsStyles()
	const [getModules, { data: modulesData }] = useGetModulesLessonsPageLazyQuery()
	const modules = (modulesData?.module || []) as Module[]

	useEffect(() => {
		getModules()
	}, [])

	const LinkToModules = (location: Location) => {
		return {
			...location,
			pathname: Pages.Modules.path,
			state: {
				catalog: SlugEnum.Modules
			}
		}
	}

	const LinePanel = ({ module }: { module: Module }) => {
		return (
			<Box className={styles.panel} display="flex" flexWrap="wrap" alignItems="center">
				<Button>{module?.module_lesson_plan.length ?? '0'} Lessons</Button>
				<div style={{ flexGrow: 1 }} />
				<Button endIcon={<CallMadeIcon style={{ transform: 'rotate(45deg)' }} />}>View</Button>
			</Box>
		)
	}

	return (
		<>
			<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
				<Typography variant="h6">
					<b>Modules</b>
				</Typography>
				<div style={{ flexGrow: 1 }} />
				<Link className={styles.link} component={LinkRD} to={LinkToModules}>
					<b>View All</b>
				</Link>
			</Box>
			<CarouselOfAny
				slidesPerPage={{ xs: 1, sm: 2 }}
				spacing={3}
				data={modules?.map((module) => ({
					backgroundImageUrl: module.image_path ?? '',
					title: <Typography style={{ fontSize: 28 }}>{module.title}</Typography>,
					element: <LinePanel module={module} />,
					linkTo: {
						pathname: buildRouteParameters(Pages.ModuleDetails, { moduleId: module.module_id })
					}
				}))}
				renderItem={(item: ImageButtonProps) => (
					<ImageButton
						className={styles.modules}
						fitToContainer
						titleHeight={{ xs: 250, md: 277 }}
						backgroundColor={item.backgroundColor}
						backgroundImageUrl={item.backgroundImageUrl}
						title={item.title}
						titlePosition="initial"
						element={item.element}
						elementPosition={{ top: 'auto', right: 16, left: 0, bottom: 32 }}
						linkTo={item.linkTo}
					/>
				)}
			/>
		</>
	)
}
