import { Box, Typography } from '@material-ui/core'

import { getCyKey } from '../../utils'

type Props = {
	title: string
	description: string
}

export const NoItems = ({ title, description }: Props) => {
	return (
		<Box>
			<Box my={4} textAlign="start">
				<Typography variant="h6" color="textSecondary" data-cy={getCyKey(NoItems, 'NoItemsMessage')}>
					{title}
				</Typography>
				<Typography color="textSecondary" style={{ paddingTop: 14 }}>
					{description}
				</Typography>
			</Box>
		</Box>
	)
}
