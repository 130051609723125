import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
	createStyles({
		description: {
			paddingTop: 12,
			paddingBottom: 12,
			paddingRight: 10,
			paddingLeft: 10,
			fontSize: 18,
			fontWeight: 'normal'
		}
	})
)
