import { createStyles, makeStyles } from '@material-ui/core'

export const useCreateModuleStyles = makeStyles(() =>
	createStyles({
		content: {
			'& img': {
				maxWidth: '100%',
				height: 'auto'
			}
		}
	})
)
