import React, { useEffect, useState } from 'react'

import { Box, InputBase, InputBaseProps, List, ListItem } from '@material-ui/core'
import clsx from 'clsx'

import { VisibilityIcon } from '../../../assets/icons'
import { getCyKey } from '../../../utils'
import { useLoginStyles } from '../styles'

enum InputTypes {
	Text = 'text',
	Password = 'password'
}

type Props = InputBaseProps & {
	password: string
	setPassword: (password: string) => void
	disableValidation?: boolean
	style?: React.CSSProperties
	setFulfillRequirements?: React.Dispatch<React.SetStateAction<boolean>>
	disabled?: boolean
}

type Validation = {
	description: string
	fulfill: boolean
}

const checkLength = (word: string, minLength: number) => {
	return word.length >= minLength
}

const checkUpperCaseLetter = (word: string) => {
	return /[A-Z]/.test(word)
}

const checkLowerCaseLetter = (word: string) => {
	return /[a-z]/.test(word)
}

const checkNumber = (word: string) => {
	return /[0-9]/.test(word)
}

const checkSpecialChar = (word: string) => {
	return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(word)
}

const generateObject = (password: string): Validation[] => {
	return [
		{ description: 'At least 8 characters long', fulfill: checkLength(password, 8) },
		{ description: 'At least one uppercase character (A-Z)', fulfill: checkUpperCaseLetter(password) },
		{ description: 'At least one lowercase character (a-z)', fulfill: checkLowerCaseLetter(password) },
		{ description: 'At least one numeric character (0-9)', fulfill: checkNumber(password) },
		{ description: 'At least one special character (S@!%*#?&)', fulfill: checkSpecialChar(password) }
	]
}

export const validatePassword = (password: string): boolean => {
	const validation = generateObject(password)

	return validation.every((item) => item.fulfill)
}

export const PasswordInput = ({
	password,
	setPassword,
	disableValidation,
	style,
	setFulfillRequirements,
	disabled,
	...props
}: Props) => {
	const classes = useLoginStyles({})
	const [inputType, setInputType] = useState<InputTypes>(InputTypes.Password)
	const [showMessage, setShowMessage] = useState<boolean>(false)
	const [validations, setValidations] = useState<Validation[]>(generateObject(password))

	useEffect(() => {
		const requirements = generateObject(password)
		setValidations(requirements)
		if (setFulfillRequirements) {
			const fulfill = !requirements.find((i) => i.fulfill === false)
			setFulfillRequirements(fulfill)
		}
	}, [password])

	return (
		<Box style={{ position: 'relative' }}>
			<InputBase
				{...props}
				data-cy={getCyKey(PasswordInput, 'InputPassword')}
				className={clsx(classes.input, props.className)}
				value={password}
				fullWidth={true}
				onChange={(e) => {
					setPassword(e.target.value)
				}}
				onFocus={() => setShowMessage(true)}
				onBlur={() => setShowMessage(false)}
				type={inputType}
				style={style}
				disabled={disabled}
				endAdornment={
					<img
						src={VisibilityIcon}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setInputType(inputType === InputTypes.Password ? InputTypes.Text : InputTypes.Password)
						}}
					/>
				}
			/>
			{!disableValidation && showMessage && (
				<Box
					style={{
						position: 'absolute',
						left: '0',
						top: '120%',
						// transform: 'translateY(-45%)',
						background: 'white',
						border: '1px solid #E1E1E1',
						borderRadius: '6px',
						zIndex: 15
					}}>
					<Box style={{ position: 'relative' }}>
						<Box
							style={{
								position: 'absolute',
								left: '20px',
								transform: 'rotate(45deg)',
								top: '-8px',
								width: '11px',
								height: '11px',
								backgroundColor: 'white',
								border: '1px solid #E1E1E1',
								borderBottom: 'none',
								borderRight: 'none'
							}}></Box>
						<List style={{ listStyleType: 'circle' }}>
							{validations.map((i, index) => (
								<ListItem
									style={{
										fontSize: '12px',
										color: i.fulfill ? '#DADADA' : 'black',
										padding: '5px 15px'
									}}
									key={index}>
									<span
										style={{
											fontSize: '20px',
											lineHeight: 0,
											paddingRight: '5px',
											color: i.fulfill ? '#DADADA' : '#FFC20C'
										}}>
										•
									</span>
									{i.description}
								</ListItem>
							))}
						</List>
					</Box>
				</Box>
			)}
		</Box>
	)
}
