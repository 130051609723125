import moment, { Moment } from 'moment'
import { DateFilterEnum } from 'src/environment'
import { Student_Productivity_Daily } from 'src/graphql/autogenerate/schemas'
import { COLORS } from 'src/styles/colors'

import { StudentCompletedAssignment } from './useStudentAnalytics'

export enum GrafEnum {
	Days = 'days',
	Months = 'months'
}
export const getTitle = (filter: DateFilterEnum) => {
	let title = 'Since Last Week'
	switch (filter) {
		case DateFilterEnum.lastWeek:
			title = 'Since Last Week'
			break
		case DateFilterEnum.thisMonth:
			title = 'Since Last Month'
			break
		case DateFilterEnum.lastMonth:
			title = 'Compared To Previous Last Month'
			break

		case DateFilterEnum.lastSixMonths:
			title = 'Compared To Previous Six Months'
			break

		case DateFilterEnum.thisYear:
			title = 'Since Last Year'

			break
	}

	return title
}
export const getAssignmentPercentage = (currentSubmited: number, previousSubmited: number) => {
	let percentage = 0
	if (currentSubmited >= previousSubmited) {
		percentage = (currentSubmited / previousSubmited) * 100 || 0
	} else {
		percentage = -(currentSubmited / previousSubmited) * 100 || 0
	}
	if (percentage === Infinity) percentage = 100
	if (percentage === -Infinity) percentage = -100

	return percentage
}

export const getWurrliesPercentage = (totalWurrlies: number, lastWeekWurrlies: number) => {
	let percentage: number
	if (totalWurrlies === 0 && lastWeekWurrlies === 0) {
		percentage = 0
	} else if (totalWurrlies >= lastWeekWurrlies) {
		// Negative progess compared to week 2
		percentage = Number(((totalWurrlies / lastWeekWurrlies) * 100).toFixed(2))
	} else {
		percentage = Number((-(lastWeekWurrlies / totalWurrlies) * 100).toFixed(2))
	}
	if (percentage === Infinity) percentage = 100
	if (percentage === -Infinity) percentage = -100

	return percentage
}

export const buildLabels = (graf: GrafEnum, fromDate: Moment, toDate: Moment) => {
	const result = []
	if (graf === GrafEnum.Days) {
		for (let i = Number(fromDate?.date()); i <= Number(toDate?.date()); i++) {
			result.push(Number(fromDate?.month()) + 1 + '/' + i + '/' + fromDate?.year())
		}
	} else {
		for (let i = Number(fromDate?.month()); i <= Number(toDate?.month()); i++) {
			result.push(i + 1 + '/' + fromDate?.year())
		}
	}

	return result
}

export const buildDataForDataset = (
	graf: GrafEnum,
	student_productivity_daily: Student_Productivity_Daily[],
	labels: string[]
) => {
	const dates: string[] = []
	const videoViews: number[] = []
	const practiceSessions: number[] = []
	const recordingSessions: number[] = []
	const videoViewsArr: number[] = []
	const practiceSessionsArr: number[] = []
	const recordingSessionsArr: number[] = []

	if (graf === GrafEnum.Days) {
		student_productivity_daily.map((item) => {
			dates.push(item.month + '/' + item.day + '/' + item.year)

			videoViews.push(item.video_views)

			practiceSessions.push(item.practice_sessions)
			recordingSessions.push(item.recording_sessions)
		})
	} else {
		student_productivity_daily.map((item) => {
			dates.push(item.month + '/' + item.year)
			videoViews.push(item.video_views)
			practiceSessions.push(item.practice_sessions)
			recordingSessions.push(item.recording_sessions)
		})
	}

	labels.forEach((label) => {
		const index = dates.indexOf(label)
		if (index >= 0) {
			videoViewsArr.push(videoViews[index])
			practiceSessionsArr.push(practiceSessions[index])
			recordingSessionsArr.push(recordingSessions[index])
		} else {
			videoViewsArr.push(0)
			practiceSessionsArr.push(0)
			recordingSessionsArr.push(0)
		}
	})

	return { recordingSessionsArr, practiceSessionsArr, videoViewsArr }
}

export const buildDataset = (
	practiceSessionsArr: number[],
	recordingSessionsArr: number[],
	videoViewsArr: number[]
) => {
	return [
		{
			label: 'Practice sessions',
			data: practiceSessionsArr,
			borderColor: [COLORS.secondary.dark],
			fill: false,
			tension: 0,
			pointRadius: 5,
			pointBorderWidth: 2,
			pointBorderColor: COLORS.secondary.dark,
			pointBackgroundColor: COLORS.secondary.dark,
			pointHoverBackgroundColor: COLORS.white
		},
		{
			label: 'Recording sessions',
			data: recordingSessionsArr,
			borderColor: [COLORS.purple],
			fill: false,
			tension: 0,
			pointRadius: 5,
			pointBorderWidth: 2,
			pointBorderColor: COLORS.purple,
			pointBackgroundColor: COLORS.purple,
			pointHoverBackgroundColor: COLORS.white
		},
		{
			label: 'Video views',
			data: videoViewsArr,
			borderColor: [COLORS.blue],
			fill: false,
			tension: 0,
			pointRadius: 5,
			pointBorderColor: COLORS.blue,
			pointBorderWidth: 2,
			pointBackgroundColor: COLORS.blue,
			pointHoverBackgroundColor: COLORS.white
		}
	]
}

export const getComparisonMoment = (filter: DateFilterEnum) => {
	let toDate = moment()
	let fromDate = moment()
	let graf = GrafEnum.Days

	switch (filter) {
		case 'lastWeek':
			fromDate = moment().subtract(7, 'days')
			break
		case 'thisMonth':
			fromDate = moment().subtract(moment().date() - 1, 'days')
			break
		case 'lastMonth':
			toDate = moment().subtract(moment().date(), 'days')
			fromDate = moment()
				.subtract(moment().date() - 1, 'days')
				.subtract(1, 'months')

			break
		case 'lastSixMonths':
			fromDate = moment()
				.subtract(moment().date() - 1, 'days')
				.subtract(5, 'month')
			graf = GrafEnum.Months
			break
		case 'thisYear':
			fromDate = moment()
				.subtract(moment().date() - 1, 'days')
				.subtract(moment().month(), 'month')

			graf = GrafEnum.Months
			break
	}

	return { fromDate, toDate, graf }
}

export const orderAssignmentByGrade = (
	classAssignments: StudentCompletedAssignment[],
	gradingValues: string[] | number[]
) => {
	const stringifiedValued = gradingValues.map((val) => val.toString())
	const sortedSubmissions = [...classAssignments]

	return sortedSubmissions?.sort((a, b) => {
		const aIndex = stringifiedValued.indexOf(a?.submission?.grade?.toString() || '')
		const bIndex = stringifiedValued.indexOf(b?.submission?.grade?.toString() || '')
		if (aIndex < 0) {
			return 1
		}
		if (bIndex < 0) {
			return -1
		}

		return aIndex - bIndex
	})
}

export const orderAssignmentByNameAsc = (classAssignments: StudentCompletedAssignment[]) => {
	return [...classAssignments].sort((a: StudentCompletedAssignment, b: StudentCompletedAssignment) =>
		(a?.assignment.name ?? '').localeCompare(b?.assignment.name ?? '')
	)
}

export const orderAssignmentByNameDesc = (classAssignments: StudentCompletedAssignment[]) => {
	return [...classAssignments].sort((a: StudentCompletedAssignment, b: StudentCompletedAssignment) =>
		(b?.assignment.name ?? '').localeCompare(a?.assignment.name ?? '')
	)
}

export const orderAssignmentByDateAsc = (classAssignments: StudentCompletedAssignment[]) => {
	return [...classAssignments].sort((a: StudentCompletedAssignment, b: StudentCompletedAssignment) => {
		const aDate = new Date(a?.submission?.date_submitted ?? '')
		const bDate = new Date(b?.submission?.date_submitted ?? '')

		return aDate.getTime() - bDate.getTime()
	})
}
