import { ButtonProps, CircularProgress } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

import { StarSmallIcon } from '../../assets/icons/CustomIcons'
import { BigBaseButton } from './BigBaseButton'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btnFav: {
			minWidth: 'auto',
			width: 56, // fixed witdh according to figma
			padding: 0,
			margin: '0 10px',
			'&.selected': {
				backgroundColor: fade(theme.palette.secondary.main, 0.26),
				color: theme.palette.secondary.dark
			}
		}
	})
)
type Props = {
	loading: boolean
	isFavorite: boolean
}
export const FavoriteButton = ({ loading, onClick, isFavorite, ...props }: Props & ButtonProps) => {
	const classes = useStyles()

	return (
		<BigBaseButton
			variant="contained"
			onClick={onClick}
			disabled={loading}
			{...props}
			className={clsx(classes.btnFav, { selected: isFavorite }) + ' ' + props.className}>
			{loading ? <CircularProgress color="secondary" size={12} /> : <StarSmallIcon />}
		</BigBaseButton>
	)
}
