import React, { useEffect, useMemo, useState } from 'react'

import { Box, CircularProgress } from '@material-ui/core'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { buildImagePath, buildVideoPath, isEmbed, isFromBamboo, isFromYoutube } from 'src/utils'

type ReactCustomVideoPlayerProps = {
	url?: string
	playing?: boolean
	light?: string
	width?: number | string
	height?: number | string
	style?: React.CSSProperties
	ref?: React.RefObject<ReactPlayer>
	className?: string
	config?: Record<string, unknown>
	onPlay?: () => void
	onPause?: () => void
	onProgress?: (x: ReactPlayerProps) => void
} & ReactPlayerProps

export const ReactCustomVideoPlayer: React.FC<ReactCustomVideoPlayerProps> = ({
	url,
	light,
	width,
	height,
	style = {},
	ref,
	className,
	config = {},
	playing,
	...props
}) => {
	const [isPlaying, setIsPlaying] = useState(false)
	const [startedPlaying, setStartedPlaying] = useState(false)
	const [isError, setIsError] = useState(false)
	const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.preventDefault()
	const handleOnPlay = () => {
		setStartedPlaying(true)
	}

	const handleOnError = () => {
		setIsPlaying(false)
		setIsError(true)
	}

	const isEmbeddedNotSupportedVideo = useMemo(() => {
		return (isEmbed(url as string) && !isFromYoutube(url as string)) || isFromBamboo(url as string)
	}, [url])

	const thumbnail = useMemo(() => {
		return isFromYoutube(url as string) ? undefined : light
	}, [url])

	useEffect(() => {
		return () => {
			setIsPlaying(false)
			setStartedPlaying(false)
		}
	}, [])

	if (!url) return <CircularProgress size={18} />

	return (
		<>
			{isEmbeddedNotSupportedVideo || isError ? (
				<Box
					display="flex"
					justifyContent="center"
					style={{ ...props.style, height: '460px', maxWidth: 813, margin: 'auto' }}>
					<iframe
						width="100%"
						style={{ borderColor: 'transparent' }}
						src={url as string}
						allowFullScreen></iframe>
				</Box>
			) : (
				<ReactPlayer
					ref={ref}
					className={className}
					onPlay={startedPlaying ? () => {} : handleOnPlay}
					controls
					width={width}
					height={height}
					playing={isPlaying || playing}
					onClickPreview={() => setIsPlaying(true)}
					light={buildImagePath(thumbnail as string)}
					style={{
						flexDirection: 'row-reverse',
						display: 'flex',
						...style
					}}
					onContextMenu={handleContextMenu}
					config={{
						file: {
							attributes: {
								disablepictureinpicture: 'true',
								controlsList: 'nodownload'
							}
						},
						...(config as Record<string, unknown>)
					}}
					preload={1}
					url={buildVideoPath(url || '')}
					onError={handleOnError}
				/>
			)}
		</>
	)
}
