import { useState } from 'react'

import { Box, makeStyles, Theme } from '@material-ui/core'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'

import { InputHeader } from '..'
import { AmericanExpress, Discovery, Mastercard, Visa } from '../../assets/icons'
import { COLORS } from '../../styles/colors'
import { masterStyles } from '../../styles/master-styles.deprecated'
import { StripeBrandEnum } from '../../utils'

const useStyles = makeStyles<Theme, { isFocused: boolean }>(() => ({
	inputContainer: {
		// backgroundColor: 'red',
		height: 56,
		lineHeight: '1.1876em',
		padding: '18.5px 14px',
		borderRadius: 4,
		borderWidth: (props) => (props.isFocused ? 2 : 1),
		borderStyle: 'solid'
	}
}))

type Props = { setCardComplete: (complete: boolean) => void }

export const CardInput = ({ setCardComplete }: Props) => {
	const [isFocusedCardNumber, setIsFocusedCardNumber] = useState(false)
	const [isFocusedCardExpiry, setIsFocusedCardExpiry] = useState(false)
	const [isFocusedCardCSV, setIsFocusedCardCSV] = useState(false)
	const [isHoveredCardNumber, setIsHoveredCardNumber] = useState(false)
	const [isHoveredCardExpiry, setIsHoveredCardExpiry] = useState(false)
	const [isHoveredCardCSV, setIsHoveredCardCSV] = useState(false)

	const [allFieldsCompleted, setAllFieldsCompleted] = useState({
		cardNumber: false,
		expiry: false,
		csv: false
	})

	const [brand, setBrand] = useState<StripeBrandEnum>(StripeBrandEnum.unknown)
	const styles = useStyles({ isFocused: isFocusedCardNumber || isFocusedCardExpiry || isFocusedCardCSV })

	const switchOpacity = (selectedBrand: string) => (selectedBrand === brand ? 1 : 0.5)
	const switchBorder = (isFocus: boolean, isHovered: boolean) =>
		isFocus ? COLORS.secondary.main : isHovered ? COLORS.black : COLORS.primary.contrastText

	const handleOnChange = (attribute: string, value: boolean) => {
		const updatedState = {
			...allFieldsCompleted,
			[attribute]: value
		}
		setAllFieldsCompleted(updatedState)
		setCardComplete(updatedState.cardNumber && updatedState.expiry && updatedState.csv)
	}

	return (
		<Box style={masterStyles.formContainerNoPadding}>
			<Box width="100%">
				<InputHeader
					name="Card Number"
					isRequired
					endAdornment={
						<Box style={masterStyles.formContainerNoPadding}>
							<img
								alt="AmericanExpress"
								src={AmericanExpress}
								style={{
									opacity: switchOpacity(StripeBrandEnum.amex)
								}}
							/>
							<img
								alt="Mastercard"
								src={Mastercard}
								style={{
									opacity: switchOpacity(StripeBrandEnum.mastercard)
								}}
							/>
							<img
								alt="Visa"
								src={Visa}
								style={{
									opacity: switchOpacity(StripeBrandEnum.visa)
								}}
							/>
							<img
								alt="Discovery"
								src={Discovery}
								style={{
									opacity: switchOpacity(StripeBrandEnum.discover)
								}}
							/>
						</Box>
					}
				/>
				<Box padding="10px 0px">
					<Box
						className={styles.inputContainer}
						style={{
							borderColor: switchBorder(isFocusedCardNumber, isHoveredCardNumber)
						}}
						onMouseEnter={() => setIsHoveredCardNumber(true)}
						onMouseLeave={() => setIsHoveredCardNumber(false)}>
						<CardNumberElement
							id="cardNumber"
							onChange={(e) => {
								setBrand(e.brand as StripeBrandEnum)
								handleOnChange('cardNumber', e.complete)
							}}
							onBlur={() => setIsFocusedCardNumber(false)}
							onFocus={() => setIsFocusedCardNumber(true)}
							// onReady={logEvent('ready')}
							// options={ELEMENT_OPTIONS}
						/>
					</Box>
				</Box>
			</Box>

			<Box width="48%">
				<InputHeader name="Expiry Date" isRequired />
				<Box padding="10px 0px">
					<Box
						className={styles.inputContainer}
						style={{
							borderColor: switchBorder(isFocusedCardExpiry, isHoveredCardExpiry)
						}}
						onMouseEnter={() => setIsHoveredCardExpiry(true)}
						onMouseLeave={() => setIsHoveredCardExpiry(false)}>
						<CardExpiryElement
							id="expiry"
							onBlur={() => setIsFocusedCardExpiry(false)}
							onFocus={() => setIsFocusedCardExpiry(true)}
							onChange={(e) => handleOnChange('expiry', e.complete)}
							// onReady={logEvent('ready')}
							// options={ELEMENT_OPTIONS}
						/>
					</Box>
				</Box>
			</Box>

			<Box width="48%">
				<InputHeader name="CVC" isRequired />
				<Box padding="10px 0px">
					<Box
						className={styles.inputContainer}
						style={{
							borderColor: switchBorder(isFocusedCardCSV, isHoveredCardCSV)
						}}
						onMouseEnter={() => setIsHoveredCardCSV(true)}
						onMouseLeave={() => setIsHoveredCardCSV(false)}>
						<CardCvcElement
							id="csv"
							onBlur={() => setIsFocusedCardCSV(false)}
							onFocus={() => setIsFocusedCardCSV(true)}
							onChange={(e) => handleOnChange('csv', e.complete)}
							// onReady={logEvent('ready')}
							// options={ELEMENT_OPTIONS}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

// const CARD_OPTIONS: StripeCardElementOptions = {
// 	iconStyle: 'solid',
// 	style: {
// 		base: {
// 			// iconColor: '#c4f0ff',
// 			fontWeight: 500,
// 			fontSize: '16px',
// 			fontSmoothing: 'antialiased',
// 			':-webkit-autofill': {
// 				color: Colors.primary.main
// 			},
// 			'::placeholder': {
// 				// color: '#87BBFD',
// 				color: 'rgba(0, 0, 0, 0.87)'
// 			}
// 		},
// 		invalid: {
// 			iconColor: Colors.error.main,
// 			color: Colors.error.main
// 		}
// 	}
// }
