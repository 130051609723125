import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles'

const drawerExpandedWidth = 256
const drawerCollapseWidth = 57

export const useStudentStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex'
		},
		drawer: {
			width: drawerExpandedWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap'
		},
		drawerPaper: {
			width: drawerExpandedWidth,
			backgroundColor: theme.palette.primary.main,
			color: fade(theme.palette.common.white, 0.87),
			'& .MuiDivider-root': {
				backgroundColor: fade(theme.palette.primary.contrastText, 0.12)
			},
			'& .MuiListSubheader-root': {
				color: fade(theme.palette.primary.contrastText, 0.5)
			},
			'& .MuiListItem-root': {
				'& .MuiListItemText-root > *': {
					fontSize: 14
				},
				'&.MuiListItem-button:hover': {
					backgroundColor: fade(theme.palette.secondary.main, 0.08)
				},
				'&.Mui-selected, &.Mui-selected:hover': {
					backgroundColor: fade(theme.palette.secondary.main, 0.16),
					color: theme.palette.secondary.main,
					'& .MuiListItemIcon-root': {
						color: theme.palette.secondary.main
					}
				},
				'& .MuiListItemIcon-root': {
					minWidth: 41,
					color: fade(theme.palette.primary.contrastText, 0.5)
				},
				'& .MuiTouchRipple-root': {
					color: theme.palette.secondary.main
				}
			},
			'&::-webkit-scrollbar': {
				width: theme.spacing(1),
				height: theme.spacing(1),
				display: 'none'
			},
			'&:hover::-webkit-scrollbar': {
				display: 'block'
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: fade(theme.palette.common.white, 0.2),
				borderRadius: theme.spacing(1),
				'&:hover': {
					backgroundColor: fade(theme.palette.common.white, 0.25)
				},
				'&:active': {
					backgroundColor: fade(theme.palette.common.white, 0.3)
				}
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: fade(theme.palette.common.white, 0.1),
				borderRadius: theme.spacing(1)
			}
		},
		drawerOpen: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			overflowX: 'hidden',
			width: drawerCollapseWidth
		},
		menuButton: {
			position: 'absolute',
			right: 0,
			top: 0
		},
		chevronIcon: {
			position: 'fixed',
			top: 0,
			zIndex: 1,
			minWidth: 'auto',
			padding: 12,
			borderRadius: 36,
			borderBottomLeftRadius: 0,
			borderTopLeftRadius: 0,
			width: 48
		},
		widthExpanded: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerExpandedWidth}px)`
			}
		},
		widthCollapsed: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			[theme.breakpoints.up('sm')]: {
				width: `calc(100% - ${drawerCollapseWidth}px)`
			}
		},
		profileItemContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		profileItemLabel: {
			fontSize: 20
		},
		profileItemArrow: {
			fill: 'rgba(255, 255, 255, 0.54)'
		}
	})
)

export default useStudentStyles
