import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useMenuButtonStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonHolder: {
			cursor: 'pointer'
		},
		button: {
			backgroundColor: theme.palette.common.white,
			textTransform: 'uppercase',
			display: 'flex',
			alignItems: 'center'
		},
		label: {
			maxWidth: 100,
			fontSize: 12,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textAlign: 'left',
			display: 'block'
		},
		input: {
			'& input': {
				textTransform: 'uppercase'
			}
		}
	})
)

export default useMenuButtonStyles
