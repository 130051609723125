import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { useGetClassMaturityLevelQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan, Song, Tip, Maturity_Level_Enum } from 'src/graphql/autogenerate/schemas'
import { useClassIdParam } from 'src/scenes/Student/scenes/3-MusicCatalog/useClassIdParam'
import {
	buildLessonSearchParams,
	buildPagination,
	buildQueryVariables,
	buildSearchText,
	buildStudentSearchParams,
	MAX_SEARCH_ITEMS,
	TypeEnum
} from 'src/utils'

import { QuerySearchResultType, querySearchSong } from './queries'

let timer: number
export const useSearch = (
	teacherId: number | undefined,
	type: TypeEnum,
	textSearch: string,
	onChange: (value: string) => void,
	studentId?: number
): [
	(event: React.ChangeEvent<HTMLInputElement>) => void,
	(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
	() => void,
	string,
	React.RefObject<HTMLInputElement>,
	boolean,
	boolean,
	boolean,
	Song[] | Tip[] | Lesson_Plan[],
	() => void
] => {
	const [text, setText] = useState('')
	const [resultData, setResultData] = useState<Song[] | Tip[] | Lesson_Plan[]>([])
	const [loading, setLoading] = useState(false)
	const [showClean, setShowClean] = useState(false)
	const [showResults, setShowResults] = useState(false)
	const inputRef = React.createRef<HTMLInputElement>()
	const { classId } = useClassIdParam()
	const { data: classMaturityLevel } = useGetClassMaturityLevelQuery({ variables: { classId } })
	const [loadSearch, searchResult] = useLazyQuery<QuerySearchResultType>(
		querySearchSong(type, !!teacherId),
		buildPagination(teacherId, 0, studentId, MAX_SEARCH_ITEMS)
	)
	useEffect(() => {
		if (!searchResult.loading && searchResult.data && showResults) {
			const t = type as keyof QuerySearchResultType
			setResultData(searchResult?.data[t] || [])
		}
	}, [searchResult, showResults, type])

	useEffect(() => {
		if (textSearch !== '') {
			setText(textSearch)
			setShowClean(true)
			if (inputRef && inputRef.current) {
				inputRef.current.focus()
			}
		}
	}, [textSearch])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoading(true)
		const textEvent = (event.target as HTMLInputElement).value

		setShowClean(!!textEvent)
		setText(textEvent)
		clearTimeout(timer)
		timer = window.setTimeout(() => {
			if (textEvent !== '') {
				setShowResults(true)
				let query
				if (type === TypeEnum.Student) {
					query = buildQueryVariables({
						conditions: buildStudentSearchParams(textEvent)
					})
				} else if (type === TypeEnum.Video) {
					query = buildQueryVariables({
						conditions: buildSearchText(textEvent || '', ['title', 'artist.name'])
					})
				} else if (type === TypeEnum.Song) {
					query = buildQueryVariables({
						conditions: buildSearchText(textEvent || '', ['title', 'artist.name']),
						excludeMaturityLevel:
							classMaturityLevel?.class_by_pk?.maturity_level === Maturity_Level_Enum.General
								? [Maturity_Level_Enum.Mature]
								: []
					})
				} else if (type === TypeEnum.Lesson) {
					query = buildQueryVariables({
						conditions: buildLessonSearchParams(textEvent)
					})
				}

				loadSearch(studentId ? query : { ...query, variables: { ...query?.variables, studentId } })
				setLoading(false)
			}
		}, 1000)
	}

	const handleSearch = () => {
		setShowResults(false)
		onChange(text)
		clearTimeout(timer)
	}

	const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.keyCode === 13 || event.key === 'Enter') {
			event.preventDefault()
			handleSearch()
		}
	}
	const handleClean = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.value = ''
			inputRef.current.focus()
			setText('')
			// onChange('')
			setShowClean(false)
		}
	}

	return [
		handleChange,
		handleKeydown,
		handleClean,
		text,
		inputRef,
		showClean,
		showResults,
		loading,
		resultData,
		handleSearch
	]
}
