import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ImageActionOptionType, ImageActionCardType } from './ImageActionCard.types'

export const useStyles = makeStyles<
	Theme,
	Pick<ImageActionOptionType, 'imagePath'> & Pick<ImageActionCardType, 'cardHeight' | 'cardWidth' | 'isSelected'>
>((theme: Theme) => ({
	buttonOption: ({ cardWidth, cardHeight, imagePath, isSelected }) => ({
		cursor: 'pointer',
		marginTop: 15,
		height: cardHeight,
		width: cardWidth,
		color: isSelected ? theme.palette.common.black : undefined,
		position: 'relative',
		backgroundImage: `${
			isSelected ? 'linear-gradient( rgba(243, 171, 52, 0.24), rgba(243, 171, 52, 0.24) ),' : ''
		}url(${imagePath})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		'& .MuiButton-iconSizeLarge > *:first-child': {
			fontSize: 50
		},
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column'
		}
	})
}))

export default useStyles
