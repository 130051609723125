import { StringInstrumentChord } from 'src/components/Studio/Record/InstrumentNotesPanel/assets/chords/types'
import { InstrumentsGuideType } from 'src/components/Studio/Record/InstrumentNotesPanel/types'

export const NOTES_SIZE = {
	[InstrumentsGuideType.guitar]: 70,
	[InstrumentsGuideType.ukulele]: 40,
	[InstrumentsGuideType.piano]: 120,
	[InstrumentsGuideType.audio]: 0
}
export const DIAGRAM_SVG_FILL_COLOR = '#7a7a7a'
export const DIAGRAM_SVG_FILL_COLOR_HIGHLIGHT = '#F3AB34'
export const GUITAR_REMAPPED_CHORDS = {
	Db: 'C#',
	Cb: 'B',
	'D#': 'Eb',
	Gb: 'F#',
	Ab: 'G#',
	'A#': 'Bb',
	'F##': 'G'
}
export const guitarChords: Record<string, StringInstrumentChord> = {
	C: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			}
		]
	},
	Cm: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			}
		]
	},
	CMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	Cm7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			}
		]
	},
	C7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			}
		]
	},
	Cm7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	C6: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			}
		]
	},
	Csus4: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			}
		]
	},
	C5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Cdim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	Cdim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	'C#': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'C#m': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'C#Maj7': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'C#m7': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'C#7': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'C#m7b5': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	'C#6': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			}
		]
	},
	'C#sus4': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'C#5': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	'C#dim': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 2
			}
		]
	},
	'C#dim7': {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	D: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			}
		]
	},
	Dm: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	DMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	Dm7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	D7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			}
		]
	},
	Dm7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	D6: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			}
		]
	},
	Dsus4: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			}
		]
	},
	D5: {
		baseFret: 3,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Ddim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			}
		]
	},
	Ddim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			}
		]
	},
	Eb: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			}
		]
	},
	Ebm: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			}
		]
	},
	EbMaj7: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			}
		]
	},
	Ebm7: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			}
		]
	},
	Eb7: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			}
		]
	},
	Ebm7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			}
		]
	},
	Eb6: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			}
		]
	},
	Ebsus4: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 9,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			}
		]
	},
	Eb5: {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Ebdim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			}
		]
	},
	Ebdim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			}
		]
	},
	E: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	Em: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	EMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	Em7: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	E7: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	Em7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			}
		]
	},
	' E6': {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			},
			{
				state: 1
			}
		]
	},
	Esus4: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 1
			},
			{
				state: 1
			}
		]
	},
	E5: {
		baseFret: 0,
		strings: [
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Edim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			}
		]
	},
	Edim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			}
		]
	},
	F: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Fm: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	FMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Fm7: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	F7: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Fm7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			}
		]
	},
	F6: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Fsus4: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	F5: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Fdim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			}
		]
	},
	Fdim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			}
		]
	},
	'F#': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#m': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#Maj7': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#m7': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#7': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#m7b5': {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 4
				}
			}
		]
	},
	'F#6': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#sus4': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	'F#5': {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	'F#dim': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			}
		]
	},
	'F#dim7': {
		baseFret: 2,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			}
		]
	},
	G: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			}
		]
	},
	Gm: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			}
		]
	},
	GMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			}
		]
	},
	Gm7: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			}
		]
	},
	G7: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			}
		]
	},
	Gm7b5: {
		baseFret: 3,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			}
		]
	},
	G6: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 1
			}
		]
	},
	Gsus4: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			}
		]
	},
	G5: {
		baseFret: 0,
		strings: [
			{
				state: 0,
				position: {
					fret: 3,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Gdim: {
		baseFret: 3,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			}
		]
	},
	Gdim7: {
		baseFret: 3,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			}
		]
	},
	'G#': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#m': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#Maj7': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#m7': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#7': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#m7b5': {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 4
				}
			}
		]
	},
	'G#6': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#sus4': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			}
		]
	},
	'G#5': {
		baseFret: 2,
		strings: [
			{
				state: 0,
				position: {
					fret: 4,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	'G#dim': {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 4
				}
			}
		]
	},
	'G#dim7': {
		baseFret: 4,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 6,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 4
				}
			}
		]
	},
	A: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 1
			}
		]
	},
	Am: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			}
		]
	},
	AMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 1
			}
		]
	},
	Amin7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			}
		]
	},
	A7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 1
			}
		]
	},
	Am7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			}
		]
	},
	Am7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			},
			{
				state: 2
			}
		]
	},
	A6: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			}
		]
	},
	Asus4: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 1
			}
		]
	},
	A5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Adim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			},
			{
				state: 1
			}
		]
	},
	Adim7: {
		baseFret: 5,
		strings: [
			{
				state: 2
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 7,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 8,
					finger: 4
				}
			}
		]
	},
	Bb: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Bbm: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	BbMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Bbm7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Bb7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Bbm7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	Bb6: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			}
		]
	},
	Bbsus4: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			}
		]
	},
	Bb5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Bbdim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	Bbdim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 1
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	B: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	Bm: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	BMaj7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	Bm7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	B7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 3
				}
			},
			{
				state: 1
			},

			{
				state: 0,
				position: {
					fret: 2,
					finger: 4
				}
			}
		]
	},
	Bm7b5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	B6: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			}
		]
	},
	Bsus4: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 5,
					finger: 4
				}
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			}
		]
	},
	B5: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 4,
					finger: 4
				}
			},
			{
				state: 2
			},
			{
				state: 2
			}
		]
	},
	Bdim: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	},
	Bdim7: {
		baseFret: 0,
		strings: [
			{
				state: 2
			},
			{
				state: 0,
				position: {
					fret: 2,
					finger: 2
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 3
				}
			},
			{
				state: 0,
				position: {
					fret: 1,
					finger: 1
				}
			},
			{
				state: 0,
				position: {
					fret: 3,
					finger: 4
				}
			},
			{
				state: 2
			}
		]
	}
}

export const UKULELE_CHORDS: Record<string, StringInstrumentChord> = {
	C: {
		baseFret: 0,
		strings: [{ state: 1 }, { state: 1 }, { state: 1 }, { state: 0, position: { fret: 3, finger: 1 } }]
	},
	Cm: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } }
		]
	},
	C7: {
		baseFret: 0,
		strings: [{ state: 1 }, { state: 1 }, { state: 1 }, { state: 0, position: { fret: 1, finger: 1 } }]
	},
	Cm7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } }
		]
	},
	Cmaj7: {
		baseFret: 0,
		strings: [{ state: 1 }, { state: 1 }, { state: 1 }, { state: 0, position: { fret: 2, finger: 1 } }]
	},
	C6: {
		baseFret: 0,
		strings: [{ state: 1 }, { state: 1 }, { state: 1 }, { state: 1 }]
	},
	Cm7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Cdim: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } }
		]
	},
	Cdim7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Csus4: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } }
		]
	},
	C5: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 1 },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } }
		]
	},
	'C#': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	'C#m': {
		baseFret: 2,
		strings: [
			{ state: 0, position: { fret: 6, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } }
		]
	},
	'C#7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } }
		]
	},
	'C#m7': {
		baseFret: 2,
		strings: [
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } }
		]
	},
	'C#maj7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } }
		]
	},
	'C#6': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	'C#m7b5': {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	'C#dim': {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } }
		]
	},
	'C#dim7': {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 2 } }
		]
	},
	'C#sus4': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	'C#5': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 2 }
		]
	},
	D: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 1 }
		]
	},
	Dm: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 }
		]
	},
	D7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 1 }
		]
	},
	Dm7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Dmaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } }
		]
	},
	D6: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	Dm7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } }
		]
	},
	Ddim: {
		baseFret: 2,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 5, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 5, finger: 3 } }
		]
	},
	Ddim7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	Dsus4: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 1 }
		]
	},
	D5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 5, finger: 4 } },
			{ state: 2 }
		]
	},
	Eb: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 4 } }
		]
	},
	Ebm: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 4 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Eb7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 2 } }
		]
	},
	Ebm7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	Ebmaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 5, finger: 3 } }
		]
	},
	Eb6: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } }
		]
	},
	Ebm7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } }
		]
	},
	Ebdim: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 1 }
		]
	},
	Ebdim7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Ebsus4: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 2 } },
			{ state: 0, position: { fret: 6, finger: 4 } }
		]
	},
	Eb5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 4 } },
			{ state: 2 }
		]
	},
	E: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	Em: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	E7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	Em7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } }
		]
	},
	Emaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } }
		]
	},
	E6: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	Em7b5: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Edim: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Edim7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 2 } }
		]
	},
	Esus4: {
		baseFret: 2,
		strings: [
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 5, finger: 3 } },
			{ state: 0, position: { fret: 7, finger: 4 } }
		]
	},
	E5: {
		baseFret: 2,
		strings: [
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 4 } },
			{ state: 2 }
		]
	},
	F: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 }
		]
	},
	Fm: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	F7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Fm7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Fmaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 }
		]
	},
	F6: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Fm7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } }
		]
	},
	Fdim: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 5, finger: 4 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	Fdim7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	Fsus4: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	F5: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } }
		]
	},
	'F#': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	'F#m': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 1 }
		]
	},
	'F#7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	'F#m7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	'F#maj7': {
		baseFret: 4,
		strings: [
			{ state: 0, position: { fret: 6, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 1 } },
			{ state: 0, position: { fret: 8, finger: 3 } }
		]
	},
	'F#6': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	'F#m7b5': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } }
		]
	},
	'F#dim': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 2 }
		]
	},
	'F#dim7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	'F#sus4': {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	'F#5': {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	G: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } }
		]
	},
	Gm: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	G7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	Gm7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Gmaj7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	G6: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } }
		]
	},
	Gm7b5: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 3 } }
		]
	},
	Gdim: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Gdim7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 2 } }
		]
	},
	Gsus4: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	G5: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 2 }
		]
	},
	'G#': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 5, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 1 } }
		]
	},
	'G#m': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	'G#7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	'G#m7': {
		baseFret: 2,
		strings: [
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 4 } }
		]
	},
	'G#maj7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	'G#6': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	'G#m7b5': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	'G#dim': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	'G#dim7': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	'G#sus4': {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	'G#5': {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 2 }
		]
	},
	A: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 1 }
		]
	},
	Am: {
		baseFret: 0,
		strings: [{ state: 0, position: { fret: 2, finger: 1 } }, { state: 1 }, { state: 1 }, { state: 1 }]
	},
	A7: {
		baseFret: 0,
		strings: [{ state: 1 }, { state: 0, position: { fret: 1, finger: 1 } }, { state: 1 }, { state: 1 }]
	},
	Am7: {
		baseFret: 0,
		strings: [{ state: 1 }, { state: 1 }, { state: 1 }, { state: 1 }]
	},
	Amaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 1 },
			{ state: 1 }
		]
	},
	A6: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 4 } }
		]
	},
	Am7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Adim: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 5, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 1 } }
		]
	},
	Adim7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 3, finger: 4 } }
		]
	},
	Asus4: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 1 },
			{ state: 1 }
		]
	},
	A5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 5, finger: 4 } },
			{ state: 2 }
		]
	},
	Bb: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Bbm: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Bb7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Bbm7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Bbmaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 }
		]
	},
	Bb6: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Bbm7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 3 } }
		]
	},
	Bbdim: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 2 } }
		]
	},
	Bbdim7: {
		baseFret: 0,
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1, finger: 2 } }
		]
	},
	Bbsus4: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 4 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	Bb5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 3, finger: 1 } },
			{ state: 0, position: { fret: 5, finger: 3 } },
			{ state: 0, position: { fret: 6, finger: 4 } },
			{ state: 2 }
		]
	},
	B: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	Bm: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	B7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 3, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	Bm7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	Bmaj7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 3, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } }
		]
	},
	B6: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	Bm7b5: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	Bdim: {
		baseFret: 0,
		strings: [
			{ state: 2 },
			{ state: 0, position: { fret: 2, finger: 2 } },
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } }
		]
	},
	Bdim7: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 1, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 3 } },
			{ state: 0, position: { fret: 1, finger: 2 } },
			{ state: 0, position: { fret: 2, finger: 4 } }
		]
	},
	Bsus4: {
		baseFret: 0,
		strings: [
			{ state: 0, position: { fret: 4, finger: 3 } },
			{ state: 0, position: { fret: 4, finger: 4 } },
			{ state: 0, position: { fret: 2, finger: 1 } },
			{ state: 0, position: { fret: 2, finger: 1 } }
		]
	},
	B5: {
		baseFret: 2,
		strings: [
			{ state: 0, position: { fret: 4, finger: 1 } },
			{ state: 0, position: { fret: 6, finger: 3 } },
			{ state: 0, position: { fret: 7, finger: 4 } },
			{ state: 2 }
		]
	},
	'A#': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'D#dim': {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 1 }
		]
	},
	Csus: {
		strings: [
			{ state: 1 },
			{ state: 1 },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } }
		]
	},
	'D#m7': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	A9: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2 } }
		]
	},
	D7sus: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } }
		]
	},
	Baug: {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	B9: {
		strings: [
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	'F#9': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	B7sus: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	'G#7sus': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	'A#7sus': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	C9: {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 3 } }
		]
	},
	'D#sus': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'D#': {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	Fsus: {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	Asus: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 1 },
			{ state: 1 }
		]
	},
	'A#7': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'A#9': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } }
		]
	},
	'F#sus': {
		strings: [
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	G7sus: {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } }
		]
	},
	'D#7sus': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	Gsus: {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } }
		]
	},
	Caug: {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 1 },
			{ state: 1 },
			{ state: 0, position: { fret: 3 } }
		]
	},
	E9: {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	E7sus: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2 } }
		]
	},
	'C#7sus': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	'A#maj7': {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'A#sus': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'A#m': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'F#7sus': {
		strings: [
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	Dsus: {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 1 }
		]
	},
	F9: {
		strings: [{ state: 1 }, { state: 1 }, { state: 0, position: { fret: 1 } }, { state: 1 }]
	},
	'A#dim7': {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1 } }
		]
	},
	C7sus: {
		strings: [
			{ state: 1 },
			{ state: 1 },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'D#7': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	'D#9': {
		strings: [
			{ state: 1 },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	Aaug: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 1 }
		]
	},
	Esus: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 1 },
			{ state: 0, position: { fret: 2 } }
		]
	},
	Bsus: {
		strings: [
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	'C#sus': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	G9: {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } }
		]
	},
	'A#aug': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	A7sus: {
		strings: [{ state: 1 }, { state: 0, position: { fret: 2 } }, { state: 1 }, { state: 1 }]
	},
	'A#dim': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'D#m': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'G#sus': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	'A#m7': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'A#m7b5': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 1 },
			{ state: 0, position: { fret: 1 } }
		]
	},
	'D#m7b5': {
		strings: [
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 2 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	F7sus: {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } }
		]
	},
	'C#9': {
		strings: [
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 1 } },
			{ state: 0, position: { fret: 4 } }
		]
	},
	'G#9': {
		strings: [
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 3 } },
			{ state: 0, position: { fret: 4 } },
			{ state: 0, position: { fret: 3 } }
		]
	}
}
