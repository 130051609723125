import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useGetSongDataByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { ProductTitleEnum, buildRouteParameters, LIMIT } from 'src/utils'
export const useSongsWrapper = () => {
	const history = useHistory()
	const { classId } = useWurrlyParams()
	const [count, setCount] = useState(0)
	const [data, setData] = useState<Song[]>([])

	const type = ProductTitleEnum.Song

	const handleViewAll = () => {
		history.push(
			buildRouteParameters(Pages.AllClassProducts, {
				classId: Number(classId),
				type: ProductTitleEnum.Song
			})
		)
	}

	const [getSongs, { data: songsData, loading }] = useGetSongDataByClassIdLazyQuery()

	useEffect(() => {
		getSongs({
			variables: {
				limit: LIMIT,
				classId: Number(classId)
			}
		})
	}, [])
	useEffect(() => {
		if (songsData) {
			setCount(songsData.song_aggregate.aggregate?.count || 0)
			setData(songsData.song as Song[])
		}
	}, [songsData])

	return {
		loading,
		data,
		count,
		handleViewAll,
		type
	}
}
