import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { AddBySelect, AddByInviteIcon, ThinCheckIcon } from 'src/assets/icons'
import {
	AddStudentsPlatformSelectorDialog,
	IconActionOptionType,
	InfoDialog,
	InviteStudentsByBulkPrivateCampDialog,
	InviteStudentsByBulkStepperDialog,
	InviteStudentsOneByOneDialog,
	ListDialog,
	AddStudentsManualInviteDialog,
	InviteStudentsOneByOnePrivateCampDialog
} from 'src/components'
import { useGetStudentsByKeywordQuery, useInsertStudentsMutation } from 'src/graphql/autogenerate/hooks'
import { GetStudentsByKeywordQueryVariables } from 'src/graphql/autogenerate/operations'
import { Order_By, Student } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { AddDialogV2 } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassStudents/components/AddDialog/AddDialogV2'
import { buildStudentSearchParams, getCyKey } from 'src/utils'

type ControlTowerType = {
	classId: number
	classTitle: string
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	onSuccess?: () => void
}
export const ControlTower = ({ classId, isOpen, setIsOpen, classTitle, onSuccess }: ControlTowerType) => {
	const [studentsArr, setStudentsArr] = useState<({ selected: boolean } & Student)[]>([])
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher?.teacher_id
	const [selectedStudents, setSelectedStudents] = useState<Student[]>([])
	const [addStudentsToClass, setAddStudentsToClass] = useState(false)
	const [sort, setSort] = useState(Order_By.Asc)
	const [search, setSearch] = useState<string>()
	const [isStudentsManualInviteOpen, setIsStudentManualInviteOpen] = useState(false)
	const [isStudentsPlatformSelectorOpen, setIsStudentsPlatformSelectorOpen] = useState(false)
	const [isInviteStudentsOneByOneOpen, setIsInviteStudentsOneByOneOpen] = useState(false)
	const [isInviteStudentsOneByOnePrivateCampOpen, setIsInviteStudentsOneByOnePrivateCampOpen] = useState(false)
	const [isInviteStudentsByBulkOpen, setIsInviteStudentsByBulkOpen] = useState(false)
	const [isInviteStudentsByBulkPrivateCampOpen, setIsInviteStudentsByBulkPrivateCampOpen] = useState(false)
	const [successfulAddition, setSuccessfulAddition] = useState(false)
	const INITIAL_QUERY_VARIABLES = {
		teacherId,
		classId
	}
	const [studentsVariables, setStudentsVariables] = useState<GetStudentsByKeywordQueryVariables>({
		...INITIAL_QUERY_VARIABLES
	})
	const {
		data: studentsData,
		loading: loadingStudents,
		refetch: refetchStudentData
	} = useGetStudentsByKeywordQuery({
		variables: studentsVariables,
		fetchPolicy: 'no-cache'
	})
	const [insertStudents, { data: insertStudentData, loading: insertDataLoading }] = useInsertStudentsMutation()
	const count = studentsData?.student_aggregate.aggregate?.count || 0

	useEffect(() => {
		if (insertStudentData && !insertDataLoading) {
			refetchStudentData()
		}
	}, [insertStudentData])

	useEffect(() => {
		if (studentsData) {
			const studentsArr = studentsData.student.map((student) => {
				return {
					selected: false,
					...student,
					class_students: student?.class_students?.filter((class_student) => class_student.class)
				} as { selected: boolean } & Student
			})
			setStudentsArr(studentsArr)
		}
	}, [studentsData])

	useEffect(() => {
		if (addStudentsToClass) {
			try {
				setStudentsVariables({
					teacherId,
					conditions: buildStudentSearchParams(search || ''),
					classId: Number(classId),
					first_name: sort
				})
			} catch (e) {
				console.error(e)
			}
		}
	}, [search, sort])

	useEffect(() => {
		if (studentsArr.length > 0) {
			setStudentsArr(
				studentsArr.map((item) => {
					if (selectedStudents.some((it) => it.student_id === item.student_id)) {
						return { ...item, selected: true }
					} else {
						return { ...item, selected: false }
					}
				})
			)
		}
	}, [selectedStudents])

	useEffect(() => {
		if (successfulAddition) {
			if (onSuccess) {
				onSuccess()
			}
		}
	}, [successfulAddition])

	const onSaveStudents = async () => {
		const array = [...selectedStudents]
		const students = array.map((student) => {
			return {
				student_id: student.student_id,
				class_id: Number(classId)
			}
		})

		if (students && students.length > 0) {
			try {
				await insertStudents({
					variables: {
						students
					},
					update: (cache, { data }) => {
						const classtoUpdateInCache = data?.insert_class_student?.returning[0].class
						if (!classtoUpdateInCache) return
						const identify = cache.identify(classtoUpdateInCache)
						cache.evict({
							id: identify,
							fieldName: 'class_students'
						})
						cache.evict({
							id: identify,
							fieldName: 'class_students_aggregate'
						})
						cache.evict({
							id: 'ROOT_QUERY',
							fieldName: 'student'
						})
					}
				})
				setSuccessfulAddition(true)
				setStudentsArr([])
				setSelectedStudents([])
			} catch (e) {
				console.error(e)
			}
		}
	}

	return (
		<Box>
			<ListDialog
				open={isOpen}
				cols={2}
				cardHeight={160}
				onClose={() => setIsOpen(false)}
				title="Add Students"
				description=""
				discardLabel="Cancel"
				onDiscard={() => setIsOpen(false)}
				onDiscardProps={{
					style: {
						width: '100%'
					}
				}}
				options={
					[
						{
							id: 'Select',
							name: (
								<Box>
									<Typography
										data-cy={getCyKey(ControlTower, 'SelectBox')}
										style={{ fontSize: 24, paddingTop: 12 }}>
										Select
									</Typography>
									<Typography style={{ fontSize: 12 }}>
										Select students from your list
									</Typography>
								</Box>
							),
							icon: <img src={AddBySelect} />,
							cols: 2
						},
						{
							id: 'Invite',
							name: (
								<Box>
									<Typography
										data-cy={getCyKey(ControlTower, 'InviteBox')}
										style={{ fontSize: 24, paddingTop: 12 }}>
										Invite
									</Typography>
									<Typography style={{ fontSize: 12 }}>
										Invite students to join the app
									</Typography>
								</Box>
							),
							icon: <AddByInviteIcon />,
							cols: 2
						}
					] as IconActionOptionType[]
				}
				handleSelectOption={(opt: string) => {
					switch (opt) {
						case 'Select':
							setIsOpen(false)
							setAddStudentsToClass(true)
							break
						case 'Invite':
							setIsOpen(false)
							setIsStudentsPlatformSelectorOpen(true)
							break
					}
				}}
			/>
			<AddDialogV2
				open={addStudentsToClass}
				setOpen={setAddStudentsToClass}
				setSearch={setSearch}
				setSelectedStudents={setSelectedStudents}
				setSort={setSort}
				sort={sort}
				count={count}
				items={studentsArr}
				selectedItems={selectedStudents}
				loading={loadingStudents}
				onSave={onSaveStudents}
				isAddNewStudentsOpen={setIsStudentsPlatformSelectorOpen}
			/>

			<InfoDialog
				open={successfulAddition}
				onClose={() => {
					setSuccessfulAddition(false)
				}}
				icon={<ThinCheckIcon />}
				title={
					<Typography data-cy={getCyKey(ControlTower, 'Title')} variant="h4">
						<b>Students Added!</b>
					</Typography>
				}
				body="You have successfully added these Students to this Class."
				discardLabel={<b>Add More Students</b>}
				onDiscard={() => {
					setSuccessfulAddition(false)
					setAddStudentsToClass(true)
				}}
				confirmLabel="View Students"
				onConfirm={() => {
					setSuccessfulAddition(false)
				}}
			/>
			<AddStudentsPlatformSelectorDialog
				isOpen={isStudentsPlatformSelectorOpen}
				classTitle={classTitle}
				classId={classId}
				setIsOPen={setIsStudentsPlatformSelectorOpen}
				setIsManualInviteOpen={setIsStudentManualInviteOpen}
			/>

			<AddStudentsManualInviteDialog
				isOpen={isStudentsManualInviteOpen}
				setIsOpen={setIsStudentManualInviteOpen}
				setIsOneByOneInviteOpen={setIsInviteStudentsOneByOneOpen}
				setIsOneByOnePrivateCampInviteOpen={setIsInviteStudentsOneByOnePrivateCampOpen}
				setIsByBulkInviteOpen={setIsInviteStudentsByBulkOpen}
				setIsByBulkPrivateCampInviteOpen={setIsInviteStudentsByBulkPrivateCampOpen}
			/>

			<InviteStudentsOneByOneDialog
				classId={classId}
				isOpen={isInviteStudentsOneByOneOpen}
				setIsOpen={setIsInviteStudentsOneByOneOpen}
				setIsPreviousDialogOpen={setIsStudentManualInviteOpen}
			/>

			<InviteStudentsOneByOnePrivateCampDialog
				classId={classId}
				isOpen={isInviteStudentsOneByOnePrivateCampOpen}
				setIsOpen={setIsInviteStudentsOneByOnePrivateCampOpen}
				setIsManualInviteOpen={setIsStudentManualInviteOpen}
			/>

			<InviteStudentsByBulkStepperDialog
				classId={classId}
				isOpen={isInviteStudentsByBulkOpen}
				setIsOpen={setIsInviteStudentsByBulkOpen}
				setIsManualInviteOpen={setIsStudentManualInviteOpen}
			/>

			<InviteStudentsByBulkPrivateCampDialog
				isOpen={isInviteStudentsByBulkPrivateCampOpen}
				setIsOpen={setIsInviteStudentsByBulkPrivateCampOpen}
				classId={classId}
			/>
		</Box>
	)
}
