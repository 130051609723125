import { useEffect, ChangeEvent } from 'react'

import { useQuery } from '@apollo/client'
import { Box, Divider, Link, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import { useGetStandardLazyQuery } from 'src/graphql/autogenerate/hooks'
import { AutoCompleteStyle } from 'src/scenes/Teacher/scenes/6-Lessons/components/CustomAutocomplete'
import { useStepFourValidation } from 'src/scenes/Teacher/scenes/6-Lessons/hooks/useStepFourValidation'
import {
	extractMusicalSkills,
	extractNonMusicalSkills
} from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonEdit/useInitialLessonData'
import { DisciplineForm } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload/Discipline'
import { Standards } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/LessonUpload/Standards'
import { extractLevels, sortByName, sortGradeLevels } from 'src/utils'

import { StepFormType } from '.'
import {
	Discipline,
	Grade,
	Musical_Skill,
	Non_Musical_Skill,
	Standard
} from '../../../../../../graphql/autogenerate/schemas'
import {
	queryMusicalSkill,
	queryNonMusicalSkill,
	queryGrade,
	QueryMusicalSkillType,
	QueryGradeType,
	QueryNonMusicalSkillType
} from '../../../../queries'
import { useStyles } from './styles'

export const StepFourForm = ({
	saveData,
	lessonData,
	setStepIsValid,
	handleInitialData,
	lesson_plan_id
}: StepFormType) => {
	const [getStandard, { data: standards }] = useGetStandardLazyQuery()
	const classes = useStyles()

	const { stepFourValidationSchema } = useStepFourValidation()

	const formik = useFormik({
		initialValues: {
			discipline: [] as Discipline[],
			process: [] as number[],
			levels: [] as Grade[],
			musicalSkills: [] as Musical_Skill[],
			nonMusicalSkills: [] as Non_Musical_Skill[]
		},
		validationSchema: stepFourValidationSchema,
		validateOnMount: true,
		onSubmit: () => {}
	})

	const gradesCatalog = useQuery<QueryGradeType>(queryGrade)
	const musicalSkillsCatalog = useQuery<QueryMusicalSkillType>(queryMusicalSkill)
	const nonMusicalSkillsCatalog = useQuery<QueryNonMusicalSkillType>(queryNonMusicalSkill)

	const handleSelectedLevels = (_event: ChangeEvent<unknown>, levels: Grade[]) => {
		sortGradeLevels(levels)
		formik.setFieldValue('levels', levels)
	}

	useEffect(() => {
		// This one is used for persisting data across steps
		getStandard()
		const { discipline, process, levels, musicalSkills, nonMusicalSkills } = lessonData

		formik.setValues({
			discipline: discipline ?? [],
			process: process ?? [],
			levels: levels ?? [],
			musicalSkills: musicalSkills ?? [],
			nonMusicalSkills: nonMusicalSkills ?? []
		})
	}, [])

	useEffect(() => {
		// This one for filling values with initial data (when editing lesson)
		if (
			handleInitialData?.initialData &&
			!handleInitialData.stepHasFilledInitialData &&
			gradesCatalog.data?.grade &&
			musicalSkillsCatalog.data?.musical_skill &&
			nonMusicalSkillsCatalog.data?.non_musical_skill
		) {
			const { discipline, process, levels, musical_skills, non_musical_skills } =
				handleInitialData.initialData

			// setInitialProcessData(process)

			const extractedLevels = extractLevels({
				selected: levels,
				options: gradesCatalog.data.grade
			})

			const extractedMusicalSkills = extractMusicalSkills({
				selected: musical_skills,
				options: musicalSkillsCatalog.data?.musical_skill
			})

			const extractedNonMusicalSkills = extractNonMusicalSkills({
				selected: non_musical_skills,
				options: nonMusicalSkillsCatalog.data?.non_musical_skill
			})

			formik.setValues({
				discipline: discipline ?? {
					dance: false,
					music: true,
					theatre: false,
					media_arts: false,
					visual_arts: false
				},
				process: process ?? { creating: [], connecting: [], performing: [], responding: [] },
				levels: extractedLevels,
				musicalSkills: extractedMusicalSkills,
				nonMusicalSkills: extractedNonMusicalSkills
			})
			handleInitialData.setInitialDataUsed()
		}
	}, [handleInitialData, gradesCatalog.data, musicalSkillsCatalog.data, nonMusicalSkillsCatalog.data])

	useEffect(() => {
		saveData(formik.values)
	}, [formik.values])

	useEffect(() => {
		if (setStepIsValid) setStepIsValid(!formik.errors.levels)
	}, [formik.errors.levels])

	return (
		<Box marginTop="80px">
			<form onSubmit={formik.handleSubmit}>
				<Box marginTop="40px">
					<DisciplineForm
						changeData={formik.setFieldValue}
						lesson_plan_id={lesson_plan_id || 0}
						disciplines={formik.values.discipline}
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Grade Level*</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-level',
							className: classes.chip,
							deleteIcon: (
								<Typography data-cy="remove-levels-selected" className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						multiple
						disableCloseOnSelect
						autoHighlight
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
						options={gradesCatalog.data?.grade || []}
						value={formik.values.levels}
						onChange={handleSelectedLevels}
						closeIcon={<CloseIcon data-cy="levels-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-lesson-levels"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>

				<Box mt={5} mb={1}>
					<Divider />
				</Box>

				<Box marginTop="40px">
					<Box marginBottom="20px">
						<Typography className={classes.fieldTitle}>Process</Typography>
						<Typography className={classes.textGray}>
							Please select the relevant standards being satisfied by the Lesson. The National Core
							Arts Standards can be referenced{' '}
							<Link color="secondary" target="_blank" href="https://www.nationalartsstandards.org/">
								HERE
							</Link>
						</Typography>
					</Box>
					{standards &&
						standards.standard.map((standard) => {
							return (
								<Standards
									key={standard.name}
									standard={standard as Standard}
									changeData={formik.setFieldValue}
									initialValues={formik.values.process as number[]}
								/>
							)
						})}
				</Box>

				<Box mt={5} mb={1}>
					<Divider />
				</Box>

				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Musical Skills</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-musical-skills',
							className: classes.chip,
							deleteIcon: (
								<Typography
									data-cy="remove-musical-skills-selected"
									className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
						multiple
						options={musicalSkillsCatalog.data?.musical_skill || []}
						value={formik.values.musicalSkills}
						onChange={(_, value) => {
							formik.setFieldValue('musicalSkills', sortByName(value))
						}}
						closeIcon={<CloseIcon data-cy="musical-skills-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-lesson-musical-skills"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>
				<Box marginTop="40px">
					<Box display="flex" justifyContent="space-between">
						<Typography className={classes.fieldTitle}>Transferable Skills</Typography>
					</Box>
					<Autocomplete
						ChipProps={{
							'data-cy': 'selected-non-musical-skills',
							className: classes.chip,
							deleteIcon: (
								<Typography
									data-cy="remove-non-musical-skills-selected"
									className={classes.closeChipIcon}>
									x
								</Typography>
							)
						}}
						autoHighlight
						ListboxProps={{
							className: classes.listBox,
							style: AutoCompleteStyle
						}}
						multiple
						options={nonMusicalSkillsCatalog.data?.non_musical_skill || []}
						value={formik.values.nonMusicalSkills}
						onChange={(_, value) => {
							formik.setFieldValue('nonMusicalSkills', sortByName(value))
						}}
						closeIcon={<CloseIcon data-cy="non-musical-skills-clean" />}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								data-cy="upload-lesson-non-musical-skills"
								variant="outlined"
								color="secondary"
								placeholder="Select all that apply"
							/>
						)}
					/>
				</Box>
			</form>
		</Box>
	)
}
