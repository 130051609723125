import { Box, Slider, styled, Typography } from '@material-ui/core'
import { FullBandIcon, MicrophoneIconOutlined } from 'src/assets/icons'
import { useStudioReviewContext } from 'src/components/Studio/Review/useStudioReview'

export const Balance = () => {
	const { audioBalance, setAudioBalance } = useStudioReviewContext()

	const handleNewValue = (_event: unknown, value: number | number[]) => {
		if (typeof value === 'number') {
			setAudioBalance(value)
		}
	}

	return (
		<BalanceContainer>
			<IconTitle>
				<MicrophoneIconOutlined />
				<Typography>Voice</Typography>
			</IconTitle>
			<StyledSlider value={audioBalance} onChange={handleNewValue} min={-100} max={100} track={false} />
			<IconTitle>
				<FullBandIcon />
				<Typography>Music</Typography>
			</IconTitle>
		</BalanceContainer>
	)
}

const BalanceStrokeColor = '#353553'

const BalanceContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
	width: '100%'
})

const IconTitle = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	color: BalanceStrokeColor,
	'& svg': {
		height: '37px',
		marginBottom: '14px'
	}
})

const StyledSlider = styled(Slider)({
	width: '200px',
	margin: '0 20px',
	'& .MuiSlider-rail': {
		backgroundColor: '#1D273D',
		opacity: 1
	},
	'& .MuiSlider-thumb': {
		backgroundColor: '#F3AB34'
	}
})
