import React, { Fragment, useEffect, useState } from 'react'

import { Box, CircularProgress, Link, makeStyles, Tooltip, Typography } from '@material-ui/core'
import csv from 'csvtojson/v2'
import { cache } from 'src/apollo/state'
import { PersonIcon, ThinCheckIcon } from 'src/assets/icons'
import {
	useAddStudentsSendEmailsMutation,
	useAddStudentsSendParentConsentEmailsMutation,
	useGetStudentQRsPdfLazyQuery,
	useValidateStudentsInClassLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { StudentData, StudentFromCsv, StudentsUnder12Data } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import {
	ADD_STUDENTS_HEADER,
	BASE_URL,
	getCyKey,
	buildImagePath,
	generateUniqueId,
	STUDENTS_UNDER_12_HEADER,
	APP_PRIVACY_TERMS
} from 'src/utils'

import {
	AddStudentsTableRow,
	AddStudentsUnder12TableRow,
	BaseDialog,
	BaseLink,
	BaseTable,
	ContactDialog,
	IconActionOptionType,
	InfoDialog,
	Stepper,
	StudentsFromCSV
} from '..'
import { BigBaseButton } from '../Buttons/BigBaseButton'
import { DragDrop } from '../DragDrop'
import { AddStudentsEnhancedTableHeader } from '../Tables/AddStudentsEnhancedTableHeader'
import { AddStudentsUnder12EnhancedTableHeader } from '../Tables/StudentsUnder12/AddStudentsUnder12EnhancedTableHeader'
import { ListDialog } from './ListDialog'

const useStyles = makeStyles(() => ({
	paragraphs: {
		paddingTop: 12,
		paddingBottom: 12,
		paddingRight: 10,
		paddingLeft: 10,
		fontSize: 18,
		fontWeight: 'normal'
	}
}))
enum AddStudentsOptsEnum {
	lowerEqTwelve = 'lowerEqTwelve',
	greaterEqThirteen = 'greaterEqThirteen',
	mix = 'mix'
}
type InviteStudentsByBulkPrivateCampDialogType = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	classId: number
}
export const InviteStudentsByBulkPrivateCampDialog = ({
	isOpen,
	setIsOpen,
	classId
}: InviteStudentsByBulkPrivateCampDialogType) => {
	const styles = useStyles()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [optionSelected, setOptionSelected] = useState('')
	const [isContactUsOpen, setIsContactUsOpen] = useState(false)
	const [isUnder12AcceptedFiles, setIsUnder12AcceptedFiles] = useState(false)
	const [isOver13AcceptedFiles, setOver13AcceptedFiles] = useState(false)
	const [isParentalConsentOpen, setIsParentalConsentOpen] = useState(false)
	const [isParentalConsentSetupOpen, setIsParentalConsentSetupOpen] = useState(false)
	const [isFinalStepOpen, setIsFinalStepOpen] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	const [isStudentInvitedDialogOpen, setIsStudentInvitedDialogOpen] = useState(false)
	const [isParentConsentDialogOpen, setIsParentConsentDialogOpen] = useState(false)
	const [isQRCodesDownloadedDialogOpen, setIsQRCodesDownloadedDialogOpen] = useState(false)
	const [isGoBackDataLostWarningOpen, setIsGoBackDataLostWarningOpen] = useState(false)
	const [isStudentsWithoutEmailOpen, setIsStudentsWithoutEmailOpen] = useState(false)
	const [studentsUnder12Arr, setStudentsUnder12Arr] = useState<StudentsFromCSV[]>([])
	const [studentsOver13Arr, setStudentsOver13Arr] = useState<StudentsFromCSV[]>([])
	const [studentsOver13PivotArr, setStudentsOver13PivotArr] = useState<StudentData[]>([])
	const [selectedStudentsOver13Arr, setSelectedStudentsOver13Arr] = useState<StudentsFromCSV[]>([])
	const [selectedStudentsUnder12Arr, setSelectedStudentsUnder12Arr] = useState<StudentsFromCSV[]>([])
	const addStudentsOpts: IconActionOptionType[] = [
		{
			id: AddStudentsOptsEnum.lowerEqTwelve,
			name: (
				<Box>
					<Typography style={{ fontSize: 26 }}>
						<b>12</b>
					</Typography>
					<Typography style={{ fontSize: 12 }}>
						<b>or younger</b>
					</Typography>
				</Box>
			),
			icon: <PersonIcon />
		},
		{
			id: AddStudentsOptsEnum.greaterEqThirteen,
			name: (
				<Box>
					<Typography style={{ fontSize: 26 }}>
						<b>13</b>
					</Typography>
					<Typography style={{ fontSize: 12 }}>
						<b>or older</b>
					</Typography>
				</Box>
			),
			icon: <PersonIcon />
		},
		{
			id: AddStudentsOptsEnum.mix,
			name: (
				<Box>
					<Typography style={{ fontSize: 26 }}>
						<b>Mix</b>
					</Typography>
					<Typography style={{ fontSize: 12 }}>
						<b>of both</b>
					</Typography>
				</Box>
			),
			icon: <PersonIcon />
		}
	]

	const [
		addStudentsSendEmailsMutation,
		{
			data: addStudentsSendEmailsData,
			loading: addStudentsSendEmailsLoading,
			error: addStudentsSendEmailsError
		}
	] = useAddStudentsSendEmailsMutation()

	const [
		getStudentQRsPdfLazyQuery,
		{ loading: studentsQRsPDFLoading, data: studentsQRsPDFData, error: studentsQRsPDFError }
	] = useGetStudentQRsPdfLazyQuery({ fetchPolicy: 'no-cache' })

	const [
		validateStudentsInClassQuery,
		{
			data: validateStudentsInClassData,
			loading: validateStudentsInClassLoading,
			error: validateStudentsInClassError
		}
	] = useValidateStudentsInClassLazyQuery({ fetchPolicy: 'no-cache' })

	const [
		addStudentsSendParentConsentEmailsMutation,
		{
			data: StudentsSendParentConsentEmailsData,
			loading: StudentsSendParentConsentEmailsLoading,
			error: StudentsSendParentConsentEmailsError
		}
	] = useAddStudentsSendParentConsentEmailsMutation()

	useEffect(() => {
		return cleanUploadedFile()
	}, [])

	useEffect(() => {
		if (
			validateStudentsInClassError ||
			addStudentsSendEmailsError ||
			studentsQRsPDFError ||
			StudentsSendParentConsentEmailsError
		)
			setIsOnErrorOpen(true)
	}, [
		validateStudentsInClassError,
		addStudentsSendEmailsError,
		studentsQRsPDFError,
		StudentsSendParentConsentEmailsError
	])

	useEffect(() => {
		if (!validateStudentsInClassLoading && validateStudentsInClassData) {
			const studentsNewInClass = [] as StudentsFromCSV[]
			const studentsAlreadyInClass = [] as StudentsFromCSV[]
			if (
				validateStudentsInClassData.validateStudentsInClass &&
				validateStudentsInClassData.validateStudentsInClass.studentsNewInClass
			)
				validateStudentsInClassData.validateStudentsInClass.studentsNewInClass.forEach((student) => {
					if (student)
						studentsNewInClass.push({
							id: generateUniqueId(),
							isSelected: false,
							firstName: student.first_name,
							lastName: student.last_name,
							email: student.email,
							code: student.code
						} as StudentsFromCSV)
				})
			if (
				validateStudentsInClassData.validateStudentsInClass &&
				validateStudentsInClassData.validateStudentsInClass.studentsAlreadyInClass
			)
				validateStudentsInClassData.validateStudentsInClass.studentsAlreadyInClass.forEach((student) => {
					if (student) {
						const studentPivot = studentsOver13PivotArr.find(
							(studentPivot) => studentPivot.email === student.email
						)
						studentsAlreadyInClass.push({
							id: generateUniqueId(),
							isSelected: false,
							firstName: studentPivot?.first_name || student.first_name,
							lastName: studentPivot?.last_name || student.last_name,
							email: student.email,
							code: student.code
						} as StudentsFromCSV)
					}
				})
			setStudentsOver13Arr([...studentsNewInClass, ...studentsAlreadyInClass].sort())
		}
	}, [validateStudentsInClassData])
	useEffect(() => {
		if (
			studentsQRsPDFData &&
			studentsQRsPDFData.addStudentsByBulkQRs &&
			studentsQRsPDFData.addStudentsByBulkQRs.path
		) {
			const link = document.createElement('a')
			link.href = `${BASE_URL}${studentsQRsPDFData.addStudentsByBulkQRs.path}`
			link.target = '_blank'
			link.download = 'student_QRs.pdf'
			// Append to html link element page
			document.body.appendChild(link)
			// Start download
			link.click()
			// Clean up and remove the link
			link.parentNode?.removeChild(link)
			setIsQRCodesDownloadedDialogOpen(true)
			setIsFinalStepOpen(false)
			cache.evict({
				id: `class:${classId}`,
				fieldName: 'class_students_aggregate'
			})
		}
	}, [studentsQRsPDFData])

	useEffect(() => {
		if (studentsOver13Arr.length > 0) {
			setStudentsOver13Arr(
				studentsOver13Arr.map((student) => {
					if (selectedStudentsOver13Arr.some((st) => st.id === student.id)) {
						return { ...student, isSelected: true } as StudentsFromCSV
					} else {
						return { ...student, isSelected: false } as StudentsFromCSV
					}
				})
			)
		}
	}, [selectedStudentsOver13Arr])

	useEffect(() => {
		if (studentsUnder12Arr.length > 0) {
			setStudentsUnder12Arr(
				studentsUnder12Arr.map((student) => {
					if (selectedStudentsUnder12Arr.some((st) => st.id === student.id)) {
						return { ...student, isSelected: true } as StudentsFromCSV
					} else {
						return { ...student, isSelected: false } as StudentsFromCSV
					}
				})
			)
		}
	}, [selectedStudentsUnder12Arr])

	useEffect(() => {
		if (addStudentsSendEmailsData) {
			setIsFinalStepOpen(false)
			setIsStudentInvitedDialogOpen(true)
		}
	}, [addStudentsSendEmailsData])

	useEffect(() => {
		if (StudentsSendParentConsentEmailsData) {
			setIsFinalStepOpen(false)
			setIsParentConsentDialogOpen(true)
		}
	}, [StudentsSendParentConsentEmailsData])

	const handleStudentQRsPDFDownload = () => {
		if (selectedStudentsOver13Arr.length) {
			const students = selectedStudentsOver13Arr.map(mapStudentData)
			getStudentQRsPdfLazyQuery({
				variables: {
					filter: {
						students,
						classId
					}
				}
			})
		}
	}

	const handleStudentSendMultipleEmails = () => {
		if (selectedStudentsOver13Arr.length) {
			const studentsWithoutEmail = selectedStudentsOver13Arr.filter((student) => !student.email)
			if (studentsWithoutEmail.length) {
				setIsStudentsWithoutEmailOpen(true)

				return
			}
			try {
				const students = selectedStudentsOver13Arr.map(mapStudentData)
				addStudentsSendEmailsMutation({
					variables: {
						filter: {
							students,
							classId,
							teacherName: `${teacher.name_first} ${teacher.name_last}`
						}
					}
				})
			} catch (e) {
				console.error(e)
			}
		}
	}
	const handleSendConsent = () => {
		if (selectedStudentsUnder12Arr.length) {
			const students = selectedStudentsUnder12Arr.map((student) => {
				return {
					first_name: student.firstName,
					last_name: student.lastName,
					parentEmail: student.parentEmail
				} as StudentsUnder12Data
			})
			addStudentsSendParentConsentEmailsMutation({
				variables: {
					set: {
						classId,
						teacherId,
						students
					}
				}
			})
		}
	}

	const isValid = () => {
		switch (optionSelected) {
			case AddStudentsOptsEnum.lowerEqTwelve:
				return isUnder12AcceptedFiles
			case AddStudentsOptsEnum.greaterEqThirteen:
				return isOver13AcceptedFiles
			case AddStudentsOptsEnum.mix:
				return isUnder12AcceptedFiles && isOver13AcceptedFiles
			default:
				return false
		}
	}

	const cleanUploadedFile = () => {
		setStudentsUnder12Arr([])
		setStudentsOver13Arr([])
		setSelectedStudentsUnder12Arr([])
		setSelectedStudentsOver13Arr([])
		setIsUnder12AcceptedFiles(false)
		setOver13AcceptedFiles(false)
		setStudentsOver13PivotArr([])
	}

	const onAcceptUnder12 = async (reader: FileReader) => {
		const text = reader.result as string
		const jsonArray = (await csv().fromString(text)).map((row) => {
			return {
				id: generateUniqueId(),
				isSelected: false,
				firstName: `${row['Student First Name']}`,
				lastName: `${row['Student Last Name']}`,
				parentEmail: row['Parent Email Address']
			} as StudentsFromCSV
		}) as StudentsFromCSV[]
		setStudentsUnder12Arr(jsonArray)
	}

	const onDeleteUnder12 = (student: StudentsFromCSV) => {
		const studentArr = [...studentsUnder12Arr]
		const studentArrIndex = studentArr.findIndex((st) => st.id === student.id)
		studentArr.splice(studentArrIndex, 1)
		setStudentsUnder12Arr(studentArr)

		const selectedArr = [...selectedStudentsUnder12Arr]
		const selectedArrIndex = selectedArr.findIndex((st) => st.id === student.id)
		selectedArr.splice(selectedArrIndex, 1)
		setSelectedStudentsUnder12Arr(selectedArr)
	}

	const onAcceptOver13 = async (reader: FileReader) => {
		const text = reader.result as string
		const jsonArray = (await csv().fromString(text)).map((row) => {
			return {
				first_name: row['Student First Name'],
				last_name: row['Student Last Name'],
				email: row['Student Email Address']
			} as StudentData
		}) as StudentData[]
		setStudentsOver13PivotArr(jsonArray)
		try {
			validateStudentsInClassQuery({
				variables: {
					students: {
						classId,
						students: jsonArray
					}
				}
			})
		} catch (e) {
			console.error(e)
		}
	}

	const onDeleteOver13 = (student: StudentsFromCSV) => {
		const studentArr = [...studentsOver13Arr]
		const studentArrIndex = studentArr.findIndex((st) => st.id === student.id)
		studentArr.splice(studentArrIndex, 1)
		setStudentsOver13Arr(studentArr)

		const selectedArr = [...selectedStudentsOver13Arr]
		const selectedArrIndex = selectedArr.findIndex((st) => st.id === student.id)
		selectedArr.splice(selectedArrIndex, 1)
		setSelectedStudentsOver13Arr(selectedArr)
	}

	const handleSelectAllOver13Click = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedStudentsOver13Arr(studentsOver13Arr)

			return
		}
		setSelectedStudentsOver13Arr([])
	}

	const handleSelectAllUnder12Click = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedStudentsUnder12Arr(studentsUnder12Arr)

			return
		}
		setSelectedStudentsUnder12Arr([])
	}

	const mapStudentData = (student: StudentsFromCSV) => {
		return {
			firstName: student.firstName,
			lastName: student.lastName,
			code: student.code,
			email: student.email
		} as StudentFromCsv
	}

	const closeContactDialog = () => setIsContactUsOpen(false)

	return (
		<Box>
			<ListDialog
				open={isOpen}
				cols={2}
				onClose={() => {}}
				stepper={
					<Stepper stepDescription="Method" currentStep={1} totalSteps={3} stepBoxProps={{ mb: 4 }} />
				}
				title="Add Students"
				description="What is the age group of the Student's that you'll be inviting?"
				discardLabel="Cancel"
				onDiscard={() => setIsOpen(false)}
				onDiscardProps={{
					style: {
						width: '100%'
					}
				}}
				options={addStudentsOpts}
				handleSelectOption={(opt: string) => {
					setOptionSelected(opt)
					switch (opt) {
						case AddStudentsOptsEnum.greaterEqThirteen:
							setIsParentalConsentSetupOpen(true)
							setIsOpen(false)
							break
						case AddStudentsOptsEnum.lowerEqTwelve:
						case AddStudentsOptsEnum.mix:
							setIsParentalConsentOpen(true)
							setIsOpen(false)
							break
						default:
							break
					}
				}}
			/>
			<BaseDialog
				dividers={false}
				open={isParentalConsentOpen}
				onClose={() => {}}
				header={
					<Fragment>
						<Stepper
							stepDescription="Invite"
							currentStep={1}
							totalSteps={3}
							stepBoxProps={{ mb: 4 }}
						/>
						<Typography
							data-cy={getCyKey(InviteStudentsByBulkPrivateCampDialog, 'Title')}
							variant="h4"
							align="center"
							style={{ fontWeight: 'bold' }}>
							A Few Things for your Students 12 and under
						</Typography>
					</Fragment>
				}
				body={
					<Box mt={3} display="flex" flexDirection="column">
						<Typography variant="h6" align="center">
							Privacy is important to us, so we want to ensure that we have the necessary permission
							to collect, store and share a user's personal in accordance with our{' '}
							<Link color="secondary" target="_blank" href={APP_PRIVACY_TERMS}>
								<b>Privacy Policy.</b>
							</Link>{' '}
							The {<b>Child Online Privary and Protection Act</b>} requires verified parental consent
							for any use by children under the age of 13 years.
						</Typography>
						<br />
						<Typography variant="h6" align="center">
							As such, for children under the age of 13, we will ask you for their parent's email so
							we can send a unique link to verify their consent. If we do not receive parental
							consent within 14 days, we will delete all personal information from our records.
						</Typography>
					</Box>
				}
				discardLabel="Go Back"
				onDiscard={(e) => {
					e.stopPropagation()
					setIsParentalConsentOpen(false)
					setIsOpen(true)
				}}
				onDiscardProps={{
					style: {
						display: 'flex',
						flexGrow: 1,
						marginRight: '2%'
					}
				}}
				confirmLabel="I Understand and Accept"
				onConfirm={() => {
					setIsParentalConsentOpen(false)
					setIsParentalConsentSetupOpen(true)
				}}
			/>
			{/* SECOND STEP */}
			<BaseDialog
				dividers={AddStudentsOptsEnum.mix === optionSelected}
				maxWidth="md"
				PaperProps={{
					style: {
						display: 'block',
						width: '660px'
						// height: '730px'
					}
				}}
				open={isParentalConsentSetupOpen}
				onClose={() => {}}
				header={
					<Fragment>
						<Stepper
							stepDescription="Invite"
							currentStep={2}
							totalSteps={3}
							stepBoxProps={{ mb: 4 }}
						/>
						<Typography
							variant="h4"
							align="center"
							style={{ fontWeight: 'bold', marginBottom: '10px' }}>
							Parental Consent Student Setup
						</Typography>
						{(AddStudentsOptsEnum.lowerEqTwelve === optionSelected ||
							AddStudentsOptsEnum.mix === optionSelected) && (
							<Box>
								<Typography align="left" variant="body2" className={styles.paragraphs}>
									{<b>For Students Under 12 - </b>} Please upload your CSV document in the
									following format: Parent Name, Parent Email.
								</Typography>
								<Typography align="left" variant="body2" className={styles.paragraphs}>
									They will be invited to set up their child's account. You can link them to a
									classroom once they are set up or by sharing the Class Code.
								</Typography>
								<DragDrop
									accept="text/csv"
									onAccept={(reader) => {
										setIsUnder12AcceptedFiles(true)
										onAcceptUnder12(reader)
									}}
									onReject={() => setIsUnder12AcceptedFiles(false)}
									fileTypeName="CSV"
								/>
								<Box mt={2} mb={2} display="flex" justifyContent="center">
									<Typography>
										<b>No CSV File? </b>
										<BaseLink href={buildImagePath('resources/StudentsUnder12.csv')} download>
											<b>Download Template</b>
										</BaseLink>
									</Typography>
								</Box>
							</Box>
						)}
					</Fragment>
				}
				body={
					(AddStudentsOptsEnum.greaterEqThirteen === optionSelected ||
						AddStudentsOptsEnum.mix === optionSelected) && (
						<Box mt={2}>
							<Typography align="left" variant="body2" className={styles.paragraphs}>
								{<b>For Students 13 and Older - </b>} Please upload your CSV document in the
								following format: Student Name, Student Email.
							</Typography>
							<DragDrop
								accept="text/csv"
								onAccept={(reader) => {
									setOver13AcceptedFiles(true)
									onAcceptOver13(reader)
								}}
								onReject={() => setOver13AcceptedFiles(false)}
								fileTypeName="CSV"
							/>
							<Box mt={2} mb={2} display="flex" justifyContent="center">
								<Typography>
									<b>No CSV File? </b>
									<BaseLink href={buildImagePath('resources/StudentsOver13.csv')} download>
										<b>Download Template</b>
									</BaseLink>
								</Typography>
							</Box>
						</Box>
					)
				}
				isConfirmDisabled={
					!isValid() ||
					!!addStudentsSendEmailsError ||
					!!validateStudentsInClassError ||
					!!studentsQRsPDFError ||
					!!StudentsSendParentConsentEmailsError
				}
				confirmLabel="Confirm"
				onConfirm={(e) => {
					e.stopPropagation()
					// setIsOpen(false)
					// setIsSecondStepOpen(true)
					setIsParentalConsentSetupOpen(false)
					setIsFinalStepOpen(true)
				}}
				onConfirmProps={{
					style: {
						width: 200
					}
				}}
				onDiscard={(e) => {
					e.stopPropagation()
					if (isUnder12AcceptedFiles || isOver13AcceptedFiles) {
						// setIsParentalConsentSetupOpen(false)
						setIsGoBackDataLostWarningOpen(true)
					} else {
						setIsParentalConsentSetupOpen(false)
						setIsOpen(true)
					}

					// acceptedFiles.pop() // to clean the array
					// setIsManualInviteOpen(true)
					// setStudentsArrFromCSV([])
				}}
				discardLabel="Go Back"
				onDiscardProps={{
					style: {
						width: 200
					}
				}}
				footer={
					<Typography>
						<b>Already have parental consent? </b>
						<BaseLink
							download
							onClick={() => {
								setIsParentalConsentSetupOpen(false)
								setIsContactUsOpen(true)
							}}>
							<b>Contact Us to get set up!</b>
						</BaseLink>
					</Typography>
				}
			/>
			{/* FINAL STEP */}
			<BaseDialog
				dividers={false}
				maxWidth="md"
				PaperProps={{
					style: {
						display: 'block',
						width: '850px'
					}
				}}
				open={isFinalStepOpen}
				onClose={() => {}}
				header={
					<Box mb={3}>
						<Stepper
							stepDescription="Finalise"
							currentStep={3}
							totalSteps={3}
							stepBoxProps={{ mb: 4 }}
						/>
						<Typography variant="h4" align="center" style={{ fontWeight: 'bold' }}>
							Happy With These Invites?
						</Typography>
						<Typography align="center" variant="body2" className={styles.paragraphs}>
							Please confirm the details below to invite these students. You can edit & upload a new
							CSV if something is incorrect.
						</Typography>
					</Box>
				}
				body={
					<Box display="flex" flexDirection="column">
						{(AddStudentsOptsEnum.lowerEqTwelve === optionSelected ||
							AddStudentsOptsEnum.mix === optionSelected) && (
							<Box display="flex" flexDirection="column">
								<Box width="100%" display="flex">
									<Typography variant="h5">
										<b>Under 12</b>
									</Typography>
								</Box>
								<BaseTable
									header={
										<AddStudentsUnder12EnhancedTableHeader
											headerCells={STUDENTS_UNDER_12_HEADER}
											onSelectAllClick={handleSelectAllUnder12Click}
											rowCount={studentsUnder12Arr.length}
											selectedCount={selectedStudentsUnder12Arr.length}
										/>
									}
									rows={studentsUnder12Arr}
									renderRow={(student) => (
										<AddStudentsUnder12TableRow
											student={student}
											selectedStudents={selectedStudentsUnder12Arr}
											setSelectedStudents={setSelectedStudentsUnder12Arr}
											onDelete={onDeleteUnder12}
										/>
									)}
									style={{
										maxHeight: '250px'
									}}
								/>
								<Box mt={2} mb={2} width="100%" display="flex" flexDirection="row-reverse">
									<Tooltip
										title="Select at least one Student"
										placement="bottom"
										disableHoverListener={!!selectedStudentsUnder12Arr.length}>
										<span>
											<BigBaseButton
												style={{ padding: '6px 50px', width: '220px' }}
												color="secondary"
												disabled={selectedStudentsUnder12Arr.length <= 0}
												onClick={(e) => {
													e.stopPropagation()
													handleSendConsent()
												}}
												startIcon={
													StudentsSendParentConsentEmailsLoading ? (
														<CircularProgress color="secondary" size={20} />
													) : undefined
												}>
												<Typography variant="body1">Send Consent</Typography>
											</BigBaseButton>
										</span>
									</Tooltip>
									<BigBaseButton
										style={{ padding: '6px 50px', width: '220px', marginRight: '30px' }}
										variant="contained"
										color="default"
										onClick={(e) => {
											e.stopPropagation()
											setIsFinalStepOpen(false)
										}}>
										<Typography variant="body1">Done</Typography>
									</BigBaseButton>
								</Box>
							</Box>
						)}
						{(AddStudentsOptsEnum.greaterEqThirteen === optionSelected ||
							AddStudentsOptsEnum.mix === optionSelected) && (
							<Box display="flex" flexDirection="column">
								<Box width="100%" display="flex">
									<Typography variant="h5">
										<b>13 And Older</b>
									</Typography>
								</Box>
								<BaseTable
									header={
										<AddStudentsEnhancedTableHeader
											headerCells={ADD_STUDENTS_HEADER}
											onSelectAllClick={handleSelectAllOver13Click}
											rowCount={studentsOver13Arr.length}
											selectedCount={selectedStudentsOver13Arr.length}
										/>
									}
									rows={studentsOver13Arr}
									renderRow={(student: StudentsFromCSV) => (
										<AddStudentsTableRow
											classId={classId}
											student={student}
											selectedStudents={selectedStudentsOver13Arr}
											setSelectedStudents={setSelectedStudentsOver13Arr}
											onDelete={onDeleteOver13}
										/>
									)}
									style={{
										maxHeight: '250px'
									}}
								/>
							</Box>
						)}
					</Box>
				}
				isActionsHidden={AddStudentsOptsEnum.lowerEqTwelve === optionSelected}
				discardLabel="Done"
				onDiscard={(e) => {
					e.stopPropagation()
					setIsFinalStepOpen(false)
				}}
				onDiscardProps={{
					style: {
						width: '220px'
					}
				}}
				leftActions={
					<Tooltip
						title="Select at least one Student"
						placement="bottom"
						disableHoverListener={selectedStudentsOver13Arr.length > 0}>
						<span>
							<BigBaseButton
								style={{ padding: '6px 50px', width: '220px' }}
								color="secondary"
								autoFocus
								onClick={(e) => {
									e.stopPropagation()
									handleStudentSendMultipleEmails()
								}}
								startIcon={
									addStudentsSendEmailsLoading ? (
										<CircularProgress color="secondary" size={20} />
									) : undefined
								}
								disabled={
									!selectedStudentsOver13Arr.length ||
									addStudentsSendEmailsLoading ||
									studentsQRsPDFLoading
								}>
								<Typography variant="body1">Send Emails</Typography>
							</BigBaseButton>
						</span>
					</Tooltip>
				}
				rightActions={
					<Tooltip
						title="Select at least one Student"
						placement="bottom"
						disableHoverListener={selectedStudentsOver13Arr.length > 0}>
						<span>
							<BigBaseButton
								style={{ padding: '6px 50px' }}
								color="secondary"
								autoFocus
								onClick={(e) => {
									e.stopPropagation()
									handleStudentQRsPDFDownload()
								}}
								startIcon={
									studentsQRsPDFLoading ? (
										<CircularProgress color="secondary" size={20} />
									) : undefined
								}
								disabled={
									!selectedStudentsOver13Arr.length ||
									addStudentsSendEmailsLoading ||
									studentsQRsPDFLoading
								}>
								<Typography variant="body1">Download QR Codes</Typography>
							</BigBaseButton>
						</span>
					</Tooltip>
				}
				confirmLabel="Download QR Codes"
				footer={
					<BaseLink
						onClick={() => {
							cleanUploadedFile()
							setIsFinalStepOpen(false)
							setIsParentalConsentSetupOpen(true)
						}}>
						<b>Upload New CSV File</b>
					</BaseLink>
				}
			/>
			<InfoDialog
				open={isGoBackDataLostWarningOpen}
				onClose={() => {}}
				onDiscard={() => {
					cleanUploadedFile()
					setIsParentalConsentSetupOpen(false)
					setIsGoBackDataLostWarningOpen(false)
					setIsOpen(true)
				}}
				onConfirm={() => {
					setIsGoBackDataLostWarningOpen(false)
					setIsParentalConsentSetupOpen(true)
				}}
				icon="!"
				title="Go Back Without Saving?"
				body="You will lose all the details you've entered up until this point."
				discardLabel="Yes, Go Back"
				confirmLabel="No, Cancel"
			/>
			<InfoDialog
				open={isQRCodesDownloadedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsQRCodesDownloadedDialogOpen(false)
					setIsFinalStepOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="QR Codes Downloaded!"
				body="You have successfully downloades these Students QR."
				confirmLabel="Got it!"
			/>
			<InfoDialog
				open={isStudentsWithoutEmailOpen}
				onClose={() => {}}
				onDiscard={() => {
					setIsStudentsWithoutEmailOpen(false)
				}}
				onConfirm={() => {
					setIsStudentsWithoutEmailOpen(false)
				}}
				icon="!"
				title="Students without email"
				body="You have selected students without email, please correct your selection and try again."
				confirmLabel="Correct Selection"
			/>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
			/>
			<InfoDialog
				open={isStudentInvitedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsStudentInvitedDialogOpen(false)
					setIsFinalStepOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="Students Invited!"
				body="You have successfully invited these Students."
				confirmLabel="Got it!"
			/>
			<InfoDialog
				open={isParentConsentDialogOpen}
				onClose={() => {}}
				onDiscard={() => {}}
				onConfirm={() => {
					setIsParentConsentDialogOpen(false)
					setIsFinalStepOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="Parent Consent Sent!"
				body="You have successfully sent the consent."
				confirmLabel="Got it!"
			/>
			<ContactDialog
				open={isContactUsOpen}
				onClose={closeContactDialog}
				onDiscard={closeContactDialog}
				closeDialog={closeContactDialog}
			/>
		</Box>
	)
}
