import { useState, useEffect, useMemo } from 'react'

import { Box, Card, CircularProgress, Link, styled, Typography, useTheme } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import { VideoPlayer } from 'src/components/VideoPlayer/VideoPlayer'
import { useCreateRoyaltyLogMutation } from 'src/graphql/autogenerate/hooks'
import {
	Screen_Type_Enum,
	Song,
	Track,
	Wurrly,
	Wurrly_Processing_Status_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { StorageEnum } from 'src/utils'
import { buildVideoPath } from 'src/utils/utils'

export type WurrlyToPlay = Pick<
	Wurrly,
	'resource_path' | 'final_resource_path' | 'is_from_legacy' | 'image_path'
> &
	WurrlyTrackType
type WurrlyTrackType = {
	track: Pick<Track, 'track_id'> & { song: Pick<Song, 'song_id'> }
	is_video_url: boolean
}
export type ProcessingStatusForPlayer = {
	status: Wurrly_Processing_Status_Enum
	isLoading: boolean
}
export type WurrlyPlayerProps = {
	wurrly: WurrlyToPlay
	processingStatus: ProcessingStatusForPlayer
}

export const WurrlyPlayer = ({ wurrly, processingStatus }: WurrlyPlayerProps) => {
	const {
		teacherData: { teacher_id },
		studentData: { student_id }
	} = useLoginContext()

	const [createRoyaltyLog] = useCreateRoyaltyLogMutation()
	const [wurrlySrc, setWurrlySrc] = useState('')

	const handlePlay = async () => {
		localStorage.setItem(StorageEnum.audioStartTime, new Date().toISOString())
	}

	const handlePause = () => {
		handleRoyaltyLog()
	}

	const handleRoyaltyLog = async () => {
		const audioPauseTime = new Date()
		const lsAudioStartTime = localStorage.getItem(StorageEnum.audioStartTime)
		const audioStartTime = lsAudioStartTime ? new Date(lsAudioStartTime) : audioPauseTime
		const playSeconds = (audioPauseTime.getTime() - audioStartTime.getTime()) / 1000 // in case startTime is undefined total time will be 0
		try {
			await createRoyaltyLog({
				variables: {
					trackId: wurrly.track.track_id ?? 0,
					songId: wurrly.track.song.song_id ?? 0,
					teacherId: teacher_id || null,
					studentId: student_id || null,
					startDate: audioStartTime,
					endDate: audioPauseTime,
					playTime: playSeconds,
					screenType: Screen_Type_Enum.WurrlyDetail
				}
			})
		} catch (error) {
			console.error('Royalty log insert error', error)
		}
		localStorage.removeItem(StorageEnum.audioStartTime)
	}

	useEffect(() => {
		const src = wurrly?.is_from_legacy ? wurrly?.resource_path : wurrly?.final_resource_path
		if (src) setWurrlySrc(buildVideoPath(src))
	}, [wurrly?.is_from_legacy, wurrly?.resource_path, wurrly?.final_resource_path])

	return (
		<Box>
			{!processingStatus.isLoading && processingStatus.status === Wurrly_Processing_Status_Enum.Done ? (
				<div>
					<VideoPlayer
						url={wurrlySrc}
						imageForAudio={wurrly?.image_path || ''}
						isVideo={wurrly?.is_video_url}
						onPlay={handlePlay}
						onPause={handlePause}
					/>
				</div>
			) : (
				<WurrlyStatusHandle processingStatus={processingStatus} />
			)}
		</Box>
	)
}

const WurrlyStatusHandle = ({ processingStatus }: { processingStatus: ProcessingStatusForPlayer }) => {
	return (
		<CenteredBox>
			{!processingStatus.isLoading && <StatusCard status={processingStatus.status} />}
			{processingStatus.status !== Wurrly_Processing_Status_Enum.Error && (
				<CircularProgress color="secondary" />
			)}
		</CenteredBox>
	)
}

const StatusCard = ({ status }: { status: Wurrly_Processing_Status_Enum }) => {
	const theme = useTheme()
	const caption = useMemo(() => {
		switch (status) {
			case Wurrly_Processing_Status_Enum.NotStarted:
				return "This wurrly isn't ready yet, it will be available right after being processed."
			case Wurrly_Processing_Status_Enum.InProgress:
				return 'This wurrly is being processed. This might take a while.'
			case Wurrly_Processing_Status_Enum.Done:
				return 'This wurrly is ready, you will see it here shortly.'
			case Wurrly_Processing_Status_Enum.Error:
			default:
				return 'An error ocurred while processing this wurrly.'
		}
	}, [status])

	return (
		<StyledCard>
			{status === Wurrly_Processing_Status_Enum.Error ? (
				<ErrorIcon style={{ color: theme.palette.error.dark }} />
			) : (
				<InfoIcon />
			)}
			<Box style={{ marginLeft: '.5em' }}>
				<Typography>{caption}</Typography>
				{status === Wurrly_Processing_Status_Enum.Error && (
					<Typography variant="body2">
						Please contact support:{' '}
						<Link color="secondary" target="_blank" href="mailto:support@wurrlyedu.com">
							<strong>support@wurrlyedu.com</strong>
						</Link>
					</Typography>
				)}
			</Box>
		</StyledCard>
	)
}

const CenteredBox = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '20em',
	width: '100%'
})

const StyledCard = styled(Card)({
	margin: '1em',
	padding: '1em',
	display: 'flex',
	flexDirection: 'row'
})
