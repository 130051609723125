/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'us-west-2',
	aws_cognito_region: 'us-west-2',
	aws_user_pools_id: 'us-west-2_D2Sr9Atth',
	aws_user_pools_web_client_id: '7jfq4qasnn4ifucb35rbl40h9p',
	oauth: {}
}

export default awsmobile
