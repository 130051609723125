import { makeStyles, createStyles } from '@material-ui/core/styles'

const useHomeStyles = makeStyles(() =>
	createStyles({
		divider: {
			margin: '3em 0'
		},
		innerBox: {
			paddingTop: 0
		}
	})
)

export default useHomeStyles
