import { useEffect, useMemo, useRef, useState, ChangeEvent } from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'

import { environment } from '../../environment'
/* eslint-disable @typescript-eslint/no-explicit-any */

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
	if (!position) {
		return
	}

	const script = document.createElement('script')
	script.setAttribute('async', '')
	script.setAttribute('id', id)
	script.src = src
	position.appendChild(script)
}

const autocompleteService = { current: null }

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2)
	},
	listBox: {
		'& .MuiAutocomplete-option[aria-selected="true"]': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(255, 194, 12, 0.3)'
		},
		'& .MuiAutocomplete-option[data-focus="true"]': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(255, 194, 12, 0.1)'
		},
		maxHeight: '200px',
		overflowY: 'scroll',
		paddingLeft: 0,
		margin: 0
	},
	noOptions: {
		backgroundColor: 'rgba(255, 61, 0, 0.12)'
	},
	input: {
		borderRadius: '4px',
		width: '100% !important',
		borderColor: '#DDDDDD',
		'&:hover': {
			borderColor: '#DDDDDD'
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: theme.palette.secondary.main
		}
	}
}))

interface PlaceType {
	description: string
	structured_formatting: {
		main_text: string
		secondary_text: string
		main_text_matched_substrings: [
			{
				offset: number
				length: number
			}
		]
	}
}

type AutocompletePlacesProps = {
	inputValue: string
	onInputChange: (event: React.ChangeEvent<unknown>, value: string) => void
	label?: string
}

export const AutocompletePlaces = ({ inputValue, onInputChange, label }: AutocompletePlacesProps) => {
	const classes = useStyles()
	const [value, setValue] = useState<PlaceType | null>(null)
	const [options, setOptions] = useState<PlaceType[]>([])
	const loaded = useRef(false)
	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`,
				document.querySelector('head'),
				'google-maps'
			)
		}

		loaded.current = true
	}

	const fetch = useMemo(
		() =>
			throttle(
				(request: { input: string }, callback: (results?: PlaceType[]) => void) =>
					(autocompleteService.current as any).getPlacePredictions(request, callback),
				200
			),
		[]
	)

	useEffect(() => {
		let active = true
		if (!autocompleteService.current && (window as any).google) {
			autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
		}
		if (!autocompleteService.current) {
			return undefined
		}

		if (inputValue === '') {
			setOptions(value ? [value] : [])

			return undefined
		}

		fetch({ input: inputValue }, (results?: PlaceType[]) => {
			if (active) {
				let newOptions = [] as PlaceType[]

				if (value) {
					newOptions = [value]
				}

				if (results) {
					newOptions = [...newOptions, ...results]
				}

				setOptions(newOptions)
			}
		})

		return () => {
			active = false
		}
	}, [value, inputValue, fetch])

	return (
		<Autocomplete
			className={classes.input}
			id="google-map-demo"
			style={{ width: 300 }}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			filterOptions={(x: PlaceType[]) =>
				x.filter((i) => i.description.includes('USA') || i.description.includes('EE. UU.'))
			}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(_event: ChangeEvent<unknown>, newValue: PlaceType | null) => {
				setOptions(newValue ? [newValue, ...options] : options)
				setValue(newValue)
			}}
			ListboxProps={{
				className: classes.listBox
			}}
			onInputChange={onInputChange}
			renderInput={(params) => (
				<TextField {...params} label={label} variant="outlined" fullWidth placeholder="Type an address" />
			)}
			renderOption={(option) => {
				const matches = option.structured_formatting.main_text_matched_substrings
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				)

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	)
}
