import { Grid, GridProps } from '@material-ui/core'
import { ProgressStatusCard } from 'src/components'

import { increasePercentageOverPrevious } from './utils'

export type AnalyticsCountCardsProps = {
	previousPeriodText: string
	assignments: {
		title: string
		submittedCurrentPeriod: number
		submittedPreviousPeriod: number
		totalCurrentPeriod: number
		viewAction?: () => void
	}
	wurrlies: {
		title: string
		totalCurrentPeriod: number
		totalPreviousPeriod: number
		viewAction?: () => void
	}
	gridProps?: GridProps
}

export const AnalyticsCountCards = ({
	previousPeriodText,
	assignments,
	wurrlies,
	gridProps
}: AnalyticsCountCardsProps) => {
	const getAssignmentPercentage = () => {
		return increasePercentageOverPrevious(
			assignments.submittedCurrentPeriod,
			assignments.submittedPreviousPeriod
		)
	}

	const getWurrliesPercentage = () => {
		return increasePercentageOverPrevious(wurrlies.totalCurrentPeriod, wurrlies.totalPreviousPeriod)
	}

	return (
		<Grid container spacing={3} {...gridProps}>
			<ProgressStatusCard
				title={assignments.title}
				body={
					<b>
						{assignments.submittedCurrentPeriod}/{assignments.totalCurrentPeriod}
					</b>
				}
				progressSinceLastX={getAssignmentPercentage()}
				sinceLastText={previousPeriodText}
				isView={!!assignments.viewAction}
				viewAction={assignments.viewAction}
			/>
			<ProgressStatusCard
				title={wurrlies.title}
				body={<b>{wurrlies.totalCurrentPeriod}</b>}
				progressSinceLastX={getWurrliesPercentage()}
				sinceLastText={previousPeriodText}
				isView={!!wurrlies.viewAction}
				viewAction={wurrlies.viewAction}
			/>
		</Grid>
	)
}
