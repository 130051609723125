import { Fragment } from 'react'

import { Box, Divider, List } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { Song, Tip } from '../../../graphql/autogenerate/schemas'
import { buildImagePath, concatenate, getSongGenres, getVideoGenres, TypeEnum } from '../../../utils'
import { ListItemAddSong } from './ListItemAddSong'

const useStyles = makeStyles((theme: Theme) => ({
	boxSongs: {
		padding: 0,
		'& .MuiBox-root': {
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

type ListItemsProps = {
	spacing?: number
	lineDivider?: boolean
	data: ({ selected: boolean } & (Song | Tip))[]
	type: TypeEnum
	onSelect: (item: { selected: boolean } & (Song | Tip)) => void
	disableText: string
}

export const ListAddItems = ({ spacing = 0, lineDivider, data, type, onSelect, disableText }: ListItemsProps) => {
	const classes = useStyles(spacing)

	return (
		<Fragment>
			<List className={classes.boxSongs}>
				{data.map((item, i) => (
					<Box
						key={concatenate([i, item[`${type}_id` as keyof typeof item], item.title], '-')}
						style={{
							cursor: 'pointer',
							backgroundColor: item.selected ? 'rgba(255, 194, 12, 0.1)' : '#fff'
						}}
						overflow="hidden"
						boxShadow={spacing > 0 ? 1 : 0}
						margin="5px"
						borderRadius={spacing > 0 ? 4 : 0}
						onClick={() => {
							onSelect(item)
						}}>
						<ListItemAddSong
							disableText={disableText}
							imageUrl={buildImagePath(item.image_path)}
							title={item.title}
							author={item.artist?.name ?? ''}
							genres={`• ${
								type === TypeEnum.Song ? getSongGenres(item as Song) : getVideoGenres(item as Tip)
							}`}
							selected={item.selected}
						/>
						{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
					</Box>
				))}
			</List>
		</Fragment>
	)
}
