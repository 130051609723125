import { makeStyles } from '@material-ui/core'

export const useClassCardStyles = makeStyles({
	title: {
		fontSize: 28,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 3,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word'
	},
	datetime: {
		color: '#fff !important',
		'& svg': {
			color: '#fff !important'
		}
	},
	ungradedBadge: {
		background: '#fee2e2',
		color: '#f87171',
		borderRadius: '4px',
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		padding: '8px 16px'
	}
})

export default useClassCardStyles
