import { createStyles, makeStyles } from '@material-ui/core'

export const useAddToClassDialogStyles = makeStyles(() =>
	createStyles({
		description: {
			padding: 14
		},
		noResult: {
			color: 'rgba(0,0,0,0.5)'
		},
		loader: {
			margin: '20px 0'
		},
		infiniteScroll: {
			padding: 5
		},
		dialogContent: {
			overflowX: 'hidden',
			width: 520
		},
		searchBar: {
			marginTop: 10,
			marginBottom: 10
		},
		subtitle: {
			fontSize: '1rem',
			textAlign: 'center',
			margin: '1em 0'
		}
	})
)

export default useAddToClassDialogStyles
