import { useQuery } from '@apollo/client'
import { Box, Link, Typography } from '@material-ui/core'
import { Link as LinkRD } from 'react-router-dom'
import { QueryCatalogItemsType, PagesProps } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons/CustomIcons'
import { Catalogs, ListSongs, MainTitle, SelectChord } from 'src/components'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useScroll } from 'src/hooks/useScroll'
import { Pages } from 'src/routes/teacherPages'
import { TypeEnum } from 'src/utils'

import { QueryCatalogItems } from '../../queries'
import { useMusicCatalog } from './hooks/useMusicCatalog'
import { useStyles } from './MusicCatalog.styles'

export const MusicCatalog = ({ page, teacherId }: PagesProps) => {
	useScroll()
	const styles = useStyles()
	const catalogs = useQuery<QueryCatalogItemsType>(QueryCatalogItems(TypeEnum.Song))

	const { onSearch, onSearchEnter, handleChord, LinkToFeatured, getSearchFeatureData, getFeaturedData } =
		useMusicCatalog({
			page
		})

	return (
		<div>
			<MainTitle
				breadcrumbs={[page]}
				title="Music Catalog"
				description="Inspire your students with music they'll love. Find song, add it to your class & share it with your students."
				rightActions={[
					{
						variant: 'outlined',
						className: styles.actionButton,
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.SongUpload.path
					}
				]}
			/>
			<SearchBar
				placeholder="Find songs by Artist or Song Title"
				onChange={(e) => onSearch(e.target.value)}
				onEnterPressed={onSearchEnter}
				loading={getSearchFeatureData.loading}
				dropdownItems={getSearchFeatureData.data?.song.map((el, key) => {
					return (
						<ListSongs
							allTranspositions={false}
							returnItemAlone
							key={key}
							teacherId={teacherId}
							spacing={1.5}
							listItem={el as Song}
							listItemNumber={key}
							data={[]}
						/>
					)
				})}
			/>

			<Box mb={2}>
				<Typography variant="h6">
					<b>Browse by Chords</b>
				</Typography>
			</Box>
			<SelectChord onChange={handleChord} />

			{Boolean(getFeaturedData?.data?.song?.length) && (
				<>
					<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Songs</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<Link className={styles.link} component={LinkRD} to={LinkToFeatured}>
							<b>View All</b>
						</Link>
					</Box>

					<ListSongs
						teacherId={teacherId}
						spacing={1.5}
						data={getFeaturedData.data?.song as Song[]}
						allTranspositions={false}
						lessonsButton
					/>
				</>
			)}

			{!catalogs.loading && catalogs.data && <Catalogs parentPage={'song'} data={catalogs.data} />}
		</div>
	)
}
