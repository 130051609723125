import { useEffect, useMemo, useRef, useState, MouseEvent, useCallback } from 'react'

import { useFormik } from 'formik'

import { useUploadSongTrackContext } from '../../useUploadSongTrack'

type ChordEditionMode = 'writting' | 'timing'

export const useTimeLyricsView = () => {
	const [speed, setSpeed] = useState(0.0)
	const [edittingMode, setEdittingMode] = useState<ChordEditionMode>('writting')
	/**
	 * coords (coordinates) represents the position of the current time text
	 * in the lyricsArray
	 */
	const [coords, setCoords] = useState<[number, number]>([0, 0])
	const boxRef = useRef<HTMLDivElement>(null)
	const {
		setIsLyricsStepValid,
		audio,
		setAudio,
		playing,
		progress,
		time,
		setTime,
		setPlaying,
		durationInSeconds,
		setLyrics,
		lyrics: rootLyrics,
		lyricsArray,
		setLyricsArray,
		setViewTitle,
		setViewDescription
	} = useUploadSongTrackContext()

	useEffect(() => {
		setViewTitle('Time Your Lyrics')
		setViewDescription(
			"Tap the spacebar or click the yellow box when the singer should sing that lyric. You can always edit the timings you've placed afterwards. Feel free to start!"
		)

		return () => {
			setViewTitle('')
			setViewDescription('')
		}
	}, [])

	const formik = useFormik({
		initialValues: {
			lyrics: ''
		},
		onSubmit: () => {}
	})

	useEffect(() => {
		if (edittingMode === 'timing') {
			handleSetLyricsWithoutTime()
		}
	}, [edittingMode])

	const validity = useMemo(() => {
		const lines = formik.values.lyrics.split('\n')
		const filtered = lines.filter((line) => line.trim() !== '')
		const cleaned = filtered.map((item) => item.trim().replaceAll(' ', ''))

		const errors = cleaned
			.map((line, index) => (line.length > 25 ? index + 1 : 0))
			.filter(Boolean)
			.map((item) => item.toString())

		const subject = errors.length === 1 ? 'line' : 'lines'

		/**
		 * joins the items in the array with a comma and a space,
		 * also ands the last item with 'and'
		 */
		const conjunction = new Intl.ListFormat(['en'], {
			type: 'conjunction'
		}).formatToParts(errors)

		return {
			hasErrors: errors.length > 0,
			message: errors.length > 0 ? `The character limit has been exceeded on ${subject}` : '',
			lines: cleaned.length,
			conjunction
		}
	}, [formik.values.lyrics])

	const getCoordinateButtonId = ([x, y]: [number, number]) => `button_text_${x}_${y}`

	const handleSetLyricsWithoutTime = () => {
		setCoords([0, 0])
		setLyricsArray(
			formik.values.lyrics
				.split('\n')
				.filter(Boolean)
				.map((line) =>
					line
						.trim()
						.replace(/  +/g, ' ')
						.split(' ')
						.map((text) => ({ text: text.trim(), time: undefined }))
				)
		)
	}

	useEffect(() => {
		if (!isNaN(audio.duration)) {
			if (playing) {
				audio.play()
			} else {
				audio.pause()
			}
		}
	}, [playing])

	const areLyricsTimesFullfilled = useMemo(() => {
		return lyricsArray.every((row) => row.every((line) => line.time !== undefined)) && lyricsArray.length > 0
	}, [lyricsArray, coords])

	useEffect(() => {
		if (areLyricsTimesFullfilled) {
			setPlaying(false)

			const alertCard = document.getElementById('completed-alert')
			alertCard?.scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			})
		}
	}, [areLyricsTimesFullfilled])

	useEffect(() => {
		/**
		 * - None of the lines has more than 25 characters excluding empty spaces
		 * - There is at least one line of lyrics
		 */
		setIsLyricsStepValid(areLyricsTimesFullfilled)
	}, [areLyricsTimesFullfilled])

	/* Setting the context lyrics from the lyricsArray. */
	useEffect(() => {
		if (areLyricsTimesFullfilled) {
			const lyrics = lyricsArray.flatMap((item) =>
				item.filter(({ time }) => time !== undefined).map(({ text, time }) => ({ text, time }))
			)

			setLyrics(lyrics as typeof rootLyrics)
		}
	}, [lyricsArray, areLyricsTimesFullfilled])

	const handleTimeChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		audio.currentTime = ((value as number) * durationInSeconds) / 100
	}

	const formatTime = (seconds: number) => {
		const seg = Math.floor(seconds)
		const min = Math.floor(seg / 60)
		const hor = Math.floor(min / 60)

		const twoDigits = (num: number) => num.toString().padStart(2, '0')

		return `${hor > 0 ? `${twoDigits(hor)}:` : ''}${twoDigits(min % 60)}:${twoDigits(seg % 60)}`
	}

	const resetTimingRow = (row: number) => {
		setPlaying(false)
		audio.pause()

		audio.currentTime = (lyricsArray[row][0].time ?? 0 * durationInSeconds) / 1000

		setLyricsArray((prev) =>
			prev.map((current, index) =>
				index === row ? current.map((line) => ({ ...line, time: undefined })) : current
			)
		)

		setCoords([row, 0])

		const button = document.getElementById(getCoordinateButtonId([row, 0]))
		button?.focus()
		button?.scrollIntoView({
			behavior: 'smooth',
			block: 'center'
		})
	}

	const handleSpeedChange = (_: React.ChangeEvent<unknown>, value: number | number[]) => {
		setSpeed(value as number)
	}

	useEffect(() => {
		audio.playbackRate = speed + 1
	}, [speed])

	const goNextWord = () => {
		setCoords(([x, y]) => {
			setLyricsArray((array) => {
				array[x][y].time = audio.currentTime * 1000

				return array
			})

			if (x <= lyricsArray.length - 1) {
				if (y < lyricsArray[x].length - 1) {
					return [x, y + 1]
				} else if (x < lyricsArray.length - 1) {
					document.getElementById(getCoordinateButtonId([x, y]))?.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					})

					return [x + 1, 0]
				}
			}

			return [x, y]
		})
	}

	const handlePlayPauseControl = (event: MouseEvent<HTMLButtonElement>) => {
		event.currentTarget.blur()
		const [x, y] = coords
		const firstButton = document.getElementById(getCoordinateButtonId([x, y]))

		firstButton?.focus()
		firstButton?.scrollIntoView({
			behavior: 'smooth',
			block: 'center'
		})
		setPlaying((prev) => !prev)
	}

	const getMinutes = (): string[] => {
		if (audio) {
			const secondsToMinutes = durationInSeconds / 60

			return Array.from({ length: Math.round(secondsToMinutes) }, (_, idx) =>
				(idx % 60).toString().padStart(2, '0')
			)
		}

		return []
	}

	const updateMinuteForWordAtCoords = (coords: [number, number], minute: string) => {
		const [x, y] = coords
		const copy = [...lyricsArray]
		const item = copy[x][y]
		const currentTime = (item.time ?? 0) / 1000
		const seconds = currentTime % 60
		const newMinute = parseInt(minute, 10)
		const newTime = (newMinute * 60 + seconds) * 1000

		item.time = newTime

		copy[x][y] = item

		setLyricsArray(copy)
	}

	const updateSecondForWordAtCoords = (coords: [number, number], second: string) => {
		const [x, y] = coords
		const copy = [...lyricsArray]
		const item = copy[x][y]
		const currentTime = (item.time ?? 0) / 1000
		const minutes = Math.round(currentTime / 60) // get just minute
		const newSeconds = parseInt(second, 10)
		const newTime = (minutes * 60 + newSeconds) * 1000

		copy[x][y].time = newTime

		setLyricsArray(copy)
	}

	const getWordMinuteAtCoords = useCallback(
		(coords: [number, number]): string => {
			const [x, y] = coords

			return Math.floor(((lyricsArray[x][y].time ?? 0) / 1000 / 60) % 60)
				.toFixed()
				.padStart(2, '0')
		},
		[lyricsArray]
	)

	const getWordSecondAtCoords = useCallback(
		(coords: [number, number]): string => {
			const [x, y] = coords

			return (Math.floor((lyricsArray[x][y].time ?? 0) / 1000) % 60).toFixed().padStart(2, '0')
		},
		[lyricsArray]
	)

	return {
		validity,
		formik,
		audio,
		playing,
		setPlaying,
		handleTimeChange,
		formatTime,
		setAudio,
		progress,
		lyricsArray,
		setLyricsArray,
		resetTimingRow,
		speed,
		handleSpeedChange,
		goNextWord,
		coords,
		setCoords,
		boxRef,
		handlePlayPauseControl,
		handleSetLyricsWithoutTime,
		getCoordinateButtonId,
		setIsLyricsStepValid,
		durationInSeconds,
		time,
		setTime,
		areLyricsTimesFullfilled,
		edittingMode,
		setEdittingMode,
		getMinutes,
		updateMinuteForWordAtCoords,
		updateSecondForWordAtCoords,
		getWordMinuteAtCoords,
		getWordSecondAtCoords
	}
}
