import { useEffect, useState } from 'react'

import { Box, ButtonProps, Paper, PaperProps, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import clsx from 'clsx'
import { CartIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { StatusProductButton } from 'src/components/Buttons/StatusProductButton/StatusProductButton'
import { Module, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath, getModuleGenres, limitString } from 'src/utils'

import useStyles from './ModulesCard.styles'

export type ModulesCardProps = PaperProps & {
	module: Module
	onDelete?: () => void
	onEdit?: () => void
	editTooltip?: string
	deleteTooltip?: string
	onActiveChange?: (active: boolean) => void
	onView?: () => void
	btnSuscription?: ButtonProps
	btnAddClass?: { selected?: boolean } & ButtonProps
}

export const ModulesCard = ({
	module,
	onClick,
	onView,
	btnSuscription,
	btnAddClass,
	...props
}: ModulesCardProps) => {
	const styles = useStyles({ imageUrl: buildImagePath(module.image_path) || '' })
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [subscription, setSubscription] = useState<boolean>(false)
	const [status, setStatus] = useState('')
	const [isDistrict, setIsDistrict] = useState(true)

	useEffect(() => {
		if (module) {
			setVisibility(module.lockable_content?.visibility)
			setSubscription(module.is_purchased_by_teacher as boolean)
			setStatus(module.get_status_by_teacher as string)
			setIsDistrict(module.lockable_content ? module?.lockable_content?.districts?.length > 0 : true)
		}
	}, [module])
	const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target !== e.currentTarget || !onClick) return
		onClick(e)
	}

	return (
		<Paper {...props} onClick={handleOnClick}>
			<Box className={styles.container}>
				<Box className={module.image_path ? styles.imageContainer : styles.blank} onClick={onClick}>
					<Box className={styles.opacity} />
					<Typography variant="h5" className={clsx(styles.ellipsis, styles.title)}>
						{module.title}
					</Typography>

					<Box className={styles.flag}>
						<Typography className={clsx(styles.flagText)}>
							{module.module_lesson_plan_aggregate.aggregate?.count} Lessons
						</Typography>
					</Box>
				</Box>
				<Box className={styles.outsideCointainer}>
					<Box className={styles.details}>
						<Typography className={clsx(styles.ellipsis, styles.description)} onClick={onClick}>
							{limitString(module.description, { limit: 110 })}
						</Typography>
						<Typography className={clsx(styles.genres, styles.ellipsis)} onClick={onClick}>
							• {getModuleGenres(module).join(', ')}
						</Typography>
						{visibility === Visibility_Enum.ForSale && (
							<StatusProductButton
								isSubscription={subscription}
								isDistrict={isDistrict}
								status={status}
							/>
						)}
					</Box>
					<Box className={styles.buttons}>
						<Tooltip title="View">
							<BaseButton
								color="primary"
								className={styles.viewButton}
								endIcon={<ArrowForwardIcon />}
								onClick={onView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
						{btnAddClass &&
							(visibility !== Visibility_Enum.ForSale ||
								(visibility === Visibility_Enum.ForSale && subscription)) && (
								<BaseButton
									color="primary"
									className="addClass"
									endIcon={<AddIcon />}
									{...btnAddClass}>
									<Typography>Add to class</Typography>
								</BaseButton>
							)}
						{visibility === Visibility_Enum.ForSale && !subscription && (
							<BaseButton
								className={styles.subscription}
								startIcon={<CartIcon style={{ color: '#F3AB34' }} />}
								{...btnSuscription}>
								<Typography className={styles.subscribe}>Subscribe to Module</Typography>
							</BaseButton>
						)}
					</Box>
				</Box>
			</Box>
		</Paper>
	)
}
