import { Box } from '@material-ui/core'

import { PagesProps } from '../../../../../@types'
import { MainTitle } from '../../../../../components'

export const ClassTitle = ({ page }: PagesProps) => {
	return (
		<Box>
			<MainTitle breadcrumbs={[page]} title={page.name} />
		</Box>
	)
}
