import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

type SubscriptionsCardStylesProps = {
	imageUrl: string
	isSearch: boolean
}
export const useSubscriptionsCardStyles = makeStyles<Theme, SubscriptionsCardStylesProps>(() =>
	createStyles({
		container: {
			display: 'flex',
			height: '217px',
			boxShadow:
				'0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.15)',
			borderRadius: '4px',
			marginBottom: ({ isSearch }: SubscriptionsCardStylesProps) => (isSearch ? '0px' : '20px')
		},
		image: {
			position: 'relative',
			backgroundImage: ({ imageUrl }: SubscriptionsCardStylesProps) => `url(${imageUrl})`
		},
		imageContainer: {
			position: 'relative',
			height: '100%',
			width: '25%',
			backgroundPosition: 'center',
			backgroundColor: 'gray',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			borderRadius: '4px 0px 4px 0 px'
		},
		titleCenter: {
			justifyContent: 'center',
			alignItems: 'center',
			color: '#FFFFFF'
		},
		ellipsis: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical'
		},
		ellipsisSecondLine: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			height: '46px',
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical'
		},
		details: {
			padding: 24,
			width: '75%',
			justifyContent: 'space-between',
			display: 'flex',
			flexDirection: 'column'
		},
		buttons: {
			display: 'flex',
			marginTop: '20px',
			alignItems: 'stretch',
			gap: '12px',
			justifyContent: 'flex-end'
		},
		description: {
			marginBottom: 10,
			fontSize: '1.3rem',
			marginRight: '10px'
		},
		title: {
			color: '#FFFFFF',
			padding: '40px 20px 0 20px'
		},
		flag: {
			padding: '10px 0',
			backgroundColor: 'rgba(0,0,0,0.3)',
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		flagText: {
			color: 'white',
			marginLeft: 15
		},
		viewButton: {
			background: '#E9E9EA',
			color: '#1D273D'
		},
		unsubscribe: {
			backgroundColor: '#323342',
			border: '2px solid #323342',
			borderRadius: ' 4px'
		},
		expire: {
			display: 'flex',
			alignItems: 'flex-start',
			flexDirection: 'column',
			justifyContent: 'space-between'
		},
		expireInLine: {
			flexDirection: 'row',
			gap: '60px'
		},
		expireText: {
			display: 'flex',
			gap: '30px'
		},
		text: {
			fontWeight: 600
		},
		textNameColor: {
			color: 'rgba(46, 38, 66, 0.6)'
		},
		footer: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		descriptionContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '30px'
		},
		price: {
			fontSize: '30px'
		},
		locked: {
			fontSize: '0.75rem',
			marginTop: '1px',
			marginLeft: '2px'
		},
		lockedButton: {
			borderRadius: '100px',
			width: '125px',
			height: '27px'
		}
	})
)

export default useSubscriptionsCardStyles
