import { createStyles, makeStyles } from '@material-ui/core'

export const useSelectAddProductDialog = makeStyles(() =>
	createStyles({
		btn: {
			height: '100px'
		},
		title: {
			paddingBottom: '30px',
			fontSize: '1.3rem'
		},
		container: {
			display: 'flex',
			gap: '20px'
		},
		footer: {
			display: 'flex',
			justifyContent: 'center'
		}
	})
)

export default useSelectAddProductDialog
