import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, { inheritedLoading: boolean }>(() =>
	createStyles({
		header: { marginTop: '10px' },
		loading: {
			height: '20px!important',
			width: '20px!important',
			display: 'flex',
			alignItems: 'center'
		},
		label: { textTransform: 'capitalize', paddingLeft: '10px' },
		integrationIcon: ({ inheritedLoading }) => ({ height: '25px', opacity: inheritedLoading ? 0.5 : 1 }),
		button: {
			margin: 0,
			width: '30%',
			borderRadius: '4px',
			marginLeft: '2px',
			marginRight: '2px',
			border: '1px solid #E2E2E2'
		}
	})
)

export default useStyles
