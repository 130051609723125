import { Box, TextField, styled, Typography, Link, Divider } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { useHistory } from 'react-router-dom'
import { InfoDialog } from 'src/components'
import { InfoItem } from 'src/components/Profile/InfoItem'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { Pages } from 'src/routes/teacherPages'
import { useEditProfileContext } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useEditProfileData'
import { buildRouteParameters } from 'src/utils'

import { PasswordInput } from '../../../../Login/components/PasswordInput'

export const EditProfileInfo = () => {
	return <ProfileSection body={<EditProfileBody />} />
}

const Row = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	width: '100%'
})
const StyledInput = styled(TextField)({
	marginTop: '1em'
})

const EditProfileBody = () => {
	const history = useHistory()
	const {
		validateCurrentPassword,
		setNewPasswordIsValid,
		isLoading,
		updateError,
		errorMessage,
		setUpdateError,
		updateSuccess,
		formik,
		handelClose
	} = useEditProfileContext()

	const goToProfile = () => {
		history.push(buildRouteParameters(Pages.Profile))
		window.location.reload()
	}

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<Row>
					<InfoItem
						style={{ paddingRight: '10px' }}
						title="Name*"
						value={
							<StyledInput
								variant="outlined"
								value={formik.values.firstName}
								onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
								fullWidth
								disabled={isLoading}
							/>
						}
					/>
					<InfoItem
						style={{ paddingLeft: '10px' }}
						title="Last Name*"
						value={
							<StyledInput
								variant="outlined"
								value={formik.values.lastName}
								onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
								fullWidth
								disabled={isLoading}
							/>
						}
					/>
				</Row>

				<InfoItem
					title="New Password"
					value={
						<PasswordInput
							password={formik.values.newPassword}
							setPassword={(e) => formik.setFieldValue('newPassword', e)}
							style={{ marginTop: '1em' }}
							setFulfillRequirements={setNewPasswordIsValid}
							disabled={isLoading}
						/>
					}
				/>
				<InfoItem
					title="Confirm New Password"
					value={
						<PasswordInput
							password={formik.values.confirmNewPassword}
							setPassword={(e) => formik.setFieldValue('confirmNewPassword', e)}
							style={{ marginTop: '1em' }}
							disableValidation
							disabled={isLoading}
						/>
					}
				/>
				{formik.values.newPassword &&
					formik.values.confirmNewPassword &&
					formik.values.newPassword !== formik.values.confirmNewPassword && (
						<Typography style={{ color: 'red' }}>Passwords don't match</Typography>
					)}
				<InfoDialog
					open={updateError}
					onClose={() => setUpdateError(false)}
					title="Couldn't update your information"
					body={
						<>
							<Typography variant="h6" style={{ fontSize: '1em' }} color="error">
								<strong>Error:</strong>
							</Typography>
							<Typography>
								{errorMessage}
								<Divider style={{ margin: '1em' }} />
								Please try again. <br />
								If the problem persists, contact support:{' '}
								<Link color="secondary" target="_blank" href="mailto:support@wurrlyedu.com">
									support@wurrlyedu.com
								</Link>
							</Typography>
						</>
					}
					discardLabel="Close"
					onDiscard={() => {
						setUpdateError(false)
					}}
					icon="!"
				/>
				<InfoDialog
					open={updateSuccess}
					icon={<CheckIcon />}
					title="Profile Saved!"
					body="Your Profile information was saved successfully!"
					discardLabel="Return to Profile"
					onDiscard={goToProfile}
				/>
				<InfoDialog
					open={validateCurrentPassword}
					icon={<CheckIcon />}
					isIcon={false}
					title="Please Confirm Password to Save Changes"
					body={
						<PasswordInput
							password={formik.values.currentPassword}
							setPassword={(e) => formik.setFieldValue('currentPassword', e)}
							style={{ marginTop: '1em' }}
							disableValidation
							disabled={isLoading}
							error={true}
						/>
					}
					discardLabel="Cancel"
					onDiscard={handelClose}
					confirmLabel={isLoading ? 'Saving...' : 'Save'}
					confirmProps={{
						disabled: isLoading || !formik.values.currentPassword
					}}
					onConfirm={() => formik.submitForm()}
				/>
			</form>
		</Box>
	)
}
