import { useEffect, useState } from 'react'

import { Box, IconButton, styled, Typography } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { BreadcrumbsArrayType } from 'src/@types/types'
import { EditPencilIcon } from 'src/assets/icons'
import { MainTitle } from 'src/components'
import { EditAvatarDialog } from 'src/components/Dialogs/EditAvatarDialog'
import { UserAvatar } from 'src/components/UserAvatar'
import { useLoginContext } from 'src/hooks/useLogin'
import { PROFILE_AVATAR_SIZE } from 'src/utils'
import { buildBreadCrumbs } from 'src/utils/utils'

const StyledButton = styled(IconButton)({
	position: 'absolute',
	width: 'min-content',
	right: '-5px',
	bottom: '-5px'
})

const Title = styled(Typography)({
	fontSize: '1.9em',
	fontWeight: 500,
	marginTop: '.6em'
})

type ProfileTitleProps = PagesProps & {
	edit?: boolean
}

export const ProfileTitle = ({ page, edit }: ProfileTitleProps) => {
	const { teacherData } = useLoginContext()
	const [teacherFullName, setTeacherFullName] = useState('teacher')
	const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsArrayType>([])
	const [isEditAvatarOpen, setIsEditAvatarOpen] = useState(false)

	useEffect(() => {
		const { name_first, name_last } = teacherData
		const fullName = `${name_first} ${name_last}`
		setTeacherFullName(fullName)
		setBreadcrumbs(buildBreadCrumbs({ page, replaceIndexes: [{ index: 0, overrideName: fullName }] }))
	}, [teacherData])

	return (
		<>
			<Box>
				<MainTitle
					title={`${edit ? 'Edit ' : ''}Profile`}
					breadcrumbs={breadcrumbs}
					marginBottom={'-1.5em'}
				/>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Box display="flex" flexDirection="column" alignItems="center">
						<Box position="relative">
							<UserAvatar
								user={teacherData}
								style={{
									height: PROFILE_AVATAR_SIZE,
									width: PROFILE_AVATAR_SIZE,
									filter: 'drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.15))'
								}}
							/>
							<StyledButton onClick={() => setIsEditAvatarOpen(true)}>
								<EditPencilIcon height="40" width="40" />
							</StyledButton>
						</Box>
						<Title variant="h1">{teacherFullName}</Title>
					</Box>
				</Box>
			</Box>
			<EditAvatarDialog isOpen={isEditAvatarOpen} setIsOpen={setIsEditAvatarOpen} />
		</>
	)
}
