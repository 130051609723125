import { Theme, createStyles, fade, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		panel: {
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white,
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			},
			'& .MuiButton-root:first-child': {
				paddingLeft: theme.spacing(4),
				backgroundColor: fade(theme.palette.common.black, 0.3),
				color: fade(theme.palette.common.white, 0.8),
				boxShadow: 'none'
			}
		},
		sequenceNamesButon: {
			whiteSpace: 'nowrap',
			maxWidth: '70%'
		},
		sequenceNames: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		},
		smallBanners: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat'
			}
		}
	})
)
