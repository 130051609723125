import { useState, useEffect } from 'react'

import { Box, Button, Card } from '@material-ui/core'
import { BaseDialog, BaseDialogProps } from 'src/components'
import { InputHeader } from 'src/components/Inputs/InputHeader/InputHeader'
import { CHORD_QUALITIES, ROOT_CHORDS } from 'src/utils'

import useStyles from './SelectChordDialog.styles'

export type SelectChordDialogProps = Pick<BaseDialogProps, 'onClose' | 'open' | 'onDiscard'> & {
	onConfirm: (compose: { chord: string; quality?: string }) => void
	chord: string
	quality?: string
}

export const SelectChordDialog = ({
	open,
	onClose,
	onConfirm,
	onDiscard,
	chord,
	quality
}: SelectChordDialogProps) => {
	const styles = useStyles()
	const [selectedChord, setChord] = useState<string>(chord)
	const [selectedQuality, setQuality] = useState<string | undefined>(quality)

	useEffect(() => {
		setChord(chord)
		setQuality(quality)
	}, [chord, quality])

	return (
		<BaseDialog
			isFullWidthButton
			open={open}
			dividers={false}
			title="More Chords Options"
			discardLabel="Cancel"
			maxWidth="sm"
			fullWidth
			confirmLabel="Save"
			bodyProps={{ className: styles.body }}
			body={
				<Box className={styles.container}>
					<Box>
						<InputHeader name="Root Chord" />
						<Box className={styles.grid}>
							{ROOT_CHORDS.map((current) => (
								<Button
									color={selectedChord === current ? 'secondary' : 'default'}
									key={current}
									onClick={() => setChord(current)}>
									{current}
								</Button>
							))}
						</Box>
					</Box>
					<Box>
						<InputHeader name="Quality" />
						<Box className={styles.grid}>
							{CHORD_QUALITIES.map((current) => (
								<Button
									key={current}
									color={selectedQuality === current ? 'secondary' : 'default'}
									onClick={() => setQuality(selectedQuality === current ? undefined : current)}>
									{current}
								</Button>
							))}
						</Box>
					</Box>
					<Box>
						<InputHeader name="New Chord" />
						<Card className={styles.newChord}>
							{selectedChord}
							{selectedQuality ?? ''}
						</Card>
					</Box>
				</Box>
			}
			onClose={onClose}
			onConfirm={() => onConfirm({ chord: selectedChord, quality: selectedQuality })}
			onDiscard={onDiscard}
		/>
	)
}
