import { ChangeEvent, useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { ThinCheckIcon } from 'src/assets/icons'
import { InfoDialog } from 'src/components'
import * as yup from 'yup'

import { useInsertContactUsWithMailMutation } from '../../../graphql/autogenerate/hooks'
import { TextInput } from '../../Inputs/TextInput'
import { BaseDialog, BaseDialogProps } from '../BaseDialog/BaseDialog'
import useStyles from './ContactDialog.styles'

const validationSchema = yup.object({
	name: yup.string().required('Name is required').ensure(),
	schoolOrDistrict: yup.string().required('School or District is required').ensure(),
	message: yup.string().required('Message is required').ensure(),
	email: yup.string().email().required('Email is required').ensure()
})

type ContactDialogProps = BaseDialogProps & {
	closeDialog: () => void
}

export const ContactDialog = ({ closeDialog, ...props }: ContactDialogProps) => {
	const styles = useStyles()
	const [insertContactUs] = useInsertContactUsWithMailMutation()
	const [formDialogOpen, setFormDialogOpen] = useState(props.open)
	const [errorDialogOpen, setErrorDialogOpen] = useState(false)
	const [successDialogOpen, setSuccessDialogOpen] = useState(false)

	const closeFormDialog = () => setFormDialogOpen(false)
	const closeErrorDialog = () => setErrorDialogOpen(false)
	const closeSuccessDialog = () => setSuccessDialogOpen(false)

	const handleNameChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		formik.setFieldValue('name', e.target.value)
	const handleSchoolOrDistrictChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		formik.setFieldValue('schoolOrDistrict', e.target.value)
	const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		formik.setFieldValue('message', e.target.value)
	const handleEmailChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
		formik.setFieldValue('email', e.target.value)

	const formik = useFormik({
		initialValues: {
			name: '',
			schoolOrDistrict: '',
			message: '',
			email: ''
		},
		validationSchema,
		validateOnMount: true,
		onSubmit: async (variables, { resetForm }) => {
			try {
				await insertContactUs({ variables })
				closeFormDialog()
				setSuccessDialogOpen(true)
				resetForm()
			} catch (error) {
				closeFormDialog()
				setErrorDialogOpen(true)
				// TODO: handle errors on save
			}
		}
	})

	useEffect(() => {
		if (!props.open) {
			closeFormDialog()
			closeErrorDialog()
			closeSuccessDialog()
		} else {
			setFormDialogOpen(true)
		}
	}, [props.open])

	return (
		<>
			<BaseDialog
				dividers={false}
				header={
					<Typography variant="h4" align="center" className={styles.boldText}>
						Contact Us
					</Typography>
				}
				confirmLabel="Send Message"
				onConfirm={formik.submitForm}
				isConfirmDisabled={!formik.isValid || formik.isSubmitting}
				discardLabel="Go Back"
				onDiscard={closeFormDialog}
				body={
					<Box padding="6px 10px">
						<form onSubmit={formik.handleSubmit}>
							<TextInput
								name="Your Name"
								isRequired
								value={formik.values.name}
								onChange={handleNameChange}
								error={formik.touched.name && Boolean(formik.errors.name)}
								helperText={formik.touched.name && formik.errors.name}
								placeholder="Enter Name"
							/>
							<TextInput
								name="Your Email"
								isRequired
								value={formik.values.email}
								onChange={handleEmailChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
								placeholder="Enter Email"
							/>
							<TextInput
								name="Your School or District"
								isRequired
								value={formik.values.schoolOrDistrict}
								onChange={handleSchoolOrDistrictChange}
								error={formik.touched.schoolOrDistrict && Boolean(formik.errors.schoolOrDistrict)}
								helperText={formik.touched.schoolOrDistrict && formik.errors.schoolOrDistrict}
								placeholder="Enter school or District"
							/>
							<TextInput
								name="Your Message"
								isRequired
								value={formik.values.message}
								onChange={handleMessageChange}
								error={formik.touched.message && Boolean(formik.errors.message)}
								helperText={formik.touched.message && formik.errors.message}
								placeholder="Enter message"
								multiline
								rows={4}
							/>
						</form>
					</Box>
				}
				paperProps={{ className: styles.dialogPaper }}
				{...props}
				open={formDialogOpen}
			/>

			<InfoDialog
				open={successDialogOpen}
				onClose={closeDialog}
				icon={<ThinCheckIcon />}
				title="Message Sent!"
				body="Your message has been sent successfully"
				confirmLabel="Done"
				onConfirm={closeDialog}
				confirmProps={{ className: styles.boldText }}
			/>
			<InfoDialog
				open={errorDialogOpen}
				onClose={closeDialog}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while sending the message"
				confirmLabel="Done"
				onConfirm={closeDialog}
				confirmProps={{ className: styles.boldText }}
			/>
		</>
	)
}
