import { WurrlyLogoIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { RecordProvider } from 'src/components/Studio/Record/useStudioRecord'
import { VideoRecorderDialog } from 'src/components/Studio/Record/VideoRecorderDialog/VideoRecorderDialog'
import { useStudioBackgroundStyles } from 'src/components/Studio/Setup/styles/studioBackgroundStyles'
import { VideoSubmitProvider } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'

export const StudioRecord = () => {
	const styles = useStudioBackgroundStyles()

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={styles.logoPosition} />}
			className={styles.backgroundImage}>
			<VideoSubmitProvider>
				<RecordProvider>
					<VideoRecorderDialog isTeacher />
				</RecordProvider>
			</VideoSubmitProvider>
		</FixedBackground>
	)
}
