import { createStyles, makeStyles } from '@material-ui/core'

export const useAvatarListeStyles = makeStyles(() =>
	createStyles({
		fileInput: {
			display: 'none'
		},
		styledFab: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '100%',
			background: '#FFFFFF',
			border: '1px dashed #8F8F8F',
			height: '70px',
			width: '70px',
			boxShadow: 'none'
		}
	})
)

export default useAvatarListeStyles
