import {
	Avatar,
	Box,
	ButtonProps,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { AvatarGroup } from '@material-ui/lab'
import moment from 'moment'

import { BaseButton } from '.'
import { FullBandIcon, GuitarIcon, PianoIcon } from '../assets/icons'
import { buildImagePath, getCyKey } from '../utils'
import { TrackTypeEnum } from '../utils/enums'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		cardAvatar: {
			backgroundColor: theme.palette.grey[300]
		},
		cardIcon: {
			height: '25px',
			width: '25px',
			color: theme.palette.primary.main
		},
		itemTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word'
		}
	})
)

export type ListItemWurrlyProps = {
	studentImagePath: string
	studentName: string
	wurrlyCreatedDate: string
	wurrlyImagePath: string
	wurrlyTrackType: string
	wurrlySongTitle: string
	wurrlySongArtist: string
	btnView?: ButtonProps
}

export const ListItemWurrly = ({
	studentImagePath,
	studentName,
	wurrlyCreatedDate,
	wurrlyImagePath,
	wurrlyTrackType,
	wurrlySongTitle,
	wurrlySongArtist,
	btnView
}: ListItemWurrlyProps) => {
	const styles = useStyles()

	const getIcon = (type: string) => {
		switch (type) {
			case TrackTypeEnum.Piano:
				return <PianoIcon className={styles.cardIcon} />
			case TrackTypeEnum.Band:
				return <FullBandIcon className={styles.cardIcon} />
			case TrackTypeEnum.Guitar:
				return <GuitarIcon className={styles.cardIcon} />
			default:
				return null
		}
	}

	return (
		<ListItem className={styles.listItem}>
			<ListItemAvatar>
				<Avatar
					variant="rounded"
					src={buildImagePath(wurrlyImagePath)}
					style={{ width: 90, height: 62, marginRight: 20 }}
				/>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={
					<Box>
						<Typography className={styles.itemTitle} variant="h6" style={{ fontSize: 18 }}>
							<b>{wurrlySongTitle}</b>
						</Typography>
						<Typography noWrap style={{ fontSize: 14 }}>
							<b>{wurrlySongArtist}</b>
						</Typography>
					</Box>
				}
				secondary={
					<Typography variant="caption" color="textSecondary">
						<b>• {wurrlyTrackType}</b>
					</Typography>
				}
			/>
			<Box display="flex" className="boxActions" alignSelf="flex-start" marginTop="6px">
				<div style={{ flexGrow: 1 }} />
				<AvatarGroup max={2} style={{ marginRight: 20 }}>
					<Avatar src={buildImagePath(studentImagePath)} />
					<Avatar className={styles.cardAvatar}>{getIcon(wurrlyTrackType)}</Avatar>
				</AvatarGroup>
				<Box>
					<Typography
						data-cy={getCyKey(ListItemWurrly, 'studentNameList')}
						noWrap
						style={{ fontSize: 14 }}>
						<b>{studentName}</b>
					</Typography>
					<Typography variant="caption" color="textSecondary">
						<b>{moment(wurrlyCreatedDate).format('YYYY/MM/DD')}</b>
					</Typography>
				</Box>
				<Tooltip title="View">
					<BaseButton
						style={{ padding: '10px 20px', marginLeft: 20 }}
						color="primary"
						className={'addClass'}
						endIcon={<ArrowForwardIcon />}
						{...btnView}>
						<Typography>View</Typography>
					</BaseButton>
				</Tooltip>
			</Box>
		</ListItem>
	)
}
