import React, { useEffect, useState } from 'react'

import { Box, Button, Chip, Divider, Grid, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { cache } from 'src/apollo/state'
import { SelectPackageDialog } from 'src/components'
import { useGetMembershipTypesQuery, useGetTeacherLicenseLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { getPriceInDollars } from 'src/utils'

import useStyles from './LicencesInfo.styles'

export const LicencesInfo = ({}) => {
	const classes = useStyles()
	const {
		teacherData: teacher,
		teacherTemporarilyEnterprise,
		setTeacherTemporarilyEnterprise
	} = useLoginContext()
	const [isSelectPackageOpen, setIsSelectPackageOpen] = useState(false)
	const [subscribedTo, setSubscribedTo] = useState('')
	const { data: memebership_types } = useGetMembershipTypesQuery()
	const isEnterprise =
		teacher.is_enterprise ||
		teacher.is_school_enterprise ||
		teacher.is_district_enterprise ||
		teacherTemporarilyEnterprise

	const [getTeacherLicense, { data: totalTeacherSubscription }] = useGetTeacherLicenseLazyQuery()

	useEffect(() => {
		getTeacherLicense({
			variables: {
				teacherId: teacher.teacher_id
			}
		})
		if (totalTeacherSubscription?.teacher_subscription[0]?.membership_type?.billing_method) {
			setSubscribedTo(totalTeacherSubscription.teacher_subscription[0].membership_type.billing_method || '')
		}
	}, [totalTeacherSubscription])
	const updateTeacherToEnterprise = () => {
		if (teacher) {
			setTeacherTemporarilyEnterprise(true)
			cache.modify({
				id: cache.identify(teacher),
				fields: {
					is_enterprise() {
						return true
					}
				},
				broadcast: false // Include this to prevent automatic query refresh */
			})
		}
	}

	return (
		<Box className={classes.licencesBox}>
			<Grid container spacing={2}>
				{/* Column headers */}
				<Grid item xs={isEnterprise ? 4 : 3}>
					<Typography className={classes.title}>Name</Typography>
				</Grid>
				<Grid item xs={isEnterprise ? 4 : 3}>
					<Typography className={classes.title}>Type</Typography>
				</Grid>
				<Grid item xs={isEnterprise ? 4 : 3}>
					<Typography className={classes.title}>Cost</Typography>
				</Grid>
				{!isEnterprise && <Grid item xs={3}></Grid>}
				<Grid item xs={12} justifyContent="center" alignItems="center">
					<Divider className={classes.divider} />
				</Grid>
				{!isEnterprise && (
					<React.Fragment>
						<Grid item xs={3}>
							<Typography className={classes.textOpacity}>STARTER PACK</Typography>
							<Chip label="Active" className={classes.smallSquareChip} />
						</Grid>
						<Grid item xs={3}>
							<Typography className={classes.text}>{'-'}</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography className={classes.text}>FREE</Typography>
						</Grid>
						<Grid item xs={3}>
							<Button
								onClick={() => {
									setIsSelectPackageOpen(true)
								}}
								variant="contained"
								color="secondary">
								<b>Upgrade</b>
							</Button>
						</Grid>
					</React.Fragment>
				)}
				{memebership_types?.membership_type.map((item, index) => (
					<React.Fragment key={index}>
						<Grid
							item
							xs={isEnterprise ? 4 : 3}
							style={{
								display: 'flex',
								alignItems: 'center'
							}}>
							<Typography className={classes.textOpacity}>ENTERPRISE LICENSE</Typography>
							{isEnterprise && subscribedTo === item.billing_method && (
								<Chip label="Active" className={classes.smallSquareChip} />
							)}
						</Grid>
						<Grid item xs={isEnterprise ? 4 : 3}>
							<Typography className={classes.text}>{item.name}</Typography>
						</Grid>
						<Grid item xs={isEnterprise ? 4 : 3}>
							<Typography className={classes.text}>$ {getPriceInDollars(item.price)}</Typography>
						</Grid>
						{!isEnterprise && <Grid item xs={3} />}
					</React.Fragment>
				))}
			</Grid>
			<SelectPackageDialog
				open={isSelectPackageOpen}
				setIsOpen={setIsSelectPackageOpen}
				onSuccess={() => noop}
				updateTeacherToEnterprise={updateTeacherToEnterprise}
				noStudents
			/>
		</Box>
	)
}
