import { useMemo } from 'react'

import { Box } from '@material-ui/core'
import { CarouselOfAny } from 'src/components'
import { useGetStickersQuery } from 'src/graphql/autogenerate/hooks'
import { Filter_Sticker } from 'src/graphql/autogenerate/schemas'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { buildImagePath, generateRandomString } from 'src/utils'

import { EffectThumbnail } from '../EffectThumbnail/EffectThumbnail'
import useStyles from './StickerFilter.styles'
type StickerFilterProps = {
	maxAmountReached: boolean
}
export const StickerFilter = ({ maxAmountReached }: StickerFilterProps) => {
	const styles = useStyles()
	const { updateSticker, videoDimmensions, unselectStickers } = useVideoSubmitContext()

	const { data } = useGetStickersQuery()
	const effects = data?.filter_sticker || []

	const onStickerSelect = (filter: { imageUrl: string; id: number }) => {
		updateSticker({
			height: 100,
			imageUrl: filter.imageUrl,
			positionX: 200,
			positionY: 200,
			rotation: 0,
			stickerId: filter.id,
			width: 100,
			renderId: generateRandomString(),
			selected: false
		})
	}

	const handleClick = (effect: Filter_Sticker) => {
		if (!maxAmountReached)
			onStickerSelect({
				imageUrl: buildImagePath(effect.image_path),
				id: effect.filter_sticker_id
			})
	}

	const hangleDragEnd = (e: React.DragEvent<HTMLElement>, sticker: Filter_Sticker) => {
		if (maxAmountReached) return
		const x = e.clientX
		const y = e.clientY
		const { minX, minY, maxX, maxY } = videoDimmensions
		if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
			const width = 100
			const height = 100
			updateSticker({
				height,
				imageUrl: buildImagePath(sticker.image_path),
				positionX: x - minX - width / 2,
				positionY: y - minY - height / 2,
				rotation: 0,
				stickerId: sticker.filter_sticker_id,
				width,
				renderId: generateRandomString(),
				selected: false
			})
		}
	}

	const stickers = useMemo(() => {
		const combinedEffects: Filter_Sticker[][] = []
		effects.forEach((effect, index) => {
			if (index % 2 === 0) {
				combinedEffects[index / 2] = [effect as Filter_Sticker]
			} else {
				combinedEffects[(index - 1) / 2].push(effect as Filter_Sticker)
			}
		})

		return combinedEffects.map((effects, index) => {
			return {
				element: (
					<Box key={generateRandomString() + index}>
						<EffectThumbnail
							key={effects[0].filter_sticker_id}
							imageUrl={buildImagePath(effects[0].image_path)}
							onClick={() => handleClick(effects[0] as Filter_Sticker)}
							maxAmountReached={maxAmountReached}
							className={styles.sticker}
							onDragStart={unselectStickers}
							onDragEnd={(e) => hangleDragEnd(e, effects[0])}
							draggable
						/>
						{!!effects[1] && (
							<EffectThumbnail
								key={effects[1].filter_sticker_id}
								imageUrl={buildImagePath(effects[1].image_path)}
								onClick={() => handleClick(effects[1] as Filter_Sticker)}
								maxAmountReached={maxAmountReached}
								className={styles.sticker}
								onDragStart={unselectStickers}
								onDragEnd={(e) => hangleDragEnd(e, effects[1])}
								draggable
							/>
						)}
					</Box>
				),
				linkTo: {}
			}
		})
	}, [effects.length, maxAmountReached])

	const carousel = useMemo(
		() => (
			<CarouselOfAny
				slidesPerPage={{ sm: 4, md: 5, xl: 8 }}
				spacing={1}
				centerSlide
				arrowType="secondary"
				data={stickers}
				renderItem={(item) => (item.element ? item.element : <div></div>)}
			/>
		),
		[effects.length, maxAmountReached]
	)

	return carousel
}
