import { createStyles, makeStyles } from '@material-ui/core'

type VideoPlayerStylesProps = {
	imageUrl: string
}

export const useVideoPlayerStyles = makeStyles(() =>
	createStyles({
		videoHolder: {
			width: '100%',
			margin: '0 auto',
			height: '500px'
		},
		videoBackground: {
			backgroundImage: (props: VideoPlayerStylesProps) => `url(${props.imageUrl})`,
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat'
		}
	})
)

export default useVideoPlayerStyles
