import { AssignmentAccordion } from 'src/components/AssignmentAccordion'
import { LessonCardList } from 'src/components/LessonCardList/LessonCardList'
import { useAssignmentsLessons } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/useAssignmentsLessons'

type AssignmentsLessonsProps = {
	classId: number
}

export const AssignmentsLessons = ({ classId }: AssignmentsLessonsProps) => {
	const { totalLessons, lessonPlans, seeMoreEnabled, seeMore, loading } = useAssignmentsLessons({
		classId
	})

	return (
		<AssignmentAccordion
			countTitle="Draft Assignments from Saved Lesson Plans"
			count={totalLessons}
			details={
				<LessonCardList
					lessonPlans={lessonPlans}
					seeMore={seeMoreEnabled}
					onSeeMore={seeMore}
					loading={loading}
					selectedClassId={classId}
				/>
			}
		/>
	)
}
