import React, { useState, useEffect } from 'react'

import { styled, Tab, Tabs, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { WurrliesGrid } from 'src/components/Grids/WurrliesGrid'
import { Privacy_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useSearchQuery } from 'src/hooks/useQuery'
import { StudentPages } from 'src/routes/studentpages'
import { buildRouteParameters, shouldSortTitleOrDate, SortOrder } from 'src/utils'
import { ClassWurrlyType } from 'src/utils/enums'

import { useClassDetailsContext } from '../hooks/useClassDetails'
import { useClassWurrlies, useClassWurrliesProps } from '../hooks/useClassWurrlies'

export type ClassWurrlyDisplayConfig = {
	wurrlyType?: ClassWurrlyType
	sortBy?: SortOrder
	highlightWurrlyId?: number
}

export const ClassWurrlies = () => {
	const [currentTab, setCurrentTab] = useState<ClassWurrlyType>(ClassWurrlyType.ClassWurrlies)
	const [sort, setSort] = useState(SortOrder.Recent)
	const {
		studentData: { student_id: studentId }
	} = useLoginContext()
	const { classId } = useClassDetailsContext()
	const [queryVariables, setQueryVariables] = useState<useClassWurrliesProps>({ classId })
	const [highlightWurrlyId, setHighlightWurrlyId] = useState<number>()
	const searchQuery = useSearchQuery()
	const history = useHistory()

	const { count, wurrlies, loading } = useClassWurrlies(queryVariables)

	useEffect(() => {
		const [orderTitle, orderDate] = shouldSortTitleOrDate(sort)
		const variables: useClassWurrliesProps = { classId, orderTitle, orderDate }
		if (currentTab === ClassWurrlyType.MyWurrlies) {
			variables.studentId = studentId
			variables.classId = classId
			variables.privacySettings = [Privacy_Enum.OnlyMe, Privacy_Enum.TeacherAndMe, Privacy_Enum.Class]
		}
		setQueryVariables(variables)
	}, [currentTab, sort, classId])

	useEffect(() => {
		// Set initial tab, sort, and highlight if specified in search query
		const wurrlyType = searchQuery.get('wurrlyType')
		const sortBy = searchQuery.get('sortBy')
		const highlightWurrlyId = searchQuery.get('highlightWurrlyId')

		if (wurrlyType) setCurrentTab(wurrlyType as ClassWurrlyType)
		if (sortBy) setSort(sortBy as SortOrder)
		if (highlightWurrlyId) setHighlightWurrlyId(Number(highlightWurrlyId) ?? undefined)
	}, [searchQuery])

	const handleWurrly = (wurrlyId: number, classId: number) => {
		history.push(
			buildRouteParameters(StudentPages.ClassWurrly, {
				classId,
				wurrlyId
			})
		)
	}

	return (
		<WurrliesGrid
			count={count}
			wurrlies={
				currentTab === ClassWurrlyType.MyWurrlies
					? wurrlies.filter((i) => i.privacy === Privacy_Enum.OnlyMe || Privacy_Enum.TeacherAndMe)
					: wurrlies.filter(
							(i) => i.student.student_id !== studentId && i.privacy === Privacy_Enum.Class
					  )
			}
			loading={loading}
			moreResults={wurrlies.length < count}
			sort={{ sort, handleSort: setSort }}
			title={<TabSwitcher currentTab={currentTab} setCurrentTab={setCurrentTab} />}
			highlightId={highlightWurrlyId}
			onWurrlyClick={handleWurrly}
			currentTab={currentTab}
		/>
	)
}

const StyledTabs = styled(Tabs)({
	minWidth: '60px',

	'& .MuiTabs-indicator': {
		backgroundColor: '#1D273D',
		height: '3px'
	},
	'& .MuiTab-root': {
		textTransform: 'none'
	}
})

type TabSwitcherProps = {
	currentTab: ClassWurrlyType
	setCurrentTab: React.Dispatch<React.SetStateAction<ClassWurrlyType>>
}

const TabSwitcher = ({ currentTab, setCurrentTab }: TabSwitcherProps) => {
	const history = useHistory()
	const handleChangeTab = (_: React.ChangeEvent<unknown>, newValue: ClassWurrlyType) => {
		setCurrentTab(newValue)
		if (currentTab === ClassWurrlyType.MyWurrlies) {
			history.push({
				pathname: '',
				search: '',
				hash: ''
			})
		}
	}

	return (
		<StyledTabs value={currentTab} onChange={handleChangeTab}>
			<Tab
				value={ClassWurrlyType.ClassWurrlies}
				label={
					<Typography color={currentTab === ClassWurrlyType.ClassWurrlies ? 'primary' : undefined}>
						<strong>Class Wurrlies</strong>
					</Typography>
				}
			/>
			<Tab
				value={ClassWurrlyType.MyWurrlies}
				label={
					<Typography color={currentTab === ClassWurrlyType.MyWurrlies ? 'primary' : undefined}>
						<strong>My Wurrlies</strong>
					</Typography>
				}
			/>
		</StyledTabs>
	)
}
