import { LessonsWrapper } from 'src/components/Lessons/LessonsWrapper'
import { ModulesWrapper } from 'src/components/Modules/ModulesWrapper'
import { ClassLessonsTitle } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassLessons/components/ClassLessonsTitle'

export const ClassLessons = () => {
	return (
		<>
			<ClassLessonsTitle />
			<LessonsWrapper />
			<ModulesWrapper />
		</>
	)
}
