import { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import {
	useGetClassesByStudentQuery,
	useGetWurrliesByStudentIdLazyQuery,
	useGetWurrlyTypesQuery
} from 'src/graphql/autogenerate/hooks'
import { Order_By, Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { buildRouteParameters, MAX_LIST_ITEMS, SortOrder } from 'src/utils'

import { LocationState } from './types'

export enum WurrlyDisplayType {
	Grid = 'grid',
	List = 'list'
}

export const useMyWurrlies = () => {
	const location = useLocation()

	const state = location.state as LocationState
	const stateType = (state?.challenge as Wurrly_Type_Enum) || 'all'

	const [sort, setSort] = useState(SortOrder.Recent)
	const { studentData: student } = useLoginContext()
	const [limit, setLimit] = useState(MAX_LIST_ITEMS)
	const [isFetching, setIsFetching] = useState(false)
	const [isErrorOpen, setIsErrorOpen] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [display, setDisplay] = useState<WurrlyDisplayType>(WurrlyDisplayType.Grid)
	const [count, setCount] = useState(0)
	const [wurrlies, setWurrlies] = useState<typeof queryWurrlies>([])
	const [wurrlyType, setWurrlyType] = useState<Wurrly_Type_Enum | 'all'>(stateType)
	const [classId, setClassId] = useState<number | 'all'>('all')
	const history = useHistory()

	const [load, { data, loading, fetchMore }] = useGetWurrliesByStudentIdLazyQuery({
		variables: {
			studentId: student.student_id,
			limit,
			orderDate: sort === SortOrder.Recent ? Order_By.Desc : undefined,
			orderTitle: sort === SortOrder.Up ? Order_By.Asc : sort === SortOrder.Down ? Order_By.Desc : undefined,
			type: wurrlyType !== 'all' ? wurrlyType : null,
			classId: classId !== 'all' ? classId : null
		}
	})

	const { data: wurrlyTypesData } = useGetWurrlyTypesQuery()
	const { data: classesData } = useGetClassesByStudentQuery({
		variables: {
			studentId: student.student_id
		},
		skip: !student.student_id
	})

	const queryWurrlies = data?.wurrly || []

	const totalWurrlies = data?.wurrly_aggregate.aggregate?.count ?? 0

	useEffect(() => {
		load()

		/* setWurrlyType(stateType) */
	}, [])

	useEffect(() => {
		if (data?.wurrly) {
			setWurrlies(data?.wurrly)
			setCount(data?.wurrly_aggregate?.aggregate?.count ?? 0)
		}
	}, [data])

	useEffect(() => {
		if (!loading) setIsFetching(false)
	}, [loading])

	useEffect(() => {
		if (!isFetching) return
		if (!loading && fetchMore) {
			const currentLength = wurrlies.length
			fetchMore({
				variables: {
					offset: currentLength,
					limit: MAX_LIST_ITEMS
				}
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.wurrly.length !== 0) {
					setLimit(currentLength + (fetchMoreResult.data?.wurrly.length || 0))
					setMoreResults(true)
				} else {
					setMoreResults(false)
					setIsFetching(false)
				}
			})
		}
	}, [isFetching])

	const handleSortType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		if (sort) {
			const newSort = event.target.value as SortOrder
			setSort(newSort)
		}
	}

	const handleSelectWurrlyType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		if (event.target.value) {
			setWurrlyType(event.target.value as Wurrly_Type_Enum)
		} else {
			setWurrlyType('all')
		}
	}

	const handleSelectClass = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		if (event.target.value) {
			setClassId(event.target.value as number)
		} else {
			setClassId('all')
		}
	}

	const goToWurrlyDetails = (wurrlyId: number, classId: number) => {
		history.push(
			buildRouteParameters(StudentPages.ClassWurrlyRouteMyWurrlies, {
				classId,
				wurrlyId,
				route: 'my-wurrlies'
			})
		)
	}

	return {
		totalWurrlies,
		wurrlies,
		loading,
		fetchMore,
		limit,
		setLimit,
		isFetching,
		setIsFetching,
		moreResults,
		setMoreResults,
		count,
		setCount,
		display,
		setDisplay,
		sort,
		handleSortType,
		wurrlyType,
		setWurrlyType,
		wurrlyTypes: wurrlyTypesData?.wurrly_type ?? [],
		handleSelectWurrlyType,
		handleSelectClass,
		classes: classesData?.class ?? [],
		classId,
		goToWurrlyDetails,
		isErrorOpen,
		setIsErrorOpen
	}
}
