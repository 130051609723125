import { GetStudentQuestionaryQuestion } from '../../../../../../../graphql/autogenerate/schemas'
import { StudentSignInOptions } from '../../../../../enums'
import { useStudentSignInOptionsContext } from '../useStudentSignInOptions'
import { AvatarSelectionDialog } from './AvatarSelectionDialog/AvatarSelectionDialog'
import { QRCodeDialog } from './QRCodeDialog/QRCodeDialog'
import { SelectOptionDialog } from './SelectOptionDialog'
import { StudentCodeDialog } from './StudentCodeDialog/StudentCodeDialog'
import { StudentLoginPage } from './StudentLoginPage/StudentLoginPage'
import { VerificationDialog } from './VerificationDialog/VerificationDialog'

export const StudentSelectSignInOptions = () => {
	const { dialog, GetStudentQuestionaryData } = useStudentSignInOptionsContext()

	const studentQuestionary = (GetStudentQuestionaryData?.getStudentQuestionary?.questions ||
		[]) as GetStudentQuestionaryQuestion[]

	const getDialog = {
		[StudentSignInOptions.QRCode]: () => <QRCodeDialog />,
		[StudentSignInOptions.StudentCode]: () => <StudentCodeDialog />,
		[StudentSignInOptions.Email]: () => <StudentLoginPage />,
		studentVerification: () => (
			<VerificationDialog question="Hi! What’s Your School Name?" options={studentQuestionary} />
		),
		privateTeacherVerification: () => (
			<VerificationDialog question="Hi! What’s Your Teacher’s Name?" options={studentQuestionary} />
		),
		default: () => <SelectOptionDialog />,
		avatar: () => <AvatarSelectionDialog />
	}

	return getDialog[dialog]()
}
