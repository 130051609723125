import { Box, Typography } from '@material-ui/core'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import clsx from 'clsx'
import { ActionButton } from 'src/components'
import { Asset } from 'src/graphql/autogenerate/schemas'
import { buildImagePath, downloadLink } from 'src/utils'

import useStyles from './AssetItem.styles'

type AssetItemProps = {
	asset: Asset
}
export const AssetItem = ({ asset }: AssetItemProps) => {
	const styles = useStyles()

	const handleDownload = () => downloadLink(buildImagePath(asset.resource_path))

	return (
		<Box className={styles.container}>
			<ActionButton
				onClick={handleDownload}
				className={clsx([styles.downloadButton, styles.ellipsis])}
				color="secondary"
				icon={<VerticalAlignBottomIcon />}
			/>
			<Typography className={styles.ellipsis} variant="caption">
				<b>{asset.name}</b>
			</Typography>
		</Box>
	)
}
