import { useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'

import { PagesProps } from '../../../../../@types'
import { cache } from '../../../../../apollo/state'
import { AddByClassCode, AddByCsv } from '../../../../../assets/icons'
import {
	ControlTower,
	IconActionOptionType,
	ListDialog,
	MainTitle,
	SelectClassDialog,
	SelectPackageDialog
} from '../../../../../components'
import { useGetTeacherQuery } from '../../../../../graphql/autogenerate/hooks'
import { useLoginContext } from '../../../../../hooks/useLogin'
import { useStudentsContext } from '../useStudents'

enum AddStudentsOptsEnum {
	upload = 'upload',
	classCode = 'classCode'
}

export const StudentsTitle = ({ page }: PagesProps) => {
	const {
		teacherData: teacher,
		teacherTemporarilyEnterprise,
		setTeacherTemporarilyEnterprise
	} = useLoginContext()
	const [isAddStudentsOpen, setIsAddStudentsOpen] = useState(false)
	const [isManualInviteOpen, setIsManualInviteOpen] = useState(false)
	const [selectedClass, setSelectedClass] = useState<{ class_id: number; title: string }>()
	const { isSelectClassOpen, setIsSelectClassOpen, isSelectPackageOpen, setIsSelectPackageOpen } =
		useStudentsContext()

	// refetch
	const { data: teacherData } = useGetTeacherQuery({
		variables: {
			teacherId: teacher.teacher_id
		},
		fetchPolicy: 'network-only'
	})

	// this functions relies on webhook to update that value properly
	// otherwise the fetchPolicy: 'network-only' is going to update it again
	const updateTeacherToEnterprise = () => {
		if (teacherData?.teacher_by_pk) {
			setTeacherTemporarilyEnterprise(true)
			cache.modify({
				id: cache.identify(teacherData?.teacher_by_pk),
				fields: {
					is_enterprise() {
						return true
					}
				},
				broadcast: false // Include this to prevent automatic query refresh */
			})
		}
	}

	const addStudentsOpts: IconActionOptionType[] = [
		{
			id: AddStudentsOptsEnum.upload,
			name: (
				<Box>
					<Typography style={{ fontSize: 24, paddingTop: 12 }}>Upload CSV</Typography>
					<Typography style={{ fontSize: 12 }}>Bulk Invitation</Typography>
				</Box>
			),
			icon: <img src={AddByCsv} />
		},
		{
			id: AddStudentsOptsEnum.classCode,
			name: (
				<Box>
					<Typography style={{ fontSize: 24, paddingTop: 12 }}>Class Code</Typography>
					<Typography style={{ fontSize: 12 }}>Send to Your Students</Typography>
				</Box>
			),
			icon: <img src={AddByClassCode} />
		}
	]

	return (
		<Box>
			<MainTitle
				breadcrumbs={[page]}
				title={'Your Students'}
				description="View and manage all of your Students here"
				rightActions={[
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <AddCircle />,
						name: 'Add Students',
						onClick: () => {
							if (
								teacher.is_enterprise ||
								teacher.is_school_enterprise ||
								teacher.is_district_enterprise ||
								teacherTemporarilyEnterprise
							)
								setIsSelectClassOpen(true)
							else setIsSelectPackageOpen(true)
						}
					}
				]}
			/>
			<SelectPackageDialog
				open={isSelectPackageOpen}
				setIsOpen={setIsSelectPackageOpen}
				onSuccess={() => setIsSelectClassOpen(true)}
				updateTeacherToEnterprise={updateTeacherToEnterprise}
			/>
			<SelectClassDialog
				title="Select Which Class You Want To Add The Student To"
				description="Your Student will be able to access all the content and assignments that you have added to the Class, and record and collaborate with other Students!"
				isOpen={!!isSelectClassOpen}
				setIsOpen={setIsSelectClassOpen}
				itemClasses={[]}
				onConfirm={async ({ class_id, title }) => {
					setSelectedClass({ class_id, title })
					setIsAddStudentsOpen(true)
				}}
			/>
			{selectedClass && (
				<ControlTower
					classId={selectedClass.class_id}
					classTitle={selectedClass.title}
					isOpen={isAddStudentsOpen}
					setIsOpen={setIsAddStudentsOpen}
				/>
			)}
			<ListDialog
				open={isManualInviteOpen}
				cols={2}
				onClose={() => setIsManualInviteOpen(false)}
				title="Add Students"
				description="How would you like to add your Students?"
				discardLabel="Cancel"
				onDiscard={() => setIsManualInviteOpen(false)}
				onDiscardProps={{
					style: {
						width: '100%'
					}
				}}
				optionSelected={''}
				options={addStudentsOpts}
				handleSelectOption={(opt: string) => {
					switch (opt) {
						case AddStudentsOptsEnum.upload:
							break
						case AddStudentsOptsEnum.classCode:
							break
						default:
							break
					}
					setIsManualInviteOpen(false)
				}}
				cardHeight={160}
				cardWidth={237}
			/>
		</Box>
	)
}
