import { Avatar, Box, Typography } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { PagesProps } from '../../../../../../../../../@types'
import { BaseLoading, MainTitle } from '../../../../../../../../../components'
import { Pages } from '../../../../../../../../../routes/teacherPages'
import { buildBreadCrumbs, buildRouteParameters } from '../../../../../../../../../utils'
import { buildImagePath, slugToName } from '../../../../../../../../../utils/utils'
import { useStudentAnalyticsContext } from '../useStudentAnalytics'

export const StudentAnalyticsTitle = ({ page }: PagesProps) => {
	const history = useHistory()
	const { data: student, loading, studentId } = useStudentAnalyticsContext()
	const breadCrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(
					`${student?.student_by_pk?.first_name ?? ''} ${student?.student_by_pk?.last_name ?? ''}`
				)
			},
			{
				index: 2,
				overrideName: 'Analytics'
			}
		],
		params: {
			studentId
		}
	})

	return (
		<Box>
			{loading && (
				<Box m={3} textAlign="center">
					<BaseLoading />
				</Box>
			)}
			{!loading && (
				<MainTitle
					breadcrumbs={breadCrumbs}
					title={
						<Box display="flex">
							<Avatar
								style={{
									width: '80px',
									height: '80px',
									marginRight: '25px',
									opacity: student?.student_by_pk?.is_active ? 1 : 0.5
								}}
								src={buildImagePath(student?.student_by_pk?.image_path || '')}
							/>
							<Box style={{ opacity: student?.student_by_pk?.is_active ? 1 : 0.5 }}>
								<Typography variant="h3">{`${student?.student_by_pk?.first_name ?? ''} ${
									student?.student_by_pk?.last_name ?? ''
								}`}</Typography>
								<Typography color="textSecondary">{student?.student_by_pk?.email}</Typography>
							</Box>
						</Box>
					}
					rightActions={[
						{
							variant: 'contained',
							color: 'primary',
							startIcon: <KeyboardArrowLeft />,
							name: 'Back to Student Details',
							style: { padding: '0 30px' },
							onClick: () => {
								history.push(
									buildRouteParameters(
										Pages.StudentDetail, // Route with paramters
										{ studentId }
									)
								)
							}
						}
					]}
				/>
			)}
		</Box>
	)
}
