import { useEffect, useState } from 'react'

import { Box, ButtonProps, Paper, PaperProps, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import clsx from 'clsx'
import { CartIcon } from 'src/assets/icons'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { StatusProductButton } from 'src/components/Buttons/StatusProductButton/StatusProductButton'
import { Maybe, Playlist, Visibility_Enum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath, getPlaylistGenres } from 'src/utils'

import useStyles from './PlaylistCard.styles'

export type PlaylistCardProps = PaperProps & {
	playlist: Playlist
	onDelete?: () => void
	onEdit?: () => void
	editTooltip?: string
	deleteTooltip?: string
	onActiveChange?: (active: boolean) => void
	onView?: () => void
	btnSubscriptions?: ButtonProps
	btnAddClass?: { selected?: boolean } & ButtonProps
}

export const PlaylistCard = ({
	playlist,
	onClick,
	onView,
	btnSubscriptions,
	btnAddClass,
	...props
}: PlaylistCardProps) => {
	const styles = useStyles({ imageUrl: buildImagePath(playlist.image_path) || '' })
	const [visibility, setVisibility] = useState<Visibility_Enum>()
	const [subscription, setSubscription] = useState<Maybe<boolean>>()
	const [status, setStatus] = useState('')
	const [isDistrict, setIsDistrict] = useState(true)

	useEffect(() => {
		if (playlist) {
			setVisibility(playlist.lockable_content?.visibility)
			setSubscription(playlist.is_purchased_by_teacher)
			setStatus(playlist.get_status_by_teacher as string)
			setIsDistrict(playlist.lockable_content ? playlist.lockable_content?.districts?.length > 0 : true)
		}
	}, [playlist])
	const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target !== e.currentTarget || !onClick) return
		onClick(e)
	}

	return (
		<Paper {...props} onClick={handleOnClick}>
			<Box className={styles.container}>
				<Box className={playlist.image_path ? styles.imageContainer : styles.blank} onClick={onClick}>
					<Box className={styles.opacity} />
					<Typography variant="h5" className={clsx(styles.ellipsis, styles.title)}>
						{playlist.name}
					</Typography>

					<Box className={styles.flag}>
						<Typography className={clsx(styles.flagText)}>
							<PlaylistPlayIcon />
							{playlist.tip_playlist_aggregate.aggregate?.count} Videos
						</Typography>
					</Box>
				</Box>

				<Box className={styles.details}>
					<Typography className={clsx(styles.ellipsis, styles.description)} onClick={onClick}>
						{playlist.description}
					</Typography>
					<Typography className={clsx(styles.genres, styles.ellipsis)} onClick={onClick}>
						• {getPlaylistGenres(playlist).join(', ')}
					</Typography>
					{visibility === Visibility_Enum.ForSale && (
						<StatusProductButton
							isSubscription={subscription as boolean}
							isDistrict={isDistrict}
							status={status}
						/>
					)}
					<Box className={styles.buttons}>
						<Tooltip title="View">
							<BaseButton
								color="primary"
								className={styles.viewButton}
								endIcon={<ArrowForwardIcon />}
								onClick={onView}>
								<Typography>View</Typography>
							</BaseButton>
						</Tooltip>
						{btnAddClass &&
							(visibility !== Visibility_Enum.ForSale ||
								(visibility === Visibility_Enum.ForSale && subscription)) && (
								<BaseButton
									color="primary"
									className="addClass"
									endIcon={<AddIcon />}
									{...btnAddClass}>
									<Typography>Add to class</Typography>
								</BaseButton>
							)}
						{visibility === Visibility_Enum.ForSale && !subscription && (
							<BaseButton
								className={styles.subscription}
								startIcon={<CartIcon style={{ color: '#F3AB34' }} />}
								{...btnSubscriptions}>
								<Typography className={styles.subscribe}>Subscribe to Playlist</Typography>
							</BaseButton>
						)}
					</Box>
				</Box>
			</Box>
		</Paper>
	)
}
