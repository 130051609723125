import { Fragment, useEffect, useState } from 'react'

import { Box, Checkbox, InputBase, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { WurrlyYellowLogo } from 'src/assets/icons/CustomIcons'
import { BaseLink, InfoDialog } from 'src/components'
import { BaseDialog } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import { LoginPages } from 'src/routes/loginPages'
import { buildRouteParameters } from 'src/utils/routes-utils'

import { PasswordInput } from '../../../../Login/components/PasswordInput'
import { useStudentAccountActivationStyles } from '../styles'
import { useStudentAccountActivationContext } from '../useStudentAccountActivation'

export const StudentAccountActivationDialog = () => {
	const history = useHistory()
	const classes = useStudentAccountActivationStyles()
	const {
		code,
		studentEmail,
		setStudentActivationMutation,
		setStudentActivationData,
		setStudentActivationLoading,
		setStudentActivationError
	} = useStudentAccountActivationContext()
	const [createPassword, setCreatePassword] = useState<string>('')
	const [confirmPassword, setConfirmPassword] = useState<string>('')
	const [isTermsOfService, setIsTermsOfService] = useState(false)
	const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false)
	const [isOnErrorOpen, setIsOnErrorOpen] = useState(false)
	// const [isFalsyParams, setIsFalsyParams] = useState(false)
	const [passwordFulfillRequirements, setPasswordFulfillRequirements] = useState<boolean>(false)

	// useEffect(() => {
	// 	if (!code || !studentEmail) {
	// 		setIsFalsyParams(true)
	// 	}
	// }, [code, studentEmail])

	useEffect(() => {
		if (setStudentActivationError) setIsOnErrorOpen(true)
	}, [setStudentActivationError])

	useEffect(() => {
		if (
			!setStudentActivationLoading &&
			setStudentActivationData &&
			setStudentActivationData.studentActivation?.activation &&
			!setStudentActivationError
		)
			history.push(buildRouteParameters(LoginPages.Student))
	}, [setStudentActivationData, setStudentActivationLoading])

	const handleStudentActivation = async () => {
		if (!(createPassword.length > 0 && createPassword !== confirmPassword) && code && studentEmail) {
			try {
				await setStudentActivationMutation({
					variables: {
						filter: { code, studentEmail, password: confirmPassword }
					}
				})
			} catch (e) {
				console.error(e)
			}
		}
	}

	return (
		<Box>
			<BaseDialog
				dividers={false}
				open
				onClose={() => {}}
				backdropStyles={{ opacity: '0 !important' }}
				paperProps={{ style: { maxHeight: 'calc(100% - 100px)', width: '100%', maxWidth: '550px' } }}
				header={
					<Fragment>
						<Box display="flex" flexDirection="column" alignItems="center">
							<WurrlyYellowLogo />
							<Typography className={classes.title}>
								<b>Let's Activate You Student Account!</b>
							</Typography>
						</Box>
						<Box>
							<Typography className={classes.label}>
								<b>Email</b>
							</Typography>

							<InputBase disabled className={classes.input} value={studentEmail} fullWidth={true} />

							<Typography className={classes.label}>
								<b>Create Password</b>
							</Typography>

							<PasswordInput
								password={createPassword}
								setPassword={setCreatePassword}
								setFulfillRequirements={setPasswordFulfillRequirements}
								style={
									createPassword.length > 0 && !passwordFulfillRequirements
										? { border: '1px solid red' }
										: {}
								}
							/>
							{createPassword.length > 0 && !passwordFulfillRequirements && (
								<Typography style={{ color: 'red' }}>
									Password don't fulfill requirements
								</Typography>
							)}

							<Typography className={classes.label}>
								<b>Confirm Password</b>
							</Typography>

							<PasswordInput
								password={confirmPassword}
								setPassword={setConfirmPassword}
								disableValidation={true}
								style={
									createPassword.length > 0 && createPassword !== confirmPassword
										? { border: '1px solid red' }
										: {}
								}
							/>
							{createPassword && confirmPassword && createPassword !== confirmPassword && (
								<Typography style={{ color: 'red' }}>Passwords don't match</Typography>
							)}
							<Box mt={1} display="flex" flexWrap="nowrap" alignItems="center">
								<Checkbox
									className={classes.checkbox}
									onClick={() => setIsTermsOfService(!isTermsOfService)}
									checked={isTermsOfService}
								/>
								<Typography variant="caption" className={classes.checkboxText}>
									I Have read and accept the WURRLY{' '}
									<BaseLink href="https://www.wurrlyedu.com/terms">
										<Typography variant="caption">Terms of Service.</Typography>
									</BaseLink>
								</Typography>{' '}
							</Box>
							<Box>
								<Checkbox
									className={classes.checkbox}
									onClick={() => setIsPrivacyPolicy(!isPrivacyPolicy)}
									checked={isPrivacyPolicy}
								/>
								<Typography variant="caption" className={classes.checkboxText}>
									I Have read, underatand and accept the WURRLY{' '}
									<BaseLink href="https://www.wurrlyedu.com/privacy">
										<Typography variant="caption">Privacy Policy.</Typography>
									</BaseLink>
								</Typography>{' '}
							</Box>
						</Box>
					</Fragment>
				}
				confirmLabel="Activate"
				isConfirmDisabled={!isTermsOfService || !isPrivacyPolicy}
				onConfirmProps={{
					style: {
						margin: '0 auto',
						textTransform: 'capitalize',
						fontWeight: 'bold',
						padding: '6px 30px'
					}
				}}
				onConfirm={() => {
					handleStudentActivation()
				}}
			/>
			<InfoDialog
				open={!code || !studentEmail}
				onClose={() => {}}
				icon="!"
				title="Invalid Params"
				body="Please verify and try again"
				// confirmLabel="Done"
				// onConfirm={() => {}}
			/>
			<InfoDialog
				open={isOnErrorOpen}
				onClose={() => {}}
				icon="x"
				title="Something went wrong"
				body="Contact us for support"
				confirmLabel="Done"
				onConfirm={() => {
					setIsOnErrorOpen(false)
				}}
			/>
		</Box>
	)
}
