import { Box, TextField, TextFieldProps, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'

import { InputHeader, InputHeaderProps } from './InputHeader'

const useStyles = makeStyles(() =>
	createStyles({
		inputDefault: {
			width: '100%',
			padding: '10px 0'
		}
	})
)

type AutoCompleteTmp<T> = AutocompleteProps<T, false, false, false> & {
	inputProps?: TextFieldProps
}
type Props<T> = InputHeaderProps & Omit<AutoCompleteTmp<T>, 'renderInput'> & { capitalize?: boolean }

export const DropdownInput = <T extends { name: string }>({
	name,
	isRequired,
	popOverLabel,
	capitalize,
	inputProps,
	...ext
}: Props<T>) => {
	const styles = useStyles()

	return (
		<Box>
			<InputHeader name={name} isRequired={isRequired} popOverLabel={popOverLabel} />
			<Autocomplete
				className={styles.inputDefault}
				ChipProps={{
					dataCy: 'selected-subject',
					deleteIcon: (
						<Typography
							data-cy="remove-subjects-selected"
							// className={classes.closeChipIcon}
						>
							x
						</Typography>
					)
				}}
				autoHighlight
				ListboxProps={
					{
						// className: classes.listBox
					}
				}
				closeIcon={<CloseIcon />}
				{...ext}
				renderInput={(params) => (
					<TextField
						{...params}
						error={inputProps?.error}
						helperText={inputProps?.helperText}
						variant="outlined"
						color="secondary"
						placeholder={inputProps?.placeholder || 'Select one'}
						inputProps={{
							style: capitalize ? { textTransform: 'capitalize' } : {},
							...params.inputProps
						}}
					/>
				)}
			/>
		</Box>
	)
}
