import { useEffect, useState } from 'react'

import { Box, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { QueryCatalogItemsType } from 'src/@types'
import { CarouselOfAny, ImageButton, ImageButtonProps } from 'src/components'
import { Catalog, Catalog_Item } from 'src/graphql/autogenerate/schemas'
import { capitalize } from 'src/utils'
import { DEFAULT_MUSIC_BG } from 'src/utils/constants'
import { generateUniqueId } from 'src/utils/utils'

import { useMusicCatalogContext } from '../useMusicCatalog'

type CatalogsProps = {
	data: QueryCatalogItemsType
	classId?: number
}

type CustomCatalog = Omit<Catalog, 'catalog_items'> & {
	catalog_items: Catalog_Item[]
}

export const Catalogs = ({ data, classId }: CatalogsProps) => {
	const { catalog_item: catalogItems } = data
	const [catalogs, setCatalogs] = useState<CustomCatalog[]>([])
	const { setCatalogId, setCatalogItemId } = useMusicCatalogContext()

	useEffect(() => {
		const listCatalogs: CustomCatalog[] = []
		catalogItems.forEach((item) => {
			const found = listCatalogs.find((c) => c.catalog_id === item.catalog.catalog_id)
			if (!found) {
				listCatalogs.push({
					...item.catalog,
					catalog_items: [
						{
							...item,
							catalog_id: item.catalog.catalog_id
						}
					]
				})
			} else {
				found.catalog_items.push({
					...item,
					catalog_id: item.catalog.catalog_id
				})
			}
		})
		setCatalogs(listCatalogs)
	}, [classId])

	return (
		<div>
			{catalogs.map(({ name: catalogName, catalog_items }) => {
				return (
					<div key={generateUniqueId()}>
						<Box mt={6} mb={2}>
							<Typography variant="h6">
								<b>{capitalize(catalogName)}</b>
							</Typography>
						</Box>
						<CarouselOfAny
							slidesPerPage={{ xs: 2, sm: 3, md: 4 }}
							spacing={3}
							data={[
								...catalog_items.map((item) => ({
									id: item.catalog_item_id,
									backgroundImageUrl: item.image_path || DEFAULT_MUSIC_BG,
									title: (
										<Typography style={{ maxWidth: '90px', textAlign: 'center' }}>
											{item.name}
										</Typography>
									),
									icon: item.icon_path || '',
									catalog: item.catalog,
									catalog_item_id: item.catalog_item_id
								})),
								{
									backgroundColor: '#221f31',
									title: 'All ' + catalogName,
									element: <SearchIcon />
								}
							]}
							renderItem={(
								item: ImageButtonProps & { catalog?: Catalog; catalog_item_id?: number }
							) => {
								const buttonOnClick = () => {
									if (item?.catalog?.catalog_id) {
										setCatalogId(item.catalog.catalog_id)
									}
									if (item?.catalog_item_id) {
										setCatalogItemId(item.catalog_item_id)
									}
								}

								return (
									<ImageButton
										backgroundColor={item.backgroundColor}
										backgroundImageUrl={item.backgroundImageUrl}
										title={item.title}
										icon={item.icon}
										element={item.element}
										onClick={buttonOnClick}
									/>
								)
							}}
						/>
					</div>
				)
			})}
		</div>
	)
}
