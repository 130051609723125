import {
	StudioSongPlayerDialog,
	StudioSongPlayerDialogProps
} from 'src/components/Dialogs/StudioDialog/StudioSongPlayerDialog'

import { useStudioSongPlayer } from './useStudioSongPlayerDialog'

export type StudioSongPlayerDialogContainerProps = {
	songId?: number
} & Pick<StudioSongPlayerDialogProps, 'open' | 'onClose' | 'onEdit' | 'onDelete' | 'disableDelete'>

export const StudioSongPlayerDialogContainer = ({
	songId,
	open,
	...props
}: StudioSongPlayerDialogContainerProps) => {
	const stateProps = useStudioSongPlayer({ songId, skipQuery: !open, open })

	return <StudioSongPlayerDialog {...stateProps} {...props} open={open} />
}
