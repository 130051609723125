import { createStyles, makeStyles } from '@material-ui/core'

export const useStepTwoFormStyles = makeStyles(() =>
	createStyles({
		container: { marginTop: 30 },
		assetsList: {
			border: '1px solid rgba(0, 0, 0, 0.08)',
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 1px 4px 0px rgb(0 0 0 / 8%)',
			borderRadius: 4,
			marginTop: 15,
			padding: '0 20px'
		},
		title: { fontSize: 18 },
		noAssetsText: { marginTop: 15, marginBottom: 40 }
	})
)

export default useStepTwoFormStyles
