import { Grid } from '@material-ui/core'

import { ImageButton } from '../../../../../components'
import { CardTitle } from '../../../../../components/CardTitle'
import { Pages } from '../../../../../routes/teacherPages'
import {
	DEFAULT_CLASS_LESSON_IMG,
	DEFAULT_CLASS_SONG_IMG,
	LIBRARY_IMG,
	CAMERA_IMG
} from '../../../../../utils/constants'
import useStyles from './BrowseOurContent.styles'
import { IconButton } from './IconButton'
import { SectionHeader } from './SectionHeader'

const elementPosition = { right: 16, bottom: 16 }
export const BrowseOurContent = () => {
	const styles = useStyles()

	const TilesContent = {
		modules: {
			backgroundImageUrl: LIBRARY_IMG,
			linkTo: Pages.Modules.path,
			title: <CardTitle text="Explore Modules" bold />,
			element: <IconButton icon="notebook" text="Modules" />,
			elementPosition
		},
		videos: {
			backgroundImageUrl: CAMERA_IMG,
			linkTo: Pages.Videos.path,
			title: <CardTitle text="Watch Videos" bold />,
			element: <IconButton icon="videos" text="Videos" />,
			elementPosition
		},
		music: {
			backgroundImageUrl: DEFAULT_CLASS_SONG_IMG,
			linkTo: Pages.MusicCatalog.path,
			title: <CardTitle text="Discover Music" bold />,
			element: <IconButton icon="musicNote" text="Music Catalog" />,
			elementPosition
		},
		lessons: {
			backgroundImageUrl: DEFAULT_CLASS_LESSON_IMG,
			linkTo: Pages.Lessons.path,
			title: <CardTitle text="Find Lessons" bold />,
			element: <IconButton icon="notebook" text="Lessons" />,
			elementPosition
		}
	}

	return (
		<section>
			<SectionHeader content="Browse Our Content" />
			<Grid container spacing={1} className={styles.container}>
				<Grid item xs={4}>
					<ImageButton
						titlePosition="initial"
						className={styles.image}
						{...TilesContent.modules}
						titleHeight="100%"
						wrapperProps={{ style: { height: '100%' } }}
					/>
				</Grid>

				<Grid item xs={8} className={styles.fullheight}>
					<Grid container spacing={1} className={styles.fullheight}>
						<Grid item xs={6}>
							<ImageButton
								className={styles.image}
								titlePosition="initial"
								{...TilesContent.music}
							/>
						</Grid>
						<Grid item xs={6}>
							<ImageButton
								className={styles.image}
								titlePosition="initial"
								{...TilesContent.videos}
							/>
						</Grid>
						<Grid item xs={12}>
							<ImageButton
								className={styles.bottomImage}
								titlePosition="initial"
								{...TilesContent.lessons}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</section>
	)
}
