import { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { LiteralTypes, PagesProps } from '../../../../../../../@types'
import { DeleteItemDialog, ListVideos, MainTitle } from '../../../../../../../components'
import {
	useDeletePlaylistFromClassMutation,
	useGetClassTeacherByPkQuery,
	useGetPlaylistDetailsByClassQuery
} from '../../../../../../../graphql/autogenerate/hooks'
import { Playlist } from '../../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../../hooks/useLogin'
import { Pages } from '../../../../../../../routes/teacherPages'
import { COLORS } from '../../../../../../../styles/colors'
import { buildRouteParameters } from '../../../../../../../utils'
import { buildBreadCrumbs, slugToName } from '../../../../../../../utils/utils'

export const ClassVideosPlayListDetails = ({ page }: PagesProps) => {
	const params = useParams<LiteralTypes<typeof Pages.ClassVideosPlaylistDetails.params[number]>>()
	const classId = Number(params.classId)
	const playlistId = Number(params.playlistId)
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const { data, loading } = useGetPlaylistDetailsByClassQuery({
		variables: {
			teacherId,
			classId,
			playlistId
		}
	})
	const [classVideosPlaylistDetails, setClassVideosPlaylistDetails] = useState<Playlist>()
	const [classVideosPlaylistDetailsCount, setClassVideosPlaylistDetailsCount] = useState(0)
	const [videosPlaylistIdToDelete, setVideosPlaylistIdToDelete] = useState<number>()
	const history = useHistory()
	const [deletePlaylistFromClass] = useDeletePlaylistFromClassMutation()
	const { data: classData } = useGetClassTeacherByPkQuery({ variables: { teacherId, classId } })
	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
			},
			{
				index: 4,
				overrideName: classVideosPlaylistDetails?.name ?? page.name
			}
		],
		params
	})

	useEffect(() => {
		if (!loading && data && data.teacher_by_pk?.class_teachers[0]?.class?.class_playlists[0]) {
			const playlist =
				(data.teacher_by_pk?.class_teachers[0]?.class.class_playlists[0].playlist as Playlist) || undefined
			setClassVideosPlaylistDetails(playlist)
			const count =
				data.teacher_by_pk?.class_teachers[0]?.class.class_playlists[0]?.playlist.tip_playlist_aggregate
					.aggregate?.count || 0
			setClassVideosPlaylistDetailsCount(count)
		}
	}, [data, loading])

	return (
		<Box>
			{loading && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}
			{!loading && (
				<Box>
					<MainTitle
						breadcrumbs={breadcrumbs}
						title={classVideosPlaylistDetails?.name ?? page.name}
						description={classVideosPlaylistDetails?.description ?? ''}
						rightActions={[
							{
								style: {
									backgroundColor: COLORS.error.main,
									color: 'white',
									height: 56,
									padding: '0px 30px'
								},
								variant: 'contained',
								startIcon: <DeleteIcon />,
								name: 'Delete Playlist',
								onClick: () => {
									setVideosPlaylistIdToDelete(playlistId)
								}
							}
						]}
					/>
					<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>There are {classVideosPlaylistDetailsCount} Videos in this Playlist</b>
						</Typography>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs>
							<ListVideos
								teacherId={teacherId}
								data={classVideosPlaylistDetails?.tip_playlist?.map((item) => item.tip) ?? []}
								spacing={1.5}
								viewButton
							/>
						</Grid>
					</Grid>
					<DeleteItemDialog
						itemName="Playlist"
						isOpen={!!videosPlaylistIdToDelete}
						setIsOpen={setVideosPlaylistIdToDelete}
						onConfirm={async () => {
							if (videosPlaylistIdToDelete) {
								await deletePlaylistFromClass({
									variables: {
										classId,
										playlistId: videosPlaylistIdToDelete
									},
									update: (cache, { data }) => {
										const classToUpdateInCache =
											data?.delete_class_playlist?.returning[0].class
										if (!classToUpdateInCache) return
										const identify = cache.identify(classToUpdateInCache)
										cache.evict({
											id: identify,
											fieldName: 'class_playlists'
										})
										cache.evict({
											id: 'ROOT_QUERY',
											fieldName: 'playlist'
										})
									}
								})
							}
						}}
						optionalMessages={{
							mainMessages: {
								title: 'Delete this Playlist from your Class?',
								body: "You'll be able to re-add it from the Videos page at any time.",
								buttonLabel: 'Delete Playlist'
							},
							confirmation: {
								body: 'You have successfully deleted this Playlist from your Class.'
							}
						}}
						onDone={() => {
							history.push(
								buildRouteParameters(
									Pages.ClassVideos, // Route with paramters
									{ classId }
								)
							)
						}}
					/>
				</Box>
			)}
		</Box>
	)
}
