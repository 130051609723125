import { makeStyles } from '@material-ui/core'

export const useSongCardStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		minHeight: '4em',
		'&:not(:last-child)': {
			marginBottom: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
		}
	},
	image: {
		borderRadius: '10px',
		height: '4em',
		width: '4em',
		marginRight: '1em'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	title: {
		lineHeight: 1.2,
		fontWeight: 'bold'
	},
	subtitle: {
		fontSize: '1em'
	},
	catalogs: {
		color: 'rgb(160, 160, 160)',
		lineHeight: 1.2,
		fontSize: '1em'
	},
	iconButton: {
		padding: 0,
		alignSelf: 'center'
	}
}))

export default useSongCardStyles
