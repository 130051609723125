import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
	createStyles({
		backgroundBox: {
			backgroundColor: theme.palette.primary.main,
			width: '100%',
			height: '100%',
			position: 'fixed',
			left: '0',
			top: '0'
		}
	})
)

type FixedBackgroundType = {
	logo?: JSX.Element
	children: JSX.Element | JSX.Element[]
	className?: string
}

export const FixedBackground = ({ children, logo, ...props }: FixedBackgroundType) => {
	const classes = useStyles()

	return (
		<Box className={classes.backgroundBox} {...props}>
			{logo}
			{children}
		</Box>
	)
}
