import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import clsx from 'clsx'

import useStyles from '../styleAvatarSelectionDialog'

type StudentAvatarPropsType = {
	isSelected: boolean
	onClickHandler: () => void
	imgUrl?: string
	stageNameInitials?: string
}
export const StudentAvatar = ({
	isSelected,
	onClickHandler,
	imgUrl,
	stageNameInitials,
	...props
}: StudentAvatarPropsType) => {
	const styles = useStyles()

	return (
		<div className={styles.container}>
			<Avatar
				alt="Avatar Icon"
				src={imgUrl || ''}
				className={clsx(styles.avatar, { [styles.selectedAvatar]: isSelected })}
				onClick={() => onClickHandler()}
				style={
					!imgUrl
						? { background: 'linear-gradient(43deg, rgba(214,24,24,1) 0%, rgba(255,171,0,1) 100%)' }
						: {}
				}
				{...props}>
				{stageNameInitials || ''}
			</Avatar>
			{isSelected && <CheckIcon className={styles.checkIcon} />}
		</div>
	)
}
