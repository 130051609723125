import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useChallengePopupStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: { marginBottom: '20px' },
		description: {
			marginTop: '15px',
			marginLeft: '12px',
			wordBreak: 'break-word'
		},
		title: {
			maxWidth: '600px',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textAlign: 'left',
			display: 'block'
		},
		subtitle: {
			fontSize: '18px'
		},
		container: {
			position: 'relative'
		},
		thumbnail: {
			width: '600px',
			height: '360px'
		},
		carouselContainter: {
			width: '700px',
			padding: '0px 50px',
			position: 'relative'
		},
		headerBadge: {
			alignItems: 'flex-end',
			backgroundColor: '#F0D6FF',
			display: 'flex',
			height: 70,
			justifyContent: 'center',
			position: 'absolute',
			right: theme.spacing(5),
			top: 0,
			width: 60,
			'&:before, &:after': {
				bottom: -20,
				content: '""',
				display: 'block',
				height: 15,
				position: 'absolute',
				width: '50%'
			},
			'&:before': {
				borderBottom: '25px solid transparent',
				borderLeft: '36.5px solid #F0D6FF',
				left: 0
			},
			'&:after': {
				borderBottom: '25px solid transparent',
				borderRight: '36.5px solid #F0D6FF',
				right: 0
			}
		},
		challengeLogo: {
			marginBottom: 10
		},
		viewButton: {
			marginRight: '10px',
			width: '118px'
		},
		closeButton: {
			fontSize: '0.75rem'
		}
	})
)

export default useChallengePopupStyles
