import { createStyles, makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useLicencesInfoStyles = makeStyles<Theme>(() =>
	createStyles({
		divider: {
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			height: 1
		},
		licencesBox: {
			backgroundColor: 'white',
			padding: '30px',
			marginTop: '20px',
			borderRadius: 4,
			boxShadow:
				'0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.15)'
		},
		smallSquareChip: {
			height: 15,
			borderRadius: 4,
			backgroundColor: 'red',
			marginLeft: 10,
			fontSize: 12,
			color: 'white'
		},
		title: {
			fontWeight: 500,
			fontSize: '17px',
			lineHeight: '20px'
		},
		textOpacity: {
			fontWeight: 500,
			fontSize: '15px',
			lineHeight: '24px',
			color: ' rgba(46, 38, 66, 0.6)',
			letterSppacing: '0.15px'
		},
		text: {
			fontWeight: 500,
			fontSize: '17px',
			lineHeight: '24px',
			letterSppacing: '0.15px'
		}
	})
)

export default useLicencesInfoStyles
