import { Box, BoxProps, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { formartESNU } from 'src/components/GradeStar/GradeStar'
import { Grading_System } from 'src/graphql/autogenerate/schemas'
import { capitalize } from 'src/utils/utils'

import useStyles from './GradeSquare.styles'

export type GradeSquareProps = BoxProps & {
	grade: string | undefined
	gradingSystem?: Pick<Grading_System, 'label' | 'values'> | undefined
	teacherComment?: string
}
export const GradeSquare = ({ grade, gradingSystem, teacherComment, ...props }: GradeSquareProps) => {
	const styles = useStyles({ grade, gradingSystem, teacherComment })

	if (
		gradingSystem?.label?.includes('Esnu') ||
		['excellent', 'satisfactory', 'needs improvement', 'unsatisfactory'].includes(grade as string)
	) {
		return (
			<Tooltip title={capitalize(grade) || '--'} placement="top" arrow>
				<Box {...props} className={clsx(styles.boxESNU, props.className)}>
					<Typography className={styles.gradeESNU} variant="h6" data-testid="grade-text">
						{formartESNU((grade as string) || '--')}
					</Typography>
				</Box>
			</Tooltip>
		)
	}

	return (
		<Box {...props} className={clsx(styles.box, props.className)}>
			<Typography className={styles.grade} variant="h6" data-testid="grade-text">
				{grade || '--'}
			</Typography>
		</Box>
	)
}
