import { Box, createStyles, Divider, Grid, makeStyles } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { ChallengeDialogContainer } from 'src/components/ChallengeDialog/ChallengeDialog.container'
import { BOX_PADDING } from 'src/styles/constants'

import { StudentAssignments } from './components/ClassAssignments'
import { StudentContent } from './components/StudentContent/StudentContent'
import { StudentGoals } from './components/StudentGoals/StudentGoals'
import { HomeTitle } from './components/StudentHomeTitle'
import { StudentWurrlies } from './components/StudentWurrlies'
import { StudentClassDetailsProvider } from './hooks/useClassDetails'

const useStyles = makeStyles(() =>
	createStyles({
		innerBox: {
			paddingTop: 0
		},
		divider: {
			margin: '2em 0 4em'
		}
	})
)

export const StudentHome = (props: PagesProps) => {
	const classes = useStyles()

	return (
		<Box>
			<StudentClassDetailsProvider>
				<HomeTitle {...props} />
				<Box p={BOX_PADDING} className={classes.innerBox}>
					<StudentContent />
					<Divider className={classes.divider} variant="middle" />
					<Grid container wrap="nowrap" spacing={2}>
						<StudentAssignments />
						<StudentGoals />
					</Grid>
					<StudentWurrlies />
				</Box>
			</StudentClassDetailsProvider>
			<ChallengeDialogContainer />
		</Box>
	)
}
