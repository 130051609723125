import { useEffect, useState } from 'react'

import { cache } from 'src/apollo/state'
import { buildGenericContext } from 'src/context/genericContext'
import {
	useGetClassAssignmentByPkLazyQuery,
	useUpdateClassAssignmentStatusMutation,
	useUpdateSubmissionsMutation,
	GetClassAssignmentsDocument
} from 'src/graphql/autogenerate/hooks'
import {
	Class_Assignment_Status_Enum,
	Grading_System,
	Submission,
	Submission_State_Enum
} from 'src/graphql/autogenerate/schemas'

import { ActionDialogsEnum } from './components/ActionDialogs/ActionDialogs'
import { useSortedSubmissions } from './components/StudentsFilter/useSortedSubmissions'

const useGrading = () => {
	const [submissions, setSubmissions] = useState<Submission[]>([])
	const [totalGraded, setTotalGraded] = useState(0)
	const [submission, setSubmission] = useState<Submission[]>([])
	const [gradingSystem, setAsignmentGradingSystem] =
		useState<Pick<Grading_System, 'label' | 'values'> | undefined>()
	const [currentCarouselPosition, setCurrentCarouselPosition] = useState(0)
	const [nextCarouselPosition, setNextCarouselPosition] = useState(0)
	const [assignmentDueDateString, setAssignmentDueDateString] = useState('')
	const [gradingValues, setGradingValues] = useState<string[] | number[]>([])
	const [classAssignmentId, setClassAssignmentId] = useState(0)

	// Dialogs states
	const [isGoBackWithouSavingOpen, setIsGoBackWithouSavingOpen] = useState(false)
	const [isConfirmGradesOpen, setIsConfirmGradesOpen] = useState(false)
	const [isFinishGradingOpen, setIsFinishGradingOpen] = useState(false)
	const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
	const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
	const [isChangeSubmissionWithoudSavingOpen, setIsChangeSubmissionWithoudSavingOpen] = useState(false)
	const totalSubmissions = submissions.length
	const [submissionsMutation, { data: mutationData, error: mutationError, loading: mutationLoading }] =
		useUpdateSubmissionsMutation()
	const [updateClassAssignmentStatusMutation] = useUpdateClassAssignmentStatusMutation()
	// For the filter dropdown
	const { sortedSubmissions, sortOrder, setSortOrder } = useSortedSubmissions(submissions, gradingValues)

	const [getSubmissions, { data: submissionsData }] = useGetClassAssignmentByPkLazyQuery()

	useEffect(() => {
		setSubmission((submissionsData?.class_assignment_by_pk?.submission as Submission[]) || [])
	}, [submissionsData])

	useEffect(() => {
		const totalGradedSubmissions =
			gradingSystem?.label !== 'None'
				? submissions.filter((i) => i.grade).length
				: submissions.filter((i) => i.teacher_comment).length
		setTotalGraded(totalGradedSubmissions)
	}, [submissions])

	useEffect(() => {
		if (mutationError) return openDialog(ActionDialogsEnum.MutationError)
		if (!mutationError && !mutationLoading && mutationData) {
			closeDialog(ActionDialogsEnum.ConfirmGrades)
			if (totalGraded === totalSubmissions) {
				openDialog(ActionDialogsEnum.FinishedGrading)
			} else {
				openDialog(ActionDialogsEnum.MutationSuccess)
			}
		}
	}, [mutationData, mutationError, mutationLoading])

	// Open the dialog or go to next slide
	useEffect(() => {
		goToNextCarouselPosition()
	}, [nextCarouselPosition])

	const isDialogOpen = (dialog: ActionDialogsEnum) => {
		switch (dialog) {
			case ActionDialogsEnum.GoBackWithouSaving:
				return isGoBackWithouSavingOpen
			case ActionDialogsEnum.ConfirmGrades:
				return isConfirmGradesOpen
			case ActionDialogsEnum.FinishedGrading:
				return isFinishGradingOpen
			case ActionDialogsEnum.MutationError:
				return isErrorDialogOpen
			case ActionDialogsEnum.MutationSuccess:
				return isSuccessDialogOpen
			case ActionDialogsEnum.ChangeSubmissionWithoutGrading:
				return isChangeSubmissionWithoudSavingOpen
			default:
				return false
		}
	}

	const closeDialog = (dialog: ActionDialogsEnum) => {
		switch (dialog) {
			case ActionDialogsEnum.GoBackWithouSaving:
				setIsGoBackWithouSavingOpen(false)
				break
			case ActionDialogsEnum.ConfirmGrades:
				setIsConfirmGradesOpen(false)
				break
			case ActionDialogsEnum.FinishedGrading:
				setIsFinishGradingOpen(false)
				break
			case ActionDialogsEnum.MutationSuccess:
				setIsSuccessDialogOpen(false)
				break
			case ActionDialogsEnum.MutationError:
				setIsErrorDialogOpen(false)
				break
			case ActionDialogsEnum.ChangeSubmissionWithoutGrading:
				setIsChangeSubmissionWithoudSavingOpen(false)
				break
		}
	}

	const openDialog = (dialog: ActionDialogsEnum) => {
		switch (dialog) {
			case ActionDialogsEnum.GoBackWithouSaving:
				setIsGoBackWithouSavingOpen(true)
				break
			case ActionDialogsEnum.ConfirmGrades:
				setIsConfirmGradesOpen(true)
				break
			case ActionDialogsEnum.FinishedGrading:
				setIsFinishGradingOpen(true)
				break
			case ActionDialogsEnum.MutationSuccess:
				setIsSuccessDialogOpen(true)
				break
			case ActionDialogsEnum.MutationError:
				setIsErrorDialogOpen(true)
				break
			case ActionDialogsEnum.ChangeSubmissionWithoutGrading:
				setIsChangeSubmissionWithoudSavingOpen(true)
				break
		}
	}

	// Update submission passing the index position of the element to change
	const updateSubmission = (index: number, key: 'grade' | 'teacher_comment', value: string) => {
		const newSubmissions = [...submissions]
		const currSubmission = newSubmissions[index]
		currSubmission[key] = value.toString()
		setSubmissions(newSubmissions)
	}

	const [totalSubmissionGraded, setTotalSubmissionGraded] = useState(0)
	const [totalSubmissionSubmitted, setTotalSubmissionSubmitted] = useState(0)

	useEffect(() => {
		setTotalSubmissionGraded(
			submission.filter((i) => i.state === Submission_State_Enum.Graded || i.teacher_comment).length
		)

		setTotalSubmissionSubmitted(submission.length)
	}, [submission])

	useEffect(() => {
		if (totalSubmissionGraded === totalSubmissionSubmitted) {
			updateClassAssignmentStatusMutation({
				variables: {
					status: Class_Assignment_Status_Enum.Graded,
					classAssignmentId
				},
				onCompleted: (data) => {
					cache.evict({
						id: `class_assignment:${data?.update_class_assignment_by_pk?.class_assignment_id}`,
						fieldName: 'status'
					})
				}
			})
		}
	}, [totalSubmissionGraded, totalSubmissionSubmitted])
	const updateSubmissionsMutation = () => {
		submissionsMutation({
			variables: {
				submissions: submissions.map(({ submission_id, grade, teacher_comment }) => {
					return {
						submission_id,
						grade,
						teacher_comment,
						class_assignment_id: 0,
						state: Submission_State_Enum.Graded
						// class_assignment_id: 0 wont affect anything, it is required for the mutation to work
					}
				})
			},
			refetchQueries: [
				{
					query: GetClassAssignmentsDocument,
					variables: {
						classAssignmentId
					}
				}
			],

			onCompleted: () => {
				cache.evict({ fieldName: 'submission' })

				getSubmissions({
					variables: {
						classAssignmentId
					},
					fetchPolicy: 'no-cache'
				})
			}
		})
	}

	const goToNextCarouselPosition = () => {
		setCurrentCarouselPosition(nextCarouselPosition)
	}

	const clearCurrentSubmission = () => {
		updateSubmission(currentCarouselPosition, 'grade', '')
		updateSubmission(currentCarouselPosition, 'teacher_comment', '')
	}

	const goToSelectedSubmission = (submission: Submission) => {
		const index = submissions.findIndex((s) => s.submission_id === submission.submission_id)
		setCurrentCarouselPosition(index !== -1 ? index : currentCarouselPosition)
	}

	return {
		submissions,
		updateSubmission,
		totalGraded,
		totalSubmissions,
		mutationLoading,
		isDialogOpen,
		closeDialog,
		openDialog,
		updateSubmissionsMutation,
		nextCarouselPosition,
		setNextCarouselPosition,
		goToNextCarouselPosition,
		clearCurrentSubmission,
		currentCarouselPosition,
		setSubmissions,
		sortedSubmissions,
		sortOrder,
		setSortOrder,
		goToSelectedSubmission,
		assignmentDueDateString,
		setAssignmentDueDateString,
		setGradingValues,
		setClassAssignmentId,
		setAsignmentGradingSystem,
		gradingSystem
	}
}

export const [GradingProvider, useGradingContext] = buildGenericContext(useGrading)
