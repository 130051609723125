import { useState, Fragment } from 'react'

import { Box, CircularProgress, TextField, Typography } from '@material-ui/core'
import { getCyKey } from 'src/utils'

import { BaseDialog, BigBaseButton } from '../../../components'
import { useLoginStyles } from '../styles'
import { DialogHeader } from './DialogHeader'
type SchoolDialogProps = {
	open: boolean
	onClose: () => void
	onConfirm: (campName: string, phoneNumber: string, comments: string) => void
	onDiscard: () => void
	email: string
	isLoading?: boolean
}

export const CampDialog = ({ open, onClose, onConfirm, onDiscard, email, isLoading }: SchoolDialogProps) => {
	const classes = useLoginStyles({})
	const [campName, setCampName] = useState<string>('')
	const [phoneNumber, setPhoneNumber] = useState<string>('')
	const [comments, setComments] = useState<string>('')

	return (
		<BaseDialog
			open={open}
			paperProps={{ className: classes.dialogsPaper }}
			onClose={() => {
				if (onClose) onClose()
			}}
			backdropStyles={{ opacity: '0 !important' }}
			dividers={false}
			header={
				<DialogHeader title="Camp" subtitle="Please fill out this form and we'll get right back to you" />
			}
			isActionsHidden={true}
			body={
				<Fragment>
					<Box style={{ position: 'relative' }}>
						<Typography className={classes.label}>
							<b>What's the name of your camp?*</b>
						</Typography>
						<TextField
							style={{ width: '100%' }}
							value={campName}
							onChange={(e) => setCampName(e.target.value)}
							variant="outlined"
							color="secondary"
						/>
					</Box>
					<Box>
						<Typography className={classes.label}>
							<b>What’s your email?</b>
						</Typography>
						<TextField
							style={{ width: '100%' }}
							value={email}
							variant="outlined"
							color="secondary"
							disabled
						/>
					</Box>
					<Box>
						<Typography className={classes.label}>
							<b>What’s your phone number?*</b>
						</Typography>
						<TextField
							style={{ width: '100%' }}
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
							variant="outlined"
							color="secondary"
						/>
					</Box>
					<Box>
						<Typography className={classes.label}>
							<b>Any comment?</b>
						</Typography>
						<TextField
							style={{ width: '100%' }}
							value={comments}
							onChange={(e) => setComments(e.target.value)}
							variant="outlined"
							color="secondary"
						/>
					</Box>

					<Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-around">
						<BigBaseButton
							color="secondary"
							variant="contained"
							disabled={!campName || !phoneNumber || isLoading}
							className={classes.getStartedButton}
							onClick={() => {
								if (onConfirm) onConfirm(campName, phoneNumber, comments)
							}}>
							<b>Contact Us!</b>
							{isLoading && <CircularProgress style={{ marginLeft: '1em' }} />}
						</BigBaseButton>
						<span
							data-cy={getCyKey(CampDialog, 'btnGoBack')}
							className={classes.goBackButton}
							onClick={() => {
								if (onDiscard) onDiscard()
							}}>
							Go Back
						</span>
					</Box>
				</Fragment>
			}
		/>
	)
}
