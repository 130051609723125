import { useEffect, useState } from 'react'

import {
	useGetMsAccountLazyQuery,
	useGetMsGroupsLazyQuery,
	useGetMsoAuthUrlQuery,
	useGetMsStudentsByCourseIdLazyQuery,
	useGetMsTokenLazyQuery,
	useGetTeacherIntegrationLazyQuery,
	useInsertTeacherIntegrationMutation
} from '../../graphql/autogenerate/hooks'
import { Integration_Name_Enum, MsGroup } from '../../graphql/autogenerate/schemas'
import { IntegrationAccount, IntegrationStudent } from '../useIntegration'

type MsIntanceType = {
	setClasses: (classes: MsGroup[]) => void
	setStudents: (students: IntegrationStudent[]) => void
	setLoadingClasses: (loading: boolean) => void
	setLoadingStudents: (loading: boolean) => void
	teacherId: number
	setTeacherIntegrationId: (id: number) => void
}
export const useMSTeamsInstance = ({
	setClasses,
	setStudents,
	setLoadingClasses,
	setLoadingStudents,
	teacherId,
	setTeacherIntegrationId
}: MsIntanceType) => {
	const [account, setAccount] = useState<IntegrationAccount>()
	const [isOpen, setIsOpen] = useState(false)
	const [getMSTeamsToken, { data: msTeamsTokenData }] = useGetMsTokenLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const { data: loginUrl } = useGetMsoAuthUrlQuery({
		fetchPolicy: 'no-cache'
	})
	const [getMSTeamsStudents, { data: msTeamsStudentsData, loading: loadingStudents }] =
		useGetMsStudentsByCourseIdLazyQuery({
			fetchPolicy: 'no-cache'
		})
	const [getMSTeamsCourses, { data: msTeamsCoursesData, loading: loadingCourses }] = useGetMsGroupsLazyQuery({
		fetchPolicy: 'no-cache'
	})
	const [getMSTeamsAccount, { data: msTeamsAcountData, loading: msTeamsAccountLoading }] =
		useGetMsAccountLazyQuery({
			fetchPolicy: 'no-cache'
		})
	const [
		teacherIntegrationMutation,
		{ data: teacherIntegrationMutationData, loading: teacherIntegrationMutationLoading }
	] = useInsertTeacherIntegrationMutation()
	const [getTeacherIntegration, { data: teacherIntegrationData, loading: loadingTeacherIntegration }] =
		useGetTeacherIntegrationLazyQuery()
	// getting the courses when we get the token from be
	useEffect(() => {
		if (msTeamsTokenData?.getMsToken) {
			const token = msTeamsTokenData.getMsToken
			setAccount({
				...account,
				token
			})
			getMSTeamsCourses({
				variables: {
					token
				}
			})
			getMSTeamsAccount({
				variables: {
					token
				}
			})
		}
	}, [msTeamsTokenData])
	useEffect(() => {
		if (msTeamsCoursesData) {
			setAccount({
				...account,
				courses: msTeamsCoursesData?.getMsGroups?.map((i) => ({ name: i?.displayName, id: i?.id }))
			})
		}
	}, [msTeamsCoursesData])
	useEffect(() => {
		if (msTeamsAcountData && !msTeamsAccountLoading) {
			// const teacherEmail = msTeamsAcountData?.getMsAccount?.mail
			const integrationId = msTeamsAcountData?.getMsAccount?.id
			if (teacherId)
				getTeacherIntegration({
					variables: {
						teacherId,
						integrationName: Integration_Name_Enum.MicrosoftTeams,
						teacherEmail: integrationId as string
					}
				})
			if (integrationId)
				setAccount({
					...account,
					integrationId
				})
		}
	}, [msTeamsAcountData])

	useEffect(() => {
		if (
			teacherIntegrationData &&
			teacherIntegrationData?.teacher_integration.length === 0 &&
			!loadingTeacherIntegration &&
			teacherId &&
			account?.token &&
			!teacherIntegrationMutationLoading &&
			!teacherIntegrationMutationData &&
			msTeamsAcountData?.getMsAccount?.id
		) {
			insertTeacherIntegration()
		} else if (teacherIntegrationData?.teacher_integration[0]?.teacher_integration_id) {
			setTeacherIntegrationId(teacherIntegrationData.teacher_integration[0].teacher_integration_id)
		} else if (
			!teacherIntegrationData?.teacher_integration.length &&
			teacherIntegrationMutationData?.insert_teacher_integration_one?.teacher_integration_id
		) {
			setTeacherIntegrationId(
				teacherIntegrationMutationData?.insert_teacher_integration_one?.teacher_integration_id
			)
		}
	}, [
		teacherIntegrationData,
		loadingTeacherIntegration,
		teacherIntegrationMutationData,
		teacherIntegrationMutationLoading,
		msTeamsAcountData
	])

	// set students
	useEffect(() => {
		if (msTeamsStudentsData?.getMsMembers) {
			setStudents(msTeamsStudentsData.getMsMembers as IntegrationStudent[])
		}
	}, [msTeamsStudentsData])

	// set classes
	useEffect(() => {
		if (msTeamsCoursesData?.getMsGroups) {
			setClasses(msTeamsCoursesData.getMsGroups as MsGroup[])
		} else if (msTeamsCoursesData?.getMsGroups === null) {
			setClasses([])
		}
	}, [msTeamsCoursesData])

	useEffect(() => {
		setLoadingClasses(loadingCourses)
	}, [loadingCourses])

	useEffect(() => {
		setLoadingStudents(loadingStudents)
	}, [loadingStudents])

	// inserting the teacher_integration in db
	const insertTeacherIntegration = () => {
		if (account?.integrationId && teacherId) {
			const { integrationId: integration_id } = account
			teacherIntegrationMutation({
				variables: {
					teacher: {
						teacher_id: teacherId,
						integration_name: Integration_Name_Enum.MicrosoftTeams,
						integration_id,
						email: integration_id
					}
				},
				update: (cache, { data }) => {
					const integrationCacheUpdate = data?.insert_teacher_integration_one
					if (!integrationCacheUpdate) return
					const identify = cache.identify(integrationCacheUpdate)
					cache.evict({
						id: identify,
						fieldName: 'teacher_integration'
					})
				}
			})
		}
	}
	const openPopup = () => {
		if (isOpen) return

		return new Promise((resolve) => {
			setIsOpen(true)
			const url = loginUrl?.getMsAuthUrl || ''
			const loginWindow = window.open(url, 'name', 'height=600,width=450')
			if (loginWindow) {
				loginWindow.focus()

				try {
					const oauthInterval = setInterval(() => {
						try {
							if (loginWindow.closed) {
								setIsOpen(false)
								clearInterval(oauthInterval)
							}
							if (!loginWindow.location.href.includes('about:blank')) {
								const codeURL = new URL(loginWindow.location.href)
								const code = codeURL.searchParams.get('code')
								if (!code) return
								getMSTeamsToken({ variables: { code } })
								setClasses([])
								loginWindow.close()
								resolve(true)
							}
						} catch {
							// reject(e)
						}
					}, 200)
				} catch (e) {
					// reject(e)
				}
			}
		})
	}

	const getAccount = () => {
		return account
	}

	const getClassStudents = (classId: string) => {
		setStudents([])
		if (account?.token)
			getMSTeamsStudents({
				variables: {
					token: account.token as string,
					groupId: classId
				}
			})
	}

	const clearToken = () => {
		if (account) setAccount({ ...account, token: null })
	}

	return { openPopup, getAccount, getClassStudents, clearToken }
}
