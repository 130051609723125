import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useAssignmentAddedStyles = makeStyles((theme: Theme) =>
	createStyles({
		whiteColor: {
			color: theme.palette.common.white
		},
		addedAssignmentButton: {
			'&.MuiButton-contained': {
				backgroundColor: theme.palette.success.main,
				'&:hover': {
					backgroundColor: theme.palette.success.main
				}
			}
		}
	})
)

export default useAssignmentAddedStyles
