import { createStyles, makeStyles } from '@material-ui/core'

export const useGradingCardStyles = makeStyles(() =>
	createStyles({
		emptyCard: {
			marginTop: 40
		},
		label: {
			fontWeight: 'bold',
			fontSize: '1.2rem'
		},
		gradeSelector: {
			width: '100%',
			marginTop: 10,
			textTransform: 'capitalize'
		},
		inputSection: {
			margin: '25px 0'
		},
		gradingOption: {
			textTransform: 'capitalize'
		},
		download: {
			'& img': {
				marginRight: 5
			}
		},
		box: {
			display: 'flex',
			borderRadius: '4px',
			justifyContent: 'center',
			alignItems: 'center'
		},

		select: {
			width: '100%',
			textTransform: 'uppercase'
		},
		menuItem: {
			'&.Mui-selected': {
				background: 'rgba(243, 171, 52, 0.12)',
				color: 'rgba(243, 171, 52, 1)'
			}
		}
	})
)

export default useGradingCardStyles
