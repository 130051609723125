import { Fragment, useState } from 'react'

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	Divider,
	Drawer,
	IconButton,
	makeStyles,
	Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
	MusicStandars,
	musicStandard,
	Creating,
	Connecting,
	Performing,
	Responding
} from 'src/utils/MusicStandard'

import { Lesson_Plan } from '../graphql/autogenerate/schemas'
import { buildImagePath } from '../utils'
import { InfoDialog } from './'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiDrawer-paper ': {
			width: '30%'
		}
	},
	Accordion: {
		border: 'none',
		boxShadow: 'none',
		marginTop: 5,
		marginBottom: 0,
		'&:not(:last-child)': {
			borderBottom: 0
		},
		'&:before': {
			display: 'none'
		},
		'&$expanded': {
			margin: 0
		},
		'&.MuiAccordion-root.Mui-expanded': {
			marginTop: 5,
			marginBottom: 0
		},
		'&.MuiAccordionSummary-content.Mui-expanded': {
			marign: 0
		}
	},
	AccordionSumary: {
		backgroundColor: '#F0F2F5',
		border: 'none',
		marginBottom: -1,
		minHeight: 40,
		'&.MuiAccordionSummary-root.Mui-expanded': {
			minHeight: 40
		},
		'&.MuiAccordionSummary-content.Mui-expanded': {
			marign: 0
		},
		'&.Mui-expanded': {
			marign: 0
		}
	}
}))

type StandatdSideBarType = {
	onClose: () => void
	lesson?: Lesson_Plan
}

const StandardItem = ({
	standardName,
	standards,
	divider
}: {
	standardName: string
	standards: { [key: string]: { title: string; description: string }[] }
	divider: boolean
}) => {
	return (
		<Box display="block">
			<Typography style={{ fontWeight: 'bolder', fontSize: 16, paddingTop: 10 }}>{standardName}</Typography>
			{standards[standardName].map((standardDetails) => (
				<Box key={standardDetails.title}>
					<Typography style={{ fontSize: 15, padding: '10px 0' }}>{standardDetails.title}</Typography>
					<Typography style={{ color: '#1D273D', fontSize: 13, opacity: 0.6, padding: 0 }}>
						{standardDetails.description}
					</Typography>
				</Box>
			))}
			{divider && <Divider style={{ marginTop: 15 }} />}
		</Box>
	)
}
export const StandardSideBar = ({ onClose, lesson }: StandatdSideBarType) => {
	const classes = useStyles()
	const [info, setInfo] = useState<boolean>(false)
	const process = lesson?.process

	const acordeons = () => {
		const hasProcess =
			!!process?.connecting.length ||
			!!process?.creating.length ||
			!!process?.performing.length ||
			!!process?.responding.length
		if (!hasProcess) return null
		const acordeons = Object.keys(process).map((key) => {
			const data: musicStandard | null =
				key === MusicStandars.Creating
					? Creating
					: key === MusicStandars.Connecting
					? Connecting
					: key === MusicStandars.Performing
					? Performing
					: key === MusicStandars.Responding
					? Responding
					: null

			const optionsTitles = process[key]
			// If standard doesn't have any item, don't show it.
			if (!data || !optionsTitles || !optionsTitles?.length) return null
			const standards: { [key: string]: { title: string; description: string }[] } = {}
			data.subCategories.forEach((subCategory) => {
				for (const optionTitle of optionsTitles) {
					const option = subCategory.options.find((i) => i.title === optionTitle)
					if (option) {
						if (standards[subCategory.name]) {
							standards[subCategory.name].push({
								title: option.title,
								description: option.description
							})
						} else {
							standards[subCategory.name] = [
								{ title: option.title, description: option.description }
							]
						}
					}
				}
			})

			const descriptions = Object.keys(standards).map((standardName, index) => (
				<StandardItem
					key={standardName}
					standardName={standardName}
					standards={standards}
					divider={index < Object.keys(standards).length - 1}
				/>
			))

			return (
				<Accordion key={key} className={classes.Accordion}>
					<AccordionSummary
						className={classes.AccordionSumary}
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header">
						<Typography style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{key}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box>{descriptions}</Box>
					</AccordionDetails>
				</Accordion>
			)
		})

		return acordeons
	}

	return (
		<Fragment>
			<Drawer className={classes.root} anchor="right" open={!!lesson} onClose={onClose}>
				<Box margin={1}>
					<Box textAlign="right">
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Box>
					<Avatar variant="rounded" src={buildImagePath(lesson?.image_path ?? '')} />
					<Box marginTop="20px"></Box>
					<Typography variant="h6">
						{' '}
						<b>{lesson?.title}</b>
					</Typography>
					<Box display="flex">
						<Typography color="textSecondary" variant="subtitle2">
							{' '}
							Core Standards • Grades
						</Typography>
						<Typography style={{ marginLeft: 2 }} color="textSecondary" variant="subtitle2">
							{formatGrades(lesson?.lesson_plan_grade.map((grade) => grade.grade.name) || [])}
						</Typography>
					</Box>
				</Box>
				<Box>{acordeons}</Box>
			</Drawer>
			<InfoDialog
				open={info}
				onClose={() => setInfo(false)}
				title="Learn More About Teaching Standards"
				body="National Core Arts Standards: A Conceptual Framework, is a narrative document that outlines the philosophy, primary goals, dinamyc processes, structures, and outcomes that shape student learning and achievement in dance, media arts, music, theatre, and visual arts, as articulated in the National Core Arts Standards"
				bodyProps={{ variant: 'subtitle2', align: 'center' }}
				discardLabel="Go Back"
				onDiscard={() => setInfo(false)}
				icon="!"
				confirmLabel="Learn More"
				onConfirm={() => {
					// TODO: redirect to: https://www.nationalartsstandards.org/content/national-core-arts-standards#:~:text=National%20Core%20Arts%20Standards%3A%20A,the%20National%20Core%20Arts%20Standards
				}}
			/>
		</Fragment>
	)
}

const formatGrades = (grades: string[]) => {
	if (grades.length === 1) return grades[0]
	if (grades.length === 2) return `${grades[0]} & ${grades[1]}`

	const lastOne = grades[grades.length - 1]
	const rest = grades.slice(0, -1)
	const text = `${rest?.join(', ')}`

	return `${text} & ${lastOne}`
}

// given an array, return an new one without the last element
