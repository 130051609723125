import { WurrlyLogoIcon } from 'src/assets/icons'
import { FixedBackground } from 'src/components'
import { StudioReviewProvider } from 'src/components/Studio/Review/useStudioReview'
import { VideoReviewDialog } from 'src/components/Studio/Review/VideoReviewDialog'
import { useStudioBackgroundStyles } from 'src/components/Studio/Setup/styles/studioBackgroundStyles'

export const StudioReview = () => {
	const styles = useStudioBackgroundStyles()

	return (
		<FixedBackground
			logo={<WurrlyLogoIcon className={styles.logoPosition} />}
			className={styles.backgroundImage}>
			<StudioReviewProvider>
				<VideoReviewDialog />
			</StudioReviewProvider>
		</FixedBackground>
	)
}
