import { useState, useEffect } from 'react'

import {
	Box,
	Divider,
	IconButton,
	InputBase,
	InputBaseProps,
	Paper,
	CircularProgress,
	PaperProps
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import _ from 'lodash'

import useStyles from './SearchBar.styles'

export type SearchBarProps = InputBaseProps & {
	onEnterPressed?: (searchText: string) => void
	dropdownItems?: React.ReactNode[]
	loading?: boolean
	noResultsFound?: React.ReactNode
	hasDropDown?: boolean
	paperProps?: PaperProps
	initialValue?: string
}
export const SearchBar = ({
	onEnterPressed,
	dropdownItems,
	loading,
	noResultsFound,
	hasDropDown = true,
	paperProps,
	initialValue,
	...props
}: SearchBarProps) => {
	const styles = useStyles()
	const [searchText, setSearchText] = useState(initialValue || '')
	const [focused, setFocused] = useState(false)
	const [, setDebouncedQuery] = useState<_.DebouncedFunc<() => void>>()
	const [showDropDown, setShowDropDown] = useState(false)

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.persist()
		const value = e?.target?.value
		setSearchText(value)
		if (!value) setShowDropDown(false)
		const debouncedFunction = _.debounce(() => {
			if (props.onChange) props?.onChange(e)
		}, 300)
		if (props.onChange) {
			setDebouncedQuery((prevDebounce) => {
				if (prevDebounce?.cancel) prevDebounce?.cancel()

				return debouncedFunction
			})
		}
		debouncedFunction()
		if (value.length === 0) clearSearchText()
	}
	const clearSearchText = () => {
		if (onEnterPressed) onEnterPressed('')
		setSearchText('')
		if (props.onChange) {
			props?.onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
		}

		setShowDropDown(false)
	}
	const handleOnFocus = () => {
		setFocused(true)
		if (!loading && searchText) setShowDropDown(true)
	}
	const handleOnBlur = () => {
		setFocused(false)
	}
	const handleSearch = () => {
		setShowDropDown(false)
		if (onEnterPressed) onEnterPressed(searchText)
	}
	const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && onEnterPressed) {
			e.currentTarget.blur()
			setShowDropDown(false)
			onEnterPressed(searchText)
		}
	}

	useEffect(() => {
		if (initialValue && !searchText) setSearchText(initialValue)
	}, [initialValue])

	useEffect(() => {
		return () => {
			setSearchText('')
			if (props.onChange) {
				props?.onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
			}

			setShowDropDown(false)
		}
	}, [])

	useEffect(() => {
		if (!loading && focused && searchText) setShowDropDown(true)
	}, [loading, focused, searchText])

	return (
		<Box className={styles.container}>
			<Paper
				elevation={0}
				{...paperProps}
				className={clsx(
					{ [styles.boxInputFocus]: focused, [styles.boxInput]: !focused },
					paperProps?.className
				)}>
				<IconButton onClick={handleSearch} aria-label="search">
					<SearchIcon />
				</IconButton>
				<InputBase
					value={searchText}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					{...props}
					onKeyDown={onEnterPress}
					onChange={handleOnChange}
				/>
				{loading && (
					<Box className={styles.loader}>
						<CircularProgress size={30} />
					</Box>
				)}
				{!!searchText && (
					<IconButton onClick={clearSearchText}>
						<HighlightOffIcon />
					</IconButton>
				)}
			</Paper>
			{showDropDown && hasDropDown && (
				<Paper className={clsx({ [styles.itemsList]: true, [styles.scrollable]: dropdownItems?.length })}>
					{!!dropdownItems?.length &&
						dropdownItems.map((item, index) => (
							<Box key={index}>
								{item}
								{index < dropdownItems.length - 1 && <Divider className={styles.divider} />}
							</Box>
						))}
					{!dropdownItems?.length && !loading && (
						<Box className={styles.noResultsFound}>
							{noResultsFound ? noResultsFound : <Box>No results found</Box>}
						</Box>
					)}
				</Paper>
			)}
		</Box>
	)
}
