import { Divider, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { PagesProps } from 'src/@types'
import { BaseButton, ImageButton, MainTitle } from 'src/components'
import { IconButton } from 'src/components/Buttons/IconButton'
import { CardTitle } from 'src/components/CardTitle'
import { DateFilterEnum } from 'src/environment'
import { Pages } from 'src/routes/teacherPages'
import { useClassAssignments } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAssignments/useClassAssignments'
import { AssignmentsLessons } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsLessons'
import { AssignmentsNoSubmissions } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsNoSubmissions'
import { AssignmentsPreviouslyGraded } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsPreviouslyGraded'
import { AssignmentsToBeGraded } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsToBeGraded'
import { DEFAULT_CLASS_LESSON_IMG, DESKTOP_IMG } from 'src/utils'

import useStyles from './ClassAssignments.styles'

export const ClassAssignments = ({ page }: PagesProps) => {
	const styles = useStyles()
	const { breadcrumbs, classId } = useClassAssignments(page)

	const elementPosition = { right: 16, bottom: 16 }

	const TilesContent = {
		addAssignments: {
			backgroundImageUrl: DESKTOP_IMG,
			linkTo: Pages.AddAssignment.path,
			title: <CardTitle text="Add Your Own Assignments" bold />,
			element: (
				<BaseButton color="secondary" endIcon={<AddIcon />}>
					<Typography>Add Assignment</Typography>
				</BaseButton>
			),
			elementPosition,
			titleHeight: {
				xs: 150,
				sm: 250
			}
		},
		lessons: {
			backgroundImageUrl: DEFAULT_CLASS_LESSON_IMG,
			linkTo: Pages.Lessons.path,
			title: <CardTitle text="Find Lessons" bold />,
			element: <IconButton icon="notebook" text="Lessons" />,
			elementPosition,
			titleHeight: {
				xs: 150,
				sm: 250
			}
		}
	}

	return (
		<>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title="Class Assignments"
				description="These are the Assignments within this Class."
			/>
			<AssignmentsToBeGraded classId={classId} date={DateFilterEnum.allTime} />

			<AssignmentsPreviouslyGraded classId={classId} date={DateFilterEnum.allTime} />

			<AssignmentsNoSubmissions classId={classId} date={DateFilterEnum.allTime} />
			<Divider className={styles.divider} />
			<AssignmentsLessons classId={classId} />

			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<ImageButton titlePosition="initial" {...TilesContent.addAssignments} />
					</Grid>
					<Grid item xs={6}>
						<ImageButton titlePosition="initial" {...TilesContent.lessons} />
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}
