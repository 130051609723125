import { Box } from '@material-ui/core'

import { PagesProps } from '../../../../@types'
import { StudentSearch } from './components/StudentSearch/StudentSearch'
import { StudentsGrid } from './components/StudentsGrid/StudentsGrid'
import { StudentsGridProvider } from './components/StudentsGrid/useStudentsGrid'
import { StudentsTitle } from './components/StudentsTitle'
import { StudentsProvider } from './useStudents'

export const Students = (props: PagesProps) => {
	return (
		<Box>
			<StudentsGridProvider>
				<StudentsProvider>
					<StudentsTitle {...props} />
					<StudentSearch />
					<StudentsGrid />
				</StudentsProvider>
			</StudentsGridProvider>
		</Box>
	)
}
