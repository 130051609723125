import { Box, Typography } from '@material-ui/core'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { SubscriptionsCard } from 'src/components/SubscriptionsCard/SubscriptionsCard'
import { useProfileSubscription } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useProfileSubscription'

export const ProfileSubscription = () => {
	const { goToViewAllSubscriptions } = useProfileSubscription()

	return (
		<ProfileSection
			title="Your Subscriptions"
			body={<SubscriptionContent />}
			editBtn={{
				label: 'View All Subscriptions',
				action: goToViewAllSubscriptions
			}}
		/>
	)
}

const SubscriptionContent = () => {
	const { data, loading } = useProfileSubscription()

	return (
		<>
			<Box style={{ marginBottom: '3em' }}>
				{!loading &&
					data?.map((item) => {
						return <SubscriptionsCard key={item.orderId} data={item} allSubscriptionsCard={false} />
					})}

				{data.length === 0 && (
					<Typography
						style={{ display: 'flex', justifyContent: 'center' }}
						variant="h6"
						color="textSecondary">
						Looks like you're not currently subscribed to anything. Maximize your experience. Subscribe
						today to any of our products!
					</Typography>
				)}
			</Box>
		</>
	)
}
