import { Dispatch, SetStateAction } from 'react'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Product } from 'src/@types'
import { BaseButton } from 'src/components/Buttons/BaseButton'
import { useAddProductToClassDialog } from 'src/components/Dialogs/AddProductToClassDialog/useAddProductToClassDialog'
import { InputHeader, InputHeaderProps } from 'src/components/Inputs/InputHeader'
import { ListItemMedia } from 'src/components/ListItems/ListItemMedia'
import { BaseLoading } from 'src/components/Loading/BaseLoading'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { ProductDataType } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/useAllClassProducts'
import { ProductTitleEnum, buildImagePath, getLessonSubjects } from 'src/utils'

import { SearchBar } from '../../SearchBar/SearchBar'
import { BaseDialog, BaseDialogProps } from '../BaseDialog/BaseDialog'

type AddProductToClassDialogProps<T> = BaseDialogProps & {
	selectedData: ProductDataType
	setSelected: Dispatch<SetStateAction<T[]>>
	resultHeader?: InputHeaderProps
	handleCancel: () => void
	type: string
}

export const AddProductToClassDialog = <T extends Product>({
	selectedData,
	type,
	handleCancel,
	resultHeader,
	setSelected,
	...props
}: AddProductToClassDialogProps<T>) => {
	const {
		total,
		data,
		loading,
		fetchMore,
		handleEnterSearch,
		handleSelect,
		updateError,
		updateData,
		title,
		baseDialogClasses,
		handleSave
	} = useAddProductToClassDialog({ handleCancel, selectedData, setSelected, type, ...props })

	return (
		<>
			<BaseDialog
				dividers={false}
				maxWidth="md"
				fullWidth
				{...props}
				header={
					<Box display="flex" flexDirection="column" alignItems="center">
						<Typography className={baseDialogClasses.title}>
							<strong>Add {title}</strong>
						</Typography>
					</Box>
				}
				body={
					<Box>
						<SearchBar
							onEnterPressed={(search: string) => handleEnterSearch(search)}
							placeholder={`Search ${title} by Title, Subject or Keyword`}
						/>
						<InputHeader
							{...{ name: `${total} ${title}` }}
							className={clsx(resultHeader?.className)}
						/>
						<Box boxShadow={1} borderRadius={4}>
							{loading && (
								<Box m={3} textAlign="center" justifyContent={'center'}>
									<CircularProgress color="secondary" size={40} />
								</Box>
							)}
							<InfiniteScroll
								dataLength={data.length}
								next={fetchMore}
								hasMore={!loading && data?.length < total}
								loader={<BaseLoading />}
								height="35vh">
								{data.map((item, key) => (
									<ListItemMedia
										type={title as ProductTitleEnum}
										selected={item.selected}
										onSelected={() => handleSelect(item.item)}
										key={key}
										imageUrl={buildImagePath(item?.item.image_path as string)}
										title={
											type === ProductTitleEnum.Playlist
												? (item?.item.name as string)
												: (item?.item.title as string)
										}
										author={item?.item.description as string}
										genres={`${
											type === ProductTitleEnum.LessonPlan
												? `• ${getLessonSubjects(item as unknown as Lesson_Plan)}`
												: ''
										}`}
									/>
								))}
							</InfiniteScroll>
						</Box>
					</Box>
				}
				footer={
					props.footer || (
						<Box display="flex" justifyContent="space-between" flexGrow={1}>
							<BaseButton onClick={handleCancel} size="large">
								Close
							</BaseButton>
							<BaseButton onClick={handleSave} size="large" color="secondary">
								Save
							</BaseButton>
						</Box>
					)
				}
			/>
			<MutationDialog
				open={false}
				error={!!updateError}
				success={!!updateData}
				icon="question"
				successProps={{
					title: 'Success!',
					body: `You have successfully added this ${title} to the selected class(es)`
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while adding this ${title} to the selected class(es).`,
					icon: 'error'
				}}
			/>
		</>
	)
}
