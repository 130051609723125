import { useEffect } from 'react'

import { Avatar, Box, Typography } from '@material-ui/core'
import { DoubleMusicNoteIcon } from 'src/assets/icons'
import { useSearchMySongsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'

import useStyles from './AllSongsCard.styles'

export const AllSongsCard = () => {
	const styles = useStyles()
	const [loadMySongs, mySongs] = useSearchMySongsLazyQuery()
	const { teacherData } = useLoginContext()
	const queryVariables = {
		teacherId: teacherData.teacher_id
	}

	useEffect(() => {
		loadMySongs({ variables: queryVariables })
	}, [])

	const count = mySongs?.data?.song_aggregate?.aggregate?.count || 0

	return (
		<Box className={styles.container}>
			<Avatar className={styles.avatar}>
				<DoubleMusicNoteIcon />
			</Avatar>
			<Typography variant="h6" className={styles.text}>
				Whole Catalog Selected ({count})
			</Typography>
		</Box>
	)
}
