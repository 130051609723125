import { makeStyles } from '@material-ui/core'
export const useTooltipStyles = makeStyles(() => ({
	polygon: {
		position: 'absolute',
		background: 'white',
		transform: 'rotate(45deg)',
		width: '20px',
		zIndex: 10,
		height: '20px',
		borderLeft: '1px solid lightgray',
		borderBottom: '1px solid lightgray',
		top: '50%',
		left: '-10px'
	},
	box: {
		position: 'relative',
		width: '416px',
		height: '202px',
		background: '#FFFFFF',
		border: '1px solid #E1E1E1',
		boxSizing: 'border-box',
		boxShadow:
			'0px 60px 94px rgba(0, 0, 0, 0.0140573), 0px 25.0666px 39.271px rgba(0, 0, 0, 0.0201946), 0px 13.4018px 20.9961px rgba(0, 0, 0, 0.025), 0px 7.51293px 11.7703px rgba(0, 0, 0, 0.0298054), 0px 3.99006px 6.2511px rgba(0, 0, 0, 0.0359427), 0px 1.66035px 2.60122px rgba(0, 0, 0, 0.05)',
		borderRadius: '6px',
		padding: '23px 23px 24px 20px'
	},
	button: {
		width: '100px',
		height: '51.98px'
	},
	checkbox: {
		marginTop: '5px',
		padding: '0px',
		color: '#ffc20c'
	},
	checkboxLabel: {
		marginTop: '5px',
		marginLeft: '10px'
	}
}))
