import { makeStyles, createStyles } from '@material-ui/core/styles'

const useHomeStyles = makeStyles(() =>
	createStyles({
		center: {
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}
	})
)

export default useHomeStyles
