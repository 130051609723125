import { Box, styled } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { useScroll } from 'src/hooks'
import { BOX_PADDING } from 'src/styles/constants'

import { ProfileInfo } from './Components/ProfileInfo'
import { ProfileTitle } from './Components/ProfileTitle'

const PageContainer = styled(Box)({
	background: '#F3F5F8'
})

export const Profile = (props: PagesProps) => {
	useScroll()

	return (
		<PageContainer p={BOX_PADDING}>
			<ProfileTitle {...props} />
			<ProfileInfo />
		</PageContainer>
	)
}
