import { AccountActivationToken } from 'src/@types'
import { Wurrly_Role_Enum } from 'src/graphql/autogenerate/schemas'
import { validatePassword } from 'src/scenes/Login/components/PasswordInput'
import * as yup from 'yup'

export const isActivateTokenPayloadValid = (tokenPayload: AccountActivationToken): boolean => {
	const { userId, role, email } = tokenPayload
	if (
		!userId ||
		isNaN(userId) ||
		!role ||
		role !== Wurrly_Role_Enum.Teacher ||
		!email ||
		typeof email !== 'string'
	) {
		return false
	}

	return true
}

export const ActivateAccountFormValidationSchema = yup.object({
	email: yup.string().email().required(),
	password: yup
		.string()
		.test({
			name: 'Password',
			test(password: unknown) {
				return validatePassword(String(password))
			}
		})
		.required(),
	passwordConfirm: yup.string().test({
		name: 'Password Confirmation',
		test(passwordConfirm: unknown) {
			return String(this.parent.password) === String(passwordConfirm)
		}
	}),
	acceptedTermsOfService: yup.boolean().oneOf([true]),
	acceptedPrivacyPolicy: yup.boolean().oneOf([true]),
	isTokenValid: yup.boolean().oneOf([true]),
	tokenHasExpired: yup.boolean().oneOf([false])
})
