import React, { useState, Fragment, useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { Camp, PrivateTeacher, School } from 'src/assets/icons'
import { BigBaseButton, BaseDialog, InfoDialog } from 'src/components'
import { useCreateCampTeacherContactMutation } from 'src/graphql/autogenerate/hooks'
import { Role_Name_Enum, Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { SignUpData } from 'src/scenes/Login/enums'
import { getCyKey } from 'src/utils'

import { CampDialog } from '../CampDialog'
import { PrivateTeacherDialog } from '../PrivateTeacherDialog'
import { SchoolDialog } from '../SchoolDialog'
import useStyles from './SelectRoleDialog.styles'
type SelectRoleDialogProps = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onClose?: () => void
	setSignUpRole: (role: Role_Name_Enum) => void
	setSchoolId: (schoolId: number) => void
	setPrivateTeacherData: (organizationName: string, address: string) => void
	onSignUp: () => void
	isLoading: boolean
	signUpData: SignUpData
	integrationName?: Integration_Name_Enum
}

export const SelectRoleDialog = ({
	open,
	setOpen,
	onClose,
	setSignUpRole,
	setSchoolId,
	setPrivateTeacherData,
	onSignUp,
	isLoading,
	signUpData,
	integrationName
}: SelectRoleDialogProps) => {
	const styles = useStyles()
	const [isSchoolDialogOpen, setIsSchoolDialogOpen] = useState<boolean>(false)
	const [isPrivateTeacherDialogOpen, setIsPrivateTeacherDialogOpen] = useState<boolean>(false)
	const [isCampDialogOpen, setIsCampDialogOpen] = useState<boolean>(false)
	const [createContact, { loading: isCreateContactLoading }] = useCreateCampTeacherContactMutation()
	const [createContactSuccess, setCreateContactSuccess] = useState(false)

	// skip the first step: role selection, and go directly to the next step as "Im a school"
	useEffect(() => {
		// we are applying this settimout hack because signedUpWithClever is set to true at the same time as the dialog is open
		// so, we need to wait for the dialog to open before we setOpen(false)
		switch (integrationName) {
			case Integration_Name_Enum.Clever:
			case Integration_Name_Enum.ClassLink:
				setTimeout(() => {
					setSignUpRole(Role_Name_Enum.School)
					setOpen(false)
					setIsSchoolDialogOpen(true)
				}, 500)
				break

			default:
				break
		}
	}, [integrationName])

	const handleContact = async (campName: string, phoneNumber: string, comments: string) => {
		try {
			await createContact({
				variables: {
					campName,
					phoneNumber,
					message: comments,
					email: signUpData.email ?? '',
					firstName: signUpData.nameFirst ?? '',
					lastName: signUpData.nameLast ?? ''
				}
			})
			setCreateContactSuccess(true)
		} catch (error) {
			console.error('Could not create contact entry:', error)
			setIsSchoolDialogOpen(false)
			setIsPrivateTeacherDialogOpen(false)
			setIsCampDialogOpen(false)
			setCreateContactSuccess(false)
		}
	}

	const goBackToSignup = () => {
		setCreateContactSuccess(false)
		setIsCampDialogOpen(false)
		if (onClose) onClose()
	}

	const roles = [
		{
			name: Role_Name_Enum.School,
			description: 'K-12 Educator',
			icon: School
		},
		{
			name: Role_Name_Enum.Private,
			description: 'Tutor or Parent',
			icon: PrivateTeacher
		},
		{
			name: Role_Name_Enum.Camp,
			description: 'Camp',
			icon: Camp
		}
	]

	return (
		<Fragment>
			<BaseDialog
				open={open}
				paperProps={{ className: styles.baseDialogPaper }}
				backdropStyles={{ opacity: '0 !important' }}
				onClose={() => {
					if (onClose) onClose()
				}}
				onDiscard={() => {
					setOpen(false)
				}}
				dividers={false}
				header={
					<Box marginBottom="60px">
						<Typography variant="h4" align="center" className={styles.title}>
							<b>Select Your Role</b>
						</Typography>
						<Typography align="center">What environment will you be using WURRLY in?</Typography>
					</Box>
				}
				isActionsHidden={true}
				body={
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-around"
						flexDirection="row"
						className={styles.rolesSection}>
						{roles.map((role, index) => (
							<Box
								key={index}
								className={styles.roleContainer}
								display="flex"
								justifyContent="space-around"
								flexDirection="column">
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-around"
									className={styles.roleImgContainer}>
									<img src={role.icon} className={styles.roleImg} />
								</Box>
								<BigBaseButton
									data-cy={getCyKey(SelectRoleDialog, role.name)}
									className={styles.roleButton}
									color="secondary"
									onClick={() => {
										setSignUpRole(role.name)
										setOpen(false)
										if (role.name === Role_Name_Enum.School) setIsSchoolDialogOpen(true)
										if (role.name === Role_Name_Enum.Private)
											setIsPrivateTeacherDialogOpen(true)
										if (role.name === Role_Name_Enum.Camp) setIsCampDialogOpen(true)
									}}>
									<b>{role.description}</b>
								</BigBaseButton>
							</Box>
						))}
					</Box>
				}
			/>
			<SchoolDialog
				open={isSchoolDialogOpen}
				onClose={() => {
					setIsSchoolDialogOpen(false)
					setOpen(true)
				}}
				onDiscard={() => {
					setIsSchoolDialogOpen(false)
					setOpen(true)
				}}
				setSchoolId={setSchoolId}
				onConfirm={() => {
					if (onSignUp) onSignUp()
				}}
				isLoading={isLoading}
			/>
			<PrivateTeacherDialog
				open={isPrivateTeacherDialogOpen}
				onClose={() => {
					setIsPrivateTeacherDialogOpen(false)
					setOpen(true)
				}}
				onDiscard={() => {
					setIsPrivateTeacherDialogOpen(false)
					setOpen(true)
				}}
				onConfirm={() => {
					if (onSignUp) onSignUp()
				}}
				setPrivateTeacherData={setPrivateTeacherData}
				isLoading={isLoading}
			/>
			<CampDialog
				open={isCampDialogOpen}
				onClose={() => {
					setIsCampDialogOpen(false)
					setOpen(true)
				}}
				onDiscard={() => {
					setIsCampDialogOpen(false)
					setOpen(true)
				}}
				onConfirm={handleContact}
				email={signUpData.email ?? ''}
				isLoading={isCreateContactLoading}
			/>
			<InfoDialog
				open={createContactSuccess}
				icon={<CheckIcon />}
				title="Contact Information Saved!"
				body="Your Contact Information was saved successfully!"
				discardLabel="Return to login"
				onDiscard={goBackToSignup}
				onClose={goBackToSignup}
			/>
		</Fragment>
	)
}
