import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useClassAssignmentsDialogStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialog: {
			'& .MuiDialog-paper': {
				padding: 40,
				maxWidth: '80%'
			}
		},
		title: {
			textAlign: 'center'
		},
		sort: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			margin: '20px 0 10px 0'
		},
		assignmentCard: {
			padding: 5
		},
		noCompletedAssignments: {
			color: theme.palette.grey[400]
		},
		discardButton: {
			width: '100%'
		}
	})
)

export default useClassAssignmentsDialogStyles
