import { Fragment } from 'react'

import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { PagesProps } from 'src/@types'
import { ActivityBoxEmpty, MainTitle, SelectSort } from 'src/components'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { UnsuscribeDialog } from 'src/components/Dialogs/UnsuscribeDialog/UnsuscribeDialog'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { SelectFilter } from 'src/components/SelectFilter'
import { SubscriptionsCard } from 'src/components/SubscriptionsCard/SubscriptionsCard'
import { Module, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useScroll } from 'src/hooks'
import { AddModuleToClass } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Modules/components/AddModuleToClass'
import { ModulesCardContainer } from 'src/scenes/Teacher/scenes/6-Lessons/scenes/Modules/components/ModulesContainer'
import { generateFlagText } from 'src/utils'

import { useGetModules } from './hooks'

export const ModulesPage = ({ page }: PagesProps) => {
	useScroll()
	const {
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		setModuleId,
		modules,
		modulesLoading,
		moduleSuscriptionLoading,
		searchbarText,
		searchbarModulesData,
		searchbarModulesLoading,
		onSearchEnter,
		sort,
		moduleId,
		isOpen,
		handleSort,
		showSubscriptions,
		setAddToClassModule,
		setIsAddModuleToClassOpen,
		addToClassModule,
		breadcrumbs,
		isAddModuleToClassOpen,
		onFilterChange,
		filter,
		moduleSuscription,
		searchTeacherSubscriptionsData,
		totalModules,
		onUnsubscribe,
		goToModule,
		isFetching,
		moreResults,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	} = useGetModules()

	const searchBarItems = () => {
		return !showSubscriptions
			? searchbarModulesData?.module.map((module) => (
					<Box key={module.module_id}>
						<ModulesCardContainer
							module={module as Module}
							key={module.module_id}
							elevation={1.5}
							btnAddClass={{
								onClick: (e) => {
									e.stopPropagation()
									setAddToClassModule(module as Module)
									setIsAddModuleToClassOpen(true)
								}
							}}
							btnSuscription={{
								onClick: (e) => {
									e.stopPropagation()
									setModuleId(module.module_id)
									handleOpenProductDialog()
								}
							}}
						/>
					</Box>
			  ))
			: searchTeacherSubscriptionsData.map((module) => (
					<SubscriptionsCard
						isSearch
						key={module.orderId}
						data={module}
						allSubscriptionsCard
						onView={() => goToModule(module.productId)}
						onUnsubscribe={() => setItem(module)}
						dataflagText={generateFlagText(module)}
					/>
			  ))
	}

	return (
		<>
			{isOpen && moduleId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={moduleId}
					productName={Product_Name_Enum.Module}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}

			<UnsuscribeDialog
				isOpen={unSuscribeOpenDialog}
				setItem={setItem}
				onConfirm={(stripeSubscriptionId) => onUnsubscribe(stripeSubscriptionId)}
				setIsOpen={setUnSuscribeOpenDialog}
				item={item}
				onDone={(stripeSubscriptionId) => handleRefetch(stripeSubscriptionId)}
			/>
			<Box>
				<MainTitle
					breadcrumbs={breadcrumbs}
					title={page.name}
					description={
						showSubscriptions
							? 'Here are all the Modules you are currently subscribed to'
							: 'Engage students with fun and effective Modules, Sequences and stand alone Lessons all using the four step learning process of Inspire, Practice, Record, Reflect.'
					}
				/>

				<SearchBar
					placeholder="Find Modules by Title or Description"
					onChange={handleSearchbarTextChange}
					loading={searchbarModulesLoading}
					dropdownItems={searchBarItems()}
					onEnterPressed={onSearchEnter}
				/>

				<Box pt={6} pb={2} display="flex" flexWrap="wrap" alignItems="center">
					<Typography variant="h6">
						<b>Modules</b>({totalModules})
					</Typography>
					<div style={{ flexGrow: 1 }} />
					{totalModules !== 0 && showSubscriptions && (
						<SelectFilter value={filter} onChange={onFilterChange} />
					)}
					{totalModules !== 0 && <SelectSort cyKey="Modules" value={sort} onChange={handleSort} />}
				</Box>
				<Grid container spacing={3}>
					<Grid item xs>
						{modules.map((module) => (
							<Box marginBottom={'15px'} marginTop={'15px'} key={module.module_id}>
								<ModulesCardContainer
									module={module as Module}
									key={module.module_id}
									elevation={1.5}
									btnAddClass={{
										onClick: (e) => {
											e.stopPropagation()
											setAddToClassModule(module as Module)
											setIsAddModuleToClassOpen(true)
										}
									}}
									btnSuscription={{
										onClick: (e) => {
											e.stopPropagation()
											setModuleId(module.module_id)
											handleOpenProductDialog()
										}
									}}
								/>
							</Box>
						))}
						{moduleSuscription &&
							showSubscriptions &&
							moduleSuscription.length > 0 &&
							moduleSuscription.map((module) => (
								<Box marginBottom={'15px'} marginTop={'15px'} key={module.productId}>
									<SubscriptionsCard
										key={module.orderId}
										data={module}
										allSubscriptionsCard
										onView={() => goToModule(module.productId)}
										onUnsubscribe={() => setItem(module)}
										dataflagText={generateFlagText(module)}
									/>
								</Box>
							))}

						{((isFetching && moreResults) || modulesLoading || moduleSuscriptionLoading) && (
							<Box m={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}

						{((!modulesLoading && modules.length > 0) ||
							(!moduleSuscriptionLoading && moduleSuscription.length > 0)) &&
							!moreResults && (
								<Box mt={4} textAlign="center">
									<Button
										onClick={() => {
											window.scrollTo(0, 0)
										}}
										variant="text"
										color="secondary">
										Back to Top
									</Button>
								</Box>
							)}
						{totalModules === 0 && (
							<>
								{!searchbarText && (
									<ActivityBoxEmpty description="You have not subscribed to any modules yet" />
								)}
								{searchbarText && (
									<Box my={4}>
										<Fragment>
											<Typography variant="h6" color="textSecondary">
												Your search for "{searchbarText}" did not return any results.
											</Typography>
										</Fragment>
									</Box>
								)}
							</>
						)}
					</Grid>
				</Grid>

				<AddModuleToClass
					isOpen={isAddModuleToClassOpen}
					setIsOpen={setIsAddModuleToClassOpen}
					module={addToClassModule}
				/>
			</Box>
		</>
	)
}
