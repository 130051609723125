import { useEffect, useState } from 'react'

import { AssetForListItem } from 'src/components/ListItems/ListAssetItem/ListAssetItem'
import { SongForListItem } from 'src/components/ListItems/ListSongItem/ListSongItem'
import { TipForListItem } from 'src/components/ListItems/ListVideoItem/ListVideoItem'
import { useGetAssignmentAttachmentsByClassAssignmentIdQuery } from 'src/graphql/autogenerate/hooks'

export const useAssignmentAttachments = (classAssignmentId: number, teacherId: number) => {
	const [songs, setSongs] = useState<SongForListItem[]>([])
	const [videos, setVideos] = useState<TipForListItem[]>([])
	const [assets, setAssets] = useState<AssetForListItem[]>([])
	const { data, error, loading } = useGetAssignmentAttachmentsByClassAssignmentIdQuery({
		variables: { classAssignmentId, teacherId }
	})

	useEffect(() => {
		if (data && !loading) {
			const assignment = data.class_assignment_by_pk?.assignment
			const songsFromQuery = assignment?.assignment_songs.map(({ song }) => song)
			const videosFromQuery = assignment?.assignment_tips.map(({ tip }) => tip)
			const assetsFromQuery = assignment?.assignment_assets.map(({ asset }) => asset)
			setSongs(songsFromQuery ?? [])
			setVideos(videosFromQuery ?? [])
			setAssets(assetsFromQuery ?? [])
		}
	}, [data, loading])

	return { songs, videos, assets, loading, error }
}
