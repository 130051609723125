import { memo } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { FileUploadIcon, ResourcesSmallIcon } from 'src/assets/icons/CustomIcons'
import { MainTitle, Search } from 'src/components'
import { useScroll } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import { CatalogList } from 'src/scenes/Teacher/scenes/6-Lessons/components/CatalogList'
import { AllLessons } from 'src/scenes/Teacher/scenes/6-Lessons/components/FeaturedLessons'
import { ModulesCarousel } from 'src/scenes/Teacher/scenes/6-Lessons/components/ModulesCarousel'
import { PartnersCarousel } from 'src/scenes/Teacher/scenes/6-Lessons/components/PartnersCarousel'
import { TypeEnum } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'

import useStyles from './Lessons.styles'

export const Lessons = memo(({ page }: PagesProps) => {
	useScroll()
	const styles = useStyles()
	const history = useHistory()

	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id

	const handleSearch = (search: string) => {
		history.push(buildRouteParameters(Pages.SearchLessons), {
			search
		})
	}

	return (
		<Box>
			<MainTitle breadcrumbs={[page]} title={<></>} titleMargin={0} />
			<PartnersCarousel />

			<MainTitle
				breadcrumbs={[]}
				title={page.name}
				description="Engage students with fun and effective Modules, Sequences and stand alone Lessons all using the four step learning process of Inspire, Practice, Record, Reflect."
				rightActions={[
					{
						variant: 'outlined',
						className: styles.actionButton,
						startIcon: <ResourcesSmallIcon />,
						name: 'My Files',
						linkTo: Pages.MyFiles.path
					},
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <FileUploadIcon />,
						name: 'Upload Your Own',
						linkTo: Pages.LessonUpload.path
					}
				]}
			/>

			<Search teacherId={teacherId} type={TypeEnum.Lesson} onChange={handleSearch} />

			<ModulesCarousel />

			<AllLessons teacherId={teacherId} />

			<CatalogList />
		</Box>
	)
}) as (props: PagesProps) => JSX.Element
