import { Box, Divider } from '@material-ui/core'
import { Select } from 'src/components'
import { AssignmentTiles } from 'src/components/AssignmentTiles/AssignmentTiles'
import { AssignmentsLessons } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsLessons'
import { AssignmentsNoSubmissions } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsNoSubmissions'
import { AssignmentsPreviouslyGraded } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsPreviouslyGraded'
import { AssignmentsToBeGraded } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/components/AssignmentsToBeGraded'
import { useAssignmentsDetails } from 'src/scenes/Teacher/scenes/5-Assignments/components/AssignmentDetails/useAssignmentsDetails'
import { optionsDateFilter } from 'src/utils'

import useStyles from './AssignmentDetails.styles'

export const AssignmentsDetails = () => {
	const styles = useStyles()
	const { classes, selectedClassId, selectedDateFilter, handleClassSelected, handleDateSelected } =
		useAssignmentsDetails()

	return (
		<Box>
			<Box display="flex" flexDirection="row" alignItems="center">
				<Select
					className={styles.classSelect}
					options={classes?.class?.map((i) => ({ name: i.title, value: i.class_id }))}
					onChange={handleClassSelected}
					value={selectedClassId}
				/>
				<Select
					className={styles.dateSelect}
					options={optionsDateFilter}
					onChange={handleDateSelected}
					value={selectedDateFilter}
				/>
			</Box>
			<AssignmentsToBeGraded classId={selectedClassId} date={selectedDateFilter} />
			<AssignmentsPreviouslyGraded classId={selectedClassId} date={selectedDateFilter} />
			<AssignmentTiles className={styles.tiles} hasAssignments={false} hasLessons={false} />
			<AssignmentsNoSubmissions classId={selectedClassId} date={selectedDateFilter} />

			<Divider className={styles.divider} />
			<AssignmentsLessons classId={selectedClassId} />
		</Box>
	)
}
