import { Submission, Submission_State_Enum } from 'src/graphql/autogenerate/schemas'
export const submittedAndGradedSubmissionStates = [Submission_State_Enum.Submitted, Submission_State_Enum.Graded]

export const orderSubmissionsByGrade = (submissions: Submission[], gradingValues: string[] | number[]) => {
	const stringifiedValued = gradingValues.map((val) => val.toString())
	const sortedSubmissions = [...submissions]

	return sortedSubmissions?.sort((a, b) => {
		const aIndex = stringifiedValued.indexOf(a?.grade?.toString() || '')
		const bIndex = stringifiedValued.indexOf(b?.grade?.toString() || '')
		if (aIndex < 0) {
			return 1
		}
		if (bIndex < 0) {
			return -1
		}

		return aIndex - bIndex
	})
}

export const orderSubmissionByNameAsc = (submissions: Submission[]) => {
	return [...submissions].sort((a: Submission, b: Submission) => (a?.name ?? '').localeCompare(b?.name ?? ''))
}

export const orderSubmissionByNameDesc = (submissions: Submission[]) => {
	return [...submissions].sort((a: Submission, b: Submission) => (b?.name ?? '').localeCompare(a?.name ?? ''))
}

export const orderSubmissionByDateAsc = (submissions: Submission[]) => {
	return [...submissions].sort((a: Submission, b: Submission) => {
		const aDate = new Date(a?.date_submitted ?? '')
		const bDate = new Date(b?.date_submitted ?? '')

		return aDate.getTime() - bDate.getTime()
	})
}
