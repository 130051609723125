import { createStyles, fade, makeStyles, Theme } from '@material-ui/core'

export const useLessonsStyles = makeStyles((theme: Theme) =>
	createStyles({
		actionButton: {
			style: { border: '1px solid rgba(50, 51, 66, 0.5)' }
		},
		link: {
			color: theme.palette.secondary.dark
		},
		panel: {
			pointerEvents: 'none',
			'& .MuiButton-root': {
				backgroundColor: theme.palette.common.white,
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			},
			'& .MuiButton-root:first-child': {
				paddingLeft: theme.spacing(4),
				backgroundColor: fade(theme.palette.common.black, 0.3),
				color: theme.palette.common.white,
				boxShadow: 'none'
			}
		},
		listCard: { marginBottom: 20 },
		banner: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '14/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		},
		modules: {
			'& .MuiButtonBase-root': {
				backgroundSize: 'cover',
				aspectRatio: '9/6',
				backgroundRepeat: 'no-repeat',
				'& .main': {
					width: '100%',
					height: '100%'
				},
				'& .MuiButton-contained': { aspectRatio: '12/3' }
			}
		},

		partner: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			pointerEvents: 'none',
			'& .MuiButton-root': {
				marginLeft: '32px',
				pointerEvents: 'none',
				backgroundColor: 'rgba(0, 0, 0, 0.2)',
				color: theme.palette.common.white,
				boxShadow: 'none',
				border: '2px solid rgba(237, 237, 237, 1)'
			}
		},
		logo: {
			width: '10%',
			marginLeft: '32px',
			marginBottom: '20px'
		}
	})
)

export default useLessonsStyles
