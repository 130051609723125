import * as yup from 'yup'
export const limits = {
	title: 80,
	description: 450,
	keywords: 5
}

export const usestepOneValidation = ({ isRestricted }: { isRestricted: boolean }) => {
	const stepOneSchema = yup.object({
		title: yup.string().required('Title is required').max(limits.title, 'Title too long'),
		description: yup.string().max(limits.description, 'Descriptions too long'),
		keywords: yup.array().max(limits.keywords, `Up to ${limits.keywords}`),
		subjects: yup.array(),
		ages: yup.array(),
		instruments: yup.array(),
		restrictions: isRestricted ? yup.array().min(1, 'At least one restriction is required') : yup.array()
	})

	return {
		stepOneSchema,
		limits
	}
}
