import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useInputHeaderStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: 40,
			marginBottom: 10
		},
		label: {
			fontWeight: 'bold'
		},
		paper: {
			padding: theme.spacing(2),
			maxWidth: '400px'
		},
		helpIcon: {
			marginLeft: '5px'
		}
	})
)

export default useInputHeaderStyles
