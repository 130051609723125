import React from 'react'

import {
	Box,
	BoxProps,
	ButtonProps,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentProps,
	DialogProps,
	DialogTitle,
	PaperProps,
	Typography,
	TypographyProps
} from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import { getCyKey } from 'src/utils'

import { ActionButton, ActionButtonProps, BigBaseButton } from '../..'
import useStyles from './BaseDialog.styles'

export type BaseDialogProps = {
	onClose: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void
	open: boolean
	header?: React.ReactNode
	body?: React.ReactNode
	bodyProps?: DialogContentProps
	onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onConfirmProps?: ButtonProps
	confirmLabel?: string | JSX.Element
	isFullWidthButton?: boolean
	isConfirmDisabled?: boolean
	isConfirmLoading?: boolean
	loading?: boolean
	onDiscard?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onDiscardProps?: ButtonProps
	discardLabel?: string
	discardLabelFontSize?: string
	typographyProps?: TypographyProps
	isActionsHidden?: boolean
	buttonAction?: ActionButtonProps[]
	rightActions?: React.ReactNode
	leftActions?: React.ReactNode
	dividers?: boolean
	footer?: React.ReactNode
	footerProps?: BoxProps
	paperProps?: PaperProps
	backdropStyles?: CSSProperties
	dialogPaperStyles?: CSSProperties
	dialogActionsStyles?: CSSProperties
	transparentBackdrop?: boolean
}
export const BaseDialog = ({
	onClose,
	open,
	header,
	body,
	bodyProps,
	confirmLabel,
	isFullWidthButton = false,
	onConfirm,
	onConfirmProps = {},
	typographyProps = {},
	discardLabel,
	onDiscard,
	onDiscardProps = {},
	isActionsHidden = false,
	buttonAction,
	rightActions,
	leftActions,
	dividers = true,
	footer,
	isConfirmDisabled = false,
	isConfirmLoading = false,
	loading = false,
	footerProps = {},
	paperProps = {}, // {{ style: { width: '100%', maxWidth: '800px' } }} use this to define width on dialog
	backdropStyles = {},
	dialogPaperStyles = {},
	dialogActionsStyles,
	transparentBackdrop,
	...props
}: BaseDialogProps & DialogProps) => {
	const classes = useStyles({
		backdropStyles,
		dialogPaperStyles,
		dialogActionsStyles,
		transparentBackdrop,
		isFullWidthButton
	})

	const confirmLabelName = typeof confirmLabel === 'string' ? confirmLabel : (confirmLabel?.key as string)

	return (
		<Dialog
			{...props}
			className={clsx(classes.baseDialog, props.className)}
			PaperProps={paperProps}
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}>
			{header && (
				<DialogTitle data-cy={getCyKey(BaseDialog, 'dialogTitle')} id="customized-dialog-title">
					{header}
				</DialogTitle>
			)}
			<DialogContent dividers={dividers} {...bodyProps}>
				{body}
			</DialogContent>
			{!isActionsHidden &&
				(onDiscard ||
					discardLabel ||
					leftActions ||
					buttonAction ||
					rightActions ||
					onConfirm ||
					confirmLabel) && (
					<DialogActions>
						{onDiscard && discardLabel && (
							<BigBaseButton
								data-cy={getCyKey(BaseDialog, discardLabel)}
								className={
									isFullWidthButton
										? clsx(classes.fullWidthButton, onConfirmProps.className)
										: clsx(classes.discardBtn, onConfirmProps.className)
								}
								variant="contained"
								color="default"
								onClick={onDiscard}
								{...onDiscardProps}
								disabled={loading}>
								<Typography variant="body1" {...typographyProps}>
									{discardLabel}
								</Typography>
							</BigBaseButton>
						)}
						{leftActions}
						{buttonAction && buttonAction.length > 0 && (
							<Box display="flex" className="boxActions">
								<div style={{ flexGrow: 1 }} />
								{buttonAction.map(
									(btn, i) =>
										btn && (
											<ActionButton
												key={`${i}-btn`}
												{...btn}
												className={clsx(
													{ selected: btn.selected },
													{ disabled: btn.disabled }
												)}
												icon={btn.icon}
											/>
										)
								)}
							</Box>
						)}
						{rightActions}
						{onConfirm && confirmLabel && (
							<BigBaseButton
								data-cy={getCyKey(BaseDialog, confirmLabelName)}
								className={
									isFullWidthButton
										? clsx(classes.fullWidthButton, onConfirmProps.className)
										: clsx(classes.confirmButton, onConfirmProps.className)
								}
								color="secondary"
								autoFocus
								onClick={onConfirm}
								{...onConfirmProps}
								disabled={isConfirmDisabled || isConfirmLoading}>
								<Typography
									variant="body1"
									{...typographyProps}
									style={{
										display: 'flex',
										alignItems: 'center',
										...typographyProps?.style
									}}>
									{confirmLabel}
									{isConfirmLoading && (
										<CircularProgress color="secondary" style={{ marginLeft: '1em' }} />
									)}
								</Typography>
							</BigBaseButton>
						)}
					</DialogActions>
				)}
			{footer && (
				<Box
					width="100%"
					display="flex"
					justifyContent="center"
					style={{ marginTop: '40px' }}
					{...footerProps}>
					{footer}
				</Box>
			)}
		</Dialog>
	)
}
