import { useEffect, useState } from 'react'

import { ApolloError } from '@apollo/client'
import { PaperProps } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { cache } from 'src/apollo/state'
import {
	useDeleteLessonByPkMutation,
	useDeleteSongByPkMutation,
	useDeleteVideoByPkMutation
} from 'src/graphql/autogenerate/hooks'
import {
	DeleteLessonMutation,
	DeleteSongByPkMutation,
	DeleteVideoByPkMutation
} from 'src/graphql/autogenerate/operations'
import { Lesson_Plan, Module, Playlist, Song, Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { ProductTitleEnum, buildRouteParameters, getId } from 'src/utils'

type UseAllClassProductsCardProps = PaperProps & {
	data: Lesson_Plan | Module | Playlist | Tip | Song
	type: string
}
export const useAllClassProductsCard = ({ data, type }: UseAllClassProductsCardProps) => {
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const { classId } = useWurrlyParams()

	const teacherId = teacher.teacher_id
	const [deleteError, setDeleteError] = useState<ApolloError>()
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
	const [deleteData, setDeleteData] =
		useState<DeleteLessonMutation | DeleteVideoByPkMutation | DeleteSongByPkMutation>()
	const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
	const [addToClassDialogOpen, setAddToClassDialogOpen] = useState(false)
	const [videoDialogOpen, setVideoDialogOpen] = useState(false)
	const [video, setVideo] = useState<Tip>({} as Tip)
	const [songPlayerOpen, setSongPlayerOpen] = useState(false)
	const [isModulesDialogOpen, setIsModulesDialogOpen] = useState(false)

	const [deleteLesson, { data: deleteLessonData, loading: deleteLessonLoading, error: deleteLessonError }] =
		useDeleteLessonByPkMutation()
	const [deleteVideo, { data: deleteVideoData, loading: deleteVideoLoading, error: deleteVideoError }] =
		useDeleteVideoByPkMutation()

	const [deleteSong, { data: deleteSongData, loading: deleteSongLoading, error: deleteSongError }] =
		useDeleteSongByPkMutation()

	const getTitle = (data: Lesson_Plan | Module | Playlist | Tip | Song): string => {
		if ('name' in data) {
			return data.name
		} else {
			return data.title
		}
	}

	const getDescription = (data: Lesson_Plan | Module | Playlist | Tip | Song): string => {
		if ('description' in data) {
			return data.description as string
		} else {
			return ''
		}
	}

	const [count, setCount] = useState(0)

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.Module:
				setCount((data as Module).module_lesson_plan_aggregate.aggregate?.count as number)
				break
			case ProductTitleEnum.Playlist:
				setCount((data as Playlist).tip_playlist_aggregate.aggregate?.count as number)
				break
			default:
				break
		}
		if (type === ProductTitleEnum.Tip) {
			setVideo(data as Tip)
		}
	}, [])

	const handleCloseDeleteDialog = () => {
		evictCacheDelete()
		setDeleteDialogOpen(false)
	}

	const openVideoDialog = () => setVideoDialogOpen(true)

	const closeVideoDialog = () => setVideoDialogOpen(false)

	const openSongPlayerDialog = () => setSongPlayerOpen(true)

	const closeSongPlayerDialog = () => setSongPlayerOpen(false)

	const handleOpenDeleteDialog = () => setDeleteDialogOpen(true)

	const handleAddToClassDialog = () => setAddToClassDialogOpen(true)

	const handleCloseAddToClassDialog = () => setAddToClassDialogOpen(false)

	const handleModulesDialogOpen = () => setIsModulesDialogOpen(true)

	const handleEdit = () => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				history.push(buildRouteParameters(Pages.LessonEdit, { lessonPlanId: getId(data) }))
				break
			case ProductTitleEnum.Song:
				history.push(buildRouteParameters(Pages.SongEdit, { songId: getId(data) }))
				break
			case ProductTitleEnum.Tip:
				history.push(buildRouteParameters(Pages.VideoEdit, { videoId: getId(data) }))
				break
			default:
				break
		}
	}
	const handleView = () => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				history.push(buildRouteParameters(Pages.LessonDetailNoModule, { lessonPlanId: getId(data) }))
				break
			case ProductTitleEnum.Module:
				history.push(buildRouteParameters(Pages.ModuleDetails, { moduleId: getId(data) }))
				break
			case ProductTitleEnum.Playlist:
				history.push(buildRouteParameters(Pages.PlaylistDetails, { playlistId: getId(data) }))
				break
			case ProductTitleEnum.Song:
				openSongPlayerDialog()
				break
			case ProductTitleEnum.Tip:
				openVideoDialog()
				break
			default:
				break
		}
	}

	const handleDelete = () => {
		const id = getId(data)
		if (id === 0) return
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				deleteLesson({
					variables: { lessonId: id }
				})
				break
			case ProductTitleEnum.Song:
				deleteSong({
					variables: { songId: id }
				})
				break
			case ProductTitleEnum.Tip:
				deleteVideo({
					variables: { tipId: id }
				})
				break
			default:
				break
		}
	}

	const evictCacheDelete = () => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (deleteLessonData?.delete_lesson_plan_by_pk?.lesson_plan_id) {
					const identify = cache.identify(deleteLessonData?.delete_lesson_plan_by_pk)
					cache.evict({
						id: identify
					})
					cache.evict({
						id: `ROOT_QUERY`,
						fieldName: 'lesson_plan_aggregate'
					})
				}
				break
			case ProductTitleEnum.Song:
				if (deleteSongData?.delete_song_by_pk?.song_id) {
					const identify = cache.identify(deleteSongData?.delete_song_by_pk)
					cache.evict({
						id: identify
					})
					cache.evict({
						id: `ROOT_QUERY`,
						fieldName: 'song_aggregate'
					})
				}
				break
			case ProductTitleEnum.Tip:
				if (deleteVideoData?.delete_tip_by_pk?.tip_id) {
					const identify = cache.identify(deleteVideoData?.delete_tip_by_pk)
					cache.evict({
						id: identify
					})
					cache.evict({
						id: `ROOT_QUERY`,
						fieldName: 'tip_aggregate'
					})
				}
				break
			default:
				break
		}
	}

	const goToCreateFromLesson = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation()
		const id = getId(data)
		if (id && classId) {
			history.push(
				buildRouteParameters(Pages.AddAssignmentFromLesson, { lessonPlanId: id }, true, {
					selectedClassId: classId
				})
			)
		}
	}

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (deleteLessonError) {
					setDeleteError(deleteLessonError)
				}
				break

			case ProductTitleEnum.Song:
				if (deleteSongError) {
					setDeleteError(deleteSongError)
				}
				break
			case ProductTitleEnum.Tip:
				if (deleteVideoError) {
					setDeleteError(deleteVideoError)
				}
				break

			default:
				break
		}
	}, [deleteLessonError, deleteVideoError, deleteSongError])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				setDeleteLoading(deleteLessonLoading)
				break

			case ProductTitleEnum.Song:
				setDeleteLoading(deleteSongLoading)
				break
			case ProductTitleEnum.Tip:
				setDeleteLoading(deleteVideoLoading)
				break

			default:
				break
		}
	}, [deleteLessonLoading, deleteVideoLoading, deleteSongLoading])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (deleteLessonData) {
					setDeleteData(deleteLessonData)
				}
				break

			case ProductTitleEnum.Song:
				if (deleteSongData) {
					setDeleteData(deleteSongData)
				}
				break
			case ProductTitleEnum.Tip:
				if (deleteVideoData) {
					setDeleteData(deleteVideoData)
				}
				break

			default:
				break
		}
	}, [deleteLessonData, deleteVideoData, deleteSongData])

	return {
		handleView,
		handleEdit,
		handleOpenDeleteDialog,
		handleCloseDeleteDialog,
		handleDelete,
		deleteDialogOpen,
		deleteLoading,
		deleteError,
		deleteData,
		evictCacheDelete,
		teacherId,
		handleModulesDialogOpen,
		setIsModulesDialogOpen,
		isModulesDialogOpen,
		handleAddToClassDialog,
		addToClassDialogOpen,
		handleCloseAddToClassDialog,
		videoDialogOpen,
		closeVideoDialog,
		video,
		songPlayerOpen,
		closeSongPlayerDialog,
		count,
		getTitle,
		getDescription,
		getId,
		goToCreateFromLesson
	}
}
