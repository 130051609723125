import { useEffect } from 'react'

import {
	Box,
	CircularProgress,
	Link as MuiLink,
	Theme,
	Typography,
	createStyles,
	makeStyles
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { ActivityBoxEmpty, MainTitle } from 'src/components'
import { UnsuscribeDialog } from 'src/components/Dialogs/UnsuscribeDialog/UnsuscribeDialog'
import { SubscriptionsCard } from 'src/components/SubscriptionsCard/SubscriptionsCard'
import { Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { useProfileSubscription } from 'src/scenes/Teacher/scenes/13-Profile/Components/hooks/useProfileSubscription'
import { LicencesInfo } from 'src/scenes/Teacher/scenes/18-Subscriptions/components/LicencesInfo'
import { buildBreadCrumbs, generateFlagText } from 'src/utils'

interface LinkProps {
	to: string
	title: string
	from: string
	[key: string]: string | number
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link: {
			color: theme.palette.secondary.dark
		}
	})
)

export const Link = ({ to, from, title, ...otherProps }: LinkProps) => {
	const history = useHistory()
	const location = useLocation()

	const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.preventDefault()
		const searchParams = new URLSearchParams(location.search)
		searchParams.set('from', from)
		const newUrl = `${to}?${searchParams.toString()}`
		history.push(newUrl)
	}

	return (
		<MuiLink href={to} onClick={handleClick} {...otherProps}>
			{title && <b>{title}</b>}
		</MuiLink>
	)
}

export const MySubscriptions = ({ page }: PagesProps) => {
	const classes = useStyles()

	const {
		goToPartner,
		goToChannel,
		goToModule,
		goToPlaylist,
		teacherId,
		onUnsubscribe,
		getTeacherSubscriptions,
		data,
		loading,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	} = useProfileSubscription()

	const getAllSubscriptions = async () => {
		const products = Object.values(Product_Name_Enum)
		for (const product of products) {
			if (product !== Product_Name_Enum.LessonPlan) {
				await getTeacherSubscriptions({
					variables: {
						teacherId,
						productName: product,
						limit: 3
					}
				})
			}
		}
	}

	useEffect(() => {
		getAllSubscriptions()
	}, [])

	const breadcrumbs = buildBreadCrumbs({
		page: Pages.MySubscriptions
	})

	return (
		<>
			<UnsuscribeDialog
				isOpen={unSuscribeOpenDialog}
				setItem={setItem}
				onConfirm={(stripeSubscriptionId) => onUnsubscribe(stripeSubscriptionId)}
				setIsOpen={setUnSuscribeOpenDialog}
				item={item}
				onDone={(stripeSubscriptionId) => handleRefetch(stripeSubscriptionId)}
			/>
			<Box>
				<MainTitle breadcrumbs={breadcrumbs} title={page.name} description="Here are your Subscriptions" />
				<Box>
					<Typography variant="h6">
						<b>Licences</b>
					</Typography>
					<LicencesInfo />
				</Box>
				<Box>
					<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Partners</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<Link
							className={classes.link}
							to={Pages.AllPartnersSubs.path}
							from="subs"
							title={'View All Partners'}
						/>
					</Box>
					{!loading ? (
						data.filter((x) => x.productType === Product_Name_Enum.Partner).length > 0 ? (
							data
								.filter((x) => x.productType === Product_Name_Enum.Partner)
								.map((partner) => (
									<Box marginBottom={'15px'} marginTop={'15px'} key={partner.orderId}>
										<SubscriptionsCard
											key={partner.orderId}
											data={partner}
											allSubscriptionsCard
											onView={() => goToPartner(partner.productId)}
											dataflagText={generateFlagText(partner)}
											onUnsubscribe={() => setItem(partner)}
										/>
									</Box>
								))
						) : (
							<ActivityBoxEmpty
								style={{ height: '100px' }}
								description="You have not subscribed to any partners yet."
							/>
						)
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
				</Box>
				<Box>
					<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Modules</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<Link
							className={classes.link}
							to={Pages.ModulesSubs.path}
							from="subs"
							title={'View All Modules'}
						/>
					</Box>
					{!loading ? (
						data.filter((x) => x.productType === Product_Name_Enum.Module).length > 0 ? (
							data
								.filter((x) => x.productType === Product_Name_Enum.Module)
								?.map((module) => {
									return (
										<Box marginBottom={'15px'} marginTop={'15px'} key={module.orderId}>
											<SubscriptionsCard
												key={module.orderId}
												data={module}
												onView={() => goToModule(module.productId)}
												onUnsubscribe={() => setItem(module)}
												dataflagText={generateFlagText(module)}
												allSubscriptionsCard
											/>
										</Box>
									)
								})
						) : (
							<ActivityBoxEmpty
								style={{ height: '100px' }}
								description="You have not subscribed to any modules yet."
							/>
						)
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
				</Box>
				<Box>
					<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Channels</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<Link
							className={classes.link}
							to={Pages.ChannelsSubs.path}
							from="subs"
							title={'View All Channels'}
						/>
					</Box>
					{!loading ? (
						data.filter((x) => x.productType === Product_Name_Enum.Channel).length > 0 ? (
							data
								.filter((x) => x.productType === Product_Name_Enum.Channel)
								?.map((channel) => {
									return (
										<Box marginBottom={'15px'} marginTop={'15px'} key={channel.orderId}>
											<SubscriptionsCard
												key={channel.orderId}
												data={channel}
												onView={() => goToChannel(channel.productId)}
												onUnsubscribe={() => setItem(channel)}
												dataflagText={generateFlagText(channel)}
												allSubscriptionsCard
											/>
										</Box>
									)
								})
						) : (
							<ActivityBoxEmpty
								style={{ height: '100px' }}
								description="You have not subscribed to any channels yet."
							/>
						)
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
				</Box>
				<Box>
					<Box mt={6} mb={2} display="flex" flexWrap="wrap" alignItems="center">
						<Typography variant="h6">
							<b>Playlists</b>
						</Typography>
						<div style={{ flexGrow: 1 }} />
						<Link
							className={classes.link}
							to={Pages.PlaylistsSubs.path}
							from="subs"
							title={'View All Playlists'}
						/>
					</Box>
					{!loading ? (
						data.filter((x) => x.productType === Product_Name_Enum.Playlist).length > 0 ? (
							data
								.filter((x) => x.productType === Product_Name_Enum.Playlist)
								?.map((playlist) => {
									return (
										<Box marginBottom={'15px'} marginTop={'15px'} key={playlist.orderId}>
											<SubscriptionsCard
												key={playlist.orderId}
												data={playlist}
												onView={() => goToPlaylist(playlist.productId)}
												onUnsubscribe={() => setItem(playlist)}
												dataflagText={generateFlagText(playlist)}
												allSubscriptionsCard
											/>
										</Box>
									)
								})
						) : (
							<ActivityBoxEmpty
								style={{ height: '100px' }}
								description="You have not subscribed to any playlists yet."
							/>
						)
					) : (
						<Box m={3} textAlign="center">
							<CircularProgress color="secondary" size={40} />
						</Box>
					)}
				</Box>
			</Box>
		</>
	)
}
