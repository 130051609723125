import { Paper, Typography } from '@material-ui/core'
import { DateRange } from 'src/@types'
import { BaseLoading } from 'src/components'
import { ActivityBoxEmpty } from 'src/components/ActivityBoxEmpty'
import { StackedBar } from 'src/components/Charts/StackedBar/StackedBar'
import { useStudentPointsChart } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassDetails/components/CheckInsights/studentPointsChart/StudentPointsChartContext'

import useStyles from '../../ClassProductivityChart.styles'
type ClassProductivityChartProps = {
	classId: number
	dateRange: DateRange
}

export const StudentPointsChart = ({ classId, dateRange }: ClassProductivityChartProps) => {
	const styles = useStyles()
	const { chartData, loading } = useStudentPointsChart({ classId, dateRange })

	return (
		<Paper className={styles.container}>
			<Typography variant="h6">
				<strong>Check insights</strong>
			</Typography>
			{chartData.labels.length > 0 && !loading && <StackedBar data={chartData} paginated />}
			{loading && (
				<ActivityBoxEmpty className={styles.emptyBox}>
					<BaseLoading />
				</ActivityBoxEmpty>
			)}
			{chartData.labels.length === 0 && !loading && (
				<ActivityBoxEmpty className={styles.emptyBox}>
					<Typography variant="h6">No data available for the selected time range.</Typography>
				</ActivityBoxEmpty>
			)}
		</Paper>
	)
}
