import { useEffect, useState, Fragment } from 'react'

import { Box, Card, CardActionArea, CardContent, ImageList, ImageListItem, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import { useHistory } from 'react-router-dom'

import { BaseDialog, InfoDialog } from '..'
import { useGetClassesQuery } from '../../graphql/autogenerate/hooks'
import { Class } from '../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../hooks/useLogin'
import { Pages } from '../../routes/teacherPages'
import { COLORS } from '../../styles/colors'
import { buildImagePath, DEFAULT_MUSIC_BG, getCyKey } from '../../utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		selected: {
			borderColor: theme.palette.secondary.main,
			backgroundColor: fade(theme.palette.secondary.main, 0.25)
		},
		saved: {
			backgroundColor: fade(theme.palette.grey[700], 0.7)
		},
		nonSelected: {
			backgroundColor: fade(theme.palette.primary.dark, 0.1)
		}
	})
)

type AddToClassProps = {
	// classes where the item belongs to (already saved on db)
	itemClasses: Class[]
	isOpen: boolean
	setIsOpen: (val: boolean) => void
	title?: string
	description?: string
	onConfirm: (selectedClasses: Class) => Promise<void>
}

type ClassroomType = {
	saved?: boolean
	selected?: boolean
} & Class

export const SelectClassDialog = ({
	isOpen,
	setIsOpen,
	itemClasses,
	title,
	description,
	onConfirm
}: AddToClassProps) => {
	const style = useStyles()
	const theme = useTheme()
	const history = useHistory()
	const { teacherData: teacher } = useLoginContext()
	const [rightNow] = useState(null) // TODO: use `new Date()` to query active classes
	const teacherId = teacher.teacher_id

	const { data: classData, loading: classLoading } = useGetClassesQuery({
		variables: { teacherId, rightNow },
		skip: !teacherId
	})

	const [classes, setClasses] = useState<ClassroomType[]>([])

	useEffect(() => {
		if (!classLoading && classData && classData.teacher_by_pk?.active?.length) {
			const teacherClasses = classData.teacher_by_pk.active.map((teacherClass) => {
				if (itemClasses.some((item) => item.class_id === teacherClass.class.class_id)) {
					return { ...teacherClass.class, saved: true, selected: false }
				} else return { ...teacherClass.class, saved: false, selected: false }
			})
			setClasses([...(teacherClasses as ClassroomType[])])
		}
	}, [classData, classLoading, itemClasses])

	const goToCreateClass = () => {
		history.push(Pages.ClassCreation.path)
	}

	return (
		<Box>
			{classes.length === 0 ? (
				<InfoDialog
					onClose={() => {
						if (setIsOpen) setIsOpen(false)
					}}
					open={isOpen}
					icon="!"
					title="Set up a class"
					body="You need to have a class set up before you can add content to it."
					confirmLabel="Create Class"
					onConfirm={goToCreateClass}
				/>
			) : (
				<BaseDialog
					dividers={false}
					onClose={() => {
						setIsOpen(false)
					}}
					open={isOpen}
					header={
						<Fragment>
							<Typography
								data-cy={getCyKey(SelectClassDialog, 'dialogTitle')}
								variant="h4"
								align="center">
								{title}
							</Typography>
							<Typography style={{ padding: 14 }} align="center" variant="body2">
								{description}
							</Typography>
						</Fragment>
					}
					body={
						<Box padding="6px 10px">
							{/* TODO: Rename all GridList to ImageList and GridListTile to ImageListItem */}
							<ImageList rowHeight={100} cols={3}>
								{classes.map((iclass, index) => (
									<ImageListItem key={`${iclass}-${index}`} cols={1}>
										<Card
											variant={iclass.selected ? 'outlined' : 'elevation'}
											elevation={iclass.saved ? 0 : 3}
											style={{
												backgroundImage: `url( ${
													iclass.image_path
														? buildImagePath(iclass.image_path)
														: DEFAULT_MUSIC_BG
												})`,
												backgroundColor: theme.palette.primary.main,
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'cover',
												backgroundPosition: 'center 40%',
												height: '-webkit-fill-available',
												color: theme.palette.common.white,
												margin: 5
											}}
											color="secondary"
											onClick={() => {
												if (!iclass.saved) {
													classes.forEach((c) => (c.selected = false)) // disable all
													classes[index].selected = !iclass.selected // enable one
													setClasses([...classes]) // update state
												}
											}}>
											<CardActionArea
												style={{ height: '-webkit-fill-available' }}
												className={
													iclass.saved
														? style.saved
														: iclass.selected
														? style.selected
														: style.nonSelected
												}>
												<CardContent>
													<Box position="absolute" bottom={5} left={5}>
														<Typography
															data-cy={getCyKey(SelectClassDialog, 'ClassTitle')}
															gutterBottom
															variant="body2">
															<b>{iclass.title}</b>
														</Typography>
													</Box>
													{iclass.saved ? (
														<Box
															style={{
																display: 'flex',
																color: COLORS.lightgrey,
																opacity: 0.7
															}}
															position="absolute"
															top={5}
															right={8}>
															<Typography
																style={{ marginRight: 3 }}
																variant="caption">
																<b>ADDED</b>
															</Typography>
															<Typography variant="subtitle2">
																<CheckCircleIcon fontSize="inherit" />
															</Typography>
														</Box>
													) : (
														<Box
															style={{ display: 'flex' }}
															position="absolute"
															top={5}
															right={8}>
															{iclass.selected ? (
																<CheckCircleIcon
																	fontSize="small"
																	color="secondary"
																/>
															) : (
																<RadioButtonUncheckedOutlinedIcon
																	fontSize="small"
																	color="disabled"
																/>
															)}
														</Box>
													)}
												</CardContent>
											</CardActionArea>
										</Card>
									</ImageListItem>
								))}
							</ImageList>
						</Box>
					}
					onConfirm={async () => {
						const selectedClass = classes.find((item) => item.selected)
						if (selectedClass) {
							delete selectedClass.selected
							delete selectedClass.saved
							await onConfirm(selectedClass)
						}
						setIsOpen(false)
					}}
					onConfirmProps={{ style: { width: '214px' } }}
					isConfirmDisabled={!classes.some((item) => item.selected)}
					confirmLabel="Select"
					onDiscard={() => {
						if (setIsOpen) setIsOpen(false)
					}}
					onDiscardProps={{ style: { width: '214px' } }}
					discardLabel="Cancel"
				/>
			)}
		</Box>
	)
}
