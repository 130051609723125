import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useChordSheetViewStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			gap: '24px'
		},
		chordButton: {
			background: 'white',
			fontWeight: 500,
			boxShadow:
				'0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
		},
		padding: { padding: 15 },
		editorWrap: { overflowX: 'auto', padding: '24px', margin: '-24px' },
		previewRow: { display: 'flex', flexDirection: 'column' },
		chordSheetContainer: {
			display: 'grid',
			gap: '32px',
			gridTemplateColumns: 'repeat(auto-fill, minmax(280pt, 1fr))',
			'& button.MuiButton-contained:not(.MuiButton-containedSecondary)': {
				background: 'white',
				fontWeight: '500'
			},
			gridAutoRows: '280px minmax(max-content, 280px)'
		},
		chordSheet: {
			display: 'grid',
			gap: '12px',
			gridTemplateColumns: 'repeat(4, 1fr)',
			'& > button': {
				aspectRatio: `${1 / 0.54}`
			}
		},
		editorContainer: {
			marginTop: '24px',
			display: 'grid',
			gap: '32px',
			gridTemplateColumns: '1fr 395px'
		},
		dragChord: { display: 'flex', alignItems: 'stretch', flexDirection: 'column' },
		previewButton: {
			width: '100%',
			flexGrow: 1,
			background: 'white',
			fontWeight: 500,
			boxShadow:
				'0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
		},
		controlsContainer: { display: 'flex', gap: '12px', marginTop: '24px', alignItems: 'center' },
		controlsCard: {
			flexGrow: 1,
			display: 'flex',
			background: 'white',
			padding: '5px 20px 5px 5px',
			gap: '8px',
			alignItems: 'center'
		},
		previewChordsList: { display: 'flex', flexDirection: 'column', gap: '12px', width: '395px' },
		previewChordsLabels: { display: 'flex', justifyContent: 'space-between' },
		bgWhite: { background: 'white' },
		timeSliderSideButton: { width: 30, height: 30 },
		chordPreview: {
			overflowY: 'scroll',
			flexGrow: 1,
			'& > ul': {
				listStyle: 'none',
				margin: 0,
				padding: '0 24px',
				'& > li': {
					borderBottom: `2px solid ${theme.palette.divider}`,
					height: '64px',
					padding: '8px 0',
					display: 'flex',
					alignItems: 'end',
					gap: '12px',
					'& b': {
						color: 'red'
					},
					'& span': {
						color: 'black',
						textTransform: 'uppercase'
					}
				}
			}
		}
	})
)

export default useChordSheetViewStyles
