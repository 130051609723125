import { ChangeEvent, useEffect, useState } from 'react'

import {
	Box,
	FormControl,
	FormGroup,
	Typography,
	FormControlLabel,
	Checkbox,
	styled,
	CircularProgress
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { ThinCheckIcon } from 'src/assets/icons'
import { BaseDialog, InfoDialog } from 'src/components'
import { useRoastCanvasClassesMutation } from 'src/graphql/autogenerate/hooks'
import { CanvasGroup, Integration_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { useIntegrationContext } from 'src/hooks/useIntegration'
import { useLoginContext } from 'src/hooks/useLogin'

type CanvasClassRoasterDialogProps = {
	isOpen?: boolean
	setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const EmptyClassesBox = styled(Box)({
	minHeight: '5em',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
})

export const CanvasClassRoasterDialog = ({ isOpen, setIsOpen }: CanvasClassRoasterDialogProps) => {
	// signedUpWithClassLlink should be true when user signeup with Canvas, and Canvas instance should have the Canvas account data
	const { integrationName, setIntegrationName, teacherData } = useLoginContext()

	const { classes, canvasInstance } = useIntegrationContext()
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
	const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)
	const [selectedClasses, setSelectedClasses] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [roastCanvasClasses] = useRoastCanvasClassesMutation()

	const closeDialog = () => {
		const signedUpWithCanvas = integrationName === Integration_Name_Enum.Canvas
		if (signedUpWithCanvas) setIntegrationName(undefined)
		setIsDialogOpen(false)
		if (setIsOpen) setIsOpen(false)
	}
	const closeSuccessDialog = () => {
		setIsSuccessDialogOpen(false)
		if (setIsOpen) setIsOpen(false)
	}
	const closeErrorDialog = () => {
		if (setIsOpen) setIsOpen(false)
		setIsErrorDialogOpen(false)
	}

	const importClasses = async () => {
		const signedUpWithCanvas = integrationName === Integration_Name_Enum.Canvas
		if (signedUpWithCanvas) setIntegrationName(undefined)
		setIsLoading(true)
		const CanvasToken = canvasInstance?.account?.access_token || ''
		const teacherId = teacherData?.teacher_id

		if (!(CanvasToken && teacherId)) return

		try {
			const res = await roastCanvasClasses({
				variables: { ids: selectedClasses.map((id) => +id), idToken: CanvasToken }
			})
			const success = res.data?.rosterCanvasClasses.success
			if (!success) {
				throw new Error(String(success))
			}
			setIsDialogOpen(false)
			setIsSuccessDialogOpen(true)
		} catch (error) {
			console.error("Couldn't import Canvas classes", error)
			setIsErrorDialogOpen(true)
		}
		setIsLoading(false)
	}

	const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const classSourcedId = event.target.value
		const selectedClassesCopy = [...selectedClasses]
		if (checked) {
			selectedClassesCopy.push(classSourcedId)
		} else {
			const itemPosition = selectedClassesCopy.indexOf(classSourcedId)
			selectedClassesCopy.splice(itemPosition, 1)
		}
		setSelectedClasses(selectedClassesCopy)
	}

	useEffect(() => {
		const signedUpWithCanvas = integrationName === Integration_Name_Enum.Canvas
		if (signedUpWithCanvas) setIsDialogOpen(true)
	}, [integrationName])

	useEffect(() => {
		if (isOpen === undefined) return
		setIsDialogOpen(isOpen)
		setSelectedClasses([])
	}, [isOpen])

	return (
		<>
			<BaseDialog
				open={isDialogOpen}
				confirmLabel="Import classes"
				isConfirmLoading={isLoading}
				isConfirmDisabled={!selectedClasses.length || isLoading}
				discardLabel={isOpen === undefined ? 'Skip' : 'Close'}
				onConfirm={importClasses}
				onClose={() => {}}
				onDiscard={closeDialog}
				header={
					<>
						<Typography variant="h5" align="center" style={{ marginTop: '10px' }}>
							<b>Import Canvas Classes</b>
						</Typography>
						<Box>
							<Typography style={{ textAlign: 'center', margin: '10px 0' }}>
								Select the classes to import from Canvas
							</Typography>
						</Box>
					</>
				}
				body={
					<Box>
						{classes.length ? (
							<FormControl component="fieldset">
								<FormGroup>
									{(classes as CanvasGroup[]).map((item) => (
										<FormControlLabel
											key={item.id}
											control={
												<Checkbox
													checked={selectedClasses.includes(String(item.id))}
													onChange={handleChange}
												/>
											}
											label={item.name}
											value={item.id}
										/>
									))}
								</FormGroup>
							</FormControl>
						) : canvasInstance.loading ? (
							<EmptyClassesBox>
								<CircularProgress color="secondary" size={30} />
							</EmptyClassesBox>
						) : (
							<EmptyClassesBox>
								<InfoIcon color="secondary" style={{ marginRight: '.3em' }} />
								<Typography>No classes are available for import</Typography>
							</EmptyClassesBox>
						)}
					</Box>
				}
			/>
			<InfoDialog
				open={isErrorDialogOpen}
				onClose={closeErrorDialog}
				icon="x"
				title="Something went wrong"
				body="Something went wrong while importing your classes."
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				discardProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={closeErrorDialog}
			/>
			<InfoDialog
				open={isSuccessDialogOpen}
				onClose={closeSuccessDialog}
				icon={<ThinCheckIcon />}
				title="Sync process started!"
				body="Your Canvas classes are now syncing."
				confirmLabel="Done"
				onConfirm={closeSuccessDialog}
			/>
		</>
	)
}
