import { Fragment } from 'react'

import { Box, Divider, List } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Sequence } from 'src/graphql/autogenerate/schemas'

import { buildImagePath, concatenate } from '../../../utils'
import { ListItemAddSequence } from './ListItemAddSequence'

const useStyles = makeStyles((theme: Theme) => ({
	boxSongs: {
		padding: 0,
		'& .MuiBox-root': {
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

type ListItemsProps = {
	spacing?: number
	lineDivider?: boolean
	data: ({ selected: boolean } & Sequence)[]
	onSelect: (item: { selected: boolean } & Sequence) => void
}

export const ListAddItems = ({ spacing = 0, lineDivider, data, onSelect }: ListItemsProps) => {
	const classes = useStyles(spacing)

	return (
		<Fragment>
			<List className={classes.boxSongs}>
				{data.map((item, i) => (
					<Box
						style={{
							cursor: 'pointer',
							backgroundColor: item.selected ? 'rgba(255, 194, 12, 0.1)' : '#fff'
						}}
						key={concatenate([i, item[`sequence_id` as keyof typeof item], item.name], '-')}
						overflow="hidden"
						boxShadow={spacing > 0 ? 1 : 0}
						margin="5px"
						borderRadius={spacing > 0 ? 4 : 0}
						onClick={() => {
							onSelect(item)
						}}>
						<ListItemAddSequence
							imageUrl={buildImagePath(item.image_path)}
							title={item.name}
							genres={`• `}
							selected={item.selected}
						/>
						{lineDivider && data.length !== i + 1 && <Divider variant="middle" />}
					</Box>
				))}
			</List>
		</Fragment>
	)
}
