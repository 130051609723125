import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { parseAsync } from 'json2csv'
import moment from 'moment'
import { ThinCheckIcon } from 'src/assets/icons'
import { InfoDialog, SelectClassDialog } from 'src/components'
import { useGetClassAssignmentsExtendedLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Class, Class_Assignment, Order_By, Submission_State_Enum } from 'src/graphql/autogenerate/schemas'

type ExportInsightsDialogProps = {
	open: boolean
	setOpen: (open: boolean) => void
}

export const ExportInsightsDialog = ({ open, setOpen }: ExportInsightsDialogProps) => {
	const [isClassesListOpen, setIsClassesListOpen] = useState(false)
	const [selectedClasses, setSelectedClasses] = useState<Class[]>([])
	const [isSuccessOpen, setIsSuccessOpen] = useState(false)
	const [isErrorOpen, setIsErrorOpen] = useState<string>()

	const [getClassAssignmentsRefetch, { data, loading }] = useGetClassAssignmentsExtendedLazyQuery({
		variables: {
			classId: selectedClasses.length ? selectedClasses[0].class_id : null,
			limit: 100,
			order: Order_By.Asc
		}
	})

	useEffect(() => {
		if (!loading && data?.class_assignment && selectedClasses.length) {
			const formatted = getFormattedData(data.class_assignment as unknown as Class_Assignment[])
			exportObject(formatted)
		}
	}, [data, loading, selectedClasses])

	const getFormattedData = (data: Class_Assignment[]) => {
		let formatted
		if (data.length)
			formatted = data.map((ca) => {
				return {
					status: ca.status,
					assignmentName: ca.assignment?.name,
					assignmentInstructions: ca.assignment?.instructions,
					assignmentDueDate: ca.assignment?.due_date,
					assignmentDueTime: ca.assignment?.due_time,
					totalSubmissions: ca.submission_aggregate.aggregate?.count || 0,
					totalSubmissionsNotStarted: ca.submission.filter(
						(s) => s.state === Submission_State_Enum.Start
					).length,
					totalSubmissionsInProgress: ca.submission.filter(
						(s) => s.state === Submission_State_Enum.InProgress
					).length,
					totalSubmissionsSubmitted: ca.submission.filter(
						(s) => s.state === Submission_State_Enum.Submitted
					).length,
					totalSubmissionsGraded: ca.submission.filter((s) => s.state === Submission_State_Enum.Graded)
						.length
				}
			})

		// numberOfAssignmentsInClass: data.class_assignment_aggregate.aggregate?.count || 0,
		// numberOfStudentsInClass: data.class_student_aggregate.aggregate?.count || 0

		return formatted
	}

	const exportObject = async (data: ReturnType<typeof getFormattedData>) => {
		const fields: string[] = []
		const opts = fields.length ? { fields } : undefined
		const nameFile = `wurrlyedu-report-${moment().format('ddMMyyyyhhmmss')}.csv`

		if (data?.length) {
			const resultCSV = await parseAsync(data, opts)

			if (encodeURI && window) {
				const url = encodeURI(resultCSV)
				const link = document.createElement('a')
				link.setAttribute('href', 'data:text/csv;charset=utf-8,' + url)
				link.setAttribute('download', nameFile)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
			setIsSuccessOpen(true)
		} else if (data?.length === 0) {
			setIsErrorOpen('This class have no assignments to be exported!')
		} else {
			setIsErrorOpen('Something went wrong while genereting the report. Please try again.')
		}

		// after exporting the object clean the selection array
		setSelectedClasses([])
	}

	const handleSelectClass = async (classe: Class) => {
		setSelectedClasses([classe])
		getClassAssignmentsRefetch()
	}

	const handleCloseExportDialog = () => setOpen(false)
	const handleDiscardExportDialog = () => {
		setOpen(false)
		setSelectedClasses([])
	}
	const handleConfirmExportDialog = () => {
		setOpen(false)
		setIsClassesListOpen(true)
	}

	const handleCloseSuccessDialog = () => setIsSuccessOpen(false)
	const handleConfirmSuccessDialog = () => setIsSuccessOpen(false)

	const handleCloseErrorDialog = () => setIsErrorOpen(undefined)
	const handleConfirmErrorDialog = () => setIsErrorOpen(undefined)

	return (
		<Box>
			<InfoDialog
				open={open}
				onClose={handleCloseExportDialog}
				title="Export Insights"
				body="Would you like to generate or update your Skills Report and include in this export, or continue without?"
				discardLabel="Continue Without"
				onDiscard={handleDiscardExportDialog}
				confirmLabel="Generate Now"
				onConfirm={handleConfirmExportDialog}
				icon="?"
			/>
			<SelectClassDialog
				title="Select a Class"
				description="Please select a Class to add this Assignment to."
				itemClasses={selectedClasses}
				isOpen={isClassesListOpen}
				setIsOpen={setIsClassesListOpen}
				onConfirm={handleSelectClass}
			/>
			<InfoDialog
				open={isSuccessOpen}
				onClose={handleCloseSuccessDialog}
				title="Report Generated!"
				body="You have successfully generated your Insights report. Your download will start automatically."
				confirmLabel="Done"
				onConfirm={handleConfirmSuccessDialog}
				icon={<ThinCheckIcon />}
			/>
			<InfoDialog
				open={!!isErrorOpen}
				onClose={handleCloseErrorDialog}
				icon="x"
				title="Something went wrong"
				body={isErrorOpen}
				confirmLabel="Done"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				onConfirm={handleConfirmErrorDialog}
			/>
		</Box>
	)
}
