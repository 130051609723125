import { useMemo } from 'react'

import { ClassWithCounters } from 'src/@types'
import { GetClassesByStudentQuery } from 'src/graphql/autogenerate/operations'
import { useLoginContext } from 'src/hooks/useLogin'

import { useGetClassesByStudentQuery } from '../../../../graphql/autogenerate/hooks'

const getClassListWithVideoCount = (data: GetClassesByStudentQuery['class']) => {
	const classesWithVideoCount = data?.map((classItem) => {
		// get video counters
		const tips = classItem.tipsCount.map((tip) => tip.tip_id)
		const tipsInPlaylist =
			classItem.playlistCount
				?.map((playlist) => playlist.playlist.tip_playlist.map((tip) => tip.tip_id))
				.flat() || []

		const totalVideos = new Set([...tips, ...tipsInPlaylist]).size
		// get lessons counters
		const lessons = classItem.lessonPlanList.map((lesson) => lesson.lesson_plan_id)
		const lessonsInModules = classItem.lessonsInModules
			.map((class_module) => class_module.module.module_lesson_plan.map((lesson) => lesson.lesson_plan_id))
			.flat()

		const lessonsInSequences = classItem.lessonsInSecuences
			.map((class_sequence) =>
				class_sequence.sequence.sequence_lesson_plans.map((lesson) => lesson.lesson_plan_id)
			)
			.flat()

		const totalLessons = new Set([...lessons, ...lessonsInModules, ...lessonsInSequences]).size

		return { ...classItem, totalVideos, totalLessons }
	})

	return classesWithVideoCount
}

export const useClasses = () => {
	const { studentData: student } = useLoginContext()

	const { loading, data: classData } = useGetClassesByStudentQuery({
		variables: { studentId: student.student_id },
		skip: !student.student_id
	})
	const classes = useMemo(() => {
		return getClassListWithVideoCount(classData?.class || []) as unknown as ClassWithCounters[]
	}, [classData])

	return { loading, classes, student }
}
