import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
	createStyles({
		fieldTitle: {
			fontSize: '1.3em',
			fontWeight: 500,
			marginBottom: '.8em',
			'& b, strong': {
				fontWeight: 500
			}
		},
		textDefault: {
			color: theme.palette.text.secondary
		},
		textSuccess: {
			color: theme.palette.success.dark
		},
		textError: {
			color: theme.palette.error.dark
		}
	})
)
