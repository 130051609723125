import { makeStyles } from '@material-ui/core'
import { useSearchQuery } from 'src/hooks'
import { generateUniqueId } from 'src/utils'

const useStyles = makeStyles({
	'@global': {
		body: {
			backgroundColor: 'black',
			overflow: 'hidden'
		}
	},
	video: {
		border: 0,
		height: 300,
		width: '100%'
	}
})

export const Frame = () => {
	const queryParams = useSearchQuery()
	const styles = useStyles()
	const url = queryParams.get('url') || ''

	return (
		<video key={generateUniqueId()} className={styles.video} controls={true} muted autoPlay={false}>
			<source src={url} />
		</video>
	)
}
