import { useEffect, useState } from 'react'

import { Box, InputBase, Typography } from '@material-ui/core'
import { BaseDialog, BaseLink, ContactDialog, InfoDialog } from 'src/components'
import { useSendResetPasswordMailLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginStyles } from 'src/scenes/Login/styles'
import { validateEmail, getCyKey } from 'src/utils'

type RecoverPasswordMailSendDialogProps = {
	open: boolean
	closeDialog: () => void
	isTeacher: boolean
}

export const RecoverPasswordMailSendDialog = ({
	open,
	closeDialog,
	isTeacher
}: RecoverPasswordMailSendDialogProps) => {
	const classes = useLoginStyles({})
	const [resetPasswordEmail, setResetPasswordEmail] = useState('')
	const [isContactDialogOpen, setIsContactDialogOpen] = useState(false)
	const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false)
	const [sendResetPasswordMail, { data: sendMailData, error: sendMailError, loading: sendMailLoading }] =
		useSendResetPasswordMailLazyQuery({ fetchPolicy: 'no-cache' })
	const closeContactDialog = () => setIsContactDialogOpen(false)
	useEffect(() => {
		if (sendMailData || sendMailError) setInfoDialogOpen(true)
	}, [sendMailData, sendMailError])

	return (
		<>
			<BaseDialog
				backdropStyles={{ opacity: '0 !important' }}
				header={
					<>
						<Typography
							style={{ paddingBottom: '40px', fontWeight: 'bolder' }}
							variant="h4"
							align="center">
							Forgot Your Password?
						</Typography>
					</>
				}
				discardLabel="Cancel"
				confirmLabel="Reset"
				isConfirmLoading={sendMailLoading}
				onConfirm={() => {
					sendResetPasswordMail({
						variables: { isTeacher, isStudent: !isTeacher, email: resetPasswordEmail }
					})
				}}
				open={open}
				onClose={() => {}}
				onDiscard={closeDialog}
				dividers={false}
				typographyProps={{
					style: {
						fontWeight: 'bold'
					}
				}}
				onConfirmProps={{
					style: {
						width: '40%'
					}
				}}
				onDiscardProps={{
					style: {
						width: '40%'
					}
				}}
				isConfirmDisabled={!validateEmail(resetPasswordEmail)}
				body={
					<Box style={{ marginBottom: 50 }}>
						<Typography style={{ textAlign: 'center' }}>
							Enter your e-mail address and we’ll send you a link to reset your password.
						</Typography>
						<Typography className={classes.label}>
							<b>Email</b>
						</Typography>
						<InputBase
							data-cy={getCyKey(RecoverPasswordMailSendDialog, 'resetEmail')}
							className={classes.input}
							value={resetPasswordEmail}
							fullWidth={true}
							onChange={(e) => {
								setResetPasswordEmail(e.target.value)
							}}
							style={
								resetPasswordEmail.length > 0 && !validateEmail(resetPasswordEmail)
									? { border: '1px solid red' }
									: {}
							}
						/>
						{resetPasswordEmail.length > 0 && !validateEmail(resetPasswordEmail) && (
							<Typography style={{ color: 'red' }}>Use a valid Email</Typography>
						)}
					</Box>
				}
			/>
			<InfoDialog
				open={infoDialogOpen}
				onClose={() => {
					setInfoDialogOpen(false)
					closeDialog()
				}}
				onConfirm={() => {
					setInfoDialogOpen(false)
					closeDialog()
				}}
				icon={'✔'}
				title={undefined}
				confirmLabel="Close"
				confirmProps={{ style: { fontWeight: 'bold' } }}
				body={
					<Typography>
						If we have your email on file, we are going to send you the instructions... If you need
						help, please{' '}
						{
							<BaseLink
								onClick={() => {
									setInfoDialogOpen(false)
									setIsContactDialogOpen(true)
									// TODO: set link
								}}>
								Contact us
							</BaseLink>
						}
						.
					</Typography>
				}
			/>
			<ContactDialog
				open={isContactDialogOpen}
				onClose={closeContactDialog}
				onDiscard={closeContactDialog}
				closeDialog={closeContactDialog}
			/>
		</>
	)
}
