import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { SubscriptionsCardType } from 'src/@types'
import {
	useCancelProductSubscriptionMutation,
	useGetPlaylistsLazyQuery,
	useGetTeacherSubscriptionsLazyQuery,
	useSearchPlaylistLazyQuery
} from 'src/graphql/autogenerate/hooks'
import {
	CancelProductSubscriptionMutation,
	GetTeacherSubscriptionsQuery
} from 'src/graphql/autogenerate/operations'
import {
	Order,
	Order_Status_Enum,
	Playlist,
	Price_Type_Enum,
	Product_Name_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import {
	buildBreadCrumbs,
	buildPlaylistSearchParams,
	buildRouteParameters,
	buildSearchText,
	createSuscriptionCardData,
	MAX_LIST_ITEMS,
	orderBySortOrder,
	SortOrder
} from 'src/utils'

export const usePlaylistPage = () => {
	const history = useHistory()
	const limit = MAX_LIST_ITEMS
	const [playlist, setPlaylist] = useState<Playlist[]>([])
	const [playlistId, setPlaylistId] = useState<number>()
	const [playlistSuscriptions, setPlaylistSuscriptions] = useState<SubscriptionsCardType[]>([])
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const { teacherData } = useLoginContext()
	const [sort, setSort] = useState(SortOrder.Up)
	const [filter, setFilter] = useState('None')
	const [isOpen, setIsOpen] = useState(false)
	const [totalPlaylists, setTotalPlaylists] = useState<number>()
	const [searchbarText, setSearchbarText] = useState('')
	const [searchbarData, setSearchbarData] = useState([] as Playlist[])
	const [searchTeacherSubscriptionsData, setSearchTeacherSubscriptionsData] = useState<SubscriptionsCardType[]>(
		[]
	)
	const [handleEnter, setHandleEnter] = useState(false)
	const [addToClassDialog, setAddToClassDialog] = useState<Playlist>()
	const [isAddPlaylisToClassOpen, setIsAddPlaylistToClassOpen] = useState(false)
	const [unSuscribeOpenDialog, setUnSuscribeOpenDialog] = useState(false)
	const [item, setItem] = useState<SubscriptionsCardType>()
	const [onCompleteRes, setOnCompleteRes] = useState<boolean | undefined>(undefined)
	const showSubscriptions = history.location.search === '?from=subs'

	const breadcrumbs = buildBreadCrumbs({
		page: showSubscriptions ? Pages.PlaylistsSubs : Pages.Playlists
	})

	const [cancelProductSubscription] = useCancelProductSubscriptionMutation()
	const [loadPlayList, { data: playlistData, loading: playlistDataLoading, refetch }] =
		useGetPlaylistsLazyQuery()
	const [getSearchPlaylists, { data: searchBarPlaylists, loading: searchbarPlaylistsLoading }] =
		useSearchPlaylistLazyQuery()

	const [
		GetTeacherSubscriptions,
		{ data: suscriptionData, loading: suscriptionDataLoading, refetch: teacherSubscriptionsFefetch }
	] = useGetTeacherSubscriptionsLazyQuery()
	const [GetSearchTeacherSubscriptions, { data: searchSuscriptionData, loading: searchSuscriptionDataLoading }] =
		useGetTeacherSubscriptionsLazyQuery()

	useEffect(() => {
		if (!showSubscriptions) {
			if (searchBarPlaylists) setSearchbarData(searchBarPlaylists?.playlist as Playlist[])
		} else {
			if (searchSuscriptionData) tranformSuscriptionData(searchSuscriptionData, true)
		}
	}, [searchBarPlaylists, searchSuscriptionData])

	useEffect(() => {
		setIsFetching(false)
		if (playlistData) {
			setTotalPlaylists(playlistData?.playlist_aggregate.aggregate?.count)
			setPlaylist((playlist) => [...playlist, ...((playlistData.playlist || []) as Playlist[])])
		}
		if (suscriptionData) {
			setTotalPlaylists(suscriptionData?.order_aggregate.aggregate?.count)
			tranformSuscriptionData(suscriptionData, false)
		}
	}, [playlistData, suscriptionData])

	useEffect(() => {
		setMoreResults(true)
		if (showSubscriptions) {
			if (!handleEnter) setPlaylistSuscriptions([])
			GetTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Playlist,
					order_by: {
						playlist: {
							name: orderBySortOrder[sort]
						}
					},
					limit,
					search: buildSearchText(searchbarText, ['name', 'description'], Product_Name_Enum.Playlist),
					price_type: filter !== 'None' ? (filter as Price_Type_Enum) : undefined
				},
				onCompleted: () => {
					setHandleEnter(false)
				}
			})
		} else {
			if (!handleEnter) setPlaylist([])
			loadPlayList({
				variables: {
					teacher_id: teacherData.teacher_id,
					search: buildPlaylistSearchParams(searchbarText || ''),
					offset: 0,
					limit,
					order_by: {
						name: orderBySortOrder[sort]
					}
				},
				onCompleted: () => {
					setHandleEnter(false)
				}
			})
		}
	}, [sort, filter])

	useEffect(() => {
		if (showSubscriptions) {
			setSearchTeacherSubscriptionsData([])
			GetSearchTeacherSubscriptions({
				variables: {
					limit,
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Playlist,
					order_by: {
						playlist: {
							name: orderBySortOrder[SortOrder.Up]
						}
					},
					search: buildSearchText(searchbarText, ['name', 'description'], Product_Name_Enum.Playlist)
				}
			})
		} else {
			getSearchPlaylists({
				variables: {
					teacher_id: teacherData.teacher_id,
					limit,
					searchText: buildSearchText(searchbarText, ['name', 'description'])
				}
			})
		}
	}, [searchbarText])

	useEffect(() => {
		if (!isFetching || !moreResults) return
		if (showSubscriptions) {
			const currentLength = playlistSuscriptions.length
			teacherSubscriptionsFefetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.order.length !== 0 && fetchMoreResult.data?.order.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		} else {
			const currentLength = playlist.length
			refetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (
					fetchMoreResult.data?.playlist.length !== 0 &&
					fetchMoreResult.data?.playlist.length >= limit
				) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])

	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	useEffect(() => {
		if (item) {
			setUnSuscribeOpenDialog(true)
		}
	}, [item])
	const tranformSuscriptionData = (data: GetTeacherSubscriptionsQuery, isSearchData: boolean) => {
		data.order.map((item) => {
			const order = item as Order
			if (isSearchData) {
				setSubscriptionSearchData(createSuscriptionCardData(searchTeacherSubscriptionsData, order))
			} else {
				setSubscriptionData(createSuscriptionCardData(playlistSuscriptions, order))
			}
		})
	}
	const setSubscriptionSearchData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setSearchTeacherSubscriptionsData((subscription) => [...subscription, data])
		}
	}
	const setSubscriptionData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setPlaylistSuscriptions((subscription) => [...subscription, data])
		}
	}
	const onUnsubscribe = async (stripeSubcriptiptionId: string) => {
		return new Promise<CancelProductSubscriptionMutation>((resolve, reject) => {
			cancelProductSubscription({
				variables: {
					teacherId: teacherData.teacher_id,
					stripeSubcriptiptionId
				},
				onCompleted: (res) => {
					setOnCompleteRes(res?.cancelProductSubscription?.success)
					resolve(res)
				},
				onError: () => {
					reject(new Error('Unsubscribe error'))
				}
			})
		})
	}

	const handleRefetch = (stripeSubcriptiptionId: string) => {
		if (onCompleteRes) {
			const playlistSuscriptionsItem = playlistSuscriptions.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			const searchTeacherSubscriptionsDataItem = searchTeacherSubscriptionsData.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			if (playlistSuscriptionsItem) {
				playlistSuscriptionsItem.status = Order_Status_Enum.Canceled
			}
			if (searchTeacherSubscriptionsDataItem) {
				searchTeacherSubscriptionsDataItem.status = Order_Status_Enum.Canceled
			}
		}
	}
	const handleSearchbarTextEnter = (text: string) => {
		if (!showSubscriptions) {
			setPlaylist([])
			loadPlayList({
				variables: {
					teacher_id: teacherData.teacher_id,
					search: buildPlaylistSearchParams(text || ''),
					offset: 0,
					limit,
					order_by: {
						name: orderBySortOrder[sort]
					}
				},
				onCompleted: () => {
					setSort(SortOrder.Up)
				}
			})
		} else {
			if (!text) setHandleEnter(true)
			setFilter('None')
			setSort(SortOrder.Up)
			setPlaylistSuscriptions([])
			GetTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Playlist,
					order_by: {
						playlist: {
							name: orderBySortOrder[SortOrder.Up]
						}
					},
					limit,
					search: buildSearchText(text, ['name', 'description'], Product_Name_Enum.Playlist)
				},
				onCompleted: () => {
					setHandleEnter(false)
				}
			})
		}
	}

	const handleSearchbarTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchbarText(e.target.value)
	}
	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const onFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const selectedFilter = event.target.value as Price_Type_Enum
		setFilter(selectedFilter)
	}

	const goToPlaylist = (playlistId: number) => {
		history.push(buildRouteParameters(Pages.PlaylistDetails, { playlistId }))
	}

	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	return {
		playlist,
		searchbarData,
		playlistSuscriptions,
		searchTeacherSubscriptionsData,
		isFetching,
		moreResults,
		sort,
		searchbarText,
		handleSort,
		breadcrumbs,
		searchSuscriptionDataLoading,
		searchbarPlaylistsLoading,
		playlistDataLoading,
		suscriptionDataLoading,
		onUnsubscribe,
		onFilterChange,
		filter,
		showSubscriptions,
		totalPlaylists,
		handleSearchbarTextEnter,
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		setAddToClassDialog,
		setIsAddPlaylistToClassOpen,
		setPlaylistId,
		isAddPlaylisToClassOpen,
		addToClassDialog,
		playlistId,
		isOpen,
		goToPlaylist,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	}
}
