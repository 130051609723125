import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useSubmissionCardStyles = makeStyles<Theme, { lateSubmission: boolean }>((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			width: '100%',
			alignItems: 'flex-start',
			'& > *:not(:last-child)': {
				marginRight: theme.spacing(2)
			}
		},
		avatar: {
			height: theme.spacing(6),
			width: theme.spacing(6)
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			paddingTop: theme.spacing(0.1)
		},
		name: {
			lineHeight: 1.2
		},
		date: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flexStart',
			color: ({ lateSubmission }) => (lateSubmission ? theme.palette.error.main : theme.palette.grey[500]),
			marginTop: theme.spacing(0.5)
		},
		dateContent: {
			display: 'flex',
			flexDirection: 'column',
			'& p': {
				lineHeight: '1.2em'
			}
		},
		clockIcon: {
			marginTop: theme.spacing(0.2),
			marginRight: theme.spacing(0.5)
		},
		message: {
			marginTop: theme.spacing(1.5)
		},
		grading: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			alignSelf: 'center'
		},
		gradeLabel: {
			fontSize: '1.2em',
			color: theme.palette.grey[500],
			marginRight: theme.spacing(1.5)
		}
	})
)

export default useSubmissionCardStyles
