import { useMemo } from 'react'

import { Box, Typography, TypographyProps } from '@material-ui/core'
import { CheckBoxOutlineBlank } from '@material-ui/icons'
import clsx from 'clsx'
import { Doughnut } from 'react-chartjs-2'
import { ChartDataType } from 'src/@types'
import { NoItems } from 'src/components/No/NoItems'
import { useInsightsContext } from 'src/scenes/Teacher/scenes/1-Home/components/Insights/hooks/useInsights'
import { generateUniqueId, slugToName } from 'src/utils'
import { MusicStandars } from 'src/utils/MusicStandard'

import useStyles from './DoughnutChart.styles'

type DoughnutChartProps = {
	title: string
	titleProps?: TypographyProps
	data?: ChartDataType
}

export const DoughnutChart = ({ title, titleProps, data }: DoughnutChartProps) => {
	const styles = useStyles()
	const { setChartAcordeonData, setPercentage } = useInsightsContext()
	const hasAtLeastAValue = useMemo(() => {
		return data?.datasets[0]?.data?.some((item) => item > 0)
	}, [data])

	return (
		<Box>
			{hasAtLeastAValue ? (
				<>
					<Box>
						<Box className={styles.titleHolder}>
							<Typography
								variant="subtitle2"
								{...titleProps}
								className={clsx(styles.title, titleProps?.className || '')}>
								{title}
							</Typography>
							{data && (
								<Doughnut
									data={data}
									options={{
										events: ['click'],
										responsive: true,
										maintainAspectRatio: true,
										cutout: '85%',
										radius: 150,
										onClick(_e, item) {
											setChartAcordeonData(
												data?.labels[item[0]?.index] as MusicStandars | undefined
											)
										},
										plugins: {
											legend: {
												display: false
											},
											tooltip: {
												displayColors: false,
												xAlign: 'right',
												yAlign: 'top',
												callbacks: {
													title: () => {
														return ''
													},
													label: (context) => {
														const rawValue = parseFloat(
															context.formattedValue.replace(',', '.')
														).toFixed(2)

														const percentage = String(rawValue + '%')

														setPercentage(percentage)

														return percentage
													}
												}
											}
										}
									}}
								/>
							)}
						</Box>
						<Box className={styles.labelsHolder}>
							{{ ...data }?.labels
								?.filter((lbl) => lbl !== 'noData')
								.map((label, index) => (
									<Box key={generateUniqueId()} className={styles.labelReferenceHolder}>
										<CheckBoxOutlineBlank
											className={styles.labelReference}
											style={{
												backgroundColor:
													data?.datasets[0].borderColor &&
													data?.datasets[0].borderColor[index],
												color:
													data?.datasets[0].borderColor &&
													data?.datasets[0].borderColor[index]
											}}
										/>
										<Typography variant="caption">
											{slugToName(label.replace('_', ' '))}
										</Typography>
									</Box>
								))}
						</Box>
					</Box>
				</>
			) : (
				<Box
					style={{
						height: '400px',
						width: '400px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: 'auto'
					}}>
					<NoItems title="No data for the Class and Date range selected" description="" />
				</Box>
			)}
		</Box>
	)
}
