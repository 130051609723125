import { useState, useEffect } from 'react'

import { Box, CircularProgress, Divider, Typography, createStyles, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { AccordionWrapper, DeleteItemDialog, ListLessons, MainTitle, NoItems } from 'src/components'
import { useDeleteLessonFromClassMutation, useGetClassTeacherByPkQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildBreadCrumbs, getCyKey, slugToName } from 'src/utils'
import { buildRouteParameters } from 'src/utils/routes-utils'

import { ClassLessonProvider, useClassLessonContext } from './useClassLesson'

export const useSavedLessonsStyles = makeStyles((theme) =>
	createStyles({
		seeMore: { cursor: 'pointer' },
		spacing: { margin: '20px 0 40px 0' },
		link: {
			color: theme.palette.secondary.dark
		}
	})
)

export default useSavedLessonsStyles

export const SavedLessonsViewAll = ({ page, teacherId }: PagesProps) => {
	return (
		<>
			<ClassLessonProvider>
				<SavedLessonsView page={page} teacherId={teacherId} />
			</ClassLessonProvider>
		</>
	)
}

export const SavedLessonsView = ({ page }: PagesProps) => {
	const {
		teacher,
		teacherId,
		classId,
		lessonsByClass: { data, loading },
		setLessonByClassVariables
	} = useClassLessonContext()
	const [limitLessons] = useState<number | undefined>(undefined)
	const { data: classData } = useGetClassTeacherByPkQuery({ variables: { teacherId, classId } })

	useEffect(() => {
		if (setLessonByClassVariables) {
			setLessonByClassVariables({
				classId,
				teacherId,
				limit: limitLessons
			})
		}
	}, [limitLessons])

	const history = useHistory()

	const lessons = (data?.lesson_plan || []) as Lesson_Plan[]
	const count = data?.lesson_plan_aggregate.aggregate?.count || 0

	const [lessonIdToDelete, setLessonIdToDelete] = useState<number>()
	const [deleteLessonFromClass] = useDeleteLessonFromClassMutation()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
			},
			{
				index: 3,
				overrideName: 'Lessons'
			}
		],
		params: {
			classId
		}
	})

	const show = page.name === 'Class Lessons View All'

	return (
		<>
			{show && (
				<Box>
					<Box>
						{loading && (
							<Box m={3} pt={3} textAlign="center">
								<CircularProgress color="secondary" size={40} />
							</Box>
						)}

						{!loading && (
							<Box pt={3}>
								<MainTitle
									breadcrumbs={breadcrumbs}
									title={'Class Lessons'}
									description={'These are the Lessons that have been added to this Class.'}
									rightActions={[
										{
											variant: 'contained',
											color: 'secondary',
											startIcon: <AddIcon />,
											name: 'Add Lesson',
											onClick: () => {
												history.push(buildRouteParameters(Pages.Lessons))
											}
										}
									]}
								/>
								<AccordionWrapper
									defaultExpanded
									summary={
										<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
											<Typography
												data-cy={getCyKey(SavedLessonsViewAll, `numberOfSavedLessons`)}
												variant="h6">
												<b
													data-cy={getCyKey(
														SavedLessonsViewAll,
														`countSavedLessons-${count}`
													)}>
													Saved Lessons ({count})
												</b>
											</Typography>
											<div style={{ flexGrow: 1 }} />
										</Box>
									}
									details={
										count <= 0 ? (
											<NoItems
												title="You haven’t added any Lessons to this Class yet."
												description="It’s easy to find your next Lesson by exploring the content area below."
											/>
										) : (
											<ListLessons
												teacherId={teacherId}
												spacing={1.5}
												data={lessons}
												removeButton
												overrideOnClick={(lessonPlanId) => {
													history.push(
														buildRouteParameters(
															Pages.ClassLessonDetail, // Route with paramters
															{ classId, lessonPlanId }
														)
													)
												}}
												overrideDelete={(lessonPlanId) => {
													setLessonIdToDelete(lessonPlanId)
												}}
												classId={classId}
												addAssignmentButton
											/>
										)
									}
								/>
							</Box>
						)}

						{!loading && count > 0 && <Divider />}
					</Box>
					<DeleteItemDialog
						itemName="Lesson"
						optionalMessages={{
							mainMessages: {
								title: 'Delete this Lesson from your Class?',
								body: 'You’ll be able to re-add it from the Lessons page at any time.',
								buttonLabel: ' Delete Lesson'
							},
							confirmation: {
								title: 'Lesson Deleted!',
								body: 'You have successfully deleted this Lesson from your Class.'
							}
						}}
						isOpen={!!lessonIdToDelete}
						setIsOpen={setLessonIdToDelete}
						onConfirm={async () => {
							if (lessonIdToDelete) {
								await deleteLessonFromClass({
									variables: {
										classId,
										lessonPlanId: lessonIdToDelete
									},
									update: (cache, { data }) => {
										const classToUpdateInCache =
											data?.delete_class_lesson_plan?.returning[0].class
										if (!classToUpdateInCache) return

										// update class lesson plans
										let identify = cache.identify(classToUpdateInCache)
										cache.evict({
											id: identify,
											fieldName: 'class_lesson_plans'
										})

										// update teacher favorites
										identify = cache.identify(teacher)
										cache.evict({
											id: identify,
											fieldName: 'teacher_lesson_plan_favorites'
										})
										cache.evict({
											id: 'ROOT_QUERY',
											fieldName: 'lesson_plan'
										})
									}
								})
							}
						}}
					/>
				</Box>
			)}
		</>
	)
}
