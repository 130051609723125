import { useState } from 'react'

import { useParams } from 'react-router-dom'

import { LiteralTypes } from '../../../../../../../@types'
import { buildGenericContext } from '../../../../../../../context/genericContext'
import {
	useGetClassLessonPlansQuery,
	useGetClassModulesQuery,
	useGetClassSequenceQuery,
	useGetTeacherFavLessonPlansQuery
} from '../../../../../../../graphql/autogenerate/hooks'
import {
	GetClassLessonPlansQueryVariables,
	GetClassModulesQueryVariables,
	GetClassSequenceQueryVariables,
	GetTeacherFavLessonPlansQueryVariables
} from '../../../../../../../graphql/autogenerate/operations'
import { Order_By } from '../../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../../hooks/useLogin'
import { Pages } from '../../../../../../../routes/teacherPages'
import { MAX_LIST_ITEMS } from '../../../../../../../utils/constants'

const useClassLesson = () => {
	const { teacherData: teacher } = useLoginContext()
	const params = useParams<LiteralTypes<typeof Pages.ClassLesson.params[number]>>()
	const classId = Number(params.classId)
	const teacherId = teacher.teacher_id
	const INITIAL_QUERY = {
		limit: MAX_LIST_ITEMS,
		orderTitle: Order_By.Asc
	}
	const [lessonByClassVariables, setLessonByClassVariables] = useState<GetClassLessonPlansQueryVariables>({
		...INITIAL_QUERY,
		teacherId,
		classId
	})
	const [favLessonByClassVariables, setFavLessonByClassVariables] =
		useState<GetTeacherFavLessonPlansQueryVariables>({
			...INITIAL_QUERY,
			teacherId,
			notInClassId: classId
		})
	const [classModulesVariables, setClassModulesVariables] = useState<GetClassModulesQueryVariables>({
		classId
	})
	const [classSequencesVariables, setClassSequencesVariables] = useState<GetClassSequenceQueryVariables>({
		classId
	})

	const lessonsByClass = useGetClassLessonPlansQuery({ variables: lessonByClassVariables })
	const favLessonsByClass = useGetTeacherFavLessonPlansQuery({ variables: favLessonByClassVariables })
	const classModules = useGetClassModulesQuery({ variables: classModulesVariables })
	const classSequences = useGetClassSequenceQuery({ variables: classModulesVariables })

	return {
		teacher,
		teacherId,
		classId,
		lessonByClassVariables,
		setLessonByClassVariables,
		favLessonByClassVariables,
		setFavLessonByClassVariables,
		classModulesVariables,
		setClassModulesVariables,
		classSequencesVariables,
		setClassSequencesVariables,

		lessonsByClass,
		favLessonsByClass,
		classModules,
		classSequences
	}
}

export const [ClassLessonProvider, useClassLessonContext] = buildGenericContext(useClassLesson)
