import { makeStyles, Theme } from '@material-ui/core'
export const useStudentSignInOptionsStyles = makeStyles((theme: Theme) => ({
	baseDialog: {
		'& .MuiBackdrop-root': {
			opacity: '0!important',
			backgroundColor: theme.palette.primary.main
		},
		'& .MuiDialogContent-root': {
			overflow: 'hidden'
		}
	},
	baseDialogNoBackground: {
		'& .MuiBackdrop-root': {
			backgroundColor: 'transparent'
		}
	},
	divider: {
		width: '45%',
		borderTop: '1px solid #221F31',
		position: 'absolute',
		'&.left': {
			left: 0
		},
		'&.right': {
			right: 0
		}
	},
	or: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate3d(-50%,-50%,0)',
		backgroundColor: '#fff',
		padding: '0 20px',
		margin: '0',
		fontSize: '18px'
	},
	dividersContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		position: 'relative',
		margin: '30px 0 20px 0',
		height: '60px'
	},
	optionsContainer: {
		margin: '20px 0',
		width: '115px',
		height: '131px',
		padding: '0px 14px',
		boxShadow:
			'0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
		borderRadius: '4px',
		'& img': {
			height: '80px',
			width: '111px'
		}
	},
	paper: {
		width: '100%',
		maxWidth: '718px',
		padding: '26px 66px 37px',
		display: 'flex',
		justifyContent: 'space-between'
	},
	optionBtn: {
		margin: '0 auto',
		padding: '0px 22px',
		width: '100%'
	},
	classLinkButton: {
		width: '40%!important',
		'& b': {
			paddingLeft: '5px !important'
		}
	}
}))
