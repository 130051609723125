import { useEffect } from 'react'

import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { HiddenPassword } from 'src/components/Profile/HiddenPassword'
import { InfoItem } from 'src/components/Profile/InfoItem'
import { ProfileSection } from 'src/components/Profile/ProfileSection'
import { useGetStudentByPkExtendedLazyQuery } from 'src/graphql/autogenerate/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { StudentPages } from 'src/routes/studentpages'
import { buildRouteParameters } from 'src/utils/routes-utils'

export const ProfileInfo = () => {
	const history = useHistory()
	const goToEdit = () => {
		history.push(buildRouteParameters(StudentPages.ProfileEdit))
	}

	return (
		<ProfileSection
			title="Information"
			body={<InfoContent />}
			editBtn={{
				label: 'Edit Profile',
				action: goToEdit
			}}
		/>
	)
}

const InfoContent = () => {
	const { studentData } = useLoginContext()
	const [getStudentByPkExtended, { data }] = useGetStudentByPkExtendedLazyQuery({
		fetchPolicy: 'no-cache'
	})

	useEffect(() => {
		if (!studentData.student_id) return

		getStudentByPkExtended({
			variables: {
				studentId: studentData.student_id
			}
		})
	}, [studentData.student_id])

	const latName = studentData.last_name ? ` ${studentData.last_name}` : ''

	return (
		<Box>
			<InfoItem title="Full Name" value={`${studentData.first_name}${latName}`} />
			<InfoItem title="Stage Name" value={studentData.stage_name} />
			<InfoItem title="Email" value={studentData.email} />
			<InfoItem title="Password" value={<HiddenPassword length={8} />} />
			<InfoItem title="Student Code" value={data?.student_by_pk?.code} />
		</Box>
	)
}
