import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useLessonCardStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			minHeight: 100,
			border: '1px solid rgba(0, 0, 0, 0.08)',
			backgroundColor: '#FFFFFF',
			boxShadow: '0px 1px 4px 0px rgb(0 0 0 / 8%)',
			borderRadius: 4,
			// alignItems: 'flex-start',
			padding: 15,
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		},
		avatar: {
			marginRight: 5
		},
		lessonTitle: {
			WebkitLineClamp: 2,
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word',
			fontSize: 18
		},
		lessonDescription: {
			WebkitLineClamp: 2,
			textOverflow: 'ellipsis',
			WebkitBoxOrient: 'vertical',
			display: '-webkit-box',
			overflow: 'hidden',
			wordWrap: 'break-word',
			fontSize: 14,
			fontWeight: 'normal',
			padding: '5px 0',
			whiteSpace: 'pre-wrap',
			height: 50
		}
	})
)

export default useLessonCardStyles
