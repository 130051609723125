import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import {
	useDeleteSongByPkMutation,
	useDeleteTrackMutation,
	useGetArtistsQuery,
	useGetScalesQuery,
	useGetSongForStudioQuery,
	useUpdateSongByPkMutation
} from 'src/graphql/autogenerate/hooks'
import { Catalog_Item, Grade } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { queryGrade, QueryGradeType } from 'src/scenes/Teacher/queries'
import { buildRouteParameters, extractLevels } from 'src/utils'
import * as yup from 'yup'

const validationSchema = yup.object({
	title: yup.string().required('Title is required'),
	levels: yup.array().min(1, 'Grade level is required').required('required')
})

export const useUpdateSong = () => {
	const [showDeleteDialog, setShowDeleteDialog] = useState(false)
	const [trackToDelete, setTrackToDelete] = useState<number>()
	const history = useHistory()
	const { songId } = useWurrlyParams()
	const { data: scalesData, loading: scalesLoading } = useGetScalesQuery()
	const { data: artistsData, loading: artistsLoading } = useGetArtistsQuery()
	const [shouldShowDeleteSongError, setShouldShowDeleteSongError] = useState(false)
	const [shouldShowUpdateSongError, setShouldShowUpdateSongError] = useState(false)
	const [deleteTrackHasError, setDeleteTrackHasError] = useState(false)
	const [trackWasDeleted, setTrackWasDeleted] = useState(false)

	const [updateSongMutation, { data: updateData, loading: updateLoading, error: updateError }] =
		useUpdateSongByPkMutation()

	const [deleteSongMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] =
		useDeleteSongByPkMutation()

	const [
		deleteTrackMutation,
		{ data: deleteTrackData, loading: deleteTrackLoading, error: deleteTrackError, reset: resetDeleteTrack }
	] = useDeleteTrackMutation()

	const {
		data: songData,
		loading: songLoading,
		error: songError
	} = useGetSongForStudioQuery({
		variables: {
			songId
		}
	})

	const toggleShowDeleteDialog = () => {
		setShowDeleteDialog((prev) => !prev)
	}

	const gradesCatalog = useQuery<QueryGradeType>(queryGrade)

	const formik = useFormik({
		initialValues: {
			title: '',
			image_path: '',
			key: 'Am',
			featured: false,
			active: false,
			scale_id: 1,
			artist_id: 1,
			genres: [] as Catalog_Item[],
			themes: [] as Catalog_Item[],
			ages: [] as Catalog_Item[],
			levels: [] as Grade[]
		},
		validationSchema,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: () => handleSongUpdate()
	})

	const handleDeleteTrack = async () => {
		if (trackToDelete) {
			try {
				await deleteTrackMutation({
					variables: {
						trackId: trackToDelete
					},
					update: (cache, data) => {
						const id = data.data?.delete_track_by_pk?.track_id
						cache.evict({ id: cache.identify({ id, __typename: 'track' }) })
						cache.gc()
					}
				})
				setTrackWasDeleted(true)
				setTrackToDelete(undefined)
			} catch {
				setDeleteTrackHasError(true)
			}
		}
	}

	const handleSongUpdate = async () => {
		const song = songData?.song_by_pk
		const f = formik.values
		const song_catalog_item = [
			...f.themes.map((catalogItem) => ({
				catalog_item_id: catalogItem.catalog_item_id,
				song_id: songId
			})),
			...f.genres.map((catalogItem) => ({
				catalog_item_id: catalogItem.catalog_item_id,
				song_id: songId
			})),
			...f.ages.map((catalogItem) => ({
				catalog_item_id: catalogItem.catalog_item_id,
				song_id: songId
			}))
		]

		const levels = [...f.levels?.map((level) => ({ grade_id: level.grade_id, song_id: songId }))]

		const songValues = {
			title: f.title,
			image_path: f.image_path,
			key: f.key,
			featured: f.featured,
			active: f.active,
			scale_id: f.scale_id,
			artist_id: f.artist_id
		}

		if (song) {
			try {
				await updateSongMutation({
					variables: {
						songId,
						values: {
							...songValues
						},
						song_catalog_item,
						levels
					},
					update: (cache, { data }) => {
						const id = data?.update_song_by_pk?.song_id
						cache.evict({ id: cache.identify({ id, __typename: 'song' }) })
						cache.gc()
					}
				})
			} catch {
				setShouldShowUpdateSongError(true)
			}
		}
	}

	const handleCancel = () => {
		history.push(-1)
	}

	const goToSongs = () => {
		history.push(buildRouteParameters(Pages.MusicCatalog))
	}

	useEffect(() => {
		const song = songData?.song_by_pk

		if (song) {
			const { title, key, featured, active, scale, artist, image_path, themes, ages, genres, levels } = song

			const extractedLevels = extractLevels({
				selected: levels.map((subject) => subject.grade_id),
				options: gradesCatalog?.data?.grade
			})

			formik.setValues({
				title,
				image_path,
				key: key ?? 'Am',
				featured,
				active,
				scale_id: scale?.scale_id ?? 1,
				artist_id: artist.artist_id,
				ages: ages.map((subject) => subject.catalog_item) as Catalog_Item[],
				themes: themes.map((subject) => subject.catalog_item) as Catalog_Item[],
				genres: genres.map((subject) => subject.catalog_item) as Catalog_Item[],
				levels: extractedLevels
			})
		}
	}, [songData])

	const deleteSong = async () => {
		try {
			await deleteSongMutation({
				variables: {
					songId
				}
			})
		} catch {
			setShouldShowDeleteSongError(true)
		}
	}

	const goToUploadTrack = () => {
		history.push(
			buildRouteParameters(Pages.SongUploadTrack, {
				songId: songData?.song_by_pk?.song_id as number
			})
		)
	}

	const clearTrackDeletionStatus = () => {
		setDeleteTrackHasError(false)
		setTrackWasDeleted(false)
	}

	return {
		formik,
		artists: artistsData?.artist ?? [],
		artistsLoading,

		scales: scalesData?.scale ?? [],
		scalesLoading,

		updateData,
		updateError,
		updateLoading,

		handleCancel,

		songData,
		songLoading,
		songError,

		deleteSong,
		deleteData,
		deleteLoading,
		deleteError,

		showDeleteDialog,
		toggleShowDeleteDialog,

		goToSongs,
		goToUploadTrack,

		trackToDelete,
		setTrackToDelete,
		handleDeleteTrack,
		deleteTrackData,
		deleteTrackLoading,
		deleteTrackError,
		resetDeleteTrack,
		shouldShowDeleteSongError,
		setShouldShowDeleteSongError,
		shouldShowUpdateSongError,
		setShouldShowUpdateSongError,
		deleteTrackHasError,
		setDeleteTrackHasError,
		trackWasDeleted,
		setTrackWasDeleted,
		clearTrackDeletionStatus,
		gradesCatalog
	}
}
