import { Avatar, Box, Typography } from '@material-ui/core'
import { Artist, Song } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils/utils'

import useStyles from '../ListItem.styles'

export type SongForListItem = Pick<Song, 'song_id' | 'title' | 'is_favorite' | 'image_path'> & {
	artist: Pick<Artist, 'name'>
}
type ListSongItemProps = {
	song: SongForListItem
	onClick: (song: SongForListItem) => void
}
export const ListSongItem = ({ song, onClick }: ListSongItemProps) => {
	const styles = useStyles()
	const handleClick = () => onClick(song)

	return (
		<Box className={styles.container} onClick={handleClick}>
			<Avatar variant="rounded" src={buildImagePath(song.image_path)} />
			<Box className={styles.info}>
				<Typography variant="caption">
					<strong>{song.title}</strong>
				</Typography>
				<Typography variant="caption" color="textSecondary">
					<strong>{song.artist.name}</strong>
				</Typography>
			</Box>
		</Box>
	)
}
