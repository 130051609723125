import { makeStyles } from '@material-ui/core/styles'
export const useQRCodeDialogStyles = makeStyles({
	qrContainer: {
		'& section': {
			height: '345px',
			width: '605px',
			'& section': {
				height: '345px',
				width: '605px',
				borderRadius: '7px',
				paddingTop: '0px !important',
				'& div': {
					borderStyle: 'none !important',
					boxShadow: 'none !important',
					height: '345px !important',
					width: '605px !important'
				},
				'& video': {
					height: '345px !important',
					width: '605px !important'
				}
			}
		},
		'& div.MuiDialogContent-root': {
			display: 'flex',
			justifyContent: 'center',
			padding: 0
		},
		'& div.MuiDialogContent-root section': {
			width: '100%'
		}
	}
})
