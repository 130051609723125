import { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { Song } from 'src/graphql/autogenerate/schemas'
import { SongCard } from 'src/scenes/Teacher/scenes/5-Assignments/scenes/AddAssignment/components/SongCard/SongCard'
import { BLANK_SONG_TITLE, getAudioUrl } from 'src/utils'

import { TrackTypeEnum } from '../../../../../../../../../../utils/enums'
import { MiniSongPlayer } from '../MiniSongPlayer/MiniSongPlayer'
import useStyles from './SongItem.styles'

type SongItemProps = {
	song: Song
	setSongPlaying?: React.Dispatch<React.SetStateAction<number>>
	songPlaying?: number
}
export const SongItem = ({ song, songPlaying, setSongPlaying }: SongItemProps) => {
	const [audioUrl, setAudioUrl] = useState('')
	const [trackId, setTrackId] = useState(0)
	const isBlankSong = song.title.toLowerCase() === BLANK_SONG_TITLE

	const styles = useStyles()

	useEffect(() => {
		const trackTypeToUse = TrackTypeEnum.Band

		setAudioUrl(getAudioUrl(0, 0, trackTypeToUse, song))
		const track = song.tracks.find((track) => track.track_type.name === trackTypeToUse)
		if (track) setTrackId(track.track_id)

		const anyTrack = song.tracks[0]
		if (anyTrack) setTrackId(anyTrack.track_id)
	}, [song])

	return (
		<Box className={styles.container}>
			<Box className={styles.songContainer}>
				<SongCard
					imgSource={song.image_path}
					songTitle={song.title}
					artistName={song.artist.name}
					catalogs={song.song_catalog_item.map((item) => item.catalog_item.name)}
				/>
			</Box>
			{!isBlankSong && (
				<MiniSongPlayer
					audioSrc={audioUrl}
					songId={song.song_id}
					trackId={trackId}
					songPlaying={songPlaying}
					setSongPlaying={setSongPlaying}
				/>
			)}
		</Box>
	)
}
