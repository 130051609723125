import { useEffect, useState } from 'react'

import { Box, Checkbox, FormControlLabel, Link, Typography } from '@material-ui/core'

import { InputFile } from '../../../../../components/InputFile'
import { FileTypeEnum } from '../../../../../utils'

type UploadFileType = {
	handleFileUrl: (data: { url: string; terms: boolean }) => void
}
export const UploadFile = ({ handleFileUrl }: UploadFileType) => {
	const [url, setUrl] = useState<string>()
	const [terms, setTerms] = useState<boolean>(false)

	useEffect(() => {
		if (!!url) {
			handleFileUrl({ url, terms })
		}
	}, [terms, url])

	return (
		<Box marginTop="20px">
			<Box>
				<Box position="relative" display="flex" justifyContent="space-between">
					<Typography variant="subtitle1">
						<b>Upload Your Video File*</b>
					</Typography>
					<FormControlLabel
						control={
							<Checkbox
								checked={terms}
								onClick={() => setTerms(!terms)}
								color="secondary"
								name="Terms"
							/>
						}
						label={
							<Typography>
								This Video Follows WURRLY´s{' '}
								<Link color="secondary" target="_blank" href="#">
									Terms of Use
								</Link>
							</Typography>
						} // TODO: add link to Term of Use
					/>
				</Box>
				<InputFile
					handleFileUrl={(key) => setUrl(key)}
					type={FileTypeEnum.Video}
					placeholder="Pick a video file"
					advise={'File Type: mp4/mov. Max File size: 200MB. Max time: 10 minutes'}
					isTemporal={false}
				/>
			</Box>
		</Box>
	)
}
