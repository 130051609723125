import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Select } from 'src/components'
import { AssignmentSortOrder } from 'src/utils'

import useStyles from './AssignmentSort.styles'
type AssignmentSortProps = {
	value: AssignmentSortOrder
	onChange?: (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => void
}

export const AssignmentSort = ({ value, onChange }: AssignmentSortProps) => {
	const styles = useStyles()

	return (
		<Box className={styles.holder}>
			<Typography className={styles.label}>Sort By</Typography>
			<Select
				options={[
					{ name: 'A-Z', value: AssignmentSortOrder.AlphAsc },
					{ name: 'Z-A', value: AssignmentSortOrder.AlphDesc },
					{ name: 'Due Date ↑', value: AssignmentSortOrder.DateAsc },
					{ name: 'Due Date ↓', value: AssignmentSortOrder.DateDesc }
				]}
				onChange={onChange}
				value={value}
			/>
		</Box>
	)
}
