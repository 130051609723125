import { GetStudentQuestionaryQuestion } from '../../../../../../../../graphql/autogenerate/schemas'
import { StudentVerificationDialog } from './components/StudentVerificationDialog'
import { VerificationDialogProvider } from './useVerificationDialog'

type VerificationDialogProps = {
	question: string
	options: GetStudentQuestionaryQuestion[]
}

export const VerificationDialog = ({ question, options }: VerificationDialogProps) => {
	return (
		<VerificationDialogProvider>
			<StudentVerificationDialog question={question} options={options} />
		</VerificationDialogProvider>
	)
}
