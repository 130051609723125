import { createStyles, makeStyles } from '@material-ui/core'

export const useSelectedSongsStyles = makeStyles((theme) =>
	createStyles({
		container: { marginTop: 80 },
		title: { fontSize: 18 },
		songsContainer: {
			marginTop: theme.spacing(3)
		},
		songCard: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			'&:not(:last-child)': {
				marginBottom: theme.spacing(2)
			}
		}
	})
)

export default useSelectedSongsStyles
