import { useEffect, useState } from 'react'

import { Avatar, Box, Typography } from '@material-ui/core'
import { Delete, EqualizerOutlined } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router'
import { PagesProps } from 'src/@types'
import { ReInviteIcon, ThinCheckIcon } from 'src/assets/icons'
import { ActionButton, BigBaseButton, InfoDialog, MainTitle, StudentInvitationDetailsDialog } from 'src/components'
import {
	useGetClassTeacherByPkLazyQuery,
	useGetDownloadStudentQRsPdfLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Integration_Name_Enum, Student, StudentFromCsv } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { COLORS } from 'src/styles/colors'
import { BASE_URL, buildBreadCrumbs, buildImagePath, buildRouteParameters, getCyKey, slugToName } from 'src/utils'

import { AddStudentToClass } from '../../../components/AddStudentToClass'
import { DeleteStudentDialog } from '../../../components/DeleteStudentDialog'
import { ReInviteStudentDialog } from '../../../components/ReInviteStudentDialog'
import { useStudentDetailContext } from '../useStudentDetail'

export const Title = ({ page }: PagesProps) => {
	const history = useHistory()
	const {
		teacherId,
		classId,
		student,
		studentLoading,
		integrationName,
		hasAssignmentsOrActivities,
		refetchStudent
	} = useStudentDetailContext()
	const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false)
	const [isQRCodeDownloadedDialogOpen, setIsQRCodeDownloadedDialogOpen] = useState(false)
	const [studentDelete, setStudentDelete] = useState<Student | undefined>()
	const [addToClassStudent, setAddToClassStudent] = useState<Student | undefined>()
	const [studentReInvite, setStudentReInvite] = useState<Student | undefined>()
	const [downloadQRCodeURL, setDownloadQRCodeURL] = useState('')
	const [getCalssData, { data: classData }] = useGetClassTeacherByPkLazyQuery()
	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: classId
			? [
					{
						index: 1,
						overrideName: slugToName(classData?.teacher_by_pk?.class_teachers[0]?.class?.title || '')
					},
					{
						index: 2,
						overrideName: 'Students'
					},
					{
						index: 3,
						overrideName: slugToName(`${student?.first_name ?? ''} ${student?.last_name ?? ''}`)
					}
			  ]
			: [
					{
						index: 1,
						overrideName: slugToName(`${student?.first_name ?? ''} ${student?.last_name ?? ''}`)
					}
			  ],
		params: {
			classId
		}
	})

	useEffect(() => {
		if (classId)
			getCalssData({
				variables: { teacherId, classId }
			})
	}, [classId])

	const [getStudentQRsPdfLazyQuery, { data: studentsQRsPDFData }] = useGetDownloadStudentQRsPdfLazyQuery()

	useEffect(() => {
		if (studentsQRsPDFData && studentsQRsPDFData.downloadQRCode && studentsQRsPDFData.downloadQRCode.path) {
			const qrCodeURL = `${BASE_URL}${studentsQRsPDFData.downloadQRCode.path}`
			setDownloadQRCodeURL(qrCodeURL)
		}
	}, [studentsQRsPDFData])

	useEffect(() => {
		if (student) {
			handleStudentQRsPDFDownload(student.first_name, student.last_name, student.code || '')
		}
	}, [student])

	useEffect(() => {
		if (isQRCodeDownloadedDialogOpen) {
			setIsDetailsOpen(false)
		}
	}, [isQRCodeDownloadedDialogOpen])

	const handleStudentQRsPDFDownload = (firstName: string, lastName: string, code: string) => {
		const students = [{ firstName, lastName, code } as StudentFromCsv]
		getStudentQRsPdfLazyQuery({
			variables: {
				filter: {
					students
				}
			}
		})
	}

	const goToStudentAnalytics = () => {
		history.push(
			buildRouteParameters(
				Pages.StudentAnalytics, // Route with paramters
				{ studentId: student.student_id }
			)
		)
	}

	return (
		<Box>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title={
					<Box display="flex">
						{!studentLoading && (
							<Avatar
								style={{
									width: '80px',
									height: '80px',
									marginRight: '25px',
									opacity: student?.is_active ? 1 : 0.5
								}}
								src={buildImagePath(student?.image_path ?? '')}
							/>
						)}
						{!studentLoading && (
							<Box style={{ opacity: student?.is_active ? 1 : 0.5 }}>
								<Typography data-cy={getCyKey(Title, 'studentDetailNameTitle')} variant="h3">{`${
									student?.first_name ?? ''
								} ${student?.last_name ?? ''}`}</Typography>
								<Typography
									data-cy={getCyKey(Title, 'StudentDetails')}
									color="textSecondary"
									style={{ cursor: 'pointer' }}
									onClick={() => setIsDetailsOpen(true)}>
									Details
								</Typography>
							</Box>
						)}
					</Box>
				}
				actionButtons={
					!student?.is_active
						? [
								<ActionButton
									key={0}
									data-cy={getCyKey(Title, 'btnStudentReInvite')}
									onClick={() => {
										setStudentReInvite(student)
									}}
									size="large"
									icon={<ReInviteIcon />}
								/>,
								<ActionButton
									key={1}
									data-cy={getCyKey(Title, 'btnStudentDelete')}
									onClick={() => {
										setStudentDelete(student)
									}}
									style={{
										backgroundColor: COLORS.error.main,
										color: 'white'
									}}
									size="large"
									icon={<Delete />}
								/>
						  ]
						: [
								<ActionButton
									key={0}
									onClick={() => {
										setStudentDelete(student)
									}}
									style={{
										backgroundColor: COLORS.error.main,
										color: 'white'
									}}
									size="large"
									icon={<Delete />}
								/>,

								<BigBaseButton
									key={1}
									data-cy={getCyKey(Title, 'btnAnalytics')}
									style={{
										backgroundColor: !hasAssignmentsOrActivities ? '' : 'white',
										color: COLORS.yellow,
										padding: '0px 30px'
									}}
									startIcon={<EqualizerOutlined />}
									onClick={goToStudentAnalytics}
									disabled={!hasAssignmentsOrActivities}>
									<b>Analytics</b>
								</BigBaseButton>
						  ]
				}
				rightActions={[
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <AddIcon />,
						name: 'Add to Class',
						onClick: () => {
							setAddToClassStudent(student)
						}
					}
				]}
			/>

			<ReInviteStudentDialog studentReinvite={studentReInvite} setStudentReinvite={setStudentReInvite} />
			<DeleteStudentDialog
				studentDelete={studentDelete}
				setStudentDelete={setStudentDelete}
				onDone={() => {
					history.push(Pages.Students.path)
				}}
			/>
			<AddStudentToClass
				addToClassStudent={addToClassStudent}
				setAddToClassStudent={setAddToClassStudent}
				refetchStudent={refetchStudent}
			/>
			<StudentInvitationDetailsDialog
				classId={classId}
				title="Details"
				studentName={`${student?.first_name} ${student?.last_name}`}
				studentCode={student?.code || ''}
				emailHeader={'Email'}
				studentEmail={student?.email || ''}
				isSendEmail={false}
				onClose={() => setIsDetailsOpen(false)}
				onConfirm={() => setIsDetailsOpen(false)}
				setIsQRCodeDownloadedDialogOpen={setIsQRCodeDownloadedDialogOpen}
				open={isDetailsOpen}
				confirmLabel="Got It!"
				onConfirmProps={{ style: { width: '100%' }, autoFocus: false }}
				downloadQRCodeURL={downloadQRCodeURL}
				paperProps={{ style: { width: 570 } }}
				integrationName={integrationName as Integration_Name_Enum}
			/>
			<InfoDialog
				open={isQRCodeDownloadedDialogOpen}
				onClose={() => {}}
				onDiscard={() => {
					setIsQRCodeDownloadedDialogOpen(false)
				}}
				onConfirm={() => {
					setIsQRCodeDownloadedDialogOpen(false)
					setIsDetailsOpen(true)
				}}
				icon={<ThinCheckIcon />}
				title="QR Codes Downloaded!"
				body="You have successfully downloades these Students QR."
				confirmLabel="Got it!"
			/>
		</Box>
	)
}
