import { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { useGetFeaturedSongsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Pages, SlugEnum } from 'src/routes/teacherPages'
import { buildRouteParameters, buildSearchText } from 'src/utils'

export const useMusicCatalog = ({ page }: Partial<PagesProps>) => {
	const history = useHistory()

	const [getFeatured, getFeaturedData] = useGetFeaturedSongsLazyQuery()

	const [getSearchFeatured, getSearchFeatureData] = useGetFeaturedSongsLazyQuery()

	const onSearch = (searchbarText: string) => {
		getSearchFeatured({
			variables: {
				conditions: buildSearchText(searchbarText || '', ['title', 'artist.name']),
				offset: 0,
				limit: 5
			}
		})
	}
	const onSearchEnter = (searchbarText: string) => {
		if ((page && page.path === Pages.SearchVideos.path) || !searchbarText) {
			getFeatured({
				variables: {
					conditions: buildSearchText(searchbarText || '', ['title', 'artist.name']),
					offset: 0
				}
			})

			return
		}

		history.push(buildRouteParameters(Pages.SearchSongs), {
			searchbarText
		})
	}

	useEffect(() => {
		getFeatured({
			variables: {
				limit: 5,
				offset: 0
			}
		})
	}, [])

	const LinkToFeatured = (location: Location) => {
		return {
			...location,
			pathname: Pages.RecommendedSongs.path,
			state: {
				catalog: SlugEnum.Recommended
			}
		}
	}

	const handleChord = (chords: string[]) => {
		history.push(buildRouteParameters(Pages.BrowseByChords), {
			chords
		})
	}
	const handleSearch = (search: string) => {
		history.push(buildRouteParameters(Pages.SearchSongs), {
			search
		})
	}

	return {
		handleSearch,
		handleChord,
		LinkToFeatured,
		onSearch,
		onSearchEnter,
		getSearchFeatureData,
		getFeaturedData
	}
}
