import { makeStyles, Theme } from '@material-ui/core/styles'

export const useIconActionCardStyles = makeStyles<Theme, { icon: JSX.Element | undefined }>((theme) => ({
	buttonOption: {
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'column'
		},
		'& .MuiButton-startIcon': {
			margin: 0,
			height: ({ icon }) => (icon ? 64 : undefined),
			alignItems: ({ icon }) => (icon ? 'center' : undefined)
		},
		'& .MuiButton-iconSizeLarge > *:first-child': {
			fontSize: 50
		},
		'&:hover': {
			background: theme.palette.secondary.main
		}
	}
}))

export default useIconActionCardStyles
