import { useEffect, useState } from 'react'

import { cache } from 'src/apollo/state'
import { ProcessingStatusForPlayer } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import { buildGenericContext } from 'src/context/genericContext'
import { useGetWurrliesLazyQuery, useGetWurrlyProcessingStatusSubscription } from 'src/graphql/autogenerate/hooks'
import { Wurrly, Wurrly_Processing_Status_Enum } from 'src/graphql/autogenerate/schemas'
import { useSearchQuery } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { useWurrlyParams } from 'src/hooks/useWurrlyParams'
import { Pages } from 'src/routes/teacherPages'
import { NO_CACHE, shouldSortTitleOrDate, SortOrder } from 'src/utils'

const useWurrliesDetails = () => {
	const { teacherData: teacher } = useLoginContext()
	const params = useWurrlyParams<typeof Pages.WurrliesDetails.params[number]>()
	const classId = Number(params.classId)
	const teacherId = teacher.teacher_id
	const [index, setIndex] = useState<number>()
	const [wurrlyDetails, setWurrlyDetails] = useState<Wurrly>()
	const [hasPrev, setHasPrev] = useState<boolean>()
	const [hasNext, setHasNext] = useState<boolean>()
	const searchQuery = useSearchQuery()

	const [getGetWurrliesQuery, getWurrliesByClassResult] = useGetWurrliesLazyQuery(NO_CACHE)
	const { data: classWurrliesDetailsData, loading: classWurrliesDetailsLoading } = getWurrliesByClassResult

	const wurrlies = (classWurrliesDetailsData?.wurrly as Wurrly[]) || []

	const [processingStatus, setProcessingStatus] = useState<ProcessingStatusForPlayer>({
		status: Wurrly_Processing_Status_Enum.Done,
		isLoading: true
	})
	const { data: processingStatusData, loading: processingStatusLoading } =
		useGetWurrlyProcessingStatusSubscription({
			variables: { wurrlyId: wurrlyDetails?.wurrly_id ?? 0 }
		})

	useEffect(() => {
		setProcessingStatus({
			status: processingStatusData?.wurrly_by_pk?.processing_status ?? Wurrly_Processing_Status_Enum.Done,
			isLoading: processingStatusLoading
		})
		if (processingStatusData?.wurrly_by_pk?.processing_status && !processingStatusLoading && wurrlyDetails) {
			cache.evict({
				id: cache.identify(wurrlyDetails),
				fieldName: 'final_resource_path'
			})
		}
	}, [processingStatusData, processingStatusLoading])

	// set sort by query param
	useEffect(() => {
		const querySort = searchQuery.get('sort') as SortOrder

		if (querySort) {
			const [orderTitle, orderDate] = shouldSortTitleOrDate(querySort)

			// call the query
			getGetWurrliesQuery({
				variables: { classId, teacherId, orderTitle, orderDate }
			})
		}
	}, [])

	// select the wurrly when finish the query
	useEffect(() => {
		wurrlies.forEach((wurrly, index) => {
			if (wurrly.wurrly_id === params.wurrlyId) {
				setWurrlyDetails(wurrly)
				setHasPrev(index > 0)
				setHasNext(index < wurrlies.length - 1)
				setIndex(index)
			}
		})
	}, [wurrlies])

	// update wurrly selected by index
	useEffect(() => {
		if (index !== undefined) {
			setWurrlyDetails(wurrlies[index])
			setHasPrev(index > 0)
			setHasNext(index < wurrlies.length - 1)
		}
	}, [index])

	const previousValue = () => {
		if (index !== undefined && hasPrev) {
			setIndex(index - 1)
		}
	}

	const nextValue = () => {
		if (index !== undefined && hasNext) {
			setIndex(index + 1)
		}
	}

	return {
		classId,
		teacherId,
		teacher,

		wurrlyDetails,
		classWurrliesDetailsLoading,

		hasPrev,
		hasNext,

		nextValue,
		previousValue,
		processingStatus
	}
}

export const [WurrliesDetailsProvider, useWurrliesDetailsContext] = buildGenericContext(useWurrliesDetails)
