import { useEffect, useState } from 'react'

import { useStudentWurrliesGrid } from 'src/scenes/Teacher/scenes/3-Students/scenes/StudentWurrlies/components/StudentWurrliesGrid/hooks'

import { buildGenericContext } from '../../../../context/genericContext'
import { useGetStudentByPkExtendedLazyQuery } from '../../../../graphql/autogenerate/hooks'
import { Student } from '../../../../graphql/autogenerate/schemas'
import { useWurrlyParams } from '../../../../hooks'
import { useLoginContext } from '../../../../hooks/useLogin'
import { Pages } from '../../../../routes/teacherPages'

const useStudentDetail = () => {
	const params = useWurrlyParams<typeof Pages.ClassStudentDetail.params[number]>()
	const { teacherData: teacher, studentData: student } = useLoginContext()
	const teacherId = teacher.teacher_id
	const classId = Number(params.classId)
	const [selectedCard, setSelectedCard] = useState(0)
	const [isErrorOpen, setIsErrorOpen] = useState(false)
	const [classSelectedId, setClassSelectedId] = useState<number>()
	const studentId = student.student_id

	const { setWurrliesVariables, wurrlies, loading } = useStudentWurrliesGrid()
	useEffect(() => {
		setWurrliesVariables({
			studentId,
			classId
		})
	}, [])

	const [getStudentByPkExtended, { data: studentData, loading: studentLoading }] =
		useGetStudentByPkExtendedLazyQuery({
			fetchPolicy: 'no-cache'
		})

	useEffect(() => {
		if (!studentId) return
		getStudentByPkExtended({
			variables: {
				studentId,
				teacherId
			}
		})
	}, [teacherId, studentId])

	return {
		teacherId,
		classId,
		studentId: student.student_id,
		selectedCard,
		setSelectedCard,
		classSelectedId,
		setClassSelectedId,
		student: studentData?.student_by_pk as Student,
		studentLoading,
		wurrlies,
		loading,
		isErrorOpen,
		setIsErrorOpen
	}
}

export const [StudentDetailProvider, useStudentDetailContext] = buildGenericContext(useStudentDetail)
