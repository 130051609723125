import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useGetPlaylistDataByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Playlist } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { Pages } from 'src/routes/teacherPages'
import { ProductTitleEnum, buildRouteParameters, LIMIT } from 'src/utils'
export const usePlaylistsWrapper = () => {
	const history = useHistory()
	const { classId } = useWurrlyParams()
	const [count, setCount] = useState(0)
	const [data, setData] = useState<Playlist[]>([])

	const type = ProductTitleEnum.Playlist

	const handleViewAll = () => {
		history.push(
			buildRouteParameters(Pages.AllClassProducts, {
				classId: Number(classId),
				type: ProductTitleEnum.Playlist
			})
		)
	}

	const [getPlaylist, { data: playlistData, loading }] = useGetPlaylistDataByClassIdLazyQuery()

	useEffect(() => {
		getPlaylist({
			variables: {
				limit: LIMIT,
				classId: Number(classId)
			}
		})
	}, [])
	useEffect(() => {
		if (playlistData) {
			setCount(playlistData.playlist_aggregate.aggregate?.count || 0)
			setData(playlistData.playlist as Playlist[])
		}
	}, [playlistData])

	return {
		loading,
		data,
		count,
		handleViewAll,
		type
	}
}
