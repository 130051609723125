import React, { useEffect, useState, Fragment } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Tab,
	Tabs,
	Typography
} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SearchIcon from '@material-ui/icons/Search'
import { FormikProps } from 'formik'
import { noop } from 'lodash/fp'
import { BaseDialog } from 'src/components'
import { useSearchMySongsLazyQuery, useSearchMyVideosLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Song, Tip } from 'src/graphql/autogenerate/schemas'
import { useScrollElement } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { setTimeoutQuery, TypeEnum, buildQueryVariables, buildSearchText } from 'src/utils'

import useStyles from './AddDialog.styles'
import { ListAddItems } from './ListAddItems'
import { querySearchAddSong, QuerySearchResultType } from './queries'

type AddDialogType = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	type: TypeEnum
	itemsSelected: (Song | Tip)[]
	onSave: (selectedItems: (Song | Tip)[]) => void
	limit: number
	initialSelectedIds?: number[]
	video?: boolean
	setCounter?: (item: number) => void
	formik?: FormikProps<Record<string, string>>
}

const searchText = {
	song: {
		placeholder: 'Find songs by Artist or Song Title',
		suggestText: ' - Search for Song'
	},
	tip: {
		placeholder: 'Find Video by Title, Subject or Keyword',
		suggestText: ' - Search for Videos'
	},
	lesson_plan: {
		placeholder: 'Find Lessons by Title, Song, Standard, or Keyword',
		suggestText: ' - Search for Lessons'
	}
}
export const AddDialog = ({
	open,
	setOpen,
	type,
	onSave,
	itemsSelected,
	limit,
	initialSelectedIds,
	video = false,
	setCounter,
	formik
}: AddDialogType) => {
	const { teacherData } = useLoginContext()
	const [items, setItems] = useState<({ selected: boolean } & (Song | Tip))[]>([])
	const [count, setCount] = useState<number>()
	const [selectedItems, setSelectedItems] = useState<(Song | Tip)[]>([])
	const [search, setSearch] = useState<string>()
	const [focus, setFocus] = useState(false)
	const [tab, setTab] = useState(0)
	const [hasUsedInitialData, setHasUsedInitialData] = useState(false)
	const [timer, setTimer] = useState(0)

	const queryVariables = {
		teacherId: teacherData.teacher_id,
		conditions: buildSearchText(search || '', ['title', 'artist.name']),
		offset: 0,
		limit: 15
	}

	const itemQueryVariables = buildQueryVariables({
		conditions: buildSearchText(search || '', ['title', 'artist.name']),
		offset: 0,
		limit: 15
	})

	const [loadItemQuery, itemQuery] = useLazyQuery<
		QuerySearchResultType & {
			total: {
				aggregate: {
					count: number
				}
			}
		}
	>(querySearchAddSong(type))
	const [loadMyVideos, myVideos] = useSearchMyVideosLazyQuery()
	const [loadMySongs, mySongs] = useSearchMySongsLazyQuery()

	const styles = useStyles(focus)
	const typeId = `${type}_id` as keyof typeof selectedItems[0]

	const setMyVideos = () => {
		if (!myVideos?.data?.tip) return
		const videos = myVideos.data.tip as Tip[]
		setCount(myVideos.data.tip.length)
		setItems(
			videos.map((item) => ({
				...item,
				selected: selectedItems.some((it) => it[typeId] === item[typeId])
			})) || []
		)
	}
	const setMySongs = () => {
		if (mySongs.data) {
			const songs = mySongs.data.song as Song[]
			setCount(mySongs.data.song.length)
			setItems(
				songs.map((item) => ({
					...item,
					selected: selectedItems.some((it) => it[typeId] === item[typeId])
				})) || []
			)
		}
	}
	const setItemQuery = () => {
		if (!itemQuery.loading && itemQuery.data) {
			setCount(itemQuery.data.total.aggregate.count)
			setItems(
				itemQuery.data[type as keyof QuerySearchResultType].map((item) => ({
					...item,
					selected: selectedItems.some((it) => it[typeId] === item[typeId])
				})) || []
			)
		}
	}

	useEffect(() => {
		setSelectedItems(itemsSelected)
		setItemQuery()
		loadItemQuery(itemQueryVariables)
	}, [])

	useEffect(() => {
		if (tab === 0) {
			setTimeoutQuery(
				() => {
					loadItemQuery(itemQueryVariables)
				},
				setTimer,
				timer
			)
		} else {
			if (type === TypeEnum.Song) {
				setTimeoutQuery(
					() => {
						loadMySongs({ variables: queryVariables })
					},
					setTimer,
					timer
				)
			} else {
				setTimeoutQuery(
					() => {
						loadMyVideos({ variables: queryVariables })
					},
					setTimer,
					timer
				)
			}
		}
	}, [search, tab])

	useEffect(() => {
		if (tab === 0 && open) {
			return setItemQuery()
		}
		if (type === TypeEnum.Song) {
			setMySongs()
		} else {
			setMyVideos()
		}
	}, [myVideos.data, mySongs.data, itemQuery.data, tab])

	useEffect(() => {
		if (items.length > 0) {
			setItems(
				items.map((item) => {
					if (selectedItems.some((it) => it[typeId] === item[typeId])) {
						return { ...item, selected: true }
					} else {
						return { ...item, selected: false }
					}
				})
			)
			setCounter?.(selectedItems.length)
		}
	}, [selectedItems])

	useEffect(() => {
		if (initialSelectedIds && items.length && !hasUsedInitialData) {
			setItems(
				(prevItems) =>
					prevItems.map((item) => ({
						...item,
						selected: selectedItems.some((it) => it[typeId] === item[typeId])
					})) || []
			)
			setHasUsedInitialData(true)
		}
	}, [initialSelectedIds, items])

	const handleFocus = () => setFocus(true)

	const handleBlur = () => setFocus(false)

	const handleChangeTab = (_: React.ChangeEvent<unknown>, newValue: number) => {
		setTab(newValue)
	}

	const onSelect = (
		item: {
			selected: boolean
		} & (Song | Tip)
	) => {
		const array = [...selectedItems]
		const index = array.findIndex((it) => it[typeId] === item[typeId])
		if (index !== -1) {
			array.splice(index, 1)
		} else if (selectedItems.length < limit) {
			array.push(item)
		}
		setSelectedItems(array)
	}
	const [disableText, setDisableText] = useState('')
	useEffect(() => {
		if (selectedItems.length >= limit) {
			if (type === TypeEnum.Song) setDisableText('Songs limit has been reached')
			else if (type === TypeEnum.Video) setDisableText('Videos limit has been reached')
		} else {
			setDisableText('')
		}
	}, [selectedItems])

	const scrollRef = React.useRef<HTMLDivElement>(null)
	const [isFetchingMore, setIsFetchingMore] = useState(false)

	const { y, direction } = useScrollElement({ wait: 500, element: scrollRef })

	useEffect(() => {
		const scrollHeight = scrollRef?.current?.scrollHeight || 0
		const clientHeight = scrollRef?.current?.clientHeight || 190

		const fetchMore = async () => {
			if (!itemQuery.fetchMore) {
				return
			}
			const fetchMoreResult = await itemQuery.fetchMore({
				variables: { offset: items?.length }
			})
			let newItems: Song[] | Tip[] = []

			if (type === TypeEnum.Song) {
				newItems = fetchMoreResult.data.song as Song[]
			} else {
				newItems = fetchMoreResult.data.tip as Tip[]
			}

			setItems((oldItems) => [
				...oldItems,
				...newItems.map((item) => ({
					...item,
					selected: selectedItems.some((it) => it[typeId] === item[typeId])
				}))
			])

			setIsFetchingMore(false)
		}

		if (y && y > 0 && direction === 'down' && !isFetchingMore) {
			// fetch more data
			if (y >= scrollHeight - clientHeight * 3) {
				setIsFetchingMore(true)
				fetchMore()
			}
		}
	}, [y, direction])

	return (
		<BaseDialog
			onClose={noop}
			dividers={false}
			open={open}
			header={
				<Fragment>
					<Typography variant="h5" align="center" className={styles.title}>
						<b>Add {type === TypeEnum.Song ? 'Songs' : 'Videos'}</b>
					</Typography>
					<Paper elevation={0} className={focus ? styles.boxInputFocus : styles.boxInput}>
						<IconButton aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							value={search ?? ''}
							onChange={(e) => setSearch(e.target.value)}
							onFocus={handleFocus}
							onBlur={handleBlur}
							placeholder={searchText[type as keyof typeof searchText].placeholder}
							inputProps={{ 'aria-label': 'search song' }}
						/>
						{!!search && (
							<Button
								className={styles.btnSearch}
								variant="text"
								onClick={() => setSearch(undefined)}>
								<HighlightOffIcon />
							</Button>
						)}
					</Paper>
					<Tabs
						className={styles.tabs}
						value={tab}
						onChange={handleChangeTab}
						aria-label="simple tabs example">
						<Tab
							label={
								<Typography variant="caption" color={tab === 0 ? 'secondary' : undefined}>
									<b>{video ? 'VIDEO' : 'MUSIC'} CATALOG</b>
								</Typography>
							}
						/>
						<Tab
							label={
								<Typography variant="caption" color={tab === 1 ? 'secondary' : undefined}>
									<b>MY FILES</b>
								</Typography>
							}
						/>
					</Tabs>
					<Typography variant="subtitle1">
						<b>{`${count} ${type === TypeEnum.Song ? 'Songs' : 'Videos'}`}</b>
					</Typography>
				</Fragment>
			}
			bodyProps={{ style: { minWidth: 500 } }}
			body={
				!itemQuery.loading && !mySongs.loading && !myVideos.loading ? (
					<Box className={styles.bodyBox}>
						<Grid item xs className={styles.bodyGrid} ref={scrollRef}>
							<ListAddItems
								disableText={disableText}
								spacing={1.5}
								data={items}
								type={type}
								onSelect={onSelect}
							/>
							{items.length <= 0 && (
								<Box style={{ opacity: 0.5 }}>
									<Typography>No results found</Typography>
								</Box>
							)}
							{isFetchingMore && (
								<Box className={styles.boxProgress}>
									<CircularProgress color="secondary" size={40} />
								</Box>
							)}
						</Grid>
					</Box>
				) : (
					<Box className={styles.boxProgress}>
						<CircularProgress color="secondary" size={40} />
					</Box>
				)
			}
			onConfirm={() => {
				onSave([...selectedItems])
				setOpen(false)
				setTab(0)
				setSelectedItems([])
			}}
			confirmLabel={`Add ${type === TypeEnum.Song ? 'Songs' : 'Videos'}`}
			onDiscard={() => {
				setOpen(false)
				setTab(0)
				setSelectedItems([])
				if (TypeEnum.Song === type) {
					setCounter?.(formik?.values?.songs?.length || 0)
				}
				if (TypeEnum.Video === type) {
					setCounter?.(formik?.values?.videos?.length || 0)
				}
			}}
			discardLabel="Cancel"
		/>
	)
}
