import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import clsx from 'clsx'

import useDatetimeStyles from './Datetime.styles'

export type DatetimeProps = {
	className?: string
	color: 'primary' | 'secondary'
	text: string
}

export const Datetime = ({ className, color, text }: DatetimeProps) => {
	const styles = useDatetimeStyles({ color })

	return (
		<div className={clsx(styles.date, className)}>
			<CalendarTodayIcon fontSize="small" />
			{text}
		</div>
	)
}
