import { Box, CircularProgress, Typography } from '@material-ui/core'
import { ListSongs } from 'src/components'
import { MyFilesAccordion } from 'src/components/MyFilesAccordion'

import useStyles from './SongUploads.styles'
import { useSongUploads } from './useSongUploads'

export const SongUploads = () => {
	const styles = useStyles()
	const { totalSongs, songs, sort, handleSort, seeMoreEnabled, seeMore, loading, teacherId } = useSongUploads()

	return (
		<MyFilesAccordion
			title="Song Uploads"
			count={totalSongs}
			details={
				<>
					{!loading && (
						<ListSongs
							teacherId={teacherId}
							spacing={1.5}
							data={songs}
							allTranspositions={false}
							removeButton
							editButton
						/>
					)}
					{loading && (
						<Box className={styles.boxLoading}>
							<CircularProgress color="secondary" size={30} />
						</Box>
					)}
					{seeMoreEnabled && (
						<Typography color="textSecondary" className={styles.seeMore} onClick={seeMore}>
							<b>See More</b>
						</Typography>
					)}
				</>
			}
			sort={sort}
			handleSort={handleSort}
		/>
	)
}
