import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import { OperationDefinitionNode } from 'graphql'

import { environment } from '../environment'
import { StorageEnum, validToken } from '../utils'
import { cache } from './state'

const httpUri = environment.hasuraEndpoint as string
const wsUri = httpUri.replace(/^https?/, 'wss')

// get the authentication token from local storage if it exists

const getAccessToken = () => {
	let accessToken: { Authorization: string } | null = null

	try {
		const token = JSON.parse(localStorage.getItem(StorageEnum.loginToken) || '')
		const decodedToken = validToken(token)
		if (token && decodedToken) accessToken = { Authorization: `Bearer ${token}` }

		return accessToken
	} catch (error) {
		console.error(error)

		return null
	}
}

const authLink = setContext((_, { headers }) => {
	// return the headers to the context so httpLink can read them
	const accessToken = getAccessToken()

	return {
		headers: {
			...headers,
			...(accessToken ? accessToken : {})
		}
	}
})

const httpLink = new HttpLink({
	uri: httpUri,
	fetch: async (uri: string, options: Record<string, string>) => {
		const { operationName } = JSON.parse(options.body)
		const transaction = Sentry.startTransaction({ name: operationName })
		const response = await fetch(`${uri}?opname=${operationName}`, options)
		transaction.setHttpStatus(response.status)
		transaction.finish()

		return response
	}
})

const link = ApolloLink.from([
	onError(({ graphQLErrors, networkError }) => {
		console.log({ graphQLErrors, networkError })
		if (graphQLErrors)
			graphQLErrors.map(({ message, locations, path, extensions }) => {
				/* eslint no-console: 0 */
				if (
					extensions?.code === 'invalid-jwt' ||
					message === 'Malformed Authorization header' ||
					message === 'Failed reading: not a valid json value' ||
					message === 'Could not verify JWT: JWTExpired'
				) {
					// client.setLink(getLink())
					console.error('Expired token')
				}
				console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
			})
		if (networkError) {
			/* eslint no-console: 0 */
			console.log(`[Network error]: ${networkError}`)
		}
	}),
	httpLink
])

const wsLink = new WebSocketLink({
	uri: wsUri,
	options: {
		reconnect: true,
		connectionParams: () => {
			const accessToken = getAccessToken()

			return {
				headers: { ...(accessToken ? accessToken : {}) }
			}
		}
	}
})

const terminatingLink = split(
	({ query }) => {
		const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode

		return kind === 'OperationDefinition' && operation === 'subscription'
	},
	wsLink,
	link
)

export const client = new ApolloClient({
	link: authLink.concat(terminatingLink),
	cache,
	connectToDevTools: !environment.isProduction
})
