import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentDetailsContentStyles = makeStyles(() =>
	createStyles({
		classSelect: { width: '50%', marginRight: '30px', height: 50 },
		dateSelect: { width: '50%', height: 50 },
		divider: { margin: '40px 0 0 0' },
		tiles: { marginTop: 20 },
		loaderHolder: { display: 'flex', alignItems: 'center', justifyContent: 'space-around' },
		container: { display: 'flex', flexDirection: 'row', alignItems: 'center' }
	})
)

export default useAssignmentDetailsContentStyles
