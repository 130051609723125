import { ChangeEvent, useEffect, useState } from 'react'

import { Box, Divider, MenuItem, Select, Typography } from '@material-ui/core'
import { DownloadIcon } from 'src/assets/icons'
import { BaseLink, TextInput } from 'src/components'
import { EmptyCard } from 'src/components/Cards/EmptyCard/EmptyCard'
import { MessageBox } from 'src/components/MessageBox/MessageBox'
import { WurrlyPlayer, WurrlyToPlay } from 'src/components/WurrlyPlayer/WurrlyPlayer'
import { Asset, Wurrly_Processing_Status_Enum } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'

import { getCyKey, capitalize } from '../../../../../../../../../../utils/utils'
import { useGradingContext } from '../../useGrading'
import { StudentsFilter } from '../StudentsFilter/StudentsFilter'
import useStyles from './GradingCard.styles'

type GradingCardProps = {
	gradingValues: string[] | number[]
	files?: Pick<Asset, 'asset_id' | 'name' | 'resource_path'>[]
	index: number
}
export const GradingCard = ({ gradingValues, files, index }: GradingCardProps) => {
	const styles = useStyles()
	const {
		updateSubmission,
		submissions,
		sortedSubmissions,
		sortOrder,
		setSortOrder,
		goToSelectedSubmission,
		assignmentDueDateString,
		setGradingValues,
		gradingSystem
	} = useGradingContext()
	const [urlFile, setUrlFile] = useState<Pick<Asset, 'asset_id' | 'name' | 'resource_path'>[]>()

	const currSubmission = submissions[index]

	const grade = currSubmission?.grade?.toLocaleLowerCase()
	const comment = currSubmission?.teacher_comment
	const wurrly = currSubmission?.submission_wurrlies[0]?.wurrly
	const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement>) =>
		updateSubmission(index, 'teacher_comment', e.target.value)

	const handleChangeGrade = (
		event: ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		updateSubmission(index, 'grade', event.target.value as string)
	}

	useEffect(() => {
		setGradingValues(gradingValues)
	}, [gradingValues])

	useEffect(() => {
		setUrlFile(files?.map((file) => ({ ...file, resource_path: buildImagePath(file.resource_path) })) || [])
	}, [files])

	const cond = grade
		? 'Add a comment'
		: gradingSystem?.label === 'None'
		? 'Add a comment'
		: 'You must grade the wurrly before adding a comment'

	return (
		<EmptyCard className={styles.emptyCard}>
			{!!sortedSubmissions.length && (
				<StudentsFilter
					currentSubmission={submissions[index]}
					submissions={sortedSubmissions}
					sortOrder={sortOrder}
					setSortOrder={setSortOrder}
					onClick={goToSelectedSubmission}
					assignmentDueDateString={assignmentDueDateString}
					gradingSystem={gradingSystem}
				/>
			)}
			{!!wurrly && (
				<>
					<WurrlyPlayer
						wurrly={wurrly as unknown as WurrlyToPlay}
						processingStatus={{
							isLoading: wurrly.processing_status === Wurrly_Processing_Status_Enum.InProgress,
							status: wurrly.processing_status || Wurrly_Processing_Status_Enum.Done
						}}
					/>
					<MessageBox text={wurrly?.message || ''} />
				</>
			)}
			<Divider />
			{!!urlFile?.length && (
				<Box className={styles.inputSection}>
					<Typography className={styles.label}>Student resources</Typography>
					{urlFile?.map((file) => (
						<Box key={file.asset_id}>
							<BaseLink className={styles.download} href={file.resource_path} target="_blank">
								<img alt="DownloadIcon" src={DownloadIcon} />
								Download - {file.name}
							</BaseLink>
						</Box>
					))}
				</Box>
			)}
			{!!gradingValues?.length && (
				<Box className={styles.inputSection}>
					<Typography className={styles.label}>Grade</Typography>
					<Select
						data-cy={getCyKey(GradingCard, 'gradeDropdown')}
						className={styles.select}
						defaultValue=""
						value={grade || ''}
						onChange={handleChangeGrade}
						variant="outlined"
						color="secondary">
						 
						{gradingValues?.map((value) => (
							<MenuItem className={styles.menuItem} key={value} value={value}>
								{capitalize(value as string)}
							</MenuItem>
						))}
					</Select>
				</Box>
			)}
			<Box className={styles.inputSection}>
				<Typography className={styles.label}>Comment</Typography>
				<TextInput
					data-cy={getCyKey(GradingCard, 'textboxComment')}
					placeholder={cond}
					multiline
					rows={8}
					value={comment}
					onChange={handleChangeComment}
					defaultValue={comment}
				/>
			</Box>
		</EmptyCard>
	)
}
