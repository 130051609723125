import { useEffect, useState } from 'react'

import { SelectedEventType } from 'src/@types'
import { GetTipsDocument, useGetTipsLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import {
	MAX_MY_FILES_ITEMS,
	RefetchProductsEnum,
	SortOrder,
	getExistingCache,
	orderBySortOrder,
	refetchCache
} from 'src/utils'

export const useVideoUploads = () => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [totalVideos, setTotalVideos] = useState(0)
	const [videos, setVideos] = useState<Tip[]>([])
	const [sort, setSort] = useState(SortOrder.Up)
	const [seeMoreEnabled, setSeeMoreEnabled] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [isRefetch, setIsRefetch] = useState(false)
	const limit = MAX_MY_FILES_ITEMS

	const variables = {
		teacherId,
		limit,
		order_by:
			sort === SortOrder.Recent ? { created_at: orderBySortOrder[sort] } : { title: orderBySortOrder[sort] }
	}

	const [getVideos, { data, loading, refetch }] = useGetTipsLazyQuery()

	const handleSort = (event: SelectedEventType) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const seeMore = () => {
		setIsFetching(true)
	}

	useEffect(() => {
		setSeeMoreEnabled(totalVideos > videos.length)
	}, [videos])

	useEffect(() => {
		if (isRefetch) {
			const existingCache = getExistingCache({ variables, query: GetTipsDocument })
			setVideos(existingCache.tip as Tip[])
			setIsRefetch(false)
		}
	}, [isRefetch])
	useEffect(() => {
		if (!isFetching) return

		const currentLength = videos.length
		refetch({
			offset: currentLength,
			limit
		}).then((fetchMoreResult) => {
			setIsRefetch(
				refetchCache({
					data: fetchMoreResult.data.tip as Tip[],
					type: RefetchProductsEnum.Tip,
					isFetching,
					variables,
					query: GetTipsDocument
				}) as boolean
			)

			setIsFetching(false)
		})
	}, [isFetching])
	useEffect(() => {
		setIsFetching(false)
		if (data?.tip && !isFetching) {
			setIsRefetch(
				refetchCache({
					data: data.tip as Tip[],
					type: RefetchProductsEnum.Tip,
					isFetching,
					variables,
					query: GetTipsDocument
				}) as boolean
			)
		}
		if (data) {
			setTotalVideos(data?.tip_aggregate?.aggregate?.count || 0)
		}
	}, [data, loading])

	useEffect(() => {
		getVideos({
			variables
		})
	}, [sort])

	return {
		totalVideos,
		videos,
		sort,
		handleSort,
		seeMoreEnabled,
		seeMore,
		loading,
		teacherId
	}
}
