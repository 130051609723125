import { createStyles, makeStyles } from '@material-ui/core'

export const useUploadSongTrackStyles = makeStyles(() =>
	createStyles({
		buttonDisabled: {
			'& .MuiButton-contained.Mui-disabled': {
				backgroundColor: 'rgba(255, 194, 12, 0.4)'
			}
		},
		button: {
			width: '216px'
		}
	})
)

export default useUploadSongTrackStyles
