import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { ApolloError } from '@apollo/client'
import { Product } from 'src/@types'
import { cache } from 'src/apollo/state'
import { BaseDialogProps } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import useBaseDialogStyles from 'src/components/Dialogs/BaseDialog/BaseDialog.styles'
import {
	useUpdateClassModulesMutation,
	useUpdateClassVideosMutation,
	useUpdateClassLessonsMutation,
	useUpdateClassSongMutation,
	useGetAllModulesDialogLazyQuery,
	useGetAllLessonPlansLazyQuery,
	useGetAllTipsLazyQuery,
	useGetAllSongsLazyQuery,
	GetAllLessonPlansDocument,
	GetAllModulesDialogDocument,
	GetAllSongsDocument,
	GetAllTipsDocument,
	GetAllPlaylistDocument,
	useGetAllPlaylistLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { UpdateClassModulesMutation } from 'src/graphql/autogenerate/operations'
import { Lesson_Plan, Module, Playlist, Song, Tip } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { ProductDataType } from 'src/scenes/Teacher/scenes/2-Classes/components/allClassProducts/useAllClassProducts'
import {
	MAX_LIST_ITEMS,
	buildSearchText,
	ProductTitleEnum,
	ProductIdsEnum,
	getId,
	refetchCache,
	RefetchProductsEnum,
	getExistingCache
} from 'src/utils'

type UseAddProductToClassDialogProps<T> = BaseDialogProps & {
	selectedData: ProductDataType
	setSelected: Dispatch<SetStateAction<T[]>>
	handleCancel: () => void
	type: string
}
export const useAddProductToClassDialog = <T extends Product>({
	backdropStyles = {},
	dialogPaperStyles = {},
	dialogActionsStyles = {},
	transparentBackdrop,
	selectedData,
	setSelected,
	type,
	handleCancel
}: UseAddProductToClassDialogProps<T>) => {
	const baseDialogClasses = useBaseDialogStyles({
		backdropStyles,
		dialogPaperStyles,
		dialogActionsStyles,
		transparentBackdrop
	})
	const limit = MAX_LIST_ITEMS
	const title = type
	const [data, setData] = useState<({ item: T } & { selected?: boolean })[]>([])
	const [isRefetch, setIsRefetch] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const { classId } = useWurrlyParams()
	const [updateData, setUpdateData] = useState<UpdateClassModulesMutation>()
	const [updateError, setUpdateError] = useState<ApolloError>()
	const [loading, setLoading] = useState(true)
	const [total, setTotal] = useState(0)

	const [updateClassModules, { data: updateClassModulesData, error: updateClassModulesError }] =
		useUpdateClassModulesMutation()
	const [updateClassVideos, { data: updateClassVideosData, error: updateClassVideosError }] =
		useUpdateClassVideosMutation()

	const [updateClassLessons, { data: updateClassLessonsData, error: updateClassLessonsError }] =
		useUpdateClassLessonsMutation()
	const [updateClassSong, { data: updateClassSongData, error: updateClassSongError }] =
		useUpdateClassSongMutation()

	const [loadModules, { loading: loadingModules, data: loadModulesData, refetch: refetchLoadModules }] =
		useGetAllModulesDialogLazyQuery()
	const [
		loadLessonPlans,
		{ loading: loadingLessonPlans, data: loadLessonPlansData, refetch: refetchLoadLessonPlans }
	] = useGetAllLessonPlansLazyQuery()
	const [loadVideos, { loading: loadingVideos, data: loadVideosData, refetch: refetchLoadVideos }] =
		useGetAllTipsLazyQuery()
	const [loadPlaylists, { loading: loadingPlaylists, data: loadPlaylistsData, refetch: refetchLoadPlaylists }] =
		useGetAllPlaylistLazyQuery()
	const [loadSongs, { loading: loadingSongs, data: loadSongsData, refetch: refetchLoadSongs }] =
		useGetAllSongsLazyQuery()

	const moduleVariables = {
		search: buildSearchText('', ['title', 'description']),
		limit,
		classId: Number(classId)
	}

	const lessonsVariables = {
		search: buildSearchText('', ['title', 'description']),
		limit,
		classId: Number(classId)
	}
	const songVariables = {
		search: buildSearchText('', ['title']),
		limit,
		classId: Number(classId)
	}
	const videoVariables = {
		search: buildSearchText('', ['title', 'description']),
		limit,
		classId: Number(classId)
	}
	const playlistVariables = {
		search: buildSearchText('', ['name', 'description']),
		limit,
		classId: Number(classId)
	}
	const getProductId = () => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				return ProductIdsEnum.LessonPlan
			case ProductTitleEnum.Module:
				return ProductIdsEnum.Module
			case ProductTitleEnum.Song:
				return ProductIdsEnum.Song
			case ProductTitleEnum.Tip:
				return ProductIdsEnum.Tip
			case ProductTitleEnum.Playlist:
				return ProductIdsEnum.Playlist
			default:
				return ProductIdsEnum.LessonPlan
		}
	}

	const handleSave = () => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				updateClassLessons({
					variables: {
						class_id: Number(classId),
						lessonPlansIds: selectedData.map((lesson) => ({
							lesson_plan_id: getId(lesson),
							class_id: Number(classId)
						}))
					}
				}).then(() => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'lesson_plan'
					})
				})
				break
			case ProductTitleEnum.Module:
				updateClassModules({
					variables: {
						class_id: Number(classId),
						moduleIds: selectedData.map((module) => ({
							module_id: getId(module),
							class_id: Number(classId)
						}))
					}
				}).then(() => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'module'
					})
				})
				break
			case ProductTitleEnum.Song:
				updateClassSong({
					variables: {
						class_id: Number(classId),
						songIds: selectedData.map((song) => ({
							song_id: getId(song),
							class_id: Number(classId)
						}))
					}
				}).then(() => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'song'
					})
				})
				break
			case ProductTitleEnum.Tip:
				updateClassVideos({
					variables: {
						class_id: Number(classId),
						tipIds: selectedData.map((tip) => ({
							tip_id: getId(tip),
							class_id: Number(classId)
						}))
					}
				}).then(() => {
					cache.evict({
						id: 'ROOT_QUERY',
						fieldName: 'tip'
					})
				})
				break
			default:
				break
		}
		handleCancel()
	}
	const handleSelect = (item: T) => {
		const productId = getProductId()
		setSelected((prev: T[]) => {
			if (prev.some((current) => current[productId] === item[productId])) {
				return prev.filter((current) => current[productId] !== item[productId]) as T[]
			} else {
				return [...prev, item] as T[]
			}
		})
	}

	const handleEnterSearch = (search: string) => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				loadLessonPlans({
					variables: {
						search: buildSearchText(search ?? '', ['title', 'description']),
						limit
					}
				})
				break
			case ProductTitleEnum.Module:
				loadModules({
					variables: {
						search: buildSearchText(search ?? '', ['title', 'description']),
						limit
					}
				})
				break
			case ProductTitleEnum.Song:
				loadSongs({
					variables: {
						search: buildSearchText(search ?? '', ['title', 'description']),
						limit
					}
				})
				break
			case ProductTitleEnum.Tip:
				loadVideos({
					variables: {
						search: buildSearchText(search ?? '', ['title', 'description']),
						limit
					}
				})
				break
			case ProductTitleEnum.Playlist:
				loadPlaylists({
					variables: {
						search: buildSearchText(search ?? '', ['name', 'description']),
						limit
					}
				})
				break
			default:
				break
		}
	}

	const fetchMore = () => {
		setIsFetching(true)
		const currentLength = data.length
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				refetchLoadLessonPlans({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.lesson_plan as Lesson_Plan[],
							type: RefetchProductsEnum.LessonPlan,
							isFetching: true,
							variables: lessonsVariables,
							query: GetAllLessonPlansDocument
						}) as boolean
					)
				})
				break
			case ProductTitleEnum.Module:
				refetchLoadModules({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.module as Module[],
							type: RefetchProductsEnum.Module,
							isFetching: true,
							variables: moduleVariables,
							query: GetAllModulesDialogDocument
						}) as boolean
					)
				})
				break
			case ProductTitleEnum.Song:
				refetchLoadSongs({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.song as Song[],
							type: RefetchProductsEnum.Song,
							isFetching: true,
							variables: songVariables,
							query: GetAllSongsDocument
						}) as boolean
					)
				})
				break
			case ProductTitleEnum.Tip:
				refetchLoadVideos({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.tip as Tip[],
							type: RefetchProductsEnum.Tip,
							isFetching: true,
							variables: videoVariables,
							query: GetAllTipsDocument
						}) as boolean
					)
				})
				break
			case ProductTitleEnum.Playlist:
				refetchLoadPlaylists({
					offset: currentLength,
					limit
				}).then((fetchMoreResult) => {
					setIsRefetch(
						refetchCache({
							data: fetchMoreResult.data.playlist as Playlist[],
							type: RefetchProductsEnum.Playlist,
							isFetching: true,
							variables: playlistVariables,
							query: GetAllPlaylistDocument
						}) as boolean
					)
				})
				break
			default:
				break
		}
	}

	const parseData = (data: []) => {
		return data.map((item) => ({
			item: item as unknown as T,
			selected: selectedData.some((current) => getId(current) === getId(item))
		}))
	}
	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (loadLessonPlansData && !isFetching) {
					setData(parseData(loadLessonPlansData.lesson_plan as []))
					setLoading(loadingLessonPlans)
					setTotal(loadLessonPlansData?.lesson_plan_aggregate.aggregate?.count || 0)
				}
				break
			case ProductTitleEnum.Module:
				if (loadModulesData && !isFetching) {
					setData(parseData(loadModulesData.module as []))
					setLoading(loadingModules)
					setTotal(loadModulesData?.module_aggregate.aggregate?.count || 0)
				}
				break
			case ProductTitleEnum.Song:
				if (loadSongsData && !isFetching) {
					setData(parseData(loadSongsData.song as []))
					setLoading(loadingSongs)
					setTotal(loadSongsData?.song_aggregate.aggregate?.count || 0)
				}
				break
			case ProductTitleEnum.Tip:
				if (loadVideosData && !isFetching) {
					setData(parseData(loadVideosData.tip as []))
					setLoading(loadingVideos)
					setTotal(loadVideosData?.tip_aggregate.aggregate?.count || 0)
				}
				break
			case ProductTitleEnum.Playlist:
				if (loadPlaylistsData && !isFetching) {
					setData(parseData(loadPlaylistsData.playlist as []))
					setLoading(loadingPlaylists)
					setTotal(loadPlaylistsData?.playlist_aggregate.aggregate?.count || 0)
				}
				break
			default:
				break
		}
	}, [loadLessonPlansData, loadModulesData, loadPlaylistsData, loadSongsData, loadVideosData])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (updateClassLessonsData) {
					setUpdateData(updateClassLessonsData)
				}
				break
			case ProductTitleEnum.Module:
				if (updateClassModulesData) {
					setUpdateData(updateClassModulesData)
				}
				break
			case ProductTitleEnum.Song:
				if (updateClassSongData) {
					setUpdateData(updateClassSongData)
				}
				break
			case ProductTitleEnum.Tip:
				if (updateClassVideosData) {
					setUpdateData(updateClassVideosData)
				}
				break
			default:
				break
		}
	}, [updateClassModulesData, updateClassLessonsData, updateClassVideosData, updateClassSongData])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				if (updateClassLessonsError) {
					setUpdateError(updateClassLessonsError)
				}
				break
			case ProductTitleEnum.Module:
				if (updateClassModulesError) {
					setUpdateError(updateClassModulesError)
				}
				break
			case ProductTitleEnum.Song:
				if (updateClassSongError) {
					setUpdateError(updateClassSongError)
				}
				break
			case ProductTitleEnum.Tip:
				if (updateClassVideosError) {
					setUpdateError(updateClassVideosError)
				}
				break
			default:
				break
		}
	}, [updateClassModulesError, updateClassLessonsError, updateClassVideosError, updateClassSongError])
	useEffect(() => {
		if (isRefetch) {
			let existingCache
			switch (type) {
				case ProductTitleEnum.LessonPlan:
					existingCache = getExistingCache({
						variables: lessonsVariables,
						query: GetAllLessonPlansDocument
					}) as {
						lesson_plan: Lesson_Plan[]
					}
					setData(parseData(existingCache.lesson_plan as []))
					break
				case ProductTitleEnum.Module:
					existingCache = getExistingCache({
						variables: moduleVariables,
						query: GetAllModulesDialogDocument
					}) as {
						module: Module[]
					}
					setData(parseData(existingCache.module as []))
					break
				case ProductTitleEnum.Song:
					existingCache = getExistingCache({
						variables: songVariables,
						query: GetAllSongsDocument
					}) as {
						song: Song[]
					}
					setData(parseData(existingCache.song as []))
					break
				case ProductTitleEnum.Tip:
					existingCache = getExistingCache({
						variables: videoVariables,
						query: GetAllTipsDocument
					}) as {
						tip: Tip[]
					}
					setData(parseData(existingCache.tip as []))
					break
				case ProductTitleEnum.Playlist:
					existingCache = getExistingCache({
						variables: playlistVariables,
						query: GetAllPlaylistDocument
					}) as {
						playlist: Playlist[]
					}
					setData(parseData(existingCache.playlist as []))
					break
				default:
					break
			}
			setIsRefetch(false)
			setIsFetching(false)
		}
	}, [isRefetch])

	useEffect(() => {
		switch (type) {
			case ProductTitleEnum.LessonPlan:
				loadLessonPlans({
					variables: lessonsVariables
				})
				break
			case ProductTitleEnum.Module:
				loadModules({
					variables: moduleVariables
				})
				break
			case ProductTitleEnum.Song:
				loadSongs({
					variables: songVariables
				})
				break
			case ProductTitleEnum.Tip:
				loadVideos({
					variables: videoVariables
				})
				break
			case ProductTitleEnum.Playlist:
				loadPlaylists({
					variables: playlistVariables
				})
				break
			default:
				break
		}
	}, [])

	useEffect(() => {
		if (selectedData) {
			setData(parseData(data.map((item) => item.item) as unknown as []))
		}
	}, [selectedData])

	return {
		total,
		data,
		loading,
		fetchMore,
		handleEnterSearch,
		handleSelect,
		updateError,
		updateData,
		title,
		baseDialogClasses,
		handleSave
	}
}
