import { createStyles, fade, makeStyles, Theme } from '@material-ui/core'

export const useAssignmentStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: fade(theme.palette.common.black, 0.4)
		},
		emptyText: {
			color: theme.palette.grey[500]
		},
		title: {
			marginBottom: 2
		},
		alert: {
			padding: '0px 6px',
			fontSize: '12px'
		},
		notStarted: {
			color: theme.palette.info.main,
			backgroundColor: fade(theme.palette.info.main, 0.2)
		},
		inProgress: {
			color: theme.palette.secondary.main,
			backgroundColor: fade(theme.palette.secondary.main, 0.2)
		},
		graded: {
			color: theme.palette.success.main,
			backgroundColor: fade(theme.palette.success.main, 0.2)
		},
		notStartedDivider: {
			backgroundColor: theme.palette.info.main
		},
		inProgressDivider: {
			backgroundColor: theme.palette.secondary.main
		},
		gradedDivider: {
			backgroundColor: theme.palette.success.main
		},
		classSelect: { width: '50%', marginRight: '10px' },
		dateSelect: { width: '50%', display: 'flex', justifyContent: 'flex-end' },
		divider: { margin: '40px 0 0 0' },
		tiles: { marginTop: 20 },
		loaderHolder: { display: 'flex', alignItems: 'center', justifyContent: 'space-around' },
		container: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
		headerBox: { display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' },
		containerBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '12px',
			height: 40
		},
		noPadding: {
			'& .MuiList-padding': {
				padding: '0px'
			},
			overflow: 'hidden'
		}
	})
)

export default useAssignmentStyles
