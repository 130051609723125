import React, { Fragment, useEffect, useState } from 'react'

import { Box, Checkbox, TableCell, TableRow, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'

import { BaseLink, InfoDialog, StudentsFromCSV } from '..'
import { CopyIcon, DownloadIcon } from '../../assets/icons'
import { useGetStudentQRsPdfQuery } from '../../graphql/autogenerate/hooks'
import { AddStudentsByBulkQRsFilter, StudentFromCsv } from '../../graphql/autogenerate/schemas'
import { buildImagePath } from '../../utils'
import { BaseButton } from '../Buttons/BaseButton'

type AddStudentsTableRowProps = {
	classId: number
	student: StudentsFromCSV
	selectedStudents: StudentsFromCSV[]
	setSelectedStudents: React.Dispatch<React.SetStateAction<StudentsFromCSV[]>>
	onDelete: (student: StudentsFromCSV) => void
}
export const AddStudentsTableRow = ({
	classId,
	student,
	selectedStudents,
	setSelectedStudents,
	onDelete
}: AddStudentsTableRowProps) => {
	const [isCopyCodeOpen, setIsCopyCodeOpen] = useState(false)
	const [qrCodeUrl, setQrCodeUrl] = useState<string>()
	const studentToAdd = {
		firstName: student.firstName,
		lastName: student.lastName,
		code: student.code,
		email: student.email,
		qrCode: student.qrCode
	} as StudentFromCsv
	const filter = { students: [studentToAdd], classId } as AddStudentsByBulkQRsFilter
	const { loading: studentsQRsPDFLoading, data: studentsQRsPDFData } = useGetStudentQRsPdfQuery({
		variables: { filter }
	})

	useEffect(() => {
		if (
			!studentsQRsPDFLoading &&
			studentsQRsPDFData &&
			studentsQRsPDFData.addStudentsByBulkQRs &&
			studentsQRsPDFData.addStudentsByBulkQRs.path
		) {
			const url = buildImagePath(studentsQRsPDFData.addStudentsByBulkQRs.path)
			setQrCodeUrl(url)
		}
	}, [studentsQRsPDFData])

	const copyCode = () => {
		navigator.clipboard.writeText(student.code || '')
		setIsCopyCodeOpen(true)
	}

	const onSelect = (student: StudentsFromCSV) => {
		const array = [...selectedStudents]
		const index = array.findIndex((st) => st.id === student.id)
		if (index !== -1) {
			array.splice(index, 1)
		} else {
			array.push(student)
		}
		setSelectedStudents(array)
	}

	return (
		<Fragment>
			<TableRow
				key={student.id}
				hover
				onClick={(e) => {
					e.stopPropagation()
					onSelect(student)
				}}>
				<TableCell>
					<Checkbox checked={student.isSelected} />
				</TableCell>
				<TableCell>
					<Typography>{`${student.firstName} ${student.lastName}`}</Typography>
				</TableCell>
				<TableCell>
					<Typography>{student.email}</Typography>
				</TableCell>
				<TableCell>
					<Box display="flex" flexWrap="nowrap" alignItems="center">
						{/* <Typography>{studentCode}</Typography> */}
						<BaseLink
							// disabled={!qrCodeUrl}
							href={qrCodeUrl}
							download={`${student.firstName} ${student.lastName}.pdf`}
							target="_blank">
							<img alt="DownloadIcon" src={DownloadIcon} style={{ cursor: 'pointer' }} />
						</BaseLink>
					</Box>
				</TableCell>
				<TableCell>
					<Box display="flex" flexWrap="nowrap" alignItems="center">
						<Typography style={{ margin: '7px' }}>{student.code} </Typography>
						<img
							alt="CopyIcon"
							src={CopyIcon}
							onClick={copyCode}
							style={{
								cursor: 'pointer'
							}}
						/>
					</Box>
				</TableCell>
				<TableCell>
					{/* <Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="left"> */}
					<BaseButton
						style={{ minWidth: '35px', width: '35px', height: '35px' }}
						onClick={(e) => {
							e.stopPropagation()
							onDelete(student)
						}}>
						<DeleteIcon />
					</BaseButton>
					{/* </Box> */}
				</TableCell>
			</TableRow>
			<InfoDialog
				open={isCopyCodeOpen}
				onClose={() => setIsCopyCodeOpen(false)}
				title={
					<Typography align="center" style={{ fontWeight: 500, fontSize: 32 }}>
						Code Copied!
					</Typography>
				}
				body="You can now send this code to your Student to invite them to a Class directly."
				discardLabel="Close"
				onDiscard={() => {
					setIsCopyCodeOpen(false)
				}}
				icon={<Check />}
			/>
		</Fragment>
	)
}
