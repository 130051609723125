import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { noop } from 'lodash'
import { ActivityBoxEmpty, MainTitle, SelectSort } from 'src/components'
import { SelectProductDialog } from 'src/components/Dialogs/SelectProductDialog'
import { UnsuscribeDialog } from 'src/components/Dialogs/UnsuscribeDialog/UnsuscribeDialog'
import { SearchBar } from 'src/components/SearchBar/SearchBar'
import { SelectFilter } from 'src/components/SelectFilter'
import { SubscriptionsCard } from 'src/components/SubscriptionsCard/SubscriptionsCard'
import { Partner, Product_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { usePartners } from 'src/scenes/Teacher/scenes/partners/usePartners'
import { generateFlagText } from 'src/utils'

import { PartnerCardContainer } from './components/PartnerCardContainer'
import useStyles from './Partners.styles'

export const AllPartners = () => {
	const styles = useStyles()
	const {
		partners,
		partnerSuscriptions,
		showSubscriptions,
		goToPartner,
		handleSearchbarTextEnter,
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		isOpen,
		partnerId,
		setPartnerId,
		sort,
		filter,
		onFilterChange,
		onSortChange,
		breadcrumbs,
		totalPartners,
		searchbarData,
		searchbarPartnersLoading,
		searchTeacherSubscriptionsData,
		searchSuscriptionDataLoading,
		onUnsubscribe,
		searchbarText,
		isFetching,
		moreResults,
		partnerDataLoading,
		suscriptionDataLoading,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	} = usePartners()

	const searchBarItems = () => {
		return !showSubscriptions
			? searchbarData.map((partner) => (
					<PartnerCardContainer partner={partner as Partner} key={partner.partner_id} elevation={0} />
			  ))
			: searchTeacherSubscriptionsData.map((partner) => (
					<SubscriptionsCard
						isSearch
						key={partner.orderId}
						data={partner}
						allSubscriptionsCard
						onView={() => goToPartner(partner.productId)}
						onUnsubscribe={() => setItem(partner)}
						dataflagText={generateFlagText(partner)}
					/>
			  ))
	}

	return (
		<>
			{isOpen && partnerId && (
				<SelectProductDialog
					onSuccess={noop}
					productId={partnerId}
					productName={Product_Name_Enum.Partner}
					handleCloseProductDialog={handleCloseProductDialog}
				/>
			)}
			<UnsuscribeDialog
				isOpen={unSuscribeOpenDialog}
				setItem={setItem}
				onConfirm={(stripeSubscriptionId) => onUnsubscribe(stripeSubscriptionId)}
				setIsOpen={setUnSuscribeOpenDialog}
				item={item}
				onDone={(stripeSubscriptionId) => handleRefetch(stripeSubscriptionId)}
			/>
			<MainTitle
				breadcrumbs={breadcrumbs}
				title="All Partners"
				description={'Here are all the Partners you are currently subscribed to'}
			/>
			<SearchBar
				placeholder="Find Partners by Title or Description"
				onChange={handleSearchbarTextChange}
				onEnterPressed={handleSearchbarTextEnter}
				loading={searchbarPartnersLoading || searchSuscriptionDataLoading}
				dropdownItems={searchBarItems()}
			/>
			<Box className={styles.listToolbar}>
				<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
					<Typography variant="h6">
						<b>Partners </b> ({totalPartners})
					</Typography>
					<div style={{ flexGrow: 1 }} />
					{totalPartners !== 0 && showSubscriptions && (
						<SelectFilter value={filter} onChange={onFilterChange} />
					)}
					{totalPartners !== 0 && <SelectSort cyKey="Songs" value={sort} onChange={onSortChange} />}
				</Box>
			</Box>
			{partners &&
				!showSubscriptions &&
				partners.length > 0 &&
				partners.map((partner) => (
					<Box marginBottom={'15px'} marginTop={'15px'} key={partner.partner_id}>
						<PartnerCardContainer
							partner={partner as Partner}
							key={partner.partner_id}
							elevation={0}
							btnSuscription={{
								onClick: (e) => {
									e.stopPropagation()
									setPartnerId(partner.partner_id)
									handleOpenProductDialog()
								}
							}}
						/>
					</Box>
				))}
			{partnerSuscriptions &&
				showSubscriptions &&
				partnerSuscriptions.length > 0 &&
				partnerSuscriptions.map((partner) => (
					<Box marginBottom={'15px'} marginTop={'15px'} key={partner.productId}>
						<SubscriptionsCard
							key={partner.orderId}
							data={partner}
							allSubscriptionsCard
							onView={() => goToPartner(partner.productId)}
							onUnsubscribe={() => setItem(partner)}
							dataflagText={generateFlagText(partner)}
						/>
					</Box>
				))}

			{((isFetching && moreResults) || partnerDataLoading || suscriptionDataLoading) && (
				<Box m={3} textAlign="center">
					<CircularProgress color="secondary" size={40} />
				</Box>
			)}

			{((!partnerDataLoading && partners.length > 0) ||
				(!suscriptionDataLoading && partnerSuscriptions.length > 0)) &&
				!moreResults && (
					<Box mt={4} textAlign="center">
						<Button
							onClick={() => {
								window.scrollTo(0, 0)
							}}
							variant="text"
							color="secondary">
							Back to Top
						</Button>
					</Box>
				)}

			{totalPartners === 0 && (
				<>
					{searchbarText && (
						<Typography variant="h6" color="textSecondary">
							Your search for "{searchbarText}" did not return any results.
						</Typography>
					)}
					{!searchbarText && (
						<ActivityBoxEmpty description="You have not subscribed to any partners yet" />
					)}
				</>
			)}
		</>
	)
}
