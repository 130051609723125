import { useEffect, useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import { useGetStudentByPkExtendedLazyQuery, useGetStudentIntegrationQuery } from 'src/graphql/autogenerate/hooks'
import { Student } from 'src/graphql/autogenerate/schemas'
import { useWurrlyParams } from 'src/hooks'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'

const useStudentDetail = () => {
	const params = useWurrlyParams<typeof Pages.ClassStudentDetail.params[number]>()
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const classId = params.classId
	const studentId = params.studentId
	const [classSelectedId, setClassSelectedId] = useState<number>()
	const [selectedCard, setSelectedCard] = useState(0)
	const [selectedPractice, setSelectedPractice] = useState(0)
	const [hasAssignmentsOrActivities, setHasAssignmentsOrActivities] = useState(false)
	const [getStudentByPkExtended, { data: studentData, loading: studentLoading, refetch: refetchStudent }] =
		useGetStudentByPkExtendedLazyQuery({
			fetchPolicy: 'no-cache'
		})
	const { data: studentIntegrationData } = useGetStudentIntegrationQuery({ variables: { studentId } })

	useEffect(() => {
		if (!studentId) return

		getStudentByPkExtended({
			variables: {
				studentId
			}
		})
	}, [studentId])

	return {
		teacherId,
		classId,
		studentId,
		classSelectedId,
		selectedCard,
		setSelectedCard,
		selectedPractice,
		setSelectedPractice,
		setClassSelectedId,
		student: studentData?.student_by_pk as Student,
		refetchStudent,
		studentLoading,
		integrationName: studentIntegrationData?.student_integration[0]?.integration_name || null,
		hasAssignmentsOrActivities,
		setHasAssignmentsOrActivities
	}
}

export const [StudentDetailProvider, useStudentDetailContext] = buildGenericContext(useStudentDetail)
