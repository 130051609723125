import React from 'react'

import {
	Avatar,
	Box,
	Checkbox,
	createStyles,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core'

import { getCyKey } from '../../../../../../../../utils'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			'& .boxActions': {
				'& .MuiButton-root': {
					minWidth: 'max-content',
					height: 36,
					'&:not(.addClass)': {
						width: 36,
						padding: theme.spacing(0.75)
					},
					'&:not(:last-child)': {
						marginRight: theme.spacing(1)
					},
					'&:last-child, &.addClass': {
						marginLeft: theme.spacing(1)
					}
				}
			}
		}
	})
)

export type ListItemSongProps = {
	imageUrl: string
	name: string
	email?: string
	classesName?: string
	onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
	selected: boolean
	hideCheckbox?: boolean
}

export const ListAddItemStudent = ({
	imageUrl,
	name,
	email,
	selected,
	classesName,
	hideCheckbox
}: ListItemSongProps) => {
	const classes = useStyles()

	return (
		<ListItem className={classes.listItem}>
			<ListItemAvatar>
				<Avatar variant="circular" src={imageUrl} />
			</ListItemAvatar>
			<ListItemText
				style={{ marginRight: '20px' }}
				disableTypography
				primary={
					<div>
						<Typography noWrap variant="h6" style={{ fontSize: 18 }}>
							<b data-cy={getCyKey(ListAddItemStudent, 'Name')}>{name}</b>
						</Typography>
						<Typography noWrap style={{ fontSize: 14 }}>
							<b data-cy={getCyKey(ListAddItemStudent, 'ClassesName')}>{classesName}</b>
						</Typography>
					</div>
				}
				secondary={<div data-cy={getCyKey(ListAddItemStudent, 'Email')}>{email}</div>}
			/>
			{!hideCheckbox && (
				<Box position="absolute" top={8} right={8}>
					<Checkbox data-cy={getCyKey(ListAddItemStudent, 'checkBox')} checked={selected} />
				</Box>
			)}
		</ListItem>
	)
}
