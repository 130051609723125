import { Box, Typography } from '@material-ui/core'

import { useClassCardStyles } from './ClassCard.styles'

type ClassCardProps = {
	imgSource?: string
	classTitle: string
	studentsCount: number
}

const ClassCard = ({ imgSource, classTitle, studentsCount }: ClassCardProps) => {
	const styles = useClassCardStyles()

	return (
		<Box className={styles.container}>
			{imgSource ? (
				<img src={imgSource} className={styles.image} />
			) : (
				<Box className={styles.defaultImage}></Box>
			)}

			<Box className={styles.details}>
				<Typography variant="h6" className={styles.title}>
					{classTitle}
				</Typography>
				<Typography variant="subtitle1" className={styles.studentsCount}>
					{studentsCount} Student{studentsCount !== 1 ? 's' : ''}
				</Typography>
			</Box>
		</Box>
	)
}

export default ClassCard
