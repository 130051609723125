import { useState } from 'react'

import { buildGenericContext } from 'src/context/genericContext'
import { useLoginStudentLazyQuery } from 'src/graphql/autogenerate/hooks'

import { useStudentSignInOptionsContext } from '../../useStudentSignInOptions'

const useLogin = () => {
	const [studentId, setStudentId] = useState<number | null>(null)
	const [studentName, setStudentName] = useState<string | null>(null)
	const [loginEmail, setLoginEmail] = useState<string>('')
	const [loginPassword, setLoginPassword] = useState<string>('')
	const [loginError, setLoginError] = useState<string>('')
	const { handleClose } = useStudentSignInOptionsContext()
	const [
		getStudentToken,
		{
			data: getStudentTokenData,
			loading: getStudentTokenLoading,
			error: getStudentTokenError,
			refetch: refetchGetStudent
		}
	] = useLoginStudentLazyQuery()

	return {
		studentId,
		studentName,
		setStudentId,
		setStudentName,
		getStudentTokenData,
		getStudentTokenLoading,
		getStudentTokenError,
		refetchGetStudent,
		loginEmail,
		loginPassword,
		setLoginEmail,
		setLoginPassword,
		getStudentToken,
		loginError,
		setLoginError,
		handleClose
	}
}

export const [StudentLoginProvider, useStudentLoginContext] = buildGenericContext(useLogin)
