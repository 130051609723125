import { Typography } from '@material-ui/core'
import { Logo } from 'src/assets/icons'

import useStyles from './ErrorPage.styles'

type ErrorPageProps = {
	errorCode: number
	description?: string
	caption?: string
	children?: React.ReactNode
}

export const ErrorPage = ({ errorCode, description, caption, children }: ErrorPageProps) => {
	const styles = useStyles()

	return (
		<div className={styles.root}>
			<div className={styles.numberError}>
				{errorCode === 404 ? (
					<>
						4<img src={Logo} draggable={false} />4
					</>
				) : (
					errorCode
				)}
			</div>

			<Typography className={styles.description} variant="body1">
				{description}
			</Typography>
			<Typography className={styles.caption} variant="caption">
				{caption}
			</Typography>

			{children && <div>{children}</div>}
		</div>
	)
}
