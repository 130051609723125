import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStudentsFilterDropDownStyles = makeStyles<Theme, { width: number }>((theme) =>
	createStyles({
		container: {
			width: ({ width }) => `calc(${width}px + ${theme.spacing(6)}px)`,
			background: 'transparent',
			padding: theme.spacing(0, 1),
			height: '100%',
			marginBottom: theme.spacing(1)
		},
		card: {
			marginTop: theme.spacing(1),
			width: '100%',
			boxShadow:
				'0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
		},
		scrollContainer: {
			overflow: 'auto',
			maxHeight: '560px',
			marginRight: theme.spacing(1),
			'&::-webkit-scrollbar': {
				width: '6px'
			}
		},
		divider: {
			margin: theme.spacing(0, 2)
		},
		cardContainer: {
			padding: theme.spacing(2),
			'&:hover:not(:first-child)': {
				background: 'rgba(243, 171, 52, 0.08)',
				cursor: 'pointer'
			}
		}
	})
)

export default useStudentsFilterDropDownStyles
