import AddIcon from '@material-ui/icons/Add'
import { MainTitle } from 'src/components'
import { AddProductToClassDialog } from 'src/components/Dialogs/AddProductToClassDialog/AddProductToClassDialog'
import { SelectAddProductDialog } from 'src/components/Dialogs/SelectAddProductDialog/SelectAddProductDialog'
import { useClassVideosTitle } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassVideos/components/useClassVideosTitle'
import { ProductTitleEnum } from 'src/utils'

export const ClassVideosTitle = (): JSX.Element => {
	const {
		breadCrumbs,
		handleAddVideos,
		handleCancelVideos,
		handleAddPlaylist,
		handleCancelPlaylist,
		handleOpenSelectProductDialog,
		handleCancelSelectProductDialog,
		showVideosDialog,
		showPlaylistDialog,
		selectProductDialog,
		selectedVideos,
		selectedPlaylist,
		setShowVideosDialog,
		setShowPlaylistDialog,
		setSelectProductDialog,
		setSelectedVideos,
		setSelectedPlaylist
	} = useClassVideosTitle()

	return (
		<>
			<MainTitle
				breadcrumbs={breadCrumbs}
				title="Class Videos"
				description="These are the Videos that have been added to this Class."
				rightActions={[
					{
						variant: 'contained',
						color: 'secondary',
						startIcon: <AddIcon />,
						name: 'Add Class',
						onClick: handleOpenSelectProductDialog
					}
				]}
			/>
			<AddProductToClassDialog
				onClose={() => {
					setShowVideosDialog(false)
					setSelectProductDialog(true)
				}}
				selectedData={selectedVideos}
				setSelected={setSelectedVideos}
				open={showVideosDialog}
				handleCancel={handleCancelVideos}
				type={ProductTitleEnum.Tip}
			/>

			<AddProductToClassDialog
				onClose={() => {
					setShowPlaylistDialog(false)
					setSelectProductDialog(true)
				}}
				selectedData={selectedPlaylist}
				setSelected={setSelectedPlaylist}
				open={showPlaylistDialog}
				handleCancel={handleCancelPlaylist}
				type={ProductTitleEnum.Playlist}
			/>

			<SelectAddProductDialog
				open={selectProductDialog}
				onClose={() => setSelectProductDialog(false)}
				handleCancel={handleCancelSelectProductDialog}
				actions={[
					{
						name: 'Add Videos',
						color: 'secondary',
						endIcon: <AddIcon />,
						onClick: handleAddVideos
					},
					{
						name: 'Add Playlist',
						color: 'secondary',
						endIcon: <AddIcon />,
						onClick: handleAddPlaylist
					}
				]}
			/>
		</>
	)
}
