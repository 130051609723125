import { Tooltip, Button, Typography } from '@material-ui/core'

type SeeAllTooltipProps = {
	canFetchMore: boolean
	btnLabel?: string
	name?: string
	onClick?: () => void
}

export const SeeAllBtnTooltip = ({ canFetchMore, name, btnLabel = 'See More', onClick }: SeeAllTooltipProps) => {
	return (
		<Tooltip
			title={
				(canFetchMore ? `Load more ${name ?? 'items'}` : `Already viewing all ${name ?? 'items'}`) + '.'
			}>
			<div>
				<Button onClick={onClick} disabled={!canFetchMore}>
					<Typography color="textSecondary">
						<strong>{btnLabel}</strong>
					</Typography>
				</Button>
			</div>
		</Tooltip>
	)
}
