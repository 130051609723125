import { useEffect } from 'react'

import { useHistory } from 'react-router'
import { queryParamsClassWurrlies } from 'src/components/Grids/WurrliesGrid.types'
import { WurrlyCarousel } from 'src/components/Wurrly/WurrlyCarousel/WurrlyCarousel'
import { useGetWurrliesByClassIdLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Wurrly } from 'src/graphql/autogenerate/schemas'
import { Pages } from 'src/routes/teacherPages'
import { buildRouteParameters, SortOrder } from 'src/utils'

import { Privacy_Enum } from '../../../../../../../graphql/autogenerate/schemas'
import { useClassDetailsContext } from '../useClassDetails'

export const ClassDetailsWurrlies = () => {
	const history = useHistory()
	const { classId } = useClassDetailsContext()
	const [getWurrlys, { data }] = useGetWurrliesByClassIdLazyQuery({})

	useEffect(() => {
		if (classId) {
			getWurrlys({
				variables: {
					classId,
					limit: 5,
					orderDate: Order_By.Desc,
					privacySettings: [Privacy_Enum.Class, Privacy_Enum.TeacherAndMe]
				}
			})
		}
	}, [classId])

	const wurrlies = (data?.wurrly || []) as Wurrly[]

	const handleWurrlyCard = (wurrlyId: number) => {
		history.push(
			buildRouteParameters(
				Pages.WurrliesDetails,
				{
					classId,
					wurrlyId
				},
				true,
				{ sort: SortOrder.Recent } as queryParamsClassWurrlies
			)
		)
	}

	const link = buildRouteParameters(Pages.ClassWurrlies, { classId })

	return (
		<WurrlyCarousel
			title="Student Wurrlies"
			wurrlies={wurrlies}
			onWurrlyClick={handleWurrlyCard}
			viewAllLink={link}
		/>
	)
}
