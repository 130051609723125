import { useEffect, useState } from 'react'

import { useGetClassesNamesQuery, useGetClassStudentActivitiesQuery } from 'src/graphql/autogenerate/hooks'
import { Order_By, Student_Activity_Order_By } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'

export const useStudentActivities = () => {
	const PAGE_ELEMENTS = 5

	const { teacherData } = useLoginContext()
	const [selectedClassId, setSelectedClassId] = useState(0)
	// order is usually sent to the query
	const [order, setOrder] = useState<Student_Activity_Order_By>({
		student_activity_type: Order_By.Asc
	})
	const { data: classes } = useGetClassesNamesQuery({
		variables: { teacherId: teacherData.teacher_id },
		skip: !teacherData.teacher_id
	})

	useEffect(() => {
		if (classes?.class?.length) {
			setSelectedClassId(classes?.class[0].class_id)
		}
	}, [classes])

	const {
		data: dataStudentActivities,
		loading: loadingStudentActivities,
		refetch: refetchStudentActivities
	} = useGetClassStudentActivitiesQuery({
		variables: {
			classId: selectedClassId,
			offset: 0,
			limit: PAGE_ELEMENTS,
			order: order as unknown as Student_Activity_Order_By
		}
	})

	return {
		PAGE_ELEMENTS,
		dataStudentActivities,
		loadingStudentActivities,
		refetchStudentActivities,
		classes,
		setSelectedClassId,
		selectedClassId,
		setOrder
	}
}
