import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStudentAccountActivationStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			paddingTop: '10px'
		},
		label: {
			margin: '25px 0 10px 0'
		},
		input: {
			borderRadius: '4px',
			border: '1px solid #DDDDDD',
			padding: '10px 30px'
		},
		checkbox: {
			paddingLeft: '0px'
		},
		checkboxText: {
			color: theme.palette.grey[500]
		}
	})
)
