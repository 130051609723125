import { Box, Divider } from '@material-ui/core'
import { PagesProps } from 'src/@types'
import { FeaturedPlaylists } from 'src/components/FeaturedPlaylists'
import { CanvasClassRoasterDialog } from 'src/scenes/Teacher/scenes/1-Home/components/CanvasRoaster'
import { ClassLinkClassRoasterDialog } from 'src/scenes/Teacher/scenes/1-Home/components/ClassLinkClassRoasterDialog'
import { CleverClassRoasterDialog } from 'src/scenes/Teacher/scenes/1-Home/components/CleverClassRoasterDialog'

import { BrowseOurContent } from './components/BrowseOurContent'
import { EngageStudents } from './components/EngageStudents/EngageStudents'
import { Hero } from './components/Hero'
import { Insights } from './components/Insights/Insights'
import StudentsSummmary from './components/StudentsSummary'
import { YourFavorites } from './components/YourFavorites'
import useHomeStyles from './Home.styles'

export const Home = (props: PagesProps) => {
	const classes = useHomeStyles()

	return (
		<Box>
			<Hero {...props} />
			<Box p={{ xs: 3, sm: 10, md: 15.5 }} className={classes.innerBox}>
				<BrowseOurContent />
				<Divider className={classes.divider} />
				<EngageStudents />
				<Divider className={classes.divider} />
				<StudentsSummmary />
				<Insights />
				<YourFavorites />
				<FeaturedPlaylists />
				<CleverClassRoasterDialog />
				<ClassLinkClassRoasterDialog />
				<CanvasClassRoasterDialog />
			</Box>
		</Box>
	)
}
