import { useState } from 'react'

import { Grid, GridProps, GridSize, GridSpacing, ButtonProps } from '@material-ui/core'
import { IconActionCard, IconActionOptionType, ImageActionCard, ImageActionOptionType } from 'src/components'
import { generateUniqueId, DEFAULT_CARD_HEIGHT } from 'src/utils'

export type HorizontalGridType = {
	optionSelected?: string
	options: (IconActionOptionType | ImageActionOptionType)[]
	handleSelectOption: (id: string) => void
	cardHeight?: number
	cardWidth?: number
	spacing?: GridSpacing
	xs?: GridSize
	gridItemProps?: GridProps
	imageButtonProps?: ButtonProps
}
export const HorizontalGrid = ({
	optionSelected,
	options,
	handleSelectOption,
	cardHeight = DEFAULT_CARD_HEIGHT + 20,
	cardWidth,
	spacing = 3,
	gridItemProps,
	imageButtonProps
}: // xs = 3
HorizontalGridType) => {
	const [optionHovered, setOptionHovered] = useState('')

	return (
		<Grid container item xs={12} spacing={spacing}>
			{options.map((opt) => {
				const iconEl = opt as IconActionOptionType
				const imageEl = opt as ImageActionOptionType

				return (
					<Grid item xs key={generateUniqueId()} {...gridItemProps}>
						{iconEl.icon ? (
							<IconActionCard
								option={iconEl}
								isSelected={!!optionSelected && optionSelected === opt.id}
								handleClick={() => handleSelectOption(opt.id)}
								cardHeight={cardHeight}
								cardWidth={cardWidth}
								isSomeCardHovered={!!optionHovered}
								isHovered={optionHovered === opt.id}
								setIsHovered={(isHovered: boolean) => {
									setTimeout(() => {
										setOptionHovered(isHovered ? opt.id : '')
									}, 50)
								}}
							/>
						) : imageEl.imagePath ? (
							<ImageActionCard
								option={imageEl}
								isSelected={!!optionSelected && optionSelected === opt.id}
								handleClick={() => handleSelectOption(opt.id)}
								cardHeight={cardHeight}
								cardWidth={cardWidth}
								buttonProps={imageButtonProps}
							/>
						) : (
							<div>{opt.name}</div>
						)}
					</Grid>
				)
			})}
		</Grid>
	)
}
