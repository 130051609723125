import { useMemo, useState } from 'react'

export const usePriceDiscount = (price?: number) => {
	const [totalDiscount, setTotalDiscount] = useState(0)
	const handleDiscountPercentage = (val: number) => {
		if (!price) return
		setTotalDiscount(price * (val / 100))
	}

	const handleDiscountAmount = (val: number) => {
		if (!price) return
		setTotalDiscount(val)
	}
	const totalPrice = useMemo(() => {
		if (!price) return 0

		return price - totalDiscount
	}, [price, totalDiscount])

	return {
		handleDiscountAmount,
		handleDiscountPercentage,
		totalPrice
	}
}
