import { Button, ButtonProps, Typography, Theme, createStyles, makeStyles, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import clsx from 'clsx'

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		AddToClassButton: {
			minWidth: 'fit-content',
			height: 40,

			display: 'flex',
			alignItems: 'center',
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary.dark
			}
		}
	})
)

type AddToClassButtonProps = ButtonProps & {
	tooltipTitle?: string
}
export const AddToClassButton = ({ tooltipTitle, ...props }: AddToClassButtonProps) => {
	const styles = useStyles()

	return (
		<Tooltip title={tooltipTitle || ''}>
			<Button {...props} className={clsx(styles.AddToClassButton, props.className)}>
				<Typography>{props?.children || 'Add to class'}</Typography>
				<AddIcon />
			</Button>
		</Tooltip>
	)
}
