import { createStyles, makeStyles } from '@material-ui/core'

export const useSelectDateRangeStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start'
		},
		select: {
			width: '220px',
			height: '40px',
			padding: '10px'
		},
		menuItem: {
			'&.Mui-selected': {
				color: 'rgba(243, 171, 52, 1)',
				backgroundColor: 'rgba(255, 194, 12, 0.12)'
			}
		},
		divider: {
			height: '40px',
			margin: theme.spacing(0, 2)
		},
		datePicker: {
			marginRight: theme.spacing(2),
			'& .MuiInputBase-root': {
				width: '165px',
				height: '40px',
				padding: '10px'
			}
		}
	})
)

export default useSelectDateRangeStyles
