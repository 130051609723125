import { createStyles, makeStyles } from '@material-ui/core'

export const usePartnerPageStyles = makeStyles((theme) => ({
	...createStyles({
		cover: {
			marginBottom: '50px',
			'& .MuiBox-root': {
				aspectRatio: '19/5',
				backgroundSize: 'cover'
			},
			'& .MuiButton-contained': { aspectRatio: '12/3' }
		},
		about: {
			marginTop: '50px'
		},
		column: {
			display: 'flex',
			flexDirection: 'column'
		},
		videoBox: {
			height: '100%'
		}
	}),
	boxPlaylist: {
		padding: 0,
		'& .listItem': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

export default usePartnerPageStyles
