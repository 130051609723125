import { Box, Typography } from '@material-ui/core'
import AssignmentReturned from '@material-ui/icons/AssignmentReturned'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import { EditIcon } from 'src/assets/icons'
import { AddToClassButton } from 'src/components/Buttons/AddToClassButton'
import { BaseLink } from 'src/components/Buttons/BaseLink'
import { IconButton } from 'src/components/Buttons/IconButton'
import { BaseDialogProps, BaseDialog } from 'src/components/Dialogs/BaseDialog/BaseDialog'
import { ReactCustomVideoPlayer } from 'src/components/ReactCustomVideoPlayer/ReactCustomVideoPlayer'
import { Tip } from 'src/graphql/autogenerate/schemas'
import { buildImagePath } from 'src/utils'

import useStyles from './VideoDialog.styles'

export type VideoDialogProps = BaseDialogProps & {
	video: Pick<Tip, 'tip_id' | 'title' | 'resource_path' | 'description' | 'image_path'>
	genres: string[]
	onDelete?: () => void
	onEdit?: () => void
	onAddToClass?: () => void
	assetUrl?: string
}

export const VideoDialog = ({
	video,
	genres,
	onDelete,
	onEdit,
	onAddToClass,
	assetUrl,
	...props
}: VideoDialogProps) => {
	const styles = useStyles()

	return (
		<BaseDialog
			maxWidth="md"
			dividers={false}
			{...props}
			paperProps={{
				className: styles.paper
			}}
			bodyProps={{
				className: styles.body
			}}
			header={
				<>
					<Typography variant="h4" className={styles.title}>
						<strong>{video.title}</strong>
					</Typography>
					<Typography className={styles.subtitle}>{video.description}</Typography>
					{!!genres?.length && (
						<Typography className={clsx(styles.genres, styles.ellipsis)}>
							• {genres.join(', ')}
						</Typography>
					)}
				</>
			}
			body={
				<ReactCustomVideoPlayer
					controls
					config={{
						file: {
							attributes: {
								controlsList: 'nodownload'
							}
						}
					}}
					width="575px"
					style={{ maxHeight: 400 }}
					preload={1}
					light={video?.image_path}
					url={video?.resource_path}
				/>
			}
			rightActions={
				<Box className={styles.rightActions}>
					{assetUrl && (
						<BaseLink target="_blank" href={buildImagePath(assetUrl)} download>
							<IconButton
								className={clsx(styles.margin, styles.actionButton)}
								tooltipTitle="Download Learning Resource"
								text={''}>
								<AssignmentReturned />
							</IconButton>
						</BaseLink>
					)}

					<IconButton
						tooltipTitle="Edit video"
						onClick={onEdit}
						className={styles.actionButton}
						text={''}>
						<EditIcon />
					</IconButton>
					<IconButton
						className={clsx(styles.margin, styles.actionButton)}
						tooltipTitle="Delete video"
						onClick={onDelete}
						text={''}>
						<DeleteIcon />
					</IconButton>
					<AddToClassButton
						tooltipTitle="Add to class"
						onClick={onAddToClass}
						className={styles.actionButton}
					/>
				</Box>
			}
			discardLabel="Close"
			onDiscardProps={{
				className: styles.discardButton
			}}
		/>
	)
}
