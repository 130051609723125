import React, { useEffect, useState } from 'react'

import { Box, List, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { PagesProps } from 'src/@types'
import { AddSequencesDialog } from 'src/components/Dialogs/AddSequenceDialog'
import { MutationDialog } from 'src/components/MutationDialog/MutationDialog'

import {
	AccordionWrapper,
	DeleteItemDialog,
	BaseLoading,
	MainTitle,
	SelectSort
} from '../../../../../../../components'
import {
	useDeleteClassSequenceMutation,
	useGetClassTeacherByPkQuery,
	useGetClassSequenceLazyQuery
} from '../../../../../../../graphql/autogenerate/hooks'
import { Order_By, Sequence } from '../../../../../../../graphql/autogenerate/schemas'
import { useLoginContext } from '../../../../../../../hooks/useLogin'
import { useScroll } from '../../../../../../../hooks/useScroll'
import { useWurrlyParams } from '../../../../../../../hooks/useWurrlyParams'
import { Pages } from '../../../../../../../routes/teacherPages'
import { SortOrder } from '../../../../../../../utils'
import { buildRouteParameters } from '../../../../../../../utils/routes-utils'
import { buildBreadCrumbs, getCyKey } from '../../../../../../../utils/utils'
import { ListItemModule } from '../../../../6-Lessons/components'

const useStyles = makeStyles((theme: Theme) => ({
	boxList: {
		padding: 0,
		'& .listItem': {
			backgroundColor: (spacing: number) => (spacing > 0 ? theme.palette.common.white : undefined),
			'&:not(:last-child)': {
				marginBottom: (spacing: number) => theme.spacing(spacing)
			}
		}
	}
}))

export const ClassSequenceItems = ({ page }: PagesProps) => {
	useScroll()
	const history = useHistory()
	const styles = useStyles(1.5)
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const params = useWurrlyParams<typeof Pages.ClassModules.params[number]>()
	const { classId } = params
	const { data: classData } = useGetClassTeacherByPkQuery({
		variables: {
			teacherId,
			classId
		}
	})

	const [openSuccess, setOpenSuccess] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [limitSequenceItems] = useState<number | undefined>(undefined)
	const [sortSequenceItems, setSortSequenceItems] = useState(SortOrder.Up)
	const [getInitialSequences, { data: sequenceItemsData, loading, refetch }] = useGetClassSequenceLazyQuery()

	const sequenceItems = (sequenceItemsData?.class_by_pk?.class_sequences.map((el) => el.sequence) ||
		[]) as Sequence[]
	const count = sequenceItemsData?.class_by_pk?.class_sequences_aggregate.aggregate?.count || 0
	const initialSequences = sequenceItemsData?.class_by_pk?.class_sequences.map((el) => el.sequence) || []
	const [sequenceItemIdToDelete, setSequenceItemIdToDelete] = useState<number>()
	const [addSequence, setAddSequence] = useState(false)
	const [deleteClassSequenceItem] = useDeleteClassSequenceMutation()

	const breadcrumbs = buildBreadCrumbs({
		page,
		replaceIndexes: [
			{
				index: 1,
				overrideName: classData?.teacher_by_pk?.class_teachers[0]?.class?.title
			},
			{
				index: 2,
				overrideName: 'Lessons'
			},
			{
				index: 3,
				overrideName: 'Sequences'
			}
		],
		params
	})

	useEffect(() => {
		getInitialSequences({
			variables: {
				classId,
				limit: limitSequenceItems
			}
		})
	}, [])

	useEffect(() => {
		refetch({
			classId,
			limit: limitSequenceItems,
			orderTitle:
				sortSequenceItems !== SortOrder.Recent
					? sortSequenceItems !== SortOrder.Down
						? Order_By.Asc
						: Order_By.Desc
					: undefined
		})
	}, [sortSequenceItems, limitSequenceItems])

	const handleSort = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>
	) => {
		const selectedSort = event.target.value as SortOrder
		setSortSequenceItems(selectedSort)
	}

	const handleSelectOne = (sequenceItemId: number) => {
		history.push(
			buildRouteParameters(
				Pages.ClassSequenceItemDetail, // Route with paramters
				{
					classId,
					sequenceItemId
				}
			)
		)
	}

	return (
		<Box pt={3}>
			{loading && <BaseLoading />}

			{!loading && (
				<Box>
					<MainTitle
						breadcrumbs={breadcrumbs}
						title={'Class Sequences'}
						description={'These are the Sequences that have been added to this Class.'}
						rightActions={[
							{
								variant: 'contained',
								color: 'secondary',
								startIcon: <AddIcon />,
								name: 'Add Sequence',
								onClick: () => {
									setAddSequence(true)
								},
								isComingSoon: true
							}
						]}
					/>
					<AccordionWrapper
						defaultExpanded
						summary={
							<Box width="100%" display="flex" flexWrap="wrap" alignItems="center">
								<Typography variant="h6">
									<b>Saved Sequences ({count})</b>
								</Typography>
								<div style={{ flexGrow: 1 }} />

								<SelectSort
									cyKey={getCyKey(ClassSequenceItems)}
									value={sortSequenceItems}
									onChange={handleSort}
								/>
							</Box>
						}
						details={
							<List className={styles.boxList}>
								{sequenceItems.map((sequence) => (
									<Box key={sequence.sequence_id} className="listItem">
										<ListItemModule
											module_id={sequence.sequence_id}
											imageUrl={sequence.image_path ?? ''}
											title={sequence.name}
											description={sequence.description}
											genres={`•`}
											totallessons={sequence.sequence_lesson_plans.length}
											onClick={() => handleSelectOne(sequence.sequence_id)}
											btnStandard={{}}
											btnRemove={{
												onClick: (e) => {
													e.stopPropagation()
													setSequenceItemIdToDelete(sequence.sequence_id)
												}
											}}
											btnView={{
												onClick: (e) => {
													e.stopPropagation()
													handleSelectOne(sequence.sequence_id)
												}
											}}
										/>
									</Box>
								))}
							</List>
						}
					/>
				</Box>
			)}
			<DeleteItemDialog
				itemName="Sequence"
				optionalMessages={{
					mainMessages: {
						title: 'Delete this Sequence from your Class?',
						body: 'You’ll be able to re-add it from the Lessons page at any time.',
						buttonLabel: ' Delete Sequence'
					},
					confirmation: {
						title: 'Sequence Deleted!',
						body: 'You have successfully deleted this Sequence from your Class.'
					}
				}}
				isOpen={!!sequenceItemIdToDelete}
				setIsOpen={setSequenceItemIdToDelete}
				onConfirm={async () => {
					if (sequenceItemIdToDelete) {
						await deleteClassSequenceItem({
							variables: {
								classId,
								sequenceItemId: sequenceItemIdToDelete
							},
							update: (cache, { data }) => {
								const classToUpdateInCache = data?.delete_class_sequence?.returning[0].class
								if (!classToUpdateInCache) return
								const identify = cache.identify(classToUpdateInCache)
								cache.evict({
									id: identify,
									fieldName: 'class_sequence_items'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_topic'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_topic_by_pk'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence'
								})
								cache.evict({
									id: 'ROOT_QUERY',
									fieldName: 'sequence_by_pk'
								})
							}
						})
					}
				}}
			/>
			<AddSequencesDialog
				classId={classId}
				open={addSequence}
				setOpenSuccess={setOpenSuccess}
				setOpenError={setOpenError}
				setOpen={setAddSequence}
				initialSelection={initialSequences as Sequence[]}
			/>

			<MutationDialog
				open={false}
				error={openError}
				success={openSuccess}
				icon="success"
				successProps={{
					title: `Success!`,
					body: `You have succesfully added this sequences.`,
					confirmButton: {
						onClick: () => {
							setOpenSuccess(false)
							window.location.reload()
						},
						label: 'Confirm'
					}
				}}
				errorProps={{
					title: 'Something went wrong',
					body: `Something went wrong while added Sequences.`,
					icon: 'error',
					confirmButton: {
						onClick: () => {
							setOpenError(false)
							window.location.reload()
						},
						label: 'Confirm'
					}
				}}
			/>
		</Box>
	)
}
