import { createStyles, makeStyles } from '@material-ui/core'

export const useAssignmentSortStyles = makeStyles(() =>
	createStyles({
		root: {
			minWidth: '140px',
			height: '40px',
			'& .MuiOutlinedInput-input': {
				padding: '10px 14px'
			}
		},
		label: { marginRight: '10px' },
		select: { minWidth: '140px', height: '40px' },
		holder: {
			display: 'flex',
			alignItems: 'center'
		}
	})
)

export default useAssignmentSortStyles
