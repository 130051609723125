import { articleSchema, limit } from 'src/scenes/Teacher/scenes/6-Lessons/utils/constants'
import * as yup from 'yup'

export const useStepThreeValidation = () => {
	const stepThreeValidationSchema = yup.object({
		stepThreeArticles: yup.array().of(articleSchema).required('Articles are required')
	})

	return { stepThreeValidationSchema, limit }
}
