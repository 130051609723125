import { Box, CircularProgress, FormControlLabel, Switch } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { QueryCatalogItemsType } from 'src/@types/types'
import { Catalogs, Search } from 'src/components'
import { SelectChord } from 'src/components/SelectChord'
import { Song } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { SongListAndFilter } from 'src/scenes/Student/scenes/6-Assignments/scenes/Details/components/StepThreeForm/components/WholeCatalog/components/SongListAndFilter/SongListAndFilter'
import { TypeEnum } from 'src/utils'

import { SongsTitle } from './components/SongsTitle/SongsTitle'
import { useWholeCatalogContext } from './useWholeCatalogContext'
import useStyles from './WholeCatalog.styles'

export type WholeCatalogProps = {
	classId: number
	overrideSongOnClick: (songId: number) => void
}

export const WholeCatalog = ({ classId, overrideSongOnClick }: WholeCatalogProps) => {
	const { studentData } = useLoginContext()
	const {
		moreResults,
		searchString,
		setSearchString,
		showCatalog,
		chords,
		setChords,
		catalogs,
		setSelectedCatalog,
		songs,
		showTransposed,
		toggleShowTransposed,
		sortResults,
		setSortResults,
		count,
		toggleChordGuide,
		toggleVocalGuide,
		withVocalGuide,
		withChordGuide,
		selectedFilters,
		setSelectedFilters,
		scale,
		setScale,
		isLoading,
		fetchMore,
		selectedCatalog
	} = useWholeCatalogContext()
	const styles = useStyles({ showTransposed })

	const innerOverrideSongOnClick = (_classId: number, songId: number) => {
		overrideSongOnClick(songId)
	}

	return (
		<Box>
			<Search
				teacherId={NaN}
				classId={classId}
				studentId={studentData.student_id}
				onChange={setSearchString}
				type={TypeEnum.Song}
				textSearch={searchString}
				overrideSongClick={innerOverrideSongOnClick}
			/>
			<Box className={styles.titleContainer}>
				<Typography variant="h6" className={styles.title}>
					Browse by Chords
				</Typography>
				<FormControlLabel
					control={<Switch checked={showTransposed} onChange={toggleShowTransposed} />}
					label={<Typography className={styles.transposeLabel}>Show Transposed Results</Typography>}
				/>
			</Box>
			<SelectChord onChange={setChords} selectedChords={chords} />

			{showCatalog && !catalogs.loading && catalogs.data && (
				<Catalogs
					isTeacher={false}
					data={catalogs.data as QueryCatalogItemsType}
					parentPage="song"
					overrideCatalogOnClick={setSelectedCatalog}
					classId={classId}
				/>
			)}
			{showCatalog && catalogs.loading && (
				<Box textAlign="center" m={5}>
					<CircularProgress color="secondary" />
				</Box>
			)}
			{!showCatalog && songs && (
				<>
					<SongsTitle
						sort={sortResults}
						handleSort={setSortResults}
						count={count}
						isLoading={isLoading}
					/>
					<SongListAndFilter
						chords={chords}
						songs={songs as Song[]}
						showTransposed={showTransposed}
						toggleChordGuide={toggleChordGuide}
						toggleVocalGuide={toggleVocalGuide}
						withVocalGuide={withVocalGuide}
						withChordGuide={withChordGuide}
						selectedFilters={selectedFilters}
						setSelectedFilters={setSelectedFilters}
						scale={scale}
						setScale={setScale}
						isLoading={isLoading}
						overrideSongClick={innerOverrideSongOnClick}
						fetchMore={fetchMore}
						selectedCatalog={selectedCatalog}
						searchString={searchString}
						moreResults={moreResults}
						classId={classId}
					/>
				</>
			)}
		</Box>
	)
}
