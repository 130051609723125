import { useEffect, useState } from 'react'

import { Avatar, Box, Divider, Grid, Paper, Typography } from '@material-ui/core'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import { useLocation } from 'react-router-dom'
import { WurrlyYellowLogo } from 'src/assets/icons/CustomIcons'
import { ActionButton } from 'src/components'
import { useGetLinkMetaDataLazyQuery } from 'src/graphql/autogenerate/hooks'
import { Lesson_Plan } from 'src/graphql/autogenerate/schemas'
import { BASE_URL, buildImagePath } from 'src/utils'

type LessonLocationType = {
	lesson: Lesson_Plan
}

/**
 *
 * @deprecated It is not used anymore because the PDF is generated in the backend by GetLessonPdf
 */
export const LessonPDF = () => {
	const [loadMetadata, { data: urlMetaData, loading: urlMetaDataLoading, error: urlMetaDataError }] =
		useGetLinkMetaDataLazyQuery()
	const [imageUrl, setImageUrl] = useState('')
	const location = useLocation()
	const state = location.state as LessonLocationType
	const lesson = state.lesson

	useEffect(() => {
		const startAt = lesson.inspire?.indexOf('<figure class="media">') ?? 0
		const endAt = lesson.inspire?.indexOf('</figure>') ?? 0
		const tagVideo = lesson.inspire?.substring(startAt, endAt + 9) ?? ''
		const videoUrl = tagVideo
			.replace('<figure class="media"><oembed url="', '')
			.replace('"></oembed></figure>', '')
		loadMetadata({ variables: { filter: { link: videoUrl } } })
	}, [])

	useEffect(() => {
		if (!urlMetaDataLoading && urlMetaData) {
			const image = urlMetaData.linkMetaData?.images ? (urlMetaData.linkMetaData?.images[0] as string) : ''
			const startAt = lesson.inspire?.indexOf('<figure class="media">') ?? 0
			const endAt = lesson.inspire?.indexOf('</figure>') ?? 0
			const tagVideo = lesson.inspire?.substring(startAt, endAt + 9) ?? ''
			setImageUrl(
				lesson.inspire?.replace(tagVideo, `<img style="width:100%; height:auto;" src = "${image}" />`) ??
					''
			)
		} else if (urlMetaDataError) {
		}
	}, [urlMetaData])

	return (
		<Box>
			<Box mb={3}>
				<Box mt={4} mb={1} display="flex" flexWrap="wrap">
					<Box width="500px">
						<Typography variant="h3">
							<b>{lesson.title}</b>&nbsp;
						</Typography>
					</Box>
					<div style={{ flexGrow: 1 }} />
					<Box display="flex">
						<Box marginTop="10px" marginRight="15px">
							<WurrlyYellowLogo />
						</Box>
						<Box></Box>
					</Box>
				</Box>
				<Typography style={{ fontSize: 18, maxWidth: 500 }}>{lesson.description}</Typography>
			</Box>
			<Typography color="textSecondary">
				Keywords: {lesson.lesson_plan_keyword.map((keyword) => keyword.keyword.name).join(', ')}
			</Typography>
			<Grid container spacing={3} style={{ marginTop: 40 }}>
				<Grid item xs>
					<Paper>
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>Inspire</b>
							</Typography>
							<Box marginTop="10px" dangerouslySetInnerHTML={{ __html: imageUrl }} />
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>PRACTICE</b>
							</Typography>
							<Box marginTop="10px" dangerouslySetInnerHTML={{ __html: lesson.practice ?? '' }} />
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>RECORD</b>
							</Typography>

							<Box marginTop="10px" dangerouslySetInnerHTML={{ __html: lesson.record ?? '' }} />
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>REFLECT</b>
							</Typography>

							<Box marginTop="10px" dangerouslySetInnerHTML={{ __html: lesson.reflect ?? '' }} />
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>SUGGESTED ASSIGNMENT</b>
							</Typography>

							<Box
								marginTop="10px"
								dangerouslySetInnerHTML={{ __html: lesson.suggested_assignments ?? '' }}
							/>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>TAKE IT FURTHER</b>
							</Typography>
							<Box
								marginTop="10px"
								dangerouslySetInnerHTML={{ __html: lesson.take_it_further ?? '' }}
							/>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>DIFFERENTIATIONS</b>
							</Typography>
							<Box
								marginTop="10px"
								dangerouslySetInnerHTML={{ __html: lesson.differentiations ?? '' }}
							/>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography color="secondary" variant="h6">
								<b>MATERIALS NEEDED</b>
							</Typography>
							<Box
								marginTop="10px"
								dangerouslySetInnerHTML={{ __html: lesson.materials_needed ?? '' }}
							/>
						</Box>
						<Divider />
					</Paper>
				</Grid>
				<Grid item xs={12} md="auto">
					<Paper>
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Levels</b>
							</Typography>
							<Box maxWidth="150px">
								{lesson.lesson_plan_grade.map((lesson_plan_grade) => (
									<Typography
										key={lesson_plan_grade.lesson_plan_grade_id}
										style={{ margin: '10px 0' }}
										color="textSecondary"
										variant="body2">
										<b>{lesson_plan_grade.grade.name}</b>
									</Typography>
								))}
							</Box>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Musical Skills</b>
							</Typography>
							<Box maxWidth="150px">
								{lesson.lesson_plan_musical_skill.map((lesson_plan_musical_skill) => (
									<Typography
										key={lesson_plan_musical_skill.musical_skill_id}
										style={{ margin: '10px 0' }}
										color="textSecondary"
										variant="body2">
										<b>{lesson_plan_musical_skill.musical_skill.name}</b>
									</Typography>
								))}
							</Box>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Transferable Skills</b>
							</Typography>
							<Box maxWidth="150px">
								{lesson.lesson_plan_non_musical_skill.map((lesson_plan_non_musical_skill) => (
									<Typography
										key={lesson_plan_non_musical_skill.non_musical_skill_id}
										style={{ margin: '10px 0' }}
										color="textSecondary"
										variant="body2">
										<b>{lesson_plan_non_musical_skill.non_musical_skill.name}</b>
									</Typography>
								))}
							</Box>
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Songs</b>
							</Typography>
							{lesson.lesson_plan_song.length > 0 ? (
								<Box display="flex" alignItems="center">
									<Box marginRight="10px">
										<Avatar
											id="song-img"
											variant="rounded"
											src={buildImagePath(lesson.lesson_plan_song[0].song.image_path)}
										/>
									</Box>
									<Box display="grid" maxWidth="150px">
										<Typography variant="caption">
											<b>{lesson.lesson_plan_song[0].song.title}</b>
										</Typography>
										<Typography variant="caption" color="textSecondary">
											<b>{lesson.lesson_plan_song[0]?.song.artist?.name ?? ''}</b>
										</Typography>
									</Box>
								</Box>
							) : null}
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Videos</b>
							</Typography>
							{lesson.lesson_plan_tip.length > 0 ? (
								<Box display="flex" alignItems="center">
									<Box marginRight="10px">
										<Avatar
											variant="rounded"
											src={buildImagePath(lesson.lesson_plan_tip[0].tip.image_path)}
										/>
									</Box>
									<Box maxWidth="150px">
										<Typography variant="caption">
											<b>{lesson.lesson_plan_tip[0].tip.title}</b>
										</Typography>
									</Box>
								</Box>
							) : null}
						</Box>
						<Divider />
						<Box padding="20px">
							<Typography variant="subtitle1">
								<b>Assets</b>
							</Typography>
							{lesson.lesson_plan_asset.length > 0 ? (
								<Box display="flex" alignItems="center">
									<Box marginRight="15px">
										<ActionButton
											onClick={() => {
												const link = document.createElement('a')
												link.href = `${BASE_URL}${lesson.lesson_plan_asset[0].asset.resource_path}`
												link.target = '_blank'
												// Append to html link element page
												document.body.appendChild(link)
												// Start download
												link.click()
												// Clean up and remove the link
												link.parentNode?.removeChild(link)
											}}
											color="secondary"
											icon={<VerticalAlignBottomIcon />}
										/>
									</Box>
									<Box maxWidth="150px">
										<Typography variant="caption">
											<b>{lesson.lesson_plan_asset[0].asset.name}</b>
										</Typography>
									</Box>
								</Box>
							) : null}
						</Box>
						<Divider />
					</Paper>
				</Grid>
			</Grid>
		</Box>
	)
}
