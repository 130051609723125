import { Filter_Color_Name_Enum } from 'src/graphql/autogenerate/schemas'
import { DIVA_FILTER_BORDER } from 'src/utils'

import { getCssFilter } from '../Studio/Preview/ColorFilters'

const TO_RADIANS = Math.PI / 180

export const addRotatedImage = async (
	context: CanvasRenderingContext2D,
	image: { url: string; width: number; height: number; rotation: number; x: number; y: number }
) => {
	// save the current co-ordinate system
	// before we screw with it
	context.save()
	// Create image object
	const rotatedImage = await addImage(image.url, image.width, image.height)

	context?.translate(image.x + image.width / 2, image.y + image.height / 2)
	context?.rotate(image.rotation * TO_RADIANS)
	context?.drawImage(rotatedImage, (image.width / 2) * -1, (image.height / 2) * -1, image.width, image.height)
	context?.rotate(-image.rotation * TO_RADIANS)
	context?.translate((image.x + image.width / 2) * -1, (image.y + image.height / 2) * -1)
}

export const applyColorFilter = async (
	context: CanvasRenderingContext2D,
	canvas: { width: number; height: number },
	colorFilter: Filter_Color_Name_Enum | undefined,
	drawImage: () => void
) => {
	if (!colorFilter) return
	context.filter = getCssFilter(colorFilter)
	drawImage()
	if (colorFilter === Filter_Color_Name_Enum.Diva) {
		context.filter = 'none'
		const borderImage = await addImage(DIVA_FILTER_BORDER, canvas.width, canvas.height)
		context.drawImage(borderImage, 0, 0, canvas.width, canvas.height)
	}
	if (colorFilter === Filter_Color_Name_Enum.Vintage) {
		context.fillStyle = 'rgb(0, 7, 93,0.72)'
		context.globalCompositeOperation = 'exclusion'
		context.fillRect(0, 0, canvas.width, canvas.height)
		context.globalCompositeOperation = 'source-over'
	}
	context.filter = 'none'
}

export const addImage = (src: string, width: number, height: number): Promise<HTMLImageElement> => {
	return new Promise((res, rej) => {
		const image = new Image()
		image.onerror = rej
		image.onload = () => res(image)
		image.crossOrigin = 'anonymous'
		image.src = src
		image.width = width
		image.height = height
	})
}
