import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { useLocation } from 'react-router-dom'
import { StudioReviewLocationState } from 'src/@types'
import { StudioStep } from 'src/components/Studio/constants'
import { buildGenericContext } from 'src/context/genericContext'
import { Wurrly_Type_Enum } from 'src/graphql/autogenerate/schemas'
import { UploadThumbnailProps } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { getPresignedUploadUrl, PresignedUrlType } from 'src/scenes/Teacher/mutations'
import { buildQueryVariables, FileTypeEnum, getAudioBlob, getBlob } from 'src/utils'

type UploadVideoProps = UploadThumbnailProps & {
	key: string
}

const useReviewAndSubmit = () => {
	const location = useLocation()
	// Shared
	const [step, setStep] = useState<StudioStep>(StudioStep.Review)
	const [audioUrl, setAudioUrl] = useState('')
	const [videoUrl, setVideoUrl] = useState('')
	const [trackId, setTrackId] = useState(0)
	const [isVideoOn, setIsVideoOn] = useState(true)
	const [selectedThumbnail, setSelectedThumbnail] = useState('')
	const [audioBalance, setAudioBalance] = useState(0)
	const [classId, setClassId] = useState<number>(0)
	const [submissionId, setSubmissionId] = useState<number>(0)
	const [challengeId, setChallengeId] = useState<number | undefined>(undefined)
	const [wurrlyType, setWurrlyType] = useState<Wurrly_Type_Enum>(Wurrly_Type_Enum.Indie)

	// Submit
	const [shouldUploadVideo, setShouldUploadVideo] = useState(false)
	const [videoBlobToUpload, setVideoBlobToUpload] = useState<Blob>()
	const [uploadError, setUploadError] = useState(false)
	const [isVideoUploading, setIsVideoUploading] = useState(false)
	const [videoUploadProgress, setVideoUploadProgress] = useState(0)
	const [uploadedVideoKey, setUploadedVideoKey] = useState<string>()
	const [getVideoPresignedUrl, videoPresignedUploadUrl] = useLazyQuery<PresignedUrlType>(getPresignedUploadUrl, {
		fetchPolicy: 'no-cache'
	})
	const [cancelUploadToken, setCancelUploadToken] = useState<CancelTokenSource>()
	const [presignedUrlResult, setPresignedUrlResult] = useState<{ url: string; key: string }>()

	const uploadVideo = async ({ presignedUrl, key, blob }: UploadVideoProps) => {
		setIsVideoUploading(true)
		setVideoUploadProgress(0)
		const cancelToken = axios.CancelToken.source()
		setCancelUploadToken(cancelToken)
		try {
			const options: AxiosRequestConfig = {
				method: 'put',
				data: blob,
				url: presignedUrl,
				cancelToken: cancelToken.token,
				headers: {
					'Content-Type': blob.type
				},
				onUploadProgress: ({ loaded, total }) => {
					const percent = Math.round((loaded / total) * 100)
					setVideoUploadProgress(percent)
				}
			}
			const result = await axios.request(options)
			setCancelUploadToken(undefined)

			if (result.status === 200) {
				setUploadedVideoKey(key)
			} else {
				console.error('Could not upload video', { result })
				setUploadError(true)
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.error('error uploading video', { error })
				setUploadError(true)
			}
		}
		setIsVideoUploading(false)
	}

	// Get the recorded video in Blob format, ready to upload
	useEffect(() => {
		const fetchVideo = async () => {
			setPresignedUrlResult(undefined)
			// Cancel previous upload (used if user switches video off)
			if (isVideoUploading && cancelUploadToken) {
				cancelUploadToken.cancel()
			}
			try {
				let blob: Blob
				if (isVideoOn) {
					blob = await getBlob(videoUrl)
				} else {
					blob = await getAudioBlob(videoUrl)
				}
				setVideoBlobToUpload(blob)
			} catch (error) {
				console.error("Couldn't get wurrly blob", error)
				setUploadError(true)
			}
		}
		if (videoUrl && shouldUploadVideo) fetchVideo()
	}, [videoUrl, shouldUploadVideo, isVideoOn])

	// Get the presigned url for the video upload
	useEffect(() => {
		if (videoBlobToUpload) {
			getVideoPresignedUrl(
				buildQueryVariables({
					filter: {
						type: FileTypeEnum.Wurrly,
						conType: videoBlobToUpload.type,
						ext: videoBlobToUpload.type.split('/')[1],
						isTemporal: false
					}
				})
			)
		}
	}, [videoBlobToUpload])

	// Trigger the video upload to S3 when the presigned url is ready
	useEffect(() => {
		if (presignedUrlResult && videoBlobToUpload) {
			const { url: presignedUrl, key } = presignedUrlResult
			uploadVideo({ presignedUrl, key, blob: videoBlobToUpload })
		}
	}, [presignedUrlResult, videoBlobToUpload])

	// Save the presigned url result to a separate value so it can be set to `undefined` and delay the re-upload until we get a new url
	useEffect(() => {
		if (!videoPresignedUploadUrl.loading && videoPresignedUploadUrl.data) {
			setPresignedUrlResult(videoPresignedUploadUrl.data.presignedUrl)
		}
	}, [videoPresignedUploadUrl])

	// Get classId, submissionId, and wurrlyType from location.state
	useEffect(() => {
		if (!location.state) return

		const {
			classId: stateClassId,
			submissionId: stateSubmissionId,
			wurrlyType: stateWurrlyType,
			challengeId: stateChallengeId
		} = location.state as StudioReviewLocationState
		setClassId(stateClassId ?? 0)
		setSubmissionId(stateSubmissionId ?? 0)
		setWurrlyType(stateWurrlyType ?? Wurrly_Type_Enum.Indie)
		setChallengeId(stateChallengeId ?? undefined)
	}, [location.state])

	// Cleanup, cancel upload if the user leaves before the upload is complete
	useEffect(() => {
		return () => {
			if (cancelUploadToken) cancelUploadToken.cancel()
		}
	}, [])

	return {
		step,
		setStep,
		audioUrl,
		setAudioUrl,
		videoUrl,
		setVideoUrl,
		trackId,
		setTrackId,
		isVideoOn,
		setIsVideoOn,
		selectedThumbnail,
		setSelectedThumbnail,
		audioBalance,
		setAudioBalance,
		uploadedVideoKey,
		setUploadError,
		uploadError,
		isVideoUploading,
		videoUploadProgress,
		setShouldUploadVideo,
		classId,
		submissionId,
		challengeId,
		wurrlyType
	}
}

export const [ReviewAndSubmitProvider, useReviewAndSubmitContext] = buildGenericContext(useReviewAndSubmit)
