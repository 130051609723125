import { useEffect, useRef, useState } from 'react'

import { Box, Tooltip, Typography } from '@material-ui/core'
import { VideocamOff } from '@material-ui/icons'
import { FlipIcon } from 'src/assets/icons'
import { ActionButton, InfoDialog } from 'src/components'
import { VideoPreview } from 'src/components/Studio/Preview/VideoPreview'
import { EffectsTabs } from 'src/components/Studio/Review/effects/EffectsTabs/EffectsTabs'
import { useStudioReviewContext } from 'src/components/Studio/Review/useStudioReview'
import { TrimmerScrubber } from 'src/components/VideoControls/TrimmerScrubber'
import { SelectThumbnailCarousel } from 'src/scenes/Student/scenes/StudentRecord/components/SelectThumbnail/SelectThumbnailCarousel'
import { useVideoSubmitContext } from 'src/scenes/Student/scenes/StudentRecord/scenes/VideoSubmitDialog/useVideoSubmit'
import { getOffset } from 'src/utils'

import { useStudioStyles } from '../../../Record/styles/studioStyles'
import { AppliedStickers } from '../AppliedStickers/AppliedStickers'

export const VideoReview = () => {
	const [isNoVideoDialogOpen, setIsNoVideoDialogOpen] = useState(false)
	const styles = useStudioStyles()

	const containerRef = useRef<HTMLDivElement>(null)
	const {
		isVideoOn,
		toggleVideo,
		videoElement,
		videoState,
		audioElement,
		mediaPercentage,
		seekMedia,
		trimPercentage,
		handleTrimValues,
		videoUrl,
		isApplyingEffects,
		isScrubberVisible,
		closeScrubber,
		closeEffects
	} = useStudioReviewContext()
	const {
		isMirrored,
		setIsMirrored,
		selectedFrameFilter,
		setCanvasDimensions,
		selectedStickerFilter,
		updateVideoDimensions,
		selectedColorFilter
	} = useVideoSubmitContext()
	const openNoVideoDialog = () => {
		setIsNoVideoDialogOpen(true)
	}
	const closeNoVideoDialog = () => {
		setIsNoVideoDialogOpen(false)
	}
	const audioOnly = () => {
		toggleVideo()
		closeNoVideoDialog()
	}
	const handleMirrorSelect = () => setIsMirrored((prevState) => !prevState)

	useEffect(() => {
		const current = containerRef?.current
		if (updateVideoDimensions && current) {
			const { top, left } = getOffset(current)
			updateVideoDimensions({
				width: current.clientWidth,
				height: current.clientHeight,
				offsetTop: top,
				offsetLeft: left
			})
		}
	}, [containerRef.current])

	return (
		<>
			<div ref={containerRef} className={styles.videoContainer} onDragOver={(e) => e.preventDefault()}>
				{isVideoOn ? (
					<>
						<ActionButton
							onClick={openNoVideoDialog}
							className={styles.recordBtn}
							icon={<VideocamOff />}
						/>
						<Tooltip title="Mirror video">
							<ActionButton
								onClick={handleMirrorSelect}
								className={styles.mirrorBtn}
								icon={<FlipIcon className={isMirrored ? styles.mirrorIconSelected : ''} />}
							/>
						</Tooltip>
					</>
				) : (
					<SelectThumbnailCarousel />
				)}
				<Box style={{ display: isVideoOn ? 'initial' : 'none' }}>
					<VideoPreview
						videoElement={videoElement}
						isMirrored={isMirrored}
						selectedFrameFilter={selectedFrameFilter}
						selectedColorFilter={selectedColorFilter?.name}
					/>
					<AppliedStickers
						boxProps={{ className: styles.stickers }}
						setCanvasDimensions={setCanvasDimensions}
					/>
				</Box>

				<Box style={{ display: 'none' }}>{audioElement}</Box>

				<TrimmerScrubber
					scrubberProps={{ currentValue: mediaPercentage, setValue: seekMedia }}
					trimmerProps={{ currentValues: trimPercentage, setValues: handleTrimValues }}
					scrubberBgProps={{
						videoUrl,
						showWaveform: !isVideoOn,
						mediaLength: videoState.duration
					}}
					boxProps={{ className: isScrubberVisible ? undefined : styles.hidden }}
					hideTab={closeScrubber}
				/>
				<EffectsTabs
					className={isApplyingEffects ? undefined : styles.hidden}
					stickersSelected={selectedStickerFilter.length}
					hideTab={closeEffects}
				/>
			</div>
			<InfoDialog
				open={isNoVideoDialogOpen}
				onClose={closeNoVideoDialog}
				icon="?"
				title={<Typography variant="h4">Not a fan of your video?</Typography>}
				body="Save the audio only!"
				discardLabel="Cancel"
				onDiscard={closeNoVideoDialog}
				confirmLabel="Audio Only"
				onConfirm={audioOnly}
			/>
		</>
	)
}
