import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export type BaseButtonProps = ButtonProps & { isLoading?: boolean }
export const BaseButton = ({
	variant = 'contained',
	color,
	children,
	isLoading = false,
	...props
}: BaseButtonProps) => {
	const theme = useTheme()

	const getColor = (color?: 'inherit' | 'primary' | 'secondary' | 'default') => {
		if (color === 'primary' && theme.palette.type === 'dark') return 'default'
		else return color
	}

	return (
		<Button variant={variant} color={getColor(color)} {...props}>
			{children}
			{isLoading && <CircularProgress color="secondary" />}
		</Button>
	)
}
