import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { SubscriptionsCardType } from 'src/@types'
import {
	useCancelProductSubscriptionMutation,
	useGetPartnersLazyQuery,
	useGetTeacherSubscriptionsLazyQuery,
	useSearchPartnerLazyQuery
} from 'src/graphql/autogenerate/hooks'
import {
	CancelProductSubscriptionMutation,
	GetTeacherSubscriptionsQuery
} from 'src/graphql/autogenerate/operations'
import {
	Order,
	Order_Status_Enum,
	Partner,
	Price_Type_Enum,
	Product_Name_Enum
} from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { Pages } from 'src/routes/teacherPages'
import {
	MAX_LIST_ITEMS,
	MAX_SEARCH_ITEMS,
	SortOrder,
	buildBreadCrumbs,
	buildRouteParameters,
	buildSearchText,
	createSuscriptionCardData,
	orderBySortOrder
} from 'src/utils'

export const usePartners = () => {
	const history = useHistory()
	const [sort, setSort] = useState(SortOrder.Up)
	const [filter, setFilter] = useState('None')
	const [isFetching, setIsFetching] = useState(false)
	const [moreResults, setMoreResults] = useState(true)
	const [partners, setPartners] = useState<Partner[]>([])
	const [partnerSuscriptions, setPartnerSuscriptions] = useState<SubscriptionsCardType[]>([])
	const [searchbarText, setSearchbarText] = useState('')
	const [isOpen, setIsOpen] = useState(false)
	const [partnerId, setPartnerId] = useState<number>()
	const [totalPartners, setTotalPartners] = useState<number>()
	const { teacherData } = useLoginContext()
	const showSubscriptions = history.location.search === '?from=subs'
	const limit = MAX_SEARCH_ITEMS
	const [searchbarData, setSearchbarData] = useState([] as Partner[])
	const [searchTeacherSubscriptionsData, setSearchTeacherSubscriptionsData] = useState<SubscriptionsCardType[]>(
		[]
	)
	const [unSuscribeOpenDialog, setUnSuscribeOpenDialog] = useState(false)
	const [item, setItem] = useState<SubscriptionsCardType>()
	const [onCompleteRes, setOnCompleteRes] = useState<boolean | undefined>(undefined)

	const [getSearchPartner, { data: searchBarPartners, loading: searchbarPartnersLoading }] =
		useSearchPartnerLazyQuery()

	useEffect(() => {
		if (item) {
			setUnSuscribeOpenDialog(true)
		}
	}, [item])
	const onUnsubscribe = async (stripeSubcriptiptionId: string) => {
		return new Promise<CancelProductSubscriptionMutation>((resolve, reject) => {
			cancelProductSubscription({
				variables: {
					teacherId: teacherData.teacher_id,
					stripeSubcriptiptionId
				},
				onCompleted: (res) => {
					setOnCompleteRes(res?.cancelProductSubscription?.success)
					resolve(res)
				},
				onError: () => {
					reject(new Error('Unsubscribe error'))
				}
			})
		})
	}

	const handleRefetch = (stripeSubcriptiptionId: string) => {
		if (onCompleteRes) {
			const partnerSuscriptionsItem = partnerSuscriptions.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			const searchTeacherSubscriptionsDataItem = searchTeacherSubscriptionsData.find(
				(i) => i.stripeSubscriptionId === stripeSubcriptiptionId
			)
			if (partnerSuscriptionsItem) {
				partnerSuscriptionsItem.status = Order_Status_Enum.Canceled
			}
			if (searchTeacherSubscriptionsDataItem) {
				searchTeacherSubscriptionsDataItem.status = Order_Status_Enum.Canceled
			}
		}
	}

	const handleSearchbarTextEnter = (text: string) => {
		setMoreResults(true)
		if (!showSubscriptions) {
			setPartners([])
			getPartners({
				variables: {
					limit: MAX_LIST_ITEMS,
					teacher_id: teacherData.teacher_id,
					order_by: {
						title: orderBySortOrder[sort]
					},
					includeCountPartners: true,
					search: buildSearchText(text, ['title', 'description'])
				}
			})
		} else {
			if (text) setPartnerSuscriptions([])
			setFilter('None')
			setSort(SortOrder.Up)
			getTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Partner,
					order_by: {
						partner: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(
						text,
						['title', 'description', 'short_description'],
						Product_Name_Enum.Partner
					)
				}
			})
		}
	}

	const breadcrumbs = buildBreadCrumbs({
		page: showSubscriptions ? Pages.AllPartnersSubs : Pages.AllPartners
	})

	const [cancelProductSubscription] = useCancelProductSubscriptionMutation()
	const [getPartners, { data: partnerData, loading: partnerDataLoading, refetch }] = useGetPartnersLazyQuery()
	const [
		getTeacherSubscriptions,
		{ data: suscriptionData, loading: suscriptionDataLoading, refetch: teacherSubscriptionsFefetch }
	] = useGetTeacherSubscriptionsLazyQuery()
	const [getSearchTeacherSubscriptions, { data: searchSuscriptionData, loading: searchSuscriptionDataLoading }] =
		useGetTeacherSubscriptionsLazyQuery()

	useEffect(() => {
		setIsFetching(false)
		if (partnerData) {
			setTotalPartners(partnerData?.partner_aggregate?.aggregate?.count)
			setPartners((partner) => [...partner, ...((partnerData?.partner ?? []) as unknown as Partner[])])
		}

		if (suscriptionData) {
			setTotalPartners(suscriptionData?.order_aggregate.aggregate?.count)
			tranformSuscriptionData(suscriptionData, false)
		}
	}, [partnerData, suscriptionData])

	const tranformSuscriptionData = (data: GetTeacherSubscriptionsQuery, isSearchData: boolean) => {
		data.order.map((item) => {
			const order = item as Order
			if (isSearchData) {
				setSubscriptionSearchData(createSuscriptionCardData(searchTeacherSubscriptionsData, order))
			} else {
				setSubscriptionData(createSuscriptionCardData(partnerSuscriptions, order))
			}
		})
	}

	const setSubscriptionSearchData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setSearchTeacherSubscriptionsData((subscription) => [...subscription, data])
		}
	}
	const setSubscriptionData = (data: SubscriptionsCardType | undefined) => {
		if (data) {
			setPartnerSuscriptions((subscription) => [...subscription, data])
		}
	}

	useEffect(() => {
		setMoreResults(true)
		if (!showSubscriptions) {
			setPartners([])
			getPartners({
				variables: {
					limit: MAX_LIST_ITEMS,
					teacher_id: teacherData.teacher_id,
					order_by: {
						title: orderBySortOrder[sort]
					},
					includeCountPartners: true,
					search: buildSearchText(searchbarText, ['title', 'description'])
				}
			})
		} else {
			setPartnerSuscriptions([])
			getTeacherSubscriptions({
				variables: {
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Partner,
					order_by: {
						partner: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(
						searchbarText,
						['title', 'description', 'short_description'],
						Product_Name_Enum.Partner
					),
					price_type: filter !== 'None' ? (filter as Price_Type_Enum) : undefined
				}
			})
		}
	}, [sort, filter])

	useEffect(() => {
		if (!isFetching || !moreResults) return
		if (showSubscriptions) {
			const currentLength = partnerSuscriptions.length
			teacherSubscriptionsFefetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.order.length !== 0 && fetchMoreResult.data?.order.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		} else {
			const currentLength = partners.length
			refetch({
				offset: currentLength,
				limit
			}).then((fetchMoreResult) => {
				if (fetchMoreResult.data?.partner.length !== 0 && fetchMoreResult.data?.partner.length >= limit) {
					setMoreResults(true)
				} else {
					setIsFetching(false)
					setMoreResults(false)
				}
			})
		}
	}, [isFetching])
	useEffect(() => {
		window.scrollTo(0, 0)
		window.addEventListener('scroll', () => {
			if (
				Math.ceil(window.innerHeight + document.documentElement.scrollTop) !==
					document.documentElement.offsetHeight ||
				isFetching
			)
				return
			setIsFetching(true)
		})

		return () => {
			window.removeEventListener('scroll', () => {})
		}
	}, [])

	const onSortChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const selectedSort = event.target.value as SortOrder
		setSort(selectedSort)
	}

	const onFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		const selectedFilter = event.target.value as Price_Type_Enum
		setFilter(selectedFilter)
	}

	const handleSearchbarTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchbarText(e.target.value)
	}
	const handleOpenProductDialog = () => setIsOpen(true)
	const handleCloseProductDialog = () => setIsOpen(false)

	useEffect(() => {
		if (!showSubscriptions) {
			if (searchBarPartners) setSearchbarData(searchBarPartners?.partner as unknown as Partner[])
		} else {
			if (searchSuscriptionData) tranformSuscriptionData(searchSuscriptionData, true)
		}
	}, [searchBarPartners, searchSuscriptionData])

	useEffect(() => {
		if (!showSubscriptions) {
			getSearchPartner({
				variables: {
					teacher_id: teacherData.teacher_id,
					limit,
					searchText: buildSearchText(searchbarText, ['title', 'description', 'short_description']),
					conditions: []
				}
			})
		} else {
			setSearchTeacherSubscriptionsData([])
			getSearchTeacherSubscriptions({
				variables: {
					limit,
					teacherId: teacherData.teacher_id,
					productName: Product_Name_Enum.Partner,
					order_by: {
						partner: {
							title: orderBySortOrder[sort]
						}
					},
					search: buildSearchText(
						searchbarText,
						['title', 'description', 'short_description'],
						Product_Name_Enum.Partner
					)
				}
			})
		}
	}, [searchbarText])

	const goToPartner = (partnerId: number) => {
		history.push(buildRouteParameters(Pages.PartnerPage, { partnerId }))
	}

	return {
		partners,
		partnerSuscriptions,
		showSubscriptions,
		goToPartner,
		handleSearchbarTextEnter,
		handleSearchbarTextChange,
		handleOpenProductDialog,
		handleCloseProductDialog,
		isOpen,
		partnerId,
		setPartnerId,
		sort,
		filter,
		onFilterChange,
		onSortChange,
		breadcrumbs,
		totalPartners,
		searchbarData,
		searchTeacherSubscriptionsData,
		searchSuscriptionDataLoading,
		searchbarPartnersLoading,
		onUnsubscribe,
		searchbarText,
		isFetching,
		moreResults,
		partnerDataLoading,
		suscriptionDataLoading,
		unSuscribeOpenDialog,
		setUnSuscribeOpenDialog,
		item,
		setItem,
		handleRefetch
	}
}
