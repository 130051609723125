import React from 'react'

import {
	Avatar,
	Box,
	ButtonProps,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	PaperProps,
	Typography,
	TypographyProps
} from '@material-ui/core'
import { fade, makeStyles, Theme } from '@material-ui/core/styles'
import { ExclamationIcon, SuccessIcon, QuestionIcon } from 'src/assets/icons'

import { BigBaseButton } from '..'
import { getCyKey } from '../../utils'

const useStyles = makeStyles<Theme, { disabled: boolean }>((theme: Theme) => ({
	// createStyles({
	dialog: {
		'& .MuiDialog-paper': {
			padding: 25
		}
	},
	dialogTitle: { paddingBottom: '0' },
	actions: {
		display: 'inline',
		'& .MuiBox-root:not(:first-child)': {
			marginLeft: 0
		}
	},
	warning: {
		color: theme.palette.secondary.dark,
		backgroundColor: fade(theme.palette.secondary.main, 0.25),
		width: 130,
		height: 130,
		fontSize: 80,
		margin: `${theme.spacing(1)}px auto ${theme.spacing(3)}px`,
		'& .MuiSvgIcon-root': {
			fontSize: 80
		}
	},
	button: {
		margin: 10,
		opacity: ({ disabled }) => (disabled ? 0.5 : 1)
	},
	footer: {
		marginTop: 25
	}
}))

type InfoDialogProps = {
	onClose?: (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => void
	open: boolean
	title: string | React.ReactNode
	icon: string | React.ReactNode | 'error' | 'warning' | 'success' | 'question'
	body: string | React.ReactNode
	onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	confirmProps?: ButtonProps
	confirmLabel?: string | JSX.Element
	onDiscard?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	discardProps?: ButtonProps
	discardLabel?: string | JSX.Element
	footer?: React.ReactNode
	bodyProps?: TypographyProps
	titleProps?: TypographyProps
	paperProps?: PaperProps
	maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
	loading?: boolean
	isIcon?: boolean
}
export const InfoDialog = ({
	onClose,
	open,
	title,
	body,
	icon,
	confirmLabel,
	onConfirm,
	confirmProps,
	discardLabel,
	onDiscard,
	discardProps,
	footer,
	bodyProps,
	titleProps,
	paperProps = {},
	maxWidth = 'xs',
	loading = false,
	isIcon = true
}: InfoDialogProps) => {
	const styles = useStyles({ disabled: !!confirmProps?.disabled })

	const renderIcon =
		icon === 'error' ? (
			'x'
		) : icon === 'warning' ? (
			<ExclamationIcon />
		) : icon === 'success' ? (
			<SuccessIcon />
		) : icon === 'question' ? (
			<QuestionIcon />
		) : (
			icon
		)

	return (
		<Dialog
			className={styles.dialog}
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth
			maxWidth={maxWidth}
			PaperProps={paperProps}>
			{!loading && (
				<DialogTitle id="customized-dialog-title" className={styles.dialogTitle}>
					{isIcon && <Avatar className={styles.warning}>{renderIcon}</Avatar>}
					<Typography align="center" {...titleProps}>
						<b data-cy={getCyKey(InfoDialog, 'infoDialogTitle')}>{title}</b>
					</Typography>
				</DialogTitle>
			)}
			{!loading && (
				<DialogContent>
					<Typography align="center" {...bodyProps}>
						{body}
					</Typography>
				</DialogContent>
			)}
			{loading && (
				<Box m={13} textAlign="center">
					<CircularProgress color="secondary" size={60} />
				</Box>
			)}
			<DialogActions className={styles.actions}>
				<Grid
					style={{ flexWrap: 'nowrap' }}
					container
					justify="flex-start"
					display="flex"
					component={Box}
					spacing={2}>
					{onDiscard && discardLabel && (
						<BigBaseButton
							data-cy={getCyKey(
								InfoDialog,
								typeof discardLabel === 'string' ? discardLabel : 'discard'
							)}
							className={styles.button}
							fullWidth
							variant="contained"
							color="default"
							onClick={onDiscard}
							{...discardProps}>
							{discardLabel}
						</BigBaseButton>
					)}
					{onConfirm && confirmLabel && (
						<BigBaseButton
							data-cy={getCyKey(
								InfoDialog,
								typeof confirmLabel === 'string' ? confirmLabel : 'confirm'
							)}
							className={styles.button}
							fullWidth
							color="secondary"
							autoFocus
							onClick={onConfirm}
							{...confirmProps}>
							{confirmLabel}
						</BigBaseButton>
					)}
				</Grid>
				{footer && (
					<Box display="flex" justifyContent="center" className={styles.footer}>
						{footer}
					</Box>
				)}
			</DialogActions>
		</Dialog>
	)
}
