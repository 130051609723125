import { useEffect, useState } from 'react'

import moment from 'moment'
import { buildGenericContext } from 'src/context/genericContext'
import { DateFilterEnum } from 'src/environment'
import {
	useGetClassWurrliesCountLazyQuery,
	useGetClassTeacherByPkQuery,
	useGetClassAssignmentAnalyticsLazyQuery,
	useGetCompletedAsignmentsLazyQuery
} from 'src/graphql/autogenerate/hooks'
import { Class, Order_By } from 'src/graphql/autogenerate/schemas'
import { useLoginContext } from 'src/hooks/useLogin'
import { useSkillsCoveredSavedLessonsData } from 'src/scenes/Teacher/scenes/2-Classes/scenes/ClassAnalytics/hooks'
import { AssignmentSortOrder, ClassChartType, getComparisonDate } from 'src/utils'

export enum Month {
	CurrentMonth,
	LastMonth
}
const today = new Date()

export const useClassAnalytics = () => {
	const { teacherData: teacher } = useLoginContext()
	const teacherId = teacher.teacher_id
	const [classId, setClassId] = useState<number>(NaN)
	const [selectedMonth, setSelectedMonth] = useState(moment().month())
	const [sortOrder, setSortOrder] = useState(AssignmentSortOrder.AlphAsc)
	const [getClassAssignmentAnalyitics, { data: classAssignmentsCount }] =
		useGetClassAssignmentAnalyticsLazyQuery()
	const [getClassWurrliesCount, { data: classWurrliesCount }] = useGetClassWurrliesCountLazyQuery()
	const [getCompletedAssignments, { data: completedClassAssignments }] = useGetCompletedAsignmentsLazyQuery()
	const { getChartData } = useSkillsCoveredSavedLessonsData(classId)

	const chartStandardsData = getChartData(ClassChartType.standards)
	const chartSkillsData = getChartData(ClassChartType.skills)

	useEffect(() => {
		const fromDate = getFromDate(selectedMonth)
		const toDate = getToDate(selectedMonth)

		if (!Number.isNaN(classId)) {
			const variables = {
				classId,
				fromDate,
				toDate,
				comparisonToDate: moment(fromDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
				comparisonFromDate: moment(fromDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
			}
			getClassAssignmentAnalyitics({ variables })
			getClassWurrliesCount({ variables })
		}
	}, [selectedMonth, classId])

	useEffect(() => {
		const filter = selectedMonth === moment().month() + 1 ? DateFilterEnum.thisMonth : DateFilterEnum.lastMonth
		const { date } = getDatesForQuery(selectedMonth, filter)
		if (classId)
			getCompletedAssignments({
				variables: {
					classId,
					fromDate: date.fromDate,
					toDate: date.toDate,
					orderAlph:
						sortOrder === AssignmentSortOrder.AlphAsc
							? Order_By.Asc
							: sortOrder === AssignmentSortOrder.AlphDesc
							? Order_By.Desc
							: undefined,
					orderDate:
						sortOrder === AssignmentSortOrder.DateAsc
							? Order_By.Asc
							: sortOrder === AssignmentSortOrder.DateDesc
							? Order_By.Desc
							: undefined
				}
			})
	}, [sortOrder, selectedMonth, classId])

	const { data, loading: loadingClass } = useGetClassTeacherByPkQuery({
		skip: Number.isNaN(classId),
		variables: {
			teacherId,
			classId
		}
	})

	return {
		classId,
		setClassId,
		classData: data?.teacher_by_pk?.class_teachers[0]?.class as Class,
		loadingClass,
		selectedMonth,
		setSelectedMonth,
		classAssignmentsCount,
		classWurrliesCount,
		chartStandardsData,
		chartSkillsData,
		completedClassAssignments: completedClassAssignments?.class_assignment || [],
		setSortOrder
	}
}

export const [ClassAnalyticsProvider, useClassAnalyticsContext] = buildGenericContext(useClassAnalytics)

const getFromDate = (selectedMonth: number) => {
	if (selectedMonth === moment().month() + 1) {
		return new Date(today.getFullYear(), today.getMonth(), 1)
	} else {
		return new Date(today.getFullYear(), today.getMonth() - 1, 1)
	}
}

const getToDate = (selectedMonth: number) => {
	if (selectedMonth === moment().month() + 1) {
		return new Date()
	} else {
		return new Date(today.getFullYear(), today.getMonth(), 1)
	}
}

const getDatesForQuery = (selectedMonth: number, filter: DateFilterEnum) => {
	const comparisonDate = getComparisonDate(filter)
	const fromDate = getFromDate(selectedMonth)
	const toDate = getToDate(selectedMonth)

	return {
		date: {
			fromDate,
			toDate
		},
		comparisonDate
	}
}
